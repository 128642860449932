const BulkSignClinicalNotes = {
	namespaced: true,
  	state: {
    	listUrl: "/clinical-notes",
		header: [
			{"Patient":"Patient"},
			{"ApptDate":"Appointment Date & Time"},
			{"rendering_provider":"Provider"},
			{"supervisor_provider":"Supervising Manager"},
			{"locked":"Note Status"},
			{"rendering_signed_Off":"Provider Signed Off"},
			{"supervising_signed_Off":"Superviser Signed Off"},
			{"":"Actions"},
			{"":""},
		],
		pagination: {
			to: "",
			from: "",
			total: "",
			last_page: "",
			current_page: "",
			pageNavArr: [],
		},	
		list: [],	
		userType: '',
		filter: {
            office_id:'',
            provider_id:'',
            supervisor_id:'',
            note_status:'',
            patient_id:'',
            from_date:'',
            to_date:'',
            search_text:'',          
        },
  	},
  getters: {
    getTableHeaders: (state) => state.header,
    getTableContents: (state) => state.list,
    getListURL: (state) => state.listUrl,
  },
  mutations: {},
  actions: {},
}

export default BulkSignClinicalNotes;