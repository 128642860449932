export default {
	mounted(){
		//this.permissioncheck();
	},
	methods: {
		permissioncheck(id){
			/*if user is super admin or supervisor provider then no need to check any permission */
			if(this.$store.state.auth.is_super_admin == 0 && this.$store.state.auth.permission_ids!=null)
			{
	      		let permission = this.$store.state.auth.permission_ids;
	      		const permissionArr = permission.split(",");
	      		if(!permissionArr.includes(id)){
		        	return false;
		      	}
		      	else{
		        	return true;
		      	}
			}
			else
			{
				return true;
			}
	     }
	},
  	computed:{},
}