<template>

	<div class="content-wrapper mt-20">
		<div class="container">
			<section class="content">
				<div class="content-header text-center">
					<!-- <img src="images/purewellness-header.png" class="img-fluid" style="width: 240px;"> -->
					<img src="images/purewellnessmedical.svg" class="img-fluid" style="width: 240px;">
				</div>
				<div class="main_frm d-none">
					<div class="row">
						<div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
							<div class="box userdoc_detail">
								<div class="box-header with-border bg-info">
									<h3 class="fw-600 my-0 mx-5">Driver's License Info</h3>
								</div>
								<div class="box-body">
									<input-field title="Driver's License Number" v-model="form.driveing_license_no" labelClass="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" divClass="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"></input-field>
									<div class="form-group row">
										<label class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 form-label">Driver's License State </label>
										<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
											<Select2 id="state_id" placeholder="Select State" :options="states" v-model="form.driveing_license_state"/>
										</div>
									</div>
									<div class="form-group row">
										<label class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 form-label">Driver's License expiry date </label>
										<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
											<div class="availdate">
												<ejs-datepicker 
												:value="expiry_date" 
												v-model="form.expiry_date"
												id="expiry_date" 
												:format="dateFormat"  
												:placeholder="waterMarkText"
												name="expiry_date" 
												:showClearButton='false' 
												:openOnFocus='true'
												@change="getdate">
												</ejs-datepicker>
											</div>
										</div>
									</div>
									<file-upload @change="type_validation($event,7)" :coustom_error="coustom_error[7]" id="uploadFiles" title="Upload Driver's License" v-model="form.driveing_license" labelClass="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" divClass="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"></file-upload>
									<span class="form-text text-muted align-sub l-h-n custom-span">Supported formats JPG, JPEG, PDF, PNG. Max allowed size is 10MB</span>
								</div>
							</div>
						</div>
						<div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
							<div class="box userdoc_detail">
								<div class="box-header with-border bg-info">
									<h3 class="fw-600 my-0 mx-5">Insurance Info</h3>
								</div>
								<div class="box-body">
									<file-upload @change="type_validation($event,1)" :coustom_error="coustom_error[1]" id="uploadFiles" title="Primary Insurance card front" v-model="form.insurance_front" labelClass="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" divClass="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"></file-upload>
									<file-upload @change="type_validation($event,2)" :coustom_error="coustom_error[2]" id="uploadFiles" title="Primary Insurance card rear" v-model="form.insurance_rear" labelClass="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" divClass="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"></file-upload>
									<file-upload @change="type_validation($event,3)" :coustom_error="coustom_error[3]" id="uploadFiles" title="Secondary Insurance card front" v-model="form.Secondary_insurance_front" labelClass="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" divClass="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"></file-upload>
									<file-upload @change="type_validation($event,4)" :coustom_error="coustom_error[4]" id="uploadFiles" title="Secondary Insurance card rear" v-model="form.Secondary_insurance_rear" labelClass="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" divClass="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"></file-upload>
									<file-upload @change="type_validation($event,5)" :coustom_error="coustom_error[5]" id="uploadFiles" title="Tertiary Insurance card front" v-model="form.tertiary_insurance_front" labelClass="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" divClass="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"></file-upload>
									<file-upload @change="type_validation($event,6)" :coustom_error="coustom_error[6]" id="uploadFiles" title="Tertiary Insurance card rear" v-model="form.tertiary_insurance_rear" labelClass="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" divClass="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"></file-upload>
									<span class="form-text text-muted align-sub l-h-n custom-span">Supported formats JPG, JPEG, PDF, PNG. Max allowed size is 10MB</span>

								</div>
							</div>
						</div>
						<div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
							<div class="box userdoc_detail">
								<div class="box-header with-border bg-info">
									<h3 class="fw-600 my-0 mx-5">Pharmacy Info</h3>
								</div>
								<div class="box-body">
									<input-field title="Pharmacy Name" v-model="form.pharmacy_name" labelClass="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" divClass="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"></input-field>
									<input-field title="Pharmacy Phone Number" v-model="form.pharmacy_number" labelClass="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" divClass="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"></input-field>
									<text-area title="Pharmacy Address" v-model="form.pharmacy_address" rows="4" labelClass="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" divClass="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"></text-area>
								</div>
							</div>
						</div>
					</div>
					<div class="text-center">
						<form-btn title="Save" btnCls="btn-info fs-20 py-10 p-20 saveData" @click="save"></form-btn>
					</div>
				</div>
					
				<div class="message d-none">
					<div class="box">
						<div class="box-body text-center">
							<h1 class="my-20 fw-300">
								{{this.msg}}
							</h1>
						</div>
					</div>
				</div>
			</section>
		</div>
	</div>

</template>

<script>
	import FileUpload from "@/components/base/formFields/fileUpload.vue";
	import InputField from "@/components/base/formFields/InputField.vue";
	import Select2 from "@/components/base/formFields/select2.vue";
	import TextArea from "@/components/base/formFields/textArea.vue";
	import FormBtn from "@/components/base/formFields/formBtn.vue";
	import axios from "@/scripts/axios.js";
	import useVuelidate from "@vuelidate/core";
	import submitMixins from "@/scripts/mixins/submitMixins.js";
	import moment from "moment";
	export default {
		mixins: [submitMixins],
		setup: () => {
			return { v$: useVuelidate() };
		},
		computed: {
			form() {
				return this.$store.state.Patientidentification.form;
			},
			vuelidateExternalResults() {
				return this.$store.state.Patientidentification.vuelidateExternalResults;
			},
			validationRules() {
				return this.$store.state.Patientidentification.validationRules;
			}
		},
		validations() {
			return this.$store.state.Patientidentification.validationRules;
		},
		data() {
			return {
				token:'',
				states: [],
				expiry_date:'',
				waterMarkText : 'mm-dd-yyyy',
				dateFormat: 'MM-dd-yyyy',
				msg:'',
				coustom_error: {
					1: '',
					2: '',
					3: '',
					4: '',
					5: '',
					6: '',
					7: '',
				},
			};
		},	
		components: {
			FileUpload,
			InputField,
			Select2,
			FormBtn,
			TextArea,
		},
		mounted(){

			this.token = this.$route.params.token;
			this.getstate();
			this.verifytoken();
		},
		methods:{
			getdate(){
				if($('#expiry_date').val() == '') {
					this.form.expiry_date = '';
				} else {
					this.expiry_date =  $('#expiry_date').val();
					this.form.expiry_date = moment(String(this.expiry_date)).format('YYYY-MM-DD');
				}
			},
			verifytoken(){
				axios.post("/identificationtoken",{token:this.$route.params.token})
				.then((response) => {
					$(".main_frm").removeClass('d-none');
					this.form.patinet_id = response.data.data.id;
					this.form.my_clinic_id = response.data.data.clinic_id;
					this.form.unique_id = response.data.data.unique_id;
				})
				.catch(() => {
					this.msg = "Please contact Pure Wellness Medical team on 855-679-7873. The above Link is now expired.";
					$(".main_frm").addClass('d-none');
					$(".message").removeClass('d-none');
				});
			},
			async getstate() {
				var loadCountry = "231";
				this.states = await this.postData(`json_autocomplete/${loadCountry}/states`);
				var temptest = [];
				this.states.forEach(element => {
					temptest.push({id: element.id, text: element.name});
				});

				this.states = temptest;
			},
			save(){
				let checkErr = 0;
				if(this.coustom_error != []){
					$.each(this.coustom_error, function(key,val){
						if(val != ''){
							checkErr += 1;
						}
					});
				}
				
				if(checkErr == 0){
					this.postForm(`/patient_documents/upload`,'Patientidentification',true,"formdata");
				}
			},
			formPostSuccess(data) {
				this.msg = "Thank you. Your information is updated with Pure Wellness Medical.";
				$(".main_frm").addClass('d-none');
				$(".message").removeClass('d-none');
			},
			type_validation(event, key){
				if(event.target.files[0].type != 'application/pdf' && event.target.files[0].type != 'image/jpeg' && event.target.files[0].type != 'image/png'){
					this.coustom_error[ key ] ="Only JPG, JPEG, PDF, PNG formats are supported.";
				}
				else if(event.target.files[0].size > 10240000){
					this.coustom_error[ key ] ="Max allowed size is 10MB.";	
				}
				else{
					this.coustom_error[ key ] = '';
				}
			},
		}
	}
</script>

<style>
.custom-span {
	display: block;
	margin-top: 20px;
}
</style>