import {
  helpers,
  required,
  email,
  minLength,
  maxLength,
  sameAs,
  numeric,
} from "@vuelidate/validators";

const VaccinesAvailable = {
  namespaced: true,

  state: {
    edit:'',
    form: {
      cvx_code:'',
    },
    vuelidateExternalResults: {
      form: {
        cvx_code:[],
        vaccine_name:[],
        administered_amount:[],
        vaccine_site:[],
        vaccine_route:[],
        vaccinated_on:[],
        ordering_doctor:[],
      },
    },
    validationRules: {
      form: {
        cvx_code:{
          required: helpers.withMessage("CVX Code is required", required),
        },
        administered_amount:{
          required: helpers.withMessage("Administered Amount is required", required),
        },
        vaccine_site:{
          required: helpers.withMessage("Vaccine Site is required", required),
        },
        vaccine_route:{
          required: helpers.withMessage("Vaccine Route is required", required),
        },
        vaccinated_on:{
          required: helpers.withMessage("Vaccinated On is required", required),
        },
        administered_at:{
          required: helpers.withMessage("Administered at is required", required),
        },
        vaccine_name:{
          required: helpers.withMessage("Vaccine Name is required", required),
        }
      },
    },
    listUrl: '',
    getEditUrl: "''",
    list: [],
  },
  getters: {
    getTableHeaders: (state) => state.header,

    getTableContents: (state) => state.list,
    getListURL: (state) => state.listUrl,
  },
  mutations: {},
  actions: {},
}

export default VaccinesAvailable;