<template>
  <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-2">
    <router-link
      to="/patient/add"
      class="waves-effect waves-light btn btn-info d-block mb-10"
      v-if="permissioncheck(this.$store.state.permission.create_update)"
    >
      <i class="fa fa-plus fs-14"></i> New Patient
    </router-link>
    <div class="sticky-scroll-box">
      <div class="accorsidebar">
        <div class="accordion-item">
          <p class="accordion-header display-vertical" id="addpatienttab">
            <span class="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapsedemogr" aria-expanded="false" aria-controls="collapsedemogr">Patient</span>
          </p>
          <div id="collapsedemogr" class="accordion-collapse collapse" aria-labelledby="addpatienttab" data-bs-parent="#accordionExample">
            <div class="side-navigation">
              <ul class="side-bar-menu side-bar-tree patientTabs" data-widget="tree">
                <li class="side-bar-treeview" :class="{ 'sub-menu-open': patientinfo.includes(activeRoute), }">
                  <a href="javascript:void(0)" class="side-bar-toggle">
                    <span>Patient Information</span>
                    <span class="pull-right-container">
                      <i class="fa fa-angle-right" :class="{ 'rotate-arrow': patientinfo.includes(activeRoute), }"></i>
                    </span>
                  </a>
                  <ul class="side-bar-treeview-menu" :class="{ 'd-block': patientinfo.includes(activeRoute) }">
                    <li>
                      <a :href="`#/patient-chart/${this.$route.params.id}`" ref="PatientDetailsLink" @click.prevent="changeComp('PatientDetails')" >Primary Information</a>
                    </li>
                    <li>
                      <a :href="`#/patient-chart/${this.$route.params.id}/demographics`"  ref="PatientDemographicsLink" @click.prevent="changeComp('PatientDemographics')" >Demographics</a>
                    </li>
                    <li>
                      <a :href="`#/patient-chart/${this.$route.params.id}/driver-license`" ref="PatientIdentificationLink" @click.prevent="changeComp('PatientIdentification')" >Identification</a>
                    </li>
                    <!-- <li><a href="javascript:void(0)"  @click="upcommngMenu()">Patient Status</a></li> -->
                    <li>
                      <a :href="`#/patient-chart/${this.$route.params.id}/smoking-status`" ref="SmokingStatusLink" @click.prevent="changeComp('SmokingStatus')" >Smoking Status</a>
                    </li>
                    <li>
                      <a :href="`#/patient-chart/${this.$route.params.id}/vitals`" ref="PatientVitalsLink" @click.prevent="changeComp('PatientVitals')" >Vitals</a>
                    </li>
                    <li>
                      <a :href="`#/patient-chart/${this.$route.params.id}/insuranceinfo`" ref="PatientInsuranceInfoLink" @click.prevent="changeComp('PatientInsuranceInfo')" >Insurance</a>
                    </li>
                  </ul>
                </li>
 
                <li class="side-bar-treeview">
                  <a href="javascript:void(0)" class="side-bar-toggle">
                    <span>Billing</span>
                    <span class="pull-right-container">
                      <i class="fa fa-angle-right" :class="{ 'rotate-arrow': document.includes(activeRoute), }"></i>
                    </span>
                  </a>
                   <ul class="side-bar-treeview-menu" :class="{ 'd-block': document.includes(activeRoute) }">
                    <li>
                      <a :href="`#/patient-chart/${this.$route.params.id}/payment/billing`" ref="MainBillingLink" @click.prevent="changeComp('MainBilling')" >Billing
                      <span v-if="this.$store.state.patientDetail.due_amount_sum > 0" class="waves-effect waves-light badge badge-danger-light ms-2 font-bold">Total Due : {{ $filters.toUSD(this.$store.state.patientDetail.due_amount_sum) }}</span>
                      </a>
                    </li>
                    <!-- Only Billing Department can access this tab also they need permission -->
                    <li v-if="permissioncheck(this.$store.state.permission.Insurance_Billing_Access_Management) && this.$store.state.auth.role_id==1">
                      <a :href="`#/patient-chart/${this.$route.params.id}/payment/insurance`" ref="MainInsuranceBillingLink" @click.prevent="changeComp('MainInsuranceBilling')" >Insurance</a>
                    </li>
                  </ul>  
                </li>

                <li>
                    <a :href="`#/patient-chart/${this.$route.params.id}/appointments`" ref="PatientDetailsAppointmentsLink" @click.prevent="changeComp('PatientDetailsAppointments')" >
                    Appointments
                    <slot v-if="this.$store.state.patientDetail.have_imported_data != 0"><i class="mdi mdi-import" style="transform: rotate(90deg); display: inline-block;"></i></slot>
                    </a>
                </li>
                <li>
                  <a :href="`#/patient-chart/${this.$route.params.id}/clinical-dashboard`" ref="PatientClinicalDashboardLink" @click.prevent="changeComp('PatientClinicalDashboard')" >Clinical Dashboard</a>
                </li>
                <li class="side-bar-treeview" :class="{ 'sub-menu-open': document.includes(activeRoute) }">
                  <a href="javascript:void(0)" class="side-bar-toggle">
                    <span>Documents</span>
                    <span class="pull-right-container">
                      <i class="fa fa-angle-right" :class="{ 'rotate-arrow': document.includes(activeRoute), }"></i>
                    </span>
                  </a>
                  <ul class="side-bar-treeview-menu" :class="{ 'd-block': document.includes(activeRoute) }">
                    <li>
                      <a :href="`#/patient-chart/${this.$route.params.id}/documents-upload`" ref="DocumentUploadLink" @click.prevent="changeComp('DocumentUpload')" >Document Upload</a>
                    </li>
                    <li>
                      <a :href="`#/patient-chart/${this.$route.params.id}/signed_consent_forms`" ref="PatientSignedConsentFormsLink" @click.prevent="changeComp('PatientSignedConsentForms')" >Consent Forms Status</a>
                    </li>
                    <li>
                      <a :href="`#/patient-chart/${this.$route.params.id}/lab_results`" ref="PatientLabesultsLink" @click.prevent="changeComp('PatientLabesults')" >Lab Results</a>
                    </li>
                    <!-- <li><a class="" href="./documents.php#outboundreferrels" role="tab">Outbound Referrals</a></li> -->
                  </ul>
                </li>
                <li>
                  <a :href="`#/patient-chart/${this.$route.params.id}/clinical-notes`" class="side-bar-toggle" @click.prevent="changeComp('ClinicalNotesList')">Clinical Note</a>
                </li>
                <li>
                  <a :href="`#/patient-chart/${this.$route.params.id}/wellness-notes/All`" class="side-bar-toggle" @click.prevent="changeComp('AllNotes')">Wellness Follow-up Notes</a>
                </li>
                <li>
                  <a :href="`#/patient-chart/${this.$route.params.id}/Questionnarie`" ref="Questionnarie" @click.prevent="changeComp('Questionnarie')" >Questionnarie</a>
                </li>
                <li>
                  <a :href="`#/patient-chart/${this.$route.params.id}/problem`" ref="PatientProblemListLink" @click.prevent="changeComp('PatientProblemList')" >Problem</a>
                </li>
                <!-- <li v-if="is_production_env == true">
                  <a href="javascript:void(0)"  @click="upcommngMenu()">Medication</a>
                </li> -->
                <li>
                  <a :href="`#/patient-chart/${this.$route.params.id}/medication`" ref="PatientMedicationsLink" @click.prevent="changeComp('PatientMedications')" >Medication</a>
                </li>
                <!-- <li v-if="is_production_env == true">
                  <a href="javascript:void(0)"  @click="upcommngMenu()">Allergy</a>
                </li> -->
                <li>
                  <a :href="`#/patient-chart/${this.$route.params.id}/allergy`" ref="PatientAllergiesLink" @click.prevent="changeComp('PatientAllergies')" >Allergy</a>
                </li>
                 <!-- <li><a href="javascript:void(0)"  @click="upcommngMenu()">Medication</a></li> -->
                <!-- <li>
                    <a href="javascript:void(0)" ref="AllergyLink" @click.prevent="changeComp('Allergy')" >Allergy</a>
                </li> -->
                <!-- <li><a>Medication List</a></li>
                <li><a>Allergy List</a></li> -->
                <li>
                  <a
                    :href="`#/patient-chart/${this.$route.params.id}/pureportal-auditLogs`"
                    ref="PatientAuditLogsLink"
                    @click.prevent="changeComp('PatientAuditLogs')"
                    >PurePORTAL</a>
                </li>
                <li>
                  <a
                    :href="`#/patient-chart/${this.$route.params.id}/cqm`"
                    ref="CQMLink"
                    @click.prevent="changeComp('CQM')"
                    >CQMs</a
                  >
                </li>
                <li>
                  <a
                    :href="`#/patient-chart/${this.$route.params.id}/cds`"
                    ref="CDSLink"
                    @click.prevent="changeComp('CDS')"
                    >CDS</a
                  >
                </li>
                <li>
                  <a :href="`#/patient-chart/${this.$route.params.id}/lab-orders`" ref="PatientDetailsLabordersLink" @click.prevent="changeComp('PatientDetailsLaborders')" >Lab Orders</a>
                </li>
                <li>
                  <a :href="`#/patient-chart/${this.$route.params.id}/immunizations`" ref="PatientDetailsimmunizationsLink" @click.prevent="changeComp('PatientDetailsimmunizations')" >Immunizations</a>
                </li>
                <li>
                  <a :href="`#/patient-chart/${this.$route.params.id}/radiology-order`" ref="RadiologyOrderLink" @click.prevent="changeComp('RadiologyOrder')" >Radiology Orders</a>
                </li>
                <li>
                  <a :href="`#/patient-chart/${this.$route.params.id}/implantable-devices`" ref="PatientImplantableDeviceLink" @click.prevent="changeComp('PatientImplantableDevice')" >Implantable Devices</a>
                </li>
                <li>
                  <a :href="`#/patient-chart/${this.$route.params.id}/crm`" ref="CRMLink" @click.prevent="changeComp('CRM')" >CRM</a>
                </li>
                
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import permissionMixins from "@/scripts/mixins/permission.js";
import submitMixins from "@/scripts/mixins/submitMixins.js";
export default {
  mixins: [permissionMixins, submitMixins],
  data() {
    return {
      event: 0,
      is_production_env: (process.env.VUE_APP_NODE_ENV == 'production') ? true : false,
      activeRoute: "",
      patientinfo: [
        "PatientDetails",
        "PatientDemographics",
        "SmokingStatus",
        "PatientVitals",
        "PatientInsuranceInfo",
      ],
      document: [
        "DocumentUpload",
        "PatientSignedConsentForms",
        "PatientLabesults",
      ],
      clinicalnote: [
        "ClinicalNoteHandP",
        "ClinicalNoteSoap",
        "ClinicalNoteAdditional",
        "PatientAmendments",
        "PatientLockedClinicalNotes",
      ],
    };
  },
  watch: {
    "$route.name": function(newValue) {
      this.linkActive();
    },
  },
  mounted() {
    setTimeout(() => {
      this.linkActive();
    }, 100);
    $(".side-bar-toggle").on("click", function() {
      
      if (
        $(this)
          .parent()
          .hasClass("sub-menu-open")
      ) {
        this.activeRoute = "";
        $(this).parent()
          .find(".side-bar-treeview-menu")
          .removeClass("d-block");
        $(this)
          .parent()
          .find(".fa-angle-right")
          .removeClass("rotate-arrow");
        $(this)
          .parent()
          .removeClass("sub-menu-open");
      } else {
        this.activeRoute = this.toggleRoute;
        $(this)
          .parent()
          .addClass("sub-menu-open");
        $(this).parent()
          .find(".side-bar-treeview-menu")
          .addClass("d-block");
        $(this)
          .parent()
          .find(".fa-angle-right")
          .addClass("rotate-arrow");
      }
    });
  },
  methods: {
    upcommngMenu() {
        this.moshaToast("This service is under development", "info");
    },
    changeComp(routerName) {
      /*logout timer*/
      this.timerCount=30;
      /*logout timer - End*/
      this.$store.state.patientDetailsComp = routerName;
      this.$route.name = routerName;
      this.activeRoute = routerName;
      this.toggleRoute = routerName;
      let props = this.$router.resolve({
        name: routerName,
        params: { id: this.$route.params.id },
      });
      if (history.pushState && props) {
        history.pushState(null, "", props.href);
      }
      $(".patientTabs li")
        .children("a")
        .removeClass("active");
      var $tabName = this.activeRoute + "Link",
        $link;
      if (
        $.type(this.$refs[$tabName]) !== undefined &&
        $.type(this.$refs[$tabName]) !== "undefined"
      ) {
        this.$refs[$tabName].classList.add("active");
      }
      //this.$router.push({name: routerName});
    },
    linkActive() {
      var name = this.$route.name;
      this.activeRoute = this.$route.name;
      this.toggleRoute = this.$route.name;
      $(".patientTabs li")
        .children("a")
        .removeClass("active");
      var $tabName = name + "Link",
        $link;
      if (
        $.type(this.$refs[$tabName]) !== undefined &&
        $.type(this.$refs[$tabName]) !== "undefined"
      ) {
        this.$refs[$tabName].classList.add("active");
      }
      // $('.side-bar-treeview').each(function() {
      //  if($(this).find("li > a").hasClass("active")){
      //    $(this).click();
      //  }
      // });
    },
  },
};
</script>
