<template>
   <div class="panel pagecollap">
      <div class="panel-heading" id="labresults" role="tab">
         <a class="panel-title" aria-controls="answer-5" aria-expanded="false" data-bs-toggle="collapse" href="#answer-5" data-parent="#accordion2" @click=
         labview()>Lab Results</a>
      </div>
      <div class="panel-collapse collapse" id="answer-5" aria-labelledby="labresults" role="tabpanel" data-bs-parent="#category-1">
         <div class="panel-body">
            <div class="table-responsive">
               <table-base :dataTypes="{dataType: 'LabResult', patient_id: patient_id,patient_unique_id:patient_unique_id,status:'Results Received',method:'post',title:'No any lab results available.'}">
                        <template v-slot="slotProps">
                           <td>{{ slotProps.item.loinc_code }}</td>
                           <td>{{ slotProps.item.loinc_description}}</td>
                           <td>{{ $filters.displayDateTimeLocal(slotProps.item.date_of_testing,'',false,"ddd, MM-DD-YYYY",true) }}</td>
                           <td v-if="slotProps.item.is_doctor_reviewed == 1">Signed Off </td>
                           <td v-else>Pending</td>
                           <td>{{ slotProps.item.status}}</td>
                           <td>{{ slotProps.item.result_value}}</td>
                           <td>{{ slotProps.item.lab_result_description}}</td>
                        </template>
               </table-base>
            </div>
         </div>
      </div>
   </div>
</template>
<script>
import TableBase from "@/components/base/Table.vue";
import { onMounted } from 'vue-demi';
import axios from "@/scripts/axios.js";
export default {
   components: { 
      TableBase,
   },
   data() 
   {
      return {
         patient_id : this.$store.state.patientDetail.patient_id,
         patient_unique_id:this.$store.state.patientDetail.patient_unique_id,
      };
   },
   mounted(){
     
   },
   methods: {
   labview(){
        axios.post(`patient/${this.$route.params.id}/audit-log`, {
          type:"LABRESULT",
          action:"VIEW"

      }).then((res) => {
         console.log(res);
      });
      }
   }
};
</script>