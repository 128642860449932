<template>
  <div class="form-group row" :class="{ error: errors.length }">
    <label :class="labelClass"
      >{{ title }}
      <small v-show="isRequired" class="text-danger asterisksign">*</small>
    </label>
    <div :class="divClass">
      <div class="controls">
        <select
          :disabled="disabled"
          name="select"
          required
          :id="checkID"
          :title="title"
          class="form-select form-control "
          :value="modelValue"
          @change="$emit('update:modelValue', $event.target.value)"
        >
          <option value="" :disabled="disabled">Select {{ title }}</option>
          <option
            :value="item.value"
            v-for="item in option"
            :key="item.value"
            >{{ item.title }}</option
          >
        </select>
        <span class="form-text text-muted" v-if="notes != ''">{{ notes }}</span>
      </div>
      <div class="error-msg">
        <ul class="mb-0 p-0 fs-14">
          <li class="d-block" v-for="(error, index) of errors" :key="index">
            {{ error.$message }}
          </li>
        </ul>
      </div>
    </div>
    <div
      :class="noteClass"
      v-show="note != ''"
      style="{vertical-align:-webkit-baseline-middle}"
    >
      <small class="form-text text-muted">{{ note }}</small>
    </div>
  </div>
</template>
<script>
import submitMixins from "@/scripts/mixins/submitMixins.js";
export default {
  mixins: [submitMixins],
  props: {
    disabled:{
        type: Boolean,
        default: false,
    },
    modelValue: {},
    errors: {
      type: Array,
      default: [],
    },
    title: {
      type: String,
    },
    option: {
      type: Object,
    },
    isRequired: {
      type: Boolean,
    },
    labelClass: {
      type: String,
      default:
        "col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end",
    },
    divClass: {
      type: String,
      default: "col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3",
    },
    notes: {
      type: String,
      default: "",
    },
    noteClass: {
      type: String,
      default: "col-12 col-sm-8 col-md-8 offset-sm-4 offset-lg-0 col-lg-4 col-xl-5",
    },
    note: {
      type: String,
      default: "",
    },
    id: {
      type: String,
    }
  },
  data() {
    return {
      data: "",
      error: false,
      checkID: (this.id) ? this.id : this.title
    };
  },
};
</script>
