<template>
  <div class="content-wrapper">
    <div class="container-full">
      <div class="content-header">
        <div class="row justify-content-between">
          <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
            <h2 class="heading2 mb-10">Archived Billing Profiles</h2>
          </div>
          <div
            class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-sm-end">
            <template v-if="this.show_lifestyle_menu">
                <router-link
                  to="/billing-profile-list?lifestyle=true"
                  class="waves-effect waves-light btn btn-info-light"><i class="fa fa-arrow-left fs-16"></i> Back</router-link>
            </template>
            <template v-else>
              <router-link
                to="/billing-profile-list"
                class="waves-effect waves-light btn btn-info-light"><i class="fa fa-arrow-left fs-16"></i> Back</router-link>
            </template>
          </div>
        </div>
      </div>
      <div class="row">
          <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-2" v-if="this.show_lifestyle_menu">
              <life-style-header routefrom="BillingProfileList"></life-style-header>
          </div>
          <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-10" v-bind:class="show_lifestyle_menu ? 'col-12 col-sm-12 col-md-9 col-lg-9 col-xl-10' : 'col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'">
              <section class="content">
                <div class="box">
                  <div class="box-body">
                    <div class="table-responsive">
                      <data-table-server dataType="billingArchived">
                        <template v-slot="slotProps">
                          <!-- slot for table data -->
                          <td>{{ slotProps.item.name }}</td>
                          <td>
                            <div class="d-inline-block" 
                              v-if="slotProps.item.icd10_codes != null"
                              :set="
                                (description = slotProps.item.icd10_description.split(
                                  ','
                                ))
                              "
                            >
                              <div
                                v-for="(item,
                                index) in slotProps.item.icd10_codes.split(',')"
                                :key="index"
                              >
                                <Popper
                                  arrow
                                  :content="description[index]"
                                  :hover="true"
                                  class="helptips"
                                >
                                  <span class="badge badge-secondary">
                                    {{ item }}
                                  </span>
                                </Popper>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div
                              v-if="slotProps.item.cpt_codes != null"
                              :set="
                                (description = slotProps.item.cpt_description.split(
                                  ','
                                ))
                              "
                            >
                              <div
                                v-for="(item, index) in slotProps.item.cpt_codes.split(
                                  ','
                                )"
                                :key="index"
                              >
                                <Popper
                                  arrow
                                  :content="description[index]"
                                  :hover="true"
                                  class="helptips"
                                >
                                  <span class="badge badge-secondary">
                                    {{ item }}
                                  </span>
                                </Popper>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div
                              v-if="slotProps.item.package_code != null"
                              :set="
                                (description = slotProps.item.package_code_description.split(
                                  ','
                                ))
                              "
                            >
                              <div
                                v-for="(item,
                                index) in slotProps.item.package_code.split(',')"
                                :key="index"
                              >
                                <Popper
                                  arrow
                                  :content="description[index]"
                                  :hover="true"
                                  class="helptips"
                                >
                                  <span class="badge badge-secondary">
                                    {{ item }}
                                  </span>
                                </Popper>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div
                              v-if="slotProps.item.custom_procedure_codes != null"
                              :set="
                                (description = slotProps.item.custom_procedure_description.split(
                                  ','
                                ))
                              "
                            >
                              <div
                                v-for="(item,
                                index) in slotProps.item.custom_procedure_codes.split(
                                  ','
                                )"
                                :key="index"
                              >
                                <Popper
                                  arrow
                                  :content="description[index]"
                                  :hover="true"
                                  class="helptips"
                                >
                                  <span class="badge badge-secondary">
                                    {{ item }}
                                  </span>
                                </Popper>
                              </div>
                            </div>
                          </td>
                          <td>{{ formatDate(slotProps.item.updated_at) }}</td>
                          
                        </template>  
                      </data-table-server>
                    </div>
                  </div>
                </div>
              </section>
          </div>
      </div>
    </div>
  </div>
</template>
<script>
import DataTableServer from "@/components/base/DataTableServer.vue";
import LifeStyleHeader from "@/components/Lifestyle/LifeStyleHeader.vue";
import moment from "moment";
import axios from "@/scripts/axios.js";
import Popper from "vue3-popper";

export default {
  name: "BIllingProfileList",
  components: { DataTableServer, Popper, LifeStyleHeader },
  data() {
    return {
      show_lifestyle_menu : this.$route.query.lifestyle ? true : false
    };
  },
  computed: {},
  mounted() {},
  methods: {
    formatDate(date) {
      return moment(date).format("MMM D, YYYY");
    },  
  },
};
</script>
