<template>
    <div class="quick-info-content d-none">
        <div
        class="media-list media-list-divided media-list-hover userprovid_scroll"
        style="max-height:260px;overflow:auto;" v-if="data.appointment_type!='Break'">
        <div class="media align-items-center d-block bb-1">
            <div class="flexbox flex-grow gap-items m-0">
                <div class="media-body">
                    <h4 class="fw-800 mb-5">Appointment</h4>
                    <div class="d-flex l-h-14 pb-2">
                        <p class="mb-0 me-1 d-inline-block">Current:</p>
                        <span class="fw-600"
                        >{{ data.schedule_date }}
                        <small>(for {{ data.duration }}min)</small></span
                        >
                    </div>
                    <div class="d-flex l-h-14 pb-2">
                        <p class="mb-0 me-1 d-inline-block">Upcoming:</p>
                        <span class="fw-600" :id="`next_appt_id_${data.Id}`">{{ (data.next_appointment_date != null) ? data.next_appointment_date : "N/A" }}</span>
                    </div>
                    <div class="d-flex l-h-14 pb-2">
                        <p class="mb-0 me-1 d-inline-block">Previous:</p>
                        <span class="fw-600" :id="`last_appt_id_${data.Id}`">{{ (data.last_appointment_date != null) ? data.last_appointment_date : "N/A" }}</span>
                    </div>
                </div>
                <div class="w-p40 l-h-14">
                    <h4 class="fw-800 mb-5">Location</h4>
                    <div class="d-flex pb-2">
                        <p class="mb-0 me-1 d-inline-block">Office:</p>
                        <span class="fw-600">{{ (data.office_name != null) ? data.office_name : "N/A" }}</span>
                    </div>
                    <div class="d-flex pb-2">
                        <p class="mb-0 me-1 d-inline-block">Room:</p>
                        <span class="fw-600">{{ (data.room_name != null) ? data.room_name : "N/A" }}</span>
                    </div>
                </div>
            </div>
            <div class="d-flex mx-0 l-h-14">
                <p class="mb-0 me-1 d-inline-block">Note:</p>
                <span class="fw-600 ">{{ (data.extra_notes != null) ? data.extra_notes : "N/A" }}</span>
            </div>
        </div>
    </div>
    <div
    class="media-list media-list-divided media-list-hover userprovid_scroll"
    style="max-height:260px;overflow:auto;" v-if="data.appointment_type=='Break'">
    <div class="media align-items-center d-block bb-1">
        <div class="flexbox flex-grow gap-items m-0">
            <div class="media-body">
                <h4 class="fw-800 mb-5">Break</h4>
                <div class="d-flex l-h-14 pb-2">
                    <p class="mb-0 me-1 d-inline-block">Current:</p>
                    <span class="fw-600"
                    >{{ data.schedule_date }}
                    <small>(for {{ data.duration }}min)</small></span
                    >
                </div>
                <div class="d-flex mx-0 l-h-14 pb-2">
                    <p class="mb-0 me-1 d-inline-block">Reason:</p>
                    <span class="fw-600 ">{{ (data.reason_for_visit != null) ? data.reason_for_visit : "N/A" }}</span>
                </div>
                <div class="d-flex mx-0 l-h-14">
                    <p class="mb-0 me-1 d-inline-block">Note:</p>
                    <span class="fw-600 ">{{ (data.extra_notes != null) ? data.extra_notes : "N/A" }}</span>
                </div>
            </div>


            <div class="w-p40 l-h-14">
                <h4 class="fw-800 mb-5">Location</h4>
                <div class="d-flex pb-2">
                    <p class="mb-0 me-1 d-inline-block">Office:</p>
                    <span class="fw-600">{{ (data.office_name != null) ? data.office_name : "N/A" }}</span>
                </div>
                <div class="d-flex pb-2">
                    <p class="mb-0 me-1 d-inline-block">Room:</p>
                    <span class="fw-600">{{ (data.room_name != null) ? data.room_name : "N/A" }}</span>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
</template>
<script>
export default {
    data() {
        return {
            data: {},
        };
    },
};
</script>