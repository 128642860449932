import {
  helpers,
  required,
  email,
  minLength,
  maxLength,
  sameAs,
  numeric,
} from "@vuelidate/validators";

const Agecvx = {
  namespaced: true,

  state: {
    tableData: {
      header: [
        "Age Group",
        "Vaccine Name",
        "CVX Code",
        "Actions",
      ],
    },
    key:0,
    edit:'',
    form: {},
    vuelidateExternalResults: {
      form: {
        agegroup:[],
        vaccine_name:[],
        cvx_code:[],
      },
    },
    validationRules: {
      form: {
        agegroup: {
          required: helpers.withMessage("Age Group is required", required),
        },
        vaccine_name:{
          required: helpers.withMessage("Vaccine Name is required", required),
        },
        cvx_code:{
          required: helpers.withMessage("CVX Code is required", required),
        }
      },
    },
    listUrl: "/age-cvx-relation/list",
    getEditUrl: "/age-cvx-relation/edit/:id",
    list: [],
  },
  getters: {
    getTableHeaders: (state) => state.header,

    getTableContents: (state) => state.list,
    getListURL: (state) => state.listUrl,
  },
  mutations: {},
  actions: {},
}

export default Agecvx;