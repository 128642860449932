<template>
  <div class="content-wrapper">
    <div class="container-full">
      <section class="content">
        <div class="row justify-content-between">
          <!-- sidebar -->
          <the-side-bar ></the-side-bar>
          <!-- content -->
          <component :is="$store.state.ScheduleHomeComp"></component>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import TheSideBar from "@/components/patients/Schedule/TheSidebar.vue";
import ScheduleAppointment from "@/components/patients/Schedule/Home.vue";
import AppointmentVitals from "@/components/patients/Schedule/AppointmentVitals.vue";
import AppointmentBilling from "@/components/patients/Schedule/AppointmentBilling.vue";
import AppointmentLogCommunication from "@/components/patients/Schedule/PhoneLog.vue";
import AppointmentEligibility from "@/components/patients/Schedule/AppointmentEligibility.vue";
import AppointmentInsuranceLog from "@/components/patients/Schedule/AppointmentInsuranceLog.vue";
import Auditlogs from "@/components/patients/Schedule/Auditlogs.vue";


export default {    
    components: {TheSideBar,ScheduleAppointment,AppointmentVitals,AppointmentBilling,AppointmentLogCommunication,AppointmentEligibility,AppointmentInsuranceLog,Auditlogs},
    data() {
        return {
        };
    },
    created() {
      if (this.$route.name === "PatientAppointment" || this.$route.name === "EditAppointment") {
        this.$store.state.ScheduleHomeComp = "ScheduleAppointment";
      }else if(this.$route.name === "AppointmentVitals"){
        this.$store.state.ScheduleHomeComp = "AppointmentVitals";
      }else if(this.$route.name === "AppointmentBilling"){
        this.$store.state.ScheduleHomeComp = "AppointmentBilling";
      }else if(this.$route.name === "AppointmentLogCommunication"){
        this.$store.state.ScheduleHomeComp = "AppointmentLogCommunication";
      }else if(this.$route.name === "AppointmentInsuranceLog"){
        this.$store.state.ScheduleHomeComp = "AppointmentInsuranceLog";
      }else if(this.$route.name === "AppointmentEligibility"){
        this.$store.state.ScheduleHomeComp = "AppointmentEligibility";
      }else if(this.$route.name === "Auditlogs"){
        this.$store.state.ScheduleHomeComp = "Auditlogs";
      }
    },
};
</script>

