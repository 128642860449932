import {
  helpers,
  required,
  email,
  minLength,
  maxLength,
  sameAs,
  numeric,
} from "@vuelidate/validators";

var quoteCheck =  /^[^‚”‟‛]+$/;
var regex = new RegExp(quoteCheck);
const quoteregex = (value) => !helpers.req(value) || regex.test(value);

const patient = {
  namespaced: true,

  state: {
    editID: "1000014",
    tableData: {
      header: [
        "Chart ID",
        "Provider",
        "Last Name",
        "First Name",
        "Home Phone",
        "Cell Phone",
        "Last Appt",
        "Next Appt",
        "Follow-up",

      ],
    },
    form: {},
    vuelidateExternalResults: {
      form: {
        first_name:[],
        last_name:[],
        email:[],
        home_phone:[],
        cell_phone:[],
        office_phone:[],
        alternate_email:[],
        prefix:[],
        suffix:[],
        primary_provider_id:[],
        status:[],
        office_id:[],
      },
    },
    validationRules: {
      form: {
        first_name: {
          required: helpers.withMessage("First Name is required", required),
          max: maxLength(35),
          quoteregex: helpers.withMessage("You have entered an invalid character.", quoteregex),
        },
        last_name:{
          required: helpers.withMessage("Last Name is required", required),
          max: maxLength(35),
          quoteregex: helpers.withMessage("You have entered an invalid character.", quoteregex),
        },
        email:{
          //required: helpers.withMessage("Email address is required", required),
          email: helpers.withMessage("Email address is not valid", email),
        },
        home_phone:{
          //numeric,
          min: minLength(10),
          max: maxLength(15),
        },
        cell_phone:{
          //numeric,
          min: minLength(10),
          max: maxLength(15),
        },
        office_phone:{
          //numeric,
          min: minLength(10),
          max: maxLength(15),
        },
        alternate_email:{
          email: helpers.withMessage("Email address is not valid", email),
        },
        prefix:{
          max: maxLength(4),
        },
        suffix:{
          max: maxLength(4),
        },
        primary_provider_id:{
          required: helpers.withMessage("Primary Provider is required", required),
        },
        status:{
          required: helpers.withMessage("Status is required", required),
        },
        office_error: false


      },
    },
    listUrl: "/patient/list",
    getEditUrl: "/patient/:id",
    list: [],
  },
  getters: {
    getTableHeaders: (state) => state.header,

    getTableContents: (state) => state.list,
    getListURL: (state) => state.listUrl,
  },
  mutations: {},
  actions: {},
}

export default patient;