<template>
	<div class="box p-15">
		<div class="mdc-tab">
			<TabbarComp routefrom="BillingContract"></TabbarComp>
			<div class="tab-content">
				<div class="pageheading">
					<div class="row justify-content-between align-items-center">
						<div class="col-6 col-sm-8 col-md-8 col-lg-8">
							<h2 class="heading2 my-10">Contracts</h2>
						</div>
						<div class="col-6 col-sm-4 col-md-4 col-lg-3 text-end">
							<a
							href="javascript:void(0)"
							class="waves-effect waves-light btn btn-info"
							data-bs-toggle="modal"
							data-bs-target="#addNewContract"
							@click="resetForm"
							><i class="fa fa-plus fs-14"></i> Contract</a
							>
						</div>
					</div>
				</div>
				<div class="table-responsive vitals-input-table">
					<table class="table">
						<thead>
							<tr>
								<th></th>
								<th>Name</th>
								<th>Status</th>
								<th>Consulation Fee</th>
								<th>Contract Amount</th>
								<th>Received Amount</th>
								<th>Refund Amount</th>
								<th>Due Amount</th>
								<th>Number Of Payment</th>
								<th>Start Date</th>
								<th>End Date</th>
								<th>Action</th>
							</tr>
						</thead>
						<tbody v-for="(item, index) in contract_list" :key="index" v-if="contract_list.length > 0">
							<tr>
								<td>
								    <a href="javascript:void(0)" class="waves-effect waves-light btn btn-info-light btn-xs allopenbtn" @click="getContract(item.id)" v-bind:id="'add'+item.id">
								        <i class="fa fa-plus"></i>
								    </a>
								    <a href="javascript:void(0)" class="waves-effect waves-light btn btn-danger-light btn-xs allclosebtn d-none" @click="contractSubClose(item.id)" v-bind:id="'min'+item.id">
								        <i class="fa fa-minus"></i>
								    </a>
								</td>
								<td>{{ item.plan_name }}<!-- ({{ item.order_reference_no }}) --></td>
								<td>
									<span v-if="item.status == 'Running' " class="bg-info-light rounded d-inline-block text-center l-h-n py-1 px-5 fs-14">Running  </span>  
									<span v-else-if="item.status == 'Completed' " class="bg-success-light rounded d-inline-block text-center l-h-n py-1 px-5 fs-14">Expired  </span>  
									<span v-else-if="item.status == 'Pending' " class="bg-warning-light rounded d-inline-block text-center l-h-n py-1 px-5 fs-14">Pending  </span>  
									<span v-else-if="item.status == 'Stoped' " class="bg-danger-light rounded d-inline-block text-center l-h-n py-1 px-5 fs-14">Cancelled  </span>  
									<span v-else-if="item.status == 'Waiting For Signature' " class="bg-primary-light rounded d-inline-block text-center l-h-n py-1 px-5 fs-14">Waiting For Signature</span>  
									<!-- <span v-else-if="item.status == 'Pending' " class="bg-danger-light rounded d-inline-block text-center l-h-n py-1 px-5 fs-14">{{ item.status }}</span> -->
									<span v-else  class="bg-info-light rounded d-inline-block text-center l-h-n py-1 px-5 fs-14">{{ item.status }}</span>
								</td>
								<td>{{  $filters.toUSD(item.consultation_price) }}</td>
								<td>{{  $filters.toUSD(item.contract_final_amount) }}</td>
								<td>{{  $filters.toUSD(item.received_amount) }}</td>
								<td>{{  $filters.toUSD(item.refund_amount) }}</td>
								<td>{{ $filters.toUSD((item.contract_final_amount - (item.received_amount - parseFloat(item.refund_amount))) ) }}</td>
								<td>{{ item.number_of_payment }}</td>
								<td>{{ $filters.displayDateTimeLocal(item.contract_start,'',false,"ddd, MM-DD-YYYY",true) }}</td>
								<td>{{ $filters.displayDateTimeLocal(item.contract_end,'',false,"ddd, MM-DD-YYYY",true) }}</td>
								<td>
									<td>
										<div class="d-flex">
											<Popper arrow content="Edit Contract" hover="true" class="helptips">
												<a href="javascript:void(0);" @click="editContract(item.id)" class="waves-effect waves-circle btn btn-circle btn-info btn-xs me-1" title=""
												><i class="fa fa-pencil"></i></a>
											</Popper>
											<Popper v-if="!item.patient_sign" arrow content="Send Contract For Signature" hover="true" class="helptips">
											     <a href="javascript:void(0)" class="waves-effect waves-circle btn btn-circle btn-info btn-xs  me-1" @click="sendContractInvitation(item.id)">
											        <i class="fa fa-paper-plane"></i>
											     </a>
											</Popper>
											<Popper arrow content="Contact Preview" hover="true" class="helptips">
												<a href="javascript:void(0);" @click="previewContract(item.id)" class="waves-effect waves-circle btn btn-circle btn-info btn-xs me-1" title=""
												><i class="fa fa-eye"></i></a>
											</Popper>
										</div>
									</td>
								</td>
							</tr>
							<tr class="d-none contract_details_hide" v-bind:id="'contract_details_'+item.id">
							    <td class="b-1" colspan="12" style="background: #f8f9fa;">
							        <table class="table">
							             <thead>
							                <tr>
							                    <th>Date</th>
							                    <th>Amount</th>
							                    <th>Charged</th>
							                    <th>Action</th>
							                </tr>
							            </thead>
							            <tbody>
							                <tr v-for="(sub_items,index) in contractsublist" v-if="contractsublist.length > 0">
							                    <td>{{$filters.displayDateTimeLocal(sub_items.input_date.value,'',false,"ddd, MM-DD-YYYY",true)}}</td>
							                    <td>
							                    	${{sub_items.input_amount.value}}
							                    	<Popper arrow :content="sub_items.comments" hover=true class="helptips amt_comment" v-if="sub_items.comments">
							                    		<i class="fa fa-info-circle fs-12"></i>
							                    	</Popper>
							                	</td>
							                    <td>{{sub_items.is_charged == 1 ? 'Yes' : 'No' }}</td>
							                    <td >
							                    	<Popper arrow content="View Contract Receipt" hover=true class="helptips">
							                        <a href="javascript:void(0)" class="waves-effect waves-circle btn btn-circle btn-info btn-xs me-1" @click ="printSubContract(sub_items.edit_id,item.id)" v-if="sub_items.is_charged == 1">
							                            <i class="fa fa-eye"></i>
							                        </a>
							                        </Popper>
							                        <Popper arrow content="Email Contract Receipt" hover=true class="helptips">
								                        <a href="javascript:void(0)" class="waves-effect waves-circle btn btn-circle btn-xs" :class="sub_items.is_receipt_send ? 'btn-primary' : 'btn-info' " @click="sendContractSub(sub_items.edit_id,item.id)" v-if="sub_items.is_charged == 1">
								                            <i class="fa fa-paper-plane"></i>
								                        </a>
						                        	</Popper>
						                        	<Popper arrow content="Refund" hover=true class="helptips">
															<a href="javascript:void(0)" class="waves-effect waves-circle btn btn-circle btn-info btn-xs me-1" @click ="refundContractAmt(sub_items)" v-if="sub_items.refund_amount == 0 && sub_items.is_charged == 1">
																<i class="fa fa-undo"></i>
															</a>
													</Popper>
													<span v-if="sub_items.refund_amount > 0" class="badge font-bold bg-success">Refunded</span>
							                    </td> 
							                </tr>
							                <tr v-if="contractsublist.length == 0">
							                	<td colspan="4"><center>No Record Found.</center></td>
							                </tr>
							            </tbody>
							        </table>
							    </td>
							</tr>
						</tbody>
						<tbody v-if="contract_list.length == 0">
							<tr>
								<td colspan="10"><center>No Record Found.</center></td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
	<div
	id="addNewContract"
	class="modal fade"
	tabindex="-1"
	role="dialog"
	aria-labelledby="myModalLabel"
	aria-hidden="true"
	>
	<div class="modal-dialog modal-lg" style="max-width: 800px;">
		<div class="modal-content">
			<div class="modal-header">
				<h3 class="modal-title font-bold m-0"> {{ this.form.contract_id == '' ? 'New Contract' : 'Edit Contract'}}  </h3>
			</div>
			<div class="modal-body">
				<div class="bg-danger-light p-5 d-inline-block mb-2 rounded text-center" v-if="serverError">{{ serverError }}</div>
				<div class="row">
					<div class="col-12 col-sm-12 col-md-12 col-lg-8">
						<div class="form-group row">
							<label class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-5 col-form-label mb-0 text-sm-end">Program <small class="text-danger asterisksign">*</small></label>
							<div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-7">
								<div class="controls d-flex">
									<select class="form-select form-control" @change="setCurrentPlan($event)" :disabled="form.is_charg_started"  v-model="form.plan_id">
										<option value="">Select Program</option>
										<option v-for="(item,index) in subscriptionPlanList" :value="item.id" :data-val="JSON.stringify(item)">{{item.plan_name}}</option>
									</select>
									<a href="javascript:void(0)" @click="redirectToPlan" class="waves-effect waves-light btn btn-info-light pt-10 ms-1 btn-xs"><i class="fa fa-plus fs-14"></i></a>
								</div>
								<div class="error-msg">
									<ul class="mb-0 p-0 fs-14">
										<li class="d-block" v-for="(error, index) of v$.form.plan_id.$errors" :key="index">
											{{ error.$message }}
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<label class="col-form-label col-12 col-sm-12 col-md-12 col-lg-4">Program Amount : {{ $filters.toUSD(form.plan_amount) }}</label>
				</div>
				<div class="row">
					<div class="col-12 col-sm-12 col-md-12 col-lg-8">
						<div class="form-group row">
							<label class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-5 col-form-label mb-0 text-sm-end">Number Of Payments <small class="text-danger asterisksign">*</small></label>
							<div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-7">
								<div class="controls">
									<select class="form-select form-control" id="id_number_of_payment" :disabled="form.is_charg_started" v-model="v$.form.number_of_payment.$model" @change="createPaymentRepeat($event)">
										<option value="">Select Frequency</option>
										<option value="1" data-hike="0">1</option>
										<option value="2" data-hike="0">2</option>
										<option value="3" data-hike="5">3</option>
										<option value="6" data-hike="10">6</option>
										<option value="12" data-hike="20">12</option>
									</select>
								</div>
								<div class="error-msg">
									<ul class="mb-0 p-0 fs-14">
										<li class="d-block" v-for="(error, index) of v$.form.number_of_payment.$errors" :key="index">
											{{ error.$message }}
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<label class="col-form-label col-12 col-sm-12 col-md-12 col-lg-4">Contract Amount : {{ $filters.toUSD(form.contract_amount) }}</label>
				</div>
				<div class="row">
					<div class="col-12 col-sm-12 col-md-12 col-lg-8">
						<div class="form-group row">
						    <label class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-5 col-form-label mb-0 text-sm-end">Bill original plan price</label>
						    <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-7">
						        <input
						          type="checkbox"
						          id="base_program_price"
						          :checked="form.base_program_price"
						          v-model="form.base_program_price"
						          :value="form.base_program_price"
						          class="filled-in chk-col-info"
								  @change="basePrice($event)"
						        />
						        <label for="base_program_price" class="m-0 text-start"></label>
						    </div>
						</div>
					</div>
					<label class="col-form-label col-12 col-sm-12 col-md-12 col-lg-4">Final Amount : {{ $filters.toUSD(form.contract_final_amount) }}</label>
				</div>
				<div class="row">
					<div class="col-12 col-sm-12 col-md-12 col-lg-8">
						<div class="form-group row">
						  <label class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-5 col-form-label mb-0 text-sm-end">Discount</label>
						  <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-7">
						    <div class="controls availdate position-relative">
						      <ejs-numerictextbox format="###" id="contract_discount_value" :disabled="form.is_charg_started" :value="form.contract_discount_value"  :step="step" :min="min" @change="calcFinalAmount"></ejs-numerictextbox>
						      <small class="form-text text-muted position-absolute" style="right: 60px;top: 2px;">%</small>
						    </div>
						  </div>
						</div>

						<!-- <div class="form-group row">
							<label class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-5 col-form-label mb-0 text-sm-end">Discount</label>
							<div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-7">
								<select name="select" required="" class="form-select form-control" :disabled="form.is_charg_started"  v-model="form.contract_discount_value">
									<option value="0">Select Discount</option>
									<option value="5">5%</option>
									<option value="10">10%</option>
									<option value="15">15%</option>
									<option value="20">20%</option>
								</select>
							</div>
						</div> -->
					</div>
				</div>
				<div class="row">
					<div class="col-12 col-sm-12 col-md-12 col-lg-8">
						<div class="form-group row">
							<label class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-5 col-form-label mb-0 text-sm-end">Payment Type <small class="text-danger asterisksign">*</small></label>
							<div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-7">
								<div class="controls d-flex">
									<select class="form-select form-control me-2" v-model="form.payment_type">
										<option value="">-Payment Type-</option>
										<option value="Credit Card">Credit Card</option>
										<option :label="'Wallet ('+$filters.toUSD($store.state.patientDetail.wallet_amount)+')'" value="Wallet">Wallet ({{ $filters.toUSD(this.$store.state.patientDetail.wallet_amount) }}) </option>
									</select>
									<Popper arrow content="Select wallet as a payment method if financing is provided by a third-party financial company" :hover="true" class="helptips cursor-pointer">
										<i class="fa fa-info-circle fs-14"></i>
									</Popper>
								</div>
								<div class="error-msg">
									<ul class="mb-0 p-0 fs-14">
										<li class="d-block" v-for="(error, index) of v$.form.payment_type.$errors" :key="index">
											{{ error.$message }}
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-12 col-sm-12 col-md-12 col-lg-8" v-if="form.payment_type == 'Credit Card' ">
						<div class="form-group row">
							<label class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-5 col-form-label mb-0 text-sm-end">Creditcard <small class="text-danger asterisksign">*</small></label>
							<div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-7">
								<div class="controls">
									<select class="form-select form-control" v-model="form.cctoken_id">
										<option value="">Select Creditcard</option>
										<option v-for="(item,index) in ccTokenData" :value="item.id" :data-val="item.id" :disabled="item.is_expired == true" >{{item.card_label}}</option>
									</select>
								</div>
								<div class="error-msg">
									<ul class="mb-0 p-0 fs-14">
										<li class="d-block" v-for="(error, index) of v$.form.cctoken_id.$errors" :key="index">
											{{ error.$message }}
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-12 col-sm-12 col-md-12 col-lg-8">
						<div class="form-group row">
						    <label class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-5 col-form-label mb-0 text-sm-end">Is Renewal?</label>
						    <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-7">
						        <input
						          type="checkbox"
						          id="is_symptom_checkbox"
						          :checked="form.is_renewal"
						          v-model="form.is_renewal"
						          :value="form.is_renewal"
						          class="filled-in chk-col-info"
						        />
						        <label for="is_symptom_checkbox" class="m-0 text-start"></label>
						    </div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-12 col-sm-12 col-md-12 col-lg-8">
						<div class="form-group row">
							<label class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-5 col-form-label mb-0 text-sm-end">Contract Status <small class="text-danger asterisksign">*</small></label>
							<div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-7">
								<div class="controls">
									<select class="form-select form-control" v-model="form.status">
										<option value="">Select Status</option>
										<option value="Pending">Pending</option>
										<option value="Waiting For Signature">Waiting For Signature</option>
										<option value="Running">Running</option>
										<option value="Stoped">Cancelled</option>
										<option value="Completed">Expired</option>
									</select>
								</div>
								<div class="error-msg">
									<ul class="mb-0 p-0 fs-14">
										<li class="d-block" v-for="(error, index) of v$.form.status.$errors" :key="index">
											{{ error.$message }}
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row" v-if="form.contract_id">
					<div class="col-12 col-sm-12 col-md-12 col-lg-8">
						<div class="form-group row">
							<label class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-5 col-form-label mb-0 text-sm-end">Contract Start</label>
							<div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-7">
								<div class="controls">
									<div class="availdate w-p100">
										<ejs-datepicker 
											:value="setDateFormat(form.contract_start)" 
											id="contract_start" 
											:format="dateFormat" 
											placeholder="Contract Start"
											@change="setStartEndDate('contract_start')" 
											@click="setStartEndDate('contract_start')" 
											:blur="setStartEndDate('contract_start')"
											:strictMode='strict' 
											:showClearButton='false' 
											:openOnFocus='true'>
										</ejs-datepicker>
									</div>
								</div>
								<!-- <div class="error-msg">
									<ul class="mb-0 p-0 fs-14">
										<li class="d-block" v-for="(error, index) of v$.form.contract_start.$errors" :key="index">
											{{ error.$message }}
										</li>
									</ul>
								</div> -->
							</div>
						</div>
					</div>
				</div>
				<div class="row" v-if="form.contract_id">
					<div class="col-12 col-sm-12 col-md-12 col-lg-8">
						<div class="form-group row">
							<label class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-5 col-form-label mb-0 text-sm-end">Contract End </label>
							<div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-7">
								<div class="controls">
									<div class="availdate w-p100">
										<ejs-datepicker 
											:value="setDateFormat(form.contract_end)" 
											id="contract_end" 
											:format="dateFormat" 
											placeholder="Contract End"
											@change="setStartEndDate('contract_end')" 
											@click="setStartEndDate('contract_end')" 
											:blur="setStartEndDate('contract_end')"
											:strictMode='strict' 
											:showClearButton='false' 
											:openOnFocus='true'>
										</ejs-datepicker>
									</div>
								</div>
								<!-- <div class="error-msg">
									<ul class="mb-0 p-0 fs-14">
										<li class="d-block" v-for="(error, index) of v$.form.contract_end.$errors" :key="index">
											{{ error.$message }}
										</li>
									</ul>
								</div> -->
							</div>
						</div>
					</div>
				</div> 
				<div class="row">
					<div class="col-12 col-sm-12 col-md-12 col-lg-8">
						<div class="form-group row">
						    <label class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-5 col-form-label mb-0 text-sm-end">Consultation Required?</label>
						    <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-7">
						        <input
						          type="checkbox"
						          id="is_consultation_required_checkbox"
						          :checked="form.is_consultation_required"
						          v-model="form.is_consultation_required"
						          :value="form.is_consultation_required"
						          class="filled-in chk-col-info"
						        />
						        <label for="is_consultation_required_checkbox" class="m-0 text-start"></label>
						    </div>
						</div>
					</div>
				</div>
				<div class="row" v-if="form.is_consultation_required == true">
					<div class="col-12 col-sm-12 col-md-12 col-lg-8">
						<div class="form-group row">
							<label class="col-12 col-sm-5 col-md-5 col-lg-5 col-xl-5 col-form-label mb-0 text-sm-end">Consultation <small class="text-danger asterisksign">*</small></label>
							<div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-7">
								<div class="controls">
									<select class="form-select form-control" v-model="form.consultation_lable" @change="changeConsultation">
										<option value="">-Consultation Fee-</option>
										<option value="Paid on Visit: $450.00">Paid on Visit: $450.00</option>
										<option value="Paid on Visit: $300.00">Paid on Visit: $300.00</option>
										<option value="Paid on Visit: $200.00">Paid on Visit: $200.00</option>
										<option value="Paid on Visit: Other">Paid on Visit: Other</option>
										<option value="Fee: $450.00">Fee: $450.00</option>
										<option value="Fee: $300.00">Fee: $300.00</option>
										<option value="Fee: $200.00">Fee: $200.00</option>
										<option value="Fee: Other">Fee: Other</option>
									</select>
								</div>
								<div class="error-msg">
									<ul class="mb-0 p-0 fs-14">
										<li class="d-block" v-for="(error, index) of v$.form.consultation_lable.$errors" :key="index">
											{{ error.$message }}
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row" v-if="form.consultation_lable == 'Fee: Other' || form.consultation_lable == 'Paid on Visit: Other' ">
					<div class="col-12 col-sm-12 col-md-12 col-lg-8">
						<div class="form-group row">
							<label class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-5 col-form-label mb-0 text-sm-end">Consultation Amount <small class="text-danger asterisksign">*</small></label>
							<div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-7">
								<div class="controls">
									<input id="consultation_value" type="text" v-model="form.consultation_value" v-on:keypress="numbersOnly" class="form-control" placeholder="Consultation Amount" @change="changeConsulationValue" autocomplete="off">
								</div>
								<div class="error-msg">
									<ul class="mb-0 p-0 fs-14">
										<li class="d-block" v-for="(error, index) of v$.form.consultation_value.$errors" :key="index">
											{{ error.$message }}
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-12 col-sm-12 col-md-12 col-lg-8">
						<div class="form-group row">
							<label class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-5 col-form-label mb-0 text-sm-end">Wellness Coordinator <small class="text-danger asterisksign">*</small></label>
							<div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-7">
								<div class="controls">
									<input id="wellness_coordinator" type="text" v-model="form.wellness_coordinator" class="form-control" placeholder="Wellness Coordinator" autocomplete="off">
								</div>
								<div class="error-msg">
									<ul class="mb-0 p-0 fs-14">
										<li class="d-block" v-for="(error, index) of v$.form.wellness_coordinator.$errors" :key="index">
											{{ error.$message }}
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-12 col-sm-12 col-md-12 col-lg-8">
						<div class="form-group row align-items-center">
							<label class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-5 col-form-label mb-0 text-sm-end">Email patient on approval</label>
							<div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-7">
								<div class="controls">
									<button type="button" data-on="Ready" data-off="Not Ready" @click="onToggleDeclChange(form, $event)" id="email_patient_on_decline" class="btn btn-toggle rounded30-im mx-100"  v-bind:class="[form.email_patient_on_decline == true ? 'active' : '']" data-bs-toggle="button" aria-pressed="true" autocomplete="off">
										<div class="handle rounded30-im"></div>
									</button>
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 col-sm-12 col-md-12 col-lg-8">
						<div class="form-group row align-items-center">
							<label class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-5 col-form-label mb-0 text-sm-end">Email patient on decline</label>
							<div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-7">
								<div class="controls">
									<button type="button"  data-on="Ready" data-off="Not Ready" @click="onToggleAprlChange('email_patient_on_approval', $event)" id="email_patient_on_approval" class="btn btn-toggle  rounded30-im mx-100"  v-bind:class="[form.email_patient_on_approval == 1 ? 'active' : '']" data-bs-toggle="button" aria-pressed="true" autocomplete="off">
										<div class="handle rounded30-im"></div>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div id="rooms" v-for="(input, index) in form.contract_items" :key="input.id">
					<div class="row mb-5">
						<div class="col-6 col-sm-5 col-md-4 col-lg-4 col-xl-5 d-flex align-items-center">
							<span class="badge badge-light rounded font-bold me-2 success w-40" :title="input.is_charged == 1 ? 'Paid':''">{{ index+1 }}</span>
							<div class="availdate w-p100">
								<ejs-datepicker 
									:value="setDateFormat(input.input_date.value)" 
									:id="input.input_date.id" 
									:format="dateFormat" 
									placeholder="Select Date" 
									@change="setDate(input.input_date.id,index)" 
									@click="setDate(input.input_date.id,index)" 
									:strictMode='strict' 
									:showClearButton='false' 
									:blur="setDate(input.input_date.id,index)"
									:readonly="input.is_charged"
									:openOnFocus='true'>
								</ejs-datepicker>
								<div class="error-msg">
									<ul class="mb-0 p-0 fs-14">
										<li class="d-none" :id="'contract-date-'+index">
											This field is required.
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div class="col-4 col-sm-5 col-md-4 col-lg-4 col-xl-4" :class="form.contract_id != '' ? 'col-xl-5' : 'col-xl-6' ">
							<input type="text" class="form-control" :readonly="input.is_charged" v-on:keypress="numbersOnly" v-model="input.input_amount.value" :id="input.input_amount.id" :placeholder="input.input_amount.label"/>
						</div>
						<div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2" v-if="form.contract_id != '' && input.is_charged">
							<span class="badge font-bold py-7" :class="input.is_charged ? 'bg-success' : 'bg-danger'">{{ input.is_charged ? 'Paid' : 'Pending' }}</span>
						</div>
					</div>
				</div>
			</div>
		<div class="modal-footer">
			<button
			type="button"
			class="waves-effect waves-light btn btn-danger"
			data-bs-dismiss="modal"
			>
			Cancel
		</button>
		<form-btn title="Save" btnCls="btn-info" @click="saveContract()"></form-btn>
	</div>
</div>
</div>
</div>

<!--show Make Refund start-->
	<div class="modal fade" id="makerefund" tabindex="-1" role="dialog" aria-labelledby="makerefund" aria-hidden="true">
		<div class="modal-dialog" style="max-width: 600px;">
			<div class="modal-content">
				<div class="modal-header">
					<h3 class="modal-title fw-900 mt-0">Make Refund</h3>
			   </div>
			   <div class="modal-body">
					<contract-refund-dialog v-if="contract_sub_id" :chargeAmount="charge_amount" :contractSubId="contract_sub_id" :key="contract_sub_id"></contract-refund-dialog>
			   </div>
			   <div class="modal-footer">
					<button type="button" class="waves-effect waves-light btn btn-danger" data-bs-dismiss="modal" @click="refundDialogClose">Close</button>
			   </div>
			</div>
		</div>
	</div>
<!--show Make Refund end-->


<!--show viewReceipt start-->
<div class="modal" id="viewReceipt" tabindex="-1">
   <div class="modal-dialog modal-md" style="max-width:75%">
      <div class="modal-content">
         <div class="modal-header">
            <button type="button" class="btn-close btn-xs" data-bs-dismiss="modal" aria-label="Close"></button>
         </div>
         <div class="modal-body">
            <vue-pdf-viewer height="1050px" :url="receipt_url" v-if="embed=0">
            </vue-pdf-viewer>
            <iframe :src="receipt_url" width="100%" height="500px" v-if="embed=1" />
            <img srd="images/1.gif" v-else> 
         </div>
      </div>
   </div>
</div>
<!--show viewReceipt end-->
</template>
<script>
	import { mapGetters, mapActions } from "vuex";
	import permissionMixins from "@/scripts/mixins/permission.js";
	import axios from "@/scripts/axios.js";
	import submitMixins from "@/scripts/mixins/submitMixins.js";
	import useVuelidate from "@vuelidate/core";
	import FormBtn from "@/components/base/formFields/formBtn.vue";
	import ContractRefundDialog from "@/components/patients/details/Payment/ContractRefundDialog.vue";
	import Popper from "vue3-popper";
	import moment from "moment";
	import TabbarComp from "@/components/patients/details/Payment/TabbarComp.vue";
	import {
		required,
		requiredIf,
		helpers,
		maxValue,
	} from "@vuelidate/validators";
	export default {
		components: {
			FormBtn,Popper,ContractRefundDialog, TabbarComp
		},
		setup() {
			return { v$: useVuelidate() };
		},
		mixins: [submitMixins,permissionMixins],
		data() {
			return {
				is_production_env : process.env.VUE_APP_NODE_ENV == 'production',
				duration_value:0,
				cal_value:0,
				min: 0,
				step: 1,
				form : {
					contract_id : '',
					plan_id : '',
					status : '',
					plan_amount : 0,
					contract_amount : 0,
					contract_discount_type : 'percentage',
					contract_discount_value : 0,
					contract_final_amount : 0,
					cctoken_id : '',
					contract_start : '',
					contract_end : '',
					wellness_coordinator : '',
					number_of_payment : '',
					email_patient_on_decline : true,
					email_patient_on_approval : true,
					contract_items: [],
					hike_percentage: 0,
					is_renewal: 0,
					is_consultation_required: 0,
					consultation_price: 0,
					consultation_lable: '',
					consultation_value: '',
					payment_type: '',
					is_charg_started: false,
					patient_id : this.$store.state.patientDetail.patient_id,
					patient_unique_id : this.$store.state.patientDetail.patient_unique_id,
					base_program_price: false,
				},
				strict: true,
				ccTokenData: true,
				dateFormat: 'MM-dd-yyyy',
				contract_list: [],
				subscriptionPlanList: [],
				selectedPlanInfo: '',
				serverError: "",
				contractsublist:[],
				is_edit_request_call: false,
				receipt_url: '',
				embed: 0,
				contract_sub_id: 0,
				charge_amount: 0,
			};
		},
		validations() {
			return {
				form: {
					plan_id: {
						required: helpers.withMessage("Plan is required.", required),
					},
					cctoken_id: {
					  required: requiredIf(function() {
					    return this.form.payment_type == 'Credit Card' ? true : false;
					  }),
					},
					status: {
						required: helpers.withMessage("Contract Status is required.", required),
					},
					payment_type: {
						required: helpers.withMessage("Payment type is required.", required),
					},
					consultation_lable: {
					  required: requiredIf(function() {
					    return this.form.is_consultation_required == true ? true : false;
					  }),
					},
					consultation_value: {
					  required: requiredIf(function() {
					    return (this.form.consultation_lable == 'Fee: Other' || this.form.consultation_lable == 'Paid on Visit: Other') ? true : false;
					  }),
					},
					/*contract_start: {
						required: helpers.withMessage("Contract start date is required.", required),
					},
					contract_end: {
						required: helpers.withMessage("Contract end date is required.", required),
					},*/
					wellness_coordinator: {
						required: helpers.withMessage(" Wellness coordinator is required.", required),
					},
					number_of_payment: {
						required: helpers.withMessage("Number Of Payments is required.", required),
						maxValue: maxValue(50),
					},
				},
			};
		},
		async created() {
		},
		watch: {
		},
		computed : {
			...mapGetters("auth", ["user"]),
		},
		mounted() {
			this.fetchPatientToken();
			this.fetchPatientContract();
			this.fetchSubscriptionPlan();
		},
		methods: {
			changeConsulationValue() {
				if (this.form.consultation_lable == 'Fee: Other') {
					this.form.consultation_price = parseFloat(this.form.consultation_value);
				} else {
					this.form.consultation_price = 0;
				}
				this.calcFinalAmount();
				// console.log(this.form);
			},
			changeConsultation() {
				this.form.consultation_value = 0;
				if (this.form.consultation_lable == 'Fee: $450.00') {
					this.form.consultation_price = 450;
				} else if (this.form.consultation_lable == 'Fee: $300.00') {
					this.form.consultation_price = 300;
				} else if (this.form.consultation_lable == 'Fee: $200.00') {
					this.form.consultation_price = 200;
				} else {
					this.form.consultation_price = 0;
				}
				this.calcFinalAmount();
			},
			printSubContract (edit_id, contract_id) {
				this.getSrc(edit_id,contract_id);
				$("#viewReceipt").modal("show");
			},
			async getSrc(edit_id, contract_id) {
				
				this.$store.state.loader = true;
				const res = await fetch(process.env.VUE_APP_API_BASE_URL+'/contract-billing/printContractSub', {
					method: 'POST',
					headers: {
						'Authorization' : 'Bearer '+localStorage.getItem('authToken'),
						'Content-Type' : 'application/json'
					},
					body: JSON.stringify({ id: edit_id, contract_id : contract_id  }),
				});
				const blob = await res.blob();
				const urlObject = URL.createObjectURL(blob);
				if (/Android|webOS|Opera Mini/i.test(navigator.userAgent)) {
					$("#viewReceipt").modal("hide");
					window.open(urlObject, '_blank', 'height=600,width=1200');
				} else {
					this.receipt_url = urlObject;
					this.browserCheck();
				}
				this.$store.state.loader = false;
			},
			browserCheck(){
				// Opera 8.0+
				var isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
			
				// Firefox 1.0+
				var isFirefox = typeof InstallTrigger !== 'undefined';
			
				// Safari 3.0+ "[object HTMLElementConstructor]" 
				var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));
			
				// Internet Explorer 6-11
				var isIE = /*@cc_on!@*/false || !!document.documentMode;
			
				// Edge 20+
				var isEdge = !isIE && !!window.StyleMedia;
			
				// Chrome 1 - 79
				var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
			
				// Edge (based on chromium) detection
				var isEdgeChromium = isChrome && (navigator.userAgent.indexOf("Edg") != -1);
			
				// Blink engine detection
				var isBlink = (isChrome || isOpera) && !!window.CSS;
			
				if (isChrome==true) {
					this.embed = 0;
				} else {
					this.embed = 1;
				}
			},
			sendContractSub(edit_id, contract_id) {
				this.$store.state.loader = true;
				axios
				.post('contract-billing/printContractSub',{id : edit_id ,contract_id : contract_id , send_email : true })
				.then((response) => {
					this.$store.state.loader = false;
					this.getContract(contract_id);
					this.moshaToast(response.data.data, "success");
				})
				.catch((error) => {
					this.$store.state.loader = false;
					this.moshaToast(error.response.data.message, "danger");
				});
			},
			refundContractAmt (info) {
				$("#makerefund").modal('show');
				this.contract_sub_id = info.edit_id;
				this.charge_amount = info.input_amount.value;
			},
			refundDialogClose() {
				this.fetchPatientContract();
				this.contract_sub_id = '';
				this.charge_amount = '';
			},
			billingDialog(){
				this.$store.state.PaymentGatewayMigration.hidemodal = false;
				$("#makerefund").modal('hide');
				$('.contract_details_hide').addClass('d-none');
				$('.allclosebtn').addClass('d-none');
				$('.allopenbtn').removeClass('d-none');
				this.fetchPatientContract();
				this.contract_sub_id = '';
				this.charge_amount = '';
			},
			contractSubClose(id){
			    $("#add"+id).removeClass('d-none');
			    $("#min"+id).addClass('d-none');
			    $("#contract_details_"+id).addClass('d-none');
			},
			getContract(id){
			    $('.contract_details_hide').addClass('d-none');
			    $('.allclosebtn').addClass('d-none');
			    $('.allopenbtn').removeClass('d-none');
			    var contract_details_id = "#contract_details_"+id;
			    $(contract_details_id).removeClass('d-none');
			    $("#add"+id).addClass('d-none');
			    $("#min"+id).removeClass('d-none');
			    this.$store.state.loader = true;
			    this.$store.state.loader = true;
			    axios
			      .post(`/contract-billing/editContract/${id}`,{ patient_id : this.$store.state.patientDetail.patient_id })
			      .then((response) => {
			      	this.$store.state.loader = false;
			      	//this.is_edit_request_call = true;
			      	this.contractsublist = response.data.data.contract_items;
			    }).catch((error) => {
			    	this.$store.state.loader = false;
			    	this.moshaToast(error.response.data.message, "danger");
			    });
			},
			redirectToPlan() {
				$("#addNewContract").modal("hide");
				this.$router.push({ name : "SubscriptionPlanList" });
			},
			calcFinalAmount() {
				// if(!this.is_edit_request_call) {
					this.form.contract_discount_value = $('#contract_discount_value').val();
					var final_price = this.form.plan_amount;
					this.form.contract_amount = final_price;
					if (this.form.hike_percentage > 0) {
						final_price = (parseFloat(final_price)+parseFloat(final_price*this.form.hike_percentage/100));
						this.form.contract_amount = final_price;
					}
					if (this.form.contract_discount_value >= 0) {
						final_price = (parseFloat(final_price)-parseFloat(final_price*this.form.contract_discount_value/100));
					}
					if (parseFloat(this.form.consultation_price) > 0) {
						final_price = (final_price + parseFloat(this.form.consultation_price));
					}
					// console.log(final_price);
					this.form.contract_final_amount = Math.round(final_price.toFixed(2));
					
					var freq_price = Math.round((this.form.contract_final_amount/this.form.number_of_payment).toFixed(2));
					this.form.contract_items.forEach((value, index) => {
						value.input_amount.value = freq_price;
					});
				// }
				this.is_edit_request_call = false;
			},
			setCurrentPlan ($event) {
				var options = $event.target.options;
				if (options.selectedIndex > -1) {
					this.selectedPlanInfo = JSON.parse(options[options.selectedIndex].getAttribute('data-val'));
					if (this.selectedPlanInfo) {
						this.form.contract_amount = this.selectedPlanInfo.plan_final_amount;
						this.form.plan_amount = this.selectedPlanInfo.plan_final_amount;
						this.form.contract_final_amount = this.selectedPlanInfo.plan_final_amount;
						$('#id_number_of_payment').val(1).trigger('change');
					} else {
						this.form.contract_amount = 0;
						this.form.plan_amount = 0;
						this.form.contract_final_amount = 0;
					}
				} else {
					this.selectedPlanInfo = '';
					this.form.contract_amount = 0;
					this.form.plan_amount = 0;
					this.form.contract_final_amount = 0;
				}
				this.calcFinalAmount();
			},
			changeTab (components) {
				this.$store.state.patientDetailsComp = components;
				let props = this.$router.resolve({
					name: components,
					params: { id: this.$route.params.id},
				});
				history.pushState(null, '',props.href);
				/*if (history.pushState && props) {
					if (this.hash=='' || this.hash==null || typeof this.hash === undefined || typeof this.hash === 'undefined') {
					} else {
						history.pushState(null, '',props.href+"/#"+this.hash);
					}
				}*/
			},
			resetForm() {
				this.form.contract_id = '';
				this.form.plan_id = '';
				this.form.status = 'Pending';
				this.form.plan_amount = 0;
				this.form.contract_amount = 0;
				this.form.contract_discount_value = 0;
				this.form.hike_percentage = 0;
				this.form.contract_final_amount = 0;
				this.form.cctoken_id = '';
				this.form.contract_start = '';
				this.form.contract_end = '';
				this.form.number_of_payment = '';
				this.form.email_patient_on_decline = true;
				this.form.email_patient_on_approval = true;
				this.form.is_charg_started = false;
				this.form.contract_items = [];
				this.form.wellness_coordinator = this.user ? ((this.user.salutation) ? this.user.salutation : '') +' '+`${this.user.first_name} ${this.user.last_name}` +' '+ ((this.user.suffix) ? this.user.suffix : '') : "";
				/*this.ccTokenData.forEach((value, index) => {
				    if (value.is_default == 1 && value.is_expired == false) {
				        this.form.cctoken_id = value.id;
				    }
				});*/
				this.v$.$reset();
			},
			fetchPatientToken : function() {
				axios
				.post('/payment/patient-cctoken',{patient_id:this.$store.state.patientDetail.patient_id})
				.then((response) => {
					this.ccTokenData = response.data.data;
				});
			},
			fetchSubscriptionPlan : function() {
				axios
				.get('/subscription-plan/list?active=true')
				.then((response) => {
					this.subscriptionPlanList = response.data.data;
				});
			},
			fetchPatientContract : function() {
				this.$store.state.loader = true;
				axios
				.post('/contract-billing/list',{patient_id:this.$store.state.patientDetail.patient_id})
				.then((response) => {
					this.contract_list = response.data.data;
					this.$store.state.loader = false;
				});
			},
			setDateFormat(cstDate) {
				if (cstDate) {
					var mDate = moment(String(cstDate)).format('MM-DD-yyyy');
					return mDate;
				}
			},
			editContract(id) {
				this.$store.state.loader = true;
				axios
				.post(`/contract-billing/editContract/${id}`,{ patient_id : this.$store.state.patientDetail.patient_id })
				.then((response) => {
					this.form = response.data.data;
					this.form.contract_items;
					$("#addNewContract").modal("show");
					this.is_edit_request_call = true;
					this.$store.state.loader = false;
				}).catch((error) => {
					this.$store.state.loader = false;
				});
			},
			sendContractInvitation(id) {
				this.$store.state.loader = true;
				axios
				.post(`/contract-billing/sendContractInvitation/${id}`,{ patient_id : this.$store.state.patientDetail.patient_id })
				.then((response) => {
					this.fetchPatientContract();
					this.moshaToast(response.data.message, "success");
					this.$store.state.loader = false;
				}).catch((error) => {
					this.moshaToast(error.response.data.message, "danger");
					this.$store.state.loader = false;
				});
			},
			previewContract(id) {
				this.$store.state.loader = true;
				axios
				.post(`/contract-billing/previewContract/${id}`,{ patient_id : this.$store.state.patientDetail.patient_id }, { responseType: 'blob' })
				.then((response) => {
		          this.$store.state.loader = false;
		          const urlObject = URL.createObjectURL(response.data);
		          $("#viewReceipt").modal("show");
		          if (/Android|webOS|Opera Mini/i.test(navigator.userAgent)) {
		          	$("#viewReceipt").modal("hide");
		          	window.open(urlObject, '_blank', 'height=600,width=1200');
		          } else {
		          	this.receipt_url = urlObject;
		          	this.browserCheck();
		          }
				}).catch((error) => {
					this.$store.state.loader = false;
				});
			},
			createPaymentRepeat($event) {
				var options = $event.target.options;
				if(this.form.base_program_price == false){
					if (options.selectedIndex > -1) {
						this.form.hike_percentage = options[options.selectedIndex].getAttribute('data-hike');
					} else {
						this.form.hike_percentage = 0;
					}
				}else{
					this.form.hike_percentage = 0;
				}
				
				this.form.contract_items = [];
				for (var payment = 1; payment <= this.form.number_of_payment; payment++) {
					this.form.contract_items.push({
						'input_date' : {id: `payment_date_${payment}`,label: `Select Date`,value: ""},
						'input_amount' : {id: `payment_amount_${payment}`,label: `Enter Amount`,value: ""},
						'is_charged' : false,
						'edit_id' : '',
					});
				}
				this.calcFinalAmount();
			},
			basePrice(){
				if(this.form.base_program_price == true){
					this.form.hike_percentage = 0;
				}else{
					this.form.hike_percentage = $('#id_number_of_payment').children('option:selected').attr('data-hike');
				}
				this.calcFinalAmount();
			},
			numbersOnly(evt) {
				evt = (evt) ? evt : window.event;
				var charCode = (evt.which) ? evt.which : evt.keyCode;
				if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
					evt.preventDefault();;
				} else {
					return true;
				}
			},
			setDate(selectedId, index) {
				let $this = this;
				setTimeout(function(){
					$this.form.contract_items[index].input_date.value = $('#'+selectedId).val();
					if (index == 0 && $('#'+selectedId).val() != '') {
						$this.form.contract_items.forEach((value, Subindex) => {
							if (Subindex != 0 && value.input_date.value == '') {
								value.input_date.value = moment($('#'+selectedId).val()).add(Subindex, 'M');
							}
						});
					}
				}, 500);
			},
			setStartEndDate(start_or_end) {
				let $this = this;
				setTimeout(function() {
					if (start_or_end == 'contract_end') {
						$this.form.contract_end = $('#contract_end').val();
					} else {
						$this.form.contract_start = $('#contract_start').val();
					}
				}, 500);
			},
			onToggleDeclChange() {
				var class_list = document.getElementById('email_patient_on_decline').classList;
				if (class_list.contains('active')) {
					this.form.email_patient_on_decline = true;
				} else {
					this.form.email_patient_on_decline = false;
				}
			},
			onToggleAprlChange(idObj, event) {
				var class_list = document.getElementById('email_patient_on_approval').classList;
				if (class_list.contains('active')) {
					this.form.email_patient_on_approval = true;
				} else {
					this.form.email_patient_on_approval = false;
				}
			},
			saveContract() {
				this.v$.$validate();
				var errorCounter = 0;
				if(this.form.contract_items.length>0) {
					this.form.contract_items.forEach((itm, indx) => {
						if(itm.input_date.value.trim()=="") {
							errorCounter++;
							document.getElementById("contract-date-"+indx).classList.remove('d-none');
							document.getElementById("contract-date-"+indx).classList.add('d-block');
						} else {
							document.getElementById("contract-date-"+indx).classList.add('d-none');
							document.getElementById("contract-date-"+indx).classList.remove('d-block');
						}
					});
				}
				if(errorCounter>0) {
					return false;
				}
				if (!this.v$.$error) {
					this.serverError = '';
					this.$store.state.loader = true;
					axios
					.post("/contract-billing/addUpdate",this.form)
					.then((response) => {
						this.moshaToast(response.data.message, "success");
						this.$store.state.loader = false;
						$("#addNewContract").modal("hide");
						this.fetchPatientContract();
					}).catch((error) => {
						if (error.response.status == 422) {
							this.serverError = "Please fill all the required fields.";
						} else {
							this.serverError = error.response.data.message;
						}
						this.$store.state.loader = false;
					});
				}
			}
		},
		watch: {
			"$store.state.PaymentGatewayMigration.hidemodal": function() {
				if (this.$store.state.PaymentGatewayMigration.hidemodal == true) {
					this.billingDialog();
				}
			},
		}
	}
</script>