<template>
  <div class="tab-content">
    <div class="box">
      <div class="box-body">
        <div class="pageheading">
          <div class="row justify-content-between">
            <div class="col-12 col-sm-7 col-md-7 col-lg-6 col-xs-6">
                <h2 class="heading2 font-bold mt-5">Radiology Order <a href="#" data-bs-toggle="modal" data-bs-target="#radiologydocument" title="Reference of Knowledge Base" v-on:click.prevent="reset()"> <i class="fa fa-question-circle fs-20"></i></a></h2>
            </div>
            <div class="col-12 col-sm-5 col-md-5 col-lg-6 col-xs-6 text-sm-end">
              <a
                v-if="
                  permissioncheck(this.$store.state.permission.create_update)"
                href="javascript:void(0);"
                class="waves-effect waves-light btn btn-info mb-10 manual_box"
                @click="DisplayForm('store')"
                ><i class="fa fa-plus fs-14"></i> Radiology Order</a>
            </div>
          </div>
        </div>
        <div class="table-responsive">
          <Table
            :dataTypes="{
              dataType: 'radiologyorder',
              patient_id: patient_id,
              patient_unique_id: patient_unique_id,
            }"
            :lastcol='permissioncheck(this.$store.state.permission.create_update)'
            :key="key"
          >
            <template v-slot="slotProps">
              <td>{{ slotProps.item.provider_name }}</td>
              <td>{{ slotProps.item.description }}</td>
              <td>{{ slotProps.item.cpt_code }}</td>
              <td>
                {{ formatDate(slotProps.item.created_at, "MMM D, YYYY") }}
              </td>
              <td>{{ slotProps.item.order_status }}</td>
              <td>
                <a
                  href="javascript:void(0);" class="fw-600 text-info" @click="FileDisplay(slotProps.item.document_name)">
                  {{ slotProps.item.document_title }}
                </a>
              </td>
              <td>{{ slotProps.item.doctor_comments }}</td>
              <td>
                <div class="d-flex" v-if="
                permissioncheck(this.$store.state.permission.create_update)">
                <Popper arrow content="Edit Radiology Order" :hover="true" class="helptips cursor-pointer">
                  <a
                    v-if="
                      permissioncheck(
                        this.$store.state.permission.create_update
                      )"
                    href="javascript:void(0)"
                    class="waves-effect waves-circle btn btn-circle btn-info btn-xs check_box"
                    @click="editItem(slotProps.item.id)"
                    ><i class="fa fa-pencil"></i></a>
                  </Popper>
                  <Popper arrow content="Delete Radiology Order" :hover="true" class="helptips cursor-pointer">
                    <a
                      href="#"
                      class="waves-effect waves-circle btn btn-circle btn-danger btn-xs"
                      @click="deleteItem(slotProps.item.id)"
                      ><i class="fa fa-trash"></i></a>
                  </Popper>
                </div>
              </td>
            </template>
          </Table>
        </div>
      </div>
    </div>
    <div class="box table_filters" v-if="fromdisplay">
      <div class="box-body" id="formbody">
        <div class="content-header">
          <h3 class="heading3 font-bold m-0">{{ formtitle }}</h3>
        </div>
        <div class="form-group row" :class="{ error: v$.form.cpt_code.$errors.length }">
          <label class="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end">CPT Code <small class="text-danger asterisksign">*</small></label>
          <div class="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-3">
            <div :class="{ error: v$.form.cpt_code.$errors.length }">
                <div class="selectcontrols ">
                    <Autocomplete 
                      :max=30 
                      @input="getlist" 
                      ref="autocomplete" 
                      :results="cpt" 
                      @onSelect="resultsvalue"
                      :item-text="form.cpt_code"
                      class="form-control ms-0 autosearch-list" 
                      placeholder="CPT code"
                      :use-html-for-results ="true">
                    </Autocomplete>
                </div>
                <div class="invalid-feedback" style="display:block;" v-for="(error, index) of v$.form.cpt_code.$errors" :key="index"> {{ error.$message }}</div>
            </div>
          </div>
        </div>

        <div class="form-group row" :class="{ error: v$.form.description.$errors.length }">
          <label class="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end">Description <small class="text-danger asterisksign">*</small></label>
          <div class="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-3">
            <div :class="{ error: v$.form.description.$errors.length }">
                <div class="selectcontrols ">
                    <Autocomplete 
                      :max=30 
                      @input="description" 
                      ref="description" 
                      :results="descriptionlist" 
                      @onSelect="descriptionvalue"
                      class="form-control ms-0 autosearch-list" 
                      placeholder="Description"
                      :use-html-for-results ="true">
                    </Autocomplete>
                </div>
                <div class="invalid-feedback" style="display:block;" v-for="(error, index) of v$.form.description.$errors" :key="index"> {{ error.$message }}</div>
            </div>
          </div>
        </div>        
        <text-area
              title="Doctor comments"
              labelClass="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end"
              divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3"
              v-model="form.doctor_comments"
            ></text-area>
        <div
          class="form-group row"
          :class="{ error: v$.form.cpt_code.$errors.length }"
        >
          <label
            class="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end"
            >Order Status
            <small class="text-danger asterisksign">*</small></label
          >
          <div class="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-3">
            <select
              class="form-control"
              data-placeholder="Order Status"
              v-model="form.order_status"
            >
              <option selected="selected" disabled="disabled" value=""
                >Status of order</option
              >
              <option value="Order Entered">Order Entered</option>
              <option value="Discontinued">Discontinued</option>
              <option value="In Progress">In Progress</option>
              <option value="Results Received">Results Received</option>
              <option value="Results Reviewed with Patient"
                >Results Reviewed with Patient</option
              >
              <option value="Paper Order">Paper Order</option>
            </select>
            <div
              class="invalid-feedback"
              style="display:block;"
              v-for="(error, index) of v$.form.order_status.$errors"
              :key="index"
            >
              {{ error.$message }}
            </div>
          </div>
        </div>
        <select-box
          id="result_document_id"
          title="Scanned in result"
          v-model="form.result_document_id"
          :getDataURL="dropdownurl"
          :requestData="{ method: 'post', data: { patient_id: patient_id,folder_type:'Radiology' } }"
          labelClass="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end"
          divClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-3"
        >
        </select-box>
        <div class="row justify-content-end">
            <div class="col-12 col-sm-12 col-md-8 col-lg-9">
              <div class="mb-15">
                <small class="fs-12 l-h-n"><b>Note:</b> Upload a document by following the path - <br>Documents > Document upload > Radiology requisition/result </small>
              </div>
            </div>
          </div>
        <div class="row">
          <div
            class="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-9 text-center"
          >
            <form-btn
              :title="btn"
              btnCls="waves-effect waves-light btn btn-info me-1"
              @click="submitForm(btn)"
            ></form-btn>
            <router-link
              :to="{ name: 'RadiologyOrder' }"
              class="waves-effect waves-light btn me-1 btn-danger"
              @click="clearForm(form)"
              >Cancel</router-link
            >
          </div>
        </div>
      </div>
    </div>
    <!--document model start-->
    <div id="radiologydocument" class="modal fade" tabindex="-1" role="dialog"
      aria-labelledby="myModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-md">
      <div class="modal-content">
        <div class="modal-header">
        <h3 class="modal-title fw-900 mt-0">Reference of Knowledge Base</h3>
        </div>
        <div class="modal-body overflow-auto" style="max-height: 350px;">
        <table class="table table-bordered">
          <thead>
          <tr>
            <th scope="col">No.</th>
            <th scope="col">Knowledge Base Articles</th>
          </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td> <a :href="path+'how-to-write-radiology-orders'" target="_blank">How To Write Radiology Orders?</a></td>
            </tr>
            <tr>
              <td>2</td>
              <td> <a :href="path+'how-to-change-radiology-orders-status'" target="_blank">How To Change Radiology Orders Status?</a></td>
            </tr>
            <tr>
              <td>3</td>
              <td> <a :href="path+'how-to-delete-radiology-orders'" target="_blank">How To Delete Radiology Orders?</a></td>
            </tr>
          </tbody>
        </table>
        <Search :key="searchKey"></Search>
        </div>
        <div class="modal-footer">
        <button type="button" @click="" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
        </div>
      </div>
      </div>
    </div>
    <!--document model end-->
  </div>
</template>

<script>
import Table from "@/components/base/Table.vue";
import moment from "moment";
import InputField from "@/components/base/formFields/InputField.vue";
import TextArea from "@/components/base/formFields/textArea.vue";
import FormBtn from "@/components/base/formFields/formBtn.vue";
import Autocomplete from "vue3-autocomplete";
import axios from "@/scripts/axios.js";
import useVuelidate from "@vuelidate/core";
import submitMixins from "@/scripts/mixins/submitMixins.js";
import SelectBox from "@/components/base/formFields/selectBox.vue";
import permissionMixins from "@/scripts/mixins/permission.js";
import Popper from "vue3-popper";
import Search from "@/components/Search.vue";
export default {
  mixins: [submitMixins, permissionMixins],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      key: 0,
      patient_id: this.$store.state.patientDetail.patient_id,
      patient_unique_id: this.$store.state.patientDetail.patient_unique_id,
      action: "",
      formtitle: "",
      btn: "",
      fromdisplay: false,
      cpt: [],
      descriptionlist:[],
      dropdownurl: `/json_dropdown/patient/${this.$route.params.id}/documents`,
      path: process.env.VUE_APP_KB_DOCUMENT,
      searchKey:0,
    };
  },
  methods: {
    reset() {
      this.searchKey = this.searchKey + 1;
    },
    formatDate(date, format) {
      return moment(date).format(format);
    },
    DisplayForm(action) {
      if (action == "store") {
        this.$store.state.radiologyorder.form = {};
        Object.assign(
          this.$store.state.radiologyorder.form,
          this.$store.state.radiologyorder.defaultFormData
        );
        if (this.$refs.autocomplete) {
          this.$refs.autocomplete.searchText = "";
        }
        this.formtitle = "New Radiology Order";
        this.btn = "Save";
      } else {
        this.formtitle = "Edit Radiology Order";
        this.btn = "Update";
      }
      this.fromdisplay = true;
      setTimeout(() => {
        var myEl = document.getElementById("formbody");
        this.$smoothScroll({
          scrollTo: myEl,
        });
      }, 100);
    },
    editItem(id) {
      this.$router.push({
        name: "RadiologyOrderEdit",
        params: { id: this.$route.params.id, id2: id },
      });
    },
    getlist(obj) {      
      if (obj.length > 1) {
        this.$refs.autocomplete.$el.classList.add("selectloader");
        axios
          .get("/json_autocomplete/cpt-code", {
            params: { term: obj },
          })
          .then((response) => {
            //this.cpt = response.data.data;
            this.cpt = response.data.data.map((cptcode) => {
              return { id: cptcode.id, 
                name: "<b>"+cptcode.code+"</b><br>"+cptcode.description,
                code:cptcode.code,
                description:cptcode.description
              }
            });
            this.$refs.autocomplete.$el.classList.remove("selectloader");
          });
      }
      if (obj.length == 0) {
        this.cpt = [];
        this.$refs.autocomplete.searchText = '';
        this.$refs.description.searchText = '';
        this.form.description = '';
        this.form.cpt_code = '';
        this.focusout();
      }
    },
    description(obj) {
      if (obj.length > 1) {
        this.$refs.description.$el.classList.add("selectloader");
        axios
          .get("/json_autocomplete/cpt-code", {
            params: { term: obj },
          })
          .then((response) => {
            //this.descriptionlist = response.data.data;
            this.descriptionlist = response.data.data.map((description) => {
              return { id: description.id, 
                name: "<b>"+description.code+"</b><br>"+description.description,
                code:description.code,
                description:description.description
              }
            });
            this.$refs.description.$el.classList.remove("selectloader");
          });
      }
      if (obj.length == 0) {
        this.descriptionlist == {};
        this.$refs.autocomplete.searchText = '';
        this.$refs.description.searchText = '';
        this.form.description = '';
        this.form.cpt_code = '';
        this.focusout();
      }
    },
    clearForm(form) {
      this.fromdisplay = false;
      if (form.id) {
        //location.reload();
      } else {
        Object.assign(
          this.$store.state.radiologyorder.form,
          this.$store.state.radiologyorder.defaultFormData
        );
        this.$refs.autocomplete.searchText = "";
      }
    },
    submitForm(action) {
      if (action === "Save") {
        var url = `patient/${this.$route.params.id}/radiology-order/store`;
      } else {
        var url = `patient/${this.$route.params.id}/radiology-order/update`;
      }
      this.form.patient_id = this.$store.state.patientDetail.patient_id;
      this.postForm(url, "radiologyorder", true, "json");
    },
    formPostSuccess(data) {
      this.$store.state.radiologyorder.form = {};
      Object.assign(
        this.$store.state.radiologyorder.form,
        this.$store.state.radiologyorder.defaultFormData
      );
      this.v$.$reset();      
      this.$refs.autocomplete.searchText = "";
      this.fromdisplay = false;
      this.moshaToast("Record Updated Successfully", "success");
      this.$router.push({
        name: "RadiologyOrder",
        params: { id: this.$route.params.id },
      });
      this.key++;
    },
    deleteItem(id) {
      this.$swal
        .fire({
          title: '<h2 class="mt-0">Are you sure?</h2>',
          text:
            "You want to delete these records? This process cannot be undone.",
          icon: "warning",
          dangerMode: true,
          showCancelButton: true,
          confirmButtonColor: "#ee3158",
          cancelButtonColor: "#bdbdbd",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed == true) {
            axios
              .post(`patient/${this.$route.params.id}/radiology-order/delete`, {
                id: id,
              })
              .then((res) => {
                this.$swal.fire("Deleted!", res.data.message, "success");
                this.key++;
              });
          }
        });
    },
    resultsvalue(obj) {
      this.$refs.autocomplete.searchText = obj.code;
      this.$refs.description.searchText = obj.description;
      this.form.description = obj.description;
      this.form.cpt_code = obj.code;
      this.focusout();
    },
    descriptionvalue(obj){
      this.$refs.autocomplete.searchText = obj.code;
      this.$refs.description.searchText = obj.description;
      this.form.description = obj.description;
      this.form.cpt_code = obj.code;
      this.focusout();
    },
    focusout() {
      const btn = this.$refs.autocomplete;
      btn.$el
        .querySelector(".vue3-autocomplete-container > .autosearch-list")
        .blur();
    },
    FileDisplay(fileName) {
      var document_name_url = process.env.VUE_APP_API_BASE_URL+"/stream-image?path="+fileName+"&slang="+this.$store.state.auth.userData.clinic_id;
      window.open(document_name_url);
    },
  },
  components: { Table, Autocomplete, InputField, TextArea, FormBtn, SelectBox, Popper, Search },
  computed: {
    form() {
      return this.$store.state.radiologyorder.form;
    },
    vuelidateExternalResults() {
      return this.$store.state.radiologyorder.vuelidateExternalResults;
    },
    validationRules() {
      return this.$store.state.radiologyorder.validationRules;
    },
  },
  validations() {
    return this.$store.state.radiologyorder.validationRules;
  },
  async beforeCreate() {
    this.$store.state.radiologyorder.form = {};
    Object.assign(
      this.$store.state.radiologyorder.form,
      this.$store.state.radiologyorder.defaultFormData
    );
  },
  mounted() {
     if (this.$route.name == "RadiologyOrderEdit") {
       axios
        .get(
          `patient/${this.$route.params.id}/radiology-order/edit/` +
            this.$route.params.id2
        )
        .then(
          (response) =>
            {
              this.$store.state.radiologyorder.form = response.data.data;
              this.form = this.$store.state.radiologyorder.form; 
              this.$refs.autocomplete.searchText = response.data.data.cpt_code;
              this.$refs.description.searchText = response.data.data.description;
            }
        );
        this.DisplayForm("update");
        
    }    
  },
};
</script>