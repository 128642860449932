<template>
        <Autocomplete :max="30" @input="getlist" ref="autocomplete" @blur="blurevent" :results="cpt_hcpcs_modifier" @onSelect="resultsvalue" class="form-control d-inline-block ps-5 pe-5 d-inline-block autosearch-list"></Autocomplete>
</template>
<script>
import Autocomplete from 'vue3-autocomplete'
import axios from "@/scripts/axios.js";

export default {
    props: {
        modelValue: {},
        isSelected: {
            type: Boolean,
            default: true
        },
    },
    data () {
        return {
            cpt_hcpcs_modifier: [],
        }
    },
    mounted () {
        this.$refs.autocomplete.searchText = this.modelValue;
    },
    methods: {
        blurevent(){
            if(this.$refs.autocomplete.searchText != null){
                if(this.$refs.autocomplete.searchText.length < 2){
                    this.$emit('update:modelValue', '')
                    this.$refs.autocomplete.searchText = ''
                }else if(this.$refs.autocomplete.searchText.length == 2){
                    this.$emit('update:modelValue', this.$refs.autocomplete.searchText)
                };
            }
        },
        getlist (obj) {            
            axios.get("/json_autocomplete/cpt-hcpcs-modifier-code", {
                params: {term: obj}
            }).then((response) => (this.cpt_hcpcs_modifier = response.data.data ))
                ;
        },
        resultsvalue(obj){
            this.$emit('clicked', obj);
            this.$emit('update:modelValue', obj.code)
            if(this.isSelected){
                this.$refs.autocomplete.searchText = obj.code;
            }else{
                this.$refs.autocomplete.searchText = '';
            }
            this.focusout();
        },
        focusout(){
            const btn = this.$refs.autocomplete;
            btn.$el.querySelector('.vue3-autocomplete-container > .autosearch-list').blur();
        }
    },
    components: { Autocomplete },
}
</script>