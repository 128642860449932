const EligibilityResponses = {
    namespaced: true,  
    state: {
        listUrl: `reports/eligibility-response`,     
        header: [
            {"patient_name" : "Patient Name"},
            {"eligibility_recent_service_checked_date" : "Service Date"},
            {"eligibility_recent_checked_date" : "Checked Date"},
            {"eligibility_recent_status" : "Status"},
            {"" : "Action"},
        ],
        pagination: {
            to: "",
            from: "",
            total: "",
            last_page: "",
            current_page: "",
            pageNavArr: [],
        },
        filter: {
            date_range:'',
            response_status:'',
            patient_id: ''            
        },
        list: [],
    },
    getters: {},
    mutations: {},
    actions: {},
  };
  
  export default EligibilityResponses;
