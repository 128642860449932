<template>
   <aside class="control-sidebar scroll-top p-0 pb-0 bg-white side-note-active" id="editNotesside">
        <div class="rpanel-title">
            <span class="pull-right btn-xs btn rounded15 btn-danger-light p-1 m-1" data-toggle="control-sidebar"><i class="ion ion-close"></i></span>
        </div>
        <div class="notssidetabs">
            <ul class="nav nav-tabs control-sidebar-tabs">
                <li class="nav-item dropdown">
                    <a href="javascript:void(0)" class="waves-light bg-transparent" data-bs-toggle="dropdown" title="">{{current_category}}
                        <i class="fa fa-angle-down"></i>
                    </a>
                    <ul class="dropdown-menu">
                        <li>
                            <a href="#control-alls" title="All" @click="getAdminNoteBytab('All','control-alls')" data-bs-toggle="tab" class="dropdown-item active" >All</a>
                        </li>
                        <li>
                            <a href="#control-refills" title="Refill" data-bs-toggle="tab" @click="getAdminNoteBytab('Refill','control-refills')" class="dropdown-item ">REFILLS</a>
                        </li>
                        <li>
                            <a href="#control-WC-Checks" class="dropdown-item" title="WC Check"  data-bs-toggle="tab" @click="getAdminNoteBytab('WcCheck','control-WC-Checks')">WC CHECK</a>
                        </li>
                        <li>
                            <a href="#control-General-Note" class="dropdown-item" title="General Notes" @click="getAdminNoteBytab('AdminNote','control-General-Note')" data-bs-toggle="tab" >ADMIN NOTES</a>
                        </li>
                        <li>
                            <a href="#control-med-lists"  class="dropdown-item" title="Med List" @click="getAdminNoteBytab('MedList','control-med-lists')" data-bs-toggle="tab">MED LIST</a>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
        <div class="tab-content px-15">
            <div class="tab-pane active" id="control-alls">
                <div class="d-flex align-items-center justify-content-between">
                    <h3 class="fw-500">Notes</h3>
                    <a href="javascript:void(0)" @click.prevent="addNewNoteAll" class="waves-effect waves-light btn btn-info btn-xs"><i class="fa fa-plus "></i> New</a>
                </div>
                <template v-if="show_admin_note_form">
                    <div class="form-group mb-10">
                        <label class="form-label">Note Category</label>
                        <select class="form-control" v-model="admin_note_input.category" @change="setCurrentCategory" :disabled="admin_cat_disabled">
                            <option value="Refill">Refill</option>
                             <option value="WcCheck">Wc Check</option>
                            <option value="AdminNote">Admin Note</option>
                            <option value="MedList">Med List</option>
                        </select>
                        <div
                          class="invalid-feedback"
                          style="display:block;"
                          v-if="!admin_note_input.category && admin_note_category_error"
                        >
                          {{ admin_note_category_error }}
                        </div>
                    </div>
                    <template v-if="admin_note_input.category == 'WcCheck'">
                        <template v-for="(item,index) in admin_wc_check_note" :key="index">
                            <div class="form-group mb-10">
                                <label class="form-label">{{item.question}}</label>
                                <input type="text" v-model="item.answer" class="form-control">
                            </div>
                        </template>
                    </template>
                    <template v-else>
                        <div class="form-group mb-10">
                            <label class="form-label">Note</label>
                            <textarea type="text" v-model="admin_note_input.note" class="form-control" rows="2"></textarea>
                            <div
                              class="invalid-feedback"
                              style="display:block;"
                              v-if="!admin_note_input.note && admin_note_note_error"
                            >
                              {{ admin_note_note_error }}
                            </div>
                        </div>
                    </template>
                    <div class="" v-if="admin_note_input.category == 'Refill'">
                        <div class="d-flex justify-content-between">
                            <div class="form-group mb-10 w-p100" v-if="this.is_new_refill_pharmacy">
                                <div class="controls">
                                    <label class="form-label">Pharmacy</label>
                                    <input id="template_title" type="text" v-model="refill_pharmacy_name" class="form-control" placeholder="Name" autocomplete="off">
                                </div>
                                <div class="error-msg">
                                    <ul class="mb-0 p-0 fs-14"></ul>
                                </div>
                            </div>
                            <div class="" v-if="this.is_new_refill_pharmacy">
                                <form-btn title="-" btnCls="waves-effect waves-light btn btn-info btn-xs" @click="this.is_new_refill_pharmacy = !this.is_new_refill_pharmacy;" style="padding:2px;"></form-btn>
                            </div>
                        </div>
                    </div>
                    <div class="" v-if="!this.is_new_refill_pharmacy">
                        <div class="d-flex justify-content-between">
                            <div class="form-group mb-10 w-p100">
                                <label class="form-label">Pharmacy</label>
                                <select name="select" class="form-select form-control" v-model="refill_pharmacy_id">
                                    <option selected="selected" value="" disabled="disabled">Select Pharmacy</option>
                                    <option v-for="item in admin_note_refill_pharmacy" :key="item.id"  :value="item.id">{{item.name}}</option>
                                </select>
                            </div>
                            <div class="ms-2 mt-30" v-if="!this.is_new_refill_pharmacy">
                                <form-btn title="+" btnCls="waves-effect waves-light btn btn-info btn-xs" @click="this.is_new_refill_pharmacy = !this.is_new_refill_pharmacy;" style="padding:2px;"></form-btn>
                            </div>
                        </div>
                    </div>

                    <div class="row" v-if="admin_note_input.category == 'Refill'">
                        <div class="col-md-6">
                            <div class="d-flex justify-content-between">
                                <div class="form-group mb-10 w-p100" v-if="this.is_new_refill_status">
                                    <div class="controls">
                                        <label class="form-label">Status</label>
                                        <input id="template_title" type="text" v-model="refill_status_name" class="form-control" placeholder="Name" autocomplete="off">
                                    </div>
                                    <div class="error-msg">
                                        <ul class="mb-0 p-0 fs-14"></ul>
                                    </div>
                                </div>
                                <div class="ms-2 mt-30" v-if="this.is_new_refill_status">
                                    <form-btn title="-" btnCls="waves-effect waves-light btn btn-info btn-xs" @click="this.is_new_refill_status = !this.is_new_refill_status;" style="padding:2px;"></form-btn>
                                </div>
                            </div>
                            <div class="d-flex justify-content-between" v-if="!this.is_new_refill_status">
                                <div class="form-group mb-10 w-p100">
                                    <label class="form-label">Status</label>
                                    <select name="select" class="form-select form-control" v-model="refill_status_id">
                                        <option selected="selected" value="" disabled="disabled">Select Status</option>
                                        <option v-for="item in admin_note_refill_status" :key="item.id"  :value="item.id">{{item.name}}</option>
                                    </select>
                                </div>
                                <div class="ms-2 mt-30" v-if="!this.is_new_refill_status">
                                    <form-btn title="+" btnCls="waves-effect waves-light btn btn-info btn-xs" @click="this.is_new_refill_status = !this.is_new_refill_status;" style="padding:2px;"></form-btn>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group mb-10">
                                <label class="form-label">Patient Confirmation Received?</label>
                                <select class="form-control" v-model="admin_note_input.confirmation_received">
                                    <option value="Yes">Yes</option>
                                     <option value="No">No</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="text-end">
                        <a href="javascript:void(0)" class="waves-effect waves-light btn btn-info me-1" @click="handleAdminNoteSubmit">Submit</a>
                        <a href="javascript:void(0)" @click.prevent="show_admin_note_form=!show_admin_note_form" class="waves-effect waves-light btn btn-default">Cancel</a>
                        
                    </div>
                </template>
                <div class="d-flex align-items-center justify-content-between">
                    <h3 class="fw-500"></h3>
                </div>
                <template v-if="!show_admin_note_form">
                    <template v-if="noteListFiltered.length > 0">
                        <div class="notesList bt-1 py-10" v-for="(item,index) in noteListFiltered" :key="index">
                            <template v-if="item.category == 'WcCheck'">
                                <p class="mb-1">WC Check note</p>
                            </template>
                            <template v-else>
                                <p class="mb-1" v-if="item.note.length<50">{{ item.note }}</p>
                                <p class="mb-1" v-else>{{ item.note.substring(0,50)+"..." }}</p>
                            </template>
                            <div class="row">
                                <div class="col-5 col-sm-5 col-md-5">
                                    <p class="mb-1">Date: <span class="fw-500">{{ $filters.displayDateTimeLocal(item.created_at) }}</span></p>
                                </div>
                                <div class="col-5 col-sm-5 col-md-5">
                                    <p class="mb-1">By: <span class="fw-500">{{ item.username }}</span></p>
                                </div>
                                <div class="col-2 col-sm-2 col-md-2 text-center">
                                    <a href="javascript:void(0)" @click="editAdminNote(item)" class="waves-effect waves-light btn btn-info-light btn-xs mx-1"><i class="fa fa-pencil"></i></a>
                                    <a href="javascript:void(0)"  @click="deleteAdminNote(item)" class="waves-effect waves-light btn btn-danger-light btn-xs"><i class="fa fa-trash"></i></a>
                                </div>
                            </div>
                        </div>
                    </template>
                    <div v-if="demo_admin_note['notes'] != null">
                        <p class="mb-1" v-if="demo_admin_note['notes'].length<50">{{ demo_admin_note['notes']}}</p>
                        <p class="mb-1" v-else>{{ demo_admin_note['notes'].substring(0,50)+".." }}</p>
                        <p>Date: <b>{{ $filters.displayDateTimeLocal(demo_admin_note['created_at'])}}</b></p>
                    </div>
                    <template v-if="noteListFiltered.length == 0 && demo_admin_note['notes'] == null">
                        <div class="notesList bt-1 py-10" >
                            <p class="mb-1">No record found.</p>
                        </div>
                    </template>
                </template>
            </div>
            <div class="tab-pane" id="control-refills">
                <div class="d-flex align-items-center justify-content-between">
                    <h3 class="fw-500">Notes</h3>
                    <a href="javascript:void(0)" @click.prevent="newRedirect('Refill')" class="waves-effect waves-light btn btn-info btn-xs"><i class="fa fa-plus "></i> New</a>
                </div>
                <template v-if="show_admin_note_form">
                    <div class="" v-if="admin_note_input.category == 'Refill'">
                        <div class="d-flex align-items-center">
                            <div class="form-group w-p100" v-if="this.is_new_refill_pharmacy">
                                <div class="controls">
                                    <label class="form-label">Pharmacy</label>
                                    <input id="template_title" type="text" v-model="refill_pharmacy_name" class="form-control" placeholder="Name" autocomplete="off">
                                </div>
                                <div class="error-msg">
                                    <ul class="mb-0 p-0 fs-14"></ul>
                                </div>
                            </div>
                            <div class="mt-30 ms-1" v-if="this.is_new_refill_pharmacy">
                                <form-btn title="-" btnCls="waves-effect waves-light btn btn-info btn-xs" @click="this.is_new_refill_pharmacy = !this.is_new_refill_pharmacy;" style="padding:2px;"></form-btn>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between" v-if="!this.is_new_refill_pharmacy">
                            <div class="form-group w-p100 mb-10">
                                <label class="form-label">Pharmacy</label>
                                <select name="select" class="form-select form-control" v-model="refill_pharmacy_id">
                                    <option selected="selected" value="" disabled="disabled">Select Pharmacy</option>
                                    <option v-for="item in admin_note_refill_pharmacy" :key="item.id"  :value="item.id">{{item.name}}</option>
                                </select>
                            </div>
                            <div class="mt-30 ms-1" v-if="!this.is_new_refill_pharmacy">
                                <form-btn title="+" btnCls="waves-effect waves-light btn btn-info btn-xs" @click="this.is_new_refill_pharmacy = !this.is_new_refill_pharmacy;" style="padding:2px;"></form-btn>
                            </div>
                        </div>
                    </div>
                    <div class="form-group mb-10">
                        <label class="form-label">Note</label>
                        <textarea type="text" v-model="admin_note_input.note" class="form-control" rows="2"></textarea>
                        <div
                          class="invalid-feedback"
                          style="display:block;"
                          v-if="admin_note_note_error"
                        >
                          {{ admin_note_note_error }}
                        </div>
                    </div>
                    <div class="row" v-if="admin_note_input.category == 'Refill'">
                        <div class="col-md-6">
                            <div class="d-flex justify-content-between">
                                <div class="form-group w-p100" v-if="this.is_new_refill_status">
                                    <div class="controls">
                                        <label class="form-label">Status</label>
                                        <input id="template_title" type="text" v-model="refill_status_name" class="form-control" placeholder="Name" autocomplete="off">
                                    </div>
                                    <div class="error-msg">
                                        <ul class="mb-0 p-0 fs-14"></ul>
                                    </div>
                                </div>
                                <div class="ms-2" v-if="this.is_new_refill_status">
                                    <form-btn title="-" btnCls="waves-effect waves-light btn btn-info btn-xs" @click="this.is_new_refill_status = !this.is_new_refill_status;" style="padding:2px;"></form-btn>
                                </div>
                            </div>
                            <div class="d-flex justify-content-between" v-if="!this.is_new_refill_status">
                                <div class="form-group mb-10 w-p100">
                                    <label class="form-label">Status</label>
                                    <select name="select" class="form-select form-control" v-model="refill_status_id">
                                        <option selected="selected" value="" disabled="disabled">Select Status</option>
                                        <option v-for="item in admin_note_refill_status" :key="item.id"  :value="item.id">{{item.name}}</option>
                                    </select>
                                </div>
                                <div class="mt-30 ms-1" v-if="!this.is_new_refill_status">
                                    <form-btn title="+" btnCls="waves-effect waves-light btn btn-info btn-xs" @click="this.is_new_refill_status = !this.is_new_refill_status;" style="padding:2px;"></form-btn>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group mb-10">
                                <label class="form-label">Confirmation Received?</label>
                                <select class="form-control" v-model="admin_note_input.confirmation_received">
                                    <option value="Yes">Yes</option>
                                     <option value="No">No</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="text-end">
                        <a href="javascript:void(0)" class="waves-effect waves-light btn btn-info me-1" @click="handleAdminNoteSubmit">Submit</a>
                        <a href="javascript:void(0)" @click.prevent="show_admin_note_form=!show_admin_note_form" class="waves-effect waves-light btn btn-default">Cancel</a>
                    </div>
                </template>
                <div class="d-flex align-items-center justify-content-between">
                    <h3 class="fw-500"></h3>
                </div>
                <template v-if="!show_admin_note_form">
                    <template v-if="noteListFiltered.length > 0">
                        <div class="notesList bt-1 py-10" v-for="(item,index) in noteListFiltered" :key="index">
                            <template v-if="item.category == 'WcCheck'">
                                <p class="mb-1">WC Check note</p>
                            </template>
                            <template v-else>
                                <p class="mb-1" v-if="item.note.length<50">{{ item.note }}</p>
                                <p class="mb-1" v-else>{{ item.note.substring(0,50)+".." }}</p>
                            </template>
                            <div class="row">
                                <div class="col-5 col-sm-5 col-md-5">
                                    <p class="mb-1">Date: <span class="fw-500">{{ $filters.displayDateTimeLocal(item.created_at) }}</span></p>
                                </div>
                                <div class="col-5 col-sm-5 col-md-5">
                                    <p class="mb-1">By: <span class="fw-500">{{ item.username }}</span></p>
                                </div>
                                <div class="col-2 col-sm-2 col-md-2 text-center">
                                    <a href="javascript:void(0)" @click="editAdminNote(item)" class="waves-effect waves-light btn btn-info-light btn-xs mx-1"><i class="fa fa-pencil"></i></a>
                                    <a href="javascript:void(0)"  @click="deleteAdminNote(item)" class="waves-effect waves-light btn btn-danger-light btn-xs"><i class="fa fa-trash"></i></a>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-if="noteListFiltered.length == 0">
                        <div class="notesList bt-1 py-10" >
                            <p class="mb-1">No record found.</p>
                        </div>
                    </template>
                </template>
            </div>
            <div class="tab-pane" id="control-WC-Checks">
                <div class="d-flex align-items-center justify-content-between">
                    <h3 class="fw-500">Notes</h3>
                    <a href="javascript:void(0)" @click.prevent="newRedirect('WcCheck')" class="waves-effect waves-light btn btn-info btn-xs"><i class="fa fa-plus "></i> New</a>
                </div>
                <template v-if="!show_admin_note_form">
                    <template v-if="noteListFiltered.length > 0">
                        <div class="notesList bt-1 py-10" v-for="(item,index) in noteListFiltered" :key="index">
                            <template v-if="item.category == 'WcCheck'">
                                <p class="mb-1">WC Check note</p>
                            </template>
                            <template v-else>
                                <p class="mb-1" v-if="item.note.length<50">{{ item.note }}</p>
                                <p class="mb-1" v-else>{{ item.note.substring(0,50)+".." }}</p>
                            </template>
                            <div class="row">
                                <div class="col-5 col-sm-5 col-md-5">
                                    <p class="mb-1">Date: <span class="fw-500">{{ $filters.displayDateTimeLocal(item.created_at) }}</span></p>
                                </div>
                                <div class="col-5 col-sm-5 col-md-5">
                                    <p class="mb-1">By: <span class="fw-500">{{ item.username }}</span></p>
                                </div>
                                <div class="col-2 col-sm-2 col-md-2 text-center">
                                    <a href="javascript:void(0)" @click="editAdminNote(item)" class="waves-effect waves-light btn btn-info-light btn-xs mx-1"><i class="fa fa-pencil"></i></a>
                                    <a href="javascript:void(0)"  @click="deleteAdminNote(item)" class="waves-effect waves-light btn btn-danger-light btn-xs"><i class="fa fa-trash"></i></a>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-if="noteListFiltered.length == 0">
                        <div class="notesList bt-1 py-10" >
                            <p class="mb-1">No record found.</p>
                        </div>
                    </template>
                </template>
                <template v-if="show_admin_note_form">
                    <template v-for="(item,index) in admin_wc_check_note" :key="index">
                        <div class="form-group mb-10">
                            <label class="form-label">{{item.question}}</label>
                            <input type="text" v-model="item.answer" class="form-control">
                        </div>
                    </template>
                    <div class="text-end">
                        <a href="javascript:void(0)" class="waves-effect waves-light btn btn-info me-1" @click="handleAdminNoteSubmit">Submit</a>
                        <a href="javascript:void(0)" @click.prevent="show_admin_note_form=!show_admin_note_form" class="waves-effect waves-light btn btn-default">Cancel</a>
                    </div>
                </template>
            </div>
            <div class="tab-pane" id="control-General-Note">
                <div class="d-flex align-items-center justify-content-between">
                    <h3 class="fw-500">Notes</h3>
                    <a href="javascript:void(0)" @click.prevent="newRedirect('AdminNote')" class="waves-effect waves-light btn btn-info btn-xs"><i class="fa fa-plus "></i> New</a>
                </div>
                <template v-if="show_admin_note_form">
                    <div class="form-group mb-10">
                        <label class="form-label">Note</label>
                        <textarea type="text" v-model="admin_note_input.note" class="form-control" rows="2"></textarea>
                        <div
                          class="invalid-feedback"
                          style="display:block;"
                          v-if="admin_note_note_error"
                        >
                          {{ admin_note_note_error }}
                        </div>
                    </div>
                    <div class="text-end">
                        <a href="javascript:void(0)"  class="waves-effect waves-light btn btn-info me-1" @click="handleAdminNoteSubmit">Submit</a>
                        <a href="javascript:void(0)" @click.prevent="show_admin_note_form=!show_admin_note_form" class="waves-effect waves-light btn btn-default">Cancel</a>
                    </div>
                </template>
                <div class="d-flex align-items-center justify-content-between">
                    <h3 class="fw-500"></h3>
                </div>
                <template v-if="!show_admin_note_form">
                    <template v-if="noteListFiltered.length > 0">
                        <div class="notesList bt-1 py-10" v-for="(item,index) in noteListFiltered" :key="index">
                            <template v-if="item.category == 'WcCheck'">
                                <p class="mb-1">WC Check note</p>
                            </template>
                            <template v-else>
                                <p class="mb-1" v-if="item.note.length<50">{{ item.note }}</p>
                                <p class="mb-1" v-else>{{ item.note.substring(0,50)+".." }}</p>
                            </template>
                            <div class="row">
                                <div class="col-5 col-sm-5 col-md-5">
                                    <p class="mb-1">Date: <span class="fw-500">{{ $filters.displayDateTimeLocal(item.created_at) }}</span></p>
                                </div>
                                <div class="col-5 col-sm-5 col-md-5">
                                    <p class="mb-1">By: <span class="fw-500">{{ item.username }}</span></p>
                                </div>
                                <div class="col-2 col-sm-2 col-md-2 text-center">
                                    <a href="javascript:void(0)" @click="editAdminNote(item)" class="waves-effect waves-light btn btn-info-light btn-xs mx-1"><i class="fa fa-pencil"></i></a>
                                    <a href="javascript:void(0)"  @click="deleteAdminNote(item)" class="waves-effect waves-light btn btn-danger-light btn-xs"><i class="fa fa-trash"></i></a>
                                </div>
                            </div>
                        </div>
                    </template>
                    <div v-if="demo_admin_note['notes'] != null">
                        <p class="mb-1">{{ demo_admin_note['notes'].substring(0,50)+"..." }}
                        <br>
                        Date: <span class="fw-500">{{ $filters.displayDateTimeLocal(demo_admin_note['created_at'])}}</span></p>
                    </div>
                    <template v-if="noteListFiltered.length == 0 && demo_admin_note['notes'] == null">
                        <div class="notesList bt-1 py-10" >
                            <p class="mb-1">No record found.</p>
                        </div>
                    </template>
                </template>
            </div>
            <div class="tab-pane" id="control-med-lists">
                <div class="d-flex align-items-center justify-content-between">
                    <h3 class="fw-500">Notes</h3>
                    <a href="javascript:void(0)" @click.prevent="newRedirect('MedList')" class="waves-effect waves-light btn btn-info btn-xs"><i class="fa fa-plus "></i> New</a>
                </div>
                <template v-if="!show_admin_note_form">
                    <template v-if="noteListFiltered.length > 0">
                        <div class="notesList bt-1 py-10" v-for="(item,index) in noteListFiltered" :key="index">
                            <template v-if="item.category == 'WcCheck'">
                                <p class="mb-1">WC Check note</p>
                            </template>
                            <template v-else>
                                <p class="mb-1" v-if="item.note.length<50">{{ item.note }}</p>
                                <p class="mb-1" v-else>{{ item.note.substring(0,50)+".." }}</p>
                            </template>
                            <div class="row">
                                <div class="col-5 col-sm-5 col-md-5">
                                    <p class="mb-1">Date: <span class="fw-500">{{ $filters.displayDateTimeLocal(item.created_at) }}</span></p>
                                </div>
                                <div class="col-5 col-sm-5 col-md-5">
                                    <p class="mb-1">By: <span class="fw-500">{{ item.username }}</span></p>
                                </div>
                                <div class="col-2 col-sm-2 col-md-2 text-center">
                                    <a href="javascript:void(0)" @click="editAdminNote(item)" class="waves-effect waves-light btn btn-info-light btn-xs mx-1"><i class="fa fa-pencil"></i></a>
                                    <a href="javascript:void(0)"  @click="deleteAdminNote(item)" class="waves-effect waves-light btn btn-danger-light btn-xs"><i class="fa fa-trash"></i></a>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-if="noteListFiltered.length == 0">
                        <div class="notesList bt-1 py-10" >
                            <p class="mb-1">No record found.</p>
                        </div>
                    </template>
                </template>
            </div>
        </div>
    </aside>
</template>
<script>
    import permissionMixins from "@/scripts/mixins/permission.js";
    import axios from "@/scripts/axios.js";
    import FormBtn from "@/components/base/formFields/formBtn.vue";
    import submitMixins from "@/scripts/mixins/submitMixins.js";
    import useVuelidate from "@vuelidate/core";
    import {
    	required,
    	helpers,
    	minLength,
    	maxLength,
    	numeric,
    	requiredIf
    } from "@vuelidate/validators";
    export default {
    	props: {
            patientId: {
              type: Number,
              required: true
            }
        },
        components: {
            FormBtn
        },
    	setup() {
    		return { v$: useVuelidate() };
    	},
    	mixins: [submitMixins,permissionMixins],
        computed: {
            noteListFiltered: function() {
                let $this = this;
                return this.admin_note_list.filter(function(el) {
                    return ($this.current_category == 'All' ? true : el.category == $this.current_category);
                });
            }
        },
    	data() {
    		return {
                refill_status_id: '',
                refill_status_name: '',
                refill_pharmacy_id: '',
                refill_pharmacy_name: '',
                is_new_refill_status: false,
                is_new_refill_pharmacy: false,
                admin_note_input : {
                    note : '',
                    category : 'All',
                    confirmation_received : 'Yes',
                },
                admin_wc_check_note :[],
                admin_wc_check_note_default : [
                    {
                        'question' : 'Do you have any immediate concerns at this time that I can help you with?',
                        'answer' : '',
                    },
                    {
                        'question' : 'How are you feeling?',
                        'answer' : '',
                    },
                    {
                        'question' : 'Are you exercising more?',
                        'answer' : '',
                    },
                    {
                        'question' : 'Do you have any questions or concerns with nutrition or diet?',
                        'answer' : '',
                    },
                    {
                        'question' : 'Are you following your protocol closely?',
                        'answer' : '',
                    },
                    {
                        'question' : 'Has your quality of life improved?',
                        'answer' : '',
                    },
                    {
                        'question' : 'What positive changes have you noticed since our last session?',
                        'answer' : '',
                    },
                    {
                        'question' : 'Do you need any refills?',
                        'answer' : '',
                    },
                    {
                        'question' : 'Do you have any other questions?',
                        'answer' : '',
                    },
                    {
                        'question' : 'How long has it been Going On?',
                        'answer' : '',
                    },
                    {
                        'question' : 'How Severe is It?',
                        'answer' : '',
                    },
                    {
                        'question' : 'How Often does it occur?',
                        'answer' : '',
                    },
                    {
                        'question' : 'Does anything make it Better?',
                        'answer' : '',
                    },
                    {
                        'question' : 'Does anything make it Worse?',
                        'answer' : '',
                    },
                    {
                        'question' : 'Anything else you would want to tell me?',
                        'answer' : '',
                    }
                ],
                admin_note_note_error: '',
                admin_note_category_error: '',
                show_admin_note_form: false,
                admin_cat_disabled: false,
                admin_note_list: [],
                current_category: 'All',
                admin_note_refill_status: [],
                admin_note_refill_pharmacy: [],
                demo_admin_note: ''
    		};
    	},
    	async created() {
            this.getPatientAdminNote();
    	},
    	mounted() {
            this.admin_wc_check_note = this.admin_wc_check_note_default;
            this.getAdminNoteRefillStatusAndPharmacy();
    	},
    	methods: {
            newRedirect(category) {                 
                this.$router.push({
                    name:"NewNotes",
                    params: {
                    cat:category,
                    type:'create'
                    },
                });
            },
            getAdminNoteRefillStatusAndPharmacy() {
                axios.post("/json_dropdown/getAdminNoteRefillStatus").then((response) => {
                    this.admin_note_refill_status = response.data.data;
                });
                axios.post("/json_dropdown/getAdminNoteRefillPharmacy").then((response) => {
                    this.admin_note_refill_pharmacy = response.data.data;
                });
            },
            addNewNoteAll() {
                this.admin_note_input.admin_note_id='';
                this.admin_note_input.category = ''
                this.current_category='All';
                this.admin_note_input.note='';
                this.admin_cat_disabled=false;
                this.admin_wc_check_note = this.admin_wc_check_note_default;
                this.admin_note_note_error= '';
                this.admin_note_category_error= '';
                $("#editNotesside").removeClass("control-sidebar-open");
                this.$store.state.patientDetailsComp = "NewNotes";
                let props = this.$router.resolve({
                    name: "NewNotes",
                    params: { cat:'All', type:'create'},
                });
                if (history.pushState && props) {
                    history.pushState(null, "", props.href);
                }
                // this.$router.push({
                //     name:"NewNotes",
                //     params: {
                //     cat:'All',
                //     type:'create'
                //     },
                // });
            },
            setCurrentCategory() {
                this.is_new_refill_status= false;
                this.is_new_refill_pharmacy= false;
                this.refill_status_id = '';
                this.refill_pharmacy_id = '';
                this.refill_pharmacy_name = '';
                this.refill_status_name = '';
                this.admin_note_input.note = '';
                //this.current_category = this.admin_note_input.category;
            },
            getPatientAdminNote() {
                axios.post("/patient/getAdminNote", { patient_id: this.patientId }).then((response) => {
                    this.admin_note_list = response.data.data['notes'];
                    this.demo_admin_note = response.data.data['admin_note'];
                });
            },
            getAdminNoteBytab(category,id) {
                $(".tab-content .tab-pane").removeClass("active");
                $("#"+id).addClass("active");
                if (category == 'WcCheck') {
                    this.admin_wc_check_note = this.admin_wc_check_note_default;
                }
                this.admin_note_input.note = '';
                this.admin_note_input.category = category;
                this.current_category = category;
                this.show_admin_note_form = false;
            },
            deleteAdminNote(item) {
                this.$swal
                    .fire({
                        title: '<h2 class="mt-0">Are you sure?</h2>',
                        text:
                            "You want to delete these records? This process cannot be undone.",
                        icon: "warning",
                        dangerMode: true,
                        showCancelButton: true,
                        confirmButtonColor: "#ee3158",
                        cancelButtonColor: "#bdbdbd",
                        confirmButtonText: "Yes, delete it!",
                    })
                    .then((result) => {
                        if (result.isConfirmed == true) {
                            axios.post("/patient/deleteAdminNote", item).
                            then((response) => {
                                this.moshaToast(response.data.message, "success");
                                this.getPatientAdminNote(item.patient_id);
                            });
                        }
                    });
            },
            editAdminNote(item) {
                /* if (item.category == 'WcCheck') {
                     this.admin_wc_check_note = JSON.parse(item.note);
                }
                this.refill_status_id = item.refill_status_id;
                this.refill_pharmacy_id = item.refill_pharmacy_id;
                this.admin_note_input.confirmation_received = item.confirmation_received;
                this.admin_note_input.note =  item.note;
                this.admin_note_input.category =  item.category;
                this.admin_note_input.admin_note_id =  item.admin_note_id;
                this.show_admin_note_form = true;
                this.admin_cat_disabled = true; */
                this.$router.push({
                   // path: "wellness-notes/edit-notes/"+item.admin_note_id,
                    name:"NewNotes",
                    params: {
                    note_id: item.admin_note_id,
                    cat: item.category,
                    type:'update'
                   // id: this.$store.state.patientDetail.patient_id
                    },
                });
               // window.location.href = "http://localhost:8093/#/patient-chart/1000015/wellness-notes/new-notes/All/admin_note_id/"+item.admin_note_id;
            },
            handleAdminNoteSubmit() {
                if (this.admin_note_input.category == 'Refill') {
                    this.admin_note_input.refill_status_id = this.refill_status_id;
                    this.admin_note_input.refill_status_name = this.refill_status_name;
                    this.admin_note_input.refill_pharmacy_id = this.refill_pharmacy_id;
                    this.admin_note_input.refill_pharmacy_name = this.refill_pharmacy_name;
                } else {
                    this.admin_note_input.refill_status_id = '';
                    this.admin_note_input.refill_status_name = '';
                    this.admin_note_input.refill_pharmacy_id = '';
                    this.admin_note_input.refill_pharmacy_name = '';
                }
                if (this.admin_note_input.category == 'WcCheck') {
                    this.admin_note_input.note = JSON.stringify(this.admin_wc_check_note);
                } else {
                    if (!this.admin_note_input.category || this.admin_note_input.category == 'All') {
                        this.admin_note_category_error = 'Category is required.';
                        return true;
                    }
                    if (!this.admin_note_input.note) {
                        this.admin_note_note_error = 'Note is required.';
                        return true;
                    }
                }
                this.admin_note_input.patient_id = this.$store.state.patientDetail.patient_id;
                axios.post("/patient/saveAdminNote", this.admin_note_input).
                then((response) => {
                    this.moshaToast(response.data.message, "success");
                    this.show_admin_note_form = false;
                    this.getPatientAdminNote(this.admin_note_input.patient_id);
                    this.getAdminNoteRefillStatusAndPharmacy();
                    this.is_new_refill_status= false;
                    this.is_new_refill_pharmacy= false;
                    this.refill_status_id = '';
                    this.refill_pharmacy_id = '';
                    this.refill_pharmacy_name = '';
                    this.refill_status_name = '';
                    this.admin_note_input.note = '';
                    this.admin_note_input.category = 'All';
                    this.admin_note_input.confirmation_received = 'Yes';
                });
            }
    	}
    }
</script>