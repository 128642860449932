<template>
  <div class="content-wrapper">
    <div class="container-full">
      <div class="content-header">
        <div class="row justify-content-between">
          <div class="col-12 col-sm-12 col-md-9 col-lg-6 col-xs-6">
            <h1 class="heading1 mt-0 mb-5">Archived Providers</h1>
          </div>
          <div class="col-12 col-sm-4 col-md-3 col-lg-3 col-xs-2 text-md-right">
            <a href="#/provider/list" class="waves-effect waves-light btn btn-info-light"><i class="fa fa-arrow-left fs-16"></i> Back </a>
          </div>
        </div>
      </div>

      <section class="content">
        <div class="box">
          <div class="box-body">
            <div class="table-responsive">
              <data-table dataType="ArchivedProvider">
                <template v-slot="slotProps">
                  <!-- slot for table data -->
                  <td>{{ slotProps.item.username }}</td>
                  <td>
                    {{ `${slotProps.item.full_name}` }}
                  </td>
                  <td>{{ slotProps.item.email }}</td>
                  <td>{{ slotProps.item.cell_phone }}</td>
                  <td>{{ slotProps.item.office_phone }}</td>
                  <td>{{ slotProps.item.supervisor_or_render }}</td>
                  <td>
                      <span v-if="slotProps.item.emergency_access == 1">Yes</span>
                      <span v-if="slotProps.item.emergency_access == 0">No</span>
                  </td>
                  <td>
                    <button type="button"  data-on="Ready" data-off="Not Ready" @click="onToggleChange(slotProps.item.id,slotProps.item.role_id,slotProps.item.email,$event)" :id="['status_' + slotProps.item.id]" class="btn btn-toggle rounded30-im"  v-bind:class="[slotProps.item.deleted_at == NULL ? 'active' : '']" data-bs-toggle="button" aria-pressed="true" autocomplete="off">
                      <div class="handle rounded30-im"></div>
                    </button>
                  </td>
                  
                </template>
              </data-table>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
// import 'jquery/dist/jquery.min.js';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import "datatables.net-dt/js/dataTables.dataTables"
// import "datatables.net-dt/css/jquery.dataTables.min.css"
import axios from "@/scripts/axios.js";
import dataTable from "@/components/base/DataTable.vue";
import submitMixins from "@/scripts/mixins/submitMixins.js";
import permissionMixins from "@/scripts/mixins/permission.js";

export default {
  name: "Home",
  mixins: [submitMixins,permissionMixins],
  components: { dataTable },
  data() {
    return {
      table_load_count: 0,
    };
  },
  methods: {
    onToggleChange(id,role_id,email,event) {
      var class_list = document.getElementById("status_"+id).classList;
      var active_deactive = '';
      if(class_list.contains('active')) {
        active_deactive = 'A';
      } else {
        active_deactive = 'D';
        // console.log(class_list.contains('active'));
      }

      axios
        .post("provider/reset",{"status":active_deactive,"provider_id":id,"email":email})
        .then((response) => {
          this.moshaToast('User has been restored successfully.','success');
          setTimeout(function(){ location.reload(); }, 3000);
        });

       

    },
    editItem(id) {
      this.$router.push({ name: "ProviderEdit", params: { id } });
    },
    deleteItem(id) {
      this.$swal
        .fire({
          title: '<h2 class="mt-0">Are you sure?</h2>',
          text:
            "You want to delete these records? This process cannot be undone.",
          icon: "warning",
          dangerMode: true,
          showCancelButton: true,
          confirmButtonColor: "#ee3158",
          cancelButtonColor: "#bdbdbd",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed == true) {
            axios
              .post(`/provider/delete`, { provider_id: id })
              .then((response) => {
                this.$swal
                  .fire(
                    "Deleted!",
                    "Patient problem has been deleted.",
                    "success"
                  )
                  .then((result) => {
                    if (result.isConfirmed == true) {
                      location.reload();
                    }
                  });
              });
          }
        });
    },
    resendVerificationLink(id) {
      this.$store.state.loader = true;
        axios
          .post(`resend-email-verification`, { email_user_id: id })
          .then((response) => {
            this.$store.state.loader = false;
            if (response.data.data.result == true) {
              this.$swal
                .fire(
                  "Success!",
                  "Email verification link has been send.",
                  "success"
                )
                .then((result) => {
                  if (result.isConfirmed == true) {
                    //location.reload();
                  }
                });
              console.log(response.data.data.result);
            } else {
              this.$swal
                .fire(
                  "Error!",
                  "Oops,something went wrong, please try again.",
                  "danger"
                )
                .then((result) => {
                  if (result.isConfirmed == true) {
                  }
                });
            }
          });
    },
  },
};
</script>
