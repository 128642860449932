import { required,helpers} from "@vuelidate/validators";

const Coupon = {
	namespaced: true,
  	
  	state: {
		header: [
			{"coupon_code":"Coupon Code"},
			{"amount":"Discount Amount"},
			{"source":"Source"},
			{"Action":"Action"},
		  ],
		  pagination: {
			to: "",
			from: "",
			total: "",
			last_page: "",
			current_page: "",
			pageNavArr: [],
		  },
    	form: {
			coupon_code: '',
			description: ''
		},    
    	vuelidateExternalResults: {
	      	form: {
	        	coupon_code: [],
				description: [],
	      	},
    	},
    	validationRules: {
	      	form: {
		        coupon_code: {
		          required: helpers.withMessage("Please enter a coupon code. ", required),
		        },
				description: [],
				expiry_date: {
					required: helpers.withMessage("Please select expiry date.", required),
				},
				type: {
					required: helpers.withMessage("Please select discount type.", required),
				},
				amount: {
					required: helpers.withMessage("Please enter discount value.", required),
				}
	      	},
    	},
    	listUrl: "/coupon/list",
    	getEditUrl: "/coupon/edit/:id",
    	list: [],
  	},
  	getters: {
    	getTableHeaders: (state) => state.header,
    	getTableContents: (state) => state.list,
    	getListURL: (state) => state.listUrl,
  	},
  	mutations: {},
  	actions: {},
}

export default Coupon;