import {
  helpers,
  required,
} from "@vuelidate/validators";

const auditRDSTemperProofLog = {
  namespaced: true,


  state: {
    list: "/audit-rds-templer-log/",
    listUrl: "/audit-rds-templer-log",
    //list: [],
    tableData: {
      header: [
         'Timestamp',
         'Ip No.',
         'IP',
         '#',
      ],
    },
  },
  getters: {
  },
  mutations: {},
  actions: {},
};

export default auditRDSTemperProofLog;
