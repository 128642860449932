<template>
    <div class="box p-15">
        <div class="mdc-tab">
            <div class="tab-content">
                <div class="tab-pane active" id="insuranceCard" role="tabpanel">
                    <form novalidate>
                        <select-box-static
                            title="Insurance Type"
                            v-model="v$.form.insurancetype.$model"
                            :errors="v$.form.insurancetype.$errors"
                            :option="insurancetype"
                            :isRequired="true">
                        ></select-box-static>

                        <file-upload id="uploadFiles" 
                        v-model="v$.form.uploadFiles_front.$model"
                        :errors="v$.form.uploadFiles_front.$errors"
                        :isRequired="true"
                        title="Insurance card front"
                        :coustom_error="front_error"
                        @change="front_validation"
                        note="Supported formats JPG, JPEG, PDF, PNG. Max allowed size is 10MB"
                        noteClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-6"
                        >
                        </file-upload>

                        <file-upload id="uploadFiles" 
                        v-model="v$.form.uploadFiles_rear.$model"
                        :errors="v$.form.uploadFiles_rear.$errors"
                        :isRequired="true" 
                        title="Insurance card rear"
                        :coustom_error="rear_error"
                        @change="rear_validation"
                        note="Supported formats JPG, JPEG, PDF, PNG. Max allowed size is 10MB"
                        noteClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-6"
                        >
                        </file-upload>
                    </form>
                    <div class="row mb-10" v-if="permissioncheck(this.$store.state.permission.create_update)">
                        <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 text-center">
                            <form-btn title="Upload" @click="upload_insurance" btnCls="btn-info saveData"></form-btn>
                        </div>
                    </div>

                    <div class="row">
                        <!-- Primary Insurance Start -->
                        <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                            <p class="fs-18 fw-400 mb-20">Primary Insurance</p>
                            <div class="row" v-if="this.primary">
                                <div class="col-6 col-sm-6 col-md-6 col-lg-6 text-center" v-if="this.primary.front_image != ''">
                                    <div class="position-relative">
                                        <img  v-if="this.primary.front_extension == 'png' || this.primary.front_extension == 'jpeg' || this.primary.front_extension == 'jpg'" :src="this.primary.front_image" class="img-fluid rounded5 zooms b-1" >
                                        <a v-if="this.primary.front_extension == 'png' || this.primary.front_extension == 'jpeg' || this.primary.front_extension == 'jpg'" href="javascript:void(0)" @click="imgpath(this.primary.front_image)" data-bs-toggle="modal" data-bs-target="#viewimg" class="bg-white py-5 px-10 rounded fs-18 position-absolute b-1" style="right: 0;bottom: 0px;">
                                            <i class="fa fa-search-plus"></i>
                                        </a>
                                    </div>
                                    <div class="position-relative" v-if="this.primary.front_extension == 'pdf'">
                                        <img  src="/images/pdf_icon.png" class="img-fluid rounded5" style="width:80%;" />
                                        <a v-if="this.primary.front_extension == 'pdf'" href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#viewpdf" @click="viewpdf(this.primary.front_image)" class="bg-white py-5 px-10 rounded fs-18 position-absolute b-1" style="right: 0;bottom: 0px;">
                                            <i class="fa fa-search-plus"></i>
                                        </a>
                                    </div>
                                    <p class="doc-text">Insurance card front</p>
                                </div>

                                <div class="col-6 col-sm-6 col-md-6 col-lg-6 text-center" v-if="this.primary.rear_image != ''">
                                    <div class="position-relative">
                                        <img v-if="this.primary.rear_extension == 'png' || this.primary.rear_extension == 'jpeg' || this.primary.rear_extension == 'jpg'" :src="this.primary.rear_image" class="img-fluid rounded5 zooms b-1">
                                        <a v-if="this.primary.rear_extension == 'png' || this.primary.rear_extension == 'jpeg' || this.primary.rear_extension == 'jpg'" href="javascript:void(0)" @click="imgpath(this.primary.rear_image)" data-bs-toggle="modal" data-bs-target="#viewimg" class="bg-white py-5 px-10 rounded fs-18 position-absolute b-1" style="right: 0;bottom: 0px;">
                                            <i class="fa fa-search-plus"></i>
                                        </a>
                                    </div>
                                    <div class="position-relative" v-if="this.primary.rear_extension == 'pdf'">
                                        <img src="/images/pdf_icon.png" class="img-fluid rounded5  b-1" style="width:80%;" />
                                        <a v-if="this.primary.rear_extension == 'pdf'" href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#viewpdf" @click="viewpdf(this.primary.rear_image)" class="bg-white py-5 px-10 rounded fs-18 position-absolute b-1"  style="right: 0;bottom: 0px;">
                                            <i class="fa fa-search-plus"></i>
                                        </a>
                                    </div>
                                    <p class="doc-text">Insurance card rear</p>
                                </div>
                                <small v-if="this.primary.rear_image != ''" class="text-mute mt-10 d-block"><b>Updated on </b>{{$filters.displayDateTimeLocal(this.primary.updated_at)}}</small>
                            </div>
                        </div>
                        <!-- Primary Insurance end -->

                        <!-- secondary Insurance start -->
                        <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                            <p class="fs-18 fw-400 mb-20">Secondary Insurance</p>
                            <div class="row" v-if="this.secondary">
                                <div class="col-6 col-sm-6 col-md-6 col-lg-6 text-center" v-if="this.secondary.front_image != ''">
                                    <div class="position-relative">
                                        <img  v-if="this.secondary.front_extension == 'png' || this.secondary.front_extension == 'jpeg' || this.secondary.front_extension == 'jpg'" :src="this.secondary.front_image" class="img-fluid rounded5 zooms b-1" >
                                        <a v-if="this.secondary.front_extension == 'png' || this.secondary.front_extension == 'jpeg' || this.secondary.front_extension == 'jpg'" href="javascript:void(0)" @click="imgpath(this.secondary.front_image)" data-bs-toggle="modal" data-bs-target="#viewimg" class="bg-white py-5 px-10 rounded fs-18 position-absolute b-1" style="right: 0;bottom: 0px;">
                                            <i class="fa fa-search-plus"></i>
                                        </a>
                                    </div>
                                    <div class="position-relative" v-if="this.secondary.front_extension == 'pdf'">
                                        <img  src="/images/pdf_icon.png" class="img-fluid rounded5" style="width:80%;" />
                                        <a v-if="this.secondary.front_extension == 'pdf'" href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#viewpdf" @click="viewpdf(this.secondary.front_image)" class="bg-white py-5 px-10 rounded fs-18 position-absolute b-1" style="right: 0;bottom: 0px;">
                                            <i class="fa fa-search-plus"></i>
                                        </a>
                                    </div>
                                    <p class="doc-text">Insurance card front</p>
                                </div>

                                <div class="col-6 col-sm-6 col-md-6 col-lg-6 text-center" v-if="this.secondary.rear_image != ''">
                                    <div class="position-relative">
                                        <img v-if="this.secondary.rear_extension == 'png' || this.secondary.rear_extension == 'jpeg' || this.secondary.rear_extension == 'jpg'" :src="this.secondary.rear_image" class="img-fluid rounded5 zooms b-1">
                                        <a v-if="this.secondary.rear_extension == 'png' || this.secondary.rear_extension == 'jpeg' || this.secondary.rear_extension == 'jpg'" href="javascript:void(0)" @click="imgpath(this.secondary.rear_image)" data-bs-toggle="modal" data-bs-target="#viewimg" class="bg-white py-5 px-10 rounded fs-18 position-absolute b-1" style="right: 0;bottom: 0px;">
                                            <i class="fa fa-search-plus"></i>
                                        </a>
                                    </div>
                                    <div class="position-relative" v-if="this.secondary.rear_extension == 'pdf'">
                                        <img src="/images/pdf_icon.png" class="img-fluid rounded5  b-1" style="width:80%;" />
                                        <a v-if="this.secondary.rear_extension == 'pdf'" href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#viewpdf" @click="viewpdf(this.secondary.rear_image)" class="bg-white py-5 px-10 rounded fs-18 position-absolute b-1"  style="right: 0;bottom: 0px;">
                                            <i class="fa fa-search-plus"></i>
                                        </a>
                                    </div>
                                    <p class="doc-text">Insurance card rear</p>
                                </div>
                                <small v-if="this.secondary.rear_image != ''" class="text-mute mt-10 d-block"><b>Updated on </b>{{$filters.displayDateTimeLocal(this.secondary.updated_at)}}</small>
                            </div>
                        </div>
                        <!-- secondary Insurance end -->

                        <!-- tertiary Insurance start -->
                        <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                            <p class="fs-18 fw-400 mb-20">Tertiary Insurance</p>
                            <div class="row mt-20" v-if="this.tertiary">
                                <div class="col-6 col-sm-6 col-md-6 col-lg-6 text-center" v-if="this.tertiary.front_image != ''">
                                    <div class="position-relative">
                                        <img  v-if="this.tertiary.front_extension == 'png' || this.tertiary.front_extension == 'jpeg' || this.tertiary.front_extension == 'jpg'" :src="this.tertiary.front_image" class="img-fluid rounded5 zooms b-1" >
                                        <a v-if="this.tertiary.front_extension == 'png' || this.tertiary.front_extension == 'jpeg' || this.tertiary.front_extension == 'jpg'" href="javascript:void(0)" @click="imgpath(this.tertiary.front_image)" data-bs-toggle="modal" data-bs-target="#viewimg" class="bg-white py-5 px-10 rounded fs-18 position-absolute b-1" style="right: 0;bottom: 0px;">
                                            <i class="fa fa-search-plus"></i>
                                        </a>
                                    </div>
                                    <div class="position-relative" v-if="this.tertiary.front_extension == 'pdf'">
                                        <img  src="/images/pdf_icon.png" class="img-fluid rounded5" style="width:80%;" />
                                        <a v-if="this.tertiary.front_extension == 'pdf'" href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#viewpdf" @click="viewpdf(this.tertiary.front_image)" class="bg-white py-5 px-10 rounded fs-18 position-absolute b-1" style="right: 0;bottom: 0px;">
                                            <i class="fa fa-search-plus"></i>
                                        </a>
                                    </div>
                                    <p class="doc-text">Insurance card front</p>
                                </div>

                                <div class="col-6 col-sm-6 col-md-6 col-lg-6 text-center" v-if="this.tertiary.rear_image != ''">
                                    <div class="position-relative">
                                        <img v-if="this.tertiary.rear_extension == 'png' || this.tertiary.rear_extension == 'jpeg' || this.tertiary.rear_extension == 'jpg'" :src="this.tertiary.rear_image" class="img-fluid rounded5 zooms b-1">
                                        <a v-if="this.tertiary.rear_extension == 'png' || this.tertiary.rear_extension == 'jpeg' || this.tertiary.rear_extension == 'jpg'" href="javascript:void(0)" @click="imgpath(this.tertiary.rear_image)" data-bs-toggle="modal" data-bs-target="#viewimg" class="bg-white py-5 px-10 rounded fs-18 position-absolute b-1" style="right: 0;bottom: 0px;">
                                            <i class="fa fa-search-plus"></i>
                                        </a>
                                    </div>
                                    <div class="position-relative" v-if="this.tertiary.rear_extension == 'pdf'">
                                        <img src="/images/pdf_icon.png" class="img-fluid rounded5  b-1" style="width:80%;" />
                                        <a v-if="this.tertiary.rear_extension == 'pdf'" href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#viewpdf" @click="viewpdf(this.tertiary.rear_image)" class="bg-white py-5 px-10 rounded fs-18 position-absolute b-1"  style="right: 0;bottom: 0px;">
                                            <i class="fa fa-search-plus"></i>
                                        </a>
                                    </div>
                                    <p class="doc-text">Insurance card rear</p>
                                </div>
                                <small v-if="this.tertiary.rear_image != ''" class="text-mute mt-10 d-block"><b>Updated on </b>{{$filters.displayDateTimeLocal(this.tertiary.updated_at)}}</small>
                            </div>
                        </div>
                        <!-- tertiary Insurance end -->      
                    </div>
                </div>
            </div>
        </div>
    </div>

<!--viewReceipt Modal Start-->
<div class="modal" id="viewpdf" tabindex="-1">
    <div class="modal-dialog modal-md" style="max-width:75%">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="btn-close btn-xs" @click="close"></button>
            </div>
            <div class="modal-body">
                <vue-pdf-viewer height="1050px" :url="document_name_url" v-if="embed=0">
                </vue-pdf-viewer>
                <iframe :src="document_name_url" width="100%" height="500px" v-if="embed=1" />
                <img srd="images/1.gif" v-else> 
            </div>
        </div>
    </div>
</div>
<!--viewReceipt Modal end-->
<!--view Image Modal Start-->
<div class="modal" id="viewimg" tabindex="-1">
    <div class="modal-dialog modal-xl">
        <div class="modal-content bg-white" style="height: 93vh;">
            <button  type="button" class="btn-close btn-xs position-absolute bg-white p-5" @click="closeImg()" style="right: 4px;top: 8px;z-index: 1;"></button>
            <div class="modal-body text-center d-flex align-items-center" style="overflow: hidden;">
                <img :src="document_name_url" class="img-fluid rounded5 zoom">
            </div>
            <div class="text-center my-5">
                <a @click="zoomplus" href="javascript:void(0)" class="waves-effect waves-light btn btn-info-light fs-16 btn-xs me-2"><i class="fa fa-search-plus"></i></a>
                <a @click="zoomminus" href="javascript:void(0)" class="waves-effect waves-light btn btn-info-light fs-16 btn-xs me-2"><i class="fa fa-search-minus"></i></a>
            </div>
        </div>
    </div>
</div>
<!--viewImage Modal end-->
</template>
<script>
import InputField from "@/components/base/formFields/InputField.vue";
import FileUpload from "@/components/base/formFields/fileUpload.vue";
import FormBtn from "@/components/base/formFields/formBtn.vue";
import useVuelidate from "@vuelidate/core";
import axios from "@/scripts/axios.js";
import submitMixins from "@/scripts/mixins/submitMixins.js";
import permissionMixins from "@/scripts/mixins/permission.js";
import SelectBoxStatic from "@/components/base/formFields/SelectBoxStatic.vue";

import {
  helpers,
  required,
  email,
  minLength,
  maxLength,
} from "@vuelidate/validators";

export default {
    name: "PatientIdentification",
    mixins: [submitMixins, permissionMixins],
    setup() {
        return { v$: useVuelidate() };
    },
    props: {
        patient_id: {
          type: Number,
          required: true
        },
        patient_unique_id: {
          type: Number,
          required: true
        }
    },
    data() {
        return {
            front_error:'',
            rear_error:'',
            back_document_url:'',
            front_document_url:'',
            insurancecardback:'',
            insurancecardfront:'',
            embed: 0,
            document_name_url:'',
            updated_at:'',
            scale: 0.5,
            insurancetype: [
                { value: "266", title: "Primary Insurance" },
                { value: "267", title: "Secondary Insurance" },
                { value: "268", title: "Tertiary Insurance" }
            ],
            primary:[],
            secondary:[],
            tertiary:[],
        }
    },
    components: {
        InputField,
        FileUpload,
        FormBtn,
        SelectBoxStatic
    },
    computed: {
        form() {
            return this.$store.state.insuranceDetail.form;
        },
        vuelidateExternalResults() {
            return this.$store.state.insuranceDetail.vuelidateExternalResults;
        },
        validationRules() {
            return this.$store.state.insuranceDetail.validationRules;
        },
        title() {
            return "Patient Identification";
        },
    },
    mounted() {
        this.getimg();
    },
    beforeCreate() {
        this.$store.state.insuranceDetail.form = {};
        Object.assign(
            this.$store.state.insuranceDetail.form,
            this.$store.state.insuranceDetail.defaultFormData
        );        
    },
    methods: {
        closeImg() {
            $('#viewimg').modal('hide');
        },
        front_validation(){
            if(this.form.uploadFiles_front.type != 'application/pdf' && this.form.uploadFiles_front.type != 'image/jpeg' && this.form.uploadFiles_front.type != 'image/png'){
                this.front_error = "Only JPG, JPEG, PDF, PNG formats are supported. Max allowed size is 10MB.";
            }
            else if(this.form.uploadFiles_front.size > 10240000){
                this.front_error ="Only JPG, JPEG, PDF, PNG formats are supported. Max allowed size is 10MB.";    
            }
            else{
                this.front_error = '';
            }
        },
        rear_validation(){
            if(this.form.uploadFiles_rear.type != 'application/pdf' && this.form.uploadFiles_rear.type != 'image/jpeg' && this.form.uploadFiles_rear.type != 'image/png'){
                this.rear_error = "Only JPG, JPEG, PDF, PNG formats are supported. Max allowed size is 10MB.";
            }
            else if(this.form.uploadFiles_rear.size > 10240000){
                this.rear_error ="Only JPG, JPEG, PDF, PNG formats are supported. Max allowed size is 10MB.";    
            }
            else{
                this.rear_error = '';
            }
        },
        imgpath(url){
            this.document_name_url = url;    
        },
        zoomclose(){
            this.scale = 1.5;
            $(".zoom").css("transform", "scale("+this.scale+")");
        },
        zoomplus(){
            this.scale = this.scale+0.5;
            $(".zoom").css("transform", "scale("+this.scale+")");
        },
        zoomminus(){
            if(this.scale > 0.5){
                this.scale = this.scale-0.5;
                $(".zoom").css("transform", "scale("+this.scale+")");
            }
        },
        close(){
            this.document_name_url ='';
            $('#viewpdf').modal('hide');
        },
        viewpdf(url){
            this.document_name_url  = url;
        },
        getimg(){
            axios.post("patient/"+this.patient_unique_id+"/documents/insurance-card",{patient_id:this.patient_id})
            .then((response) => {
                if(response.data.data.primary != null){
                    this.primary.front_image = process.env.VUE_APP_API_BASE_URL+"/stream-image?path="+response.data.data.primary.front_image+"&slang="+response.data.data.primary.clinic_id;
                    this.primary.rear_image = process.env.VUE_APP_API_BASE_URL+"/stream-image?path="+response.data.data.primary.rear_image+"&slang="+response.data.data.primary.clinic_id;    
                    const primary_front = response.data.data.primary.front_image.split(".");
                    const primary_rear = response.data.data.primary.rear_image.split(".");
                    this.primary.front_extension = primary_front[1];                
                    this.primary.rear_extension = primary_rear[1];                
                    this.primary.updated_at = response.data.data.primary.updated_at;
                }
                else{
                    this.primary = [];
                }

                if(response.data.data.secondary!= null){
                    this.secondary.front_image = process.env.VUE_APP_API_BASE_URL+"/stream-image?path="+response.data.data.secondary.front_image+"&slang="+response.data.data.secondary.clinic_id;
                    this.secondary.rear_image = process.env.VUE_APP_API_BASE_URL+"/stream-image?path="+response.data.data.secondary.rear_image+"&slang="+response.data.data.secondary.clinic_id;    
                    const secondary_front = response.data.data.secondary.front_image.split(".");
                    const secondary_rear = response.data.data.secondary.rear_image.split(".");
                    this.secondary.front_extension = secondary_front[1];                
                    this.secondary.rear_extension = secondary_rear[1];                
                    this.secondary.updated_at = response.data.data.secondary.updated_at;
                }
                else{
                    this.secondary = [];
                }

                if(response.data.data.tertiary != null){
                    this.tertiary.front_image = process.env.VUE_APP_API_BASE_URL+"/stream-image?path="+response.data.data.tertiary.front_image+"&slang="+response.data.data.tertiary.clinic_id;
                    this.tertiary.rear_image = process.env.VUE_APP_API_BASE_URL+"/stream-image?path="+response.data.data.tertiary.rear_image+"&slang="+response.data.data.tertiary.clinic_id;    
                    const tertiary_front = response.data.data.tertiary.front_image.split(".");
                    const tertiary_rear = response.data.data.tertiary.rear_image.split(".");
                    this.tertiary.front_extension = tertiary_front[1];                
                    this.tertiary.rear_extension = tertiary_rear[1];                
                    this.tertiary.updated_at = response.data.data.tertiary.updated_at;
                }
                else{
                    this.tertiary = [];
                }
                console.log(this.primary);
                console.log(this.secondary);
                console.log(this.tertiary);
            });
        },
        upload_insurance()
        {
            if(this.front_error == '' && this.rear_error == ''){
                this.$store.state.insuranceDetail.form.id = this.$store.state.insuranceDetail.form.insurancetype;
                this.$store.state.insuranceDetail.form.patient_id = this.patient_id;
                this.postForm(`/patient/${this.patient_unique_id}/documents/store_insurance`,'documents',true,"formdata");  
            }
        },
        formPostSuccess(data) {
            this.$store.state.insuranceDetail.form = {};
            this.v$.$reset();
            this.moshaToast(
                `Record ${"added"} successfully`,
                "success"
            );
            this.getimg();
        },
        browserCheck(){
            // Opera 8.0+
            var isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

            // Firefox 1.0+
            var isFirefox = typeof InstallTrigger !== 'undefined';

            // Safari 3.0+ "[object HTMLElementConstructor]" 
            var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));

            // Internet Explorer 6-11
            var isIE = /*@cc_on!@*/false || !!document.documentMode;

            // Edge 20+
            var isEdge = !isIE && !!window.StyleMedia;

            // Chrome 1 - 79
            var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

            // Edge (based on chromium) detection
            var isEdgeChromium = isChrome && (navigator.userAgent.indexOf("Edg") != -1);

            // Blink engine detection
            var isBlink = (isChrome || isOpera) && !!window.CSS;

            if (isChrome==true) {
                this.embed = 0;
            } else {
                this.embed = 1;
            }
        },
    },
    validations() {
        return this.$store.state.insuranceDetail.validationRules;
    },
}
</script>