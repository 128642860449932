<template>
  <select class="form-control" :name="select" :value="modelValue" @change="$emit('selectmodelValue', $event.target.value)">
    <option value="" selected>Select Time</option>
    <option v-for="(item, index) in time_array" :value="item" :selected="item == modelValue">{{$filters.timeFormat(item)}}</option>
  </select>
</template>

<script>
import submitMixins from "@/scripts/mixins/submitMixins.js";
export default {
  mixins: [submitMixins],
  name: "timeDropDown",
  data() {
    return {
        time_array: null,
    };
  },
  props: [ "select", "modelValue" ],
  mounted() {
    var interval = 15,times = [],t = 0;
    for (var i=0;t<=1200; i++) {
      if(i >= 32){
        var h = Math.floor(t/60);
        var m = (t%60);
        times[i] = ("0" + (h % 24)).slice(-2) + ':' + ("0" + m).slice(-2);
      }
      t = t + interval;
    }
    this.time_array = times.filter(item => item);
  }
};
</script>