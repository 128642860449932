<template>  
    <div class="box">
        <div class="box-body">
            <h2 class="font-bold mt-0 mb-20">Insurance Information <a href="#" data-bs-toggle="modal" data-bs-target="#insuranceinfo" title="Reference of Knowledge Base" v-on:click.prevent="reset()" class="fs-18"> <i class="fa fa-question-circle"></i> </a></h2>
            <div class="mdc-tab">
                <ul class="nav nav-tabs customtab2 text-center text-md-start" role="tablist">
                    <li class="nav-item"> <a class="nav-link active" data-bs-toggle="tab" href="#primary_ins_info" role="tab" aria-selected="true">Primary</a> </li>
                    <li class="nav-item"> <a class="nav-link disabled" data-bs-toggle="tab" href="#secoundry_ins_info" role="tab" aria-selected="false">Secondary</a> </li>
                    <!-- <li class="nav-item"> <a class="nav-link" data-bs-toggle="tab" href="#tertiary_ins_info" role="tab" aria-selected="false">Tertiary</a> </li> -->
                     <li class="nav-item"> <a class="nav-link disabled" data-bs-toggle="tab" href="#auto_accident_ins_info" role="tab" aria-selected="false">Auto Accident</a> </li>
                    <li class="nav-item"> <a class="nav-link disabled" data-bs-toggle="tab" href="#worker_comp_ins_info" role="tab" aria-selected="false">Worker's Compensation</a> </li>
                    <!-- 
                    <li class="nav-item"> <a class="nav-link" data-bs-toggle="tab" href="#durable_med_eqpt" role="tab" aria-selected="false">Durable Med. Eqpt.</a> </li> -->
                    <li class="nav-item"> <a class="nav-link" title="Click here to manage Copay/Co-Insurance Amount" data-bs-toggle="tab" href="#copay_or_coins" role="tab" aria-selected="false">Copay/CoIns. Amount</a> </li>
                    <li v-if="permissioncheck(this.$store.state.permission.Eligibility_Check_Access)" title="Click here to Check Eligibility" class="nav-item"> <a class="nav-link" data-bs-toggle="tab" href="#eligibility_check_tab" role="tab" aria-selected="false">Eligibility Check</a> </li>
                </ul>
            </div>
            <div class="tab-content py-10">
                <!-- Patient's primary insurance details-->
                <div class="tab-pane active" id="primary_ins_info" role="tabpanel">
                    <div class="form-group row align-items-center">
                        <label class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end">Subscriber is the Patient </label>
                        <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4">
                            <input id="primary_p" type="checkbox" class="filled-in chk-col-info" @change="scrollToDiv('primary')" v-model="form.primary.patient_is_subscriber">
                            <label for="primary_p" class="m-0 text-start fs-16"></label>           
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end">Insurance Company  </label>
                        <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4">
                            <div class="selectcontrols">
                                <Autocomplete :max="30" @input="getlist" ref="autocomplete" :results="insurance_list" @onSelect="resultsvalue" :use-html-for-results ="true" class="form-control ms-0 autosearch-list" placeholder="Insurance Company"></Autocomplete>
                            </div>
                        </div>
                    </div>                   

                    <div class="form-group row">
                        <label class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end">Healthcare Program</label>
                        <div class="col-12 col-sm-6 col-md-8 col-lg-8 col-xl-8">
                            <div class="row">
                                <div class="col-6 col-sm-6 col-md-6 col-lg-3">
                                    <input type="radio" id="medicare" name="form.primary.healthcare_program" value="medicare" class="filled-in chk-col-info" v-model="form.primary.healthcare_program"/>
                                    <label for="medicare">Medicare</label>
                                </div>
                                <div class="col-6 col-sm-6 col-md-6 col-lg-3">
                                    <input type="radio" id="medicaid" name="form.primary.healthcare_program" value="medicaid" class="filled-in chk-col-info" v-model="form.primary.healthcare_program"/>
                                    <label for="medicaid">Medicaid</label>
                                </div>
                                <div class="col-6 col-sm-6 col-md-6 col-lg-3">
                                    <input type="radio" id="tricare" name="form.primary.healthcare_program" value="tricare" class="filled-in chk-col-info" v-model="form.primary.healthcare_program"/>
                                    <label for="tricare">Tricare</label>
                                </div>
                                <div class="col-6 col-sm-6 col-md-6 col-lg-3">
                                    <input type="radio" id="champva" name="form.primary.healthcare_program" value="champva" class="filled-in chk-col-info" v-model="form.primary.healthcare_program"/>
                                    <label for="champva">Champva</label>
                                </div>
                                <div class="col-6 col-sm-6 col-md-6 col-lg-3">
                                    <input type="radio" id="group_health_plan" name="form.primary.healthcare_program" value="group_health_plan" class="filled-in chk-col-info" v-model="form.primary.healthcare_program"/>
                                    <label for="group_health_plan">Group Health Plan</label>
                                </div>
                                <div class="col-6 col-sm-6 col-md-6 col-lg-3">
                                    <input type="radio" id="FECA" name="form.primary.healthcare_program" value="FECA" class="filled-in chk-col-info" v-model="form.primary.healthcare_program"/>
                                    <label for="FECA">FECA</label>
                                </div>
                                <div class="col-6 col-sm-6 col-md-6 col-lg-3">
                                    <input type="radio" id="Other" name="form.primary.healthcare_program" value="other" class="filled-in chk-col-info" v-model="form.primary.healthcare_program"/>
                                    <label for="Other">Other</label>
                                </div>
                                <span class="form-text text-muted">used in CMS 1500 form field#1</span>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end">Insurance Payer ID</label>
                        <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4">
                            <div class="selectcontrols">
                                <Autocomplete :max="30" @input="getlist2" ref="autocomplete2" :results="insurance_list2" @onSelect="resultsvalue2" :use-html-for-results ="true" class="form-control ms-0 autosearch-list" placeholder="Insurance Payer ID"></Autocomplete>
                            </div>
                        </div>
                    </div>
                    <input-field title="Insurance Company ID Number" divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4" labelClass="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end" v-model="form.primary.insurance_id_number"> </input-field>

                    <text-area title="Insurance Company Address" divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4" labelClass="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end" v-model="form.primary.insurance_company_address"> </text-area>

                    <input-field title="Insurance Company Zipcode" @focusout="zipcode('patient_primary')" divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4" labelClass="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end" v-model="form.primary.insurance_company_zip_code"> </input-field>

                    <input-field title="Insurance Company City" v-model="form.primary.insurance_company_city" divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4" labelClass="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end"> </input-field>

                    <div class="form-group row">
                        <label class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end">Insurance Company State </label>
                        <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4">
                            <Select2
                            v-model="form.primary.insurance_company_state_id"
                            id="primary_insurance_company_state_id"
                            placeholder="Select State"
                            :options="states"
                            />            
                        </div>
                    </div>

                    <input-field title="Insurance Group Name" v-model="form.primary.insurance_group_name" divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4" labelClass="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end" note="*if available" noteClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-4"> </input-field>

                    <input-field title="Insurance Group Number/FECA Number" v-model="form.primary.insurance_group_number" divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4" labelClass="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end" note="*if available" noteClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-4"> </input-field>

                    <input-field title="Insurance Plan Name" v-model="form.primary.insurance_plan_name" divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4" labelClass="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end" note="*if available" noteClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-4"> </input-field>

                    <div class="form-group row">
                        <label class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end">Insurance plan type </label>
                        <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4">
                            <select id="id_primary-insurance_plan_type" class="form-control" v-model="form.primary.insurance_plan_type">
                                <option value="" selected=""></option>
                                <option :selected="form.primary.insurance_plan_type == 'AM'" value="AM">Automobile Medical</option>
                                <option :selected="form.primary.insurance_plan_type == 'BL'" value="BL">Blue Cross/Blue Shield</option>
                                <option :selected="form.primary.insurance_plan_type == 'CH'" value="CH">Champus</option>
                                <option :selected="form.primary.insurance_plan_type == 'CI'" value="CI">Commercial Insurance Co.</option>
                                <option :selected="form.primary.insurance_plan_type == '17'" value="17">Dental Maintenance Organization</option>
                                <option :selected="form.primary.insurance_plan_type == 'DS'" value="DS">Disability</option>
                                <option :selected="form.primary.insurance_plan_type == '14'" value="14">Exclusive Provider Organization (EPO)</option>
                                <option :selected="form.primary.insurance_plan_type == 'FI'" value="FI">Federal Employees Program</option>
                                <option :selected="form.primary.insurance_plan_type == 'HM'" value="HM">Health Maintenance Organization</option>
                                <option :selected="form.primary.insurance_plan_type == '16'" value="16">Health Maintenance Organization (HMO) Medicare Risk</option>
                                <option :selected="form.primary.insurance_plan_type == '15'" value="15">Indemnity Insurance</option>
                                <option :selected="form.primary.insurance_plan_type == 'LM'" value="LM">Liability Medical</option>
                                <option :selected="form.primary.insurance_plan_type == 'MC'" value="MC">Medicaid</option>
                                <option :selected="form.primary.insurance_plan_type == 'MA'" value="MA">Medicare Part A</option>
                                <option :selected="form.primary.insurance_plan_type == 'MB'" value="MB">Medicare Part B</option>
                                <option :selected="form.primary.insurance_plan_type == 'ZZ'" value="ZZ">Mutually Defined</option>
                                <option :selected="form.primary.insurance_plan_type == 'OF'" value="OF">Other Federal Program</option>
                                <option :selected="form.primary.insurance_plan_type == '11'" value="11">Other Non-Federal Programs</option>
                                <option :selected="form.primary.insurance_plan_type == '13'" value="13">Point of Service (POS)</option>
                                <option :selected="form.primary.insurance_plan_type == '12'" value="12">Preferred Provider Organization (PPO)</option>
                                <option :selected="form.primary.insurance_plan_type == 'TV'" value="TV">Title V</option>
                                <option :selected="form.primary.insurance_plan_type == 'VA'" value="VA">Veterans Affairs Plan</option>
                                <option :selected="form.primary.insurance_plan_type == 'WC'" value="WC">Workers' Compensation Health Claim</option>
                            </select>         
                        </div>
                        <div class="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-4">
                            <small class="form-text text-muted">*if available</small>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end">Number visits allowed per year</label>
                        <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4">
                            <input type="number" class="form-control" min="0" v-model="form.primary.visit_per_year">
                        </div>
                    </div>
                    <file-upload id="uploadFilesFP" 
                    v-model="v$.form.front_image.$model"
                    :errors="v$.form.front_image.$errors"
                    divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4"
                    labelClass="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end" title="Insurance card front" notes="Notes:- Only JPG, JPEG, PNG, PDF format is allowed." noteClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-4">
                    </file-upload>

                    <div class="row justify-content-sm-center mb-20" v-if="primary_front_image != null">
                        <div class="col-3 col-sm-2 col-md-2 col-lg-2 text-center">
                            <div class="position-relative">
                                <img  v-if="primary_front_image_ext == 'png' || primary_front_image_ext == 'jpeg' || primary_front_image_ext == 'jpg'" :src="primary_front_image" class="img-fluid rounded5 zooms b-1" >
                                <a v-if="primary_front_image_ext == 'png' || primary_front_image_ext == 'jpeg' || primary_front_image_ext == 'jpg'" href="javascript:void(0)" @click="imgpath(primary_front_image)" class="bg-white py-5 px-10 rounded fs-18 position-absolute b-1" style="right: 0;bottom: 0px;">
                                    <i class="fa fa-search-plus"></i>
                                </a>
                            </div>
                            <div class="position-relative" v-if="primary_front_image_ext == 'pdf'">
                                <img  src="/images/pdf_icon.png" class="img-fluid rounded5" style="width:80%;" />
                                <a v-if="primary_front_image_ext == 'pdf'" href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#viewpdf" @click="viewpdf(primary_front_image)" class="bg-white py-5 px-10 rounded fs-18 position-absolute b-1" style="right: 0;bottom: 0px;">
                                    <i class="fa fa-search-plus"></i>
                                </a>
                            </div>
                            <p class="doc-text">Insurance card front</p>
                        </div>  
                    </div>


                    <file-upload id="uploadFilesRP" 
                    v-model="v$.form.rear_image.$model"
                    :errors="v$.form.rear_image.$errors"
                    divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4"
                    labelClass="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end"
                    title="Insurance card rear" notes="Notes:- Only JPG, JPEG, PNG, PDF format is allowed." noteClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-4">
                    </file-upload>

                    <div class="row justify-content-sm-center mb-20" v-if="primary_rear_image != null">
                        <div class="col-3 col-sm-2 col-md-2 col-lg-2 text-center">
                            <div class="position-relative">
                                <img  v-if="primary_rear_image_ext == 'png' || primary_rear_image_ext == 'jpeg' || primary_rear_image_ext == 'jpg'" :src="primary_rear_image" class="img-fluid rounded5 zooms b-1" >
                                <a v-if="primary_rear_image_ext == 'png' || primary_rear_image_ext == 'jpeg' || primary_rear_image_ext == 'jpg'" href="javascript:void(0)" @click="imgpath(primary_rear_image)" data-bs-toggle="modal" data-bs-target="#viewimg" class="bg-white py-5 px-10 rounded fs-18 position-absolute b-1" style="right: 0;bottom: 0px;">
                                    <i class="fa fa-search-plus"></i>
                                </a>
                            </div>
                            <div class="position-relative" v-if="primary_rear_image_ext == 'pdf'">
                                <img  src="/images/pdf_icon.png" class="img-fluid rounded5" style="width:80%;" />
                                <a v-if="primary_rear_image_ext == 'pdf'" href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#viewpdf" @click="viewpdf(primary_rear_image)" class="bg-white py-5 px-10 rounded fs-18 position-absolute b-1" style="right: 0;bottom: 0px;">
                                    <i class="fa fa-search-plus"></i>
                                </a>
                            </div>
                            <p class="doc-text">Insurance card rear</p>
                        </div>
                    </div>
                    <div class="bg-lightest p-15 mb-20" id="primary_subscriber_info" v-if="form.primary.patient_is_subscriber == false">
                        <h2 class="font-bold mb-20">If patient and subscriber are not same</h2>
                        <div class="form-group row">
                            <label class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end">Patient relationship to the Subscriber </label>
                            <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4">
                                <select id="id_primary-patient_relationship_to_subscriber" class="form-control" v-model="form.primary.subscriber_relation">
                                    <option value="" selected=""></option>
                                    <option :selected="form.primary.subscriber_relation == '01'" value="01">Spouse</option>
                                    <option :selected="form.primary.subscriber_relation == '04'" value="04">Grandparent</option>
                                    <option :selected="form.primary.subscriber_relation == '05'" value="05">Grandchild</option>
                                    <option :selected="form.primary.subscriber_relation == '07'" value="07">Nephew or Niece</option>
                                    <option :selected="form.primary.subscriber_relation == '10'" value="10">Foster Child</option>
                                    <option :selected="form.primary.subscriber_relation == '15'" value="15">Ward</option>
                                    <option :selected="form.primary.subscriber_relation == '17'" value="17">Stepson or Stepdaughter</option>
                                    <option :selected="form.primary.subscriber_relation == '19'" value="19">Child</option>
                                    <option :selected="form.primary.subscriber_relation == '20'" value="20">Employee</option>
                                    <option :selected="form.primary.subscriber_relation == '21'" value="21">Unknown</option>
                                    <option :selected="form.primary.subscriber_relation == '22'" value="22">Handicapped Dependent</option>
                                    <option :selected="form.primary.subscriber_relation == '23'" value="23">Sponsored Dependent</option>
                                    <option :selected="form.primary.subscriber_relation == '24'" value="24">Dependent of a Minor Dependent</option>
                                    <option :selected="form.primary.subscriber_relation == '29'" value="29">Significant Other</option>
                                    <option :selected="form.primary.subscriber_relation == '32'" value="32">Mother</option>
                                    <option :selected="form.primary.subscriber_relation == '33'" value="33">Father</option>
                                    <option :selected="form.primary.subscriber_relation == '36'" value="36">Emancipated Minor</option>
                                    <option :selected="form.primary.subscriber_relation == '39'" value="39">Organ Donor</option>
                                    <option :selected="form.primary.subscriber_relation == '40'" value="40">Cadaver Donor</option>
                                    <option :selected="form.primary.subscriber_relation == '41'" value="41">Injured Plaintiff</option>
                                    <option :selected="form.primary.subscriber_relation == '43'" value="43">Child Where Insured Has No Financial Responsibility</option>
                                    <option :selected="form.primary.subscriber_relation == '53'" value="53">Life Partner</option>
                                    <option :selected="form.primary.subscriber_relation == '76'" value="76">Dependent</option>
                                    <option :selected="form.primary.subscriber_relation == 'G8'" value="G8">Other Relationship</option>
                                </select>           
                            </div>
                            <div class="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-4"><small class="form-text text-muted">*If patient and subscriber are not same</small></div>
                        </div>

                        <input-field title="Subscriber First Name" divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4" labelClass="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end" v-model="form.primary.subscriber_first_name" noteClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-4"></input-field>

                        <input-field title="Subscriber Middle Name" divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4" labelClass="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end" v-model="form.primary.subscriber_middle_name" noteClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-4"></input-field>

                        <input-field title="Subscriber Last Name" divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4" labelClass="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end" v-model="form.primary.subscriber_last_name" noteClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-4"></input-field>

                        <div class="form-group row">
                            <label class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end"> Subscriber DOB </label>
                            <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4 availdate">
                                <ejs-datepicker :value="primary_subscriber_dob" id="primary_subscriber_dob" v-model="form.primary.subscriber_dob" :format="dateFormat" placeholder="Date Of Birth" @change="primary_getdobdate($event)" name="primary_subscriber_dob" :strictMode='strict' :max='maxVal' :showClearButton='false' :openOnFocus='true'>
                                </ejs-datepicker>
                            </div>
                        </div>

                        <input-field title="Subscriber SSN" divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4" labelClass="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end" noteClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-4" v-model="form.primary.subscriber_ssn"> </input-field>

                        <text-area title="Subscriber Address" divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4" labelClass="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end" noteClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-4" v-model="form.primary.subscriber_address"> </text-area>

                        <input-field title="Subscriber Zipcode" divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4" labelClass="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end" noteClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-4" @focusout="zipcode('sub_primary')" v-model="form.primary.subscriber_zip_code"> </input-field>

                        <input-field title="Subscriber City" divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4" labelClass="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end" noteClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-4" v-model="form.primary.subscriber_city"> </input-field>

                        <div class="form-group row">
                            <label class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end">Subscriber State </label>
                            <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4">
                                <Select2
                                v-model="form.primary.subscriber_state_id"
                                id="primary_subscriber_state_id"
                                placeholder="Select State"
                                :options="states"
                                />            
                            </div>                            
                        </div>

                        <input-field title="Subscriber Phone" mask="(000) 000-0000" divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4" labelClass="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end" noteClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-4" v-model="form.primary.subscriber_phone"> </input-field> 

                        <select-box-static title="Subscriber Gender" divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4" labelClass="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end" v-model="form.primary.subscriber_gender" noteClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-4"  :option="sex"></select-box-static>

                    </div>
                    <div class=" text-center">
                        <form-btn title="Save" btnCls="btn-info" @click="save('primary')"></form-btn>         
                    </div>
                </div>
                <!-- Patient's primary insurance details -- End -->

                <!-- Patient's secondary insurance details -->
                <div class="tab-pane" id="secoundry_ins_info" role="tabpanel">
                    <div class="form-group row align-items-center">
                        <label class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end">Subscriber is the Patient </label>
                        <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4">
                            <input id="secondary_p" type="checkbox" class="filled-in chk-col-info" @change="scrollToDiv('secondary')" v-model="form.secondary.patient_is_subscriber">
                            <label for="secondary_p" class="m-0 text-start fs-16"></label>           
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end">Secondary Insurance </label>
                        <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4">
                            <div class="selectcontrols">
                                <Autocomplete :max="30" @input="getlist3" ref="autocomplete3" :results="insurance_list3" @onSelect="resultsvalue3" :use-html-for-results ="true" class="form-control ms-0 autosearch-list" placeholder="Secondary Insurance"></Autocomplete>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end">Healthcare Program</label>
                        <div class="col-12 col-sm-6 col-md-8 col-lg-8 col-xl-8">
                            <div class="row">
                                <div class="col-6 col-sm-6 col-md-6 col-lg-3">
                                    <input type="radio" id="secondary_medicare" name="form.secondary.healthcare_program" value="medicare" class="filled-in chk-col-info" v-model="form.secondary.healthcare_program"/>
                                    <label for="secondary_medicare">Medicare</label>
                                </div>
                                <div class="col-6 col-sm-6 col-md-6 col-lg-3">
                                    <input type="radio" id="secondary_medicaid" name="form.secondary.healthcare_program" value="medicaid" class="filled-in chk-col-info" v-model="form.secondary.healthcare_program"/>
                                    <label for="secondary_medicaid">Medicaid</label>
                                </div>
                                <div class="col-6 col-sm-6 col-md-6 col-lg-3">
                                    <input type="radio" id="secondary_tricare" name="form.secondary.healthcare_program" value="tricare" class="filled-in chk-col-info" v-model="form.secondary.healthcare_program"/>
                                    <label for="secondary_tricare">Tricare</label>
                                </div>
                                <div class="col-6 col-sm-6 col-md-6 col-lg-3">
                                    <input type="radio" id="secondary_champva" name="form.secondary.healthcare_program" value="champva" class="filled-in chk-col-info" v-model="form.secondary.healthcare_program"/>
                                    <label for="secondary_champva">Champva</label>
                                </div>
                                <div class="col-6 col-sm-6 col-md-6 col-lg-3">
                                    <input type="radio" id="secondary_group_health_plan" name="form.secondary.healthcare_program" value="group_health_plan" class="filled-in chk-col-info" v-model="form.secondary.healthcare_program"/>
                                    <label for="secondary_group_health_plan">Group Health Plan</label>
                                </div>
                                <div class="col-6 col-sm-6 col-md-6 col-lg-3">
                                    <input type="radio" id="secondary_FECA" name="form.secondary.healthcare_program" value="FECA" class="filled-in chk-col-info" v-model="form.secondary.healthcare_program"/>
                                    <label for="secondary_FECA">FECA</label>
                                </div>
                                <div class="col-6 col-sm-6 col-md-6 col-lg-3">
                                    <input type="radio" id="secondary_Other" name="form.secondary.healthcare_program" value="other" class="filled-in chk-col-info" v-model="form.secondary.healthcare_program"/>
                                    <label for="secondary_Other">Other</label>
                                </div>
                                <span class="form-text text-muted">used in CMS 1500 form field#1</span>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end">Secondary Insurance Payer ID</label>
                        <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4">
                            <div class="selectcontrols">
                                <Autocomplete :max="30" @input="getlist4" ref="autocomplete4" :results="insurance_list4" @onSelect="resultsvalue4" :use-html-for-results ="true" class="form-control ms-0 autosearch-list" placeholder="Secondary Insurance"></Autocomplete>
                            </div>
                        </div>
                    </div>

                    <input-field title=" Secondary Insurance Company ID Number" divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4" labelClass="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end" v-model="form.secondary.insurance_id_number"> </input-field>

                    <text-area title="Secondary Insurance Address" divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4" labelClass="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end" v-model="form.secondary.insurance_company_address"> </text-area>

                    <input-field title="Secondary Insurance Zipcode" divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4" labelClass="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end" @focusout="zipcode('patient_secondary')" v-model="form.secondary.insurance_company_zip_code"> </input-field>

                    <input-field title="Secondary Insurance City" divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4" labelClass="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end" v-model="form.secondary.insurance_company_city"> </input-field>

                    <div class="form-group row">
                        <label class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end">Secondary Insurance State</label>
                        <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4">
                            <Select2
                            v-model="form.secondary.insurance_company_state_id"
                            id="secondary_insurance_company_state_id"
                            placeholder="Select State"
                            :options="states"
                            />            
                        </div>
                    </div>   

                    <input-field title="Secondary Insurance Group Name" v-model="form.secondary.insurance_group_name" divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4" labelClass="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end" note="*if available" noteClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-4"> </input-field>

                    <input-field title="Secondary Insurance Group Number/FECA Number" v-model="form.secondary.insurance_group_number" divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4" labelClass="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end" note="*if available" noteClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-4"> </input-field>         

                    <input-field title="Secondary Insurance Plan Name" v-model="form.secondary.insurance_plan_name" divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4" labelClass="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end" note="*if available" noteClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-4"> </input-field>

                    <div class="form-group row">
                        <label class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end">Secondary Insurance plan type </label>
                        <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4">
                            <select id="id_secondary-insurance_plan_type" class="form-control" v-model="form.secondary.insurance_plan_type">
                                <option value="" selected=""></option>
                                <option :selected="form.secondary.insurance_plan_type == 'AM'" value="AM">Automobile Medical</option>
                                <option :selected="form.secondary.insurance_plan_type == 'BL'" value="BL">Blue Cross/Blue Shield</option>
                                <option :selected="form.secondary.insurance_plan_type == 'CH'" value="CH">Champus</option>
                                <option :selected="form.secondary.insurance_plan_type == 'CI'" value="CI">Commercial Insurance Co.</option>
                                <option :selected="form.secondary.insurance_plan_type == '17'" value="17">Dental Maintenance Organization</option>
                                <option :selected="form.secondary.insurance_plan_type == 'DS'" value="DS">Disability</option>
                                <option :selected="form.secondary.insurance_plan_type == '14'" value="14">Exclusive Provider Organization (EPO)</option>
                                <option :selected="form.secondary.insurance_plan_type == 'FI'" value="FI">Federal Employees Program</option>
                                <option :selected="form.secondary.insurance_plan_type == 'HM'" value="HM">Health Maintenance Organization</option>
                                <option :selected="form.secondary.insurance_plan_type == '16'" value="16">Health Maintenance Organization (HMO) Medicare Risk</option>
                                <option :selected="form.secondary.insurance_plan_type == '15'" value="15">Indemnity Insurance</option>
                                <option :selected="form.secondary.insurance_plan_type == 'LM'" value="LM">Liability Medical</option>
                                <option :selected="form.secondary.insurance_plan_type == 'MC'" value="MC">Medicaid</option>
                                <option :selected="form.secondary.insurance_plan_type == 'MA'" value="MA">Medicare Part A</option>
                                <option :selected="form.secondary.insurance_plan_type == 'MB'" value="MB">Medicare Part B</option>
                                <option :selected="form.secondary.insurance_plan_type == 'ZZ'" value="ZZ">Mutually Defined</option>
                                <option :selected="form.secondary.insurance_plan_type == 'OF'" value="OF">Other Federal Program</option>
                                <option :selected="form.secondary.insurance_plan_type == '11'" value="11">Other Non-Federal Programs</option>
                                <option :selected="form.secondary.insurance_plan_type == '13'" value="13">Point of Service (POS)</option>
                                <option :selected="form.secondary.insurance_plan_type == '12'" value="12">Preferred Provider Organization (PPO)</option>
                                <option :selected="form.secondary.insurance_plan_type == 'TV'" value="TV">Title V</option>
                                <option :selected="form.secondary.insurance_plan_type == 'VA'" value="VA">Veterans Affairs Plan</option>
                                <option :selected="form.secondary.insurance_plan_type == 'WC'" value="WC">Workers' Compensation Health Claim</option>
                            </select>         
                        </div>
                        <div class="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-4">
                            <small class="form-text text-muted">*if available</small>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end">Number visits allowed per year</label>
                        <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4">
                            <input type="number" class="form-control" min="0" v-model="form.secondary.visit_per_year">
                        </div>
                    </div>

                    <file-upload id="uploadFilesFS" 
                    v-model="v$.form.front_image.$model"
                    :errors="v$.form.front_image.$errors"
                    divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4"
                    labelClass="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end"
                    title="Insurance card front" notes="Notes:- Only JPG, JPEG, PNG, PDF format is allowed." noteClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-4">
                    </file-upload>

                    <div class="row justify-content-sm-center mb-20" v-if="secondary_front_image != null">
                        <div class="col-3 col-sm-2 col-md-2 col-lg-2 text-center">
                            <div class="position-relative">
                                <img  v-if="secondary_front_image_ext == 'png' || secondary_front_image_ext == 'jpeg' || secondary_front_image_ext == 'jpg'" :src="secondary_front_image" class="img-fluid rounded5 zooms b-1" >
                                <a v-if="secondary_front_image_ext == 'png' || secondary_front_image_ext == 'jpeg' || secondary_front_image_ext == 'jpg'" href="javascript:void(0)" @click="imgpath(secondary_front_image)" data-bs-toggle="modal" data-bs-target="#viewimg" class="bg-white py-5 px-10 rounded fs-18 position-absolute b-1" style="right: 0;bottom: 0px;">
                                    <i class="fa fa-search-plus"></i>
                                </a>
                            </div>
                            <div class="position-relative" v-if="secondary_front_image_ext == 'pdf'">
                                <img src="/images/pdf_icon.png" class="img-fluid rounded5" style="width:80%;" />
                                <a v-if="secondary_front_image_ext == 'pdf'" href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#viewpdf" @click="viewpdf(secondary_front_image)" class="bg-white py-5 px-10 rounded fs-18 position-absolute b-1" style="right: 0;bottom: 0px;">
                                    <i class="fa fa-search-plus"></i>
                                </a>
                            </div>
                            <p class="doc-text">Insurance card front</p>
                        </div>
                    </div>

                    <file-upload id="uploadFilesRS" 
                    v-model="v$.form.rear_image.$model"
                    :errors="v$.form.rear_image.$errors"
                    divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4"
                    labelClass="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end"
                    title="Insurance card rear" notes="Notes:- Only JPG, JPEG, PNG, PDF format is allowed." noteClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-4">
                    </file-upload>

                    <div class="row justify-content-sm-center mb-20" v-if="secondary_rear_image != null">
                        <div class="col-3 col-sm-2 col-md-2 col-lg-2 text-center" >
                            <div class="position-relative">
                                <img  v-if="secondary_rear_image_ext == 'png' || secondary_rear_image_ext == 'jpeg' || secondary_rear_image_ext == 'jpg'" :src="secondary_rear_image" class="img-fluid rounded5 zooms b-1" >
                                <a v-if="secondary_rear_image_ext == 'png' || secondary_rear_image_ext == 'jpeg' || secondary_rear_image_ext == 'jpg'" href="javascript:void(0)" @click="imgpath(secondary_rear_image)" data-bs-toggle="modal" data-bs-target="#viewimg" class="bg-white py-5 px-10 rounded fs-18 position-absolute b-1" style="right: 0;bottom: 0px;">
                                    <i class="fa fa-search-plus"></i>
                                </a>
                            </div>
                            <div class="position-relative" v-if="secondary_rear_image_ext == 'pdf'">
                                <img  src="/images/pdf_icon.png" class="img-fluid rounded5" style="width:80%;" />
                                <a v-if="secondary_rear_image_ext == 'pdf'" href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#viewpdf" @click="viewpdf(secondary_rear_image)" class="bg-white py-5 px-10 rounded fs-18 position-absolute b-1" style="right: 0;bottom: 0px;">
                                    <i class="fa fa-search-plus"></i>
                                </a>
                            </div>
                            <p class="doc-text">Insurance card rear</p>
                        </div>
                    </div>

                    <div class="bg-lightest p-15 mb-20" id="secondary_subscriber_info"  v-if="form.secondary.patient_is_subscriber == false">
                        <h2 class="font-bold mb-20">If patient and subscriber are not same</h2>
                        <div class="form-group row">
                            <label class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end">Patient relationship to the Subscriber </label>
                            <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4">
                                <select id="id_secondary-patient_relationship_to_subscriber" class="form-control" v-model="form.secondary.subscriber_relation">
                                    <option value="" selected=""></option>
                                    <option :selected="form.secondary.subscriber_relation == '01'" value="01">Spouse</option>
                                    <option :selected="form.secondary.subscriber_relation == '04'" value="04">Grandparent</option>
                                    <option :selected="form.secondary.subscriber_relation == '05'" value="05">Grandchild</option>
                                    <option :selected="form.secondary.subscriber_relation == '07'" value="07">Nephew or Niece</option>
                                    <option :selected="form.secondary.subscriber_relation == '10'" value="10">Foster Child</option>
                                    <option :selected="form.secondary.subscriber_relation == '15'" value="15">Ward</option>
                                    <option :selected="form.secondary.subscriber_relation == '17'" value="17">Stepson or Stepdaughter</option>
                                    <option :selected="form.secondary.subscriber_relation == '19'" value="19">Child</option>
                                    <option :selected="form.secondary.subscriber_relation == '20'" value="20">Employee</option>
                                    <option :selected="form.secondary.subscriber_relation == '21'" value="21">Unknown</option>
                                    <option :selected="form.secondary.subscriber_relation == '22'" value="22">Handicapped Dependent</option>
                                    <option :selected="form.secondary.subscriber_relation == '23'" value="23">Sponsored Dependent</option>
                                    <option :selected="form.secondary.subscriber_relation == '24'" value="24">Dependent of a Minor Dependent</option>
                                    <option :selected="form.secondary.subscriber_relation == '29'" value="29">Significant Other</option>
                                    <option :selected="form.secondary.subscriber_relation == '32'" value="32">Mother</option>
                                    <option :selected="form.secondary.subscriber_relation == '33'" value="33">Father</option>
                                    <option :selected="form.secondary.subscriber_relation == '36'" value="36">Emancipated Minor</option>
                                    <option :selected="form.secondary.subscriber_relation == '39'" value="39">Organ Donor</option>
                                    <option :selected="form.secondary.subscriber_relation == '40'" value="40">Cadaver Donor</option>
                                    <option :selected="form.secondary.subscriber_relation == '41'" value="41">Injured Plaintiff</option>
                                    <option :selected="form.secondary.subscriber_relation == '43'" value="43">Child Where Insured Has No Financial Responsibility</option>
                                    <option :selected="form.secondary.subscriber_relation == '53'" value="53">Life Partner</option>
                                    <option :selected="form.secondary.subscriber_relation == '76'" value="76">Dependent</option>
                                    <option :selected="form.secondary.subscriber_relation == 'G8'" value="G8">Other Relationship</option>
                                </select>           
                            </div>                            
                        </div>

                        <input-field title="Subscriber First Name" divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4" labelClass="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end" v-model="form.secondary.subscriber_first_name" noteClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-4"></input-field>

                        <input-field title="Subscriber Middle Name" divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4" labelClass="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end" v-model="form.secondary.subscriber_middle_name" noteClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-4"></input-field>

                        <input-field title="Subscriber Last Name" divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4" labelClass="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end" v-model="form.secondary.subscriber_last_name" noteClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-4"></input-field>

                        <div class="form-group row">
                            <label class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end"> Subscriber DOB </label>
                            <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4 availdate">
                                <ejs-datepicker :value="secondary_subscriber_dob" id="secondary_subscriber_dob" v-model="form.secondary.subscriber_dob" :format="dateFormat" placeholder="Date Of Birth" @change="secondary_getdobdate($event)" name="secondary_subscriber_dob" :strictMode='strict' :max='maxVal' :showClearButton='false' :openOnFocus='true'>
                                </ejs-datepicker>
                            </div>                            
                        </div>

                        <input-field title="Subscriber SSN" divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4" labelClass="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end" v-model="form.secondary.subscriber_ssn" noteClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-4"> </input-field>

                        <text-area title="Subscriber Address" divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4" labelClass="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end" v-model="form.secondary.subscriber_address" noteClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-4" notes="*If patient and subscriber are not same"> </text-area>

                        <input-field title="Subscriber Zipcode" @focusout="zipcode('sub_secondary')" divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4" labelClass="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end" v-model="form.secondary.subscriber_zip_code" noteClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-4"> </input-field>

                        <input-field title="Subscriber City" divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4" labelClass="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end" noteClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-4" v-model="form.secondary.subscriber_city"> </input-field>

                        <div class="form-group row">
                            <label class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end">Subscriber State </label>
                            <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4">
                                <Select2
                                v-model="form.secondary.subscriber_state_id"
                                id="secondary_subscriber_state_id"
                                placeholder="Select State"
                                :options="states"
                                />            
                            </div>
                            <div class="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-4"><small class="form-text text-muted">*If patient and subscriber are not same</small></div>
                        </div>

                        <input-field title="Subscriber Phone" mask="(000) 000-0000" divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4" labelClass="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end" noteClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-4" v-model="form.secondary.subscriber_phone"> </input-field>

                        <select-box-static
                        title="Subscriber Gender"
                        v-model="form.secondary.subscriber_gender"
                        :option="sex"
                        divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4" noteClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-4" labelClass="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end"
                        ></select-box-static>
                    </div>
                    <div class=" text-center">
                        <form-btn
                        title="Save"
                        btnCls="btn-info"
                        @click="save('secondary')"
                        ></form-btn>         
                    </div>
                </div>
                <!-- Patient's secondary insurance details -- End -->

                <!-- Patient's tertiary insurance details-->
                <div class="tab-pane" id="tertiary_ins_info" role="tabpanel">
                    <div class="form-group row align-items-center">
                        <label class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end">Subscriber is the Patient </label>
                        <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4">
                            <input id="tertiary_p" type="checkbox" class="filled-in chk-col-info" @change="scrollToDiv('tertiary')" v-model="form.tertiary.patient_is_subscriber">
                            <label for="tertiary_p" class="m-0 text-start fs-16"></label>           
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end">Tertiary Insurance </label>
                        <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4">
                            <div class="selectcontrols">
                                <Autocomplete :max="30" @input="getlist5" ref="autocomplete5" :results="insurance_list5" @onSelect="resultsvalue5" :use-html-for-results ="true" class="form-control ms-0 autosearch-list" placeholder="Tertiary Insurance"></Autocomplete>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end">Healthcare Program</label>
                        <div class="col-12 col-sm-6 col-md-8 col-lg-8 col-xl-8">
                            <div class="row">
                                <div class="col-6 col-sm-6 col-md-6 col-lg-3">
                                    <input type="radio" id="tertiary_medicare" name="form.tertiary.healthcare_program" value="medicare" class="filled-in chk-col-info" v-model="form.tertiary.healthcare_program"/>
                                    <label for="tertiary_medicare">Medicare</label>
                                </div>
                                <div class="col-6 col-sm-6 col-md-6 col-lg-3">
                                    <input type="radio" id="tertiary_medicaid" name="form.tertiary.healthcare_program" value="medicaid" class="filled-in chk-col-info" v-model="form.tertiary.healthcare_program"/>
                                    <label for="tertiary_medicaid">Medicaid</label>
                                </div>
                                <div class="col-6 col-sm-6 col-md-6 col-lg-3">
                                    <input type="radio" id="tertiary_tricare" name="form.tertiary.healthcare_program" value="tricare" class="filled-in chk-col-info" v-model="form.tertiary.healthcare_program"/>
                                    <label for="tertiary_tricare">Tricare</label>
                                </div>
                                <div class="col-6 col-sm-6 col-md-6 col-lg-3">
                                    <input type="radio" id="tertiary_champva" name="form.tertiary.healthcare_program" value="champva" class="filled-in chk-col-info" v-model="form.tertiary.healthcare_program"/>
                                    <label for="tertiary_champva">Champva</label>
                                </div>
                                <div class="col-6 col-sm-6 col-md-6 col-lg-3">
                                    <input type="radio" id="tertiary_group_health_plan" name="form.tertiary.healthcare_program" value="group_health_plan" class="filled-in chk-col-info" v-model="form.tertiary.healthcare_program"/>
                                    <label for="tertiary_group_health_plan">Group Health Plan</label>
                                </div>
                                <div class="col-6 col-sm-6 col-md-6 col-lg-3">
                                    <input type="radio" id="tertiary_FECA" name="form.tertiary.healthcare_program" value="FECA" class="filled-in chk-col-info" v-model="form.tertiary.healthcare_program"/>
                                    <label for="tertiary_FECA">FECA</label>
                                </div>
                                <div class="col-6 col-sm-6 col-md-6 col-lg-3">
                                    <input type="radio" id="tertiary_Other" name="form.tertiary.healthcare_program" value="other" class="filled-in chk-col-info" v-model="form.tertiary.healthcare_program"/>
                                    <label for="tertiary_Other">Other</label>
                                </div>
                                <span class="form-text text-muted">used in CMS 1500 form field#1</span>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end">Tertiary Insurance Payer ID</label>
                        <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4">
                            <div class="selectcontrols">
                                <Autocomplete :max="30" @input="getlist6" ref="autocomplete6" :results="insurance_list6" @onSelect="resultsvalue6" :use-html-for-results ="true" class="form-control ms-0 autosearch-list" placeholder="tertiary Insurance"></Autocomplete>
                            </div>
                        </div>
                    </div>

                    <input-field title=" Tertiary Insurance Company ID Number" divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4" labelClass="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end" v-model="form.tertiary.insurance_id_number"> </input-field>

                    <text-area title="Tertiary Insurance Address" divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4" labelClass="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end" v-model="form.tertiary.insurance_company_address"> </text-area>

                    <input-field title="Tertiary Insurance Zipcode" divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4" labelClass="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end" @focusout="zipcode('patient_tertiary')" v-model="form.tertiary.insurance_company_zip_code"> </input-field>

                    <input-field title="Tertiary Insurance City" divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4" labelClass="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end" v-model="form.tertiary.insurance_company_city"> </input-field>

                    <div class="form-group row">
                        <label class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end">Tertiary Insurance State</label>
                        <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4">
                            <Select2
                            v-model="form.tertiary.insurance_company_state_id"
                            id="tertiary_insurance_company_state_id"
                            placeholder="Select State"
                            :options="states"
                            />            
                        </div>
                    </div>   

                    <input-field title="Tertiary Insurance Group Name" v-model="form.tertiary.insurance_group_name" divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4" labelClass="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end" note="*if available" noteClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-4"> </input-field>

                    <input-field title="Tertiary Insurance Group Number/FECA Number" v-model="form.tertiary.insurance_group_number" divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4" labelClass="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end" note="*if available" noteClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-4"> </input-field>         

                    <input-field title="Tertiary Insurance Plan Name" v-model="form.tertiary.insurance_plan_name" divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4" labelClass="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end" note="*if available" noteClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-4"> </input-field>

                    <div class="form-group row">
                        <label class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end">Tertiary Insurance plan type </label>
                        <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4">
                            <select id="id_tertiary-insurance_plan_type" class="form-control" v-model="form.tertiary.insurance_plan_type">
                                <option value="" selected=""></option>
                                <option :selected="form.tertiary.insurance_plan_type == 'AM'" value="AM">Automobile Medical</option>
                                <option :selected="form.tertiary.insurance_plan_type == 'BL'" value="BL">Blue Cross/Blue Shield</option>
                                <option :selected="form.tertiary.insurance_plan_type == 'CH'" value="CH">Champus</option>
                                <option :selected="form.tertiary.insurance_plan_type == 'CI'" value="CI">Commercial Insurance Co.</option>
                                <option :selected="form.tertiary.insurance_plan_type == '17'" value="17">Dental Maintenance Organization</option>
                                <option :selected="form.tertiary.insurance_plan_type == 'DS'" value="DS">Disability</option>
                                <option :selected="form.tertiary.insurance_plan_type == '14'" value="14">Exclusive Provider Organization (EPO)</option>
                                <option :selected="form.tertiary.insurance_plan_type == 'FI'" value="FI">Federal Employees Program</option>
                                <option :selected="form.tertiary.insurance_plan_type == 'HM'" value="HM">Health Maintenance Organization</option>
                                <option :selected="form.tertiary.insurance_plan_type == '16'" value="16">Health Maintenance Organization (HMO) Medicare Risk</option>
                                <option :selected="form.tertiary.insurance_plan_type == '15'" value="15">Indemnity Insurance</option>
                                <option :selected="form.tertiary.insurance_plan_type == 'LM'" value="LM">Liability Medical</option>
                                <option :selected="form.tertiary.insurance_plan_type == 'MC'" value="MC">Medicaid</option>
                                <option :selected="form.tertiary.insurance_plan_type == 'MA'" value="MA">Medicare Part A</option>
                                <option :selected="form.tertiary.insurance_plan_type == 'MB'" value="MB">Medicare Part B</option>
                                <option :selected="form.tertiary.insurance_plan_type == 'ZZ'" value="ZZ">Mutually Defined</option>
                                <option :selected="form.tertiary.insurance_plan_type == 'OF'" value="OF">Other Federal Program</option>
                                <option :selected="form.tertiary.insurance_plan_type == '11'" value="11">Other Non-Federal Programs</option>
                                <option :selected="form.tertiary.insurance_plan_type == '13'" value="13">Point of Service (POS)</option>
                                <option :selected="form.tertiary.insurance_plan_type == '12'" value="12">Preferred Provider Organization (PPO)</option>
                                <option :selected="form.tertiary.insurance_plan_type == 'TV'" value="TV">Title V</option>
                                <option :selected="form.tertiary.insurance_plan_type == 'VA'" value="VA">Veterans Affairs Plan</option>
                                <option :selected="form.tertiary.insurance_plan_type == 'WC'" value="WC">Workers' Compensation Health Claim</option>
                            </select>         
                        </div>
                        <div class="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-4">
                            <small class="form-text text-muted">*if available</small>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end">Number visits allowed per year</label>
                        <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4">
                            <input type="number" class="form-control" min="0" v-model="form.tertiary.visit_per_year">
                        </div>
                    </div>

                    <file-upload id="uploadFilesFT" 
                    v-model="v$.form.front_image.$model"
                    :errors="v$.form.front_image.$errors"
                    divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4"
                    labelClass="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end"
                    title="Insurance card front" notes="Notes:- Only JPG, JPEG, PNG, PDF format is allowed." noteClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-4">
                    </file-upload>

                    <div class="row justify-content-sm-center mb-20" v-if="tertiary_front_image != null">
                        <div class="col-3 col-sm-2 col-md-2 col-lg-2 text-center">
                            <div class="position-relative">
                                <img  v-if="tertiary_front_image_ext == 'png' || tertiary_front_image_ext == 'jpeg' || tertiary_front_image_ext == 'jpg'" :src="tertiary_front_image" class="img-fluid rounded5 zooms b-1" >
                                <a v-if="tertiary_front_image_ext == 'png' || tertiary_front_image_ext == 'jpeg' || tertiary_front_image_ext == 'jpg'" href="javascript:void(0)" @click="imgpath(tertiary_front_image)" data-bs-toggle="modal" data-bs-target="#viewimg" class="bg-white py-5 px-10 rounded fs-18 position-absolute b-1" style="right: 0;bottom: 0px;">
                                    <i class="fa fa-search-plus"></i>
                                </a>
                            </div>
                            <div class="position-relative" v-if="tertiary_front_image_ext == 'pdf'">
                                <img src="/images/pdf_icon.png" class="img-fluid rounded5" style="width:80%;" />
                                <a v-if="tertiary_front_image_ext == 'pdf'" href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#viewpdf" @click="viewpdf(tertiary_front_image)" class="bg-white py-5 px-10 rounded fs-18 position-absolute b-1" style="right: 0;bottom: 0px;">
                                    <i class="fa fa-search-plus"></i>
                                </a>
                            </div>
                            <p class="doc-text">Insurance card front</p>
                        </div>
                    </div>

                    <file-upload id="uploadFilesRT" 
                    v-model="v$.form.rear_image.$model"
                    :errors="v$.form.rear_image.$errors"
                    divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4"
                    labelClass="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end"
                    title="Insurance card rear" notes="Notes:- Only JPG, JPEG, PNG, PDF format is allowed." noteClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-4">
                    </file-upload>

                    <div class="row justify-content-sm-center mb-20" v-if="tertiary_rear_image != null">
                        <div class="col-3 col-sm-2 col-md-2 col-lg-2 text-center" >
                            <div class="position-relative">
                                <img  v-if="tertiary_rear_image_ext == 'png' || tertiary_rear_image_ext == 'jpeg' || tertiary_rear_image_ext == 'jpg'" :src="tertiary_rear_image" class="img-fluid rounded5 zooms b-1" >
                                <a v-if="tertiary_rear_image_ext == 'png' || tertiary_rear_image_ext == 'jpeg' || tertiary_rear_image_ext == 'jpg'" href="javascript:void(0)" @click="imgpath(tertiary_rear_image)" data-bs-toggle="modal" data-bs-target="#viewimg" class="bg-white py-5 px-10 rounded fs-18 position-absolute b-1" style="right: 0;bottom: 0px;">
                                    <i class="fa fa-search-plus"></i>
                                </a>
                            </div>
                            <div class="position-relative" v-if="tertiary_rear_image_ext == 'pdf'">
                                <img  src="/images/pdf_icon.png" class="img-fluid rounded5" style="width:80%;" />
                                <a v-if="tertiary_rear_image_ext == 'pdf'" href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#viewpdf" @click="viewpdf(tertiary_rear_image)" class="bg-white py-5 px-10 rounded fs-18 position-absolute b-1" style="right: 0;bottom: 0px;">
                                    <i class="fa fa-search-plus"></i>
                                </a>
                            </div>
                            <p class="doc-text">Insurance card rear</p>
                        </div>
                    </div>

                    <div class="bg-lightest p-15 mb-20" id="tertiary_subscriber_info" v-if="form.tertiary.patient_is_subscriber == false">
                        <div class="form-group row">
                            <h2 class="font-bold mb-20">If patient and subscriber are not same</h2>
                            <label class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end">Patient relationship to the Subscriber </label>
                            <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4">
                                <select id="id_tertiary-patient_relationship_to_subscriber" class="form-control" v-model="form.tertiary.subscriber_relation">
                                    <option value="" selected=""></option>
                                    <option :selected="form.tertiary.subscriber_relation == '01'" value="01">Spouse</option>
                                    <option :selected="form.tertiary.subscriber_relation == '04'" value="04">Grandparent</option>
                                    <option :selected="form.tertiary.subscriber_relation == '05'" value="05">Grandchild</option>
                                    <option :selected="form.tertiary.subscriber_relation == '07'" value="07">Nephew or Niece</option>
                                    <option :selected="form.tertiary.subscriber_relation == '10'" value="10">Foster Child</option>
                                    <option :selected="form.tertiary.subscriber_relation == '15'" value="15">Ward</option>
                                    <option :selected="form.tertiary.subscriber_relation == '17'" value="17">Stepson or Stepdaughter</option>
                                    <option :selected="form.tertiary.subscriber_relation == '19'" value="19">Child</option>
                                    <option :selected="form.tertiary.subscriber_relation == '20'" value="20">Employee</option>
                                    <option :selected="form.tertiary.subscriber_relation == '21'" value="21">Unknown</option>
                                    <option :selected="form.tertiary.subscriber_relation == '22'" value="22">Handicapped Dependent</option>
                                    <option :selected="form.tertiary.subscriber_relation == '23'" value="23">Sponsored Dependent</option>
                                    <option :selected="form.tertiary.subscriber_relation == '24'" value="24">Dependent of a Minor Dependent</option>
                                    <option :selected="form.tertiary.subscriber_relation == '29'" value="29">Significant Other</option>
                                    <option :selected="form.tertiary.subscriber_relation == '32'" value="32">Mother</option>
                                    <option :selected="form.tertiary.subscriber_relation == '33'" value="33">Father</option>
                                    <option :selected="form.tertiary.subscriber_relation == '36'" value="36">Emancipated Minor</option>
                                    <option :selected="form.tertiary.subscriber_relation == '39'" value="39">Organ Donor</option>
                                    <option :selected="form.tertiary.subscriber_relation == '40'" value="40">Cadaver Donor</option>
                                    <option :selected="form.tertiary.subscriber_relation == '41'" value="41">Injured Plaintiff</option>
                                    <option :selected="form.tertiary.subscriber_relation == '43'" value="43">Child Where Insured Has No Financial Responsibility</option>
                                    <option :selected="form.tertiary.subscriber_relation == '53'" value="53">Life Partner</option>
                                    <option :selected="form.tertiary.subscriber_relation == '76'" value="76">Dependent</option>
                                    <option :selected="form.tertiary.subscriber_relation == 'G8'" value="G8">Other Relationship</option>
                                </select>           
                            </div>                            
                        </div>

                        <input-field title="Subscriber First Name" divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4" labelClass="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end" v-model="form.tertiary.subscriber_first_name" noteClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-4"></input-field>

                        <input-field title="Subscriber Middle Name" divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4" labelClass="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end" v-model="form.tertiary.subscriber_middle_name" noteClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-4"></input-field>

                        <input-field title="Subscriber Last Name" divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4" labelClass="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end" v-model="form.tertiary.subscriber_last_name" noteClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-4"></input-field>

                        <div class="form-group row">
                            <label class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end"> Subscriber DOB </label>
                            <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4 availdate">
                                <ejs-datepicker :value="tertiary_subscriber_dob" id="tertiary_subscriber_dob" v-model="form.tertiary.subscriber_dob" :format="dateFormat" placeholder="Date Of Birth" @change="tertiary_getdobdate($event)" name="tertiary_subscriber_dob" :strictMode='strict' :max='maxVal' :showClearButton='false' :openOnFocus='true'>
                                </ejs-datepicker>
                            </div>
                        </div>

                        <input-field title="Subscriber SSN" divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4" labelClass="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end" v-model="form.tertiary.subscriber_ssn" noteClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-4"> </input-field>

                        <text-area title="Subscriber Address" divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4" labelClass="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end" v-model="form.tertiary.subscriber_address" noteClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-4"> </text-area>

                        <input-field title="Subscriber Zipcode" @focusout="zipcode('sub_tertiary')" divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4" labelClass="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end" v-model="form.tertiary.subscriber_zip_code" noteClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-4"> </input-field>

                        <input-field title="Subscriber City" divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4" labelClass="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end" noteClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-4" v-model="form.tertiary.subscriber_city"> </input-field>

                        <div class="form-group row">
                            <label class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end">Subscriber State </label>
                            <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4">
                                <Select2
                                v-model="form.tertiary.subscriber_state_id"
                                id="tertiary_subscriber_state_id"
                                placeholder="Select State"
                                :options="states"
                                />            
                            </div>                            
                        </div>

                        <input-field title="Subscriber Phone" mask="(000) 000-0000" divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4" labelClass="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end" noteClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-4" v-model="form.tertiary.subscriber_phone"> </input-field>

                        <select-box-static
                        title="Subscriber Gender"
                        v-model="form.tertiary.subscriber_gender"
                        :option="sex"
                        divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4" noteClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-4" labelClass="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end"
                        ></select-box-static>
                    </div>

                    <div class=" text-center">
                        <form-btn
                        title="Save"
                        btnCls="btn-info"
                        @click="save('tertiary')"
                        ></form-btn>         
                    </div>
                </div>
                <!-- Patient's tertiary insurance details -- End -->

                <!-- patient auto accident details -->
                <div class="tab-pane" id="auto_accident_ins_info" role="tabpanel">
                    <div class="form-group row align-items-center">
                        <label class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end">Subscriber is the Patient </label>
                        <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4">
                            <input id="auto_accident_p" type="checkbox" class="filled-in chk-col-info" v-model="form.auto_accident.patient_is_subscriber">
                            <label for="auto_accident_p" class="m-0 text-start fs-16"></label>           
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end">Auto Accident Insurance </label>
                        <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4">
                            <div class="selectcontrols">
                                <Autocomplete :max="30" @input="getlist7" ref="autocomplete7" :results="insurance_list7" @onSelect="resultsvalue7" :use-html-for-results ="true" class="form-control ms-0 autosearch-list" placeholder="Auto Accident Insurance"></Autocomplete>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end">Auto Accident Insurance Payer ID</label>
                        <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4">
                            <div class="selectcontrols">
                                <Autocomplete :max="30" @input="getlist8" ref="autocomplete8" :results="insurance_list8" @onSelect="resultsvalue8" :use-html-for-results ="true" class="form-control ms-0 autosearch-list" placeholder="Auto Accident Insurance"></Autocomplete>
                            </div>
                        </div>
                    </div>


                    <text-area title="Auto Accident Insurance Address" divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4" labelClass="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end" v-model="form.auto_accident.insurance_company_address"> </text-area>

                    <input-field title="Auto Accident Insurance Zipcode" divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4" labelClass="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end" @focusout="zipcode('patient_auto_accident')" v-model="form.auto_accident.insurance_company_zip_code"> </input-field>

                    <input-field title="Auto Accident Insurance City" divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4" labelClass="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end" v-model="form.auto_accident.insurance_company_city"> </input-field>

                    <div class="form-group row">
                        <label class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end">Auto Accident Insurance State</label>
                        <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4">
                            <Select2
                            v-model="form.auto_accident.insurance_company_state_id"
                            id="auto_accident_insurance_company_state_id"
                            placeholder="Select State"
                            :options="states"
                            />            
                        </div>
                    </div>   

                    <input-field title="Auto Accident Insurance Policy Number" v-model="form.auto_accident.subscriber_policy_id" divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4" labelClass="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end"> </input-field>

                    <div class="form-group row">
                        <label class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end"> Patient was unable to work from </label>
                        <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4 availdate">
                            <ejs-datepicker :value="patient_unable_to_work_start" id="patient_unable_to_work_start" v-model="form.auto_accident.patient_unable_to_work_start" :format="dateFormat" placeholder="Date Of Birth" @change="auto_accident_unable_to_work_start($event)" name="patient_unable_to_work_start" :strictMode='strict' :max='maxVal' :showClearButton='false' :openOnFocus='true'>
                            </ejs-datepicker>
                        </div>              
                    </div>

                    <div class="form-group row">
                        <label class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end"> Patient was unable to work to </label>
                        <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4 availdate">
                            <ejs-datepicker :value="patient_unable_to_work_end" id="patient_unable_to_work_end" v-model="form.auto_accident.patient_unable_to_work_end" :format="dateFormat" placeholder="Date Of Birth" @change="auto_accident_unable_to_work_end($event)" name="patient_unable_to_work_end" :strictMode='strict' :max='maxVal' :showClearButton='false' :openOnFocus='true'>
                            </ejs-datepicker>
                        </div>              
                    </div>

                    <div class="bg-lightest p-15 mb-20" v-if="form.auto_accident.patient_is_subscriber == false">
                        <div class="form-group row">
                            <label class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end">Patient relationship to the Subscriber </label>
                            <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4">
                                <select id="id_auto_accident-patient_relationship_to_subscriber" class="form-control" v-model="form.auto_accident.subscriber_relation">
                                    <option value="" selected=""></option>
                                    <option :selected="form.auto_accident.subscriber_relation == '01'" value="01">Spouse</option>
                                    <option :selected="form.auto_accident.subscriber_relation == '04'" value="04">Grandparent</option>
                                    <option :selected="form.auto_accident.subscriber_relation == '05'" value="05">Grandchild</option>
                                    <option :selected="form.auto_accident.subscriber_relation == '07'" value="07">Nephew or Niece</option>
                                    <option :selected="form.auto_accident.subscriber_relation == '10'" value="10">Foster Child</option>
                                    <option :selected="form.auto_accident.subscriber_relation == '15'" value="15">Ward</option>
                                    <option :selected="form.auto_accident.subscriber_relation == '17'" value="17">Stepson or Stepdaughter</option>
                                    <option :selected="form.auto_accident.subscriber_relation == '19'" value="19">Child</option>
                                    <option :selected="form.auto_accident.subscriber_relation == '20'" value="20">Employee</option>
                                    <option :selected="form.auto_accident.subscriber_relation == '21'" value="21">Unknown</option>
                                    <option :selected="form.auto_accident.subscriber_relation == '22'" value="22">Handicapped Dependent</option>
                                    <option :selected="form.auto_accident.subscriber_relation == '23'" value="23">Sponsored Dependent</option>
                                    <option :selected="form.auto_accident.subscriber_relation == '24'" value="24">Dependent of a Minor Dependent</option>
                                    <option :selected="form.auto_accident.subscriber_relation == '29'" value="29">Significant Other</option>
                                    <option :selected="form.auto_accident.subscriber_relation == '32'" value="32">Mother</option>
                                    <option :selected="form.auto_accident.subscriber_relation == '33'" value="33">Father</option>
                                    <option :selected="form.auto_accident.subscriber_relation == '36'" value="36">Emancipated Minor</option>
                                    <option :selected="form.auto_accident.subscriber_relation == '39'" value="39">Organ Donor</option>
                                    <option :selected="form.auto_accident.subscriber_relation == '40'" value="40">Cadaver Donor</option>
                                    <option :selected="form.auto_accident.subscriber_relation == '41'" value="41">Injured Plaintiff</option>
                                    <option :selected="form.auto_accident.subscriber_relation == '43'" value="43">Child Where Insured Has No Financial Responsibility</option>
                                    <option :selected="form.auto_accident.subscriber_relation == '53'" value="53">Life Partner</option>
                                    <option :selected="form.auto_accident.subscriber_relation == '76'" value="76">Dependent</option>
                                    <option :selected="form.auto_accident.subscriber_relation == 'G8'" value="G8">Other Relationship</option>
                                </select>           
                            </div>
                            <div class="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-4"><small class="form-text text-muted">*If patient and subscriber are not same</small></div>
                        </div>

                        <input-field title="Subscriber First Name" divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4" labelClass="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end" v-model="form.auto_accident.subscriber_first_name" noteClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-4" note="*If patient and subscriber are not same"></input-field>

                        <input-field title="Subscriber Middle Name" divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4" labelClass="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end" v-model="form.auto_accident.subscriber_middle_name" noteClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-4" note="*If patient and subscriber are not same"></input-field>

                        <input-field title="Subscriber Last Name" divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4" labelClass="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end" v-model="form.auto_accident.subscriber_last_name" noteClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-4" note="*If patient and subscriber are not same"></input-field>

                        <div class="form-group row">
                            <label class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end"> Subscriber DOB </label>
                            <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4 availdate">
                                <ejs-datepicker :value="auto_accident_subscriber_dob" id="auto_accident_subscriber_dob" v-model="form.auto_accident.subscriber_dob" :format="dateFormat" placeholder="Date Of Birth" @change="auto_accident_getdobdate($event)" name="auto_accident_subscriber_dob" :strictMode='strict' :max='maxVal' :showClearButton='false' :openOnFocus='true'>
                                </ejs-datepicker>
                            </div>
                            <div class="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-4"><small class="form-text text-muted">*If patient and subscriber are not same</small></div>
                        </div>

                        <input-field title="Subscriber SSN" divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4" labelClass="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end" v-model="form.auto_accident.subscriber_ssn" noteClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-4" note="*If patient and subscriber are not same"> </input-field>

                        <text-area title="Subscriber Address" divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4" labelClass="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end" v-model="form.auto_accident.subscriber_address" noteClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-4" notes="*If patient and subscriber are not same"> </text-area>

                        <input-field title="Subscriber Zipcode" @focusout="zipcode('sub_auto_accident')" divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4" labelClass="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end" v-model="form.auto_accident.subscriber_zip_code" noteClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-4" note="*If patient and subscriber are not same"> </input-field>

                        <input-field title="Subscriber City" divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4" labelClass="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end" noteClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-4" note="*If patient and subscriber are not same" v-model="form.auto_accident.subscriber_city"> </input-field>

                        <div class="form-group row">
                            <label class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end">Subscriber State </label>
                            <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4">
                                <Select2
                                v-model="form.auto_accident.subscriber_state_id"
                                id="auto_accident_subscriber_state_id"
                                placeholder="Select State"
                                :options="states"
                                />            
                            </div>
                            <div class="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-4"><small class="form-text text-muted">*If patient and subscriber are not same</small></div>
                        </div>

                        <input-field title="Subscriber Phone" mask="(000) 000-0000" divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4" labelClass="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end" noteClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-4" note="*If patient and subscriber are not same" v-model="form.auto_accident.subscriber_phone"> </input-field>

                        <select-box-static
                        title="Subscriber Gender"
                        v-model="form.auto_accident.subscriber_gender"
                        :option="sex"
                        divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4" noteClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-4" note="*If patient and subscriber are not same" labelClass="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end"
                        ></select-box-static>
                    </div>
                    <div class="text-center">
                        <form-btn
                        title="Save"
                        btnCls="btn-info"
                        @click="save('auto_accident')"
                        ></form-btn>         
                    </div>
                </div>
                <!-- patient auto accident details -- End -->


                <!-- patient's worker compensation details -->
                <div class="tab-pane" id="worker_comp_ins_info" role="tabpanel">
                    <div class="form-group row">
                        <label class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end">Insurance Company  </label>
                        <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4">
                            <div class="selectcontrols">
                                <Autocomplete :max="30" @input="getlist9" ref="autocomplete9" :results="insurance_list9" @onSelect="resultsvalue9" :use-html-for-results ="true" class="form-control ms-0 autosearch-list" placeholder="Insurance Company"></Autocomplete>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end">Insurance Payer ID</label>
                        <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4">
                            <div class="selectcontrols">
                                <Autocomplete :max="30" @input="getlist10" ref="autocomplete10" :results="insurance_list10" @onSelect="resultsvalue10" :use-html-for-results ="true" class="form-control ms-0 autosearch-list" placeholder="Insurance Payer ID"></Autocomplete>
                            </div>
                        </div>
                    </div>
                    <text-area title="Insurance Company Address" divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4" labelClass="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end" v-model="form.worker_comp.insurance_company_address"> </text-area>

                    <input-field title="Insurance Company Zipcode" @focusout="zipcode('patient_worker_comp')" divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4" labelClass="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end" v-model="form.worker_comp.insurance_company_zip_code"> </input-field>

                    <input-field title="Insurance Company City" v-model="form.worker_comp.insurance_company_city" divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4" labelClass="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end"> </input-field>

                    <div class="form-group row">
                        <label class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end">Insurance Company State </label>
                        <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4">
                            <Select2
                            v-model="form.worker_comp.insurance_company_state_id"
                            id="worker_comp_insurance_company_state_id"
                            placeholder="Select State"
                            :options="states"
                            />            
                        </div>
                    </div>

                    <input-field title="Insurance Group Name" v-model="form.worker_comp.insurance_group_name" divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4" labelClass="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end" note="*if available" noteClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-4"> </input-field>

                    <input-field title="Insurance Group Number/FECA Number" v-model="form.worker_comp.insurance_group_number" divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4" labelClass="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end" note="*if available" noteClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-4"> </input-field>

                    <input-field title="Insurance Plan Name" v-model="form.worker_comp.insurance_plan_name" divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4" labelClass="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end" note="*if available" noteClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-4"> </input-field>

                    <div class="form-group row">
                        <label class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end">Insurance plan type </label>
                        <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4">
                            <select id="id_worker_comp-insurance_plan_type" class="form-control" v-model="form.worker_comp.insurance_plan_type">
                                <option value="" selected=""></option>
                                <option :selected="form.worker_comp.insurance_plan_type == 'AM'" value="AM">Automobile Medical</option>
                                <option :selected="form.worker_comp.insurance_plan_type == 'BL'" value="BL">Blue Cross/Blue Shield</option>
                                <option :selected="form.worker_comp.insurance_plan_type == 'CH'" value="CH">Champus</option>
                                <option :selected="form.worker_comp.insurance_plan_type == 'CI'" value="CI">Commercial Insurance Co.</option>
                                <option :selected="form.worker_comp.insurance_plan_type == '17'" value="17">Dental Maintenance Organization</option>
                                <option :selected="form.worker_comp.insurance_plan_type == 'DS'" value="DS">Disability</option>
                                <option :selected="form.worker_comp.insurance_plan_type == '14'" value="14">Exclusive Provider Organization (EPO)</option>
                                <option :selected="form.worker_comp.insurance_plan_type == 'FI'" value="FI">Federal Employees Program</option>
                                <option :selected="form.worker_comp.insurance_plan_type == 'HM'" value="HM">Health Maintenance Organization</option>
                                <option :selected="form.worker_comp.insurance_plan_type == '16'" value="16">Health Maintenance Organization (HMO) Medicare Risk</option>
                                <option :selected="form.worker_comp.insurance_plan_type == '15'" value="15">Indemnity Insurance</option>
                                <option :selected="form.worker_comp.insurance_plan_type == 'LM'" value="LM">Liability Medical</option>
                                <option :selected="form.worker_comp.insurance_plan_type == 'MC'" value="MC">Medicaid</option>
                                <option :selected="form.worker_comp.insurance_plan_type == 'MA'" value="MA">Medicare Part A</option>
                                <option :selected="form.worker_comp.insurance_plan_type == 'MB'" value="MB">Medicare Part B</option>
                                <option :selected="form.worker_comp.insurance_plan_type == 'ZZ'" value="ZZ">Mutually Defined</option>
                                <option :selected="form.worker_comp.insurance_plan_type == 'OF'" value="OF">Other Federal Program</option>
                                <option :selected="form.worker_comp.insurance_plan_type == '11'" value="11">Other Non-Federal Programs</option>
                                <option :selected="form.worker_comp.insurance_plan_type == '13'" value="13">Point of Service (POS)</option>
                                <option :selected="form.worker_comp.insurance_plan_type == '12'" value="12">Preferred Provider Organization (PPO)</option>
                                <option :selected="form.worker_comp.insurance_plan_type == 'TV'" value="TV">Title V</option>
                                <option :selected="form.worker_comp.insurance_plan_type == 'VA'" value="VA">Veterans Affairs Plan</option>
                                <option :selected="form.worker_comp.insurance_plan_type == 'WC'" value="WC">Workers' Compensation Health Claim</option>
                            </select>         
                        </div>
                        <div class="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-4">
                            <small class="form-text text-muted">*if available</small>
                        </div>
                    </div>
                    <div class=" text-center">
                        <form-btn title="Save" btnCls="btn-info" @click="save('worker_comp')"></form-btn>
                    </div>
                </div>
                <!-- patient's worker compensation details End -->

                <div class="tab-pane" id="durable_med_eqpt" role="tabpanel">
                    <div class="form-group row">
                        <label class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end">Insurance Company  </label>
                        <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4">
                            <div class="selectcontrols">
                                <Autocomplete :max="30" @input="getlist11" ref="autocomplete11" :results="insurance_list11" @onSelect="resultsvalue11" :use-html-for-results ="true" class="form-control ms-0 autosearch-list" placeholder="Insurance Company"></Autocomplete>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end">Insurance Payer ID</label>
                        <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4">
                            <div class="selectcontrols">
                                <Autocomplete :max="30" @input="getlist12" ref="autocomplete12" :results="insurance_list12" @onSelect="resultsvalue12" :use-html-for-results ="true" class="form-control ms-0 autosearch-list" placeholder="Insurance Payer ID"></Autocomplete>
                            </div>
                        </div>
                    </div>

                    <input-field title="Insurance Company ID Number" divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4" labelClass="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end" v-model="form.durable_med_eqpt.insurance_id_number"> </input-field>


                    <input-field title="Insurance Group Name" v-model="form.durable_med_eqpt.insurance_group_name" divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4" labelClass="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end" note="*if available" noteClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-4"> </input-field>

                    <input-field title="Insurance Group Number/FECA Number" v-model="form.durable_med_eqpt.insurance_group_number" divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4" labelClass="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end" note="*if available" noteClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-4"> </input-field>

                    <input-field title="Insurance Plan Name" v-model="form.durable_med_eqpt.insurance_plan_name" divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4" labelClass="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end" note="*if available" noteClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-4"> </input-field>

                    <div class="form-group row">
                        <label class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end">Insurance plan type </label>
                        <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4">
                            <select id="id_durable_med_eqpt-insurance_plan_type" class="form-control" v-model="form.durable_med_eqpt.insurance_plan_type">
                                <option value="" selected=""></option>
                                <option :selected="form.durable_med_eqpt.insurance_plan_type == 'AM'" value="AM">Automobile Medical</option>
                                <option :selected="form.durable_med_eqpt.insurance_plan_type == 'BL'" value="BL">Blue Cross/Blue Shield</option>
                                <option :selected="form.durable_med_eqpt.insurance_plan_type == 'CH'" value="CH">Champus</option>
                                <option :selected="form.durable_med_eqpt.insurance_plan_type == 'CI'" value="CI">Commercial Insurance Co.</option>
                                <option :selected="form.durable_med_eqpt.insurance_plan_type == '17'" value="17">Dental Maintenance Organization</option>
                                <option :selected="form.durable_med_eqpt.insurance_plan_type == 'DS'" value="DS">Disability</option>
                                <option :selected="form.durable_med_eqpt.insurance_plan_type == '14'" value="14">Exclusive Provider Organization (EPO)</option>
                                <option :selected="form.durable_med_eqpt.insurance_plan_type == 'FI'" value="FI">Federal Employees Program</option>
                                <option :selected="form.durable_med_eqpt.insurance_plan_type == 'HM'" value="HM">Health Maintenance Organization</option>
                                <option :selected="form.durable_med_eqpt.insurance_plan_type == '16'" value="16">Health Maintenance Organization (HMO) Medicare Risk</option>
                                <option :selected="form.durable_med_eqpt.insurance_plan_type == '15'" value="15">Indemnity Insurance</option>
                                <option :selected="form.durable_med_eqpt.insurance_plan_type == 'LM'" value="LM">Liability Medical</option>
                                <option :selected="form.durable_med_eqpt.insurance_plan_type == 'MC'" value="MC">Medicaid</option>
                                <option :selected="form.durable_med_eqpt.insurance_plan_type == 'MA'" value="MA">Medicare Part A</option>
                                <option :selected="form.durable_med_eqpt.insurance_plan_type == 'MB'" value="MB">Medicare Part B</option>
                                <option :selected="form.durable_med_eqpt.insurance_plan_type == 'ZZ'" value="ZZ">Mutually Defined</option>
                                <option :selected="form.durable_med_eqpt.insurance_plan_type == 'OF'" value="OF">Other Federal Program</option>
                                <option :selected="form.durable_med_eqpt.insurance_plan_type == '11'" value="11">Other Non-Federal Programs</option>
                                <option :selected="form.durable_med_eqpt.insurance_plan_type == '13'" value="13">Point of Service (POS)</option>
                                <option :selected="form.durable_med_eqpt.insurance_plan_type == '12'" value="12">Preferred Provider Organization (PPO)</option>
                                <option :selected="form.durable_med_eqpt.insurance_plan_type == 'TV'" value="TV">Title V</option>
                                <option :selected="form.durable_med_eqpt.insurance_plan_type == 'VA'" value="VA">Veterans Affairs Plan</option>
                                <option :selected="form.durable_med_eqpt.insurance_plan_type == 'WC'" value="WC">Workers' Compensation Health Claim</option>
                            </select>         
                        </div>
                        <div class="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-4">
                            <small class="form-text text-muted">*if available</small>
                        </div>
                    </div>

                    <div class=" text-center">
                        <form-btn title="Save" btnCls="btn-info" @click="save('durable_med_eqpt')"></form-btn>         
                    </div>
                </div>

                <!-- Patient copay details -->
                <div class="tab-pane" id="copay_or_coins" role="tabpanel">
                    <input-field title="Copay Amount ($)" divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4" labelClass="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end" v-model="form.patient_copay_detail.patient_copay"> </input-field>
                    <input-field title="Coinsurance Amount (%)" divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4" labelClass="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end" v-model="form.patient_copay_detail.patient_coinsurance"> </input-field>

                    <div class="text-center">
                        <form-btn title="Save" btnCls="btn-info" @click="save('patient_copay')"></form-btn>         
                    </div>
                </div>
                <!-- patient copay details -- End -->
                <!-- patient Eligibility test  -->
                <div class="tab-pane" id="eligibility_check_tab" role="tabpanel">
                  <EligibilityCheck :patient_id="$route.params.id"/>
                </div>
                <!-- patient Eligibility test -- end  -->
            </div>
        </div>
    </div>
<!--document model start-->
<div id="insuranceinfo" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
<div class="modal-dialog modal-md" style="max-width: 600px;">
    <div class="modal-content">
    <div class="modal-header">
        <h3 class="modal-title fw-900 mt-0">Reference of Knowledge Base</h3>
    </div>
    <div class="modal-body overflow-auto" style="max-height: 350px;">
    <table class="table table-bordered">
        <thead>
        <tr>
        <th scope="col">No.</th>
        <th scope="col">Knowledge Base Articles</th>
        </tr>
        </thead>
        <tbody>
        <tr>
            <td>1</td>
            <td> <a :href="path+'cms-1500-information-for-each-box-in-pureehr'" target="_blank">CMS 1500 Information For Each Box In PureEHR</a></td>
        </tr>
        <tr>
            <td>2</td>
            <td> <a :href="path+'how-to-claim-insurance-in-pureehr'" target="_blank">How To Claim Insurance In PureEHR?</a></td>
        </tr>
        </tbody>
    </table>
    <Search :key="searchKey"></Search>
    </div>
    <div class="modal-footer">
        <button type="button" @click="" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
    </div>
    </div>
</div>
</div>
<!--document model end-->
    <!-- Image zoom -->
    <div class="modal" id="viewimg" tabindex="-1">
        <div class="modal-dialog modal-xl">
            <div class="modal-content bg-white" style="height: 93vh;">
                <button @click="zoomclose" type="button" class="btn-close btn-xs position-absolute bg-white p-5" data-bs-dismiss="modal" aria-label="Close" style="right: 4px;top: 8px;z-index: 1;"></button>
                <div class="modal-body text-center d-flex align-items-center" style="overflow: hidden;">
                    <img src="" class="img-fluid rounded5 zoom preview_img">
                </div>
                <div class="text-center my-5">
                    <a @click="zoomplus" href="javascript:void(0)" class="waves-effect waves-light btn btn-info-light fs-16 btn-xs me-2"><i class="fa fa-search-plus"></i></a>
                    <a @click="zoomminus" href="javascript:void(0)" class="waves-effect waves-light btn btn-info-light fs-16 btn-xs me-2"><i class="fa fa-search-minus"></i></a>
                </div>
            </div>
        </div>
    </div>
    <!-- Image zoom -- End -->

    <!-- PDF view -->
    <div class="modal" id="viewpdf" tabindex="-1">
        <div class="modal-dialog modal-md" style="max-width:75%">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="btn-close btn-xs" data-bs-dismiss="modal" aria-label="Close" @click="close"></button>
                </div>
                <div class="modal-body">
                    <iframe :src="document_name_url" width="100%" height="500px" />
                </div>
            </div>
        </div>
    </div>
    <!-- PDF view -- End -->
</template>
<script>
import InputField from "@/components/base/formFields/InputField.vue";
import FormBtn from "@/components/base/formFields/formBtn.vue";
import Autocomplete from 'vue3-autocomplete'
import Select2 from "@/components/base/formFields/select2.vue";
import useVuelidate from "@vuelidate/core";
import submitMixins from "@/scripts/mixins/submitMixins.js";
import permissionMixins from "@/scripts/mixins/permission.js";
import axios from "@/scripts/axios.js";
import TextArea from "@/components/base/formFields/textArea.vue";
import moment from "moment";
import SelectBoxStatic from "@/components/base/formFields/SelectBoxStatic.vue";
import FileUpload from "@/components/base/formFields/fileUpload.vue";
import EligibilityCheck from "@/components/patients/details/PatientInformation/EligibilityCheck.vue";
import Search from "@/components/Search.vue";

export default {
    mixins: [submitMixins, permissionMixins],
    setup: () => {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            primary_front_image_ext: null,
            primary_rear_image_ext: null,
            secondary_front_image_ext: null,
            secondary_rear_image_ext: null,
            tertiary_front_image_ext: null,
            tertiary_rear_image_ext: null,
            insurance_list: [],
            insurance_list2: [],
            insurance_list3: [],
            insurance_list4: [],
            insurance_list5: [],
            insurance_list6: [],
            insurance_list7: [],
            insurance_list8: [],
            insurance_list9: [],
            insurance_list10: [],
            insurance_list11: [],
            insurance_list12: [],            
            document_name_url: null,
            embed: 0,
            scale: 0.5,  
            primary_front_image: null,
            primary_rear_image: null,
            secondary_front_image: null,
            secondary_rear_image: null,
            tertiary_front_image: null,
            tertiary_rear_image: null,
            patient_unable_to_work_start: null,
            patient_unable_to_work_end: null,
            primary_subscriber_dob: null,
            secondary_subscriber_dob: null,
            tertiary_subscriber_dob: null,
            auto_accident_subscriber_dob: null,
            states: null,
            patient_id: this.$store.state.patientDetail.patient_id,
            subscriber_dob : new Date(),
            strict: true,
            maxVal: new Date(),
            dateFormat: 'MM-dd-yyyy',
            sex: [
            { value: "Male", title: "Male" },
            { value: "Female", title: "Female" },
            { value: "Others", title: "Others" },
            { value: "Unknown", title: "Unknown" },
            { value: "Declined to specify", title: "Declined to specify" },
            ], 
            path: process.env.VUE_APP_KB_DOCUMENT,
            searchKey:0,           
        }
    },
    components: {
        InputField,   
        FormBtn,    
        Autocomplete,
        Select2,
        TextArea,
        SelectBoxStatic,
        FileUpload,
        EligibilityCheck,
        Search
    },
    computed: {
        form() {
            return this.$store.state.patientinsuranceinfo.form;
        },
        vuelidateExternalResults() {
            return this.$store.state.patientinsuranceinfo.vuelidateExternalResults;
        },
        validationRules() {
            return this.$store.state.patientinsuranceinfo.validationRules;
        },
    },
    mounted() {
        this.getData();    
    },
    methods: {
        reset() {
            this.searchKey = this.searchKey + 1;
        },
        scrollToDiv(type){
            if(type=='primary'){
                if(!this.form.primary.patient_is_subscriber){
                    $('html, body').animate({
                        'scrollTop' : $("#primary_subscriber_info").position().top
                    });
                }
            }else if(type=='secondary'){
                if(!this.form.secondary.patient_is_subscriber){
                    $('html, body').animate({
                        'scrollTop' : $("#secondary_subscriber_info").position().top
                    });
                }
            }else if(type=='tertiary'){
                if(!this.form.tertiary.patient_is_subscriber){
                    $('html, body').animate({
                        'scrollTop' : $("#tertiary_subscriber_info").position().top
                    });
                }
            }       
        },
        getData()
        {
            this.$store.state.loader = true;
            axios.post(`/patient/${this.$route.params.id}/insuranceinfo/edit`,{
                params: {patient_id: this.patient_id}
            }).then(response => { 

                /*Primary Insurance Info*/
                if(response.data.data.primary != null){
                    this.$store.state.patientinsuranceinfo.form.primary = response.data.data.primary
                    this.$refs.autocomplete.searchText =  this.$store.state.patientinsuranceinfo.form.primary.insurance_company
                    this.$refs.autocomplete2.searchText =  this.$store.state.patientinsuranceinfo.form.primary.insurance_company_id
                    this.$store.state.patientinsuranceinfo.form.primary.patient_is_subscriber = (response.data.data.primary.patient_is_subscriber==1) ? true : false;    
                    if(this.form.primary.subscriber_dob != null){
                        this.primary_subscriber_dob = moment(String(this.form.primary.subscriber_dob)).format('MM-DD-YYYY');
                    }else{
                        this.primary_subscriber_dob = null;
                    }

                    this.primary_front_image = (response.data.data.primary.front_image!=null) ? process.env.VUE_APP_API_BASE_URL+"/stream-image?path="+response.data.data.primary.front_image+"&slang="+response.data.data.primary.clinic_id : null;

                    var primary_front_image_ext_detail = (response.data.data.primary.front_image!=null) ? response.data.data.primary.front_image.split('.') : '';

                    if(primary_front_image_ext_detail!='')
                    {
                        this.primary_front_image_ext = primary_front_image_ext_detail[1];
                    }

                    this.primary_rear_image = (response.data.data.primary.rear_image!=null) ? process.env.VUE_APP_API_BASE_URL+"/stream-image?path="+response.data.data.primary.rear_image+"&slang="+response.data.data.primary.clinic_id : null;


                    var primary_rear_image_ext_detail = (response.data.data.primary.rear_image!=null) ? response.data.data.primary.rear_image.split('.') : '';

                    if(primary_rear_image_ext_detail!='')
                    {
                        this.primary_rear_image_ext = primary_rear_image_ext_detail[1];
                    }

                }
                /*Primary Insurance Info -- End*/

                /*Secondary Insurance Info*/
                if(response.data.data.secondary != null){
                    this.$store.state.patientinsuranceinfo.form.secondary = response.data.data.secondary
                    this.$refs.autocomplete3.searchText =  this.$store.state.patientinsuranceinfo.form.secondary.insurance_company
                    this.$refs.autocomplete4.searchText =  this.$store.state.patientinsuranceinfo.form.secondary.insurance_company_id
                    this.$store.state.patientinsuranceinfo.form.secondary.patient_is_subscriber = (response.data.data.secondary.patient_is_subscriber==1) ? true : false;
                    if(this.form.secondary.subscriber_dob != null){
                        this.secondary_subscriber_dob = moment(String(this.form.secondary.subscriber_dob)).format('MM-DD-YYYY');
                    }else{
                        this.secondary_subscriber_dob = null;
                    }
                    this.secondary_front_image = (response.data.data.secondary.front_image!=null) ? process.env.VUE_APP_API_BASE_URL+"/stream-image?path="+response.data.data.secondary.front_image+"&slang="+response.data.data.secondary.clinic_id : null;

                    var secondary_front_image_ext_detail = (response.data.data.secondary.front_image!=null) ? response.data.data.secondary.front_image.split('.') : '';

                    if(secondary_front_image_ext_detail!='')
                    {
                        this.secondary_front_image_ext = secondary_front_image_ext_detail[1];
                    }

                    this.secondary_rear_image = (response.data.data.secondary.rear_image!=null) ? process.env.VUE_APP_API_BASE_URL+"/stream-image?path="+response.data.data.secondary.rear_image+"&slang="+response.data.data.secondary.clinic_id : null;

                    var secondary_rear_image_ext_detail = (response.data.data.secondary.rear_image!=null) ? response.data.data.secondary.rear_image.split('.') : '';

                    if(secondary_rear_image_ext_detail!='')
                    {
                        this.secondary_rear_image_ext = secondary_rear_image_ext_detail[1];
                    }
                }
                /*Secondary Insurance Info -- End*/

                /*tertiary Insurance Info*/
                if(response.data.data.tertiary != null){
                    this.$store.state.patientinsuranceinfo.form.tertiary = response.data.data.tertiary
                    this.$refs.autocomplete5.searchText =  this.$store.state.patientinsuranceinfo.form.tertiary.insurance_company
                    this.$refs.autocomplete6.searchText =  this.$store.state.patientinsuranceinfo.form.tertiary.insurance_company_id
                    this.$store.state.patientinsuranceinfo.form.tertiary.patient_is_subscriber = (response.data.data.tertiary.patient_is_subscriber==1) ? true : false;
                    if(this.form.tertiary.subscriber_dob != null){
                        this.tertiary_subscriber_dob = moment(String(this.form.tertiary.subscriber_dob)).format('MM-DD-YYYY');
                    }else{
                        this.tertiary_subscriber_dob = null;
                    }

                    this.tertiary_front_image = (response.data.data.tertiary.front_image!=null) ? process.env.VUE_APP_API_BASE_URL+"/stream-image?path="+response.data.data.tertiary.front_image+"&slang="+response.data.data.tertiary.clinic_id : null;

                    var tertiary_front_image_ext_detail = (response.data.data.tertiary.front_image!=null) ? response.data.data.tertiary.front_image.split('.') : '';

                    if(tertiary_front_image_ext_detail!='')
                    {
                        this.tertiary_front_image_ext = tertiary_front_image_ext_detail[1];
                    }

                    this.tertiary_rear_image = (response.data.data.tertiary.rear_image!=null) ? process.env.VUE_APP_API_BASE_URL+"/stream-image?path="+response.data.data.tertiary.rear_image+"&slang="+response.data.data.tertiary.clinic_id : null;

                    var tertiary_rear_image_ext_detail = (response.data.data.tertiary.rear_image!=null) ? response.data.data.tertiary.rear_image.split('.') : '';

                    if(tertiary_rear_image_ext_detail!='')
                    {
                        this.tertiary_rear_image_ext = tertiary_rear_image_ext_detail[1];
                    }
                }
                /*tertiary Insurance Info -- End*/


                /*Auto Accident Insurance Info*/
                if(response.data.data.auto_accident != null){
                    this.$store.state.patientinsuranceinfo.form.auto_accident = response.data.data.auto_accident
                    this.$refs.autocomplete3.searchText =  this.$store.state.patientinsuranceinfo.form.auto_accident.insurance_company
                    this.$refs.autocomplete4.searchText =  this.$store.state.patientinsuranceinfo.form.auto_accident.insurance_company_id
                    this.$store.state.patientinsuranceinfo.form.auto_accident.patient_is_subscriber = (response.data.data.auto_accident.patient_is_subscriber==1) ? true : false;
                    if(this.form.auto_accident.patient_unable_to_work_start != null){
                        this.patient_unable_to_work_start = moment(String(this.form.auto_accident.patient_unable_to_work_start)).format('MM-DD-YYYY');
                    }else{
                        this.patient_unable_to_work_start = null;
                    }
                    if(this.form.auto_accident.patient_unable_to_work_end != null){
                        this.patient_unable_to_work_end = moment(String(this.form.auto_accident.patient_unable_to_work_end)).format('MM-DD-YYYY');
                    }else{
                        this.patient_unable_to_work_end = null;
                    }
                    if(this.form.auto_accident.subscriber_dob != null){
                        this.auto_accident_subscriber_dob = moment(String(this.form.auto_accident.subscriber_dob)).format('MM-DD-YYYY');
                    }else{
                        this.auto_accident_subscriber_dob = null;
                    }
                }
                /*Auto Accident Insurance Info -- End*/

                /*Worker's compensation Info*/
                if(response.data.data.worker_comp != null){
                    this.$store.state.patientinsuranceinfo.form.worker_comp = response.data.data.worker_comp
                    this.$refs.autocomplete9.searchText =  this.$store.state.patientinsuranceinfo.form.worker_comp.insurance_company
                    this.$refs.autocomplete10.searchText =  this.$store.state.patientinsuranceinfo.form.worker_comp.insurance_company_id
                    this.$store.state.patientinsuranceinfo.form.worker_comp.patient_is_subscriber = (response.data.data.worker_comp.patient_is_subscriber==1) ? true : false;
                    if(this.form.worker_comp.patient_unable_to_work_start != null){
                        this.patient_unable_to_work_start = moment(String(this.form.worker_comp.patient_unable_to_work_start)).format('MM-DD-YYYY');
                    }else{
                        this.patient_unable_to_work_start = null;
                    }
                    if(this.form.worker_comp.patient_unable_to_work_end != null){
                        this.patient_unable_to_work_end = moment(String(this.form.worker_comp.patient_unable_to_work_end)).format('MM-DD-YYYY');
                    }else{
                        this.patient_unable_to_work_end = null;
                    }
                    if(this.form.worker_comp.subscriber_dob != null){
                        this.worker_comp_subscriber_dob = moment(String(this.form.worker_comp.subscriber_dob)).format('MM-DD-YYYY');
                    }else{
                        this.worker_comp_subscriber_dob = null;
                    }
                }
                /*Worker's compensation Info -- End*/

                /*Durable med eqpt*/
                if(response.data.data.durable_med_eqpt != null){
                    this.$store.state.patientinsuranceinfo.form.durable_med_eqpt = response.data.data.durable_med_eqpt
                    this.$refs.autocomplete9.searchText =  this.$store.state.patientinsuranceinfo.form.durable_med_eqpt.insurance_company
                    this.$refs.autocomplete10.searchText =  this.$store.state.patientinsuranceinfo.form.durable_med_eqpt.insurance_company_id                    
                }
                /*Durable med eqpt -- End*/

                /*Co-pay*/
                if(response.data.data.copay_detail != null)
                {
                    if($.type(this.form.patient_copay_detail) == "string"){
                        this.form.patient_copay_detail = jQuery.parseJSON(this.form.patient_copay_detail);
                    }
                    this.form.patient_copay_detail.patient_copay = response.data.data.copay_detail['patient_copay'];
                    this.form.patient_copay_detail.patient_coinsurance = response.data.data.copay_detail['patient_coinsurance'];
                }
                /*Co-pay -- End*/


                this.$store.state.patientinsuranceinfo.form.patient_id = this.patient_id;
                this.$store.state.loader = false;
            });
        },
        viewpdf(url){
            this.document_name_url  = url;
        },
        imgpath(img){
            $(".preview_img").attr("src",img);
            $("#viewimg").modal("show");
        },
        zoomclose(){
            this.scale = 1.5;
            $(".zoom").css("transform", "scale("+this.scale+")");
        },
        zoomplus(){
            this.scale = this.scale+0.5;
            $(".zoom").css("transform", "scale("+this.scale+")");
        },
        zoomminus(){
            if(this.scale > 0.5){
                this.scale = this.scale-0.5;
                $(".zoom").css("transform", "scale("+this.scale+")");
            }
        },
        getlist (obj) {
            axios.get("/json_autocomplete/insurance-company-list",{
                params: {term: obj}
            }).then(response => { 
                this.insurance_list = response.data.data.map((code) => {
                    return { id: code.id, name: "<b>"+code.code+"</b><br>"+code.name,code:code.code,title:code.name }
                });
            });          
        },        
        resultsvalue(obj){
            this.$refs.autocomplete.searchText = obj.title;
            this.$refs.autocomplete2.searchText = obj.code;
            this.form.primary.insurance_company_id = obj.code
            this.form.primary.insurance_company = obj.title
        },
        getlist2 (obj) {
            axios.get("/json_autocomplete/insurance-company-list",{
                params: {term: obj}
            }).then(response => { 
                this.insurance_list2 = response.data.data.map((code) => {
                    return { id: code.id, name: "<b>"+code.code+"</b><br>"+code.name,code:code.code,title:code.name }
                });
            });          
        },
        resultsvalue2(obj){
            this.$refs.autocomplete.searchText = obj.title;
            this.$refs.autocomplete2.searchText = obj.code;
            this.form.primary.insurance_company_id = obj.code
            this.form.primary.insurance_company = obj.title
        }, 
        getlist3 (obj) {
            axios.get("/json_autocomplete/insurance-company-list",{
                params: {term: obj}
            }).then(response => { 
                this.insurance_list3 = response.data.data.map((code) => {
                    return { id: code.id, name: "<b>"+code.code+"</b><br>"+code.name,code:code.code,title:code.name }
                });
            });          
        },
        resultsvalue3(obj){
            this.$refs.autocomplete3.searchText = obj.title;
            this.$refs.autocomplete4.searchText = obj.code;
            this.form.secondary.insurance_company_id = obj.code
            this.form.secondary.insurance_company = obj.title
        },
        getlist4 (obj) {
            axios.get("/json_autocomplete/insurance-company-list",{
                params: {term: obj}
            }).then(response => { 
                this.insurance_list4 = response.data.data.map((code) => {
                    return { id: code.id, name: "<b>"+code.code+"</b><br>"+code.name,code:code.code,title:code.name }
                });
            });          
        },
        resultsvalue4(obj){
            this.$refs.autocomplete3.searchText = obj.title;
            this.$refs.autocomplete4.searchText = obj.code;
            this.form.secondary.insurance_company_id = obj.code
            this.form.secondary.insurance_company = obj.title
        },
        getlist5 (obj) {
            axios.get("/json_autocomplete/insurance-company-list",{
                params: {term: obj}
            }).then(response => { 
                this.insurance_list5 = response.data.data.map((code) => {
                    return { id: code.id, name: "<b>"+code.code+"</b><br>"+code.name,code:code.code,title:code.name }
                });
            });          
        },
        resultsvalue5(obj){
            this.$refs.autocomplete5.searchText = obj.title;
            this.$refs.autocomplete6.searchText = obj.code;
            this.form.tertiary.insurance_company_id = obj.code
            this.form.tertiary.insurance_company = obj.title
        },
        getlist6 (obj) {
            axios.get("/json_autocomplete/insurance-company-list",{
                params: {term: obj}
            }).then(response => { 
                this.insurance_list6 = response.data.data.map((code) => {
                    return { id: code.id, name: "<b>"+code.code+"</b><br>"+code.name,code:code.code,title:code.name }
                });
            });          
        },
        resultsvalue6(obj){
            this.$refs.autocomplete5.searchText = obj.title;
            this.$refs.autocomplete6.searchText = obj.code;
            this.form.tertiary.insurance_company_id = obj.code
            this.form.tertiary.insurance_company = obj.title
        },

        getlist7 (obj) {
            axios.get("/json_autocomplete/insurance-company-list",{
                params: {term: obj}
            }).then(response => { 
                this.insurance_list7 = response.data.data.map((code) => {
                    return { id: code.id, name: "<b>"+code.code+"</b><br>"+code.name,code:code.code,title:code.name }
                });
            });          
        },
        resultsvalue7(obj){
            this.$refs.autocomplete7.searchText = obj.title;
            this.$refs.autocomplete8.searchText = obj.code;
            this.form.tertiary.insurance_company_id = obj.code
            this.form.tertiary.insurance_company = obj.title
        },

        getlist8 (obj) {
            axios.get("/json_autocomplete/insurance-company-list",{
                params: {term: obj}
            }).then(response => { 
                this.insurance_list8 = response.data.data.map((code) => {
                    return { id: code.id, name: "<b>"+code.code+"</b><br>"+code.name,code:code.code,title:code.name }
                });
            });          
        },
        resultsvalue8(obj){
            this.$refs.autocomplete7.searchText = obj.title;
            this.$refs.autocomplete8.searchText = obj.code;
            this.form.tertiary.insurance_company_id = obj.code
            this.form.tertiary.insurance_company = obj.title
        },

        getlist9 (obj) {
            axios.get("/json_autocomplete/insurance-company-list",{
                params: {term: obj}
            }).then(response => { 
                this.insurance_list9 = response.data.data.map((code) => {
                    return { id: code.id, name: "<b>"+code.code+"</b><br>"+code.name,code:code.code,title:code.name }
                });
            });          
        },
        resultsvalue9 (obj){
            this.$refs.autocomplete9.searchText = obj.title;
            this.$refs.autocomplete10.searchText = obj.code;
            this.form.worker_comp.insurance_company_id = obj.code
            this.form.worker_comp.insurance_company = obj.title
        },

        getlist10 (obj) {
            axios.get("/json_autocomplete/insurance-company-list",{
                params: {term: obj}
            }).then(response => { 
                this.insurance_list10 = response.data.data.map((code) => {
                    return { id: code.id, name: "<b>"+code.code+"</b><br>"+code.name,code:code.code,title:code.name }
                });
            });          
        },
        resultsvalue10(obj){
            this.$refs.autocomplete9.searchText = obj.title;
            this.$refs.autocomplete10.searchText = obj.code;
            this.form.worker_comp.insurance_company_id = obj.code
            this.form.worker_comp.insurance_company = obj.title
        },


        getlist11 (obj) {
            axios.get("/json_autocomplete/insurance-company-list",{
                params: {term: obj}
            }).then(response => { 
                this.insurance_list11 = response.data.data.map((code) => {
                    return { id: code.id, name: "<b>"+code.code+"</b><br>"+code.name,code:code.code,title:code.name }
                });
            });          
        },
        resultsvalue11(obj){
            this.$refs.autocomplete11.searchText = obj.title;
            this.$refs.autocomplete12.searchText = obj.code;
            this.form.durable_med_eqpt.insurance_company_id = obj.code
            this.form.durable_med_eqpt.insurance_company = obj.title
        },


        getlist12 (obj) {
            axios.get("/json_autocomplete/insurance-company-list",{
                params: {term: obj}
            }).then(response => { 
                this.insurance_list11 = response.data.data.map((code) => {
                    return { id: code.id, name: "<b>"+code.code+"</b><br>"+code.name,code:code.code,title:code.name }
                });
            });          
        },
        resultsvalue12(obj){
            this.$refs.autocomplete11.searchText = obj.title;
            this.$refs.autocomplete12.searchText = obj.code;
            this.form.durable_med_eqpt.insurance_company_id = obj.code
            this.form.durable_med_eqpt.insurance_company = obj.title
        },

        zipcode(from){
            if(from == 'patient_primary'){
                axios.post("json_dropdown/state-city-auto-populations",{zip_code:this.form.primary.insurance_company_zip_code})
                .then((response) => {
                    this.form.primary.insurance_company_city=response.data.data.City;
                    this.form.primary.insurance_company_state_id=response.data.data.id
                });
            }else if(from == 'sub_primary'){
                axios.post("json_dropdown/state-city-auto-populations",{zip_code:this.form.primary.subscriber_zip_code})
                .then((response) => {
                    this.form.primary.subscriber_city=response.data.data.City;
                    this.form.primary.subscriber_state_id=response.data.data.id
                });
            }else if(from == 'patient_secondary'){
                axios.post("json_dropdown/state-city-auto-populations",{zip_code:this.form.secondary.insurance_company_zip_code})
                .then((response) => {
                    this.form.secondary.insurance_company_city=response.data.data.City;
                    this.form.secondary.insurance_company_state_id=response.data.data.id
                });
            }else if(from == 'patient_tertiary'){
                axios.post("json_dropdown/state-city-auto-populations",{zip_code:this.form.tertiary.insurance_company_zip_code})
                .then((response) => {
                    this.form.tertiary.insurance_company_city=response.data.data.City;
                    this.form.tertiary.insurance_company_state_id=response.data.data.id
                });
            }
            else if(from == 'sub_secondary'){
                axios.post("json_dropdown/state-city-auto-populations",{zip_code:this.form.secondary.subscriber_zip_code})
                .then((response) => {
                    this.form.secondary.subscriber_city=response.data.data.City;
                    this.form.secondary.subscriber_state_id=response.data.data.id
                });
            }else if(from == 'sub_auto_accident'){
                axios.post("json_dropdown/state-city-auto-populations",{zip_code:this.form.auto_accident.subscriber_zip_code})
                .then((response) => {
                    this.form.auto_accident.subscriber_city=response.data.data.City;
                    this.form.auto_accident.subscriber_state_id=response.data.data.id
                });
            }else if(from == 'sub_tertiary'){
                axios.post("json_dropdown/state-city-auto-populations",{zip_code:this.form.tertiary.subscriber_zip_code})
                .then((response) => {
                    this.form.tertiary.subscriber_city=response.data.data.City;
                    this.form.tertiary.subscriber_state_id=response.data.data.id
                });
            }
            else if(from == 'patient_auto_accident'){
                axios.post("json_dropdown/state-city-auto-populations",{zip_code:this.form.auto_accident.subscriber_zip_code})
                .then((response) => {
                    this.form.auto_accident.insurance_company_city=response.data.data.City;
                    this.form.auto_accident.insurance_company_state_id=response.data.data.id
                });
            }
        },
        save(type) {
            this.form.patient_id = this.patient_id;
            this.form.patient_unique_id = this.$store.state.patientDetail.patient_unique_id;
            this.form.insurance_type = type;
            /*primary*/
            this.form.primary = JSON.stringify(this.form.primary);
            /*secondary*/
            this.form.secondary = JSON.stringify(this.form.secondary);
            /*tertiary*/
            this.form.tertiary = JSON.stringify(this.form.tertiary);
            /*worker comp.*/
            this.form.worker_comp = JSON.stringify(this.form.worker_comp);
            /*auto accident*/
            this.form.auto_accident = JSON.stringify(this.form.auto_accident);
            /*durable med. eqpt*/
            this.form.durable_med_eqpt = JSON.stringify(this.form.durable_med_eqpt);
            /*patient copay*/
            this.form.patient_copay_detail = JSON.stringify(this.form.patient_copay_detail);
            this.postForm("/patient/"+this.$store.state.patientDetail.patient_unique_id+"/insuranceinfo/update","",true,"FormData");     
                
        },
        formPostSuccess(data) {
            this.$store.state.patientinsuranceinfo.form = JSON.parse(JSON.stringify(this.$store.state.patientinsuranceinfo.defaultFormData));
            this.moshaToast("Record added successfully.", "success");
             window.location.reload();   
            this.getData();          
        },   
        primary_getdobdate($event){
            console.log($('#primary_subscriber_dob').val());
            if($('#primary_subscriber_dob').val() == ''){
                this.form.primary.subscriber_dob = null;
            }else{
                this.form.primary.subscriber_dob = moment($event.value).format('YYYY-MM-DD');
            }
        },
        secondary_getdobdate($event){
            if($('#secondary_subscriber_dob').val() == ''){
                this.form.secondary.subscriber_dob = null;
            }else{
                this.form.secondary.subscriber_dob = moment($event.value).format('YYYY-MM-DD');
            }
        },
        tertiary_getdobdate($event){
            if($('#tertiary_subscriber_dob').val() == ''){
                this.form.tertiary.subscriber_dob = null;
            }else{
                this.form.tertiary.subscriber_dob = moment($event.value).format('YYYY-MM-DD');
            }
        },
        auto_accident_getdobdate($event){
            if($('#auto_accident_subscriber_dob').val() == ''){
                this.form.auto_accident.subscriber_dob = null;
            }else{
                this.form.auto_accident.subscriber_dob = moment($event.value).format('YYYY-MM-DD');
            }
        },
        auto_accident_unable_to_work_start($event){
            if($('#patient_unable_to_work_start').val() == ''){
                this.form.auto_accident.patient_unable_to_work_start = null;
            }else{
                this.form.auto_accident.patient_unable_to_work_start = moment($event.value).format('YYYY-MM-DD');
            }
        }, 
        auto_accident_unable_to_work_end($event){
            if($('#patient_unable_to_work_end').val() == ''){
                this.form.auto_accident.patient_unable_to_work_end = null;
            }else{
                this.form.auto_accident.patient_unable_to_work_end = moment($event.value).format('YYYY-MM-DD');
            }
        },  
       
    },
    async beforeCreate() {
        this.$store.state.patientinsuranceinfo.form = JSON.parse(JSON.stringify(this.$store.state.patientinsuranceinfo.defaultFormData));
        var loadCountry = 231;
        axios.post("/json_autocomplete/" + loadCountry + "/state")
        .then((response) => {
            this.states = response.data.data.map((state) => {
                return { id: state.id, text: state.name };
            });       
        });
    },
    validations() {
        return this.$store.state.patientinsuranceinfo.validationRules;
    },

}
</script>

<style>

</style>