<template>
    <div class="box p-15">
        <div class="mdc-tab">
            <ul class="nav nav-tabs mt-0 text-center text-sm-start" role="tablist">
                <li class="nav-item"> <a class="nav-link active" data-bs-toggle="tab" href="#healthCareBilling" @click.prevent="changeTab('HealthCareBilling')" role="tab">Health Care</a> </li>
                <!-- <li class="nav-item"> <a class="nav-link" data-bs-toggle="tab" href="#eCommerceBilling" @click.prevent="changeTab('ECommerceBilling')" role="tab">eCommerce</a> </li> -->
                <li class="nav-item"> <a class="nav-link" data-bs-toggle="tab" href="#billingPOS" @click.prevent="changeTab('BillingPOS')" role="tab">POS</a> </li>
                <!-- <li class="nav-item"> <a class="nav-link" data-bs-toggle="tab" href="#billingSummary" @click.prevent="changeTab('BillingSummary')" role="tab">Billing Summary</a> </li> -->
                <li class="nav-item"> <a class="nav-link" data-bs-toggle="tab" href="#billingContract" @click.prevent="changeTab('BillingContract')" role="tab">Contract</a> </li>
                <li class="nav-item"> <a class="nav-link" data-bs-toggle="tab" href="#wallet" @click.prevent="changeTab('Wallet')" role="tab">Wallet</a> </li>
            </ul>
            <div class="tab-content">
                <div class="tab-pane active" id="healthCareBilling" role="tabpanel">
                    <table class="table">
                        <thead>
                            <tr class="text-center">
                                <th></th>
                                <th>Appointment Number</th>
                                <th>Schedule Date</th>
                                <th>Health Care</th>
                                <th>Custom Charges</th>
                                <!-- <th>Discount</th> -->
                                <th>Total</th>
                                <th>Received</th>
                                <th>Remaining</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody v-for="(item,index) in list.slice(this.start,this.end)" v-if="list.length>0">
                            <tr class="text-center">
                                <td>
                                    <a href="javascript:void(0)" class="waves-effect waves-light btn btn-info-light btn-xs allopenbtn" @click="getreceipt(item.id)" v-bind:id="'add'+item.id">
                                        <i class="fa fa-plus"></i>
                                    </a>
                                    <a href="javascript:void(0)" class="waves-effect waves-light btn btn-danger-light btn-xs allclosebtn d-none" @click="receiptclose(item.id)" v-bind:id="'min'+item.id">
                                        <i class="fa fa-minus"></i>
                                    </a>
                                </td>
                                <td>{{item.appointment_number}}</td>
                                <td>{{$filters.displayDateTimeLocal(item.schedule_date,'',false,"ddd, MM-DD-YYYY",true)}}</td>
                                <td>{{ $filters.toUSD(item.healthcare)}}</td>
                                <td>{{ $filters.toUSD(item.customcharges)}}</td>
                                <!-- <td>{{item.discount}}</td> -->
                                <td>{{  $filters.toUSD(item.healthcare + item.customcharges) }}</td>
                                <td>{{ $filters.toUSD(item.received_amount) }}</td>
                                <td>{{ $filters.toUSD(item.healthcare + item.customcharges - item.received_amount) }}</td>
                                <td>
                                    <a href="javascript:void(0)" class="waves-effect waves-light btn btn-info-light btn-sm me-1" data-bs-toggle="modal" data-bs-target="#addbilling" @click="getappointmentnumber(item.appointment_number)"><i class="fa fa-plus fs-12"></i> Billing</a>
                                    <a href="javascript:void(0)" class="waves-effect waves-light btn btn-info btn-sm" data-bs-toggle="modal" data-bs-target="#makepayment" @click="getappointmentid(item.id)"><i class="fa fa-plus fs-12"></i> Payment</a>
                                </td>
                            </tr>
                            <tr class="d-none receipt_hide" v-bind:id="'receipt'+item.id">
                                <td class="b-1" colspan="9" style="background: #f8f9fa;">
                                    <table class="table">
                                         <thead>
                                            <tr>
                                                <th>Payment Date</th>
                                                <th>Amount</th>
                                                <th>Payment Type</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(items,index) in receiptlist" v-if="receiptlist.length > 0">
                                                <td>{{$filters.displayDateTimeLocal(items.payment_date,'',false,"ddd, MM-DD-YYYY",true)}}</td>
                                                <td>${{items.paid_amount}}</td>
                                                <td>{{items.payment_type}}</td>
                                                <td>
                                                    <Popper arrow content="View Receipt" hover=true class="helptips">
                                                        <a href="javascript:void(0)" class="waves-effect waves-circle btn btn-circle btn-info btn-xs me-1" @click ="printReceipt(items.id)">
                                                            <i class="fa fa-eye"></i>
                                                        </a>
                                                    </Popper>
                                                    <Popper arrow content="Email Receipt" hover=true class="helptips">
                                                        <a href="javascript:void(0)" class="waves-effect waves-circle btn btn-circle btn-info btn-xs" @click="sendReceipt(items.id)">
                                                            <i class="fa fa-paper-plane"></i>
                                                        </a>
                                                    </Popper>
                                                </td>
                                            </tr>
                                            <tr v-if="receiptlist.length == 0">
                                                <td colspan="4"><center>No Record Found.</center></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <td colspan="9" class="text-center"> No data available</td>
                            </tr>
                        </tbody>
                    </table>
                    <label>Showing {{start}} to {{(end >= list_lenght )? list_lenght : end}} of {{list_lenght}} entries</label>
                    <a v-if="end < list_lenght" href="javascript:void(0)" class="btn btn-light float-end" @click="next(this.end)">Next</a>
                    <p class="float-end">
                        <a href="javascript:void(0)" class="btn btn-light me-5" v-for="item in steps" @click="pagejump(item)">{{item}}</a>
                    </p>
                    <a v-if="this.start != 0" href="javascript:void(0)" class="btn btn-light float-end me-5" @click="previous(this.end,this.start)">Previous</a>
                </div>
            </div>
        </div>
    </div>
<!--show Add Billing start-->
    <div class="modal fade" id="addbilling" tabindex="-1" role="dialog" aria-labelledby="addbilling" aria-hidden="true">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <h3 class="modal-title fw-900 mt-0">Add Billing</h3>
               </div>
               <div class="modal-body" style="max-height: 720px;overflow: auto;">
                    <AppointmentBilling v-if="appointment_number" :AppointmentNumber="appointment_number" :permission="permissioncheck(this.$store.state.permission.create_update)" />
               </div>
            </div>
        </div>
    </div>
<!--show Add Billing end-->

<!--show Make Payment start-->
    <div class="modal fade" id="makepayment" tabindex="-1" role="dialog" aria-labelledby="makepayment" aria-hidden="true">
        <div class="modal-dialog" style="max-width: 600px;">
            <div class="modal-content">
                <div class="modal-header">
                    <h3 class="modal-title fw-900 mt-0">Make Payment</h3>
               </div>
               <div class="modal-body">
                    <make-payment v-if="appointment_id" :Appointmentid="appointment_id"></make-payment>
               </div>
               <div class="modal-footer">
                    <button type="button" class="waves-effect waves-light btn btn-danger" data-bs-dismiss="modal" @click="makepaymentclose">Close</button>
               </div>
            </div>
        </div>
    </div>
<!--show Make Payment end-->

<!--viewReceipt Modal Start-->
<div class="modal" id="viewReceipt" tabindex="-1">
    <div class="modal-dialog modal-md" style="max-width:75%">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="btn-close btn-xs" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <vue-pdf-viewer height="1050px" :url="receipt_url" v-if="embed=0">
                </vue-pdf-viewer>
                <iframe :src="receipt_url" width="100%" height="500px" v-if="embed=1" />
                <img srd="images/1.gif" v-else> 
            </div>
        </div>
    </div>
</div>
<!--viewReceipt Modal end-->


</template>
<script>
    import axios from "@/scripts/axios.js";
    import AppointmentBilling from "@/views/Appointments/AppointmentBilling.vue";
    import MakePayment from "@/components/patients/details/Payment/BillingSummary.vue";
    import permissionMixins from "@/scripts/mixins/permission.js";
    import Popper from "vue3-popper";
    import submitMixins from "@/scripts/mixins/submitMixins.js";
    export default {
        mixins: [permissionMixins,submitMixins],
        components: {
            AppointmentBilling,
            MakePayment,
            Popper
        },
        data() {
            return {
                list:[],
                list_lenght:'',
                start:0,
                end:10,
                step:10,
                steps:'',
                appointment_number : '',
                appointment_id :'',
                is_inline_nav : true,
                receiptlist:[],      
                receipt_url: '',
                embed: 0,          
            };
        },
        async beforeCreate() {
            this.$store.state.loader = true;
            axios.post("/payment/healthcare-list",{patient_id:this.$store.state.patientDetail.patient_id})
            .then((response) => {
              this.list = response.data.data;
              this.$store.state.loader = false;
              this.list_lenght = this.list.length;
              this.steps = parseInt(this.list_lenght / this.step);
            });
        },
        methods: {
            changeTab (components) {
                this.$store.state.patientDetailsComp = components;
                let props = this.$router.resolve({ 
                    name: components,
                    params: { id: this.$route.params.id},
                });
                history.pushState(null, '',props.href);
                /*if (history.pushState && props) {
                    if (this.hash=='' || this.hash==null || typeof this.hash === undefined || typeof this.hash === 'undefined') {
                    }
                    else {
                        history.pushState(null, '',props.href+"/#"+this.hash);
                    }
                }*/
            },
            pagejump(id){
                var last_id = parseInt(id * this.step);
                this.start = parseInt(last_id - this.step);
                this.end = last_id;
                $('.myCheckbox').prop('checked', false); 
                $('#checkAll').prop('checked', false); 
            },
            next(end){
                this.start = end;
                this.end = parseInt(end) + parseInt(this.step);
                $('.myCheckbox').prop('checked', false); 
                $('#checkAll').prop('checked', false); 
            },
            previous(end,start){
                this.start = parseInt(start)-parseInt(this.step);
                this.end = start;
                $('.myCheckbox').prop('checked', false); 
                $('#checkAll').prop('checked', false); 
            },
            getappointmentnumber(id){
                this.appointment_number = id;
            },
            getappointmentid(id){
                this.appointment_id = id;
            },
            addbillingmodal(){
                this.$store.state.PaymentGatewayMigration.hidemodal = false;
                $("#addbilling").modal('hide');
                $("#makepayment").modal('hide');
                this.$store.state.loader = true;
                axios.post("/payment/healthcare-list",{patient_id:this.$store.state.patientDetail.patient_id})
                .then((response) => {
                  this.list = response.data.data;
                  this.$store.state.loader = false;
                  this.list_lenght = this.list.length;
                  this.steps = parseInt(this.list_lenght / this.step);
                });
                this.appointment_number = '';
            },
            makepaymentclose(){
                axios.post("/payment/healthcare-list",{patient_id:this.$store.state.patientDetail.patient_id})
                .then((response) => {
                  this.list = response.data.data;
                  this.$store.state.loader = false;
                  this.list_lenght = this.list.length;
                  this.steps = parseInt(this.list_lenght / this.step);
                });
                this.appointment_id = '';  
            },
            getreceipt(id){
                $('.receipt_hide').addClass('d-none');
                $('.allclosebtn').addClass('d-none');
                $('.allopenbtn').removeClass('d-none');
                var receiptid = "#receipt"+id;
                $(receiptid).removeClass('d-none');
                $("#add"+id).addClass('d-none');
                $("#min"+id).removeClass('d-none');
                this.$store.state.loader = true;
                axios.post("/payment/payment-receipt-list",{patient_id:this.$store.state.patientDetail.patient_id,appointment_id:id})
                .then((response) => {
                  this.receiptlist = response.data.data;
                  this.$store.state.loader = false;
                }).catch((error) => {
                    this.$store.state.loader = false;
                    this.moshaToast(error.response.data.message, "danger");
                });
            },
            receiptclose(id){
                $("#add"+id).removeClass('d-none');
                $("#min"+id).addClass('d-none');
                $("#receipt"+id).addClass('d-none');
            },
            printReceipt (id) {
                this.getSrc(id);
                $("#viewReceipt").modal("show");
            },
            async getSrc(id) {
                var masterId = id;
                const res = await fetch(process.env.VUE_APP_API_BASE_URL+'/payment/printApptReceipt', {
                    method: 'POST',
                    headers: {
                        'Authorization' : 'Bearer '+localStorage.getItem('authToken'),
                        'Content-Type' : 'application/json'
                    },
                    body: JSON.stringify({ payment_id: masterId }),
                });
                const blob = await res.blob();
                const urlObject = URL.createObjectURL(blob);
                this.receipt_url = urlObject;
                this.browserCheck();
            },
            browserCheck(){
                // Opera 8.0+
                var isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

                // Firefox 1.0+
                var isFirefox = typeof InstallTrigger !== 'undefined';

                // Safari 3.0+ "[object HTMLElementConstructor]" 
                var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));

                // Internet Explorer 6-11
                var isIE = /*@cc_on!@*/false || !!document.documentMode;

                // Edge 20+
                var isEdge = !isIE && !!window.StyleMedia;

                // Chrome 1 - 79
                var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

                // Edge (based on chromium) detection
                var isEdgeChromium = isChrome && (navigator.userAgent.indexOf("Edg") != -1);

                // Blink engine detection
                var isBlink = (isChrome || isOpera) && !!window.CSS;

                if (isChrome==true) {
                    this.embed = 0;
                } else {
                    this.embed = 1;
                }
            },
            sendReceipt(id) {
                this.$store.state.loader = true;
                axios
                .post('payment/printApptReceipt',{payment_id : id , send_email : true })
                .then((response) => {
                    this.$store.state.loader = false;
                    this.moshaToast('Email sent successfully.', "success");
                })
                .catch((error) => {
                    this.$store.state.loader = false;
                    this.moshaToast("Coundn't send email.", "danger");
                });
            },
        },
        watch: {
            "$store.state.PaymentGatewayMigration.hidemodal": function() {
              this.addbillingmodal();
            },
        }
    }
</script>