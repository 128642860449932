<template>
    <div id="myAProblemModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
        <div class="modal-dialog" style="max-width: 530px;">
            <div class="modal-content">
                <div class="modal-header">
                    <h2 class="modal-title m-0">Add New Vaccine</h2>
                </div>
                <add-new-vaccine></add-new-vaccine>                
            </div>
        </div>
    </div>

    <div class="box">
        <div class="box-body">
            <div class="pageheading">
                <div class="row justify-content-between">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xs-6">
                        <div class="content-header">
                            <h2 class="heading2 font-bold my-5">Immunizations <a href="#" data-bs-toggle="modal" data-bs-target="#immunizationdocument" title="Reference of Knowledge Base" v-on:click.prevent="reset()"> <i class="fa fa-question-circle fs-20"></i></a></h2>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xs-6 text-sm-end" v-if="permissioncheck(this.$store.state.permission.create_update)">
                        <a title="Add New Vaccine" href="javascript:void(0);" class="waves-effect waves-light btn btn-info me-1 mt-1" data-bs-toggle="modal" data-bs-target="#myAProblemModal"><i class="fa fa-plus fs-14"></i> Vaccine</a>
                    </div>
                </div>
            </div>
            <h3 class="heading3 fw-600">Vaccines given to {{this.$store.state.patientDetail.first_name}}  {{this.$store.state.patientDetail.last_name}}</h3>
            <div class="table-responsive">
                <table class="table table-hover">
                    <thead>
                        <tr>
                            <th>Vaccine Name</th>
                            <th>Vaccine CVX Code</th>
                            <th>Vaccinated On</th>
                            <th>Vaccinated By</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody v-if="list.length>0">
                        <tr  v-for="(item, index) in list" :key="index">
                            <td>{{item.vaccine_name}}</td>
                            <td>{{item.cvx_code}}</td>
                            <td v-if="item.vaccination_status == 'Completed'">{{$filters.displayDateTimeLocal(item.vaccinated_on,'',false,"ddd, MM-DD-YYYY",true)}}</td>
                            <td v-else>{{$filters.displayDateTimeLocal(item.created_at,'',false,"ddd, MM-DD-YYYY",true)}}</td>
                            <td v-if="item.vaccination_status == 'Completed'">{{item.administered_by}}</td>
                            <td v-else>-</td>
                            <td v-if="item.vaccination_status == 'Completed'"><span class="badge badge-success-light font-600"><i class="fa fa-check-circle"></i> {{item.vaccination_status}}</span></td>
                            <td v-else>
                                <Popper
                                    arrow
                                    :content="item.refuse_dec"
                                    hover="true"
                                    class="helptips cursor-pointer">
                                    <span class="badge badge-danger-light font-600"><i class="fa fa-info-circle"></i> Refused</span>
                                </Popper>
                            </td>
                        </tr>
                       
                    </tbody>
                    <tbody v-else>
                        <tr >
                            <td colspan="5" class="text-center"> No data available</td>
                        </tr>
                    </tbody>
                </table>
            </div>
             <!--document model start-->
            <div id="immunizationdocument" class="modal fade" tabindex="-1" role="dialog"
            aria-labelledby="myModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-md">
            <div class="modal-content">
                <div class="modal-header">
                <h3 class="modal-title fw-900 mt-0">Reference of Knowledge Base</h3>
                </div>
                <div class="modal-body overflow-auto" style="max-height: 350px;">
                <table class="table table-bordered">
                <thead>
                <tr>
                    <th scope="col">No.</th>
                    <th scope="col">Knowledge Base Articles</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>1</td>
                    <td> <a :href="path+'how-to-add-a-new-vaccine-type'" target="_blank">How To Add A New Vaccine Type?</a></td>
                </tr>
                </tbody>
                </table>
                <Search :key="searchKey"></Search>
                </div>
                <div class="modal-footer">
                <button type="button" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
                </div>
            </div>
            </div>
            </div>
            <!--document model end-->
        </div>
    </div>

    <vaccines-available v-if="permissioncheck(this.$store.state.permission.create_update)"></vaccines-available>


</template>
<script>
    import AddNewVaccine from "@/components/patients/details/Immunizations/AddNewVaccine.vue";
    import VaccinesAvailable from "@/components/patients/details/Immunizations/VaccinesAvailable.vue";
    import axios from "@/scripts/axios.js";
    import Popper from "vue3-popper";
    import permissionMixins from "@/scripts/mixins/permission.js";
    import Search from "@/components/Search.vue";

    export default {
        mixins: [permissionMixins],
        components : {
            AddNewVaccine,
            VaccinesAvailable,
            Popper,
            Search
        },
        data() {
            return {
                list:{},
                path: process.env.VUE_APP_KB_DOCUMENT,
                searchKey:0,
            }
        },
        async beforeCreate() {
            axios
            .post("patient/" + this.$route.params.id + "/vaccination/list")
            .then((response) => {
                this.list = response.data.data;
            });
        },
        methods:{
            reset() {
                this.searchKey = this.searchKey + 1;
            },
            getuser(){
                axios
                .post("patient/" + this.$route.params.id + "/vaccination/list")
                .then((response) => {
                    this.list = response.data.data;
                });
                this.$store.state.Agecvx.key = 0; 
            }
        },
        watch: {
            "$store.state.Agecvx.key": function() {
              this.getuser();
            },
        }
    }
</script>