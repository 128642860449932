<template>
	<div>
		<div class="panel-group panel-group-simple panel-group-continuous mb-0" id="category-1" aria-multiselectable="true" role="tablist">
			<div class="panel pagestabscoll">
				<div class="panel-heading" id="question-1" role="tab">
					<a class="panel-title d-block mb-0" aria-controls="answer-1" aria-expanded="true" data-bs-toggle="collapse" href="#answer-1"  data-parent="#accordion1">CRM Status</a>
				</div>
				<div class="panel-collapse collapse show" id="answer-1" aria-labelledby="question-1" role="tabpanel" data-bs-parent="#category-1">
					<div class="authform-modal box">
						<select-box :isRequired="true" title="CRM Pipeline list" v-model="v$.form.crm_pipeline_id.$model" :errors="v$.form.crm_pipeline_id.$errors"  v-bind:getDataURL="'/json_dropdown/crm-pipeline-list/' + this.$route.params.id" @change="onChange($event)"> 
						</select-box>						
						<select-box :isRequired="true" title="CRM Prospect Status" v-model="v$.form.crm_prospect_patient_status.$model" :errors="v$.form.crm_prospect_patient_status.$errors" v-bind:getDataURL="'/json_dropdown/crm-prospect-status/' + this.pipeline_id" :key=this.index></select-box>						
						<div class="form-group row">
							<label class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end">Wellness Coordinator</label>
							<div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3">
								<div class="controls">
									<select class="form-select form-control" v-model="form.wellness_coordinator">
										<option value=null selected="selected" disabled="disabled">Select Wellness Coordinator</option>
										<template v-for="item in wellnessList" :key="item.id">
											<option :value="item.id" v-if="item.user_type == 'staff'">{{ item.name }} </option>
										</template>
									</select>
								</div>
							</div>
						</div>						
						<div class="col-12 col-sm-12 col-md-12 col-lg-11 col-xl-9 text-center">
							<form-btn title="Save" btnCls="btn-info saveData" @click="save(false)"></form-btn>
						</div>
					</div>
				</div>
				<div class="panel pagestabscoll">
					<div class="panel-heading" id="question-2" role="tab">
						<a class="panel-title d-block mb-0" aria-controls="answer-2" aria-expanded="false" data-bs-toggle="collapse" href="#answer-2" data-parent="#accordion1">Patient Source History</a>
					</div>
					<div class="panel-collapse collapse" id="answer-2" aria-labelledby="question-2" role="tabpanel"
						data-bs-parent="#category-1">
						<div class="panel-body py-20" style="border-color:#EFEFFD;">
							<div class="timeline Patient_History" style="	background-color: rgb(221 228 243); box-sizing: border-box;">
								<div v-for="(item, i) in events" v-bind:key="i" :class='i % 2 ? "timeline-item left" : "timeline-item right"'  style="height:auto;">
									<div class="content" style="marging:10px;">
										<h2 style="padding-left:10px">Source: {{ item.source_name }}</h2>
										<p style="padding-left:10px">Added on: {{ item.create_date }}</p>
										<p style="padding-left:10px" v-if="item.corporate_name">Corporate Name:
											{{ item.corporate_name }}</p>
										<p style="padding-left:10px" v-if="item.secondary_source_name">Secondary Source
											Name: {{ item.secondary_source_name }}</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import SelectBox from "@/components/base/formFields/selectBox.vue";
import useVuelidate from "@vuelidate/core";
import submitMixins from "@/scripts/mixins/submitMixins.js";
import permissionMixins from "@/scripts/mixins/permission.js";
import axios from "@/scripts/axios.js";
import moment from "moment";
import FormBtn from "@/components/base/formFields/formBtn.vue";

import {
	helpers,
	required,
	email,
	minLength,
	maxLength,
	sameAs,
	numeric,
} from "@vuelidate/validators";
export default {
	mixins: [submitMixins, permissionMixins],
	setup: () => {
		return { v$: useVuelidate() };
	},
	components: {
		SelectBox,
		FormBtn,
	},
	data() {
		return {
			form: {
				crm_pipeline_id: null,
				crm_prospect_patient_status: null,
				wellness_coordinator: null,
			},
			pipeline_id: 0,
			index: 0,
			wellnessList: null,
			events: '',
		};
	},
	validations() {
		return {
			form: {
				crm_pipeline_id: {
					required: helpers.withMessage("CRM Pipeline is required.", required),
				},
				crm_prospect_patient_status: {
					required: helpers.withMessage("CRM Prospect Status is required.", required),
				}
			},
		};
	},
	mounted() {
		axios.post("json_dropdown/getCrmstatus", {
			'id': this.$route.params.id,
		}).then(response => {
			this.form.crm_pipeline_id = response.data.data.crm_pipeline_id;
			this.pipeline_id = response.data.data.crm_pipeline_id;
			this.form.crm_prospect_patient_status = response.data.data.crm_prospect_patient_status;
			this.form.wellness_coordinator = response.data.data.wellness_coordinator;
		});
		axios.post("json_dropdown/getpatienthistory", {
			'id': this.$route.params.id,
		}).then(response => {
			this.events = response.data.data;
		});
		this.getwellnesscoordinator();
	},
	methods: {
		onChange(e) {
			this.pipeline_id = e.target.value;
			axios.get("/getCrmprospectStatus/" + this.pipeline_id + "/" + this.$route.params.id, {
			}).then(response => {
				if (response.data.data == "") {
					this.form.crm_prospect_patient_status = null;
					this.form.wellness_coordinator = null;
				} else {
					this.form.crm_prospect_patient_status = response.data.data[0]['crm_prospect_patient_status'];
					this.form.wellness_coordinator = response.data.data[0]['wellness_coordinator'];
				}
			});
			this.index++;
			this.getwellnesscoordinator();
			this.v$.$reset();
		},
		getwellnesscoordinator() {
			axios.get("/json_dropdown/user-list", {
			}).then(response => {
				this.wellnessList = response.data.data
			});
		},
		save() {
			this.v$.$validate();
			if (!this.v$.$error) {
				this.$store.state.loader = true;
				axios.post("patient/CRMstore", {
					'id': this.$route.params.id,
					'crm_pipeline_id': this.form.crm_pipeline_id,
					'crm_prospect_patient_status': this.form.crm_prospect_patient_status,
					'wellness_coordinator': this.form.wellness_coordinator
				}).then(response => {
					if (response.status == 200) {
						this.$store.state.loader = false;
						this.moshaToast("Records add successfully", 'success');
						this.v$.$reset();
					}
				});
			}
		}
	}
}
</script>