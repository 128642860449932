<template>
	<div class="mt-0" v-if="this.server_error != ''">
		<section class="content">
			<div class="box text-center">
				<div class="box-body py-50">
					<i class="fa fa-frown fs-70 text-danger"></i>
					<div class="content-header">
						<h1 class="fw-300 fs-36">{{ this.server_error }}</h1>
					</div>
				</div>
			</div>
		</section>
	</div>
	<div class="mt-0" v-if="this.success_message != ''">
		<section class="content">
			<div class="box text-center">
				<div class="box-body py-50">
					<i class="fa fa-frown fs-70 text-suc"></i>
					<div class="content-header">
						<h1 class="fw-300 fs-36">{{ this.success_message }}</h1>
						<p>If done accidentally then <a href="javascript:void(0)" @click="callCrmSubscribeAgain(true)">click here</a> to subscribe again.</p>
					</div>
				</div>
			</div>
		</section>
	</div>
	<div class="mt-0" v-if="this.success_subscribe_message != ''">
		<section class="content">
			<div class="box text-center">
				<div class="box-body py-50">
					<i class="fa fa-smile-o fs-70 text-suc"></i>
					<div class="content-header">
						<h1 class="fw-300 fs-36">{{ this.success_subscribe_message }}</h1>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>
<script>
	import axios from "@/scripts/axios.js";
	import submitMixins from "@/scripts/mixins/submitMixins.js";
	import $ from "jquery";
	export default {
		mixins: [ submitMixins ],
		name: "CRMUnsubscribe",
		components: {
		},
		data() {
			return {
				server_error:'',
				success_message:'',
				success_subscribe_message:'',
			}
		},
		mounted() {
			$('.tophead').html("");
			this.callCrmUnsubscribe(false);
		},
		methods:{
			callCrmUnsubscribe(is_subscribe) {
				axios.post("/crm/unsubscribe", { chart_id: this.$route.params.chart_id, token : this.$route.params.token, is_subscribe : is_subscribe })
				.then((response) => {
					this.success_message = response.data.data;
					this.success_subscribe_message = '';
				}).catch((error) => {
					this.server_error = error.response.data.message;
				});
			},
			callCrmSubscribeAgain(is_subscribe) {
				axios.post("/crm/unsubscribe", { chart_id: this.$route.params.chart_id, token : this.$route.params.token, is_subscribe : is_subscribe })
				.then((response) => {
					this.success_message = '';
					this.success_subscribe_message = response.data.data;
				}).catch((error) => {
					this.server_error = error.response.data.message;
				});
			},
		}
	}
</script>