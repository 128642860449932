const AppointmentStatusDashboard = {
    namespaced: true,
  
    state: {
      listUrl: "/appointment/search",
      header: [
        {"":""},
        {"patient_name":"Patient"},
        {"provider_name":"Provider"},
        {"office_name":"Office"},
        {"type":"Appointment Type"},
        {"appointment_profile_name":"Visit Profile"},
        {"appointment_status_name":"Appointment Status"},
        {"schedule_time":"Scheduled At"},
        {"":"Action"},
      ],
      pagination: {
        to: "",
        from: "",
        total: "",
        last_page: "",
        current_page: "",
        pageNavArr: [],
      },
      filter: {
        to_date:'',
        office_id: '', 
        from_date:'',
        date_range: '',
        visit_profile_id: '',
        appointment_type: 'Tele Health',
        appointment_status_id: '',
      },
      list: [],
    },
  
    getters: {},
    mutations: {},
    actions: {},
  };
  
  export default AppointmentStatusDashboard;
  