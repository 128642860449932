<template>
    <div class="content-wrapper">
        <div class="container-full">
            <div class="content-header">
                <div class="row justify-content-between">
                    <div class="col-12 col-sm-8 col-md-9 col-lg-6 col-xs-6">
                        <h1 class="mt-0">Custom Billing Status</h1>
                    </div>
                    <div class="col-12 col-sm-4 col-md-3 col-lg-3 col-xs-2 text-sm-end">
                        <a href="#/custom/billing-status/archived"
                            class="waves-effect waves-light btn btn-info-light me-1 mb-1"> Show Archived </a>
                        <a class="waves-effect waves-light btn btn-info ms-1 mb-1" @click="createModal"><i
                                class="fa fa-plus fs-14"></i> Add New Status</a>
                    </div>
                </div>
            </div>
            <div class="box">
                <div class="box-body">
                    <div class="table-responsive">
                        <data-table-server dataType="CustomBillingStatus" dataMethod="post" :key="key">
							<template v-slot="slotProps">
                                <td>{{ slotProps.item.sr_no }}</td>
                                <td>{{ slotProps.item.name }}</td>
                                <td>
                                    <slot v-if="slotProps.item.description != null">
                                        {{ slotProps.item.description }}
                                    </slot>
                                    <slot v-else>
                                        -
                                    </slot>
                                </td>
                                <td>
                                    <Popper arrow content="Edit Billing Status" :hover="true"
                                        class="helptips cursor-pointer">
                                        <a href="#" @click="edit(slotProps.item)"
                                            class="waves-effect waves-circle btn btn-circle btn-info btn-xs me-1"><i
                                                class="fa fa-pencil"></i></a>
                                    </Popper>
                                    <Popper arrow content="Delete Billing Status" :hover="true"
                                        class="helptips cursor-pointer">
                                        <a href="#" @click="remove(slotProps.item.id)"
                                            class="waves-effect waves-circle btn btn-circle btn-danger btn-xs"><i
                                                class="fa fa-trash"></i></a>
                                    </Popper>
                                </td>
							</template>
						</data-table-server>                        
                    </div>
                </div>
                <div class="modal fade" tabindex="-1" id="billingStatus">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h3 class="modal-title font-bold m-0">{{ action }} Custom Billing Status</h3>
                                <button type="button" class="btn-close btn-xs" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <input-field title="Name" labelClass="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                                    divClass="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" v-model="form.name">
                                </input-field>
                                <text-area title="Description" divClass="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                                    labelClass="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" v-model="form.description">
                                </text-area>
                                <button @click="submitForm()"
                                    class="waves-effect waves-light btn btn-info me-1">{{ btnlabel }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Popper from "vue3-popper";
import axios from "@/scripts/axios.js";
import TextArea from "@/components/base/formFields/textArea.vue";
import InputField from "@/components/base/formFields/InputField.vue";
import submitMixins from "@/scripts/mixins/submitMixins.js";
import permissionMixins from "@/scripts/mixins/permission.js";
import DataTableServer from "@/components/base/DataTableServer.vue";
export default {
    data() {
        return {
            list: [],
            form: {
                id: null,
                name: null,
                description: null,
            },
            action: null,
            btnlabel: null,
            key:0,
        }
    },
    mixins: [permissionMixins, submitMixins],
    components: { InputField, TextArea, Popper, DataTableServer },
    methods: {
        getList() {
            this.key++;
        },
        createModal() {
            this.action = "Create";
            this.btnlabel = "Save";
            $("#billingStatus").modal("show");
        },
        submitForm() {
            if (this.form.id == null) {
                var url = "/billingstatus/store";
            } else {
                var url = "/billingstatus/update";
            }
            axios.post(url, this.form)
                .then((response) => {
                    this.moshaToast("Billing status update successfully.", "success");
                    $("#billingStatus").modal("hide");
                    this.getList();
                });
        },
        edit(details) {
            this.action = "Edit";
            this.btnlabel = "Update";
            this.form.id = details.id;
            this.form.name = details.name;
            this.form.description = details.description;
            $("#billingStatus").modal("show");
        },
        remove(id) {
            var confermation = confirm("Are you sure you want to delete this billing status ??");
            if (confermation) {
                axios.post("/billingstatus/delete", { id: id })
                    .then((response) => {
                        this.moshaToast("Billing status delete successfully.", "success");
                        this.getList();
                    });
            }
        },
    },
    mounted() {},
}
</script>