<template>
    <div class="tab-content">
        <div class="box mb-10" v-if="permission_ids.split(',').includes('33') && role_id =='1'">
            <div class="box-body">
                <div class="form-group row">
                    <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                        <select-box-static title="Payment Profile" divClass="col-12 col-sm-6 col-md-8 col-lg-9 col-xl-9" v-model="form.payment_profile" :option="payment_profile"></select-box-static>

                        <div class="form-group row">
                            <label class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end">Billing status <small class="text-danger asterisksign" style="display: none;">*</small></label>
                            <div class="col-12 col-sm-6 col-md-8 col-lg-9 col-xl-9">
                                <div class="controls">
                                    <select name="select" v-model="form.billing_status" required="" id="billing_status" title="Billing status" class="form-select form-control" placeholder="Select Billing Status" @change="change_billing_status($event)">
                                        <option disabled="" value="">Select Billing status</option>
                                        <option v-for="(status,index) in billing_status" :value="status.value" :key="index">{{status.title}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-4"></div>
                            <div id="appointment_amount" style="color:red" class="col-md-12">
                            </div>
                        </div>
                        
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                        <a :href="billing_details_link" target="_blank" class="waves-effect waves-light btn btn-info-light btn-xs float-end me-5" title="Billing Details"> Billing Details <i class="fa fa-mail-forward fs-14"></i></a>
                        <a href="javascript:void(0)" @click="getCMS()" class="waves-effect waves-light btn btn-info-light btn-xs float-end me-5">View CMS1500 Form <i class="fa fa-mail-forward fs-14"></i></a>
                    </div>
                </div>                    
            </div>
        </div>
        <div class="box mb-10" v-if="reqfrom!='lifestyle'">
            <div class="box-body">
                <div class="form-group row">
                    <div class="col-12 col-sm-3 col-md-4 col-lg-3 col-xl-3 text-sm-end">
                        <label class="col-form-label">ICD-10 Codes</label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-5 col-xl-5">
                        <div class="selectcontrols">
                            <Icd10-code @clicked="icdsearchresult" :isSelected="false"></Icd10-code>
                        </div>
                        <div class="" v-if="icd10duplication!=null">
                            <div class="text-danger word-break-all">{{icd10duplication}}</div>
                        </div>
                    </div>
                </div>
                <div v-if="icd10table.length > 0">
                    <div class="table-responsive">
                        <table class="table table-hover table-bordered mb-0">
                            <thead>
                                <tr>
                                    <td>No</td>
                                    <td>Code</td>
                                    <td>Description</td>
                                    <td>Action</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in icd10table" :key="index">
                                    <td>{{ index + 1 }}</td>
                                    <td>{{ item.code }}</td>
                                    <td>{{ item.description }}</td>
                                    <td>
                                        <Popper arrow content="Delete" :hover="true" class="helptips cursor-pointer">
                                            <a href="#" class="waves-effect waves-circle btn btn-circle btn-danger btn-xs remove" @click="icd10table.splice(index, 1);icdremove(item.table_id,item.code)"><i class="fa fa-trash"></i></a>
                                        </Popper>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="box mb-10" v-if="reqfrom!='lifestyle'">
            <div class="box-body">
                <div class="form-group row">
                    <div class="col-12 col-sm-3 col-md-4 col-lg-3 col-xs-3 text-sm-end">
                        <label class="col-form-label">CPT Codes</label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-5 col-xl-5">
                        <div class="selectcontrols">
                            <Cpt-code
                            @clicked="cptsearchresult"
                            :isSelected="false"
                            ></Cpt-code>
                        </div>
                    </div>
                </div>
                <div v-if="cpttable.length > 0">
                    <div class="table-responsive">
                        <table class="table table-hover table-bordered mb-0">
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>Code</th>
                                    <th>Modifiers</th>
                                    <th>ICD10 Dx Ptrs</th>
                                    <th style="width: 150px;">Quantity</th>
                                    <th style="width: 150px;">Price</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in cpttable" :key="index">
                                    <td>{{ index + 1 }}</td>
                                    <td>{{ item.code }}</td>
                                    <td>
                                        <Cpt-hcpcs-modifier
                                        v-model="item.cpt_modifier_a"
                                        class="cptMod me-1"
                                        style="width:35px;"
                                        ></Cpt-hcpcs-modifier>
                                        <Cpt-hcpcs-modifier
                                        v-model="item.cpt_modifier_b"
                                        class="cptMod me-1"
                                        style="width:35px;"
                                        ></Cpt-hcpcs-modifier>
                                        <Cpt-hcpcs-modifier
                                        v-model="item.cpt_modifier_c"
                                        class="cptMod me-1"
                                        style="width:35px;"
                                        ></Cpt-hcpcs-modifier>
                                        <Cpt-hcpcs-modifier
                                        v-model="item.cpt_modifier_d"
                                        class="cptMod me-1"
                                        style="width:35px;"
                                        ></Cpt-hcpcs-modifier>
                                    </td>
                                    <td>
                                        <input
                                        type="text"
                                        class="form-control d-inline-block ps-5 pe-5 me-1"
                                        v-model="item.cpt_icd10_pointer_a"
                                        style="width:38px;"
                                        />
                                        <input
                                        type="text"
                                        class="form-control d-inline-block ps-5 pe-5 me-1"
                                        v-model="item.cpt_icd10_pointer_b"
                                        style="width:38px;"
                                        />
                                        <input
                                        type="text"
                                        class="form-control d-inline-block ps-5 pe-5 me-1"
                                        v-model="item.cpt_icd10_pointer_c"
                                        style="width:38px;"
                                        />
                                        <input
                                        type="text"
                                        class="form-control d-inline-block me-1"
                                        v-model="item.cpt_icd10_pointer_d"
                                        style="width:38px;"
                                        />
                                    </td>
                                    <td>
                                        <input
                                        type="text"
                                        class="form-control mx-0"
                                        v-model="item.cpt_qty"
                                        placeholder="Quantity"
                                        />
                                    </td>
                                    <td>
                                        <div class="input-group">
                                            <span class="input-group-text"
                                            ><i class="fa fa-dollar"></i
                                            ></span>
                                            <input
                                            type="text"
                                            class="form-control mx-0"
                                            placeholder="Price"
                                            v-model="item.cpt_price"
                                            />
                                        </div>
                                    </td>
                                    <td>
                                        <a href="#" class="waves-effect waves-light btn btn-danger btn-xs me-1" @click="cptndctable.splice(cptndctable.indexOf(index),1);ndcremove(item.cpt_ndc_table_id,index,item.cpt_ndc_code);"
                                        v-if="cptndctable.includes(index)"><i class="fa fa-minus"></i> NDC</a>

                                        <a href="#" class="waves-effect waves-light btn btn-info btn-xs me-1" @click="addCptNdc(index)" v-else ><i class="fa fa-plus"></i> NDC </a>
                                        <Popper arrow content="Delete" :hover="true" class="helptips cursor-pointer">
                                            <a href="#" class="waves-effect waves-circle btn btn-circle btn-danger btn-xs" @click="cpttable.splice(index, 1),cptndctable.splice(cptndctable.indexOf(index),1);ndcremove(item.cpt_ndc_table_id,null,item.cpt_ndc_code); cptremove(item.cpt_table_id,item.code);"><i class="fa fa-trash"></i></a>
                                        </Popper>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="box p-10 rounded5 mb-10" v-if="cptndctable.length > 0">
            <div class="table-responsive-sm">
                <table class="table table-hover table-bordered mb-0">
                    <thead>
                        <tr>
                            <td>Procedure</td>
                            <td>Package Code</td>
                            <td>Quantity</td>
                            <td>Units</td>
                            <td>Actions</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in cptndctable" :key="index">
                            <td>
                                {{ cpttable[item].code }}/{{
                                    cpttable[item].cpt_modifier_a
                                }}<span v-if="cpttable[item].cpt_modifier_b"
                                >:{{ cpttable[item].cpt_modifier_b }}</span
                                ><span v-if="cpttable[item].cpt_modifier_c"
                                >:{{ cpttable[item].cpt_modifier_c }}</span
                                ><span v-if="cpttable[item].cpt_modifier_d"
                                >:{{ cpttable[item].cpt_modifier_d }}</span
                                >/${{ cpttable[item].cpt_price }}
                            </td>
                            <td>
                                <NdcCode
                                v-model="cpttable[item].cpt_ndc_code"
                                @clicked="ndcsearchresult"
                                ></NdcCode>
                            </td>
                            <td>
                                <input
                                type="text"
                                class="form-control mx-0"
                                v-model="cpttable[item].cpt_ndc_qty"
                                v-on:keyup="bindndcqty(item, $event.target.value)"
                                placeholder="Quantity"
                                />
                            </td>
                            <td>
                                <div class="controls">
                                    <select
                                        name="select"
                                        v-model="cpttable[item].cpt_ndc_units"
                                        v-on:change="
                                        bindndcunit(item, $event.target.value)
                                        "
                                        class="form-select form-control"
                                        >
                                        <option value="">Select Units</option>
                                        <option
                                        :selected="cpttable[item].cpt_ndc_units == 'UN'"
                                        value="UN"
                                        >UN(Unit){{
                                            cpttable[item].cpt_ndc_units
                                        }}</option
                                        >
                                        <option
                                        :selected="cpttable[item].cpt_ndc_units == 'F2'"
                                        value="F2"
                                        >F2(International Unit)</option
                                        >
                                        <option
                                        :selected="cpttable[item].cpt_ndc_units == 'GR'"
                                        value="GR"
                                        >GR(Gram)</option
                                        >
                                        <option
                                        :selected="cpttable[item].cpt_ndc_units == 'ME'"
                                        value="ME"
                                        >ME(Milligram)</option
                                        >
                                    </select>
                                </div>
                            </td>
                            <td>
                                <Popper arrow content="Delete" :hover="true" class="helptips cursor-pointer">
                                    <a
                                    href="#"
                                    class="waves-effect waves-circle btn btn-circle btn-danger btn-xs"
                                    @click="cptndctable.splice(index, 1);ndcremove(cpttable[item].cpt_ndc_table_id,index,item.cpt_ndc_code);"><i class="fa fa-trash"></i></a>
                                </Popper>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="mb-10">
            <div class="form-group row">
                <div class="col-12 col-sm-3 col-md-4 col-lg-3 col-xs-3 text-sm-end">
                    <label class="col-form-label">Custom Procedures</label>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-5 col-xl-5">
                    <div class="selectcontrols">
                        <Custom-procedure-code
                            @clicked="customproceduresearchresult"
                            :isSelected="false"
                            :appointment_profile_id="appointment_profile_id"
                            :key="this.form.appointment_id"
                            >
                        </Custom-procedure-code>
                    </div>
                    <div class="" v-if="customprocedureduplication!=null">
                        <div class="text-danger word-break-all">{{customprocedureduplication}}</div>
                    </div>
                </div>
            </div>
            <div class="" v-if="customproceduretable.length > 0">
                <div class="table-responsive">
                    <table class="table table-hover table-bordered mb-0">
                        <thead>
                            <tr>
                                <td>No</td>
                                <td style="min-width: 175px;">Code</td>
                                <td>Description</td>
                                <td style="min-width:70px">Quantity</td>
                                <td style="min-width:120px">Price</td>
                                <td>Action</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in customproceduretable" :key="index">
                                <td>{{ index + 1 }}</td>
                                <td style="min-width: 175px;">
                                    {{ item.code }}
                                    <div v-if="cps_data">
                                        <ul v-if="cps_data.length>0 && cps_data[item.id]" class="ul-checklist-h">
                                            <li v-for="(i, idx) in cps_data[item.id]" :key="i.service_id+idx">
                                                <input type='checkbox' :checked="i.is_selected" @change="updateservices(idx, item.id)">  {{ i.product_name }}
                                            </li>
                                        </ul>
                                    </div>
                                </td>
                                <td>{{ item.description }}</td>
                                <td style="min-width:70px">
                                    <input
                                    type="text"
                                    class="form-control mx-0"
                                    v-model="item.custom_procedure_qty"
                                    placeholder="Quantity"
                                    />
                                </td>
                                <td style="min-width:120px">
                                    <div class="input-group">
                                        <span class="input-group-text"
                                        ><i class="fa fa-dollar"></i
                                        ></span>
                                        <input
                                        type="text"
                                        class="form-control mx-0"
                                        v-model="item.custom_procedure_price"
                                        placeholder="Price"
                                        />
                                    </div>
                                </td>      
                                <td>
                                    <Popper arrow content="Delete" :hover="true" class="helptips cursor-pointer">
                                        <a
                                        href="#"
                                        class="waves-effect waves-circle btn btn-circle btn-danger btn-xs"
                                        @click="customproceduretable.splice(index, 1); customprocedureremove(item.id, item.table_id, item.code)"
                                        ><i class="fa fa-trash"></i></a>
                                    </Popper>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="box mb-10" v-if="products.length > 0">
            <div class="box-body">
                <h3 style="font-weight: bold; margin-top: 0;">Products  <span class="waves-effect waves-light badge badge-info-light me-1" style="font-weight: 500;">Products Billed   :${{total}}</span></h3>
                <button class="btn btn-info" type="button" 
                data-bs-toggle="collapse" data-bs-target="#collapseProductArea" 
                aria-expanded="false" aria-controls="collapseProductArea" 
                style=" position: absolute; right: 14px; top: 14px; padding: 0px 8px;" 
                @click="changeCollapseProductArea()">
                    {{ collapseProductArea }}
                </button>
                <div class="collapse" id="collapseProductArea">
                    <div class="table-responsive">
                        <table class="table table-bordered mb-0">
                            <thead>
                                <tr>
                                    <td style="min-width:30px;">No</td>
                                    <td style="min-width:50px">Code</td>
                                    <td style="min-width:120px;">Description</td>
                                    <td style="min-width:50px">Quantity</td>
                                    <td style="min-width:100px">Price</td>
                                    <td style="min-width:50px">Total</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(itemc, indexc) in product_categories" :key="indexc">
                                    <td colspan="6" v-if="products[itemc].length>0">
                                        <b class="m-2">{{ itemc }}</b>      
                                        <input type='checkbox' class="form-check-input ml-3" style="opacity: 1;" 
                                        @click="setdata(itemc,indexc,'all')" :id="itemc" v-model="cate_data[itemc]">
                                        <button class="btn btn-info" :id="`collapseBtn${ indexc}`" type="button" data-bs-toggle="collapse" :data-bs-target="`#collapseProductArea-${ indexc }`" aria-expanded="false" :aria-controls="`collapseProductArea-${ indexc }`" style="float: right; padding: 0px 8px;" @click="changeBtnText(indexc)">
                                            +
                                        </button>
                                        <div class="collapse mt-10" :id="`collapseProductArea-${ indexc }`">
                                            <table class="table table-hover table-bordered mb-0">
                                                <tbody>
                                                    <tr v-for="(itemp, indexp) in products[itemc]" :key="indexp">
                                                        <td width="5%" v-if="itemp.product_qty>0"> <input type='checkbox' class="form-check-input mx-0 checkdata" :class="`checkdata${indexc}`"  style="opacity: 1; position: static;" @click="setdata(itemc, indexp,indexc,this)" :id="`${itemc}-${indexp}`" checked></td>
                                                        <td width="5%"  v-else > <input type='checkbox' class="form-check-input mx-0 checkdata " :class="`checkdata${indexc}`" style="opacity: 1; position: static;" @click="setdata(itemc, indexp,indexc,this)" :id="`${itemc}-${indexp}`"></td>
                                                        <td width="5%">{{ indexp+1 }}</td>
                                                        <td width="15%">{{ itemp.product_code }}</td>
                                                        <td width="35%">{{ itemp.product_name }}</td>
                                                        <td width="15%">
                                                            <input
                                                                type="number"
                                                                class="form-control mx-0 setquantity"
                                                                v-model="itemp.product_qty"
                                                                placeholder="Quantity"
                                                                @keyup="update_total(itemc, indexp)"
                                                                @change="update_total(itemc, indexp)"
                                                            />
                                                        </td>
                                                        <td style="min-width:100px">
                                                            <div class="input-group">
                                                                <span class="input-group-text"><i class="fa fa-dollar"></i></span>
                                                                <input
                                                                    type="number"
                                                                    class="form-control mx-0"
                                                                    v-model="itemp.product_price"
                                                                    placeholder="Price"
                                                                    @keyup="update_total(itemc, indexp)"
                                                                    @change="update_total(itemc, indexp)"
                                                                />
                                                            </div>
                                                        </td>
                                                        <td width="10%">${{ itemp.product_total }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="text-center">

        <button v-if="permission" @click="closeModel" class="waves-effect waves-light btn btn-danger mb-5 me-1">Close</button>

        <button v-if="permission" v-bind:class="(billingStatus=='Fully Billed - Payment Locked') ? 'd-none' : ''" @click="submitForm()" class="waves-effect waves-light btn btn-info mb-5 me-1">Save</button>
    </div>
   

</template>

<style scoped>
    .ul-checklist-h {
        font-size: 1rem; list-style: none; padding-left: 1rem; margin-bottom: 0; margin-top: 0.4rem;
    }
    .ul-checklist-h li { padding: 2px 0; }
    .ul-checklist-h li input[type="checkbox"] { position: unset; opacity: 1 }
</style>

<script>
import InputField from "@/components/base/formFields/InputField.vue";
import FormBtn from "@/components/base/formFields/formBtn.vue";
import Icd10Code from "@/components/base/Icd10Code.vue";
import CptCode from "@/components/base/CptCode.vue";
import NdcCode from "@/components/base/NdcCode.vue";
import CustomProcedureCode from "@/components/base/CustomProcedureCode.vue";
import CptHcpcsModifier from "@/components/base/CptHcpcsModifier.vue";
import axios from "@/scripts/axios.js";
import useVuelidate from "@vuelidate/core";
import $ from "jquery";
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';
import Popper from "vue3-popper";
import SelectBoxStatic from "@/components/base/formFields/SelectBoxStatic.vue";

export default {
    props: {
        AppointmentNumber: {
          type: Number,
          required: true
        },
        uniqueId: {
          type: Number,
        },
        paymentProfile: {
          type: String,
          default: null,
        },
        billingStatus: {
          type: String,
          default: null,
        },
        is_inline_nav : {
          type: Boolean,
          default: false,
          required: false
        },
        permission : {
          type: Boolean,
          default: true,
        },
        reqfrom: {
            type: String,
            default: null,
            required: false
        }
    },
    // mixins: [submitMixins],
    setup: () => {
        const moshaToast = () => {
          createToast("Record updated successfully",{
            timeout: 4000,
            position: "top-right",
            type: "success",
            transition: "slide",
            showIcon: true,
            hideProgressBar: false,
            toastBackgroundColor: "#05825f",
            timeout: 2000, 
            })
      }
      return { moshaToast }
    },
    data() {
        return {
            permission_ids: localStorage.getItem('permission'),
            role_id: localStorage.getItem('role_id'),
            payment_profile:[
                { value: "Cash", title: "Cash" },
                { value: "Insurance", title: "Insurance" },
            ],
            appointment_profile_id:"",
            billing_status:[],
            appointment_number: "",
            icd10table: [],
            cpttable: [],
            dataqyt:[],
            cptndctable: [],
            customproceduretable: [],
            ndctable: [],
            icddelete:[],
            icdcodedelete:[],
            cptdelete:[],
            cptcodedelete:[],
            ndcdelete:[],
            ndccodedelete:[],
            customproceduredelete:[],
            customprocedurecodedelete:[],
            billing_details_link: "",
            allSelected:false,
            form: {
            },
            defaultFormData: {
                appointment_id: '',
                icd10_code_id: [],
                icd10_table_id: [],
                icd10_code: [],
                icd10_description: [],
                cpt_table_id: [],
                cpt_code: [],
                cpt_code_id: [],
                cpt_modifier_a: [],
                cpt_modifier_b: [],
                cpt_modifier_c: [],
                cpt_modifier_d: [],
                cpt_icd10_pointer_a: [],
                cpt_icd10_pointer_b: [],
                cpt_icd10_pointer_c: [],
                cpt_icd10_pointer_d: [],
                cpt_qty: [],
                cpt_price: [],
                cpt_ndc_table_id: [],
                cpt_ndc_code: [],
                cpt_ndc_qty: [],
                cpt_ndc_units: [],
                custom_procedure_table_id: [],
                custom_procedure_code_id: [],
                custom_procedure_code: [],
                custom_procedure_description: [],
                custom_procedure_qty: [],
                custom_procedure_price: [],
                icddelete: [], 
                icdcodedelete:[],
                cptdelete: [], 
                cptcodedelete: [], 
                ndcdelete: [], 
                ndccodedelete: [], 
                customproceduredelete: [],
                customprocedurecodedelete: [],
                payment_profile: null,
                billing_status: null,
            },
            icd10duplication: null,
            customprocedureduplication: null,
            cps: [],
            selected_services_list_filter: [],
            product_categories: [],
            products: [],
            collapseProductArea: "+",
            cps_data: [],
            cate_data:[],
            total:0

        };
    },
    created() {
        this.appointment_number = this.AppointmentNumber;
        this.form.payment_profile = this.paymentProfile;
        this.form.billing_status = this.billingStatus;
        this.billing_details_link = "/#/patient-chart/"+this.uniqueId+"/edit-appointment/"+this.appointment_number+"/billing";
        this.form.cps = [];
        this.form.cps_list = [];
    },
    components: {
        Icd10Code,
        CptCode,
        CustomProcedureCode,
        CptHcpcsModifier,
        InputField,
        FormBtn,
        NdcCode,
        Popper,
        SelectBoxStatic,
    },
    methods: {  
        setdata(data,itemc,type=null,event=null){
            
        if(type=="all"){
            var id="checkdata"+itemc;
            console.log(data);
                var checkdat=document.getElementById(data).checked;
				if (checkdat==true) {
                     $("."+id).prop('checked',true);
                        for (const key in this.products) {
                                var category=key;
                              
                            if (this.products.hasOwnProperty.call(this.products, key)) {
                                const element = this.products[key];
                                element.forEach((element1,index) => {
                                      if (category==data) {
                                          element1.product_qty=1;
                                           this.total=this.total+element1.product_price;
                                       this.update_total(category,index,"donot");
                                    }
                                      
                                });
                                
                            }
                      
                       
                      }
                }
                else{
                     for (const key in this.products) {
                            var category=key;                              
                            if (this.products.hasOwnProperty.call(this.products, key)) {
                                const element = this.products[key];
                                element.forEach((element1,index) => {

                                      if (category==data) {
                                         let qty=element1.product_qty;
                                        element1.product_qty=0;
                                        let multi=qty*element1.product_price;
                                        this.total=this.total-multi;
                                        this.update_total(category,index,"donot");
                                    }                                      
                                });                                
                            }
                      }
                }        
        }else{
          
            var id=data+"-"+itemc;
            var checkdat=document.getElementById(id).checked;
                if (checkdat==true) {   
                for (const key in this.products) {
                    var category=key;
                    if (this.products.hasOwnProperty.call(this.products, key)) {
                        const element = this.products[key];
                        element.forEach((element1,index) => {
                            if (category==data&&index==itemc) {
                                    element1.product_qty=1;
                                     this.total=this.total+element1.product_price;
                                 this.update_total(category,index,"donot");
                            }
                                
                        });
                    }
                }
                }
                else{
                    this.cate_data[data]=false;
                   for (const key in this.products) {
                        var category=key;
                        if (this.products.hasOwnProperty.call(this.products, key)) {
                            const element = this.products[key];
                            element.forEach((element1,index) => {
                                if (category==data&&index==itemc) {
                                    let qty=element1.product_qty;
                                        element1.product_qty=0;
                                        let multi=qty*element1.product_price;
                                        this.total=this.total-multi;
                                    this.update_total(category,index,"donot");
                                } 
                                    
                            });
                        }
                    } 
                  
                }
            }
            
        },    
        getCMS(){		
            if(this.form.appointment_id){
                axios.post("/get-cms1500",{id:this.form.appointment_id})
                .then((response) => {
                    window.open(response.data, '_blank').focus();
                });
            }				
        }, 
        closeModel(){
            if(this.is_inline_nav == true){
                this.$store.state.PaymentGatewayMigration.hidemodal =true;
                this.$store.state.MainInsurnceBilling.hidemodal =true;
            }
            $(".e-footer-content").find(".e-event-cancel").trigger("click");
        },
        icdsearchresult(obj) {
            const checkicd10 = value => value.code === obj.code;
            if(this.icd10table.some(checkicd10)){
                this.icd10duplication = "Selected ICD code already present!!";
					setTimeout(() => {
						this.icd10duplication = null;
					}, 8000);
            }else{
                this.icd10table.push({
                    id: obj.id,
                    code: obj.code,
                    description: obj.description,
                    table_id: "",
                });
            }
        },
        ndcsearchresult(obj) {
            this.ndctable[obj.code] = {
                id: obj.id,
                description: obj.name,
            };
        },
        cptsearchresult(obj) {
            this.cpttable.push({
                id: obj.id,
                code: obj.code,
                description: obj.description,
                cpt_modifier_a: "",
                cpt_modifier_b: "",
                cpt_modifier_c: "",
                cpt_modifier_d: "",
                cpt_icd10_pointer_a: "",
                cpt_icd10_pointer_b: "",
                cpt_icd10_pointer_c: "",
                cpt_icd10_pointer_d: "",
                cpt_qty: 1,
                cpt_price: "",
                cpt_ndc_code_id: "",
                cpt_ndc_code: "",
                cpt_ndc_description: "",
                cpt_ndc_qty: 1,
                cpt_ndc_units: "",
                cpt_table_id: "",
                cpt_ndc_table_id: "",
            });
        },
        async customproceduresearchresult(obj) {  
            var $this = this;          
            const checkcustomprocedure = value => value.code === obj.code;
            if($this.customproceduretable.some(checkcustomprocedure)){
                $this.customprocedureduplication = "Selected Custom Procedure code already present!!";
					setTimeout(() => {
						$this.customprocedureduplication = null;
					}, 8000);
            }else{
                /*get services */
                if($this.$store) {
                    $this.$store.state.loader = true;
                }
                if(!$this.cps_data[obj.id])  {
                    $this.cps_data[obj.id] = [];
                }
                await axios.get("appointment-billing/services_id/" + obj.id).then((res) => {
                    if(res.data.data) {
                        $this.cps_data[obj.id] = res.data.data;
                        $this.cps_data[obj.id].forEach(cp => {
                            cp['is_selected'] = 1;
                        });
                    } else {
                        $this.cps_data[obj.id] = [];
                    }
                    $this.customproceduretable.push({
                        id: obj.id,
                        code: obj.code,
                        description: obj.description,
                        custom_procedure_qty: 1,
                        custom_procedure_price: obj.price,
                        custom_procedure_table_id: ""
                    });
                    if($this.$store) {
                        $this.$store.state.loader = false;
                    }
                });
            }
        },
        addCptNdc(index) {
            this.cptndctable.push(index);
        },
        bindndcqty(item, value) {
            this.cpttable[item].cpt_ndc_qty = value;
        },
        bindndcunit(item, value) {
            this.cpttable[item].cpt_ndc_units = value;
        },
        submitForm() { 
            this.form.icddelete = this.icddelete;
            this.form.icdcodedelete = this.icdcodedelete;
            this.form.cptdelete = this.cptdelete;
            this.form.cptcodedelete = this.cptcodedelete;
            this.form.ndcdelete = this.ndcdelete;
            this.form.ndccodedelete = this.ndccodedelete;
            this.form.customproceduredelete = this.customproceduredelete;
            this.form.customprocedurecodedelete = this.customprocedurecodedelete;
            this.cpttable.forEach((entry) => {
                if (this.ndctable[entry.cpt_ndc_code]) {
                    entry.cpt_ndc_code_id = this.ndctable[entry.cpt_ndc_code].id;
                    entry.cpt_ndc_description = this.ndctable[entry.cpt_ndc_code].description;
                }
            });
            this.form.icd10_code_id = this.icd10table.map((icdary) => {
                return icdary.id;
            });
            this.form.icd10_table_id = this.icd10table.map((icdary) => {
                return icdary.table_id;
            });
            this.form.icd10_code = this.icd10table.map((icdary) => {
                return icdary.code;
            });
            this.form.icd10_description = this.icd10table.map((icdary) => {
                return icdary.description;
            });
            this.form.cpt_table_id = this.cpttable.map((cptary) => {
                return cptary.cpt_table_id;
            });
            this.form.cpt_code = this.cpttable.map((cptary) => {
                return cptary.code;
            });
            this.form.cpt_description = this.cpttable.map((cptary) => {
                return cptary.description;
            });
            this.form.cpt_code_id = this.cpttable.map((cptary) => {
                return cptary.id;
            });
            this.form.cpt_modifier_a = this.cpttable.map((cptary) => {
                return cptary.cpt_modifier_a;
            });
            this.form.cpt_modifier_b = this.cpttable.map((cptary) => {
                return cptary.cpt_modifier_b;
            });
            this.form.cpt_modifier_c = this.cpttable.map((cptary) => {
                return cptary.cpt_modifier_c;
            });
            this.form.cpt_modifier_d = this.cpttable.map((cptary) => {
                return cptary.cpt_modifier_d;
            });
            this.form.cpt_icd10_pointer_a = this.cpttable.map((cptary) => {
                return cptary.cpt_icd10_pointer_a;
            });
            this.form.cpt_icd10_pointer_b = this.cpttable.map((cptary) => {
                return cptary.cpt_icd10_pointer_b;
            });
            this.form.cpt_icd10_pointer_c = this.cpttable.map((cptary) => {
                return cptary.cpt_icd10_pointer_c;
            });
            this.form.cpt_icd10_pointer_d = this.cpttable.map((cptary) => {
                return cptary.cpt_icd10_pointer_d;
            });
            this.form.cpt_qty = this.cpttable.map((cptary) => {
                return cptary.cpt_qty;
            });
            this.form.cpt_price = this.cpttable.map((cptary) => {
                return cptary.cpt_price;
            });
            this.form.cpt_ndc_table_id = this.cpttable.map((cptary) => {
                return cptary.cpt_ndc_table_id;
            });
            this.form.cpt_ndc_code = this.cpttable.map((cptary) => {
                return cptary.cpt_ndc_code;
            });
            this.form.cpt_ndc_code_id = this.cpttable.map((cptary) => {
                return cptary.cpt_ndc_code_id;
            });
            this.form.cpt_ndc_description = this.cpttable.map((cptary) => {
                return cptary.cpt_ndc_description;
            });
            this.form.cpt_ndc_qty = this.cpttable.map((cptary) => {
                return cptary.cpt_ndc_qty;
            });
            this.form.cpt_ndc_units = this.cpttable.map((cptary) => {
                return cptary.cpt_ndc_units;
            });
            this.form.custom_procedure_table_id = this.customproceduretable.map(
                (customprocedureary) => {
                return customprocedureary.table_id;
                }
            );
            this.form.custom_procedure_code = this.customproceduretable.map(
                (customprocedureary) => {
                return customprocedureary.code;
                }
            );
            this.form.custom_procedure_code_id = this.customproceduretable.map(
                (customprocedureary) => {
                return customprocedureary.id;
                }
            );
            this.form.custom_procedure_description = this.customproceduretable.map(
                (customprocedureary) => {
                return customprocedureary.description;
                }
            );
            this.form.custom_procedure_qty = this.customproceduretable.map(
                (customprocedureary) => {
                return customprocedureary.custom_procedure_qty;
                }
            );
            this.form.custom_procedure_price = this.customproceduretable.map(
                (customprocedureary) => {
                return customprocedureary.custom_procedure_price;
                }
            );

            this.form.cps=[];
            this.form.cps=this.cps_data;
            // return false;
            
            var url = "appointment-billing/store-update";          
            // this.postForm(url, "appointmentbilling", false, "formData");
            axios.post(url, this.form)
                .then((response) => {
                    // $(".e-footer-content").find(".e-event-save").trigger("click");
                    this.moshaToast("Record updated successfully", "success"); 
                    if (this.is_inline_nav == true) {
                        this.$store.state.PaymentGatewayMigration.hidemodal =true;
                        this.$store.state.MainInsurnceBilling.hidemodal =true;
                    }
                    this.editCall();
                })
                .catch((error) => {
                    // this.moshaToast(error.response.data.message, "danger");
                });
        },
        // formPostSuccess(data) {
        //     this.moshaToast("Record updated successfully", "success");        
        // },
        icdremove(id,code){
            if(id != null){
                this.icddelete.push(id)
            }
            if(code!=null){
                this.icdcodedelete.push(code)
            }
        },
        cptremove(id,code){
            if(id != null){
                this.cptdelete.push(id)
            }
            if(code!=null){
                this.cptcodedelete.push(code)
            }
        },
        ndcremove(id,index=null,code=null){
            if(id != null){
                this.ndcdelete.push(id)
            }
            if(code!=null){
                this.ndccodedelete.push(code)
            }
            if(index!=null){
                this.cpttable[index].cpt_ndc_code = null;
                this.cpttable[index].cpt_ndc_code_id  = null;
                this.cpttable[index].cpt_ndc_description  = null;
                this.cpttable[index].cpt_ndc_qty  = null;
                this.cpttable[index].cpt_ndc_table_id  = null;
                this.cpttable[index].cpt_ndc_units  = null;
            }
        },
        customprocedureremove(cpsid, id,code){
            if(cpsid != null) {
                if(this.cps_data && this.cps_data[cpsid]) {
                    this.cps_data[cpsid] = null;
                }
            }
            if(id != null){
                this.customproceduredelete.push(id)
            }
            if(code != null){
                this.customprocedurecodedelete.push(code)
            }
        },
        changeTab (components) {
            this.$store.state.patientDetailsComp = components;
            let props = this.$router.resolve({ 
              name: components,
              params: { id: this.$route.params.id},
            });
            if (history.pushState && props) {
                if(location.href.substring(location.href.lastIndexOf('/') + 1) != 'healthcare') {
                    history.pushState(null, '',props.href+"/"+location.href.substring(location.href.lastIndexOf('/') + 1));
                } else {
                    history.pushState(null, '',props.href);
                }
            }
        },
        async editCall() {
            if(this.appointment_number){
                var appNumber = this.appointment_number, slice = appNumber.toString().slice(0,1);
                
                if(slice!='B')
                {
                    // this.$store.state.loader = true;
                    await axios.get("appointment-billing/edit/" + this.appointment_number).then((res) => {
                        this.icd10table = res.data.data.billing_icd10_record;
                        this.cpttable = res.data.data.billing_cpt_record;
                        this.customproceduretable = res.data.data.billing_custom_record;
                        this.appointment_profile_id = res.data.data.appointment_details.appointment_profile_id;
                        this.form.appointment_id = res.data.data.appointment_details.id;
                        this.form.patient_id = res.data.data.appointment_details.patient_id;
                        this.form.appointment_number = res.data.data.appointment_details.appointment_number;
                        /** */
                        this.cps_data=[];
                        var temp_cps_counter = [];
                        res.data.data.billing_custom_record_selected_services2.forEach((itm, idx) => {
                            var name=itm.code;
                            if(!this.cps_data[itm.custom_procedure_id]) {
                                this.cps_data[itm.custom_procedure_id] = [];
                                temp_cps_counter[itm.custom_procedure_id] = 0;
                            }
                            this.cps_data[itm.custom_procedure_id][temp_cps_counter[itm.custom_procedure_id]]={"product_name": itm.product_name, "is_selected": itm.is_selected, "service_id": itm.service_id};
                            temp_cps_counter[itm.custom_procedure_id]++;                          
                        });
                        
                        this.form.services_data_qty = [];
                        this.form.services_data_price = [];
                        this.form.services_data_title = [];
                        if(res.data.data.billing_custom_services.length>0) {
                            res.data.data.billing_custom_services.forEach((itm, indx) => {
                                this.form.services_data_qty[itm.id] = itm.custom_service_qty;
                                this.form.services_data_price[itm.id] = itm.custom_service_price;
                                this.form.services_data_title[itm.id] = itm.custom_service_title;
                            });
                        }
                      
                        this.update_form_services_onload();
                        /** */
                        this.cpttable.forEach((data, i) => {
                            if (data.cpt_ndc_code != null) {
                                this.cptndctable.push(i);
                            }
                        });
                        setTimeout(() => {
                            $(".cptMod").each(function() {
                                $(this).focus();
                                $(this).blur();
                            });
                        }, 500);
                        // this.$store.state.loader = false;
                    });                    
                }
            }
        },
        change_billing_status($event){
            var val = $event.target.value;
            
            var a = document.getElementById("billing_status");
            for (var i = 0; i < a.length; i++) {
                var option = a.options[i];
                if (option.text == 'Fully Billed - Payment Locked') {
                    var full_bill_opt = option.value;
                }
            }

            if(val === full_bill_opt && this.form.payment_profile == 'Insurance'){
                this.insurance_pending_billing_amount(this.form.appointment_id, this.form.patient_id);
            }else{
                $("#appointment_amount").html("");
            }
        },
        insurance_pending_billing_amount(appointment_id, patient_id){
            axios.post("/appointment/get-insurance-billable-amount-details", {'appointment_id': appointment_id, 'patient_id': patient_id})
            .then((response) => {
                $("#appointment_amount").html(response.data.data);
            });
        },
        updateservices(index, cp_id) {
            this.cps_data[cp_id][index]['is_selected'] = this.cps_data[cp_id][index]['is_selected']==1 ? 0 : 1;
        },
        update_total(itemc, indexp,type=null) {
            
            if(this.products[itemc][indexp]['product_qty']<0) {
                this.products[itemc][indexp]['product_qty'] = 0;
            }
            this.products[itemc][indexp]['product_total'] = this.products[itemc][indexp]['product_qty']*this.products[itemc][indexp]['product_price'];
            if(type!="donot"){
                 var sub_total=0;
                 var multiply=0;
            for (const key in this.products) {    
                if (this.products.hasOwnProperty.call(this.products, key)) {
                    const element = this.products[key];
                    element.forEach((element1,index) => {
                          multiply=element1.product_qty*element1.product_price;
                                sub_total= sub_total+multiply;          
                    });
                    
                }
            }
             this.total=sub_total;
                 
            }
          
            this.update_form_services();
        },
        changeCollapseProductArea() {
            this.collapseProductArea = this.collapseProductArea=="+" ? "-" : "+";
        },
        changeBtnText(id) {
            let btn = document.getElementById('collapseBtn'+id).innerText;
            if(btn=="+") {
                document.getElementById('collapseBtn'+id).innerText="-";
            } else {
                document.getElementById('collapseBtn'+id).innerText="+";
            }
        },
        update_form_services() {
            this.form.services_data_qty = [];
            this.form.services_data_price = [];
            this.form.services_data_title = [];
            this.product_categories.forEach((item, index) => {
                if(this.products[item].length>0) {
                    this.products[item].forEach((itm, indx) => {
                        if(this.products[item][indx]['product_qty']>0) {
                            let id = this.products[item][indx]['product_id'];
                            let qty = this.products[item][indx]['product_qty'];
                            let price = this.products[item][indx]['product_price'];
                            let title = this.products[item][indx]['product_name'];
                            this.form.services_data_qty[id] = qty;
                            this.form.services_data_price[id] = price;
                            this.form.services_data_title[id] = title
                        }
                    });
                }
            });
        },
        update_form_services_onload() {
            let data1=[];
            let count=0;
            let sub_total=0;
            this.product_categories.forEach((item, index) => {
                 
                if(this.products[item].length>0) {
                    this.products[item].forEach((itm, indx) => {
                        let id = this.products[item][indx]['product_id'];
                        if(this.form.services_data_qty[id]) {
                            this.products[item][indx]['product_qty'] = this.form.services_data_qty[id];
                            if(this.products[item][indx]['product_qty']==1){
                               count++;
                            }
                            if(this.form.services_data_price[id]) { 
                                this.products[item][indx]['product_price'] = this.form.services_data_price[id];
                            }
                            this.products[item][indx]['product_total'] = this.products[item][indx]['product_qty'] * this.products[item][indx]['product_price'];
                            sub_total+=this.products[item][indx]['product_total'];
                        }
                    });
                }
                if (this.products[item].length==count) {
                    this.cate_data[item]="true";
                }
                data1.push({"category":item,"length":this.products[item].length,"count":count});
                count=0;
               
            });
            
            if(sub_total>0){
                 this.total=sub_total;
            } 
        }
    },
    async beforeCreate() {
        this.permission_ids = localStorage.getItem('permission');
        this.role_id = localStorage.getItem('role_id');
        this.payment_profile=[
            { value: "Cash", title: "Cash" },
            { value: "Insurance", title: "Insurance" },
        ];
        this.billing_status=[];
        this.appointment_number="";
        this.icd10table=[];
        this.cpttable=[];
        this.cptndctable=[];
        this.customproceduretable=[];
        this.ndctable=[];
        this.icddelete=[];
        this.icdcodedelete=[];
        this.cptdelete=[];
        this.cptcodedelete=[];
        this.ndcdelete=[];
        this.ndccodedelete=[];
        this.customproceduredelete=[];
        this.customprocedurecodedelete=[];
        this.billing_details_link="";
        this.form={};
        this.defaultFormData={
            appointment_id: '',
            icd10_code_id: [],
            icd10_table_id: [],
            icd10_code: [],
            icd10_description: [],
            cpt_table_id: [],
            cpt_code: [],
            cpt_code_id: [],
            cpt_modifier_a: [],
            cpt_modifier_b: [],
            cpt_modifier_c: [],
            cpt_modifier_d: [],
            cpt_icd10_pointer_a: [],
            cpt_icd10_pointer_b: [],
            cpt_icd10_pointer_c: [],
            cpt_icd10_pointer_d: [],
            cpt_qty: [],
            cpt_price: [],
            cpt_ndc_table_id: [],
            cpt_ndc_code: [],
            cpt_ndc_qty: [],
            cpt_ndc_units: [],
            custom_procedure_table_id: [],
            custom_procedure_code_id: [],
            custom_procedure_code: [],
            custom_procedure_description: [],
            custom_procedure_qty: [],
            custom_procedure_price: [],
            icddelete: [], 
            icdcodedelete: [], 
            cptdelete: [], 
            cptcodedelete: [], 
            ndcdelete: [], 
            ndccodedelete: [], 
            customproceduredelete: [],
            customprocedurecodedelete: [],
            payment_profile: null,
            billing_status: null,
        };
        this.icd10duplication=null;
        this.customprocedureduplication=null;
        Object.assign(
            this.form,
            this.defaultFormData
        );
        axios.get("/json_dropdown/get-billing-status")
        .then((response) => {
            this.billing_status = response.data.data.map((item) => {
                return { value: item.id, title: item.name };
            });       
        });

        var product_qty = 0;
        axios.get("/IV_life_style/service/all_by_category")
        .then((response) => {
            var cat_index = [];
            var total_records = 0;
            var cat_counter = 0;
            response.data.data.forEach( (res, indx) => {
                if(!this.products[res.category_name]) {
                    this.products[res.category_name] = [];
                    cat_index[res.category_name] = 0;
                    this.product_categories[cat_counter] = res.category_name;
                    cat_counter++;
                }
                product_qty = 0;
                this.products[res.category_name][cat_index[res.category_name]] = [];
                this.products[res.category_name][cat_index[res.category_name]]['product_id'] = res.id;
                this.products[res.category_name][cat_index[res.category_name]]['product_code'] = res.product_code;
                this.products[res.category_name][cat_index[res.category_name]]['product_name'] = res.product_name;
                this.products[res.category_name][cat_index[res.category_name]]['product_price'] = res.product_price;
                this.products[res.category_name][cat_index[res.category_name]]['product_qty'] = product_qty;
                this.products[res.category_name][cat_index[res.category_name]]['product_total'] = 0;
                cat_index[res.category_name]++;
                total_records++;
            });
            this.products['length'] = total_records;
            this.update_form_services();
        });
    },
    mounted() {
        this.editCall();
    },
    computed: {},    
}
</script>