<template>
  <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-2">
    <div class="sticky-scroll-box">
      <div class="accorsidebar">
        <div class="accordion-item">
          <p class="accordion-header display-vertical" id="patientscheduling">
              <span class="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapsedemogr" aria-expanded="false" aria-controls="collapsedemogr">Schedule Appointment</span>
          </p>
          <div
            id="collapsedemogr"
            class="accordion-collapse collapse"
            aria-labelledby="patientscheduling"
            data-bs-parent="#accordionExample"
          >
           <div class="side-navigation">
            <ul class="side-bar-menu side-bar-tree patientTabs" data-widget="tree">
              <li>
                <a href="#" ref="ScheduleAppointmentLink" @click.prevent="changeComp('ScheduleAppointment')" class="active">Schedule Appointment</a
                >
              </li>
              <li :class="{ 'linkDisabled': tabActive, }">
                <a href="#" ref="AppointmentVitalsLink" @click.prevent="changeComp('AppointmentVitals')">Vitals</a>
              </li>
              <li :class="{ 'linkDisabled': tabActive, }">
                <a href="#" ref="AppointmentBillingLink" @click.prevent="changeComp('AppointmentBilling')">Billing</a>
              </li>
              <li :class="{ 'linkDisabled': tabActive, }">
                <a href="#" ref="AppointmentInsuranceLogLink" @click.prevent="changeComp('AppointmentInsuranceLog')">Insurance Billing Log</a>
              </li>
              <li :class="{ 'linkDisabled': tabActive, }">
                <a href="#" ref="AppointmentLogCommunicationLink" @click.prevent="changeComp('AppointmentLogCommunication')">Log Communication</a>
              </li>
              <li :class="{ 'linkDisabled': tabActive, }" v-if="permissioncheck(this.$store.state.permission.Eligibility_Check_Access)">             
                <a href="#" ref="AppointmentEligibilityLink" @click.prevent="changeComp('AppointmentEligibility')">Eligibility </a>
              </li>
              <li :class="{ 'linkDisabled': tabActive, }">             
                <a href="#" ref="AuditlogsLink" @click.prevent="changeComp('Auditlogs')">Appointment Logs</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>
<script>
import permissionMixins from "@/scripts/mixins/permission.js";
export default {
  mixins: [permissionMixins],
  data() {
    return {
      tabActive: true,
    };
  },
  computed: {
    
  },

  watch: {
    "$route.name": function(newValue) {
      this.linkActive();
    },
  },

  mounted() {
    this.linkActive();
  },

  methods: {
    changeComp(name) {
      if(this.$route.params.appointments_id == null){
        this.tabActive = true;
        this.$router.push(
        `/patient-chart/${this.$route.params.id}/schedule-appointment`
        );
      }
      else{
        this.tabActive = false;
        if (name === "ScheduleAppointment") {
            this.$router.push(
            `/patient-chart/${this.$route.params.id}/edit-appointment/${this.$route.params.appointments_id}`
            );
        } else if(name === "AppointmentVitals"){
            this.$router.push(
            `/patient-chart/${this.$route.params.id}/edit-appointment/${this.$route.params.appointments_id}/vitals`
            );
        } else if (name === "AppointmentBilling") {
            this.$router.push(
            `/patient-chart/${this.$route.params.id}/edit-appointment/${this.$route.params.appointments_id}/billing`
            );
        } else if (name === "AppointmentInsuranceLog") {
            this.$router.push(
            `/patient-chart/${this.$route.params.id}/edit-appointment/${this.$route.params.appointments_id}/insurance-log`
            );
        }
        else if (name === "AppointmentLogCommunication") {
            this.$router.push(
            `/patient-chart/${this.$route.params.id}/edit-appointment/${this.$route.params.appointments_id}/log-communication`
            );
        }
        else if (name === "AppointmentEligibility") {
            this.$router.push(
            `/patient-chart/${this.$route.params.id}/edit-appointment/${this.$route.params.appointments_id}/check-eligibility`
            );
        }
        else if (name === "Auditlogs") {
            this.$router.push(
            `/patient-chart/${this.$route.params.id}/edit-appointment/${this.$route.params.appointments_id}/Auditlogs`
            );
        }
      }        
    },

    linkActive() {
      if (this.$route.name === "ScheduleAppointment") {
        this.$refs.ScheduleAppointmentLink.classList.add("active");
        this.$refs.AppointmentVitalsLink.classList.remove("active");
        this.$refs.AppointmentBillingLink.classList.remove("active");
        this.$refs.AppointmentLogCommunicationLink.classList.remove("active");
        this.$refs.AppointmentInsuranceLogLink.classList.remove("active");
        if(this.$refs.AppointmentEligibilityLink!=undefined){
          this.$refs.AppointmentEligibilityLink.classList.remove("active");
        }
      } else if (this.$route.name === "AppointmentVitals") {
        this.$refs.AppointmentVitalsLink.classList.add("active");
        this.$refs.ScheduleAppointmentLink.classList.remove("active");
        this.$refs.AppointmentBillingLink.classList.remove("active");
        this.$refs.AppointmentLogCommunicationLink.classList.remove("active");
        this.$refs.AppointmentInsuranceLogLink.classList.remove("active");
        if(this.$refs.AppointmentEligibilityLink!=undefined){
          this.$refs.AppointmentEligibilityLink.classList.remove("active");
        }
      }else if (this.$route.name === "AppointmentBilling") {
        this.$refs.AppointmentBillingLink.classList.add("active");
        this.$refs.ScheduleAppointmentLink.classList.remove("active");
        this.$refs.AppointmentVitalsLink.classList.remove("active");
        this.$refs.AppointmentLogCommunicationLink.classList.remove("active");
        this.$refs.AppointmentInsuranceLogLink.classList.remove("active");
        if(this.$refs.AppointmentEligibilityLink!=undefined){
          this.$refs.AppointmentEligibilityLink.classList.remove("active");
        }
      }else if (this.$route.name === "AppointmentLogCommunication") {
        this.$refs.AppointmentLogCommunicationLink.classList.add("active");
        this.$refs.AppointmentBillingLink.classList.remove("active");
        this.$refs.ScheduleAppointmentLink.classList.remove("active");
        this.$refs.AppointmentVitalsLink.classList.remove("active");
        this.$refs.AppointmentInsuranceLogLink.classList.remove("active");
        if(this.$refs.AppointmentEligibilityLink!=undefined){
          this.$refs.AppointmentEligibilityLink.classList.remove("active");
        }
      }else if (this.$route.name === "AppointmentInsuranceLog") {
        this.$refs.AppointmentInsuranceLogLink.classList.add("active");
        this.$refs.AppointmentBillingLink.classList.remove("active");
        this.$refs.ScheduleAppointmentLink.classList.remove("active");
        this.$refs.AppointmentVitalsLink.classList.remove("active");
        if(this.$refs.AppointmentEligibilityLink!=undefined){
          this.$refs.AppointmentEligibilityLink.classList.remove("active");
        }
      }else if (this.$route.name === "AppointmentEligibility") {
        this.$refs.AppointmentEligibilityLink.classList.add("active");
        this.$refs.AppointmentLogCommunicationLink.classList.remove("active");
        this.$refs.AppointmentBillingLink.classList.remove("active");
        this.$refs.ScheduleAppointmentLink.classList.remove("active");
        this.$refs.AppointmentVitalsLink.classList.remove("active");
        this.$refs.AppointmentInsuranceLogLink.classList.remove("active");
      }
      else if (this.$route.name === "Auditlogs") {
        this.$refs.AuditlogsLink.classList.add("active");
        this.$refs.AppointmentLogCommunicationLink.classList.remove("active");
        this.$refs.AppointmentBillingLink.classList.remove("active");
        this.$refs.ScheduleAppointmentLink.classList.remove("active");
        this.$refs.AppointmentVitalsLink.classList.remove("active");
        this.$refs.AppointmentInsuranceLogLink.classList.remove("active");
         if(this.$refs.AppointmentEligibilityLink!=undefined){
          this.$refs.AppointmentEligibilityLink.classList.remove("active");
        }
      }
      if(this.$route.params.appointments_id == null){
        this.tabActive = true;
      }else{
        this.tabActive = false;
      }
    },
  },
};
</script>