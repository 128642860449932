<template>
	<div class="patiboxdetail">
		<div class="box patientsdetails">
			<div class="row">
				<div class="col-12 col-sm-8 col-md-8 col-lg-7 col-xl-7">
					<h2 class="patientname float-start me-5">
						{{ capitalize(patientChart.first_name) }}
						{{ capitalize(patientChart.middle_name) }}
						{{ capitalize(patientChart.last_name) }}
					</h2>
					<Popper v-if="patientChart.paymentauthorized == 1" arrow content="Credit Card Authorized" :hover="true" class="helptips cursor-pointer ms-2 mt-1">
						<img  @click="sendpos()" src="images/patient_icons/pay-card-green.svg" alt="Credit Card Authorized" class="img-fluid" style="width: 25px;"/>
					</Popper>

					<Popper v-if="patientChart.paymentauthorized == 0" arrow content="Credit Card Not Authorized - Send authorization email." :hover="true" class="helptips cursor-pointer ms-2 mt-1">
						<img  @click="sendAuthorizedEmail(patientChart.id)" src="images/patient_icons/pay-card-red.svg" alt="Credit Card Authorized" class="img-fluid" style="width: 25px;cursor: pointer;" />
					</Popper>

					<Popper v-if="patientChart.insurance_detail_available == 1" arrow content="Insurance Document available" :hover="true" class="helptips cursor-pointer ms-2 mt-1">
						<img @click.prevent="changeTab('PatientInsurance')" src="images/patient_icons/insurance-card-green.svg" alt="Insurance Document available" class="img-fluid" style="width: 25px;" />
					</Popper>

					<Popper v-if="patientChart.insurance_detail_available == 0" arrow content="Insurance Document not available" :hover="true" class="helptips cursor-pointer ms-2 mt-1">
						<img @click.prevent="changeTab('PatientInsurance')" src="images/patient_icons/insurance-card-red.svg" alt="Insurance Document not available" class="img-fluid" style="width: 25px;" />
					</Popper>

					<Popper v-if="patientChart.id_proof_available == 1" arrow content="ID proof available" :hover="true" class="helptips cursor-pointer ms-2 mt-1">
						<img @click.prevent="changeTab('PatientIdentification')" src="images/patient_icons/driving-licence-green.svg" alt="ID proof available" class="img-fluid" style="width: 25px;"  />
					</Popper>

					<Popper v-if="patientChart.id_proof_available == 0" arrow content="ID proof not available" :hover="true" class="helptips cursor-pointer ms-2 mt-1">
						<img @click.prevent="changeTab('PatientIdentification')" src="images/patient_icons/driving-licence-red.svg" alt="ID proof not available" class="img-fluid" style="width: 25px;" />
					</Popper>

					<span class="badge font-bold" v-bind:class="data_missing_alert_class">
						<Popper arrow :hover="true" class="helptips cursor-pointer ms-2 mt-1">
							<span v-html="data_missing_alert_msg"></span>
							<template #content>
								<div>{{ data_missing_alert }}</div>
							</template>
						</Popper>
					</span>
				</div>
				<div class="col-12 col-sm-4 col-md-4 col-lg-5 col-xl-5">
					<div class="marginprovi providname">
						<p>
							<b>Primary Provider:</b>
							<span>{{ capitalize(patientChart.provider_name) }}</span>
						</p>
					</div>
				</div>
			</div>
			<div class="row mb-10">
				<div class="col-12 col-sm-3 col-md-4 col-lg-6 col-xl-3">
					<div class="row">
						<div class="col-4 col-sm-12 col-md-12 col-lg-5 col-xl-4">
							<div class="patientimg">
								<Popper arrow content="Upload Avatar" :hover="true" class="helptips cursor-pointer">
									<div class="effect-grid">
										<p class="mb-0" v-html="this.$store.state.patientDetail.patientAvatar" v-if="this.$store.state.patientDetail.patientAvatar!=''"></p>
										<p class="mb-0" v-else>
											<img src="images/patient-avtar.png" alt="Patient Photo" class="img-fluid"/>
										</p>
										<div class="middlecont">
											<div class="edit-btn"><a href="#" data-bs-toggle="modal" data-bs-target="#profileupdate" title="Upload Avatar"><i class="fa fa-pencil"></i></a></div>
										</div>
									</div>
								</Popper>

								<!--model start-->
								<div id="profileupdate" class=" profileupdate modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
									<div class="modal-dialog modal-md">
										<div class="modal-content">
											<div class="modal-header">
												<h3 class="modal-title fw-900 mt-0">Update Profile Avatar</h3>
											</div>
											<div class="modal-body">
												<div class="form-group row" id="profile_avatar">
													<label class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-5 col-form-label mb-0 text-sm-end">Patient Chart Photo <small class="text-danger asterisksign" style="display: none;">*</small></label>
													<div class="col-12 col-sm-6 col-md-4 col-lg-6 col-xl-6">
														<div class="controls">
															<div class="web-camera-container">
																<div class="web-camera-container">
																	<div class="camera-button">
																	<button type="button" class="btn btn-secondary is-rounded" :class="{ 'is-primary' : !isCameraOpen, 'is-danger' : isCameraOpen}" @click="toggleCamera">
																		<span v-if="!isCameraOpen">Take Photo</span>
																		<span v-else>Close Camera</span>
																		</button>
																	</div>
																		
																	<div v-show="isCameraOpen && isLoading" class="camera-loading">
																		<ul class="loader-circle">
																			<li></li>
																			<li></li>
																			<li></li>
																		</ul>
																	</div>
																		
																	<div v-if="isCameraOpen" v-show="!isLoading" class="camera-box" :class="{ 'flash' : isShotPhoto }">
																		<div class="camera-shutter" :class="{'flash' : isShotPhoto}"></div>
																		<video v-show="!isPhotoTaken" ref="camera" id="video_player" style="width:100%;"  autoplay></video>                       
																		<canvas v-show="isPhotoTaken" id="photoTaken" ref="canvas"></canvas>
																	</div>
																		
																	<div v-if="isCameraOpen && !isLoading" class="camera-shoot">
																		<button type="button" class="button" @click="takePhoto">
																		<img src="https://img.icons8.com/material-outlined/50/000000/camera--v2.png">
																		</button>
																	</div>
																	<div v-if="isCameraOpen && !isLoading">
																		<button type="button" class="btn" @click="camera('environment')">Back Camera</button>
																		<button type="button" class="btn" @click="camera('user')">Front Camera</button>
																	</div>
																		
																	<div v-if="isPhotoTaken && isCameraOpen" class="camera-download">
																		<a id="downloadPhoto" download="my-photo.jpg" class="button" role="button" @click="downloadImage">Download</a>
																	</div>
																</div>
															</div>
																OR
															<input type="file" id="file" ref="file" v-on:change="onChangeFileUpload()"/>
															<img :src="this.profile_avatar" class="img-fluid rounded5 my-10" style="width:auto;">
															<small class="text-danger">{{profile_avatarerror}}</small>
														</div>
													</div>
												</div>
												
											</div>
											<div class="modal-footer">
												<button type="button" @click="changeprofile"  class="waves-effect waves-light btn btn-info">Save</button>
												<button type="button" @click="closeModal" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
											</div>
										</div>
									</div>
								</div>
								<!--model end-->

								<!--duplicate model start-->
								<div id="duplicate" class="duplicate modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
									<div class="modal-dialog modal-md">
										<div class="modal-content">
											<div class="modal-header">
												<h3 class="modal-title fw-900 mt-0">Duplicate Patient Found:</h3>
											</div>
											<div class="modal-body">
												<p class="fs-14 text-start l-h-n">Duplicate patients cannot be merged or deleted.To set a patient as inactive, go to Demographics > important tab and set Status to Inactive.</p>
												<table class="table">
													<thead>
														<tr>
															<th>Patient Name</th>
															<th>Patient Chart Id</th>
														</tr>
													</thead>
													<tbody>
														<tr v-for="(item,index) in duplicate_list" :key="index">
															<td>{{item.patient_name}}</td>
															<td>{{item.unique_id}}</td>
														</tr>
													</tbody>
												</table>
											</div>
											<div class="modal-footer">
												<button type="button" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
											</div>
										</div>
									</div>
								</div>
								<!--duplicate model end-->								
							</div>
						</div>
						<div class="col-8 col-sm-12 col-md-12 col-lg-7 col-xl-8">
							<div class="patientditailist mb-10">
								<p>
									<b>Gender:</b>
									<span>{{ patientChart.sex ? patientChart.sex : " Not Available" }}</span>
								</p>
								<p>
									<b>Age:</b>
									<span>{{
										patientChart.age ? patientChart.age : " Not Available"
									}}</span>
								</p>
								<p>
									<b>DOB:</b>
									<span>{{ patientChart.DOB ? patientChart.DOB : " Not Available" }}</span>
								</p>
							</div>
						</div>
					</div>
				</div>
				<div class="col-12 col-sm-8 col-md-8 col-lg-6 col-xl-9">
					<div class="patiDetails">
						<div class="row">
							<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
								<div class="patientditailist">
									<p>
										<b>Phone:</b> 
										<slot v-if="patientChart.crm_prospect_id!=null && permissioncheck(this.$store.state.permission.PureCRMContacts)">
											<Popper arrow content="Message patient using CRM contact patient" :hover="true" class="helptips cursor-pointer ms-2 mt-1">
												<router-link :to="{ name: 'CRMProspectPatientDetails',params: { 'id' : patientChart.unique_id, 'section' : 'SMS' }}" class="position-relative fw-500" target="_blank"> {{ patientChart.cell_phone ? patientChart.cell_phone : " Not Available" }} </router-link>
											</Popper>
										</slot>
										<slot v-else>
											<span>{{ patientChart.cell_phone ? patientChart.cell_phone : " Not Available" }}</span>
										</slot>
									</p>
									<p>
										<b>Email: </b>
										<slot v-if="patientChart.crm_prospect_id!=null && permissioncheck(this.$store.state.permission.PureCRMContacts)">
											<Popper arrow content="Message patient using CRM contact patient" :hover="true" class="helptips cursor-pointer ms-2 mt-1">
												<router-link :to="{ name: 'CRMProspectPatientDetails',params: { 'id' : patientChart.unique_id, 'section' : 'Email' }}" class="position-relative fw-500" target="_blank"> {{ patientChart.email ? patientChart.email : " Not Available"}} </router-link>
											</Popper>
										</slot>
										<slot v-else>
											<span>{{ patientChart.email ? patientChart.email : " Not Available"}}</span>
										</slot>
									</p>
									<p>
										<b>Address:</b>
										<span>{{
											patientChart.address ? patientChart.address+(patientChart.city ? ", "+patientChart.city : '')+(patientChart.state_sortname ? ", "+patientChart.state_sortname : patientChart.state_name)+(patientChart.zip_code ? " "+patientChart.zip_code : '') : " Not Available"
										}}</span>
									</p>
								</div>
							</div>
							<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
								<div class="patientditailist">
									<p>
										<b>Primary Office:</b>
										<span>{{
											patientChart.office_name ? patientChart.office_name : " Not Available"
										}}</span>
									</p>
									<p>
										<b>Date Added:</b>
										<span>{{
											patientChart.date_added ? patientChart.date_added : " Not Available"
										}}</span>
									</p>
									<p>
										<b>Previous Appointment:</b>
										<span>{{
											patientChart.past_appt
											? patientChart.past_appt.dis_schedule_date
											: " Not Available"
										}} <b>({{ patientChart.past_appt
											? patientChart.past_appt.status_label
											: "-" }})</b></span>
									</p>
									<p>
										<b>Upcoming Appointment:</b>
										<span>{{
											patientChart.next_appt
											? patientChart.next_appt.dis_schedule_date
											: " Not Available"
										}} <b>({{ patientChart.next_appt
											? patientChart.next_appt.status_label
											: "-" }})</b></span>
									</p>
									<p>
										<b>Wellness Coordinator:</b>
										<span>{{
											patientChart.wellness_coordinator_name
											? patientChart.wellness_coordinator_name
											: " Not Available"
										}}</span>
									</p>
								</div>
							</div>
						</div>
					</div>
					<a href="javascript:void(0)" id="elem-hide" class="read_mobbtn text-info mb-2 text-decoration-underline" @click="elemHide">Read more</a>
				</div>
			</div>
			<div class="row">
				<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-5 col-xxl-6">
					<div class="d-flex ">
						<p class="patientcds pull-left mb-0 me-1 d-flex justify-content-around flex-column">
							<b class="fw-500">CDS:</b>
						</p>
						<div>
							<slot v-if="this.cds_alert_for_patient.length  > 0">
								<span class="cdsdocument" v-for="(item,index) in this.cds_alert_for_patient" :key="index">
									<router-link class="cdslink"
									:to="{
										name: 'CDS',
										params: { id: this.$route.params.id },
									}">{{ item }}</router-link>
								</span>
							</slot>
							<slot v-else>
								<span>
									Not Available
								</span>
							</slot>							
						</div>
					</div>
				</div>
				<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-7 col-xxl-6 text-sm-end">					
					<Popper v-if="patientChart.crm_notes != null" arrow :content="patientChart.crm_notes" :hover="true" class="helptips contract_tips cursor-pointer mt-1">
					<a href="#" class="helptips contract_tips cursor-pointer mt-1" title="CRM Notes">
						<img src="images/patient_icons/crm-icon.svg" alt="CRM Notes" class="img-fluid mt-1" style="width: 35px;" />
					</a>
					</Popper>

					<Popper arrow content="Wellness Follow-up Notes " :hover=true class="helptips contract_tips cursor-pointer mt-1">
						<img @click.prevent="toggleSidebar(0)" src="images/notes.svg" alt="" class="mt-1"> 
					</Popper>
					
					<Popper v-if="patientChart.contract == 1" arrow :hover="true" class="helptips contract_tips cursor-pointer mt-1">
						<img @click.prevent="changeTab('BillingContract')" src="images/patient_icons/contract.svg" alt="Contract" class="img-fluid mt-1" style="width: 35px;" />
						<template #content >
							<table>
								<tr>
									<th style="border: 1px solid #8d98ad;border-collapse: collapse; padding: 5px;">Plan Name</th>
									<th style="border: 1px solid #8d98ad;border-collapse: collapse; padding: 5px;">Contract End</th>
								</tr>
								<tr v-for="(item,index) in patientChart.contract_msg" :key="index">
									<td style="border: 1px solid #8d98ad;border-collapse: collapse; padding: 5px;">
										{{ item.plan_name }} 
									</td>
									<td style="border: 1px solid #8d98ad;border-collapse: collapse; padding: 5px;">
										{{ $filters.displayDateTimeLocal(item.contract_end,'',false,"ddd, MM-DD-YYYY",true) }} 
									</td>
								</tr>
							</table>
						</template>
					</Popper>

					<router-link
					:to="{ name: 'ClinicalNotesList' }"
					class="waves-effect waves-light btn btn-info mt-5 me-1 ms-2"
					><i class="fa fa-sticky-note fs-14"></i> Clinical Notes</router-link>
					
					<router-link
					:to="{
						name: 'PatientVitals',
						params: { id: this.$route.params.id },
					}"
					class="waves-effect waves-light btn btn-info-light mt-5 me-1"
					><i class="fa fa-heartbeat fs-14"></i> Vitals</router-link>

					<router-link v-if="permissioncheck(this.$store.state.permission.create_update) && this.patientChart.status != 'Inactive' "
					:to="{ name: 'PatientAppointment' }" class="waves-effect waves-light btn btn-info-light mt-5"><i class="fa fa-plus fs-14"></i> Appointment</router-link>
				</div>
			</div>
		</div>
	</div>
	<PatientAdminNote v-if="admin_patient_id > 0" :patientId="admin_patient_id" :key="admin_patient_comp_key"></PatientAdminNote>
</template>
<script>
	import axios from "@/scripts/axios.js";
	import permissionMixins from "@/scripts/mixins/permission.js";
	import submitMixins from "@/scripts/mixins/submitMixins.js";
	import Popper from "vue3-popper";
	import FileUpload from "@/components/base/formFields/fileUpload.vue";
	import PatientAdminNote from "@/components/patients/details/PatientInformation/PatientAdminNote.vue";
	import moment from "moment";
	import WebCam from "@/views/CovidExams/Camera.vue";

	export default {
		components: {Popper,FileUpload,PatientAdminNote,WebCam},
		data() {
			return {
				duplicate_list:{},
				patientChart: {},
				cds_alert_for_patient: [],
				profile_avatar:'',
				profile_avatarerror:'',
				return_url : window.location.href,
				notification_system_name:'',
				notification_practice_user_name:'',
				notification_action_url:'',
				notification_provider_external_id:'',
				data_missing_alert: '',
				data_missing_alert_class: '',
				data_missing_alert_msg: '',
				admin_patient_id: '',
				admin_patient_comp_key: 0,
				is_production_env: (process.env.VUE_APP_NODE_ENV == 'production') ? true : false,
				isCameraOpen: false,
    		    isPhotoTaken: false,
    		    isShotPhoto: false,
    		    isLoading: false,
    		    link: '#'
			};
		},
		mixins: [permissionMixins,submitMixins],
		created() {},
		mounted() {
			$(".patiDetails").addClass("pati_detaile");
			$(".read_mobbtn").click(function() {
				if($(".patiDetails").hasClass("d-block")){
					$(".patiDetails").removeClass("d-block");
				}else{
					$(".patiDetails").addClass("d-block");
				}
			});
		},
		async beforeCreate() {
			var data=this.$route.path;
			var dataarr=data.split("/")
			var current_date = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
			var audit_log="";
			if(dataarr.length==3){
				audit_log=1;
			}else{
				audit_log=2;
			}
			var $this = this;
			axios
			.get("/patient/" + this.$route.params.id,{
					params: { audit_log: audit_log, current_date: current_date },
		  	})
			.then((response) => {
				this.patientChart = response.data.data;
				this.admin_patient_id  = response.data.data.id;
				this.$store.state.patientDetail.patient_id = response.data.data.id;
				this.$store.state.patientDetail.patient_unique_id =
				response.data.data.unique_id;
				this.$store.state.patientDetail.patient_rcopia_id =
				response.data.data.rcopia_patient_id;
				this.$store.state.patientDetail.patient_demographics_id =
				response.data.data.patient_demographics_id;
				this.$store.state.patientDetail.last_name = response.data.data.last_name;
				this.$store.state.patientDetail.first_name = response.data.data.first_name;
				this.$store.state.patientDetail.age = response.data.data.age;
				this.$store.state.patientDetail.pp_id = response.data.data.pp_id;
				this.$store.state.patientDetail.pp_email_count = response.data.data.pp_email_count;
				this.$store.state.patientDetail.pp_status = response.data.data.pp_status;
				this.$store.state.patientDetail.patient_portal_token = response.data.data.patient_portal_token;
				this.$store.state.patientDetail.email = response.data.data.email;
				this.$store.state.patientDetail.wallet_amount = response.data.data.wallet_amount;
				this.$store.state.patientDetail.due_amount_sum = response.data.data.order_summary ? response.data.data.order_summary.due_amount_sum : 0;
				this.$store.state.patientDetail.primary_provider_id = response.data.data.primary_provider_id;
				this.$store.state.patientDetail.date_of_birth = response.data.data.DOB;
				this.$store.state.patientDetail.cell_phone = response.data.data.cell_phone;
				this.$store.state.patientDetail.have_imported_data = response.data.data.have_imported_data;
				this.$store.state.patientDetail.gender = response.data.data.sex;

				this.duplicate();
				/*RX Data Pending*/
				if(response.data.data.wellness_coordinator!=null)
				{
					this.patientChart.wellness_coordinator_name=response.data.data.pipeline_name+"-"+response.data.data.wellness_coordinator_name;
				}				
				if(response.data.data.DOB=='' || response.data.data.DOB==null)
				{
					this.data_missing_alert += 'DOB, ';
				}

				if(response.data.data.first_name=='' || response.data.data.first_name==null)
				{
					this.data_missing_alert += 'firstname, ';
				}

				if(response.data.data.last_name=='' || response.data.data.last_name==null)
				{
					this.data_missing_alert += 'lastname, ';
				}

				if(response.data.data.zip_code=='' || response.data.data.zip_code==null)
				{
					this.data_missing_alert += 'zip code, ';
				}

				if(response.data.data.sex=='' || response.data.data.sex==null)
				{
					this.data_missing_alert += 'gender, ';
				}

				if(response.data.data.address=='' || response.data.data.address==null)
				{
					this.data_missing_alert += 'address, ';
				}

				if(response.data.data.rcopia_patient_id == null)
				{
					var str1 = this.data_missing_alert;
					str1 = str1.replace(/,\s*$/, "");
					this.data_missing_alert = "The patient's " + str1+' is missing!';
					this.data_missing_alert_class = 'badge-danger-light ms-2';
					this.data_missing_alert_msg = '<i class="fa fa-exclamation-circle"></i> Not yet added in Rcopia!';
				}
				else
				{
					if(this.data_missing_alert != ''){
						var str1 = this.data_missing_alert;
						str1 = str1.replace(/,\s*$/, "");
						this.data_missing_alert = "The patient's " + str1+' is missing!';	
						this.data_missing_alert_class = 'badge-warning-light ms-2';
						this.data_missing_alert_msg = '<i class="fa fa-exclamation-triangle"></i> Rcopia required details are missing!';
					}
				}
				/*RX Data Pending - End*/

				this.getPatientAvatar(response.data.data.profile_avatar);
				this.getCdsAlertForPatient(response.data.data.id);
				
				let show_recent_patient = localStorage.getItem('show_recently_viewed_patient');
				var myRecentPatient = localStorage.getItem('myRecentPatient');
				if (show_recent_patient && show_recent_patient == 'yes') {
					if (myRecentPatient) {
						let a = myRecentPatient.split(",");
						a.push(response.data.data.unique_id.toString());
						var myRectPatientArr = a.filter((item, i, ar) => ar.indexOf(item) === (i));
						var myRectPatientArr = myRectPatientArr.slice(-10);
					} else {
						var myRectPatientArr = [parseInt(response.data.data.unique_id)];
					}
					if (myRectPatientArr) {
						localStorage.setItem('myRecentPatient',myRectPatientArr.join());
					}
					this.$store.dispatch("auth/getRecentPatient");
				}
			}).catch(function (error) {
				console.log(error)
				$this.$store.state.patientDetail.key = 1;
			});
		},
		methods: {
			toggleCamera() {
                if(this.isCameraOpen) {
                    this.isCameraOpen = false;
                    this.isPhotoTaken = false;
                    this.isShotPhoto = false;
                    this.stopCameraStream();
                } 
                else {
                    this.isCameraOpen = true;
                    setTimeout(() => {
                        this.createCameraElement();
                    }, 1000);
                }
            },
			camera(face) {
                /*this.stop();*/
                
                this.gum(face);
            },
			gum(face) {
                if(face === 'user') {
                    return navigator.mediaDevices.getUserMedia({video: {
                        facingMode: face,
                        width: { min: 1024, ideal: 1280, max: 1920 },
                        height: { min: 576, ideal: 720, max: 1080 }, 

                    }} )
                    .then(stream => {
                        document.getElementById('video_player').srcObject = stream;
                    }).catch(error => {
                         alert("Pictures can not uploaded using camera.");
                    });
                }
                if(face === 'environment') {
                    
                    return navigator.mediaDevices.getUserMedia({video: {
                        facingMode: {exact: face},
                        width: { min: 1024, ideal: 1280, max: 1920 },
                        height: { min: 576, ideal: 720, max: 1080 }, 
                    }})
                    .then(stream => {
                        document.getElementById('video_player').srcObject = stream;
                    }).catch(error => {
						console.log(error);
                         alert("Pictures can not uploaded using camera.");
                    });
                }
            },
			createCameraElement() {
				this.camera('environment');
				 var isiPhone = /iphone/i.test(navigator.userAgent.toLowerCase());
                if (isiPhone){
                    var myVideo = document.getElementsByTagName('video')[0];
                    myVideo.addEventListener('webkitendfullscreen', () => {
                        alert("The image you clicked isn't saved at this point, you need to tap on the camera symbol underneath to continue.");
                    },false);
                }      
            },
			stopCameraStream() {
                let tracks = this.$refs.camera.srcObject.getTracks();
                tracks.forEach(track => {
                    track.stop();
                });
            },
			takePhoto() {
                if(!this.isPhotoTaken) {
                    this.isShotPhoto = true;
                    const FLASH_TIMEOUT = 50;
                    setTimeout(() => {
                        this.isShotPhoto = false;
                    }, FLASH_TIMEOUT);
                }
                this.isPhotoTaken = !this.isPhotoTaken;
                const context = this.$refs.canvas.getContext('2d');
				context.canvas.width = this.$refs.camera.videoWidth;
                context.canvas.height = this.$refs.camera.videoHeight;
				context.drawImage(this.$refs.camera, 0, 0);
				const canvas = document.getElementById("photoTaken").toDataURL("image/jpeg");
                if(context.canvas.width >0 && context.canvas.height>0){
					this.profile_avatar =  canvas;
				}
                this.toggleCamera();
            },
			stop() {
                return document.getElementById('video_player').srcObject && document.getElementById('video_player').srcObject.getTracks().map(t => t.stop());
            },
			downloadImage() {
                const download = document.getElementById("downloadPhoto");
                const canvas = document.getElementById("photoTaken").toDataURL("image/jpeg")
                .replace("image/jpeg", "image/octet-stream");
                console.log(download);
                console.log(canvas);
                download.setAttribute("href", canvas);
            },
			toggleSidebar (hide='') {
				this.admin_patient_comp_key = this.admin_patient_comp_key++;
				if (hide == 1) {
					$("#editNotesside").removeClass("control-sidebar-open");
				} else if($("#editNotesside").hasClass("control-sidebar-open")) {
					$("#editNotesside").removeClass("control-sidebar-open");
				} else {
					$("#editNotesside").addClass("control-sidebar-open");
				}
			},
			navToAdminNote() {
				//this.$router.push(`/patient-chart/${this.$route.params.id}/demographics?redirectTo=admin`); 
				this.$store.state.patientDetailsComp = 'PatientDemographics';
				let props = this.$router.resolve({
					name: 'PatientDemographics',
					params: { id: this.$route.params.id},
					query: { redirectTo: 'admin' }
				});
				if (history.pushState && props) {
					if (this.hash=='' || this.hash==null || typeof this.hash === undefined || typeof this.hash === 'undefined') {
						history.pushState(null, '',props.href);
					} else {
						history.pushState(null, '',props.href+"/#"+this.hash);
					}
				}
			},
			duplicate(){
				var date_of_birth = moment(this.patientChart.DOB).format('YYYY-MM-DD');
				axios.post("patient/duplicate",{first_name:this.patientChart.first_name,last_name:this.patientChart.last_name,date_of_birth:date_of_birth})
				.then((response) => {
					this.duplicate_list = response.data.data;
					if(this.duplicate_list.length > 1){
						$("#duplicate").modal("show");
					}
				});
			},
			sendpos(){
				this.$router.push({ name: 'MainBilling'})
			},
			closeModal(){
				window.location.reload();
				this.profile_avatar = '';
				this.profile_avatarerror = '';
			},
			onChangeFileUpload(){
				let reader = new FileReader;
				if (this.$refs.file&&this.$refs.file.files[0]) {
						reader.onload = e => {
							this.profile_avatar =  e.target.result;
						}
					}
					reader.readAsDataURL(this.$refs.file.files[0]);
				
			},
			changeprofile(){
				
				if (['image/png','image/jpeg','image/svg'].includes(this.profile_avatar['type'])) {
					if(this.profile_avatar != ''){
					let formData = new FormData();
					formData.append('profile_avatar', this.profile_avatar);
					let config = {headers: {'Content-Type': 'multipart/form-data'}}
					this.$store.state.loader = true;
					axios.post("patient/" + this.$route.params.id + "/profile-update",formData,config)
					.then((result) => {
						location.reload();
					});
					}else{
						this.profile_avatarerror = "Please Upload profile avatar";
					}
				}else{
					if (this.profile_avatar['type']==undefined) {
						console.log(this.profile_avatar['type']);
						if(this.profile_avatar != ''){
						let formData = new FormData();
						formData.append('profile_avatar', this.profile_avatar);
						let config = {headers: {'Content-Type': 'multipart/form-data'}}
						this.$store.state.loader = true;
						axios.post("patient/" + this.$route.params.id + "/profile-update",formData,config)
						.then((result) => {
							location.reload();
						});
					}else{
						this.profile_avatarerror = "Please Upload profile avatar";
					}
						
					}else{
					this.profile_avatar="";
					this.profile_avatarerror = "Please upload your profile avatar with a png, svg, jpg or jpeg format only.";
					}
					
				}
				
			},
			changeTab (components) {
				this.$store.state.patientDetailsComp = components;
				let props = this.$router.resolve({
					name: components,
					params: { id: this.$route.params.id},
				});
				if (history.pushState && props) {
					if (this.hash=='' || this.hash==null || typeof this.hash === undefined || typeof this.hash === 'undefined') {
						history.pushState(null, '',props.href);
					} else {
						history.pushState(null, '',props.href+"/#"+this.hash);
					}
				}
			},
			capitalize(string) {
				return string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
			},
			sendAuthorizedEmail(patientId) {
				this.$swal
				.fire({
					title: '<h2 class="mt-0">Are you sure?</h2>',
					text: 'You want to send Credit Card authorization email?',
					icon: "warning",
					dangerMode: true,
					showCancelButton: true,
					confirmButtonColor: "#ee3158",
					cancelButtonColor: "#bdbdbd",
					confirmButtonText: 'Yes',
				})
				.then((result) => {
					if (result.isConfirmed == true) {
						this.$store.state.loader = true;
						axios.post("/payment-gateway-migration/resend-email", { patient_id: patientId }).then((response) => {
							this.$store.state.loader = false;
							this.moshaToast(response.data.data, "success");
						}).catch((error) => {
							this.$store.state.loader = false;
							this.moshaToast(error.response.data.message, "error");
						});
					}
				});
			},
			getCdsAlertForPatient(patientId) {
				axios
				.post(`/patient/${this.$route.params.id}/cdsAlertForPatient`, {
					'patient_id' : patientId
				}).then((response) => {
					this.cds_alert_for_patient = response.data.data;
				});
			},
			async getPatientAvatar(patientAvatar){
				if(patientAvatar!=null)
				{
					const res = await fetch(process.env.VUE_APP_API_BASE_URL+`/patient-avatar/${patientAvatar}`, {
						method: 'GET',
						headers: {
							'Authorization' : 'Bearer '+localStorage.getItem('authToken')
						}
					});
					if(res){
						const blob = await res.blob();
						const urlObject = URL.createObjectURL(blob);
						this.$store.state.patientDetail.patientAvatar = (urlObject!='') ? '<img src="'+urlObject+'" alt="Patient Photo" class="img-fluid"/>' : '';       
					}
					else{
						this.$store.state.patientDetail.patientAvatar = "";     
					}
				}
				else{
					this.$store.state.patientDetail.patientAvatar = ""; 
				}				
			}
		},
	};
</script>
<style>
    .web-camera-container {padding: 10px;display: flex;flex-direction: column;justify-content: center;align-items: center;border: 1px solid #e4e6ef;border-radius: 4px;background: #f1f3fe;}
    .web-camera-container .camera-button {}
    .web-camera-container .camera-box .camera-shutter {opacity: 0;width: 450px;height: 337.5px;background-color: #fff;position: absolute;}
    .web-camera-container .camera-box .camera-shutter.flash {opacity: 1;}
    .web-camera-container .camera-shoot {margin: 10px 0 0;}
    .web-camera-container .camera-shoot button{    height: 45px;
    width: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20%;
    border: 2px solid #e7e9f6;
    background: #fff;}
    .web-camera-container .camera-shoot button img {height: 30px;object-fit: cover;}
    .web-camera-container .camera-loading {overflow: hidden;height: 100%;position: absolute;width: 100%;min-height: 150px;margin: 3rem 0 0 -1.2rem;}
    .web-camera-container .camera-loading ul {height: 100%;position: absolute;width: 100%;z-index: 999999;margin: 0;}
    .web-camera-container .camera-loading .loader-circle {display: block;height: 14px;margin: 0 auto;top: 50%;left: 100%;transform: translateY(-50%);transform: translateX(-50%);position: absolute;width: 100%;padding: 0;}
    .web-camera-container .camera-loading .loader-circle li {display: block;float: left;width: 10px;height: 10px;line-height: 10px;padding: 0;position: relative;margin: 0 0 0 4px; background: #999;animation: preload 1s infinite;top: -50%;border-radius: 100%;}
    .web-camera-container .camera-loading .loader-circle li:nth-child(2) {animation-delay: 0.2s;}
    .web-camera-container .camera-loading .loader-circle li:nth-child(3) {animation-delay: 0.4s;}
    @keyframes preload {
        0% {
            opacity: 1;
        }
        50% {
            opacity: 0.4;
        }
        100% {
            opacity: 1;
        }
    }
</style>