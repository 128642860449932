<template>
  <div class="content-wrapper">
    <div class="container-full">
      <div class="content-header">
        <div class="row justify-content-between">
          <div class="col-12 col-sm-6 col-md-6 col-lg-8">
            <h1 class="heading1 mt-0">Archived Staff <a href="#" data-bs-toggle="modal" data-bs-target="#staffarchivedocument" title="Reference of Knowledge Base" v-on:click.prevent="reset()"> <i class="fa fa-question-circle fs-18"></i></a></h1>
          </div>
          <div class="col-12 col-sm-6 col-md-6 col-lg-4 text-sm-end">
            <a href="#/staff/list" class="waves-effect waves-light btn btn-info-light"><i class="fa fa-arrow-left fs-14"></i> Back </a> 
          </div>
        </div>
      </div>
      <section class="content">
        <div class="box">
          <div class="box-body">
            <div class="table-responsive">
              <center>
                <span class="isvalid-feedback" style="display:block;">{{success}}</span>
              </center>
              <data-table dataType="StaffArchivedList">
                <template v-slot="slotProps">
                  <td>{{ slotProps.item.username }}</td>
                  <td>{{ slotProps.item.rolename }}</td>
                  <td>
                    {{ slotProps.item.first_name }}
                    {{ slotProps.item.last_name }}
                  </td>
                  <td>{{ slotProps.item.email }}</td>
                  <td>{{ slotProps.item.provider_name }}</td>
                  <td>{{ slotProps.item.cell_phone }}</td>
                  <td>{{ slotProps.item.home_phone }}</td>
                  <td>
                    <span v-if="slotProps.item.emergency_access== 1"> Yes</span>
                    <span v-if="slotProps.item.emergency_access== 0"> No</span>
                  </td>
                  <td>
                    
                    <button type="button"  data-on="Ready" data-off="Not Ready" @click="onToggleChange(slotProps.item.id,slotProps.item.role_id,slotProps.item.email,$event)" :id="['status_' + slotProps.item.id]" class="btn btn-toggle rounded30-im"  v-bind:class="[slotProps.item.deleted_at != NULL ? 'active' : '']" data-bs-toggle="button" aria-pressed="true" autocomplete="off">
                      <div class="handle rounded30-im"></div>
                    </button>
                  </td>
                  
                </template>
              </data-table>
            </div>
          </div>
        </div>
      </section>
      <!--document model start-->
      <div id="staffarchivedocument" class="modal fade" tabindex="-1" role="dialog"
         aria-labelledby="myModalLabel" aria-hidden="true">
         <div class="modal-dialog modal-md" style="max-width: 600px;">
            <div class="modal-content">
               <div class="modal-header">
                  <h3 class="modal-title fw-900 mt-0">Reference of Knowledge Base</h3>
               </div>
               <div class="modal-body overflow-auto" style="max-height: 350px;">
                  <table class="table table-bordered">
                     <thead>
                        <tr>
                           <th scope="col">No.</th>
                           <th scope="col">Knowledge Base Articles</th>
                        </tr>
                     </thead>
                     <tbody>
                        <tr>
                           <td>1</td>
                           <td> <a :href="path+'how-to-recover-deleted-staff-member-s-account-in-pureehr'" target="_blank">How To Recover Deleted Staff Member's Account In PureEHR?</a></td>
                        </tr>
                     </tbody>
                  </table>
                  <Search :key="searchKey"></Search>
               </div>
               <div class="modal-footer">
                  <button type="button" @click="" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
               </div>
            </div>
         </div>
      </div>
	   <!--document model end-->
    </div>
  </div>
</template>

<script>
import dataTable from "@/components/base/DataTable.vue";
import axios from "@/scripts/axios.js";
import submitMixins from "@/scripts/mixins/submitMixins.js";
import permissionMixins from "@/scripts/mixins/permission.js";
import Search from "@/components/Search.vue";

export default {
  name: "Home",
  mixins: [submitMixins,permissionMixins],
  components: { dataTable, Search },
  data() {
    return {
      success: "",
      result: "",
      path: process.env.VUE_APP_KB_DOCUMENT,
      searchKey:0,
    };
  },
  mounted() {
    if (this.$route.params.message) {
      this.success = this.$route.params.message;
    }
  },
  methods: {
    reset() {
      this.searchKey = this.searchKey + 1;
    },
    onToggleChange(id,role_id,email,event) {
      var class_list = document.getElementById("status_"+id).classList;
      var active_deactive = '';
      if(class_list.contains('active')) {
        active_deactive = 'A';
      } else {
        active_deactive = 'D';
        // console.log(class_list.contains('active'));
      }

      axios
        .post("staff/reset",{"deleted_at":null,'deleted_by':'',"id":id,"role_id":role_id,"email":email})
        .then((response) => {
          this.moshaToast('User restored successfully.','success');
          setTimeout(function(){ location.reload(); }, 3000);
      });
      
    },
    remove(id) {
      this.$swal
        .fire({
          title: '<h2 class="mt-0">Are you sure?</h2>',
          text:
            "You want to delete these records? This process cannot be undone.",
          icon: "warning",
          dangerMode: true,
          showCancelButton: true,
          confirmButtonColor: "#ee3158",
          cancelButtonColor: "#bdbdbd",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed == true) {
            axios.post("/staff/delete", { id: id }).then((response) => {
              this.$swal
                .fire(
                  "Deleted!",
                  "Your Staff Profiles has been deleted.",
                  "success"
                )
                .then((result) => {
                  if (result.isConfirmed == true) {
                    location.reload();
                  }
                });
            });
          }
        });
    },
    edit(id) {
      this.$router.push({ name: "Staffedit", params: { id: id } });
    },
    resendVerificationLink(id) {
      this.$store.state.loader = true;
        axios
          .post(`resend-email-verification`, { email_user_id: id })
          .then((response) => {
            this.$store.state.loader = false;
            if (response.data.data.result == true) {
              this.$swal
                .fire(
                  "Success!",
                  "Email verification link has been send.",
                  "success"
                )
                .then((result) => {
                  if (result.isConfirmed == true) {
                    //location.reload();
                  }
                });
              console.log(response.data.data.result);
            } else {
              this.$swal
                .fire(
                  "Error!",
                  "Oops,something went wrong, please try again.",
                  "danger"
                )
                .then((result) => {
                  if (result.isConfirmed == true) {
                  }
                });
            }
          });
    },
  },
};
</script>
