<template>
  <div class="content-wrapper">
    <div class="container-full">
      <div class="content-header">
        <div class="row justify-content-between">
          <div class="col-12 col-sm-4 col-md-6 col-lg-6 col-xl-6">
            <h2 class="heading2 mb-10">Billing Profiles <a href="#" data-bs-toggle="modal" data-bs-target="#billingprofiledocument" title="Reference of Knowledge Base" v-on:click.prevent="reset()"> <i class="fa fa-question-circle fs-20"></i></a></h2>
          </div>
          <div class="col-12 col-sm-8 col-md-6 col-lg-6 col-xl-6 text-sm-end">
            <template v-if="this.show_lifestyle_menu">
                <a href="#/billing-profile-list/archived?lifestyle=true" class="waves-effect waves-light btn btn-info mb-1 me-1"> Show Archived </a>
                <router-link
                  class="waves-effect waves-light btn btn-info mb-1"
                  to="/billing-profile/add?lifestyle=true"
                  ><i class="fa fa-plus fs-14"></i> Billing Profile
                </router-link>
            </template>
            <template v-else>
              <a href="#/billing-profile-list/archived" class="waves-effect waves-light btn btn-info mb-1 me-1"> Show Archived </a>
              <router-link
                class="waves-effect waves-light btn btn-info mb-1"
                to="/billing-profile/add"
                ><i class="fa fa-plus fs-14"></i> Billing Profile
              </router-link>
            </template>

          </div>
        </div>
      </div>
      <div class="row">
          <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-2"  v-if="this.show_lifestyle_menu">
              <life-style-header routefrom="BillingProfileList"></life-style-header>
          </div>
          <div  v-bind:class="show_lifestyle_menu ? 'col-12 col-sm-12 col-md-9 col-lg-9 col-xl-10' : 'col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12' ">
              <section class="content">
                <div class="box">
                  <div class="box-body">
                    <div class="table-responsive">
                      <data-table-server dataType="billingprofile">
                        <template v-slot="slotProps">
                          <!-- slot for table data -->
                          <td>{{ slotProps.item.name }}</td>
                          <td>
                            <div class="d-inline-block" 
                              v-if="slotProps.item.icd10_codes != null"
                              :set="
                                (description = slotProps.item.icd10_description.split(
                                  ','
                                ))
                              "
                            >
                              <div class="d-inline-block" 
                                v-for="(item,
                                index) in slotProps.item.icd10_codes.split(',')"
                                :key="index"
                              >
                                <Popper
                                  arrow
                                  :content="description[index]"
                                  :hover="true"
                                  class="helptips"
                                >
                                  <span class="badge badge-secondary me-1 ">
                                    {{ item }}
                                  </span>
                                </Popper>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="d-inline-block" 
                              v-if="slotProps.item.cpt_codes != null"
                              :set="
                                (description = slotProps.item.cpt_description.split(
                                  ','
                                ))
                              "
                            >
                              <div
                                v-for="(item, index) in slotProps.item.cpt_codes.split(
                                  ','
                                )"
                                :key="index"
                              >
                                <Popper
                                  arrow
                                  :content="description[index]"
                                  :hover="true"
                                  class="helptips"
                                >
                                  <span class="badge badge-secondary me-1">
                                    {{ item }}
                                  </span>
                                </Popper>
                              </div>
                            </div>
                          </td>
                          <!-- <td>
                            <div class="d-inline-block" v-if="slotProps.item.hcpcs_codes != null">
                              <span
                              v-for="(
                                item, index
                                ) in slotProps.item.hcpcs_codes.split(',')"
                                :key="index"
                                class="badge badge-secondary me-1"
                                >
                                {{ item }}
                              </span>
                            </div>
                          </td> -->
                          <td>
                            <div class="d-inline-block" 
                              v-if="slotProps.item.package_code != null"
                              :set="
                                (description = (slotProps.item.package_code_description ? slotProps.item.package_code_description.split(
                                  ','
                                ) : '' ))
                              "
                            >
                              <div
                                v-for="(item,
                                index) in slotProps.item.package_code.split(',')"
                                :key="index"
                              >
                                <Popper
                                  arrow
                                  :content="description[index]"
                                  :hover="true"
                                  class="helptips"
                                >
                                  <span class="badge badge-secondary me-1">
                                    {{ item }}
                                  </span>
                                </Popper>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="d-inline-block" 
                              v-if="slotProps.item.custom_procedure_codes != null"
                              :set="
                                (description = slotProps.item.custom_procedure_description.split(
                                  ','
                                ))
                              "
                            >
                              <div
                                v-for="(item,
                                index) in slotProps.item.custom_procedure_codes.split(
                                  ','
                                )"
                                :key="index"
                              >
                                <Popper
                                  arrow
                                  :content="description[index]"
                                  :hover="true"
                                  class="helptips"
                                >
                                  <span class="badge badge-secondary me-1">
                                    {{ item }}
                                  </span>
                                </Popper>
                              </div>
                            </div>
                          </td>
                          <td>{{ slotProps.item.updated_ats }}</td>
                          <td>
                            <Popper arrow content="Edit Billing Profile" :hover="true" class="helptips cursor-pointer">
                              <a
                                href="#"
                                @click="editItem(slotProps.item.id)"
                                class="waves-effect waves-circle btn btn-circle btn-info btn-xs me-1"
                                data-bs-toggle="modal"
                                data-bs-target="#billingprofilemodal"
                                ><i class="fa fa-pencil"></i></a>
                            </Popper>
                            <Popper arrow content="Delete Billing Profile" :hover="true" class="helptips cursor-pointer">
                              <a
                                href="javascript:void(0)"
                                class="waves-effect waves-circle btn btn-circle btn-danger btn-xs"
                                @click="deleteItem(slotProps.item.id)"
                                ><i class="fa fa-trash"></i></a>
                            </Popper>
                          </td>
                        </template>
                      </data-table-server>
                    </div>
                  </div>
                </div>
              </section>
          </div>
      </div>
      <!--document model start-->
      <div id="billingprofiledocument" class="modal fade" tabindex="-1" role="dialog"
         aria-labelledby="myModalLabel" aria-hidden="true">
         <div class="modal-dialog modal-md">
            <div class="modal-content">
               <div class="modal-header">
                  <h3 class="modal-title fw-900 mt-0">Reference of Knowledge Base</h3>
               </div>
               <div class="modal-body overflow-auto" style="max-height: 350px;">
                  <table class="table table-bordered">
                     <thead>
                        <tr>
                           <th scope="col">No.</th>
                           <th scope="col">Knowledge Base Articles</th>
                        </tr>
                     </thead>
                     <tbody>
                        <tr>
                           <td>1</td>
                           <td> <a :href="path+'how-to-add-a-new-billing-profile-in-pureehr'" target="_blank">How To Add A New Billing Profile In PureEHR? </a></td>
                        </tr>
                        <tr>
                           <td>2</td>
                           <td> <a :href="path+'how-to-edit-a-billing-profile-in-pureehr'" target="_blank">How To Edit A Billing Profile In PureEHR?</a></td>
                        </tr>
                        <tr>
                           <td>3</td>
                           <td> <a :href="path+'how-to-delete-billing-profile-in-pureehr'" target="_blank">How To Delete Billing Profile In PureEHR?</a></td>
                        </tr>
                        <tr>
                           <td>4</td>
                           <td> <a :href="path+'how-to-view-delete-billing-profile-in-pureehr'" target="_blank">How To View Delete Billing Profile In PureEHR?</a></td>
                        </tr>
                     </tbody>
                  </table>
                  <Search :key="searchKey"></Search>
               </div>
               <div class="modal-footer">
                  <button type="button" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
               </div>
            </div>
         </div>
      </div>
	   <!--document model end-->
    </div>
  </div>
</template>
<script>
import DataTableServer from "@/components/base/DataTableServer.vue";
import LifeStyleHeader from "@/components/Lifestyle/LifeStyleHeader.vue";
import moment from "moment";
import axios from "@/scripts/axios.js";
import Popper from "vue3-popper";
import Search from "@/components/Search.vue";

export default {
  name: "BIllingProfileList",
  components: { DataTableServer, Popper, LifeStyleHeader, Search },
  data() {
    return {
       show_lifestyle_menu : this.$route.query.lifestyle ? true : false,
       path: process.env.VUE_APP_KB_DOCUMENT,
       searchKey:0,
    };
  },
  computed: {},
  mounted() {
  },
  methods: {
    reset() {
      this.searchKey = this.searchKey + 1;
    },
    formatDate(date) {
      return moment(date).format("MMM D, YYYY");
    },
    editItem(id) {
      if (this.show_lifestyle_menu) {
        this.$router.push({ name: "BillingProfileEdit", params: { id }, query: { lifestyle: this.show_lifestyle_menu } });
      } else {
        this.$router.push({ name: "BillingProfileEdit", params: { id } });
      }
    },
    deleteItem(id) {
      this.$swal
        .fire({
          title: '<h2 class="mt-0">Are you sure?</h2>',
          text:
            "You want to delete these records? This process cannot be undone.",
          icon: "warning",
          dangerMode: true,
          showCancelButton: true,
          confirmButtonColor: "#ee3158",
          cancelButtonColor: "#bdbdbd",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed == true) {
            axios.post("billing-profile/delete", { id: id }).then((res) => {
              this.$swal
                .fire("Deleted!", res.data.message, "success")
                .then((result) => {
                  if (result.isConfirmed == true) {
                    location.reload();
                  }
                });
            });
          }
        });
    },
  },
};
</script>
