<template>
  <div class="content-wrapper">
    <div class="container-full">
      <div class="content-header">
        <div class="row justify-content-between">
          <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xs-6">
            <h1 class="heading1 my-5">PureCRM WorkFlow</h1>
          </div>
          <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xs-6 text-sm-end">
            <router-link to="/crm/workflow/add" class="waves-effect waves-light btn btn-info mb-1"><i class="fa fa-plus fs-14"></i> Workflow</router-link>
          </div>
        </div>
      </div>
      <section class="content">
        <div class="box">
          <div class="box-body">
            <div class="table-responsive">
              <data-table dataType="workflow">
                <template v-slot="slotProps">
                  <td>{{ slotProps.item.name }}</td>
                  <td>{{$filters.displayDateTimeLocal(slotProps.item.created_at,'',false,"ddd, MM-DD-YYYY",true
)}} </td>
                  <td>{{$filters.displayDateTimeLocal(slotProps.item.latest_updated_at,'',false,"ddd, MM-DD-YYYY",true
)}} </td>
                  <td>
                    <div class="d-flex">
                      <Popper arrow content="Edit Workflow" :hover="true" class="helptips cursor-pointer">
                        <a
                        @click="editItem(slotProps.item.id)"
                        href="#"
                        class="waves-effect waves-circle btn btn-circle btn-info btn-xs me-1" ><i class="fa fa-pencil"></i></a>
                      </Popper>
                      <Popper arrow content="Delete Workflow" :hover="true" class="helptips cursor-pointer">
                        <a
                        @click="deleteItem(slotProps.item.id)"
                        href="#"
                        class="waves-effect waves-circle btn btn-circle btn-danger btn-xs me-1"><i class="fa fa-trash"></i></a>
                      </Popper>
                      <Popper arrow content="View Execution Log History" :hover="true" class="helptips cursor-pointer">
                        <a
                        @click="openExecutionLog(slotProps.item.id)"
                        href="#"
                        class="waves-effect waves-circle btn btn-circle btn-info btn-xs me-1"><i class="fa fa-history"></i></a>
                      </Popper>
                    </div>
                  </td>
                </template>
              </data-table>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
<!--show Execution Log start-->
<div class="modal fade" id="execution_log_dialog" tabindex="-1" role="dialog" aria-labelledby="execution_log_dialog" aria-hidden="true">
    <div class="modal-dialog" style="max-width: 600px;">
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="modal-title fw-900 mt-0">Execution Log</h3>
            </div>
            <div class="modal-body">
                    <execution-log v-if="current_workflow_id" :workflowId="current_workflow_id"  :key="current_workflow_id"></execution-log>
            </div>
            <div class="modal-footer">
                <button type="button" class="waves-effect waves-light btn btn-danger" data-bs-dismiss="modal" @click="paymentDialogClose">Close</button>
            </div>
        </div>
    </div>
</div>
<!--show Execution Log end-->
</template>


<script>
import axios from "@/scripts/axios.js";
import dataTable from "@/components/base/DataTable.vue";
import submitMixins from "@/scripts/mixins/submitMixins.js";
import permissionMixins from "@/scripts/mixins/permission.js";
import ExecutionLog from "@/views/CRM/Workflow/ExecutionLog.vue";

import Popper from "vue3-popper";

export default {
  name: "Workflow",
  mixins: [submitMixins,permissionMixins],
  components: { dataTable, Popper,ExecutionLog },
  data() {
    return {
      current_workflow_id:'',
    }
  },
  methods: {
    openExecutionLog(workflow_id) {
      this.current_workflow_id = workflow_id;
      console.log(this.current_workflow_id);
      $("#execution_log_dialog").modal('show');
    },
    editItem(id) {
      this.$router.push({ name: "workflowEdit", params: { id } });
    },
    deleteItem(id) {
      this.$swal
        .fire({
          title: '<h2 class="mt-0">Are you sure?</h2>',
          text:
            "You want to delete these record?",
          icon: "warning",
          dangerMode: true,
          showCancelButton: true,
          confirmButtonColor: "#ee3158",
          cancelButtonColor: "#bdbdbd",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed == true) {
            axios
              .post(`/crm/workflow/delete`, { id: id })
              .then((response) => {
                this.$swal
                  .fire(
                    "Deleted!",
                    "Corporate has been deleted.",
                    "success"
                  )
                  .then((result) => {
                    if (result.isConfirmed == true) {
                      //location.reload();
                    }
                  });
              });
          }
        });
    },
  },
};
</script>