<template>
  <div class="content-wrapper">
    <div class="container-full">
      <section class="content">
        <div class="row justify-content-between">
          <!-- sidebar -->
          <the-side-bar></the-side-bar>
          <!-- content -->
          <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-10">
            <div class="tab-content">
              <div class="authform-modal box">
              <component
                :is="$store.state.addProviderComp"
                :key="Math.floor(Math.random() * 20)"
              ></component>
            </div>
            </div>
          </div>
        </div>
      </section>  
    </div>
  </div>
</template>

<script>
import TheSideBar from "@/components/GeneralSetting/Sidebar.vue";
import AddProviderHome from "@/components/provider/add/Home.vue";
import GeneralSetting from "@/components/GeneralSetting/Setting.vue";
import ProfileSetting from "@/components/GeneralSetting/ProfileSetting.vue";

export default {
  components: { TheSideBar, AddProviderHome, GeneralSetting, ProfileSetting },
  data() {
    return {};
  },
  watch: {},
  created() {
    this.$store.state.addProviderComp = this.$route.name;
  },
  methods: {},
};
</script>
