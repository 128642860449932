<template>
	<div class="container-fluid bg-white">
		<footer class="main-footer text-center position-fixed w-p100 py-1" style="bottom:0;z-index: 2;left: 0;">
			<div class="fs-14">
				{{  new Date().getFullYear() }}. All Rights Reserved.
				<a href="https://staging.pureehr.com/Documents/TermsOfUse.pdf" class="text-dark text-decoration-underline me-1" target="_blank">Term & Conditions</a> |
				<a href="https://staging.pureehr.com/Documents/APIDocument.pdf" class="text-dark text-decoration-underline ms-1" target="_blank">API Document</a>
			</div>
		</footer>
	</div>
</template>