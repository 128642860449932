<template>  
	<div class="content-wrapper">
		<div class="container-full">
			<div class="content-header">
				<h1 class="heading1 mt-0">LifeStyle Management</h1>
			</div>
			<div class="row">
				<div class="col-12 col-sm-6 col-md-4 col-lg-4">
					<div class="box">
						<div class="box-header bg-warning-light">
							<h2 class="heading2 my-5 fs-22 text-dark">Prerequisites</h2>
						</div>
						<div class="box-body">
							<p>For the PureEHR Lifestyle module to function properly, a Lifestyle-specific Provider, Staff, and Office must first be set up. The chosen Lifestyle-specific Provider, Staff, and Office are listed below.</p>
							
							<h3 class="d-inline-block fw-600 mt-0">Provider</h3>
							<a href="#/provider/list" class="waves-effect waves-light btn btn-info-light btn-sm float-end" target="_blank"><i class="fa fa-plus"></i> Add New</a>
							
							<ul class="ps-20">
								<li v-for="providerlist in providers" class="py-1">{{ providerlist.name }}</li>
							</ul>

							<hr>
							<h3 class="d-inline-block fw-600 mt-0">Staff</h3>
							<a href="#/staff/list" class="waves-effect waves-light btn btn-info-light btn-sm float-end" target="_blank"><i class="fa fa-plus"></i> Add New</a>
							<ul class="ps-20">
								<li v-for="stafflist in staff" class="py-1">{{ stafflist.name }}</li>
							</ul>
							
							<hr>
							<h3 class="d-inline-block fw-600 mt-0">Office</h3>
							<a href="#/office/list" class="waves-effect waves-light btn btn-info-light btn-sm float-end" target="_blank"><i class="fa fa-plus"></i> Add New</a>
							<ul class="ps-20">
								<li v-for="officelist in office" class="py-1">{{ officelist.name }}</li>
							</ul>
						</div>
					</div>
				</div>
				<div class="col-12 col-sm-6 col-md-4 col-lg-4">
					<div class="box">
						<div class="box-header bg-info-light">
							<h2 class="heading2 my-5 fs-22 text-dark">LifeStyle Product Management</h2>
						</div>
						<div class="box-body">
							<p>With the use of lifestyle product management, editing existing products/ package kits or adding new ones is made simple.</p>
							<p>To add a new product/ package kit, please follow the steps mentioned.</p>
						</div>
					</div>
					<div class="box">
						<div class="box-body">
							<h3><b>Step: 1 LifeStyle Category </b></h3>
							<p>Add new package kits, first add the main category name like Pure Plus, Pure Beauty</p>
							<router-link to="/corporate/life-style-service-category" class="waves-effect waves-light btn btn-info-light btn-sm"><i class="fa fa-plus"></i> Add New</router-link>
						</div>
					</div>
					<div class="box">
						<div class="box-body">
							<h3><b>Step: 2 Lifestyle Services</b></h3>
							<p>Add new treatment/formulation under the created category(Pure Plus) package Kits i.e. Vital Complex for Pure Plus</p>
							<router-link to="/corporate/life-style-service" class="waves-effect waves-light btn btn-info-light btn-sm"><i class="fa fa-plus"></i> Add New</router-link>
						</div>
					</div>
					<div class="box">
						<div class="box-body">
							<h3><b>Step: 3 LifeStyle Specific Custom Procedures</b></h3>
							<p>Add the Created Product/ Package as Custom Procedures for Appointment and Billing purpose i.g. Pure Plus, Pure biotin</p>
							<router-link to="/custom-procedures?lifestyle=true" class="waves-effect waves-light btn btn-info-light btn-sm"><i class="fa fa-plus"></i> Add New</router-link>
						</div>
					</div>
					<div class="box">
						<div class="box-body">
							<h3><b>Step: 4 Billing Profile</b></h3>
							<p>Integrate all the IV Products and Packages into IV Form Attach created custom procedures to lifestyle Billing Profile.</p>  
							<router-link to="/billing-profile-list?lifestyle=true" class="waves-effect waves-light btn btn-info-light btn-sm"><i class="fa fa-plus"></i> Add New</router-link>
						</div>
					</div>
				</div>
				<div class="col-12 col-sm-6 col-md-4 col-lg-4">
					<div class="box">
						<div class="box-header bg-success-light">
							<h2 class="heading2 my-5 fs-22 text-dark">LifeStyle Appointments</h2>
						</div>
						<div class="box-body">
							<router-link to="/life-style/appointment-list" class="waves-effect waves-light btn btn-info d-block">View LifeStyle Appointments list</router-link>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from "@/scripts/axios.js";
import dataTable from "@/components/base/DataTable.vue";
import submitMixins from "@/scripts/mixins/submitMixins.js";
import Popper from "vue3-popper";

export default {
	mixins: [submitMixins],
	name: "roles",
	components: { dataTable, Popper },
	data() {
		return {
			providers: [],
			staff: [],
			office: [],
		};
	},
	methods: {
		getPrerequisite(){
			axios
				.get("/lifestyle/prerequisite")
				.then((response) => {
					this.providers = response.data.data.provider;
					this.staff = response.data.data.staff;
					this.office = response.data.data.office;
				});
		}
	},
	mounted(){
		this.getPrerequisite();
	}
};
</script>
