<template>
   <div class="box p-15">
        <div class="mdc-tab">
            <ul class="nav nav-tabs mt-0 text-center text-sm-start" role="tablist">
               <!--  <li class="nav-item"> <a class="nav-link" data-bs-toggle="tab" href="#handp" @click.prevent="changeTab('ClinicalNoteHandP')" role="tab">H&P</a> </li>
                <li class="nav-item"> <a class="nav-link" data-bs-toggle="tab" href="#soap" role="tab" @click.prevent="changeTab('ClinicalNoteSoap')">SOAP</a> </li> -->
                <li class="nav-item"> <a class="nav-link" data-bs-toggle="tab" href="#additionalnotes" role="tab" @click.prevent="changeTab('ClinicalNoteAdditional')">Custom Notes</a> </li>
                <li class="nav-item"> <a class="nav-link active" data-bs-toggle="tab" href="#amendments" role="tab" @click.prevent="changeTab('PatientAmendments')">Amendments</a> </li>
                <li class="nav-item"> <a class="nav-link" data-bs-toggle="tab" href="#lockedNote" role="tab" @click.prevent="changeTab('PatientLockedClinicalNotes')">Locked Clinical Notes</a> </li>
            </ul>
            <div class="tab-content">
                <div class="tab-pane active" id="amendments" role="tabpanel">
                     <div class="box bg-lightest p-10 my-2">
                        <div class="form-group row mb-0">
                            <label class="col-form-label col-12 col-sm-3 col-md-3 col-lg-2 col-xl-2 col-form-label mb-0 text-sm-end">Appointment <small class="text-danger asterisksign" style="display: none;">*</small></label>
                            <div class="col-12 col-sm-9 col-md-9 col-lg-7 col-xl-5">
                                <select class="select2Single form-select form-control mb-1" v-model="appointment_id" id="appointment_id"  @change="changeAppointment($event)">
                                    <option value="" disabled="disabled">Select Appointment</option>

                                    <template  v-for="(item,index) in appointmentData"  :key="item.id">
                                        <option :value="item.id" v-if="item.imported_at == null " :data-val="item.note_unique_id" :data-app-val="item.appointment_number" data-type="system">
                                            {{item.name}}
                                        </option>
                                    </template>
                                    <optgroup label="Imported Appointment">
                                        <template  v-for="(item,index) in appointmentData"  :key="item.id">
                                          <option :value="item.id" v-if="item.imported_at != null " :data-val="item.note_unique_id" :data-app-val="item.appointment_number">
                                            {{ item.name }}
                                          </option>
                                        </template>
                                    </optgroup>
                                </select>
                            </div>
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-5 text-sm-end">
                                <button type="button" v-if="notePreview" class="waves-effect waves-light btn btn-secondary mb-1 me-1" @click="PreviewNote"><i class="fa fa-file-pdf-o"></i> Preview Note</button>
                            </div>
                        </div>
                        <label>
                           <span class="px-2 p-1 bg-warning-light text-dark border-warning d-inline-block rounded b-1 fs-14 l-h-n mt-10" v-if="this.amedment_note">{{this.amedment_note}}</span>
                        </label>
                    </div>

                  <div class="" id="amendments_list">
                     <div v-if="this.form.appointment_id">
                        <div v-if="this.reander">
                           <div class="table-responsive" >
                              <table-base 
                                 :dataTypes="{
                                    dataType: 'Amendments',
                                    patient_id: patient_id,
                                    patient_unique_id:patient_unique_id,
                                    appointment_id:this.form.appointment_id
                                 }"
                                 :lastcol='permissioncheck(this.$store.state.permission.create_update)'
                                 >
                                 <template v-slot="slotProps">
                                    <td>
                                       <a href="javascript:void(0);" @click="FileDisplay(slotProps.item.document_name_url,$event)" class="fw-600">{{ slotProps.item.document_name }}</a><br>
                                       <b class="fw-500">Comments: </b>{{ slotProps.item.comments }}<br>
                                       <b class="fw-500">Source: </b>{{ slotProps.item.source }}
                                    </td>
                                    <td v-if="slotProps.item.status == 'Accepted'">
                                       <span class="badge badge-success-light fs-18 font-bold"><i class="mdi mdi-check"></i> {{ capitalizeFirstLetter(slotProps.item.status) }}</span>
                                    </td>
                                    <td v-else>
                                       <button v-if="permissioncheck(this.$store.state.permission.create_update)" @click="edit(slotProps.item.id)" class="waves-effect waves-light btn btn-info btn-xs me-1"><i class="mdi mdi-table-edit "></i> Edit</button>
                                       <button class="waves-effect waves-light btn btn-danger btn-xs"><i class="mdi mdi-close"></i> {{ capitalizeFirstLetter(slotProps.item.status) }}</button>
                                    </td>
                                    <td>
                                       {{ $filters.displayDateTimeLocal(slotProps.item.created_at,'ddd, MM-DD-YYYY h:mm A') }}<br>
                                       <b class="fw-500">Requested: </b>{{ $filters.displayDateTimeLocal(slotProps.item.request_date,'','',"ddd, MM-DD-YYYY",true) }}<br>
                                       <b class="fw-500">Processed: </b>{{ $filters.displayDateTimeLocal(slotProps.item.processed_date,'','',"ddd, MM-DD-YYYY",true) }}
                                    </td>
                                    <td>{{ capitalizeFirstLetter(slotProps.item.provider_name) }}</td>
                                    <td>
                                       <div class="d-flex" v-if="permissioncheck(this.$store.state.permission.create_update)">
                                          <Popper arrow content="Send Amendment Document to patient portal" :hover="true" class="helptips cursor-pointer">
                                             <a v-if="slotProps.item.status == 'Accepted'" @click="senddocument(slotProps.item.id)" href="javascript:void(0)" class="waves-effect waves-circle btn btn-circle btn-info btn-xs"><i class="fa fa-send"></i></a>
                                          </Popper>
                                          <Popper arrow content="Send Clinical Note with Amendment to patient portal" :hover="true" class="helptips cursor-pointer">
                                             <a v-if="slotProps.item.status == 'Accepted'" @click="sendnots(slotProps.item.clincal_id)" href="javascript:void(0)" class="waves-effect waves-circle btn btn-circle btn-info btn-xs"><i class="fa fa-share"></i></a>
                                          <div v-else>NA</div>
                                          </Popper>
                                       </div>
                                    </td>
                                 </template>
                              </table-base>
                           </div>

                           <div v-if="permissioncheck(this.$store.state.permission.create_update)" class="attach-record_required mt-10">
                              <div class="pageheading">
                                 <h3>Attach a new amendment document to this patient record. All fields are required.</h3>
                              </div>


                              <form novalidate class="form-control_input">
                                 <file-upload
                                    id="document_name"
                                    title="Amendment Document"
                                    v-model="v$.form.document_name.$model"
                                    :errors="v$.form.document_name.$errors"
                                    :isRequired="true"
                                    notes ="PDF files only."
                                    labelClass="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end"
                                    divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3"
                                    v-if="this.$route.name != 'PatientAmendmentsedit'"
                                 ></file-upload>               

                                 <input-field
                                    title="Amendment Source"
                                    id="amendment_source"
                                    v-model="v$.form.source.$model"
                                    :errors="v$.form.source.$errors"
                                    note ="Where this amendment originated (patient, provider, etc)"
                                    labelClass="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end"
                                    divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3"
                                    :isRequired="true"
                                 >
                                 </input-field>

                                 <select-box-static
                                    title="Status"
                                    v-model="v$.form.status.$model"
                                    :errors="v$.form.status.$errors"
                                    :isRequired="true"
                                    :option="Status"
                                    labelClass="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end"
                                    divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3"
                                    note ="Whether you accept or deny this amendment to the patient's record."
                                 ></select-box-static>

                                 <text-area 
                                    title="Comments/Notes"
                                    v-model="form.comments"
                                    labelClass="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end"
                                    divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3"
                                    notes ="Description or comments regarding this amendment, status, etc."
                                 >
                                 </text-area>

                                 <div class="form-group row" max="2021-09-26">
                                    <label class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end">Request Date</label>
                                    <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3 availdate">
                                       <div class="controls">
                                          <!-- <input v-model="form.request_date" id="Request Date" type="date" name="text" class="form-control" placeholder="Request Date" :max="this.current_date"> -->
                                          <ejs-datepicker 
                                             :value="request_date" 
                                             id="request_date" 
                                             :format="dateFormat" 
                                             placeholder="select Request Date"
                                             @change="getdate" 
                                             :strictMode='strict' 
                                             :max='maxVal' 
                                             :showClearButton='false' 
                                             :blur="getdate"
                                             :openOnFocus='true'>
                                          </ejs-datepicker>
                                       </div>
                                    </div>
                                    <div class="col-12 col-sm-8 col-md-8 offset-sm-4 offset-lg-0 col-lg-4 col-xl-5">
                                       <span class="form-text text-muted align-sub">The date on which the patient or other source requested the amendment.</span>
                                    </div>
                                 </div>

                                 <div class="form-group row">
                                    <label class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end">Processed Date</label>
                                    <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3 availdate">
                                       <div class="controls">
                                          <!-- <input v-model="form.processed_date" id="Request Date" type="date" name="text" class="form-control" placeholder="Request Date" :max="this.current_date"> -->
                                          <ejs-datepicker 
                                             :value="processed_date" 
                                             id="processed_date" 
                                             :format="dateFormat" 
                                             placeholder="select Request Date"
                                             @change="getprocesseddate" 
                                             :strictMode='strict' 
                                             :max='maxVal' 
                                             :showClearButton='false' 
                                             :blur="getprocesseddate"
                                             :openOnFocus='true'>
                                          </ejs-datepicker>
                                       </div>
                                    </div>
                                    <div class="col-12 col-sm-8 col-md-8 offset-sm-4 offset-lg-0 col-lg-4 col-xl-5">
                                       <span class="form-text text-muted align-sub">The date on which you reviewed the amendment request.</span>
                                    </div>
                                 </div>

                              </form>
                              <div class="row">
                                 <div class="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9 text-center">
                                     <form-btn title="Save" btnCls="waves-effect waves-light btn btn-info" @click="save(false)" ></form-btn>
                                     <form-btn title="Cancel" @click="resetForm()" btnCls="waves-effect waves-light btn btn-danger"></form-btn>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>

</template>
<script>
import FileUpload from "@/components/base/formFields/fileUpload.vue";
import InputField from "@/components/base/formFields/InputField.vue";
import SelectBoxStatic from "@/components/base/formFields/SelectBoxStatic.vue";
import TextArea from "@/components/base/formFields/textArea.vue";
import DatePicker from "@/components/base/formFields/datePicker.vue";
import FormBtn from "@/components/base/formFields/formBtn.vue";
import TableBase from "@/components/base/Table.vue";
import moment from "moment";
import useVuelidate from "@vuelidate/core";
import submitMixins from "@/scripts/mixins/submitMixins.js";
import axios from "@/scripts/axios.js";
import permissionMixins from "@/scripts/mixins/permission.js";
import SelectBox from "@/components/base/formFields/selectBox.vue";
import ClinicalNotePreview from '@/components/patients/details/ClinicalNote/PDF.vue';
import Popper from "vue3-popper";

export default {
   mixins: [submitMixins,permissionMixins],
   setup: () => {
      return { v$: useVuelidate() };
   },

   components: {
      FileUpload,
      InputField,
      TextArea,
      TableBase,
      SelectBoxStatic,
      DatePicker,
      FormBtn,
      SelectBox,
      ClinicalNotePreview,
      Popper,
   },

   computed: {
      form() {
         return this.$store.state.Amendments.form;
      },
      vuelidateExternalResults() {
         return this.$store.state.Amendments.vuelidateExternalResults;
      },
      validationRules() {
         return this.$store.state.Amendments.validationRules;
      },
   },

   async created() {
      window.addEventListener('scroll', this.handleScroll);
    },
   async beforeCreate() {

      var hash = location.href.substring(location.href.lastIndexOf('/')+2);
      this.$store.state.clinicalNote.active_note = 'amendment';
      let $this = this;
      axios
          .post('/json_dropdown/patient-amendments-appointments',{patient_id:this.$store.state.patientDetail.patient_id})
          .then((response) => {
            $this.appointmentData = response.data.data,
            $this.masterId = (response.data.data.length > 0) ? response.data.data[0].clinical_note_id : '';
            if(response.data.data.length == 0){
               this.amedment_note="Note : Any clinical notes not available.";
            }
      })
   // clear form data since, the same data is being used for add and edit both
      if (this.$route.name === "PatientAmendments") {
         this.$store.state.Amendments.form = {};
         Object.assign(
         this.$store.state.Amendments.form,
         );
      } else if (this.$route.name === "PatientAmendmentsedit") {
         if (this.$store.state.Amendments.form.id != this.$route.params.id) {
            await this.$store.dispatch("getEditDataForMultipalId", {
               dataType: "Amendments", // pass the module name
               formType: "form", // pass the propery name
               id: this.$route.params.id,
               id2: this.$route.params.id2,
            });
            if(this.form.processed_date != null){
               this.processed_date = moment(String(this.form.processed_date)).format('MM-DD-YYYY');
            }
            if(this.form.request_date != null){
               this.request_date = moment(String(this.form.request_date)).format('MM-DD-YYYY');
            }
         }
      }
   },
   mounted(){
      var length = $('#appointment_id > option').length
      
      var d = new Date();
      var month = d.getMonth()+1;
      var day = d.getDate();

      this.current_date = d.getFullYear() + '-' +
          ((''+month).length<2 ? '0' : '') + month + '-' +
          ((''+day).length<2 ? '0' : '') + day;

      var thisObj = this;
      /*axios.post('/json_dropdown/patient-amendments-appointments',{patient_id: this.$store.state.patientDetail.patient_id})
      .then(response => {
         if(response.data.data.length == 0){
            this.amedment_note="Note : Any clinical notes not available.";
         }
         else
         {
            setTimeout(function(){
               var hash = location.href.substring(location.href.lastIndexOf('/') + 2);
               if(location.href.substring(location.href.lastIndexOf('/') + 1)!='amendments')
               {           
                   thisObj.hash = hash;   
                   thisObj.masterId = $("select#appointment_id option[data-app-val='"+hash+"']").attr("data-val");           
                   $("select#appointment_id").val($("select#appointment_id option[data-app-val='"+hash+"']").attr("value"));  
                   thisObj.form.appointment_id = $("select#appointment_id option[data-app-val='"+hash+"']").attr("value");
                   thisObj.notePreview = 1;
               }
            }, 100);

         }
       });*/
   },
   methods: {
      getdate(){
         if($('#request_date" ').val() == ''){
            this.form.request_date = null;
         }else{
            this.request_date = $('#request_date" ').val();
            this.form.request_date = moment(String(this.request_date)).format('YYYY-MM-DD');
         }
      },
      getprocesseddate(){
         if($('#processed_date" ').val() == ''){
            this.form.processed_date = null;
         }else{
            this.processed_date = $('#processed_date" ').val();
            this.form.processed_date = moment(String(this.processed_date)).format('YYYY-MM-DD');
         }
      },
      sendnots(id){
         axios.post('patient-portal/clinicalDocuments',{type:'clinical_note',id:id})
         .then(response => {
            this.moshaToast('Record Updated successfully.','success');
          });
      },
      senddocument(id){
         axios.post('patient-portal/clinicalDocuments',{type:'amendments',id:id})
         .then(response => {
            this.moshaToast('Record Updated successfully.','success');
          });
      },
      changeTab(components)
      {
         this.$store.state.patientDetailsComp = components;
         let props = this.$router.resolve({ 
           name: components,
           params: { id: this.$route.params.id},
         });
         if (history.pushState && props) {
            if(this.hash=='' || this.hash==null || typeof this.hash === undefined || typeof this.hash === 'undefined' || components == 'PatientLockedClinicalNotes')
            {
                history.pushState(null, '',props.href);
            }
            else
            {
                history.pushState(null, '',props.href+"/#"+this.hash);
            }
         }
      },  
      changeAppointment()
      {
         this.amedment_note ='';
         this.form.appointment_id = $("select#appointment_id").val();
         this.masterId = $("select#appointment_id").find('option:selected').attr("data-val");
         this.notePreview = 1;

         /*URL hash update*/
         this.hash = $("select#appointment_id option[value='"+this.appointment_id+"']").attr("data-app-val");
         
         var newUrl = (location.href.substring(location.href.lastIndexOf('/') + 1)!='amendments') ? location.href.substring(0, location.href.lastIndexOf('/')) : location.href;
         history.pushState(null, '',newUrl+"/#"+this.hash);              
         /*URL hash update - new*/            

         var dataTypes = {dataType: 'Amendments', patient_id: this.$store.state.patientDetail.patient_id,patient_unique_id:this.$store.state.patientDetail.patient_unique_id,appointment_id:this.form.appointment_id};
         this.$store.dispatch("getSimpleTableContents", dataTypes);
      },
      FileDisplay(fileName, e){
         $('<img src="images/loader.gif" class="ms-1">').insertAfter(e.target);
         axios
           .get(process.env.VUE_APP_API_BASE_URL+'/note-amendments/'+fileName)
           .then((res) => {
               e.target.nextElementSibling.remove();
               window.open(res.data,'_blank');
         });
      },
      formatDate(date, format) {
         return moment(date).format(format);
      },
      utcformat(date, format) {
         return moment(date).utcOffset(0).format(format);
      },
      capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      },
      save(next) {
         this.postForm(`/patient/${this.$route.params.id}/amendments-document/store`,"Amendments",true,'formdata');
      },
      formPostSuccess(data) {
         this.moshaToast('Record added successfully.','success');
         if (this.$route.name === "PatientAmendments") {
            this.$router.push(`/patient-chart/${this.$route.params.id}/clinical-note/amendments`);   
         }
         else{
            this.$router.push(`/patient-chart/${this.$route.params.id}/clinical-note/amendments`);   
         }         
         /*setTimeout(function(){ location.reload(); }, 500);*/
      },
      edit(id){
         this.$router.push(`/patient-chart/${this.$route.params.id}/clinical-note/amendments/${id}`);
      },
      resetForm() {
          var self = this;
          
          Object.keys(this.form).forEach(function(key,index) {
            self.form[key] = '';
          });
          this.form.comments = '';
          this.v$.$reset();
          $("textarea[name=textarea]").val("")
      },
      _UIdentiTy(){
         return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
             (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
           );
      },
      PreviewNote()
      {        
         this.$store.state.clinicalNote.appointment_id = $("#appointment_id").val();
         var app = $('#appointment_id :selected').text(),
              data = app.split(' '),dt = data[0],
              newDat = this.$filters.inputDateTime(dt,'YYYY-MM-DD');

         this.$store.state.clinicalNote.appointment_date = newDat;
         this.$store.state.clinicalNote.clinical_note_id = this.masterId+"-"+this._UIdentiTy();;

         this.$store.state.clinicalNote.active_note_component = 'PatientAmendments';
         this.$store.state.patientDetailsComp = ClinicalNotePreview;               
      }
      
   },
   data() {
      return {
         url:'Loading Please wait...',
         appointment_id:"",
         hash: '',
         masterId: '',
         notePreview: '',
         appointmentData: '',
         amedment_note:'Note :  Select appointment to attach the amendments.',
         amedment_note_reander: true,
         reander:'true',
         current_date:'',
         patient_id : this.$store.state.patientDetail.patient_id,
         patient_unique_id:this.$store.state.patientDetail.patient_unique_id,
         Status: [
           {value:"Accepted", title:"Accepted"},
           {value:"Rejected", title:"Rejected"},
         ],
         strict: true,
         maxVal: new Date(),
         dateFormat: 'MM-dd-yyyy',
      };
   },
   validations() {
      return this.$store.state.Amendments.validationRules;
   },
};
</script>