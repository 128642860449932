<template>
	<div class="content-wrapper">
		<div class="container-full">
			<div class="content-header">
				<h1 class="heading1 mt-0">Unmatched ERA Report <a href="#" data-bs-toggle="modal" data-bs-target="#unmatcheddocument" title="Reference of Knowledge Base" v-on:click.prevent="reset()"> <i class="fa fa-question-circle fs-20"></i></a></h1>
			</div>
			<div class="box p-10 mb-10">
				<div class="row">
					<div class="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5">
						<div class="form-group row mb-5">
							<label class="col-form-label mb-0 text-md-end col-12 col-sm-12 col-md-4 col-lg-7 col-xl-6">Unmatched ERA Range Type</label>
							<div class="col-12 col-sm-12 col-md-8 col-lg-5 col-xl-6 availdate">
								<select id="Status" v-model="filter.range_of" class="form-select form-control">
									<option value="DOS">Date of Service</option>
									<option value="Received">ERA Received</option>
								</select>
							</div>
						</div>
					</div>
					<div class="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-4">
						<div class="form-group row mb-5">
							<label class="col-form-label mb-0 text-md-end col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2">Date</label>
							<div class="col-12 col-sm-12 col-md-8 col-lg-10 col-xl-10 availdate">
								<ejs-daterangepicker format='MM-dd-yyyy' id="date_range" :value="date" 
								v-model="filter.date_range" strictMode='strict'
								placeholder="Select Date Range"  @change="onRangeSelect"></ejs-daterangepicker>
							</div>
						</div>
					</div>
					<div class="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 text-center text-lg-start">
						<a @click="get_list" class="waves-effect waves-light btn btn-info-light">Search</a>
					</div>
				</div>
			</div>
			<div class="box p-10 mb-10">
				<div class="table-responsive">
					<data-table-server dataType="UnmatchedERA" dataMethod="post" :key="key">
						<template v-slot="slotProps">
							<td>
								<a href="javascript:void(0);" title="Details" @click="get_details(slotProps.item.id)"> {{ slotProps.item.claim_number.substring(slotProps.item.claim_number.indexOf('I') + 1) }} </a>
							</td>
							<td>{{ formatDate(slotProps.item.schedule_date,"MM/DD/YYYY") }}</td>
							<td>{{ formatDate(slotProps.item.created_at,"MM/DD/YYYY") }}</td>
							<td>
								<slot v-if="slotProps.item.claim_requested_data!=null && JSON.parse(slotProps.item.claim_requested_data).payerName!=undefined"> 
									{{ JSON.parse(slotProps.item.claim_requested_data).payerName }} 
								</slot>  
								<slot v-else>-</slot>
							</td>
							<td>
								<slot v-if="slotProps.item.total_claimed_accepted_amount!=null">
									${{ slotProps.item.total_claimed_accepted_amount }}
								</slot>
								<slot v-else>
									-
								</slot>
							</td>
							<td>
								<slot v-if="slotProps.item.adjustment_amount!=null">
									${{ slotProps.item.adjustment_amount }}
								</slot>
								<slot v-else>
									-
								</slot>
							</td>
							<td>
								<slot v-if="slotProps.item.adjustments_codes!=null">
									<slot :set="reason_code=slotProps.item.adjustment_reason_code.split(',')"></slot>
									<slot :set="co_array=[]"></slot>
									<slot :set="oa_array=[]"></slot>
									<slot :set="pi_array=[]"></slot>
									<slot v-for="(value,index1) in slotProps.item.adjustments_codes.split(',')" :key="index1">
										<slot :set="aj_detail={grp_code:value+' : '+claim_adjustment_group_codes[value],reason_code:reason_code[index1]+' : '+claim_adjustment_codes[reason_code[index1]] }"></slot>
										<slot v-if="value=='CO'" :set="co_array.push(reason_code[index1]+' : '+claim_adjustment_codes[reason_code[index1]])"></slot>
										<slot v-if="value=='OA'" :set="oa_array.push(reason_code[index1]+' : '+claim_adjustment_codes[reason_code[index1]])"></slot>
										<slot v-if="value=='PI'" :set="pi_array.push(reason_code[index1]+' : '+claim_adjustment_codes[reason_code[index1]])"></slot>
									</slot>
									<slot v-if="co_array.length>0">
										<Popper arrow :hover="true" class="helptips cursor-pointer">
											<div class="div-circle me-1">CO</div>
											<template #content>
												<div>CO : Contractual Obligations</div>
												<hr style="margin: 0rem 0;">
												<slot v-for="(value,index1) in co_array" :key="index1">
													<div>{{value+' : '+claim_adjustment_codes[value]}}</div>
												</slot>
											</template>	
										</Popper>
									</slot>
									<slot v-if="oa_array.length>0">
										<Popper arrow :hover="true" class="helptips cursor-pointer">
											<div class="div-circle me-1">OA</div>
											<template #content>
												<div>OA : Other adjustments</div>
												<hr style="margin: 0rem 0;">
												<slot v-for="(value,index1) in oa_array" :key="index1">
													<div>{{value+' : '+claim_adjustment_codes[value]}}</div>
												</slot>
											</template>
										</Popper>
									</slot>
									<slot v-if="pi_array.length>0">
										<Popper arrow :hover="true" class="helptips cursor-pointer">
											<div class="div-circle me-1">PI</div>
											<template #content>
												<div>PI : Payor Initiated Reductions</div>
												<hr style="margin: 0rem 0;">
												<slot v-for="(value,index1) in pi_array" :key="index1">
													<div>{{value+' : '+claim_adjustment_codes[value]}}</div>
												</slot>
											</template>
										</Popper>
									</slot>
								</slot>
							</td>
							<td>
								<a href="javascript:void(0);" title="Details" @click="get_details(slotProps.item.id)"> <i class="fa fa-eye"></i> </a> 
							</td>
						</template>
					</data-table-server>
				</div>
			</div>
			<div class="modal" tabindex="-1" id="Detailmodal">
				<div class="modal-dialog modal-xl">
					<div class="modal-content">
						<div class="modal-header">
							<h3 class="modal-title font-bold m-0">Details</h3>
							<button type="button" class="btn-close btn-xs" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div class="modal-body" id="DetailBody">

						</div>
					</div>
				</div>
			</div>
			<!--document model start-->
			<div id="unmatcheddocument" class="modal fade" tabindex="-1" role="dialog"
				aria-labelledby="myModalLabel" aria-hidden="true">
				<div class="modal-dialog modal-md" style="max-width: 600px;">
					<div class="modal-content">
					<div class="modal-header">
					<h3 class="modal-title fw-900 mt-0">Reference of Knowledge Base</h3>
					</div>
					<div class="modal-body overflow-auto" style="max-height: 350px;">
					<table class="table table-bordered">
						<thead>
							<tr>
							<th scope="col">No.</th>
							<th scope="col">Knowledge Base Articles</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>1</td>
								<td> <a :href="path+'how-to-check-unmatched-era-in-pureehr'" target="_blank">How To Check Unmatched ERA In PureEHR?</a></td>
							</tr>
						</tbody>
					</table>
					<Search :key="searchKey"></Search>
					</div>
					<div class="modal-footer">
					<button type="button" @click="" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
					</div>
					</div>
				</div>
			</div>
			<!--document model end-->
		</div>
	</div>
</template>

<script>
	import moment from 'moment';
	import axios from "@/scripts/axios.js";
	import permissionMixins from "@/scripts/mixins/permission.js";
	import submitMixins from "@/scripts/mixins/submitMixins.js";
	import Popper from "vue3-popper";
	import Search from "@/components/Search.vue";
	import DataTableServer from "@/components/base/DataTableServer.vue";
	export default {
		mixins: [permissionMixins,submitMixins],
		components: { Popper, Search, DataTableServer },
		computed: {
			filter() {
				return this.$store.state.UnmatchedERA.filter;
			},
		},
		data() {
			return {
				key: 0,
				list:[],
				claim_adjustment_codes:[],
				claim_adjustment_group_codes:[],
				date : '',				
				path: process.env.VUE_APP_KB_DOCUMENT,
				searchKey:0,
			}
		},
		methods: {
			reset() {
				this.searchKey = this.searchKey + 1;
			},
			formatDate(date, format) {
				if(date != null && date != ''){
					return moment(date).format(format);
				}else{
					return '-';
				}
			},
			onRangeSelect() {
				var daterange = document.getElementById("date_range").value;
				if(daterange!='' && daterange!=null){
					var date = daterange.split(' - ');
					this.filter.start_date = moment(String(date[0])).format('YYYY-MM-DD');
					this.filter.end_date = moment(String(date[1])).format('YYYY-MM-DD');
				}else{
					this.filter.start_date = null;
					this.filter.end_date = null;
				}
			},
			get_list() {
				this.key = this.key + 1;
			},
			get_details(id){
				axios.post("/reports/unmatchedERA-details",{id:id})
				.then((res) => {
					$("#Detailmodal").modal("show");
					$("#DetailBody").html(res.data.data);
				});
			},			
		},
		mounted() {
			this.date = moment().subtract(1, 'months').format("MM-DD-YYYY")+' - '+moment(new Date()).format("MM-DD-YYYY");
			axios.post("/getCARCodes")
			.then((res) => {
				this.claim_adjustment_codes = res.data.data.claim_adjustment_codes;
				this.claim_adjustment_group_codes = res.data.data.claim_adjustment_group_codes;
			});
		},
	}
</script>