<template>
  <div class="content-wrapper">
    <div class="container-full">
      <div class="content-header">
        <div class="row justify-content-between">
          <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xs-6">
            <h1 class="heading1 my-5">Referral Management</h1>
          </div>
          <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xs-6 text-sm-end">
            <router-link to="/referral/add" class="waves-effect waves-light btn btn-info mb-1"><i class="fa fa-plus fs-14"></i> Referral</router-link>
          </div>
        </div>
      </div>
      <section class="content">
        <div class="box">
          <div class="box-body">
            <div id="referral">
              <center>
                <span class="isvalid-feedback" style="display:block;">{{
                  success
                }}</span>
              </center>
              <data-table-server dataType="Referral" dataMethod="post" :key="key">
							<template v-slot="slotProps">			
								<td v-if="slotProps.item.user_name_type != null">{{slotProps.item.user_name_type}}</td>
                  <td v-else>--</td>
                  <td>{{ slotProps.item.referral_name }}</td>
                  <td>{{ slotProps.item.added_from }}</td>
                  <td>
                    <div class="d-flex">
                      <Popper arrow content="Edit Referral" :hover="true" class="helptips cursor-pointer">
                        <a @click="edit(slotProps.item.id)" href="javascript:void(0)" class="waves-effect waves-circle btn btn-circle btn-info btn-xs me-1">
                      	 <i class="fa fa-pencil"></i>
                        </a>
                      </Popper>
                      <Popper arrow content="Delete Referral" :hover="true" class="helptips cursor-pointer mt-0">
                        <a  @click="deleteItem(slotProps.item.id)" href="javascript:void(0)" class="waves-effect waves-circle btn btn-circle btn-danger btn-xs" title="Delete Referral">
                      	<i class="fa fa-trash"></i>
                      </a>
                        </Popper>
                        <Popper arrow content="Sync Referral" :hover="true" class="helptips cursor-pointer mt-0" v-if="slotProps.item.is_sync == 0">
                          <a  @click="syncItem(slotProps.item.id)" href="javascript:void(0)" v-if="slotProps.item.is_sync == 0" class="waves-effect waves-circle btn btn-circle btn-success btn-xs " title="Sync Referral">
                            <i class="fa fa-refresh"></i>
                          </a>
                        </Popper>
                    </div>
                  </td>
							</template>
						</data-table-server>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import axios from "@/scripts/axios.js";
import Popper from "vue3-popper";
import $ from 'jquery';
import submitMixins from "@/scripts/mixins/submitMixins.js";
import DataTableServer from "@/components/base/DataTableServer.vue";
export default {
  name: "Referral",
  components: { DataTableServer, Popper },
  mixins: [submitMixins],  
  data() {
    return {
     test: 'test'
    }
  },
  mounted(){
      
  },
  methods:{
    edit(id){
      this.$router.push("/referral/edit/"+id);
    },
    deleteItem(id) {
      this.$swal
        .fire({
          title: '<h2 class="mt-0">Are you sure?</h2>',
          text:
            "You want to delete these record?",
          icon: "warning",
          dangerMode: true,
          showCancelButton: true,
          confirmButtonColor: "#ee3158",
          cancelButtonColor: "#bdbdbd",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed == true) {
            axios
              .post(`/referral/delete`, { referral_id: id })
              .then((response) => {
                this.$swal
                  .fire(
                    "Deleted!",
                    "Referral has been deleted.",
                    "success"
                  )
                  .then((result) => {
                    if (result.isConfirmed == true) {
                      location.reload();
                    }
                  });
              });
          }
        });
    },
    syncItem(id) {
      axios
        .post(`/ehr-pwm-sync-referral`, { referral_id: id })
        .then((response) => {
          var resp = response.data;
          if(resp.status == "success") {
            this.moshaToast(resp.message, "success");
          }
          if (resp.status == "error") {
            this.moshaToast(resp.message, "danger");
          }
          window.setTimeout(function() {
							location.reload();
          }, 2000);
        });
    }
  },
};
</script>
