const supportIndex = {
    namespaced: true,
    state: {
        listUrl: "/support/list",
        tableData: {
            header: [
                "Subject",
                "Status",
                "Time",
            ],
        },
        defaultFormData: {
            master_id:"",
            subject: "",
            status: "",
            time: "",
        },
        list: [],
    },
    mutations: {},
    
};

export default supportIndex;
