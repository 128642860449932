<template>
	<div class="content-wrapper">
		<div class="container-full">
			<div class="content-header">
				<div class="row">
					<div class="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-3">
						<div class="box box-info text-white rounded5" style="background-color: #0265a0 !important;">
							<div class="box-body py-10 px-10 rounded5">
								<div class="d-sm-flex align-items-center text-sm-start text-center">
									<div class="me-sm-15 mb-5">
										<!-- <img :src="provider_img" alt="D.O. David Suarez" title="D.O. David Suarez" class="w-70 b-3 rounded50 bg-white" > -->
										<slot v-if="user">
											<img :src="user.profile_avatar_image" v-if="user.profile_avatar_image!=''" alt="" title="" class="w-70 b-3 rounded50 bg-white">
											<div v-if="user.profile_avatar_image==''" class="profname d-inline-block fw-900 fs-22 w-50 text-white h-50 rounded50 text-center b-2" style="padding-top: 10.5px;" :style="{'background-color':user.profile_letter_color}">{{ user.profile_letter }}</div>
										</slot>

									</div>
									<div class="d-sm-flex w-p100 align-items-center justify-content-between">
										<div class="">
											<span class="mb-0 fw-400">Welcome!</span>
											<h2 class="m-0 fw-600 fs-18">
												{{ this.user ? this.user.salutation : ""}}
												{{ this.user ? `${this.user.first_name} ${this.user.last_name}` : ""}}
												{{ this.user ? this.user.suffix : ""}}
											</h2>
											 <!-- <p class="mb-0 fs-16">Have a Good Day!</p> -->
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="box">
							<div class="box-body p-0">
								<ejs-calendar @change="changeDate" :value="selectedDate" style="width:100%;max-width: 100%;border: 0;border-radius: 5px;"></ejs-calendar>
							</div>
						</div>
						<div>
						<div class="box-header bg-info-light py-2">
							<h2 class="my-5 fs-16 text-dark">Upcoming Leaves</h2>
						</div>
						<div class="table-responsive">
							<table class="table fs-16">
								<thead>
									<tr>
									<th>User</th>
									<th style="width: 103px;">Leave Days</th>
									<th>Date Range</th>
									</tr>
								</thead>
								<tbody v-if="this.upcoming_leave.length>0">
									<tr v-for="(item, index) in this.upcoming_leave" :key="index">
										<td>{{item.username}}</td>
										<td>{{item.Days}}</td>
										
										<td v-if="item.from_date==item.to_date">{{item.from_date}}</td>
										<td v-else>{{item.from_date}} To {{item.to_date}}</td>
									</tr>
								</tbody>
								<tbody v-else>
									<tr class="text-center">
										<th colspan="3">No any upcoming leave</th>
									</tr>
									
								</tbody>
							</table>
						</div>
					</div>
					</div>
					
					<div class="col-12 col-sm-12 col-md-7 col-lg-7 col-xl-5">
						<div class="box">
							<div class="box-header badge-info-light py-3">
								<h2 class="heading2 fs-16 mb-0 text-dark">Today's Appointments 
									<span class="float-end fs-16 fw-500">{{currentDate()}}
										<div class="dropdown d-inline-block ms-2">
											<a class="text-info" href="#" data-bs-toggle="dropdown" aria-expanded="false" title="appointment print">
												<i class="fa fa-ellipsis-v	"></i>
											</a>
											<div class="dropdown-menu dropdown-menu-end overflow-hidden">
											<a class="dropdown-item py-10" data-bs-toggle="modal" data-bs-target="#printApptModal" @click="printApptData('pdf')" href="#"  title="print"><i class="fa fa-print"></i> Print</a>
											<a class="dropdown-item py-10" @click="printApptData('excel')" href="#" title="xls"><i class="fa fa-download"></i> Download</a>
											</div>
										</div>  
									</span>
								</h2>
								
							</div>
							<div class="box-body">
								<div class="appointments_dash userprovid_scroll">
									<div v-for="(item,index) in today_appointment_list" :key="index" class="mb-10 d-flex justify-content-between align-items-center py-10 px-10 rounded5 appo_patient bg-white b-1">
										
										<div class="w-p100 justify-content-between align-items-center d-flex">
											<div class="d-flex justify-content-between align-items-center">
												<img v-if="item.profile_avatar" :src="item.profile_avatar_url" class="me-10 avatar rounded10" alt="">
												<img v-else src="images/patient-avtar.png" class="me-10 avatar rounded20 w-auto" alt="">
												<div>
													<h6 class="mb-0">
														<router-link :to="'/patient-chart/'+item.unique_id" class="fs-16 fw-600">{{item.patient_name}}</router-link>
													</h6>
													<p class="mb-0 fs-14 text-dark">Diagnosis: {{item.reason_for_visit}}</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="box">
							<div class="box-header badge-success-light py-2">
								<div class="row align-items-center">
									<div class="col-12 col-sm-8 col-md-6 col-lg-6">
										<h2 class="my-5 fs-16 text-dark">Payment Transactions <a href="#" data-bs-toggle="modal" data-bs-target="#paymentdocument" title="Reference of Knowledge Base" v-on:click.prevent="reset()"> <i class="fa fa-question-circle"></i></a></h2>
									</div>
									<div class="col-12 col-sm-4 col-md-6 col-lg-6">
										<div class="availdate">
											<ejs-datepicker :value="paymenttransactionsdate" id="start_date" :format="dateFormat"  :placeholder="waterMarkText" @change="getdate" :showClearButton='false' :blur="getdate" name="start_date" :strictMode='strict' :max='maxVal' :openOnFocus='true'></ejs-datepicker>
										</div>
									</div>
								</div>
							</div>
							<div class="box-body">
								<div class="table-responsive">
									<table class="table fs-16">
										<thead>
											<tr>
												<th>Patient Name</th>
												<th>Charge Amount</th>
												<th>Payment Status</th>
											</tr>
										</thead>

										<tbody>
											<slot v-if="paymenttransactions.length > 0">
												<tr v-for="(item,index) in paymenttransactions" :key="index">
													<td>
														<router-link :to="{name: 'BillingContract',params: { id: item.unique_id },}" :title="'View Chart of ' +item.patient_name +'(' +item.chart_id +')'" class="fw-500">{{item.patient_name}}</router-link>
													</td>
													<td>{{item.charge_amount}}</td>
													<td>
														<span v-if="item.payment_status == 'Approved'" class="font-bold text-success">{{item.payment_status}}</span>
														<span v-else class="font-bold text-danger">{{item.payment_status}}</span>
													</td>
												</tr>
											</slot>
											<slot v-else>
												<tr>
													<td colspan="3" class="text-center">No data to display</td>
												</tr>
											</slot>
										</tbody>
									</table>
								</div>
							</div>
						</div>
						<div class="box">
							<div class="box-header badge-success-light py-2">
								<div class="row align-items-center">
									<div class="col-12 col-sm-8 col-md-6 col-lg-6">
										<h2 class="my-5 fs-16 text-dark">Upcoming Payment Transactions <a href="#" data-bs-toggle="modal" data-bs-target="#upcomingpaymentdocument" title="Reference of Knowledge Base" v-on:click.prevent="reset()"> <i class="fa fa-question-circle"></i></a></h2>
									</div>
									<div class="col-12 col-sm-4 col-md-6 col-lg-6">
										<div class="availdate">
											<ejs-datepicker :value="upcomingdate" id="upcoming_date" :format="dateFormat"  :placeholder="waterMarkText" @change="getupcoming" :showClearButton='false' :strictMode='strict' :openOnFocus='true'></ejs-datepicker>
										</div>
									</div>
								</div>
							</div>
							<div class="box-body">
								<div class="table-responsive">
									<table class="table fs-16">
										<thead>
											<tr>
												<th>Patient Name</th>
												<th>Charge Amount</th>
											</tr>
										</thead>

										<tbody>
											<slot v-if="upcoming.length > 0">
												<tr v-for="(item,index) in upcoming" :key="index">
													<td>
														<router-link :to="{name: 'BillingContract',params: { id: item.unique_id },}" :title="'View Chart of ' +item.patient_name +'(' +item.chart_id +')'" class="fw-500">{{item.patient_name}}</router-link>
													</td>
													<td>{{item.charge_amount}}</td>
												</tr>
											</slot>
											<slot v-else>
												<tr>
													<td colspan="2" class="text-center">No data to display</td>
												</tr>
											</slot>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
					
					<div class="col-12 col-sm-12 col-md-12 col-lg-7 col-xl-4" v-if="permissioncheck(this.$store.state.permission.CashBilling) || permissioncheck(this.$store.state.permission.Claims) || permissioncheck(this.$store.state.permission.InsuranceBilling) || permissioncheck(this.$store.state.permission.Patient)">
						<div class="box" v-if="permissioncheck(this.$store.state.permission.CashBilling)">
							<div class="box-header bg-info-light py-2">
								<div class="row align-items-center">
									<div class="col-12 col-sm-8 col-md-6 col-lg-6">
										<h2 class="my-5 fs-16 text-dark">Daily Cash Billing </h2>
									</div>
									<div class="col-12 col-sm-4 col-md-6 col-lg-6">
										<div class="availdate">
											<ejs-datepicker :value="cashdate" id="cash_date" :format="dateFormat"  :placeholder="waterMarkText" @change="getCashdate" :showClearButton='false' :strictMode='strict' :max='maxVal' :openOnFocus='true'></ejs-datepicker>
										</div>
									</div>
								</div>
							</div>
							<div class="box-body">
								<div class="table-responsive">
									<table class="table fs-16">
										<tbody>
											<tr>
												<th class="fw-500">Total Health Care:</th>
												<td>
													<span class="text-success"> Added: {{dailyCash[0]}}</span>
												</td>
												<td>
													<span class="text-danger">Refunded: {{dailyCash[1]}}</span>
												</td>
											</tr>
											<tr>
												<th class="fw-500">Total POS:</th>
												<td>
													<span class="text-success">Added: {{dailyCash[2]}}</span>
												</td>
												<td>
													<span class="text-danger">Refunded: {{dailyCash[3]}}</span>
												</td>
											</tr>
											<tr>
												<th class="fw-500">Total Contract:</th>
												<td>
													<span class="text-success">Added: {{dailyCash[4]}}</span>
												</td>
												<td>
													<span class="text-danger">Refunded: 0.00</span>
												</td>
											</tr>
											<tr class="fw-600 badge-success-light">
												<th>Total:</th>
												<td>
													<span class="text-success">Added: {{dailyCash[5]}}</span>
												</td>
												<td>
													<span class="text-danger">Refunded: {{dailyCash[6]}}</span>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
						<div class="box" v-if="permissioncheck(this.$store.state.permission.Claims)">
							<div class="box-header bg-warning-light py-2">
								<div class="row align-items-center">
									<div class="col-12 col-sm-8 col-md-6 col-lg-6">
										<h2 class="my-5 fs-16 text-dark">Monthly Claims </h2>
									</div>
									<div class="col-12 col-sm-4 col-md-6 col-lg-6">
										<div class="availdate">
											<ejs-daterangepicker id="claim_date" :placeholder="waterMarkText"  :format="dateFormat" :openOnFocus='true' @change="getClaimToday()" :value="claimDates.daterange"></ejs-daterangepicker>
										</div>
									</div>
								</div>
							</div>
							<div class="box-body">
								<b class="fs-16 fw-500">No of claims: </b>{{dailyClaims['count']}}
							</div>
						</div>
						<div class="box" v-if="permissioncheck(this.$store.state.permission.InsuranceBilling)">
							<div class="box-header bg-info-light py-2">
								<div class="row align-items-center">
									<div class="col-12 col-sm-8 col-md-6 col-lg-6">
										<h2 class="my-5 fs-16 text-dark">Monthly Insurance Billing </h2>
									</div>
									<div class="col-12 col-sm-4 col-md-6 col-lg-6">
										<div class="availdate">
											<ejs-daterangepicker id="insurance_date" :placeholder="waterMarkText"  :format="dateFormat" :openOnFocus='true' @change="getInsuranceToday()" :value="insuranceDates.daterange"></ejs-daterangepicker>
											
										</div>
									</div>
								</div>
							</div>
							<div class="box-body">
								<b class="fs-16 fw-500">Amount: </b><small v-if="insuranceData!=null">${{insuranceData}}</small>
								<small v-else>0</small>
							</div>
						</div>
						<div class="box" v-if="permissioncheck(this.$store.state.permission.Patient)">
							<div class="box-header bg-warning-light py-2">
								<div class="row align-items-center">
									<div class="col-12 col-sm-8 col-md-6 col-lg-6">
										<h2 class="my-5 fs-16 text-dark">Number of Patient Added</h2>
									</div>
									<div class="col-12 col-sm-4 col-md-6 col-lg-6">
										<div class="availdate">
											<ejs-datepicker :value="patientdate" id="start_date_patient" :format="dateFormat"  :placeholder="waterMarkText" @change="getPatientDate" :showClearButton='false' :strictMode='strict' :openOnFocus='true'></ejs-datepicker>
										</div>
									</div>
								</div>
							</div>
							<div class="box-body">
								<div><b class="fs-16 fw-500">Total Patient: </b>{{patients.length}}</div>
							</div>
						</div>
					</div>
					

					<!-- payment document model start-->
					<div id="paymentdocument" class="modal fade" tabindex="-1" role="dialog"
						aria-labelledby="myModalLabel" aria-hidden="true">
						<div class="modal-dialog modal-md" style="max-width: 600px;">
							<div class="modal-content">
							<div class="modal-header">
							<h3 class="modal-title fw-900 mt-0">Reference of Knowledge Base</h3>
							</div>
							<div class="modal-body overflow-auto" style="max-height: 350px;">
							<table class="table table-bordered">
								<thead>
									<tr>
									<th scope="col">No.</th>
									<th scope="col">Knowledge Base Articles</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>1</td>
										<td> <a :href="path+'how-to-check-payment-transaction-status'" target="_blank">How To Check Payment Transaction Status?</a></td>
									</tr>
								</tbody>
							</table>
							<Search :key="searchKey"></Search>
							</div>
							<div class="modal-footer">
							<button type="button" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
							</div>
							</div>
						</div>
					</div>
					<!-- payment document model end-->
					<!-- upcoming payment document model start-->
					<div id="upcomingpaymentdocument" class="modal fade" tabindex="-1" role="dialog"
						aria-labelledby="myModalLabel" aria-hidden="true">
						<div class="modal-dialog modal-md">
							<div class="modal-content">
							<div class="modal-header">
							<h3 class="modal-title fw-900 mt-0">Reference of Knowledge Base</h3>
							</div>
							<div class="modal-body overflow-auto" style="max-height: 350px;">
							<table class="table table-bordered">
								<thead>
									<tr>
									<th scope="col">No.</th>
									<th scope="col">Knowledge Base Articles</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>1</td>
										<td> <a :href="path+'how-to-check-upcoming-payments'" target="_blank">How To Check Upcoming Payments?</a></td>
									</tr>
								</tbody>
							</table>
							<Search :key="searchKey"></Search>
							</div>
							<div class="modal-footer">
							<button type="button" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
							</div>
							</div>
						</div>
					</div>
					<!-- upcoming payment document model end-->
					<!--printAppt Modal Start-->
						<div class="modal" id="printApptModal" tabindex="-1">
							<div class="modal-dialog modal-md" style="max-width:75%">
								<div class="modal-content">
									<div class="modal-header">
										<button type="button" class="btn-close btn-xs" data-bs-dismiss="modal" aria-label="Close"></button>
									</div>
									<div class="modal-body">
										<vue-pdf-viewer height="1050px" :url="receipt_url" v-if="embed=0">
										</vue-pdf-viewer>
										<iframe :src="receipt_url" width="100%" height="500px" v-if="embed=1" />
										<img srd="images/1.gif" v-else> 
									</div>
								</div>
							</div>
						</div>
					<!--printAppt Modal end-->
			
				</div>
				
			</div>
		</div>
	</div>
</template>

<script>
	import Search from "@/components/Search.vue";
	import $ from "jquery";
	import submitMixins from "@/scripts/mixins/submitMixins.js";
	import permissionMixins from "@/scripts/mixins/permission.js";
	import axios from "@/scripts/axios.js";
	import moment from "moment";
	import { mapGetters, mapActions } from "vuex";
	import { CalendarComponent } from "@syncfusion/ej2-vue-calendars";
	import VuePDFViewer from 'vue-instant-pdf-viewer';

	export default {
		mixins: [submitMixins,permissionMixins],
		components: {
			"ejs-calendar": CalendarComponent,
			Search,
			'vue-pdf-viewer': VuePDFViewer,
		},
		name: "Home",
		data() {
			return {
				upcomingdate:'',
				upcoming:[],
				waterMarkText : 'Choose a date',
      			dateFormat: 'MM-dd-yyyy',
      			strict: true,
				paymenttransactions:[],
				paymenttransactionsdate:'',
				today_appointment_list:[],
				provider_img:'/images/patient-avtar.png',
				path: process.env.VUE_APP_KB_DOCUMENT,
				searchKey:0,
				dailyClaims:[],
				patients:[],
				patientdate:'',
				dailyCash:[],
				cashdate:'',
				insuranceData: [],
				upcoming_leave:[],
				claimDates: {
						start_date: moment().subtract(1, 'months').format("YYYY-MM-DD"),
					end_date:moment().add(1, 'days').format("YYYY-MM-DD"),
					daterange:moment().subtract(1, 'months').format("MM-DD-YYYY")+' - '
						+ moment(new Date()).format("MM-DD-YYYY"),
				},
				insuranceDates: {
						start_date: moment().subtract(1, 'months').format("YYYY-MM-DD"),
						end_date:moment().add(1, 'days').format("YYYY-MM-DD"),
						daterange:moment().subtract(1, 'months').format("MM-DD-YYYY")+' - '
						+ moment(new Date()).format("MM-DD-YYYY"),
				},
				receipt_url: '',

			}
		},
		computed: {
			...mapGetters("auth", ["user","activeProvider"]),
		},
		beforeCreate() {
			if (this.$store.state.covid_exam_rpt_user_access.includes(parseInt(localStorage.getItem("user_credential_id")))) {
				this.$router.push('/covid/examination-report');
			} else {
				if(localStorage.getItem("google_authentication")  == 'true'){
					if(localStorage.getItem("login_security")  == '0'){
						this.$router.push('/google-authentication');
					}
				}
				else{
					if(localStorage.getItem("default_homepage") == 'Calendar'){
						this.$router.push('/appointments/calendar');
						localStorage.removeItem("default_homepage");
					}
				}
			}
		},
		mounted(){
			this.todayappointment();
			this.upcomingleave();
			this.getpaymenttransactions();
			this.getupcomingpayment();
			this.getDailyClaim();
			this.getNumPatient();
			this.getCashBilling();
			axios.get("/check_env").then((response) => {
				console.log(response);
			})
		},
		methods:{
			reset() {
				this.searchKey = this.searchKey+1;
			},
			getupcoming(){
				this.upcoming_date = moment($('#upcoming_date').val()).format('YYYY-MM-DD');
				axios.post("/dashboard/upcoming-payment",{start_date:this.upcoming_date})
				.then((response) => {
					if(response.data.data.length > 0){
						this.upcoming = response.data.data;
					}else{
						this.upcoming = [];
					}
					this.upcomingdate = moment(response.data.message).format('MM-DD-YYYY');
				})
			},
			getdate(){
		        this.start_date = moment($('#start_date').val()).format('YYYY-MM-DD');
		        axios.post("/dashboard/payment-transactions",{start_date:this.start_date})
				.then((response) => {
					if(response.data.data.length > 0){
						this.paymenttransactions = response.data.data;
					}else{
						this.paymenttransactions = [];
					}
					this.paymenttransactionsdate = moment(response.data.message).format('MM-DD-YYYY');
				})
		    },
			getAllTotal(data) {
				var health_positive_total = data['health_care'][0]['positive_health_total'];
				if (health_positive_total== null) health_positive_total = 0.00;
				var health_negative_total = data['health_care'][0]['negative_health_total'];
				if (health_negative_total== null) health_negative_total = 0.00;
				var pos_positve_total = data['POS'][0]['positive_pos_total'];
				if (pos_positve_total== null) pos_positve_total = 0.00;
				var pos_negative_total = data['POS'][0]['negative_pos_total'];
				if (pos_negative_total== null) pos_negative_total = 0.00;
				var contract_total = data['Contract'][0]['contract_total'];
				if (contract_total== null) contract_total = 0.00;
				this.dailyCash[0] = parseFloat(health_positive_total).toFixed(2);
				this.dailyCash[1] = parseFloat(health_negative_total).toFixed(2);
				this.dailyCash[2] = parseFloat(pos_positve_total).toFixed(2);
				this.dailyCash[3] = parseFloat(pos_negative_total).toFixed(2);
				this.dailyCash[4] = parseFloat(contract_total).toFixed(2);
				this.dailyCash[5] = parseFloat(data.positive_total).toFixed(2);
				this.dailyCash[6] = parseFloat(data.negative_total).toFixed(2);
			},
			getCashdate() {
				this.cash_date = moment($('#cash_date').val()).format('YYYY-MM-DD');
				axios.post("/dashboard/daily-cash-billing", {start_date:this.cash_date})
				.then((response) => {
					this.getAllTotal(response.data.data);
					this.cashdate = moment(response.data.message).format('MM-DD-YYYY');
				})
			},
			claimDatefun(dates,pattern,type){
				var daterange="";
				if (dates!="") {
					daterange=dates;
					if(daterange != ''&& daterange != null){
						var date = daterange.split(' - ');
						if (type == "count") {
							this.claimDates.start_date = moment(String(date[0])).format(pattern);
							this.claimDates.end_date = moment(String(date[1])).format(pattern);
							this.claimDates.daterange = this.claimDates.start_date + " - " + this.claimDates.end_date;
						} 
						if (type == 'total') {
							this.insuranceDates.start_date = moment(String(date[0])).format(pattern);
							this.insuranceDates.end_date = moment(String(date[1])).format(pattern);
							this.insuranceDates.daterange = this.insuranceDates.start_date + " - " + this.insuranceDates.end_date;
						} 
					}
				}
			},
			getClaimToday() {
				var dateranges = document.getElementById("claim_date").value;
				if (dateranges!="") {
					var daterange = dateranges;
					if(daterange != ''&& daterange != null){
						var date = daterange.split(' - ');
						var start_date = moment(String(date[0])).format('YYYY-MM-DD');
						var end_date = moment(String(date[1])).format('YYYY-MM-DD');
						var daterange = start_date + " - " + end_date;
						axios.post("/dashboard/daily-claimed-list", {date_range:daterange})
						.then((response) => {
								this.dailyClaims = response.data.data;
								var dateranges = response.data.message;
								var daterange="";
							if (dateranges!="") {
								daterange=dateranges;
								if(daterange != ''&& daterange != null){
									var date = daterange.split(' - ');
									this.claimDates.start_date = moment(String(date[0])).format('MM-DD-YYYY');
									this.claimDates.end_date = moment(String(date[1])).format('MM-DD-YYYY');
									this.claimDates.daterange = this.claimDates.start_date + " - " + this.claimDates.end_date;
								}
							}

						})
					}
				}
			},
			getInsuranceToday() {
				var dateranges = document.getElementById("insurance_date").value;
				if (dateranges!="") {
					var daterange = dateranges;
					if(daterange != ''&& daterange != null){
						var date = daterange.split(' - ');
						var start_date = moment(String(date[0])).format('YYYY-MM-DD');
						var end_date = moment(String(date[1])).format('YYYY-MM-DD');
						var daterange = start_date + " - " + end_date;
						axios.post("/dashboard/daily-claimed-list", {date_range:daterange})
						.then((response) => {
								this.insuranceData = response.data.data['total'][0]['claim_total'];
								var dateranges = response.data.message;
								var daterange="";
							if (dateranges!="") {
								daterange=dateranges;
								if(daterange != ''&& daterange != null){
									var date = daterange.split(' - ');
									this.insuranceDates.start_date = moment(String(date[0])).format('MM-DD-YYYY');
									this.insuranceDates.end_date = moment(String(date[1])).format('MM-DD-YYYY');
									this.insuranceDates.daterange = this.insuranceDates.start_date + " - " + this.insuranceDates.end_date;
								}
							}

						})
					}
				}
			},
			getPatientDate() {
				this.start_date_patient = moment($('#start_date_patient').val()).format('YYYY-MM-DD');
				axios.post("/dashboard/number-of-add-patients", {start_date:this.start_date_patient})
				.then((response) => {
					this.patients = response.data.data;
					this.patientdate = moment(response.data.message).format('MM-DD-YYYY');
				})
			},
			getCashBilling() {
				axios.post("/dashboard/daily-cash-billing")
				.then((response) => {
					this.getAllTotal(response.data.data);
					this.cashdate = moment(response.data.message).format('MM-DD-YYYY');
				}) 
			},
			getNumPatient() {
				axios.post("/dashboard/number-of-add-patients")
				.then((response) => {
					this.patients = response.data.data;
					this.patientdate = moment(response.data.message).format('MM-DD-YYYY');
				})
			},
			getDailyClaim(){
				axios.post("/dashboard/daily-claimed-list")
				.then((response) => {
					this.dailyClaims = response.data.data;
					this.insuranceData = response.data.data['total'][0]['claim_total'];
					var date1 = response.data.message;
					this.claimDatefun(date1,'MM-DD-YYYY','count');
					this.claimDatefun(date1,'MM-DD-YYYY','total');
				})
			},
			getpaymenttransactions(){
				axios.post("/dashboard/payment-transactions")
				.then((response) => {
					if(response.data.data.length > 0){
						this.paymenttransactions = response.data.data;
					}else{
						this.paymenttransactions = [];
					}
					this.paymenttransactionsdate = moment(response.data.message).format('MM-DD-YYYY');
				})
			},
			getupcomingpayment(){
				axios.post("/dashboard/upcoming-payment")
				.then((response) => {
					if(response.data.data.length > 0){
						this.upcoming = response.data.data;
					}else{
						this.upcoming = [];
					}
					this.upcomingdate = moment(response.data.message).format('MM-DD-YYYY');
				})
			},
			changeDate(event) {
				this.selectedDate = event.value;
				this.$store.state.date = this.selectedDate;
				this.$router.push({ path: '/appointments/calendar'})
			},
			todayappointment(){
				axios.get("/dashboard/today-appointment")
				.then((response) => {
					this.today_appointment_list = response.data.data.map((today_appointment_list) => {
						return { patient_name: today_appointment_list.patient_name,reason_for_visit: today_appointment_list.reason_for_visit,schedule_time:today_appointment_list.schedule_time,app_time:today_appointment_list.schedule_time,profile_avatar:today_appointment_list.profile_avatar,unique_id:today_appointment_list.unique_id,app_date:today_appointment_list.schedule_date,status_label:today_appointment_list.status_label,provider_name:today_appointment_list.provider_name,office_name:today_appointment_list.office_name,
						room_name:today_appointment_list.room_name,
						profile_avatar_url:today_appointment_list.profile_avatar_url}
					});
				})
			},
			upcomingleave(){
				axios.get("/leave/upcoming")
				.then((response) => {
					this.upcoming_leave=response.data.data;
				})
			},
			timeformate(time){
				let default_format = "01-01-1970"+ time;
				return moment(default_format, 'MM-DD-YYYY, hh:mm A').format('hh:mm A');
			},
			currentDate() {
				const current = new Date();
				const date = `${current.getMonth()+1}-${current.getDate()}-${current.getFullYear()}`;
				return moment(date).format('MM-DD-YYYY');
		    },
			printApptData(type) {
				var th = this;
				var apptList = th.today_appointment_list;
				if (type == 'pdf') {
					this.getSrc(apptList);
				} else {
					axios.post("dashboard/print-today-appointment",{"appointmentlist": apptList, 'export_type' : "excel"})
						.then((response) => {
							let blob = new Blob([response.data.data], {
								type: 'application/pdf'
							})
							let link = document.createElement('a')
							link.href = response.data.data
							link.download = 'appointment.xlsx'
							link.click()
							this.moshaToast("Download XSL successfully", "success");
						});
				}
			},
			async getSrc(apptList) {
				var date  = new Date();
				var date_range = `${date.getMonth()+1}/${date.getDate()}/${date.getFullYear()}`;
				const res = await fetch(process.env.VUE_APP_API_BASE_URL+'/dashboard/print-today-appointment', {
					method: 'POST',
					headers: {
						'Authorization' : 'Bearer '+localStorage.getItem('authToken'),
						'Content-Type' : 'application/json'
					},
					body: JSON.stringify({ "appointmentlist": apptList,"date_range":date_range}),
				});
				const blob = await res.blob();
				const urlObject = URL.createObjectURL(blob);
				if (/Android|webOS|Opera Mini/i.test(navigator.userAgent)) {
					$("#printApptModal").modal("hide");
					window.open(urlObject, '_blank', 'height=600,width=1200');
				} else {
					this.receipt_url = urlObject;
					this.browserCheck();
				}
			},
			browserCheck(){
				// Opera 8.0+
				var isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

				// Firefox 1.0+
				var isFirefox = typeof InstallTrigger !== 'undefined';

				// Safari 3.0+ "[object HTMLElementConstructor]" 
				var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));

				// Internet Explorer 6-11
				var isIE = /*@cc_on!@*/false || !!document.documentMode;

				// Edge 20+
				var isEdge = !isIE && !!window.StyleMedia;

				// Chrome 1 - 79
				var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

				// Edge (based on chromium) detection
				var isEdgeChromium = isChrome && (navigator.userAgent.indexOf("Edg") != -1);

				// Blink engine detection
				var isBlink = (isChrome || isOpera) && !!window.CSS;

				if (isChrome==true) {
					this.embed = 0;
				} else {
					this.embed = 1;
				}
			},
		}
	};
</script>
