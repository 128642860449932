<template>
	<div class="panel pagecollap">
		<div class="panel-heading" id="importstructured" role="tab">
	        <a class="panel-title" aria-controls="answer-8" aria-expanded="false" data-bs-toggle="collapse" href="#answer-8" data-parent="#accordion2">Clinical Data Management</a>
	     </div>
	     <div class="panel-collapse collapse" id="answer-8" aria-labelledby="importstructured" role="tabpanel" data-bs-parent="#category-1">
	        <div class="panel-body">
	           <form>
	              <div class="form-group row align-items-center appointment">
	                 <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end">
	                    <label class="mb-0">Select Appointment</label> <small class="text-danger asterisksign">*</small>
	                 </div>
	                 <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3">
	                    <select class="select2Single form-select form-control" v-model="appointment_id" id="appointment_id"  @change="changeAppointment($event)">
													<option value="" disabled="disabled">Select Appointment</option>
												<template  v-for="(item,index) in appointmentData"  :key="item.id">
													<option v-if="item.imported_at == null " :value="item.id" :data-val="item.appointment_number">
														{{item.name}}
													</option>
												</template>
												<optgroup label="Imported Appointment">
													<template  v-for="(item,index) in appointmentData"  :key="item.id">
														<option :value="item.id" v-if="item.imported_at != null " :data-val="item.appointment_number" data-type="imported">
															{{ item.name }}
														</option>
													</template>
												</optgroup>
											</select>


                        <div class="text-danger appointment_error d-none">Please select appointment </div>
	                 </div>
	              </div>
	              <!-- <div class="form-group row align-items-center">
	                 <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end">
	                    <label class="mb-0">File to upload (*.xml)</label>
	                 </div>
	                 <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3">
	                    <input name="file" type="file" title="" multiple />
	                 </div>
	              </div> -->
	              <div class="col-12 col-md-12 col-lg-8 mt-20 text-center">
	                 <button @click="download" type="button" class="waves-effect waves-light btn btn-info m-1">Download CCDA</button>
	                 <button @click="uploadpp" type="button" class="waves-effect waves-light btn btn-info m-1">Export CCDA to Patient Portal</button>
	              </div>
	           </form>
	        </div>
	     </div>
  	</div>
  	{{this.AllTypeDocument}}
</template>
<script>
import axios from "@/scripts/axios.js";
import submitMixins from "@/scripts/mixins/submitMixins.js";
export default {
	mixins: [submitMixins],
	async beforeCreate() {
		let $this = this;
      	axios
    	.post('/json_dropdown/patient-appointments',{patient_id:this.$store.state.patientDetail.patient_id})
      	.then((response) => {
        	$this.appointmentData = response.data.data,
        	$this.masterId = (response.data.data.length > 0) ? response.data.data[0].clinical_note_id : '';
  		})
	},
	methods:{
		appointment_change(){
			if($( "#appointment_id option:selected" ).text() != ''){
				$('.appointment').removeClass('error');
				$('.appointment_error').addClass('d-none');
			}
		},
		download(){
			if(this.appointment_id == ''){
				$('.appointment').addClass('error');
				$('.appointment_error').removeClass('d-none');
			}
			else{
				this.$store.state.loader = true;
				axios.post(`patient/${this.$route.params.id}/getCCDA/`+this.appointment_id, {xml: true,})
				.then((res) => {      
			        var hiddenElement = document.createElement('a');
			        var blob = new Blob([res.data], {type: "text/xml"});
			        var url = window.URL.createObjectURL(blob);
			        hiddenElement.href = url;
			        hiddenElement.download = 'CCDA'+this.appointment_id+'.xml';
			        document.body.append(hiddenElement);
			        hiddenElement.click();
			        hiddenElement.remove();
			        window.URL.revokeObjectURL(url);        
			        this.appointment_id ='';
			        this.moshaToast('Download successfully.','success');
			        this.$store.state.loader = false;
			      });
			}
		},
		uploadpp(){
			if(this.appointment_id == ''){
				$('.appointment').addClass('error');
				$('.appointment_error').removeClass('d-none');
			}
			else{
				this.$store.state.loader = true;
				axios.post('patient/' + this.$route.params.id +'/getCCDA/'+this.appointment_id)
		      	.then((response) => {
		      		axios.post('patient-portal/clinicalDocuments',{
						type:'ccda',
						id:this.appointment_id,
						AllTypeDocument:response.data
					})
			        .then(response => {
			        	this.moshaToast('Record Updated successfully.','success');
			        	this.appointment_id ='';
			        	this.$store.state.loader = false;
			        });
		      	})   	
		    }
		}
	},
	data() {
		return {
			appointment_id:'',
        	appointmentData: '',
        	AllTypeDocument:'',
        }
    }

}
</script>