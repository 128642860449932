<template>
  <div class="content-wrapper">
    <div class="container-full">
      <section class="content">
        <div class="row justify-content-between">
          <!-- sidebar -->
          <the-side-bar v-if="this.$store.state.renderComponent" ></the-side-bar>
          <!-- content -->
          <component :is="$store.state.StaffHomeComp"></component>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import TheSideBar from "@/components/Staff/TheSidebar.vue";
import StaffAdd from "@/components/Staff/Home.vue";
import Scheduling from "@/components/Staff/Scheduling/Home.vue";
import StaffAccessPermission from "@/components/Staff/permission/Home.vue";

export default {
    
    components: {TheSideBar,StaffAdd,Scheduling,StaffAccessPermission},
    data() {
        return {
        };
    },
    created() {
      if (this.$route.name === "StaffEditScheduling") {
        this.$store.state.StaffHomeComp = "Scheduling";
      } else if (
        this.$route.name === "StaffAdd" ||
        this.$route.name === "Staffedit"
      ) {
        this.$store.state.StaffHomeComp = "StaffAdd";
      } else if (this.$route.name === "StaffAccessPermission"){
        this.$store.state.StaffHomeComp = "StaffAccessPermission";
      }
    },
};
</script>

