<template>
  <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-10">
    <div class="authform-modal box">
      <div class="pageheading">
        <h2 class="font-bold mt-0">{{ title }} <a href="#" data-bs-toggle="modal" data-bs-target="#addstaffdocument" title="Reference of Knowledge Base" v-on:click.prevent="reset()"> <i class="fa fa-question-circle fs-20"></i></a></h2>
        <p class="mb-10 fs-14">NOTE: Verification link will be sent on registered email that will be valid for 24 Hours.</p>
      </div>
      <form novalidate>
        <div class="form-group row">
            <label
              class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end"
              >Salutation
            </label>
            <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3">
              <div class="controls">
                <select
                  name="salutation"
                  v-model="form.salutation"
                  class="form-select form-control form-control"
                >
                  <option value="" disabled>---------</option>
                  <option value="Dr.">Dr.</option>
                  <option value="Prof.">Prof.</option>
                  <option value="Prac.">Prac.</option>
                  <option value="D.O.">D.O.</option>
                </select>
              </div>
            </div>
          </div>
        <input-field
          title="First Name"
          id="first_name"
          v-model="v$.form.first_name.$model"
          :errors="v$.form.first_name.$errors"
          :isRequired="true"
        >
        </input-field>

        <input-field
          title="Last Name"
          id="last_name"
          v-model="v$.form.last_name.$model"
          :errors="v$.form.last_name.$errors"
          :isRequired="true"
        >
        </input-field>
      
        <input-field
              title="Suffix"
              v-model="form.suffix"
              placeholder="e.g. Jr., Sr., I, II"
            >
            </input-field>

						<provider
							title="Primary Provider"
              id="primary_provider_id"
							v-model="v$.form.primary_provider_id.$model"
							:errors="v$.form.primary_provider_id.$errors"
							getDataURL="/json_dropdown/provider"
							:isRequired="true"
              @click="chnageprovider(form.primary_provider_id)"
						></provider>
						<multi-select-box
							title="Additional Provider"
							v-model="form.additional_provider_id"
							getDataURL="/json_dropdown/provider"
						></multi-select-box>
            

        <select-box
          title="Role"
          id="role_id"
          v-model="v$.form.role_id.$model"
          :errors="v$.form.role_id.$errors"
          getDataURL="/json_dropdown/role"
          :isRequired="true"
        ></select-box>

        <input-field
          title="E-mail Address"
          id="email"
          v-model="v$.form.email.$model"
          :errors="v$.form.email.$errors"
          :isRequired="true"
        >
        </input-field>
        <div class="form-group row">
            <label class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end">Country</label>
          <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3">
            <Select2
              v-model="form.country_id"
              id="country_id"
              placeholder="Select Country"
              :options="countries"
              @select="countryChange($event)"
            />               
          </div>
        </div>
            
            <input-field title="Zipcode" v-model="form.zip_code" @focusout="zipcode"> </input-field>

            <div class="form-group row">
              <label class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end">State </label>
              <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3">
                <Select2
                  v-model="form.state_id"
                  id="state_id"
                  placeholder="Select State"
                  :options="states"
                />                
              </div>
            </div>

            <input-field
              title="City"
              v-model="form.city"
              placeholder="City"
            />

            <text-area title="Address" v-model="form.address"> </text-area>


        <input-field
          title="Username"
          v-model="v$.form.username.$model"
          :errors="v$.form.username.$errors"
          :isRequired="true"
        >
        </input-field>

        <password
          id="password"
          title="Password"
          v-model="v$.form.password.$model"
          :errors="v$.form.password.$errors"
          :isRequired="true"
          :showPassword = "true"
          :suggestion = "true"
          v-on:keyup="usercheck"
        >
        </password>

        <password
          id="password_confirmation"
          title="Confirm Password"
          v-model="v$.form.password_confirmation.$model"
          :errors="v$.form.password_confirmation.$errors"
          :isRequired="true"
          v-on:keyup="passcheck"
        >
        </password>

        <input-field
          title="Cell Phone"
          v-model="v$.form.cell_phone.$model"
          :errors="v$.form.cell_phone.$errors"
          mask="(000) 000-0000"
        >
        </input-field>

        <input-field
          title="Home Phone"
          v-model="v$.form.home_phone.$model"
          :errors="v$.form.home_phone.$errors"
          mask="(000) 000-0000"
        >
        </input-field>

        <multi-select-box
          title="Office Access"
          :isRequired="true"
          v-model="v$.form.office_access.$model"
          :errors="v$.form.office_access.$errors"
          getDataURL="/json_dropdown/office"
        ></multi-select-box>

        <div class="form-group row" v-if="this.$route.name === 'Staffedit'">
            <label class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xs form-label text-sm-end"></label>
            <label class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xs form-label">
              <a href="#" data-bs-toggle="modal" data-bs-target="#defaultRoomModal" @click="this.defaultRoomModalShow()">Set Default Room</a>
            </label>
        </div>

        <div class="form-group row">
           <label class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 form-label mb-0 text-sm-end">Can Take Appointment ? </label>
           <div class="col-6 col-sm-6 col-md-8 col-lg-5 col-xl-3">
              <div class="controls">
                 <input id="can_take_appt" type="checkbox" class="filled-in chk-col-info" v-model="form.can_take_appt">
                 <label for="can_take_appt" class="m-0 text-start fs-16"></label>
              </div>
           </div>
        </div>
        <div  v-if="this.$store.state.auth.is_lifestyle_specific_enable==1">
          <div v-if="this.piv==1" class="form-group row">
               <label class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 form-label mb-0 text-sm-end">Is LifeStyle Specific</label>
               <div class="col-6 col-sm-6 col-md-8 col-lg-5 col-xl-3">
                  <div class="controls">
                      <input id="is_lifeStyle_specific" type="checkbox" class="filled-in chk-col-info" v-model="form.is_lifestyle_specific">
                      <label for="is_lifeStyle_specific" class="m-0 text-start fs-16"></label>
                  </div>
               </div>
          </div>
          <div v-else class="form-group row">
            <label class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 form-label mb-0 text-sm-end">Is LifeStyle Specific</label>
               <div class="col-6 col-sm-6 col-md-8 col-lg-5 col-xl-3">
                  <div class="controls">
                      <input id="is_lifeStyle_specific" type="checkbox" class="filled-in chk-col-info" v-model="form.is_lifestyle_specific" disabled>
                      <label for="is_lifeStyle_specific" class="m-0 text-start fs-16"></label>
                  </div>
                  <div v-if="this.piv==0">
                    <span class="text-danger">The primary provider of this staff must be IV-specific, for this staff to be IV-specific.</span>
                  </div>
                  <div v-else>
                    <span class="text-danger">Please select the primary provider</span>
                  </div>
               </div>
          </div>
        </div>

        <div class="form-group row">
           <label class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 form-label mb-0 text-sm-end">Provider required at Appointment Scheduling</label>
           <div class="col-6 col-sm-6 col-md-8 col-lg-5 col-xl-3">
              <div class="controls">
                 <input id="need_provider_help_for_appt" type="checkbox" class="filled-in chk-col-info" v-model="form.need_provider_help_for_appt">
                 <label for="need_provider_help_for_appt" class="m-0 text-start fs-16"></label>
              </div>
           </div>
        </div>

        <div class="form-group row" v-if="this.form.profile_avatar_img">
              <label
                class="col-12 col-sm-6 col-md-4 col-lg-3 col-form-label mb-0 text-sm-end"
                >Current Profile Picture:</label
              >
              <div class="col-12 col-sm-6 col-md-8 col-lg-3">
                <div class="profilepic">
                  <img
                    :src="form.profile_avatar_img"
                    alt="Patient photo"
                    class="img-fluid"
                  />
                </div>
              </div>
            </div>

            <file-upload
              id="profile_avatar"
              title="Profile Picture"
              v-model="v$.form.profile_avatar.$model"
              :errors="vuelidateExternalResults.form.profile_avatar"
              @change="fileValidation($event, 'profile_avatar')"
              noteClass="col-12 col-sm-8 col-md-8 offset-sm-4 offset-lg-0 col-lg-4 col-xl-5"
              note="Only .jpg, .png formats are supported"
            ></file-upload>

            <signature title="Signature"></signature>

      </form>
      <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-11 col-xl-9 text-center">
          <form-btn
            :title="btnname"
            btnCls="btn-info mb-1"
            @click="save(false)"
          ></form-btn>
          <router-link
            v-if="permissioncheck(this.$store.state.permission.Manage_Accounts)"
            :to="{ name: 'StaffList' }"
            class="waves-effect waves-light btn me-1 mb-1 btn-danger"
            >Cancel</router-link
          >
          <router-link
            v-else
            :to="{ name: 'ProfileSetting' }"
            class="waves-effect waves-light btn me-1 mb-1 btn-danger"
            >Cancel</router-link
          >
          <form-btn
            :title="btnname1"
            btnCls="btn-info mb-1"
            @click="save(true)"
          ></form-btn>
        </div>
      </div>
      <!--document model start-->
      <div id="addstaffdocument" class="modal fade" tabindex="-1" role="dialog"
         aria-labelledby="myModalLabel" aria-hidden="true">
         <div class="modal-dialog modal-md">
            <div class="modal-content">
               <div class="modal-header">
                  <h3 class="modal-title fw-900 mt-0">Reference of Knowledge Base</h3>
               </div>
               <div class="modal-body overflow-auto" style="max-height: 350px;">
                  <table class="table table-bordered">
                     <thead>
                        <tr>
                           <th scope="col">No.</th>
                           <th scope="col">Knowledge Base Articles</th>
                        </tr>
                     </thead>
                     <tbody>
                        <tr>
                           <td>1</td>
                           <td> <a :href="path+'how-to-create-a-staff-account-in-pureehr'" target="_blank">How To Create A Staff Account In PureEHR?</a></td>
                        </tr>
						            <tr>
                           <td>2</td>
                           <td> <a :href="path+'how-to-edit-staff-member-s-account-in-pureehr'" target="_blank">How To Edit Staff Member's Account In PureEHR?</a></td>
                        </tr>
                     </tbody>
                  </table>
                  <Search :key="searchKey"></Search>
               </div>
               <div class="modal-footer">
                  <button type="button" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
               </div>
            </div>
         </div>
      </div>
	    <!--document model end-->
    </div>
  </div>

  <div id="defaultRoomModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
          <div class="modal-header">
            <h3 class="modal-title fw-900 mt-0">Office Room</h3>
          </div>
          <div class="modal-body">
            <table id="roomTable" class="table table-hover">
              <thead>
                <tr>
                  <th width="20%">Office Name</th>
                  <th width="80%">Rooms</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if ="this.rooms.length != 0" v-for="(roomDetail, index) in this.rooms">
                  <td>{{roomDetail.office_name}}</td>
                  <td >
                    <div v-for="(item, index) in roomDetail.room" class="form-check form-check-inline">
                        <input type="radio"  :id="`office_${roomDetail.office_id}_${item.room_id}`" :name="`office_${roomDetail.office_id}`" :value="`${item.room_id}`" class="filled-in chk-col-info officeDefaultRoom" :data-office-id="`${roomDetail.office_id}`" :checked="item.is_checked == true"/>
                        <label :for="`office_${roomDetail.office_id}_${item.room_id}`">{{item.room_name}}</label>
                    </div>
                  </td>
                </tr>
                <tr v-else>
                  <td colspan="2" class="text-center">No data available</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="modal-footer">
            <button type="button" data-bs-dismiss="modal" @click="this.saveDefaultRoom()" class="waves-effect waves-light btn btn-info">Save</button>
              <button type="button" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
          </div>
      </div>
    </div>
</div>

</template>

<style>
#defaultRoomModal .modal-body {    
    overflow-y: auto;
    /* height: calc(80vh - 5em); */
    max-height: calc(80vh - 5em);
}
</style>

<script>
import InputField from "@/components/base/formFields/InputField.vue";
import CheckBox from "@/components/base/formFields/checkBox.vue";
import SelectBox from "@/components/base/formFields/selectBox.vue";
import MultiSelectBox from "@/components/base/formFields/multiSelectBox.vue";
import FormBtn from "@/components/base/formFields/formBtn.vue";
import Provider from "@/components/base/Provider.vue";
import Password from "@/components/base/formFields/password.vue";
import Select2 from "@/components/base/formFields/select2.vue";
import TextArea from "@/components/base/formFields/textArea.vue";
import Signature from "@/components/base/Signature.vue";
import FileUpload from "@/components/base/formFields/fileUpload.vue";


import useVuelidate from "@vuelidate/core";
import submitMixins from "@/scripts/mixins/submitMixins.js";
import permissionMixins from "@/scripts/mixins/permission.js";

// import axios from "axios";
import axios from "@/scripts/axios.js";
import {
  helpers,
  required,
  email,
  minLength,
  maxLength,
  sameAs,
  numeric,
} from "@vuelidate/validators";
import Search from "@/components/Search.vue";

export default {
  mixins: [submitMixins, permissionMixins],
  setup: () => {
    return { v$: useVuelidate() };
  },
  components: {
    InputField,
    CheckBox,
    SelectBox,
    FormBtn,
    Provider,
    MultiSelectBox,
    Password,
    Select2,
    TextArea,
    Signature,
    Search,
    FileUpload,
  },
  computed: {
    form() {
      return this.$store.state.staff.form;
    },
    vuelidateExternalResults() {
      return this.$store.state.staff.vuelidateExternalResults;
    },
    validationRules() {
      return this.$store.state.staff.validationRules;
    },
    title() {
      return this.$route.name === "Staffedit"
        ? "Edit Staff Member"
        : "New Staff Member";
    },
    btnname() {
      return this.$route.name === "Staffedit" ? "Update" : "Save";
    },
    btnname1() {
      return this.$route.name === "Staffedit" ? "Update & Next" : "Save & Next";
    },
  },
  async beforeCreate() {
    // clear form data since, the same data is being used for add and edit both

    if (this.$route.name === "StaffAdd") {
      this.$store.state.staff.form = {};
      Object.assign(this.$store.state.staff.form, this.$store.state.staff.defaultFormData);
      this.$store.state.signature = "";
    } else if (this.$route.name === "Staffedit") {
      // if (this.$store.state.staff.form && this.$store.state.staff.form.id != this.$route.params.id) {
        
        // If edit, get data from server
        this.$store.state.signature = "";
        await this.$store.dispatch("getEditData", {
          dataType: "staff", // pass the module name
          formType: "form", // pass the propery name
          id: this.$route.params.id,
        });
        // need to set provider id since, getEdit data give id where update excepts provider_id       
      // }
      
      if (this.form) {
       
        this.form.profile_avatar = null;
        if (this.form.can_take_appt == 1) {
          this.form.can_take_appt = true;
        } else {
          this.form.can_take_appt = false;
        }
        if (this.form.need_provider_help_for_appt == 1) {
          this.form.need_provider_help_for_appt = true;
        } else {
          this.form.need_provider_help_for_appt = false;
        }
        if(this.form.is_lifestyle_specific == '1'){
          this.form.is_lifestyle_specific = true;
        }
        else{
          this.form.is_lifestyle_specific = false;  
        }
        if(this.form.digtal_signature && this.form.digtal_signature!="") {
          this.$store.state.signature = this.form.digtal_signature;
        }
        if (this.form.piv=='0') {
          this.piv=0;
        } else {
         this.piv=1;
        }
      }
      $("#Username").attr("disabled", "disabled");
      axios.post(`/json_autocomplete/231/state`).then(
        (response) =>
          (this.states = response.data.data.map((state) => {
            return { id: state.id, text: state.name };
          }))
      );
    }
  },
  async created() {
    // remove required rule for passwords when edit
    if (this.$route.name === "StaffAdd") {
      this.validationRules.form.password = {
        required: helpers.withMessage("Password is required", required),
        min: minLength(8),
        max: maxLength(14),
      };
      this.validationRules.form.password_confirmation = {
        required: helpers.withMessage("Confirm Password is required", required),
        min: minLength(8),
        max: maxLength(14),
      };
      this.form.salutation = "";
      this.form.country_id = "231";
      axios.post("/json_autocomplete/231/state").then(
        (response) =>
          (this.states = response.data.data.map((state) => {
            return { id: state.id, text: state.name };
          }))
      );
    } else {
      this.validationRules.form.password = {
        min: minLength(8),
        max: maxLength(14),
      };
      this.validationRules.form.password_confirmation = {
        min: minLength(8),
        max: maxLength(14),
      };
    }
  },
  mounted() {
    axios.get("/json_autocomplete/country").then(
      (response) =>
        (this.countries = response.data.data.map((country) => {
          return { id: country.id, text: country.name };
        }))
    );
  },
  methods: {
    reset() {
      this.searchKey = this.searchKey + 1;
    },
    
    zipcode(){
      if(this.form.zip_code && this.form.zip_code.length > 3 ){
        axios.post("json_dropdown/state-city-auto-populations",{zip_code:this.form.zip_code})
        .then((response) => {
            this.form.city=response.data.data.City;
            this.form.state_id=response.data.data.id
        });
      }
      else{
        this.form.city= null;
        this.form.state_id=null;
      }
    },
    usercheck() {
      if (this.form.password == this.form.username) {
        if ($("#password").find(".confirmerror").length != 1) {
          $("#password")
            .find("ul")
            .append(
              '<li class="d-block confirmerror">Username and password must not be same.</li>'
            );
        }
        this.usernameerror = true;
      } else {
        $("#password")
          .find(".confirmerror")
          .remove();
        this.usernameerror = false;
      }
    },
    countryChange({ id, text }) {
      axios.post("/json_autocomplete/" + id + "/state").then(
        (response) =>
          (this.states = response.data.data.map((state) => {
            return { id: state.id, text: state.name };
          }))
      );
    },
    passcheck() {
      if (this.form.password != this.form.password_confirmation) {
        if ($("#password_confirmation").find(".confirmerror").length != 1) {
          $("#password_confirmation")
            .find("ul")
            .append(
              '<li class="d-block confirmerror">Password Confirmation Does Not Match.</li>'
            );
        }
        this.confirmerror = true;
      } else {
        $("#password_confirmation")
          .find(".confirmerror")
          .remove();
        this.confirmerror = false;
      }
    },
    save(next) {
      next ? (this.saveAndNext = true) : (this.saveAndNext = false);
      this.form.digtal_signature = this.$store.state.signature;
      if (this.$route.name === "Staffedit") {
        this.postForm("/staff/update", "staff", true, "formData");
      } else {
        this.postForm("/staff/store", "staff", true, "formData");
      }
    },
    formPostSuccess(data) {
      if (this.saveAndNext) {
        this.$router.push({
          path: `/staff/edit/scheduling/${data.id}`,
        });
        if (this.$route.name === "Staffedit") {
          this.moshaToast("Record updated successfully.", "success");
        } else {
          this.moshaToast("Record added successfully.", "success");
        }
      } else {
          if (this.$route.name === "Staffedit") {
            this.moshaToast("Record updated successfully.", "success");
          } else {
              if(data == null){
                  this.moshaToast("Entered email is invalid or does not exist. Please enter valid email.", "success");
                }
                else{
                  this.moshaToast("Record added successfully.", "success");
                }
          }
            if (data.profile_avatar_img) {
              this.form.profile_avatar_img = data.profile_avatar_img;
            }
            if (permissioncheck(this.$store.state.permission.Manage_Accounts)) {
              this.$router.push("/staff/list");
            } else {
              this.$router.push("/profile/personal-info");
            }
      }
    },
    fileValidation(event, id) {
      $("#" + id)
        .find("ul")
        .html("");
      const size = (event.target.files[0].size / 1024 / 1024).toFixed(2);
      if (size > 2) {
        $("#" + id)
          .find("ul")
          .append(
            '<li class="d-block confirmerror"> image size should not exceed more than 2 MB.</li>'
          );
      }
      const acceptedImageTypes = ["image/jpg", "image/jpeg", "image/png"];
      if (!acceptedImageTypes.includes(event.target.files[0].type)) {
        $("#" + id)
          .find("ul")
          .append(
            '<li class="d-block confirmerror"> Only .jpg,.jpeg,.png formats are supported</li>'
          );
      }
    },
    defaultRoomModalShow() {
      this.getOfficeRoom();
    },
    getOfficeRoom() {
        axios.post(`/provider/get-user-default-room`, 
        {
          type: "staff",
          id: this.$route.params.id,
          'office_id':this.form.office_access.join(',')
        }
        ).then(
          (response) =>
            (this.rooms = response.data.data)

        );
        
    },
    chnageprovider(primary_provider_id){
     
      axios.post(`/provider/isivspecific`, 
        {
         
          id:primary_provider_id,
         
        }
        ).then(
          (response) =>{
            if (response.data.data.is_lifestyle_specific=="1") {
              this.piv=1;
            } else {
              this.piv=0;
              this.form.is_lifestyle_specific = false; 
            }
          
          }
            

        );
      
    },
    saveDefaultRoom() {
      var selectedOpt = [];
      $(".officeDefaultRoom").each(function () {
        if ($(this).prop('checked')) {
          var value    = $(this).val();
          var officeId = $(this).attr('data-office-id');
          selectedOpt.push({
            officeId: officeId, 
            roomId:value
          });
        }
      });
      
      if (selectedOpt.length > 0) {
        axios.post(`/provider/store-user-default-room`, {
          type: "staff",
          id: this.$route.params.id,
          'room_ids': selectedOpt
        }).then(
          function(res) {
            if (res.data.status && res.data.status == "success") {
            } else {
            }
          }
        );
        this.moshaToast('Default room saved successfully', 'success');
      }

    }
  },
  data() {
    return {
      saveAndNext: false,
      seen: "Save",
      confirmerror: false,
      usernameerror: false,
      countries: [],
      states: [],
      path: process.env.VUE_APP_KB_DOCUMENT,
      searchKey:0,
      rooms: [],
      piv:null,
    };
  },
  validations() {
    return this.$store.state.staff.validationRules;
  },
};
</script>
