<template>
	<div class="content-wrapper">
		<div class="container-full">
			<section class="content">
				<div class="row justify-content-sm-center">
					<div class="col-12 col-sm-12 col-md-12 col-lg-12">
						<iframe src="https://cloudwatch.amazonaws.com/dashboard.html?dashboard=PureEHR_Logging&context=eyJSIjoidXMtZWFzdC0xIiwiRCI6ImN3LWRiLTQxNzM3NjE0MDQ2NiIsIlUiOiJ1cy1lYXN0LTFfOTBNdGJpMnlRIiwiQyI6IjZlczR2ZXQ3N2dlN2E5MW8wdWM1ZWR0MzcxIiwiSSI6InVzLWVhc3QtMTo3MzdiZjg2My1kODA2LTQ1YjMtYTNkYi01MTI5YjEyMTQ1NDUiLCJNIjoiUHVibGljIn0=" width="100%" height="1000px"></iframe>
					</div>
				</div>
			</section>
		</div>
	</div>
</template>

<script>
	import axios from "@/scripts/axios.js";
	export default {
		components: {
			
		},
		computed: {
			
		},
		validations() {
		},
		methods:{
			
		},
	}
</script>
