<template>
  <div class="content-wrapper">
    <div class="container-full">
      <div class="content-header">
        <div class="row justify-content-between">
          <div class="col-12 col-sm-7 col-md-8 col-lg-6 col-xs-6">
            <h1 class="heading1 mt-0">Appointment Status <a href="#" data-bs-toggle="modal" data-bs-target="#statusdocument" title="Reference of Knowledge Base" v-on:click.prevent="reset()"> <i class="fa fa-question-circle fs-20"></i></a></h1>
          </div>
          <div class="col-12 col-sm-5 col-md-4 col-lg-6 col-xs-6 text-md-right">
            <a href="javascript:void(0)" 
             class="waves-effect waves-light btn btn-info" data-bs-toggle="modal" data-bs-target="#statusAddEditModal"><i class="fa fa-plus fs-14"></i> Appointment Status
            </a>
          </div>
          <!--document model start-->
            <div id="statusdocument" class="modal fade" tabindex="-1" role="dialog"
                aria-labelledby="myModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-md">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h3 class="modal-title fw-900 mt-0">Reference of Knowledge Base</h3>
                        </div>
                        <div class="modal-body overflow-auto" style="max-height: 350px;">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th scope="col">No.</th>
                                        <th scope="col">Knowledge Base Articles</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td> <a :href="path+'how-to-add-new-appointment-status'" target="_blank">How To Add New Appointment Status?</a></td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td> <a :href="path+'how-to-edit-and-deleted-appointment-status'" target="_blank">How To Edit And Deleted Appointment Status?</a></td>
                                    </tr>
                                </tbody>
                            </table>
                            <Search :key="searchKey"></Search>
                        </div>
                        <div class="modal-footer">
                            <button type="button" @click="" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
                        </div>
                    </div>
                </div>
            </div>
          <!--document model end-->
          <div id="statusAddEditModal" class="modal fade" tabindex="-1" aria-labelledby="problemModalLabel" style="display: none;" aria-hidden="true">
            <div class="modal-dialog ">
                <div class="modal-content">
                    <div class="modal-header">
                        <h2 class="modal-title m-0"> Appointment Status </h2>
                    </div>
                    <form>
                        <div class="modal-body">
                            <div class="form-group mb-10" sortBy="name" :class="{ error: v$.form.status_label.$errors.length }">
                                <label class="form-label">Status </label> <small class="text-danger asterisksign">*</small>
                                <input type="text"
                                  v-model="form.status_label"
                                  class="form-control"
                                  required
                                  :placeholder="title"
                                >
                                <div class="error-msg" v-if="v$.form.status_label.$errors.length > 0">
                                  <ul class="mb-0 p-0 fs-14"><li class="d-block" v-for="(error, index) of v$.form.status_label.$errors"> {{ error.$message }}</li>
                                  </ul>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="waves-effect waves-light btn btn-info"  @click="saveStatus()">Save</button>
                            <button type="button" @click="closeDialog"  class="waves-effect waves-light btn btn-danger" data-bs-dismiss="modal">Close</button>
                        </div>
                    </form>
                </div>
            </div>
          </div>

        </div>
      </div>

      <section class="content">
        <div class="box">
          <div class="box-body">
            <div class="table-responsive">

              <data-table-server dataType="AppointmentStatusList" :dataTypes="{dataType: 'AppointmentStatusList', 'method' : 'get'}" :key="table_load_count">
                <template v-slot="slotProps">
                  <td>{{ slotProps.item.status_label }}</td>
                  <td>
                    <span v-if="slotProps.item.created_by == null">
                      Default
                    </span>
                    <span v-if="slotProps.item.created_by != null">
                      Custom
                    </span>
                  </td>
                  <td>
                    <div class="">
                      <span v-if="slotProps.item.created_by != null">
                        <button type="button"  data-on="Ready" data-off="Not Ready" @click="onToggleChange(slotProps.item.id, $event)" :id="['status_' + slotProps.item.id]" class="btn btn-toggle rounded30-im"  v-bind:class="[slotProps.item.status == 'A' ? 'active' : '']" data-bs-toggle="button" aria-pressed="true" autocomplete="off">
                          <div class="handle rounded30-im"></div>
                        </button>
                      </span>
                    </div> 
                  </td>
                  <td>
                    <div class="d-flex">
                      <span v-if="slotProps.item.created_by != null">
                        <Popper arrow content="Edit Status" :hover="true" class="helptips cursor-pointer">
                          <a 
                            @click="editStatus(slotProps.item.id)"
                            href="#"
                            class="waves-effect waves-circle btn btn-circle btn-info btn-xs me-1"
                            ><i class="fa fa-pencil"></i></a>
                        </Popper>
                        <Popper arrow content="Delete Status" :hover="true" class="helptips cursor-pointer">
                          <a
                            @click="deleteItem(slotProps.item.id)"
                            href="#"
                            class="waves-effect waves-circle btn btn-circle btn-danger btn-xs"
                            ><i class="fa fa-trash"></i></a>
                        </Popper>
                      </span>
                      <span v-if="slotProps.item.created_by == null">
                        No Action
                      </span>
                    </div>
                  </td>
                </template>  
              </data-table-server>

            </div>
          </div>
        </div>
        
      </section>
    </div>
  </div>

</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
// import 'jquery/dist/jquery.min.js';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import "datatables.net-dt/js/dataTables.dataTables"
// import "datatables.net-dt/css/jquery.dataTables.min.css"
import axios from "@/scripts/axios.js";
import DataTableServer from "@/components/base/DataTableServer.vue";
import submitMixins from "@/scripts/mixins/submitMixins.js";

import Autocomplete from 'vue3-autocomplete';
import useVuelidate from "@vuelidate/core";
import TableBase from "@/components/base/Table.vue";
import InputField from "@/components/base/formFields/InputField.vue";
import SelectBoxStatic from "@/components/base/formFields/SelectBoxStatic.vue";
import permissionMixins from "@/scripts/mixins/permission.js";
import Popper from "vue3-popper";
import moment from 'moment';
import Search from "@/components/Search.vue";

export default {

  name: "AppointmentStatusList",
  setup: () => {
    return { v$: useVuelidate() };
  },
  mixins: [submitMixins,permissionMixins],
  components: { Autocomplete, TableBase,InputField, SelectBoxStatic,DataTableServer, Popper,Search },
  data() {
    
    return {
      table_load_count : 0,
      staffList : [],
      
      date: new Date(),
      dateFormat: 'MM-dd-yyyy',
      strict:true,
      minVal: new Date(),  
      path: process.env.VUE_APP_KB_DOCUMENT,
      searchKey:0,
    }
    
  },
  mounted() {
    
  },
  computed: {
    form() {
      return this.$store.state.AppointmentStatusList.form;
    },
    vuelidateExternalResults() {
      return this.$store.state.AppointmentStatusList.vuelidateExternalResults;
    },
    validationRules() {
      return this.$store.state.AppointmentStatusList.validationRules;
    },  
  },
  validations() {
    return this.$store.state.AppointmentStatusList.validationRules;
  },
  methods: {
    reset() {
      this.searchKey = this.searchKey + 1;
    },
    onToggleChange(id, event) {
      var class_list = document.getElementById("status_"+id).classList;
      var active_deactive = '';
      if(class_list.contains('active')) {
        active_deactive = 'A';
      } else {
        active_deactive = 'D';
        // console.log(class_list.contains('active'));
      }

      axios
        .post("appointment-status/update",{"status":active_deactive,"id":id})
        .then((response) => {
          this.moshaToast('Status updated successfully.','success');
        });
      
    },
    resetForm() {
       var self = this;
       Object.keys(this.form).forEach(function(key,index) {
         self.form[key] = '';
       });
      this.date = '';
      this.v$.$reset();
    },
    closeDialog() {
        $('#statusAddEditModal').modal('hide');
        this.from_date = '';
        this.to_date = '';
        this.resetForm();
     },
    saveStatus() {
      const storeOrUpdate = this.form.id ? 'update' : 'store';
      const url = '/appointment-status/'+storeOrUpdate;
      this.postForm(url, 'AppointmentStatusList');
    },
    formPostSuccess(response) {
      const message = this.form.id ? 'Record updated successfully.' : 'Record added successfully.';
      this.moshaToast(message,'success');
    
      $('#statusAddEditModal').modal('hide');
    
      this.resetForm();
      // this.table_load_count++;
      this.staffList = [];
      setTimeout(function(){ location.reload(); }, 400);
    },
    async editStatus(id) {
        if (id) {
            const result =  await this.$store.dispatch("getEditData", {
              dataType: "AppointmentStatusList", // pass the module name
              formType: "form", // pass the propery name
              id: id,
            });
           
          $('#statusAddEditModal').modal('show');
          
        }
    },
    deleteItem(id) {
      this.$swal.fire({
        title: 'Are you sure you want to delete this record?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      })
      .then((result) => {
          if (result.isConfirmed == true) {
              axios.post(`/appointment-status/delete`,{'id':id})
              .then((response) => {
              this.$swal.fire('Deleted!','Status has been deleted.','success')  
                  .then((result) => { 
                      if(result.isConfirmed == true){
                         location.reload();
                      }
                  });
              })
          }
      });
    },
  },
};
</script>
