<template>
	<div class="content-wrapper">
		<div class="container-full">
			<div class="content-header">
				<div class="row justify-content-between">
					<div class="col-12 col-sm-8 col-md-9 col-lg-6 col-xs-6">
						<h1 class="mt-0">LifeStyle Service</h1>
					</div>
					<div class="col-12 col-sm-4 col-md-3 col-lg-3 col-xs-2 text-sm-end">
						<a href="#/corporate/life-style-service/add" class="waves-effect waves-light btn btn-info mb-5"><i class="fa fa-plus fs-14"></i> New Service</a>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-2">
					<life-style-header routefrom="CorporateLifeStyleService"></life-style-header>
				</div>
				<div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-10">
					<section class="content">
						<div class="box">
							<div class="box-body">
								 <div class="table-responsive">
									<data-table dataType="CorporateLifeStyleService" :key="render">
									
										 <template v-slot="slotProps">
											
											<td>
												{{ slotProps.item.product_name }}
											</td>
											<td>
												{{ slotProps.item.category_name }}
											</td>
											<td>
												{{ slotProps.item.product_code }}
											</td>
											<td>
												${{ slotProps.item.product_price }}
											</td>
											<td>										
							                    <span>
							                        <button type="button"  data-on="Ready" data-off="Not Ready" @click="onToggleChange(slotProps.item.id, $event)" :id="['status_' + slotProps.item.id]" class="btn btn-toggle rounded30-im"  v-bind:class="[slotProps.item.product_status == 'Active' ? 'active' : '']" data-bs-toggle="button" aria-pressed="true" autocomplete="off">
							                          <div class="handle rounded30-im"></div>
							                        </button>
							                    </span>
											</td>
											<td>
												<Popper arrow content="Edit Product" :hover="true" class="helptips cursor-pointer">
													<a @click="editProduct(slotProps.item.id)" href="#" class="waves-effect waves-circle btn btn-circle btn-info btn-xs me-1"><i class="fa fa-pencil"></i></a>
												</Popper>
											</td>
										</template>
									</data-table>
								</div> 
							</div>
						</div>
					</section>
				</div>
			</div>
		</div>
	</div>
	<div id="myContentModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
		<div class="modal-dialog modal-xl">
			<div class="modal-content">
				<div class="modal-header">
					<h2 class="modal-title m-0">Content</h2>
					<button type="button" class="btn-close btn-xs" data-bs-dismiss="modal" aria-label="Close" @click="closeModal()"></button>
				</div>
				<div class="modal-body">
					<form novalidate>
                      <select-box
                        v-model="this.form.category_id"
                        title="Select Category"
                        getDataURL="/IV_life_style/category/dropdownlist"
                        :isRequired="true"
                      ></select-box>   
                  <input-field title="Service Name" v-model="this.form.product_name" :isRequired="true"></input-field>
                  <input-field title="Service Code" v-model="this.form.product_code"></input-field>
                  <input-field title="Service Price ($)" v-model="this.form.product_price"  :isRequired="true"></input-field>
				  <div class="row">
				  	<div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-6 text-end">
				   		<a data-bs-toggle="collapse" href="#moreServices" class="waves-effect waves-light btn-sm btn-info mb-5">View Similar Services</a>
				  	</div>
				  </div>
					<div class="collapse" id="moreServices">
						<div class="bg-light p-3 rounded mt-3">
							<div v-if="(form.servicedata.length == 0)"><p class="text-center">No record found</p></div>
							<div class="bb-1 row justify-content-center" v-for="(item,index) in form.servicedata" :key="item">
								<div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
									<input-field divClass="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" labelClass="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-form-label mb-0" title="Service Name" v-model="form.servicedata[index].product_name" :isRequired="true" v-bind:id="'product_name_'+index"></input-field>
								</div>
								<div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
									<input-field divClass="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" labelClass="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-form-label mb-0" title="Service Code" v-model="form.servicedata[index].product_code" v-bind:id="'product_code_'+index"></input-field>
								</div>
								<div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
									<input-field divClass="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" labelClass="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-form-label mb-0" title="Service Price ($)" v-model="form.servicedata[index].product_price"  :isRequired="true" v-bind:id="'product_price_'+index"></input-field>
								</div>
							</div>
						</div>
					</div>
                </form>	
				
				</div>
				<div class="modal-footer">
					<form-btn title="Update" btnCls="btn-info" @click="saveDialogContent(this.form.category_id)"></form-btn>
					<button type="button" class="addpatientbtn btn-danger b-0" data-bs-dismiss="modal" @click="closeModal()">Close</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from "@/scripts/axios.js";
	import InputField from "@/components/base/formFields/InputField.vue";
	import useVuelidate from "@vuelidate/core";
	import submitMixins from "@/scripts/mixins/submitMixins.js";
	import dataTable from "@/components/base/DataTable.vue";
	import SelectBox from "@/components/base/formFields/selectBox.vue";
	import FormBtn from "@/components/base/formFields/formBtn.vue";
	import LifeStyleHeader from "@/components/Lifestyle/LifeStyleHeader.vue";

	export default {
		name: "Home",
		mixins: [submitMixins],
		components: {InputField, dataTable, LifeStyleHeader,SelectBox,FormBtn},
		computed: {
			
		},
		data() {
			return {
				list:[],
				form:{
					product_name:"",
					product_code:"",
					product_price:"",
					product_id:"",
					category_id:"",
					servicedata: [],
					},
				
			}
		},
		mounted(){
			 
		},
		methods:{
			editProduct(id){
				$('#myContentModal').modal('show');
				axios
		        .get("/IV_life_style/service/edit/"+id)
		        .then((response) => {
					this.form.product_name=response.data.data.product_name;
					this.form.product_code=response.data.data.product_code;
					this.form.product_price=response.data.data.product_price;
					this.form.product_id=response.data.data.id;
					this.form.category_id=response.data.data.category_id;
					this.form.servicedata=response.data.data.all_product;
					
		        });
				
				// this.$router.push({ name: "CorporateLifeStyleServiceEdit", params: { id } });
			},
			saveDialogContent(category_id){
				axios
		        .post("/IV_life_style/service/update",this.form)
		        .then((response) => {
					if (response.data.status=="success") {
						 this.moshaToast(response.data.message,"success");
						 setTimeout(function(){
							 window.location.reload();
						 },1000);
						
					}
					
		        });
				
			},
			closeModal(){
				 window.location.reload();
			},
			onToggleChange(id, event) {
		      var class_list = document.getElementById("status_"+id).classList;
		      var staus = '';
		      if(class_list.contains('active')) {
		        staus = 'Active';
		      } else {
		        staus = 'Deactive';
		      }

		      axios.post("/IV_life_style/service/status-update",{"status":staus,"id":id})
		        .then((response) => {
		          this.moshaToast('Status updated successfully.','success');
		        });
		      
		    }
		}
	
};
</script>
<style type="text/css">
	.btn-toggle:before{
		content: 'Inactive';
		left: -6rem;
	}
	.btn-toggle:after{
		content: 'Active';
		right: -5rem;
	}
</style>