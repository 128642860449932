<template>
  <div class="loginback">
    <div class="container-fluid">
      <div class="row align-items-center">
        <div class="col-12 col-sm-9 col-md-8 col-lg-6 col-xl-5 col-xxl-4 offset-sm-1">
          <div class="bg-white rounded10 p-35 pt-40 pb-40 my-20">
            <div class="text-start">
              <img src="/images/pureehr.svg" alt="PureEHR" title="PureEHR" class="authcompany_logo mb-10"/>
              <h2 class="mb-15 fw-900">RectangleHealth Authorization</h2>
              <p v-if="this.paymentdone == false && errorMessage == ''">Welcome {{this.patient_name}},</p>
              <p v-if="this.paymentdone == false && errorMessage == ''">Please enter your credit card information for $1 verification.</p>
              <p v-if="serverError" class="bg-danger-light p-10 py-5 rounded5 fs-16 mb-15" role="alert">{{ serverError }}</p>
            </div>
            
            <div class="bg-danger-light p-10 py-5 rounded5 fs-16 mb-15" role="alert" v-if="errorMessage">
              {{errorMessage}}
            </div>
            <!-- iframe start-->
            <div class="form-group" v-if="errorMessage == '' && this.paymentdone == false">
              <div style="display: none" id="customStyles">
                body{margin:0;}
                iframe{height: 100px;}
                #payment-form {border: 1px solid rgb(212, 221, 230);max-height: 130px;width: 100%;padding: 20px 10px;border-radius: 4px;margin: 0px;background: rgb(245, 248, 250);}
                #payment-form {color: #2d3c58;}
                #payment-form .input-label {color: #2d3c58;border-top: 1px solid #d4dde6;font-size:13px;text-align: center !important;}
              </div>
              <div id="card" style="height: 130px;"></div>
              <div id="errorMessage" style="margin-bottom: 8px; color: #c0392b;"></div>
            </div>
            <button v-if="this.paymentdone == false && errorMessage == ''" type="button" class="waves-effect waves-light btn  mb-5 me-1 waves-effect waves-light btn btn-info me-1" @click="handlePaymentPay">Authorize</button>
            <!-- iframe end-->
            <p v-if="this.paymentdone == false">Thanks,<br>Pure Wellness Medical</p>
            <p v-if="this.paymentdone == true" class="text-center text-success fw-900"><b> Thanks</b><br><span> Your Credit Card is authorized successfully!</span></p>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "@/scripts/axios.js";
import { mapGetters, mapActions } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required, minLength, helpers } from "@vuelidate/validators";
import $ from "jquery";
export default {
  name: "Login",
  setup() {
    return { v$: useVuelidate() };
  },
  components: {},
  data() {
    return {
      errorMessage: "",
      isDisabled: false,
      paymentdone: false,
      patient_name:'',

      is_production_env : process.env.VUE_APP_NODE_ENV == 'production',
      appointment_id : '',
      appointment_number : '',
      appointmentData: [],
      hash: '',
      payment_frm : {
        payment_type : 'Credit Card',
        basic_amount : 0,
        discount_amount : 0,
        payable_amount : 0,
        paid_amount : process.env.VUE_APP_NODE_ENV == 'production' ? 1  : 5,
        check_trace : '',
        other_payment_type : '',
        cctoken_id : 'addNew',
        use_credidcard_for_future : true,
        patient_id : '',
        clinic_id:'',        
      },
      billing_summary : {
        healthcare_amount: 0,
        custom_amount: 0,
        basic_amount: 0,
        discount_amount: 0,
        payable_amount: 0,
        received_amount: 0,
        order_payment_info: [],
        order_info: {},
      },
      tokenpay : {},
      tokenPayReady: false, 
      checkingInterval: null,
      serverError: '',
      ccTokenData: [],
      receipt_url: '',
      embed: 0,
      publicKey : '',
    };
  },
  computed: {
    ...mapGetters(["errors"]),
  },
  beforeCreate(){
    $('.tophead').html("");
    this.$store.commit("setErrors", {});
    this.$store.state.headerRequired = false;
    this.$store.state.footerRequired = false;
    //this.$store.dispatch("auth/sendLogoutRequest");
  },
  async created() {
    this.tokencheck();
  },
  watch: {
    /*tokenPayReady: function(data) {
      if (data) { 
        clearInterval(this.checkingInterval) 
        //this.initializeToken()
      }
    }*/
  },
  mounted() {
    console.log(this.payment_frm.paid_amount);
    let bridgePayScript = document.createElement('script')
    bridgePayScript.setAttribute('src', process.env.VUE_APP_API_RECTANGLEHEALTH_TOKENPAY_URL)
    document.head.appendChild(bridgePayScript);
  },
  methods: {
      tokencheck(){
        axios
        .post("/payment-gateway-migration/token-timeout-check", { token: this.$route.params.token })
        .then((response) => {
          if (response.data.data.success != 1) {
            this.errorMessage = response.data.message;
            this.isDisabled = true;
          }
          else{
            this.patient_name = response.data.data.name;
            this.payment_frm.patient_id = response.data.data.patient_id;
            this.payment_frm.clinic_ids = response.data.data.clinic_id;
            this.publicKey = response.data.data.PUBLICKEY;
            this.initializeToken();
          }
        })
        .catch((error) => {
          this.errorMessage = error.response.data.message;
        });
      },
      numbersOnly(evt) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
          evt.preventDefault();;
        } else {
          return true;
        }
      },
      initializeToken : function() {
        this.tokenpay = {};
        if (this.publicKey) {
          this.tokenpay = TokenPay(this.publicKey);
          this.tokenpay.initialize({
            dataElement: '#card',
            errorElement: '#errorMessage',
            amountElement: 'amount',
            useACH: false,
            //if displaying all 4 fields then useStyles=false, disableZip=false, disableCvv=false
            //if displaying 3 out of 4 fields then useStyles=false, and set disableZip or disableCvv equal to true
            //if displaying 2 out of 4 fields then useStyles=true, disableZip=true, disableCvv=true
            useStyles: true,
            disableZip: true,
            disableCvv: false
          });
        }
        console.log(this.tokenpay);
      },
      resetForm : function() {
        this.v$.$reset();
        this.payment_frm.payment_type = '';
        this.payment_frm.paid_amount = '';
        this.payment_frm.check_trace = '';
        this.payment_frm.other_payment_type = '';
        this.payment_frm.cctoken_id = '';
        this.payment_frm.use_credidcard_for_future = false;
      },
      handlePaymentCapture : function() {
        var $this = this;
        $this.$store.state.loader = true;
        axios.post("payment-gateway-migration/storePatientBillingPayment", this.payment_frm)
        .then((response) => {
          $this.resetForm();
          $this.$store.state.loader = false;
          $this.paymentdone=true;
          $this.moshaToast(response.data.message, "success");
        })
        .catch((error) => {
          $this.$store.state.loader = false;
          $this.serverError = error.response.data.message;
        });
      },
      handlePaymentPay: function() {
        this.v$.$validate();
        if (!this.v$.$error) {
          this.serverError = '';
          this.$store.state.loader = true;
          var vm = this;
          if (this.payment_frm.payment_type == 'Credit Card' && this.payment_frm.cctoken_id == 'addNew') {
            this.tokenpay.createToken(function (result) {
              vm.payment_frm.token = result.token;
              vm.handlePaymentCapture();
            }, function (result) {
              vm.$store.state.loader = false;
            });
          }
        }
      }
    }
};
</script>
