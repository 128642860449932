<template>
  <div class="quick-info-footer">
    <div class="cell-footer" v-if="data.elementType === 'cell'">
      <ejs-button v-if="data.appointment_type == 'Tele Health' && (data.status == 1 || data.status == 9 || data.status == 2)"
        :cssClass="'e-flat float-start tele_conf_status_'+data.Id"
        :content="data.status == 2 ? 'Resend Confirmation' : 'Confirm Appt' "
        v-on:click.native='confirmAppt($event, data)'
      ></ejs-button>
      <ejs-button
        id="more-details"
        cssClass="e-flat me-1"
        content="More Details"
        v-on:click.native="buttonClickActions"
      ></ejs-button>
      <ejs-button
        id="add"
        cssClass="e-flat"
        content="Add"
        :isPrimary="true"
        v-on:click.native="buttonClickActions"
      ></ejs-button>
    </div>
    <div class="event-footer" v-else>
      <ejs-button v-if="data.appointment_type == 'Tele Health' && (data.status == 1 || data.status == 9 || data.status == 2)"
        :cssClass="'e-flat float-start tele_conf_status_'+data.Id"
        :content="data.status == 2 ? 'Resend Confirmation' : 'Confirm Appt' "
        v-on:click.native='confirmAppt($event, data)'
      ></ejs-button>
      <ejs-button
        id="collapse-quickpopup"
        cssClass="e-flat collapse-quickpopup me-1"
        content="More"
      ></ejs-button>
      <ejs-button
        id="more-details"
        cssClass="e-flat text-capitalize"
        content="Appt. Details"
        :isPrimary="true"
        v-if="data.status_label!='Rescheduled'"
        v-on:click.native="buttonClickActions"
      ></ejs-button>
      <!-- <ejs-button
        id="close-quick-popup"
        cssClass="e-flat text-capitalize e-event-delete"
        content="Close"
        :isPrimary="true"
        v-on:click.native="buttonClickActions"
      ></ejs-button> -->
    </div>
  </div>
</template>
<script>
import { ButtonComponent } from "@syncfusion/ej2-vue-buttons";
import axios from "@/scripts/axios.js";
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';

export default {
  components: {
    "ejs-button": ButtonComponent,
  },
  data() {
    return {
      data: {},
    };
  },
  setup: () => {
      const moshaToast = () => {
        createToast("Changes updated successfully",{
          timeout: 4000,
          position: "top-right",
          type: "success",
          transition: "slide",
          showIcon: true,
          hideProgressBar: false,
          toastBackgroundColor: "#05825f",
          timeout: 2000, 
          })
    }
    return { moshaToast }
  },

  mounted() {
    const scheduleObj = document.querySelector(".e-schedule").ej2_instances[0];
    if (this.data.elementType === "cell") {
      const cellDetails = scheduleObj.getCellDetails(
        scheduleObj.getSelectedElements()
      );
      let addObj = {};
      addObj.Id = scheduleObj.getEventMaxID();
      addObj.StartTime = new Date(+cellDetails.startTime);
      addObj.EndTime = new Date(+cellDetails.endTime);

      scheduleObj.addEvent(addObj);
      scheduleObj.closeQuickInfoPopup();
      scheduleObj.openEditor(addObj, "Add", true);
    }
  },
  methods: {
    confirmAppt : function ($event, item) {
        $('.tele_conf_status_'+item.Id).html( $('.tele_conf_status_'+item.Id).html()+'&nbsp<i class="fa fa-spinner fa-spin"></i>');
        if (item.status == 2) {
            axios.post("appointment/telehealth-notify",{'appointment_id' : item.Id })
            .then((response) => {
                this.moshaToast("Confirmation has been sent.", "success"); 
                const scheduleObjInner =  document.querySelector(".e-schedule").ej2_instances[0];
                scheduleObjInner.closeQuickInfoPopup();
                scheduleObjInner.refreshEvents();
            });
        } else {
          axios.post("appointment/status-update",{'appointment_ids' : [ item.Id ], 'appointment_status_id' : 2 })
          .then((response) => {
              this.moshaToast("Appointment has been confirmed.", "success"); 
              const scheduleObjInner =  document.querySelector(".e-schedule").ej2_instances[0];
              scheduleObjInner.closeQuickInfoPopup();
              scheduleObjInner.refreshEvents();
          });
        }
    },
    buttonClickActions: function(e) {
      const scheduleObj = document.querySelector(".e-schedule")
        .ej2_instances[0];

      if (e.target.id === "close-quick-popup") {
        scheduleObj.closeQuickInfoPopup();
      } else  {
        const quickPopup = scheduleObj.element.querySelector(
          ".e-quick-popup-wrapper"
        );
        const getSlotData = function() {
          const titleObj = quickPopup.querySelector("#title").ej2_instances[0];
          const notesObj = quickPopup.querySelector("#notes").ej2_instances[0];
          const eventTypeObj = quickPopup.querySelector("#eventType")
            .ej2_instances[0];
          const cellDetails = scheduleObj.getCellDetails(
            scheduleObj.getSelectedElements()
          );
          let addObj = {};
          addObj.Id = scheduleObj.getEventMaxID();
          addObj.Subject = titleObj.value;
          addObj.StartTime = new Date(+cellDetails.startTime);
          addObj.EndTime = new Date(+cellDetails.endTime);
          addObj.Description = notesObj.value;

          return addObj;
        };
        if (e.target.id === "add") {
          const addObj = getSlotData();
          scheduleObj.addEvent(addObj);
        } else if (e.target.id === "delete") {
          const eventDetails = scheduleObj.activeEventData.event;
          let currentAction = "Delete";
          if (eventDetails.RecurrenceRule) {
            currentAction = "DeleteOccurrence";
          }
          scheduleObj.deleteEvent(eventDetails, currentAction);
        } else {
          let eventDetails = null;
          let currentAction = null;
          if(quickPopup){
            const isCellPopup = quickPopup.firstElementChild.classList.contains(
              "e-cell-popup"
            );
            eventDetails = isCellPopup
              ? getSlotData()
              : scheduleObj.activeEventData.event;
            currentAction = isCellPopup ? "Add" : "Save";
          }else{
            eventDetails = scheduleObj.activeEventData.event;
            currentAction = "Save";
          }
          if (eventDetails.RecurrenceRule) {
            currentAction = "EditOccurrence";
          }
          scheduleObj.openEditor(eventDetails, currentAction, true);
        }
        scheduleObj.closeQuickInfoPopup();
      }
    },
  },
};
</script>
