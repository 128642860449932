<template>
  <div class="content-wrapper">
    <div class="container-full">
      <div class="content-header">
        <h1 class="heading1 mt-0">Card Authorization of Prospects <a href="#" data-bs-toggle="modal" data-bs-target="#carddocument" title="Reference of Knowledge Base" v-on:click.prevent="reset()"> <i class="fa fa-question-circle fs-20"></i></a></h1>
      </div>
      <section class="content">
        <div class="box">
          <div class="box-body">
            <div class="row">
              <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 px-5">
                <div class="form-group">
                  <label class="form-label">Choose Date Range</label>
                  <div class="form-group mb-5 availdate">
                    <ejs-daterangepicker id="daterangepicker" :placeholder="waterMarkText"  :format="dateFormat" :openOnFocus='true' @change="getdate" :value="filter.date_range"></ejs-daterangepicker>
                  </div>
                </div>
              </div>
              <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-2 px-5">
                <div class="form-group">
                  <label class="form-label">Source</label>
                  <select class="form-select" v-model="filter.source">
                    <option selected="selected" disabled="disabled" value="">Select Source</option>
                    <option v-for="(item,index) in patientsource" :value="item.legacy_source">{{item.legacy_source}}</option>
                  </select>
                </div>
              </div>
              <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-2 px-5">
                <div class="form-group">
                  <label class="form-label">Authorization Status</label>
                  <select class="form-select" v-model="filter.authorization">
                    <option selected="selected" disabled="disabled" value="">Select Authorization Status</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                </div>
              </div>

							<div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-2 px-5">
								<div class="form-group">
									<label class="form-label">Search</label>
									<input type="text" class="form-control" v-model="filter.free_search"
										placeholder="Search" @keydown="patient_search($event)">
								</div>
							</div>
							<div class="col-12 col-sm-12 col-md-8 col-lg-12 col-xl-3 px-5">
								<div class="mt-25">
									<button class="b-0 waves-effect waves-light btn btn-info py-7 me-1" @click="search">
										Search </button>
									<button class="b-0 waves-effect waves-light btn btn-info-light py-7 me-1"
										@click="Clear"> Clear
									</button>
									<button class="b-0 waves-effect waves-light btn btn-info py-7" @click="sendemail">
										Send Mail </button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="box">
					<div class="box-body">
						<div class="table-responsive">
							<data-table-server dataType="CardAuthorizationofProspectus" dataMethod="post" @checkall="checkall">
								<template v-slot="slotProps">
									<td>
										<router-link :to="{
											name: 'PatientDetails',
											params: { id: slotProps.item.unique_id },
										}" :title="'View Chart of ' + slotProps.item.patinet_name + '(' + slotProps.item.chart_id + ')'"
											class="fw-500">{{ slotProps.item.chart_id }}</router-link>
									</td>
									<td>{{ slotProps.item.first_name }}</td>
									<td>{{ slotProps.item.last_name }}</td>
									<td>{{ slotProps.item.email }}</td>
									<td>{{ slotProps.item.legacy_source }}</td>
									<td>{{ (slotProps.item.auth == 'No' ? 'No' : 'Yes') }}</td>
									<td>
										<div class="d-flex">
											<Popper arrow content="Send $1 Verification Eamil" :hover="true"
												class="helptips cursor-pointer">
												<a v-if="slotProps.item.auth == 'No'"
													@click="resendVerificationLink(slotProps.item.id)" href="#"
													class="waves-effect waves-circle btn btn-circle btn-info btn-xs me-1">
													<i class="fa fa-paper-plane"></i>
												</a>
											</Popper>
											<Popper arrow content="Show email template" :hover="true"
												class="helptips cursor-pointer">
												<a v-if="slotProps.item.auth == 'No'" href="javascript:void(0)"
													class="waves-effect waves-circle btn btn-circle btn-info btn-xs"
													data-bs-toggle="modal" data-bs-target="#showemailtemplate"
													@click="showemailtemplate(slotProps.item.patinet_name)">
													<i class="fa fa-eye"></i>
												</a>
											</Popper>
										</div>
									</td>
									<td>
										<div class="controls ">
											<input v-if="slotProps.item.auth == 'No'"
												v-bind:id="'checkbox_id' + slotProps.item.id" name="cardauthorization"
												type="checkbox" class="filled-in chk-col-info myCheckbox"
												:value="slotProps.item.id">
											<label v-bind:for="'checkbox_id' + slotProps.item.id"
												class="m-0 text-start fs-16" style="padding-left: 18px;"></label>
										</div>
									</td>
								</template>
							</data-table-server>
						</div>
					</div>
				</div>
			</section>
			<!--document model start-->
			<div id="carddocument" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
				<div class="modal-dialog modal-md" style="max-width: 600px;">
					<div class="modal-content">
						<div class="modal-header">
							<h3 class="modal-title fw-900 mt-0">Reference of Knowledge Base</h3>
						</div>
						<div class="modal-body overflow-auto" style="max-height: 350px;">
							<table class="table table-bordered">
								<thead>
									<tr>
										<th scope="col">No.</th>
										<th scope="col">Knowledge Base Articles</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>1</td>
										<td> <a :href="path + 'how-to-check-if-prospect-s-credit-card-is-authorized-in-pureehr'"
												target="_blank">How To Check If Prospect's Credit Card Is Authorized In
												PureEHR?</a></td>
									</tr>
								</tbody>
							</table>
							<Search :key="searchKey"></Search>
						</div>
						<div class="modal-footer">
							<button type="button" @click="" data-bs-dismiss="modal" aria-label="Close"
								class="waves-effect waves-light btn btn-danger">Close</button>
						</div>
					</div>
				</div>
			</div>
			<!--document model end-->

			<!--show email template start-->
			<div class="modal fade" id="showemailtemplate" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
				<div class="modal-dialog modal-xl">
					<div class="modal-content">
						<div class="modal-header">
							<h3 class="modal-title fw-900 mt-0">Email Template</h3>
							<button type="button" data-bs-dismiss="modal" aria-label="Close"
								class="btn-close btn-xs"></button>
						</div>
						<div class="modal-body">
							<div>
								<h3 class="fw-600 mt-0 fs-20">To {{ this.patinet_name }},</h3>
								<p class="fs-14 l-h-20">We would like to thank you for being a part of the Pure Wellness
									Medical family.
									In an effort to continue to improve patient satisfaction, Pure Wellness Medical has
									changed our credit
									card processing company. Please use the following link to input your preferred and
									current credit card
									information. By responding to this email, you are CONFIRMING that you would like
									Pure Wellness Medical
									to use the credit card entered for all purchases going forward.</p>
								<p class="fs-14 l-h-20">If you have already registered your credit card information to
									our new platform,
									please ignore this email.</p>
							</div>
							<div class="text-center">
								<a href="javascript:void(0)" class="waves-effect waves-light btn btn-info mb-10">Authorize
									Now</a>
								<p class="mb-1 fs-14 l-h-20">Or Copy and paste the below URL into your browser.</p>
								<p class="fs-14 l-h-20">The link will expire in the next 48 hours.</p>
							</div>
							<div class="bg-lightest p-10 mb-10">
								<p class="mb-1 fw-600 d-block">Respectfully,</p>
								<p class="mb-0 fs-14 l-h-20"> Dr. David P. Suarez <br> Ph: 855-679-7873 <br> Pure
									Wellness Medical <br>
									Optimize Your Life <br> * Live Pure * Be Well * <br> Hormone Replacement Therapy
									Specialist <br>
									Integrative, Functional & Regenerative Medicine</p>
							</div>
							<div>
								<p class="mb-1 fs-14 fw-500">ELECTRONIC MAIL CONFIDENTIAL NOTICE:</p>
								<p class="fs-12 l-h-n mb-1">This electronic message and any attached files may be
									confidential or
									privileged and intended solely for the intended recipient(s). If you are not the
									named recipient you,
									should you be hereby notified that any disclosure, copying, distribution or use of
									any information of
									this message is strictly prohibited. If you have received this message in error or
									are not the
									intended recipient, please notify us immediately by contacting the sender at the
									electronic mail
									address noted above, in addition to deleting and destroying all copies of this
									message. Thank You</p>
								<p class="fs-12 l-h-n mb-1">Any views or opinions expressed in this email are those of
									the author and do
									not represent those of Dr. David Suarez, his staff, and Pure Wellness Medical.
									Warning: Although
									precautions have been taken to make sure no viruses are present in this email; the
									company cannot
									accept responsibility for any loss or damage that arise from the use of this email
									or attachments.
									This may also violate HIPPA laws to forward or copy.</p>
								<p class="fs-12 l-h-n mb-1">Physicians will only prescribe treatment if it is deemed a
									necessity -
									verified through a physical examination, blood work and a patient's medical history.
									Dr. David Suarez,
									his staff, and Pure Wellness Medical, affiliate physicians expressly refuse to
									prescribe treatment for
									performance enhancement, bodybuilding and anti-aging purposes. Agreeing to blood
									work does not
									automatically result in clinical necessity or a resulting prescription.</p>
								<p class="fs-12 l-h-n mb-1">The contents of this web page are not intended to be and
									should not be
									treated as medical advice. Please consult with your health care provider before
									starting any treatment
									program.</p>
							</div>
						</div>
						<div class="modal-footer">
							<button type="button" data-bs-dismiss="modal" aria-label="Close"
								class="waves-effect waves-light btn btn-danger m-0">Close</button>
						</div>
					</div>
				</div>
			</div>
			<!--show email template end-->
		</div>
	</div>
</template>

<script>
import DataTableServer from "@/components/base/DataTableServer.vue";
import axios from "@/scripts/axios.js";
import submitMixins from "@/scripts/mixins/submitMixins.js";
import permissionMixins from "@/scripts/mixins/permission.js";
import Popper from "vue3-popper";
import Search from "@/components/Search.vue";

export default {
	mixins: [submitMixins, permissionMixins],
	components: { DataTableServer, Popper, Search },
	data() {
		return {
			waterMarkText: "Select a Range",
			dateFormat: "MM-dd-yyyy",
			list: [],
			patientsource: [],
			filter: {
				date_range: '',
				source: '',
				authorization: '',
				free_search: '',
			},
			patinet_id: [],
			patinet_name: '',
			list_lenght: '',
			start: 0,
			end: 25,
			step: 25,
			steps: '',
			path: process.env.VUE_APP_KB_DOCUMENT,
			searchKey: 0,
		};
	},
	methods: {
		reset() {
			this.searchKey = this.searchKey + 1;
		},
		pagejump(id) {
			var last_id = parseInt(id * this.step);
			this.start = parseInt(last_id - this.step);
			this.end = last_id;
			$('.myCheckbox').prop('checked', false);
			$('#checkAll').prop('checked', false);
		},
		next(end) {
			this.start = end;
			this.end = parseInt(end) + parseInt(this.step);
			$('.myCheckbox').prop('checked', false);
			$('#checkAll').prop('checked', false);
		},
		previous(end, start) {
			this.start = parseInt(start) - parseInt(this.step);
			this.end = start;
			$('.myCheckbox').prop('checked', false);
			$('#checkAll').prop('checked', false);
		},
		showemailtemplate(name) {
			this.patinet_name = name;
		},
		patient_search(e) {
			if (e.keyCode == 13) {
				this.search();
			}
		},
		Clear() {
			this.filter.date_range = '';
			this.filter.source = '';
			this.filter.authorization = '';
			this.filter.free_search = '';
			axios.post("/reports/card-authorization-of-prospectus")
				.then((response) => {
					this.list = response.data.data;
				});
		},
		resendVerificationLink(id) {
			this.$store.state.loader = true;
			axios.post("/payment-gateway-migration/resend-email", { patient_id: id })
				.then((res) => {
					this.$store.state.loader = false,
						this.moshaToast("Email Sent successfully.", "success")
				});
		},
		getdate() {
			this.filter.date_range = $('#daterangepicker').val();
		},
		search() {
			$('.myCheckbox').prop('checked', false);
			$('#checkAll').prop('checked', false);
			this.$store.state.loader = true;
			axios.post("/reports/card-authorization-of-prospectus", this.filter)
				.then((response) => {
					this.list = response.data.data;
					this.list_lenght = this.list.length;
					this.steps = parseInt(this.list_lenght / this.step);
					this.$store.state.loader = false
				});
		},
		checkall() {
			var checked = $('#checkAll').is(':checked');
			if (checked == true) {
				$('.myCheckbox').prop('checked', true);
			}
			else {
				$('.myCheckbox').prop('checked', false);
			}
		},
		sendemail() {
			var patinet_id_array = Array();
			$("input:checkbox[name=cardauthorization]:checked").each(function () {
				patinet_id_array.push($(this).val());
			});
			this.patinet_id = patinet_id_array;
			this.$store.state.loader = true;
			axios.post("/reports/bulksendemail", { patinet_id: this.patinet_id })
				.then((res) => {
					this.moshaToast("Email Sent successfully.", "success"),
						$('.myCheckbox').prop('checked', false)
					$('#checkAll').prop('checked', false)
					this.$store.state.loader = false
				});
		}
	},
	beforeCreate() {
		this.$store.state.loader = true;
		axios.post("/reports/card-authorization-of-prospectus")
			.then((response) => {
				this.list = response.data.data;
				this.list_lenght = this.list.length;
				this.steps = parseInt(this.list_lenght / this.step);
				this.$store.state.loader = false;
			});

		axios.get("/json_dropdown/patient-source")
			.then((response) => {
				this.patientsource = response.data.data;
			});
	}
};
</script>
