  <template>
    <div :class="mainclass">
      <div class="authform-modal box">
        <div class="pageheading">
          <h2 class="font-bold mt-0">Primary Information <a href="#" data-bs-toggle="modal" data-bs-target="#addpatient" title="Reference of Knowledge Base" v-on:click.prevent="reset()"> <i class="fa fa-question-circle fs-20"></i> </a> </h2>
        </div>
        <!--document model start-->
        <div id="addpatient" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-md">
            <div class="modal-content">
              <div class="modal-header">
                <h3 class="modal-title fw-900 mt-0">Reference of Knowledge Base</h3>
              </div>
              <div class="modal-body overflow-auto" style="max-height: 350px;">
              <table class="table table-bordered">
                <thead>
                  <tr>
                  <th scope="col">No.</th>
                  <th scope="col">Knowledge Base Articles</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td> <a :href="path+'how-to-add-a-new-patient-in-pureehr'" target="_blank">How to add new patient?</a></td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td><a :href="path+'how-to-edit-patient-s-primary-information-in-pureehr'" target="_blank">How to Edit Patient Primary Information?</a></td>
                  </tr>
                </tbody>
              </table>
              <Search :key="searchKey"></Search>
              </div>
              <div class="modal-footer">
                <button type="button"  data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
              </div>
            </div>
          </div>
        </div>
        <!--document model end-->
        <form novalidate>
       
          <provider
            title="Primary Provider"
            id="primary_provider_id"
            v-model="v$.form.primary_provider_id.$model"
            :errors="v$.form.primary_provider_id.$errors"
            getDataURL="/json_dropdown/provider"
            :isRequired="true"
            divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3"
            labelClass="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end"
          ></provider>

          <div class="form-group row" id="office_div">
            <label class="col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end">
              <Popper v-if="form.office_error" arrow content="Attention! This office may be deleted or the patient's primary provider is no longer associated with it. Please verify the information before proceeding." :hover="true" class="helptips cursor-pointer">
                <i class="fa fa-exclamation-triangle fa-2 primary-office-error col-12 col-sm-6 col-md-8 col-lg-5 col-xl-5 mt-2" aria-hidden="true"></i>
              </Popper>  Primary Office
            </label>
            <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3">
              <select class="form-control" v-model="form.office_id">
                <option value="" selected="selected" disabled="disabled" v-if="offices==''||offices==[]||offices==null">Please select primary provider first</option>
                <option value="" selected="selected" disabled="disabled" v-else>Select Office</option>
                <option v-for="(office,index) of offices" :value="office.id" :key="index">{{office.name}}</option>
              </select>
            </div>
          </div>

          <select-box-static
            title="Status"
            id="status"
            v-model="v$.form.status.$model"
            :errors="v$.form.status.$errors"
            :option="Status"
            :isRequired="true"
          ></select-box-static>
          <select-box-static
            title="Health Status"
            v-model="form.health_status"
            :option="health_status"
            @change="Changehealth_status($event)"
          ></select-box-static>

          <div class="form-group row d-none" id="health_status_update_date_div">
            <label class="col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end">
              Health Status Date
            </label>
            <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3 availdate">
              <ejs-datepicker :value="health_status_update_date" id="health_status_update_date" v-model="form.health_status_update_date" :format="dateFormat" placeholder="Health Status Date" @change="gethealth_status_update_date" name="health_status_update_date" :strictMode='strict' :max='maxVal' :showClearButton='false' :blur="gethealth_status_update_date" :openOnFocus='true'>
                </ejs-datepicker> 
            </div>
          </div>

          <div v-if="form.health_status == 419099009" id="status" class="d-block">
            <input-field
              id="reason_of_death"
              title="Reason of death"
              v-model="form.reason_of_death"
            >
            </input-field>

            <date-picker
              title="Date Of Death"
              v-model="form.date_of_death"
              :maxDate="current_date"
            ></date-picker>
          </div>

          <input-field
            title="Title"
            v-model="v$.form.prefix.$model"
            :errors="v$.form.prefix.$errors"
            placeholder="e.g. Mr, Mrs, Ms"
          >
          </input-field>

          <input-field
            title="First Name"
            id="first_name"
            v-model="v$.form.first_name.$model"
            :errors="v$.form.first_name.$errors"
            :isRequired="true"
          >
          </input-field>

          <input-field title="Middle Name" v-model="form.middle_name">
          </input-field>

          <input-field
            title="Last Name"
            id="last_name"
            v-model="v$.form.last_name.$model"
            :errors="v$.form.last_name.$errors"
            :isRequired="true"
          >
          </input-field>

          <input-field title="Previous/Birth Name" v-model="form.previous_name">
          </input-field>

          <input-field
            title="Suffix"
            v-model="v$.form.suffix.$model"
            :errors="v$.form.suffix.$errors"
            placeholder="e.g. I, II, III, IV, Jr, Sr"
          >
          </input-field>

          <file-upload
            id="profile_avatar"
            title="Patient Chart Photo"
            v-model="form.profile_avatar"
          >
          </file-upload>

          <input-field
            title="Home Number"
            mask="(000) 000-0000"
            v-model="v$.form.home_phone.$model"
            :errors="v$.form.home_phone.$errors"
          >
          </input-field>

          <input-field
            title="Cell Phone"
            mask="(000) 000-0000"
            v-model="v$.form.cell_phone.$model"
            :errors="v$.form.cell_phone.$errors"
          >
          </input-field>

          <input-field
            title="Office Phone"
            mask="(000) 000-0000"
            v-model="v$.form.office_phone.$model"
            :errors="v$.form.office_phone.$errors"
          >
          </input-field>

          <input-field
            title="Email"
            v-model="v$.form.email.$model"
            :errors="v$.form.email.$errors"
          >
          </input-field>
          <check-box
            title=""
            v-model="form.email_duplication_allow"
            label="Allow Duplicate Email"
            @click="allowDuplicateChange"
            divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3"
            labelClass="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 form-label mb-0 text-sm-end"
          ></check-box>

          <input-field
            title="Alternate Email"
            v-model="v$.form.alternate_email.$model"
            :errors="v$.form.alternate_email.$errors"
          >
          </input-field>

          <select-box-static
            title="Preferred Communication"
            v-model="form.preferred_communication"
            :option="preferred_communication"
          ></select-box-static>

          <select-box-static
            title="Medication History Consent"
            v-model="form.medication_history_consent"
            :option="medication_history_consent"
          ></select-box-static>

          <select-box
            title="Default Visit Profile"
            v-model="form.appointment_profile_id"
            getDataURL="/json_dropdown/appointment-profile"
          ></select-box>
        </form>
        <div
          class="row mb-10"
          v-if="permissioncheck(this.$store.state.permission.create_update)"
          >
          <div class="col-12 col-sm-12 col-md-12 col-lg-11 col-xl-9 text-center">
            <form-btn
              :title="btnname"
              btnCls="btn-info saveData"
              @click="save(false)"
            ></form-btn>
            <router-link
              :to="{ name: 'PatientList' }"
              class="waves-effect waves-light btn me-1 btn-danger"
              >Close</router-link
            >
            <form-btn
              v-if="this.$route.name != 'PatientDetails'"
              :title="btnname1"
              btnCls="btn-info"
              @click="save(true)"
            ></form-btn>
          </div>
        </div>
      </div>
    </div>
</template>

  <script>
  import Provider from "@/components/base/Provider.vue";
  import InputField from "@/components/base/formFields/InputField.vue";
  import CheckBox from "@/components/base/formFields/checkBox.vue";
  import SelectBox from "@/components/base/formFields/selectBox.vue";
  import FileUpload from "@/components/base/formFields/fileUpload.vue";
  import FormBtn from "@/components/base/formFields/formBtn.vue";
  import SelectBoxStatic from "@/components/base/formFields/SelectBoxStatic.vue";
  import DatePicker from "@/components/base/formFields/datePicker.vue";

  import useVuelidate from "@vuelidate/core";
  import submitMixins from "@/scripts/mixins/submitMixins.js";
  import permissionMixins from "@/scripts/mixins/permission.js";
  import axios from "@/scripts/axios.js";
  import moment from "moment";
  import Search from "@/components/Search.vue";
  import Popper from "vue3-popper";

  import {
    helpers,
    required,
    email,
    minLength,
    maxLength,
    sameAs,
    numeric,
  } from "@vuelidate/validators";

  export default {
    mixins: [submitMixins, permissionMixins],
    setup: () => {
      return { v$: useVuelidate() };
    },
    components: {
      InputField,
      CheckBox,
      SelectBox,
      FileUpload,
      FormBtn,
      Provider,
      SelectBoxStatic,
      DatePicker,
      Search,
      Popper
    },
    computed: {
      form() {
        return this.$store.state.patient.form;
      },
      vuelidateExternalResults() {
        return this.$store.state.patient.vuelidateExternalResults;
      },
      validationRules() {
        return this.$store.state.patient.validationRules;
      },
      title() {
        return this.$route.name === "PatientEdit"
          ? "Edit Patient Information"
          : "Patient Information";
      },
      btnname() {
        return this.$route.name === "PatientEdit" ? "Update" : "Save";
      },
      btnname1() {
        return this.$route.name === "PatientEdit"
          ? "Update & Next"
          : "Save & Next";
      },
    },
    mounted() {
      this.form.wellness_coordinator ='';
      var d = new Date();
      var month = d.getMonth() + 1;
      var day = d.getDate();

      this.current_date =
        d.getFullYear() +
        "-" +
        (("" + month).length < 2 ? "0" : "") +
        month +
        "-" +
        (("" + day).length < 2 ? "0" : "") +
        day;

      if (this.$route.name === "PatientDetails") {
        this.mainclass = "";
        /*if (this.$store.state.patient.form.id != this.$route.params.id) {
          // If edit, get data from server
          this.$store.dispatch("getEditData", {
            dataType: "patient", // pass the module name
            formType: "form", // pass the propery name
            id: this.$route.params.id,
          });
          // need to set provider id since, getEdit data give id where update excepts provider_id
          if (this.form.health_status_update_date!= '') {
            this.health_status_update_date = moment(String(this.form.health_status_update_date)).format('MM-DD-YYYY');  
          }
        }*/
      }

      if (this.$route.name === "PatientAdd") {
        if(localStorage.getItem("activeProvider") == "all") {
          this.form.primary_provider_id = "";
        } else {
          this.form.primary_provider_id = localStorage.getItem("activeProvider");
        }
        this.form.status = 'Active';
        //this.form.health_status ='81323004';
      }

      this.getwellnesscoordinator();

    },
    async beforeCreate() {
      // clear form data since, the same data is being used for add and edit both
      if (this.$route.name === "PatientAdd") {
        this.$store.state.patient.form = {};
        Object.assign(this.$store.state.patient.form);
      } 
      else if (this.$route.name === "PatientDetails") {
        this.mainclass = "";
        // If edit, get data from server
        await this.$store.dispatch("getEditData", {
          dataType: "patient", // pass the module name
          formType: "form", // pass the propery name
          id: this.$route.params.id,
        });
        // need to set provider id since, getEdit data give id where update excepts provider_id
        if (this.form.health_status_update_date!= '') {
          this.health_status_update_date = moment(String(this.form.health_status_update_date)).format('MM-DD-YYYY');  
        }
        if(this.form.email_duplication_allow == 0){
          this.form.email_duplication_allow = false;
        }
        else{
          this.form.email_duplication_allow = true; 
        }
        /*if (this.form.crm_pipeline_id==null||this.form.crm_pipeline_id==1) {
            this.pipeline_id=1;
            this.index=1;
        }*/
        if (this.form.crm_pipeline_id!=null) {
            this.pipeline_id=this.form.crm_pipeline_id;
            this.index=1;
        }

        if(this.form.primary_provider_id != "" || this.form.primary_provider_id != null){
          this.getProviderOffices(this.form.primary_provider_id);
        }
      }
    },
    methods: {
       onChange(e) {
         this.pipeline_id=e.target.value;
          if (
          this.$route.name === "PatientEdit" ||
          this.$route.name === "PatientDetails"
        ) {
         axios.get("/getCrmprospectStatus/"+this.pipeline_id+"/"+this.$route.params.id, {
        }).then(response => { 
          if (response.data.data=="") {
            this.form.crm_prospect_patient_status=null;
            this.form.wellness_coordinator=null;
          }else{
            this.form.crm_prospect_patient_status=response.data.data[0]['crm_prospect_patient_status'];
            this.form.wellness_coordinator=response.data.data[0]['wellness_coordinator'];
          }
          });
        }
        this.index++;
      },
      getwellnesscoordinator(){
        axios.get("/json_dropdown/user-list", {
        }).then(response => { 
            this.wellnessList = response.data.data 
        });
      },
      allowDuplicateChange(){
        if (this.form.email_duplication_allow) {
          this.v$.$reset();
          this.$store.state.patient.vuelidateExternalResults.form.email = [];
        }
      },
      Changehealth_status(event){
        $("#health_status_update_date_div").removeClass("d-none"); 
      },
      save(next) {
        if (this.form.health_status_update_date != null) {
          this.form.health_status_update_date =  moment(String(this.form.health_status_update_date)).format('YYYY-MM-DD');
        }
        this.$store.state.processing = true;
        next ? (this.saveAndNext = true) : (this.saveAndNext = false);

        if (
          this.$route.name === "PatientEdit" ||
          this.$route.name === "PatientDetails"
        ) {
          this.postForm(
            `/patient/${this.$route.params.id}/update`,
            "patient",
            true,
            "formdata"
          );
        } else {
          this.postForm("/patient/store", "patient", true, "formdata");
        }
        this.$store.state.patientDetail.renderComponent = false;
      },
      formPostSuccess(data) {
        this.$store.state.processing = false;
        this.$store.state.patientDetail.renderComponent = true;
        this.$route.params.id = data.unique_id;
        if (this.$route.name === "PatientDetails") {
          axios.post(`/patient/${data.unique_id}/rcopia_registration`,{ patient_id: data.id, patient_rcopia_id:null });
          axios.post(`/patient-portal/patient-sync`,data);
          this.moshaToast("Record updated successfully .", "success");
        } else if (this.$route.name === "PatientEdit") {
          if (this.saveAndNext) {
            this.$router.push(
              `/patient/edit/demographics/${this.$route.params.id}`
            );
          } else {
            this.$router.push(
              `/patient-chart/${data.unique_id}`
            );
          }
          axios.post(`/patient/${data.unique_id}/rcopia_registration`,{ patient_id: data.id, patient_rcopia_id:null });
          this.moshaToast("Record added successfully.", "success");
        } else {
          if (this.saveAndNext) {
            this.$router.push(
              `/patient/add/demographics/${this.$route.params.id}`
            );
          } else {
              this.$router.push(
                `/patient-chart/${data.unique_id}`
              );
          }
          axios.post(`/patient/${data.unique_id}/rcopia_registration`,{ patient_id: data.id, patient_rcopia_id:null });
          this.moshaToast("Record added successfully.", "success");
        }
      },
      gethealth_status_update_date(){
        if($('#health_status_update_date').val() == ''){
          this.form.health_status_update_date = null;
        }else{
          this.form.health_status_update_date =  $('#health_status_update_date').val();
        }
      },
      reset() {
				this.searchKey = this.searchKey+1;
			},
      getProviderOffices(newValue) {
        axios.get("/provider/provider-offices/"+newValue).then(response => {
          this.offices = response.data;
          let flag = true;
          let office_id = this.form.office_id;
          this.offices.forEach(element => {
            if(element.id == office_id){
              flag = false;
            }
          });

          if(office_id == '' || office_id == [] || office_id == null || office_id === undefined){
            flag = false;
          }

          this.form.office_error = flag;
        });
      },
    },
    data() {
      return {
        wellnessList:[],
        health_status_update_date: null,
        dateFormat: 'MM-dd-yyyy',
        strict: true,
        maxVal: new Date(),
        current_date: "",
        mainclass: "col-12 col-sm-12 col-md-9 col-lg-9 col-xl-10",
        saveAndNext: false,
        pipeline_id:0,
        index:0,
        url_data:'',
        pipeline_option:null,
        crm_pipelinelist:1,
        health_status:[
          { value: "81323004", title: "Alive and well" },
          { value: "313386006", title: "In remission" },
          { value: "162467007", title: "Symptom free" },
          { value: "161901003", title: "Chronically ill" },
          { value: "271593001", title: "Severely ill" },
          { value: "21134002", title: "Disabled" },
          { value: "161045001", title: "Severely disabled" },
          { value: "419099009", title: "Deceased" },
          { value: "135815002", title: "General health good" },
        ],
        Status: [
          { value: "Active", title: "Active" },
          { value: "Inactive", title: "Inactive" },
          { value: "Prospect", title: "Prospect" },
        ],
        preferred_communication: [
          { value: "Home Phone", title: "Home Phone" },
          { value: "Office Phone", title: "Office Phone" },
          { value: "Cell Phone", title: "Cell Phone" },
          { value: "Email", title: "Email" },
          { value: "Decline", title: "Decline" },
        ],
        medication_history_consent: [
          { value: "1", title: "Yes" },
          { value: "0", title: "No" },
        ],
        payment_profile: [
          { value: "Cash", title: "Cash" },
          { value: "Credit card", title: "Credit card" },
          { value: "Insurance", title: "Insurance" },
        ],
        path: process.env.VUE_APP_KB_DOCUMENT,
        searchKey:0,
        offices: [],
      };
    },
    validations() {
      return this.$store.state.patient.validationRules;
    },
    watch: {
      "$store.state.processing": function(newValue) {
        if(this.$store.state.processing){
          $(".saveData").text("Processing please wait..");
        }else{
          $(".saveData").text("Save");
        };
      },
      "form.primary_provider_id": function(newValue) {        
        this.getProviderOffices(newValue);
      },
      "form.office_id": function(newValue){
        if(newValue != ""){
          this.form.office_error = false;
        }
      }
    },  
  };
  </script>
