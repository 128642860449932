<template>
  <div class="content-wrapper">
    <div class="container-full">
      <div class="content-header">
        <div class="row justify-content-between">
          <div class="col-12 col-md-6">
            <h1 class="heading2 mb-0">Archived Custom Procedures</h1>
          </div>
          <div class="col-12 col-md-6 text-end">
            <template v-if="this.show_lifestyle_menu">
              <router-link
                to="/custom-procedures?lifestyle=true"
                class="waves-effect waves-light btn btn-info-light"
                ><i class="fa fa-arrow-left fs-16"></i> Back</router-link>
            </template>
            <template v-else>
                <router-link
                  to="/custom-procedures"
                  class="waves-effect waves-light btn btn-info-light"
                  ><i class="fa fa-arrow-left fs-16"></i> Back</router-link>
            </template>
          </div>
        </div>
      </div>
      <div class="row">
          <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-2" v-if="this.show_lifestyle_menu">
              <life-style-header routefrom="CustomProcedures"></life-style-header>
          </div>
          <div v-bind:class="show_lifestyle_menu ? 'col-12 col-sm-12 col-md-9 col-lg-9 col-xl-10' : 'col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12' ">
            <section class="content">
              <div class="box">
                <div class="box-body">
                  <div class="table-responsive">
                    <center>
                      <span class="isvalid-feedback" style="display:block;">{{
                        success
                      }}</span>
                    </center>
                    <data-table dataType="CustomProceduresarchived">
                      <template v-slot="slotProps">
                        <td> {{ slotProps.item.category_name }}</td>
                        <td>
                          {{ slotProps.item.code }} ({{
                            slotProps.item.type_of_product
                          }})
                        </td>
                        <td>{{ slotProps.item.provider_name }}</td>
                        <td>{{ slotProps.item.description }}</td>
                        <td>{{ slotProps.item.note }}</td>
                        <td>${{ slotProps.item.price }}</td>
                        <td>${{ slotProps.item.price_with_tax }}</td>
                        <td>${{ slotProps.item.cost }}</td>
                        <td>{{ slotProps.item.duration }} mins</td>
                        <!-- <td>
                          <div class="d-flex">
                            <a
                              title="Custom Procedures Edit"
                              href="#"
                              @click="edit(slotProps.item.id)"
                              class="waves-effect waves-circle btn btn-circle btn-info btn-xs me-5"
                              ><i class="fa fa-pencil"></i
                            ></a>
                            <a
                              title="Custom Procedures Delete"
                              href="#"
                              @click="remove(slotProps.item.id)"
                              class="waves-effect waves-circle btn btn-circle btn-danger btn-xs"
                              ><i class="fa fa-trash"></i
                            ></a>
                          </div>
                        </td> -->
                      </template>
                    </data-table>
                  </div>
                </div>
              </div>
            </section>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import dataTable from "@/components/base/DataTable.vue";
import LifeStyleHeader from "@/components/Lifestyle/LifeStyleHeader.vue";
import axios from "@/scripts/axios.js";

export default {
  name: "Home",
  components: { dataTable, LifeStyleHeader },
  data() {
    return {
      success: "",
      result: "",
      show_lifestyle_menu : this.$route.query.lifestyle ? true : false
    };
  },
  mounted() {
    if (this.$route.params.message) {
      this.success = this.$route.params.message;
    }
  },
  methods: {
    remove(id) {
      this.$swal
        .fire({
          title: '<h2 class="mt-0">Are you sure?</h2>',
          text:
            "You want to delete these records? This process cannot be undone.",
          icon: "warning",
          dangerMode: true,
          showCancelButton: true,
          confirmButtonColor: "#ee3158",
          cancelButtonColor: "#bdbdbd",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed == true) {
            axios
              .post("/custom-procedure/delete", { id: id })
              .then((response) => {
                this.$swal
                  .fire(
                    "Deleted!",
                    "Your Visit Profiles has been deleted.",
                    "success"
                  )
                  .then((result) => {
                    if (result.isConfirmed == true) {
                      location.reload();
                    }
                  });
              });
          }
        });
    },
    edit(id) {
      this.$router.push({ name: "customproceduresedit", params: { id: id } });
    },
  },
};
</script>
