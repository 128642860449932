<template>
	<div class="row">
		<div class="col-12 col-sm-6 col-md-6">
			<div class="d-flex align-items-center">
				<span class="me-1">Show: </span>
				<select class="form-control me-1" style="width: 90px" v-model="table_range" @change="getData(1)">
					<option value="10">10</option>
					<option value="25">25</option>
					<option value="50">50</option>
					<option value="100">100</option>
					<option value="500">500</option>
					<option value="1000">1000</option>
				</select>
				<span class="me-1"> entries</span>
			</div>
		</div>
		<div class="col-12 col-sm-6 col-md-6">
			<div class="d-flex align-items-center float-end">
				<span class="me-1">Search:</span>
				<input class="form-control" type="text" name="table_search" id="table_search" v-model="table_search"
					@input="getData(1)" />
			</div>
		</div>
	</div>
	<div class="table-responsive mt-20">
		<table :id="dataType" class="table table-hover">
			<thead>
				<tr>
					<th v-for="(item, index) in getTableHeaders" :key="index">
						<span v-for="(col, i) in item" :key="i">
							<template v-if="col!=''">
								{{ col }}
								<span class="float-end sorting" :id="index" @click="sorting(i, $event.currentTarget, index)"
									v-if="col != 'Action' && i != ''">
									<a href="#" class="text-mute" :id="'1_' + i"><i class="mdi mdi-arrow-up"></i></a>
									<a href="#" class="text-mute" :id="'2_' + i"><i class="mdi mdi-arrow-down"></i></a>
								</span>
							</template>
							<template v-else>
								<input v-if="getUserType == 'provider' || permissioncheck(this.$store.state.permission.Sign_Lock_Clinical_Notes) || getUserType == 'not_applicable'" id="checkAll" type="checkbox" class="filled-in chk-col-info" @click="checkall">
                         		<label v-if="getUserType == 'provider' || permissioncheck(this.$store.state.permission.Sign_Lock_Clinical_Notes) || getUserType == 'not_applicable'" for="checkAll" class="m-0 text-start fs-16" style="padding-left: 18px;"></label>
							</template>
						</span>
					</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(item, index) in getTableContents" v-if="getTableContents != ''" :key="index" class="hover-primary">
					<slot v-bind:item="item"></slot>
				</tr>
				<tr v-else>
					<td :colspan="getTableHeaders.length" class="no-record-found">No Records Found</td>
				</tr>
			</tbody>
		</table>
		<div style="float: left; margin-top: 5px" class="dataTables_info" id="example1_info" role="status" aria-live="polite">
			{{`Showing ${getPagination.from != null ? getPagination.from : 0} to ${getPagination.to != null ? getPagination.to : 0 } of ${getPagination.total} entries`}}
		</div>
		<div class="float-end">
			<ul class="pagination">
				<li class="paginate_button page-item" :class="isDisabled('previous')"
					@click="getData(getPagination.current_page - 1)">
					<a href="#" aria-controls="example1" data-dt-idx="0" tabindex="0" class="page-link">Previous</a>
				</li>
				<template v-if="getPagination.current_page >= 5 && getPagination.last_page != 5">
					<li>
						<a @click="getData(1)" class="" > 1 </a>
					</li>
					<span class="ellipsis">...</span>
				</template>
				<li>
					<a v-for="pageNumber in pageNumbers"  :key="pageNumber" @click="getData(pageNumber)" :class="getPagination.current_page==pageNumber ? 'current' : ''" > {{ pageNumber }} </a>
				</li>
				<template v-if="getPagination.current_page <= (getPagination.last_page-4) && getPagination.last_page != 5">
					<span class="ellipsis">...</span>
					<li>
						<a @click="getData(getPagination.last_page)" class="" > {{getPagination.last_page}} </a>
					</li>
				</template>
				<li class="paginate_button page-item next" :class="isDisabled('next')" id="example1_next" @click="getData(getPagination.current_page + 1)">
					<a href="#" aria-controls="example1" data-dt-idx="2" tabindex="0" class="page-link">Next</a>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import { useAttrs } from "vue";
import permissionMixins from "@/scripts/mixins/permission.js";

export default {
	emits: ['checkall'], 
	props: {
		dataType:{
			type: String
		},
		url:{
			type: String
		},
		dataMethod:{
			type: String
		},
		onLoadCall: {
            type: Boolean,
            default: true
        }
	},
	data() {
		return {
			isNextDisable: false,
			isPrevDisable: false,
			table_search: "",
			table_range: 10,
			table_col_name: "",
			table_order: "",
			localTimezone: null,
		};
	},
	mixins: [permissionMixins],
	computed: {
		getTableHeaders() {
			if(this.dataType == "AdvanceReport"){
				if(this.$store.state[this.dataType].filter.result_for == "Appointment"){
					return this.$store.state[this.dataType].header1;
				}else{
					return this.$store.state[this.dataType].header;
				}
			}else{
				return this.$store.state[this.dataType].header;
			}
		},
		getTableContents() {
			const arry = this.$store.state[this.dataType].list.map((obj,index)=> ({ ...obj, sr_no: index+1 }));
			return arry;
		},
		getPagination() {
			return this.$store.state[this.dataType].pagination;
		},
		getFilter() {
			return this.$store.state[this.dataType].filter;
		},
		pageNumbers() {
			let pageRange = 5; // Number of visible pages
			let halfRange = Math.floor(pageRange / 2);
			let firstPage = Math.max(this.getPagination.current_page - halfRange, 1);
			if(this.getPagination.current_page < 5){
				firstPage = 1;
			}
			let lastPage = Math.min(firstPage + pageRange - 1, this.getPagination.last_page);
			let pages = [];
			if (lastPage - firstPage + 1 < pageRange) {
				// If the range is less than pageRange, adjust the start page accordingly
				let diff = pageRange - (lastPage - firstPage + 1);
				firstPage = Math.max(firstPage - diff, 1);
			}
			for (let page = firstPage; page <= lastPage; page++) {
				pages.push(page);
			}
			if(this.getPagination.current_page >= 5 && this.getPagination.current_page <=  (this.getPagination.last_page-4)){
				pages = pages.slice(1, pages.length - 1);
			}
			return pages;
		},
		getUserType() {
			if(this.dataType == "BulkSignClinicalNotes"){
				return this.$store.state[this.dataType].userType;
			}else{
				return 'not_applicable';
			}
		}
	},

	methods: {
		checkall(){
			this.$emit('checkall', true);
		},
		isDisabled(type) {
			if (type === "previous") {
				if (this.getPagination.current_page === 1) {
					this.isPrevDisable = true;
					return "disabled";
				}
			}
			if (type === "next") {
				if (this.getPagination.current_page === this.getPagination.last_page) {
					this.isNextDisable = true;
					return "disabled";
				}
			}
		},
		sorting(name, ele, index) {
			$(".sorting").each(function (i) {
				if (i != index) {
					$("#" + i + ">a").addClass("text-mute");
				} else {
					$("#" + i + ">a>").removeClass("text-mute");
				}
			});
			if ($(ele).children().eq(0).hasClass("text-dark")) {
				$(ele).children().eq(0).removeClass("text-dark").addClass("text-mute");
				$(ele).children().eq(1).removeClass("text-mute").addClass("text-dark");
				this.table_order = "DESC";
			} else {
				$(ele).children().eq(0).removeClass("text-mute").addClass("text-dark");
				$(ele).children().eq(1).removeClass("text-dark").addClass("text-mute");
				this.table_order = "ASC";
			}

			this.table_col_name = name;
			this.getData(1);
		},
		async getData(page) {
			if($('#checkAll').prop('checked')){
				$("#checkAll").trigger("click")
			}
			if ((page > 0 && page <= this.getPagination.last_page) || page === 1) {
				if (this.dataMethod == "post") {
					if (this.dataType == "patientList") {
						var queryStr = {
							page: page,
							table_search: this.table_search,
							table_range: this.table_range,
							table_col_name: this.table_col_name,
							table_order: this.table_order,
							
							duplicate: this.getFilter.duplicate,
							inactive_only: this.getFilter.inactive_only,
							active_inactive: this.getFilter.active_inactive,
							search_text: this.getFilter.search_text,
							having_followup: this.getFilter.having_followup,
							upcoming_appointment: this.getFilter.upcoming_appointment,
							approaching_appointment: this.getFilter.approaching_appointment,
							new_patients: this.getFilter.new_patients,
							yesterday_appointment: this.getFilter.yesterday_appointment,
							today_appointment: this.getFilter.today_appointment,
							office: this.getFilter.office_id,
						};
					} else if (this.dataType == "preAppointment") {
						var queryStr = {
							page: page,
							table_search: this.table_search,
							table_range: this.table_range,
							table_col_name: this.table_col_name,
							table_order: this.table_order,
							
							search_text: this.getFilter.search_text,
							converted_appt: this.getFilter.converted_appt,
							pre_with_converted_appt: this.getFilter.pre_with_converted_appt,
							deleted_appt: this.getFilter.deleted_appt,
						};
					} else if (this.dataType == "leave") {
						var queryStr = {
							page: page,
							table_search: this.table_search,
							table_range: this.table_range,
							table_col_name: this.table_col_name,
							table_order: this.table_order,
							
							staff_id: this.getFilter.staff_id,
							provider_id: this.getFilter.provider_id,
							status: this.getFilter.status,
							start_date: this.getFilter.from_date,
							end_date: this.getFilter.to_date,
							date: this.getFilter.date,
						};
					} else if (this.dataType == "RemittanceReport") {
						var queryStr = {
							page: page,
							table_search: this.table_search,
							table_range: this.table_range,
							table_col_name: this.table_col_name,
							table_order: this.table_order,
							
							start_date: this.getFilter.start_date,
							end_date: this.getFilter.end_date,
							trace_number: this.getFilter.trace_number,
							date_for: this.getFilter.date_for,
							patient_id: this.getFilter.patient_id,
							payer_id: this.getFilter.payer_id,
							remit_type: this.getFilter.remit_type,
							report_number: this.getFilter.report_number,
						};
					} else if (this.dataType == "RefundReport") {
						var queryStr = {
							page: page,
							table_search: this.table_search,
							table_range: this.table_range,
							table_col_name: this.table_col_name,
							table_order: this.table_order,
							
							start_date: this.getFilter.start_date,
							end_date: this.getFilter.end_date,
							daterange: this.getFilter.daterange,
							select_option_date: this.getFilter.select_option_date,
							search_text: this.getFilter.search_text,
							select_refund_type: this.getFilter.select_refund_type,
						};
					} else if (this.dataType == "Contract") {
						var queryStr = {
							page: page,
							table_search: this.table_search,
							table_range: this.table_range,
							table_col_name: this.table_col_name,
							table_order: this.table_order,
							
							date_range: this.getFilter.date_range,
							status: this.getFilter.status,
						};
					} else if(this.dataType == "ContractPayment"){
						var queryStr = {
							page: page,
							date_range: this.getFilter.date_range,
							status: this.getFilter.status,
							method: this.getFilter.method,
							table_search: this.table_search,
							table_range: this.table_range,
							table_col_name: this.table_col_name,
							table_order: this.table_order,
						};
					} else if (this.dataType == "PatientContactReport") {
						var queryStr = {
							page: page,
							table_search: this.table_search,
							table_range: this.table_range,
							table_col_name: this.table_col_name,
							table_order: this.table_order,
							
							zip_code: this.getFilter.zip_code,
							min_age: this.getFilter.min_age,
							max_age: this.getFilter.max_age,
							state_id: this.getFilter.state_id,
							cpt_id: this.getFilter.cpt_id,
							gender: this.getFilter.gender,
							visit: this.getFilter.visit,
							credit_card: this.getFilter.credit_card,
							problem_id: this.getFilter.problem_id,
							insurance_card: this.getFilter.insurance_card,
							chief_complaint_id: this.getFilter.chief_complaint_id,
							patient_portal_id: this.getFilter.patient_portal_id,
							mail: this.getFilter.mail,
							pending_documents_id: this.getFilter.pending_documents_id,
							appointment_start_date: this.getFilter.appointment_start_date,
							appointment_end_date: this.getFilter.appointment_end_date,
							join_start_date: this.getFilter.join_start_date,
							join_end_date: this.getFilter.join_end_date,
							appointment_daterange: this.getFilter.appointment_daterange,
							join_daterange: this.getFilter.join_daterange,
						};
					} else if (this.dataType == "BulkSignClinicalNotes") {
						var queryStr = {
							page: page,
							table_search: this.table_search,
							table_range: this.table_range,
							table_col_name: this.table_col_name,
							table_order: this.table_order,
							
							office_id: this.getFilter.office_id,
							provider_id: this.getFilter.provider_id,
							supervisor_id: this.getFilter.supervisor_id,
							note_status: this.getFilter.note_status,
							patient_id: this.getFilter.patient_id,
							from_date: this.getFilter.from_date,
							to_date: this.getFilter.to_date,
							search_text: this.getFilter.search_text,
						};
					} else if(this.dataType =="EmailTemplate") {
						var queryStr = {
							page: page,
							table_search: this.table_search,
							table_range: this.table_range,
							table_col_name: this.table_col_name,
							table_order: this.table_order,
							
							category: this.getFilter.category,
						};
					} else if(this.dataType =="PaymentGatewayMigration") {						
						var queryStr = {
							page: page,
							table_search: this.table_search,
							table_range: this.table_range,
							table_col_name: this.table_col_name,
							table_order: this.table_order,
							
							search: this.getFilter.search_data,
							filter: this.getFilter.filter_data,
						};
					} else if(this.dataType =="AppointmentStatusDashboard"){
						var queryStr = {
							page: page,
							table_search: this.table_search,
							table_range: this.table_range,
							table_col_name: this.table_col_name,
							table_order: this.table_order,
							
							to_date: this.getFilter.to_date,
							office_id: this.getFilter.office_id,
							from_date: this.getFilter.from_date,
							date_range: this.getFilter.date_range,
							visit_profile_id: this.getFilter.visit_profile_id,
							appointment_type: this.getFilter.appointment_type,
							appointment_status_id: this.getFilter.appointment_status_id,
						};
					} else if(this.dataType =="EligibilityResponses"){
						var queryStr = {
							page: page,
							table_search: this.table_search,
							table_range: this.table_range,
							table_col_name: this.table_col_name,
							table_order: this.table_order,

							date_range: this.getFilter.date_range,
							response_status: this.getFilter.response_status,
							patient_id: this.getFilter.patient_id,							
						};
					} else if(this.dataType =="AdvanceReport"){
						var queryStr = {
							page: page,														
							table_search: this.table_search,
							table_range: this.table_range,
							table_col_name: this.table_col_name,
							table_order: this.table_order,

							primary_provider: this.getFilter.primary_provider,
							first_name: this.getFilter.first_name,
							middle_name: this.getFilter.middle_name,
							last_name: this.getFilter.last_name,
							patient_status: this.getFilter.patient_status,
							address: this.getFilter.address,
							zipcode: this.getFilter.zipcode,
							city: this.getFilter.city,
							state: this.getFilter.state,
							home_phone: this.getFilter.home_phone,
							cell_phone: this.getFilter.cell_phone,
							office_phone: this.getFilter.office_phone,
							email: this.getFilter.email,
							dob: this.getFilter.dob,
							gender: this.getFilter.gender,
							ethnicity: this.getFilter.ethnicity,
							race: this.getFilter.race,
							visit_profile: this.getFilter.visit_profile,
							doctor: this.getFilter.doctor,
							office: this.getFilter.office,
							exam_room: this.getFilter.exam_room,
							comma_separated_codes: this.getFilter.comma_separated_codes,
							result_for: this.getFilter.result_for,
							appointment_status: this.getFilter.appointment_status,
							billing_status: this.getFilter.billing_status,
							last_id: this.getFilter.last_id,
							first_id: this.getFilter.first_id,
						};
					} else if(this.dataType =="CovidExaminationReport"){
						var queryStr = {
							page: page,
							table_search: this.table_search,
							table_range: this.table_range,
							table_col_name: this.table_col_name,
							table_order: this.table_order,

							is_speciman_collected: this.getFilter.is_speciman_collected,
							is_refund_requested: this.getFilter.is_refund_requested,							
							is_lab_report_received: this.getFilter.is_lab_report_received,							
							from_date: this.getFilter.from_date,							
							to_date: this.getFilter.to_date,							
							free_search: this.getFilter.free_search,							
							current_user_office_id: this.getFilter.current_user_office_id,							
						};
					} else if(this.dataType =="UnmatchedERA"){
						var queryStr = {
							page: page,
							table_search: this.table_search,
							table_range: this.table_range,
							table_col_name: this.table_col_name,
							table_order: this.table_order,
							
							start_date: this.getFilter.start_date,
							end_date: this.getFilter.end_date,
							range_of: this.getFilter.range_of,							
						};
					} else if(this.dataType == "patientIdentification"){
						var queryStr = {
							page: page,
							table_search: this.table_search,
							table_range: this.table_range,
							table_col_name: this.table_col_name,
							table_order: this.table_order,
							patinet_search: this.$store.state.patientIdentification.patinet_search,
							status: this.$store.state.patientIdentification.status,
						};
					} else {
						var date = new Date();
						var timezoneOffset = date.getTimezoneOffset();
						var hoursOffset = Math.abs(timezoneOffset / 60);
						var timeZone = timezoneOffset > 0 ? '-' : '+';
						timeZone += ('0' + hoursOffset).slice(-1) + ':' + ('0' + (timezoneOffset % 60)).slice(-2);
						var queryStr = {
							page: page,
							table_search: this.table_search,
							table_range: this.table_range,
							table_col_name: this.table_col_name,
							table_order: this.table_order,
							localTimezone: toString(timeZone),
						};
					}
				} else {
					var date = new Date();
					var timezoneOffset = date.getTimezoneOffset();
					var hoursOffset = Math.abs(timezoneOffset / 60);
					var timeZone = timezoneOffset > 0 ? 'sub' : 'add';
					timeZone += ('0' + hoursOffset).slice(-1) + ':' + ('0' + (timezoneOffset % 60)).slice(-2);
					console.log(timeZone)
					if (this.dataType == "CustomProcedures") {
						var queryStr = `?page=${page}&search_text=${this.getFilter.search_text}&table_search=${this.table_search}&table_range=${this.table_range}&table_col_name=${this.table_col_name}&table_order=${this.table_order}&localTimezone=${toString(timeZone)}`;
					} else {
						var queryStr = `?page=${page}&table_search=${this.table_search}&table_range=${this.table_range}&table_col_name=${this.table_col_name}&table_order=${this.table_order}&localTimezone=${timeZone}`;
					}
				}
				if(this.onLoadCall){
					await this.$store.dispatch("getDataTableContents", {
						dataType: this.dataType,
						method: this.dataMethod,
						queryStr: queryStr,
					});
				}
			}
		},
	},

	async created() {
		this.getData(1);
	},
};
</script>

<style scoped>
.pagination li a {min-width:auto;
	padding: 5px 10px;
}
.pagination li a.current{background-color: #0265a0;}

.pagination {
	padding-left: 50px;
}
span.ellipsis {
    padding: 0 1em;
}
</style>
