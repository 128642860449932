<template>
	<ul class="schedtabs nav nav-tabs text-center text-md-start" role="tablist">
		<li class="nav-item">
			<a class="nav-link active" data-bs-toggle="tab"	href="#appointmentTab" role="tab" id="tabappointment" @click="tabChange('appointment')">Schedule Appointment</a>
    	</li>
		<li class="nav-item">
			<a class="nav-link linkDisabled" id="tabvitals" data-bs-toggle="" @click="tabChange('vitals')" href="javascript:void(0)" data-href="#vitalsTab" role="tab">Vitals</a>
		</li>
		<li class="nav-item">
			<a class="nav-link linkDisabled" id="tabbilling" data-bs-toggle="" href="javascript:void(0)" data-href="#billingTab" @click="tabChange('billing')" role="tab">Billing</a>
		</li>
		<li class="nav-item">
			<a class="nav-link linkDisabled" data-bs-toggle="" href="javascript:void(0)" data-href="#logsTab" role="tab" @click="tabChange('logs')" id="tablogs">Log Communication</a>
		</li>    
		<li class="nav-item" v-if="permission_ids.split(',').includes('34')">
			<a class="nav-link linkDisabled" data-bs-toggle="" href="javascript:void(0)" data-href="#eligibilityTab" role="tab" @click="tabChange('eligibility')" id="tabeligibility">Eligibility</a>
		</li>
		<li class="nav-item">
			<a class="nav-link linkDisabled" data-bs-toggle="" href="javascript:void(0)" data-href="#auditlogTab" role="tab" @click="tabChange('auditlog')" id="tabauditlogs">Appointment Logs</a>
		</li>
    	<li class="nav-item m-2">
			<a href="javascript:void(0)" class="fw-600 text-decoration-underline" @click="opendocumentModal"><i class="fa fa-question-circle pt-1 fs-16"></i></a>
		</li>
	</ul>

    <!--document model start-->
    <div id="appointmentdocument" class="modal fade" tabindex="-1" role="dialog" data-bs-backdrop="static" data-bs-keyboard="false" 
        aria-labelledby="myModalLabel" aria-hidden="true" style="top:70px">
        <div class="modal-dialog modal-md">
          	<div class="modal-content">
				<div class="modal-header">
					<h3 class="modal-title fw-900 mt-0">Reference of Knowledge Base</h3>
				</div>
          		<div class="modal-body overflow-auto" style="max-height: 350px;">
              		<table class="table table-bordered">
						<thead>
							<tr>
								<th scope="col">No.</th>
								<th scope="col">Knowledge Base Articles</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>1</td>
								<td> <a :href="path+'how-to-schedule-new-appointments'" target="_blank">How To Schedule New Appointments?</a></td>
							</tr>
							<tr>
								<td>2</td>
								<td> <a :href="path+'how-to-overlap-for-appointment'" target="_blank">How To Overlap For Appointment?</a></td>
							</tr>
							<tr>
								<td>3</td>
								<td> <a :href="path+'how-to-make-recurring-appointment'" target="_blank">How To Make Recurring Appointment?</a></td>
							</tr>
							<tr>
								<td>4</td>
								<td> <a :href="path+'how-to-stop-recurring-appointments'" target="_blank">How To Stop Recurring Appointments?</a></td>
							</tr>
							<tr>
								<td>5</td>
								<td> <a :href="path+'how-to-edit-appointments-from-calendar'" target="_blank">How To Edit Appointments?</a></td>
							</tr>
							<tr>
								<td>6</td>
								<td> <a :href="path+'how-to-delete-appointment-from-calendar'" target="_blank">How To Delete Appointment?</a></td>
							</tr>
							<tr>
								<td>7</td>
								<td> <a :href="path+'how-to-add-vitals-with-appointment-from-calendar'" target="_blank">How To Add Vitals With Appointment From Calendar?</a></td>
							</tr>
							<tr>
								<td>8</td>
								<td> <a :href="path+'how-to-modify-icd-10-codes-cpt-codes-ndc-codes-and-modifiers-for-a-general-appointment-in-pureehr'" target="_blank">How To Modify ICD-10 Codes, CPT Codes, NDC Codes And Modifiers For An Individual Appointment In PureEHR?</a></td>
							</tr>
						</tbody>
              		</table>
              		<Search :key="searchKey"></Search>
          		</div>
				<div class="modal-footer">
					<button type="button" @click="searchReset()" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
				</div>
          	</div>
        </div>
    </div>
   	<!--document model end-->
  	<div v-if="data.appointment_number!=undefined && data.appointment_number!=null">
      <AppointmentInsuranceBadge :AppointmentNumber="data.appointment_number" />
  	</div>
  	<div class="tab-content tabcontent-border border border-secondary schedulerpopup">
    	<div class="tab-pane active" id="appointmentTab" role="tabpanel">
      		<div class="p-15">
        		<form>
          			<div class="row form-group mb-5">
						<label class="form-label m-0 col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2 text-md-end">
							Appointment Type
						</label>
						<div class="col-12 col-sm-12 col-md-8 col-lg-10 col-xl-10">
							<div class="row">
								<div class="col-3 col-sm-3 col-md-3 col-lg-2 col-xl-2 availdate">
									<input type="text" id="appointment_type_office" name="appointment_type" class="e-field"/>
								</div>
								<div class="col-3 col-sm-3 col-md-3 col-lg-2 col-xl-2 availdate">
									<input type="text" id="appointment_type_teleVisit" name="appointment_type" class="e-field"/>
								</div>
								<div class="col-3 col-sm-3 col-md-3 col-lg-2 col-xl-2 availdate">
									<input type="text" id="appointment_type_walkin" name="appointment_type" class="e-field"/>
								</div>
								<div class="col-3 col-sm-3 col-md-3 col-lg-2 col-xl-2 availdate">
									<input type="text" id="appointment_type_phone" name="appointment_type" class="e-field"/>
								</div>
								<div class="col-3 col-sm-3 col-md-3 col-lg-2 col-xl-2 availdate">
									<input type="text" id="appointment_type_break" name="appointment_type" class="e-field"/>
								</div>
							</div>
						</div>
          			</div>
					<div class="breakType d-none" style="padding:10px 0px">						
						<div class="row form-group mb-5">
							<label class="col-form-label m-0 col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2 text-md-end">
								Office <small class="text-danger asterisksign">*</small>
							</label>
							<div class="col-12 col-sm-12 col-md-8 col-lg-4 col-xl-4 availdate">
								<input type="text" id="break_office_id" name="break_office_id" class="e-field" style="width: 100%"/>
								<div id="break_officeError" class="validationError text-danger">
									Office field is required
								</div>
							</div>
							<label class="col-form-label m-0 col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2 text-md-end">Exam Room</label>
							<div class="col-12 col-sm-12 col-md-8 col-lg-4 col-xl-4 availdate">
								<input type="text" id="break_room_id" name="break_room_id" class=" e-input e-field" style="width: 100%"/>
							</div>
						</div>
						<div class="row form-group mb-5">
							<label class="col-form-label m-0 col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2 text-md-end">Provider <small class="text-danger asterisksign">*</small></label>
							<div class="col-12 col-sm-12 col-md-8 col-lg-4 col-xl-4 availdate">
								<input type="text" id="break_provider_id" name="break_provider_id" class="e-field"/>
								<div id="break_providerError" class="validationError text-danger"></div>
							</div>
						</div>
						<div class="row form-group mb-5">
							<label	class="col-form-label m-0 col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2 text-md-end">Reason</label>
							<div class="col-12 col-sm-12 col-md-8 col-lg-4 col-xl-4 availdate">
								<textarea name="break_reason" rows="2" id="break_reason" class="e-field form-control" placeholder="Reason"></textarea>
							</div>
							<label class="col-form-label m-0 col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2 text-md-end">Note </label>
							<div class="col-12 col-sm-12 col-md-8 col-lg-4 col-xl-4 ">
								<textarea name="break_note" rows="2" id="break_note" class="e-field form-control" placeholder="note"></textarea>
								<div id="break_noteError" class="validationError text-danger">
									Note field is required
								</div>
							</div>
						</div>
						<div class="row form-group mb-5">
							<label class="col-form-label m-0 col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2 text-md-end">Schedule Start Time</label>
							<div class="col-12 col-sm-12 col-md-8 col-lg-4 col-xl-4 availdate">
								<input id="break_StartTime" class="e-field " type="text" name="break_StartTime"/>
							</div>
							<label class="col-form-label m-0 col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2 text-md-end">Duration</label>
							<div class="col-12 col-sm-12 col-md-8 col-lg-4 col-xl-4">
								<div class="controls availdate position-relative">
									<input id="break_duration" class="e-field form-control" type="text" name="break_duration"/>
									<small class="form-text text-muted position-absolute fs-14" style="right: 60px;top: 5px;">Minutes</small>
									<div id="break_durationError" class="validationError text-danger">
										Duration field is required
									</div>
								</div>
							</div>
						</div>
						<div class="row form-group mb-5">
							 <label class="col-form-label m-0 col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2 text-md-end">Color</label>
							 <div class="col-12 col-sm-12 col-md-8 col-lg-4 col-xl-4">
								<input id="break_color_code" class="e-field" type="text" name="break_color_code"/>
							 </div>
						</div>
					</div>
					<div class="otherAppointment">
						
            			<div class="row form-group mb-5" v-if="data.type == 'Tele Health' && data.vcallroom != null">
              				<label class="col-form-label m-0 col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2 text-md-end">&nbsp;</label>
							<div class="col-12 col-sm-12 col-md-8 col-lg-4 col-xl-4">
								<a class="e-btn e-flat text-capitalize e-primary" target="_blank"  
								v-bind:href="'/#/telehealth/'+data.vcallroom+'/'+this.onlyDateFormat(data.schedule_time)+'/'+this.onlyTimeFormat(data.schedule_time)+'/'+this.onlyTimeFormat(data.EndTime)" style="font-size: 0.9rem !important;">
								<i class="fa fa-video-camera"></i> Join call 
								</a>
							</div>
            			</div>
						<div class="row form-group mb-5">
							<label class="col-form-label m-0 col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2 text-md-end"
								>Office <small class="text-danger asterisksign">*</small></label>
							<div class="col-12 col-sm-12 col-md-8 col-lg-4 col-xl-4 availdate">
								<input id="Office" class="e-field " type="text" name="Office" />
								<div id="officeError" class="validationError text-danger">
									Office field is required
								</div>
								<div id="officeScheduleError" class="text-danger"></div>
							</div>
							<label class="col-form-label m-0 col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2 text-md-end">Exam Room</label>
							<div class="col-12 col-sm-12 col-md-8 col-lg-4 col-xl-4 availdate">
								<input type="text" id="Room" name="Room" class=" e-input e-field" style="width: 100%"/>
							</div>
						</div>
						<div class="row form-group mb-5">
							<label class="col-form-label m-0 col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2 text-md-end">Provider <small class="text-danger asterisksign">*</small></label
							>
							<div class="col-12 col-sm-12 col-md-8 col-lg-4 col-xl-4 availdate">
								<input type="text" id="Provider" name="Provider" class="e-field"/>
								<div id="providerError" class="validationError text-danger"></div>
								<div id="providerScheduleError" class="text-danger"></div>
							</div>
							<label class="col-form-label m-0 col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2 text-md-end">Supervising Manager</label>
							<div class="col-12 col-sm-12 col-md-8 col-lg-4 col-xl-4 availdate">
								<input id="Supervisor" class="e-field " type="text" name="Supervisor"/>
								<div id="supervisorError" class="validationError text-danger">
									Supervisor field is required
								</div>
							</div>
						</div>
						<div class="row form-group mb-5">
							<label class="col-form-label m-0 col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2 text-md-end"
								>Patient <small class="text-danger asterisksign">*</small></label>
							<div class="col-12 col-sm-12 col-md-8 col-lg-4 col-xl-4 availdate d-flex justify-content-between">
								<div class="w-p100 me-1">
									<input id="Patient" class="e-field" type="text" name="Patient"/>
									<div id="patientError" class="validationError text-danger">
										Patient field is required <small class="text-danger asterisksign">*</small>
									</div>
								</div>
								<Popper arrow content="Add New Patient" :hover="true" class="helptips cursor-pointer">
									<a href="javascript:void(0)" class="waves-effect waves-light btn btn-info-light btn-xs py-5" @click="add_patient">
										<i class="fa fa-plus" />
									</a>
								</Popper>
								<Popper arrow content="Edit Patient" :hover="true" class="helptips cursor-pointer">
									<a href="javascript:void(0)" class="waves-effect waves-light btn btn-info-light btn-xs py-5 edit_patient" @click="add_patient('edit',$event)">
										<i class="fa fa-pencil" />
									</a>
								</Popper>
								<div class="portal_data">
									<Popper arrow content="Invite to PurePORTAL" :hover="true" class="helptips cursor-pointer">
										<a href="javascript:void(0)" class="waves-effect waves-light btn btn-info-light btn-xs py-5 portal_active" @click="accesspPatientportal">
											<i class="fa fa-share-square-o" aria-hidden="true"></i>
										</a>
									</Popper>
								</div>
								<div class="portal_question d-none">
									<Popper arrow content="Send to question" :hover="true" class="helptips cursor-pointer">
										<a href="javascript:void(0)" class="waves-effect waves-light btn btn-info-light btn-xs py-5 portal_active" @click="accessQuestionnarie">
												<i class="fa fa-share-square-o" aria-hidden="true"></i>
										</a>
									</Popper>
								</div>
              				</div>
              				<label class="col-form-label m-0 col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2 text-md-end">Staff</label>
							<div class="col-12 col-sm-12 col-md-8 col-lg-4 col-xl-4 availdate">
								<input id="WellnessCoordinator" class="e-field " type="text" name="WellnessCoordinator"/>
								<div id="WellnessCoordinatorError" class="validationError text-danger">
									Staff field is required
								</div>
							</div>
            			</div>
						<div class="row justify-items-center form-group mb-5 d-none patient_package">
							<div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
								<div id="package-section"  class="row justify-items-center align-items-center">
									<label class="col-form-label m-0 col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 text-md-end">Package</label>
									<div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 pt-5">
										<div class="availdate">
											<input id="Package" class="e-field" type="text" name="Package" />
											<div id="packageapp" class="validationError text-danger">Selected package will not be changed or removed once the appointment is saved successfully.</div>
											<div id="packageapp1" class="validationError text-danger">Adding a package is not currently available as the billing has been charged for this appointment.</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="row justify-items-center form-group mb-5">
							<div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
								<div id="allow-overlapping-section"  class="row justify-items-center">
									<label class="col-form-label m-0 col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4 text-md-end">Allow Overlapping</label>
									<div class="col-12 col-sm-12 col-md-8 col-lg-4 col-xl-4 pt-5" style="display: inline-block;">
										<input id="allow_overlapping" class="e-field" type="text" name="allow_overlap" />                 
									</div>
								</div>
							</div>
							<div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
								<div id="is-provider-occupied-section"  class="row justify-items-center" style="display:none;">
								<label class="col-form-label m-0 col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4 text-md-end">Is Provider Needed?</label>
									<div class="col-12 col-sm-12 col-md-8 col-lg-4 col-xl-4" style="display: inline-block;">
										<input id="is_provider_occupied" class="e-field" type="text" name="is_provider_occupied"/>
									</div>
								</div>
							</div>
						</div>
            			<div class="row form-group mb-5">
							<label class="col-form-label m-0 col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2 text-md-end">
								Visit Profile 
								<Popper arrow content="No billable amount for this visit profile" style="display: none;" :hover="true" class="helptips cursor-pointer" id="showNoBillableInfo">
								<div class="inline-block"><i class="fa fa-info-circle text-danger fs-12"></i></div>
								</Popper>
							</label>
							<div class="col-12 col-sm-12 col-md-8 col-lg-4 col-xl-4">
								<div class="availdate d-flex">
									<div class="position-relative w-p100">
										<input id="ApptProfile" class="e-field" type="text" name="ApptProfile"/>
										<span class="text-dark my-5 fs-14 fw-500 d-block" id="showNoBillableAmount" style="display: none;z-index: 0;"></span>
									</div>
									<div class="ms-2 w-p20">
										<input id="ApptColor" class="e-field " type="text" name="color_code"/>
									</div>
								</div>
							</div>
              				<label class="col-form-label m-0 col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2 text-md-end">Duration</label>
							<div class="col-12 col-sm-12 col-md-8 col-lg-4 col-xl-4">
								<div class="controls availdate position-relative">
									<input id="duration" class="e-field form-control" type="text" name="duration"/>
									<small class="form-text text-muted position-absolute fs-14" style="right: 60px;top: 5px;">Minutes</small>
								</div>
							</div>
						</div>
						<div class="row form-group mb-5">            
							<label class="col-form-label m-0 col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2 text-md-end"
								>Consent Forms</label>
								<div class="col-12 col-sm-12 col-md-8 col-lg-4 col-xl-4">
									<div class="d-flex justify-content-between">
										<div class="availdate w-p100">
											<input id="consent_form_ids" class="e-field " type="text" name="consent_form_ids"/>
										</div>
										<button id="consentFormSigned" class="ms-5 b-0 text-success fs-12 constinfo_btn"><i class="fa fa-info-circle"></i></button>
									</div>
								</div>
							<label class="col-form-label m-0 col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2 text-md-end">Reason <small class="text-danger asterisksign">*</small></label>
							<div class="col-12 col-sm-12 col-md-8 col-lg-4 col-xl-4 ">
								<textarea name="reason_for_visit" rows="2" id="reason_for_visit" class="e-field form-control" placeholder="Reason for visit"></textarea>
								<div id="reasonError" class="validationError text-danger">
									Reason field is required
								</div>
							</div>
						</div>
						<div class="row form-group mb-5">
							<label class="col-form-label m-0 col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2 text-md-end"
								>Schedule Start Time</label>
							<div class="col-12 col-sm-12 col-md-8 col-lg-4 col-xl-4 availdate">
								<input id="StartTime" class="e-field " type="text" name="StartTime"/>
								<div id="startTimeError" class="validationError text-danger">The Provider is available to take only televisit. Please book the appointment as "Tele Health".</div>                
								<a id="DisplayAvailableSlots" href="javascript:void(0)" class="fs-14 fw-600 text-decoration-underline d-none" data-bs-toggle="modal" data-bs-target="#displayavailableslotsmodal">Click Here to view free slots</a>
							</div>
							<label class="col-form-label m-0 col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2 text-md-end"
								>End Time</label>
							<div class="col-12 col-sm-12 col-md-8 col-lg-4 col-xl-4 availdate">
								<input id="EndTime" class="e-field " type="text" name="EndTime"/>
							</div>
						</div>						
						<div class="row form-group mb-5">
							<label class="col-form-label m-0 col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2 text-md-end">Status <small class="text-danger asterisksign">*</small></label>
							<div class="col-12 col-sm-12 col-md-8 col-lg-4 col-xl-4 availdate">
								<input type="text" id="Status" name="Status" class="e-field" style="width: 100%"/>
								<div id="statusError" class="validationError text-danger">
									Status field is required
								</div>
							</div>
							<label class="col-form-label m-0 col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2 text-md-end">Notes</label>
							<div class="col-12 col-sm-12 col-md-8 col-lg-4 col-xl-4">
								<textarea name="extra_notes" rows="2" id="notes" class="e-field form-control" placeholder="Notes"></textarea>
							</div>
						</div>
						<div class="row form-group mb-5 d-none" id="rescheduleSection">
							<label class="col-form-label m-0 col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2 text-md-end"
								>Rescheduled Time <small class="text-danger asterisksign">*</small></label>
							<div class="col-12 col-sm-12 col-md-8 col-lg-4 col-xl-4 availdate">
								<input type="text" id="reschedule_time" name="reschedule_time" class="e-field" style="width: 100%"/>
								<div id="rescheduledError" class="validationError text-danger">
									Rescheduled Time field is required
								</div>
								<a id="DisplayAvailableSlotsReschedule" href="javascript:void(0)" class="fs-14 fw-600 text-decoration-underline d-none" data-bs-toggle="modal" data-bs-target="#displayavailableslotsmodal">Click Here to view free slots</a>
							</div>
						</div>
						<div class="row form-group mb-5" id="crm_prospect_patient_status_div">
							<label class="col-form-label m-0 col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2 text-md-end"
								>CRM Status</label>
							<div class="col-12 col-sm-12 col-md-8 col-lg-4 col-xl-4 availdate">
								<input id="crm_prospect_patient_status" class="e-field " type="text" name="crm_prospect_patient_status" />
								<div id="crm_prospect_patient_status_error" class="validationError text-danger">
									CRM Status is required
								</div>
							</div>
						</div>
					</div>				 
					<a href="javascript:void(0)" target="_blank" class="d-none float-end waves-effect waves-light btn btn-info-light" id="viewNote" title="View/Create Clinical Notes"> <i class="fa fa-file-text fs-14"></i> Patient Chart</a>
					<div class="p-10 bg-lighter mt-20">
						<div class="row form-group mb-0">
							<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex">
								<input type="text" name="is_recurring" id="is_recurring">
							</div>
						</div>
						<div class="row form-group mb-0 mt-40 d-none" id="Recurcontainer">
							<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 availdate d-flex">
								<div id="container">
										<div id="RecurrenceEditor" class="recurrenceedit_time" ref='EditorObj'></div>
								</div>
							</div>
						</div>
					</div>          
					<div class="d-none my-10 fs-14 " id="unavailable-slots">
						<label class="text-danger">Time Slot Not Available</label> <a href="javascript:void(0)" class="fw-600 text-decoration-underline" @click="openSlotPopup">Click Here to view free slots</a>
					</div>
					<div class="my-10 fs-14">
						 <label class="text-danger validationError" id="sameDayError">The schedule date and day doesn't match</label>
					</div>
					<div id="recurringscheduling" data-backdrop="static" data-keyboard="false" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="recurringscheduling" aria-hidden="true">
						<div class="modal-dialog modal-md">
						 <div class="modal-content">
							<div class="modal-header d-block">
								 <h3 class="modal-title fw-900 mt-0">Select a new time slot</h3>
								 <p class="fs-14 mb-0">There is/are already appointment(s) booked for date/time/room. Please select time from free slots listed below:</p>
							</div>
							<div class="modal-body"></div>
							<div class="modal-footer">
								 <button type="button" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-info slot_book" >Schedule</button>
								 <button type="button" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
							</div>
						 </div>
						</div>
					</div>
					<div class="p-10 bg-lighter mt-10">
						<div class="row form-group mb-0">              
							<div class="">                
								<input id="reminderProfileCheck" @change="checkRemProfileChange" v-model="reminderProfileCheck" type="checkbox" :checked="reminderProfileCheck" class="filled-in chk-col-info">
								<label for="reminderProfileCheck" id="reminderProfileCheckLabel" class="fs-16 mb-0">View Active Reminders</label>
							</div>
						</div>
						<div v-if="reminderProfileCheck">
							<div class="form-group row align-items-center">
								<div class="col-12 col-sm-6 col-md-4 col-lg-3 text-sm-end">
									<label class="form-label fs-16">Choose a Reminder Profile</label>
								</div>
								<div v-if="this.reminderProfileList.length==0" class="col-12 col-sm-12 col-md-8 col-lg-3">
									<b>No Profiles Are Added</b>
									<Popper arrow content="Add New Profile" :hover="true" class="helptips cursor-pointer">
										<a href="javascript:void(0)" class="waves-effect waves-light btn btn-info-light btn-xs py-5" @click="add_profile">
											<i class="fa fa-plus" />
										</a>
									</Popper>
								</div>
								<div v-else class="col-12 col-sm-12 col-md-8 col-lg-3">
									<select  name="select" v-model="reminderProfilevalue" id="reminderProfilevalue" class="form-select" @change="reminder_list" :disabled="reminderProfilestatus">
										<option value="" selected="selected" disabled="disabled">Select Reminder Profile</option>
										<option v-for="(item,index) in reminderProfileList" :key="index" :value="item.id"> <slot v-if="item.is_default == 'yes'" :set="reminderProfilevalue = item.id"></slot>{{item.name}}</option>
									</select>
								</div>
							</div>
							<div v-for="(item,index) in reminderdata" :key="index">
								<div class="form-group row align-items-center reminderProfileDataLoop" :id="'form'+index">
									<div class="col-12 col-md-8">
										<div class="row">
											<div class="col-12 col-md-2">
												<select name="select" class="form-select form-control reminder_type" v-model="reminderdata[index].reminder_type" :disabled="reminderProfilestatus">
													<option value="email" :selected="reminderdata[index].reminder_type == 'email'">E-Mail</option>
													<option value="phone" :selected="reminderdata[index].reminder_type == 'phone'">Phone</option>
													<option value="sms" :selected="reminderdata[index].reminder_type == 'sms'">SMS</option>
												</select>
											</div>
											<div class="col-12 col-md-2" :id="'reminder_value_'+index">
												<input type="text" :id="'reminder_value'+index" class="form-control reminder_value" placeholder="1" :value="reminderdata[index].reminder_value" :disabled="reminderProfilestatus">
											</div>
											<div class="col-12 col-md-2">
												<select name="select" class="form-select form-control reminder_unit_type" v-model="reminderdata[index].reminder_unit_type" :disabled="reminderProfilestatus">
													<option value="days" :selected="reminderdata[index].reminder_unit_type == 'days'">Day(s)</option>
													<option value="hours" :selected="reminderdata[index].reminder_unit_type == 'hours'">Hour(s)</option>
												</select>
											</div>
											<div class="col-12 col-md-4" :id="'reminder_unit_before_or_after_'+index">
												<select name="select" class="form-select form-control reminder_unit_before_or_after" :id="'reminder_unit_before_or_after'+index" :disabled="reminderProfilestatus">
													<option value="" selected="selected" disabled="disabled">Select Value</option>
													<option value="before" :selected="reminderdata[index].reminder_unit_before_or_after == 'before'">Before Appointment</option>
													<option value="after"  :selected="reminderdata[index].reminder_unit_before_or_after == 'after'">After Appointment</option>
												</select>
											</div>
											<div class="col-12 col-md-2" :id="'reminder_content_'+index">
											 <input type="hidden" :id="'reminder_content'+index" class="form-control reminder_content" :value="reminderdata[index].reminder_content">
											</div>
										</div>
									</div>
									<div class="col-12 col-md-4 text-end" v-if="!reminderProfilestatus">
										<button @click="delete_reminder(index)" type="button" class="waves-effect waves-light btn btn-danger btn-sm me-5"><i class="fa fa-trash" aria-hidden="true"></i></button>
										<button @click="reminderview(index)" type="button" class="waves-effect waves-light btn btn-info btn-sm me-5" data-bs-toggle="modal" data-bs-target="#mypreviewModal"><i class="fa fa-eye"></i> Preview</button>
									</div>
									<div class="col-12 col-md-4" v-if="reminderProfilestatus">
										<span class="badge badge-success-light border border-success" v-if="reminderdata[index].reminder_status">Sent</span>
										<span class="badge badge-danger-light border border-danger" v-else>Failed</span>
									</div>
								</div>
							</div>
						</div>
					</div>          
				</form>
			</div>        
		</div>
		<!--Reminder email preview Modal start -->
		<div id="mypreviewModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
			<div class="modal-dialog modal-lg" style="margin-top: 105px;">
				<div class="modal-content">
					<div class="modal-header">
						<h2 class="modal-title" id="myModalLabel">Email Message Preview</h2>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body">
						<div class="d-flex mb-3">
							<strong>Subject: </strong>{{ remindersubjectline}}
						</div>
						<div v-html="reminderbody_content"></div>
					</div>
					<div class="modal-footer">
						<button type="button" class="addpatientbtn btn-danger b-0" data-bs-dismiss="modal">Close</button>
					</div>
				</div>
			</div>
		</div>
		<!--Reminder email preview Modal End -->
		<div class="tab-pane" id="vitalsTab" role="tabpanel">
			<patientVitals :patientID="data.patient_id" :ScheduleDate="data.schedule_date" :Appointmentid="data.id" :billingStatus="data.billing_status"></patientVitals>
		</div>
		<div class="tab-pane" id="billingTab" role="tabpanel">
			<div class="p-15" v-if="data.appointment_number != undefined">
				<AppointmentBilling :AppointmentNumber="data.appointment_number" :uniqueId="data.unique_id" :paymentProfile="data.payment_profile" :billingStatus="data.billing_status" />
			</div>
		</div>
		<div class="tab-pane" id="logsTab" role="tabpanel">
			<phone-log :patientID="data.patient_id" :Appointmentid="data.id"></phone-log>
		</div>
		<div class="tab-pane" id="revision" role="tabpanel">
			<div class="p-15">
				<div class="pageheading">
					<h3 class="font-bold mt-0">Note Information</h3>
				</div>
				<div class="table-responsive mb-20">
					<table class="table table-hover mb-0">
						<thead>
							<tr>
								<th>PDF Generated At</th>
								<th>Locked</th>
								<th>Supervisor Locked</th>
								<th>Action</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>Aug 30, 2021 9:40 AM</td>
								<td>-</td>
								<td>-</td>
								<td>
									<a
										href="javascript:void(0);"
										class="waves-effect btn btn-info btn-xs"
										><i class="fa fa-eye"></i> View PDF</a
									>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="pageheading">
					<h3 class="font-bold mt-0">PDF Revisions</h3>
					<p class="fs-16 mb-10">
						A revision is saved when the note is locked, an amendment is
						added, and sometimes while editing an unlocked note
					</p>
				</div>
				<div class="table-responsive mb-20">
					<table class="table table-hover mb-0">
						<thead>
							<tr>
								<th>PDF Generated At</th>
								<th>
									Locked
									<i
										class="fa fa-info-circle fs-12"
										title="was the note locked when this PDF was created?"
									></i>
								</th>
								<th>
									Supervisor Locked
									<i
										class="fa fa-info-circle fs-12"
										title="was the note locked by the supervisior when this PDF was created?"
									></i>
								</th>
								<th>Action</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>Sep 23, 2021 4:40 PM</td>
								<td>No</td>
								<td>No</td>
								<td>
									<a
										href="javascript:void(0);"
										class="waves-effect btn btn-info btn-xs"
										><i class="fa fa-eye"></i> View PDF</a>
								</td>
							</tr>
							<tr>
								<td>Sep 23, 2021 4:40 PM</td>
								<td>No</td>
								<td>No</td>
								<td>
									<a
										href="javascript:void(0);"
										class="waves-effect btn btn-info btn-xs"
										><i class="fa fa-eye"></i> View PDF</a
									>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
		<div class="tab-pane" id="eligibilityTab" role="tabpanel">
			<div class="p-15" v-if="data.appointment_number != undefined">
				<EligibilityCheck :patient_id="data.unique_id" :appointment_id="data.id"/>
			</div>
		</div>
		<div class="tab-pane" id="auditlogTab" role="tabpanel">
			<div class="p-15" v-if="data.appointment_number != undefined">
				<AuditLogs :patient_id="data.unique_id" :appointment_id="data.id" :recurring_id="data.recurring_id"/>
			</div>
		</div>
	</div>
</template>
<script>
import AppointmentBilling from "@/views/Appointments/AppointmentBilling.vue";
import AuditLogs from "@/views/Appointments/AuditLogs.vue";
import PatientVitals from "@/views/Appointments/Vitals.vue";
import PhoneLog from "@/views/Appointments/PhoneLog.vue";
import axios from "@/scripts/axios.js";
import Popper from "vue3-popper";
import moment from "moment";
import EligibilityCheck from "@/components/patients/details/PatientInformation/EligibilityCheck.vue";
import AppointmentInsuranceBadge from "@/components/Appointments/AppointmentInsuranceBadge.vue";
import Search from "@/components/Search.vue";
export default {
  	components: { AppointmentBilling,PatientVitals,PhoneLog, Popper,EligibilityCheck, AppointmentInsuranceBadge, Search,AuditLogs },
   	props:{
    	pp_id:Number,
  	},
  	data() {
		return {
			permission_ids: localStorage.getItem('permission'),
			role_id: localStorage.getItem('role_id'),
			recurrencedate: [],
			reminderProfileCheck: false,      
			reminderProfileList: [],
			emailTemplateList: [],
			reminderProfilevalue: null,
			reminderdata: {},
			remindersubjectline: '',
			reminderbody_content: '',
			reminder_content:'',
			path: process.env.VUE_APP_KB_DOCUMENT,
			searchKey:0,
			patient_portal:0,
			vitalsauditlog:0,
			billingauditlog:0,
			logsauditlog:0,
			reminderProfilestatus: false,
			reminder_type: {},
			toolTip:'',
		};
  	},  
  	async beforeCreate() {		
    	axios.get('/reminder/list').then((response) => {
       		this.reminderProfileList = response.data.data;	 
      	});
      	axios.post('/email-template/list').then((response) => {
       		this.emailTemplateList = response.data.data 
      	});	 
  	},
	mounted(){
		
	},
	created(){
		this.reminderdata = {};
		this.reminderProfilevalue = null;
		this.reminderProfilestatus = false;
		this.reminderProfilevalue = null;
		this.reminderProfileCheck = this.data.is_reminder ? true : false;
		if(this.data.reminderdata && this.data.reminderdata.length>0) {
			this.reminderdata = this.data.reminderdata;
			this.reminderProfilevalue = this.data.reminderdata[0].reminder_profile_id;
			this.data.reminderdata.map((item) => {
				if(item.reminder_status){
					this.reminderProfilestatus = true;
				}
			});
		}
	},   
  	methods: {
		onlyDateFormat(date) {
			if(date) {
				let dt_format = "YYYY-MM-DD";
				return moment(date).format(dt_format);			
			}else{
				return "";
			}
		},
		onlyTimeFormat(time) {
			if (time) {
				let dt_format =   "HH:mm:ss";
				return moment(time).format(dt_format);			
			} else {
				return "";
			}
		},
		searchReset() {
			window.location.reload();
		},
		toUSD (value) {
			if (value) {
				return `$${parseFloat(value).toFixed(2)}`;
			} else {
				return `$${parseFloat(0).toFixed(2)}`;
			}
		},
		redirect_location(id) {
			let origin = location.origin;
			document.getElementsByClassName('e-event-cancel')[0].click();
			location.href = origin+"/#/patient-chart/"+id+"/payment/billing";
		},
		accessQuestionnarie(){
			var appointment_id=$(".portal_question").attr('data-appointment_id');
			var patient_id=$(".portal_question").attr('data-patient_id');
			var clinic_id=$(".portal_question").attr('data-clinic_id');
			axios.post("patient/pp-questionnarie",{appointment_id:appointment_id,patient_id:patient_id,clinicId:clinic_id})
				.then((response) =>{});		
		},
		accesspPatientportal(){
			var id =  $(".portal_active").attr('data-id');
			var email = $(".portal_active").attr("data-email");
			var dob  = $(".portal_active").attr("data-dob");
			var pp_id = $(".portal_active").attr("data-ppid");
			var cell_phone = $(".portal_active").attr("data-cellphone");
			var unique_id = $(".portal_active").attr("data-uniqueid");
			var patient=$("#Patient").val();
			var pp_status=$(".portal_active").attr("data-pp_status");		
			if (patient=="") {
				alert('Please select patient');
			}else if(pp_id != 0){
				if (pp_status==1) {
					patient_portal=0;
					alert('Please Enable patient portal');
				}else{
					patient_portal=1;
					alert('PurePORTAL Already Activated');
				}			
			}else{
				if(cell_phone != null && email != null && dob != null){
					var dob = moment(dob).format('YYYY-MM-DD');
					axios.post("patient/pp-active",{unique_id:unique_id,email:email,dob:dob,isValid:0,patient_id:id,cell_phone:cell_phone}).then((response) =>{
						if (response.data.status == 'success') {
							alert('The patient will receive an email to add the PurePORTAL password.');
						} else {
							alert(response.data.message);
						}						
					});
				}
				else{
					alert('Cell Phone,Email & DOB are missing');
				}
			}
		},
		add_profile(){
			window.open("/#/reminder-profiles", '_blank'); 
		},
		add_patient(mode,$event){
			if(mode == 'edit'){
				var unique_id = $($event.target).parent().attr("data-id");
				if(unique_id != undefined){
					window.open("/#patient-chart/"+unique_id,"_blank");
				}
			}else{
				window.open("/#/patient/add", '_blank');
			}
		},		
		openSlotPopup(){
			$("#recurringscheduling").modal("show");
			setTimeout(function(){
				$("div.modal-backdrop").addClass("position-inherit")
			}, 100);
		},
		opendocumentModal() {
			this.searchKey = this.searchKey+1;
			$("#appointmentdocument").modal("show");
			$("div#appointmentdocument").css("font-size","medium");
			setTimeout(function(){
				$("div.modal-backdrop").addClass("position-inherit");
			}, 100)
		},
		toggleHtml(el) {
			if (this.$refs[el].checked) {
				this.$refs[`${el}_html`].classList.add("d-block");
			} else {
				this.$refs[`${el}_html`].classList.remove("d-block");
			}
		},
		tabChange(tab) {
			if((tab != "appointment" && !$(".nav-link").hasClass("linkDisabled"))){
				$(".e-event-save, .e-event-cancel, .e-event-delete").addClass("d-none");
			}else{
				$(".e-event-save, .e-event-cancel, .e-event-delete").removeClass("d-none"); 
			}
			if(tab=="vitals"&&this.vitalsauditlog==0){
				var unique_id = $(".portal_active").attr("data-uniqueid");
				const result = axios.post("vital/audit-log", {
					patient_id:unique_id,
				});
				this.vitalsauditlog++;
			}else if(tab=="billing"&&this.billingauditlog==0){
				var unique_id = $(".portal_active").attr("data-uniqueid");
				const result = axios.post("appointment-billing/audit-log", {
					patient_id:unique_id,
				});
				this.billingauditlog++;
			}else if(tab=="logs"&&this.logsauditlog==0){
				var unique_id = $(".portal_active").attr("data-uniqueid");
				const result = axios.post("appointment/phonelog/audit-log", {
					patient_id:unique_id,
				});
				this.logsauditlog++;
			}else if(tab=="auditlog"){
				$(".e-event-save, .e-event-cancel, .e-event-delete").addClass("d-none");
			}
			if(!$(".nav-link").hasClass("linkDisabled")){
				$(".schedtabs").find(".nav-link").removeClass('active');
				$(".schedtabs").find("#"+tab+"ID").addClass('active');
				$(".schedulerpopup").find(".tab-pane").removeClass('active');
				$(".schedulerpopup").find("#"+tab+"Tab").addClass('active');
			}
		},
		reminder_list(){      
			var id = this.reminderProfilevalue;
			if(id){
				axios.post('/reminder/edit',{id:id})
				.then((response) => {
					this.reminderdata  = response.data.data.reminderdata;
				});
			}
		},
		checkRemProfileChange(){
			if(reminderProfileCheck){
				this.reminder_list();
			}
		},
		delete_reminder(index){
			this.reminderdata.splice(index, 1);
		},
		reminderview(index){
			var id = this.reminderdata[index].reminder_content;
			axios.get('email-template/edit/'+id)
			.then((response) => {
				this.remindersubjectline = response.data.data.subject_line
				this.reminderbody_content = response.data.data.body_content
				setTimeout(function(){
				$("div.modal-backdrop").addClass("position-inherit");
				}, 100);
			});
		},
  	},
};
</script>
<style scoped>
.validationError {
	color: #ee3158;
	display: none;
}
label.col-form-label{font-size: 1rem;}
</style>
