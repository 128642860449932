<template>
    <div class="box">
        <div class="box-body">
            <div class="pageheading">
                <div class="row justify-content-between">
                  <div class="col-12 col-sm-7 col-md-7 col-lg-6 col-xs-6">
                    <h3 class="font-bold">Current Allergy List <i class="fa fa-refresh" style="cursor:pointer;"  @click="downloadActiveMedsAndAllergyByPatient" aria-hidden="true"></i></h3>
                  </div>
                  <div class="col-12 col-sm-5 col-md-5 col-lg-6 col-xs-4 text-sm-end">
                    <a href="javascript:void(0)" class="waves-effect waves-light btn btn-info mb-10 pull-right" @click="launchRxnt"><i class="fa fa-plus fs-14"></i> Allergy</a>
                    <div class="bg-danger-light py-5 rounded5 fs-14 mb-10 w-p75 text-center" role="alert"
                        v-if="errorMessage">
                        {{ errorMessage }}
                      </div>
                  </div>
                </div>
            </div>
            <div class="table-responsive">
                <table class="table table-hover">
                    <thead>
                      <tr>
                        <th>Allergy Description</th>
                        <th>Reaction</th>
                        <th>Comments</th>
                        <th>Date Added</th>
                      </tr>
                    </thead>
                    <tbody>
                        <tr v-if="allergy.length>0" v-for="item in allergy">
                            <td>{{item.AllergyDescription}}</td>
                            <td>{{item.ReactionString}}</td>
                            <td>{{item.Comments}}</td>
                            <td>{{ $filters.displayDateTimeLocal(item.DateAdded,'',false,"ddd, MM-DD-YYYY",true) }}</td>
                        </tr>
                        <tr v-else>
                            <td colspan="4" class="text-center">No data available</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
    import axios from "@/scripts/axios.js";
    export default {
      components: {},
      data() {
        return {
            allergy:[],
            sso_token : '',
            return_url : btoa(window.location.href),
        };
      },
      watch: {},
      created() {
            this.fetchLocalActiveMed();
      },
      mounted() {
         console.log("mounted")
      },
      methods: {
       launchRxnt : function() {
           this.$store.state.loader = true;
           this.errorMessage = '';
           axios
           .post("/createSSOString", { patient_id : this.$store.state.patientDetail.patient_id })
           .then((response) => {
                this.sso_token = response.data.data.sso_token;
                if (this.sso_token) {
                    var form = document.createElement("form");
                    form.setAttribute("method", "post");
                    form.setAttribute("action", "https://app.rxnt.com/ehrv8/SingleSignOnProcessor.ashx?OTO=1");

                    var hiddenInput = document.createElement('input');
                    hiddenInput.setAttribute('type', 'hidden');
                    hiddenInput.setAttribute('name', 'data');
                    hiddenInput.setAttribute('value', this.sso_token);
                    /*hiddenInput.setAttribute('value', 'Ay+CWnWhzbdBHvJzYRsXH5MGTcrQaOKqG0Qq2PoTdpMHHqbt1wyMzkregWZzRYZgIZwMOvWnKYw3K0NRQeXY1eepe9FnbBxz2BlHj98rBzK7z/qV3UIwjiDdSIwk3lBXMqwFfVPs3/YGKvoj2p7oY6U7JYgsLG0/12iBk8gst6BbSzNlc+gKlrCOGBMUtWrbQVudyctJhXT3bek4ovchhfchbzEsQAeWzPGdUsdUC/C8oGcvOrYg2cXKmF77sPoes4R95SVBpid4OX4siNb7UOJitT/Xq82P2TDOLPG+nGULO0F0yOl+WRB4l4bnoW802VpHXCvIpdWoZDvuQidAYQiOrHPsOsk3dZmaO+yosZaBmJwzRm/APVDrZXrl0kiIQ8N7PreAKcavclPZkSvO4ZP6gwQ27db91cbg43EuzNR3woIpg1Mc1qQmF5ofuKOw/xM27ocB7SDSidAT2RblJhGawrvNbbADZJ7V8GGrModAygVixC3NB9DBrSQ5rJgJofTwBckEIZGwFbpvWcP2JOKiu5/7Mopr4+WPuVtNtKK1QNAxVxz90p7jqTIJaL9wwflR4/U0fbBIKeI3UwOYRecyUm5f0SvYqa6ffCGlD/EwaWovu6Fv5BIMBu9/EuVUlS8h7W0PVjZhzQoj7DKsesycsx/64xLE6W3ZVK13OwyCMZaVyHkE6iWjoiPfV6NPcHwe10Mhi95/+2NbCbFXj+mVYTlgifhC7yXZmuQeWw34CbzEooOzxBrcLKU1sMhHg1zxw8JwvVoSXTLdcU9W2l1NReKPFWbKumqsAYV5Nvi4AckiQQQWOUHxSAX4XFbRsCr6MTaUprlP+tTlc+N5LFLAP42XCw4csCJA5BQ7X28=');*/
                    form.appendChild(hiddenInput);

                    var hiddenInput2 = document.createElement('input');
                    hiddenInput2.setAttribute('type', 'hidden');
                    hiddenInput2.setAttribute('name', 'returnURL');
                    hiddenInput2.setAttribute('value', this.return_url);
                    form.appendChild(hiddenInput2);

                    var hiddenInput3 = document.createElement('input');
                    hiddenInput3.setAttribute('type', 'hidden');
                    hiddenInput3.setAttribute('name', 'externalorderid');
                    hiddenInput3.setAttribute('value', 'MQ==');
                    form.appendChild(hiddenInput3);

                    document.body.appendChild(form);
                    function submitToPopup(f) {
                        var w = window.open('', 'form_open', 'height=600,width=1200');
                        f.target = 'form_open';
                        f.submit();
                    };
                    submitToPopup(form);
                   console.log(this.sso_token);
                } else {
                   this.errorMessage = 'Could not fetch SSO token';
                }
               this.$store.state.loader = false;
           })
           .catch((error) => {
               this.errorMessage = error.response.data.message;
           });
       },
       downloadActiveMedsAndAllergyByPatient : function() {
            this.$store.state.loader = true;
           axios
             .post("/downloadActiveMedsAndAllergyByPatient", { patient_id : this.$store.state.patientDetail.patient_id })
             .then((response) => {
                   this.$store.state.loader = false;
                   this.fetchLocalActiveMed();
             })
             .catch((error) => {
               this.errorMessage = error.response.data.message;
             });
       },
       fetchLocalActiveMed : function() {
          axios
          .post("/rxntpatientallergy", { patient_id : this.$store.state.patientDetail.patient_id })
          .then((response) => {
                this.allergy = response.data.data;
          })
          .catch((error) => {
            this.errorMessage = error.response.data.message;
          });
       }
      },
    };
</script>
<style type="text/css" scoped>
</style>