<template>
	<div class="content-wrapper">
		<div class="container-full">
			<div class="content-header">
				<h1 class="heading1 my-5">PureCRM Contacts</h1>

			</div>
			<section class="content">
				<div class="box">
					<div class="box-body">
						<div class="table-responsive">
							<data-table-server dataType="crmContacts" dataMethod="get" :key="key">
								<template v-slot="slotProps">
									<td :class="{ 'notify': (slotProps.item.message_counter > 0) }">
										<router-link
											:to="{ name: 'CRMProspectPatientDetails', params: { 'id': slotProps.item.unique_id } }"
											class="position-relative fw-500"> {{ slotProps.item.patient_name }}
										</router-link>
									</td>
									<td>{{ slotProps.item.cell_phone }}</td>
									<td>{{ slotProps.item.email }}</td>
									<td>{{ slotProps.item.crm_pipeline_name }} ({{ get_pipeline_for(slotProps.item.pipeline_for) }})</td>
									<td>{{ slotProps.item.current_crm_status }}</td>
									<td>{{ $filters.displayDateTimeLocal(slotProps.item.created_at, '', false, "ddd, MM-DD-YYYY",true) }}</td>
									<td v-html="getTags(slotProps.item.tags)"></td>
								</template>
							</data-table-server>
						</div>
					</div>
				</div>
			</section>
		</div>
	</div>
</template>

<script>
import axios from "@/scripts/axios.js";
import dataTable from "@/components/base/DataTable.vue";
import DataTableServer from "@/components/base/DataTableServer.vue";
import submitMixins from "@/scripts/mixins/submitMixins.js";
import permissionMixins from "@/scripts/mixins/permission.js";
import Popper from "vue3-popper";

export default {
	name: "Home",
	mixins: [submitMixins, permissionMixins],
	components: { dataTable, Popper, DataTableServer },
	created() {
		setInterval(function () {
			$(".notify").each(function () {
				$(this).parent().addClass("notifytr")
			});
		}, 3000)
	},
	methods: {
		getTags(Tags) {
			let tagDiv = "";
			if (Tags) {
				let tags = Tags.split(",");
				for (let tag of tags) {
					tagDiv += "&nbsp;<span class='badge badge-info'>" + tag + "</span>";
				}
			}
			return tagDiv;
		},
		get_pipeline_for(title) {
			var retVal = title;
			if (title == "gro") {
				retVal = "GrowLocal";
			} else if (title == "nuc") {
				retVal = "ClientSwipe";
			}
			return retVal;
		}
	}
};
</script>