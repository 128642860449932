<template>
	<div class="content-wrapper">
		<div class="vid-container p-40">
			<img src="images/pureehr.svg" alt="PureEHR" title="PureEHR" v-if="siteLogo"/>
			<div class="vid-container-title">
				<h1>Join Tele-Health</h1>
			</div>
			
			<div class="vid-container-form">
				<div class="vid-container-form-text">
					<input type="text" v-model="username" placeholder="Enter Display Name" class="text" />	
				</div>
				<div class="vid-container-form-text">
					<input type="text" v-model="roomid" placeholder="Enter Meeting ID" class="text" :disabled="true" :style="roomIdStyle" />	
				</div>
				<div class="vid-container-form-button">
					<button class="btns" @click="create_token" :disabled="loading" style="display: block;width: 100%;padding: 12px;font-size: 18px;margin: 0;">Join Call</button>
				</div>
			</div>
		</div>
	</div>
	<div class="master-video-container" v-if="isVideo">
		<div class="video-container" id="video-container">
			<div id="noParticipents" class="noParticipents"></div>
			<div id="noParticipents1" class="permissionmsg" v-if="this.isAdmin">Kindly check your site permissions.kindly Allow permission from the popup so as to enable your microphone and camera.</div>
			<div id="noParticipents2" class="permissionmsgpatient" v-else>Kindly check your site permissions.kindly Allow permission from the popup so as to enable your microphone and camera.</div>
			<div class="video-container-buttons" id="video-container-buttons"></div>
		</div>
		
		<div class="clinicalNotes-container" v-if="!(siteLogo)">
			<basic-info :patientId='patientId' :patientUId='patientUId' :appointmentId='appointmentId' />
			<!-- <clinical-notes-list :patientId=patientId /> -->
		</div>
	</div>
</template>

<style>
.swal2-container {
    position: absolute;
    z-index: 1113;
}


.master-video-container {
	overflow: hidden; position: absolute; top: 0; height: 100%; width: 100%; background: #eaeaeb; z-index: 1111;
}
.clinicalNotes-container {
	overflow: auto; position: absolute; top: 0; height: 100%; width: 60%; background: #eaeaeb; z-index: 111; right: 0;
}
.content-wrapper{min-height: unset!important;}
.vid-container {
	width: 100%; max-width: 600px; margin: 0 auto; background-color: #eee;
    padding: 50px; border: 1px solid #ccc; border-radius: 6px; text-align: center;
}
.vid-container-title, .vid-container-form {
	padding: 5px; width: 100%;
}
.vid-container-title h1 {
	margin-top: 0;
}
 
.vid-container-form {
	width: 100%; 
}
.vid-container-form-text {
	width: 50%; display: inline-block; padding: 5px;
}
.vid-container-form-text .text {
	outline: none; padding: 10px; font-size: 18px; width: 100%; display: block; text-align: left; letter-spacing: unset; border: 1px solid #ccc;
}
.vid-container-form-button {
	padding: 5px;
}
.wrapper {
	min-height: 100vh !important;
}
.noParticipents {
	color: #fff;font-size: 20px;position: absolute;text-align: center;top: 50%;margin: 0 auto;left: 0;right: 0;padding:12px 0;
}
#nav{
	padding: 10px;background: #242636; border-bottom: 1px solid #3d4058;
}
#nav a{
	color: #fff;font-weight: 600;
}
.video-container, .video-container .fullscreen {
	overflow: hidden; position: absolute; top: 0; height: 100%; width: 100%; background: #0c0d14; z-index: 111;
}
.video-container .thumbscreen {
	width: 150px; height: 150px; position: relative; margin-top: 10px; left: calc(100% - 170px); z-index: 11111; border: 2px solid #939393; border-radius: 10px; overflow: hidden; cursor: pointer;
}
.video-container .fullscreen video, .video-container .thumbscreen video {
	width: 100% !important; height: 100% !important; object-fit: cover;
	/* transform: rotateY(180deg); */
}
.video-container .fullscreen video:nth-child(4), video:nth-child(5), video:nth-child(6), video:nth-child(7), video:nth-child(8), video:nth-child(9), video:nth-child(10) {
	z-index: 1111; object-fit: contain; width: 100%; height: 100%; position: absolute; top: 0; left: 0; background: #0c0d14;
}
.participantName {
	background: rgba(0, 0, 0, 0.5); color: #fff; position: absolute; left: 0; bottom: 0px; padding: 5px 10px; z-index: 111111; font-size: 14px;
	/* top: calc(100vh - 40px); */
}
.video-container .fullscreen .participantName {
	padding: 12px; position: fixed;
}
.btns {
	border: 1px solid #035587; background: #0265a0; color: #fff; border-radius: 3px; padding: 5px 10px; font-size: 14px; font-weight: bold; cursor: pointer; margin: 0 5px; vertical-align: middle;
}

.vdobtnimp{background: rgb(9 10 14 / 25%); border: 0;vertical-align: sub;}
.vdobtnimp:hover{background: rgb(9 10 14 / 45%) !important;}
.btns img {width: 18px;}
.btns:hover {
	background-color: #035587;
}
.red {
	border: 1px solid #db2828;
	background: #f14949;
	color: #fff;
}
.red:hover {
	background-color: #db2828;
}
.video-container-buttons {
	bottom: 0px;
	position: fixed;
	z-index: 111111;
	width: 100%;
	text-align: center;
	/* background: rgb(9 10 14 / 85%);
	border-top: 1px solid #3d4058; */
	padding: 5px 0;
	margin-bottom: 10px;
}
.permissionmsg {
    width: 30%;
    left: 5%;
    right: auto;
    position: relative;
    text-align: center;
    top: 50%;
    color: #fff;
}
.permissionmsgpatient{
	right: auto;
	position: relative;
	text-align: center;
	top: 50%;
	color: #fff;
	padding: 0 15px;
}

@media (max-width:575px) {
	.vid-container-form-text{width:100%;}
}

</style>
<script>

	import axios from "@/scripts/axios.js";
	import ClinicalNotesList from "@/components/patients/details/ClinicalNote/Home.vue";
	import BasicInfo from "@/components/patients/BasicInfo/Home.vue";
	import VueSweetalert2 from 'vue-sweetalert2';
	import moment from 'moment-timezone';
	
	import 'sweetalert2/dist/sweetalert2.min.css';
	/** */
	var $isLogin;
	var $this;	
	
	const createVideoScreen = function(room, token) {
		const Video = require('twilio-video');

		// await $this.$swal('Please enter username');

		var tokenData = token;
		var roomVal = room;
		
		if (Video.isSupported) {
			var $room;

			Video.connect(tokenData, 
			{ 
				name: roomVal, 
				audio: true, 
				video: { width: 1280, height: 720, frameRate: 24 } 
			}).then(room => {
				$room = room;
				let no_participent_msg = "";
				if ($this.isontime) {
						if($isLogin) { 
							no_participent_msg = "A Patient will join you momentarily.";
							document.getElementById("noParticipents1").innerHTML="";
							
						} else {
							no_participent_msg = "A Medical Practitioner will join you momentarily."
							document.getElementById("noParticipents2").innerHTML="";
							
						}
						document.getElementById("noParticipents").innerHTML=no_participent_msg;
						
						
						
						
						if(room.localParticipant) {
							participantConnected(room.localParticipant, 'thumbscreen');
						}
						room.participants.forEach(participantConnected);

						room.on('participantConnected', participantConnected);
						room.on('participantDisconnected', participantDisconnected);
						room.once('participantConnected', participant => {
						
							
							console.log(`Participant "${participant.identity}" has connected to the Room`);
						});

						// Log Participants as they disconnect from the Room
						room.once('participantDisconnected', participant => {
							
						console.log(`Participant "${participant.identity}" has disconnected from the Room`);
						});

						const btn_container = document.getElementById("video-container-buttons");
						var images=require.context('../../../public/images/', false, /\.png$/)

						// ## MIC ON/OFF BUTTON ##
						const micBtn = document.createElement('button');
						micBtn.className = "btns vdobtnimp";
						micBtn.id = "micTrackBtn";
						micBtn.innerHTML = "<img src='"+images('./mic-start.png')+"' alt='Mute' title='Mute' />";
						micBtn.onclick = () => {
							$room.localParticipant.audioTracks.forEach(track => {
								let altText = "";
								if(track.isTrackEnabled) {
									track.track.disable();
									altText = "Unmute";
								} else {
									track.track.enable();
									altText = "Mute";
								}
								updateBtn(track.isTrackEnabled, "micTrackBtn", "Mic", altText);
							});
						}
						btn_container.appendChild(micBtn);
						
						// ## CAMERA ON/OFF BUTTON ##
						const videoBtn = document.createElement('button');
						videoBtn.className = "btns vdobtnimp";
						videoBtn.id = "videoTrackBtn";
						videoBtn.innerHTML = "<img src='"+images('./video-start.png')+"' alt='Stop video' title='Stop video' />";
						videoBtn.onclick = () => {
							$room.localParticipant.videoTracks.forEach(track => {
								let altText = "";
								console.log(track.isTrackEnabled);
								if(track.isTrackEnabled) {
									// track.track.stopped();
									track.track.disable();
									// track.track.stop();
									altText = "Start video";
								} else {
									// track.track.attach();
									track.track.enable();
									// track.track.started();
									altText = "Stop video";
								}
								updateBtn(track.isTrackEnabled, "videoTrackBtn", "Video", altText);
							});
						}
						btn_container.appendChild(videoBtn);

						// ## UPDATE BUTTON STATUS ##
						const updateBtn = function(flag, id, type, altText) {
							console.log(flag+'-----'+id+'----'+type+'----'+altText);
							if(flag) {
								document.getElementById(id).innerHTML = "<img src='"+images('./'+type.toLowerCase()+'-start.png')+"' width='12px' alt='"+altText+"' title='"+altText+"' />";
							} else {
								document.getElementById(id).innerHTML = "<img src='"+images('./'+type.toLowerCase()+'-stop.png')+"' width='12px' alt='"+altText+"' title='"+altText+"' />";
							}
						}
						
						// ## SCREEN SHARE BUTTON ##
						var screenTrack;
						const sharBtn = document.createElement('button');
						sharBtn.className = "btns vdobtnimp";
						sharBtn.id = "sharScreenBtn";
						sharBtn.innerHTML = "<img src='"+images('./share-start.png')+"' width='12px' alt='Share Screen' title='Share Screen' />";
						sharBtn.onclick = () => {
							let altText = "";
							if (!screenTrack) {
								var isScreenShareSupport = navigator.mediaDevices.getDisplayMedia ? true : false;

								// ## CONDITION FOR CHECK IF SHARE SCREEN SUPPORT OR NOT ##
								if(!isScreenShareSupport) {
									alert("Screen sharing is possible only from a laptop or computer.");
									return false;
								}
								altText = "Stop Screen";
								navigator.mediaDevices.getDisplayMedia({
									audio: false,
									video: {
										frameRate: 24,
										height: 720,
										width: 1280,
									},
								}).then(stream => {
									screenTrack = new Video.LocalVideoTrack(stream.getTracks()[0]);
									screenTrack.mediaStreamTrack.onended = () => { shareScreenStop() }

									$room.localParticipant.publishTrack(screenTrack, {
										priority: 'low'
									});
								}).catch((e) => {
									console.error("E: ", e);
									document.getElementById("sharScreenBtn").innerHTML = "<img src='"+images('./'+'share-start.png')+"' width='12px' alt='Start Screen' title='Start Screen' />";
									// updateBtn(true, "sharScreenBtn", "share", altText);
								});
								document.getElementById("sharScreenBtn").innerHTML = "<img src='"+images('./'+'share-stop.png')+"' width='12px' alt='Stop Screen' title='Stop Screen' />";
								// updateBtn(false, "sharScreenBtn", "share", altText);
							} else {
								
								shareScreenStop();
							}
							
						}
						btn_container.appendChild(sharBtn);

						const shareScreenStop = function() {
							let	altText = "Start Screen";
							$room.localParticipant.unpublishTrack(screenTrack);
							screenTrack.stop();
							screenTrack = null;
							document.getElementById("sharScreenBtn").innerHTML = "<img src='"+images('./'+'share-start.png')+"' width='12px' alt='Start Screen' title='Start Screen' />";
							// updateBtn(true, "sharScreenBtn", "share", altText);
						}

						// ## LEAVE ROOM BUTTON ##
						const leaveBtn = document.createElement('button');
						leaveBtn.className = "btns red";
						leaveBtn.id = "LeaveBtn";
						leaveBtn.innerHTML = "Leave <img src='"+images('./logout.png')+"' width='12px' alt='Leave Meeting' title='Leave Meeting' style='display:none;' />";
						leaveBtn.onclick = async function() {
						
							$this.$store.state.loader = false;
							if($isLogin) {
								$this.$swal({
									title: 'Are you sure?',
									type: 'warning',
									showCancelButton: true,
									confirmButtonColor: '#3085d6',
									cancelButtonColor: '#d13737',
									confirmButtonText: 'End Meeting',
									cancelButtonText: 'Leave Meeting'
									}).then((result) => {
										if(result.value){
											$room.disconnect();
											$room.localParticipant.tracks.forEach(publication => {
												const attachedElements = publication.track.detach();
												attachedElements.forEach(element => element.remove());
											});
											document.getElementById('video-container-buttons').style.display='none';
											if(document.getElementsByClassName('fullscreen')[0]) {
												document.getElementsByClassName('fullscreen')[0].style.display='none';
											}
											if(document.getElementsByClassName('thumbscreen')[0]) {
												document.getElementsByClassName('thumbscreen')[0].style.display='none';
											}
											document.getElementById('noParticipents').innerHTML = "Thank you.";
											document.getElementById("noParticipents1").innerHTML="";
											$this.endmeeting=true;
											
										
									}else if(result.dismiss == 'cancel'){
										$room.localParticipant.tracks.forEach(publication => {
											const attachedElements = publication.track.detach();
											attachedElements.forEach(element => element.remove());
										});
										document.getElementById('video-container-buttons').style.display='none';
										if(document.getElementsByClassName('fullscreen')[0]) {
											document.getElementsByClassName('fullscreen')[0].style.display='none';
										}
										if(document.getElementsByClassName('thumbscreen')[0]) {
											document.getElementsByClassName('thumbscreen')[0].style.display='none';
										}
										document.getElementById('noParticipents').innerHTML = "Thank you.";
										document.getElementById('noParticipents1').innerHTML = "";
										window.location.reload();
									}
								});
								
							} else {
								$room.disconnect();
								document.getElementById('video-container-buttons').style.display='none';
								if(document.getElementsByClassName('fullscreen')[0]) {
									document.getElementsByClassName('fullscreen')[0].style.display='none';
								}
								if(document.getElementsByClassName('thumbscreen')[0]) {
									document.getElementsByClassName('thumbscreen')[0].style.display='none';
								}
								document.getElementById('noParticipents').innerHTML = "Thank you.";
								document.getElementById('noParticipents2').innerHTML = "";
								
								window.location.reload();
							}
								
						};
						btn_container.appendChild(leaveBtn);

						if($isLogin) {
							var id = document.getElementsByClassName("video-container")[0].id;
							document.getElementById(id).style.width = "40%";
							document.getElementById("video-container-buttons").style.width="40%";
							/** Update logout time to 3 hours */
							let currTime = new Date();
							localStorage.setItem("loginExpireTime", new Date(currTime.setMinutes(currTime.getMinutes() + 180)));
							/** ***** **/
							// document.querySelector('.video-container .fullscreen .participantName').style.position="absolute";
							// document.querySelector('.video-container .fullscreen video').style.objectFit="contain";
							// document.getElementsByClassName("fullscreen").style.height='50vh';
						}
				}else{
					return false;
				}		
					});

					/** ON CLICK SWITCH CLASS :: START */
					const switchClass = function() {
						let attribute = this.getAttribute("id");
						/** Remove full screen and add thumb */
						if(document.getElementsByClassName('fullscreen')[0]) {
							let thisId = document.getElementsByClassName('fullscreen')[0].id;
							if(attribute!=thisId) {
								document.getElementById(thisId).className = "thumbscreen";
								document.getElementById(thisId).classList.remove("fullscreen");
								/** Remove thumb and add fullscreen */
								document.getElementById(attribute).className = "fullscreen";
								document.getElementById(attribute).classList.remove("thumbscreen");
							}
						}
					};

					const addThumbListern = function() {
						let elements = document.getElementsByClassName("thumbscreen");
						for (let i = 0; i < elements.length; i++) {
							elements[i].addEventListener('click', switchClass, false);
						}
						elements = document.getElementsByClassName("fullscreen");
						for (let i = 0; i < elements.length; i++) {
							elements[i].addEventListener('click', switchClass, false);
						}
					}
					/** ON CLICK SWITCH CLASS :: END */

					const participantConnected = function(participant, className) {
						if(className!="thumbscreen") { 
							className = "fullscreen"; 

							if(document.getElementsByClassName('fullscreen')[0]) {
								let thisId = document.getElementsByClassName('fullscreen')[0].id;
								document.getElementById(thisId).className = "thumbscreen";
								document.getElementById(thisId).classList.remove("fullscreen");
							}
						}

						const container = document.getElementById("video-container");
						const div = document.createElement('div');
						div.className = className;
						div.id = participant.sid;

						const participantName = document.createElement('div');
						participantName.className = "participantName";
						participantName.innerText = participant.identity;

						participant.on('trackSubscribed', track => trackSubscribed(div, track));
						participant.on('trackUnsubscribed', trackUnsubscribed);
						participant.tracks.forEach(publication => {
							// if (publication.isSubscribed) {
								trackSubscribed(div, publication.track);
							// }
						});

						div.appendChild(participantName);
						container.appendChild(div);

						/** Add event listener on every thumbnail */
						addThumbListern();
					}

					const participantDisconnected = function(participant) {
						document.getElementById(participant.sid).remove();
					}

					const trackSubscribed = async function(div, track) {
						if(track) {
							div.appendChild(track.attach());
							/** BLUR SCREEN CODE START */
							// var bg = new GaussianBlurBackgroundProcessor({
							// 	assetsPath: '/',
							// 	maskBlurRadius: 10,
							// 	blurFilterRadius: 5,
							// });
							// await bg.loadModel();
							// track.addProcessor(bg);
						}
					}
					
					const trackUnsubscribed = function(track) {
						track.detach().forEach(element => element.remove());
					}

				} else {
					console.error('This browser is not supported by twilio-video.js.');
					alert("This browser/device dosn't support video call functionality.");
				}
	}
	/**/
	export default {
		name: 'Videocall',
		components: { ClinicalNotesList, BasicInfo,VueSweetalert2 },
		props: {
		},
		created() {
			
			$this = this;
			if(this.$route.params.room) {
				this.isVideo=false;
				this.user=true;
				this.roomid = "";
				this.siteLogo = localStorage.getItem("authToken") ? false : true;
				$isLogin = false;
				if(!this.siteLogo) {
					$isLogin = true;
					this.getUser();
				}
				this.checkroom(this.$route.params.room);
			}
			
		},
		beforeCreate() {
			
		},
		data() {
			return {
				isVideo: false,
				user:true,
				roomid: '',
				roomsid:'',
				username: '',
				loading:false,
				patientId: 0,
				appointmentId: 0,
				patientUId: 0,
				isAdmin:false,
				schedule_time:'',
				isontime:false,
				endmeeting:false,
			};
		},
		watch:{
            endmeeting: async function(){  
                if (this.endmeeting) {
                   await this.updateMeetingStatus();
                    await  axios.get('/telehealth-twilio/get-host', {
                        params: {
                            room_name:this.roomid,
                            hostLogin:0
                        }
                        })
                        .then(function (response) {
							window.location.reload();
							
                        }).catch((error)=>{
                            console.log(error);
                    })
					// await this.patient_screen();
                }
               },  
        },
		mounted(){
			var $this=this;
			setInterval(function () {
				axios.get('/telehealth-twilio/check-host', {
					params: {
						room_name:$this.$route.params.room,
					}
				})
				.then(function (response) {
					
					if (response.data.data.loginhostjoin==0) {
						if(document.getElementsByClassName('fullscreen')[0]) {
							document.getElementsByClassName('fullscreen')[0].style.display='none';
						}
						if(document.getElementsByClassName('thumbscreen')[0]) {
							document.getElementsByClassName('thumbscreen')[0].style.display='none';
						}
						
						if (document.getElementById('noParticipents')!=null) {
							
							document.getElementById('noParticipents').innerHTML = "Thank you.";
							if($isLogin) { 
								document.getElementById("noParticipents1").innerHTML="";
								
							} else {
								document.getElementById("noParticipents2").innerHTML="";
								
							}
							window.location.reload();
						}
						
					}
					
					})
				}, 5000);
			
		},
		methods: {
			async create_token() {
				let success = false;
				this.$store.state.loader = true;
			
				if(this.roomid=="" || this.roomid=="Room not available") {
					// alert("Room not available");
					this.$swal('Room not available!!!');
					this.$store.state.loader = false;
					return false;
				}
				let isLogin=0;
				if ($isLogin) {
					isLogin=1;
					this.isAdmin=true;
				}else{
					isLogin=0;
					this.isAdmin=false;
				}
				if ($isLogin) {
					await axios.get('/telehealth-twilio/get-host', {
					params: {
						room_name:this.roomid,
						hostLogin:isLogin
					}
				})
				.then(function (response) {
					console.log(response);
				})
				}
				
				if(this.username=="") {
					this.$swal('Please enter username');
					this.$store.state.loader = false;
					return false;
				}
				let token = '';
				await axios.get('/telehealth-twilio/create-token', {
					params: {
						room: this.roomid,
						identity: this.username,
					}
				})
				.then(function (response) {
					token = response.data.data.token;
					success = true;
				})
				.catch(function (error) {
					console.log(error);
					this.$swal('Invalid room.');
					return false;
				});
				if(this.$route.params.time&&this.$route.params.endtime){
					this.schedule_date=this.$route.params.date;
					this.schedule_time=this.$route.params.time;
					var schedule_end_time=this.$route.params.endtime;
					let currTime = Date.now();	
					var data=this.schedule_date+" "+this.schedule_time;
					var date = new Date(data);
					var end_data=this.schedule_date+" "+schedule_end_time;
					var end_time = new Date(end_data);
					if (!$isLogin) {
						if(!this.isontime){
							if(date.getTime()<currTime){
								if (end_time.getTime()>currTime) {
									this.isontime=false;
									this.$store.state.loader = false;
									this.$swal('Host will start this meeting soon.');
									return false;
								}else{
									console.log(end_time);
									this.isontime=false;
									this.$store.state.loader = false;
									this.$swal('The meeting has already been ended.');
									return false;	
								}
									
							}
							else if(date.getTime()>currTime){
								this.isontime=false;
								this.$store.state.loader = false;
								this.$swal('This meeting will start on '+moment(this.schedule_date).format("MM-DD-YYYY")+'at ' +this.schedule_time);
								return false;
							}else if(date.getTime()==currTime){
								this.isontime=false;
								this.$store.state.loader = false;
								this.$swal("A Medical Practitioner will join you momentarily.");
								return false;
							}
						}
							
					}else{
						this.isontime=true;
					}
			}
			if(success) {
				this.isVideo=true;
				this.user=false;
				this.$store.state.loader = false;
				createVideoScreen(this.roomid, token);
			}
				
			},
			async checkroom(roomid,isLogin) {
				let roomId = '';
				let roomSid = '';
				let patientId = 0;
				let appointmentId = 0;
				let patientUId = 0;
				let roomIdStyle = "";
				this.$store.state.loader = true;
				let isontime=true;
				
				
				await axios.get('/telehealth-twilio/get-room', {
					params: {
						room_name:roomid,
						// hostdLogin:isLogin
					}
				})
				.then(function (response) {
					if(response.data.status=="error") {
						roomId = "Room not available";
					} else {
						roomId = response.data.data.roomName;
						roomSid = response.data.data.roomSid;
						patientId = response.data.data.patient_id;
						appointmentId = response.data.data.appointment_id;
						patientUId = response.data.data.patient_unique_id;	
						if (response.data.data.hostjoin==1) {
							isontime=true;
						}else{
							isontime=false;
						}
					}
				})
				.catch(function (error) {
					console.log(error);
					roomId = "Room not available";
					roomIdStyle = "border-color: #eb4e4e; color: #eb4e4e;";
				});
				this.roomid = roomId;
				this.roomsid = roomSid;
				this.patientId = patientId;
				this.appointmentId = appointmentId;
				this.roomIdStyle = roomIdStyle;
				this.patientUId = patientUId;
				this.$store.state.loader = false;
				this.isontime=isontime;
			},
			async getUser(){
				await axios.get("user-info").then(response => {
					this.username = "";
					if(response.data.data.user_type=="provider") {
						this.username = "Dr. ";
					}
					this.username += response.data.data.first_name + " " + response.data.data.last_name;
				}).catch(() => {
					this.username="";
				});
			},
			async updateMeetingStatus() {
				// this.$store.state.loader = true;
				let roomid = this.roomid;
				let roomsid = this.roomsid;
				let patientid = this.patientId;
				let appointmentId = this.appointmentId;
				console.log("Calling Status API");
				await axios.post('telehealth-twilio/updatemeetingstatus', {
					room_name: roomid,
					patient: patientid,
					appointment_id: appointmentId,
					room_sid: roomsid
				})
				.then(function (response) {
					console.log(response);
					return true;
				})
				.catch(function (error) {
					console.log(error);
					return true;
				});
			},
		}
  	}
</script>
