<template>
  <div :class="mainclass">
    <div class="authform-modal box">
      <div class="pageheading pb-10">
        <div class="row align-items-center">
          <div class="col-12 col-sm-8 col-md-8 col-lg-8">
            <h2 class="heading2">Demographics
              <a href="#" data-bs-toggle="modal" data-bs-target="#demographics" title="Reference of Knowledge Base" v-on:click.prevent="reset()"> <i class="fa fa-question-circle fs-20"></i> </a>
            </h2>
          </div>
          <div class="col-12 col-sm-4 col-md-4 col-lg-4 text-sm-end">
            <a href="#" class="waves-effect waves-light btn btn-info-light me-1 l-h-n" title="Download XLS"><i class="fa fa-download" @click="exportDemographics('excel')" aria-hidden="true"></i></a>
            <a href="#" class="waves-effect waves-light btn btn-info-light l-h-n" title="Print"><i class="fa fa-print" @click="exportDemographics('pdf')" aria-hidden="true"></i></a>
          </div>
        </div>
      <!--document model start-->
        <div id="demographics" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-md">
            <div class="modal-content">
              <div class="modal-header">
                <h3 class="modal-title fw-900 mt-0">Reference of Knowledge Base</h3>
              </div>
              <div class="modal-body overflow-auto" style="max-height: 350px;">
              <table class="table table-bordered">
                <thead>
                  <tr>
                  <th scope="col">No.</th>
                  <th scope="col">Knowledge Base Articles</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td> <a :href="path+'how-to-edit-patient-s-demographic-information-in-pureehr'" target="_blank">How To Edit Patient Demographic Information?</a></td>
                  </tr>
                </tbody>
              </table>
              <Search :key="searchKey"></Search>
              </div>
              <div class="modal-footer">
                <button type="button" @click="" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
              </div>
            </div>
          </div>
        </div>
      <!--document model end-->    
    </div>
    <form novalidate>
        <input-field
        title="Patient SSN"
        v-model="v$.form.ssn_number.$model"
        :errors="v$.form.ssn_number.$errors"
        >
    </input-field>
    <div class="form-group row">
      <label class="col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end">
        Patient DOB<!-- <small class="text-danger asterisksign">*</small> -->
    </label>
    <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3 availdate" >
        <ejs-datepicker :value="date_of_birth" id="date_of_birth" v-model="v$.form.date_of_birth.$model" :format="dateFormat" placeholder="select date of birth" @change="getdate($event)" name=
        "date_of_birth" :strictMode='strict' :max='maxVal' :showClearButton='false' :openOnFocus='true'>
    </ejs-datepicker>

    <div class="error-msg">
      <ul class="mb-0 p-0 fs-14">
        <li class="d-block" v-for="(error, index) of v$.form.date_of_birth.$errors" :key="index">
          {{ error.$message }}
      </li>
  </ul>
</div>

</div>
</div>

<input-field title="Approx Age" v-model="form.age"> </input-field>

<select-box-static
title="Gender"
id="sex"
v-model="v$.form.sex.$model"
:errors="v$.form.sex.$errors"
:option="sex"
:isRequired="true">
></select-box-static>

<select-box-static
title="Gender Identity"
v-model="form.gender_identity"
:option="gender_identity">
></select-box-static>

<select-box-static
title="Sexual Orientation"
v-model="form.sexual_orientation"
:option="sexual_orientation">
></select-box-static>


<div class="form-group row">
  <label class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end">Race</label>
  <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3">
    <Select2
    v-model="form.race"
    id="race_id"
    placeholder="Select Race"
    :options="raceOption"
    @select="raceChange($event)"
    />
</div>
</div>

<div class="form-group row">
  <label class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end">Granularity</label>
  <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3">
    <Select2
    v-model="form.granularity"
    id="granularity_id"
    placeholder="Select Granularity"
    :options="granularityoption"
    />
</div>
</div>

<div class="form-group row">
  <label class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end"> Additional Race</label>
  <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3">
    <Select2
    v-model="form.additional_race"
    id="additional_race_id"
    placeholder="Select Additional Race"
    :options="raceOption"
    @select="additionalraceChange($event)"
    />
</div>
</div>

<div class="form-group row">
  <label class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end">Additional Granularity</label>
  <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3">
    <Select2
    v-model="form.additional_granularity"
    id="additional_granularity_id"
    placeholder="Select Granularity"
    :options="additional_granularityoption"
    />
</div>
</div>

<select-box-static
title="Ethnicity"
v-model="form.ethnicity"
:option="ethnicity"
></select-box-static>

<div class="form-group row">
  <label class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end">Ethnicity Granularity</label>
  <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3">
    <Select2
    v-model="form.ethnicity_granularity_id"
    id="ethnicity_granularity_id"
    placeholder="Select Ethnicity Granularity"
    :options="ethnicity_granularity_option"
    />
</div>
</div>

<select-box-static
title="Additional Ethnicity"
v-model="form.additional_ethnicity"
:option="ethnicity"
></select-box-static>

<div class="form-group row">
  <label class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end">Additional Ethnicity Granularity</label>
  <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3">
    <Select2
    v-model="form.additional_ethnicity_granularity_id"
    id="additional_ethnicity_granularity_id"
    placeholder="Select Ethnicity Granularity"
    :options="ethnicity_granularity_option"
    />
</div>
</div>

<div class="form-group row">
    <label class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end">Preferred Language</label>
    <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3">
        <Select2
        v-model="form.preferred_language"
        id="preferred_language"
        placeholder="Preferred Language"
        :options="languages"
        />               
    </div>
</div>
<text-area title="Patient Home Address" v-model="v$.form.address.$model" :errors="v$.form.address.$errors" :isRequired="true" id="address"> </text-area>

<input-field title="Zipcode" @focusout="zipcode" v-model="v$.form.zip_code.$model" :errors="v$.form.zip_code.$errors" :isRequired="true" id="zip_code"> </input-field>

<input-field title="City" v-model="form.city"> </input-field>

<div class="form-group row" :class="{ error: v$.form.state_id.$errors.length }">
  <label class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end">State <small class="text-danger asterisksign">*</small></label>
  <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3">
    <Select2
    v-model="v$.form.state_id.$model"
    id="state_id"
    :errors="v$.form.state_id.$errors"
    placeholder="Select State"
    :options="states"
    />
    <div class="error-msg">
      <ul class="mb-0 p-0 fs-14">
        <li class="d-block" v-for="(error, index) of v$.form.state_id.$errors" :key="index">
          {{ error.$message }}
      </li>
  </ul>
</div>
</div>
</div>

<div
class="form-group row"
:class="{ error: v$.form.country_id.$errors.length }"
>
<label class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end">Country <small class="text-danger asterisksign">*</small></label>
<div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3">
    <Select2
    v-model="v$.form.country_id.$model"
    ref="selectCountry"
    :errors="v$.form.country_id.$errors"
    id="country_id"
    placeholder="Select Country"
    :options="countries"
    @select="countryChange($event)"
    />
    <div class="error-msg">
      <ul class="mb-0 p-0 fs-14">
        <li
        class="d-block"
        v-for="(error, index) of v$.form.country_id.$errors"
        :key="index"
        >
        {{ error.$message }}
    </li>
</ul>
</div>
</div>
</div>


<select-box-static
title="Patient Marital Status"
v-model="form.marital_status"
:option="marital_status"
></select-box-static>

<select-box-static
title="Patient Employment Status"
v-model="form.employment_status"
:option="employment_status"
></select-box-static>

<select-box-static
title="EPSDT Services"
v-model="form.epsdt_services_id"
:option="epsdt_services_id"
></select-box-static>

<select-box-static
title="EPSDT Referral Condition"
v-model="form.epsdt_referral_condition_id"
:option="epsdt_referral_condition_id"
></select-box-static>

<input-field
title="Emergency Contact Name"
v-model="form.emergency_contact_name"
id="emergency_contact_name"
:errors="v$.form.emergency_contact_name.$errors"
>
</input-field>

<input-field
title="Emergency Contact Phone"
mask="(000) 000-0000"
v-model="v$.form.emergency_contact_phone.$model"
:errors="v$.form.emergency_contact_phone.$errors"
>
</input-field>

<input-field
title="Emergency Contact Relation"
v-model="form.emergency_contact_relation"
>
</input-field>

<hr />

<div class="form-group row">
  <div
  class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end"
  >
  <label>Referring Provider ID</label>
</div>
<div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3">
    <Select2
    v-model="form.referring_doctor_contact_id"
    id="referring_doctor_contact_id"
    placeholder="Select Referring Provider ID"
    :options="referring_doctor"
    @select="reason"
    />
</div>
</div>

<div class="form-group row d-none" id="referral_reason">
  <label class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end">Referral reason</label>
  <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3">
    <div class="controls">
      <input v-model="form.referral_reason" type="text" class="form-control" placeholder="Referral reason" id="Referral_reason">
  </div>
</div>
</div>        
<div class="form-group row d-none" id="referral_date_div">
  <label class="col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end">
    Referral Date
</label>
<div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3 availdate">
    <ejs-datepicker :value="referral_date" id="referral_date" v-model="form.referral_date" :format="dateFormat" placeholder="Referral Date" @change="getreferral_date($event)" name="referral_date" :strictMode='strict' :max='maxVal' :showClearButton='false' :blur="getreferral_date" :openOnFocus='true'>
    </ejs-datepicker> 
</div>
</div>

<div class="form-group row">
  <div
  class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end"
  >
  <label>Ordering Provider ID</label>
</div>
<div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3">
    <Select2
    v-model="form.ordering_doctor_contact_id"
    id="ordering_doctor_contact_id"
    placeholder="Select Ordering Provider ID"
    :options="referring_doctor"
    />
    <div
    class="text-center"
    v-if="
    permissioncheck(
    this.$store.state.permission.Add_new_referring_sources
    )
    "
    >
    <div
    @click="newcontact()"
    class="waves-effect waves-light btn btn-info btn-sm mt-15"
    data-bs-toggle="modal"
    data-bs-target="#referringnewcontact"
    >
    <i class="fa fa-plus fs-12"></i> New Contact
</div>
</div>
</div>
</div>

<div class="pageheading" v-if="this.customdemographics.length>0">
  <h3 class="heading2">Custom Demographics</h3>
</div>
<div v-for="item in customdemographics">
    <input-field
    :title="item.name"
    v-if="item.field_type == 'Text Field'"
    v-model="parent_id[item.id]"
    @focusout="getvalue(item.id,$event.target.value)"
    :maxlength ="20"
    >
</input-field>
<text-area 
:title="item.name"
v-if="item.field_type == 'Text Area'"
v-model="parent_id[item.id]"
@focusout='getvalue(item.id,$event.target.value)'
:maxlength ="20"
> 
</text-area>
</div>


<div class="pageheading">
  <h3 class="heading2">Responsible Party</h3>
</div>

<input-field
title="First Name"
v-model="form.responsible_party_name"
>
</input-field>

<input-field
title="Middle Name"
v-model="form.responsible_party_middle_name"
>
</input-field>

<input-field
title="Last Name"
v-model="form.responsible_party_last_name"
>
</input-field>

<div class="form-group row">
  <label class="col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end">
    DOB
</label>
<div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3 availdate">
    <ejs-datepicker :value="responsible_party_dob" id="responsible_party_dob" v-model="form.responsible_party_dob" :format="dateFormat" placeholder="Date Of Birth" @change="getdobdate($event)" name="responsible_party_dob" :strictMode='strict' :max='maxVal' :showClearButton='false' :blur="getdobdate" :openOnFocus='true'>
    </ejs-datepicker>
</div>
</div>

<select-box-static
title="Relation"
v-model="form.responsible_party_relation"
:option="responsible_relation_list"
></select-box-static>

<input-field
title="Phone"
mask="(000) 000-0000"
v-model="v$.form.responsible_party_phone.$model"
:errors="v$.form.responsible_party_phone.$errors"
>
</input-field>

<input-field
title="Email"
v-model="v$.form.responsible_party_email.$model"
:errors="v$.form.responsible_party_email.$errors"
>
</input-field>

<text-area 
title="Address"
v-model="form.responsible_party_address"> 
</text-area>

<input-field 
title="Zipcode" 
v-model="form.responsible_party_zip_code"
@focusout="responsible_party_zip"
> 
</input-field>

<input-field 
title="City" 
v-model="form.responsible_party_city"> 
</input-field>

<div class="form-group row">
    <label class="col-form-label col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 text-sm-end">State</label>
    <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3">
        <Select2
        v-model="form.responsible_party_state_id"
        id="responsible_party_state_id"
        placeholder="Select State"
        :options="previous_states"
        />
    </div>
</div>

<div class="pageheading">
  <h3 class="heading2">Previous Address</h3>
</div>

<input-field 
title="Name" 
v-model="form.previous_name"> 
</input-field>

<input-field
title="Mobile Number"
mask="(000) 000-0000"
v-model="v$.form.previous_mobile.$model"
:errors="v$.form.previous_mobile.$errors"
maxlength="15"
>
</input-field>

<input-field
title="Phone Number"
mask="(000) 000-0000"
v-model="v$.form.previous_phone.$model"
:errors="v$.form.previous_phone.$errors"
maxlength="15"
>
</input-field>

<input-field
title="Email"
v-model="v$.form.previous_email.$model"
:errors="v$.form.previous_email.$errors"
>
</input-field>

<text-area 
title="Address"
v-model="form.previous_address"> 
</text-area>

<input-field 
title="Zipcode" 
v-model="form.previous_zip_code"
@focusout="previous_zip_code"
> 
</input-field>

<input-field 
title="City" 
v-model="form.previous_city"> 
</input-field>

<div class="form-group row">
    <label class="col-form-label col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 text-sm-end">State</label>
    <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3">
        <Select2
        v-model="form.previous_state_id"
        id="previous_state_id"
        placeholder="Select State"
        :options="previous_states"
        />
    </div>
</div>

<div class="form-group row">
  <label class="col-form-label col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 text-sm-end">Country</label>
  <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3">
    <Select2
    v-model="form.previous_country_id"
    ref="selectCountry"
    id="previous_country_id"
    placeholder="Select Country"
    :options="previous_countries"
    @select="previouscountryChange($event)"
    />
</div>
</div>

<!-- <text-area 
title="Admin Notes"
v-model="form.notes"
id="admin_notes"
divClass="col-12 col-sm-6 col-md-8 col-lg-8 col-xl-6"
rows="4"
> 
</text-area> -->

</form>
<div
class="row"
v-if="permissioncheck(this.$store.state.permission.create_update)"
>
<div class="col-12 col-sm-12 col-md-12 col-lg-11 col-xl-9 text-center">
  <form-btn
  title="Save"
  btnCls="btn-info"
  @click="save(false)"
  ></form-btn>
  <router-link
  :to="{ name: 'PatientList' }"
  class="waves-effect waves-light btn me-1 btn-danger"
  >Close</router-link
  >
</div>
</div>
</div>
</div>
<div
id="referringnewcontact"
class="modal fade"
tabindex="-1"
role="dialog"
aria-labelledby="myModalLabel"
aria-hidden="true"
>
<div class="modal-dialog">
  <div class="modal-content permissmodal">
    <div class="modal-header">
      <h3 class="modal-title font-bold m-0">New Contact</h3>
  </div>
  <referral-doctor
  v-if="this.$store.state.ReferralDoctor.renderComponent"
  ></referral-doctor>
</div>
</div>
</div>
<div class="modal" tabindex="-1" id="pdFilterDetail">
    <div class="modal-dialog modal-md">
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="modal-title font-bold m-0">Patient Demographics Fields</h3>
                <button type="button" class="btn-close btn-xs" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body" >
                <div id="pd_detail_table" class="table-responsive">
                    <table class="table table-bordered">
                      <tr>
                          <td>
                              <div class="controls">
                                <input type="checkbox" id="checkbox_for_all" v-model="allSelected"  class="filled-in chk-col-info"/>
                                <label for="checkbox_for_all" class="m-0">Select All</label>
                              </div>
                          </td>
                      </tr>
                      <template v-if="export_type == 'pdf'">
                            <tr v-for="patient_demo in patient_demo_export_key">
                                <td>
                                    <div class="controls">
                                      <input type="checkbox" :id="'checkbox'+patient_demo.name" v-model="patient_demo_selected" :value="patient_demo.name" class="filled-in chk-col-info"/>
                                      <label :for="'checkbox'+patient_demo.name" class="m-0">{{ patient_demo.lable }}</label>
                                    </div>
                                </td>
                            </tr>
                      </template>
                      <template v-else>
                          <tr v-for="patient_demo in patient_demo_xls_export_key">
                              <td>
                                  <div class="controls">
                                    <input type="checkbox" :id="'checkbox'+patient_demo.name" v-model="patient_demo_xls_selected" :value="patient_demo.name" class="filled-in chk-col-info"/>
                                    <label :for="'checkbox'+patient_demo.name" class="m-0">{{ patient_demo.lable }}</label>
                                  </div>
                              </td>
                          </tr>
                      </template>
                    </table>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-info" @click="printPatientDemoApply">Apply Filter</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" tabindex="-1" id="pdDetail">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="modal-title font-bold m-0">Patient Demographics</h3>
                <button type="button" class="btn-close btn-xs" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body" >
                <div id="pd_detail_table" class="table-responsive" >
                    <table class="table table-bordered">
                      <tr>
                          <td>
                              <div class="controls">
                                <input type="checkbox" id="checkbox_for_all" v-model="allSelected"  class="filled-in chk-col-info"/>
                                <label for="checkbox_for_all" class="m-0">Select All</label>
                              </div>
                          </td>
                      </tr>
                      <tr v-for="patient_demo in patient_demo_export_key">
                          <td>
                              <div class="controls">
                                <input type="checkbox" :id="'checkbox'+patient_demo.name" v-model="patient_demo_selected" :value="patient_demo.name" class="filled-in chk-col-info"/>
                                <label :for="'checkbox'+patient_demo.name" class="m-0">{{ patient_demo.lable }}</label>
                              </div>
                          </td>
                      </tr>
                    </table>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-info" @click="printPatientDemo">Print</button>
            </div>
        </div>
    </div>
</div>
<!--viewReceipt Modal Start-->
<div class="modal" id="viewReceipt" tabindex="-1">
  <div class="modal-dialog modal-md" style="max-width:75%">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="btn-close btn-xs" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <vue-pdf-viewer height="1050px" :url="receipt_url" v-if="embed=0">
        </vue-pdf-viewer>
        <iframe :src="receipt_url" width="100%" height="500px" v-if="embed=1" />
        <img srd="images/1.gif" v-else> 
      </div>
    </div>
  </div>
</div>
<!--viewReceipt Modal end-->
</template>

<script>
import InputField from "@/components/base/formFields/InputField.vue";
import SelectBox from "@/components/base/formFields/selectBox.vue";
import TextArea from "@/components/base/formFields/textArea.vue";
import FormBtn from "@/components/base/formFields/formBtn.vue";
import DatePicker from "@/components/base/formFields/datePicker.vue";
import SelectBoxStatic from "@/components/base/formFields/SelectBoxStatic.vue";
import StateSelectBox from "@/components/base/formFields/stateSelectBox.vue";
import Select2 from "@/components/base/formFields/select2.vue";
import multiSelectBoxStatic from "@/components/base/formFields/multiSelectBoxStatic.vue";
import ReferralDoctor from "@/components/ReferralDoctor/Home.vue";
import useVuelidate from "@vuelidate/core";
import submitMixins from "@/scripts/mixins/submitMixins.js";
import axios from "@/scripts/axios.js";
import permissionMixins from "@/scripts/mixins/permission.js";
import { DatePickerPlugin} from '@syncfusion/ej2-vue-calendars';
import moment from "moment";

import {
  helpers,
  required,
  email,
  minLength,
  maxLength,
  sameAs,
  numeric,
} from "@vuelidate/validators";
import Search from "@/components/Search.vue";

export default {
  mixins: [submitMixins, permissionMixins],
  setup: () => {
    return { v$: useVuelidate() };
},
components: {
    InputField,
    SelectBox,
    TextArea,
    FormBtn,
    DatePicker,
    StateSelectBox,
    Select2,
    ReferralDoctor,
    SelectBoxStatic,
    multiSelectBoxStatic,
    Search
},
computed: {
  allXlsSelected: {
    set (value) {
      var selected = [];
      if (value) {
          this.patient_demo_xls_export_key.forEach(function (user) {
              selected.push(user.name);
          });
      }
      this.patient_demo_xls_selected = selected;
    },
    get: function () {
        return this.patient_demo_xls_export_key ? this.patient_demo_xls_selected.length == this.patient_demo_xls_export_key.length : false;
    },
  },
  allSelected: {
    set (value) {
      var selected = [];
      if (value) {
          this.patient_demo_export_key.forEach(function (user) {
              selected.push(user.name);
          });
      }
      this.patient_demo_selected = selected;
    },
    get: function () {
        return this.patient_demo_export_key ? this.patient_demo_selected.length == this.patient_demo_export_key.length : false;
    },
  },
  form() {
      return this.$store.state.demographics.form;
  },
  vuelidateExternalResults() {
      return this.$store.state.demographics.vuelidateExternalResults;
  },
  validationRules() {
      return this.$store.state.demographics.validationRules;
  },
  referring_doctor() {
      return this.$store.state.demographics.referring_doctor;
  },
},
async beforeCreate() {
    $("#referral_reason").removeClass("d-none");
    $("#referral_date_div").removeClass("d-none");    
    // clear form data since, the same data is being used for add and edit both

    let loadCountry = "";
    this.$store.state.demographics.form.country_id = 231;
    this.$store.state.demographics.form.previous_country_id = 231;
    loadCountry = 231;

    /*state load for specific country*/
    var $this = this;
    axios.post("/json_autocomplete/" + loadCountry + "/state")
    .then((response) => {
        $this.states = response.data.data.map((state) => {
          return { id: state.id, text: state.name };
      });
        $this.previous_state = response.data.data.map((state) => {
          return { id: state.id, text: state.name };
      });
    });

    //race 
    axios.get("json_dropdown/race")
    .then((response) => {
        this.raceOption = response.data.data.map((race) => {
          return { id: race.id, text: race.description };
      });
    });

    axios.get("/json_dropdown/language").then(
      (response) =>
      (this.languages = response.data.data.map((language) => {
          return { id: language.code, text: language.name };
      }))
      );

    if (this.$route.name === "PatientAddDemographics") {
      this.$store.state.demographics.form = {};
      Object.assign(this.$store.state.patient.form);

      this.$store.state.demographics.form.country_id = 231;
      this.$store.state.demographics.form.previous_country_id = 231;
      
  } else if (this.$route.name === "PatientEditDemographics") {
      if (this.$store.state.patient.form.id != this.$route.params.id) {
        // If edit, get data from server
        await this.$store.dispatch("getEditData", {
          dataType: "demographics", // pass the module name
          formType: "form", // pass the propery name
          id: this.$route.params.id,
      });
        // need to set provider id since, getEdit data give id where update excepts provider_id
    }
}
if (this.$route.name === "PatientDemographics") {
  if (this.$store.state.patientDetail.patient_demographics_id) {
        // If edit, get data from server
        await this.$store.dispatch("getEditData", {
          dataType: "demographics", // pass the module name
          formType: "form", // pass the propery name
          id: this.$route.params.id,
      });   
        if(this.form.previous_country_id == 0 || this.form.previous_country_id != '' || this.form.previous_country_id == null){
          this.form.previous_country_id = 231;
      }

      this.date_of_birth = moment(String(this.form.date_of_birth)).format('MM-DD-YYYY');
      if (this.form.responsible_party_dob!= '') {
          this.responsible_party_dob = moment(String(this.form.responsible_party_dob)).format('MM-DD-YYYY');  
      }
      if (this.form.referral_date!= '') {
          this.referral_date = moment(String(this.form.referral_date)).format('MM-DD-YYYY');  
      }
      for (var i = 0; i < this.form.customDemographics.length; i++) {
          this.parent_id[this.form.customDemographics[i]['custom_demographics_id']] =  this.form.customDemographics[i]['value']
      }
      var $this = this;
      if(this.form.country_id != null && this.form.country_id != ''){
          axios
          .post("/json_autocomplete/" + this.form.country_id + "/state")
          .then(
              (response) =>
              ($this.states = response.data.data.map((state) => {
                  return { id: state.id, text: state.name };
              }))
              );
      }

      if(this.form.previous_country_id != null && this.form.previous_country_id != ''){
          axios
          .post("/json_autocomplete/" + this.form.previous_country_id + "/state")
          .then(
              (response) =>
              ($this.previous_states = response.data.data.map((state) => {
                  return { id: state.id, text: state.name };
              }))
              );
      }

      if(this.form.race != null && this.form.race != ''){
          console.log(this.form.race);
          axios
          .get("json_dropdown/race-granularity/"+this.form.race)
          .then(
              (response) =>
              (
                  this.granularityoption = response.data.data.map((granularity) => {
                    return { id: granularity.id, text: granularity.description }
                })
                  )
              );
      }

      if(this.form.additional_race != null && this.form.additional_race != ''){
          axios
          .get("json_dropdown/race-granularity/"+this.form.additional_race)
          .then(
              (response) =>
              (
                  this.additional_granularityoption = response.data.data.map((granularity) => {
                    return { id: granularity.id, text: granularity.description }
                })
                  )
              );
      }
  }
}
axios.get("json_dropdown/ethnicity-granularity")
.then((response) =>(
  this.ethnicity_granularity_option = response.data.data.map((ethnicity_g) => {
    return { id: ethnicity_g.id, text: ethnicity_g.name }
})
  )
);
},

mounted() {
    axios.post("patient/custom-demographics")
    .then((response) =>(
      this.customdemographics = response.data.data
      )
    );

    var d = new Date();
    this.current_date = moment(String(d)).format('MM-DD-YYYY');
    if (this.$route.name === "PatientDemographics") {
      this.mainclass = "";
  }

  axios.get("/referral-contact/list").then(
      (response) =>
      (this.$store.state.demographics.referring_doctor = response.data.data.map(
          (doctor) => {
            return { id: doctor.id, text: doctor.first_name };
        }
        ))
      );

  axios.get("/json_autocomplete/country")
  .then((response) => {
    this.countries = response.data.data.map((country) => {
      return { id: country.id, text: country.name };
  });
    this.previous_countries = response.data.data.map((country) => {
      return { id: country.id, text: country.name };
  })
});
  this.$nextTick(function () {
      if (this.$route.query.redirectTo) {
          const myEl = document.getElementById('admin_notes');
          if (myEl) {
            this.$smoothScroll({
              scrollTo: myEl,
            });
          }
      }
  })
},
methods: {
  async printPatientDemo() {
    await this.$htmlToPaper('pd_detail_table',{
      styles: [ 'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css']
    });
  },
  async printPatientDemoApply() {
      if (this.export_type == 'pdf') {
        if (Object.keys(this.patient_demo_selected).length > 0) {
            $("#viewReceipt").modal("show");
            this.$store.state.loader = true;
            const res = await fetch(process.env.VUE_APP_API_BASE_URL+'/exportDemoGraphics', {
              method: 'POST',
              headers: {
                'Authorization' : 'Bearer '+localStorage.getItem('authToken'),
                'Content-Type' : 'application/json'
              },
              body: JSON.stringify({ patient_id : this.$store.state.patientDetail.patient_id, 'export_type' : this.export_type, patient_demo_selected : this.patient_demo_selected }),
            });
            const blob = await res.blob();
            const urlObject = URL.createObjectURL(blob);
            this.$store.state.loader = false;
            $('#pdFilterDetail').modal('hide');
            if (/Android|webOS|Opera Mini/i.test(navigator.userAgent)) {
              $("#viewReceipt").modal("hide");
              window.open(urlObject, '_blank', 'height=600,width=1200');
            } else {
              this.receipt_url = urlObject;
              this.browserCheck();
            }
        } else {
          this.moshaToast("Please select atleast one field.", "error");
        }
      } else {
        if (Object.keys(this.patient_demo_xls_selected).length > 0) {
          $('#pdFilterDetail').modal('hide');
          axios.post("exportDemoGraphics",{ patient_id : this.$store.state.patientDetail.patient_id, 'export_type' : this.export_type, patient_demo_selected : this.patient_demo_xls_selected })
           .then((response) => {
              let blob = new Blob([response.data.data], {
                  type: 'application/pdf'
              })
              let link = document.createElement('a')
              link.href = response.data.data
              link.download = 'patients.xlsx'
              link.click()
              this.moshaToast("Download XSL successfully", "success");
               /*if (is_print) {
                   this.patient_demo_export = response.data.data;
                   console.log(this.patient_demo_export.chart_id);
                   $('#pdDetail').modal('show');
               } else {
                
               }*/
           });
         } else {
            this.moshaToast("Please select atleast one field.", "error");
         }
      }
  },
  exportDemographics(type) {
       this.export_type = type;
      //  console.log(this.export_type);
       $('#pdFilterDetail').modal('show');
  },
  browserCheck(){
    // Opera 8.0+
    var isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

    // Firefox 1.0+
    var isFirefox = typeof InstallTrigger !== 'undefined';

    // Safari 3.0+ "[object HTMLElementConstructor]" 
    var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));

    // Internet Explorer 6-11
    var isIE = /*@cc_on!@*/false || !!document.documentMode;

    // Edge 20+
    var isEdge = !isIE && !!window.StyleMedia;

    // Chrome 1 - 79
    var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

    // Edge (based on chromium) detection
    var isEdgeChromium = isChrome && (navigator.userAgent.indexOf("Edg") != -1);

    // Blink engine detection
    var isBlink = (isChrome || isOpera) && !!window.CSS;

    if (isChrome==true) {
      this.embed = 0;
    } else {
      this.embed = 1;
    }
  },
  getvalue(id,e){
      if(e){
        this.customdemographicsvalue.push({"custom_demographics_id":id,"value":e});
  }
},
previous_zip_code(){      
  if(this.form.previous_zip_code && this.form.previous_zip_code.length > 3){
    axios.post("json_dropdown/state-city-auto-populations",{zip_code:this.form.previous_zip_code})
    .then((response) => {
        this.form.previous_city=response.data.data.City;
        this.form.previous_state_id=response.data.data.id
    });
}
else{
    this.form.previous_city= null;
    this.form.previous_state_id=null;
}
},
responsible_party_zip(){
  if (this.form.responsible_party_zip_code && this.form.responsible_party_zip_code.length > 3 ) {
    axios.post("json_dropdown/state-city-auto-populations",{zip_code:this.form.responsible_party_zip_code})
    .then((response) => {
        this.form.responsible_party_city =response.data.data.City;
        this.form.responsible_party_state_id =response.data.data.id
    });
  } else{
    this.form.responsible_party_city= null;
    this.form.responsible_party_state_id=null;
  }
},
zipcode(){
  if(this.form.zip_code && this.form.zip_code.length > 3 ){
    axios.post("json_dropdown/state-city-auto-populations",{zip_code:this.form.zip_code})
    .then((response) => {
        this.form.city=response.data.data.City;
        this.form.state_id=response.data.data.id
    });
}
else{
    this.form.city= null;
    this.form.state_id=null;
}
},
getdate($event){
    if($('#date_of_birth').val() == '' && this.form.date_of_birth == '' ) {
      this.form.date_of_birth = null;
    } else {      
      this.form.date_of_birth = moment($event.value).format('YYYY-MM-DD');
    }  
},
getdobdate($event){
  if($('#responsible_party_dob').val() == ''){
      this.form.responsible_party_dob = null;
  }else{
      this.form.responsible_party_dob = moment($event.value).format('YYYY-MM-DD');
  }
},
getreferral_date($event){
  if($('#referral_date').val() == ''){
    this.form.referral_date = null;
}else{
    this.form.referral_date = moment($event.value).format('YYYY-MM-DD');
}
},
raceChange(id){
  axios.get("json_dropdown/race-granularity/"+this.form.race)
  .then((response) => {
    this.granularityoption = response.data.data.map((granularity) => {
      return { id: granularity.id, text: granularity.description }
  });
});  
},
additionalraceChange(id){
  axios.get("json_dropdown/race-granularity/"+this.form.additional_race)
  .then((response) => {
    this.additional_granularityoption = response.data.data.map((granularity) => {
      return { id: granularity.id, text: granularity.description }
  });
});  
},
reason(){
  if(this.form.referring_doctor != ''){
    $("#referral_reason").removeClass("d-none");
    $("#referral_date_div").removeClass("d-none");  
}
},
previouscountryChange(id, text){
  axios.post("/json_autocomplete/" + this.form.previous_country_id + "/state").then(
    (response) =>
    (this.previous_states = response.data.data.map((state) => {
        return { id: state.id, text: state.name };
    }))
    );
},
countryChange({ id, text }) {
  axios.post("/json_autocomplete/" + id + "/state").then(
    (response) =>
    (this.states = response.data.data.map((state) => {
        return { id: state.id, text: state.name };
    }))
    );
},
newcontact() {
  this.$store.state.ReferralDoctor.renderComponent = true;
},
save(next) {
  this.form.customdemographicsvalue = this.customdemographicsvalue;
  if(this.form.date_of_birth != null){
    this.form.date_of_birth = moment(this.form.date_of_birth).format('YYYY-MM-DD');
    
}      

if (this.form.responsible_party_dob != null) {
    this.form.responsible_party_dob = moment(String(this.form.responsible_party_dob)).format('YYYY-MM-DD');  
}

if (this.form.referral_date != null) {
    this.form.referral_date =  moment(String(this.form.referral_date)).format('YYYY-MM-DD');
}      

if (this.$route.name === "PatientDemographics") {        
    if (this.$store.state.patientDetail.patient_demographics_id) {
      this.postForm(
        `/patient/${this.$route.params.id}/demographics/update`,
        "demographics"
        );
  } else {
      this.postForm(
        `/patient/${this.$route.params.id}/demographics/store`,
        "demographics"
        );
  }
} else {
    this.postForm(
      `/patient/${this.$route.params.id}/demographics/store`,
      "demographics"
      );
}
this.$store.state.patientDetail.renderComponent = false;
},
formPostSuccess(data) {
  this.$store.state.patientDetail.renderComponent = true;
  if (this.$route.name === "PatientDemographics") {
    if (this.$store.state.patientDetail.patient_demographics_id) {
          // If edit, get data from server
          this.$store.dispatch("getEditData", {
            dataType: "demographics", // pass the module name
            formType: "form", // pass the propery name
            id: this.$route.params.id,
        });
          $("#referral_reason").addClass("d-none");
          $("#referral_date_div").addClass("d-none");  
      }
      axios.post(`/patient/${this.$route.params.id}/rcopia_registration`,{ patient_id: data.patient_id, patient_rcopia_id:null });
      this.moshaToast("Record updated successfully.", "success");
  } else {
    axios.post(`/patient/${this.$route.params.id}/rcopia_registration`,{ patient_id: data.patient_id, patient_rcopia_id:null });
     this.$router.push(`/patient-chart/${this.$route.params.id}`);
    this.moshaToast("Record added successfully.", "success");
}
},
reset() {
    this.searchKey = this.searchKey+1;
},
},
data() {
    return {
      waterMarkText:"MM-DD-YYYY",
      current_date: "",
      mainclass: "col-12 col-sm-12 col-md-9 col-lg-9 col-xl-10",
      saveAndNext: false,
      countries: [],
      states: [],
      previous_countries:[],
      previous_states:[],
      customdemographics:[],
      customdemographicsvalue:[],
      parent_id:[],
      sex: [
        { value: "Male", title: "Male" },
        { value: "Female", title: "Female" },
        { value: "Others", title: "Others" },
        { value: "Unknown", title: "Unknown" },
        { value: "Declined to specify", title: "Declined to specify" },
      ],
      gender_identity: [
        { value: "1", title: "Male" },
        { value: "2", title: "Female" },
        { value: "3", title: "Male-to-Female (MTF)/Transgender Female/Trans Woman" },
        { value: "4", title: "Female-to-Male (FTM)/Transgender Male/Trans Man" },
        { value: "5", title: "Genderqueer, neither exclusively male nor female" },
        { value: "6", title: "Other" },
        { value: "7", title: "Unknown" },
        { value: "8", title: "Choose not to disclose" },
      ],
      sexual_orientation: [
        { value: "1", title: "Heterosexual (not lesbian, gay, or bisexual)" },
        { value: "2", title: "Lesbian or gay" },
        { value: "3", title: "Bisexual" },
        { value: "4", title: "Other" },
        { value: "5", title: "Unknown" },
        { value: "6", title: "Choose not to disclose" },
      ],
      ethnicity: [
        { value: "Hispanic or Latino", title: "Hispanic or Latino" },
        { value: "Not Hispanic or Latino", title: "Not Hispanic or Latino" },
        { value: "Decline to specify", title: "Decline to specify" },
        { value: "Unknown", title: "Unknown" },
      ],
      preferred_language: [
        { value: "English", title: "English" },
        { value: "Spanish", title: "Spanish" },
      ],
      employment_status: [
        { value: "Part-Time Student", title: "Part-Time Student" },
        { value: "Full-time Student", title: "Full-time Student" },
        { value: "Part-Time Employed", title: "Part-Time Employed" },
        { value: "Full-Time Employed", title: "Full-Time Employed" },
        { value: "Self-Employed", title: "Self-Employed" },
        { value: "Retired", title: "Retired" },
        { value: "Disabled", title: "Disabled" }
      ],
      responsible_relation_list: [
        { value: "1", title: "Self" },
        { value: "2", title: "Spouse" },
        { value: "3", title: "Son" },
        { value: "4", title: "Daughter" },
        { value: "5", title: "Other" }
      ],
      marital_status: [
        { value: "Single", title: "Single" },
        { value: "Married", title: "Married" },
        { value: "Widowed", title: "Widowed" },
        { value: "Divorced", title: "Divorced" },
        { value: "Other", title: "Other" },
      ],
      epsdt_services_id: [
        { value: "Children", title: "Children" },
        { value: "Family Planning", title: "Family Planning" },
        {
          value: "Children / Family Planning",
          title: "Children / Family Planning",
        },
        { value: "Pregnancy", title: "Pregnancy" },
      ],
      epsdt_referral_condition_id: [
        { value: "AV - Available", title: "AV - Available" },
        { value: "NU - Not Used", title: "NU - Not Used" },
        { value: "S2 - Under Treatment", title: "S2 - Under Treatment" },
        {
          value: "ST - New Services Requested",
          title: "ST - New Services Requested",
        },
      ],
      raceOption:[],
      languages:[],
      granularityoption:[],
      additional_granularityoption:[],
      ethnicity_granularity_option:[],
      dateFormat: 'MM-dd-yyyy',
      date_of_birth :'',
      responsible_party_dob : new Date(),
      strict: true,
      maxVal: new Date(),
      referral_date: null,
      patient_demo_export: {},
      path: process.env.VUE_APP_KB_DOCUMENT,
      searchKey:0,
      patient_demo_selected: ['chart_id','first_name','last_name','date_of_birth','approx_age','gender','gender_identity','sexual_orientation','race','address','city','state','zip_code','email','cell_phone','ssn_number','driving_license_no','driver_licence_docs','ins_front_image','ins_rear_image'], //,'notes'
      patient_demo_xls_selected: ['chart_id','first_name','last_name','date_of_birth','approx_age','gender','gender_identity','sexual_orientation','race','address','city','state','zip_code','email','cell_phone','ssn_number','driving_license_no'], //,'notes'
      patient_demo_export_key: [
          { 'name' : 'chart_id', 'lable' : 'Chart No.' },
          { 'name' : 'first_name', 'lable' : 'First Name' },
          { 'name' : 'last_name', 'lable' : 'Last Name' },
          { 'name' : 'date_of_birth', 'lable' : 'DOB' },
          { 'name' : 'approx_age', 'lable' : 'Approx Age.' },
          { 'name' : 'gender', 'lable' : 'Gender' },
          { 'name' : 'gender_identity', 'lable' : 'Gender Identity' },
          { 'name' : 'sexual_orientation', 'lable' : 'Sexual Orientation' },
          { 'name' : 'race', 'lable' : 'Race' },
          { 'name' : 'address', 'lable' : 'Address' },
          { 'name' : 'city', 'lable' : 'City' },
          { 'name' : 'state', 'lable' : 'State' },
          { 'name' : 'zip_code', 'lable' : 'Zipcode' },
          { 'name' : 'email', 'lable' : 'Email' },
          { 'name' : 'cell_phone', 'lable' : 'Cell Phone' },
          { 'name' : 'ssn_number', 'lable' : 'SSN Number' },
          { 'name' : 'driving_license_no', 'lable' : 'Patient\'s Driver Licence No' },
          { 'name' : 'driver_licence_docs', 'lable' : 'Patient\'s Driver Licence' },
          { 'name' : 'ins_front_image', 'lable' : 'Insurance Card Front' },
          { 'name' : 'ins_rear_image', 'lable' : 'Insurance Card Back' },
          // { 'name' : 'notes', 'lable' : 'Admin note' },
      ],
      patient_demo_xls_export_key: [
          { 'name' : 'chart_id', 'lable' : 'Chart No.' },
          { 'name' : 'first_name', 'lable' : 'First Name' },
          { 'name' : 'last_name', 'lable' : 'Last Name' },
          { 'name' : 'date_of_birth', 'lable' : 'DOB' },
          { 'name' : 'approx_age', 'lable' : 'Approx Age.' },
          { 'name' : 'gender', 'lable' : 'Gender' },
          { 'name' : 'gender_identity', 'lable' : 'Gender Identity' },
          { 'name' : 'sexual_orientation', 'lable' : 'Sexual Orientation' },
          { 'name' : 'race', 'lable' : 'Race' },
          { 'name' : 'address', 'lable' : 'Address' },
          { 'name' : 'city', 'lable' : 'City' },
          { 'name' : 'state', 'lable' : 'State' },
          { 'name' : 'zip_code', 'lable' : 'Zipcode' },
          { 'name' : 'email', 'lable' : 'Email' },
          { 'name' : 'cell_phone', 'lable' : 'Cell Phone' },
          { 'name' : 'ssn_number', 'lable' : 'SSN Number' },
          { 'name' : 'driving_license_no', 'lable' : 'Patient\'s Driver Licence No' },
          // { 'name' : 'notes', 'lable' : 'Admin note' },
      ],
      export_type:"",
      embed: 0,
      receipt_url: ''
  };
},
validations() {
    return this.$store.state.demographics.validationRules;
},
};
</script>
<style>
.select2-container {
  width: 100% !important;
}
</style>
