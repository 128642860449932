<template>
    <div class="content-wrapper">
        <div class="container-full">
            <div class="content-header">
                <h1 class="heading1 mt-0">Remittance Reports <a href="#" data-bs-toggle="modal" data-bs-target="#remittancedocument" title="Reference of Knowledge Base" v-on:click.prevent="reset()"> <i class="fa fa-question-circle fs-20"></i></a></h1>
            </div>
            <div class="box">
                <div class="box-body">
                    <div class="row">                    
                        <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2">
                            <div class="controls">
                                <select name="select" v-model="filter.remit_type" required="" id="Status" class="form-select form-control">
                                    <option value="">ERA,EOB &amp; Patient Payment</option>
                                    <option value="ERA">ERA</option>
                                    <option value="EOB">EOB</option>
                                    <option value="Patient Payment">Patient Payment</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2">
                            <div class="form-group">
                                <div class="selectcontrols">
                                    <Autocomplete :max="30" @input="gettrnnumber" ref="trnautocomplete" :results="trn_list" @onSelect="select_trn" class="form-control ms-0 autosearch-list" placeholder="Trace #"></Autocomplete>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2">
                            <div class="controls">
                                <select name="select" v-model="filter.date_for" required="" id="Status" class="form-select form-control">
                                    <option value="received">Posted Date</option>
                                    <option value="check">Check Date</option>
                                    <option value="deposit">EOB Deposit Date</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                            <div class="form-group">
                                <div class="selectcontrols">
                                    <Autocomplete :max="30" @input="getinslist" ref="autocomplete" :results="insurance_list" @onSelect="resultsvalue" :use-html-for-results ="true" class="form-control ms-0 autosearch-list" placeholder="Insurance Company"></Autocomplete>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2">
                            <div class="form-group">
                                <div class="selectcontrols">
                                    <Autocomplete :max="30" @input="getpatient" ref="patientautocomplete" :results="patient_list" @onSelect="select_patient" class="form-control ms-0 autosearch-list" placeholder="Patient"></Autocomplete>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                            <div class="form-group">
                                <div class="availdate">
                                    <ejs-daterangepicker format='MM-dd-yyyy' id="date_range" :value="date" 
                                    v-model="filter.date_range" strictMode='strict'
                                    placeholder="Select Date Range"  @change="onRangeSelect"></ejs-daterangepicker>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-2 col-md-2 col-lg-3 col-xl-4">
                            <a @click="filter_list" class="waves-effect waves-light btn btn-info-light mt-xs-5 me-1">Search <i class="fa fa-search fs-14" aria-hidden="true"></i> </a>

                            <a @click="export_list" class="waves-effect waves-light btn btn-info-light mt-xs-5">Export <i class="fa fa-download fs-14" aria-hidden="true"></i></a>
                            <div v-if="csv_export_message!=null">
                                <div class="text-danger">{{csv_export_message}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="box">
                <div class="box-body">
                    <data-table-server dataType="RemittanceReport" dataMethod="post" :key="key">
                            <template v-slot="slotProps">			
								<td>{{ formatDate(slotProps.item.remit_check_date,"MM/DD/YYYY") }}</td>
								<td>{{ formatDate(slotProps.item.remit_received_date,"MM/DD/YYYY") }}</td>
								<td>{{ formatDate(slotProps.item.remit_deposit_date,"MM/DD/YYYY") }}</td>
								<td>
                                    <router-link :to="{ name: 'ERADetailReport', params: { id: slotProps.item.remit_trace_number }}" class="fw-500">
                                        <slot v-if="slotProps.item.is_pt_payment==1">
                                            [Patient Payment]{{slotProps.item.pt_check_number}}
                                        </slot>
                                        <slot v-else>
                                            [{{slotProps.item.remit_type}}]{{slotProps.item.remit_trace_number}}
                                        </slot>                                            
                                    </router-link>
                                </td>
								<td>
                                    <slot v-if="slotProps.item.remit_payer_name!=null">
                                        {{slotProps.item.remit_payer_name}}
                                    </slot>
                                    <slot v-else>
                                        -
                                    </slot>
                                </td>
								<td>{{ slotProps.item.no_claims }}</td>
                                <td>{{ $filters.toUSD(slotProps.item.total_amount) }}</td>
                                <td>{{ $filters.toUSD(slotProps.item.total_amount_paid) }}</td>
                                <td>{{ $filters.toUSD(slotProps.item.adjustment_amount) }}</td>
                                <td>{{ $filters.toUSD(slotProps.item.copay_amt) }}</td>
                                <td>{{ $filters.toUSD(slotProps.item.coinsurance_amt) }}</td>
                                <td>{{ $filters.toUSD(slotProps.item.deductible_amt) }}</td>
                                <td>{{ $filters.toUSD(slotProps.item.pr_paid_amt) }}</td>
                                <td>{{ $filters.toUSD(slotProps.item.other_amt) }}</td>
								<td>{{ slotProps.item.remit_payment_type }}</td>
								<td>
                                    <span v-if="slotProps.item.scanned_EOB != null" >
                                        <Popper arrow content="Click Here to view EOB file" :hover="true" class="helptips cursor-pointer">
                                        <a href="javascript:void(0)"  class="waves-effect waves-light btn btn-info-light btn-xs me-1" @click="view_EOB(slotProps.item.scanned_EOB,slotProps.item.clinic_id)" ><i class="fa fa-eye"></i></a>
                                        </Popper>                                                
                                    </span>
                                </td>
							</template>
                    </data-table-server>
                </div>
            </div>
            <div class="modal" tabindex="-1" id="depositDateModal">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h3 class="modal-title font-bold m-0" id="claim-data-head">
                                Deposit Date
                            </h3>                        
                        </div>
                        <div class="modal-body" id="depositDateModalBody">
                            <div class="waves-effect waves-light btn btn-info-light btn-xs float-end me-5">
                                Trace #{{form.trace_number}}
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end"> Deposit Date</label>
                                <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4">
                                    <div class="availdate">
                                        <ejs-datepicker :value="deposit_date" id="deposit_date" :format="dateFormat" placeholder="Select Deposite Date" @change="getdate($event)" name="deposit_date" :strictMode='true' :showClearButton='false' :openOnFocus='true'></ejs-datepicker>
                                    </div>
                                </div>
                            </div>                        
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="waves-effect waves-light btn btn-info" @click="saveDepositDate"> Save </button>
                            <button type="button" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
                        </div>
                    </div>
                </div>
            </div>            
            <!--document model start-->
			<div id="remittancedocument" class="modal fade" tabindex="-1" role="dialog"
				aria-labelledby="myModalLabel" aria-hidden="true">
				<div class="modal-dialog modal-md">
					<div class="modal-content">
					<div class="modal-header">
					<h3 class="modal-title fw-900 mt-0">Reference of Knowledge Base</h3>
					</div>
					<div class="modal-body overflow-auto" style="max-height: 350px;">
					<table class="table table-bordered">
						<thead>
							<tr>
							<th scope="col">No.</th>
							<th scope="col">Knowledge Base Articles</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>1</td>
								<td> <a :href="path+'how-to-check-remittance-in-pureehr'" target="_blank">How To Check Remittance In PureEHR?</a></td>
							</tr>
						</tbody>
					</table>
                    <Search :key="searchKey"></Search>
					</div>
					<div class="modal-footer">
					<button type="button" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
					</div>
					</div>
				</div>
			</div>
			<!--document model end-->                 
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import axios from "@/scripts/axios.js";
import permissionMixins from "@/scripts/mixins/permission.js";
import submitMixins from "@/scripts/mixins/submitMixins.js";
import selectCheckBox from "@/components/base/selectCheckBox.vue";
import Popper from "vue3-popper";
import useVuelidate from "@vuelidate/core";
import Autocomplete from 'vue3-autocomplete';
import InputField from "@/components/base/formFields/InputField.vue";
import Search from "@/components/Search.vue";
import DataTableServer from "@/components/base/DataTableServer.vue";
export default {
    mixins: [permissionMixins,submitMixins],
    components: {selectCheckBox,Popper,Autocomplete,DataTableServer, InputField, Search},
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            key: 0,            
            report_number: '',
            deposit_date: '',
            date : '',
            trn_list:[],
            patient_list:[],
            insurance_list:[],
            list:[],
            dateFormat: 'MM-dd-yyyy',
            total_list:'',
            start:0,
            end:20,
            step:20,
            steps:'',
            file_name: "",
            coustom_error: "",
            pt_form:{
                pt_pay_type:"",
                pt_posting_date:null,
                pt_deposit_date:null,
                pt_check_date:null,
                pt_check_number:null,
                pt_check_amount:null,
                is_pt_payment:null,
                trace_number:null,
            },  
            pt_posting_date:null,
            pt_deposit_date:null,
            pt_check_date:null,    
            csv_export_message:null,  
            path: process.env.VUE_APP_KB_DOCUMENT,
            searchKey:0,    
        }
    },
    computed: {
        form() {
            return this.$store.state.eob.form;
        },
        vuelidateExternalResults() {
            return this.$store.state.eob.vuelidateExternalResults;
        },
        validationRules() {
            return this.$store.state.eob.validationRules;
        },
        filter(){
           return this.$store.state.RemittanceReport.filter; 
        }
    },
    methods: {
        reset() {
            this.searchKey = this.searchKey + 1;
        },
        type_validation() {
            if (this.form.uploadFiles.type != "application/pdf") {
                this.coustom_error = "Only pdf,txt,docx formats are supported.";
            } else {
                this.coustom_error = "";
            }
        },
        pagejump(id){
            var last_id = parseInt(id * this.step);
            this.start = parseInt(last_id - this.step);
            this.end = last_id;
            $('.myCheckbox').prop('checked', false); 
            $('#checkAll').prop('checked', false); 
        },
        next(end){
            this.start = end;
            this.end = parseInt(end) + parseInt(this.step);
            $('.myCheckbox').prop('checked', false); 
            $('#checkAll').prop('checked', false); 
        },
        previous(end,start){
            this.start = parseInt(start)-parseInt(this.step);
            this.end = start;
            $('.myCheckbox').prop('checked', false); 
            $('#checkAll').prop('checked', false); 
        },
        formatDate(date, format) {
            if(date != null && date != ''){
                return moment(date).format(format);
            }else{
                return '-';
            }
        },
        saveDepositDate(){
            axios.post("/claim/deposit_date",this.form)
            .then((response) =>{
                $("#depositDateModal").modal('hide');
                this.getList();
            });
        },
        getdate($event){
            if($('#deposit_date').val() == '' && this.form.deposit_date == '' ) {
                this.form.deposit_date = null;
            } else {      
                this.form.deposit_date = moment($event.value).format('YYYY-MM-DD');
            }  
        },
        getposting_date($event){
            if($('#pt_posting_date').val() == '' && this.pt_form.pt_posting_date == '' ) {
                this.pt_form.pt_posting_date = null;
            } else {      
                this.pt_form.pt_posting_date = moment($event.value).format('YYYY-MM-DD');
            }  
        },
        getdeposit_date($event){
            if($('#pt_deposit_date').val() == '' && this.pt_form.pt_deposit_date == '' ) {
                this.pt_form.pt_deposit_date = null;
            } else {      
                this.pt_form.pt_deposit_date = moment($event.value).format('YYYY-MM-DD');
            }  
        },
        getcheck_date($event){
            if($('#pt_check_date').val() == '' && this.pt_form.pt_check_date == '' ) {
                this.pt_form.pt_check_date = null;
            } else {
                this.pt_form.pt_check_date = moment($event.value).format('YYYY-MM-DD');
            }  
        },
        addDepositDate(trace_number){
            this.form.trace_number = trace_number;
            $("#depositDateModal").modal('show');
        },
        onRangeSelect() {
            var daterange = document.getElementById("date_range").value;
            if(daterange != ''&& daterange != null){
                var date = daterange.split(' - ');
                this.filter.start_date = moment(String(date[0])).format('YYYY-MM-DD');
                this.filter.end_date = moment(String(date[1])).format('YYYY-MM-DD');
            }else{
                this.filter.start_date = null;
                this.filter.end_date = null;
            }            
        },
        gettrnnumber(obj){
            axios.get("/json_autocomplete/get-trn-number",{
                params: {term: obj}
            }).then(response => { 
                this.trn_list = response.data.data.map((code) => {
                    return { id: code.remit_trace_number, name: code.remit_trace_number }
                });
            });
        },
        select_trn(args){
            this.$refs.trnautocomplete.searchText = args.name;
            this.filter.trace_number = args.name;
        },
        getpatient(obj){
            axios.post("/patient/patient-search",{search_text:obj}).then(response => { 
                this.patient_list = response.data.data.map((code) => {
                    return { id: code.id, name: code.name }
                });
            });
        },
        select_patient(args){
            this.$refs.patientautocomplete.searchText = args.name;
            this.filter.patient_id = args.id;
        },
        getinslist (obj) {
            axios.get("/json_autocomplete/insurance-company-list",{
                params: {term: obj}
            }).then(response => { 
                this.insurance_list = response.data.data.map((code) => {
                    return { id: code.id, name: "<b>"+code.code+"</b><br>"+code.name,code:code.code,title:code.name }
                });
            });          
        },
        resultsvalue(obj){
            this.$refs.autocomplete.searchText = obj.title;            
        },
        getList(){
            this.$store.state.loader = true;
            axios.post("reports/remittance-report-list",this.filter)
            .then((response) =>{
                this.list = response.data.data
                this.total_list = this.list.length;
                this.steps = parseInt(this.total_list / this.step);
                this.$store.state.loader = false;
            });            
        },
        filter_list(){
            this.key++;
        },        
        attachEOB() {
            let remit_report_number=$("#trace_number").val();
            if (this.coustom_error == "") {
                this.postForm(`reports/store/`+remit_report_number,"eob", true, "formdata");
            }
        },
        view_EOB(doc_name,c_id){
            axios.post("/view-document",{doc_name:doc_name,folder_name:'EOB',clinicId:c_id})
                    .then((response) => {
            console.warn(response.data);
                        window.open(response.data, '_blank').focus();
                    });
        },
        export_list(){
            var data = {dataarray : []}
            this.list.forEach((element) => {					
                data.dataarray.push(element.remit_trace_number);
            });
            this.$store.state.loader = true;
            axios.post("/claim/exportRemitReport",data)
            .then((response) => {
                this.csv_export_message = "Please find your file in Download Logs Section.";
                this.$store.state.loader = false;
                setTimeout(() => {
                    this.csv_export_message = null;
                }, 8000);
            });
        },
    },
    mounted() {
        this.date = moment().subtract(1, 'months').format("MM-DD-YYYY")+' - '+moment(new Date()).format("MM-DD-YYYY"); 
        this.getList();
    },
    async beforeCreate() {
        this.$store.state.eob.form = {};
        Object.assign(
            this.$store.state.eob.form,
            this.$store.state.eob.defaultFormData
        );
    },
    validations() {
      return this.$store.state.eob.validationRules;
    }   
}
</script>