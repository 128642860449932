<template>
	<div class="content-wrapper min-h-p100">
		<div class="container-full">
			<div class="content min-h-p100">
				<div class="row mb-20">
					<div class="col-12 col-sm-12 col-md-6 col-lg-6">
						<h1 class="mt-0 mb-10">Development Status Report</h1>
					</div>
					<div class="col-12 col-sm-12 col-md-6 col-lg-6">
						<table class="float-md-end fs-16 mb-20">
							<tbody>
								<tr>
									<td class="legendColorBox px-1">
										<div style="border:1px solid #ccc;padding:1px">
											<div style="width:4px;height:0;border:5px solid #c3e6cb;overflow:hidden"></div>
										</div>
									</td>
									<td class="legendLabel px-1">Running</td>
									<td class="legendColorBox px-1">
										<div style="border:1px solid #ccc;padding:1px">
											<div style="width:4px;height:0;border:5px solid #ffeeba;overflow:hidden"></div>
										</div>
									</td>
									<td class="legendLabel px-1">Hold</td>
									<td class="legendColorBox px-1">
										<div style="border:1px solid #ccc;padding:1px">
											<div style="width:4px;height:0;border:5px solid rgba(0,0,0,.075);overflow:hidden"></div>
										</div>
									</td>
									<td class="legendLabel px-1">Not Started</td>
									<td class="legendColorBox px-1">
										<div style="border:1px solid #ccc;padding:1px">
											<div style="width:4px;height:0;border:5px solid #bee5eb;overflow:hidden"></div>
										</div>
									</td>
									<td class="legendLabel">Completed</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<iframe class="responsive-iframe" scrolling="no" width="100%" onload="height=screen.height;" src="https://support.jilesh.com/iframe/project/report/9ZLOPNCX" style="height:60vh;"></iframe>
			</div>
		</div>
	</div>
</template>