import { required, email, minLength,helpers } from "@vuelidate/validators";
const AppointmentProfilesList = {
  namespaced: true,

  state: {
    listUrl: "/appointment-profile/list-master",
    header: [
      {"name":"Name"},
      {"color_code":"Color"},
      {"default_duration":"Duration"},
      {"reason_for_visit":"Reson for Visit"},
      {"billing_profile":"Billing Profile"},
      {"":"Payment Profile"},
      {"consent_form_total":"Consent Form"},
      {"updated_ats":"Updated"},
      {"created_ats":"Created"},
      {"":"Actions"},
    ],
    pagination: {
      to: "",
      from: "",
      total: "",
      last_page: "",
      current_page: "",
      pageNavArr: [],
    },    
    form: {},
    vuelidateExternalResults: {
      form: {
        id:[],
        name: [],
        color_code:[],
        reason_for_visit:[],
        default_duration:[],
        payment_profile_type:[],
        required_consent:[],
        consent_form_ids:[],
      },
    },
    validationRules: {
      form: {
        name: {
          required: helpers.withMessage("Name is required", required),
        },
        color_code: {
          required: helpers.withMessage("Color Code is required", required),
        }
      },      
    },
    getEditUrl: "/appointment-profile/edit/:id",
    list: [],
  },
  getters: {
    getTableHeaders: (state) => state.header,

    getTableContents: (state) => state.list,
    getListURL: (state) => state.listUrl,
  },
  mutations: {},
  actions: {},
}

const AppointmentProfilesArchivedList = {
  namespaced: true,

  state: {
    listUrl: "/appointment-profile/list-master/archived",
    header: [
      {"name":"Name"},
      {"color_code":"Color"},
      {"default_duration":"Duration"},
      {"reason_for_visit":"Reson for Visit"},
      {"billing_profile":"Billing Profile"},
      {"":"Payment Profile"},
      {"consent_form_total":"Consent Form"},
      {"deleted_ats":"Deleted at"},
      {"created_ats":"Created at"},
      {"":"Actions"},
    ], 
    pagination: {
      to: "",
      from: "",
      total: "",
      last_page: "",
      current_page: "",
      pageNavArr: [],
    },  
    list: [],
  },
  getters: {
    getTableHeaders: (state) => state.header,
    getTableContents: (state) => state.list,
    getListURL: (state) => state.listUrl,
  },
  mutations: {},
  actions: {},
};


const AppointmentStatusList = {
  namespaced: true,

  state: {    
    listUrl: "/appointment-status/list",
    header: [
      {"status_label":"Description"},
      {"created_by":"Type"},
      {"":"Active / Inactive"},
      {"":"Action"},
    ],
    pagination: {
      to: "",
      from: "",
      total: "",
      last_page: "",
      current_page: "",
      pageNavArr: [],
    },
    validationRules: {
        form: {
          status_label: {
            required: helpers.withMessage('Status is required.', required) ,
          }
           
       },
    },
    vuelidateExternalResults: {
        form : {
          status_label : '',
        }
    },
    form : {
      status_label : '',
      
    },
    defaultFormData : {
     status_label : '',
    },
    getEditUrl: "/appointment-status/edit/:id",
    list: [],
  },
  getters: {
    getTableHeaders: (state) => state.header,
    getTableContents: (state) => state.list,
    getListURL: (state) => state.listUrl,
  },
  mutations: {},
  actions: {},
};

export {AppointmentProfilesList,AppointmentProfilesArchivedList,AppointmentStatusList};
//export default permissionList;

