import { createRouter, createWebHashHistory } from "vue-router";
import Home from "../views/Home.vue";
import Unauthorized from "../views/Unauthorized.vue";
import Login from "../views/Login.vue";
import Googleauthentication from "../views/Googleauthentication.vue";
import PatientList from "../views/Patients/PatientsList.vue";
import PatientAddEdit from "../views/Patients/PatientAddEdit.vue";
import PatientDetails from "../views/Patients/PatientDetails.vue";
import CCDAPreview from "../views/Patients/CCDAPreview.vue";
import PatientAppointment from "../views/Patients/PatientAppointment.vue";
import ProviderList from "../views/Providers/List.vue";
import ArchivedProvider from "../views/Providers/ArchivedList.vue";

import ProviderAddEdit from "../views/Providers/AddEdit.vue";
import ProviderAppointmentSwap from "../views/Providers/ProviderAppointmentSwap.vue";
import PHIMailBox from "../views/Providers/PHIMailBox.vue";
import Forgot from "../views/Forgot.vue";
import Reset from "../views/Reset.vue";
import pageNotFound from "../views/404.vue";
import PatientPortalActivation from "../views/PatientPortalActivation.vue";
import UserActivate from "../views/UserActivate.vue";
import store from "@/store";
import OfficeList from "../views/Offices/OfficeList.vue";
import OfficeArchived from "../views/Offices/OfficeArchived.vue";
import OfficeAddEdit from "../views/Offices/OfficeForm.vue";
import LeaveList from "../views/Leave/List.vue";
import VitalsList from "../views/Vitals/list.vue";
import VitalsAdd from "../views/Vitals/Add.vue";
import SubscriptionPlanList from "../views/SubscriptionPlan/List.vue";
import ArchivedLeave from "../views/Leave/ArchivedList.vue";
import generalSetting from "../views/GeneralSetting/GeneralSetting.vue";
import AppointmentStatusDashboard from "../views/Appointments/AppointmentStatusDashboard.vue";
import LifeStyleAppointmentList from "../views/LifeStyle/LifeStyleAppointmentList.vue";
import LifeStyleProcess from "../views/LifeStyle/Home.vue";

import ClinicalNotes from "@/views/Clinical/ClinicalNotes.vue";
import CovidExaminationReport from "@/views/Reports/CovidExaminationReport.vue";


import WriteClinicalNotes from "@/views/Clinical/ClinicalNotes.vue";
import Permissions from "../views/Settings/Permissions.vue";
import CCDApreferences from "../views/Settings/CCDApreferences.vue";
import ManageClinicalNotes from "../views/Clinical/ManageClinicalNotes.vue";
import ManageMacros from "../views/Clinical/ManageMacros.vue";
import AppointmentProfilesList from "../views/AppointmentProfiles/AppointmentProfilesList.vue";
import AppointmentProfilesAdd from "../views/AppointmentProfiles/AppointmentProfileAdd.vue";
import AppointmentProfilesArchivedList from "../views/AppointmentProfiles/AppointmentProfilesArchivedList.vue";
import AppointmentStatus from "../views/AppointmentProfiles/AppointmentStatus.vue";
import StaffList from "../views/Staff/StaffList.vue";
import StaffAddEdit from "../views/Staff/StaffAdd.vue";
import StaffArchivedList from "../views/Staff/StaffArchivedList.vue";

import OfficeAdd from "../views/Offices/OfficeForm.vue";
import ConsentForms from "../views/ConsentForms/ConsentFormsList.vue";
import ConsentFormsarchived from "../views/ConsentForms/ConsentFormsArchived.vue";
import BillingProfileList from "../views/BillingProfile/List.vue";
import BillingProfileArchivedList from "../views/BillingProfile/Archived.vue";
import BillingProfileForm from "../views/BillingProfile/Form.vue";
import Calender from "../views/Appointments/Calender.vue";
import AuditLog from "../views/Clinical/AuditLog.vue";
import DownloadLogs from "../views/Clinical/DownloadLogs.vue";
import AuditTemperLog from "../views/Clinical/AuditTemperLog.vue";
import CDSRules from "../views/Clinical/CDSRules.vue";
import CRMProspectPatient from "../views/CRM/ProspectPatient.vue";
//import CRMProspectPatientDetails from "../views/CRM/ProspectPatientDetails.vue";
//import CRMProspectPatientDetails from "../views/CRM/ProspectPatientDetails.vue";
import WellnessCoordTransfer from "../views/CRM/WellnessCoordTransfer.vue";
import CRMProspectStatus from "../views/CRM/Status.vue";
import CQMList from "../views/Clinical/CQMList.vue";
import WorkflowAddEdit from "../views/CRM/Workflow/AddEdit.vue";
import WorkflowList from "../views/CRM/Workflow/List.vue";
import CustomProcedures from "../views/CustomProcedures/List.vue";
import CustomProceduresarchived from "../views/CustomProcedures/ArchivedList.vue";
import CustomProceduresaddedit from "../views/CustomProcedures/AddEdit.vue";
import AvailabilitySearch from "../views/Appointments/AvailabilitySearch.vue";
import BulkAppointments from "../views/Appointments/BulkAppointments.vue";
import PreAppointment from "../views/Appointments/PreAppointment.vue";
import Notification from "../views/notification/Home.vue";
import MeaningfulAutomatedReport from "../views/MeaningfulAutomatedReport/Home.vue";
import RecoverAppointments from "../views/Appointments/recover_appointments.vue";
import PaymentGatewayMigration from "../views/Reports/PaymentGatewayMigration.vue";
import PaymentAuthentication from "../views/PaymentAuthentication.vue";
import PatientWallet from "../views/walletSetting/PatientWallet.vue";
import LifeStyleServicePaymentAuthorization from "../views/LifeStyle/ServicePaymentAuthorization.vue";
import LifeStyleConsentFormSign from "../views/LifeStyle/ConsentFormSign.vue";
import LifeStyleHippaFormSign from "../views/LifeStyle/HippaFormSign.vue";
import ContractAggrement from "../views/ContractAggrement.vue";
import AppointmentRequest from "../views/AppointmentRequest.vue";
import CRMUnsubscribe from "../views/CRM/crm_unsubscribe.vue";


import HelpSupport from "../views/Help/support.vue";
import AboutPureEHR from "@/components/Help/AboutPureEHR.vue";
import DBLogs from "../views/Help/DBLogs.vue";
import HelpIndex from "../views/Help/index.vue";
import CloseTicket from "@/components/Help/CloseTicket.vue";
import OpenTicket from "@/components/Help/OpenTicket.vue";
import HelpDevelopmentReport from "../views/Help/DevelopmentReport.vue";
import SupportThread from "../views/Help/supportThread.vue";
import Cardauthorizationofprospectus from "../views/Reports/CardAuthorizationofProspectus.vue";
import EmailTemplate from "../views/EmailTemplate/Home.vue";
import WalletSetting from "../views/walletSetting/Home.vue";
import ThirdPartyConfig from "../views/ThirdPartyConfig/Home.vue";
import AddEmailTemplate from "../views/EmailTemplate/EmailTemplateAdd.vue";
import EditEmailTemplate from "../views/EmailTemplate/EmailTemplateAdd.vue";
import SmsTemplate from "../views/SmsTemplate/Home.vue";
import AddSmsTemplate from "../views/SmsTemplate/SmsTemplateAdd.vue";
import EditSmsTemplate from "../views/SmsTemplate/SmsTemplateAdd.vue";
import RecurringAppointments from "../views/Appointments/RecurringAppointments.vue";
import ReminderProfiles from "../views/ReminderProfiles/Home.vue";
import Covidexams from "../views/CovidExams/Home.vue";
import CovidRegistration from "../views/CovidExams/CovidRegistration.vue";
import TeleHealthCovidRegistration from "../views/CovidExams/TeleHealthCovidRegistration.vue";
import OnlineAppt from "../views/Appointments/OnlineAppt.vue";
import camera from "../views/CovidExams/Camera.vue";
import Referral from "../views/Referral/Home.vue";
import ClinicSettings from "../views/ClinicSettings";
import AddReferral from "../views/Referral/ReferralAdd.vue";
import EditReferral from "../views/Referral/ReferralAdd.vue";
import Contractreport from "../views/Reports/Contract.vue";
import ContractPaymentreport from "../views/Reports/ContractPayment.vue";
import PatientIdentifications from "../views/Reports/PatientIdentifications.vue";
import patientidentification from "../views/Reports/patientidentificationupload.vue";
import Corporatelist from "../views/Corporate/Home.vue";
import CorporateAddEdit from "../views/Corporate/AddEdit.vue";
import ArchivedCorporate from "../views/Corporate/ArchivedCorporate.vue";
import Corporate from "../views/Corporate/Corporate.vue";
import EligibilityResponse from "../views/Reports/EligibilityResponses.vue";
import ClaimReport from "../views/Reports/ClaimReport.vue";
import EOBUpload from "../views/Reports/EOBUpload.vue";
import EOBUploadDetails from "../views/Reports/EOBUploadDetails.vue";
import ERAUpload from "../views/Reports/ERAUpload.vue";
import PatientContactReport from "../views/Reports/PatientContactReport.vue";
import RefundReport from "../views/Reports/RefundReport.vue";
import CollectionReport from "../views/Reports/CollectionReport.vue";
import UnmatchedERA from "../views/Reports/UnmatchedERA.vue";
import RemittanceReport from "../views/Reports/RemittanceReport.vue";
import RemittanceReport1 from "../views/Reports/RemittanceReport1.vue";
import ERADetailReport from "../views/Reports/ERADetailReport.vue";
import AdvanceReport from "../views/Reports/AdvanceReport.vue";
import CustomBillingStatus from '../views/Settings/CustomBillingStatus.vue';
import CustomBillingStatusArchive from '../views/Settings/CustomBillingStatusArchive.vue';
import CRMProspectPatientDetails from "../views/CRM/ProspectPatientDetails.vue";
import CRMPcontacts from "../views/CRM/CRMPcontacts.vue";

import SocketHome from "../views/Socket/Home.vue";
import Videocall from '../views/twilio/Videocall.vue';
import CorporateLifeStyleService from "../views/Corporate/LifeStyleServiceList.vue";
import CorporateLifeStyleServiceAddEdit from "../views/Corporate/LifeStyleServiceForm.vue";
import CorporateLifeStyleServiceCategory from "../views/Corporate/LifeStyleServiceCategoryList.vue";
import CorporateLifeStyleServiceCategoryAddEdit from "../views/Corporate/LifeStyleServiceCategoryForm.vue";

import CouponHome from "../views/Coupon/Home.vue";
import CouponAdd from "../views/Coupon/Add.vue";
import BulkUnsubscribe from "../views/BulkUnsubscribe.vue";

const guest = (to, from, next) => {
  if (!localStorage.getItem("authToken")) {
    return next();
  } else {
    return next("/");
  }
};

const idRequired = (to, from, next) => {
  if (!to.params.id) {
    return next(from.path);
  } else {
    next();
  }
};

const auth = (to, from, next) => {
  if (localStorage.getItem("authToken")) {
    return next();
  } else {
    return next("/login");
  }
};

async function permissioncheck(to, from, next) {
  var id = to.meta.id;
  var optional_id = to.meta.optional_id;
  const permission = await getUserPermission();
  if (permission !== null) {
    if (store.state.auth.is_super_admin == 0) {
      const permissionArr = permission.split(",");
      if (permissionArr.includes(id) || (typeof optional_id !== 'undefined' && permissionArr.includes(optional_id))) {
        return next();
      } else {
        return next("/Unauthorized");
      }
    } else {
      next();
    }
  } else {
    next();
  }
}

function getUserPermission() {
  return new Promise((resolve, reject) => {
    // if (store.state.auth.permission_ids === undefined || store.state.auth.permission_ids == null) {
      // const unwatch = store.state.auth.watch(
      //   () => store.state.auth.permission_ids,
      //   (value) => {
      //     unwatch();
      //     resolve(value);
      //   }
      // );
      recurWatch();
      function recurWatch() {
          if(store.state.auth.permission_ids === undefined || store.state.auth.permission_ids == null) {
              setTimeout(function() {
                recurWatch();
              }, 1000);
          } else {
            resolve(store.state.auth.permission_ids);
          }
      }
    // } else {
      // resolve(store.state.auth.permission_ids);
    // }
  });
}

const routes = [
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: pageNotFound,
  },
  {
    path: "/",
    name: "Home",
    component: Home,
    beforeEnter: auth,
  },
  {
    path: "/google-authentication",
    name: "Googleauthentication",
    component: Googleauthentication,
    beforeEnter: auth,
  },
  {
    path: "/Unauthorized",
    name: "Unauthorized",
    component: pageNotFound,
    //beforeEnter: auth,
  },
  {
    path: "/cds-rules",
    name: "CDSRules",
    beforeEnter: [auth],
    component: CDSRules,
  },
  {
    path: "/crm/contacts",
    name: "CRMPcontacts",
    beforeEnter: [auth, permissioncheck],
    component: CRMPcontacts,
    meta: { id: store.state.permission.PureCRMContacts },
  },
  {
    path: "/crm/contact/:id?/:section?",
    name: "CRMProspectPatientDetails",
    beforeEnter: [auth, idRequired, permissioncheck],
    component: CRMProspectPatientDetails,
    meta: { id: store.state.permission.PureCRMContacts },
  },
  {
    path: "/crm/prospect-patient",
    name: "CRMProspectPatient",
    beforeEnter: [auth, permissioncheck],
    component: CRMProspectPatient,
    meta: { id: store.state.permission.PureCRMView },
  },
  /*{
    path: "/crm/contacts/:id",
    name: "CRMProspectPatientDetails",
    beforeEnter: [auth],
    component: CRMProspectPatientDetails,
  },*/
  
  {
    path: "/crm/transfer-wellness-corodinator",
    name: "WellnessCoordTransfer",
    beforeEnter: [auth, permissioncheck],
    component: WellnessCoordTransfer,
    meta: { id: store.state.permission.TransferWellnessCoordinator },
  },
  {
    path: "/crm/status",
    name: "CRMProspectStatus",
    beforeEnter: [auth, permissioncheck],
    component: CRMProspectStatus,
    meta: { id: store.state.permission.StatusandPipeline },
  },
  {
    path: "/cqm/list",
    name: "CQMList",
    beforeEnter: [auth, permissioncheck],
    component: CQMList,
    meta: { id: store.state.permission.ClinicalQualityMeasures },
  },
  {
    path: "/crm/workflow/add",
    name: "WorkflowAdd",
    beforeEnter: [auth],
    component: WorkflowAddEdit,
  }, 
  {
    path: "/crm/workflow/:id/edit",
    name: "workflowEdit",
    beforeEnter: auth,
    component: WorkflowAddEdit,
  },
  {
    path: "/crm/workflow/list",
    name: "WorkflowList",
    beforeEnter: [auth],
    component: WorkflowList,
  },
  {
    path: "/audit-log",
    name: "AuditLog",
    component: AuditLog,
    beforeEnter: [auth, permissioncheck],
    meta: { id: store.state.permission.AuditLogReport },
  },
  {
    path: "/review-logs",
    name: "AuditTemperLog",
    component: AuditTemperLog,
    beforeEnter: auth,
  },
  {
    path: "/login",
    name: "Login",
    beforeEnter: guest,
    component: Login,
  },
  {
    path: "/forgot",
    name: "Forgot",
    beforeEnter: guest,
    component: Forgot,
  },
  {
    path: "/reset-password/:token",
    name: "Reset",
    beforeEnter: guest,
    component: Reset,
  },
  {
    path: "/patient-portal-activation/:token",
    name: "PatientPortalActivation",
    //beforeEnter: guest,
    component: PatientPortalActivation,
  },
  {
    path: "/patient_portal_representative_activation/:id/:token",
    name: "patient_portal_activation",
    //beforeEnter: guest,
    component: PatientPortalActivation,
  },
  {
    path: "/user-activate/:token",
    name: "UserActivate",
    component: UserActivate,
  },
  {
    path: "/patient/list",
    name: "PatientList",
    beforeEnter: [auth, permissioncheck],
    component: PatientList,
    meta: { id: store.state.permission.patients_list },
  },
  {
    path: "/appointments/status-dashboard",
    name: "AppointmentStatusDashboard",
    beforeEnter: [auth, permissioncheck],
    component: AppointmentStatusDashboard,
    meta: { id: store.state.permission.appointment_dashboard },
  },
  {
    path: "/life-style/appointment-list",
    name: "LifeStyleAppointmentList",
    beforeEnter: [ auth ],
    component: LifeStyleAppointmentList,
  },
  {
    path: "/patient/add",
    name: "PatientAdd",
    beforeEnter: [auth, permissioncheck],
    component: PatientAddEdit,
    meta: { id: store.state.permission.create_update },
  },
  {
    path: "/patient/edit/:id",
    name: "PatientEdit",
    beforeEnter: [auth, permissioncheck],
    component: PatientAddEdit,
    meta: { id: store.state.permission.create_update },
  },
  {
    path: "/patient/edit/demographics/:id",
    name: "PatientEditDemographics",
    beforeEnter: [auth, permissioncheck],
    component: PatientAddEdit,
    meta: { id: store.state.permission.create_update },
  },
  {
    path: "/patient/add/demographics/:id",
    name: "PatientAddDemographics",
    beforeEnter: [auth, permissioncheck],
    component: PatientAddEdit,
    meta: { id: store.state.permission.create_update },
  },

  {
    path: "/patient-chart/:id",
    name: "PatientDetails",
    component: PatientDetails,
    beforeEnter: [auth, idRequired, permissioncheck],
    meta: { id: store.state.permission.Access_Patient_Chart },
    children: [
      {
        path: "demographics",
        name: "PatientDemographics",
        beforeEnter: [auth, idRequired],
        component: PatientDetails,
      },
      {
        path: "locked_clinical_notes",
        name: "PatientLockedClinicalNotes",
        beforeEnter: [auth, idRequired],
        component: PatientDetails,
      },
      {
        path: "signed_consent_forms",
        name: "PatientSignedConsentForms",
        beforeEnter: [auth, idRequired],
        component: PatientDetails,
      },
      {
        path: "lab_results",
        name: "PatientLabesults",
        name: "PatientLabesults",
        beforeEnter: [auth, idRequired],
        component: PatientDetails,
      },
      {
        path: "pureportal-auditLogs",
        name: "PatientAuditLogs",
        beforeEnter: [auth],
        component: PatientDetails,
      },

      {
        path: "clinical-dashboard",
        name: "PatientClinicalDashboard",
        beforeEnter: [auth, idRequired],
        component: PatientDetails,
      },
      {
        path: "appointments",
        name: "PatientDetailsAppointments",
        beforeEnter: [auth, idRequired],
        component: PatientDetails,
      },
      {
        path: "telehealthlog/:room_sid",
        name: "TelehealthLog",
        beforeEnter: [auth, idRequired],
        component: PatientDetails,
      },
      {
        path: "lab-orders",
        name: "PatientDetailsLaborders",
        beforeEnter: [auth, idRequired],
        component: PatientDetails,
      },
      {
        path: "lab-orders/edit/:id2",
        name: "PatientDetailsLabordersEdit",
        beforeEnter: [auth, idRequired],
        component: PatientDetails,
      },
      {
        path: "immunizations",
        name: "PatientDetailsimmunizations",
        beforeEnter: [auth, idRequired],
        component: PatientDetails,
      },
      {
        path: "clinical-dashboard/functional-status",
        name: "PatientDetailsClinicalDashboard",
        beforeEnter: [auth, idRequired],
        component: PatientDetails,
      },

      {
        path: "problem",
        name: "PatientProblemList",
        beforeEnter: [auth, idRequired],
        component: PatientDetails,
      },
      {
        path: "vitals",
        name: "PatientVitals",
        beforeEnter: [auth, idRequired],
        component: PatientDetails,
      },
      {
        path: "cqm",
        name: "CQM",
        beforeEnter: [auth, idRequired],
        component: PatientDetails,
      },
      {
        path: "cds",
        name: "CDS",
        beforeEnter: [auth, idRequired],
        component: PatientDetails,
      },
      {
        path: "implantable-devices",
        name: "PatientImplantableDevice",
        beforeEnter: [auth, idRequired],
        component: PatientDetails,
      },
      {
        path: "smoking-status",
        name: "SmokingStatus",
        component: PatientDetails,
        beforeEnter: [auth, idRequired],
      },
      {
        path: "documents-upload",
        name: "DocumentUpload",
        component: PatientDetails,
        beforeEnter: [auth, idRequired],
      },
      {
        path: "insurance-detail",
        name: "PatientInsurance",
        component: PatientDetails,
        beforeEnter: [auth, idRequired],
      },
      {
        path: "driver-license",
        name: "PatientIdentification",
        component: PatientDetails,
        beforeEnter: [auth, idRequired],
      },
      {
        path: "identification",
        name: "PatientOtherIdentification",
        component: PatientDetails,
        beforeEnter: [auth, idRequired],
      },
      {
        path: "radiology-order",
        name: "RadiologyOrder",
        component: PatientDetails,
        beforeEnter: [auth, idRequired],
      },
      {
        path: "radiology-order/edit/:id2",
        name: "RadiologyOrderEdit",
        component: PatientDetails,
        beforeEnter: [auth, idRequired],
      },
      {
        path: "immunizations",
        name: "Immunizations",
        component: PatientDetails,
        beforeEnter: [auth, idRequired],
      },
      {
        path: "clinical-notes",
        name: "ClinicalNotesList",
        component: PatientDetails,
        beforeEnter: [auth, idRequired],
      },
      {
        path: "wellness-notes/:type/:cat/:note_id?",
        name: "NewNotes",
        component: PatientDetails,
        beforeEnter: [auth, idRequired],
      },
      {
        path: "wellness-notes/All",
        name: "AllNotes",
        component: PatientDetails,
        beforeEnter: [auth, idRequired],
      },
      {
        path: "wellness-notes/refill",
        name: "RefillNotes",
        component: PatientDetails,
        beforeEnter: [auth, idRequired],
      },
      {
        path: "wellness-notes/WcNote",
        name: "WcNotes",
        component: PatientDetails,
        beforeEnter: [auth, idRequired],
      },
      {
        path: "wellness-notes/adminnote",
        name: "GeneralNotes",
        component: PatientDetails,
        beforeEnter: [auth, idRequired],
      },
      {
        path: "wellness-notes/medlist",
        name: "MedNotes",
        component: PatientDetails,
        beforeEnter: [auth, idRequired],
      },
      {
        path: "clinical-note/H&P",
        name: "ClinicalNoteHandP",
        component: PatientDetails,
        beforeEnter: [auth, idRequired],
      },
      {
        path: "clinical-note/amendments",
        name: "PatientAmendments",
        beforeEnter: [auth, idRequired],
        component: PatientDetails,
      },
      {
        path: "clinical-note/amendments/:id2",
        name: "PatientAmendmentsedit",
        beforeEnter: [auth, idRequired],
        component: PatientDetails,
      },
      {
        path: "clinical-note/SOAP",
        name: "ClinicalNoteSoap",
        component: PatientDetails,
        beforeEnter: [auth, idRequired],
      },
      {
        path: "clinical-note/Custom",
        name: "ClinicalNoteAdditional",
        component: PatientDetails,
        beforeEnter: [auth, idRequired],
      },
      {
        path: "clinical-note/preview",
        name: "ClinicalNotePreview",
        component: PatientDetails,
        beforeEnter: [auth, idRequired],
      },
      {
        path: "payment/healthcare",
        name: "HealthCareBilling",
        component: PatientDetails,
        beforeEnter: [auth, idRequired],
      },
      {
        path: "payment/billing",
        name: "MainBilling",
        component: PatientDetails,
        beforeEnter: [auth, idRequired],
      },
      {
        path: "payment/ecommerce",
        name: "ECommerceBilling",
        component: PatientDetails,
        beforeEnter: [auth, idRequired],
      },
      {
        path: "payment/summary",
        name: "BillingSummary",
        component: PatientDetails,
        beforeEnter: [auth, idRequired],
      },
      {
        path: "payment/pos",
        name: "BillingPOS",
        component: PatientDetails,
        beforeEnter: [auth, idRequired],
      },
      {
        path: "payment/contract",
        name: "BillingContract",
        component: PatientDetails,
        beforeEnter: [auth, idRequired],
      },
      {
        path: "payment/contracttmp",
        name: "BillingContractTmp",
        component: PatientDetails,
        beforeEnter: [auth, idRequired],
      },
      {
        path: "payment/wallet",
        name: "Wallet",
        component: PatientDetails,
        beforeEnter: [auth, idRequired],
      },
      {
        path: "payment/credit-card-list",
        name: "CreditCardList",
        component: PatientDetails,
        beforeEnter: [auth, idRequired],
      },
      {
        path: "payment/package-list",
        name: "PackageList",
        component: PatientDetails,
        beforeEnter: [auth, idRequired],
      },
      {
        path: "payment/insurance",
        name: "MainInsuranceBilling",
        component: PatientDetails,
        beforeEnter: [auth, idRequired],
      },
      {
        path: "medication",
        name: "PatientMedications",
        component: PatientDetails,
        beforeEnter: [auth, idRequired],
      },
      {
        path: "allergy",
        name: "PatientAllergies",
        component: PatientDetails,
        beforeEnter: [auth, idRequired],
      },
      {
        path: "insuranceinfo",
        name: "PatientInsuranceInfo",
        component: PatientDetails,
        beforeEnter: [auth, idRequired],
      },
      {
        path: "Questionnarie",
        name: "Questionnarie",
        beforeEnter: [auth, idRequired],
        component: PatientDetails,
      },
      // {
      //   path: "Questionnarie/:id",
      //   name: "Tabview",
      //   component: PatientDetails,
      //   beforeEnter: [auth, idRequired],
      // },
      {
        path: "crm",
        name: "CRM",
        beforeEnter: [auth, idRequired],
        component: PatientDetails,
      },
    ],
  },
  {
    path: "/patient-chart/:id/preview-CCDA/:id2",
    name: "CCDAPreview",
    component: CCDAPreview,
    beforeEnter: [auth, idRequired],
  },
  {
    path: "/patient-chart/:id/schedule-appointment",
    name: "PatientAppointment",
    component: PatientAppointment,
    beforeEnter: [auth, idRequired],
  },
  {
    path: "/patient-chart/:id/edit-appointment/:appointments_id",
    name: "EditAppointment",
    component: PatientAppointment,
    beforeEnter: [auth, idRequired],
  },
  {
    path: "/patient-chart/:id/edit-appointment/:appointments_id/vitals",
    name: "AppointmentVitals",
    component: PatientAppointment,
    beforeEnter: [auth, idRequired],
  },
  {
    path: "/patient-chart/:id/edit-appointment/:appointments_id/log-communication",
    name: "AppointmentLogCommunication",
    component: PatientAppointment,
    beforeEnter: [auth, idRequired],
  },
  {
    path: "/patient-chart/:id/edit-appointment/:appointments_id/insurance-log",
    name: "AppointmentInsuranceLog",
    component: PatientAppointment,
    beforeEnter: [auth, idRequired],
  },
  {
    path: "/patient-chart/:id/edit-appointment/:appointments_id/billing",
    name: "AppointmentBilling",
    component: PatientAppointment,
    beforeEnter: [auth, idRequired],
  },
  {
    path: "/patient-chart/:id/edit-appointment/:appointments_id/check-eligibility",
    name: "AppointmentEligibility",
    component: PatientAppointment,
    beforeEnter: [auth, idRequired],
  },
  {
    path: "/patient-chart/:id/edit-appointment/:appointments_id/Auditlogs",
    name: "Auditlogs",
    component: PatientAppointment,
    beforeEnter: [auth, idRequired],
  },
  {
    path: "/provider/list",
    name: "ProviderList",
    beforeEnter: [auth, permissioncheck],
    component: ProviderList,
    meta: { id: store.state.permission.Settings },
  },
  {
    path: "/provider/add",
    name: "ProviderAdd",
    beforeEnter: [auth, permissioncheck],
    component: ProviderAddEdit,
    meta: { id: store.state.permission.Settings },
  },
  {
    path: "/leave/list",
    name: "Leave",
    beforeEnter: [auth, permissioncheck],
    component: LeaveList,
    meta: { id: store.state.permission.leave_management },
  },
  {
    path: "/vitals/list",
    name: "Vitals",
    beforeEnter: [auth, permissioncheck],
    component: VitalsList,
    meta: { id: store.state.permission.vital_management },
  },
  {
    path: "/vital/add",
    name: "Vitaladd",
    beforeEnter: [auth, permissioncheck],
    component: VitalsAdd,
    meta: { id: store.state.permission.vital_management },
  },
  {
    path: "/subscription-plans/list",
    name: "SubscriptionPlanList",
    beforeEnter: [auth, permissioncheck],
    component: SubscriptionPlanList,
    meta: { id: store.state.permission.SubscriptionManagement },
  },
  {
    path: "/leave/archived",
    name: "ArchivedLeave",
    beforeEnter: [auth],
    component: ArchivedLeave,
  },
  {
    path: "/provider/archived",
    name: "ArchivedProvider",
    beforeEnter: [auth],
    component: ArchivedProvider,
  },
  {
    path: "/profile/personal-info",
    name: "ProfileSetting",
    beforeEnter: [auth],
    component: generalSetting,
  },
  {
    path: "/profile/setting",
    name: "GeneralSetting",
    beforeEnter: [auth],
    component: generalSetting,
  },

  {
    path: "/permission",
    name: "Permissions",
    component: Permissions,
    beforeEnter: [auth, permissioncheck],
    meta: { id: store.state.permission.access_management },
    children: [
      {
        path: "roles",
        name: "Permissionsroles",
        component: Permissions,
      },
      {
        path: "Providers",
        name: "PermissionsProviders",
        component: Permissions,
        beforeEnter: [permissioncheck],
        meta: { id: store.state.permission.Provider_Permission },
      },
      {
        path: "staff",
        name: "Permissionsstaff",
        component: Permissions,
        beforeEnter: [permissioncheck],
        meta: { id: store.state.permission.Staff_Permission },
      },
      {
        path: "emergency-access",
        name: "Permissionsemergency",
        component: Permissions,
      },
    ],
  },

  {
    path: "/provider/edit/:id",
    name: "ProviderEdit",
    beforeEnter: [auth, permissioncheck],
    component: ProviderAddEdit,
    meta: { id: store.state.permission.Settings, optional_id: store.state.permission.provider_staff_account},
  },
  {
    path: "/provider/appointment-swap/:id",
    name: "ProviderAppointmentSwap",
    beforeEnter: [auth, permissioncheck],
    component: ProviderAppointmentSwap,
    meta: { id: store.state.permission.Settings },
  },
  {
    path: "/provider/edit/billing/:id",
    name: "ProviderBilling",
    component: ProviderAddEdit,
    beforeEnter: [auth, permissioncheck],
    meta: { id: store.state.permission.Settings, optional_id: store.state.permission.provider_staff_account},
  },
  {
    path: "/provider/edit/scheduling/:id",
    name: "ProviderEditScheduling",
    component: ProviderAddEdit,
    beforeEnter: [auth, permissioncheck],
    meta: { id: store.state.permission.Settings, optional_id: store.state.permission.provider_staff_account},
  },
  {
    path: "/provider/edit/permission/:id",
    name: "ProviderAccessPermission",
    component: ProviderAddEdit,
    beforeEnter: [auth, permissioncheck],
    meta: { id: store.state.permission.Settings, optional_id: store.state.permission.provider_staff_account},
  },
  {
    path: "/provider/setting/:id",
    name: "ProviderSetting",
    component: ProviderAddEdit,
    beforeEnter: [auth, permissioncheck],
    meta: { id: store.state.permission.Settings },
  },
  {
    path: "/office/list",
    name: "OfficeList",
    component: OfficeList,
    beforeEnter: [auth, permissioncheck],
    meta: { id: store.state.permission.Manage_Offices_Resources },
  },
  {
    path: "/office/archived",
    name: "OfficeArchived",
    component: OfficeArchived,
    beforeEnter: [auth, permissioncheck],
    meta: { id: store.state.permission.Manage_Offices_Resources },
  },
  
  {
    path: "/office/:id/billing",
    name: "OfficeBilling",
    component: OfficeAddEdit,
    beforeEnter: [auth, permissioncheck],
    meta: { id: store.state.permission.Manage_Offices_Resources },
  },
  {
    path: "/office/add",
    name: "OfficeAdd",
    beforeEnter: [auth, permissioncheck],
    component: OfficeAddEdit,
    meta: { id: store.state.permission.Manage_Offices_Resources },
  },
  {
    path: "/office/edit/:id",
    name: "OfficeEdit",
    component: OfficeAddEdit,
    beforeEnter: [auth, idRequired, permissioncheck],
    meta: { id: store.state.permission.Manage_Offices_Resources },
  },
  {
    path: "/office/:id/room",
    name: "RoomsAdd",
    component: OfficeAddEdit,
    beforeEnter: [auth, idRequired, permissioncheck],
    meta: { id: store.state.permission.Manage_Offices_Resources },
  },
  {
    path: "/office/:id/room/archived",
    name: "RoomsArchived",
    component: OfficeAddEdit,
    beforeEnter: [auth, idRequired],
  },
  {
    path: "/office/:id/rooms/resources",
    name: "ResourcesAdd",
    component: OfficeAddEdit,
    beforeEnter: [auth, idRequired, permissioncheck],
    meta: { id: store.state.permission.Manage_Offices_Resources },
  },
  {
    path: "/office/:id/schedule",
    name: "OfficeSchedule",
    component: OfficeAddEdit,
    beforeEnter: [auth, idRequired, permissioncheck],
    meta: { id: store.state.permission.Manage_Offices_Resources },
  },
  {
    path: "/office/:id/labs",
    name: "LabSetting",
    component: OfficeAddEdit,
    beforeEnter: [auth, idRequired, permissioncheck],
    meta: { id: store.state.permission.Manage_Offices_Resources },
  },
  {
    path: "/office/:id/provider",
    name: "OfficeProviderSchedule",
    component: OfficeAddEdit,
    beforeEnter: [auth, idRequired, permissioncheck],
    meta: { id: store.state.permission.Manage_Offices_Resources },
  },
  {
    path: "/clinical/notes",
    name: "ClinicalNotes",
    component: ClinicalNotes,
    beforeEnter: [auth, permissioncheck],
    meta: { id: store.state.permission.SignBulkClinicalNotes },
  },
  {
    path: "/covid/examination-report",
    name: "CovidExaminationReport",
    component: CovidExaminationReport,
    beforeEnter: [auth, permissioncheck],
    meta: { id: store.state.permission.CovidExamination },
  },
  {
    path: "/clinical/notes/settings",
    name: "ManageClinicalNotes",
    beforeEnter: [auth, permissioncheck],
    component: ManageClinicalNotes,
    meta: { id: store.state.permission.ClinicalNote },
  },
  {
    path: "/clinical/macros",
    name: "ManageMacros",
    beforeEnter: [auth, permissioncheck],
    component: ManageMacros,
    meta: { id: store.state.permission.MacroSettings },
  },
  {
    path: "/billing-profile-list",
    name: "BillingProfileList",
    component: BillingProfileList,
    beforeEnter: [auth, permissioncheck],
    meta: { id: store.state.permission.billing_profile },
  },
  {
    path: "/billing-profile-list/archived",
    name: "BillingProfileArchivedList",
    component: BillingProfileArchivedList,
    beforeEnter: [auth, permissioncheck],
    meta: { id: store.state.permission.billing_profile },
  },
  {
    path: "/billing-profile/add",
    name: "BillingProfileAdd",
    component: BillingProfileForm,
    beforeEnter: [auth, permissioncheck],
    meta: { id: store.state.permission.billing_profile },
  },
  {
    path: "/billing-profile/edit/:id",
    name: "BillingProfileEdit",
    component: BillingProfileForm,
    beforeEnter: [auth, permissioncheck],
    meta: { id: store.state.permission.billing_profile },
  },

  {
    path: '/about',
    name: 'About',
    component:AboutPureEHR,
    beforeEnter: [auth],
  },
  {
    path: "/visit-profile",
    name: "AppointmentProfilesList",
    beforeEnter: [auth, permissioncheck],
    component: AppointmentProfilesList,
    meta: { id: store.state.permission.visit_profile },
  },
  {
    path: "/visit-profile/add",
    name: "AppointmentProfilesAdd",
    beforeEnter: [auth, permissioncheck],
    component: AppointmentProfilesAdd,
    meta: { id: store.state.permission.visit_profile },
  },
  {
    path: "/visit-profile/edit/:id",
    name: "AppointmentProfilesEdit",
    beforeEnter: [auth, permissioncheck],
    component: AppointmentProfilesAdd,
    meta: { id: store.state.permission.visit_profile },
  },
  {
    path: "/visit-profile/archived",
    name: "AppointmentProfilesArchivedList",
    beforeEnter: [auth, permissioncheck],
    component: AppointmentProfilesArchivedList,
    meta: { id: store.state.permission.visit_profile },
  },
  {
    path: "/staff/list",
    name: "StaffList",
    beforeEnter: [auth, permissioncheck],
    component: StaffList,
    meta: { id: store.state.permission.Manage_Accounts },
  },
  {
    path: "/staff/archived",
    name: "StaffArchivedList",
    beforeEnter: [auth, permissioncheck],
    component: StaffArchivedList,
    meta: { id: store.state.permission.Manage_Accounts },
  },
  {
    path: "/staff/add",
    name: "StaffAdd",
    beforeEnter: [auth, permissioncheck],
    component: StaffAddEdit,
    meta: { id: store.state.permission.Manage_Accounts },
  },
  {
    path: "/staff/edit/:id",
    name: "Staffedit",
    beforeEnter: [auth, permissioncheck],
    component: StaffAddEdit,
    meta: { id: store.state.permission.Manage_Accounts, optional_id: store.state.permission.provider_staff_account },
  },
  {
    path: "/staff/edit/scheduling/:id",
    name: "StaffEditScheduling",
    component: StaffAddEdit,
    beforeEnter: [auth, permissioncheck],
    meta: { id: store.state.permission.Manage_Accounts , optional_id: store.state.permission.provider_staff_account},
  },
  {
    path: "/staff/edit/permission/:id",
    name: "StaffAccessPermission",
    component: StaffAddEdit,
    beforeEnter: [auth, permissioncheck],
    meta: { id: store.state.permission.Manage_Accounts, optional_id: store.state.permission.provider_staff_account},
  },
  {
    path: "/consent-forms",
    name: "ConsentFormslist",
    beforeEnter: [auth, permissioncheck],
    component: ConsentForms,
    meta: { id: store.state.permission.consent_forms },
  },
  {
    path: "/consent-forms/archived",
    name: "ConsentFormsarchived",
    beforeEnter: auth,
    component: ConsentFormsarchived,
  },
  {
    path: "/appointments/calendar",
    name: "CalenderView",
    beforeEnter: [auth, permissioncheck],
    component: Calender,
    meta: { id: store.state.permission.Calendar },
  },
  {
    path: "/appointment-status",
    name: "AppointmentStatus",
    beforeEnter: [auth, permissioncheck],
    component: AppointmentStatus,
    meta: { id: store.state.permission.appointment_status },
  },
  {
    path: "/custom-procedures",
    name: "customproceduresList",
    beforeEnter: [auth, permissioncheck],
    component: CustomProcedures,
    meta: { id: store.state.permission.custom_procedures },
  },
  {
    path: "/custom-procedures/add",
    name: "customproceduresadd",
    beforeEnter: [auth, permissioncheck],
    component: CustomProceduresaddedit,
    meta: { id: store.state.permission.custom_procedures },
  },
  {
    path: "/custom-procedures/edit/:id",
    name: "customproceduresedit",
    beforeEnter: [auth, permissioncheck],
    component: CustomProceduresaddedit,
    meta: { id: store.state.permission.custom_procedures },
  },
  {
    path: "/custom-procedures/archived",
    name: "CustomProceduresarchived",
    beforeEnter: [auth, permissioncheck],
    component: CustomProceduresarchived,
    meta: { id: store.state.permission.custom_procedures },
  },
  {
    path: "/availability-search",
    name: "AvailabilitySearch",
    beforeEnter: [auth, permissioncheck],
    component: AvailabilitySearch,
    meta: { id: store.state.permission.availability_search },
  },
  {
    path: "/bulk-appointments",
    name: "BulkAppointments",
    beforeEnter: [auth, permissioncheck],
    component: BulkAppointments,
    meta: { id: store.state.permission.bulk_appointments },
  },
  {
    path: "/pre-appointments",
    name: "PreAppointment",
    beforeEnter: [auth, permissioncheck],
    component: PreAppointment,
    meta: { id: store.state.permission.pre_appointments },
  },
  {
    path: "/notification",
    name: "getnotification",
    beforeEnter: auth,
    component: Notification,
  },
  {
    path: "/CCDApreferences",
    name: "CCDApreferences",
    beforeEnter: [auth, permissioncheck],
    component: CCDApreferences,
    meta: { id: store.state.permission.ccda_preferences },
  },
  {
    path: "/phi-mail-box",
    name: "PHIMailBox",
    beforeEnter: [auth, permissioncheck],
    component: PHIMailBox,
    meta: { id: store.state.permission.PHIMailBox },
  },
  // {
  //   path: "/meaningful-automated-report",
  //   name: "MeaningfulAutomatedReport",
  //   beforeEnter: [auth, permissioncheck],
  //   component: MeaningfulAutomatedReport,
  //   meta: { id: store.state.permission.AutomatedMeasuresCalculationReport },
  // },
  {
    path: "/recover-appointments",
    name: "RecoverAppointments",
    beforeEnter: [auth, permissioncheck],
    component: RecoverAppointments,
    meta: { id: store.state.permission.deleted_appointments },
  },
  {
    path: "/rectangle-authorization",
    name: "PaymentGatewayMigration",
    beforeEnter: [auth, permissioncheck],
    component: PaymentGatewayMigration,
    meta: { id: store.state.permission.rectangleHealth },
  },
  {
    path: "/cc-authorized/:token",
    name: "PaymentAuthentication",
    component: PaymentAuthentication,
  },
  {
    path: "/patientDeposit/:token",
    name: "PatientWallet",
    component: PatientWallet,
  },
  {
    path: "/lifestyle-pay-authorized/:token",
    name: "LifeStyleServicePaymentAuthorization",
    component: LifeStyleServicePaymentAuthorization,
  },
  {
    path: "/lifestyle-send-consent/:token",
    name: "LifeStyleConsentFormSign",
    component: LifeStyleConsentFormSign,
  },
  {
    path: "/hippa-sign/:token",
    name: "LifeStyleHippaFormSign",
    component: LifeStyleHippaFormSign,
  },
  {
    path: "/contract-aggrement/:token",
    name: "ContractAggrement",
    component: ContractAggrement,
  },
  {
    path: "/appointment-request/:token",
    name: "AppointmentRequest",
    component: AppointmentRequest,
  },
  {
    path: "/crm_unsubscribe/:chart_id/:token",
    name: "CRMUnsubscribe",
    component: CRMUnsubscribe,
  },
  {
    path: "/bulk_unsubscribe/:chart_id/:token",
    name: "BulkUnsubscribe",
    component: BulkUnsubscribe,
  },
  {
    path: "/support/create",
    name: "HelpSupport",
    beforeEnter: [auth, permissioncheck],
    component: HelpSupport,
    meta: { id: store.state.permission.Support },
  },
  {
    path: "/db-logs",
    name: "DB Logs",
    beforeEnter: auth,
    component: DBLogs,
  },
  {
    path: "/support",
    name: "HelpIndex",
    beforeEnter: [auth, permissioncheck],
    component: HelpIndex,
    meta: { id: store.state.permission.Support },
  },
  {
    path: "/support/closeticket",
    name: "CloseTicket",
    beforeEnter: [auth, permissioncheck],
    component: HelpIndex,
    meta: { id: store.state.permission.Support },
  },
  {
    path: "/support/openticket",
    name: "OpenTicket",
    beforeEnter: [auth, permissioncheck],
    component: HelpIndex,
    meta: { id: store.state.permission.Support },
  },
  {
    path: "/support/development/report",
    name: "HelpDevelopmentReport",
    beforeEnter: [auth, permissioncheck],
    component: HelpDevelopmentReport,
    meta: { id: store.state.permission.Support },
  },
  {
    path: "/support/:master_id/threads",
    name: "SupportThread",
    beforeEnter: auth,
    component: SupportThread,
  },
  {
    path: "/card-authorization-of-prospectus",
    name: "Cardauthorizationofprospectus",
    beforeEnter: [auth, permissioncheck],
    component: Cardauthorizationofprospectus,
    meta: { id: store.state.permission.card_authorization },
  },
  {
    path: "/email-template",
    name: "EmailTemplate",
    beforeEnter: [auth,permissioncheck],
    component: EmailTemplate,
    meta: { id: store.state.permission.email_template_setting },
  },
  {
    path: "/wallet-setting",
    name: "WalletSetting",
    beforeEnter: [auth,permissioncheck],
    component: WalletSetting,
    meta: { id: store.state.permission.wallet_amount },
  },
  {
    path: "/third-party-config",
    name: "ThirdPartyConfig",
    beforeEnter: auth,
    component: ThirdPartyConfig,
  },
  {
    path: "/email-template/add",
    name: "AddEmailTemplate",
    beforeEnter: [auth,permissioncheck],
    component: AddEmailTemplate,
    meta: { id: store.state.permission.email_template_setting },
  },
  {
    path: "/email-template/edit/:id",
    name: "EditEmailTemplate",
    beforeEnter: [auth,permissioncheck],
    component: EditEmailTemplate,
    meta: { id: store.state.permission.email_template_setting },
  },
  {
    path: "/sms-template",
    name: "SmsTemplate",
    beforeEnter: [auth,permissioncheck],
    component: SmsTemplate,
    meta: { id: store.state.permission.sms_template_setting },
  },
  {
    path: "/sms-template/add",
    name: "AddSmsTemplate",
    beforeEnter: [auth,permissioncheck],
    component: AddSmsTemplate,
    meta: { id: store.state.permission.sms_template_setting },
  },
  {
    path: "/sms-template/edit/:id",
    name: "EditSmsTemplate",
    beforeEnter: [auth,permissioncheck],
    component: EditSmsTemplate,
    meta: { id: store.state.permission.sms_template_setting },
  },
  {
    path: "/recurring-appointments",
    name: "RecurringAppointments",
    beforeEnter: [auth, permissioncheck],
    component: RecurringAppointments,
    meta: { id: store.state.permission.recurring_appointments },
  },
  {
    path: "/reminder-profiles",
    name: "ReminderProfiles",
    beforeEnter: [auth, permissioncheck],
    component: ReminderProfiles,
    meta: { id: store.state.permission.reminder_profiles },
  },
  {
    path: "/referral",
    name: "Referral",
    beforeEnter: [auth, permissioncheck],
    component: Referral,
    meta: { id: store.state.permission.referral_management },
  },
  {
    path: "/clinic_settings",
    name: "ClinicSettings",
    beforeEnter: [auth, permissioncheck],
    component: ClinicSettings,
    meta: { id: store.state.permission.ClinicSettings },
  },
  {
    path: "/referral/add",
    name: "AddReferral",
    beforeEnter: [auth, permissioncheck],
    component: AddReferral,
    meta: { id: store.state.permission.referral_management },
  },
  {
    path: "/referral/edit/:id",
    name: "EditReferral",
    beforeEnter: [auth, permissioncheck],
    component: EditReferral,
    meta: { id: store.state.permission.referral_management },
  },
  {
    path: "/covid-exams",
    name: "Covidexams",
    beforeEnter: [auth, permissioncheck],
    component: Covidexams,
    meta: { id: store.state.permission.CovidExams },
  },
  {
    path: "/covid-registration/:clinic_id/:office_id",
    name: "CovidRegistration",
    component: CovidRegistration,
  },
  {
    path: "/tele-health-registration/:clinic_id/:office_id",
    name: "TeleHealthCovidRegistration",
    component: TeleHealthCovidRegistration,
  },
  {
    path: "/online-appt/:token",
    name: "OnlineAppt",
    component: OnlineAppt,
  },
  {
    path: "/camera",
    name: "camera",
    component: camera,
  },
  {
    path: "/contract",
    name: "Contractreport",
    beforeEnter: [auth, permissioncheck],
    component: Contractreport,
    meta: { id: store.state.permission.contract },
  },
  {
    path: "/contract-payment",
    name: "ContractPaymentreport",
    beforeEnter: [auth, permissioncheck],
    component: ContractPaymentreport,
    meta: { id: store.state.permission.contract },
  },
  {
    path: "/patient-identification",
    name: "PatientIdentifications",
    beforeEnter: [auth, permissioncheck],
    component: PatientIdentifications,
    meta: { id: store.state.permission.PatientIdentification },
  },

  {
    path: "/patient-identification/:token",
    name: "patientidentification",
    component: patientidentification,
  },
  {
    path: "/corporate/list",
    name: "Corporatelist",
    beforeEnter: [auth, permissioncheck],
    component: Corporatelist,
    meta: { id: store.state.permission.CorporateList },
  },

  {
    path: "/corporate/add",
    name: "Corporateadd",
    beforeEnter: [auth, permissioncheck],
    component: CorporateAddEdit,
    meta: { id: store.state.permission.CorporateList },
  },

  {
    path: "/corporate/:id/edit",
    name: "Corporateedit",
    beforeEnter: [auth, permissioncheck],
    component: CorporateAddEdit,
    meta: { id: store.state.permission.CorporateList },
  },
  {
    path: "/corporate/archived",
    name: "ArchivedCorporate",
    beforeEnter: [auth, permissioncheck],
    component: ArchivedCorporate,
    meta: { id: store.state.permission.CorporateList },
  },
  {
    path: "/collection-report",
    name: "CollectionReport",
    beforeEnter: [auth, permissioncheck],
    component: CollectionReport,
    meta: { id: store.state.permission.Collection },
  },
  
  {
    path: "/corporate/event-calendar",
    name: "Corporate",
    beforeEnter: [auth, permissioncheck],
    component: Corporate,
    meta: { id: store.state.permission.EventCalendar },
  },
  {
    path: "/life-style-process",
    name: "LifeStyleProcess",
    beforeEnter: auth,
    component: LifeStyleProcess,
  },   
  {
    path: "/corporate/life-style-service",
    name: "CorporateLifeStyleService",
    beforeEnter: auth,
    component: CorporateLifeStyleService,
  },
  {
    path: "/corporate/life-style-service/add",
    name: "CorporateLifeStyleServiceAdd",
    beforeEnter: auth,
    component: CorporateLifeStyleServiceAddEdit,
  },
  {
    path: "/corporate/life-style-service/edit/:id",
    name: "CorporateLifeStyleServiceEdit",
    beforeEnter: auth,
    component: CorporateLifeStyleServiceAddEdit,
  },
  {
    path: "/corporate/life-style-service-category",
    name: "CorporateLifeStyleServiceCategory",
    beforeEnter: auth,
    component: CorporateLifeStyleServiceCategory,
  },
  {
    path: "/corporate/life-style-service-category/add",
    name: "CorporateLifeStyleServiceCategoryAdd",
    beforeEnter: auth,
    component: CorporateLifeStyleServiceCategoryAddEdit,
  },
  {
    path: "/corporate/life-style-service-category/edit/:id",
    name: "CorporateLifeStyleServiceCategoryEdit",
    beforeEnter: auth,
    component: CorporateLifeStyleServiceCategoryAddEdit,
  },
  
  
  {
    path: "/patient-contact-report",
    name: "PatientContactReport",
    beforeEnter: auth,
    component: PatientContactReport,
  },
  {
    path: "/refund-report",
    name: "RefundReport",
    beforeEnter: auth,
    component: RefundReport,
  },
  {
    path: "/insurance-claim-report",
    name: "ClaimReport",
    beforeEnter: auth,
    component: ClaimReport,
  },
  {
    path: "/insurance-eob-upload",
    name: "EOBUpload",
    beforeEnter: auth,
    component: EOBUpload,
  },
  {
    path: "/insurance-eob-upload/:id",
    name: "EOBUploadDetails",
    beforeEnter: auth,
    component: EOBUploadDetails,
  },
  {
    path: "/insurance-era-upload",
    name: "ERAUpload",
    beforeEnter: auth,
    component: ERAUpload,
  },
  {
    path: "/eligibility-test",
    name: "EligibilityResponse",
    beforeEnter: auth,
    component: EligibilityResponse,
  },
  {
    path: "/unmatched-era",
    name: "UnmatchedERA",
    beforeEnter: auth,
    component: UnmatchedERA,
  },
  {
    path: "/socket-home",
    name: "SocketHome",
    component: SocketHome,
  },
  
  
  {
    path: "/remittance-report",
    name: "RemittanceReport",
    beforeEnter: auth,
    component: RemittanceReport,
  },
  {
    path: "/remittance-report1",
    name: "RemittanceReport1",
    beforeEnter: auth,
    component: RemittanceReport1,
  },
  {
    path: "/era_list/:id",
    name: "ERADetailReport",
    beforeEnter: auth,
    component: ERADetailReport,
  },
  {
    path: "/advance-report",
    name: "AdvanceReport",
    beforeEnter: auth,
    component: AdvanceReport,
  },
  {
    path: "/custom/billing-status",
    name: "CustomBillingStatus",
    beforeEnter: [auth, permissioncheck],
    component: CustomBillingStatus,
    meta: { id: store.state.permission.custom_billing_status },
  },
  {
    path: "/custom/billing-status/archived",
    name: "CustomBillingStatusArchive",
    beforeEnter: auth,
    component: CustomBillingStatusArchive,
  },
  {
    path: '/telehealth/:room?/:date/:time/:endtime',
    name: 'VideoCall',
    component: Videocall
  },
  {
    path: "/download-logs",
    name: "DownloadLogs",
    beforeEnter: [auth, permissioncheck],
    component: DownloadLogs,
    meta: { id: store.state.permission.DownloadLogs },
  },
  {
    path: '/telehealth/:room?/:date/:time/:endtime',
    name: 'VideoCall',
    component: Videocall
  },
  {
    path: '/coupon/list',
    name: 'CouponHome',
    beforeEnter: [auth, permissioncheck],
    component: CouponHome,
    meta: { id: store.state.permission.coupon_management },
  },
  {
    path: '/coupon/add',
    name: 'CouponAdd',
    beforeEnter: [auth, permissioncheck],
    component: CouponAdd,
    meta: { id: store.state.permission.coupon_management },
  },
  {
    path: "/coupon/edit/:id",
    name: "CouponEdit",
    beforeEnter: [auth, permissioncheck],
    component: CouponAdd,
    meta: { id: store.state.permission.coupon_management },
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
