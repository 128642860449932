<template>
    <div id="appointmentSection">
        <div class="row justify-content-sm-center m-0 codeComponent">        
            <div class="col-12 col-sm-8 col-md-5 ">
                <Icd10-code @clicked="save" :isSelected="false"></Icd10-code>
            </div>
        </div>
        <div class="table-responsive">
            <table-base v-if='this.render' :dataTypes="{dataType: 'clinicalNote', appointment_id: appointment_id, patient_id: patient_id}" :key="tabRender">
               <template v-slot="slotProps">
                    <td>{{slotProps.item.icd10_code}}</td>
                    <td>{{slotProps.item.description}}</td>
               </template>
            </table-base>
        </div>
    </div>
</template>
<script>

import TableBase from "@/components/base/Table.vue";
import Icd10Code from "@/components/base/Icd10Code.vue";
import axios from "@/scripts/axios.js";

export default {
    components: { TableBase, Icd10Code },
    props: {
        dataId: Number,
    },
    data() {
        return {
            render:true,
            appointment_id: this.dataId,
            tabRender: 1,
            patient_id: this.$store.state.patientDetail.patient_id,
            form:{
                icd10_id: '',
                icd10_code: '',
                description: '',
                appointment_id: ''
            }
        };
    },
    methods:{
        save(object)
        {
            this.form.description = object.description;
            this.form.icd10_code = object.code;
            this.form.icd10_id = object.id;
            this.form.appointment_id = this.appointment_id;
            this.form.patient_id = this.$store.state.patientDetail.patient_id;
            this.render = false;
            var response = axios
                      .post('/patient-appointment-icdCodes/save',this.form).
                        then(response => { 
                            this.render = true;
                        });
        }
    }  
}
</script>