<template>
    <div class="content-wrapper">
		<div class="container-full">
			<div class="content-header">
                <div class="container">
                    <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xs-6">
                        <h1 class="heading1 my-5">Wallet Amount Settings</h1>
                    </div>
                    <form novalidate>
                        <input-field
                        title="Please set the default amount to add wallet balance"
                        v-model="this.form.amount"
                        :label-class="col-12"
                        :div-class="col-12"
                        @keypress="isNumber($event)"
                        ></input-field>
                        <div
                            class="invalid-feedback"
                            style="display:block;"
                            v-for="(error, index) of v$.form.amount.$errors"
                            :key="index"
                            >
                            {{ error.$message }}
                        </div>
                        
                       
                        <div class="col-12 col-sm-12 col-md-12 col-lg-11 col-xl-9 text-center">
                            <button type="button" class="waves-effect waves-light btn btn-info me-1"  @click="save">Save <img v-if="loader" src="images/loader.gif"></button>
                            <router-link
                            :to="{ name: 'Home' }"
                            class="waves-effect waves-light btn me-1 btn-danger"
                            >Close</router-link
                            >
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import InputField from "@/components/base/formFields/InputField.vue";
  import axios from "@/scripts/axios.js";
  import moment from 'moment';
  import useVuelidate from "@vuelidate/core";
  import FormBtn from "@/components/base/formFields/formBtn.vue";
  import permissionMixins from "@/scripts/mixins/permission.js";
  import submitMixins from "@/scripts/mixins/submitMixins.js";
  import {
    required,
    helpers,
  } from "@vuelidate/validators";
  export default {
    mixins: [submitMixins, permissionMixins],
    name: "Wallet Setting",
    setup() {
      return { v$: useVuelidate() };
    },
    components: {InputField,axios,moment,FormBtn},
    data() {
      return {
        loader:false,
        form: {
          amount: "",
        },
        errorMessage: "",
      };
    },
    validations() {
      return {
        form: {
            amount: {
                required: helpers.withMessage("Amount is required.", required),
            },
        },
      };
    },
    computed: {
     
     
    },
    created() {},
    mounted() {   
        axios.get("walletSetting").then(response => { 
            if(response.data.data.default_wallet_amount=="0.00"){
               
            }else{
                this.form.amount=response.data.data.default_wallet_amount;
            }
               
                });
     },
    methods: {
        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();;
            } else {
                return true;
            }
        },
        save(){
            this.v$.$validate();
            if (!this.v$.$error) {
                this.loader=true;
                this.errorMessage = '';
                axios.post("clinic/walletSetting",{
                    'wallet_amount': this.form.amount,
                }).then(response => { 
                
                if(response.status==200){
                    this.loader=false;
                    this.moshaToast("Record save successfully", "success");
                    
                }
                
                });
            }
        },
    },
  };
  </script>

  