import {
  helpers,
  required,
  email,
  minLength,
  maxLength,
  sameAs,
  numeric,
} from "@vuelidate/validators";

const clinicSettings = {
  namespaced: true,

  state: {
    form: {},
    validationRules: {
      form: {
        clinicName: {
            required: helpers.withMessage('Clinic Name is required', required)
        },
        clinicCellNumber: {
            required: helpers.withMessage('Clinic Cell Number is required', required),
        },
        clinicEmail: {
            required: helpers.withMessage('Clinic Email is required', required),
            email: helpers.withMessage("Email address is not valid", email),
        },
        clinicAddress: {
            required: helpers.withMessage('Clinic Address is required', required)
        },
        clinicTaxId: {
            required: helpers.withMessage('Clinic Tax Id is required', required)
        },
        clinicLogo: {}
      },
    },
  },
}

export default clinicSettings;