<template>
  <div class="content-wrapper">
    <div class="container-full">
      <div class="content-header">
        <div class="row justify-content-between">
          <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xs-6">
            <h1 class="heading1 my-5">Clinic Settings</h1>
          </div>
        </div>
      </div>
      <section class="content">
        <div class="box">
          <div class="box-body">
            <div id="clinicSettings">
              <form>
                <input-field title="Clinic Name" v-model="v$.form.clinicName.$model" :errors="v$.form.clinicName.$errors"></input-field>
                <text-area title="Clinic Address" v-model="v$.form.clinicAddress.$model" :errors="v$.form.clinicAddress.$errors"></text-area>
                <input-field title="Clinic Cell Number" v-model="v$.form.clinicCellNumber.$model" :errors="v$.form.clinicCellNumber.$errors" mask="(000) 000-0000"></input-field>
                <input-field title="Email Id" v-model="v$.form.clinicEmail.$model" :errors="v$.form.clinicEmail.$errors"></input-field>
                <input-field title="Clinic Tax Id" v-model="v$.form.clinicTaxId.$model" :errors="v$.form.clinicTaxId.$errors"></input-field>
                <file-upload id="clinic_logo" title="Clinic Logo" v-model="v$.form.clinicLogo.$model" @change="fileValidation($event)" note="Only .jpg, .png formats are supported" noteClass="col-12 col-sm-8 col-md-8 offset-sm-4 offset-lg-0 col-lg-4 col-xl-5">
                </file-upload>
                <div v-if="previewImageURL != ''" class="form-group row" id="">
                  <label class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end">Clinic Logo Preview</label>
                  <img :src="previewImageURL" class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3 img-preview">
                </div>
                <div class="form-group row">
                  <label for="" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 text-end">Email Signature</label>
                  <ejs-richtexteditor
                    :pasteCleanupSettings="pasteCleanupSettings"
                    :toolbarSettings="toolbarSettings"
                    ref="editorObj"
                    title="Email Signature"
                    rows="10">
                  </ejs-richtexteditor>
                </div>
              </form>
              <div class="row mb-10">
                <div class="col-12 col-sm-12 col-md-12 col-lg-11 col-xl-9 text-center">
                  <form-btn
                    title="Save"
                    btnCls="btn-info saveData"
                    @click="save()"
                  ></form-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import axios from "@/scripts/axios.js";
import FileUpload from "@/components/base/formFields/fileUpload.vue";
import InputField from "@/components/base/formFields/InputField.vue";
import textArea from "@/components/base/formFields/textArea.vue";
import FormBtn from "@/components/base/formFields/formBtn.vue";
import Popper from "vue3-popper";
import $ from 'jquery';
import submitMixins from "@/scripts/mixins/submitMixins.js";
import useVuelidate from "@vuelidate/core";

export default {
  name: "Referral",
  components: { Popper, InputField,FileUpload,textArea, FormBtn },
  mixins: [submitMixins],  
  setup: () => {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      test: 'test',
      toolbarSettings: {
        type: 'MultiRow',
        items: ['Bold', 'Italic', 'Underline', 'StrikeThrough','FontName', 'FontSize','Formats', 'Alignments', 'OrderedList', 'UnorderedList','Outdent', 'Indent','CreateLink', 'Image', 'ClearFormat','Undo', 'Redo']
      },
      pasteCleanupSettings: {
        prompt: true,
        plainText: false,
        keepFormat: false,
        deniedTags: ['a'],
        deniedAttrs: ['class', 'title', 'id'],
        allowedStyleProps: ['color', 'margin', 'font-size']
      },
      previewImageURL: '',
    }
  },
  computed: {
    form() {
      return this.$store.state.clinicSettings.form;
    },
    validationRules() {
      return this.$store.state.clinicSettings.validationRules;
    },
  },
  mounted(){
  },
  methods:{
    save(){
      this.$store.state.loader = true;
      this.form.emailSignature = this.$refs.editorObj.ej2Instances.value;
      this.postForm(`clinic_settings`,'clinicSettings',true,"formdata");
      this.$store.state.loader = false;
    },
    formPostSuccess(data) {
      this.moshaToast('Clinic settings saved successfully.', "success");
		},
    fileValidation(event) {
      $("#clinic_logo")
          .find("ul")
          .html('');
          
      if(event.target.files.length > 0){
        let err = 0;
        const size = (event.target.files[0].size / 1024 / 1024).toFixed(2);
        if (size > 2) {
          $("#clinic_logo").find("ul").append('<li class="d-block confirmerror"> Image size should not exceed more than 2 MB.</li>');
          err++;
          $('.saveData').attr('disabled', true);
        }
  
        const acceptedImageTypes = ["image/jpg", "image/jpeg", "image/png"];
        if (!acceptedImageTypes.includes(event.target.files[0].type)) {
          $("#clinic_logo").find("ul").append('<li class="d-block confirmerror"> Only .jpg,.jpeg,.png formats are supported</li>');
          err++;
          $('.saveData').attr('disabled', true);
        }
        
        if(err == 0){
          $('.saveData').attr('disabled', false);
          const file = event.target.files[0];
          this.previewImageURL = URL.createObjectURL(file);
        }else{
          this.previewImageURL = '';
        }
      }else{
        $('.saveData').attr('disabled', false);
        this.previewImageURL = '';
      }
    },
    getClinicSettings(){
      axios.post('/get_clinic_settings')
      .then((response) => {
        this.form.clinicName = response.data.data.clinicName;
        this.form.clinicAddress = response.data.data.clinicAddress;
        this.form.clinicCellNumber = response.data.data.clinicCellNumber;
        this.form.clinicEmail = response.data.data.clinicEmail;
        this.form.clinicTaxId = response.data.data.clinicTaxId;
        this.previewImageURL = response.data.data.clinicLogo;
        $('.e-content').html(response.data.data.emailSignature);
      });
    }
  },
  validations() {
    return this.$store.state.clinicSettings.validationRules;
  },
  created() {
    this.getClinicSettings();
  }
};
</script>
<style>
.e-richtexteditor.e-rte-tb-expand .e-rte-content, .e-richtexteditor.e-rte-tb-expand .e-source-content {
  margin-top: 75px !important;
}
.e-control.e-richtexteditor.e-lib.e-rte-toolbar-enabled{
    color: #333;
    display: block;
    position: relative;
    width: 500px !important;
}
.img-preview {
  max-height: 200px;
  width: auto;
}
</style>