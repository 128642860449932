<template>
      <p><b>Schedule Date:- </b> {{dateFormated(schedule_date+' '+schedule_time, 'MMM D, YYYY hh:mm a')}}</p>
    <div class="" v-if="adjustmentLog.length>0">
        <h3 class="mb-20 fw-600">Claim Level Adjustment</h3>
        <div class="table-responsive">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Trace Number</th>
                        <th>Payment</th>
                        <th>Adjustment</th>
                        <th>Adjustment Reason</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(items, index) in adjustmentLog" :key="index">
                        <td>{{ dateFormated(items.created_at, 'MMM D, YYYY') }}</td>
                        <td>{{items.trace_number}}</td>
                        <td>{{$filters.toUSD()}}</td>
                        <td>{{$filters.toUSD(items.adjustment_amount)}}</td>
                        <td>
                            <slot v-if="items.adjustment_group_code!=null">
                                <slot :set="reason_code=items.adjustment_reason_code.split(',')"></slot>
                                <slot :set="co_array=[]"></slot>	
                                <slot :set="oa_array=[]"></slot>	
                                <slot :set="pi_array=[]"></slot>
                                <slot :set="pr_array=[]"></slot>
                                <slot v-for="(value,index1) in items.adjustment_group_code.split(',')" :key="index1">
                                    <slot :set="aj_detail={grp_code:value+' : '+claim_adjustment_group_codes[value],reason_code:reason_code[index1]+' : '+claim_adjustment_codes[reason_code[index1]] }"></slot>
                                    <slot v-if="value=='CO'" :set="co_array.push(reason_code[index1]+' : '+claim_adjustment_codes[reason_code[index1]])"></slot>
                                    <slot v-if="value=='OA'" :set="oa_array.push(reason_code[index1]+' : '+claim_adjustment_codes[reason_code[index1]])"></slot>
                                    <slot v-if="value=='PI'" :set="pi_array.push(reason_code[index1]+' : '+claim_adjustment_codes[reason_code[index1]])"></slot>	
                                    <slot v-if="value=='PR'" :set="pr_array.push(reason_code[index1]+' : '+claim_adjustment_codes[reason_code[index1]])"></slot>	
                                </slot>
                                <slot v-if="co_array.length>0">                                        
                                    <slot v-for="(value_co,index_co) in co_array" :key="index_co">
                                        <div>{{value_co}}</div>
                                    </slot>
                                </slot>

                                <slot v-if="oa_array.length>0">                                                                                
                                    <slot v-for="(value_oa,index_oa) in oa_array" :key="index_oa">
                                        <div>{{value_oa}}</div>
                                    </slot>
                                </slot>
                                <slot v-if="pi_array.length>0">
                                    <slot v-for="(value_pi,index_pi) in pi_array" :key="index_pi">
                                        <div>{{value_pi}}</div>
                                    </slot>
                                </slot>
                                <slot v-if="pr_array.length>0">
                                    <slot v-for="(value_pr,index1) in pr_array" :key="index1">
                                        <div>{{value_pr}}</div>
                                    </slot>
                                </slot>
                            </slot>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="table-responsive">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th>Date</th>
                    <th>Log Details</th>
                    <th>User</th>
                </tr>
            </thead>
            <tbody>
                <slot v-if="insurance_log.length>0">
                    <tr v-for="(logs, index) in insurance_log" :key="index" >
                        <td>{{ dateFormated(logs.created_at, 'MMM D, YYYY hh:mm a') }}</td>
                        <td v-html="logs.log"></td>
                        <td>{{ logs.addedBy }}</td>
                    </tr>
                </slot>
                <slot v-else>
                    <tr class="text-center">
                        <td colspan="3">No logs available</td>
                    </tr>
                </slot>
            </tbody>
        </table>
    </div>
</template>

<script>
import axios from "@/scripts/axios.js";
import moment from "moment";
import Popper from "vue3-popper";
export default {
    props: {
        patient_id: null,
        appointment_id: null,
    },
    data() {
        return {
            insurance_log: [],
            schedule_date: null,
            schedule_time: null,
            adjustmentLog: [],
            claim_adjustment_codes: null,
            claim_adjustment_group_codes: null,
        }
    },
    components: { Popper },
    mounted() {
       this.getData() ;
       this.claimLevelAdjustment();
    },
    methods:{
        dateFormated(dt,format) {
            if(dt!=null && dt!=''){
                return moment(dt).format(format);
            }else{
                return '-';
            }
        },
        getData(){
            axios.post(`/reports/appt_billing_insurance_log`, {'patient_id' : this.patient_id, 'appointment_id' : this.appointment_id }).then(response=> { 
                this.insurance_log = response.data.data;
                this.schedule_date = response.data.data[0].schedule_date;
                this.schedule_time = response.data.data[0].schedule_time;
            });
        },
        claimLevelAdjustment(){
            axios.post(`/reports/claim_level_adjustment`, {'patient_id' : this.patient_id, 'appointment_id' : this.appointment_id }).then(response=> { 
                this.adjustmentLog = response.data.data.list;
                this.claim_adjustment_codes = response.data.data.claim_adjustment_codes;
                this.claim_adjustment_group_codes = response.data.data.claim_adjustment_group_codes;
            });
        },
    }

}
</script>

<style>

</style>