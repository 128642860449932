<template>
	
	<div class="content-wrapper">
		<div class="container-full">
			<div class="tab-content">
				<div class="content-header">
					<h2 class="heading2 font-bold m-0">WorkFlows</h2>
				</div>
				<div class="box">
					<div class="box-body">
						<form class="">
							<div class="row justify-content-sm-center">
								<div class="col-12 col-md-4">
									<div class="form-group">
										<input type="text" class="form-control me-2" id="title" placeholder="New WorkFlow Name" v-model="this.form.name">
										<div class="error-msg">
											<ul class="mb-0 p-0 fs-14">
												<li class="d-block" v-if="errorMessage && !this.form.name">
													{{ errorMessage }}
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
							<div class="bb-2 mb-10" v-for="(item,index) in this.form.workflowdata" :key="this.key">
								<div class="row align-items-center " v-bind:id="'form'+index">
									<div class="col-12 col-md-10">
										<div class="row">
											<div class="col-4 col-sm-2 col-md-2 col-lg-1">
												<span class="badge badge-secondary">Action {{ index+1 }}</span>
											</div>
											<div class="col-8 col-sm-3 col-md-3 col-lg-2" v-bind:id="'reminder_action_name_'+index">
												<div class="form-group mb-10">
													<input type="text" v-bind:id="'reminder_action_name_'+index" class="form-control" id="title" placeholder="Enter Action Name" v-model="this.form.workflowdata[index].reminder_action_name">
												</div>
											</div>
											<div class="col-6 col-sm-3 col-md-3 col-lg-2" v-bind:id="'reminder_type_'+index">
												<select name="select" class="form-select form-control mb-10" v-model="this.form.workflowdata[index].reminder_type" @change="reminder_type(index,item)">
													<option value=""  >Select Action Type</option>
													<option value="email">E-Mail</option>
													<option value="sms" >SMS</option>
												</select>
											</div>
											<div class="col-6 col-sm-3 col-md-3 col-lg-2" v-bind:id="'reminder_after_event_'+index">
												<div class="form-group mb-10">
													<select name="select" class="form-select form-control" v-bind:id="'reminder_after_event'+index" v-model="this.form.workflowdata[index].reminder_after_event"  @change="reminder_after_event(index)">
														<option value="after_main_event">After Start Date</option>
														<option value="after_previous_event" >After Previous Action</option>
													</select>
												</div>
											</div>
											<div class="col-6 col-sm-3 col-md-3 col-lg-2" v-bind:id="'reminder_value_'+index">
												<div class="form-group mb-10">
													<input type="text" v-bind:id="'reminder_value'+index" class="form-control" id="title" placeholder="1" @focusout="reminder_value(index)"  v-model="this.form.workflowdata[index].reminder_value">
												</div>
											</div>

											<div class="col-6 col-sm-3 col-md-3 col-lg-2" v-bind:id="'reminder_unit_type_'+index">
												<select name="select" class="form-select form-control mb-10" v-model="this.form.workflowdata[index].reminder_unit_type">
													<option value="" selected="selected" disabled="disabled">Select Unit</option>
													<option value="days" >Day(s)</option>
													<option value="minutes" >Minute(s)</option>
												</select>
											</div>
											<div class="col-12 col-sm-3 col-md-3 col-lg-1" v-bind:id="'reminder_content_'+index">
												<div class="form-group mb-10">
													<button @click="openContentDialog(index,item)" type="button" class="waves-effect waves-light btn btn-info btn-sm me-1 mb-1">{{ item && item.reminder_type == 'sms' ? 'Add SMS' : (item.reminder_type == 'email' ?  'Add Email' : 'Add Content')  }}</button>
												</div>
											</div>
										</div>
									</div>
									<div class="col-12 col-md-2 text-end">
										<!-- <button @click="addNewAction(index)" type="button" class="waves-effect waves-light btn btn-info btn-sm me-1 mb-1" data-bs-toggle="modal" data-bs-target="#mypreviewModal"><i class="fa fa-eye"></i> Preview</button> -->
										<button @click="addNewActionAfter(index,item)" v-if="this.form.workflowdata.length >1 " type="button" class="waves-effect waves-light btn btn-info btn-sm mb-5 me-1"><i class="fa fa-plus fs-12"></i> New Action</button>
										<button @click="removeAction(index)" type="button" class="waves-effect waves-light btn btn-danger btn-sm mb-5 mb-1"><i class="fa fa-close"></i> Delete</button>
									</div>
								</div>
							</div>
							<div class="text-end">
								<button @click="addNewAction(this.form.workflowdata.length)" type="button" class="waves-effect waves-light btn btn-info btn-sm"><i class="fa fa-plus fs-12"></i> New Action</button>
							</div>
							<div class="text-center">
								<button @click="saveWorkFlow" type="button" class="waves-effect waves-light btn btn-info me-1 mt-1">Save</button>
								<router-link to="/crm/workflow/list" class="waves-effect waves-light btn btn-danger me-1 mt-1">Close</router-link>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>


	<!--Modal start -->
	<div id="myContentModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
		<div class="modal-dialog modal-xl">
			<div class="modal-content">
				<div class="modal-header">
					<h2 class="modal-title m-0">Content</h2>
					<button type="button" class="btn-close btn-xs" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">
					<div v-if="this.current_item && this.current_item.reminder_type == 'email'">
						<div class="form-group row" id="subject_row">
							<label class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">Subject <small class="text-danger asterisksign">*</small></label>
							<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
								<div class="controls">
									<input id="Subject" type="text" v-model="subject" class="form-control" placeholder="Subject" autocomplete="off">
								</div>
								<div class="error-msg">
									<ul class="mb-0 p-0 fs-14"></ul>
								</div>
							</div>
						</div>
						<div class="form-group row">
							<div class="col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8" v-if="this.is_new_template">
								<div class="controls">
									<input id="template_title" type="text" v-model="template_title" class="form-control" placeholder="Enter title to save your new template" autocomplete="off">
								</div>
								<div class="error-msg">
									<ul class="mb-0 p-0 fs-14"></ul>
								</div>
							</div>
							<div class="col-2 col-md-2" v-if="this.is_new_template">
								<form-btn title="Back" btnCls="btn-info" @click="this.is_new_template = !this.is_new_template;"></form-btn>
							</div>
							<div class="col-12 col-md-10" v-if="!this.is_new_template">
								<div class="form-group mb-10">
									<select name="select" class="form-select form-control" v-model="selected_email_template" @change="changeTemplate">
										<option selected="selected" value="" disabled="disabled">Select Template</option>
										<option v-for="item in emaillist"  :value="item.id">{{item.template_name}}</option>
									</select>
								</div>
							</div>
							<div class="col-12 col-sm-4 col-md-4 col-lg-2" v-if="!this.is_new_template">
								<form-btn title="Add New" btnCls="btn-info" @click="this.is_new_template = !this.is_new_template;this.$refs.editorObj.ej2Instances.value= ''"></form-btn>
							</div>
						</div>
						<div class="form-group" id="content_row">
							<ejs-richtexteditor
							:pasteCleanupSettings="pasteCleanupSettings"
							:toolbarSettings="toolbarSettings"
							:insertImageSettings="insertImageSettings"
							:imageUploading="imageUploading"
							:imageRemoving="imageRemoving"
							:afterImageDelete="afterImageDelete"
							:width="width"
							:height="height"
							ref="editorObj"
							title="Message"
							rows="10">
						</ejs-richtexteditor>
					</div>
				</div>
				<div v-if="this.current_item && this.current_item.reminder_type == 'sms'">
					<div class="form-group" id="sms_content_row">
						<div class="d-flex align-items-center mb-2">
							<label class="mb-2 w-150">SMS Content</label> <small class="text-danger asterisksign">*</small>
							<select class="form-control w-200" id="customSmsTool" v-model="customSmsTool" @change="customSmsToolEvnt">
								<option value="">Custom Fields</option>
								<option value="{{patient.full_name}}">Full Name</option>
								<option value="{{patient.first_name}}">First Name</option>
								<option value="{{patient.last_name}}">Last Name</option>
								<option value="{{patient.email}}">Email</option>
								<option value="{{patient.cell_phone}}">Cell Phone</option>
								<option value="{{patient.date_of_birth}}">Date Of Birth</option>
							</select>
						</div>
						<div class="form-group controls">
							<textarea
							class="form-control"
							id="payment_note"
							v-model="sms_content"
							rows="2"
							placeholder="SMS Content"
							></textarea>
						</div>
						<div class="error-msg">
							<ul class="mb-0 p-0 fs-14"></ul>
						</div>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<form-btn title="Save Draft" btnCls="btn-info" @click="saveDialogContent"></form-btn>
				<button type="button" class="addpatientbtn btn-danger b-0" data-bs-dismiss="modal">Close</button>
			</div>
		</div>
	</div>
</div>
<!--Modal End -->
</template>
<script>
	import axios from "@/scripts/axios.js";
	import useVuelidate from "@vuelidate/core";
	import submitMixins from "@/scripts/mixins/submitMixins.js";
	import FormBtn from "@/components/base/formFields/formBtn.vue";
	import InputField from "@/components/base/formFields/InputField.vue";
	import FileUploadMultiple from "@/components/base/formFields/FileUploadMultiple.vue";


	export default {
		props: {
			value: String,
			width: Number,
			height: Number,
		},
		mixins: [submitMixins],
		setup: () => {
			return { v$: useVuelidate() };
		},
		components: {
			FormBtn,
			InputField,
			FileUploadMultiple,
		},
		data() {
			return {
				key :'0',
				workflowdata: [],
				selected_email_template:'',
				customSmsTool:'',
				subjectline:'',
				body_content:'',
				template_title:'',
				current_item : {},
				errorMessage : '',
				current_index : null,
				workflow_content_obj : {
					workflow_content_body : '',
					workflow_content_subject : '',
				},
				form : {
					name : '',
					workflowdata : [],
				},
				emaillist:[],
				subject:"",
				message:"",
				sms_content:"",
				file_name:"",
				is_new_template:false,
				attach_file:[],
				insertImageSettings:{
					path: process.env.VUE_APP_API_BASE_URL.substr(0,process.env.VUE_APP_API_BASE_URL.lastIndexOf('/'))+"/storage/attach_file/",
					saveUrl: process.env.VUE_APP_API_BASE_URL+"/support/file/store",
					display: 'inline'
				},
				toolbarSettings: {
					items: ['Bold', 'Italic', 'Underline', 'StrikeThrough','FontName', 'FontSize','Formats', 'Alignments', 'OrderedList', 'UnorderedList','Outdent', 'Indent','CreateLink', 'Image', 'ClearFormat','Undo', 'Redo',{
						tooltipText: 'Insert Symbol',
						undo: true,
						click: this.cutomFieldClick.bind(this),
						template: `<select id="customTool" class="customfill">
						<option value="">Custom Fields</option>
						<option value="{{patient.full_name}}">Full Name</option>
						<option value="{{patient.first_name}}">First Name</option>
						<option value="{{patient.last_name}}">Last Name</option>
						<option value="{{patient.email}}">Email</option>
						<option value="{{patient.cell_phone}}">Cell Phone</option>
						<option value="{{patient.date_of_birth}}">Date Of Birth</option>
						</select>`,
					}]
				},
				pasteCleanupSettings: {
					prompt: true,
					plainText: false,
					keepFormat: false,
					deniedTags: ['a'],
					deniedAttrs: ['class', 'title', 'id'],
					allowedStyleProps: ['color', 'margin', 'font-size']
				},
			}
		},
		async beforeCreate() {
			if (this.$route.name === "workflowEdit") {
				this.$store.state.loader = true;
				axios.get(`/crm/workflow/edit/${this.$route.params.id}`)
				.then((response) => {
					this.form = response.data.data;
					this.$store.state.loader = false;
				}).catch((error) => {
					this.$store.state.loader = false;
				});
			}
		},
		mounted () {
			this.addWorkFlowObj();
		},
		created() {
			this.email_template();
		},
		methods:{
			customSmsToolEvnt() {
				console.log("change call");
				this.sms_content = this.sms_content+''+this.customSmsTool;
				console.log(this.sms_content);
			},
			changeTemplate() {
				if (this.selected_email_template) {
					axios.get('email-template/edit/'+this.selected_email_template)
					.then((response) => {
						this.$refs.editorObj.ej2Instances.value = response.data.data.body_content;
					});
				}
			},
			addNewActionAfter(index, data) {
				$("#reminder_type_"+index).removeClass('form-group row error');
				$("#reminder_value_"+index).removeClass('form-group row error');
				$("#reminder_unit_type_"+index).removeClass('form-group row error');
				if (!data.reminder_type) {
					$("#reminder_type_"+index).addClass('form-group row error');
				} else if(!data.reminder_value) {
					$("#reminder_value_"+index).addClass('form-group row error');
				} else if(!data.reminder_unit_type) {
					$("#reminder_unit_type_"+index).addClass('form-group row error');
				} else {
					this.form.workflowdata.splice((index+1), 0, {
						reminder_type:'',
						reminder_value:'',
						reminder_action_name:'',
						reminder_unit_type:'',
						reminder_after_event:'after_main_event',
						reminder_content_subject:'',
						reminder_content_body:'',
					});
				}
			},
			cutomFieldClick: function(th) {
				this.$refs.editorObj.ej2Instances.executeCommand('insertText', $('#customTool').val() , {undo: true});
			},
			imageUploading(args) {
				var token = localStorage.getItem("authToken");
				this.file_name = Date.now()+"_"+(Math.random() + 1).toString(36).substring(7)+"."+args.fileData.type;
				args.fileData.name = this.file_name;
				args.fileData.id=this.file_name;
				args.currentRequest.setRequestHeader("Authorization", `Bearer ${token}`);
				args.currentRequest.setRequestHeader("File-Name", this.file_name);
			},
			saveWorkFlow() {
				var has_error = false;
				if (!this.form.name) {
					this.errorMessage = "Please add workflow name.";
					return false;
				}
				this.form.workflowdata.forEach((element, index) => {
					$("#reminder_type_"+index).removeClass('form-group row error');
					$("#reminder_value_"+index).removeClass('form-group row error');
					$("#reminder_unit_type_"+index).removeClass('form-group row error');
					if (!element.reminder_type) {
						$("#reminder_type_"+index).addClass('form-group row error');
						has_error = true;
					} else if(!element.reminder_value) {
						$("#reminder_value_"+index).addClass('form-group row error');
						has_error = true;
					} else if(!element.reminder_unit_type) {
						$("#reminder_unit_type_"+index).addClass('form-group row error');
						has_error = true;
					}
				});
				if (!has_error) {
					var routeUrl = this.form.workflow_id ? `/crm/workflow/update` : `/crm/workflow/store`;
					axios
					.post(routeUrl, this.form)
					.then((response) => {
						this.moshaToast(response.data.message, 'success');
						let $this = this;
						setTimeout(function() {
							$this.$router.push({ name: "WorkflowList" });
						}, 1000);
					}).catch((error) => {
						if (error.response.status === 422) {
							this.moshaToast("Action "+(parseInt(Object.keys(error.response.data.data)[0].replace(/[^0-9]/g,''))+1)+" mising conent.", "danger");
						} else {
							this.errorMessage = error.response.data.message;
						}
					});
				}
			},
			saveDialogContent() {
				if (this.current_item) {
					if (this.current_item.reminder_type == 'sms') {
						this.sms_content;
						$("#sms_content_row").removeClass('form-group row error');
						if (!this.sms_content) {
							$("#sms_content_row").addClass('form-group row error');
						}
						if (this.sms_content) {
							this.form.workflowdata[this.current_index].reminder_content_body = this.sms_content;
							$('#myContentModal').modal('hide');
						}
					} else if(this.current_item.reminder_type == 'email') {
						$("#subject_row").removeClass('error');
						if (!this.subject) {
							$("#subject_row").addClass('error');
						}
						if (this.template_title) {
							this.form.workflowdata[this.current_index].template_title = this.template_title;
						}
						if (this.$refs.editorObj.ej2Instances.value && this.subject) {
							this.form.workflowdata[this.current_index].reminder_content_body = this.$refs.editorObj.ej2Instances.value;
							this.form.workflowdata[this.current_index].reminder_content_subject = this.subject;
							$('#myContentModal').modal('hide');
						}
					}
				}
			},
			openContentDialog(index, item) {
				this.selected_email_template = '';
				this.template_title = '';
				this.subject = '';
				this.is_new_template = false;
				if (!item.reminder_type) {
					this.moshaToast("Please select action type", "danger");
				} else {
					this.current_item = item;
					this.current_index = index;
					console.log(item.id);
					if (item.reminder_type == 'email') {
						this.subject = item.reminder_content_subject;
						let $this = this;
						setTimeout(function() {
							$this.$refs.editorObj.ej2Instances.value = item.reminder_content_body;
						}, 1000);
						if (item.template_title) {
							this.template_title = item.template_title;
							this.is_new_template = true;
						}
					} else if (item.reminder_type == 'sms') {
						this.sms_content = item.reminder_content_body;
					}
					$('#myContentModal').modal('show');
				}
			},
			email_template(){
				axios.get('/email-template/listByCategory/workflow')
				.then((response) => {
					this.emaillist = response.data.data 
				});
			},
			addWorkFlowObj() {
				this.form.workflowdata.push({
					reminder_type:'',
					reminder_value:'',
					reminder_unit_type:'',
					reminder_after_event:'after_main_event',
					reminder_content_subject:'',
					reminder_content_body:'',
				});
			},
			addNewAction(value) {
				if (this.form.workflowdata == '') {
					this.addWorkFlowObj();
				} else {
					var index = value - 1;
					var data = this.form.workflowdata[index];
					$("#reminder_type_"+index).removeClass('form-group row error');
					$("#reminder_value_"+index).removeClass('form-group row error');
					$("#reminder_unit_type_"+index).removeClass('form-group row error');
					if (!data.reminder_type) {
						$("#reminder_type_"+index).addClass('form-group row error');
					} else if(!data.reminder_value) {
						$("#reminder_value_"+index).addClass('form-group row error');
					} else if(!data.reminder_unit_type) {
						$("#reminder_unit_type_"+index).addClass('form-group row error');
					} else {
						this.addWorkFlowObj();
					}
				}
			},
			removeAction(index) {
				this.form.workflowdata.splice(index, 1);
				this.key++;
			},
			reminder_type(index, item) {
				if (item.reminder_type == 'email') {
					$('#reminder_content_'+index+' button').html("Add Email");
				} else if(item.reminder_type == 'sms') {
					$('#reminder_content_'+index+' button').html("Add SMS");
				}
				$("#reminder_type_"+index).removeClass('form-group row error');
			},
			reminder_value(index){
				$("#reminder_value_"+index).removeClass('form-group row error');
			},
			reminder_after_event(index) {
				$("#reminder_after_event_"+index).removeClass('form-group row error');
			},
		}
	}
</script>
<style type="text/css">
	.e-richtexteditor .e-rte-toolbar.e-control[class*='e-toolbar'], .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar.e-control[class*='e-toolbar'] {
		position: relative;
		z-index: 3;
	}
	.e-richtexteditor.e-rte-tb-expand .e-rte-content, .e-richtexteditor.e-rte-tb-expand .e-source-content {
		margin-top: 45px !important;
	}
</style>