<template>
	<div class="content-wrapper">
		<div class="container-full">
			<div class="content-header">
				<div class="row justify-content-between">
					<div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xs-6">
						<h1 class="heading1 mt-0 me-2 d-inline-block">Leave Management <a href="#" data-bs-toggle="modal" data-bs-target="#leavedocument" title="Reference of Knowledge Base" v-on:click.prevent="reset()"> <i class="fa fa-question-circle fs-18"></i></a></h1>
						<!-- <Popper arrow content="It is used to add a leave request." :hover="true" class="helptips cursor-pointer">
							<i class="fa fa-info-circle"></i>
						</Popper> -->
					</div>
					<div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xs-6 text-md-right">
						<a href="#/leave/archived" class="waves-effect waves-light btn btn-info-light me-1"> Show Archived </a>

						<a href="javascript:void(0)" @click="getUserData()"
						class="waves-effect waves-light btn btn-info" data-bs-toggle="modal" data-bs-target="#leaveAddEditModal"><i class="fa fa-plus fs-14"></i> Request Leave
					</a>

				</div>
				<div id="leaveAddEditModal" class="modal fade" tabindex="-1" aria-labelledby="problemModalLabel" style="display: none;" aria-hidden="true">
					<div class="modal-dialog ">
						<div class="modal-content">
							<div class="modal-header">
								<h2 class="modal-title m-0"> Leave Management </h2>
							</div>
							<form>
								<div class="modal-body">

									<div class="form-group mb-10" :class="{ error: v$.form.staff_provider_id.$errors.length }">
										<label class="form-label">Staff Name</label> <small class="text-danger asterisksign">*</small>

										<select
										required
										class="select2Single form-select form-control"
										:value="modelValue" v-model="v$.form.staff_provider_id.$model"
										@input="staff_provider_id" 
										@change="$emit('update:modelValue', $event.target.value)"
										>
										<option value="">Select User</option>
										<optgroup label="Provider">
											<template  v-for="item in staffList"  :key="item.id">
												<option :value="item.id" v-if="item.user_type == 'provider' ">
													{{ item.salutation }} {{ item.name }}
												</option>
											</template>
										</optgroup>  
										<optgroup label="Staff">
											<template  v-for="item in staffList"  :key="item.id">
												<option :value="item.id" v-if="item.user_type == 'staff' ">
													{{ item.name }}
												</option>
											</template>
										</optgroup>

									</select>   
									<div class="error-msg" v-if="v$.form.staff_provider_id.$errors.length > 0">
										<ul class="mb-0 p-0 fs-14"><li class="d-block" v-for="(error, index) of v$.form.staff_provider_id.$errors"> {{ error.$message }}</li>
										</ul>
									</div>
								</div>

								<div class="form-group mb-10 availdate" :class="{ error: v$.form.to_date.$errors.length }">
									<label class="form-label">Start Date</label> <small class="text-danger asterisksign">*</small>
									<ejs-datepicker id="to_date" :value="to_date" 
									v-model="v$.form.to_date.$model" :format="dateFormat" :strictMode='strict'  :min='minVal' 
									placeholder="Start Date" :showClearButton='false' @change="getDate" :openOnFocus='true'></ejs-datepicker>

									<div class="error-msg" v-if="v$.form.to_date.$errors.length > 0">
										<ul class="mb-0 p-0 fs-14"><li class="d-block" v-for="(error, index) of v$.form.details.$errors"> {{ error.$message }}</li>
										</ul>
									</div>
								</div>

								<div class="form-group mb-10 availdate" :class="{ error: v$.form.from_date.$errors.length }">
									<label class="form-label">End Date</label> <small class="text-danger asterisksign">*</small>
									<ejs-datepicker id="from_date" :value="from_date" 
									v-model="v$.form.from_date.$model" :strictMode='strict'  :min='minVal' :format="dateFormat"
									placeholder="End Date" :showClearButton='false' @change="getEndDate" :openOnFocus='true'></ejs-datepicker>

									<div class="error-msg" v-if="v$.form.from_date.$errors.length > 0">
										<ul class="mb-0 p-0 fs-14"><li class="d-block" v-for="(error, index) of v$.form.details.$errors"> {{ error.$message }}</li>
										</ul>
									</div>
								</div>


								<div class="form-group mb-10" :class="{ error: v$.form.details.$errors.length }">
									<label class="form-label">Leave Reason</label> <small class="text-danger asterisksign">*</small>
									<textarea
									v-model="v$.form.details.$model"
									class="form-control"
									required
									:placeholder="title"
									></textarea>
									<div class="error-msg" v-if="v$.form.details.$errors.length > 0">
										<ul class="mb-0 p-0 fs-14"><li class="d-block" v-for="(error, index) of v$.form.details.$errors"> {{ error.$message }}</li>
										</ul>
									</div>
								</div>
							</div>

							<div class="modal-footer">
								<button type="button" class="waves-effect waves-light btn btn-info"  @click="saveLeave()">Save</button>
								<button type="button" @click="closeDialog"  class="waves-effect waves-light btn btn-danger" data-bs-dismiss="modal">Close</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
<!--document model start-->
	<div id="leavedocument" class="modal fade" tabindex="-1" role="dialog"
		aria-labelledby="myModalLabel" aria-hidden="true">
		<div class="modal-dialog modal-md">
		<div class="modal-content">
			<div class="modal-header">
				<h3 class="modal-title fw-900 mt-0">Reference of Knowledge Base</h3>
			</div>
			<div class="modal-body overflow-auto" style="max-height: 350px;">
				<table class="table table-bordered">
					<thead>
					<tr>
						<th scope="col">No.</th>
						<th scope="col">Knowledge Base Articles</th>
					</tr>
					</thead>
					<tbody>
					<tr>
						<td>1</td>
						<td> <a :href="path+'how-to-request-for-leave'" target="_blank">How To Request For Leave?</a></td>
					</tr>
					</tbody>
				</table>
				<Search :key="searchKey"></Search>
			</div>
			<div class="modal-footer">
				<button type="button" @click="" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
			</div>
		</div>
		</div>
	</div>
<!--document model end-->
	<section class="content">
		<div class="box">
			<div class="box-body">

				<div class="row">
					<div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-2">
						<div class="form-group mb-5 availdate">
							<label class="col-12 col-sm-12 col-md-12 col-lg-12">Provider</label>
							<Select2
                            v-model="filter.provider_id"
                            placeholder="Select Provider"
                            :options="providerFilterList"
							@select="changeFilterProvider($event)"
                            />
						</div>
					</div>

					<div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-2">
						<div class="form-group mb-5 availdate">
							<label class="col-12 col-sm-12 col-md-12 col-lg-12">Staff</label>
							<Select2
                            v-model="filter.staff_id"
                            placeholder="Select Staff"
                            :options="staffFilterList"
							@select="changeFilterStaff($event)"
                            />

						</div>
					</div>

					<div class="col-6 col-sm-6 col-md-6 col-lg-4 col-xl-2">
						<div class="form-group mb-5 availdate">
							<label class="col-12 col-sm-12 col-md-12 col-lg-12">Status</label>
							<select name="select" class="form-select form-control" v-model="filter.status">
								<option value="" selected>Select Status</option>
								<option
									v-for="(leave_status, index) of StatusList"
									:key="index"
									:value="leave_status.value"
								>
									{{ leave_status.title }}
								</option>
							</select>
						</div>
					</div>

					<div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-2">
						<div class="form-group mb-5 availdate">
							<label class="col-12 col-sm-12 col-md-12 col-lg-12">Leave Date Range</label>
							<div Class="col-12 col-sm-12 col-md-12 col-lg-12">
								<ejs-daterangepicker strictMode='strict'
								 id="leave_date_picker" v-model="filter.date"
								 placeholder="Select Date Range"
								 @change="getFilterDate"
								 >
								</ejs-daterangepicker>
							</div>
						</div>
					</div>

					<div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-2">
						<div class="form-group row">
							<div class="col-12 col-sm-12 col-md-12 col-lg-12 mt-sm-25">
								<div class="controls">
									<button
										type="button"
										@click="applyFilter"
										class="waves-effect waves-light btn btn-info me-1" style="height: 37px;"
									>
										Search
									</button>
									<button
										type="button"
										@click="resetFilter"
										class="waves-effect waves-light btn btn-info" style="height: 37px;"
									>
										Reset
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>

				<hr class="mt-0" />

				<div class="table-responsive">

					<data-table-server dataType="leave" :key="key" dataMethod="post">
						<template v-slot="slotProps">
							<td>{{ slotProps.item.username }}</td>
							<td v-if="slotProps.item.Days > 1">{{ slotProps.item.from_date }}-{{ slotProps.item.to_date }}</td>
							<td v-else>{{ slotProps.item.from_date }}</td>
							<td>{{ slotProps.item.Days }}</td>
							<td> 
								<span v-if="slotProps.item.status == 'Upcoming Leaves' " class="badge badge-success-light rounded d-inline-block fs-16">{{ slotProps.item.status }}  </span>
								<span v-else-if="slotProps.item.status == 'Completed' " class="badge badge-danger-light rounded d-inline-block fs-16">{{ slotProps.item.status }}</span>
								<span v-else-if="slotProps.item.status == 'On Leave' " class="badge badge-info-light rounded d-inline-block fs-16">{{ slotProps.item.status }}</span>
							</td>
							<td>
								<div class="d-flex">
									<a v-if="slotProps.item.status != 'Completed'"
									@click="editLeave(slotProps.item.id)"
									href="#"
									class="waves-effect waves-circle btn btn-circle btn-info btn-xs me-5" title="Edit Leave"
									><i class="fa fa-pencil"></i
									></a>
									<a v-if="slotProps.item.status != 'Completed'"
									@click="deleteItem(slotProps.item.id)"
									href="#"
									class="waves-effect waves-circle btn btn-circle btn-danger btn-xs" title="Delete Leave"
									><i class="fa fa-trash"></i
									></a>
								</div>
							</td>
						</template>
					</data-table-server>
				</div>
			</div>
		</div>
	</section>
</div>
</div>

</template>

<script>
import axios from "@/scripts/axios.js";
import DataTableServer from "@/components/base/DataTableServer.vue";
import submitMixins from "@/scripts/mixins/submitMixins.js";

import Autocomplete from 'vue3-autocomplete';
import useVuelidate from "@vuelidate/core";
import TableBase from "@/components/base/Table.vue";
import InputField from "@/components/base/formFields/InputField.vue";
import SelectBoxStatic from "@/components/base/formFields/SelectBoxStatic.vue";
import permissionMixins from "@/scripts/mixins/permission.js";
import moment from 'moment';
import Popper from "vue3-popper";
import Search from "@/components/Search.vue";
import Select2 from "@/components/base/formFields/select2.vue";

export default {

	name: "Leave",
	setup: () => {
		return { v$: useVuelidate() };
	},
	mixins: [submitMixins,permissionMixins],
	components: { Autocomplete, TableBase,InputField, SelectBoxStatic,DataTableServer, Popper, Search, Select2 },
	data() {
		
		return {
			key: 0,
			table_load_count : 0,
			staffList : [],
			staff_provider_id: this.$store.state.leave.user_id,
			date: new Date(),
			dateFormat: 'MM-dd-yyyy',
			strict:true,
			minVal: new Date(),
			path: process.env.VUE_APP_KB_DOCUMENT,
			searchKey:0,
			StatusList: [
				{ value: "leave", title: "On Leave" },
				{ value: "upcoming", title: "Upcoming Leaves" },
				{ value: "completed", title: "Completed" },
			],
			providerFilterList: [],
			staffFilterList: [],
		}
		
	},
	mounted() {
		this.getUserData();
		
	},
	computed: {
		form() {
			return this.$store.state.leave.form;
		},
		vuelidateExternalResults() {
			return this.$store.state.leave.vuelidateExternalResults;
		},
		validationRules() {
			return this.$store.state.leave.validationRules;
		},
		filter() {
			return this.$store.state.leave.filter;
		},
	},
	validations() {
		return this.$store.state.leave.validationRules;
	},
	methods: {
		reset() {
			this.searchKey = this.searchKey + 1;
		},
		getDate() {
			this.form.to_date =  $('#to_date').val();
		},
		getEndDate() {
			this.form.to_date = $('#to_date').val();
		},
		getUserData() {
			axios.get("/json_dropdown/user-list", {
			}).then(response => { 
				this.staffList = response.data.data;

				axios.get("/json_dropdown/provider").then(
				    (response) => {
					this.providerFilterList = response.data.data.map((detail) => {
						return { id: detail.id, text: detail.name };
					});
				});
				axios.get("/json_dropdown/staff").then(
					(response) => {
					this.staffFilterList = response.data.data.map((detail) => {
						return { id: detail.id, text: detail.name };
					});
				});

			});  
		},
		
		resetForm() {
			var self = this;
			Object.keys(this.form).forEach(function(key,index) {
				self.form[key] = '';
			});
			this.date = '';
			this.v$.$reset();
		},
		closeDialog() {
			$('#leaveAddEditModal').modal('hide');
			this.from_date = '';
			this.to_date = '';
			this.resetForm();
		},
		saveLeave() {
			const storeOrUpdate = this.form.id ? 'update' : 'store';
			const url = '/leave/'+storeOrUpdate;
			// var daterange = $('#date_range').val();
			// var date = daterange.split(' - ');
			var start =  $('#to_date').val();
			var end = $('#from_date').val();
			this.form.from_date = moment(String(start)).format('YYYY-MM-DD');
			this.form.to_date = moment(String(end)).format('YYYY-MM-DD');
			
			this.postForm(url, 'leaveList');

		},
		formPostSuccess(response) {
			const message = this.form.id ? 'Record updated successfully.' : 'Record added successfully.';
			this.moshaToast(message,'success');

			$('#leaveAddEditModal').modal('hide');

			this.resetForm();
			// this.table_load_count++;
			this.staffList = [];
			setTimeout(function(){ location.reload(); }, 400);
			
		},
		async editLeave(id) {
			if (id) {
				const result =  await this.$store.dispatch("getEditData", {
							dataType: "leave", // pass the module name
							formType: "form", // pass the propery name
							id: id,
						});
				this.getUserData(); 
				$('#leaveAddEditModal').modal('show');
				this.from_date = this.form.to_date;
				this.to_date = this.form.from_date;

			}
		},
		deleteItem(id) {
			this.$swal.fire({
				title: 'Are you sure you want to delete this record?',
				type: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Yes, delete it!'
			})
			.then((result) => {
				if (result.isConfirmed == true) {
					axios.post(`/leave/delete`,{'id':id})
					.then((response) => {
						this.$swal.fire('Deleted!','Leave has been deleted.','success')  
						.then((result) => { 
							if(result.isConfirmed == true){
								location.reload();
							}
						});
					})
				}
			});
		},
		getFilterDate() {
			var daterange = document.getElementById("leave_date_picker").value;
			var date = daterange.split(' - ');
			this.filter.date  = daterange;
			this.filter.from_date  = date[0];
			this.filter.to_date  = date[1];
		},
		applyFilter() {
			this.key++;
			this.search_drop = false;
		},
		resetFilter(){
			this.searchKey = this.searchKey + 1;
			this.filter.staff_id = '';
			this.filter.provider_id = '';
			this.filter.status = '';
			this.filter.from_date = '';
			this.filter.to_date = '';
			this.filter.date = '';
			this.search_result = [];
			$("#leave_date_picker").val('');
			this.applyFilter();
		},
		getFilter(dataType) {
      		return this.$store.state[dataType].filter;
    	},
		increment() {
			this.filter.staff_id = '';
			this.filter.provider_id = '';
			this.filter.status = '';
			this.search_result = [];
		},
		checklength() {
			if (this.filter.search_text == "") {
				this.increment();
			}
		},
		changeFilterProvider() {
			console.log("staff id: "+this.filter.staff_id)
			console.log("provider id: "+this.filter.provider_id)
			if (this.filter.staff_id != '' && this.filter.provider_id != '') {
				this.filter.staff_id = '';
			}
		},
		changeFilterStaff() {
			console.log("staff id: "+this.filter.staff_id)
			console.log("provider id: "+this.filter.provider_id)

			if (this.filter.staff_id != '' && this.filter.provider_id != '') {
				this.filter.provider_id = '';
			}
		},
	},
	watch: {
		"$route.name": function() {
			this.increment();
		},
	},
};
</script>
