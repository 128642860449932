<template>
    <div class="box p-15">
        <div class="mdc-tab">
            <ul class="nav nav-tabs mt-0 text-center text-sm-start" role="tablist">
                <li class="nav-item"> <a class="nav-link" data-bs-toggle="tab" href="#healthCareBilling" @click.prevent="changeTab('HealthCareBilling')" role="tab">Health Care</a> </li>
                <!-- <li class="nav-item"> <a class="nav-link active" data-bs-toggle="tab" href="#eCommerceBilling" @click.prevent="changeTab('ECommerceBilling')" role="tab">eCommerce</a> </li> -->
                <li class="nav-item"> <a class="nav-link" data-bs-toggle="tab" href="#billingPOS" @click.prevent="changeTab('BillingPOS')" role="tab">POS</a> </li>
                <!-- <li class="nav-item"> <a class="nav-link" data-bs-toggle="tab" href="#billingSummary" @click.prevent="changeTab('BillingSummary')" role="tab">Billing Summary</a> </li> -->
                <li class="nav-item"> <a class="nav-link" data-bs-toggle="tab" href="#billingContract" @click.prevent="changeTab('BillingContract')" role="tab">Contract</a> </li>
                <li class="nav-item"> <a class="nav-link" data-bs-toggle="tab" href="#wallet" @click.prevent="changeTab('Wallet')" role="tab">Wallet</a> </li>
            </ul>
            <div class="tab-content">
                <div class="tab-pane active" id="healthCareBilling" role="tabpanel">
                    <div class="box bg-lightest p-10 my-2">
                        <!-- <div class="form-group row mb-0">
                            <label class="col-form-label col-12 col-sm-3 col-md-3 col-lg-2 col-xl-2 col-form-label mb-0 text-sm-end">Appointment <small class="text-danger asterisksign" style="display: none;">*</small></label>
                            <div class="col-12 col-sm-9 col-md-9 col-lg-7 col-xl-5">
                                <select class="select2Single form-select form-control" v-model="appointment_id" id="appointment_id"  @change="changeAppointment($event)">
                                    <template  v-for="(item,index) in appointmentData"  :key="item.id">
                                        <option v-if="item.imported_at == null " :value="item.id" :data-val="item.appointment_number">
                                            {{item.name}}
                                        </option>
                                    </template>
                                    <optgroup label="Imported Appointment">
                                        <template  v-for="(item,index) in appointmentData"  :key="item.id">
                                          <option :value="item.id" v-if="item.imported_at != null " :data-val="item.appointment_number" data-type="imported">
                                            {{ item.name }}
                                          </option>
                                        </template>
                                    </optgroup>
                                </select>
                            </div>
                        </div> -->
                        <h2 class="my-30 text-center">Coming Soon...</h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import axios from "@/scripts/axios.js";
    import $ from 'jquery';
    export default {
        components: {
        },
        data() {
            return {
                appointment_id : '',
                appointment_number : '',
                componentKey: 0,
                appointmentData: [],
                hash: '',
            };
        },
        async beforeCreate() {
            var $this = this;
            $this.$store.state.loader = true;
                axios
                .post('/json_dropdown/patient-appointments',{patient_id:this.$store.state.patientDetail.patient_id})
                .then((response) => {
                    $this.$store.state.loader = false;
                    $this.appointmentData = response.data.data,
                    $this.notePreview = (response.data.data.length>0) ? 1 : '',
                    $this.appointment_id = (response.data.data.length>0) ? response.data.data[0].id : '';
                    $this.hash = (response.data.data.length>0) ? response.data.data[0].appointment_number : '';
                    $this.appointment_number = (response.data.data.length>0) ? response.data.data[0].appointment_number : '';
                    setTimeout( function() {
                        var hash = location.href.substring(location.href.lastIndexOf('/') + 2);
                        if(location.href.substring(location.href.lastIndexOf('/') + 1)!='ecommerce')
                        {
                            console.log(location.href.substring(location.href.lastIndexOf('/') + 1));
                            $this.hash = hash;
                            $this.appointment_id = $("select#appointment_id option[data-val='"+hash+"']").attr("value");
                            $this.appointment_number = hash;
                            $this.componentKey += 1;
                        }
                    }, 100);
                });
        },
        methods: {
          changeTab (components) {
              this.$store.state.patientDetailsComp = components;
              let props = this.$router.resolve({ 
                name: components,
                params: { id: this.$route.params.id},
              });
              history.pushState(null, '',props.href);
              /*if (history.pushState && props) {
                  if (this.hash=='' || this.hash==null || typeof this.hash === undefined || typeof this.hash === 'undefined') {
                  } else {
                      history.pushState(null, '',props.href+"/#"+this.hash);
                  }
              }*/
          },
          changeAppointment($event) {
              this.appointment_id = $event.target.value;
              this.hash = $("select#appointment_id option[value='"+this.appointment_id+"']").attr("data-val");
              this.appointment_number = this.hash;
              var newUrl = location.href.substring(0, location.href.lastIndexOf('/'));
              history.pushState(null, '',newUrl+"/#"+this.hash);
              this.componentKey += 1;
          }
        }
    }
</script>