<template>
	<div class="content-wrapper">
		<div class="container-full">
			<section class="content">
				<div class="content-header">
					<h1 class="heading1 m-0">Appointment Dashboard 
						<a href="#" data-bs-toggle="modal" data-bs-target="#statusdocument" title="Reference of Knowledge Base" v-on:click.prevent="reset()"> 
							<i class="fa fa-question-circle fs-20"></i>
						</a>
					</h1>
				</div>
				<div class="box">
					<div class="box-body">
						<div class="row">
							<div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
								<div class="form-group mb-5 availdate">
									<label class="col-12 col-sm-12 col-md-12 col-lg-12">Appointment Date Range</label>
									<div Class="col-12 col-sm-12 col-md-12 col-lg-12">
										<ejs-daterangepicker id="daterangepicker" :value="date" :placeholder="waterMarkText" @change="getdate"></ejs-daterangepicker>
									</div>
								</div>
							</div>
							<div class="col-6 col-sm-6 col-md-6 col-lg-4 col-xl-3">
								<div class="form-group">
									<label class="form-label">Office</label>
									<select name="select" class="form-select form-control" v-model="form.office_id">
										<option value="">Select Office</option>
										<option v-for="(office, index) of offices" :key="index" :value="office.id">
											{{ office.name }}
										</option>
									</select>
								</div>
							</div>
							<div class="col-6 col-sm-6 col-md-6 col-lg-4 col-xl-3">
								<div class="form-group">
									<label class="form-label">Appointment Type</label>
									<select name="select" class="form-select form-control" @change="changeUpdtApptStatus" v-model="form.appointment_type">
										<option value="">Select Type</option>
										<option value="WalkIn">WalkIn</option>
										<option value="Tele Health">Tele Health</option>
										<option value="Phone">Phone</option>
										<option value="Office">Office</option>
									</select>
								</div>
							</div>
							<div class="col-6 col-sm-6 col-md-6 col-lg-4 col-xl-3">
								<div class="form-group">
									<label class="form-label">Visit Profile</label>
									<select name="select" class="form-select form-control" v-model="form.visit_profile_id">
										<option value="">Select Visit Profile</option>
										<option v-for="(visit_profile, index) of visit_profiles" :key="index" :value="visit_profile.id">
											{{ visit_profile.name }}
										</option>
									</select>
								</div>
							</div>
							<div class="col-6 col-sm-6 col-md-6 col-lg-4 col-xl-3">
								<div class="form-group">
									<label class="form-label">Appointment Status</label>
									<select name="select" class="form-select form-control" v-model="form.appointment_status_id">
										<option value="">Select Appointment Status</option>
										<option v-for="(appt_status, index) of appointmentstatusFiltered" :key="index"
											:value="appt_status.id">
											{{ appt_status.name }}
										</option>
									</select>
								</div>
							</div>
							<div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
								<div class="form-group row">
									<div class="col-12 col-sm-12 col-md-12 col-lg-12 mt-sm-25">
										<div class="controls">
											<a @click="getFilterReportData()" class="waves-effect waves-light btn btn-info me-1">Search</a>
											<a @click="clearFiter()" class="waves-effect waves-light btn btn-info-light me-1">Clear</a>
											<div class="dropdown d-inline-block ms-2">
												<a class="text-info" href="#" data-bs-toggle="dropdown" aria-expanded="false" title="appointment print">
													<i class="fa fa-reorder"></i>
												</a>
												<div class="dropdown-menu dropdown-menu-end overflow-hidden">
													<a class="dropdown-item py-10" data-bs-toggle="modal" data-bs-target="#printApptModal" @click="printApptData('pdf')" href="#" title="print"><i class="fa fa-print"></i> Print</a>
													<a class="dropdown-item py-10" @click="printApptData('excel')" href="#" title="xls"><i class="fa fa-download"></i> Download</a>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<hr class="mt-0" />
					<div class="box-body">
						<div class="row">
							<div class="col-8 col-sm-6 col-md-6 col-lg-6 col-xl-2">
								<div class="form-group">
									<select name="select" class="form-select form-control" @change="changeUpdtApptStatus" v-model="updated_appointment_status_id">
										<option disabled="" value="">Select Appointment Status</option>
										<option v-for="(appt_status, index) of appointmentstatusFiltered" :key="index" :value="appt_status.id">
											{{ appt_status.name }}
										</option>
									</select>
								</div>
							</div>
							<div class="col-4 col-sm-6 col-md-2 col-lg-2 col-xl-2">
								<div class="controls">
									<a @click="updateApptStatus()" class="waves-effect waves-light btn btn-info-light py-7"> {{ this.update_btn_label }}</a>
								</div>
							</div>
						</div>
						<div class="table-responsive">
							<data-table-server dataType="AppointmentStatusDashboard" dataMethod="post" :key="key">
								<template v-slot="slotProps">			
									<td>
										<input v-bind:id="'checkbox_id' + slotProps.item.id" name="appointment_id" type="checkbox" class="filled-in chk-col-info myCheckbox" :value="slotProps.item.id">
										<label v-bind:for="'checkbox_id' + slotProps.item.id" class="m-0 text-start fs-16" style="padding-left: 18px;"></label>
									</td>
									<td>
										<a v-bind:href="'#/patient-chart/' + slotProps.item.unique_id" target="_blank">{{ slotProps.item.patient_name }}</a>
									</td>
									<td>{{ slotProps.item.provider_name }}</td>
									<td>{{ slotProps.item.office_name }}</td>
									<td>{{ slotProps.item.type }}</td>
									<td>
										<slot v-if="slotProps.item.appointment_profile_name?.length > 0">
											{{ slotProps.item.appointment_profile_name }}
										</slot>
										<slot v-else>
											-
										</slot>
									</td>
									<td>
										<span v-if="slotProps.item.appointment_status_name == 'Confirmed'" class="bg-success-light rounded d-inline-block text-center l-h-n py-1 px-5 fs-14">{{
												getApptDisplayStatus(slotProps.item) }}</span>
										<span v-else>{{ getApptDisplayStatus(slotProps.item) }}</span>
									</td>
									<td>{{ $filters.displayDateTimeLocal(slotProps.item.schedule_time) }}</td>
									<td>
										<slot v-if="!slotProps.item.clinical_note">
											<a @click="getCovidNote(slotProps.item.id)" v-if="slotProps.item.type == 'Tele Health'" class="waves-effect waves-light btn btn-info me-1 btn-xs">
												<i class="fa fa-eye"></i> Covid Note
											</a>
											<a @click="uploadInsDocument(slotProps.item)" v-if="slotProps.item.type == 'Tele Health' && slotProps.item.is_blurry == 1" class="waves-effect waves-light btn btn-info me-1 btn-xs">
												<i class="fa fa-shield" aria-hidden="true"></i> Ins. Docs.
											</a>
										</slot>
										<a v-if="permissioncheck(this.$store.state.permission.create_update) && slotProps.item.appointment_status_name != 'Rescheduled'" target="_blank" :href="`/#/patient-chart/`+ slotProps.item.unique_id +`/edit-appointment/`+ slotProps.item.appointment_number"	class="waves-effect waves-light btn btn-info btn-xs me-1" title="Edit Appointment"><i class="fa fa-pencil"></i></a>
										<a href="#" @click="getappointment(slotProps.item.id,slotProps.item)" class="waves-effect waves-light btn btn-danger btn-xs me-1" title="Delete Appointment" data-bs-toggle="modal"  data-bs-target="#appointment" ><i class="fa fa-trash"></i></a>
									</td>
								</template>
							</data-table-server>
						</div>
					</div>
				</div>
			</section>
		</div>
	</div>
	<!--document model start-->
	<div id="statusdocument" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
		aria-hidden="true">
		<div class="modal-dialog modal-md">
			<div class="modal-content">
				<div class="modal-header">
					<h3 class="modal-title fw-900 mt-0">Reference of Knowledge Base</h3>
				</div>
				<div class="modal-body overflow-auto" style="max-height: 350px;">
					<table class="table table-bordered">
						<thead>
							<tr>
								<th scope="col">No.</th>
								<th scope="col">Knowledge Base Articles</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>1</td>
								<td> 
									<a :href="path + 'what-is-appointment-status-dashboard-in-pureehr'" target="_blank">What Is Appointment Dashboard In PureEHR?</a>
								</td>
							</tr>
						</tbody>
					</table>
					<Search :key="searchKey"></Search>
				</div>
				<div class="modal-footer">
					<button type="button" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
				</div>
			</div>
		</div>
	</div>
	<!--document model end-->
	<div id="covidPDF" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
		<div class="modal-dialog modal-lg">
			<div class="modal-content">
				<div class="modal-header">
					<h3 class="modal-title fw-900 mt-0">Covid PDF</h3>
				</div>
				<div class="modal-body"></div>
				<div class="modal-footer">
					<button type="button" class="waves-effect waves-light btn btn-danger" data-bs-dismiss="modal" aria-label="Close">Close</button>
				</div>
			</div>
		</div>
	</div>

	<div id="insDocsUpload" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
		aria-hidden="true">
		<div class="modal-dialog modal-xl">
			<div class="modal-content">
				<div class="modal-header">
					<h3 class="modal-title fw-900 mt-0">Insurance Document</h3>
				</div>
				<div class="modal-body">
					<patient-insurance-external-use :patient_id="current_patient_id" :patient_unique_id="current_patient_unique_id" v-if="current_patient_id > 0" :key="current_patient_id"></patient-insurance-external-use>
				</div>
				<div class="modal-footer">
					<button type="button" class="waves-effect waves-light btn btn-danger"
						@click="insDocsClosed">Close</button>
				</div>
			</div>
		</div>
	</div>

	<!--model start-->
	<div id="appointment" class="appointment modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
		<div class="modal-dialog modal-md">
			<div class="modal-content">
				<div class="modal-header">
					<h4 class="modal-title fw-900 mt-0">
						Are you sure you want to delete this appointment?
					</h4>
				</div>
				<div class="modal-body">
					<div class="form-group">
						<textarea class="form-control" placeholder="Enter reason for delete." id="reasonfordelete"></textarea>
					</div>
				</div>
				<div class="modal-footer">
					<button @click="appointmentdelete" type="button" class="waves-effect waves-light btn btn-info">
						Delete
					</button>
					<button type="button" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">
						Close
					</button>
				</div>
			</div>
		</div>
	</div>
	<!--model end-->
	<!--printAppt Modal Start-->
	<div class="modal" id="printApptModal" tabindex="-1">
		<div class="modal-dialog modal-md" style="max-width:75%">
			<div class="modal-content">
				<div class="modal-header">
					<button type="button" class="btn-close btn-xs" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">
					<vue-pdf-viewer height="1050px" :url="receipt_url" v-if="embed = 0">
					</vue-pdf-viewer>
					<iframe :src="receipt_url" width="100%" height="500px" v-if="embed = 1" />
					<img srd="images/1.gif" v-else>
				</div>
			</div>
		</div>
	</div>
	<!--printAppt Modal end-->
</template>
<script>
import axios from "@/scripts/axios.js";
import permissionMixins from "@/scripts/mixins/permission.js";
import submitMixins from "@/scripts/mixins/submitMixins.js";
import moment from 'moment';
import PatientInsuranceExternalUse from "@/components/patients/details/PatientInformation/PatientInsuranceExternalUse.vue";
import Search from "@/components/Search.vue";
import DataTableServer from "@/components/base/DataTableServer.vue";
import VuePDFViewer from 'vue-instant-pdf-viewer';

export default {
	mixins: [permissionMixins,submitMixins],
	components: {
		PatientInsuranceExternalUse,
		Search,
		'vue-pdf-viewer': VuePDFViewer,
		DataTableServer,
	},
	computed: {
		form() {
			return this.$store.state.AppointmentStatusDashboard.filter;
		},
	},
	data() {
		return {
			office_id: [],
			current_patient_id: '',
			current_patient_unique_id: '',
			waterMarkText: "Select Date Range",
			date: new Date(),
			appointment_list: {},
			appointmentstatus: [],
			appointmentstatusFiltered: [],
			offices: [],
			visit_profiles: [],
			example: '',
			updated_appointment_status_id: '',			
			update_btn_label: 'Update',
			telehealth_label_status: [3, 4, 5, 6, 7, 9, 10, 11],
			path: process.env.VUE_APP_KB_DOCUMENT,
			searchKey: 0,
			receipt_url: '',
			key: 1,
			id: '',
			action: []
		};
	},
	mounted() {		
		this.date = moment().format("MM-DD-YYYY") + ' - ' + moment().format("MM-DD-YYYY");
	},
	async created() {
		this.get_fiter_dropdown_list();
	},
	methods: {		
		reset() {
			this.searchKey = this.searchKey + 1;
		},
		insDocsClosed() {
			$('#insDocsUpload').modal('hide');
			this.getFilterReportData();
		},
		uploadInsDocument(item) {
			this.current_patient_id = item.patient_id;
			this.current_patient_unique_id = item.unique_id;
			$('#insDocsUpload').modal("show");
		},
		async getCovidNote(id) {
			this.$store.state.loader = true;
			const res = await fetch(process.env.VUE_APP_API_BASE_URL + '/appointment/view-pdf', {
				method: 'POST',
				headers: {
					'Authorization': 'Bearer ' + localStorage.getItem('authToken'),
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({ appointment_id: id }),
			});
			const blob = await res.blob();
			const urlObject = URL.createObjectURL(blob);
			$("#covidPDF").modal("show");
			$("#covidPDF .modal-body").html('<iframe src="' + urlObject + '" width="100%" height="500px" ></iframe>');
			this.$store.state.loader = false;
		},
		getApptDisplayStatus(item) {
			let statusNew = '';
			if (item.type == 'Tele Health') {
				if (item.status == 2) {
					statusNew = 'Telehealth Confirmed';
				} else if (item.status == 1) {
					statusNew = 'Telehealth Pending';
				} else if (item.status == 8) {
					statusNew = 'Telehealth Completed';
				}
			} else {
				statusNew = item.appointment_status_name;
			}
			return statusNew;
		},
		changeUpdtApptStatus() {
			let newAppointmentstatus = [];
			this.appointmentstatus.forEach((value, index) => {
				if (this.form.appointment_type == 'Tele Health') {
					if (!this.telehealth_label_status.includes(value.id)) {
						if (value.id == 2) {
							value.name = 'Telehealth Confirmed';
						} else if (value.id == 1) {
							value.name = 'Telehealth Pending';
						} else if (value.id == 8) {
							value.name = 'Telehealth Completed';
						}
						newAppointmentstatus.push(value);
					}
				} else {
					if (value.id == 2) {
						value.name = 'Confirmed';
					} else if (value.id == 1) {
						value.name = 'Not Confirmed';
					} else if (value.id == 8) {
						value.name = 'Complete';
					}
					newAppointmentstatus.push(value);
				}
			});
			this.appointmentstatusFiltered = newAppointmentstatus;
			if (this.form.appointment_type == 'Tele Health' && this.updated_appointment_status_id == 2) {
				this.update_btn_label = 'Update & Send Tele Health Link';
			} else {
				this.update_btn_label = 'Update';
			}
		},
		updateApptStatus() {
			if (this.updated_appointment_status_id) {
				var apptIds = $.map(this.example && this.example.rows('.selected').data(), function (item) {
					var custom_value = '';
					if ($(item[0]).attr("value") != undefined) {
						custom_value = $(item[0]).attr("value");
					} else {
						custom_value = item[0];
					}
					return parseInt(custom_value);
				});
				if (apptIds && apptIds.length > 0) {
					axios.post("appointment/status-update", { 'appointment_ids': apptIds, 'appointment_status_id': this.updated_appointment_status_id })
						.then((response) => {
							this.moshaToast('Appointment status updated successfully.', "success");
							this.getFilterReportData();
						});
				} else {
					this.moshaToast("Please select appointment", "danger");
				}
			} else {
				this.moshaToast("Please select appointment status", "danger");
			}
		},
		clearFiter() {
			this.form.appointment_type = '';
			this.form.office_id = '';
			this.form.visit_profile_id = '';
			this.form.appointment_status_id = '';
		},
		getFilterReportData() {
			this.key = this.key+1;
		},
		initializeTable() {
			setTimeout(() => {
				this.example = $("#apptStatusDash").DataTable({
					'columnDefs': [
						{
							'targets': 0,
							'checkboxes': {
								'selectRow': true
							},
							'render': function (data, type, full, meta) {
								var custom_value = '';
								if ($(data).attr("value") != undefined) {
									custom_value = $(data).attr("value");
								} else {
									custom_value = data;
								}
								return '<input type="checkbox" class="dt-checkboxes" name="id[]" value="' + $('<div/>').text(custom_value).html() + '">';
							}
						}
					],
					'select': {
						'style': 'multi',
						'selector': 'td:first-child'
					},
					"aaSorting": [],
					destroy: true,
				});
				$("#apptStatusDash").DataTable().rows('.selected').nodes().to$().removeClass('selected');
				let elem = document.getElementsByName("apptStatusDash_length")[0];
				elem.classList.add("form-select");
				elem.classList.add("form-control-sm");
				elem.style.cssText += `border-color: #86a4c3 !important`;
				elem = document.getElementsByTagName("input")[1];
				elem.style.cssText += `outline: none`;
				$('#apptStatusDash_filter').remove();
			}, 500);
		},
		get_fiter_dropdown_list() {
			axios.get("/json_dropdown/get-user-office-list")
				.then((response) => (
					this.offices = response.data.data
				));
			axios.get("/json_dropdown/appointment-profile")
				.then((response) => (
					this.visit_profiles = response.data.data
				));
			axios.get("/json_dropdown/appointment-status").then((response) => {
				this.appointmentstatus = response.data.data;
				this.appointmentstatusFiltered = this.appointmentstatus;
				this.changeUpdtApptStatus();
			});
		},
		getdate() {
			var daterange = document.getElementById("daterangepicker").value;
			var date = daterange.split(' - ');
			this.form.from_date = date[0];
			this.form.to_date = date[1];
		},
		printApptData(type) {
			var th = this;
			var apptList = th.appointment_list;
			if (type == 'pdf') {
				this.getSrc(apptList);
			} else {
				axios.post("dashboard/print-today-appointment", { "appointmentlist": apptList, 'export_type': "excel" })
					.then((response) => {
						let blob = new Blob([response.data.data], {
							type: 'application/pdf'
						})
						let link = document.createElement('a')
						link.href = response.data.data
						link.download = 'appointment.xlsx'
						link.click()
						this.moshaToast("Download XSL successfully", "success");
					});
			}
		},
		async getSrc(apptList) {
			var daterange = document.getElementById("daterangepicker").value;
			const res = await fetch(process.env.VUE_APP_API_BASE_URL + '/dashboard/print-today-appointment', {
				method: 'POST',
				headers: {
					'Authorization': 'Bearer ' + localStorage.getItem('authToken'),
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({ "appointmentlist": apptList, "date_range": daterange }),
			});
			const blob = await res.blob();
			const urlObject = URL.createObjectURL(blob);
			if (/Android|webOS|Opera Mini/i.test(navigator.userAgent)) {
				$("#printApptModal").modal("hide");
				window.open(urlObject, '_blank', 'height=600,width=1200');
			} else {
				this.receipt_url = urlObject;
				this.browserCheck();
			}
		},
		browserCheck() {
			// Opera 8.0+
			var isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

			// Firefox 1.0+
			var isFirefox = typeof InstallTrigger !== 'undefined';

			// Safari 3.0+ "[object HTMLElementConstructor]" 
			var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));

			// Internet Explorer 6-11
			var isIE = /*@cc_on!@*/false || !!document.documentMode;

			// Edge 20+
			var isEdge = !isIE && !!window.StyleMedia;

			// Chrome 1 - 79
			var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

			// Edge (based on chromium) detection
			var isEdgeChromium = isChrome && (navigator.userAgent.indexOf("Edg") != -1);

			// Blink engine detection
			var isBlink = (isChrome || isOpera) && !!window.CSS;

			if (isChrome == true) {
				this.embed = 0;
			} else {
				this.embed = 1;
			}
		},
		appointmentdelete(){
			var reasonfordelete =$("#reasonfordelete").val();

			if(reasonfordelete != ""){
				axios.post("/appointment/store",{id:this.id,deleted:this.action})
				.then((response) => {
					axios.post("/appointment/reason-for-delete",{id:this.id,reason_for_delete:reasonfordelete})
					.then((response) => {
						this.moshaToast("Record deleted successfully.", "success");
						$('#appointment').modal('hide');
						this.getFilterReportData();
					})
				});
			}else{
				this.moshaToast("Please enter reason for delete!", "error");
			}
			
		},
		getappointment(id,item){
			$("#reasonfordelete").val('');
			this.id = id;
			this.action[0] = item;
		},
	},
};
</script>
<style>
	.dt-checkboxes {
		position: relative !important;
		opacity: unset !important;
	}

	#apptStatusDash input {
		position: relative !important;
		opacity: unset !important;
	}
</style>
