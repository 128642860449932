import {
  helpers,
  required,
  email,
  minLength,
  maxLength,
  sameAs,
  numeric,
  alpha
} from "@vuelidate/validators";

var nameCheck =  /^[a-zA-Z\s]*$/;
var regex = new RegExp(nameCheck);
const nameregex = (value) => !helpers.req(value) || regex.test(value);

const demographics = {
  namespaced: true,

  state: {
    tableData: {
      header: [
        "Chart ID",
        "Provider",
        "Last Name",
        "First Name",
        "Home Phone",
        "Cell Phone",
        "Last Appt",
        "Next Appt",
        "Follow-up",

      ],
    },
    form: {},
    referring_doctor:{},
    vuelidateExternalResults: {
      form: {
        ssn_number:[],
        race:[],
        emergency_contact_phone:[],
        emergency_contact_name:[],
        ethnicity:[],
        state_id:[],
        country_id:[],
        responsible_party_email:[],
        previous_mobile:[],
        previous_phone:[],
        address:[],
        zip_code:[],
        sex:[],
      },
    },
    validationRules: {
      form: {
        ssn_number: {
          max: maxLength(9),
          numeric,
        },
        sex: {
          required: helpers.withMessage("Gender is required", required),
        },
        date_of_birth: {
          //required: helpers.withMessage("Patient DOB is required", required),
        },
        /*race:{
          required: helpers.withMessage("Race is required", required),
        },*/
        emergency_contact_name:{
          nameregex: helpers.withMessage("Enter only valid alphabets.", nameregex),
        },
        emergency_contact_phone:{
          max: maxLength(15),
          //numeric,
        },
        responsible_party_phone:{
          max: maxLength(15),
          //numeric,
        },
        /*ethnicity:{
          required: helpers.withMessage("Ethnicity is required", required),
        },*/
        address:{
          required: helpers.withMessage("Address is required", required),
        },
        zip_code:{
          required: helpers.withMessage("zipcode is required", required),
        },
        state_id:{
          required: helpers.withMessage("State is required", required),
        },
        country_id:{
          required: helpers.withMessage("Country is required", required),
        },
        responsible_party_email:{
          email: helpers.withMessage("Email address is not valid", email),
        },
        previous_mobile:{
          //numeric,
          min: minLength(10),
          max: maxLength(15),
        },
        previous_phone:{
          //numeric,
          min: minLength(10),
          max: maxLength(15),
        },
        previous_email:{
          email: helpers.withMessage("Email address is not valid", email),
        }
      },
    },
    listUrl: "/patient/list",
    getEditUrl: "/patient/:id/demographics/edit",
    list: [],
  },
  getters: {
    getTableHeaders: (state) => state.header,

    getTableContents: (state) => state.list,
    getListURL: (state) => state.listUrl,
  },
  mutations: {},
  actions: {},
}

export default demographics;
