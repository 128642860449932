import moment from 'moment';
const Contract = {
	namespaced: true,
  	state: {
    	listUrl: "/reports/contract",
		header: [
			{"chart_id":"Plan #"},
			{"first_name":"Patient Name"},
			{"last_name":"Status"},
			{"email":"Contract Name"},
			{"legacy_source":"Amount"},
			{"auth":"Start Date"},
			{"":"End Date"},
		],
		pagination: {
			to: "",
			from: "",
			total: "",
			last_page: "",
			current_page: "",
			pageNavArr: [],
		},	
		list: [],	
		filter: {
            date_range: moment().subtract(1, 'months').format("MM-DD-YYYY") + ' - ' + moment(new Date()).format("MM-DD-YYYY"),
            status: 'all',            
        },
  	},
  getters: {
    getTableHeaders: (state) => state.header,
    getTableContents: (state) => state.list,
    getListURL: (state) => state.listUrl,
  },
  mutations: {},
  actions: {},
}

export default Contract;