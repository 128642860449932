<template>
    <div class="content-wrapper">
		<div class="container-full">
			<div class="content-header">
				<h1 class="heading1 m-0 d-inline-block">EOB Upload Details </h1>
			</div>
			<section class="content">
				<div class="box">
                    <div class="box-body">
                        <div class="table-responsive">
                            <table class="table table-bordered fs-16">
                                <thead class="text-center">
                                    <tr>
                                        <th>Sr. #</th>
                                        <th>Claim Ref.</th>
                                        <th>Patient Name</th>
                                        <th>Allowed Amount</th>
                                        <th>Patient Balance</th>
                                        <th>Insurance Balance</th>
                                        <th>Adjustment Amount</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <slot v-if="list.length>0">
                                    <tbody class="text-center">
                                        <tr v-for="(item, index) in list" :key="index">
                                            <td>{{index+1}}</td>
                                            <td>{{item.claim_number.substring(item.claim_number.indexOf('I') + 1)}}</td>
                                            <td>{{item.patient_name}}</td>
                                            <td>{{ $filters.toUSD(item.allowed_amount) }}</td>
                                            <td>{{ $filters.toUSD(item.eob_patient_balance) }}</td>
                                            <td>{{ $filters.toUSD(item.eob_insurance_balance) }}</td>
                                            <td>{{ $filters.toUSD(item.adjustment_amount) }}</td>
                                            <td><a href="javascript:void(0);" @click="editBatch(item.id)"> <i class="fa fa-pencil-square-o" aria-hidden="true"></i> </a></td>
                                        </tr>
                                    </tbody>
                                </slot>
                                <slot v-else>
                                    <tbody>
                                        <tr>
                                            <td colspan="6" align="center">No Records</td>
                                        </tr>
                                    </tbody>
                                </slot>
                            </table>
                        </div>
                    </div>
                </div>
            </section>
            <div class="modal" id="editinsuranceinfo" tabindex="-1">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="btn-close btn-xs" data-bs-dismiss="modal" aria-label="Close" @click="close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="form-group row mb-5">
                                <label class="col-form-label mb-0 col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 text-md-end">Allowed Amount </label>
                                <div class="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6 mb-5">
                                    <div class="input-group">
                                        <span class="input-group-addon">$</span>
                                        <input type="text" class="form-control" placeholder="Allowed Amount" v-model="formSubmit.allowed_amount" v-on:keypress="numbersOnly">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row mb-5">
                                <label class="col-form-label mb-0 col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 text-md-end">Patient Balance </label>
                                <div class="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6 mb-5">
                                    <div class="input-group">
                                        <span class="input-group-addon">$</span>
                                        <input type="text" class="form-control" placeholder="Patient Balance" v-model="formSubmit.eob_patient_balance" v-on:keypress="numbersOnly">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row mb-5">
                                <label class="col-form-label mb-0 col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 text-md-end">Insurance Balance </label>
                                <div class="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6 mb-5">
                                    <div class="input-group">
                                        <span class="input-group-addon">$</span>
                                        <input type="text" class="form-control" placeholder="Insurance Amount" v-model="formSubmit.eob_insurance_balance" v-on:keypress="numbersOnly">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row mb-5">
                                <label class="col-form-label mb-0 col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 text-md-end">Adjustment Amount </label>
                                <div class="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6 mb-5">
                                    <div class="input-group">
                                        <span class="input-group-addon">$</span>
                                        <input type="text" class="form-control" placeholder="Insurance Amount" v-model="formSubmit.adjustment_amount" v-on:keypress="numbersOnly">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" @click="closemodal" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
                            <button type="button" @click="save" class="waves-effect waves-light btn btn-info">Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import submitMixins from "@/scripts/mixins/submitMixins.js";
import axios from "@/scripts/axios.js";
import permissionMixins from "@/scripts/mixins/permission.js";
import moment from 'moment';
import { mapGetters } from "vuex";
export default {
    mixins: [submitMixins, permissionMixins],
    setup: () => {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            list: [],                        
            clinic_id: null,
            formSubmit:{
                id:null,
                allowed_amount: null,
                eob_patient_balance: null,
                eob_insurance_balance: null,
                adjustment_amount: null,
            }
        };
    },
    components: {  },
    methods: {
        getList(){
            this.$store.state.loader = true;
            axios.post("/EOBbatchDetail/list",{id:this.$route.params.id})
            .then((response) => {
                this.list = response.data.data;
                this.$store.state.loader = false;
            });
        },
        editBatch(id){
            this.$store.state.loader = true;
            axios.post("/EOBbatchDetail/edit",{id:id}).then((response) => {
                this.formSubmit.id = response.data.data.id;
                this.formSubmit.allowed_amount = response.data.data.allowed_amount;
                this.formSubmit.eob_patient_balance = response.data.data.eob_patient_balance;
                this.formSubmit.eob_insurance_balance = response.data.data.eob_insurance_balance;
                this.formSubmit.adjustment_amount = response.data.data.adjustment_amount;
                this.$store.state.loader = false;
                $("#editinsuranceinfo").modal("show");
            });
        },
        numbersOnly(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        closemodal(){
            this.formSubmit.id = null;
            this.formSubmit.allowed_amount = null;
            this.formSubmit.eob_patient_balance = null;
            this.formSubmit.eob_insurance_balance = null;
            this.formSubmit.adjustment_amount = null;
        },
        save(){
            this.$store.state.loader = true;
            axios.post("/EOBbatchDetail/store",this.formSubmit).then((response) => {
                this.formSubmit.id = null;
                this.formSubmit.allowed_amount = null;
                this.formSubmit.eob_patient_balance = null;
                this.formSubmit.eob_insurance_balance = null;
                this.formSubmit.adjustment_amount = null;
                this.$store.state.loader = false;
                $("#editinsuranceinfo").modal("hide");
                this.getList();
            });
        },
    },
    mounted() {
        this.getList();
    },
    computed: {
		...mapGetters("auth", ["user"]),
  	},
    async beforeCreate() {},
    validations() {},
}
</script>