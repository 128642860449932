<template>
    <div class="content-wrapper">
        <div class="container-full">
            <div class="content-header">
                <h1 class="heading1 mt-0">ERA List  #{{$route.params.id}}</h1>
            </div>
            <div class="box">
                <div class="box-body">
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                            <div class="form-group row">                            
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    <div class="selectcontrols">
                                        <Autocomplete :max="30" @input="gettrnnumber" ref="trnautocomplete" :results="trn_list" @onSelect="select_trn" class="form-control ms-0 autosearch-list" placeholder="Trace #"></Autocomplete>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-2 col-md-2 col-lg-3 col-xl-1">
                            <a @click="filter_list" class="waves-effect waves-light btn btn-info-light mt-xs-5">Search</a>
                        </div>
                        <div class="col-12 col-sm-2 col-md-2 col-lg-3 col-xl-2">
                            <a href="javascript:void(0)"  class="waves-effect waves-light btn btn-info mt-xs-5" @click="view_ERA($route.params.id)"><i class="fa fa-eye"></i> view ERA </a>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-4 col-md-4 col-lg-6 col-xl-4">
                            <router-link :to="{ name: 'RemittanceReport' }" class="fw-500"> 
                                <a class="waves-effect waves-light btn btn-info-light mt-xs-5"> <i class="fa fa-backward" aria-hidden="true"></i> Remittance Report </a>
                            </router-link>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-6 col-xl-4">
                            <a href="javascript:void(0)"  class="waves-effect waves-light btn btn-info mt-xs-5" @click="exportCsv($route.params.id)"> Export Remittance </a>
                        </div>
                        <div v-if="csv_export_message!=null" class="text-danger">{{csv_export_message}}</div>
                    </div>
                </div>
            </div>
            <div class="box">
                <div class="box-body">
                    <div class="table-responsive">                        
                        <table class="table fs-16 table-bordered">
                            <thead>
                                <tr class="text-center">
                                    <th>No.</th>
                                    <th>Patient</th>
                                    <th>Appointment</th>
                                    <th>Ins ID #</th>
                                    <th>Billed</th>
                                    <th>Allowed</th>
                                    <th>Paid</th>
                                    <th>Pr. Resp</th>                                    
                                    <th>Adjusted</th>
                                    <th>Insurance Balance</th>
                                    <th>Patient Balance</th>
                                    <th>Positing Status</th>
                                    <th>CARC code</th>
                                </tr>
                            </thead>
                            <slot v-if="list.length!=0">
                                <slot :set="count=0"></slot>
                                <tbody>
                                    <slot v-for="(item, index) in list" :key="index">
                                        <tr class="text-center">
                                            <td>{{count+1}}</td>
                                            <td>
                                                <Popper arrow content="Click here to view patient chart" :hover="true" class="helptips cursor-pointer">
												    <a v-bind:href="'#/patient-chart/'+item.patientUniqueID" class="fs-16 fw-600 text-dark" target="_blank">{{ item.first_name }} {{ item.last_name }}</a>
											    </Popper>
                                            </td>
                                            <td>
                                                <Popper arrow content="Click here to view Appointment Details" :hover="true" class="helptips cursor-pointer">
												    <a v-bind:href="'#/patient-chart/'+item.patientUniqueID+'/edit-appointment/'+item.appointment_number" class="fs-16 fw-600 text-dark" target="_blank">{{ $filters.displayDateTimeLocal(item.appointment_date,'',false,"ddd, MM-DD-YYYY",true) }} <p>( #{{ item.appointment_number }} )</p></a>
											    </Popper>
                                            </td>
                                            <td>{{item.insuranceID}}</td>
                                            <td>{{ $filters.toUSD(item.billed) }}</td>
                                            <td>{{ $filters.toUSD(item.allowed_amount)}}</td>
                                            <td>{{$filters.toUSD(item.paid_amount)}}</td>
                                            <td>{{ $filters.toUSD(item.patient_responsibility_amount)}}</td>
                                            <td>{{ $filters.toUSD(item.adjustment_amount) }}</td>                                            
                                            <td>{{ $filters.toUSD((item.billed - (item.paid_amount + item.patient_responsibility_amount + item.adjustment_amount) )) }}</td>
                                            <td>{{ $filters.toUSD((item.patient_balance_amount-item.copay_coinsurance_received)) }}</td>
                                            <td>{{item.posting_status}}</td>
                                            <td>
                                                <p><b>{{item.adjustment_group_code}}</b></p>
                                                <p><b>{{item.adjustment_reason_code}}</b></p>
                                                <Popper arrow :hover="true" class="helptips cursor-pointer">
                                                    <i class="fa fa-info-circle" aria-hidden="true"></i>
                                                    <template #content>
                                                        <div v-html="getCARcode(item.adjustment_group_code,item.adjustment_reason_code)"></div>
                                                    </template>	
                                                </Popper>                                                
                                            </td>
                                            <!-- <td>
                                                <a href="#" @click="postingModal(item.posting_status,item.id)">
                                                    <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                                                </a>
                                            </td> -->
                                        </tr>
                                        <slot :set="count++"></slot>
                                    </slot>                                    
                                </tbody>
                            </slot>
                            <tbody v-if="list.length==0">
                                <tr>
                                    <td colspan="11" class="text-center"> No data available</td>
                                </tr>
                            </tbody>
                        </table>
                    </div> 
                </div>
            </div>
            <div class="modal" tabindex="-1" id="viewERAModal">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h3 class="modal-title font-bold m-0" id="claim-data-head">
                                View ERA
                            </h3>
                            <button type="button" class="btn-close btn-xs" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body" id="uploadEOBModalBody">
                            <div id="sourceofERA" class="bg-light p-10 b-1 rounded5" style="background:#f2f2f5">
                                <div v-for="item in this.getERAarray" :key="item.id">
                                    <p class="mb-1 fs-12 font-weight-bold" v-if="item!=''" style="font-size:15px">{{item}}~</p>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-info" data-bs-dismiss="modal" aria-label="Close">Close</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal" tabindex="-1" id="postingModal">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h3 class="modal-title font-bold m-0" id="posting-data-head">
                                Update Posting
                            </h3>
                            <button type="button" class="btn-close btn-xs" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <select-box-static title="Posting Status" divClass="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" labelClass="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" v-model="posting_status" :option="postingStatus"></select-box-static>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-info" @click="updatePosting">Save</button>
                            <button type="button" class="btn btn-info" data-bs-dismiss="modal" aria-label="Close">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import axios from "@/scripts/axios.js";
import permissionMixins from "@/scripts/mixins/permission.js";
import submitMixins from "@/scripts/mixins/submitMixins.js";
import Popper from "vue3-popper";
import Autocomplete from 'vue3-autocomplete';
import SelectBoxStatic from "@/components/base/formFields/SelectBoxStatic.vue";
export default {
    mixins: [permissionMixins,submitMixins],
    components: {Popper,Autocomplete, SelectBoxStatic},
    data() {
        return {
            trn_list:[],
            trace_number:null,
            list:[],
            claim_adjustment_codes:[],
            claim_adjustment_group_codes:[],
            getERAarray:[],
            claim_id: null,
            posting_status: "Pending",
            postingStatus: [
                { value : "Pending", title : "Pending" },
                { value : "Paid", title : "Paid" },
                { value : "Denied", title : "Denied" },
            ],
            csv_export_message: null,
        }
    },
    methods: {
        updatePosting(){
            $("#postingModal").modal('hide');
            axios.post("/claim/postingUpdate",{ claim_id: this.claim_id, posting_status: this.posting_status }).then(response => {
                this.moshaToast("Posting Updated Successfully.", "success");
            });            
        },
        postingModal(posting_status,claim_id){
            this.posting_status = posting_status;
            this.claim_id = claim_id;
            $("#postingModal").modal('show');
        },
        getCARcode(claim_adjustment_group_codes,claim_adjustment_codes){
            var claim_adjustment_codes = claim_adjustment_codes.split(",");
            var claim_adjustment_group_codes = claim_adjustment_group_codes.split(",");
            var str = '';
            var $this = this;
            claim_adjustment_group_codes.forEach(element => {
                str = str + '<hr>' + element + ' : ' + $this.claim_adjustment_group_codes[element];
            });
            claim_adjustment_codes.forEach(ele => {
                var num = parseInt(ele);
                str = str + '<hr>' + ele + ' : ' + $this.claim_adjustment_codes[num];
            });
            return str.slice(4);
        },
        dateFormated(dt) {
            if(dt!=null && dt!=''){
                return moment(dt).format("MM/DD/YYYY");
            }else{
                return '-';
            }
        },
        gettrnnumber(obj){
            axios.get("/json_autocomplete/get-trn-number",{
                params: {term: obj}
            }).then(response => { 
                this.trn_list = response.data.data.map((code) => {
                    return { id: code.remit_trace_number, name: code.remit_trace_number }
                });
            });
        },
        select_trn(args){
            this.$refs.trnautocomplete.searchText = args.name;
            this.trace_number = args.name;
        },
        getList(){
            axios.post("/reports/trace-number-details",{trace_number: this.trace_number}).then(response => { 
                this.list = response.data.data.result
                this.claim_adjustment_codes = response.data.data.claim_adjustment_codes;
                this.claim_adjustment_group_codes = response.data.data.claim_adjustment_group_codes;
            });
        },
        // ERA start
        view_ERA(trace_number){
            $("#viewERAModal").modal("show");
            // sourceofERA
            axios.get("/getdocument/"+trace_number)
                .then((response) => {
                    let getcontent=response.data;
                    let arr=getcontent.split("~");
                    let data=[];
                    arr.forEach(element => {
                        data.push(element.replaceAll("|","*"));
                    });
                        let ST_POS= data.findIndex(element => element.includes("ST"));
                        let SE_POS= data.findIndex(element => element.includes("SE*"));
                        let length=SE_POS-ST_POS;
                    for (let index = ST_POS; index <=SE_POS; index++) {
                        this.getERAarray.push(data[index]);                    
                    }
                });
        },
        returnStr(){

        },
        exportCsv(id){
            this.$store.state.loader = true;
            axios.post("/reports/remit-details-export",{ trace_number: id })
            .then((response) => {
                this.csv_export_message = "Please find your file in Download Logs Section.";
                this.$store.state.loader = false;
                setTimeout(() => {
                    this.csv_export_message = null;
                }, 8000);
            });
        }
    },
    mounted() {
        this.$refs.trnautocomplete.searchText = this.$route.params.id;
        this.trace_number = this.$route.params.id;        
        this.getList();
    },
    async beforeCreate() {},
}
</script>

<style>

</style>