<template>
    <div class="web-camera-container">
        <div class="camera-button">
           <button type="button" class="btn btn-secondary is-rounded" :class="{ 'is-primary' : !isCameraOpen, 'is-danger' : isCameraOpen}" @click="toggleCamera">
            <span v-if="!isCameraOpen">{{btnname}}</span>
              <span v-else>Close Camera</span>
            </button>
        </div>
              
        <div v-show="isCameraOpen && isLoading" class="camera-loading">
            <ul class="loader-circle">
                <li></li>
                <li></li>
                <li></li>
            </ul>
        </div>
              
        <div v-if="isCameraOpen" v-show="!isLoading" class="camera-box" :class="{ 'flash' : isShotPhoto }">
            <div class="camera-shutter" :class="{'flash' : isShotPhoto}"></div>
            <video v-show="!isPhotoTaken" ref="camera" id="video_player" style="width:100%;"  autoplay></video>                       
            <canvas v-show="isPhotoTaken" id="photoTaken" ref="canvas"></canvas>
        </div>
            
        <div v-if="isCameraOpen && !isLoading" class="camera-shoot">
            <button type="button" class="button" @click="takePhoto">
              <img src="https://img.icons8.com/material-outlined/50/000000/camera--v2.png">
            </button>
        </div>
        <div v-if="isCameraOpen && !isLoading">
            <button type="button" class="btn" @click="camera('environment')">Back Camera</button>
            <button type="button" class="btn" @click="camera('user')">Front Camera</button>
        </div>
              
        <div v-if="isPhotoTaken && isCameraOpen" class="camera-download">
            <a id="downloadPhoto" download="my-photo.jpg" class="button" role="button" @click="downloadImage">Download</a>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            btnname: {
                type: String,
                default: "Open camera"
            },
            imgname:{
                type: String,
                default: null,  
            }
        },
        data() {
            return {
                isCameraOpen: false,
                isPhotoTaken: false,
                isShotPhoto: false,
                isLoading: false,
                link: '#',
                front: true
            }
        },
        mounted(){
            
        },
        methods: {
            toggleCamera() {
                if(this.isCameraOpen) {
                    this.isCameraOpen = false;
                    this.isPhotoTaken = false;
                    this.isShotPhoto = false;
                    this.stop();
                } 
                else {
                    this.isCameraOpen = true;
                    setTimeout(() => {
                        this.createCameraElement();
                    }, 1000);
                }
            },
            camera(face) {
                /*this.stop();*/
                
                this.gum(face);
            },
            stop() {
                return document.getElementById('video_player').srcObject && document.getElementById('video_player').srcObject.getTracks().map(t => t.stop());
            },
            gum(face) {
                if(face === 'user') {
                    return navigator.mediaDevices.getUserMedia({video: {
                        facingMode: face,
                        width: { min: 1024, ideal: 1280, max: 1920 },
                        height: { min: 576, ideal: 720, max: 1080 }, 

                    }} )
                    .then(stream => {
                        document.getElementById('video_player').srcObject = stream;
                    }).catch(error => {
                         alert("Pictures can not uploaded using camera.");
                    });
                }
                if(face === 'environment') {
                    
                    return navigator.mediaDevices.getUserMedia({video: {
                        facingMode: {exact: face},
                        width: { min: 1024, ideal: 1280, max: 1920 },
                        height: { min: 576, ideal: 720, max: 1080 }, 
                    }})
                    .then(stream => {
                        document.getElementById('video_player').srcObject = stream;
                    }).catch(error => {
                         alert("Pictures can not uploaded using camera.");
                    });
                }
            },

            createCameraElement() {
                this.camera('environment');

                var isiPhone = /iphone/i.test(navigator.userAgent.toLowerCase());
                if (isiPhone){
                    var myVideo = document.getElementsByTagName('video')[0];
                    myVideo.addEventListener('webkitendfullscreen', () => {
                        alert("The image you clicked isn't saved at this point, you need to tap on the camera symbol underneath to continue.");
                    },false);
                }
                // this.isLoading = true;      
                // const constraints = (window.constraints = {
                //     audio: false,
                //     video: { 
                //         facingMode: "environment"
                //     }
                // });

                // navigator.mediaDevices.getUserMedia(constraints).then(stream => {
                //     this.isLoading = false;
                //     this.$refs.camera.srcObject = stream;
                // }).catch(error => {
                //     this.isLoading = false;
                //     alert("Pictures can only be uploaded using rear camera.");
                // });
                // console.log(this.$refs.camera);
            },
    
            stopCameraStream() {
                let tracks = this.$refs.camera.srcObject.getTracks();
                tracks.forEach(track => {
                    track.stop();
                });
            },
    
            takePhoto() {
               
                if(!this.isPhotoTaken) {
                    this.isShotPhoto = true;
                    const FLASH_TIMEOUT = 50;
                    setTimeout(() => {
                        this.isShotPhoto = false;
                    }, FLASH_TIMEOUT);
                }
                this.isPhotoTaken = !this.isPhotoTaken;
                const context = this.$refs.canvas.getContext('2d');
                // console.log(context);
                console.log(this.$refs.camera.videoWidth);
                console.log(this.$refs.camera.videoHeight);
                context.canvas.width = this.$refs.camera.videoWidth;
                context.canvas.height = this.$refs.camera.videoHeight;
                context.drawImage(this.$refs.camera, 0, 0);
                const canvas = document.getElementById("photoTaken").toDataURL("image/jpeg");
                
               if( context.canvas.width >0 && context.canvas.height>0){
                this.$store.state.TeleHealthRegistration.form[this.imgname] =  canvas;
               }
               
                // console.log(this.imgname);
              
                this.toggleCamera();
            },
    
            downloadImage() {
                const download = document.getElementById("downloadPhoto");
                const canvas = document.getElementById("photoTaken").toDataURL("image/jpeg")
                .replace("image/jpeg", "image/octet-stream");
                console.log(download);
                console.log(canvas);
                download.setAttribute("href", canvas);
            }
        }
    }
</script>

<style>
    .web-camera-container {padding: 10px;display: flex;flex-direction: column;justify-content: center;align-items: center;border: 1px solid #e4e6ef;border-radius: 4px;background: #f1f3fe;}
    .web-camera-container .camera-button {}
    .web-camera-container .camera-box .camera-shutter {width: 100%;background-color: #fff; height: auto; }
    .web-camera-container .camera-box .camera-shutter.flash {opacity: 1;}
    .web-camera-container .camera-shoot {margin: 10px 0 0;}
    .web-camera-container .camera-shoot button{    height: 45px;
    width: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20%;
    border: 2px solid #e7e9f6;
    background: #fff;}
    .web-camera-container .camera-shoot button img {height: 30px;object-fit: cover;}
    .web-camera-container .camera-loading {overflow: hidden;height: 100%;position: absolute;width: 100%;min-height: 150px;margin: 3rem 0 0 -1.2rem;}
    .web-camera-container .camera-loading ul {height: 100%;position: absolute;width: 100%;z-index: 999999;margin: 0;}
    .web-camera-container .camera-loading .loader-circle {display: block;height: 14px;margin: 0 auto;top: 50%;left: 100%;transform: translateY(-50%);transform: translateX(-50%);position: absolute;width: 100%;padding: 0;}
    .web-camera-container .camera-loading .loader-circle li {display: block;float: left;width: 10px;height: 10px;line-height: 10px;padding: 0;position: relative;margin: 0 0 0 4px; background: #999;animation: preload 1s infinite;top: -50%;border-radius: 100%;}
    .web-camera-container .camera-loading .loader-circle li:nth-child(2) {animation-delay: 0.2s;}
    .web-camera-container .camera-loading .loader-circle li:nth-child(3) {animation-delay: 0.4s;}
    @keyframes preload {
        0% {
            opacity: 1;
        }
        50% {
            opacity: 0.4;
        }
        100% {
            opacity: 1;
        }
    }
</style>
