<template>
    <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-10">
        <div class="box mb-10">
            <div class="box-body">
                <div class="pageheading">
                    <h2 class="heading2 font-bold">Appointment Logs</h2>
                </div>
                <div class="table-responsive">
            <table class="table table-bordered mb-0">
                <thead>
                    <tr>
                        <th style="min-width:30px;">No</th>
                        <th style="min-width:50px">Changed by</th>
                        <th style="min-width:50px">Operation</th>
                        <th style="min-width:120px;">Details</th>
                        <th style="min-width:50px">Log Updated At</th>
                        <th style="min-width:100px">IP Address</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        class="hover-primary"
                        v-for="(item, index) in auditlog"
                        :key="index"
                    >
                        <td class="align-top">{{index+1}}</td>
                        <td class="align-top">{{ user_list[item.user_id] }}</td>
                        <td class="align-top">{{ item.action }}</td>
                        <td class="word-break-all align-top" v-html="item.detail"></td>
                        <td class="align-top" :data-order='item.updated_at_timestamp'>{{$filters.displayDateTimeLocal(item.updated_at, "MM-DD-Y, h:mm A") }}</td>
                        <td class="align-top">{{ item.ip }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="text-center mt-2">
            <button @click="close" class="waves-effect waves-light btn btn-danger mb-5 me-1">Close</button>
        </div>
            </div>
        </div>
    </div>
</template>
<script>
import "datatables.net-dt/js/dataTables.dataTables";
import FormBtn from "@/components/base/formFields/formBtn.vue";
import axios from "@/scripts/axios.js";
import submitMixins from "@/scripts/mixins/submitMixins.js";
import moment from "moment";
export default {
     mixins: [submitMixins],
    components: {
		    FormBtn,    
		
	    },
    data(){
        return{
            auditlog:'',
            user_list: [],
        }
    },
    mounted() {
         this.$store.state.loader = true;
        axios.post("appointment/audit-logs",{appointment_id:this.$route.params.appointments_id,type:"web"})
            .then((response) => {
                 this.$store.state.loader = false;
                this.auditlog=response.data.data.audit_log;
                this.user_list = response.data.data.user_list;
            });
    },
    methods:{
        close(){
            this.$router.push(`/patient-chart/${this.$route.params.id}/appointments/`); 
        },
    }
}
</script>