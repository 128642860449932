<template>
  <div class="content-wrapper">
    <div class="container-full">
      <section class="content">
        <component :is="$store.state.AppointmentProfileHomeComp"></component>
      </section>
    </div>
  </div>
</template>
<script>
import AppointmentProfile from "@/components/CustomProcedures/Home.vue";

export default {
    
    components: {AppointmentProfile},
    data() {
        return {
        };
    },
};
</script>
