<template>
   <div class="tab-pane" id="lockedclinical" role="tabpanel">
      <div class="authform-modal box" v-if="permissioncheck(this.$store.state.permission.create_update)">
         <div class="pageheading">
            <h2 class="heading2">Consent Forms <a href="#" data-bs-toggle="modal" data-bs-target="#formdocument" title="Reference of Knowledge Base" v-on:click.prevent="reset()"> <i class="fa fa-question-circle fs-20"></i></a> </h2>
         </div>
         <div class="table-responsive" id="listpdf">
            <table-base :dataTypes="{dataType: 'unsignedConsentForms', patient_id: patient_id,patient_unique_id:patient_unique_id,method:'get'}">
               <template v-slot="slotProps">
                  <td>
                     <ol class="mb-1">
                        <li v-for="(form,index) in slotProps.item.form" :key="index">
                           {{form.title}}
                        </li>
                     </ol>
                  </td>
                  <td>{{ $filters.displayDateTimeLocal(slotProps.item.schedule_date+' '+slotProps.item.schedule_time,'','MMM D, YYYY hh:mm A') }} </td>
                  <td>
                     <button type="button" class="waves-effect btn btn-info me-1" data-bs-toggle="modal" data-bs-target="#myConsentModal" @click="getform(slotProps.item.form,slotProps.item.consent_form_ids,slotProps.item.id)">Sign Consent Form</button>
                  </td>
               </template>
            </table-base>
         </div>
      </div>
      <!--document model start-->
      <div id="formdocument" class="modal fade" tabindex="-1" role="dialog"
         aria-labelledby="myModalLabel" aria-hidden="true">
         <div class="modal-dialog modal-md">
            <div class="modal-content">
               <div class="modal-header">
                  <h3 class="modal-title fw-900 mt-0">Reference of Knowledge Base</h3>
               </div>
               <div class="modal-body overflow-auto" style="max-height: 350px;">
                  <table class="table table-bordered">
                     <thead>
                        <tr>
                           <th scope="col">No.</th>
                           <th scope="col">Knowledge Base Articles</th>
                        </tr>
                     </thead>
                     <tbody>
                        <tr>
                           <td>1</td>
                           <td> <a :href="path+'how-to-check-the-consent-form-status'" target="_blank">How To Check The Consent Form Status?</a></td>
                        </tr>
                        <tr>
                           <td>2</td>
                           <td> <a :href="path+'how-to-sign-a-consent-form'" target="_blank">How To Sign A Consent Form?</a></td>
                        </tr>
                        <tr>
                           <td>3</td>
                           <td> <a :href="path+'how-to-view-the-signed-consent-form'" target="_blank">How To View The Signed Consent Form?</a></td>
                        </tr>
                        <tr>
                           <td>4</td>
                           <td> <a :href="path+'how-to-send-consent-form-to-pureportal'" target="_blank">How To Send Consent Form To PurePORTAL?</a></td>
                        </tr>
                     </tbody>
                  </table>
                  <Search :key="searchKey"></Search>
               </div>
               <div class="modal-footer">
                  <button type="button" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
               </div>
            </div>
         </div>
      </div>
	   <!--document model end-->
      <!--model start-->
      <div id="myConsentModal" class=" myConsentModal modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
         <div class="modal-dialog modal-xl">
            <div class="modal-content">
               <div class="modal-header">
                  <h3 class="modal-title fw-900 mt-0">Please read and give your consents</h3>
               </div>
               <div class="modal-body">
                  <div class="tab-pane animation-fade" id="category-2" role="tabpanel">
                     <div class="panel-group panel-group-simple panel-group-continuous mb-0" id="accordion" aria-multiselectable="true" role="tablist">
                        <div v-for="(item, index) in formlist" :key="index">
                           <div class="panel pagecollap bb-1">
                              <div class="panel-heading" v-bind:id="'question-'+index" role="tab">
                                 <a class="panel-title collapsed" v-bind:aria-controls="'answer-'+index" aria-expanded="false" data-bs-toggle="collapse" v-bind:href="'#answer-'+index" data-parent="#accordion">
                                    {{item.title}}
                                 </a>
                              </div>
                              <div class="panel-collapse collapse" v-bind:id="'answer-'+index" v-bind:aria-labelledby="'question-'+index" role="tabpanel" data-bs-parent="#category-2">
                                    <div class="panel-collapse collapse" v-bind:id="'answer-'+index" v-bind:aria-labelledby="'question-'+index" role="tabpanel" data-bs-parent="#category-2">
                                       <template v-if="embed=0">
                                          <vue-pdf-viewer height="1050px" :url="item.document_name_url"></vue-pdf-viewer>
                                       </template>
                                       <template v-else>
                                          <iframe :src="item.document_name_url" height="1050px" width="100%" />
                                       </template>
                                       <div class="form-check">
                                         <input class="filled-in chk-col-info" type="checkbox" value="" v-bind:id="'CheckDefault-'+index">
                                         <label class="form-check-label" v-bind:for="'CheckDefault-'+index">I have read and given my consent.</label>
                                       </div>
                                       <div v-if="this.formlength == index">
                                          <center>
                                             <span class="me-100">Please sign here</span>
                                             <a href="javascript:void(0)" @click="clear" class="btn btn-danger mb-5">Clear</a>
                                             <VueSignaturePad ref="signaturePad" style="border:solid 1px #000000;width: 300px;height: 150px;" :options="{onBegin: () => {$refs.signaturePad.resizeCanvas()}}" />
                                          </center>
                                       </div>
                                    </div>
                                 
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="modal-footer">
                  <button type="button" @click="save" class="waves-effect waves-light btn btn-info">Save</button>
                  <button type="button" @click="close" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
               </div>
            </div>
         </div>
      </div>
      <!--model end-->

      <div class="authform-modal box">
         <div class="pageheading">
            <h2 class="heading2">Signed Consent Forms</h2>
         </div>
         <div class="table-responsive">
            <table-base 
               :dataTypes="{
                  dataType: 'SignedConsentForms', 
                  patient_id: patient_id,
                  patient_unique_id:patient_unique_id,
                  method:'get'}"
               :lastcol='permissioncheck(this.$store.state.permission.create_update)'
               >
               <template v-slot="slotProps">
                  <td>{{ capitalizeFirstLetter(slotProps.item.title) }}</td>
                  <td>{{ $filters.displayDateTimeLocal(slotProps.item.schedule_date+' '+slotProps.item.schedule_time,'','MMM D, YYYY hh:mm A') }} </td>
                  <td>{{$filters.displayDateTimeLocal(slotProps.item.created_at)}}</td>
                  <td>
                     <div class="d-flex" v-if="permissioncheck(this.$store.state.permission.create_update)">
                        <Popper arrow content="View Consent Form" :hover="true" class="helptips cursor-pointer">
                           <a data-bs-toggle="modal" data-bs-target="#SignConsentModal" @click="signform(slotProps.item.form,slotProps.item.consent_form_ids,slotProps.item.id,slotProps.item.patient_sign)" href="#" class="waves-effect waves-circle btn btn-circle btn-info btn-xs" ><i class="fa fa-eye"></i></a>
                        </Popper>
                        <Popper arrow content="Send to Patient Portal" :hover="true" class="helptips cursor-pointer" >
                           <a href="#" class="waves-effect waves-circle btn btn-circle btn-info btn-xs" @click="sendform(slotProps.item.consent_form_ids,slotProps.item.id)"
                           ><i class="fa fa-send"></i></a>
                        </Popper>
                    </div>
                  </td>
               </template>
            </table-base>
         </div>
      </div>

      <!--model start-->
      <div id="SignConsentModal" class=" SignConsentModal modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
         <div class="modal-dialog modal-xl">
            <div class="modal-content">
               <div class="modal-header">
                  <h3 class="modal-title fw-900 mt-0">Sign Consents Forms</h3>
               </div>
               <div class="modal-body">
                  <div class="tab-pane animation-fade" id="category-2" role="tabpanel">
                     <div class="panel-group panel-group-simple panel-group-continuous mb-0" id="accordion" aria-multiselectable="true" role="tablist">
                        <div v-for="(item, index) in formlist2" :key="index">
                           <div class="panel pagecollap bb-1">
                              <div class="panel-heading" v-bind:id="'question-'+index" role="tab">
                                 <a class="panel-title collapsed" v-bind:aria-controls="'answer-'+index" aria-expanded="false" data-bs-toggle="collapse" v-bind:href="'#answer-'+index" data-parent="#accordion">
                                    {{item.title}}
                                 </a>
                              </div>
                              <div class="panel-collapse collapse" v-bind:id="'answer-'+index" v-bind:aria-labelledby="'question-'+index" role="tabpanel" data-bs-parent="#category-2">
                                    <div class="panel-collapse collapse" v-bind:id="'answer-'+index" v-bind:aria-labelledby="'question-'+index" role="tabpanel" data-bs-parent="#category-2">
                                       <template v-if="embed=0">
                                          <vue-pdf-viewer height="1050px" :url="item.document_name_url"></vue-pdf-viewer>
                                       </template>
                                       <template v-else>
                                          <iframe :src="item.document_name_url" height="1050px" width="100%" />
                                       </template>
                                       
                                    </div>
                                    <div v-if="this.formlength2 == index">
                                       <h3><b>Patient Signature</b></h3>
                                       <center>
                                          <img :src="this.patient_sign2">
                                       </center>
                                    </div>
                                    
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="modal-footer">
                  <button type="button" @click="close" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
               </div>
            </div>
         </div>
      </div>
      <!--model end-->

   </div>
</template>
<script>
import TableBase from "@/components/base/Table.vue";
import moment from "moment";
import VuePDFViewer from 'vue-instant-pdf-viewer';
import CheckBox from "@/components/base/formFields/checkBox.vue";
import submitMixins from "@/scripts/mixins/submitMixins.js";
import permissionMixins from "@/scripts/mixins/permission.js";
import axios from "@/scripts/axios.js";
import PreviewPdf from '@/components/patients/details/ClinicalNote/PDF.vue';
import Popper from "vue3-popper";
import Search from "@/components/Search.vue";

export default {
   mixins: [submitMixins,permissionMixins],
   components: { TableBase,CheckBox,'vue-pdf-viewer': VuePDFViewer, Popper, Search},
   data() 
   {
      return {
         read:{},
         sign: '',
         formlist:{},
         formlist2:{},
         formlength:'',
         formlength2:'',
         patient_id : this.$store.state.patientDetail.patient_id,
         patient_unique_id:this.$store.state.patientDetail.patient_unique_id,
         patient_sign2:'',
         from:{
            form_id:'',
            appointment_id:'',
            patient_sign:'',
         },
         embed:'',
         path: process.env.VUE_APP_KB_DOCUMENT,
         searchKey:0,
      };
   },
   methods: {
      reset() {
         this.searchKey = this.searchKey + 1;
      },
      clear(){
         this.$refs.signaturePad.clearSignature();
      },
      save(){
         const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
         this.from.patient_sign = data;
         console.log(this.from.patient_sign);

         for (var i = this.formlength; i >= 0; i--) {
            var CheckBox = $('#CheckDefault-'+i).is(":checked")
            if(CheckBox == false){
               this.moshaToast('You have not accepted all the consent forms.','danger');
               return false;
            }
         }
         if(isEmpty == true){
            this.moshaToast('You have not signed consent forms.','danger');
            return false;
         }            
         var thisObj = this;
         axios.post('patient/'+this.$route.params.id+'/documents/signed-consent-form/store',this.from)
         .then(response => {
            this.moshaToast('Record updated successfully.','success'),
            this.from = {}
            this.$refs.signaturePad.clearSignature();
            $('.myConsentModal').modal('toggle');
            this.formlist = '';
            this.formlength = '';
            this.from.form_id = '';
            this.from.appointment_id = '';
            this.$store.dispatch("getSimpleTableContents", {dataType: 'SignedConsentForms', patient_id: this.$store.state.patientDetail.patient_id,patient_unique_id:this.$store.state.patientDetail.patient_unique_id,method:'get'});
            this.$store.dispatch("getSimpleTableContents", {dataType: 'unsignedConsentForms', patient_id: this.$store.state.patientDetail.patient_id,patient_unique_id:this.$store.state.patientDetail.patient_unique_id,method:'get'});
            //this.v$.$reset();
          });
      },
      close(){
         this.$refs.signaturePad.clearSignature();
         this.formlist = '';
         this.formlength = '';
         this.from.form_id = '';
         this.from.appointment_id = '';
         this.formlist2 = "";
         this.patient_sign2 = "";
         this.formlength2 = "";
         this.from.form_id = "";
         this.from.appointment_id = "";
      },
      getform(form,consent_form_ids,id){
         this.formlist = form;
         this.formlength = form.length - 1;
         this.from.form_id = consent_form_ids;
         this.from.appointment_id = id;
         for (var i = form.length - 1; i >= 0; i--) {
            this.read = i
         }
         console.log(this.read);
      },
      signform(form,consent_form_ids,id,patient_sign){
         this.formlist2 = form;
         this.patient_sign2 = patient_sign;
         this.formlength2 = form.length - 1;
         this.from.form_id = consent_form_ids;
         this.from.appointment_id = id;
         for (var i = form.length - 1; i >= 0; i--) {
            this.read = i
         }
         axios.post('patient/'+this.$route.params.id+'/documents/signed-consent-form/view',this.from)
         .then(response => {
            
          });
      },
      sendform(consent_form_ids,id){
         axios.post("patient-portal/form-permission",{patient_id:this.$store.state.patientDetail.patient_id,consentstatus:consent_form_ids.toString(),id:id})
				.then((response) =>{
					this.moshaToast("Consent Form Sent Successfully.", "success");
				})
				.catch((error) => {
          			this.moshaToast(error.response.data.message, "danger");
        		});
      },
      
      capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      }
   },
   beforeCreate(){
      // Opera 8.0+
      var isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

      // Firefox 1.0+
      var isFirefox = typeof InstallTrigger !== 'undefined';

      // Safari 3.0+ "[object HTMLElementConstructor]" 
      var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));

      // Internet Explorer 6-11
      var isIE = /*@cc_on!@*/false || !!document.documentMode;

      // Edge 20+
      var isEdge = !isIE && !!window.StyleMedia;

      // Chrome 1 - 79
      var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

      // Edge (based on chromium) detection
      var isEdgeChromium = isChrome && (navigator.userAgent.indexOf("Edg") != -1);

      // Blink engine detection
      var isBlink = (isChrome || isOpera) && !!window.CSS;

      if(isChrome==true)
      {
          this.embed = 0;
      }else{
          this.embed = 1;
      }
   }
};
</script>