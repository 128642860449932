<template>       
    <!-- <h2>Clinical Notes</h2> -->
    <div class="box p-15">
        <div class="mdc-tab">
            <ul class="nav nav-tabs mt-0 text-center text-sm-start" role="tablist">
                <!-- <li class="nav-item"> <a class="nav-link" data-bs-toggle="tab" href="#handp" @click.prevent="changeTab('ClinicalNoteHandP')" role="tab">H&P</a> </li>
                <li class="nav-item"> <a class="nav-link" data-bs-toggle="tab" href="#soap" role="tab" @click.prevent="changeTab('ClinicalNoteSoap')">SOAP</a> </li> -->
                <li class="nav-item"> <a class="nav-link active" :class="{'active' : activeDiv() }" data-bs-toggle="tab" href="#additionalnotes" role="tab" @click.prevent="changeTab('ClinicalNoteAdditional')">Custom Notes</a> </li>
                <li class="nav-item"> <a class="nav-link" data-bs-toggle="tab" href="#amendments" role="tab" @click.prevent="changeTab('PatientAmendments')">Amendments</a> </li>
                <li class="nav-item"> <a class="nav-link" data-bs-toggle="tab" href="#lockedNote" role="tab" @click.prevent="changeTab('PatientLockedClinicalNotes')">Locked Clinical Notes</a> </li>
            </ul>
            <div class="tab-content">
                <div class="tab-pane active" id="additional" role="tabpanel">
                    <div class="box bg-lightest p-10 my-2">
                        <div class="form-group row mb-0">
                            <label class="col-form-label col-12 col-sm-3 col-md-3 col-lg-2 col-xl-2 col-form-label mb-0 text-sm-end">Appointment <small class="text-danger asterisksign" style="display: none;">*</small></label>
                            <div class="col-12 col-sm-9 col-md-9 col-lg-7 col-xl-5">
                                <select class="select2Single form-select form-control mb-1" v-model="appointment_id" id="appointment_id"  @change="changeAppointment($event)">
                                    <!-- <option v-for="(item,index) in appointmentData" :value="item.id" :data-val="item.appointment_number">{{item.name}}</option> -->
                                    <template  v-for="(item,index) in appointmentData"  :key="item.id">
                                        <option :value="item.id" v-if="item.imported_at == null " :data-val="item.appointment_number" data-type="system">
                                            {{item.name}}
                                        </option>
                                    </template>
                                    <optgroup label="Imported Appointment">
                                        <template  v-for="(item,index) in appointmentData"  :key="item.id">
                                          <option :value="item.id" v-if="item.imported_at != null " :data-val="item.appointment_number" data-type="imported">
                                            {{ item.name }}
                                          </option>
                                        </template>
                                    </optgroup>
                                </select>
                            </div>
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-5 text-sm-end">
                                <span v-if="note_lock_status==true" class="badge bg-success  border-dark p-8 rounded5 fs-16 me-1 mb-1" style="border-radius: 5px;"><i class="fa fa-lock fs-14"></i> Locked</span>
                                <button v-if="note_sign_and_lock_rights==1" @click="sign_lock" type="button" class="waves-effect waves-light btn btn-info mb-1 me-1"><i class="fa fa-sticky-note-o fs-14"></i> {{sign_lock_label}} </button>

                                <button type="button" id="previewNote" v-if="notePreview" class="waves-effect waves-light btn btn-info-light mb-1 me-1" @click="PreviewNote"><i class="fa fa-file-pdf-o fs-14"></i> Preview Note</button>
                            </div>
                        </div>
                        <label v-if="note_msg!=''" v-html="note_msg"></label>
                        <label v-if="tempMsg!=''" v-html="tempMsg"></label>
                    </div>
                    <div class="tab-pane animation-fade active" id="category-1" role="tabpanel" v-if="appointment_id">
                        <div class="panel-group panel-group-simple panel-group-continuous mb-0" id="accordion2" aria-multiselectable="true" role="tablist">
                            <div v-for="index in notes" class="panel pagecollap">
                                <div class="panel-heading" :id="index" role="tab">
                                    <a class="panel-title" :aria-controls="'answer-'+index" aria-expanded="false" title="Click to open note" @click="stickButton('title'+index)" data-bs-toggle="collapse" :href="'#answer-'+index" :id="'title'+index" data-parent="#accordion2">Loading...</a>
                                </div>
                                <div class="panel-collapse collapse" :id="'answer-'+index" :aria-labelledby="index" role="tabpanel" data-bs-parent="#category-1">
                                    <div class="panel-body clinical_notes_form" >
                                        <div class="extra_part">
                                            <p class="text-end mt-10" v-if="activate_section=='1'"><p v-if="noteData[index].template_detail_data['is_included']!==null"><b>Include in Note: </b><Toggle :id="'toggle'+index" :name="'include'+index"  v-model="noteData[index].template_detail_data['is_included']" :value="noteData[index].template_detail_data['is_included']"  on-label="On" off-label="Off" @change="noteActivate($event,index)" /></p></p>
                                            <icd-code-table :dataId="appointment_id" v-if="icd10PartVisible == index"></icd-code-table>
                                        </div>
                                        <div v-bind:id="'notes'+index">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>
<script>
/*Ref. taken from :- https://github.com/vueform/toggle*/
import Toggle from '@vueform/toggle';
import Popper from "vue3-popper";
import { mapGetters } from "vuex";
import SelectBox from "@/components/base/formFields/selectBox.vue";
import IcdCodeTable from "../icd10Code.vue";
import axios from "@/scripts/axios.js";
import $ from 'jquery';
import submitMixins from "@/scripts/mixins/submitMixins.js";
import ClinicalNotePreview from '@/components/patients/details/ClinicalNote/PDF.vue';
import PatientLockedClinicalNotes from "@/components/patients/details/Documents/LockedClinicalNotes.vue";

export default {
components: { 
    Popper,
    SelectBox, 
    IcdCodeTable,
    ClinicalNotePreview,
    PatientLockedClinicalNotes,
    Toggle
},
mixins: [submitMixins],
data() {
    return {
        formDataToStore: [],
		axiosChange: 0,
        activate_section: 0,
        is_include: 1,
        switchValue: true,
        hash: '',
        appointmentData: '',
        notePreview: '',
        notes: '',
        icd10PartVisible: '',
        patient_id: this.$store.state.patientDetail.patient_id,
        patient_unique_id: this.$store.state.patientDetail.patient_unique_id,
        appointment_id: '',
        note_lock_status: false,
        note_msg: '',
        dataSave: false,
        is_supervisor_sign_required: false,
        sign_lock_label : 'Sign',
        provider_id_for_sign: '',
        supervisor_id_for_sign: '',
        note_sign_and_lock_rights: 0,
        tempMsg: '',
        noteData: [{
            template_detail_data: [{
                id: '',
                masterId : '',
                template_id: '',
                name: '',
                category: '',
                patient_id: '',
                appointment_id: '',
                note_status: '',
                is_included : '',
                patient_clinical_note_version : '',
            }],
            components: [],
        }],
        data: {},
    };
},
async created() {
    window.addEventListener('scroll', this.handleScroll);
},
async beforeCreate() {
    var $this = this;
    $this.$store.state.clinicalNote.active_note = 'additional';
    $this.$store.state.loader = true;
    axios
    .post('/json_dropdown/patient-appointments',{patient_id:this.$store.state.patientDetail.patient_id})
    .then((response) => {
        $this.$store.state.loader = false;
        $this.appointmentData = response.data.data,
        $this.notePreview = (response.data.data.length>0) ? 1 : '',
        $this.appointment_id = (response.data.data.length>0) ? response.data.data[0].id : '';
        $this.hash = (response.data.data.length>0) ? response.data.data[0].appointment_number : '';
        setTimeout(function(){ 
            var hash = location.href.substring(location.href.lastIndexOf('/') + 2);
            
            if(location.href.substring(location.href.lastIndexOf('/') + 1)!='Custom')
            {           
                $this.hash = hash;    
                $this.appointment_id = $("select#appointment_id option[data-val='"+hash+"']").attr("value");
            }
            $this.getNote();
        }, 100);
    })
},
methods: {
    nl2br (str, is_xhtml) {   
        var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';    
        return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1'+ breakTag +'$2');
	}, 
    macroGenerateFunction()
    {
        let self = this;
        $(document).on("click",".toggle-macro", function(){
            if($(".macros").hasClass("d-none") && $(this).hasClass("fa-toggle-down"))
            {
                $(".toggle-macro").removeClass("fa-toggle-up").addClass("fa-toggle-down");
                var textArea = $(this).parent().next().find("textarea");
                $(".macros").addClass("d-none");
                setTimeout(function(){
                    textArea.next("div.macros").removeClass("d-none");
                
                    textArea.next(".macros").attr("id","macros-"+textArea.attr("id"));
                    self.macros("macros-"+textArea.attr("id"));
                }, 100);	
                $(this).removeClass("fa-toggle-down").addClass("fa-toggle-up");
            }
            else
            {
                $("div.macros").addClass("d-none");
                $(this).removeClass("fa-toggle-up").addClass("fa-toggle-down");
            }
        });
        $(document).click(function(e) {
            if($(e.target).hasClass("copy-content") || e.target.nodeName!=='TEXTAREA')
            {
                $(".macros").addClass("d-none");
            }
        });
    },
    macros(_fieldId)
    {
        var a = typeof this.$store.state.clinicalNote.macros;			
        axios
            .get('macros-list')
            .then((response) => {
                (this.$store.state.clinicalNote.macros = response.data.data.map((macros) => {
                    return { macro_title: macros.macro_title, macro_text: macros.macro_text };
                }))
                this.axiosChange = _fieldId;
        });
    },
    macrosetup(_fieldId)
    {
        var html = "", $this = this;
        $.each(this.$store.state.clinicalNote.macros,function(index,value){
            html += "<div><a href='javascript:void(0)' class='badge badge-dark py-1 copy-content' data-id='"+index+"'>"+$this.nl2br(value.macro_title, 1)+"</a></div>";
        });
        $("#"+_fieldId).find(".panel-body").html(html);

        $(document).on("click",".copy-content",function(){

            var index = $(this).parents("div.panel-collapse.collapse.show").attr("aria-labelledby");
            var macroIndex = $(this).attr("data-id");
            if($("#"+_fieldId).hasClass("d-none") === false)
            {
                var eleId = $("#"+_fieldId).prev().attr("id"), 
                form = $this.formDataToStore[index], 
                compIdDetail = eleId.split("-"), 
                compId = compIdDetail[0];
                form.getComponentById(compId).setValue($("#"+_fieldId).prev().val()+' '+$this.$store.state.clinicalNote.macros[macroIndex].macro_text);
            }
        });
    },
    noteActivate(e, index){
        var id = this.noteData[index].template_detail_data.id; 

        this.is_include = this.is_include+1;
        if(id!==null && this.is_include>2)
        {
            axios
                .post('/clinical-note/is_include',{ patient_id:this.$store.state.patientDetail.patient_id, note_index:id, status: (e===true) ? '1' : '0' })
                .then((response) => {
                    
                });             
        }
    },
    sign_lock()
    {
        /*Blank note can't be sign validation msg*/
        var noteId = this.noteData[1].template_detail_data.masterId;
        if(noteId=='' || noteId==null)
        {
            this.tempMsg = '<span class="px-2 p-1 bg-warning-light text-dark border-warning d-inline-block rounded b-1 fs-14 l-h-n">Clinical note is empty, you can\'t sign it.</span>';
            return 0;
        }
        /*Blank note can't be sign validation msg -- End*/

        /*Supervisor sign validation*/
        if((this.supervisor_id_for_sign == this.user.user_id) && this.noteData[1].template_detail_data.note_status != 'provider_signed' && this.supervisor_id_for_sign!=this.provider_id_for_sign)
        {
            this.tempMsg = '<span class="px-2 p-1 bg-warning-light text-dark border-warning d-inline-block rounded b-1 fs-14 l-h-n">The rendering provider signature is pending. You can sign and lock the clinical note after rendering provider signs it.</span>';
            return 0;
        }
        /*Supervisor sign validation -- End*/

        var noteId = this.noteData[1].template_detail_data.masterId,
        patient_id = this.patient_id,
        appointment_id = this.appointment_id,
        signed_by = (this.provider_id_for_sign == this.user.user_id) ? 'provider' : 'supervisor',
        $this = this;

        if(this.sign_lock_label == 'Sign'){
            var msg = 'Do you want to sign this Note?',
            confrmbutnlabel = 'Yes, want to sign!',
            signStatus = 'sign';
        }
        else{
            var msg = 'Do you want to sign and lock this Note?',
            confrmbutnlabel = 'Yes, want to sign and lock this Note!',
            signStatus = 'lock';
        }

        this.$swal
            .fire({
              title: '<h2 class="mt-0">Are you sure?</h2>',
              text: msg,
              icon: "warning",
              dangerMode: true,
              showCancelButton: true,
              confirmButtonColor: "#ee3158",
              cancelButtonColor: "#bdbdbd",
              confirmButtonText: confrmbutnlabel,
            })
             .then((result) => {
              if (result.isConfirmed == true) {
                axios.post("/clinical-note-template/sign-lock", { noteId: noteId, patient_id: patient_id, signStatus: signStatus, appointment_id: appointment_id, appointment_number: this.hash, signed_by : signed_by,supervise_by: this.supervisor_id_for_sign, provider_by : this.provider_id_for_sign }).then((response) => {
                  
                    if(response.data.status == 'success')
                    {
                        this.$swal
                            .fire("Success!", "Note status saved.", "success")
                            .then((result) => {
                                $(".active").trigger("click");
                            });
                        
                        $("#previewNote").trigger("click");
                    }
                });
              }
            });
    },
    stickButton(id)
    {
        if(!$("#"+id).hasClass("collapsed"))
        {
            setTimeout(function(){ 
                $("#accordion2").children().children('.show').find(".submit-btn").addClass('stickyBtn');
            },1000);
        }
    },
    activeDiv(){
        if(this.$store.state.clinicalNote.active_note == 'additional') {
            return true;
        }
        else{
            return false;
        }
    },
    changeTab(components)
    {
        this.$store.state.patientDetailsComp = components;
        let props = this.$router.resolve({ 
          name: components,
          params: { id: this.$route.params.id},
        });
        if (history.pushState && props) {
            if(this.hash=='' || this.hash==null || typeof this.hash === undefined || typeof this.hash === 'undefined' || components == 'PatientLockedClinicalNotes')
            {
                history.pushState(null, '',props.href);
            }
            else
            {
                history.pushState(null, '',props.href+"/#"+this.hash);
            }
        }
    },
    handleScroll (event)
    {
        if($("#accordion2").children().children('.show').hasClass("show")===true)
        {
            var _actHeight = $("#accordion2").children().children('.show').height(),_actOffstop = $("#accordion2").children().children('.show').offset().top;

            if(event.target.documentElement.scrollTop>=_actOffstop && event.target.documentElement.scrollTop<=_actHeight)
            {
                $("#accordion2").children().children('.show').prev().addClass('stickyPan');
                $("#accordion2").children().children('.show').find(".submit-btn").addClass('stickyBtn');
            }
            else if(event.target.documentElement.scrollTop>=_actHeight)
            {
                $("#accordion2").children().children('.show').find(".submit-btn").removeClass('stickyBtn');
            }
            else
            {
                $("#accordion2").children().children('.show').prev().removeClass('stickyPan');
            }
        }
        else
        {
            $("#accordion2").children().children().prev().removeClass('stickyPan');
            $("#accordion2").children().children().find(".submit-btn").removeClass('stickyBtn');
        }
    },
    async createForm(index) {
        let oriindex = index;
        index = index+1;
        let self = this;

        await Formio.createForm(
            document.getElementById("notes"+index), this.noteData[index]
            ).then(
            function(form) {
                self.formDataToStore[index] = form;
                /*blocked save note for not authorised person*/
                self.activate_section = 1;
                /*console.log(self.noteData[index].template_detail_data['id']+' '+self.noteData[index].template_detail_data['template_id']);*/
                if(self.noteData[index].template_detail_data.id !== null && typeof index !== undefined)
                {
                    var tempId = "include"+index;
                    $("input[type=checkbox][name='"+tempId+"']").attr("data-temp-id","toggle"+self.noteData[index].template_detail_data.template_id);
                    $("input[type=checkbox][name='"+tempId+"']").attr("data-id","toggle"+self.noteData[index].template_detail_data.id);
                }

                /*blocked save note for not authorised person*/
                console.log(self.noteData[index].template_detail_data);
                if(self.note_sign_and_lock_rights==1 || self.noteData[index].template_detail_data.category == 'Covid')
                {
                    $("#notes"+index).append("<button class='btn btn-info submit-btn my-10' id='form"+index+"_submit'><i class='fa fa-file fs-14' aria-hidden='true'></i> Save Note</button>");
                }
                /*blocked save note for not authorised person - End*/

                if(self.noteData[index].template_detail_data.name == 'Assessment')
                {
                    self.icd10PartVisible = index;
                }

                $("a#title"+index).text(self.noteData[index].template_detail_data.name);
                let formName = 'form'+self.noteData[index].template_detail_data.template_id;

                form.submission = {
                    data: JSON.parse(self.data[oriindex][formName]),
                };

                form.submission.data.template_detail_data = self.noteData[index].template_detail_data;
                form.submission.data.components = self.noteData[index].components;

                var valArr = {};

                $("#form"+index+"_submit").click(function(submission) {
                    
                    var finalData = {};
                    finalData['template_detail_data'] = form.submission.data.template_detail_data;
                    delete form.submission.data['template_detail_data'];
                    delete form.submission.data['components']; 

                    /*change json*/   
                    var $fieldjsonData = form.submission.data;
                    var newArr = new Array(),newIndex;
                    $.each($fieldjsonData,function(indexx,eleValue) { 
                        if(!$.isNumeric(indexx))
                        {
                            newIndex = $("div.formio-component-"+indexx).parent().attr("data-index-id");
                            newArr.push({[indexx] : eleValue});
                        }
                    });
                    /*change json - end*/
                    finalData['fieldjsonData'] = newArr;
                    axios
                        .post('/clinical-note-template/Additional/save',finalData)
                        .then((response) => {
                            self.noteData[1].template_detail_data.masterId = response.data.data;
                            if(response)
                            {
                                self.moshaToast(`Note saved successfully`,'success');
                            }
                            else
                            {
                                self.moshaToast(`Note not saved successfully`,'danger');   
                            }
                        });
                });
        
            });

            /*custom reindex set*/
            $('div[ref^=column]').each(function(index,element){ $(element).attr("data-index-id",index) });
            /*custom reindex set - end*/
        }, 
        async getNote() {
            let bodyFormData = new FormData();
            bodyFormData.append("appointment_id", this.appointment_id);
            bodyFormData.append("patient_id", this.patient_id);
            let $this = this;

            await axios.post(
                `/clinical-note-template/Additional`,
                bodyFormData
                ).then((response) => {

                    $this.notes = response.data.data.length;
                    if(typeof response.data.data.length == undefined || response.data.data.data==0)
                    {
                        $this.note_msg = '<span class="px-2 p-1 bg-warning-light text-dark border-warning d-inline-block rounded b-1 fs-14 l-h-n mt-10">No appointment found to write clinical note.</span>';
                        return false;
                    }

                    $this.note_lock_status = false;
                    $this.is_supervisor_sign_required = (response.data.data[0].is_supervisor_sign_required == 0 || response.data.data[0].is_supervisor_sign_required == '0') ? false : true;
                    

                    switch(response.data.data[0].noteStatus)
                    {
                        case 'provider_signed':
                            $this.note_msg = (response.data.data[0].is_supervisor_sign_required == '1') ? '<span class="px-2 p-1 bg-warning-light text-dark border-warning d-inline-block rounded b-1 fs-14 l-h-n mt-10">Locked by Rendering Provider. Awaiting Supervisor Provider to sign and lock.</span>' : '';
                            $this.dataSave = true;
                            $this.sign_lock_label = 'Sign and Lock';
                            break;      
                        case 'locked':
                            $this.note_lock_status = true;
                            $this.dataSave = false;
                            $this.note_sign_and_lock_rights = 0;
                            break;
                        default:
                            $this.sign_lock_label = (response.data.data[0].is_supervisor_sign_required == 0 || response.data.data[0].is_supervisor_sign_required == '0') ? 'Sign and Lock': 'Sign';
                            $this.dataSave = true;
                            break;
                    }

                    setTimeout(function(){ 
                        
                        $this.getNoteDetail(response.data.data);

                        if($this.user.user_type == 'staff')
                        {
                            $this.note_sign_and_lock_rights = 0;
                        }
                        else if(response.data.data[0].noteStatus == 'locked')
                        {
                            $this.note_sign_and_lock_rights = 0;
                        }
                        else if(
                            ($this.provider_id_for_sign!='' || $this.supervisor_id_for_sign!='') 
                                && 
                            (
                                (response.data.data[0].noteStatus=='pending' || response.data.data[0].noteStatus=='' || (response.data.data[0].noteStatus=='provider_signed' && $this.user.user_id == $this.supervisor_id_for_sign)) 
                                    && 
                                ($this.user.user_id == $this.provider_id_for_sign 
                                    ||
                                $this.user.user_id == $this.supervisor_id_for_sign)
                            )
                        )
                        {
                            $this.note_sign_and_lock_rights = 1;
                            $this.dataSave = true;
                        }
                        else
                        {   
                            $this.note_sign_and_lock_rights = 0;
                        }
                    }, 1000);
                });
            },
            async getNoteDetail(redata){
                let $this = this;
                $this.data = [];
                $.each(redata,function(index,item) {
                    let componentsData = JSON.parse(
                        item.template_json
                        ).components;

                    $this.provider_id_for_sign = item.provider_id_for_sign;
                    $this.supervisor_id_for_sign = item.supervisor_id_for_sign;
                    $this.noteData.push({
                        template_detail_data : {
                            'id': item.noteId,
                            'masterId' : item.note_unique_id,
                            'note_status' : item.noteStatus,
                            'template_id' : item.id,
                            'name' : item.name,
                            'category' : item.category,
                            'patient_id' : $this.patient_id,
                            'appointment_id' : $this.appointment_id,
                            'is_included' : item.is_included,
                            'patient_clinical_note_version' : (item.version ==  null) ? 1 : item.version,
                        },
                        components : componentsData,  
                    });

                    let arr = item.note_field_json;
                    let arrKey = 'form'+item.id;
                    var object = {};
                    object[arrKey] = arr;

                    $this.data.push(object);
                    $this.createForm(index);
                });
                /*Macro content setup*/
                $('textarea').parent().prev().append('<i class="fa fa-toggle-down toggle-macro ms-1 cursor-pointer" title="toggle macros" style="color:#00abeb"></i>');

			$( '<div class="d-none macros panel panel-default macros-container p-0 box-bordered position-absolute"><div class="panel-heading bg-light text-dark">Macros</div><div class="panel-body bg-white"></div></div>' ).insertAfter( "textarea" );
                /*Macro content setup -- End*/
            },
            changeAppointment($event)
            {
                this.activate_section = 0;
                this.appointment_id = $event.target.value;
                this.notes = '';
                this.note_msg = this.tempMsg = '';
                this.noteData = [{
                    template_detail_data: [{
                        id: '',
                        masterId : '',
                        template_id: '',
                        name: '',
                        category: '',
                        patient_id: '',
                        appointment_id: '',
                        is_included : null,
                        patient_clinical_note_version : '',              
                    }],
                    components: [],
                }];
                /*URL hash update*/
                this.hash = $("select#appointment_id option[value='"+this.appointment_id+"']").attr("data-val");
                var parts = location.href.split("/"),
                    last_part = parts[parts.length-1];
                                    
                var newUrl = (last_part == 'Custom') ? location.href : location.href.substring(0, location.href.lastIndexOf('/'));
                            
                history.pushState(null, '',newUrl+"/#"+this.hash);              
                /*URL hash update - new*/

                /*Note Trigger*/
                this.getNote();
                /*Note Trigger - End*/
            },
            _UIdentiTy(){
                return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
                    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
                  );
            },
            PreviewNote()
            {        
                if(this.noteData[1].template_detail_data.masterId!='')
                {
                    this.$store.state.clinicalNote.appointment_id = $("#appointment_id").val();
                    var app = $('#appointment_id :selected').text(),
                    data = app.split(' '),dt = data[0],
                    newDat = this.$filters.inputDateTime(dt,'YYYY-MM-DD');

                    this.$store.state.clinicalNote.appointment_date = newDat;

                    this.$store.state.clinicalNote.clinical_note_id = this.noteData[1].template_detail_data.masterId+"-"+this._UIdentiTy();

                    this.$store.state.clinicalNote.active_note = 'additional';
                    this.$store.state.clinicalNote.active_note_component = 'ClinicalNoteAdditional';
                    this.$store.state.patientDetailsComp = ClinicalNotePreview;                
                }
                else
                {
                    this.moshaToast(`No Clinical note available`,'info');
                }
            }
        },
        computed: {
            ...mapGetters("auth", ["user"])
        },
        mounted(){
            this.macroGenerateFunction();
        },
        watch: {
            axiosChange: function() {
                this.macrosetup(this.axiosChange);
            },
        },
    }
</script>

<style src="@vueform/toggle/themes/default.css"></style>