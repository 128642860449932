<template>
	<div class="content-wrapper">
		<div class="container-full">
			<section class="content">
				<div class="row justify-content-sm-center mb-20">
					<div class="col-12 col-sm-12 col-md-12 col-lg-7 col-xl-6 col-xxl-5">
						<div class="row align-items-center">
							<div class="col-12 col-sm-8 col-md-8">
								<h1 class="font-bold my-10">Clinical Note Settings
									<!-- <Popper arrow content="It is used to carry the clinical note setting whether if the user wants to make notes as persistant or want to keep it active or not." :hover="true" class="helptips cursor-pointer fs-14">
										<i class="fa fa-info-circle fs-16"></i>
									</Popper> -->
									<a href="#" data-bs-toggle="modal" data-bs-target="#clinicalnotedocument" title="Reference of Knowledge Base" v-on:click.prevent="reset()"> <i class="fa fa-question-circle fs-20"></i></a>
								</h1>
							</div>
							<div class="col-12 col-sm-4 col-md-4 text-sm-end">
								<form-btn title="Save" btnCls="waves-effect waves-light btn btn-info" @click="submitForm()"></form-btn>
							</div>
						</div>
						<div class="box mt-10">
							<div class="box-header bg-info">
								<div class="row align-items-center justify-content-between">
									<div class="col-6 fw-600">Note</div>
									<div class="col-3 fw-600 text-center">
										#Persistance
									</div>
									<div class="col-3 fw-600 text-center">
										#Active
									</div>
								</div>
							</div>
							<div class="box-body">
								<draggable
								:list="list"
								:disabled="!enabled"
								item-key="name"
								class="list-group"
								ghost-class="ghost"
								:move="checkMove"
								@start="dragging = true"
								@end="dragging = false"
								>
								<template #item="{ element, index }" class="bb-1 border-secondary">
									<div class="row align-items-center justify-content-between py-10" :class="{ 'not-draggable': !enabled }" v-bind:class="element.is_active == 0 ? 'bg-danger-light' : ''" title="drag and drop to reorder notes" style="cursor: -webkit-grabbing; cursor: grabbing;">

										<div class="col-6">
											<span class="" style="cursor: move;">
												<i class="fa fa-ellipsis-v handle fs-14 me-1"></i>
												<i class="fa fa-ellipsis-v handle fs-14 me-10"></i>
											</span>
											<span v-if="(element.category=='H&P'|| element.category=='SOAP')">{{element.category}}</span><span v-else>{{ element.name }}</span>
										</div>
										<div class="col-3 text-center">
											<input type="checkbox" :id="element.id+'_'+index" v-model="element.is_persistance" class="form-control filled-in chk-col-info w-auto">
											<label :for="element.id+'_'+index"  class="text-start"></label>
										</div>

										<div class="col-2 text-center">
											<input type="checkbox" :id="'active_'+element.id+'_'+index" v-model="element.is_active" class="form-control filled-in chk-col-info w-auto">
											<label :for="'active_'+element.id+'_'+index"  class="text-start"></label>
										</div>
									</div>
								</template>
							</draggable>
						</div>
						<rawDisplayer class="col-3" :value="list" title="List" />
					</div>
					<!--document model start-->
					<div id="clinicalnotedocument" class="modal fade" tabindex="-1" role="dialog"
						aria-labelledby="myModalLabel" aria-hidden="true">
						<div class="modal-dialog modal-md" style="max-width: 600px;">
							<div class="modal-content">
							<div class="modal-header">
							<h3 class="modal-title fw-900 mt-0">Reference of Knowledge Base</h3>
							</div>
							<div class="modal-body overflow-auto" style="max-height: 350px;">
							<table class="table table-bordered">
								<thead>
									<tr>
									<th scope="col">No.</th>
									<th scope="col">Knowledge Base Articles</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>1</td>
										<td> <a :href="path+'how-to-make-clinical-note-persistent-and-active-in-pureehr'" target="_blank">How To Make Clinical Note Persistent And Active In PureEHR?</a></td>
									</tr>
									<tr>
										<td>2</td>
										<td> <a :href="path+'how-to-active-deactive-clinical-notes-in-pureehr'" target="_blank">How To Active/Deactive Clinical Notes In PureEHR?</a></td>
									</tr>
									<tr>
										<td>3</td>
										<td> <a :href="path+'how-to-edit-a-rejected-ammendment-in-pureehr'" target="_blank">How To Edit A Rejected Ammendment In PureEHR?</a></td>
									</tr>
									<tr>
										<td>4</td>
										<td> <a :href="path+'how-to-write-an-amendment-in-pureehr'" target="_blank">How To Write An Amendment In PureEHR?</a></td>
									</tr>
									<tr>
										<td>5</td>
										<td> <a :href="path+'how-does-the-supervising-provider-sign-and-lock-the-clinical-note-in-pureehr'" target="_blank">How Does The Supervising Provider Sign And Lock The Clinical Note In PureEHR?</a></td>
									</tr>
									<tr>
										<td>6</td>
										<td> <a :href="path+'how-to-write-a-clinical-note-in-pureehr'" target="_blank">How To Write A Clinical Note In PureEHR?</a></td>
									</tr>
									<tr>
										<td>7</td>
										<td> <a :href="path+'how-to-preview-the-clinical-note'" target="_blank">How To Preview The Clinical Note?</a></td>
									</tr>
									<tr>
										<td>8</td>
										<td> <a :href="path+'how-to-unlock-any-clinical-note-in-pureehr'" target="_blank">How To Unlock Any Clinical Note In PureEHR?</a></td>
									</tr>
									<tr>
										<td>9</td>
										<td> <a :href="path+'how-to-edit-a-clinical-note-in-pureehr'" target="_blank">How To Edit A Clinical Note In PureEHR?</a></td>
									</tr>
								</tbody>
							</table>
							<Search :key="searchKey"></Search>
							</div>
							<div class="modal-footer">
							<button type="button" @click="" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
							</div>
							</div>
						</div>
					</div>
					<!--document model end-->
				</div>
			</div>
		</section>
	</div>
</div>
</template>


<script>
	import axios from "@/scripts/axios.js";
	import FormBtn from "@/components/base/formFields/formBtn.vue";
	import draggable from 'vuedraggable';
	import submitMixins from "@/scripts/mixins/submitMixins.js";
	import Popper from "vue3-popper";
	import Search from "@/components/Search.vue";

	let id = 1;
	export default {
		name: "simple",
		display: "Simple",
		order: 0,
		mixins: [submitMixins],
		components: {
			draggable,
			FormBtn,
			Popper,
			Search
		},
		data() {
			return {
				enabled: true,
				list: [],
				dragging: false,
				path: process.env.VUE_APP_KB_DOCUMENT,
				searchKey:0,
			};
		},
		mounted(){
		},
		async beforeCreate() {
			axios.get("/manage-clinical-note-list")
			.then((response) => (
				(this.list = response.data.data.map((listData) => {
					return { 
						id: listData.id, 
						name: listData.name, 
						category: listData.category,
						is_persistance: ((listData.is_persistance == 'n') ? false : true),
						is_active: ((listData.is_active == '0') ? false : true)
					};
				})
				)));
		},
		computed: {

		},
		methods: {
			reset() {
				this.searchKey = this.searchKey + 1;
			},
			checkMove: function(e) {
				window.console.log("Future index: " + e.draggedContext.futureIndex);
			},
			submitForm(){
				axios.post("/manage-clinical-note-list-save", {list: this.list})
				.then((response) => {
					this.moshaToast("Data saved successfully.", "success"); 
				});
			}
		}
	};
</script>
<style scoped>
	.buttons {
		margin-top: 35px;
	}
	.ghost {
		opacity: 0.5;
		background: #c8ebfb;
	}
	.not-draggable {
		cursor: no-drop;
	}
</style>