<template>
  	<div class="tab-content">
    	<div class="tab-pane active" id="locked" role="tabpanel">
      		<div class="authform-modal box">
        		<div class="pageheading">
          			<div class="row">
            			<div class="col-12 col-sm-6 col-md-6 col-lg-6">
              				<h2 class="heading2 my-5 float-start">Active Problems <a href="#" data-bs-toggle="modal" data-bs-target="#problemdocument" title="Reference of Knowledge Base" v-on:click.prevent="reset()"> <i class="fa fa-question-circle fs-20"></i></a> </h2>
              				<button v-if="this.$store.state.patientDetail.patient_rcopia_id!='' && this.$store.state.patientDetail.patient_rcopia_id!=mull" class="waves-effect waves-light btn btn-info-light btn-xs ms-10 fetch_problem mt-10" @click="fetchProblem" title="fetch problems from rcopia"><i class="fa fa-refresh"></i></button>
            			</div>
            			<div class="col-12 col-sm-6 col-md-6 col-lg-6 text-sm-end">
              				<a v-if="permissioncheck(this.$store.state.permission.create_update)" href="javascript:void(0)" class="waves-effect waves-light btn btn-info mb-10 mt-xs-10" data-bs-toggle="modal" data-bs-target="#problemAddEditModal" @click="setDate" ><i class="fa fa-plus fs-14"></i> Problem</a>
            			</div>
          			</div>
          			<div id="problemAddEditModal" class="modal fade" tabindex="-1" aria-labelledby="problemModalLabel" style="display: none;" aria-hidden="true">
            			<div class="modal-dialog ">
              				<div class="modal-content">
								<div class="modal-header">
									<h2 class="modal-title m-0">
										{{ this.form.id ? "Edit Problem" : "Add Problem" }}
									</h2>
								</div>
								<div class="modal-body">
                					<form>
                    					<div class="form-group row align-items-center" :class="{ error: v$.form.problem.$errors.length }">
                      						<label class="col-form-label col-12 col-md-5 text-md-end mb-0">Problem <small class="text-danger asterisksign">*</small></label>
											<div class="col-12 col-md-7">
                        						<div class="selectcontrols ">
													<Autocomplete max="30" @input="inputCode" ref="autocomplete" :results="problemCodeList" @onSelect="problemSelectedValue" class="form-control ms-0 autosearch-list" :value="modelValue" placeholder="Search Problem" :use-html-for-results ="true"></Autocomplete>
                        						</div>
                        						<div class="error-msg" v-if="v$.form.problem.$errors.length > 0">
													<ul class="mb-0 p-0 fs-14">
														<li class="d-block"  v-for="(error, index) of v$.form.problem.$errors" :key="index">
														{{ error.$message }}
														</li>
													</ul>
                        						</div>
                      						</div>
                    					</div>
                    					<div class="form-group row align-items-center">
                        					<label class="mb-0 col-form-label col-12 col-md-5 text-md-end">ICD-10 Code</label>
											<div class="col-12 col-md-7">
												<div class="selectcontrols">
													<Autocomplete max="30" @input="inputCodeIcdCode" ref="autocompleteicd10" :results="problemCodeListICD10" @onSelect="problemSelectedValue" class="form-control ms-0 autosearch-list" :value="modelValue" placeholder="ICD-10 Code" :use-html-for-results ="true"></Autocomplete>
												</div>
											</div>
                    					</div>
										<div class="form-group row align-items-center" :class="{ error: v$.form.problem.$errors.length }">
                      						<label class="col-form-label col-12 col-md-5 text-md-end mb-0">SNOMED Code </label>
											<div class="col-12 col-md-7">
												<div class="selectcontrols ">
													<Autocomplete @input="snomed_code" ref="snomed_code" :results="snomed_codeList" @onSelect="snomed_codevalue" class="form-control" :value="modelValue" placeholder="Search SNOMED Code" :use-html-for-results ="true"></Autocomplete>
												</div>                        
											</div>
					                    </div>                    
										<select-box-static title="Status" id="Status" v-model="v$.form.status.$model" :errors="v$.form.status.$errors" :isRequired="true" :option="prbolem_status" labelClass="col-12 col-md-5 text-md-end col-form-label mb-0" divClass="col-12 col-md-7">
										</select-box-static>
										<div class="form-group row align-items-center">
											<label class="mb-0 col-form-label col-12 col-md-5 text-md-end">Date & Time Diagnosis</label>
											<div class="col-12 col-md-7">
												<div class="controls availdate">
													<ejs-datetimepicker id="datetime_diagnosis" placeholder="mm-dd-yyyy, --:-- --" :step="30" v-model="form.datetime_diagnosis" :value="datetime_diagnosis" :showClearButton='false' :format="dateFormat" @change="getDate" :blur="getDate"></ejs-datetimepicker>
												</div>
											</div>
										</div>
										<div class="form-group row align-items-center">
											<label class="mb-0 col-form-label col-12 col-md-5 text-md-end">Date & Time Onset</label>
											<div class="col-12 col-md-7">
												<div class="controls availdate">
													<ejs-datetimepicker id="datetime_onset" placeholder="mm-dd-yyyy, --:-- --" :step="30" v-model="form.datetime_onset" :value="datetime_onset" :showClearButton='false' :format="dateFormat" @change="getDateTimeOnset"></ejs-datetimepicker>   
												</div>
											</div>
										</div>
										<div class="form-group row align-items-center">
											<label class="mb-0 col-form-label col-12 col-md-5 text-md-end">Date & Time Changed</label>
											<div class="col-12 col-md-7">
												<div class="controls availdate">
												<ejs-datetimepicker id="datetime_changed" placeholder="mm-dd-yyyy, --:-- --" :value="datetime_changed" :step="30"  v-model="form.datetime_changed" :showClearButton='false' :format="dateFormat" @change="getDateTimeChanged" :blur="getDateTimeChanged"></ejs-datetimepicker>
												</div>
											</div>
										</div>
										<div class="form-group row">
											<label class="mb-0 col-form-label col-12 col-md-5 text-md-end">Notes</label>
											<div class="col-12 col-md-7">
												<textarea name="textarea" v-model="form.description" id="textarea" class="form-control" placeholder="Notes"></textarea>
											</div>
										</div>
										<div class="form-group row">
											<label class="mb-0 form-label col-5 col-md-5 text-md-end">Is Symptom</label>
											<div class="col-5 col-md-7">
												<input type="checkbox" id="is_symptom_checkbox" :checked="form.is_symptom" v-model="form.is_symptom" :value="form.is_symptom" class="filled-in chk-col-info"/>
												<label for="is_symptom_checkbox" class="m-0 text-start"></label>
											</div>
										</div>                  
										<div class="modal-footer">
											<button type="button" class="waves-effect waves-light btn btn-info" @click="saveProblem(false)"> Save </button>
											<button type="button" class="waves-effect waves-light btn btn-info" v-if="!form.id" @click="saveProblem(true)"> Save and Add Another </button>
											<button type="button" @click="closeDialog" class="waves-effect waves-light btn btn-danger" data-bs-dismiss="modal"> Close </button>
										</div>
                					</form>
              					</div>
            				</div>
          				</div>
        			</div>
					<div class="col-12 px-0 pb-3">
						<div class="controls d-inline-block me-4">
							<input type="checkbox" id="checkbox_1" value="single" class="filled-in chk-col-info" @change="showInactiveProblem"/>
							<label for="checkbox_1" class="m-0">Show Inactive Problems</label>
						</div>
					</div>
        			<div class="box-body p-0">
          				<div class="table-responsive">
            				<table-base :dataTypes="{  dataType: 'patientProblem',  patient_id: patient_id,  patient_unique_id: patient_unique_id,  method: 'get',  title:'No active problems available',}" :lastcol='permissioncheck(this.$store.state.permission.create_update)' :key="table_load_count">
              					<template v-slot="slotProps">
									<td>{{ slotProps.item.source }}</td>
									<td>{{ slotProps.item.problem }}</td>
									<td>{{ slotProps.item.ICD_10 }}</td>
									<td>{{ slotProps.item.SNOMED_CT }}</td>
									<td>
										{{ $filters.displayDateTimeLocal(slotProps.item.datetime_diagnosis) }}
									</td>
									<td>
										{{ $filters.displayDateTimeLocal(slotProps.item.datetime_changed) }}
									</td>
									<td>{{ slotProps.item.status }}</td>
									<td>{{ slotProps.item.description }}</td>
									<td>{{ (slotProps.item.datetime_onset!=NULL && slotProps.item.datetime_onset!='' && slotProps.item.datetime_onset!='0000-00-00 00:00:00') ? $filters.displayDateTimeLocal(slotProps.item.datetime_onset) : '-' }}</td>
									<td>
										<div class="d-flex">
											<Popper arrow content="Edit Problem" :hover="true" class="helptips cursor-pointer">
												<a v-if="permissioncheck(this.$store.state.permission.create_update)" href="javascript:void(0);" @click="editProblem(slotProps.item.id)" class="waves-effect waves-circle btn btn-circle btn-info btn-xs"><i class="fa fa-pencil"></i></a>
											</Popper>
											<Popper arrow content="Delete Problem" :hover="true" class="helptips cursor-pointer">
												<a v-if="permissioncheck(this.$store.state.permission.create_update)" href="#" data-bs-toggle="tooltip" @click="deleteProblem(slotProps.item.id)" data-bs-original-title="Delete" class="waves-effect waves-circle btn btn-circle btn-danger btn-xs"><i class="fa fa-trash"></i></a>
											</Popper>										
										</div>
									</td>
								</template>
            				</table-base>
          				</div>
        			</div>
      			</div>
				<div class="authform-modal box">
					<div class="pageheading row mx-0">
						<div class="col-md-6 px-0">
							<h2 class="heading2">Audit Log</h2>
						</div>
					</div>
					<div class="box-body p-0">
						<div class="table-responsive">
							<AuditLogTable LogType="Problem List" :patient_unique_id="patient_unique_id" :key="table_load_count" />
						</div>
					</div>
				</div>
      			<div id="problemdocument" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
        			<div class="modal-dialog modal-md">
          				<div class="modal-content">
							<div class="modal-header">
								<h3 class="modal-title fw-900 mt-0">Reference of Knowledge Base</h3>
							</div>
							<div class="modal-body overflow-auto" style="max-height: 350px;">
								<table class="table table-bordered">
									<thead>
										<tr>
											<th scope="col">No.</th>
											<th scope="col">Knowledge Base Articles</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>1</td>
											<td> <a :href="path+'how-to-add-a-new-problem'" target="_blank">How To Add A New Problem?</a></td>
										</tr>
										<tr>
											<td>2</td>
											<td> <a :href="path+'how-to-view-audit-logs-for-problems-in-pureehr'" target="_blank">How To View Audit Logs For Problems In PureEHR?</a></td>
										</tr>
										<tr>
											<td>3</td>
											<td> <a :href="path+'how-to-edit-a-problem'" target="_blank">How To Edit A Problem?</a></td>
										</tr>
										<tr>
											<td>4</td>
											<td> <a :href="path+'how-to-delete-a-problem'" target="_blank">How To Delete A Problem?</a></td>
										</tr>
										<tr>
											<td>5</td>
											<td> <a :href="path+'how-to-edit-inactive-problems'" target="_blank">How To Edit Inactive Problems?</a></td>
										</tr>
										<tr>
											<td>6</td>
											<td> <a :href="path+'how-to-delete-inactive-problems'" target="_blank">How To Delete Inactive Problems?</a></td>
										</tr>
										<tr>
											<td>7</td>
											<td> <a :href="path+'how-to-view-inactive-problems'" target="_blank">How To View Inactive Problems?</a></td>
										</tr>
									</tbody>
								</table>
								<Search :key="searchKey"></Search>
							</div>
							<div class="modal-footer">
								<button type="button" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
							</div>
          				</div>
        			</div>
      			</div>
    		</div>
  		</div>
	</div>
</template>
<script>
import axios from "@/scripts/axios.js";
import Autocomplete from "vue3-autocomplete";
import useVuelidate from "@vuelidate/core";
import submitMixins from "@/scripts/mixins/submitMixins.js";
import TableBase from "@/components/base/Table.vue";
import InputField from "@/components/base/formFields/InputField.vue";
import SelectBoxStatic from "@/components/base/formFields/SelectBoxStatic.vue";
import permissionMixins from "@/scripts/mixins/permission.js";
import AuditLogTable from "@/components/base/AuditLogTable.vue";
import Popper from "vue3-popper";
import moment from 'moment';
import Search from "@/components/Search.vue";

export default {
  setup: () => {
    return { v$: useVuelidate() };
  },
  mixins: [submitMixins, permissionMixins],
  components: {
    Autocomplete,
    TableBase,
    InputField,
    SelectBoxStatic,
    AuditLogTable,
    Popper,
    Search
  },
  data() {
    return {
      is_production_env: (process.env.VUE_APP_NODE_ENV == 'production') ? true : false,
      datetime_diagnosis:'',
      datetime_onset:'',
      datetime_changed:'',
      current_date: "",
      today: "",
      //datetime_changed : new Date(),
      dateFormat : 'MM-dd-yyyy hh:mm a',
      prbolem_status: [
        { value: "Active", title: "Active" },
        { value: "InActive", title: "Inactive" },
        { value: "Resolved", title: "Resolved" },
      ],
      show_inactive_problem: false,
      save_and_add_another: false,
      problemCodeList: [],
      problemCodeListICD10: [],
      patient_id: this.$store.state.patientDetail.patient_id,
      patient_unique_id: this.$store.state.patientDetail.patient_unique_id,
      table_load_count: 0,
      problem_fetched_date: this.$store.state.patientDetail.problem_fetched_date,
      path: process.env.VUE_APP_KB_DOCUMENT,
      searchKey:0,
      snomed_codeList: [],
    };
  },
  computed: {
    form() {
      return this.$store.state.patientProblem.form;
    },
    vuelidateExternalResults() {
      return this.$store.state.patientProblem.vuelidateExternalResults;
    },
    validationRules() {
      return this.$store.state.patientProblem.validationRules;
    },
  },
  validations() {
    return this.$store.state.patientProblem.validationRules;
  },
  created() {
    this.form.datetime_onset = new Date();
    this.setCurrentDate();
  },
  mounted() {
    setTimeout(function() {
      var d = new Date();
      var month = d.getMonth() + 1;
      var day = d.getDate();
      this.today =
        d.getFullYear() +
        "-" +
        (("" + month).length < 2 ? "0" : "") +
        month +
        "-" +
        (("" + day).length < 2 ? "0" : "") +
        day;
      this.today = this.today + "T00:00";
      // console.log(this.today);
      // $("#publishDate").val(this.today);
    }, 3000);

    //$("#publishDate").val("'"+this.today+"'");
  },
  methods: {
    reset() {
      this.searchKey = this.searchKey + 1;
    },
    fetchProblem() {
      if(this.$store.state.patientDetail.patient_rcopia_id == null)
      {
          this.$swal
            .fire({
              title: '<h2 class="mt-0">Sorry!</h2>',
              text:
                "The patient is not available in Rcopia. Please add the patient in Rcopia to fetch problem.",
              icon: "warning",
              dangerMode: true,
              showCancelButton: true,
              confirmButtonColor: "#ee3158",
              cancelButtonColor: "#bdbdbd",
              confirmButtonText: "ok",
            })
            .then((result) => {
            });
      }
      else
      {
        $("button.fetch_problem").html("<i class='fa fa-sync fa-spin'></i>");
        axios
            .post(`/patient/${this.$route.params.id}/fetch-problem`, { patient_id : this.$store.state.patientDetail.patient_id, patient_rcopia_id: this.$store.state.patientDetail.patient_rcopia_id, problem_fetched_date : this.problem_fetched_date })
            .then((response) => {
                $("button.fetch_problem").html('<i class="fa fa-refresh"></i>');
                this.table_load_count++;
                this.$swal.fire("Fetched!","Problem fetched successfully.","success");
            });
      }
    },
    getDate() {
      if($('#datetime_diagnosis').val() == ''){
        this.form.datetime_diagnosis = null;
      }else{
        this.datetime_diagnosis =  $('#datetime_diagnosis').val();
        this.form.datetime_diagnosis = moment(String(this.datetime_diagnosis)).format('YYYY-MM-DD hh:mm a');
      }
    },
    getDateTimeOnset(event) {
      
        if($('#datetime_onset').val() == '' && this.form.datetime_onset==''){
          this.form.datetime_onset = null;
        }else{
          this.datetime_onset = $('#datetime_onset').val();
          this.form.datetime_onset = moment(String(this.datetime_onset)).format('YYYY-MM-DD hh:mm a');
         
        }      
    },
    getDateTimeChanged() {
      if($('#datetime_changed').val() == ''){
        this.form.datetime_changed = null;
      }else{
        this.form.datetime_changed = $('#datetime_changed').val();
        this.form.datetime_changed = moment(String(this.form.datetime_changed)).format('YYYY-MM-DD hh:mm a');
      }
      
    },
    inputCode(obj) {
      if (obj.length > 1) {
        this.$refs.autocomplete.$el.classList.add("selectloader");
        axios
          .get("/json_autocomplete/icd-code", {
            params: { term: obj },
          })
          .then((response) => {
            //this.problemCodeList = response.data.data;
            this.problemCodeList = response.data.data.map((problem) => {
              return { id: problem.id, name: "<b>"+problem.code+"</b><br>"+problem.description,code:problem.code,description:problem.description }
            });
            this.$refs.autocomplete.$el.classList.remove("selectloader");
            this.form.problem = obj;
          });
      }
      if (obj.length == 0) {
        this.problemCodeList = [];
      }
    },
    inputCodeIcdCode(obj) {
      if (obj.length > 1) {
        this.$refs.autocompleteicd10.$el.classList.add("selectloader");
        axios
          .get("/json_autocomplete/icd-code", {
            params: { term: obj },
          })
          .then((response) => {
            //this.problemCodeListICD10 = response.data.data;
            this.problemCodeListICD10 = response.data.data.map((problem) => {
              return { id: problem.id, name: "<b>"+problem.code+"</b><br>"+problem.description,code:problem.code,description:problem.description }
            });
            this.$refs.autocompleteicd10.$el.classList.remove("selectloader");
          });
      }
      if (obj.length == 0) {
        this.problemCodeListICD10 = [];
      }
    },
    problemSelectedValue(obj) {
      this.form.problem_id = obj.id;
      this.form.ICD_10 = obj.code;
	  this.form.SNOMED_CT = null;
      this.form.problem = obj.description;
      this.$refs.autocomplete.searchText = obj.description;
      this.$refs.autocompleteicd10.searchText = obj.code;
      this.$emit("update:modelValue", obj.code);
    },
    resetForm() {
      var self = this;
      Object.keys(this.form).forEach(function(key, index) {
        self.form[key] = "";
      });
      this.$refs.autocomplete.searchText = "";
      this.$refs.autocompleteicd10.searchText = "";
      this.v$.$reset();
      $('#datetime_diagnosis').val('');
      $('#datetime_changed').val('');
      var date = new Date();
      date = moment(String(date)).format('MM-DD-YYYY hh:mm A');  
      // $('#datetime_onset').val(date);
    },
    closeDialog() {
      $("#problemAddEditModal").modal("hide");
      this.resetForm();
      $('#datetime_diagnosis').val('');
      $('#datetime_changed').val('');
      var date = new Date();
      date = moment(String(date)).format('MM-DD-YYYY hh:mm A');  
      // $('#datetime_onset').val(date);
    },
    setDate() {
      // this.form.datetime_onset = new Date();
      this.setCurrentDate()
    },
    saveProblem(addNew) {
      if (addNew) {
        this.save_and_add_another = true;
      } else {
        this.save_and_add_another = false;
      }
      const storeOrUpdate = this.form.id ? "update" : "store";
      this.form.patient_id = this.$store.state.patientDetail.patient_id;
      const url = `/patient/${this.$route.params.id}/problem/${storeOrUpdate}`;
    
      if (this.form.datetime_diagnosis) {
        this.form.datetime_diagnosis = moment(String(this.form.datetime_diagnosis)).format('YYYY-MM-DD HH:mm:ss');   
      } 
      if (this.form.datetime_onset) {
        this.form.datetime_onset = moment(String(this.form.datetime_onset)).format('YYYY-MM-DD HH:mm:ss'); 
      }
      if (this.form.datetime_changed) {
        this.form.datetime_changed = moment(String(this.form.datetime_changed)).format('YYYY-MM-DD HH:mm:ss');
      }
      this.postForm(url, "patientProblem");
    },
  
    formPostSuccess(response) {
      const message = this.form.id
        ? "Record updated successfully."
        : "Record added successfully.";
      this.moshaToast(message, "success");
      this.$store.state.patientProblem.form = {};
      this.v$.$reset();
      Object.assign(this.$store.state.patientProblem.form);
      if (!this.save_and_add_another) {
        $("#problemAddEditModal").modal("hide");
      }

      this.resetForm();
      this.table_load_count++;
      this.problemCodeList = [];
      this.problemCodeListICD10 = [];
      this.form.datetime_onset = new Date();
    },
    async editProblem(id) {
      if (id) {
        const result = await this.$store.dispatch("getEditData", {
          dataType: "patientProblem", // pass the module name
          formType: "form", // pass the propery name
          patient_unique_id: this.$route.params.id,
          id: id,
        });
        this.$refs.autocomplete.searchText = this.form.problem;
        this.$refs.autocompleteicd10.searchText = this.form.ICD_10;
        this.$refs.snomed_code.searchText = this.form.SNOMED_CT;
        $("#problemAddEditModal").modal("show");

        if (this.form.datetime_diagnosis) {
         
          this.form.datetime_diagnosis = moment(String(this.form.datetime_diagnosis)).format('MM-DD-YYYY hh:mm A');
          this.datetime_diagnosis = moment(String(this.form.datetime_diagnosis)).format('MM-DD-YYYY hh:mm A');
          console.log(this.datetime_diagnosis);
        }
        if (this.form.datetime_onset) {
          this.form.datetime_onset = moment(String(this.form.datetime_onset)).format('MM-DD-YYYY hh:mm A');
          this.datetime_onset = moment(String(this.form.datetime_onset)).format('MM-DD-YYYY hh:mm A');
        }

        if (this.form.datetime_changed) {
          this.form.datetime_changed = moment(String(this.form.datetime_changed)).format('MM-DD-YYYY hh:mm A');
          this.datetime_changed = moment(String(this.form.datetime_changed)).format('MM-DD-YYYY hh:mm A');
        } 
      }
    },
    deleteProblem(id) {
      this.$swal
        .fire({
          title: '<h2 class="mt-0">Are you sure?</h2>',
          text:
            "You want to delete these records? This process cannot be undone.",
          icon: "warning",
          dangerMode: true,
          showCancelButton: true,
          confirmButtonColor: "#ee3158",
          cancelButtonColor: "#bdbdbd",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed == true) {
            axios
              .post(`/patient/${this.$route.params.id}/problem/delete`, {
                id: id,
              })
              .then((response) => {
                this.$swal
                  .fire(
                    "Deleted!",
                    "Patient problem has been deleted.",
                    "success"
                  )
                  .then((result) => {
                    if (result.isConfirmed == true) {
                      this.table_load_count++;
                    }
                  });
              });
          }
        });
    },
    showInactiveProblem() {
      this.show_inactive_problem = !this.show_inactive_problem;
      const listUrl = this.$store.state.patientProblem.listUrl.replace(
        "/Active",
        ""
      );
      if (this.show_inactive_problem) {
        this.$store.state.patientProblem.listUrl = listUrl;
        this.table_load_count++;
      } else {
        this.$store.state.patientProblem.listUrl = listUrl + "/Active";
        this.table_load_count++;
      }
    },
    snomed_code(obj) {
      if (obj.length > 1) {
          this.$refs.snomed_code.$el.classList.add("selectloader");
          axios
          .get("/json_autocomplete/snomed-code", {
              params: { term: obj },
          })
          .then((response) => {
              this.snomed_codeList = response.data.data.map((snomed_code) => {
                return { id: snomed_code.id, name: "<b>"+snomed_code.code+"</b><br>"+snomed_code.description,code:snomed_code.code,description:snomed_code.description }
              });
              this.$refs.snomed_code.$el.classList.remove("selectloader");
          });
      }   
      if (obj.length == 0) {
          this.snomed_codeList = {}; 
          this.form.SNOMED_CT = '';
          this.form.problem = '';
          this.$refs.snomed_code.searchText = '';
          this.$refs.autocomplete.searchText = '';
      }
    },
    snomed_codevalue(obj){        
        this.form.problem_id = obj.id;
        this.form.SNOMED_CT = obj.code;
		this.form.ICD_10 = null;
        this.form.problem = obj.description;
		this.$refs.snomed_code.searchText = obj.code;
        this.$refs.autocomplete.searchText = obj.description;
        this.$refs.autocompleteicd10.searchText = '';
        this.$emit("update:modelValue", obj.code);
    },
    setCurrentDate() {
      var currentDate = new Date();
      
      var month = currentDate.getMonth() + 1;
      var day = currentDate.getDate();
      var year = currentDate.getFullYear();
      var hours = currentDate.getHours();
      var minutes = currentDate.getMinutes();
      console.log('hours:- ', hours);
      var ampm = hours >= 12 ? 'PM' : 'AM';

      hours = hours % 12;
      hours = hours ? hours : 12;
      
      month = month < 10 ? '0' + month : month;
      day = day < 10 ? '0' + day : day;
      hours = hours < 10 ? '0' + hours : hours;
      // minutes = minutes < 10 ? '0' + minutes : minutes;  // If need to set the exact current time then just uncomment this and comment the next line
      minutes = minutes >= 30 ? '30' : '00';
      
      var formattedDate = month + '-' + day + '-' + year + ' ' + hours + ':' + minutes + ' ' + ampm;
      
      this.datetime_diagnosis = formattedDate;
      this.datetime_onset = formattedDate;
      // this.datetime_changed = formattedDate;
    }
  },
};
</script>
<style type="text/css" scoped="">
.vue3-autocomplete-container {
  width: 100%;
}
</style>
