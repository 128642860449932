<template>
    <div class="mdc-tab">
        <div class="tab-content">
            <div class="tab-pane active" id="billingSummary" role="tabpanel">
                <div class="box">
                    <div class="box-header with-border bg-secondary">
                        <h2 class="black-color m-0 fs-20" id="creditpayment">Payment</h2>
                    </div>
                    <div class="box-body text-dark">
                        <form @submit.prevent id="paymentForm" method="post">
                            <div style="margin-bottom: 10px; color: #c0392b;" v-if="serverError">{{ serverError }}</div>
                            <div class="form-group" v-if="this.billing_summary.order_source == 'POS' || newPos == true">
                                <label for="name" class="form-label">Purpose Of Payment</label>
                                <div class="input-group">
                                    <select name="select" class="form-select form-control" v-model="payment_frm.purpose_of_payment" :disabled="newPos == false && order_id != ''">
                                        <option disabled="" value="">Select Purpose Of Payment</option>
                                        <option value="other">Add New</option>
                                        <option v-for="item in purposeofpayment" :value="item.id">{{ item.purposeofpayment }}
                                        </option>
                                    </select>
                                    <div class="invalid-feedback" style="display:block;"
                                        v-for="(error, index) of v$.payment_frm.purpose_of_payment.$errors" :key="index">
                                        {{ error.$message }}
                                    </div>
                                </div>
                            </div>
                            <div class="form-group" v-show="payment_frm.purpose_of_payment == 'other'">
                                <div class="input-group">
                                    <input type="text" id="purpose_of_payment" class="form-control"
                                        v-model="payment_frm.purposeofpaymentother" placeholder="Add New" />
                                    <div class="invalid-feedback" style="display:block;">{{ purpose_of_paymenterror }}</div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="name" class="form-label">Billing Amount</label>
                                <div class="input-group">
                                    <span class="input-group-addon">$</span>
                                    <input type="text" class="form-control" :readonly="this.billing_summary.order_source != 'POS' && newPos == false" v-model="payment_frm.payable_amount" placeholder="Billing Amount" @blur="handlePaybleBlur" />
                                    <div class="invalid-feedback" style="display:block;"
                                        v-for="(error, index) of v$.payment_frm.payable_amount.$errors" :key="index">
                                        {{ error.$message }}
                                    </div>
                                    <div class="invalid-feedback" style="display:block;">{{ payable_amountterror }}</div>
                                </div>
                            </div>
                            <div class="form-group" v-if="this.billing_summary.order_payment_info.length > 0">
                                <label for="name" class="form-label">Received Amount</label>
                                <div class="input-group">
                                    <span class="input-group-addon">$</span>
                                    <input type="text" class="form-control" readonly="" v-model="billing_summary.received_amount" placeholder="Billing Amount" />
                                </div>
                            </div>
                            <div class="form-group" v-if="this.billing_summary.refund_amount > 0">
                                <label for="name" class="form-label">Refund Amount</label>
                                <div class="input-group">
                                    <span class="input-group-addon">$</span>
                                    <input type="text" class="form-control" readonly="" v-model="billing_summary.refund_amount" placeholder="Billing Amount" />
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="form-label">Amount to Charge</label>
                                <div class="input-group">
                                    <span class="input-group-addon">$</span>
                                    <input type="text" class="form-control" v-model="payment_frm.paid_amount" v-on:keypress="numbersOnly" placeholder="Enter Amount" @blur="payTypeChange" />
                                    <div class="invalid-feedback" style="display:block;" v-for="(error, index) of v$.payment_frm.paid_amount.$errors" :key="index">
                                        {{ error.$message }}
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="form-label">Payment Method</label>
                                <select class="form-select form-control" v-model="payment_frm.payment_type"  id="payment_type" @change="payTypeChange">
                                    <option value="">- Select Payment Type -</option>
                                    <option label="Cash" value="Cash">Cash</option>
                                    <option label="Check" value="Check">Check</option>
                                    <option label="Credit Card" value="Credit Card">Credit Card</option>
                                    <option label="Other" value="Other">Other</option>
                                    <option v-if="$store.state.patientDetail.wallet_amount > 0" :label="'Wallet (' + $filters.toUSD($store.state.patientDetail.wallet_amount) + ')'" value="Wallet">Wallet ({{ $filters.toUSD(this.$store.state.patientDetail.wallet_amount) }}) </option>
                                </select>
                                <div class="invalid-feedback" style="display:block;" v-if="wallet_error != ''">{{ wallet_error }}</div>
                                <div class="invalid-feedback" style="display:block;" v-for="(error, index) of v$.payment_frm.payment_type.$errors" :key="index">
                                    {{ error.$message }}
                                </div>
                            </div>
                            <div class="" v-show="payment_frm.payment_type == 'Other'">
                                <div class="form-group">
                                    <input type="text" class="form-control" v-model="payment_frm.other_payment_type" placeholder="Enter Other Payment">
                                    <div class="invalid-feedback" style="display:block;" v-for="(error, index) of v$.payment_frm.other_payment_type.$errors" :key="index">
                                        Other payment is required.
                                    </div>
                                </div>
                            </div>
                            <div class="" v-show="payment_frm.payment_type == 'Check'">
                                <div class="form-group">
                                    <label class="form-label">Check No.</label>
                                    <input type="text" class="form-control" v-model="payment_frm.check_trace" placeholder="Check No.">
                                    <div class="invalid-feedback" style="display:block;" v-for="(error, index) of v$.payment_frm.check_trace.$errors" :key="index">
                                        Check No. required
                                    </div>
                                </div>
                            </div>
                            <div class="" v-if="payment_frm.payment_type == 'Credit Card'">
                                <div class="form-group">
                                    <select class="form-select form-control" v-model="payment_frm.cctoken_id">
                                        <option value="">- Select Credit-Card -</option>
                                        <option value="addNew">Add New Card</option>
                                        <option v-for="(item, index) in ccTokenData" :value="item.id" :data-val="item.id" :disabled="item.is_expired == true">{{ item.card_label }}</option>
                                    </select>
                                    <div class="invalid-feedback" style="display:block;" v-for="(error, index) of v$.payment_frm.cctoken_id.$errors" :key="index">
                                        Please select any option.
                                    </div>
                                </div>
                            </div>
                            <div class="form-group"
                                v-show="payment_frm.payment_type == 'Credit Card' && (payment_frm.cctoken_id == 'addNew')">
                                <div style="display: none" id="customStyles">
                                    body{margin:0;}
                                    iframe{height: 100px;}
                                    #payment-form {border: 1px solid rgb(212, 221, 230);max-height: 130px;width:
                                    100%;padding: 20px 10px;border-radius: 4px;margin: 0px;background: rgb(245, 248, 250);}
                                    #payment-form {color: #2d3c58;}
                                    #payment-form .input-label {color: #2d3c58;border-top: 1px solid
                                    #d4dde6;font-size:13px;text-align: center !important;}
                                </div>
                                <div id="card" style="height: 130px;"></div>
                                <div id="errorMessage" style="margin-bottom: 8px; color: #c0392b;"></div>
                                <input type="checkbox" id="use_credidcard_for_future_checkbox"
                                    class="filled-in chk-col-info" v-model="payment_frm.use_credidcard_for_future" />
                                <label for="use_credidcard_for_future_checkbox" class="m-0 text-start">Save for future
                                    use?</label>
                            </div>
                            <div class="form-group" v-if="newPos == true">
                                <label for="payment_note" class="m-0 text-start">Payment Note <i class="fa fa-toggle-down toggle-macro ms-1 cursor-pointer" title="toggle macros" style="color:#00abeb"></i></label>
                                <textarea class="form-control" id="payment_note" v-model="payment_frm.payment_note" rows="2" placeholder="Payment Note"></textarea>
                                <div class="d-none macros panel panel-default macros-container p-0 box-bordered position-absolute"><div class="panel-heading bg-light text-dark">Macros</div><div class="panel-body bg-white"></div></div>
                            </div>
                        </form>
                        <button v-if="permissioncheck(this.$store.state.permission.create_update)" type="button" :disabled="!this.orderId && newPos == false" class="waves-effect waves-light btn  mb-5 me-1 waves-effect waves-light btn btn-info me-1" @click="handlePaymentPay">Pay</button>
                        <a v-if="permissioncheck(this.$store.state.permission.create_update)" href="javascript:void(0)" @click.prevent="resetForm" class="waves-effect waves-light btn mb-5 me-1 btn-danger">Clear</a>
                    </div>
                </div>
                <div v-if="!is_production_env">
                    <div class="table-responsive">
                        <table class="ql-table-blob table" data-aura-rendered-by="9911:0">
                            <thead>
                                <tr>
                                    <th>Card Type</th>
                                    <th>Number</th>
                                    <th>Expiry Date</th>
                                    <th>Security Code</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Mastercard</td>
                                    <td>5439750001500347</td>
                                    <td>12/29</td>
                                    <td>999</td>
                                </tr>
                                <tr>
                                    <td>Visa</td>
                                    <td>4005550000000019</td>
                                    <td>12/29</td>
                                    <td>999</td>
                                </tr>
                                <tr>
                                    <td>Discover</td>
                                    <td>60011111111111117</td>
                                    <td>12/29</td>
                                    <td>999</td>
                                </tr>
                                <tr>
                                    <td>Diners</td>
                                    <td>36999999999999</td>
                                    <td>12/29</td>
                                    <td>999</td>
                                </tr>
                                <tr>
                                    <td>AMEX</td>
                                    <td>374255312721002</td>
                                    <td>12/29</td>
                                    <td>9999</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="tab-pane animation-fade active" id="category-1" role="tabpanel">
            <div class="panel-group panel-group-simple panel-group-continuous mb-0" id="accordion2" aria-multiselectable="true" role="tablist"> </div>
        </div>
    </div>
</template>
<script>
import permissionMixins from "@/scripts/mixins/permission.js";
import axios from "@/scripts/axios.js";
import submitMixins from "@/scripts/mixins/submitMixins.js";
import useVuelidate from "@vuelidate/core";
import {
    required,
    helpers,
    minLength,
    maxLength,
    numeric,
    requiredIf,
} from "@vuelidate/validators";
export default {
    props: {
        orderId: {
            type: null,
            required: false,
            default: null,
        },
        newPos: {
            default: false,
            type: Boolean,
            required: false
        },
    },
    components: {
        billing_order_id: function () {
            return this.orderId;
        },
    },
    setup() {
        return { v$: useVuelidate() };
    },
    mixins: [submitMixins, permissionMixins],
    data() {
        return {
            is_production_env: process.env.VUE_APP_NODE_ENV == 'production',
            appointment_id: '',
            appointment_number: '',
            appointmentData: [],
            hash: '',
            payment_frm: {
                order_id: this.orderId,
                payment_type: '',
                basic_amount: 0,
                discount_amount: 0,
                payable_amount: 0,
                paid_amount: '',
                check_trace: '',
                other_payment_type: '',
                purpose_of_payment: '',
                payment_note: '',
                cctoken_id: '',
                use_credidcard_for_future: false,
                patient_id: this.$store.state.patientDetail.patient_id,
                patient_unique_id: this.$store.state.patientDetail.patient_unique_id,
            },
            billing_summary: {
                healthcare_amount: 0,
                custom_amount: 0,
                basic_amount: 0,
                discount_amount: 0,
                payable_amount: 0,
                received_amount: 0,
                refund_amount: 0,
                order_payment_info: [],
                order_info: {},
            },
            tokenpay: {},
            tokenPayReady: false,
            checkingInterval: null,
            serverError: '',
            purposeofpayment: '',
            payable_amountterror: '',
            wallet_error: '',
            ccTokenData: [],
            receipt_url: '',
            embed: 0,
            publicKey: '',
            purpose_of_paymenterror: "",
            axiosChange: "",
        };
    },
    validations() {
        return {
            payment_frm: {
                purpose_of_payment: {
                    required: requiredIf(function () {
                        return (this.billing_summary.order_source == 'POS' || this.newPos) ? true : false;
                    }),
                },
                payable_amount: {
                    required: helpers.withMessage("Billing amount is required.", required),
                },
                paid_amount: {
                    required: helpers.withMessage("Amount is required.", required),
                },
                payment_type: {
                    required: helpers.withMessage("Payment Type is required.", required),
                },
                check_trace: {
                    required: requiredIf(function () {
                        return this.payment_frm.payment_type == 'Check' ? true : false;
                    }),
                },
                other_payment_type: {
                    required: requiredIf(function () {
                        return this.payment_frm.payment_type == 'Other' ? true : false;
                    }),
                },
                cctoken_id: {
                    required: requiredIf(function () {
                        return this.payment_frm.payment_type == 'Credit Card' ? true : false;
                    }),
                },
            },
        };
    },
    async created() {
        let localThis = this;
        this.fetchPatientToken();
        this.fetchOrderSummary(this.orderId);
        this.fetchPurposeOfPayment();
        const result = await this.getTpConfigByKey('RECTANGLEHEALTH', 'PUBLICKEY');
        if (result) {
            this.publicKey = result.data.PUBLICKEY;
            this.checkingInterval = setInterval(function () {
                if (window.TokenPay) {
                    localThis.tokenPayReady = true
                }
            }, 500);
        }
    },
    watch: {
        tokenPayReady: function (data) {
            if (data) {
                clearInterval(this.checkingInterval)
                this.initializeToken()
            }
        },
        axiosChange: function() {
            this.macrosetup(this.axiosChange);
        },
    },
    mounted() {
        let bridgePayScript = document.createElement('script')
        bridgePayScript.setAttribute('src', process.env.VUE_APP_API_RECTANGLEHEALTH_TOKENPAY_URL)
        document.head.appendChild(bridgePayScript);
        this.macroGenerateFunction();
    },
    methods: {
        numbersOnly(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();;
            } else {
                return true;
            }
        },
        handlePaybleBlur() {
            if (this.payment_frm.paid_amount <= 0 && !this.orderId) {
                this.payment_frm.paid_amount = this.payment_frm.payable_amount;
            }
            if ((parseFloat(this.payment_frm.paid_amount) + parseFloat(this.billing_summary.received_amount) - parseFloat(this.billing_summary.refund_amount)) > parseFloat(this.payment_frm.payable_amount)) {
                this.payable_amountterror = "Billing amount should not lesser then (receive amount + amount to charged).";
                return false;
            } else {
                this.payable_amountterror = '';
            }
        },
        payTypeChange() {
            if (this.payment_frm.payment_type == 'Wallet' && parseFloat(this.payment_frm.paid_amount) > parseFloat(this.$store.state.patientDetail.wallet_amount)) {
                this.wallet_error = 'Charge amount should not greater then wallet amount.';
            } else {
                this.wallet_error = '';
            }
        },
        fetchPurposeOfPayment() {
            axios.get('/json_dropdown/purposeofpayment')
                .then((response) => {
                    this.$store.state.loader = false;
                    this.purposeofpayment = response.data.data;
                });
        },
        fetchOrderSummary(orderId) {
            if (orderId) {
                axios.post('payment/orderSummary', { order_id: orderId, patient_id: this.$store.state.patientDetail.patient_id })
                    .then((response) => {
                        this.billing_summary.order_source = response.data.data.order_source;
                        this.billing_summary.healthcare_amount = response.data.data.healthcare;
                        this.billing_summary.custom_amount = response.data.data.custom_charge;
                        this.billing_summary.payable_amount = response.data.data.payable_amount;
                        this.payment_frm.purpose_of_payment = response.data.data.purposeofpayment;
                        this.payment_frm.payable_amount = response.data.data.payable_amount;
                        this.billing_summary.received_amount = response.data.data.received_amount;
                        this.billing_summary.refund_amount = response.data.data.refund_amount;
                        this.billing_summary.order_payment_info = response.data.data.order_payment_info;
                        let remaing_amt = (parseFloat(response.data.data.payable_amount - response.data.data.received_amount) + parseFloat(response.data.data.refund_amount));
                        if (remaing_amt > 0) {
                            this.payment_frm.paid_amount = remaing_amt;
                        } else {
                            this.payment_frm.paid_amount = '';
                        }
                    });
            }
        },
        initializeToken: function () {
            this.tokenpay = {};
            if (this.publicKey) {
                this.tokenpay = TokenPay(this.publicKey);
                this.tokenpay.initialize({
                    dataElement: '#card',
                    errorElement: '#errorMessage',
                    amountElement: 'amount',
                    useACH: false,
                    useStyles: true,
                    disableZip: true,
                    disableCvv: false
                });
            }
        },
        fetchPatientToken: function () {
            axios.post('/payment/patient-cctoken', { patient_id: this.$store.state.patientDetail.patient_id })
                .then((response) => {
                    this.ccTokenData = response.data.data;
                    this.ccTokenData.forEach((value, index) => {
                        if (value.is_default == 1 && value.is_expired == false) {
                            this.payment_frm.cctoken_id = value.id;
                        }
                    });
                });
        },
        resetForm: function () {
            this.v$.$reset();
            this.payment_frm.payment_type = '';
            this.payment_frm.paid_amount = '';
            this.payment_frm.check_trace = '';
            this.payment_frm.other_payment_type = '';
            this.payment_frm.cctoken_id = '';
            this.payment_frm.use_credidcard_for_future = false;
            this.payment_frm.payable_amount = '';
            this.payment_frm.purpose_of_payment = '';
            this.payment_frm.payment_note = '';
            this.ccTokenData.forEach((value, index) => {
                if (value.is_default == 1 && value.is_expired == false) {
                    this.payment_frm.cctoken_id = value.id;
                }
            });
        },
        handlePaymentCapture: function () {
            console.log(this.payment_frm);
            var $this = this;
            $this.$store.state.loader = true;
            axios.post("payment/storePatientBillingPayment", this.payment_frm)
                .then((response) => {
                    if (this.payment_frm.payment_type == 'Wallet') {
                        this.$store.dispatch("refreshCurrentWallet");
                    }
                    $this.resetForm();
                    $this.$store.state.loader = false;
                    $this.moshaToast(response.data.message, "success");
                    $this.fetchOrderSummary($this.orderId);
                    $this.fetchPatientToken();
                    this.$store.state.PaymentGatewayMigration.hidemodal = true;
                })
                .catch((error) => {
                    $this.$store.state.loader = false;
                    $this.serverError = error.response.data.message;
                });
        },
        handlePaymentPay: function () {
            this.v$.$validate();
            if (!this.v$.$error) {
                if ((parseFloat(this.payment_frm.paid_amount) + parseFloat(this.billing_summary.received_amount) - parseFloat(this.billing_summary.refund_amount)) > parseFloat(this.payment_frm.payable_amount)) {
                    this.payable_amountterror = "Billing amount should not lesser then (receive amount + amount to charged).";
                    return false;
                } else {
                    this.payable_amountterror = '';
                }
                if ($('#purpose_of_payment').val() == '' && this.payment_frm.purpose_of_payment == 'other') {
                    this.purpose_of_paymenterror = "Purpose Of Payment is required";
                    return false;
                }
                this.serverError = '';
                this.wallet_error = '';
                this.$store.state.loader = true;
                var vm = this;
                if (this.payment_frm.payment_type == 'Credit Card' && this.payment_frm.cctoken_id == 'addNew') {
                    this.tokenpay.createToken(function (result) {
                        vm.payment_frm.token = result.token;
                        vm.handlePaymentCapture();
                    }, function (result) {
                        vm.$store.state.loader = false;
                    });
                } else if (this.payment_frm.payment_type == 'Wallet') {
                    if (parseFloat(this.payment_frm.paid_amount) > parseFloat(this.$store.state.patientDetail.wallet_amount)) {
                        this.wallet_error = 'Charge amount should not greater then wallet amount.';
                        this.$store.state.loader = false;
                    } else {
                        vm.handlePaymentCapture();
                    }
                } else {
                    vm.handlePaymentCapture();
                }
            }
        },
        macroGenerateFunction(){
		    let self = this;
            $(document).on("click",".toggle-macro", function(){
                if($(".macros").hasClass("d-none") && $(this).hasClass("fa-toggle-down")){
                    $(".toggle-macro").removeClass("fa-toggle-up").addClass("fa-toggle-down");
                    var textArea = $(this).parent().next("textarea");
                    $(".macros").addClass("d-none");
                    setTimeout(function(){
                        textArea.next("div.macros").removeClass("d-none");   
                        textArea.next("div.macros").attr("id","macros-"+textArea.attr("id"));
                        self.macros("macros-"+textArea.attr("id"));
                    }, 100);	
                    $(this).removeClass("fa-toggle-down").addClass("fa-toggle-up");
                } else {
                    $("div.macros").addClass("d-none");
                    $(this).removeClass("fa-toggle-up").addClass("fa-toggle-down");
                }
            });
            $(document).click(function(e) {
                if($(e.target).hasClass("copy-content") || e.target.nodeName!=='TEXTAREA')
                {
                    $(".macros").addClass("d-none");
                }
            });
	    },
        macros(_fieldId){
            var $this = this;
            var a = typeof this.$store.state.clinicalNote.macros;			
            axios.post('macros-list',{'patient_name': this.$store.state.patientDetail.first_name+' '+this.$store.state.patientDetail.last_name})
                .then((response) => {
                    (this.$store.state.clinicalNote.macros = response.data.data.map((macros) => {
                        return { macro_title: macros.macro_title, macro_text: macros.macro_text };
                    }))
                    this.axiosChange = _fieldId;
            });
	    },
        macrosetup(_fieldId){
            var html = "", $this = this;
            $.each(this.$store.state.clinicalNote.macros,function(index,value){
                html += "<a href='javascript:void(0)' class='badge badge-dark py-1 copy-content m-1' data-id='"+index+"'>"+$this.nl2br(value.macro_title, 1)+"</a>";
            });
            $("div[id='"+_fieldId+"']").find(".panel-body").html(html);
            $(document).on("click",".copy-content",function(){
                _fieldId = $this.axiosChange;
                var macroIndex = $(this).attr("data-id");                
                if($("div[id='"+_fieldId+"']").hasClass("d-none") === false){
                    var eleId = $("div[id='"+_fieldId+"']").prev().attr("id"), 
                    compIdDetail = eleId.split("-"), 
                    compId = compIdDetail[0];
                    $this.payment_frm.payment_note = $("div[id='"+_fieldId+"']").prev().val()+' '+$this.$store.state.clinicalNote.macros[macroIndex].macro_text;
                    $("textarea[id='"+compId+"']").val($("div[id='"+_fieldId+"']").prev().val()+' '+$this.$store.state.clinicalNote.macros[macroIndex].macro_text);
                }
            });
        },
        nl2br (str, is_xhtml) {   
            var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';    
            return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1'+ breakTag +'$2');
        },
    },
}
</script>