<template>
	<div class="content-wrapper">
		<div class="container-full">
			<div class="content-header">
				<div class="row justify-content-between">
					<div class="col-12 col-sm-8 col-md-9 col-lg-6 col-xs-6">
						<h1 class="mt-0">Vitals Management</h1>
					</div>
					<div class="col-12 col-sm-4 col-md-3 col-lg-3 col-xs-2 text-sm-end">
						<a href="javascript:void(0)" class="waves-effect waves-light btn btn-info mb-5" @click="opendialog('add')"><i class="fa fa-plus fs-14"></i> New Vitals</a>
					</div>
				</div>
			</div>
			<div class="row">
					<section class="content">
						<div class="box">
							<div class="box-body">
								 <div class="table-responsive">
									<data-table dataType="Vitals" :key="render">
									
										 <template v-slot="slotProps">
											
											<td>
												{{ slotProps.item.vital}}
											</td>
											<td>
												<span class="badge badge-info-light fs-16" v-if="slotProps.item.created_by==0">System Default
												</span>
												<span class="badge badge-info-light fs-16" v-else>
													Custom
												</span>
											</td>
											<td>										
							                    <span>
							                        <button type="button"  data-on="Ready" data-off="Not Ready" @click="onToggleChange(slotProps.item.id, $event,slotProps.item.vital)" :id="['status_' + slotProps.item.id]" class="btn btn-toggle rounded30-im"  v-bind:class="[slotProps.item.status == 'Active'? 'active' : '']" data-bs-toggle="button" aria-pressed="true" autocomplete="off">
							                          <div class="handle rounded30-im"></div>
							                        </button>
							                    </span>
											</td>
											<td>
												<Popper arrow content="Edit vital" :hover="true" class="helptips cursor-pointer" v-if="slotProps.item.created_by!=0">
													<a @click="opendialog('edit',slotProps.item.id)" href="#" class="waves-effect waves-circle btn btn-circle btn-info btn-xs me-1"><i class="fa fa-pencil"></i></a>
												</Popper>
												<Popper arrow content="Delete Vital" :hover="true" class="helptips cursor-pointer" v-if="slotProps.item.created_by!=0">
													<a @click="deletevital(slotProps.item.id)" href="#" class="waves-effect waves-circle btn btn-circle btn-danger btn-xs me-1"><i class="fa fa-trash" aria-hidden="true"></i></a>
												</Popper>
											</td>
										</template>
									</data-table>
								</div> 
							</div>
						</div>
					</section>
				</div>
			</div>
			<div id="myContentModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
				<div class="modal-dialog modal-xl">
					<div class="modal-content">
						<div class="modal-header">
							<h2 class="modal-title m-0">{{this.title}}</h2>
							<button type="button" class="btn-close btn-xs" data-bs-dismiss="modal" aria-label="Close" @click="closeModal()"></button>
						</div>
						<div class="modal-body">
								<div class="form-group">
										<multi-select-box
											title="Units"
											:isRequired="true"
											v-model="v$.form.unit_name.$model"
											:errors="v$.form.unit_name.$errors"
											getDataURL="/vital/unitlist"	
										></multi-select-box>
								</div>
								<input-field
									:isRequired="true"
									title="Vital Name"
									v-model="v$.form.vital.$model"
									:errors="v$.form.vital.$errors"
									placeholder="e.g. Temperature"
								>
								</input-field>
								<input-field
									title="Vital Code"
									v-model="form.vital_code"
									placeholder="e.g. 8310-5"
									>
								</input-field>	
								<input type="hidden" name="provider_id" v-model="this.form.provider_id">
						</div>
						<div class="modal-footer">
							<div v-if="this.vital_id!=0">
								<form-btn
									title="Update"
									btnCls="btn-info saveData"
									@click="save(true)"
									></form-btn>
							</div>
							<div v-else>
									<form-btn
									title="Save"
									btnCls="btn-info saveData"
									@click="save(false)"
									></form-btn>
							</div>
									
							<button type="button" class="addpatientbtn btn-danger b-0" data-bs-dismiss="modal" @click="closeModal()">Close</button>
						</div>
					</div>
					
				</div>
			</div>
		</div>
		
</template>

<script>
	import axios from "@/scripts/axios.js";
	import InputField from "@/components/base/formFields/InputField.vue";
	import useVuelidate from "@vuelidate/core";
	import submitMixins from "@/scripts/mixins/submitMixins.js";
	import dataTable from "@/components/base/DataTable.vue";
    import MultiSelectBox from "@/components/base/formFields/multiSelectBox.vue";
	import FormBtn from "@/components/base/formFields/formBtn.vue";
	export default {
		name: "Home",
		mixins: [submitMixins],
		components: {InputField, dataTable,MultiSelectBox,FormBtn},
		setup: () => {
			return { v$: useVuelidate() };
		},
		computed: {
			form() {
				return this.$store.state.Vitals.form;
			},
			vuelidateExternalResults() {
				return this.$store.state.Vitals.vuelidateExternalResults;
			},
			validationRules() {
				return this.$store.state.Vitals.validationRules;
			},
		},
		data() {
			return {
				list:[],
             unitList:null,  
			 title:"Add Vital",
			 vital_id:0,
			 id:0,
			}
		},
		async beforeCreate(){
		Object.assign(
			this.$store.state.Vitals.form,
			this.$store.state.Vitals.defaultFormData
		);
    	},
		mounted(){
			axios.get("/vital/unitlist", {
            }).then(response => { 
            this.unitList = response.data.data 
            }); 
			this.form.provider_id=this.$store.state.auth.userData.user_id;
		},
		methods:{
			save(next) {
				
				
				this.v$.$validate();
				if (!this.v$.$error) {
					// console.log(this.form);
					this.errorMessage = '';
					if (next == true){
						axios.post("/vital/vital-status-update",{"id":this.id,"form":this.form})
						.then(response => { if(response.status==200){
							this.moshaToast("Records Updated successfully",'success');
							$('#myContentModal').modal('hide');	
							 setTimeout(function(){location.reload()}, 3000);
						}})
					}else{
						// console.log(next);
						// return false;
						axios.post("/vital/defultstore",this.form)
						.then(response => { 
						console.log(response);
						if(response.status==200){
							this.moshaToast("Records add successfully",'success');
							$('#myContentModal').modal('hide');	
							
							 setTimeout(function(){location.reload()}, 3000);
						}
						});
					}
				}
			},
			opendialog(type,id){
				if (type=="edit") {
					axios.post("/vital/edit",{'id':id})
						.then(response => {
							this.title="Edit Vital";
							this.id=id;
							this.form.vital=response.data.data.result.vital;
							var arr=[];
							response.data.data.units.forEach(element => {
								arr.push(element.id);
							});
							let arrdata=arr.toString();
							this.form.unit_name=arrdata;
							this.form.vital_code=response.data.data.result.vital_code;
							this.vital_id=response.data.data.result.vital_id;
							console.log(this.vital_id);
						});
				}
				$('#myContentModal').modal('show');	
			},
			closeModal(){
				this.title="Add Vital";
				 this.$store.state.Vitals.form = {};
				this.form.unit_name="";
				this.vital_id=0;
				$('#myContentModal').modal('hide');	
			},
			onToggleChange(id, event,vital) {
		      var class_list = document.getElementById("status_"+id).classList;
		      var status = '';
		      if(class_list.contains('active')) {
		        status = 'Active';
		      } else {
		        status = 'Deactive';
		      }
			// console.log(status);
		      axios
		        .post("vital/status-update",{"status":status,"id":id,"vital":vital})
		        .then((response) => {
		          this.moshaToast('Status updated successfully.','success');
				  setTimeout(function(){location.reload()}, 3000);
		        }) .catch(function (error) {
					this.moshaToast('Oops! something Wrong','danger');
				});
							
			},
			deletevital(id){
				 axios
		        .post("vital/status-delete",{"id":id})
		        .then((response) => {
		          this.moshaToast('Status deleted successfully.','success');
				 setTimeout(function(){location.reload()}, 3000);
		        }).catch(function (error) {
					this.moshaToast('Oops! something Wrong','danger');
				});
			}
		},
		validations() {
			return this.$store.state.Vitals.validationRules;
		},
	
};
</script>
<style type="text/css">
	.btn-toggle:before{
		content: 'Inactive';
		left: -6rem;
	}
	.btn-toggle:after{
		content: 'Active';
		right: -5rem;
	}
</style>