<template>
  <div>
    <div class="col-12 col-sm-8 col-md-9 col-lg-6 col-xs-6">
        <h1 class="heading1 mt-0">Socket Home </h1>
        <textarea cols="40" id="textArea" rows="10"></textarea>
        <br />
        <button type="button" @click="toggleRbtn">{{recordButtonValue}}</button>
    </div>
  </div>
</template>

<script>
import { downsampleBuffer, pcmEncode } from '@/assets/awsSpeechToText/audioUtils.js';  // for encoding audio data as PCM
import crypto from "crypto"; // tot sign our pre-signed URL
import v4 from '@/assets/awsSpeechToText/aws-signature-v4.js'; // to generate our pre-signed URL
import { EventStreamMarshaller } from "@aws-sdk/eventstream-marshaller"; // for converting binary event stream messages to and from JSON
import { toUtf8, fromUtf8 } from "@aws-sdk/util-utf8-node"; // utilities for encoding and decoding UTF8
import MicrophoneStream from "microphone-stream";

var micStream1;
var socket;
var inputSampleRate = 0;
var sampleRate = 44100;
var eventStreamMarshaller;
var transcription="";
var textAreaData="";

const getAudioEventMessage = function(buffer) {
    return {
        headers: {
            ':message-type': {
                type: 'string',
                value: 'event'
            },
            ':event-type': {
                type: 'string',
                value: 'AudioEvent'
            }
        },
        body: buffer
    };
}

const convertAudioToBinaryMessage = function(audioChunk) {
    var raw = MicrophoneStream.toRaw(audioChunk);
    if (raw == null) return; 

    var downsampledBuffer = downsampleBuffer(raw, inputSampleRate, sampleRate);
    var pcmEncodedBuffer = pcmEncode(downsampledBuffer);
    var audioEventMessage = getAudioEventMessage(Buffer.from(pcmEncodedBuffer));
    var binary = eventStreamMarshaller.marshall(audioEventMessage);

    return binary;
}

const getEventUnMarshall = function(mData) {
    return eventStreamMarshaller.unmarshall(Buffer(mData));
}

const handleEventStreamMessage = function(messageJson) {
    let results = messageJson.Transcript.Results;

    if (results.length > 0) {
        if (results[0].Alternatives.length > 0) {
            let transcript = results[0].Alternatives[0].Transcript;
            transcript = decodeURIComponent(escape(transcript));
            textAreaData = transcription + transcript + "\n";
            // console.log(transcription + transcript + "\n");
            document.getElementById("textArea").value = textAreaData;
            if (!results[0].IsPartial) {
                transcription += transcript + "\n";
            }
        }
    }
}

export default {
    name: 'HelloWorld',
    props: {
        msg: String
    },
    data() {
        return {
            recordButtonValue : "Start Recording",
            recording: true,
            region: "us-east-1",
            languageCode: "en-US",
            // sampleRate: 44100,
            // inputSampleRate: 0,
        }
    },
    methods: {
        toggleRbtn() {

            if(!this.recording) {
                console.log("Stop");
                this.recording = true;
                micStream1.stop();

                if (socket.readyState === socket.OPEN) {
                    let emptyMessage = getAudioEventMessage(Buffer.from(new Buffer([])));
                    let emptyBuffer = eventStreamMarshaller.marshall(emptyMessage);
                    socket.send(emptyBuffer);
                }

            } else {
                document.getElementById("textArea").value = textAreaData;
                this.recording = false;
                micStream1 = new MicrophoneStream();
                eventStreamMarshaller = new EventStreamMarshaller(toUtf8, fromUtf8);
            
                window.navigator.mediaDevices.getUserMedia({ video: false, audio: true })
                .then(function(stream) {
                    micStream1.setStream(stream);
                }).catch(function(error) {
                    console.log(error);
                });

                micStream1.on('format', function(format) {
                    console.log('format', format);
                    inputSampleRate = format.sampleRate;
                });

                var url = this.createPresignedUrl();
                socket = new WebSocket(url);
                socket.binaryType = "arraybuffer";

                

                socket.onopen = function () { 
                    micStream1.on('data', function(chunk) {
                        // const raw = MicrophoneStream.toRaw(chunk)
                        var binary = convertAudioToBinaryMessage(chunk);
                        // console.log("Raw: ", binary);
                        if (socket.readyState === socket.OPEN) socket.send(binary);
                    });
                };

                socket.onmessage = function (message) { 
                    var messageWrapper = getEventUnMarshall(message.data);
                    var messageBody = JSON.parse(String.fromCharCode.apply(String, messageWrapper.body));

                    if (messageWrapper.headers[":message-type"].value === "event") {
                      handleEventStreamMessage(messageBody);
                    } else {
                      // transcribeException = true;
                      // showError(messageBody.Message);
                      // toggleStartStop();
                    }
                }

                
            }

            this.recordButtonValue = this.recordButtonValue == "Start Recording" ? "Stop Recording" : "Start Recording";
            
        },
        createPresignedUrl() {
            try {
                var endpoint = "transcribestreaming." + this.region + ".amazonaws.com:8443";
                console.log("endpoint: ", endpoint);

                return v4.createPresignedURL('GET', endpoint, '/stream-transcription-websocket', 'transcribe', crypto.createHash('sha256').update('', 'utf8').digest('hex'), {
                    'key': "AKIAWCLMOPSZNRB2LN3C",
                    'secret': "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",
                    'sessionToken': "",
                    'protocol': 'wss',
                    'expires': 15,
                    'region': this.region,
                    'query': "language-code=" + this.languageCode + "&media-encoding=pcm&sample-rate=" + sampleRate
                });
            } catch(e) {
                console.error("createPresignedUrl E=> ", e);
            }
        },
    }
    
}
</script>

<style lang="scss">
.speech-to-txt,
.txt-to-speech {
  display: grid;
  width: 200px;
  height: 100px;
  border-radius: 20px;
  border: 2px solid grey;
  background-color: rgb(248, 245, 245);
  font-size: 38px;
  color: black;
  font-family: Arial, Helvetica, sans-serif;
  place-items: center;
}
.speech-transciption {
  width: 500px;
  padding: 20px;
  border: 2px solid grey;
  background-color: rgb(211, 228, 253);
  border-radius: 20px;
}
</style>