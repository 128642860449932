import {
  helpers,
  required,
} from "@vuelidate/validators";

const auditTemperProofLog = {
  namespaced: true,


  state: {
    list: "/audit-templer-log/",
    listUrl: "/audit-templer-log",
    //list: [],
    tableData: {
      header: [
         'Event Source',
         'Event ID',
         'Event Name',
         'Source Ip Address',
         'Event Time',
         '#',
      ],
    },
  },
  getters: {
  },
  mutations: {},
  actions: {},
};

export default auditTemperProofLog;
