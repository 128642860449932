<template>    
 <!-- <div class="box">
        <div class="box-body">
            <div class="pageheading">    -->
		<div class="box-body">
		<div class="mdc-tab">
			<ul class="nav nav-tabs mt-0 mb-20 text-center text-sm-start" role="tablist">
				<li class="nav-item"> <a  class="nav-link" href="#all" title="All" id="Allach" @click.prevent="changeTab('AllNotes','All')" data-bs-toggle="tab" role="tab" >All</a> </li>
				<li class="nav-item"> <a class="nav-link" href="#refill" title="Refill" id="Refillach" data-bs-toggle="tab" @click.prevent="changeTab('RefillNotes','Refill')" role="tab">Refills</a> </li>
				<li class="nav-item"> <a class="nav-link"  href="#WC-Check" title="WC Check" id="WcCheckach" data-bs-toggle="tab" @click.prevent="changeTab('WcNotes','WcCheck')" role="tab">WC Check</a> </li>
                <li class="nav-item"> <a class="nav-link" href="#General-Notes" title="Admin Notes" id="AdminNoteach" @click.prevent="changeTab('GeneralNotes','Admin Note')" data-bs-toggle="tab" role="tab">Admin Notes</a> </li>
                <li class="nav-item"> <a class="nav-link" id="MedListach" href="#med-list" title="Med List" @click.prevent="changeTab('MedNotes','Med List')" data-bs-toggle="tab" role="tab">Med List</a> </li>
				<!-- <li class="nav-item"> <a class="m-5" href="#" data-bs-toggle="modal" data-bs-target="#identification" title="Reference of Knowledge Base" v-on:click.prevent="reset()"><i class="fa fa-question-circle fa-lg pt-2"></i></a> </li> -->
			</ul>
            <div class="d-flex align-items-center justify-content-between">
                <h3 class="fw-500">{{admin_note_input.category}} Notes</h3>
            </div>
            <div class="form-group mb-10" v-if="admin_note_input.category == 'All' || all_category==true">
                <label class="form-label">Note Category</label>
                <select class="form-control" v-model="admin_note_input.category" @change="setCurrentCategory">
                    <option value="" class="d-none">select</option>
                    <option value="Refill">Refill</option>
                    <option value="WcCheck">Wc Check</option>
                    <option value="AdminNote">Admin Note</option>
                    <option value="MedList">Med List</option>
                </select>
                <div
                class="invalid-feedback"
                style="display:block;"
                v-if="!admin_note_input.category && admin_note_category_error"
                >
                {{ admin_note_category_error }}
                </div>
            </div>
            <template v-if="admin_note_input.category == 'WcCheck'">
                <template v-for="(item,key) in admin_wc_check_note" :key="key">
                    <div class="form-group mb-10">
                        <label class="form-label">{{item.question}}</label>
                        <input v-if="key!=14" type="text" v-model="item.answer" class="form-control">
                        <textarea v-if="key==14" type="text" v-model="item.answer" class="form-control" rows="3"></textarea>
                    </div>
                </template>
            </template>
            <template v-if="admin_note_input.category == 'AdminNote' || admin_note_input.category == 'MedList'">
                <div class="form-group mb-10">
                    <label class="form-label">Note</label>
                    <textarea type="text" v-model="admin_note_input.note" class="form-control" rows="7"></textarea>
                    <div
                    class="invalid-feedback"
                    style="display:block;"
                    v-if="!admin_note_input.note && admin_note_note_error"
                    >
                    {{ admin_note_note_error }}
                    </div>
                </div>
            </template>
            <div class="" v-if="admin_note_input.category == 'Refill'">
                <div class="d-flex justify-content-between">
                    
                    <div class="form-group mb-10 w-p100" v-if="this.is_new_refill_pharmacy">
                        <div class="controls">
                            <label class="form-label">Pharmacy</label>
                            <input id="template_title" type="text" v-model="refill_pharmacy_name" class="form-control" placeholder="Name" autocomplete="off">
                        </div>
                        <div class="error-msg">
                            <ul class="mb-0 p-0 fs-14"></ul>
                        </div>
                    </div>
                    <div class="ms-2 mt-30" v-if="this.is_new_refill_pharmacy">
                        <form-btn title="-" btnCls="waves-effect waves-light btn btn-info btn-xs" @click="this.is_new_refill_pharmacy = !this.is_new_refill_pharmacy;" style="padding:2px;"></form-btn>
                    </div>
                </div>
            </div>
            <div class="" v-if="!this.is_new_refill_pharmacy && admin_note_input.category == 'Refill'">
                <div class="d-flex justify-content-between">
                    <div class="form-group mb-10 w-p100">
                        <label class="form-label">Pharmacy</label>
                        <select name="select" class="form-select form-control" v-model="refill_pharmacy_id">
                            <option selected="selected" value="" disabled="disabled">Select Pharmacy</option>
                            <option v-for="item in admin_note_refill_pharmacy" :key="item.id"  :value="item.id">{{item.name}}</option>
                        </select>
                    </div>
                    <div class="ms-2 mt-30" v-if="!this.is_new_refill_pharmacy">
                        <form-btn title="+" btnCls="waves-effect waves-light btn btn-info btn-xs" @click="this.is_new_refill_pharmacy = !this.is_new_refill_pharmacy;" style="padding:2px;"></form-btn>
                    </div>
                </div>
            </div>
            <div class="form-group mb-10" v-if="admin_note_input.category == 'Refill'">
                <label class="form-label">Note</label>
                <textarea type="text" v-model="admin_note_input.note" class="form-control" rows="3"></textarea>
                <div
                class="invalid-feedback"
                style="display:block;"
                v-if="!admin_note_input.note && admin_note_note_error"
                >
                {{ admin_note_note_error }}
                </div>
            </div>
            <div class="row" v-if="admin_note_input.category == 'Refill'">
                <div class="col-md-6">
                    <div class="d-flex justify-content-between">
                        <div class="form-group mb-10 w-p100" v-if="this.is_new_refill_status">
                            <div class="controls">
                                <label class="form-label">Status</label>
                                <input id="template_title" type="text" v-model="refill_status_name" class="form-control" placeholder="Name" autocomplete="off">
                            </div>
                            <div class="error-msg">
                                <ul class="mb-0 p-0 fs-14"></ul>
                            </div>
                        </div>
                        <div class="ms-2 mt-30" v-if="this.is_new_refill_status">
                            <form-btn title="-" btnCls="waves-effect waves-light btn btn-info btn-xs" @click="this.is_new_refill_status = !this.is_new_refill_status;" style="padding:2px;"></form-btn>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between" v-if="!this.is_new_refill_status">
                        <div class="form-group mb-10 w-p100">
                            <label class="form-label">Status</label>
                            <select name="select" class="form-select form-control" v-model="refill_status_id">
                                <option selected="selected" value="" disabled="disabled">Select Status</option>
                                <option v-for="item in admin_note_refill_status" :key="item.id"  :value="item.id">{{item.name}}</option>
                            </select>
                        </div>
                        <div class="ms-2 mt-30" v-if="!this.is_new_refill_status">
                            <form-btn title="+" btnCls="waves-effect waves-light btn btn-info btn-xs" @click="this.is_new_refill_status = !this.is_new_refill_status;" style="padding:2px;"></form-btn>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group mb-10">
                        <label class="form-label">Patient Confirmation Received?</label>
                        <select class="form-control" v-model="admin_note_input.confirmation_received">
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="text-end">
                <a href="javascript:void(0)" class="waves-effect waves-light btn btn-info me-1" @click="handleAdminNoteSubmit">Submit</a>
                <a href="javascript:void(0)" @click.prevent="show_admin_noteForm=!show_admin_noteForm" class="waves-effect waves-light btn btn-danger">Cancel</a>
                
            </div>
		</div>
	</div>

</template>
<script>
    import permissionMixins from "@/scripts/mixins/permission.js";
    import axios from "@/scripts/axios.js";
    import FormBtn from "@/components/base/formFields/formBtn.vue";
    import submitMixins from "@/scripts/mixins/submitMixins.js";
    import useVuelidate from "@vuelidate/core";
    import {
    	required,
    	helpers,
    	minLength,
    	maxLength,
    	numeric,
    	requiredIf
    } from "@vuelidate/validators";
    export default {
        name: "NewNotes",
    	props: {
            patientId:String,
        },
        components: {
            FormBtn
        },
    	setup() {
    		return { v$: useVuelidate() };
    	},
    	mixins: [submitMixins,permissionMixins],
        computed: {
            adminNoteListFiltered: function() {
                let $this = this;
                return this.adminNoteList.filter(function(el) {
                    return ($this.current_category == 'All' ? true : el.category == $this.current_category);
                });
               
            }
        },
    	data() {
    		return {
                refill_status_id: '',
                refill_status_name: '',
                refill_pharmacy_id: '',
                refill_pharmacy_name: '',
                is_new_refill_status: false,
                is_new_refill_pharmacy: false,
                admin_note_input : {
                    note : '',
                    category : 'Refill',
                    confirmation_received : 'Yes',
                },
                show_admin_noteForm :false,
                admin_wc_check_note :[],
                admin_wc_check_note_default : [
                    {
                        'question' : 'Do you have any immediate concerns at this time that I can help you with?',
                        'answer' : '',
                    },
                    {
                        'question' : 'How are you feeling?',
                        'answer' : '',
                    },
                    {
                        'question' : 'Are you exercising more?',
                        'answer' : '',
                    },
                    {
                        'question' : 'Do you have any questions or concerns with nutrition or diet?',
                        'answer' : '',
                    },
                    {
                        'question' : 'Are you following your protocol closely?',
                        'answer' : '',
                    },
                    {
                        'question' : 'Has your quality of life improved?',
                        'answer' : '',
                    },
                    {
                        'question' : 'What positive changes have you noticed since our last session?',
                        'answer' : '',
                    },
                    {
                        'question' : 'Do you need any refills?',
                        'answer' : '',
                    },
                    {
                        'question' : 'Do you have any other questions?',
                        'answer' : '',
                    },
                    {
                        'question' : 'How long has it been Going On?',
                        'answer' : '',
                    },
                    {
                        'question' : 'How Severe is It?',
                        'answer' : '',
                    },
                    {
                        'question' : 'How Often does it occur?',
                        'answer' : '',
                    },
                    {
                        'question' : 'Does anything make it Better?',
                        'answer' : '',
                    },
                    {
                        'question' : 'Does anything make it Worse?',
                        'answer' : '',
                    },
                    {
                        'question' : 'Anything else you would want to tell me?',
                        'answer' : '',
                    }
                ],
                admin_note_note_error: '',
                admin_note_category_error: '',
                show_admin_note_form: false,
                admin_cat_disabled: false,
                adminNoteList: [],
                current_category: 'Refill',
                admin_note_refill_status: [],
                admin_note_refill_pharmacy: [],
                all_category: false
    		};
    	},
    	async created() {
            this.getPatientAdminNote();
    	},
    	mounted() {
            this.admin_wc_check_note = this.admin_wc_check_note_default;
            this.getAdminNoteRefillStatusAndPharmacy();
            this.current_category = (this.$route.params.cat == undefined) ? 'All' : this.$route.params.cat;
            this.admin_note_input.category = (this.$route.params.cat == undefined) ? 'All' : this.$route.params.cat;
            this.isActive(this.current_category);
            if (this.$route.params.type == 'update')
                this.editPatientAdminNote();
    	},
    	methods: {
            changeTab (components,category) {
				this.$store.state.patientDetailsComp = components;
				let props = this.$router.resolve({ 
					name: components,
					params: { id: this.$route.params.id},
				});
                this.getAdminNoteByTab(category);
				history.pushState(null, '',props.href);
			},
            isActive(menuname) {
                $("#"+menuname+"ach").addClass('active')
                return true;
            },
            getAdminNoteRefillStatusAndPharmacy() {
                axios.post("/json_dropdown/getAdminNoteRefillStatus").then((response) => {
                    this.admin_note_refill_status = response.data.data;
                });
                axios.post("/json_dropdown/getAdminNoteRefillPharmacy").then((response) => {
                    this.admin_note_refill_pharmacy = response.data.data;
                });
            },
            close(){
                this.$store.state.patientDetailsComp = "PatientDetails";
				let props = this.$router.resolve({ 
					name: "PatientDetails",
					params: { id: this.$route.params.id},
				});
				history.pushState(null, '',props.href);
                // this.$router.push(`/patient-chart/${ this.$route.params.id}`);
            },
            setCurrentCategory() {
                this.is_new_refill_status= false;
                this.is_new_refill_pharmacy= false;
                this.refill_status_id = '';
                this.refill_pharmacy_id = '';
                this.refill_pharmacy_name = '';
                this.refill_status_name = '';
                this.admin_note_input.note = '';
                this.all_category = true;
            },
            getPatientAdminNote() {
                axios.post("/patient/getAdminNote", { patient_id: this.$store.state.patientDetail.patient_id }).then((response) => {
                    //this.adminNoteList = response.data.data;
                    this.adminNoteList = response.data.data['notes'];
                });
            },
            editPatientAdminNote() {
                axios.post("/patient/editAdminNote", { patient_id: this.$store.state.patientDetail.patient_id, admin_note_id: this.$route.params.note_id}).then((response) => {
                    this.editAdminNote(response.data.data);
                });
            },
            getAdminNoteByTab(category) {
                if (category == 'WcCheck') {
                    this.admin_wc_check_note = this.admin_wc_check_note_default;
                }
                this.admin_note_input.note = '';
                this.admin_note_input.category = category;
                this.current_category = category;
                this.show_admin_note_form = false;
            },
            deleteAdminNote(item) {
                this.$swal
                    .fire({
                        title: '<h2 class="mt-0">Are you sure?</h2>',
                        text:
                            "You want to delete these records? This process cannot be undone.",
                        icon: "warning",
                        dangerMode: true,
                        showCancelButton: true,
                        confirmButtonColor: "#ee3158",
                        cancelButtonColor: "#bdbdbd",
                        confirmButtonText: "Yes, delete it!",
                    })
                    .then((result) => {
                        if (result.isConfirmed == true) {
                            axios.post("/patient/deleteAdminNote", item).
                            then((response) => {
                                this.moshaToast(response.data.message, "success");
                                this.getPatientAdminNote(item.patient_id);
                            });
                        }
                    });
            },
            editAdminNote(data) {
                var item = data[0];
                if (item.category == 'WcCheck') {
                     this.admin_wc_check_note = JSON.parse(item.note);
                }
                this.refill_status_id = item.refill_status_id;
                this.refill_pharmacy_id = item.refill_pharmacy_id;
                this.admin_note_input.confirmation_received = item.confirmation_received;
                this.admin_note_input.note =  item.note;
                this.admin_note_input.category =  item.category;
                this.admin_note_input.admin_note_id =  item.admin_note_id;
                this.show_admin_note_form = true;
                this.admin_cat_disabled = true;
            },
            handleAdminNoteSubmit() {
                if (this.admin_note_input.category == 'Refill') {
                    this.admin_note_input.refill_status_id = this.refill_status_id;
                    this.admin_note_input.refill_status_name = this.refill_status_name;
                    this.admin_note_input.refill_pharmacy_id = this.refill_pharmacy_id;
                    this.admin_note_input.refill_pharmacy_name = this.refill_pharmacy_name;
                } else {
                    this.admin_note_input.refill_status_id = '';
                    this.admin_note_input.refill_status_name = '';
                    this.admin_note_input.refill_pharmacy_id = '';
                    this.admin_note_input.refill_pharmacy_name = '';
                }
                if (this.admin_note_input.category == 'WcCheck') {
                    this.admin_note_input.note = JSON.stringify(this.admin_wc_check_note);
                } else {
                    if (!this.admin_note_input.category || this.admin_note_input.category == 'All') {
                        this.admin_note_category_error = 'Category is required.';
                        return true;
                    }
                    if (!this.admin_note_input.note) {
                        this.admin_note_note_error = 'Note is required.';
                        return true;
                    }
                }
                this.admin_note_input.patient_id = this.$store.state.patientDetail.patient_id;
                axios.post("/patient/saveAdminNote", this.admin_note_input).
                then((response) => {
                    this.moshaToast(response.data.message, "success");
                    this.show_admin_note_form = false;
                    this.getPatientAdminNote(this.admin_note_input.patient_id);
                    this.getAdminNoteRefillStatusAndPharmacy();
                    this.is_new_refill_status= false;
                    this.is_new_refill_pharmacy= false;
                    this.refill_status_id = '';
                    this.refill_pharmacy_id = '';
                    this.refill_pharmacy_name = '';
                    this.refill_status_name = '';
                    this.admin_note_input.note = '';
                    //this.admin_note_input.category = 'All';
                    this.admin_note_input.confirmation_received = 'Yes';
                });
                 if (this.$route.params.type == 'update') {
                    if(this.admin_note_input.category == 'Refill')
                        this.$router.push({name:'RefillNotes'});
                     if(this.admin_note_input.category == 'WcCheck')
                        this.$router.push({name:'WcNotes'});
                    if(this.admin_note_input.category == 'AdminNote')
                        this.$router.push({name:'GeneralNotes'});
                    if(this.admin_note_input.category == 'MedList')
                        this.$router.push({name:'MedNotes'});
                 }
                else {
                    this.$router.push({name:'AllNotes'});
                }
            }
    	}
    }
</script>

<style src="@vueform/toggle/themes/default.css"></style>
<style>
/*.formio-component-columns{
	padding:10px 5px;
}*/
.card-body{
	padding:20px;
}
.tblClass .col{
	border: 1px solid #d1d3e0;
}
td div.formio-component p {text-align:center}
</style>