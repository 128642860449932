<template>
  <div class="content-wrapper">
    <div class="container-full">
      <div class="content-header">
        <div class="row justify-content-between">
          <div class="col-12 col-sm-8 col-md-9 col-lg-6 col-xs-6">
            <h1 class="mt-0">Archived Office</h1>
          </div>
          <div class="col-12 col-sm-4 col-md-3 col-lg-3 col-xs-2 text-sm-end">
            <a href="#/office/list" class="waves-effect waves-light btn btn-info-light"> <i class="fa fa-arrow-left fs-16"></i> back </a>
          </div>
        </div>
      </div>

      <section class="content">
        <div class="box">
          <div class="box-body">
            <div class="table-responsive">
              <data-table dataType="Archivedoffice" :key="render">
                <template v-slot="slotProps">
                  <td>
                    {{ slotProps.item.office_name }}
                  </td>
                  <td>{{ slotProps.item.primary_provider }}</td>
                  <td>{{ slotProps.item.city }}</td>
                  <td>{{ slotProps.item.address }}</td>
                  <td>
                    {{
                      slotProps.item.office_phone == "" ||
                      slotProps.item.office_phone == NULL
                        ? "-"
                        : slotProps.item.office_phone
                    }}
                  </td>
                  <td>{{ slotProps.item.rooms_count }}</td>
                  <!--<td v-if="slotProps.item.is_online_available == 'Yes'">Yes</td>
                  <td v-else>No</td>-->
                  <td>
                    <button type="button"  data-on="Ready" data-off="Not Ready" @click="onToggleChange(slotProps.item.office_id,$event)" :id="['status_' + slotProps.item.office_id]" class="btn btn-toggle rounded30-im"  v-bind:class="[slotProps.item.deleted_at == NULL ? 'active' : '']" data-bs-toggle="button" aria-pressed="true" autocomplete="off">
                      <div class="handle rounded30-im"></div>
                    </button>
                  </td>
                </template>
              </data-table>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>

</template>

<script>

import dataTable from "@/components/base/DataTable.vue";
import axios from "@/scripts/axios.js";
import submitMixins from "@/scripts/mixins/submitMixins.js";

export default {
  mixins: [submitMixins],
  name: "Office",
  components: { dataTable },
  data() {
    return {
      render: 0,
      share_url:'',
      office_name:'',
    };
  },
  methods: {
    onToggleChange(id,event) {
      var class_list = document.getElementById("status_"+id).classList;
      var active_deactive = '';
      if(class_list.contains('active')) {
        active_deactive = 'A';
      } else {
        active_deactive = 'D';
        // console.log(class_list.contains('active'));
      }

      axios.post("office/reset",{"status":active_deactive,"id":id})
      .then((response) => {
        this.moshaToast('User has been restored successfully.','success');
        setTimeout(function(){ location.reload(); }, 3000);
      }); 
    },
  },
};
</script>
