<template>
   <div class="box p-15">
      <div class="mdc-tab">
         <ul class="nav nav-tabs mt-0 text-center text-sm-start" role="tablist">
            <!-- <li class="nav-item"> <a class="nav-link" data-bs-toggle="tab" href="#handp" @click.prevent="changeTab('ClinicalNoteHandP')" role="tab">H&P</a> </li>
            <li class="nav-item"> <a class="nav-link" data-bs-toggle="tab" href="#soap" role="tab" @click.prevent="changeTab('ClinicalNoteSoap')">SOAP</a> </li> -->
            <li class="nav-item"> <a class="nav-link" data-bs-toggle="tab" href="#additionalnotes" role="tab" @click.prevent="changeTab('ClinicalNoteAdditional')">Custom Notes</a> </li>
            <li class="nav-item"> <a class="nav-link" data-bs-toggle="tab" href="#amendments" role="tab" @click.prevent="changeTab('PatientAmendments')">Amendments</a> </li>
            <li class="nav-item"> <a class="nav-link active" data-bs-toggle="tab" href="#lockedNote" role="tab" @click.prevent="changeTab('PatientLockedClinicalNotes')">Locked Clinical Notes</a> </li>
         </ul>
         <div class="tab-content">
            <div class="tab-pane active" id="lockedNote" role="tabpanel">
               <div class="table-responsive">
                  <table-base 
                  :dataTypes="{
                     dataType: 'LockedClinicalNotes',
                     patient_id: patient_id,
                     patient_unique_id:patient_unique_id
                  }"
                  :lastcol='permissioncheck(this.$store.state.permission.create_update)'
                  >
                     <template v-slot="slotProps">
                        <td>{{ slotProps.item.schedule_date }}</td>
                        <td>{{ slotProps.item.reason_for_visit }}</td>
                        <td>{{slotProps.item.salutation}} {{ slotProps.item.provider_name }} {{slotProps.item.suffix}}</td>
                        <td>{{ slotProps.item.locked_at }}</td>
                        <td>
                           <div class="d-flex" v-if="
                                permissioncheck(this.$store.state.permission.create_update)
                              ">
                              <Popper arrow content="Send to Patient Portal" :hover="true" class="helptips cursor-pointer">
                                 <a @click="senddocument(slotProps.item.id)" href="#" class="waves-effect waves-circle btn btn-circle btn-info btn-xs"><i class="fa fa-send"></i></a>
                              </Popper>
                              <Popper arrow content="View Clinical Note" :hover="true" class="helptips cursor-pointer">
                                 <a href="javascript:void(0)" @click="viewNote(slotProps.item.note_unique_id)" class="waves-effect waves-circle btn btn-circle btn-info btn-xs"><i class="fa fa-eye"></i></a>
                              </Popper>
                          </div>
                        </td>
                     </template>
                  </table-base>
               </div>
            </div>
         </div>
      </div>
   </div>   

   <div class="modal" id="viewNote" tabindex="-1">
      <div class="modal-dialog modal-lg" style="max-width:90%">
         <div class="modal-content">
            <div class="modal-header">
               <button type="button" class="btn-close btn-xs" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
               <vue-pdf-viewer height="1050px" :url="url" v-if="embed=0">
               </vue-pdf-viewer>
               <iframe :src="url" width="100%" height="1050px" v-if="embed=1" />
               <img srd="images/1.gif" v-else> 
            </div>
         </div>
      </div>
   </div>
</template>
<script>
import VuePDFViewer from 'vue-instant-pdf-viewer';
import submitMixins from "@/scripts/mixins/submitMixins.js";
import axios from "@/scripts/axios.js";
import TableBase from "@/components/base/Table.vue";
import permissionMixins from "@/scripts/mixins/permission.js";
import Popper from "vue3-popper";

export default {
   mixins: [submitMixins, permissionMixins],
   components: { TableBase, 'vue-pdf-viewer': VuePDFViewer, Popper },
   data() 
   {
      return {
         url: '',
         embed: 0,
         patient_id : this.$store.state.patientDetail.patient_id,
         patient_unique_id:this.$store.state.patientDetail.patient_unique_id,
      };
   },
   methods:{
      senddocument(id){
         axios.post('patient-portal/clinicalDocuments',{type:'clinical_note',id:id})
         .then(response => {
            this.moshaToast('Record Updated successfully.','success');
          });
      },
      changeTab(components)
      {
         this.$store.state.patientDetailsComp = components;
         let props = this.$router.resolve({ 
           name: components,
           params: { id: this.$route.params.id},
         });
         if (history.pushState && props) {            
            history.pushState(null, '',props.href);            
         }
      },
      viewNote(id)
      {
         this.getSrc(id);
         $("#viewNote").modal("show");
      },
      _UIdentiTy(){
         return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
                (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
              );
      },
      async getSrc(id) {
         var masterId = id+'-'+this._UIdentiTy();
         const res = await fetch(process.env.VUE_APP_API_BASE_URL+'/preview/'+masterId, {
            method: 'GET',
            headers: {
              'Authorization' : 'Bearer '+localStorage.getItem('authToken')
            }
         });
         const blob = await res.blob();
         const urlObject = URL.createObjectURL(blob);
         this.url = urlObject;
         this.browserCheck();
      }, 
      browserCheck(){
            // Opera 8.0+
            var isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

            // Firefox 1.0+
            var isFirefox = typeof InstallTrigger !== 'undefined';

            // Safari 3.0+ "[object HTMLElementConstructor]" 
            var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));

            // Internet Explorer 6-11
            var isIE = /*@cc_on!@*/false || !!document.documentMode;

            // Edge 20+
            var isEdge = !isIE && !!window.StyleMedia;

            // Chrome 1 - 79
            var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

            // Edge (based on chromium) detection
            var isEdgeChromium = isChrome && (navigator.userAgent.indexOf("Edg") != -1);

            // Blink engine detection
            var isBlink = (isChrome || isOpera) && !!window.CSS;

            if(isChrome==true)
            {
                this.embed = 0;
            }else{
                this.embed = 1;
            }

        },
   }
};
</script>