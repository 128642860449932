<template>
    <div class="content-wrapper">
		<div class="container-full">
			<div class="content-header">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                        <h1 class="heading1 m-0 d-inline-block">EOB Upload </h1>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-6 text-sm-end">
                        <a href="#" class="waves-effect waves-light btn btn-info mt-20 mt-sm-0" title="Create Batch" data-bs-toggle="modal" data-bs-target="#createBatch"> Create Batch</a>
                    </div>
                </div>
			</div>
			<section class="content">
				<div class="box">
                    <div class="box-body">
                        <div class="table-responsive">
                            <table class="table table-bordered fs-16">
                                <thead class="text-center">
                                    <tr>
                                        <th>Sr. #</th>
                                        <th>Batch Name</th>
                                        <th>No of Claims</th>
                                        <th>Check Number</th>
                                        <th>Check Date</th>
                                        <th>Check Amount</th>
                                        <th>Upload Date</th>
                                        <th>Uploaded By</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <slot v-if="list.length>0">
                                    <tbody class="text-center">
                                        <tr v-for="(item, index) in list" :key="index">
                                            <td>{{index+1}}</td>
                                            <td>
                                                <router-link :to="{ name: 'EOBUploadDetails', params: { id: item.id }}" class="fw-500">
                                                    {{item.batch_name}}
                                                </router-link> 
                                            </td>
                                            <td>{{item.no_of_claims}}</td>
                                            <td>{{item.trace_number}}</td>
                                            <td>{{ $filters.displayDateTimeLocal(item.check_date,'',true, "MMM D, YYYY",true) }}</td>
                                            <td>{{item.check_amount}}</td>
                                            <td>{{ $filters.displayDateTimeLocal(item.created_at,'',false,"MMM D, YYYY h:mm A") }}</td>
                                            <td>{{item.addedBy}}</td>
                                            <td> <a href="javascript:void(0);" @click="editBatch(item.id)"> <i class="fa fa-pencil-square-o" aria-hidden="true"></i> </a> </td>
                                        </tr>
                                    </tbody>
                                </slot>
                                <slot v-else>
                                    <tbody>
                                        <tr>
                                            <td colspan="6" align="center">No Records</td>
                                        </tr>
                                    </tbody>
                                </slot>
                            </table>
                        </div>
                    </div>
                </div>
            </section>
            <div id="createBatch" class="modal fade" data-backdrop="static" data-keyboard="false">
				<div class="modal-dialog modal-md">
					<div class="modal-content">
						<div class="modal-header">
							<h3 class="modal-title fw-900 mt-0">Create Batch</h3>
						</div>
						<div class="modal-body">
                            <div class="form-group row mb-5">
                                <label class="col-form-label mb-0 col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 text-md-end">Batch Name </label>
                                <div class="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6 mb-5">
                                    <input type="text" class="form-control" placeholder="Batch Name" v-model="formSubmit.batch_name">
                                </div>
                            </div>
                            <div class="form-group row mb-5">
                                <label class="col-form-label mb-0 col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 text-md-end">Check Date </label>
                                <div class="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6 mb-5 availdate">
                                    <ejs-datepicker :value="check_date" id="check_date" :format="dateFormat"  placeholder="Check Date" :showClearButton='false'  @blur="getdate" @change="getdate"  name="check_date" :strictMode='true' :openOnFocus='true'></ejs-datepicker>
                                </div>
                            </div>
                            <div class="form-group row mb-5">
                                <label class="col-form-label mb-0 col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 text-md-end"> Check Number </label>
                                <div class="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6 mb-5">
                                    <input type="text" class="form-control" placeholder="Check Number" v-model="formSubmit.trace_number">
                                </div>
                            </div>      
                            <div class="form-group row mb-5">
                                <label class="col-form-label mb-0 col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 text-md-end">Check Amount </label>
                                <div class="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6 mb-5">
                                    <div class="input-group">
                                        <span class="input-group-addon">$</span>
                                        <input type="text" class="form-control" placeholder="Check Amount" v-model="formSubmit.check_amount" v-on:keypress="numbersOnly">
                                    </div>
                                </div>
                            </div>                      
                            <div class="form-group row">
								<label class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end">Upload PDF</label>
								<div class="col-12 col-sm-6 col-md-8 col-lg-8 col-xl-8">
									<input type="file" title="Upload PDF" id="scanned_EOB" ref="file" class="form-control" v-on:change="FileUpload($event)"/>
								</div>
                                <div class="error-msg" id="GroupFileError">
                                    <ul class="mb-0 p-0 fs-14"></ul>
                                </div>
							</div>
                            <div class="col-6 col-sm-3 col-md-3 col-lg-2 text-center">
                                <div v-if="formSubmit.id != null" class="d-block mb-10 position-relative">
                                    <img src="/images/pdf_icon.png" class="img-fluid rounded5" style="width:80%;" />
                                    <a href="javascript:void(0)" @click="viewEOBPDF(user.clinic_id,'patient_EOB',formSubmit.scanned_EOB_file)" class="bg-white py-5 px-10 rounded fs-18 position-absolute" style="right: 0;bottom: 0px;"><i class="fa fa-eye"></i></a>
                                </div>
                            </div>
                            <div class="selectcontrols">
                                <Autocomplete
                                    @input="inputclaim"
                                    ref="autocompleteclaim"
                                    :results="CodeLists"
                                    @onSelect="claimvalue"
                                    class="form-control ms-0 autosearch-list"
                                    placeholder="Search Claim ID"
                                    :use-html-for-results ="true"
                                ></Autocomplete>
                            </div>
                            <div>
                                No. of claim : {{formSubmit.batchArray.length}}
                                <div class="my-5" v-for="(item, index) in formSubmit.batchArray" :key="index">
                                    <div class="border border-dark  px-10 py-5">
                                        {{item.claim_number.substring(item.claim_number.indexOf('I') + 1)}}
                                        <a href="javascript:void(0);" @click="remove(index)" class="float-end"> <i class="fa fa-times-circle" aria-hidden="true"></i> </a>
                                    </div>
                                </div>
                            </div>
						</div>
						<div class="modal-footer">
							<button type="button" @click="closemodal" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
                            <slot v-if="formSubmit.id == null">
							    <button type="button" @click="createBatch" class="waves-effect waves-light btn btn-info">Create Batch</button>
                            </slot>
                            <slot v-else>
                                <button type="button" @click="updateBatch" class="waves-effect waves-light btn btn-info">Update Batch</button>
                            </slot>
						</div>
					</div>
				</div>
			</div>
            <!-- PDF view -->
            <div class="modal" id="viewpdf" tabindex="-1">
                <div class="modal-dialog modal-md" style="max-width:75%">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="btn-close btn-xs" data-bs-dismiss="modal" aria-label="Close" @click="close"></button>
                        </div>
                        <div class="modal-body">
                            <iframe :src="document_name_url" width="100%" height="500px" />
                        </div>
                    </div>
                </div>
            </div>
            <!-- PDF view -- End -->
        </div>
    </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import submitMixins from "@/scripts/mixins/submitMixins.js";
import axios from "@/scripts/axios.js";
import permissionMixins from "@/scripts/mixins/permission.js";
import moment from 'moment';
import Popper from "vue3-popper";
import Autocomplete from "vue3-autocomplete";
import { mapGetters } from "vuex";

export default {
    mixins: [submitMixins, permissionMixins],
    setup: () => {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            list: [],            
            CodeLists:[],
            formSubmit:{
                batch_name: null,
                trace_number: null,
                scanned_EOB: null,
                batchArray:[],
                id:null,
                scanned_EOB_file:null,
                check_date:null,
                check_amount:null,
            },
            clinic_id: null,
            document_name_url: null,
            dateFormat: 'MM-dd-yyyy',
            check_date:null,
        };
    },
    components: { Popper, Autocomplete },
    methods: {
        getdate(){
            if($('#check_date').val() == '') {
                this.formSubmit.check_date = null;
            } else {
                this.formSubmit.check_date =  $('#check_date').val();
            }
        },
        numbersOnly(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        inputclaim(obj) {
            if (obj.length > 1) {
                this.$refs.autocompleteclaim.$el.classList.add("selectloader");
                axios.get("/json_autocomplete/claim-reference-number", {
                    params: { term: obj },
                }).then((response) => {
                    var $this = this;
                    this.CodeLists = response.data.data.map((res) => {
                        return { id: res.id, name: "<b> Claim ID: </b>"+res.claim_number.substring(res.claim_number.indexOf('I') + 1)+"<br> <b>Patient: </b>"+res.first_name+" "+res.last_name+" ( Appt. "+$this.displayDate(res.schedule_date)+")", claim_number: res.claim_number }
                    });
                    this.$refs.autocompleteclaim.$el.classList.remove("selectloader");
                });
            }
            if(obj.length == 0){
                this.CodeLists = [];
                this.$refs.autocompleteclaim.searchText = '';               
            }
        },
        displayDate(date, format, timezone = false) {
            if (date) {
                let dt_format = format ? format : "ddd, MM-DD-YYYY";
                if(timezone == true){
                    var testDateUtc = moment.utc(date);
                    return moment(testDateUtc).tz('US/Eastern').format(dt_format);
                }
                else{
                    return moment(date).format(dt_format); 
                }
            } else {
                return "";
            }
        },
        claimvalue(obj){
            const checkduplicate = value => value.claim_number === obj.claim_number;
            if(!this.formSubmit.batchArray.some(checkduplicate)){                
                this.formSubmit.batchArray.push(obj)
            }
            this.$emit("update:modelValue", obj.claim_number);
            this.CodeLists = [];
            this.$refs.autocompleteclaim.searchText = '';
            const btn = this.$refs.autocompleteclaim;
            btn.$el.querySelector('.vue3-autocomplete-container > .autosearch-list').blur();
        },
        remove(index){
            this.formSubmit.batchArray.splice(index, 1);
        },
        createBatch(){
            if($(".confirmerror").text() == ''){
                if(this.formSubmit.check_date != null){
                    this.formSubmit.check_date = moment(String(this.formSubmit.check_date)).format('YYYY-MM-DD');
                }
                var bodyFormData = new FormData();
                for (const [key, value] of Object.entries(this.formSubmit)) {
                    if (Array.isArray(this.formSubmit[key])) {
                        this.formSubmit[key].forEach((item) => {
                            if(item == null || item == '' || item == 'null'){
                                item = "";
                            }
                            bodyFormData.append(`${key}[]`, JSON.stringify(item));
                        });
                    } else {
                        if(value == null || value == '' || value == 'null'){
                            value = "";
                        }
                        bodyFormData.append(key, value);
                    }
                }
                this.$store.state.loader = true;
                axios.post("/EOBbatch/store",bodyFormData)
                .then((response) => {
                    this.$store.state.loader = false;
                    $("#createBatch").modal('hide');
                    this.formSubmit.id = null;
                    this.formSubmit.batchArray = [];
                    this.formSubmit.batch_name = null; 
                    this.formSubmit.trace_number = null; 
                    this.formSubmit.scanned_EOB = null;
                    this.formSubmit.scanned_EOB_file = null;
                    this.formSubmit.check_date = null;
                    this.formSubmit.check_amount = null;
                    $('#scanned_EOB').val('')
                    this.getList();
                });
            }
        },
        updateBatch(){
            if($(".confirmerror").text() == ''){
                console.log(this.formSubmit.check_date,moment(String(this.formSubmit.check_date)).format('YYYY-MM-DD'))
                if(this.formSubmit.check_date != null){
                    this.formSubmit.check_date = moment(String(this.formSubmit.check_date)).format('YYYY-MM-DD');
                }
                var bodyFormData = new FormData();
                for (const [key, value] of Object.entries(this.formSubmit)) {
                    if (Array.isArray(this.formSubmit[key])) {
                        this.formSubmit[key].forEach((item) => {
                            if(item == null || item == '' || item == 'null'){
                                item = "";
                            }
                            bodyFormData.append(`${key}[]`, JSON.stringify(item));
                        });
                    } else {
                        if(value == null || value == '' || value == 'null'){
                            value = "";
                        }
                        bodyFormData.append(key, value);
                    }
                }
                this.$store.state.loader = true;
                axios.post("/EOBbatch/update",bodyFormData)
                .then((response) => {
                    this.$store.state.loader = false;
                    $("#createBatch").modal('hide');
                    this.formSubmit.id = null;
                    this.formSubmit.batchArray = [];
                    this.formSubmit.batch_name = null;
                    this.formSubmit.trace_number = null;
                    this.formSubmit.scanned_EOB = null;
                    this.formSubmit.scanned_EOB_file = null;
                    this.formSubmit.check_date = null;
                    this.formSubmit.check_amount = null;
                    $('#scanned_EOB').val('')
                    this.getList();
                });
            }
        },
        closemodal(){
            this.formSubmit.id = null;
            this.formSubmit.batchArray = [];
            this.formSubmit.batch_name = null;
            this.formSubmit.trace_number = null;
            this.formSubmit.scanned_EOB = null;
            this.formSubmit.scanned_EOB_file = null;
            this.formSubmit.check_date = null;
            this.formSubmit.check_amount = null;
            $('#scanned_EOB').val('')
        },
        getList(){
            this.$store.state.loader = true;
            axios.post("/EOBbatch/list")
            .then((response) => {
                this.list = response.data.data;
                this.$store.state.loader = false;
            });
        },
        editBatch(id){
            this.$store.state.loader = true;
            axios.post("/EOBbatch/edit",{id:id}).then((response) => {
                this.$store.state.loader = false;  
                this.formSubmit.batchArray = response.data.data.batchArray;
                this.formSubmit.batch_name = response.data.data.batch_name;
                this.formSubmit.trace_number = response.data.data.trace_number;
                this.formSubmit.scanned_EOB_file = response.data.data.scanned_EOB;
                this.formSubmit.id = response.data.data.id;
                this.formSubmit.check_date = response.data.data.check_date;
                this.formSubmit.check_amount = response.data.data.check_amount;
                this.check_date =  moment(String(response.data.data.check_date)).format('MM/DD/YYYY');
                $("#createBatch").modal('show');
            });
        },        
        viewEOBPDF(clinic_id,foldername,filename){
			this.document_name_url  = process.env.VUE_APP_API_BASE_URL.slice(0, -3)+'storage/'+foldername+'/'+filename;
            $("#createBatch").modal('hide');
            $("#viewpdf").modal('show');
        },
        FileUpload(event) {
            this.formSubmit.scanned_EOB = this.$refs.file.files[0];
            $("#GroupFileError").find("ul").html("");
            const size = (this.$refs.file.files[0].size / 1024 / 1024).toFixed(2);
            if (size > 50) {
                $("#GroupFileError").find("ul").append('<li class="d-block confirmerror"> File size should not exceed more than 50 MB.</li>');
                this.formSubmit.scanned_EOB = "";
                $("#scanned_EOB").val("");
            }
            const acceptedImageTypes = [
                "application/pdf",		
            ];
            if (!acceptedImageTypes.includes(this.$refs.file.files[0].type)) {
                $("#GroupFileError").find("ul").append('<li class="d-block confirmerror"> Only PDF formats are supported.</li>');
                this.formSubmit.scanned_EOB = "";
                $("#scanned_EOB").val("");
            }
        },
    },
    mounted() {
        this.getList();
    },
    computed: {
		...mapGetters("auth", ["user"]),
  	},
    async beforeCreate() {},
    validations() {},
};
</script>