<template>
  <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-2">
    <div class="sticky-scroll-box">
      <div class="accorsidebar">
        <div class="accordion-item">
          <p class="accordion-header display-vertical" id="addpatienttab">
              <span class="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapsedemogr" aria-expanded="false" aria-controls="collapsedemogr">General Setting</span>
          </p>
          <div
            id="collapsedemogr"
            class="accordion-collapse collapse"
            aria-labelledby="addpatienttab"
            data-bs-parent="#accordionExample"
          >
           <div class="side-navigation">
            <ul class="side-bar-menu side-bar-tree patientTabs" data-widget="tree">
              <li>
                <a 
                  href="javascript:void(0)"
                  ref="ProfileSettingLink"
                  class="active"
                  @click.prevent="changeComp('ProfileSetting')"
                  >Personal Info</a>
              </li>
              <li>
                <a 
                  href="javascript:void(0)"
                  ref="GeneralSettingLink"
                  @click.prevent="changeComp('GeneralSetting')"
                  >General Setting</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>
<script>
import permissionMixins from "@/scripts/mixins/permission.js";

export default {
  mixins: [permissionMixins],
  data() {
    return {};
  },
  watch: {
    "$route.name": function(newValue) {
      this.linkActive();
    },
  },

  mounted() {
    this.linkActive();
  },

  methods: {
    changeComp(routerName) {
      this.$router.push({name: routerName});
    },
    linkActive() {
      if ( this.$route.name === "ProfileSetting") {
        this.$refs.ProfileSettingLink.classList.add("active");
        this.$refs.GeneralSettingLink.classList.remove("active");
      }
      else{
        this.$refs.ProfileSettingLink.classList.remove("active");
        this.$refs.GeneralSettingLink.classList.add("active"); 
      }

    },
  },
};
</script>