<template>
  <div class="content-wrapper">
    <div class="container-full">
      <div class="content-header">
          <h2 class="heading2 my-5">Logs</h2>
      </div>

      <section class="content">
      <div class="box p-15">
        <div class="mdc-tab">
          <ul class="nav nav-tabs mt-0 text-center text-sm-start" role="tablist">
            <li class="nav-item"> <a class="nav-link active" data-bs-toggle="tab" href="#handp" role="tab">Dynamodb</a></li>
            <li class="nav-item"> <a class="nav-link " data-bs-toggle="tab" href="#soap" role="tab" >RDS</a> </li>
          </ul>
          
          <div class="tab-content">
            <div class="tab-pane active" id="handp" role="tabpanel">
                <div class="container-full">
                  <div class="content-header">
                    <div class="row justify-content-between">
                      <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xs-6">
                      </div>
                      <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xs-6 text-md-right">
                        <a href="javascript:void(0)"
                         class="waves-effect waves-light btn btn-info" @click="refreshLog"><i class="fa fa-refresh fs-14"></i> 
                         Refresh
                        </a>
                      </div>
                    </div>
                  </div>
                  <section class="content">
                    <div class="box">
                      <div class="box-body">
                        <div class="table-responsive">
                          <data-table dataType="auditTemperProofLog" :dataTypes="{dataType: 'auditTemperProofLog', 'method' : 'get'}" :key="table_load_count">
                            <template v-slot="slotProps">
                              <td>{{ slotProps.item.eventsource }}</td>
                              <td>{{ slotProps.item.eventid }}</td>
                              <td>{{ slotProps.item.eventname }}</td>
                              <td>{{ slotProps.item.sourceipaddress }}</td>
                              <td>{{ slotProps.item.eventtime }}</td>
                              <td>
                                  <Popper arrow content="View Details" :hover="true" class="helptips cursor-pointer">
                                      <a href="#" class="waves-effect waves-circle btn btn-circle btn-info btn-xs" @click="viewDetail(slotProps.item.id)"><i class="fa fa-eye"></i></a>
                                  </Popper>
                              </td>
                            </template>
                          </data-table>
                        </div>
                      </div>
                    </div>
                  </section>
                  <div class="modal" tabindex="-1" id="medicationDetail">
                      <div class="modal-dialog modal-lg">
                          <div class="modal-content">
                              <div class="modal-header">
                                  <h3 class="modal-title font-bold m-0">Log Details</h3>
                                  <button type="button" class="btn-close btn-xs" data-bs-dismiss="modal" aria-label="Close"></button>
                              </div>
                              <div class="modal-body" >
                                  <div id="medication_detail_table" class="table-responsive">
                                      <table class="table table-bordered">
                                          <tr>
                                            <th style="width: 25%;">Eventid :</th>
                                            <td>{{ this.log_details_data.eventid }}</td>
                                          </tr>
                                          <tr>
                                            <th>Event Name :</th>
                                            <td>{{ this.log_details_data.eventname }}</td>
                                          </tr>
                                          <tr>
                                            <th>Request Parameters : </th>
                                            <td>{{ this.log_details_data.requestparameters }}</td>
                                          </tr>
                                          <tr>
                                            <th>Event Time : </th>
                                            <td>{{ this.log_details_data.eventtime }}</td>
                                          </tr>
                                          <tr>
                                            <th>AWS Region : </th>
                                            <td>{{ this.log_details_data.awsregion }}</td>
                                          </tr>
                                          <tr>
                                            <th>Event Source : </th>
                                            <td>{{ this.log_details_data.eventsource }}</td>
                                          </tr>
                                           <tr>
                                            <th>User Agent : </th>
                                            <td>{{ this.log_details_data.useragent }}</td>
                                          </tr>
                                      </table>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                </div>
            </div>

            <div class="tab-pane " id="soap" role="tabpanel">
              <div class="container-full">
                <div class="content-header">
                  <div class="row justify-content-between">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xs-6">
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xs-6 text-md-right">
                      <a href="javascript:void(0)"
                       class="waves-effect waves-light btn btn-info" @click="refreshRdsLog"><i class="fa fa-refresh fs-14"></i> 
                       Refresh
                      </a>
                    </div>
                  </div>
                </div>
                <section class="content">
                  <div class="box">
                    <div class="box-body">
                      <div class="table-responsive">
                        <data-table dataType="auditRDSTemperProofLog" :dataTypes="{dataType: 'auditRDSTemperProofLog', 'method' : 'get'}" :key="table_load_count">
                          <template v-slot="slotProps">
                            <td>{{ slotProps.item.timestamp }}</td>
                            <td>{{ slotProps.item.ip }}</td>
                            <td>{{ slotProps.item.ip_no }}</td>
                            <td>
                                <Popper arrow content="View Details" :hover="true" class="helptips cursor-pointer">
                                    <a href="#" class="waves-effect waves-circle btn btn-circle btn-info btn-xs" @click="viewRdsDetail(slotProps.item)"><i class="fa fa-eye"></i></a>
                                </Popper>
                            </td>
                          </template>
                        </data-table>
                      </div>
                    </div>
                  </div>
                </section>
                <div class="modal" tabindex="-1" id="medicationRdsDetail">
                    <div class="modal-dialog modal-lg">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h3 class="modal-title font-bold m-0">Log Details</h3>
                                <button type="button" class="btn-close btn-xs" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body" >
                                <div id="medication_detail_table" class="table-responsive">
                                    <table class="table table-bordered">
                                        <tr>
                                          <th style="width: 25%;">Timestamp :</th>
                                          <td>{{ this.log_rds_details_data.timestamp }}</td>
                                        </tr>
                                        <tr>
                                          <th style="width: 25%;">Ip No :</th>
                                          <td>{{ this.log_rds_details_data.ip_no }}</td>
                                        </tr>
                                        <tr>
                                          <th>IP:</th>
                                          <td>{{ this.log_rds_details_data.ip }}</td>
                                        </tr>
                                        <tr>
                                          <th>Query : </th>
                                          <td>{{ this.log_rds_details_data.query }}</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
      
        </div>
      </div>
      </section>
    </div>
  </div>
</template>

<script>

import axios from "@/scripts/axios.js";
import dataTable from "@/components/base/DataTable.vue";
import submitMixins from "@/scripts/mixins/submitMixins.js";


export default {

  name: "AuditTemperLog",
  mixins: [submitMixins],
  components: { dataTable },
  data() {   
    return {
      table_load_count : 0,
      log_data : [],
      log_details_data : [],
      log_rds_details_data : [],
    }
    
  },
  mounted() {
    //this.fetchAuditTemperLog();
  },
  computed: {
  },
  methods: {
    viewDetail(id) {
      axios.post("/get-audit-templer-log",{"id" : id})
      .then((response) => {
        $("#medicationDetail").modal("show"); 
          this.log_details_data = response.data.data;
          //$("#medication_detail_table").html(response.data);
      }).catch((error) => {
      });
    },
    viewRdsDetail(info) {
      $("#medicationRdsDetail").modal("show"); 
      this.log_rds_details_data = info;
    },
    refreshLog() {
      this.$store.state.loader = true;
      axios.post("/refresh-audit-templer-log")
      .then((response) => {
        this.$store.state.loader = false;
        location.reload();
      }).catch((error) => {
        this.$store.state.loader = false;
      });
    },
    refreshRdsLog() {
      this.$store.state.loader = true;
      axios.post("/refresh-audit-rds-templer-log")
      .then((response) => {
        this.$store.state.loader = false;
        location.reload();
      }).catch((error) => {
        this.$store.state.loader = false;
      });
    }
  },
};
</script>
