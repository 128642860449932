import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import axios from "@/scripts/axios.js";
import jquery from "jquery";
import VueSmoothScroll from "vue3-smooth-scroll";
import VueHtmlToPaper from "../plugins/VueHtmlToPaper";
// https://github.com/devindex/vue-mask
import VueMask from "@devindex/vue-mask";
//import moment, * as moments from "moment";
import moment from 'moment-timezone'


//https://szboynono.github.io/mosha-vue-toastify/#playground
//https://www.npmjs.com/package/mosha-vue-toastify
import moshaToast from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";

//https://www.npmjs.com/package/vue-signature-pad
import VueSignaturePad from "vue-signature-pad";

import { NumericTextBoxPlugin } from "@syncfusion/ej2-vue-inputs";
import { DateRangePickerPlugin } from "@syncfusion/ej2-vue-calendars";
import { TimePickerPlugin } from "@syncfusion/ej2-vue-calendars";
import { SchedulePlugin } from "@syncfusion/ej2-vue-schedule";
import { CheckBoxPlugin } from "@syncfusion/ej2-vue-buttons";
import { ColorPickerPlugin } from "@syncfusion/ej2-vue-inputs";
import { DatePickerPlugin} from '@syncfusion/ej2-vue-calendars';
import { DateTimePickerPlugin } from "@syncfusion/ej2-vue-calendars";
import { DropDownListPlugin } from "@syncfusion/ej2-vue-dropdowns";
import { MultiSelectPlugin } from "@syncfusion/ej2-vue-dropdowns";
import { FileManagerPlugin, DetailsView, NavigationPane,Toolbar } from "@syncfusion/ej2-vue-filemanager";
import { RecurrenceEditorPlugin } from '@syncfusion/ej2-vue-schedule';
import { RichTextEditorPlugin } from "@syncfusion/ej2-vue-richtexteditor";
import { InstantSearch } from 'vue-instantsearch/vue3/es';

axios.defaults.withCredentials = true;
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log(error.message);
    if (error.response.status === 422) {
      store.commit("setErrors", error.response.data.data);
      return Promise.reject(error);
    } else if (error.response.status === 401) {
      store.commit("auth/SET_USER_DATA", null);
      localStorage.removeItem("authToken");
      localStorage.removeItem("activeProvider");
      location.reload(true);
    } else {
      return Promise.reject(error);
    }
  }
);

const app = createApp(App);

app.config.errorHandler = function (err, vm, info) {
  console.log(info);
  console.log(err);
  //vm.moshaToast('Could not render the page properly, Please reload the page.','danger');
  //location.reload();
}

/*app.use(VueProgressBar, options);*/
app.use(store);
app.use(router);
app.use(VueMask);
app.use(VueHtmlToPaper, {
  styles: ["../public/css/print.css"],
  timeout: 1000, // default timeout before the print window appears
  autoClose: true, // if false, the window will not close after printing
  windowTitle: window.document.title,
});
app.use(VueSweetalert2);
app.use(VueSmoothScroll, {
  updateHistory: false,
  offset: -250,
  easingFunction: (t) => t * t,
});
app.use(SchedulePlugin);
app.use(CheckBoxPlugin);

app.use(moshaToast);
app.use(VueSignaturePad);
app.use(NumericTextBoxPlugin);
app.use(DateRangePickerPlugin);
app.use(TimePickerPlugin);
app.use(ColorPickerPlugin);
app.use(DatePickerPlugin);
app.use(DateTimePickerPlugin);
app.use(DropDownListPlugin);
app.use(MultiSelectPlugin);
app.use(FileManagerPlugin);
app.use(RecurrenceEditorPlugin);
app.use(RichTextEditorPlugin);
app.use(InstantSearch);
app.mount("#app");

app.config.globalProperties.window = window;
app.config.globalProperties.$ = jquery;

app.config.globalProperties.$filters = {
  displayDateTimeLocal(date=null,time=null,utc=false,format ="ddd, MM-DD-YYYY hh:mm A",onlydate=false,onlytime=false ) {
    
    if(utc==true){
      if(onlydate==true){
        let dt_format = format ? format : "ddd, MM-DD-YYYY";
        var datatime=moment(date).utc(date);
        var timedata=datatime.local().format(dt_format);
        return  timedata;
      }else if(onlytime==true){
        var datatime=moment(date).utc(date);
        var timedata=datatime.local().format('hh:mm A');
        return timedata;
      }else{
          let dt_format = format ? format : "ddd, MM-DD-YYYY hh:mm A";
          var datatime=moment(date).utc(date);
          var timedata=datatime.local().format(dt_format);
          return  timedata;
      }
    }else{
        if(onlydate==true){
          let dt_format = format ? format : "ddd, MM-DD-YYYY";
          var datatime=moment(date).local();
          var timedata=datatime.format(dt_format);
          return  timedata;
        }else if(onlytime==true){
          var datatime=moment(date);
          var timedata=datatime.local().format('hh:mm A');
          return timedata;
        }else{
        let dt_format = format ? format : "ddd, MM-DD-YYYY hh:mm A";
        var datatime=moment(date);
        var timedata=datatime.local().format(dt_format);
        return  timedata;
      }
    }
    
   
  },
 
  inputDateTime(date, format) {
    if (date) {
      let dt_format = format ? format : "YYYY-MM-DDTHH:mm";
      return moment(date).format(dt_format);
    } else {
      return "";
    }
  },
  timeFormat(time) {
    if (time) {      
      let default_format = "01-01-1970"+ time;
      return moment(default_format, 'DD-MM-YYYY, hh:mm A').format('hh:mm A');
    } else {
      return "";
    }
  },
  
  toUSD (value) {
    if (value) {
      return `$${parseFloat(value).toFixed(2)}`;
    } else {
      return `$${parseFloat(0).toFixed(2)}`;
    }
  }
};