<template>
  <div class="content-wrapper">
    <div class="container-full">
      <div class="content-header">
          <h2 class="heading2 my-5">View All Notification</h2>
      </div>

      <section class="content">
      <div class="box p-15">
        <div class="mdc-tab">
          <ul class="nav nav-tabs mt-0 text-center text-sm-start" role="tablist">
            <li class="nav-item"> <a class="nav-link active" data-bs-toggle="tab" href="#handp" role="tab">Notification</a></li>
            <li class="nav-item"> <a class="nav-link" data-bs-toggle="tab" href="#soap" role="tab" >Messages</a> </li>
          </ul>
          
          <div class="tab-content">
            <div class="tab-pane active" id="handp" role="tabpanel">
              <data-table dataType="Notification">
                <template v-slot="slotProps">
                  <td>{{ $filters.displayDateTimeLocal(slotProps.item.created_at,'',false,"ddd, MM-DD-YYYY",true) }}</td>
                  <td>{{ slotProps.item.notification_type }}</td>
                  <td>
                    <router-link :to="slotProps.item.notification_link" @click="status_change(slotProps.item.id)">
                      {{ slotProps.item.notification_msg }}
                    </router-link>
                  </td>
                  <td>{{ slotProps.item.status }}</td>
                </template>
              </data-table>
            </div>

            <div class="tab-pane" id="soap" role="tabpanel">
              <data-table dataType="unreaadmessage">
                <template v-slot="slotProps">
                  <td>{{ $filters.displayDateTimeLocal(slotProps.item.MessageDateTime,'',false,"ddd, MM-DD-YYYY",true) }}</td>
                  <td> {{(slotProps.item.sendertype == 'Provider') ? slotProps.item.provider_name : slotProps.item.patient_name}}</td>
                  <td> {{(slotProps.item.sendertype == 'Patient') ? slotProps.item.provider_name : slotProps.item.patient_name}} </td>
                  <td>{{ slotProps.item.MessageText }}</td>
                  <td>
                    <a @click="replayto(slotProps.item.FromEmail,slotProps.item.ToEmail,slotProps.item.pp_id,slotProps.item.notification_id,slotProps.item.MessageText,slotProps.item.patient_name)" href="javascript:void(0)" class="waves-effect waves-light btn btn-info-light btn-sm" data-bs-toggle="modal" data-bs-target="#myConsentModal"><i class="fa fa-reply fs-12 me-1" aria-hidden="true"></i> Reply</a>
                  </td>
                </template>
              </data-table>
            </div>
          </div>
      
        </div>
      </div>
      </section>
    </div>
  </div>

<div id="myConsentModal" class="modal fade myConsentModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title fw-900 mt-0">
          Reply To {{this.patient_name}}
        </h3>
      </div>
      <div class="modal-body">
        <div class="form-group mb-10">
          <div class="bg-lightest rounded5 p-10 b-1 border-info mb-10">
            <label>{{this.MessageText}}</label>
          </div>
            <textarea @keyup="message" v-model="messages" name="textarea" class="form-control message" required="required" placeholder="Message"></textarea>
            <small class="text-danger error_msg d-none">{{errorMessage}}</small>
          <div class="error-msg">
             <ul class="mb-0 p-0 fs-14"></ul>
          </div>
        </div>
          <div class="msgstore smooth_scroll">
            <div class="bg-lightest rounded5 p-10 bb-2 border-secondary mb-10" v-for="item in message_list">
              <label class="fw-900"> From: {{item.FromEmail}}
                <small>({{$filters.displayDateTimeLocal(item.MessageDateTime,'',false, "MM-DD-Y hh:mm A")}})</small>
              </label>
              <p class="mb-0">{{item.MessageText}}</p>
            </div>
          </div>
      </div>
      <div class="modal-footer">
        <button @click="send" type="button" class="waves-effect waves-light btn btn-info">Send</button>
        <button type="button" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
      </div>
    </div>
  </div>
</div>

</template>

<script>

import dataTable from "@/components/base/DataTable.vue";
import TextArea from "@/components/base/formFields/textArea.vue";
import axios from "@/scripts/axios.js";
import submitMixins from "@/scripts/mixins/submitMixins.js";
export default {
  mixins: [submitMixins],
  data() {
    return {
      list:[],
      toemail:'',
      formemail:'',
      messages:'',
      errorMessage:'please enter message',
      pp_id:'',
      notification_id:'',
      message_list:[],
      MessageText:'',
      patient_name:'',
      email_not_counter:0,
      is_email_not_counter:false,
    };
  },
  components: {
    dataTable,
    TextArea,
  },
  methods: {
    replayto(toemail,formemail,pp_id,notification_id,MessageText,patient_name){
      axios.get("/patient-portal/unreadmessages/"+notification_id)
      .then((response) => {
          this.message_list = response.data.data ;
          
          axios.get("get-notification").then(response => {
             this.$store.state.auth.notificationData = response.data.data;
             this.$store.state.auth.notificationCounter = response.data.data.length;
          }).catch(() => {
          });
      });


      if(this.$store.state.auth.userData.clinic_id == 1){
        this.toemail = patient_name;
        this.formemail = this.$store.state.auth.activeProvider.name;
      }
      else{
        this.toemail = toemail;
        this.formemail = formemail;
      }

      this.pp_id = pp_id;
      this.notification_id = notification_id;
      this.MessageText = MessageText;
      this.patient_name = patient_name;

    },
    status_change(id){
      var $this = this;
      axios.get("notification-status/"+id)
      .then((response) => {
          this.get_notification();
      });
    },
    change_count(count,arry){
            if(count>0) {
               this.is_email_not_counter = false;
               this.email_not_counter = 0;
               let counter_ER = 0;
               let email_url_list = [];
               let remove = [];
               arry.map((itm, idx) => {
                  if(this.permissioncheck(this.$store.state.permission.PureCRMContacts)){
                     if(itm.notification_type=="Email reply" || itm.notification_type=="SMS reply") {
                        email_url_list.push(itm.notification_link);
                        counter_ER++;
                     }
                  }else{
                     if(itm.notification_type=="Email reply" || itm.notification_type=="SMS reply") {                        
                        remove.push(idx);
                     }
                  }
               });
               remove.map(obj => {
                  this.$store.state.auth.notificationData.splice(obj, 1); 
               });
               this.$store.state.auth.notificationCounter = this.$store.state.auth.notificationCounter-remove.length
               if(counter_ER>0) {
                  this.is_email_not_counter = true;
                  this.email_not_counter = counter_ER;
                  this.$store.state.auth.email_reply_not_url_list = email_url_list;
               }
            } else {
               this.is_email_not_counter = false;
               this.email_not_counter = 0;
            }
         },
    get_notification(){
      axios.get("get-notification").then(response => {
          this.$store.state.auth.notificationData = response.data.data;
          this.$store.state.auth.notificationCounter = response.data.data.length;
          /** Update email counter */
          setTimeout(() => {
            this.change_count(this.$store.state.auth.notificationCounter,this.$store.state.auth.notificationData);
          }, 500);
      }).catch((e) => {
          console.log(e)
      });
    },
    send(){
      if (this.messages != '') {
        axios.post("patient-portal/messages",{
          ToEmail:this.toemail,
          FromEmail:this.formemail,
          MessageText:this.messages,
          pp_id:this.pp_id,
          notification_id:this.notification_id,
        })
        .then((result) => {
          this.moshaToast("Record added successfully.", "success");
        });
          this.toemail = '';
          this.formemail = '';
          this.messages = '';
          $(".myConsentModal").modal("toggle");
      }
      else{
        $('.message').addClass("message_error");
        $('.error_msg').removeClass("d-none");
        this.errorMessage = 'please enter message';
      }
    },
    message(){
      if(this.messages != ''){
        if(this.messages.length > 240){
          $('.message').addClass("message_error");
          $('.error_msg').removeClass("d-none");
          this.errorMessage = "Message should be maximum 240 character."
        }else{
          $('.message').removeClass("message_error");
          $('.error_msg').addClass("d-none");
        }
      }
    }
  },
  beforeCreate(){
    axios.get("view-notification")
    .then((response) => {this.list =response.data.data });
  }
};
</script>
<style >
  .message_error{
    border: solid 1px red;
  } 
</style>
