const CareTeamMembers = {
  namespaced: true,

  state: {
    tableData: {
      header: [
        "Appt Scheduled Time",
        "Appt Provider",
        "Care Team Members",
        "Action",
      ],
    },
    form: {},
    listUrl: "/patient/:patient_unique_id/careteammembers/list",
    list: [],
  },
  getters: {
    getTableHeaders: (state) => state.header,

    getTableContents: (state) => state.list,
    getListURL: (state) => state.listUrl,
  },
  mutations: {},
  actions: {},
}

export default CareTeamMembers;