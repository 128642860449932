<template>
	<div class="content-wrapper">
		<div class="container-full">
			<div class="content-header">
				
				<div class="row justify-content-between">
					<div class="col-12 col-sm-8 col-md-9 col-lg-9 col-xs-10">
						<h1 class="mt-0">Get Support</h1>
					</div>
					<div class="col-12 col-sm-4 col-md-3 col-lg-3 col-xs-2 text-sm-end">
						<a href="#/support/create" class="waves-effect waves-light btn btn-info mb-5"><i class="fa fa-plus fs-14"></i>Raise Ticket</a>
					</div>
				</div>
			</div>
			<div class="tab-content">
				<div class="tab-pane active" id="openTicket" role="tabpanel">
				<section class="content">
					<div class="box">
						<div class="box-body">
							<div class="table-responsive">
								<data-table dataType="supportCloseIndex" :key="render">
									<template v-slot="slotProps">
										<td style="width:50%;"><a :href="'#/support/'+slotProps.item.master_id+'/threads'" class="fw-500"> {{ slotProps.item.subject }} </a></td>
										<td><span v-bind:class="BindThreadStatus(slotProps.item.status)">{{ slotProps.item.status }}</span></td>
										<td>{{ formatDate(slotProps.item.time, "DD/MM/YYYY hh:mm a")  }}</td>
									</template>
								</data-table>
							</div>
						</div>
					</div>
				</section>
				</div>
			</div>	
			
		</div>
	</div>
</template>

<script>
	import dataTable from "@/components/base/DataTable.vue";
	import axios from "@/scripts/axios.js";
	import Popper from "vue3-popper";
	import TabbarComp from "@/components/Help/HelpTabbar.vue";
	import moment from "moment";

	export default {

		components: { dataTable, Popper,TabbarComp },
		data() {
			return {
				render: 0,
			};
		},
		methods: {
			BindThreadStatus: function(status){
				if(status=="Closed"){
					return "badge badge-danger font-bold fs-14";
				}
			},
			formatDate(date, format) {
				return moment(date).format(format);
			},

		},
	};
</script>