<template>
  <div class="content-wrapper">
    <div class="container-full">
      <div class="content-header">
        <div class="row justify-content-between">
          <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xs-6">
            <h1 class="heading1 mt-0">Subscription Plan <a href="#" data-bs-toggle="modal" data-bs-target="#subsciptiondocument" title="Reference of Knowledge Base" v-on:click.prevent="reset()"> <i class="fa fa-question-circle fs-20"></i></a></h1>
          </div>
          <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xs-6 text-sm-end">
            <a href="javascript:void(0)"
             class="waves-effect waves-light btn btn-info" data-bs-toggle="modal" data-bs-target="#subscriptionplanModal"><i class="fa fa-plus fs-14"></i> Subscription Plan
            </a>
          </div>
          <div id="subscriptionplanModal" class="modal fade" tabindex="-1" aria-labelledby="problemModalLabel" style="display: none;" aria-hidden="true">
            <div class="modal-dialog" style="max-width:500px;">
                <div class="modal-content">
                    <div class="modal-header">
                        <h2 class="modal-title m-0">{{ this.form.id != '' ? 'Edit Subscription Plan' : 'New Subscription Plan' }} </h2>
                    </div>
                    <form>
                        <div class="modal-body">
                          <div class="form-group row align-items-center">
                              <label class="col-form-label col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 text-lg-end mb-0">Plan Code<small class="text-danger asterisksign">*</small></label>
                              <div class="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9">
                                  <input type="text" class="form-control" v-model="v$.form.plan_code.$model" placeholder="Plan Code">
                                  <div class="error-msg" v-if="v$.form.plan_code.$errors.length > 0">
                                    <ul class="mb-0 p-0 fs-14"><li class="d-block" v-for="(error, index) of v$.form.plan_code.$errors"> {{ error.$message }}</li>
                                    </ul>
                                  </div>
                              </div>
                          </div>
                              <div class="form-group row align-items-center">
                                  <label class="col-form-label col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 text-lg-end mb-0">Plan Name<small class="text-danger asterisksign">*</small></label>
                                  <div class="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9">
                                      <input type="text" class="form-control"  v-model="v$.form.plan_name.$model" id="Plan Name" placeholder="Plan Name">
                                      <div class="error-msg" v-if="v$.form.plan_name.$errors.length > 0">
                                        <ul class="mb-0 p-0 fs-14"><li class="d-block" v-for="(error, index) of v$.form.plan_name.$errors"> {{ error.$message }}</li>
                                        </ul>
                                      </div>
                                  </div>
                              </div>
                              <div class="form-group row align-items-center">
                                  <label class="col-form-label col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 text-lg-end mb-0">Amount<small class="text-danger asterisksign">*</small></label>
                                  <div class="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9">
                                      <input type="text" class="form-control" id="amount" placeholder="Amount" @change="calcFinalAmount"  v-model="v$.form.plan_amount.$model">
                                      <div class="error-msg" v-if="v$.form.plan_amount.$errors.length > 0">
                                        <ul class="mb-0 p-0 fs-14"><li class="d-block" v-for="(error, index) of v$.form.plan_amount.$errors"> {{ error.$message }}</li>
                                        </ul>
                                      </div>
                                  </div>
                              </div>
                              <div class="form-group row align-items-center">
                                  <label class="col-form-label col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 text-lg-end mb-0">Discount</label>
                                  <div class="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9">
                                      <select name="select" required="" class="form-select form-control" @change="calcFinalAmount" v-model="form.discount_value">
                                          <option value="0">-Select Discount-</option>
                                          <option value="5">5%</option>
                                          <option value="10">10%</option>
                                          <option value="15">15%</option>
                                          <option value="20">20%</option>
                                      </select>
                                  </div>
                              </div>
                              <div class="form-group row align-items-center">
                                  <label class="col-form-label col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 text-lg-end mb-0">Final Amount<small class="text-danger asterisksign">*</small></label>
                                  <div class="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9">
                                      <input type="text" class="form-control" readonly id="finalamount" v-model="v$.form.plan_final_amount.$model"  placeholder="Final Amount">
                                      <div class="error-msg" v-if="v$.form.plan_final_amount.$errors.length > 0">
                                        <ul class="mb-0 p-0 fs-14"><li class="d-block" v-for="(error, index) of v$.form.plan_final_amount.$errors"> {{ error.$message }}</li>
                                        </ul>
                                      </div>
                                  </div>
                              </div>
                              <div class="form-group row align-items-center">
                                  <label class="col-form-label col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 text-lg-end mb-0">Status<small class="text-danger asterisksign">*</small></label>
                                  <div class="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9">
                                      <select name="select" required="" class="form-select form-control" v-model="v$.form.status.$model">
                                          <option value="">-Select Status-</option>
                                          <option value="Active">Active</option>
                                          <option value="Inactive">Inactive</option>
                                      </select>
                                      <div class="error-msg" v-if="v$.form.status.$errors.length > 0">
                                        <ul class="mb-0 p-0 fs-14"><li class="d-block" v-for="(error, index) of v$.form.status.$errors"> {{ error.$message }}</li>
                                        </ul>
                                      </div>
                                  </div>
                              </div>
                              <div class="form-group row align-items-center">
                                  <label class="col-form-label col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 text-lg-end mb-0">Category
                                    <small class="text-danger asterisksign">*</small>
                                  </label>
                                  <div class="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9">
                                      <select name="select" required="" class="form-select form-control" v-model="v$.form.category.$model">
                                          <option value="">-Select Category-</option>
                                          <option value="HRT">HRT</option>
                                          <option value="Weight Management">Weight Management</option>
                                      </select>
                                      <div class="error-msg" v-if="v$.form.category.$errors.length > 0">
                                        <ul class="mb-0 p-0 fs-14"><li class="d-block" v-for="(error, index) of v$.form.category.$errors"> {{ error.$message }}</li>
                                        </ul>
                                      </div>
                                  </div>
                              </div>
                              <div class="form-group row align-items-center">
                                  <label class="col-form-label col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 text-lg-end mb-0">Duration
                                    <small class="text-danger asterisksign">*</small>
                                  </label>
                                  <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                                      <select name="select" required="" class="form-select form-control mb-2" v-model="v$.form.duration.$model">
                                        <option value="">-Duration-</option>
                                        <option :value="n" v-for="(n,index) in 15">{{ n }}</option>
                                      </select>
                                      <div class="error-msg" v-if="v$.form.duration.$errors.length > 0">
                                        <ul class="mb-0 p-0 fs-14"><li class="d-block" v-for="(error, index) of v$.form.duration.$errors"> {{ error.$message }}</li>
                                        </ul>
                                      </div>
                                  </div>
                                  <div class="col-12 col-sm-6 col-md-6 col-lg-5 col-xl-5">
                                      <select name="select" required="" v-model="v$.form.duration_type.$model" class="form-select form-control mb-2">
                                        <option value="">-Duration Type-</option>
                                        <option value="week">Week</option>
                                        <option value="month">Month</option>
                                      </select>
                                      <div class="error-msg" v-if="v$.form.duration_type.$errors.length > 0">
                                        <ul class="mb-0 p-0 fs-14"><li class="d-block" v-for="(error, index) of v$.form.duration_type.$errors"> {{ error.$message }}</li>
                                        </ul>
                                      </div>
                                  </div>
                              </div>
                              <div class="form-group row align-items-center">
                                  <label class="col-form-label col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 text-lg-end mb-0">Renewal Duration
                                    <small class="text-danger asterisksign">*</small>
                                  </label>
                                  <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                                      <select name="select" required="" class="form-select form-control mb-2" v-model="v$.form.renewal_duration.$model">
                                        <option value="">-Duration-</option>
                                        <option :value="n" v-for="(n,index) in 15">{{ n }}</option>
                                      </select>
                                      <div class="error-msg" v-if="v$.form.renewal_duration.$errors.length > 0">
                                        <ul class="mb-0 p-0 fs-14"><li class="d-block" v-for="(error, index) of v$.form.renewal_duration.$errors"> {{ error.$message }}</li>
                                        </ul>
                                      </div>
                                  </div>
                                  <div class="col-12 col-sm-6 col-md-6 col-lg-5 col-xl-5">
                                      <select name="select" required="" v-model="v$.form.renewal_duration_type.$model" class="form-select form-control mb-2">
                                        <option value="">-Duration Type-</option>
                                        <option value="week">Week</option>
                                        <option value="month">Month</option>
                                      </select>
                                      <div class="error-msg" v-if="v$.form.renewal_duration_type.$errors.length > 0">
                                        <ul class="mb-0 p-0 fs-14"><li class="d-block" v-for="(error, index) of v$.form.renewal_duration_type.$errors"> {{ error.$message }}</li>
                                        </ul>
                                      </div>
                                  </div>
                              </div>
                          </div>
                        <div class="modal-footer">
                            <button type="button" @click="closeDialog"  class="waves-effect waves-light btn btn-danger" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="waves-effect waves-light btn btn-info"  @click="saveSubcriptionPlan()">Save</button>
                        </div>
                    </form>
                </div>
            </div>
          </div>
        </div>
      </div>
      <section class="content">
        <div class="box">
          <div class="box-body">
            <div class="table-responsive">
              <data-table dataType="subcriptionPlan"  sortBy="From Date" :dataTypes="{dataType: 'subcriptionPlan', 'method' : 'get'}" :key="table_load_count">
                <template v-slot="slotProps">
                  <!-- slot for table data -->
                  <td>{{ slotProps.item.plan_code }}</td>
                  <td>{{ slotProps.item.plan_name }}</td>
                  <td>{{ slotProps.item.plan_amount }}</td>
                  <td>{{ slotProps.item.discount_value }}%</td>
                  <td>{{ slotProps.item.plan_final_amount }}</td>
                  <td> 
                    <span v-if="slotProps.item.status == 'Active' " class="badge badge-success-light rounded d-inline-block fs-16">{{ slotProps.item.status }}  </span>  
                    <span v-else-if="slotProps.item.status == 'Inactive' " class="badge badge-danger-light rounded d-inline-block fs-16">{{ slotProps.item.status }}</span>
                  </td>
                  <td>
                    <div class="d-flex">
                      <Popper arrow content="Edit Subcription Plan" :hover="true" class="helptips cursor-pointer">
                        <a v-if="slotProps.item.status != 'Completed'"
                          @click="editSubcription(slotProps.item.id)"
                          href="#"
                          class="waves-effect waves-circle btn btn-circle btn-info btn-xs me-1" 
                          ><i class="fa fa-pencil"></i></a>
                      </Popper>
                      <!-- <a
                        @click="deleteItem(slotProps.item.id)"
                        href="#"
                        class="waves-effect waves-circle btn btn-circle btn-danger btn-xs" title="Delete Subcription Plan"
                        ><i class="fa fa-trash"></i
                      ></a> -->
                    </div>
                  </td>
                </template>
              </data-table>
            </div>
          </div>
        </div>
      </section>
      <!--document model start-->
      <div id="subsciptiondocument" class="modal fade" tabindex="-1" role="dialog"
        aria-labelledby="myModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-md">
          <div class="modal-content">
          <div class="modal-header">
          <h3 class="modal-title fw-900 mt-0">Reference of Knowledge Base</h3>
          </div>
          <div class="modal-body overflow-auto" style="max-height: 350px;">
          <table class="table table-bordered">
            <thead>
              <tr>
              <th scope="col">No.</th>
              <th scope="col">Knowledge Base Articles</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td> <a :href="path+'how-to-add-new-subscription-plan'" target="_blank">How To Add New Subscription Plan?</a></td>
              </tr>
              <tr>
                <td>2</td>
                <td> <a :href="path+'subscription-and-contracts'" target="_blank">Subscription And Contracts</a></td>
              </tr>
            </tbody>
          </table>
          <Search :key="searchKey"></Search>
          </div>
          <div class="modal-footer">
          <button type="button" @click="" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
          </div>
          </div>
        </div>
      </div>
      <!--document model end-->
    </div>
  </div>

</template>

<script>

import axios from "@/scripts/axios.js";
import dataTable from "@/components/base/DataTable.vue";
import submitMixins from "@/scripts/mixins/submitMixins.js";
import useVuelidate from "@vuelidate/core";
import TableBase from "@/components/base/Table.vue";
import permissionMixins from "@/scripts/mixins/permission.js";
import Popper from "vue3-popper";
import Search from "@/components/Search.vue";

export default {

  name: "Leave",
  setup: () => {
    return { v$: useVuelidate() };
  },
  mixins: [submitMixins,permissionMixins],
  components: { TableBase, dataTable, Popper, Search },
  data() {
    
    return {
      table_load_count : 0,
      path: process.env.VUE_APP_KB_DOCUMENT,
      searchKey:0,
    }
    
  },
  mounted() {
    const $this = this;

    $(document).ready(function(){
      $(document).on('focusout', '#amount', function(){
        console.log('#amount out of focus');
        const newVal = $this.form.plan_amount;
        
        if(newVal != ""){
          const arr = newVal.split('.');
          if(arr[1] !== "NaN" && arr[1] !== NaN && arr[1] !== undefined && arr[1] !== 'undefined'){
            if(arr[1] != ""){
              const finalAmount = arr[0] +'.'+ (parseInt(arr[1]) !== NaN ? parseInt(arr[1]) : '');
              $this.form.plan_amount = finalAmount;
            }
          }
          $this.form.plan_amount = parseFloat($this.form.plan_amount.replaceAll(',',''));
          $this.calcFinalAmount();
        }
      });
    });
  },
  computed: {
    form() {
      return this.$store.state.subcriptionPlan.form;
    },
    vuelidateExternalResults() {
      return this.$store.state.subcriptionPlan.vuelidateExternalResults;
    },
    validationRules() {
      return this.$store.state.subcriptionPlan.validationRules;
    },
  },
  validations() {
    return this.$store.state.subcriptionPlan.validationRules;
  },
  methods: {
    reset() {
      this.searchKey = this.searchKey + 1;
    },
    calcFinalAmount() {
      if (this.form.plan_amount > 0 && this.form.discount_value > 0) {
          this.form.plan_final_amount = (this.form.plan_amount - (this.form.plan_amount*this.form.discount_value/100).toFixed(2));
      } else {
        this.form.plan_final_amount = this.form.plan_amount;
      }
    },
    resetForm() {
       var self = this;
       Object.keys(this.form).forEach(function(key,index) {
         self.form[key] = '';
       });
      this.date = '';
      this.v$.$reset();
    },
    closeDialog() {
        $('#subscriptionplanModal').modal('hide');
        this.resetForm();
    },
    saveSubcriptionPlan() {
      const storeOrUpdate = this.form.id ? 'update' : 'store';
      const url = '/subscription-plan/'+storeOrUpdate;
      this.postForm(url, 'subcriptionPlan');
    },
    formPostSuccess(response) {
      const message = this.form.id ? 'Record updated successfully.' : 'Record added successfully.';
      this.moshaToast(message,'success');
    
      $('#subscriptionplanModal').modal('hide');
    
      this.resetForm();
      setTimeout(function() {
        location.reload();
      }, 1000);
    },
    async editSubcription(id) {
        if (id) {
            const result =  await this.$store.dispatch("getEditData", {
              dataType: "subcriptionPlan", // pass the module name
              formType: "form", // pass the propery name
              id: id,
            });
          $('#subscriptionplanModal').modal('show');
        }
   },
   deleteItem(id) {
      this.$swal.fire({
        title: 'Are you sure you want to delete this record?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      })
      .then((result) => {
          if (result.isConfirmed == true) {
              axios.post(`/leave/delete`,{'id':id})
              .then((response) => {
              this.$swal.fire('Deleted!','Leave has been deleted.','success')  
                  .then((result) => { 
                      if(result.isConfirmed == true){
                         location.reload();
                      }
                  });
              })
          }
      });
    },
  },
};
</script>
