<template>       
	<div class="box p-15" id="noteSection" :style=noteSectionStyle>
		<div>
			<h1 class="heading1 my-0 me-3 d-inline-block">Clinical Notes <a href="#" data-bs-toggle="modal" data-bs-target="#clinicalnotedocument" title="Reference of Knowledge Base"> <i class="fa fa-question-circle fs-20"></i></a></h1>
			<a href="#" class="bg-danger-light rounded px-3 py-1 fs-20 l-h-n float-end" title="Toggle Appointments" @click="toggleSidebar(0)" v-on:click.prevent="reset()"><i class="fa fa-bars"></i></a>
		</div>
		<div class="bg-lightest rounded b-1 p-10 my-3" v-if="appointment_id!=''">          
			<div class="my-1">
				<p class="mb-0 me-3 d-inline-block"><b>Appointment:- </b>{{ AppointmentLabel }}</p>
				<p class="badge badge-danger-light white-space-normal mb-0 me-2" v-if="note_sign_and_lock_rights==0 && note_lock_status!=true">You do not have rights to save clinical note for this patient</p>
				<div class="d-inline-block" :style="stickyButtonStyle">	
					<button v-if="note_lock_status==true && note_unlock_rights==1" @click="unlock_note($event)" type="button" class="waves-effect waves-light btn btn-info mb-1 me-1"><i class="fa fa-lock"></i> Unlock </button>
					<button v-if="note_sign_and_lock_rights==1" @click="sign_lock($event)" type="button" class="waves-effect waves-light btn btn-info mb-1 me-1"><i class="fa fa-lock"></i> {{sign_lock_label}} </button>
					<button type="button" v-if="notePreview" id="previewNote" class="waves-effect waves-light btn btn-info-light mb-1 me-1" @click="PreviewNote(appointment_id,AppointmentLabel,masterId, $event)"><i class="fa fa-file-pdf-o fs-14"></i> Preview Note</button>
				</div>
			</div>
			<span v-if="note_lock_status==true" class="badge badge-success-light" style="border-radius: 5px;"><i class="fa fa-lock fs-14"></i> Status: Locked by {{lockedByProvider}}</span>
			<label v-if="note_msg!=''" v-html="note_msg"></label>
			<label v-if="tempMsg!=''" v-html="tempMsg"></label>
		</div>
		<div v-else>
			<p class="p-15">Appointment not found for write clinical note</p>
		</div>

		<div v-html="categoriesTemplate" class="mb-20" id="categoriesTemplate"></div>

			<!-- Note view section (is_template_show=0)-->

		<template v-if="is_template_show==0">
			<template v-if="embed=0">
				<vue-pdf-viewer height="1050px" :url="url" style="width:100%"></vue-pdf-viewer>  
			</template>
			<template v-if="embed=1">
				<iframe :src="url" height="1050px" style="width:100%" />
			</template>                
		</template>

		<!-- Note add/edit section (is_template_show=1)-->
		<template v-if="is_template_show==1">
			<div class="formio-component-form">
				<div class="tab-pane animation-fade active" id="category-1" role="tabpanel">
					<div class="panel-group panel-group-simple panel-group-continuous mb-0" id="accordion2" aria-multiselectable="true" role="tablist">
						<div v-for="index in notes" class="panel pagecollap">
							<div class="panel-heading" :id="index" role="tab">
								<a class="panel-title" :aria-controls="'answer-'+index" aria-expanded="true" title="Click to open note" @click="stickButton('title'+index)" data-bs-toggle="collapse" :href="'#answer-'+index" :id="'title'+index" data-parent="#accordion2">Loading...</a>
							</div>
							<div class="panel-collapse collapse" v-bind:class="showClass" :id="'answer-'+index" :aria-labelledby="index" role="tabpanel" data-bs-parent="#category-1">
								<div class="panel-body clinical_notes_form b-0" style="padding:0 0 !important;">
									<div class="extra_part">   	    
										<div class="previous_note_section bg-light p-15 rounded5 mb-10">
											<div class="form-group row mb-0">
												<div class="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9">
													<div class="row" v-if="previous_notes.length>0">
														<label class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0">Copy Previous Note: </label>
														<div class="col-12 col-sm-6 col-md-8 col-lg-9 col-xl-9" >
															<select class="form-control" @change="getPreviousNoteData($event)" :data-previous-index="index">
																<option value="">-- select Appointment --</option>
																<option v-for="loop in previous_notes" :value="loop.id">{{loop.title}}</option>
															</select>
			      										</div>
			      									</div>
			      									<div class="row" v-else>
			      										<label class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-form-label mb-0">No previous note available to copy</label>
			      									</div>
												</div>
												<div class="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 text-sm-end" v-if="activate_section=='1'">
													<p class="my-2 fs-14" v-if="noteData[index].template_detail_data['is_included']!==null">
														<b>Include in Note: </b>
														<Toggle :id="'toggle'+index" :name="'include'+index"  v-model="noteData[index].template_detail_data['is_included']" :value="noteData[index].template_detail_data['is_included']"  on-label="Yes" off-label="No" @change="noteActivate($event,index)"/>
													</p>
												</div>
											</div>
										</div>
										<icd-code-table :dataId="appointment_id" v-if="icd10PartVisible == index"></icd-code-table>
										<cpt-code-table :dataId="appointment_id" v-if="cptPartVisible == index"></cpt-code-table>
									</div>
									<div v-bind:id="'notes'+index" style="margin-bottom:50px">
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</template>

			<!-- Loader (is_template_show=2)-->
		<template v-if="is_template_show==2">Loading Please wait...</template>

			<!-- Note amendment section (is_template_show=3)-->
		<template v-if="is_template_show==3" :key="amendment_reload">
			<div>
				<div class="table-responsive" >
					<table-base 
					:dataTypes="{
						dataType: 'Amendments',
						patient_id: patient_id,
						patient_unique_id:patient_unique_id,
						appointment_id:this.appointment_id
					}"
					:lastcol='permissioncheck(this.$store.state.permission.create_update)'
					:key="amendment_reload"
					>
					<template v-slot="slotProps">
						<td>
							<a href="javascript:void(0);" @click="FileDisplay(slotProps.item.document_name_url,$event)" class="fw-600 d-block">{{ slotProps.item.document_name }}</a>
							<b class="fw-500">Comments: </b>{{ slotProps.item.comments }}<br>
							<b class="fw-500">Source: </b>{{ slotProps.item.source }}
						</td>
						<td v-if="slotProps.item.status == 'Accepted'">
							<span class="badge badge-success-light"><i class="mdi mdi-check"></i> {{ capitalizeFirstLetter(slotProps.item.status) }}</span>
						</td>
						<td v-else>
							<button v-if="permissioncheck(this.$store.state.permission.create_update)" @click="edit(slotProps.item.id)" class="waves-effect waves-light btn btn-info btn-xs me-1"><i class="mdi mdi-table-edit "></i> Edit</button>
							<button class="waves-effect waves-light btn btn-danger btn-xs"><i class="mdi mdi-close"></i> {{ capitalizeFirstLetter(slotProps.item.status) }}</button>
						</td>
						<td>
							{{ $filters.displayDateTimeLocal(slotProps.item.created_at,'MM-DD-YYYY h:mm A') }}<br>
							<b class="fw-500">Requested: </b>{{ $filters.displayDateTimeLocal(slotProps.item.request_date,'','',"ddd, MM-DD-YYYY",true) }}<br>
							<b class="fw-500">Processed: </b>{{ $filters.displayDateTimeLocal(slotProps.item.processed_date,'','',"ddd, MM-DD-YYYY",true) }}
						</td>
						<td>{{ capitalizeFirstLetter(slotProps.item.provider_name) }}</td>
						<td>
							<div class="d-flex" v-if="permissioncheck(this.$store.state.permission.create_update)">
								<a title="Send Amendment Document to patient portal" v-if="slotProps.item.status == 'Accepted'" @click="senddocument(slotProps.item.id,'amendments')" href="javascript:void(0)" class="waves-effect waves-circle btn btn-circle btn-info btn-xs me-5" data-bs-toggle="tooltip" data-bs-original-title="Send to onpatient"><i class="fa fa-send"></i></a>
								<a title="Send Clinical Note with Amendment to patient portal" v-if="slotProps.item.status == 'Accepted'" @click="sendnots(slotProps.item.clincal_id)" href="javascript:void(0)" class="waves-effect waves-circle btn btn-circle btn-info btn-xs" data-bs-toggle="tooltip" data-bs-original-title="Send to onpatient"><i class="fa fa-share"></i></a>
								<div v-else>NA</div>
							</div>
						</td>
					</template>
					</table-base>
				</div>

				<div v-if="permissioncheck(this.$store.state.permission.create_update)" class="attach-record_required mt-10" :class="dynamicClass">
					<div class="pageheading">
						<h3>Attach a new amendment document to this patient record. All fields are required.</h3>
					</div>
					<form novalidate class="form-control_input">
						<file-upload
						id="document_name"
						title="Amendment Document"
						v-model="v$.form.document_name.$model"
						:errors="v$.form.document_name.$errors"
						:isRequired="true"
						notes ="PDF files only."
						labelClass="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end"
						divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3"
						v-if="amendment_edit==false"
						></file-upload>

						<input-field
						title="Amendment Source"
						id="amendment_source"
						v-model="v$.form.source.$model"
						:errors="v$.form.source.$errors"
						note ="Where this amendment originated (patient, provider, etc)"
						labelClass="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end"
						divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3"
						:isRequired="true"
						>
						</input-field>

						<select-box-static
						title="Status"
						v-model="v$.form.status.$model"
						:errors="v$.form.status.$errors"
						:isRequired="true"
						:option="Status"
						labelClass="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end"
						divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3"
						note ="Whether you accept or deny this amendment to the patient's record."
						></select-box-static>

						<text-area 
						title="Comments/Notes"
						v-model="form.comments"
						labelClass="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end"
						divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3"
						notes ="Description or comments regarding this amendment, status, etc."
						>
						</text-area>

						<div class="form-group row" max="2021-09-26">
								<label class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end">Request Date</label>
								<div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3 availdate">
									<div class="controls">
										<!-- <input v-model="form.request_date" id="Request Date" type="date" name="text" class="form-control" placeholder="Request Date" :max="this.current_date"> -->
										<ejs-datepicker 
										:value="form.request_date" 
										id="request_date" 
										:format="dateFormat" 
										placeholder="select Request Date"
										@change="getdate($event)" 
										:strictMode='strict' 
										:max='maxVal' 
										:showClearButton='false' 
										:openOnFocus='true'>
									</ejs-datepicker>
								</div>
							</div>
							<div class="col-12 col-sm-8 col-md-8 offset-sm-4 offset-lg-0 col-lg-4 col-xl-5">
								<span class="form-text text-muted align-sub">The date on which the patient or other source requested the amendment.</span>
							</div>
						</div>

						<div class="form-group row">
								<label class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end">Processed Date</label>
								<div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3 availdate">
									<div class="controls">
										<!-- <input v-model="form.processed_date" id="Request Date" type="date" name="text" class="form-control" placeholder="Request Date" :max="this.current_date"> -->
										<ejs-datepicker 
										:value="processed_date" 
										id="form.processed_date" 
										:format="dateFormat" 
										placeholder="select Request Date"
										@change="getprocesseddate($event)" 
										:strictMode='strict' 
										:max='maxVal' 
										:showClearButton='false' 
										:openOnFocus='true'>
									</ejs-datepicker>
								</div>
							</div>
							<div class="col-12 col-sm-8 col-md-8 offset-sm-4 offset-lg-0 col-lg-4 col-xl-5">
								<span class="form-text text-muted align-sub">The date on which you reviewed the amendment request.</span>
							</div>
						</div>
					</form>
					<div class="row">
						<div class="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9 text-center">
							<form-btn title="Save" btnCls="waves-effect waves-light btn btn-info" @click="save(false)" ></form-btn>
							<form-btn title="Cancel" @click="resetForm()" btnCls="waves-effect waves-light btn btn-danger"></form-btn>
						</div>
					</div>
				</div>
			</div>
		</template>
	</div>
	
	<p class="autosave_msg d-none"><i class="fa fa-spinner fa-spin"></i> Auto Save</p>

	<aside class="control-sidebar control-sidebar-open scroll-top p-0 pb-0 bg-white side-note-active" id="noteSidebar">
		<div class="rpanel-title">
			<span class="pull-right btn-sm btn btn-circle btn-danger-light fs-16" data-toggle="control-sidebar"><i class="ion ion-close"></i></span>
		</div>
		<ul class="nav nav-tabs control-sidebar-tabs p-10 pb-0">
			<li class="nav-item"><a href="#control-sidebar-notes" data-bs-toggle="tab" class="active px-1" title="notes"><i class="mdi mdi-note-multiple-outline"></i></a></li>
			<li class="nav-item"><a href="#control-sidebar-locked-notes" v-if="importedAppointmentData.length>0" title="Imported Notes" data-bs-toggle="tab" class="px-1"><i class="mdi mdi-import" style="transform: rotate(90deg); display: inline-block;"></i></a></li>
		</ul>
		<div class="tab-content">
			<div class="tab-pane active" id="control-sidebar-notes">
				<div class="media-list media-list-hover" v-if="appointmentData.length>0">
					<div class="media py-10 px-0 justify-content-space-between justify-content-between bb-1" v-for="appointment in appointmentData" v-bind:class="($filters.displayDateTimeLocal(appointment.appDate,'',false,'YYYY-MM-DD',true) > this.$filters.displayDateTimeLocal(new Date(),'',false,'YYYY-MM-DD',true)) ? 'future_appt' : ''">
						<div class="fs-16">
							<a href="#" :data-href="appointment.appointment_number" :data-href-id="appointment.id" @click="getAppointmentwiseNotes(appointment.lockedByProvider,appointment.duration,appointment.appDate,appointment.appointment_number, appointment.id, appointment.name, appointment.note_status, appointment.is_supervisor_sign_required, appointment.provider_id, appointment.supervisor_id, appointment.masterId, appointment.note_id,$event)" ref="noteViewButton"><strong>{{ appointment.appDate }} </strong></a>
							<p class="mb-5">{{ appointment.reason_for_visit }}</p>
							<p class="badge badge-info-light mb-0 py-1 fs-14" v-if="appointment.note_status=='provider_signed'">Provider Signed</p>
							<p class="badge badge-success-light mb-0 py-1 fs-14" v-if="appointment.note_status=='locked'">Locked</p>
							<p class="badge badge-secondary-light mb-0 py-1 fs-14" v-if="appointment.note_status=='pending'">Unsigned</p>
							<p class="badge badge-danger-light mb-0 py-1 fs-14" v-if="appointment.note_status==null || appointment.note_status==''">Pending</p>
						</div>
						<div class="notebutton">
							<Popper v-if="appointment.notes>0 && appointment.note_status!='locked'" arrow content="Add/Edit Note" :hover="true" class="helptips cursor-pointer">
								<a class="fs-14" href="#" @click="getAppointmentwiseNotes(appointment.lockedByProvider,appointment.duration,appointment.appDate,appointment.appointment_number,appointment.id, appointment.name, appointment.note_status, appointment.is_supervisor_sign_required, appointment.provider_id, appointment.supervisor_id, appointment.masterId, appointment.note_id,$event)"><i class="fa fa-pencil"></i></a>
							</Popper>

							<Popper v-if="appointment.notes==0" arrow content="Add/Edit Note" :hover="true" class="helptips cursor-pointer">
								<a class="fs-14" href="#" @click="getAppointmentwiseNotes(appointment.lockedByProvider,appointment.duration,appointment.appDate,appointment.appointment_number,appointment.id, appointment.name, appointment.note_status, appointment.is_supervisor_sign_required, appointment.provider_id, appointment.supervisor_id, appointment.masterId, appointment.note_id,$event)"><i class="fa fa-plus"></i></a>
							</Popper>

							<Popper v-if="appointment.notes>0" arrow content="View PDF" :hover="true" class="helptips cursor-pointer">
								<a class="fs-14" href="#" @click="PreviewNote(appointment.id,appointment.name,appointment.masterId, $event, 0, appointment.duration,appointment.appDate, appointment.appointment_number,appointment.note_status, appointment.is_supervisor_sign_required, appointment.provider_id, appointment.supervisor_id, appointment.note_id, appointment.lockedByProvider)"><i class="fa fa-eye"></i></a>
							</Popper>

							<Popper v-if="appointment.locked_at!=null" arrow content="Amendments" :hover="true" class="helptips cursor-pointer">
								<a class="fs-14" href="#" @click="getAmendments(appointment.appointment_number,appointment.id, appointment.name, appointment.masterId,$event)"><b><i class="mdi mdi-move-resize-variant" aria-hidden="true"></i></b></a>
							</Popper>

							<Popper v-if="appointment.note_status=='locked'" arrow content="Send to Patient Portal" :hover="true" class="helptips cursor-pointer">
								<a @click="senddocument(appointment.note_id,'clinical_note')" href="#" class="fs-14"><i class="fa fa-send"></i></a>
						  </Popper>
						</div>
					</div>
				</div>
				<div class="media-list media-list-hover" v-else>
					<p class="p-10">Appointment not found for write clinical note</p>
				</div>
			</div>
			<div class="tab-pane" id="control-sidebar-locked-notes" v-if="importedAppointmentData.length>0">
				<div class="media py-10 px-0 justify-content-space-between justify-content-between bb-1" v-for="importedappointment in importedAppointmentData">
					<div class="fs-16">
						<a href="#" :data-href="importedappointment.appointment_number" :data-href-id="importedappointment.id" @click="PreviewNote(importedappointment.id,importedappointment.name,importedappointment.masterId, $event, 1)"><strong>{{ importedappointment.appDate }} </strong></a>
						<p class="mb-5">{{ importedappointment.reason_for_visit }}</p>
						<p class="badge badge-success-light mb-0 py-1 fs-14" v-if="importedappointment.note_status=='locked'">Locked</p>
					</div>
					<div class="notebutton">
						<a class="fs-14" href="#" @click="PreviewNote(importedappointment.id,importedappointment.name,importedappointment.masterId, $event, 1)" title="View PDF" v-if="importedappointment.notes>0"><i class="fa fa-eye"></i></a>							
					</div>
				</div>					
			</div>
		</div>
	</aside>

	<div class="modal" id="followUp_Appointment_modal" tabindex="-1" role="dialog">
	  <div class="modal-dialog" role="document">
		<div class="modal-content">
		  <div class="modal-header">
			<h3 class="modal-title text-center"><b>Are you sure you want to schedule follow up appointment? </b></h3>
		  </div>
		  <div class="modal-body">
			<p class="text-center">Note: - appointment date will be scheduled on</p>
			<div class="form-group row">
				<label class="col-form-label appt_day col-12 col-sm-3 col-md-4 col-lg-3 text-sm-end"></label>
				<div class="col-12 col-sm-9 col-md-8 col-lg-9">
					<div class="availdate">
						<ejs-datetimepicker id="followup_schedule" :value="slot_suggestion_time_manual" placeholder="mm-dd-yyyy, --:-- --" :showClearButton='false' format="MM-dd-yyyy hh:mm a" step="15" :openOnFocus='true'></ejs-datetimepicker>
					</div>
				</div>
			</div>
		  </div>
		  <div class="modal-footer">
			<button type="button" class="btn btn-primary" @click="checkAvailability">Yes schedule an appointment</button>
			<button type="button" class="btn btn-secondary" @click="closemodal" data-dismiss="modal">Close</button>
		  </div>
		</div>
	  </div>
	</div>

	<div id="displayavailableslots" class="modal">
		<div class="modal-dialog modal-sm">
			<div class="modal-content">
				<div class="modal-header">
					<h3 class="modal-title fw-900 mt-0">CHOOSE A FREE SLOT</h3>
				</div>   
				<div class="modal-body">										
					<div class="form-group mb-0" v-for="(item,index) in slot_suggestion_list.slice(0,5)" :key="index">
						<input v-model="slot_suggestion_time" type="radio" v-bind:id="'suggestion'+index" name="suggestion" class="filled-in chk-col-info" @change="syncDate($event)" :value="item.schedule_time">
						<label v-bind:for="'suggestion'+index">{{item.value}}</label>
					</div>
					<div class="free_slot mb-10">
						<div class="form-group mb-0" v-for="(item,index) in slot_suggestion_list.slice(6)" :key="index">
							<input v-model="slot_suggestion_time" type="radio" v-bind:id="'qsuggestion'+index" name="suggestion" class="filled-in chk-col-info" @change="syncDate($event)" :value="item.schedule_time">
							<label v-bind:for="'qsuggestion'+index">{{item.value}}</label>
						</div>
					</div>
					<button type="button" class="waves-effect waves-light btn btn-light float-end check_box">more</button>

					<p> -- Or -- </p>

					<ejs-datetimepicker id="slot_suggestion_time_manual" placeholder="mm-dd-yyyy, --:-- --" :value="slot_suggestion_time_manual" :showClearButton='false' format="MM-dd-yyyy hh:mm a" step="15" :openOnFocus='true' @change="syncSelection($event)"></ejs-datetimepicker>

				</div>
				<div class="modal-footer">
					<button type="button" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-info" @click="save_schedule">Schedule</button>
					<button type="button" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger-light">Close</button>
				</div>
			</div>
		</div>
	</div>
	<!--document model start-->
	<div id="clinicalnotedocument" class="modal fade" tabindex="-1" role="dialog"
		aria-labelledby="myModalLabel" aria-hidden="true">
		<div class="modal-dialog modal-md">
		<div class="modal-content">
			<div class="modal-header">
				<h3 class="modal-title fw-900 mt-0">Reference of Knowledge Base</h3>
			</div>
			<div class="modal-body overflow-auto" style="max-height: 350px;">
				<table class="table table-bordered">
					<thead>
					<tr>
						<th scope="col">No.</th>
						<th scope="col">Knowledge Base Articles</th>
					</tr>
					</thead>
					<tbody>
						<tr>
							<td>1</td>
							<td> <a :href="path+'how-to-write-a-clinical-note-in-pureehr'" target="_blank">How To Write A Clinical Note In PureEHR?</a></td>
						</tr>
						<tr>
							<td>2</td>
							<td> <a :href="path+'how-to-preview-the-clinical-note'" target="_blank">How To Preview The Clinical Note?</a></td>
						</tr>
						<tr>
							<td>3</td>
							<td> <a :href="path+'how-to-edit-a-clinical-note-in-pureehr'" target="_blank">How To Edit A Clinical Note In PureEHR?</a></td>
						</tr>
						<tr>
							<td>4</td>
							<td> <a :href="path+'how-does-the-supervising-provider-sign-and-lock-the-clinical-note-in-pureehr'" target="_blank">How Does The Supervising Provider Sign And Lock The Clinical Note In PureEHR?</a></td>
						</tr>
						<tr>
							<td>5</td>
							<td> <a :href="path+'how-to-unlock-any-clinical-note-in-pureehr'" target="_blank">How To Unlock Any Clinical Note In PureEHR?</a></td>
						</tr>
						<tr>
							<td>6</td>
							<td> <a :href="path+'how-to-active-deactive-clinical-notes-in-pureehr'" target="_blank">How To Active/Deactive Clinical Notes In PureEHR?</a></td>
						</tr>
						<tr>
							<td>7</td>
							<td> <a :href="path+'how-to-write-an-amendment-in-pureehr'" target="_blank">How To Write An Amendment In PureEHR?</a></td>
						</tr>
						<tr>
							<td>8</td>
							<td> <a :href="path+'how-to-edit-a-rejected-ammendment-in-pureehr'" target="_blank">How To Edit A Rejected Amendment In PureEHR?</a></td>
						</tr>
						<tr>
							<td>9</td>
							<td> <a :href="path+'how-to-make-clinical-note-persistent-and-active-in-pureehr'" target="_blank">How To Make Clinical Note Persistent And Active In PureEHR?</a></td>
						</tr>
					</tbody>
				</table>
				<Search :key="searchKey"></Search>
			</div>
			<div class="modal-footer">
				<button type="button" @click="" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
			</div>
		</div>
		</div>
	</div>

	<!--document model end-->
	<form style="display:none" name="rcopiaForm" id="rcopiaForm" method="post">
	    <div>
	        <input type="text" id="rcopia_portal_system_name" name="rcopia_portal_system_name" value="" size="30" />
	        <input type="text" id="rcopia_practice_user_name" name="rcopia_practice_user_name" value="" size="30" />
	        <input type="text" id="rcopia_user_id" name="rcopia_user_id" value="" size="30" />
	        <input type="text" id="rcopia_user_external_id" name="rcopia_user_external_id" value="" size="100" />
	        <input type="text" id="rcopia_patient_id" name="rcopia_patient_id" value="" size="15" />
	        <input type="text" id="rcopia_patient_system_name" name="rcopia_patient_system_name" value="" size="30" />            
	        <input type="text" id="rcopia_patient_external_id" name="rcopia_patient_external_id" value="" size="40" />
	        <input type="text" id="service" name="service" value="rcopia" size="30" />
	        <input type="text" id="startup_screen" name="startup_screen" value="patient" size="30" />
	        <input type="text" id="close_window" name="close_window" value="n" />
	        <input type="text" id="allow_popup_screens" name="allow_popup_screens" value="y" />
	        <input type="text" id="logout_url" name="logout_url" :value="return_url" size="100" />
	        <input type="text" id="timeout_url" name="timeout_url" :value="timeout_url" size="100" />
	        <input type="text" id="limp_mode" name="limp_mode" value="n" size="100" />
	    </div>
	</form>
</template>
<script>
/*Ref. taken from :- https://github.com/vueform/toggle*/
/*Amendments*/
import FileUpload from "@/components/base/formFields/fileUpload.vue";
import InputField from "@/components/base/formFields/InputField.vue";
import SelectBoxStatic from "@/components/base/formFields/SelectBoxStatic.vue";
import TextArea from "@/components/base/formFields/textArea.vue";
import DatePicker from "@/components/base/formFields/datePicker.vue";
import FormBtn from "@/components/base/formFields/formBtn.vue";
import TableBase from "@/components/base/Table.vue";
import moment from "moment";
import useVuelidate from "@vuelidate/core";
/*Amendments - End*/

import Toggle from '@vueform/toggle';
import Popper from "vue3-popper";
import { mapGetters } from "vuex";
import SelectBox from "@/components/base/formFields/selectBox.vue";
import IcdCodeTable from "@/components/patients/details/ClinicalNote/icd10Code.vue";
import CptCodeTable from "@/components/patients/details/ClinicalNote/cptCode.vue";
import axios from "@/scripts/axios.js";
import submitMixins from "@/scripts/mixins/submitMixins.js";
import permissionMixins from "@/scripts/mixins/permission.js";

/*Note Preview*/
import ClinicalNotePreview from '@/components/patients/details/ClinicalNote/PDF.vue';
import PatientLockedClinicalNotes from "@/components/patients/details/Documents/LockedClinicalNotes.vue";
import VuePDFViewer from 'vue-instant-pdf-viewer';
/*Note Preview - End*/
import Search from "@/components/Search.vue";
/** SPEECH TO TEXT - Start */
/*import { downsampleBuffer, pcmEncode } from '@/assets/awsSpeechToText/audioUtils.js';
import crypto1 from "crypto";
import v4 from '@/assets/awsSpeechToText/aws-signature-v4.js';
import { EventStreamMarshaller } from "@aws-sdk/eventstream-marshaller";
import { toUtf8, fromUtf8 } from "@aws-sdk/util-utf8-node";
import MicrophoneStream from "microphone-stream";*/

/*var micStream1;
var socket;
var inputSampleRate = 0;
var sampleRate = 44100;
var eventStreamMarshaller;
var transcription="";
var textAreaData="";
var speechToTextKey=process.env.VUE_APP_AWSACCESSKEY
var speechToTextSecret=process.env.VUE_APP_AWSSECRET
var speechToTextRegion=process.env.VUE_APP_REGION
var speechToTextLanguageCode=process.env.VUE_APP_LANGUAGECODE*/

/*const getAudioEventMessage = function(buffer) {
    return {
        headers: {
            ':message-type': {
                type: 'string',
                value: 'event'
            },
            ':event-type': {
                type: 'string',
                value: 'AudioEvent'
            }
        },
        body: buffer
    };
}*/

/*const convertAudioToBinaryMessage = function(audioChunk) {
    var raw = MicrophoneStream.toRaw(audioChunk);
    if (raw == null) return; 

    var downsampledBuffer = downsampleBuffer(raw, inputSampleRate, sampleRate);
    var pcmEncodedBuffer = pcmEncode(downsampledBuffer);
    var audioEventMessage = getAudioEventMessage(Buffer.from(pcmEncodedBuffer));
    var binary = eventStreamMarshaller.marshall(audioEventMessage);

    return binary;
}*/

/*const getEventUnMarshall = function(mData) {
    return eventStreamMarshaller.unmarshall(Buffer(mData));
}*/

/*const handleEventStreamMessage = function(messageJson, textAreaId, $this) {
    let results = messageJson.Transcript.Results;

    if (results.length > 0) {
        if (results[0].Alternatives.length > 0) {
            let transcript = results[0].Alternatives[0].Transcript;
            transcript = decodeURIComponent(escape(transcript));
			textAreaData = transcription + transcript + "\n";
			$("#"+textAreaId).val(textAreaData);

			var eleId = textAreaId;
			var index = $(this).parents("div.panel-collapse.collapse.show").attr("aria-labelledby");
			var form = $this.formDataToStore[index], 
			compIdDetail = eleId.split("-"), 
			compId = compIdDetail[0];
			form.getComponentById(compId).setValue(textAreaData);

			if (!results[0].IsPartial) {
                transcription += transcript + "\n";
            }
        }
    }
}*/

/*const createPresignedUrl = function() {
	try {
		var endpoint = "transcribestreaming." + speechToTextRegion + ".amazonaws.com:8443";

		return v4.createPresignedURL('GET', endpoint, '/stream-transcription-websocket', 'transcribe', crypto1.createHash('sha256').update('', 'utf8').digest('hex'), {
			'key': speechToTextKey,
			'secret': speechToTextSecret,
			'sessionToken': "",
			'protocol': 'wss',
			'expires': 15,
			'region': speechToTextRegion,
			'query': "language-code=" + speechToTextLanguageCode + "&media-encoding=pcm&sample-rate=" + sampleRate
		});
	} catch(e) {
		console.error("createPresignedUrl E=> ", e);
	}
}*/
/** SPEECH TO TEXT - End */

export default {
components: { 
	FileUpload,
	InputField,
	TextArea,
	TableBase,
	SelectBoxStatic,
	DatePicker,
	FormBtn,
	Popper,
	SelectBox, 
	IcdCodeTable,
	CptCodeTable,
	ClinicalNotePreview,
	PatientLockedClinicalNotes,
	Toggle,
	'vue-pdf-viewer': VuePDFViewer,
	Search
},
mixins: [submitMixins, permissionMixins],
setup: () => {
  return { v$: useVuelidate() };
},
props:{
    patientId:String,
    patientUId:String,
	margin_top:String
},
data() {
	return {
		patientid:0,
		dynamicClass:"hide",
		medical_history_data: '',
		medication_data: '',
		allergy_data: '',
		schedule_date: '',
		schedule_time: '',
		previous_notes: [],
		onloadDataAutosaveTrigger: 0,
		slot_suggestion_time_manual: '',
		followUpApptDate: '',
		slot_suggestion_list: [],
		slot_suggestion_time: '',
		currentTime: 0,
		dataAutoSaveTrigger: false,
		index: 0,
		amedment_note:'Note :  Select appointment to attach the amendments.',
		amedment_note_reander: true,
		reander:'true',
		current_date:'',
		Status: [
			{value:"Accepted", title:"Accepted"},
			{value:"Rejected", title:"Rejected"},
		],
		strict: true,
		maxVal: new Date(),
		dateFormat: 'MM-dd-yyyy',
		amendment_reload: 0,
		amendment_edit: false,
		request_date: '',
		processed_date: '',
		url: '',
		embed: null,
		show: false,
		is_template_show: '',
		formDataToStore: [],
		axiosChange: 0,
		activate_section: 0,
		is_include: 1,
		switchValue: true,
		hash: '',
		appointmentData: '',
		importedAppointmentData: '',
		notePreview: '',
		notes: '',
		icd10PartVisible: '',
		cptPartVisible: '',
		patient_id: this.$store.state.patientDetail.patient_id,
		patient_unique_id: this.$store.state.patientDetail.patient_unique_id,
		medication_fetched_date: this.$store.state.patientDetail.medication_fetched_date,
		allergy_fetched_date: this.$store.state.patientDetail.allergy_fetched_date,
		appointment_id: '',
		appointment_date: '',
		note_lock_status: false,
		note_msg: '',
		dataSave: false,
		is_supervisor_sign_required: false,
		sign_lock_label : 'Sign',
		provider_id_for_sign: '',
		supervisor_id_for_sign: '',
		provider_id: '',
		supervisor_id: '',
		lockedByProvider: '',
		note_sign_and_lock_rights: 0,
		note_unlock_rights: 0,
		tempMsg: '',
		categoriesTemplate: [],
		AppointmentLabel: '',
		showClass: '',
		masterId: '',
		id: '',
		note_status: '',
		noteData: [{
			template_detail_data: [{
				followup_appointment: '',
				id: '',
				masterId : '',
				template_id: '',
				name: '',
				category: '',
				patient_id: '',
				appointment_id: '',
				note_status: '',
				is_included : '',
				patient_clinical_note_version : '',
			}],
			components: [],
		}],
		data: {},
		isHashActive: true,
		marginTop: this.margin_top ? this.margin_top : 0,
		noteSectionStyle: '',
		stickyButtonStyle: '',
		path: process.env.VUE_APP_KB_DOCUMENT,
		searchKey:0,
		return_url : window.location.href,
        timeout_url : window.location.href+"/#rx-err",
		isMacroCallInprogress: false,
		assessment_pmhx: [],
		assessment_pmhx_other: '',
	};
},
async created() {
	window.addEventListener('scroll', this.handleScroll);
	if(this.$route.params.id) {
		this.patientid = this.$route.params.id;
	} else if(this.patientId) {
		this.patientid = this.patientId;
		this.patient_id = this.patientid;
		this.$store.state.patientDetail.patient_id = this.patientid;
		this.$store.state.patientDetail.patient_unique_id = this.patientUId;
		this.isHashActive = false;
		this.stickyButtonStyle = 'position: fixed; top: 50px; right: 50px; z-index: 1111;';
		this.marginTop=230;
	} else {
		this.patientid = 0;
	}

	this.getAppointments();

	const $this = this;
	
	$(document).on('change','.pmhx-class', function(){
		$this.assessment_pmhx = [];
		$.each($('.pmhx-class'), function(){
			let code = $(this).closest('td').next().find('p').html();
			let description = $(this).closest('td').next().next().find('p').html();

			if(description == '' || description == 'undefined' || description === undefined){
				description = $(this).closest('td').next().next().next().next().find('p').html();;
			}

			if($(this).find('input[type="checkbox"]').is(':checked')){
				$this.assessment_pmhx.push({'code': code, 'description': description});
			}
		});
	});

	$(document).on('DOMSubtreeModified', '.pmhx-other .ql-editor', function(){
		$this.assessment_pmhx_other = $(this).html();
	});
},
mounted(){

	window.setInterval(() => {
		this.currentTime = new Date()
	}, 10000)
	/*macros call*/
	
	/*macros call - end*/
	/*get template*/
	var $this = this;
	$(document).on("click",".getTemplate",function(){
		$this.activate_section=0;
		$this.$store.state.loader = true;

		/*macro generate*/
		$this.macroGenerateFunction();
		/*macro generate -- end*/

		var category = $(this).attr("data-note-type"), note = $(this).attr("data-note"), noteId = $(this).attr("data-note-id");
		
		/*previous Note*/
		$this.previous_note_section(noteId);
		/*previous Note -- end*/

		$(this).siblings().removeClass("activeTab");
		$(this).addClass("activeTab");
		$this.showClass = (noteId!='' && noteId!=null) ? "show" : "";
		$("#accordion2").children().children('.show').find(".submit-btn").addClass('stickyBtn');
		
		$this.data = {};
		$this.noteData = [{
			template_detail_data: [{
				followup_appointment: '',
				id: '',
				masterId : '',
				template_id: '',
				name: '',
				category: '',
				patient_id: '',
				appointment_id: '',
				note_status: '',
				is_included : '',
				patient_clinical_note_version : '',
			}],
			components: [],
		}];

		$this.is_template_show=2;
		$("#noteSidebar").removeClass("control-sidebar-open");
		$this.getNote(category,note,noteId);
	});

	$(document).on("click",".book_appt",function(){
		$this.slot_suggestion_time_manual = moment($this.followUpApptDate).format('YYYY-MM-DD hh:mm a');
		$(".appt_day").text(moment(String($this.followUpApptDate)).format('dddd'));

		$("#followUp_Appointment_modal").modal("show");
	});
	
	/*shortcode*/
	$this.shotCodeGetData();
	/*shortcode -- End*/
	if(this.marginTop>0) {
		this.noteSectionStyle = "margin-top: " + this.marginTop + "px";
	}
	
	$(document).on("click",".stickyBtn", function(){
		$this.dataAutoSave();
	});

	$(document).on("click", ".add_medication", function(){
		if($this.$store.state.auth.is_drfirst_enable == 'y' || ($this.permissioncheck(
                    $this.$store.state.permission.Rcopia_Access_Management) && $this.$store.state.auth.is_rx_setup==true)){
			$this.before_launchRxnt("Medication");
		}else{
			$this.$swal
				.fire("Warning!", "You do not have access to Rcopia, Please coordinate with your supervising Provider");
		}
	});

	$(document).on("click", ".fetch_medication", function(){
		if($this.$store.state.auth.is_drfirst_enable == 'y' || ($this.permissioncheck(
                    $this.$store.state.permission.Rcopia_Access_Management) && $this.$store.state.auth.is_rx_setup==true)){
			$(this).html("<i class='fa fa-sync fa-spin'></i> Fetch Medication");
			$this.fetch_medication();
		}else{
			$this.$swal
				.fire("Warning!", "You do not have access to Rcopia, Please coordinate with your supervising Provider");	
		}
	});

	$(document).on("click", ".fetch_allergy", function(){
		if($this.$store.state.auth.is_drfirst_enable == 'y' || ($this.permissioncheck(
                    $this.$store.state.permission.Rcopia_Access_Management) && $this.$store.state.auth.is_rx_setup==true)){
			$(this).html("<i class='fa fa-sync fa-spin'></i> Fetch Allergy");
			$this.fetch_allergy();
		}else{
			$this.$swal
				.fire("Warning!", "You do not have access to Rcopia, Please coordinate with your supervising Provider");
		}
	});

	$(document).on("click", ".add_allergy", function(){
		if($this.$store.state.auth.is_drfirst_enable == 'y' || ($this.permissioncheck(
                    $this.$store.state.permission.Rcopia_Access_Management) && $this.$store.state.auth.is_rx_setup==true)){
			$this.before_launchRxnt("Allergy");
		}else{
			$this.$swal
				.fire("Warning!", "You do not have access to Rcopia, Please coordinate with your supervising Provider");
		}
	});

	$(document).on("click", ".panel-title", function(){

	});	
},
async beforeCreate() {
	var $this = this;
	$this.$store.state.loader = true;

	/*Amendments*/
		// clear form data since, the same data is being used for add and edit both
		if (this.$route.name === "PatientAmendments") {
			this.$store.state.Amendments.form = {};
			Object.assign(
				this.$store.state.Amendments.form,
			);
		}
	/*Amendments - End*/
},
methods: {
	fetch_allergy(){
		axios
			.post(`/refresh-allergy`, { patient_id : this.$store.state.patientDetail.patient_id, patient_rcopia_id: this.$store.state.patientDetail.patient_rcopia_id, allergy_fetched_date : this.allergy_fetched_date })
            .then((response) => {
            	$(".fetch_allergy").html("<i class='fa fa-refresh'></i> Fetch Allergy");
            	$('table.allergy_data').html(response.data);
            });
	},
	fetch_medication(){
		axios
			.post(`/refresh-medication`, { patient_id : this.$store.state.patientDetail.patient_id, patient_rcopia_id: this.$store.state.patientDetail.patient_rcopia_id, medication_fetched_date: this.medication_fetched_date })
            .then((response) => {
            	$(".fetch_medication").html("<i class='fa fa-refresh'></i> Fetch Medication");
            	$('table.medication_data').html(response.data);
            });
	},
	before_launchRxnt(section){
	    $("a.add_medication").html("Loading Please wait..");
	    

	    if(this.$store.state.patientDetail.patient_rcopia_id==null)
	    {
	        axios
	            .post(`/patient/${this.$route.params.id}/rcopia_registration`,{patient_id: this.$store.state.patientDetail.patient_id, patient_rcopia_id:this.$store.state.patientDetail.patient_rcopia_id,source: 'medication' })
	            .then((response) => {
	                if(response.data.data.patient_rcopia_id)
	                {
	                    $("#rcopia_patient_id").val(response.data.data.patient_rcopia_id);
	                    this.$store.state.patientDetail.patient_rcopia_id = response.data.data.patient_rcopia_id;
	                    this.launchRxnt(section);
	                }
	            })
	            .catch((error) => {
	                this.$swal.fire("Sorry!",error.response.data.message,"warning");
	            });
	    }
	    else
	    {
	        this.launchRxnt(section);
	    }
	},
	launchRxnt(section) {
        /*Rcopia*/

        axios
            .post(`/patient/${this.$route.params.id}/rcopia-access`)
            .then((response) => {
                document.getElementById("rcopia_portal_system_name").value = response.data.data.system_name;
                document.getElementById("rcopia_practice_user_name").value = response.data.data.practice_user_name;
                document.getElementById("rcopiaForm").setAttribute("action",response.data.data.action_url);
                document.getElementById("rcopia_user_external_id").value = response.data.data.provider_external_id;
                var retries = response.data.data.no_of_retries, vendor_pwd = response.data.data.vendor_pwd;
                for(var i=0;i<retries;i++)
                {
                    var $this = this;
                    var responseFrm = this.launchRxntFrm(vendor_pwd, section);
                    if($this.frmSubmit == "Success")
                    {
                        /*Rcopia SSO Logs*/
                        axios.post(`/patient/${this.$route.params.id}/create-rcopia-log`, { patient_id : this.$store.state.patientDetail.patient_id, patient_rcopia_id: this.$store.state.patientDetail.patient_rcopia_id, login_type: "SSO - Medication" });
                        break;
                    }
                    else{
                        /*Rcopia SSO Logs*/
                        axios.post(`/patient/${this.$route.params.id}/create-rcopia-log`, { patient_id : this.$store.state.patientDetail.patient_id, patient_rcopia_id: this.$store.state.patientDetail.patient_rcopia_id, login_type: "SSO - Medication" })
                        /*Rcopia SSO Logs - End*/
                    }
                }
                this.index++;
            });
        /*Rcopia*/
    },
	launchRxntFrm(vendor_pwd, section) {

        $("a.add_medication").html('<i class="fa fa-plus fs-14"></i> '+ section);
        
        document.getElementById("rcopia_patient_external_id").value = document.getElementById("rcopia_practice_user_name").value+document.getElementById("rcopia_portal_system_name").value+this.$store.state.auth.userData.clinic_id+'_'+this.$store.state.patientDetail.patient_id;


        $("#rcopia_patient_id").val(this.$store.state.patientDetail.patient_rcopia_id);

        if($("form#rcopiaForm input[name='MAC']").length>0)
        {
            var timenow = this.gmtTime();
            $("form#rcopiaForm input[name='time']").val(timenow);
            var strMAC = this.GenerateStringForMAC(timenow,vendor_pwd);
            var md5Hash = CryptoJS.MD5(strMAC).toString();
            md5Hash = md5Hash.toUpperCase();
            $("form#rcopiaForm input[name='MAC']").val(md5Hash);
        }
        else{
            var elem = document.createElement("input");
            elem.setAttribute("type", "text");
            elem.setAttribute("name", "action");
            elem.setAttribute("id", "action");
            elem.setAttribute("value", "login");
            document.rcopiaForm.appendChild(elem);

            var timenow = this.gmtTime();
            var elem = document.createElement("input");
            elem.setAttribute("type", "text");
            elem.setAttribute("name", "time");
            elem.setAttribute("id", "time");
            elem.setAttribute("value", timenow);
            document.rcopiaForm.appendChild(elem);

            var elem = document.createElement("input");
            elem.setAttribute("type", "text");
            elem.setAttribute("name", "skip_auth");
            elem.setAttribute("id", "skip_auth");
            elem.setAttribute("value", "n");
            document.rcopiaForm.appendChild(elem);

            var strMAC = this.GenerateStringForMAC(timenow,vendor_pwd);
            var md5Hash = CryptoJS.MD5(strMAC).toString();
            md5Hash = md5Hash.toUpperCase();
            
            elem = document.createElement("input");
            elem.setAttribute("type", "text");
            elem.setAttribute("name", "MAC");
            elem.setAttribute("value", md5Hash);
            document.rcopiaForm.appendChild(elem);
        }

        let $this = this;
        function submitToPopup(f) {
            f.target = '_blank';
            try {
                f.submit();
                $this.frmSubmit = 'Success';
            }
            catch(err) {
                $this.frmSubmit = 'Error';
            }
        }
        return submitToPopup(document.rcopiaForm);
    },
    GenerateStringForMAC(timenowtemp,vendor_pwd) {

        var str = "rcopia_portal_system_name=" + document.getElementById("rcopia_portal_system_name").value +
        "&rcopia_practice_user_name=" + document.getElementById("rcopia_practice_user_name").value +
        "&rcopia_user_id=" + document.getElementById("rcopia_user_id").value +
        "&rcopia_user_external_id=" + document.getElementById("rcopia_user_external_id").value +
        "&rcopia_patient_id=" + document.getElementById("rcopia_patient_id").value +
        "&rcopia_patient_system_name=" + document.getElementById("rcopia_patient_system_name").value +
        "&rcopia_patient_external_id=" + document.getElementById("rcopia_patient_external_id").value +
        "&service=" + document.getElementById("service").value +
        "&startup_screen=" + document.getElementById("startup_screen").value +
        "&close_window=" + document.getElementById("close_window").value +
        "&allow_popup_screens=" + document.getElementById("allow_popup_screens").value +
        "&logout_url=" + document.getElementById("logout_url").value +
        "&timeout_url=" + document.getElementById("timeout_url").value +
        "&limp_mode=" + document.getElementById("limp_mode").value +
        "&action=login" + 
        "&time=" + timenowtemp +
        "&skip_auth=" + document.getElementById("skip_auth").value +
        //"&posturl=" + posturl.Value +
        vendor_pwd;
        return str;
    },
    gmtTime() {
        var GMTTime = new Date();
        var month = GMTTime.getUTCMonth() + 1;
        var day = GMTTime.getUTCDate();
        var year = GMTTime.getUTCFullYear() - 2000;
        var hour = GMTTime.getUTCHours();
        var min = GMTTime.getUTCMinutes();
        var sec = GMTTime.getUTCSeconds();

        if (month < 10) { month = "0" + month; }
        if (day < 10) { day = "0" + day; }
        if (hour < 10) { hour = "0" + hour; }
        if (hour < 1) { hour = "00"; }
        if (min < 10) { min = "0" + min; }
        if (min < 1) { min = "00"; }
        if (sec < 10) { sec = "0" + sec; }
        if (sec < 1) { sec = "00"; }

        return  month.toString() + day.toString() + year.toString() + hour.toString() + min.toString() + sec.toString();
    },
	reset() {
		this.searchKey = this.searchKey + 1;
	},
	previous_note_section(noteId){

		axios
			.post("clinical-note-templates/previousNotes", {
				note_id: noteId,
				patient_id: this.$store.state.patientDetail.patient_id,
				appt_id: this.appointment_id
			})
			.then((response) => {
				this.previous_notes = response.data.data
			});
	},
	getPreviousNoteData($event){
		this.$store.state.loader = true;
		var notedId = $event.target.value;

		if(notedId!=''){
			var dataPreviousIndex = $($event.srcElement).attr("data-previous-index");
			var dataPreviousIndexOr = dataPreviousIndex-1;
			var $this = this;
			axios
				.post("clinical-note-templates/previousNotesData", {
					note_id: notedId				
				})
				.then((response) => {
					var object = {};
					var keyNm = Object.keys($this.data[dataPreviousIndexOr]);
					object[dataPreviousIndexOr] = response.data.data.note_field_json;
					$this.data[dataPreviousIndexOr][keyNm] = response.data.data.note_field_json;
					$this.createForm(dataPreviousIndexOr);
					$this.$store.state.loader = false;
				});			
		}
		
	},
	closemodal()
	{
		$("#followUp_Appointment_modal").modal("hide");
	},
	syncSelection(event)
	{
		let $this = this;
		$this.newfollowUpApptDate = event.value;
		$this.slot_suggestion_time_manual = moment($this.newfollowUpApptDate).format('YYYY-MM-DD hh:mm a');
	},
	syncDate(event)
	{
		let $this = this;
		$this.newfollowUpApptDate = moment($this.followUpApptDate).format("MM/DD/yyyy")+' '+event.target.value;		
		$this.slot_suggestion_time_manual = moment($this.newfollowUpApptDate).format('YYYY-MM-DD hh:mm a');
	},
	save_schedule(){
		let $this = this;
		
		$("#displayavailableslots").modal("hide");

		axios
			.post("appointment/followup", {
				appointment_id: this.appointment_id,
				followUpApptDate: moment(new Date(this.newfollowUpApptDate)).format("YYYY-MM-DD HH:mm:ss"),
				note_id: $(".activeTab").attr("data-note-id")
			})
			.then((response) => {
				this.$swal
					.fire("Success!", "Followup Appointment set successfully", "success")
					.then((result) => {
						$(".book_appt").addClass("d-none");
						$("<label class='followup_appointment_section' style='color: #0265a0;'>Followup Appointment is scheduled on "+moment(String(this.newfollowUpApptDate)).format('MM/DD/YYYY hh:mm A')+"</label>").insertAfter(".book_appt");					
					});
			});
	},
	checkAvailability()
	{
		var dt = $("#followup_schedule").val(); 
		dt = moment(String(dt)).format('YYYY-MM-DD HH:mm:ss');
		$("#followUp_Appointment_modal").modal("hide");

		var $this = this;
		axios.post("/appointment/check-schedule",{
			app_provider_id : this.provider_id,
			StartTime: dt,
			duration: this.appointment_duration,
			Id: this.appointment_id,
		})
		.then((response) => {
			$this.save_schedule();
		})
		.catch((error) => {
			axios.post("/appointment/suggestion-schedule",{
				app_provider_id : this.provider_id,
				StartTime: dt,
				duration: this.appointment_duration,
			})
			.then((response) => {
				$this.slot_suggestion_list = response.data.data;
				$("#displayavailableslots").modal("show");
			})
			.catch((error) => {
				
			});
		});
	},
	senddocument(id, type){
		this.$store.state.loader = true;
		/*Send Document to patient portal*/
		axios.post('patient-portal/clinicalDocuments',{type:type,id:id})
			.then(response => {
				this.$store.state.loader = false;
				var docType = (type == 'clinical_notes') ? 'Clinical Note' : 'Amendments';
				this.$swal
					.fire("Success!", docType+" Sent successfully to the patient.", "success");					
			});
	},
	toggleSidebar(hide='')
	{
		if(hide==1)
		{
			$("#noteSidebar").removeClass("control-sidebar-open");            
		}
		else if($("#noteSidebar").hasClass("control-sidebar-open"))
		{
			$("#noteSidebar").removeClass("control-sidebar-open");            
		}
		else
		{
			$("#noteSidebar").addClass("control-sidebar-open");
		}
	},
	handleScroll (event)
	{
		let $window = $(window);
		if($("#noteSection").length>0)
		{			
			let offsetTop = $("#noteSection").offset().top;
			if($window.scrollTop()>(offsetTop+50))
			{
				$("#noteSidebar").removeClass("scroll-top");
			}
			else
			{
				$("#noteSidebar").addClass("scroll-top");
			}

			/*Scroll Save Button*/
			if($("#accordion2").children().children('.show').hasClass("show")===true)
			{
				var _actHeight = $("#accordion2").children().children('.show').height(),_actOffstop = $("#accordion2").children().children('.show').offset().top;

				if(event.target.documentElement.scrollTop>=_actOffstop && event.target.documentElement.scrollTop<=_actHeight)
				{
					$("#accordion2").children().children('.show').prev().addClass('stickyPan');
					$("#accordion2").children().children('.show').find(".submit-btn").addClass('stickyBtn');
				}
				else if(event.target.documentElement.scrollTop>=_actHeight)
				{
					$("#accordion2").children().children('.show').find(".submit-btn").removeClass('stickyBtn');
				}
				else
				{
					$("#accordion2").children().children('.show').prev().removeClass('stickyPan');
					//$("#accordion2").children().children('.show').find(".submit-btn").removeClass('stickyBtn');
				}
			}
			else
			{
				$("#accordion2").children().children().prev().removeClass('stickyPan');
				//$("#accordion2").children().children().find(".submit-btn").removeClass('stickyBtn');
			}
			/*Scroll Save Button -- End*/
		}
	},
	nl2br (str, is_xhtml) {   
		var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';    
		return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1'+ breakTag +'$2');
	}, 
	macroGenerateFunction()
	{
		let self = this;
		$(document).on("click",".toggle-macro", function(){

			if($(".macros").hasClass("d-none") && $(this).hasClass("fa-toggle-down"))
			{
				$(".toggle-macro").removeClass("fa-toggle-up").addClass("fa-toggle-down");
				if($(this).parent().next("textarea").length > 0){
					var textArea = $(this).parent().next("textarea")
				}else{
					var textArea = $(this).parent().next();
				}
				$(".macros").addClass("d-none");
				setTimeout(function(){
					textArea.next("div.macros").attr("id","macros-"+textArea.attr("id"));
					if(self.isMacroCallInprogress==false){
						self.macros("macros-"+textArea.attr("id"));
					}
					textArea.next("div.macros").removeClass("d-none");                
				}, 100);	
				$(this).removeClass("fa-toggle-down").addClass("fa-toggle-up");
			}
			else
			{
				$("div.macros").addClass("d-none");
				$(this).removeClass("fa-toggle-up").addClass("fa-toggle-down");
			}
		});
		
	},
	macros(_fieldId)
	{

		this.isMacroCallInprogress = true;
		var $this = this;
		var a = typeof this.$store.state.clinicalNote.macros;			
		axios
			.post('macros-list',{'appointment_time': this.schedule_time,'appointment_date': moment(new Date(this.schedule_date)).format("MM/DD/YYYY") , 'patient_name': this.$store.state.patientDetail.first_name+' '+this.$store.state.patientDetail.last_name})
			.then((response) => {
				$this.isMacroCallInprogress = false;
				(this.$store.state.clinicalNote.macros = response.data.data.map((macros) => {
					return { macro_title: macros.macro_title, macro_text: macros.macro_text };
				}))
				this.axiosChange = _fieldId;
		});
	},
	macrosetup(_fieldId)
	{
		
		var html = "", $this = this;

		$.each(this.$store.state.clinicalNote.macros,function(index,value){
			html += "<a href='javascript:void(0)' class='badge badge-dark py-1 copy-content m-1' data-id='"+index+"'>"+$this.nl2br(value.macro_title, 1)+"</a>";
		});

		$("div[id='"+_fieldId+"']").find(".panel-body").html(html);
		$(document).on("click",".copy-content",function(){

			_fieldId = $this.axiosChange;
			var index = $(this).parents("div.panel-collapse.collapse.show").attr("aria-labelledby");
			var macroIndex = $(this).attr("data-id");
			
			if($("div[id='"+_fieldId+"']").hasClass("d-none") === false)
			{
				var eleId = $("div[id='"+_fieldId+"']").prev().attr("id"), 
				form = $this.formDataToStore[index], 
				compIdDetail = eleId.split("-"), 
				compId = compIdDetail[0];
				$("textarea[id='"+compId+"']").val($("div[id='"+_fieldId+"']").prev().val()+' '+$this.$store.state.clinicalNote.macros[macroIndex].macro_text);
				$("textarea[id='"+compId+"']").trigger("change");
				
				let macroObj = ($("div[id='"+_fieldId+"']").prev().val()+' '+$this.$store.state.clinicalNote.macros[macroIndex].macro_text).split('\n');
				
				macroObj.forEach(element => {
					if(element == ''){
						$("div[id='"+compId+"'] .ql-editor[contenteditable=true]").append('<br>');
					}else{
						$("div[id='"+compId+"'] .ql-editor[contenteditable=true]").append(element);
					}
				});
				
				//testMac();
				compId = compId.replace("data[", "");
				compId = compId.replace("]", "");

				// form.submission.data[compId] = $("div[id='"+_fieldId+"']").prev().val()+' '+$this.$store.state.clinicalNote.macros[macroIndex].macro_text;
				form.submission.data[compId] = $("div[id='"+_fieldId+"']").prev().val();

				var finalData = {};
				finalData['fieldjsonData'] = [];
				finalData['fieldjsonData'].push(form.submission.data);
				let templateData = JSON.parse(localStorage.getItem('answer-'+index));
				finalData['template_detail_data'] = templateData;
				localStorage.setItem('noteData', JSON.stringify(finalData));
				
				$(".toggle-macro").removeClass("fa-toggle-up").addClass("fa-toggle-down");

				$this.dataAutoSaveTrigger = true;
			}
			$(".macros").addClass("d-none");
			
		});
	}, 
	stickButton(id)
	{
		if(!$("#"+id).hasClass("collapsed"))
		{            
			$("#accordion2").children().children('.show').find(".submit-btn").addClass('stickyBtn');
		}
	},
	getAppointments()
	{
		var $this = this;
		axios.post('/clinical-note-template/appointments',{patient_id:$this.$store.state.patientDetail.patient_id}).then((response) => {
			$this.$store.state.loader = false;
			$this.appointmentData = response.data.data.system_appointments; 
			$this.importedAppointmentData = response.data.data.imported_appointments;           

			/*Hash setup*/
			var hash = location.href.substring(location.href.lastIndexOf('/')+2);
			$.each($this.appointmentData, function(index, value){
				if(value.appointment_number == hash)
				{
					$this.index = index;
				}
			});
			/*Hash setup - End*/
			if(response.data.data.system_appointments.length>0)
			{
				this.appointment_id = (this.appointment_id==null || this.appointment_id=='') ? response.data.data.system_appointments[this.index].id : this.appointment_id;

				this.schedule_date = (this.schedule_date==null || this.schedule_date=='') ? response.data.data.system_appointments[this.index].schedule_date : this.schedule_date;

				this.schedule_time = (this.schedule_time==null || this.schedule_time=='') ? response.data.data.system_appointments[this.index].schedule_time : this.schedule_time;

				this.categoriesTemplates(response.data.data.system_appointments[this.index].id, response.data.data.system_appointments[this.index].name,"preview");

				this.getAppointmentwiseNotes(response.data.data.system_appointments[this.index].lockedByProvider,response.data.data.system_appointments[this.index].duration,response.data.data.system_appointments[this.index].appDate,response.data.data.system_appointments[this.index].appointment_number,response.data.data.system_appointments[this.index].id, response.data.data.system_appointments[this.index].name, response.data.data.system_appointments[this.index].note_status, response.data.data.system_appointments[this.index].is_supervisor_sign_required, response.data.data.system_appointments[this.index].provider_id, response.data.data.system_appointments[this.index].supervisor_id, response.data.data.system_appointments[this.index].masterId, response.data.data.system_appointments[this.index].note_id);
				setTimeout(function(){
					$("div#control-sidebar-notes").children().children(":nth-child("+($this.index+1)+")").addClass("opened_notes");
				}, 200);
			}
		})  
	},
	getAppointmentwiseNotes(lockedByProvider, duration,AppDate,AppNumber, AppId, name, noteStatus,is_supervisor_sign_required, provider_id, supervisor_id, masterId, note_id, $event='', $note_preview_mode=0, $is_template_show = '')
	{
		if(moment(this.$filters.displayDateTimeLocal(AppDate,'',false,"ddd, MM-DD-YYYY",true)).unix() > moment(this.$filters.displayDateTimeLocal(new Date(),'',false,"ddd, MM-DD-YYYY",true)).unix()){
			this.$swal
				.fire("Warning!", "You are writing the clinical note for a future appointment", "warning");
		}
		this.$store.state.loader = true;
		this.url = '';
		$("#categoriesTemplate").children().removeClass('activeTab');
		
		let $this = this;
		$this.is_template_show = $is_template_show;
		

		/*push history*/
		if(this.isHashActive) {
			this.hash = AppNumber;
			var parts = location.href.split("/"),last_part = parts[parts.length-1];
			var newUrl = (last_part == 'clinical-notes') ? location.href : location.href.substring(0, location.href.lastIndexOf('/'));
			history.pushState(null, '',newUrl+"/#"+this.hash);
		}
		/*push history - End*/


		if($event && $event!='')
		{
			$($event.target).parent().parent().parent().siblings().removeClass("opened_notes");
			$($event.target).parent().parent().parent().addClass("opened_notes");            
		}
		this.appointment_duration = duration;
		this.appointment_date = AppDate;
		this.appointment_id = AppId;
		this.data = {};
		this.provider_id = provider_id;
		this.supervisor_id = supervisor_id;
		this.masterId = masterId;
		this.id = note_id;
		this.note_status = noteStatus;
		this.lockedByProvider = lockedByProvider;

		this.categoriesTemplates(AppId, name, $this.is_template_show, $note_preview_mode);

		$this.notePreview = (this.masterId!=null) ? 1 : 0;
		
		/*Sign and lock button Rights setup*/
			switch(noteStatus)
			{
				case 'provider_signed':
					$this.note_msg = (is_supervisor_sign_required == '1') ? '<span class="bg-white py-1 px-2 rounded5 text-warning d-inline-block fw-500 fs-16">Signed by Rendering Provider. Awaiting Supervisor Provider to sign and lock.</span>' : '';
					$this.dataSave = true;
					$this.sign_lock_label = 'Sign and Lock';
					$this.note_lock_status = false;
					$this.note_sign_and_lock_rights = ($this.user.user_id == supervisor_id || this.permissioncheck(this.$store.state.permission.Sign_Lock_Clinical_Notes) || (this.permissioncheck(this.$store.state.permission.Sign_Lock_Clinical_Notes) && $this.user.user_id!=this.provider_id))  ? 1 : 0;
					break;      
				case 'locked':
					$this.note_lock_status = true;
					$this.dataSave = false;
					$this.note_sign_and_lock_rights = 0;
					$this.note_unlock_rights = (this.permissioncheck(this.$store.state.permission.Sign_Lock_Clinical_Notes) == true || this.user.user_id==this.provider_id || this.user.user_id==this.supervisor_id)  ? 1 : 0;
					$this.note_msg = '';
					break;
				default:
					$this.note_lock_status = false;
					$this.sign_lock_label = (is_supervisor_sign_required == 0 || is_supervisor_sign_required == '0') ? 'Sign and Lock': ($this.user.user_id == supervisor_id) ? 'Sign and Lock' : 'Sign';
					$this.dataSave = true;
					$this.note_msg = '';
					$this.note_sign_and_lock_rights = (this.permissioncheck(this.$store.state.permission.Sign_Lock_Clinical_Notes) == true || this.user.user_id==this.provider_id || this.user.user_id==this.supervisor_id)  ? 1 : 0;
					break;
			}
		/*Sign and lock button Rights setup -- End*/
		/*sign and lock rights*/
			/*if(this.permissioncheck(this.$store.state.permission.Sign_Lock_Clinical_Notes) && noteStatus != 'locked')
			{
				$this.note_sign_and_lock_rights = 1;	
			}
			else if($this.user.user_type == 'staff' || noteStatus == 'locked')
			{
				$this.note_sign_and_lock_rights = 0;
			}
			else if(
				($this.provider_id!='' || $this.supervisor_id!='') 
					&& 
				(
					( 
						noteStatus=='pending' || noteStatus=='' || noteStatus==null || (noteStatus=='provider_signed' && $this.user.user_id == $this.supervisor_id)
					) 
					&& 
					(
						$this.user.user_id == $this.provider_id || $this.user.user_id == $this.supervisor_id
					)
				)
			){
				$this.note_sign_and_lock_rights = 1;
				$this.dataSave = true;
			}
			else
			{   
				$this.note_sign_and_lock_rights = 0;
			}*/
		/*sign and lock rights -- End*/
	},   
	categoriesTemplates(appointment_id, name, is_template_show=1, preview=0){
		this.appointment_id = appointment_id;
		this.AppointmentLabel = name; 

		axios.post('/clinical-note-template/categorisedNote',{'appointment_id': appointment_id}).then((response) => {
			this.is_template_show = is_template_show;
			this.categoriesTemplate = response.data.data
			this.$store.state.loader = false;

			if(preview == 0)
			{
				if($("a.badge-secondary-light").length>0)
				{
					$($("a.badge-secondary-light")).each(function( index ) {
						if(index == 0)
						{
							$(this).trigger("click");
						}
					});				
				}else{
					$($("a.border-secondary")).each(function( index ) {
						if(index == 0)
						{
							$(this).trigger("click");
						}
					});	
				}				
			}

			if($(".badge-secondary-light").attr("data-note-type") == 'Covid')
			{
				this.onloadDataAutosaveTrigger = 1;
				this.dataAutoSaveTrigger = true;
			}
		})  
	},
	noteActivate(e, index){
		var id = this.noteData[index].template_detail_data.id;
		this.is_include = this.is_include+1;
		if(id!==null && this.is_include>2)
		{
			axios
				.post('/clinical-note/is_include',{ patient_id:this.$store.state.patientDetail.patient_id, note_index:id, status: (e===true) ? '1' : '0' })
				.then((response) => {
					
				});             
		}
	},
	async createForm(index) {
		let oriindex = index;
		index = index+1;
		let $this = this;
		let isHashActive = this.isHashActive;


		$(window).on('resize', function(){
			if($('.aggravating-factors').length){
				$.each($('.aggravating-factors'), function(index, item){
					$(this).children('label').css('min-height', $(this).children('div.form-group').height());
				});
			}
		});

		if(document.getElementById("notes"+index)!=null){
			
			await Formio.createForm(
			document.getElementById("notes"+index), this.noteData[index]
			).then(
			function(form) {

				$this.formDataToStore[index] = form;
				/*blocked save note for not authorised person*/
				$this.activate_section = 1;
				
				if($this.noteData[index].template_detail_data.id !== null && typeof index !== undefined)
				{
					var tempId = "include"+index;
					$("input[type=checkbox][name='"+tempId+"']").attr("data-temp-id","toggle"+$this.noteData[index].template_detail_data.template_id);
					$("input[type=checkbox][name='"+tempId+"']").attr("data-id","toggle"+$this.noteData[index].template_detail_data.id);
				}

				/*blocked save note for not authorised person*/
				if($this.noteData[index].template_detail_data.category == 'Covid')
				{
					$this.note_sign_and_lock_rights=1;
					/*if($("button#form"+index+"_submit").length==0)
					{
						$("#notes"+index).append("<button class='btn btn-info submit-btn mt-10 stickyBtn' id='form"+index+"_submit'><i class='fa fa-file fs-14' aria-hidden='true'></i> Save Note</button>");
					}*/
				}
				/*blocked save note for not authorised person - End*/

				if($this.noteData[index].template_detail_data.name == 'Assessment')
				{
					$this.icd10PartVisible = index;
				}

				if($this.noteData[index].template_detail_data.name == 'Assessment and Plans')
				{
					$this.icd10PartVisible = index;
					$this.cptPartVisible = index;

					$('.panel-group-simple .panel-collapse .panel-body').css('padding-left', '15px').css('padding-right', '15px');
				}

				if($('[data-id=sensory_identity]').length){
					$('[data-id=sensory_identity]').parent().parent('td').attr('colspan', '4');
					for (let index = 0; index < 3; index++) {
						$('[data-id=sensory_identity]').parent().parent('td').next('td').remove();
					}
				}

				$("a#title"+index).text($this.noteData[index].template_detail_data.name);
				let formName = 'form'+$this.noteData[index].template_detail_data.template_id;

				/*tele health covid note specific*/
				$(".patient_part").addClass("hide");
				/*tele health covid note specific -- End*/

				setTimeout(() => {
					if($('.aggravating-factors').length){
						$.each($('.aggravating-factors'), function(index, item){
							$(this).children('label').css('min-height', $(this).children('div.form-group').height());
							console.log('timeout code run successfully');
						});
					}
				}, 5000);

				if($('.formio-component-thoracictendernessnotedlevelst').length){
					$('.formio-component-thoracictendernessnotedlevelst').children('label').css('width', '32%');
					$('.formio-component-thoracictendernessnotedlevelst').children('input').css('width', '65%');
				}
				
				form.submission = {
					data: JSON.parse($this.data[oriindex][formName]),
				};

				form.submission.data.template_detail_data = $this.noteData[index].template_detail_data;				
				form.submission.data.components = $this.noteData[index].components;
				var valArr = {};

				form.on("change", function(submission) {
					localStorage.setItem('answer-'+index, JSON.stringify($this.noteData[index].template_detail_data));
					if(submission.changed !== undefined && submission.changed.component.key == 'followUp'){
						if(submission.changed.value!='' && submission.changed.value!='PRN')
						{
							var followUp = submission.changed.value;
							var followUpArr = followUp.split(' ');
							if(followUpArr[1].toLowerCase() == 'weeks' || followUpArr[1].toLowerCase() == 'week')
							{
								var days=7;
							}
							else if(followUpArr[1].toLowerCase() == 'months' || followUpArr[1].toLowerCase() == 'month')
							{
								var days=30;	
							}
							else if(followUpArr[1].toLowerCase() == 'years' || followUpArr[1].toLowerCase() == 'year')
							{
								var days=365;	
							}

							let num = Number(followUpArr[0]);
							let now = new Date(moment(String($this.appointment_date)).format('YYYY-MM-DD HH:mm:ss'));
							now.setDate(now.getDate() + num * days);
							$this.followUpApptDate = now;
						}

						if(submission.changed.value=='' || submission.changed.value=='PRN')
						{
							$("button.book_appt").remove();	
						}else if($this.noteData[index].template_detail_data.followup_appointment){
							if($this.noteData[index].template_detail_data.followup_appointment!='' && $this.noteData[index].template_detail_data.followup_appointment!=null && $(".followup_appointment_section").length==0)
							{
								$("<label class='followup_appointment_section' style='color: #0265a0;'>Followup Appointment is scheduled on "+moment(String($this.noteData[index].template_detail_data.followup_appointment)).format('MM/DD/YYYY hh:mm A')+"</label>").insertAfter($("select[name='"+submission.changed.instance.info.attr.name+"']").parent().parent());

								$(".book_appt").addClass("d-none");
							}
						}
						else if($(".book_appt").length==0 && submission.changed.value!='')
						{
							$("<button class='btn btn-primary btn-sm book_appt mt-2'>Book an appointment</button>").insertAfter($("select[name='"+submission.changed.instance.info.attr.name+"']").parent().parent());
						}
					}

					if($this.noteData[index]){
						if($(".followup_appointment_section").length==0 && $this.noteData[index].template_detail_data.followup_appointment!='' && $this.noteData[index].template_detail_data.followup_appointment!=null)
						{
							$("<label class='followup_appointment_section' style='color: #0265a0;'>Followup Appointment is scheduled on "+moment(String($this.noteData[index].template_detail_data.followup_appointment)).format('MM/DD/YYYY hh:mm A')+"</label>").insertAfter($("select[name='data[followUp]']").parent().parent());
						}	
											
						var finalData = {};
						form.submission.data.template_detail_data = $this.noteData[index].template_detail_data;
						form.submission.data.components = $this.noteData[index].components;

						finalData['template_detail_data'] = form.submission.data.template_detail_data;
						delete form.submission.data['template_detail_data'];
						delete form.submission.data['components']; 

						/*change json*/   
						var $fieldjsonData = form.submission.data;
						var newArr = new Array(),newIndex;
						$.each($fieldjsonData,function(indexx,eleValue) { 
							if(!$.isNumeric(indexx))
							{
								newIndex = $("div.formio-component-"+indexx).parent().attr("data-index-id");
								newArr.push({[indexx] : eleValue});
							}
						});
						/*change json - end*/
						finalData['fieldjsonData'] = newArr;
						
						if($this.note_sign_and_lock_rights==1){						
							if(submission.changed !== undefined){
								localStorage.setItem('noteData', JSON.stringify(finalData));
							}
							$this.dataAutoSaveTrigger = true;
							if($this.onloadDataAutosaveTrigger == 1)
							{
								$this.dataAutoSave();
							}
							if($("button#form"+index+"_submit").length==0)
							{
								if(isHashActive) {
									$("#notes"+index).append("<button class='btn btn-info submit-btn mt-10 stickyBtn' id='form"+index+"_submit'><i class='fa fa-file fs-14' aria-hidden='true'></i> Save Note</button>");
								}
							}
						}
					}

				});		
			});

			/*custom reindex set*/
			$('div[ref^=column]').each(function(index,element){ $(element).attr("data-index-id",index) });
			/*custom reindex set - end*/
		}
	},
	dataAutoSave()
	{
		if(this.note_sign_and_lock_rights==1)
		{

			if(localStorage.getItem('noteData') && localStorage.getItem('noteData')!='')
			{			
				var finalData = JSON.parse(localStorage.getItem('noteData'));
				var $this = this;
				if($this.dataAutoSaveTrigger == true)
				{
					axios
						.post('/clinical-note-template/save',finalData)
						.then((response) => {
							if(response)
							{
								$this.dataAutoSaveTrigger = false;

								/*Clear local store data*/
								localStorage.removeItem("noteData");
								/*Clear local store data - end*/
								
								var noteTxt = $(".activeTab").text();
								$this.masterId = response.data.data;
								
								/*Note save msg*/
								$("p.autosave_msg").html('<i class="fa fa-spinner fa-spin"></i> '+noteTxt+' note is auto saved!');
								$("p.autosave_msg").removeClass("d-none");
								/*Note save msg -- End*/
								
								/*Note Preview activated*/
								$this.notePreview =1;
								/*Note Preview activated -- End*/

								/*Only for covid note*/
								$this.onloadDataAutosaveTrigger = 0;
								/*Only for covid note -- End*/
								$(".activeTab").html('<i class="fa fa-sticky-note text-warning"></i> <b>'+ $(".activeTab").text()+'</b>');
								
								$(".activeTab").removeClass("border-secondary text-dark b-1")
								$(".activeTab").addClass("badge-secondary-light written-note");

								setTimeout(function(){
									$("p.autosave_msg").addClass("d-none");
								}, 1000);
							}
						});
				}
			}
		}
	},
	async getNote(category='', note='', noteId='') {

		this.is_template_show=2;
		this.$store.state.loader = true;
		let bodyFormData = new FormData();
		bodyFormData.append("category", category);
		bodyFormData.append("note", note);
		bodyFormData.append("noteId", noteId);
		bodyFormData.append("appointment_id", this.appointment_id);
		bodyFormData.append("patient_id", this.patient_id);
		let $this = this;

		await axios.post(
			`/clinical-note-templates`,
			bodyFormData
			).then((response) => {
				$this.is_template_show=1;
				$this.notes = response.data.data.templates.length;

				if(category == 'Covid - Telehealth'){
					this.showClass = 'show';
				}
				
				if(typeof response.data.data.templates.length == undefined || response.data.data.data==0)
				{
					$this.note_msg = '<span class="bg-white py-1 px-2 rounded5 text-warning d-inline-block fw-500 fs-16">No appointment found to write clinical note.</span>';
					return false;
				}
				setTimeout(function(){
					$this.getNoteDetail(response.data.data.templates, response.data.data.iv_formulations);
				}, 1000);
				this.$store.state.loader = false;
			});
	},
	async getNoteDetail(redata, iv_formulations){
		let $this = this;
		$this.data = [];
		$.each(redata,function(index,item) {
			let componentsData = JSON.parse(
				item.template_json
				).components;

			$this.provider_id_for_sign = item.provider_id_for_sign;
			$this.supervisor_id_for_sign = (item.supervisor_id_for_sign == 0 || item.supervisor_id_for_sign == '0') ? '' : item.supervisor_id_for_sign;
			$this.noteData.push({
				template_detail_data : {
					'followup_appointment': item.followup_appointment,
					'id': item.noteId,
					'masterId' : item.note_unique_id,
					'note_status' : item.noteStatus,
					'template_id' : item.id,
					'name' : item.name,
					'category' : item.category,
					'patient_id' : $this.patient_id,
					'appointment_id' : $this.appointment_id,
					'is_included' : item.is_included,
					'patient_clinical_note_version' : (item.version ==  null) ? 1 : item.version,
				},
				components : componentsData,  
			});

			/* Triggering all the radio to check "yes" option for Preventive Care note - Ketan */
			setTimeout(() => {
				/* Triggering all the radio buttons to get round shape */
				$('input[type=radio]').css('border-radius', '50%');
				
				if(item.name == "Preventive Care"){
					if(item.note_field_json === undefined || item.note_field_json == "" || item.note_field_json == null){
						/* It should not trigger yes option to be checked if clinical note is already added/updated as this process will be done for new clinical note */
						console.log('here we are');
						$('.radio-button-yes').each(function(key,val){
							$(this).find('input[type=checkbox]').trigger('click');
						})
					}

					/* Replacing dynamic values for age and gender for assessment */
					let t = $('.assessment-class').html().replace('##age##', $this.$store.state.patientDetail.age).replace('##gender##',$this.$store.state.patientDetail.gender);
					$('.assessment-class').html(t);
					$('input[name="data[assessment]"]').val(t);

					$('.pmhx-class').change();

					let tempCount = 0;
					$('.formio-wysiwyg-editor').each(function(key,val){
						tempCount++;
						$(this).attr('id', 'wysiwyg_editor_'+tempCount);
						$(this).prev('.ql-toolbar').remove();
					});
				}
			}, 4000);

			let arr = item.note_field_json;
			let arrKey = 'form'+item.id;
			var object = {};
			object[arrKey] = arr;

			$this.data.push(object);
			$this.createForm(index);
		});
		/*Macro content setup*/

		setTimeout(function(){
			$.each($('#category-1 textarea'), function(){
				if($(this).siblings("i.toggle-macro").length == 0){
					$(this).prev().append('<i class="fa fa-toggle-down toggle-macro ms-1 cursor-pointer" title="toggle macros" style="color:#00abeb; position: relative;"></i>');
				}
			});
			
			$.each($('#category-1 .formio-wysiwyg-editor'), function(){
				if($(this).siblings("i.toggle-macro").length == 0){
					$(this).siblings('label').append('<i class="fa fa-toggle-down toggle-macro ms-1 cursor-pointer" title="toggle macros" style="color:#00abeb; position: relative;"></i>');
				}
			});

			// $('#category-1 textarea').prev().append('<i class="fa fa-toggle-down toggle-macro ms-1 cursor-pointer" title="toggle macros" style="color:#00abeb; position: relative;"></i>');

			$( '<div class="d-none macros panel panel-default macros-container p-0 box-bordered position-absolute"><div class="panel-heading bg-light text-dark">Macros</div><div class="panel-body bg-white"></div></div>' ).insertAfter( "textarea" );

			$( '<div class="d-none macros panel panel-default macros-container p-0 box-bordered position-absolute"><div class="panel-heading bg-light text-dark">Macros</div><div class="panel-body bg-white"></div></div>' ).insertAfter('.formio-wysiwyg-editor');

			$('textarea').each(function(i, obj) {
			    if(typeof $(obj).attr("name") !== "undefined"){
			    	$(this).attr("id", $(obj).attr("name"));
			    }
			});
			if(iv_formulations!=''){
				var iv_formulations_list = iv_formulations;
				$.each($(".ivformulations"), function(i,e){
				    if(iv_formulations_list!==null){
					    if(iv_formulations_list.indexOf($(e).children().children("span").text()) != -1){
					      $(e).children().children("input").prop("checked",true)  ;
					    }				    	
				    }
				});				
			}
		
			$.each($(".dynamicContent"), function(i, e){
				var dynamicContent = $(this).text().replace("###patient_name###", $this.$store.state.patientDetail.first_name+' '+$this.$store.state.patientDetail.last_name).replace("###age###", $this.$store.state.patientDetail.age).replace("###gender###", $this.$store.state.patientDetail.gender);
				$(this).html(dynamicContent);
			});
		}, 100);

		// $('.cls-speech-to-text-icon').find('label').append('<i class="fa fa-microphone ms-1 cursor-pointer" @click="speech_to_text" title="Start speech to text" style="color:#00abeb"></i>');

		//$('#noteSection textarea').parent().prev().append('<i class="fa fa-microphone ms-1 cursor-pointer toggle-speech-to-text" title="Start speech to text" style="color:#00babe"></i>');

		/*$('.toggle-speech-to-text').click(function(){
			if($(this).hasClass('fa-stop-circle')) {
				console.log("Stop");
				micStream1.stop();

                if (socket.readyState === socket.OPEN) {
                    let emptyMessage = getAudioEventMessage(Buffer.from(new Buffer([])));
                    let emptyBuffer = eventStreamMarshaller.marshall(emptyMessage);
                    socket.send(emptyBuffer);
                }
				$(this).addClass('fa-microphone').removeClass('fa-stop-circle').css("color", "#00babe");
			} else {
				$('.toggle-speech-to-text').each(function(i, obj) {
					if($(obj).hasClass('fa-stop-circle')) {
						$(obj).addClass('fa-microphone').removeClass('fa-stop-circle').css("color", "#00babe");
					}
				});
				var textAreaId = $(this).parent().parent().find('textarea').attr("id");
				if($(this).hasClass('fa-microphone')) {				
					// $("#"+textAreaId).val("Started One");
					$(this).removeClass('fa-microphone').addClass('fa-stop-circle').css("color", "#ff0000");

					textAreaData = $("#"+textAreaId).val();
					transcription = textAreaData;
					micStream1 = new MicrophoneStream();
					eventStreamMarshaller = new EventStreamMarshaller(toUtf8, fromUtf8);
				
					window.navigator.mediaDevices.getUserMedia({ video: false, audio: true })
					.then(function(stream) {
						micStream1.setStream(stream);
					}).catch(function(error) {
						console.log(error);
					});

					micStream1.on('format', function(format) {
						inputSampleRate = format.sampleRate;
					});

					var url = createPresignedUrl();
					socket = new WebSocket(url);
					socket.binaryType = "arraybuffer";
					socket.onopen = function () { 
						micStream1.on('data', function(chunk) {
							var binary = convertAudioToBinaryMessage(chunk);
							if (socket.readyState === socket.OPEN) socket.send(binary);
						});
					};

					socket.onmessage = function (message) { 
						var messageWrapper = getEventUnMarshall(message.data);
						var messageBody = JSON.parse(String.fromCharCode.apply(String, messageWrapper.body));

						if (messageWrapper.headers[":message-type"].value === "event") {
							handleEventStreamMessage(messageBody, textAreaId, $this);
						} else {
							// transcribeException = true;
							// showError(messageBody.Message);
							// toggleStartStop();
						}
					}
				}
			}
		});*/



		setTimeout(function () {
			$this.shortCodes();
		}, 500);
		$($("input[type=checkbox]")).each(function( index ) {
			$(this).change(function(){
				setTimeout(function () {
					$this.shortCodes();
				}, 500);
			})
		});

		/*Macro content setup -- End*/
	},
	putMacroIcon(iv_formulations){
		$.each($('#category-1 .formio-wysiwyg-editor'), function(){
			if($(this).prev('label').find("i.toggle-macro").length == 0){
				$(this).prev().append('<i class="fa fa-toggle-down toggle-macro ms-1 cursor-pointer" title="toggle macros" style="color:#00abeb; position: relative;"></i>');
			}
		});

		$( '<div class="d-none macros panel panel-default macros-container p-0 box-bordered position-absolute"><div class="panel-heading bg-light text-dark">Macros</div><div class="panel-body bg-white"></div></div>' ).insertAfter('.formio-wysiwyg-editor');

		if(iv_formulations!=''){
			var iv_formulations_list = iv_formulations;
			$.each($(".ivformulations"), function(i,e){
				if(iv_formulations_list!==null){
					if(iv_formulations_list.indexOf($(e).children().children("span").text()) != -1){
						$(e).children().children("input").prop("checked",true)  ;
					}				    	
				}
			});				
		}
	
		$.each($(".dynamicContent"), function(i, e){
			var dynamicContent = $(this).text().replace("###patient_name###", $this.$store.state.patientDetail.first_name+' '+$this.$store.state.patientDetail.last_name).replace("###age###", $this.$store.state.patientDetail.age).replace("###gender###", $this.$store.state.patientDetail.gender);
			$(this).html(dynamicContent);
		});
	},
	shotCodeGetData(){
		axios.post("/short-code", {patient_id: this.patient_id, appointment_id: this.appointment_id}).then((response) => {
			if(response.data.data.medical_history_data!=''){
				this.$store.state.clinicalNote.medical_history_data = response.data.data.medical_history_data;
			}else{
				this.$store.state.clinicalNote.medical_history_data = "<p class='mt-5'>No Medical History Found</p>";
			}
			if(response.data.data.medication_data!=''){
				this.$store.state.clinicalNote.medication_data = response.data.data.medication_data;
			}else{
				this.$store.state.clinicalNote.medication_data = "<p class='mt-5'>No Current Medication Found</p>";
			}
			if(response.data.data.allergy_data!==''){
				this.$store.state.clinicalNote.allergy_data = response.data.data.allergy_data;
			}else{
				this.$store.state.clinicalNote.allergy_data = "<p class='mt-5'>No Active Allergy Found</p>";
			}
			if(response.data.data.iv_formulation_data!==''){
				this.$store.state.clinicalNote.iv_formulation_data = response.data.data.iv_formulation_data;
			}else{
				this.$store.state.clinicalNote.iv_formulation_data = "<p class='mt-5'>No IV formulations found</p>";
			}
			this.shortCodes();
		});
	},
	shortCodes(){		
		$($('p:contains("###Medical History###")')).html(this.$store.state.clinicalNote.medical_history_data);
		
		$($('p:contains("###Medication_manage_links###")')).html('<div class="text-end"><a href="javascript:void(0)" class="fetch_medication waves-effect waves-light btn btn-info-light me-2" title="Click here to fetch medication"><i class="fa fa-refresh"></i> Fetch Medication</a>  <a href="javascript:void(0)" class="add_medication waves-effect waves-light btn btn-info-light me-2" title="Click here to add medication"><i class="fa fa-plus fs-14"></i> Medication</a></div>');
		
		$($('p:contains("###Medication###")')).html(this.$store.state.clinicalNote.medication_data);	

		$($('p:contains("###Allergies_manage_links###")')).html('<div class="text-end"><a href="javascript:void(0)" class="fetch_allergy waves-effect waves-light btn btn-info-light me-2" title="Click here to fetch allergy"><i class="fa fa-refresh"></i> Fetch Allergy</a> <a href="javascript:void(0)" class="add_allergy waves-effect waves-light btn btn-info-light me-2" title="Click here to add allergy"><i class="fa fa-plus fs-14"></i> Allergy</a></div>');
		
		$($('p:contains("###Allergies###")')).html(this.$store.state.clinicalNote.allergy_data);
		
		$($('p:contains("###IVFormulations###")')).html(this.$store.state.clinicalNote.iv_formulation_data);
	},
	sign_lock($event)
	{
		$($event.target).append('<img src="images/loader.gif" class="ms-1">');
		/*Blank note can't be sign validation msg*/
		var noteId = this.masterId;

		if(noteId=='' || noteId==null)
		{
			this.tempMsg = '<span class="px-2 p-1 bg-warning-light text-dark border-warning d-inline-block rounded b-1 fs-14 l-h-n">Clinical note is empty, you can\'t sign it.</span>';
			$($event.target).html('<i class="fa fa-lock"></i> Sign and Lock');
			return 0;
		}
		/*Blank note can't be sign validation msg -- End*/

		/*Supervisor sign validation*/
		/*if((this.supervisor_id == this.user.user_id) && this.note_status != 'provider_signed' && this.supervisor_id!=this.provider_id)
		{
			this.tempMsg = '<span class="px-2 p-1 bg-warning-light text-dark border-warning d-inline-block rounded b-1 fs-14 l-h-n">The rendering provider signature is pending. You can sign and lock the clinical note after rendering provider signs it.</span>';
			return 0;
		}*/
		/*Supervisor sign validation - End*/

		var patient_id = this.patient_id,
		appointment_id = this.appointment_id,
		signed_by = (this.provider_id == this.user.user_id) ? 'provider' : 'supervisor',
		$this = this;

		if(this.sign_lock_label == 'Sign'){
			var msg = 'Do you want to sign this Note?',
			confrmbutnlabel = 'Yes, want to sign!',
			signStatus = 'sign';
		}
		else{
			var msg = 'Do you want to sign and lock this Note?',
			confrmbutnlabel = 'Yes, want to sign and lock this note!',
			signStatus = 'lock';
		}

		if(!this.isHashActive) {
			if(confirm(msg)) {
				axios.post("/clinical-note-template/sign-lock", { noteId: noteId, patient_id: patient_id, patient_unique_id: this.$store.state.patientDetail.patient_unique_id, signStatus: signStatus, appointment_id: appointment_id, appointment_number: this.hash,signed_by : signed_by,supervise_by: this.supervisor_id, provider_by : this.provider_id, noteSaveId: this.$store.state.clinicalNote.clinical_note_id, note_id: this.id}).then((response) => {	
					if(response.data.status == 'success')
					{
						this.$emit("updateKey");
					}
				});
			} else {
				$($event.target).html('<i class="fa fa-lock"></i> Sign and Lock');
			}
		} else {
			this.$swal
				.fire({
				title: '<h2 class="mt-0">Are you sure?</h2>',
				text: msg,
				icon: "warning",
				dangerMode: true,
				showCancelButton: true,
				confirmButtonColor: "#ee3158",
				cancelButtonColor: "#bdbdbd",
				confirmButtonText: confrmbutnlabel,
				})
				.then((result) => {
				if (result.isConfirmed == true) {
					axios.post("/clinical-note-template/sign-lock", { noteId: noteId, patient_id: patient_id, patient_unique_id: this.$store.state.patientDetail.patient_unique_id, signStatus: signStatus, appointment_id: appointment_id, appointment_number: this.hash,signed_by : signed_by,supervise_by: this.supervisor_id, provider_by : this.provider_id, noteSaveId: this.$store.state.clinicalNote.clinical_note_id, note_id: this.id}).then((response) => {
					
						if(response.data.status == 'success')
						{
							this.$swal
								.fire("Success!", "Note status saved.", "success")
								.then((result) => {
									location.reload();
								});
							
							/*remove form and show PDF once locked*/
							/*this.data = {};
							this.is_template_show = 2;
							$("#previewNote").trigger("click");*/
							/*remove form and show PDF once locked -- End*/
						}

						/*Button Loader*/
						/*this.note_msg = '';
						$($event.target).html('<i class="fa fa-lock"></i> Sign and Lock');*/
						/*Button Loader -- End*/
						
						/*If locked then label should be change to lock*/
						/*if(signStatus == 'lock')
						{
							this.note_sign_and_lock_rights=0;
							this.note_lock_status=1;
						}*/
						
						/*If locked then label should be change to lock -- end*/
					});
				}
				else
				{
					/*Button Loader*/
					$($event.target).html('<i class="fa fa-lock"></i> Sign and Lock');
					/*Button Loader -- End*/
				}
				});
		}
		
	},	
	unlock_note(){
		var patient_id = this.patient_id,
		appointment_id = this.appointment_id;
		var $this = this;
		if(!this.isHashActive) {
			if(confirm("Do you want to unlock this note?")) {
				axios.post("/clinical-note-template/unlock", {'patient_id': patient_id, 'appointment_id': appointment_id})
				.then((response) => {
					this.$emit("updateKey");
				});
			}
		} else {
			this.$swal
				.fire({
				title: '<h2 class="mt-0">Are you sure?</h2>',
				text:
					"You want to unlock this note",
				icon: "warning",
				dangerMode: true,
				showCancelButton: true,
				confirmButtonColor: "#ee3158",
				cancelButtonColor: "#bdbdbd",
				confirmButtonText: "Yes, unlock please!",
				})
				.then((result) => {
				if (result.isConfirmed == true) {
						axios.post("/clinical-note-template/unlock", {'patient_id': patient_id, 'appointment_id': appointment_id})
							.then((response) => {
								$this.$swal
									.fire("Success!", "Note unlocked successfully.", "success")
									.then((result) => {
										location.reload();
									});
							});
				}
			});
		}
	},	
	_UIdentiTy(){
		return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
			(c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
		  );
	},
	PreviewNote(AppId,name,masterId, $event='', is_imported=0, duration='',appDate='', appointment_number='',note_status='', is_supervisor_sign_required='', provider_id='', supervisor_id='', note_id='', lockedByProvider='')
	{  
		this.is_template_show = 0;
		
		$("#categoriesTemplate").children().removeClass('activeTab');
		this.appointment_id = AppId;
		this.data = {};
		this.$store.state.loader = true;
		
		/*if(is_imported==0)
		{
			//For imported Notes prevent note tags
			this.categoriesTemplates(AppId, name, this.is_template_show, 1);
		}
		else
		{			
			this.categoriesTemplate = [];
		}	*/

		if($event)
		{
			this.dataAutoSaveTrigger = true;
			this.dataAutoSave();
			$($event.target).parent().parent().parent().siblings().removeClass("opened_notes");
			$($event.target).parent().parent().parent().addClass("opened_notes"); 

			if(duration!='' && appDate!='' && appointment_number!='' && note_status!='' && is_supervisor_sign_required!='' &&  provider_id!='' && supervisor_id!='' && note_id!='')
			{
				this.getAppointmentwiseNotes(lockedByProvider,duration,appDate,appointment_number,AppId, name,note_status,is_supervisor_sign_required, provider_id, supervisor_id, masterId, note_id,'', 1, this.is_template_show);
			}
		}	
		
		if(masterId!='')
		{
			this.toggleSidebar(1);
			this.$store.state.clinicalNote.appointment_id = AppId;
			this.$store.state.clinicalNote.clinical_note_id = masterId+"-"+this._UIdentiTy();
			this.browserCheck();
			this.getSrc();
		}
		else
		{
			this.moshaToast(`No Clinical note available`,'info');
		}
	},
	async getSrc() {
		
		const res = await fetch(process.env.VUE_APP_API_BASE_URL+'/'+this.$store.state.patientDetail.patient_unique_id+'/clinical-notes/preview/'+this.$store.state.clinicalNote.clinical_note_id, {
			method: 'GET',
			headers: {
			  'Authorization' : 'Bearer '+localStorage.getItem('authToken')
			}
		});

		if(res.status == 200)
		{
			const blob = await res.blob();
			const urlObject = URL.createObjectURL(blob);
			this.url = urlObject;
			$("p.msg").addClass("d-none");
			this.$store.state.loader = false;
		}
		else
		{
			this.embed = null;
			$("p.msg").text("Sorry! Please try again.");
			this.$store.state.loader = false;
		}
	},   
	browserCheck(){
		// Opera 8.0+
		var isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

		// Firefox 1.0+
		var isFirefox = typeof InstallTrigger !== 'undefined';

		// Safari 3.0+ "[object HTMLElementConstructor]" 
		var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));

		// Internet Explorer 6-11
		var isIE = /*@cc_on!@*/false || !!document.documentMode;

		// Edge 20+
		var isEdge = !isIE && !!window.StyleMedia;

		// Chrome 1 - 79
		var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

		// Edge (based on chromium) detection
		var isEdgeChromium = isChrome && (navigator.userAgent.indexOf("Edg") != -1);

		// Blink engine detection
		var isBlink = (isChrome || isOpera) && !!window.CSS;

		if(isChrome==true)
		{
			this.embed = 0;
		}else{
			this.embed = 1;
		}

	},
	getAmendments(AppNumber, AppId, name, masterId, $event){
		$("#categoriesTemplate").children().removeClass('activeTab');
		/*push history*/
		if(this.isHashActive) {
			this.hash = AppNumber;
			var parts = location.href.split("/"),last_part = parts[parts.length-1];
			var newUrl = (last_part == 'clinical-notes') ? location.href : location.href.substring(0, location.href.lastIndexOf('/'));
			history.pushState(null, '',newUrl+"/#"+this.hash);
		}
		/*push history - End*/

		if($event)
		{
			$($event.target).parent().parent().parent().parent().siblings().removeClass("opened_notes");
			$($event.target).parent().parent().parent().parent().addClass("opened_notes");            
		}
		this.dynamicClass = "d-block";
		this.appointment_id = AppId;
		this.data = {};
		this.is_template_show = 3;
		this.amendment_reload++;
		this.categoriesTemplates(AppId, name, this.is_template_show, 1);
		this.toggleSidebar(1);
	},
	FileDisplay(fileName, e){
		 $('<img src="images/loader.gif" class="ms-1">').insertAfter(e.target);
		 axios
		   .get(process.env.VUE_APP_API_BASE_URL+'/note-amendments/'+fileName)
		   .then((res) => {
			   e.target.nextElementSibling.remove();
			   window.open(res.data,'_blank');
		 });
	},
	capitalizeFirstLetter(string) {
		return string.charAt(0).toUpperCase() + string.slice(1);
	},
	save(next) {
		this.form.appointment_id = this.appointment_id;
		if(this.amendment_edit == true)
		{
			this.postForm(`/patient/${this.patientid}/amendments-document/update`,"Amendments",true,'formdata');
		}
		else
		{
			this.postForm(`/patient/${this.patientid}/amendments-document/store`,"Amendments",true,'formdata');
		}
		
	},
	formPostSuccess(data) {
		if(this.amendment_edit == true)
		{
			this.moshaToast('Record updated successfully.','success');
			this.amendment_edit = false;
		}
		else
		{
			this.moshaToast('Record added successfully.','success');
		}
		this.resetForm();
		this.amendment_reload++;
	},
	edit(amendId){
		this.amendment_edit = true;
		if (this.$store.state.Amendments.form.id != this.patientid) {
			this.$store.dispatch("getEditDataForMultipalId", {
			   dataType: "Amendments", // pass the module name
			   formType: "form", // pass the propery name
			   id: this.patientid,
			   id2: amendId,
			});
			if(this.form.processed_date != null){
			   this.processed_date = moment(String(this.form.processed_date)).format('MM-DD-YYYY');
			}
			if(this.form.request_date != null){
			   this.request_date = moment(String(this.form.request_date)).format('MM-DD-YYYY');
			}
		}
	},
	resetForm() {
		var self = this;
		this.dynamicClass="hide";
		console.log(this.dynamicClass);
		  
		Object.keys(this.form).forEach(function(key,index) {
			self.form[key] = '';
		});
		this.form.comments = '';
		this.v$.$reset();
		$("textarea[name=textarea]").val("");
		$("#request_date").val("");
		$("#processed_date").val("");
		// window.location.reload();
	},
	getdate($event){
		if($event == 'change')
		{
			 if($('#request_date').val() == '' && this.request_date==''){
				this.form.request_date = null;
			 }else{
				this.request_date = $('#request_date').val();
				this.form.request_date = moment(String(this.request_date)).format('YYYY-MM-DD');
			 }
		}
	},
	getprocesseddate($event){
		if($event == 'change')
		{
			if($('#processed_date').val() == ''){
				this.form.processed_date = null;
			}else{
				this.processed_date = $('#processed_date').val();
				this.form.processed_date = moment(String(this.processed_date)).format('YYYY-MM-DD');
			}
		}
	},
	sendnots(id){
		axios.post('patient-portal/clinicalDocuments',{type:'clinical_note',id:id})
		 .then(response => {
			this.moshaToast('Record Updated successfully.','success');
		  });
	},	
},
computed: {
	...mapGetters("auth", ["user"]),
	form() {
		 return this.$store.state.Amendments.form;
	},
	vuelidateExternalResults() {
		 return this.$store.state.Amendments.vuelidateExternalResults;
	},
	validationRules() {
		 return this.$store.state.Amendments.validationRules;
	},
},
validations() {
  return this.$store.state.Amendments.validationRules;
},
watch: {
	is_template_show: function() {

		this.data = {};
		this.noteData = [{
			template_detail_data: [{
				followup_appointment: '',
				id: '',
				masterId : '',
				template_id: '',
				name: '',
				category: '',
				patient_id: '',
				appointment_id: '',
				note_status: '',
				is_included : '',
				patient_clinical_note_version : '',
			}],
			components: [],
		}];
		this.browserCheck();
	},
	axiosChange: function() {
		this.macrosetup(this.axiosChange);
	},
	async currentTime(newValue, oldValue) {
		this.dataAutoSave();
	},
	assessment_pmhx: function(newValue, oldValue){
		/* Showing PMHx details under the assessment section - Starts */
		var html = '<table style="border: 1px solid black; width: 100%;"><thead><tr><th style="padding: 5px; border: 1px solid black;">Code</th><th style="padding: 5px; border: 1px solid black;">Description</th><th style="padding: 5px; border: 1px solid black;">Code</th><th style="padding: 5px; border: 1px solid black;">Description</th><th style="padding: 5px; border: 1px solid black;">Code</th><th style="padding: 5px; border: 1px solid black;">Description</th></tr></thead><tbody>';

		/* Adding extra TD to make table look proper */
		let extraTdCount = 0;
		let lastIndex = newValue.length;
		if((newValue.length % 3) != 0){
			extraTdCount = 3 - (newValue.length % 3);
		}
		
		let count = 0;
		$.each(newValue, function(key, val){
			count++;
			if(count==1){
				html += '<tr>';
			}

			html += '<td style="padding: 5px; border: 1px solid black;">'+ val.code +'</td>';
			html += '<td style="padding: 5px; border: 1px solid black;">'+ val.description +'</td>';
			
			if(key == (lastIndex - 1) && extraTdCount > 0){
				for (let index = 0; index < (extraTdCount*2); index++) {
					html += '<td style="padding: 5px; border: 1px solid black;"></td>';
				}
			}

			if(count==3){
				html += '</tr>';
				count = 0;
			}
		});
		html += '</tbody></table>';

		if(lastIndex > 0){
			$('.assessment-pmhx-display').html(html);
			$('.assessment-phmx').find('textarea').val(html);
		}else{
			$('.assessment-pmhx-display').html('');
			$('.assessment-phmx').find('textarea').val('');
		}
		/* Showing PMHx details under the assessment section - Starts */
	},
	assessment_pmhx_other: function(newValue, oldValue){
		let html = '';
		if(newValue != ''){
			html += '<p>Other: </p>';
			html += newValue;
		}
		$('.assessment-pmhx-display-other').html(html);
		$('.assessment-phmx-other').find('textarea').val(html);
	},
  },
}
</script>

<style src="@vueform/toggle/themes/default.css"></style>
<style>
/*.formio-component-columns{
	padding:10px 5px;
}*/
.card-body{
	padding:20px;
}
.tblClass .col{
	border: 1px solid #d1d3e0;
}
td div.formio-component p {text-align:center}
.strength-table-class td {
	border: 1px solid;
}
.margin-top-100 {
	margin-top: 100px !important;
}
</style>