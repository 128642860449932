<template>
  <div class="content-wrapper">
    <div class="container-full">
      <div class="content-header">
        <div class="row justify-content-between">
          <div class="col-12 col-sm-8 col-md-9 col-lg-6 col-xs-6">
            <h1 class="heading1 mt-0">Archived Leaves </h1>
          </div>
          <div class="col-12 col-sm-4 col-md-3 col-lg-3 col-xs-2 text-sm-end">
            <a href="#/leave/list" class="waves-effect waves-light btn btn-info-light"><i class="fa fa-arrow-left fs-16"></i> Back </a>
          </div>
        </div>
      </div>

      <section class="content">
        <div class="box">
          <div class="box-body">

            <div class="table-responsive">
              <data-table dataType="leaveAllArchived" :dataTypes="{dataType: 'leaveAllArchived', 'method' : 'get'}" :key="table_load_count">
                <template v-slot="slotProps">
                  <!-- slot for table data -->
                  <td>{{ slotProps.item.username }}</td>
                  <td>{{ slotProps.item.from_date }}</td>
                  <td>{{ slotProps.item.to_date }}</td>
                  <td>{{ slotProps.item.Days }}</td>
                  <td> 
                    <span v-if="slotProps.item.status == 'Upcoming Leaves' " class="badge badge-success-light w-130 rounded d-inline-block fs-16">{{ slotProps.item.status }}  </span>  
                    <span v-else-if="slotProps.item.status == 'Completed' " class="badge badge-danger-light rounded d-inline-block fs-16">{{ slotProps.item.status }}</span>
                  </td>
                  <td>
                    <button type="button"  data-on="Ready" data-off="Not Ready" @click="onToggleChange(slotProps.item.id,$event)" :id="['status_' + slotProps.item.id]" class="btn btn-toggle rounded30-im"  v-bind:class="[slotProps.item.deleted_at == NULL ? 'active' : '']" data-bs-toggle="button" aria-pressed="true" autocomplete="off">
                      <div class="handle rounded30-im"></div>
                    </button>
                  </td>
                </template>
              </data-table>
            </div>

          </div>
        </div>
      </section>
    </div>
  </div>

</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
// import 'jquery/dist/jquery.min.js';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import "datatables.net-dt/js/dataTables.dataTables"
// import "datatables.net-dt/css/jquery.dataTables.min.css"
import axios from "@/scripts/axios.js";
import dataTable from "@/components/base/DataTable.vue";
import submitMixins from "@/scripts/mixins/submitMixins.js";

import Autocomplete from 'vue3-autocomplete';
import useVuelidate from "@vuelidate/core";
import TableBase from "@/components/base/Table.vue";
import InputField from "@/components/base/formFields/InputField.vue";
import SelectBoxStatic from "@/components/base/formFields/SelectBoxStatic.vue";
import permissionMixins from "@/scripts/mixins/permission.js";
import moment from 'moment';


export default {

  name: "Leave",
  setup: () => {
    return { v$: useVuelidate() };
  },
  mixins: [submitMixins,permissionMixins],
  components: { Autocomplete, TableBase,InputField, SelectBoxStatic,dataTable },
  data() {
    
    return {
      table_load_count : 0,
      staffList : [],
      staff_provider_id: this.$store.state.leave.user_id,
      date: new Date(),
      strict:true,
      minVal: new Date(),
    }
    
  },
  mounted() {
    this.getUserData();
  },
  computed: {
    form() {
      return this.$store.state.leave.form;
    },
    vuelidateExternalResults() {
      return this.$store.state.leave.vuelidateExternalResults;
    },
    validationRules() {
      return this.$store.state.leave.validationRules;
    },
  },
  validations() {
    return this.$store.state.leave.validationRules;
  },
  methods: {
    getdate(){
      this.form.date_range =  $('#date_range').val();
    },
    getUserData() {
      axios.get("/json_dropdown/user-list", {
      }).then(response => { 
          console.log(response.data.data);
          this.staffList = response.data.data 
      });  
    },
    resetForm() {
       var self = this;
       Object.keys(this.form).forEach(function(key,index) {
         self.form[key] = '';
       });
      this.date = '';
      this.v$.$reset();
    },
    closeDialog() {
        $('#leaveAddEditModal').modal('hide');
        this.resetForm();
     },
    saveLeave() {
      const storeOrUpdate = this.form.id ? 'update' : 'store';
      const url = '/leave/'+storeOrUpdate;
      var daterange = $('#date_range').val();

      var date = daterange.split(' - ');
      this.form.from_date = moment(String(date[0])).format('YYYY-MM-DD');
      this.form.to_date = moment(String(date[1])).format('YYYY-MM-DD');
      this.postForm(url, 'leaveList');

    },
    formPostSuccess(response) {
      const message = this.form.id ? 'Record updated successfully.' : 'Record added successfully.';
      this.moshaToast(message,'success');
    
      $('#leaveAddEditModal').modal('hide');
    
      this.resetForm();
      // this.table_load_count++;
      this.staffList = [];
      setTimeout(function(){ location.reload(); }, 400);
      
    },
    async editLeave(id) {
        if (id) {
            const result =  await this.$store.dispatch("getEditData", {
              dataType: "leave", // pass the module name
              formType: "form", // pass the propery name
              id: id,
            });
           this.getUserData(); 
          $('#leaveAddEditModal').modal('show');
          this.date = this.form.from_date+' - '+this.form.to_date;
        }
   },
    deleteItem(id) {
      this.$swal.fire({
        title: 'Are you sure you want to delete this record?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      })
      .then((result) => {
          if (result.isConfirmed == true) {
              axios.post(`/leave/delete`,{'id':id})
              .then((response) => {
              this.$swal.fire('Deleted!','Leave has been deleted.','success')  
                  .then((result) => { 
                      if(result.isConfirmed == true){
                         location.reload();
                      }
                  });
              })
          }
      });
    },
    onToggleChange(id,event) {
      var class_list = document.getElementById("status_"+id).classList;
      var active_deactive = '';
      if(class_list.contains('active')) {
        active_deactive = 'A';
      } else {
        active_deactive = 'D';
        // console.log(class_list.contains('active'));
      }

      axios.post("leave/reset",{"status":active_deactive,"id":id})
      .then((response) => {
        this.moshaToast('Leave has been restored successfully.','success');
        setTimeout(function(){ location.reload(); }, 3000);
      }); 
    }
  },
};
</script>
