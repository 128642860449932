import {
    helpers,
    required, 
    requiredIf,   
  } from "@vuelidate/validators";
  const BulkAppointments = {
    namespaced: true,  
    state: {  
        form: {},
        validationRules: {
            form: {
                provider_id: {
                    required: helpers.withMessage("Provider is required", required),
                },
                supervisor_id: {
                    required: helpers.withMessage("Supervising Manager is required", requiredIf(function() {
                        return this.$store.state.is_supervisor_required ? true : false;
                    })),
                },
                office_id: {
                    required: helpers.withMessage("Office is required", required),
                },                
                type: {
                    required: helpers.withMessage("Appointment type is required", required),
                },
                patient_id: {
                    required: helpers.withMessage("Patient is required", required),
                },
                schedule_time: {
                    required: helpers.withMessage("Schedule start time is required", required),
                },
                schedule_end_time: {
                    required: helpers.withMessage("Schedule end time is required", required),
                },
                duration: {
                    required: helpers.withMessage("Duration is required", required),
                },
                reason_for_visit: {
                    required: helpers.withMessage("Reason for visit is required", required),
                },
                status: {
                    required: helpers.withMessage("Appointment status is required", required),
                },
            },
        },
        vuelidateExternalResults: {
            form: {
                provider_id:  [], 
                supervisor_id: [], 
                office_id:  [],  
                type:  [],  
                patient_id:  [],  
                schedule_time:  [],  
                schedule_end_time:  [],  
                duration:  [],  
                reason_for_visit:  [],  
                status:  [],                    
            },
        },
    },
    getters: {
        getTableHeaders: (state) => state.header,  
        getTableContents: (state) => state.list,
        getListURL: (state) => state.listUrl,
    },
    mutations: {},
    actions: {},
  };
  
  export default BulkAppointments;
  