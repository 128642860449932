<template>
  <div class="panel pagecollap">
    <div class="panel-heading" id="careteammembers" role="tab">
      <a
        class="panel-title"
        aria-controls="answer-10"
        aria-expanded="false"
        data-bs-toggle="collapse"
        href="#answer-10"
        data-parent="#accordion2"
        >Care Team Members</a
      >
    </div>
    <div
      class="panel-collapse collapse"
      id="answer-10"
      aria-labelledby="careteammembers"
      role="tabpanel"
      data-bs-parent="#category-1"
    >
      <div class="panel-body">
        <div class="table-responsive">
          <table-base
            :dataTypes="{
              dataType: 'CareTeamMembers',
              patient_id: patient_id,
              patient_unique_id: patient_unique_id,
              status: 'Results Received',
              method: 'post',
              title:'No any appointment available.',
            }"
            :lastcol='permissioncheck(this.$store.state.permission.create_update)'
          >
            <template v-slot="slotProps">
              <td>
                {{
                  $filters.displayDateTimeLocal(slotProps.item.appt_scheduled_time)
                }}
              </td>
              <td>{{ slotProps.item.appt_provider }}</td>
              <td>{{ slotProps.item.care_team_members }}</td>
              <td>
                <div class="d-flex">
                  <Popper arrow content="Edit Care Team Members" :hover="true" class="helptips cursor-pointer">
                    <a
                      v-if="
                        permissioncheck(
                          this.$store.state.permission.create_update
                        )
                      "
                      href="#"
                      @click="
                        edit(
                          slotProps.item.id,
                          slotProps.item.care_team_members,
                          slotProps.item.appt_provider,
                          slotProps.item.care_team_provider_ids,
                          slotProps.item.care_team_staff_ids
                        )
                      "
                      class="waves-effect waves-circle btn btn-circle btn-info btn-xs"
                      data-bs-toggle="modal"
                      data-bs-target="#selectmembers"
                      ><i class="fa fa-pencil"></i></a>
                    </Popper>
                </div>
              </td>
            </template>
          </table-base>
        </div>
        <!--modal start-->
        <div
          id="selectmembers"
          class="modal fade careteam"
          tabindex="-1"
          role="dialog"
          aria-labelledby="myModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" style="max-width: 400px;">
            <div class="modal-content permissmodal">
              <div class="modal-header">
                <h4 class="modal-title font-bold">Select Care Team Members</h4>
              </div>
              <div class="modal-body">
                <div class="mb-10">
                  <div class="lookup patients-search">
                    <div class="selectcontrols">
                      <Autocomplete
                        max="30"
                        @input="getlist"
                        ref="autocomplete"
                        :results="careteam"
                        @onSelect="resultsvalue"
                        class="form-control ms-0 autosearch-list col-12 w-p100"
                        placeholder="Search"
                      ></Autocomplete>
                    </div>
                  </div>
                </div>
                <div class="box m-0 selectlist-members">
                  <div class="box-body p-0">
                    <ul class="todo-list ui-sortable">
                      <li>{{ this.appt_provider }}</li>
                      <li v-for="(item, index) in provider_name" :key="index">
                        <span class="text-line">{{ item }}</span>
                        <div class="tools d-block" @click="removeRow(index)">
                          <i class="fa fa-trash-o"></i>
                        </div>
                      </li>
                      <li v-for="(item, index) in staff_name" :key="index">
                        <span class="text-line">{{ item }}</span>
                        <div class="tools d-block" @click="removeRow(index)">
                          <i class="fa fa-trash-o"></i>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  @click="update()"
                  type="button"
                  class="waves-effect waves-light btn btn-info"
                >
                  Save
                </button>
                <button
                  type="button"
                  class="waves-effect waves-light btn btn-danger"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        <!--modal End-->
      </div>
    </div>
  </div>
</template>
<script>
import TableBase from "@/components/base/Table.vue";
import Autocomplete from "vue3-autocomplete";
import axios from "@/scripts/axios.js";
import permissionMixins from "@/scripts/mixins/permission.js";
import submitMixins from "@/scripts/mixins/submitMixins.js";
import Popper from "vue3-popper";
export default {
  mixins: [permissionMixins, submitMixins],
  components: {
    TableBase,
    Autocomplete,
    Popper,
  },
  methods: {
    update() {
      axios
        .post("patient/" + this.$route.params.id + "/careteammembers/update", {
          id: this.edit_id,
          staff_id: this.staff_id,
          provider_id: this.provider_id,
        })
        .then((response) =>
          this.$store.dispatch("getSimpleTableContents", this.dataTypes)
        );
      this.moshaToast("Record Updated successfully.", "success");
      $(".careteam").modal("toggle");
    },
    edit(id, careteammember, appt_provider,provider_id,staff_id) {
      //const careteammembers = careteammember.split(",");
      this.provider_name = careteammember.split(",");
      this.appt_provider = appt_provider;
      this.edit_id = id;
      this.provider_id = provider_id.split(",");
      this.staff_id = staff_id.split(",");
    },
    getlist(obj) {
      if (obj.length > 1) {
        this.$refs.autocomplete.$el.classList.add("selectloader");
        axios
          .get("/json_autocomplete/careteammembers", {
            params: { term: obj },
          })
          .then((response) => {
            this.careteam = response.data.data;
            this.$refs.autocomplete.$el.classList.remove("selectloader");
          });
      }
      if (obj.length == 0) {
        this.careteam = {};
      }
    },
    resultsvalue(obj) {
      this.$emit("clicked", obj);
      this.$refs.autocomplete.searchText = "";
      if (obj.user_type == "provider") {
        this.provider_name.push(obj.name);
        this.provider_id.push(obj.id);
      } else {
        this.staff_name.push(obj.name);
        this.staff_id.push(obj.id);
      }

      this.focusout();
    },
    focusout() {
      const btn = this.$refs.autocomplete;
      btn.$el
        .querySelector(".vue3-autocomplete-container > .autosearch-list")
        .blur();
    },
    removeRow(index) {
      this.provider_name.splice(index, 1);
      this.staff_name.splice(index, 1);
      this.provider_id.splice(index, 1);
      this.staff_id.splice(index, 1);
    },
  },
  data() {
    return {
      appt_provider: "",
      patient_id: this.$store.state.patientDetail.patient_id,
      patient_unique_id: this.$store.state.patientDetail.patient_unique_id,
      careteam: [],
      provider_name: [],
      staff_name: [],
      provider_id: [],
      staff_id: [],
      edit_id: "",
      dataTypes: {
        dataType: "CareTeamMembers",
        patient_id: this.$store.state.patientDetail.patient_id,
        patient_unique_id: this.$store.state.patientDetail.patient_unique_id,
        status: "Results Received",
        method: "post",
      },
    };
  },
};
</script>
