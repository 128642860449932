import moment from 'moment';
const ContractPayment = {
	namespaced: true,
  	state: {
    	listUrl: "/reports/contract-payment",
		header: [
			{"chart_id":"Medical Chart ID"},
			{"patient_name":"Patient Name"},
			{"payment_amount":"Payment Amount"},
			{"payment_method":"Payment Method"},
			{"payment_date":"Payment Date"},
			{"payment_status":"Payment Status"},
			// {"":"End Date"},
		],
		pagination: {
			to: "",
			from: "",
			total: "",
			last_page: "",
			current_page: "",
			pageNavArr: [],
		},	
		list: [],	
        priceTotals: {
			totalTrans: 0,
			totalReceived: 0,
			totalPending: 0
		},
		filter: {
            date_range: moment().subtract(1, 'months').format("MM-DD-YYYY") + ' - ' + moment(new Date()).format("MM-DD-YYYY"),
            status: 'all',   
            method: 'all',  
        },
  	},
  getters: {
    getTableHeaders: (state) => state.header,
    getTableContents: (state) => state.list,
    getListURL: (state) => state.listUrl,
  },
  mutations: {},
  actions: {},
}

export default ContractPayment;