const clinicalNote2 = {
  appointment_id: "",
  appointment_date: "",
  clinical_note_id: "",
  active_note_component: "ClinicalNoteHandP",
  active_note_legasy: 'system',
  macros: [
    {
      macro_title: "",
      macro_text: "",
    },
  ],
  active_note: "handp",
  namespaced: true,
  state: {
    listUrl: "/patient-appointment-cptCodes",
    medical_history_data: '',
    medication_data: '',
    allergy_data: '',
    iv_formulation_data: '',
    tableData: {
      header: ["CPT-code", "Description"],
    },
    list: [],
  },
  getters: {
    getTableHeaders: (state) => state.header,
    getTableContents: (state) => state.list,
    getListURL: (state) => state.listUrl,
  },
  mutations: {},
  actions: {},
};

export default clinicalNote2;
