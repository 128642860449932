<template>
   <div class="box p-15">
      <div class="mdc-tab">
         <TabbarComp routefrom="Wallet"></TabbarComp>
         <div class="tab-content">
            <div class="tab-pane active" id="wallet" role="tabpanel">
               <div class="my-10">
                  <div class="row justify-content-between align-items-center">
                     <div class="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
                        <h2 class="heading2 my-10">Transactions</h2>
                     </div>
                     <div class="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-8">
                        <div class="row justify-content-sm-end">
                           <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                              <div class="availdate mb-1">
                                  <ejs-daterangepicker format='MM-dd-yyyy' id="date_range" :value="range_date" 
                                   v-model="first_last_date" :strictMode='strict'
                                   placeholder="Select Date Range"  @change="onRangeSelect"></ejs-daterangepicker>
                              </div>
                           </div>
                           <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4">
                              <div class="d-flex">
                                 <div class="controls mb-1">
                                    <select name="select" id="Status" v-model="wallet_type" title="Status" class="form-select form-control" @change="selectType($event)">
                                       <option value="normal">Cash Wallet</option>
                                       <option value="package">Package Wallet</option>
                                    </select>
                                 </div>
                                 <button class="waves-effect waves-light btn btn-info ms-1 mb-1" @click="fetchWalletTransaction">Search</button>
                              </div>
                           </div>
                        </div>
                        <!-- <div class="controls">
                              <select name="select" id="Status" v-model="wallet_type" title="Status" class="form-select form-control">
                                 <option value="normal">Cash Wallet</option>
                                 <option value="package">Package Wallet</option>
                              </select>
                        </div> -->
                        <!-- <button class="waves-effect waves-light btn btn-info ms-1" @click="fetchWalletTransaction">Search</button> -->
                     </div>
                     <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-md-end">
                        <span class="badge badge-primary-light py-7 my-2 fs-16 w-xs-p100" v-show="wallet_type=='normal'">
                           Cash Wallet : $ <span class="fw-900">{{ this.$store.state.patientDetail.wallet_amount }}</span>
                        </span>
                        <span class="badge badge-primary-light py-7 my-2 fs-16 w-xs-p100" v-show="wallet_type=='package'">
                           <Popper arrow hover="true" class="helptips">
                              <i class="fa fa-info-circle fs-16"></i>
                              <template #content>
                                 <table>
                                       <tr>
                                          <th style="border: 1px solid #8d98ad;border-collapse: collapse; padding: 5px;">Package Name</th>
                                          <th style="border: 1px solid #8d98ad;border-collapse: collapse; padding: 5px;">Deposit Amount</th>
                                          <th style="border: 1px solid #8d98ad;border-collapse: collapse; padding: 5px;">Receive Amount</th>
                                          <th style="border: 1px solid #8d98ad;border-collapse: collapse; padding: 5px;">Refund Amount</th>
                                          <th style="border: 1px solid #8d98ad;border-collapse: collapse; padding: 5px;">Remaining Amount</th>
                                       </tr>
                                          <tr v-for="item in service_wallet_info" v-if="service_wallet_info.length > 0">
                                             <td style="border: 1px solid #8d98ad;border-collapse: collapse; padding: 5px;">{{ item.package_name ? item.package_name : 'Unknown' }} </td>
                                             <td style="border: 1px solid #8d98ad;border-collapse: collapse; padding: 5px;">{{ $filters.toUSD(item.amount) }} </td>
                                             <td style="border: 1px solid #8d98ad;border-collapse: collapse; padding: 5px;">{{ $filters.toUSD(item.received_amount) }} </td>
                                             <td style="border: 1px solid #8d98ad;border-collapse: collapse; padding: 5px;">{{ $filters.toUSD(item.refund_amount) }} </td>
                                             <td style="border: 1px solid #8d98ad;border-collapse: collapse; padding: 5px;">{{ $filters.toUSD(item.amount - item.received_amount - item.refund_amount) }} </td>
                                          </tr>
                                          <tr v-if="service_wallet_info.length == 0">
                                             <td colspan="5" style="border: 1px solid #8d98ad;border-collapse: collapse; padding: 5px;">No record found.</td>
                                       </tr>
                                 </table>
                              </template>
                           </Popper>
                           Package Wallet : $<span style="font-weight: 900;">{{ this.$store.state.patientDetail.reserve_wallet_amount }}</span>
                        </span>
						   <a href="javascript:void(0)" class="waves-effect waves-light btn btn-info mx-1" @click="HistoryWallet"><i class="fa fa-eye fs-14"></i> History </a>
                        <a  href="javascript:void(0)" class="waves-effect waves-light btn btn-info mx-1" data-bs-toggle="modal" data-bs-target="#AddFunds" v-if="showbtn" ><i class="fa fa-plus fs-14"></i> Add Funds</a>
                     </div>
                  </div>
               </div>
               <div class="table-responsive">
                  <table class="table fs-16">
                     <thead>
                        <tr>
                           <th>Transaction Type</th>
                           <th>Amount</th>
                           <th v-if="wallet_type_package_enable == true">Received Amount</th>
                           <th v-if="wallet_type_package_enable == true">Refund Amount</th>
                           <th v-if="wallet_type_package_enable == true">Remaining Amount</th>
                           <th>Reference</th>
                           <th>Mode</th>
                           <th>Date</th>
                           <th>Note</th>
                           <th v-if="wallet_type_package_enable == true">Action</th>
                        </tr>
                     </thead>
                     <tbody v-if="walletTransactionData.length>0">
                        <tr v-for="(item, index) in walletTransactionData.slice(this.start,this.end)" :key="index">
                           <td>
                              <span v-if="item.transaction_type == 1" class="badge badge-success-light fs-16 text-center">Debit</span>
                              <span v-if="item.transaction_type == 2" class="badge badge-danger-light fs-16 text-center">Credit</span>
                           </td>
                           <td>${{ item.transaction_type == 2 ? '-' : '' }} {{ item.paid_amount }}</td>
                           <td v-if="wallet_type_package_enable == true">{{ $filters.toUSD(item.received_amount) }}</td>
                           <td v-if="wallet_type_package_enable == true">{{ $filters.toUSD(item.refund_amount) }}</td>
                           <td v-if="wallet_type_package_enable == true">{{ $filters.toUSD(item.paid_amount - item.received_amount - item.refund_amount) }}</td>
                           <td v-if="item.contract_sub_id"> {{ item.transaction_type == 2 ? (item.contract ? (item.contract.plan_name+'#'+$filters.toUSD(item.contract.contract_final_amount)) : '') : '-' }}</td>
                           <td v-if="!item.contract_sub_id">{{ item.transaction_type == 2 ? item.order_reference_no+'#'+$filters.displayDateTimeLocal(item.order_refs,'',false,'MM-DD-YYYY hh:mm A') : '-' }}</td>
                           <td>{{ item.payment_type }}</td>
                           <td>{{ $filters.displayDateTimeLocal(item.payment_date,'',false,"ddd, MM-DD-YYYY",true) }}</td>
                           <td>{{ item.payment_note }}</td>
                           <td>
                              <Popper arrow content="Refund" hover=true class="helptips"  v-if="wallet_type_package_enable == true && item.transaction_type == 1 && item.show_refund_icon == true">
                                 <a href="javascript:void(0)" class="waves-effect waves-circle btn btn-circle btn-info btn-xs me-1" @click ="refundPayment(item)">
                                    <i class="fa fa-undo"></i>
                                 </a>
                              </Popper>
                           </td>
                        </tr>
                     </tbody>
                     <tbody v-else>
                        <tr>
                           <td colspan="10" class="text-center">No Data Found</td>
                        </tr>
                     </tbody>
                  </table>
                  <label>Showing {{start}} to {{(end >= list_lenght )? list_lenght : end}} of {{list_lenght}} entries</label>
                  <a v-if="end < list_lenght" href="javascript:void(0)" class="btn btn-light float-end" @click="next(this.end)">Next</a>
                  <p class="float-end mb-0">
                     <a href="javascript:void(0)" class="btn btn-light me-5" v-for="(item, indx) in steps" @click="pagejump(item)" :key="indx">{{item}}</a>
                  </p>
                  <a v-if="this.start != 0" href="javascript:void(0)" class="btn btn-light float-end me-5" @click="previous(this.end,this.start)">Previous</a>
               </div>
            </div>
         </div>
      </div>
   </div>

   <!--show New Order start-->
   <div class="modal fade" id="AddFunds" tabindex="-1" role="dialog" aria-labelledby="AddFunds" aria-hidden="true">
      <div class="modal-dialog modal-md">
         <div class="modal-content">
            <div class="modal-header">
               <h3 class="modal-title fw-900 mt-0">Cash Wallet</h3>
            </div>
            <div class="modal-body">
               <div class="text-dark mb-20">
                  <a @click="creditWallet" href="javascript:void(0)" class="waves-effect waves-light btn btn-info mx-1" data-bs-toggle="modal" data-bs-target="#creditWallet"><i class="fa fa-plus fs-14"></i> Add Funds</a> 
                   <a @click="creditWallet" href="javascript:void(0)" class="waves-effect waves-light btn btn-info" data-bs-toggle="modal" data-bs-target="#sendLink"><i class="fa fa-paper-plane"></i> Send Link</a>
               </div>
            </div>
            <div class="modal-footer">
               <button type="button" @click.prevent="resetForm" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
            </div>
         </div>
      </div>
   </div>
   <!--show Add funds end-->
   <!--show New Order start-->
   <div class="modal fade" id="creditWallet" tabindex="-1" role="dialog" aria-labelledby="creditWallet" aria-hidden="true">
      <div class="modal-dialog modal-md">
         <div class="modal-content">
            <div class="modal-header">
               <h3 class="modal-title fw-900 mt-0">Cash Wallet</h3>
            </div>
            <div class="modal-body">
               <div class="text-dark mb-20">
                  <form @submit.prevent id="paymentForm" method="post">
                     <div class="badge badge-danger-light fs-16 mb-10" v-if="serverError">{{ serverError }}</div>
                     <div class="form-group">
                        <label for="name" class="form-label">Amount</label>
                        <div class="input-group">
                           <span class="input-group-addon">$</span>
                           <input type="text" class="form-control" v-model="wallet_frm.paid_amount" placeholder="Amount" />
                           <div
                              class="invalid-feedback"
                              style="display:block;"
                              v-for="(error, index) of v$.wallet_frm.paid_amount.$errors"
                              :key="index">
                              {{ error.$message }}
                           </div>
                        </div>
                     </div>
                     <div class="form-group">
                        <label class="form-label">Payment Method</label>
                        <select class="form-select form-control" v-model="wallet_frm.payment_type" id="payment_type" >
                           <option value="">- Select Payment Type -</option>
                           <option label="Cash" value="Cash" >Cash</option>
                           <option label="Check" value="Check">Check</option>
                           <option label="Credit Card" value="Credit Card">Credit Card</option>
                           <option label="Other" value="Other">Other</option>
                        </select>
                        <div
                           class="invalid-feedback"
                           style="display:block;"
                           v-for="(error, index) of v$.wallet_frm.payment_type.$errors"
                           :key="index"
                           >
                           {{ error.$message }}
                        </div>
                     </div>
                     <div class=""  v-show="wallet_frm.payment_type == 'Other'">
                        <div class="form-group">
                           <input type="text" class="form-control" v-model="wallet_frm.other_payment_type" placeholder="Enter Other Payment">
                           <div
                              class="invalid-feedback"
                              style="display:block;"
                              v-for="(error, index) of v$.wallet_frm.other_payment_type.$errors"
                              :key="index"
                              >
                              Other payment is required.
                           </div>
                        </div>
                     </div>
                     <div class=""  v-show="wallet_frm.payment_type == 'Check'">
                        <div class="form-group">
                           <label class="form-label">Check No.</label>
                           <input type="text" class="form-control" v-model="wallet_frm.check_trace" placeholder="Check No.">
                           <div
                              class="invalid-feedback"
                              style="display:block;"
                              v-for="(error, index) of v$.wallet_frm.check_trace.$errors"
                              :key="index"
                              >
                              Check No. required
                           </div>
                        </div>
                     </div>
                     <div class="" v-if="wallet_frm.payment_type == 'Credit Card'">
                        <div class="form-group">
                           <select class="form-select form-control" v-model="wallet_frm.cctoken_id">
                              <option value="">- Select Credit-Card -</option>
                              <option value="addNew">Add New Card</option>
                              <option v-for="(item,index) in ccTokenData" :value="item.id" :data-val="item.id" :disabled="item.is_expired == true" :key="index">{{item.card_label}}</option>
                           </select>
                           <div
                              class="invalid-feedback"
                              style="display:block;"
                              v-for="(error, index) of v$.wallet_frm.cctoken_id.$errors"
                              :key="index"
                              >
                              Please select any option.
                           </div>
                        </div>
                     </div>
                     <div class="form-group" v-show="wallet_frm.payment_type == 'Credit Card' && (wallet_frm.cctoken_id == 'addNew')">
                        <div style="display: none" id="customStyles">
                           body{margin:0;}
                           iframe{height: 100px;}
                           #payment-form {border: 1px solid rgb(212, 221, 230);max-height: 130px;width: 100%;padding: 20px 10px;border-radius: 4px;margin: 0px;background: rgb(245, 248, 250);}
                           #payment-form {color: #2d3c58;}
                           #payment-form .input-label {color: #2d3c58;border-top: 1px solid #d4dde6;font-size:13px;text-align: center !important;}
                        </div>
                        <div id="card" style="height: 130px;"></div>
                        <div id="errorMessage" style="margin-bottom: 8px; color: #c0392b;"></div>
                        <input
                           type="checkbox"
                           id="use_credidcard_for_future_checkbox"
                           class="filled-in chk-col-info"
                           v-model="wallet_frm.use_credidcard_for_future"
                           />
                        <label for="use_credidcard_for_future_checkbox" class="m-0 text-start">Save for future use?</label>
                     </div>
                     <div class="form-group">
                        <textarea
                           class="form-control"
                           id="payment_note"
                           v-model="wallet_frm.payment_note"
                           rows="2"
                           placeholder="Note"
                           ></textarea>
                     </div>
                  </form>
                  <button v-if="permissioncheck(this.$store.state.permission.create_update)" type="button" class="waves-effect waves-light btn me-1 waves-effect waves-light btn btn-info me-1" @click="handleWalletPay">Pay</button>
                  <a v-if="permissioncheck(this.$store.state.permission.create_update)" href="javascript:void(0)" @click.prevent="resetForm" class="waves-effect waves-light btn me-1 btn-danger-light">Clear</a>
               </div>
               <div v-if="!is_production_env">
                  <div class="table-responsive">
                     <table class="ql-table-blob table" data-aura-rendered-by="9911:0">
                        <thead>
                           <tr>
                              <th>Card Type</th>
                              <th>Number</th>
                              <th>Expiry Date</th>
                              <th>Security Code</th>
                           </tr>
                        </thead>
                        <tbody>
                           <tr>
                              <td>Mastercard</td>
                              <td>5439750001500347</td>
                              <td>12/29</td>
                              <td>999</td>
                           </tr>
                           <tr>
                              <td>Visa</td>
                              <td>4005550000000019</td>
                              <td>12/29</td>
                              <td>999</td>
                           </tr>
                           <tr>
                              <td>Discover</td>
                              <td>60011111111111117</td>
                              <td>12/29</td>
                              <td>999</td>
                           </tr>
                           <tr>
                              <td>Diners</td>
                              <td>36999999999999</td>
                              <td>12/29</td>
                              <td>999</td>
                           </tr>
                           <tr>
                              <td>AMEX</td>
                              <td>374255312721002</td>
                              <td>12/29</td>
                              <td>9999</td>
                           </tr>
                        </tbody>
                     </table>
                  </div>
               </div>
            </div>
            <div class="modal-footer">
               <button type="button" @click.prevent="resetForm" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
            </div>
         </div>
      </div>
   </div>
   <!--show New Order end-->
   <!--show New send link start-->
   <div class="modal fade" id="sendLink" tabindex="-1" role="dialog" aria-labelledby="sendLink" aria-hidden="true">
      <div class="modal-dialog modal-md">
         <div class="modal-content">
            <div class="modal-header">
               <h3 class="modal-title fw-900 mt-0">Cash Wallet</h3>
            </div>
            <div class="modal-body">
               <div class="text-dark mb-20">
                  <form @submit.prevent id="paymentsend" method="post">
                     <div class="badge badge-danger-light fs-16 mb-10" v-if="serverError">{{ serverError }}</div>
                     <div class="form-group">
                        <label for="name" class="form-label">Amount</label>
                        <div class="input-group">
                           <span class="input-group-addon">$</span>
                           <input type="text" class="form-control" v-model="wallet_frm.paid_amount" placeholder="Amount" />
                           <div
                              class="invalid-feedback"
                              style="display:block;"
                              v-for="(error, index) of v$.wallet_frm.paid_amount.$errors"
                              :key="index">
                              {{ error.$message }}
                           </div>
                        </div>
                     </div>
                     <div>
                        <input type="radio" name="patient_can_edit_the_amount" id="patient_can_edit_the_amount" @click="chnagesvalue('patient_can_edit_the_amount')">
                        <label for="patient_can_edit_the_amount">Patient can edit the amount</label>
                     </div>
                     <div>
                        <input type="radio" name="patient_can_edit_the_amount" id="patient_can_not_edit_the_amount" @click="chnagesvalue('patient_can_not_edit_the_amount')">
                        <label for="patient_can_not_edit_the_amount">Patient can’t edit the amount</label>
                     </div>
                     <div>
                        <input type="radio" name="patient_can_edit_the_amount" id="free_type" @click="chnagesvalue('free_type')">
                        <label for="free_type">Free type</label>
                     </div>
                  </form>
                 
                  
               </div>
            </div>
            <div class="modal-footer">
               <button v-if="permissioncheck(this.$store.state.permission.create_update)" type="button" class="waves-effect waves-light btn me-1 waves-effect waves-light btn btn-info me-1" @click="sendAmountLink">Send <img v-if="loader" src="images/loader.gif"></button>
               <button type="button" @click.prevent="resetForm" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
            </div>
         </div>
      </div>
   </div>
   <!--show New send link end-->

<!--show Make Refund start-->
   <div class="modal fade" id="makerefund" tabindex="-1" role="dialog" aria-labelledby="makerefund" aria-hidden="true">
      <div class="modal-dialog" style="max-width: 600px;">
         <div class="modal-content">
            <div class="modal-header">
               <h3 class="modal-title fw-900 mt-0">Make Refund</h3>
            </div>
            <div class="modal-body">
               <div class="mdc-tab">
                   <div class="tab-content">
                       <div class="tab-pane active" id="billingSummary" role="tabpanel">
                           <div class="box box-solid mb-0">
                               <div class="box-header with-border bg-secondary">
                                   <h2 class="black-color m-0 fs-20" id="creditpayment">Payment</h2>
                               </div>
                               <div class="box-body text-dark">
                                   <form @submit.prevent id="paymentForm" method="post">
                                       <div style="margin-bottom: 10px; color: #c0392b;" v-if="errorMessageRefund">{{ errorMessageRefund }}</div>
                                       <div class="form-group">
                                           <label for="name" class="form-label">Refundable Amount</label>
                                           <div class="input-group">
                                               <span class="input-group-addon">$</span>
                                               <input type="text" class="form-control" :readonly="true" v-model="refund_frm.paid_amount" placeholder="Refundable Amount"/>
                                           </div>
                                       </div>
                                       <div class="form-group">
                                           <label class="form-label">Refund Amount</label>
                                           <div class="input-group">
                                               <span class="input-group-addon">$</span>
                                               <input type="text" class="form-control" :readonly="true" v-model="refund_frm.refund_amount" v-on:keypress="numbersOnly" placeholder="Enter Amount" />
                                               <div class="invalid-feedback" style="display:block;">{{refund_amt_error}}</div>
                                           </div>
                                       </div>
                                   </form>
                                   <button v-if="permissioncheck(this.$store.state.permission.create_update)" type="button" class="waves-effect waves-light btn  mb-5 me-1 waves-effect waves-light btn btn-info me-1" @click="handleRefundPay">Refund</button>
                               </div>
                           </div>
                       </div>
                   </div>
                   <div class="tab-pane animation-fade active" id="category-1" role="tabpanel">
                       <div class="panel-group panel-group-simple panel-group-continuous mb-0" id="accordion2" aria-multiselectable="true" role="tablist">
                       </div>
                   </div>
               </div>
            </div>
            <div class="modal-footer">
               <button type="button" class="waves-effect waves-light btn btn-danger" data-bs-dismiss="modal" @click="refundDialogClose">Close</button>
            </div>
         </div>
      </div>
   </div>
<!--show Make Refund end-->
<!--viewReceipt Modal Start-->
	<div class="modal" id="viewReceipt" tabindex="-1">
		<div class="modal-dialog modal-md" style="max-width:75%">
			<div class="modal-content">
				<div class="modal-header">
					<button type="button" class="btn-close btn-xs" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">
					<vue-pdf-viewer height="1050px" :url="receipt_url" v-if="embed=0">
					</vue-pdf-viewer>
					<iframe :src="receipt_url" width="100%" height="500px" v-if="embed=1" />
					<img srd="images/1.gif" v-else> 
				</div>
			</div>
		</div>
	</div>
	<!--viewReceipt Modal end-->

</template>
<script>
   import permissionMixins from "@/scripts/mixins/permission.js";
   import axios from "@/scripts/axios.js";
   import submitMixins from "@/scripts/mixins/submitMixins.js";
   import useVuelidate from "@vuelidate/core";
   import Popper from "vue3-popper";
   import moment from 'moment';
	import TabbarComp from "@/components/patients/details/Payment/TabbarComp.vue";
	import VuePDFViewer from 'vue-instant-pdf-viewer';
   import {
      required,
      helpers,
      minLength,
      maxLength,
      numeric,
      requiredIf,
   } from "@vuelidate/validators";
import { FLOWBASEANNOTATION_TYPES } from "@babel/types";
   export default {
    components: {
      Popper,
      TabbarComp,
	  'vue-pdf-viewer': VuePDFViewer,
    },
    setup() {
        return { v$: useVuelidate() };
    },
    mixins: [submitMixins,permissionMixins],
    data() {
        return {
            is_production_env : process.env.VUE_APP_NODE_ENV == 'production',
            hash: '',
            loader:false,
            showbtn:true,
            wallet_frm : {
                payment_type : '',
                paid_amount : '',
                payment_note : '',
                check_trace : '',
                other_payment_type : '',
                cctoken_id : '',
                use_credidcard_for_future : false,
                patient_id : this.$store.state.patientDetail.patient_id,
                patient_unique_id : this.$store.state.patientDetail.patient_unique_id,
                amount_payment_type:"",
            },
            refund_frm : {
               wallet_trans_id : '',
               refund_amount : '',
               paid_amount : '',
               patient_id : this.$store.state.patientDetail.patient_id,
               patient_unique_id : this.$store.state.patientDetail.patient_unique_id,
            },
            current_patient_wallet : 0,
            walletTransactionData: [],
            service_wallet_info: [],
            tokenpay : {},
            tokenPayReady: false, 
            wallet_type: 'normal', 
            wallet_type_package_enable: false, 
            checkingInterval: null,
            serverError: '',
            range_date: '',
            first_last_date: '',
            start_date: '',
            end_date: '',
            ccTokenData: [],
            receipt_url: '',
            embed: 0,
            list_lenght:'',
            start:0,
            end:10,
            step:10,
            steps:'',
            publicKey : '',
            refund_amt_error : '',
            errorMessageRefund : '',
        };
    },
    validations() {
        return {
            wallet_frm: {
                paid_amount: {
                    required: helpers.withMessage("Walllet amount is required.", required),
                },
                payment_type: {
                    required: helpers.withMessage("Payment Type is required.", required),
                },
                check_trace: {
                    required: requiredIf(function() {
                        return this.wallet_frm.payment_type == 'Check' ? true : false;
                    }),
                },
                other_payment_type: {
                    required: requiredIf(function() {
                        return this.wallet_frm.payment_type == 'Other' ? true : false;
                    }),
                },
                cctoken_id: {
                    required: requiredIf(function() {
                        return this.wallet_frm.payment_type == 'Credit Card' ? true : false;
                    }),
                },
            },
        };
    },
    async created() {
        let localThis = this 
        this.fetchPatientToken();
        this.fetchWalletTransaction();
        const result = await this.getTpConfigByKey('RECTANGLEHEALTH','PUBLICKEY');
        if (result) {
            this.publicKey = result.data.PUBLICKEY;
            this.checkingInterval = setInterval(function() {
                if (window.TokenPay) {
                    localThis.tokenPayReady = true
                }
            }, 500);
        }
    },
    watch: {
        tokenPayReady: function(data) {
            if (data) { 
                clearInterval(this.checkingInterval) 
                this.initializeToken()
            }
        }
    },
    computed: {
    },
    mounted() {
        let bridgePayScript = document.createElement('script')
        bridgePayScript.setAttribute('src', process.env.VUE_APP_API_RECTANGLEHEALTH_TOKENPAY_URL)
        document.head.appendChild(bridgePayScript);
        this.range_date = moment().subtract(1, 'months').format("MM-DD-YYYY")+' - '+moment(new Date()).format("MM-DD-YYYY");
        this.first_last_date = this.range_date;
        axios.get("walletSetting").then(response => { 
            if(response.data.data.default_wallet_amount=="0.00"){
               
            }else{
                this.wallet_frm.paid_amount=response.data.data.default_wallet_amount;
            }
               
         });
    },
    methods: {
		async HistoryWallet(){
			this.$store.state.loader = true;
			var patient_id = this.$store.state.patientDetail.patient_id;
			const res = await fetch(process.env.VUE_APP_API_BASE_URL+'/wallet/print-transaction', {
				method: 'POST',
				headers: {
					'Authorization' : 'Bearer '+localStorage.getItem('authToken'),
					'Content-Type' : 'application/json'
				},
				body: JSON.stringify({ patient_id :this.$store.state.patientDetail.patient_id , start_date : this.start_date, end_date : this.end_date, wallet_type : this.wallet_type }),
			});
			const blob = await res.blob();
			const urlObject = URL.createObjectURL(blob);
			if (/Android|webOS|Opera Mini/i.test(navigator.userAgent)) {
				$("#viewReceipt").modal("hide");
				window.open(urlObject, '_blank', 'height=600,width=1200');
			} else {
				this.receipt_url = urlObject;
				this.browserCheck();
			}
			this.$store.state.loader = false;
			$("#viewReceipt").modal("show");
		},
		browserCheck(){
			// Opera 8.0+
			var isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

			// Firefox 1.0+
			var isFirefox = typeof InstallTrigger !== 'undefined';

			// Safari 3.0+ "[object HTMLElementConstructor]" 
			var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));

			// Internet Explorer 6-11
			var isIE = /*@cc_on!@*/false || !!document.documentMode;

			// Edge 20+
			var isEdge = !isIE && !!window.StyleMedia;

			// Chrome 1 - 79
			var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

			// Edge (based on chromium) detection
			var isEdgeChromium = isChrome && (navigator.userAgent.indexOf("Edg") != -1);

			// Blink engine detection
			var isBlink = (isChrome || isOpera) && !!window.CSS;

			if (isChrome==true) {
				this.embed = 0;
			} else {
				this.embed = 1;
			}
		},
        handleRefundPay() {
            this.refund_amt_error = "";
             if (!this.refund_frm.refund_amount || parseFloat(this.refund_frm.refund_amount) < 0) {
                  this.refund_amt_error = "Refund amount is required.";
             }
             if (parseFloat(this.refund_frm.refund_amount) > parseFloat(this.refund_frm.paid_amount)) {
                  this.refund_amt_error = "Refund amount should not greater then main amount.";
             }
             if (!this.refund_amt_error) {
               this.$store.state.loader = true;
               axios.post('wallet/package-refund', this.refund_frm)
               .then((response) => {
                  this.$store.state.loader = false;
                   this.fetchWalletTransaction();
                   $("#makerefund").modal('hide');
                   this.moshaToast(response.data.message, "success");
               }).catch((error) => {
                   this.errorMessageRefund = error.response.data.message;
                   this.$store.state.loader = false;
               });
             }
        },
        refundPayment(item) {
            $("#makerefund").modal('show');
            this.refund_frm.paid_amount = (item.paid_amount - item.received_amount - item.refund_amount) ;
            this.refund_frm.wallet_trans_id = item.id;
            this.refund_frm.refund_amount = this.refund_frm.paid_amount;
         },
         onRangeSelect() {
           this.date_range =  $('#date_range').val();
         },
         changeTab (components) {
               this.$store.state.patientDetailsComp = components;
               let props = this.$router.resolve({
                  name: components,
                  params: { id: this.$route.params.id},
               });
               if (history.pushState && props) {
                  history.pushState(null, '',props.href);
               }
         },
         numbersOnly(evt) {
               evt = (evt) ? evt : window.event;
               var charCode = (evt.which) ? evt.which : evt.keyCode;
               if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                  evt.preventDefault();;
               } else {
                  return true;
               }
         },
         
         initializeToken : function() {
               this.tokenpay = {};
               this.tokenpay = TokenPay(this.publicKey);
               this.tokenpay.initialize({
                  dataElement: '#card',
                  errorElement: '#errorMessage',
                  amountElement: 'amount',
                  useACH: false,
               //if displaying all 4 fields then useStyles=false, disableZip=false, disableCvv=false
               //if displaying 3 out of 4 fields then useStyles=false, and set disableZip or disableCvv equal to true
               //if displaying 2 out of 4 fields then useStyles=true, disableZip=true, disableCvv=true
               useStyles: true,
               disableZip: true,
               disableCvv: false
               });
         },
        fetchWalletTransaction() {
            var $this = this;
            setTimeout(function(){
               $this.$store.state.loader = true;

               var daterange = $('#date_range').val();
               if (daterange != '' || daterange != null) {
                  var date = daterange.split(' - ');
                  $this.start_date = moment(String(date[0])).format('YYYY-MM-DD');
                  $this.end_date = moment(String(date[1])).format('YYYY-MM-DD');
               } 
               if(daterange == "") {
                  $this.start_date = "";
                  $this.end_date = "";
               }
               axios
               .post('wallet/list',{ patient_id :$this.$store.state.patientDetail.patient_id , start_date : $this.start_date, end_date : $this.end_date, wallet_type : $this.wallet_type })
               .then((response) => {
                   if ($this.wallet_type == 'package') {
                     $this.wallet_type_package_enable = true;
                   } else {
                     $this.wallet_type_package_enable = false;
                   }
                   $this.$store.state.loader = false;
                   $this.walletTransactionData = response.data.data.wallet_trans;
                   $this.$store.state.patientDetail.reserve_wallet_amount = response.data.data.reserve_wallet_amount;
                   $this.current_patient_wallet = response.data.data.wallet_amount;
                   $this.service_wallet_info = response.data.data.service_wallet_info;
                   $this.$store.state.patientDetail.wallet_amount = response.data.data.wallet_amount;
                  $this.list_lenght = $this.walletTransactionData.length;
                  $this.steps = parseInt($this.list_lenght / $this.step);
               });
            },300);
         },
         fetchPatientToken : function() {
               axios
               .post('/payment/patient-cctoken',{patient_id:this.$store.state.patientDetail.patient_id})
               .then((response) => {
                  this.ccTokenData = response.data.data;
                  this.ccTokenData.forEach((value, index) => {
                     if (value.is_default == 1 && value.is_expired == false) {
                           this.wallet_frm.cctoken_id = value.id;
                     }
                  });
               });
         },
         resetForm : function() {
          
               this.v$.$reset();
              
               this.wallet_frm.payment_type = '';
               if (this.wallet_frm.amount_payment_type==null) {
                  this.wallet_frm.paid_amount = '';
               }
               this.wallet_frm.amount_payment_type="";
               this.wallet_frm.check_trace = '';
               this.wallet_frm.other_payment_type = '';
               this.wallet_frm.cctoken_id = '';
               this.wallet_frm.payment_note = '';
               this.wallet_frm.use_credidcard_for_future = false;
               
               this.ccTokenData.forEach((value, index) => {
                  if (value.is_default == 1 && value.is_expired == false) {
                     this.wallet_frm.cctoken_id = value.id;
                  }
               });
               window.location.reload();
         },
         handleWalletCapture : function() {
               var $this = this;
               $this.$store.state.loader = true;
               this.wallet_frm.order_id = $this.order_id;
               axios.post("wallet/store", this.wallet_frm)
               .then((response) => {
                  //$this.order_id = response.data.data.patient_billing_order_id;
                  $this.$store.state.loader = false;
                  $this.resetForm();
                  $this.moshaToast(response.data.message, "success");
                  this.fetchWalletTransaction();
                  $("#creditWallet").modal('hide');
               })
               .catch((error) => {
                  $this.$store.state.loader = false;
                  $this.serverError = error.response.data.message;
               });
         },
         handleWalletPay: function() {
               this.v$.$validate();
               if (!this.v$.$error) {
                  this.serverError = '';
                  this.$store.state.loader = true;
                  var vm = this;
                  if (this.wallet_frm.payment_type == 'Credit Card' && this.wallet_frm.cctoken_id == 'addNew') {
                     this.tokenpay.createToken(function (result) {
                           vm.wallet_frm.token = result.token;
                           vm.handleWalletCapture();
                     }, function (result) {
                           vm.$store.state.loader = false;
                     });
                  } else {
                     vm.handleWalletCapture();
                  }
               }
         },
         sendAmountLink(){
            this.loader = true;
            axios.post("patient/walletSetting",{
                    'wallet_amount': this.wallet_frm.paid_amount,
                    'patient_id':this.$store.state.patientDetail.patient_id,
                    'amount_payment_type': this.wallet_frm.amount_payment_type,
                }).then(response => { 
                
                if(response.status==200){
                  this.loader = false;
                    this.moshaToast(" emails send successfully", "success");
                    
                }
                
                });
          
         },
         selectType(event){
            if(event.target.value=="normal"){
              this.showbtn=true;
            }else{
               this.showbtn=false;
            }
            
         },
         chnagesvalue(type){
            this.wallet_frm.amount_payment_type=type;
            
         },
         creditWallet(){
            $("#AddFunds").modal("hide");
         },
      }
   }
</script>
<style type="text/css">
   .helptips .popper {
       transform: translate(0px, 149px);
       text-align: center;
       font-size: 14px;
       max-width: 700px;
       /* background: #6c7991 !important; */
       z-index: 2 !important;
       font-weight: 500;
       padding: 10px 5px !important;
       /* line-height: normal; */
       margin: 5px;
   }
</style>