<template>
    <div class="content-wrapper">
        <div class="container-full">
            <router-link to="/crm/contacts" class="mb-10 fs-16 fw-600 d-block"><i class="fa fa-long-arrow-left fs-14 text-info me-2"></i> {{this.form.first_name}} {{this.form.last_name}}</router-link>
            <div class="box">
                <div class="box-body fs-16">
                    <div class="row justify-content-center m-0">
                        <div class="col-12 col-sm-6 col-md-6 col-lg-6 px-0">
                            <div class="sectscroltabs position-relative">
                                <ul class="ramostabs nav nav-tabs nav-fill" role="tablist">
                                    <li class="nav-item"> <a class="nav-link active" data-bs-toggle="tab" href="#contact_tabs" role="tab" aria-selected="true">Contact</a></li>
                                </ul>
                                <div class="tab-content">
                                    <div class="tab-pane active" id="contact_tabs" role="tabpanel">
                                        <div class="p-10">
                                            <div class="form-group continput_fill mb-5">
                                                <label class="form-label">First Name</label>
                                                <input type="text" class="form-control"  :class="{ 'is-invalid': errors && errors.first_name }" v-model="this.form.first_name">
                                                <div
                                                  class="invalid-feedback"
                                                  style="display:block;"
                                                  v-for="(error, index) of v$.form.first_name.$errors"
                                                  :key="index"
                                                >
                                                  {{ error.$message }}
                                                </div>
                                            </div>
                                            <div class="form-group continput_fill mb-5">
                                                <label class="form-label">Last Name</label>
                                                <input type="text" name="first_name" class="form-control" :class="{ 'is-invalid': errors && errors.last_name }" v-model="this.form.last_name">
                                                <div
                                                  class="invalid-feedback"
                                                  style="display:block;"
                                                  v-for="(error, index) of v$.form.last_name.$errors"
                                                  :key="index"
                                                  >
                                                  {{ error.$message }}
                                                </div>
                                            </div>
                                            <div class="form-group continput_fill mb-5">
                                                <label class="form-label">Email</label>
                                                <input type="email" name="Email" class="form-control" :class="{ 'is-invalid': errors && errors.email }" v-model="this.form.email">
                                                <div
                                                  class="invalid-feedback"
                                                  style="display:block;"
                                                  v-for="(error, index) of v$.form.email.$errors"
                                                  :key="index"
                                                  >
                                                  {{ error.$message }}
                                                </div>
                                            </div>
                                            <div class="form-group continput_fill mb-5">
                                                <label class="form-label">Cell Phone</label>
                                                <input type="text" name="cell_phone" class="form-control"  v-mask="'(000) 000-0000'" :class="{ 'is-invalid': errors && errors.cell_phone }" v-model="this.form.cell_phone">
                                                <div
                                                  class="invalid-feedback"
                                                  style="display:block;"
                                                  v-for="(error, index) of v$.form.cell_phone.$errors"
                                                  :key="index"
                                                  >
                                                  {{ error.$message }}
                                                </div>
                                            </div>
                                            <div class="form-group continput_fill mb-5">
                                                <label class="form-label">Date of Birth </label>
                                                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 availdate" :class="{ error: v$.form.date_of_birth.$errors.length }">
                                                    <ejs-datepicker :value="date_of_birth" id="date_of_birth" v-model="v$.form.date_of_birth.$model" :format="dateDOBFormat" placeholder="select date of birth" @change="getdate($event)" name="date_of_birth" :strictMode='strict' :max='maxVal' :showClearButton='false' :openOnFocus='true'>
                                                    </ejs-datepicker>
                                                </div>
                                            </div>
                                            <div class="form-group continput_fill mb-5">
                                                <label class="form-label">Tags</label>
                                                <smart-tagz autosuggest editable inputPlaceholder="Enter your tags" :allowPaste="{delimiter: ','}" :allowDuplicates="false" :maxTags="20" :defaultTags="this.defaulttaglist" :onChanged="onTagChanged" :key="key"/>
                                                <span class = "help-block">
                                                    Press enter key after adding new tag.
                                                 </span>
                                            </div>
                                            <div class="form-group continput_fill my-15">
                                                <label class="form-label d-block">DND (opt out of marketing campaigns)</label>
                                                <button type="button" class="btn btn-sm btn-toggle rounded30-im" data-bs-toggle="button" aria-pressed="true" autocomplete="off" id="dnd">
                                                    <div class="handle rounded30-im"></div>
                                                </button>
                                            </div>
                                            <div class="cont_collapse mt-15" id="workcollapse">
                                                <div class="accordion-item">
                                                    <a class="accordion-button fs-16 fw-600" id="camp_workflows" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="true" aria-controls="flush-collapseOne">
                                                    Workflows</a>
                                                    <div id="flush-collapseOne" class="accordion-collapse collapse show" aria-labelledby="camp_workflows" data-bs-parent="#workcollapse">
                                                        <div class="accordion-body b-1 border-secondary p-5">
                                                            <p class="m-1 d-inline-block" v-for="(item,index) in patientworkflow" :key="index">
                                                                <span class="badge badge-info-light text-start white-space-normal">{{item.name}} ({{item.status}}) <i class="fa fa-close cursor-pointer" @click="removeworkflow(item.id)"></i></span>
                                                            </p>
                                                            <a href="javascript:void(0)" class="waves-effect waves-light btn btn-outline btn-info btn-sm" data-bs-toggle="modal" data-bs-target="#AddWorkFlowModal"><i class="fa fa-plus"></i> Add</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <button type="button" class="waves-effect waves-light btn btn-info mt-10" @click="save"> Save</button>
                                        </div>
                                    </div>
                                    <div class="tab-pane" id="general_tabs" role="tabpanel">
                                        <div class="py-10">
                                            <p>Duis cursus eros lorem, pretium ornare purus tincidunt eleifend. Etiam quis justo vitae erat faucibus pharetra. Morbi in ullamcorper diam. Morbi lacinia, sem vitae dignissim cursus, massa nibh semper magna, nec pellentesque lorem nisl quis ex.</p>
                                        </div>
                                    </div>
                                    <div class="tab-pane" id="additional_tabs" role="tabpanel">
                                        <div class="py-10">
                                            <p>Duis cursus eros lorem, pretium ornare purus tincidunt eleifend. Etiam quis justo vitae erat faucibus pharetra. Morbi in ullamcorper diam. Morbi lacinia, sem vitae dignissim cursus, massa nibh semper magna, nec pellentesque lorem nisl quis ex.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-6 px-0">
                            <div class="sectscroltabs position-relative">
                                <div class="messages-group" id="chatDiv">
                                    <div class="messages-group-inner" style="text-align:center;">
                                        <div class="messages-group-heading" style="">
                                            <i class="fa fa-commenting-o fs-18"></i>
                                            <h4>Conversation began</h4>
                                        </div>
                                    </div>
                                    <div v-if="this.chat_message_history.length > 0">
                                        <div class="message-wrapper" v-for="(chat_message,index) in chat_message_history" :key="index">
                                            <div data-tour-step="2" class="messages-single" :class="chat_message.sender_type=='staff' ? '--own-message' : '' ">
                                                <div class="avatar">
                                                    <span :title="chat_message.sender_name">
                                                        <div class="--sm avatar">
                                                            <div class="avatar_img"> {{ chat_message.sender_short_name }} </div>
                                                        </div>
                                                    </span>
                                                    <i class="type-icon icon  icon-sms2 absolute-right"></i>
                                                </div>
                                                <div class="message-bubble">
                                                    <p v-html="chat_message.message_content" ></p>
                                                </div>
                                                <p class="time-date flex"><span :title="chat_message.msg_full_time"> {{ chat_message.msg_time }} </span></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="box-footer message-input-wrap">
                                    <ul class="ramostabs nav nav-tabs mb-10" role="tablist">
                                        <template v-for="(item, index) in nav_tab_items" :key="index">
                                            <li  class="nav-item" v-if="item.is_hidden == false"> 
                                                <a class="nav-link"  v-bind:class="{'active' : index === current_nav_tab }" data-bs-toggle="tab" :id="'tab_nav'+item.title" :href="'#'+item.ref" @click.prevent="changeMsgTab(item)" role="tab" >{{ item.title }}</a>
                                            </li>
                                        </template>
                                    </ul>
                                    <div class=" position-relative br-0">
                                        <div class="tab-content">
                                            <template v-for="(item, index) in nav_tab_items" :key="index">
                                                <div class="tab-pane" v-bind:class="{'active' : index === current_nav_tab }" :id="item.ref" role="tabpanel" v-if="item.is_hidden == false">
                                                    <div class="" v-if="item.ref == 'email_tab'">
                                                        <div class="form-group continput_fill mb-5">
                                                            <input type="text" name="Subject" class="form-control" v-model="this.send_form.message_subject" placeholder="Subject">
                                                            <div class="invalid-feedback" style="display:block;" v-if="!this.send_form.message_subject && this.message_subject_error"> {{ this.message_subject_error }}</div>
                                                        </div>
                                                        <div class="form-group continput_fill mb-5">
                                                            <ejs-richtexteditor
                                                                :pasteCleanupSettings="pasteCleanupSettings"
                                                                :toolbarSettings="toolbarSettings"
                                                                :insertImageSettings="insertImageSettings"
                                                                :imageUploading="imageUploading"
                                                                :imageRemoving="imageRemoving"
                                                                :afterImageDelete="afterImageDelete"
                                                                ref="editorObj"
                                                                title="Message"
                                                                rows="10">
                                                            </ejs-richtexteditor>
                                                             <div class="invalid-feedback" style="display:block;" v-if="!this.send_form.message_content && this.message_content_error"> {{ this.message_content_error }}</div>
                                                        </div>
                                                        <div class="mt-10 text-end">
                                                            <button type="button" class="waves-effect waves-light btn btn-light ms-1">Clear</button>
                                                            <button type="button" class="waves-effect waves-light btn btn-info ms-1" @click="sendMessage()"><i class="mdi mdi-send"></i> Send</button>
                                                        </div>
                                                    </div>
                                                    <div class="message-box position-relative" v-if="item.ref == 'sms_tab'">
                                                        <div class="d-md-flex d-block justify-content-between align-items-center bg-whiteoverflow-hidden">
                                                            <textarea class="form-control text-area-input pe-30" v-model="send_form.message_content" rows="8" placeholder="Say something..."></textarea>
                                                        </div>
                                                        <div class="invalid-feedback" style="display:block;" v-if="!this.send_form.message_content && this.message_content_error"> {{ this.message_content_error }}</div>
                                                        <div class="mt-10 text-end">
                                                            <button type="button" class="waves-effect waves-light btn btn-light ms-1">Clear</button>
                                                            <button type="button" class="waves-effect waves-light btn btn-info ms-1" @click="sendMessage()"><i class="mdi mdi-send"></i> Send</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-12 col-sm-6 col-md-4 col-lg-3 px-0">
                            <ul class="ramostabs nav nav-tabs nav-fill" role="tablist">
                                <li class="nav-item"> <a class="nav-link active" data-bs-toggle="tab" href="#activity_tabs" role="tab" aria-selected="true">Activity</a></li>
                                <li class="nav-item"> <a class="nav-link" data-bs-toggle="tab" href="#task_tabs" role="tab" aria-selected="false">Task</a></li>
                                <li class="nav-item"> <a class="nav-link" data-bs-toggle="tab" href="#nots_tabs" role="tab" aria-selected="false">Nots</a></li>
                                <li class="nav-item"> <a class="nav-link" data-bs-toggle="tab" href="#appoint_tabs" role="tab" aria-selected="false">Appointment</a></li>
                            </ul>
                            <div class="sectscroltabs position-relative br-0">
                                <div class="tab-content">
                                    <div class="tab-pane active" id="activity_tabs" role="tabpanel">
                                        <div class="p-20 attribution-source-both">
                                            <div class="my-2">
                                                <div class="row">
                                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6">
                                                        <div class="createLeadt_contact l-h-n">
                                                            <div class="me-3 fs-18">
                                                                <i class="fa fa-user-circle text-success"></i>
                                                            </div>
                                                            <div>
                                                                <p class="mb-0">Contact Created</p>
                                                                <a href="javascript:void(0)" class="fs-12">Facebook Lead Form</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6">
                                                        <div class="text-end">
                                                            <p class="mb-0 fw-500 fs-14">Apr 14th, 2022</p>
                                                            <span class="text-mute">12:34 am</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="my-2">
                                                <div class="row">
                                                    <div class="col-7 col-sm-7 col-md-7 col-lg-7">
                                                        <div class="createLeadt_contact l-h-n">
                                                            <div class="me-3 fs-18">
                                                                <i class="fa fa-globe text-info"></i>
                                                            </div>
                                                            <div>
                                                                <p class="mb-0">Contact Created</p>
                                                                <a href="javascript:void(0)" class="waves-effect waves-light btn btn-danger-light btn-xs mt-1 me-1 fs-12">Paid Social</a>
                                                                <a href="javascript:void(0)" class="waves-effect waves-light btn btn-outline btn-danger-light btn-xs mt-1 me-1 fs-12">Campaign: Cbm | Mofu | Leads <i class="fa  fa-question-circle"></i></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-5 col-sm-5 col-md-5 col-lg-5">
                                                        <div class="text-end">
                                                            <p class="mb-0 fw-500 fs-14">Apr 14th, 2022</p>
                                                            <span class="text-mute">12:34 am</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-15">
                                            <div class="bt-1 p-10">
                                                <p class="mb-0">First Attribution</p>
                                                <span class=""><b>Session Source:</b> Paid Social <i class="fa fa-question-circle" title="Session Source: Paid Social Campaign: CBM | MOFU | LEADS UTM Source:"></i></span>
                                            </div>
                                            <div class="bt-1 p-10">
                                                <p class="mb-0">Latest Attribution</p>
                                                <span class=""><b>Session Source:</b> Paid Social <i class="fa fa-question-circle" title="Session Source: Paid Social Campaign: CBM | MOFU | LEADS UTM Source:"></i></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane" id="task_tabs" role="tabpanel">
                                        <div class="p-20">
                                            <p>There are no tasks assigned for the selected user</p>
                                        </div>
                                    </div>
                                    <div class="tab-pane" id="nots_tabs" role="tabpanel">
                                        <div class="p-20">
                                            <h3>Notes</h3>
                                            <p class="mb-2 text-mute">15% discount; $362 6 monthly: went to LabCorp 3/15/22 and 3/22/22 SD</p>
                                            <p class="mb-2 text-mute">medication refilled 03/10/22 SD</p>
                                            <p class="mb-2 text-mute">sent LabCorp REQ 03/07/22 SD</p>
                                            <p class="mb-2 text-mute">payment successful 03/02/22 SD</p>
                                            <p class="mb-2 text-mute">sent notification about the failed payment 03/02/22 SD</p>
                                            <p class="mb-2 text-mute">payment prescription sent to the pharmacy. Reprocessed refill 02/23/22 SD</p>
                                            <p class="mb-2 text-mute">Refill done 02/17/2022</p>
                                            <p class="mb-2 text-mute">requesting for thyroid refill medication. sent clarification for medication 02/15/22 SD</p>
                                            <p class="mb-2 text-mute">01/03/22. RB , 1st monthly payment charged 02/03/22 RB; Last contacted 02/02/22 RB</p>
                                            <p class="mb-2 text-mute">contract with intakes and consents sent 12/29/21.RB</p>
                                            <p class="mb-2 text-mute">Lisa is a teacher and should recieve the 15% discount. Text Ramel to send over correted contract. 12/29/21 SR</p>
                                            <p class="mb-2 text-mute">5% Discount; Pertinent docs sent 12/20/21.RB</p>
                                            <p class="mb-2 text-mute">Lab review appt w/ doc on 12/20/21 at 10:00 AM 11/24/21 SR</p>
                                            <p class="mb-2 text-mute">Pt went to LabCorp (12/02/21) 12/03/21 MH</p>
                                            <p class="mb-2 text-mute">pt booked appt w/ LabCorp on 12/02/21 at 8:30 AM 11/24/21. SR</p>
                                            <p class="mb-2 text-mute">Sent LabCorp REQ 11/24/21 MH</p>
                                            <p class="mb-2 text-mute">Stratgey call booked 11/24/21 at 10:15 11/24/21 SR not qualified, cannot afford right now, added to Future. Was also sent copy of online form. 10/29/21</p>
                                            <p class="mb-2 text-mute">SR 10/28/21 Left vm and sms confirming strategy call for tomorrow at 3:30.</p>
                                            <p class="mb-2 text-mute">strat call booked 10/29 @ 3:30 10/26/21 SA</p>
                                            <p class="mb-2 text-mute">SR 10/21/21 Left vm and sent sms and email.</p>
                                            <p class="mb-2 text-mute">DearDoc lead 10/21/21 SA</p>
                                            <p class="mb-2 text-mute">Date: Apr 1 2022, 3:22pm</p>
                                            <p>By: Jilesh P</p>
                                        </div>
                                    </div>
                                    <div class="tab-pane" id="appoint_tabs" role="tabpanel">
                                        <div class="p-20">
                                            <div class="text-center">
                                                <i class="fa fa-calendar fs-18"></i>
                                                <p>No appointments for this contact <br> <a href="javascript:void">Click here</a> to create one</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--Workflows modal end-->
    <div id="AddWorkFlowModal" class="modal fade" tabindex="-1" aria-labelledby="problemModalLabel" style="display: none;" aria-hidden="true">
        <div class="modal-dialog ">
            <div class="modal-content">
                <div class="modal-header">
                    <h2 class="modal-title m-0">Add to Workflow</h2>
                </div>
                <div class="modal-body">
                    <div class="form-group mb-10">
                        <label class="form-label">Workflow </label> <small class="text-danger asterisksign">*</small>
                        <select class="form-select" v-model="Workflows_id" @change="change_workflow">
                            <option selected="selected" disabled="disabled" value="">Select Workflow</option>
                            <option v-for="(item,index) in workflowlist" :value="item.id" :key="index">{{item.name}}</option>
                        </select>
                        <span class="d-none text-danger d-block fs-14" id="workflow_error">Please select Workflow</span>
                    </div>
                    <div class="form-group mb-10">
                        <label class="form-label">Event Start Date </label> <small class="text-danger asterisksign">*</small>              
                        <div class="availdate">
                            <ejs-datetimepicker id="schedule_time" placeholder="mm-dd-yyyy, --:-- --" :value="schedule_time" :showClearButton='false' :format="dateFormat" @change="scheduleDate" :step="starttime_step" :openOnFocus='true'></ejs-datetimepicker>
                            <span class="d-none text-danger d-block fs-14" id="event_time_error">Please select Event Start Date</span>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="waves-effect waves-light btn btn-info" @click="saveworkflow">Save</button>
                    <button type="button" class="waves-effect waves-light btn btn-danger" data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
    <!--Workflows modal end--> 
</template>
<script>
    import axios from "@/scripts/axios.js";
    import moment from "moment";
    import { SmartTagz } from "smart-tagz";
    import "smart-tagz/dist/smart-tagz.css";
    import submitMixins from "@/scripts/mixins/submitMixins.js";
    import useVuelidate from "@vuelidate/core";
    import {
      required,
      helpers,
      minLength,
      maxLength,
      requiredIf,
    } from "@vuelidate/validators";
    export default {
      mixins: [submitMixins],
      setup() {
        return { v$: useVuelidate() };
      },
      data() {
        return {
          form :{
            first_name : '',
            last_name : '',
          },
          dob:'',
          taglist:{},
          defaulttaglist:[],
          key:0,
          workflowlist:{},
          Workflows_id:'',
          schedule_time : '',
          dateFormat : 'MM-dd-yyyy hh:mm a',
          event_time:'',
          starttime_step:15,  
          patientworkflow:[],
          send_form : { 
            patient_id : '',
            message_type : '',
            message_subject : '',
            message_content : '',
          },
          current_nav_tab  : '',
          nav_tab_items: [
              {
                  id: "0",
                  title: "SMS",
                  content: "SMS",
                  ref: "sms_tab",
                  type: "sms",
                  is_hidden: false,
              },
              {
                  id: "1",
                  title: "Email",
                  content: "Email",
                  ref: "email_tab",
                  type: "email",
                  is_hidden: false,
              }
          ],
          message_content_error : '',
          message_subject_error : '',
          chat_message_history : [],
          attach_file:[],
          insertImageSettings:{
            path: process.env.VUE_APP_API_BASE_URL.substr(0,process.env.VUE_APP_API_BASE_URL.lastIndexOf('/'))+"/storage/attach_file/",
            saveUrl: process.env.VUE_APP_API_BASE_URL+"/support/file/store",
            display: 'inline'
          },
          toolbarSettings: {
            type: 'MultiRow',
            items: ['Bold', 'Italic', 'Underline', 'StrikeThrough','FontName', 'FontSize','Formats', 'Alignments', 'OrderedList', 'UnorderedList','Outdent', 'Indent','CreateLink', 'Image', 'ClearFormat','Undo', 'Redo',{
              tooltipText: 'Insert Symbol',
              undo: true,
              click: this.cutomFieldClick.bind(this),
              template: `<select id="customTool" class="customfill">
              <option value="">Custom Fields</option>
              <option value="{{patient.full_name}}">Full Name</option>
              <option value="{{patient.first_name}}">First Name</option>
              <option value="{{patient.last_name}}">Last Name</option>
              <option value="{{patient.email}}">Email</option>
              <option value="{{patient.cell_phone}}">Cell Phone</option>
              <option value="{{patient.date_of_birth}}">Date Of Birth</option>
              </select>`,
    
            }]
          },
          pasteCleanupSettings: {
            prompt: true,
            plainText: false,
            keepFormat: false,
            deniedTags: ['a'],
            deniedAttrs: ['class', 'title', 'id'],
            allowedStyleProps: ['color', 'margin', 'font-size']
          },
          strict: true,
          dateDOBFormat: 'MM-dd-yyyy',
          date_of_birth :'',
          maxVal: new Date(),
          errors: null,
        }
      },
      components: {
        SmartTagz,
      },
      mounted() {},
      created() {
        this.getContactInfo();
      },
      validations() {
        return {
          form: {
            first_name: {
              required: helpers.withMessage("First name is required.", required),
            },
            last_name: {
              required: helpers.withMessage("Last name is required.", required),
            },
            email: {
              required: helpers.withMessage("Email is required.", required),
            },
            cell_phone: {
              required: helpers.withMessage("Cell phone is required.", required),
              min: helpers.withMessage("Cell phone should be at least 10 long.", minLength(14))
            },
            date_of_birth: {
              required: helpers.withMessage("DOB is required.", required),
            }
          },
        };
      },
      methods:{
        getdate($event){
            if($('#date_of_birth').val() == '' && this.form.date_of_birth == '' ) {
              this.form.date_of_birth = null;
            } else {      
              this.form.date_of_birth = moment($event.value).format('YYYY-MM-DD');
            }  
        },
        getContactInfo() {
            axios.get("/crm/contact-details/"+this.$route.params.id)
            .then(response => {
              this.form = response.data.data;
              for (var i=0; i < this.nav_tab_items.length; i++) {
                    if (this.nav_tab_items[i].type == 'sms')  {
                        this.nav_tab_items[i].is_hidden =  this.form.cell_phone ? false : true;
                    }
                    if (this.nav_tab_items[i].type == 'email') {
                        this.nav_tab_items[i].is_hidden = this.form.email ? false : true;
                    }
                    if(this.current_nav_tab === '' && this.nav_tab_items[i].is_hidden == false) {
                        this.current_nav_tab = i;
                        this.send_form.message_type = this.nav_tab_items[i].type;
                    }
              }
              this.send_form.patient_id = response.data.data.id;
              this.date_of_birth = moment(response.data.data.date_of_birth).format('MM-DD-YYYY') ;
              if (response.data.data.crm_dnd == 0) {
                    $('#dnd').removeClass('active');
              } else {
                    $('#dnd').addClass('active'); 
              }
              axios.get("/crm/workflow/list")
              .then(response => { 
                this.workflowlist = response.data.data;
              });
              
              this.chatMessageHistoryByPatient(this.form.id);
              this.getPatientWorkFlow(this.form.id);
              if (response.data.data.crm_tags) {
                    this.defaulttaglist = response.data.data.crm_tags.split(',');
              }
              this.key++;
            })
        },
        scrollChatToBottom() {
            setTimeout(() => {
                var chatDiv = document.getElementById("chatDiv");
                chatDiv.scrollTop = chatDiv.scrollHeight;
                if(this.$route.params.section=='Email'){
                    document.getElementById("tab_nav"+this.$route.params.section).click();
                }
            },1000);
        },
        cutomFieldClick: function(th) {
            // console.log(th);
            this.$refs.editorObj.ej2Instances.executeCommand('insertText', $('#customTool').val() , {undo: true});
        },
        imageRemoving(args){
            var token = localStorage.getItem("authToken");
            var xmlhttp = new XMLHttpRequest();
            xmlhttp.open('POST', process.env.VUE_APP_API_BASE_URL+"/support/file/remove");
            xmlhttp.setRequestHeader("Authorization", `Bearer ${token}`);
            xmlhttp.setRequestHeader("File-Name", this.file_name);
            xmlhttp.send(null);
        },
        afterImageDelete(args){
            this.file_name = args.src.substring(args.src.lastIndexOf('/') + 1);
            this.imageRemoving(null);
        },
        imageUploading(args) {
          var token = localStorage.getItem("authToken");
          this.file_name = Date.now()+"_"+(Math.random() + 1).toString(36).substring(7)+"."+args.fileData.type;
          args.fileData.name = this.file_name;
          args.fileData.id=this.file_name;
          args.currentRequest.setRequestHeader("Authorization", `Bearer ${token}`);
          args.currentRequest.setRequestHeader("File-Name", this.file_name);
        },
        getPatientWorkFlow(patient_id)
        {
            axios.post("/crm/workflow/patient-workflow-list",{'id' : patient_id})
            .then(response => { 
              this.patientworkflow = response.data.data;
            });
        },
        chatMessageHistoryByPatient(patient_id) {
            axios.post("/crm/chatMessageHistoryByPatient",{ 'patient_id' : patient_id })
            .then(response => {
                this.chat_message_history = response.data.data;
                this.scrollChatToBottom();
            });
        },
        sendMessage() {
            this.message_content_error = '';
            this.message_subject_error = ''
            if (this.send_form.message_type == 'email') {
                this.send_form.message_content = this.$refs.editorObj.ej2Instances.value;
            }
    
          if (this.send_form.message_type == 'email' && !this.send_form.message_subject) {
                this.message_subject_error = "Subject is required.";
          }
          if (!this.send_form.message_content) {
                this.message_content_error = "Message is required.";
          }
        //   console.log(this.send_form);
          if (!this.message_content_error && !this.message_subject_error) {
              this.$store.state.loader = true;
              axios.post("/crm/send-message",this.send_form)
              .then(response => { 
                this.$store.state.loader = false;
                this.send_form.message_subject = '';
                this.send_form.message_content = '';
                this.$refs.editorObj.ej2Instances.value = '';
                this.moshaToast('Message sent successfully.', "success");
                this.chatMessageHistoryByPatient(this.send_form.patient_id);
              }).catch((error) => {
                this.$store.state.loader = false;
                this.moshaToast(error.response.data.message, "danger");
            });
          }
        },
        changeMsgTab(item) {
            this.$route.params.section = null;
            this.send_form.message_type = item.type;
            this.current_nav_tab = item.id;
            this.$refs.editorObj.refreshUI();
            this.scrollChatToBottom();
        },
        onTagChanged(obj) {
          this.defaulttaglist = obj;
        },
        save(){
            this.v$.$validate();
            if (!this.v$.$error) {
              this.form.crm_tags = this.defaulttaglist.toString();
              this.form.crm_dnd = $('#dnd').hasClass('active');
              this.$store.state.loader = true;
              axios.post("/crm/contacts-update",this.form)
              .then(response => {
                this.$store.state.loader = false;
                this.getContactInfo();
                this.moshaToast('Record updated successfully.', "success");
              });
            }
        },
        scheduleDate(e){
            // console.log(e.value);
          if(e.event){
            this.schedule_time = moment(e.value).format('MM-DD-YYYY hh:mm a');
            this.event_time = moment(e.value).utc().format('YYYY-MM-DD hh:mm');
            $("#event_time_error").addClass('d-none');
          }
        },
        change_workflow(){
          $("#workflow_error").addClass('d-none');
        },
        saveworkflow(){
          var workflow_error = true;
          if(this.Workflows_id == ''){
            workflow_error = false;
            $("#workflow_error").removeClass('d-none');
          }
          if(this.event_time == ''){
            workflow_error = false;
            $("#event_time_error").removeClass('d-none');
          }
          if(workflow_error == true){
            this.form.Workflows_id = this.Workflows_id;
            this.form.event_time = this.event_time;
            this.$store.state.loader = true;
            axios.post("/crm/workflow/patient-workflow-store",this.form)
            .then(response => { 
              this.$store.state.loader = false;
              this.moshaToast('Record updated successfully.', "success");
              this.getPatientWorkFlow(this.form.id);
            })
              this.Workflows_id = '';
              this.event_time = '';
              this.schedule_time = '';
              $('#AddWorkFlowModal').modal('hide');
          }
        },
        removeworkflow(id){
          this.$swal
            .fire({
              title: '<h2 class="mt-0">Are you sure?</h2>',
              text:
                "You want to delete these records? This process cannot be undone.",
              icon: "warning",
              dangerMode: true,
              showCancelButton: true,
              confirmButtonColor: "#ee3158",
              cancelButtonColor: "#bdbdbd",
              confirmButtonText: "Yes, delete it!",
            })
            .then((result) => {
              if (result.isConfirmed == true) {
                axios
                  .post("crm/workflow/patient-workflow-delete", {
                    id: id,
                  })
                  .then((response) => {
                    this.$swal
                      .fire(
                        "Deleted!",
                        "Your Workflows has been deleted.",
                        "success"
                      )
                      .then((result) => {
                        if (result.isConfirmed == true) {
                            this.getPatientWorkFlow(this.form.id);
                        }
                      });
                  });
              }
            });
        }
      }
    }
</script>
<style type="text/css">
    .e-richtexteditor.e-rte-tb-expand .e-rte-content, .e-richtexteditor.e-rte-tb-expand .e-source-content {
    margin-top: 75px !important;
    }
    .e-rte-content {
    height: auto;
    /*margin-top: 100px !important;*/
    }
</style>