<template>
  <div class="main-div">
    <form action="" novalidate="" class="ais-SearchBox-form">
      <input type="text" autocorrect="off" autocapitalize="off" autocomplete="off" v-model="searchQuery" spellcheck="false" required="" maxlength="512" aria-label="Search" placeholder="Didn't find your Article? Try to search more" class="ais-SearchBox-input">
      <button type="reset" title="Clear" class="ais-SearchBox-reset">
        <svg role="img" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 20 20" class="ais-SearchBox-resetIcon">
          <path d="M8.114 10L.944 2.83 0 1.885 1.886 0l.943.943L10 8.113l7.17-7.17.944-.943L20 1.886l-.943.943-7.17 7.17 7.17 7.17.943.944L18.114 20l-.943-.943-7.17-7.17-7.17 7.17-.944.943L0 18.114l.943-.943L8.113 10z" fillRule="evenodd"></path>
        </svg>
      </button>
    </form>
    <ul v-if="searchResults && searchResults.length" class="custom-ul">
      <li v-for="result in searchResults" :key="result.id">
        <div class="articlelist px-2">
          <a :href="result.portalUrl" target="_blank" class="d-block bb-1 border-secondary py-2 fs-16">{{result.title}}</a>
        </div>
      </li>
    </ul>
    <p v-else class="fs-14">{{ searchQuery.length > 2 ? noRes : ""}}</p>
  </div>
</template>

<script>
import axios from "@/scripts/axios.js";

export default {
  data() {
    return {
      searchQuery: '',
      searchResults: '',
      path: process.env.VUE_APP_KB_DOCUMENT,
      noRes: 'No search results found.'
    };
  },
  watch: {
    searchQuery(newVal){
      this.searchQuery = newVal;
      this.searchArticles();
    }
  },
  methods: {
    searchArticles() {
      this.noRes = '';
      const url = 'get_zoho_kb';
      const query = this.searchQuery;
      const limit = 10;

      const params = {
        query: query,
        limit: limit
      };

      axios.get(url, { params })
        .then(response => {
          this.noRes = 'No search results found.';
          this.searchResults = response.data;
        })
        .catch(error => {
          this.noRes = 'No search results found.';
          console.error(error);
        });
    }
  }
};
</script>
<style>
.custom-ul {
  background: #fff;
  list-style: none;
  padding-left: 0px;
  margin-top: 10px;
  border-radius: 5px;
}

.main-div{
  padding: 10px;
  padding-bottom: 1px;
  background: #e4e6ef;
  border-radius: 5px;
}

input{
  border-radius: 5px;
}

.articlelist a{
  color: #03183e;
}

.articlelist a:hover {
  color: #0265a0;
  text-decoration: underline;
}
.ais-SearchBox-form:before {
  background: transparent url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20stroke%3D%22%235a5e9a%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%3E%3Ccircle%20cx%3D%2211%22%20cy%3D%2211%22%20r%3D%228%22%3E%3C%2Fcircle%3E%3Cline%20x1%3D%2221%22%20y1%3D%2221%22%20x2%3D%2216.65%22%20y2%3D%2216.65%22%3E%3C%2Fline%3E%3C%2Fsvg%3E") repeat scroll 0 0;
    content: "";
    height: 1rem;
    left: 1rem;
    margin-top: -.5rem;
    position: absolute;
    top: 50%;
    width: 1rem;
}
.ais-SearchBox-form {
  background-color: #fff;
  display: flex;
  font-size: .875rem;
  height: 2.5rem;
  line-height: 1.25rem;
  position: relative;
  width: 100%;
}
.ais-SearchBox-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  border: 1px solid #d6d6e7;
  border-radius: 3px;
  box-shadow: inset 0 1px 4px 0 rgba(119,122,175,.3);
  caret-color: #5a5e9a;
  color: #23263b;
  flex: 1 1 0%;
  font: inherit;
  max-width: 100%;
  padding-left: 2.5rem;
  font-size: 16px !important;
  outline: none;
}
.ais-SearchBox-input:focus { border-color: #3c4fe0; box-shadow: 0 1px 0 0 rgba(35,38,59,.05);outline: medium none currentcolor;}
.ais-SearchBox-loadingIndicator, .ais-SearchBox-reset {
  align-items: center;
  border-radius: 50%;
  display: flex;
  fill: #484c7a;
  height: 20px;
  justify-content: center;
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  padding: 0;
  overflow: visible;
  font: inherit;
  line-height: normal;
  color: inherit;
  background: none;
  border: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

button, select{
  text-transform: none;
}
</style>