import {
  helpers,
  required,
  email,
  minLength,
  maxLength,
  sameAs,
  numeric,
} from "@vuelidate/validators";

const SignedConsentForms = {
  namespaced: true,

  state: {
    tableData: {
      header: [
        "Consent Form",
        "Appointment Date",
        "Date of Signature",
        "Actions",
      ],
    },
    form: {},
    listUrl: "/patient/:patient_unique_id/documents/signed-consent-form",
    list: [],
  },
  getters: {
    getTableHeaders: (state) => state.header,

    getTableContents: (state) => state.list,
    getListURL: (state) => state.listUrl,
  },
  mutations: {},
  actions: {},
}

const unsignedConsentForms = {
  namespaced: true,

  state: {
    tableData: {
      header: [
        "Consent Form",
        "Appointment Date",
        "Actions",
      ],
    },
    form: {},
    listUrl: "/patient/:patient_unique_id/documents/signed-consent-form/sign",
    list: [],
  },
  getters: {
    getTableHeaders: (state) => state.header,

    getTableContents: (state) => state.list,
    getListURL: (state) => state.listUrl,
  },
  mutations: {},
  actions: {},
}

export {SignedConsentForms,unsignedConsentForms};
