<template>
  <div class="form-group row" :class="{ error: errors.length }">
    <label :class="labelClass"
      >{{ title }}
      <small v-show="isRequired" class="text-danger asterisksign"
        >*</small
      ></label
    >
    <div :class="divClass">
      <div class="controls">
        <textarea
          :id="id"
          name="textarea"
          class="form-control"
          :value="modelValue"
          required
          :placeholder="title"
          :rows="rows"
          autocomplete="chrome-off"
          @input="$emit('update:modelValue', $event.target.value)"
        ></textarea>
      </div>
      <div class="error-msg">
        <ul class="mb-0 p-0 fs-14">
          <li class="d-block" v-for="(error, index) of errors" :key="index">
            {{ error.$message }}
          </li>
        </ul>
      </div>
    </div>
    <div :class="noteClass">
      <small class="form-text text-muted" v-if="notes != ''">{{ notes }}</small>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    modelValue: {
      type: String,
      default: "",
    },
    errors: {
      type: Array,
      default: [],
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    labelClass: {
      type: String,
      default:
        "col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xs form-label text-sm-end",
    },
    divClass: {
      type: String,
      default: "col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3",
    },
    noteClass: {
      type: String,
      default: "col-12 col-sm-8 col-md-8 offset-sm-4 offset-lg-0 col-lg-4 col-xl-5",
    },
    notes: {
      type: String,
      default: "",
    },
    rows: {
      type: String,
      default: "2",
    },
    id: {
      type: String,
    },
  },
};
</script>
