<template>
    <div class="mdc-tab">
        <div class="tab-content">
            <div class="tab-pane active" id="billingSummary" role="tabpanel">
                <div class="box box-solid mb-0">
                    <div class="box-header with-border bg-secondary">
                        <h2 class="black-color m-0 fs-20" id="creditpayment">Payment</h2>
                    </div>
                    <div class="box-body text-dark">
                        <form @submit.prevent id="paymentForm" method="post">
                            <div style="margin-bottom: 10px; color: #c0392b;" v-if="serverError">{{ serverError }}</div>
                            <div class="form-group">
                                <label for="name" class="form-label">Received Amount</label>
                                <div class="input-group">
                                    <span class="input-group-addon">$</span>
                                    <input type="text" class="form-control" :readonly="true" v-model="refund_frm.charge_amount" placeholder="Paid Amount"/>
                                    <div
                                        class="invalid-feedback"
                                        style="display:block;"
                                        v-for="(error, index) of v$.refund_frm.charge_amount.$errors"
                                        :key="index"
                                        >
                                        {{ error.$message }}
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="form-label">Refund Amount</label>
                                <div class="input-group">
                                    <span class="input-group-addon">$</span>
                                    <input type="text" class="form-control" v-model="refund_frm.refund_amount" readonly="true" placeholder="Enter Amount"  />
                                    <div
                                        class="invalid-feedback"
                                        style="display:block;"
                                        v-for="(error, index) of v$.refund_frm.refund_amount.$errors"
                                        :key="index"
                                        >
                                        {{ error.$message }}
                                    </div>
                                    <div class="invalid-feedback" style="display:block;">{{refund_amt_error}}</div>
                                </div>
                            </div>
                        </form>
                        <button v-if="permissioncheck(this.$store.state.permission.create_update)" type="button" :disabled="!this.contractSubId && !this.chargeAmount" class="waves-effect waves-light btn  mb-5 me-1 waves-effect waves-light btn btn-info me-1" @click="handlePaymentPay">Refund</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="tab-pane animation-fade active" id="category-1" role="tabpanel">
            <div class="panel-group panel-group-simple panel-group-continuous mb-0" id="accordion2" aria-multiselectable="true" role="tablist">
            </div>
        </div>
    </div>
</template>
<script>
    import permissionMixins from "@/scripts/mixins/permission.js";
    import axios from "@/scripts/axios.js";
    import submitMixins from "@/scripts/mixins/submitMixins.js";
    import useVuelidate from "@vuelidate/core";
    import {
    	required,
    	helpers,
    	minLength,
    	maxLength,
    	numeric,
    	requiredIf,
    } from "@vuelidate/validators";
    export default {
    	props: {
            contractSubId: {
              type: Number,
              required: true
            },
            chargeAmount: {
              type: Number,
              required: true
            },
        },
    	setup() {
    		return { v$: useVuelidate() };
    	},
    	mixins: [submitMixins,permissionMixins],
    	data() {
    		return {
    			refund_frm : {
    				contract_sub_id : this.contractSubId,
    				charge_amount : this.chargeAmount,
                    refund_amount : this.chargeAmount,
    				patient_id : this.$store.state.patientDetail.patient_id,
    				patient_unique_id : this.$store.state.patientDetail.patient_unique_id,
    			},
    			serverError: '',
    			refund_amt_error: '',
    		};
    	},
    	validations() {
    		return {
    			refund_frm: {
    				refund_amount: {
    					required: helpers.withMessage("Refund amount is required.", required),
    				},
    				charge_amount: {
    					required: helpers.withMessage("Amount is required.", required),
    				}
    			},
    		};
    	},
    	async created() {
    	},
    	methods: {
    		resetForm : function() {
    			this.v$.$reset();
    			this.refund_frm.charge_amount = '';
                this.refund_frm.refund_amount = '';
    		},
    		handlePaymentPay: function() {
    			this.v$.$validate();
    			if (!this.v$.$error) {
                   this.serverError = '';
    				this.refund_amt_error = '';
                    if (this.refund_frm.refund_amount > this.refund_frm.charge_amount) {
                        this.refund_amt_error = "Refund amount not allowed greater than charged amount.";
                        return false;
                    }
                        this.$store.state.loader = true;
                        this.$store.state.patientDetail.renderComponent = false;
                        axios.post("contract-billing/patientContractRefund", this.refund_frm)
                        .then((response) => {
                            this.$store.state.patientDetail.renderComponent = true;
                            this.$store.state.PaymentGatewayMigration.hidemodal = true;
                            this.$store.state.loader = false;
                            this.moshaToast(response.data.message, "success");
                        })
                        .catch((error) => {
                            this.$store.state.loader = false;
                            this.serverError = error.response.data.message;
                        });
    			}
    		}
    	}
    }
</script>