<template>
	<div class="mt-10" v-if="this.patinet_ticket == 0">
		<div class="container-fluid">
			<div class="text-center">
				<!-- <h1 class="heading1 font-bold mt-0 mb-1">Suarez Health Medical</h1> -->
				<img src="images/shm-logo-new.png" alt="PureEHR" title="PureEHR" class="mb-10 my-10 w-120" />
				<p class="fw-900 fs-18">Covid Examination Form</p>
			</div>
		</div>
	</div>
	<div class="content-wrapper mt-0">
		<ul  v-if="this.patinet_ticket == 0">
			<li>Please don't use the front camera to take pictures.</li>
			<li>Please don't auto-fill data. Instead, type it manually.</li>
		</ul>
		<section class="content" v-if="this.patinet_ticket == 0">
			<div class="box">
				<div class="box-body">
					<form novalidate>
						<div class="row">
							<div class="col-12 col-sm-6 col-md-4">
								<input-field
								title="First Name"
								:isRequired="true"
								v-model="v$.form.first_name.$model"
								:errors="v$.form.first_name.$errors"
								labelClass ="col-12 col-sm-12 col-md-12"
								divClass ="col-12 col-sm-12 col-md-12">
							</input-field>
						</div>

						<div class="col-12 col-sm-6 col-md-4">
							<input-field
							title="Middle Name"
							v-model="v$.form.middle_name.$model"
							:errors="v$.form.middle_name.$errors"
							labelClass ="col-12 col-sm-12 col-md-12"
							divClass ="col-12 col-sm-12 col-md-12">
						</input-field>
					</div>

					<div class="col-12 col-sm-6 col-md-4">
						<input-field
						title="Last Name"
						:isRequired="true"
						v-model="v$.form.last_name.$model"
						:errors="v$.form.last_name.$errors"
						labelClass ="col-12 col-sm-12 col-md-12"
						divClass ="col-12 col-sm-12 col-md-12">
					</input-field>
				</div>

				<div class="col-12 col-sm-6 col-md-4">
					<div class="form-group" :class="{ error: v$.form.date_of_birth.$errors.length }">
						<label>DOB</label> <small class="text-danger asterisksign">*</small>
						<div class="availdate">
							<ejs-datepicker 
							:value="date_of_birth" 
							id="date_of_birth" 
							:format="dateFormat" 
							placeholder="MM-dd-yyyy" 
							name="date_of_birth" 
							:max="min_date"
							@change="getdate"
							:strictMode='true' 
							:showClearButton='false' 
							:openOnFocus='true'>
						</ejs-datepicker>
						<div class="error-msg">
							<ul class="mb-0 p-0 fs-14">
								<li class="d-block" v-for="(error, index) of v$.form.date_of_birth.$errors" :key="index">
									{{ error.$message }}
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>

			<div class="col-12 col-sm-6 col-md-4">
				<input-field
				title="Email Address"
				:isRequired="true"
				v-model="v$.form.email_address.$model"
				:errors="v$.form.email_address.$errors"
				labelClass ="col-12 col-sm-12 col-md-12"
				divClass ="col-12 col-sm-12 col-md-12">
			</input-field>
		</div>

		<div class="col-12 col-sm-6 col-md-4">
			<input-field
			title="Cell Phone"
			:isRequired="true"
			mask="(000) 000-0000"
			v-model="v$.form.phone_no.$model"
			:errors="v$.form.phone_no.$errors"
			labelClass ="col-12 col-sm-12 col-md-12"
			divClass ="col-12 col-sm-12 col-md-12">
		</input-field>
	</div>

	<div class="col-12 col-sm-4 col-md-4">
		<div class="form-group" :class="{ error: v$.form.sex.$errors.length }">
			<label class="form-label">Gender</label> <small class="text-danger asterisksign">*</small>
			<select class="form-control" v-model="form.sex">
				<option value="" selected="selected" disabled="disabled">Select Gender</option>
				<option value="Male">Male</option>
				<option value="Female">Female</option>
				<option value="Other">Other</option>
			</select>
			<div class="error-msg">
				<ul class="mb-0 p-0 fs-14">
					<li class="d-block" v-for="(error, index) of v$.form.sex.$errors" :key="index">
						{{ error.$message }}
					</li>
				</ul>
			</div>
		</div>
	</div>

	<div class="col-12 col-sm-6 col-md-4">
		<text-area 
			title="Address" 
			v-model="v$.form.address.$model" 
			:errors="v$.form.address.$errors" 
			:isRequired="true"
			:rows="1"
			labelClass ="col-12 col-sm-12 col-md-12"
			divClass ="col-12 col-sm-12 col-md-12">
		</text-area>
	</div>	

	<div class="col-12 col-sm-6 col-md-4">
		<div class="form-group row" :class="{ error: v$.form.country_id.$errors.length }">
			<label class="form-label col-12 col-sm-12 col-md-12">Country <small class="text-danger asterisksign">*</small></label>
        	<div class="col-12 col-sm-12 col-md-12">
                <Select2
                	v-model="v$.form.country_id.$model"
                  	id="country_id"
                  	placeholder="Select Country"
                  	:options="countries"
                  	@select="countryChange($event)"
                />
                <div class="invalid-feedback" style="display:block;" v-for="(error, index) of v$.form.country_id.$errors" :key="index">
                  	{{ error.$message }}
                </div>
          	</div>
        </div>
	</div>

	<div class="col-12 col-sm-6 col-md-4">
		<input-field
			title="Zipcode"
			:isRequired="true"
			@focusout="getzipcode" 
			mask="00000-0000"
			v-model="v$.form.zip_code.$model" 
			:errors="v$.form.zip_code.$errors" 
			labelClass ="col-12 col-sm-12 col-md-12"
			divClass ="col-12 col-sm-12 col-md-12">
		</input-field>
	</div>

	<div class="col-12 col-sm-6 col-md-4">
		<div class="form-group row" :class="{ error: v$.form.state_id.$errors.length }">
			<label class="col-12 col-sm-12 col-md-12">State<small class="text-danger asterisksign">*</small></label>
			<div class="col-12 col-sm-12 col-md-12">
				<Select2
					v-model="v$.form.state_id.$model"
					id="state_id"
					:errors="v$.form.state_id.$errors"
					placeholder="Select State"
					:options="subscriber_state_list"/>
				<div class="error-msg">
					<ul class="mb-0 p-0 fs-14">
						<li class="d-block" v-for="(error, index) of v$.form.state_id.$errors" :key="index">
							{{ error.$message }}
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>

	<div class="col-12 col-sm-6 col-md-4">
		<input-field 
			title="City" 
			v-model="v$.form.city.$model" 
			:errors="v$.form.city.$errors" 
			:isRequired="true"
			labelClass ="col-12 col-sm-12 col-md-12"
			divClass ="col-12 col-sm-12 col-md-12">	
		</input-field>
	</div>

	<div class="col-12 col-sm-6 col-md-4">
		<input-field
			title="Driver's License Number"
			v-model="v$.form.driver_license_number.$model" 
			:errors="v$.form.driver_license_number.$errors" 
			:isRequired="true"
			labelClass ="col-12 col-sm-12 col-md-12"
			divClass ="col-12 col-sm-12 col-md-12">
		</input-field>
	</div>

<div class="col-12 col-sm-6 col-md-4">
	<div class="form-group">
		<label class="form-label">Driver's License Expiry Date</label>
		<div class=" availdate">
			<ejs-datepicker
			id="driver_license_number" 
			placeholder="MM-DD-YYYY" 
			mask="00-00-0000"
			:value="driver_license_number" 
			:showClearButton='false' 
			:format="dateFormat" 
			@change="driver_license_number"
			:step="15" 
			:openOnFocus='true'>
		</ejs-datepicker>
	</div>
</div>
</div>

							<!-- <div class="col-12 col-sm-12 col-md-12">
								<check-box
									title=""
									v-model="form.insurance"
									@click="changeinsurance"
									label="I have Insurance"
									divClass="col-12 col-sm-12 col-md-12"
									labelClass="col-12 col-sm-12 col-md-12"
									>
								</check-box>
							</div> -->
						</div>

						<!-- <div class="row align-items-center" v-if="form.insurance == 1">
							<div class="col-12 col-sm-6 col-md-4">
								<div class="form-group row" :class="{ error: v$.form.insurance_company_id.$errors.length }">								
									<label class="col-12 col-sm-12 col-md-12">Insurance Name <small class="text-danger asterisksign">*</small></label>
									<div class="col-12 col-sm-12 col-md-12 availdate">
										<div class="selectcontrols">
											<Autocomplete max="30" 
												@input="getlist" 
												ref="autocomplete" 
												:results="insurance_list" 
												@onSelect="resultsvalue" 
												class="form-control ms-0 autosearch-list" 
												placeholder="Select insurance">                        
											</Autocomplete>		
										</div>
										<div class="error-msg">
											<ul class="mb-0 p-0 fs-14">
												<li class="d-block" v-for="(error, index) of v$.form.insurance_company_id.$errors" :key="index">
													{{ error.$message }}
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>

							<div class="col-12 col-sm-6 col-md-4">
								<input-field
									title="Insurance ID / Policy No"
									:isRequired="true"
									v-model="v$.form.insurance_id_number.$model"
									:errors="v$.form.insurance_id_number.$errors"
									labelClass ="col-12 col-sm-12 col-md-12"
									divClass ="col-12 col-sm-12 col-md-12">
								</input-field>
							</div>

							<div class="col-12 col-sm-6 col-md-4">
								<check-box
									title=""
									v-model="form.patient_is_subscriber"
									@click ="patientissubscriber"
									label="Subscriber is the Patient"
									divClass="col-12 col-sm-12 col-md-12"
									labelClass="col-12 col-sm-12 col-md-12"
									>
								</check-box>
							</div>
						</div>
						
						<div class="row" v-if="form.patient_is_subscriber == 0">
							<div class="col-12 col-sm-6 col-md-4">
								<div class="form-group row" :class="{ error: v$.form.subscriber_relation.$errors.length }">								
									<label class="col-12 col-sm-12 col-md-12">Patient relationship to the Subscriber <small class="text-danger asterisksign">*</small></label>
									<div class="col-12 col-sm-12 col-md-12 availdate">
										<select class="form-control me-2 h-36" id="insurance_select" v-model="form.subscriber_relation">
											<option value="" disabled>Select Patient relationship to the Subscriber</option>
											<option value="1">Self</option>
											<option value="2">Spouse</option>
											<option value="3">Son</option>
											<option value="4">Daughter</option>
											<option value="5">Other</option>
										</select>									
										<div class="error-msg">
											<ul class="mb-0 p-0 fs-14">
												<li class="d-block" v-for="(error, index) of v$.form.subscriber_relation.$errors" :key="index">
													{{ error.$message }}
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>

							<div class="col-12 col-sm-6 col-md-4">
								<input-field
									title="Subscriber First Name"
									:isRequired="true"
									v-model="v$.form.subscriber_first_name.$model"
									:errors="v$.form.subscriber_first_name.$errors"
									labelClass ="col-12 col-sm-12 col-md-12"
									divClass ="col-12 col-sm-12 col-md-12">
								</input-field>
							</div>

							<div class="col-12 col-sm-6 col-md-4">
								<input-field
									title="Subscriber Middle Name"
									:isRequired="true"
									v-model="v$.form.subscriber_middle_name.$model"
									:errors="v$.form.subscriber_middle_name.$errors"
									labelClass ="col-12 col-sm-12 col-md-12"
									divClass ="col-12 col-sm-12 col-md-12">
								</input-field>
							</div>

							<div class="col-12 col-sm-6 col-md-4">
								<input-field
									title="Subscriber Last Name"
									:isRequired="true"
									v-model="v$.form.subscriber_last_name.$model"
									:errors="v$.form.subscriber_last_name.$errors"
									labelClass ="col-12 col-sm-12 col-md-12"
									divClass ="col-12 col-sm-12 col-md-12">
								</input-field>
							</div>

							<div class="col-12 col-sm-6 col-md-4">
								<text-area 
									title="Subscriber Address" 
									v-model="v$.form.subscriber_address.$model" 
									:errors="v$.form.subscriber_address.$errors" 
									:isRequired="true"
									:rows="1"
									labelClass ="col-12 col-sm-12 col-md-12"
									divClass ="col-12 col-sm-12 col-md-12">
								</text-area>
							</div>

							<div class="col-12 col-sm-6 col-md-4">
								<input-field 
									title="Subscriber Zipcode" 
									@focusout="subscriberzipcode" 
									v-model="v$.form.subscriber_zip_code.$model" 
									:errors="v$.form.subscriber_zip_code.$errors" 
									:isRequired="true"
									labelClass ="col-12 col-sm-12 col-md-12"
									divClass ="col-12 col-sm-12 col-md-12">
								</input-field>
							</div>

							<div class="col-12 col-sm-6 col-md-4">
								<input-field 
									title="Subscriber City" 
									v-model="v$.form.subscriber_city.$model" 
									:errors="v$.form.subscriber_city.$errors" 
									:isRequired="true"
									labelClass ="col-12 col-sm-12 col-md-12"
									divClass ="col-12 col-sm-12 col-md-12">
								</input-field>
							</div>

							<div class="col-12 col-sm-6 col-md-4">
								<div class="form-group row" :class="{ error: v$.form.subscriber_state_id.$errors.length }">
									<label class="col-12 col-sm-12 col-md-12">Subscriber State<small class="text-danger asterisksign">*</small></label>
									<div class="col-12 col-sm-12 col-md-12">
										<Select2
											v-model="v$.form.subscriber_state_id.$model"
											id="state_id"
											:errors="v$.form.subscriber_state_id.$errors"
											placeholder="Select State"
											:options="subscriber_state_list"/>
										<div class="error-msg">
											<ul class="mb-0 p-0 fs-14">
												<li class="d-block" v-for="(error, index) of v$.form.subscriber_state_id.$errors" :key="index">
													{{ error.$message }}
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div> -->

						<div class="row align-items-center">
							<div class="col-12 col-sm-6 col-md-4">
								<div class="form-group">
									<label class="col-12 col-sm-12 col-md-12 error">Capture Driver's License </label>
									<img :src="form.document" class="img-fluid rounded5 my-10" style="width:200px">
									<web-cam btnname="Click and Upload ID Document" imgname="document"></web-cam>
									OR
									<div class="col-12 col-sm-6 col-md-4 col-lg-6 col-xl-6">
										<div class="controls">
											<input type="file" id="filelicense" accept="image/png, .pdf, image/png" ref="filelicense" v-on:change="onChangeFileUpload('driverLicense')"/>
										</div>
									</div>
								</div>
							</div>
						</div>

						<!-- <div class="row align-items-center">
							<label class="form-label col-12 col-sm-3 col-md-3">Capture Driver's License <span class="text-danger">*</span></label>
							<div class="col-12 col-sm-6 col-md-4">
								<web-cam btnname="Click and Upload ID Document" imgname="document"></web-cam>
								<img :src="form.document" class="img-fluid rounded5 my-10" style="width:200px">
								<div class="error-msg">
									<ul class="mb-0 p-0 fs-14">
										<li class="d-block" v-for="(error, index) of v$.form.document.$errors" :key="index">
											{{ error.$message }}
										</li>
									</ul>
								</div>
							</div>
							 -->
							<!-- <div class="col-12 col-sm-6 col-md-4 text-center">
								<web-cam btnname="Click and Upload Insurance Front" imgname="insurancefront"></web-cam>
								<img :src="form.insurancefront" class="img-fluid rounded5 my-10" style="width:200px">
							</div>
							
							<div class="col-12 col-sm-6 col-md-4 text-center">
								<web-cam btnname="Click and Upload Insurance Back" imgname="insuranceback"></web-cam>
								<img :src="form.insuranceback" class="img-fluid rounded5 my-10" style="width:200px">
							</div> -->
						<!-- </div> -->
						<div class="row">
							<!-- <div class="col-12 col-sm-12 col-md-6 col-lg-6">
								<div class="form-group">
									<label class="form-label">I NEED TO BE TESTED FOR COVID-19 FOR THE Following REASONS</label>
									<select class="form-select" v-model="form.reasons_for_visit">
										<option selected="selected" disabled="disabled" value="">Select Reason For Visit</option>
										<option value="TRAVEL">TRAVEL</option>
										<option value="SCHOOL">SCHOOL</option>
										<option value="EVENT">EVENT</option>
										<option value="POSSIBLE EXPOSURE">POSSIBLE EXPOSURE</option>
										<option value="SYMPTOMS">SYMPTOMS</option>
									</select>
									<div class="error-msg">
										<ul class="mb-0 p-0 fs-14">
											<li class="d-block" v-for="(error, index) of v$.form.reasons_for_visit.$errors" :key="index">
												{{ error.$message }}
											</li>
										</ul>
									</div>
								</div>
							</div> -->

							<label class="form-label fw-900">I NEED TO BE TESTED FOR COVID-19 FOR THE  FOLLOWING REASONS : <small class="text-danger asterisksign">*</small></label>
							<div class="error-msg">
								<ul class="mb-0 p-0 fs-14">
									<li class="d-block" v-for="(error, index) of v$.form.reasons_for_visit.$errors" :key="index">
										{{ error.$message }}
									</li>
								</ul>
							</div>
							<div class="col-12 col-sm-6 col-md-6 col-lg-4">
								<check-box
									title=""
									v-model="this.travel"
									label="TRAVEL"
									divClass="col-12 col-sm-12 col-md-12"
									@click="changereason"
									labelClass="col-12 col-sm-12 col-md-12 "
									name="reason"
									value="TRAVEL"
								>
								</check-box>
								<check-box
									title=""
									v-model="this.school"
									label="SCHOOL"
									@click="changereason"
									divClass="col-12 col-sm-12 col-md-12"
									labelClass="col-12 col-sm-12 col-md-12 "
									name="reason"
									value="SCHOOL"
								>
								</check-box>
								<check-box
									title=""
									v-model="this.event"
									label="EVENT"
									@click="changereason"
									divClass="col-12 col-sm-12 col-md-12"
									labelClass="col-12 col-sm-12 col-md-12 "
									name="reason"
									value="EVENT"
								>
								</check-box>
								<check-box
									title=""
									v-model="this.exposure"
									label="POSSIBLE EXPOSURE"
									@click="changereason"
									divClass="col-12 col-sm-12 col-md-12"
									labelClass="col-12 col-sm-12 col-md-12 "
									name="reason"
									value="POSSIBLE EXPOSURE"	
								>
								</check-box>
								<check-box
									title=""
									v-model="this.symptom"
									label="SYMPTOMS"
									@click="changereason"
									divClass="col-12 col-sm-12 col-md-12"
									labelClass="col-12 col-sm-12 col-md-12"
									name="reason"
									value="SYMPTOMS"	
								>
								</check-box>
							</div>

						</div>
						<hr>
						<h3 class="font-bold">Reports and Rates</h3>
						<div class="row">
							<div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8">
								<label class="form-label fw-900">PCR Test :</label>
								<div class="row">
									<div class="col-12 col-sm-6 col-md-6 col-lg-4">
										<div class="form-group">

										<radio-button
											title=""
											v-model="form.is_rapid_pcr_test"
											label="Rapid PCR Test (3 hours)- $150"
											divClass="col-12 col-sm-12 col-md-12"
											id="is_rapid_pcr_test"
											labelClass="col-12 col-sm-12 col-md-12 "
											@click="rapidTestChange('is_rapid_pcr_test', $event)"
											>
										</radio-button>

										<radio-button
											title=""
											v-model="form.is_pcr_test"
											label="PCR Test (48 hours) - $90"
											id="is_pcr_test"
											divClass="col-12 col-sm-12 col-md-12"
											labelClass="col-12 col-sm-12 col-md-12 "
											@click="rapidTestChange('is_pcr_test', $event)"
											:errors="v$.form.pcr_test.$errors" 
											>
										</radio-button>

										<!-- <input type="radio" v-model="form.is_rapid_pcr_test" id="is_rapid_pcr_test" name="filter_list" value="true" @change="filterchange('is_rapid_pcr_test')" class="filled-in chk-col-info"/>
										<label for="is_rapid_pcr_test" class="fs-16">Rapid PCR Test (3 hours)- $150</label> -->
										</div>
										<!-- <div class="form-group">
											<input type="radio" v-model="form.is_pcr_test" id="is_pcr_test" name="is_pcr_test" value="true" @change="filterchange('is_pcr_test')" class="filled-in chk-col-info"/>
											<label for="is_pcr_test" class="fs-16">PCR Test (48 hours) - $90</label>
										</div> -->
									</div>

									<!-- <div class="col-12 col-sm-6 col-md-6 col-lg-7">
										<input type="radio" v-model="form.is_pcr_test" id="is_pcr_test" name="is_pcr_test" value="true" @change="filterchange('is_pcr_test')" class="filled-in chk-col-info"/>
										<label for="is_pcr_test">PCR Test (48 hours) - $90</label>
									</div> -->
								</div>
								<div class="row">
									<div class="col-12 col-sm-6 col-md-6 col-lg-6">
										<label class="form-label fw-900">Other Test :</label>
										<check-box
											title=""
											v-model="form.is_rapid_antigen_test"
											label="Rapid Antigen Test - $40"
											divClass="col-12 col-sm-12 col-md-12"
											labelClass="col-12 col-sm-12 col-md-12 "
											@click="finaltotal"
											>
										</check-box>

										<check-box
											title=""
											v-model="form.is_antibody_test"
											label="Antibody Test -$40"
											divClass="col-12 col-sm-12 col-md-12"
											labelClass="col-12 col-sm-12 col-md-12 fs-14"
											@click="finaltotal"
											>
										</check-box>

								</div>
						</div>
					</div>
				</div>
				

				<hr>
				<div class="row">
					<div class="col-12 col-sm-6 col-md-4">
								<input-field
								@focusout="checkRefferedBy"
								title="Referred By"
								v-model="form.referred_by_code"
								labelClass ="col-12 col-sm-12 col-md-12"
								maxlength="200"
								divClass ="col-12 col-sm-12 col-md-12">
							</input-field>
						</div>
				</div>
				<div style="display:flex;"><h3 class="font-bold">Payment</h3>  <span style="color:green;margin-top: 10px;">&nbsp; (Amount To Be Paid : <span v-if="this.payment_frm.discount_pr > 0 "><s>${{ this.payment_frm.paid_amount }}</s>&nbsp; <b>${{ this.payment_frm.discounted_paid_amount }}</b> </span><span v-else> <b>${{ this.payment_frm.paid_amount }}</b> </span>)</span></div>
				<div class="row">
					
					<div class="col-12 col-sm-12 col-md-6 col-lg-6" v-show="(this.payment_frm.discount_pr != 100 && this.total>0)">
						<!-- iframe start-->
						<div class="form-group">
							<div style="display: none" id="customStyles">
								body{margin:0;}
								iframe{height: 100px;}
								#payment-form {border: 1px solid rgb(212, 221, 230);max-height: 130px;width: 100%;padding: 20px 10px;border-radius: 4px;margin: 0px;background: rgb(245, 248, 250);}
								#payment-form {color: #2d3c58;}
								#payment-form .input-label {color: #2d3c58;border-top: 1px solid #d4dde6;font-size:13px;text-align: center !important;}
							</div>
							<div id="card" style="height: 130px;"></div>
							<div id="errorMessage" style="margin-bottom: 8px; color: #c0392b;"></div>
						</div>
						<!-- iframe end-->
					</div>
				</div>
			</form>
		</div>
	</div>

	


	<!-- <div v-for="index in notes" v-bind:id="'notes'+index"></div> -->

	<div class="row">
				<!-- <div class="col-12 col-sm-12 col-md-12">
					<check-box
						title=""
						v-model="form.terms0"
						label="I acknowledge that I have answered these questions truthfully to the best of my knowledge"
						divClass="col-12 col-sm-12 col-md-12"
						labelClass="col-12 col-sm-12 col-md-12"
						:isRequired="true"
						>
					</check-box>
				</div> -->
				<h3 class="font-bold">Acknowledgement and Authorization to Test :</h3>
				<div class="col-12 col-sm-12 col-md-12">
					<check-box
					title=""
					v-model="form.terms1"
					label="I acknowledge that I have answered these questions truthfully to the best of my knowledge.I additionally provide consent for testing and reporting of results by electronic and telephonic means, including as a parent or legal guardian to minors that I may have registered."
					divClass="col-12 col-sm-12 col-md-12"
					labelClass="col-12 col-sm-12 col-md-12"
					:isRequired="true">
				</check-box>
			</div>
			<!-- <div class="col-12 col-sm-12 col-md-12">
				<check-box
				title=""
				v-model="form.disclaimer"
				label="I additionally provide consent for testing and reporting of results by electronic and telephonic means, including as a parent or legal guardian to minors that I may have registered."
				divClass="col-12 col-sm-12 col-md-12"
				labelClass="col-12 col-sm-12 col-md-12"
				:isRequired="true">
			</check-box>
		</div> -->
				<!-- <div class="col-12 col-sm-12 col-md-12">
					<check-box
						title=""
						v-model="form.terms2"
						label="I understand that this specimen must be shipped the same day it is collected, otherwise, the lab is not permitted by the FDA to process this test."
						divClass="col-12 col-sm-12 col-md-12"
						labelClass="col-12 col-sm-12 col-md-12"
						:isRequired="true">
					</check-box>
				</div> -->

			</div>
			<div class="text-center">
				<a v-if="form.terms1 == 1" href="javascript:void(0)" class="waves-effect waves-light btn btn-info l-h-n py-10 px-20 fs-22" id="form" @click="save()">Submit</a>
				<a v-else href="javascript:void(0)" class="waves-effect waves-light btn btn-info l-h-n py-10 px-20 fs-22 disabled" id="form" @click="save()">Submit</a>
			</div>
		</section>
		<div class="d-flex align-items-center" style="min-height: 620px;">
			<div class="box" v-if="this.patinet_ticket != 0">
				<div class="box-body text-center">
					<h1>{{this.card_status}}</h1>
					<h1>
						Your covid examination token number is <b>{{this.patinet_ticket}}</b>. Please provide this token number to medical staff.
					</h1>
				</div>
			</div>
		</div>
	</div>

	


	<!--model start-->
	<div id="MyPatientModal" class=" MyPatientModal modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
		<div class="modal-dialog modal-lg">
			<div class="modal-content">
				<div class="modal-header">
					<h3 class="modal-title fw-900 mt-0">Patient List</h3>
				</div>
				<div class="modal-body">
					<div class="table-responsive">
						<table class="table">
							<thead>
								<tr>
									<th>Patient Name</th>
									<th>Email</th>
									<th>Action</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(item,index) in patinet_list">
									<td>{{item.first_name}} {{item.last_name}}</td>
									<td>{{item.email}}</td>
									<td><a @click="get_patient(index)" href="javascript:void(0)" class="waves-effect waves-light btn btn-info">Select</a></td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="modal-footer">
					<a href="javascript:void(0)" class="waves-effect waves-light btn btn-danger" @click="close"> Close </a>
				</div>
			</div>
		</div>
	</div>
	<!--model end-->
</template>
<script>
	import {helpers,required,} from "@vuelidate/validators";
	import InputField from "@/components/base/formFields/InputField.vue";
	import CheckBox from "@/components/base/formFields/checkBox.vue";
	import RadioButton from "@/components/base/formFields/radioButton.vue";
	import TextArea from "@/components/base/formFields/textArea.vue";
	import Select2 from "@/components/base/formFields/select2.vue";
	import WebCam from "@/views/CovidExams/Camera.vue";
	import axios from "@/scripts/axios.js";
	import submitMixins from "@/scripts/mixins/submitMixins.js";
	import useVuelidate from "@vuelidate/core";
	import moment from "moment";
	import Autocomplete from "vue3-autocomplete";
	//import $ from 'jquery';
	
	export default {
		mixins: [submitMixins],
		setup: () => {
			return { v$: useVuelidate() };
		},

		computed: {
			form() {
				return this.$store.state.CovidExams.form;
			},
			vuelidateExternalResults() {
				return this.$store.state.CovidExams.vuelidateExternalResults;
			},
			validationRules() {
				return this.$store.state.CovidExams.validationRules;
			}
		},

		validations() {
			return this.$store.state.CovidExams.validationRules;
		},

		components: {
			InputField,
			WebCam,
			CheckBox,
			RadioButton,
			TextArea,
			Select2,
			Autocomplete,
		},

		data() {
			return {
				card_status:'',
				travel:0,
				school:0,
				event:0,
				exposure:0,
				symptom:0,
				countries: [],
				total:0,
				submission:[],
				patinet_ticket: 0,
				notes: '',
				/*noteData: [{
					template_detail_data: [{
						id: '',
						template_id: '',
						name: '',
						category: '',
						patient_id: '',
						appointment_id: '',            
						is_included:'',
					}],
					components: [],
				}],*/
				payment_frm : {
					payment_type : 'Credit Card',
					basic_amount : 0,
					discount_amount : 0,
					payable_amount : 0,
					paid_amount : 5,
					check_trace : '',
					other_payment_type : '',
					cctoken_id : 'addNew',
					use_credidcard_for_future : true,
					patient_id : '',
					clinic_id:'',
					discounted_paid_amount: '',
					discount_pr: 0,
				},
				dateFormat: 'MM-dd-yyyy',
				date_of_birth:'',
				visitdateFormats : 'MM-dd-yyyy hh:mm a',
				appointmentprofiles:[],
				patinet_list:[],
				schedule_time : new Date(),
				insurance_list:[],
				min_date:"",
				subscriber_state_list:[],
				publicKey:'',
			}
		},

		mounted(){
			let bridgePayScript = document.createElement('script')
			bridgePayScript.setAttribute('src', process.env.VUE_APP_API_RECTANGLEHEALTH_TOKENPAY_URL)
			document.head.appendChild(bridgePayScript);
			this.form.sex ='';			
			this.form.insurance = 0;
			this.form.insurance_company_id ='';			
			this.form.patient_is_subscriber = 1;
			this.form.country_id = 231;
			this.form.subscriber_relation= '';
			this.form.document= '';
			this.form.terms0 = 0;
			this.form.terms1 = 0;
			this.form.terms2 = 0;
			this.form.schedule_time = moment(new Date()).format('YYYY-MM-DD hh:mm a');
			this.form.is_pcr_test = false;
			this.form.is_rapid_pcr_test = false;
			this.form.referred_by_code = '';
			this.min_date=new Date();
			this.min_date.setDate(this.min_date.getDate() - 2);
			/*if(process.env.VUE_APP_NODE_ENV == 'production'){
				this.total = 150;
			}
			else{
				this.total = 10;	
			}*/
			this.total = 0;	
			this.form.is_antibody_test =false;
			this.form.is_rapid_antigen_test =false;
			this.form.reasons_for_visit = '';
			this.form.schedule_time = moment(this.schedule_time).format('YYYY-MM-DD hh:mm a');
			this.getstate();
			//this.scheduleDate();
			this.filterchange();
			this.finaltotal();
		},

		beforeCreate(){
			this.$store.state.loader = true;
			this.$store.state.CovidExams.form = {};
			Object.assign(this.$store.state.CovidExams.form);
			var clinic_name = window.atob(this.$route.params.clinic_id);
			var office_id =window.atob(this.$route.params.office_id);
			axios.post("covid/clinic-info",{'clinic_id1':clinic_name,'office_id':office_id})
			.then((result) => { 
				this.form.clinic_id1 = window.atob(this.$route.params.clinic_id);
				this.form.provider_id = result.data.data.primary_provider_id;
				this.form.office_id = window.atob(this.$route.params.office_id);
				//this.getnote();
				this.publicKey = result.data.data.PUBLICKEY;
				let $this = this;
				setTimeout(function () {
					$this.initializeToken();
					$this.$store.state.loader = false;
				}, 3000);
			})
			.catch((error) => {
				this.$router.push(`/Unauthorized`);
			});

			axios.get("/covid/country")
				.then((response) =>
		        	(this.countries = response.data.data.map((country) => {
		          	return { id: country.id, text: country.name };
		        }))
	    	);

		},
		methods:{
			onChangeFileUpload(type){
				let reader = new FileReader;
				 if(type=="driverLicense"){
					if (this.$refs.filelicense&&this.$refs.filelicense.files[0]) {
						reader.onload = e => {
							
							this.$store.state.CovidExams.form.document = e.target.result;
						}
					}
					reader.readAsDataURL(this.$refs.filelicense.files[0]);
				}
			},
			rapidTestChange(option, $event){
				this.$store.state.CovidExams.form.pcr_test = true;
				if(option == 'is_rapid_pcr_test'){
					$("input[id='is_pcr_test']").prop("checked", false);
					if($("input[id='is_rapid_pcr_test']").prop("checked") == true){
						this.form.is_rapid_pcr_test = true;
						this.form.is_pcr_test = false;
						$("input[id='is_rapid_pcr_test']").prop("checked", true);
					}else{
						this.form.is_rapid_pcr_test = false;
					}
				}else{
					if($("input[id='is_pcr_test']").prop("checked") == true){
						this.form.is_pcr_test = true;
						this.form.is_rapid_pcr_test = false;
						$("input[id='is_pcr_test']").prop("checked", true);
					}else{
						this.form.is_pcr_test = false;
					}
					$("input[id='is_rapid_pcr_test']").prop("checked", false);
				}
				this.finaltotal();
			},
			checkRefferedBy() {
				
				if (this.form.referred_by_code && this.payment_frm.paid_amount) {
					axios.post("/covid/check-reffered-by", { 'referred_by_code' : this.form.referred_by_code, 'paid_amount' : this.payment_frm.paid_amount })
					.then((response) => {
						if (Object.keys(response.data.data).length > 0) {
							this.payment_frm.discounted_paid_amount = response.data.data.discounted_paid_amount;
							this.payment_frm.discount_pr = response.data.data.discount_pr;
						} else {
							this.payment_frm.discounted_paid_amount = 0;
							this.payment_frm.discount_pr = 0;
						}
					});
				}else{
					this.payment_frm.discounted_paid_amount = 0;
					this.payment_frm.discount_pr = 0;
				}
			},
			changereason(){
				var reason = Array();
			    $("input:checkbox[name=reason]:checked").each(function() {
			    	reason.push($(this).val());
			     });
				this.form.reasons_for_visit = reason.toString();
			},
			countryChange(){
				this.getstate();
			},
			initializeToken : function() {
				this.tokenpay = {};
				if (this.publicKey) {
					this.tokenpay = TokenPay(this.publicKey);
					this.tokenpay.initialize({
						dataElement: '#card',
						errorElement: '#errorMessage',
						amountElement: 'amount',
						useACH: false,
						useStyles: true,
						disableZip: true,
						disableCvv: false
					});
				}
				console.log(this.tokenpay);
			},
			getlist(obj) {
				if (obj.length > 1) {
					this.$refs.autocomplete.$el.classList.add("selectloader");
					axios.post("/covid/insurance-list", {term: obj})
					.then((response) => {
						this.insurance_list = response.data.data.map((insurance) => {
							return { id: insurance.id, name: insurance.insurance_name}
						});
						this.$refs.autocomplete.$el.classList.remove("selectloader");
					});
				}
				if (obj.length == 0) {
					this.insurance_list = {};
				}
			},
			resultsvalue(obj) {
				console.log(obj);
				this.$emit("clicked", obj);
				this.$refs.autocomplete.searchText = obj.name;
				this.$store.state.CovidExams.form.insurance_company_id = obj.id;
				this.focusout();
			},
			focusout() {
				const btn = this.$refs.autocomplete;
				btn.$el
				.querySelector(".vue3-autocomplete-container > .autosearch-list")
				.blur();
			},

			close(){
				$('#MyPatientModal').modal('hide');
				this.form.appointment_profile_id = 18;
			},

			get_patient(index){
				this.form.first_name = this.patinet_list[index]['first_name'];
				this.form.last_name = this.patinet_list[index]['last_name'];
				this.form.middle_name =  this.patinet_list[index]['middle_name'];
				this.form.email_address =  this.patinet_list[index]['email'];
				this.form.phone_no =  this.patinet_list[index]['cell_phone'];
				this.form.sex =  this.patinet_list[index]['sex'];
				this.form.appointment_profile_id = 19;
				$('#MyPatientModal').modal('hide');
			},

			onChangeAppointmentProfile(e) {
				console.log(e);
				if (e.target.options.selectedIndex > -1) {
					const theTarget = e.target.options[e.target.options.selectedIndex].dataset;
					this.form.color_code = theTarget.color_code
					this.form.duration = theTarget.default_duration
					this.form.reason_for_visit = theTarget.name
				}
			},

			getdate(){
				if($('#date_of_birth').val() == '') {
					this.form.date_of_birth = '';
				} else {
					this.date_of_birth =  $('#date_of_birth').val();
					this.form.date_of_birth = moment(String(this.date_of_birth),'MM-DD-YYYY').format('YYYY-MM-DD');
				}
			},

			scheduleDate(e){
				this.form.schedule_time = moment(e.value).format('YYYY-MM-DD hh:mm a');
			},
			driver_license_number(e){
				this.form.license_expiry_date = moment(e.value).format('YYYY-MM-DD');
			},
			
			save() {
				
				this.v$.$validate();
				if (!this.v$.$error) {
					
					this.serverError = '';
					this.$store.state.loader = true;
					var vm = this;
					if (this.payment_frm.discount_pr == 100 || this.total==0) {
						vm.handlePaymentCapture();
					} else {
						if (this.payment_frm.payment_type == 'Credit Card' && this.payment_frm.cctoken_id == 'addNew') {
							this.tokenpay.createToken(function (result) {
								vm.payment_frm.token = result.token;
								vm.handlePaymentCapture();
							}, 
							function (result) {
								vm.$store.state.loader = false;
							});
						}
					}
				}else{
						
					for (const [key, value] of Object.entries(
						this.vuelidateExternalResults.form
						)) {
						if (this.v$.form[key].$errors.length > 0) {
							const myEl = document.getElementById(key);
							if (myEl) {
								this.$smoothScroll({
									scrollTo: myEl,
								});
								break;
							}
						}
					}
				}
			},

			handlePaymentCapture : function() {
				this.v$.$validate();
				if (!this.v$.$error) {
					/*var submission = this.submission;
					var finalData = {};
					finalData['template_detail_data'] = submission.data.template_detail_data;
					delete submission.data['template_detail_data'];
					delete submission.data['components'];    
					finalData['fieldjsonData'] = submission.data;
					this.form.formdata = finalData['fieldjsonData'];*/
					this.$store.state.loader = true;
					axios.post("/covid/new-store",{'form':this.form,'payment_frm':this.payment_frm})
					.then((result) => { 
						if(result.status == 200){
							this.moshaToast("Record added successfully.", "success");
							this.patinet_ticket  = result.data.data.patinet_ticket;
							this.card_status = result.data.data.card_status;
							this.$store.state.loader = false;
						}
						else{
							this.moshaToast("Opp Somthing Wrong!.", "danger");	
							this.$store.state.loader = false;
						}
					}).catch((error) => {
						console.log(error);
						this.$store.state.loader = false;
						// if (error.response.status == 501) {
						// 	$('#errorMessage').html(error.response.data.message).show();
						// } else {
						// 	this.moshaToast(error.response.data.message, "danger");
						// }
					});
				}
				
			},

			changeinsurance(){
				if(this.form.insurance === 0){
					this.$store.state.is_insurance = false
				}
				else{
					this.$store.state.is_insurance = true
				}
			},

			patientissubscriber(){
				if(this.form.patient_is_subscriber === 0){
					this.$store.state.is_patient_is_subscriber = true
				}
				else{
					this.$store.state.is_patient_is_subscriber = false
				}	
			},

			getstate(){
				axios.get("/covid/state/"+this.form.country_id)
				.then((response) => {
					this.subscriber_state_list = response.data.data.map((state) => {
						return { id: state.id, text: state.name };
					});
				});
			},

			getzipcode(){
				if(this.form.zip_code && this.form.zip_code.length > 3 ){
					axios.post("covid/state-city-auto-populations",{zip_code:this.form.zip_code})
					.then((response) => {
						this.form.city=response.data.data.City;
						this.form.state_id=response.data.data.id
					});
				}
			},
			
			filterchange(field) {
				this.form.is_rapid_pcr_test = false;
				this.form.is_pcr_test = false;
				this.form[field] = true;
				this.finaltotal();
			},

			finaltotal(){
				
				this.form.report = [];
				this.total = 0;
			
				if(this.form.is_rapid_pcr_test == true){
					if(process.env.VUE_APP_NODE_ENV == 'production'){
						// this.form.report.is_rapid_pcr_test = 150;
						this.form.report.push({"is_rapid_pcr_test":150});
						this.total = this.total + 150;
					}
					else{
						this.form.report.push({"is_rapid_pcr_test":10});
						// this.form.report.is_rapid_pcr_test = 10;	
						this.total = this.total + 10;
					}
				}
				if(this.form.is_pcr_test ==1){
					if(process.env.VUE_APP_NODE_ENV == 'production'){
						this.form.report.push({"is_pcr_test":90});
						// this.form.report.is_pcr_test = 90;
						this.total = this.total + 90;
					}
					else{
						this.form.report.push({"is_pcr_test":10});
						// this.form.report.is_pcr_test = 10;	
						this.total = this.total + 10;
					}
				}
				if(this.form.is_rapid_antigen_test == 1){
					if(process.env.VUE_APP_NODE_ENV == 'production'){
						this.total = this.total + 40;	
						this.form.report.push({"is_rapid_antigen_test":40});
						// this.form.report.is_rapid_antigen_test = 40;
					}
					else{
						this.form.report.push({"is_rapid_antigen_test":10});
						// this.form.report.is_rapid_antigen_test = 10;	
						this.total = this.total + 10;
					}
				}
				if(this.form.is_antibody_test == 1){
					if(process.env.VUE_APP_NODE_ENV == 'production'){	
						this.total = this.total + 40;
						// this.form.report.is_antibody_test = 40;
						this.form.report.push({"is_antibody_test":40});
					}else{
						this.form.report.push({"is_antibody_test":10});
						// this.form.report.is_antibody_test = 10;
						this.total = this.total + 10;
					}
				}
				
				if(process.env.VUE_APP_NODE_ENV == 'production'){
					this.payment_frm.paid_amount = this.total;
				}
				else{
					this.payment_frm.paid_amount = this.total;	
				}
				
				this.checkRefferedBy();
			console.log(this.form.report);
				// console.log(this.payment_frm.paid_amount);*/
			}
		}
	}
</script>
<style scoped>
.note-uli{ text-align: left; list-style: none; padding-left: 20px;}
.note-uli li{ position: relative;}
.note-uli li:after{content:''; left:-20px; position: absolute; width: 6px; height: 6px; background-color: #ff0000; border-radius: 15px; margin: auto; top:0; bottom: 0;}
</style>