const permission = {
  namespaced: true,

  state: {
    create_update:'1',
    Access_Patient_Chart:'10',
    Provider_Dropdown:'27',
    Manage_Permissions:'14',
    Manage_Accounts:'25',
    Add_new_referring_sources:'19',
    CDS_Management: '31',
    Sign_Lock_Clinical_Notes: '5',
    Rcopia_Access_Management :'32',
    Insurance_Billing_Access_Management :'33',
    Eligibility_Check_Access :'34',
    Unlock_Payment :'35',
    consent_forms:'37',
    patients_list:'36',
    //appointment Start
    Calendar:'38',
    availability_search:'39',
    appointment_dashboard:'40',
    visit_profile:'41',
    billing_profile:'42',
    custom_procedures:'43',
    reminder_profiles:'44',
    recurring_appointments:'45',
    bulk_appointments:'46',
    appointment_status:'47',
    deleted_appointments :'48',
    pre_appointments :'49',
    //appointment end

    //Settings start
    Manage_Offices_Resources:'23',
    Settings:'26',
    Provider_Permission: '29',
    Staff_Permission: '30',
    ccda_preferences:'50',
    access_management :'51',
    leave_management :'52',
    vital_management :'92',
    custom_billing_status :'53',
    erx_settings :'54',
    lab_settings:'55',
    email_template_setting: '93',
    sms_template_setting:'94',
    provider_staff_account: '95',
    coupon_management: '96',
    referral_management: '97',
    wallet_amount:'100',
    //Settings end

    //Report start
    rectangleHealth :'56',
    card_authorization :'57',
    contract :'58',
    PatientIdentification  :'59',
    Collection  :'60',
    CovidExamination  :'61',
    refund_report:'91',
    ContactPatient: '79',
    ClaimReport: '80',
    EligibilityTest: '81',
    UnmatchedERA: '82',
    RemittanceReports: '83',
    DownloadLogs: '76',
    AdvanceReport: '85',
    EOBUpload: '98',
    ERAUpload: '99',
    //Report end

    //Clinical start
    ClinicalNote : '62',
    MacroSettings : '63',
    SignBulkClinicalNotes : '64',
    ClinicalQualityMeasures : '65',
    AutomatedMeasuresCalculationReport : '66',
    AuditLogReport : '67',
    PHIMailBox : '68',
    //Clinical end

    //PureCRM View start
    PureCRMView :'69',
    StatusandPipeline :'70',
    TransferWellnessCoordinator :'71',
    CorporateList :'72',
    EventCalendar :'73',
    SubscriptionManagement :'74',
    CovidExams : '75',
    LifeStyleAppointment : '84',
    LifeStyleManagement : '86',
    PureCRMContacts : '101',
    //PureCRM View end

    //help start
    Support : '77',
    DevelopmentStatusReport : '78',
    //help end

    //Dashboard Settings start
    CashBilling: '87',
    InsuranceBilling: '88',
    Claims: '89',
    Patient: '90',
    //Dashboard Settings end

    // Clinic settings start
    ClinicSettings: '113'
    // Clinic settings ends
  },
  getters: {},
  mutations: {},
  actions: {},
}

export default permission;

