import {
  helpers,
  required,
  email,
  minLength,
  maxLength,
  sameAs,
  numeric,
} from "@vuelidate/validators";

const ConsentForms = {
  namespaced: true,
  state: {    
    listUrl: "/consent-form/list",  
    header: [
      {"title":"Title"},
      {"Owner":"Owner"},
      {"is_mandatory":"Deposit Date"},
      {"in_appointment":"Assigned by Default"},
      {"":"Action"},            
    ],
    pagination: {
      to: "",
      from: "",
      total: "",
      last_page: "",
      current_page: "",
      pageNavArr: [],
    },
    edit:'',
    form: {},
    vuelidateExternalResults: {
      form: {
        title:[],
        document_name:[],
      },
    },
    validationRules: {
      form: {
        title: {
          required: helpers.withMessage("Title is required", required),
        },
        document_name:{
            required: helpers.withMessage("Custom Consent Form is required", required),
        }
      },
    },
    getEditUrl: "/staff/edit/:id",
    list: [],
  },
  getters: {
    getTableHeaders: (state) => state.header,

    getTableContents: (state) => state.list,
    getListURL: (state) => state.listUrl,
  },
  mutations: {},
  actions: {},
}

const ConsentFormsArchived = {
  namespaced: true,

  state: {
    listUrl: "/consent-form/list/sign",    
    header: [
      {"title":"Title"},
      {"Owner":"Owner"},
      {"is_mandatory":"Deposit Date"},
      {"in_appointment":"Assigned by Default"},
    ],
    pagination: {
      to: "",
      from: "",
      total: "",
      last_page: "",
      current_page: "",
      pageNavArr: [],
    },
    edit:'',
    form: {},
    vuelidateExternalResults: {
      form: {
        title:[],
        document_name:[],
      },
    },
    validationRules: {
      form: {
        title: {
          required: helpers.withMessage("Title is required", required),
        },
        document_name:{
            required: helpers.withMessage("Custom Consent Form is required", required),
        }
      },
    },
    getEditUrl: "/staff/edit/:id",
    list: [],
  },
  getters: {
    getTableHeaders: (state) => state.header,

    getTableContents: (state) => state.list,
    getListURL: (state) => state.listUrl,
  },
  mutations: {},
  actions: {},
}



export {ConsentForms,ConsentFormsArchived};