<template>
	<div class="col-12">
		<ejs-accumulationchart id='chartcontainer' :title='title' :legendSettings='legendSettings' :tooltip='tooltip' :enableSmartLables='enableSmartLabels' :enableAnimation='enableAnimation' :center='center'>
            <e-accumulation-series-collection>
                <e-accumulation-series :dataSource='seriesData' :startAngle='startAngle' :endAngle='endAngle'  :radius='radius'  xName='x' yName='y' :dataLabel='dataLabel' name='CollectionReportChart' innerRadius='50%' :pointColorMapping=' pointColorMapping' tooltipMappingName='text'> </e-accumulation-series>
            </e-accumulation-series-collection>
        </ejs-accumulationchart>
		<div class="col-12 text-center" style="font-size: 12px; color: #565656;">
			<div class="p-5" style="display: inline-block;">
				<div style="width: 8px; height: 8px; border-radius: 50%; background-color: #498fff; display: inline-block;"></div> Healthcare ({{ Healthcare }}%)
			</div>
			<div class="p-5" style="display: inline-block;">
				<div style="width: 8px; height: 8px; border-radius: 50%; background-color: #ffa060; display: inline-block;"></div> POS ({{ POS }}%)
			</div>
			<div class="p-5" style="display: inline-block;">
				<div style="width: 8px; height: 8px; border-radius: 50%; background-color: #ff68b6; display: inline-block;"></div> Insurance ({{ Insurance }}%)
			</div>
			<div class="p-5" style="display: inline-block;">
				<div style="width: 8px; height: 8px; border-radius: 50%; background-color: #81e2a1; display: inline-block;"></div> Contract Auto Pay ({{ Contract }}%)
			</div>
		</div>
	</div>
</template>

<script>
	import { AccumulationChartComponent, AccumulationSeriesCollectionDirective, AccumulationSeriesDirective, AccumulationLegend, PieSeries, AccumulationTooltip, AccumulationDataLabel } from "@syncfusion/ej2-vue-charts";
	
	export default {
		props: {
			POS: Number,
			Healthcare: Number,
			Insurance: Number,
			Contract: Number,
		},
		components: { 
			"ejs-accumulationchart": AccumulationChartComponent,
			"e-accumulation-series-collection": AccumulationSeriesCollectionDirective,
			"e-accumulation-series":  AccumulationSeriesDirective,
		},
		data() {
			return {
				seriesData: [
					{ x: 'Healthcare', y: this.Healthcare, fill: '#498fff', text: this.Healthcare+'%' }, 
					{ x: 'POS', y: this.POS, fill: '#ffa060', text: this.POS+'%' },
					{ x: 'Insurance', y: this.Insurance, fill: '#ff68b6', text: this.Insurance+'%' },
					{ x: 'Contract Auto Pay', y: this.Contract, fill: '#81e2a1', text: this.Contract+'%' }
				],
				dataLabel: {
					visible: true,
					position: 'Inside', name: 'text',
					font: {
						fontWeight: '600'
					}
				},
				pointColorMapping: 'fill',
				enableSmartLabels: true,
				enableAnimation: true,
				legendSettings: {
					visible: true,
				},
				tooltip: { enable: true, header: '<b>${point.x}</b>', format: 'Composition: <b>${point.y}</b>' },
				startAngle: '0',
				endAngle: '360',
				radius: '70%',
				explodeOffset: '10%',
				explodeIndex : '0',
				center: { x: '50%', y: '50%' },
				title: "Collection Report Statistics"
			};
		},
		provide: {
			accumulationchart: [AccumulationLegend, PieSeries, AccumulationTooltip, AccumulationDataLabel]
		}
	};
	</script>
	<style>
		#container {
			height: 350px;
		}
	</style>