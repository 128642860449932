<template>
  <div id="OfficeScheduleLink">
    <div class="patiboxdetail">
      <div class="authform-modal box">
        <div class="requiredinfo">
          <div class="pageheading">
            <h2 class="heading2">Office Scheduling {{this.$store.state.office.office_name}} <a href="#" data-bs-toggle="modal" data-bs-target="#schedulecedocument" title="Reference of Knowledge Base" v-on:click.prevent="reset()"> <i class="fa fa-question-circle fs-20"></i></a></h2>
          </div>
          <form novalidate @submit.prevent="store">
            <div class="table-responsive schedulingtime">
              <table class="table">
                <thead>
                  <tr class="text-center">
                    <th>&nbsp;</th>
                    <th colspan="2">Session 1</th>
                    <th colspan="2">Session 2</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="$day in week_days">
                    <td>
                      <div class="form-check">
                        <input
                          type="checkbox"
                          :id="$day.id"
                          v-model="form.week_day"
                          :checked="$day.checked"
                          class="filled-in chk-col-info"
                          :value="$day.daynm"
                        />
                        <label
                          :for="$day.id"
                          @click="checkBoxToggle($day.id)"
                          class="text-start"
                          >{{ $day.daynm }}</label
                        >
                        <input type="hidden" v-model="form.week_ids" />
                      </div>
                    </td>
                    <td>
                      <time-drop-down
                        :id="$day.daynm + '1'"
                        @selectmodelValue="
                          timeChange($event, $day.daynm + '_session_one_start')
                        "
                        v-model="form[$day.daynm + '_session_one_start']"
                      >
                      </time-drop-down>
                    </td>
                    <td>
                      <time-drop-down
                        :id="$day.daynm + '2'"
                        :ref="$day.daynm + '_session_one_end'"
                        @selectmodelValue="
                          timeChange($event, $day.daynm + '_session_one_end')
                        "
                        v-model="form[$day.daynm + '_session_one_end']"
                      ></time-drop-down>
                    </td>
                    <td>
                      <time-drop-down
                        :id="$day.daynm + '3'"
                        :ref="$day.daynm + '_session_two_start'"
                        @selectmodelValue="
                          timeChange($event, $day.daynm + '_session_two_start')
                        "
                        v-model="form[$day.daynm + '_session_two_start']"
                      ></time-drop-down>
                    </td>
                    <td>
                      <time-drop-down
                        :id="$day.daynm + '4'"
                        :ref="$day.daynm + '_session_two_end'"
                        @selectmodelValue="
                          timeChange($event, $day.daynm + '_session_two_end')
                        "
                        v-model="form[$day.daynm + '_session_two_end']"
                      ></time-drop-down>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div
                class="bg-danger-light p-10 py-5 rounded mb-10"
                v-if="isError == 1"
              >
                {{ error }}
              </div>
            </div>
            <div class="text-center">
              <button
                type="submit"
                class="waves-effect waves-light btn btn-info mb-5 me-5"
              >
                Save
              </button>
              <button
                type="submit" class="waves-effect waves-light btn btn-danger mb-5">
                Cancel
              </button>
            </div>
          </form>
          <!--document model start-->
          <div id="schedulecedocument" class="modal fade" tabindex="-1" role="dialog"
            aria-labelledby="myModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-md">
              <div class="modal-content">
              <div class="modal-header">
                <h3 class="modal-title fw-900 mt-0">Reference of Knowledge Base</h3>
              </div>
              <div class="modal-body overflow-auto" style="max-height: 350px;">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                    <th scope="col">No.</th>
                    <th scope="col">Knowledge Base Articles</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td> <a :href="path+'how-to-schedule-office-timings'" target="_blank">How To Schedule Office Timings?</a></td>
                    </tr>
                  </tbody>
                </table>
                <Search :key="searchKey"></Search>
              </div>
              <div class="modal-footer">
                <button type="button" @click="" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
              </div>
              </div>
            </div>
          </div>
					<!--document model end-->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import submitMixins from "@/scripts/mixins/submitMixins.js";
import TimeDropDown from "@/components/office/formFields/timeDropDown.vue";
import axios from "@/scripts/axios.js";
import $ from "jquery";
import Search from "@/components/Search.vue";
export default {
  mixins: [submitMixins],
  name: "OfficeSchedule",
  data() {
    return {
      isError: 0,
      error: "",
      week_days: [
        { id: "md_checkbox_2", checked: "false", daynm: "Monday" },
        { id: "md_checkbox_3", checked: "false", daynm: "Tuesday" },
        { id: "md_checkbox_4", checked: "false", daynm: "Wednesday" },
        { id: "md_checkbox_5", checked: "false", daynm: "Thursday" },
        { id: "md_checkbox_6", checked: "false", daynm: "Friday" },
        { id: "md_checkbox_7", checked: "false", daynm: "Saturday" },
        { id: "md_checkbox_1", checked: "false", daynm: "Sunday" },
      ],
      form: {
        week_day: [],
        week_ids: [],
        Sunday_session_one_start: null,
        Sunday_session_one_end: null,
        Sunday_session_two_start: null,
        Sunday_session_two_end: null,
        Monday_session_one_start: null,
        Monday_session_one_end: null,
        Monday_session_two_start: null,
        Monday_session_two_end: null,
        Tuesday_session_one_start: null,
        Tuesday_session_one_end: null,
        Tuesday_session_two_start: null,
        Tuesday_session_two_end: null,
        Wednesday_session_one_start: null,
        Wednesday_session_one_end: null,
        Wednesday_session_two_start: null,
        Wednesday_session_two_end: null,
        Thursday_session_one_start: null,
        Thursday_session_one_end: null,
        Thursday_session_two_start: null,
        Thursday_session_two_end: null,
        Friday_session_one_start: null,
        Friday_session_one_end: null,
        Friday_session_two_start: null,
        Friday_session_two_end: null,
        Saturday_session_one_start: null,
        Saturday_session_one_end: null,
        Saturday_session_two_start: null,
        Saturday_session_two_end: null,
        office_id: this.$route.params.id,
      },
      time_array: null,
      path: process.env.VUE_APP_KB_DOCUMENT,
      searchKey:0,
    };
  },
  components: { TimeDropDown, Search },
  mounted() {
    if (this.$store.state.office.rooms_count == 0) {
      $("#ResourceTab").addClass("linkDisabled");
    }
    var interval = 15,
      times = [],
      t = 0;
    for (var i = 0; t < 24 * 60; i++) {
      var h = Math.floor(t / 60);
      var m = t % 60;
      times[i] = ("0" + (h % 24)).slice(-2) + ":" + ("0" + m).slice(-2);
      t = t + interval;
    }
    this.time_array = times;

    if (this.$route.params.id != null) {
      var $this = this;
      axios
        .get("/office/" + this.$route.params.id + "/schedule")
        .then((response) => {
          $.each(response.data.data, function(index, value) {
            $this.form.week_day.push(value.week_day);
            switch (value.week_day) {
              case "Sunday":
                $this.form.week_ids.push(value.week_id);
                $this.form.Sunday_session_one_start = value.session_one_start;
                $this.form.Sunday_session_one_end = value.session_one_end;
                $this.form.Sunday_session_two_start = value.session_two_start;
                $this.form.Sunday_session_two_end = value.session_two_end;
                break;
              case "Monday":
                $this.form.week_ids.push(value.week_id);
                $this.form.Monday_session_one_start = value.session_one_start;
                $this.form.Monday_session_one_end = value.session_one_end;
                $this.form.Monday_session_two_start = value.session_two_start;
                $this.form.Monday_session_two_end = value.session_two_end;
                break;
              case "Tuesday":
                $this.form.week_ids.push(value.week_id);
                $this.form.Tuesday_session_one_start = value.session_one_start;
                $this.form.Tuesday_session_one_end = value.session_one_end;
                $this.form.Tuesday_session_two_start = value.session_two_start;
                $this.form.Tuesday_session_two_end = value.session_two_end;
                break;
              case "Wednesday":
                $this.form.week_ids.push(value.week_id);
                $this.form.Wednesday_session_one_start =
                  value.session_one_start;
                $this.form.Wednesday_session_one_end = value.session_one_end;
                $this.form.Wednesday_session_two_start =
                  value.session_two_start;
                $this.form.Wednesday_session_two_end = value.session_two_end;
                break;
              case "Thursday":
                $this.form.week_ids.push(value.week_id);
                $this.form.Thursday_session_one_start = value.session_one_start;
                $this.form.Thursday_session_one_end = value.session_one_end;
                $this.form.Thursday_session_two_start = value.session_two_start;
                $this.form.Thursday_session_two_end = value.session_two_end;
                break;
              case "Friday":
                $this.form.week_ids.push(value.week_id);
                $this.form.Friday_session_one_start = value.session_one_start;
                $this.form.Friday_session_one_end = value.session_one_end;
                $this.form.Friday_session_two_start = value.session_two_start;
                $this.form.Friday_session_two_end = value.session_two_end;
                break;
              case "Saturday":
                $this.form.week_ids.push(value.week_id);
                $this.form.Saturday_session_one_start = value.session_one_start;
                $this.form.Saturday_session_one_end = value.session_one_end;
                $this.form.Saturday_session_two_start = value.session_two_start;
                $this.form.Saturday_session_two_end = value.session_two_end;
                break;
            }
          });
        });
    }
  },
  computed: {},
  methods: {
    reset() {
      this.searchKey = this.searchKey + 1;
    },
    checkBoxToggle(index) {
      if (
        document.getElementById(index).checked &&
        this.form.week_day.length == 0
      ) {
        this.isError = 1;
      } else {
        this.isError = 0;
      }
    },
    store() {
      if (this.isError != 1 && this.form.week_day.length != 0) {
        axios
          .post("/office/schedule/save", this.form)
          .then((response) => this.$router.push("/office/list"));
        this.moshaToast("Record updated successfully.", "success");
      } else {
        this.isError = 1;
        this.error = "You have not set up any schedule!";
      }
    },
    timeChange($event, nm) {
      if(nm == "Monday_session_one_start"){
        for (var i = 0; i < this.week_days.length; i++) {
            this.form[this.week_days[i]['daynm']+'_session_one_start'] = $event;
        }
      }
      if(nm == "Monday_session_one_end"){
        for (var i = 0; i < this.week_days.length; i++) {
            this.form[this.week_days[i]['daynm']+'_session_one_end'] = $event;
        }
      }
      if(nm == "Monday_session_two_start"){
        for (var i = 0; i < this.week_days.length; i++) {
            this.form[this.week_days[i]['daynm']+'_session_two_start'] = $event;
        }
      }
      if(nm == "Monday_session_two_end"){
        for (var i = 0; i < this.week_days.length; i++) {
            console.log(this.week_days[i]['daynm']);
            this.form[this.week_days[i]['daynm']+'_session_two_end'] = $event;
        }
      }
      this.form[nm] = $event;
      var c;
      var splt = nm.split("_");
      
      if (nm.indexOf("one") !== -1) {
        c = 1;
        var session1 = splt[0] + "_session_one_start";
        var session2 = splt[0] + "_session_one_end";
      } else {
        c = 3;
        var session1 = splt[0] + "_session_two_start";
        var session2 = splt[0] + "_session_two_end";
      }
      if(this.form[session1] != undefined){
        var start1 = this.form[session1].split(":");
        start1 = start1.join("");
      }else{
        var start1 = null;
      }
      if(this.form[session2] != undefined){
        var end1 = this.form[session2].split(":");
        end1 = end1.join("");
      }else{
        var end1 = null;
      }

      if (start1 > end1) {
        this.isError = 1;
        this.error = "Invalid time value selected for the highlighted items.";
        document.getElementById(splt[0] + c).style.border = `1px solid red`;
      } else {
        this.isError = 0;
        document.getElementById(splt[0] + c).style.border = `1px solid #d4dde6`;
      }
    },
  },
};
</script>
