const CRMPcontacts = {
    namespaced: true,
    state: {
        listUrl: "/crm/contacts",
        tableData: {
            header: [
                "Patient Name",
                "Phone",
                "Email",
                "CRM Pipeline",
                "CRM Current Status",
                "Created",
                "Tag",
            ],
        },
        form: {},
        list: [],
    },
    getters: {
        getTableHeaders: (state) => state.header,
        getTableContents: (state) => state.list,
        getListURL: (state) => state.listUrl,
    },
    mutations: {},
    actions: {},
  };
  
//   export default CRMPcontacts;
export {CRMPcontacts};
  