<template>
    <div class="box p-15">
        <div class="mdc-tab">
            <TabbarComp routefrom="CreditCardList"></TabbarComp>
            <div class="tab-content">
                <div class="tab-pane active" id="creditcardlist" role="tabpanel">
                    <div class="row" v-if="ccTokenData.length > 0">
                        <template v-for="(item,index) in ccTokenData" :key="index">
                            <div class="col-12 col-sm-6 col-md-8 col-lg-6 col-xl-4" v-if="item.is_default == 1">
                                <div class="cardbg text-white my-10" :class="item.bg_color">
                                    <img :src="'/images/'+item.logo_name" alt="Visa" title="Visa" class="paycard_Logo">
                                    <ul class="makeedit">
                                        <li v-if="item.is_default == 1"><i class="fa fa-star-o me-5"></i>Default</li>
                                        <li v-if="item.is_default == 0"><a href="javascript:void(0)" class="text-white" @click="makeThisDefault(item)"> Make Default </a></li>
                                        <!-- <li><a href="javascript:void(0)" class="text-white"><i class="fa fa-pencil-square-o"></i></a></li> -->
                                        <li><a href="javascript:void(0)" class="text-white"><i class="fa fa-trash-o" @click="moveToTrash(item)"></i></a></li>
                                    </ul>
                                    <p class="card_nomber">{{ item.masked_pan_display }}</p>
                                    <div>
                                        <p class="card-tagline">{{ this.$store.state.patientDetail.first_name }} {{ this.$store.state.patientDetail.last_name }} <span class="exp_date">Exp {{ item.cc_exp_date }}</span></p>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                    <div class="row" v-if="ccTokenData.length > 0">
                        <template v-for="(item,index) in ccTokenData" :key="index">
                            <div class="col-12 col-sm-6 col-md-8 col-lg-6 col-xl-4" v-if="item.is_default == 0">
                                <div class="cardbg text-white my-10" :class="item.bg_color">
                                    <img :src="'/images/'+item.logo_name" alt="Visa" title="Visa" class="paycard_Logo">
                                    <ul class="makeedit">
                                        <li v-if="item.is_default == 1"><i class="fa fa-star-o me-5"></i>Default</li>
                                        <li v-if="item.is_default == 0"><a href="javascript:void(0)" class="text-white" @click="makeThisDefault(item)"> Make Default </a></li>
                                        <!-- <li><a href="javascript:void(0)" class="text-white"><i class="fa fa-pencil-square-o"></i></a></li> -->
                                        <li><a href="javascript:void(0)" class="text-white"><i class="fa fa-trash-o" @click="moveToTrash(item)"></i></a></li>
                                    </ul>
                                    <p class="card_nomber">{{ item.masked_pan_display }}</p>
                                    <div>
                                        <p class="card-tagline">{{ this.$store.state.patientDetail.first_name }} {{ this.$store.state.patientDetail.last_name }} <span class="exp_date">Exp {{ item.cc_exp_date }}</span></p>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                    <div class="text-center my-20 fs-22" v-if="ccTokenData.length == 0">No Credit Card Found.</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import permissionMixins from "@/scripts/mixins/permission.js";
    import axios from "@/scripts/axios.js";
    import submitMixins from "@/scripts/mixins/submitMixins.js";
    import useVuelidate from "@vuelidate/core";
    import moment from 'moment';
    import TabbarComp from "@/components/patients/details/Payment/TabbarComp.vue";
    import {
        required,
        helpers,
        minLength,
        maxLength,
        numeric,
        requiredIf,
    } from "@vuelidate/validators";
    export default {
        components: {
            TabbarComp
        },
        setup() {
            return { v$: useVuelidate() };
        },
        mixins: [submitMixins,permissionMixins],
        data() {
            return {
                is_production_env : process.env.VUE_APP_NODE_ENV == 'production',
                hash: '',
                wallet_frm : {
                    payment_type : '',
                    paid_amount : '',
                    payment_note : '',
                    check_trace : '',
                    other_payment_type : '',
                    cctoken_id : '',
                    use_credidcard_for_future : false,
                    patient_id : this.$store.state.patientDetail.patient_id,
                    patient_unique_id : this.$store.state.patientDetail.patient_unique_id,
                },
                current_patient_wallet : 0,
                walletTransactionData: [],
                tokenpay : {},
                tokenPayReady: false, 
                checkingInterval: null,
                serverError: '',
                range_date: '',
                first_last_date: '',
                start_date: '',
                end_date: '',
                ccTokenData: [],
                receipt_url: '',
                embed: 0,
                list_lenght:'',
                start:0,
                end:10,
                step:10,
                steps:'',
                publicKey : '',
            };
        },
        
        async created() {
            let localThis = this 
            this.fetchPatientToken();
        },
    
        computed: {
        },
        mounted() {
        
        },
        methods: {
            makeThisDefault(item) {
                axios
                .post('/payment/patient-cctoken-make-default',{patient_id:this.$store.state.patientDetail.patient_id, 'token_id' : item.id })
                .then((response) => {
                this.moshaToast(response.data.message, "success");
                this.fetchPatientToken();
                });
            },
            moveToTrash(item) {
                console.log(item.is_default);
                if (item.is_default == 1) {
                    this.moshaToast("This is default card, can not be deleted!", "info");
                } else {
                this.$swal
                    .fire({
                    title: '<h2 class="mt-0">Are you sure?</h2>',
                    text:
                        "You want to delete this card? This process cannot be undone.",
                    icon: "warning",
                    dangerMode: true,
                    showCancelButton: true,
                    confirmButtonColor: "#ee3158",
                    cancelButtonColor: "#bdbdbd",
                    confirmButtonText: "Yes, delete it!",
                    })
                    .then((result) => {
                    if (result.isConfirmed == true) {
                        axios
                        .post(`/payment/patient-cctoken-move-to-trash`, {patient_id:this.$store.state.patientDetail.patient_id, 'token_id' : item.id })
                        .then((response) => {
                            this.$swal
                            .fire(
                                "Deleted!",
                                response.data.message,
                                "success"
                            )
                            .then((result) => {
                                if (result.isConfirmed == true) {
                                    this.fetchPatientToken();
                                }
                            });
                        });
                    }
                    });
                }
            },
            changeTab (components) {
                this.$store.state.patientDetailsComp = components;
                let props = this.$router.resolve({
                    name: components,
                    params: { id: this.$route.params.id},
                });
                if (history.pushState && props) {
                history.pushState(null, '',props.href);
                }
            },
        
            fetchPatientToken : function() {
                this.$store.state.loader = true;
                axios
                .post('/payment/patient-cctoken-for-cardview',{patient_id:this.$store.state.patientDetail.patient_id})
                .then((response) => {
                    this.ccTokenData = response.data.data;
                    this.$store.state.loader = false;
                });
            }
        }
    }
</script>