<template>
	<div class="tab-pane active" id="AddRoomsLink" role="tabpanel">
			<div class="authform-modal box">
				<div class="pageheading">
					<div class="row">
						<div class="col-12 col-sm-6 col-md-6 col-lg-8">
							<h2 class="fw-900 mt-0">Rooms {{this.$store.state.office.office_name}} <a href="#" data-bs-toggle="modal" data-bs-target="#roomdocument" title="Reference of Knowledge Base" v-on:click.prevent="reset()"> <i class="fa fa-question-circle fs-20"></i></a></h2>
						</div>
						<div class="col-12 col-sm-6 col-md-6 col-lg-4 text-sm-end">
							<router-link :to="'/office/'+ this.$route.params.id +'/room/archived'" class="waves-effect waves-light btn btn-info-light me-1 mb-1"> Show Archived </router-link>
						</div>
					</div>
				</div>
				<div class="table-responsive" v-if="rooms">
						<table
							id="example6"
							class="table table-bordered"
							cellspacing="0"
						>
							<thead>
								<tr>
									<th>#</th>
									<th>Room Name</th>
									<th>No. of Resources</th>
									<th>Action</th>
								</tr>
							</thead>
							<tbody v-if="rooms.length">
								<tr v-for="room in rooms">
									<td :data-id="room.id"> 
										<span class="float-start" style="cursor: move;">
											<i class="fa fa-ellipsis-v handle fw-100 fs-14 text-dark me-1"></i>
											<i class="fa fa-ellipsis-v handle fw-100 fs-14 text-dark me-1"></i>
										</span> 
									</td>
									<td>
										<label v-bind:id="'roomlabel'+room.id" class="roomlabel col-12">{{ room.name }}</label>
										<div class="d-none roomtext" v-bind:id="'roomtext'+room.id">
											<div class="d-flex align-items-center">
												<input type="text" class="form-control" v-model="room_name">
												<a href="#" class="fs-14 text-mute mx-2" @click="closeedit">
													<i class="fa fa-close"></i>
												</a>
												<a href="#" class="waves-effect btn btn-info btn-xs" @click="updateroom(room.id)">
													<i class="fa fa-floppy-o"></i>
												</a>
											</div>
										</div>
									</td>
									<td>{{ room.resources_count }}</td>
									<td>
										<div class="d-flex">
											<a href="#" class="waves-effect waves-circle btn btn-circle btn-info btn-xs me-1" @click="editroom(room.id,room.name)">
												<i class="fa fa-pencil"></i>
											</a>
											<a
												href="javascript:void(0)"
												@click="deleteRoom(room.id)"
												title="Delete Room"
												class="waves-effect waves-circle btn btn-circle btn-danger btn-xs"
												><i class="fa fa-trash"></i
											></a>
										</div>
									</td>
								</tr>
							</tbody>
							<tbody v-else>
								<tr>
									<td colspan="3">No rooms available</td>
								</tr>
							</tbody>
						</table>
				</div>
				<form novalidate @submit.prevent="store">
					<div class="form-group row">
						<label class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-3 col-form-label text-sm-end mb-0">Add new Exam Rooms</label>
						<div class="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-3">
							<select
								class="form-control"
								@change="createRooms($event)"
								v-model="num_rooms"
							>
								<option v-for="item in 10" :value="item">{{ item }}</option>
							</select>
						</div>
					</div>
					<div id="rooms" v-for="input in items" :key="input.id">
						<div class="form-group row">
								<label class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-3 col-form-label text-sm-end mb-0" :for="input.id">{{ input.label }}</label>
							<div class="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-3">
								<input
									type="text"
									:id="input.id"
									v-model="input.name"
									class="form-control"
									:placeholder="input.label"
								/>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-12 col-sm-12 col-md-12 col-lg-9 text-center">
							<button
								type="button"
								v-on:click="store('list')"
								class="waves-effect waves-light btn btn-info mb-5 me-5"
							>
								Save
							</button>
							<button
								type="button"
								@click="$router.push('/office/list')"
								class="waves-effect waves-light btn btn-danger mb-5 me-5"
							>
								Cancel
							</button>
							<button
								type="button"
								v-on:click="store('next')"
								class="waves-effect waves-light btn btn-info mb-5"
							>
								Save & Next
							</button>
						</div>
					</div>
				</form>
				<!--document model start-->
				<div id="roomdocument" class="modal fade" tabindex="-1" role="dialog"
						aria-labelledby="myModalLabel" aria-hidden="true">
					<div class="modal-dialog modal-md">
						<div class="modal-content">
						<div class="modal-header">
							<h3 class="modal-title fw-900 mt-0">Reference of Knowledge Base</h3>
						</div>
						<div class="modal-body overflow-auto" style="max-height: 350px;">
							<table class="table table-bordered">
								<thead>
									<tr>
									<th scope="col">No.</th>
									<th scope="col">Knowledge Base Articles</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>1</td>
										<td> <a :href="path+'how-to-create-a-room'" target="_blank">How To Create A Room?</a></td>
									</tr>
									<tr>
										<td>2</td>
										<td> <a :href="path+'how-to-remove-delete-rooms'" target="_blank">How To Remove/ Delete Rooms?</a></td>
									</tr>
									<tr>
										<td>3</td>
										<td> <a :href="path+'how-to-view-deleted-rooms'" target="_blank">How To View Deleted Rooms?</a></td>
									</tr>
								</tbody>
							</table>
							<Search :key="searchKey"></Search>
						</div>
						<div class="modal-footer">
							<button type="button" @click="" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
						</div>
						</div>
					</div>
				</div>
				<!--document model end-->
			</div>
		</div>
</template>
<script>
import submitMixins from "@/scripts/mixins/submitMixins.js";
import axios from "@/scripts/axios.js";
import Search from "@/components/Search.vue";
//import $ from "jquery";
export default {
	mixins: [submitMixins],
	components: { Search },
	data() {
		return {
			tokenPayReady: false, 
			checkingInterval: null,
			num_rooms: 1,
			room_name :'',
			items: [
				{
					id: "room0",
					label: "Exam Room 1",
					value: "",
				},
			],
			rooms: null,
			form: {
				name: [],
				office_id: this.$route.params.id,
			},
			path: process.env.VUE_APP_KB_DOCUMENT,
			searchKey:0,
		};
	},
	mounted() {
		let bridgePayScript = document.createElement('script')
		bridgePayScript.setAttribute('src', 'https://cdnjs.cloudflare.com/ajax/libs/jqueryui/1.12.1/jquery-ui.min.js')
		document.head.appendChild(bridgePayScript);

		this.getroom();
		/*if (this.$store.state.office.rooms_count == 0) {
			$("#ResourceTab").addClass("linkDisabled");
		}*/
	},
	computed: {},
	methods: {
		reset() {
			this.searchKey = this.searchKey + 1;
		},
		getroom(){
			axios.post("/json_dropdown/" + this.$route.params.id + "/room")
			.then((response) => {
				this.rooms = response.data.data;
			});
		},
		editroom(id,name){            
			this.room_name = name;
			$(".roomtext").addClass('d-none');
			$(".roomlabel").removeClass('d-none');
			$("#roomlabel"+id).addClass('d-none');
			$("#roomtext"+id).removeClass('d-none');
		},
		closeedit(){
			$(".roomtext").addClass('d-none');
			$(".roomlabel").removeClass('d-none');
		},
		updateroom(id){
			axios.post("office/" + this.$route.params.id + "/rooms/update",{id:id,name:this.room_name,office_id:this.$route.params.id})
			.then((result) => {
				$(".roomtext").addClass('d-none');
				$(".roomlabel").removeClass('d-none');
				this.getroom();
				this.moshaToast("Record updated successfully.", "success");
			});
		},
		createRooms(event) {
			var rooms = event.target.value,
				html = "";
			/*reset array*/
			this.items.splice(1);
			/*restructure array*/
			for (var room = 2; room <= this.num_rooms; room++) {
				this.items.push({
					id: `room${room}`,
					label: `Exam Room ${room}`,
					value: "",
				});
			}
		},
		deleteRoom(room_id) {
			this.$swal
				.fire({
					title: '<h2 class="mt-0">Are you sure?</h2>',
					text:
						"You want to delete these records? This process cannot be undone.",
					icon: "warning",
					dangerMode: true,
					showCancelButton: true,
					confirmButtonColor: "#ee3158",
					cancelButtonColor: "#bdbdbd",
					confirmButtonText: "Yes, delete it!",
				})
				.then((result) => {
					if (result.isConfirmed == true) {
						axios
							.post("/office/" + this.$route.params.id + "/rooms/delete", {
								id: room_id,
							})
							.then((response) => {
								this.$store.state.office.rooms_count =
									this.$store.state.office.rooms_count - 1;
								location.reload();
							});
					}
				});
		},
		store(param) {
			var $this = this;
			$.each(this.items, function(key1, value1) {
				var v = "";
				if (value1 != "" || value1 != null) {
					$.each(value1, function(key, value) {
						v = value;
					});
					$this.form.name.push(v);
				}
			});

			axios
				.post("/office/" + this.$route.params.id + "/rooms/store", this.form)
				.then((response) => {
					param == "list"
						? $this.$router.push("/office/list")
						: ($this.$router.push(
								`/office/${$this.$route.params.id}/rooms/resources`
							),
							($this.$store.state.office.rooms_count =
								$this.$store.state.office.rooms_count + 1),
							($this.$store.state.addOfficeComp = "AddResources")),
						$this.moshaToast("Record updated successfully.", "success");
				});
		},
		updateOrder(sortedIds) {
			axios.post("/office/" + this.$route.params.id + "/rooms/changeSortOrder",{ 'sortedIds' : sortedIds })
			.then((response) => {
				this.moshaToast('Order updated successfully.','success');
				//setTimeout(function(){ location.reload(); }, 300);
			})
		},
	},
	async created() {
		let localThis = this ;
		this.checkingInterval = setInterval(function(){
			if (window.$ && $.ui) {
				localThis.tokenPayReady = true
			}
		}, 500);
	},
	watch: {
		tokenPayReady: function(data) {
			if (data) { 
				clearInterval(this.checkingInterval);
				let $this = this;
				$( "tbody" ).sortable({
					delay: 150,
					stop: function() {
						var selectedData = new Array();
						$('tbody > tr').each(function() {
							selectedData.push($(this).find('td:first-child').data('id'));
						});
						$this.updateOrder(selectedData);
					}
				});
			}
		}
	},
};
</script>
