import {
  helpers,
  required,
  email,
  minLength,
  maxLength,
  sameAs,
  numeric,
} from "@vuelidate/validators";

const FunctionalStatus = {
  namespaced: true,

  state: {
    tableData: {
      header: [
        "SNOMED Code",
        "Description",
        "Observation Date",
      ],
    },
    form: {
      snomed_code:'',
    },
    vuelidateExternalResults: {
      form: {
        snomed_code:[],
        observation_date:[],
      },
    },
    validationRules: {
      form: {
        snomed_code: {
          required: helpers.withMessage("Snomed Code is required", required),
        },
        observation_date: {
          required: helpers.withMessage("Observation Date is required", required),
        },
      },
    },
    listUrl: "/patient/:patient_unique_id/functional-status/list",
    list: [],
  },
  getters: {
    getTableHeaders: (state) => state.header,

    getTableContents: (state) => state.list,
    getListURL: (state) => state.listUrl,
  },
  mutations: {},
  actions: {},
}

export default FunctionalStatus;