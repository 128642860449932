<template>
    <div class="tab-pane active" id="AddResourcesLink" role="tabpanel">
        <div class="patiboxdetail">
            <div class="authform-modal box">
                    <div class="pageheading">
                        <h2 class="heading2">Office Billing {{this.$store.state.office.office_name}}</h2>
                    </div>
                    <form >
                            <div class="form-group row" :class="{ error: v$.form.facility_code_or_place_of_service.$errors.length }">
                                <label
                                    class="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label text-sm-end"
                                    >Place of Service <small class="text-danger asterisksign">*</small>
                                </label>

                              <div class="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-3">
                                <div class="controls">
                                  <select
                                      v-model="v$.form.facility_code_or_place_of_service.$model"
                                      :errors="v$.form.facility_code_or_place_of_service.$errors"
                                      name="facility_code_or_place_of_service"
                                      class="form-select form-control form-control">
                                      <option :selected="form.facility_code_or_place_of_service == ''" value="" disabled>---------</option>
                                      <option :selected="form.facility_code_or_place_of_service == '03'" value="03"> 03 - School</option>
                                      <option :selected="form.facility_code_or_place_of_service == '04'" value="04"> 04 - Homeless Shelter</option>
                                      <option :selected="form.facility_code_or_place_of_service == '05'" value="05"> 05 - Indian Health Service Free-Standing Facility</option>
                                      <option :selected="form.facility_code_or_place_of_service == '06'" value="06"> 06 -Indian Health Service Provider-Based Facility</option>
                                      <option :selected="form.facility_code_or_place_of_service == '07'" value="07"> 07 - Tribal 638 Free-Standing Facility</option>
                                      <option :selected="form.facility_code_or_place_of_service == '08'" value="08"> 08 - Tribal 638 Provider Based-Facility</option>
                                      <option :selected="form.facility_code_or_place_of_service == '11'" value="11"> 11 - Office Visit</option>
                                      <option :selected="form.facility_code_or_place_of_service == '12'" value="12"> 12 - Home</option>
                                      <option :selected="form.facility_code_or_place_of_service == '13'" value="13"> 13 - Assisted Living</option>
                                      <option :selected="form.facility_code_or_place_of_service == '14'" value="14"> 14 - Group Home</option>
                                      <option :selected="form.facility_code_or_place_of_service == '15'" value="15"> 15 - Mobile Unit</option>
                                      <option :selected="form.facility_code_or_place_of_service == '20'" value="20"> 20 - Urgent Care Facility</option>
                                      <option :selected="form.facility_code_or_place_of_service == '21'" value="21"> 21 - Inpatient Hospital</option>
                                      <option :selected="form.facility_code_or_place_of_service == '22'" value="22"> 22 - Outpatient Hospital</option>
                                      <option :selected="form.facility_code_or_place_of_service == '23'" value="23"> 23 - Emergency Room</option>
                                      <option :selected="form.facility_code_or_place_of_service == '24'" value="24"> 24 - Ambulatory Surgical Center</option>
                                      <option :selected="form.facility_code_or_place_of_service == '25'" value="25"> 25 - Birthing Center</option>
                                      <option :selected="form.facility_code_or_place_of_service == '26'" value="26"> 26 - Military Treatment Facility</option>
                                      <option :selected="form.facility_code_or_place_of_service == '31'" value="31"> 31 - Skilled Nursing Facility</option>
                                      <option :selected="form.facility_code_or_place_of_service == '32'" value="32"> 32 - Nursing Facility</option>
                                      <option :selected="form.facility_code_or_place_of_service == '33'" value="33"> 33 - Custodial Care Facility</option>
                                      <option :selected="form.facility_code_or_place_of_service == '34'" value="34"> 34 - Hospice</option>
                                      <option :selected="form.facility_code_or_place_of_service == '41'" value="41"> 41 - Ambulance-Land</option>
                                      <option :selected="form.facility_code_or_place_of_service == '42'" value="42"> 42 - Ambulance-Air or Water</option>
                                      <option :selected="form.facility_code_or_place_of_service == '50'" value="50"> 50 - Federally Qualified Health Center</option>
                                      <option :selected="form.facility_code_or_place_of_service == '51'" value="51"> 51 - Inpatient Psychiatric Facility</option>
                                      <option :selected="form.facility_code_or_place_of_service == '52'" value="52"> 52 - Psychiatric Facility Partial Hospitalization</option>
                                      <option :selected="form.facility_code_or_place_of_service == '53'" value="53"> 53 - Community Mental Health Center</option>
                                      <option :selected="form.facility_code_or_place_of_service == '54'" value="54"> 54 - Intermediate Care Facility</option>
                                      <option :selected="form.facility_code_or_place_of_service == '55'" value="55"> 55 - Residential Substance Abuse Treatment Facility</option>
                                      <option :selected="form.facility_code_or_place_of_service == '56'" value="56"> 56 - Psychiatric Residential Treatment Center</option>
                                      <option :selected="form.facility_code_or_place_of_service == '60'" value="60"> 60 - Mass Immunization Center</option>
                                      <option :selected="form.facility_code_or_place_of_service == '61'" value="61"> 61 - Comprehensive Inpatient Rehab Facility</option>
                                      <option :selected="form.facility_code_or_place_of_service == '62'" value="62"> 62 - Comprehensive Outpatient Rehab Facility</option>
                                      <option :selected="form.facility_code_or_place_of_service == '65'" value="65"> 65 - End Stage Renal Disease Treatment Facility</option>
                                      <option :selected="form.facility_code_or_place_of_service == '71'" value="71"> 71 - State or Local Public Health Clinic7</option>
                                      <option :selected="form.facility_code_or_place_of_service == '2'" value="2"> 2 - Rural Health Clinic</option>
                                      <option :selected="form.facility_code_or_place_of_service == '81'" value="81"> 81 - Independent Laboratory</option>
                                      <option :selected="form.facility_code_or_place_of_service == '99'" value="99"> 99 - Other Unlisted Facility </option>
                                  </select>

                                  <div class="invalid-feedback" style="display:block;" v-for="(error, index) of v$.form.facility_code_or_place_of_service.$errors"
                                  :key="index">{{ error.$message }}</div>
                                </div>
                              </div>

                          <div class="col-12 col-sm-8 col-md-8 offset-sm-4 offset-lg-0 col-lg-4 col-xl-5"><small class="form-text text-muted">Required for CMS 1500 form #24b field</small></div>
                        </div>

                        <div class="form-group row align-items-center" :class="{ error: v$.form.federal_tax_type.$errors.length }">
                            <label class="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label text-sm-end">Fedral Tax Type 
                              <!-- <small class="text-danger asterisksign">*</small> -->
                            </label>
                            <div class="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-1">
                                <input type="radio" id="SSN" name="federal_tax_type" value="SSN" @change="changetaxType('SSN')" class="filled-in chk-col-info" v-model="v$.form.federal_tax_type.$model" :errors="v$.form.federal_tax_type.$errors"/>
                               <label for="SSN">SSN</label>
                            </div>
                            <div class="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-1">                              
                                <input type="radio" v-model="form.federal_tax_type" id="EIN" name="federal_tax_type" value="EIN" @change="changetaxType('EIN')" class="filled-in chk-col-info"/>
                                   <label for="EIN">EIN</label>
                            </div>
                            <div class="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-5">
                              <small class="form-text text-muted">Note:- Used in CMS 1500 form box#25.</small>
                            </div>
                            <div class="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-3">
                                <div class="invalid-feedback" style="display:block;" v-for="(error, index) of v$.form.federal_tax_type.$errors" :key="index">{{ error.$message }}</div>
                            </div>
                        </div>

                        <input-field title="EIN Number" note="Used in CMS 1500 form box#25" divClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-3" labelClass="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label text-sm-end" v-model="v$.form.federal_tax_EIN.$model" :errors="v$.form.federal_tax_EIN.$errors" :isRequired="false"> </input-field>

                        <input-field title="SSN Number" note="Used in CMS 1500 form box#25" divClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-3" labelClass="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label text-sm-end" v-model="v$.form.federal_tax_SSN.$model" :errors="v$.form.federal_tax_SSN.$errors" :isRequired="false"> </input-field>

                        <hr>

                        <input-field title="Taxonomy Number" divClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-3" labelClass="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label text-sm-end" v-model="form.taxonomy_number"> </input-field>

                        <input-field title="Billing name" note="Used in CMS 1500 form box#33" divClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-3" labelClass="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label text-sm-end" v-model="form.billing_provider_name"> </input-field>

                        <input-field title="Billing NPI" note="Used in CMS 1500 form box#33a" divClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-3" labelClass="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label text-sm-end" v-model="v$.form.billing_provider_npi.$model" :errors="v$.form.billing_provider_npi.$errors"> </input-field>

                        <input-field title="Facility NPI" note="Used in CMS 1500 form box#32a" divClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-3" labelClass="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label text-sm-end" v-model="v$.form.facility_npi.$model" :errors="v$.form.facility_npi.$errors"> </input-field>

                        <input-field id="billing_provider_number" title="Billing Phone" v-model="form.billing_provider_number" mask="(000) 000-0000"> </input-field>

                        <text-area title="Billing address" notes="Used in CMS 1500 form box#33" divClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-3" labelClass="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label text-sm-end" v-model="form.billing_provider_address"> </text-area>

                        <input-field title="Billing zipcode" note="Used in CMS 1500 form box#33" @focusout="zipcode('patient_primary')" divClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-3" labelClass="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label text-sm-end" v-model="v$.form.billing_provider_zipcode.$model" :errors="v$.form.billing_provider_zipcode.$errors"> </input-field>

                        <input-field title="Billing city" note="Used in CMS 1500 form box#33" divClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-3" labelClass="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label text-sm-end" v-model="form.billing_provider_city"> </input-field>   

                        <div class="form-group row">
                            <label class="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label text-sm-end">Billing State </label>
                            <div class="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-3">
                              <Select2
                              v-model="form.billing_provider_state"
                              id="billing_provider_state"
                              placeholder="Select State"
                              :options="states"
                              />            
                            </div>
                            <div class="col-12 col-sm-8 col-md-8 offset-sm-4 offset-lg-0 col-lg-4 col-xl-5" style=""><small class="form-text text-muted">Used in CMS 1500 form box#32a</small></div>
                        </div>

                        <div class="form-group row" >
                            <label class="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label text-sm-end">Country</label>
                            <div class="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-3">
                                <Select2
                                  v-model="form.billing_provider_country"
                                  ref="selectCountry"
                                  id="billing_provider_country"
                                  placeholder="Select Country"
                                  :options="countries"
                                  selected="231"
                                  @select="countryChange($event)"
                                />
                            </div>
                            <div class="col-12 col-sm-8 col-md-8 offset-sm-4 offset-lg-0 col-lg-4 col-xl-5" style=""><small class="form-text text-muted">Used in CMS 1500 form box#32a</small></div>
                        </div>
                                                              
                    </form>

                <div class="row mb-10">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9 text-center">
                        <form-btn title="Save" btnCls="btn-info mb-5" @click="save()"></form-btn>   
                        <form-btn type="button" title="Cancel" btnCls="waves-effect waves-light btn btn-danger mb-5 me-5"
                        @click="$router.push('/office/list')"></form-btn>
                        <form-btn title="Save & Next" btnCls="waves-effect waves-light btn btn-info mb-5 me-5"
                        @click="save(true)"></form-btn>
                    </div>      
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import submitMixins from "@/scripts/mixins/submitMixins.js";
import axios from "@/scripts/axios.js";
import useVuelidate from "@vuelidate/core";
import InputField from "@/components/base/formFields/InputField.vue";
import FormBtn from "@/components/base/formFields/formBtn.vue";
import Select2 from "@/components/base/formFields/select2.vue";
import TextArea from "@/components/base/formFields/textArea.vue";
export default {
    mixins: [submitMixins],
    setup: () => {
      return { v$: useVuelidate() };
    },
    data() {
        return {
            countries: null,
            states: null,
            office_or_provider_id: null,
            saveAndNext: false,
            EIN_true: false,
						SSN_true: false,
        };
    },
    validations() {
        return this.$store.state.officeBilling.validationRules;
    },
    components: { FormBtn, InputField, Select2, TextArea },
    mounted() {
        axios.get("/json_autocomplete/country").then(
          (response) =>
            (this.countries = response.data.data.map((country) => {
              return { id: country.id, text: country.name };
            }))
        );
        this.form.billing_provider_country = '231';
        var $this = this;
        axios
          .post(
            "/json_autocomplete/" +
              this.form.billing_provider_country +
              "/state"
          )
          .then(
            (response) =>
              ($this.states = response.data.data.map((state) => {
                return { id: state.id, text: state.name };
              }))
          );
    },
    async beforeCreate() {
        this.office_or_provider_id = this.$route.params.id;
        this.$store.state.officeBilling.form = {};
        Object.assign(
            this.$store.state.officeBilling.form,
            this.$store.state.officeBilling.defaultFormData
        );

        
        axios.get("office/"+this.office_or_provider_id+"/billing-details/edit")
            .then((response) => {
                    if(response.data.data.id)
                    {
                        this.$store.state.officeBilling.form = response.data.data
                        this.$store.state.officeBilling.form.billing_provider_country = String(this.$store.state.officeBilling.form.billing_provider_country);
                        this.$store.state.officeBilling.form.billing_provider_state = String(this.$store.state.officeBilling.form.billing_provider_state);                        
                    }
        });            
    },
    computed: {
        form() {
          return this.$store.state.officeBilling.form;
        },
        vuelidateExternalResults() {
          return this.$store.state.officeBilling.vuelidateExternalResults;
        },
        validationRules() {
          return this.$store.state.officeBilling.validationRules;
        },
        title() {
          return this.$route.name === "Office Billing";
        },
    },
    methods: {
        zipcode(){
          if(this.form.billing_provider_zipcode && this.form.billing_provider_zipcode.length > 3 ){
            axios.post("json_dropdown/state-city-auto-populations",{zip_code:this.form.billing_provider_zipcode})
            .then((response) => {
                this.form.billing_provider_city = response.data.data.City,
                this.form.billing_provider_state = response.data.data.id

                console.log(this.form.billing_provider_state);
            });
          }
          else{
            this.form.billing_provider_city= null;
            this.form.billing_provider_state=null;
          }
        },
        countryChange({ id, text }) {
            axios.post("/json_autocomplete/" + id + "/state").then(
            (response) =>
              (this.states = response.data.data.map((state) => {
                return { id: state.id, text: state.name };
              }))
            );
        },
        save(next){
            next ? (this.saveAndNext = true) : (this.saveAndNext = false);
            this.form.office_or_provider_id = this.$route.params.id;
            if(this.form.id!='' && typeof this.form.id!='undefined' && typeof this.form.id!=undefined && this.form.id!=null)
            {
                this.postForm(`/office/${this.$route.params.id}/billing-details/update`,"",true);
            }
            else
            {
                this.postForm(`/office/${this.$route.params.id}/billing-details/store`,"",true);
            }
        },
        formPostSuccess(data) {
            if (this.saveAndNext) {
                var officeId = this.$route.params.id;
                this.$router.push(`/office/${officeId}/room`);
            } else {
            }
            this.moshaToast(`Record saved successfully`,"success");
        },
    },
    // watch: {      
		// 	"form.federal_tax_type": function(newValue) {
		// 			if(newValue=='EIN'){
		// 				this.EIN_true=true
		// 				this.SSN_true=false
		// 			}else{
		// 				this.EIN_true=false
		// 				this.SSN_true=true
		// 			}
		// 	},
		// },
};
</script>
