<template>
  <div class="content-wrapper">
    <div class="container-full">
      <div class="content-header">
        <div class="row justify-content-between">
          <div class="col-12 col-sm-6 col-md-6 col-lg-8">
            <h1 class="heading1 mt-0">Email Templates Settings</h1>
          </div>
        </div>
      </div>
      <section class="content">
        <div class="box">
					<div class="box-body">
						<div class="row justify-content-between">
							<div class="col-12 col-sm-12 col-md-7 col-lg-8 col-xl-6">
								<div class="form-group float-start w-md-p30 mb-5 me-1">
									<select class="form-select" v-model="filter_category">
										<option selected="selected" disabled="disabled" value="">Select Category</option>
										<option value="general">General</option>
										<option value="appointment_reminder">Appointment Reminder</option>
										<option value="workflow">Work Flow</option>
										<option value="patient_contact">Patient Contact</option>
									</select>
								</div>
                <button
                  class="b-0 waves-effect waves-light btn btn-info py-7 me-1 ms-2"
                  @click="search(filter_category)">
                  Search
                </button>
                <button
                  class="b-0 waves-effect waves-light btn btn-info-light py-7 me-1 ms-2"
                  @click="Clear">
                  Clear
                </button>
							</div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-4 text-sm-end" v-if="filter_category == 'general'">
                <router-link to="/email-template/add" class="waves-effect waves-light btn btn-info">
                  <i class="fa fa-plus fs-14"></i> Mail Template
                </router-link>
          </div>
						</div>
					</div>
				</div>
        <div class="box">
          <div class="box-body">
            <div id="emailTemplate">
              <center>
                <span class="isvalid-feedback" style="display:block;">{{
                  success
                }}</span>
              </center>
              <data-table-server dataType="EmailTemplate" dataMethod="post" :key="key">
                <template v-slot="slotProps">
                  <td>{{ slotProps.item.template_name }}</td>
                  <td>{{ slotProps.item.subject_line }}</td>
                  <td>
                    <div class="d-flex">
                      <Popper arrow content="Edit Mail template" :hover="true" class="helptips cursor-pointer">
                        <a @click="edit(slotProps.item.id)" href="javascript:void(0)" class="waves-effect waves-circle btn btn-circle btn-info btn-xs me-1">
                      	 <i class="fa fa-pencil"></i>
                        </a>
                      </Popper>
                      <Popper arrow content="Preview email template" :hover="true" class="helptips cursor-pointer mt-0">
                        <a  href="javascript:void(0)" class="waves-effect waves-circle btn btn-circle btn-info btn-xs me-1" data-bs-toggle="modal" data-bs-target="#mypreviewModal" @click="showemailtemplate(slotProps.item.id)">
                            <i class="fa fa-eye"></i>
                          </a>
                        </Popper>
                    </div>
                  </td>
                </template>
              </data-table-server>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
<!--Modal start -->
<div id="mypreviewModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-lg">
		<div class="modal-content">
			<div class="modal-header">
				<h2 class="modal-title m-0">Email Template Preview</h2>
				<button type="button" class="btn-close btn-xs" data-bs-dismiss="modal" aria-label="Close"></button>
			</div>
			<div class="modal-body">
				<p>
					<strong>Subject: </strong>{{ subjectline}}
				</p>
        <p><strong>Mail Content: </strong></p>
				<div v-html="body_content"></div>
			</div>
      
			<div class="modal-footer">
				<button type="button" class="addpatientbtn btn-danger b-0" data-bs-dismiss="modal">Close</button>
			</div>
		</div>
	</div>
</div>
<!--Modal End -->
</template>

<script>
import DataTableServer from "@/components/base/DataTableServer.vue";
import axios from "@/scripts/axios.js";
import Popper from "vue3-popper";
export default {
  name: "Email Template",
  components: { DataTableServer, Popper },
  data() {
    return {
      key:0,
      subjectline:'',
      body_content:'',
      filter_category : 'general',
    }
  },
  mounted(){
      const prelink = this.$router.options.history.state.back;
      var name = prelink.split('/');
      if(name[1] != 'email-template') {
        this.checkPassword();
      }
  },
  computed:{
      filter(){
        return this.$store.state.EmailTemplate.filter;
      },
  },
  methods:{
    checkPassword() {
      this.$swal
        .fire({
          title: '<h2 class="mt-0">Retype your password</h2>',
          input: 'password',
          inputLabel: 'In order to go with settings in Email Template please reconfirm your password',
          inputPlaceholder: 'Your password',
          dangerMode: true,
          showCancelButton: false,
          confirmButtonColor: "#ee3158",
          cancelButtonColor: "#bdbdbd",
          confirmButtonText: "Yes, confirm it!",
          allowOutsideClick: false,
          allowEscapeKey: false,
          padding: '2em',
          inputAttributes: {
	          autocomplete: 'new-password'
          }
        })
        .then((result) => {
          if (result.isConfirmed == true) {
            axios
              .post(`email-template/check`, { password:result.value })
              .then((response) => {
                if(response.data.data == 'confirm' ) {
                  this.$swal
                  .fire({
                      title: "<h2 class='mt-0'>Success!</h2>",
                      html: "Your password is matched Successfully.",
                      icon:"success",
                      dangerMode: true,
                      confirmButtonColor: "#ee3158",
                      confirmButtonText: "Ok",
                      allowOutsideClick: false,
                      allowEscapeKey: false,
                    })
                  .then((result) => {
                    if (result.isConfirmed == true) {
                    }
                  });
                } else {
                    this.$swal
                    .fire({
                      title: "<h2 class='mt-0'>Error!</h2>",
                      html: "Opps,Your password doesn't mactched, please try again.",
                      icon:"error",
                      dangerMode: true,
                      confirmButtonColor: "#ee3158",
                      confirmButtonText: "Ok",
                      allowOutsideClick: false,
                      allowEscapeKey: false,
                    })
                    .then((result) => {
                      if (result.isConfirmed == true) {
                        this.checkPassword();
                      }
                    });
                  }
              });
          }
        }); 
    },
    Clear() {
      this.filter_category = "general";
      this.search('general');
    },
    search(category) {
      
      this.filter.category = category;
      this.key++;
    },
    edit(id){
      this.$router.push("/email-template/edit/"+id);
    },
    showemailtemplate(index){
			axios.get('email-template/edit/'+index)
			.then((response) => {
				this.subjectline = response.data.data.subject_line
				this.body_content = response.data.data.body_content
			 });
		}
  },
  beforeUnmount() {
    this.$swal.close();
  }
};
</script>
