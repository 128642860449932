<template>
  <div class="form-group row" :class="{ error: errors.length}">
    <label
      :class="labelClass"
      >{{ title }}
      <small v-show="isRequired" class="text-danger asterisksign">*</small></label
    >
    <div :class="divClass">
      <div class="controls">
        <input
          :id="title"
          type="color"
          name="text"
          class="form-control"
          :value="modelValue"
          @input="$emit('update:modelValue', $event.target.value)"
          :placeholder="placeholder1"
        />
        <span class="form-text text-muted" v-if="notes != ''">{{notes}}</span>
      </div>

      <div class="error-msg">
        <ul class="mb-0 p-0 fs-14">
          <li class="d-block" v-for="(error, index) of errors" :key="index">
            {{ error.$message }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: ""
    },
    modelValue: {
      type: String,
      default: ""
    },
    errors: {
      type: Array,
      default: []
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ""
    },
    labelClass:{
      type: String,
      default: "col-12 col-sm-6 col-md-4 col-xl-3 form-label text-sm-end"
    },
    divClass:{
      type: String,
      default: "col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3"
    },
    notes: {
      type: String,
      default: ""
    },
  },
  computed: {
    placeholder1() {
      return this.placeholder ? this.placeholder : this.title;
    },    
  },
  data() {
    return {
        
    };
  },
};
</script>