<template>
  <div class="panel pagecollap">
    <div class="panel-heading" id="mentalstatus" role="tab">
      <a
        class="panel-title"
        aria-controls="answer-12"
        aria-expanded="false"
        data-bs-toggle="collapse"
        href="#answer-12"
        data-parent="#accordion2"
        >Previous Cognitive Status
      </a>
    </div>
    <div
      class="panel-collapse collapse"
      id="answer-12"
      aria-labelledby="mentalstatus"
      role="tabpanel"
      data-bs-parent="#category-1"
    >
      <div class="panel-body">
        <div
          v-if="permissioncheck(this.$store.state.permission.create_update)"
        >
          <div class="form-group row">
            <label
              class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end"
              >Cognitive Status Observation</label
            >
            <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3">
              <select
                name="select"
                class="form-select form-control"
                v-model="form.snomed_code"
              >
                <option value="" selected="selected" disabled="disabled"
                  >Select Cognitive Status
                </option>
                <option
                  :value="item.id"
                  v-for="(item, index) in snomed_codes"
                  :key="index"
                  >{{ item.code }} -({{ item.description }})</option
                >
              </select>
            </div>
          </div>
          <div class="form-group row">
            <label
              class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end"
              >Effective Date</label
            >
            <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3 availdate">
              <ejs-datepicker :value="form.observation_date" id="observation_date_id" v-model="form.observation_date" :format="dateFormat" placeholder="Choose a date" @change="getdate"  :strictMode='strict'
               :max='current_date' :showClearButton='false' @blur="getdate" :openOnFocus='true'>
              </ejs-datepicker>

            </div>
          </div>
          <div class="row mb-20">
            <div class="col-12 col-sm-12 col-md-12 col-lg-11 col-xl-9 text-center">
            <button
              @click="save()"
              type="button"
              class="waves-effect waves-light btn btn-info"
              :disabled="!form.snomed_code"
            >
              Add Status
            </button>
            </div>
          </div>
        </div>
        <p class="font-bold fs-18">Previous Cognitive Status</p>
        <div class="table-responsive mb-0">
          <table-base
            :dataTypes="{
              dataType: 'CognitiveStatus',
              patient_id: patient_id,
              patient_unique_id: patient_unique_id,
              status: 'Results Received',
              method: 'get',
              title:'No any Cognitive status available.',
            }"
          >
            <template v-slot="slotProps">
              <td>{{ slotProps.item.snomed_code }}</td>
              <td>{{ slotProps.item.snomed_description }}</td>
              <td>
                {{ $filters.displayDateTimeLocal(slotProps.item.observation_date,'',false,"ddd, MM-DD-YYYY",true) }}
              </td>
            </template>
          </table-base>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TableBase from "@/components/base/Table.vue";
import CognitiveFunctionalSnomedCode from "@/components/base/formFields/CognitiveFunctionalSnomedCode.vue";
import useVuelidate from "@vuelidate/core";
import submitMixins from "@/scripts/mixins/submitMixins.js";
import axios from "@/scripts/axios.js";
import permissionMixins from "@/scripts/mixins/permission.js";
import moment from "moment";

export default {
  mixins: [submitMixins, permissionMixins],
  setup: () => {
    return { v$: useVuelidate() };
  },
  components: {
    TableBase,
  },
  computed: {
    form() {
      return this.$store.state.CognitiveStatus.form;
    },
    vuelidateExternalResults() {
      return this.$store.state.CognitiveStatus.vuelidateExternalResults;
    },
    validationRules() {
      return this.$store.state.CognitiveStatus.validationRules;
    },
  },
  mounted() {
    axios
      .get("/json_autocomplete/snomed-code-with-data-element", {
          params: {'data_element':'device_order_device_applied' },
       })
      .then((response) => (this.snomed_codes = response.data.data));

    var d = new Date();
    var month = d.getMonth() + 1;
    var day = d.getDate();

    this.current_date =
      d.getFullYear() +
      "-" +
      (("" + month).length < 2 ? "0" : "") +
      month +
      "-" +
      (("" + day).length < 2 ? "0" : "") +
      day;
  },
  methods: {
    getdate(){
      if($('#observation_date_id').val() == '') {
        this.form.observation_date = null;
      } else {
        this.form.observation_date = $('#observation_date_id').val();
      }
    },
    save() {
      if(this.form.observation_date != null  && this.form.observation_date != ''){
        this.form.observation_date = moment(String(this.form.observation_date)).format('YYYY-MM-DD');
      }
      
      axios
        .post(
          "/patient/" + this.$route.params.id + "/cognitive-status/store",
          this.form
        )
        .then((response) => this.formPostSuccess());
    },
    formPostSuccess() {
      this.$store.state.CognitiveStatus.form = {};
      this.$store.dispatch("getSimpleTableContents", this.dataTypes);
      this.moshaToast("Record added successfully.", "success");
      this.form.observation_date = '';
      this.form.snomed_code ='';
      this.observation_date = '';
    },
  },
  data() {
    return {
      current_date: "",
      snomed_codes: "",
      patient_id: this.$store.state.patientDetail.patient_id,
      patient_unique_id: this.$store.state.patientDetail.patient_unique_id,
      dataTypes: {
        dataType: "CognitiveStatus",
        patient_id: this.$store.state.patientDetail.patient_id,
        patient_unique_id: this.$store.state.patientDetail.patient_unique_id,
        method: "get",
      },
      waterMarkText : 'Choose a date',
      dateFormat: 'MM-dd-yyyy',
      observation_date : new Date(),
      strict: true,
    };
  },
  validations() {
    return this.$store.state.CarePlan.validationRules;
  },
};
</script>
