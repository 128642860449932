<template>
  <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-2">
    <div class="sticky-scroll-box">
      <div class="accorsidebar">
        <div class="accordion-item">
          <p class="accordion-header display-vertical" id="addpatienttab">
              <span class="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapsedemogr" aria-expanded="false" aria-controls="collapsedemogr">Appointment</span>
          </p>
          <div id="collapsedemogr" class="accordion-collapse collapse" aria-labelledby="addpatienttab" data-bs-parent="#accordionExample">
            <div class="side-navigation">
            <ul class="side-bar-menu side-bar-tree patientTabs" data-widget="tree">
              <li><router-link class="active" to="/appointment-profiles">{{title}}</router-link></li>
            </ul>
          </div>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      
    }
  },
  methods: {
  },
  computed :{
    title() {
      return this.$route.name === "AppointmentProfilesEdit"
        ? "Edit Visit Profile"
        : "Create Visit Profile";
    },
  }
}
</script>