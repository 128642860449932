import {
  helpers,
  required,
} from "@vuelidate/validators";

const subcription_plan = {
  namespaced: true,

  state: {
    listUrl: "/subscription-plan/list",
    getEditUrl: "/subscription-plan/edit/:id",
    tableData: {
      header: [
        'Code',
        'Plan Name',
        'Amount',
        'Discount',
        'Final Amount',
        'Status',
        'Actions',
      ],
    },
    validationRules: {
        form: {
           plan_code: {
              required: helpers.withMessage('Plan code is required.', required) ,
           },
           plan_name:{
              required : helpers.withMessage('Plan name is required.', required),
           },
           plan_amount: {
              required : helpers.withMessage('Amount is required.', required),
           },
           status: {
              required : helpers.withMessage('Status is required.', required),
           },
           plan_final_amount: {
              required : helpers.withMessage('Final amount is required.', required),
           },
           category: {
              required : helpers.withMessage('Category is required.', required),
           },
           duration: {
              required : helpers.withMessage('Duration is required.', required),
           },
           duration_type: {
              required : helpers.withMessage('Duration type is required.', required),
           },
           renewal_duration: {
              required : helpers.withMessage('Renewal duration is required.', required),
           },
           renewal_duration_type: {
              required : helpers.withMessage('Renewal duration type is required.', required),
           }
       },
    },
    vuelidateExternalResults: {
        form : {
          plan_code : '',
          plan_name : '',
          plan_amount : '',
          status : '',
          plan_final_amount : '',
          category : '',
          duration : '',
          duration_type : '',
          renewal_duration : '',
          renewal_duration_type : '',
        }
    },
    form : {
      id : '',
      plan_code : '',
      plan_name : '',
      discount_value : '0',
      plan_amount : '',
      plan_final_amount : '',
      category : 'HRT',
      duration : '',
      duration_type : '',
      status : '',
      renewal_duration : '',
      renewal_duration_type : '',
    },
    defaultFormData : {
     plan_code : '',
     plan_name : '',
     discount_value : 0,
     plan_amount : '',
     plan_final_amount : '',
     status : '',
     duration : '',
     duration_type : '',
     renewal_duration : '',
     renewal_duration_type : '',
     category : 'HRT',
   },
    list: [],
  },

  getters: {
    getTableHeaders: (state) => state.header,
    getTableContents: (state) => state.list,
    getListURL: (state) => state.listUrl,
  },
  mutations: {},
  actions: {},
};

export default subcription_plan;
