<template>
	<div class="box p-15">
		<div class="mdc-tab">
			<TabbarComp routefrom="MainBilling"></TabbarComp>
			<div class="tab-content">
				<div class="tab-pane active" id="mainBilling" role="tabpanel">
					<div class="box bg-lightest p-10 my-2">
					   <div class="text-md-right">
							<span class="waves-effect waves-light badge badge-info-light m-1 pull-left" style="font-weight: 500;">Total Billed   : {{ $filters.toUSD(this.patient_order_summary.payable_amount_sum) }}</span>

							<span class="waves-effect waves-light badge badge-info-light m-1 pull-left" style="font-weight: 500;">Total Paid   : {{ $filters.toUSD(this.patient_order_summary.received_amount_sum - this.patient_order_summary.refund_amount_sum ) }}</span>
							<span class="waves-effect waves-light badge m-1 pull-left" style="font-weight: 500;" :class="this.patient_order_summary.due_amount_sum > 0 ? 'badge-danger-light' : 'bg-success'">Total Due   : {{ $filters.toUSD(this.patient_order_summary.due_amount_sum) }}</span>
							<a href="javascript:void(0)" class="waves-effect waves-light btn btn-info me-1" @click="HistorymodalOpen"><i class="fa fa-eye fs-14"></i> Billing History </a>
							<a href="javascript:void(0)" class="waves-effect waves-light btn btn-info me-1" @click="newApptBilling"><i class="fa fa-plus fs-14"></i> Appt. Billing</a>
							<a href="javascript:void(0)" class="waves-effect waves-light btn btn-info" @click="newPOSOrder"><i class="fa fa-plus fs-14"></i> POS</a>
						</div>
					</div>
					<div class="table-responsive">
						<table class="table fs-16">
							<thead>
								<tr class="text-center">
									<th></th>
									<th>Source</th>
									<th>Order / Schedule Date</th>
									<th>Appt. / Ref. #</th>
									<th>Total Billed</th>
									<th>Received</th>
									<th>Refund</th>
									<th>Total Due</th>
									<th>Action</th>
								</tr>
							</thead>
							<tbody>
								<slot v-if="list.length>0">
									<slot v-for="(item, index) in list" :key="index">
										<tr>
											<td>
												<a href="javascript:void(0)" class="waves-effect waves-light btn btn-info-light btn-xs allopenbtn" @click="getReceipt(item.id)" v-bind:id="'add'+item.id">
													<i class="fa fa-plus"></i>
												</a>
												<a href="javascript:void(0)" class="waves-effect waves-light btn btn-danger-light btn-xs allclosebtn d-none" @click="receiptClose(item.id)" v-bind:id="'min'+item.id">
													<i class="fa fa-minus"></i>
												</a>
											</td>
											<td>{{item.order_source}} <span v-if="item.order_source == 'POS'" style="font-size: 13px;">({{ item.purposeofpayment }})</span></td>
											<td>{{item.order_source == 'Healthcare' ? $filters.displayDateTimeLocal(item.schedule_date,'',false,"ddd, MM-DD-YYYY",true) : $filters.displayDateTimeLocal(item.created_at,'',false,"ddd, MM-DD-YYYY",true) }}</td>
											<td>{{item.order_source == 'Healthcare' ? item.appointment_number : item.order_reference_no }}</td>
											<td class="text-end">
												<a href="javascript:void(0)" class="me-1 black-color" v-if="item.order_source == 'Healthcare'">
													<Popper arrow :hover="true" class="helptips">
														<i class="fa fa-info-circle fs-12"></i>
														<template #content>
															<div>HealthCare : {{ $filters.toUSD(item.healthcare)}}</div>
															<div>Custom Charge : {{ $filters.toUSD(item.custom_charge)}}</div>
														</template>
												</Popper>
												</a>
												{{ $filters.toUSD(item.payable_amount) }} 
											</td>
											<td class="bg-success-light text-end">{{ $filters.toUSD(item.received_amount) }}</td>
											<td class="text-end">{{ item.refund_amount > 0 ? '-'+$filters.toUSD(item.refund_amount) : $filters.toUSD(item.refund_amount) }}</td>
											<td class="text-end" :class="(item.payable_amount - item.received_amount + parseFloat(item.refund_amount)) > 0 ? 'bg-danger-light' : '' ">{{ $filters.toUSD((item.payable_amount - item.received_amount) + parseFloat(item.refund_amount) ) }}</td>
											<td class="ps-15">
												<a href="javascript:void(0)" class="waves-effect waves-light btn btn-info btn-sm me-1 mb-1" @click="makeOrderPayment(item.id)"><i class="fa fa-plus fs-12"></i> Payment</a>
												<a href="javascript:void(0)" v-if="item.order_source == 'Healthcare'" class="waves-effect waves-light btn btn-info-light btn-sm mb-1" @click="apptBillingModal(item)"><i class="fa fa-plus fs-12"></i> Billing</a>
												
												<div class="dropdown d-inline-block ms-2">
                                            <a class="text-info" href="#" data-bs-toggle="dropdown" aria-expanded="false" title="Extra Action">
                                                <i class="fa fa-ellipsis-v  "></i>
                                            </a>
                                            <div class="dropdown-menu dropdown-menu-end overflow-hidden p-10" style="min-width: 110px;bottom: 0;">
												<h4 class="fs-18 mt-0">Billing</h4>
													<a href="javascript:void(0)" class="waves-effect waves-circle btn btn-circle btn-info btn-xs me-1" :class="(item.received_amount > 0.00) ? '' : 'disabled text-info'" @click ="printReceipt(item.id, 'order')" title="View Full Receipt">
														<i class="fa fa-eye"></i>
													</a>
													<a href="javascript:void(0)" class="waves-effect waves-circle btn btn-circle btn-xs btn-primary" :class="(item.received_amount > 0.00) ? '' : 'disabled text-info'"  @click="sendReceipt(item.id,'order')" title="Email Full Receipt" v-if="item.is_receipt_send">
														<i class="fa fa-paper-plane"></i>
													</a>
													<a href="javascript:void(0)" class="waves-effect waves-circle btn btn-circle btn-xs btn-info" :class="(item.received_amount > 0.00) ? '' : 'disabled text-info'"   @click="sendReceipt(item.id,'order')" title="Email Full Receipt" v-else>
														<i class="fa fa-paper-plane"></i>
													</a>
												<div class="dropdown-divider" v-if="item.order_source != 'POS'"></div>
												<h4 class="fs-18" v-if="item.order_source != 'POS'">Invoice</h4>
													<a href="javascript:void(0)" class="waves-effect waves-circle btn btn-circle btn-xs btn-info me-1" @click="previewInvoice(item.appointment_number,'invoice')" title="Invoice" v-if="item.order_source != 'POS'">
														<i class="fa fa-file-text"></i>
													</a>
													<a href="javascript:void(0)" class="waves-effect waves-circle btn btn-circle btn-xs btn-info" @click="sendReceipt(item.appointment_number,'invoice')" v-if="item.order_source != 'POS'" title="Email Invoice">
														<i class="fa fa-paper-plane"></i>
													</a>
                                            </div>
                                        </div> 
											</td>
										</tr>
										<tr class="d-none receipt_hide" v-bind:id="'receipt'+item.id" >
											<td class="b-1" colspan="11" style="background: rgb(161 201 241);">
												<table class="table">
													<thead>
														<tr>
															<th></th>
															<th>Payment Date</th>
															<th>Amount</th>
															<th>Payment Type</th>
															<th>Payment Note</th>
															<th>Action</th>
														</tr>
													</thead>
													<tbody>
														<slot v-if="receiptlist.length > 0">
															<tr v-for="(items,index) in receiptlist" :key="index">
																<td></td>
																<td>{{$filters.displayDateTimeLocal(items.payment_date,'',false,"ddd, MM-DD-YYYY",true)}}</td>
																<td>${{items.paid_amount}}</td>
																<td>{{(items.payment_type == 'Other') ? items.other_payment_type : items.payment_type }}</td>
																<td>{{items.payment_note}}</td>
																<td>
																	<Popper arrow content="Refund" :hover="true" class="helptips" v-if="items.show_refund_icon == true" >
																		<a href="javascript:void(0)" class="waves-effect waves-circle btn btn-circle btn-info btn-xs me-1" @click ="refundPayment(items.id)">
																			<i class="fa fa-undo"></i>
																		</a>
																	</Popper>
																	<Popper arrow content="View Receipt" :hover="true" class="helptips">
																		<a href="javascript:void(0)" class="waves-effect waves-circle btn btn-circle btn-info btn-xs me-1" @click ="printReceipt(items.id, 'payment')">
																			<i class="fa fa-eye"></i>
																		</a>
																	</Popper>
																	<Popper arrow content="Email Receipt" :hover="true" class="helptips">
																		<a href="javascript:void(0)" class="waves-effect waves-circle btn btn-circle btn-xs" :class="items.is_receipt_send ? 'btn-primary' : 'btn-info' " @click="sendReceipt(items.id,'payment')">
																			<i class="fa fa-paper-plane"></i>
																		</a>
																	</Popper>
																</td>
															</tr>
														</slot>
														<slot v-else>
															<tr>
																<td colspan="5" class="text-center">No Record Found.</td>
															</tr>
														</slot>
													</tbody>
												</table>
											</td>
										</tr>
									</slot>
								</slot>
							</tbody>
							<tbody class="bg-lightest">
								<tr>
									<th colspan="4">Totals</th>
									<th  class="text-end">{{ $filters.toUSD(this.patient_order_summary.payable_amount_sum) }}</th>
									<th class="text-end bg-success-light">{{ $filters.toUSD(this.patient_order_summary.received_amount_sum) }}</th>
									<th class="text-end">{{ this.patient_order_summary.refund_amount_sum > 0 ? '-'+$filters.toUSD(this.patient_order_summary.refund_amount_sum) : $filters.toUSD(this.patient_order_summary.refund_amount_sum) }}</th>
									<th class="text-end" :class="this.patient_order_summary.due_amount_sum > 0 ? 'badge-danger-light' : 'badge-sucess-light'">{{ $filters.toUSD(this.patient_order_summary.due_amount_sum) }}</th>
									<th colspan="2"></th>
								</tr>
							</tbody>
							<tbody v-if="list.length==0">
								<tr>
									<td colspan="9" class="text-center"> No data available</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
<!--show Billing Filter start-->
	<div class="modal fade" id="filterbilling" tabindex="-1" role="dialog">
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-header">
					<h3 class="modal-title fw-900 mt-0">Billing History Duration</h3>
			   </div>
			   <div class="modal-body" style="max-height: 720px;overflow: auto;">
					<div class="form-group row align-items-center">
						<div class="col-12 col-sm-4 col-md-4 col-lg-7 col-xl-5 col-form-label mb-0 text-sm-end">
							<label class="mb-0">Select Duration</label>
					 	</div>
					 	<div class="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-7">
							<select class="form-select form-control" v-model="transaction_for" >
								<option value="Today">Today</option>
								<option value="Yesterday">Yesterday</option>
								<option value="Last_Week">Last Week</option>
								<option value="Last_Month">Last Month</option>
								<option value="Last_3_months">Last 3 months</option>
								<option value="Last_6_months">Last 6 months</option>
								<option value="Last_1_year">Last 1 year</option>
								<option value="Custom_Date">Custom Date</option>
							</select>
							<div class="availdate mt-5" v-if="transaction_for == 'Custom_Date'">	
								<ejs-daterangepicker format='MM-dd-yyyy' id="date_range" strictMode='strict' placeholder="Select Date Range" :value="date"  @change="onRangeSelect"></ejs-daterangepicker>
							</div>
						</div>
				  	</div>					
			   </div>
			   <div class="modal-footer">
					<a href="javascript:void(0)" class="waves-effect waves-light btn btn-info me-1" @click="getAllTransaction"><i class="fa fa-eye fs-14"> Preview </i> </a>
			   </div>
			</div>
		</div>
	</div>
<!--show Billing Filter end-->
<!--show Add Billing start-->
	<div class="modal fade" id="addbilling" tabindex="-1" role="dialog" aria-labelledby="addbilling" aria-hidden="true">
		<div class="modal-dialog modal-xl" style="max-width: 1140px;">
			<div class="modal-content">
				<div class="modal-header">
					<h3 class="modal-title fw-900 mt-0">Add Billing</h3>
			   	</div>
			   	<div class="modal-body" style="max-height: 720px;overflow: auto;">
					<div class="form-group row align-items-center appointment">
						<div class="col-0 col-sm-0 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end">
							&nbsp;
						</div>
						<div class="col-12 col-sm-12 col-md-8 col-lg-9 col-xl-9">
							<input type="radio" name="searchtype" style="position: unset; opacity: 1; margin-right: 10px;" :checked="searchType=='past'" @change="changeSearchType('past')" />Only display past appointments &nbsp;&nbsp;&nbsp;
							<input type="radio" name="searchtype" style="position: unset; opacity: 1; margin-right: 10px;" :checked="searchType=='future'" @change="changeSearchType('future')" />Only display future appointments &nbsp;&nbsp;&nbsp;
							<input type="radio" name="searchtype" style="position: unset; opacity: 1; margin-right: 10px;" :checked="searchType=='all'" @change="changeSearchType('all')" />Display all appointments
						</div>
					</div>
					<div class="form-group row align-items-center appointment">
						<div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end">
							<label class="mb-0">Select Appointment</label>
					 	</div>
					 	<div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3">
							<select class="select2Single form-select form-control" v-model="appointment_id" id="appointment_id"  @change="changeAppointment($event)">
									<option value="" disabled="disabled">Select Appointment</option>
								<template  v-for="(item,index) in apptDropdownList"  :key="index">
									<option v-if="item.imported_at == null " :value="item.id" :data-val="item.appointment_number">
										{{item.name}}
									</option>
								</template>
								<optgroup label="Imported Appointment">
									<template  v-for="(item,index) in apptDropdownList"  :key="index">
										<option :value="item.id" v-if="item.imported_at != null " :data-val="item.appointment_number" data-type="imported">
											{{ item.name }}
										</option>
									</template>
								</optgroup>
							</select>
							<div class="text-danger appointment_error d-none">Please select appointment </div>
					 	</div>
				  	</div>
					<AppointmentBilling v-if="appointment_number" :is_inline_nav="is_inline_nav" :AppointmentNumber="appointment_number" :permission="permissioncheck(this.$store.state.permission.create_update)" :key="appointment_number" paymentProfile="Cash" disabled="true" />
			   	</div>
			</div>
		</div>
	</div>
<!--show Add Billing end-->

<!--show Make Payment start-->
	<div class="modal fade" id="makepayment" tabindex="-1" role="dialog" aria-labelledby="makepayment" aria-hidden="true">
		<div class="modal-dialog" style="max-width: 600px;">
			<div class="modal-content">
				<div class="modal-header">
					<h3 class="modal-title fw-900 mt-0">Make Payment</h3>
			   </div>
			   <div class="modal-body">
					<payment-dialog v-if="order_id || new_pos" :orderId="order_id" :newPos="new_pos" :key="order_id"></payment-dialog>
			   </div>
			   <div class="modal-footer">
					<button type="button" class="waves-effect waves-light btn btn-danger" data-bs-dismiss="modal" @click="paymentDialogClose">Close</button>
			   </div>
			</div>
		</div>
	</div>
<!--show Make Payment end-->

<!--show Make Refund start-->
	<div class="modal fade" id="makerefund" tabindex="-1" role="dialog" aria-labelledby="makerefund" aria-hidden="true">
		<div class="modal-dialog" style="max-width: 600px;">
			<div class="modal-content">
				<div class="modal-header">
					<h3 class="modal-title fw-900 mt-0">Make Refund</h3>
			   </div>
			   <div class="modal-body">
					<refund-dialog v-if="order_payment_id" :orderPaymentId="order_payment_id" :key="order_payment_id"></refund-dialog>
			   </div>
			   <div class="modal-footer">
					<button type="button" class="waves-effect waves-light btn btn-danger" data-bs-dismiss="modal" @click="refundDialogClose">Close</button>
			   </div>
			</div>
		</div>
	</div>
<!--show Make Refund end-->

<!--viewReceipt Modal Start-->
<div class="modal" id="viewReceipt" tabindex="-1">
	<div class="modal-dialog modal-md" style="max-width:75%">
		<div class="modal-content">
			<div class="modal-header">
				<button type="button" class="btn-close btn-xs" data-bs-dismiss="modal" aria-label="Close"></button>
			</div>
			<div class="modal-body">
				<vue-pdf-viewer height="1050px" :url="receipt_url" v-if="embed=0">
				</vue-pdf-viewer>
				<iframe :src="receipt_url" width="100%" height="500px" v-if="embed=1" />
				<img srd="images/1.gif" v-else> 
			</div>
		</div>
	</div>
</div>
<!--viewReceipt Modal end-->
<!--viewInvoice Modal Start-->
<div class="modal" id="viewInvoice" tabindex="-1">
	<div class="modal-dialog modal-md" style="max-width:75%">
		<div class="modal-content">
			<div class="modal-header">
				<button type="button" class="btn-close btn-xs" data-bs-dismiss="modal" aria-label="Close"></button>
			</div>
			<div class="modal-body">
				<vue-pdf-viewer height="1050px" :url="receipt_url" v-if="embed=0">
				</vue-pdf-viewer>
				<iframe :src="receipt_url" width="100%" height="500px" v-if="embed=1" />
				<img srd="images/1.gif" v-else> 
			</div>
		</div>
	</div>
</div>
<!--viewInvoice Modal end-->
</template>
<script>
	import moment from 'moment';
	import axios from "@/scripts/axios.js";
	import AppointmentBilling from "@/views/Appointments/AppointmentBilling.vue";
	import PaymentDialog from "@/components/patients/details/Payment/PaymentDialog.vue";
	import RefundDialog from "@/components/patients/details/Payment/RefundDialog.vue";
	import permissionMixins from "@/scripts/mixins/permission.js";
	import Popper from "vue3-popper";
	import submitMixins from "@/scripts/mixins/submitMixins.js";
	import TabbarComp from "@/components/patients/details/Payment/TabbarComp.vue";
	import VuePDFViewer from 'vue-instant-pdf-viewer';
	export default {
		mixins: [permissionMixins,submitMixins],
		components: {
			AppointmentBilling,
			PaymentDialog,
			RefundDialog,
			Popper,
			TabbarComp,
			'vue-pdf-viewer': VuePDFViewer,
		},
		data() {
			return {
				list:[],
				patient_order_summary:[],
				appointment_number : '',
				appointment_id :'',
				order_id :'',
				order_payment_id :'',
				is_inline_nav : true,
				receiptlist:[],      
				receipt_url: '',
				new_pos: false,
				apptDropdownList: [],
				embed: 0,
				current_billing_order_id:'',
				searchType: 'past',
				transaction_for: 'Today',
			};
		},
		created() {
			this.date = moment().subtract(1, 'months').format("MM-DD-YYYY")+' - '+moment(new Date()).format("MM-DD-YYYY");
			this.patientOrderList();
			this.getApptDropdownList();			
		},
		methods: {
			previewInvoice(id,type){
				this.getSrc(id, type);
				$("#viewInvoice").modal("show");
			},
			HistorymodalOpen(){
				this.transaction_for = "Today";
				$("#filterbilling").modal("show");
			},
			onRangeSelect() {
				var daterange = document.getElementById("date_range").value;
				if(daterange != ''&& daterange != null){
					var date = daterange.split(' - ');
					this.pay_start_date = moment(String(date[0])).format('YYYY-MM-DD');
					this.pay_end_date = moment(String(date[1])).format('YYYY-MM-DD');
				}else{
					this.pay_start_date = null;
					this.pay_end_date = null;
				}
			},
			async getAllTransaction(){	
				$("#filterbilling").modal("hide");
				this.$store.state.loader = true;
				var patient_id = this.$store.state.patientDetail.patient_id;
				const res = await fetch(process.env.VUE_APP_API_BASE_URL+'/payment/get-all-patient-order', {
					method: 'POST',
					headers: {
						'Authorization' : 'Bearer '+localStorage.getItem('authToken'),
						'Content-Type' : 'application/json'
					},
					body: JSON.stringify({ patient_id: patient_id, start_date: this.pay_start_date, end_date: this.pay_end_date, transaction_for: this.transaction_for }),
				});
				const blob = await res.blob();
				const urlObject = URL.createObjectURL(blob);
				if (/Android|webOS|Opera Mini/i.test(navigator.userAgent)) {
					$("#viewReceipt").modal("hide");
					window.open(urlObject, '_blank', 'height=600,width=1200');
				} else {
					this.receipt_url = urlObject;
					this.browserCheck();
				}
				this.$store.state.loader = false;
				$("#viewReceipt").modal("show");				
			},
			changeAppointment($event) {
				var options = $event.target.options;
				if (options.selectedIndex > -1) {
					this.appointment_number = options[options.selectedIndex].getAttribute('data-val');
				} else {
					this.appointment_number = 0;
				}
				$('#addbilling').modal('show');
			},
			getApptDropdownList() {
				this.$store.state.loader = true;
				axios
				.post('/json_dropdown/patient-appointments',{patient_id:this.$store.state.patientDetail.patient_id,'appt_payment_type': 'Cash', 'appt_type': this.searchType})
				.then((response) => {
					this.apptDropdownList = response.data.data;
					this.$store.state.loader = false;
				})
			},
			patientOrderList() {
				this.$store.state.loader = true;
				axios.post("/payment/patient-order-list",{patient_id:this.$store.state.patientDetail.patient_id , 'order_summary_required' : true})
				.then((response) => {
				  this.list = response.data.data.patient_order_list;
				  this.patient_order_summary = response.data.data.patient_order_summary ? response.data.data.patient_order_summary : [];
				//   console.log(this.patient_order_summary);
				  this.$store.state.loader = false;
				});
			},
			changeTab (components) {
				this.$store.state.patientDetailsComp = components;
				let props = this.$router.resolve({ 
					name: components,
					params: { id: this.$route.params.id},
				});
				history.pushState(null, '',props.href);
			},
			apptBillingModal(item) {
				this.appointment_id = item.appointment_id;
				this.appointment_number = item.appointment_number;
				$('#addbilling').modal('show');
			},
			newApptBilling() {
				$('#addbilling').modal('show');
				this.appointment_id = '';
				this.appointment_number = '';
			},
			newPOSOrder() {
				$("#makepayment").modal('show');
				this.new_pos = true;
			},
			makeOrderPayment(order_id) {
				 $("#makepayment").modal('show');
				 this.order_id = order_id;
				 this.new_pos = false;
			},
			billingDialog(){
				this.$store.state.PaymentGatewayMigration.hidemodal = false;
				$("#addbilling").modal('hide');
				$("#makepayment").modal('hide');
				$("#makerefund").modal('hide');
				$('.receipt_hide').addClass('d-none');
				$('.allclosebtn').addClass('d-none');
				$('.allopenbtn').removeClass('d-none');
				this.patientOrderList();
				this.appointment_number = '';
				this.order_payment_id = '';
			},
			paymentDialogClose() {
				this.patientOrderList();
				this.order_id = '';
			},
			refundDialogClose() {
				this.patientOrderList();
				this.order_payment_id = '';
			},
			getReceipt(id){
				$('.receipt_hide').addClass('d-none');
				$('.allclosebtn').addClass('d-none');
				$('.allopenbtn').removeClass('d-none');
				var receiptid = "#receipt"+id;
				$(receiptid).removeClass('d-none');
				$("#add"+id).addClass('d-none');
				$("#min"+id).removeClass('d-none');
				this.$store.state.loader = true;
				this.current_billing_order_id = id;
				axios.post("/payment/pos-receipt-list",{patient_id:this.$store.state.patientDetail.patient_id,patient_billing_order_id:id})
				.then((response) => {
				  this.receiptlist = response.data.data;
				  this.$store.state.loader = false;
				}).catch((error) => {
					this.$store.state.loader = false;
					this.moshaToast(error.response.data.message, "danger");
				});
			},
			receiptClose(id) {
				$("#add"+id).removeClass('d-none');
				$("#min"+id).addClass('d-none');
				$("#receipt"+id).addClass('d-none');
			},
			printReceipt (id, type) {
				this.getSrc(id, type);
				$("#viewReceipt").modal("show");
			},
			refundPayment (id) {
				$("#makerefund").modal('show');
				this.order_payment_id = id;
			},
			async getSrc(id, type) {
				
				this.$store.state.loader = true;
				this.receipt_url="";
				var masterId = id;
				if (type == 'order') {
					const res = await fetch(process.env.VUE_APP_API_BASE_URL+'/payment/printOrderFullReceipt', {
						method: 'POST',
						headers: {
							'Authorization' : 'Bearer '+localStorage.getItem('authToken'),
							'Content-Type' : 'application/json'
						},
						body: JSON.stringify({ order_id: masterId }),
					});
					const blob = await res.blob();
					const urlObject = URL.createObjectURL(blob);
					this.$store.state.loader = false;
					if (/Android|webOS|Opera Mini/i.test(navigator.userAgent)) {
						$("#viewReceipt").modal("hide");
						window.open(urlObject, '_blank', 'height=600,width=1200');
					} else {
						this.receipt_url = urlObject;
						this.browserCheck();
					}
				}
				else if(type == 'invoice') {
					const res = await fetch(process.env.VUE_APP_API_BASE_URL+'/appointment-billing/invoice', {
						method: 'POST',
						headers: {
							'Authorization' : 'Bearer '+localStorage.getItem('authToken'),
							'Content-Type' : 'application/json'
						},
						body: JSON.stringify({ id: masterId }),
					});
					const blob = await res.blob();
					const urlObject = URL.createObjectURL(blob);
					this.$store.state.loader = false;
					if (/Android|webOS|Opera Mini/i.test(navigator.userAgent)) {
						$("#viewInvoice").modal("hide");
						window.open(urlObject, '_blank', 'height=600,width=1200');
					} else {
						this.receipt_url = urlObject;
						this.browserCheck();
					}
				} else {
					const res = await fetch(process.env.VUE_APP_API_BASE_URL+'/payment/printApptReceipt', {
						method: 'POST',
						headers: {
							'Authorization' : 'Bearer '+localStorage.getItem('authToken'),
							'Content-Type' : 'application/json'
						},
						body: JSON.stringify({ payment_id: masterId  }),
					});
					const blob = await res.blob();
					const urlObject = URL.createObjectURL(blob);
					this.$store.state.loader = false;
					if (/Android|webOS|Opera Mini/i.test(navigator.userAgent)) {
						$("#viewReceipt").modal("hide");
						window.open(urlObject, '_blank', 'height=600,width=1200');
					} else {
						this.receipt_url = urlObject;
						this.browserCheck();
					}
				}
			},
			browserCheck(){
				// Opera 8.0+
				var isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

				// Firefox 1.0+
				var isFirefox = typeof InstallTrigger !== 'undefined';

				// Safari 3.0+ "[object HTMLElementConstructor]" 
				var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));

				// Internet Explorer 6-11
				var isIE = /*@cc_on!@*/false || !!document.documentMode;

				// Edge 20+
				var isEdge = !isIE && !!window.StyleMedia;

				// Chrome 1 - 79
				var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

				// Edge (based on chromium) detection
				var isEdgeChromium = isChrome && (navigator.userAgent.indexOf("Edg") != -1);

				// Blink engine detection
				var isBlink = (isChrome || isOpera) && !!window.CSS;

				if (isChrome==true) {
					this.embed = 0;
				} else {
					this.embed = 1;
				}
			},
			sendReceipt(id, type) {
				if (type == 'order') {
					this.$store.state.loader = true;
					axios
					.post('payment/printOrderFullReceipt',{ order_id : id , send_email : true })
					.then((response) => {
						this.$store.state.loader = false;
						this.moshaToast('Email sent successfully.', "success");
						this.patientOrderList();
					})
					.catch((error) => {
						this.$store.state.loader = false;
						this.moshaToast("Coundn't sent email.", "danger");
					});
				} if(type == 'invoice') {
					this.$store.state.loader = true;
					axios
					.post('appointment-billing/invoice',{ id : id , send_email : true })
					.then((response) => {
						this.$store.state.loader = false;
						this.moshaToast('Email sent successfully.', "success");
						this.patientOrderList();
					})
					.catch((error) => {
						this.$store.state.loader = false;
						this.moshaToast("Coundn't sent email.", "danger");
					});
				} if(type == 'payment') {
					this.$store.state.loader = true;
					axios
					.post('payment/printApptReceipt',{ payment_id : id , send_email : true })
					.then((response) => {
						this.$store.state.loader = false;
						this.moshaToast('Email sent successfully.', "success");
						this.getReceipt(this.current_billing_order_id);
					})
					.catch((error) => {
						this.$store.state.loader = false;
						this.moshaToast("Coundn't sent email.", "danger");
					});

				}
			},
			changeSearchType(type) {
				this.searchType = type;
				this.getApptDropdownList();
				console.log(this.searchType);
			}
		},
		watch: {
			"$store.state.PaymentGatewayMigration.hidemodal": function() {
				if (this.$store.state.PaymentGatewayMigration.hidemodal == true) {
					this.billingDialog();
				}
			},
		}
	}
</script>