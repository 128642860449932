<template>
	<div class="content-wrapper">
		<div class="container-full">
			<section class="content">
				<h1 class="heading1 font-bold mt-0 mb-20 d-inline-block">COVID EXAMS (Add New Patient) 
					<Popper arrow content="It is a special form for exclusive covid patients." :hover="true" class="helptips cursor-pointer">
						<i class="fa fa-info-circle fs-20"></i>
					</Popper>
				</h1>
				<div class="box">
					<div class="box-body">
						<form novalidate>
							<div class="row">
								<div class="col-12 col-sm-6 col-md-4">
									<input-field
										title="Patient Token"
										v-model="form.patinet_ticket"
										@change="patinet_token"
										labelClass ="col-12 col-sm-12 col-md-12"
										divClass ="col-12 col-sm-12 col-md-12">
									</input-field>
								</div>

								<div class="col-12 col-sm-6 col-md-4">
									<div class="form-group row" :class="{ error: v$.form.date_of_birth.$errors.length }">
										<label>DOB <small class="text-danger asterisksign">*</small></label>
										<div class="col-12 col-sm-12 col-md-12 availdate">
											<ejs-datepicker 
												:value="date_of_birth" 
												id="date_of_birth" 
												:format="dateFormat" 
												placeholder="MM-dd-yyyy" 
												name="date_of_birth" 
												@change="getdate"
												:strictMode='true' 
												:showClearButton='false' 
												:openOnFocus='true'
												@blur="handleBlur">
											</ejs-datepicker>
											<div class="error-msg">
												<ul class="mb-0 p-0 fs-14">
													<li class="d-block" v-for="(error, index) of v$.form.date_of_birth.$errors" :key="index">
														{{ error.$message }}
													</li>
												</ul>
											</div>
										</div>
									</div>
								</div>

								<div class="col-12 col-sm-6 col-md-4">
									<input-field
										title="First Name"
										:isRequired="true"
										v-model="v$.form.first_name.$model"
										:errors="v$.form.first_name.$errors"
										labelClass ="col-12 col-sm-12 col-md-12"
										divClass ="col-12 col-sm-12 col-md-12">
									</input-field>
								</div>

								<div class="col-12 col-sm-6 col-md-4">
									<input-field
										title="Middle Name"
										v-model="form.middle_name"
										labelClass ="col-12 col-sm-12 col-md-12"
										divClass ="col-12 col-sm-12 col-md-12">
									</input-field>
								</div>

								<div class="col-12 col-sm-6 col-md-4">
									<input-field
										title="Last Name"
										:isRequired="true"
										v-model="v$.form.last_name.$model"
										:errors="v$.form.last_name.$errors"
										labelClass ="col-12 col-sm-12 col-md-12"
										divClass ="col-12 col-sm-12 col-md-12">
									</input-field>
								</div>

								<div class="col-12 col-sm-6 col-md-4">
									<input-field
										title="Email Address"
										v-model="form.email_address"
										labelClass ="col-12 col-sm-12 col-md-12"
										divClass ="col-12 col-sm-12 col-md-12">
									</input-field>
								</div>

								<div class="col-12 col-sm-6 col-md-4">
									<input-field
										title="Phone Number"
										:isRequired="true"
										mask="(000) 000-0000"
										v-model="v$.form.phone_no.$model"
										:errors="v$.form.phone_no.$errors"
										labelClass ="col-12 col-sm-12 col-md-12"
										divClass ="col-12 col-sm-12 col-md-12">
									</input-field>
								</div>

								<div class="col-12 col-sm-4 col-md-4">
			                        <div class="form-group row" :class="{ error: v$.form.sex.$errors.length }">
			                           <label class="col-12 col-sm-12 col-md-12">Gender <small class="text-danger asterisksign">*</small></label>
			                           <div class="col-12 col-sm-12 col-md-12">
			                              <select class="form-control" v-model="form.sex">
			                                 <option value="" selected="selected" disabled="disabled">Select Gender</option>
			                                 <option value="Male">Male</option>
			                                 <option value="Female">Female</option>
			                              </select>                                    
			                              <div class="error-msg">
			                                 <ul class="mb-0 p-0 fs-14">
			                                    <li class="d-block" v-for="(error, index) of v$.form.sex.$errors" :key="index">
			                                       {{ error.$message }}
			                                    </li>
			                                 </ul>
			                              </div>
			                           </div>
			                        </div>
		                     	</div>

		                     	<div class="col-12 col-sm-6 col-md-4">
									<text-area 
										title="Address" 
										v-model="v$.form.address.$model" 
										:errors="v$.form.address.$errors" 
										:isRequired="true"
										:rows="1"
										labelClass ="col-12 col-sm-12 col-md-12"
										divClass ="col-12 col-sm-12 col-md-12">
									</text-area>
								</div>

		                     	<div class="col-12 col-sm-4 col-md-4">
		                     		<div class="form-group row" :class="{ error: v$.form.country_id.$errors.length }">
	                					<label class="col-12 col-sm-12 col-md-12">Country <small class="text-danger asterisksign">*</small></label>
	              						<div class="col-12 col-sm-12 col-md-12">
		                					<Select2
							                  	v-model="v$.form.country_id.$model"
							                  	id="country_id"
							                  	placeholder="Select Country"
							                  	:options="countries"
							                  	@select="countryChange($event)"
							                />
		                					<div class="invalid-feedback" style="display:block;" v-for="(error, index) of v$.form.country_id.$errors" :key="index">
												{{ error.$message }}
		                					</div>
	              						</div>
            						</div>
		                     	</div>

		                     	<div class="col-12 col-sm-4 col-md-4">
		                     		<input-field 
		                     			title="Zipcode" 
		                     			v-model="v$.form.zip_code.$model"
										:errors="v$.form.zip_code.$errors"
										labelClass ="col-12 col-sm-12 col-md-12"
										divClass ="col-12 col-sm-12 col-md-12"
		                     			@focusout="zipcode"
		                     			:isRequired="true"
		                     			> 
		                     		</input-field>
		                     	</div>

		                     	<div class="col-12 col-sm-4 col-md-4">
			                     	<div class="form-group row" :class="{ error: v$.form.state_id.$errors.length }">
	              						<label class="col-12 col-sm-12 col-md-12">State <small class="text-danger asterisksign"> *</small></label>
	              						<div class="col-12 col-sm-12 col-md-12">
		                					<Select2
			                  					v-model="form.state_id"
			                  					id="state_id"
			                  					placeholder="Select State"
			                  					:options="states"
		                					/>
	                						<div class="invalid-feedback" style="display:block;" v-for="(error, index) of v$.form.state_id.$errors" :key="index">
	                							{{ error.$message }}
	                						</div>
	                					</div>
	            					</div>
	            				</div>

	            				<div class="col-12 col-sm-4 col-md-4">
		            				<input-field
						            	title="City"
						              	v-model="form.city"
						              	labelClass ="col-12 col-sm-12 col-md-12"
										divClass ="col-12 col-sm-12 col-md-12"
						              	placeholder="City"
						            />
						        </div>

						        <div class="col-12 col-sm-4 col-md-4">
			                     	<div class="form-group row" :class="{ error: v$.form.office_id.$errors.length }">
	              						<label class="col-12 col-sm-12 col-md-12">Office <small class="text-danger asterisksign"> *</small></label>
	              						<div class="col-12 col-sm-12 col-md-12">
		                					<Select2
			                  					v-model="form.office_id"
			                  					id="office_id"
			                  					placeholder="Select Office"
			                  					:options="office_list"
		                					/>
	                						<div class="invalid-feedback" style="display:block;" v-for="(error, index) of v$.form.office_id.$errors" :key="index">
	                							{{ error.$message }}
	                						</div>
	                					</div>
	            					</div>
	            				</div>


								<div class="col-12 col-sm-6 col-md-4">
									<div class="form-group row" :class="{ error: v$.form.schedule_time.$errors.length }">
										<label class="col-12 col-sm-12 col-md-12">Visit Date <small class="text-danger asterisksign">*</small></label>
										<div class="col-12 col-sm-12 col-md-12 availdate">
											<ejs-datetimepicker 
												id="schedule_time" 
												placeholder="MM-DD-YY HH:MM" 
												:value="schedule_time" 
												:showClearButton='false' 
												:format="visitdateFormats" 
												@change="scheduleDate"
												:step="15" 
												:openOnFocus='true'>
											</ejs-datetimepicker>
											<div class="error-msg">
												<ul class="mb-0 p-0 fs-14">
													<li class="d-block" v-for="(error, index) of v$.form.schedule_time.$errors" :key="index">
														{{ error.$message }}
													</li>
												</ul>
											</div>
										</div>
									</div>
								</div>

								<div class="col-12 col-sm-6 col-md-4">
									<div class="form-group row" >
										<label class="col-12 col-sm-12 col-md-12">Visit Profile <small class="text-danger asterisksign">*</small></label>
										<div class="col-12 col-sm-12 col-md-12 availdate">
											<select class="form-control me-2 h-36" id="visti-profile-select" v-model="form.appointment_profile_id" @change="onChangeAppointmentProfile">
												<option value="" disabled>Select Visit Profile</option>
												<option v-for="(appointmentprofile,index) of appointmentprofiles" :value="appointmentprofile.id" :key="index" :data-color_code="appointmentprofile.color_code" :data-default_duration="appointmentprofile.default_duration" :data-name="appointmentprofile.name" :data-consent_form_ids="appointmentprofile.consent_form_ids">{{appointmentprofile.name}}</option>
											</select>
											<div class="error-msg">
												<ul class="mb-0 p-0 fs-14">
													<!-- <li class="d-block" v-for="(error, index) of v$.form.appointment_profile_id.$errors" :key="index">
														{{ error.$message }}
													</li> -->
												</ul>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div class="col-12 col-sm-12 col-md-12">
								<check-box
									title=""
						            v-model="form.insurance"
						            @click="changeinsurance"
						            label="I have Insurance"
						            divClass="col-12 col-sm-12 col-md-12"
						            labelClass="col-12 col-sm-12 col-md-12"
						            >
								</check-box>
							</div>

							<div class="row align-items-center" v-if="form.insurance == 1">
								<div class="col-12 col-sm-6 col-md-4">
									<div class="form-group row" :class="{ error: v$.form.insurance_company_id.$errors.length }">								
										<label class="col-12 col-sm-12 col-md-12">Insurance Name <small class="text-danger asterisksign">*</small></label>
										<div class="col-12 col-sm-12 col-md-12 availdate">
											<div class="selectcontrols">
												<Autocomplete max="30" 
							                    	@input="getlist" 
							                        ref="autocomplete" 
							                        :results="insurance_list" 
							                        @onSelect="resultsvalue" 
							                        class="form-control ms-0 autosearch-list" 
							                        placeholder="Select insurance">                        
							                    </Autocomplete>		
							                </div>
											<div class="error-msg">
												<ul class="mb-0 p-0 fs-14">
													<li class="d-block" v-for="(error, index) of v$.form.insurance_company_id.$errors" :key="index">
														{{ error.$message }}
													</li>
												</ul>
											</div>
										</div>
									</div>
								</div>

								<div class="col-12 col-sm-6 col-md-4">
									<input-field
										title="Insurance ID / Policy No"
										:isRequired="true"
										v-model="v$.form.insurance_id_number.$model"
										:errors="v$.form.insurance_id_number.$errors"
										labelClass ="col-12 col-sm-12 col-md-12"
										divClass ="col-12 col-sm-12 col-md-12">
									</input-field>
								</div>

								<div class="col-12 col-sm-6 col-md-4">
									<check-box
										title=""
							            v-model="form.patient_is_subscriber"
							            @click ="patientissubscriber"
							            label="Subscriber is the Patient"
							            divClass="col-12 col-sm-12 col-md-12"
							            labelClass="col-12 col-sm-12 col-md-12"
							            >
									</check-box>
								</div>
							</div>

							<div class="row" v-if="form.patient_is_subscriber == 0">
								<div class="col-12 col-sm-6 col-md-4">
									<div class="form-group row" :class="{ error: v$.form.subscriber_relation.$errors.length }">								
										<label class="col-12 col-sm-12 col-md-12">Patient relationship to the Subscriber <small class="text-danger asterisksign">*</small></label>
										<div class="col-12 col-sm-12 col-md-12 availdate">
											<select class="form-control me-2 h-36" id="insurance_select" v-model="form.subscriber_relation">
												<option value="" disabled>Select Patient relationship to the Subscriber</option>
												<option value="1">Self</option>
												<option value="2">Spouse</option>
												<option value="3">Son</option>
												<option value="4">Daughter</option>
												<option value="5">Other</option>
											</select>									
											<div class="error-msg">
												<ul class="mb-0 p-0 fs-14">
													<li class="d-block" v-for="(error, index) of v$.form.subscriber_relation.$errors" :key="index">
														{{ error.$message }}
													</li>
												</ul>
											</div>
										</div>
									</div>
								</div>

								<div class="col-12 col-sm-6 col-md-4">
									<input-field
										title="Subscriber First Name"
										:isRequired="true"
										v-model="v$.form.subscriber_first_name.$model"
										:errors="v$.form.subscriber_first_name.$errors"
										labelClass ="col-12 col-sm-12 col-md-12"
										divClass ="col-12 col-sm-12 col-md-12">
									</input-field>
								</div>

								<div class="col-12 col-sm-6 col-md-4">
									<input-field
										title="Subscriber Middle Name"
										:isRequired="true"
										v-model="v$.form.subscriber_middle_name.$model"
										:errors="v$.form.subscriber_middle_name.$errors"
										labelClass ="col-12 col-sm-12 col-md-12"
										divClass ="col-12 col-sm-12 col-md-12">
									</input-field>
								</div>

								<div class="col-12 col-sm-6 col-md-4">
									<input-field
										title="Subscriber Last Name"
										:isRequired="true"
										v-model="v$.form.subscriber_last_name.$model"
										:errors="v$.form.subscriber_last_name.$errors"
										labelClass ="col-12 col-sm-12 col-md-12"
										divClass ="col-12 col-sm-12 col-md-12">
									</input-field>
								</div>

								<div class="col-12 col-sm-6 col-md-4">
									<text-area 
										title="Subscriber Address" 
										v-model="v$.form.subscriber_address.$model" 
										:errors="v$.form.subscriber_address.$errors" 
										:isRequired="true"
										:rows="1"
										labelClass ="col-12 col-sm-12 col-md-12"
										divClass ="col-12 col-sm-12 col-md-12">
									</text-area>
								</div>

								<div class="col-12 col-sm-6 col-md-4">
									<input-field 
										title="Subscriber Zipcode" 
										@focusout="subscriberzipcode" 
										v-model="v$.form.subscriber_zip_code.$model" 
										:errors="v$.form.subscriber_zip_code.$errors" 
										:isRequired="true"
										labelClass ="col-12 col-sm-12 col-md-12"
										divClass ="col-12 col-sm-12 col-md-12">
									</input-field>
								</div>

								<div class="col-12 col-sm-6 col-md-4">
									<input-field 
										title="Subscriber City" 
										v-model="v$.form.subscriber_city.$model" 
										:errors="v$.form.subscriber_city.$errors" 
										:isRequired="true"
										labelClass ="col-12 col-sm-12 col-md-12"
										divClass ="col-12 col-sm-12 col-md-12">
									</input-field>
								</div>

								<div class="col-12 col-sm-6 col-md-4">
									<div class="form-group row" :class="{ error: v$.form.subscriber_state_id.$errors.length }">
	  									<label class="col-12 col-sm-12 col-md-12">Subscriber State<small class="text-danger asterisksign">*</small></label>
	  									<div class="col-12 col-sm-12 col-md-12">
		    								<Select2
		    									v-model="v$.form.subscriber_state_id.$model"
		    									id="state_id"
		    									:errors="v$.form.subscriber_state_id.$errors"
		    									placeholder="Select State"
		    									:options="states"/>
		    								<div class="error-msg">
		      									<ul class="mb-0 p-0 fs-14">
		        									<li class="d-block" v-for="(error, index) of v$.form.subscriber_state_id.$errors" :key="index">
		          										{{ error.$message }}
		      										</li>
		  										</ul>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div class="row">
								<div class="col-12 col-sm-6 col-md-4 text-center">
									<web-cam btnname="Click and Upload ID Document" imgname="document"></web-cam>
									<img :src="form.document" class="img-fluid rounded5 my-10" style="width:200px">
								</div>
								<div class="col-12 col-sm-6 col-md-4 text-center">
									<web-cam btnname="Click and Upload Insurance Front" imgname="insurancefront"></web-cam>
									<img :src="form.insurancefront" class="img-fluid rounded5 my-10" style="width:200px">
								</div>
								<div class="col-12 col-sm-6 col-md-4 text-center">
									<web-cam btnname="Click and Upload Insurance Back" imgname="insuranceback"></web-cam>
									<img :src="form.insuranceback" class="img-fluid rounded5 my-10" style="width:200px">
								</div>
							</div>

							

							<div class="text-center">
								<a href="javascript:void(0)" class="waves-effect waves-light btn btn-info me-1" @click="save">Save</a>
								<a href="javascript:void(0)" class="waves-effect waves-light btn btn-danger" @click="clear">Clear</a>
							</div>
						</form>
					</div>
				</div>
			</section>
		</div>
	</div>

<!--model start-->
<div id="MyPatientModal" class=" MyPatientModal modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-lg">
		<div class="modal-content">
			<div class="modal-header">
				<h3 class="modal-title fw-900 mt-0">Patient List</h3>
			</div>
			<div class="modal-body">
				<div class="table-responsive">
					<table class="table">
						<thead>
							<tr>
								<th>Patient Name</th>
								<th>Email</th>
								<th>Action</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(item,index) in patinet_list">
								<td>{{item.first_name}} {{item.last_name}}</td>
								<td>{{item.email}}</td>
								<td><a @click="get_patient(index)" href="javascript:void(0)" class="waves-effect waves-light btn btn-info">Select</a></td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<div class="modal-footer">
				<a href="javascript:void(0)" class="waves-effect waves-light btn btn-danger" @click="close"> Close </a>
			</div>
		</div>
	</div>
</div>
<!--model end-->
</template>
<script>
	import InputField from "@/components/base/formFields/InputField.vue";
	import Select2 from "@/components/base/formFields/select2.vue";
	import CheckBox from "@/components/base/formFields/checkBox.vue";
	import TextArea from "@/components/base/formFields/textArea.vue";
	import WebCam from "@/views/CovidExams/Camera.vue";
	import axios from "@/scripts/axios.js";
	import submitMixins from "@/scripts/mixins/submitMixins.js";
	import useVuelidate from "@vuelidate/core";
	import moment from "moment";
	import Autocomplete from "vue3-autocomplete";
	import Popper from "vue3-popper";
	//import $ from 'jquery';
	
	export default {
		mixins: [submitMixins],
		setup: () => {
			return { v$: useVuelidate() };
		},
		computed: {
			form() {
				return this.$store.state.CovidExams.form;
			},
			vuelidateExternalResults() {
				return this.$store.state.CovidExams.vuelidateExternalResults;
			},
			validationRules() {
				return this.$store.state.CovidExams.validationRules;
			}
		},
		validations() {
			return this.$store.state.CovidExams.validationRules;
		},
		components: {
			InputField,
			WebCam,
			Select2,
			CheckBox,
			TextArea,
			Autocomplete,
			Popper,
		},
		data() {
			return {
				dateFormat: 'MM-dd-yyyy',
				date_of_birth:'',
				visitdateFormats : 'MM-dd-yyyy hh:mm a',
				appointmentprofiles:[],
				patinet_list:[],
				schedule_time : new Date(),
				countries:[],
				states:[],
				office_list:[],
				insurance_list:[],
			}
		},
		mounted(){
			this.form.appointment_profile_id = "";
			this.form.sex ='';			
			this.form.schedule_time = moment(new Date()).format('YYYY-MM-DD hh:mm a');
		    this.form.country_id = 231;
		    this.form.insurance = 0;
		    this.form.insurance_company_id ='';
		    this.form.patient_is_subscriber = 1;
		    this.form.subscriber_relation= '';
		    //this.$refs.autocomplete.searchText = '';
		},
		beforeCreate(){
			axios.get("/json_autocomplete/country").then(
		      (response) =>
		        (this.countries = response.data.data.map((country) => {
		          return { id: country.id, text: country.name };
		        }))
		    );
			this.$store.state.CovidExams.form = {};
			Object.assign(this.$store.state.CovidExams.form);
			axios.get("/json_dropdown/appointment-profile")
			.then((response) => {
				this.appointmentprofiles = response.data.data;
			});

			axios.post("/json_autocomplete/231/state")
			.then((response) =>
	        	(this.states = response.data.data.map((state) => {
	            	return { id: state.id, text: state.name };
	          	}))
	      	);

	      	axios.get("/json_dropdown/office")
			.then((response) =>
	        	(this.office_list = response.data.data.map((office) => {
	            	return { id: office.id, text: office.name };
	          	}))
	      	); 
	      	console.log(this.office_list);
		},
		methods:{
			getlist(obj) {
		    	if (obj.length > 1) {
		        	this.$refs.autocomplete.$el.classList.add("selectloader");
		        	axios.post("/covid/insurance-list", {term: obj})
		          	.then((response) => {
		            	this.insurance_list = response.data.data.map((insurance) => {
		              		return { id: insurance.id, name: insurance.insurance_name}
		            	});
		            	this.$refs.autocomplete.$el.classList.remove("selectloader");
		          	});
		      	}
		      	if (obj.length == 0) {
			        this.insurance_list = {};
		      	}
		    },
		    resultsvalue(obj) {
		      console.log(obj);
		      this.$emit("clicked", obj);
		      this.$refs.autocomplete.searchText = obj.name;
		      this.$store.state.CovidExams.form.insurance_company_id = obj.id;
		      this.focusout();
		    },
		    focusout() {
				const btn = this.$refs.autocomplete;
		      	btn.$el
		        .querySelector(".vue3-autocomplete-container > .autosearch-list")
		        .blur();
		    },
			clear(){
				location.reload();
			},

			countryChange({ id, text }) {
				axios.post("/json_autocomplete/" + id + "/state")
				.then((response) =>
		        	(this.states = response.data.data.map((state) => {
		            	return { id: state.id, text: state.name };
		          	}))
		      	);
		    },

			zipcode(){
				if(this.form.zip_code && this.form.zip_code.length > 3 ){
			        axios.post("json_dropdown/state-city-auto-populations",{zip_code:this.form.zip_code})
			        .then((response) => {
			            this.form.city=response.data.data.City;
			            this.form.state_id=response.data.data.id
			        });
			    }
			    else{
			        this.form.city= null;
			        this.form.state_id=null;
			    }
			},

			patinet_token(){
				this.form.insurance = 1;
				const article = { patinet_ticket: this.form.patinet_ticket };
				axios.post("covid-exams-patinet",article)
				.then((response) => {
					if(this.patinet_list != 'No Patient Found'){
						this.form.first_name = response.data.data.first_name;
						this.form.middle_name = response.data.data.middle_name;
						this.form.last_name = response.data.data.last_name;
						this.form.last_name = response.data.data.last_name;
						this.form.date_of_birth = moment(String(response.data.data.date_of_birth)).format('YYYY-MM-DD');
						this.date_of_birth = moment(String(response.data.data.date_of_birth)).format('MM-DD-YYYY');
						this.form.email_address = response.data.data.email;
						this.form.phone_no = response.data.data.cell_phone;
						this.form.sex = response.data.data.sex;
						this.schedule_time = moment(response.data.data.schedule_time).format('MM-DD-YYYY hh:mm a');
						this.form.schedule_time = moment(response.data.data.schedule_time).format('YYYY-MM-DD hh:mm a');
						this.form.color_code = response.data.data.color_code;
						this.form.duration = response.data.data.duration;
						this.form.reason_for_visit = response.data.data.reason_for_visit;
						this.form.appointment_profile_id  = response.data.data.appointment_profile_id;
						this.form.city  = response.data.data.city;
						this.form.state_id  = response.data.data.state_id;
						this.form.country_id  = response.data.data.country_id;
						this.form.office_id  = response.data.data.office_id;
						this.form.zip_code  = response.data.data.zip_code;
						this.form.patinet_id = response.data.data.patinet_id;
						this.form.appointment_id = response.data.data.appointment_id;
						this.form.patient_demographics = response.data.data.patient_demographics;
						this.form.insurance_id_number = response.data.data.insurance_id_number;
						this.form.address = response.data.data.address;
						if(this.form.insurance_id_number){
							this.form.insurance = 1;
							this.form.insurance_company_id = response.data.data.insurance_company_id;
							this.form.patient_is_subscriber = response.data.data.patient_is_subscriber;
							this.form.subscriber_relation = response.data.data.subscriber_relation;
							this.form.subscriber_first_name = response.data.data.subscriber_first_name;
							this.form.subscriber_last_name = response.data.data.subscriber_last_name;
							this.form.subscriber_middle_name = response.data.data.subscriber_middle_name;
							this.form.subscriber_address = response.data.data.subscriber_address;
							this.form.subscriber_zip_code = response.data.data.subscriber_zip_code;
							this.form.subscriber_city = response.data.data.subscriber_city;
							this.form.subscriber_state_id = response.data.data.subscriber_state_id;
							this.$refs.autocomplete.searchText  = response.data.data.insurance_company;
						}
						else{
							this.form.insurance = 0;
						}
					}
					else{
						this.form.appointment_profile_id = 18;
					}
				});
			},

			close(){
				$('#MyPatientModal').modal('hide');
				this.form.appointment_profile_id = 18;
			},

			get_patient(index){
				this.form.first_name = this.patinet_list[index]['first_name'];
				this.form.last_name = this.patinet_list[index]['last_name'];
				this.form.middle_name =  this.patinet_list[index]['middle_name'];
				this.form.email_address =  this.patinet_list[index]['email'];
				this.form.phone_no =  this.patinet_list[index]['cell_phone'];
				this.form.sex =  this.patinet_list[index]['sex'];
				this.form.zip_code =  this.patinet_list[index]['zip_code'];
				this.form.city  = this.patinet_list[index]['city'];
				this.form.state_id  = this.patinet_list[index]['state_id'];
				this.form.country_id  = this.patinet_list[index]['country_id'];
				this.form.appointment_profile_id = 19;
				this.form.address = this.patinet_list[index]['address'];
				$('#MyPatientModal').modal('hide');
			},

			handleBlur(){
				if(!this.form.patinet_ticket){
					const article = { date_of_birth: this.form.date_of_birth };
					axios.post("covid-exams-patinet",article)
					.then((response) => {
						this.patinet_list = response.data.data;
						if(this.patinet_list != 'No Patient Found'){
							$('#MyPatientModal').modal('show');
						}
						else{
							this.form.appointment_profile_id = 18;
						}
					});
				}
			},

			onChangeAppointmentProfile(e) {
				if (e.target.options.selectedIndex > -1) {
					const theTarget = e.target.options[e.target.options.selectedIndex].dataset;
					this.form.color_code = theTarget.color_code
					this.form.duration = theTarget.default_duration
					this.form.reason_for_visit = theTarget.name
				}
			},

			getdate(){
				if($('#date_of_birth').val() == '') {
					this.form.date_of_birth = '';
				} else {
					this.date_of_birth =  $('#date_of_birth').val();
					this.form.date_of_birth = moment(String(this.date_of_birth)).format('YYYY-MM-DD');
				}
			},

			scheduleDate(e){
				this.form.schedule_time = moment(e.value).format('YYYY-MM-DD hh:mm a');
			},

			save(){
				this.form.provider_id = this.$store.state.auth.activeProvider.id;
				//this.form.office_id = this.$store.state.auth.activeProvider.office_access;
				this.form.color_code = $("#visti-profile-select").find(':selected').attr('data-color_code');
				this.form.duration = $("#visti-profile-select").find(':selected').attr('data-default_duration');
				this.form.reason_for_visit = $("#visti-profile-select").find(':selected').attr('data-name');
				this.postForm(`/covid-exams-store`,"CovidExams",true,"formdata");
			},

			formPostSuccess(data) {
				this.$router.push(`/patient-chart/${data['unique_id']}/clinical-notes/`);
			},

			changeinsurance(){
				if(this.form.insurance === 0){
					this.$store.state.is_insurance = false
				}
				else{
					this.$store.state.is_insurance = true
				}
			},

			patientissubscriber(){
				if(this.form.patient_is_subscriber === 0){
					this.$store.state.is_patient_is_subscriber = true
				}
				else{
					this.$store.state.is_patient_is_subscriber = false
				}	
			},

			subscriberzipcode(){
				if(this.form.subscriber_zip_code && this.form.subscriber_zip_code.length > 3 ){
				    axios.post("covid/state-city-auto-populations",{zip_code:this.form.subscriber_zip_code})
				    .then((response) => {
				        this.form.subscriber_city=response.data.data.City;
				        this.form.subscriber_state_id=response.data.data.id
				    });
				}
			},
		}
	}
</script>