<template>
  <div class="tab-pane active" id="AddResourcesLink" role="tabpanel">
    <div class="patiboxdetail">
      <div class="authform-modal box">
        <div class="requiredinfo">
          <div class="pageheading">
            <h2 class="heading2">Resources {{this.$store.state.office.office_name}} <a href="#" data-bs-toggle="modal" data-bs-target="#resourcedocument" title="Reference of Knowledge Base" v-on:click.prevent="reset()"> <i class="fa fa-question-circle fs-20"></i></a></h2>
          </div>
          <form novalidate @submit.prevent="store">
            <div class="form-group row">
              <label
                class="col-form-label mb-0 col-12 col-sm-3 col-md-4 col-lg-4 col-xl-3"
                >Exam Rooms</label
              >
              <div class="col-12 col-sm-9 col-md-8 col-lg-5 col-xl-3">
                <select
                  class="form-control"
                  v-model="form.room_id"
                  @change="getResources($event)"
                >
                  <option value="">Select Room</option>
                  <option v-for="room in rooms" :value="room.id">{{
                    room.name
                  }}</option>
                </select>
              </div>
            </div>
            
            <div v-for="input in items" id="resources" class="form-group row">
              <label
                class="col-form-label mb-0 col-12 col-sm-3 col-md-4 col-lg-4 col-xl-3"
                :for="input.id"
                >{{ input.label }}</label
              >
              <div class="col-11 col-sm-8 col-md-7 col-lg-5 col-xl-3">
                <input
                  type="text"
                  :id="input.id"
                  :name="input.name"
                  v-model="input.value"
                  class="form-control"
                  id="resources2"
                  :placeholder="input.label"
                />
              </div>
              <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 mt-sm-5 p-0">
                <a href="#" @click.prevent="add($event)">
                  <i class="fa fa-plus-square text-info me-1"
                    v-if="input.editFrm === 0"></i>
                  </a>
                <a
                  href="#"
                  @click.prevent="remove(input.id, input.editFrm)"
                  v-if="lastVal != input.id"
                  ><i class="fa fa-minus-square text-danger me-1"></i
                ></a>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-sm-12 col-md-12 col-lg-9 text-center">
                <button
                  title = "Save"
                  type="button"
                  v-on:click="store('list')"
                  class="waves-effect waves-light btn btn-info mb-5 me-1"
                >
                  Save
                </button>
                <button
                  type="button"
                  @click="$router.push('/office/list')"
                  class="waves-effect waves-light btn btn-danger me-1"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  v-on:click="store('next')"
                  class="waves-effect waves-light btn btn-info mb-1"
                >
                  Save & Next
                </button>
              </div>
            </div>
          </form>
          <!--document model start-->
          <div id="resourcedocument" class="modal fade" tabindex="-1" role="dialog"
            aria-labelledby="myModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-md">
              <div class="modal-content">
              <div class="modal-header">
                <h3 class="modal-title fw-900 mt-0">Reference of Knowledge Base</h3>
              </div>
              <div class="modal-body overflow-auto" style="max-height: 350px;">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                    <th scope="col">No.</th>
                    <th scope="col">Knowledge Base Articles</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td> <a :href="path+'how-to-create-resources'" target="_blank">How To Create Resources?</a></td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td> <a :href="path+'how-to-remove-delete-resources'" target="_blank">How To Remove/ Delete Resources?</a></td>
                    </tr>
                  </tbody>
                </table>
                <Search :key="searchKey"></Search>
              </div>
              <div class="modal-footer">
                <button type="button" @click="" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
              </div>
              </div>
            </div>
          </div>
					<!--document model end-->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import submitMixins from "@/scripts/mixins/submitMixins.js";
import axios from "@/scripts/axios.js";
import FormBtn from "@/components/base/formFields/formBtn.vue";
import Search from "@/components/Search.vue";
export default {
  mixins: [submitMixins],
  data() {
    return {
      room_id:'',
      count: 1,
      rooms: null,
      items_new: null,
      items: [
        {
          id: "resources0",
          label: "Resource 1",
          value: "",
          editFrm: 0,
        },
      ],
      form: {
        room_id: null,
        name: [
          {
            name: "",
            id: "",
          },
        ],
      },
      lastVal: "resources1",
      path: process.env.VUE_APP_KB_DOCUMENT,
      searchKey:0,
    };
  },
  components: { FormBtn, Search },
  mounted() {
    if (this.$store.state.office.rooms_count == 0) {
      this.$router.push("/office/list");
    } else {
      axios
        .post("/json_dropdown/" + this.$route.params.id + "/room")
        .then((response) => (this.rooms = response.data.data));
    }
  },
  computed: {},
  methods: {
    reset() {
      this.searchKey = this.searchKey + 1;
    },
    add($event) {
      $event.target.remove();
      var count = ++this.count;
      this.items.push({
        id: `resource${count}`,
        label: `Resource ${count}`,
        value: "",
        editFrm: 0,
      });
      this.lastVal = `resource${count}`;
    },
    remove($id, $edit) {
      if ($edit == 0) {
        $("#" + $id)
          .parent()
          .parent()
          .remove();
      } else {
        this.$swal
          .fire({
            title: "Are you sure you want to delete this resource?",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
          })
          .then((result) => {
            if (result.isConfirmed == true) {
              axios
                .post(
                  "/office/" +
                    this.$route.params.id +
                    "/rooms/" +
                    $id +
                    "/resources/delete",
                  { id: $id }
                )
                .then((response) => {
                  $("#" + $id)
                    .parent()
                    .parent()
                    .remove();
                    this.items = [
                      {
                        id: "resources0",
                        label: "Resource 1",
                        value: "",
                        editFrm: 0,
                      },
                    ],
                    axios
                    .post("/json_dropdown/" + this.room_id + "/room-resources")
                    .then((response) => this.resourceSetup(response.data.data));
                });
            }
          });
      }
    },
    getResources($event) {
      if ($event.target.value != "") {
        this.room_id = $event.target.value;
        axios
          .post("/json_dropdown/" + $event.target.value + "/room-resources")
          .then((response) => this.resourceSetup(response.data.data));
      } else {
        this.empty(this.items);
        this.items.push({
          id: "resources0",
          label: "Resource 1",
          value: "",
          editFrm: 0,
        });
        this.count = 0;
      }
    },
    resourceSetup(response) {
      this.items.splice(0, this.items.length);
      var $this = this;
      if (response.length > 0) {
        $.each(response, function(key, val) {
          var count = ++key;
          $this.items.push({
            id: `${val["id"]}`,
            label: `Resource ${count}`,
            value: val["name"],
            editFrm: 1,
          });
        });
      }
      var len = ++response.length;
      this.count = len;
      $this.items.push({
        id: `resource${len}`,
        label: `Resource ${len}`,
        value: "",
        editFrm: 0,
      });
    },
    empty(list) {
      list.length = 0;
    },
    store(param) {
      var $this = this;
      $.each(this.items, function(key1, value1) {
        if (value1["value"] !== null) {
          $this.form.name.push({
            name: value1["value"],
            id: value1["id"],
          });
        }
      });

      axios
        .post("/office/"+this.$route.params.id+"/rooms/"+this.form.room_id+"/resources/store",this.form)
        .then((response) => {
          param == "list"
            ? $this.$router.push("/office/list")
            : $this.$router.push(
                `/office/${$this.$route.params.id}/schedule`
              ),
            $this.moshaToast("Record updated successfully.", "success");
        });
    },
  },
};
</script>
