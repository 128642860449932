<template>
    <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-10">
        <div class="box mb-10">
            <div class="box-body">
                <div class="pageheading">
                    <h2 class="heading2 font-bold">Eligibility Check</h2>
                </div>
                <div v-if="$route.params.appointments_id!=undefined && $route.params.appointments_id!=null">
					<AppointmentInsuranceBadge :AppointmentNumber="$route.params.appointments_id" />
				</div>
                <EligibilityCheck :patient_id="$route.params.id" :appointment_id="appointment_id" />
                <div class="text-center">
                    <button @click="close" class="waves-effect waves-light btn btn-danger mb-5 mt-1">Close</button>
                </div>                
            </div>
        </div>
    </div>
</template>
<script>
import AppointmentInsuranceBadge from "@/components/Appointments/AppointmentInsuranceBadge.vue";
import EligibilityCheck from "@/components/patients/details/PatientInformation/EligibilityCheck.vue"
import axios from "@/scripts/axios.js";
export default {
    data() {
        return {
            appointment_id: null,
        };
    },
    components: { EligibilityCheck, AppointmentInsuranceBadge },
    mounted() {
        axios.post("/appointment/single-appointment",{id:this.$route.params.appointments_id})
		.then((response) => {
			this.appointment_id = response.data.data.id;
		});              
    },
    methods:{
        close(){
            this.$router.push(`/patient-chart/${this.$route.params.id}/appointments/`); 
        },
    }
}
</script>

<style>

</style>