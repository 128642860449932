import moment from 'moment';
const RemittanceReport = {
    namespaced: true,  
    state: {
        listUrl: "/reports/remittance-report-list",
        header: [
            {"remit_check_date":"Check Date"},
            {"remit_received_date":"Posted Date"},
            {"remit_deposit_date":"Deposit Date"},
            {"remit_trace_number":"Check/Trace #"},
            {"remit_payer_name":"Insurance Co."},
            {"no_claims":"# of Claims"},
            {"total_amount":"Claimed Amount"},
            {"total_amount_paid":"Insurance Paid"},
            {"adjustment_amount":"Adjusted"},
            {"copay_amt":"Copay"},
            {"coinsurance_amt":"CoInsurance"},
            {"deductible_amt":"Deductible"},
            {"pr_paid_amt":"Paid to patient"},
            {"other_amt":"Other"},
            {"remit_payment_type":"Payment Method"},
            {"":"Action"},
        ],
        pagination: {
            to: "",
            from: "",
            total: "",
            last_page: "",
            current_page: "",
            pageNavArr: [],
        },
        filter: {
            start_date: moment().subtract(1, 'months').format("YYYY-MM-DD"),
            end_date:moment(new Date()).format("YYYY-MM-DD"),
            trace_number:null,
            date_for:'received',
            patient_id:null,
            payer_id:null,  
            remit_type:'',  
            report_number:"",
        },
        list: [],
    },
  };
  
  export default RemittanceReport;
  