<template :key="index">
    <div class="box">
        <div class="box-body">
            <div class="pageheading">
                <div class="row justify-content-between align-items-center">
                    <div class="col-12 col-sm-7 col-md-7 col-lg-6 col-xs-6">
                        <h3 class="font-bold d-inline-block">Active Medication Orders </h3>
                        <button class="waves-effect waves-light btn btn-info-light btn-xs ms-10 fetch_medication" @click="fetchMedication"><i class="fa fa-refresh"></i></button>
                    </div>
                    <div class="col-12 col-sm-5 col-md-5 col-lg-6 col-xs-4 text-sm-end" v-if="this.$store.state.auth.is_drfirst_enable == 'y' || (permissioncheck(
                    this.$store.state.permission.Rcopia_Access_Management) && this.$store.state.auth.is_rx_setup==true)">
                        <a href="javascript:void(0)" class="waves-effect waves-light btn btn-info mb-10 pull-right add_medication" @click="before_launchRxnt"><i class="fa fa-plus fs-14"></i> Medication </a>
                        <div class="bg-danger-light py-5 rounded5 fs-14 mb-10 w-p75 text-center" role="alert"
                        v-if="errorMessage">
                            {{ errorMessage }}
                        </div>
                    </div>
                </div>
                <form style="display:none" name="rcopiaForm" id="rcopiaForm" method="post">
                    <div>
                        <input type="text" id="rcopia_portal_system_name" name="rcopia_portal_system_name" value="" size="30" />
                        <input type="text" id="rcopia_practice_user_name" name="rcopia_practice_user_name" value="" size="30" />
                        <input type="text" id="rcopia_user_id" name="rcopia_user_id" value="" size="30" />
                        <input type="text" id="rcopia_user_external_id" name="rcopia_user_external_id" value="" size="100" />
                        <input type="text" id="rcopia_patient_id" name="rcopia_patient_id" value="{{ this.$store.state.patientDetail.patient_rcopia_id }}" size="15" />
                        <input type="text" id="rcopia_patient_system_name" name="rcopia_patient_system_name" value="" size="30" />            
                        <input type="text" id="rcopia_patient_external_id" name="rcopia_patient_external_id" value="{{ this.$store.state.patientDetail.patient_id }}" size="40" />
                        <input type="text" id="service" name="service" value="rcopia" size="30" />
                        <input type="text" id="startup_screen" name="startup_screen" value="patient" size="30" />
                        <input type="text" id="close_window" name="close_window" value="n" />
                        <input type="text" id="allow_popup_screens" name="allow_popup_screens" value="y" />
                        <input type="text" id="logout_url" name="logout_url" :value="return_url" size="100" />
                        <input type="text" id="timeout_url" name="timeout_url" :value="timeout_url" size="100" />
                        <input type="text" id="limp_mode" name="limp_mode" value="n" size="100" />
                    </div>
                </form>
            </div>
            <div class="controls d-block mb-2">
                <input type="checkbox" id="checkbox_1" class="filled-in chk-col-info" value="single" @change="show_inactive">
                <label for="checkbox_1" class="m-0">Show Inactive Medication</label>
            </div>
            <div class="table-responsive">
                <table class="table table-hover">
                    <thead>
                        <tr>
                            <th>Medication</th>
                            <th>Rxnorm ID</th>
                            <th>Dispense</th>
                            <th>Refills</th>
                            <th>SIG</th>
                            <th>Medication Status</th>
                            <th>Order Status</th>
                            <th>Start-Stop Date</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-if="medication.length>0" v-for="item in medication" :key="item.id">
                            <tr>
                                <td>{{ item.sig_drug_drug_description}}</td>
                                <td>{{ item.sig_drug_rxnorm_id }}</td>
                                <td>{{ item.sig_quantity }} {{ item.sig_quantity_unit }}</td>
                                <td>{{ item.sig_refills }}</td>
                                <td>{{ item.SIG }}</td>
                                <td>{{ item.Status }}</td>
                                <td></td>
                                <td>{{ item.start_date }} - {{ item.stop_date }}</td>
                                <td>
                                    <Popper arrow content="View Medication" :hover="true" class="helptips cursor-pointer">
                                        <a href="#" class="waves-effect waves-circle btn btn-circle btn-info btn-xs" @click="viewDetail(item.id)"><i class="fa fa-eye"></i></a>
                                    </Popper>
                                </td>
                            </tr>
                        </template>
                        <template v-else>
                            <tr>
                                <td colspan="9" class="text-center">No data available</td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
            <h3 class="font-bold d-inline-block" v-if="importedMedicationData.length>0">Imported Medication Orders ({{ import_source }})</h3>
            <div class="table-responsive" v-if="importedMedicationData.length>0">
                <table class="table table-hover">
                    <thead>
                        <tr>
                            <th>Medication</th>
                            <th>Rxnorm ID</th>
                            <th>SIG</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                            <th>Order Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="item in importedMedicationData" :key="item.id">
                            <tr>
                                <td>{{ item.product_name}}</td>
                                <td>{{ item.product_code }}</td>
                                <td>{{ item.sig }}</td>
                                <td>{{ item.start_date }}</td>
                                <td>{{ item.end_date }}</td>
                                <td>{{ item.order_status }}</td>
                                <td>
                                    <Popper arrow content="View Medication" :hover="true" class="helptips cursor-pointer">
                                        <a href="#" class="waves-effect waves-circle btn btn-circle btn-info btn-xs" @click="viewDetail(item.id, 'imported')"><i class="fa fa-eye"></i></a>
                                    </Popper>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="modal" tabindex="-1" id="medicationDetail">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h3 class="modal-title font-bold m-0">Detail Medication</h3>
                    <button type="button" class="btn-close btn-xs" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body" >
                    <div id="medication_detail_table" class="table-responsive"></div>
                </div>
            </div>
        </div>
    </div>    
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import axios from "@/scripts/axios.js";
import Popper from "vue3-popper";
import permissionMixins from "@/scripts/mixins/permission.js";

export default {
    components: {Popper,},
    mixins: [permissionMixins],
    data() {
        return {
            key: 0,
            index: 0,
            inactive: false,
            medication:[],
            importedMedicationData: [],
            medication_fetched_date: this.$store.state.patientDetail.medication_fetched_date,
            sso_token : '',
            return_url : window.location.href,
            timeout_url : window.location.href+"/#rx-err",
            user_type: "",
        };
    },
    watch: {},
    created() {
        this.fetchLocalActiveMed();
        this.importedMedication();
    },
    mounted() {
        /*RX SSO timeout Logs*/
        var location = window.location.href;
        if(location.indexOf('#rx-err')!=-1)
        {
            axios.post(`/patient/${this.$route.params.id}/create-rcopia-log`, { patient_id : this.$store.state.patientDetail.patient_id, patient_rcopia_id: this.$store.state.patientDetail.patient_rcopia_id, login_type: "SSO - Medication", response_type: "Timeout" });
        }
        /*RX SSO timeout Logs -- End*/
        $("#rcopia_patient_id").val(this.$store.state.patientDetail.patient_rcopia_id);
        this.user_type = this.user.user_type;
    },
    methods: {
        show_inactive: function(args){        
            this.inactive = (args.target.checked) ? true : false;        
            this.fetchLocalActiveMed();
        },
        viewDetail: function(id, imported=null){
            $("#medicationDetail").modal("show"); 
            axios
                .post(`/patient/${this.$route.params.id}/get-medication-detail`,{patient_id:this.$store.state.patientDetail.patient_id, medication: id, imported: imported})
                .then((response) => {
                    $("#medication_detail_table").html(response.data);
                });
            
        },
        before_launchRxnt: function(){
            $("a.add_medication").html("Loading Please wait..");
            
            if(this.$store.state.patientDetail.patient_rcopia_id==null)
            {
                axios
                    .post(`/patient/${this.$route.params.id}/rcopia_registration`,{patient_id: this.$store.state.patientDetail.patient_id, patient_rcopia_id:this.$store.state.patientDetail.patient_rcopia_id,source: 'medication' })
                    .then((response) => {
                        if(response.data.data.patient_rcopia_id)
                        {
                            $("#rcopia_patient_id").val(response.data.data.patient_rcopia_id);
                            this.$store.state.patientDetail.patient_rcopia_id = response.data.data.patient_rcopia_id;
                            this.launchRxnt();
                        }
                    })
                    .catch((error) => {
                        this.$swal.fire("Sorry!",error.response.data.message,"warning");
                    });
            }
            else
            {
                this.launchRxnt();
            }
        },
        launchRxnt : function() {
        /*Rcopia*/
        axios
            .post(`/patient/${this.$route.params.id}/rcopia-access`)
            .then((response) => {
                document.getElementById("rcopia_portal_system_name").value = response.data.data.system_name;
                document.getElementById("rcopia_practice_user_name").value = response.data.data.practice_user_name;
                document.getElementById("rcopiaForm").setAttribute("action",response.data.data.action_url);
                document.getElementById("rcopia_user_external_id").value = response.data.data.provider_external_id;
                var retries = response.data.data.no_of_retries, vendor_pwd = response.data.data.vendor_pwd;
                for(var i=0;i<retries;i++)
                {
                    var $this = this;
                    var responseFrm = this.launchRxntFrm(vendor_pwd);
                    if($this.frmSubmit == "Success")
                    {
                        /*Rcopia SSO Logs*/
                        axios.post(`/patient/${this.$route.params.id}/create-rcopia-log`, { patient_id : this.$store.state.patientDetail.patient_id, patient_rcopia_id: this.$store.state.patientDetail.patient_rcopia_id, login_type: "SSO - Medication" });
                        break;
                    }
                    else{
                        /*Rcopia SSO Logs*/
                        axios.post(`/patient/${this.$route.params.id}/create-rcopia-log`, { patient_id : this.$store.state.patientDetail.patient_id, patient_rcopia_id: this.$store.state.patientDetail.patient_rcopia_id, login_type: "SSO - Medication" })
                        /*Rcopia SSO Logs - End*/
                    }
                }
                this.index++;
            });
        /*Rcopia*/
        },
        launchRxntFrm : function(vendor_pwd) {

            $("a.add_medication").html('<i class="fa fa-plus fs-14"></i> Medication');
            
            document.getElementById("rcopia_patient_external_id").value = document.getElementById("rcopia_practice_user_name").value+document.getElementById("rcopia_portal_system_name").value+this.$store.state.auth.userData.clinic_id+'_'+this.$store.state.patientDetail.patient_id;

            if($("form#rcopiaForm input[name='MAC']").length>0)
            {
                var timenow = this.gmtTime();
                $("form#rcopiaForm input[name='time']").val(timenow);
                var strMAC = this.GenerateStringForMAC(timenow,vendor_pwd);
                var md5Hash = CryptoJS.MD5(strMAC).toString();
                md5Hash = md5Hash.toUpperCase();
                $("form#rcopiaForm input[name='MAC']").val(md5Hash);
            }
            else{
                var elem = document.createElement("input");
                elem.setAttribute("type", "text");
                elem.setAttribute("name", "action");
                elem.setAttribute("id", "action");
                elem.setAttribute("value", "login");
                document.rcopiaForm.appendChild(elem);

                var timenow = this.gmtTime();
                var elem = document.createElement("input");
                elem.setAttribute("type", "text");
                elem.setAttribute("name", "time");
                elem.setAttribute("id", "time");
                elem.setAttribute("value", timenow);
                document.rcopiaForm.appendChild(elem);

                var elem = document.createElement("input");
                elem.setAttribute("type", "text");
                elem.setAttribute("name", "skip_auth");
                elem.setAttribute("id", "skip_auth");
                elem.setAttribute("value", "n");
                document.rcopiaForm.appendChild(elem);

                var strMAC = this.GenerateStringForMAC(timenow,vendor_pwd);
                var md5Hash = CryptoJS.MD5(strMAC).toString();
                md5Hash = md5Hash.toUpperCase();
                
                elem = document.createElement("input");
                elem.setAttribute("type", "text");
                elem.setAttribute("name", "MAC");
                elem.setAttribute("value", md5Hash);
                document.rcopiaForm.appendChild(elem);
            }
            let $this = this;
            function submitToPopup(f) {
                f.target = '_blank';
                try {
                    f.submit();
                    $this.frmSubmit = 'Success';
                }
                catch(err) {
                    $this.frmSubmit = 'Error';
                }
            }
            return submitToPopup(document.rcopiaForm);
        },
        GenerateStringForMAC : function(timenowtemp,vendor_pwd) {
            var str = "rcopia_portal_system_name=" + document.getElementById("rcopia_portal_system_name").value +
            "&rcopia_practice_user_name=" + document.getElementById("rcopia_practice_user_name").value +
            "&rcopia_user_id=" + document.getElementById("rcopia_user_id").value +
            "&rcopia_user_external_id=" + document.getElementById("rcopia_user_external_id").value +
            "&rcopia_patient_id=" + document.getElementById("rcopia_patient_id").value +
            "&rcopia_patient_system_name=" + document.getElementById("rcopia_patient_system_name").value +
            "&rcopia_patient_external_id=" + document.getElementById("rcopia_patient_external_id").value +
            "&service=" + document.getElementById("service").value +
            "&startup_screen=" + document.getElementById("startup_screen").value +
            "&close_window=" + document.getElementById("close_window").value +
            "&allow_popup_screens=" + document.getElementById("allow_popup_screens").value +
            "&logout_url=" + document.getElementById("logout_url").value +
            "&timeout_url=" + document.getElementById("timeout_url").value +
            "&limp_mode=" + document.getElementById("limp_mode").value +
            "&action=login" + 
            "&time=" + timenowtemp +
            "&skip_auth=" + document.getElementById("skip_auth").value +
            //"&posturl=" + posturl.Value +
            vendor_pwd;
            return str;
        },
        gmtTime : function () {
            var GMTTime = new Date();
            var month = GMTTime.getUTCMonth() + 1;
            var day = GMTTime.getUTCDate();
            var year = GMTTime.getUTCFullYear() - 2000;
            var hour = GMTTime.getUTCHours();
            var min = GMTTime.getUTCMinutes();
            var sec = GMTTime.getUTCSeconds();

            if (month < 10) { month = "0" + month; }
            if (day < 10) { day = "0" + day; }
            if (hour < 10) { hour = "0" + hour; }
            if (hour < 1) { hour = "00"; }
            if (min < 10) { min = "0" + min; }
            if (min < 1) { min = "00"; }
            if (sec < 10) { sec = "0" + sec; }
            if (sec < 1) { sec = "00"; }

            return  month.toString() + day.toString() + year.toString() + hour.toString() + min.toString() + sec.toString();
        },
        downloadActiveMedsAndAllergyByPatient : function() {
             this.$store.state.loader = true;
             axios
             .post("/downloadActiveMedsAndAllergyByPatient", { patient_id : this.$store.state.patientDetail.patient_id })
             .then((response) => {
                this.$store.state.loader = false;
                this.fetchLocalActiveMed();
            })
             .catch((error) => {
                this.errorMessage = error.response.data.message;
            });
        },
        fetchLocalActiveMed : function(msg='') {
            axios
            .post(`/patient/${this.$route.params.id}/get-medications`, { patient_id : this.$store.state.patientDetail.patient_id, show_inactive: this.inactive })
            .then((response) => {
                if(response.data.data.length >0){
                    this.medication = response.data.data;
                    this.medication_fetched_date = response.data.data[0]['medication_fetched_date'];
                    if(msg!='')
                    {
                        this.$swal.fire("Fetched!","Medication fetched successfully.","success");
                    }
                }
                else
                {
                    if(msg!='')
                        this.$swal.fire("Fetched!","No Medication Found.","warning");
                }
            })
            .catch((error) => {
                this.errorMessage = error.response.data.message;
            });
        },
        fetchMedication: function() {
            if(this.$store.state.patientDetail.patient_rcopia_id == null)
            {
                this.$swal
                    .fire({
                      title: '<h2 class="mt-0">Sorry!</h2>',
                      text:
                        "The patient is not available in Rcopia. Please add the patient in Rcopia to write medication. Do you want to create patient in Rcopia now?",
                      icon: "warning",
                      dangerMode: true,
                      showCancelButton: true,
                      confirmButtonColor: "#ee3158",
                      cancelButtonColor: "#bdbdbd",
                      confirmButtonText: "Yes, please!",
                    })
                    .then((result) => {
                        this.before_launchRxnt();
                    });
            }
            else
            {
                $("button.fetch_medication").html("<i class='fa fa-sync fa-spin'></i>");
                axios
                .post(`/patient/${this.$route.params.id}/fetch-medication`, { patient_id : this.$store.state.patientDetail.patient_id, patient_rcopia_id: this.$store.state.patientDetail.patient_rcopia_id, medication_fetched_date : this.medication_fetched_date })
                .then((response) => {
                    $("button.fetch_medication").html('<i class="fa fa-refresh"></i>');
                    this.key++;
                    this.fetchLocalActiveMed("msg");
                })
            }
        },
        importedMedication: function() {
            axios
            .post(`/patient/${this.$route.params.id}/get-imported-medication`, { patient_id : this.$store.state.patientDetail.patient_id })
            .then((response) => {
                this.import_source = response.data.data.source;
                this.importedMedicationData = response.data.data.loop_data;
            });
        }

    },
    computed: {
        ...mapGetters("auth", ["user", "provider_list", "active_provider"]),
    },
};
</script>