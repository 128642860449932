<template>
  <div class="form-group row" :class="{ error: errors.length}">
    <label
      :class="labelClass"
      >{{ title }}
      <small v-show="isRequired" class="text-danger asterisksign">*</small></label
    >
    <div :class="divClass">
      <div class="controls">
        <input
          :id="title"
          type="date"
          name="text"
          class="form-control"
          :value="modelValue"
          :min="minDate"
          :max="maxDate"
          @input="$emit('update:modelValue', $event.target.value)"
          :placeholder="title"
        />
        <span class="form-text text-muted" v-if="notes != ''">{{notes}}</span>
      </div>

      <div class="error-msg fs-14">
        <ul class="mb-0 p-0">
          <li class="d-block" v-for="(error, index) of errors" :key="index">
            {{ error.$message }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: ""
    },
    modelValue: {
      type: String,
      default: ""
    }, 
    errors: {
      type: Array,
      default: []
    }, 
    labelClass:{
      type: String,
      default: "col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end"
    },
    divClass:{
      type: String,
      default: "col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3"
    },
    notes: {
      type: String,
      default: ""
    },
    minDate: {
      type: String,
      default: ""
    },
    maxDate:{
      type: String,
      default: ""
    },
    isRequired: {
      type: Boolean,
      default: false
    },
  },
};
</script>