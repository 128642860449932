<template>
	<div class="content-wrapper">
		<div class="container-fluid">
			<section class="content">
				<div class="content-header">
					<h1 class="heading1 m-0">Support</h1>
				</div>
				<div class="box">
					<div class="box-body">
						<div class="form-group">
							<label class="form-label">To</label> <small class="text-danger asterisksign">*</small>
							<input id="Support" type="text" class="form-control" placeholder="Support" value="Support" readonly>
						</div>
						<input-field 
							title="Subject"
							:isRequired="true"
							v-model="subject"
							divClass="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
							labelClass="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
							>
						</input-field>
						<div class="form-group">
							<ejs-richtexteditor
								:pasteCleanupSettings="pasteCleanupSettings"
								:toolbarSettings="toolbarSettings"
								:quickToolbarSettings='quickToolbarSettings'
								:insertImageSettings="insertImageSettings"
								:imageUploading="imageUploading"
								:imageRemoving="imageRemoving"
								:afterImageDelete="afterImageDelete"
								:width="width"
		                        :height="height"
								ref="rteRef" 
								title="Message"
								
								divClass="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
								labelClass="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
								rows="5">
							</ejs-richtexteditor>
						</div>
						<file-upload-multiple 
							title="Attach File"
							v-on:change="onImageChange"
							v-model="attach_file"
							divClass="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
							labelClass="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
						</file-upload-multiple>
						<span @click="sent" class="waves-effect waves-light btn btn-info me-1" v-if="!loading">Send</span>
						<button type="button" title="Send" id="sent-btn" class="waves-effect waves-light btn btn-info-light me-1" v-if="loading">
							<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
							Sending ...
						</button>
						<a href="/#/support" class="waves-effect waves-light btn btn-danger me-1">Cancel</a>
					</div>
				</div>
			</section>
		</div>
	</div>
</template>

<script>
import InputField from "@/components/base/formFields/InputField.vue";	
import FormBtn from "@/components/base/formFields/formBtn.vue";
import FileUploadMultiple from "@/components/base/formFields/FileUploadMultiple.vue";
import axios from "@/scripts/axios.js";
import submitMixins from "@/scripts/mixins/submitMixins.js";
export default {
	props: {
        value: String,
        width: Number,
        height: Number,
    },
	mixins: [submitMixins],
	components: {
		InputField,
		FormBtn,
		FileUploadMultiple,
	},
	data(){
		return {
			master_id: this.$route.params,
			subject:"",
			message:"",
			file_name:"",
			attach_file:[],
			loading: false,
			insertImageSettings:{
				path: process.env.VUE_APP_API_BASE_URL.substr(0,process.env.VUE_APP_API_BASE_URL.lastIndexOf('/'))+"/storage/attach_file/",
				saveUrl: process.env.VUE_APP_API_BASE_URL+"/support/file/store",
				display: 'inline'
			},
			toolbarSettings: {
		     items: ['Bold', 'Italic', 'Underline', 'StrikeThrough','FontName', 'FontSize','Formats', 'Alignments', 'OrderedList', 'UnorderedList','Outdent', 'Indent','CreateLink', 'Image','CreateTable', 'ClearFormat','SourceCode', 'FullScreen', 'Undo', 'Redo']
		   },
		    quickToolbarSettings: {
                table: ['TableHeader', 'TableRows', 'TableColumns', 'TableCell', '-', 'BackgroundColor', 'TableRemove', 'TableCellVerticalAlign', 'Styles']
            },
            pasteCleanupSettings: {
	            prompt: true,
	            plainText: false,
	            keepFormat: false,
	            deniedTags: ['a'],
	            deniedAttrs: ['class', 'title', 'id'],
	            allowedStyleProps: ['color', 'margin', 'font-size']
        	},
		};
	},
	methods:{
		sent(){

			this.loading=true;
			this.message=this.$refs.rteRef.ej2Instances.value;
			if(this.message=="null" || this.message=="" || this.message==null ){
	          this.message="";
	        }

			var form = new FormData();
			var file_list=[];
			var attach_file = this.attach_file;
			for(var i = 0; i < attach_file.length; i++) {
			    form.append('attach_file['+i+']', attach_file[i]);
			}
			
			form.append('subject', this.subject);
			form.append('message', this.message);
			var $this=this;
			axios.post('/support/store',form)
    		.then((response) => {
                this.moshaToast("Ticket added successfully !!", "success");
                setTimeout(function(){ $this.$router.push("/support") }, 3000);
            this.loading=false;
            }).catch((error) => {
                if (error.response.status == 422) {
              		this.moshaToast("Please enter something in message box !!", "danger");
	            }else{

	              this.moshaToast("Oops, Something when wrong!.", "danger");
	            }
            	this.loading=false;

            });
		},

		// Image upload through drag & drop OR popup	
		imageUploading(args){
			
			var token = localStorage.getItem("authToken");

			this.file_name = Date.now()+"_"+(Math.random() + 1).toString(36).substring(7)+"."+args.fileData.type;
			args.fileData.name = this.file_name;
			args.fileData.id=this.file_name;

			args.currentRequest.setRequestHeader("Authorization", `Bearer ${token}`);

			args.currentRequest.setRequestHeader("File-Name", this.file_name);
		},

		// delete image in popup
		imageRemoving(args){
			var token = localStorage.getItem("authToken");

			var xmlhttp = new XMLHttpRequest();
			xmlhttp.open('POST', process.env.VUE_APP_API_BASE_URL+"/support/file/remove");
			xmlhttp.setRequestHeader("Authorization", `Bearer ${token}`);
			xmlhttp.setRequestHeader("File-Name", this.file_name);
			xmlhttp.send(null);
		},

		// delete image in editor
		afterImageDelete(args){
			this.file_name = args.src.substring(args.src.lastIndexOf('/') + 1);
			this.imageRemoving(null);

		},

		// // insert image through link
		// actionBegin(args){

		// 	var image = args.itemCollection.url;

		// 	console.log(image)
		// 		var form = new FormData();
		// 		form.append('UploadFiles', blob);
		// 		form.append('File_Name', Date.now()+"_"+(Math.random() + 1).toString(36).substring(7)+"."+"jpeg");

		// 		axios.post('/support/file/store',form);

		//     console.log("end");

		// }
	}
}
</script>
