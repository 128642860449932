<template>
  <div class="content-wrapper">
    <div class="container-full">
      <section class="content">
        <div class="row justify-content-sm-center">
            <div class="col-12 col-sm-12 col-md-9 col-lg-6">
              <div class="box">
                <div class="box-body">
                  <div class="content-header">
                    <h1 class="heading1 m-0">{{title}}</h1>
                  </div>
                  <div class="form-group">
                    <label class="form-label">Provider Name</label>
                    <div class="controls">
                        <select class="form-control" v-model="provider_id" @change="providerstaffselect(provider_id,'provider')">
                            <option value="0">Select Provider</option>
                            <option v-for="(item,index) in provider" :data-id="index" :value="item.id">{{item.name}}</option>
                        </select>
                    </div>
                  </div>
                  <div class="form-group">
                    <label class="form-label">Staff Name</label>
                    <div class="controls">
                        <select class="form-control" v-model="staff_id" @change="providerstaffselect(staff_id,'staff')">
                            <option value="0">Select Staff</option>
                            <option v-for="(item,index) in staff" :data-id="index" :value="item.id">{{item.name}}</option>
                        </select>
                    </div>
                  </div>
                  <input-field 
                      title="Referral Name"
                      id="referral_name"
                      :isRequired="true"
                      v-model="v$.form.referral_name.$model"
                      :errors="v$.form.referral_name.$errors"
                      divClass="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                      labelClass="form-label col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                      >
                  </input-field>
                  <form-btn :title="btnname" @click="save" btnCls="btn-info" ></form-btn>
                  <a href="javascript:void(0)" @click="reset" class="waves-effect waves-light btn btn-dark-light">Reset</a>
                  <a href="javascript:void(0)" @click="close" class="waves-effect waves-light btn btn-danger-light">Cancel</a>
                </div>

            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import InputField from "@/components/base/formFields/InputField.vue";   
import FormBtn from "@/components/base/formFields/formBtn.vue";
import axios from "@/scripts/axios.js";
import submitMixins from "@/scripts/mixins/submitMixins.js";
import useVuelidate from "@vuelidate/core";
import $ from 'jquery';

export default {
    mixins: [submitMixins],
    setup: () => {
        return { v$: useVuelidate() };
      },
      data() {
        return {
            template_key:[],
            provider:[],
            staff:[],
            provider_id: 0,
            staff_id:0,
        }
    },
        components: {
        InputField,
        FormBtn,
    },
    computed: {
        form() {
          return this.$store.state.Referral.form;
        },
        vuelidateExternalResults() {
          return this.$store.state.Referral.vuelidateExternalResults;
        },
        validationRules() {
          return this.$store.state.Referral.validationRules;
        },
        title() {
          return this.$route.name === "AddReferral" ? "New Referral" : "Edit Referral";
        },
        btnname() {
          return this.$route.name === "AddReferral" ? "Save" : "Update";
        },
    },
    validations() {
        return this.$store.state.Referral.validationRules;
        },
        methods:{
          reset() {
            this.$store.state.Referral.vuelidateExternalResults.form.referral_name = [];
            if (this.$route.name != "AddReferral") {
              this.getData();
            } else {
              this.provider_id = 0;
              this.staff_id = 0;
              $("#referral_name :input").removeAttr("readonly");
              this.$store.state.Referral.form.referral_name = "";
            }
           
          },
          providerstaffselect(id,type){
            var name ="";
            if (type == "provider") {
              var data = this.provider;
              this.staff_id = 0;
              $("#referral_name :input").attr("readonly", "readonly");
            }
            if (type == "staff") {
              var data = this.staff;
              this.provider_id = 0;
              $("#referral_name :input").attr("readonly", "readonly");
            }
            if (this.provider_id == 0 && this.staff_id == 0) {
              $("#referral_name :input").removeAttr("readonly");
            }
             $.grep(data, function(e){ 
                if (e.id == id) {
                   name = e.name;
                }
            });
            this.$store.state.Referral.form.user_name_type = type;
            this.$store.state.Referral.form.referral_name = name;
            this.$store.state.Referral.form.user_type_id = id;
          },
          save(){
                if (this.$route.name === "AddReferral") {
                  this.$store.state.Referral.vuelidateExternalResults.form.referral_name = [];
                    this.postForm("referral/store", "Referral",true);
                }
                else{
                  this.$store.state.Referral.vuelidateExternalResults.form.referral_name = [];
                  this.postForm("referral/update", "Referral",true); 
                }
              },
            formPostSuccess(data) {
          this.$store.state.Referral.form = {};
          Object.assign(this.$store.state.Referral.form);
          this.v$.$reset();
          if (this.$route.name === "AddReferral") {
            this.moshaToast("Record added successfully.", "success");
          }
          else{
            this.moshaToast("Record updated successfully.", "success"); 
          }
          this.$router.push("/referral");
        },
        close(){
            this.$router.push("/referral");
        },
        async getData() {
         await this.$store.dispatch("getEditData", {
                  dataType: "Referral", // pass the module name
                  formType: "form", // pass the propery name
                  id: this.$route.params.id,
                });
               
                var type = this.$store.state.Referral.form.user_name_type;
                if (type == 'staff') {
                  this.provider_id = 0;
                  this.staff_id = this.$store.state.Referral.form.user_type_id;
                  $("#referral_name :input").attr("readonly", "readonly");
                }
               else if (type == 'provider') {
                  this.staff_id = 0;
                  this.provider_id = this.$store.state.Referral.form.user_type_id;
                  $("#referral_name :input").attr("readonly", "readonly");
                } else {
                  this.staff_id = 0;
                  this.provider_id = 0;
                }
        }
        },
        mounted(){
         
        },
        async beforeCreate(){
          axios.get("/json_dropdown/provider").then(
				    (response) => {
          this.provider = response.data.data;
          });
          axios.get("/json_dropdown/staff").then(
				    (response) => {
          this.staff = response.data.data;
          });
          
            if (this.$route.name === "AddReferral") {
                this.$store.state.Referral.form = {};
                this.$store.state.Referral.vuelidateExternalResults.form.referral_name = [];
                Object.assign(this.$store.state.Referral.form);
            }
            else{
                await this.$store.dispatch("getEditData", {
                  dataType: "Referral", // pass the module name
                  formType: "form", // pass the propery name
                  id: this.$route.params.id,
                });
                var type = this.$store.state.Referral.form.user_name_type;
                if (type == 'staff') {
                  this.provider_id = 0;
                  this.staff_id = this.$store.state.Referral.form.user_type_id;
                  $("#referral_name :input").attr("readonly", "readonly");
                }
               else if (type == 'provider') {
                  this.staff_id = 0;
                  this.provider_id = this.$store.state.Referral.form.user_type_id;
                  $("#referral_name :input").attr("readonly", "readonly");
                } else {
                  this.staff_id = 0;
                  this.provider_id = 0;
                }
            }
        }
}
</script>
