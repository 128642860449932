<template>
  <div class="form-group row">
    <label
      class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end"
      >{{ title }}</label
    >
    <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3">
      <div class="controls">
        <select
          name="select"
          required
          class="form-select form-control form-control"
          @change="$emit('update:modelValue', $event.target.value)"
        >
          <option value="">Select Your {{ title }}</option>
          <option :value="item.id" v-for="item in data" :key="item.id">{{
            item.name
          }}</option>
        </select>
      </div>
      <p v-if="error" class="form-error">
        Could not get {{ title }} options. Click
        <a @click="window.location.reload(true)"> HERE </a> to reload the page
        and try again
      </p>
    </div>
  </div>
</template>
<script>
import submitMixins from "@/scripts/mixins/submitMixins.js";
export default {
  mixins: [submitMixins],
  props: {
    title: {
      type: String,
    },
    getDataURL: {
      type: String,
    },
  },
  data() {
    return {
      data: "",
      error: false,
    };
  },
  async created() {
    this.data = await this.getData(this.getDataURL);

    if (!this.data) {
      this.error = true;
    }
  },
};
</script>