import moment from 'moment';
const PatientContactReport = {
	namespaced: true,
  	state: {
    	listUrl: "/reports/patient-report",
		header: [
			{"chart_id":"Medical Chart ID"},
			{"first_name":"First Name"},
			{"last_name":"Last Name"},
			{"email":"Email"},
			{"state_name":"State"},
			{"pp_id":"Portal Status"},
			{"bulk_dnd":"Unsubscribe"},
			{"":""},
		],
		pagination: {
			to: "",
			from: "",
			total: "",
			last_page: "",
			current_page: "",
			pageNavArr: [],
		},	
		list: [],	
		filter: {
            zip_code:"",
            min_age:"",
            max_age:"",
            state_id:"",
            cpt_id:"",
            gender:"",
            visit:"",
            credit_card:"",
            problem_id:"",
            insurance_card:"",
            chief_complaint_id:"",
            patient_portal_id:"",
            mail:[],
            pending_documents_id:"",
            appointment_start_date: moment().subtract(1, 'months').format("YYYY-MM-DD"),
            appointment_end_date: moment(new Date()).format("YYYY-MM-DD"),
            join_start_date: moment().subtract(1, 'months').format("YYYY-MM-DD"),
            join_end_date: moment(new Date()).format("YYYY-MM-DD"),
            appointment_daterange: moment().subtract(1, 'months').format("MM-DD-YYYY")+' - '+ moment(new Date()).format("MM-DD-YYYY"),
            join_daterange: moment().subtract(1, 'months').format("MM-DD-YYYY")+' - '+ moment(new Date()).format("MM-DD-YYYY"),            
        },
  	},
  getters: {
    getTableHeaders: (state) => state.header,
    getTableContents: (state) => state.list,
    getListURL: (state) => state.listUrl,
  },
  mutations: {},
  actions: {},
}

export default PatientContactReport;