import moment from 'moment';
const RefundReport = {
	namespaced: true,
  	state: {
    	listUrl: "/payment/refundreport",
		header: [
			{"patient_name":"Patient Full Name"},
			{"schedule_date_time":"Appointment Schedule Date & time"},
			{"reason_for_visit":"Appointment Reason"},
			{"last_refund_date":"Refund Date"},
			{"payment_type":"Refund Type"},
			{"refund_reason":"Refund Reason"},
			{"refund_amount":"Refund Amount"},
		],
		pagination: {
			to: "",
			from: "",
			total: "",
			last_page: "",
			current_page: "",
			pageNavArr: [],
		},	
		list: [],
        filter: {
            start_date: moment().subtract(1, 'months').format("YYYY-MM-DD"),
            end_date: moment().add(1, 'days').format("YYYY-MM-DD"),
            daterange: moment().subtract(1, 'months').format("MM-DD-YYYY") + ' - '
                + moment(new Date()).format("MM-DD-YYYY"),
            select_option_date: '',
            search_text: '',
            select_refund_type: '',
        },	
  	},
  getters: {
    getTableHeaders: (state) => state.header,
    getTableContents: (state) => state.list,
    getListURL: (state) => state.listUrl,
  },
  mutations: {},
  actions: {},
}

export default RefundReport;