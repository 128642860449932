import { required,helpers} from "@vuelidate/validators";

const Reminderprofile = {
	namespaced: true,
  	state: {
    	form: {},    
    	vuelidateExternalResults: {
    		form: {
    			reminderprofilename:[],
    		}
    	},
    	validationRules: {
    		form: {
	    		reminderprofilename: {
			        required: helpers.withMessage("Reminder Profile is required", required),
			    },
			}
    	},
  	},
  	getters: {
    	getTableHeaders: (state) => state.header,
    	getTableContents: (state) => state.list,
    	getListURL: (state) => state.listUrl,
  	},
  	mutations: {},
  	actions: {},
}

export default Reminderprofile;