<template>
  <div class="loginback">
    <div class="container-fluid">
      <div class="row align-items-center">
        <div
          class="col-12 col-sm-9 col-md-8 col-lg-6 col-xl-5 col-xxl-4 offset-sm-1">
          <div class="bg-white rounded10 p-35 pt-40 pb-40 my-20">
            <div class="text-start">
              <img
                src="/images/pureehr.svg"
                alt="PureEHR"
                title="PureEHR"
                class="authcompany_logo mb-20"
              />
              <h2 class="mb-15 fw-900">Trouble Signing In?</h2>
              <p class="fs-16 text-fade">
                Enter your email and we'll send you a link to get back into your
                account.
              </p>
            </div>
            <form @submit.prevent="resetPassword" class="loginform mt-15">
              <div
                class="badge-danger-light rounded5 py-5 px-10 mb-15"
                role="alert"
                v-if="errorMessage"
              >
                {{ errorMessage }}
              </div>
              <div
                class="alert-success rounded5 py-5 px-10 mb-15"
                role="alert"
                v-if="successMessage"
              >
                {{ successMessage }}
              </div>
              <div
                class="form-group mb-20"
                :class="{ error: v$.form.email.$errors.length }"
              >
                <label class="fs-18">Email</label>
                <input
                  name="email"
                  id="email"
                  type="email"
                  class="form-control"
                  :class="{ 'is-invalid': errors.email }"
                  v-model="form.email"
                  placeholder=""
                />
                <div
                  class="invalid-feedback"
                  style="display:block;"
                  v-for="(error, index) of v$.form.email.$errors"
                  :key="index"
                >
                  {{ error.$message }}
                </div>
                <div
                  class="invalid-feedback"
                  style="display:block;"
                  v-if="errors.email"
                >
                  {{ errors.email[0] }}
                </div>
              </div>
              <!-- <div class="form-group" :class="{ error: v$.form.username.$errors.length }">
								<label class="fs-18">Username</label>
								<input name="username" id="username" type="text" class="form-control" 
								:class="{ 'is-invalid': errors.username }"
								v-model="form.username"
								placeholder="">
								<div class="invalid-feedback" style="display:block;" v-for="(error, index) of v$.form.username.$errors" :key="index"> {{ error.$message }}</div>
								<div class="invalid-feedback" style="display:block;"  v-if="errors.username">
									{{ errors.username[0] }}
								</div>
							</div> -->
              <div class="text-center">
                <button
                  type="submit"
                  :disabled="isDisabled"
                  class="waves-effect waves-light btn btn-info d-block fs-22 w-p100 started_btn"
                >
                  SEND LOGIN LINK
                </button>
              </div>
              <router-link
                to="/login"
                class="fog-pwd fs-16 mt-20 d-inline-block"
                >Return to Login</router-link
              >
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import axios from "@/scripts/axios.js";
import { mapGetters, mapActions } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
export default {
  name: "Forgot",
  setup() {
    return { v$: useVuelidate() };
  },
  components: {},
  data() {
    return {
      form: {
        email: "",
        //username:"",
      },
      errorMessage: "",
      successMessage: "",
      isDisabled: false,
    };
  },
  validations() {
    return {
      form: {
        email: {
          required: helpers.withMessage("Email is required.", required),
        } /*,
					username: {
						required: helpers.withMessage('Username is required.', required) ,
					}*/,
      },
    };
  },
  computed: {
    ...mapGetters(["errors"]),
  },
  created() {},
  mounted() {
    this.$store.commit("setErrors", {});
  },
  methods: {
    resetPassword: function() {
      this.v$.$validate();
      if (!this.v$.$error) {
        this.v$.$reset();
        this.errorMessage = "";
        this.successMessage = "";
        this.isDisabled = true;
        axios
          .post("forgot-password", this.form)
          .then((response) => {
            this.successMessage = response.data.message;
            this.isDisabled = false;
            this.form.email = "";
            this.form.username = "";
          })
          .catch((error) => {
            this.isDisabled = false;
            this.errorMessage = error.response.data.message;
          });
      }
    },
  },
};
</script>
