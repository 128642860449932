<template>
	<div class="loginback">
		<div class="container-fluid">
			<div class="row align-items-center" style="height:100vh">
				<div class="col-12 col-sm-8 col-md-7 col-lg-5 col-xl-4 col-xxl-3 offset-md-1">
					<div class="bg-white rounded10 p-35 pt-30 pb-30 my-20">
						<div class="bg-danger-light p-10 py-20 rounded5 fs-20 d-block text-center" role="alert" v-if="errorMessage">
							<i class="fa fa-info-circle"></i> {{ errorMessage }}
						</div>
						<div class="alert-success rounded5 p-10 py-20 fs-20 d-block text-center" role="alert" v-if="successMessage">
							<i class="fa fa-check-circle"></i> {{ successMessage }}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

		<script>
// @ is an alias to /src
import axios from "@/scripts/axios.js";
import { mapGetters, mapActions } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required, minLength,  helpers } from "@vuelidate/validators";
import $ from "jquery";
export default {
	name: "Login",
	setup() {
		return { v$: useVuelidate() };
	},
	components: {},
	data(){
		return {
			form:{
				password:"",
				password_confirmation:"",
			},
			is_loading : true,
			isDisabled : false,
			errorMessage : false,
			successMessage : false,
		}
	},
	computed: {
		...mapGetters(["errors"]),
	},
	created() {
		axios.post("user-activate-token", {'token' : this.$route.params.token }).then(response => {
			if (response.data.data.success != 1) {
				this.errorMessage = response.data.message;
			} else {
				console.log();
				this.successMessage = response.data.message;
			}
			let vm =this;
			setTimeout(function() {
				localStorage.removeItem("authToken");
				localStorage.removeItem("activeProvider");
				vm.$router.push({ name: "Login" });
			}, 3000);
		}).catch((error) => {
			this.errorMessage = error.response.data.message;
		});

	},
	mounted() {
		$('.tophead').html("");
		this.$store.commit("setErrors", {});
		this.$store.state.headerRequired = false;
		this.$store.state.footerRequired = false;
	},
	methods: {
	}
};
</script>