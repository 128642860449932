<template>
	<div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-10">
		<div class="tab-content">
			<div class="tab-pane active" id="locked" role="tabpanel">
				<div class="text-end">
					<a href="#" data-bs-toggle="modal" data-bs-target="#appbillingdocument" title="Reference of Knowledge Base" v-on:click.prevent="reset()"> <i class="fa fa-question-circle fs-20"></i></a>
				</div>
				<div class="box" v-if="permissioncheck(this.$store.state.permission.Insurance_Billing_Access_Management) && this.$store.state.auth.role_id==1">
					<div class="box-body">
						<div class="pageheading pb-5">
							<div class="row">
								<div class="col-12 col-sm-7 col-md-7 col-lg-8">
									<h2 class="heading2 font-bold">Billing</h2>
								</div>
								<div class="col-12 col-sm-5 col-md-5 col-lg-4 text-sm-end">
									<a href="javascript:void(0)" @click="getCMS()" class="waves-effect waves-light btn btn-info">View CMS1500 Form  <img v-if="loader" src="images/loader.gif" class="ms-1"></a>
								</div>
							</div>
						</div>
						<div v-if="$route.params.appointments_id!=undefined && $route.params.appointments_id!=null">
							<AppointmentInsuranceBadge :AppointmentNumber="$route.params.appointments_id" />
						</div>
                        <div class="">
                            <h3 class="mb-20 fw-600">HCFA Box 10 - Is patient's condition related to:</h3>
                            <div class="row">
								<div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
								</div>
                            </div>


                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                     <select-box-static title="Billing status" divClass="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" labelClass="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" v-model="form.billing_status" id="billing_status" :option="billing_status" :disabled="(billing_status_txt=='Fully Billed - Payment Locked' && !this.permissioncheck(this.$store.state.permission.Unlock_Payment)) ? true : false"></select-box-static>
                                     
									<div class="form-group row" v-if="form.billing_status==1">
										<label class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">Insurance Type </label>
										<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
											<div class="controls">
												<select class="form-select form-control" v-model="form.insurance_type">
													<option value="" disabled="disabled" selected> Select Insurance Type </option>
													<option v-for="(item,index) in insurance_type" :key="index" :value="item.value" :disabled="item.disable"> 
														{{item.title}}
													</option>	
												</select>
											</div>
										</div>
									</div>

                                    <select-box-static title="Payment Profile" divClass="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" labelClass="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" v-model="form.payment_profile" :option="payment_profile"></select-box-static>

	                            	<div class="form-group row" v-if="form.payment_profile == 'Insurance'">
										<label class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">Claim Type </label>
										<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
											<div class="controls">
												<select class="form-select form-control" v-model="form.insurance_info.claim_type">
													<option value="" selected> Select Claim Type </option>
													<option value="1">Original</option>
													<option value="7">Resubmit</option>
													<option value="8">Cancel</option>
												</select>
											</div>
										</div>
									</div>

									<div class="form-group row">
										<label class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">Employment </label>
										<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
											<div class="controls">
												<select class="form-select form-control"  v-model="form.insurance_info.is_employment">
													<option value="" selected> Select Employment </option>
													<option label="Yes" value="Yes" >Yes</option>
													<option label="No" value="No">No</option>
												</select>
											</div>
										</div>
									</div>

									<div class="form-group row">
										<label class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">Auto Accident </label>
										<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
											<div class="controls">
												<select class="form-select form-control"  v-model="form.insurance_info.is_autoaccident">
													<option value="" selected> Select Auto Accident </option>
													<option label="Yes" value="Yes" >Yes</option>
													<option label="No" value="No">No</option>
												</select>
											</div>
										</div>
									</div>                           

                                    <div class="form-group" v-if="form.insurance_info.is_autoaccident == 'Yes'">
                                        <label class="col-form-label">State </label>
                                        <div class="">
                                            <Select2
                                            v-model="form.insurance_info.autoaccident_state_id"
                                            id="state_id"
                                            placeholder="Select State"
                                            :options="states"
                                            />
                                        </div>
                                    </div>

									<div class="form-group row">
										<label class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">Other Accident</label>
										<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
											<div class="controls">
												<select class="form-select form-control"  v-model="form.insurance_info.is_other_accident">
													<option value="" selected> Select Other Accident </option>
													<option label="Yes" value="Yes" >Yes</option>
													<option label="No" value="No">No</option>
												</select>
											</div>
										</div>
									</div>
									
                                    
                                    <input-field title="Prior Authorization Number" divClass="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" labelClass="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" v-model="form.insurance_info.prior_authorization_number"> </input-field>
                                </div>
                                <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
									
									<div class="form-group row">
										<label class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">Emergency Service</label>
										<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
											<div class="controls">
												<select class="form-select form-control"  v-model="form.insurance_info.is_emergency_service">
													<option value="" selected> Select Emergency Service </option>
													<option label="Yes" value="Yes" >Yes</option>
													<option label="No" value="No">No</option>
												</select>
											</div>
										</div>
									</div>									

                                    <select-box-static title="Onset Date Type" divClass="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" labelClass="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" v-model="form.insurance_info.onset_date_type" :option="onset_date_type"></select-box-static>

                                    <div class="form-group">
                                        <label class="form-label"> Onset Date </label>
                                        <div class="availdate">
                                            <ejs-datepicker id="onset_date" :value="onset_date_var" v-model="form.insurance_info.onset_date" :format="dateFormat" placeholder="Onset Date" @change="onset_date($event)" name="onset_date" :strictMode='strict' :max='maxVal' :showClearButton='false' :blur="onset_date" :openOnFocus='true'></ejs-datepicker>
                                        </div>
                                    </div>

                                    <div class="form-group row" :class="{ error: (resubmission_code_error!='') }" v-if="form.insurance_info.claim_type == 7">
										<label class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">Claim Ref. Number <Popper arrow content="This number will auto populate once remit is recived" :hover="true" class="helptips cursor-pointer"><i class="fa fa-info-circle"></i></Popper></label>
										<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
											<div class="controls">
												<input type="text" v-model="form.insurance_info.resubmission_code" class="form-control" placeholder="Enter Claim Ref. Number #">

												<div class="invalid-feedback" style="display:block;">{{ resubmission_code_error }}</div>
											</div>
										</div>
									</div>

                                    <select-box-static title="Other Date Type" divClass="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" labelClass="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" v-model="form.insurance_info.other_date_type" :option="other_date_type"></select-box-static>
                                    <div class="form-group row">
                                        <label class="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-form-label"> Other Date </label>
                                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 availdate">
                                            <ejs-datepicker id="other_date" :value="other_date_var" v-model="form.insurance_info.other_date" :format="dateFormat" placeholder="Other Date" @change="other_date($event)" name="other_date" :strictMode='strict' :max='maxVal' :showClearButton='false' :blur="other_date" :openOnFocus='true'></ejs-datepicker>
                                        </div>
                                    </div>
                            		<input-field title="Ambulance Service Codes (CMS-1500 Line 19)" labelClass="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" divClass="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" v-model="form.insurance_info.ambulance_service_code"> </input-field>
                                </div>
                            </div>                            
                        </div>
					</div>
				</div>
				<div class="box" v-if="permissioncheck(this.$store.state.permission.Insurance_Billing_Access_Management) && this.$store.state.auth.role_id==1">
					<div class="box-body">
						<h3 class="mb-20 fw-600">Outside Lab Services Details:</h3>
						<div class="form-group row mb-0">
							<label class="col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end"> Outside Lab Entity Type </label>
							<div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-5 availdate">
								<input type="radio" v-model="form.insurance_info.outside_lab_entity_type" @change="entity_type_change"  name="outside_lab_entity_type" id="outside_lab_entity_type_label1" value="Referral" class="filled-in chk-col-info"/>
								<label for="outside_lab_entity_type_label1">Referral</label>
								<input type="radio" v-model="form.insurance_info.outside_lab_entity_type" @change="entity_type_change" name="outside_lab_entity_type" id="outside_lab_entity_type_label2" value="Lab" class="filled-in chk-col-info"/>
								<label for="outside_lab_entity_type_label2">Lab.</label>                                        
							</div>
							<select-box-static title="Outside Lab Entity Name" labelClass="col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end"  divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-5 availdate" v-model="form.insurance_info.outside_lab_entity_id" :option="outside_lab_entity_list"></select-box-static>							
							<div class="form-group row">
								<label class="col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end">Outside Lab Charges </label>
								<div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-5 availdate">
									<div class="input-group controls">
										<span class="input-group-text"><i class="fa fa-dollar"></i></span>
										<input id="Outside Lab Charges" type="number" min="0" class="form-control" placeholder="Outside Lab Charges" v-model="form.insurance_info.outside_lab_charges" autocomplete="off">
									</div>
								</div>
							</div>								
						</div>
					</div>
				</div>
				<div class="box">
					<div class="box-body">
						<div class="form-group row mb-0">
							<label class="col-12 col-sm-3 col-md-4 col-lg-3 col-xl-3 col-form-label text-sm-end">ICD-10 Codes</label>
							<div class="col-12 col-sm-6 col-md-6 col-lg-5 col-xl-4">
								<div class="selectcontrols">
									<Icd10-code
									@clicked="icdsearchresult"
									:isSelected="false"
									></Icd10-code>
								</div>
								<div class="" v-if="icd10duplication!=null">
									<div class="text-danger word-break-all">{{icd10duplication}}</div>
								</div>
							</div>
						</div>
						<div class="my-10" v-if="icd10table.length > 0">
							<div class="table-responsive">
								<table class="table table-hover table-bordered mb-0">
									<thead>
										<tr>
											<td>No</td>
											<td>Code</td>
											<td>Description</td>
											<td>Action</td>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(item, index) in icd10table" :key="index">
											<td>{{ index + 1 }}</td>
											<td>{{ item.code }}</td>
											<td>{{ item.description }}</td>
											<td>
												<Popper arrow content="Delete" :hover="true" class="helptips cursor-pointer">
													<a href="#" class="waves-effect waves-circle btn btn-circle btn-danger btn-xs remove" @click="icd10table.splice(index, 1);icdremove(item.table_id,item.code)"><i class="fa fa-trash"></i></a>
												</Popper>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
				<div class="box">
					<div class="box-body">
						<div class="form-group row mb-0">
							<div class="col-12 col-sm-3 col-md-4 col-lg-3 col-xs-3 text-sm-end">
								<label class="col-form-label">CPT Codes</label>
							</div>
							<div class="col-12 col-sm-6 col-md-6 col-lg-5 col-xl-4">
								<div class="selectcontrols">
									<Cpt-code
									@clicked="cptsearchresult"
									:isSelected="false"
									></Cpt-code>
								</div>
							</div>
						</div>
						<div class="my-10" v-if="cpttable.length > 0">
							<div class="table-responsive">
								<table class="table table-hover table-bordered mb-0">
									<thead>
										<tr>
											<th>No</th>
											<th>Code</th>
											<th>Modifiers</th>
											<th>ICD10 Dx Ptrs</th>
											<th style="width: 150px;">Quantity</th>
											<th style="width: 150px;">Price</th>
											<th>Actions</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(item, index) in cpttable" :key="index">
											<td>{{ index + 1 }}</td>
											<td>{{ item.code }}</td>
											<td>
												<div class="d-flex">
													<Cpt-hcpcs-modifier
													v-model="item.cpt_modifier_a"
													class="cptMod me-1"
													style="width:35px;"
													></Cpt-hcpcs-modifier>
													<Cpt-hcpcs-modifier
													v-model="item.cpt_modifier_b"
													class="cptMod me-1"
													style="width:35px;"
													></Cpt-hcpcs-modifier>
													<Cpt-hcpcs-modifier
													v-model="item.cpt_modifier_c"
													class="cptMod me-1"
													style="width:35px;"
													></Cpt-hcpcs-modifier>
													<Cpt-hcpcs-modifier
													v-model="item.cpt_modifier_d"
													class="cptMod me-1"
													style="width:35px;"
													></Cpt-hcpcs-modifier>
												</div>
											</td>
											<td>
												<div class="d-flex">
													<input
													type="text"
													class="form-control d-inline-block ps-5 pe-5 me-1"
													v-model="item.cpt_icd10_pointer_a"
													style="width:38px;"
													/>
													<input
													type="text"
													class="form-control d-inline-block ps-5 pe-5 me-1"
													v-model="item.cpt_icd10_pointer_b"
													style="width:38px;"
													/>
													<input
													type="text"
													class="form-control d-inline-block ps-5 pe-5 me-1"
													v-model="item.cpt_icd10_pointer_c"
													style="width:38px;"
													/>
													<input
													type="text"
													class="form-control d-inline-block me-1"
													v-model="item.cpt_icd10_pointer_d"
													style="width:38px;"
													/>
												</div>
											</td>
											<td>
												<input
												type="text"
												class="form-control mx-0"
												v-model="item.cpt_qty"
												placeholder="Quantity"
												/>
											</td>
											<td>
												<div class="input-group">
													<span class="input-group-text"
													><i class="fa fa-dollar"></i
													></span>
													<input
													type="text"
													class="form-control mx-0"
													placeholder="Price"
													v-model="item.cpt_price"
													/>
												</div>
											</td>
											<td>
												<a href="#" class="waves-effect waves-light btn btn-danger btn-xs me-1" @click="cptndctable.splice(cptndctable.indexOf(index),1);ndcremove(item.cpt_ndc_table_id,index,item.cpt_ndc_code);"
												v-if="cptndctable.includes(index)"><i class="fa fa-minus"></i> NDC</a>

												<a href="#" class="waves-effect waves-light btn btn-info btn-xs me-1" @click="addCptNdc(index)" v-else ><i class="fa fa-plus"></i> NDC </a>

												<Popper arrow content="Delete" :hover="true" class="helptips cursor-pointer">
													<a href="#" class="waves-effect waves-circle btn btn-circle btn-danger btn-xs" @click="cpttable.splice(index, 1),cptndctable.splice(cptndctable.indexOf(index),1);ndcremove(item.cpt_ndc_table_id,null,item.cpt_ndc_code); cptremove(item.cpt_table_id,item.code)"><i class="fa fa-trash"></i></a>
												</Popper>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
						<div class="mb-10" v-if="cptndctable.length > 0">
							<div class="table-responsive-sm">
								<table class="table table-hover table-bordered mb-0">
									<thead>
										<tr>
											<td>Procedure</td>
											<td>Package Code</td>
											<td>Quantity</td>
											<td>Units</td>
											<td>Actions</td>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(item, index) in cptndctable" :key="index">
											<td>
												{{ cpttable[item].code }}/{{
													cpttable[item].cpt_modifier_a
												}}<span v-if="cpttable[item].cpt_modifier_b"
												>:{{ cpttable[item].cpt_modifier_b }}</span
												><span v-if="cpttable[item].cpt_modifier_c"
												>:{{ cpttable[item].cpt_modifier_c }}</span
												><span v-if="cpttable[item].cpt_modifier_d"
												>:{{ cpttable[item].cpt_modifier_d }}</span
												>/${{ cpttable[item].cpt_price }}
											</td>
											<td>
												<NdcCode
												v-model="cpttable[item].cpt_ndc_code"
												@clicked="ndcsearchresult"
												></NdcCode>
											</td>
											<td>
												<input
												type="text"
												class="form-control mx-0"
												v-model="cpttable[item].cpt_ndc_qty"
												v-on:keyup="bindndcqty(item, $event.target.value)"
												placeholder="Quantity"
												/>
											</td>
											<td>
												<div class="controls">
													<select name="select" v-model="cpttable[item].cpt_ndc_units" v-on:change=" bindndcunit(item, $event.target.value)" class="form-select form-control">
														<option value="">Select Units</option>
														<option :selected="cpttable[item].cpt_ndc_units == 'UN'" value="UN">UN(Unit){{cpttable[item].cpt_ndc_units}}</option>
														<option :selected="cpttable[item].cpt_ndc_units == 'F2'" value="F2">F2(International Unit)</option>
														<option :selected="cpttable[item].cpt_ndc_units == 'GR'" value="GR">GR(Gram)</option>
														<option :selected="cpttable[item].cpt_ndc_units == 'ME'" value="ME">ME(Milligram)</option>
													</select>
												</div>
											</td>
											<td>
												<Popper arrow content="Delete" :hover="true" class="helptips cursor-pointer">
													<a href="#" class="waves-effect waves-circle btn btn-circle btn-danger btn-xs"
													@click="cptndctable.splice(index, 1);ndcremove(cpttable[item].cpt_ndc_table_id,index,item.cpt_ndc_code);"><i class="fa fa-trash"></i></a>
												</Popper>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
                <div class="box">
					<div class="box-body">
						<div class="form-group row mb-0">
							<div class="col-12 col-sm-3 col-md-4 col-lg-3 col-xs-3 text-sm-end">
								<label class="col-form-label">Custom Procedures </label>
							</div>
							<div class="col-12 col-sm-6 col-md-6 col-lg-5 col-xl-4">
								<div class="selectcontrols">
									<Custom-procedure-code
									@clicked="customproceduresearchresult"
									:isSelected="false"
									:appointment_profile_id="appointment_profile_id"
									:key="this.form.appointment_id"
									>
									</Custom-procedure-code>
								</div>								
								<div class="" v-if="customprocedureduplication!=null">
									<div class="text-danger word-break-all">{{customprocedureduplication}}</div>
								</div>
							</div>
						</div>
						<div class="my-10" v-if="customproceduretable.length > 0">
							<div class="table-responsive">
								<table class="table table-hover table-bordered mb-0">
									<thead>
										<tr>
											<td>No</td>
											<td>Code</td>
											<td>Description</td>
											<td>Quantity</td>
											<td style="min-width:100px;">Price</td>
											<td>Actions</td>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(item, index) in customproceduretable" :key="index">
											<td>{{ index + 1 }}</td>
											<td>
												{{ item.code }}
												<div v-if="cps_data">
													<ul v-if="cps_data.length>0 && cps_data[item.id]" class="ul-checklist-h">
														<li v-for="(i, idx) in cps_data[item.id]" :key="i.service_id+idx">
															<input type='checkbox' :checked="i.is_selected" @change="updateservices(idx, item.id)">  {{ i.product_name }}
														</li>
													</ul>
												</div>												
											</td>
											<td>{{ item.description }}</td>
											<td>
												<input
												type="text"
												class="form-control mx-0"
												v-model="item.custom_procedure_qty"
												placeholder="Quantity"
												/>
											</td>
											<td>
												<div class="input-group">
													<span class="input-group-text"
													><i class="fa fa-dollar"></i
													></span>
													<input
													type="text"
													class="form-control mx-0"
													v-model="item.custom_procedure_price"
													placeholder="Price"
													/>
												</div>
											</td>
											<td>
												<Popper arrow content="Delete" :hover="true" class="helptips cursor-pointer">
													<a href="#" class="waves-effect waves-circle btn btn-circle btn-danger btn-xs"
													@click="customproceduretable.splice(index, 1);customprocedureremove(item.id,item.table_id,item.code)" 
													><i class="fa fa-trash"></i></a>
												</Popper>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
				<div class="box mb-10" v-if="products.length > 0">
					<div class="box-body">
						<div class="row">
							<div class="col-6 col-sm-6 col-md-6 col-lg-6">
								<h3 class="fw-900 ">Products</h3>
							</div>
							<div class="col-6 col-sm-6 col-md-6 col-lg-6 text-end">
								<button class="waves-effect waves-light btn btn-info" type="button" data-bs-toggle="collapse" data-bs-target="#collapseProductArea" aria-expanded="false" aria-controls="collapseProductArea" @click="changeCollapseProductArea()" v-html="collapseProductArea"></button>
							</div>
						</div>
						<div class="collapse" id="collapseProductArea">
							<div class="table-responsive">
								<table class="table table-bordered mb-0">
									<thead>
										<tr>
											<td style="min-width:30px;">No</td>
											<td style="min-width:50px;">Code</td>
											<td style="min-width:120px;">Description</td>
											<td style="min-width:50px">Quantity</td>
											<td style="min-width:100px">Price</td>
											<td style="min-width:50px">Total</td>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(itemc, indexc) in product_categories" :key="indexc">
											<td colspan="6" v-if="products[itemc].length>0">
												<b>{{ itemc }}</b>
												<button class="btn btn-info" :id="`collapseBtn${ indexc}`" type="button" data-bs-toggle="collapse" :data-bs-target="`#collapseProductArea-${ indexc }`" aria-expanded="false" :aria-controls="`collapseProductArea-${ indexc }`" style="float: right; padding: 0px 8px;" @click="changeBtnText(indexc)">
													<i class="fa fa-plus-square"></i>
												</button>
												<div class="collapse" :id="`collapseProductArea-${ indexc }`" style="margin-top: 10px;">
													<table class="table table-hover table-bordered mb-0">
														<tbody>
															<tr v-for="(itemp, indexp) in products[itemc]" :key="indexp">
																<td style="min-width:30px;">{{ indexp+1 }}</td>
																<td style="min-width:50px;">{{ itemp.product_code }}</td>
																<td style="min-width:120px;">{{ itemp.product_name }}</td>
																<td style="min-width:50px">
																	<input
																		type="number"
																		class="form-control mx-0"
																		v-model="itemp.product_qty"
																		placeholder="Quantity"
																		@keyup="update_total(itemc, indexp)"
																		@change="update_total(itemc, indexp)"
																	/>
																</td>
																<td style="min-width:100px">
																	<div class="input-group">
																		<span class="input-group-text"><i class="fa fa-dollar"></i></span>
																		<input
																			type="number"
																			class="form-control mx-0"
																			v-model="itemp.product_price"
																			placeholder="Price"
																			@keyup="update_total(itemc, indexp)"
																			@change="update_total(itemc, indexp)"
																		/>
																	</div>
																</td>
																<td style="min-width:50px">${{ itemp.product_total }}</td>
															</tr>
														</tbody>
													</table>
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
				<div class="text-center">
					<button @click="submitForm()" v-if="this.billing_status_txt!='Fully Billed - Payment Locked'" class="waves-effect waves-light btn btn-info me-1">Save</button>
					<button @click="close" class="waves-effect waves-light btn btn-danger me-1">Close</button>
				</div>
			</div>
		</div>
	</div>
		<!--document model start-->
      <div id="appbillingdocument" class="modal fade" tabindex="-1" role="dialog"
         aria-labelledby="myModalLabel" aria-hidden="true">
         <div class="modal-dialog modal-md">
            <div class="modal-content">
            <div class="modal-header">
               <h3 class="modal-title fw-900 mt-0">Reference of Knowledge Base</h3>
            </div>
            <div class="modal-body overflow-auto" style="max-height: 350px;">
               <table class="table table-bordered">
                  <thead>
                     <tr>
                     <th scope="col">No.</th>
                     <th scope="col">Knowledge Base Articles</th>
                     </tr>
                  </thead>
                  <tbody>
                     <tr>
                        <td>1</td>
                        <td> <a :href="path+'how-to-modify-icd-10-codes-cpt-codes-ndc-codes-and-modifiers-for-a-general-appointment-in-pureehr'" target="_blank">How To Modify ICD-10 Codes, CPT Codes, NDC Codes And Modifiers For An Individual Appointment In PureEHR?</a></td>
                     </tr>
					 <tr>
                        <td>2</td>
                        <td> <a :href="path+'how-to-generate-cms-1500-form-in-pureehr'" target="_blank">How To Generate CMS 1500 Form In PureEHR?</a></td>
                     </tr>
					 <tr>
                        <td>3</td>
                        <td> <a :href="path+'cms-1500-information-for-each-box-in-pureehr'" target="_blank">CMS 1500 Information For Each Box In PureEHR</a></td>
                     </tr>
                  </tbody>
               </table>
			   <Search :key="searchKey"></Search>	
            </div>
            <div class="modal-footer">
               <button type="button" @click="" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
            </div>
            </div>
         </div>
      </div>
   <!--document model end-->
	<div class="modal fade" tabindex="-1" id="appointment_amount">
		<div class="modal-dialog modal-md">
	      <div class="modal-content">
	         <div class="modal-header">
	            <h4 class="modal-title fw-900 mt-0">Pending amount</h4>
	         </div>
	         <div class="modal-body">
	            
	         </div>
	         <div class="modal-footer">
	            <button type="button" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Ok</button>
	         </div>
	      </div>
	   </div>
	</div>
</template>

<script>
	import InputField from "@/components/base/formFields/InputField.vue";
	import FormBtn from "@/components/base/formFields/formBtn.vue";
	import Icd10Code from "@/components/base/Icd10Code.vue";
	import CptCode from "@/components/base/CptCode.vue";
	import NdcCode from "@/components/base/NdcCode.vue";
	import CustomProcedureCode from "@/components/base/CustomProcedureCode.vue";
	import CptHcpcsModifier from "@/components/base/CptHcpcsModifier.vue";
	import axios from "@/scripts/axios.js";
	import useVuelidate from "@vuelidate/core";
	import submitMixins from "@/scripts/mixins/submitMixins.js";
	import permissionMixins from "@/scripts/mixins/permission.js";
	import Popper from "vue3-popper";
	import SelectBoxStatic from "@/components/base/formFields/SelectBoxStatic.vue";
	import Select2 from "@/components/base/formFields/select2.vue";
	import moment from "moment";
	import AppointmentInsuranceBadge from "@/components/Appointments/AppointmentInsuranceBadge.vue";
	import { helpers, required, requiredIf } from "@vuelidate/validators";
	import Search from "@/components/Search.vue";

	export default {
		mixins: [permissionMixins,submitMixins],
		setup: () => {
			return { v$: useVuelidate() };
		},
		data() {
			return {
				all_billing_status: 0,
				billing_status_txt: '',
				resubmission_code_error: '',
				appointment_profile_id : '',
				loader:false,
                is_production_env: (process.env.VUE_APP_NODE_ENV == 'production') ? true : false,
				outside_lab_entity_list: [],
				icd10table: [],
				cpttable: [],
				cptndctable: [],
				customproceduretable: [],
				ndctable: [],
				icddelete:[],
				icdcodedelete:[],
				cptdelete:[],
				cptcodedelete:[],
				ndcdelete:[],
				ndccodedelete:[],
				onset_date_var: null,
				other_date_var: null,
				customproceduredelete:[],				
				customprocedurecodedelete:[],				
				onset_date_type:[
				{ value: "431", title: "Onset of Current Symptoms or Illness" },
				{ value: "439", title: "Date of Accident" },
				{ value: "484", title: "Last Menstrual Period" },
				],
				other_date_type:[
				{ value: "444", title: "Initial Visit Date" },
				{ value: "454", title: "Initial Treatment Date" },
				{ value: "304", title: "Last Related Visit" },
				],
				states:[],
				strict: true,
				maxVal: new Date(),
				dateFormat: 'MM-dd-yyyy',
                payment_profile:[
                    { value: "Cash", title: "Cash" },
                    { value: "Insurance", title: "Insurance" },
                ],
                billing_status:[],
                billing_status_arr:[],
                insurance_type:[
					{ value: "Primary", title: "Primary" , disable: false },
                    { value: "Secondary", title: "Secondary (Upcoming Feature)" , disable: true },
                    { value: "Auto Accident", title: "Auto Accident (Upcoming Feature)" , disable: true },
                    { value: "Worker's Compensation", title: "Worker's Compensation (Upcoming Feature)" , disable: true },
				],
				icd10duplication: null,
				customprocedureduplication: null,
				cps: [],
				selected_services_list: [],
				selected_services_list_filter: [],
				product_categories: [],
				products: [],
				collapseProductArea: "<i class='fa fa-plus-square'></i>",
				cps_data: [],
				path: process.env.VUE_APP_KB_DOCUMENT,
				searchKey:0,
			};
		},
		components: {
			Icd10Code,
			CptCode,
			CustomProcedureCode,
			CptHcpcsModifier,
			InputField,
			FormBtn,
			NdcCode,
			Popper,
			SelectBoxStatic,
			Select2,
			AppointmentInsuranceBadge,
			Search
		},
		methods: { 
			reset() {
				this.searchKey = this.searchKey + 1;
			},
			change_status(e){
				console.log(e);
			},
			getCMS(){
				if(this.form.appointment_id){
					this.loader = true;						
					axios.post("/get-cms1500",{id:this.form.appointment_id})
					.then((response) => {
						this.loader = false;
						window.open(response.data, '_blank').focus();
					});
				}
			},  
			entity_type_change(){
				if(this.form.insurance_info.outside_lab_entity_type == 'Referral'){
					axios.get("/referral-contact/list").then(response => { 
						this.outside_lab_entity_list = response.data.data.map((code) => {
							return { value: code.id, title: code.organization_name }
						});
					});					
				}else{
					this.outside_lab_entity_list = 
					[
						{ value: "Suretox Lab", title: "Suretox Lab" },                   
						{ value: "Quality Lab", title: "Quality Lab" },						
                	];
				}
			},  
			onset_date($event){
				if($('#onset_date').val() == ''){
					this.form.insurance_info.onset_date = null;
				}else{
					this.form.insurance_info.onset_date = moment($('#onset_date').val()).format('YYYY-MM-DD');
				}
			},
			other_date($event){
				if($('#other_date').val() == ''){
					this.form.insurance_info.other_date = null;
				}else{
					this.form.insurance_info.other_date = moment($('#other_date').val()).format('YYYY-MM-DD');
				}
			},
			icdsearchresult(obj) {
				const checkduplicate = value => value.code === obj.code;
				if(this.icd10table.some(checkduplicate)){
					this.icd10duplication = "Selected ICD code already present!!";
					setTimeout(() => {
						this.icd10duplication = null;
					}, 8000);
				}else{
					this.icd10table.push({
						id: obj.id,
						code: obj.code,
						description: obj.description,
						table_id: "",
					});
				}
			},			
			ndcsearchresult(obj) {
				this.ndctable[obj.code] = {
					id: obj.id,
					description: obj.name,
				};
			},
			cptsearchresult(obj) {
				this.cpttable.push({
					id: obj.id,
					code: obj.code,
					description: obj.description,
					cpt_modifier_a: "",
					cpt_modifier_b: "",
					cpt_modifier_c: "",
					cpt_modifier_d: "",
					cpt_icd10_pointer_a: "",
					cpt_icd10_pointer_b: "",
					cpt_icd10_pointer_c: "",
					cpt_icd10_pointer_d: "",
					cpt_qty: 1,
					cpt_price: "",
					cpt_ndc_code_id: "",
					cpt_ndc_code: "",
					cpt_ndc_description: "",
					cpt_ndc_qty: 1,
					cpt_ndc_units: "",
					cpt_table_id: "",
					cpt_ndc_table_id: "",
				});
			},
			async customproceduresearchresult(obj) {    
				var $this = this;        
				const checkcustomprocedure = value => value.code === obj.code;
				if(this.customproceduretable.some(checkcustomprocedure)){
					this.customprocedureduplication = "Selected Custom Procedure code already present!!";
						setTimeout(() => {
							this.customprocedureduplication = null;
						}, 8000);
				}else{
					/*get services */
					$this.$store.state.loader = true;
					if(!$this.cps_data[obj.id])  {
						$this.cps_data[obj.id] = [];
					}
					await axios.get("appointment-billing/services_id/" + obj.id).then((res) => {
						if(res.data.data) {
							$this.cps_data[obj.id] = res.data.data;
							$this.cps_data[obj.id].forEach(cp => {
								cp['is_selected'] = 1;
							});
						} else {
							$this.cps_data[obj.id] = [];
						}
						$this.customproceduretable.push({
							id: obj.id,
							code: obj.code,
							description: obj.description,
							custom_procedure_qty: 1,
							custom_procedure_price: obj.price,
							custom_procedure_table_id: ""
						});
						$this.$store.state.loader = false;
					});

					console.log("cps_data: ", $this.cps_data);
				}
			},
			addCptNdc(index) {
				this.cptndctable.push(index);
			},
			bindndcqty(item, value) {
				this.cpttable[item].cpt_ndc_qty = value;
			},
			bindndcunit(item, value) {
				this.cpttable[item].cpt_ndc_units = value;
			},
			submitForm() {
				//7 == resumission process
				if (this.form.insurance_info.claim_type == 7 && this.form.insurance_info.resubmission_code == '') {
					this.resubmission_code_error = "Resubmission code is required when you resubmit the claim";
                  	return false;
				}else{			
					this.cpttable.forEach((entry) => {
						if (this.ndctable[entry.cpt_ndc_code]) {
							entry.cpt_ndc_code_id = this.ndctable[entry.cpt_ndc_code].id;
							entry.cpt_ndc_description = this.ndctable[entry.cpt_ndc_code].description;
						}
					});
					this.form.icd10_code_id = this.icd10table.map((icdary) => {
						return icdary.id;
					});
					this.form.icd10_table_id = this.icd10table.map((icdary) => {
						return icdary.table_id;
					});
					this.form.icd10_code = this.icd10table.map((icdary) => {
						return icdary.code;
					});
					this.form.icd10_description = this.icd10table.map((icdary) => {
						return icdary.description;
					});
					this.form.cpt_table_id = this.cpttable.map((cptary) => {
						return cptary.cpt_table_id;
					});
					this.form.cpt_code = this.cpttable.map((cptary) => {
						return cptary.code;
					});
					this.form.cpt_description = this.cpttable.map((cptary) => {
						return cptary.description;
					});
					this.form.cpt_code_id = this.cpttable.map((cptary) => {
						return cptary.id;
					});
					this.form.cpt_modifier_a = this.cpttable.map((cptary) => {
						return cptary.cpt_modifier_a;
					});
					this.form.cpt_modifier_b = this.cpttable.map((cptary) => {
						return cptary.cpt_modifier_b;
					});
					this.form.cpt_modifier_c = this.cpttable.map((cptary) => {
						return cptary.cpt_modifier_c;
					});
					this.form.cpt_modifier_d = this.cpttable.map((cptary) => {
						return cptary.cpt_modifier_d;
					});
					this.form.cpt_icd10_pointer_a = this.cpttable.map((cptary) => {
						return cptary.cpt_icd10_pointer_a;
					});
					this.form.cpt_icd10_pointer_b = this.cpttable.map((cptary) => {
						return cptary.cpt_icd10_pointer_b;
					});
					this.form.cpt_icd10_pointer_c = this.cpttable.map((cptary) => {
						return cptary.cpt_icd10_pointer_c;
					});
					this.form.cpt_icd10_pointer_d = this.cpttable.map((cptary) => {
						return cptary.cpt_icd10_pointer_d;
					});
					this.form.cpt_qty = this.cpttable.map((cptary) => {
						return cptary.cpt_qty;
					});
					this.form.cpt_price = this.cpttable.map((cptary) => {
						return cptary.cpt_price;
					});
					this.form.cpt_ndc_table_id = this.cpttable.map((cptary) => {
						return cptary.cpt_ndc_table_id;
					});
					this.form.cpt_ndc_code = this.cpttable.map((cptary) => {
						return cptary.cpt_ndc_code;
					});
					this.form.cpt_ndc_code_id = this.cpttable.map((cptary) => {
						return cptary.cpt_ndc_code_id;
					});
					this.form.cpt_ndc_description = this.cpttable.map((cptary) => {
						return cptary.cpt_ndc_description;
					});
					this.form.cpt_ndc_qty = this.cpttable.map((cptary) => {
						return cptary.cpt_ndc_qty;
					});
					this.form.cpt_ndc_units = this.cpttable.map((cptary) => {
						return cptary.cpt_ndc_units;
					});
					this.form.custom_procedure_table_id = this.customproceduretable.map(
						(customprocedureary) => {
							return customprocedureary.table_id;
						}
						);
					this.form.custom_procedure_code = this.customproceduretable.map(
						(customprocedureary) => {
							return customprocedureary.code;
						}
						);
					this.form.custom_procedure_code_id = this.customproceduretable.map(
						(customprocedureary) => {
							return customprocedureary.id;
						}
						);
					this.form.custom_procedure_description = this.customproceduretable.map(
						(customprocedureary) => {
							return customprocedureary.description;
						}
						);
					this.form.custom_procedure_qty = this.customproceduretable.map(
						(customprocedureary) => {
							return customprocedureary.custom_procedure_qty;
						}
						);
					this.form.custom_procedure_price = this.customproceduretable.map(
						(customprocedureary) => {
							return customprocedureary.custom_procedure_price;
						}
						); 
					if(this.form.insurance_info.is_autoaccident==null || this.form.insurance_info.is_autoaccident=='No'){
						this.form.insurance_info.autoaccident_state_id = null;
					}
					
					var billing_status_label = this.billing_status_arr.map((status) => {
						if(status.value == this.form.billing_status){
							return status.title;
						}else{
							return null;
						}
					}).filter(n => n);
					this.form.billing_status_label = billing_status_label.toString();
					this.form.insurance_info = JSON.stringify(this.form.insurance_info);       

					this.form.cps=this.cps_data;

					var url = "appointment-billing/store-update";
					 axios.post(url, this.form)
						.then((response) => {
							this.moshaToast("Record updated successfully", "success"); 	
							this.$store.state.appointmentbilling.form = {};
							Object.assign(
								this.$store.state.appointmentbilling.form,
								this.$store.state.appointmentbilling.defaultFormData
								);
							this.editApiCall();						

							setTimeout(() => {
								console.log('.');
								location.reload();
							}, 1500);
						})
						.catch((error) => {
							this.moshaToast("Oops, Appointment Not Found!.", "danger");
						});
					// this.postForm(url, "appointmentbilling", false, "formData");
				}
			},
			// formPostSuccess(data) {
			// 	this.moshaToast("Record updated successfully", "success");
			// 	this.$store.state.appointmentbilling.form = {};
			// 	Object.assign(
			// 		this.$store.state.appointmentbilling.form,
			// 		this.$store.state.appointmentbilling.defaultFormData
			// 		);
			// 	this.editApiCall();
			// },
			icdremove(id,code){
				if(id != null){
					this.form.icddelete.push(id)
				}
				if(code!=null){
					this.form.icdcodedelete.push(code)
				}
			},
			cptremove(id,code){
				if(id != null){
					this.form.cptdelete.push(id)
				}
				if(code!=null){
					this.form.cptcodedelete.push(code)
				}
			},
			ndcremove(id,index=null,code=null){
				if(id != null){
					this.form.ndcdelete.push(id)
				}
				if(code!=null){
					this.form.ndccodedelete.push(code)
				}
				if(index!=null){
					this.cpttable[index].cpt_ndc_code = null;
					this.cpttable[index].cpt_ndc_code_id  = null;
					this.cpttable[index].cpt_ndc_description  = null;
					this.cpttable[index].cpt_ndc_qty  = null;
					this.cpttable[index].cpt_ndc_table_id  = null;
					this.cpttable[index].cpt_ndc_units  = null;
				}
			},
			customprocedureremove(cpsid,id,code){
				if(cpsid != null) {
					if(this.cps_data && this.cps_data[cpsid]) {
						this.cps_data[cpsid] = null;
					}
				}
				if(id != null){
					this.customproceduredelete.push(id)
				}
				if(code != null){
					this.customprocedurecodedelete.push(code)
				}
			},
			close(){
				this.$router.push(`/patient-chart/${this.$route.params.id}/appointments/`); 
			},
			async editApiCall(){
				await axios.get("appointment-billing/edit/" + this.$route.params.appointments_id).then((res) => {
					this.cptndctable = [],
					this.icd10table = res.data.data.billing_icd10_record;
					this.cpttable = res.data.data.billing_cpt_record;
					this.customproceduretable = res.data.data.billing_custom_record;
					this.appointment_profile_id = res.data.data.appointment_details.appointment_profile_id;
					this.form.appointment_id = res.data.data.appointment_details.id;
					this.form.patient_id = res.data.data.appointment_details.patient_id;
					this.form.payment_profile = res.data.data.appointment_details.payment_profile;
					// this.form.payment_profile = (res.data.data.appointment_details.payment_profile == null) ? 'Cash' : res.data.data.appointment_details.payment_profile;
					this.form.billing_status = res.data.data.appointment_details.billing_status;
					this.form.insurance_type = res.data.data.appointment_details.insurance_type;

					if(this.all_billing_status == 0 && this.billing_status_txt == 'Fully Billed - Payment Locked'){						
						this.billing_status = this.billing_status_arr.map((status) => {
							if(status.value == this.form.billing_status){
								return { value: status.value, title: status.title };
							}else{
								return null;
							}
						}).filter(n => n);
					}else{
						Object.assign(
								this.billing_status,
								this.billing_status_arr
							);
					}
					this.form.appointment_number = res.data.data.appointment_details.appointment_number;
					this.form.pre_billing_status_label = res.data.data.appointment_details.billing_status_txt;
					this.billing_status_txt = res.data.data.appointment_details.billing_status_txt;
					/** */
					this.cps_data=[];
					var temp_cps_counter = [];
					res.data.data.billing_custom_record_selected_services.forEach((itm, idx) => {
						if(!this.cps_data[itm.custom_procedure_id]) {
							this.cps_data[itm.custom_procedure_id] = [];
							temp_cps_counter[itm.custom_procedure_id] = 0;
						}
						this.cps_data[itm.custom_procedure_id][temp_cps_counter[itm.custom_procedure_id]]={"product_name": itm.product_name, "is_selected": itm.is_selected, "service_id": itm.service_id};
						temp_cps_counter[itm.custom_procedure_id]++;
					});
					console.log("cps_data: ", this.cps_data);
					/** */
					/** */
					this.form.services_data_qty = [];
					this.form.services_data_price = [];
					this.form.services_data_title = [];
					if(res.data.data.billing_custom_services.length>0) {
						res.data.data.billing_custom_services.forEach((itm, indx) => {
							this.form.services_data_qty[itm.id] = itm.custom_service_qty;
							this.form.services_data_price[itm.id] = itm.custom_service_price;
							this.form.services_data_title[itm.id] = itm.custom_service_title;
						});
					}
					this.update_form_services_onload();
					/** */
					this.cpttable.forEach((data, i) => {
						if (data.cpt_ndc_code != null) {
							this.cptndctable.push(i);
						}
					});
					if(res.data.data.insurance_info != null){
						this.form.insurance_info = res.data.data.insurance_info;
						if(res.data.data.insurance_info.is_autoaccident!=null || res.data.data.insurance_info.is_autoaccident!='No'){
							this.form.insurance_info.autoaccident_state_id = res.data.data.insurance_info.autoaccident_state_id
						}
					}
					if(this.form.insurance_info.onset_date != null){
						this.onset_date_var = moment(String(this.form.insurance_info.onset_date)).format('MM-DD-YYYY');
					}
					if(this.form.insurance_info.other_date != null){                        
						this.other_date_var = moment(String(this.form.insurance_info.other_date)).format('MM-DD-YYYY'); ;
					}
					setTimeout(() => {                    
						$(".cptMod").each(function() {
							$(this).focus();
							$(this).blur();
						});
					}, 500);
				});
			},
			insurance_pending_billing_amount(appointment_id, patient_id){
				axios.post("/appointment/get-insurance-billable-amount-details", {'appointment_id': appointment_id, 'patient_id': patient_id})
				.then((response) => {
					$("#appointment_amount").children().children().children(".modal-body").html(response.data.data);
					$("#appointment_amount").modal("show");
				});
			},			
			updateservices(index, cp_id) {
				this.cps_data[cp_id][index]['is_selected'] = this.cps_data[cp_id][index]['is_selected']==1 ? 0 : 1;
			},
			update_total(itemc, indexp) {
				if(this.products[itemc][indexp]['product_qty']<0) {
					this.products[itemc][indexp]['product_qty'] = 0;
				}
				this.products[itemc][indexp]['product_total'] = this.products[itemc][indexp]['product_qty']*this.products[itemc][indexp]['product_price'];
				this.update_form_services();
			},
			changeCollapseProductArea() {
				this.collapseProductArea = this.collapseProductArea=="<i class='fa fa-plus-square'></i>" ? "<i class='fa fa-minus-square'></i>" : "<i class='fa fa-plus-square'></i>";
			},
			changeBtnText(id) {
				let btn = document.getElementById('collapseBtn'+id).getAttribute("aria-expanded");
				if(btn==true || btn=="true") {
					document.getElementById('collapseBtn'+id).innerHTML="<i class='fa fa-minus-square'></i>";
				} else {
					document.getElementById('collapseBtn'+id).innerHTML="<i class='fa fa-plus-square'></i>";
				}
			},
			update_form_services() {
				this.form.services_data_qty = [];
				this.form.services_data_price = [];
				this.product_categories.forEach((item, index) => {
					if(this.products[item].length>0) {
						this.products[item].forEach((itm, indx) => {
							if(this.products[item][indx]['product_qty']>0) {
								let id = this.products[item][indx]['product_id'];
								let qty = this.products[item][indx]['product_qty'];
								let price = this.products[item][indx]['product_price'];
								this.form.services_data_qty[id] = qty;
								this.form.services_data_price[id] = price;
							}
						});
					}
				});
			},
			update_form_services_onload() {
				this.product_categories.forEach((item, index) => {
					if(this.products[item].length>0) {
						this.products[item].forEach((itm, indx) => {
							let id = this.products[item][indx]['product_id'];
							if(this.form.services_data_qty[id]) {
								this.products[item][indx]['product_qty'] = this.form.services_data_qty[id];
								if(this.form.services_data_price[id]) { 
									this.products[item][indx]['product_price'] = this.form.services_data_price[id];
								}
								this.products[item][indx]['product_total'] = this.products[item][indx]['product_qty'] * this.products[item][indx]['product_price'];
							}
						});
					}
				});
			}
		},
		async beforeCreate() {
			this.$store.state.appointmentbilling.form = {};
			Object.assign(
				this.$store.state.appointmentbilling.form,
				this.$store.state.appointmentbilling.defaultFormData
				);
			var loadCountry = 231;
			axios.post("/json_autocomplete/" + loadCountry + "/state")
			.then((response) => {
				this.states = response.data.data.map((state) => {
					return { id: state.id, text: state.name };
				});       
			});
			axios.get("/json_dropdown/get-billing-status")
			.then((response) => {
				if(this.permissioncheck(this.$store.state.permission.Unlock_Payment) || this.is_super_admin == 1){
					this.all_billing_status = 1;
				}else{
					this.all_billing_status = 0;
				}
				this.billing_status_arr = response.data.data.map((item) => {
					return { value: item.id, title: item.name };
				});       
			});
			var product_qty = 0;
			axios.get("/IV_life_style/service/all_by_category")
			.then((response) => {
				var cat_index = [];
				var total_records = 0;
				var cat_counter = 0;
				response.data.data.forEach( (res, indx) => {
					if(!this.products[res.category_name]) {
						this.products[res.category_name] = [];
						cat_index[res.category_name] = 0;
						this.product_categories[cat_counter] = res.category_name;
						cat_counter++;
					}
					product_qty = 0;
					this.products[res.category_name][cat_index[res.category_name]] = [];
					this.products[res.category_name][cat_index[res.category_name]]['product_id'] = res.id;
					this.products[res.category_name][cat_index[res.category_name]]['product_code'] = res.product_code;
					this.products[res.category_name][cat_index[res.category_name]]['product_name'] = res.product_name;
					this.products[res.category_name][cat_index[res.category_name]]['product_price'] = res.product_price;
					this.products[res.category_name][cat_index[res.category_name]]['product_qty'] = product_qty;
					this.products[res.category_name][cat_index[res.category_name]]['product_total'] = 0;
					cat_index[res.category_name]++;
					total_records++;
				});
				this.products['length'] = total_records;
				this.update_form_services();
			});
		},
		mounted() {
			this.editApiCall();
		},
		computed: {
			form() {
	          return this.$store.state.appointmentbilling.form;
	        }
		},  
		watch:{
			'form.insurance_info.resubmission_code': function(val) {
				if(val!=''){
					this.resubmission_code_error = "";
				}else{
					this.resubmission_code_error = "Resubmission code is required when you resubmit or void the claim";
				}
			},
			'form.billing_status': function(val){				
				var full_bill_opt = this.billing_status_arr.map((status) => {
					if(status.title == 'Fully Billed - Payment Locked'){
						return status.value;
					}else{
						return null;
					}
				}).filter(n => n).toString();				
				if(val === full_bill_opt && this.form.payment_profile == 'Insurance'){
					this.insurance_pending_billing_amount(this.form.appointment_id, this.form.patient_id);
				}
			}
		}   
	}
</script>
<style scoped>
    .ul-checklist-h {
        font-size: 1rem; list-style: none; padding-left: 1rem; margin-bottom: 0; margin-top: 0.4rem;
    }
    .ul-checklist-h li { padding: 2px 0; }
    .ul-checklist-h li input[type="checkbox"] { position: unset; opacity: 1 }
</style>