<template>
   <div class="tophead main-header">
      <header class="d-inline-block w-p100">
         <div class="inside-header">
            <div class="d-flex align-items-center logo-box justify-content-start mt-1">
               <a href="index.html" class="logo"><img src="images/pureehr.svg" alt="PureEHR" title="PureEHR"/></a>
               <slot v-if="user">
                  <span style="margin-top: 14px; margin-left: 20px; color: rgb(61 68 72); padding: 2px 7px; border-radius: 5px; font-weight: 600;">{{user.clinic_name}}</span>
               </slot>
            </div>
            <nav class="navbar navbar-static-top topnavsearch">
               <div class="navbar-custom-menu r-side">
                  <ul class="nav navbar-nav my-sm-10 align-items-center">
                     <li class="dropdown user user-menu adminuser" v-if="this.$store.state.auth.recentPatientList.length > 0">
                        <a 
                           href="#"
                           class="waves-light bg-transparent"
                           data-bs-toggle="dropdown" title="Provider">
                        Recent Patients
                        <i class="fa fa-angle-down"></i>
                        </a>
                        <ul class="dropdown-menu animated userprovid_scroll">
                           <li class="user-body">
                              <div v-for="(item, index) in this.$store.state.auth.recentPatientList" :key="index" class="recent_ptnts_list">
                                 <router-link :to="{ name: 'PatientDetails', params: { id: item.unique_id } }" class="dropdown-item d-flex align-items-center l-h-n">
                                    <img
                                       v-if="item.profile_avatar"
                                       :src="item.profile_avatar"
                                       class="img-fluid"
                                       />
                                    <span class="word-break-all">{{ item.name }}</span>
                                 </router-link>
                              </div>
                           </li>
                        </ul>
                     </li>
                     <li class="em_switch" v-if="user && user.emergency_access == 1">
                        <span class="fs-14 fw-500 text-danger">Emergency Access</span>
                        <button type="button" id="emergency" class="btn btn-sm btn-toggle me-0" data-bs-toggle="button" aria-pressed="true" autocomplete="off" style="border-radius: 15px;" @click="emergency($event)">
                           <div class="handle w-15 h-15" style="border-radius: 15px;"></div>
                        </button>
                     </li>
                     
                     <li class="dropdown notifications-menu">
                        <a
                           href="#"
                           class="waves-light dropdown-toggle position-relative"
                           data-bs-toggle="dropdown" title="Notifications">
                        <i class="icon-Notification">
                        <span class="fa fa-envelope fs-18"></span>
                        <small class="msgCount me-0 coutanimated" v-if="this.$store.state.auth.notificationCounter!=0">{{this.$store.state.auth.notificationCounter}}</small>
                        <!-- <span v-if="this.$store.state.auth.notificationCounter>0" class="notipath2"></span> -->
                        </i>
                        </a>
                        <ul class="dropdown-menu animated bounceIn user_notify border">
                           <li class="header">
                              <div class="flexbox p-10">
                                 <h5 class="mb-0 mt-5 fw-500">Notifications</h5>
                                 <a v-if="this.$store.state.auth.notificationCounter>1" href="javascript:void(0)" class="text-danger fs-14" @click="clear_notification('all')">Clear All</a>
                              </div>
                           </li>
                           <li class="notif_size">
                              <!-- inner menu: contains the actual data -->
                              <ul class="menu sm-scrol">
                                 <li v-if="this.$store.state.auth.notificationCounter==0"><a href="javascript:void(0)"><i class="fa fa-plus-square text-info"></i> No Notifications !</a></li>
                                 <li v-for="(notification,index) in this.$store.state.auth.notificationData" class="d-flex justify-content-between l-h-n bb-1" :key="index">
                                    <router-link :to="notification.notification_link" @click="status_change(notification.id)" class="d-flex w-p100">
                                       <i class="fa fa-plus-square text-info mt-1"></i> <span class="ms-2"> {{notification.notification_msg}}</span>
                                    </router-link>
                                    <a href="javascript:void(0)" @click="clear_notification(notification.id)" class="p-5 w-60"><i class="fa fa-trash text-danger d-inline-block mt-5"></i></a>
                                 </li>
                              </ul>
                              <router-link to="/notification" class="text-secondary fs-14 py-5 d-block text-center fw-500">View all</router-link>
                           </li>
                        </ul>
                     </li>
                     <li class="dropdown notifications-menu">
                        <a
                           href="#"
                           class="waves-light dropdown-toggle position-relative" title="Rx Notifications"
                           data-bs-toggle="dropdown">
                        <img style="width: 18px;" class="img-fluid" src="images/patient_icons/rx-bell.svg" alt="Notification" />
                        <span class="notipath3"></span>
                        </a>
                        <ul class="dropdown-menu animated bounceIn user_notify border">
                           <li class="notif_size">
                              <ul class="menu" id="Rx_notification">
                                 <li class="header rx-header">
                                    <div class="flexbox p-10">
                                       <h5 class="mb-0 fw-500 w-p100">PRESCRIPTION REPORT <a v-if="(auth_user_type=='provider' && this.$store.state.auth.is_rx_setup==true) || (permissioncheck(
                                          this.$store.state.permission.Rcopia_Access_Management) && this.$store.state.auth.is_rx_setup==true)" href="#" @click="login_to_erx('report')" class="float-end"><i class="fa fa-external-link"></i></a></h5>
                                    </div>
                                 </li>
                                 <li v-if="rx_notification_data.rx_pending > 0" class="px-15 py-10 d-flex justify-content-between l-h-n bb-1">Rx Pending <span class="mt-0">({{rx_notification_data.rx_pending}})</span></li>
                                 <li v-if="rx_notification_data.rx_need_signing > 0" class="px-15 py-10 d-flex justify-content-between l-h-n bb-1">Rx Needs Signing <span class="mt-0">({{rx_notification_data.rx_need_signing}})</span></li>
                                 <li class="header rx-header">
                                    <div class="flexbox p-10">
                                       <h5 class="mb-0 fw-500 w-p100">PHARMACY MESSAGE <a v-if="(auth_user_type=='provider' && this.$store.state.auth.is_rx_setup==true) || (permissioncheck(
                                          this.$store.state.permission.Rcopia_Access_Management) && this.$store.state.auth.is_rx_setup==true)" href="#" @click="login_to_erx('message')" class="float-end"><i class="fa fa-external-link"></i></a></h5>
                                    </div>
                                 </li>
                                 <li v-if="rx_notification_data.refill > 0" class="px-15 py-10 d-flex justify-content-between l-h-n bb-1">Refill <span class="mt-0">({{rx_notification_data.refill}})</span></li>
                                 <li v-if="rx_notification_data.rxchange > 0" class="px-15 py-10 d-flex justify-content-between l-h-n bb-1">Rx Change <span class="mt-0">({{rx_notification_data.rxchange}})</span></li>
                              </ul>
                           </li>
                        </ul>
                     </li>
                     <li
                        class="dropdown user user-menu adminuser">
                        <slot v-if="selected_active_provider=='all'">
                           <a href="#" class="waves-light bg-transparent" data-bs-toggle="dropdown" title="Provider">
                              All <i class="fa fa-angle-down"></i>
                           </a>
                        </slot>
                        <slot v-else>
                              <slot v-if="provider_list && provider_list.length > 1">
                                 <a href="#" class="waves-light bg-transparent" data-bs-toggle="dropdown" title="Switch Provider"> 
                                    {{ active_provider ? ((active_provider.salutation) ? active_provider.salutation : '') +' '+`${active_provider.first_name} ${active_provider.last_name}` +' '+ ((active_provider.suffix) ? active_provider.suffix : '') : ""}}
                                       <i class="fa fa-angle-down"></i>
                                 </a>
                              </slot>
                              <slot v-else>
                                    <a href="#" class="waves-light bg-transparent" title="Primary Provider">
                                       {{ active_provider ? ((active_provider.salutation) ? active_provider.salutation : '') +' '+`${active_provider.first_name} ${active_provider.last_name}` +' '+ ((active_provider.suffix) ? active_provider.suffix : '') : ""}}
                                    </a>
                              </slot>
                        </slot>
                        <ul class="dropdown-menu animated userprovid_scroll">
                           <li class="user-body">
                              <a class="dropdown-item d-flex l-h-n" href="javascript:void(0);"  @click="setActiveProvider('all')">
                                 <i class="fa fa-user-o text-muted fs-12 me-2 mt-1"></i>
                                 <span>All</span>
                              </a>
                              <a
                                 class="dropdown-item d-flex l-h-n"
                                 href="javascript:void(0);"
                                 v-for="(item, index) in provider_list"
                                 @click="setActiveProvider(item)"
                                 :key="index">
                              <i class="fa fa-user-o text-muted fs-12 me-2 mt-1"></i>
                              <span>{{ ((item.salutation) ? item.salutation : '') +' '+ `${item.first_name} ${item.last_name}` +' '+ ((item.suffix) ? item.suffix : '') }}</span>
                              </a>
                           </li>
                        </ul>
                     </li>

                     <li class="dropdown user user-menu adminuser">
                        <a href="#" class="waves-light bg-transparent p-0" data-bs-toggle="dropdown" title="User">
                        <!-- {{ user ? ((user.salutation) ? user.salutation : '') +' '+`${user.first_name} ${user.last_name}` +' '+ ((user.suffix) ? user.suffix : '') : "" }} -->
                        <slot v-if="user">
                           <img :src="user.profile_avatar_image" v-if="user.profile_avatar_image!=''" alt="" title="" class="w-40 b-1 rounded50 bg-white">

                           <div v-if="user.profile_avatar_image==''" class="profname d-inline-block fw-900 fs-14 ms-1 w-30 text-white h-30 rounded50 text-center" style="padding-top: 3.5px;" :style="{'background-color':user.profile_letter_color}">{{ user.profile_letter }}</div>
                              <i class="fa fa-angle-down ms-2"></i>                           
                        </slot>
                        </a>
                        <ul class="dropdown-menu animated userprovid_scroll">
                           <li class="user-body">
                              <router-link to="/profile/personal-info" class="dropdown-item d-flex l-h-n">
                                 <i class="fa fa-user-o text-muted fs-12 me-2 mt-1"></i>
                                 <span>Profile</span>
                              </router-link>
                           </li>
                           <li class="user-body">
                              <a @click="logout" class="dropdown-item d-flex l-h-n" role="button">
                                 <i class="fa fa-sign-out text-muted fs-12 me-2 mt-1" aria-hidden="true"></i>
                                 <span>Logout</span>
                              </a>
                           </li>
                        </ul>
                     </li>
                     <!-- <li class="user user-menu customeruser">
                        {{ user ? ((user.salutation) ? user.salutation : '') +' '+`${user.first_name} ${user.last_name}` +' '+ ((user.suffix) ? user.suffix : '') : "" }}
                        </li> -->
                     <!-- <li class="signout-top">
                        <a @click="logout" title="Logout" href="javascript:void(0)"><i class="fa fa-sign-out"></i></a>
                     </li> -->
                  </ul>
               </div>
            </nav>
         </div>
      </header>
      <nav class="main-nav" role="navigation">
         <input id="main-menu-state" type="checkbox" />
         <label class="main-menu-btn" for="main-menu-state" style="z-index:99999;">
            <span class="main-menu-btn-icon"></span>
         </label>
         <ul id="main-menu" class="sm sm-blue topmenudesc">
            <li>
               <router-link to="/">Home </router-link>
            </li>
            <li v-if="permissioncheck(this.$store.state.permission.create_update) == true || permissioncheck(this.$store.state.permission.patients_list) == true">
               <a href="#">Patients</a>
               <ul>
                  <li v-if="permissioncheck(this.$store.state.permission.patients_list)">
                     <router-link to="/patient/list">Patient list</router-link>
                  </li>
                  <li
                     v-if="
                     permissioncheck(this.$store.state.permission.create_update)
                     ">
                     <router-link to="/patient/add">Add Patient</router-link>
                  </li>
                  
               </ul>
            </li>
            <li  v-if="
               permissioncheck(this.$store.state.permission.Calendar) == true || 
               permissioncheck(this.$store.state.permission.availability_search) == true ||
               permissioncheck(this.$store.state.permission.consent_forms) == true ||
               permissioncheck(this.$store.state.permission.appointment_dashboard) == true ||
               permissioncheck(this.$store.state.permission.visit_profile) == true ||
               permissioncheck(this.$store.state.permission.billing_profile) == true ||
               permissioncheck(this.$store.state.permission.custom_procedures) == true ||
               permissioncheck(this.$store.state.permission.reminder_profiles) == true ||
               permissioncheck(this.$store.state.permission.recurring_appointments) == true ||
               permissioncheck(this.$store.state.permission.bulk_appointments) == true ||
               permissioncheck(this.$store.state.permission.appointment_status) == true ||
               permissioncheck(this.$store.state.permission.deleted_appointments) == true ||
               permissioncheck(this.$store.state.permission.pre_appointments) == true">
               <a href="javascript:void(0)">
               Appointments</a>
               <ul>
                  <li v-if="permissioncheck(this.$store.state.permission.Calendar)">
                     <router-link to="/appointments/calendar">Calendar</router-link>
                  </li>
                  <li v-if="permissioncheck(this.$store.state.permission.consent_forms)">
                     <router-link to="/consent-forms">Consent Forms</router-link>
                  </li>
                  <li v-if="permissioncheck(this.$store.state.permission.availability_search)">
                     <router-link to="/availability-search">Availability Search</router-link>
                  </li>
                  <li v-if="permissioncheck(this.$store.state.permission.appointment_status)">
                    <router-link to="/appointment-status">Appointment Status</router-link>
                 </li>
                  <li v-if="permissioncheck(this.$store.state.permission.appointment_dashboard)">
                     <router-link to="/appointments/status-dashboard">Appointment Dashboard</router-link>
                  </li>
                   <li v-if="permissioncheck(this.$store.state.permission.recurring_appointments)">
                     <router-link to="/recurring-appointments">Recurring Appointments</router-link>
                  </li>
                  <li v-if="permissioncheck(this.$store.state.permission.bulk_appointments)">
                     <router-link to="/bulk-appointments">Bulk Appointment Scheduler</router-link>
                  </li>
                  <li v-if="permissioncheck(this.$store.state.permission.deleted_appointments)">
                     <router-link to="/recover-appointments">Recover Deleted Appointments</router-link>
                  </li>
                  <li v-if="permissioncheck(this.$store.state.permission.pre_appointments)">
                     <router-link to="/pre-appointments">Pre Appointments</router-link>
                  </li>
                  <li>
                     <a  v-if="permissioncheck(this.$store.state.permission.visit_profile) == true || permissioncheck(this.$store.state.permission.billing_profile) == true || permissioncheck(this.$store.state.permission.custom_procedures) == true || permissioncheck(this.$store.state.permission.reminder_profiles) == true" href="javascript:void(0)"><b>Appointment Profile Settings</b></a>
                     <ul>
                        <li v-if="permissioncheck(this.$store.state.permission.visit_profile)">
                           <router-link to="/visit-profile">Visit Profiles</router-link>
                        </li>
                        <li v-if="permissioncheck(this.$store.state.permission.billing_profile)">
                           <router-link to="/billing-profile-list"
                              >Billing Profiles</router-link>
                        </li>
                        <li v-if="permissioncheck(this.$store.state.permission.custom_procedures)">
                           <router-link to="/custom-procedures"
                              >Custom Profiles</router-link
                              >
                        </li>
                        <li v-if="permissioncheck(this.$store.state.permission.reminder_profiles)">
                           <router-link to="/reminder-profiles">Reminder Profiles</router-link>
                        </li>
                     </ul>
                  </li>
               </ul>
            </li>
            <li v-if="
               permissioncheck(this.$store.state.permission.Manage_Offices_Resources == true) || 
               permissioncheck(this.$store.state.permission.Settings) == true ||
               permissioncheck(this.$store.state.permission.Provider_Permission) == true ||
               permissioncheck(this.$store.state.permission.Staff_Permission) == true ||
               permissioncheck(this.$store.state.permission.ccda_preferences) == true ||
               permissioncheck(this.$store.state.permission.access_management) == true ||
               permissioncheck(this.$store.state.permission.leave_management) == true ||
               permissioncheck(this.$store.state.permission.custom_billing_status) == true ||
               permissioncheck(this.$store.state.permission.erx_settings) == true ||
               permissioncheck(this.$store.state.permission.lab_settings) == true ||
               permissioncheck(this.$store.state.permission.coupon_management) == true ||
               permissioncheck(this.$store.state.permission.referral_management) == true">
               <a href="#">Settings</a>
               <ul>
                  <li>
                     <a  v-if="permissioncheck(this.$store.state.permission.Settings) == true || permissioncheck(this.$store.state.permission.Manage_Accounts) == true || permissioncheck(this.$store.state.permission.Manage_Offices_Resources) == true || 
                     permissioncheck(this.$store.state.permission.access_management) == true ||
                     permissioncheck(this.$store.state.permission.leave_management) == true" href="javascript:void(0)"><b>Practice Management</b></a>
                     <ul>
                        <li v-if="permissioncheck(this.$store.state.permission.Settings)">
                           <router-link to="/provider/list">Provider Management</router-link>
                        </li>
                        <li v-if="permissioncheck(this.$store.state.permission.Manage_Accounts)">
                           <router-link to="/staff/list">Staff Management</router-link>
                        </li>
                        <li v-if="permissioncheck(this.$store.state.permission.Manage_Offices_Resources)">
                           <router-link to="/office/list">Office Management</router-link>
                        </li>
                        <li v-if="permissioncheck(this.$store.state.permission.access_management)">
                           <router-link to="/permission">Access Management</router-link>
                        </li>
                        <li v-if="permissioncheck(this.$store.state.permission.leave_management)">
                           <router-link to="/leave/list">Leave Management</router-link>
                        </li>
                        <li v-if="permissioncheck(this.$store.state.permission.vital_management)">
                           <router-link to="/vitals/list">Vitals Management</router-link>
                        </li>
                     </ul>
                  </li>
                  <li v-if="!is_production_env">
                     <a v-if="permissioncheck(this.$store.state.permission.erx_settings) == true || permissioncheck(this.$store.state.permission.lab_settings) == true " href="javascript:void(0)"><b>3rd Party Settings</b></a>
                     <ul>
                        <li v-if="permissioncheck(this.$store.state.permission.erx_settings)">
                           <a href="javascript:void(0)">DrFirst Settings</a>
                        </li>
                        <li v-if="permissioncheck(this.$store.state.permission.lab_settings)">
                           <a href="javascript:void(0)">Lab Setting</a>
                        </li>
                     </ul>
                  </li>
                  <li v-if="permissioncheck(this.$store.state.permission.custom_billing_status)">
                     <router-link to="/custom/billing-status">Custom Billing Status</router-link>
                  </li>
                  <li v-if="permissioncheck(this.$store.state.permission.ccda_preferences)">
                     <router-link to="/CCDApreferences">CCDA Preferences</router-link>
                  </li>
                  <li v-if="permissioncheck(this.$store.state.permission.email_template_setting)">
                     <router-link to="/email-template">Email Templates Settings</router-link>
                  </li> 
                  <li v-if="permissioncheck(this.$store.state.permission.sms_template_setting)">
                     <router-link to="/sms-template">SMS Templates Settings</router-link>
                  </li> 
                  <li v-if="permissioncheck(this.$store.state.permission.wallet_amount)">
                     <router-link to="/wallet-setting">Wallet Amount Settings</router-link>
                  </li> 
                  <li v-if="permissioncheck(this.$store.state.permission.coupon_management)">
                     <router-link to="/coupon/list">Coupon Management</router-link>
                  </li>
                  <li v-if="permissioncheck(this.$store.state.permission.referral_management)">
                     <router-link to="/referral">Referral Management</router-link>
                  </li> 
                  <!-- <li>
                     <router-link to="/clinic_settings">Clinic Settings</router-link>
                  </li>  -->
               </ul>
            </li>
            <li v-if="permissioncheck(this.$store.state.permission.rectangleHealth) == true ||
                  permissioncheck(this.$store.state.permission.card_authorization) == true ||
                  permissioncheck(this.$store.state.permission.contract) == true ||
                  permissioncheck(this.$store.state.permission.PatientIdentification) == true ||
                  permissioncheck(this.$store.state.permission.Collection) == true ||
                  permissioncheck(this.$store.state.permission.CovidExamination) == true ||
                  permissioncheck(this.$store.state.permission.ContactPatient) == true || 
                  permissioncheck(this.$store.state.permission.ClaimReport) == true || 
                  permissioncheck(this.$store.state.permission.EligibilityTest) == true || 
                  permissioncheck(this.$store.state.permission.UnmatchedERA) == true || 
                  permissioncheck(this.$store.state.permission.RemittanceReports) == true || 
                  permissioncheck(this.$store.state.permission.DownloadLogs) == true || 
                  permissioncheck(this.$store.state.permission.AdvanceReport) == true
                  ">
               <a href="#">Reports</a>
               <ul>
                  <li>
                     <a v-if="permissioncheck(this.$store.state.permission.rectangleHealth) == true || permissioncheck(this.$store.state.permission.card_authorization) == true || permissioncheck(this.$store.state.permission.contract) == true|| permissioncheck(this.$store.state.permission.refund_report) == true || permissioncheck(this.$store.state.permission.Collection) " href="javascript:void(0)"><b>Billing Reports</b></a>
                     <ul>
                        <li v-if="permissioncheck(this.$store.state.permission.contract)">
                          <router-link to="/contract-payment">Contract Payment Report</router-link>
                        </li>
                        <li v-if="permissioncheck(this.$store.state.permission.contract)">
                          <router-link to="/contract">Contract Report</router-link>
                        </li>
                        <li v-if="permissioncheck(this.$store.state.permission.Collection)">
                           <router-link to="/collection-report">Collection Report</router-link>
                        </li>
                        <li v-if="permissioncheck(this.$store.state.permission.rectangleHealth)">
                           <router-link to="/rectangle-authorization">Credit Card Authorization Report</router-link>
                        </li>
                        <li v-if="permissioncheck(this.$store.state.permission.card_authorization)">
                           <router-link to="/card-authorization-of-prospectus">Card Authorization of Prospects</router-link>
                        </li>
                        <li v-if="permissioncheck(this.$store.state.permission.refund_report)">
                           <router-link to="/refund-report">Refund Report</router-link>
                        </li>
                     </ul>
                  </li>
                  <li v-if="permissioncheck(this.$store.state.permission.ClaimReport) == true || 
                     permissioncheck(this.$store.state.permission.EligibilityTest) == true">
                     <a href="javascript:void(0)"><b>Insurance Reports</b></a>
                     <ul>
                        <li v-if="permissioncheck(this.$store.state.permission.ClaimReport)">
                           <router-link to="/insurance-claim-report" >Claim Report</router-link>
                        </li>
                        <li v-if="permissioncheck(this.$store.state.permission.EligibilityTest)">
                           <router-link to="/eligibility-test" >Eligibilty Search</router-link>
                        </li>                        
                     </ul>
                  </li>
                  <li v-if="permissioncheck(this.$store.state.permission.UnmatchedERA) == true || 
                     permissioncheck(this.$store.state.permission.RemittanceReports) == true || 
                     permissioncheck(this.$store.state.permission.EOBUpload) || 
                     permissioncheck(this.$store.state.permission.ERAUpload)">
                     <a href="javascript:void(0)"><b>Insurance Payment</b></a>
                     <ul>                        
                        <li v-if="permissioncheck(this.$store.state.permission.UnmatchedERA)">
                           <router-link to="/unmatched-era" >Unmatched ERA Report</router-link>
                        </li>
                        <li v-if="permissioncheck(this.$store.state.permission.RemittanceReports)">
                           <router-link to="/remittance-report" >Remittance Reports</router-link>
                        </li>
                        <li v-if="permissioncheck(this.$store.state.permission.EOBUpload)">
                           <router-link to="/insurance-eob-upload">EOB Upload</router-link>
                        </li>
                        <li v-if="permissioncheck(this.$store.state.permission.ERAUpload)">
                           <router-link to="/insurance-era-upload">ERA Upload</router-link>
                        </li>
                     </ul>
                  </li>
                  <li v-if="permissioncheck(this.$store.state.permission.AdvanceReport)">
                     <router-link to="/advance-report" >Advance Report</router-link>
                  </li>
                  <li v-if="permissioncheck(this.$store.state.permission.DownloadLogs)">
                     <router-link to="/download-logs" >Download Logs</router-link>
                  </li>
                  <li v-if="permissioncheck(this.$store.state.permission.PatientIdentification)">
                     <router-link to="/patient-identification">Patient Identification</router-link>
                  </li>
                  <li v-if="permissioncheck(this.$store.state.permission.ContactPatient)">
                     <router-link to="/patient-contact-report">Bulk Email</router-link>
                  </li>
                  <li v-if="permissioncheck(this.$store.state.permission.CovidExamination)">
                     <router-link to="/covid/examination-report">Covid Examination Report</router-link>
                  </li>
               </ul>
            </li>
            <li v-if="permissioncheck(this.$store.state.permission.ClinicalNote) == true ||
                  permissioncheck(this.$store.state.permission.MacroSettings) == true ||
                  permissioncheck(this.$store.state.permission.SignBulkClinicalNotes) == true ||
                  permissioncheck(this.$store.state.permission.ClinicalQualityMeasures) == true ||
                  permissioncheck(this.$store.state.permission.AutomatedMeasuresCalculationReport) == true ||
                  permissioncheck(this.$store.state.permission.AuditLogReport) == true ||
                  permissioncheck(this.$store.state.permission.PHIMailBox) == true 
                  ">
               <a href="#">Clinical</a>
               <ul>
                  <li>
                     <a v-if="permissioncheck(this.$store.state.permission.ClinicalNote) == true || permissioncheck(this.$store.state.permission.MacroSettings) == true || permissioncheck(this.$store.state.permission.SignBulkClinicalNotes) == true " href="javascript:void(0)"><b>Clinical Notes</b></a>
                     <ul>
                        <li v-if="permissioncheck(this.$store.state.permission.ClinicalNote)">
                           <router-link to="/clinical/notes/settings">Clinical Note Settings</router-link>
                        </li>
                        <li v-if="permissioncheck(this.$store.state.permission.MacroSettings)">
                           <router-link to="/clinical/macros">Macro Note Settings</router-link>
                        </li>
                        <li v-if="permissioncheck(this.$store.state.permission.SignBulkClinicalNotes)">
                           <router-link to="/clinical/notes">Sign Bulk Note</router-link>
                        </li>
                     </ul>
                  </li>
                  <li v-if="permissioncheck(this.$store.state.permission.ClinicalQualityMeasures)">
                     <router-link to="/cqm/list">Clinical Quality Measures</router-link>
                  </li>
                  <!-- <li v-if="permissioncheck(this.$store.state.permission.AutomatedMeasuresCalculationReport)">
                     <router-link to="/meaningful-automated-report">Meaningful Use(MU)</router-link>
                  </li> -->
                  <li v-if="permissioncheck(this.$store.state.permission.AuditLogReport)">
                     <router-link to="/audit-log">Audit Log Report</router-link>
                  </li>
                  <li v-if="permissioncheck(this.$store.state.permission.CDS_Management)">
                     <router-link to="/cds-rules">CDS Rules</router-link>
                  </li>
                  <li v-if="permissioncheck(this.$store.state.permission.PHIMailBox)">
                     <router-link to="/phi-mail-box">PHI Mail Box</router-link>
                  </li>
               </ul>
            </li>
            <li v-if="permissioncheck(this.$store.state.permission.PureCRMView) == true ||
               permissioncheck(this.$store.state.permission.StatusandPipeline) == true ||
               permissioncheck(this.$store.state.permission.TransferWellnessCoordinator) == true ||
               permissioncheck(this.$store.state.permission.CorporateList) == true ||
               permissioncheck(this.$store.state.permission.EventCalendar) == true ||
               permissioncheck(this.$store.state.permission.SubscriptionManagement) == true ||
               permissioncheck(this.$store.state.permission.CovidExams) == true ||
               permissioncheck(this.$store.state.permission.LifeStyleAppointment) == true ||
               permissioncheck(this.$store.state.permission.LifeStyleManagement) == true ||
               permissioncheck(this.$store.state.permission.PureCRMContacts) == true
            ">
               <a href="#">Services</a>
               <ul>
                  <li>
                     <a v-if="permissioncheck(this.$store.state.permission.PureCRMView) == true || permissioncheck(this.$store.state.permission.StatusandPipeline) == true || permissioncheck(this.$store.state.permission.TransferWellnessCoordinator) == true || permissioncheck(this.$store.state.permission.PureCRMContacts) == true" href="javascript:void(0)" class="position-relative">
                        <b>PureCRM</b>
                        <small v-show="is_email_not_counter" class="msgCount me-0" style="right: 40px;top: 8px;padding: 3px 7px !important;width: auto;height: auto;background: #0265a0;">{{ email_not_counter }}</small>
                     </a>
                     <ul>
                        <li v-if="permissioncheck(this.$store.state.permission.PureCRMView)">
                           <router-link to="/crm/prospect-patient">Opportunity Tracker</router-link>
                        </li>
                        <li v-if="permissioncheck(this.$store.state.permission.StatusandPipeline)">
                           <router-link to="/crm/status">Status & Pipeline Settings</router-link>
                        </li>
                        <li v-if="permissioncheck(this.$store.state.permission.TransferWellnessCoordinator)">
                           <router-link to="/crm/transfer-wellness-corodinator">Transfer Wellness Coordinator</router-link>
                        </li>
                        <li v-if="permissioncheck(this.$store.state.permission.PureCRMContacts)">
                           <router-link to="/crm/contacts">
                              PureCRM Contacts
                              <small v-show="is_email_not_counter" class="msgCount me-0" style="right: 40px;top: 8px;padding: 3px 7px !important;width: auto;height: auto;background: #0265a0;">{{ email_not_counter }}</small>
                           </router-link>
                        </li>
                        <li>
                           <router-link to="/crm/workflow/list">PureCRM WorkFlow</router-link>
                        </li>
                     </ul>
                  </li>
                  
                  
                  <!-- <li v-if="this.iv_lifestyle==true || this.iv_lifestyle==1"> -->
                  <li >
                     <a v-if="permissioncheck(this.$store.state.permission.CorporateList) == true || permissioncheck(this.$store.state.permission.EventCalendar) == true || permissioncheck(this.$store.state.permission.LifeStyleAppointment) == true || permissioncheck(this.$store.state.permission.LifeStyleManagement) == true" href="javascript:void(0)">Pure LifeStyle</a>
                     <ul>
                        <li v-if="permissioncheck(this.$store.state.permission.CorporateList)">
                           <router-link to="/corporate/list">Corporate Management</router-link>
                        </li>
                        <li v-if="permissioncheck(this.$store.state.permission.EventCalendar)">
                           <router-link to="/corporate/event-calendar">Corporate Event Calendar</router-link>
                        </li>
                        <li v-if="permissioncheck(this.$store.state.permission.LifeStyleAppointment)">
                           <router-link to="/life-style/appointment-list">LifeStyle Appointments</router-link>
                        </li>
                        <li v-if="permissioncheck(this.$store.state.permission.LifeStyleManagement)">
                           <router-link to="/life-style-process">LifeStyle Management</router-link>
                        </li>
                     </ul>
                  </li>
                  <li v-if="permissioncheck(this.$store.state.permission.SubscriptionManagement)">
                     <router-link to="/subscription-plans/list">Subscription Plan</router-link>
                  </li>
                  <!-- <li v-if="permissioncheck(this.$store.state.permission.CovidExams)">
                     <router-link to="/covid-exams">Covid Exams</router-link>
                  </li> -->
               </ul>
            </li>
            <li v-if="permissioncheck(this.$store.state.permission.Support) == true || permissioncheck(this.$store.state.permission.DevelopmentStatusReport) == true">
               <a href="javascript:void(0)">Help</a>
               <ul>
                  <li>
                     <router-link to="/about">About PureEHR</router-link>
                  </li>
                  <li v-if="permissioncheck(this.$store.state.permission.Support)">
                     <router-link to="/support">Support Tickets</router-link>
                  </li>
                  <li>
                     <a :href="path">Knowledge Base</a>
                  </li>
                  <!-- <li v-if="permissioncheck(this.$store.state.permission.DevelopmentStatusReport)">
                     <router-link to="/support/development/report">Development Report</router-link>
                  </li> -->
               </ul>
            </li>
         </ul>
         <div class="topsearchbx">
            <div class="input-group">
               <input
                  type="search"
                  class="form-control"
                  placeholder="Search"
                  aria-label="Search"
                  aria-describedby="button-addon2"
                  v-model="search_text"
                  @keyup="globleSearch"
                  />
               <button class="btn" type="submit" id="button-addon3">
               <i class="fa fa-search"></i>
               </button>
            </div>
            <div v-if="search_result.length > 0 && search_text != ''">
               <div
                  v-for="(item, index) in search_result"
                  :key="index"
                  class="global_search"
                  >
                  <router-link
                     :to="{ name: 'PatientDetails', params: { id: item.unique_id } }"
                     @click="increment"
                     >
                     <img
                        v-if="item.profile_avatar"
                        :src="item.profile_avatar"
                        class="img-fluid"
                        />
                     <img v-else src="images/patient-avtar.png" class="img-fluid" />
                     <div class="filter_results">
                        <p>{{ item.first_name }} {{ item.last_name }}</p>
                        <span v-if="item.date_of_birth != null">
                        {{ formatDate(item.date_of_birth, "MMM D, YYYY") }}
                        </span>
                     </div>
                  </router-link>
               </div>
            </div>
            <div
               v-if="
               search_text != '' && search_result.length == 0 && noresult == true
               "
               class="global_search"
               >
               <div class="filter_results">
                  <p>No patients found</p>
               </div>
            </div>
         </div>
      </nav>
   </div>
   <div id="emergency_modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg" style="max-width: 700px;">
         <div class="modal-content">
            <div class="modal-header">
               <h3 class="modal-title fw-900 m-0">Emergency Access</h3>
            </div>
            <div class="modal-body">
               <p>You are requesting Emergency Access for your account pureadmin.</p>
               <p>By continuing, you agree that you understand that you are exercising below permissions that were not not explicitly granted to your account.</p>
               <ol>
                  <li>Create & Update Patients</li>
                  <li>Access Patient Chart</li>
                  <li>View Clinical Notes</li>
               </ol>
               <p>It is your responsibility to terminate your emergency access when your task is complete.</p>
               <p class="mb-0">By switching of Emergency Access switch off.</p>
               <small>Or</small>
               <p>Logging out from current session.</p>
               <p>You must provide a reason as to why you are using your emergency access permissions.</p>
               <div class="form-group">
                  <textarea v-model="notes" class="form-control" rows="2" placeholder="Enter your descriptive reason to initiate Emergency Access"></textarea>
                  <div class="error-msg">
                     <ul class="mb-0 p-0 fs-14">
                        <li class="d-block">{{this.error}}</li>
                     </ul>
                  </div>
               </div>
            </div>
            <div class="modal-footer">
               <button @click="closeModel" type="button" class="waves-effect waves-light btn btn-danger" data-bs-dismiss="modal">Close</button>
               <button @click="save" type="button" class="waves-effect waves-light btn btn-info">Raise Permissions</button>
            </div>
         </div>
      </div>
   </div>
</template>
<script>

   var $localthis;

   window.onstorage = (event) => {
      if(event.key=="continueLogout") {
         if(event.newValue=="false") {
            location.reload();
         }
         if(event.newValue=="true") {
            $localthis.autologout();
         }
      }
   }

   import { mapGetters, mapActions } from "vuex";
   import axios from "@/scripts/axios.js";
   import moment from "moment";
   import permissionMixins from "@/scripts/mixins/permission.js";
   import submitMixins from "@/scripts/mixins/submitMixins.js";
   import Popper from "vue3-popper";
   export default {
      components: {Popper},
      mixins: [permissionMixins,submitMixins],
      name: "TheHeader",
      props: {
         msg: String,
      },
      data() {
         return {
            is_production_env: (process.env.VUE_APP_NODE_ENV == 'production') ? true : false,
            notes:'',
            error:'',
            emergency_id:'',
            emergency_status:"off",
            search_text: "",
            search_result: [],
            noresult: true,
            rx_notification_data : {
               rcopia_notification:0,
               rx_pending:0,
               refill:0,
               rxchange:0,
               rx_need_signing:0,
               other:0,
               total:0,
            },
            rcopia_notification_system_name:'',
            rcopia_notification_practice_user_name:'',
            rcopia_notification_action_url:'',
            rcopia_notification_provider_external_id:'',
            rcopia_vendor_pwd:'',
            auth_user_type:'',
            userid : '',
            path: process.env.VUE_APP_KB_DOCUMENT_MAIN_PAGE,
            selected_active_provider: '',
            is_email_not_counter: false,
            email_not_counter: 0,
            iv_lifestyle:false,
         };
      },
      beforeCreate(){
         $localthis = this;
         this.userid = localStorage.getItem("user_id");         
      },
      computed: {
         ...mapGetters("auth", ["user", "provider_list", "active_provider"]),
      },
      methods: {
         ...mapActions("auth", ["getUserData", "getProviderList"]),
   
         autologout() {
            if(localStorage.getItem("continueLogout")=="true") {
               this.$swal.fire({
                  title: 'Your session is about to expire! <label class="Timer"></label>',
                  text: "Your session will expire in 1 minutes, you can carry on or end your session.",
                  icon: "warning",
                  dangerMode: true,
                  showCancelButton: true,
                  confirmButtonColor: "#ee3158",
                  cancelButtonColor: "#bdbdbd",
                  confirmButtonText: "Keep me signed in.",
               })
               .then((okay) => {
                  if(okay.isConfirmed == true) {
                     
                     localStorage.setItem("loginExpireTime", this.createLoginExpireTime());
                     localStorage.setItem("continueLogout", "false");
                     clearInterval(Timeinterval);
                  } else {
                     if(localStorage.getItem("continueLogout")=="true") {
                        this.$store.dispatch("auth/sendLogoutRequest");
                     }
                  }
               });

               var $this = this;
               var timer2 = "1:01",Timeinterval = setInterval(function() {
                  var timer = timer2.split(':'),minutes = parseInt(timer[0], 10), seconds = parseInt(timer[1], 10);
      
                  --seconds;
                  minutes = (seconds < 0) ? --minutes : minutes;
                  if (minutes < 0) 
                     clearInterval(Timeinterval);
                  seconds = (seconds < 0) ? 59 : seconds;
                  seconds = (seconds < 10) ? '0' + seconds : seconds;
                  var finalTime = minutes + ':' + seconds;
                  if(finalTime === '0:00')
                  {
                     if(localStorage.getItem("continueLogout")=="true") {
                        $this.$store.dispatch("auth/sendLogoutRequest");
                     }
                  }
                  $(".swal2-title").children(".Timer").html(minutes + ':' + seconds);
                  timer2 = minutes + ':' + seconds;
               }, 600);
            }
         },
         upcommngMenu() {
            this.moshaToast("This service is under development", "info");
         },
         emergency(){
            var emergency = $('#emergency').hasClass('active');
            if(emergency == true){
               $('#emergency_modal').modal('show');
            }
            else{
               axios.post('/emergency-access/store',{
                  emergency_id :this.emergency_id,
                  status :'off',
                  user_type:this.user.user_type,
               }).then(response => {
                  $('.sm-blue').removeClass('red_bg_sm');
                  $('#emergency_modal').modal('hide');
                  location.reload()
               });   
            }
         },
         closeModel(){
            var emergency = $('#emergency').removeClass('active');
         },
   
         save(){
            this.user_id = this.user.user_id;
            this.user_type = this.user.user_type;
            var emergency = $('#emergency').hasClass('active');
            if(emergency == true){
               this.status = "on";
            }
            else{
               this.status = "off";
               $('.sm-blue').removeClass('red_bg_sm');
            }
            
            if(!this.notes){
               this.error = "Note is required"
            }
            else{
               axios.post('/emergency-access/store',{
                  emergency_id :this.user_id,
                  notes :this.notes,
                  status :this.status,
                  user_type:this.user_type,
               })
               .then(response => {
                  this.emergency_id = response.data.data.id,
                  this.notes="";
                  location.reload();
               });   
   
               $('.sm-blue').addClass('red_bg_sm');
               $('#emergency_modal').modal('hide');
            }
         },
   
         getuser(){
            if(this.user) {
               axios.post('/emergency-access/get',{
                  emergency_id : (this.user ? this.user.user_id : ''),
               })
               .then(response => {
                  if(response.data.data != null ){
                     this.emergency_id = response.data.data.id;
                     if(response.data.data.status == "on"){
                        var emergency = $('#emergency').addClass('active');      
                        this.emergency_status = "on";
                        $('.sm-blue').addClass('red_bg_sm');
                     }
                  }
               });
               this.auth_user_type = this.user.user_type;
            }
         },
   
         logout() {
            if(this.emergency_id == ""){
               this.$store.dispatch("auth/sendLogoutRequest");
            }
            else{
               axios.post('/emergency-access/store',{
                  emergency_id :this.emergency_id,
                  status :'off',
                  user_type:this.user.user_type,
               }).then(response => {
                  this.$store.dispatch("auth/sendLogoutRequest");
               });   
            }
         },
   
         status_change(id){
            var $this = this;
            axios.get("notification-status/"+id)
            .then((response) => {
               this.get_notification();
            });
         },
   
         clear_notification(id){
            axios
            .post("notification-remove",{"userId":this.user.user_id,"id":id})
            .then((response) => {
               this.get_notification();
            });
         },
         change_count(count,arry){
            if(count>0) {
               this.is_email_not_counter = false;
               this.email_not_counter = 0;
               let counter_ER = 0;
               let email_url_list = [];
               let remove = [];
               arry.map((itm, idx) => {
                  if(this.permissioncheck(this.$store.state.permission.PureCRMContacts)){
                     if(itm.notification_type=="Email reply" || itm.notification_type=="SMS reply") {
                        email_url_list.push(itm.notification_link);
                        counter_ER++;
                     }
                  }else{
                     if(itm.notification_type=="Email reply" || itm.notification_type=="SMS reply") {                        
                        remove.push(idx);
                     }
                  }
               });
               remove.map(obj => {
                  this.$store.state.auth.notificationData.splice(obj, 1); 
               });
               this.$store.state.auth.notificationCounter = this.$store.state.auth.notificationCounter-remove.length
               if(counter_ER>0) {
                  this.is_email_not_counter = true;
                  this.email_not_counter = counter_ER;
                  this.$store.state.auth.email_reply_not_url_list = email_url_list;
               }
            } else {
               this.is_email_not_counter = false;
               this.email_not_counter = 0;
            }
         },
         get_notification(){
            axios.get("get-notification").then(response => {
               this.$store.state.auth.notificationData = response.data.data;
               this.$store.state.auth.notificationCounter = response.data.data.length;
               /** Update email counter */
               setTimeout(() => {
                  this.change_count(this.$store.state.auth.notificationCounter,this.$store.state.auth.notificationData);
               }, 500);
            }).catch((e) => {
               console.log(e)
            });
         },
         rx_notification() {
            axios.post(`get-rx-notification`)
            .then((response) => {            
               if(response.data.data.total > 0){
                  this.rx_notification_data = response.data.data;
               }
            });
         },
         async setActiveProvider(provider) {
            if(this.$route.name != "ClinicalNotes"){
               await this.$store.dispatch("auth/setActiveProvider", provider);
            }
         },
         formatDate(date, format) {
            return moment(date).format(format);
         },
         globleSearch() {
            this.noresult = true;
            if (this.search_text.length > 1) {
               var data = { search_text: this.search_text };
               axios
               .post("patient/patient-search", data)
               .then((response) => (this.search_result = response.data.data));
            }
         },
         increment(type) {
            if (type == "blur") {
               this.search_result = [];
               this.noresult = false;
            } else {
               this.search_text = "";
               this.search_result = [];
            }
         },
         checklength() {
            if (this.search_text == "") {
               this.increment();
            }
         },
         login_to_erx(lending_page){
            /*Rcopia*/
            axios.post(`rcopia-access`).then((response) => {
               this.rcopia_notification_system_name = response.data.data.system_name;
               this.rcopia_notification_practice_user_name = response.data.data.practice_user_name;
               this.rcopia_notification_action_url = response.data.data.action_url;
               this.rcopia_notification_provider_external_id = response.data.data.provider_external_id;
               this.rcopia_vendor_pwd = response.data.data.vendor_pwd;
               this.launch_rcopia(lending_page, this.rcopia_vendor_pwd);
            });
            /*Rcopia*/        
         },
         launch_rcopia(lending_page, vendor_pwd){     
            if($("form#rcopiaNotificationForm").length>0){
               document.getElementById("notification_rcopia_portal_system_name").value = this.rcopia_notification_system_name;
               document.getElementById("notification_rcopia_practice_user_name").value = this.rcopia_notification_practice_user_name;
               document.getElementById("rcopiaNotificationForm").setAttribute("action",this.rcopia_notification_action_url);
               document.getElementById("notification_rcopia_user_external_id").value = this.rcopia_notification_provider_external_id;
               document.getElementById("notification_startup_screen").value = lending_page;
   
               var timenow = this.gmtTime();
               var strMAC = this.GenerateStringForMAC(timenow, vendor_pwd);
               var md5Hash = CryptoJS.MD5(strMAC).toString();
               md5Hash = md5Hash.toUpperCase();          
               $("#rcopiaNotificationForm input[name='MAC']").val(md5Hash);
               $("#rcopiaNotificationForm input[id='notification_time']").val(timenow);
            }else{
               var rcopiaNotificationForm = document.createElement("form");
               rcopiaNotificationForm.setAttribute("name", "rcopiaNotificationForm");
               rcopiaNotificationForm.setAttribute("id", "rcopiaNotificationForm");
               rcopiaNotificationForm.setAttribute("style", "display:none");
               rcopiaNotificationForm.setAttribute("method", "post");
               rcopiaNotificationForm.setAttribute("action", this.rcopia_notification_action_url);
   
               document.body.appendChild(rcopiaNotificationForm);
               /*Element 1*/     
               var elem = document.createElement('input');
               elem.setAttribute("type", "text");
               elem.setAttribute("id", "notification_rcopia_portal_system_name");
               elem.setAttribute("name", "rcopia_portal_system_name");
               elem.setAttribute("value", this.rcopia_notification_system_name);
               rcopiaNotificationForm.appendChild(elem);
   
               /*Element 2*/     
               var elem = document.createElement('input');
               elem.setAttribute("type", "text");
               elem.setAttribute("id", "notification_rcopia_practice_user_name");
               elem.setAttribute("name", "rcopia_practice_user_name");
               elem.setAttribute("value", this.rcopia_notification_practice_user_name);
               rcopiaNotificationForm.appendChild(elem);
   
               /*Element 3*/     
               var elem = document.createElement('input');
               elem.setAttribute("type", "text");
               elem.setAttribute("id", "notification_rcopia_user_id");
               elem.setAttribute("name", "rcopia_user_id");
               elem.setAttribute("value", "");
               rcopiaNotificationForm.appendChild(elem);
   
               /*Element 4*/     
               var elem = document.createElement('input');
               elem.setAttribute("type", "text");
               elem.setAttribute("id", "notification_rcopia_user_external_id");
               elem.setAttribute("name", "rcopia_user_external_id");
               elem.setAttribute("value", this.rcopia_notification_provider_external_id);      
               rcopiaNotificationForm.appendChild(elem);          
   
               /*Element 8*/     
               var elem = document.createElement('input');
               elem.setAttribute("type", "text");
               elem.setAttribute("id", "notification_service");
               elem.setAttribute("name", "service");
               elem.setAttribute("value", "rcopia");      
               rcopiaNotificationForm.appendChild(elem);
   
               /*Element 9*/     
               var elem = document.createElement('input');
               elem.setAttribute("type", "text");
               elem.setAttribute("id", "notification_startup_screen");
               elem.setAttribute("name", "startup_screen");
               elem.setAttribute("value", lending_page);      
               rcopiaNotificationForm.appendChild(elem);
   
               /*Element 10*/     
               var elem = document.createElement('input');
               elem.setAttribute("type", "text");
               elem.setAttribute("id", "notification_close_window");
               elem.setAttribute("name", "close_window");
               elem.setAttribute("value", "n");      
               rcopiaNotificationForm.appendChild(elem);
   
               /*Element 11*/     
               var elem = document.createElement('input');
               elem.setAttribute("type", "text");
               elem.setAttribute("id", "notification_allow_popup_screens");
               elem.setAttribute("name", "allow_popup_screens");
               elem.setAttribute("value", "y");      
               rcopiaNotificationForm.appendChild(elem);
   
   
               /*Element 11*/     
               var elem = document.createElement('input');
               elem.setAttribute("type", "text");
               elem.setAttribute("id", "notification_logout_url");
               elem.setAttribute("name", "logout_url");
               elem.setAttribute("value", window.location.href);      
               rcopiaNotificationForm.appendChild(elem);
   
               var elem = document.createElement('input');
               elem.setAttribute("type", "text");
               elem.setAttribute("id", "notification_timeout_url");
               elem.setAttribute("name", "timeout_url");
               elem.setAttribute("value", window.location.href+"/#rx-err");      
               rcopiaNotificationForm.appendChild(elem);
   
   
               /*Element 11*/     
               var elem = document.createElement('input');
               elem.setAttribute("type", "text");
               elem.setAttribute("id", "notification_limp_mode");
               elem.setAttribute("name", "limp_mode");
               elem.setAttribute("value", 'y');      
               rcopiaNotificationForm.appendChild(elem);
   
               var elem = document.createElement("input");
               elem.setAttribute("type", "text");
               elem.setAttribute("name", "action");
               elem.setAttribute("id", "notification_action");
               elem.setAttribute("value", "login");
               rcopiaNotificationForm.appendChild(elem);
   
               var timenow = this.gmtTime();
               var elem = document.createElement("input");
               elem.setAttribute("type", "text");
               elem.setAttribute("name", "time");
               elem.setAttribute("id", "notification_time");
               elem.setAttribute("value", timenow);
               rcopiaNotificationForm.appendChild(elem);
   
               var elem = document.createElement("input");
               elem.setAttribute("type", "text");
               elem.setAttribute("name", "skip_auth");
               elem.setAttribute("id", "notification_skip_auth");
               elem.setAttribute("value", "n");
               rcopiaNotificationForm.appendChild(elem);
   
               var strMAC = this.GenerateStringForMAC(timenow, vendor_pwd);
               var md5Hash = CryptoJS.MD5(strMAC).toString();
               md5Hash = md5Hash.toUpperCase();
   
               elem = document.createElement("input");
               elem.setAttribute("type", "text");
               elem.setAttribute("name", "MAC");
               elem.setAttribute("value", md5Hash);
               rcopiaNotificationForm.appendChild(elem);
            }
            function submitToPopup(f) {
               /*var w = window.open('', '_blank', 'height=600,width=1200');*/
               f.target = '_blank';
               f.submit();
            }
            /*SSO Login*/
            submitToPopup(document.rcopiaNotificationForm);
            /*SSO Login - End*/
   
            /*Rcopia SSO Request Logs*/
            axios.post(`create-rcopia-log`, { login_type: "SSO - Notification" });
            /*Rcopia SSO Request Logs -- End*/
         },
         GenerateStringForMAC : function(timenowtemp, vendor_pwd) {        
            var str = "rcopia_portal_system_name=" + document.getElementById("notification_rcopia_portal_system_name").value +
            "&rcopia_practice_user_name=" + document.getElementById("notification_rcopia_practice_user_name").value +
            "&rcopia_user_id=" + document.getElementById("notification_rcopia_user_id").value +
            "&rcopia_user_external_id=" + document.getElementById("notification_rcopia_user_external_id").value +
            "&service=" + document.getElementById("notification_service").value +
            "&startup_screen=" + document.getElementById("notification_startup_screen").value +
            "&close_window=" + document.getElementById("notification_close_window").value +
            "&allow_popup_screens=" + document.getElementById("notification_allow_popup_screens").value +
            "&logout_url=" + document.getElementById("notification_logout_url").value +
            "&timeout_url=" + document.getElementById("notification_timeout_url").value +
            "&limp_mode=" + document.getElementById("notification_limp_mode").value +
            "&action=login" + 
            "&time=" + timenowtemp +
            "&skip_auth=" + document.getElementById("notification_skip_auth").value +
               //"&posturl=" + posturl.Value +
            vendor_pwd;
            return str;
         },
         gmtTime : function () {
            var GMTTime = new Date();
            var month = GMTTime.getUTCMonth() + 1;
            var day = GMTTime.getUTCDate();
            var year = GMTTime.getUTCFullYear() - 2000;
            var hour = GMTTime.getUTCHours();
            var min = GMTTime.getUTCMinutes();
            var sec = GMTTime.getUTCSeconds();

            if (month < 10) { month = "0" + month; }
            if (day < 10) { day = "0" + day; }
            if (hour < 10) { hour = "0" + hour; }
            if (hour < 1) { hour = "00"; }
            if (min < 10) { min = "0" + min; }
            if (min < 1) { min = "00"; }
            if (sec < 10) { sec = "0" + sec; }
            if (sec < 1) { sec = "00"; }

            return  month.toString() + day.toString() + year.toString() + hour.toString() + min.toString() + sec.toString();
         },
         onDomClick(event){
            /*for ideal activity*/
            // sessionStorage.setItem("timerCount", new Date());
            localStorage.setItem("timerCount", new Date());
            /*for ideal activity -- End*/
         },
         createLoginExpireTime() {
            let currTime = new Date();
            return new Date(currTime.setMinutes(currTime.getMinutes() + 60));
         }
      },
      created() {
         if (!this.user) {
            this.getUserData();
         }
         if (!this.provider_list) {
            this.getProviderList();
         }         
         this.rx_notification();
      },
      mounted() {
         document.addEventListener('click', this.onDomClick);         
         /*user notification counter*/
         var $this = this;
         setInterval(function(){ 
            $this.get_notification();            
         }, 60000);   
         /*user notification counter -- End*/

         /*RX SSO timeout Logs*/
         var location = window.location.href;
         if(location.indexOf('#rx-err')!=-1)
         {
            axios.post(`/patient/${this.$route.params.id}/create-rcopia-log`, { patient_id : this.$store.state.patientDetail.patient_id, patient_rcopia_id: this.$store.state.patientDetail.patient_rcopia_id, login_type: "SSO - Notification", response_type: "Timeout" });
         }
         /*RX SSO timeout Logs -- End*/


         /*Auto Logout manage for ideal user activity*/
         let self = this;
         localStorage.setItem("loginExpireTime", this.createLoginExpireTime());
         localStorage.setItem("continueLogout", "false");

         setInterval(function() {
            // var check = new Date(sessionStorage.getItem("timerCount"));
            if(localStorage.getItem("continueLogout")=="false") {
               var check = new Date(localStorage.getItem("loginExpireTime"));
               var curDate = new Date();
               if (curDate.getTime()>=check.getTime()) {
                  localStorage.setItem("continueLogout", "true");
                  self.autologout();
               }
            }
         }, 60000); //3600000); // setup 1 hour interval
         
         /*Auto Logout manage for ideal user activity -- End*/     

         this.$store.dispatch("auth/getRecentPatient");
      },
      watch: {
         "$route.name": function(newValue) {
            localStorage.setItem("timerCount", new Date());
            this.increment();
         },
         "filter.search_text": function() {
            this.checklength();
         },
         "user": function() {
            this.getuser();
            this.get_notification();
            if(this.$store.state.auth.is_lifestyle_specific_enable==1){
              this.iv_lifestyle=true;
            }            
         },
         "provider_list": function(){
            if(this.provider_list.length == 1){
               if(this.user){
                  if(this.user.user_type == 'provider'){
                     this.selected_active_provider = this.user.user_id;
                  }else{
                     this.selected_active_provider = this.user.primary_provider_id;
                  }
               }
            }else{
               this.selected_active_provider = localStorage.getItem("activeProvider");
            }
         }
      }            
   };
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->