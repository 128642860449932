<template>
  <div class="content-wrapper">
    <div class="container-full">
      <div class="content-header">
        <div class="row justify-content-between">
          <div class="col-12 col-sm-5 col-md-6">
            <h2 class="heading2 my-5">Consent Forms <a href="#" data-bs-toggle="modal" data-bs-target="#consentdocument" title="Reference of Knowledge Base" v-on:click.prevent="reset()"> <i class="fa fa-question-circle fs-20"></i></a></h2>
          </div>
          <div class="col-12 col-sm-7 col-md-6 text-sm-end">
            <router-link
              to="/consent-forms/archived"
              class="waves-effect waves-light btn btn-info-light my-1 me-1"
              >Show Archived</router-link>
            <a
              href=""
              class="waves-effect waves-light btn btn-info my-1"
              data-bs-toggle="modal"
              @click="add"
              data-bs-target="#myConsentModal"
              ><i class="fa fa-plus fs-14"></i> Consent Form</a>
          </div>
          <div
            id="myConsentModal"
            class="modal fade"
            tabindex="-1"
            role="dialog"
            aria-labelledby="myModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-lg">
              <div class="modal-content">
                <div class="modal-header">
                  <h3 class="modal-title fw-900 mt-0" id="myModalLabel">
                    {{ heddingtitle }}
                  </h3>
                </div>
                <div class="modal-body">
                  <input-field
                    title="Title for consent form "
                    v-model="v$.form.title.$model"
                    :errors="v$.form.title.$errors"
                    :isRequired="true"
                    labelClass="col-form-label col-12 col-md-6 text-md-end mb-0"
                    divClass="col-12 col-md-6"
                  >
                  </input-field>

                  <check-box
                    title="Require patient to agree"
                    v-model="form.is_mandatory"
                    labelClass="col-12 col-sm-8 col-md-6 text-md-end mb-0"
                    divClass="col-12 col-sm-2 col-md-6"
                  ></check-box>

                  <check-box
                    title="Assign by default on new appointments"
                    v-model="form.in_appointment"
                    labelClass="col-12 col-sm-8 col-md-6 text-md-end mb-0"
                    divClass="col-12 col-sm-2 col-md-6"
                  ></check-box>

                  <file-upload
                    v-if="this.$store.state.ConsentForms.edit != 1"
                    id="document_name"
                    title="Custom Consent Form"
                    v-model="v$.form.document_name.$model"
                    :errors="v$.form.document_name.$errors"
                    :isRequired="true"
                    labelClass="col-form-label col-12 col-md-6 text-md-end"
                    divClass="col-12 col-md-6"
                  ></file-upload>
                </div>
                <div class="modal-footer">
                  <button
                    @click="save()"
                    type="button"
                    class="waves-effect waves-light btn btn-info">
                    {{ buttonlabel }}
                  </button>
                  <button
                    type="button"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    class="waves-effect waves-light btn btn-danger">
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section class="content">
        <div class="box">
          <div class="box-body">
            <div class="table-responsive">
              <center>
                <span class="isvalid-feedback" style="display:block;">{{
                  success
                }}</span>
              </center>
              <data-table-server dataType="ConsentForms">
                <template v-slot="slotProps">
                  <td v-if="slotProps.item.document_file_exist == true">
                    <a
                      href="javascript:void(0);"  @click="FileDisplay(slotProps.item.document_name_url)" class="fw-500">{{ slotProps.item.title }}</a>
                  </td>
                  <td v-else>{{ slotProps.item.title }}</td>
                  <td>{{ slotProps.item.salutation }} {{ slotProps.item.owner }} {{ slotProps.item.suffix }}</td>
                  <td>
                    <div class="controls">
                      <input
                        v-if="slotProps.item.is_mandatory == 1"
                        checked
                        type="checkbox"
                        v-bind:id="'first_' + slotProps.item.id"
                        value="single"
                        class="filled-in chk-col-info"
                        disabled
                      />
                      <input
                        v-else
                        type="checkbox"
                        v-bind:id="'first_' + slotProps.item.id"
                        value="single"
                        class="filled-in chk-col-info"
                        disabled
                      />
                      <label
                        v-bind:for="'first_' + slotProps.item.id"
                        class="m-0"
                      ></label>
                    </div>
                  </td>
                  <td>
                    <div class="controls">
                      <input
                        v-if="slotProps.item.in_appointment == 1"
                        checked
                        type="checkbox"
                        v-bind:id="'two_' + slotProps.item.id"
                        value="single"
                        class="filled-in chk-col-info"
                        disabled
                      />
                      <input
                        v-else
                        type="checkbox"
                        v-bind:id="'two_' + slotProps.item.id"
                        value="single"
                        class="filled-in chk-col-info"
                        disabled
                      />
                      <label
                        v-bind:for="'two_' + slotProps.item.id"
                        class="m-0"
                      ></label>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex">
                      <Popper arrow content="Edit Consent form" :hover="true" class="helptips cursor-pointer">
                      <a
                        href="#"
                        @click="edit(slotProps.item.id)"
                        class="waves-effect waves-circle btn btn-circle btn-info btn-xs me-1"
                        ><i class="fa fa-pencil"></i></a>
                      </Popper>
                      <Popper arrow content="Delete Consent form" :hover="true" class="helptips cursor-pointer">
                      <a
                        href="#"
                        @click="remove(slotProps.item.id)"
                        class="waves-effect waves-circle btn btn-circle btn-danger btn-xs"
                        ><i class="fa fa-trash"></i></a>
                      </Popper>
                    </div>
                  </td>
                </template>
              </data-table-server>
            </div>
          </div>
        </div>
      </section>
    <!--document model start-->
    <div id="consentdocument" class="modal fade" tabindex="-1" role="dialog"
      aria-labelledby="myModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-md" style="max-width: 600px;">
      <div class="modal-content">
        <div class="modal-header">
        <h3 class="modal-title fw-900 mt-0">Reference of Knowledge Base</h3>
        </div>
        <div class="modal-body overflow-auto" style="max-height: 350px;">
        <table class="table table-bordered">
          <thead>
          <tr>
            <th scope="col">No.</th>
            <th scope="col">Knowledge Base Articles</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>1</td>
            <td> <a :href="path+'how-to-add-a-new-consent-form-in-pureehr'" target="_blank">How To Add A New Consent Form In PureEHR?</a></td>
          </tr>
          <tr>
            <td>2</td>
            <td> <a :href="path+'how-to-edit-a-consent-forms-in-pureehr-in-pureehr'" target="_blank">How To Edit A Consent Forms In PureEHR In PureEHR?</a></td>
          </tr>
          <tr>
            <td>3</td>
            <td> <a :href="path+'how-to-delete-consent-forms-in-pureehr'" target="_blank">How To Delete Consent Forms In PureEHR?</a></td>
          </tr>
          <tr>
            <td>4</td>
            <td> <a :href="path+'how-to-view-deleted-consent-forms-in-pureehr'" target="_blank">How To View Deleted Consent Forms In PureEHR?</a></td>
          </tr>
          </tbody>
        </table>
        <Search :key="searchKey"></Search>
        </div>
        <div class="modal-footer">
        <button type="button" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
        </div>
      </div>
      </div>
    </div>
    <!--document model end-->
    </div>
  </div>
</template>

<script>
import InputField from "@/components/base/formFields/InputField.vue";
import FileUpload from "@/components/base/formFields/fileUpload.vue";
import CheckBox from "@/components/base/formFields/checkBox.vue";
import DataTableServer from "@/components/base/DataTableServer.vue";
import Popper from "vue3-popper";

import useVuelidate from "@vuelidate/core";
import submitMixins from "@/scripts/mixins/submitMixins.js";
import axios from "@/scripts/axios.js";
import Search from "@/components/Search.vue";

export default {
  mixins: [submitMixins],
  setup: () => {
    return { v$: useVuelidate() };
  },
  components: {
    DataTableServer,
    InputField,
    FileUpload,
    CheckBox,
    Popper,
    Search
  },
  computed: {
    form() {
      return this.$store.state.ConsentForms.form;
    },
    vuelidateExternalResults() {
      return this.$store.state.ConsentForms.vuelidateExternalResults;
    },
    validationRules() {
      return this.$store.state.ConsentForms.validationRules;
    },
  },
  data() {
    return {
      dataTypes: "ConsentForms",
      heddingtitle: "Add Consent Form",
      buttonlabel: "Upload Document",
      path: process.env.VUE_APP_KB_DOCUMENT,
      searchKey:0,
    };
  },
  methods: {
    reset() {
      this.searchKey = this.searchKey + 1;
    },
    FileDisplay(fileName) {
      window.open(fileName);
    },
    save() {
      if (this.$store.state.ConsentForms.edit != 1) {
        this.postForm("/consent-form/store", "ConsentForms", true, "formdata");
      } else {
        this.postForm("/consent-form/update", "ConsentForms", true, "formdata");
      }
    },
    formPostSuccess(data) {
      this.$store.state.ConsentForms.form = {};
      this.v$.$reset();
      Object.assign(this.$store.state.ConsentForms.form);
      this.$store.dispatch("getSimpleTableContents", this.dataTypes);
      if (this.$store.state.ConsentForms.edit != 1) {
        this.moshaToast("Record added successfully.", "success");
      } else {
        this.moshaToast("Record updated successfully.", "success");
      }
      setTimeout(function() {
        location.reload();
      }, 500);
    },
    remove(id) {
      this.$swal
        .fire({
          title: '<h2 class="mt-0">Are you sure?</h2>',
          text:
            "You want to delete these records? This process cannot be undone.",
          icon: "warning",
          dangerMode: true,
          showCancelButton: true,
          confirmButtonColor: "#ee3158",
          cancelButtonColor: "#bdbdbd",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed == true) {
            axios.post("/consent-form/delete", { id: id }).then((response) => {
              this.$swal
                .fire(
                  "Deleted!",
                  "Consent form has been deleted.",
                  "success"
                )
                .then((result) => {
                  if (result.isConfirmed == true) {
                    location.reload();
                  }
                });
            });
          }
        });
    },

    edit(id) {
      this.heddingtitle = "Edit Consent Form";
      this.buttonlabel = "Update Document";
      this.$store.state.ConsentForms.edit = 1;
      axios.get("/consent-form/edit/" + id).then((result) => {
        this.$store.state.ConsentForms.form = result.data.data;
        this.$store.state.ConsentForms.form.is_mandatory = Number(
          this.$store.state.ConsentForms.form.is_mandatory
        );
        this.$store.state.ConsentForms.form.in_appointment = Number(
          this.$store.state.ConsentForms.form.in_appointment
        );
      });
      $("#myConsentModal").modal("show");
    },
    add() {
      this.heddingtitle = "Add Consent Form";
      this.buttonlabel = "Upload Document";
      this.$store.state.ConsentForms.edit = 0;
      this.$store.state.ConsentForms.form = {};
    }
  },
  validations() {
    return this.$store.state.ConsentForms.validationRules;
  },
};
</script>
