import {
  helpers,
  required,
  email,
  minLength,
  maxLength,
  sameAs,
  url,
} from "@vuelidate/validators";


const workflow = {
  namespaced: true,

  state: {
    editID: "",
    listUrl: "/crm/workflow/list",
    getEditUrl: "/corporate/:id/edit",
    tableData: {
      header: [
        "Workflow Name",
        "Created At",
        "Last Modified",
        "Actions",
      ],
    },
    form: {},
    list: [],
  },
  getters: {
    getTableHeaders: (state) => state.header,
    getTableContents: (state) => state.list,
    getListURL: (state) => state.listUrl,
  },
  mutations: {},
  actions: {},
};


export {workflow};
