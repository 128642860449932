<template>
  <div class="form-group row" :class="{ error: errors.length }">
    <label :class="labelClass"
      >{{ title }}
      <small v-show="isRequired" class="text-danger asterisksign"
        >*</small
      ></label
    >
    <div :class="divClass">
      <select
        name="select"
        required
        class="form-select form-control "
        :value="modelValue"
        :disabled ="disabled"
        @change="$emit('update:modelValue', $event.target.value)"
        :style="style"
      >
        <option value="" disabled="disabled">Select {{ title }}</option>
        <option :value="item.id" v-for="item in data" :key="item.id"
          >{{ item.salutation }} {{ item.first_name }} {{ item.last_name }} {{ item.suffix }}</option
        >
      </select>
      <p v-if="error" class="error-msg fs-14">
        Could not get {{ title }} options. Click
        <a @click="window.location.reload(true)" class="fs-14"> HERE </a> to
        reload the page and try again
      </p>
      <div class="error-msg">
        <ul class="mb-0 p-0 fs-14">
          <li class="d-block" v-for="(error, index) of errors" :key="index">
            {{ error.$message }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import submitMixins from "@/scripts/mixins/submitMixins.js";
export default {
  mixins: [submitMixins],
  props: {
    modelValue: {},
    errors: {
      type: Object,
      default: {},
    },
    title: {
      type: String,
    },
    getDataURL: {
      type: String,
    },
    isRequired: {
      type: Boolean,
    },
    labelClass: {
      type: String,
      default:
        "col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end",
    },
    divClass: {
      type: String,
      default: "col-12 col-sm-8 col-md-8 col-lg-5 col-xl-3",
    },
    disabled:{
      type: Boolean,
      default:false,
    },
    style:{
      type: String,
      default: ""
    },
  },
  data() {
    return {
      data: "",
      error: false,
    };
  },
  async created() {
    this.data = await this.getData(this.getDataURL);

    if (!this.data) {
      this.error = true;
    }
  },
};
</script>
