<template>
   <div class="panel pagecollap">
         <div class="panel-heading" id="ongoingproblems" role="tab">
            <a class="panel-title" aria-controls="answer-2" aria-expanded="false" data-bs-toggle="collapse" href="#answer-2" data-parent="#accordion2">Active Problems</a>
         </div>
         <div class="panel-collapse collapse" id="answer-2" aria-labelledby="ongoingproblems" role="tabpanel" data-bs-parent="#category-1">
            <div class="panel-body">
               <div class="table-responsive">
                  <table-base :dataTypes="{dataType: 'OngoingProblems', patient_id: patient_id,patient_unique_id:patient_unique_id,method:'get',title:'No any active problems.'}">
                           <template v-slot="slotProps">
                              <td>{{ slotProps.item.problem }}</td>
                              <td>{{ slotProps.item.ICD_10}}</td>
                              <td>{{ $filters.displayDateTimeLocal(slotProps.item.datetime_diagnosis) }}</td>
                              <td>{{ slotProps.item.status}}</td>
                              <td>{{ slotProps.item.description}}</td>
                           </template>
                  </table-base>
               </div>
            </div>
         </div>
      </div>
</template>
<script>
import TableBase from "@/components/base/Table.vue";
export default {
   components: { 
      TableBase,
   },
   data() 
   {
      return {
         patient_id : this.$store.state.patientDetail.patient_id,
         patient_unique_id:this.$store.state.patientDetail.patient_unique_id,
      };
   }
};
</script>