<template>
	<div class="content-wrapper">
		<div class="container-full">
			<div class="content-header">
				<div class="row justify-content-between">
					<div class="col-12 col-sm-4 col-md-6 col-lg-8">
						<h1 class="heading1 mt-0">Staff Management <a href="#" data-bs-toggle="modal" data-bs-target="#staffdocument" title="Reference of Knowledge Base" v-on:click.prevent="reset()"> <i class="fa fa-question-circle fs-20"></i></a></h1>
					</div>
					<div class="col-12 col-sm-8 col-md-6 col-lg-4 text-sm-end">
						<a href="#/staff/archived" class="waves-effect waves-light btn btn-info-light mb-1 me-1"> Show Archived </a>
						<router-link to="/staff/add" class="waves-effect waves-light btn btn-info mb-1" ><i class="fa fa-plus fs-14"></i> Staff Member</router-link>
					</div>
				</div>
			</div>
			<section class="content">
				<div class="box">
					<div class="box-body">
						<div class="table-responsive">
							<center>
								<span class="isvalid-feedback" style="display:block;">{{
									success
								}}</span>
							</center>
							<data-table dataType="staff">
								<template v-slot="slotProps">
									<td>{{ slotProps.item.username }}</td>
									<td>{{ slotProps.item.rolename }}</td>
									<td>
										{{ slotProps.item.first_name }}
										{{ slotProps.item.last_name }}
									</td>
									<td>{{ slotProps.item.email }}</td>
									<td>{{ slotProps.item.provider_name }}</td>
									<td>{{ slotProps.item.cell_phone }}</td>
									<td>{{ slotProps.item.home_phone }}</td>
									<td>
										<span v-if="slotProps.item.emergency_access== 1"> Yes</span>
										<span v-if="slotProps.item.emergency_access== 0"> No</span>
									</td>
									<td>
										<div class="d-flex">
											<Popper arrow content="Edit Staff" :hover="true" class="helptips cursor-pointer">
												<a href="#"
												@click="edit(slotProps.item.id)"
												class="waves-effect waves-circle btn btn-circle btn-info btn-xs"><i class="fa fa-pencil"></i></a>
											</Popper>
											<Popper arrow content="Delete Staff" :hover="true" class="helptips cursor-pointer">
												<a href="#" @click="remove(slotProps.item.id)"
												class="waves-effect waves-circle btn btn-circle btn-danger btn-xs"
												><i class="fa fa-trash"></i></a>
											</Popper>
											<Popper arrow content="Resend Email Verification Link" :hover="true" class="helptips cursor-pointer">
												<a v-if="slotProps.item.is_email_verified == 0"
												@click="resendVerificationLink(slotProps.item.user_id)"
												href="#" class="waves-effect waves-circle btn btn-circle btn-info btn-xs"><i class="fa fa-paper-plane"></i></a>
											</Popper>
											<Popper arrow content="Drfirst account Setup" :hover="true" class="helptips cursor-pointer">
												<a @click="drfirstAccountSetup(slotProps.item.id)" href="#" class="waves-effect waves-circle btn btn-circle btn-info btn-xs"><i class="fa fa-user"></i></a>
											</Popper>
											<Popper arrow content="Share User Specific Appointment Link" v-if="slotProps.item.can_take_appt" :hover="true" class="helptips cursor-pointer">
												<a href="javascript:void(0)" @click="shareUserLink(slotProps.item.id,slotProps.item.clinic_id)" class="waves-effect waves-circle btn btn-circle btn-info btn-xs me-1"><i class="fa fa-share"></i></a>
											</Popper>
										</div>
									</td>
								</template>
							</data-table>
						</div>
					</div>
				</div>
			</section>
		</div>
	</div>
	<div id="drfirstAccountSetupModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
		<div class="modal-dialog modal-lg">
			<div class="modal-content">
				<div class="modal-header">
					<div>
						<h2 class="modal-title mt-0" id="myModalLabel">Drfirst Account Setup</h2>
					</div>
					<button type="button" class="btn-close btn-xs" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">
					<input-field title="drfirst username" id="drfirst_username" v-model="v$.form.drfirst_username.$model" :errors="v$.form.drfirst_username.$errors"
					:isRequired="true" divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4" labelClass="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end"></input-field>

					<input-field title="drfirst External ID" id="drfirst_external_id" v-model="v$.form.drfirst_external_id.$model" :errors="v$.form.drfirst_external_id.$errors" :isRequired="true" divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4" labelClass="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end"></input-field>

					<input type="hidden" v-model="staff_id">

					<div class="form-group row align-items-center">
						<label class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end">Account Enable:</label>
						<div class="col-12 col-sm-6 col-md-8 col-lg-8 col-xl-8">
							<div class="row">
								<div class="col-6 col-sm-6 col-md-3">
									<input type="radio" id="is_drfirst_enable_y" name="form.is_drfirst_enable_mode" value="y" class="filled-in chk-col-info" v-model="form.is_drfirst_enable_mode"/>
									<label for="is_drfirst_enable_y">Enabled</label>
								</div>
								<div class="col-6 col-sm-6 col-md-3">
									<input type="radio" id="is_drfirst_enable_n" name="form.is_drfirst_enable_mode" value="n" class="filled-in chk-col-info" v-model="form.is_drfirst_enable_mode"/>
									<label for="is_drfirst_enable_n">Disabled</label>
								</div>
							</div>
						</div>
					</div>

					<div class=" text-center">
						<form-btn title="Save" btnCls="btn-info" @click="saveDrfirstData()"></form-btn>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div id="userspecifichare" class="userspecifichare modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
		<div class="modal-dialog modal-lg">
			<div class="modal-content">
				<div class="modal-header">
					<h3 class="modal-title fw-900 mt-0">User Specific Online Appointment Booking URL</h3>
				</div>
				<div class="modal-body">
					<a id="officecopy" target="_blank" :href="this.share_url" class="d-block">{{this.share_url}}</a>
					<small>Copy and paste the embed url above.</small>
				</div>
				<div class="modal-footer">
					<button type="button" @click="copyUserUrl(this.share_url)" class="waves-effect waves-light btn btn-info">Click to Copy</button>
					<button type="button"  data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
				</div>
			</div>
		</div>
	</div>
	<!--document model start-->
	<div id="staffdocument" class="modal fade" tabindex="-1" role="dialog"
         aria-labelledby="myModalLabel" aria-hidden="true">
         <div class="modal-dialog modal-md" style="max-width: 600px;">
            <div class="modal-content">
               <div class="modal-header">
                  <h3 class="modal-title fw-900 mt-0">Reference of Knowledge Base</h3>
               </div>
               <div class="modal-body overflow-auto" style="max-height: 350px;">
                  <table class="table table-bordered">
                     <thead>
                        <tr>
                           <th scope="col">No.</th>
                           <th scope="col">Knowledge Base Articles</th>
                        </tr>
                     </thead>
                     <tbody>
                        <tr>
                           <td>1</td>
                           <td> <a :href="path+'how-to-create-a-staff-account-in-pureehr'" target="_blank">How To Create A Staff Account In PureEHR?</a></td>
                        </tr>
						<tr>
                           <td>2</td>
                           <td> <a :href="path+'how-to-edit-staff-member-s-account-in-pureehr'" target="_blank">How To Edit Staff Member's Account In PureEHR?</a></td>
                        </tr>
						<tr>
                           <td>3</td>
                           <td> <a :href="path+'how-to-activate-staff-member-account-in-pureehr'" target="_blank">How To Activate Staff Member Account In PureEHR?</a></td>
                        </tr>
						<tr>
                           <td>4</td>
                           <td> <a :href="path+'how-to-recover-deleted-staff-member-s-account-in-pureehr'" target="_blank">How To Recover Deleted Staff Member's Account In PureEHR? </a></td>
                        </tr>
						<tr>
                           <td>5</td>
                           <td> <a :href="path+'how-to-delete-staff-member-s-account-in-pureehr'" target="_blank">How To Delete Staff Member's Account In PureEHR?</a></td>
                        </tr>
						<tr>
                           <td>6</td>
                           <td> <a :href="path+'how-to-schedule-staff-s-timing-in-pureehr'" target="_blank">How To Schedule Staff's Timing In PureEHR?</a></td>
                        </tr>
                     </tbody>
                  </table>
				  <Search :key="searchKey"></Search>
               </div>
               <div class="modal-footer">
                  <button type="button" @click="" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
               </div>
            </div>
         </div>
	</div>
	<!--document model end-->
</template>

<script>
	import dataTable from "@/components/base/DataTable.vue";
	import axios from "@/scripts/axios.js";
	import submitMixins from "@/scripts/mixins/submitMixins.js";
	import permissionMixins from "@/scripts/mixins/permission.js";
	import Popper from "vue3-popper";
	import useVuelidate from "@vuelidate/core";
	import InputField from "@/components/base/formFields/InputField.vue";
	import FormBtn from "@/components/base/formFields/formBtn.vue";
	import Search from "@/components/Search.vue";

	export default {
		name: "Home",
		mixins: [submitMixins,permissionMixins],
		setup: () => {
			return { v$: useVuelidate() };
		},
		components: { dataTable, Popper, InputField, FormBtn, Search },
		data() {
			return {
				success: "",
				result: "",
				staff_id: "",
				share_url: "",
				path: process.env.VUE_APP_KB_DOCUMENT,
				searchKey:0,
			};
		},
		mounted() {
			if (this.$route.params.message) {
				this.success = this.$route.params.message;
			}
		},
		computed: {
			form() {
				return this.$store.state.StaffRXAccountSetup.form;
			}, 
			vuelidateExternalResults() {
				return this.$store.state.StaffRXAccountSetup.vuelidateExternalResults;
			},
			validationRules() {
				return this.$store.state.StaffRXAccountSetup.validationRules;
			},    
		},
		async beforeCreate() {
			this.$store.state.StaffRXAccountSetup.form = {};
			Object.assign(
				this.$store.state.StaffRXAccountSetup.form,
				this.$store.state.StaffRXAccountSetup.defaultFormData
				);
		},
		methods: {
			reset() {
				this.searchKey = this.searchKey + 1;
			},
			drfirstAccountSetup(id){
				this.staff_id = this.form.staff_id = id;
				var $this = this;
				axios.post("/staff/getdrfirstAccountData", {'staff_id': this.staff_id}).then(function(res) {
					if(res.data.length>0){
						$this.form.drfirst_username = res.data.data.provider_or_staff_username;
						$this.form.drfirst_external_id = res.data.data.provider_or_staff_external_id;
						$this.form.is_drfirst_enable_mode = res.data.data.is_drfirst_enable;          
					}else{
						Object.assign(
							$this.$store.state.StaffRXAccountSetup.form,
							$this.$store.state.StaffRXAccountSetup.defaultFormData
							);
					}
				});
				$("#drfirstAccountSetupModal").modal("show");
			},
			saveDrfirstData(){
				this.form.staff_id = this.staff_id;
				this.postForm("/staff/drfirstAccountDataSetup", "StaffRXAccountSetup", true);
			},
			formPostSuccess(data) {
				$("#drfirstAccountSetupModal").modal("hide");
				this.moshaToast("data saved successfully","success");
			},
			async copyUserUrl(s){
				await navigator.clipboard.writeText(s);
				this.moshaToast("URL is copied.", "info");
			},
			shareUserLink(user_id,clinic_id) {
				var queryFrm = { 'clinic_id' : clinic_id, 'user_id' : user_id , 'user_type' : 'staff'};
				const searchParams = new URLSearchParams();
				Object.keys(queryFrm).forEach(key => searchParams.append(key, queryFrm[key]));
				var user_appt_string = window.btoa(searchParams.toString());
				this.share_url = window.location.origin+"/#/online-appt/"+user_appt_string;
	  //this.office_name = name;
	  $('#userspecifichare').modal('show');
	},
	onToggleChange(id,role_id,email,event) {
		var class_list = document.getElementById("status_"+id).classList;
		var active_deactive = '';
		if(class_list.contains('active')) {
			active_deactive = 'A';
		} else {
			active_deactive = 'D';
		// console.log(class_list.contains('active'));
	}

	axios
	.post("staff/update",{"status":active_deactive,"id":id,"role_id":role_id,"email":email})
	.then((response) => {
		this.moshaToast('Status updated successfully.','success');
	});
},
remove(id) {
	this.$swal
	.fire({
		title: '<h2 class="mt-0">Are you sure?</h2>',
		text:
		"You want to delete these records? This process cannot be undone.",
		icon: "warning",
		dangerMode: true,
		showCancelButton: true,
		confirmButtonColor: "#ee3158",
		cancelButtonColor: "#bdbdbd",
		confirmButtonText: "Yes, delete it!",
	})
	.then((result) => {
		if (result.isConfirmed == true) {
			axios.post("/staff/delete", { id: id }).then((response) => {
				this.$swal
				.fire(
					"Deleted!",
					"Your Staff Profiles has been deleted.",
					"success"
					)
				.then((result) => {
					if (result.isConfirmed == true) {
						location.reload();
					}
				});
			});
		}
	});
},
edit(id) {
	this.$router.push({ name: "Staffedit", params: { id: id } });
},
resendVerificationLink(id) {
	this.$store.state.loader = true;
	axios
	.post(`resend-email-verification`, { email_user_id: id })
	.then((response) => {
		this.$store.state.loader = false;
		if (response.data.data.result == true) {
			this.$swal
			.fire(
				"Success!",
				"Email verification link has been send.",
				"success"
				)
			.then((result) => {
				if (result.isConfirmed == true) {
					//location.reload();
				}
			});
			console.log(response.data.data.result);
		} else {
			this.$swal
			.fire(
				"Error!",
				"Oops,something went wrong, please try again.",
				"danger"
				)
			.then((result) => {
				if (result.isConfirmed == true) {
				}
			});
		}
	});
},
},
validations() {
	return this.$store.state.StaffRXAccountSetup.validationRules;
},
};
</script>
