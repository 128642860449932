<template>
    <div class="content-wrapper">
        <div class="container-full">
            <div class="content-header">
                <h1 class="heading1 mt-0">Remittance Reports <a href="#" data-bs-toggle="modal" data-bs-target="#remittancedocument" title="Reference of Knowledge Base" v-on:click.prevent="reset()"> <i class="fa fa-question-circle fs-20"></i></a></h1>
            </div>
            <div class="box">
                <div class="box-body">
                    <div class="row">                    
                        <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2">
                            <div class="controls">
                                <select name="select" v-model="filter.remit_type" required="" id="Status" class="form-select form-control">
                                    <option value="">ERA,EOB &amp; Patient Payment</option>
                                    <option value="ERA">ERA</option>
                                    <option value="EOB">EOB</option>
                                    <option value="Patient Payment">Patient Payment</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2">
                            <div class="form-group">
                                <div class="selectcontrols">
                                    <Autocomplete :max="30" @input="gettrnnumber" ref="trnautocomplete" :results="trn_list" @onSelect="select_trn" class="form-control ms-0 autosearch-list" placeholder="Trace #"></Autocomplete>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2">
                            <div class="controls">
                                <select name="select" v-model="filter.date_for" required="" id="Status" class="form-select form-control">
                                    <option value="received">Posted Date</option>
                                    <option value="check">Check Date</option>
                                    <option value="deposit">EOB Deposit Date</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                            <div class="form-group">
                                <div class="selectcontrols">
                                    <Autocomplete :max="30" @input="getinslist" ref="autocomplete" :results="insurance_list" @onSelect="resultsvalue" :use-html-for-results ="true" class="form-control ms-0 autosearch-list" placeholder="Insurance Company"></Autocomplete>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2">
                            <div class="form-group">
                                <div class="selectcontrols">
                                    <Autocomplete :max="30" @input="getpatient" ref="patientautocomplete" :results="patient_list" @onSelect="select_patient" class="form-control ms-0 autosearch-list" placeholder="Patient"></Autocomplete>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                            <div class="form-group">
                                <div class="availdate">
                                    <ejs-daterangepicker format='MM-dd-yyyy' id="date_range" :value="date" 
                                    v-model="filter.date_range" strictMode='strict'
                                    placeholder="Select Date Range"  @change="onRangeSelect"></ejs-daterangepicker>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-2 col-md-2 col-lg-3 col-xl-4">
                            <a @click="filter_list" class="waves-effect waves-light btn btn-info-light mt-xs-5 me-1">Search <i class="fa fa-search fs-14" aria-hidden="true"></i> </a>

                            <a @click="export_list" class="waves-effect waves-light btn btn-info-light mt-xs-5">Export <i class="fa fa-download fs-14" aria-hidden="true"></i></a>
                            <div v-if="csv_export_message!=null">
                                <div class="text-danger">{{csv_export_message}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="box">
                <div class="box-body">
                    <div class="table-responsive">
                        <table class="table table-bordered fs-16">
                            <thead>
                                <tr class="text-center" rowspan="2">
                                    <th>Check Date</th>
                                    <th>Posted Date</th>
                                    <th>Deposit Date</th>
                                    <th>Check/Trace #</th>
                                    <th>Insurance Co.</th>
                                    <th># of Claims</th>
                                    <th>Claimed Amount</th>
                                    <th>Insurance Paid</th>
                                    <th>Adjusted</th>
                                    <th colspan="4">Patient Resp</th>
                                    <th>Payment Method</th>
                                    <th style="width:10%">Action</th>
                                </tr>
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th>Copay</th>
                                    <th>CoInsurance</th>
                                    <th>Deductible</th>
                                    <th>Other</th>
                                    <th></th>
                                    <th></th>                                
                                </tr>
                            </thead>
                            <slot v-if="list.length>0">
                                <tbody>
                                    <tr v-for="(item, index) in list.slice(this.start,this.end)" :key="index" class="text-center">
                                        <td>{{ formatDate(item.remit_check_date,"MM/DD/YYYY") }}</td>
                                        <td>{{ formatDate(item.remit_received_date,"MM/DD/YYYY") }}</td>
                                        <td>{{ formatDate(item.remit_deposit_date,"MM/DD/YYYY") }}</td>
                                        <td>
                                            <router-link :to="{ name: 'ERADetailReport', params: { id: item.remit_trace_number }}" class="fw-500">
                                                <slot v-if="item.is_pt_payment==1">
                                                    [Patient Payment]{{item.pt_check_number}}
                                                </slot>
                                                <slot v-else>
                                                    [{{item.remit_type}}]{{item.remit_trace_number}}
                                                </slot>                                            
                                            </router-link>
                                        </td>
                                        <td>
                                            <slot v-if="item.remit_payer_name!=null">
                                                {{item.remit_payer_name}}
                                            </slot>
                                            <slot v-else>
                                                -
                                            </slot>
                                        </td>
                                        <td>{{item.no_claims}}</td>
                                        <td>{{ $filters.toUSD(item.total_amount) }}</td>
                                        <td>{{ $filters.toUSD(item.total_amount_paid) }}</td>
                                        <td>{{ $filters.toUSD(item.adjustment_amount) }}</td>
                                        <td>
                                            <span class="waves-effect waves-light badge badge-danger-light mb-1 me-1 pull-left" style="font-weight: 500;" title="Pending Amount">Pending: {{ $filters.toUSD(item.copay_amt-item.paid_copay) }}</span>
                                            <span class="waves-effect waves-light badge badge-success-light mb-1 me-1 pull-left" style="font-weight: 500;" title="Pending Amount">Paid: {{ $filters.toUSD(item.paid_copay) }}</span>
                                        </td>
                                        <td>
                                            <span class="waves-effect waves-light badge badge-danger-light mb-1 me-1 pull-left" style="font-weight: 500;" title="Pending Amount">Pending: {{ $filters.toUSD(item.coinsurance_amt-item.paid_coinsurance) }}</span>
                                            <span class="waves-effect waves-light badge badge-success-light mb-1 me-1 pull-left" style="font-weight: 500;" title="Pending Amount">Paid: {{ $filters.toUSD(item.paid_coinsurance) }}</span>
                                        </td>
                                        <td>
                                            <span class="waves-effect waves-light badge badge-danger-light mb-1 me-1 pull-left" style="font-weight: 500;" title="Pending Amount">Pending: {{ $filters.toUSD(item.deductible_amt-item.paid_deductible) }}</span>
                                            <span class="waves-effect waves-light badge badge-success-light mb-1 me-1 pull-left" style="font-weight: 500;" title="Pending Amount">Paid: {{ $filters.toUSD(item.paid_deductible) }}</span>
                                        </td>
                                        <td>
                                            <span class="waves-effect waves-light badge badge-danger-light mb-1 me-1 pull-left" style="font-weight: 500;" title="Pending Amount">Pending: {{ $filters.toUSD(item.other_amt-item.paid_other) }}</span>
                                            <span class="waves-effect waves-light badge badge-success-light mb-1 me-1 pull-left" style="font-weight: 500;" title="Pending Amount">Paid: {{ $filters.toUSD(item.paid_other) }}</span>
                                        </td>
                                        <td>{{item.remit_payment_type}}</td>
                                        <td>
                                            <Popper arrow content="Click Here to Edit deposit date" :hover="true" class="helptips cursor-pointer">  <a
                                                href="javascript:void(0);" class="waves-effect waves-light btn btn-info-light btn-xs me-1"
                                                v-if="item.remit_deposit_date == null"
                                                @click="addDepositDate(item.remit_trace_number)"><i class="fa fa-pencil"></i></a></Popper> 

                                            
                                            <!-- EOB btn -->
                                            <span v-if="item.scanned_EOB != null" >
                                            <Popper arrow content="Click Here to view EOB file" :hover="true" class="helptips cursor-pointer">
                                            <a href="javascript:void(0)"  class="waves-effect waves-light btn btn-info-light btn-xs me-1" @click="view_EOB(item.scanned_EOB,item.clinic_id)" ><i class="fa fa-eye"></i></a>
                                            </Popper>
                                            <Popper arrow content="Click Here to Update EOB file" :hover="true" class="helptips cursor-pointer">
                                            <a href="javascript:void(0)"  class="waves-effect waves-light btn btn-info btn-xs me-1" @click="upload_EOB(item.remit_trace_number)" ><i class="fa fa-upload"></i> </a>
                                            </Popper>
                                            </span>
                                            <span v-if="item.scanned_EOB == null">
                                                <Popper arrow content="Click Here to Attach EOB file" :hover="true" class="helptips cursor-pointer">
                                                <a href="javascript:void(0)"  class="waves-effect waves-light btn btn-info btn-xs me-1" @click="upload_EOB(item.remit_trace_number)" ><i class="fa fa-upload"></i> </a>
                                                </Popper>
                                            </span>
                                            <!-- EOB btn end -->
                                            <Popper arrow content="Add/Edit Patient Payment" :hover="true" class="helptips cursor-pointer">
                                                <a href="javascript:void(0)"  class="waves-effect waves-light btn btn-info btn-xs me-1" @click="uploadPatientPayment(item)" ><i class="fa fa-credit-card-alt" aria-hidden="true"></i></a>
                                            </Popper>
                                        </td>
                                    </tr>
                                </tbody>
                            </slot>
                            <tbody v-if="list.length==0">
                                <tr>
                                    <td colspan="15" class="text-center"> No data available</td>
                                </tr>
                            </tbody>
                        </table>
                            <label>Showing {{this.start}} to {{(this.end >= total_list )? total_list : end}} of {{total_list}} entries</label>
                            <a v-if="end < total_list" href="javascript:void(0)" class="btn btn-light float-end" @click="next(this.end)">Next</a>
                            <p class="float-end">
                                <a href="javascript:void(0)" class="btn btn-light me-5" v-for="(item,index) in steps" @click="pagejump(item)" :key="index">{{item}}</a>
                            </p>
                            <a v-if="this.start != 0" href="javascript:void(0)" class="btn btn-light float-end me-5" @click="previous(this.end,this.start)">Previous</a>
            
                    </div> 
                </div>
            </div>
            <div class="modal" tabindex="-1" id="depositDateModal">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h3 class="modal-title font-bold m-0" id="claim-data-head">
                                Deposit Date
                            </h3>                        
                        </div>
                        <div class="modal-body" id="depositDateModalBody">
                            <div class="waves-effect waves-light btn btn-info-light btn-xs float-end me-5">
                                Trace #{{form.trace_number}}
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end"> Deposit Date</label>
                                <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4">
                                    <div class="availdate">
                                        <ejs-datepicker :value="deposit_date" id="deposit_date" :format="dateFormat" placeholder="Select Deposite Date" @change="getdate($event)" name="deposit_date" :strictMode='true' :showClearButton='false' :openOnFocus='true'></ejs-datepicker>
                                    </div>
                                </div>
                            </div>                        
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="waves-effect waves-light btn btn-info" @click="saveDepositDate"> Save </button>
                            <button type="button" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- start upload EOB modal-->
            <div class="modal" tabindex="-1" id="uploadEOBModal">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h3 class="modal-title font-bold m-0" id="claim-data-head">
                                Attach EOB
                            </h3>                        
                        </div>
                        <div class="modal-body" id="uploadEOBModalBody">
                            <div class="form-group row">
                                <div class="col-12">
                                    <div class="availdate">
                                        <form novalidate>
                                            <input type="hidden" name="trace_number" id="trace_number" :value="this.report_number">
                                            <file-upload id="uploadFiles" v-model="v$.form.uploadFiles.$model" :errors="v$.form.uploadFiles.$errors" :isRequired="true" title="Scanned EOB" :coustom_error="coustom_error" @change="type_validation" note="Supported formats txt,  PDF, docx." noteClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-6">
                                            </file-upload>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="waves-effect waves-light btn btn-info" @click="attachEOB()">
                                Attach EOB
                            </button>
                            <button type="button" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end upload EOB modal-->
            <!-- start Patient Payment modal -->
            <div class="modal" tabindex="-1" id="PatientPaymentModal">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h3 class="modal-title font-bold m-0" id="claim-data-head">
                                Patient Payment
                            </h3>
                            <button type="button" class="btn-close btn-xs" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body" id="PatientPaymentModalBody">
                            <div class="form-group row">                    
                                <label class="col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end">Payment Method</label>
                                <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4">
                                    <div class="controls">
                                        <select name="select" v-model="pt_form.pt_pay_type" id="pt_pay_type" class="form-select form-control">
                                            <option value="">- Select Payment Method -</option>
                                            <option value="Credit Card">Credit Card</option>
                                            <option value="Cash">Cash</option>
                                            <option value="Check">Check</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end"> Posting Date</label>
                                <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4">
                                    <div class="availdate">
                                        <ejs-datepicker id="pt_posting_date" :value="pt_posting_date" :format="dateFormat" placeholder="Select Posting Date" @change="getposting_date($event)" name="pt_posting_date" :strictMode='true' :showClearButton='false' :openOnFocus='true'></ejs-datepicker>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end"> Deposit Date</label>
                                <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4">
                                    <div class="availdate">
                                        <ejs-datepicker id="pt_deposit_date" :value="pt_deposit_date" :format="dateFormat" placeholder="Select Deposit Date" @change="getdeposit_date($event)" name="pt_deposit_date" :strictMode='true' :showClearButton='false' :openOnFocus='true'></ejs-datepicker>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end">Check Date</label>
                                <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4">
                                    <div class="availdate">
                                        <ejs-datepicker id="pt_check_date" :value="pt_check_date" :format="dateFormat" placeholder="Select Check Date" @change="getcheck_date($event)" name="pt_check_date" :strictMode='true' :showClearButton='false' :openOnFocus='true'></ejs-datepicker>
                                    </div>
                                </div>
                            </div>
                            <input-field title="Check Number" divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4" labelClass="col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end" v-model="pt_form.pt_check_number"> </input-field>
                            <input-field title="Check Amount" divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4" labelClass="col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end" v-model="pt_form.pt_check_amount"> </input-field>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="waves-effect waves-light btn btn-info" @click="savePatientPayment"> Save </button>
                        </div>
                    </div>
                </div>
            </div> 
            <!--document model start-->
			<div id="remittancedocument" class="modal fade" tabindex="-1" role="dialog"
				aria-labelledby="myModalLabel" aria-hidden="true">
				<div class="modal-dialog modal-md">
					<div class="modal-content">
					<div class="modal-header">
					<h3 class="modal-title fw-900 mt-0">Reference of Knowledge Base</h3>
					</div>
					<div class="modal-body overflow-auto" style="max-height: 350px;">
					<table class="table table-bordered">
						<thead>
							<tr>
							<th scope="col">No.</th>
							<th scope="col">Knowledge Base Articles</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>1</td>
								<td> <a :href="path+'how-to-check-remittance-in-pureehr'" target="_blank">How To Check Remittance In PureEHR?</a></td>
							</tr>
						</tbody>
					</table>
                    <Search :key="searchKey"></Search>
					</div>
					<div class="modal-footer">
					<button type="button" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
					</div>
					</div>
				</div>
			</div>
			<!--document model end-->                 
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import axios from "@/scripts/axios.js";
import permissionMixins from "@/scripts/mixins/permission.js";
import submitMixins from "@/scripts/mixins/submitMixins.js";
import selectCheckBox from "@/components/base/selectCheckBox.vue";
import Popper from "vue3-popper";
import useVuelidate from "@vuelidate/core";
import Autocomplete from 'vue3-autocomplete';
import FileUpload from "@/components/base/formFields/fileUpload.vue";
import dataTable from "@/components/base/DataTable.vue";
import InputField from "@/components/base/formFields/InputField.vue";
import Search from "@/components/Search.vue";
export default {
    mixins: [permissionMixins,submitMixins],
    components: {selectCheckBox,Popper,Autocomplete, FileUpload,dataTable, InputField, Search},
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            filter:{                
                start_date: moment().subtract(1, 'months').format("YYYY-MM-DD"),
                end_date:moment(new Date()).format("YYYY-MM-DD"),
                trace_number:null,
                date_for:'received',
                patient_id:null,
                payer_id:null,  
                remit_type:'',  
                report_number:"",            
            },
            report_number: '',
            deposit_date: '',
            date : '',
            trn_list:[],
            patient_list:[],
            insurance_list:[],
            list:[],
            dateFormat: 'MM-dd-yyyy',
            total_list:'',
            start:0,
            end:20,
            step:20,
            steps:'',
            file_name: "",
            coustom_error: "",
            pt_form:{
                pt_pay_type:"",
                pt_posting_date:null,
                pt_deposit_date:null,
                pt_check_date:null,
                pt_check_number:null,
                pt_check_amount:null,
                is_pt_payment:null,
                trace_number:null,
            },  
            pt_posting_date:null,
            pt_deposit_date:null,
            pt_check_date:null,    
            csv_export_message:null,  
            path: process.env.VUE_APP_KB_DOCUMENT,
            searchKey:0,    
        }
    },
    computed: {
        form() {
            return this.$store.state.eob.form;
        },
        vuelidateExternalResults() {
            return this.$store.state.eob.vuelidateExternalResults;
        },
        validationRules() {
            return this.$store.state.eob.validationRules;
        },
    },
    methods: {
        reset() {
            this.searchKey = this.searchKey + 1;
        },
        type_validation() {
            if (this.form.uploadFiles.type != "application/pdf") {
                this.coustom_error = "Only pdf,txt,docx formats are supported.";
            } else {
                this.coustom_error = "";
            }
        },
        pagejump(id){
            var last_id = parseInt(id * this.step);
            this.start = parseInt(last_id - this.step);
            this.end = last_id;
            $('.myCheckbox').prop('checked', false); 
            $('#checkAll').prop('checked', false); 
        },
        next(end){
            this.start = end;
            this.end = parseInt(end) + parseInt(this.step);
            $('.myCheckbox').prop('checked', false); 
            $('#checkAll').prop('checked', false); 
        },
        previous(end,start){
            this.start = parseInt(start)-parseInt(this.step);
            this.end = start;
            $('.myCheckbox').prop('checked', false); 
            $('#checkAll').prop('checked', false); 
        },
        formatDate(date, format) {
            if(date != null && date != ''){
                return moment(date).format(format);
            }else{
                return '-';
            }
        },
        saveDepositDate(){
            axios.post("/claim/deposit_date",this.form)
            .then((response) =>{
                $("#depositDateModal").modal('hide');
                this.getList();
            });
        },
        getdate($event){
            if($('#deposit_date').val() == '' && this.form.deposit_date == '' ) {
                this.form.deposit_date = null;
            } else {      
                this.form.deposit_date = moment($event.value).format('YYYY-MM-DD');
            }  
        },
        getposting_date($event){
            if($('#pt_posting_date').val() == '' && this.pt_form.pt_posting_date == '' ) {
                this.pt_form.pt_posting_date = null;
            } else {      
                this.pt_form.pt_posting_date = moment($event.value).format('YYYY-MM-DD');
            }  
        },
        getdeposit_date($event){
            if($('#pt_deposit_date').val() == '' && this.pt_form.pt_deposit_date == '' ) {
                this.pt_form.pt_deposit_date = null;
            } else {      
                this.pt_form.pt_deposit_date = moment($event.value).format('YYYY-MM-DD');
            }  
        },
        getcheck_date($event){
            if($('#pt_check_date').val() == '' && this.pt_form.pt_check_date == '' ) {
                this.pt_form.pt_check_date = null;
            } else {
                this.pt_form.pt_check_date = moment($event.value).format('YYYY-MM-DD');
            }  
        },
        addDepositDate(trace_number){
            this.form.trace_number = trace_number;
            $("#depositDateModal").modal('show');
        },
        onRangeSelect() {
            var daterange = document.getElementById("date_range").value;
            if(daterange != ''&& daterange != null){
                var date = daterange.split(' - ');
                this.filter.start_date = moment(String(date[0])).format('YYYY-MM-DD');
                this.filter.end_date = moment(String(date[1])).format('YYYY-MM-DD');
            }else{
                this.filter.start_date = null;
                this.filter.end_date = null;
            }            
        },
        gettrnnumber(obj){
            axios.get("/json_autocomplete/get-trn-number",{
                params: {term: obj}
            }).then(response => { 
                this.trn_list = response.data.data.map((code) => {
                    return { id: code.remit_trace_number, name: code.remit_trace_number }
                });
            });
        },
        select_trn(args){
            this.$refs.trnautocomplete.searchText = args.name;
            this.filter.trace_number = args.name;
        },
        getpatient(obj){
            axios.post("/patient/patient-search",{search_text:obj}).then(response => { 
                this.patient_list = response.data.data.map((code) => {
                    return { id: code.id, name: code.name }
                });
            });
        },
        select_patient(args){
            this.$refs.patientautocomplete.searchText = args.name;
            this.filter.patient_id = args.id;
        },
        getinslist (obj) {
            axios.get("/json_autocomplete/insurance-company-list",{
                params: {term: obj}
            }).then(response => { 
                this.insurance_list = response.data.data.map((code) => {
                    return { id: code.id, name: "<b>"+code.code+"</b><br>"+code.name,code:code.code,title:code.name }
                });
            });          
        },
        resultsvalue(obj){
            this.$refs.autocomplete.searchText = obj.title;            
        },
        getList(){
            this.$store.state.loader = true;
            axios.post("reports/remittance-report-list",this.filter)
            .then((response) =>{
                this.list = response.data.data
                this.total_list = this.list.length;
                this.steps = parseInt(this.total_list / this.step);
                this.$store.state.loader = false;
            });            
        },
        filter_list(){
            this.getList();
        },
        // start upload EOB
        upload_EOB(remit_report_number) {
            this.report_number=remit_report_number;
            $("#uploadEOBModal").modal("show");
        },
        attachEOB() {
            let remit_report_number=$("#trace_number").val();

            //  this.report_number=document.getElementById("#trace_number").val;
            //  console.log(remit_report_number);
            
            if (this.coustom_error == "") {
                this.postForm(`reports/store/`+remit_report_number,"eob", true, "formdata");
            }
        
        //    
        },
        formPostSuccess(data) {
            $("#uploadEOBModal").modal("hide");
            this.$store.state.documents.form = {};
            this.$store.state.documents.form_insurance = {};
            this.v$.$reset();
            this. getList();
            this.moshaToast(
                `Record ${"added"} successfully`,
                "success"
            );
        },
        view_EOB(doc_name,c_id){
            axios.post("/view-document",{doc_name:doc_name,folder_name:'EOB',clinicId:c_id})
                    .then((response) => {
            console.warn(response.data);
                        window.open(response.data, '_blank').focus();
                    });
        },        
        // end upload EOB
        uploadPatientPayment(item){
            this.pt_form.trace_number = item.remit_trace_number;
            this.pt_form.pt_pay_type = (item.pt_pay_type == null) ? "" : item.pt_pay_type;
            this.pt_posting_date = moment(item.pt_posting_date).format("MM-DD-YYYY");
            this.pt_deposit_date = moment(item.pt_deposit_date).format("MM-DD-YYYY");
            this.pt_check_date = moment(item.pt_check_date).format("MM-DD-YYYY");
            this.pt_form.pt_check_number = item.pt_check_number;
            this.pt_form.pt_check_amount = item.pt_check_amount;
            $("#PatientPaymentModal").modal("show");
        },
        savePatientPayment(){
            axios.post("/claim/patient_payment",this.pt_form)
            .then((response) =>{
                $("#PatientPaymentModal").modal('hide');
                this.moshaToast("Patient Payment added successfully.", "success");
                this.getList();
            });
        },
        export_list(){
            var data = {dataarray : []}
            this.list.forEach((element) => {					
                data.dataarray.push(element.remit_trace_number);
            });
            this.$store.state.loader = true;
            axios.post("/claim/exportRemitReport",data)
            .then((response) => {
                this.csv_export_message = "Please find your file in Download Logs Section.";
                this.$store.state.loader = false;
                setTimeout(() => {
                    this.csv_export_message = null;
                }, 8000);
            });
        },
    },
    mounted() {
        this.date = moment().subtract(1, 'months').format("MM-DD-YYYY")+' - '+moment(new Date()).format("MM-DD-YYYY"); 
        this.getList();
    },
    async beforeCreate() {
        this.$store.state.eob.form = {};
        Object.assign(
            this.$store.state.eob.form,
            this.$store.state.eob.defaultFormData
        );
    },
    validations() {
      return this.$store.state.eob.validationRules;
    }   
}
</script>