<template>
	<div class="content-wrapper">
		<div class="container">
			<section class="content">
				<div class="content-header">
					<div class="row">
						<div class="col-12 col-sm-6 col-md-8 col-lg-8">
							<h1 class="mt-0 mb-5">{{subject}}</h1>
						</div>
						<div class="col-12 col-sm-6 col-md-4 col-lg-4 text-sm-end">
							<a href="#replyto" class="waves-effect waves-light btn btn-info me-1" v-smooth-scroll><i class="fa fa-reply fs-14"></i> Reply</a>
							<button type="button" @click="CloseTicket" class="waves-effect waves-light btn btn-warning"><i class="fa fa-archive fs-14 me-1"></i>Close Ticket</button>
						</div>
					</div>
				</div>

				<div class="mb-30">
					<!-- class="fqbvdm" -->
					<div class="box">
						<div class="box-body">
							<div id="replyto" class="form-group">
								<ejs-richtexteditor
									:pasteCleanupSettings="pasteCleanupSettings"
									:toolbarSettings="toolbarSettings"
									:quickToolbarSettings="quickToolbarSettings"
									:insertImageSettings="insertImageSettings"
									:imageUploading="imageUploading"
									:imageRemoving="imageRemoving"
									:afterImageDelete="afterImageDelete"
									:width="width"
									:height="height"
									ref="rteRef" 
									title="Message"
									
									divClass="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
									labelClass="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
									rows="5">
								</ejs-richtexteditor>
							</div>
							<div class="form-group">
								<file-upload-multiple 
									title="Attach File"
									v-on:change="onImageChange"
									v-model="attach_file"
									divClass="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
									labelClass="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
								</file-upload-multiple>
							</div>
							<div class="text-end">
								<span @click="Send" class="waves-effect waves-light btn btn-info me-1" v-if="!loading">Send</span>
								<button type="button" title="Send" id="sent-btn" class="waves-effect waves-light btn btn-info-light" v-if="loading">
									<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
									Sending ...
								</button>
								<a href="/#/support" class="waves-effect waves-light btn btn-danger">Cancel</a>
							</div>
						</div>
					</div>
			<div class="box box-solid" v-for='thread in threads' >
				<div v-bind:class="BindThreadClass(thread.user_id,user.user_id)" class="box-header bg-light">
					<h3 class="box-title font-bold">{{thread.name}}</h3>
					<span class="float-end fs-16">{{ formatDate(thread.created_at, "MMM D, YYYY hh:mm a") }}</span>
				</div>
				<div class="box-body">
					<div v-html='thread.message'>
					</div>
					<div v-if='thread.attach_file!=null'>
						<div v-for='attach_file in thread.attach_file' class="thr_attach_file d-inline-block">
							<a :href='attach_file' target="_blank"><i class="fa fa-paperclip" aria-hidden="true"></i></a>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div v-html="content">
		</div>
	</section>
</div>
</div>
</template>
<script>
	import $ from "jquery";
	import submitMixins from "@/scripts/mixins/submitMixins.js";
	import axios from "@/scripts/axios.js";
	import FormBtn from "@/components/base/formFields/formBtn.vue";
	import FileUploadMultiple from "@/components/base/formFields/FileUploadMultiple.vue";
	import { mapGetters } from "vuex";
	import moment from "moment";

	// import { bus } from '../main'

	export default {
		props: {
			value: String,
			width: Number,
			height: Number
		},

		mixins: [submitMixins],

		components: {
			FormBtn,
			FileUploadMultiple,
		},
		computed: {
			...mapGetters("auth", ["user"]),
		},

		data(){
			return {
				master_id: this.$route.params,
				subject:"",
				message:"",
				threads:"",
				file_name:"",
				attach_file:[],
				loading: false,
				insertImageSettings:{
					path: process.env.VUE_APP_API_BASE_URL.substr(0,process.env.VUE_APP_API_BASE_URL.lastIndexOf('/'))+"/storage/attach_file/",
					saveUrl: process.env.VUE_APP_API_BASE_URL+"/support/file/store",
					display: 'inline'
				},
				toolbarSettings: {
					items: ['Bold', 'Italic', 'Underline','FontName', 'FontSize','Formats', 'Alignments', 'OrderedList', 'UnorderedList','CreateLink', 'Image','Undo', 'Redo'
				]
				},
				pasteCleanupSettings: {
					prompt: true,
					plainText: false,
					keepFormat: false,
					deniedTags: ['a'],
					deniedAttrs: ['class', 'title', 'id'],
					allowedStyleProps: ['color', 'margin', 'font-size']
				},
			};
		},

		mounted(){
			this.getThread()
		},

		methods:{
			getThread(){
				axios.post("/support/threads",this.master_id)
				.then((response) => {
					this.subject=response.data.data.subject.subject;
					this.threads= response.data.data.threads;
				})
			},
			formatDate(date, format) {
				return moment(date).format(format);
			},

			BindThreadClass: function(thread_user_id,current_user_id){
				if(thread_user_id==current_user_id){
					return "box-header bg-light";
				}else{
					return "box-header bg-info";
				}
				
			},
			closeModal(){
				$("#comment_id").removeClass("d-none");
				$("#content_row").addClass("d-none");
			},

			Send(){
				this.loading = true;

				var message = this.$refs.rteRef.ej2Instances.value;
				var master_id = this.master_id.master_id;
				if(message=="null" || message=="" || message==null ){
					message="";
				}

				var form = new FormData();
				var file_list=[];
				var attach_file = this.attach_file;
				for(var i = 0; i < attach_file.length; i++) {
					form.append('attach_file['+i+']', attach_file[i]);
				}
				
				form.append('message', message);
				form.append('master_id', master_id);
				var $this=this;
				axios.post('/support/threads/reply',form)
				.then((response) => {
					this.moshaToast("Reply Sent Successfully !!", "success");
							// setTimeout(function(){ $this.$router.push("/support") }, 3000);
							this.loading = false;
						}).catch((error) => {
							if (error.response.status == 422) {
								this.moshaToast("Please enter something in message box !!", "danger");
							}else{

								this.moshaToast("Oops, Something when wrong!.", "danger");
							}
							this.loading = false;
						});

					},

					CloseTicket(){
						var $this=this;
						var master_id = this.master_id.master_id;
						axios.post('/support/threads/close',{"master_id":master_id})
						.then((response) => {
							this.moshaToast("Tieckt Close Successfully !!", "success");
							setTimeout(function(){ $this.$router.push("/support") }, 3000);
							this.loading = false;
						}).catch((error) => {
							this.moshaToast("Oops, Something when wrong!.", "danger");
						});
					},

				// Image upload through drag & drop OR popup  
				imageUploading(args){

					var token = localStorage.getItem("authToken");

					this.file_name = Date.now()+"_"+(Math.random() + 1).toString(36).substring(7)+"."+args.fileData.type;
					args.fileData.name = this.file_name;
					args.fileData.id=this.file_name;

					args.currentRequest.setRequestHeader("Authorization", `Bearer ${token}`);

					args.currentRequest.setRequestHeader("File-Name", this.file_name);
				},

			// delete image in popup
			imageRemoving(args){
				var token = localStorage.getItem("authToken");

				var xmlhttp = new XMLHttpRequest();
				xmlhttp.open('POST', process.env.VUE_APP_API_BASE_URL+"/support/file/remove");
				xmlhttp.setRequestHeader("Authorization", `Bearer ${token}`);
				xmlhttp.setRequestHeader("File-Name", this.file_name);
				xmlhttp.send(null);
			},

			// delete image in editor
			afterImageDelete(args){
				this.file_name = args.src.substring(args.src.lastIndexOf('/') + 1);
				this.imageRemoving(null);

			},
			onImageChange(event){
				var file=  event.target.files[0];
				// console.log(file);
   				var fileName=file.name;
				var master_id = this.master_id.master_id;
				const formData = new FormData();
        		formData.append('file', file);
        		formData.append('master_id',master_id);
				// console.log(formData);
				const headers = { 'Content-Type': 'multipart/form-data'};
				axios.post('/support/file/store', formData,{headers}).then((res) => {
					console.log(res.data);
						// res.data.files; // binary representation of the file
						// res.status; // HTTP status
        		});
				
			}

		}
	};

</script>

<style type="text/css">
	.e-rte-dropdown-popup{
		width: auto !important;
	}
	.e-rte-img-dialog{
		bottom: 0px !important;
		top: unset !important;
	}
</style>
