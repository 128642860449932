<template>
	<div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-10">
		<div class="box">
			<div class="box-body">
				<div class="pageheading">
					<h2 class="font-bold mt-0">Emergency Access</h2>
				</div>
				<div class="row justify-content-sm-center">
					<div class="col-12 col-sm-12 col-md-12 col-lg-6">
						<h3 class="fw-700">Staff</h3>
						<div class="table-responsive">
							<table id="staff" class="table table-hover dataTable no-footer" aria-describedby="staff_info">
								<thead>
									<tr>
										<th>Username</th>
										<th>Name</th>
										<th>Emergency Access</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="item in stafflist">
										<td>{{item.username}}</td>
										<td>{{ item.first_name+' '+item.last_name }}</td>
										<td>
											<div class="controls">
												<input v-bind:id="'staff'+item.id" type="checkbox" class="filled-in chk-col-info" :value="item.id" name="staff" :data-xcv="item.emergency_access" :checked="item.emergency_access == 1">
												<label v-bind:for="'staff'+item.id" class="m-0 text-start fs-16"></label>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>

					<div class="col-12 col-sm-12 col-md-12 col-lg-6">
						<h3 class="fw-700">Providers</h3>
						<div class="table-responsive">
							<table id="staff" class="table table-hover dataTable no-footer" aria-describedby="staff_info">
								<thead>
									<tr>
										<th>Username</th>
										<th>Name</th>
										<th>Emergency Access</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="item in providerlist">
										<td>{{item.username}}</td>
										<td>{{item.full_name}}</td>
										<td>
											<div class="controls">
												<input v-bind:id="'provider'+item.id"  type="checkbox" class="filled-in chk-col-info" :value="item.id" name="provider" :checked="item.emergency_access == 1">
												<label v-bind:for="'provider'+item.id" class="m-0 text-start fs-16"></label>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<div class="text-center">
          			<form-btn title="Save" btnCls="btn-info mt-10" @click="save"></form-btn>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from "@/scripts/axios.js";
import submitMixins from "@/scripts/mixins/submitMixins.js";
import FormBtn from "@/components/base/formFields/formBtn.vue";
import Popper from "vue3-popper";

export default {
	mixins: [submitMixins],
	name: "roles",
	components: { FormBtn, Popper },
	data() {
		return {
			stafflist:[],
			providerlist:[],

		};
	},
	beforeCreate(){
		axios.get('/staff/list')
         .then(response => {
         	this.stafflist = response.data.data 
        });

        axios.get('/provider/list')
         .then(response => {
         	this.providerlist = response.data.data 
        });
	},
	methods: {
		save(){
			var staffarray = Array();
		    $("input:checkbox[name=staff]:checked").each(function() {
		    	staffarray.push($(this).val());
		    });
		    
		    var providerarray = Array();
		    $("input:checkbox[name=provider]:checked").each(function() {
		    	providerarray.push($(this).val());
		    });

		    axios.post('/emergency-access/update',{
		    	staff_id    :staffarray.toString(),
		    	provider_id : providerarray.toString()
		    })
	         .then(response => {
	         	this.moshaToast("Record updated successfully.", "success");
	        });
		    
		}
	},

};
</script>
