<template>
    <div class="selectcontrols">
        <Autocomplete :max="30" @input="getlist" ref="autocomplete" :results="icd10" @onSelect="resultsvalue" class="form-control ms-0 autosearch-list" 
        placeholder="Search for ICD-10 Diagnosis codes" :use-html-for-results ="true"></Autocomplete>
    </div>
</template>
<script>
import Autocomplete from 'vue3-autocomplete'
import axios from "@/scripts/axios.js";

export default {
    data () {
        return {
            icd10: [],        
        }
    },
    props: {
        modelValue:{},
        isSelected: {
            type: Boolean,
            default: true
        },        
    },
    mounted () {
        this.$refs.autocomplete.searchText = this.modelValue;
    },
    methods: {
        getlist (obj) {
            if(obj.length > 2){
                this.$refs.autocomplete.$el.classList.add('selectloader');
                axios.get("/json_autocomplete/icd-code", {
                    params: {term: obj}
                }).then(response => { 
                    //this.icd10 = response.data.data 
                    this.icd10 = response.data.data.map((snomed) => {
                      return { id: snomed.id, name: "<b>"+snomed.code+"</b><br>"+snomed.description,code:snomed.code,description:snomed.description }
                    });
                    this.$refs.autocomplete.$el.classList.remove('selectloader');
                });
            }     
            if (obj.length == 0) {
                this.icd10 == {}
            }    
        },
        resultsvalue(obj){
            this.$emit('clicked', obj)
            this.$emit('update:modelValue', obj.code)
            if(this.isSelected){
                this.$refs.autocomplete.searchText = obj.code;
            }else{
                this.$refs.autocomplete.searchText = '';
            }
            this.focusout();            
        },
        focusout(){
            const btn = this.$refs.autocomplete;
            btn.$el.querySelector('.vue3-autocomplete-container > .autosearch-list').blur();
        }
    },
    components: { Autocomplete },
}
</script>