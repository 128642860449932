<template>
  
  <form novalidate autocomplete="off">
    <div
      class="panel-group panel-group-simple panel-group-continuous mb-0"
      id="category-1"
      aria-multiselectable="true"
      role="tablist">
      <div class="panel pagestabscoll">
        <div class="panel-heading" id="question-1" role="tab">
          <a
            :class="{ 'form-panel-error': errAttribute1() }"
            class="panel-title d-block mb-0"
            aria-controls="answer-1"
            aria-expanded="true"
            data-bs-toggle="collapse"
            href="#answer-1"
            data-parent="#accordion1"
            >{{ title }} <a href="#" data-bs-toggle="modal" data-bs-target="#addproviderdocument" title="Reference of Knowledge Base" v-on:click.prevent="reset()"> <i class="fa fa-question-circle fs-20"></i></a></a>
          <p class="mb-10 fs-14" v-if="$route.name === 'ProviderAdd'">NOTE: Verification link will be sent on registered email that will be valid for 24 Hours.</p>
        </div>

        <div
          class="panel-collapse collapse show"
          id="answer-1"
          aria-labelledby="question-1"
          role="tabpanel"
          data-bs-parent="#category-1"
        >
          <div class="panel-body py-20" style="border-color:#EFEFFD;">
            <div class="form-group row">
              <label
                class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end"
                >Salutation
              </label>
              <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3">
                <div class="controls">
                  <select
                    name="salutation"
                    v-model="form.salutation"
                    class="form-select form-control form-control">
                    <option value="" disabled>---------</option>
                    <option value="Dr.">Dr.</option>
                    <option value="Prof.">Prof.</option>
                    <option value="Prac.">Prac.</option>
                    <option value="D.O.">D.O.</option>
                  </select>
                </div>
              </div>
            </div>
            <input-field
              title="First Name"
              id="first_name"
              v-model="v$.form.first_name.$model"
              :errors="v$.form.first_name.$errors"
              :isRequired="true"
            >
            </input-field>
            <input-field
              title="Middle Name"
              id="middle_name"
              v-model="form.middle_name"              
            >
            </input-field>
            <input-field
              title="Last Name"
              id="last_name"
              v-model="v$.form.last_name.$model"
              :errors="v$.form.last_name.$errors"
              :isRequired="true"
            >
            </input-field>
            <div class="form-group row" v-if="this.$store.state.auth.is_lifestyle_specific_enable==1">
               <label class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 form-label mb-0 text-sm-end">Is LifeStyle Specific</label>
               <div class="col-6 col-sm-6 col-md-8 col-lg-5 col-xl-3">
                  <div class="controls">
                     <input id="is_iv_specific" type="checkbox" class="filled-in chk-col-info" v-model="form.is_lifestyle_specific" >
                     <label for="is_iv_specific" class="m-0 text-start fs-16"></label>
                  </div>
               </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end">Date Of Birth <small class="text-danger asterisksign">*</small></label>
                <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3 availdate" :class="{ error: v$.form.date_of_birth.$errors.length }">
                  <ejs-datepicker 
                    :value="date_of_birth" 
                    id="date_of_birth" 
                    :format="dateFormat" 
                    placeholder="select date of birth" 
                    @change="getdate" 
                    @click="getdate" 
                    :strictMode='strict' 
                    :max='maxVal' 
                    :showClearButton='false' 
                    :blur="getdate"
                    :openOnFocus='true'>
                  </ejs-datepicker>
                  <div class="error-msg">
                    <ul class="mb-0 p-0 fs-14">
                      <li class="d-block" v-for="(error, index) of v$.form.date_of_birth.$errors" :key="index">
                        {{ error.$message }}
                      </li>
                    </ul>
                  </div>            
                </div>
            </div>

            <input-field
              title="Suffix"
              v-model="form.suffix"
              placeholder="e.g. Jr., Sr., I, II"
            >
            </input-field>

            <select-box
              title="Speciality"
              id="specialty_id"
              v-model="form.specialty_id"
              getDataURL="/json_dropdown/speciality"
            ></select-box>

            <select-box
              title="Job Title"
              id="job_title_id"
              v-model="v$.form.job_title_id.$model"
              :errors="v$.form.job_title_id.$errors"
              getDataURL="/json_dropdown/jobtitle"
              :isRequired="true"
            ></select-box>
            <input-field
              id="cell_phone"
              title="Cell Phone"
              v-model="v$.form.cell_phone.$model"
              :errors="v$.form.cell_phone.$errors"
              :isRequired="true"
              mask="(000) 000-0000"
            >
            </input-field>
            <input-field
              id="office_phone"
              title="Office Phone"
              v-model="v$.form.office_phone.$model"
              mask="(000) 000-0000"
            >
            </input-field>
            <input-field
              id="fax_number"
              title="Fax Number"
              v-model="v$.form.fax_number.$model"
              :errors="v$.form.fax_number.$errors"
              mask="(000) 000-0000"
            >
            </input-field>

            <input-field
              id="email"
              title="E-mail Address"
              :isRequired="true"
              v-model="v$.form.email.$model"
              :errors="v$.form.email.$errors"
            >
            </input-field>


            <div class="form-group row" :class="{ error: v$.form.country_id.$errors.length }">
                <label class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end">Country <small class="text-danger asterisksign">*</small></label>
              <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3">
                <Select2
                  v-model="v$.form.country_id.$model"
                  id="country_id"
                  placeholder="Select Country"
                  :options="countries"
                  @select="countryChange($event)"
                />
                <div
                  class="invalid-feedback"
                  style="display:block;"
                  v-for="(error, index) of v$.form.country_id.$errors"
                  :key="index"
                >
                  {{ error.$message }}
                </div>
              </div>
            </div>
            <input-field title="Zipcode" v-model="form.zip_code" @focusout="zipcode"> </input-field>
            <div class="form-group row" :class="{ error: v$.form.state_id.$errors.length }">
              <label class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end">State <small class="text-danger asterisksign"> *</small></label>
              <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3">
                <Select2
                  v-model="form.state_id"
                  id="state_id"
                  placeholder="Select State"
                  :options="states"
                />
                <div
                  class="invalid-feedback"
                  style="display:block;"
                  v-for="(error, index) of v$.form.state_id.$errors"
                  :key="index"
                >
                  {{ error.$message }}
                </div>
              </div>
            </div>

            <input-field
              title="City"
              v-model="form.city"
              placeholder="City"
            />

            <text-area title="Address" v-model="form.address"> </text-area>
            <input-field
              title="Website"
              placeholder="www.example.com"
              v-model="v$.form.website.$model"
              :errors="v$.form.website.$errors"
            >
            </input-field>

        

            <input-field
              id="username"
              title="Username"
              :isRequired="true"
              v-model="v$.form.username.$model"
              :errors="v$.form.username.$errors"
            >
            </input-field>
            <Password
              id="password"
              title="Password"
              :isRequired="true"
              v-model="v$.form.password.$model"
              :errors="v$.form.password.$errors"
              :placeholder="getPassPlaceholder"
              v-on:keyup="usercheck"
              :showPassword = "true"
              :suggestion = "true"
            >
            </Password>

            <Password
              id="password_confirmation"
              title="Confirm Password"
              :isRequired="true"
              v-model="v$.form.password_confirmation.$model"
              :errors="v$.form.password_confirmation.$errors"
              :placeholder="getPassPlaceholder"
              @focusout="passcheck"
            >
            </Password>
            <multi-select-box
              id="office_access"
              title="Office Access"
              :isRequired="true"
              v-model="v$.form.office_access.$model"
              :errors="v$.form.office_access.$errors"
              getDataURL="json_dropdown/office"
            ></multi-select-box> 
            <div class="form-group row" v-if="this.$route.name === 'ProviderEdit'">
              <label class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xs form-label text-sm-end"></label>
              <label class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xs form-label">
                <a href="#" data-bs-toggle="modal" data-bs-target="#defaultRoomModal" @click="this.defaultRoomModalShow()">Set Default Room</a>
              </label>
            </div> 
            
            <check-box
              title="Get Notification for Appointment Confirmation, cancellation, Rescheduling"
              v-model="form.can_get_notification"
              labelClass="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 form-label mb-0 text-sm-end"
              divClass="col-6 col-sm-6 col-md-8 col-lg-5 col-xl-3"
            ></check-box>

            <select-box-static 
              v-if="form.can_get_notification == '1'"
              title="Mode of Notification" 
              divClass="col-6 col-sm-6 col-md-8 col-lg-5 col-xl-3"
              labelClass="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 form-label mb-0 text-sm-end"
              v-model="form.mode_of_notification" 
              :option="allModeNotification">
            </select-box-static>
            <multi-select-box
              id="staff_assign"
              title="Assign staff members to the provider"
              :getDataURL="getStaffURL"
              v-model="v$.form.staff_assign.$model"
            ></multi-select-box> 
          </div>
        </div>
      </div>
      <div class="panel pagestabscoll">
        <div class="panel-heading" id="question-2" role="tab">
          <a
            class="panel-title d-block mb-0"
            aria-controls="answer-2"
            aria-expanded="false"
            data-bs-toggle="collapse"
            href="#answer-2"
            data-parent="#accordion1"
            >Profile Images</a
          >
        </div>
        <div
          class="panel-collapse collapse"
          id="answer-2"
          aria-labelledby="question-2"
          role="tabpanel"
          data-bs-parent="#category-1"
        >
          <div class="panel-body py-20" style="border-color:#EFEFFD;">

            <div class="form-group row" v-if="this.form.business_logo_img">
              <label
                class="col-12 col-sm-6 col-md-4 col-lg-3 col-form-label mb-0 text-sm-end"
                >Current Practice Business Logo:</label
              >
              <div class="col-12 col-sm-6 col-md-8 col-lg-3">
                <div class="profilepic">
                  <img
                    :src="form.business_logo_img"
                    alt="Patient photo"
                    class="img-fluid"
                  />
                </div>
              </div>
            </div>

            <file-upload
              id="business_logo"
              title="Practice Business Logo"
              v-model="v$.form.business_logo.$model"
              :errors="vuelidateExternalResults.form.business_logo"
              @change="fileValidation($event, 'business_logo')"
              noteClass="col-12 col-sm-8 col-md-8 offset-sm-4 offset-lg-0 col-lg-4 col-xl-5"
              note="Only .jpg, .png formats are supported"
            ></file-upload>
            
            <div class="form-group row" v-if="this.form.profile_avatar_img">
              <label
                class="col-12 col-sm-6 col-md-4 col-lg-3 col-form-label mb-0 text-sm-end"
                >Current Profile Picture:</label
              >
              <div class="col-12 col-sm-6 col-md-8 col-lg-3">
                <div class="profilepic">
                  <img
                    :src="form.profile_avatar_img"
                    alt="Patient photo"
                    class="img-fluid"
                  />
                </div>
              </div>
            </div>

            <file-upload
              id="profile_avatar"
              title="Profile Picture"
              v-model="v$.form.profile_avatar.$model"
              :errors="vuelidateExternalResults.form.profile_avatar"
              @change="fileValidation($event, 'profile_avatar')"
              noteClass="col-12 col-sm-8 col-md-8 offset-sm-4 offset-lg-0 col-lg-4 col-xl-5"
              note="Only .jpg, .png formats are supported"
            ></file-upload>

            
            <text-area
              title="Signature"
              v-model="form.email_signature"
              notes="Note:- This signature will be used for clinical note sign"
            >
            </text-area>


            <div class="form-group row">
              <label class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xs form-label text-sm-end">Signature <small class="text-danger asterisksign" style="display: none;">*</small></label>
              <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3">
                <div class="controls">
                  <a href="javascript:void(0)" class="waves-effect waves-light btn me-1 btn-info" data-bs-toggle="modal" data-bs-target="#SignatureModal">Click Signature</a>
                  <img :src="form.digtal_signature" class="img-fluid mt-10">
                  <!-- Signature Modal Start-->
                  <div class="modal fade" id="SignatureModal" tabindex="-1" role="dialog" aria-labelledby="SignatureModal" aria-hidden="true">
                    <div class="modal-dialog modal-lg" role="document">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h3 class="modal-title fw-900 mt-0" id="exampleModalLabel">Signature</h3>
                        </div>
                        <div class="modal-body">
                          
                          <div class="box p-15">
                            <div class="mdc-tab">
                              <ul class="nav nav-tabs mt-0 text-center text-sm-start" role="tablist">
                                <li class="nav-item"> <a @click="tabchange('digtal_signature')" class="nav-link active" data-bs-toggle="tab" href="#signature_pad" role="tab">Signature Pad</a> </li>
                                <li class="nav-item"> <a @click="tabchange('free_text')" class="nav-link" data-bs-toggle="tab" href="#type" role="tab">Type</a> </li>
                                <li class="nav-item"> <a @click="tabchange('image')" class="nav-link" data-bs-toggle="tab" href="#upload" role="tab">Upload</a> </li>
                              </ul>
                            </div>

                            <div class="tab-content">
                              <div class="tab-pane active" id="signature_pad" role="tabpanel">
                                  <div class="row">
                                    <div class="col-12 col-md-12 text-center">
                                      <VueSignaturePad ref="signaturePad" style="border:2px solid rgb(204 217 223);width: 100%;height: 150px;border-radius: 3px;" :options="{onBegin: () => {$refs.signaturePad.resizeCanvas()}}" />
                                        <a href="javascript:void(0)" @click="clear" class="btn btn-danger mt-5">Clear</a>
                                    </div>
                                  </div>
                              </div>
                              <div class="tab-pane" id="type" role="tabpanel">
                                  <div class="mb-20">
                                    <input type="text" class="form-control" placeholder="Enter your Name" v-model="signature_type">
                                  </div>
                                  <div class="row" v-if="signature_type != ''">
                                  <div class="col-12 col-md-6" id="border1" @click="signature_select(1)">
                                    <div class="box text-center border-danger">
                                      <p class="l-h-n text-center py-15 mb-0" style="font-family: 'Mea Culpa';font-size: 42px;word-break: break-word;">{{signature_type}}</p>
                                    </div>
                                  </div>
                                  <div class="col-12 col-md-6" id="border2" @click="signature_select(2)">
                                    <div class="box text-center">
                                      <p class="l-h-n text-center py-15 mb-0" style="font-family: 'Parisienne';font-size: 42px;word-break: break-word;">{{signature_type}}</p>
                                    </div>
                                  </div>
                                  <div class="col-12 col-md-6" id="border3" @click="signature_select(3)">
                                    <div class="box text-center">
                                      <p class="l-h-n text-center py-15 mb-0" style="font-family: 'Tangerine';font-size: 42px;word-break: break-word;">{{signature_type}}</p>
                                    </div>
                                  </div>
                                  <div class="col-12 col-md-6" id="border4" @click="signature_select(4)">
                                    <div class="box text-center">
                                      <p class="l-h-n text-center py-15 mb-0" style="font-family: 'brush-script-std';font-size: 42px;word-break: break-word;">{{signature_type}}</p>
                                    </div>
                                  </div>
                                  <div class="col-12 col-md-6" id="border5" @click="signature_select(5)">
                                    <div class="box text-center">
                                      <p class="l-h-n text-center py-15 mb-0" style="font-family: 'Great Vibes';font-size: 42px;word-break: break-word;">{{signature_type}}</p>
                                    </div>
                                  </div>
                                  <div class="col-12 col-md-6" id="border6" @click="signature_select(6)">
                                    <div class="box text-center">
                                      <p class="l-h-n text-center py-15 mb-0" style="font-family: 'Comforter';font-size: 42px;word-break: break-word;">{{signature_type}}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="tab-pane" id="upload" role="tabpanel">
                                <input type="file" class="form-control" id="myfile" @change="encodeImageFileAsURL">
                              </div>
                            </div>
                          </div>

                        </div>
                        <div class="modal-footer">
                          <button type="button" @click="signature_save" class="waves-effect waves-light btn btn-info">Save</button>
                          <button type="button" id="close-model-signature" class="waves-effect waves-light btn btn-danger" data-bs-dismiss="modal">Close</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Signature Modal End-->      
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
      <div class="panel pagestabscoll">
        <div class="panel-heading" id="question-3" role="tab">
          <a
            :class="{ 'form-panel-error': errAttribute3() }"
            class="panel-title d-block mb-0"
            aria-controls="answer-3"
            aria-expanded="false"
            data-bs-toggle="collapse"
            href="#answer-3"
            data-parent="#accordion1"
            >Identifiers</a
          >
        </div>
        <div
          class="panel-collapse collapse"
          id="answer-3"
          aria-labelledby="question-3"
          role="tabpanel"
          data-bs-parent="#category-1"
        >
          <div class="panel-body py-20" style="border-color:#EFEFFD;">
            <input-field
              id="dea_number"
              title="DEA No"
              :isRequired="true"
              v-model="v$.form.dea_number.$model"
              :errors="v$.form.dea_number.$errors"
              maxlength="9"
            >
            </input-field>
            <input-field
              id="state_license_number"
              title="License No"
              :isRequired="true"
              v-model="v$.form.state_license_number.$model"
              :errors="v$.form.state_license_number.$errors"
              maxlength="10"
            >
            </input-field>
            <!-- <input-field
              id="tax_number"
              title="Tax No"
              :isRequired="true"
              v-model="v$.form.tax_number.$model"
              :errors="v$.form.tax_number.$errors"
            >
            </input-field> -->
            <input-field
              id="provider_npi"
              title="NPI No"
              :isRequired="true"
              v-model="v$.form.provider_npi.$model"
              :errors="v$.form.provider_npi.$errors"
            >
            </input-field>
            <check-box divClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-3" labelClass="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label text-sm-end" title="Disable rendering provider npi in claim" v-model="form.disable_claim_npi" notes="If you check this box, Rendering provider npi will not be used in insurance claim."></check-box>
            <input-field
              id="taxonomy_number"
              title="Taxonomy Number"
              v-model="form.taxonomy_number"
            >
            </input-field>
            <!-- <input-field
              id="ssn_number"
              title="SSN No"
              v-model="v$.form.ssn_number.$model"
              :errors="v$.form.ssn_number.$errors"
            >
            </input-field> -->
            <input-field
              id="phi_mail_address"
              title="PHI email ID"
              v-model="form.phi_mail_address"
              toolTip="This email is required if you want to receive the Consolidated Clinical Document (CCDA) of a patient from another EHR."
            >
            </input-field>            
            <Password
              id="phi_mail_password"
              title="PHI email password"
              :isRequired="false"
              v-model="form.phi_mail_password"
              placeholder="PHI email password"
            >
            </Password>
            <div class="form-group row align-items-center">
                <label class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end">Provider Type</label>
                <div class="col-12 col-sm-6 col-md-8 col-lg-6 col-xl-4">
                  <div class="row">
                    <div class="col-6 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                        <input type="radio" id="provider0" name="provider_type" value="0" @change="check_provider_type" class="filled-in chk-col-info" v-model="form.provider_type"/>
                        <label for="provider0">Rendering</label>
                    </div>
                    <div class="col-6 col-sm-4 col-md-4 col-lg-4 col-xl-4">                              
                        <input type="radio" v-model="form.provider_type" id="provider2" name="provider_type" value="2" @change="check_provider_type" class="filled-in chk-col-info"/>
                          <label for="provider2">Managing</label>
                    </div>
                    <div class="col-6 col-sm-4 col-md-4 col-lg-4 col-xl-4">                              
                        <input type="radio" v-model="form.provider_type" id="provider1" name="provider_type" value="1" @change="check_provider_type" class="filled-in chk-col-info"/>
                          <label for="provider1">Supervising</label>
                    </div>
                  </div>
                </div>
            </div>
            <slot v-if="form.provider_type == 2">
              <multi-select-box
                :isRequired="additional_provider_val"
                title="Additional Provider"
                :errors="v$.form.additional_provider_id.$errors"
                v-model="v$.form.additional_provider_id.$model"
                getDataURL="/json_dropdown/provider"
              ></multi-select-box>
            </slot>

            <div class="form-group row">
               <label class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 form-label mb-0 text-sm-end">Supervisor Sign Required</label>
               <div class="col-6 col-sm-6 col-md-8 col-lg-5 col-xl-3">
                  <div class="controls">
                     <input id="supervisor_sign_needed" type="checkbox" class="filled-in chk-col-info" v-model="form.supervisor_sign_needed" @click="check_Supervisor_Sign_Required()">
                     <label for="supervisor_sign_needed" class="m-0 text-start fs-16"></label>
                  </div>
               </div>
            </div>
            <check-box
              title="Is Appointment Overlapping"
              v-model="form.is_appointment_overlapping"
              labelClass="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 form-label mb-0 text-sm-end"
              divClass="col-6 col-sm-6 col-md-8 col-lg-5 col-xl-3"
            ></check-box>
          </div>
        </div>
      </div>
    </div>
  </form>
  <div class="row">
    <div class="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-9 text-center">
      <form-btn title="Save" btnCls="btn-info mb-1" @click="save(false)"></form-btn>
      <router-link
        v-if="permissioncheck(this.$store.state.permission.Settings)"
        :to="{ name: 'ProviderList' }"
        class="waves-effect waves-light btn me-1 mb-1 btn-danger"
        >Cancel</router-link
      >
      <router-link
        v-else
        :to="{ name: 'ProfileSetting' }"
        class="waves-effect waves-light btn me-1 mb-1 btn-danger"
        >Cancel</router-link
      >
      <form-btn
        title="Save & Next"
        btnCls="btn-info mb-1"
        @click="save(true)"
      ></form-btn>
    </div>
  </div>
    <!--document model start-->
    <div id="addproviderdocument" class="modal fade" tabindex="-1" role="dialog"
        aria-labelledby="myModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-md">
          <div class="modal-content">
              <div class="modal-header">
                <h3 class="modal-title fw-900 mt-0">Reference of Knowledge Base</h3>
              </div>
              <div class="modal-body overflow-auto" style="max-height: 350px;">
                <table class="table table-bordered">
                    <thead>
                      <tr>
                          <th scope="col">No.</th>
                          <th scope="col">Knowledge Base Articles</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                          <td>1</td>
                          <td> <a :href="path+'how-to-create-a-provider-account'" target="_blank">How To Create A Provider Account?</a></td>
                      </tr>
                    </tbody>
                </table>
                <Search :key="searchKey"></Search>
              </div>
              <div class="modal-footer">
                <button type="button" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
              </div>
          </div>
        </div>
    </div>
    <!--document model end-->
  <!-- {{ form }}<br />ddddd<br />
  {{ v$.form.$errors }} -->
  <!-- {{ form }}<br /><br />
  {{ $store.state.provider.form }} -->
<div id="defaultRoomModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
          <div class="modal-header">
            <h3 class="modal-title fw-900 mt-0">Office Room</h3>
          </div>
          <div class="modal-body">
            <table id="roomTable" class="table table-hover">
              <thead>
                <tr>
                  <th width="20%">Office Name</th>
                  <th width="80%">Rooms</th>
                </tr>
              </thead>
              <tbody>
                <slot v-if ="this.rooms.length != 0">
                  <tr v-for="(roomDetail, index) in this.rooms" :key="index">
                    <td>{{roomDetail.office_name}}</td>
                    <td >
                      <div v-for="(item, index1) in roomDetail.room" class="form-check form-check-inline" :key="index1">
                          <input type="radio"  :id="`office_${roomDetail.office_id}_${item.room_id}`" :name="`office_${roomDetail.office_id}`" :value="`${item.room_id}`" class="filled-in chk-col-info officeDefaultRoom" :data-office-id="`${roomDetail.office_id}`" :checked="item.is_checked == true"/>
                          <label :for="`office_${roomDetail.office_id}_${item.room_id}`">{{item.room_name}}</label>
                      </div>
                    </td>
                  </tr>
                </slot>
                <slot v-else>
                  <tr>
                    <td colspan="2" class="text-center">No data available</td>
                  </tr>
                </slot>
              </tbody>
            </table>
          </div>
          <div class="modal-footer">
            <button type="button" data-bs-dismiss="modal" @click="this.saveDefaultRoom()" class="waves-effect waves-light btn btn-info">Save</button>
              <button type="button" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
          </div>
      </div>
    </div>
</div>
</template>

<style>
#defaultRoomModal .modal-body {    
    overflow-y: auto;
    /* height: calc(80vh - 5em); */
    max-height: calc(80vh - 5em);
}
</style>

<script>
import InputField from "@/components/base/formFields/InputField.vue";
import CheckBox from "@/components/base/formFields/checkBox.vue";
import SelectBox from "@/components/base/formFields/selectBox.vue";
import StateSelectBox from "@/components/base/formFields/stateSelectBox.vue";
import MultiSelectBox from "@/components/base/formFields/multiSelectBox.vue";
import FileUpload from "@/components/base/formFields/fileUpload.vue";
import TextArea from "@/components/base/formFields/textArea.vue";
import FormBtn from "@/components/base/formFields/formBtn.vue";
import DatePicker from "@/components/base/formFields/datePicker.vue";
import Select2 from "@/components/base/formFields/select2.vue";
import useVuelidate from "@vuelidate/core";
import submitMixins from "@/scripts/mixins/submitMixins.js";
import axios from "@/scripts/axios.js";
import Password from "@/components/base/formFields/password.vue";
import moment from "moment";
import permissionMixins from "@/scripts/mixins/permission.js";
import $ from "jquery";
import SelectBoxStatic from "@/components/base/formFields/SelectBoxStatic.vue";
import {
  helpers,
  required,
  email,
  minLength,
  maxLength,
  sameAs,
} from "@vuelidate/validators";
import Search from "@/components/Search.vue";

export default {
  mixins: [submitMixins, permissionMixins],
  setup: () => {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      signature:'',
      signature_type:'',
      signature_selects:'#border1',
      imagebase64:'',
      type_image:'',
      tabname :'digtal_signature',
      extraError: 0,
      errorCountNewProvider: "answer-1",
      errorCountIdentifier: "answer-3",
      current_date: "",
      addSpecialityField: false,
      speciality: [],
      saveAndNext: false,
      countries: [],
      states: [],
      value: null,
      confirmerror: false,
      usernameerror: false,
      options: ["Batman", "Robin", "Joker"],
      strict: true,
      maxVal: new Date(),
      dateFormat: 'MM-dd-yyyy',
      path: process.env.VUE_APP_KB_DOCUMENT,
      searchKey:0,
      additional_provider_val: false,
      rooms: [],
      allModeNotification:[
        { value : "sms", title : "SMS" },
        { value : "email", title : "Email" },
        { value : "both", title : "Both" },
      ],
    };
  },
  mounted() {
    var d = new Date();
    var month = d.getMonth() + 1;
    var day = d.getDate();

    this.current_date =
      d.getFullYear() +
      "-" +
      (("" + month).length < 2 ? "0" : "") +
      month +
      "-" +
      (("" + day).length < 2 ? "0" : "") +
      day;

    axios.get("/json_autocomplete/country").then(
      (response) =>
        (this.countries = response.data.data.map((country) => {
          return { id: country.id, text: country.name };
        }))
    );
  },
  components: {
    Select2,
    DatePicker,
    InputField,
    CheckBox,
    SelectBox,
    FileUpload,
    TextArea,
    FormBtn,
    StateSelectBox,
    MultiSelectBox,
    Password,
    Search,
    SelectBoxStatic
  },
  watch: {
    "v$.form.$errors.length": function() {
      this.errAttribute1();
      this.errAttribute3();
    },
    "form.provider_type": function(){      
      if(this.form.provider_type == 2){
        this.additional_provider_val = true;
      }
    }
  },
  computed: {
    getPassPlaceholder() {
      if (this.$route.name === "ProviderEdit") {
        return "KEEP IT EMPTY IF DO NOT WISH TO UPDATE";
      }
    },
    form() {
      return this.$store.state.provider.form;
    },
    vuelidateExternalResults() {
      return this.$store.state.provider.vuelidateExternalResults;
    },
    validationRules() {
      return this.$store.state.provider.validationRules;
    },
    title() {
      return this.$route.name === "ProviderEdit"
        ? "Edit Provider"
        : "New Provider";
    },
    getStaffURL() {
      if(this.$route.params.id == undefined)
        this.$route.params.id = null;
      return `/json_dropdown/providerSpecificStaff?provider_id=${this.$route.params.id}`;
    },
  },

  async beforeCreate() {
    // clear form data since, the same data is being used for add and edit both

    if (this.$route.name === "ProviderAdd") {
      this.$store.state.provider.form = {};
      Object.assign(
        this.$store.state.provider.form,
        this.$store.state.provider.defaultFormData
      );
    } else if (this.$route.name === "ProviderEdit") {
      //if (this.$store.state.provider.form.id != this.$route.params.id) {
      // If edit, get data from server
      await this.$store.dispatch("getEditData", {
        dataType: "provider", // pass the module name
        formType: "form", // pass the propery name
        id: this.$route.params.id,
      });
      // need to set provider id since, getEdit data give id where update excepts provider_id
      this.form.provider_id = this.form.id;
      if(this.form.date_of_birth != null){
        this.date_of_birth = moment(String(this.form.date_of_birth)).format('MM-DD-YYYY');
      }
      if(this.form.additional_provider_id!=null){
        this.form.additional_provider_id = this.form.additional_provider_id.split(",");
      }
      axios.post(`/json_autocomplete/${this.form.country_id}/state`).then(
        (response) =>
          (this.states = response.data.data.map((state) => {
            return { id: state.id, text: state.name };
          }))
      );
      this.$store.state.provider.editID = this.form.id;
      if(this.form.provider_type == 1){
        this.form.supervisor_sign_needed = false;
        $("#supervisor_sign_needed").attr("disabled", true); 
      }
      else{
        this.form.supervisor_sign_needed = true;
      }
     
      if(this.form.is_lifestyle_specific == '1'){
        this.form.is_lifestyle_specific = true;
      }
      else{
        this.form.is_lifestyle_specific = false;  
      }
      if(this.form.disable_claim_npi == '1'){
        this.form.disable_claim_npi = true;
      }
      else{
        this.form.disable_claim_npi = false;  
      }
      if(this.form.supervisor_sign_needed == 1){
        this.form.supervisor_sign_needed = true;
      }
      else{
        this.form.supervisor_sign_needed = false; 
      } 
      if(this.form.can_get_notification == 1){
        this.form.can_get_notification = true;
      } else{
        this.form.can_get_notification = false; 
      }     
      //}
    }
  },
  async created() {
    // expand all tab panels
    
    // remove required rule for passwords when edit
    if (this.$route.name === "ProviderAdd") {
      this.validationRules.form.password = {
        required: helpers.withMessage("Password is required", required),
        minLength: helpers.withMessage(
          () => "Password must be between 8 to 16 characters.",
          minLength(8)
        ),
        maxLength: helpers.withMessage(
          () => "Password must be between 8 to 16 characters.",
          maxLength(16)
        ),
      };
      this.validationRules.form.password_confirmation = {
        required: helpers.withMessage("Confirm Password is required", required),
        minLength: helpers.withMessage(
          () => "Password confirmation must be between 8 to 16 characters.",
          minLength(8)
        ),
        maxLength: helpers.withMessage(
          () => "Password confirmation must be between 8 to 16 characters.",
          maxLength(16)
        ),
        // sameAsPassword: helpers.withMessage(() => 'Password Confirmation Does Not Match.',sameAs('password')),
      };
      axios.post("/json_autocomplete/231/state").then(
        (response) =>
          (this.states = response.data.data.map((state) => {
            return { id: state.id, text: state.name };
          }))
      );
    } else {
      this.validationRules.form.password = {
        minLength: helpers.withMessage(
          () => "Password must be between 8 to 16 characters.",
          minLength(8)
        ),
        maxLength: helpers.withMessage(
          () => "Password must be between 8 to 16 characters.",
          maxLength(16)
        ),
      };
      this.validationRules.form.password_confirmation = {
        minLength: helpers.withMessage(
          () => "Password confirmation must be between 8 to 16 characters.",
          minLength(8)
        ),
        maxLength: helpers.withMessage(
          () => "Password confirmation must be between 8 to 16 characters.",
          maxLength(16)
        ),
      };
    }
    
  },
  methods: {
    reset() {
      this.searchKey = this.searchKey + 1;
    },
    encodeImageFileAsURL(){
      var imagebase64 = '';
      var element = document.getElementById('myfile');
      var file = element.files[0];  
      var reader = new FileReader();  
      reader.onloadend = function() {  
          imagebase64 = reader.result;  
      }  
      reader.readAsDataURL(file);
      this.imagebase64 = reader;
    },
    tabchange(value){
      this.tabname = value;
    },
    signature_save(){
      var errMsg = "Signature is required.";
      if(this.tabname == 'digtal_signature'){
        const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
        this.signature = data;
        if(isEmpty) {
						alert(errMsg)
						return false;
					}
      }
      if(this.tabname == 'free_text'){
        var element = $(this.signature_selects+" .box"); // global variable
        var getCanvas; // global variable
        var thisObj = this;
        if(element.length<=0) {
						alert(errMsg)
						return false;
					}
        html2canvas(element, {
          onrendered: function (canvas) {
            getCanvas = canvas;
            var imgageData = getCanvas.toDataURL("image/jpeg");
            thisObj.signature = getCanvas.toDataURL("image/jpeg");
          }
        });
      }
      if(this.tabname == 'image'){
        if(!this.imagebase64.result) {
						alert(errMsg)
						return false;
					}
        this.signature = this.imagebase64.result;
      }
      this.form.digtal_signature = this.signature;
      document.getElementById("close-model-signature").click();
      //$('#SignatureModal').modal('hide');
    },
    signature_select(id){
      $('.box').removeClass('border-danger');
      $('#border'+id+' .box').addClass('border-danger');
      this.signature_selects = '#border'+id;
    },
    clear(){
      this.signature = "";
      this.$refs.signaturePad.clearSignature();
    },
    zipcode(){
      if(this.form.zip_code && this.form.zip_code.length > 3 ){
        axios.post("json_dropdown/state-city-auto-populations",{zip_code:this.form.zip_code})
        .then((response) => {
            this.form.city=response.data.data.City;
            this.form.state_id=response.data.data.id
        });
      }
      else{
        this.form.city= null;
        this.form.state_id=null;
      }
    },
    getdate(){
      //this.datepickerObj.show();
      if($('#date_of_birth').val() == ''){
        this.form.date_of_birth = null;
      }else{
        this.form.date_of_birth =  $('#date_of_birth').val();
        this.form.date_of_birth = moment(String(this.form.date_of_birth)).format('YYYY-MM-DD');
      }
    },
    check_Supervisor_Sign_Required(){
      if(this.form.supervisor_sign_needed == 0){
        this.form.supervisor_sign_needed == 1;
      }
      else{
        this.form.supervisor_sign_needed == 0;
      }
    },
    check_provider_type(){
      if(this.form.provider_type == 2){
        this.additional_provider_val = true;
      }
      if(this.form.provider_type == 1){
        $("#supervisor_sign_needed").attr("disabled", true); 
      }else{
        $("#supervisor_sign_needed").removeAttr("disabled"); 
      }
    },    
    errAttribute1() {
      return $("#answer-1")
        .children()
        .children(".error").length;
    },
    errAttribute3() {
      return $("#answer-3")
        .children()
        .children(".error").length;
    },
    fileValidation(event, id) {
      $("#" + id)
        .find("ul")
        .html("");
      const size = (event.target.files[0].size / 1024 / 1024).toFixed(2);
      if (size > 2) {
        $("#" + id)
          .find("ul")
          .append(
            '<li class="d-block confirmerror"> image size should not exceed more than 2 MB.</li>'
          );
      }
      const acceptedImageTypes = ["image/jpg", "image/jpeg", "image/png"];
      if (!acceptedImageTypes.includes(event.target.files[0].type)) {
        $("#" + id)
          .find("ul")
          .append(
            '<li class="d-block confirmerror"> Only .jpg,.jpeg,.png formats are supported</li>'
          );
      }
    },
    countryChange({ id, text }) {
      axios.post("/json_autocomplete/" + id + "/state").then(
        (response) =>
          (this.states = response.data.data.map((state) => {
            return { id: state.id, text: state.name };
          }))
      );
    },
    save(next) {
      if(this.signature=="" && (!this.form.digtal_signature || this.form.digtal_signature=="")) {
        alert("Signature is required.");
        return false;
      }
      if(this.signature=="" && this.form.digtal_signature && this.form.digtal_signature!="") {
        this.signature=this.form.digtal_signature;
      }
      next ? (this.saveAndNext = true) : (this.saveAndNext = false);
      this.form.digtal_signature = this.signature;
      if (!this.confirmerror && !this.usernameerror) {
        if (this.$route.name === "ProviderEdit") {
          this.postForm("/provider/update", "provider", true, "formData");
        } else {
          this.postForm("/provider/store", "provider", true, "formData");
        }
      }
      setTimeout(function() {
        if (
          $("#answer-1")
            .children()
            .children(".error").length > 0
        ) {
          $("#question-1")
            .children("a")
            .addClass("form-panel-error");
        } else if (
          $("#answer-3")
            .children()
            .children(".error").length > 0
        ) {
          $("#question-3")
            .children("a")
            .addClass("form-panel-error");
        }
      }, 500);
    },
    passcheck() {
      if (this.form.password != this.form.password_confirmation) {
        if ($("#password_confirmation").find(".confirmerror").length != 1) {
          $("#password_confirmation")
            .find("ul")
            .append(
              '<li class="d-block confirmerror">Confirm password does not match.</li>'
            );
        }
        this.confirmerror = true;
      } else {
        $("#password_confirmation")
          .find(".confirmerror")
          .remove();
        this.confirmerror = false;
      }
    },
    usercheck() {
      if (this.form.password == this.form.username) {
        if ($("#password").find(".confirmerror").length != 1) {
          $("#password")
            .find("ul")
            .append(
              '<li class="d-block confirmerror">Username and password must not be same.</li>'
            );
        }
        this.usernameerror = true;
      } else {
        $("#password")
          .find(".confirmerror")
          .remove();
        this.usernameerror = false;
      }
    },
    formPostSuccess(data) {
      if (this.saveAndNext) {
        this.$router.push({
          path: `/provider/edit/billing/${data.id}`,
        });
        if (this.$route.name === "ProviderEdit") {
          this.moshaToast("Record updated successfully.", "success");
        } else {
          this.moshaToast("Record added successfully.", "success");
        }
      } else {
        if (this.$route.name === "ProviderEdit") {
          this.moshaToast("Record updated successfully.", "success");
        } else {
          if(data == null){
            this.moshaToast("Entered email is invalid or does not exist. Please enter valid email.", "info")
          }
          else{
            this.moshaToast("Record added successfully.", "success");
          }
        }
        if (permissioncheck(this.$store.state.permission.Settings)) {
          this.$router.push("/provider/list");
        } else {
          this.$router.push("/profile/personal-info");
        }
      }
    },
    defaultRoomModalShow() {
      this.getOfficeRoom();
    },
    getOfficeRoom() {
        axios.post(`/provider/get-user-default-room`, 
        {
          type: "provider",
          id: this.$route.params.id,
          'office_id':this.form.office_access.join(',')
        }
        ).then(
          (response) =>
            (this.rooms = response.data.data)

        );
        
    },
    saveDefaultRoom() {
      var selectedOpt = [];
      $(".officeDefaultRoom").each(function () {
        if ($(this).prop('checked')) {
          var value    = $(this).val();
          var officeId = $(this).attr('data-office-id');
          selectedOpt.push({
            officeId: officeId, 
            roomId:value
          });
        }
      });
      
      if (selectedOpt.length > 0) {
        axios.post(`/provider/store-user-default-room`, {
          type: "provider",
          id: this.$route.params.id,
          'room_ids': selectedOpt
        }).then(
          function(res) {
            if (res.data.status && res.data.status == "success") {
            } else {
            }
          }
        );
        this.moshaToast('Default room saved successfully', 'success');
      }

    }
  },
  validations() {
    return this.$store.state.provider.validationRules;
  }
};
</script>
