<template>
	<div class="content-wrapper">
		<div class="container-full">
			<div class="content-header">
				<div class="row justify-content-between">
					<div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xs-6">
						<h1 class="heading1 my-5">Status & Pipeline Settings</h1>
					</div>
				</div>
			</div>

			<section class="content">
				<div class="box p-15">
					<div class="mdc-tab">
						<ul class="nav nav-tabs mt-0 text-center text-sm-start" role="tablist">
				            <li class="nav-item"> <a class="nav-link active" data-bs-toggle="tab" href="#soap" role="tab" >Status</a> </li>
				            <li class="nav-item"> <a class="nav-link" data-bs-toggle="tab" href="#handp" role="tab">Pipeline</a></li>
				        </ul>
					</div>

					<div class="tab-content">
						<div class="tab-pane active" id="soap" role="tabpanel">
							<div class="content-header">
								<div class="row justify-content-between">
									<div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xs-6 text-sm-end">
										<select class="form-select" v-model="changes_pipeline_id" @change="changepipeline">
											<option selected="selected" disabled="disabled" value="">Select Pipeline</option>
											<option v-for="item in pipeline_list" :value="item.id" v-show="item.status == 'Active'">{{item.name}} ({{ get_pipeline_for(item.pipeline_for) }})</option>
										</select>
									</div>
									<div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xs-6 text-sm-end">
										<a href="javascript:void(0)" 
										class="waves-effect waves-light btn btn-info" data-bs-toggle="modal" data-bs-target="#statusAddEditModal"><i class="fa fa-plus fs-14"></i>  Status</a>
									</div>
								</div>
							</div>
							<section class="content">
								<div class="table-responsive">
									<data-table dataType="ProspectStatus" :key="table_load_count" pageLength="50" >
										<template v-slot="slotProps">
											<!-- slot for table data -->
											<slot v-if="slotProps.item.pipeline_id == changes_pipeline_id || slotProps.item.pipeline_id == 0">
												
												<td :data-id="slotProps.item.id"> <span class="float-start" style="cursor: move;"  v-if="slotProps.item.clinic_id != null">
													<i class="fa fa-ellipsis-v handle fw-100 fs-14 text-dark me-1"></i>
													<i class="fa fa-ellipsis-v handle fw-100 fs-14 text-dark me-1"></i>
													</span> 
												</td>
												<td> {{ slotProps.item.status_label }} </td>
												<td>
													<span v-if="slotProps.item.clinic_id == null">
														<span class="badge badge-info-light fs-16">System</span>
													</span>
													<span v-if="slotProps.item.clinic_id != null">
														<span class="badge badge-info-light fs-16">Custom</span>
													</span>
												</td>

												<td>{{ slotProps.item.sort_order }}</td>
												<td>
													<div class="form-check" v-if="slotProps.item.clinic_id != null">
														<input
														:id="'cb_' + slotProps.item.id"
														type="checkbox"
														name="day_name"
														:checked="slotProps.item.show_on_calendar"
														class="filled-in chk-col-info dayCheckbox"
														@click="showOnCalendar(slotProps.item.id, $event)"
														/>
														<label
														:for="'cb_' + slotProps.item.id"
														class="text-start"
														></label
														>
													</div>
												</td>
												<td>
													<div class="form-check" v-if="slotProps.item.clinic_id != null">
														<input
														:id="'cb_new_' + slotProps.item.id"
														type="checkbox"
														name="day_name"
														:checked="slotProps.item.isExpanded"
														class="filled-in chk-col-info dayCheckbox"
														@click="isExpantable(slotProps.item.id, $event)"
														/>
														<label
														:for="'cb_new_' + slotProps.item.id"
														class="text-start"
														></label
														>
													</div>
												</td>
												<td> 
													<span v-if="slotProps.item.clinic_id != null">
														<button type="button"  data-on="Ready" data-off="Not Ready" @click="onToggleChange(slotProps.item.id, $event)" :id="['status_' + slotProps.item.id]" class="btn btn-toggle rounded30-im"  v-bind:class="[slotProps.item.status == 'A' ? 'active' : '']" data-bs-toggle="button" aria-pressed="true" autocomplete="off">
															<div class="handle rounded30-im"></div>
														</button>
													</span>
												</td>
												<td>
													<span v-if="slotProps.item.clinic_id != null">
														<Popper arrow content="Edit Status" :hover="true" class="helptips cursor-pointer">
															<a 
															@click="editStatus(slotProps.item.id)"
															href="#"
															class="waves-effect waves-circle btn btn-circle btn-info btn-xs" 
															><i class="fa fa-pencil"></i></a>
														</Popper>
													</span>
												</td>
											</slot>
										</template>
									</data-table>
								</div>
							</section>
						</div>
						<div class="tab-pane" id="handp" role="tabpanel">
							<div class="content-header">
								<div class="row justify-content-between">
									<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xs-12 text-sm-end">
										<a href="javascript:void(0)" class="waves-effect waves-light btn btn-info" data-bs-toggle="modal" data-bs-target="#pipelineAddEditModal"><i class="fa fa-plus fs-14"></i>  Pipeline</a>
									</div>
								</div>
							</div>
							<section class="content">
								<div class="box">
									<div class="box-body">
										<div class="table-responsive">
											<data-table dataType="Pipeline">
												<template v-slot="slotProps">
													<td> {{ slotProps.item.name }} ({{ get_pipeline_for(slotProps.item.pipeline_for) }}) </td>
													<td>
														<span>
															<button type="button"  data-on="Ready" data-off="Not Ready" @click="pipelineToggleChange(slotProps.item.id, $event)" :id="['pipelinestatus_' + slotProps.item.id]" class="btn btn-toggle rounded30-im"  v-bind:class="[slotProps.item.status == 'Active' ? 'active' : '']" data-bs-toggle="button" aria-pressed="true" autocomplete="off">
																<div class="handle rounded30-im"></div>
															</button>
														</span>
													</td>
													<td>
														<a href="javascript:void(0)" class="waves-effect waves-circle btn btn-circle btn-info btn-xs" @click="editpipeline(slotProps.item.id)" ><i class="fa fa-pencil"></i> </a>
													</td>
												</template>
											</data-table>
										</div>
									</div>
								</div>
							</section>
						</div>
					</div>
				</div>
			</section>
	</div>
</div>

<div id="statusAddEditModal" class="modal fade" tabindex="-1" aria-labelledby="problemModalLabel" style="display: none;" aria-hidden="true">
	<div class="modal-dialog ">
		<div class="modal-content">
			<div class="modal-header">
				<h2 class="modal-title m-0"> {{ this.form.id ? 'Edit' : 'New' }} Status </h2>
			</div>
			<form>
				<div class="modal-body">

					<div class="form-group mb-10" :class="{ error: v$.form.status_label.$errors.length }">
						<label class="form-label">Name </label> <small class="text-danger asterisksign">*</small>
						<input type="text"
						v-model="form.status_label"
						class="form-control"
						required
						placeholder="Name">
						<div class="error-msg" v-if="v$.form.status_label.$errors.length > 0">
							<ul class="mb-0 p-0 fs-14"><li class="d-block" v-for="(error, index) of v$.form.status_label.$errors"> {{ error.$message }}</li>
							</ul>
						</div>
					</div>

					<div class="form-group mb-10" :class="{ error: v$.form.pipeline_id.$errors.length }">
						<label class="form-label">Pipeline </label> <small class="text-danger asterisksign">*</small>
						<select class="form-select" v-model="form.pipeline_id">
							<option selected="selected" disabled="disabled" value=''>Select Pipeline </option>
							<option v-for="item in pipeline_list" :value="item.id" v-show="item.status == 'Active'">{{item.name}} ({{ get_pipeline_for(item.pipeline_for) }})</option>
						</select>						
						<div class="error-msg" v-if="v$.form.pipeline_id.$errors.length > 0">
							<ul class="mb-0 p-0 fs-14"><li class="d-block" v-for="(error, index) of v$.form.pipeline_id.$errors"> {{ error.$message }}</li>
							</ul>
						</div>
					</div>

				</div>
				<div class="modal-footer">
					<button type="button" class="waves-effect waves-light btn btn-info"  @click="saveStatus()">Save</button>
					<button type="button" class="waves-effect waves-light btn btn-danger" data-bs-dismiss="modal">Close</button>
				</div>
			</form>
		</div>
	</div>
</div>

<!--pipeline addeditmodal start-->
<div id="pipelineAddEditModal" class="modal fade" tabindex="-1" aria-labelledby="problemModalLabel" style="display: none;" aria-hidden="true">
	<div class="modal-dialog ">
		<div class="modal-content">
			<div class="modal-header">
				<h2 class="modal-title m-0"> {{ this.pipeline_id ? 'Edit' : 'New' }} Pipeline </h2>
			</div>
			<form>
				<div class="modal-body">
					<div class="form-group mb-10" :class="{ error: v$.form.status_label.$errors.length }">
						<label class="form-label">Name </label> <small class="text-danger asterisksign">*</small>
						<input type="text" v-model="pipeline_name" class="form-control" required placeholder="Name" @keyup="pipelinekeyup">
						<div class="error-msg pipeline_name_error d-none">
							<ul class="mb-0 p-0 fs-14">
								<li class="d-block">Pipeline name is required.</li>
							</ul>
						</div>
					</div>
					<div class="form-group mb-10">
						<label class="form-label">Short Name </label> <small class="text-danger asterisksign">*</small>
						<input type="text" v-model="short_name" class="form-control" required placeholder="Short Name" @keyup="short_namekeyup">
						<div class="error-msg short_name_error d-none">
							<ul class="mb-0 p-0 fs-14">
								<li class="d-block">Short name is required.</li>
							</ul>
						</div>
					</div>
				</div>
				<div class="modal-footer">
					<button type="button" class="waves-effect waves-light btn btn-info"  @click="savepipeline()">{{ this.pipeline_id ? 'Update' : 'Save' }}</button>
					<button type="button" class="waves-effect waves-light btn btn-danger" data-bs-dismiss="modal">Close</button>
				</div>
			</form>
		</div>
	</div>
</div>
<!--pipeline addeditmodal end-->
</template>

<script>
	import axios from "@/scripts/axios.js";
	import dataTable from "@/components/base/DataTable.vue";
	import Popper from "vue3-popper";
	import useVuelidate from "@vuelidate/core";
	import submitMixins from "@/scripts/mixins/submitMixins.js";
	import permissionMixins from "@/scripts/mixins/permission.js";

	export default {
		name: 'ProspectStatus',
		setup: () => {
			return { v$: useVuelidate() };
		},
		mixins: [submitMixins,permissionMixins],
		components: { dataTable,Popper},
		data () {
			return {
				rolename:'',
				description:'',
				permission_ids:'',
				Editpermission:[],
				tokenPayReady: false, 
				checkingInterval: null,
				table_load_count: 1,
				pipeline_name: '',
				pipeline_id:'',
				pipeline_list:{},
				changes_pipeline_id:'',
				short_name:'',
			} 
		},
		computed: {
			form() {
				return this.$store.state.ProspectStatus.form;
			},
			vuelidateExternalResults() {
				return this.$store.state.ProspectStatus.vuelidateExternalResults;
			},
			validationRules() {
				return this.$store.state.ProspectStatus.validationRules;
			}, 
		},
		validations() {
			return this.$store.state.ProspectStatus.validationRules;
		},
		mounted() {
			let bridgePayScript = document.createElement('script')
			bridgePayScript.setAttribute('src', 'https://cdnjs.cloudflare.com/ajax/libs/jqueryui/1.12.1/jquery-ui.min.js')
			document.head.appendChild(bridgePayScript);
			this.getpipeline();
		},
		methods: {
			changepipeline(){
				this.form.pipeline_id = this.changes_pipeline_id;
			},
			showOnCalendar(id, event) {
				axios
				.post("crm/status/update",{"show_on_calendar": event.target.checked, "id": id })
				.then((response) => {
					this.moshaToast('Record updated successfully.','success');
				});
			},
			isExpantable(id, event) {
				console.log("call inside");
				axios
				.post("crm/status/update",{"isExpanded": event.target.checked, "id": id })
				.then((response) => {
					this.moshaToast('Record updated successfully.','success');
				});
			},
			onToggleChange(id, event) {
				var class_list = document.getElementById("status_"+id).classList;
				var active_deactive = '';
				if(class_list.contains('active')) {
					active_deactive = 'A';
				} else {
					active_deactive = 'I';
				}

				axios
				.post("crm/status/update",{"status":active_deactive,"id":id})
				.then((response) => {
					this.moshaToast('Status updated successfully.','success');
				});
			},
			resetForm() {
				var self = this;
				Object.keys(this.form).forEach(function(key,index) {
					self.form[key] = '';
				});
				this.v$.$reset();
			},
			saveStatus() {
				const storeOrUpdate = this.form.id ? 'update' : 'store';
				const url = 'crm/status/'+storeOrUpdate;
				this.postForm(url, 'ProspectStatus');
			},
			pipelinekeyup(){
				if(this.pipeline_name.length === 0){
					$('.pipeline_name_error').removeClass('d-none');
				}
				else{
					$('.pipeline_name_error').addClass('d-none');	
				}
			},
			savepipeline(){
				if(this.pipeline_name != '' && this.short_name != ''){
					if(this.pipeline_id == ''){
						axios.post(`crm/pipeline-store`,{ 'name' : this.pipeline_name,'short_name' : this.short_name })
						.then((response) => {
							this.moshaToast("Record added successfully.", "success");
							setTimeout(function(){ location.reload(); }, 300);
						})
					}
					else{
						axios.post(`crm/pipeline-update`,{ 'name' : this.pipeline_name,'id' : this.pipeline_id,'short_name' : this.short_name })
						.then((response) => {
							this.moshaToast("Record updated successfully.", "success");
							setTimeout(function(){ location.reload(); }, 300);
						})	
					}
				}
				else{
					
					if(this.pipeline_name == ''){
						$('.pipeline_name_error').removeClass('d-none');
					}
					if(this.short_name == ''){
						$('.short_name_error').removeClass('d-none');
					}
				}
			},
			pipelineToggleChange(id, event){
				var class_list = document.getElementById("pipelinestatus_"+id).classList;
				var active_deactive = '';
				if(class_list.contains('active')) {
					active_deactive = 'Active';
				} else {
					active_deactive = 'InActive';
				}
				axios
				.post("crm/pipeline-status",{"status":active_deactive,"id":id})
				.then((response) => {
					this.moshaToast('Status updated successfully.','success');
					this.getpipeline();
				});
			},
			editpipeline(id){
				axios.get(`crm/pipeline-edit/`+id)
				.then((response) => {
					this.pipeline_name = response.data.data.name;
					this.pipeline_id = response.data.data.id;
					this.short_name = response.data.data.short_name;
				})
				$('#pipelineAddEditModal').modal('show');

			},
			getpipeline(){
				axios.get(`crm/pipeline-list`)
				.then((response) => {
					this.pipeline_list = response.data.data;
					for (var i = 0; i < this.pipeline_list.length; i++) {
						if(this.pipeline_list[i]['status'] == 'Active'){
							this.changes_pipeline_id = response.data.data[i].id;
							break;
						}
					}
				})
			},
			formPostSuccess(response) {
				const message = this.form.id ? 'Record updated successfully.' : 'Record added successfully.';
				this.moshaToast(message,'success');

				$('#statusAddEditModal').modal('hide');

				this.resetForm();

				this.ProspectStatus = [];
				setTimeout(function(){ location.reload(); }, 300);
			},
			async editStatus(id) {
				if (id) {
					const result =  await this.$store.dispatch("getEditData", {
					  dataType: "ProspectStatus", // pass the module name
					  formType: "form", // pass the propery name
					  id: id,
					});
					$('#statusAddEditModal').modal('show');
				}
			},
			updateOrder(sortedIds) {
				var newArray = new Array();
			    for(var i = 0; i<sortedIds.length; i++)
			    {
			        if (sortedIds[i])
			        {
			            newArray.push(sortedIds[i]);
			        }
			    }
				axios.post(`/crm/status/changeSortOrder`,{ 'sortedIds' : newArray })
				.then((response) => {
					this.moshaToast(response.data.message,'success');
					setTimeout(function(){ location.reload(); }, 300);
				})
			},
			get_pipeline_for(title) {
				var retVal = title;
				if(title=="gro") {
					retVal = "GrowLocal";
				} else if(title=="nuc") {
					retVal = "ClientSwipe";
				}
				return retVal;
			}
		},
		async created() {
			let localThis = this ;
			this.checkingInterval = setInterval(function(){
				if (window.$ && $.ui) {
					localThis.tokenPayReady = true
				}
			}, 500);
		},
		watch: {
			tokenPayReady: function(data) {
				if (data) { 
					clearInterval(this.checkingInterval);
					let $this = this;
					$( "tbody" ).sortable({
						delay: 150,
						items: 'tr:not(:first)',
						stop: function() {
							var selectedData = new Array();
							$('tbody > tr').each(function() {
								selectedData.push($(this).find('td:first-child').data('id'));
							});
							$this.updateOrder(selectedData);
						}
					});
				}
			}
		},
	}
</script>