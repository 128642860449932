<template>
   <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-10">
      <div class="box">
         <div class="box-body">
            <div class="pageheading py-2">
               <div class="row">
                  <div class="col-6 col-sm-6 col-md-6">
                     <h2 class="font-bold mt-0">Roles <a href="#" data-bs-toggle="modal" data-bs-target="#rolesdocument" title="Reference of Knowledge Base" v-on:click.prevent="reset()"> <i class="fa fa-question-circle fs-20"></i></a></h2>
                  </div>
                  <div class="col-6 col-sm-6 col-md-6 text-end">
                     <a href="javascript:void(0)" 
                     class="waves-effect waves-light btn btn-info" @click="createRole()" data-bs-toggle="modal" data-bs-target="#roleAddEditModal"><i class="fa fa-plus fs-14"></i>  Role</a>
                  </div>
               </div>
            </div>
         <div class="table-responsive">
            <data-table dataType="RolesList">
               <template v-slot="slotProps">
                  <!-- slot for table data -->
                  <td> {{ slotProps.item.name }} </td>
                  <td>
                     <span v-if="slotProps.item.is_public == 1">
                      <span class="badge badge-info-light fs-16">System</span>
                     </span>
                     <span v-if="slotProps.item.is_public != 1">
                      <span class="badge badge-info-light fs-16">Custom</span>
                     </span>
                  </td>

                  <td>{{ slotProps.item.description }}</td>
                  <td> 
                     <span v-if="slotProps.item.is_public != 1">
                        <button type="button"  data-on="Ready" data-off="Not Ready" @click="onToggleChange(slotProps.item.id, $event)" :id="['status_' + slotProps.item.id]" class="btn btn-toggle rounded30-im"  v-bind:class="[slotProps.item.status == '1' ? 'active' : '']" data-bs-toggle="button" aria-pressed="true" autocomplete="off">
                          <div class="handle rounded30-im"></div>
                        </button>
                     </span>
                  </td>
                  <td>
                     <span v-if="slotProps.item.is_public != 1">
                        <Popper arrow content="Edit Role" :hover="true" class="helptips cursor-pointer">
                           <a 
                             @click="editRole(slotProps.item.id)"
                             href="#"
                             class="waves-effect waves-circle btn btn-circle btn-info btn-xs me-1" 
                             ><i class="fa fa-pencil"></i></a>
                        </Popper>
                        <Popper arrow content="Delete Role" :hover="true" class="helptips cursor-pointer">
                           <a
                          @click="deleteItem(slotProps.item.id)"
                          href="#"
                          class="waves-effect waves-circle btn btn-circle btn-danger btn-xs me-1"><i class="fa fa-trash"></i></a>
                       </Popper>
                     </span>
                     <Popper arrow content="View Permissions" :hover="true" class="helptips cursor-pointer">
                        <a href="#" @click="rolesmodal(slotProps.item.name,slotProps.item.description,slotProps.item.permission_ids,slotProps.item.id)" class="waves-effect waves-circle btn btn-circle btn-info btn-xs me-1" data-bs-toggle="modal" data-bs-target="#rolemodal"><i class="fa fa-eye"></i></a>
                     </Popper>
                  </td>
               </template>
            </data-table>
         </div>
      </div>
   </div>

   <div id="rolemodal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
      <div class="modal-dialog">
         <div class="modal-content permissmodal">
            <div class="modal-header">
               <h3 class="modal-title font-bold m-0">View Permissions</h3>
            </div>
            <div class="modal-body">
               <div class="form-group row">
                  <label class="col-12 col-sm-3 col-md-3">Role Name</label>
                  <span class="col-12 col-sm-9 col-md-9">{{rolename}}</span>
               </div>
               <div class="form-group row">
                  <label class="col-12 col-sm-3 col-md-3">Description</label>
                  <span class="col-12 col-sm-9 col-md-9">{{description}}</span>
               </div>
               <div class="box mb-0">
                  <div class="box-body">
                     <div class="form-check" v-for="(obj,index) in Editpermission" :key="index">
                        <div class="text-center font-bold">{{index}}</div>
                        <hr>
                        <slot v-for="(item,index1) in obj" :key="index1">
                           <div v-if="item.selected === 'true'">
                              <input checked disabled type="checkbox" v-bind:id="'md_checkbox_' + item.id" class="filled-in chk-col-info">   
                              <label v-bind:for="'md_checkbox_' + item.id" class="text-start">{{ item.name }}</label>
                              <a href="javascript:void(0)" class="ms-5 black-color">
                                 <Popper arrow :content="item.description" hover="true" class="helptips">
                                    <i class="fa fa-info-circle fs-12"></i>
                                 </Popper>
                              </a>
                           </div>
                           <div v-else>
                              <input disabled type="checkbox" v-bind:id="'md_checkbox_' + item.id" class="filled-in chk-col-info">   
                              <label v-bind:for="'md_checkbox_' + item.id" class="text-start">{{ item.name }} </label>
                              <a href="javascript:void(0)" class="ms-5 black-color">
                                 <Popper arrow :content="item.description" hover="true" class="helptips">
                                 <i class="fa fa-info-circle fs-12"></i>
                                 </Popper>
                              </a>
                           </div>
                        </slot>
                     </div>
                  </div>
               </div>
            </div>
            <div class="modal-footer">
               <button type="button" @click="clearpermisson" class="waves-effect btn btn-danger" data-bs-dismiss="modal">Close</button>
            </div>
         </div>
      </div>
   </div>

   <div id="roleAddEditModal" class="modal fade" tabindex="-1" aria-labelledby="problemModalLabel" style="display: none;" aria-hidden="true">
      <div class="modal-dialog ">
         <div class="modal-content">
            <div class="modal-header">
               <h2 class="modal-title m-0"> {{ this.form.id ? 'Edit' : 'Create' }} Role </h2>
            </div>
            <form>
               <div class="modal-body">

                  <div class="form-group mb-10" :class="{ error: v$.form.name.$errors.length }">
                     <label class="form-label">Name </label> <small class="text-danger asterisksign">*</small>
                     <input type="text"
                     v-model="form.name"
                     class="form-control"
                     required
                     :placeholder="title">

                     <div class="error-msg" v-if="v$.form.name.$errors.length > 0">
                        <ul class="mb-0 p-0 fs-14"><li class="d-block" v-for="(error, index) of v$.form.name.$errors"> {{ error.$message }}</li>
                        </ul>
                     </div>
                  </div>

                  <div class="form-group mb-10"  :class="{ error: v$.form.description.$errors.length }">
                     <label class="form-label">Description </label> <small class="text-danger asterisksign">*</small>
                     <textarea
                     v-model="form.description"
                     class="form-control"
                     required
                     :placeholder="Description"
                     ></textarea>
                     
                     <div class="error-msg" v-if="v$.form.description.$errors.length > 0">
                        <ul class="mb-0 p-0 fs-14"><li class="d-block" v-for="(error, index) of v$.form.description.$errors"> {{ error.$message }}</li>
                        </ul>
                     </div>
                  </div>
                  <div class="box mb-0">
                     <div class="box-body">
                        <div class="form-check" v-for="(obj,index) in Editpermission" :key="index">
                           <div class="text-center font-bold">{{index}}</div>
                           <hr>
                           <slot v-for="(item,index1) in obj" :key="index1">
                              <div v-if="item.selected === 'true'">
                                 <input checked  name="permission_ids" type="checkbox" v-bind:id="'role_checkbox_' + item.id" class="filled-in chk-col-info" :value="item.id">   
                                 <label v-bind:for="'role_checkbox_' + item.id" class="text-start">{{ item.name }}</label>
                                 <a href="javascript:void(0)" class="ms-5 black-color">
                                    <Popper arrow :content="item.description" hover="true" class="helptips">
                                       <i class="fa fa-info-circle fs-12"></i>
                                    </Popper>
                                 </a>
                              </div>
                              <div v-else>
                                 <input type="checkbox" name="permission_ids" v-bind:id="'role_checkbox_' + item.id" class="filled-in chk-col-info" :value="item.id">   
                                 <label v-bind:for="'role_checkbox_' + item.id" class="text-start">{{ item.name }} </label>
                                 <a href="javascript:void(0)" class="ms-5 black-color">
                                    <Popper arrow :content="item.description" hover="true" class="helptips">
                                       <i class="fa fa-info-circle fs-12"></i>
                                    </Popper>
                                 </a>
                              </div>
                           </slot>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="modal-footer">
                  <button type="button" class="waves-effect waves-light btn btn-info"  @click="saveRole()">Save</button>
                  <button type="button" @click="closeDialog"  class="waves-effect waves-light btn btn-danger" data-bs-dismiss="modal">Close</button>
               </div>
            </form>
         </div>
      </div>
   </div>
   <!--document model start-->
   <div id="rolesdocument" class="modal fade" tabindex="-1" role="dialog"
      aria-labelledby="myModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-md">
         <div class="modal-content">
         <div class="modal-header">
            <h3 class="modal-title fw-900 mt-0">Reference of Knowledge Base</h3>
         </div>
         <div class="modal-body overflow-auto" style="max-height: 350px;">
            <table class="table table-bordered">
               <thead>
                  <tr>
                  <th scope="col">No.</th>
                  <th scope="col">Knowledge Base Articles</th>
                  </tr>
               </thead>
               <tbody>
                  <tr>
                     <td>1</td>
                     <td> <a :href="path+'how-to-add-a-custom-created-role'" target="_blank">How To Add A Custom Created Role?</a></td>
                  </tr>
                  <tr>
                     <td>2</td>
                     <td> <a :href="path+'how-to-edit-custom-created-roles'" target="_blank">How To Edit Custom Created Roles?</a></td>
                  </tr>
                  <tr>
                     <td>3</td>
                     <td> <a :href="path+'how-to-activate-deactivate-custom-created-role'" target="_blank">How To Activate/ Deactivate Custom Created Role?</a></td>
                  </tr>
                  <tr>
                     <td>4</td>
                     <td> <a :href="path+'how-to-create-a-role-for-billing-administrator-in-pureehr'" target="_blank">How To Create A Role For Billing Administrator In PureEHR?</a></td>
                  </tr>
               </tbody>
            </table>
            <Search :key="searchKey"></Search>
         </div>
         <div class="modal-footer">
            <button type="button" @click="" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
         </div>
         </div>
      </div>
   </div>
   <!--document model end-->
</div>
</template>

<script>
   import axios from "@/scripts/axios.js";
   import dataTable from "@/components/base/DataTable.vue";
   import Popper from "vue3-popper";
   import useVuelidate from "@vuelidate/core";
   import submitMixins from "@/scripts/mixins/submitMixins.js";
   import permissionMixins from "@/scripts/mixins/permission.js";
   import Search from "@/components/Search.vue";

   export default {
      name: 'RolesList',
      setup: () => {
         return { v$: useVuelidate() };
      },
      mixins: [submitMixins,permissionMixins],
      components: { dataTable,Popper,Search},
      data () {
         return {
            rolename:'',
            description:'',
            permission_ids:'',
            Editpermission:[],
            path: process.env.VUE_APP_KB_DOCUMENT,
            searchKey:0,
         } 
      },
      computed: {
         form() {
            return this.$store.state.RolesList.form;
         },
         vuelidateExternalResults() {
            return this.$store.state.RolesList.vuelidateExternalResults;
         },
         validationRules() {
            return this.$store.state.RolesList.validationRules;
         }, 
      },
      validations() {
         return this.$store.state.RolesList.validationRules;
      },
      mounted() {
      },
      methods: {
         reset() {
            this.searchKey = this.searchKey + 1;
         },
         createRole(){
            var permission_ids = '';
            var thisObj = this;
            axios.post('/permission-edit',{permission_id:permission_ids})
               .then(response => {thisObj.Editpermission = response.data.data });
         },
         onToggleChange(id, event) {
            var class_list = document.getElementById("status_"+id).classList;
            var active_deactive = '';
            if(class_list.contains('active')) {
              active_deactive = '1';
            } else {
              active_deactive = '0';
              // console.log(class_list.contains('active'));
            }

            axios
              .post("permission/role/update",{"status":active_deactive,"id":id})
              .then((response) => {
                this.moshaToast('Role updated successfully.','success');
              });
         },
         resetForm() {
            var self = this;
            Object.keys(this.form).forEach(function(key,index) {
               self.form[key] = '';
            });
            this.date = '';
            this.v$.$reset();
         },
         rolesmodal(name,description,permission_ids,id){
            this.rolename = name;
            this.description = description;
            this.permission_ids = permission_ids;      
            var thisObj = this;
            axios.post('/permission-edit',{permission_id:permission_ids})
            .then(response => {thisObj.Editpermission = response.data.data });
         },
         saveRole() {
            const storeOrUpdate = this.form.id ? 'update' : 'store';
            const url = '/permission/role/'+storeOrUpdate;
            var permissionsedit = Array();
            $("input:checkbox[name=permission_ids]:checked").each(function() {
              permissionsedit.push($(this).val());
            });
            this.form.permission_id = permissionsedit;
            this.postForm(url, 'RolesList');
         },
         formPostSuccess(response) {
            const message = this.form.id ? 'Record updated successfully.' : 'Record added successfully.';
            this.moshaToast(message,'success');

            $('#roleAddEditModal').modal('hide');

            this.resetForm();

            this.RolesList = [];
            setTimeout(function(){ location.reload(); }, 400);
         },
         async editRole(id) {
            if (id) {
               const result =  await this.$store.dispatch("getEditData", {
                 dataType: "RolesList", // pass the module name
                 formType: "form", // pass the propery name
                 id: id,
               });
              
               var permission_ids = this.$store.state.RolesList.form.permission_ids;
               var thisObj = this;
               
               axios.post('/permission-edit',{permission_id:permission_ids})
               .then(response => {thisObj.Editpermission = response.data.data });

               $('#roleAddEditModal').modal('show');
            }
         },
         deleteItem(id) {
            this.$swal.fire({
              title: 'Are you sure you want to delete this record?',
              type: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes, delete it!'
            })
            .then((result) => {
                if (result.isConfirmed == true) {
                    axios.post(`/permission/role/delete`,{'id':id})
                    .then((response) => {
                    this.$swal.fire('Deleted!','Role has been deleted.','success')  
                        .then((result) => { 
                            if(result.isConfirmed == true){
                               location.reload();
                            }
                        });
                    })
                }
            });
         },
      }
   }
</script>