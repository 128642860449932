import {
    helpers,
    required,
    email,
    numeric,
} from "@vuelidate/validators";

const CorporateCampaign = {
  namespaced: true,
  state: { 
    form: {
      // provider_id: '',
      staff_id: '',
      office_id:'',
      room_id:[],
      date:new Date(),
      start_time:'',
      end_time:'',
      customProcedureArr:[],
      street:'',
      apartment:'',
      zipcode:'',
      city:'',
      state_id:'',
      country_id:"231",
      corporate_id:'',
      campaign_name:'',
      is_edit_patient:'',
      name:'',

    },
    vuelidateExternalResults: {
      form: {
        // provider_id: '',
        staff_id: '',
        office_id:'',
        room_id:[],
        date:'',
        start_time:'',
        end_time:'',
        customProcedureArr:[],
        category_id:[],
        street:'',
        zipcode:'',
        city:'',
        state:'',
        country_id:"",
        corporate_id:'',
        name:'',
      }
    },
    validationRules: {
      form:{
        // provider_id: {
        //   required: helpers.withMessage("This field is required", required),
        // },
        staff_id: {
          required: helpers.withMessage("This field is required", required),
        },
        name: {
          required: helpers.withMessage("This field is required", required),
        },
        office_id: {
          required: helpers.withMessage("This field is required", required),
        },
        room_id: {
          required: helpers.withMessage("This field is required", required),
        },
        date: {
          required: helpers.withMessage("This field is required", required),
        },
        start_time: {
          required: helpers.withMessage("This field is required", required),
        },
        end_time: {
          required: helpers.withMessage("This field is required", required),
        },
        customProcedureArr: {
          required: helpers.withMessage("Select any one Service", required),
          
        },
        street: {
          required: helpers.withMessage("This field is required", required),
        },
        zipcode: {
          required: helpers.withMessage("This field is required", required),
        },
        city: {
          required: helpers.withMessage("This field is required", required),
        },
        state_id: {
          required: helpers.withMessage("This field is required", required),
        },
        country_id: {
          required: helpers.withMessage("This field is required", required),
        },
        
      }
    },
    list: [],
  },
  
  mutations: {},
  actions: {},
};

export default CorporateCampaign;
