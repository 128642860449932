import {
  helpers,
  required,
  email,
  minLength,
  maxLength,
  sameAs,
  url,
} from "@vuelidate/validators";


const corporate = {
  namespaced: true,

  state: {
    editID: "",
    listUrl: "/corporate/list",
    getEditUrl: "/corporate/:id/edit",
    tableData: {
      header: [
        "Corporate name",
        "Team Leader",
        "Primary Contact Name",
        "Primary Contact No",
        "Actions",
      ],
    },
    form: {},
    vuelidateExternalResults: {
      form: {
        corporate_name:[],
        country_id:[],
        state_id:[],
        zip_code:[],
        city:[],
        address:[],
        primary_provider:[],
        // crm_status:[],
        team_leader:[],
        main_contact_firstname:[],
        main_contact_lastname:[],
        main_contact_email:[],
        main_contact_phone:[],
      }
    },
    validationRules: {
      form: {
        corporate_name: {
          required: helpers.withMessage("Corporate Name is required", required),
        },
        country_id: {
          required: helpers.withMessage("Country is required", required),
        },
        state_id: {
          required: helpers.withMessage("State is required", required),
        },
        zip_code:{
          required: helpers.withMessage("zip Code is required", required),
        },
        city:{
          required: helpers.withMessage("City is required", required),
        },
        address:{
          required: helpers.withMessage("Address is required", required),
        },
        primary_provider:{
          required: helpers.withMessage("Primary Provider is required", required),
        },
        // crm_status:{
        //   required: helpers.withMessage("CRM Status is required", required),
        // },
        team_leader:{
          required: helpers.withMessage("Team Leader is required", required),
        },
        main_contact_firstname :{
          required: helpers.withMessage("Primary First Name is required", required),
        },
        main_contact_lastname :{
          required: helpers.withMessage("Primary Last Name is required", required),
        },
        main_contact_email :{
          required: helpers.withMessage("Contact Email is required", required),
          email: helpers.withMessage("Email address is not valid", email),
        },
        main_contact_phone:{
          required: helpers.withMessage("Contact Phone is required", required),
        }
      }
    },
    list: [],
  },
  getters: {
    getTableHeaders: (state) => state.header,
    getTableContents: (state) => state.list,
    getListURL: (state) => state.listUrl,
  },
  mutations: {},
  actions: {},
};

const archivedcorporate = {
  namespaced: true,

  state: {
    editID: "",
    listUrl: "/corporate/archived",
    tableData: {
      header: [
        "Corporate name",
        "Team Leader",
        "Primary Contact Name",
        "Primary Contact No",
        "Action",
      ],
    },
    form: {},
    vuelidateExternalResults: {
    },
    validationRules: {
    },
    list: [],
  },
  getters: {
    getTableHeaders: (state) => state.header,
    getTableContents: (state) => state.list,
    getListURL: (state) => state.listUrl,
  },
  mutations: {},
  actions: {},
};

export {corporate,archivedcorporate};
