import {
  helpers,
  required,
  email,
  minLength,
  maxLength,
  sameAs,
  numeric,
} from "@vuelidate/validators";

const Procedure = {
  namespaced: true,

  state: {
    tableData: {
      header: [
        "SNOMED Code",
        "Description",
        "Start Date",
        "End Date",
        "Location",
        "Note",
        "Negation",
        "Actions",
      ],
    },
    form: {},
    vuelidateExternalResults: {
      form: {
        snomed_code:[],
        snomed_description:[],
        location:[],
      },
    },
    validationRules: {
      form: {
        snomed_code: {
          required: helpers.withMessage("Snomed Code is required", required),
        },
        snomed_description:{
          required: helpers.withMessage("Snomed Description is required", required),
        },
        /*location:{
          required: helpers.withMessage("Location is required", required), 
        }*/
      },
    },
    listUrl: "/patient/:patient_unique_id/cqm-procedure/list",
    list: [],
    edit: 0,
  },
  getters: {
    getTableHeaders: (state) => state.header,
    getTableContents: (state) => state.list,
    getListURL: (state) => state.listUrl,
  },
  mutations: {},
  actions: {},
}

export default Procedure;
