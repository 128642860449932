<template>
	<div class="content-wrapper">
		<div class="container-full">
			<div class="content-header">
				<div class="row justify-content-between">
					<div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xs-6">
						<h1 class="heading1 my-5">Corporate Management</h1>
					</div>
					<div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xs-6 text-sm-end">
						<router-link to="/corporate/archived" class="waves-effect waves-light btn btn-info-light me-1 mb-1">Show Archived</router-link>            
						<router-link to="/corporate/add" class="waves-effect waves-light btn btn-info mb-1"><i class="fa fa-plus fs-14"></i> Corporate</router-link>
					</div>
				</div>
			</div>
			<section class="content">
				<div class="box">
					<div class="box-body">
						<div class="table-responsive">
							<data-table dataType="corporate">
								<template v-slot="slotProps">
									<!-- slot for table data -->
									<td>{{ slotProps.item.corporate_name }}</td>
									<td>
										{{ `${slotProps.item.team_leader}` }}
									</td>
									<td>{{ slotProps.item.main_contact_name }}</td>
									<td>{{ slotProps.item.main_contact_phone }}</td>
									<td>
										<div class="d-flex">
											<Popper arrow content="Corporate Campaign" :hover="true" class="helptips cursor-pointer">
												<a
												@click="corporate_campaign(slotProps.item.id)"
												href="#"
												class="waves-effect waves-circle btn btn-circle btn-info btn-xs me-1" > <i class="fa fa-square"></i></a>
											</Popper>
											<Popper arrow content="Edit corporate" :hover="true" class="helptips cursor-pointer">
												<a
												@click="editItem(slotProps.item.id)"
												href="#"
												class="waves-effect waves-circle btn btn-circle btn-info btn-xs me-1" ><i class="fa fa-pencil"></i></a>
											</Popper>
											<Popper arrow content="Delete corporate" :hover="true" class="helptips cursor-pointer">
												<a
												@click="deleteItem(slotProps.item.id)"
												href="#"
												class="waves-effect waves-circle btn btn-circle btn-danger btn-xs me-1"><i class="fa fa-trash"></i></a>
											</Popper>
										</div>
									</td>
								</template>
							</data-table>
						</div>
					</div>
				</div>
			</section>
		</div>
		<div id="myContentModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
			<div class="modal-dialog modal-xl" style="max-width: 1140px;">
			 <div class="modal-content">
				<div class="modal-header modal-header-h">
				 <h2 class="modal-title m-0">Corporate Campaign</h2>
				 <button type="button" class="btn-close btn-xs text-white" data-bs-dismiss="modal" aria-label="Close" @click="closeModal()" style="background-color: #fff;"></button>
			 </div>
			 <div
			 class="panel-group panel-group-simple panel-group-continuous mt-0 mb-0"
			 id="category-1"
			 aria-multiselectable="true"
			 role="tablist">
			 
			 <div class="modal-body">
				<div class="panel-heading" id="question-1" role="tab">
					<h3 style="font-weight: 500;border-bottom: 1px solid #dbdddf; color: #0265a0; padding-bottom: 10px;">Campaign Data</h3>
						</div>
						<div
						class="panel-collapse collapse show"
						id="answer-1"
						aria-labelledby="question-1"
						role="tabpanel"
						data-bs-parent="#category-1"
						>
						<div class="panel-body b-0">
							<div class="row mt-2">
								<div class="col-md-6 col-lg-4">
									<div class="row form-group">
										<label class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end">Office <small class="text-danger asterisksign">*</small></label>
										<div class="col-12 col-sm-6 col-md-8 col-lg-8 col-xl-8">
											<select class="form-control"  v-model="v$.form.office_id.$model" @change="changeOffice">
												<option value="" selected disabled>Select Office</option>
												<option v-for="(item,index) of selectOffice" :value="item.id" :key="index">
													{{item.name}}
												</option>
											</select>
											<div class="text-danger" v-for="(error, index) of v$.form.office_id.$errors" :key="index">
												{{ error.$message }}
											</div>
										</div>
									</div>  
								</div>

								<div class="col-md-6 col-lg-4">
									<div class="row form-group">
										<label class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0  text-sm-end">Staff <small class="text-danger asterisksign">*</small></label>
										<div class="col-12 col-sm-6 col-md-8 col-lg-8 col-xl-8">
											<select class="form-control" v-model="v$.form.staff_id.$model">
												<option value="" selected disabled>Select Staff</option>
												<option v-for="(item,index) of selectStaff" :value="item.id" :key="index">
													{{item.name}}
												</option>
											</select>
											<div class="text-danger" v-for="(error, index) of v$.form.staff_id.$errors" :key="index">
												{{ error.$message }}
											</div>
										</div>
									</div>
								</div>
								<div class="col-md-6 col-lg-4">
									<div class="row form-group">
										<label class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end">Room <small class="text-danger asterisksign">*</small></label>
										<div class="col-12 col-sm-6 col-md-8 col-lg-8 col-xl-8">
												<div class="availdate">
												 <ejs-multiselect id='roomdata' :dataSource='officeRooms' :placeholder='groupWaterMark'
												  :fields='groupFields'
												  @select="onRoomSelect"></ejs-multiselect>
												</div>
											<div class="text-danger" v-for="(error, index) of v$.form.room_id.$errors" :key="index">
											{{ error.$message }}
										</div>
									</div>
								</div>
							</div>
							</div>

							<div class="row mt-2">
								<div class="col-md-6 col-lg-4">
									<div class="form-group row">
										<label class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end" for="">Select Date <small class="text-danger asterisksign">*</small></label>
										<div class="col-12 col-sm-6 col-md-8 col-lg-8 col-xl-8 availdate">
											<ejs-datepicker :placeholder="waterMark" v-model="v$.form.date.$model" @change="selectDate" id="selectdate"
											name="selectdate" :strictMode='strict' :min='this.currentdate'
											></ejs-datepicker>
											<div class="text-danger" v-for="(error, index) of v$.form.date.$errors" :key="index">
												{{ error.$message }}
											</div>
										</div>
									</div>
								</div>
								<div class="col-md-6 col-lg-4">
									<div class="form-group row">
										<label class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end">Start Time <small class="text-danger asterisksign">*</small></label>
										<div class="col-12 col-sm-6 col-md-8 col-lg-8 col-xl-8">
											<select class="form-control" v-model="v$.form.start_time.$model" @change="selectSlot()" id="start_time">
												<option value="" selected disabled>Select Start Time</option>
												<option v-for="(item,index) of select_startTime" :value="item" :key="index">
													{{item}}
												</option>
											</select>
											<div class="text-danger" v-for="(error, index) of v$.form.start_time.$errors" :key="index">
												{{ error.$message }}
											</div>
										</div>
									</div>
								</div>

								<div class="col-md-6 col-lg-4">
									<div class="form-group row">
										<label class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end">End Time <small class="text-danger asterisksign">*</small></label>
										<div class="col-12 col-sm-6 col-md-8 col-lg-8 col-xl-8">
											<select class="form-control" v-model="v$.form.end_time.$model" @change="selectSlot()" id="end_time">
												<option value="" selected disabled>Select End Time</option>
												<option v-for="(item,index) of select_endTime" :value="item" :key="index">
													{{item}}
												</option>
											</select>
											<div class="text-danger" v-for="(error, index) of v$.form.end_time.$errors" :key="index">
												{{ error.$message }}
											</div>
										</div>
									</div>
								</div>

								
							</div>

							<div class="row mt-2">
								<div class="col-md-6 col-lg-4">
									<div class="form-group row">
										<label class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end">No. of Slot <small class="text-danger asterisksign">*</small></label>
										<div class="col-12 col-sm-6 col-md-8 col-lg-8 col-xl-8">
											<input type="text" class="form-control" v-model="this.slot" :isRequired="true" :readonly="true">
										</div>
									</div>
								</div>
								<div class="col-md-6 col-lg-4">
									<div class="form-group row">
										<label class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end">Campaign</label>
										<div class="col-12 col-sm-6 col-md-8 col-lg-8 col-xl-8">
											<input type="text" class="form-control" :isRequired="true" v-model="this.form.campaign_name" />
										</div>
									</div>
								</div>
							</div>

							<div class="list-box-95-h p-10">
								<div class="mt-2">
									<h4 style="font-weight: bold;">Place of Service</h4>
								</div>

								<div class="row mt-2">
									<div class="col-md-6 col-lg-4">
										<div class="form-group row">
											<label class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end">Name <small class="text-danger asterisksign">*</small></label>
											<div class="col-12 col-sm-6 col-md-8 col-lg-8 col-xl-8">
												<input type="text" class="form-control" v-model="v$.form.name.$model">
												<div class="text-danger" v-for="(error, index) of v$.form.name.$errors" :key="index">
													{{ error.$message }}
												</div>
											</div>
										</div>
									</div>
									<div class="col-md-6">
										<div class="form-group">
											<input  class="filled-in chk-col-info"  type="checkbox" id='is_edit_patient' :value="form.is_edit_patient"  @click="selectClick">
											<label class="form-check-label" for="is_edit_patient" style="display:inline-block;"> Allow the patient to choose an apartment number.</label>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-md-6 col-lg-4">
										<div class="form-group row">
											<label class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end">Street <small class="text-danger asterisksign">*</small></label>
											<div class="col-12 col-sm-6 col-md-8 col-lg-8 col-xl-8">
												<input type="text" class="form-control" v-model="v$.form.street.$model">
												<div class="text-danger" v-for="(error, index) of v$.form.start_time.$errors" :key="index">
													{{ error.$message }}
												</div>
											</div>
										</div>
									</div>

									<div class="col-md-6 col-lg-4">
										<div class="form-group row">
											<label class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end">Apartment</label>
											<div class="col-12 col-sm-6 col-md-8 col-lg-8 col-xl-8">
												<input type="text" class="form-control" v-model="form.apartment">
											</div>
										</div>
									</div>

									<div class="col-md-6 col-lg-4">
										<div class="form-group row">
											<label class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end">Zipcode <small class="text-danger asterisksign">*</small></label>
											<div class="col-12 col-sm-6 col-md-8 col-lg-8 col-xl-8">
												<input type="text" class="form-control" v-model="v$.form.zipcode.$model" @blur="loadcountry">
												<div class="text-danger" v-for="(error, index) of v$.form.zipcode.$errors" :key="index">
													{{ error.$message }}
												</div>
											</div>
										</div>
									</div>
								</div>

								<div class="row">
									<div class="col-md-6 col-lg-4">
										<div class="form-group row">
											<label class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end">City <small class="text-danger asterisksign">*</small></label>
											<div class="col-12 col-sm-6 col-md-8 col-lg-8 col-xl-8">
												<input type="text" class="form-control" v-model="v$.form.city.$model">
												<div class="text-danger" v-for="(error, index) of v$.form.city.$errors" :key="index">
													{{ error.$message }}
												</div>
											</div>
										</div>
									</div>

									<div class="col-md-6 col-lg-4">
										<div class="form-group row">
											<label class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end">State <small class="text-danger asterisksign">*</small></label>
											<div class="col-12 col-sm-6 col-md-8 col-lg-8 col-xl-8">
												<select class="form-control" v-model="v$.form.state_id.$model">
													<option value="" selected disabled>Select State</option>
													<option v-for="(item,index) of states" :value="item.id" :key="index">
														{{item.name}}
													</option>
												</select>
												<div class="text-danger" v-for="(error, index) of v$.form.state_id.$errors" :key="index">
													{{ error.$message }}
												</div>
											</div>
										</div>
									</div>

									<div class="col-md-6 col-lg-4">
										<div class="form-group row">
											<label class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end">Country <small class="text-danger asterisksign">*</small></label>
											<div class="col-12 col-sm-6 col-md-8 col-lg-8 col-xl-8">
												<select class="form-control" v-model="v$.form.country_id.$model">
													<option value="" selected disabled>Select Country</option>
													<option v-for="(item,index) of this.countries" :value="item.id" :key="index">
														{{item.name}}
													</option>
												</select>
												<div class="text-danger" v-for="(error, index) of v$.form.country_id.$errors" :key="index">
													{{ error.$message }}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					
						<div class="panel-heading" id="question-2" role="tab">
							<h3 style="font-weight: 500;border-bottom: 1px solid #dbddde;color: #0265a0;padding-bottom: 10px;">Services</h3>
						</div>
						<div
							class="panel-collapse collapse show"
							id="answer-2"
							aria-labelledby="question-2"
							role="tabpanel"
							data-bs-parent="#category-1"
							>
							<div class="panel-body" style="border-top: none;"> 
								<div class="text-danger" v-for="(error, index) of v$.form.customProcedureArr.$errors" :key="index">
									{{ error.$message }}
								</div>
								<div class="row align-items-center list-box-95-h p-10" v-for="(customProcedureitem,index) of     customProcedure" value="item. id" :key="index">
									<b>{{index}}</b>
									<div class="col-12 col-sm-6 col-md-6 col-lg-4 mt-2" v-for="(item,index) of customProcedureitem" :value="item.code_id" :key="index">
										 <div class="">
											<div class="controls col-6" style="float:left;"> 
												<input  class="filled-in chk-col-info" @click="select($event,item.code_id,item.price,item.code)" type="checkbox" :id='"product_"+item.code_id' :value="item.code">
												<label class="form-check-label" :for="'product_'+item.code_id" style="display:inline-block;"> {{item.code}}</label>
											</div>
											<div class="controls col-6" style="float:left;">
												<div class="input-group mb-3">
													<div class="input-group-prepend">
														<span class="input-group-text">$</span>
													</div>
													<input type="text" :id="item.price" class="form-control" style="display:inline-block;" v-model="item.price">
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<!-- </div> -->
						<!-- </div> -->
					</div>
				</div>


				<!-- <div class="col-md-6 col-lg-4">
					 <div class="row form-group">
							<label class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end">Provider <small class="text-danger asterisksign">*</small></label>
							<div class="col-12 col-sm-6 col-md-8 col-lg-8 col-xl-8">
								<select class="form-control"  v-model="v$.form.provider_id.$model" required>
									<option value="" selected disabled>Select Provider</option>
									<option v-for="(item,index) of selectProvider" :value="item.id" :key="index">
										{{item.name}}
									</option>
								</select>
									<div class="text-danger" v-for="(error, index) of v$.form.provider_id.$errors" :key="index">
								{{ error.$message }}
							</div>
							</div>
						</div>
						</div> -->
				 			<!-- <div class="form-group row my-2">   -->

							<!-- <div class="col-md-6 col-lg-4">
								<div class="row form-group">
									<label class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end">Room <small class="text-danger asterisksign">*</small></label>
									<div class="col-12 col-sm-6 col-md-8 col-lg-8 col-xl-8">
										<select class="form-control" v-model="v$.form.room_id.$model">
											<option value="" selected disabled>Select Room</option>
											<option v-for="(item,index) of officeRooms" :value="item.id" :key="index">
												{{item.name}}
											</option>
										</select>
										<div class="text-danger" v-for="(error, index) of v$.form.room_id.$errors" :key="index">
										{{ error.$message }}
									</div>
									</div>
								</div>
							</div> -->

							<!-- <div class="mt-2"> -->

								<!-- </div> -->
								
								<!-- </div> -->
						<!-- <div v-if="this.slot">
							<input-field title="Number of Slot" 
							v-model="this.slot"  
							:isRequired="true" 
							:readonly="true">
							</input-field>
						</div> -->
						 <!-- <div class="mt-2">
								
							</div>
						 <div class="mt-2">
								
							</div>
						 <div class="mt-2">
								
						 </div> -->
						 <div class="modal-footer">
							<button title="Save Draft" class="addpatientbtn btn-info b-0" @click="save">Save</button>
							<button type="button" class="addpatientbtn btn-danger b-0" data-bs-dismiss="modal" @click="closeModal()">Close</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</template>

	<style scoped>
		.list-box-95-h { border-radius: 5px; background-color: #e1edf9;margin: 0 auto 20px; }
		.modal-header-h { background-color: #0265A0; color: #fff;}
	</style>
<script>
import axios from "@/scripts/axios.js";
import dataTable from "@/components/base/DataTable.vue";
import submitMixins from "@/scripts/mixins/submitMixins.js";
import permissionMixins from "@/scripts/mixins/permission.js";
import InputField from "@/components/base/formFields/InputField.vue";
import SelectBoxStatic from "@/components/base/formFields/SelectBoxStatic.vue";
import MultiSelectBox from "@/components/base/formFields/multiSelectBox.vue";
import Select2 from "@/components/base/formFields/select2.vue";
import SelectBox from "@/components/base/formFields/selectBox.vue";
import SelectBox2 from "@/components/Appointments/formFields/selectBox.vue";
import LifeStyleHeader from "@/components/Lifestyle/LifeStyleHeader.vue";
import Popper from "vue3-popper";
import { SmartTagz } from "smart-tagz";
import useVuelidate from "@vuelidate/core";
import moment from 'moment';
import { MultiSelectPlugin } from "@syncfusion/ej2-vue-dropdowns";
import {
	helpers,
	required,
	email,
	minLength,
	maxLength,
	sameAs,
	numeric,
	
} from "@vuelidate/validators";

		export default {
			name: "Home",
			mixins: [submitMixins,permissionMixins],
			components: { dataTable, Popper ,InputField,SelectBoxStatic,SelectBox,SelectBox2,SmartTagz,LifeStyleHeader,MultiSelectBox,Select2,MultiSelectPlugin},
			setup: () => {
				return { v$: useVuelidate() };
			},
			computed: {
				form() {
					return this.$store.state.CorporateCampaign.form;
				},
				vuelidateExternalResults() {
					return this.$store.state.CorporateCampaign.vuelidateExternalResults;
				},
				validationRules() {
					return this.$store.state.CorporateCampaign.validationRules;
				},
			},
			validations() {
				return this.$store.state.CorporateCampaign.validationRules;
			},
			data(){
				return {
					
					officeRooms:[],
					selectProvider:[],
					selectStaff:[],
					selectOffice:[],
					coordinator_staff_id:'',
					waterMark : 'Select a date',
					slot:'',
					states:[],
					countries:[],
					select_startTime:[],
					select_endTime:[],
					customProcedure:[],
					dateFormat: 'MM-dd-yyyy',
					currentdate:new Date(),
					groupFields: {text: 'name', value: 'id' },
					groupWaterMark: 'Select Rooms',
				}
			},
			created() {
				axios.get("json_dropdown/getIVprovider")
				.then((response) => {
					this.selectProvider = response.data.data; 
				});
				axios.get("json_dropdown/getIVoffice")
				.then((response) => {
					this.selectOffice = response.data.data;
				});
				axios.get("purecustom-procedure")
				.then((response) => {
					this.customProcedure = response.data.data;
					
				});
			},
			mounted(){
				var today = new Date();
				var year = today.getFullYear();
				var mes = today.getMonth()+1;
				var dia = today.getDate();
				this.currentdate =mes+"/"+dia+"/"+year;
				axios.get("/json_autocomplete/country").then(
					(response) =>{
						this.countries = response.data.data;
					});
				axios.post("/json_autocomplete/"+this.form.country_id+"/state").then(
					(response) =>{
						this.states = response.data.data;
						
					});
			},
			methods: {
				loadcountry(){
					if(this.form.zipcode && this.form.zipcode.length > 3 ){
						// console.log(this.form.zipcode);
						axios.post("json_dropdown/state-city-auto-populations",{zip_code:this.form.zipcode})
						.then((response) => {
							// console.log(response);
							this.form.city=response.data.data.City;
							// console.log(this.form.city);
							this.form.state_id=response.data.data.id;
							// console.log(this.form.state_id);
						});
						
						
					}
					else{
						this.form.city= null;
						this.form.state_id=null;
					}
				},
				onRoomSelect(event){
					this.form.room_id.push(event.itemData.id);
				},
				select(event,id,price,product){
					var custom_procedure_code=product;
					if (event.target.checked) {

						
						if(this.form.customProcedureArr.length>0){
							
							for (let i = 0; i < this.form.customProcedureArr.length; i++) {
								
							
									if (this.form.customProcedureArr[i].custom_procedure_id!=id) 
									{
											this.form.customProcedureArr.push({'custom_procedure_id':id,'custom_procedure_code':product,'custom_procedure_price':price,'custom_procedure_qty':1});
											break;
											
									}
									
							}
						}else{
							
							this.form.customProcedureArr.push({'custom_procedure_id':id,'custom_procedure_code':product,'custom_procedure_price':price,'custom_procedure_qty':1});
						}
					}
					else{
					
						for (let i = 0; i < this.form.customProcedureArr.length; i++) {
							if (this.form.customProcedureArr[i].custom_procedure_id==id) 
									{
											this.form.customProcedureArr.splice(i, 1);
											break;
											
									}
									
							}
							
						} 
						
				},
				save(){
					
					this.postForm("/corporate/campaign/create", "CorporateCampaign");
					
				},
				selectClick(event){
					if (event.target.checked) {
						this.form.is_edit_patient='1';
					}
					else{
						this.form.is_edit_patient='0';
					}
				},
				formPostSuccess(data) {
					$('#myContentModal').modal('hide');	
					this.moshaToast("Record added successfully", "success");
					// myInterval = setInterval(location.reload(), 10000);
					// clearInterval(myInterval);
					
				},
				
				corporate_campaign(id){
				this.form.corporate_id=id;
				
				$('#myContentModal').modal('show');	
			},
			closeModal(){
				$('#myContentModal').modal('hide');
				location.reload();
			},
			parseTime( t ){
				var d = new Date();
				var time = t.match( /(\d+)(?::(\d\d))?\s*(p?)/ );
				d.setHours( parseInt( time[1]) + (time[3] ? 12 : 0) );
				d.setMinutes( parseInt( time[2]) || 0 );
				return d;
			},
			parseIndex(time)
			{
			let select_time=[];
			let st_time=[];
			let final='';
			select_time = time.split(" ");
			if (select_time[1]=="am") {
				st_time=select_time[0].split(":");
				if (st_time[1]=="30") {
					final= parseInt(st_time[0])+0.5;
				}
				else{
					final= parseInt(st_time[0]);
				}   
			}
			else{
				st_time=select_time[0].split(":");
				if (st_time[1]=="30") {
					if (st_time[0]=="12") {
						final= parseInt(st_time[0])+0.5;     
					}else{
						final= 12+parseInt(st_time[0])+0.5;
					}
				}
				else{
					if (st_time[0]=="12") {
						final= parseInt(st_time[0]);
					}else{
						final= 12+parseInt(st_time[0]);
					}
				} 
			}
			return final
		},
		changeOffice(){
			var multiObj = document.getElementById("roomdata").ej2_instances[0]; 
            multiObj.value = null;
			axios.post("json_dropdown/room",{ 'office_id':this.form.office_id})
			.then((response) => {
			this.officeRooms = response.data.data;
			axios.post("json_dropdown/getIVstaff",{ 'office_id':this.form.office_id})
				.then((response) => {
					this.selectStaff = response.data.data;  
				});
			
		});
		
		},
		selectSlot()
		{
		this.start_time=$("#start_time").val();
		let len = this.select_startTime.length;
		const index = this.select_startTime.indexOf(this.start_time);
		let select_endTime=[];
		for(var i = index+1; i < len ;i++){
			this.select_endTime[i] = this.select_startTime[i];
		}
		this.select_endTime = this.select_endTime.filter(function (el) {
		return el != null;
	});
		let final_arr=[];
		this.date=this.parseTime(this.start_time);
		let slot_arr=[];
		var duration = '';
		for(var i = 0; i < len ;i++){
		final_arr[i] = this.parseIndex(this.select_startTime[i]);  
	}
	if ($("#end_time").val()!=null) {
		// console.log($("#end_time").val());
		this.end_time=$("#end_time").val();
	}
	if (this.start_time!= ''&& this.end_time!='') {
		const start_index = final_arr.indexOf(this.parseIndex(this.start_time));
		const end_index = final_arr.indexOf(this.parseIndex(this.end_time));
		
		for(var i = start_index; i <= end_index ;i++){
			slot_arr[i] =  final_arr[i]; 
		}
		slot_arr =slot_arr.filter(function (el) {
			return el != null;
		});
		len =  slot_arr.length;
		let pos=0;
		for(var i = 0; i < len ;i++){
			duration= slot_arr[i+1]- slot_arr[i];
			if (duration>0.5) {
			pos++;
		}
	}
	
	this.slot=(end_index-start_index-pos)*(this.form.room_id.length);
	
}

},
selectDate(){
this.date=$("#selectdate").val();
this.form.date=this.date;
var daterange =this.date.split(' - ');
var sch_date = moment(String(daterange[0])).format('YYYY-MM-DD');
axios.post('/provider/schedule',{'staff_id':this.form.staff_id,'date':sch_date,'officeId':this.form.office_id}).then((response)=>{
	this.select_startTime=response.data.data;
	
})
},
editItem(id) {
this.$router.push({ name: "Corporateedit", params: { id } });
},
deleteItem(id) {
this.$swal
.fire({
	title: '<h2 class="mt-0">Are you sure?</h2>',
	text:
	"You want to delete these record?",
	icon: "warning",
	dangerMode: true,
	showCancelButton: true,
	confirmButtonColor: "#ee3158",
	cancelButtonColor: "#bdbdbd",
	confirmButtonText: "Yes, delete it!",
})
.then((result) => {
	if (result.isConfirmed == true) {
		axios
		.post(`/corporate/delete`, { id: id })
		.then((response) => {
			this.$swal
			.fire(
				"Deleted!",
				"Corporate has been deleted.",
				"success"
				)
			.then((result) => {
				if (result.isConfirmed == true) {
					location.reload();
				}
			});
		});
	}
});
},
},
};
</script>
