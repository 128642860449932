const Notification = {
  namespaced: true,

  state: {
    tableData: {
      header: [
        "Date Time",
        "Notification Type",
        "Notification Message",
        "Status",
      ],
    },
    listUrl: "view-notification",
    
  },
  getters: {
    getTableHeaders: (state) => state.header,
    getTableContents: (state) => state.list,
    getListURL: (state) => state.listUrl,
  },
  mutations: {},
  actions: {},
}

export default Notification;