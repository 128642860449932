<template>
    <section class="content" style="padding: 0 1.5rem 0px 1.5rem;">
        <div class="row justify-content-between">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" id="patient_info" style="padding-right: 0; padding-left: 0; position: fixed; width: calc(60% + 4px - 3rem); z-index: 1111;">
                <div class="patiboxdetail">
                    <div class="box patientsdetails">
                        <div class="row">
                            <div class="col-12 col-sm-8 col-md-8 col-lg-7 col-xl-7">
                                <h2 class="patientname float-start me-5" style="font-size: 1.6rem; margin-bottom: 0;">{{ first_name }} {{ last_name }}</h2>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <div class="row">
                                    <div class="col-3 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                        <div class="patientimg mb-0">
                                            <div class="inline-block helptips">
                                                <div class="effect-grid">
                                                    <p class="mb-0" v-html="patientAvatar" v-if="patientAvatar!=''"></p>
                                                    <p class="mb-0" v-else>
                                                        <img src="images/patient-avtar.png" alt="Patient Photo" class="img-fluid"/>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-9 col-sm-12 col-md-9 col-lg-9 col-xl-9">
                                        <div class="patientditailist mb-0">
                                            <p style="font-size: 1rem;">
                                                <b>Gender:</b>
                                                <span>{{ gender }}</span>
                                            </p>
                                            <p style="font-size: 1rem;">
                                                <b>Age:</b>
                                                <span>{{ age }}</span>
                                            </p>
                                            <p style="font-size: 1rem;">
                                                <b>DOB:</b>
                                                <span>{{ dob }}</span>
                                            </p>
                                            <p style="font-size: 1rem;">
                                                <b>Phone:</b>
                                                <span>{{ phone }}</span>
                                            </p>
                                            <p style="font-size: 1rem;">
                                                <b>Email:</b>
                                                <span>{{ email }}</span>
                                            </p>
                                            <p style="font-size: 1rem;">
                                                <b>Address:</b>
                                                <span>{{ address }}</span>
                                            </p>
                                            <p style="font-size: 1rem;">
                                                <b>{{heightTitle}}:</b>
                                                <span>{{ heightValue }}</span>
                                            </p>
                                            <p style="font-size: 1rem;">
                                                <b>{{weightTitle}}:</b>
                                                <span>{{ weightValue }}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <clinical-notes-list :patientId=patientId :patientUId=patientUId :margin_top=margin_top :key=pat_key @updateKey="update_key" />
            <div class="box p-15">
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6">
                        <p class="fs-18 fw-400 mb-20">Primary Insurance</p>
                        <div class="row" v-if="this.primary">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 text-center" v-if="this.primary.front_image != ''">
                                <div class="position-relative">
                                    <img  v-if="this.primary.front_extension == 'png' || this.primary.front_extension == 'jpeg' || this.primary.front_extension == 'jpg'" :src="this.primary.front_image" class="img-fluid rounded5 zooms b-1" >
                                    <a v-if="this.primary.front_extension == 'png' || this.primary.front_extension == 'jpeg' || this.primary.front_extension == 'jpg'" href="javascript:void(0)" @click="imgpath(this.primary.front_image)" data-bs-toggle="modal" data-bs-target="#viewimg" class="bg-white py-5 px-10 rounded fs-18 position-absolute b-1" style="right: 0;bottom: 0px;">
                                        <i class="fa fa-search-plus"></i>
                                    </a>
                                </div>
                                <div class="position-relative" v-if="this.primary.front_extension == 'pdf'">
                                    <img  src="/images/pdf_icon.png" class="img-fluid rounded5" style="width:80%;" />
                                    <a v-if="this.primary.front_extension == 'pdf'" href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#viewpdf" @click="viewpdf(this.primary.front_image)" class="bg-white py-5 px-10 rounded fs-18 position-absolute b-1" style="right: 0;bottom: 0px;">
                                        <i class="fa fa-search-plus"></i>
                                    </a>
                                </div>
                                <p class="doc-text">Insurance card front</p>
                            </div>

                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 text-center" v-if="this.primary.rear_image != ''">
                                <div class="position-relative">
                                    <img v-if="this.primary.rear_extension == 'png' || this.primary.rear_extension == 'jpeg' || this.primary.rear_extension == 'jpg'" :src="this.primary.rear_image" class="img-fluid rounded5 zooms b-1">
                                    <a v-if="this.primary.rear_extension == 'png' || this.primary.rear_extension == 'jpeg' || this.primary.rear_extension == 'jpg'" href="javascript:void(0)" @click="imgpath(this.primary.rear_image)" data-bs-toggle="modal" data-bs-target="#viewimg" class="bg-white py-5 px-10 rounded fs-18 position-absolute b-1" style="right: 0;bottom: 0px;">
                                        <i class="fa fa-search-plus"></i>
                                    </a>
                                </div>
                                <div class="position-relative" v-if="this.primary.rear_extension == 'pdf'">
                                    <img src="/images/pdf_icon.png" class="img-fluid rounded5  b-1" style="width:80%;" />
                                    <a v-if="this.primary.rear_extension == 'pdf'" href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#viewpdf" @click="viewpdf(this.primary.rear_image)" class="bg-white py-5 px-10 rounded fs-18 position-absolute b-1"  style="right: 0;bottom: 0px;">
                                        <i class="fa fa-search-plus"></i>
                                    </a>
                                </div>
                                <p class="doc-text">Insurance card rear</p>
                            </div>
                            <div class="text-mute mt-10">
                                Insurance card properly visible? 
                                &nbsp;
                                <input type="radio" name="update_card_visibility" value="0" checked="checked" style="opacity: 1; position: unset; left: unset;" @change="changeCardVisibilityStatus($event)" /> Yes
                                <input type="radio" name="update_card_visibility" value="1" style="opacity: 1; position: unset; left: unset;" @change="changeCardVisibilityStatus($event)" /> No  
                            </div>
                            
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </section>
    <!--viewReceipt Modal Start-->
    <div class="modal" id="viewpdf" tabindex="-1">
        <div class="modal-dialog modal-md" style="max-width:75%;">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="btn-close btn-xs" data-bs-dismiss="modal" aria-label="Close" @click="close"></button>
                </div>
                <div class="modal-body">
                    <vue-pdf-viewer height="1050px" :url="document_name_url" v-if="embed=0">
                    </vue-pdf-viewer>
                    <iframe :src="document_name_url" width="100%" height="500px" v-if="embed=1" />
                    <img srd="images/1.gif" v-else> 
                </div>
            </div>
        </div>
    </div>
    <!--viewReceipt Modal end-->
    <!--view Image Modal Start-->
    <div class="modal" id="viewimg" tabindex="-1">
        <div class="modal-dialog modal-xl">
            <div class="modal-content bg-white" style="height: 93vh;">
                <button @click="zoomclose" type="button" class="btn-close btn-xs position-absolute bg-white p-5" data-bs-dismiss="modal" aria-label="Close" style="right: 4px;top: 8px;z-index: 1;"></button>
                <div class="modal-body text-center d-flex align-items-center" style="overflow: hidden;">
                    <img :src="document_name_url" class="img-fluid rounded5 zoom">
                </div>
                <div class="text-center my-5">
                    <a @click="zoomplus" href="javascript:void(0)" class="waves-effect waves-light btn btn-info-light fs-16 btn-xs me-2"><i class="fa fa-search-plus"></i></a>
                    <a @click="zoomminus" href="javascript:void(0)" class="waves-effect waves-light btn btn-info-light fs-16 btn-xs me-2"><i class="fa fa-search-minus"></i></a>
                </div>
            </div>
        </div>
    </div>
    <!--viewImage Modal end-->
</template>

<script>
import axios from "@/scripts/axios.js";
import ClinicalNotesList from "@/components/patients/details/ClinicalNote/Home.vue";

var $this;

function calculateHeight(arg) {
    let patient_info_height = document.getElementById("patient_info").clientHeight;
    patient_info_height = parseInt(patient_info_height) + parseInt(14);
    if(document.getElementById("noteSection")) {
        document.getElementById("noteSection").style.marginTop = patient_info_height+"px";
    } else {
        console.log("noteSection not found");
    }
    $this.margin_top = patient_info_height;
}

export default {
    props:{
        patientUId:String,
        patientId:String,
        appointmentId:String
    },
    components: { ClinicalNotesList },
    data() {
        return {
            patientid: 0,
            patient_u_id: 0,
            first_name: "",
            last_name: "",
            email: "",
            gender: "",
            dob: "",
            phone: "",
            address: "",
            age: "",
            patientAvatar: "",
            scale: 0.5,
            insurancetype: [
                { value: "266", title: "Primary Insurance" },
                { value: "267", title: "Secondary Insurance" },
                { value: "268", title: "Tertiary Insurance" }
            ],
            primary:[],
            document_name_url: "",
            document_id: 0,
            pat_key: 1,
            appointment_id: this.appointmentId,
            heightTitle: 'Height',
            heightValue: '',
            weightTitle: 'Weight',
            weightValue: '',
            margin_top:0,
        };
    },
    computed: {},
    watch: {},
    mounted() {
        calculateHeight("2");
        this.getimg();
    },
    async beforeCreate() {
        $this = this;
        if(this.patientUId) {
            this.patient_u_id = this.patientUId;
            this.patientid = this.patientId;
        } else {
            this.patient_u_id = 0;
            this.patientid = 0;
        }
        axios
        .get("/patient/" + this.patient_u_id)
        .then((response) => {
            let resultData = response.data.data;

            this.first_name = resultData.first_name ? resultData.first_name : "";
            this.last_name = resultData.last_name ? resultData.last_name : "";
            this.email = resultData.email ? resultData.email : "N/A";
            this.dob = resultData.DOB ? resultData.DOB : "N/A";
            this.gender = resultData.sex ? resultData.sex : "N/A";
            this.phone = resultData.cell_phone ? resultData.cell_phone : "N/A";
            this.age = resultData.age ? resultData.age : "N/A";
            this.address = resultData.address ? resultData.address + (resultData.city ? ", "+resultData.city : '') + (resultData.state_sortname ? ", "+resultData.state_sortname : resultData.state_name) + (resultData.zip_code ? " " + resultData.zip_code : '') : "N/A";
            this.getPatientAvatar(resultData.profile_avatar);
            
        }).catch(function (error) {
            
        });

        axios
        .post("/vital/patient-chart", { 'patient_id': this.patientid})
        .then((response) => {
            this.heightTitle = response.data.data[6][1][0].name;
            this.heightValue = response.data.data[6][2] ? response.data.data[6][2] : '';
            this.weightTitle = response.data.data[7][1][0].name;
            this.weightValue = response.data.data[7][2] ? response.data.data[7][2] : '';
        }).catch(function (error) {
            
        });
    },
    methods: {
        async getPatientAvatar(patientAvatar){
            if(patientAvatar!=null)
            {
                const res = await fetch(process.env.VUE_APP_API_BASE_URL+`/patient-avatar/${patientAvatar}`, {
                    method: 'GET',
                    headers: {
                        'Authorization' : 'Bearer '+localStorage.getItem('authToken')
                    }
                });
                if(res){
                    const blob = await res.blob();
                    const urlObject = URL.createObjectURL(blob);
                    this.patientAvatar = (urlObject!='') ? '<img src="'+urlObject+'" alt="Patient Photo" class="img-fluid"/>' : '';       
                }
                else{
                    this.patientAvatar = "";     
                }
            }
            else{
                this.patientAvatar = ""; 
            }
            
        },
        getimg(){
            axios.post("patient/"+this.patientUId+"/documents/insurance-card",{patient_id:this.patientId})
            .then((response) => {
                console.log(response);
                if(response.data.data.primary != null){
                    this.primary.front_image = process.env.VUE_APP_API_BASE_URL+"/stream-image?path="+response.data.data.primary.front_image+"&slang="+response.data.data.primary.clinic_id;
                    this.primary.rear_image = process.env.VUE_APP_API_BASE_URL+"/stream-image?path="+response.data.data.primary.rear_image+"&slang="+response.data.data.primary.clinic_id;    
                    const primary_front = response.data.data.primary.front_image.split(".");
                    const primary_rear = response.data.data.primary.rear_image.split(".");
                    this.primary.front_extension = primary_front[1];                
                    this.primary.rear_extension = primary_rear[1];                
                    this.primary.updated_at = response.data.data.primary.updated_at;
                    this.document_id = response.data.data.primary.id;
                }
                else{
                    this.primary = null;
                }
                console.log(this.primary);
            });
        },
        imgpath(url){
            this.document_name_url = url;    
        },
        zoomclose(){
            this.scale = 1.5;
            $(".zoom").css("transform", "scale("+this.scale+")");
        },
        zoomplus(){
            this.scale = this.scale+0.5;
            $(".zoom").css("transform", "scale("+this.scale+")");
        },
        zoomminus(){
            if(this.scale > 0.5){
                this.scale = this.scale-0.5;
                $(".zoom").css("transform", "scale("+this.scale+")");
            }
        },
        close(){
            this.document_name_url ='';
        },
        viewpdf(url){
            this.document_name_url  = url;
        },
        changeCardVisibilityStatus(event) {
            var flag = event.target.value;
            console.log(flag);
            axios.post("patient/"+this.document_id+"/documents/document-Status-Update",{flag:flag})
            .then((response) => {
                console.log(response);
            });
        },
        update_key() {
            this.pat_key = parseInt(this.pat_key)+1;
            console.log(this.pat_key);
        }
    },
};
</script>
