<template>
  <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-10">
    <div class="authform-modal box">
      <div class="pageheading">
        <h2 class="heading2">{{ title }}</h2>
      </div>
      <form novalidate>
        <input-field
          title="Name"
          v-model="v$.form.name.$model"
          :errors="v$.form.name.$errors"
          :isRequired="true"
        >
        </input-field>

        <div class="form-group row" :class="{ error: v$.form.color_code.$errors.length }">
          <label class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end">Color <small class="text-danger asterisksign" style="display: none;">*</small></label>
          <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3">
            <div class="controls">
              <ejs-colorpicker :value="default_color" id="inline-color-palette" mode="Palette" :modeSwitcher="true" :inline="false" :showButtons="false" :change="onChange"></ejs-colorpicker> 
              <div class="error-msg">
              <ul class="mb-0 p-0 fs-14">
                <li
                  class="d-block"
                  v-for="(error, index) of v$.form.color_code.$errors"
                  :key="index"
                >
                  {{ error.$message }}
                </li>
              </ul>
            </div>
            </div>
          </div>
        </div>

        <input-field title="Reason for Visit" v-model="form.reason_for_visit">
        </input-field>

        <div class="form-group availdate row">
          <label class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end">Duration</label>
          <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3">
            <div class="controls position-relative">
              <ejs-numerictextbox format="###" id="Duration" ref="numeric_instance" :value="duration_value" :step="step" :min="min"></ejs-numerictextbox>
              <!-- <input
                type="number"
                class="form-control"
                min="5"
                max="1200"
                v-model="form.default_duration"
                title="Duration"
                placeholder="Duration"
              /> -->
              <small class="form-text text-muted position-absolute" style="right: 60px;top: 2px;">Minutes</small>
            </div>
          </div>
        </div>
        <select-box
          title="Billing Profile"
          v-model="form.billing_profile_id"
          getDataURL="/json_dropdown/billing-profile"
          :isRequired="false"
        ></select-box>

        <div class="form-group row">
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 text-sm-end">
            <label>Consent Forms</label>
          </div>
          <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3">
            <div class="controls">
              <input
                v-on:click="getconsentforms($event)"
                v-model="form.required_consent"
                :checked="form.required_consent"
                type="checkbox"
                id="checkbox_2"
                value="single"
                class="filled-in chk-col-info"
              />
              <label for="checkbox_2" class="m-0"
                >Check to select consent forms</label
              >
            </div>
          </div>
        </div>
        <div v-show="form.required_consent">
          <multi-select-box
            id="consent_form_ids"
            title=""
            v-model="form.consent_form_ids"
            getDataURL="/json_dropdown/consent-form"
          ></multi-select-box>
        </div>
      </form>
      <div class="row mb-10">
        <div class="col-12 col-sm-12 col-md-12 col-lg-11 col-xl-9 text-center">
          <form-btn
            :title="btnname"
            btnCls="btn-info"
            @click="save(false)"
          ></form-btn>
          <router-link to="/visit-profile" class="waves-effect waves-light btn me-1 btn-danger">Close</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputField from "@/components/base/formFields/InputField.vue";
import ColorCode from "@/components/base/formFields/ColorCode.vue";
import CheckBox from "@/components/base/formFields/checkBox.vue";
import SelectBox from "@/components/base/formFields/selectBox.vue";
import MultiSelectBox from "@/components/base/formFields/multiSelectBox.vue";
import FileUpload from "@/components/base/formFields/fileUpload.vue";
import TextArea from "@/components/base/formFields/textArea.vue";
import FormBtn from "@/components/base/formFields/formBtn.vue";
import Provider from "@/components/base/Provider.vue";

import useVuelidate from "@vuelidate/core";
import submitMixins from "@/scripts/mixins/submitMixins.js";
import axios from "@/scripts/axios.js";

import {
  helpers,
  required,
  email,
  minLength,
  maxLength,
  sameAs,
  numeric,
} from "@vuelidate/validators";

export default {
  mixins: [submitMixins],
  setup: () => {
    return { v$: useVuelidate() };
  },
  components: {
    InputField,
    ColorCode,
    CheckBox,
    SelectBox,
    FileUpload,
    TextArea,
    FormBtn,
    Provider,
    MultiSelectBox,
  },
  computed: {
    form() {
      return this.$store.state.AppointmentProfilesList.form;
    },
    vuelidateExternalResults() {
      return this.$store.state.AppointmentProfilesList.vuelidateExternalResults;
    },
    validationRules() {
      return this.$store.state.AppointmentProfilesList.validationRules;
    },
    title() {
      return this.$route.name === "AppointmentProfilesEdit"
        ? "Edit Visit Profile"
        : "Create Visit Profile";
    },
    btnname() {
      return this.$route.name === "AppointmentProfilesEdit" ? "Update" : "Save";
    },
  },
  async beforeCreate() {
    // clear form data since, the same data is being used for add and edit both
    this.$store.state.AppointmentProfilesList.form = {};
    Object.assign(this.$store.state.AppointmentProfilesList.form);
    if (this.$route.name === "AppointmentProfilesAdd") {
      this.$store.state.AppointmentProfilesList.form.required_consent = 0;
    } else if (this.$route.name === "AppointmentProfilesEdit") {
      if (
        this.$store.state.AppointmentProfilesList.form.id !=
        this.$route.params.id
      ) {
        // If edit, get data from server
        await this.$store.dispatch("getEditData", {
          dataType: "AppointmentProfilesList", // pass the module name
          formType: "form", // pass the propery name
          id: this.$route.params.id,
        });
        this.default_color = this.form.color_code;
        // need to set provider id since, getEdit data give id where update excepts provider_id
      }
    }
    this.duration_value = this.$store.state.AppointmentProfilesList.form.default_duration;
    if (this.$store.state.AppointmentProfilesList.form.required_consent === 0) {
      this.$store.state.AppointmentProfilesList.form.required_consent = false;
    } else {
      this.$store.state.AppointmentProfilesList.form.required_consent = true;
    }
  },
  methods: {    
    onChange: function(args) {
      this.form.color_code = args.currentValue.hex;
    },
    getconsentforms: function(event) {
      if (event.target.checked) {
        this.consent_form = true;
      } else {
        this.consent_form = false;
        this.form.consent_form_ids = "";
      }
    },
    save(next) {
      this.form.default_duration = $('#Duration').val();
      next ? (this.saveAndNext = true) : (this.saveAndNext = false);
      if (this.$route.name === "AppointmentProfilesEdit") {
        this.postForm("/appointment-profile/update", "staff");
      } else {
        this.postForm("/appointment-profile/store", "staff");
      }
    },
    formPostSuccess(data) {
      this.$router.push("/visit-profile");
      if (this.$route.name === "AppointmentProfilesEdit") {
        this.moshaToast("Record updated successfully.", "success");
      } else {
        this.moshaToast("Record added successfully.", "success");
      }
    },
  },
  data() {
    return {
      saveAndNext: false,
      seen: "Save",
      consent_form: false,
      default_color:'#000000',
      duration_value:5,
      min: 5,
      step: 5,
    };
  },
  validations() {
    return this.$store.state.AppointmentProfilesList.validationRules;
  },
  mounted(){
    this.$nextTick(function () {
        if (window.browserDetails.isDevice) {
            document.getElementById("inline-control").classList.add("e-mobile-control");
        }
    });
  }
};
</script>
