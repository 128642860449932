<template>
  <div class="tab-pane active" id="AddRoomsLink" role="tabpanel">
    <div class="patiboxdetail">
      <div class="authform-modal box">
        <div class="requiredinfo">
          <div class="pageheading">
            <h2 class="fw-900 mt-0">Providers</h2>
          </div>
            <div>
              <ol>
                <li v-for="(item, index) in providers" :key="index"
                class="py-3 bb-1 border-secondary" ><b>{{item.full_name}}</b>: Click here to <a 
                :href="'#/provider/edit/scheduling/'+item.id" class="text-decoration-underline" target="_blank">Check Schedule</a>.</li>
              </ol>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import $ from "jquery";
  import axios from "@/scripts/axios.js";
  import submitMixins from "@/scripts/mixins/submitMixins.js";

  export default {
    mixins: [submitMixins],
    components: {
    },
    mounted() {
      // console.log("555");
      this.getProviderSchedule();
    },
    computed: {},
    data() {
      return {
        providers : [],
      }
    },
    methods: {
      getProviderSchedule() {
        axios.post("office/" + this.$route.params.id + "/provider")
        .then((response) => {
          this.providers = response.data.data;
        });
      }
    },
    beforeCreate(){  
    }
  };
</script>