<template>
  <div class="content-wrapper">
    <div class="container-full">
      <section class="content">
        <div class="row justify-content-between">
          <!-- sidebar -->
          <the-side-bar></the-side-bar>
          <!-- content -->
          <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-10">
            <div class="tab-content">
              <div class="authform-modal box">
              <component
                :is="$store.state.addProviderComp"
                :key="Math.floor(Math.random() * 20)"
              ></component>
            </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import TheSideBar from "@/components/provider/add/TheSidebar.vue";
import AddProviderHome from "@/components/provider/add/Home.vue";
import AddProviderScheduling from "@/components/provider/add/Scheduling.vue";
import AddProviderBilling from "@/components/provider/billing/Home.vue";
import AddAccessPermission from "@/components/provider/permission/Home.vue";

export default {
  components: { TheSideBar, AddProviderScheduling, AddProviderHome, AddProviderBilling, AddAccessPermission },
  data() {
    return {};
  },
  watch: {},
  created() {
    console.log(this.$route.name);
    if (this.$route.name === "ProviderEditScheduling") {
      this.$store.state.addProviderComp = "AddProviderScheduling";
    }
    else if (this.$route.name === "ProviderBilling"){
      this.$store.state.addProviderComp = "AddProviderBilling";
    }
    else if (
      this.$route.name === "ProviderEdit" ||
      this.$route.name === "ProviderAdd"
    ) {
      this.$store.state.addProviderComp = "AddProviderHome";
    }
    else if (this.$route.name === "ProviderAccessPermission"){
      this.$store.state.addProviderComp = "AddAccessPermission";
    }
  },
  methods: {},
};
</script>
