<template>
	<div class="content-wrapper">
		<div class="container-full">
			<div class="content-header">
				<div class="row justify-content-between">
					<div class="col-12 col-sm-6 col-md-6 col-lg-8">
						<h1 class="heading1 mt-0">Credit Card Authorization Report 
							<a href="#" data-bs-toggle="modal" data-bs-target="#rectangledocument" title="Reference of Knowledge Base" v-on:click.prevent="reset()"> <i class="fa fa-question-circle fs-20"></i></a>
						</h1>
					</div>
					<div class="col-12 col-sm-6 col-md-6 col-lg-4 text-sm-end">
						<a href="javascript:void(0)" class="waves-effect waves-light btn btn-info me-1" data-bs-toggle="modal" data-bs-target="#importe_csv">Import CSV</a>
						<button class="b-0 waves-effect waves-light btn btn-info" @click="sendemail"> Send Mail </button>
					</div>
				</div>
			</div>
			<!--document model start-->
			<div id="rectangledocument" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
				aria-hidden="true">
				<div class="modal-dialog modal-md">
					<div class="modal-content">
						<div class="modal-header">
							<h3 class="modal-title fw-900 mt-0">Reference of Knowledge Base</h3>
						</div>
						<div class="modal-body overflow-auto" style="max-height: 350px;">
							<table class="table table-bordered">
								<thead>
									<tr>
										<th scope="col">No.</th>
										<th scope="col">Knowledge Base Articles</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>1</td>
										<td> <a :href="path + 'how-to-check-whether-the-patient-s-credit-card-is-authorized-in-pureehr'"
												target="_blank">How To Check Whether The Patient's Credit Card Is
												Authorized In PureEHR?</a>
										</td>
									</tr>
								</tbody>
							</table>
							<Search :key="searchKey"></Search>
						</div>
						<div class="modal-footer">
							<button type="button" data-bs-dismiss="modal" aria-label="Close"
								class="waves-effect waves-light btn btn-danger">Close</button>
						</div>
					</div>
				</div>
			</div>
			<!--document model end-->
			<!--show email template start-->
			<div class="modal fade" id="showemailtemplate" tabindex="-1" role="dialog"
				aria-labelledby="exampleModalLongTitle" aria-hidden="true">
				<div class="modal-dialog modal-xl">
					<div class="modal-content">
						<div class="modal-header">
							<h3 class="modal-title fw-900 mt-0">Email Template</h3>
							<button type="button" data-bs-dismiss="modal" aria-label="Close"
								class="btn-close btn-xs"></button>
						</div>
						<div class="modal-body">
							<div>
								<h4 class="fw-600 mt-0 fs-20">To {{ this.patinet_name }},</h4>
								<p class="fs-14 l-h-20">We would like to thank you for being a part of the Pure Wellness
									Medical family.
									In an effort to continue to improve patient satisfaction, Pure Wellness Medical has
									changed our credit
									card processing company. Please use the following link to input your preferred and
									current credit card
									information. By responding to this email, you are CONFIRMING that you would like
									Pure Wellness Medical
									to use the credit card entered for all purchases going forward.</p>
								<p class="fs-14 l-h-20">If you have already registered your credit card information to
									our new platform,
									please ignore this email.</p>
							</div>
							<div class="text-center">
								<a href="javascript:void(0)" class="waves-effect waves-light btn btn-info mb-10">Authorize
									Now</a>
								<p class="mb-1 fs-14 l-h-20">Or Copy and paste the below URL into your browser.</p>
								<p class="fs-14 l-h-20">The link will expire in the next 48 hours.</p>
							</div>
							<div class="bg-lightest p-10 mb-10">
								<p class="mb-1 fw-600 d-block">Respectfully,</p>
								<p class="mb-0 fs-14 l-h-20"> Dr. David P. Suarez <br> Ph: 855-679-7873 <br> Pure
									Wellness Medical <br>
									Optimize Your Life <br> * Live Pure * Be Well * <br> Hormone Replacement Therapy
									Specialist <br>
									Integrative, Functional & Regenerative Medicine</p>
							</div>
							<div>
								<p class="mb-1 fs-14 fw-500">ELECTRONIC MAIL CONFIDENTIAL NOTICE:</p>
								<p class="fs-12 l-h-n mb-1">This electronic message and any attached files may be
									confidential or
									privileged and intended solely for the intended recipient(s). If you are not the
									named recipient you,
									should you be hereby notified that any disclosure, copying, distribution or use of
									any information of
									this message is strictly prohibited. If you have received this message in error or
									are not the
									intended recipient, please notify us immediately by contacting the sender at the
									electronic mail
									address noted above, in addition to deleting and destroying all copies of this
									message. Thank You</p>
								<p class="fs-12 l-h-n mb-1">Any views or opinions expressed in this email are those of
									the author and do
									not represent those of Dr. David Suarez, his staff, and Pure Wellness Medical.
									Warning: Although
									precautions have been taken to make sure no viruses are present in this email; the
									company cannot
									accept responsibility for any loss or damage that arise from the use of this email
									or attachments.
									This may also violate HIPPA laws to forward or copy.</p>
								<p class="fs-12 l-h-n mb-1">Physicians will only prescribe treatment if it is deemed a
									necessity -
									verified through a physical examination, blood work and a patient's medical history.
									Dr. David Suarez,
									his staff, and Pure Wellness Medical, affiliate physicians expressly refuse to
									prescribe treatment for
									performance enhancement, bodybuilding and anti-aging purposes. Agreeing to blood
									work does not
									automatically result in clinical necessity or a resulting prescription.</p>
								<p class="fs-12 l-h-n mb-1">The contents of this web page are not intended to be and
									should not be
									treated as medical advice. Please consult with your health care provider before
									starting any treatment
									program.</p>
							</div>
						</div>
						<div class="modal-footer">
							<button @click="closeshowemailtemplate" type="button" data-bs-dismiss="modal" aria-label="Close"
								class="waves-effect waves-light btn btn-danger">Close</button>
						</div>
					</div>
				</div>
			</div>
			<!--show email template end-->

			<!--model start-->
			<div id="importe_csv" class=" importe_csv modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
				aria-hidden="true">
				<div class="modal-dialog modal-md">
					<div class="modal-content">
						<div class="modal-header">
							<h3 class="modal-title fw-900 mt-0">Import CSV</h3>
						</div>
						<div class="modal-body">
							<div class="form-group row">
								<label
									class="col-12 col-sm-12 col-md-3 col-lg-4 col-xl-4 mb-0 col-form-label text-md-end">Choose
									CSV</label>
								<div class="col-12 col-sm-12 col-md-9 col-lg-8 col-xl-8 mb-5">
									<input type="file" ref="file" v-on:change="FileUpload($event)" />
									<br>
									<small class="text-danger">{{ file_type_error }}</small>
								</div>
							</div>

							<div v-if="not_sent_email != ''">
								<div class="text-center"><b>The CSV file is uploaded successfully.</b></div>
								<label class="fs-16">There are no patients with below email addresses. Please copy these
									email addresses
									for your reference.</label>
								<ol class="mb-0 fs-14 mt-10">
									<li v-for="(item, index) in not_sent_email" :key="index">{{ item }}</li>
								</ol>
							</div>

							<div v-if="not_sent_email == ''">
								<label class="fs-16">Please create a CSV file as below.</label>
								<ol class="mb-0 fs-14">
									<li>First row must have title "Email ID"</li>
									<li>List email ids one per line</li>
									<li>Save the file as .csv</li>
									<!-- <li><a @click="showemailtemplate" href="javascript:void(0)" data-bs-toggle="modals" data-bs-target="#showemailtemplate"> Click hear to Show Email Template</a></li> -->
								</ol>
								<div class="text-center mt-10">
									<img src="images/sample_csv.png" alt="sample_csv" class="img-fluid img-thumbnail"
										style="width: 250px;" />
								</div>
							</div>
						</div>
						<div class="modal-footer">
							<button v-if="not_sent_email == ''" type="button" @click="submitForm"
								class="waves-effect waves-light btn btn-info">Upload File</button>
							<button type="button" @click="closecsvmodel" data-bs-dismiss="modal" aria-label="Close"
								class="waves-effect waves-light btn btn-danger">Close</button>
						</div>
					</div>
				</div>
			</div>
			<!--model end-->
			<section class="content">
				<div class="box">
					<div class="box-body">
						<div class="row justify-content-between">
							<div class="col-12 col-sm-4 col-md-4 col-lg-4">
								<div class="form-group">
									<input type="text" class="form-control" placeholder="search"
										@keydown="search_patient($event)" v-model="patinet_search">
								</div>
							</div>
							<div class="col-12 col-sm-4 col-md-4 col-lg-4">
								<button class="b-0 waves-effect waves-light btn btn-info-light py-7 me-1" @click="Clear">
									Clear
								</button>
							</div>
							<div class="col-12 col-sm-4 col-md-4 col-lg-4">
								<div class="form-group">
									<select class="form-control" v-model="filterData" @change="filterchange">
										<option value="all">All</option>
										<option selected="selected" value="verify_pending">Authorization Pending
										</option>
										<option value="verify_complete">Authorized Successfully</option>
										<option value="success">Email Sent</option>
										<option value="failure">Email Sending Failed</option>
									</select>
								</div>
							</div>
						</div>
						<div class="table-responsive">
							<data-table-server dataType="PaymentGatewayMigration" @checkall="checkall" :key="key"
								dataMethod="post">
								<template v-slot="slotProps">
									<td>
										<router-link
											:to="{ name: 'PatientDetails', params: { id: slotProps.item.unique_id } }"
											:title="'View Chart of ' + slotProps.item.patinet_name + '(' + slotProps.item.chart_id + ')'"
											class="fw-500">{{ slotProps.item.chart_id }}</router-link>
									</td>
									<td id="title">{{ slotProps.item.patinet_name }}</td>
									<td>{{ slotProps.item.email }}</td>
									<td>{{ (slotProps.item.email_status) }}</td>
									<td>{{ slotProps.item.email_count }}</td>
									<td>{{ (slotProps.item.verify_status == "verify pending") ? "Pending" : "Authorized" }}
									</td>
									<td>
										{{ $filters.displayDateTimeLocal(slotProps.item.updates, '', false, "ddd, MM-DD-YYYY",true)}}
									</td>
									<td>
										<div class="d-flex">
											<Popper arrow content="Resend Verification Link" :hover="true"
												class="helptips cursor-pointer">
												<a @click="resendVerificationLink(slotProps.item.patient_id)" href="#"
													class="waves-effect waves-circle btn btn-circle btn-info btn-xs me-1">
													<i class="fa fa-paper-plane"></i>
												</a>
											</Popper>
											<Popper arrow content="Show email template" :hover="true"
												class="helptips cursor-pointer">
												<a href="javascript:void(0)"
													class="waves-effect waves-circle btn btn-circle btn-info btn-xs"
													data-bs-toggle="modal" data-bs-target="#showemailtemplate"
													@click="showemailtemplate(slotProps.item.patinet_name)">
													<i class="fa fa-eye"></i>
												</a>
											</Popper>
										</div>
									</td>
									<td>
										<div class="controls ">
											<input v-bind:id="'checkbox_id' + slotProps.item.patient_id"
												name="cardauthorization" type="checkbox"
												class="filled-in chk-col-info myCheckbox"
												:value="slotProps.item.patient_id">
											<label v-bind:for="'checkbox_id' + slotProps.item.patient_id"
												class="m-0 text-start fs-16" style="padding-left: 18px;"></label>
										</div>
									</td>
								</template>
							</data-table-server>
						</div>
					</div>
				</div>
			</section>
		</div>
	</div>
</template>

<script>
import axios from "@/scripts/axios.js";
import submitMixins from "@/scripts/mixins/submitMixins.js";
import Popper from "vue3-popper";
import Search from "@/components/Search.vue";
import DataTableServer from "@/components/base/DataTableServer.vue";
export default {
	mixins: [submitMixins],
	data() {
		return {
			list: [],
			not_sent_email: [],
			errormsg: '',
			filterData: 'verify_pending',
			file_type_error: '',
			csvform: {
				document_name: '',
			},
			patinet_name: '',
			patinet_id: '',
			list_lenght: '',
			start: 0,
			end: 25,
			step: 25,
			steps: '',
			patinet_search: '',
			path: process.env.VUE_APP_KB_DOCUMENT,
			searchKey: 0,
			key: 0,
		};
	},
	components: {
		Popper, Search, DataTableServer
	},
	computed: {
		filter() {
			return this.$store.state.PaymentGatewayMigration.filter;
		},
	},
	methods: {
		reset() {
			this.searchKey = this.searchKey + 1;
		},
		Clear() {
			this.patinet_search = "";
			this.filterData = "verify_pending";
			this.filterchange();
		},
		search_patient(e) {
			if (e.keyCode == 13) {
				this.filterchange();
			}
			this.start = 0;
			this.end = this.list_lenght;
			if (this.patinet_search.length == 0) {
				this.start = 0;
				this.end = 25;
			}

			var regex = new RegExp(this.patinet_search, "i");
			var rows = $('table tr:gt(0)');
			var title;
			rows.each(function (index) {
				title = $(this).children("#title").html()
				if (title.search(regex) != -1) {
					$(this).show();
				} else {
					$(this).hide();
				}
			});


		},
		pagejump(id) {
			var last_id = parseInt(id * this.step);
			this.start = parseInt(last_id - this.step);
			this.end = last_id;
			$('.myCheckbox').prop('checked', false);
			$('#checkAll').prop('checked', false);
		},
		next(end) {
			this.start = end;
			this.end = parseInt(end) + parseInt(this.step);
			$('.myCheckbox').prop('checked', false);
			$('#checkAll').prop('checked', false);
		},
		previous(end, start) {
			this.start = parseInt(start) - parseInt(this.step);
			this.end = start;
			$('.myCheckbox').prop('checked', false);
			$('#checkAll').prop('checked', false);
		},
		sendemail() {
			var patinet_id_array = Array();
			$("input:checkbox[name=cardauthorization]:checked").each(function () {
				patinet_id_array.push($(this).val());
			});
			this.patinet_id = patinet_id_array;
			this.$store.state.loader = true;
			axios.post("/reports/bulksendemail", { patinet_id: this.patinet_id })
				.then((res) => {
					this.moshaToast("Email Sent successfully.", "success"),
						$('.myCheckbox').prop('checked', false)
					$('#checkAll').prop('checked', false)
					this.$store.state.loader = false
				});
		},
		checkall() {
			var checked = $('#checkAll').is(':checked');
			if (checked == true) {
				$('.myCheckbox').prop('checked', true);
			}
			else {
				$('.myCheckbox').prop('checked', false);
			}
		},
		showemailtemplate(name) {
			this.patinet_name = name;
		},
		FileUpload(event) {
			if (this.$refs.file.files[0].type == "text/csv") {
				this.csvform.document_name = this.$refs.file.files[0];
				this.file_type_error = '';
			}
			else {
				this.file_type_error = "Please upload a CSV file.";
			}
		},
		closecsvmodel() {
			this.file_type_error = '';
			if (this.not_sent_email == '') {
				this.csvform.csvform = '';
				this.not_sent_email = [];
				$("#csvmodalclose").click();
				$("#importe_csv").modal("hide");
			}
			else {
				location.reload();
			}

		},
		submitForm() {
			if (this.csvform.document_name == '') {
				this.file_type_error = "Please upload a CSV file.";
			} else {
				var bodyFormData = new FormData();
				console.log(bodyFormData);
				for (const [key, value] of Object.entries(this.csvform)) {
					if (Array.isArray(this.csvform[key])) {
						this.csvform[key].forEach((item) => {
							if (item == null || item == '' || item == 'null') {
								item = "";
							}
							bodyFormData.append(`${key}[]`, item);
						});
					} else {
						if (value == null || value == '' || value == 'null') {
							value = "";
						}
						bodyFormData.append(key, value);
					}
				}
				this.$store.state.loader = true;

				axios.post("/payment-gateway-migration/send-email", bodyFormData)
					.then((res) => {
						if (res.data.status === "success") {
							this.$store.state.loader = false;
							this.moshaToast("Patients Email Sent successfully.", "success");
							this.not_sent_email = res.data.data;
							//setTimeout(function(){ location.reload(); }, 3000);
						} else {
							this.$store.state.loader = false;
							this.moshaToast(res.data.message, 'danger');
						}
						if (this.not_sent_email == '') {
							$('#importe_csv').modal('hide');
							location.reload();
						}
					});
			}
		},
		filterchange() {
			this.filter.search_data = this.patinet_search;
			this.filter.filter_data = this.filterData;
			this.key++;
		},
		resendVerificationLink(id) {
			this.$store.state.loader = true;
			axios.post("/payment-gateway-migration/resend-email", { patient_id: id })
				.then((res) => {
					this.$store.state.loader = false;
					this.moshaToast("Email Sent successfully.", "success")
				});
		}
	},
	beforeCreate() {
		this.$store.state.loader = true;
	},
};
</script>