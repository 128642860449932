<template>
	<div class="content-wrapper">
		<div class="container-full">
			<div class="content-header">
				<div class="row justify-content-between">
					<div class="col-12 col-sm-8 col-md-9 col-lg-6 col-xs-6">
						<h1 class="mt-0">Office Management <a href="#" data-bs-toggle="modal" data-bs-target="#officedocument" title="Reference of Knowledge Base" v-on:click.prevent="reset()"> <i class="fa fa-question-circle fs-20"></i></a></h1>
					</div>
					<div class="col-12 col-sm-4 col-md-3 col-lg-3 col-xs-2 text-sm-end">
						<a href="#/office/archived" class="waves-effect waves-light btn btn-info-light me-1 mb-1"> Show Archived </a>
						<a href="#/office/add" class="waves-effect waves-light btn btn-info mb-5"><i class="fa fa-plus fs-14"></i> Office</a>
					</div>
				</div>
			</div>

			<section class="content">
				<div class="box">
					<div class="box-body">
						<div class="table-responsive">
							<data-table dataType="office" :key="render">
								<template v-slot="slotProps">
									<td>
										{{ slotProps.item.office_name }}
									</td>
									<td>{{ slotProps.item.primary_provider }}</td>
									<td>{{ slotProps.item.city }}</td>
									<td>{{ slotProps.item.address }}</td>
									<td>
										{{
											slotProps.item.office_phone == "" ||
											slotProps.item.office_phone == NULL
												? "-"
												: slotProps.item.office_phone
										}}
									</td>
									<td>{{ slotProps.item.rooms_count }}</td>
									<!--<td v-if="slotProps.item.is_online_available == 'Yes'">Yes</td>
									<td v-else>No</td>-->
									<td>
										<div class="d-flex">
											<Popper arrow content="Edit Office" :hover="true" class="helptips cursor-pointer">
												<a
													@click="editOffice(slotProps.item.office_id)"
													href="#"
													class="waves-effect waves-circle btn btn-circle btn-info btn-xs me-1"><i class="fa fa-pencil"></i></a>
											</Popper>
											<Popper arrow content="Delete Office" :hover="true" class="helptips cursor-pointer">
												<a href="#"
													@click="deleteOffice(slotProps.item.office_id)" class="waves-effect waves-circle btn btn-circle btn-danger btn-xs"><i class="fa fa-trash"></i></a>
											</Popper>
											<Popper arrow content="Share URL" :hover="true" class="helptips cursor-pointer">
												<div class="btn-group mt-1">
													<button type="button" class="waves-effect waves-light btn btn-info-light dropdown-toggle btn-xs" data-bs-toggle="dropdown" aria-expanded="false"><i class="fa fa-share" aria-hidden="true"></i></button>
													<div class="dropdown-menu">
														<a href="javascript:void(0)" class="dropdown-item" @click="showURL(slotProps.item.office_id,slotProps.item.office_name, 'Telehealth')" >Link for TeleHealth</a>
														<a href="javascript:void(0)" class="dropdown-item" @click="showURL(slotProps.item.office_id,slotProps.item.office_name, 'Covid Examination Form')">Link for Covid Examination Form</a>
													</div>
												</div>
											</Popper>
											<Popper arrow content="Share QR Code" :hover="true" class="helptips cursor-pointer">
												<div class="btn-group mt-1">
													<button type="button" class="waves-effect waves-light btn btn-info-light dropdown-toggle btn-xs" data-bs-toggle="dropdown" aria-expanded="false"><i class="fa fa-qrcode" aria-hidden="true"></i></button>
													<div class="dropdown-menu">
														<a href="javascript:void(0)" class="dropdown-item" @click="showQRCode(slotProps.item.office_id,slotProps.item.office_name, 'Telehealth')" >QR for TeleHealth</a>
														<a href="javascript:void(0)" class="dropdown-item" @click="showQRCode(slotProps.item.office_id,slotProps.item.office_name, 'Covid Examination Form')">QR for Covid Examination Form</a>
													</div>
												</div>
											</Popper>
										</div>
									</td>
								</template>
							</data-table>
						</div>
					</div>
				</div>
			</section>
		</div>
	</div>

	<div id="officeshare" class="officeshare modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
		<div class="modal-dialog modal-md">
			<div class="modal-content">
				<div class="modal-header" style="align-items: center;flex-direction: column;">
					<h3 class="modal-title fw-900 mt-0">{{this.office_name}} </h3>
					<span v-if="this.qr_code_type">({{ this.qr_code_type }})</span>
				</div>
				<div class="modal-body">
					<div class="row">
						<div class="col-12 col-md-12">
							<h3>Office URL</h3>
						</div>
						<div class="col-12 col-md-12">
							<a id="officecopy" :href="this.share_url">{{this.share_url}}</a>
						</div>
						<div class="col-12 col-md-12">
							<small>Copy and paste the embed url above.</small>
						</div>
					</div>
				</div>
				<div class="modal-footer" style="display: block;text-align: center;">
					<button type="button" @click="copyoffice(this.share_url)" class="waves-effect waves-light btn btn-info">Click to Copy</button>
					<button type="button"  data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
				</div>
			</div>
		</div>
	</div>

	<div id="officeqr" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
		<div class="modal-dialog modal-md">
			<div class="modal-content">
				<div class="modal-header" style="align-items: center;flex-direction: column;">
					<h3 class="modal-title fw-900 mt-0">{{this.office_name}} </h3>
					<span v-if="this.qr_code_type">({{ this.qr_code_type }})</span>
				</div>
				<div class="modal-body">
					<div class="row">
						<div class="col-12 col-md-12 text-center">                
								<qrcode-vue :value="this.share_url" :size="size" level="H" id="html-content-holder"/>
						</div>
					</div>
				</div>
				<div class="modal-footer" style="display: block;text-align: center;">
					<a type="button" @click="download" id="btn-Convert-Html2Image" class="waves-effect waves-light btn btn-info">Click to Download</a>
					<button type="button"  data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
				</div>
			</div>
		</div>
	</div>
	<!--document model start-->
	<div id="officedocument" class="modal fade" tabindex="-1" role="dialog"
		aria-labelledby="myModalLabel" aria-hidden="true">
		<div class="modal-dialog modal-md">
		<div class="modal-content">
			<div class="modal-header">
				<h3 class="modal-title fw-900 mt-0">Reference of Knowledge Base</h3>
			</div>
			<div class="modal-body overflow-auto" style="max-height: 350px;">
				<table class="table table-bordered">
					<thead>
					<tr>
						<th scope="col">No.</th>
						<th scope="col">Knowledge Base Articles</th>
					</tr>
					</thead>
					<tbody>
					<tr>
						<td>1</td>
						<td> <a :href="path+'how-to-create-an-office'" target="_blank">How To Create An Office?</a></td>
					</tr>
					<tr>
						<td>2</td>
						<td> <a :href="path+'how-to-edit-an-office'" target="_blank">How To Edit An Office?</a></td>
					</tr>
					<tr>
						<td>3</td>
						<td> <a :href="path+'how-to-delete-an-office'" target="_blank">How To Delete An Office?</a></td>
					</tr>
					<tr>
						<td>4</td>
						<td> <a :href="path+'how-to-share-covid-examination-form-for-an-office'" target="_blank">How to Share Covid Examination Form for an Office?</a></td>
					</tr>
					<tr>
						<td>5</td>
						<td> <a :href="path+'how-to-use-telehealth-functionality-of-pureehr'" target="_blank">How To Use Telehealth Functionality Of PureEHR?</a></td>
					</tr>
					<tr>
						<td>6</td>
						<td> <a :href="path+'how-to-share-covid-examination-form-for-an-office'" target="_blank">How to Share Covid Examination Form for an Office?</a></td>
					</tr>
					<tr>
						<td>7</td>
						<td> <a :href="path+'how-to-schedule-office-timings'" target="_blank">How To Schedule Office Timings?</a></td>
					</tr>
					<tr>
						<td>8</td>
						<td> <a :href="path+'why-is-the-office-not-visible-while-scheduling-the-appointment-in-pureehr'" target="_blank">Why Is The Office Not Visible While Scheduling The Appointment In PureEHR?</a></td>
					</tr>
					</tbody>
				</table>
				<Search :key="searchKey"></Search>
			</div>
			<div class="modal-footer">
				<button type="button" @click="" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
			</div>
		</div>
		</div>
	</div>
	<!--document model end-->
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
// import 'jquery/dist/jquery.min.js';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import "datatables.net-dt/js/dataTables.dataTables"
// import "datatables.net-dt/css/jquery.dataTables.min.css"

import dataTable from "@/components/base/DataTable.vue";
import axios from "@/scripts/axios.js";
import Popper from "vue3-popper";
import submitMixins from "@/scripts/mixins/submitMixins.js";
import QrcodeVue from 'qrcode.vue';
import Search from "@/components/Search.vue";

export default {
	mixins: [submitMixins],
	name: "Office",
	components: { dataTable, Popper,QrcodeVue, Search },
	data() {
		return {
			render: 0,
			share_url:'',
			office_name:'',
			qr_code_type:'',
			size:300,
			qrcode:'',
			path: process.env.VUE_APP_KB_DOCUMENT,
			searchKey:0,
		};
	},
	mounted(){
		/*let bridgePayScript = document.createElement('script')
		bridgePayScript.setAttribute('src', 'https://cdnjs.cloudflare.com/ajax/libs/jquery/3.4.1/jquery.min.js')
		document.head.appendChild(bridgePayScript);*/
	},
	methods: {
		reset() {
			this.searchKey = this.searchKey + 1;
		},
		download(){
			var canvas = document.querySelector('#html-content-holder');
			var image = canvas.toDataURL();  
		    // create temporary link  
		    var tmpLink = document.createElement('a');
		    tmpLink.download = this.qr_code_type+'QRcode.png';
		    tmpLink.href = image;  
		    document.body.appendChild( tmpLink );
		    tmpLink.click();  
		    document.body.removeChild( tmpLink );
		},
		async copyoffice(s){
			await navigator.clipboard.writeText(s);
			this.moshaToast("Office URL is copied.", "info");
		},
		shareoffice(id,name) {
			var id = window.btoa(id);
			var clinic_id = window.btoa(this.$store.state.auth.userData.clinic_id);      
			var url = window.location.origin;
			this.share_url = url+"/#/covid-registration/"+clinic_id+"/"+id;
			this.office_name = name;
			$('#officeshare').modal('show');
		},
		showURL(id, name, type) {
			var slug = '';
			if (type == 'Telehealth') {
				slug = '/#/tele-health-registration/';
			} else {
				slug = '/#/covid-registration/';
			}
			this.qr_code_type = type;
			var url = window.location.origin;
			var id = window.btoa(id);
			var clinic_id = window.btoa(this.$store.state.auth.userData.clinic_id);
			this.share_url = url+slug+clinic_id+"/"+id;
			this.office_name = name;
			$('#officeshare').modal('show');
		},
		showQRCode(id, name, type) {
			var slug = '';
			if (type == 'Telehealth') {
				slug = '/#/tele-health-registration/';
			} else {
				slug = '/#/covid-registration/';
			}
			this.qr_code_type = type;
			var url = window.location.origin;
			var id = window.btoa(id);
			var clinic_id = window.btoa(this.$store.state.auth.userData.clinic_id);
			this.share_url = url+slug+clinic_id+"/"+id;
			console.log(this.share_url);
			this.office_name = name;
			$('#officeqr').modal('show');
		},
		shareqr(id,name){
			var id = window.btoa(id);
			var clinic_id = window.btoa(this.$store.state.auth.userData.clinic_id);
			var url = window.location.origin;
			this.share_url = url+"/#/covid-registration/"+clinic_id+"/"+id;
			this.office_name = name;
			$('#officeqr').modal('show');      
		},
		editOffice(id) {
			this.$router.push({ name: "OfficeEdit", params: { id } });
		},
		deleteOffice(id) {
			this.$swal
				.fire({
					title: '<h2 class="mt-0">Are you sure?</h2>',
					text:
						"You want to delete these records? This process cannot be undone.",
					icon: "warning",
					dangerMode: true,
					showCancelButton: true,
					confirmButtonColor: "#ee3158",
					cancelButtonColor: "#bdbdbd",
					confirmButtonText: "Yes, delete it!",
				})
				.then((result) => {
					if (result.isConfirmed == true) {
						axios.post("/office/delete", { id: id }).then((response) => {
							this.$swal
								.fire("Deleted!", "Office has been deleted.", "success")
								.then((result) => {
									if (result.isConfirmed == true) {
										location.reload();
									}
								});
						});
					}
				});
		},
	},
};
</script>
