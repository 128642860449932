<template>
  <div class="content-wrapper">
    <div class="container-full">
      <div class="content-header">
        <div class="row justify-content-between align-items-center">
          <div class="col-12 col-sm-8 col-md-8 col-lg-8">
            <h1 class="heading2 my-5">Archived Visit Profiles</h1>
          </div>
          <div class="col-12 col-sm-4 col-md-4 col-lg-4 text-sm-end">
            <router-link
              to="/visit-profile"
              class="waves-effect waves-light btn btn-info-light"
              ><i class="fa fa-arrow-left fs-16"></i> Back</router-link>
          </div>
        </div>
      </div>
      <section class="content">
        <div class="box">
          <div class="box-body">
            <div class="table-responsive">
              <center>
                <span class="isvalid-feedback" style="display:block;">{{
                  success
                }}</span>
              </center>
              <data-table-server dataType="AppointmentProfilesArchivedList">
                <template v-slot="slotProps">
                  <td>{{ slotProps.item.name }}</td>
                  <td>
                    <div
                      v-bind:style="{
                        'background-color': slotProps.item.color_code,
                      }" class="selectedcolore"></div>
                  </td>
                  <td>{{ slotProps.item.default_duration }} minutes</td>
                  <td>{{ slotProps.item.reason_for_visit }}</td>
                  <td>{{ slotProps.item.billing_profile }}</td>
                  <td>cash</td>
                  <td>{{ slotProps.item.consent_form_total }}</td>
                  <td>{{ slotProps.item.deleted_ats }}</td>
                  <td>{{ slotProps.item.created_ats }}</td>
                  <td>
                    <button type="button"  data-on="Ready" data-off="Not Ready" @click="onToggleChange(slotProps.item.id,$event)" :id="['status_' + slotProps.item.id]" class="btn btn-toggle rounded30-im"  v-bind:class="[slotProps.item.deleted_ats == NULL ? 'active' : '']" data-bs-toggle="button" aria-pressed="true" autocomplete="off">
                      <div class="handle rounded30-im"></div>
                    </button>
                  </td> 
                </template>
              </data-table-server>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import DataTableServer from "@/components/base/DataTableServer.vue";
import axios from "@/scripts/axios.js";
import submitMixins from "@/scripts/mixins/submitMixins.js";

export default {
  name: "Home",
  mixins: [submitMixins],
  components: { DataTableServer },
  data() {
    return {
      success: "",
      result: "",
    };
  },
  mounted() {
    if (this.$route.params.message) {
      this.success = this.$route.params.message;
    }
  },
  methods: {
    onToggleChange(id,event) {
      var class_list = document.getElementById("status_"+id).classList;
      var active_deactive = '';
      if(class_list.contains('active')) {
        active_deactive = 'A';
      } else {
        active_deactive = 'D';
        // console.log(class_list.contains('active'));
      }
      axios.post("/appointment-profile/reset",{"status":active_deactive,"id":id})
        .then((response) => {
          this.moshaToast('Visit Profiles restored successfully.','success');
          setTimeout(function(){ location.reload(); }, 3000);
        });
    },
  },
};
</script>
