<template>
  <table :id="dataTypes.dataType" class="table table-hover">
    <thead>
      <tr>
        <th v-for="(item, index) in getTableHeaders" :key="index" :id="item">
          {{ item }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-if="getTableContents.length>0"
        v-for="(item, index) in getTableContents"
        :key="index"
        class="hover-primary"
      >
        <slot v-bind:item="item" v-bind:index="index"></slot>
      </tr>
      <tr v-else>
        <td v-if="dataTypes.title" align="center" :colspan="getTableHeaders.length">{{dataTypes.title}}</td>
        <td v-else align="center" :colspan="getTableHeaders.length">No data available</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import axios from "@/scripts/axios.js";
import $ from "jquery";
import submitMixins from "@/scripts/mixins/submitMixins.js";

export default {
  mixins: [submitMixins],
  props: {
    dataTypes: Object,
    url: String,
    title:String,
    lastcol: {
      type: Boolean,
      default: true
    },
    callonload: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
    };
  },
  mounted(){
    if(this.lastcol == false){
      $("table#"+this.dataTypes.dataType+" thead tr").children().last().addClass('d-none')
    }
  },
  computed: {
    getTableHeaders() {
      return this.$store.state[this.dataTypes.dataType].tableData.header;
    },
    getTableContents() {
      return this.$store.state[this.dataTypes.dataType].list;
    },
  },
  async created() {
    if(this.callonload){
      const result = await this.$store.dispatch("getSimpleTableContents", this.dataTypes);
      if(result == 'false'){
        this.moshaToast('Could not get data.','danger');
      }
    }
  },
};
</script>
