import {
    helpers,
    required,    
  } from "@vuelidate/validators";
const imageRule = helpers.regex('image', /\.(jpg|jpe?g|tiff|png|pdf)$/);

const macrosettings = {
namespaced: true,  
state: {        
    defaultFormData: {
        id: null,
        macro_title: null,
        macro_text: null,
        status: '',
    },        
    form: { 
        
    },
    validationRules: {
        
    },
    vuelidateExternalResults: {
        
    },
},
getters: {
    getTableHeaders: (state) => state.header,  
    getTableContents: (state) => state.list,
    getListURL: (state) => state.listUrl,
},
mutations: {},
actions: {},
};

export default macrosettings;
