<template>
    <div>
        <ul class="nav nav-tabs mt-0 text-center text-sm-start" role="tablist">
            <li class="nav-item"> <a class="nav-link" :class="isActive('OpenTicket')" data-bs-toggle="tab" href="#openTicket" @click.prevent="changeTab('OpenTicket')" role="tab">Open Ticket</a> </li>
            <li class="nav-item"> <a class="nav-link" :class="isActive('CloseTicket')" data-bs-toggle="tab" href="#CloseTicket" @click.prevent="changeTab('CloseTicket')" role="tab">Close Ticket</a> </li>
        </ul>
        <component
              :is="$store.state.helpSupportComp"
            ></component>
    </div>
     
</template>

<script>
	export default { 
        props: {
            routefrom: String
        },
        data() {
            return {
                active: ''
            }
        },
        methods: {
			changeTab (components) {
				this.$store.state.helpSupportComp = components;
				let props = this.$router.resolve({ 
					name: components,
					params: { id: this.$route.params.id},
				});
                console.log(props);
				history.pushState(null, '',props.href);
                
			},
            isActive(menuname) {
                return menuname==this.routefrom ? 'active' : '';
            }
		},
    }
</script>