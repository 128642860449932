<template>
	<div class="content-wrapper">
		<div class="container">
			<div class="row align-items-center">
				<div class="col-12 col-sm-6 col-md-6 col-lg-6">
					<img src="images/purewellness-header.png" class="img-fluid mb-10" style="width: 240px;">
				</div>
				<div class="col-12 col-sm-6 col-md-6 col-lg-6">
					<h1 class="heading1 font-bold my-2 text-sm-end text-info">Appointment Request</h1>
				</div>
			</div>
		</div>
        <div class="container">
            <div class="box mt-20">
                <div class="box-body p-20">
                <div class="box-data" v-if="this.action == 'reschedule'">
                    <date-picker
                        title="ReSchedule Appointment"
                        v-model="this.date"
                        :maxDate="current_date"
                        ></date-picker>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-11 col-xl-9 text-center">
                        <button
                        class="btn-info saveData"
                        @click="save(false)"
                        >Save</button>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
	import axios from "@/scripts/axios.js";
	import Signature from "@/components/base/Signature.vue";
	import submitMixins from "@/scripts/mixins/submitMixins.js";
    import DatePicker from "@/components/base/formFields/datePicker.vue";
    import FormBtn from "@/components/base/formFields/formBtn.vue";
	export default {
		mixins: [ submitMixins ],
		components: {
			Signature,
            DatePicker,
             FormBtn,
		},
        mounted() {
			this.urldata=window.location.href.split('/');
            var token=this.urldata[5].split('&');
            var action=token[1].split('=');
            this.action=action[1];
            this.token=token[0];
            if (action[1]!="Reschedule") {
                axios.post("reminder/appointment-status",{token:token[0],action:action[1]})
                .then((response) => {
                    if(response.data.status=="success"){
                        if (this.action=="Confirm") {
                            $(".box-body").html("<h1 style='text-align:center;'>Your appointment Confirm Successfully.</h1>");
                        }else if(this.action=="Cancel"){
                             $(".box-body").html("<h1 style='text-align:center;'>Your appointment Cancel Successfully.</h1>");
                        }
                       

                    }             
                }).catch((error) =>{
                         $(".box-body").html("<h1 style='text-align:center;'>This link was expired.</h1>");
                }); 
            }
           
		},
        data(){
            return{
                urldata:'',
                action:'',
                date:'',
                token:'',
                dateFormat: 'MM-dd-yyyy',
            }
        },
        methods:{
            save(){
               axios.post("reminder/appointment-status",{token:this.token,reschedule_date:this.date,action:this.action})
                .then((response) => {
                    if(response.data.status=="success"){
                        console.log(response.data.status);
                        $(".box-data").html("");
                        $(".box-body").html("<h1 style='text-align:center;'>Your appointment Reschedule Successfully.</h1>")

                    }
                }); 
                return false;
            }
        }
    }
    
</script>