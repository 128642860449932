import moment from 'moment';
const UnmatchedERA = {
    namespaced: true,  
    state: {
        listUrl: `reports/unmatchedERA-report`,  
        header: [
            {"claim_number" : "Ref. #"},
            {"schedule_date" : "Date of Service"},
            {"created_at" : "ERA Received"},
            {"" : "Insurance Co."},
            {"total_claimed_accepted_amount" : "Payment"},
            {"adjustment_amount" : "Adjustment"},            
            {"" : "Adjustment Reason"},
            {"" : "Details"},
        ],
        pagination: {
            to: "",
            from: "",
            total: "",
            last_page: "",
            current_page: "",
            pageNavArr: [],
        },
        filter:{
            start_date:  moment().subtract(1, 'months').format("YYYY-MM-DD"),
            end_date: moment(new Date()).format("YYYY-MM-DD"),
            range_of: 'DOS',            
        },
        list: [],
    },
    getters: {},
    mutations: {},
    actions: {},
  };
  
  export default UnmatchedERA;