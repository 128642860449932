<template>
	<div class="content-wrapper">
		<div class="container-full">
			<div class="content-header">
				<h1 class="heading1 m-0">Refund Report</h1>
			</div>
			<div class="box">
				<div class="box-body">
					<div class="row">
						<div class="col-12 col-sm-6 col-md-6 col-lg-3">
							<div class="form-group">
								<label class="form-label">Select Date Range</label>
								<div class="availdate">
									<ejs-daterangepicker format='MM-dd-yyyy' id="date_range" :value="filter.daterange"
										strictMode='strict' placeholder="Select Date Range" :openOnFocus='true'
										@change="onRangeSelect"></ejs-daterangepicker>
								</div>
							</div>
						</div>
						<div class="col-12 col-sm-6 col-md-6 col-lg-3">
							<div class="form-group mb-sm-10">
								<label class="form-label d-block">Patient</label>
								<div class="selectcontrols">
									<Autocomplete max="30" @input="getlist" ref="autocomplete" :results="patientResult"
										@onSelect="resultsvalue" class="form-control ms-0 autosearch-list"
										placeholder="Search Patient" :use-html-for-results="true">
									</Autocomplete>
								</div>
							</div>
						</div>
						<div class="col-12 col-sm-6 col-md-6 col-lg-3">
							<div class="form-group">
								<label class="form-label mb-1">Select Refund Type</label>
								<select-box-static id="PendingDocuments" v-model="filter.select_refund_type"
									:option="select_refund_option" divClass="col-12 col-sm-12 col-md-12 col-lg-12 mt-0"
									labelClass="d-none"></select-box-static>
							</div>
						</div>
						<div class="col-6 col-sm-6 col-md-4 col-lg-3">
							<div class="mt-25">
								<a @click="filter_list" class="waves-effect waves-light btn btn-info me-1">Search</a>
								<a @click="clear_filter" href="javascript:void(0)"
									class="waves-effect waves-light btn btn-info-light">Clear Filter</a>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="box">
				<div class="box-body">
					<div class="text-end mb-10">
						<span class="waves-effect waves-light badge badge-danger-light" style="font-weight: 500;">Total
							Refund Amount :<b>${{ this.sub_total }}</b></span>
					</div>
					<div class="table-responsive">
						<data-table-server dataType="RefundReport" dataMethod="post" :key="key">
							<template v-slot="slotProps">
								<td v-if="permissioncheck(this.$store.state.permission.Access_Patient_Chart)">
									<router-link :to="{ name: 'PatientDetails', params: { id: slotProps.item.unique_id } }"
										:title="'View Chart of ' + slotProps.item.first_name + '(' + slotProps.item.chart_id + ')'"
										class="fw-500">{{ slotProps.item.patient_name }}</router-link>
								</td>
								<td v-else>{{ slotProps.item.patient_name }}</td>
								<td v-if="(slotProps.item.schedule_date != null && slotProps.item.schedule_time != null)">{{
									$filters.displayDateTimeLocal(slotProps.item.schedule_date
									+" "+slotProps.item.schedule_time,'',false,"ddd, MM-DD-YYYY hh:mm A",true)
								}}</td>
								<td v-else>-None-</td>
								<td v-if="(slotProps.item.reason_for_visit != null && slotProps.item.reason_for_visit != null)">
									{{ slotProps.item.reason_for_visit }}</td>
								<td v-else>-None-</td>
								<td v-if="(slotProps.item.last_refund_date != null && slotProps.item.last_refund_date != null)">
									{{$filters.displayDateTimeLocal(slotProps.item.last_refund_dates,'',true,"ddd, MM-DD-YYYY hh:mm A",true) }}</td>
								<td v-else>-None-</td>
								<td v-if="(slotProps.item.payment_type != null && slotProps.item.payment_type != null)">
									{{ slotProps.item.payment_type }}</td>
								<td v-else>-None-</td>
								<td v-if="(slotProps.item.refund_reason != null && slotProps.item.refund_reason != null)">
									{{ slotProps.item.refund_reason }}</td>
								<td v-else>-None-</td>
								<td v-if="(slotProps.item.refund_amount != null && slotProps.item.refund_amount != null)">${{
									Math.abs(slotProps.item.refund_amount)
								}}</td>
								<td v-else>-None-</td>
							</template>
						</data-table-server>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import moment from 'moment';
import Autocomplete from "vue3-autocomplete";
import axios from "@/scripts/axios.js";
import permissionMixins from "@/scripts/mixins/permission.js";
import submitMixins from "@/scripts/mixins/submitMixins.js";
import selectCheckBox from "@/components/base/selectCheckBox.vue";
import Popper from "vue3-popper";
import Select2 from "@/components/base/formFields/select2.vue";
import SelectBox from "@/components/base/formFields/selectBox.vue";
import SelectBoxStatic from "@/components/base/formFields/SelectBoxStatic.vue";
import Search from "@/components/Search.vue";
import DataTableServer from "@/components/base/DataTableServer.vue";

export default {
	mixins: [permissionMixins, submitMixins],
	components: {
		selectCheckBox,
		Autocomplete,
		Popper,
		SelectBoxStatic,
		SelectBox,
		Select2,
		Search,
		DataTableServer
	},
	data() {
		return {
			sub_total: 0,			
			select_refund_option: [
				{ value: "Cash", title: "Cash" },
				{ value: "Check", title: "Check" },
				{ value: "Credit Card", title: "Credit Card" },
				{ value: "Wallet", title: "Wallet" },
				{ value: "Other", title: "Other" },
			], 
			patientResult: {},
			key:0,
		}
	},
	computed: {
		filter(){
			return this.$store.state.RefundReport.filter; 
		}
	},
	created() {},
	methods: {
		getlist(obj) {
			if (obj.length > 1) {
				this.$refs.autocomplete.$el.classList.add("selectloader");
				axios
					.post("/patient/patient-search", {
						search_text: obj,
					})
					.then((response) => {
						if (response.data.data.length > 0) {
							this.patientResult = response.data.data.map((patient) => {
								return { id: patient.id, name: patient.first_name + " " + patient.last_name }
							});
							this.$refs.autocomplete.$el.classList.remove("selectloader");
						}
						else {
							this.patientResult = {};
						}
					});
			}
			if (obj.length == 0) {
				this.patientResult = {};
				this.search.patient_id = '';
			}
		},
		resultsvalue(obj) {
			this.$emit("clicked", obj);
			this.$refs.autocomplete.searchText = obj.name;
			this.filter.search_text = obj.name;
			this.patientResult = {};
			this.search.patient_id = obj.id;
			this.focusout();
		},
		filter_list() {
			this.key++;
		},
		clear_filter() {			
			this.filter.start_date = moment().subtract(1, 'months').format("YYYY-MM-DD");
            this.filter.end_date = moment().add(1, 'days').format("YYYY-MM-DD");
            this.filter.daterange = moment().subtract(1, 'months').format("MM-DD-YYYY") + ' - ' + moment(new Date()).format("MM-DD-YYYY");
            this.filter.select_option_date = '';
            this.filter.search_text = '';
            this.filter.select_refund_type = '';
			this.key++;
		},
		onRangeSelect() {
			this.filter.daterange = document.getElementById("date_range").value;
			var daterange = "";
			if (this.filter.daterange != "") {
				daterange = this.filter.daterange;
				if (daterange != '' && daterange != null) {
					var date = daterange.split(' - ');
					this.filter.start_date = moment(String(date[0])).format('YYYY-MM-DD');
					this.filter.end_date = moment(String(date[1])).format('YYYY-MM-DD');
				}
			}
			else {
				this.filter.start_date = null;
				this.filter.end_date = null;
			}
		},
	},
	mounted() {},
}
</script>
<style>
.select2-container {
	width: 100% !important;
}
</style>
