import {
  helpers,
  required,
  email,
  minLength,
  maxLength,
  sameAs,
  numeric,
  requiredIf,
  alpha,
  alphaNum,
} from "@vuelidate/validators";

const zipcode = (value) => {
  if(value.length > 6 && value.length < 10){
    return false
  }
  return true;
}

const TeleHealthRegistration = {
  namespaced: true,

  state: {
    form: {},
    vuelidateExternalResults: {
      form: {
        first_name:[],
        last_name:[],
        date_of_birth:[],
        phone_no:[],
        sex:[],
        address:[],
        zip_code:[],
        country_id:[],
        state_id:[],
        city:[],
        schedule_date:[],
        schedule_time:[],
        insurance_company_id:[],
        insurance_id_number:[],
        subscriber_relation:[],
        subscriber_first_name:[],
        subscriber_middle_name:[],
        subscriber_last_name:[],
        subscriber_address:[],
        subscriber_zip_code:[],
        subscriber_city:[],
        subscriber_state_id:[],
        insurancefront:[],
        insuranceback:[],
        email_address:[],
        insurance: [],
      },
    },
    validationRules: {
      form: {
        insurance:{
          required : helpers.withMessage("Please check this check-box.", required),
        },
        insurancefront:{
          required: helpers.withMessage("Insurance Front is required", required),
        },
        insuranceback:{
          required: helpers.withMessage("Insurance Back is required", required),
        },
        first_name: {
          required: helpers.withMessage("First Name is required", required),
          alpha: helpers.withMessage('Please enter valid First Name', alpha),
        },
        middle_name: {
          alpha: helpers.withMessage('Please enter valid Middle Name', alpha),
        },
        last_name: {
          required: helpers.withMessage("Last Name is required", required),
          alpha: helpers.withMessage('Please enter valid Last Name', alpha),
        },
        phone_no:{
          required: helpers.withMessage("Phone Number is required", required),  
        },
        date_of_birth:{
          required: helpers.withMessage("Date of Birth is required", required),  
        },
        schedule_date:{
          required: helpers.withMessage("TeleHealth Appointment Date is required", required),    
        },
        schedule_time:{
          required: helpers.withMessage("TeleHealth Appointment Time is required", required),    
        },
        sex:{
          required: helpers.withMessage("Gender is required", required),    
        },
        address:{
          required: helpers.withMessage("Address is required", required),    
        },
        zip_code:{
          required: helpers.withMessage("Zip Code is required", required),
          zipcode: helpers.withMessage('Please enter a valid zip code', zipcode),
          min: minLength(5),
          max: maxLength(10),
        },
        country_id:{
          required: helpers.withMessage("Country is required", required),    
        },
        state_id:{
          required: helpers.withMessage("State is required", required),    
        },
        city:{
          required: helpers.withMessage("City is required", required),
          alphaNum: helpers.withMessage("Please enter valid city name", alphaNum),
        },
        insurance_company_id:{
            required: helpers.withMessage("Insurance Name is required", required),    
        },
        insurance_id_number:{
          required: helpers.withMessage("Insurance Id is required", required),    
        },
        subscriber_relation:{
          required: helpers.withMessage("Subscriber Relation is required", requiredIf(function() {
                return this.$store.state.is_patient_is_subscriber ? true : false;
          })),
        },
        subscriber_first_name:{
          required: helpers.withMessage("Subscriber First Name is required", requiredIf(function() {
                return this.$store.state.is_patient_is_subscriber ? true : false;
          })),
        },
        subscriber_middle_name:{
          required: helpers.withMessage("Subscriber Middle Name is required", requiredIf(function() {
                return this.$store.state.is_patient_is_subscriber ? true : false;
          })),
        },
        subscriber_last_name:{
          required: helpers.withMessage("Subscriber Last Name is required", requiredIf(function() {
                return this.$store.state.is_patient_is_subscriber ? true : false;
          })),
        },
        subscriber_address:{
          required: helpers.withMessage("Subscriber Address is required", requiredIf(function() {
                return this.$store.state.is_patient_is_subscriber ? true : false;
          })),
        },
        subscriber_zip_code:{
          required: helpers.withMessage("Subscriber Zipcode is required", requiredIf(function() {
                return this.$store.state.is_patient_is_subscriber ? true : false;
          })),
        },
        subscriber_city:{
          required: helpers.withMessage("Subscriber City is required", requiredIf(function() {
                return this.$store.state.is_patient_is_subscriber ? true : false;
          })),
        },
        subscriber_state_id:{
          required: helpers.withMessage("Subscriber State is required", requiredIf(function() {
                return this.$store.state.is_patient_is_subscriber ? true : false;
          })),
        },
        email_address:{
          required: helpers.withMessage("Email Address is required", required),    
        } 
      },
    },
  },
  getters: {
    getTableHeaders: (state) => state.header,
    getTableContents: (state) => state.list,
    getListURL: (state) => state.listUrl,
  },
  mutations: {},
  actions: {},
}

export default TeleHealthRegistration;
