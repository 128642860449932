<template>
<div class="content-wrapper">
	<div class="container-full">
		<div class="content-header">
			<h1 class="heading1 my-0">Covid Examination Report <a href="#" data-bs-toggle="modal" data-bs-target="#covidreportdocument" title="Reference of Knowledge Base" v-on:click.prevent="reset()"> <i class="fa fa-question-circle fs-20"></i></a></h1>
		</div>
		<section class="content">
			<div class="box p-10 mb-10">
				<div class="row">
					<div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
						<div class="form-group mb-5 availdate" :class="{ error: v$.search.from_date.$errors.length }">
							<label class="col-12 col-sm-12 col-md-12 col-lg-12">Date Of Service</label>
							<div Class="col-12 col-sm-12 col-md-12 col-lg-12">
								<ejs-daterangepicker 
									:Key="date_range_key"
									ref="date"
									id="daterangepicker" 
									:value="date" 
									v-model="date"
									:placeholder="waterMarkText" 
									@change="getdate">
								</ejs-daterangepicker>
								 <div class="error-msg" v-if="v$.search.from_date.$errors.length > 0">
								  <ul class="mb-0 p-0 fs-14"><li class="d-block" v-for="(error, index) of v$.search.from_date.$errors"> {{ error.$message }}</li>
								  </ul>
								</div>
							</div>
						</div>
					</div>

					<div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
						<div class="form-group">
							<label class="form-label">Specimen Collection</label>
							<select name="select" id="is_spicement_collected" class="form-select form-control"   v-model="search.is_speciman_collected">
								<option value=""> Please Select Option </option>
								<option value="yes">Collected</option>
								<option value="no">Not Collected</option>
							</select>
						</div>
					</div>
					<div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
						<div class="form-group">
							<label class="form-label">Lab Report</label>
							<select name="select" id="is_lab_report_received" v-model="search.is_lab_report_received" class="form-select form-control">
								<option value=""> Please Select Option  </option>
								<option value="yes">Generated</option>
								<option value="no">Not Generated</option>
							</select>
						</div>
					</div>
					<div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
						<div class="form-group">
							<label class="form-label">Refund Requested ?</label>
							<select name="select" id="is_refund_requested" v-model="search.is_refund_requested" class="form-select form-control">
								<option value=""> Please Select Option  </option>
								<option value="yes">Requested</option>
								<option value="no">Not Requested</option>
							</select>
						</div>
					</div>

					<div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
					  <div class="form-group">
					    <label class="form-label">Search</label>
					    <input type="text" class="form-control" v-model="search.free_search" placeholder="Search By Patient Name  or Patient Token" @keydown="patient_search($event)">
					  </div>
					</div>

					<div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
						<div class="form-group" :class="{ error: v$.search.current_user_office_id.$errors.length }">
							<label class="form-label">Offices</label>
							<multi-select-box
								id="current_user_office_id"
								title=""
								v-model="v$.search.current_user_office_id.$model"
								getDataURL="/currentUserOfficeList"
								labelClass="col-12 col-sm-12 col-md-12 col-lg-12"
								divClass="col-12 col-sm-12 col-md-12 col-lg-12"
								>
							</multi-select-box>
							 <div class="error-msg" v-if="v$.search.current_user_office_id.$errors.length > 0">
							  <ul class="mb-0 p-0 fs-14"><li class="d-block" v-for="(error, index) of v$.search.current_user_office_id.$errors"> {{ error.$message }}</li>
							  </ul>
							</div>
						</div>
					</div>
				</div>
				<div class="">
					<a @click="getFilterReportData()" class="waves-effect waves-light btn btn-info-light py-7 me-1">Filter</a>
					<a @click="resetFilterReportData()" class="waves-effect waves-light btn btn-info-light py-7 me-1">Reset</a>
					<a @click="shareOfficeLink()" class="waves-effect waves-light btn btn-info py-7">Add Patient Link</a>
				</div>
			</div>
			<div class="box mt-10">
				<div class="box-body">
					<div class="table-responsive">
						<data-table-server dataType="CovidExaminationReport" dataMethod="post" :key="key">
                            <template v-slot="slotProps">
								<td v-if="this.$store.state.auth.userData && this.$store.state.auth.userData.user_credential_id && this.$store.state.covid_exam_rpt_user_access.includes(this.$store.state.auth.userData.user_credential_id)">
									{{ slotProps.item.full_name }}
								</td>
								<td v-if="this.$store.state.auth.userData && this.$store.state.auth.userData.user_credential_id && !this.$store.state.covid_exam_rpt_user_access.includes(this.$store.state.auth.userData.user_credential_id)">
									<a v-bind:href="'#/patient-chart/'+slotProps.item.unique_id" target="_blank">{{ slotProps.item.full_name }}</a> 
									<Popper arrow hover="true" class="helptips" v-if="slotProps.item.license_no">
										<i class="fa fa-id-card-o" style="color: green;" aria-hidden="true"></i>
										<template #content>
											<div>License No : {{ slotProps.item.license_no }}</div>
											<div>License Expiry Date : {{ $filters.displayDateTimeLocal(slotProps.item.license_expiry_date) }}</div>
										</template>
									</Popper>
								</td>
								<td>{{ $filters.displayDateTimeLocal(slotProps.item.visit_date) }} </td>
								<td>{{ slotProps.item.cell_phone }} </td>
								<td>{{ slotProps.item.email }} </td>
								<td v-if="this.$store.state.auth.userData && this.$store.state.auth.userData.user_credential_id && !this.$store.state.covid_exam_rpt_user_access.includes(this.$store.state.auth.userData.user_credential_id)" >
									{{ $filters.toUSD(slotProps.item.payable_amount)  }} {{ slotProps.item.discount_pr > 0 ? '('+slotProps.item.discount_pr+'%)' : '' }}
								</td>
								<td>
									<table class="table table-bordered mb-0" cellpadding="5" style="text-align: center;">
										<tr>
											<td>Test Name</td>
											<td>Is Specimen collected?</td>
											<td>Result</td>
											<td>View</td>
											<td>Send</td>
											<td>Refund Request?</td>
											<td v-if="this.$store.state.auth.userData && this.$store.state.auth.userData.user_credential_id && !this.$store.state.covid_exam_rpt_user_access.includes(this.$store.state.auth.userData.user_credential_id)">POS Link</td>
										</tr>
										<tr v-if="slotProps.item.is_pcr_test">
											<td>
												PCR
											</td>
											<td>
												<input
												:id="'is_pcr_speciman_collected_' + slotProps.item.id"
												type="checkbox"
												:checked="slotProps.item.is_pcr_speciman_collected"
												class="filled-in chk-col-info dayCheckbox"
												@click="reportUpdate(slotProps.item.id, $event, 'is_pcr_speciman_collected')"
												/>
												<label
												:for="'is_pcr_speciman_collected_' + slotProps.item.id"
												class="text-start"
												></label
												>
											</td>
											<td>

												<div class="form-group">
													<input type="radio" :id="'pcr_test_positive_mm' + slotProps.item.id"   @change="reportUpdate(slotProps.item.id, $event, 'is_pcr_test_positive')" value="positive"  v-model="slotProps.item.is_pcr_test_positive" class="filled-in chk-col-info" :disabled="!slotProps.item.is_pcr_speciman_collected" />
													<label :for="'pcr_test_positive_mm' + slotProps.item.id">Positive</label>
													<input type="radio" :id="'pcr_test_positive_' + slotProps.item.id" @change="reportUpdate(slotProps.item.id, $event, 'is_pcr_test_positive')" value="negative"  v-model="slotProps.item.is_pcr_test_positive" class="filled-in chk-col-info" :disabled="!slotProps.item.is_pcr_speciman_collected" />
													<label :for="'pcr_test_positive_' + slotProps.item.id">Negative</label>
													<input type="radio" :id="'pcr_test_positive_inc_' + slotProps.item.id" @change="reportUpdate(slotProps.item.id, $event, 'is_pcr_test_positive')" value="inconclusive"  v-model="slotProps.item.is_pcr_test_positive" class="filled-in chk-col-info" :disabled="!slotProps.item.is_pcr_speciman_collected" />
													<label :for="'pcr_test_positive_inc_' + slotProps.item.id">Inconclusive</label>
												</div>
											</td>
											<td v-if="slotProps.item.is_pcr_test_positive != null">
												<Popper arrow content="View Report" hover=true class="helptips">
													<a href="javascript:void(0)" class="waves-effect waves-circle btn btn-circle btn-info btn-xs me-1" @click ="printLabResult(slotProps.item.id, 'is_pcr_test')">
														<i class="fa fa-eye"></i>
													</a>
												</Popper>
											</td>
											<td v-if="slotProps.item.is_pcr_test_positive == null">
													NA
											</td>
											<td v-if="slotProps.item.is_pcr_test_positive != null">
												<Popper arrow content="Email Report" hover=true class="helptips">
													<a href="javascript:void(0)" class="waves-effect waves-circle btn btn-circle btn-xs" :class="slotProps.item.is_pcr_test_result_send ? 'btn-primary' : 'btn-info' " @click="sendLabReport(slotProps.item.id, 'is_pcr_test')">
														<i class="fa fa-paper-plane"></i>
													</a>
												</Popper>
											</td>
											<td v-if="slotProps.item.is_pcr_test_positive == null">
													NA
											</td>
											<td>
												<input
												:id="'pcr_test_positive_is_refund_requested_' + slotProps.item.id"
												type="checkbox"
												:checked="slotProps.item.pcr_test_positive_is_refund_requested"
												class="filled-in chk-col-info dayCheckbox"
												@click="reportUpdate(slotProps.item.id, $event, 'pcr_test_positive_is_refund_requested')"
												/>
												<label
												:for="'pcr_test_positive_is_refund_requested_' + slotProps.item.id"
												class="text-start"
												></label
												>
											</td>
											<td v-if="this.$store.state.auth.userData && this.$store.state.auth.userData.user_credential_id && !this.$store.state.covid_exam_rpt_user_access.includes(this.$store.state.auth.userData.user_credential_id)">
												<template v-if="slotProps.item.pcr_payment_refund > 0">
													<button  class="btn btn-primary btn-xs">Refunded</button>
												</template>
												<template v-else>
													<a v-bind:href="'#/patient-chart/'+slotProps.item.unique_id+'/payment/billing'" class="btn btn-info btn-xs" v-if="slotProps.item.pcr_test_positive_is_refund_requested == 1" target="_blank">POS <i class="fa fa-external-link" aria-hidden="true"></i></a>
												</template>
											</td>
										</tr>
										<tr v-if="slotProps.item.is_rapid_antigen_test">
											<td>
												Rapid Anitgen
											</td>
											<td>
													<input
													:id="'is_rapid_antigen_speciman_collected_' + slotProps.item.id"
													type="checkbox"
													:checked="slotProps.item.is_rapid_antigen_speciman_collected"
													class="filled-in chk-col-info dayCheckbox"
													@click="reportUpdate(slotProps.item.id, $event, 'is_rapid_antigen_speciman_collected')"
													/>
													<label
													:for="'is_rapid_antigen_speciman_collected_' + slotProps.item.id"
													class="text-start"
													></label
													>
												</td>
											<td>
												<div class="form-group">
													<input type="radio" :id="'rapid_antigen_test_positive_mm' + slotProps.item.id"  @change="reportUpdate(slotProps.item.id, $event, 'is_rapid_antigen_test_positive')" value="positive"  v-model="slotProps.item.is_rapid_antigen_test_positive" class="filled-in chk-col-info" :disabled="!slotProps.item.is_rapid_antigen_speciman_collected" />
													<label :for="'rapid_antigen_test_positive_mm' + slotProps.item.id">Positive </label>
													<input type="radio" :id="'rapid_antigen_test_positive_' + slotProps.item.id"  @change="reportUpdate(slotProps.item.id, $event, 'is_rapid_antigen_test_positive')" value="negative"  v-model="slotProps.item.is_rapid_antigen_test_positive" class="filled-in chk-col-info" :disabled="!slotProps.item.is_rapid_antigen_speciman_collected" />
													<label :for="'rapid_antigen_test_positive_' + slotProps.item.id">Negative</label>
													<input type="radio" :id="'rapid_antigen_test_positive_inc_' + slotProps.item.id" @change="reportUpdate(slotProps.item.id, $event, 'is_rapid_antigen_test_positive')" value="inconclusive"  v-model="slotProps.item.is_rapid_antigen_test_positive" class="filled-in chk-col-info" :disabled="!slotProps.item.is_rapid_antigen_speciman_collected" />
													<label :for="'rapid_antigen_test_positive_inc_' + slotProps.item.id">Inconclusive</label>
												</div>
											</td>
											<td v-if="slotProps.item.is_rapid_antigen_test_positive != null">
												<Popper arrow content="View Report" hover=true class="helptips">
													<a href="javascript:void(0)" class="waves-effect waves-circle btn btn-circle btn-info btn-xs me-1" @click ="printLabResult(slotProps.item.id, 'is_rapid_antigen_test')">
														<i class="fa fa-eye"></i>
													</a>
												</Popper>
											</td>
											<td v-if="slotProps.item.is_rapid_antigen_test_positive == null">
												NA
											</td>
											<td v-if="slotProps.item.is_rapid_antigen_test_positive != null">
												<Popper arrow content="Email Report" hover=true class="helptips">
													<a href="javascript:void(0)" class="waves-effect waves-circle btn btn-circle btn-xs" :class="slotProps.item.is_rapid_antigen_test_result_send ? 'btn-primary' : 'btn-info' " @click="sendLabReport(slotProps.item.id, 'is_rapid_antigen_test')">
														<i class="fa fa-paper-plane"></i>
													</a>
												</Popper>
											</td>
											<td v-if="slotProps.item.is_rapid_antigen_test_positive == null">
												NA
											</td>
											<td>
												<input
												:id="'rapid_antigen_test_positive_is_refund_requested_' + slotProps.item.id"
												type="checkbox"
												:checked="slotProps.item.rapid_antigen_test_positive_is_refund_requested"
												class="filled-in chk-col-info dayCheckbox"
												@click="reportUpdate(slotProps.item.id, $event, 'rapid_antigen_test_positive_is_refund_requested')"
												/>
												<label
												:for="'rapid_antigen_test_positive_is_refund_requested_' + slotProps.item.id"
												class="text-start"
												></label
												>
											</td>
											<td v-if="this.$store.state.auth.userData && this.$store.state.auth.userData.user_credential_id && !this.$store.state.covid_exam_rpt_user_access.includes(this.$store.state.auth.userData.user_credential_id)">
												<template v-if="slotProps.item.antigen_payment_refund > 0">
													<button  class="btn btn-primary btn-xs">Refunded</button>
												</template>
												<template v-else>
													<a v-bind:href="'#/patient-chart/'+slotProps.item.unique_id+'/payment/billing'" class="btn btn-info btn-xs" v-if="slotProps.item.rapid_antigen_test_positive_is_refund_requested == 1" target="_blank">POS <i class="fa fa-external-link" aria-hidden="true"></i></a>
												</template>
											</td>
										</tr>
										<tr v-if="slotProps.item.is_rapid_pcr_test">
											<td>
												Rapid PCR
											</td>
											<td>
													<input
													:id="'is_rapid_pcr_speciman_collected_' + slotProps.item.id"
													type="checkbox"
													:checked="slotProps.item.is_rapid_pcr_speciman_collected"
													class="filled-in chk-col-info dayCheckbox"
													@click="reportUpdate(slotProps.item.id, $event, 'is_rapid_pcr_speciman_collected')"
													/>
													<label
													:for="'is_rapid_pcr_speciman_collected_' + slotProps.item.id"
													class="text-start"
													></label
													>
												</td>
											<td>
												<div class="form-group">
													<input type="radio" :id="'rapid_pcr_test_positive_mm' + slotProps.item.id"   @change="reportUpdate(slotProps.item.id, $event, 'is_rapid_pcr_test_positive')" value="positive"  v-model="slotProps.item.is_rapid_pcr_test_positive" class="filled-in chk-col-info" :disabled="!slotProps.item.is_rapid_pcr_speciman_collected" />
													<label :for="'rapid_pcr_test_positive_mm' + slotProps.item.id">Positive </label>
													<input type="radio" :id="'rapid_pcr_test_positive_' + slotProps.item.id" @change="reportUpdate(slotProps.item.id, $event, 'is_rapid_pcr_test_positive')" value="negative"  v-model="slotProps.item.is_rapid_pcr_test_positive" class="filled-in chk-col-info" :disabled="!slotProps.item.is_rapid_pcr_speciman_collected"/>
													<label :for="'rapid_pcr_test_positive_' + slotProps.item.id">Negative</label>
													<input type="radio" :id="'rapid_pcr_test_positive_inc_' + slotProps.item.id" @change="reportUpdate(slotProps.item.id, $event, 'is_rapid_pcr_test_positive')" value="inconclusive"  v-model="slotProps.item.is_rapid_pcr_test_positive" class="filled-in chk-col-info" :disabled="!slotProps.item.is_rapid_pcr_speciman_collected"/>
													<label :for="'rapid_pcr_test_positive_inc_' + slotProps.item.id">Inconclusive</label>
												</div>
											</td>
											<td v-if="slotProps.item.is_rapid_pcr_test_positive != null">
												<Popper arrow content="View Report" hover=true class="helptips">
													<a href="javascript:void(0)" class="waves-effect waves-circle btn btn-circle btn-info btn-xs me-1" @click ="printLabResult(slotProps.item.id, 'is_rapid_pcr_test')">
														<i class="fa fa-eye"></i>
													</a>
												</Popper>
											</td>
											<td v-if="slotProps.item.is_rapid_pcr_test_positive == null">
												NA
											</td>
											<td v-if="slotProps.item.is_rapid_pcr_test_positive != null">
												<Popper arrow content="Email Report" hover=true class="helptips">
													<a href="javascript:void(0)" class="waves-effect waves-circle btn btn-circle btn-xs" :class="slotProps.item.is_rapid_pcr_test_result_send ? 'btn-primary' : 'btn-info' " @click="sendLabReport(slotProps.item.id, 'is_rapid_pcr_test')">
														<i class="fa fa-paper-plane"></i>
													</a>
												</Popper>
											</td>
											<td v-if="slotProps.item.is_rapid_pcr_test_positive == null">
												NA
											</td>
											<td>
												<input
												:id="'rapid_pcr_test_positive_is_refund_requested_' + slotProps.item.id"
												type="checkbox"
												:checked="slotProps.item.rapid_pcr_test_positive_is_refund_requested"
												class="filled-in chk-col-info dayCheckbox"
												@click="reportUpdate(slotProps.item.id, $event, 'rapid_pcr_test_positive_is_refund_requested')"
												/>
												<label
												:for="'rapid_pcr_test_positive_is_refund_requested_' + slotProps.item.id"
												class="text-start"
												></label>
											</td>
											<td v-if="this.$store.state.auth.userData && this.$store.state.auth.userData.user_credential_id && !this.$store.state.covid_exam_rpt_user_access.includes(this.$store.state.auth.userData.user_credential_id)">
													<template v-if="slotProps.item.rapid_pcr_payment_refund > 0">
														<button  class="btn btn-primary btn-xs">Refunded</button>
													</template>
													<template v-else>
														<a v-bind:href="'#/patient-chart/'+slotProps.item.unique_id+'/payment/billing'" class="btn btn-info btn-xs" v-if="slotProps.item.rapid_pcr_test_positive_is_refund_requested == 1" target="_blank">POS <i class="fa fa-external-link" aria-hidden="true"></i></a>
													</template>
											</td>
										</tr>
										<tr v-if="slotProps.item.is_antibody_test">
											<td>
												Antibody
											</td>
											<td>
													<input
													:id="'is_antibody_speciman_collected_' + slotProps.item.id"
													type="checkbox"
													:checked="slotProps.item.is_antibody_speciman_collected"
													class="filled-in chk-col-info dayCheckbox"
													@click="reportUpdate(slotProps.item.id, $event, 'is_antibody_speciman_collected')"
													/>
													<label
													:for="'is_antibody_speciman_collected_' + slotProps.item.id"
													class="text-start"
													></label
													>
												</td>
											<td>
												<div class="form-group">
													<input type="radio" :id="'antibody_test_positive_mm' + slotProps.item.id"   @change="reportUpdate(slotProps.item.id, $event, 'is_antibody_test_positive')" value="positive"  v-model="slotProps.item.is_antibody_test_positive" class="filled-in chk-col-info"  :disabled="!slotProps.item.is_antibody_speciman_collected" />
													<label :for="'antibody_test_positive_mm' + slotProps.item.id">Yes </label>
													<input type="radio" :id="'antibody_test_positive_' + slotProps.item.id" @change="reportUpdate(slotProps.item.id, $event, 'is_antibody_test_positive')" value="negative"  v-model="slotProps.item.is_antibody_test_positive" class="filled-in chk-col-info" :disabled="!slotProps.item.is_antibody_speciman_collected" />
													<label :for="'antibody_test_positive_' + slotProps.item.id">No</label>
													<input type="radio" :id="'antibody_test_positive_inc_' + slotProps.item.id" @change="reportUpdate(slotProps.item.id, $event, 'is_antibody_test_positive')" value="inconclusive"  v-model="slotProps.item.is_antibody_test_positive" class="filled-in chk-col-info" :disabled="!slotProps.item.is_antibody_speciman_collected" />
													<label :for="'antibody_test_positive_inc_' + slotProps.item.id">Inconclusive</label>
												</div>
											</td>
											<td v-if="slotProps.item.is_antibody_test_positive != null">
												<Popper arrow content="View Report" hover=true class="helptips">
													<a href="javascript:void(0)" class="waves-effect waves-circle btn btn-circle btn-info btn-xs me-1" @click ="printLabResult(slotProps.item.id, 'is_antibody_test')">
														<i class="fa fa-eye"></i>
													</a>
												</Popper>
											</td>
											<td v-if="slotProps.item.is_antibody_test_positive == null">
												NA
											</td>
											<td v-if="slotProps.item.is_antibody_test_positive != null">
												<Popper arrow content="Email Report" hover=true class="helptips">
													<a href="javascript:void(0)" class="waves-effect waves-circle btn btn-circle btn-xs" :class="slotProps.item.is_antibody_test_result_send ? 'btn-primary' : 'btn-info' " @click="sendLabReport(slotProps.item.id, 'is_antibody_test')">
														<i class="fa fa-paper-plane"></i>
													</a>
												</Popper>
											</td>
											<td v-if="slotProps.item.is_antibody_test_positive == null">
												NA
											</td>
											<td>
												<input
												:id="'antibody_test_positive_is_refund_requested_' + slotProps.item.id"
												type="checkbox"
												:checked="slotProps.item.antibody_test_positive_is_refund_requested"
												class="filled-in chk-col-info dayCheckbox"
												@click="reportUpdate(slotProps.item.id, $event, 'antibody_test_positive_is_refund_requested')"
												/>
												<label
												:for="'antibody_test_positive_is_refund_requested_' + slotProps.item.id"
												class="text-start"
												></label
												>
											</td>
											<td v-if="this.$store.state.auth.userData && this.$store.state.auth.userData.user_credential_id && !this.$store.state.covid_exam_rpt_user_access.includes(this.$store.state.auth.userData.user_credential_id)">
												<template v-if="slotProps.item.antibody_payment_refund > 0">
													<button  class="btn btn-primary btn-xs">Refunded</button>
												</template>
												<template v-else>
													<a v-bind:href="'#/patient-chart/'+slotProps.item.unique_id+'/payment/billing'" class="btn btn-info btn-xs" v-if="slotProps.item.antibody_test_positive_is_refund_requested == 1" target="_blank">POS <i class="fa fa-external-link" aria-hidden="true"></i></a>
												</template>
											</td>
										</tr>
									</table>
								</td>
							</template>
						</data-table-server>						
					</div>
				</div>
			</div>
		</section>
	</div>
</div>
<!--document model start-->
<div id="covidreportdocument" class="modal fade" tabindex="-1" role="dialog"
	aria-labelledby="myModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-md">
		<div class="modal-content">
		<div class="modal-header">
		<h3 class="modal-title fw-900 mt-0">Reference of Knowledge Base</h3>
		</div>
		<div class="modal-body overflow-auto" style="max-height: 350px;">
		<table class="table table-bordered">
			<thead>
				<tr>
				<th scope="col">No.</th>
				<th scope="col">Knowledge Base Articles</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>1</td>
					<td> <a :href="path+'how-to-share-covid-examination-form-for-an-office'" target="_blank">How to Share Covid Examination Form for an Office?</a></td>
				</tr>
			</tbody>
		</table>
		<Search :key="searchKey"></Search>
		</div>
		<div class="modal-footer">
		<button type="button" @click="" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
		</div>
		</div>
	</div>
</div>
<!--document model end-->
<!--viewReceipt Modal Start-->
<div class="modal" id="viewReceipt" tabindex="-1">
	<div class="modal-dialog modal-md" style="max-width:75%">
		<div class="modal-content">
			<div class="modal-header">
				<button type="button" class="btn-close btn-xs" data-bs-dismiss="modal" aria-label="Close"></button>
			</div>
			<div class="modal-body">
				<vue-pdf-viewer height="1050px" :url="receipt_url" v-if="embed=0">
				</vue-pdf-viewer>
				<iframe :src="receipt_url" width="100%" height="500px" v-if="embed=1" />
				<img srd="images/1.gif" v-else> 
			</div>
		</div>
	</div>
</div>
<!--viewReceipt Modal end-->

<div id="officeshare" class="officeshare modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-md">
		<div class="modal-content">
			<div class="modal-header">
				<button type="button" class="btn-close btn-xs" data-bs-dismiss="modal" aria-label="Close"></button>
			</div>
			<div class="modal-body">
				<div class="row">
					<div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
						<div class="form-group">
							<select name="select" class="form-select form-control" v-model="office_id" @change="changeOfficeShare">
								<option value=""> Please Select Office </option>
								<option v-for="(item,index) in office_list" :value="item"  :data-val="JSON.stringify(item)">{{item.name}}</option>
							</select>
						</div>
					</div>
					<div class="col-12 col-md-12">
						<h3>Office URL</h3>
					</div>
					<div class="col-12 col-md-12">
						<a id="officecopy" :href="this.share_url">{{this.share_url}}</a>
					</div>
					<div class="col-12 col-md-12">
						<small>Copy and paste the embed url above.</small>
					</div>
				</div>
			</div>
			<div class="modal-footer" style="display: block;text-align: center;">
				<button type="button" @click="copyoffice(this.share_url)" class="waves-effect waves-light btn btn-info">Click to Copy</button>
				<button type="button"  data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
			</div>
		</div>
	</div>
</div>
</template>

<script>
	import { mapGetters, mapActions } from "vuex";
	import DataTableServer from "@/components/base/DataTableServer.vue";
	import useVuelidate from "@vuelidate/core";
	import { required, helpers } from "@vuelidate/validators";
	import axios from "@/scripts/axios.js";
	import Popper from "vue3-popper";
	import permissionMixins from "@/scripts/mixins/permission.js";
	import submitMixins from "@/scripts/mixins/submitMixins.js";
	import moment from 'moment';
	import MultiSelectBox from "@/components/base/formFields/multiSelectBox.vue";
	import Search from "@/components/Search.vue";
	import VuePDFViewer from 'vue-instant-pdf-viewer';
	
	export default {
		components: {
			DataTableServer,
			Popper,
			MultiSelectBox,
			Search,
			'vue-pdf-viewer': VuePDFViewer
		},
		setup() {
		  return { v$: useVuelidate() };
		},
		computed: {
			search() {
				return this.$store.state.CovidExaminationReport.filter;
			},
		},
		data() {
			return {
				key: 0,
				office_id: '',
				date_range_key: 1,
				waterMarkText: "Select Date",
				date:new Date(),
				current_item : '',				
				form :{
					id : '',
					patient_id : '',
					pcr_test : '',
					is_pcr_test_positive : '',
					rapid_pcr_test: '',
					is_rapid_pcr_test_positive: '',
					rapid_antigen_test: '',
					is_rapid_antigen_test_positive: '',
					is_antibody_test_positive: '',
				},
				receipt_url:'',
				office_list:'',
				share_url:'',
				office_name:'',
				path: process.env.VUE_APP_KB_DOCUMENT,
				searchKey:0,
			}
		},
		mounted() {
	      this.date = moment().subtract(1, 'days').format("MM-DD-YYYY")+' - '+moment().add(1, 'days').format("MM-DD-YYYY");
	      setTimeout(() => {
	      	this.search.current_user_office_id = this.$store.state.auth.userData.office_access;
	      },800);
		},
		mixins: [ permissionMixins, submitMixins ],
		created() {
			axios.get('/json_dropdown/office')
			.then((response) => {
				this.office_list = response.data.data;
				this.office_id = this.office_list[0];
				this.showURL(this.office_list[0].id,this.office_list[0].name);
			});
		},
		methods:{
			reset() {
				this.searchKey = this.searchKey + 1;
			},
			async copyoffice(s){
				await navigator.clipboard.writeText(s);
				this.moshaToast("Office URL is copied.", "info");
			},
			showURL(id, name) {
				var slug = '/#/covid-registration/';
				var url = window.location.origin;
				var id = window.btoa(id);
				var clinic_id = window.btoa(this.$store.state.auth.userData.clinic_id);
				this.share_url = url+slug+clinic_id+"/"+id;
				this.office_name = name;
			},
			changeOfficeShare() {
				this.showURL(this.office_id.id,this.office_id.name);
			},
			shareOfficeLink() {
				$("#officeshare").modal("show");
			},
			printLabResult(id, keyname) {
				this.getSrc(id, keyname);
				$("#viewReceipt").modal("show");
			},
			async getSrc(id, keyname) {
				this.$store.state.loader = true;
				const res = await fetch(process.env.VUE_APP_API_BASE_URL+'/covid/printLabTestReport', {
					method: 'POST',
					headers: {
						'Authorization' : 'Bearer '+localStorage.getItem('authToken'),
						'Content-Type' : 'application/json'
					},
					body: JSON.stringify({ id: id , keyname : keyname }),
				});
				const blob = await res.blob();
				const urlObject = URL.createObjectURL(blob);
				this.$store.state.loader = false;
				if (/Android|webOS|Opera Mini/i.test(navigator.userAgent)) {
					$("#viewReceipt").modal("hide");
					window.open(urlObject, '_blank', 'height=600,width=1200');
				} else {
					this.receipt_url = urlObject;
					this.browserCheck();
				}
			},
			browserCheck(){
				// Opera 8.0+
				var isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

				// Firefox 1.0+
				var isFirefox = typeof InstallTrigger !== 'undefined';

				// Safari 3.0+ "[object HTMLElementConstructor]" 
				var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));

				// Internet Explorer 6-11
				var isIE = /*@cc_on!@*/false || !!document.documentMode;

				// Edge 20+
				var isEdge = !isIE && !!window.StyleMedia;

				// Chrome 1 - 79
				var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

				// Edge (based on chromium) detection
				var isEdgeChromium = isChrome && (navigator.userAgent.indexOf("Edg") != -1);

				// Blink engine detection
				var isBlink = (isChrome || isOpera) && !!window.CSS;

				if (isChrome==true) {
					this.embed = 0;
				} else {
					this.embed = 1;
				}
			},
			sendLabReport(id, keyname) {
				this.$store.state.loader = true;
				axios
				.post('covid/printLabTestReport',{ id: id , 'keyname' : keyname, send_email : true })
				.then((response) => {
					this.$store.state.loader = false;
					this.moshaToast('Email sent successfully.', "success");
					this.getFilterReportData();
				})
				.catch((error) => {
					this.$store.state.loader = false;
					this.moshaToast("Coundn't sent email.", "danger");
				});
			},
			reportUpdate(id, event, keyname) {
				if (event.target.type == 'checkbox') {
					var keyvalue = event.target.checked;
					var success_status = 'speciman';
				} else {
					var keyvalue = event.target.value;
					var success_status = 'status';
				}
				axios
				.post("covid/uploadExaminationData",{"keyvalue": keyvalue, "id": id, 'keyname' : keyname })
				.then((response) => {
					this.moshaToast('lab report '+success_status+' updated successfully.','success');
					this.getFilterReportData();
				});
			},
			displayResult(item) {
				item.pcr_test_document_name_file_type = '';
				item.pcr_test_document_name_url = '';
				if (item.pcr_test_document_name) {
					item.pcr_test_document_name_file_type = item.pcr_test_document_name.split('.')[1];
					item.pcr_test_document_name_url = process.env.VUE_APP_API_BASE_URL+"/stream-image?path="+item.pcr_test_document_name+"&slang="+item.clinic_id
				}
				item.rapid_antigen_test_document_name_file_type = '';
				item.rapid_antigen_test_document_name_url = '';
				if (item.rapid_antigen_test_document_name) {
					item.rapid_antigen_test_document_name_file_type = item.rapid_antigen_test_document_name.split('.')[1];
					item.rapid_antigen_test_document_name_url = process.env.VUE_APP_API_BASE_URL+"/stream-image?path="+item.rapid_antigen_test_document_name+"&slang="+item.clinic_id
				}
				item.rapid_pcr_test_document_name_file_type = '';
				item.rapid_pcr_test_document_name_url = '';
				if (item.rapid_pcr_test_document_name) {
					item.rapid_pcr_test_document_name_file_type = item.rapid_pcr_test_document_name.split('.')[1];
					item.rapid_pcr_test_document_name_url = process.env.VUE_APP_API_BASE_URL+"/stream-image?path="+item.rapid_pcr_test_document_name+"&slang="+item.clinic_id
				}
				item.antibody_test_document_name_file_type = '';
				item.antibody_test_document_name_url = '';
				if (item.antibody_test_document_name) {
					item.antibody_test_document_name_file_type = item.antibody_test_document_name.split('.')[1];
					item.antibody_test_document_name_url = process.env.VUE_APP_API_BASE_URL+"/stream-image?path="+item.antibody_test_document_name+"&slang="+item.clinic_id
				}
				this.current_item = item;
				$('#ReceivedModal').modal('show');
			},
			uploadResult(item) {
				this.current_item = item;
				console.log(this.current_item);
				this.form.patient_id = item.patient_id;
				this.form.id = item.id;
				$('#UploadModal').modal('show');
			},
			patient_search(e){
		      if(e.keyCode == 13){
		        this.getFilterReportData();
		      }
		    },
			resetFilterReportData() {
				this.date = moment().subtract(1, 'days').format("MM-DD-YYYY")+' - '+moment().add(1, 'days').format("MM-DD-YYYY");
				console.log(this.date);
				this.search.is_speciman_collected = '';
				this.search.is_refund_requested = '';
				this.search.is_lab_report_received = '';
				this.search.from_date = '';
				this.search.to_date = '';
				this.getdate();
				this.date_range_key++;
				this.$refs.date.ej2Instances.value = this.date;
				setTimeout(()=> {
					this.getFilterReportData();
				},800);
				
			},
			getFilterReportData() {
				this.v$.$validate();
				if (!this.v$.$error) {
					this.key = this.key+1;					
				}
			},
			getdate() {
				var daterange = document.getElementById("daterangepicker").value;
				var date = daterange.split(' - ');
				this.search.from_date  = date[0];
				this.search.to_date  = date[1];
			} 
		},
		validations() {
		  return {
		    search: {
		      current_user_office_id: {
		        required: helpers.withMessage("Office is required.", required),
		      },
		      from_date: {
		        required: helpers.withMessage("Date range is required.", required),
		      },
		    },
		  };
		},
	}
</script>
<style>
	/*.zoom:hover {
	  transform: scale(1.5);
	}*/
	.zoom {
	  transition: transform .2s; /* Animation */
	  width: 200px;
	  margin: 0 auto;
	}
</style>