<template>
    <div class="box p-15">
        <div class="mdc-tab">
            <ul class="nav nav-tabs mt-0 mb-20 text-center text-sm-start" role="tablist">
                <li class="nav-item"> <a class="nav-link" data-bs-toggle="tab" href="#patientIdCard" @click.prevent="changeTab('PatientIdentification')" role="tab">Driver's License</a> </li>
                <li class="nav-item"> <a class="nav-link" data-bs-toggle="tab" href="#insuranceCard" @click.prevent="changeTab('PatientInsurance')" role="tab">Insurance Card</a> </li>
                <li class="nav-item"> <a class="nav-link active" data-bs-toggle="tab" href="#insuranceCard" @click.prevent="changeTab('PatientOtherIdentification')" role="tab">Other Identification</a> </li>
                <li class="nav-item"> <a class="m-5" href="#" data-bs-toggle="modal" data-bs-target="#otheridentification" title="Reference of Knowledge Base" v-on:click.prevent="reset()"><i class="fa fa-question-circle fa-lg pt-2"></i></a> </li>
            </ul>
            <div class="tab-content">
                <div class="tab-pane active" id="patientIdCard" role="tabpanel">
                    <form novalidate>
                        <!-- <input-field title="Document Type" labelClass="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end" divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3" :isRequired="true" v-model="v$.form.document_title.$model" :errors="v$.form.document_title.$errors"></input-field> -->
                        <file-upload id="uploadFiles" 
                        v-model="v$.form.uploadFiles.$model"
                        :errors="v$.form.uploadFiles.$errors"
                        :isRequired="true" 
                        title="Identification test"
                        :coustom_error="coustom_error"
                        @change="type_validation"
                        note="Supported formats JPG, JPEG, PDF, PNG. Max allowed size is 10MB"
                        noteClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-6">
                        </file-upload>
                    </form>
                    <div class="row mb-10" v-if="permissioncheck(this.$store.state.permission.create_update)">
                        <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 text-center">
                            <form-btn title="Upload" @click="upload" btnCls="btn-info saveData"></form-btn>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 col-sm-3 col-md-3 col-lg-2 text-center" v-if="file_type == 'png' || file_type == 'jpeg' || file_type == 'jpg'">
                            <div class="position-relative" v-if="document_name_url != ''">
                                <img v-if="file_type == 'png' || file_type == 'jpeg' || file_type == 'jpg'" :src="document_name_url" class="img-fluid rounded5 b-1" style="height: 120px;">
                                <a v-if="file_type == 'png' || file_type == 'jpeg' || file_type == 'jpg'" href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#viewimg" class="waves-effect  btn btn-info btn-xs py-1 px-2 rounded fs-18 position-absolute" style="right: 0;bottom: 0px;">
                                    <i class="fa fa-search-plus"></i>
                                </a>
                            </div>
                        </div>
                        <div class="col-6 col-sm-3 col-md-3 col-lg-2 text-center" v-if="file_type == 'pdf'">
                            <div class="position-relative">
                                <iframe
                                    :src="document_name_url" height="100%" width="100%" style="height:120px;"></iframe>
                                <a v-if="file_type == 'pdf'" href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#viewpdf" class="waves-effect  btn btn-info btn-xs py-1 px-2 rounded fs-18 position-absolute b-1" style="right: 0;bottom: 0px;">
                                    <i class="fa fa-eye"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <small v-if="document_name_url != ''" class="text-mute mt-10 d-block"><b>Updated on </b>{{$filters.displayDateTimeLocal(updated_at)}}</small>
                </div>
            </div>
        </div>
    </div>
<!--document model start-->
<div id="otheridentification" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
<div class="modal-dialog modal-md">
    <div class="modal-content">
    <div class="modal-header">
        <h3 class="modal-title fw-900 mt-0">Reference of Knowledge Base</h3>
    </div>
    <div class="modal-body overflow-auto" style="max-height: 350px;">
    <table class="table table-bordered">
        <thead>
        <tr>
        <th scope="col">No.</th>
        <th scope="col">Knowledge Base Articles</th>
        </tr>
        </thead>
        <tbody>
        <tr>
            <td>1</td>
            <td>  <a :href="path+'how-to-upload-other-identification-documents'" target="_blank">How To Upload Other Identification Documents?</a></td>
        </tr>
        </tbody>
    </table>
    <Search :key="searchKey"></Search>
    </div>
    <div class="modal-footer">
        <button type="button" @click="" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
    </div>
    </div>
</div>
</div>
<!--document model end-->
<!--viewReceipt Modal Start-->
<div class="modal" id="viewpdf" tabindex="-1">
    <div class="modal-dialog modal-md" style="max-width:75%">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="btn-close btn-xs" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <vue-pdf-viewer height="1050px" :url="document_name_url" v-if="embed=0">
                </vue-pdf-viewer>
                <iframe :src="document_name_url" width="100%" height="500px" v-if="embed=1" />
                <img srd="images/1.gif" v-else> 
            </div>
        </div>
    </div>
</div>
<!--viewReceipt Modal end-->

<!--view Image Modal Start-->
<div class="modal" id="viewimg" tabindex="-1">
    <div class="modal-dialog modal-xl">
        <div class="modal-content bg-white" style="height: 93vh;">
            <button @click="zoomclose" type="button" class="btn-close btn-xs position-absolute" data-bs-dismiss="modal" aria-label="Close" style="right: 4px;top: 8px;z-index: 1;"></button>
            <div class="modal-body text-center d-flex align-items-center" style="overflow: hidden;">
                <img v-if="file_type == 'png' || file_type == 'jpeg' || file_type == 'jpg'" :src="document_name_url" class="img-fluid rounded5 zoom">
            </div>
            <div class="text-center my-5">
                <a @click="zoomplus" href="javascript:void(0)" class="waves-effect waves-light btn btn-info-light fs-16 btn-xs me-2"><i class="fa fa-search-plus"></i></a>
                <a @click="zoomminus" href="javascript:void(0)" class="waves-effect waves-light btn btn-info-light fs-16 btn-xs"><i class="fa fa-search-minus"></i></a>
            </div>
        </div>
    </div>
</div>
<!--viewImage Modal end-->
</template>
<script>
import InputField from "@/components/base/formFields/InputField.vue";
import FileUpload from "@/components/base/formFields/fileUpload.vue";
import FormBtn from "@/components/base/formFields/formBtn.vue";
import useVuelidate from "@vuelidate/core";
import axios from "@/scripts/axios.js";
import submitMixins from "@/scripts/mixins/submitMixins.js";
import permissionMixins from "@/scripts/mixins/permission.js";
import Search from "@/components/Search.vue";

import {
  helpers,
  required,
  email,
  minLength,
  maxLength,
} from "@vuelidate/validators";

export default {
    name: "PatientIdentification",
    mixins: [submitMixins, permissionMixins],
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            document_name_url:'',
            document_name:'',
            getImageUrl:'',
            file_type:'',
            embed: 0,
            updated_at:'',
            scale: 0.5,
            coustom_error:'',
            path: process.env.VUE_APP_KB_DOCUMENT,
            searchKey:0,
        }
    },
    components: {
        InputField,
        FileUpload,
        FormBtn,
        Search
    },
    computed: {
        form() {
            return this.$store.state.documents.form;
        },
        vuelidateExternalResults() {
            return this.$store.state.documents.vuelidateExternalResults;
        },
        validationRules() {
            return this.$store.state.documents.validationRules;
        },
        title() {
            return "Patient Identification";
        },
    },
    mounted(){
        this.getimg();
    },
    beforeCreate() {
        this.$store.state.documents.form = {};
        Object.assign(
            this.$store.state.documents.form,
            this.$store.state.documents.defaultFormData
        );
    },
    methods: {
        reset() {
            this.searchKey = this.searchKey+1;
        },
        type_validation(){
            if(this.form.uploadFiles.type != 'application/pdf' && this.form.uploadFiles.type != 'image/jpeg' && this.form.uploadFiles.type != 'image/png'){
                this.coustom_error ="Only JPG, JPEG, PDF, PNG formats are supported. Max allowed size is 10MB.";
            }
            else if(this.form.uploadFiles.size > 10240000){
                this.coustom_error ="Only JPG, JPEG, PDF, PNG formats are supported. Max allowed size is 10MB.";    
            }
            else{
                this.coustom_error = '';
            }
        },
        zoomclose(){
            this.scale = 1.5;
            $(".zoom").css("transform", "scale("+this.scale+")");
        },
        zoomplus(){
            this.scale = this.scale+0.5;
            $(".zoom").css("transform", "scale("+this.scale+")");
        },
        zoomminus(){
            if(this.scale > 0.5){
                this.scale = this.scale-0.5;
                $(".zoom").css("transform", "scale("+this.scale+")");
            }
        },
        browserCheck(){
            // Opera 8.0+
            var isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

            // Firefox 1.0+
            var isFirefox = typeof InstallTrigger !== 'undefined';

            // Safari 3.0+ "[object HTMLElementConstructor]" 
            var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));

            // Internet Explorer 6-11
            var isIE = /*@cc_on!@*/false || !!document.documentMode;

            // Edge 20+
            var isEdge = !isIE && !!window.StyleMedia;

            // Chrome 1 - 79
            var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

            // Edge (based on chromium) detection
            var isEdgeChromium = isChrome && (navigator.userAgent.indexOf("Edg") != -1);

            // Blink engine detection
            var isBlink = (isChrome || isOpera) && !!window.CSS;

            if (isChrome==true) {
                this.embed = 0;
            } else {
                this.embed = 1;
            }
        },
        getimg(){
            this.$store.state.loader = true;
            axios.post("patient/"+this.$route.params.id+"/documents/otheridentification",{
                patient_id:this.$store.state.patientDetail.patient_id,
                document_folder_id:146,
                document_tags:'Other Identification'
            })
            .then((response) => {
                this.$store.state.loader = false;
                if(response.data.data.document_name != undefined){
                    this.document_name = response.data.data.document_name;
                    this.file_type = response.data.data.file_type;
                    this.document_name_url = process.env.VUE_APP_API_BASE_URL+"/stream-image?path="+this.document_name+"&slang="+response.data.data.clinic_id;
                    this.updated_at = response.data.data.updated_at;
                    
                }
            });
        },
        upload()
        {
            if(this.coustom_error == ''){
                this.$store.state.loader = true;
                this.$store.state.documents.form.id = 146;
                this.$store.state.documents.form.other_identification = 'yes';
                this.$store.state.documents.form.patient_id = this.$store.state.patientDetail.patient_id;
                this.postForm(`/patient/${this.$route.params.id}/documents/store`,'documents',true,"formdata");
                this.$store.state.loader = false;
            }
        },
        changeTab (components) {
            this.$store.state.patientDetailsComp = components;
            let props = this.$router.resolve({
                name: components,
                params: { id: this.$route.params.id},
            });
            if (history.pushState && props) {
                if (this.hash=='' || this.hash==null || typeof this.hash === undefined || typeof this.hash === 'undefined') {
                    history.pushState(null, '',props.href);
                } else {
                    history.pushState(null, '',props.href+"/#"+this.hash);
                }
            }
        },
        /*upload_insurance()
        {
            this.$store.state.documents.form_insurance.id = 147;
            this.$store.state.documents.form_insurance.patient_id = this.$store.state.patientDetail.patient_id;
            Object.assign(this.form, this.form_insurance);

            this.postForm(`/patient/${this.$route.params.id}/documents/store_insurance`,'documents',true,"formdata");  
        },*/
        formPostSuccess(data) {
            this.$store.state.documents.form = {};
            this.$store.state.documents.form_insurance = {};
            this.v$.$reset();
            this.moshaToast(
                `Record ${"added"} successfully`,
                "success"
            );
            this.getimg();
        }
    },
    validations() {
        return this.$store.state.documents.validationRules;
    },
}
</script>