import {
    helpers,
    required,    
  } from "@vuelidate/validators";
  const documents = {
    namespaced: true,  
    state: {
        listUrl: `patient/:patient_unique_id/documents/list`,
        tableData: {
            header: [
                "Date",
                "Title",
                "Description",
                "Document Folders",
                "Uploaded On",
                "Actions",
            ],
        },
        defaultFormData: {
            id: null,    
            document_title: '',    
            document_desc: '',    
            document_date: '',
        },
        form: {},
        formedit: {},
        validationRules: {
            formedit: {
                document_title: {
                    required: helpers.withMessage("Document Title is required", required),
                },
            },
            form: {
                uploadFiles: {
                    required: helpers.withMessage("Please upload file.",required),
                }
            }            
        },
        vuelidateExternalResults: {
            form: {
                document_title: '',
                uploadFiles: '',
            },
            formedit: {
                document_title: '',  
            },
        },
        list: [],
    },
    getters: {
        getTableHeaders: (state) => state.header,  
        getTableContents: (state) => state.list,
        getListURL: (state) => state.listUrl,
    },
    mutations: {},
    actions: {},
  };
  
  export default documents;
  