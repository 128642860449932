<template>
  <table :id="dataType" class="table">
    <thead>
      <tr>
        <th v-for="(item, index) in getTableHeaders" :key="index">
          {{ item }}
        </th>
        <th class="text-center" v-if="this.addcol==1">
          <button class="waves-effect waves-light btn btn-info btn-xs" @click="recover">Recover</button>
          <div class="controls ">
             <input id="checkAll" type="checkbox" class="filled-in chk-col-info" @click="checkall">
             <label for="checkAll" class="m-0 text-start fs-16" style="padding-left: 18px;"></label>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(item, index) in getTableContents"
        :key="index"
        class="hover-primary"
      >
        <slot v-bind:item="item"></slot>
      </tr>
    </tbody>
  </table>
</template>

<script>
import "datatables.net-dt/js/dataTables.dataTables";
import $ from "jquery";
import submitMixins from "@/scripts/mixins/submitMixins.js";

export default {
  mixins: [submitMixins],
  props: {
    dataType :String ,
    url : String,
    addcol : Number,
    pageLength: {
        type: Number,
        default: 10
    },
    dataMethod: String
  },
  data() {
    return {};
  },
  computed: {
    getTableHeaders() {
      return this.$store.state[this.dataType].tableData.header;
    },
    getTableContents() {
      return this.$store.state[this.dataType].list;
    },
  },
  async created() {
    // initialize table data
    var queryStr = "";
    if (this.dataType == 'billingprofile') {
        if (this.$route.query.lifestyle) {
          queryStr += '?lifestyle=true';
        }
    }
    console.log(queryStr);
    if (this.dataMethod == undefined) {
      const resultGet = await this.$store.dispatch("getDataTableContents", {
        dataType: this.dataType,
        queryStr: queryStr,
      });
      if(resultGet == 'false'){
        this.moshaToast('Could not get data.','danger');
      }
    } else {
      const resultPost = await this.$store.dispatch("getDataTableContents", {
        dataType: this.dataType,
        method: this.dataMethod,
        queryStr: queryStr,
      });
      if(resultPost == 'false') {
        this.moshaToast('Could not get data.','danger');
    }
    }

    $(`#${this.dataType}`).DataTable({
      order : [],
      "pageLength": this.pageLength,
      "bStateSave": true 
    });
    let elem = document.getElementsByName(`${this.dataType}_length`)[0];
    elem.classList.add("form-select");
    elem.classList.add("form-control-sm");
    elem.style.cssText += `border-color: #86a4c3 !important`;

    elem = document.getElementsByTagName("input")[1];
    elem.style.cssText += `outline: none`;
  },
  mounted(){
  },
  methods:{
    recover(){
      this.$store.state[this.dataType].clickevent =  1;
    },
    checkall(){      
      this.$store.state[this.dataType].checkall =  1;
    }
  }
};
</script>
<style scoped></style>
