<template>
  <div class="form-group row">
    <label
      :class="labelClass"
      >{{ title }}</label
    >
    <div :class="divClass">
      <div class="controls">
        <select
          name="select"
          required
          class="form-select form-control form-control"
          :value="modelValue"
          @change="$emit('update:modelValue', $event.target.value)"
        >
          <option value="" disabled="disabled" selected="selected">Select Your {{ title }}</option>
          <option :value="item.id" v-for="item in data" :key="item.id">{{
            item.name
          }}</option>
        </select>
        <span class="form-text text-muted" v-if="notes != ''">{{notes}}</span>
      </div>
      <!-- <div class="error-msg">
        <ul>
          <li v-for="(error, index) of errors" :key="index">
            {{ error.$message }}
          </li>
        </ul>
      </div> -->
      <p v-if="error" class="form-error">
        Could not get {{ title }} options. Click
        <a @click="window.location.reload(true)"> HERE </a> to reload the page
        and try again
      </p>
    </div>
  </div>
</template>
<script>
import submitMixins from "@/scripts/mixins/submitMixins.js";

export default {
  mixins: [submitMixins],
  props: {
    modelValue: {},
    errors: {
      Object,
    },
    title: {
      type: String,
    },
    countryID: {
      type: [String, Number],
    },
    labelClass:{
      type: String,
      default: "col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end"
    },
    divClass:{
      type: String,
      default: "col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3"
    },
    notes: {
      type: String,
      default: ""
    },
  },
  watch: {
    countryID: function(newValue) {
      this.getStates(newValue);
    },
  },
  created() {
    if (this.countryID) {
      this.getStates(this.countryID);
    }
  },
  data() {
    return {
      data: "",
      error: false,
    };
  },
  methods: {
    async getStates(countryid) {
      this.data = await this.postData(
        `json_autocomplete/${countryid}/state`
      );

      if (!this.data) {
        this.error = true;
      }
    },
  },
};
</script>