<template>
  <div class="content-wrapper">
    <div class="container-full">
      <div class="content-header">
        <div class="row justify-content-between">
          <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xs-6">
            <h1 class="heading1 my-5">SMS Templates Settings</h1>
          </div>
          <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xs-6 text-sm-end">
            <router-link to="/sms-template/add" class="waves-effect waves-light btn btn-info mb-1"><i class="fa fa-plus fs-14"></i> SMS Template</router-link>
          </div>
        </div>
      </div>
      <section class="content">
        <div class="box">
          <div class="box-body">
            <div class="" id="emailTemplate">
              <center>
                <span class="isvalid-feedback" style="display:block;">{{
                  success
                }}</span>
              </center>
              <data-table-server dataType="SmsTemplate" dataMethod="post" >
                <template v-slot="slotProps">
                  <td>{{ slotProps.item.template_name }}</td>
                  <td>{{ slotProps.item.body_content }}</td>
                  <td>
                    <div class="d-flex">
                      <Popper arrow content="Edit sms template" :hover="true" class="helptips cursor-pointer">
                        <a @click="edit(slotProps.item.id)" href="javascript:void(0)" class="waves-effect waves-circle btn btn-circle btn-info btn-xs me-1">
                      	 <i class="fa fa-pencil"></i>
                        </a>
                      </Popper>
                      <Popper arrow content="Preview sms template" :hover="true" class="helptips cursor-pointer mt-0">
                        <a  href="javascript:void(0)" class="waves-effect waves-circle btn btn-circle btn-info btn-xs me-1" data-bs-toggle="modal" data-bs-target="#mypreviewModal" @click="showsmstemplate(slotProps.item.id)">
                            <i class="fa fa-eye"></i>
                          </a>
                        </Popper>
                    </div>
                  </td>
                </template>
              </data-table-server>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
<!--Modal start -->
<div id="mypreviewModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-lg">
		<div class="modal-content">
			<div class="modal-header">
				<h2 class="modal-title m-0">SMS Template Preview</h2>
				<button type="button" class="btn-close btn-xs" data-bs-dismiss="modal" aria-label="Close"></button>
			</div>
			<div class="modal-body">
        <p><strong>SMS Content: </strong></p>
				<div v-html="body_content"></div>
			</div>
      
			<div class="modal-footer">
				<button type="button" class="addpatientbtn btn-danger b-0" data-bs-dismiss="modal">Close</button>
			</div>
		</div>
	</div>
</div>
<!--Modal End -->
</template>

<script>
import DataTableServer from "@/components/base/DataTableServer.vue";
import axios from "@/scripts/axios.js";
import Popper from "vue3-popper";
export default {
  name: "SMS Template",
  components: { DataTableServer, Popper },
  data() {
    return {
      subjectline:'',
      body_content:'',
      filter_category : 'general',
    }
  },
  mounted(){
      const prelink = this.$router.options.history.state.back;
      var name = prelink.split('/');
      if(name[1] != 'sms-template') {
        this.checkPassword();
      }
  },
  methods:{
    checkPassword() {
      this.$swal
        .fire({
          title: '<h2 class="mt-0">Retype your password</h2>',
          input: 'password',
          inputLabel: 'In order to go with settings in SMS Template please reconfirm your password',
          inputPlaceholder: 'Your password',
          dangerMode: true,
          showCancelButton: false,
          confirmButtonColor: "#ee3158",
          cancelButtonColor: "#bdbdbd",
          confirmButtonText: "Yes, confirm it!",
          allowOutsideClick: false,
          allowEscapeKey: false,
          padding: '2em',
          inputAttributes: {
	          autocomplete: 'new-password'
          }
        })
        .then((result) => {
          if (result.isConfirmed == true) {
            axios
              .post(`email-template/check`, { password:result.value })
              .then((response) => {
                if(response.data.data == 'confirm' ) {
                  this.$swal
                  .fire({
                      title: "<h2 class='mt-0'>Success!</h2>",
                      html: "Your password is matched Successfully.",
                      icon:"success",
                      dangerMode: true,
                      confirmButtonColor: "#ee3158",
                      confirmButtonText: "Ok",
                      allowOutsideClick: false,
                      allowEscapeKey: false,
                    })
                  .then((result) => {
                    if (result.isConfirmed == true) {
                    }
                  });
                } else {
                    this.$swal
                    .fire({
                      title: "<h2 class='mt-0'>Error!</h2>",
                      html: "Opps,Your password doesn't mactched, please try again.",
                      icon:"error",
                      dangerMode: true,
                      confirmButtonColor: "#ee3158",
                      confirmButtonText: "Ok",
                      allowOutsideClick: false,
                      allowEscapeKey: false,
                    })
                    .then((result) => {
                      if (result.isConfirmed == true) {
                        this.checkPassword();
                      }
                    });
                  }
              });
          }
        }); 
    },
    edit(id){
      this.$router.push("/sms-template/edit/"+id);
    },
    showsmstemplate(index){
			axios.get('sms-template/edit/'+index)
			.then((response) => {
				this.body_content = response.data.data.body_content
			 });
		}
  },
  beforeUnmount() {
    this.$swal.close();
  }
};
</script>
