import { required,helpers} from "@vuelidate/validators";

const Referral = {
	namespaced: true,
  	
  	state: {
		header: [
			{"user_name_type":"User Type"},
			{"referral_name":"Referral Name"},
			{"added_from":"Added From"},
			{"Action":"Action"},
		  ],
		  pagination: {
			to: "",
			from: "",
			total: "",
			last_page: "",
			current_page: "",
			pageNavArr: [],
		  },
    	form: {},    
    	vuelidateExternalResults: {
	      	form: {
	        	referral_name: [],
				user_credential_id: [],
				added_from: [],
				user_type_id: [],
				user_type: []
	      	},
    	},
    	validationRules: {
	      	form: {
		        referral_name: {
		          required: helpers.withMessage("Referral Name is required", required),
		        },
				user_credential_id: {},
				added_from: {},
				user_type_id: {},
				user_type: {}
	      	},
    	},
    	listUrl: "/referral/list",
    	getEditUrl: "/referral/edit/:id",
    	list: [],
  	},
  	getters: {
    	getTableHeaders: (state) => state.header,
    	getTableContents: (state) => state.list,
    	getListURL: (state) => state.listUrl,
  	},
  	mutations: {},
  	actions: {},
}

export default Referral;