<template let-data>
  <div class="header-template-wrap">
    <div class="header-text">{{data.headerText}}</div>
  </div>
    <div class="e-item-count">{{data.count }} items</div>
</template>

<script>
export default {
  data() {
    return {
      data: {}
    };
  },
  mounted() {
  },
  methods: {
    getClassName: function(data) {
      return "header-icon e-icons " + data.keyField;
    }
  }
};
</script>
