import {
    helpers,
    required,    
  } from "@vuelidate/validators";
  const eob = {
    namespaced: true,  
    state: {
        form: {
            
        },
        defaultFormData: {
            deposit_date: "",
            trace_number: "",
            uploadFiles: ""
        }, 
        vuelidateExternalResults: {
            form: {
                uploadFiles: []
            }            
        },
        validationRules: {
            form: {
                uploadFiles: {
                    required: helpers.withMessage("Please upload file.",required),
                }
            }            
        }   
    },
  };
  
  export default eob;
  