import { required,helpers, maxLength,} from "@vuelidate/validators";

const SmsTemplate = {
	namespaced: true,
  	
  	state: {
		header: [
			{"template_name":"Template Name"},
			{"body_content":"Message Content"},
			{"Action":"Action"},
		  ],
		  pagination: {
			to: "",
			from: "",
			total: "",
			last_page: "",
			current_page: "",
			pageNavArr: [],
		  },
    	form: {},    
    	vuelidateExternalResults: {
	      	form: {
	        	template_name: [],
	        	//subject_line: [],
	        	body_content: [],
	      	},
    	},
    	validationRules: {
	      	form: {
		        template_name: {
		          required: helpers.withMessage("Template Name is required", required),
		        },
		        /* subject_line: {
		          required: helpers.withMessage("Subject is required", required),
		        }, */
		        body_content: {
		          required: helpers.withMessage("Sms Content is required", required),
				  maxLength: helpers.withMessage(() => 'SMS content must be between 1 to 320 characters.',maxLength(320),),
		        },
	      	},
    	},
    	listUrl: "/sms-template/list",
    	getEditUrl: "/sms-template/edit/:id",
    	list: [],
  	},
  	getters: {
    	getTableHeaders: (state) => state.header,
    	getTableContents: (state) => state.list,
    	getListURL: (state) => state.listUrl,
  	},
  	mutations: {},
  	actions: {},
}

export default SmsTemplate;