<template>
  <div class="content-wrapper">
    <div class="container-full">
      <div class="content-header">
        <h1 class="heading1 mt-0">LifeStyle Service Category</h1>
      </div>
      <div class="row">
        <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-2">
            <life-style-header routefrom="CorporateLifeStyleServiceCategory"></life-style-header>
        </div>
        <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-10">
            <div class="box">
              <div class="box-body">
                <form novalidate>   
                  <input-field title="Category Name" v-model="v$.form.category_name.$model" :errors="v$.form.category_name.$errors" :isRequired="true"></input-field>
                </form>
                <div class="row">
                  <div class="col-12 col-sm-12 col-md-12 col-lg-11 col-xl-9 text-center">
                    <form-btn title="Save Category" btnCls="btn-info" @click="save(false)"></form-btn>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputField from "@/components/base/formFields/InputField.vue";
import FormBtn from "@/components/base/formFields/formBtn.vue";
import LifeStyleHeader from "@/components/Lifestyle/LifeStyleHeader.vue";
import useVuelidate from "@vuelidate/core";
import submitMixins from "@/scripts/mixins/submitMixins.js";
import axios from "@/scripts/axios.js";

export default {
  mixins: [submitMixins],
  setup: () => {
    return { v$: useVuelidate() };
  },
  computed: {
    form() {
      return this.$store.state.CorporateLifeStyleServiceCategory.form;
    },
    vuelidateExternalResults() {
      return this.$store.state.CorporateLifeStyleServiceCategory.vuelidateExternalResults;
    },
    validationRules() {
      return this.$store.state.CorporateLifeStyleServiceCategory.validationRules;
    },
    title() {
      return this.$route.name === "CorporateLifeStyleServiceCategoryEdit" ? "Edit Lifestyle Service Category" : "New Lifestyle Service Category";
    },  
  },
  components: {
    InputField,FormBtn,LifeStyleHeader
  },
  data() {
    return {

    }
  },
  validations() {
    return this.$store.state.CorporateLifeStyleServiceCategory.validationRules;
  },
  async beforeCreate(){
    if (this.$route.name === "CorporateLifeStyleServiceCategoryAdd") {
      this.$store.state.CorporateLifeStyleServiceCategory.form = {};
      Object.assign(
        this.$store.state.CorporateLifeStyleServiceCategory.form,
        this.$store.state.CorporateLifeStyleServiceCategory.defaultFormData
      );
    }else{
      // If edit, get data from server
        await this.$store.dispatch("getEditData", {
          dataType: "CorporateLifeStyleServiceCategory", // pass the module name
          formType: "form", // pass the propery name
          id: this.$route.params.id,
        });
    }
  },
  mounted(){
    
  },
  methods:{
    save(){
      if (this.$route.name === "CorporateLifeStyleServiceCategoryEdit") {
        this.postForm("/IV_life_style/category/update", "CorporateLifeStyleServiceCategory", true, "json");
      } else {
        this.postForm("/IV_life_style/category/store", "CorporateLifeStyleServiceCategory", true, "json");
      }
    },
    formPostSuccess(data) {
      this.$store.state.CorporateLifeStyleService.form = data;
      this.$router.push("/corporate/life-style-service-category");
      this.moshaToast(
        `Record ${
          this.$route.name === "CorporateLifeStyleServiceCategoryEdit" ? "updated" : "added"
        } successfully`,
        "success"
      );
    }
  }
}
</script>