<template>
  <div class="content-wrapper">
    <div class="container-full">
      <section class="content">
        <div class="row justify-content-between" v-if="this.$store.state.patientDetail.key == 0">
          <!-- sidebar -->
          <the-side-bar priority="2"></the-side-bar>
          <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-10">
            <the-top-bar
              v-if="this.$store.state.patientDetail.renderComponent"
            ></the-top-bar>
            <component
              v-if="this.$store.state.patientDetail.patient_id"
              :is="$store.state.patientDetailsComp"
            ></component>
          </div>
          <!-- content -->
        </div>

        <div class="box text-center" v-if="this.$store.state.patientDetail.key == 1">
          <div class="box-body py-50">
            <i class="fa fa-frown fs-46 text-danger"></i>
            <div class="content-header">
              <h1 class="fw-300 fs-32 my-20">An error has occurred and we're working to fix the problem! we'll be up and running shortly.</h1>
            </div>
            <a @click="back" href="javascript:void(0)" class="waves-effect waves-light btn btn-info me-1">Reload</a>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import TheSideBar from "@/components/patients/details/TheSidebar.vue";
import TheTopBar from "@/components/patients/details/TheTopbar.vue";
import PatientDetails from "@/components/patients/details/Home.vue";
import PatientDetailsAppointments from "@/components/patients/details/Appointments/Home.vue";
import PatientLockedClinicalNotes from "@/components/patients/details/Documents/LockedClinicalNotes.vue";
import PatientSignedConsentForms from "@/components/patients/details/Documents/SignedConsentForms.vue";
import PatientLabesults from "@/components/patients/details/Documents/LabResults.vue";
import PatientClinicalDashboard from "@/components/patients/details/ClinicalDashboard/Home.vue";
import PatientDemographics from "@/components/patients/details/PatientInformation/PatientDemographics.vue";
import PatientIdentification from "@/components/patients/details/PatientInformation/PatientIdentification.vue";
import PatientOtherIdentification from "@/components/patients/details/PatientInformation/PatientOtherIdentification.vue";
import PatientInsurance from "@/components/patients/details/PatientInformation/PatientInsurance.vue";
import PatientProblemList from "@/components/patients/details/ProblemList.vue";
import PatientVitals from "@/components/patients/details/Important/Vitals.vue";
import PatientImplantableDevice from "@/components/patients/details/ImplantableDevice.vue";
import PatientDetailsLaborders from "@/components/patients/details/Laborders/Home.vue";
import PatientDetailsLabordersEdit from "@/components/patients/details/Laborders/Home.vue";
import DocumentUpload from "@/components/patients/details/Documents/Home.vue";
import RadiologyOrder from "@/components/patients/details/RadiologyOrder/Home.vue";
import RadiologyOrderEdit from "@/components/patients/details/RadiologyOrder/Home.vue";
import SmokingStatus from "@/components/patients/details/Important/SmokingStatus.vue";
/*import ClinicalNoteHandP from "@/components/patients/details/ClinicalNote/HandP/Home.vue";
import ClinicalNoteSoap from "@/components/patients/details/ClinicalNote/SOAP/Home.vue";*/
import ClinicalNotesList from "@/components/patients/details/ClinicalNote/Home.vue";
import AllNotes from "@/components/patients/details/PurewellNessNote/AllNotes.vue";
import RefillNotes from "@/components/patients/details/PurewellNessNote/RefillNotes.vue";
import WcNotes from "@/components/patients/details/PurewellNessNote/WcNotes.vue";
import GeneralNotes from "@/components/patients/details/PurewellNessNote/GeneralNotes.vue";
import MedNotes from "@/components/patients/details/PurewellNessNote/MedNotes.vue";
import NewNotes from "@/components/patients/details/PurewellNessNote/NewNotes.vue";
import ClinicalNoteAdditional from "@/components/patients/details/ClinicalNote/Additional/Home.vue";
import PatientAmendmentsedit from "@/components/patients/details/ClinicalNote/Amendments.vue";
import PatientAmendments from "@/components/patients/details/ClinicalNote/Amendments.vue";
import CQM from '@/components/patients/details/CQM/Home.vue';
import CRM from '@/components/patients/details/CRM/Home.vue';
import Questionnarie from '@/components/patients/details/Questionnarie/Home.vue';
import Tabview from '@/components/patients/details/Questionnarie/Tabview.vue';
import CDS from '@/components/patients/details/CDS/Home.vue';
import PatientDetailsimmunizations from "@/components/patients/details/Immunizations/Home.vue";
import PatientAuditLogs from "@/components/patients/details/PatientAuditLogs/Home.vue";
import HealthCareBilling from "@/components/patients/details/Payment/HealthCareBilling.vue";
import MainBilling from "@/components/patients/details/Payment/MainBilling.vue";
import MainInsuranceBilling from "@/components/patients/details/Payment/MainInsuranceBilling.vue";
import ECommerceBilling from "@/components/patients/details/Payment/ECommerceBilling.vue";
import BillingSummary from "@/components/patients/details/Payment/BillingSummary.vue";
import BillingPOS from "@/components/patients/details/Payment/BillingPOS.vue";
import BillingContract from "@/components/patients/details/Payment/BillingContract.vue";
import BillingContractTmp from "@/components/patients/details/Payment/BillingContractTmp.vue";
import Wallet from "@/components/patients/details/Payment/Wallet.vue";
import CreditCardList from "@/components/patients/details/Payment/CreditCardList.vue";
import PackageList from "@/components/patients/details/Payment/PackageList.vue";
import PatientInsuranceInfo from "@/components/patients/details/Important/PatientInsuranceInfo.vue";
import MedAndAllergy from "@/components/patients/details/MedAndAllergy/Home.vue";
import Allergy from "@/components/patients/details/MedAndAllergy/Allergy.vue";
import TelehealthLog from "@/components/patients/details/Telehealth/Home.vue";

/*New*/
import PatientMedications from "@/components/patients/details/Medications/Home.vue";
import PatientAllergies from "@/components/patients/details/Allergies/Home.vue";




export default {
  components: {
    TheSideBar,
    TheTopBar,
    PatientDetails,
    PatientDetailsAppointments,
    PatientDetailsLaborders,
    PatientDetailsLabordersEdit,
    PatientProblemList,
    PatientVitals,
    PatientImplantableDevice,
    PatientLockedClinicalNotes,
    PatientSignedConsentForms,
    PatientLabesults,
    PatientAmendments,
    PatientAmendmentsedit,
    PatientClinicalDashboard,
    PatientDemographics,
    PatientIdentification,
    PatientInsurance,
    PatientProblemList,
    PatientVitals,
    PatientImplantableDevice,
    TheTopBar,
    SmokingStatus,
    DocumentUpload,
    RadiologyOrder,
    RadiologyOrderEdit,
    /*ClinicalNoteHandP,
    ClinicalNoteSoap,*/
    ClinicalNoteAdditional,
    ClinicalNotesList,
    AllNotes,
    RefillNotes,
    WcNotes,
    GeneralNotes,
    MedNotes,
    NewNotes,
    CQM,
    CRM,
    Questionnarie,
    Tabview,
    CDS,
    PatientDetailsimmunizations,
    PatientAuditLogs,
    MainBilling,
    MainInsuranceBilling,
    HealthCareBilling,
    ECommerceBilling,
    BillingSummary,
    BillingPOS,
    BillingContract,
    BillingContractTmp,
    Wallet,
    CreditCardList,
    PackageList,
    MedAndAllergy,
    Allergy,
    PatientOtherIdentification,
    PatientMedications,
    PatientAllergies,
    PatientInsuranceInfo,
    TelehealthLog
  },
  data() {
    return {};
  },
  created() {
    this.$store.state.patientDetailsComp = this.$route.name;
  },
  methods: {
    back(){
      location.reload();
    }
  },
  mounted() {},
};
</script>