<template>
	<div class="panel-group panel-group-simple panel-group-continuous" id="category-1" aria-multiselectable="true" role="tablist">
		<div class="panel pagestabscoll">
			<p>NOTE: Changes will take effect on next Login.</p>
			<div class="panel-heading" id="question-1" role="tab">
				<a class="panel-title d-block mb-0" aria-controls="answer-1" aria-expanded="true" data-bs-toggle="collapse" href="#answer-1" data-parent="#accordion1">General Settings
					<Popper arrow content="It is a used to set a default page when the user logs in." :hover="true" class="helptips cursor-pointer">
						<i class="fa fa-info-circle fs-14"></i>
					</Popper>
				</a>
				<div class="panel-collapse collapse mt-15 show" id="answer-1" aria-labelledby="question-1" role="tabpanel" data-bs-parent="#category-1">
					<div class="form-group row">
						<label class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end">Default Homepage</label>
						<div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3">
							<div class="form-group mb-0">
								<input type="radio" checked v-model="default_homepage" id="default_home_dashboard" name="DefaultHomepage" value="true" class="filled-in chk-col-info" @change="homepagechange('Dashboard')"/>
								<label for="default_home_dashboard">Dashboard</label>
							</div>

							<div class="form-group mb-0">
								<input type="radio" v-model="default_homepage_chk" id="default_home_calendar" name="DefaultHomepage" value="true" class="filled-in chk-col-info" @change="homepagechange('Calendar')"/>
								<label for="default_home_calendar">Calendar</label>
							</div>
						</div>
					</div>

					<div class="form-group row">
						<label class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end">Google Authentication</label>
						<div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3">
							<select class="form-select" v-model="google_authentication">
								<option value="" selected="selected" disabled="disabled">select Google Authentication Status </option>
								<option value="true">Enable</option>
								<option value="false">Disable</option>
							</select>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="panel pagestabscoll">
			<div class="panel-heading" id="question-2" role="tab">
				<a class="panel-title d-block mb-0" aria-controls="answer-2" aria-expanded="false" data-bs-toggle="collapse" href="#answer-2" data-parent="#accordion2">Calendar</a>
				<div class="panel-collapse collapse" id="answer-2" aria-labelledby="question-2" role="tabpanel" data-bs-parent="#category-1">          
					<div class="form-group row">
						<label class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end">Default Office <small class="text-danger asterisksign" style="display: none;">*</small> </label>
						<div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3">
							<div class="d-flex">
								<div class="controls w-p100 me-2">
									<select id="office_access" name="select" required="" class="select2Single form-select form-control" v-model="office_access">
										<option value="">Select Default Office</option>
										<option v-for="item in getOffice" :value="item.id">{{item.name}}</option>
									</select>
								</div>
								<Popper arrow content="It will enable the user to define a default office, which will be autopopulated when scheduling an appointment." :hover="true" class="helptips cursor-pointer">
									<i class="fa fa-info-circle fs-14"></i>
								</Popper>
							</div>
						</div>
					</div>
					<div class="form-group row">
						<label class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end">Duration Of Exam
						</label>

						<div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3">
							<div class="d-flex">
								<div class="controls availdate position-relative w-p100 me-2">
									<ejs-numerictextbox format="###" id="exam_duration" :value="duration_value" :step="step" :min="min"></ejs-numerictextbox>
									<small class="form-text text-muted position-absolute" style="right: 60px;top: 2px;">Minutes</small>
								</div>
								<Popper arrow content="This enables the user to customise the calendar's time slots according to their preferences." :hover="true" class="helptips cursor-pointer">
									<i class="fa fa-info-circle fs-14"></i>
								</Popper>
							</div>
						</div>
					</div>
					<div class="form-group row align-items-center">
						<label class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end">Minimum time gap between appointment slot</label>
						<div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3">
							<div class="d-flex">
								<div class="controls position-relative availdate w-p100 me-2">
									<ejs-numerictextbox format="###" id="calendar_increament" :value="cal_value" :step="step" :min="min"></ejs-numerictextbox>
									<small class="form-text text-muted position-absolute" style="right: 60px;top: 2px;">Minutes</small>
								</div>
								<Popper arrow content="Specify the time gap you require for the fields of Schedule start time and End time while scheduling an appointment" :hover="true" class="helptips cursor-pointer">
									<i class="fa fa-info-circle fs-14"></i>
								</Popper>
							</div>
						</div>
					</div>
					<div class="form-group row">
						<label class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end">Default Calendar View</label>
						<div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3">
							<div class="d-flex">
								<div class="controls w-p100 me-2">
									<select
										name="default_calendar"
										v-model="calendar_view"
										id="default_calendar"
										class="form-select form-control"
										>
										<option value="" disabled>Select Calendar View</option>
										<option value="Monthly">Monthly</option>
										<option value="Day">Day</option>
										<option value="Week">Week</option>
										<option value="Room">Room</option>
										<option value="Provider">Provider</option>
									</select>
								</div>
								<Popper arrow content="Choose the calendar's default view (e.g., today, day, room, month, week, provider). When you access the calendar, the selected view will be displayed." :hover="true" class="helptips cursor-pointer">
									<i class="fa fa-info-circle fs-14"></i>
								</Popper>
							</div>
						</div>
					</div>
			</div>
		</div>
	</div>

	<div class="panel pagestabscoll">
		<div class="panel-heading" id="question-3" role="tab">
			<a class="panel-title d-block mb-0" aria-controls="answer-3" aria-expanded="false" data-bs-toggle="collapse" href="#answer-3" data-parent="#accordion1">Clinical Note</a>
			<div class="panel-collapse collapse" id="answer-3" aria-labelledby="question-3" role="tabpanel" data-bs-parent="#category-1">          
				<div class="form-group row">
					<label class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end">Display generated date in the PDF?</label>
					<div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3">
						<div class="controls d-flex">
							<select id="office_access" name="select" required="" class="select2Single form-select form-control me-2" v-model="clinical_note_generated">
								<option  selected="" disabled="" value="">Select Clinical Note Generated</option>
								<option value="yes">Yes</option>
								<option value="no">No</option>
							</select>
							<Popper arrow content="It is used if the user wants the generated date to appear in the clinical note PDF." :hover="true" class="helptips cursor-pointer">
								<i class="fa fa-info-circle fs-14"></i>
							</Popper>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="panel pagestabscoll">
		<div class="panel-heading" id="question-top-header" role="tab">
			<a class="panel-title d-block mb-0" aria-controls="answer-top-header" aria-expanded="false" data-bs-toggle="collapse" href="#answer-top-header" data-parent="#accordion_top_header">Recent Patients</a>
			<div class="panel-collapse collapse" id="answer-top-header" aria-labelledby="question-top-header" role="tabpanel" data-bs-parent="#category-1">          
				<div class="form-group row">
					<label class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end" for="show_recently_viewed_patient">Do you want to show recently viewed patients?</label>
					<div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3">
						<div class="controls d-flex">
							<select id="show_recently_viewed_patient" name="select" required="" class="select2Single form-select form-control me-2" v-model="show_recently_viewed_patient">
								<option  selected="" disabled="" value="">Select Option</option>
								<option value="yes">Yes</option>
								<option value="no">No</option>
							</select>
							<Popper arrow content="It is used to enable recently viewed patients alongside of the PureEHR logo." :hover="true" class="helptips cursor-pointer">
								<i class="fa fa-info-circle fs-14"></i>
							</Popper>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="panel pagestabscoll" v-if="this.$store.state.auth.userData.user_type != null && 
              this.$store.state.auth.userData.user_type == 'provider' && 
              permissioncheck(this.$store.state.permission.provider_staff_account)">
		<div class="panel-heading" id="question-top-header" role="tab">
			<a class="panel-title d-block mb-0" aria-controls="answer-top-header" aria-expanded="false" data-bs-toggle="collapse" href="#edit-provider-account" data-parent="#accordion_top_header">Edit Provider</a>
			<div class="panel-collapse collapse" id="edit-provider-account" aria-labelledby="question-top-header" role="tabpanel" data-bs-parent="#category-1">
				<a
					:href="'#/provider/edit/'+this.$store.state.auth.userData.user_id"
					class="me-1 mb-1" target="_blank"
					>{{window.location.host}}/#/provider/edit/{{this.$store.state.auth.userData.user_id}}</a>
			</div>
		</div>
	</div>

	<div class="panel pagestabscoll" v-if="this.$store.state.auth.userData.user_type != null && 
              this.$store.state.auth.userData.user_type == 'staff' && 
              permissioncheck(this.$store.state.permission.provider_staff_account)">
		<div class="panel-heading" id="question-top-header" role="tab">
			<a class="panel-title d-block mb-0" aria-controls="answer-top-header" aria-expanded="false" data-bs-toggle="collapse" href="#edit-staff-account" data-parent="#accordion_top_header">Edit Staff</a>
			<div class="panel-collapse collapse" id="edit-staff-account" aria-labelledby="question-top-header" role="tabpanel" data-bs-parent="#category-1">
				<a
					:href="'#/staff/edit/'+this.$store.state.auth.userData.user_id"
					class="me-1 mb-1" target="_blank"
					>{{window.location.host}}/#/staff/edit/{{this.$store.state.auth.userData.user_id}}</a>
			</div>
		</div>
	</div>
</div>

<div class="row">
	<div class="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-9 text-center">
		<button type="button" class="waves-effect waves-light btn mb-5 me-1 btn btn-info" @click="save()">Save</button>
		<button type="button" class="waves-effect waves-light btn mb-5 me-1 btn-danger" @click="cancel()">Cancel</button>
	</div>
</div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
// import 'jquery/dist/jquery.min.js';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import "datatables.net-dt/js/dataTables.dataTables"
// import "datatables.net-dt/css/jquery.dataTables.min.css"
import axios from "@/scripts/axios.js";
import dataTable from "@/components/base/DataTable.vue";
import submitMixins from "@/scripts/mixins/submitMixins.js";

import Autocomplete from 'vue3-autocomplete';
import useVuelidate from "@vuelidate/core";
import TableBase from "@/components/base/Table.vue";
import InputField from "@/components/base/formFields/InputField.vue";
import SelectBoxStatic from "@/components/base/formFields/SelectBoxStatic.vue";
import permissionMixins from "@/scripts/mixins/permission.js";
import SelectBox from "@/components/base/formFields/selectBox.vue";
import moment from 'moment';
import Popper from "vue3-popper";
import { mapGetters, mapActions } from "vuex";
export default {
	setup: () => {
		return { v$: useVuelidate() };
	},

	mixins: [submitMixins,permissionMixins],
	components: { Autocomplete, TableBase,InputField,SelectBox,SelectBoxStatic,dataTable,Popper },
	data() {

		return {
			duration_value:15,
			cal_value:15,
			min: 5,
			step: 5,
			selected : '',
			office_access : '',
			clinical_note_generated :'',
			show_recently_viewed_patient :'',
			calendar_view : '',
			default_homepage: true,
			default_homepage_chk : false,
			getOffice:[],
			user_credential_id:'',
			default_page: 'Dashboard',
			google_authentication:'false',
		}
	},
	watch: {
		"user": function() {
			this.getuser();
		},
	},
	computed: {
		...mapGetters("auth", ["user", "provider_list", "active_provider"]),
	},
	mounted(){
		this.getuser();
	},
	methods: {
		getuser(){
			if (this.user) {
				this.user_credential_id = this.user ? this.user.user_credential_id : 0;
				if(this.user.user_type == "provider"){
					var url = 'provider/office_access/'+this.user.user_id;
				}
				if(this.user.user_type == "staff"){
					var url = 'staff/office_access/'+this.user.user_id; 
				}
				axios.get(url)
				.then((response) => {
					this.getOffice = response.data.data;
				});
				this.getsetting(this.user_credential_id);
			}
		},
		getsetting(id){
			this.duration_value = 15;
			this.cal_value = 15;
			this.$store.state.loader = true;
			axios.get('provider/get-setting/'+id)
			.then((response) => {
				this.office_access = (response.data.data.office_access) ? response.data.data.office_access : '';
				this.duration_value = (response.data.data.exam_duration) ? response.data.data.exam_duration : '15';
				this.cal_value = (response.data.data.calendar_increament) ? response.data.data.calendar_increament : '15';
				this.calendar_view = (response.data.data.calendar_view) ? response.data.data.calendar_view : '';
				this.default_page = (response.data.data.default_homepage) ? response.data.data.default_homepage : 'Dashboard';
				this.show_recently_viewed_patient = (response.data.data.show_recently_viewed_patient) ? response.data.data.show_recently_viewed_patient : '';
				this.google_authentication = (response.data.data.google_authentication) ? response.data.data.google_authentication : 'false';
				if(response.data.data.default_homepage == 'Dashboard') {
					this.default_homepage_chk = false
					this.default_homepage = true
		  // document.getElementById("default_home_dashboard").checked = true;  
		} else if(response.data.data.default_homepage == 'Calendar'){
			this.default_homepage_chk = true
			this.default_homepage = false
		  // document.getElementById("default_home_calendar").checked = true;  
		}
		this.$store.state.loader = false;
	})
		},
		save() {
			var office_access = document.getElementById('office_access').value;
			var exam_duration = document.getElementById('exam_duration').value;
			var calendar_increament = document.getElementById('calendar_increament').value;
			var default_calendar = document.getElementById('default_calendar').value;
			var default_homepage = this.default_page;
			axios.post('provider/general-setting',{'office_access':office_access,'exam_duration':exam_duration,'calendar_increament':calendar_increament,'calendar_view':default_calendar,'default_homepage':default_homepage,'user_credential_id':this.user_credential_id,'clinical_note_generated':this.clinical_note_generated, 'show_recently_viewed_patient' : this.show_recently_viewed_patient,'google_authentication':this.google_authentication})
			.then((response) => {
				this.moshaToast("Record updated successfully.", "success");    
				this.getsetting(this.user_credential_id);
			})    
		},
		cancel() {
			this.getsetting(this.user_credential_id);
		},
		homepagechange(value) {
			this.default_page = value;
			if(value == 'Dashboard') {
				this.default_homepage_chk = false
				this.default_homepage = true
			} else if(value == 'Calendar'){
				this.default_homepage_chk = true
				this.default_homepage = false
			}
		},
		// getEditProfileLink(id, source='provider') {
		// 	if (source == 'staff') {
		// 		this.$router.push({ name: "ProviderEdit", params: { id } });
		// 	} else {
		// 		this.$router.push({ name: "Staffedit", params: { id } });
		// 	}
		// }
	},
};
</script>