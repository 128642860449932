<template>
	<div class="content-wrapper">
		<div class="container-fluid">

           <h1 class="mb-20 text-center">Software Information</h1> 
        <div class="row justify-content-center">
            <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="box">
                    <div class="box-body">
                        <div class="table-responsive">
                            <table class="table table-bordered software_Info_tbl">
                                <tr>
                                    <th>
                                        Software Name
                                    </th>
                                    <td>
                                        PureEHR
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        Software Current Version
                                    </th>
                                    <td>
                                        1.0
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>
</template>