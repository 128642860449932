<template>
	<div class="box p-15">
		<div class="mdc-tab">
			<div class="tab-content">
				<div class="tab-pane active" id="mainBilling" role="tabpanel">
					<slot :set="record={total_billed:0,total_claimed_accepted_amount:0,total_due:0,patient_responsibility:0,copay_coinsurance_received:0,adjustments:0}">
						<slot v-for="(item, index) in list" :key="index">
							<slot v-if="item.claim_amount!=null" :set="record.total_billed+=parseInt(item.claim_amount)"></slot>
							<slot v-if="item.total_claimed_accepted_amount!=null" :set="record.total_claimed_accepted_amount+=parseInt(item.total_claimed_accepted_amount)"></slot>
							<slot v-if="item.patient_responsibility!=null" :set="record.patient_responsibility+=(parseInt(item.patient_responsibility))"></slot>
							<slot v-if="item.copay_coinsurance_received!=null" :set="record.copay_coinsurance_received+=parseInt(item.copay_coinsurance_received)"></slot>
							<slot v-if="item.total_claimed_accepted_amount == 0 && (item.patient_responsibility == 0 || item.patient_responsibility == null)">
								<slot v-if="item.adjustment_amount!=null" :set="record.adjustments+=parseInt(0)"></slot>
							</slot>
							<slot v-else>
								<slot v-if="item.adjustment_amount!=null" :set="record.adjustments+=parseInt(item.adjustment_amount)"></slot>
							</slot>
						</slot>
						<slot :set="record.total_due=((parseInt(record.total_billed)+parseInt(record.patient_responsibility))-parseInt(record.total_claimed_accepted_amount)-parseInt(record.copay_coinsurance_received))"></slot>
					</slot>
					<div class="box bg-lightest p-10 my-2">
					   <div class="text-md-right">
							<span class="waves-effect waves-light badge badge-info-light mb-1 me-1 pull-left" style="font-weight: 500;">Total Claimed:{{ $filters.toUSD(record.total_billed) }}</span>

							<span class="waves-effect waves-light badge badge-info-light mb-1 me-1 pull-left" style="font-weight: 500;">Total Payer Paid:{{ $filters.toUSD(record.total_claimed_accepted_amount) }}</span>

							<span class="waves-effect waves-light badge badge-info-light mb-1 me-1 pull-left" style="font-weight: 500;" title="Total Patient Responsibility">Total Pr. Res.:{{ $filters.toUSD(record.patient_responsibility) }}</span>

							<span class="waves-effect waves-light badge badge-info-light mb-1 me-1 pull-left" style="font-weight: 500;" title="Total Patient Responsibility Paid">Total Pr. Res. Paid: {{ $filters.toUSD(record.copay_coinsurance_received) }}</span>

							<span class="waves-effect waves-light badge badge-info-light mb-1 me-1 pull-left" style="font-weight: 500;" title="Total Patient Responsibility Paid">Total Adjustment: {{ $filters.toUSD(record.adjustments) }}</span>

							<a href="javascript:void(0)" class="waves-effect waves-light btn btn-info mb-1 me-1" @click="newApptBilling"><i class="fa fa-plus fs-14"></i> Appt. Billing</a>
						</div>
					</div>
					<div class="table-responsive">
						<table class="table table-bordered insu_chart-tbl fs-16">
							<thead>
								<tr class="text-center">
									<th>Check/Trace #</th>
									<th>Schedule Date</th>
									<th>Claim Amount</th>
									<th title="Patient Responsibility Amount">Pr. Res.</th>
									<th title="Patient Responsibility Paid Amount">Pr. Paid</th>
									<th>Adjustment</th>
									<th>Claim Status</th>
									<th>Posting Status</th>
									<th title="Insurance Paid">Ins. Paid</th>
									<th title="Insurance Balance">Ins. Balance</th>
                                    <th title="Patient Balance">Pr. Balance</th>
									<th>Action</th>
								</tr>
							</thead>
							<slot v-if="list.length>0">
								<tbody>
									<slot v-for="(item, index) in list" :key="index">
										<tr class="text-center">
											<td>
												<slot v-if="item.remit_trace_number">
													{{ item.remit_trace_number }}
												</slot>
												<slot v-else>
													-
												</slot>
											</td>
											<td>{{ $filters.displayDateTimeLocal(item.schedule_date,'',false,"ddd, MM-DD-YYYY",true) }} ( #{{ item.appointment_number }} )</td>
											<td>{{ $filters.toUSD(item.claim_amount) }}</td>
											<td>
												{{ $filters.toUSD(item.patient_responsibility) }} <br>
												<slot v-if="item.patient_adjustment_reason_code!=null">
													<Popper arrow :hover="true" class="helptips cursor-pointer">
														<div class="div-circle me-1">PR</div>
														<template #content>
															<div>PR : Patient Responsibility</div>
															<hr style="margin: 0rem 0;">
															<slot v-for="(value,index1) in item.patient_adjustment_reason_code.split(',')" :key="index1">
																<div>{{value+' : '+claim_adjustment_codes[value]}}</div>
															</slot>
														</template>	
													</Popper>
												</slot>
											</td>
											<td> {{ $filters.toUSD(item.copay_coinsurance_received) }} </td>
											<td>
												{{ $filters.toUSD(item.adjustment_amount) }}												
												<slot v-if="item.adjustments_codes!=null">
													<slot :set="reason_code=item.adjustment_reason_code.split(',')"></slot>
													<slot :set="co_array=[]"></slot>	
													<slot :set="oa_array=[]"></slot>	
													<slot :set="pi_array=[]"></slot>
													<slot v-for="(value,index1) in item.adjustments_codes.split(',')" :key="index1">
														<slot :set="aj_detail={grp_code:value+' : '+claim_adjustment_group_codes[value],reason_code:reason_code[index1]+' : '+claim_adjustment_codes[reason_code[index1]] }"></slot>
														<slot v-if="value=='CO'" :set="co_array.push(reason_code[index1]+' : '+claim_adjustment_codes[reason_code[index1]])"></slot>	
														<slot v-if="value=='OA'" :set="oa_array.push(reason_code[index1]+' : '+claim_adjustment_codes[reason_code[index1]])"></slot>	
														<slot v-if="value=='PI'" :set="pi_array.push(reason_code[index1]+' : '+claim_adjustment_codes[reason_code[index1]])"></slot>	
													</slot>
													<slot v-if="co_array.length>0">
														<Popper arrow :hover="true" class="helptips cursor-pointer">			
															<div class="div-circle me-1">CO</div>
															<template #content>
																<div>CO : Contractual Obligations</div>
																<hr style="margin: 0rem 0;">
																<slot v-for="(value,index1) in co_array" :key="index1">
																	<div>{{value+' : '+claim_adjustment_codes[value]}}</div>
																</slot>
															</template>	
														</Popper>
													</slot>
													<slot v-if="oa_array.length>0">
														<Popper arrow :hover="true" class="helptips cursor-pointer">			
															<div class="div-circle me-1">OA</div>
															<template #content>
																<div>OA : Other adjustments</div>
																<hr style="margin: 0rem 0;">
																<slot v-for="(value,index1) in oa_array" :key="index1">
																	<div>{{value+' : '+claim_adjustment_codes[value]}}</div>
																</slot>
															</template>	
														</Popper>
													</slot>
													<slot v-if="pi_array.length>0">
														<Popper arrow :hover="true" class="helptips cursor-pointer">			
															<div class="div-circle me-1">PI</div>
															<template #content>
																<div>PI : Payor Initiated Reductions</div>
																<hr style="margin: 0rem 0;">
																<slot v-for="(value,index1) in pi_array" :key="index1">
																	<div>{{value+' : '+claim_adjustment_codes[value]}}</div>
																</slot>
															</template>	
														</Popper>
													</slot>
												</slot>
											</td>
											<td>{{ capitalizeFirstLetter(item.claim_status.replaceAll("_"," ")) }}</td>
											<td> {{ item.posting_status }} </td>
											<td> {{ $filters.toUSD(item.total_claimed_accepted_amount) }} </td>
											<td>
												<slot v-if="item.is_in_eob_batch==1">
													{{ $filters.toUSD(item.eob_insurance_balance) }}
												</slot>
												<slot v-else>
													<slot v-if="item.total_claimed_accepted_amount == 0 && (item.patient_responsibility == 0 || item.patient_responsibility == null)">
														{{ $filters.toUSD(item.claim_amount-item.adjustment_amount) }}
													</slot>
													<slot v-else>
														-
													</slot>
												</slot>
                                            </td>
                                            <td>
												<slot v-if="item.is_in_eob_batch==1">
													{{ $filters.toUSD(item.eob_patient_balance) }}
												</slot>
												<slot v-else>
													{{ $filters.toUSD((item.patient_balance_amount-(item.copay_coinsurance_received+item.adjustment_amount))) }}
												</slot>
											</td>
											<td class="ps-15">
												<a href="javascript:void(0)" class="waves-effect waves-light btn btn-info btn-sm me-1 mb-1" @click="makeOrderPayment(item.appointment_id)"><i class="fa fa-plus fs-12"></i>Payment</a>												

												<a href="javascript:void(0)" class="waves-effect waves-light btn btn-info-light btn-sm me-1 mb-1" @click="apptBillingModal(item)"><i class="fa fa-pencil fs-12"></i> Billing Codes</a>
												
												<a href="javascript:void(0)" v-if="item.scanned_EOB != null"  class="waves-effect waves-light btn btn-info btn-sm me-1 mb-1" @click="viewEOB(item.scanned_EOB)"> EOB </a>
												
												<slot v-if="item.patient_responsibility_type!=null">
													<Popper arrow content="View Receipt" :hover=true class="helptips">
														<a href="javascript:void(0)" class="waves-effect waves-circle btn btn-circle btn-info btn-xs me-1" @click ="printReceipt(item.id)">
															<i class="fa fa-eye"></i>
														</a>
													</Popper>
												</slot>
												<a href="javascript:void(0)" class="waves-effect waves-light btn btn-info btn-sm me-1 mb-1" @click="makeAdjustmentAmount(item.id,item.adjustment_amount)"><i class="fa fa-upload fs-12"></i></a>
											</td>
										</tr>
									</slot>
								</tbody>
							</slot>
							<tbody v-if="list.length==0">
								<tr>
									<td colspan="12" class="text-center"> No data available</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
<!--show Add Billing start-->
	<div class="modal fade" id="addbilling" tabindex="-1" role="dialog" aria-labelledby="addbilling" aria-hidden="true">
		<div class="modal-dialog modal-xl">
			<div class="modal-content">
				<div class="modal-header">
					<h3 class="modal-title fw-900 mt-0">Add Billing</h3>
			   </div>
			   <div class="modal-body" style="max-height: 720px;overflow: auto;">
					<div class="form-group row align-items-center appointment">
						<div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end">
							<label class="mb-0">Select Appointment</label>
						</div>
					 	<div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3">
							<select class="select2Single form-select form-control" v-model="appointment_id" id="appointment_id"  @change="changeAppointment($event)">
								<option value="" disabled="disabled">Select Appointment</option>
								<template  v-for="item in apptDropdownList"  :key="item.id">
									<option v-if="item.imported_at == null " :value="item.id" :data-val="item.appointment_number">
										{{item.name}}
									</option>
								</template>
								<optgroup label="Imported Appointment">
									<template  v-for="item in apptDropdownList"  :key="item.id">
										<option :value="item.id" v-if="item.imported_at != null " :data-val="item.appointment_number" data-type="imported">
											{{ item.name }}
										</option>
									</template>
								</optgroup>
							</select>
							<div class="text-danger appointment_error d-none">Please select appointment </div>
					 	</div>
				    </div>
					<AppointmentBilling v-if="appointment_number" :is_inline_nav="is_inline_nav" :AppointmentNumber="appointment_number" :permission="permissioncheck(this.$store.state.permission.create_update)" :key="appointment_number" disabled="true" paymentProfile="Insurance" />
			   </div>
			</div>
		</div>
	</div>
<!--show Add Billing end-->

<!--show Make Payment start-->
	<div class="modal fade" id="makepayment" tabindex="-1" role="dialog" aria-labelledby="makepayment" aria-hidden="true">
		<div class="modal-dialog" style="max-width: 600px;">
			<div class="modal-content">
				<div class="modal-header">
					<h3 class="modal-title fw-900 mt-0">Make Payment</h3>
			   </div>
			   <div class="modal-body">
					<CopayInsurance :appointmentId="appointment_id"  :key="appointment_id"></CopayInsurance>
			   </div>
			   <div class="modal-footer">
					<button type="button" class="waves-effect waves-light btn btn-danger" data-bs-dismiss="modal" @click="CopayInsuranceClose">Close</button>
			   </div>
			</div>
		</div>
	</div>
<!--show Make Payment end-->
<!--viewReceipt Modal Start-->
	<div class="modal" id="viewReceipt" tabindex="-1">
		<div class="modal-dialog modal-md" style="max-width:75%">
			<div class="modal-content">
				<div class="modal-header">
					<button type="button" class="btn-close btn-xs" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">
					<vue-pdf-viewer height="1050px" :url="receipt_url" v-if="embed=0">
					</vue-pdf-viewer>
					<iframe :src="receipt_url" width="100%" height="500px" v-if="embed=1" />
					<img srd="images/1.gif" v-else> 
				</div>
			</div>
		</div>
	</div>
<!--viewReceipt Modal end-->
<!--AdjustmentAmount Modal Start-->
	<div class="modal" id="adjustmentAmountmodal" tabindex="-1">
		<div class="modal-dialog modal-md">
			<div class="modal-content">
				<div class="modal-header">
					<button type="button" class="btn-close btn-xs" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">
					<div class="form-group row mb-5">
						<label class="col-form-label mb-0 col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 text-md-end">Adjustment Amount </label>
						<div class="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6 mb-5">
							<div class="input-group">
								<span class="input-group-addon">$</span>
								<input type="text" class="form-control" placeholder="Insurance Amount" v-model="Adjustmentform.adjustment_amount" v-on:keypress="numbersOnly">
							</div>
						</div>
					</div>
				</div>
				<div class="modal-footer">
				<button type="button" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
				<button type="button" @click="saveAdjustmentform" class="waves-effect waves-light btn btn-info">Save</button>
			</div>
			</div>
		</div>
	</div>
<!--AdjustmentAmount Modal end-->

</template>
<script>
	import FormBtn from "@/components/base/formFields/formBtn.vue";
	import axios from "@/scripts/axios.js";
	import AppointmentBilling from "@/views/Appointments/AppointmentBilling.vue";
	import CopayInsurance from "@/components/patients/details/Payment/CopayInsurance.vue";
	import RefundDialog from "@/components/patients/details/Payment/RefundDialog.vue";
	import permissionMixins from "@/scripts/mixins/permission.js";
	import Popper from "vue3-popper";
	import submitMixins from "@/scripts/mixins/submitMixins.js";
	import FileUpload from "@/components/base/formFields/fileUpload.vue";
	import Autocomplete from 'vue3-autocomplete';
	import InputField from "@/components/base/formFields/InputField.vue";
	import VuePDFViewer from 'vue-instant-pdf-viewer';
	export default {
		mixins: [permissionMixins,submitMixins],
		components: {
			AppointmentBilling,
			CopayInsurance,
			RefundDialog,
			Popper,
			FileUpload,
			Autocomplete,
			InputField,
			FormBtn,
			'vue-pdf-viewer': VuePDFViewer,
		},
		data() {
			return {
				claim_adjustment_codes:[],
				claim_adjustment_group_codes:[],
				list:[],
				patient_order_summary:[],
				appointment_number : '',
				appointment_id :null,
				order_id :'',
				order_payment_id :'',
				is_inline_nav : true,
				receiptlist:[],      
				receipt_url: '',
				new_pos: false,
				apptDropdownList: [],
				embed: 0,
				current_billing_order_id:'',				
				payment_type:'',
				Adjustmentform:{
					id:null,
					adjustment_amount:null,
				}
			};
		},
		created() {
			this.patientInsuranceOrderList();
			this.getApptDropdownList();
		},
		methods: {
			numbersOnly(evt) {
				evt = (evt) ? evt : window.event;
				var charCode = (evt.which) ? evt.which : evt.keyCode;
				if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
					evt.preventDefault();
				} else {
					return true;
				}
			},
			saveAdjustmentform(){
				axios.post("/claim/adjustmentUpdate",this.Adjustmentform)
				.then((response) => {
					$('#adjustmentAmountmodal').modal('hide');
					this.patientInsuranceOrderList();
				});
			},
			makeAdjustmentAmount(id,adjustment_amount){
				this.Adjustmentform.id = id;
				this.Adjustmentform.adjustment_amount = adjustment_amount;
				$('#adjustmentAmountmodal').modal('show');
			},
			capitalizeFirstLetter(string) {
				return string.charAt(0).toUpperCase() + string.slice(1);
			},
			viewEOB(doc_name){
				axios.post("/view-document",{doc_name:doc_name,folder_name:'patient_EOB'})
				.then((response) => {
					window.open(response.data, '_blank').focus();
				});
			},
			changeAppointment($event) {
				var options = $event.target.options;
				if (options.selectedIndex > -1) {
					this.appointment_number = options[options.selectedIndex].getAttribute('data-val');
				} else {
					this.appointment_number = 0;
				}
				$('#addbilling').modal('show');
				//this.appointment_number = $event.target.value;
			},
			getApptDropdownList() {
				axios
				.post('/json_dropdown/patient-appointments',{patient_id:this.$store.state.patientDetail.patient_id, 'appt_payment_type': 'Insurance'})
				.then((response) => {
					this.apptDropdownList = response.data.data;
				})
			},
			patientInsuranceOrderList() {
				this.$store.state.loader = true;
				axios.post("/payment/patient-insurance-list",{patient_id:this.$store.state.patientDetail.patient_id , 'order_summary_required' : true})
				.then((response) => {
				  this.list = response.data.data.patient_insurance_order_list;
				  this.claim_adjustment_codes = response.data.data.claim_adjustment_codes;
				  this.claim_adjustment_group_codes = response.data.data.claim_adjustment_group_codes;
				  this.$store.state.loader = false;
				});
			},			
			apptBillingModal(item) {
				this.appointment_id = item.appointment_id;
				this.appointment_number = item.appointment_number;
				$('#addbilling').modal('show');
			},
			newApptBilling() {
				$('#addbilling').modal('show');
				this.appointment_id = null;
				this.appointment_number = '';
			},			
			makeOrderPayment(appointment_id) {
				 $("#makepayment").modal('show');
				 this.appointment_id = appointment_id;
			},			
			CopayInsuranceClose() {
				this.appointment_id = null;
			},			
			printReceipt (id) {
				this.$store.state.loader = true;
				this.getSrc(id);
				$("#viewReceipt").modal("show");
			},
			async getSrc(id) {
				var masterId = id;
				const res = await fetch(process.env.VUE_APP_API_BASE_URL+'/claim/printPRFullReceipt', {
					method: 'POST',
					headers: {
						'Authorization' : 'Bearer '+localStorage.getItem('authToken'),
						'Content-Type' : 'application/json'
					},
					body: JSON.stringify({ claim_id: masterId }),
				});
				const blob = await res.blob();
				const urlObject = URL.createObjectURL(blob);
				this.receipt_url = urlObject;
				this.browserCheck();
				this.$store.state.loader = false;
			},
			browserCheck(){
				// Opera 8.0+
				var isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

				// Firefox 1.0+
				var isFirefox = typeof InstallTrigger !== 'undefined';

				// Safari 3.0+ "[object HTMLElementConstructor]" 
				var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));

				// Internet Explorer 6-11
				var isIE = /*@cc_on!@*/false || !!document.documentMode;

				// Edge 20+
				var isEdge = !isIE && !!window.StyleMedia;

				// Chrome 1 - 79
				var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

				// Edge (based on chromium) detection
				var isEdgeChromium = isChrome && (navigator.userAgent.indexOf("Edg") != -1);

				// Blink engine detection
				var isBlink = (isChrome || isOpera) && !!window.CSS;

				if (isChrome==true) {
					this.embed = 0;
				} else {
					this.embed = 1;
				}
			},
		},
		watch: {
			"$store.state.MainInsurnceBilling.hidemodal": function() {
				if (this.$store.state.MainInsurnceBilling.hidemodal == true) {
					$('#addbilling').modal('hide');
					$('#makepayment').modal('hide');
					this.patientInsuranceOrderList();
					this.getApptDropdownList();
				}
			},
		}
	}
</script>