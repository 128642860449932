import {
  helpers,
  required,
  email,
  minLength,
  maxLength,
  sameAs,
} from "@vuelidate/validators";
const Amendments = {
  namespaced: true,

  state: {
    editID: "",
    listUrl: "/patient/:patient_unique_id/amendments-document/list",
    getEditUrl: "patient/:id/amendments-document/edit/:id2",
    tableData: {
      header: [
        "Name",
        "Status",
        "Date",
        "Doctor",
        "Actions",
      ],
    },
    form: {},
    vuelidateExternalResults: {
      form: {
        document_name: [],
        source:[],
        status:[],
      },
    },
    validationRules: {
      form: {
        document_name: {
          required: helpers.withMessage("Document File is required", required),
        },
        source:{
          required: helpers.withMessage("Source is required", required),
        },
        status:{
          required: helpers.withMessage("Status is required", required), 
        }
      },
    },
    list: [],
  },
  getters: {
    getTableHeaders: (state) => state.header,

    getTableContents: (state) => state.list,
    getListURL: (state) => state.listUrl,
  },
  mutations: {},
  actions: {},
};

export default Amendments;
