import {
    helpers,
    required,    
  } from "@vuelidate/validators";
// const imageRule = helpers.regex('image', /\.(jpg|jpe?g|tiff|png|pdf)$/);

const imageRule = (value) => (value == null) ? true : /\.(jpg|jpe?g|tiff|png|pdf)$/i.test(value.name);

  const patientinsuranceinfo = {
    namespaced: true,  
    state: {        
        defaultFormData: {
            front_image:null,
            rear_image:null,  
            primary:{
                id:null,
                healthcare_program:'other',
                insurance_company_id: null,    
                insurance_company: null,    
                insurance_company_address: null,    
                insurance_company_zip_code: null,    
                insurance_company_city: null,    
                insurance_company_state_id: null,
                patient_is_subscriber:true,
                subscriber_relation:null,
                subscriber_first_name:null,
                subscriber_middle_name:null,
                subscriber_last_name:null,
                subscriber_dob:null,
                subscriber_ssn:null,
                subscriber_address:null,
                subscriber_zip_code:null,
                subscriber_state_id:null,
                insurance_id_number:null,                
                insurance_group_name:null,                
                insurance_group_number:null,                
                insurance_plan_name:null,                
                insurance_plan_type:null,                
                visit_per_year:null,  
                subscriber_policy_id:null,    
                subscriber_phone:null,          
                subscriber_gender:null,
            },
            secondary:{
                id:null,
                healthcare_program:'other',
                insurance_company_id: null,    
                insurance_company: null,    
                insurance_company_address: null,    
                insurance_company_zip_code: null,    
                insurance_company_city: null,    
                insurance_company_state_id: null,
                patient_is_subscriber:true,
                subscriber_relation:null,
                subscriber_first_name:null,
                subscriber_middle_name:null,
                subscriber_last_name:null,
                subscriber_dob:null,
                subscriber_ssn:null,
                subscriber_address:null,
                subscriber_zip_code:null,
                subscriber_state_id:null,
                insurance_id_number:null,
                insurance_group_name:null,
                insurance_group_number:null,
                insurance_plan_name:null,
                insurance_plan_type:null,
                visit_per_year:null,
                subscriber_policy_id:null,
                subscriber_phone:null,
                subscriber_gender:null,
            },
            tertiary:{
                id:null,
                healthcare_program:'other',
                insurance_company_id: null,    
                insurance_company: null,    
                insurance_company_address: null,    
                insurance_company_zip_code: null,    
                insurance_company_city: null,    
                insurance_company_state_id: null,
                patient_is_subscriber:true,
                subscriber_relation:null,
                subscriber_first_name:null,
                subscriber_middle_name:null,
                subscriber_last_name:null,
                subscriber_dob:null,
                subscriber_ssn:null,
                subscriber_address:null,
                subscriber_zip_code:null,
                subscriber_state_id:null,
                insurance_id_number:null,
                insurance_group_name:null,
                insurance_group_number:null,
                insurance_plan_name:null,
                insurance_plan_type:null,
                visit_per_year:null,
                subscriber_policy_id:null,
                subscriber_phone:null,
                subscriber_gender:null,
            },
            auto_accident:{
                id:null,
                insurance_company_id: null,    
                insurance_company: null,    
                insurance_company_address: null,    
                insurance_company_zip_code: null,    
                insurance_company_city: null,    
                insurance_company_state_id: null,
                patient_is_subscriber:true,
                subscriber_relation:null,
                subscriber_first_name:null,
                subscriber_middle_name:null,
                subscriber_last_name:null,
                subscriber_dob:null,
                subscriber_ssn:null,
                subscriber_address:null,
                subscriber_zip_code:null,
                subscriber_state_id:null,
                insurance_id_number:null,
                insurance_group_name:null,
                insurance_group_number:null,
                insurance_plan_name:null,
                insurance_plan_type:null,
                visit_per_year:null,
                subscriber_policy_id:null,
                subscriber_phone:null,
                subscriber_gender:null,
                patient_unable_to_work_start: null,
                patient_unable_to_work_end: null,
            },
            worker_comp:{
                id:null,
                insurance_company_id: null,
                insurance_company: null,
                insurance_company_address: null,
                insurance_company_zip_code: null,
                insurance_company_city: null,
                insurance_company_state_id: null,
                patient_is_subscriber:true,
                insurance_id_number:null,
                insurance_group_name:null,
                insurance_group_number:null,
                insurance_plan_name:null,
                insurance_plan_type:null,
            },
            durable_med_eqpt:{
                id:null,
                insurance_company_id: null,
                insurance_company: null,
                insurance_id_number:null,
                insurance_group_name:null,
                insurance_group_number:null,
                insurance_plan_name:null,
                insurance_plan_type:null,
            },
            patient_copay_detail:{
                patient_copay:null,
                patient_coinsurance:null,
            },            
            check_eligibility:{
                service_code:'30',
                type:'primary',
            },
            patient_id: null,
            patient_unique_id: null,
            insurance_type:null,
        },        
        form: { 
            front_image: [],
            rear_image: [],
        },
        validationRules: {
            form: {
                front_image: {
                    imageRule: helpers.withMessage("only jpg,jpeg,png and pdf are allowed.",imageRule),
                },
                rear_image: {
                    imageRule: helpers.withMessage("only jpg,jpeg,png and pdf are allowed.",imageRule),
                },
            }
        },
        vuelidateExternalResults: {
            form: {
                       
            },
        },
    },
    getters: {
        getTableHeaders: (state) => state.header,  
        getTableContents: (state) => state.list,
        getListURL: (state) => state.listUrl,
    },
    mutations: {},
    actions: {},
  };
  
  export default patientinsuranceinfo;
  