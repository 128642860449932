<template>
  <div class="tab-pane active" id="AddRoomsLink" role="tabpanel">
    <div class="patiboxdetail">
      <div class="authform-modal box">
        <div class="requiredinfo">
          <div class="pageheading">
            <h2 class="fw-900 mt-0">Labs </h2>
          </div>
          <form novalidate @submit.prevent="store">
            <div class="form-group row">
                <label class="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label text-sm-end">Select Lab <span class="text-danger">*</span></label>
                <div class="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-3">
                    <div class="controls">
                        <select name="select" v-model="v$.form.lab_id.$model"  class="form-select form-control" @change="Change_lab_id($event)">
                            <option  value="" disabled="disabled">Select Lab</option>
                            <option :value="item.id" v-for="item in LabOption" :key="item.id" :data-text="item.lab_name" >{{
                              item.lab_name
                            }}</option>
                        </select>
                    </div>
                </div>
            </div>
          <div class="row justify-content-end">
            <div class="col-12 col-sm-12 col-md-8 col-lg-9">
              <div class="mb-15">
                <small class="fs-12 l-h-n"><b>Note:</b> The lab is already integrated to send lab orders successfully</small>
              </div>
            </div>
          </div>
            <input-field
              title="Account Number"
              v-model="v$.form.accession_no.$model"
              :errors="v$.form.accession_no.$errors" 
              :isRequired="true"
              labelClass="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label text-sm-end"
              divClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-3"
            >
            </input-field>

            <div class="row">
              <div class="col-12 col-sm-12 col-md-12 col-lg-9 text-center">
                <button
                  type="button"
                  v-on:click="store('submit')"
                  class="waves-effect waves-light btn btn-info mb-5 me-5"
                >
                  Save
                </button>
                <button 
                  type="button"
                  @click="$router.push('/office/list')"
                  class="waves-effect waves-light btn btn-danger mb-5 me-5"
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import InputField from "@/components/base/formFields/InputField.vue";
import SelectBoxStatic from "@/components/base/formFields/SelectBoxStatic.vue";
import submitMixins from "@/scripts/mixins/submitMixins.js";
import axios from "@/scripts/axios.js";
import $ from "jquery";
import useVuelidate from "@vuelidate/core";

export default {
mixins: [submitMixins],
components: { InputField, SelectBoxStatic },
setup: () => {
  return { v$: useVuelidate() };
},
computed: {
    form() {
      return this.$store.state.OfficeLab.form;
    },
    vuelidateExternalResults() {
      return this.$store.state.OfficeLab.vuelidateExternalResults;
    },
    validationRules() {
      return this.$store.state.OfficeLab.validationRules;
    },

    
 },
 data() {
    return {
      LabOption: [],
    }
  },
 validations() {
    return this.$store.state.OfficeLab.validationRules;
  },
  mounted(){
     axios.get(`/json_dropdown/lab-master?is_configured=true`).then((response) => {
          this.LabOption = response.data.data;
         
      });
  },
  methods: {
    store(param) {
      this.postForm(`/office/${this.$route.params.id}/labs/store`,"OfficeLab");
     
    },
    formPostSuccess(data) {
        this.moshaToast("Record added successfully.", "success");
        this.$store.state.OfficeLab.form = {};
        Object.assign(this.$store.state.OfficeLab.form);
        this.v$.$reset();
        this.$store.state.OfficeLab.form.lab_id ="";
    },
    Change_lab_id($event) {
      if ($event.target.value != "") {
        this.lab_id = $event.target.value;
        axios
          .post(`/office/${this.$route.params.id}/${this.lab_id}/labs/get_accession_no`)
          .then((response) => {
            this.$store.state.OfficeLab.form.accession_no=response.data.accession_no;
            
          });
        return false;
      } else {
        this.empty(this.items);
        this.items.push({
          id: "resources0",
          label: "Resource 1",
          value: "",
          editFrm: 0,
        });
        this.count = 0;
      }
    },
  },
  beforeCreate(){
    this.$store.state.OfficeLab.form.lab_id ="";
  }
};
</script>
