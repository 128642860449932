<template>
  <div class="form-group row">
      <label
          :class="labelClass"
        >{{ title }}
        <small v-show="isRequired" class="text-danger asterisksign">*</small></label>
    <div :class="divClass">
        <Multiselect
          class="form-control"
          v-model="values"
          :class="{ error: errors.length}"
          mode="tags"
          valueProp="id"
          :searchable="true"
          label="name"
          trackBy="name"
          :options="data"
          :canClear="false"
          :placeholder="'Select '+ title"
        />
        <span class="form-text text-muted" v-if="notes != ''">{{notes}}</span>
        <div class="error-msg">
          <ul class="mb-0 p-0 fs-14">
            <li class="d-block" v-for="(error, index) of errors" :key="index">
              {{ error.$message }}
            </li>
          </ul>
        </div>
      


      <p v-if="error" class="form-error">
        Could not get {{ title }} options. Click
        <a @click="window.location.reload(true)"> HERE </a> to reload the page
        and try again
      </p>
    </div>
  </div>
</template>

<script>
import submitMixins from "@/scripts/mixins/submitMixins.js";
// https://bestofvue.com/repo/vueform-multiselect-vuejs-form-select
import Multiselect from "@vueform/multiselect";

export default {
  components: { Multiselect },
  mixins: [submitMixins],
  props: {
    errors: {
      Object,
      default:{}
    },
    modelValue: {},
    title: {
      type: String,
    },
    getDataURL: {
      type: String,
    },
    isRequired: {
      type: Boolean,
    },
    labelClass:{
      type: String,
      default: "col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end"
    },
    divClass:{
      type: String,
      default: "col-12 col-sm-6 col-md-8 col-lg-3 col-lg-5 col-xl-3"
    },
    notes: {
      type: String,
      default: ""
    },
  },
  watch: {
    values: function(newValue) {
      this.$emit("update:modelValue", this.values);
    },
    // server sends comma seprated string for arrays so need to convert it to JS array
    modelValue: function(newValue) {
      if (!Array.isArray(newValue)) {
        this.values = newValue.split(",");
        this.values = this.values.map((item) => {
          return item; // convert string to number
        });
        this.$emit("update:modelValue", this.values);
      }
    },
  },
  mounted() {},
  methods: {},
  data() {
    return {
      data: [],
      error: false,
      values: [],
    };
  },

  beforeCreate() {},
  async created() {
    this.data = await this.getData(this.getDataURL);

    if (!this.data) {
      this.error = true;
    }
  },
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>