import { helpers, required, email, minLength, maxLength, sameAs,url,} from "@vuelidate/validators";

var alphaValidation = /^[a-zA-Z]*$/;
var regexAlpha = new RegExp(alphaValidation);
const alpha = (value) => !helpers.req(value) || regexAlpha.test(value);

const personalinfo = {
  namespaced: true,
  state: {
    form: {},
    vuelidateExternalResults: {
      form: {
        first_name: [],
        last_name: [],
        email: [],
        cell_phone: [],        
        country_id: [],
        state_id: [],
      },
    },
    validationRules: {
      form: {
        first_name: {
          required: helpers.withMessage("First Name is required", required),
          alpha: helpers.withMessage("Only Alphabets allow", alpha),
        },

        last_name: {
          required: helpers.withMessage("Last Name is required", required),
          alpha: helpers.withMessage("Only Alphabets allow", alpha),
        },
        email: {
          required: helpers.withMessage("Email Address is required", required),
          email: helpers.withMessage("Email address is not valid", email),
        },
        cell_phone: {
          required: helpers.withMessage(
            "Cell Phone Number is required",
            required
          ),
          max: maxLength(15),
        },
        country_id: {
          required: helpers.withMessage("Country is required", required),
        },
        state_id: {
          required: helpers.withMessage("State is required", required),
        },
      },
    },
  },
  getters: {
    getTableHeaders: (state) => state.header,

    getTableContents: (state) => state.list,
    getListURL: (state) => state.listUrl,
  },
  mutations: {},
  actions: {},
};


export default personalinfo;
