<template>
	<div class="content-wrapper min-height-auto" >
		<div class="container-full">
			<section class="content">
				<div class="row justify-content-between">
					<!-- sidebar -->
					<div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-2 cal_scroll smooth_scroll" style="overflow-x: auto;">
						<div class="" >
							<div class="side_calend mb-10">
								<ejs-calendar
									@change="changeDate"
									:value="selectedDate"
								></ejs-calendar>
							</div>
							<div class="accordion" id="accordioncalendar">
								<div class="accordion-item b-0">
									<h2 class="accordion-header mt-0" id="panelsStayOpen-headingProvider">
								    	<button class="accordion-button collapsed bg-info-light py-10 px-10 fw-600 fs-16" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseProvider" aria-expanded="false" aria-controls="panelsStayOpen-collapseProvider">Provider</button>
									</h2>
									<div id="panelsStayOpen-collapseProvider" class="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingProvider">
										<div class="box-body p-0">
											<div class="media-list media-list-divided media-list-hover userprovid_scroll" style="max-height: 135px;overflow: auto;">
												<div class="media align-items-center py-2" v-for="(provider, index) in providerLists" :key="index">
													<div class="flexbox flex-grow gap-items m-0 black-color">
														<img class="avatar img-fluid w-30 h-30" :src="provider.image_url" :alt="provider.name"/>
														<div class="media-body">
															<h5 class="fw-500 mt-5" :title="provider.name+' '+ ((provider.suffix == null) ? '' : provider.suffix)">{{ ((provider.name+' '+ ((provider.suffix == null) ? '' : provider.suffix)).length > 23) ? (provider.name+' '+ ((provider.suffix == null) ? '' : provider.suffix)).substring(0,23) + "..." : provider.name+' '+ ((provider.suffix == null) ? '' : provider.suffix)  }}</h5>
														</div>
													</div>
													<div class="custom-control custom-checkbox">
														<ejs-checkbox :ref="`provider${provider.id}obj`" :id="`provider${provider.id}objId`" cssClass="e-info" style="color: red" :value="`${provider.id}`" :checked="provider.pref_checked" @change="filterProvider">
														</ejs-checkbox>
														<label for="possibleduplicate"></label>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="accordion-item b-0">
									<h2 class="accordion-header" id="panelsStayOpen-headingoffice">
								    	<button class="accordion-button collapsed bg-info-light py-10 px-10 fw-600 fs-16" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseoffice" aria-expanded="false" aria-controls="panelsStayOpen-collapseoffice">Offices</button>
									</h2>
									<div id="panelsStayOpen-collapseoffice" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingoffice">
										<div class="box-body p-0">
											<div class="media-list media-list-divided media-list-hover userprovid_scroll">
												<div class="media align-items-center py-7" v-for="(office, index) in officeLists" :key="index">
													<div class="flexbox flex-grow gap-items m-0 black-color">
														<div class="media-body">
															<h5 class="fw-500 mt-5" :title=office.name>{{ office.office_nm }}</h5>
														</div>
													</div>
													<div class="custom-control custom-checkbox m-0">
														<ejs-checkbox :ref="`office${office.id}obj`" :id="`office${office.id}objId`" cssClass="e-info" :value="`${office.id}`" :checked="office.pref_checked" @change="filterOffice">
														</ejs-checkbox>
														<label for="possibleduplicate"></label>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="accordion-item b-0" v-if="coordinatorLists.length > 0">
									<h2 class="accordion-header" id="panelsStayOpen-headingstaff">
										<button class="accordion-button collapsed bg-info-light py-10 px-10 fw-600 fs-16" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseStaff" aria-expanded="false" aria-controls="panelsStayOpen-collapseStaff">Staff</button>
								    </h2>
								    <div id="panelsStayOpen-collapseStaff" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingstaff">
								   		<div class="box-body p-0">
											<div class="media-list media-list-divided media-list-hover userprovid_scroll" style="max-height: 135px;overflow: auto;">
												<div class="media align-items-center py-2" v-for="(coordinator, index) in coordinatorLists" :key="index">
													<div class="flexbox flex-grow gap-items m-0 black-color" >
														<div class="media-body">
															<h5 class="fw-500 mt-5">{{ coordinator.name  }}</h5>
														</div>
													</div>
													<div class="custom-control custom-checkbox">
														<ejs-checkbox :ref="`coordinator${coordinator.id}obj`" :id="`coordinator${coordinator.id}objId`" cssClass="e-info" style="color: red" :value="`${coordinator.id}`" :checked="coordinator.pref_checked" @change="filterCoordinator">
														</ejs-checkbox>
														<label for="crmcheckboxlabel"></label>
													</div>
												</div>
											</div>
										</div>
								    </div>
								</div>
								<div class="accordion-item b-0" v-if="crmStatusLists.length > 0">
									<h2 class="accordion-header" id="panelsStayOpen-headingcrm">
								      <button class="accordion-button collapsed bg-info-light py-10 px-10 fw-600 fs-16" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapsecrm" aria-expanded="false" aria-controls="panelsStayOpen-collapsecrm">CRM Calendar</button>
								    </h2>
								    <div id="panelsStayOpen-collapsecrm" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingcrm">
										<div class="accordion-body p-0">
											<div class="box-body p-0">
												<div class="media-list media-list-divided media-list-hover userprovid_scroll" style="max-height: 135px;overflow: auto;">
													<div class="media align-items-center py-2" v-for="(crmstatus, index) in crmStatusLists" :key="index">
														<div class="flexbox flex-grow gap-items m-0 black-color" >
															<div class="media-body">
																<h5 class="fw-500 mt-5">{{ crmstatus.name  }}</h5>
															</div>
														</div>
														<div class="custom-control custom-checkbox">
															<ejs-checkbox :ref="`crmstatus${crmstatus.id}obj`" :id="`crmstatus${crmstatus.id}objId`" cssClass="e-info" style="color: red" :value="`${crmstatus.id}`" :checked="crmstatus.pref_checked" @change="filterCRMStatus">
															</ejs-checkbox>
															<label for="crmcheckboxlabel"></label>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="accordion-item b-0" v-if="apptypeLists.length > 0">
									<h2 class="accordion-header" id="panelsStayOpen-headingAppotype">
								      <button class="accordion-button collapsed bg-info-light py-10 px-10 fw-600 fs-16" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseAppotype" aria-expanded="false" aria-controls="panelsStayOpen-collapseAppotype">Appointment Type</button>
								    </h2>
								    <div id="panelsStayOpen-collapseAppotype" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingAppotype">
										<div class="accordion-body p-0">
											<div class="box-body p-0">
												<div class="media-list media-list-divided media-list-hover userprovid_scroll" style="max-height: 135px;overflow: auto;">
													<div class="media align-items-center py-2" v-for="(apptTyp, index) in apptypeLists" :key="index">
														<div class="flexbox flex-grow gap-items m-0 black-color" >
															<div class="media-body">
																<h5 class="fw-500 mt-5">{{ apptTyp.type }}</h5>
															</div>
														</div>
														<div class="custom-control custom-checkbox">
															<ejs-checkbox :ref="`apptTyp${index}obj`" :id="`apptTyp${index}objId`" cssClass="e-info" style="color: red" :value="`${apptTyp.type}`" :checked="apptTyp.pref_checked" @change="filterapptTyp">
															</ejs-checkbox>
															<label for="apptTypcheckboxlabel"></label>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="accordion-item b-0" v-if="appointment_profile.length > 0">
									<h2 class="accordion-header" id="panelsStayOpen-headingAppoProfile">
								      <button class="accordion-button collapsed bg-info-light py-10 px-10 fw-600 fs-16" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseAppoProfile" aria-expanded="false" aria-controls="panelsStayOpen-collapseAppoProfile">Appointment Profile</button>
								    </h2>
								    <div id="panelsStayOpen-collapseAppoProfile" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingAppoProfile">
										<div class="accordion-body p-0">
											<div class="box-body p-0">
												<div class="media-list media-list-divided media-list-hover userprovid_scroll" style="max-height: 135px;overflow: auto;">
													<div class="media align-items-center py-2" v-for="(appProf, index) in appointment_profile" :key="index">
														<div class="flexbox flex-grow gap-items m-0 black-color" >
															<div class="media-body">
																<h5 class="fw-500 mt-0">{{  appProf.name }}</h5>
															</div>
														</div>
														<div class="custom-control custom-checkbox">
															<ejs-checkbox :ref="`apptProf${index}obj`" :id="`apptProf${index}objId`" cssClass="e-info" style="color: red" :value="`${appProf.id}`" :checked="appProf.pref_checked" @change="filterappProf">
															</ejs-checkbox>
															<label for="apptTypcheckboxlabel"></label>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<!-- content -->
					<div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-9 col-xxl-10">
						<div class="tab-content">
							<div id="Scheduer1">
								<a href="#" data-bs-toggle="modal" data-bs-target="#calendardocument"  title="Reference of Knowledge Base" v-on:click.prevent="resetKey()" id="algosearchlink" class="waves-effect waves-light btn btn-outline btn-info-light mb-5 float-end btn-sm"> Help <i class="fa fa-question-circle pt-1"></i></a>
								<a data-bs-toggle="modal" data-bs-target="#printApptModal" @click="printApptData('pdf')" href="#" class="waves-effect waves-light btn btn-info float-end btn-sm me-1" title="print" style="padding: 5px 6px;"><i class="fa fa-print fs-16"></i></a>
								<a @click="printApptData('excel')" href="#" class="waves-effect waves-light btn btn-info float-end btn-sm me-1" title="xls" style="padding: 5px 6px;"><i class="fa fa-download"></i></a>
								<ejs-schedule
									id="schedule"
									ref="ScheduleObj"
									endHour="20:00"
									startHour="08:00"
									:dragStart="dragStart"
									:dragStop="dragStop"
									:timeScale="timeScale"
									:allowResizing="false"
									:allowDragAndDrop="true"
									:allowMultiCellSelection="false"
									:allowMultiRowSelection="false"
									height="80vh"
									width="100%"
									:selectedDate="selectedDate"
									:eventSettings="eventSettings"
									:editorTemplate="editorTemplate"
									:onActionBegin="onActionBegin"
									:onActionComplete="onActionComplete"
									:popupOpen="onPopupOpen"
									:popupClose="onPopupClose"
									:onHover="onHover"
									:quickInfoTemplates="quickInfoTemplates"
									:eventRendered="oneventRendered"
									:navigating="navigatingClick"
								>
									<e-views>
										<e-view option="Day" :isSelected="default_day_view" [allowVirtualScrolling]="true"></e-view>
										<e-view option="Week" :isSelected="default_week_view" [allowVirtualScrolling]="true"></e-view>

										<e-view option="Month" :isSelected="default_month_view" [allowVirtualScrolling]="true" showWeekend="false"></e-view>
										<e-view
											:isSelected="default_room_view"
											option="Day"
											:group="group1"
											displayName="Rooms"
											:resourceHeaderTemplate="roomResourceHeaderTemplate"
											[allowVirtualScrolling]="true"
										></e-view>

										<e-view
											:isSelected="default_provider_view"
											option="Day"
											:group="group"
											displayName="Provider"
											:resourceHeaderTemplate="providerResourceHeaderTemplate"
											[allowVirtualScrolling]="true"
										></e-view>
									</e-views>
									<e-resources>
										<e-resource
											field="provider_id"
											title="Provider"
											name="Providers"
											:eventSettings="eventSettings"
											:dataSource="providerDataSource"
											textField="name"
											idField="id"
											[allowVirtualScrolling]="true"
										>
										</e-resource>
										<e-resource
											field="room_id"
											title="Room"
											name="Rooms"
											:eventSettings="eventSettings"
											:dataSource="roomDataSource"
											textField="name"
											idField="id"
											[allowVirtualScrolling]="true"
										>
										</e-resource>
										
									</e-resources>
								</ejs-schedule>
							</div>
						</div>
					</div>
					<!--model start-->
					<div id="displayavailableslotsmodal" class="modal fade" tabindex="-1" role="dialog"  aria-labelledby="displayavailableslotsmodal" aria-hidden="true">
						<div class="modal-dialog modal-sm">
							<div class="modal-content">
								<div class="modal-header">
									<h3 class="modal-title fw-900 mt-0">CHOOSE A FREE SLOT</h3>
								</div>   
								<div class="modal-body">										
									<img src="images/loader.gif" v-if="choose_slot_loader" class="mt-50 img-fluid" style="width:50px;margin-left:125px">
									<div class="form-group mb-0" v-for="(item,index) in slot_suggestion_list.slice(0,5)" :key="index">
										<input v-model="slot_suggestion_time" type="radio" v-bind:id="'suggestion'+index" name="suggestion" class="filled-in chk-col-info" :value="item.schedule_time">
										<label v-bind:for="'suggestion'+index">{{item.value}}</label>
									</div>
									<div class="free_slot mb-10">
										<div class="form-group mb-0" v-for="(item,index) in slot_suggestion_list.slice(6)" :key="index">
											<input v-model="slot_suggestion_time" type="radio" v-bind:id="'qsuggestion'+index" name="suggestion" class="filled-in chk-col-info" :value="item.schedule_time">
											<label v-bind:for="'qsuggestion'+index">{{item.value}}</label>
										</div>
									</div>
									<button type="button" class="waves-effect waves-light btn btn-light float-end check_box">more</button>
								</div>
								<div class="modal-footer">
									<button type="button" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-info" @click="select_schedule_slot">Schedule</button>
									<button type="button" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger-light">Close</button>
								</div>
							</div>
						</div>
					</div>
        			<!--model end-->	
					<!--document model start-->
						<div id="calendardocument" class="modal fade" tabindex="-1" role="dialog" data-bs-backdrop="static" data-bs-keyboard="false"
							aria-labelledby="myModalLabel" aria-hidden="true" >
							<div class="modal-dialog modal-md">
								<div class="modal-content">
								<div class="modal-header">
								<h3 class="modal-title fw-900 mt-0">Reference of Knowledge Base</h3>
								</div>
								<div class="modal-body overflow-auto" style="max-height: 350px;">
								<table class="table table-bordered">
									<thead>
										<tr>
										<th scope="col">No.</th>
										<th scope="col">Knowledge Base Articles</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>1</td>
											<td> <a :href="path+'how-to-schedule-new-appointments'" target="_blank">How To Schedule New Appointments?</a></td>
										</tr>
										<tr>
											<td>2</td>
											<td> <a :href="path+'how-to-edit-appointments-from-calendar'" target="_blank">How To Edit Appointments?</a></td>
										</tr>
										<tr>
											<td>3</td>
											<td> <a :href="path+'how-to-delete-appointment-from-calendar'" target="_blank">How To Delete Appointment?</a></td>
										</tr>
									</tbody>
								</table>
								<algo-search :key="algoKey"></algo-search>
								</div>
								<div class="modal-footer">
								<button type="button" @click="searchreset()" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
							</div>
							</div>
						</div>
					</div>
					<!--document model end-->
					<!--printAppt Modal Start-->
					<div class="modal" id="printApptModal" tabindex="-1">
						<div class="modal-dialog modal-md" style="max-width:75%">
							<div class="modal-content">
								<div class="modal-header">
									<button type="button" class="btn-close btn-xs" data-bs-dismiss="modal" aria-label="Close"></button>
								</div>
								<div class="modal-body">
									<vue-pdf-viewer height="1050px" :url="receipt_url" v-if="embed=0">
									</vue-pdf-viewer>
									<iframe :src="receipt_url" width="100%" height="500px" v-if="embed=1" />
									<img srd="images/1.gif" v-else> 
								</div>
							</div>
						</div>
					</div>
					<!--printAppt Modal end-->				
				</div>
			</section>
		</div>
	</div>
</template>
<script>
import Search from "@/components/Search.vue";
import {
	RecurrenceEditorPlugin,
	ScheduleComponent,
	Day,
	Week,
	WorkWeek,
	Month,
	Agenda,
	DragAndDrop,
	Resize,
	ViewsDirective,
	ViewDirective,
	ResourcesDirective,
	ResourceDirective,
	SchedulePlugin
} from "@syncfusion/ej2-vue-schedule";
import $ from "jquery";
import { CalendarComponent } from "@syncfusion/ej2-vue-calendars";
/*import CheckBox from "@/components/base/formFields/checkBox.vue";*/
import { isNullOrUndefined, Internationalization, L10n } from "@syncfusion/ej2-base";
import { resourceSampleData } from "./dataSource";
import { RadioButton,CheckBox } from "@syncfusion/ej2-vue-buttons";
import {
	DropDownList,
	MultiSelect,
	AutoComplete,
} from "@syncfusion/ej2-dropdowns";
import { DateTimePicker } from "@syncfusion/ej2-calendars";
import moment, * as moments from "moment";
import {
	Predicate,
	Query,
	DataManager,
	ODataV4Adaptor,
	UrlAdaptor,
	CustomDataAdaptor,
} from "@syncfusion/ej2-data";
import { extend } from "@syncfusion/ej2-base";
import submitMixins from "@/scripts/mixins/submitMixins.js";
import axios from "@/scripts/axios.js";
import { createApp } from "vue";
import { mapGetters } from "vuex";
import { setTime, Timezone, RecurrenceEditor, RecurrenceEditorChangeEventArgs } from "@syncfusion/ej2-schedule";
import { ButtonComponent } from "@syncfusion/ej2-vue-buttons";
import { DropDownListComponent } from "@syncfusion/ej2-vue-dropdowns";
import { TextBoxComponent, NumericTextBox, ColorPicker } from "@syncfusion/ej2-vue-inputs";
import { Tooltip } from '@syncfusion/ej2-popups';
import { Button } from '@syncfusion/ej2-buttons';

const app = createApp();
app.config.compilerOptions.isCustomElement = (tag) => tag.startsWith("ejs-");
import EditorTemplate from "./CalenderComps/EditorTemplate.vue";
import QuickInfoHeader from "./CalenderComps/QuickInfoHeader.vue";
import QuickInfoContent from "./CalenderComps/QuickInfoContent.vue";
import QuickInfoFooter from "./CalenderComps/QuickInfoFooter.vue";
import VuePDFViewer from 'vue-instant-pdf-viewer';

L10n.load({
	'en-US': {
		'schedule': {
			'newEvent': 'New Appointment',
			'editEvent': 'Edit Appointment',
			'cancelButton': 'Close',
		},
	}
});

var providerResourceHeaderTemplateVue = app.component(
	"providerResourceHeaderTemplate",
	{
		template: `<div class="flexbox flex-grow gap-items m-0">
			<img
				class="avatar img-fluid"
				:src="data.resourceData.image_url"
				:alt="data.resourceData.name"
			/>
			<div class="media-body">

				<h5 class="fw-500 mt-5" :title="data.resourceData.name+' '+((data.resourceData.suffix)?data.resourceData.suffix : '')">{{ (data.resourceData.name+' '+((data.resourceData.suffix)?data.resourceData.suffix : "")).length > 23 ? (data.resourceData.name+' '+((data.resourceData.suffix)?data.resourceData.suffix : "")).substring(0,23) + "..." : (data.resourceData.name+' '+((data.resourceData.suffix)?data.resourceData.suffix : "")) }}</h5>
			</div>
		</div>`,
		data() {
			return {
				data: {},
			};
		},
	}
);
var roomResourceHeaderTemplateVue = app.component(
	"roomResourceHeaderTemplate",
	{
		// template: ` <div class="flexbox flex-grow gap-items m-0">
		// 				<div class="media-body">
		// 					<h5 class="fw-500 black-color">{{ (data.resourceData.abbrName == null) ? data.resourceData.name : data.resourceData.abbrName }}</h5>
		// 				</div>
		// 			</div>`,
		template: `<div class="office-room">
						<div class="o-name" :title=data.resourceData.office_name>{{ (data.resourceData.office_abbr == null) ? data.resourceData.office_nm : data.resourceData.office_abbr }}</div> 
						<span :title=data.resourceData.room_name>{{ data.resourceData.room_nm }}</span>
					</div>`,
		data() {
			return {
				data: {},
			};
		},
	}
);

var editorTemplateVue = app.component("editorTemplate", EditorTemplate);
var headerTemplateVue = app.component("header", QuickInfoHeader);
var contentTemplateVue = app.component("content", QuickInfoContent);
var footerTemplateVue = app.component("footer", QuickInfoFooter);

var providerCollections = new DataManager({
	adaptor: new CustomDataAdaptor({
		getData: function(option) {
			let xhttp = new XMLHttpRequest();
			xhttp.onreadystatechange = function() {
				if (this.readyState == 4) {
					let request = extend({}, option, { httpRequest: xhttp });
					if (
						(xhttp.status >= 200 && xhttp.status <= 299) ||
						xhttp.status === 304
					) {
						let data = JSON.parse(xhttp.responseText);
						console.log('data: ', data);
						option.onSuccess(data.data, request);
					} else {
						option.onFailure(request);
					}
				}
			};
			xhttp.open(
				"POST",
				`${process.env.VUE_APP_API_BASE_URL}/get-user-provider/preferred`,
				true
			);
			xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
			xhttp.setRequestHeader("Authorization", `Bearer ${token}`);
			xhttp.setRequestHeader("activeProvider", `${activeProvider}`);
			xhttp.send(option.data);
		},
	}),
});

var roomCollections = new DataManager({
	adaptor: new CustomDataAdaptor({
		getData: function(option) {
			let xhttp = new XMLHttpRequest();
			xhttp.onreadystatechange = function() {
				if (this.readyState == 4) {
					let request = extend({}, option, { httpRequest: xhttp });
					if (
						(xhttp.status >= 200 && xhttp.status <= 299) ||
						xhttp.status === 304
					) {
						let data = JSON.parse(xhttp.responseText);
						option.onSuccess(data.data, request);
					} else {
						option.onFailure(request);
					}
				}
			};
			xhttp.open(
				"POST",
				`${process.env.VUE_APP_API_BASE_URL}/json_dropdown/get-user-rooms/preferred`,
				true
			);
			xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
			xhttp.setRequestHeader("Authorization", `Bearer ${token}`);
			xhttp.setRequestHeader("activeProvider", `${activeProvider}`);
			xhttp.send(option.data);
		},
	}),
});

var profileCollections = new DataManager({
	adaptor: new CustomDataAdaptor({
		getData: function(option) {
			let xhttp = new XMLHttpRequest();
			xhttp.onreadystatechange = function() {
				if (this.readyState == 4) {
					let request = extend({}, option, { httpRequest: xhttp });
					if (
						(xhttp.status >= 200 && xhttp.status <= 299) ||
						xhttp.status === 304
					) {
						let data = JSON.parse(xhttp.responseText);
						option.onSuccess(data.data, request);
					} else {
						option.onFailure(request);
					}
				}
			};
			xhttp.open(
				"GET",
				`${process.env.VUE_APP_API_BASE_URL}/json_dropdown/appointment-profile-CPT-code`,
				true
			);
			xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
			xhttp.setRequestHeader("Authorization", `Bearer ${token}`);
			xhttp.setRequestHeader("activeProvider", `${activeProvider}`);
			xhttp.send(option.data);
		},
	}),
});

// Do not change these
var token = localStorage.getItem("authToken");
var activeProvider = localStorage.getItem("activeProvider");
var selectedPatientID = "";
var selectedProvider = '';
var isSelectedProviderSupervisor = 0;
var filteredProviders = [];
var filteredCRMStatus = [];
var filteredapptTyp = [];
var filteredapptProf = [];
var filteredCoordinator = [];
var filteredOffices = [];
var callStore = false;
var selectedType = 'Office';
var recurrencedate_freeslots = [];
var recurrence_rule = '';
var mendatoryDay = 'mendatoryDay';
var beforeDrag = {};
var allowOverLap = false;
var getAppointmentflag = 'onload';
var packageId = '';
var package_set = 0;
var signedConsentForm=[];
var signedConsentFormmsg="";


var pp_id=0;
var appData = {};
var startDate = "";
var endDate = "";
// END

var scheduleData = [];
let _self = this;
let loader_status_update = (status) => {
	if(status=="show") {
		document.getElementById('loader').style.display="block";
		document.getElementById('loader').style.opacity="0.5";
	} else {
		document.getElementById('loader').style.display="none";
		document.getElementById('loader').style.opacity="0.05";
	}
}
let createRequest = (type, option, _self = '') => {
	let xhttp = new XMLHttpRequest();

	xhttp.onreadystatechange = function() {
		if (callStore == true && type=='update' && this.readyState == 1) {
			loader_status_update("show");
		} else {
			loader_status_update("hide");
		}
		if (this.readyState == 4) {
			let request = extend({}, option, { httpRequest: xhttp });			
			if ( (xhttp.status >= 200 && xhttp.status <= 299) || xhttp.status === 304 ) {
				if(xhttp.responseText){
					let data = JSON.parse(xhttp.responseText);
					appData = data.data;
					if (type === "getData") {
						let data = JSON.parse(xhttp.responseText);
						if (Array.isArray(data.data) && data.data.length > 0) {
							data.data.forEach(function(item) {
								item.StartTime = moment(item.schedule_time,"YYYY-MM-DD HH:mm:ss").toDate();
								item.EndTime = moment(item.schedule_end_time,"YYYY-MM-DD HH:mm:ss").toDate();
								item.Id = item.id;

								if(item.type == "Tele Health"){
									item.Subject = '<i class="fa fa-video-camera" title="Tele Health"></i> '+ item.subject;
								}else{

									switch(item.status_label)
									{
										case 'Rescheduled':
											item.Subject = 	'<i class="fa fa-calendar-times fs-16 fw-100" title="Rescheduled"></i>';
											break;
										case 'Complete':
											item.Subject = '<i class="fa fa-check-circle-o fs-16 fw-100" title="Complete"></i> '+ item.subject;
											break;
										case 'Confirmed':
											item.Subject = '<i class="fa fa-copyright fs-16 fw-100" title="Confirmed"></i> '+ item.subject;
											break;
										case 'No Show':
											item.Subject = '<i class="fa fa-eye-slash fs-16 fw-100" title="No Show"></i> '+ item.subject;
											break;
										case 'Cancelled':
											item.Subject = '<i class="fa fa-times-circle-o fs-16 fw-100" title="Cancelled"></i> '+ item.subject;
											break;
										case 'Arrived':
											item.Subject = '<img src="/images/appointment_status_icons/Arrived.svg" title="Arrived"> '+ item.subject;
											break;
										default:
											item.Subject = item.subject;
											break;
									}
								}
								if(item.appointment_type !='Break'){
									if (item.consent_form_ids && item.consent_form_ids.includes(",")) {
										item.consent_form_ids = item.consent_form_ids.split(",").map(String);
									} else {
										item.consent_form_ids = [item.consent_form_ids];
									}
																		
								}
							});
							document.getElementById('loader').style.display="none";
							document.getElementById('loader').style.opacity="0.05";
							option.onSuccess(data.data, request);
						} else {
							loader_status_update("hide");
							option.onSuccess([], request);
						}
					} else {
						loader_status_update("hide");
						option.onSuccess(data, request);
					}
				}
				loader_status_update("hide");
			} else {
				loader_status_update("hide");
				console.log("fail function run");
				option.onFailure(request);
			}
		}
	};
	if (type === "getData") {		
		xhttp.open(
			"POST",
			`${process.env.VUE_APP_API_BASE_URL}/appointment/get-appointments`,
			true
		);
		xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
		xhttp.setRequestHeader("Authorization", `Bearer ${token}`);
		xhttp.setRequestHeader("activeProvider", `${activeProvider}`);

		let params = JSON.parse(option.data);
		
		let reqParams = {
			start_date: moment(new Date(params.StartDate)).format("YYYY-MM-DD"),
			end_date: moment(new Date(params.EndDate)).format("YYYY-MM-DD"),
			getAppointmentflag: getAppointmentflag,
		};			
		startDate = moment(new Date(params.StartDate)).format("MM/DD/YYYY");
		endDate = moment(new Date(params.EndDate)).subtract(1, "days").format("MM/DD/YYYY");
		if (filteredProviders.length > 0 || filteredOffices.length > 0 || filteredCRMStatus.length || filteredCoordinator.length || filteredapptTyp.length > 0 || filteredapptProf.length > 0) {
			reqParams.filter = true;
			reqParams.providers = filteredProviders;
			reqParams.offices = filteredOffices;
			reqParams.crm_status = filteredCRMStatus;
			reqParams.coordinators = filteredCoordinator;
			reqParams.filter_appointment_type = filteredapptTyp;
			reqParams.filter_appointment_profile = filteredapptProf;
		}
		xhttp.send(JSON.stringify(reqParams));
	} else {
		if(callStore == true){
			xhttp.open(
				"POST",
				`${process.env.VUE_APP_API_BASE_URL}/appointment/store`,
				true
			);
			xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
			xhttp.setRequestHeader("Authorization", `Bearer ${token}`);
			xhttp.setRequestHeader("activeProvider", `${activeProvider}`);

			let reqData = JSON.parse(option.data);
			var recurrencedates = [];
			if (document.getElementById('reminderProfileCheck').checked) {
				var reminderdata = [];
				$('.reminderProfileDataLoop').each(function() {
					reminderdata.push({
						reminder_type: $(this).find(".reminder_type").val(),
						reminder_value: $(this).find(".reminder_value").val(),
						reminder_unit_type: $(this).find(".reminder_unit_type").val(),
						reminder_unit_before_or_after: $(this).find(".reminder_unit_before_or_after").val(),
						reminder_content: $(this).find(".reminder_content").val(),
					});
				});
				reqData.reminder_profile_id = document.getElementById('reminderProfilevalue').value
			}
			if (reqData.added.length > 0) {
				
				reqData.added.forEach(function(item) {
					if (document.getElementById('reminderProfileCheck').checked) {
						item.reminderdata = reminderdata;
					}
					item.app_provider_id = (item.app_provider_id) ? item.app_provider_id : item.provider_id;			
					let start = moment(new Date(item.StartTime)).format("YYYY-MM-DD HH:mm:ss");
					let end = moment(new Date(item.EndTime)).format("YYYY-MM-DD HH:mm:ss");
					let rescheduleTime = moment(new Date(item.reschedule_time)).format("YYYY-MM-DD HH:mm:ss");
					let break_start = moment(new Date(item.break_StartTime)).format("YYYY-MM-DD HH:mm:ss");

					var appTypetype = $("input[type=radio][name='appointment_type']:checked").val();
					item.appointment_type = (appTypetype == undefined || appTypetype == '') ? item.type : appTypetype;
					item.type = item.appointment_type;		

					item.schedule_time = start;
					item.break_schedule_time = break_start;
					item.schedule_end_time = end;
					item.reschedule_time = rescheduleTime;
					item.patient_id = selectedPatientID;
					recurrencedates = recurrencedate_freeslots;
					item.recurrencedates = recurrencedates;
					item.package_id = (packageId!=0) ? packageId : null;
					item.package_set = package_set;
				});
			}

			if (reqData.changed.length > 0) {
				reqData.changed.forEach(function(item) {
					if (document.getElementById('reminderProfileCheck').checked) {
						item.reminderdata = reminderdata;
					}
					let start = moment(new Date(item.StartTime)).format(
						"YYYY-MM-DD HH:mm:ss"
					);
					let end = moment(new Date(item.EndTime)).format("YYYY-MM-DD HH:mm:ss");
					let rescheduleTime = moment(new Date(item.reschedule_time)).format("YYYY-MM-DD HH:mm:ss");
					let break_start = moment(new Date(item.break_StartTime)).format(
						"YYYY-MM-DD HH:mm:ss"
					);

					var appTypetype = $("input[type=radio][name='appointment_type']:checked").val();
					item.appointment_type = (appTypetype == undefined || appTypetype == '') ? item.type : appTypetype;
					item.type = item.appointment_type;		

					item.schedule_time = start;
					item.schedule_end_time = end;
					item.reschedule_time = rescheduleTime;
					item.app_provider_id = (item.app_provider_id) ? item.app_provider_id : item.provider_id;
					item.app_room_id = (item.app_room_id) ? item.app_room_id : item.room_id;
					item.package_id = (packageId!=0) ? packageId : null;
					item.package_set =package_set;
					
					//changed for onload data management
					if(item.type == 'Break')
					{
						item.break_StartTime = (item.break_StartTime) ?  item.break_StartTime : item.StartTime;
						item.break_color_code = (item.break_color_code) ? item.break_color_code : item.appointment_color_code;
						item.break_duration = (item.break_duration) ? item.break_duration : item.duration;
						item.break_note = (item.break_note) ? item.break_note : item.extra_notes;
						item.break_office_id = (item.break_office_id) ? item.break_office_id : item.office_id;
						item.break_provider_id = (item.break_provider_id) ? item.break_provider_id : item.provider_id;
						item.break_reason = (item.break_reason) ? item.break_reason : item.reason_for_visit;
						item.break_room_id = (item.break_room_id) ? item.break_room_id : [item.room_id];
						item.break_schedule_time = (break_start!=="Invalid date") ? break_start : item.break_StartTime;
					}
					item.recurrencedates = recurrencedates;
					//changed for onload data management -- End
				});
			}
			xhttp.send(JSON.stringify(reqData));
			packageId="";
		}
	}
};

var dataManager = new DataManager({
	adaptor: new CustomDataAdaptor({
		getData: function(option) {
			createRequest("getData", option);
		},
		// to handle Batch operation
		batchUpdate: function(option) {
			createRequest("update", option);
		},
	}),
});

export default {
	name: "App",
	// pp_id:this.pp_id,
	mixins: [submitMixins],
	// Declaring component and its directives
	components: {
		"ejs-recurrenceeditor" : RecurrenceEditorPlugin,
		"ejs-schedule": ScheduleComponent,
		"e-views": ViewsDirective,
		"e-view": ViewDirective,
		"e-resources": ResourcesDirective,
		"e-resource": ResourceDirective,
		"ejs-calendar": CalendarComponent,
		"ejs-button": ButtonComponent,
		"algo-search":Search,
		"vue-pdf-viewer": VuePDFViewer,
	},
	
	async beforeCreate() {
		filteredProviders = [];
		filteredOffices = [];
		let self = this;
		axios.post("/get-user-provider").then(function(res) {
			if (res.data.status == "success") {
				self.providerLists = res.data.data;
				self.providerLists.forEach((provider) => {  
					if(provider.pref_checked){
						filteredProviders.push(provider.id);
					}        
				});
			}
		});
		axios.get("/json_dropdown/get-user-office-list").then(function(res) {
			if (res.data.status == "success") {
				self.officeLists = res.data.data;
				self.officeLists.forEach((office) => {
					if(office.pref_checked){
						filteredOffices.push(office.id);
					}          
				});
			}
		});
		axios.post("/json_dropdown/get-user-rooms").then(function(res) {
			if (res.data.status == "success") {
				self.roomLists = res.data.data;
			}
		});
		axios.get(`/json_dropdown/appointment-crm-prospect-status`).then(function(res) {
			if (res.data.status == "success") {
				self.crmStatusLists = res.data.data;
				self.crmStatusLists.forEach((crmStatusLists) => {
					if(crmStatusLists.pref_checked){
						filteredCRMStatus.push(crmStatusLists.id);
					}
				});
			}
		});

		axios.get('/appointment-profile/list').then(function(res) {
			self.appointment_profile = res.data.data;
			self.appointment_profile.forEach((appointment_profile) => {
				if(appointment_profile.pref_checked){
					filteredapptProf.push(appointment_profile.id);
				}
			});
		});

		axios.get('/appointment/appointment-types').then(function(res) {
			self.apptypeLists = res.data.data;
			self.apptypeLists.forEach((apptypeLists) => {
				if(apptypeLists.pref_checked){
					filteredapptTyp.push(apptypeLists.type);
				}
			});
		});
		
		axios.post("/json_dropdown/office-staff").then(function(res) {
			if (res.data.status == "success") {
				self.coordinatorLists = res.data.data.loop_data;
				self.coordinatorLists.forEach((coordinatorLists) => {
					if(coordinatorLists.pref_checked){
						filteredCoordinator.push(coordinatorLists.id);
					}
				});
			}
		});
	},
	methods: { 
		searchreset() {
			window.location.reload();
		},
		resetKey() {
			this.algoKey = this.algoKey + 1;
		},		
		preferencesSave: function(preference_type_id, is_checked, preference_type){
			axios.post("/appointment/calendar-preference-save",{
					preference_type : preference_type,
					preference_type_id : preference_type_id,
					is_checked: is_checked
				})
				.then((response) => {

				});
		},
		nth(n){
			return["st","nd","rd"][((n+90)%100-10)%10-1]||"th"
		},
		getRecurringDates: function(startDate,RecurrenceRule)
		{
			let recObject = new RecurrenceEditor({ });;
			var rules = RecurrenceRule;
			let dates = recObject.getRecurrenceDates(new Date(startDate),rules);
			let recurrencedates = [];
			var time =  moment(startDate).format('hh:mm a')
			for (let index = 0; index < dates.length; index++) {
				var dayname = moment(String(new Date(dates[index]).toString())).format('ddd');
				var dater = moment(String(new Date(dates[index]).toString())).format('YYYY-MM-DD');
				if(dayname == "Sun"){
					var date = new Date(dater); 
					date. setDate(date. getDate() + 1); 
					recurrencedates.push(moment(date).format('YYYY-MM-DD')+" "+time);	            
	        	}
	        	else if(dayname == "Sat"){
					var date = new Date(dater); 
					date. setDate(date. getDate() + 2); 
					recurrencedates.push(moment(date).format('YYYY-MM-DD')+" "+time);	            
	        	}
	        	else{
					recurrencedates.push(moment(String(new Date(dates[index]).toString())).format('YYYY-MM-DD')+" "+time);
				}
			}
			return recurrencedates;
		},
		recurringcheckSchedule: function(provider_id,recurrencedate,duration,room_id, coordinator_staff_id, is_provider_occupied, office_id){
			if(allowOverLap == false){
				let _self = this;
				axios.post("/appointment/recurring-check-schedule",{
					app_provider_id :provider_id,
					app_coordinator_staff_id :coordinator_staff_id,
					is_provider_occupied :is_provider_occupied,
					recurrencedate:recurrencedate,
					duration:duration,
					room_id:room_id,
					office_id:office_id,
				})
				.then((response) => {
					let _self = this;
					if(response.data.data.error != null){
						recurrencedate_freeslots = response.data.data.success;
						var recurrenceerrordate = response.data.data.error;
						$("div#unavailable-slots").removeClass("d-none");
						var slotsHtml='';
						for(var index = 0; index < recurrenceerrordate.length; ++index) {

							slotsHtml += '<div class="row"><div class="col-6 col-sm-6 col-md-6 col-lg-6">'+moment(new Date(recurrenceerrordate[index].date)).format("MM-DD-YYYY HH:mm:ss")+'</div><div class="col-6 col-sm-6 col-md-6 col-lg-6"><div class="form-group"><select class="form-control" id="free_sloat'+index+'">';

							var subLoop = '', slots = recurrenceerrordate[index].slot;
							
							for(var j=0; j<slots.length; j++)
							{
								subLoop += '<option value="'+slots[j].schedule_time+'">'+slots[j].value+'</option>';
							}
							slotsHtml += subLoop;
							slotsHtml += '</select></div></div></div>';
						}

						$("div#recurringscheduling > div > div > .modal-body").html(slotsHtml);
					}
					else
					{
						$("div#unavailable-slots").addClass("d-none");
						recurrencedate_freeslots =  response.data.data.success;
					}
				});
			}
			else
			{
				recurrencedate_freeslots = recurrencedate;
			}
		},
		checkProviderSchedule: function(dt,provider_id,office_id,element,is_provider_occupied,coordinator_staff_id) {
			var apptype = $("input[type=radio][name='appointment_type']:checked").val();
			if(this.objectchanged == true && apptype != 'Tele Health' && apptype != 'Phone' && apptype != 'Break' && provider_id!=null && office_id !=null){
				axios.post('check-user-schedule',{	provider_id: provider_id,
					office_id: office_id,
					is_provider_occupied: is_provider_occupied,
					coordinator_staff_id: coordinator_staff_id,
					selectedTime: moment(new Date(dt)).format("YYYY-MM-DD HH:mm:ss")
				}).then((res) => {
					if(res.data.data.schedule_time == false){
						$("#"+element).text('The '+res.data.data.user_type+' is available to take only televisit. Please book the appointment as "Tele Health".');
						let elem = document.getElementById(element);
						elem.style.display = "block";
					}else{
						let elem = document.getElementById(element);
						elem.style.display = "none";
					}					
				});
			}else{
				let elem = document.getElementById(element);
				elem.style.display = "none";
			}
		},
		changeAppointmentType: function(type, is_recurringObject, startElement) {
			this.changed_appointment_type = type.value;
			recurrencedate_freeslots = [];

			if(!$("div#Recurcontainer").hasClass("d-none"))
			{
				$("div#Recurcontainer").addClass("d-none");
				is_recurringObject.properties.checked = false;
			}

			if(type.value == 'Break'){
				$("div.otherAppointment").addClass("d-none");
				$("div.breakType").removeClass("d-none");    

				/*recurring object disabled*/        
				
				is_recurringObject.properties.disabled = true;
				is_recurringObject.properties.indeterminate = true;
				is_recurringObject.properties.label = "<b>Recurring Appointments</b>";
				is_recurringObject.refresh();

				/*recurring object disabled -- End*/        
			}else{
				$("div.breakType").addClass("d-none");               
				$("div.otherAppointment").removeClass("d-none");

				/*recurring object enabled/disabled*/        
				if(new Date(startElement) >= new Date())
				{
					is_recurringObject.properties.disabled = false;
					is_recurringObject.properties.indeterminate = false;
					is_recurringObject.properties.label = "<b>Recurring Appointments</b>";
				}
				else
				{
					is_recurringObject.properties.disabled = true;
					is_recurringObject.properties.indeterminate = true;
					is_recurringObject.properties.label = "<b>Recurring Appointments</b> (Recurring Appointments cannot be scheduled before the current time.)"
				}
				is_recurringObject.refresh();
				/*recurring object enabled/disabled -- End*/        
			}
		},
		disableDate: function(args, startElement) {
			var date1 = moment(args.date).format("YYYY-MM-DD HH:mm:ss"), date2 = moment(startElement).format("YYYY-MM-DD HH:mm:ss");
			if (args.date < startElement) {
				args.isDisabled = true;
			}
		}, 
		onHover: function (args) {
			if(args.element.classList.contains('e-appointment') || args.element.classList.contains('e-quick-popup-wrapper')) {
				var AppTime = $(args.element).children(".e-appointment-details").children("div.e-time").text(),
				AppSubject = $(args.element).children(".e-appointment-details").children("div.e-subject").text();
				var html = AppTime+" "+AppSubject;
				args.element.setAttribute("title",html);
			}
		},
		navigatingClick: function(args) {	
			var activeView = $('.e-active-view').find('.e-tbar-btn-text').text();

			setTimeout(() => {
				var activeView = $('.e-active-view').find('.e-tbar-btn-text').text();
				const element = document.getElementById('schedule')
				if(activeView === 'Rooms' || activeView === 'Provider'){
					element.classList.add("schedule-cell-dimension");
				}else{
					element.classList.remove("schedule-cell-dimension");
				}
			}, 500);
			
			if(args.action == 'date' && this.outerCaledarClick == true){			
				this.outerCaledarClick = false;
				if(activeView == 'Month' || activeView == 'Week'){
					activeView = "Day";
				}
				$('.e-tbtn-txt[aria-label="'+activeView+'"]').trigger("click");
			}else{
				if(args.action == "view"){
					var schObj = document.querySelector(".e-schedule").ej2_instances[0];
					schObj.ScheduleView = args.previousView;
				} 
			}
		},
		oneventRendered: function(args) {
			var activeView = $('.e-active-view').find('.e-tbar-btn-text').text();
			const element = document.getElementById('schedule')
			if(activeView === 'Rooms' || activeView === 'Provider'){
				element.classList.add("schedule-cell-dimension");
			}else{
				element.classList.remove("schedule-cell-dimension");
			}
			if(args.data.status_label != "Not Confirmed" && args.data.status_label != "Confirmed" && args.data.status_label!="Rescheduled" && args.data.status_label!="Cancelled")
			{
				$(args.element).addClass("completed-appointment");
			}
			if(args.data.status_label == "Rescheduled"){
				if($(".e-month").hasClass("e-active-view")== true){
					$(args.element).addClass("e-rescheduled-appointment-month-view");
				}
				else{
					$(args.element).addClass("e-rescheduled-appointment");
				}
			}
			if(args.data.status_label == "Cancelled"){
				if($(".e-month").hasClass("e-active-view")== true){
					$(args.element).addClass("e-cancelled-appointment-month-view");
				}
				else{
					$(args.element).addClass("e-cancelled-appointment");
				}
			}
			
			let scheduleObj = this.$refs.ScheduleObj;
			let categoryColor = args.data.appointment_color_code;
			if (!args.element || !categoryColor) {
				return;
			}

			if (scheduleObj.ej2Instances.currentView === 'Agenda') {
				(args.element.firstChild).style.borderLeftColor = categoryColor;
			} else {
				args.element.style.backgroundColor = categoryColor;
			}
		},
		getDropdownListDS(url, method, params) {
			return new DataManager({
				adaptor: new CustomDataAdaptor({
					getData: function(option) {
						let xhttp = new XMLHttpRequest();
						xhttp.onreadystatechange = function() {
							if (this.readyState == 4) {
								let request = extend({}, option, {
									httpRequest: xhttp,
								});
								if (
									(xhttp.status >= 200 && xhttp.status <= 299) ||
									xhttp.status === 304
								) {
									let data = JSON.parse(xhttp.responseText);
									option.onSuccess(data.data, request);
								} else {
									option.onFailure(request);
								}
							}
						};
						xhttp.open(method, `${process.env.VUE_APP_API_BASE_URL}/${url}`, true);
						xhttp.setRequestHeader(
							"Content-Type",
							"application/json; charset=utf-8"
						);
						xhttp.setRequestHeader("Authorization", `Bearer ${token}`);
						xhttp.setRequestHeader("activeProvider", `${activeProvider}`);
						if (params) {
							xhttp.send(JSON.stringify(params));
						} else {
							let customOption = JSON.parse(option.data);
							
							if (selectedProvider) {
								customOption.selected_provider = selectedProvider;
							}
							if(url == 'patient/patient-search'){
								if(customOption.where[0].value.length > 2){
									xhttp.send(JSON.stringify(customOption));
								}
							}else{
								xhttp.send(JSON.stringify(customOption));
							}
						}
					},
				}),
			});
		},
		onActionComplete(args) {			
			let scheduleObj = this.$refs.ScheduleObj.ej2Instances;
			if (!isNullOrUndefined(document.querySelector(".e-schedule-dialog"))) {
				var diaObj = document.querySelector(".e-schedule-dialog")
					.ej2_instances[0];
				scheduleObj.uiStateValues.isBlock = false;
				diaObj.hide();
			}
			if(args.requestType == 'eventCreated'){
				scheduleObj.refreshEvents();									
			}
			if(args.requestType == "eventRemoved"){
				var appointment_id = args.data[0].id;
				var appointment_type = args.data[0].appointment_type;
				var self = this;
				this.$swal.fire({
					input: 'textarea',
					inputLabel: 'Enter reason for delete.',
					inputPlaceholder: 'Enter reason for delete...',
					inputAttributes: {
						'aria-label': 'Enter reason for delete'
					},
					allowOutsideClick: false,
					showCancelButton: false,
					confirmButtonText: 'Submit', 
					inputValidator: function(inputValue) {
						return new Promise(function(resolve, reject) {
							if (inputValue && inputValue.length > 0) {
								resolve();
							} else {
								self.$swal.showValidationMessage('Can not be blank') 
								self.$swal.enableInput()
								self.$swal.enableButtons()
							}
						});
					}                        
				})
				.then((result) => {
					if (result.isConfirmed == true) {
						axios.post("/appointment/reason-for-delete",{id:appointment_id, reason_for_delete:result.value,appointment_type:appointment_type })
						.then((res) => (
								this.moshaToast("Reason added successfully.", "success")   
						));
					}
				});
			}
			this.slot_suggestion_for = null;
			this.slot_suggestion_time = null;
			this.slot_suggestion_end_time = null;
			this.slot_suggestion_list = [];
			this.choose_slot_loader = false;
			$("#DisplayAvailableSlotsReschedule").addClass("d-none")
			$("#DisplayAvailableSlots").addClass("d-none")
		},
		dragStart(args){
			if(args.data.status_label == 'Rescheduled')
			{
				args.cancel = true;
			}
			else
			{
				beforeDrag = args.data;
			}
		},
		dragStop(args)
		{
			var schObj = document.querySelector(".e-schedule").ej2_instances[0];
			if(args.data.status_label != 'Rescheduled'  && args.data.schedule_time != moment(args.data.StartTime).format("YYYY-MM-DD HH:mm:ss"))
			{				
				args.cancel = true;
				var datetime = 	args.data.StartTime;	
				
				this.$swal.fire({
					title: 'Confirm!',
					text: 'Do you want to reschedule this appointment?',
					allowOutsideClick: false,
					showCancelButton: true,
					confirmButtonText: 'Yes', 
				}).then((result) => {
					if (result.isConfirmed == true) {					
						args.data = beforeDrag;
						args.data.status = "9";
						args.data.reschedule_time = datetime;
						schObj.saveEvent(args.data)
						beforeDrag = {};
					}
				});
				this.isDragged = true;
			}else{
				setTimeout(() => {
					let scheduleObj = this.$refs.ScheduleObj;
					scheduleObj.refreshEvents();					
				}, 500);
			}
		},
		onActionBegin(args) {
			var appNumber = null;
			if((args.requestType == "eventCreate" || args.requestType == "eventChange"))
			{
				appNumber = (args.data[0]) ? args.data[0].appointment_number : args.data.appointment_number;
			}
			
			if ((args.requestType == "eventCreate") || (args.requestType == "eventChange" && appNumber!=null && appNumber!=undefined)) {
				if(args.data[0]){
					if(this.isDragged == true)
					{
						args.data[0].app_provider_id = args.data[0].provider_id;
					}
					args.data[0].StartTime  =  moment(args.data[0].StartTime).format("YYYY-MM-DD HH:mm:ss");
					args.data[0].reschedule_time  =  moment(args.data[0].reschedule_time).format("YYYY-MM-DD HH:mm:ss");
					var UserData = args.data[0];            
				}else{
					if(this.isDragged == true)
					{
						args.data.app_provider_id = args.data.provider_id;
					}
					args.data.StartTime  =  moment(args.data.StartTime).format("YYYY-MM-DD HH:mm:ss");
					args.data.reschedule_time  =  moment(args.data.reschedule_time).format("YYYY-MM-DD HH:mm:ss");
					var UserData = args.data;               
				}

				if(this.slot_suggestion_time != null){
					if(this.slot_suggestion_for == "Reschedule" ){
						UserData.reschedule_time = this.slot_suggestion_time; 
					}else{
						UserData.StartTime = this.slot_suggestion_time; 
						UserData.EndTime = this.slot_suggestion_end_time; 
					}
				}
				UserData.app_coordinator_staff_id = UserData.coordinator_staff_id;
				// Server validation post
				var schObj = document.querySelector(".e-schedule").ej2_instances[0];
				var scheduleObj = this.$refs.ScheduleObj.ej2Instances;
				var self = this;
				var formData = { provider_id: ((UserData.app_provider_id) ? UserData.app_provider_id : UserData.provider_id), office_id: UserData.office_id, selectedTime: UserData.StartTime, coordinator_staff_id : ((UserData.app_coordinator_staff_id) ? UserData.app_coordinator_staff_id : UserData.coordinator_staff_id), is_provider_occupied : UserData.is_provider_occupied };
				schObj.uiStateValues.isBlock = true; // prevent closing window
				var errorVariable1 = false;
				var errorVariable2 = false;
				var apptype = $("input[type=radio][name='appointment_type']:checked").val();

				var apptype = (apptype == undefined) ? UserData.type : apptype;


				if(formData.office_id != undefined && formData.provider_id != undefined && formData.selectedTime != undefined && apptype != 'Tele Health' && apptype != 'Phone' && apptype != 'Break'){					
					$.ajax({
						url: `${process.env.VUE_APP_API_BASE_URL}/check-user-schedule`,
						data: JSON.stringify(formData),
						type: "POST",
						async: false,
						traditional: true,
						contentType: "application/json",
						headers: { Authorization: `Bearer ${token}`, activeProvider: `${activeProvider}`},
						success: function(data) {
							if(data.data.schedule_time == false){
								let elem = document.getElementById("startTimeError");
								elem.style.display = "block";
								errorVariable1 = true;
								if(self.isDragged == true)
								{
									self.$swal.fire({
										title: 'Sorry!',
										text: 'The provider is not available in this time slot.',
					        			type: 'warning'
									});		
									self.isDragged = false;	
								}
							}else{
								let elem = document.getElementById("startTimeError");
								errorVariable1 = false;
								elem.style.display = "none";
							}
						}
					});
				}
				if(UserData.office_id != undefined && allowOverLap == false){
					UserData.appointment_type = apptype;
					UserData.break_StartTime = moment(new Date(UserData.break_StartTime)).format("YYYY-MM-DD HH:mm:ss");	
					$.ajax({
						url: `${process.env.VUE_APP_API_BASE_URL}/appointment/check-schedule`,
						data: JSON.stringify(UserData),
						type: "POST",
						async: false,
						traditional: true,
						contentType: "application/json",
						headers: { Authorization: `Bearer ${token}`, activeProvider: `${activeProvider}` },
						success: function(data) {
							errorVariable2 = false;
							let bk_elem = document.getElementById("break_providerError");
							bk_elem.style.display = "none";
							let elem = document.getElementById("providerError");
							elem.style.display = "none";
						},
						failure: function(data) {
							errorVariable2 = true;
						},
						error: function(data) {
							if(self.isDragged == true)
							{
								self.$swal.fire({
									title: 'Sorry!',
									text: 'The provider is not available in this time slot.',
				        			type: 'warning'
								});
								errorVariable2 = true;	
								self.isDragged = false;		
							}
							else{
								if($("#appointment_type_break").is(":checked")){
									let bk_elem = document.getElementById("break_providerError");
									bk_elem.innerText = "The provider is occupied in this time slot.";
									bk_elem.style.display = "block";
									errorVariable2 = true;
								}else{
									let elem = document.getElementById("providerError");
									elem.innerText = "The provider is occupied in this time slot.";
									elem.style.display = "block";
									errorVariable2 = true;
									$('#DisplayAvailableSlots').unbind('click');
									$('#providerError').removeClass('d-none');
									$('#DisplayAvailableSlots').removeClass('d-none');
									document.getElementById("DisplayAvailableSlots").addEventListener("click", function() {
										self.slot_suggestion_list = [];
										self.choose_slot_loader = true;
										axios.post("/appointment/suggestion-schedule",{
											app_provider_id: UserData.app_provider_id,
											StartTime: UserData.StartTime,
											duration: UserData.duration,
											office_id: UserData.office_id
										})
										.then((response) => {
											self.choose_slot_loader = false;
											self.slot_suggestion_list = response.data.data;
										});
										$(".free_slot").hide();
										$('.check_box').unbind('click');
										$(".check_box").click(function() {
											if($(".free_slot").hasClass("d-block")){
												$(this).text("more");
												$(".free_slot").removeClass("d-block");
											}else{
												$(this).text("less");
												$(".free_slot").addClass("d-block");
											}
										});
									});
								}
							}
						},
					});
				}
				if(errorVariable1 == true || errorVariable2 == true){
					/*For popup block*/
					args.cancel = true;
					this.flag = false;
				}else{
					callStore = true;
					schObj.uiStateValues.isBlock = false;
				}
			}
		},
		filterOffice: function(args){
			let scheduleObj = this.$refs.ScheduleObj;

			/*preference save process*/
			var office = parseInt($(args.event.target).prev().attr('value'), 10), officeChecked = $(args.event.target).prev().prop("checked");
			this.preferencesSave(office, officeChecked, 'office');
			/*preference save process -- End*/

			filteredOffices = [];
				this.officeLists.forEach((office) => {
					let checkboxobj = this.$refs[`office${office.id}obj`];
					if (checkboxobj.$el.checked) {
						filteredOffices.push(office.id);
					}
			});
			var displayedList = []
			scheduleObj.getResourceCollections().forEach((resource) => {
				if(resource.title == 'Room'){
					resource.dataSource.forEach((data) => {
						displayedList.push(data.office_id)
					})
				}
			});
			displayedList = displayedList.filter(function(item, pos){
				return displayedList.indexOf(item)== pos; 
			});
			let officeData = this.roomLists;
			getAppointmentflag = "changed";
			let value = parseInt($(args.event.target).prev().attr('value'), 10);

			let resourceDataRoom = officeData.filter(function (office) { 
					return office.office_id === value; 
			});

			if (args.checked) {                
				if(!displayedList.includes(value)){
					scheduleObj.addResource(resourceDataRoom, 'Rooms', value - 1);
				}
				if(filteredOffices.length == 1 && filteredOffices[0] == value){
					var id_array = [];
					officeData.forEach((data) => {
						if(data.office_id != value){
							id_array.push(data.id);
						}
					});
					scheduleObj.removeResource(id_array, 'Rooms');
				}
			} else {
					var roomArr = [];
					resourceDataRoom.forEach((room) => {
							roomArr.push(room.id);
					});
					scheduleObj.removeResource(roomArr, 'Rooms');
				if(filteredOffices.length==0){
					scheduleObj.addResource(officeData, 'Rooms', value - 1);					
				}
			}
		},
		filterProvider: function(args) {
			var provider = parseInt($(args.event.target).prev().attr('value'), 10), proChecked = $(args.event.target).prev().prop("checked");
			this.preferencesSave(provider, proChecked, 'provider');

			let scheduleObj = this.$refs.ScheduleObj;
			filteredProviders = [];
			this.providerLists.forEach((provider) => {
				let checkboxobj = this.$refs[`provider${provider.id}obj`];
				if (checkboxobj.$el.checked) {
					filteredProviders.push(provider.id);
				}
			});      
			var displayedList = []
			scheduleObj.getResourceCollections().forEach((resource) => {
				if(resource.title == 'Provider'){
					resource.dataSource.forEach((data) => {
						displayedList.push(data.id)
					})
				}
			});
			getAppointmentflag = "changed";
			let providerData = this.providerLists;
				let value = parseInt($(args.event.target).prev().attr('value'), 10);
				let resourceData = providerData.filter(function (provider) { return provider.id === value; });
				if (args.checked) {
				if(!displayedList.includes(value)){
						scheduleObj.addResource(resourceData, 'Providers', value - 1);
				}
				if(filteredProviders.length == 1 && filteredProviders[0] == value){
					var id_array = [];
					providerData.forEach((data) => {
						if(data.id != value){
							id_array.push(data.id)
						}
					});
					scheduleObj.removeResource(id_array, 'Providers');
				}
				} else {
						scheduleObj.removeResource(value, 'Providers');
				if(filteredProviders.length<=0 && displayedList.length<=1){					
					scheduleObj.addResource(providerData, 'Providers', value - 1);
				}
			}			
		},
		filterCRMStatus : function(args) {

			var crmStatus = parseInt($(args.event.target).prev().attr('value'), 10), crmStatusChecked = $(args.event.target).prev().prop("checked");
			this.preferencesSave(crmStatus, crmStatusChecked, 'crmstatus');

			filteredCRMStatus = [];
			getAppointmentflag = "changed";
			let scheduleObj = this.$refs.ScheduleObj;
			this.crmStatusLists.forEach((provider) => {
				let checkboxobj = this.$refs[`crmstatus${provider.id}obj`];
				if (checkboxobj.$el.checked) {
					filteredCRMStatus.push(provider.id);
				}
			});
			scheduleObj.refreshEvents();
		},
		filterapptTyp : function(args) {

			var apptTyp = $(args.event.target).prev().attr('value'), apptTypChecked = $(args.event.target).prev().prop("checked");
			this.preferencesSave(apptTyp, apptTypChecked, 'appointmentType');

			filteredapptTyp = [];
			getAppointmentflag = "changed";
			let scheduleObj = this.$refs.ScheduleObj;
			var i = 0;
			this.apptypeLists.forEach((appt) => {
				let checkboxobj = this.$refs[`apptTyp${i}obj`];
				if (checkboxobj.$el.checked) {
					filteredapptTyp.push(appt.type);
				}
				i = i+1;
			});
			scheduleObj.refreshEvents();
		},
		filterappProf : function(args) {

			var apptProf = parseInt($(args.event.target).prev().attr('value'), 10), apptProfChecked = $(args.event.target).prev().prop("checked");
			this.preferencesSave(apptProf, apptProfChecked, 'appointmentProfile');

			filteredapptProf = [];
			getAppointmentflag = "changed";
			let scheduleObj = this.$refs.ScheduleObj;
			var i = 0;
			this.appointment_profile.forEach((appt) => {
				let checkboxobj = this.$refs[`apptProf${i}obj`];
				if (checkboxobj.$el.checked) {
					filteredapptProf.push(appt.id);
				}
				i = i+1;
			});
			scheduleObj.refreshEvents();
		},
		filterCoordinator : function(args) {
			var coordinator = parseInt($(args.event.target).prev().attr('value'), 10), coordinatorChecked = $(args.event.target).prev().prop("checked");
			this.preferencesSave(coordinator, coordinatorChecked, 'coordinatorStaff');

			filteredCoordinator = [];
			getAppointmentflag = "changed";
			let scheduleObj = this.$refs.ScheduleObj;
			this.coordinatorLists.forEach((coord) => {
				let checkboxobj = this.$refs[`coordinator${coord.id}obj`];
				if (checkboxobj.$el.checked) {
					filteredCoordinator.push(coord.id);
				}
			});
			scheduleObj.refreshEvents();
		},
		async moreEventsClick(args){},
		async onPopupClose(args) {

			/*patient infor part removed*/
			if($(".patient_info").length>0)
			{
				$(".patient_info").remove();
			}
			/*patient infor part removed -- End*/

			let scheduleObj = this.$refs.ScheduleObj.ej2Instances;
			var self = this;			
			let isPrevent = false;
			if ((args.data != null && args.type == "Editor" && scheduleObj.eventWindow.isCrudAction && $("input[type=radio][name=appointment_type]:checked").val() == "Break") && (args.data.break_StartTime != null && args.data.break_StartTime!='')){
				if (!args.data.break_duration) {
					isPrevent = true;
					let elem = document.getElementById("break_durationError");
					elem.style.display = "block";
				}
				args.cancel = isPrevent;
				this.flag = false;
			}else if (args.data != null && args.type == "Editor" && scheduleObj.eventWindow.isCrudAction) {	
				if(args.data.status && (args.data.status == '9' || args.data.status_label == 'Rescheduled') && !args.data.reschedule_time){
					isPrevent = true;
					let elem = document.getElementById("rescheduledError");
					elem.style.display = "block";
				}				
				if(isSelectedProviderSupervisor==0 &&  (args.data.supervisor_id == '' || args.data.supervisor_id == null)){
					isPrevent = true;
					let elem = document.getElementById("supervisorError");
					elem.style.display = "block";
				}
				if (!selectedPatientID){
					isPrevent = true;
					let elem = document.getElementById("patientError");
					elem.style.display = "block";
				}
				if (!args.data.reason_for_visit){
					isPrevent = true;
					let elem = document.getElementById("reasonError");
					elem.style.display = "block";
				}
				if (!args.data.status){
					isPrevent = true;
					let elem = document.getElementById("statusError");
					elem.style.display = "block";
				}
				if (!args.data.office_id){
					isPrevent = true;
					let elem = document.getElementById("officeError");
					elem.style.display = "block";
				}
				if(document.getElementById("startTimeError").style.display == 'block'){
					isPrevent = true;					
				}

				var ruleSplit = recurrence_rule.split(";");

				if($("#is_recurring").prop("checked") == true)
				{
					$.each(ruleSplit,function(i){
					  var a = ruleSplit[i].split('=');
					  if(a[0] == 'BYDAY' && recurrence_rule.indexOf('WEEKLY')>0)
					  {
					  	if(a[1].indexOf(mendatoryDay)==-1)
					  	{
					  		isPrevent = true;
					  		let elem = document.getElementById("sameDayError");
							elem.style.display = "block";
					  	}
					  }
					});					
					if(!document.getElementById("unavailable-slots").classList.contains("d-none"))
					{
						isPrevent = true;
					}
				}
				else
				{
					recurrencedate_freeslots = [];
				}

				args.cancel = isPrevent;
				this.flag = false;
			}
			if(isPrevent == false){
				this.objectchanged = null;
				callStore = true;
			}
		},
		async onPopupOpen(args) {

			callStore = false;
			
			setTimeout(function(){
				if(args.data.billing_status_txt == 'Fully Billed - Payment Locked'){				
					$(".e-event-save, .e-event-cancel, .e-event-delete").addClass("d-none");
				}
			},100);

			if(args.type == "QuickInfo"){				
				var popupInstance = document.querySelector(".e-quick-popup-wrapper").ej2_instances[0];
				if(args.element.classList.contains('e-quick-popup-wrapper')){
					this.showPop = 1;
					var eventDataID = args.data.id;
					var apptId = eventDataID;
					$('#next_appt_id_'+apptId).html('&nbsp<i class="fa fa-spinner fa-spin"></i>');
					$('#last_appt_id_'+apptId).html('&nbsp<i class="fa fa-spinner fa-spin"></i>');
					setTimeout(function() { 
						popupInstance.refreshPosition();
						if($(".e-appointment-wrapper").find("[data-id='Appointment_"+eventDataID+"']").offset() != undefined){
							if ($(".e-appointment-wrapper").find("[data-id='Appointment_"+eventDataID+"']").offset().left < $(".e-quick-popup-wrapper").offset().left){
								var left = $(".e-quick-popup-wrapper").css('left')
								var val = parseInt(left.substring(0, left.length - 2))-parseInt(10)
								$(".e-quick-popup-wrapper").css('left',val);
							} else {
								var left = $(".e-quick-popup-wrapper").css('left')
								var val = parseInt(left.substring(0, left.length - 2))+parseInt(10)
								$(".e-quick-popup-wrapper").css('left',val);
							}
							axios.post(`/appointment/get-next-previous`,{'appointment_id': apptId })
								.then((res) => {
									$('#next_appt_id_'+apptId).html(res.data.data.next_appt ? res.data.data.next_appt.dis_schedule_date : 'NA');
									$('#last_appt_id_'+apptId).html(res.data.data.past_appt ? res.data.data.past_appt.dis_schedule_date : 'NA');
							});
						}
					}, 100);
				}else{   
					let scheduleObj = this.$refs.ScheduleObj;
					scheduleObj.closeQuickInfoPopup();        
				}
			}
			/*Reschedule Appointment - Main popup blocked*/
			if(args.data.status_label=='Rescheduled' && args.type=='Editor'){
				args.cancel = true;
				return false;
			}

			$(".e-dlg-header").css("width","100%");
			$(".e-dlg-header").children().first().addClass("d-inline-block");			

			$(".e-dlg-header").find("#viewNote").remove();

			/*Reschedule Appointment - Main popup blocked - End*/
			$(".e-event-save, .e-event-cancel, .e-event-delete").removeClass("d-none");
			let scheduleObj = this.$refs.ScheduleObj.ej2Instances;			

			/*Edit appointment time Other tab unblocked*/
			console.log(args.data.appointment_number,args.data.appointment_type);
			if(args.data.appointment_number && args.data.appointment_type!='Break'){
				$(".e-dlg-header").append('<a href="javascript:void(0)" target="_blank" class="d-none waves-effect waves-light btn btn-info-light btn-sm float-end" id="viewNote" title="View/Create Clinical Notes"><i class="fa fa-file-text fs-14"></i> Patient Chart</a>');
				setTimeout(() => {
					var link = "/#/patient-chart/"+args.data.unique_id+"/clinical-notes/#"+args.data.appointment_number;
					$("#viewNote").attr("href",link);
					$('[id*="tab"]').removeClass("linkDisabled");
					$('[id*="tab"]').each(function(index) {
						$(this).attr("href",$(this).attr("data-href"));
						$(this).attr("data-bs-toggle","tab");
					});
					$("#viewNote").removeClass("d-none");					
				}, 500);
				document.getElementById('startTimeError').style.display = "none";

				if($(".patient_info").length==0)
				{
					$(".e-dlg-header").append('<div class="d-inline-block ms-20 patient_info"></div>');
				}
				var phone_icon = (args.data.cell_phone== null || args.data.cell_phone=='') ? "glyphicon glyphicon-phone-alt" : "glyphicon glyphicon-earphone", P_phone = (args.data.cell_phone== null || args.data.cell_phone=='') ? args.data.home_phone : args.data.cell_phone;
				P_phone = (P_phone == null || P_phone == '') ? 'Not Available' : P_phone;

				var P_email = (args.data.email == null || args.data.email == '') ? 'Not Available' : args.data.email;

				/*patient detail*/
				$(".patient_info").html('<a href="mailto:'+P_email+'" target="_blank" title="'+P_email+'" class="text-link patient_email me-2"><i class="fa fa-envelope fs-14 me-1"></i> '+P_email+'</a> | <a href="tel:'+P_phone+'" target="_blank" title="Phone" class="ms-2"><i class="'+phone_icon+' fs-14 me-1"></i> '+P_phone+'</a>');
				/*patient detail -- End*/
			}else if(args.data.appointment_number && args.data.appointment_type=='Break'){
				$('#tabauditlogs').removeClass("linkDisabled");
				$('#tabauditlogs').each(function(index) {
					$(this).attr("href",$(this).attr("data-href"));
					$(this).attr("data-bs-toggle","tab");
				});
			}
			if(args.data.unique_id)
			{
				this.pp_id=args.data.pp_id;
				// console.log(args.data.legacy_source);
				if(this.pp_id>0){
					$(".portal_data").addClass('d-none');
					if(args.data.legacy_source=="PWM New Form"){
						$(".portal_question").removeClass('d-none');
						$(".portal_question").attr('data-appointment_id',args.data.id);
						$(".portal_question").attr('data-patient_id',args.data.patient_id);
						$(".portal_question").attr('data-clinic_id',args.data.clinic_id);
						
					}
					
				}
				$(".edit_patient").css("cursor","pointer");
				$(".edit_patient").attr("data-id", args.data.unique_id);
				$(".edit_patient").attr("title", "Edit chart of "+args.data.patient_name);

				$(".portal_active").attr("data-id", args.data.patient_id);
				$(".portal_active").attr("data-email", args.data.email);
				$(".portal_active").attr("data-dob", args.data.date_of_birth);
				$(".portal_active").attr("data-ppid", args.data.pp_id);
				$(".portal_active").attr("data-cellphone", args.data.cell_phone);
				$(".portal_active").attr("data-uniqueid", args.data.unique_id);
				// $(".portal_active").attr("data-uniqueid", args.data.unique_id);
				$(".portal_active").attr("data-pp_status", args.data.pp_status);
				
			}else{
				$(".edit_patient").css("cursor","not-allowed");
			}
			/*Edit Appointment time Other tab unblocked - End*/


			if (args.type === "Editor" && !isNullOrUndefined(args.data)) {

				let self = this;
				setTimeout(function(){
					const dropdownObj = args.element.querySelector("#ej2_dropdownlist_20_hidden");
					$("#ej2_dropdownlist_20_hidden").on("click", self.onDropdownOpen);
				}, 300);

				args.duration = 15;
				selectedPatientID = "";

				let appointmentTypeElementWalkIn = args.element.querySelector("#appointment_type_walkin");
				let appointmentTypeElementOffice = args.element.querySelector("#appointment_type_office");
				let appointmentTypeElementTeleVisit = args.element.querySelector("#appointment_type_teleVisit");
				let appointmentTypeElementBreak = args.element.querySelector("#appointment_type_break");
				let activeReminder = args.element.querySelector("#active_reminder");
				let appointmentTypeElementPhone = args.element.querySelector("#appointment_type_phone");
				let durationElement = args.element.querySelector("#duration");
				let startElement = args.element.querySelector("#StartTime");
				let rescheduleTimeElement = args.element.querySelector("#reschedule_time");
				let endElement = args.element.querySelector("#EndTime");
				let roomElement = args.element.querySelector("#Room");
				let patientElement = args.element.querySelector("#Patient");
				let patientPackageElement = args.element.querySelector("#Package");
				let providerElement = args.element.querySelector("#Provider");  
				let allowOverlappingElement = args.element.querySelector("#allow_overlapping");
				let isProviderOccupiedElement = args.element.querySelector("#is_provider_occupied");
				let crmProspectPatientStatus = args.element.querySelector("#crm_prospect_patient_status");      

				let supervisorElement = args.element.querySelector("#Supervisor");
				let wellnessCoordinatorElement = args.element.querySelector("#WellnessCoordinator");
				let apptProfileElement = args.element.querySelector("#ApptProfile");
				let apptColorElement = args.element.querySelector("#ApptColor");
				let officeElement = args.element.querySelector("#Office");
				let reasonElement = args.element.querySelector("#reason_for_visit");
				let notesElement = args.element.querySelector("#Notes");
				let consentFormElement = args.element.querySelector(
					"#consent_form_ids"
				);
				let subjectElement = args.element.querySelector("#Subject");
				let statusElement = args.element.querySelector("#Status");
				let break_StartTimeElement = args.element.querySelector("#break_StartTime");
				let break_ProviderElement = args.element.querySelector("#break_provider_id");
				let break_OfficeElement = args.element.querySelector("#break_office_id");
				let break_apptColorElement = args.element.querySelector("#break_color_code");
				let break_RoomElement = args.element.querySelector("#break_room_id");
				let break_DurationElement = args.element.querySelector("#break_duration");
				let break_reasonElement = args.element.querySelector("#break_reason");
				let break_notesElement = args.element.querySelector("#break_note");

				let is_recurring = args.element.querySelector("#is_recurring");

				let dropDownListObjectProvider;
				let dropDownListObjectbreakProvider;
				let dropDownListObjectOffice;
				let dropDownListObjectRoom;
				let dropDownListObjectPatient;
				let dropDownListObjectPackage;
				let dropDownListObjectSupervisor;
				let dropDownListObjectCRMStaus;
				let dropDownListObjectWellnessCoord;
				let dropDownListObjectbreakOffice;
				let durationObject;
				let startElemntObject;
				let dropDownListObjectBreakRoom;
				let is_recurringObject;
				let allowOverlappingObject;
				let isProviderOccupiedObject;

				let endPicker = "";

				let activeProviderEle = ''
				if(localStorage.getItem('activeProvider') == 'all'){
					if(this.user.user_type == 'provider'){
						activeProviderEle = this.user.user_id;
					}else{
						activeProviderEle = this.user.primary_provider_id;
					}
				}else{
					activeProviderEle = localStorage.getItem('activeProvider');
				}
				
				
				/*when appointment is break need to block other appointment change option*/
				if(args.data.appointment_type == 'Break' && args.data.appointment_number)
				{
					appointmentTypeElementWalkIn.disabled = true;
					appointmentTypeElementOffice.disabled = true;
					appointmentTypeElementTeleVisit.disabled = true;
					appointmentTypeElementPhone.disabled = true;
				}
				else if(args.data.appointment_type != 'Break' && args.data.appointment_number)
				{
					appointmentTypeElementBreak.disabled = true;
				}
				/*when appointment is not a break need to block break appointment change option*/

				if(!allowOverlappingElement.classList.contains('e-checkbox-wrapper')){
					var is_checked = (args.data.allow_overlap && args.data.allow_overlap == 1) ? true : false;
					allowOverlappingObject = new CheckBox({
						disabled: false,
						checked: is_checked,
						change: function(argsAO) {
							allowOverLap = argsAO.checked
						},
					});
					allowOverlappingObject.appendTo(allowOverlappingElement);
					allowOverlappingElement.setAttribute("name", "allow_overlap");
				}

				if(!isProviderOccupiedElement.classList.contains('e-checkbox-wrapper')){
					var is_checked = (args.data && args.data.is_provider_occupied == 0) ? false : true;
					
					isProviderOccupiedObject = new CheckBox({
						disabled: false,
						checked: is_checked,
						change: function(argsPO) {
							self.checkSchedule(args.data.id,dropDownListObjectProvider.value,startElemntObject.value,durationObject.value,dropDownListObjectOffice.value,argsPO.checked,dropDownListObjectWellnessCoord.value,dropDownListObjectbreakProvider.value,break_StartTimeElement.value,break_DurationElement.value);
							self.checkProviderSchedule(startElemntObject.value,dropDownListObjectProvider.value,dropDownListObjectOffice.value,"startTimeError",argsPO.checked,dropDownListObjectWellnessCoord.value);
						},
					});
					isProviderOccupiedObject.appendTo(isProviderOccupiedElement);
					isProviderOccupiedElement.setAttribute("name", "is_provider_occupied");
				}

				
				if(!is_recurring.classList.contains('e-checkbox-wrapper'))
				{
					var is_recurring_disabled = (args.data.appointment_number) ? true : false;
					var is_indeterminate = (args.data.appointment_number) ? true : false;
					var recurrinLabel = (args.data.appointment_number) ? "<b>Recurring Appointments</b> (Existing Appointment cannot be converted to a recurring series)" : "Recurring Appointments";
					if(new Date(startElement.value) < new Date())
					{
						is_recurring_disabled = true;
						is_indeterminate = true;
						recurrinLabel = "<b>Recurring Appointments</b> (Recurring Appointments cannot be scheduled before the current time.)";
					}
					var is_checked = (args.data.is_recurring && args.data.is_recurring == 1) ? true : false;
					

					is_recurringObject = new CheckBox({
						label: recurrinLabel,
						startDate: new Date(startElement.value),
						indeterminate: is_indeterminate,
						change: function(argsRe){

							/*Selected Date Validation*/
							var d = new Date(startElement.value);
							var onday = d.getDay();

							var days = ['SU','MO','TU','WE','TH','FR','SA'];
							mendatoryDay = days[onday];

							var d1 = new Date();
							var curOnday = d1.getDay();

							if(onday != curOnday)
							{
								$("#RecurrenceEditor > div > div.e-days").children('button').removeClass("e-active");
								$("#RecurrenceEditor > div > div.e-days").children('button').removeClass("e-primary");
								$("#RecurrenceEditor > div > div.e-days").children('button[data-index="'+onday+'"]').trigger("click");
								/*$("#RecurrenceEditor > div > div.e-days").children('button[data-index="'+curOnday+'"]').trigger("click");*/
							}
							/*Selected Date Validation*/

							/*Label changed*/
							$(".e-month-expand-wrapper").children().children().first().children().first().children().children().children("label").text("Date");
							$(".e-month-expand-wrapper").children().children().last().children().first().children().children().children("label").text("Day");
							/*Label changed - End*/

							/*Recurring section Hide/Show*/

							if(argsRe.checked == true)
							{
								$("div#Recurcontainer").removeClass("d-none");
							}
							else
							{
								$("div#Recurcontainer").addClass("d-none");
								$("#unavailable-slots").addClass("d-none");
							}
							/*Recurring section Hide/Show -- End*/
						},
						disabled: is_recurring_disabled,
						checked: is_checked
					});
					is_recurringObject.appendTo(is_recurring);
				}


				let recurElement = args.element.querySelector('#RecurrenceEditor');
		        if (!recurElement.classList.contains('e-recurrenceeditor')) {
		            let recurrObject = new RecurrenceEditor({
		            	frequencies: ['weekly','monthly'],
		            	change: function(argsRecur)
                		{
							if(is_recurringObject.checked == true)
							{
								var recuVal = argsRecur.value;
								recurrence_rule = recuVal;
								var untilStr;
								if(recuVal.indexOf('UNTIL')>0)
								{
									untilStr = recuVal;
								}
								else
								{
									var date = new Date(startElement.value); 
									date.setDate(date.getDate() + 364); 
									var until = moment(date).utcOffset(0).format('YYYYMMDD');
									untilStr = recuVal+"UNTIL="+until;
								}

                				var recurrencedate = self.getRecurringDates(startElement.value,untilStr);
	                			self.recurringcheckSchedule(dropDownListObjectProvider.value,recurrencedate,durationObject.value,dropDownListObjectRoom.value,dropDownListObjectWellnessCoord.value,isProviderOccupiedObject.properties.checked,dropDownListObjectOffice.value);
                			}else{
								argsRecur.value = '';
							}
                		}
		            });
		            recurrObject.appendTo(recurElement);
		            /*recurrObject.setRecurrenceRule(args.data.RecurrenceRule);
		            scheduleObj.eventWindow.recurrenceEditor = recurrObject;*/
		        }

				if(args.data.appointment_type == 'Break'){
					$("div.otherAppointment").addClass("d-none");
					$("div.breakType").removeClass("d-none");
				}
				if (!break_StartTimeElement.classList.contains("e-datetimepicker")) {
					new DateTimePicker({
						open:function(arg1) {
							if (arg1.popup.element.className.indexOf("e-datetimepicker") > -1) {
								var list = arg1.popup.element.querySelectorAll(".e-list-item");
								for (var item = 0; item < list.length; item++) {
									if($(list[item]).attr("data-value") == '8:00 AM'){
										var start_time_array = $(list[item]).attr("id").split("-");
										var start_time  = start_time_array[1];
									}
									if($(list[item]).attr("data-value") == '8:00 PM'){
										var end_time_array = $(list[item]).attr("id").split("-");
										var end_time  = end_time_array[1] - 1;
									}
									if(start_time != undefined || end_time != undefined){        				
										if (item < start_time || item > end_time) {
						        			list[item].className = list[item].className + " e-hidden";
						      			}
									}
									else{
										list[item].className = list[item].className + " e-hidden";
									}
						  			
								}
							}
						},
						change: function(argsP) {
							self.checkSchedule(args.data.id,dropDownListObjectProvider.value,startElemntObject.value,durationObject.value,dropDownListObjectOffice.value,isProviderOccupiedObject.properties.checked,dropDownListObjectWellnessCoord.value,dropDownListObjectbreakProvider.value,argsP.value,break_DurationElement.value);
						},
						value: new Date(startElement.value) || new Date(),
						format: 'MM/dd/yyyy hh:mm a',
						step: 15
					},
					break_StartTimeElement
					);
				}

				if(!break_reasonElement.classList.contains("e-dropdownlist")){
					break_reasonElement.value = args.data.reason_for_visit || "";
				}

				if(break_OfficeElement.classList.contains("e-field")) {
					dropDownListObjectbreakOffice = new DropDownList({
						fields: { text: "name", value: "id" },
						value: (args.data.office_id) ? args.data.office_id : this.officeLists[0]['id'],
						dataSource: this.getDropdownListDS(
							`json_dropdown/get-user-office-list`,
							"GET"
						),
						select: function(args1) {
							let elem = document.getElementById("break_officeError");
							elem.style.display = "none";

							/*room autopopulate*/
							dropDownListObjectBreakRoom.showSpinner();
							break_RoomElement.value = [];
							dropDownListObjectBreakRoom.dataBind();

							axios
								.post(`/json_dropdown/${args1.itemData.id}/room`,{'default_option':'All Rooms'})
								.then((res) => {
									// assign new datasource to room dropdown
									dropDownListObjectBreakRoom.dataSource = res.data.data;
									dropDownListObjectBreakRoom.value = [];
									dropDownListObjectBreakRoom.hideSpinner();
							});
							/*room autopopulate - End*/
						},
						allowFiltering: true
					});

					dropDownListObjectbreakOffice.appendTo(break_OfficeElement);
					break_OfficeElement.setAttribute("name", "break_office_id");
				}

				if(!break_RoomElement.classList.contains("e-multiselect")){
					var officeId = (args.data.office_id) ? args.data.office_id : dropDownListObjectbreakOffice.value;
					// var _SelectedRoom = (args.data.room_id == null) ? 'All' : args.data.room_id;
					var _SelectedRoom = args.data.room_id;
						_SelectedRoom = (_SelectedRoom) ? _SelectedRoom.toString() : _SelectedRoom;

					dropDownListObjectBreakRoom = new MultiSelect({
						fields: { text: "name", value: "id" },
						placeholder: "All Rooms",
						value: [_SelectedRoom], 
						mode: "Box",              
						dataSource: this.getDropdownListDS(
								`json_dropdown/${officeId}/room`,
								"POST",
								{'default_option':'All Rooms'}
						),						
						removing: function(argsR){
							if(argsR.itemData){
								$.each($(dropDownListObjectBreakRoom.popupObj.element).children().children().children('.e-list-item'), function(index,ele){
									if(index!=0)
									{
										$(ele).removeClass("e-disabled");
									}
								});
							}
							else{
								$(dropDownListObjectBreakRoom.popupObj.element).children().children().children('.e-list-item:first').removeClass("e-disabled");
							}
						},
						select: function(argsSR){	
							if(argsSR.itemData){
								dropDownListObjectBreakRoom.selectAll(false);
								$.each($(dropDownListObjectBreakRoom.popupObj.element).children().children().children('.e-list-item'), function(index,ele){
									if(index!=0)
									{
										$(ele).addClass("e-disabled");
									}
								});
							}
							else{
								$(dropDownListObjectBreakRoom.popupObj.element).children().children().children('.e-list-item:first').addClass("e-disabled");
							}
						}
					});
					dropDownListObjectBreakRoom.appendTo(break_RoomElement);
					break_RoomElement.setAttribute("name", "break_room_id");
				}

				if(!break_ProviderElement.classList.contains("e-dropdownlist")){
					/*provider list */
					var accessProviders = [];
					self.providerLists.forEach((provider) => {
							accessProviders.push(provider.id);
					});

					axios.post(`/json_dropdown/office-provider`,{'office_id':  ((args.data.office_id) ? args.data.office_id : dropDownListObjectbreakOffice.value), 'Providers': accessProviders})
						.then((res) => {
							dropDownListObjectbreakProvider.dataSource = res.data.data.loop_data;
							dropDownListObjectbreakProvider.value = (args.data.provider_id) ? args.data.provider_id : res.data.data.office_primary_provider;
					});
					/*provider list - End*/					
					dropDownListObjectbreakProvider = new DropDownList({
						fields: { text: "name", value: "id" },
						placeholder: "Select Provider",
						value: (typeof args.data.provider_id === undefined || !args.data.provider_id) ? Number(activeProviderEle) : args.data.provider_id,
						allowFiltering: true,
						change: function(argsP) {
							if(argsP.itemData != null && argsP.itemData.is_appointment_overlapping == '1'){
								allowOverLap = true							
							}else{
								allowOverLap = false;								
							}
							self.checkSchedule(args.data.id,dropDownListObjectProvider.value,startElemntObject.value,durationObject.value,dropDownListObjectOffice.value,isProviderOccupiedObject.properties.checked,dropDownListObjectWellnessCoord.value,argsP.value,break_StartTimeElement.value,break_DurationElement.value);
						}
					});
					dropDownListObjectbreakProvider.appendTo(break_ProviderElement);
					break_ProviderElement.setAttribute("name", "break_provider_id");
				}

				if(!break_DurationElement.classList.contains("e-numerictextbox")){
					let durationBreakObject = new NumericTextBox({
						max: 3000,
						min: 0,
						strictMode: false,
						step: 5,
						format: "##",
						value: (args.data.duration) ? args.data.duration : '15',
						change: function(argsP) {
							if(self.objectchanged != null){
								self.objectchanged = true;
							}
							self.checkSchedule(args.data.id,dropDownListObjectProvider.value,startElemntObject.value,durationObject.value,dropDownListObjectOffice.value,isProviderOccupiedObject.properties.checked,dropDownListObjectWellnessCoord.value,dropDownListObjectbreakProvider.value,break_StartTimeElement.value,argsP.value);
						},
					});
					durationBreakObject.appendTo(break_DurationElement);
					break_DurationElement.setAttribute("name", "break_duration");
				}

				if(!break_apptColorElement.classList.contains("e-numerictextbox")){
					let apptBreakColorObject = new ColorPicker({            
						value: (args.data.appointment_color_code) ? args.data.appointment_color_code : '#a7b3b5',
						mode: 'Palette',
						showButtons:false,
					});
					$(".e-split-btn-wrapper > .e-split-colorpicker > .e-btn-icon > .e-split-preview").css('background-color',args.data.appointment_color_code);

					apptBreakColorObject.appendTo(break_apptColorElement);
					break_apptColorElement.setAttribute("name", "break_color_code");
				}

				if (break_notesElement) {
					break_notesElement.value = args.data.extra_notes || "";
				}			 

				var default_steps = (localStorage.getItem("calendar_increament") != null) ? parseInt(localStorage.getItem("calendar_increament")) : 15;
				if (!endElement.classList.contains("e-datetimepicker")) {
					endPicker = new DateTimePicker({
						value: new Date(endElement.value) || new Date(),
						change: function(arg1) {
							var a = moment(endElement.value), b = moment(startElement.value);
							durationObject.value = Math.abs(a.diff(b, 'minutes'));
							if(self.objectchanged != null){
								self.objectchanged = true;
							}
							self.checkSchedule(args.data.id,dropDownListObjectProvider.value,arg1.value,durationObject.value,dropDownListObjectOffice.value,isProviderOccupiedObject.properties.checked,dropDownListObjectWellnessCoord.value,dropDownListObjectbreakProvider.value,break_StartTimeElement.value,break_DurationElement.value);
							self.checkProviderSchedule(startElement.value,dropDownListObjectProvider.value,dropDownListObjectOffice.value,"startTimeError",isProviderOccupiedObject.properties.checked,dropDownListObjectWellnessCoord.value);
						},
						open:function(arg1) {
							if (arg1.popup.element.className.indexOf("e-datetimepicker") > -1) {
								var list = arg1.popup.element.querySelectorAll(".e-list-item");
								for (var item = 0; item < list.length; item++) {
									if($(list[item]).attr("data-value") == '8:00 AM'){
										var start_time_array = $(list[item]).attr("id").split("-");
										var start_time  = start_time_array[1];
									}
									if($(list[item]).attr("data-value") == '8:00 PM'){
										var end_time_array = $(list[item]).attr("id").split("-");
										var end_time  = end_time_array[1] - 1;
									}
									if(start_time != undefined || end_time != undefined){        				
										if (item < start_time || item > end_time) {
						        			list[item].className = list[item].className + " e-hidden";
						      			}
									}
									else{
										list[item].className = list[item].className + " e-hidden";
									}
						  			
								}
							}
						},
						format: 'MM/dd/yyyy hh:mm a',
						step: default_steps,
						min: startElement.value
					},
					endElement
					);
				}

				if (!startElement.classList.contains("e-datetimepicker")) {
					startElemntObject = new DateTimePicker({
						value: new Date(startElement.value) || new Date(),
						change: function(arg1) {
							
							var officeForSchedule = (dropDownListObjectOffice.value) ? dropDownListObjectOffice.value : default_office;

							/*office schedule check*/
							axios.post(`office/daywise-schedule`,{'office_id': officeForSchedule, 'schedule_date': startElement.value })
								.then((res) => {
									if(res.data.message != "schedule available"){
										$("#officeScheduleError").html(res.data.message);
									}else{
										$("#officeScheduleError").html("");
									}
								});
							
							var providerSchedule = (typeof dropDownListObjectProvider.value === undefined || !dropDownListObjectProvider.value) ? Number(activeProviderEle) : dropDownListObjectProvider.value;

							if(officeForSchedule!=null && officeForSchedule!=''){
								axios.post(`provider/daywise-and-officewise-schedule`,{'provider_id' : providerSchedule,'office_id': officeForSchedule, 'schedule_date': startElement.value })
									.then((res) => {			
										if(res.data.message!='schedule available'){
											$("#providerScheduleError").html(res.data.message);
										}else{
											$("#providerScheduleError").html("");
										}			
									});								
							}

							if(!args.data.appointment_number){
								/*Selected Date Validation*/
								var d = new Date(startElement.value);
								var onday = d.getDay();

								var days = ['SU','MO','TU','WE','TH','FR','SA'];
								mendatoryDay = days[onday];

								var d1 = new Date();
								var curOnday = d1.getDay();

								if(onday != curOnday)
								{
									$("#RecurrenceEditor > div > div.e-days").children('button').removeClass("e-active");
									$("#RecurrenceEditor > div > div.e-days").children('button').removeClass("e-primary");
									$("#RecurrenceEditor > div > div.e-days").children('button[data-index="'+onday+'"]').trigger("click");
									/*$("#RecurrenceEditor > div > div.e-days").children('button[data-index="'+curOnday+'"]').trigger("click");*/
								}
								/*Selected Date Validation*/
								if(new Date(startElement.value) >= new Date())
								{
									is_recurringObject.disabled = false;
									is_recurringObject.indeterminate = false;
									is_recurringObject.label = "<b>Recurring Appointments</b>";
									// document.getElementById('reminderProfileCheckLabel').innerHTML = 'View Active Reminders';
									// document.getElementById("reminderProfileCheckLabel").disabled= false;
									// document.getElementById("reminderProfileCheckLabel").indeterminate = false;
								}
								else
								{
									is_recurringObject.disabled = true;
									is_recurringObject.indeterminate = true;
									is_recurringObject.label = "<b>Recurring Appointments</b> (Recurring Appointments cannot be scheduled before the current time.)"
									// document.getElementById('reminderProfileCheckLabel').innerHTML = 'View Active Reminders (Reminder Profile cannot be scheduled before the current time)';
									// document.getElementById("reminderProfileCheckLabel").disabled= true;
									// document.getElementById("reminderProfileCheckLabel").indeterminate = true;
								}
								is_recurringObject.refresh();
							}

							if(self.objectchanged != null){
								self.objectchanged = true;
							}
							endElement.value = moment(startElement.value).add(durationObject.value, 'minutes').format("MM/DD/yyyy hh:mm A");
							self.checkSchedule(args.data.id,dropDownListObjectProvider.value,arg1.value,durationObject.value,dropDownListObjectOffice.value,isProviderOccupiedObject.properties.checked,dropDownListObjectWellnessCoord.value,dropDownListObjectbreakProvider.value,break_StartTimeElement.value,break_DurationElement.value);
							self.checkProviderSchedule(arg1.value,dropDownListObjectProvider.value,dropDownListObjectOffice.value,"startTimeError",isProviderOccupiedObject.properties.checked,dropDownListObjectWellnessCoord.value);
							/*refresh slot suggestion list based on changed start time*/
							self.slot_suggestion_time = null;
						},
						open:function(arg1) {
							if (arg1.popup.element.className.indexOf("e-datetimepicker") > -1) {
								var list = arg1.popup.element.querySelectorAll(".e-list-item");
								for (var item = 0; item < list.length; item++) {
				        			if($(list[item]).attr("data-value") == '8:00 AM'){
				        				var start_time_array = $(list[item]).attr("id").split("-");
				        				var start_time  = start_time_array[1];
				        			}
				        			if($(list[item]).attr("data-value") == '8:00 PM'){
				        				var end_time_array = $(list[item]).attr("id").split("-");
				        				var end_time  = end_time_array[1] - 1;
				        			}
				        			if(start_time != undefined || end_time != undefined){        				
				        				if (item < start_time || item > end_time) {
					            			list[item].className = list[item].className + " e-hidden";
					          			}
				        			}
				        			else{
				        				list[item].className = list[item].className + " e-hidden";
				        			}
				          			
				        		}
							}
						},
						format: 'MM/dd/yyyy hh:mm a',
						step: default_steps,
					},
					startElement
					);
				}

				if (!rescheduleTimeElement.classList.contains("e-datetimepicker")) {
					new DateTimePicker({
						value: new Date(rescheduleTimeElement.value) || new Date(),
						/*min: new Date(),*/
						change: function(arg1){
							self.checkSchedule(args.data.id,dropDownListObjectProvider.value,arg1.value,durationObject.value,dropDownListObjectOffice.value,isProviderOccupiedObject.properties.checked,dropDownListObjectWellnessCoord.value,dropDownListObjectbreakProvider.value,break_StartTimeElement.value,break_DurationElement.value);
							let elem = document.getElementById("rescheduledError");
							elem.style.display = "none";
						},
						open:function(arg1) {
							if (arg1.popup.element.className.indexOf("e-datetimepicker") > -1) {
								var list = arg1.popup.element.querySelectorAll(".e-list-item");
								for (var item = 0; item < list.length; item++) {
									if($(list[item]).attr("data-value") == '8:00 AM'){
										var start_time_array = $(list[item]).attr("id").split("-");
										var start_time  = start_time_array[1];
									}
									if($(list[item]).attr("data-value") == '8:00 PM'){
										var end_time_array = $(list[item]).attr("id").split("-");
										var end_time  = end_time_array[1] - 1;
									}
									if(start_time != undefined || end_time != undefined){        				
										if (item < start_time || item > end_time) {
						        			list[item].className = list[item].className + " e-hidden";
						      			}
									}
									else{
										list[item].className = list[item].className + " e-hidden";
									}
						  			
								}
							}
						},
						steps: 5,
						format: 'MM/dd/yyyy hh:mm a',
					},
					rescheduleTimeElement
					);
				}       
				var default_office = (localStorage.getItem("office_access") != null) ? parseInt(localStorage.getItem("office_access")) : this.officeLists[0]['id'];

				var officeForSchedule = (args.data.office_id) ? args.data.office_id : default_office;

				/*office schedule check*/
				axios.post(`office/daywise-schedule`,{'office_id': officeForSchedule, 'schedule_date': startElement.value })
					.then((res) => {
						if(res.data.message != "schedule available"){
							$("#officeScheduleError").html(res.data.message);
						}else{
							$("#officeScheduleError").html("");
						}
					});

				if (!officeElement.classList.contains("e-dropdownlist")) {					
					dropDownListObjectOffice = new DropDownList({
						fields: { text: "name", value: "id" },
						value: (args.data.office_id) ? args.data.office_id : default_office,
						dataSource: this.getDropdownListDS(
								`json_dropdown/get-user-office-list`,
								"GET"
						),
						allowFiltering: true,
						change: function(argsO) {
							self.checkProviderSchedule(startElemntObject.value,dropDownListObjectProvider.value,argsO.value,"startTimeError",isProviderOccupiedObject.properties.checked,dropDownListObjectWellnessCoord.value);
						},
						select: function(args1) {
							let elem = document.getElementById("officeError");
							elem.style.display = "none";

							/*room autopopulate*/
							dropDownListObjectRoom.showSpinner();
							roomElement.value = "";
							dropDownListObjectRoom.dataBind();

							axios.post(`/json_dropdown/${args1.itemData.id}/room`, {default_option: 'Select Room'}).then((res) => {
								// assign new datasource to room dropdown
								dropDownListObjectRoom.dataSource = res.data.data;
								roomElement.value = "";
								dropDownListObjectRoom.hideSpinner();
							});
							/*room autopopulate - End*/

							/*office schedule check*/
							officeForSchedule = (args1.itemData.id) ? args1.itemData.id : default_office;
							
							axios.post(`office/daywise-schedule`,{'office_id': officeForSchedule, 'schedule_date': startElement.value })
								.then((res) => {
									if(res.data.message != "schedule available"){
										$("#officeScheduleError").html(res.data.message);
									}else{
										$("#officeScheduleError").html("");
									}
								});

							/*provider autopopulate*/
							dropDownListObjectProvider.showSpinner();
							roomElement.value = "";
							dropDownListObjectProvider.dataBind();

							/*providerData*/
							var accessProviders = [];
							self.providerLists.forEach((provider) => {
								accessProviders.push(provider.id);
							});               
							axios.post(`/json_dropdown/office-provider`,{'office_id':args1.itemData.id, 'Providers': accessProviders})
							.then((res) => {
								// assign new datasource to room dropdown
								dropDownListObjectProvider.dataSource = res.data.data.loop_data;
								dropDownListObjectProvider.value = res.data.data.office_primary_provider;
								$("#Provider").trigger("change");
								dropDownListObjectProvider.dataBind();
								dropDownListObjectProvider.hideSpinner();
							});
							/*provider autopopulate - End*/

							/*supervisor autopopulate*/
							dropDownListObjectSupervisor.showSpinner();
							roomElement.value = "";
							dropDownListObjectSupervisor.dataBind();

							axios.post(`/json_dropdown/office-provider`,{'office_id':args1.itemData.id,'is_supervisor_list':1, 'Providers': accessProviders, default_option: 'Select Supervisor'}).then((res) => {
								// assign new datasource to room dropdown
								dropDownListObjectSupervisor.dataSource = res.data.data.loop_data;
								dropDownListObjectSupervisor.value = res.data.data.office_supervisor_provider;
								dropDownListObjectSupervisor.hideSpinner();
							});
							/*supervisor autopopulate - End*/

							/*wellness coordinator */
							dropDownListObjectWellnessCoord.showSpinner();
							axios.post(`/json_dropdown/office-staff`,{'office_id': args1.itemData.id, default_option: 'Select Staff' }).then((res) => {
								dropDownListObjectWellnessCoord.dataSource = res.data.data.loop_data;
								dropDownListObjectWellnessCoord.value = '';
								dropDownListObjectWellnessCoord.dataBind();
								dropDownListObjectWellnessCoord.hideSpinner();
							});
						},
					});

					dropDownListObjectOffice.appendTo(officeElement);
					officeElement.setAttribute("name", "office_id");
				}

				if (!roomElement.classList.contains("e-dropdownlist")) {
					var officeId = (args.data.office_id) ? args.data.office_id : dropDownListObjectOffice.value;
					dropDownListObjectRoom = new DropDownList({
						fields: { text: "name", value: "id" },
						placeholder: "Select Room",
						value: args.data.room_id,                
						dataSource: this.getDropdownListDS(
								`json_dropdown/${officeId}/room`,
								"POST",
								{'default_option':'Select Room'}
						),
					});
					dropDownListObjectRoom.appendTo(roomElement);
					roomElement.setAttribute("name", "app_room_id");
				}
				var providerSchedule = (typeof args.data.provider_id === undefined || !args.data.provider_id) ? Number(activeProviderEle) : args.data.provider_id;

				if(officeForSchedule!=null && officeForSchedule!=''){
					axios.post(`provider/daywise-and-officewise-schedule`,{'provider_id' : providerSchedule,'office_id': officeForSchedule, 'schedule_date': startElement.value })
						.then((res) => {			
							if(res.data.message!='schedule available'){
								$("#providerScheduleError").html(res.data.message);
							}else{
								$("#providerScheduleError").html("");
							}			
						});					
				}

				if (!providerElement.classList.contains("e-dropdownlist")) {
					selectedProvider = (typeof args.data.provider_id === undefined || !args.data.provider_id) ? Number(activeProviderEle) : args.data.provider_id;

					if(selectedProvider){
						axios.post(`/supervisor-need`,{'id': selectedProvider })
						.then((res) => {
								isSelectedProviderSupervisor = res.data.data.is_supervisor;
						});            
					}
					
					/*provider list */
					var accessProviders = [];
					self.providerLists.forEach((provider) => {
						accessProviders.push(provider.id);
					});

					axios.post(`/json_dropdown/office-provider`,{'office_id':  ((args.data.office_id) ? args.data.office_id : dropDownListObjectbreakOffice.value), 'Providers': accessProviders})
						.then((res) => {
							dropDownListObjectProvider.dataSource = res.data.data.loop_data;
							dropDownListObjectProvider.value = (args.data.provider_id) ? args.data.provider_id : res.data.data.office_primary_provider;
					});
					/*provider list - End*/
					dropDownListObjectProvider = new DropDownList({
						fields: { text: "name", value: "id" },
						placeholder: "Select Provider",
						value: (typeof args.data.provider_id === undefined || !args.data.provider_id) ? Number(activeProviderEle) : args.data.provider_id,
						allowFiltering: true,
						change: function(argsP) {
							selectedProvider = argsP.value;							
							if(self.objectchanged != null){
								self.objectchanged = true;
							}
							self.checkSchedule(args.data.id,argsP.value,startElemntObject.value,durationObject.value,dropDownListObjectOffice.value,isProviderOccupiedObject.properties.checked,dropDownListObjectWellnessCoord.value,dropDownListObjectbreakProvider.value,break_StartTimeElement.value,break_DurationElement.value);
							self.checkProviderSchedule(startElemntObject.value,argsP.value,dropDownListObjectOffice.value,"startTimeError",isProviderOccupiedObject.properties.checked,dropDownListObjectWellnessCoord.value);
							if(argsP.itemData != null && argsP.itemData.is_appointment_overlapping == '1'){
								allowOverLap = true
								allowOverlappingObject.properties.checked = true
								let elem = document.getElementById("allow-overlapping-section");
									elem.style.display = "none";
							}else{
								allowOverlappingObject.properties.checked = false
								allowOverLap = false
								let elem = document.getElementById("allow-overlapping-section");
									elem.style.display = "block";
							}

							if(dropDownListObjectOffice.value!=null && dropDownListObjectOffice.value!=''){
								axios.post(`provider/daywise-and-officewise-schedule`,{'provider_id' : argsP.value,'office_id': dropDownListObjectOffice.value, 'schedule_date': startElement.value })
									.then((res) => {			
										if(res.data.message!='schedule available'){
											$("#providerScheduleError").html(res.data.message);
										}else{
											$("#providerScheduleError").html("");
										}			
									});								
							}
						},
						select: function(args1) {
							selectedProvider = args1.itemData.id;
							axios
								.post(`/supervisor-need`,{'id': selectedProvider })
								.then((res) => {
									isSelectedProviderSupervisor = res.data.data.is_supervisor;
							});
						},
					});

					dropDownListObjectProvider.appendTo(providerElement);
					providerElement.setAttribute("name", "app_provider_id");
				}

				if (!patientElement.classList.contains("e-autocomplete")) {
					if (args.data.patient_id) {
						selectedPatientID = args.data.patient_id;
						dropDownListObjectPatient = new DropDownList({
							fields:{ 
								text: "name", 
								value: "id" 
							},
							value: args.data.patient_id,
							enabled: false,
							dataSource: [
							{ 
								name: args.data.patient_name, id: args.data.patient_id 
							},
							],
						});
						dropDownListObjectPatient.appendTo(patientElement);
						patientElement.setAttribute("name", "patient_id");
						if (args.data.package_id>0) {
							$(".patient_package").removeClass('d-none');
							var completed_no_of_treatments=parseInt(args.data.completed_no_of_treatments)+1;
							var package_name=args.data.package_name+" [$"+args.data.price_per_treatment+"] ";
							if (!patientPackageElement.classList.contains("e-autocomplete")) {
								dropDownListObjectPackage = new DropDownList({
									fields:{ 
										text: "name", 
										value: "id" 
									},
									value: args.data.package_id,
									enabled: false,
									dataSource: [
									{ 
										name:package_name, id: args.data.package_id 
									},
									],
								});
								dropDownListObjectPackage.appendTo(patientPackageElement);
								packageId=args.data.package_id;
								package_set=0;
							}		
						}else{	
							axios.get(`/patient-package/active-packages/${args.data.patient_id}`)
							.then((res) => {
								if(res.data.data.length>0){
									$(".patient_package").removeClass('d-none');
									var optData = new Array();
									optData.push({"name":"Select Package","id":0})
									var packageOpt = res.data.data.map((packageOpt) => {
											optData.push({"name":packageOpt.package_display_name,
											"id":packageOpt.id});
									});
									if(args.data.patientBillingId==null){
										if (!patientPackageElement.classList.contains("e-dropdownlist")) {
											dropDownListObjectPackage = new DropDownList({
												fields: { text: "name", value: "id" },
												placeholder: "Select Package",
												value: args.data.room_id,                
												dataSource: optData,
												select: function(argsP) {
													packageId=argsP.itemData.id;
													package_set=1;
													let elem = document.getElementById("packageapp");
													elem.style.display = "block";
												}
												
											});
											dropDownListObjectPackage.appendTo(patientPackageElement);
										}
									}else{
										if (!patientPackageElement.classList.contains("e-dropdownlist")) {
											dropDownListObjectPackage = new DropDownList({
												fields: { text: "name", value: "id" },
												placeholder: "Select Package",
												value: args.data.room_id,                
												dataSource: optData,
												enabled: false,
												select: function(argsP) {
													package_set=0;		
												
												}
												
											});
											dropDownListObjectPackage.appendTo(patientPackageElement);
										}
										let elem1 = document.getElementById("packageapp1");
										elem1.style.display = "block";
									}
									
								}
							});
							
						}
					} else {
						selectedPatientID = args.data.patient_id;
						dropDownListObjectPatient = new AutoComplete({
							fields: 
							{ 
								text: "name", 
								value: "name" 
							},
							placeholder: "Select Patient",
							value: args.data.patient_name,
							dataSource: this.getDropdownListDS(`patient/patient-search`,"POST"),
							select: function(args1) {
								/*Edit link setup*/
								this.pp_id=args.data.pp_id;
								$(".edit_patient").attr("data-id", args1.itemData.unique_id);
								$(".edit_patient").attr("title", "Edit chart of "+ args1.itemData.name);

								$(".portal_active").attr("data-id", args1.itemData.id);
								$(".portal_active").attr("data-email", args1.itemData.email);
								$(".portal_active").attr("data-dob", args1.itemData.date_of_birth);
								$(".portal_active").attr("data-ppid", args1.itemData.pp_id);
								$(".portal_active").attr("data-cellphone", args1.itemData.cell_phone);
								$(".portal_active").attr("data-uniqueid", args1.itemData.unique_id);
								$(".portal_active").attr("data-pp_status", args1.itemData.pp_status);
								
								/*Edit link setup - End*/

								if($(".patient_info").length==0)
								{
									$(".e-dlg-header").append('<div class="d-inline-block ms-20 patient_info"></div>');
								}

								var phone_icon = (args1.itemData.cell_phone== null || args1.itemData.cell_phone=='') ? "glyphicon glyphicon-phone-alt" : "glyphicon glyphicon-earphone", P_phone = (args1.itemData.cell_phone== null || args1.itemData.cell_phone=='') ? args1.itemData.home_phone : args1.itemData.cell_phone;
								P_phone = (P_phone == null || P_phone == '') ? 'Not Available' : P_phone;

								var P_email = (args1.itemData.email == null || args1.itemData.email == '') ? 'Not Available' : args1.itemData.email;

								$(".patient_info").html('<a href="mailto:'+P_email+'" target="_blank" title="'+P_email+'" class="text-link patient_email me-2"><i class="fa fa-envelope fs-14 me-1"></i> '+P_email+'</a> | <a href="tel:'+P_phone+'" target="_blank" title="Phone" class="ms-2"><i class="'+phone_icon+' fs-14 me-1"></i> '+P_phone+'</a>');

								selectedPatientID = args1.itemData.id;
								let elem = document.getElementById("patientError");
								elem.style.display = "none";

								/*active package selection*/
								
								
									axios.get(`/patient-package/active-packages/${args1.itemData.id}`)
									.then((res) => {
										if(res.data.data.length>0){
											$(".patient_package").removeClass('d-none');
											var optData = new Array();
											optData.push({"name":"Select Package","id":0})
											
											var packageOpt = res.data.data.map((packageOpt) => {
									        		optData.push({"name":packageOpt.package_display_name,
													"id":packageOpt.id});
									        });
											if (!patientPackageElement.classList.contains("e-dropdownlist")) {
												
												dropDownListObjectPackage = new DropDownList({
													fields: { text: "name", value: "id" },
													placeholder: "Select Package",
													value: args.data.room_id,                
													dataSource: optData,
													select: function(argsP) {
														packageId=argsP.itemData.id;
														package_set=1;
														let elem = document.getElementById("packageapp");
														elem.style.display = "block";
													}
													
												});
												dropDownListObjectPackage.appendTo(patientPackageElement);
											}
										}
								});
								
								
							},
						});
						dropDownListObjectPatient.appendTo(patientElement);
						patientElement.setAttribute("name", "patient_name");
					}
				}

				if (!supervisorElement.classList.contains("e-dropdownlist")) {
						var accessProviders = [];
						self.providerLists.forEach((provider) => {
								accessProviders.push(provider.id);
						});
					axios.post(`/json_dropdown/office-provider`,{'office_id': dropDownListObjectOffice.value, 'Providers': accessProviders, 'is_supervisor_list':1,'default_option':'Select Supervisor'})
						.then((res) => {
							dropDownListObjectSupervisor.dataSource = res.data.data.loop_data;
							// dropDownListObjectSupervisor.value = res.data.data.office_primary_provider;
					});
					dropDownListObjectSupervisor = new DropDownList({
						fields: { text: "name", value: "id" },
						placeholder: "Select Supervisor",
						value: args.data.supervisor_id,
						select: function(args1) {
							let elem = document.getElementById("supervisorError");
							elem.style.display = "none";
						}
					});
					dropDownListObjectSupervisor.appendTo(supervisorElement);
					supervisorElement.setAttribute("name", "supervisor_id");
				}

				if (!wellnessCoordinatorElement.classList.contains("e-dropdownlist")) {
					axios.post(`/json_dropdown/office-staff`,{'office_id': dropDownListObjectOffice.value, default_option: 'Select Staff' })
						.then((res) => {
							dropDownListObjectWellnessCoord.dataSource = res.data.data.loop_data;
					});
					if (args.data.coordinator_staff_id) {
						let elem = document.getElementById("is-provider-occupied-section");
						elem.style.display = "block";
					}
					dropDownListObjectWellnessCoord = new DropDownList({
						fields: { text: "name", value: "id" },
						placeholder: "Select Staff",
						value: args.data.coordinator_staff_id,						
						change: function(argsWC) {
							if (argsWC.value) {
								let elem = document.getElementById("is-provider-occupied-section");
								elem.style.display = "block";
								if (argsWC.isInteracted) {
									if (argsWC.itemData.need_provider_help_for_appt == '1') {
										if ($("#is_provider_occupied").prop('checked') == false) {
											document.querySelector("#is_provider_occupied").click();
										}
									} else {
										if ($("#is_provider_occupied").prop('checked') == true) {
											document.querySelector("#is_provider_occupied").click();
										}
									}
								}								
							} else {
								let elem = document.getElementById("is-provider-occupied-section");
								elem.style.display = "none";
								//isProviderOccupiedObject.properties.checked = true;
							}
							self.checkSchedule(args.data.id,dropDownListObjectProvider.value,startElemntObject.value,durationObject.value,dropDownListObjectOffice.value,isProviderOccupiedObject.properties.checked,argsWC.value,dropDownListObjectbreakProvider.value,break_StartTimeElement.value,break_DurationElement.value);
							self.checkProviderSchedule(startElemntObject.value,dropDownListObjectProvider.value,dropDownListObjectOffice.value,"startTimeError",isProviderOccupiedObject.properties.checked,argsWC.value);
							
						}
					});
					dropDownListObjectWellnessCoord.appendTo(wellnessCoordinatorElement);
					wellnessCoordinatorElement.setAttribute("name", "coordinator_staff_id");
				}
				if (!crmProspectPatientStatus.classList.contains("e-dropdownlist")) {
					axios.get(`/json_dropdown/appointment-crm-prospect-status`)
						.then((res) => {
							if (res.data.data.length > 0) {
								dropDownListObjectCRMStaus.dataSource = res.data.data;
							}
					});
					dropDownListObjectCRMStaus = new DropDownList({
						fields: { text: "name", value: "id" },
						placeholder: "Select CRM Status",
						value: args.data.crm_prospect_patient_status,
						select: function(args1) {
							let elem = document.getElementById("crm_prospect_patient_status_error");
							elem.style.display = "none";
						}
					});
					dropDownListObjectCRMStaus.appendTo(crmProspectPatientStatus);
					crmProspectPatientStatus.setAttribute("name", "crm_prospect_patient_status");
				}

				if (!consentFormElement.classList.contains("e-multiselect")) {
					var consent_form_ids=[];
					if (args.data.consent_form_ids && args.data.consent_form_ids.includes(",")) {
						consent_form_ids=args.data.consent_form_ids.split(",");
					} else {
						consent_form_ids=[args.data.consent_form_ids];
					}
					
					if(args.data.consent_form_ids!=""){
						let tooltip="";
						signedConsentFormmsg="";
						axios.post("appointment/signed-consent-form",{appointment_id:args.data.id,form_id:consent_form_ids})
							.then((response) =>{
								console.log(response.data.data);
								if (response.data.data.length>0) {
									let ele=document.getElementById('consentFormSigned');
									ele.style.display="block";
									for (const key in response.data.data) {
										if (Object.hasOwnProperty.call(response.data.data, key)) {
											const element = response.data.data[key];
											if(!signedConsentForm.includes(element.form_id)){
												signedConsentForm.push(element.form_id)
												signedConsentFormmsg+=element.consent_form_name+" is signed  by the patient.</br>";
											}
										}
									}
									let button  = new Button();
									//Render initialized Button component
									button.appendTo('#consentFormSigned');
									//Initialize Tooltip component
									tooltip="";
									tooltip= new Tooltip({
										//Set tooltip content
										content: signedConsentFormmsg
									});
								
									//Render initialized Tooltip component
									tooltip.appendTo('#consentFormSigned');
								}else{
									let ele=document.getElementById('consentFormSigned');
									ele.style.display="none";
								}
							}	
						); 
					}else{
						let ele=document.getElementById('consentFormSigned');
						ele.style.display="none";
					}
					let multiSelectObject = new MultiSelect({
						placeholder: "Select Consent Forms",
						fields: { text: "name", value: "id" },
						mode: "Box",
						dataSource: this.getDropdownListDS(
							`json_dropdown/consent-form`,
							"GET"
						),            
						value: args.data.consent_form_ids,
					});
					multiSelectObject.appendTo(consentFormElement);
					consentFormElement.setAttribute("name", "consent_form_ids");
				}       
				var default_duration = (localStorage.getItem("exam_duration") != null) ? localStorage.getItem("exam_duration") : '15';		
				if(!durationElement.classList.contains("e-numerictextbox")){
					durationObject = new NumericTextBox({
						max: 3000,
						min: 0,
						strictMode: false,
						step: 5,
						format: "##",
						change: function(argsDuration){
							endElement.value = moment(startElement.value).add(argsDuration.value, 'minutes').format("MM/DD/yyyy hh:mm A");
							$(".e-datetimepicker").trigger("click");
						},
						value: (args.data.duration) ? args.data.duration : default_duration,
					});
					durationObject.appendTo(durationElement);
					durationElement.setAttribute("name", "duration");
				}

				if(!apptColorElement.classList.contains("e-numerictextbox")){
					let apptColorObject = new ColorPicker({            
						value: (args.data.appointment_color_code) ? args.data.appointment_color_code : '#0265a0',
						mode: 'Palette',
						showButtons:false,
					});
					$(".e-split-btn-wrapper > .e-split-colorpicker > .e-btn-icon > .e-split-preview").css('background-color',args.data.appointment_color_code);

					apptColorObject.appendTo(apptColorElement);
					apptColorElement.setAttribute("name", "color_code");
				}

				if (!apptProfileElement.classList.contains("e-dropdownlist")) {

					let $this = this;
					let dropDownListObject = new DropDownList({
						fields: { text: "Apname", value: "id" },
						placeholder: "Select Visit Profile",
						value: args.data.appointment_profile_id,
						dataSource: this.getDropdownListDS(
							`json_dropdown/appointment-profile-CPT-code`,
							"GET"
						),
						change: function(arg1) {
							if(arg1.value!=null){
								axios.post("/appointment_profile/consent-forms",{'appointment_profile_id': arg1.value}).then(function(res) {
									if (res.data.status == "success") {  
										/*duration changed*/ 
										durationObject.value = (res.data.data.default_duration) ? res.data.data.default_duration : '15';
										/*duration changed -- End*/

										/*consent form changed*/
										consentFormElement.ej2_instances[0].value = (res.data.data.consent_form_ids) ? res.data.data.consent_form_ids.split(",").map(String) : '';
										/*consent form changed -- End*/
								
										/*End Time changed*/
										endElement.value = moment(startElement.value).add(durationObject.value, 'minutes').format("MM/DD/yyyy hh:mm A");
										$( ".e-datetimepicker#EndTime" ).trigger("click");
										/*End Time changed -- End*/

										/*Appointment color Element changed*/
										apptColorElement.value = (res.data.data.color_code) ? res.data.data.color_code : '#008000';
										$(".e-split-btn-wrapper > .e-split-colorpicker > .e-btn-icon > .e-split-preview").css('background-color',apptColorElement.value);
										/*Appointment color Element changed -- End*/
									}
								});

								axios.get("/appointment-profile/get-billable-amount/"+arg1.value).then(function(res) {
									if (res.data.status == "success") {
										if (res.data.data.payable_amount > 0) {
											$('#showNoBillableInfo').hide();
											$('#showNoBillableAmount').html("Amount : $"+res.data.data.payable_amount).show();
										} else {
											$('#showNoBillableAmount').html('').hide();
											$('#showNoBillableInfo').show();
										}
									}
								});

								/*Reason Element changed*/
								if(arg1.itemData.reason_for_visit){
                                    var reason_value = arg1.itemData.reason_for_visit;
                                }else{
                                    var reason_value = (arg1.item) ? arg1.item.innerText : null;
                                }                               
                                reasonElement.value = reason_value;
								if(reasonElement.value!=null){
									let elem = document.getElementById("reasonError");
									elem.style.display = "none";
								}
								/*reasonElement.dispatchEvent("change");*/
								/*Reason Element changed -- End*/
							}
						},
						allowFiltering: true,
					});
					dropDownListObject.appendTo(apptProfileElement);
					apptProfileElement.setAttribute("name", "appointment_profile_id");
				}

				if (reasonElement) {
					reasonElement.value = args.data.reason_for_visit || "";
					reasonElement.addEventListener("input", function(args1) {
						if (args1.target.value || $(".e-datetimepicker#reason_for_visit").val()!='') {
							let elem = document.getElementById("reasonError");
							elem.style.display = "none";
						} else {
							let elem = document.getElementById("reasonError");
							elem.style.display = "block";
						}
					});
				}

				if (notesElement) {
					notesElement.value = args.data.notes || "";
				}

				if (subjectElement) {
					subjectElement.value = args.data.Subject || "";
				}

				if (!statusElement.classList.contains("e-dropdownlist")) {
					let dropDownListObject = new DropDownList({
						fields: { text: "name", value: "id" },
						placeholder: "Choose status",
						value: (args.data.status) ? args.data.status : 1,
						dataSource: this.getDropdownListDS(
							`json_dropdown/appointment-status`,
							"GET"
						),
						select() {
							let elem = document.getElementById("statusError");
							elem.style.display = "none";
						},
						change(args) {
							if(args.itemData)
							{
								if(args.itemData.name === 'Rescheduled'){
									$("#rescheduleSection").removeClass("d-none");
								}
								else
								{
									if(args.itemData.name === "Complete"){
										package_set=1;
									}
									$("#rescheduleSection:not([class*='d-none'])").addClass("d-none");
								}
							}
						},
						allowFiltering: true,
					});
					dropDownListObject.appendTo(statusElement);
					statusElement.setAttribute("name", "status");
				}

				var typeAppointment = (args.data.appointment_type) ? args.data.appointment_type : "Office";				
				if(!appointmentTypeElementBreak.classList.contains("e-RadioButtonPlugin")) {						
					let radioButtonObjectAppointmentType = new RadioButton({ 
						label: 'Break', 
						name: 'appointment_type', 
						value: 'Break',
						checked: (typeAppointment == 'Break') ? true : false,
						change(args1){
							if(self.objectchanged != null){
								self.objectchanged = true;
							}							
							self.changeAppointmentType(args1, is_recurringObject, startElement.value);
							selectedType = args1.value;
							self.checkProviderSchedule(startElemntObject.value,dropDownListObjectProvider.value,dropDownListObjectOffice.value,"startTimeError",isProviderOccupiedObject.properties.checked,dropDownListObjectWellnessCoord.value);
						}
					});
					radioButtonObjectAppointmentType.appendTo(appointmentTypeElementBreak);
					appointmentTypeElementBreak.setAttribute("name", "appointment_type");
				}
				if(!appointmentTypeElementOffice.classList.contains("e-RadioButtonPlugin")) {						
					let radioButtonObjectAppointmentType = new RadioButton({ 
						label: 'Office', 
						name: 'appointment_type', 
						value: 'Office',
						checked: (typeAppointment == 'Office') ? true : false,
						change(args1){
							if(self.objectchanged != null){
								self.objectchanged = true;
							}
							self.changeAppointmentType(args1, is_recurringObject, startElement.value);
							selectedType = args1.value;
							self.checkProviderSchedule(startElemntObject.value,dropDownListObjectProvider.value,dropDownListObjectOffice.value,"startTimeError",isProviderOccupiedObject.properties.checked,dropDownListObjectWellnessCoord.value);
						}
					});
					radioButtonObjectAppointmentType.appendTo(appointmentTypeElementOffice);
					appointmentTypeElementOffice.setAttribute("name", "appointment_type");
				}

				if(!appointmentTypeElementTeleVisit.classList.contains("e-RadioButtonPlugin")) {						
					let radioButtonObjectAppointmentType = new RadioButton({ 
						label: 'Tele Health', 
						name: 'appointment_type', 
						value: 'Tele Health',
						checked: (typeAppointment == 'Tele Health') ? true : false,
						change(args1){
							if(self.objectchanged != null){
								self.objectchanged = true;
							}
							self.changeAppointmentType(args1, is_recurringObject, startElement.value);
							selectedType = args1.value;
							self.checkProviderSchedule(startElemntObject.value,dropDownListObjectProvider.value,dropDownListObjectOffice.value,"startTimeError",isProviderOccupiedObject.properties.checked,dropDownListObjectWellnessCoord.value);
						}
					});
					radioButtonObjectAppointmentType.appendTo(appointmentTypeElementTeleVisit);
					appointmentTypeElementTeleVisit.setAttribute("name", "appointment_type");
				}        

				if(!appointmentTypeElementPhone.classList.contains("e-RadioButtonPlugin")) {
					let radioButtonObjectAppointmentType = new RadioButton({ 
						label: 'Phone', 
						name: 'appointment_type', 
						value: 'Phone',
						checked: (typeAppointment == 'Phone') ? true : false,
						change(args1){
							if(self.objectchanged != null){
								self.objectchanged = true;
							}
							self.changeAppointmentType(args1, is_recurringObject, startElement.value);
							selectedType = args1.value;
							self.checkProviderSchedule(startElemntObject.value,dropDownListObjectProvider.value,dropDownListObjectOffice.value,"startTimeError",isProviderOccupiedObject.properties.checked,dropDownListObjectWellnessCoord.value);
						}
					});
					radioButtonObjectAppointmentType.appendTo(appointmentTypeElementPhone);
					appointmentTypeElementPhone.setAttribute("name", "appointment_type");
				}

				if(!appointmentTypeElementWalkIn.classList.contains("e-RadioButtonPlugin")) {
					let radioButtonObjectAppointmentType = new RadioButton({ 
						label: 'Walk-in', 
						name: 'appointment_type', 
						value: 'WalkIn',
						checked: (typeAppointment == 'WalkIn') ? true : false,
						change(args1){
							if(self.objectchanged != null){
								self.objectchanged = true;
							}
							self.changeAppointmentType(args1, is_recurringObject, startElement.value);
							selectedType = args1.value;
							self.checkProviderSchedule(startElemntObject.value,dropDownListObjectProvider.value,dropDownListObjectOffice.value,"startTimeError",isProviderOccupiedObject.properties.checked,dropDownListObjectWellnessCoord.value);
						}
					});
					radioButtonObjectAppointmentType.appendTo(appointmentTypeElementWalkIn);
					appointmentTypeElementWalkIn.setAttribute("name", "appointment_type");
				}        
				self.objectchanged = false;				
			}
			if (args.type === "QuickInfo") {
				if ((!args.data.reason && !args.data.status_label)) {
					args.cancel = true;
				}
			}
			$(".delete_all_break").remove();
			if(args.type === 'DeleteAlert' && args.data.appointment_type=='Break'){
				var common_number = args.data.common_number;
				var self = this;
				let scheduleObj = this.$refs.ScheduleObj;
				let content = args.element.querySelector('.e-footer-content'); 
				var button = document.createElement("button");
				button.innerHTML = "Delete All";
				button.setAttribute("class","e-control e-btn e-lib e-quick-alertok e-flat e-primary delete_all_break");
				content.prepend(button);
				button.addEventListener ("click", function() {
					axios.post("/appointment/delete-all-break",{common_number:common_number})
					.then((response) => {
						document.querySelector('.e-quick-dialog-cancel').click();
						self.$swal.fire({
							input: 'textarea',
							inputLabel: 'Enter reason for delete.',
							inputPlaceholder: 'Enter reason for delete...',
							inputAttributes: {
								'aria-label': 'Enter reason for delete'
							},
							allowOutsideClick: false,
							showCancelButton: false,
							confirmButtonText: 'Submit', 
							inputValidator: function(inputValue) {
								return new Promise(function(resolve, reject) {
									if (inputValue && inputValue.length > 0) {
										resolve();
									} else {
										self.$swal.showValidationMessage('Can not be blank') 
										self.$swal.enableInput()
										self.$swal.enableButtons()
									}
								});
							}                        
						})
						.then((result) => {
							if (result.isConfirmed == true) {
								axios.post("/appointment/reason-for-delete-allbreak",{common_number:common_number,reason_for_delete:result.value})
								.then(response=>{
									self.moshaToast("Reason added successfully.", "success")
									scheduleObj.refreshEvents()
								});
							}
						});						
					})
				});
				
			}
		},
		onDropdownOpen: function () {
		    if (document.querySelector("#ej2_dropdownlist_20_options li:last-child").innerText === "Count") {
		        document.querySelector("#ej2_dropdownlist_20_options li:last-child").remove();
		    }
	    },
		changeDate(event) {
			this.selectedDate = event.value;
			this.outerCaledarClick = true;
		},
		checkSchedule(id,provider_id,starttime,duration, office_id, is_provider_occupied,coordinator_staff_id,break_provider_id,break_startTime,break_duration){
			var apptype = $("input[type=radio][name='appointment_type']:checked").val();
			if(this.objectchanged == true && allowOverLap == false){
				callStore = true;
				var app_provider_id = provider_id;
				var app_coordinator_staff_id = coordinator_staff_id;
				var StartTime = moment(starttime).format("MM/DD/yyyy hh:mm A");
				var duration = duration;
				var office_id = office_id;
				var id = id;
				var self = this;
				axios.post("/appointment/check-schedule",{
					app_provider_id : app_provider_id,
					is_provider_occupied : is_provider_occupied,
					app_coordinator_staff_id : app_coordinator_staff_id,
					StartTime: StartTime,
					duration: duration,
					Id: id,
					break_StartTime: break_startTime,
					break_provider_id: break_provider_id,
					break_duration: break_duration,
					appointment_type: apptype,
				})
				.then((response) => {
					let elem = document.getElementById("providerError");
					elem.style.display = "none";		
					let elemcord = document.getElementById("WellnessCoordinatorError");
					elemcord.style.display = "none";
					let bk_elem = document.getElementById("break_providerError");
					bk_elem.style.display = "none";		
					$("#DisplayAvailableSlotsReschedule").addClass("d-none");
					$("#DisplayAvailableSlots").addClass("d-none")
				})
				.catch((error) => {
					if($("#appointment_type_break").is(":checked")){
						let bk_elem = document.getElementById("break_providerError");
						bk_elem.innerText = "The provider is occupied in this time slot.";
						bk_elem.style.display = "block";
					}else{
						let elem = document.getElementById("providerError");
						elem.style.display = "none";		
						let elemcord = document.getElementById("WellnessCoordinatorError");
						elemcord.style.display = "none";
						let bk_elem = document.getElementById("break_providerError");
						bk_elem.style.display = "none";			
	
						var selected_status = $("#Status_hidden").val();
						if(selected_status == '9'){
							$("#DisplayAvailableSlotsReschedule").removeClass("d-none")
						}else{
							$("#DisplayAvailableSlots").removeClass("d-none")
						}
						if (error.response.data.data.user_type == 'provider') {
							let elem = document.getElementById("providerError");
							elem.innerText = "The provider is occupied in this time slot.";
							elem.style.display = "block";
						} else {
							let elem = document.getElementById("WellnessCoordinatorError");
							elem.innerText = "The staff is occupied in this time slot.";
							elem.style.display = "block";
						}
						this.flag = false; 
						$('#DisplayAvailableSlots').unbind('click');
						$('#DisplayAvailableSlotsReschedule').unbind('click');
						$(document).off("click","#DisplayAvailableSlotsReschedule,#DisplayAvailableSlots");
						$(document).on("click","#DisplayAvailableSlotsReschedule,#DisplayAvailableSlots", function() {
							self.slot_suggestion_list = [];
							self.choose_slot_loader = true;
							axios.post("/appointment/suggestion-schedule",{
								app_provider_id :app_provider_id,
								app_coordinator_staff_id :$('#WellnessCoordinator_hidden').val(),
								is_provider_occupied :is_provider_occupied,
								StartTime:StartTime,
								duration:duration,
								office_id:office_id,
							})
							.then((response) => {
								self.choose_slot_loader = false;
								self.slot_suggestion_list = response.data.data;
							});
							$(".free_slot").hide();
							$('.check_box').unbind('click');
							$(".check_box").click(function() {
								if($(".free_slot").hasClass("d-block")){
									$(this).text("more");
									$(".free_slot").removeClass("d-block");
								}else{
									$(this).text("less");
									$(".free_slot").addClass("d-block");
								}
							});
						});
					}
				});
			}					
			// if(new Date(starttime) >= new Date()){
			// 	document.getElementById('reminderProfileCheckLabel').innerHTML = 'View Active Reminders';
			// 	document.getElementById("reminderProfileCheck").disabled= false;
			// 	document.getElementById("reminderProfileCheck").indeterminate = false;
			// }else{
			// 	document.getElementById('reminderProfileCheckLabel').innerHTML = 'View Active Reminders (Reminder Profile cannot be scheduled before the current time)';
			// 	document.getElementById("reminderProfileCheck").disabled= true;
			// 	document.getElementById("reminderProfileCheck").indeterminate = true;
			// }
		},
		select_schedule_slot(){
			let startElement = document.querySelector("#StartTime");
			let rescheduleTimeElement =	document.querySelector("#reschedule_time");
			let durationElement = document.querySelector("#duration");
			let endElement = document.querySelector("#EndTime");
			var selected_status = $("#Status_hidden").val();
			if(selected_status == '9'){
				this.slot_suggestion_time = moment(rescheduleTimeElement.value).format("MM/DD/yyyy")+' '+this.slot_suggestion_time;
				this.slot_suggestion_for = "Reschedule";
				rescheduleTimeElement.value =  moment(this.slot_suggestion_time).format("MM/DD/yyyy hh:mm A");
				$('#DisplayAvailableSlotsReschedule').addClass('d-none');
			}else{
				this.slot_suggestion_time = moment(startElement.value).format("MM/DD/yyyy")+' '+this.slot_suggestion_time;
				this.slot_suggestion_for = "Schedule";
				startElement.value = moment(this.slot_suggestion_time).format("MM/DD/yyyy hh:mm A");
				endElement.value = moment(startElement.value).add(durationElement.value, 'minutes').format("MM/DD/yyyy hh:mm A");
				this.slot_suggestion_time = startElement.value;
				this.slot_suggestion_end_time = endElement.value;
				$('#DisplayAvailableSlots').addClass('d-none');
			}			
			$("#providerError").addClass('d-none');
			$("#WellnessCoordinatorError").addClass('d-none');
		},
		printApptData(type) {
			var activeView = $('.e-active-view').find('.e-tbar-btn-text').text();
			//console.log(activeView,"yogi");
			if(type == 'pdf') {
				this.getSrc(appData,activeView);
			} else {
				axios.post("dashboard/print-today-appointment",{"appointmentlist": appData, 'export_type' : "excel",'print_from':"calendar",'view':activeView})
					.then((response) => {
						let blob = new Blob([response.data.data], {
							type: 'application/pdf'
						})
						let link = document.createElement('a')
						link.href = response.data.data
						link.download = 'appointment.xlsx'
						link.click()
						this.moshaToast("Download XSL successfully", "success");
					});
			}
		},
		async getSrc(apptList,activeView) {
			var date_range = startDate+"-"+endDate;
			const res = await fetch(process.env.VUE_APP_API_BASE_URL+'/dashboard/print-today-appointment', {
				method: 'POST',
				headers: {
				'Authorization' : 'Bearer '+localStorage.getItem('authToken'),
				'Content-Type' : 'application/json'
				},
				body: JSON.stringify({ "appointmentlist": apptList,"date_range":date_range,"print_from":"calendar","view":activeView}),
			});
			const blob = await res.blob();
			const urlObject = URL.createObjectURL(blob);
			if (/Android|webOS|Opera Mini/i.test(navigator.userAgent)) {
				$("#printApptModal").modal("hide");
				window.open(urlObject, '_blank', 'height=600,width=1200');
			} else {
				this.receipt_url = urlObject;
				this.browserCheck();
			}
		},
		browserCheck(){
			// Opera 8.0+
			var isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

			// Firefox 1.0+
			var isFirefox = typeof InstallTrigger !== 'undefined';

			// Safari 3.0+ "[object HTMLElementConstructor]" 
			var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));

			// Internet Explorer 6-11
			var isIE = /*@cc_on!@*/false || !!document.documentMode;

			// Edge 20+
			var isEdge = !isIE && !!window.StyleMedia;

			// Chrome 1 - 79
			var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

			// Edge (based on chromium) detection
			var isEdgeChromium = isChrome && (navigator.userAgent.indexOf("Edg") != -1);

			// Blink engine detection
			var isBlink = (isChrome || isOpera) && !!window.CSS;

			if (isChrome==true) {
			this.embed = 0;
			} else {
			this.embed = 1;
			}
		},
	},
	mounted() {
		let _self = this
		if(_self.$store.state.date != ''){
			_self.selectedDate = _self.$store.state.date;
			_self.changeDate();
		}
		$(document).on("click",".slot_book",function(){
			var length = $("div#recurringscheduling > div > div > .modal-body").children().length;    
			var recurrencedate = []; 
		    for (var i = 0; i < length; i++) {
		    	if(recurrencedate_freeslots == null && i==0)
		    	{
		    		recurrencedate_freeslots = $("#free_sloat"+i).val();
		    	}
		    	else
		    	{
		    		recurrencedate_freeslots.push($("#free_sloat"+i).val());
		    	}
		    }

		    $("#unavailable-slots").addClass("d-none");
		    $("#recurringscheduling > div > div > .modal-body").html("");
		});
		$('.cal_scroll').css('max-height',(window.innerHeight-135)+'px');
	},
	async created() {
		this.selectedDate = new Date();
		$(document).on("click",".collapse-quickpopup", function() {
			if($(this).text() == "More"){
				$(this).text("Less")
				$(this).parents().eq(5).find(".quick-info-content").removeClass('d-none');
			}else{
				$(this).text("More")
				$(this).parents().eq(5).find(".quick-info-content").addClass('d-none');
			}
		});
		switch (localStorage.getItem("calendar_view")) {
			case "Day":
				this.default_day_view = true;
				break;
			case "Monthly":
				this.default_month_view = true;
				break;
			case "Week":
				this.default_week_view = true;
				break;
			case "Room":
				this.default_room_view = true;
				break;
			case "Provider":
				this.default_provider_view = true;
				break;		
			default:
				this.default_week_view = true;
				break;
		}
	},
	computed: {
		...mapGetters("auth", ["user"]),
	},
	data() {
		return {
			outerCaledarClick: false,
			default_day_view: false,
			default_month_view: false,
			default_week_view: false,
			default_room_view: false,
			default_provider_view: false,
			recurrencedates	: [],
			isDragged: false,
			DragAndDropErr:true,
			objectchanged: null,		
			slot_suggestion_for: null,
			slot_suggestion_time: null,
			slot_suggestion_end_time: null,
			slot_suggestion_list: [],
			appointment_profile: [],			
			choose_slot_loader: false,			
			changed_appointment_type: 'Office',
			showPop: 0,
			changedData: 0,
			timeScale: {
				enable: true,
				interval: 60,
				slotCount: 4,
			},
			editorTemplate: function() {
				return { template: editorTemplateVue };
			},
			providerResourceHeaderTemplate: function(e) {
				return {
					template: providerResourceHeaderTemplateVue,
				};
			},
			roomResourceHeaderTemplate: function(e) {
				return {
					template: roomResourceHeaderTemplateVue,
				};
			},

			quickInfoTemplates: {
				header: function(e) {
					return {
						template: headerTemplateVue,
					};
				},
				content: function(e) {
					return {
						template: contentTemplateVue,
					};
				},
				footer: function(e) {
					return {
						template: footerTemplateVue,
					};
				},
			},
			providerLists: [],
			officeLists: [],
			roomLists: [],
			crmStatusLists: [],
			coordinatorLists: [],
			apptypeLists: [],
			selectedDate: "",
			allowMultiple: true,

			roomDataSource: roomCollections,
			providerDataSource: providerCollections,
			profileDataSource: profileCollections,

			group: { resources: ["Providers"] },
			group1: { resources: ["Rooms"] },
			eventSettings: {
				dataSource: dataManager,
				resourceColorField: "Profile",
			},
			path: process.env.VUE_APP_KB_DOCUMENT,
			algoKey:0,
			receipt_url: '',
		};
	},
	provide: {
		schedule: [Day, Week, WorkWeek, Month, Agenda, DragAndDrop, Resize],
	},
};
</script>

<style>
@import "../../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-calendars/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-vue-dropdowns/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-vue-schedule/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-vue-calendars/styles/material.css";

#schedule_dialog_wrapper {
	/*min-width: 1000px !important;*/
	min-height: auto !important;
    width: 100% !important;
    max-width: 1220px;

}

.quick-info-header {}

.quick-info-header-content {
	justify-content: flex-end;
	display: flex;
	flex-direction: column;
	padding: 5px 10px 5px;
}

.quick-info-title {
	font-weight: 500;
	font-size: 16px;
	letter-spacing: 0.48px;
	height: 22px;
}

.duration-text {
	font-size: 11px;
	letter-spacing: 0.33px;
	height: 14px;
}

.content-area {
	margin: 0;
	padding: 10px;
	width: auto;
}

.event-content {
	height: 90px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 0 15px;
}

.meeting-type-wrap,
.meeting-subject-wrap,
.notes-wrap {
	font-size: 11px;
	color: #666;
	letter-spacing: 0.33px;
	height: 24px;
	padding: 5px;
}

.event-content div label {
	display: inline-block;
	min-width: 45px;
	color: #666;
}

.event-content div span {
	font-size: 11px;
	color: #151515;
	letter-spacing: 0.33px;
	line-height: 14px;
	padding-left: 8px;
}

.cell-footer.e-btn {
	background-color: #ffffff;
	border-color: #878787;
	color: #878787;
}

.cell-footer {
	padding-top: 10px;
}

.e-quick-popup-wrapper .e-cell-popup .e-popup-content {
	padding: 0 14px;
}

.e-quick-popup-wrapper .e-event-popup .e-popup-footer {
	display: block;
}

.e-quick-popup-wrapper .e-popup-footer button:first-child {
	margin-right: 5px;
}
/* checkbox  */
.e-checkbox-wrapper.e-info .e-frame.e-check,
.e-checkbox-wrapper.e-info .e-checkbox:focus + .e-frame.e-check {
	/* csslint allow: adjoining-classes */
	background-color: #0265a0;border: 1px solid #b9c8d7;
		padding: 3px 0;
} 

/* hide date header */

/* .e-schedule .e-vertical-view .e-left-indent-wrap table tbody td.e-header-cells {
	display: none;
}
.e-schedule .e-vertical-view .e-date-header-wrap table tbody td.e-header-cells {
	display: none;
} */

/* quick info */
.e-quick-popup-wrapper {
	max-width: 450px;
}
.e-schedule .e-vertical-view .e-day-wrapper .e-appointment .e-time{padding-top: 0;line-height: normal;}

.e-schedule-dialog.e-device .e-dlg-header, .e-schedule-dialog.e-device .e-dlg-header *{width: auto;}
.swal2-html-container{margin-top: 25px !important;}
.e-dlg-container{z-index: 1000 !important}
.schedule-cell-dimension.e-schedule .e-vertical-view .e-date-header-wrap table col,
.schedule-cell-dimension.e-schedule .e-vertical-view .e-content-wrap table col {
    width: 250px;
}
.o-name { background: #0265a0;color: #fff;padding: 3px 5px;font-weight: 700;}
.office-room span {display: block;background: transparent!important;border-top: solid 1px #d2d2d2;font-weight: 700;}
</style>