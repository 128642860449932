<template>
	<div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-10">
		<div class="box">
			<div class="box-body">
				<div class="pageheading">
					<h2 class="font-bold mt-0">Access Management <a href="#" data-bs-toggle="modal" data-bs-target="#permissiondocument" title="Reference of Knowledge Base" v-on:click.prevent="reset()"> <i class="fa fa-question-circle fs-20"></i></a></h2>
				</div>
				<div class="table-responsive">
					<data-table dataType="permissionList">
						<template v-slot="slotProps">
							<!-- slot for table data -->
							<td> {{ slotProps.item.module_name }} </td>
							<td> {{ slotProps.item.name }} </td>
							<td>{{ slotProps.item.description }}</td>
						</template>
					</data-table>
				</div>
				<!--document model start-->
				<div id="permissiondocument" class="modal fade" tabindex="-1" role="dialog"
					aria-labelledby="myModalLabel" aria-hidden="true">
					<div class="modal-dialog modal-md">
						<div class="modal-content">
						<div class="modal-header">
							<h3 class="modal-title fw-900 mt-0">Reference of Knowledge Base</h3>
						</div>
						<div class="modal-body overflow-auto" style="max-height: 350px;">
							<table class="table table-bordered">
								<thead>
									<tr>
									<th scope="col">No.</th>
									<th scope="col">Knowledge Base Articles</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>1</td>
										<td> <a :href="path+'what-is-access-management'" target="_blank">What Is Access Management?</a></td>
									</tr>
									<tr>
										<td>2</td>
										<td> <a :href="path+'how-to-view-list-of-all-the-permissions'" target="_blank">How To View List Of All The Permissions?</a></td>
									</tr>
								</tbody>
							</table>
							<Search :key="searchKey"></Search>
						</div>
						<div class="modal-footer">
							<button type="button" @click="" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
						</div>
						</div>
					</div>
				</div>
				<!--document model end-->
			</div>
		</div>
	</div>
</template>

<script>

	import dataTable from "@/components/base/DataTable.vue";
	import Search from "@/components/Search.vue";

	export default {
		name: 'Permission',
		components: { dataTable, Search },
		data() {
			return {
				path: process.env.VUE_APP_KB_DOCUMENT,
				searchKey:0,
			}
		},
		methods: {
			reset() {
				this.searchKey = this.searchKey + 1;
			},
		}
	}
</script>