<template>
  <div class="tab-pane active" id="OfficeAddLink" role="tabpanel">
    <div class="patiboxdetail">
      <div class="authform-modal box">
        <div class="requiredinfo">
          <div class="pageheading">
            <h2 class="font-bold mt-0">Office Information {{this.$store.state.office.office_name}} <a href="#" data-bs-toggle="modal" data-bs-target="#addofficedocument" title="Reference of Knowledge Base" v-on:click.prevent="reset()"> <i class="fa fa-question-circle fs-20"></i></a></h2>
          </div>

          <form novalidate>
            <input-field
              v-on:keyup="getAbbrName($event)"
              title="Office Name"
              id="office_name"
              v-model="v$.form.office_name.$model"
              :errors="v$.form.office_name.$errors"
              :isRequired="true"
              labelClass="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label text-sm-end"
              divClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-3"
            >
            </input-field>
             <div class="form-group row" v-if="this.$store.state.auth.is_lifestyle_specific_enable==1">
               <label class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 form-label mb-0 text-sm-end">Is LifeStyle Specific</label>
               <div class="col-6 col-sm-6 col-md-8 col-lg-5 col-xl-3">
                  <div class="controls">
                     <input id="is_iv_specific" type="checkbox" class="filled-in chk-col-info" v-model="form.is_lifestyle_specific">
                     <label for="is_iv_specific" class="m-0 text-start fs-16"></label>
                  </div>
               </div>
            </div>
            <input-field
              title="Office Abbreviation"
              v-model="form.office_abbr"
              labelClass="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label text-sm-end"
              divClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-3"
            >
            </input-field>


            <input-field
              title="Facility Name"
              v-model="form.facility_name"
              note="Leave it blank if same as office name (Used in CMS 1500 form box#32)"
              labelClass="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label text-sm-end"
              divClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-3"
            >
            </input-field>

            <provider
              title="Primary Provider"
              v-model="form.primary_provider_id"
              getDataURL="/json_dropdown/provider"
              :isRequired="false"
            ></provider>

            <provider
              title="Default Supervisor"
              v-model="form.primary_supervisor_id"
              getDataURL="/json_dropdown/supervisor"
              :isRequired="false"
            ></provider>
            <input-field
              title="Branch Name"
              v-model="form.branch_name"
              labelClass="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label text-sm-end"
              divClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-3"
            >
            </input-field>
            <text-area
              title="Address"
              id="address"
              v-model="v$.form.address.$model"
              :errors="v$.form.address.$errors"
              :isRequired="true"
              labelClass="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label text-sm-end"
              divClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-3"
            ></text-area>

            <div
              class="form-group row"
              :class="{ error: v$.form.country_id.$errors.length }"
            >
              <div class="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3">
                <label class="col-form-label text-sm-end"
                  >Country
                  <small class="text-danger asterisksign">*</small></label
                >
              </div>
              <div class="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-3">
                <Select2
                  v-model="v$.form.country_id.$model"
                  ref="selectCountry"
                  :errors="v$.form.country_id.$errors"
                  id="country_id"
                  placeholder="Select Country"
                  :options="countries"
                  selected="231"
                  @select="countryChange($event)"
                />

                <div class="error-msg">
                  <ul class="mb-0 p-0 fs-14">
                    <li
                      class="d-block"
                      v-for="(error, index) of v$.form.country_id.$errors"
                      :key="index"
                    >
                      {{ error.$message }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <input-field
              title="Zip Code"
              v-model="v$.form.zip_code.$model"
              :errors="v$.form.zip_code.$errors"
              :isRequired="false"
              labelClass="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label text-sm-end"
              divClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-3"
              @focusout="zipcode"
            >
            </input-field>

            <div
              class="form-group row"
              :class="{ error: v$.form.state_id.$errors.length }"
            >
              <div class="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3">
                <label class="col-form-label text-sm-end"
                  >State
                  <small class="text-danger asterisksign">*</small></label
                >
              </div>
              <div class="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-3">
                <Select2
                  v-model="v$.form.state_id.$model"
                  id="state_id"
                  :errors="v$.form.state_id.$errors"
                  placeholder="Select State"
                  :options="states"
                />
                <div class="error-msg">
                  <ul class="mb-0 p-0 fs-14">
                    <li
                      class="d-block"
                      v-for="(error, index) of v$.form.state_id.$errors"
                      :key="index"
                    >
                      {{ error.$message }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <input-field
              title="City"
              v-model="form.city"
              :isRequired="false"
              labelClass="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label text-sm-end"
              divClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-3"
            >
            </input-field>

            <input-field
              title="Office Phone"
              v-model="v$.form.office_phone.$model"
              :errors="v$.form.office_phone.$errors"
              :isRequired="false"
              mask="(000) 000-0000"
              labelClass="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label text-sm-end"
              divClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-3"
            >
            </input-field>

            <input-field
              title="Fax Number"
              v-model="v$.form.fax_number.$model"
              :errors="v$.form.fax_number.$errors"
              :isRequired="false"
              mask="(000) 000-0000"
              labelClass="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label text-sm-end"
              divClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-3"
            >
            </input-field>

            <check-box
              title=""
              v-model="form.is_online_available"
              label="Allow online scheduling in this office"
              divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3 mb-20"
            ></check-box>

          </form>
          <div class="row mb-10">
            <div
              class="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9 text-center"
            >
              <form-btn
                title="Save"
                btnCls="waves-effect waves-light btn btn-info mb-5 me-5"
                @click="save(false)"
              ></form-btn>
              <form-btn
                type="button"
                title="Cancel"
                btnCls="waves-effect waves-light btn btn-danger mb-5 me-5"
                @click="$router.push('/office/list')"
              ></form-btn>
              <form-btn
                title="Save & Next"
                btnCls="waves-effect waves-light btn btn-info mb-5 me-5"
                @click="save(true)"
              ></form-btn>
            </div>
          </div>
          <!--document model start-->
          <div id="addofficedocument" class="modal fade" tabindex="-1" role="dialog"
            aria-labelledby="myModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-md">
                <div class="modal-content">
                  <div class="modal-header">
                      <h3 class="modal-title fw-900 mt-0">Reference of Knowledge Base</h3>
                  </div>
                  <div class="modal-body overflow-auto" style="max-height: 350px;">
                      <table class="table table-bordered">
                        <thead>
                            <tr>
                              <th scope="col">No.</th>
                              <th scope="col">Knowledge Base Articles</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                              <td>1</td>
                              <td> <a :href="path+'how-to-create-an-office'" target="_blank">How To Create An Office?</a></td>
                            </tr>
                            <tr>
                              <td>2</td>
                              <td> <a :href="path+'how-to-edit-an-office'" target="_blank">How To Edit An Office?</a></td>
                            </tr>
                        </tbody>
                      </table>
                      <Search :key="searchKey"></Search>
                  </div>
                  <div class="modal-footer">
                      <button type="button" @click="" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
                  </div>
                </div>
            </div>
          </div>
        <!--document model end-->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import InputField from "@/components/base/formFields/InputField.vue";
import SelectBox from "@/components/base/formFields/selectBox.vue";
import TextArea from "@/components/base/formFields/textArea.vue";
import CheckBox from "@/components/base/formFields/checkBox.vue";
import FormBtn from "@/components/base/formFields/formBtn.vue";
import axios from "@/scripts/axios.js";
import Select2 from "./formFields/Select2.vue";
import Provider from "@/components/base/Provider.vue";
import useVuelidate from "@vuelidate/core";
import submitMixins from "@/scripts/mixins/submitMixins.js";
import Search from "@/components/Search.vue";

import {
  helpers,
  required,
  email,
  minLength,
  maxLength,
} from "@vuelidate/validators";

export default {
  mixins: [submitMixins],
  setup: () => {
    return { v$: useVuelidate() };
  },
  components: { InputField, SelectBox, Select2, Provider, TextArea, FormBtn,CheckBox, Search },
  computed: {
    form() {
      return this.$store.state.office.form;
    },
    vuelidateExternalResults() {
      return this.$store.state.office.vuelidateExternalResults;
    },
    validationRules() {
      return this.$store.state.office.validationRules;
    },
    title() {
      return this.$route.name === "OfficeEdit" ? "Edit Office" : "New Office";
    },
  },
  async created() {
    this.validationRules.form.country_id = {
      required: helpers.withMessage("Country is required", required),
    };
    this.validationRules.form.state_id = {
      required: helpers.withMessage("State is required", required),
    };
  },
  async beforeCreate() {
    let loadCountry = "";

    if (this.$route.name === "OfficeAdd") {
      this.$store.state.office.form = {};
      this.$store.state.office.office_name = '';
      this.$store.state.office.form.is_online_available = 0;
      Object.assign(
        this.$store.state.office.form,
        this.$store.state.office.defaultFormData
      );
      /*onload US country preference*/
      this.$store.state.office.form.country_id = 231;
      loadCountry = 231;
    } else if (this.$route.name === "OfficeEdit") {
      if (this.$store.state.office.form.id != this.$route.params.id) {
        // If edit, get data from server
        await this.$store.dispatch("getEditData", {
          dataType: "office", // pass the module name
          formType: "form", // pass the propery name
          id: this.$route.params.id,
        });
        // need to set office id since, getEdit data give id where update excepts provider_id
        this.$store.state.office.rooms_count = this.form.rooms_count;
        this.$store.state.office.office_name = "("+this.form.office_name+")";
        if (this.form.rooms_count == 0) {
          $("#ResourceTab").addClass("linkDisabled");
        }
        if(this.$store.state.office.form.is_online_available == "No"){
          this.$store.state.office.form.is_online_available = 0;  
        }
        else{
          this.$store.state.office.form.is_online_available = 1;   
        }
        if(this.form.is_lifestyle_specific == '1'){
          this.form.is_lifestyle_specific = true;
        }
        else{
          this.form.is_lifestyle_specific = false;  
        }
        this.form.office_id = this.form.id;
        this.$store.state.office.editID = this.form.id;
        loadCountry =
          this.form.country_id !== null || this.form.country_id !== ""
            ? this.form.country_id
            : 231;
      }
    }
    /*state load for specific country*/
    var $this = this;
    axios
      .post(
        "/json_autocomplete/" +
          this.$store.state.office.form.country_id +
          "/state"
      )
      .then(
        (response) =>
          ($this.states = response.data.data.map((state) => {
            return { id: state.id, text: state.name };
          }))
      );
  },
  data() {
    return {
      states: null,
      countries: null,
      primary_providers: null,
      saveAndNext: false,
      serverErrors: [],
      path: process.env.VUE_APP_KB_DOCUMENT,
      searchKey:0,
    };
  },
  validations() {
    return this.$store.state.office.validationRules;
  },
  mounted() {
    axios.get("/json_autocomplete/country").then(
      (response) =>
        (this.countries = response.data.data.map((country) => {
          return { id: country.id, text: country.name };
        }))
    );
  },
  methods: {
    reset() {
      this.searchKey = this.searchKey + 1;
    },
    getAbbrName($event){
      var officeName = $event.target.value;
      
    },
    zipcode(){
      if(this.form.zip_code && this.form.zip_code.length > 3 ){
        axios.post("json_dropdown/state-city-auto-populations",{zip_code:this.form.zip_code})
        .then((response) => {
            this.form.city=response.data.data.City;
            this.form.state_id=response.data.data.id
        });
      }
      else{
        this.form.city= null;
        this.form.state_id=null;
      }
    },
    save(next) {
      if(this.$store.state.office.form.is_online_available == 0){
          this.$store.state.office.form.is_online_available = "No";  
        }
        else{
          this.$store.state.office.form.is_online_available = "Yes";   
        }

      next ? (this.saveAndNext = true) : (this.saveAndNext = false);
      if (this.$route.name === "OfficeEdit") {
        this.postForm("/office/update");
      } else {
        this.postForm("/office/store");
      }
    },
    countryChange({ id, text }) {
      axios.post("/json_autocomplete/" + id + "/state").then(
        (response) =>
          (this.states = response.data.data.map((state) => {
            return { id: state.id, text: state.name };
          }))
      );
    },
    formPostSuccess(data) {
      this.$store.state.office.form = data;
      if (this.saveAndNext) {
        var officeId =
          this.$route.name === "OfficeAdd"
            ? this.$store.state.office.form.id
            : this.$route.params.id;
        this.$router.push(`/office/${officeId}/billing`);
      } else {
        this.$router.push("/office/list");
      }
      let msg;
      this.moshaToast(
        `Record ${
          this.$route.name === "OfficeEdit" ? "updated" : "added"
        } successfully`,
        "success"
      );
    },
  },
};
</script>
