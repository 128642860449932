<template>
	<div class="row">
		<div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6" v-if="recent_coverage_data.eligibility_recent_checked_date!=null">
			<div class="mb-3">
				<p class="badge badge-success-light fs-16 me-2">Coverage Status: <span class="fw-600"> {{recent_coverage_data.eligibility_recent_status}}</span></p>
				<p class="d-inline-block mb-5 me-2 fs-16">Recently Checked On: <span class="fw-600">{{ localformatDate(recent_coverage_data.eligibility_recent_checked_date, "MMM D, YYYY h:mm A") }} </span></p>
				<a href="javascript:void(0);" @click="view_recent" class="waves-effect waves-light btn btn-info-light btn-xs fs-16">Click here to View Response <i class="fa fa-mail-forward fs-12"></i></a>
			</div>
		</div>
		<div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
			<select-box-static title="Insurance Type" divClass="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" labelClass="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" v-model="check_eligibility_type" noteClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-4" :option="insurance_type"></select-box-static>
			<div class="form-group">
				<label class="form-label">Eligibility Service</label>
				<div class="selectcontrols">
					<Autocomplete :max="30" @input="getservicelist" ref="eligibilityautocomplete" :results="service_list" @onSelect="serviceresultsvalue" :use-html-for-results ="true" class="form-control ms-0 autosearch-list" placeholder="Eligibility Service"></Autocomplete>
				</div>
				<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
				</div>
			</div>
			<div class="mb-10">
				<button type="submit" class="waves-effect waves-light btn me-1 btn-info mb-5" @click="save()"> Check Eligibility <img v-if="loader" src="images/loader.gif" class="ms-1"></button>
			</div>
			<div class="" v-if="error_message_display">
				<div class="text-danger word-break-all">{{error_message}}</div>
			</div>
		</div>
	</div>
	<hr>
	<h3 class="heading3 font-bold mb-15">Previous Log</h3>
	<div class="table-responsive">
		<table class="table table-hover fs-16">
			<thead>
				<tr class="text-center">
					<th>Ref. #</th>
					<th>Checked On</th>
					<th>Coverage Status</th>
					<th>Service Date</th>
				</tr>
			</thead>
			<slot v-if="eligibility_logs.length>0">
				<tbody>
					<tr class="text-center" v-for="(item, index) in eligibility_logs" :key="index">
						<td>{{index+1}}</td>
						<td>{{ localformatDate(item.created_at, "MMM D, YYYY h:mm A") }}</td>
						<td>{{item.response_status}}</td>
						<slot v-if="(item.appointment_date!='0000-00-00 00:00:00' && item.appointment_date!=null)">
							<td>{{ formatDate(item.appointment_date, "MMM D, YYYY h:mm A") }}</td>
						</slot>
						<slot v-else>
							<td>-</td>
						</slot>
					</tr>
				</tbody>
			</slot>
			<tbody v-if="eligibility_logs.length==0">
				<tr>
					<td colspan="9" class="text-center"> No data available</td>
				</tr>
			</tbody>
		</table> 
	</div>
</template>

<script>
import SelectBoxStatic from "@/components/base/formFields/SelectBoxStatic.vue";
import FormBtn from "@/components/base/formFields/formBtn.vue";
import Autocomplete from 'vue3-autocomplete';
import axios from "@/scripts/axios.js";
import moment from "moment";

export default {
	props: {
		patient_id: null,
		appointment_id: null,
	},
	data() {
		return {
			insurance_type:[
			  { value: "primary", title: "Primary" },
			  { value: "secondary", title: "Secondary" },
			],
			service_list: [],
			check_eligibility_type:"primary",
			service_code:30,
			eligibility_logs:[],
			recent_coverage_data:{
				eligibility_recent_checked_date:null,
				eligibility_recent_response:null,
			},  
			loader:false, 
			error_message_display:false,
			error_message:null,
		}
	},
	components: {
		FormBtn,    
		Autocomplete,
		SelectBoxStatic,
	},
	async beforeCreate() {
		this.insurance_type=[
			{ value: "primary", title: "Primary" },
			{ value: "secondary", title: "Secondary" },
		];
		this.service_list=[];
		this.check_eligibility_type="primary";
		this.service_code=30;
		this.eligibility_logs=[];
		this.recent_coverage_data={
			eligibility_recent_checked_date:null,
			eligibility_recent_response:null,
		};
		this.loader=false;
		this.error_message_display=false;
		this.error_message=null;
  	},
	mounted() {
		this.$refs.eligibilityautocomplete.searchText = "Plan Coverage and General Benefits";
		this.getData();
	},
	methods:{
		view_recent(){
			this.get_file_link();
		},
		localformatDate(date, format) {
			return moment.utc(date).local().format(format);
		},
		formatDate(date, format) {
			return moment(date).format(format);
		},
		getservicelist(obj){
		  axios.get("/json_autocomplete/eligibility-service-list",{
				params: {term: obj}
			}).then(response => { 
			this.service_list = response.data.data.map((code) => {
				return { id: code.id, name: "<b>"+code.code+"</b><br>"+code.name,code:code.code,title:code.name }
			});
		  });
		},
		serviceresultsvalue(obj){
			this.$refs.eligibilityautocomplete.searchText = obj.title;
			this.service_code = obj.code;
		},
		save(){ 
			this.loader = true;
			axios.post(`/patient/${this.patient_id}/insuranceinfo/check-eligibility`,{
				type: this.check_eligibility_type,service_type: this.service_code, appointment_id: this.appointment_id
			}).then(response=> { 
				this.getData();	
				this.get_file_link();
			}).catch(error=>{
				this.error_message=error.response.data.message;
				this.error_message_display=true;
				setTimeout(() => {
					this.error_message=null;
					this.error_message_display=false;
					this.loader = false;
				}, 5000);
			})
		},
		getData(){
			if(this.patient_id != null){
				axios.post(`/patient/${this.patient_id}/insuranceinfo/get-recent-eligibility-response`).then(response=> { 
					this.eligibility_logs = response.data.data.eligibility_logs;
					this.recent_coverage_data = response.data.data.recent_coverage_data;				
				});
			}
		},
		get_file_link(){
			axios.post(`/get-eligibility-file`,{patient_clinic_id: this.$store.state.auth.userData.clinic_id, patient_unqID: this.patient_id}).then(response=>{
				this.loader = false;
				window.open(response.data, '_blank').focus();
			})
		},
	}

}
</script>