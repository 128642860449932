import {
  helpers,
  required,
  email,
  minLength,
  maxLength,
  sameAs,
  numeric,
} from "@vuelidate/validators";

const Support = {
  namespaced: true,

  state: {
    vuelidateExternalResults: {
      form: {
        first_name:[],
        last_name:[],
        email:[],
        cell_phone:[],
        date_of_birth:[],
        check_time:[],
      },
    },
    validationRules: {
      form: {
        first_name: {
          required: helpers.withMessage("First Name is required", required),
          max: maxLength(35),
        }, 
        last_name:{
          required: helpers.withMessage("Last Name is required", required),
          max: maxLength(35),
        },
        email:{
          required: helpers.withMessage("Email address is required", required),
          email: helpers.withMessage("Email address is not valid", email),
        },
        cell_phone:{
          required: helpers.withMessage("Phone no. is required", required),
        },
        date_of_birth: {
          required: helpers.withMessage("DOB is required", required),
        },
        office_id: {
          required: helpers.withMessage("Office is required", required),
        },
        check_time: {
          required: helpers.withMessage("Time slot is required", required),
        }, 
      },
    },
  },
  getters: {
    getTableHeaders: (state) => state.header,
    getTableContents: (state) => state.list,
    getListURL: (state) => state.listUrl,
  },
  mutations: {},
  actions: {},
}

export default Support;
