const PaymentGatewayMigration = {
	namespaced: true,
  	state: {
    	listUrl: "/payment-gateway-migration/list",
		header: [
			{"chart_id":"Chart ID"},
			{"patinet_name":"Patient Name"},
			{"email":"Email"},
			{"email_status":"Email Status"},
			{"email_count":"Follow-up"},
			{"verify_status":"Authorization Status"},
			{"updated_at":"Authorized on/Last Email sent on"},
			{"":"Action"},
			{"":""},
		],
		pagination: {
			to: "",
			from: "",
			total: "",
			last_page: "",
			current_page: "",
			pageNavArr: [],
		},
		filter: {
			search_data:'',
			filter_data:'',
		},	
		list: [],	
  },
  getters: {
    getTableHeaders: (state) => state.header,
    getTableContents: (state) => state.list,
    getListURL: (state) => state.listUrl,
  },
  mutations: {},
  actions: {},
}

export default PaymentGatewayMigration;
