<template>
	<div class="content-wrapper">
		<div class="container-full">
			<section class="content">
				<div class="content-header">
					<h1 class="heading1 m-0">Availability Search <a href="#" data-bs-toggle="modal" data-bs-target="#availabilitydocument" title="Reference of Knowledge Base" v-on:click.prevent="reset()"> <i class="fa fa-question-circle fs-20"></i></a></h1>
				</div>
				<div class="row">
					<div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">
						<div class="tab-pane" id="category-1" role="tabpanel">
							<div class="box notescollap">
								<div class="box-header with-border">
									<a class="box-title" aria-controls="offices" aria-expanded="true" data-bs-toggle="collapse" href="#offices" data-parent="#accordion2">Offices</a>
									<a class="waves-effect waves-light btn btn-info float-end btn-xs" data-bs-toggle="modal" data-bs-target="#officesedit">Edit Selection</a>
								</div>
								<div class="panel-collapse collapse show" id="offices" aria-labelledby="question-1" role="tabpanel" data-bs-parent="#category-1">
									<div class="box-body">{{name_of_office}}</div>
								</div>
							</div>

							<div class="box notescollap">
								<div class="box-header with-border">
									<a class="box-title" aria-controls="doctors" aria-expanded="false" data-bs-toggle="collapse" href="#doctors" data-parent="#accordion2">Providers</a>
									<a class="waves-effect waves-light btn btn-info float-end btn-xs" data-bs-toggle="modal" data-bs-target="#doctorsedit">Edit Selection</a>
								</div>
								<div class="panel-collapse collapse show" id="doctors" aria-labelledby="question-2" role="tabpanel" data-bs-parent="#category-1">
									<div class="box-body">{{this.name_of_provider}}</div>
								</div>
							</div>
						</div>

						<div id="officesedit" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
							<div class="modal-dialog">
								<div class="modal-content permissmodal">
									<div class="modal-header">
										<h3 class="modal-title font-bold m-0">Select Offices</h3>
									</div>
									<div class="modal-body">
										<div class="lookup patients-search mb-10">
											<div class="selectcontrols">
												<Autocomplete 
												@input="getoffice" 
												ref="autocomplete" 
												:results="office" 
												@onSelect="officevalue" 
												class="form-control ms-0 autosearch-list col-12 w-p100" 
												placeholder="Search"></Autocomplete>
											</div>
										</div>
										<div class="box m-0">
											<div class="box-body p-0">
												<ul class="todo-list ui-sortable">
													<li v-for="(item, index) in office_name" :key="index">
														<span class="text-line">{{item}}</span>
														<div class="tools d-block" @click="removeoffice(index)">
															<i class="fa fa-trash-o"></i>
														</div>
													</li>
												</ul>
											</div>
										</div>
									</div>
									<div class="modal-footer">
										<button @click="saveoffice()" type="button" class="waves-effect waves-light btn btn-info">Save</button>
										<button type="button" class="waves-effect waves-light btn btn-danger" data-bs-dismiss="modal">Close</button>
									</div>
								</div>
							</div>
						</div>

						<div id="doctorsedit" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
							<div class="modal-dialog">
								<div class="modal-content permissmodal">
									<div class="modal-header">
										<h3 class="modal-title font-bold m-0">Select Providers</h3>
									</div>
									<div class="modal-body">
										<div class="lookup patients-search mb-10">
											<div class="selectcontrols">
												<Autocomplete 
												@input="getprovider" 
												ref="autocomplete" 
												:results="provider" 
												@onSelect="providervalue" 
												class="form-control ms-0 autosearch-list col-12 w-p100" 
												placeholder="Search"></Autocomplete>
											</div>
										</div>
										<div class="box m-0">
											<div class="box-body p-0">
												<ul class="todo-list ui-sortable">
													<li v-for="(item, index) in provider_name" :key="index">
														<span class="text-line">{{item}}</span>
														<div class="tools d-block" @click="removeprovider(index)">
															<i class="fa fa-trash-o"></i>
														</div>
													</li>
												</ul>
											</div>
										</div>
									</div>
									<div class="modal-footer">
										<button type="button" @click="saveprovider()" class="waves-effect waves-light btn btn-info">Save</button>
										<button type="button" class="waves-effect waves-light btn btn-danger" data-bs-dismiss="modal">Close</button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-9">
						<div class="box mb-10">
							<div class="box-body">
								<div class="row">
									<div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-3">
										<div class="bootstrap-timepicker">
											<div class="form-group availdate">
												<label class="form-label">Date</label>
												<ejs-daterangepicker id="daterangepicker" :value="date" :placeholder="waterMarkText" @change="getdate"></ejs-daterangepicker>
											</div>
										</div>
									</div>
									<div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-5">
										<div class="row">
											<div class="col-6 col-sm-4 col-md-4 col-lg-4 col-xl-4">
												<div class="form-group availdate">
													<label for="example-time-input" class="form-label">Duration</label>
													<ejs-numerictextbox format="###" id="Duration" ref="numeric_instance" :value="duration_value" :step="step" :min="min" :max="max"></ejs-numerictextbox>
												</div>
											</div>
											<div class="col-6 col-sm-4 col-md-4 col-lg-4 col-xl-4">
												<div class="bootstrap-timepicker">
													<div class="form-group availdate">
														<label class="form-label">Time From</label>
														
														<ejs-timepicker id="time_from"  :placeholder="waterMarkText" :value='time_from'  :step='interval'  :min="minTime" :max="maxTime" ></ejs-timepicker>
													</div>
												</div>
											</div>
											<div class="col-6 col-sm-4 col-md-4 col-lg-4 col-xl-4">
												<div class="form-group availdate">
													<label for="example-time-input" class="form-label">Time To</label>
													<ejs-timepicker id="time_to" :placeholder="waterMarkText" :value='time_to'   :step='interval' ></ejs-timepicker>
												</div>
											</div>
										</div>
									</div>
									<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
										<div class="row">
											<div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
												<div class="form-group">
													<label class="form-label">Visit Profile</label>
													<select class="form-control" id="id_appointment_profile" @click="appointmentprofile()">
														<option value="" selected="selected" disabled="disabled">Select Profile</option>
														<option v-for="item in appointment_profile" :value="item.id">{{item.name}}</option>
													</select>
												</div>
											</div>
											<div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
												<div class="form-group">
													<label class="form-label">Exam Room</label>
													<select class="form-control" id="room_id" v-model="this.room_id">
														<option value="" selected="selected" disabled="disabled">Select Room</option>
														<option v-for="item in room" :value="item.id">{{item.name}}</option>
													</select>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="d-none mb-5" id="week_day">
									<div class="form-group mb-0 row">
										<label class="form-label">Select Weekdays</label>
										<div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-1">
											<div class="form-check">
												<input name="week_name" type="checkbox" id="md_checkbox_7" class="filled-in chk-col-info Weekdays" value="Sunday" >
												<label for="md_checkbox_7" class="text-start">Sun</label>
											</div>
										</div>
										<div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-1">
											<div class="form-check">
												<input name="week_name" type="checkbox" id="md_checkbox_8" class="filled-in chk-col-info Weekdays" value="Monday" >
												<label for="md_checkbox_8" class="text-start">Mon</label>
											</div>
										</div>
										<div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-1">
											<div class="form-check">
												<input name="week_name" type="checkbox" id="tuesday-check" class="filled-in chk-col-info Weekdays" value="Tuesday" >
												<label for="tuesday-check" class="text-start">Tue</label>
											</div>
										</div>
										<div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-1">
											<div class="form-check">
												<input name="week_name" type="checkbox" id="md_checkbox_5" class="filled-in chk-col-info Weekdays" value="Wednesday" >
												<label for="md_checkbox_5" class="text-start">Wed</label>
											</div>
										</div>
										<div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-1">
											<div class="form-check">
												<input name="week_name" type="checkbox" id="thursday-check" class="filled-in chk-col-info Weekdays" value="Thursday" >
												<label for="thursday-check" class="text-start">Thu</label>
											</div>
										</div>
										<div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-1">
											<div class="form-check">
												<input name="week_name" type="checkbox" id="friday-check" class="filled-in chk-col-info Weekdays" value="Friday" >
												<label for="friday-check" class="text-start">Fri</label>
											</div>
										</div>
										<div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-1">
											<div class="form-check">
												<input name="week_name" type="checkbox" id="saturday-check" class="filled-in chk-col-info Weekdays" value="Saturday" >
												<label for="saturday-check" class="text-start">Sat</label>
											</div>
										</div>
									</div>
								</div>
								<button @click="search()" type="button" class="waves-effect waves-light btn btn-info me-1">Search</button>
								<button type="button" class="waves-effect waves-light btn btn-info-light me-1">Clear</button>
							</div>
						</div>
						<div class="box mt-10">
							<div class="box-body">
								<div class="table-responsive">
									<table id="example1" class="table table-condensed table-hover">
										<thead>
											<tr>
												<th>Provider</th>
												<th>Date</th>
												<th>Time</th>
												<th>Exam Room</th>
												<th>Office</th>
												<th>Actions</th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="item in appointment_list">
												<td>{{item.doctor}}</td>
												<td>{{this.$filters.displayDateTimeLocal(item.date,'',false,"ddd, MM-DD-YYYY",true)}}</td>
												<td>{{item.time}}</td>
												<td>{{item.room_name}}</td>
												<td>{{item.office_name}}</td>
												<td>
													<router-link
									                  to="/patient-chart/new/schedule-appointment"
									                  class="waves-effect waves-light btn btn-info btn-xs"
									                  @click="datetimeset(item.date,item.time)"
									                  >Schedule Appointment</router-link>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<!--document model start-->
			<div id="availabilitydocument" class="modal fade" tabindex="-1" role="dialog"
			aria-labelledby="myModalLabel" aria-hidden="true">
			<div class="modal-dialog modal-md">
			<div class="modal-content">
				<div class="modal-header">
				<h3 class="modal-title fw-900 mt-0">Reference of Knowledge Base</h3>
				</div>
				<div class="modal-body overflow-auto" style="max-height: 350px;">
				<table class="table table-bordered">
				<thead>
				<tr>
					<th scope="col">No.</th>
					<th scope="col">Knowledge Base Articles</th>
				</tr>
				</thead>
				<tbody>
				<tr>
					<td>1</td>
					<td> <a :href="path+'how-to-use-availability-search-in-pureehr'" target="_blank">How To Use Availability Search In PureEHR?</a></td>
				</tr>
				</tbody>
				</table>
				<Search :key="searchKey"></Search>
				</div>
				<div class="modal-footer">
				<button type="button" @click="" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
				</div>
			</div>
			</div>
			</div>
    		<!--document model end-->
		</div>
	</div>
</template>


<script>
	import { mapGetters, mapActions } from "vuex";
	import Autocomplete from 'vue3-autocomplete'
	import axios from "@/scripts/axios.js";
	import { TimePickerPlugin } from "@syncfusion/ej2-vue-calendars";
	import Search from "@/components/Search.vue";
	export default {
		components: { 
			Autocomplete,Search,TimePickerPlugin
		},
		mounted(){
			axios.get("/json_dropdown/appointment-profile")
			.then(response => { this.appointment_profile = response.data.data });
			this.getuser();
		},
		methods: {
			reset() {
				this.searchKey = this.searchKey + 1;
			},
			datetimeset(date,time){
				this.$store.state.scheduleappointment.date = date; 
				this.$store.state.scheduleappointment.time = time;
			},
			getuser(){
				axios.post("/json_dropdown/get-user-office",{user_type:this.user.user_type})
				.then(response => {
					var thisObj = this;
					$.each(response.data.data, function( index, value ) {
						thisObj.office_name.push(value.name);
						thisObj.office_id.push(value.id);
						$('#officesedit').modal('toggle'); 
					});
				});
			},
			getdate(){
				$('#week_day').addClass('d-block');
				$('#week_day').removeClass('d-none');

			},
			appointmentprofile(){
				var id = $('#id_appointment_profile').val();
				axios.get("appointment-profile/edit/"+id)
				.then(response => { this.duration_value = response.data.data.default_duration });
			},
			getoffice (obj) {
				if(obj.length > 1){
					this.$refs.autocomplete.$el.classList.add('selectloader');
					axios.get("/json_autocomplete/office",{params: {term: obj}})
					.then(response => { 
						this.office = response.data.data 
						this.$refs.autocomplete.$el.classList.remove('selectloader');
					});
				}
				if(obj.length == 0){
					this.careteam = {}
				}
			},
			officevalue(obj){
				this.$emit('clicked', obj)
				this.$refs.autocomplete.searchText = '';
				this.office_name.push(obj.name);
				this.office_id.push(obj.id);   
			},
			removeoffice(index){
				this. office_name.splice(index, 1)
				this. office_id.splice(index, 1)
			},
			saveoffice(){
				axios.post("/get-user-provider",{user_type:this.user.user_type})
				.then(response => {
					var thisObj = this;
					$.each(response.data.data, function( index, value ) {
						thisObj.provider_name.push(value.name);
						thisObj.provider_id.push(value.id);
					});
					$('#officesedit').modal('hide'); 
				});
				this.name_of_office = this.office_name.join(', ');
				axios.post("/json_dropdown/room",{office_id: this.office_id.toString()})
				.then(response => { 
					this.room = response.data.data 
				});
				$('#officesedit').modal('toggle');
				$('#doctorsedit').modal('toggle');
			},
			getprovider(obj){
				if(obj.length > 1){
					this.$refs.autocomplete.$el.classList.add('selectloader');
					axios.get("/json_autocomplete/provider",{params: {term: obj}})
					.then(response => { 
						this.provider = response.data.data 
						this.$refs.autocomplete.$el.classList.remove('selectloader');
					});
				}
				if(obj.length == 0){
					this.careteam = {}
				}
			},
			providervalue(obj){
				this.$emit('clicked', obj)
				this.$refs.autocomplete.searchText = '';
				this.provider_name.push(obj.name);
				this.provider_id.push(obj.id);   
			},
			removeprovider(index){
				this. provider_name.splice(index, 1)
				this. provider_id.splice(index, 1)
			},
			saveprovider(){
				this.name_of_provider = this.provider_name.join(', ');
				axios.post("/appointment/availability-search",{
					office_id:this.office_id.toString(),
					provider_id:this.provider_id.toString(),
					from_time:this.time_from,
					to_time:this.time_to
				})
				.then(response => { this.appointment_list = response.data.data });
				$('#doctorsedit').modal('toggle'); 
			},
			search(){
				var daterange = $('#daterangepicker').val();
				var date = daterange.split(' - ');
				let duration = $('#Duration').val();
				this.time_from = $('#time_from').val();
				this.time_to = $('#time_to').val();
				var week_name=Array();
				$("input:checkbox[name=week_name]:checked").each(function(){
					week_name.push($(this).val());
				});
				var day_week = week_name.toString();
				console.log(day_week);
				axios.post("/appointment/availability-search",{
					office_id:this.office_id.toString(),
					provider_id:this.provider_id.toString(),
					from_time:this.time_from,
					to_time:this.time_to,
					duration:duration,
					room_id:this.room_id,
					from_date:date[0],
					to_date:date[1],
					week_day:day_week
				})
				.then(response => { this.appointment_list = response.data.data });
			},
			onClick: function(args) {
				var min=parseFloat(document.getElementById("min").value);
				var max=parseFloat(document.getElementById("max").value);
				var step=parseFloat(document.getElementById("step").value);
				console.log($('#Duration').val());
				this.min=min;
				this.max=max;
				this.step=step;
			}
		},
		data() 
		{
			return {
				office:[],
				office_name:[],
				office_id:[],
				name_of_office : '',
				provider:[],
				provider_name:[],
				provider_id:[],
				name_of_provider:'',
				waterMarkText: "Select week",
				min: 5,
				max: 60,
				step: 5,
				duration_value: '15',
				appointment_profile:{},
				room:{},
				waterMarkText: "Select Date Range",
				time_from: "10:00 AM",
				time_to: "12:00 PM",
				date:new Date(),
				interval: 60,
				minTime: new Date("8:00 AM"),
				maxTime:new Date("8:00 PM"),
				appointment_list:{},
				room_id:'',
				path: process.env.VUE_APP_KB_DOCUMENT,
				searchKey:0,
			}
		},
		computed: {
			...mapGetters("auth", ["user"]),
		},
		watch: {
			"user": function() {
			  this.getuser();
			},
		}
	}
</script>