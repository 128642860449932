import {
	helpers,
	required,
	email,
	minLength,
	maxLength,
	sameAs,
	numeric,
} from "@vuelidate/validators";

const Patientidentification = {
	namespaced: true,
	state: {
		listUrl: "/reports/patient-identification",
		header: [
			{ "chart_id": "Chart ID" },
			{ "patinet_name": "Patient Name" },
			{ "office_phone": "Office Phone" },
			{ "cell_phone": "Cell Phone" },
			{ "email": "Email" },
			{ "": "Action" },
			{ "": "" },
		],
		pagination: {
			to: "",
			from: "",
			total: "",
			last_page: "",
			current_page: "",
			pageNavArr: [],
		},
		list: [],
		form: {},
		vuelidateExternalResults: {
			form: {
				ssn_number: [],
			},
		},
		validationRules: {
			form: {
				ssn_number: {
					max: maxLength(9),
					numeric,
				},
			},
		},
	},
	getters: {
		getTableHeaders: (state) => state.header,
		getTableContents: (state) => state.list,
		getListURL: (state) => state.listUrl,
	},
	mutations: {},
	actions: {},
}

export default Patientidentification;
