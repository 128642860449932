<template>
  <div class="content-wrapper">
    <div class="container-full">
        <component
          :is="$store.state.CorporateComp"
          :key="Math.floor(Math.random() * 20)"
        ></component>
    </div>
  </div>
</template>

<script>
import AddCorporate from "@/components/Corporate/Home.vue";

export default {
  components: {AddCorporate},
  data() {
    return {};
  },
  watch: {},
  created() {
    this.$store.state.CorporateComp  = AddCorporate;
  },
  methods: {},
};
</script>
