<template>
  <div class="content-wrapper">
    <div class="container-full">
      <div class="content-header">
        <h1 class="heading1 mt-0">LifeStyle Service</h1>
      </div>
      <div class="row">
        <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-2">
            <life-style-header routefrom="CorporateLifeStyleService"></life-style-header>
        </div>
        <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-10">
            <div class="box">
              <div class="box-body">
                <form novalidate>
                  <div class="row">
                    <div class="col-12 col-sm-12 col-md-8 col-lg-8">
                      <select-box
                        v-model="v$.form.category_id.$model"
                        :errors="v$.form.category_id.$errors"
                        title="Select Category"
                        getDataURL="/IV_life_style/category/dropdownlist"
                        :isRequired="true"
                        divClass="col-12 col-sm-6 col-md-8 col-lg-6 col-xl-6"
                        labelClass="col-12 col-sm-6 col-md-4 col-lg-6 col-xl-6 col-form-label mb-0 text-sm-end"
                      ></select-box>
                    </div>
                    <div class="col-12 col-sm-12 col-md-4 col-lg-4">
                      <button @click="formshow" v-show="formdisplay == false" type="button" class="waves-effect waves-light btn btn-info"><i class="fa fa-plus"></i> Services</button>
                    </div>
                  </div>
                      
                    <div class="mb-20" v-show="formdisplay == true">
                      
                        <div class="bb-2 row justify-content-center" v-for="(item,index) in servicedata" :key="this.key">
                          <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                            <input-field divClass="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" labelClass="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-form-label mb-0" title="Service Name" v-model="servicedata[index].product_name" :isRequired="true" v-bind:id="'product_name_'+index"></input-field>
                          </div>
                          <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                            <input-field divClass="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" labelClass="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-form-label mb-0" title="Service Code" v-model="servicedata[index].product_code" v-bind:id="'product_code_'+index"></input-field>
                          </div>
                          <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                            <input-field divClass="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" labelClass="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-form-label mb-0" title="Service Price ($)" v-model="servicedata[index].product_price"  :isRequired="true" v-bind:id="'product_price_'+index"></input-field>
                          </div>
                        </div>
                    </div>
                      <div class="text-end" v-show="formdisplay == true">
                        <button @click="newreminder(servicedata.length)" type="button" class="waves-effect waves-light btn btn-info btn-sm"><i class="fa fa-plus fs-14"></i> New Service</button>
                    </div>
                </form>
                  <div class="text-center" v-show="formdisplay == true">
                    <form-btn title="Save Services" btnCls="btn-info" @click="save(false)"></form-btn>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import InputField from "@/components/base/formFields/InputField.vue";
import FormBtn from "@/components/base/formFields/formBtn.vue";
import useVuelidate from "@vuelidate/core";
import submitMixins from "@/scripts/mixins/submitMixins.js";
import SelectBox from "@/components/base/formFields/selectBox.vue";
import LifeStyleHeader from "@/components/Lifestyle/LifeStyleHeader.vue";
import axios from "@/scripts/axios.js";

export default {
  mixins: [submitMixins],
  setup: () => {
    return { v$: useVuelidate() };
  },
  computed: {
    form() {
      return this.$store.state.CorporateLifeStyleService.form;
    },
    vuelidateExternalResults() {
      return this.$store.state.CorporateLifeStyleService.vuelidateExternalResults;
    },
    validationRules() {
      return this.$store.state.CorporateLifeStyleService.validationRules;
    },
    title() {
      return this.$route.name === "CorporateLifeStyleServiceEdit" ? "Edit Lifestyle Service" : "New Lifestyle Service";
    },  
  },
  components: {
    InputField,FormBtn,SelectBox,LifeStyleHeader
  },
  data() {
    return {
      formdisplay:false,
      key :'0',
      servicedata: [],
    }
  },
  validations() {
    return this.$store.state.CorporateLifeStyleService.validationRules;
  },
  async beforeCreate(){
    if (this.$route.name === "CorporateLifeStyleServiceAdd") {
      this.$store.state.CorporateLifeStyleService.form = {};
      Object.assign(
        this.$store.state.CorporateLifeStyleService.form,
        this.$store.state.CorporateLifeStyleService.defaultFormData
      );
    }else{
      // If edit, get data from server
        await this.$store.dispatch("getEditData", {
          dataType: "CorporateLifeStyleService", // pass the module name
          formType: "form", // pass the propery name
          id: this.$route.params.id,
        });
    }
  },
  mounted(){
  },
  methods:{
    save(){
       this.$store.state.CorporateLifeStyleService.form.servicedata=this.servicedata;
       console.log(this.$store.state.CorporateLifeStyleService.form);
      if (this.$route.name === "CorporateLifeStyleServiceEdit") {
        this.postForm("/IV_life_style/service/update", "CorporateLifeStyleService", true, "json");
      } else {
        this.postForm("/IV_life_style/service/store", "CorporateLifeStyleService", true, "json");
      }
    },
    formPostSuccess(data) {
      // console.log(data);
      this.$store.state.CorporateLifeStyleService.form = data;
      this.$router.push("/corporate/life-style-service");
      this.moshaToast("Records added successfully","success");
    },
    formshow(){
			this.formdisplay = true;
			this.servicedata.push({
				product_name:'',
				product_code:'',
				product_price:'',
			});
    },
    newreminder(value){
			if(this.servicedata == ''){
				this.servicedata.push({
					product_name:'',
				  product_code:'',
				  product_price:'',
				});
			}
			else{
				var index = value - 1;
				var data = this.servicedata[index];
				if(data.product_name == ''){
					$("#product_name_"+index).addClass('form-group row error');
				}
				else if(data.product_code == ''){    			
					$("#product_code_"+index).addClass('form-group row error');	
				}
				else if(data.product_price == ''){
					$("#product_price_"+index).addClass('form-group row error');		
				}
				else{
          this.servicedata.push({
            product_name:'',
            product_code:'',
            product_price:'',
          });
				}
			}
		},
  }
}
</script>