<template>
  <div class="modal-body">
    <input-field
      title="First Name"
      v-model="v$.form.first_name.$model"
      :errors="v$.form.first_name.$errors"
      :isRequired="true"
      labelClass="col-form-label col-12 col-sm-3 col-md-3 col-lg-4 text-sm-end mb-0"
      divClass="col-12 col-sm-9 col-md-9 col-lg-8"
    >
    </input-field>

    <input-field
      title="Middle Name"
      v-model="form.middle_name"
      :errors="form.middle_name"
      labelClass="col-form-label col-12 col-sm-3 col-md-3 col-lg-4 text-sm-end mb-0"
      divClass="col-12 col-sm-9 col-md-9 col-lg-8"
    >
    </input-field>

    <input-field
      title="Last Name"
      v-model="v$.form.last_name.$model"
      :errors="v$.form.last_name.$errors"
      :isRequired="true"
      labelClass="col-form-label col-12 col-sm-3 col-md-3 col-lg-4 text-sm-end mb-0"
      divClass="col-12 col-sm-9 col-md-9 col-lg-8"
    >
    </input-field>

    <select-box-static
      title="Salutation"
      v-model="form.salutation"
      :option="salutation"
      labelClass="col-form-label col-12 col-sm-3 col-md-3 col-lg-4 text-sm-end mb-0"
      divClass="col-12 col-sm-9 col-md-9 col-lg-8"
    ></select-box-static>

    <input-field
      title="Organization"
      v-model="v$.form.organization_name.$model"
      :errors="v$.form.organization_name.$errors"
      :isRequired="true"
      labelClass="col-form-label col-12 col-sm-3 col-md-3 col-lg-4 text-sm-end mb-0"
      divClass="col-12 col-sm-9 col-md-9 col-lg-8"
    >
    </input-field>

    <input-field
      title="Direct Email Address"
      v-model="v$.form.direct_email_address.$model"
      :errors="v$.form.direct_email_address.$errors"
      labelClass="col-form-label col-12 col-sm-3 col-md-3 col-lg-4 text-sm-end mb-0"
      divClass="col-12 col-sm-9 col-md-9 col-lg-8"
    >
    </input-field>

    <input-field
      title="Phone Number"
      v-model="v$.form.phone_number.$model"
      :errors="v$.form.phone_number.$errors"
      labelClass="col-form-label col-12 col-sm-3 col-md-3 col-lg-4 text-sm-end mb-0"
      divClass="col-12 col-sm-9 col-md-9 col-lg-8"
      mask="(000) 000-0000"
    >
    </input-field>

    <input-field
      title="Fax Number"
      v-model="v$.form.fax_number.$model"
      :errors="v$.form.fax_number.$errors"
      :isRequired="true"
      labelClass="col-form-label col-12 col-sm-3 col-md-3 col-lg-4 text-sm-end mb-0"
      divClass="col-12 col-sm-9 col-md-9 col-lg-8"
    >
    </input-field>

    <div class="form-group row">
      <div
        class="col-12 col-sm-3 col-md-3 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end"
      >
        <label>Country</label> <small class="text-danger asterisksign">*</small>
      </div>
      <div class="col-12 col-sm-9 col-md-8 col-lg-5 col-xl-8">
        <Select2
          v-model="form.country_id"
          ref="selectCountry"
          id="country_id"
          placeholder="Select Country"
          :options="countries"
          @select="countryChange($event)"
        />
      </div>
    </div>

    <div class="form-group row">
      <div
        class="form-label col-12 col-sm-3 col-md-3 col-lg-4 col-xl-4 text-sm-end"
      >
        <label>State</label> <small class="text-danger asterisksign">*</small>
      </div>
      <div class="col-12 col-sm-9 col-md-9 col-lg-8">
        <Select2
          v-model="form.state_id"
          id="state_id"
          placeholder="Select State"
          :options="states"
        />
      </div>
    </div>

    <input-field
      title="City"
      v-model="form.city"
      labelClass="col-form-label col-12 col-sm-3 col-md-3 col-lg-4 text-sm-end mb-0"
      divClass="col-12 col-sm-9 col-md-9 col-lg-8"
    >
    </input-field>

    <input-field
      title="Zip Code"
      v-model="form.zip_code"
      labelClass="col-form-label col-12 col-sm-3 col-md-3 col-lg-4 text-sm-end mb-0"
      divClass="col-12 col-sm-9 col-md-9 col-lg-8"
    >
    </input-field>

    <text-area
      title="Address"
      v-model="form.address"
      labelClass="col-form-label col-12 col-sm-3 col-md-3 col-lg-4 text-sm-end mb-0"
      divClass="col-12 col-sm-9 col-md-9 col-lg-8"
    >
    </text-area>

    <div class="form-group row">
      <label class="form-label col-12 col-sm-3 col-md-3 col-lg-4 col-xl-4 text-sm-end mb-0">Speciality <small class="text-danger asterisksign">*</small></label>
      <div class="col-12 col-sm-9 col-md-8 col-lg-5 col-xl-8">
        <Select2
          v-model="form.speciality_id"
          id="speciality_id"
          placeholder="Select Speciality"
          :options="speciality"
        />
      </div>
    </div>

    <input-field
      title="NPI Number"
      v-model="v$.form.npi_number.$model"
      :errors="v$.form.npi_number.$errors"
      :isRequired="true"
      labelClass="col-form-label col-12 col-sm-3 col-md-3 col-lg-4 text-sm-end mb-0"
      divClass="col-12 col-sm-9 col-md-9 col-lg-8"
    >
    </input-field>

    <input-field
      title="Provider Number"
      v-model="form.provider_number"
      labelClass="col-form-label col-12 col-sm-3 col-md-3 col-lg-4 text-sm-end mb-0"
      divClass="col-12 col-sm-9 col-md-9 col-lg-8"
    >
    </input-field>
  </div>
  <div class="modal-footer">
    <form-btn title="Save" btnCls="btn-info" @click="save(false)"></form-btn>
    <button
      type="button"
      class="waves-effect waves-light btn btn-danger"
      data-bs-dismiss="modal"
    >
      Cancel
    </button>
  </div>
</template>

<script>
import InputField from "@/components/base/formFields/InputField.vue";
import SelectBox from "@/components/base/formFields/selectBox.vue";
import TextArea from "@/components/base/formFields/textArea.vue";
import FormBtn from "@/components/base/formFields/formBtn.vue";
import StateSelectBox from "@/components/base/formFields/stateSelectBox.vue";
import SelectBoxStatic from "@/components/base/formFields/SelectBoxStatic.vue";
import Select2 from "@/components/base/formFields/select2.vue";

import useVuelidate from "@vuelidate/core";
import submitMixins from "@/scripts/mixins/submitMixins.js";
import axios from "@/scripts/axios.js";

import {
  helpers,
  required,
  email,
  minLength,
  maxLength,
  sameAs,
  numeric,
} from "@vuelidate/validators";

export default {
  mixins: [submitMixins],
  setup: () => {
    return { v$: useVuelidate() };
  },
  components: {
    InputField,
    SelectBox,
    TextArea,
    FormBtn,
    StateSelectBox,
    SelectBoxStatic,
    Select2,
  },
  computed: {
    form() {
      return this.$store.state.ReferralDoctor.form;
    },
    vuelidateExternalResults() {
      return this.$store.state.ReferralDoctor.vuelidateExternalResults;
    },
    validationRules() {
      return this.$store.state.ReferralDoctor.validationRules;
    },
  },
  mounted() {
    axios.get("/json_autocomplete/country").then(
      (response) =>
        (this.countries = response.data.data.map((country) => {
          return { id: country.id, text: country.name };
        }))
    );

    axios.get("/json_dropdown/speciality").then(
      (response) =>
        (this.speciality = response.data.data.map((specialitys) => {
          return { id: specialitys.id, text: specialitys.name };
        }))
    );
  },
  methods: {
    countryChange({ id, text }) {
      axios.post("/json_autocomplete/" + id + "/state").then(
        (response) =>
          (this.states = response.data.data.map((state) => {
            return { id: state.id, text: state.name };
          }))
      );
    },
    save(next) {
      if (this.$route.name === "PatientEditDemographics") {
        this.postForm(`/referral-contact/store`, "demographics");
      } else {
        this.postForm(`/referral-contact/store`, "demographics");
      }
    },
    formPostSuccess(data) {
      axios.get("/referral-contact/list").then(
        (response) =>
          (this.$store.state.demographics.referring_doctor = response.data.data.map(
            (doctor) => {
              return { id: doctor.id, text: doctor.first_name };
            }
          ))
      );
      $("#referringnewcontact").modal("toggle");
      this.$store.state.ReferralDoctor.form = {};
      this.$store.state.ReferralDoctor.vuelidateExternalResults = {};
      this.$store.state.ReferralDoctor.validationRules = {};
      Object.assign(
        this.$store.state.ReferralDoctor.form,
        this.$store.state.ReferralDoctor.vuelidateExternalResults,
        this.$store.state.ReferralDoctor.validationRules
      );
    },
  },
  data() {
    return {
      saveAndNext: false,
      referring_doctor: null,
      countries: [],
      states: [],
      speciality: [],
      salutation: [
        { value: "Dr.", title: "Dr." },
        { value: "Prof.", title: "Prof." },
        { value: "Prac.", title: "Prac." },
        { value: "D.O.", title: "D.O." },
      ],
    };
  },
  async beforeCreate() {
    let loadCountry = "";
    this.$store.state.ReferralDoctor.form.country_id = 231;
    loadCountry = 231;
    /*state load for specific country*/
    var $this = this;
    axios.post("/json_autocomplete/" + loadCountry + "/state").then(
      (response) =>
        ($this.states = response.data.data.map((state) => {
          return { id: state.id, text: state.name };
        }))
    );
  },
  validations() {
    return this.$store.state.ReferralDoctor.validationRules;
  },
};
</script>
<style>
.select2-container {
  width: 100% !important;
}
</style>
