<template>
  <div class="loginback">
    <div class="container-fluid">
      <div class="row align-items-center">
        <div class="col-12 col-sm-9 col-md-8 col-lg-6 col-xl-6 col-xxl-6 offset-sm-1">
          <div class="bg-white rounded10 p-35 pt-40 pb-50 my-20">
            <div class="text-start">
              <img src="/images/pureehr.svg" alt="PureEHR" title="PureEHR" class="authcompany_logo mb-10"/>
              <h2 class="mb-15 fw-900">Lifestyle Service Payment Authorization</h2>
              <p v-if="this.paymentdone == false && this.is_link_expired == false">Welcome {{this.patient ? patient.first_name+' '+patient.last_name : '' }},</p>
              <p v-if="this.paymentdone == false && this.is_link_expired == false">Please find your Billing Details as under and authorize your final payment for Vitamin IV Therapy.</p>
              <div class="bg-danger-light p-10 py-5 rounded5 fs-16 mb-15" role="alert" v-if="errorMessage">
                {{errorMessage}}
              </div>
              <div class="table-responsive" v-if="this.paymentdone == false && this.is_link_expired == false">
                    <table class="table table-hover table-bordered mb-0">
                        <thead>
                            <tr>
                                <td>Sr No.</td>
                                <td>Category</td>
                                <td>Service</td>
                                <td>Price</td>
                            </tr>
                        </thead>
                        <tbody v-if="customProcedure.length > 0">
                            <tr v-for="(item,index) in customProcedure" :key="index">
                                <td>{{ index+1 }}</td>
                                <td>{{ item.category_name }}</td>
                                <td>{{ item.code }}</td>
                                <td>{{ $filters.toUSD(item.price*item.qty) }}</td>
                            </tr>
                            <tr>
                                <td colspan="3"><b>Total Amount</b></td>
                                <td>{{ $filters.toUSD(payable_amount) }}</td>
                            </tr>
                            <tr>
                                <td colspan="3"><b>Deposit Amount</b></td>
                                <td>  {{ $filters.toUSD(deposit_amount) }}</td>
                            </tr>
                            <tr v-if="received_amount > 0">
                                <td colspan="3"><b>Received Amount</b></td>
                                <td>{{ $filters.toUSD(received_amount) }}</td>
                            </tr>
                            <tr v-if="this.wallet_amount > 0">
                                <td colspan="3"><b>Wallet Balance ( {{ $filters.toUSD(wallet_amount) }} )</b> <div class="controls">
                                  <input
                                    :checked="this.pay_with_wallet"
                                    v-model="this.pay_with_wallet"
                                    @change="useWalletCheck($event)"
                                    type="checkbox"
                                    id="checkbox_1"
                                    value="single"
                                    class="filled-in chk-col-info"
                                  />
                                  <label for="checkbox_1" class="m-0"
                                    >Use wallet balance ?</label>
                                </div></td>
                                <td v-if="this.wallet_pay > 0"> 
                                    Pay From Wallet- {{ $filters.toUSD(this.wallet_pay) }} <br> 
                                    Pay From Credit Card-  {{ $filters.toUSD(this.total_amount  - this.wallet_pay) }}
                                </td>
                            </tr>
                            <tr>
                                <td colspan="3"><b>Payable Amount</b></td>
                                <td><b>{{ $filters.toUSD( total_amount) }}</b></td>
                            </tr>
                            <tr>
                              <td colspan="3">
                                <div class="controls">
                                  <input
                                    :checked="this.pay_with_new_cc"
                                    v-model="this.pay_with_new_cc"
                                    type="checkbox"
                                    id="checkbox_2"
                                    value="single"
                                    class="filled-in chk-col-info"
                                  />
                                  <label for="checkbox_2" class="m-0"
                                    >Pay with new credit card ?</label>
                                </div>
                              </td>
                            </tr>
                            <tr v-show="this.pay_with_new_cc">
                                <td colspan="4">
                                  <div class="form-group">
                                    <div style="display: none" id="customStyles">
                                      body{margin:0;}
                                      iframe{height: 100px;}
                                      #payment-form {border: 1px solid rgb(212, 221, 230);max-height: 130px;width: 100%;padding: 20px 10px;border-radius: 4px;margin: 0px;background: rgb(245, 248, 250);}
                                      #payment-form {color: #2d3c58;}
                                      #payment-form .input-label {color: #2d3c58;border-top: 1px solid #d4dde6;font-size:13px;text-align: center !important;}
                                    </div>
                                    <div id="card" style="height: 130px;"></div>
                                    <div id="errorMessage" style="margin-bottom: 8px; color: #c0392b;"></div>
                                  </div>
                                </td>
                            </tr>
                           <!--  <tr>
                                <td colspan="3">
                                  <div class="controls">
                                    <input
                                      v-on:click="getconsentforms($event)"
                                      :checked="true"
                                      type="checkbox"
                                      id="checkbox_2"
                                      value="single"
                                      class="filled-in chk-col-info"
                                    />
                                    <label for="checkbox_2" class="m-0"
                                      >Pay From Wallet</label
                                    >
                                  </div>
                                </td>
                                <td>
                                    <div class="input-group">
                                        <span style="margin-top: 6px;"> {{ $filters.toUSD(wallet_amount) }} /</span>
                                        <input type="text" class="form-control" @blur="payFromWalletChange" placeholder="Pay Amount"/>
                                    </div>
                                </td>
                            </tr> -->
                        </tbody>
                    </table>
                </div>
            </div>
            <p class="waves-effect mt-5 pull-left" v-if="this.paymentdone == false && this.pay_with_new_cc == false && this.is_link_expired == false">
              Authorize using {{ first_credit_card ? first_credit_card.masked_pan+'/'+first_credit_card.rectanglehealth_cc_exp_date : ''}} credit card.
            </p>
             <button v-if="this.paymentdone == false && this.is_link_expired == false" type="button" :disabled="this.total_amount == 0" class="waves-effect waves-light btn btn btn-info me-1 mt-5 pull-right" @click="handleAuthorizePay">{{ this.total_amount == 0 ? 'Paid' : 'Authorize' }}</button>
             <p v-if="this.paymentdone == true && this.is_link_expired == false" class="text-center text-success" style="font-size: 35px;"><b> Thank You, </b><br><br><span> <b> Your payment has been processed.</b></span></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "@/scripts/axios.js";
import { mapGetters, mapActions } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required, minLength, helpers } from "@vuelidate/validators";
import $ from "jquery";
export default {
  name: "Login",
  setup() {
    return { v$: useVuelidate() };
  },
  components: {},
  data() {
    return {
      errorMessage: "",
      paymentdone: false,
      patient_name:'',
      is_production_env : process.env.VUE_APP_NODE_ENV == 'production',
      appointment_id : '',
      patient_id : '',
      clinic_id1 : '',
      appointment_number : '',
      appointmentData: [],
      serverError: '',
      patient: '',
      customProcedure: [],
      total_amount: 0,
      wallet_amount: 0,
      deposit_amount: 0,
      received_amount: 0,
      payable_amount: 0,
      wallet_pay: 0,
      publicKey : '',
      tokenpay : {},
      pay_with_new_cc : false,
      pay_with_wallet : false,
      tmp_token : '',
      first_credit_card : '',
      is_link_expired : false,
    };
  },
  computed: {
    ...mapGetters(["errors"]),
  },
  beforeCreate(){
    $('.tophead').html("");
    this.$store.commit("setErrors", {});
    this.$store.state.headerRequired = false;
    this.$store.state.footerRequired = false;
  },
  async created() {
    this.tokencheck();
  },
  mounted() {
    let bridgePayScript = document.createElement('script')
    bridgePayScript.setAttribute('src', process.env.VUE_APP_API_RECTANGLEHEALTH_TOKENPAY_URL)
    document.head.appendChild(bridgePayScript);
  },
  methods: {
    useWalletCheck(e) {
      if (this.pay_with_wallet) {
          this.wallet_pay = 0;
          if (this.wallet_amount >= this.total_amount) {
            this.wallet_pay = this.total_amount;
          } else {
            this.wallet_pay = this.wallet_amount;
          }
      } else {
        this.wallet_pay = 0;
      }
    },
    initializeToken : function() {
      this.tokenpay = {};
      if (this.publicKey) {
        this.tokenpay = TokenPay(this.publicKey);
        this.tokenpay.initialize({
          dataElement: '#card',
          errorElement: '#errorMessage',
          amountElement: 'amount',
          useACH: false,
          //if displaying all 4 fields then useStyles=false, disableZip=false, disableCvv=false
          //if displaying 3 out of 4 fields then useStyles=false, and set disableZip or disableCvv equal to true
          //if displaying 2 out of 4 fields then useStyles=true, disableZip=true, disableCvv=true
          useStyles: true,
          disableZip: true,
          disableCvv: false
        });
      }
     },
     payFromWalletChange() {
        //console.log("call inside");
     },
     tokencheck() {
        axios
        .post("/token-check-lifestyle-payment", { token: this.$route.params.token })
        .then((response) => {
            this.patient = response.data.data.patient;
            this.first_credit_card = response.data.data.first_credit_card;
            this.appointment_id = response.data.data.appointment_id;
            this.clinic_id1 = response.data.data.clinic_id;
            this.patient_id = response.data.data.patient_id;
            this.customProcedure = response.data.data.customProcedure;
            this.wallet_amount = response.data.data.wallet_amount;
            this.deposit_amount = response.data.data.deposit_amount;
            this.total_amount = response.data.data.total_amount;
            this.received_amount = parseFloat(response.data.data.received_amount);
            this.payable_amount = parseFloat(response.data.data.payable_amount);
            this.publicKey = response.data.data.PUBLICKEY;
            this.is_link_expired = false;
            setTimeout(() => {
                this.initializeToken();
            },1500);
        }).catch((error) => {
            if (error.response.status == 410) {
              this.is_link_expired = true;
              this.errorMessage = error.response.data.message;
            } else {
              this.is_link_expired = false;
                this.errorMessage = error.response.data.message;
            }
        });
      },
      handlePaymentCapture() {
        this.$store.state.loader = true;
        axios
          .post("/authorized-lifestyle-payment", { token: this.$route.params.token, patient_id : this.patient_id, appointment_id : this.appointment_id, clinic_id1 : this.clinic_id1, tmp_token : this.tmp_token, wallet_pay : this.wallet_pay, deposit_amount : this.deposit_amount, pay_with_wallet : this.pay_with_wallet  })
          .then((response) => {
              this.$store.state.loader = false;
              this.paymentdone = true;
          }).catch((error) => {
              this.$store.state.loader = false;
              this.errorMessage = error.response.data.message;
              this.tokencheck();
          });
      },
      handleAuthorizePay() {
          this.$store.state.loader = true;
          var vm = this;
          if (this.pay_with_new_cc == true) {
            this.tokenpay.createToken(function (result) {
              vm.tmp_token = result.token;
              vm.handlePaymentCapture();
            }, function (result) {
              vm.$store.state.loader = false;
            });
          } else {
            vm.handlePaymentCapture();
          }
      }
    }
};
</script>
