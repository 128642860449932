const CustomBillingStatus = {
	namespaced: true,
  	state: {
    	listUrl: "/billingstatus/list",
		header: [
			{"id":"#Sr No."},
			{"name":"Name"},
			{"description":"Description"},			
			{"":"Action"},
		],
		pagination: {
			to: "",
			from: "",
			total: "",
			last_page: "",
			current_page: "",
			pageNavArr: [],
		},	
		list: [],	
    },
    getters: {
        getTableHeaders: (state) => state.header,
        getTableContents: (state) => state.list,
        getListURL: (state) => state.listUrl,
    },
    mutations: {},
    actions: {},
};

const CustomBillingStatusArchive = {
	namespaced: true,
  	state: {
    	listUrl: "/billingstatus/list/archived",
		header: [
			{"id":"#Sr No."},
			{"name":"Name"},
			{"description":"Description"},			
			{"":"Action"},
		],
		pagination: {
			to: "",
			from: "",
			total: "",
			last_page: "",
			current_page: "",
			pageNavArr: [],
		},	
		list: [],	
    },
    getters: {
        getTableHeaders: (state) => state.header,
        getTableContents: (state) => state.list,
        getListURL: (state) => state.listUrl,
    },
    mutations: {},
    actions: {},
};

export {CustomBillingStatus,CustomBillingStatusArchive};
