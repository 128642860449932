import {
    helpers,
    required,
    email,
    minLength,
    maxLength,
    numeric,
} from "@vuelidate/validators";

const office = {
    namespaced: true,
    state: {
        rooms_count: '0',
        office_name:'',
        editID: "",
        listUrl: "/office/list",
        getEditUrl: "/office/:id/edit",
        tableData: {
            header: [
                "Office Name",
                "Provider Name",
                "City",
                "Address",
                "Office Phone",
                "Exam Rooms",
                // "Online Availabilty",
                "Actions",
            ],
        },
        defaultFormData: {
            office_name: "",
            office_abbr: "",
            facility_name: "",
            place_of_service: "",
            primary_provider_id: "",
            country_id: "",
            state_id: "",
            is_lifestyle_specific:'0',
            city: "",
            zip_code: "",
            office_phone: "",
            fax_number: "",
        },     
        form: {},
        vuelidateExternalResults:{
            form: {
                office_name: [],
                facility_name: [],
                primary_provider_id: [],
                city: [],
                zip_code: [],
                office_phone: [],
                fax_number: [],
                address: [],
                country_id: [],
                state_id: [],
            }
        },
        validationRules: {
            form: {
                office_name: {
                    required: helpers.withMessage("Office Name is required", required),
                },
                office_phone: {
                    min: minLength(10),
                    max: maxLength(15),
                },
                fax_number: {
                    min: minLength(10),
                    max: maxLength(15),
                },
                zip_code: {
                    numeric,
                },
                address: {
                    required: helpers.withMessage("Address is required", required),
                },
                country_id: {
                    required: helpers.withMessage("Country is required", required),
                },
                state_id: {
                    required: helpers.withMessage("State is required", required),
                },
            }
        },
        list: [],
    },
    getters: {
        getTableHeaders: (state) => state.header,
        getTableContents: (state) => state.list,
        getListURL: (state) => state.listUrl,
    },
    mutations: {},
    actions: {},
};

const Archivedoffice = {
    namespaced: true,
    state: {
        rooms_count: '0',
        office_name:'',
        editID: "",
        listUrl: "/office/archivedList",
        tableData: {
            header: [
                "Office Name",
                "Provider Name",
                "City",
                "Address",
                "Office Phone",
                "Exam Rooms",
                "Unarchived",
            ],
        },
        form: {},
        vuelidateExternalResults:{},
        validationRules: {},
        list: [],
    },
    getters: {
        getTableHeaders: (state) => state.header,
        getTableContents: (state) => state.list,
        getListURL: (state) => state.listUrl,
    },
    mutations: {},
    actions: {},
};

export {office,Archivedoffice};
