<template>
  <div class="content-wrapper">
    <div class="container-full"> 
        <div class="content-header">
            <div class="row justify-content-between">
                <div class="col-12 col-sm-5 col-md-5 col-lg-6">
                    <h1 class="heading2 mb-10">Custom Billing Status</h1>
                </div>
                <div class="col-12 col-sm-7 col-md-7 col-lg-6 text-sm-end">
                    <a href="#/custom/billing-status" class="waves-effect waves-light btn btn-info-light"> <i class="fa fa-arrow-left fs-16"></i> back </a>
                </div>
            </div>
        </div>       
        <div class="box">
            <div class="box-body">
                <div class="table-responsive">
                    <data-table-server dataType="CustomBillingStatusArchive" dataMethod="post" :key="key">
                        <template v-slot="slotProps">
                            <td>{{ slotProps.item.sr_no }}</td>
                            <td>{{slotProps.item.name}}</td>
                            <td>
                                <slot v-if="slotProps.item.description!=null">
                                    {{slotProps.item.description}}
                                </slot>
                                <slot v-else>
                                    -
                                </slot>
                            </td>
                            <td>
                                <button type="button"  data-on="Ready" data-off="Not Ready" @click="unarchive(slotProps.item.id)" :id="['status_' + slotProps.item.id]" class="btn btn-toggle rounded30-im"  v-bind:class="[slotProps.item.deleted_at == null ? 'active' : '']" data-bs-toggle="button" aria-pressed="true" autocomplete="off">
                                    <div class="handle rounded30-im"></div>
                                </button>
                            </td>
                        </template>
                    </data-table-server> 
                    
                </div>
            </div>
            
        </div>        
    </div>
  </div>
</template>

<script>
import Popper from "vue3-popper";
import axios from "@/scripts/axios.js";
import TextArea from "@/components/base/formFields/textArea.vue";
import InputField from "@/components/base/formFields/InputField.vue";
import submitMixins from "@/scripts/mixins/submitMixins.js";
import permissionMixins from "@/scripts/mixins/permission.js";
import DataTableServer from "@/components/base/DataTableServer.vue";
export default {
    data() {
        return {				
            list:[],   
            key:0,         
        }
    },
    mixins: [permissionMixins,submitMixins],
    components: { InputField,TextArea,Popper, DataTableServer },
    methods: {
        getList(){
            this.key++;
        },        
        unarchive(id){
            var confermation = confirm("Are you sure you want to unarchive this billing status ??");
            if(confermation){
                axios.post("/billingstatus/unarchive",{id:id})
                .then((response) => {
                    this.moshaToast("Billing status unarchive successfully.", "success");
                    this.getList();
                });
            }
        }
    },
    mounted() {},
}
</script>

<style>

</style>