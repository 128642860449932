import {
  helpers,
  required,
  email,
} from "@vuelidate/validators";

const patientLabOrdersEdit = {
    namespaced: true,
    state: {
        listUrl: "/patient/:patient_unique_id/lab-order/group-edit/:id2",
        tableData: {
          header: [
            "Ordering Doctor",
            "LOINC Code",
            "Description",
            "Date",
            "Sign off",
            "Status",
            "Result",
            "File",
            "Comments",
            "Actions",
          ],
        },
        form:{},
        validationRules: {
            form: 
            {
                appointment_id: {
                    required: helpers.withMessage("Appointment is required", required),
                },
                lab_email: {
                  email: helpers.withMessage("Email address is not valid", email),
                },
            },
        },
        vuelidateExternalResults: {
            form:
            {
                appointment_id: [],
            }
        },
        list: [],
    },
    getters: {
        getTableHeaders: (state) => state.header,
        getTableContents: (state) => state.list,
        getListURL: (state) => state.listUrl,
    },
    mutations: {},
    actions: {},
}

const patientLabOrders = {
  namespaced: true,
  state: {
    listUrl: "/patient/:patient_unique_id/lab-order/list",
    tableData: {
      header: [
        "Appointment",
        "Tests",
        "Date Of Test",
        "Status",
        "Action",
      ],
    },
    form:{},
    validationRules: {
        form: 
        {
            appointment_id: {
                required: helpers.withMessage("Appointment is required", required),
            },
            lab_email: {
              email: helpers.withMessage("Email address is not valid", email),
            },
        },
    },
    vuelidateExternalResults: {
        form:
        {
            appointment_id: [],
        }
    },
    list: [],
  },
  getters: {
    getTableHeaders: (state) => state.header,
    getTableContents: (state) => state.list,
    getListURL: (state) => state.listUrl,
  },
  mutations: {},
  actions: {},
}

export  { patientLabOrders, patientLabOrdersEdit };