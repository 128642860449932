import {
  helpers,
  required,
  email,
  minLength,
  maxLength,
  sameAs,
  numeric
} from "@vuelidate/validators";
const ReferralDoctor = {
  namespaced: true,

  state: {
    renderComponent:false,
    editID: "",
    listUrl: "/provider/list",
    getEditUrl: "/provider/:id/edit",
    tableData: {
      header: [
        "Name",
        "Direct Email Address",
        "Phone Number",
        "Fax Number",
        "Address",
        "Specialty",
        "NPI Number",
        "Provider Number",
        "Actions",
      ],
    },
    form: {},
    vuelidateExternalResults: {
      form: {
        first_name: [],
        last_name:[],
        organization_name:[],
        fax_number:[],
        direct_email_address:[],
        npi_number:[],
      },
    },
    validationRules: {
      form: {
        first_name: {
            required: helpers.withMessage("First Name is required", required),
            max: maxLength(50),
        },
        last_name: {
            required: helpers.withMessage("Last Name is required", required),
            max: maxLength(50),
        },
        organization_name: {
            required: helpers.withMessage("Organization Name is required", required),
            max: maxLength(50),
        },
        fax_number:{
            required: helpers.withMessage("Fax Number is required", required),
            min: minLength(12),
            max: maxLength(12),
        },
        npi_number:{
            required: helpers.withMessage("NPI Number is required", required), 
            min: maxLength(10),
        },
        phone_number:{
            numeric,
        },
        direct_email_address:{
          email: helpers.withMessage("Email address is not valid", email),
        }
      },
    },
    list: [],
  },
  getters: {
    getTableHeaders: (state) => state.header,

    getTableContents: (state) => state.list,
    getListURL: (state) => state.listUrl,
  },
  mutations: {},
  actions: {},
};

export default ReferralDoctor;
