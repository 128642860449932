<template>
  <div v-if="this.data != ''" class="table-responsive schedulingtime">
    <table class="table" v-if="data.length > 0">
      <thead>
        <tr class="text-center">
          <th>&nbsp;</th>
          <th colspan="2">Session 1</th>
          <th colspan="2">Session 2</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in data" :key="index">
          <td>
            <div class="form-check">
              <input
                :ref="'cb_' + index"
                type="checkbox"
                name="day_name"
                :value="item.week_day"
                class="filled-in chk-col-info dayCheckbox"
                :checked="checkIfSelected(index)"
              />
              <label
                for="md_checkbox_7"
                class="text-start"
                @click="checkBoxToggle(index)"
                >{{ item.week_day }}</label
              >
            </div>
          </td>

          <td style="width:280px;">
            <select
              class="form-control"
              v-model="data[index].session_one_start_selected"
              :ref="'session_one_start_' + index"
              @change="checkIfValid(index, 'session_one',data.length,item.week_day)"
            >
              <option value="null">Select time</option>
              <option
                v-for="(itemsub, index) in buildTimeDropdown(
                  item.session_one_start,
                  item.session_one_end,
                  item,
                  index,
                  'one',
                )"
                :key="index"
                :value="itemsub"
                selected = "item.session_one_start == itemsub"              
                >{{ $filters.timeFormat(itemsub)}}</option>
            </select>
          </td>
          <td style="width:280px;">
            <select
              class="form-control"
              v-model="data[index].session_one_end_selected"
              :ref="'session_one_end_' + index"
              @change="checkIfValid(index, 'session_one',data.length,item.week_day)"
            >
              <option value="null">Select time</option>
              <option
                v-for="(itemsub, index) in buildTimeDropdown(
                  item.session_one_start,
                  item.session_one_end,
                  item,
                  index,
                  'one',
                )"
                :key="index"
                :value="itemsub"
                >{{ $filters.timeFormat(itemsub) }}</option
              >
            </select>
          </td>
          <td style="width:280px;">
            <select
              class="form-control"
              v-model="data[index].session_two_start_selected"
              :ref="'session_two_start_' + index"
              @change="checkIfValid(index, 'session_two',data.length,item.week_day)"
            >
              <option value="null">Select time</option>
              <option
                v-for="(itemsub, index) in buildTimeDropdown(
                  item.session_two_start,
                  item.session_two_end,
                  item,
                  index,
                  'two',
                )"
                :key="index"
                :value="itemsub"
                >{{ $filters.timeFormat(itemsub) }}</option
              >
            </select>
          </td>
          <td style="width:280px;">
            <select
              class="form-control"
              v-model="data[index].session_two_end_selected"
              :ref="'session_two_end_' + index"
              @change="checkIfValid(index, 'session_two',data.length,item.week_day)"
            >
              <option value="null">Select time</option>
              <option
                v-for="(itemsub, index) in buildTimeDropdown(
                  item.session_two_start,
                  item.session_two_end,
                  item,
                  index,
                  'two',
                )"
                :key="index"
                :value="itemsub"
                >{{ $filters.timeFormat(itemsub) }}</option
              >
            </select>
          </td>
        </tr>
      </tbody>
    </table>
    <div
      class="bg-danger-light p-10 py-5 rounded5 fs-14 mb-10"
      v-if="error_msg"
    >
      {{ error_msg }}
    </div>
    <small
      v-if="!isValid"
      style="color: red; display: block"
      class="bg-danger-light p-10 py-5 rounded5 fs-14 mb-10"
    >
      {{ error }}
      Invalid time value selected for the highlighted items.
    </small>
    <div class="text-center">
      <button
        @click.prevent="saveSchedule(false)"
        type="submit"
        class="waves-effect waves-light btn btn-info me-1"
      >
        Save
      </button>
      <router-link
        v-if="this.usertype == 'staff' && permissioncheck(this.$store.state.permission.Manage_Accounts)"
        :to="{ name: 'StaffList' }"
        class="waves-effect waves-light btn me-1 btn-danger"
        >Cancel</router-link
      >
      <router-link
        v-if="this.usertype == 'staff' && !permissioncheck(this.$store.state.permission.Manage_Accounts)"
        :to="{ name: 'ProfileSetting' }"
        class="waves-effect waves-light btn me-1 btn-danger"
        >Cancel</router-link
      >
      <router-link
        v-else-if="this.usertype == 'provider' && permissioncheck(this.$store.state.permission.Settings)"
        :to="{ name: 'ProviderList' }"
        class="waves-effect waves-light btn me-1 btn-danger"
        >Cancel</router-link
      >
      <router-link
        v-else-if="this.usertype == 'provider' && !permissioncheck(this.$store.state.permission.Settings)"
        :to="{ name: 'ProfileSetting' }"
        class="waves-effect waves-light btn me-1 btn-danger"
        >Cancel</router-link
      >
      <button
        v-if="permissioncheck(this.$store.state.permission.Settings) || 
        permissioncheck(this.$store.state.permission.Manage_Accounts)"
        @click.prevent="saveSchedule(true)"
        type="submit"
        class="waves-effect waves-light btn btn-info me-1"
      >
        Save & Next
      </button>
    </div>
    <div id="listOfSlot" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content permissmodal">
          <div class="modal-header">
            <h4 class="modal-title font-bold m-0">List of scheduled time</h4>
            <button type="button" class="btn-close btn-xs" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <ul>
                <li v-for="(item,index) in slot_list" :key="index">
                  <div v-html="item"></div>
                </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <slot v-if="error_list != null">
      <br>
      <slot v-if="error_list.Sunday != undefined">
        <div class="bg-info-light p-10 py-5 rounded5 fs-14 mb-10" v-if="error_list.Sunday.length > 0">
          <b>Sunday</b>
          <ul>
              <li v-for="(item,index) in error_list.Sunday" :key="index">
                <div v-html="item"></div>
              </li>
          </ul>
        </div>
      </slot>
      <slot v-if="error_list.Monday != undefined">
        <div class="bg-info-light p-10 py-5 rounded5 fs-14 mb-10" v-if="error_list.Monday.length > 0">
          <b>Monday</b>
          <ul>
              <li v-for="(item,index) in error_list.Monday" :key="index">
                <div v-html="item"></div>
              </li>
          </ul>
        </div>
      </slot>
      <slot v-if="error_list.Tuesday != undefined">
        <div class="bg-info-light p-10 py-5 rounded5 fs-14 mb-10" v-if="error_list.Tuesday.length > 0">
          <b>Tuesday</b>
          <ul>
              <li v-for="(item,index) in error_list.Tuesday" :key="index">
                <div v-html="item"></div>
              </li>
          </ul>
        </div>
      </slot>
      <slot v-if="error_list.Wednesday != undefined">
        <div class="bg-info-light p-10 py-5 rounded5 fs-14 mb-10" v-if="error_list.Wednesday.length > 0">
          <b>Wednesday</b>
          <ul>
              <li v-for="(item,index) in error_list.Wednesday" :key="index">
                <div v-html="item"></div>
              </li>
          </ul>
        </div>
      </slot>
      <slot v-if="error_list.Thursday != undefined">
        <div class="bg-info-light p-10 py-5 rounded5 fs-14 mb-10" v-if="error_list.Thursday.length > 0">
          <b>Thursday</b>
          <ul>
              <li v-for="(item,index) in error_list.Thursday" :key="index">
                <div v-html="item"></div>
              </li>
          </ul>
        </div>
      </slot>
      <slot v-if="error_list.Friday != undefined">
        <div class="bg-info-light p-10 py-5 rounded5 fs-14 mb-10" v-if="error_list.Friday.length > 0">
          <b>Friday</b>
          <ul>
              <li v-for="(item,index) in error_list.Friday" :key="index">
                <div v-html="item"></div>
              </li>
          </ul>
        </div>
      </slot>
      <slot v-if="error_list.Saturday != undefined">
        <div class="bg-info-light p-10 py-5 rounded5 fs-14 mb-10" v-if="error_list.Saturday.length > 0">
          <b>Saturday</b>
          <ul>
              <li v-for="(item,index) in error_list.Saturday" :key="index">
                <div v-html="item"></div>
              </li>
          </ul>
        </div>
      </slot>
    </slot>
  </div>
</template>
<script>
import axios from "@/scripts/axios.js";
import moment from 'moment';
import submitMixins from "@/scripts/mixins/submitMixins.js";
import permissionMixins from "@/scripts/mixins/permission.js";

export default {
  mixins: [submitMixins, permissionMixins],
  props: ["selectedOffice", "providerID", "officeID", "usertype","officescheduling"],
  methods: {
    checkBoxToggle(index) {
      if (this.$refs[`cb_${index}`].checked) {
        this.data[index].session_one_start_selected = "null";
        this.data[index].session_one_end_selected = "null";
        this.data[index].session_two_start_selected = "null";
        this.data[index].session_two_end_selected = "null";
        this.$refs[`cb_${index}`].checked = false;
        this.$refs[`session_one_start_${index}`].setAttribute("disabled", "");
        this.$refs[`session_one_end_${index}`].setAttribute("disabled", "");
        this.$refs[`session_two_start_${index}`].setAttribute("disabled", "");
        this.$refs[`session_two_end_${index}`].setAttribute("disabled", "");
      } else {
        if (this.error == "You have not setup any schedule!") {
          this.isValid = true;
          this.error = "";
        }
        this.$refs[`cb_${index}`].checked = true;
        this.$refs[`session_one_start_${index}`].removeAttribute(
          "disabled",
          ""
        );
        this.$refs[`session_one_end_${index}`].removeAttribute("disabled", "");
        this.$refs[`session_two_start_${index}`].removeAttribute(
          "disabled",
          ""
        );
        this.$refs[`session_two_end_${index}`].removeAttribute("disabled", "");
      }
    },
    changevent(index){
      console.log(index);
    },
    checkIfSelected(index) {
      if (
        this.data[index].session_one_start_selected ||
        this.data[index].session_one_end_selected ||
        this.data[index].session_two_start_selected ||
        this.data[index].session_two_end_selected
      ) {
        return true;
      } else {
        return false;
      }
    },

    buildTimeDropdown(start, end , item , index ,session) {
      if(this.officescheduling ==true){
        if(session == 'one'){
          this.data[index].session_one_start_selected = start;
          this.data[index].session_one_end_selected = end;
        }
        if(session == 'two'){
          this.data[index].session_two_start_selected = start;
          this.data[index].session_two_end_selected = end;
        } 
      }
      
      if(item.uncheduling == 1){
        if(session == 'one' && item.week_id != null){
          if(item['session_one_start_selected'] == null){
              this.data[index].session_one_start_selected = start;
              this.data[index].session_one_end_selected = end;
          }
        }
        if(session == 'two' && item.week_id != null){
          if(item['session_two_start_selected'] == null){
            this.data[index].session_two_start_selected = start;
            this.data[index].session_two_end_selected = end;
          }
        }
      }
      
      
      if (start && end) {
        // type filed is used to hide last slot to show in start time dropdown
        let startTime = start.split(":");

        const startHH = startTime[0];
        const startMM = startTime[1];

        let endTime = end.split(":");

        const endHH = endTime[0];
        const endMM = endTime[1];

        let hh = startHH * 1;
        let mm = startMM * 1;

        let times = [];
        let isDone = false;
        let counter = 0;

        while (!isDone && counter < 100) {
          if (hh <= endHH) {
            if (hh == endHH && mm > endMM) {
              isDone = true;
              break;
            } else {
              times.push(`${hh}:${mm}`);
            }

            let min = mm + 15;
            if (min >= 60) {
              hh++;
              mm = 0;
            } else {
              mm = min;
            }
          } else {
            isDone = true;
          }
          counter++;
          // console.log(counter);
        }

        return times.map((item) => {
          let item1 = item.split(":");

          if (item1[0] < 10) {
            item1[0] = "0" + item1[0];
          }
          if (item1[1] < 10) {
            item1[1] = "0" + item1[1];
          }
          return item1.join(":");
        });
      }
    },

    setValidationResult(name, index, color) {
      this.$refs[`${name}_start_${index}`].style.border = `1px solid ${color}`;
      this.$refs[`${name}_end_${index}`].style.border = `1px solid ${color}`;
      this.isValid = color === "black" ? true : false;
    },

    checkIfValid(index, name ,len, week_day) {
      if(index == 0 && name == "session_one"){
        for (var i = 0; i < len; i++) {
          this.data[i].session_one_start_selected = this.data[index][`${name}_start_selected`];
          this.data[i].session_one_end_selected = this.data[index][`${name}_end_selected`]
        }
      }
      if(index == 0 && name == "session_two"){
        for (var i = 0; i < len; i++) {
          this.data[i].session_two_start_selected = this.data[index][`${name}_start_selected`];
          this.data[i].session_two_end_selected = this.data[index][`${name}_end_selected`]
        }
      }
      this.error = "";
      let start = this.data[index][`${name}_start_selected`];
      let end = this.data[index][`${name}_end_selected`];
      if (start == "null" && end == "null") {
        this.setValidationResult(name, index, "black");
        return;
      } else if (start == "null" || end == "null") {
        this.setValidationResult(name, index, "red");
        return;
      }
      if(start != undefined){
        var start1 = start.split(":");
        start1 = start1.join("");
      }else{
        var start1 = null;
      }
      if(end != undefined){
        var end1 = end.split(":");
        end1 = end1.join("");
      }else{
        var end1 = null;
      }

      if (start1 * 1 <= end1 * 1) {
        this.setValidationResult(name, index, "black");
      } else {
        this.setValidationResult(name, index, "red");
      }
      axios.post("check-user-schedule-slots",{usertype : this.usertype, staff_user_id: this.providerID, office_id: this.selectedOffice, week_day: week_day, start_time: start, end_time:end})
      .then((response) => {
          if(response.data.data.length > 0){
            this.slot_list = response.data.data;
            $("#listOfSlot").modal("show");
            this.data[index][`${name}_start_selected`] = "null";
            this.data[index][`${name}_end_selected`] = "null";
          }else{
            this.slot_list = [];
          }
			});
    },
    async getData(providerID, officeID) {
      if(providerID!='' && officeID!=''){
        let res = await axios.get(
          `/${this.usertype}/schedule/${officeID}/${providerID}`
        );
        this.data = [];
        this.data = res.data.data;
        if (this.data == "") {
          this.error_msg =
            "You have not scheduled your office time for this office. Please click here to schedule your time for this office.";
        }        
      }
    },

    async getOffice() {
      let res = await axios.get("/office/" + this.officeID + "/schedule");
      this.data = [];
      this.data = res.data.data;
    },

    formPostSuccess(result) {
      console.log(result)
      if (result.status === "success") {
        /*this.$store.commit("showToster", [
          "success",
          `Schedule saved successfuly`,
        ]);*/
      }
      this.moshaToast("Record updated successfully.", "success");
    },
    async saveSchedule(next) {
      next ? (this.saveAndNext = true) : (this.saveAndNext = false);
      this.error_list = null;
      if (this.isValid) {
        this.form = {
          office_id: this.selectedOffice,
          staff_user_id: this.providerID,
          usertype: this.usertype,
          week_ids: [],
          week_day: [],
        };
        var day_name = Array();
        $("input:checkbox[name=day_name]:checked").each(function() {
          day_name.push($(this).val());
        });
        this.form.week_day = day_name;
        this.data.forEach((item) => {
          if (
            (item.session_one_start_selected &&
              item.session_one_end_selected) ||
            (item.session_two_start_selected && item.session_two_end_selected)
          ) {
            this.form[item.week_day + "_session_one_start"] =
              item.session_one_start_selected;
            this.form[item.week_day + "_session_one_end"] =
              item.session_one_end_selected;
            this.form[item.week_day + "_session_two_start"] =
              item.session_two_start_selected;
            this.form[item.week_day + "_session_two_end"] =
              item.session_two_end_selected;

            //this.form.week_day.push(item.week_day);

            this.form.week_ids.push(item.week_id);
          }
        });

        if (this.form.week_day.length != 0) {
          // this.postForm("/user-schedule/save", "", false);
           axios.post("/user-schedule/save", this.form).then((response) => { 
              if (this.saveAndNext) {
                if (this.usertype == 'provider') {
                  this.$router.push(`/provider/edit/permission/${this.providerID}`);
                } else {
                  this.$router.push(`/staff/edit/permission/${this.providerID}`);
                }
              }
              this.moshaToast("Record updated successfully.", "success");
           }).catch((error) => {
             this.moshaToast(error.response.data.message,'danger');
             this.error_list = error.response.data.data;
           });
        } else {
          this.error = "You have not setup any schedule!";
          this.isValid = false;
        }
      } else {
        this.error = "Invalid time value selected for the highlighted items.";
        this.isValid = false;
      }
    },
    test(){
      console.log('test');
    }
  },

  data() {
    return {
      isValid: true,
      error_msg: "",
      data: [],
      error: "",
      slot_list: [],
      error_list:null,
      saveAndNext: false,
    };
  },
  created() {
    if (this.usertype == "office") {
      this.getOffice();
    }
  },
  computed: {},
  mounted(){
    this.getData(this.providerID,this.selectedOffice);
  },
  watch: {
    selectedOffice: function(newValue) {
      if (newValue) {
        this.getData(this.providerID, newValue);
      } else {
        this.data = [];
      }
    },
  },
};
</script>
