<template>
  <div class="wrapper">
    <div id="loader" v-show="$store.state.loader"></div>

    <!-- header and main menu -->
    <the-header v-if="isLogin"></the-header>

    <!-- Router view for main content layout -->
    
    <router-view :key="$route.fullPath" />
    <!-- <vue-progress-bar></vue-progress-bar> -->

    <!-- Footer -->
    <the-footer v-if="isLogin"></the-footer>
  </div>
</template>
<script>
import TheHeader from "@/components/base/TheHeader.vue";
import TheFooter from "@/components/base/TheFooter.vue";
import moment from "moment";
import {
  ScheduleComponent,
  SchedulePlugin,
  Day,
  Week,
  WorkWeek,
  Month,
  Agenda,
  DragAndDrop,
  Resize,
  ViewsDirective,
  ViewDirective,
  ResourcesDirective,
  ResourceDirective,
} from "@syncfusion/ej2-vue-schedule";
import { FileManagerPlugin, DetailsView, NavigationPane,Toolbar } from "@syncfusion/ej2-vue-filemanager";
import { RichTextEditor, Toolbar as ToolbarRich, Link, Image, HtmlEditor, QuickToolbar, Table,PasteCleanup } from "@syncfusion/ej2-vue-richtexteditor";


export default {
  name: "APP",
  components: { TheHeader, TheFooter },
  data() {
    return {
      dataTableLoaded: false,
      currentUser: false,
      
    };
  },
  computed: {
    getTosterData() {
      return this.$store.state.toster;
    },
    getLoaderState() {
      return this.$store.state.loader;
    },
    isLogin: function() {
      return localStorage.getItem("authToken") ? true : false;
    },
  },
  watch: {
    getTosterData(newValue) {
      this.$toast[newValue.type](
        `<h3>${newValue.type}</h3><br />${newValue.msg}`
      );
    },
    getLoaderState: (newValue) => {
      const loader = document.getElementById("loader");
      loader.style.opacity = "0.7";
      if (newValue) {
        loader.style.display = "inline-block";
      } else {
        loader.style.display = "none";
      }
    },
  },
  mounted() {},
  provide: {
    schedule: [Day, Week, WorkWeek, Month, Agenda, DragAndDrop, Resize],
    filemanager: [DetailsView, NavigationPane, Toolbar],
    richtexteditor:[ToolbarRich, Link, Image, HtmlEditor, QuickToolbar, Table, PasteCleanup]
  },
};
</script>

<style>
@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-calendars/styles/material.css";
@import "../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-vue-schedule/styles/material.css";
@import "../node_modules/@syncfusion/ej2-vue-calendars/styles/material.css";
@import "../node_modules/@syncfusion/ej2-icons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-layouts/styles/material.css";
@import "../node_modules/@syncfusion/ej2-grids/styles/material.css";
@import "../node_modules/@syncfusion/ej2-vue-filemanager/styles/material.css";
@import '../node_modules/@syncfusion/ej2-vue-kanban/styles/material.css';
@import "../node_modules/@syncfusion/ej2-vue-richtexteditor/styles/material.css";
@import "../node_modules/@syncfusion/ej2-lists/styles/material.css";
</style>
