<template>
  <div class="content-wrapper">
    <div class="container-full">
      <section class="content">
        <div class="row justify-content-between">
          <!-- sidebar -->
          <the-side-bar></the-side-bar>
          <!-- content -->
          <component :is="$store.state.AppointmentProfileHomeComp"></component>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import TheSideBar from "@/components/AppointmentProfiles/TheSidebar.vue";
import AppointmentProfile from "@/components/AppointmentProfiles/Home.vue";

export default {
    
    components: {TheSideBar,AppointmentProfile},
    data() {
        return {
        };
    },
};
</script>
