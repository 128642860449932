<template>
		<div class="tab-pane active" id="AddResourcesLink" role="tabpanel">
				<div class="patiboxdetail">
							<div class="">
									<div class="pageheading">
											<h2 class="heading2">Provider Billing {{this.$store.state.provider.provider_name}}</h2>
									</div>
									<form>
											<div class="form-group row align-items-center" :class="{ error: v$.form.federal_tax_type.$errors.length }">
													<label class="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label text-sm-end">Fedral Tax Type 
														<!-- <small class="text-danger asterisksign">*</small> -->
													</label>
													<div class="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-1">
															<input type="radio" id="SSN" name="federal_tax_type" value="SSN" @change="changetaxType('SSN')" class="filled-in chk-col-info" v-model="v$.form.federal_tax_type.$model" :errors="v$.form.federal_tax_type.$errors"/>
															<label for="SSN">SSN</label>
													</div>
													<div class="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-1">                              
															<input type="radio" v-model="form.federal_tax_type" id="EIN" name="federal_tax_type" value="EIN" @change="changetaxType('EIN')" class="filled-in chk-col-info"/>
																	<label for="EIN">EIN</label>
													</div>
													<div class="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-5">
														<small class="form-text text-muted">Note:- Used in CMS 1500 form box#25.</small>
													</div>
													<div class="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-3">
															<div class="invalid-feedback" style="display:block;" v-for="(error, index) of v$.form.federal_tax_type.$errors" :key="index">{{ error.$message }}</div>
													</div>
											</div>

											<input-field title="EIN Number" note="Used in CMS 1500 form box#25" divClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-3" labelClass="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label text-sm-end" v-model="v$.form.federal_tax_EIN.$model" :errors="v$.form.federal_tax_EIN.$errors" :isRequired="false"> </input-field>

											<input-field title="SSN Number" note="Used in CMS 1500 form box#25" divClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-3" labelClass="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label text-sm-end" v-model="v$.form.federal_tax_SSN.$model" :errors="v$.form.federal_tax_SSN.$errors" :isRequired="false"> </input-field>

											<hr>

											<input-field title="Taxonomy Number" divClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-3" labelClass="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label text-sm-end" v-model="form.taxonomy_number"> </input-field>
											
											<check-box divClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-3" labelClass="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label text-sm-end" title="Is Billing Enable" v-model="form.is_billing_enable" notes="If you check this box, provider billing details will be used to receive the insurance claim amount."></check-box>
											

											<input-field title="Billing name" note="Used in CMS 1500 form box#33" divClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-3" labelClass="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label text-sm-end" v-model="form.billing_provider_name"> </input-field>

											<input-field title="Billing NPI (Individual Provider NPI)" note="Used in CMS 1500 form box#33a" divClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-3" labelClass="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label text-sm-end" v-model="v$.form.billing_provider_npi.$model" notes="Note:- Keep it blank if billing NPI is same as office billing NPI" :errors="v$.form.billing_provider_npi.$errors"> </input-field>

											<input-field id="billing_provider_number" title="Billing Phone" v-model="form.billing_provider_number" mask="(000) 000-0000"> </input-field>

											<text-area title="Billing address" notes="Used in CMS 1500 form box#33" divClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-3" labelClass="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label text-sm-end" v-model="form.billing_provider_address"> </text-area>

											<input-field title="Billing zipcode" note="Used in CMS 1500 form box#33" @focusout="zipcode('patient_primary')" divClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-3" labelClass="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label text-sm-end" v-model="v$.form.billing_provider_zipcode.$model" :errors="v$.form.billing_provider_zipcode.$errors"> </input-field>

											<input-field title="Billing city" note="Used in CMS 1500 form box#33" divClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-3" labelClass="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label text-sm-end" v-model="form.billing_provider_city"> </input-field>   

											<div class="form-group row">
													<label class="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label text-sm-end">Billing State </label>
													<div class="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-3">
														<Select2
														v-model="form.billing_provider_state"
														id="billing_provider_state"
														placeholder="Select State"
														:options="states"
														/>            
													</div>
													<div class="col-12 col-sm-8 col-md-8 offset-sm-4 offset-lg-0 col-lg-4 col-xl-5" style=""><small class="form-text text-muted">Used in CMS 1500 form box#32a</small></div>
											</div>

											<div class="form-group row" >
													<label class="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label text-sm-end">Country</label>
													<div class="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-3">
															<Select2
																v-model="form.billing_provider_country"
																ref="selectCountry"
																id="billing_provider_country"
																placeholder="Select Country"
																:options="countries"
																selected="231"
																@select="countryChange($event)"
															/>
													</div>
													<div class="col-12 col-sm-8 col-md-8 offset-sm-4 offset-lg-0 col-lg-4 col-xl-5" style=""><small class="form-text text-muted">Used in CMS 1500 form box#32a</small></div>
											</div>
																														
									</form>
							</div>

							<div class="row mb-10">
									<div class="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9 text-center">
											<form-btn title="Save" btnCls="btn-info mb-5" @click="save()"></form-btn>   
											<form-btn type="button" v-if="permissioncheck(this.$store.state.permission.Settings)" title="Cancel" btnCls="waves-effect waves-light btn btn-danger mb-5 me-5"
											@click="$router.push('/provider/list')"></form-btn>
											<form-btn type="button" v-else title="Cancel" btnCls="waves-effect waves-light btn btn-danger mb-5 me-5"
											@click="$router.push('/profile/personal-info')"></form-btn>
											<form-btn title="Save & Next" btnCls="waves-effect waves-light btn btn-info mb-5 me-5"
											@click="save(true)"></form-btn>
									</div>
							</div>
				</div>
		</div>
</template>
<script>
import submitMixins from "@/scripts/mixins/submitMixins.js";
import axios from "@/scripts/axios.js";
import useVuelidate from "@vuelidate/core";
import InputField from "@/components/base/formFields/InputField.vue";
import FormBtn from "@/components/base/formFields/formBtn.vue";
import Select2 from "@/components/base/formFields/select2.vue";
import TextArea from "@/components/base/formFields/textArea.vue";
import CheckBox from "@/components/base/formFields/checkBox.vue";
import permissionMixins from "@/scripts/mixins/permission.js";
export default {
		mixins: [submitMixins, permissionMixins],
		setup: () => {
			return { v$: useVuelidate() };
		},
		data() {
				return {
						countries: null,
						states: null,
						office_or_provider_id: null,
						saveAndNext: false,
						EIN_true: false,
						SSN_true: false,
				};
		},
		validations() {
				return this.$store.state.providerBilling.validationRules;
		},
		components: { FormBtn, InputField, Select2, TextArea,CheckBox },
		mounted() {
				axios.get("/json_autocomplete/country").then(
					(response) =>
						(this.countries = response.data.data.map((country) => {
							return { id: country.id, text: country.name };
						}))
				);
				this.form.billing_provider_country = '231';
				var $this = this;
				axios
					.post(
						"/json_autocomplete/" +
							this.form.billing_provider_country +
							"/state"
					)
					.then(
						(response) =>
							($this.states = response.data.data.map((state) => {
								return { id: state.id, text: state.name };
							}))
					);
		},
		async beforeCreate() {
				this.office_or_provider_id = this.$route.params.id;
				this.$store.state.providerBilling.form = {};
				Object.assign(
						this.$store.state.providerBilling.form,
						this.$store.state.providerBilling.defaultFormData
				);

				
				axios.get("provider/"+this.office_or_provider_id+"/billing-details/edit")
						.then((response) => {
										if(response.data.data.id)
										{
												this.$store.state.providerBilling.form = response.data.data
												this.$store.state.providerBilling.form.billing_provider_country = String(this.$store.state.providerBilling.form.billing_provider_country);
												this.$store.state.providerBilling.form.billing_provider_state = String(this.$store.state.providerBilling.form.billing_provider_state);                        
										}
										if(this.form.is_billing_enable == 0){
											this.form.is_billing_enable = false;
											}
											else{
											this.form.is_billing_enable = true; 
											}
				});            
		},
		computed: {
				form() {
					return this.$store.state.providerBilling.form;
				},
				vuelidateExternalResults() {
					return this.$store.state.providerBilling.vuelidateExternalResults;
				},
				validationRules() {
					return this.$store.state.providerBilling.validationRules;
				},
				title() {
					return this.$route.name === "Provider Billing";
				},
		},
		methods: {
				changetaxType(val){

				},
				zipcode(){
					if(this.form.billing_provider_zipcode && this.form.billing_provider_zipcode.length > 3 ){
						axios.post("json_dropdown/state-city-auto-populations",{zip_code:this.form.billing_provider_zipcode})
						.then((response) => {
								this.form.billing_provider_city = response.data.data.City,
								this.form.billing_provider_state = response.data.data.id

								console.log(this.form.billing_provider_state);
						});
					}
					else{
						this.form.billing_provider_city= null;
						this.form.billing_provider_state=null;
					}
				},
				countryChange({ id, text }) {
						axios.post("/json_autocomplete/" + id + "/state").then(
						(response) =>
							(this.states = response.data.data.map((state) => {
								return { id: state.id, text: state.name };
							}))
						);
				},
				save(next){
						next ? (this.saveAndNext = true) : (this.saveAndNext = false);
						this.form.office_or_provider_id = this.$route.params.id;

						if(this.form.id!='' && typeof this.form.id!='undefined' && typeof this.form.id!=undefined && this.form.id!=null)
						{
								this.postForm(`/provider/${this.$route.params.id}/billing-details/update`,"",true);
						}
						else
						{
								this.postForm(`/provider/${this.$route.params.id}/billing-details/store`,"",true);
						}
				},
				formPostSuccess(data) {
						if (this.saveAndNext) {
								var providerId = this.$route.params.id;
								this.$router.push(`/provider/edit/scheduling/${this.form.office_or_provider_id}`);
						} else {
						}
						this.moshaToast(`Record saved successfully`,"success");
				},
		},
		// watch: {      
		// 	"form.federal_tax_type": function(newValue) {
		// 			if(newValue=='EIN'){
		// 				this.EIN_true=true
		// 				this.SSN_true=false
		// 			}else{
		// 				this.EIN_true=false
		// 				this.SSN_true=true
		// 			}
		// 	},
		// },
};
</script>
