const OngoingProblems = {
  namespaced: true,

  state: {
    tableData: {
      header: [
        "Problem",
        "ICD-10 Code",
        "Diagnosis Date",
        "Status",
        "Notes",
      ],
    },
    form: {},
    listUrl: "/patient/:patient_unique_id/problem/list/Active?req_type=clinicaldashboard",
    list: [],
  },
  getters: {
    getTableHeaders: (state) => state.header,

    getTableContents: (state) => state.list,
    getListURL: (state) => state.listUrl,
  },
  mutations: {},
  actions: {},
}

export default OngoingProblems;