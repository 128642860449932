import {
    helpers,
    required,    
  } from "@vuelidate/validators";

  const billingprofile = {
    namespaced: true,  
    state: {
        listUrl: "/billing-profile/list",
        header: [            
            {"name":"Profile"},
            {"icd10_codes":"ICD-10"},
            {"cpt_codes":"CPT"},
            {"package_code":"NDC"},
            {"custom_procedure_codes":"Custom"},
            {"billing_profile.updated_at":"Last Updated"},            
            {"":"Actions"},
        ],
        pagination: {
          to: "",
          from: "",
          total: "",
          last_page: "",
          current_page: "",
          pageNavArr: [],
        },    
        form: {             
        },
        defaultFormData: {
            id: '',
            name: '',
            icd10_id: [],
            icd10_code: [],
            icd10_description: [],
            cpt_code: [],
            cpt_code_id: [],
            cpt_modifier_a: [],
            cpt_modifier_b: [],
            cpt_modifier_c: [],
            cpt_modifier_d: [],
            cpt_icd10_pointer_a: [],
            cpt_icd10_pointer_b: [],
            cpt_icd10_pointer_c: [],
            cpt_icd10_pointer_d: [],
            cpt_qty: [],
            cpt_price: [],
            cpt_ndc_code: [],
            cpt_ndc_qty: [],
            cpt_ndc_units: [],
            custom_procedure_code_id: [],
            custom_procedure_code: [],
            custom_procedure_description: [],
            custom_procedure_qty: [],
            custom_procedure_price: [],
        },
        vuelidateExternalResults: {
            form: {
                name: [],          
            },
        },
        validationRules: {
            form: {
                name: {
                    required: helpers.withMessage("Profile name is required", required),
                },  
            },
        },
        list: [],
    },
    getters: {
        getTableHeaders: (state) => state.header,  
        getTableContents: (state) => state.list,
        getListURL: (state) => state.listUrl,
    },
    mutations: {},
    actions: {},
  };

    const billingArchived = {
      namespaced: true,

      state: {
        listUrl: "/billing-profile/archived",
        header: [            
          {"name":"Profile"},
          {"icd10_codes":"ICD-10"},
          {"cpt_codes":"CPT"},
          {"package_code":"NDC"},
          {"custom_procedure_codes":"Custom"},
          {"billing_profile.updated_at":"Last Updated"},            
        ],        
        list: [],
        pagination: {
          to: "",
          from: "",
          total: "",
          last_page: "",
          current_page: "",
          pageNavArr: [],
        }, 
      },
      getters: {
        getTableHeaders: (state) => state.header,
        getTableContents: (state) => state.list,
        getListURL: (state) => state.listUrl,
      },
      mutations: {},
      actions: {},
    };

    
  
  export {billingprofile,billingArchived};
  