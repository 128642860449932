import {
  helpers,
  required,
  email,
  minLength,
  maxLength,
  sameAs,
  url,
} from "@vuelidate/validators";


const generalSetting = {
  namespaced: true,

  state: {
    editID: "",
    
    defaultFormData: {
      office_access: '',
    },
    form: {
      office_access : '',
    },
    validationRules: {
      form: {
        office_access : {},
      }
    },
    list: [],
  },
  getters: {
    getTableHeaders: (state) => state.header,
    getTableContents: (state) => state.list,
    getListURL: (state) => state.listUrl,
  },
  mutations: {},
  actions: {},
};

export default generalSetting;
