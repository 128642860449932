<template>
    <div class="modal-body">
		<select-box-static
        	title="Select Age Group"
            v-model="v$.form.agegroup.$model"
            :errors="v$.form.agegroup.$errors"
            :option="agegroupoption"
            :isRequired="true"
            labelClass="col-12 col-sm-4 col-md-4 col-lg-5 col-form-label text-sm-end mb-0"
            divClass="col-12 col-sm-8 col-md-8 col-lg-7"
        ></select-box-static>
		
		<div class="form-group row" :class="{ error: v$.form.cvx_code.$errors.length }">
        <label class="col-12 col-sm-4 col-md-4 col-lg-5 col-form-label text-sm-end mb-0">CVX Code <small class="text-danger asterisksign">*</small></label>
        <div class="col-12 col-sm-8 col-md-8 col-lg-7">
            <div class="selectcontrols ">
                <Autocomplete
                    @input="cvx_code"
                    ref="cvx_code"
                    :results="cvx_codeList"
                    @onSelect="cvx_codevalue"
                    class="form-control"
                    placeholder="Search CVX Code"
                    :use-html-for-results ="true"
                ></Autocomplete>
            </div>
            <div class="error-msg" v-if="v$.form.cvx_code.$errors.length > 0">
                <ul class="mb-0 p-0 fs-14">
                    <li class="d-block" v-for="(error, index) of v$.form.cvx_code.$errors">
                        {{ error.$message }}
                    </li>
                </ul>
            </div>
        </div>
  	</div>

    	<div class="form-group row align-items-center">
		   <label class="col-12 col-sm-4 col-md-4 col-lg-5 col-form-label text-sm-end mb-0">CVX Short Name</label>
		   <div class="col-12 col-sm-8 col-md-8 col-lg-7">
		      <input type="text" class="form-control" placeholder="CVX Short Name" disabled="disabled" :value="form.short_name">
		   </div>
		</div>

		<div class="form-group row align-items-center">
		   <label class="col-12 col-sm-4 col-md-4 col-lg-5 col-form-label text-sm-end mb-0">CVX Full Name</label>
		   <div class="col-12 col-sm-8 col-md-8 col-lg-7">
		      <input type="text" class="form-control" placeholder="CVX Full Name" disabled="disabled" :value="form.full_name">
		   </div>
		</div>

		<input-field
      	title="Known name of Vaccine"
          v-model="v$.form.vaccine_name.$model"
          :errors="v$.form.vaccine_name.$errors"
          :isRequired="true"
          labelClass="col-12 col-sm-4 col-md-4 col-lg-5 col-form-label text-sm-end mb-0"
          divClass="col-12 col-sm-8 col-md-8 col-lg-7"
      >
      </input-field>

	</div>

	<div class="modal-footer">
    	<button @click="save(false)" type="button" class="waves-effect waves-light btn btn-info">{{ buttonlabel }}</button>
      <button @click="save(true)" type="button" class="waves-effect waves-light btn btn-info">Save and Add Another</button>
		  <button type="button" @click="close()" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
	</div>
</template>

<script>
import InputField from "@/components/base/formFields/InputField.vue";
import SelectBoxStatic from "@/components/base/formFields/SelectBoxStatic.vue";
import Autocomplete from "vue3-autocomplete";
import dataTable from "@/components/base/DataTable.vue";
import useVuelidate from "@vuelidate/core";
import submitMixins from "@/scripts/mixins/submitMixins.js";
import axios from "@/scripts/axios.js";

export default {
  mixins: [submitMixins],
  setup: () => {
    return { v$: useVuelidate() };
  },

  components: {
    dataTable,
    InputField,
    SelectBoxStatic,
    Autocomplete,
  },

  computed: {
    form() {
      return this.$store.state.Agecvx.form;
    },
    vuelidateExternalResults() {
      return this.$store.state.Agecvx.vuelidateExternalResults;
    },
    validationRules() {
      return this.$store.state.Agecvx.validationRules;
    },
  },

  data() {
    return {
      dataTypes: {
        dataType: "Agecvx",
        patient_id: this.$store.state.patientDetail.patient_id,
        patient_unique_id: this.$store.state.patientDetail.patient_unique_id,
        method: "get",
      },
      heddingtitle: "Add Age CVX Relation",
      buttonlabel: "Save",
      agegroupoption: [
        { value: "Birth", title: "Birth" },
        { value: "1 - 2 Months", title: "1 - 2 Months" },
        { value: "3 - 4 Months", title: "3 - 4 Months" },
        { value: "5 - 6 Months", title: "5 - 6 Months" },
        { value: "7 - 8 Months", title: "7 - 8 Months" },
        { value: "9 - 10 Months", title: "9 - 10 Months" },
        { value: "11 - 12 Months", title: "11 - 12 Months" },
        { value:"1 - 2 Years" ,title:"1 - 2 Years"},
        { value:"3 - 4 Years" ,title:"3 - 4 Years"},
        { value:"5 - 6 Years " ,title:"5 - 6 Years "},
        { value:"7 - 8 Years" ,title:"7 - 8 Years"},
        { value:"9 - 10 Years" ,title:"9 - 10 Years"},
        { value:"11 - 12 Years" ,title:"11 - 12 Years"},
        { value:"13 - 14 Years" ,title:"13 - 14 Years"},
        { value:"15 - 16 Years" ,title:"15 - 16 Years"},
        { value:"17 - 18 Years" ,title:"17 - 18 Years"},
        { value:"18- above" ,title:"18- above"},
      ],
      cvx_codeList:[],
      save_and_add_another: false,
    };
  },

  methods: {
    cvx_code(obj) {
		  if (obj.length > 1) {
        this.$refs.cvx_code.$el.classList.add("selectloader");
        axios.get("json_autocomplete/cvx-code", {params: { term: obj,data_element:'intervention_performed' },})
          .then((response) => {
            //this.cvx_codeList = response.data.data;
            this.cvx_codeList = response.data.data.map((problem) => {
              return { id: problem.id,
                name: "<b>"+problem.code+"</b><br>"+problem.description,
                code:problem.code,
                description:problem.description,
                vaccine_name:problem.vaccine_name
              }
            });
            this.$refs.cvx_code.$el.classList.remove("selectloader");
          });
      }   
      if (obj.length == 0) {
        this.cvx_codeList = []; 
        this.form.cvx_code = '';
        this.$refs.cvx_code.searchText = '';
      }
	  },
	  cvx_codevalue(obj){
      this.form.cvx_code = obj.code
      this.form.short_name = obj.description
      this.form.full_name = obj.vaccine_name
      this.$refs.cvx_code.searchText = obj.code+"-"+obj.description
      this.$emit("update:modelValue", obj.code);
    },

    save(addNew) {
      if (addNew) {
        this.save_and_add_another = true;
      } else {
        this.save_and_add_another = false;
      }
      this.postForm("age-cvx-relation/store", "Agecvx", true, "formdata");
    },

    formPostSuccess(data) {
      this.$store.state.Agecvx.form = {};
      this.v$.$reset();
      Object.assign(this.$store.state.Agecvx.form);
      this.$refs.cvx_code.searchText = '';
      this.$store.dispatch("getSimpleTableContents", this.dataTypes);      
      
      if (!this.save_and_add_another) {
        $(".modal").modal("hide");
        this.moshaToast("Record added successfully.", "success");
        this.$store.state.Agecvx.key = 1;
      }
    },

    close(){
      this.$store.state.Agecvx.form = {};
      this.v$.$reset();
      Object.assign(this.$store.state.Agecvx.form);
      this.$refs.cvx_code.searchText = '';
    }
  },
  validations() {
    return this.$store.state.Agecvx.validationRules;
  },
  /*async beforeCreate() {
    axios.get("json_dropdown/cvx-code")
    .then((response) => {
        this.cvx_codeList = response.data.data.map((cvx) => {
          return { id: cvx.id, text: cvx.name }
        });
    });
  }*/
};
</script>