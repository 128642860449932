<template>
	<div class="box">
	  <div class="box-body">
		<div class="pageheading">
			<h2 class="font-bold mt-0">{{ title }}</h2>
		</div>
		<form novalidate>
			<h3 class="font-bold">Organization Info</h3>
			<provider title="Primary Provider" id="primary_provider" v-model="v$.form.primary_provider.$model" :errors="v$.form.primary_provider.$errors" getDataURL="/json_dropdown/provider" :isRequired="true" labelClass="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end" divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3"></provider>

			

			<select-box title="Team Leader" id="team_leader" v-model="v$.form.team_leader.$model" :errors="v$.form.team_leader.$errors" getDataURL="/json_dropdown/team-leader" :isRequired="true"></select-box>
			<div class="form-group row">
				<label class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end">Demo REPS</label>
				<div class="col-12 col-sm-6 col-md-8 col-lg-3 col-lg-5 col-xl-3 d-flex">
					<Multiselect class="form-control" v-model="form.demo_reps" mode="tags" valueProp="id" :searchable="true" label="name" trackBy="name" :options="demo_resp" :canClear="false" placeholder="Demo REPS"/>
					<div class="waves-effect waves-light btn btn-info btn-xs ms-1 d-flex align-items-center" data-bs-toggle="modal" data-bs-target="#demoresp"><i class="fa fa-plus fs-14"></i></div>
				</div>
			</div>
			
			<hr>

			<h3 class="font-bold">Corporate Info</h3>
			
			<input-field title="Corporate Name" id="corporate_name" v-model="v$.form.corporate_name.$model" :errors="v$.form.corporate_name.$errors" :isRequired="true"></input-field>

			<text-area title="Address" id="address" v-model="v$.form.address.$model" :errors="v$.form.address.$errors" :isRequired="true"> </text-area>

			<input-field title="Zipcode" id="zip_code" v-model="v$.form.zip_code.$model" :errors="v$.form.zip_code.$errors" :isRequired="true" @focusout="zipcode"></input-field>

			<input-field title="City" id="city" v-model="v$.form.city.$model" :errors="v$.form.city.$errors" :isRequired="true"></input-field>

			<div class="form-group row">
				<label class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end">State <small class="text-danger asterisksign">*</small></label>
				<div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3">
					<Select2 v-model="v$.form.state_id.$model" :errors="v$.form.state_id.$errors" id="state_id" placeholder="Select state" :options="states"/>
					<div class="error-msg">
						<ul class="mb-0 p-0 fs-14">
							<li class="d-block" v-for="(error, index) of v$.form.state_id.$errors" :key="index">
								{{ error.$message }}
							</li>
						</ul>
					</div>
				</div>
			</div>

			<div class="form-group row">
				<label class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end">Country <small class="text-danger asterisksign">*</small></label>
				<div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3" :class="{ error: v$.form.country_id.$errors.length }">
					<Select2 v-model="v$.form.country_id.$model" :errors="v$.form.country_id.$errors" id="country_id" placeholder="Select Country" :options="countries" @select="countryChange($event)"/>
					<div class="error-msg">
						<ul class="mb-0 p-0 fs-14">
							<li class="d-block" v-for="(error, index) of v$.form.country_id.$errors" :key="index">
								{{ error.$message }}
							</li>
						</ul>
					</div>
				</div>
			</div>

			<input-field title="URL" v-model="form.corporate_url"></input-field>

			<hr>

			<h3 class="font-bold">Primary Contact Details</h3>
			<input-field title="First Name" id="main_contact_firstname" v-model="v$.form.main_contact_firstname.$model" :errors="v$.form.main_contact_firstname.$errors" :isRequired="true"></input-field>
			<input-field title="Last Name" id="main_contact_lastname" v-model="v$.form.main_contact_lastname.$model" :errors="v$.form.main_contact_lastname.$errors" :isRequired="true"></input-field>

			<input-field title="Email" v-model="v$.form.main_contact_email.$model" id="main_contact_email" :errors="v$.form.main_contact_email.$errors" :isRequired="true"></input-field>

			<input-field title="Phone" id="main_contact_phone" v-model="v$.form.main_contact_phone.$model" :errors="v$.form.main_contact_phone.$errors" :isRequired="true" mask="(000) 000-0000"></input-field>

			<hr>

			<h3 class="font-bold">Secondary Contact Details</h3>

			<input-field title="First Name" v-model="form.secondary_contact_firstname"></input-field>
			<input-field title="Last Name" v-model="form.secondary_contact_lastname"></input-field>

			<input-field title="Email" v-model="form.secondary_contact_email"></input-field>

			<input-field title="Phone" v-model="form.secondary_contact_phone" mask="(000) 000-0000"></input-field>

			<hr>

			<h3 class="font-bold">Referrals</h3>

			<div class="form-group row">
				<label class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end">Influencers</label>
				<div class="col-12 col-sm-6 col-md-8 col-lg-3 col-lg-5 col-xl-3 d-flex">
					<select class="form-select" v-model="form.influencers">
						<option value="" disabled="disabled">Select Influencers</option>
						<option v-for="item in influencers_list" :value="item.id">{{item.name}}</option>
					</select>
					<div class="waves-effect waves-light btn btn-info btn-xs ms-1 d-flex align-items-center" data-bs-toggle="modal" data-bs-target="#influencers"><i class="fa fa-plus fs-14"></i></div>
				</div>
			</div>

			<div class="form-group row">
				<label class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end">Affiliates</label>
				<div class="col-12 col-sm-6 col-md-8 col-lg-3 col-lg-5 col-xl-3 d-flex">
					<select class="form-select" v-model="form.affiliates">
						<option value="" disabled="disabled">Select Affiliates</option>
						<option v-for="item in affiliates_list" :value="item.id">{{item.name}}</option>
					</select>
					<div class="waves-effect waves-light btn btn-info btn-xs ms-1 d-flex align-items-center" data-bs-toggle="modal" data-bs-target="#affiliates"><i class="fa fa-plus fs-14"></i></div>
				</div>
			</div>

			<div class="form-group row">
				<label class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end">Patient</label>
				<div class="col-12 col-sm-6 col-md-8 col-lg-3 col-lg-5 col-xl-3">
					<div class="selectcontrols">
						<Autocomplete max="30" 
						@input="getlist" 
						ref="autocomplete" 
						:results="patientResult" 
						@onSelect="resultsvalue" 
						class="form-control ms-0 autosearch-list" 
						placeholder="Search Patient"
						:use-html-for-results ="true">
						</Autocomplete>
					</div>
				</div>
			</div>
			<hr>

			<h3 class="font-bold">Others</h3>
			<text-area title="Corporate Note" v-model="form.corporate_note"> </text-area>

			<div class="form-group row">
				<label class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end">Preferred Communication</label>
				<div class="col-12 col-sm-6 col-md-8 col-lg-3 col-lg-5 col-xl-3">
					<select class="form-select" v-model="form.preferred_communication">
						<option value="" selected="selected" disabled="disabled">Select Preferred Communication</option>
						<option value="Primary Email">Primary Email</option>
						<option value="Primary Phone">Primary Phone</option>
						<option v-if="form.secondary_contact_email" value="Secondary Email">Secondary Email</option>
						<option v-if="form.secondary_contact_phone" value="Secondary Phone">Secondary Phone</option>
					</select>
				</div>
			</div>

		</form>
		<div class="row">
			<div class="col-12 col-sm-12 col-md-12 col-lg-11 col-xl-9 text-center">
				<form-btn
				:title="btnname"
				btnCls="btn-info"
				@click="save(false)"
				></form-btn>
				<router-link :to="{ name: 'Corporatelist' }" class="waves-effect waves-light btn me-1 btn-danger">Cancel</router-link>
			</div>
		</div>
	</div>
</div>


<!--demo resp modal start-->
<div id="demoresp" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
	<div class="modal-dialog">
		<div class="modal-content">
			<div class="modal-header">
				<h3 class="modal-title font-bold m-0">New Demo REPS</h3>
			</div>
			<div class="modal-body">
				<div class="form-group row" :class="{ error: errorMessage.length }">
					<label class="col-form-label col-12 col-sm-3 col-md-3 col-lg-5 text-sm-end mb-0">Demo REPS Name <small class="text-danger asterisksign" >*</small></label>
					<div class="col-12 col-sm-9 col-md-9 col-lg-7">
						<div class="controls">
							<input id="Category Name" v-model="reps" type="text" class="form-control" placeholder="Demo REPS Name" autocomplete="off" @keyup="repschange">
						</div>
						<div class="error-msg">
							<ul class="mb-0 p-0 fs-14" v-if="errorMessage"> {{ errorMessage }}</ul>
						</div>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<button type="button" class="waves-effect waves-light btn btn-danger" data-bs-dismiss="modal">Cancel</button>
				<form-btn title="Save" btnCls="btn-info" @click="savedemoreps()"></form-btn>
			</div>
		</div>
	</div>
</div>
<!--demo resp modal end-->

<!--Influencers modal start-->
<div id="influencers" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="influencers" aria-hidden="true">
	<div class="modal-dialog">
		<div class="modal-content">
			<div class="modal-header">
				<h3 class="modal-title font-bold m-0">New Influencers</h3>
			</div>
			<div class="modal-body">
				<div class="form-group row" :class="{ error: influencerserrorMessage.length }">
					<label class="col-form-label col-12 col-sm-3 col-md-3 col-lg-5 text-sm-end mb-0">Influencers Name <small class="text-danger asterisksign" >*</small></label>
					<div class="col-12 col-sm-9 col-md-9 col-lg-7">
						<div class="controls">
							<input  v-model="influencers" type="text" class="form-control" placeholder="Influencers Name" autocomplete="off" @keyup="influencerschange">
						</div>
						<div class="error-msg">
							<ul class="mb-0 p-0 fs-14" v-if="influencerserrorMessage"> {{ influencerserrorMessage }}</ul>
						</div>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<button type="button" class="waves-effect waves-light btn btn-danger" data-bs-dismiss="modal">Cancel</button>
				<form-btn title="Save" btnCls="btn-info" @click="savedinfluencers()"></form-btn>
			</div>
		</div>
	</div>
</div>
<!--influencers modal end-->

<!--Influencers modal start-->
<div id="affiliates" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="affiliates" aria-hidden="true">
	<div class="modal-dialog">
		<div class="modal-content">
			<div class="modal-header">
				<h3 class="modal-title font-bold m-0">New Affiliates</h3>
			</div>
			<div class="modal-body">
				<div class="form-group row" :class="{ error: affiliateserrorMessage.length }">
					<label class="col-form-label col-12 col-sm-3 col-md-3 col-lg-5 text-sm-end mb-0">Affiliates Name <small class="text-danger asterisksign" >*</small></label>
					<div class="col-12 col-sm-9 col-md-9 col-lg-7">
						<div class="controls">
							<input  v-model="affiliates" type="text" class="form-control" placeholder="Affiliates Name" autocomplete="off" @keyup="affiliateschange">
						</div>
						<div class="error-msg">
							<ul class="mb-0 p-0 fs-14" v-if="affiliateserrorMessage"> {{ affiliateserrorMessage }}</ul>
						</div>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<button type="button" class="waves-effect waves-light btn btn-danger" data-bs-dismiss="modal">Cancel</button>
				<form-btn title="Save" btnCls="btn-info" @click="savedaffiliates()"></form-btn>
			</div>
		</div>
	</div>
</div>
<!--influencers modal end-->

</template>

<script>
	import InputField from "@/components/base/formFields/InputField.vue";
	import Select2 from "@/components/base/formFields/select2.vue";
	import TextArea from "@/components/base/formFields/textArea.vue";
	import FormBtn from "@/components/base/formFields/formBtn.vue";
	import Provider from "@/components/base/Provider.vue";
	import SelectBox from "@/components/base/formFields/selectBox.vue";
	import MultiSelectBox from "@/components/base/formFields/multiSelectBox.vue";
	import LifeStyleHeader from "@/components/Lifestyle/LifeStyleHeader.vue";
	import Autocomplete from "vue3-autocomplete";
	import Multiselect from "@vueform/multiselect";
	import useVuelidate from "@vuelidate/core";
	import submitMixins from "@/scripts/mixins/submitMixins.js";
	import axios from "@/scripts/axios.js";
//import $ from 'jquery';
export default {
	mixins: [submitMixins],
	setup: () => {
		return { v$: useVuelidate() };
	},
	computed: {
		title() {
			return this.$route.name === "Corporateadd" ? "New Corporate Member" : "Edit Corporate Member";
		},
		btnname() {
			return this.$route.name === "Corporateadd" ? "Save" : "Update";
		},
		form() {
			return this.$store.state.corporate.form;
		},
		vuelidateExternalResults() {
			return this.$store.state.corporate.vuelidateExternalResults;
		},
		validationRules() {
			return this.$store.state.corporate.validationRules;
		},
	},
	components: {
		InputField,FormBtn,Select2,TextArea,Provider,SelectBox,MultiSelectBox,Multiselect,Autocomplete,LifeStyleHeader
	},
	validations() {
		return this.$store.state.corporate.validationRules;
	},
	data() {
		return {
			reps:null,
			errorMessage:'',
			influencerserrorMessage:'',
			influencers:null,
			affiliateserrorMessage:'',
			affiliates:null,
			countries:[],
			states:[],
			demo_resp:[],
			influencers_list:[],
			affiliates_list:[],
			patientResult: [],
		}
	},
	async beforeCreate() {
		if (this.$route.name === "Corporateadd") {
			this.$store.state.corporate.form = {};
		}
		else{
			await this.$store.dispatch("getEditData", {
	         	dataType: "corporate", // pass the module name
	          	formType: "form", // pass the propery name
	          	id: this.$route.params.id,
	          });
			this.countryChange();
			this.$refs.autocomplete.searchText = this.form.patient_name;
		}
	},
	mounted(){
		this.form.influencers ='';
		this.form.affiliates ='';
		this.form.preferred_communication='';
		this.form.country_id = 231;
		this.getcountry();
		this.getdemoresp();
		this.getinfluencers();
		this.getaffiliates();
		if (this.$route.name === "Corporateadd") {
			this.countryChange(231);
		}
	},
	methods:{
		save(next) {
			if (this.$route.name === "Corporateadd") {
				this.postForm("/corporate/store", "corporate");
			} else {
				this.postForm("/corporate/update", "corporate");
			}
		},
		formPostSuccess(data) {
			if (this.$route.name === "Corporateadd") {
				this.moshaToast("Record added successfully.", "success");
			} else {
				this.moshaToast("Record updated successfully.", "success");
			}
			this.$router.push("/corporate/list");
		},
		getcountry(){
			axios.get("/json_autocomplete/country").then(
				(response) =>
				(this.countries = response.data.data.map((country) => {
					return { id: country.id, text: country.name };
				}))
				);
		},
		countryChange() {
			axios.post("/json_autocomplete/" + this.form.country_id + "/state")
			.then((response) =>
				(this.states = response.data.data.map((state) => {
					return { id: state.id, text: state.name };
				}))
				);
		},
		zipcode(){
			if(this.form.zip_code && this.form.zip_code.length > 3 ){
				axios.post("json_dropdown/state-city-auto-populations",{zip_code:this.form.zip_code})
				.then((response) => {
					this.form.city=response.data.data.City;
					this.form.state_id=response.data.data.id
				});
			}
			else{
				this.form.city= null;
				this.form.state_id=null;
			}
		},
		getdemoresp(){
			axios.get("/json_dropdown/demoreps")
			.then((response) => {
				this.demo_resp=response.data.data;
			});
		},
		savedemoreps(){
			if(this.reps == null){
				this.errorMessage ="Please enter demo reps";
			}
			else{
				axios.post("corporate/demoreps-store",{name:this.reps})
				.then((response) => {
					this.getdemoresp();
					$('#demoresp').modal('hide');
				});
			}
		},
		repschange(){
			if(this.reps.length != 0){
				this.errorMessage = '';
			}
		},
		savedinfluencers(){
			if(this.influencers == null){
				this.influencerserrorMessage ="Please enter Influencers";
			}
			else{
				axios.post("corporate/influencers-store",{name:this.influencers})
				.then((response) => {
					this.getinfluencers();
					this.form.influencers = response.data.data.id;
					$('#influencers').modal('hide');
				});
			}	
		},
		influencerschange(){
			if(this.influencers.length != 0){
				this.influencerserrorMessage = '';
			}	
		},
		getinfluencers(){
			axios.get("/json_dropdown/influencers")
			.then((response) => {
				this.influencers_list=response.data.data;
			});	
		},
		savedaffiliates(){
			if(this.affiliates == null){
				this.affiliateserrorMessage ="Please enter affiliates";
			}
			else{
				axios.post("corporate/affiliates-store",{name:this.affiliates})
				.then((response) => {
					this.getaffiliates();
					this.form.affiliates = response.data.data.id;
					$('#affiliates').modal('hide');
				});
			}	
		},
		affiliateschange(){
			if(this.affiliates.length != 0){
				this.affiliateserrorMessage = '';
			}	
		},
		getaffiliates(){
			axios.get("/json_dropdown/affiliates")
			.then((response) => {
				this.affiliates_list=response.data.data;
			});	
		},
		getlist(obj) {
			if (obj.length > 1) {
				this.$refs.autocomplete.$el.classList.add("selectloader");
				axios.post("/patient/patient-search", {search_text: obj,})
				.then((response) => {
					if(response.data.data.length>0)
					{
						this.patientResult = response.data.data.map((patient) => {
							return { id: patient.id, name: patient.first_name+" "+patient.last_name }
						});
						this.$refs.autocomplete.$el.classList.remove("selectloader");                    
					}
					else
					{
						this.patientResult = {};
					}
				});
			}
			if (obj.length == 0) {
				this.patientResult = {};
			}
		},
		resultsvalue(obj) {
			this.$emit("clicked", obj);
			this.$refs.autocomplete.searchText = obj.name;
			this.patientResult = {};
			this.form.patient_id = obj.id;
			this.focusout();
		},
		focusout() {
			const btn = this.$refs.autocomplete;
			btn.$el.querySelector(".vue3-autocomplete-container > .autosearch-list").blur();
		},
	}
}
</script>