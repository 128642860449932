<template>
  	<div class="content-wrapper">
    	<div class="container-full">
      		<div class="content-header">
        		<div class="row justify-content-between">
          			<div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xs-6">
            			<h1 class="heading1 my-5"> Provider Management  
							<a href="#" data-bs-toggle="modal" data-bs-target="#providerdocument" title="Reference of Knowledge Base" v-on:click.prevent="reset()">
                				<i class="fa fa-question-circle fs-20"></i>
							</a>
            			</h1>
          			</div>
          			<div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xs-6 text-sm-end">
						<a href="#/provider/archived" class="waves-effect waves-light btn btn-info-light me-1 mb-1"> Show Archived </a>
            			<router-link to="/provider/add" class="waves-effect waves-light btn btn-info mb-1"><i class="fa fa-plus fs-14"></i> Provider</router-link>
          			</div>
        		</div>
      		</div>
      		<section class="content">
        		<div class="box">
          			<div class="box-body">
            			<div class="table-responsive">
              				<data-table dataType="provider">
                				<template v-slot="slotProps">
                  					<!-- slot for table data -->
									<td>{{ slotProps.item.username }}</td>
									<td>
										{{ `${slotProps.item.full_name}` }}
									</td>
									<td>{{ slotProps.item.email }}</td>
									<td>{{ slotProps.item.cell_phone }}</td>
									<td>{{ slotProps.item.office_phone }}</td>
									<td>{{ slotProps.item.supervisor_or_render }}</td>
									<td>
										<span v-if="slotProps.item.emergency_access == 1">Yes</span>
										<span v-if="slotProps.item.emergency_access == 0">No</span>
									</td>
									<td>
                    					<div class="d-flex">
                      						<Popper arrow content="Edit Provider" :hover="true" class="helptips cursor-pointer">
												<a @click="editItem(slotProps.item.id)" href="#" class="waves-effect waves-circle btn btn-circle btn-info btn-xs me-1">
													<i class="fa fa-pencil"></i>
												</a>
                      						</Popper>
											<Popper arrow content="Delete Provider" :hover="true" class="helptips cursor-pointer">
												<a @click="deleteItem(slotProps.item.id)" href="#" class=" 	waves-effect waves-circle btn btn-circle btn-danger btn-xs me-1">
													<i class="fa fa-trash"></i>
												</a>
											</Popper>
											<Popper arrow content="Resend Email Verification Link" :hover="true" class="helptips cursor-pointer">
												<a v-if="slotProps.item.is_email_verified == 0" @click="resendVerificationLink(slotProps.item.user_id)" href="#" class="waves-effect waves-circle btn btn-circle btn-info btn-xs">
													<i class="fa fa-paper-plane"></i>
												</a>
											</Popper>											
                    					</div>
                  					</td>
                				</template>
              				</data-table>
            			</div>
          			</div>
        		</div>
      		</section>
      		<!--document model start-->
      		<div id="providerdocument" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
        		<div class="modal-dialog modal-md">
          			<div class="modal-content">
            			<div class="modal-header">
              				<h3 class="modal-title fw-900 mt-0">
                				Reference of Knowledge Base
              				</h3>
            			</div>
            			<div class="modal-body overflow-auto" style="max-height: 350px">
              				<table class="table table-bordered">
                				<thead>
									<tr>
										<th scope="col">No.</th>
										<th scope="col">Knowledge Base Articles</th>
									</tr>
                				</thead>
                				<tbody>
                  					<tr>
                    					<td>1</td>
										<td>
											<a :href="path + 'how-to-create-a-provider-account'" target="_blank">How To Create A Provider Account?</a>
										</td>
                  					</tr>
                  					<tr>
                    					<td>2</td>
										<td>
											<a :href="path + 'how-to-create-provider-s-schedule-in-pureehr'" target="_blank">How To Create Provider's Schedule In PureEHR?</a>
										</td>
                  					</tr>
									<tr>
										<td>3</td>
										<td>
											<a :href="path + 'how-to-activate-a-provider-account-in-pureehr'" target="_blank">How To Activate A Provider Account In PureEHR?</a>
										</td>
									</tr>
                  					<tr>
										<td>4</td>
										<td>
											<a :href="path + 'how-to-edit-a-provider-account-in-pureehr'" target="_blank">How To Edit A Provider Account In PureEHR?</a>
										</td>
									</tr>
									<tr>
										<td>5</td>
										<td>
											<a :href="path + 'how-to-delete-a-provider-account'" target="_blank">How To Delete A Provider Account?</a>
										</td>
									</tr>
									<tr>
										<td>6</td>
										<td>
											<a :href="path + 'how-to-delete-a-provider-account'" target="_blank">How To Delete A Provider Account?</a>
										</td>
									</tr>
                				</tbody>
              				</table>
              				<Search :key="searchKey"></Search>
            			</div>
            			<div class="modal-footer">
              				<button type="button" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">
								Close
							</button>
						</div>
          			</div>
        		</div>
      		</div>
      		<!--document model end-->			
    	</div>
  	</div>
</template>

<script>
	import axios from "@/scripts/axios.js";
	import dataTable from "@/components/base/DataTable.vue";
	import submitMixins from "@/scripts/mixins/submitMixins.js";
	import permissionMixins from "@/scripts/mixins/permission.js";
	import Popper from "vue3-popper";
	import Search from "@/components/Search.vue";
	import moment from "moment";

	export default {
		name: "Home",
		mixins: [submitMixins, permissionMixins],
		components: { dataTable, Popper, Search },
		data() {
			return {
				table_load_count: 0,
				path: process.env.VUE_APP_KB_DOCUMENT,
				searchKey: 0,
			};
		},
		methods: {
			dateFormated(dt, time) {
				return moment(dt + " " + time).format("ddd MMM DD, YYYY h:mm A");
			},
			capitalizeFirstLetter(string) {
				if(string!=null){
					return string.charAt(0).toUpperCase() + string.slice(1);
				}else{
					return null;
				}
			},
			reset() {
				this.searchKey = this.searchKey + 1;
			},			
			editItem(id) {
				this.$router.push({ name: "ProviderEdit", params: { id } });
			},
			deleteItem(id) {
				axios.post("provider/availabilityCheck", { id: id, for: "count" })
				.then((response) => {
					if(response.data.data.count > 0){
						this.$router.push({ name: "ProviderAppointmentSwap", params: { id } });
					}else{
						this.$swal.fire({
							title: '<h2 class="mt-0">Are you sure?</h2>',
							text: "You want to delete these record?",
							input: 'textarea',
                            inputLabel: 'Enter reason for delete.',
                            inputPlaceholder: 'Enter reason for delete...',
                            inputAttributes: {
                                'aria-label': 'Enter reason for delete'
                            },
							icon: "warning",
							dangerMode: true,
							showCancelButton: true,
							confirmButtonColor: "#ee3158",
							cancelButtonColor: "#bdbdbd",
							confirmButtonText: "Yes, delete it!",
							inputValidator: function(inputValue) {
                                return new Promise(function(resolve, reject) {
                                    if (inputValue && inputValue.length > 0) {
                                        resolve();
                                    } else {
                                        self.$swal.showValidationMessage('Can not be blank') 
                                        self.$swal.enableInput()
                                        self.$swal.enableButtons()
                                    }
                                });
                            }
						}).then((result) => {
							if (result.isConfirmed == true) {
								axios.post(`/provider/delete`, { provider_id: id, reason_for_delete: result.value })
								.then((response) => {
									this.$swal.fire("Deleted!", "Provider has been deleted.", "success")
									.then((result) => {
										if (result.isConfirmed == true) {
											location.reload();
										}
									});
								});
							}
						});
					}
				});
			},
			resendVerificationLink(id) {
				this.$store.state.loader = true;
				axios.post(`resend-email-verification`, { email_user_id: id })
				.then((response) => {
					this.$store.state.loader = false;
					if (response.data.data.result == true) {
						this.$swal.fire(
							"Success!",
							"Email verification link has been send.",
							"success"
						)
						.then((result) => {
							if (result.isConfirmed == true) {
							//location.reload();
							}
						});
					} else {
						this.$swal.fire(
							"Error!",
							"Oops,something went wrong, please try again.",
							"danger"
						)
						.then((result) => {
							if (result.isConfirmed == true) {
							}
						});
					}
				});
			},
		},
	};
</script>