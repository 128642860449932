<template>
  <div class="loginback">
    <div class="container-fluid">
      <div class="row align-items-center">
        <div
          class="col-12 col-sm-9 col-md-8 col-lg-6 col-xl-5 col-xxl-4 offset-sm-1"
        >
          <div class="bg-white rounded10 p-35 pt-40 pb-40 my-20">
            <div class="text-start">
              <img
                src="/images/pureehr.svg"
                alt="PureEHR"
                title="PureEHR"
                class="authcompany_logo mb-20"
              />
              <h2 class="mb-20 fw-900">Let's Get Started</h2>
            </div>
            <form @submit.prevent="login" class="loginform mt-20">
              <div
                class="bg-danger-light p-10 py-5 rounded5 fs-16 mb-15"
                role="alert"
                v-if="errorMessage"
              >
                {{ errorMessage }}
              </div>
              <div
                class="form-group mb-20"
                :class="{ error: v$.form.username.$errors.length }"
              >
                <label class="fs-18">Username</label>
                <input
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': errors && errors.username }"
                  name="username"
                  id="username"
                  v-model="form.username"
                  placeholder=""
                />
                <div
                  class="invalid-feedback"
                  style="display:block;"
                  v-for="(error, index) of v$.form.username.$errors"
                  :key="index"
                >
                  {{ error.$message }}
                </div>
                <div
                  class="invalid-feedback"
                  style="display:block;"
                  v-if="errors && errors.username"
                >
                  {{ errors.username[0] }}
                </div>
              </div>
              <div
                class="form-group mb-20"
                :class="{ error: v$.form.password.$errors.length }"
              >
                <label class="fs-18">Password</label>
                <input
                  type="password"
                  class="form-control"
                  :class="{ 'is-invalid': errors && errors.password }"
                  placeholder=""
                  name="password"
                  id="password"
                  v-model="form.password"
                />
                <div
                  class="invalid-feedback"
                  style="display:block;"
                  v-for="(error, index) of v$.form.password.$errors"
                  :key="index"
                >
                  {{ error.$message }}
                </div>
                <div
                  class="invalid-feedback"
                  style="display:block;"
                  v-if="errors && errors.password"
                >
                  {{ errors.password[0] }}
                </div>
              </div>
              
              <div
                class="form-group mb-20"
                v-if="user_clinic_list"
                :class="{ error: v$.form.user_clinic_id.$errors.length }"
              >
                <label class="fs-18">Clinic</label>
                <select class="form-select" v-model="form.user_clinic_id">
                  <option disabled selected="" value="">Select Clinic</option>
                  <option
                    :value="clinic.id"
                    v-for="(clinic, index) of user_clinic_list"
                    :key="index"
                    >{{ clinic.organization_name }}</option
                  >
                </select>
                <span class="help-block text-danger fs-14 mt-2" v-if="user_clinic_list && !form.user_clinic_id">Please select your clinic.</span>
              </div>
              <div
                class="form-group mb-20"
                v-if="user_clinic_list"
                :class="{ error: v$.form.useremail.$errors.length }"
              >
                <label class="fs-18">Email</label>
                <input
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': errors.useremail }"
                  name="useremail"
                  id="useremail"
                  v-model="form.useremail"
                  placeholder="Email Address"
                />
              </div>
              <div class="text-center">
                <button
                  class="waves-effect waves-light btn btn-info d-block fs-22 w-p100 started_btn"
                >
                  SIGN IN
                </button>
              </div>
              <div class="row">
                <div class="col-12 col-sm-6 col-md-6 col-lg-6 text-center text-sm-start">
                  <router-link
                    to="/forgot"
                    class="fog-pwd fs-16 mt-15 d-inline-block"
                  >
                    Forgot Password?</router-link
                  >
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-6 text-center text-sm-end">
                  <p class="fs-16 mt-15 d-inline-block">PureEHR - Version 1.0</p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "@/scripts/axios.js";
import { mapGetters, mapActions } from "vuex";
import useVuelidate from "@vuelidate/core";
import $ from "jquery";
import {
  required,
  helpers,
  minLength,
  maxLength,
  requiredIf,
} from "@vuelidate/validators";
export default {
  name: "Login",
  setup() {
    return { v$: useVuelidate() };
  },
  components: {},
  data() {
    return {
      form: {
        username: "",
        password: "",
        user_clinic_id: "",
        useremail: "",
      },
      errorMessage: "",
    };
  },
  validations() {
    return {
      form: {
        username: {
          required: helpers.withMessage("Username is required.", required),
          minLength: helpers.withMessage(
            () => "Username must be between 8 to 16 characters.",
            minLength(8)
          ),
          maxLength: helpers.withMessage(
            () => "Username must be between 8 to 16 characters.",
            maxLength(16)
          ),
        },
        password: {
          required: helpers.withMessage("Password is required.", required),
          minLength: helpers.withMessage(
            () => "Password must be between 8 to 16 characters.",
            minLength(8)
          ),
          maxLength: helpers.withMessage(
            () => "Password must be between 8 to 16 characters.",
            maxLength(16)
          ),
        },
        user_clinic_id: {
          required: requiredIf(function() {
            return this.user_clinic_list ? true : false;
          }),
        },
        useremail: {
          required: requiredIf(function() {
            return this.user_clinic_list ? true : false;
          }),
        },
      },
    };
  },
  computed: {
    ...mapGetters(["errors"]),
    ...mapGetters("auth", ["user_clinic_list"]),
  },
  created() {},
  mounted() {
    $('.tophead').html("");
    this.$store.commit("setErrors", {});
    this.$store.state.headerRequired = false;
    this.$store.state.footerRequired = false;
  },
  methods: {
    ...mapActions("auth", ["sendLoginRequest"]),
    login: function() {
      this.v$.$validate();
      if (!this.v$.$error) {
        this.errorMessage = '';
        this.sendLoginRequest(this.form)
          .then(() => {
            //this.$router.push({ name: "Home" });
            //location.reload(true);
            console.log("call");
          })
          .catch((error) => {
            console.log(error);
            this.errorMessage = error.response.data.message;
          });
      }
    },
  },
};
</script>
<style scoped></style>
