<template>
  	<div class="box notescollap">
    	<div class="box-body">
      		<div class="pageheading">
				<div class="row justify-content-between align-items-center">
					<div class="col-12 col-sm-7 col-md-7 col-lg-6 col-xs-6">
						<h3 class="font-bold my-15">Upcoming Appointments <a href="#" data-bs-toggle="modal" data-bs-target="#upcomingappointmentdocument" title="Reference of Knowledge Base" v-on:click.prevent="reset()"> <i class="fa fa-question-circle fs-20"></i></a></h3>
						
					</div>
					<div class="col-12 col-sm-5 col-md-5 col-lg-6 col-xs-4 text-sm-end">
						<a class="waves-effect waves-light btn btn-info mb-5" @click="printSection('printAppointment','Future')" >Print Future Appointments</a>
					</div>
				</div>
      		</div>
			<div id="printAppointment">
				<div class="table-responsive">
					<table v-if="appointment_list" class="table table-hover">
						<thead>
							<tr>
								<th>Scheduled Time</th>
								<th>Provider</th>
								<th>Staff</th>
								<th>Reason</th>
								<th>Notes</th>
								<th>Office</th>
								<th>Exam Room</th>
								<th>Appointment Status</th>
								<th>Profile</th>
								<th v-if="permissioncheck(this.$store.state.permission.create_update)">Actions</th>
							</tr>
						</thead>
						
						<tbody :set="count=0">
							<tr v-for="(item,index) of appointment_list" :key="index" :class="{'bg-color-for-imported':(item.is_imported)}">
								<template v-if="curDate < (item.schedule_date+item.schedule_time)"> 
									<td :set="count++">
										{{ dateFormated(item.schedule_date,item.schedule_time)}} 
									</td>
									 
									<td>{{ item.provider_name }}</td>
									<td>{{item.staff_name}}</td>
									<td>{{ item.reason_for_visit }}</td>
									<td>{{ item.extra_notes }}</td>
									<td>{{ item.office_name }}</td>
									<td>{{ item.room_name }}</td>
									<td>{{ item.appointment_status }}</td>
									<td>
										<div v-if="item.name"> {{ item.name }}
											<span :style="{ 'background-color': item.color_code }" class="selectedcolore d-block"></span>
										</div>  
										<div v-else> - </div>               
									</td>
								
									<td>
										<div v-if="item.appointment_status != 'Rescheduled'">
											<div class="d-flex" v-if="permissioncheck(this.$store.state.permission.create_update)">
												<a v-if="permissioncheck(this.$store.state.permission.create_update)" href="javascript:void(0)"	class="waves-effect waves-circle btn btn-circle btn-info btn-xs me-5" @click="edit(item.appointment_number)" title="Edit Appointment"><i class="fa fa-pencil"></i></a>
												<a href="#" @click="getappointment(item.id,item)" class="waves-effect waves-circle btn btn-circle btn-danger btn-xs me-5" title="Delete Appointment" data-bs-toggle="modal"  data-bs-target="#appointment"><i class="fa fa-trash"></i></a>
												<router-link :to="{ name: 'AppointmentEligibility', params: { id: patient_unique_id, appointments_id: item.appointment_number } }">
													<a href="javascript:void(0);" title="Eligibility Test" class="waves-effect waves-circle btn btn-circle btn-info btn-xs me-5">
													<i class="fa fa-qrcode"></i>
													</a>
												</router-link>
												<a v-bind:href="'/#/telehealth/'+item.vcallroom+'/'+item.schedule_date+'/'+item.schedule_time+'/'+item.schedule_end_time"
													target = "_blank"
													v-if="item.type=='Tele Health' && item.vcallroom != null"
													class="waves-effect waves-circle btn btn-circle btn-info btn-xs"
													title="Join call "
													><i class="fa fa-video-camera"></i
												></a>
											</div>
										</div>
									</td>
								</template> 
							</tr>
							<tr v-if="count==0"><td colspan="9" align="center">No appointment is available.</td></tr>
						</tbody>
					</table>
				</div>
			</div>
    	</div>
  	</div>
	<!--document model start-->
	<div id="upcomingappointmentdocument" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
		<div class="modal-dialog modal-md">
			<div class="modal-content">
				<div class="modal-header">
					<h3 class="modal-title fw-900 mt-0">Reference of Knowledge Base</h3>
				</div>
				<div class="modal-body overflow-auto" style="max-height: 350px;">
					<table class="table table-bordered">
						<thead>
							<tr>
								<th scope="col">No.</th>
								<th scope="col">Knowledge Base Articles</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>1</td>
								<td> <a :href="path+'how-to-print-future-and-past-appointment-list-9-6-2023'" target="_blank">How To Print a Patient's Future And Past Appointment List?</a></td>
							</tr>
							<tr>
								<td>2</td>
								<td> <a :href="path+'how-to-view-ccda'" target="_blank">How To View CCDA?</a></td>
							</tr>
							<tr>
								<td>3</td>
								<td> <a :href="path+'how-to-download-ccda'" target="_blank">How To Download CCDA?</a></td>
							</tr>
							<tr>
								<td>4</td>
								<td> <a :href="path+'how-to-share'" target="_blank">How to Share CCDA to Referral Doctor?</a></td>
							</tr>
							<tr>
								<td>5</td>
								<td> <a :href="path+'how-to-view'" target="_blank">How to View a Patient's List of Appointments?</a></td>
							</tr>
						</tbody>
					</table>
					<Search :key="searchKey"></Search>
				</div>
				<div class="modal-footer">
					<button type="button" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
				</div>
			</div>
		</div>
	</div>
	<!--document model end-->
	<!--model start-->
	<div id="appointment" class=" appointment modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
		<div class="modal-dialog modal-md">
			<div class="modal-content">
				<div class="modal-header">
					<h4 class="modal-title fw-900 mt-0">Are you sure you want to delete this appointment?</h4>
				</div>
				<div class="modal-body">
					<div class="form-group">
						<textarea class="form-control" placeholder="Enter reason for delete." id="reasonfordelete"></textarea>
					</div>
				</div>
				<div class="modal-footer">
					<button @click="appointmentdelete" type="button" class="waves-effect waves-light btn btn-info">Delete</button>
					<button type="button" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
				</div>
			</div>
		</div>
	</div>
	<!--model end-->
	<div class="box notescollap">
		<div class="box-body">
			<div class="pageheading">
				<div class="row justify-content-between">
					<div class="col-12 col-sm-7 col-md-7 col-lg-6 col-xs-6">
					<h3 class="font-bold">Past Appointments <a href="#" data-bs-toggle="modal" data-bs-target="#pastappointmentdocument" title="Reference of Knowledge Base" v-on:click.prevent="reset()"> <i class="fa fa-question-circle fs-20"></i></a></h3>
					</div>
					<div class="col-12 col-sm-5 col-md-5 col-lg-6 col-xs-4 text-sm-end">
						<a
						class="waves-effect waves-light btn btn-info mb-5"
						@click="printSection('printPastAppointment','past')"
						>Print Past Appointments</a
					>
					</div>
				</div>
			</div>		
			<div id="printPastAppointment">
				<div class="table-responsive">
					<table v-if="appointment_list" class="table table-hover">
						<thead>
						<tr>
							<th>Scheduled Time</th>
							<th>Provider</th>
							<th>Reason</th>
							<th>Notes</th>
							<th>Office</th>
							<th>Exam Room</th>
							<th>Appointment Status</th>
							<th>Profile</th>
							<th v-if="permissioncheck(this.$store.state.permission.create_update)">Actions</th>
						</tr>
						</thead>
						<tbody :set="count1=0">
						<slot v-for="(item,index) of appointment_list"  :key="index">
							<tr :class="{'bg-color-for-imported':(item.is_imported)}">
							<template v-if="curDate > (item.schedule_date+item.schedule_time)"> 
							<td :set="count1++">
								{{
								dateFormated(
									item.schedule_date,
									item.schedule_time
								)
								}}
							</td>
							<td>{{ item.provider_name }}</td>
							<td>{{ item.reason_for_visit }}</td>
							<td>{{ item.extra_notes }}</td>
							<td>{{ item.office_name }}</td>
							<td>{{ item.room_name }}</td>
							<td>{{ item.appointment_status }}</td>
							<td>
								<div v-if="item.name"> {{ item.name }}
								<span :style="{ 'background-color': item.color_code }" class="selectedcolore d-block"></span>
								</div>  
								<div v-else> - </div>
							</td>
							<td>
								<div class="d-flex" v-if="permissioncheck(this.$store.state.permission.create_update)">
								<a href="javascript:void(0)" @click="edit(item.appointment_number)" class="waves-effect waves-circle btn btn-circle btn-info btn-xs me-5" title="Edit/View Appointment"><i class="fa fa-pencil"></i></a>
								<a href="#" @click="getappointment(item.id,item)" class="waves-effect waves-circle btn btn-circle btn-danger btn-xs me-5" title="Delete Appointment" data-bs-toggle="modal"  data-bs-target="#appointment" ><i class="fa fa-trash"></i></a>
								<a href="#" class="waves-effect waves-circle btn btn-circle btn-info btn-xs me-5" @click="downloadCCDA(item.id,item.appointment_number)" title="Download CCDA"><i class="fa fa-download"></i></a>
								<a href="javascript:void(0);" class="waves-effect waves-circle btn btn-circle btn-info btn-xs me-5" @click="CCDAmodal(item.id,item.appointment_number)" title="Send CCDA using PHI mail"><i class="fa fa-paper-plane"></i></a>
								<a href="javascript:void(0);" @click="viewCCDA(item.id,item.appointment_number)" class="waves-effect waves-circle btn btn-circle btn-info btn-xs me-5" title="View CCDA"> <i class="fa fa-eye"></i> </a>
								<router-link :to="{ name: 'AppointmentEligibility', params: { id: patient_unique_id, appointments_id: item.appointment_number } }">
									<a href="javascript:void(0);" title="Eligibility Test" class="waves-effect waves-circle btn btn-circle btn-info btn-xs me-5">
									<i class="fa fa-barcode"></i>
									</a>
								</router-link> 
								<a v-bind:href="'/#/patient-chart/'+patient_unique_id+'/telehealthlog/'+item.room_sid" 
									target = "_blank"
									v-if="item.type=='Tele Health' && item.vcallroom != null && item.room_sid!='' && item.room_sid!=null"
									class="waves-effect waves-circle btn btn-circle btn-info btn-xs" 
									title="Video call recording"> 
									<i class="fa fa-file-video-o" style="font-size: 14px;"></i> 
								</a>                  
								</div>
							</td>  
							</template> 
							</tr>
						</slot>
						<tr v-if="count1==0"><td align="center" colspan="9">No appointment is available.</td></tr>
						</tbody>
					</table>
				</div>
			</div>
			<div id="ccdarecipient" class="modal fade"  tabindex="-1" role="dialog"  aria-labelledby="myModalLabel" aria-hidden="true">
				<div class="modal-dialog">
					<div class="modal-content permissmodal">
						<div class="modal-header">
							<h4 class="modal-title font-bold m-0">Send CCDA</h4>
							<button type="button" class="btn-close btn-xs"  id="csvmodalclose" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div class="modal-body">                  
							<div class="row form-group">
								<label class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end"> Recipient <small class="text-danger asterisksign">*</small></label>
								<div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8" :class="{ error: v$.ccdarecipient.$errors.length }">
								<input type="text" id="ccdarecipient" v-model="ccdarecipient" class="form-control" placeholder="Mail Recipient">
								<div class="invalid-feedback" style="display:block;" v-for="(error, index) of v$.ccdarecipient.$errors" :key="index"> {{ error.$message }} </div>
								<div class="invalid-feedback" style="display:block;" v-if="email_error != null"> {{ email_error }} </div>
								</div>
							</div>
							<div class="row form-group">
								<label class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end"> Mail Subject </label>
								<div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
								<input type="text" id="mail_subject" v-model="mail_subject" class="form-control" placeholder="Mail Subject">
								</div>
							</div>
							<div class="row form-group">
								<label class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end"> Mail Content <small class="text-danger asterisksign">*</small></label>
								<div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8" :class="{ error: v$.mail_body.$errors.length }">
								<textarea name="textarea" rows="2" id="mail_body" v-model="mail_body" class="form-control" placeholder="Mail Content"></textarea>
								<div class="invalid-feedback" style="display:block;" v-for="(error, index) of v$.mail_body.$errors" :key="index"> {{ error.$message }} </div>                      
								</div>
							</div>
							<div class="form-group row">
								<label class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end"> Referring doctor </label>
								<div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">                  
								<Select2
									v-model="referring_doctor_contact_id"
									id="referring_doctor_contact_id"
									placeholder="Select Referring doctor ID"
									:options="referring_doctor"
								/>
								</div>
							</div>
							<div class="form-group row">
								<label class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end"> Ordering doctor </label>
								<div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">                  
								<Select2
									v-model="ordering_doctor_contact_id"
									id="ordering_doctor_contact_id"
									placeholder="Select Ordering doctor ID"
									:options="referring_doctor"
								/>
								</div>
							</div>
							<check-box
								title="Referral Doctor"
								v-model="referral_doctor"
								label=""
								labelClass="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end"
								divClass="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8"
							></check-box>
						</div>
						<div class="modal-footer">
							<button type="button" class="waves-effect waves-light btn btn-info" v-if="loader">Sending Please wait ...</button>
							<button type="button" class="waves-effect waves-light btn btn-info" v-else @click="sendCCDA">Send</button>
						</div>
					</div>
				</div>   
			</div>
			<div id="ccdadownloadtype" class="modal fade"  tabindex="-1" role="dialog"  aria-labelledby="myModalLabel" aria-hidden="true">
				<div class="modal-dialog">
					<div class="modal-content permissmodal">
						<div class="modal-header">
							<h4 class="modal-title font-bold m-0">Download CCDA</h4>
							<button type="button" class="btn-close btn-xs"  id="csvmodalclose" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div class="modal-body">
							<div class="form-group row">
                        		<label class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end">Select CCDA Type</label>
								<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
									<div class="row">
										<div class="col-12 col-sm-12 col-md-12 col-lg-12">
											<input type="radio" id="Summarization_of_episode_note"  value="1" class="filled-in chk-col-info" v-model="ccda_type"/>
											<label for="Summarization_of_episode_note">Summarization of episode note</label>
										</div>
										<div class="col-12 col-sm-12 col-md-12 col-lg-12">
											<input type="radio" id="Referral_Note"  value="2" class="filled-in chk-col-info" v-model="ccda_type"/>
											<label for="Referral_Note">Referral Note</label>
										</div>										
									</div>
								</div>
							</div>
						</div>
						<div class="modal-footer">							
							<button type="button" class="waves-effect waves-light btn btn-info" @click="downloadCCDAfile">Download</button>
						</div>
					</div>
				</div>   
			</div>
		</div>
	</div>
  	<!--document model start-->
	<div id="pastappointmentdocument" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
		<div class="modal-dialog modal-md">
			<div class="modal-content">
				<div class="modal-header">
					<h3 class="modal-title fw-900 mt-0">Reference of Knowledge Base</h3>
				</div>
				<div class="modal-body overflow-auto" style="max-height: 350px;">
					<table class="table table-bordered">
						<thead>
							<tr>
							<th scope="col">No.</th>
							<th scope="col">Knowledge Base Articles</th>
							</tr>
						</thead>
						<tbody>
							<tr>
							<td>1</td>
							<td> <a :href="path+'how-to-edit-appointments-from-calendar'" target="_blank">How To Edit Appointments from calendar ?</a></td>
							</tr>
							<tr>
							<td>2</td>
							<td> <a :href="path+'how-to-delete-appointment-from-calendar'" target="_blank">How To Delete Appointment From Calendar?</a></td>
							</tr>
						</tbody>
					</table>
					<Search :key="searchKey"></Search>
				</div>
				<div class="modal-footer">
					<button type="button" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
				</div>
			</div>
		</div>
	</div>
	<!--document model end-->
</template>
<script>
import TableBase from "@/components/base/Table.vue";
import moment, * as moments from "moment";
import permissionMixins from "@/scripts/mixins/permission.js";
import submitMixins from "@/scripts/mixins/submitMixins.js";
import axios from "@/scripts/axios.js";
import useVuelidate from "@vuelidate/core";
import Select2 from "@/components/base/formFields/select2.vue";
import CheckBox from "@/components/base/formFields/checkBox.vue";
import { required, email, helpers } from '@vuelidate/validators';
import Search from "@/components/Search.vue";

export default {
  mixins: [permissionMixins,submitMixins],
  setup: () => {
      return { v$: useVuelidate() };
  },
  components: { TableBase,Select2,CheckBox, Search },
  data() {
    return {
      patient_id: this.$store.state.patientDetail.patient_id,
      patient_unique_id: this.$store.state.patientDetail.patient_unique_id,
      output: null,
      curDate: '',
      appointment_list: 'jh',
      ccdarecipient: null,
      mail_subject: null,
      mail_body: null,
      ccda_appointment_id: null,
      loader: false,
      email_error: null,
      referring_doctor:[],
      referring_doctor_contact_id:'',
      ordering_doctor_contact_id:'',
      referral_doctor:0,
      id:'',
      action:[],
      path: process.env.VUE_APP_KB_DOCUMENT,
      searchKey:0,
	  ccda_type:1,
    };
  },
  computed: {
    profileColor() {
      return {
        color: "Red",
      };
    },
  },
  mounted() {

    axios.get("/referral-contact/list").then(
      (response) =>
        (this.referring_doctor = response.data.data.map(
          (doctor) => {
            return { id: doctor.id, text: doctor.first_name };
          }
        ))
    );

    this.curDate = moment().format('YYYY-MM-DDHH:mm');
    this.$store.state.loader = true;
      axios.post("/patient/"+this.patient_unique_id+"/appointment-list",{patient_id:
          this.patient_id,patient_unique_id: this.patient_unique_id})
        .then((response) => {
          this.$store.state.loader = false;
            this.appointment_list = response.data.data.map(item => {
                return {...item, is_imported: (item.imported_at != null) ? true : false };
            });            
        })    
  },
  methods: {
    reset() {
      this.searchKey = this.searchKey + 1;
    },
    getappointment(id,item){
      $("#reasonfordelete").val('');
      this.id = id;
      this.action[0] = item;
    },
    appointmentdelete(){
      var reasonfordelete =$("#reasonfordelete").val();
      axios.post("/appointment/store",{id:this.id,deleted:this.action})
      .then((response) => {
        axios.post("/appointment/reason-for-delete",{id:this.id,reason_for_delete:reasonfordelete})
        .then((response) => {
          this.moshaToast("Record updated successfully.", "success");
          $('#appointment').modal('hide');
          axios.post("/patient/"+this.patient_unique_id+"/appointment-list",{patient_id:
            this.patient_id,patient_unique_id: this.patient_unique_id})
          .then((response) => (this.appointment_list = response.data.data))    
        })
      });
    },
    edit(id){
      this.$router.push(`/patient-chart/${this.$route.params.id}/edit-appointment/`+id);   
    },
    /*reference from - https://www.npmjs.com/package/vue-moment*/
    dateFormated(dt, time) {
      return moment(dt + " " + time).format("ddd MMM DD, YYYY h:mm A");
    },
    async printSection($div,type) {
      
      axios.post(`patient/${this.$route.params.id}/audit-log`, {
          type:"APPOINTMENT",
          action:"PRINT"

      }).then((res) => {
      });
      await this.$htmlToPaper($div);
    },
    downloadCCDA(id){
		this.ccda_appointment_id = null;
      	this.ccda_appointment_id = id;
      	$("#ccdadownloadtype").modal("show");
	},
	downloadCCDAfile(){
		axios.post(`patient/${this.$route.params.id}/getCCDA/`+this.ccda_appointment_id, {
			xml: true, ccda_type: this.ccda_type
		}).then((res) => { 
			$("#ccdadownloadtype").modal("hide");     
			var  last_name = this.$store.state.patientDetail.last_name;
					var first_name = this.$store.state.patientDetail.first_name;
			var hiddenElement = document.createElement('a');
			var blob = new Blob([res.data], {type: "text/xml"});
			var url = window.URL.createObjectURL(blob);
			hiddenElement.href = url;
			hiddenElement.download = 'CCDA'+first_name+'_'+last_name+'.xml';
			document.body.append(hiddenElement);
			hiddenElement.click();
			hiddenElement.remove();
			window.URL.revokeObjectURL(url);        
		});
	},
    CCDAmodal(id,appointment_number){
      this.ccdarecipient = null;
      this.mail_subject = null;
      this.mail_body = null;
      this.ccda_appointment_id = null;
      this.loader = false;
      this.ccda_appointment_id = id;
      $("#ccdarecipient").modal("show");
    },
    sendCCDA(){
      this.v$.$validate();
      if (!this.v$.$error) {
        this.loader = true;
        axios.post("/phimailbox/send",{referral_doctor:this.referral_doctor,ordering_doctor_contact_id:this.ordering_doctor_contact_id,referring_doctor_contact_id:this.referring_doctor_contact_id,ccda_appointment_id:
          this.ccda_appointment_id,ccdarecipient: this.ccdarecipient,mail_body: this.mail_body,mail_subject: this.mail_subject}).then((response) => {
            this.loader = false;
            this.ccdarecipient = null;
            this.mail_subject = null;
            this.mail_body = null;
            this.ccda_appointment_id = null;
            $("#ccdarecipient").modal("hide");
            this.moshaToast("Mail sent successfully.", "success"); 
        }).catch((error) => {
          this.loader = false;
          this.email_error = error.response.data.message;
        });
      }
    },
    viewCCDA(id,appointment_number){ 
      this.$store.state.loader = true;
      axios.post(`patient/${this.$route.params.id}/getCCDA/`+id, {
        html: true,
      }).then((res) => {      
        this.$store.state.loader = false;
        var w = window.open();
        var html = res.data;
        $(w.document.body).html(html);   
      });
    },
  },
  validations () {
    return {      
      ccdarecipient: { 
        required: helpers.withMessage("Recipient Email is required.", required), 
        email: helpers.withMessage("Recipient Email address is not valid.", email) 
      },
      mail_body: { 
        required: helpers.withMessage("Mail Content is required.", required), 
      }  
    }
  }
};
</script>
<style lang="scss" scoped>
.bg-color-for-imported {
  background-color:#daddf5 !important;
}
.table-hover > tbody > tr.bg-color-for-imported:hover{
  --bs-table-accent-bg: #daddf5!important;
}
</style>