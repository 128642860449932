<template>
  
  <div class="content-wrapper">
      <div class="container-full">
        <section class="content">
          <div class="row">
            <div class="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-2">
              <the-side-bar></the-side-bar>
            </div>
            <div class="col-12 col-sm-9 col-md-9 col-lg-9 col-xl-10">
              <div class="tab-content">
                  <component :is="$store.state.addOfficeComp"></component>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
// import 'jquery/dist/jquery.min.js';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import "datatables.net-dt/js/dataTables.dataTables"
// import "datatables.net-dt/css/jquery.dataTables.min.css"

import axios from "axios";
import TheSideBar from "@/components/office/TheSidebar.vue";
import AddOffice from "@/components/office/Home.vue";
import OfficeBilling from "@/components/office/billing/Home.vue";
import AddRooms from "@/components/office/rooms/Home.vue";
import RoomsArchived from "@/components/office/rooms/RoomsArchived.vue";
import AddResources from "@/components/office/resources/Home.vue";
import OfficeSchedule from "@/components/office/schedule/Home.vue";
import LabSetting from "@/components/office/labs/Home.vue";
import OfficeProviderSchedule from "@/components/office/provider/Home.vue";

export default {
  name: "OfficeAddEdit",
  components: { AddOffice, OfficeBilling, TheSideBar, AddRooms, AddResources, OfficeSchedule, LabSetting, RoomsArchived, OfficeProviderSchedule},
  watch: {

  },
  created() {
    if (this.$route.name === "OfficeSchedule") {
      this.$store.state.addOfficeComp = "OfficeSchedule";
    }
    else if (this.$route.name === "OfficeBilling") {
      this.$store.state.addOfficeComp = "OfficeBilling";
    }
    else if (this.$route.name === "OfficeEdit" || this.$route.name === "OfficeAdd") {
      this.$store.state.addOfficeComp = "AddOffice";
    } 
    else if(this.$route.name === "RoomsAdd") {
      this.$store.state.addOfficeComp = "AddRooms";
    } 
    else if(this.$route.name === "ResourcesAdd") {
      this.$store.state.addOfficeComp = "AddResources";
    }
    else if(this.$route.name === "LabSetting") {
      this.$store.state.addOfficeComp = "LabSetting";
    }
    else if(this.$route.name === "RoomsArchived") {
      this.$store.state.addOfficeComp = "RoomsArchived";
    }
    else if(this.$route.name === "OfficeProviderSchedule") {
      this.$store.state.addOfficeComp = "OfficeProviderSchedule";
    }
  },
  mounted(){

  },
  methods: {},
};
</script>