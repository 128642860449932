<template>
	<div class="content-wrapper position-relative">
		<div class="container-full">
			<div class="content-header">
				<h1 class="heading1 m-0">Bulk Email <a href="#" data-bs-toggle="modal" data-bs-target="#patientcontactdocument" title="Reference of Knowledge Base" v-on:click.prevent="reset()"> <i class="fa fa-question-circle fs-20"></i></a></h1>
			</div>
			<div class="box">
				<div class="box-body">
					<div class="row">
						<div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 form-group">
							<label class="form-label mb-2">State</label>
							<Select2 v-model="this.filter.state_id" id="state_id" placeholder="Select State" :options="states" />
						</div>
						<div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 form-group mb-0">
							 <label class="form-label mb-2">Portal Status</label>
								<select-box-static
									v-model="filter.patient_portal_id"
									:option="patient_portal"
									divClass="col-12 col-sm-12 col-md-12 col-lg-12 mt-0"
									labelClass="d-none"
								></select-box-static>	
						</div>
						<div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
							<div class="form-group">
								<label class="form-label">Age</label>
								<div class="d-flex align-items-center">
									<input id="Age" type="text" class="form-control" placeholder="Age"
										v-model="filter.min_age">
									<span class="mx-5">To</span>
									<input id="Age" type="text" class="form-control" placeholder="Age"
										v-model="filter.max_age">
								</div>
							</div>
						</div>
						<div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
							<div class="form-group">
								<label class="form-label">ZipCode</label>
								<input id="ZipCode" type="text" class="form-control" placeholder=" Enter ZipCode"
									v-model="filter.zip_code">
							</div>
						</div>
						<div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
							<div class="form-group">
								<label class="form-label"> Patient Joining Date Range</label>
								<div class="availdate">
									<ejs-daterangepicker format='MM-dd-yyyy' id="join_date_range" :value="filter.join_daterange" strictMode='strict' placeholder="Select Date Range" :openOnFocus='true' @change="onRangeSelect"></ejs-daterangepicker>
								</div>
							</div>
						</div>
						<div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
							<div class="form-group">
								<label class="form-label">Appointment Date Range</label>
								<div class="availdate">
									<ejs-daterangepicker format='MM-dd-yyyy' id="appointment_date_range" :value="filter.appointment_daterange" strictMode='strict' placeholder="Select Date Range" :openOnFocus='true' @change="onRangeSelect"></ejs-daterangepicker>
								</div>
							</div>
						</div>
						<div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
							<div class="form-group">
								<label class="form-label mb-1">Pending Documents</label>
								<select-box-static id="PendingDocuments" v-model="filter.pending_documents_id" :option="pending_documents" divClass="col-12 col-sm-12 col-md-12 col-lg-12 mt-0" labelClass="d-none"></select-box-static>
							</div>
						</div>
						<div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
							<div class="form-group">
								<label class="form-label mb-1">Gender</label>
								<select-box-static id="PendingDocuments" v-model="filter.gender" :option="gender" divClass="col-12 col-sm-12 col-md-12 col-lg-12 mt-0" labelClass="d-none"></select-box-static>
							</div>
						</div>
						<div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
							<div class="form-group">
								<label class="form-label">Visit Type</label>
								<select-box v-model="filter.visit" getDataURL="/appointment-profile/list" divClass="col-12 col-sm-12 col-md-12 col-lg-12 mt-0"  labelClass="d-none"></select-box>
							</div>
						</div>
						<div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
							<div class="form-group">
								<label class="form-label"> Credit Card Expired</label>
								<select-box-static id="credit_card" v-model="filter.credit_card" :option="credit_card" 
								divClass="col-12 col-sm-12 col-md-12 col-lg-12 mt-0" labelClass="d-none"></select-box-static>
							</div>
						</div>

						<div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
							<div class="form-group">
								<label class="form-label">CPT Codes</label>
								<div class="selectcontrols">
									<Cpt-code placeholder="Search CPT Code" v-model="this.filter.cpt_id" @clicked="cptsearchresult"></Cpt-code>
								</div>
							</div>
						</div>
						<div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
							<div class="form-group">
								<label class="form-label">Problem List</label>
								<div class="selectcontrols">
									<div class="selectcontrols ">
										<Autocomplete max="30" @input="inputCode" ref="autocomplete" :results="problemCodeList" 
										@onSelect="problemSelectedValue" class="form-control ms-0 autosearch-list" :value="modelValue" 
										placeholder="Search Problem" :use-html-for-results="true"></Autocomplete>
									</div>
								</div>
							</div>
						</div>
						<div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
							<div class="form-group">
								<label class="form-label">Chief Complaint</label>
								<div class="selectcontrols" id="Chief_complaint">
									<Icd10-code  v-model="filter.chief_complaint_id" @clicked="icdsearchresult"></Icd10-code>
								</div>
							</div>
						</div>
						<div class="col-12 col-sm-6 col-md-4 col-lg-3">
							<div class="mt-sm-25">
								<a @click="filter_list" class="waves-effect waves-light btn btn-info me-1">Search</a>
								<a @click="clear_filter" href="javascript:void(0)" class="waves-effect waves-light btn btn-info-light">Clear Filter</a>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="box">
				<div class="box-body">
					<div class="text-start text-md-end mb-15">
						<a href="javascript:void(0)" class="waves-effect waves-light btn btn-info-light m-1" @click="openContentDialog('create')"><i class="fa fa-envelope fs-14 me-1"></i> Create Email Template</a>
						<a href="javascript:void(0)" class="waves-effect waves-light btn btn-info-light m-1" @click="openContentDialog('bulk')" id="bluk_email"><i class="fa fa-send fs-14 me-1"></i> Bulk Email</a>
						<a href="javascript:void(0)" class="waves-effect waves-light btn btn-info-light m-1" @click="bluk_pp_active_llink()" id="bluk_email"><i class="fa fa-send fs-14 me-1"></i> Bulk PurePortal Activation</a>
						<!-- <a  href="javascript:void(0)" class="waves-effect waves-light btn btn-info-light m-1"><i class="fa fa-lock fs-14 me-1"></i> Secure Email</a> -->
						<a href="javascript:void(0)" class="waves-effect waves-light btn btn-info-light m-1" @click="downloadexcel()"><i class="fa fa-download fs-14 me-1"></i> Download XLS</a>
					</div>
					<div class="table-responsive">
						<data-table-server dataType="PatientContactReport" dataMethod="post" @checkall="checkall" :key="key">
							<template v-slot="slotProps">
								<td v-if="permissioncheck(this.$store.state.permission.Access_Patient_Chart)">
									<router-link :to="{ name: 'PatientDetails', params: { id: slotProps.item.unique_id }, }" :title="'View Chart of ' + slotProps.item.first_name + '(' + slotProps.item.chart_id + ')'" class="fw-500">{{ slotProps.item.chart_id }}</router-link>
								</td>
								<td v-else>{{ slotProps.item.chart_id }}</td>
								<td>{{ slotProps.item.first_name }}</td>
								<td>{{ slotProps.item.last_name }}</td>
								<td>{{ slotProps.item.email }}</td>
								<td>{{ slotProps.item.state_name }}</td>
								<td>
									<span v-if="slotProps.item.pp_id == 0"
										class="badge badge-danger-light fs-16 font-bold">Not Active</span>
									<span v-else class="badge badge-success-light fs-16 font-bold">Active</span>
								</td>
								<td>
									<span v-if="slotProps.item.bulk_dnd == 1">Yes</span>
									<span v-else>-</span>
								</td>
								<td>
									<input type="checkbox" class="filled-in chk-col-info select myCheckbox"
										:id="'select' + slotProps.item.unique_id" v-model="patientIds"
										@click="select(slotProps.item.patient_id, $event, slotProps.item.first_name, slotProps.item.last_name)"
										:value="slotProps.item.patient_id"
										:data-patient_name="slotProps.item.first_name + ' ' + slotProps.item.last_name">
									<label :for="'select' + slotProps.item.unique_id"></label>
								</td>
							</template>
						</data-table-server>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!--document model start-->
	<div id="patientcontactdocument" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
		aria-hidden="true">
		<div class="modal-dialog modal-md">
			<div class="modal-content">
				<div class="modal-header">
					<h3 class="modal-title fw-900 mt-0">Reference of Knowledge Base</h3>
				</div>
				<div class="modal-body overflow-auto" style="max-height: 350px;">
					<table class="table table-bordered">
						<thead>
							<tr>
								<th scope="col">No.</th>
								<th scope="col">Knowledge Base Articles</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>1</td>
								<td> <a :href="path + 'how-to-use-bulk-email-in-pureehr'" target="_blank">How To Use Bulk
										Email In PureEHR?</a></td>
							</tr>
						</tbody>
					</table>
					<Search :key="searchKey"></Search>
				</div>
				<div class="modal-footer">
					<button type="button" data-bs-dismiss="modal" aria-label="Close"
						class="waves-effect waves-light btn btn-danger">Close</button>
				</div>
			</div>
		</div>
	</div>
	<!--document model end-->
	<div id="myContentModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
		aria-hidden="true">
		<div class="modal-dialog modal-xl">
			<div class="modal-content">
				<div class="modal-header">
					<h2 class="modal-title m-0">Content</h2>
					<button type="button" class="btn-close btn-xs" data-bs-dismiss="modal" aria-label="Close"
						@click="closeModal()"></button>
				</div>
				<div class="modal-body">
					<div class="form-group row" id="subject_row">
						<label class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">Subject <small
								class="text-danger asterisksign">*</small></label>
						<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
							<div class="controls">
								<input id="Subject" type="text" v-model="subject" class="form-control" placeholder="Subject"
									autocomplete="off">
							</div>
							<div class="error-msg">
								<ul class="mb-0 p-0 fs-14"></ul>
							</div>
						</div>
					</div>
					<div class="form-group row">
						<div class="col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8" v-if="this.is_new_template">
							<div class="controls">
								<input id="template_title" type="text" v-model="template_title" class="form-control"
									placeholder="Enter title to save your new template" autocomplete="off">
							</div>
							<div class="error-msg">
								<ul class="mb-0 p-0 fs-14"></ul>
							</div>
						</div>
						<div class="col-2 col-md-2" v-if="this.is_new_template">
							<form-btn title="Back" btnCls="btn-info"
								@click="this.is_new_template = !this.is_new_template;"></form-btn>
						</div>
						<div class="col-12 col-md-10" v-if="!this.is_new_template">
							<div class="form-group mb-10">
								<select name="select" class="form-select form-control" v-model="selected_email_template"
									@change="changeTemplate">
									<option selected="selected" value="" disabled="disabled">Select Template</option>
									<option v-for="item in emaillist" :key="item.id" :value="item.id">{{ item.template_name }}
									</option>
								</select>
							</div>
						</div>
						<div class="col-2 col-md-2" v-if="!this.is_new_template && !this.is_bluk_email">
							<div v-if="!this.is_edit_template">
								<form-btn title="Add New" btnCls="btn-info" @click="this.is_new_template = !this.is_new_template; this.$refs.editorObj.ej2Instances.value = null"></form-btn>
							</div>
						</div>
					</div>
					<div class="form-group" id="content_row">
						<ejs-richtexteditor :pasteCleanupSettings="pasteCleanupSettings" :toolbarSettings="toolbarSettings"
							:insertImageSettings="insertImageSettings" :imageUploading="imageUploading"
							:imageRemoving="imageRemoving" :afterImageDelete="afterImageDelete" ref="editorObj"
							title="Message" rows="10">
						</ejs-richtexteditor>
					</div>
					<div class="my-2" v-if="this.is_bluk_email">
						<smart-tagz :default-tags="this.full_name" inputPlaceholder="" @changed="removelist($event)" />
					</div>
				</div>
				<div class="modal-footer">
					<div v-if="this.is_bluk_email">
						<button type="button" class="waves-effect waves-light btn btn-info" @click="sendEmail">Send <img
								v-if="loader" src="images/loader.gif"></button>
					</div>
					<div v-else>
						<form-btn title="Save Template" btnCls="btn-info" @click="saveDialogContent"></form-btn>
					</div>
					<button type="button" class="addpatientbtn btn-danger b-0" data-bs-dismiss="modal"
						@click="closeModal()">Close</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import moment from 'moment';
import Autocomplete from "vue3-autocomplete";
import axios from "@/scripts/axios.js";
import permissionMixins from "@/scripts/mixins/permission.js";
import submitMixins from "@/scripts/mixins/submitMixins.js";
import selectCheckBox from "@/components/base/selectCheckBox.vue";
import Popper from "vue3-popper";
import Icd10Code from "@/components/base/Icd10Code.vue";
import CptCode from "@/components/base/CptCode.vue";
import Select2 from "@/components/base/formFields/select2.vue";
import SelectBox from "@/components/base/formFields/selectBox.vue";
import SelectBoxStatic from "@/components/base/formFields/SelectBoxStatic.vue";
import FormBtn from "@/components/base/formFields/formBtn.vue";
import FileUploadMultiple from "@/components/base/formFields/FileUploadMultiple.vue";
import { SmartTagz } from "smart-tagz";
import "smart-tagz/dist/smart-tagz.css";
import { warn } from 'vue-demi';
import Search from "@/components/Search.vue";
import DataTableServer from "@/components/base/DataTableServer.vue";

export default {
	mixins: [permissionMixins, submitMixins],
	components: {
		selectCheckBox,
		Autocomplete,
		Popper,
		SelectBoxStatic,
		SelectBox,
		Select2,
		Icd10Code,
		CptCode,
		FormBtn,
		FileUploadMultiple,
		SmartTagz,
		Search,
		DataTableServer
	},
	data() {
		return {
			billing_status_to_change: null,
			select_all_claim: false,
			list: [],
			loader: false,
			emaillist: [],
			total_list: '',
			start: 0,
			end: 20,
			step: 20,
			steps: '',
			subject: "",
			message: "",
			file_name: "",
			selected_email_template: '',
			customSmsTool: '',
			subjectline: '',
			body_content: '',
			template_title: '',
			patientIds: [],
			full_name: [],
			removedata: [],
			patientid: '',
			is_new_template: false,
			is_edit_template: false,
			is_bluk_email: false,
			patientemail: [],
			patient_portal: [
				{ value: "1", title: "Activated" },
				{ value: "-1", title: "Not Activated" },
			],
			pending_documents: [
				{ value: "1", title: "Identification" },
				{ value: "2", title: "insurance card" },
			],
			gender: [
				{ value: "Male", title: "Male" },
				{ value: "Female", title: "Female" },
				{ value: "Others", title: "Others" },
				{ value: "Unknown", title: "Unknown" },
				{ value: "Declined to specify", title: "Declined to specify" },
			],
			credit_card: [
				{ value: "-1", title: "No" },
				{ value: "1", title: "Yes" },
			],
			insurance_card: [
				{ value: "0", title: "No" },
				{ value: "1", title: "Yes" },
			],
			insertImageSettings: {
				path: process.env.VUE_APP_API_BASE_URL.substr(0, process.env.VUE_APP_API_BASE_URL.lastIndexOf('/')) + "/storage/attach_file/",
				saveUrl: process.env.VUE_APP_API_BASE_URL + "/support/file/store",
				display: 'inline'
			},
		toolbarSettings: {
			items: ['Bold', 'Italic', 'Underline', 'StrikeThrough', 'FontName', 'FontSize', 'Formats', 'Alignments', 'OrderedList', 'UnorderedList', 'Outdent', 'Indent', 'CreateLink', 'Image', 'ClearFormat', 'Undo', 'Redo', {
				tooltipText: 'Insert Symbol',
				undo: true,
				click: this.cutomFieldClick.bind(this),
				template: `<select id="customTool" class="customfill">
					<option value="">Custom Fields</option>
					<option value="{{patient.full_name}}">Full Name</option>
					<option value="{{patient.first_name}}">First Name</option>
					<option value="{{patient.last_name}}">Last Name</option>
					<option value="{{patient.email}}">Email</option>
					<option value="{{patient.cell_phone}}">Cell Phone</option>
					<option value="{{patient.date_of_birth}}">Date Of Birth</option>
					</select>`,
			}]
		},
		pasteCleanupSettings: {
			prompt: true,
			plainText: false,
			keepFormat: false,
			deniedTags: ['a'],
			deniedAttrs: ['class', 'title', 'id'],
			allowedStyleProps: ['color', 'margin', 'font-size']
		},
		states: [],
		cpt: [],
		email_template_id: 0,
		problemCodeList: [],
		displayDiv: false,
		select_box_key: 0,
		date: moment().subtract(1, 'months').format("MM-DD-YYYY") + ' - '
			+ moment(new Date()).format("MM-DD-YYYY"),
		selected_data: [],
		update_error_message: null,
		update_batch_error_message: null,
		path: process.env.VUE_APP_KB_DOCUMENT,
		searchKey: 0,
		key: 0,
		list:[],
		}
		},
		created() {
			this.email_template();
		},
		computed: {
			filter() {
				return this.$store.state.PatientContactReport.filter;
			}
		},
		watch: {
            '$store.state.PatientContactReport.list':{
                immediate: true,
                handler() {
                    this.list=this.$store.state.PatientContactReport.list;
                }
            }
        },
		methods: {
		reset() {
			this.searchKey = this.searchKey + 1;
		},
		removelist(obj){
			this.full_name=obj;
			let getname="";
			this.removedata.forEach(element => {
				if (!this.full_name.includes(element)) {
					getname=element;
					this.list.forEach(element => {
						let name=element['first_name']+" "+element['last_name'];
						if (getname==name){
							let id=element['patient_id'].toString();
							this.patientIds.splice(this.patientIds.indexOf(id), 1);
							// console.log(this.patientIds);
						}
					});
				}
			});
		},
		bluk_pp_active_llink(){
				if(this.full_name.length>0){
					axios.post('reports/bulk_pp-active',{'patient_ids':this.patientIds})
						.then((response) => {
							this.loader = false;
							if(response.status==200){
								this.$swal.fire('Email Action in Progress',
								"Sending emails may take a few minutes. You will receive a notification when it's done. You can safely log out without interrupting the process.",
								'success');
							}
					});
				} else {
					this.moshaToast("please select petient", "error");
				}

		},
		openContentDialog(type) {
			if (type == "bulk") {
				this.is_bluk_email = true;
				if (this.full_name.length > 0) {
					$('#myContentModal').modal('show');
				} else {
					this.moshaToast("please select petient", "error");
				}
			}
			else if (type == "create") {
				$('#myContentModal').modal('show');
			}

		},
		downloadexcel() {
            axios.post("reports/patient-report-xsl",{"list": this.list, 'export_type' : "excel"})
                    .then((response) => {
                        let blob = new Blob([response.data.data], {
                            type: 'application/pdf'
                        })
                        let link = document.createElement('a')
                        link.href = response.data.data
                        link.download = 'patients.xlsx'
                        link.click()
                        this.moshaToast("Download XSL successfully", "success");
                    });
		},
		changeTemplate() {
			if (this.selected_email_template) {
				axios.get('email-template/edit/' + this.selected_email_template)
					.then((response) => {
						this.$refs.editorObj.ej2Instances.value = response.data.data.body_content;
						this.subject = response.data.data.subject_line;
						this.email_template_id = response.data.data.id;
						this.template_title = response.data.data.template_name;
					});
			}
			this.is_edit_template = true;
		},
		closeModal() {
			$('#checkAll').prop('checked', false);
			this.is_bluk_email = false;
			this.is_edit_template = false;
			this.full_name = [];
			this.patientIds = [];
			this.$refs.editorObj.ej2Instances.value = "";
			this.subject = "";
			this.email_template_id = "";
			this.template_title = "";
			this.selected_email_template = "";
		},
		email_template() {
			axios.get('/email-template/listByCategory/patient_contact')
				.then((response) => {
					this.emaillist = response.data.data
				});
		},
		sendEmail() {
			this.loader = true;
			let content = this.$refs.editorObj.ej2Instances.value
			axios.post('reports/patient-send-email', { "body_subject": this.subject, "body_content": content, 'patient_ids': this.patientIds })
				.then((response) => {
					this.loader = false;
					if (response.data.status == "success") {
						$('#myContentModal').modal('hide');
						this.$swal.fire('Email Action in Progress',
						"Sending emails may take a few minutes. You will receive a notification when it's done. You can safely log out without interrupting the process.",
						'success');
						this.closeModal();

					}
				});


		},
		imageUploading(args) {
			console.log(this.insertImageSettings);
			var token = localStorage.getItem("authToken");
			this.file_name = Date.now() + "_" + (Math.random() + 1).toString(36).substring(7) + "." + args.fileData.type;
			console.log(this.file_name);
			args.fileData.name = this.file_name;
			args.fileData.id = this.file_name;
			args.currentRequest.setRequestHeader("Authorization", `Bearer ${token}`);
			args.currentRequest.setRequestHeader("File-Name", this.file_name);
		},
		cutomFieldClick: function (th) {
			this.$refs.editorObj.ej2Instances.executeCommand('insertText', $('#customTool').val(), { undo: true });
		},
		saveDialogContent() {
			var data = this.$refs.editorObj.ej2Instances.value;
			if (this.email_template_id > 0) {
				axios.post('/email-template/update', { 'template_category': 'patient_contact', 'subject_line': this.subject, 'body_content': data, 'template_name': this.template_title, 'id': this.email_template_id })
					.then((response) => {
						this.loader = false;
						if (response.data.status=="success") {
							$('#myContentModal').modal('hide');	
							this.moshaToast("Update email template successfully.", "success");
						// setTimeout(function () { location.reload() }, 3000);
						
						}
				});	
			}else if (this.email_template_id == 0) {
				axios.post('/email-template/store', { 'template_category': 'patient_contact', 'subject_line': this.subject, 'body_content': data, 'template_name': this.template_title })
					.then((response) => {
						this.emaillist = response.data.data;
						$('#myContentModal').modal('hide');
						this.moshaToast("Save email template successfully.", "success");
						
						// setTimeout(f/unction () { location.reload() }, 3000);
						// console.error(this.emaillist);
					});
			}
			this.closeModal();
		},
		checkall() {
			this.patientIds = [];
			var checked = $('#checkAll').is(':checked');
			if (checked == true) {
				var $this = this;
				$('.myCheckbox').prop('checked', true);
				$('.myCheckbox').each(function () {
					var patient_id = $(this).val()
					var full_name = $(this).attr('data-patient_name')
					$this.full_name.push(full_name);
					$this.patientIds.push(patient_id.toString());
				});
			}
			else {
				$('.myCheckbox').prop('checked', false);
			}
			this.removedata = this.full_name;
		},
		select(patient_id, event, first_name, last_name) {
			let id = patient_id.toString();
			let name = first_name + ' ' + last_name;
			if (!event.target.checked) {
				this.patientIds.splice(this.patientIds.indexOf(id), 1);
				this.full_name.splice(this.full_name.indexOf(name), 1);
				$('#checkAll').prop('checked', false);
			}
			else {
				this.full_name.push(name);
				this.patientIds.push(id.toString());
				}
				this.removedata=this.full_name;
		},	
		filter_list() {
			this.key++;
		},
		clear_filter() {
			window.location.reload();
			// this.filter.zip_code = "";
			// this.filter.min_age = "";
			// this.filter.max_age = "";
			// this.filter.state_id = "";
			// this.filter.cpt_id = "";
			// this.filter.gender = "";
			// this.filter.visit = "";
			// this.filter.credit_card = "";
			// this.filter.problem_id = "";
			// this.filter.insurance_card = "";
			// this.filter.chief_complaint_id ="";
			// this.$refs.autocomplete.searchText="";
			// this.filter.patient_portal_id = "";
			// this.filter.pending_documents_id = "";
			// this.filter.appointment_daterange=moment().subtract(1, 'months').format("MM-DD-YYYY")+' - '+ moment(new Date()).format("MM-DD-YYYY"),
			// this.filter.join_daterange=moment().subtract(1, 'months').format("MM-DD-YYYY")+' - '+ moment(new Date()).format("MM-DD-YYYY"),   
			// this.filter.appointment_start_date = moment().subtract(1, 'months').format("YYYY-MM-DD");
			// this.filter.appointment_end_date = moment(new Date()).format("YYYY-MM-DD");
			// this.filter.join_start_date = moment().subtract(1, 'months').format("YYYY-MM-DD");
			// this.filter.join_end_date = moment(new Date()).format("YYYY-MM-DD");
			// this.filter_list();
		},
			problemSelectedValue(obj) {
				console.log(obj);
				this.filter.problem_id = obj.code;
				this.$refs.autocomplete.searchText = obj.description;
				this.$emit("update:modelValue", obj.code);
   			 },
			inputCode(obj) {
				if (obj.length > 1) {
					this.$refs.autocomplete.$el.classList.add("selectloader");
					axios.get("/json_autocomplete/icd-code", {
						params: { term: obj },
						}).then((response) => {
						this.problemCodeList = response.data.data.map((problem) => {
						return { id: problem.id, name: "<b>"+problem.code+"</b><br>"+problem.description,code:problem.code,description:problem.description }
						});
						this.$refs.autocomplete.$el.classList.remove("selectloader");
						this.form.problem = obj;
					});
				}
				if (obj.length == 0) {
					this.problemCodeList = [];
				}
			},
			onRangeSelect() {
				this.filter.appointment_daterange = document.getElementById("appointment_date_range").value;
				this.filter.join_daterange = document.getElementById("join_date_range").value;
				var daterange="";
				if (this.filter.appointment_daterange!="") {
					daterange=this.filter.appointment_daterange;
					if(daterange != ''&& daterange != null){
					var date = daterange.split(' - ');
					this.filter.appointment_start_date = moment(String(date[0])).format('YYYY-MM-DD');
					this.filter.appointment_end_date = moment(String(date[1])).format('YYYY-MM-DD');
				}
			}
			else {
				this.filter.appointment_start_date = null;
				this.filter.appointment_end_date = null;
			}
			if (this.filter.join_daterange != "") {
				daterange = this.filter.join_daterange;
				if (daterange != '' && daterange != null) {
					var date = daterange.split(' - ');
					this.filter.join_start_date = moment(String(date[0])).format('YYYY-MM-DD');
					this.filter.join_end_date = moment(String(date[1])).format('YYYY-MM-DD');
				}
			}
			else {
				this.filter.join_start_date = null;
				this.filter.join_end_date = null;
			}
		},
	},
	mounted(){
		var $this = this;
		document.body.addEventListener("click", function (evt) {
			if ($(evt.target).closest('.selectCheckBox').length) {
			} else {
				$this.displayDiv = false;
				$this.select_box_key = $this.select_box_key + 1;
			}
		});
		this.date = moment().subtract(1, 'months').format("MM-DD-YYYY") + ' - '
			+ moment(new Date()).format("MM-DD-YYYY");
	},
	async beforeCreate() {
		var $this = this;
		this.loader = false;
		axios.post("/json_autocomplete/231/state")
			.then((response) => {
				$this.states = response.data.data.map((state) => {
					return { id: state.id, text: state.name };
				});
			});

	},
}
</script>
<style>
.select2-container {
	width: 100% !important;
}
.swal2-container{position:fixed !important;}
</style>