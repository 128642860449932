<template>
    <div class="mdc-tab">
        <div class="tab-content">
            <div class="tab-pane active" id="billingSummary" role="tabpanel">
                <div class="box box-solid mb-0">
                    <div class="box-header with-border bg-secondary">
                        <h2 class="black-color m-0 fs-20" id="creditpayment">Payment</h2>
                    </div>
                    <div class="box-body text-dark">
                        <form @submit.prevent id="paymentForm" method="post">
                            <div style="margin-bottom: 10px; color: #c0392b;" v-if="serverError">{{ serverError }}</div>
                            <input type="hidden" name="appointment_id" v-model="refund_frm.appointment_id">
                            <div class="form-group">
                                <label for="name" class="form-label">Paid Amount</label>
                                <div class="input-group">
                                    <span class="input-group-addon">$</span>
                                    <input type="text" class="form-control" :readonly="true" v-model="refund_frm.paid_amount" placeholder="Paid Amount"/>
                                    <div
                                        class="invalid-feedback"
                                        style="display:block;"
                                        v-for="(error, index) of v$.refund_frm.paid_amount.$errors"
                                        :key="index"
                                        >
                                        {{ error.$message }}
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="form-label">Refund Amount</label>
                                <div class="input-group">
                                    <span class="input-group-addon">$</span>
                                    <input type="text" class="form-control" v-model="refund_frm.refund_amount" v-on:keypress="numbersOnly" placeholder="Enter Amount" @blur="payTypeChange" />
                                    <div
                                        class="invalid-feedback"
                                        style="display:block;"
                                        v-for="(error, index) of v$.refund_frm.refund_amount.$errors"
                                        :key="index"
                                        >
                                        {{ error.$message }}
                                    </div>
                                    <div class="invalid-feedback" style="display:block;">{{refund_amt_error}}</div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="form-label">Refund Reason</label>
                                <div class="input-group">
                                    <textarea name="refund_reason" id="refund_reason"  rows="3" v-model="refund_frm.refund_reason" class="form-control"   placeholder="Enter Refund Reason" @blur="payTypeChange"></textarea>
                                    <div
                                        class="invalid-feedback"
                                        style="display:block;"
                                        v-for="(error, index) of v$.refund_frm.refund_reason.$errors"
                                        :key="index"
                                        >
                                        {{ error.$message }}
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="form-label">Payment Method</label>
                                <select class="form-select form-control" disabled="true" v-model="refund_frm.payment_type" id="payment_type" @change="payTypeChange" >
                                    <option value="">- Select Payment Type -</option>
                                    <option label="Cash" value="Cash" >Cash</option>
                                    <option label="Check" value="Check">Check</option>
                                    <option label="Credit Card" value="Credit Card">Credit Card</option>
                                    <option label="Other" value="Other">Other</option>
                                    <option :label="'Wallet ('+$filters.toUSD($store.state.patientDetail.wallet_amount)+')'" value="Wallet">Wallet ({{ $filters.toUSD(this.$store.state.patientDetail.wallet_amount) }}) </option>
                                </select>
                                <div class="invalid-feedback" style="display:block;" v-if="wallet_error != ''">{{ wallet_error }}</div>
                                <div
                                    class="invalid-feedback"
                                    style="display:block;"
                                    v-for="(error, index) of v$.refund_frm.payment_type.$errors"
                                    :key="index"
                                    >
                                    {{ error.$message }}
                                </div>
                            </div>
                            <div class=""  v-show="refund_frm.payment_type == 'Other'">
                                <div class="form-group">
                                    <input type="text" class="form-control" v-model="refund_frm.other_payment_type" placeholder="Enter Other Payment">
                                    <div
                                        class="invalid-feedback"
                                        style="display:block;"
                                        v-for="(error, index) of v$.refund_frm.other_payment_type.$errors"
                                        :key="index"
                                        >
                                        Other payment is required.
                                    </div>
                                </div>
                            </div>
                            <div class=""  v-show="refund_frm.payment_type == 'Check'">
                                <div class="form-group">
                                    <label class="form-label">Check No.</label>
                                    <input type="text" class="form-control" v-model="refund_frm.check_trace" placeholder="Check No.">
                                    <div
                                        class="invalid-feedback"
                                        style="display:block;"
                                        v-for="(error, index) of v$.refund_frm.check_trace.$errors"
                                        :key="index"
                                        >
                                        Check No. required
                                    </div>
                                </div>
                            </div>
                            <div class="form-group" v-if="refund_frm.order_source == 'POS'">
                                <input
                                    type="checkbox"
                                    id="adjust_with_total_checkbox"
                                    class="filled-in chk-col-info"
                                    v-model="refund_frm.is_adjust_with_payable"
                                    />
                                <label for="adjust_with_total_checkbox" class="m-0 text-start">Remove from POS Amount ?</label>
                            </div>
                            <div class="form-group" v-if="refund_frm.order_source == 'POS' && refund_frm.is_adjust_with_payable">
                                <label for="name" class="form-label">Amount</label>
                                <div class="input-group">
                                    <span class="input-group-addon">$</span>
                                    <input type="text" class="form-control" v-model="refund_frm.adjust_with_payable" placeholder="Amount"/>
                                </div>
                            </div>
                            <!-- <div class="" v-if="refund_frm.payment_type == 'Credit Card'">
                                <div class="form-group">
                                    <select class="form-select form-control" v-model="refund_frm.cctoken_id">
                                        <option value="">- Select Credit-Card -</option>
                                        <option value="addNew">Add New Card</option>
                                        <option v-for="(item,index) in ccTokenData" :value="item.id" :data-val="item.id">{{item.card_label}}</option>
                                    </select>
                                    <div
                                        class="invalid-feedback"
                                        style="display:block;"
                                        v-for="(error, index) of v$.refund_frm.cctoken_id.$errors"
                                        :key="index"
                                        >
                                        Please select any option.
                                    </div>
                                </div>
                            </div>
                            <div class="form-group" v-show="refund_frm.payment_type == 'Credit Card' && (refund_frm.cctoken_id == 'addNew')">
                                <div style="display: none" id="customStyles">
                                    body{margin:0;}
                                    iframe{height: 100px;}
                                    #payment-form {border: 1px solid rgb(212, 221, 230);max-height: 130px;width: 100%;padding: 20px 10px;border-radius: 4px;margin: 0px;background: rgb(245, 248, 250);}
                                    #payment-form {color: #2d3c58;}
                                    #payment-form .input-label {color: #2d3c58;border-top: 1px solid #d4dde6;font-size:13px;text-align: center !important;}
                                </div>
                                <div id="card" style="height: 130px;"></div>
                                <div id="errorMessage" style="margin-bottom: 8px; color: #c0392b;"></div>
                                <input
                                    type="checkbox"
                                    id="use_credidcard_for_future_checkbox"
                                    class="filled-in chk-col-info"
                                    v-model="refund_frm.use_credidcard_for_future"
                                    />
                                <label for="use_credidcard_for_future_checkbox" class="m-0 text-start">Save for future use?</label>
                            </div> -->
                            
                        </form>
                        <button v-if="permissioncheck(this.$store.state.permission.create_update)" type="button" :disabled="!this.orderPaymentId" class="waves-effect waves-light btn  mb-5 me-1 waves-effect waves-light btn btn-info me-1" @click="handlePaymentPay">Refund</button>
                        <a v-if="permissioncheck(this.$store.state.permission.create_update)" href="javascript:void(0)" @click.prevent="resetForm" class="waves-effect waves-light btn mb-5 me-1 btn-danger">Clear</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="tab-pane animation-fade active" id="category-1" role="tabpanel">
            <div class="panel-group panel-group-simple panel-group-continuous mb-0" id="accordion2" aria-multiselectable="true" role="tablist">
            </div>
        </div>
    </div>
</template>
<script>
    import permissionMixins from "@/scripts/mixins/permission.js";
    import axios from "@/scripts/axios.js";
    import submitMixins from "@/scripts/mixins/submitMixins.js";
    import useVuelidate from "@vuelidate/core";
    import {
    	required,
    	helpers,
    	minLength,
    	maxLength,
    	numeric,
    	requiredIf,
    } from "@vuelidate/validators";
    export default {
    	props: {
            orderPaymentId: {
              type: Number,
              required: true
            },
        },
    	components: {
    		billing_order_payment_id : function() {
    		   return this.orderorderPaymentIdId;
    		 },
    	},
    	setup() {
    		return { v$: useVuelidate() };
    	},
    	mixins: [submitMixins,permissionMixins],
    	data() {
    		return {
    			is_production_env : process.env.VUE_APP_NODE_ENV == 'production',
    			refund_frm : {
    				order_id : '',
                    order_payment_id : this.orderPaymentId,
    				payment_type : '',
    				paid_amount : '',
    				check_trace : '',
    				other_payment_type : '',
                    is_adjust_with_payable : false,
                    adjust_with_payable : 0,
                    order_source : '',
    				patient_id : this.$store.state.patientDetail.patient_id,
    				patient_unique_id : this.$store.state.patientDetail.patient_unique_id,
                    refund_reason:'',
    			},
    			serverError: '',
    			purposeofpayment: '',
    			wallet_error: '',
                refund_amt_error: '',
    			embed: 0,
    		};
    	},
    	validations() {
    		return {
    			refund_frm: {
    				refund_amount: {
    					required: helpers.withMessage("Refund amount is required.", required),
    				},
    				refund_reason: {
    					required: helpers.withMessage("Refund Reason is required.", required),
    				},
    				paid_amount: {
    					required: helpers.withMessage("Amount is required.", required),
    				},
    				payment_type: {
    					required: helpers.withMessage("Payment Type is required.", required),
    				},
    				check_trace: {
    					required: requiredIf(function() {
    						return this.refund_frm.payment_type == 'Check' ? true : false;
    					}),
    				},
    				other_payment_type: {
    					required: requiredIf(function() {
    						return this.refund_frm.payment_type == 'Other' ? true : false;
    					}),
    				},
    			},
    		};
    	},
    	async created() {
            let localThis = this ;
            this.fetchOrderPaymentSummary(this.orderPaymentId);
    	},
    	methods: {
    		numbersOnly(evt) {
    			evt = (evt) ? evt : window.event;
    			var charCode = (evt.which) ? evt.which : evt.keyCode;
    			if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
    				evt.preventDefault();;
    			} else {
    				return true;
    			}
    		},
            handlePaybleBlur() {
                if (this.refund_frm.paid_amount <= 0) {
                    this.refund_frm.paid_amount = this.refund_frm.payable_amount;
                }
            },
    		payTypeChange() {
    		   /*if (this.refund_frm.payment_type == 'Wallet' && parseFloat(this.refund_frm.paid_amount) > parseFloat(this.$store.state.patientDetail.wallet_amount)) {
    		      this.wallet_error = 'Charge amount should not greater then wallet amount.';
    		   } else {
    		      this.wallet_error = '';
    		   }*/
               if (parseFloat(this.refund_frm.refund_amount) > parseFloat(this.refund_frm.paid_amount)) {
                   this.refund_amt_error = "Refund amount not allowed greater than paid amount.";
               } else {
                   this.refund_amt_error = '';
               }
    		},
    		fetchOrderPaymentSummary(orderPaymentId) {
    			if (orderPaymentId) {
    				axios
    					.post('payment/orderPaymentSummary',{order_payment_id : orderPaymentId , patient_id:this.$store.state.patientDetail.patient_id })
    					.then((response) => {
                            console.log(response.data.data);
                            this.refund_frm.paid_amount = response.data.data.total_refundable;
                            this.refund_frm.refund_amount = response.data.data.total_refundable;
                            this.refund_frm.payment_type = response.data.data.payment_type;
                            this.refund_frm.order_id = response.data.data.patient_billing_order_id;
                            this.refund_frm.adjust_with_payable = response.data.data.total_refundable;
                            this.refund_frm.order_source = response.data.data.order_source;
                            this.refund_frm.appointment_id = response.data.data.appointment_id;
    						/*this.billing_summary.order_source = response.data.data.order_source;
    						this.billing_summary.healthcare_amount = response.data.data.healthcare;
    						this.billing_summary.custom_amount = response.data.data.custom_charge;
    						this.billing_summary.payable_amount = response.data.data.payable_amount;
    						this.refund_frm.purpose_of_payment = response.data.data.purposeofpayment;
    						this.refund_frm.payable_amount = response.data.data.payable_amount;
    						this.billing_summary.received_amount = response.data.data.received_amount;
    						this.billing_summary.order_payment_info = response.data.data.order_payment_info;
    						let remaing_amt = (response.data.data.payable_amount - response.data.data.received_amount);
    						if (remaing_amt > 0) {
    							this.refund_frm.paid_amount = remaing_amt;
    						} else {
    							this.refund_frm.paid_amount = '';
    						}*/
    					});
    			}
    		},
    		resetForm : function() {
    			this.v$.$reset();
    			this.refund_frm.payment_type = '';
    			this.refund_frm.refund_reason = '';
                this.refund_frm.refund_amount = '';
    			this.refund_frm.check_trace = '';
    			this.refund_frm.other_payment_type = '';
    		},
    		handlePaymentPay: function() {
    			this.v$.$validate();
    			if (!this.v$.$error) {
                   this.serverError = '';
    				this.wallet_error = '';
                    this.refund_amt_error = '';
                    if (parseFloat(this.refund_frm.refund_amount)  > parseFloat(this.refund_frm.paid_amount)) {
                        this.refund_amt_error = "Refund amount not allowed greater than paid amount.";
                        return false;
                    }
                        this.$store.state.loader = true;
                        this.$store.state.patientDetail.renderComponent = false;
                        axios.post("payment/storePatientBillingRefund", this.refund_frm)
                        .then((response) => {
                            if (this.refund_frm.payment_type == 'Wallet') {
                                 this.$store.dispatch("refreshCurrentWallet");
                            }
                            this.$store.state.patientDetail.renderComponent = true;
                            this.$store.state.PaymentGatewayMigration.hidemodal = true;
                            this.resetForm();
                            this.$store.state.loader = false;
                            this.moshaToast(response.data.message, "success");
                            this.fetchOrderPaymentSummary(this.orderPaymentId);
                        })
                        .catch((error) => {
                            this.$store.state.loader = false;
                            this.serverError = error.response.data.message;
                        });
    			}
    		}
    	}
    }
</script>