<style>
	.box{-webkit-box-shadow: 0 3px 5px 1px rgb(0 0 0 / 5%);box-shadow: 0 3px 5px 1px rgb(0 0 0 / 5%);}
</style>
<template>
	<div class="container" style="max-width: 1130px;">
		<h1 class="text-center my-40 fs-28 fw-100" v-if="!server_error && !success_message" style="font-family: 'Nova Square', cursive;" >Welcome, You're scheduling appointment with {{this.appt_with_staff }}.</h1>
		<div class="box text-center" v-if="success_message">
			<div class="box-body py-50">
				<i class="fa fa-check fs-70 text-primary"></i>
				<div class="content-header">
					<h1 class="fw-300 fs-26">{{ this.success_message }}</h1>
				</div>
			</div>
		</div>
		<div class="box text-center" v-if="server_error">
			<div class="box-body py-50">
				<i class="fa fa-frown fs-70 text-danger"></i>
				<div class="content-header">
					<h1 class="fw-300 fs-36">{{ this.server_error }}</h1>
				</div>
			</div>
		</div>
		<div v-if="!server_error && !success_message">
			<div class="box overflow-hidden" id="first_widget">
				<div class="box-body py-30 px-20">
					<p class="ribbon-text"><span class="fs-14 d-block">Powered By</span><span>PureEHR</span></p>
					<div class="row justify-content-sm-center">
						<div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 be-1 border-secondary pe-lg-30">
							<div class="text-center mb-30 pb-30 bb-1 border-secondary">
								<img src="/images/purewellnessmedical.png" alt="Pure Wellness Medical" title="Pure Wellness Medical" class="img-fluid" style="width: 110px;">
							</div>
							<p class="fs-16">Thank you for your interest in Pure Wellness Medical. We offer services to OPTIMIZE Health and Wellness nationwide.</p>
							<p class="fs-16">Pure Wellness Medical specializes in Hormone Replacement Therapy (HRT), Innovative Weight Loss Solutions, Regenerative Medicine, Vitamin IV Therapy, Sexual Health, Advanced Aesthetics and Cosmetic Services, Fitness Coaching, Meal Delivery, and more!</p>
							<p class="fs-16">PWM Wellness Concierge offers members guided annual programs that include:</p>
							<ul class="fs-16">
								<li>Comprehensive lab review with our doctor</li>
								<li>Dedicated Wellness Coordinators</li>
								<li>Unlimited follow-up visits</li>
								<li>Medications delivered to your doorstep</li>
								<li>Membership perks and discounts</li>
							</ul>
						</div>
						<div class="col-12 col-sm-8 col-md-8 col-lg-7 col-xl-6 ps-lg-30">
							<div class="form-group w-p80">
								<label class="form-label">Select Location</label> <small class="text-danger">*</small>
								<select
									id="office_id"
									name="select"
									v-model="form.office_id"
									class="select2Single form-select form-control"
									@change="changeOffice()"
									>
									<option selected="selected" disabled="disabled" value="">Select Location</option>
									<option :value="item.id" v-for="item in this.office_list" :key="item.id"  :data-text="item.name">{{
										item.name
									}}</option>
								</select>
								<div
								class="invalid-feedback"
								style="display:block;"
								v-for="(error, index) of v$.form.office_id.$errors"
								:key="index"
								>
								{{ error.$message }}
							</div>
						</div>
					<ejs-calendar @change="changeDate" :value="dateVal" :min="minDate" :max="maxDate" style="width:100%;max-width: 100%;border: 0;border-radius: 5px;margin: 0 auto;"></ejs-calendar>
					<p>{{ this.dateValDisplay }}</p>
					<div class="spot-list smooth_scroll">
						<img src="images/loader.gif" v-if="show_slog_loader" class="mt-50 img-fluid" style="width:50px;margin-left:125px">
						<div class="row mx-1">
							<div class="col-3 col-sm-3 col-md-3 col-lg-3 px-1" v-for="item in avail_slot_list" v-if="avail_slot_list.length > 0">
								<a href="javascript:void(0)">
									<span class="calnhouresbtn" v-if="!item.show_confirm" @click="itemClick(item)">{{ item.schedule_time }}</span>
									<span class="calnhouresbtn confbtn" v-if="item.show_confirm">{{ item.schedule_time }}</span>
								</a>
							</div>
						</div>
						<a href="javascript:void(0)" v-if="avail_slot_list.length == 0 && this.show_slog_loader == false">No Time Slot Available.</a>
					</div>
					<div
					class="invalid-feedback"
					style="display:block;"
					v-for="(error, index) of v$.form.check_time.$errors"
					:key="index"
					>
					{{ error.$message }}
				</div>
				<div class="text-end">
					<button class="waves-effect waves-light btn btn-rounded btn-info" @click="confirmFirstWidget" :disabled="!this.form.check_time">Confirm</button>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="box overflow-hidden" id="second_widget">
	<div class="box-body py-30 px-20">
		<div class="backbutton" @click="backFirstWidget">
			<i class="fa fa-angle-left"></i>
		</div>
		<p class="ribbon-text"><span class="fs-14 d-block">Powered By</span><span>PureEHR</span></p>
		<div class="row justify-content-sm-center">
			<div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 be-1 border-secondary pe-lg-30">
				<div class="text-center mb-30 pb-30 bb-1 border-secondary">
					<img src="/images/purewellnessmedical.png" alt="Pure Wellness Medical" title="Pure Wellness Medical" class="img-fluid" style="width: 110px;">
				</div>
				<p class="fs-18 fw-600">{{ this.clinic_name }}</p>
				<p class="fs-16">{{ this.clinic_address }}</p>
				<p class="fs-16 mb-5"><i class="fa fa-clock-o fs-14 me-1"></i> {{ this.form.duration }} min</p>
				<!-- <p class="fs-16 mb-5"><i class="fa fa-phone fs-14 me-1"></i> Phone call</p> -->
				<p class="fs-16 mb-5"><i class="fa fa-calendar fs-14 me-1"></i> {{ this.form.check_time_display }}, {{ this.dateValDisplay }}</p>
			</div>
			<div class="col-12 col-sm-8 col-md-8 col-lg-7 col-xl-6 ps-lg-30">
				<div class="form-group">
					<label class="form-label">First Name</label> <small class="text-danger">*</small>
					<input id="First Name" type="text" class="form-control"  v-model="form.first_name" placeholder="First Name" autocomplete="off">
					<div
					class="invalid-feedback"
					style="display:block;"
					v-for="(error, index) of v$.form.first_name.$errors"
					:key="index"
					>
					{{ error.$message }}
				</div>
			</div>
			<div class="form-group">
				<label class="form-label">Last Name</label> <small class="text-danger">*</small>
				<input id="Last Name" type="text" class="form-control" v-model="form.last_name"  placeholder="Last Name" autocomplete="off">
				<div
				class="invalid-feedback"
				style="display:block;"
				v-for="(error, index) of v$.form.last_name.$errors"
				:key="index"
				>
				{{ error.$message }}
			</div>
		</div>
		<div class="form-group">
			<label class="form-label">Email</label> <small class="text-danger">*</small>
			<input id="Email" type="email" class="form-control" v-model="form.email" placeholder="Email" autocomplete="off">
			<div
			class="invalid-feedback"
			style="display:block;"
			v-for="(error, index) of v$.form.email.$errors"
			:key="index"
			>
			{{ error.$message }}
		</div>
	</div>
	<div class="form-group">
		<label class="form-label">Phone Number</label> <small class="text-danger">*</small>
		<input id="Phone Number" v-mask="'(000) 000-0000'"  type="text" class="form-control" v-model="form.cell_phone"  placeholder="Phone Number" >
		<div
		class="invalid-feedback"
		style="display:block;"
		v-for="(error, index) of v$.form.cell_phone.$errors"
		:key="index"
		>
		{{ error.$message }}
	</div>
</div>
<div class="form-group">
	<label class="form-label">Date of Birth</label> <small class="text-danger">*</small>
	<div class="availdate" :class="{ error: v$.form.date_of_birth.$errors.length }">
		<ejs-datepicker :value="date_of_birth" id="date_of_birth" v-model="v$.form.date_of_birth.$model" :format="dateFormat" placeholder="Select Date" @change="getdate($event)" name=
		"date_of_birth" :strictMode='strict' :max='maxVal' :showClearButton='false' :openOnFocus='true'>
	</ejs-datepicker>
	<div class="error-msg">
		<ul class="mb-0 mt-1 p-0 fs-14">
			<li class="d-block" v-for="(error, index) of v$.form.date_of_birth.$errors" :key="index">
				{{ error.$message }}
			</li>
		</ul>
	</div>
</div>
</div>
<div class="form-group">
	<label class="form-label">Notes</label>
	<textarea class="form-control" id="Notes" v-model="this.form.reason_for_visit" rows="3"></textarea>
</div>
<div class="text-end">
	<a href="javascript:void(0)" class="waves-effect waves-light btn btn-rounded btn-info" @click="submitScheduleEvent">Book Appointment</a>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</template>
<script>
	import axios from "@/scripts/axios.js";
	import submitMixins from "@/scripts/mixins/submitMixins.js";
	import useVuelidate from "@vuelidate/core";
	import moment from "moment";
	import { CalendarComponent } from "@syncfusion/ej2-vue-calendars";
	import $ from "jquery";
	export default {
		mixins: [submitMixins],
		setup: () => {
			return { v$: useVuelidate() };
		},
		data() {
			return {
				form : {
					app_coordinator_staff_id : '',
					is_provider_occupied : false,
					provider_id : '',
					office_id : '',
					my_clinic_id : '',
					first_name:'',
					last_name:'',
					email:'',
					cell_phone:'',
					duration:30,
					reason_for_visit:'',
					check_date : moment(new Date()).add(1, 'days').format("MM/DD/YYYY"),
					check_time : '',
					check_time_display : '',
				},
				dateValDisplay : moment(new Date()).add(1, 'days').format("dddd, MMMM D"),
				dateVal : moment(new Date()).format("MM/DD/YYYY"),
				minDate : moment(new Date()).add(1, 'days').format("MM/DD/YYYY"),
				maxDate : moment().add(1, 'M').endOf("month").format('MM/DD/YYYY'),
				maxVal: new Date(),
				dateFormat: 'MM-dd-yyyy',
				date_of_birth :'',
				show_slog_loader : false,
				avail_slot_list : [],
				office_list : [],
				success_message : '',
				server_error : '',
				clinic_name : '',
				appt_with_staff : '',
			}
		},
		components: {
			"ejs-calendar": CalendarComponent,
		},
		mounted() {
			$('.tophead').html("");
			$('#second_widget').hide();
			this.$store.commit("setErrors", {});
			this.$store.state.headerRequired = false;
			this.$store.state.footerRequired = false;
			this.fetchStaffDetails();
		},
		computed: {
			vuelidateExternalResults() {
				return this.$store.state.OnlineAppointment.vuelidateExternalResults;
			},
			validationRules() {
				return this.$store.state.OnlineAppointment.validationRules;
			},
		},
		validations() {
			return this.$store.state.OnlineAppointment.validationRules;
		},
		methods:{
			confirmFirstWidget() {
				$('#first_widget').hide();
				$('#second_widget').show();
			},
			backFirstWidget() {
				console.log("call");
				$('#first_widget').show();
				$('#second_widget').hide();
			},
			changeOffice() {
				this.fetchAvailibility();
			},
			fetchStaffDetails() {
				axios.post("pre-appointment/online-autofill-details", { token: this.$route.params.token })
				.then((response) => {
					if (response.data.data.length == 0) {
						this.server_error =  "Something went wrong!";
					} else {
						this.appt_with_staff = response.data.data.first_name+' '+response.data.data.last_name;
						document.title = `Book your Appointment with ${this.appt_with_staff}`;
						if (response.data.data.office_list.length > 0) {
							this.form.office_id = response.data.data.office_list[0].id;
							this.form.app_coordinator_staff_id = response.data.data.id;
							this.form.my_clinic_id = response.data.data.clinic_id;
							this.office_list = response.data.data.office_list;
							this.form.app_provider_id = response.data.data.primary_provider_id;
							this.clinic_name = response.data.data.organization_name;
							this.clinic_address = response.data.data.address;
							this.fetchAvailibility();
						} else {
							this.server_error =  "No office associated with this user.";
						}
					}
				}).catch((error) => {
					this.server_error = error.response.data.message;
				});
			},
			submitScheduleEvent() {
				this.v$.$validate();
				if (!this.v$.$error) {
					axios.post("/pre-appointment/online-pre-appointment-store", this.form)
					.then((response) => {
						this.success_message = 'Your appointment request has been registered successfully.';
					}).catch((error) => {
						this.moshaToast(error.response.data.message,'danger');
					});
				}
			},
			itemClick(item) {
				for (var list in this.avail_slot_list) {
					if (item.schedule_time == this.avail_slot_list[list].schedule_time) {
						this.avail_slot_list[list].show_confirm = true;
					} else {
						this.avail_slot_list[list].show_confirm = false;
					}
				}
				this.form.check_time = item.schedule_time;
				this.form.check_time_display = item.value;
				console.log(item);
			},
			changeDate(event) {
				this.form.check_date =  moment(event.value).format('YYYY-MM-DD');
				this.dateValDisplay =  moment(event.value).format("dddd, MMMM D");
				this.form.check_time = '';
				this.fetchAvailibility();
			},
			fetchAvailibility() {
				this.avail_slot_list = [];
				this.show_slog_loader = true;
				axios.post("/pre-appointment/online-suggestion-schedule", this.form)
				.then((response) => {
					this.avail_slot_list = response.data.data.slot_data;
					this.form.duration = response.data.data.duration;
					this.show_slog_loader = false;
				}).catch((error) => {
					this.show_slog_loader = false;
				});
			},
			getdate($event){
				if($('#date_of_birth').val() == '' && this.form.date_of_birth == '' ) {
					this.form.date_of_birth = null;
				} else {      
					this.form.date_of_birth = moment($event.value).format('YYYY-MM-DD');
				}  
			},
		}
	}
</script>