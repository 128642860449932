<template>
  <div class="content-wrapper">
    <div class="container-full">
      <div class="content-header">
        <div class="row justify-content-between">
          <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xs-6">
            <h1 class="heading1 my-5">Coupon Management</h1>
          </div>
          <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xs-6 text-sm-end">
            <router-link to="/coupon/add" class="waves-effect waves-light btn btn-info mb-1"><i class="fa fa-plus fs-14"></i> Coupon</router-link>
          </div>
        </div>
      </div>

      <section class="content">
        <div class="box">
            <div class="box-body">
                <div>
                    <data-table-server dataType="Coupon" dataMethod="post" >
                        <template v-slot="slotProps">
                            <td>{{ slotProps.item.coupon_code }}</td>
                            <td>{{ slotProps.item.amount }}</td>
                            <td>{{ slotProps.item.source }}</td>
                            <td>
                              <div class="d-flex">
                              <Popper arrow content="Edit Coupon" :hover="true" class="helptips cursor-pointer">
                                <a href="#" @click="edit(slotProps.item.id)"
                                    class="waves-effect waves-circle btn btn-circle btn-info btn-xs me-1"
                                    ><i class="fa fa-pencil"></i></a>
														  </Popper>
                              <Popper arrow content="Delete Coupon" :hover="true" class="helptips cursor-pointer">
                                <a href="#" @click="remove(slotProps.item.id)"
                                    class="waves-effect waves-circle btn btn-circle btn-danger btn-xs"
                                    ><i class="fa fa-trash"></i></a>
                              </Popper>
                              <Popper v-if="slotProps.item.is_sync == '0'" arrow content="Sync to PureWellness Medical" :hover="true" class="helptips cursor-pointer">
                                  <a @click="couponSync(slotProps.item.id)" href="#" class="waves-effect waves-circle btn btn-circle btn-success btn-xs"><i class="fa fa-refresh"></i></a>
                              </Popper>
                            </div>
                            </td>
                        </template>
                    </data-table-server>
                </div>
            </div>
        </div>
      </section>
    </div>
</div>
</template>
<script>
import axios from "@/scripts/axios.js";
import DataTableServer from "@/components/base/DataTableServer.vue";
import Popper from "vue3-popper";
import $ from 'jquery';
import submitMixins from "@/scripts/mixins/submitMixins.js";

export default {
  name: "Coupon",
  components: { DataTableServer, Popper },
  mixins: [submitMixins],
  methods:{
    remove(id) {
        this.$swal
        .fire({
            title: '<h2 class="mt-0">Are you sure?</h2>',
            text:
            "You want to delete these records? This process cannot be undone.",
            icon: "warning",
            dangerMode: true,
            showCancelButton: true,
            confirmButtonColor: "#ee3158",
            cancelButtonColor: "#bdbdbd",
            confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
            if (result.isConfirmed == true) {
              try {
                axios.post("/coupon/delete", { id: id }).then((response) => {
                  axios.post("coupon/sync-to-pwm",{coupon_id:id, 'mode':'auto', 'action':'delete'});

                    this.$swal
                    .fire(
                        "Deleted!",
                        "Coupon has been deleted.",
                        "success"
                        )
                    .then((result) => {
                        if (result.isConfirmed == true) {
                            location.reload();
                        }
                    });
                });
              } catch (err) {
                console.log(err)
              }
            }
        });
    },
    edit(id) {
        this.$router.push({ name: "CouponEdit", params: { id: id } })
    },
    async couponSync(id) {
      axios.post("coupon/sync-to-pwm",{'coupon_id':id, 'mode' : 'manual'})
				.then((response) => {
					if (response.data.code == 0) {
						this.moshaToast(response.data.msg, "success");
            location.reload();
					}else{
						this.moshaToast(response.data.msg, "error");
					}
				})
    },

  },
}
</script>
