import axios from "@/scripts/axios.js";

const patientDetail = {
    state:{
        patient_id: '',        
        patient_unique_id :'',
        patient_rcopia_id : '',
        renderComponent:true,
        patient_demographics_id:'',
        wallet_amount:0,
        reserve_wallet_amount:0,
        due_amount_sum:0,
        first_name:'',
        last_name:'',
        age:'',
        patientAvatar:'<img src="images/loader.gif" class="mt-20 img-fluid" style="width:25px;margin-left:25px">',
        key : 0,
        pp_id:'0',
        patient_portal_token: null,
        email:'',
        primary_provider_id:'',
        problem_fetched_date:'',
        allergy_fetched_date:'',
        medication_fetched_date:'',
        have_imported_data:'',
        gender:''
    },
    actions : {
        refreshCurrentWallet(context) {
            axios
              .post(`/wallet/current-wallet`,{ patient_id : context.state.patient_id })
              .then((response) => {
                if (response.data.data && response.data.data.wallet_amount) {
                    context.state.wallet_amount = response.data.data.wallet_amount;
                }
            })
        }
    }
}

export default patientDetail;