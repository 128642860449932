<template>
	<div class="content-wrapper">
		<div class="container-full">
			<div class="content-header">
				<h1 class="heading1 m-0">Contract Report <a href="#" data-bs-toggle="modal" data-bs-target="#contractdocument" title="Reference of Knowledge Base" v-on:click.prevent="reset()"> <i class="fa fa-question-circle fs-20"></i></a></h1>
			</div>
			<section class="content">
				<div class="box">
					<div class="box-body">
						<div class="row justify-content-between">
							<div class="col-12 col-sm-6 col-md-6 col-lg-4">
								<div class="form-group mb-5 availdate">
									<ejs-daterangepicker id="daterangepicker" :placeholder="waterMarkText"  :format="dateFormat" :openOnFocus='true' @change="getdate" :value="filter.date_range"></ejs-daterangepicker>
								</div>
							</div>
							<div class="col-12 col-sm-6 col-md-6 col-lg-4">
								<div class="form-group">
									<select class="form-control" v-model ="filter.status" @change="filterchange">
										<option value="all">All</option>
										<option value="Pending">Pending</option>
										<option value="Waiting For Signature">Waiting For Signature</option>
										<option value="Running">Running</option>
										<option value="Stoped">Cancelled</option>
										<option value="Completed">Expired</option>
									</select>
								</div>
							</div>
						</div>
						<div class="table-responsive">
							<data-table-server dataType="Contract" dataMethod="post" :key="key">
								<template v-slot="slotProps">
									<td>#{{slotProps.item.order_reference_no}}</td>
									<td	v-if="permissioncheck(this.$store.state.permission.Access_Patient_Chart)">
										<router-link :to="{	name: 'PatientDetails',	params: { id: slotProps.item.unique_id }}"	:title="'View Chart of ' + slotProps.item.first_name + '(' + slotProps.item.chart_id + ')'" class="fw-500">{{ slotProps.item.patinet_name }}</router-link>
									</td>
									<td>
										<span v-if="slotProps.item.status == 'Running'">Running  </span>  
										<span v-else-if="slotProps.item.status == 'Completed' " >Expired </span>  
										<span v-else-if="slotProps.item.status == 'Stoped' ">Cancelled  </span>  
										<span v-else>{{ slotProps.item.status }}</span>
									</td>
									<td>{{(slotProps.item.plan_name)}}</td>
									<td>${{slotProps.item.contract_amount}}</td>
									<td>{{ slotProps.item.contract_starts}}</td>
									<td>{{ slotProps.item.contract_ends}}</td>
								</template>
							</data-table-server>							
						</div>
					</div>
				</div>
			</section>
			<!--document model start-->
			<div id="contractdocument" class="modal fade" tabindex="-1" role="dialog"
				aria-labelledby="myModalLabel" aria-hidden="true">
				<div class="modal-dialog modal-md" style="max-width: 600px;">
					<div class="modal-content">
						<div class="modal-header">
							<h3 class="modal-title fw-900 mt-0">Reference of Knowledge Base</h3>
						</div>
						<div class="modal-body overflow-auto" style="max-height: 350px;">
							<table class="table table-bordered">
								<thead>
									<tr>
									<th scope="col">No.</th>
									<th scope="col">Knowledge Base Articles</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>1</td>
										<td> <a :href="path+'how-to-check-different-status-of-the-contract-with-a-specific-date-range-in-pureehr'" target="_blank">How To Check Different Status Of The Contract With A Specific Date Range In PureEHR?</a></td>
									</tr>
								</tbody>
							</table>
							<Search :key="searchKey"></Search>
						</div>
						<div class="modal-footer">
							<button type="button" @click="" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
						</div>
					</div>
				</div>
			</div>
			<!--document model end-->
		</div>
	</div>
</template>

<script>
import axios from "@/scripts/axios.js";
import submitMixins from "@/scripts/mixins/submitMixins.js";
import permissionMixins from "@/scripts/mixins/permission.js";
import Popper from "vue3-popper";
import Search from "@/components/Search.vue";
import DataTableServer from "@/components/base/DataTableServer.vue";
export default {
	mixins: [submitMixins,permissionMixins],
	data() {
		return {
			waterMarkText: "Select a Range",
			dateFormat: "MM/dd/yyyy",			
			path: process.env.VUE_APP_KB_DOCUMENT,
			searchKey:0,
			key:0,
		};
	},
	components: {
		Popper,Search,DataTableServer
	},
	computed: {
		filter(){
			return this.$store.state.Contract.filter; 
		}
	},
	methods:{
		reset() {
			this.searchKey ++;
		},
		getdate(){
			var date_range = $('#daterangepicker').val();
			this.filter.date_range = date_range;
			this.key++;
		},		
		filterchange(){
			this.key++;
		},    
	},  
	beforeCreate(){},
};
</script>
