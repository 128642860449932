<template>
	<div class="mdc-tab">
		<div class="tab-content">
			<div class="tab-pane active" id="billingSummary" role="tabpanel">
				<div class="row">
					<!-- <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4 col-xxl-5">
						<div class="box box-solid mt-20">
							<div class="box-header bg-secondary with-border">
								<h2 class="black-color m-0 fs-20">Summary</h2>
							</div>
							<div class="box-body text-dark">
								<ul class="billinglist black-color">
									<li>Health Care     <span> {{ $filters.toUSD(this.billing_summary.healthcare_amount) }}</span></li>
									<li>Custom Charges  <span>{{ $filters.toUSD(this.billing_summary.custom_amount) }}</span></li>
									<li>Vitamin B  <span>$10.00</span></li>
									<li>Vitamin A   <span>$45.00</span></li>
									<li>Hormones A  <span>$10.00</span></li>
									<li>Hormones B  <span>$20.00</span></li>
									<li>Discount     <span>{{ $filters.toUSD(0) }}</span></li>
									<li class="total-devider">Total  <span>{{ $filters.toUSD(this.billing_summary.payable_amount) }}</span></li>
									<li class="total-devider" v-if="this.billing_summary.received_amount > 0">Received <span>{{ $filters.toUSD(this.billing_summary.received_amount) }}</span></li>
									<li class="total-devider" v-if="this.billing_summary.received_amount > 0">Remaining     <span>{{ $filters.toUSD(this.billing_summary.payable_amount-this.billing_summary.received_amount) }}</span></li>
								</ul>
							</div>
						</div>
					</div> -->
					<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
						<div class="box box-solid">
							<div class="box-header with-border bg-secondary">
								<h2 class="black-color m-0 fs-20" id="creditpayment">Payment</h2>
							</div>
							
							<div class="box-body text-dark">
								<form @submit.prevent id="paymentForm" method="post">
									<div  style="margin-bottom: 10px; color: #c0392b;" v-if="serverError">{{ serverError }}</div>

									<div class="form-group">
										<label for="name" class="form-label">Billing Amount</label>
										<div class="input-group">
											<span class="input-group-addon">$</span>
											<input type="text" class="form-control" readonly="" v-model="payment_frm.payable_amount" placeholder="Billing Amount" />
											<div
											class="invalid-feedback"
											style="display:block;"
											v-for="(error, index) of v$.payment_frm.payable_amount.$errors"
											:key="index"
											>
											{{ error.$message }}
										</div>
									</div>
								</div>
								<div class="form-group" v-if="this.billing_summary.order_payment_info.length > 0">
									<label for="name" class="form-label">Received Amount</label>
									<div class="input-group">
										<span class="input-group-addon">$</span>
										<input type="text" class="form-control" readonly="" v-model="billing_summary.received_amount" placeholder="Billing Amount" />
									</div>
								</div>
								<div class="form-group">
									<label class="form-label">Payment Method</label>
									<select class="form-select form-control" v-model="payment_frm.payment_type" id="payment_type" @change="payTypeChange" >
										<option value="">- Select Payment Type -</option>
										<option label="Cash" value="Cash" >Cash</option>
										<option label="Check" value="Check">Check</option>
										<option label="Credit Card" value="Credit Card">Credit Card</option>
										<option label="Other" value="Other">Other</option>
										<option v-if="$store.state.patientDetail.wallet_amount > 0" :label="'Wallet ('+$filters.toUSD($store.state.patientDetail.wallet_amount)+')'" value="Wallet">Wallet ({{ $filters.toUSD(this.$store.state.patientDetail.wallet_amount) }}) </option>
									</select>
									<div class="invalid-feedback" style="display:block;" v-if="wallet_error != ''">{{ wallet_error }}</div>
									<div
									class="invalid-feedback"
									style="display:block;"
									v-for="(error, index) of v$.payment_frm.payment_type.$errors"
									:key="index"
									>
									{{ error.$message }}
								</div>
							</div>
							<div class=""  v-show="payment_frm.payment_type == 'Other'">
								<div class="form-group">
									<input type="text" class="form-control" v-model="payment_frm.other_payment_type" placeholder="Enter Other Payment">
									<div
									class="invalid-feedback"
									style="display:block;"
									v-for="(error, index) of v$.payment_frm.other_payment_type.$errors"
									:key="index"
									>
									Other payment is required.
								</div>
							</div>
						</div>
						<div class=""  v-show="payment_frm.payment_type == 'Check'">
							<div class="form-group">
								<label class="form-label">Check No.</label>
								<input type="text" class="form-control" v-model="payment_frm.check_trace" placeholder="Check No.">
								<div
								class="invalid-feedback"
								style="display:block;"
								v-for="(error, index) of v$.payment_frm.check_trace.$errors"
								:key="index"
								>
								Check No. required
							</div>
						</div>
					</div>
					<div class="" v-if="payment_frm.payment_type == 'Credit Card'">
						<div class="form-group">
							<select class="form-select form-control" v-model="payment_frm.cctoken_id">
								<option value="">- Select Credit-Card -</option>
								<option value="addNew">Add New Card</option>
								<option v-for="(item,index) in ccTokenData" :value="item.id" :data-val="item.id">{{item.card_label}}</option>
							</select>
							<div
							class="invalid-feedback"
							style="display:block;"
							v-for="(error, index) of v$.payment_frm.cctoken_id.$errors"
							:key="index"
							>
							Please select any option.
						</div>
					</div>
				</div>
				<div class="form-group" v-show="payment_frm.payment_type == 'Credit Card' && (payment_frm.cctoken_id == 'addNew')">
					<div style="display: none" id="customStyles">
						body{margin:0;}
						iframe{height: 100px;}
						#payment-form {border: 1px solid rgb(212, 221, 230);max-height: 130px;width: 100%;padding: 20px 10px;border-radius: 4px;margin: 0px;background: rgb(245, 248, 250);}
						#payment-form {color: #2d3c58;}
						#payment-form .input-label {color: #2d3c58;border-top: 1px solid #d4dde6;font-size:13px;text-align: center !important;}
					</div>
					<div id="card" style="height: 130px;"></div>
					<div id="errorMessage" style="margin-bottom: 8px; color: #c0392b;"></div>
					<input
					type="checkbox"
					id="use_credidcard_for_future_checkbox"
					class="filled-in chk-col-info"
					v-model="payment_frm.use_credidcard_for_future"
					/>
					<label for="use_credidcard_for_future_checkbox" class="m-0 text-start">Save for future use?</label>
				</div>
				<div class="form-group">
					<label class="form-label">Amount to Charge</label>
					<div class="input-group">
						<span class="input-group-addon">$</span>
						<input type="text" class="form-control" v-model="payment_frm.paid_amount" v-on:keypress="numbersOnly" placeholder="Enter Amount" />
						<div
						class="invalid-feedback"
						style="display:block;"
						v-for="(error, index) of v$.payment_frm.paid_amount.$errors"
						:key="index"
						>
						{{ error.$message }}
					</div>
				</div>
			</div>
		</form>
		<button v-if="permissioncheck(this.$store.state.permission.create_update)" type="button" :disabled="!appointment_id" class="waves-effect waves-light btn  mb-5 me-1 waves-effect waves-light btn btn-info me-1" @click="handlePaymentPay">Pay</button>
		<a v-if="permissioncheck(this.$store.state.permission.create_update)" href="javascript:void(0)" @click.prevent="resetForm" class="waves-effect waves-light btn mb-5 me-1 btn-danger">Clear</a>
	</div>
</div>
</div>
</div>
<div class="row" v-if="!is_production_env">
<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
	<div class="table-responsive">
		<table class="ql-table-blob table" data-aura-rendered-by="9911:0">
			<thead>
				<tr>
					<th>Card Type</th>
					<th>Number</th>
					<th>Expiry Date</th>
					<th>Security Code</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>Mastercard</td>
					<td>5439750001500347</td>
					<td>12/29</td>
					<td>999</td>
				</tr>
				<tr>
					<td>Visa</td>
					<td>4005550000000019</td>
					<td>12/29</td>
					<td>999</td>
				</tr>
				<tr>
					<td>Discover</td>
					<td>60011111111111117</td>
					<td>12/29</td>
					<td>999</td>
				</tr>
				<tr>
					<td>Diners</td>
					<td>36999999999999</td>
					<td>12/29</td>
					<td>999</td>
				</tr>
				<tr>
					<td>AMEX</td>
					<td>374255312721002</td>
					<td>12/29</td>
					<td>9999</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>
</div>
</div>
</div>
<div class="tab-pane animation-fade active" id="category-1" role="tabpanel">
	<div class="panel-group panel-group-simple panel-group-continuous mb-0" id="accordion2" aria-multiselectable="true" role="tablist">
	</div>
</div>
</div>
<div class="modal" id="viewReceipt" tabindex="-1">
	<div class="modal-dialog modal-md" style="max-width:75%">
		<div class="modal-content">
			<div class="modal-header">
				<button type="button" class="btn-close btn-xs" data-bs-dismiss="modal" aria-label="Close"></button>
			</div>
			<div class="modal-body">
				<vue-pdf-viewer height="1050px" :url="receipt_url" v-if="embed=0">
				</vue-pdf-viewer>
				<iframe :src="receipt_url" width="100%" height="500px" v-if="embed=1" />
				<img srd="images/1.gif" v-else> 
			</div>
		</div>
	</div>
</div>

</template>

<script>
	import VuePDFViewer from 'vue-instant-pdf-viewer';
	import permissionMixins from "@/scripts/mixins/permission.js";
	import axios from "@/scripts/axios.js";
	import submitMixins from "@/scripts/mixins/submitMixins.js";
	import useVuelidate from "@vuelidate/core";
	import {
		required,
		helpers,
		minLength,
		maxLength,
		numeric,
		requiredIf,
	} from "@vuelidate/validators";
	export default {
		props: {
	        Appointmentid: {
	          type: Number,
	          required: true
	        },
	    },
		components: {
			'vue-pdf-viewer': VuePDFViewer
		},
		setup() {
			return { v$: useVuelidate() };
		},
		mixins: [submitMixins,permissionMixins],
		data() {
			return {
				is_production_env : process.env.VUE_APP_NODE_ENV == 'production',
				appointment_id : '',
				appointment_number : '',
				appointmentData: [],
				hash: '',
				payment_frm : {
					payment_type : '',
					basic_amount : 0,
					discount_amount : 0,
					payable_amount : 0,
					paid_amount : '',
					check_trace : '',
					other_payment_type : '',
					cctoken_id : '',
					use_credidcard_for_future : false,
					patient_id : this.$store.state.patientDetail.patient_id,
					patient_unique_id : this.$store.state.patientDetail.patient_unique_id,
				},
				billing_summary : {
					healthcare_amount: 0,
					custom_amount: 0,
					basic_amount: 0,
					discount_amount: 0,
					payable_amount: 0,
					received_amount: 0,
					order_payment_info: [],
					order_info: {},
				},
				tokenpay : {},
				tokenPayReady: false, 
				checkingInterval: null,
				serverError: '',
				wallet_error: '',
				ccTokenData: [],
				receipt_url: '',
				embed: 0,
			};
		},
		validations() {
			return {
				payment_frm: {
					payable_amount: {
						required: helpers.withMessage("Billing amount is required.", required),
					},
					paid_amount: {
						required: helpers.withMessage("Amount is required.", required),
					},
					payment_type: {
						required: helpers.withMessage("Payment Type is required.", required),
					},
					check_trace: {
						required: requiredIf(function() {
							return this.payment_frm.payment_type == 'Check' ? true : false;
						}),
					},
					other_payment_type: {
						required: requiredIf(function() {
							return this.payment_frm.payment_type == 'Other' ? true : false;
						}),
					},
					cctoken_id: {
						required: requiredIf(function() {
							return this.payment_frm.payment_type == 'Credit Card' ? true : false;
						}),
					},
				},
			};
		},
		async created() {
			let localThis = this 
			this.checkingInterval = setInterval(function(){
				if (window.TokenPay) {
					localThis.tokenPayReady = true
				}
			}, 500);
			this.appointment_id = this.Appointmentid;	
			this.fetchPatientToken();
		},
		watch: {
			tokenPayReady: function(data) {
				if (data) { 
					clearInterval(this.checkingInterval) 
					this.initializeToken()
				}
			}
		},
		beforeCreate() {
			this.appointment_id = this.Appointmentid;	
			console.log(this.appointment_id);
			axios
				.post('payment/fetchApptBillingAmount',{appointment_id : this.appointment_id , patient_id:this.$store.state.patientDetail.patient_id })
				.then((response) => {
					this.billing_summary.healthcare_amount = response.data.data.healthcare_amount;
					this.billing_summary.custom_amount = response.data.data.custom_amount;
					this.billing_summary.payable_amount = response.data.data.payable_amount;
					this.payment_frm.payable_amount = response.data.data.payable_amount;
					this.billing_summary.received_amount = response.data.data.order_info ? response.data.data.order_info.received_amount : 0;
					this.billing_summary.order_payment_info = response.data.data.order_payment_info;
					this.billing_summary.order_info = response.data.data.order_info;
				});
		},
		mounted() {
			let bridgePayScript = document.createElement('script')
			bridgePayScript.setAttribute('src', process.env.VUE_APP_API_RECTANGLEHEALTH_TOKENPAY_URL)
			document.head.appendChild(bridgePayScript);
			console.log(process.env);

		},
		methods: {
			numbersOnly(evt) {
				evt = (evt) ? evt : window.event;
				var charCode = (evt.which) ? evt.which : evt.keyCode;
				if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
					evt.preventDefault();;
				} else {
					return true;
				}
			},
			payTypeChange() {
			   if (this.payment_frm.payment_type == 'Wallet' && this.payment_frm.paid_amount > this.$store.state.patientDetail.wallet_amount) {
			      this.wallet_error = 'Charge amount should not greater then wallet amount.';
			   } else {
			      this.wallet_error = '';
			   }
			},
			printReceipt (obj) {
				this.getSrc(obj.id);
				$("#viewReceipt").modal("show");
			},
			_UIdentiTy(){
				return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
					(c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
					);
			},
			async getSrc(id) {
				var masterId = id;
				const res = await fetch(process.env.VUE_APP_API_BASE_URL+'/payment/printApptReceipt', {
					method: 'POST',
					headers: {
						'Authorization' : 'Bearer '+localStorage.getItem('authToken'),
						'Content-Type' : 'application/json'
					},
					body: JSON.stringify({ payment_id: masterId }),
				});
				const blob = await res.blob();
				const urlObject = URL.createObjectURL(blob);
				this.receipt_url = urlObject;
				this.browserCheck();
			},
			browserCheck(){
				// Opera 8.0+
				var isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

				// Firefox 1.0+
				var isFirefox = typeof InstallTrigger !== 'undefined';

				// Safari 3.0+ "[object HTMLElementConstructor]" 
				var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));

				// Internet Explorer 6-11
				var isIE = /*@cc_on!@*/false || !!document.documentMode;

				// Edge 20+
				var isEdge = !isIE && !!window.StyleMedia;

				// Chrome 1 - 79
				var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

				// Edge (based on chromium) detection
				var isEdgeChromium = isChrome && (navigator.userAgent.indexOf("Edg") != -1);

				// Blink engine detection
				var isBlink = (isChrome || isOpera) && !!window.CSS;

				if (isChrome==true) {
					this.embed = 0;
				} else {
					this.embed = 1;
				}
			},
			fetchApptBillingAmt(apptId) {
				axios
				.post('payment/fetchApptBillingAmount',{appointment_id : apptId , patient_id:this.$store.state.patientDetail.patient_id })
				.then((response) => {
					this.billing_summary.healthcare_amount = response.data.data.healthcare_amount;
					this.billing_summary.custom_amount = response.data.data.custom_amount;
					this.billing_summary.payable_amount = response.data.data.payable_amount;
					this.payment_frm.payable_amount = response.data.data.payable_amount;
					this.billing_summary.received_amount = response.data.data.order_info ? response.data.data.order_info.received_amount : 0;
					this.billing_summary.order_payment_info = response.data.data.order_payment_info;
					this.billing_summary.order_info = response.data.data.order_info;
				});
			},
			initializeToken : function() {
				this.tokenpay = {};
				this.tokenpay = TokenPay(process.env.VUE_APP_API_RECTANGLEHEALTH_TOKEN);
				this.tokenpay.initialize({
					dataElement: '#card',
					errorElement: '#errorMessage',
					amountElement: 'amount',
					useACH: false,
				  //if displaying all 4 fields then useStyles=false, disableZip=false, disableCvv=false
				  //if displaying 3 out of 4 fields then useStyles=false, and set disableZip or disableCvv equal to true
				  //if displaying 2 out of 4 fields then useStyles=true, disableZip=true, disableCvv=true
				  useStyles: true,
				  disableZip: true,
				  disableCvv: false
				});
				console.log(this.tokenpay);
			},
			fetchPatientToken : function() {
				axios
				.post('/payment/patient-cctoken',{patient_id:this.$store.state.patientDetail.patient_id})
				.then((response) => {
					this.ccTokenData = response.data.data;
				});
			},
			resetForm : function() {
				this.v$.$reset();
				this.payment_frm.payment_type = '';
				this.payment_frm.paid_amount = '';
				this.payment_frm.check_trace = '';
				this.payment_frm.other_payment_type = '';
				this.payment_frm.cctoken_id = '';
				this.payment_frm.use_credidcard_for_future = false;
			},
			handlePaymentCapture : function() {
				var $this = this;
				$this.$store.state.loader = true;
				this.payment_frm.appointment_id = $this.appointment_id;
				axios.post("payment/storePatientBillingPayment", this.payment_frm)
				.then((response) => {
					if (this.payment_frm.payment_type == 'Wallet') {
		                 this.$store.dispatch("refreshCurrentWallet");
		            }
					$this.resetForm();
					$this.$store.state.loader = false;
					$this.moshaToast(response.data.message, "success");
					$this.fetchApptBillingAmt($this.appointment_id);
					$this.fetchPatientToken();
					this.$store.state.PaymentGatewayMigration.hidemodal = true;
				})
				.catch((error) => {
					$this.$store.state.loader = false;
					$this.serverError = error.response.data.message;
				});
			},
			handlePaymentPay: function() {
				this.v$.$validate();
				if (!this.v$.$error) {
					this.serverError = '';
					this.wallet_error = '';
					this.$store.state.loader = true;
					var vm = this;
					if (this.payment_frm.payment_type == 'Credit Card' && this.payment_frm.cctoken_id == 'addNew') {
						this.tokenpay.createToken(function (result) {
							vm.payment_frm.token = result.token;
							vm.handlePaymentCapture();
						}, function (result) {
							vm.$store.state.loader = false;
						});
					} else if(this.payment_frm.payment_type == 'Wallet') {
	                  if (this.payment_frm.paid_amount > this.$store.state.patientDetail.wallet_amount) {
	                     this.wallet_error = 'Charge amount should not greater then wallet amount.';
	                     this.$store.state.loader = false;
	                  }  else {
	                     vm.handlePaymentCapture();
	                  }
	   				} else {
						vm.handlePaymentCapture();
					}
				}
			}
		}
	}
</script>