<template>
  <div>
    <div class="pageheading">
      <h2 class="heading2">{{ title }}</h2>
    </div>
    <div class="row">
        <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-2" v-if="this.show_lifestyle_menu">
            <life-style-header routefrom="CustomProcedures"></life-style-header>
        </div>
        <div v-bind:class="show_lifestyle_menu ? 'col-12 col-sm-12 col-md-9 col-lg-9 col-xl-10' : 'col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12' ">
            <div class="box">
              <div class="box-body">
                  <form novalidate>
                    <div class="form-group row">
                        <label class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end">Category</label>
                        <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3">
                            <div class="controls d-flex">
                                <select name="select" v-model="form.category" class="form-select form-control" @change="changeCategorySelect()">
                                    <option value="null" disabled="disabled">Select Category</option>
                                    <option :value="item.id" v-for="item in category_list" :key="item.id" :data-text="item.category_name">{{
                                      item.category_name
                                    }}</option>
                                </select>
                                <div class="waves-effect waves-light btn btn-info btn-xs pt-10 ms-1" id="addCategoryBtn" @click="addCategory">
                                    <i class="fa fa-plus fs-14"></i>
                                </div>
                                <div class="waves-effect waves-light btn btn-info btn-xs pt-10 ms-1" id="editCategoryBtn" @click="editCategory">
                                    <i class="fa fa-pencil fs-14"></i>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-8 col-md-8 offset-sm-4 offset-lg-0 col-lg-4 col-xl-5">
                          <Popper arrow content="it will be shown as a main category for product in IV form." hover="true" class="helptips cursor-pointer">
                            <i class="fa fa-info-circle"></i>
                          </Popper>
                        </div>
                    </div>
                    <!-- <input-field
                      title="Category Name"
                      v-model="form.category"
                      >
                    </input-field> -->

                    <input-field
                      title="Product Name / Code"
                      v-model="v$.form.code.$model"
                      :errors="v$.form.code.$errors"
                      :isRequired="true"
                      >
                    </input-field>

                    <input-field
                      title="Quantity / Units"
                      v-model="v$.form.qty.$model"
                      :errors="v$.form.qty.$errors"
                      maxlength="8"
                      :isRequired="true"
                    >
                    </input-field>

                    <input-field
                      title="Description"
                      v-model="v$.form.description.$model"
                      :errors="v$.form.description.$errors"
                      :isRequired="true"
                      toolTip="It will display as a hover message in IV form"
                    >
                    </input-field>

                    <text-area title="Note" v-model="form.note"> </text-area>

                    <!-- <check-box
                            title="Sales Tax Applicable"
                            v-model="form.tax_applicable"
                            label = "Is sales tax applicable to this service/product?"
                        ></check-box> -->

                    <touch-spin
                      id="price"
                      title="Price"
                      v-model="v$.form.price.$model"
                      :errors="v$.form.price.$errors"
                      :isRequired="true"
                    >
                    </touch-spin>

                    <touch-spin title="Price with Tax" v-model="form.price_with_tax">
                    </touch-spin>

                    <!-- <touch-spin title="Allowed Amount" v-model="form.allowed_amount">
                    </touch-spin> -->

                    <touch-spin title="Cost" v-model="form.cost"> </touch-spin>

                    <div class="form-group availdate row">
                      <label class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end">Duration</label>
                      <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3">
                        <div class="controls position-relative">
                          <ejs-numerictextbox format="###" id="Duration" ref="numeric_instance" :value="duration_value" :step="step" :min="min" :max="max"></ejs-numerictextbox>
                          <small class="form-text text-muted position-absolute" style="right: 60px;top: 2px;">Minutes</small>
                        </div>
                      </div>
                    </div>

                    <select-box-static
                      title="Type of Product"
                      v-model="v$.form.type_of_product.$model"
                      :errors="v$.form.type_of_product.$errors"
                      :option="type_of_product"
                      :isRequired="true"
                    ></select-box-static>

                    <div class="form-group availdate row">
                      <label class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end">Is favorite?</label>
                      <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3">
                        <div class="controls position-relative" style="padding-top: 5px;">
                          <ejs-checkbox :ref="is_favourite" name="is_favourite" :id="is_favourite" cssClass="e-info" :value="1" :checked="form.is_favourite" @change="update_is_favourite()"></ejs-checkbox>
                        </div>
                      </div>
                    </div>

                    <!-- Items included to this custom procedure -->
                    <div v-if="service_category.length > 0" class="form-group row" style="width: 100%; max-width: 1024px; margin: 20px auto; border: 1px solid #dedede; background: #eee; border-radius: 10px; padding: 20px 10px 10px;">
                      <div class="col-12 d-inline-block" style="margin-bottom: 15px;">
                        <b>Select services</b> 
                        <select v-model="form.service_category" class="form-select form-control col-4" style="display: inline-block; width: auto; margin-left: 20px;" @change="select_services_by_category($event)">
                          <option value="null">Select Service Category</option>
                          <option :value="item.id" v-for="item in service_category" :key="item.id" :data-text="item.name">
                            {{ item.name }}
                          </option>
                        </select>
                      </div>
                      
                      <div v-for="(lifess, index) in lifestyleservices" :key="index" class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 border-bottom" style="border-right: 1px solid #dee2e6; padding-bottom: 10px;">
                        <div class="col-1 d-inline-block">
                          <ejs-checkbox :ref="`lifess${index}obj`" name="lifess[]" :id="`lifess${index}objId`" cssClass="e-info" style="color: red" :value="`${lifess.id}`" @change="updateservices()" :checked="lifess.checked"></ejs-checkbox>
                        </div>
                        <div class="col-2 d-inline-block">{{ lifess.product_code }}</div>
                        <div class="col-6 d-inline-block">{{ lifess.product_name }}</div>
                        <div class="col-3 d-inline-block" style="padding-left: 5px;">${{ lifess.product_price }}</div>
                      </div>

                    </div>
                  </form>
                  <div class="row mb-10">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-9 text-center">
                      <form-btn
                        :title="btnname"
                        btnCls="btn-info"
                        @click="save(false)"
                      ></form-btn>
                      <router-link
                        to="/custom-procedures?lifestyle=true"
                        class="waves-effect waves-light btn me-1 btn-danger"
                        >Cancel</router-link>
                    </div>
                  </div>
              </div>
            </div>
        </div>
    </div>
  </div>

  <div
    id="addNewCategory"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title font-bold m-0">New Category</h3>
        </div>
         <div class="modal-body">
            <input type="hidden" id="category_id_modal" name="category_id" value="">
            <div class="form-group row">
              <label class="col-form-label col-12 col-sm-3 col-md-3 col-lg-4 text-sm-end mb-0">Category Name <small class="text-danger asterisksign" >*</small></label>
              <div class="col-12 col-sm-9 col-md-9 col-lg-8">
                <div class="controls">
                  <input id="category_name" v-model="category_name" type="text" class="form-control" placeholder="Category Name" autocomplete="off">
                  <!--v-if-->
                </div>
                <div class="error-msg">
                  <ul class="mb-0 p-0 fs-14" v-if="errorMessage"> {{ errorMessage }}</ul>
                </div>
              </div>
            </div>
            <text-area title="Description" v-model="category_description" labelClass="col-form-label col-12 col-sm-3 col-md-3 col-lg-4 text-sm-end mb-0" divClass="col-12 col-sm-9 col-md-9 col-lg-8" id="catDescription"> </text-area>
         </div>
         <div class="modal-footer">
           <button
             type="button"
             class="waves-effect waves-light btn btn-danger"
             data-bs-dismiss="modal"
           >
             Cancel
           </button>
           <form-btn title="Save" btnCls="btn-info save-modal-btn" @click="saveCategory(false)"></form-btn>
         </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputField from "@/components/base/formFields/InputField.vue";
import TextArea from "@/components/base/formFields/textArea.vue";
import CheckBox from "@/components/base/formFields/checkBox.vue";
import TouchSpin from "@/components/base/formFields/Touchspin.vue";
import SelectBoxStatic from "@/components/base/formFields/SelectBoxStatic.vue";
import FormBtn from "@/components/base/formFields/formBtn.vue";
import useVuelidate from "@vuelidate/core";
import submitMixins from "@/scripts/mixins/submitMixins.js";
import LifeStyleHeader from "@/components/Lifestyle/LifeStyleHeader.vue";
import axios from "@/scripts/axios.js";
import Popper from "vue3-popper";

import {
  helpers,
  required,
  email,
  minLength,
  maxLength,
  sameAs,
  numeric,
} from "@vuelidate/validators";

export default {
  mixins: [submitMixins],
  setup: () => {
    return { v$: useVuelidate() };
  },
  components: {
    InputField,
    FormBtn,
    TextArea,
    CheckBox,
    TouchSpin,
    SelectBoxStatic,
    LifeStyleHeader,
    Popper
  },
  computed: {
    form() {
      return this.$store.state.CustomProcedures.form;
    },
    vuelidateExternalResults() {
      return this.$store.state.CustomProcedures.vuelidateExternalResults;
    },
    validationRules() {
      return this.$store.state.CustomProcedures.validationRules;
    },
    title() {
      return this.$route.name === "customproceduresedit"
        ? "Edit Custom Procedure"
        : "New Custom Procedure";
    },
    btnname() {
      return this.$route.name === "customproceduresedit" ? "Update" : "Save";
    },
  },
  created() {
    this.fetchProcedureCategory();
  },
  mounted() {
    this.form.category = null;
    this.form.service_category = null;
    this.form.is_favourite = 0;
    this.changeCategorySelect();
  },
  async beforeCreate() {
    // clear form data since, the same data is being used for add and edit both
    let self = this;
    if (this.$route.name === "customproceduresadd") {
      this.$store.state.CustomProcedures.form = {};
      Object.assign(this.$store.state.CustomProcedures.form);
    } else if (this.$route.name === "customproceduresedit") {
      // If edit, get data from server
      await this.$store.dispatch("getEditData", {
        dataType: "CustomProcedures", // pass the module name
        formType: "form", // pass the propery name
        id: this.$route.params.id,
      });
      this.changeCategorySelect();
      this.form.lifestyleservices = [0];
      if(this.form.service_category) {
        this.form.service_category.split(",").forEach((sc, isc) => {
          this.form.service_category = sc;
        });
        this.select_services_by_category();
      }
      if(this.form.services) {
        this.form.lifestyleservices = this.form.services.split(",");
        for (var i = 0; i < this.form.lifestyleservices.length; i++ ) {
          this.form.lifestyleservices[i] = parseInt(this.form.lifestyleservices[i]);
        }
      }
      this.duration_value = this.form.duration;
    }

    axios.get('/IV_life_style/category/dropdownlist').then(function(res) { 
      console.log(res.data.data);
      self.service_category = res.data.data;
    });

    // axios.get('/life-style-service/list').then(function(res) {
		// 	self.lifestyleservices = res.data.data;

    //   if(self.form.lifestyleservices!=undefined) {
    //     for (var j = 0; j < self.lifestyleservices.length; j++ ) {
    //       var item = self.lifestyleservices[j];
    //       if (self.form.lifestyleservices.includes(item.id)) {
    //         self.lifestyleservices[j].checked = true;
    //       }
    //     }
    //   }
		// });

  },
  methods: {
    fetchProcedureCategory() {
        console.log("call inside");
        axios
          .post(`/custom-procedure/category-list`,{})
          .then((response) => {
              this.category_list = response.data.data;
          })
          .catch((error) => {
          });
    },
    saveCategory() {
      this.errorMessage = "";
      if (!this.category_name) {
        this.errorMessage = "category name is required.";
      } else {
        var reqParam = { 'category_name': this.category_name, 'category_description' : this.category_description };
        if ($("#category_id_modal").val()) {
          reqParam.category_id = parseInt($("#category_id_modal").val());
        }
        axios
          .post(`/custom-procedure/category-store`, reqParam)
          .then((response) => {
              this.category_name = '';
              this.category_description = '';
              this.moshaToast(response.data.message, 'success');
              $("#addNewCategory").modal("hide");
              this.fetchProcedureCategory();
          })
          .catch((error) => {
            if (error.response.status === 422) {
              this.errorMessage =error.response.data.data.category_name[0];
            } else {
              this.errorMessage = error.response.data.message;
            }
          });
      }
    },
    save(next) {
      next ? (this.saveAndNext = true) : (this.saveAndNext = false);
      this.form.duration = $('#Duration').val();
      if (this.$route.name === "customproceduresedit") {
        this.postForm("/custom-procedure/update", "CustomProcedures");
      } else {
        this.postForm("/custom-procedure/store", "CustomProcedures");
      }
    },
    formPostSuccess(data) {
      if (this.$route.name === "customproceduresedit") {
        this.moshaToast("Record updated successfully.", "success");
      } else {
        this.moshaToast("Record added successfully.", "success");
      }
      if (this.show_lifestyle_menu) {
          this.$router.push({ name: "customproceduresList", query: { lifestyle: this.show_lifestyle_menu } });
      } else {
          this.$router.push("/custom-procedures");
      }
    },
    updateservices() {
      this.form.lifestyleservices = [];
      var i = 0;
			this.lifestyleservices.forEach((ls) => {
				let checkboxobj = this.$refs[`lifess${i}obj`];
				if (checkboxobj.$el.checked) {
          this.form.lifestyleservices.push(ls.id);
				}
				i = i+1;
			});
    },
    async select_services_by_category(event) {
      let self = this;
      self.$store.state.loader = true;
      if(this.form.service_category!=null && this.form.service_category!='null' && this.form.service_category>0) {
        await axios.get('/IV_life_style/service/list/'+this.form.service_category).then(function(res) {
          self.lifestyleservices = res.data.data;
          if(event) {
            self.form.lifestyleservices = [];
          }
          if(self.form.lifestyleservices!=undefined) {
            for (var j = 0; j < self.lifestyleservices.length; j++ ) {
              var item = self.lifestyleservices[j];
              if (self.form.lifestyleservices.includes(item.id)) {
                self.lifestyleservices[j].checked = true;
                self.form.lifestyleservices.push(item.id);
              }
            }
          }
          self.$store.state.loader = false;
        });
      } else {
        self.form.lifestyleservices = [];
        self.lifestyleservices = [];
        self.$store.state.loader = false;
      }
    },
    update_is_favourite() {
      this.form.is_favourite = !this.form.is_favourite;
      console.log(this.form.is_favourite);
    },
    changeCategorySelect() {
      $("#editCategoryBtn").addClass('disabled');
      if (this.form.category > 0) {
        $("#editCategoryBtn").removeClass('disabled');
      }
    },
    resetCategoryModel(){
      $("#addNewCategory .modal-title").text('New Category');
      $("#category_id_modal").val('');
      $(".save-modal-btn").text('Save');
      this.category_name = '';
      this.category_description = '';
    },
    addCategory(){
      this.resetCategoryModel();
      $("#addNewCategory").modal("show");
    },
    editCategory(){
      var catId = this.form.category;
      if (catId > 0) {
        axios
          .get(`/custom-procedure/category/${catId}`,{})
          .then((response) => {
              var categoryDetail = response.data.data;
              this.category_name = categoryDetail.category_name;
              this.category_description = categoryDetail.category_description;

              $("#addNewCategory .modal-title").text('Edit Category');
              $(".save-modal-btn").text('Update');
              $("#category_name").val(categoryDetail.category_name);
              $("#catDescription").text(categoryDetail.category_description);
              $("#category_id_modal").val(categoryDetail.id);
              $("#addNewCategory").modal("show");
            })
          .catch((error) => {
          });
      } else {
        $("#addNewCategory").modal("hide");
        this.resetCategoryModel();
      }
    }
  },
  data() {
    return {
      duration_value:5,
      min: 0,
      max: 60,
      step: 5,
      saveAndNext: false,
      seen: "Save",
      consent_form: "true",
      type_of_product: [
        { value: "Service", title: "Service" },
        { value: "Product", title: "Product" },
      ],
      category_name:'',
      category_description:'',
      category_list:'',
      errorMessage:'',
      lifestyleservices:[],
      service_category: [],
      show_lifestyle_menu : this.$route.query.lifestyle ? true : false
    };
  },
  validations() {
    return this.$store.state.CustomProcedures.validationRules;
  },
};
</script>
