import {
  helpers,
  required,
} from "@vuelidate/validators";

const patientImplantableDevice = {
  namespaced: true,


  state: {
    listUrl: "/patient/:patient_unique_id/implantable-device/list/Active",
    list: [],
    tableData: {
      header: [
        'UDI Code',
         'GMDN PT Name',
         'Version or Model',
         'Manufactured Date',
         'Expiration Date',
         'Lot or Batch',
         'Serial Number',
         'HCT/P Identification Code',
         'MRI Safety Status',
         'Labeled Contains NRL',
         'Status',
         'Actions',
      ],
    },
    validationRules: {
        form: 
        {
            udi: {
                 required: helpers.withMessage('UDI is required.', required) ,
           },
           /*snomed_code: {
            required: helpers.withMessage('Snomed Code is required.', required) ,
            },
            snomed_description: {
              required: helpers.withMessage('Snomed Description is required.', required) ,
              },*/
           status: {
                required: helpers.withMessage('Status is required.', required) ,
             }
        },
    },
    vuelidateExternalResults: {
        form:
        {
            udi: '',
            status: '',
        }
    },
    form : {
        udi:"",
        status : 'Active',
        udi_display : {
            company_name:"",
            brand_name:"",
            gmdn_pt_Name:"",
            version_or_model:"",
            expiration_date:'',
            manufacturing_date:'',
            serial_number:'',
            device_hctp:'',
            labeled_contains_nrl:'',
            mri_sefety_status:'',
            lot_or_batch_no:'',
        },
        udi_detailed_data : {},
    }
  },
  getters: {
  },
  mutations: {},
  actions: {},
};

export default patientImplantableDevice;
