<template>
    <div class="mdc-tab">
        <div class="tab-content">
            <div class="tab-pane active" id="billingSummary" role="tabpanel">
                <div class="box box-solid">
                    <div class="box-header with-border bg-secondary">
                        <h2 class="black-color m-0 fs-20" id="creditpayment">Payment</h2>
                    </div>
                    <div class="box-body text-dark">
                        <form method="post">
                            <div class="form-group">
                                <label for="payment" class="form-label">Payment Type </label> <small class="text-danger asterisksign">*</small>
                                <div class="input-group">
                                    <input type="radio" id="Copay" v-model="payment_form.order_source" @change="paymentTypeChange('Copay')" name="order_source" value="Copay"  class="filled-in chk-col-info"/>
                                    <label for="Copay">Copay </label>
                                     <input type="radio" v-model="payment_form.order_source" id="CoInsurance" name="order_source" value="CoInsurance" class="filled-in chk-col-info" @change="paymentTypeChange('CoInsurance')" />
                                    <label for="CoInsurance">CoInsurance </label>
                                     <input type="radio" v-model="payment_form.order_source" id="Deductible" name="order_source" value="Deductible" class="filled-in chk-col-info" @change="paymentTypeChange('Deductible')" />
                                    <label for="Deductible">Deductible </label>
                                    <input type="radio" v-model="payment_form.order_source" id="PaidToPr" name="order_source" value="PaidToPr" class="filled-in chk-col-info" @change="paymentTypeChange('PaidToPr')" />
                                    <label for="PaidToPr">Paid To Patient </label>
                                    <input type="radio" v-model="payment_form.order_source" id="Other" name="order_source" value="Other" class="filled-in chk-col-info" @change="paymentTypeChange('Other')" />
                                    <label for="Other">Other </label>
                                </div>
                                <div class="invalid-feedback" style="display:block;" v-for="(error, index) of v$.payment_form.order_source.$errors" :key="index">
                                    {{ error.$message }}
                                </div>
                            </div>
                            <div class="form-group" v-if="payment_form.order_source=='Other'">
                                <label for="name" class="form-label">Reason for payment</label><small class="text-danger asterisksign">*</small>
                                <div class="input-group">
                                    <select class="form-select form-control"  v-model="payment_form.payment_for_code">
                                        <option value="" selected>- Select Reason -</option>
                                        <option v-for="(item,index) in patient_adjustment_reason_code" :key="index" :value="item"> {{item}} : {{claim_adjustment_codes[item]}} </option>
                                    </select>
                                    <div class="invalid-feedback" style="display:block;" v-for="(error, index) of v$.payment_form.payment_for_code.$errors" :key="index">
                                        {{ error.$message }}
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="name" class="form-label">Payable Amount</label>
                                <div class="input-group">
                                    <span class="input-group-addon">$</span>
                                    <input type="text" class="form-control" v-model="payment_form.payable_amount" readonly="true" placeholder="Payable Amount"/>
                                    <div class="invalid-feedback" style="display:block;" v-for="(error, index) of v$.payment_form.payable_amount.$errors" :key="index">
                                        {{ error.$message }}
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="name" class="form-label">Received Amount</label>
                                <div class="input-group">
                                    <span class="input-group-addon">$</span>
                                    <input type="text" class="form-control" v-model="payment_form.received_amount" readonly="true" placeholder="Received Amount" />
                                </div>                                
                            </div>                            
                            <div class="form-group">
                                <label class="form-label">Amount to Charge</label>
                                <div class="input-group">
                                    <span class="input-group-addon">$</span>
                                    <input type="text" v-model="payment_form.paid_amount" class="form-control" v-on:keypress="numbersOnly" placeholder="Enter Amount" />                                    
                                </div>
                                <div class="invalid-feedback" style="display:block;" v-for="(error, index) of v$.payment_form.paid_amount.$errors" :key="index">
                                    {{ error.$message }}
                                </div>
                            </div>                            
                            <div class="form-group">
                                <label class="form-label">Payment Method </label> <small class="text-danger asterisksign">*</small>
                                <select class="form-select form-control"  v-model="payment_form.payment_type" @change="payTypeChange">
                                    <option value="" selected>- Select Payment Method -</option>
                                    <option label="Cash" value="Cash" >Cash</option>
                                    <option label="Check" value="Check">Check</option>
                                    <option label="Credit Card" value="Credit Card">Credit Card</option>
                                    <option label="Other" value="Other">Other</option>
                                    <option v-if="$store.state.patientDetail.wallet_amount > 0" :label="'Wallet ('+$filters.toUSD($store.state.patientDetail.wallet_amount)+')'" value="Wallet">Wallet ({{ $filters.toUSD(this.$store.state.patientDetail.wallet_amount) }}) </option>
                                </select> 
                                <div class="invalid-feedback" style="display:block;" v-for="(error, index) of v$.payment_form.payment_type.$errors" :key="index">
                                    {{ error.$message }}
                                </div>
                            </div>
                            <div v-show="payment_form.payment_type == 'Other'">
                                <div class="form-group">
                                    <input type="text"  v-model="payment_form.other_payment_type" class="form-control" placeholder="Enter Other Payment">                                    
                                    <div class="invalid-feedback" style="display:block;" v-for="(error, index) of v$.payment_form.other_payment_type.$errors" :key="index">
                                        {{ error.$message }}
                                    </div>
                                </div>
                            </div>
                            <div class=""  v-show="payment_form.payment_type == 'Check'">
                                <div class="form-group">
                                    <label class="form-label">Check No.</label>
                                    <input type="text" class="form-control" v-model="payment_form.check_trace" placeholder="Check No.">
                                    <div class="invalid-feedback" style="display:block;" v-for="(error, index) of v$.payment_form.check_trace.$errors" :key="index">
                                        {{ error.$message }}
                                    </div>
                                </div>
                            </div>
                            <div class="" v-if="payment_form.payment_type == 'Credit Card'">
                                <div class="form-group">
                                    <select class="form-select form-control" v-model="payment_form.cctoken_id">
                                        <option value="">- Select Credit-Card -</option>
                                        <option value="addNew">Add New Card</option>
                                        <option v-for="(item,index) in ccTokenData" :value="item.id" :data-val="item.id" :disabled="item.is_expired == true" :key="index">{{item.card_label}}</option>
                                    </select>
                                    <div class="invalid-feedback" style="display:block;" v-for="(error, index) of v$.payment_form.cctoken_id.$errors" :key="index" > 
                                        Please select any option.
                                    </div>                                    
                                </div>
                            </div>
                            <div class="form-group" v-show="payment_form.payment_type == 'Credit Card' && (payment_form.cctoken_id == 'addNew')">
                                <div style="display: none" id="customStyles">
                                    body{margin:0;}
                                    iframe{height: 100px;}
                                    #payment-form {border: 1px solid rgb(212, 221, 230);max-height: 130px;width: 100%;padding: 20px 10px;border-radius: 4px;margin: 0px;background: rgb(245, 248, 250);}
                                    #payment-form {color: #2d3c58;}
                                    #payment-form .input-label {color: #2d3c58;border-top: 1px solid #d4dde6;font-size:13px;text-align: center !important;}
                                </div>
                                <div id="card" style="height: 130px;"></div>
                                <div id="errorMessage" style="margin-bottom: 8px; color: #c0392b;"></div>
                                <input
                                    type="checkbox"
                                    id="use_creditcard_for_future_checkbox"
                                    class="filled-in chk-col-info"
                                    v-model="payment_form.use_creditcard_for_future"
                                    />
                                <label for="use_creditcard_for_future_checkbox" class="m-0 text-start">Save for future use?</label>
                            </div>
                        </form>
                        <button v-if="permissioncheck(this.$store.state.permission.create_update)" type="button" class="waves-effect waves-light btn  mb-5 me-1 waves-effect waves-light btn btn-info me-1" @click="handlePaymentPay">Pay</button>
                    </div>
                </div>
                <div v-if="!is_production_env">
                    <div class="table-responsive">
                            <table class="ql-table-blob table" data-aura-rendered-by="9911:0">
                                <thead>
                                    <tr>
                                        <th>Card Type</th>
                                        <th>Number</th>
                                        <th>Expiry Date</th>
                                        <th>Security Code</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Mastercard</td>
                                        <td>5439750001500347</td>
                                        <td>12/29</td>
                                        <td>999</td>
                                    </tr>
                                    <tr>
                                        <td>Visa</td>
                                        <td>4005550000000019</td>
                                        <td>12/29</td>
                                        <td>999</td>
                                    </tr>
                                    <tr>
                                        <td>Discover</td>
                                        <td>60011111111111117</td>
                                        <td>12/29</td>
                                        <td>999</td>
                                    </tr>
                                    <tr>
                                        <td>Diners</td>
                                        <td>36999999999999</td>
                                        <td>12/29</td>
                                        <td>999</td>
                                    </tr>
                                    <tr>
                                        <td>AMEX</td>
                                        <td>374255312721002</td>
                                        <td>12/29</td>
                                        <td>9999</td>
                                    </tr>
                                </tbody>
                            </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="tab-pane animation-fade active" id="category-1" role="tabpanel">
            <div class="panel-group panel-group-simple panel-group-continuous mb-0" id="accordion2" aria-multiselectable="true" role="tablist">
            </div>
        </div>
    </div>
</template>
<script>
    import permissionMixins from "@/scripts/mixins/permission.js";
    import axios from "@/scripts/axios.js";
    import submitMixins from "@/scripts/mixins/submitMixins.js";
    import useVuelidate from "@vuelidate/core";
    import { required,helpers,requiredIf } from "@vuelidate/validators";
    export default {
    	props: {
            appointmentId: {
              required: true
            },            
        },
    	components: {},
    	setup() {
    		return { v$: useVuelidate() };
    	},
    	mixins: [submitMixins,permissionMixins],
    	data() {
    		return {
                ccTokenData:[],
    			is_production_env : process.env.VUE_APP_NODE_ENV == 'production',
    			appointment_id : this.appointmentId,
                patient_id : this.$store.state.patientDetail.patient_id,
    			payment_form : {
    				appointment_id : this.appointmentId,    				
    				patient_id : this.$store.state.patientDetail.patient_id,
                    payable_amount:0,
                    paid_amount:0,
                    received_amount:0,
                    payment_type:'',
                    check_trace:'',
                    other_payment_type:'',
                    use_creditcard_for_future : false,
                    cctoken_id:'',
                    order_source:null,
                    payment_for_code:'',
    			},
                publicKey : '',
                checkingInterval: null,
                claim_adjustment_codes: [],
                patient_adjustment_reason_code: [],
                tokenpay: {},
                tokenPayReady: false,
    		};
    	},
    	validations() {
    		return {
    			payment_form: {
                    order_source: {
    					required: helpers.withMessage("Payment Type is required.", required),
    				},
    				payable_amount: {
    					required: helpers.withMessage("Billing amount is required.", required),
    				},
    				paid_amount: {
    					required: helpers.withMessage("Amount is required.", required),
    				},
    				payment_type: {
    					required: helpers.withMessage("Payment Method is required.", required),
    				},
    				check_trace: {
    					required: requiredIf(function() {
    						return this.payment_form.payment_type == 'Check' ? true : false;
    					}),
    				},
    				other_payment_type: {
    					required: requiredIf(function() {
    						return this.payment_form.payment_type == 'Other' ? true : false;
    					}),
    				},
                    cctoken_id: {
                        required: requiredIf(function() {
                            return this.payment_form.payment_type == 'Credit Card' ? true : false;
                        }),
                    },
                    payment_for_code: {
                        required: requiredIf(function() {
    						return this.payment_form.order_source == 'Other' ? true : false;
    					}),
                    },
    			},
    		};
    	},        
    	async created() {
            let localThis = this;
            this.paymentTypeChange('Copay');
            const result =await this.getTpConfigByKey('RECTANGLEHEALTH','PUBLICKEY');
            if (result) {
                this.publicKey = result.data.PUBLICKEY;
                this.checkingInterval = setInterval(function(){
                    if (window.TokenPay) {
                        localThis.tokenPayReady = true
                    }
                }, 500);
            }
    	},
    	mounted() {            
            this.$store.dispatch("refreshCurrentWallet");
            let bridgePayScript = document.createElement('script')
            bridgePayScript.setAttribute('src', process.env.VUE_APP_API_RECTANGLEHEALTH_TOKENPAY_URL)
            document.head.appendChild(bridgePayScript);
    	},
    	methods: {
            paymentTypeChange(type){
                if(this.patient_id!=null && this.appointment_id!=null){
                    axios.post("/payment/get-copay-coins-details",{appointment_id:this.appointment_id,patient_id:this.patient_id,type:type})
                    .then((response) => {                
                        this.payment_form.payable_amount = response.data.data.payable_amount
                        this.payment_form.received_amount = response.data.data.received_amount
                        this.payment_form.paid_amount = response.data.data.payable_amount
                        if(response.data.data.patient_adjustment_reason_code != null){
                            this.patient_adjustment_reason_code = response.data.data.patient_adjustment_reason_code.split(',')
                        }
                        this.claim_adjustment_codes = response.data.data.claim_adjustment_codes
                    });
                    this.fetchPatientToken();
                }
            },
            payTypeChange() {
                if (this.payment_form.payment_type == 'Wallet' && parseFloat(this.payment_form.paid_amount) > parseFloat(this.$store.state.patientDetail.wallet_amount)) {
                    this.wallet_error = 'Charge amount should not greater then wallet amount.';
                } else {
                    this.wallet_error = '';
                }
            },
            numbersOnly(evt) {
    			evt = (evt) ? evt : window.event;
    			var charCode = (evt.which) ? evt.which : evt.keyCode;
    			if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
    				evt.preventDefault();
    			} else {
    				return true;
    			}
    		},    		
    		initializeToken : function() {
    			this.tokenpay = {};
                if (this.publicKey) {
        			this.tokenpay = TokenPay(this.publicKey);
        			this.tokenpay.initialize({
        				dataElement: '#card',
        				errorElement: '#errorMessage',
        				amountElement: 'amount',
        				useACH: false,
        			  //if displaying all 4 fields then useStyles=false, disableZip=false, disableCvv=false
        			  //if displaying 3 out of 4 fields then useStyles=false, and set disableZip or disableCvv equal to true
        			  //if displaying 2 out of 4 fields then useStyles=true, disableZip=true, disableCvv=true
        			  useStyles: true,
        			  disableZip: true,
        			  disableCvv: false
        			});
                }
    		},
    		fetchPatientToken : function() {
    			axios
    			.post('/payment/patient-cctoken',{patient_id:this.$store.state.patientDetail.patient_id})
    			.then((response) => {
    				this.ccTokenData = response.data.data;
                    this.ccTokenData.forEach((value, index) => {
                        if (value.is_default == 1 && value.is_expired == false) {
                            this.payment_form.cctoken_id = value.id;
                        }
                    });
    			});
    		},    		
    		handlePaymentCapture : function() {
    			var $this = this;
    			$this.$store.state.loader = true;
    			axios.post("payment/storePatientCopayCoinsPayment", this.payment_form)
    			.then((response) => {
    				if (this.payment_form.payment_type == 'Wallet') {
    	                 this.$store.dispatch("refreshCurrentWallet");
    	            }
    				$this.$store.state.loader = false;
    				$this.moshaToast(response.data.message, "success");
    				$this.fetchPatientToken();
    				this.$store.state.MainInsurnceBilling.hidemodal = true;
    			})
    			.catch((error) => {
    				$this.$store.state.loader = false;
    				$this.serverError = error.response.data.message;
    			});
    		},
    		handlePaymentPay: function() {
    			this.v$.$validate();
    			if (!this.v$.$error) {
                    var vm = this;
    				if (this.payment_form.payment_type == 'Credit Card' && this.payment_form.cctoken_id == 'addNew') {
    					this.tokenpay.createToken(function (result) {
    						vm.payment_form.token = result.token;
    						vm.handlePaymentCapture();
    					}, function (result) {
    						vm.$store.state.loader = false;
    					});
    				} else if(this.payment_form.payment_type == 'Wallet') {
                      if (parseFloat(this.payment_form.paid_amount) > parseFloat(this.$store.state.patientDetail.wallet_amount)) {
                         this.wallet_error = 'Charge amount should not greater then wallet amount.';
                         this.$store.state.loader = false;
                      }  else {
                         vm.handlePaymentCapture();
                      }
       				} else {
    					vm.handlePaymentCapture();
    				}
    			}
    		}
    	},
        watch: {
    		tokenPayReady: function(data) {
    			if (data) { 
    				clearInterval(this.checkingInterval) 
    				this.initializeToken()
    			}
    		}
    	},
    }
</script>