<template>
	<div class="content-wrapper">
		<div class="container-full">
			<div class="content-header">
				<div class="row justify-content-between">
					<div class="col-12 col-sm-8 col-md-9 col-lg-9 col-xs-10">
						<h1 class="mt-0">Support Tickets</h1>
					</div>
					<div class="col-12 col-sm-4 col-md-3 col-lg-3 col-xs-2 text-sm-end">
						<a href="#/support/create" class="waves-effect waves-light btn btn-info mb-5"><i class="fa fa-plus fs-14"></i> Ticket</a>
					</div>
				</div>
			</div>

			<section class="content">
				<div class="box">
					<div class="box-body">
						<div class="table-responsive">
							<data-table dataType="supportIndex" :key="render">
								<template v-slot="slotProps">
									<td style="width:70%;"><a :href="'#/support/'+slotProps.item.master_id+'/threads'" class="fw-500"> {{ slotProps.item.subject }} </a></td>
									<td><span v-bind:class="BindThreadStatus(slotProps.item.status)">{{ slotProps.item.status }}</span></td>
									<td>{{ $filters.displayDateTimeLocal(slotProps.item.time,'',true) }}</td>
								</template>
							</data-table>
						</div>
					</div>
				</div>
			</section>
		</div>
	</div>
</template>

<script>
	import dataTable from "@/components/base/DataTable.vue";
	import axios from "@/scripts/axios.js";
	import Popper from "vue3-popper";

	export default {

		components: { dataTable, Popper, },
		data() {
			return {
				render: 0,
			};
		},
		methods: {
			BindThreadStatus: function(status){
				if(status=="New"){
					return "badge badge-warning font-bold fs-14";
				}
				if(status=="Pending"){
					return "badge badge-danger font-bold fs-14";
				}if(status=="Close"){
					return "badge badge-success font-bold fs-14";
				}
			},
		},
	};
</script>