<template>
        <div class="selectcontrols">
            <Autocomplete max="30" @input="getlist" ref="autocomplete" :results="icd10" @onSelect="resultsvalue" class="form-control ms-0 autosearch-list" placeholder="SNOMED Code"></Autocomplete>
        </div>
</template>
<script>
import Autocomplete from "vue3-autocomplete";
import axios from "@/scripts/axios.js";

export default {
  data() {
    return {
      icd10: [],
    };
  },
  mounted() {},
  methods: {
    getlist(obj) {
      if (obj.length > 1) {
        this.$refs.autocomplete.$el.classList.add("selectloader");
        axios
          .get("/json_autocomplete/snomed-code", {
            params: { term: obj },
          })
          .then((response) => {
            this.icd10 = response.data.data;
            this.$refs.autocomplete.$el.classList.remove("selectloader");
          });
      }
      if (obj.length == 0) {
        this.icd10 = {};
      }
    },
    resultsvalue(obj) {
      console.log(obj);
      this.$emit("clicked", obj);
      this.$refs.autocomplete.searchText = obj.code;
      this.$store.state.CarePlan.form.snomed_code = obj.code;
      this.focusout();
    },
    focusout() {
      const btn = this.$refs.autocomplete;
      btn.$el
        .querySelector(".vue3-autocomplete-container > .autosearch-list")
        .blur();
    },
  },
  components: { Autocomplete },
};
</script>
