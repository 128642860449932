<template>
  <div class="content-wrapper">
    <div class="container-full">
      <section class="content">
        <div class="row justify-content-sm-center">
            <div class="col-12 col-sm-12 col-md-9 col-lg-6">
              <div class="box">
                <div class="box-body">
                  <div class="content-header">
                    <h1 class="heading1 m-0">{{title}}</h1>
                  </div>
                  <div class="form-group" :class="{ error: v$.form.template_name.$errors.length }">
                    <label class="form-label">Sms Template Name</label> <small class="text-danger asterisksign">*</small>
                    <div class="controls">
                        <select class="form-control" v-model="v$.form.template_name.$model" @change="templateselect" v-if="this.$route.name == 'AddSmsTemplate'">
                            <option>Select Sms Template</option>
                            <option v-for="(item,index) in template_key" :data-id="index" :value="item.template_name">{{item.template_name}}</option>
                        </select>
                        <input class="form-control" v-if="this.$route.name != 'AddSmsTemplate'" type="text" v-model="v$.form.template_name.$model" readonly>
                        <div class="error-msg">
                          <ul class="mb-0 p-0 fs-14">
                            <li class="d-block" v-for="(error, index) of v$.form.template_name.$errors" :key="index">
                              {{ error.$message }}
                            </li>
                          </ul>
                        </div>
                    </div>
                  </div>
                  <div class="form-group mb-30" :class="{ error: v$.form.body_content.$errors.length }">
                      <label class="form-label">Sms Body</label> <small class="text-danger asterisksign">*</small>
                      <div class="controls">
                          <ejs-richtexteditor
                          :pasteCleanupSettings="pasteCleanupSettings"
                          :toolbarSettings="toolbarSettings"
                          ref="editor1"
                          title="Content"
                          rows="10"
                          :value="form.body_content">
                          </ejs-richtexteditor>
                          <div class="error-msg">
                            <ul class="mb-0 p-0 fs-14">
                              <li class="d-block" v-for="(error, index) of v$.form.body_content.$errors" :key="index">
                                {{ error.$message }}
                              </li>
                            </ul>
                          </div>
                        </div>
                    </div>
                  <form-btn :title="btnname" @click="save" btnCls="btn-info" ></form-btn>
                  <a href="javascript:void(0)" @click="close" class="waves-effect waves-light btn btn-danger-light">Cancel</a>
                  <div class="mt-20">
                    <div class="">
                        <label class="form-label fw-900">Dynamic Words List</label>
                        <p class="mb-5">##FIRST_NAME## = eg. Paulang</p>
                        <p class="mb-5">##LAST_NAME##  = eg. Morris</p>
                        <p class="mb-5">##USER_NAME##  = eg. PaulangMorris</p>
                        <p class="mb-5">##PATIENT_FULL_NAME##  = eg. Paulang Morris</p>
                        <p class="mb-5">##ACTIVE_URL##  = eg. https://stagging.pureehr.com/</p>
                        <p class="mb-5">##OFFICE_NAME## = eg. Medical Office</p>
                        <p class="mb-5">##OFFICE_ADDRESS## = eg. 1299 McCarter Hwy</p>
                        <p class="mb-5">##OFFICE_STATE## = eg. NJ</p>
                        <p class="mb-5">##SCHEDULE_DATE##   = eg. 11/05/2022</p>
                        <p class="mb-5">##SCHEDULE_TIME## = eg. 10:25 am</p>
                        <p class="mb-5">##APPOINTMENT_VISIT_PROFILE## = Covid Examination</p>
                        <p class="mb-5">##CLINIC_NAME## = eg. Pure Wellness Medical</p>
                        <p class="mb-5">##CLINIC_NUMBER## = eg. 855-675-7873</p>
                      </div>
                    </div>
                </div>

            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import InputField from "@/components/base/formFields/InputField.vue";   
import FormBtn from "@/components/base/formFields/formBtn.vue";
import axios from "@/scripts/axios.js";
import submitMixins from "@/scripts/mixins/submitMixins.js";
import useVuelidate from "@vuelidate/core";
import $ from 'jquery';

export default {
    mixins: [submitMixins],
    setup: () => {
        return { v$: useVuelidate() };
      },
      data() {
        return {
            template_key:[],
            toolbarSettings: {
                    items: ['Bold', 'Italic', 'Underline', 'StrikeThrough','FontName', 'FontSize','Formats', 'Alignments', 'OrderedList', 'UnorderedList','Outdent', 'Indent','CreateLink', 'ClearFormat','Undo', 'Redo',{
                        tooltipText: 'Insert Symbol',
                        undo: true,
                        click: this.cutomFieldClick.bind(this),
                    template: `<select id="customTool" class="customfill">
                    <option value="">Custom Fields</option>
                    <option value="##FIRST_NAME##">First Name</option>
                    <option value="##LAST_NAME##">Last Name</option>
                    <option value="##USER_NAME##">User Name</option>
                    <option value="##PATIENT_FULL_NAME##">Full Name</option>
                    <option value="##ACTIVE_URL##">Active URL</option>
                    <option value="##OFFICE_NAME##">Office Name</option>
                    <option value="##OFFICE_ADDRESS##">Office Address</option>
                    <option value="##OFFICE_STATE##">Office State</option>
                    <option value="##SCHEDULE_DATE##">Appointment Date</option>
                    <option value="##SCHEDULE_TIME##">Appointment Time</option>
                    <option value="##APPOINTMENT_VISIT_PROFILE##">Appointment Visit Profile</option>
                    <option value="##CLINIC_NAME##">Clinic Name</option>
                    <option value="##CLINIC_NUMBER##">Clinic Number</option>
                    </select>`,
                    }]
                },
                pasteCleanupSettings: {
                    prompt: true,
                    plainText: false,
                    keepFormat: false,
                    deniedTags: ['a'],
                    deniedAttrs: ['class', 'title', 'id'],
                    allowedStyleProps: ['color', 'margin', 'font-size']
                },
        }
    },
        components: {
        InputField,
        FormBtn,
    },
    computed: {
        form() {
          return this.$store.state.SmsTemplate.form;
        },
        vuelidateExternalResults() {
          return this.$store.state.SmsTemplate.vuelidateExternalResults;
        },
        validationRules() {
          return this.$store.state.SmsTemplate.validationRules;
        },
        title() {
          return this.$route.name === "AddSmsTemplate"
            ? "New SMS Template"
            : "Edit SMS Template";
        },
        btnname() {
          return this.$route.name === "AddSmsTemplate" ? "Save" : "Update";
        },
    },
    validations() {
        return this.$store.state.SmsTemplate.validationRules;
        },
        methods:{
          cutomFieldClick: function(th) {
				this.$refs.editor1.ej2Instances.executeCommand('insertText', $('#customTool').val() , {undo: true});
			},
            templateselect(e){
                if (this.$route.name === "AddSmsTemplate") {
                    this.$store.state.SmsTemplate.vuelidateExternalResults.form.template_name = [];
                    const theTarget = e.target.options[e.target.options.selectedIndex].dataset;             
                    this.form.body_content = this.$refs.editor1.ej2Instances.value = this.template_key[theTarget.id]['body_content'];
                }
            },
            save(){
              const contetnHtml = new DOMParser().parseFromString(this.$refs.editor1.ej2Instances.value, 'text/html');
              var childnodes = contetnHtml.body.childNodes;
              var htmlAsString = "";
              for (var i = 0; i < childnodes.length; i++) {
                htmlAsString = htmlAsString + "\n" + childnodes[i].textContent;
              }
              if (htmlAsString == '\nnull') htmlAsString = null;
                this.$store.state.SmsTemplate.form.body_content = htmlAsString;
                if (this.$route.name === "AddSmsTemplate") {
                    this.postForm("sms-template/store", "SmsTemplate",true);
                }
                else{
                  this.$swal
                    .fire({
                      title: '<h2 class="mt-0">Are you sure?</h2>',
                      html:
                      "Please preview this sms before updating it, after you <strong>update it</strong> the changes in sms will immediately reflected from the next sms",
                      icon: "warning",
                      dangerMode: true,
                      showCancelButton: true,
                      confirmButtonColor: "#ee3158",
                      cancelButtonColor: "#bdbdbd",
                      confirmButtonText: "Yes, update it!",
                    })
                    .then((result) => {
                      if (result.isConfirmed == true) {
                        this.$store.state.SmsTemplate.vuelidateExternalResults.form.template_name = [];
                        this.postForm("sms-template/update", "Support"); 
                      }
                    });
                }
              },
            formPostSuccess(data) {
          this.$store.state.SmsTemplate.form = {};
          Object.assign(this.$store.state.SmsTemplate.form);
          this.v$.$reset();
          if (this.$route.name === "AddSmsTemplate") {
            this.moshaToast("Record added successfully.", "success");
          }
          else{
            this.moshaToast("Record updated successfully.", "success"); 
          }
          this.$router.push("/sms-template");
        },
        close(){
            this.$router.push("/sms-template");
        }
        },
        mounted(){
          axios.get("/json_dropdown/sms-templatelist")
          .then((response) => {
                this.template_key = response.data.data
          })
        },
        async beforeCreate(){
            if (this.$route.name === "AddSmsTemplate") {
                this.$store.state.SmsTemplate.form = {};
                Object.assign(this.$store.state.SmsTemplate.form);
            }
            else{
                await this.$store.dispatch("getEditData", {
                  dataType: "SmsTemplate",
                  formType: "form",
                  id: this.$route.params.id,
                });
            }
        }
}
</script>
