const RecoverAppointments = {
    namespaced: true,  
    state: {
        listUrl: `appointment/archived`,        
        header: [
            {"schedule_at" : "Appointment Date"},
            {"patinet_name" : "Patient Name"},
            {"username" : "Deleted By"},
            {"deleted_at" : "Deleted On"},
            {"reason_for_delete" : "Reason of Deletion"},
            {"" : ""},
        ],
        pagination: {
            to: "",
            from: "",
            total: "",
            last_page: "",
            current_page: "",
            pageNavArr: [],
        },
        list: [],        
    },
    getters: {},
    mutations: {},
    actions: {},
  };
  
  export default RecoverAppointments;
