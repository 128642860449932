<template>
  	<div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-10">
    	<div class="box">
      		<div class="box-body">
				<div class="pageheading">
					<h2 class="font-bold mt-0">Vitals <a href="#" data-bs-toggle="modal" data-bs-target="#vitaldocument" title="Reference of Knowledge Base" v-on:click.prevent="reset()"> <i class="fa fa-question-circle"></i> </a></h2>
				</div>
				<div v-if="$route.params.appointments_id!=undefined && $route.params.appointments_id!=null">
					<AppointmentInsuranceBadge :AppointmentNumber="$route.params.appointments_id" />
				</div>
				<div class="table-responsive vitals-input-table">
					<table class="table table-hover">
						<thead>
							<tr v-for="(item, index) in patient_vitals" :key="index">
								<slot v-if="index == 0">
									<th v-for="(vital, subindex) in item" :key="subindex" :class="subindex == 0 ? 'w-205' : ''">  
										<div v-if="subindex == 0"> Name </div>
										<div v-if="edit_index == 1">
											<div v-if="subindex == 1">
												Current Vitals
											</div>
										</div>
									</th>
                					<th v-for="(date, subindex) in this.vitals_header" :key="subindex" :class="subindex == 0 ? 'w-205' : ''">  
										<div v-html="date"></div>
									</th>
								</slot>            
							</tr>
						</thead>
						<tbody>
							<slot v-if="patient_vitals.length > 1">
								<tr v-for="(item, index) in patient_vitals" :key="index">
									<slot v-if="index != 0">
										<td v-for="(vital, indexOne) in item" :key="indexOne">
											<div class="d-flex">
												<div v-if="indexOne == edit_index" v-bind:id="'vitals-'+vitals[index-1].vital_list_id"  class="d-flex">
													<select class="form-select" v-if="vitals[index-1].vital_list_id == 10 && vitals[index-1]" v-model="vitals[index-1].vital_value">
														<option value="">Please Select Smoking Status</option>
														<option value="1">Never smoked tobacco</option>
														<option value="2"
														>Tobacco smoking consumption unknown</option
														>
														<option value="3">Occasional tobacco smoker</option>
														<option value="4">Ex-smoker</option>
														<option value="5">Heavy tobacco smoker</option>
														<option value="6">Smokes tobacco daily</option>
														<option value="7">Smoker</option>
														<option value="8">Light tobacco smoker</option>
													</select>
													<div v-if="vitals[index-1].vital_list_id != 10">
														<input  type="text" :title="title[index]"  v-on:blur=" setPatientVitalValue(vitals[index-1].vital_list_id,'value',$event.target.value)" placeholder="" v-model="vitals[index-1].vital_value" :class="vitals[index-1].vital_list_id == 3 ? 'form-control bloodpre-input w-50 p-5' : 'form-control w-120 me-1'" :readonly="vitals[index-1].vital_list_id == 8"/>
														<input type="hidden" :title="title[index]" v-model="vitals[index-1].vital_list_id"/>
													</div>
												
													<span v-if="vitals[index-1].vital_list_id == 3" class="bloodpre-input">
														 / <input type="text" :title="title[index]" class="form-control bloodpre-input w-50 p-5" id="blood" placeholder="" v-on:blur="setPatientVitalSubValue(vitals[index-1].vital_list_id,'value',$event.target.value)"
														v-model="vitals[index-1].vital_value_sub"/>
													</span>
													<div v-if="indexOne == 1">
														<select name="select" v-model="vitals[index-1].vital_sel_unit" class="form-select d-inline-block p-1" style="width:60px;" v-if="vital[0].options && vital[0].options.length > 1 && vitals[index-1]" @change="bmiCalc">
															<option :value="vunit" v-for="(vunit,vunitindex) in vital[0].options" :key="vunitindex">
																{{ vunit }}
															</option>
														</select>
														<span v-else v-for="(vunit,vunitindex) in vital[0].options" :key="vunitindex">{{ vunit }}</span> 
													</div>
													<span v-if="!!title[vitals[index-1].vital_list_id]" :title="title[vitals[index-1].vital_list_id]" class="mt-2 ml-2">
														<i class="fa fa-info-circle text-danger fs-14 ms-2"></i>
													</span>
												</div>
												<div v-if ="indexOne == 0" >
													<div>
														<span v-show='false'>{{vital_option = vital.options}}</span>
														<span class="">{{ vital.name }}</span>
													</div>
												</div>
											</div>
										</td>
										<td v-for="(date, subindex) in this.vitals_header" :key="subindex">
											<slot v-for="(vital, indexOne) in this.temp" :key="indexOne" class="w-205">
												<div v-for="(vitalin,index) in vital" :key="index">
													<div v-for="(vital2,index2) in vitalin" :key="index2">
														<div v-for="(vital3,index3) in vital2" :key="index3">
															<div v-for="(vital4,index4) in vital3" :key="index4">
																<div v-for="(vital5,index5) in vital4" :key="index5">
																	<div v-if="date==vital5.date">
																		<div v-if="item[0].vital_list_id==vital5.vital_id">
																			{{vital5.value}}
																		</div>
																	</div>
																</div>
																<div v-if="date==vital4.date">
																	<div v-if="item[0].vital_list_id==vital4.vital_id">
																		{{vital4.value}}
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</slot>
										</td>
									</slot>
									<slot v-if="index != 0">
										<td></td>
									</slot>
								</tr>
							</slot>
							<slot v-else>
								<tr>
									<td>No Data Found.</td>
								</tr>
							</slot>
						</tbody>
					</table>
				</div>
				<div class="text-center mt-10" v-if="permissioncheck(this.$store.state.permission.create_update)">
					<button type="submit" @click="handleSubmit" class="waves-effect waves-light btn btn-info mb-5">
						Save
					</button>
				</div>
        		<!--document model start-->
        		<div id="vitaldocument" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
          			<div class="modal-dialog modal-md">
            			<div class="modal-content">
							<div class="modal-header">
								<h3 class="modal-title fw-900 mt-0">Reference of Knowledge Base</h3>
							</div>
              				<div class="modal-body overflow-auto" style="max-height: 350px;">
								<table class="table table-bordered">
									<thead>
										<tr>
											<th scope="col">No.</th>
											<th scope="col">Knowledge Base Articles</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>1</td>
											<td> <a :href="path+'how-to-add-vitals-with-appointment-from-patient-chart'" target="_blank">How To Add Vitals With Appointment From Patient Chart?</a></td>
										</tr>
									</tbody>
								</table>
              					<Search :key="searchKey"></Search>
              				</div>
							<div class="modal-footer">
								<button type="button"  data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
							</div>
            			</div>
          			</div>
        		</div>
        		<!--document model end-->
      		</div>
    	</div>
  	</div>
</template>
<script>
import axios from "@/scripts/axios.js";
import submitMixins from "@/scripts/mixins/submitMixins.js";
import permissionMixins from "@/scripts/mixins/permission.js";
import Popper from "vue3-popper";
import AppointmentInsuranceBadge from "@/components/Appointments/AppointmentInsuranceBadge.vue";
import Search from "@/components/Search.vue";
import moment from 'moment';
export default {
  	components: {Popper, AppointmentInsuranceBadge, Search},
  	mixins: [submitMixins, permissionMixins],
  	data() {
		return {
			patient_vitals: [],
			vitals: [],
			title:[],
			titles:[],
			edit_index:1,
			action:'save',
			appointments_id: this.$store.state.scheduleappointment.appointments_id,
			path: process.env.VUE_APP_KB_DOCUMENT,
			searchKey:0,
			vitals_header:[],
			vitals_data:[],
			temp:[],
			Appointmentid:this.$route.params.appointments_id,
			patientID:this.$store.state.scheduleappointment.patient_id,
		};
  	},
  	created() {
    	this.Appointmentid=this.$route.params.appointments_id;
		if(this.Appointmentid){
			this.edit_index=1;
			this.action='save';
			this.patient_vitals=[];
			this.vitals=[];
			this.title=[];
			this.titles=[];
			this.getData();
		}
  	},
  	methods: {
		reset() {
			this.searchKey = this.searchKey + 1;
		},
		onEnter(){		
		},
   		bmiCalc() {
     		var id=0;
			var id1=0;
			var id2=0;
			this.vitals.forEach(
			function (value, i) {
				if (value['vital_list_id']==6) {
					id=i;
				}
				if (value['vital_list_id']==7) {
					id1=i;
				}
				if (value['vital_list_id']==8) {
					id2=i;
				}
			});	
			if (this.vitals[id] && this.vitals[id1]) {
				if ( this.vitals[id].vital_value && this.vitals[id].vital_sel_unit && this.vitals[id1].vital_value && this.vitals[id1].vital_sel_unit) {
					var height = this.vitals[id].vital_value;
					var weight = this.vitals[id1].vital_value;
					if (isNaN(height) || isNaN(height)) {
						this.vitals[id2].vital_value = "";
						this.moshaToast("Only numeric value allowed for height & weight.","danger");
					} else {
						var height_unit = this.vitals[id].vital_sel_unit;
						var weight_unit = this.vitals[id1].vital_sel_unit;
						if (weight_unit == "kg") {
							weight = weight *= 2.20462262;
						}
						if (height_unit == "cm") {
							height = height *= 0.393700787;
						}
						var k = Math.round((weight / (height * height)) * 703 * 10) / 10;
						this.vitals[id2].vital_value = k;
					}
				}
			}
    	},
    	keyExists(key, search) {
			if (!search || (search.constructor !== Array && search.constructor !== Object)) {
				return false;
			}
			for (var i = 0; i < search.length; i++) {
				for (const key1 in search[i]) {
					if (key1 === key) {				
						return true;            	
					}
				}  
			}
        	return key in search;
    	},
    	async getData() {
      		var data=[];
			var data1=[];
			var data2=[];
			var vital_data=[];
			var vital_data1=[];
			if(this.patientID!=null){
				const result = await axios.post("vital/patient-chart", {
					patient_id: this.patientID,
					appointment_id:this.Appointmentid,
					type:"vb",
				});
				if (result.data.data) {
					this.patient_vitals = result.data.data;
					// console.log(this.patient_vitals);
					var last_index=this.patient_vitals.length-1;
					data1=result.data.data[last_index];
					data = this.patient_vitals[0];
					console.log(data);
					if (data.length>2) {
						data = data[2];
						const entries = Object.entries(data1);
						vital_data=Object.entries(entries[0][1]);
						for (var i = 0; i < this.patient_vitals.length; i++) {
						
							if (this.patient_vitals[i][0].vital_id != undefined) {
								this.vitals.push({
									vital_id: this.patient_vitals[i][0].vital_id,
									vital_value: "",
									vital_list_id: this.patient_vitals[i][0].vital_list_id,
									vital_value_sub: "",
									vital_sel_unit: this.patient_vitals[i][0].options
										? this.patient_vitals[i][0].options[0]
										: "",
								});
							}
							
						}
						
						var data4=[];
						var data5=[];
						var id=0;
						for (var i = 0; i < this.vitals.length; i++) {
							for (let j = 0; j < vital_data.length; j++) {
								if (this.vitals[i].vital_id != undefined) {
									if(vital_data[j][0]==this.vitals[i].vital_list_id){										
										for (let k = 0; k < vital_data[j][1].length; k++){
											
											var arr=vital_data[j][1][k].date.split("<");
											
											var arr2=arr[2].split("l>");
											
											var time=arr2[1];
											var date=arr[0];
											var datadate=date+" "+time;
											var datatime=moment(datadate).utc(datadate);
											var timedata=datatime.local().format('h:mm A');
											var data22="<small>"+date+"<br><small>"+timedata+"</small></small>";
											if (!data4.includes(data22)) {
												data4.push(data22);
											}
											if(this.vitals[i].vital_list_id==10){
											id=vital_data[j][1][k].vital_id;
											}
											if (vital_data[j][1][k].vital_id==id) {
												// console.log(vital_data[j][1][k].value);
												if (vital_data[j][1][k].value==1) {
													data5.push({
													"vital_id":vital_data[j][1][k].vital_id,
													"value":"Never smoked tobacco",
													"date":data22,
													});
											}else if(vital_data[j][1][k].value==2){
													data5.push({
													"vital_id":vital_data[j][1][k].vital_id,
													"value":"Tobacco smoking consumption unknown",
													"date":data22,
													});
											}else if(vital_data[j][1][k].value==3){
													data5.push({
													"vital_id":vital_data[j][1][k].vital_id,
													"value":"Occasional tobacco smoker unknown",
													"date":data22,
													});
											}else if(vital_data[j][1][k].value==4){
													data5.push({
													"vital_id":vital_data[j][1][k].vital_id,
													"value":"Ex-smoker",
													"date":data22,
													});
											}else if(vital_data[j][1][k].value==5){
													data5.push({
													"vital_id":vital_data[j][1][k].vital_id,
													"value":"Heavy tobacco smoker",
													"date":data22,
													});
											}else if(vital_data[j][1][k].value==6){
													data5.push({
													"vital_id":vital_data[j][1][k].vital_id,
													"value":"Smokes tobacco daily",
													"date":data22,
													});
											}else if(vital_data[j][1][k].value==7){
													data5.push({
													"vital_id":vital_data[j][1][k].vital_id,
													"value":"Smoker",
													"date":data22,
													});
											}else if(vital_data[j][1][k].value==8){
													data5.push({
													"vital_id":vital_data[j][1][k].vital_id,
													"value":"Light tobacco smoker",
													"date":data22,
													});
											}
											}else{
												if (vital_data[j][1][k].value==null) {
													data5.push({
													"vital_id":vital_data[j][1][k].vital_id,
													"value":"",
													"date":data22,
													});	
												} else {
													data5.push({
													"vital_id":vital_data[j][1][k].vital_id,
													"value":vital_data[j][1][k].value+"("+vital_data[j][1][k].selected_unit+")",
													"date":data22,
													});	
												}
											
											}
										}
									}
									
								}
							}
						}
						this.vitals_header = data4.filter((x, i, a) => a.indexOf(x) == i);
						var temp2=[];
						var temp3=[];
						var data8=[];
						for (let i = 0; i < data5.length; i++) {
							var arr=data5[i].date.split("<");
							var dataofdate=arr[1].split("l>")
							var arr2=arr[3].split("l>");
							var time=arr2[1];
							var date=dataofdate[1];
							var datadate=new Date(date+" "+time);
							// console.log(datadate);
							var theDate = new Date(datadate);
						time=moment(theDate).format('h:mm A');
							if(this.keyExists(time,temp2)){
								temp3.push(data5[i]);
								
							}
							else{	
								temp2.push({
									[time]:data5[i]
								});
							}	
						}
						data8.push({[date]:temp2});
						data8.push({[time]:temp3});
						if(this.keyExists(date,this.temp)){
								this.temp.push({
									[date]:temp2
								});
							}
							else{
								this.temp.push({
									[date]:data8
								});	
						}
						console.log(this.temp);
					}else{
					for (var i = 0; i < this.patient_vitals.length; i++) {
							
							if (this.patient_vitals[i][0].vital_id!=undefined) {
								this.vitals.push({
								vital_id: this.patient_vitals[i][0].vital_id,
								vital_value: "",
								vital_list_id: this.patient_vitals[i][0].vital_list_id,
								vital_value_sub: "",
								vital_sel_unit: this.patient_vitals[i][0].options
									? this.patient_vitals[i][0].options[0]
									: "",
								});
							}
							
						}
					}
					if(this.edit_index != 1 ){
						
						const editdata = await axios.get("vital/"+this.Appointmentid+"/edit");
						this.edit_vitals = editdata.data.data;
						console.log(this.edit_vitals);
						for (var i = 0; i < this.edit_vitals.length; i++) {
							for (var j = 0; j < this.vitals.length; j++) {
								if(this.edit_vitals[i].vital_id == 3){
								
								if(this.edit_vitals[i].value){
								const myArray = this.edit_vitals[i].value.split("/");
								this.vitals[this.edit_vitals[i].vital_id].vital_value = myArray[0];
								$('#blood').val(myArray[1]);
								}
							}
							else{
								if (this.edit_vitals[i].vital_id==this.vitals[j].vital_id) {
									this.vitals[j].vital_value=this.edit_vitals[i].value;
								}
							}
								
							}
							
						
						}			
					}
				} else {
					this.moshaToast(`Could not get data.`, "danger");
				}
			}		
    	},
    	setPatientVitalValue(currentIndex, name, value) {    
      		if(currentIndex == 1){
				var id = "#vitals-"+currentIndex;
				
				var vital_sel_unit = this.vitals[currentIndex-1].vital_sel_unit;
				if(vital_sel_unit == 'C'){
					if(value < 36.5){
						$(id).addClass("error");
						this.title[currentIndex] = "Normal range of temperature is between 36.5°C – 38.3°C (97.7°F – 100.9°F)";
					}
					else if(value > 38.3){
						$(id).addClass("error"); 
						this.title[currentIndex] = "Normal range of temperature is between 36.5°C – 38.3°C (97.7°F – 100.9°F)";
					}
					else{
						$(id).removeClass("error");
						this.title[currentIndex]='';
					}
				}else{
					if(value < 97.7){
						$(id).addClass("error");
						this.title[currentIndex] = "Normal range of temperature is between 36.5°C – 38.3°C (97.7°F – 100.9°F)";
					}
					else if(value > 100.9){
						$(id).addClass("error"); 
						this.title[currentIndex] = "Normal range of temperature is between 36.5°C – 38.3°C (97.7°F – 100.9°F)";
					}
					else{
						$(id).removeClass("error");
						this.title[currentIndex]='';
					}
				}
			}
			if(currentIndex == 2){
				var id = "#vitals-"+currentIndex
				if(value < 60){
					$(id).addClass("error");
					this.title[currentIndex] = "Normal range of pulse is between 60-100 bpm";
				}
				else if(value > 100){
					$(id).addClass("error"); 
					this.title[currentIndex] = "Normal range of pulse is between 60-100 bpm";
				}
				else{
					$(id).removeClass("error");
					this.title[currentIndex]='';
				}
			}
			if(currentIndex == 3){
				var id = "#vitals-"+currentIndex
				if(value < 60){
					$(id).addClass("error");
					this.title[currentIndex] = "Normal range of  diastolic blood pressure is between 60-90";
				}
				else if(value > 90){
					$(id).addClass("error"); 
					this.title[currentIndex] = "Normal range of  diastolic blood pressure is between 60-90";
				}
				else{
					$(id).removeClass("error");
					this.title[currentIndex]='';
				}
			}
			if(currentIndex == 4){
				var id = "#vitals-"+currentIndex
				if(value < 12){
					$(id).addClass("error");
					this.title[currentIndex] = "Please enter respiration rate range between 12-25";
				}
				else if(value > 25){
					$(id).addClass("error"); 
					this.title[currentIndex] = "Please enter respiration rate range between 12-25";
				}
				else{
					$(id).removeClass("error");
					this.title[currentIndex]='';
				}
			}
			if(currentIndex == 5){
				var id = "#vitals-"+currentIndex
				if(value < 90){
					$(id).addClass("error");
					this.title[currentIndex] = "Please enter oxygen saturation range between 90% to 100%";
				}
				else{
					$(id).removeClass("error");
					this.title[currentIndex]='';
				}
			}
			if (currentIndex == 6 || currentIndex == 7) {
				this.bmiCalc();
			}
    	},
    	setPatientVitalSubValue(currentIndex, name, value) {
			if(currentIndex == 3){
				var id = "#vitals-"+currentIndex
				if(value < 90){
					$(id).addClass("error");
					this.titles[currentIndex] = "Normal range of  systolic blood pressure is between 90-140";
				}
				else if(value > 140){
					$(id).addClass("error");
					this.titles[currentIndex] = "Normal range of  systolic blood pressure is between 90-140"; 
				}
				else{
					$(id).removeClass("error");
					this.title[currentIndex]='';
				}
			}			
		},
    	handleSubmit() {	
			var frm = {
				patient_id: this.$store.state.scheduleappointment.patient_id,
				vitals: this.vitals,
				appointment_id:this.$store.state.scheduleappointment.appointment_id,
				type:'vb'
			};
			var url = `/vital/store`;      
      		axios.post(url, frm)
        		.then((response) => {
					this.vitals = [];
					this.patient_vitals = [];
					this.temp=[];
					$("input:text").val("");
					this.moshaToast("Vitals added successfully.", "success");
					this.getData();
	        })
			.catch((error) => {
			this.moshaToast(error.response.data.message, "danger");
			});
    	},
  	},
};
</script>
