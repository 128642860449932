<template>
  <div class="loginback">
    <div class="container-fluid">
      <div class="row align-items-center">
        <div
          class="col-12 col-sm-9 col-md-8 col-lg-6 col-xl-5 col-xxl-4 offset-sm-1"
        >
          <div class="bg-white rounded10 p-35 pt-40 pb-40 my-20">
            <div class="text-start">
              <img
                src="/images/pureehr.svg"
                alt="PureEHR"
                title="PureEHR"
                class="authcompany_logo mb-20"
              />
              <h2 class="mb-20 fw-900">Scan the QR code and add the code to login.</h2>

              <div class="form-group mb-20 text-center">
              	<div v-if="url_type == 'svg' " v-html="google2fa_url"></div>
              	<img v-else :src="google2fa_url">
              </div>

              <div class="form-group mb-20">
              	<label class="fs-18">Authenticator Code</label>
              	<input type="text" class="form-control" name="authenticator_code" id="authenticator_code" v-model="authenticator_code" placeholder="Enter Authenticator Code" @keyup="authenticatorchange"/>
              	<div class="text-danger">
                  {{ authenticator_code_error }}
                </div>
              </div>

              <div class="text-center">
              	<button class="waves-effect waves-light btn btn-info d-block fs-22 w-p100 started_btn" @click="authenticator">Authenticate</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
	import $ from "jquery";
	import axios from "@/scripts/axios.js";

	export default {
  		name: "Google authentication",
  		data() {
    		return {
    			google2fa_url :'',
    			authenticator_code:'',
    			authenticator_code_error:'',
    		}
    	},
  		mounted() {
		    $('.tophead').html("");
		    this.$store.commit("setErrors", {});
		    this.$store.state.headerRequired = false;
		    this.$store.state.footerRequired = false;
				this.google2fa_url = localStorage.getItem("google2fa_url");
				var google2fa_url = localStorage.getItem("google2fa_url").split(':');
				if(google2fa_url[0] == "data"){
						this.url_type = "base64"
				}
				else{
						this.url_type = "svg";
				}
		},
		methods: {
			authenticator(){
				if(this.authenticator_code == ''){
					this.authenticator_code_error ='Please enter Authenticator Code';
				}
				else{
					axios.post("/2fa/enable2fa",{secret:this.authenticator_code})
					.then((response) => {
						this.$store.state.headerRequired = true;
		    			this.$store.state.footerRequired = true;
						localStorage.removeItem("login_security");
						window.location = "/";
					})
					.catch((error) => {
			            this.authenticator_code_error = error.response.data.data;
			        });
				}
			},
			authenticatorchange(){
				if(this.authenticator_code.length > 0){
					this.authenticator_code_error = '';
				}
			}
		},
  	}
</script>
