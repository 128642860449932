<template>
	<div class="content-wrapper">
		<div class="container-full">
			<div class="content-header">
				<h1 class="heading1 m-0 d-inline-block">Recurring Appointment Management</h1>
			</div>
			<section class="content">
				<div class="box">
					<div class="box-body">
						<div class="table-responsive">
							<data-table-server dataType="RecurringAppointments">
								<template v-slot="slotProps">
									<td>{{ slotProps.item.provide }}</td>
									<td>{{ slotProps.item.patient }}</td>
									<td>{{ slotProps.item.original_scheduled_time }}</td>
									<td>{{ slotProps.item.recur_description }}</td>
									<td> 
										<select name="select" class="form-select form-control">
											<option v-for="(item,index) in slotProps.item.recuring_appointment" :key="index">{{item.schedule_date}} #{{item.appointment_number}}</option>
										</select>
									</td>
									<td>{{ slotProps.item.office_name }} <br>{{(slotProps.item.room_name == 'null' || slotProps.item.room_name == null  ? '':'-'+slotProps.item.room_name)}}</td>
									<td>
										<b>Created:</b> {{ $filters.displayDateTimeLocal(slotProps.item.created_at,'',false,'MM-DD-YYYY hh:mm A') }}
									</td>
									<td>
										<button @click="stop(slotProps.item.id,slotProps.item.recurring_id)" type="button" class="waves-effect waves-light btn btn-secondary btn-sm me-1 mb-1"><i class="fa fa-close text-danger"></i> Stop</button>
										<div class="availdate">
											<ejs-datepicker 
												:value="stop_date" 
												v-bind:id="'stop_date'+slotProps.item.id" 
												:format="dateFormat" 
												placeholder="mm-dd-yyyy" 
												:strictMode='strict' 
												:showClearButton='false' 
												:openOnFocus='true'
												:min="$filters.displayDateTimeLocal(new Date(),'',false,'ddd, MM-DD-YYYY',true)"
											>
											</ejs-datepicker>
										</div>
									</td>
								</template>
							</data-table-server>
						</div>
					</div>
				</div>
			</section>
		</div>
	</div>
</template>
<script>
	import axios from "@/scripts/axios.js";
	import DataTableServer from "@/components/base/DataTableServer.vue";
	import moment from "moment";
	import submitMixins from "@/scripts/mixins/submitMixins.js";
	export default {
		mixins: [submitMixins],
		components: { DataTableServer },
		data(){
			return{
				dateFormat : 'MM-dd-yyyy',
			}
		},
		methods:{
			stop(id,recurring_id){
				var self = this;
				var date = moment(String($("#stop_date"+id).val())).format('YYYY-MM-DD');
				self.$swal.fire({
					input: 'textarea',
					inputLabel: 'Enter reason for delete.',
					inputPlaceholder: 'Enter reason for delete...',
					inputAttributes: {
						'aria-label': 'Enter reason for delete'
					},
					allowOutsideClick: false,
					showCancelButton: false,
					confirmButtonText: 'Submit', 
					inputValidator: function(inputValue) {
						return new Promise(function(resolve, reject) {
							if (inputValue && inputValue.length > 0) {
								resolve();
							} else {
								self.$swal.showValidationMessage('Can not be blank') 
								self.$swal.enableInput()
								self.$swal.enableButtons()
							}
						});
					}
				})
				.then((result) => {
					if (result.isConfirmed == true) {
						self.$store.state.loader = true;
						axios.post("/appointment/recurring-delete",{recurring_id:recurring_id,stop_date:date, reason_for_delete:result.value})
						.then(response=>{
							self.moshaToast("Record Deleted successfully.", "success");
							self.$store.state.loader = false;
							setTimeout(function(){location.reload()}, 3000);
						});
					}
				});
			}
		}
	}
</script>