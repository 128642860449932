import {
    helpers,
    required,    
  } from "@vuelidate/validators";
  const insuranceDetail = {
    namespaced: true,  
    state: {
        defaultFormData: {
            id: null,    
            document_title: '',    
            document_desc: '',    
            document_date: '',
        },
        form: {},
        validationRules: {
            form: {
                uploadFiles_front: {
                    required: helpers.withMessage("Please upload front side.",required),
                },
                uploadFiles_rear: {
                    required: helpers.withMessage("Please upload rear side.",required),
                },
                insurancetype:{
                    required: helpers.withMessage("Please Select Insurance Type.",required),  
                }
            }            
        },
        vuelidateExternalResults: {
            form: {
                document_title: '',
                uploadFiles_front: '',
                uploadFiles_rear: '',
                insurancetype:'',
            },
        },
        list: [],
    },
    getters: {
        getTableHeaders: (state) => state.header,  
        getTableContents: (state) => state.list,
        getListURL: (state) => state.listUrl,
    },
    mutations: {},
    actions: {},
  };
  
  export default insuranceDetail;
  