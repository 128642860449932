<template>
    <div class="content-wrapper">
        <div class="content-header">
            <h2 class="heading2 mb-0">Clinical Decision Support Rules</h2>
        </div>
        <section class="content">
            <div class="tab-content">
                <div class="box" style="background: #F2F8FF">
                    <div class="box-body">
                        <iframe :src="cds_rules_url" frameborder="0" allowfullscreen></iframe>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
    export default {
      components: {},
      data() {
    	return {
            cds_rules_url : `${process.env.VUE_APP_API_CDS_URL}/cds/admin/Ruleshome.aspx`,
        };
      },
      watch: {},
      created() {},
      methods: {},
    };
</script>
<style type="text/css" scoped>
    iframe {
    height:calc(100vh - 4px);
    width:calc(100vw - 4px);
    box-sizing: border-box;
    }
</style>