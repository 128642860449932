<template>
    <div class="content-wrapper">
        <div class="container-full">
            <section class="content">
                <div class="content-header">
                    <h2 class="heading2 font-bold my-5">Clinical Quality Measures</h2>
                </div>
                <div class="box">
                    <div class="box-body">
                        <div class="row justify-content-sm-center">
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                <div class="form-group d-flex" :class="{ error: v$.form.provider_id.$errors.length }">
                                    <label class="col-form-label text-end w-70 me-2">Provider</label>
                                    <div class="w-p100">
                                        <select name="select" id="salutationselect" class="form-select form-control" v-model="form.provider_id">
                                            <option disabled="" value="">Select Provider</option>
                                            <option
                                              v-for="(provider, index) of providerlist"
                                              :key="index" :value="provider.id"
                                              >{{ provider.name }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-2">
                                <div class="form-group mb-5 d-flex">
                                    <label class="col-form-label text-end w-70 me-2">CQM</label>
                                    <div class="w-p100">
                                        <select name="select" class="form-select form-control" v-model="form.cqm_value">
                                            <option value="CMSALL">ALL</option>
                                            <!-- <option value="122v9">CMS122</option>
                                            <option value="138v9">CMS138</option>
                                            <option value="156v9">CMS156</option>
                                            <option value="165v9">CMS165</option>
                                            <option value="68v10">CMS68</option>
                                            <option value="69v9">CMS69</option> -->
                                            <option value="68v11">CMS68v11</option>
                                            <option value="69v10">CMS69v10</option>
                                            <option value="122v10">CMS122v10</option>
                                            <option value="138v10">CMS138v10</option>
                                            <option value="156v10">CMS156v10</option>
                                            <option value="165v10">CMS165v10</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                <div class="form-group mb-5 d-flex">
                                    <label class="col-form-label text-end w-60 me-2">Date</label>
                                    <div class="availdate w-p100" :class="{ error: v$.form.date_range.$errors.length }">
                                        <ejs-daterangepicker format='MM-dd-yyyy' id="date_range" :value="date" 
                                         v-model="form.date_range" :strictMode='strict'
                                         placeholder="Select Date Range"  @change="onRangeSelect"></ejs-daterangepicker>
                                         <div class="error-msg" v-if="v$.form.date_range.$errors.length > 0">
                                          <ul class="mb-0 p-0 fs-14">
                                            <li class="d-block" v-for="(error, index) of v$.form.date_range.$errors" :key="index"> {{ error.$message }}</li>
                                          </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 text-center text-xl-start">
                                <button class="waves-effect waves-light btn btn-info me-1" @click="getFilterPatientCqm">Search</button>
                                <button class="waves-effect waves-light btn btn-secondary me-1" @click="exportPatinentCqm">QRDA Export</button>
                                <button class="waves-effect waves-light btn btn-secondary" @click="ImportCqm">QRDA Import</button>
                            </div>
                        </div>
                    </div>
                    <hr class="mt-0">
                    <div class="box-body">
                        <div class="table-responsive">
                            <table id="cqm_patient" class="table mb-0">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <!-- <th>Appt. Date</th> -->
                                        <th>First Name</th>
                                        <th>Last Name</th>
                                        <th>DOB</th>
                                    </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    class="hover-primary"
                                    v-for="(item, index) in patientlist"
                                    :key="index"> 
                                    <td>{{ item.patient_id }}</td>
                                    <td>{{ item.first_name }}</td>
                                    <td>{{ item.last_name }}</td>
                                    <td>{{ $filters.displayDateTimeLocal(item.date_of_birth,'',false,"ddd, MM-DD-YYYY",true) }}</td>
                                  </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </section>
            <div id="ImportCQM" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h3 class="modal-title fw-900 mt-0">Import CQM</h3>
                        </div>
                        <div class="modal-body">
                            <div class="form-group row">
								<label class="col-12 col-sm-12 col-md-3 col-lg-4 col-xl-4 mb-0 col-form-label text-md-end">File to upload</label>
								<div class="col-12 col-sm-12 col-md-9 col-lg-8 col-xl-8">
									<input type="file" id="file_field" ref="file" class="form-control" v-on:change="FileUpload($event)"/>
								</div>
							</div>
                            <p><b>Note :</b> Only zip formats are supported.</p>
                            <div class="error-msg" id="GroupFileError">
								<ul class="mb-0 p-0 fs-14"></ul>
							</div>
                        </div>
                        <div class="modal-footer">
                            <form-btn title="Upload File" btnCls="waves-effect waves-light btn btn-info float-sm-end" @click="submitForm()"></form-btn>
                            <a href="javascript:void(0)" class="waves-effect waves-light btn btn-danger" data-bs-dismiss="modal"> Close </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "@/scripts/axios.js";
import moment from 'moment';
import useVuelidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import submitMixins from "@/scripts/mixins/submitMixins.js";
import FormBtn from "@/components/base/formFields/formBtn.vue";

export default {
      components: { FormBtn },
      setup() {
        return { v$: useVuelidate() };
      },
      mixins: [ submitMixins ],
      validations() {
        return {
          form: {
            provider_id: {
              required: helpers.withMessage("Provider is required.", required),
            },
            date_range: {
              required: helpers.withMessage("Date range is required.", required),
            },
          },
        };
      },
      data() {
    	return {
            providerlist : [],
            patientlist : [],
            example : '',
            form : {
                provider_id : '',
                cqm_value : 'CMSALL',
                date_range : '',
                start_date : '',
                end_date : '',
                date : ''
            },
            importform : {
                document_name : '',
            },
            strict: true,
        };
      },
      mounted() {
          let externalScript = document.createElement('script')
          externalScript.setAttribute('src', 'https://cdn.jsdelivr.net/npm/jquery-datatables-checkboxes@1.2.12/js/dataTables.checkboxes.min.js')
          document.head.appendChild(externalScript);
          axios.get("/json_dropdown/provider")
            .then((response) => {
                  this.providerlist = response.data.data;
                  //this.getFilterPatientCqm();
            });
            this.date = moment().subtract(1, 'months').format("MM-DD-YYYY")+' - '+moment(new Date()).format("MM-DD-YYYY");
      },
      methods: {
        onRangeSelect() {
          this.form.date_range =  $('#date_range').val();
        },
        getFilterPatientCqm() {
            setTimeout(() => {
                this.v$.$validate();
                if (!this.v$.$error) {
                    //$('#cqm_patient').DataTable().destroy();
                    var daterange = $('#date_range').val();
                    var date = daterange.split(' - ');
                    this.form.start_date = moment(String(date[0])).format('YYYY-MM-DD');
                    this.form.end_date = moment(String(date[1])).format('YYYY-MM-DD');
                    this.$store.state.loader = true;
                    axios.post("getFilterPatientCqm", this.form).then((response) => {
                        this.patientlist = response.data.data;
                        this.$store.state.loader = false;
                        this.initializeTable();
                    });
                }
             }, 500);
        },
        exportPatinentCqm() {
            var patientIds = $.map(this.example.rows('.selected').data(), function (item) {
                var custom_value ='';
                if ($(item[0]).attr("value") != undefined) {
                    custom_value = $(item[0]).attr("value");
                } else {
                    custom_value = item[0];
                }
               return parseInt(custom_value);
           });
            if (patientIds.length > 0) {
                this.form.patientIds = patientIds;
                /*this.form.selectedPatientObj = this.patientlist.filter(function (patient) {
                    return patientIds.includes(patient.patient_id);
                })*/
                this.$store.state.loader = true;
                axios.post("exportPatientCqm", this.form, {responseType: 'blob' }).then((response) => {
                    this.$store.state.loader = false;
                    var a = document.createElement('a');
                    var blob = new Blob([response.data], {type: "application/zip"})
                    var url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = this.form.cqm_value+'.zip';
                    document.body.append(a);
                    a.click();
                    a.remove();
                    window.URL.revokeObjectURL(url);
                });
            } else {
                this.moshaToast("Please select patient", "danger");
            }
        },
        initializeTable() {
                $('#cqm_patient').DataTable().destroy();
              setTimeout(() => {
                     this.example = $('#cqm_patient').DataTable({
                        'columnDefs': [
                             {
                                'targets': 0,
                                'checkboxes': {
                                   'selectRow': true
                                },
                                'render': function (data, type, full, meta) {
                                    var custom_value ='';
                                    if ($(data).attr("value") != undefined) {
                                        custom_value = $(data).attr("value");
                                    } else {
                                        custom_value = data;
                                    }
                                    return '<input type="checkbox" class="dt-checkboxes" name="id[]" value="' + $('<div/>').text(custom_value).html() + '">';
                                }
                             }
                          ],
                          'select': {
                             'style': 'multi'
                          },
                          'order': [[1, 'asc']],
                          destroy: true,
                    });
                    $("#cqm_patient").DataTable().rows('.selected').nodes().to$().removeClass('selected');
              }, 500);
        },
        ImportCqm(){
            $("#ImportCQM").modal("show");
        },
        FileUpload(event) {
            this.importform.document_name = this.$refs.file.files[0];
            $("#GroupFileError").find("ul").html("");
            const size = (this.$refs.file.files[0].size / 1024 / 1024).toFixed(2);
            if (size > 100) {
                $("#GroupFileError").find("ul").append('<li class="d-block confirmerror"> File size should not exceed more than 100 MB.</li>');
                this.importform.document_name = "";
                $("#file_field").val("");
            }
            const acceptedImageTypes = [
                "application/zip"
            ];
            if (!acceptedImageTypes.includes(this.$refs.file.files[0].type)) {
                $("#GroupFileError").find("ul").append('<li class="d-block confirmerror"> Only zip formats are supported.</li>');
                this.importform.document_name = "";
                $("#file_field").val("");
            }
        },
        submitForm() {
            if(this.importform.document_name == ''){
                $("#GroupFileError").find("ul").append('<li class="d-block confirmerror"> Please select file first.</li>');
            }else{
                var bodyFormData = new FormData();
                for (const [key, value] of Object.entries(this.importform)) {
                    if (Array.isArray(this.importform[key])) {
                        this.importform[key].forEach((item) => {
                            if(item == null || item == '' || item == 'null'){
                                item = "";
                            }
                            bodyFormData.append(`${key}[]`, item);
                        });
                    } else {
                        if(value == null || value == '' || value == 'null'){
                            value = "";
                        }
                        bodyFormData.append(key, value);
                    }
                }
                this.$store.state.loader = true;
                axios.post("/importPatientCqm", bodyFormData).then((res) => {
                    this.$store.state.loader = false;
                    location.reload();
                });
                
            }				
        },
      },
    };
</script>
<style>
.dt-checkboxes {
    position: relative !important;
    opacity: unset !important;
}
#cqm_patient input {
    position: relative !important;
    opacity: unset !important;
}
</style>