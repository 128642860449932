<template>
  <div class="content-wrapper">
    <div class="container-full">
      <div class="content-header">
        <div class="row justify-content-between">
          <div class="col-12 col-sm-8 col-md-9 col-lg-9">
            <h2 class="heading2 my-5">Archived Consent Forms</h2>
          </div>
          <div class="col-12 col-sm-4 col-md-3 col-lg-3 text-sm-end">
            <router-link
              to="/consent-forms"
              class="waves-effect waves-light btn btn-info-light me-5"
              ><i class="fa fa-arrow-left fs-14"></i> Back</router-link>
          </div>
        </div>
      </div>
      <section class="content">
        <div class="box">
          <div class="box-body">
            <div class="table-responsive">
              <center>
                <span class="isvalid-feedback" style="display:block;">{{
                  success
                }}</span>
              </center>
              <data-table-server dataType="ConsentFormsArchived">
                <template v-slot="slotProps">
                  <td v-if="slotProps.item.document_file_exist == true">
                    <a href="javascript:void(0);" @click="FileDisplay(slotProps.item.document_name_url)" class="fw-500">{{ slotProps.item.title }}</a>
                  </td>
                  <td v-else>{{ slotProps.item.title }}</td>
                  <td>{{ slotProps.item.owner }}</td>
                  <td>
                    <div class="controls">
                      <input
                        v-if="slotProps.item.is_mandatory == 1"
                        checked
                        type="checkbox"
                        v-bind:id="'first_' + slotProps.item.id"
                        value="single"
                        class="filled-in chk-col-info"
                        disabled
                      />
                      <input
                        v-else
                        type="checkbox"
                        v-bind:id="'first_' + slotProps.item.id"
                        value="single"
                        class="filled-in chk-col-info"
                        disabled
                      />
                      <label
                        v-bind:for="'first_' + slotProps.item.id"
                        class="m-0"
                      ></label>
                    </div>
                  </td>
                  <td>
                    <div class="controls">
                      <input
                        v-if="slotProps.item.in_appointment == 1"
                        checked
                        type="checkbox"
                        v-bind:id="'two_' + slotProps.item.id"
                        value="single"
                        class="filled-in chk-col-info"
                        disabled
                      />
                      <input
                        v-else
                        type="checkbox"
                        v-bind:id="'two_' + slotProps.item.id"
                        value="single"
                        class="filled-in chk-col-info"
                        disabled
                      />
                      <label
                        v-bind:for="'two_' + slotProps.item.id"
                        class="m-0"
                      ></label>
                    </div>
                  </td>
                </template>
              </data-table-server>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import InputField from "@/components/base/formFields/InputField.vue";
import FileUpload from "@/components/base/formFields/fileUpload.vue";
import CheckBox from "@/components/base/formFields/checkBox.vue";
import DataTableServer from "@/components/base/DataTableServer.vue";

import useVuelidate from "@vuelidate/core";
import submitMixins from "@/scripts/mixins/submitMixins.js";
import axios from "@/scripts/axios.js";

export default {
  mixins: [submitMixins],
  setup: () => {
    return { v$: useVuelidate() };
  },
  components: {
    DataTableServer,
    InputField,
    FileUpload,
    CheckBox,
  },
  computed: {
    form() {
      return this.$store.state.ConsentForms.form;
    },
    vuelidateExternalResults() {
      return this.$store.state.ConsentForms.vuelidateExternalResults;
    },
    validationRules() {
      return this.$store.state.ConsentForms.validationRules;
    },
  },
  data() {
    return {
      dataTypes: "ConsentForms",
      heddingtitle: "Add Consent Form",
      buttonlabel: "Upload Document",
    };
  },
  methods: {
    FileDisplay(fileName) {
      window.open(fileName);
    },
    save() {
      if (this.$store.state.ConsentForms.edit != 1) {
        this.postForm("/consent-form/store", "ConsentForms", true, "formdata");
      } else {
        this.postForm("/consent-form/update", "ConsentForms", true, "formdata");
      }
    },
    formPostSuccess(data) {
      this.$store.state.ConsentForms.form = {};
      this.v$.$reset();
      Object.assign(this.$store.state.ConsentForms.form);
      this.$store.dispatch("getSimpleTableContents", this.dataTypes);
      if (this.$store.state.ConsentForms.edit != 1) {
        this.$store.commit("showToster", [
          "success",
          `Consent Forms added successfully`,
        ]);
      } else {
        this.$store.commit("showToster", [
          "success",
          `Consent Forms Updated successfully`,
        ]);
      }
      location.reload();
    },
    remove(id) {
      this.$swal
        .fire({
          title: '<h2 class="mt-0">Are you sure?</h2>',
          text:
            "You want to delete these records? This process cannot be undone.",
          icon: "warning",
          dangerMode: true,
          showCancelButton: true,
          confirmButtonColor: "#ee3158",
          cancelButtonColor: "#bdbdbd",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed == true) {
            axios.post("/consent-form/delete", { id: id }).then((response) => {
              this.$swal
                .fire(
                  "Deleted!",
                  "Consent form has been deleted.",
                  "success"
                )
                .then((result) => {
                  if (result.isConfirmed == true) {
                    location.reload();
                  }
                });
            });
          }
        });
    },

    edit(id) {
      this.heddingtitle = "Edit Consent Form";
      this.buttonlabel = "Update Document";
      this.$store.state.ConsentForms.edit = 1;
      axios.get("/consent-form/edit/" + id).then((result) => {
        this.$store.state.ConsentForms.form = result.data.data;
      });
      $(".modal").modal("toggle");
    },
  },
  validations() {
    return this.$store.state.ConsentForms.validationRules;
  },
};
</script>
