
const crmContacts = {
    namespaced: true,
  
    state: {
      listUrl: "/crm/contacts",
      header: [
        {"patient_name":"Patient Name"},
        {"cell_phone":"Phone"},
        {"patient.email":"Email"},
        {"crm_pipeline_name":"CRM Pipeline"},
        {"current_crm_status":"CRM Current Status"},
        {"created_at":"Created"},
        {"tags":"Tag"},
      ],
      pagination: {
        to: "",
        from: "",
        total: "",
        last_page: "",
        current_page: "",
        pageNavArr: [],
      },
      /* filter: {
        duplicate: false,
        inactive_only: false, 
        active_inactive: false,
        search_text: '',
        having_followup: false,
        upcoming_appointment : false,
        approaching_appointment : false,
        new_patients : false,
        yesterday_appointment : false,
        today_appointment : false,
      }, */
      list: [],
    },
  
    getters: {
      // getTableHeaders: (state) => state.header,
      // getTableContents: (state) => state.list,
      // getListURL: (state) => state.listUrl,
    },
    mutations: {},
    actions: {},
  };
  
  export default crmContacts;
  