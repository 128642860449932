<template>
	<div class="content-wrapper">
		<div class="container-full">
			<div class="tab-content">
				<div class="content-header">
					<h2 class="heading2 font-bold m-0">Reminder Profiles</h2>
				</div>
				<div class="box">
					<div class="box-body">
						<form class="">
							<div class="form-group row align-items-center">
								<div class="col-12 col-sm-12 col-md-12 col-lg-6">
									<div class="row">
										<label class="col-form-label mb-0 col-12 col-sm-5 col-md-5 col-lg-5">Choose a
											Reminder Profile</label>
										<div class="col-12 col-sm-7 col-md-7 col-lg-7">
											<select name="select" class="form-select form-control" v-model="rid"
												@change="change_reminderprofile(rid)">
												<option value="" selected="selected" disabled="disabled">Select Reminder
													Profile</option>
												<option v-for="(item, index) in list" :value="item.id" :key="index">
													{{ item.name }}</option>
											</select>
										</div>
									</div>
								</div>
								<div class="col-12 col-sm-12 col-md-12 col-lg-6 text-end">
									<button @click="formshow" v-show="formdisplay == false" type="button"
										class="waves-effect waves-light btn btn-info btn-sm me-1 my-1"><i
											class="fa fa-plus"></i> Profile</button>
									<a v-show="formdisplay == true" href="javascript:void(0)"
										class="waves-effect waves-light btn btn-info-light btn-sm me-1"
										@click="openContentDialog()"><i class="fa fa-envelope fs-14 me-1"></i> Create
										Template</a>
									<button @click="savereminder" v-show="formdisplay == true" type="button"
										class="waves-effect waves-light btn btn-info btn-sm me-1 my-1"><i
											class="mdi mdi-content-save"></i> {{ btndisplay }}</button>
									<button v-show="rid != ''" @click="delete_reminderprofile" type="button"
										class="waves-effect waves-light btn btn-danger btn-sm my-1"><i
											class="fa fa-close"></i> Delete Profile</button>
								</div>
							</div>
							<div v-show="formdisplay == true">
								<div class="form-group row align-items-center"
									:class="{ error: v$.form.reminderprofilename.$errors.length }">
									<div class="col-12 col-md-6 d-flex align-items-center">
										<input type="text" class="form-control me-2" id="title"
											placeholder="New Profile Name" v-model="this.form.reminderprofilename">
										<!-- <div class="controls">
										<input type="checkbox" id="checkbox_1" value="single" class="filled-in chk-col-info" v-model="this.form.is_default">
										<label for="checkbox_1" class="m-0">Default?</label>
									</div> -->
									</div>
									<div class="error-msg">
										<ul class="mb-0 p-0 fs-14">
											<li class="d-block"
												v-for="(error, index) of v$.form.reminderprofilename.$errors" :key="index">
												{{ error.$message }}
											</li>
										</ul>
									</div>
								</div>
								<div class="bb-2 mb-10" v-for="(item, index) in reminderdata" :key="index">
									<div class="row align-items-center" v-bind:id="'form' + index">
										<div class="col-12 col-md-8">
											<div class="row">
												<div class="col-6 col-sm-3 col-md-2" v-bind:id="'reminder_type' + index">
													<select name="select" class="form-select form-control mb-10"
														v-model="reminderdata[index].reminder_type"
														@change="reminderTypeChange(index)">
															<option value="email" :selected="reminderdata[index].reminder_type == 'email'">E-Mail</option>
															<option value="phone" :selected="reminderdata[index].reminder_type == 'phone'">Phone</option>
															<option value="sms" :selected="reminderdata[index].reminder_type == 'sms'">SMS</option>
													</select>
												</div>
												<div class="col-6 col-sm-3 col-md-2" v-bind:id="'reminder_value_' + index">
													<div class="form-group mb-10">
														<input type="text" v-bind:id="'reminder_value' + index"
															class="form-control" placeholder="1"
															@focusout="reminder_value(index)"
															:value="reminderdata[index].reminder_value">
													</div>
												</div>
												<div class="col-6 col-sm-3 col-md-2" v-bind:id="'reminder_unit_type' + index">
													<select name="select" class="form-select form-control mb-10"
														v-model="reminderdata[index].reminder_unit_type">
														<option value="days"
															:selected="reminderdata[index].reminder_unit_type == 'days'">
															Day(s)</option>
														<option value="hours"
															:selected="reminderdata[index].reminder_unit_type == 'hours'">
															Hour(s)</option>
													</select>
												</div>
												<div class="col-6 col-sm-3 col-md-3"
													v-bind:id="'reminder_unit_before_or_after_' + index">
													<div class="form-group mb-10">
														<select name="select" class="form-select form-control"
															v-bind:id="'reminder_unit_before_or_after' + index"
															@change="reminder_unit_before_or_after(index)">
															<option value=""
																:selected="reminderdata[index].reminder_unit_before_or_after == ''"
																disabled="disabled">Select Value</option>
															<option value="before"
																:selected="reminderdata[index].reminder_unit_before_or_after == 'before'">
																Before Appointment</option>
															<option value="after"
																:selected="reminderdata[index].reminder_unit_before_or_after == 'after'">
																After Appointment</option>
														</select>
													</div>
												</div>
												<div class="col-12 col-md-3" v-bind:id="'reminder_content_' + index">
													<div class="form-group mb-10">
														<select name="select" class="form-select form-control"
															v-bind:id="'reminder_content' + index"
															@change="reminder_content(index)">
															<option selected="selected" value="">Select template</option>
															<option v-for="item in emaillist"
																:selected="reminderdata[index].reminder_content == item.id"
																:value="item.id"
																v-show="item.template_subcategory == reminderdata[index].reminder_type">
																{{ item.template_name }}</option>
														</select>
													</div>
												</div>
											</div>
										</div>
										<div class="col-12 col-md-4 text-end btnDisabledParent">
											<button @click="reminderview(index)" :disabled="!item.preview_btn" type="button"
												class="waves-effect waves-light btn btn-info btn-sm me-1 mb-1 btnDisabled"
												data-bs-toggle="modal" data-bs-target="#mypreviewModal"><i
													class="fa fa-eye"></i> Preview</button>
											<button @click="removeformrow(index)" type="button"
												class="waves-effect waves-light btn btn-danger btn-sm mb-1"><i
													class="fa fa-close"></i> Delete</button>
										</div>
									</div>
								</div>
								<div class="text-end">
									<button @click="newreminder(reminderdata.length)" type="button"
										class="waves-effect waves-light btn btn-info btn-sm"><i
											class="fa fa-plus fs-14"></i> New Reminder</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div id="myContentModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
		<div class="modal-dialog modal-xl">
			<div class="modal-content">
				<div class="modal-header">
					<h2 class="modal-title m-0">Content</h2>
					<button type="button" class="btn-close btn-xs" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">
					<div class="form-group row" id="subject_row">
						<label class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">Template Type <small class="text-danger asterisksign">*</small></label>
						<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
							<div class="controls">
								<select name="select" class="form-select form-control" v-model="templateType"
									@change="templateTypeChange">
									<option selected="selected" value="" disabled="disabled">Select Template Type</option>
									<option value="email" :selected="templateType == 'email'">E-Mail</option>
									<option value="phone" :selected="templateType == 'phone'">Phone</option>
									<option value="sms" :selected="templateType == 'sms'">SMS</option>
								</select>
							</div>							
						</div>
					</div>
					<div class="form-group row" id="subject_row" v-show="showSubject">
						<label class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">Subject <small
								class="text-danger asterisksign">*</small></label>
						<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
							<div class="controls">
								<input id="Subject" type="text" v-model="subject" class="form-control" placeholder="Subject" autocomplete="off">
							</div>
							<div class="error-msg">
								<ul class="mb-0 p-0 fs-14">{{ errorMsg.content.subject }}</ul>
							</div>
						</div>
					</div>
					<div class="form-group row">
						<div class="col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8" v-if="this.is_new_template">
							<div class="controls">
								<input id="template_title" type="text" v-model="template_title" class="form-control"
									placeholder="Enter title to save your new template" autocomplete="off">
							</div>
							<div class="error-msg">
								<ul class="mb-0 p-0 fs-14">{{ errorMsg.content.template }}</ul>
							</div>
						</div>
						<div class="col-2 col-md-2" v-if="this.is_new_template">
							<form-btn title="Back" btnCls="btn-info"
								@click="this.is_new_template = !this.is_new_template;"></form-btn>
						</div>
						<div class="col-12 col-sm-8 col-md-8 col-lg-10" v-if="!this.is_new_template">
							<div class="form-group mb-10">
								<select name="select" class="form-select form-control" v-model="selected_email_template"
									@change="changeTemplate">
									<option selected="selected" value="" disabled="disabled">Select Template</option>
									<option v-for="item in emaillistwithoutsystem" :key="item.id" :value="item.id">
										{{ item.template_name }}</option>
								</select>
								<div class="error-msg">
									<ul class="mb-0 p-0 fs-14">{{ errorMsg.content.template }}</ul>
								</div>
							</div>
						</div>
						<div class="col-12 col-sm-4 col-md-4 col-lg-2" v-if="!this.is_new_template">
							<form-btn title="Add New" btnCls="btn-info"
								@click="this.is_new_template = !this.is_new_template; this.$refs.editorObj.ej2Instances.value = null"></form-btn>
						</div>
					</div>
					<div class="form-group" id="content_row">
						<ejs-richtexteditor :pasteCleanupSettings="pasteCleanupSettings" :toolbarSettings="toolbarSettings"
							:insertImageSettings="insertImageSettings" :imageUploading="imageUploading" ref="editorObj"
							title="Message" rows="10" :created="textCreated">
						</ejs-richtexteditor>
						<div class="error-msg">
							<ul class="mb-0 p-0 fs-14">{{ errorMsg.content.content }}</ul>
						</div>
					</div>

				</div>
				<div class="modal-footer">
					<form-btn title="Save Draft" btnCls="btn-info" @click="saveDialogContent"></form-btn>
					<button type="button" class="addpatientbtn btn-danger b-0" data-bs-dismiss="modal" @click="closeModal">Close</button>
				</div>
			</div>
		</div>
	</div>
	<!--Modal start -->
	<div id="mypreviewModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
		aria-hidden="true">
		<div class="modal-dialog modal-lg">
			<div class="modal-content">
				<div class="modal-header">
					<h2 class="modal-title m-0">Email Message Preview</h2>
					<button type="button" class="btn-close btn-xs" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">
					<p>
						<strong>Subject: </strong>{{ subjectline }}
					</p>
					<div v-html="body_content"></div>
				</div>
				<div class="modal-footer">
					<button type="button" class="addpatientbtn btn-danger b-0" data-bs-dismiss="modal">Close</button>
				</div>
			</div>
		</div>
	</div>
	<!--Modal End -->
</template>
<style type="text/css">
.e-richtexteditor.e-rte-tb-expand .e-rte-content,
.e-richtexteditor.e-rte-tb-expand .e-source-content {
	margin-top: 0px !important;
}
</style>
<script>
import axios from "@/scripts/axios.js";
import useVuelidate from "@vuelidate/core";
import submitMixins from "@/scripts/mixins/submitMixins.js";
import FormBtn from "@/components/base/formFields/formBtn.vue";
import FileUploadMultiple from "@/components/base/formFields/FileUploadMultiple.vue";
export default {
	mixins: [submitMixins],
	setup: () => {
		return { v$: useVuelidate() };
	},
	components: {
		FormBtn,
		FileUploadMultiple,
	},
	data() {
		return {
			list: [],
			rid: '',
			formdisplay: false,
			emaillist: [],
			key: '0',
			reminderdata: [],
			btndisplay: 'Save Reminder Profile',
			subjectline: '',
			body_content: '',
			templateType: 'email',
			subject: "",
			showSubject: true,
			email_template_id: 0,
			message: "",
			template_title: '',
			is_new_template: false,
			selected_email_template: '',
			emaillistwithoutsystem: '',
			insertImageSettings: {
				path: process.env.VUE_APP_API_BASE_URL.substr(0, process.env.VUE_APP_API_BASE_URL.lastIndexOf('/')) + "/storage/attach_file/",
				saveUrl: process.env.VUE_APP_API_BASE_URL + "/support/file/store",
				display: 'inline'
			},
			toolbarSettings: {},
			pasteCleanupSettings: {
				prompt: true,
				plainText: false,
				keepFormat: false,
				deniedTags: ['a'],
				deniedAttrs: ['class', 'title', 'id'],
				allowedStyleProps: ['color', 'margin', 'font-size']
			},
			reminder_type: {},
			errorMsg: {
				content: {
					templateType: '',
					subject: '',
					template: '',
					content: ''
				}
			}
		}
	},
	computed: {
		form() {
			return this.$store.state.Reminderprofile.form;
		},
		vuelidateExternalResults() {
			return this.$store.state.Reminderprofile.vuelidateExternalResults;
		},
		validationRules() {
			return this.$store.state.Reminderprofile.validationRules;
		},
	},
	validations() {
		return this.$store.state.Reminderprofile.validationRules;
	},
	created() {
		this.templateTypeChange();
		this.reminder_profile();
		this.email_template();
	},
	methods: {
		closeModal(){
			this.errorMsg.content.templateType = '';
			this.errorMsg.content.subject = '';
			this.errorMsg.content.template = '';
			this.errorMsg.content.content = '';
			this.templateType = 'email';
			this.subject = '';
			this.template_title = '';
			this.is_new_template = false;
			this.selected_email_template = '';
			this.$refs.editorObj.ej2Instances.value = '';
			this.showSubject = true;
			this.email_template_id = 0;
			this.templateTypeChange();
		},
		reminder_profile() {
			this.rid = '',
			axios.get('/reminder/list')
				.then((response) => {
					this.list = response.data.data
				});
		},
		changeTemplate() {
			if (this.selected_email_template) {
				axios.get('email-template/edit/' + this.selected_email_template)
					.then((response) => {
						this.$refs.editorObj.ej2Instances.value = response.data.data.body_content;
						this.subject = response.data.data.subject_line;
						this.email_template_id = response.data.data.id;
						this.template_title = response.data.data.template_name;
					});
			}
		},
		imageUploading(args) {
			var token = localStorage.getItem("authToken");
			this.file_name = Date.now() + "_" + (Math.random() + 1).toString(36).substring(7) + "." + args.fileData.type;
			args.fileData.name = this.file_name;
			args.fileData.id = this.file_name;
			args.currentRequest.setRequestHeader("Authorization", `Bearer ${token}`);
			args.currentRequest.setRequestHeader("File-Name", this.file_name);
		},
		cutomFieldClick: function (th) {
			this.$refs.editorObj.ej2Instances.executeCommand('insertText', $('#customTool').val(), { undo: true });
		},
		change_reminderprofile(id) {
			this.form.id = id;
			axios.post('/reminder/edit', { id: id })
				.then((response) => {
					this.form.reminderprofilename = response.data.data.name;
					this.form.is_default = (response.data.data.is_default == 'yes') ? true : false;
					this.reminderdata = response.data.data.reminderdata;
					var emaillist = this.emaillist.map((item) => {
						return item.id;
					}).filter(n => n);
					this.reminderdata.map((item) => {
						if (emaillist.includes(parseInt(item.reminder_content))) {
							item.preview_btn = true;
						} else {
							item.preview_btn = false;
						}
					})
					this.form.reminderdata = response.data.data.reminderdata;
					this.btndisplay = "Update Reminder Profile";
					this.formdisplay = true;
				});
			this.email_template();
		},
		delete_reminderprofile() {
			axios.post('/reminder/delete', { id: this.form.id })
				.then((response) => {
					this.reminder_profile();
					this.moshaToast("Record Deleted successfully.", "success");
					location.reload();
				});
		},
		// openContentDialog() {
		// 	$('#myContentModal').modal('show');
		// },
		saveDialogContent() {
			var $THIS = this;
			var data = this.$refs.editorObj.ej2Instances.value;
			$THIS.errorMsg.content.template = '';
			$THIS.errorMsg.content.subject = '';
			$THIS.errorMsg.content.content = '';
			if (this.email_template_id > 0) {
				axios.post('/email-template/update', {
					'template_category': 'appointment_reminder',
					'subject_line': this.subject,
					'body_content': data,
					'template_name': this.template_title,
					'id': this.email_template_id
				}).then((response) => {
					this.email_template();
					$('#myContentModal').modal('hide');
					this.moshaToast("Update email template successfully.", "success");
					this.templateType = 'email';
					this.subject = "";
					this.$refs.editorObj.ej2Instances.value = '';
					this.template_title = '';
					this.email_template_id = 0;
					this.templateTypeChange();
				}).catch(function (e) {
					if (e.response.data.data.template_name && e.response.data.data.template_name[0]) {
						$THIS.errorMsg.content.template = e.response.data.data.template_name[0];
					}
					if (e.response.data.data.subject_line && e.response.data.data.subject_line[0]) {
						$THIS.errorMsg.content.subject = e.response.data.data.subject_line[0];
					}
					if (e.response.data.data.body_content && e.response.data.data.body_content[0]) {
						$THIS.errorMsg.content.content = e.response.data.data.body_content[0];
					}
				});
			}
			else if (this.email_template_id == 0) {
				axios.post('/email-template/store', {
					'template_category': 'appointment_reminder',
					'template_subcategory': this.templateType,
					'subject_line': this.subject,
					'body_content': data,
					'template_name': this.template_title
				}).then((response) => {
					this.email_template();
					$('#myContentModal').modal('hide');
					this.moshaToast("Save email template successfully.", "success");
					this.templateType = 'email';
					this.subject = "";
					this.$refs.editorObj.ej2Instances.value = '';
					this.template_title = '';
					this.email_template_id = 0;
					this.templateTypeChange();
				}).catch(function (e) {
					if (e.response.data.data.template_name && e.response.data.data.template_name[0]) {
						$THIS.errorMsg.content.template = e.response.data.data.template_name[0];
					}
					if (e.response.data.data.subject_line && e.response.data.data.subject_line[0]) {
						$THIS.errorMsg.content.subject = e.response.data.data.subject_line[0];
					}
					if (e.response.data.data.body_content && e.response.data.data.body_content[0]) {
						$THIS.errorMsg.content.content = e.response.data.data.body_content[0];
					}
				});
			}
		},
		openContentDialog() {
			$( 'div[id^="richtexteditor"][id$="view"]' ).prev().attr("style", $( 'div[id^="richtexteditor"][id$="view"]' ).prev().attr("style") + "margin-bottom: -30px !important;");
			$('#myContentModal').modal('show');
		},
		formshow() {
			this.formdisplay = true;
			this.email_template();
			this.reminderdata.push({
				reminder_type: 'email',
				reminder_value: '',
				reminder_unit_type: 'days',
				reminder_unit_before_or_after: '',
				reminder_content: '',
			});
		},
		email_template() {
			axios.get('/email-template/listByCategory/appointment_reminder')
				.then((response) => {
					this.emaillist = response.data.data
				});
			axios.get('/email-template/listByCategory/appointment_reminder/emaillistwithoutsystem')
				.then((response) => {
					this.emaillistwithoutsystem = response.data.data
				});
		},
		newreminder(value) {
			if (this.reminderdata == '') {
				this.reminderdata.push({
					reminder_type: 'email',
					reminder_value: '',
					reminder_unit_type: 'days',
					reminder_unit_before_or_after: '',
					reminder_content: '',
				});
			}
			else {
				var errCounter = 0;
				for (var ii = 0; ii < value; ii++) {
					var data = this.reminderdata[ii];
					if (data.reminder_value == '') {
						$("#reminder_value_" + ii).addClass('form-group row error');
						errCounter++;
					}
					else if (data.reminder_unit_type == '') {
						$("#reminder_unit_type_" + ii).addClass('form-group row error');
						errCounter++;
					}
					else if (data.reminder_unit_before_or_after == '') {
						$("#reminder_unit_before_or_after_" + ii).addClass('form-group row error');
						errCounter++;
					}
					else if (data.reminder_content == '') {
						$("#reminder_content_" + ii).addClass('form-group row error');
						errCounter++;
					}
				}
				if (errCounter == 0) {
					this.reminderdata.push({
						reminder_type: 'email',
						reminder_value: '',
						reminder_unit_type: 'days',
						reminder_unit_before_or_after: '',
						reminder_content: '',
					});
				}				
			}
		},
		savereminder() {
			this.v$.$validate();
			if (!this.v$.$error) {
				this.form.reminderdata = this.reminderdata;
				if (this.btndisplay == "Update Reminder Profile") {
					var url = "/reminder/update";
				}
				else {
					var url = "/reminder/store";
				}
				axios.post(url, this.form)
					.then((response) => {
						// this.moshaToast("Record Add successfully.", "success")
						this.moshaToast(response.data.message, "success")
						setTimeout(function () {
							location.reload();
						}, 1000);
					});
			}
		},
		removeformrow(index) {
			$("#form" + index).remove();
			this.reminderdata.splice(index, 1);
			this.key++;
		},
		reminder_value(index) {
			$("#reminder_value_" + index).removeClass('form-group row error');
			var value = $("#reminder_value" + index).val();
			this.reminderdata[index].reminder_value = value;
		},
		reminder_unit_before_or_after(index) {
			$("#reminder_unit_before_or_after_" + index).removeClass('form-group row error');
			var value = $("#reminder_unit_before_or_after" + index).val();
			this.reminderdata[index].reminder_unit_before_or_after = value;
		},
		reminder_content(index) {
			$("#reminder_content_" + index).removeClass('form-group row error');
			var value = $("#reminder_content" + index).val();
			if (value != '') {
				this.reminderdata[index].preview_btn = true;
			} else {
				this.reminderdata[index].preview_btn = false;
			}
			this.reminderdata[index].reminder_content = value;
		},
		reminderview(index) {
			this.$store.state.loader = true;
			var id = this.reminderdata[index].reminder_content;
			axios.get('email-template/edit/' + id)
			.then((response) => {
				this.$store.state.loader = false;
				this.subjectline = response.data.data.subject_line
				this.body_content = response.data.data.body_content
			});
		},
		templateTypeChange() {
			if (this.templateType == "email") {
				this.showSubject = true;
				this.toolbarSettings = {
					items: ['Bold', 'Italic', 'Underline', 'StrikeThrough', 'FontName', 'FontSize', 'Formats', 'Alignments', 'OrderedList', 'UnorderedList', 'Outdent', 'Indent', 'CreateLink', 'Image', 'ClearFormat', 'Undo', 'Redo', {
						tooltipText: 'Insert Symbol',
						undo: true,
						click: this.cutomFieldClick.bind(this),
						template: `<select id="customTool" class="customfill">
						<option value="">Custom Fields</option>
						<option value="##full_name##">Full Name</option>
						<option value="##first_name##">First Name</option>
						<option value="##last_name##">Last Name</option>
						<option value="##email##">Email</option>
						<option value="##cell_phone##">Cell Phone</option>
						<option value="##date_of_birth##">Date Of Birth</option>
						<option value="##appointment_date##">Appointment Date</option>
						<option value="##appointment_time##">Appointment Time</option>
						<option value="##APPOINTMENT_VISIT_PROFILE##">Appointment visit profile</option>
						<option value="##clinic_name##">Clinic Name</option>
						</select>`,
					}]
				}
			} else {
				this.showSubject = false;
				this.toolbarSettings = {
					items: [{
						tooltipText: 'Insert Symbol',
						undo: true,
						click: this.cutomFieldClick.bind(this),
						template: `<select id="customTool" class="customfill">
						<option value="">Custom Fields</option>
						<option value="##full_name##">Full Name</option>
						<option value="##first_name##">First Name</option>
						<option value="##last_name##">Last Name</option>
						<option value="##appointment_date##">Appointment Date</option>
						<option value="##appointment_time##">Appointment Time</option>
						<option value="##APPOINTMENT_VISIT_PROFILE##">Appointment visit profile</option>
						</select>`,
					}]
				}
			}
		},
		reminderTypeChange(index) {
			this.reminderdata[index].reminder_content = 0;
		},
		textCreated(){
			var editarea = document.querySelector('.e-richtexteditor .e-rte-content .e-content'); 
			editarea.addEventListener('keyup', this.docKeyUp); //Bind keyup event 
		},
		docKeyUp(e) { 
			this.errorMsg.content.content = '';		
		}   
	},
	watch: {
		"subject": function() {
			this.errorMsg.content.subject = '';
		},
		"template_title": function() {
			this.errorMsg.content.template = '';
		},		
  	},
}
</script>