const CovidExaminationReport = {
    namespaced: true,  
    state: {
        listUrl: `covid/get-examination-report`,  
        header: [
            {"full_name" : "Patient"},
            {"visit_date" : "Visit Date"},
            {"cell_phone" : "Cell Phone"},
            {"email" : "Email"},
            {"payable_amount" : "Paid Amount"},
            {"" : "Test Desc."},            
        ],
        pagination: {
            to: "",
            from: "",
            total: "",
            last_page: "",
            current_page: "",
            pageNavArr: [],
        },
        filter:{
            is_speciman_collected: '',
            is_refund_requested: '',
            is_lab_report_received: '',
            from_date: '',
            to_date: '',
            free_search: '',
            current_user_office_id: '',
        },
        list: [],
    },
    getters: {},
    mutations: {},
    actions: {},
  };
  
  export default CovidExaminationReport;
