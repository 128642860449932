<template>
  <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-10">
    <div class="authform-modal box">
      <div class="pageheading">
        <h2 class="font-bold mt-0">Staff Scheduling <a href="#" data-bs-toggle="modal" data-bs-target="#staffschedulingdocument" title="Reference of Knowledge Base" v-on:click.prevent="reset()"> <i class="fa fa-question-circle fs-20"></i></a></h2>
      </div>

      <form>
        <select-box
          title="Office Access"
          id="office_access"
          v-model="selectedOffice"
          :getDataURL="getOfficeDDURL"
          :isRequired="true"
        ></select-box>
        <div class="form-group">
          <input type="checkbox" id="office_selection" class="filled-in chk-col-info" v-model="default_office_scheduling">
          <label for="office_selection" class="text-start">Default Office Scheduling</label>
        </div>
        <scheduling-table
          :selectedOffice="selectedOffice"
          :providerID="$route.params.id"
          usertype ="staff"
          :officescheduling ="this.default_office_scheduling"
        ></scheduling-table>
      </form>
      <div class="text-center">
        <form-btn
          title="Save"
          btnCls="btn-info"
          @click="save(true)"
        ></form-btn>
        
      </div>
      <!--document model start-->
      <div id="staffschedulingdocument" class="modal fade" tabindex="-1" role="dialog"
         aria-labelledby="myModalLabel" aria-hidden="true">
         <div class="modal-dialog modal-md">
            <div class="modal-content">
               <div class="modal-header">
                  <h3 class="modal-title fw-900 mt-0">Reference of Knowledge Base</h3>
               </div>
               <div class="modal-body overflow-auto" style="max-height: 350px;">
                  <table class="table table-bordered">
                     <thead>
                        <tr>
                           <th scope="col">No.</th>
                           <th scope="col">Knowledge Base Articles</th>
                        </tr>
                     </thead>
                     <tbody>
                        <tr>
                           <td>1</td>
                           <td> <a :href="path+'how-to-schedule-staff-s-timing-in-pureehr'" target="_blank">How To Schedule Staff's Timing In PureEHR?</a></td>
                        </tr>
                     </tbody>
                  </table>
                  <Search :key="searchKey"></Search>
               </div>
               <div class="modal-footer">
                  <button type="button" @click="" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
               </div>
            </div>
         </div>
      </div>
	   <!--document model end-->
    </div>
  </div>
</template>

<script>
import SchedulingTable from "@/components/common/SchedulingTable.vue";
import SelectBox from "@/components/base/formFields/selectBox.vue";
import axios from "@/scripts/axios.js";
import Search from "@/components/Search.vue";

export default {
  // props: ["officeIds"],
  components: { SchedulingTable, SelectBox, Search },
  data() {
    return {
      selectedOffice: "",
      default_office_scheduling:false,
      path: process.env.VUE_APP_KB_DOCUMENT,
      searchKey:0,
    };
  },
  methods:{
    reset() {
      this.searchKey = this.searchKey + 1;
    },
  },
  computed: {
    getOfficeDDURL() {
      return `/staff/office_access/${this.$route.params.id}`;
    },
  },
  created() {
    this.$store.state.provider.editID = this.$route.params.id;
  },
  beforeCreate(){
    axios
      .get("/staff/office_access/" + this.$route.params.id)
      .then((result) => {
          this.selectedOffice = result.data.data[0].id
      });
  }
};
</script>
