import {
  helpers,
  required,
} from "@vuelidate/validators";

const Pipeline = {
  namespaced: true,
  
  state: {
    listUrl: "/crm/pipeline-list",
    getEditUrl: "/crm/pipeline-edit/:id",
    tableData: {
      header: [
        "Name",
        "Status",
        "Action",
      ],
    },
    validationRules: {
    },
    vuelidateExternalResults: {
    },
    form : {
    },
    list: [],
  },
  getters: {
    getTableHeaders: (state) => state.header,

    getTableContents: (state) => state.list,
    getListURL: (state) => state.listUrl,
  },
  mutations: {},
  actions: {},
}

export default Pipeline;