<template>
	<div class="content-wrapper">
		<div class="container-full">
			<div class="content-header">
				<div class="row justify-content-between">
					<div class="col-12 col-sm-8 col-md-9 col-lg-6 col-xs-6">
						<h1 class="mt-0">LifeStyle Service Category</h1>
					</div>
					<div class="col-12 col-sm-4 col-md-3 col-lg-3 col-xs-2 text-sm-end">
						<a href="#/corporate/life-style-service-category/add" class="waves-effect waves-light btn btn-info mb-5"><i class="fa fa-plus fs-14"></i> New Category</a>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-2">
				    <life-style-header routefrom="CorporateLifeStyleServiceCategory"></life-style-header>
				</div>
				<div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-10">
					<section class="content">
						<div class="box">
							<div class="box-body">
								 <div class="table-responsive">
									<data-table dataType="CorporateLifeStyleServiceCategory" :key="render">
									
										 <template v-slot="slotProps">
											
											<td>
												{{ slotProps.item.category_name }}
											</td>
											<td>										
							                    <span>
							                        <button type="button"  data-on="Ready" data-off="Not Ready" @click="onToggleChange(slotProps.item.id, $event)" :id="['status_' + slotProps.item.id]" class="btn btn-toggle rounded30-im"  v-bind:class="[slotProps.item.category_status == 'Active' ? 'active' : '']" data-bs-toggle="button" aria-pressed="true" autocomplete="off">
							                          <div class="handle rounded30-im"></div>
							                        </button>
							                    </span>
											</td>
											<td>
												<Popper arrow content="Edit Product" :hover="true" class="helptips cursor-pointer">
													<a @click="editcategory(slotProps.item.id)" href="#" class="waves-effect waves-circle btn btn-circle btn-info btn-xs me-1"><i class="fa fa-pencil"></i></a>
												</Popper>
											</td>
										</template>
									</data-table>
								</div> 
							</div>
						</div>
					</section>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from "@/scripts/axios.js";
	import InputField from "@/components/base/formFields/InputField.vue";
	import useVuelidate from "@vuelidate/core";
	import submitMixins from "@/scripts/mixins/submitMixins.js";
	import LifeStyleHeader from "@/components/Lifestyle/LifeStyleHeader.vue";
	import dataTable from "@/components/base/DataTable.vue";

	export default {
		name: "Home",
		mixins: [submitMixins],
		components: {InputField, dataTable, LifeStyleHeader},
		computed: {
			
		},
		data() {
			return {
				list:[],
			}
		},
		mounted(){
			 
		},
		methods:{
			editcategory(id){
				this.$router.push({ name: "CorporateLifeStyleServiceCategoryEdit", params: { id } });
			},
			onToggleChange(id, event) {
		      var class_list = document.getElementById("status_"+id).classList;
		      var staus = '';
		      if(class_list.contains('active')) {
		        staus = 'Active';
		      } else {
		        staus = 'Deactive';
		      }

		      axios
		        .post("/IV_life_style/category/status-update",{"status":staus,"id":id})
		        .then((response) => {
		          this.moshaToast('Status updated successfully.','success');
		        });
		      
		    }
		}
	
};
</script>
<style type="text/css">
	.btn-toggle:before{
		content: 'Inactive';
		left: -6rem;
	}
	.btn-toggle:after{
		content: 'Active';
		right: -5rem;
	}
</style>