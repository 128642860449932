<template>
  <div class="box">
    <div class="box-body">
      <div class="pageheading py-5">
        <div class="row justify-content-between">
          <div class="col-12 col-sm-6 col-md-6 col-lg-7 col-xl-8">
              <h2 class="font-bold my-2">Implantable Devices</h2>
          </div>
          <div class="col-12 col-sm-6 col-md-6 col-lg-5 col-xl-4 text-sm-end">
            <a
              v-if="permissioncheck(this.$store.state.permission.create_update)"
              href="javascript:void(0);"
              @click="toggleForm"
              class="waves-effect waves-light btn btn-info my-5 manual_box"
              ><i class="fa fa-plus fs-14"></i> Implantable Device</a>
          </div>
        </div>
      </div>
      <div class="controls d-inline-block mb-10">
        <input
          type="checkbox"
          id="checkbox_1"
          value="single"
          class="filled-in chk-col-info"
          @change="showInactiveDevice"
        />
        <label for="checkbox_1" class="m-0">Show Inactive Implantable Device</label>
      </div>
      <div class="table-responsive">
        <table-base
          :dataTypes="{
            dataType: 'patientImplantableDevice',
            patient_id: patient_id,
            patient_unique_id: patient_unique_id,
            method: 'get',
          }"
          :lastcol='permissioncheck(this.$store.state.permission.create_update)'
          :key="table_load_count"
        >
          <template v-slot="slotProps">
            <td>{{ slotProps.item.udi }}</td>
            <td>{{ slotProps.item.gmdn_pt_Name }}</td>
            <td>{{ slotProps.item.version_or_model }}</td>
            <td>{{ $filters.displayDateTimeLocal(slotProps.item.manufacturing_date,'',false,"ddd, MM-DD-YYYY",true) }}</td>
            <td>{{ $filters.displayDateTimeLocal(slotProps.item.expiration_date,'',false,"ddd, MM-DD-YYYY",true) }}</td>
            <td>{{ slotProps.item.serial_number }}</td>
            <td>{{ slotProps.item.lot_or_batch_no }}</td>
            <td>{{ slotProps.item.device_hctp }}</td>
            <td>{{ slotProps.item.mri_sefety_status }}</td>
            <td>{{ slotProps.item.labeled_contains_nrl }}</td>
            <td>{{ slotProps.item.status }}</td>
            <td>
              <div class="d-flex" v-if="
                  permissioncheck(this.$store.state.permission.create_update)
                ">
                <Popper arrow content="Change Status" :hover="true" class="helptips cursor-pointer">

                  <a v-if="permissioncheck(this.$store.state.permission.create_update)" href="javascript:void(0);" @click="changeStatus(slotProps.item)" class="waves-effect waves-circle btn btn-circle btn-xs me-5 s me-1" :class="slotProps.item.status == 'Active' ? 'btn-success' : 'btn-danger'" ><i class="fa fa-lightbulb-o" alt="Change Status"></i></a>
                </Popper>
                <!-- <Popper arrow content="Delete" :hover="true" class="helptips cursor-pointer">
                  <a
                    href="#"
                    data-bs-toggle="tooltip"
                    @click="deleteImplDevice(slotProps.item.id)"
                    class="waves-effect waves-circle btn btn-circle btn-danger btn-xs"
                    ><i class="fa fa-trash"></i></a>
                  </Popper> -->
              </div>
            </td>
          </template>
        </table-base>
      </div>
    </div>
  </div>

  <div class="box manual_entry d-block mt-20" v-if="show_implantable_form">
    <div class="box-body">
      <div class="content-header">
        <h3 class="heading3 font-bold m-0">Create Implantable Device</h3>
      </div>
      <div class="form-group row">
          <label class="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end">SNOMED Code </label>
          <div class="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-3">
              <div class="selectcontrols ">
                  <Autocomplete
                      @input="inputCode"
                      ref="autocompletecode"
                      :results="CodeList"
                      @onSelect="codevalue"
                      class="form-control"
                      :value="modelValue"
                      placeholder="Search SNOMED Code"
                      :use-html-for-results ="true"
                  ></Autocomplete>
              </div>
          </div>
      </div>

      <div class="form-group row" >
          <label class="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end">SNOMED Desc.</label>
          <div class="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-3">
              <div class="selectcontrols ">
                  <Autocomplete
                      @input="inputdescription"
                      ref="autocompletedescription"
                      :results="CodeLists"
                      @onSelect="descriptionvalue"
                      class="form-control"
                      :value="modelValue"
                      placeholder="Search SNOMED Description"
                      :use-html-for-results ="true"
                  ></Autocomplete>
              </div>
          </div>
      </div>
      <div
        class="form-group row" :class="{ error: v$.form.udi.$errors.length }">
        <label class="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end">Unique Device Identifiers
          <small class="text-danger asterisksign">*</small></label>
        <div class="col-10 col-sm-7 col-md-7 col-lg-5 col-xl-3">
          <div
            class="badge bg-danger-light fs-14 mb-5 text-center py-1 w-p100"
            role="alert"
            v-if="errorMessage">
            {{ errorMessage }}
          </div>
          <textarea
            class="form-control"
            v-model="form.udi"
            id="udi"
            rows="2"
          ></textarea>
          <div
            class="invalid-feedback"
            style="display:block;"
            v-for="(error, index) of v$.form.udi.$errors"
            :key="index"
          >
            {{ error.$message }}
          </div>
        </div>
        <div class="col-2 col-sm-1 col-md-1 col-lg-2 col-xs-1 ps-0">
          <a
            href="javascript:void(0)"
            @click="checkUdi"
            id="check_udi_id"
            class="waves-effect waves-light btn btn-info btn-xs text-center"
            >{{ check_udi_label }}</a
          >
        </div>
      </div>
      <div class="form-group row">
        <label
          class="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end"
          >Company Name</label
        >
        <div class="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-3">
          <input
            class="form-control"
            type="text"
            v-model="form.udi_display.company_name"
            placeholder="Company Name"
            disabled=""
          />
        </div>
      </div>
      <div class="form-group row">
        <label
          class="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end"
          >Brand Name</label
        >
        <div class="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-3">
          <input
            class="form-control"
            type="text"
            v-model="form.udi_display.brand_name"
            placeholder="Brand Name"
            disabled=""
          />
        </div>
      </div>
      <div class="form-group row">
        <label
          class="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end"
          >GMDN PT Name</label
        >
        <div class="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-3">
          <textarea
            class="form-control"
            v-model="form.udi_display.gmdn_pt_Name"
            rows="2"
            placeholder="GMDN PT Name"
            disabled=""
          ></textarea>
        </div>
      </div>
      <div class="form-group row">
        <label
          class="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end"
          >Version or Model</label
        >
        <div class="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-3">
          <input
            class="form-control"
            type="text"
            v-model="form.udi_display.version_or_model"
            placeholder="Version or Model"
            disabled=""
          />
        </div>
      </div>
      <div class="form-group row">
        <label
          class="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end"
          >Expiration Date</label
        >
        <div class="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-3">
          <input
            class="form-control"
            type="date"
            v-model="form.udi_display.expiration_date"
            disabled=""
          />
        </div>
      </div>
      <div class="form-group row">
        <label
          class="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end"
          >Manufactured Date</label
        >
        <div class="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-3">
          <input
            class="form-control"
            type="date"
            v-model="form.udi_display.manufacturing_date"
            disabled=""
          />
        </div>
      </div>
      <div class="form-group row">
        <label
          class="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end"
          >Lot or Batch</label
        >
        <div class="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-3">
          <select
            class="form-control"
            disabled=""
            v-model="form.udi_display.lot_or_batch_no"
          >
            <option :value="true">True</option>
            <option :value="false">False</option>
          </select>
        </div>
      </div>
      <div class="form-group row">
        <label
          class="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end"
          >Serial Number</label
        >
        <div class="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-3">
          <input
            class="form-control"
            type="text"
            v-model="form.udi_display.serial_number"
            disabled=""
          />
        </div>
      </div>
      <div class="form-group row">
        <label
          class="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end"
          >HCT/P Identification Code</label
        >
        <div class="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-3">
          <select
            class="form-control"
            disabled=""
            v-model="form.udi_display.device_hctp"
          >
            <option :value="true">True</option>
            <option :value="false">False</option>
          </select>
        </div>
      </div>
      <div class="form-group row">
        <label
          class="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end"
          >MRI Safety Status</label
        >
        <div class="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-3">
          <input
            class="form-control"
            type="text"
            v-model="form.udi_display.mri_sefety_status"
            disabled=""
          />
        </div>
      </div>
      <div class="form-group row">
        <label
          class="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end"
          >Labeled Contains NRL</label
        >
        <div class="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-3">
          <select
            class="form-control"
            disabled=""
            v-model="form.udi_display.labeled_contains_nrl"
          >
            <option :value="true">True</option>
            <option :value="false">False</option>
          </select>
        </div>
      </div>
      <div class="form-group row">
        <label
          class="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end"
          >Status</label
        >
        <div class="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-3">
          <select class="form-control" v-model="form.status" id="status">
            <option value="Active">Active</option>
            <option value="Inactive">Inactive</option>
          </select>
          <div
            class="invalid-feedback"
            style="display:block;"
            v-for="(error, index) of v$.form.status.$errors"
            :key="index"
          >
            {{ error.$message }}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-9">
          <div class="text-center">
            <a
              href="javascript:void(0)"
              @click="saveImplantableDevice"
              class="waves-effect waves-light btn btn-info me-5"
              >Save</a
            >
            <a
              href="javascript:void(0)"
              @click="show_implantable_form = !show_implantable_form"
              class="waves-effect waves-light btn btn-danger"
              >Close</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "@/scripts/axios.js";
import useVuelidate from "@vuelidate/core";
import submitMixins from "@/scripts/mixins/submitMixins.js";
import TableBase from "@/components/base/Table.vue";
import permissionMixins from "@/scripts/mixins/permission.js";
import Autocomplete from "vue3-autocomplete";
import Popper from "vue3-popper";
export default {
  setup: () => {
    return { v$: useVuelidate() };
  },
  mixins: [submitMixins, permissionMixins],
  components: { TableBase, Autocomplete, Popper },
  data() {
    return {
      patient_id: this.$store.state.patientDetail.patient_id,
      patient_unique_id: this.$store.state.patientDetail.patient_unique_id,
      show_implantable_form: false,
      check_udi_label: "Check UDI",
      show_inactive_device: false,
      errorMessage: "",
      table_load_count: 0,
      CodeList:[],
      CodeLists:[],
    };
  },
  validations() {
    return this.$store.state.patientImplantableDevice.validationRules;
  },
  computed: {
    form() {
      return this.$store.state.patientImplantableDevice.form;
    },
    vuelidateExternalResults() {
      return this.$store.state.patientImplantableDevice
        .vuelidateExternalResults;
    },
    validationRules() {
      return this.$store.state.patientImplantableDevice.validationRules;
    },
  },
  created() {},
  mounted() {},
  methods: {
    checkUdi() {
      this.errorMessage = "";
      this.v$.$validate();
      if (this.form.udi) {
        this.check_udi_label = "Please Wait...";
        axios
          .post(`/implantable-device/info`, { udi: this.form.udi })
          .then((response) => {
            this.check_udi_label = "Check UDI";
            this.form.udi_detailed_data = response.data.data;
            if (response.data.data) {
              this.form.udi_display = {};
              this.form.status = "Active";
              this.form.udi_display.company_name =
                response.data.data.gudid.device.companyName;
              this.form.udi_display.brand_name =
                response.data.data.gudid.device.brandName;
              this.form.udi_display.version_or_model =
                response.data.data.gudid.device.catalogNumber;
              this.form.udi_display.gmdn_pt_Name =
                response.data.data.gudid.device.gmdnTerms.gmdn.gmdnPTName;
              this.form.udi_display.device_hctp =
                response.data.data.gudid.device.deviceHCTP;
              this.form.udi_display.mri_sefety_status =
                response.data.data.gudid.device.MRISafetyStatus;
              this.form.udi_display.labeled_contains_nrl =
                response.data.data.gudid.device.labeledContainsNRL;
              this.form.udi_display.lot_or_batch_no =
                response.data.data.gudid.device.lotBatch;
              this.form.udi_display.expiration_date =
                response.data.data.expiration_date;
              this.form.udi_display.manufacturing_date =
                response.data.data.manufacturing_date;
              this.form.udi_display.serial_number =
                response.data.data.serial_number;
            }
          })
          .catch((error) => {
            console.log(error);
            this.check_udi_label = "Check UDI";
            this.errorMessage = error.response.data.message;
          });
      }
    },
    resetForm() {
      var self = this;
      Object.keys(this.form).forEach(function(key, index) {
        self.form[key] = "";
      });
      this.v$.$reset();
    },
    saveImplantableDevice() {
      this.form.patient_id = this.$store.state.patientDetail.patient_id;
      this.postForm(
        `/patient/${this.$route.params.id}/implantable-device/store`,
        "patientImplantableDevice"
      );
    },
    formPostSuccess(response) {
      this.moshaToast("Record added successfully.", "success");
      this.resetForm();
      this.show_implantable_form = !this.show_implantable_form;
      this.table_load_count++;
    },
    deleteImplDevice(id) {
      this.$swal
        .fire({
          title: '<h2 class="mt-0">Are you sure?</h2>',
          text:
            "You want to delete these records? This process cannot be undone.",
          icon: "warning",
          dangerMode: true,
          showCancelButton: true,
          confirmButtonColor: "#ee3158",
          cancelButtonColor: "#bdbdbd",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed == true) {
            axios
              .post(
                `/patient/${this.$route.params.id}/implantable-device/delete`,
                { id: id }
              )
              .then((response) => {
                this.$swal
                  .fire(
                    "Deleted!",
                    "patient implantable device has been deleted.",
                    "success"
                  )
                  .then((result) => {
                    if (result.isConfirmed == true) {
                      this.table_load_count++;
                    }
                  });
              });
          }
        });
    },
    changeStatus(obj) {
      this.$swal
        .fire({
          title: "Are you sure you want change status?",
          type: "info",
          showCancelButton: true,
          confirmButtonColor: "#0265a0",
          cancelButtonColor: "#bdbdbd",
          confirmButtonText: "Yes",
        })
        .then((result) => {
          if (result.isConfirmed == true) {
            axios
              .post(
                `/patient/${this.$route.params.id}/implantable-device/change-status`,
                { id: obj.id, status: obj.status }
              )
              .then((response) => {
                this.$swal
                  .fire("Changed!", "status changed successfully.", "success")
                  .then((result) => {
                    if (result.isConfirmed == true) {
                      this.table_load_count++;
                    }
                  });
              });
          }
        });
    },
    toggleForm() {
      this.show_implantable_form = !this.show_implantable_form;
      if (this.show_implantable_form) {
        let vm = this;
        setTimeout(function() {
          vm.$smoothScroll({
            scrollTo: document.getElementById("check_udi_id"),
          });
        }, 100);
      }
    },
    codevalue(obj){
      this.form.snomed_code = obj.code
      this.form.snomed_description = obj.description
      this.$refs.autocompletecode.searchText = obj.code
      this.$refs.autocompletedescription.searchText = obj.description
      this.$emit("update:modelValue", obj.code);
    },
    descriptionvalue(obj){
      this.form.snomed_code = obj.code
      this.form.snomed_description = obj.description
      this.$refs.autocompletecode.searchText = obj.code
      this.$refs.autocompletedescription.searchText = obj.description
      this.$emit("update:modelValue", obj.code);  
    },
    inputCode(obj) {
      if (obj.length > 1) {
          this.$refs.autocompletecode.$el.classList.add("selectloader");
          axios
          .get("/json_autocomplete/snomed-code", {
              params: { term: obj },
          })
          .then((response) => {
              //this.CodeList = response.data.data;
              this.CodeList = response.data.data.map((code) => {
                  return { id: code.id, name: "<b>"+code.code+"</b><br>"+code.description,code:code.code,description:code.description }
              });
              this.$refs.autocompletecode.$el.classList.remove("selectloader");
          });
      }   
      if (obj.length == 0) {
          this.CodeList = {};    
          this.$refs.autocompletecode.searchText = '';
          this.$refs.autocompletedescription.searchText = '';
          this.form.snomed_code = '';
          this.form.snomed_description = '';
      }
    },
    inputdescription(obj) {
        if (obj.length > 1) {
            this.$refs.autocompletedescription.$el.classList.add("selectloader");
            axios
            .get("/json_autocomplete/snomed-code", {
                params: { term: obj },
            })
            .then((response) => {
                //this.CodeLists = response.data.data;
                this.CodeLists = response.data.data.map((descriptions) => {
                    return { id: descriptions.id, name: "<b>"+descriptions.code+"</b><br>"+descriptions.description,code:descriptions.code,description:descriptions.description }
                });
                this.$refs.autocompletedescription.$el.classList.remove("selectloader");
            });
        }   
        if (obj.length == 0) {
            this.CodeLists = {};
            this.$refs.autocompletecode.searchText = '';
            this.$refs.autocompletedescription.searchText = '';
            this.form.snomed_code = '';
            this.form.snomed_description = '';
        }
    },
    showInactiveDevice() {
      this.show_inactive_device = !this.show_inactive_device;
      const listUrl = this.$store.state.patientImplantableDevice.listUrl.replace(
        "/Active",
        ""
      );
      if (this.show_inactive_device) {
        this.$store.state.patientImplantableDevice.listUrl = listUrl;
        this.table_load_count++;
      } else {
        this.$store.state.patientImplantableDevice.listUrl = listUrl + "/Active";
        this.table_load_count++;
      }
    },
  },
};
</script>
