<template>
    <div class="panel pagecollap">
        <div class="panel-heading" id="medicationsection" role="tab">
            <a class="panel-title collapsed font-bold" aria-controls="answerm-1" aria-expanded="true" data-bs-toggle="collapse" href="#answerm-1" data-parent="#accordion2">Medication</a>
        </div>
        <div class="panel-collapse collapse" id="answerm-1" aria-labelledby="medicationsection" role="tabpanel" data-bs-parent="#category-1" style="">
            <div class="panel-body">
                <div class="text-end">
                    <a v-if="permissioncheck(this.$store.state.permission.create_update)" href="javascript:void(0);" class="waves-effect waves-light btn btn-info mb-10" @click="create"><i class="fa fa-plus fs-14"></i> Medication</a>
                </div>
                <div class="table-responsive">
                    <div class="table-responsive" id="assessment_list">
                        <table-base 
                            :dataTypes="{
                                dataType: 'Medication',
                                patient_id: patient_id,
                                patient_unique_id:patient_unique_id,
                                method:'post'}"
                            :lastcol='permissioncheck(this.$store.state.permission.create_update)'
                        >
                            <template v-slot="slotProps">
                                <td>{{ slotProps.item.product_code }}</td>
                                <td>{{ slotProps.item.product_name }}</td>                               
                                <td>
                                    <slot v-if="slotProps.item.negationInd==1">
                                        True
                                    </slot>
                                    <slot v-else>
                                        False
                                    </slot>
                                </td>
                                <td>
                                    <slot v-if="slotProps.item.order_status==1">
                                        True
                                    </slot>
                                    <slot v-else>
                                        False
                                    </slot>
                                </td>
                                <td>
                                    <div class="d-flex">
                                        <Popper arrow content="Edit Medication" :hover="true" class="helptips cursor-pointer">
                                            <a 
                                            v-if="permissioncheck(this.$store.state.permission.create_update)"
                                            href="javascript:void(0)" @click="editCQMMedication(slotProps.item.id)" class="waves-effect waves-circle btn btn-circle btn-info btn-xs" ><i class="fa fa-pencil"></i></a>
                                        </Popper>
                                        <Popper arrow content="Delete Medication" :hover="true" class="helptips cursor-pointer">
                                            <a v-if="permissioncheck(this.$store.state.permission.create_update)" href="javascript:void(0)" @click="removeCQMMedication(slotProps.item.id)" class="waves-effect waves-circle btn btn-circle btn-danger btn-xs"><i class="fa fa-trash"></i></a>
                                        </Popper>
                                    </div>
                                </td>
                            </template>
                        </table-base>
                    </div>
                </div>
            </div>

            <div class="box bg-lightest new-medication d-none" id="new-medication">
                <div class="box-body">
                    <div class="content-header">
                        <h3 class="heading3 font-bold m-0">{{title}}</h3>
                    </div>
                    <input-field
                        title="Medication Code"
                        id="medication"
                        v-model="form.product_code"
                        :isRequired="false"
                        labelClass="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end col-form-label"
                        divClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-3"
                    >
                    </input-field>
                    <input-field
                        title="Medication Name"
                        id="medicationname"
                        v-model="form.product_name"
                        :isRequired="false"
                        labelClass="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end col-form-label"
                        divClass="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-3"
                    >
                    </input-field>
                    <div class="form-group row">
                        <label class="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-3 form-label mb-0 text-sm-end">Medication Status</label>
                        <div class="col-6 col-sm-8 col-md-8 col-lg-5 col-xl-3">
                            <select name="select"  class="form-select form-control" v-model="form.status">
                                <option value="Active">Active</option>
                                <option value="InActive">InActive</option>
                            </select>
                        </div>
                    </div>
                    
                    <div class="form-group row">
                        <label class="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-3 form-label mb-0 text-sm-end">Negation</label>
                        <div class="col-6 col-sm-8 col-md-8 col-lg-5 col-xl-3">
                            <input
                              type="checkbox"
                              id="mnegation_checkbox"
                              :checked="form.negationInd"
                              v-model="form.negationInd"
                              :value="form.negationInd"
                              class="filled-in chk-col-info"
                              @change="checkNegation"
                            />
                            <label for="mnegation_checkbox" class="m-0 text-start"></label>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-3 form-label mb-0 text-sm-end">Is Order ?</label>
                        <div class="col-6 col-sm-8 col-md-8 col-lg-5 col-xl-3">
                            <input
                              type="checkbox"
                              id="morder_status_checkbox"
                              :checked="form.order_status"
                              v-model="form.order_status"
                              :value="form.order_status"
                              class="filled-in chk-col-info"
                            />
                            <label for="morder_status_checkbox" class="m-0 text-start"></label>
                        </div>
                    </div>
                    <div class="row mb-10">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-9 text-center">
                            <form-btn
                                :title="btn"
                                btnCls="waves-effect waves-light btn btn-info"
                                @click="save(false)"
                            ></form-btn>
                            <button @click="close" type="button" class="waves-effect waves-light btn btn-danger ">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import TableBase from "@/components/base/Table.vue";
import useVuelidate from "@vuelidate/core";
import submitMixins from "@/scripts/mixins/submitMixins.js";
import Autocomplete from "vue3-autocomplete";
import InputField from "@/components/base/formFields/InputField.vue";
import permissionMixins from "@/scripts/mixins/permission.js";
import FormBtn from "@/components/base/formFields/formBtn.vue";
import axios from "@/scripts/axios.js";
import moment from "moment";
import Popper from "vue3-popper";

export default {
    setup: () => {
        return { v$: useVuelidate() };
    },
    mixins: [submitMixins, permissionMixins],
    components: { 
        TableBase,
        Autocomplete,
        InputField,
        FormBtn,
        Popper,
    },
    computed: {
        form() {
            return this.$store.state.Medication.form;
        },
        vuelidateExternalResults() {
            return this.$store.state.Medication.vuelidateExternalResults;
        },
        validationRules() {
            return this.$store.state.Medication.validationRules;
        },
    },
    validations() {
        return this.$store.state.Medication.validationRules;
    },
    data() 
    {
        return {
            patient_id : this.$store.state.patientDetail.patient_id,
            patient_unique_id:this.$store.state.patientDetail.patient_unique_id,
            dataTypes: {
                dataType: "Medication",
                patient_id: this.$store.state.patientDetail.patient_id,
                patient_unique_id: this.$store.state.patientDetail.patient_unique_id,
            },
            title:'Create Medication',
            btn:'Save',
        };
    },
    methods:{
        create(){
            $("#new-medication").removeClass("d-none");
            this.$store.state.Medication.form = {};
            Object.assign(this.$store.state.Medication.form);
            this.v$.$reset();           
            this.$smoothScroll({
                scrollTo: document.getElementById("new-medication"),
            });
            this.form.status = "Active";
        },
        checkNegation(event) {
            console.log(this.form.negationInd);
        },
        editCQMMedication(id){
            this.$store.state.Medication.edit = 1;
            this.title = 'Edit Medication';
            this.btn = 'Update';
            axios.get("patient/" + this.$route.params.id + "/cqm-medication/edit/" + id)
            .then((result) => {
                this.$store.state.Medication.form = result.data.data;          
            });
            $("#new-medication").removeClass("d-none");
            this.$smoothScroll({
                scrollTo: document.getElementById("new-medication"),
            });
        },
        removeCQMIntervention(id){
            this.$swal.fire({
                title: '<h2 class="mt-0">Are you sure?</h2>',
                text:"You want to delete these records? This process cannot be undone.",
                icon: "warning",
                dangerMode: true,
                showCancelButton: true,
                confirmButtonColor: "#ee3158",
                cancelButtonColor: "#bdbdbd",
                confirmButtonText: "Yes, delete it!",
            })
            .then((result) => {
                if (result.isConfirmed == true) {
                    axios
                    .post("patient/" + this.$route.params.id + "/cqm-medication/delete", {id: id,})
                    .then((response) => {
                        this.$swal
                            .fire(
                                "Deleted!",
                                "Your Care Plan has been deleted.",
                                "success"
                            )
                        .then((result) => {
                            if (result.isConfirmed == true) {
                                this.$store.dispatch("getSimpleTableContents",this.dataTypes);
                            }
                        });
                    });
                }
            });
        },
        save(next) {
            if(this.$store.state.Medication.edit == 1){
                this.postForm(`/patient/${this.$route.params.id}/cqm-medication/update`,"Assessment");
            }
            else{
                this.postForm(`/patient/${this.$route.params.id}/cqm-medication/store`,"Assessment");   
            }
        },
        formPostSuccess(data) {
            this.$store.state.Medication.form = {};
            Object.assign(this.$store.state.Medication.form);
            this.v$.$reset();
            
            this.observation_date = "";
            this.$store.dispatch("getSimpleTableContents", this.dataTypes);
            if(this.$store.state.Medication.edit == 1){
                this.moshaToast("Record added successfully.", "success");
            }
            else{
                this.moshaToast("Record updated successfully.", "success");   
            }
            $("#new-medication").addClass("d-none");
            this.$smoothScroll({
                scrollTo: document.getElementById("assessment_list"),
            });
        },
        close(){
            this.$store.state.Medication.edit = 0;
            this.$store.state.Medication.form = {};
            Object.assign(this.$store.state.Medication.form);
            this.v$.$reset();
            this.$store.dispatch("getSimpleTableContents", this.dataTypes);
            $("#new-medication").addClass("d-none");
            this.$smoothScroll({
                scrollTo: document.getElementById("assessment_list"),
            });
        },
        removeCQMMedication(id){
            this.$swal.fire({
                title: '<h2 class="mt-0">Are you sure?</h2>',
                text:"You want to delete these records? This process cannot be undone.",
                icon: "warning",
                dangerMode: true,
                showCancelButton: true,
                confirmButtonColor: "#ee3158",
                cancelButtonColor: "#bdbdbd",
                confirmButtonText: "Yes, delete it!",
            })
            .then((result) => {
                if (result.isConfirmed == true) {
                    axios
                    .post("patient/" + this.$route.params.id + "/cqm-medication/delete", {id: id})
                    .then((response) => {
                        this.$swal
                            .fire(
                                "Deleted!",
                                "Medication has been deleted.",
                                "success"
                            )
                        .then((result) => {
                            if (result.isConfirmed == true) {
                                this.$store.dispatch("getSimpleTableContents",this.dataTypes);
                            }
                        });
                    });
                }
            });
        },
    },
    mounted(){

    }
};
</script>