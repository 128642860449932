<template>
	<div class="content-wrapper">
		<div class="container">
			<div class="row align-items-center">
				<div class="col-12 col-sm-6 col-md-6 col-lg-6">
					<img v-if="businessLogo != ''" :src="businessLogo" class="img-fluid mb-10" style="width: 240px; height: 105px;">
					<!-- <img v-else src="images/purewellness-header.png" class="img-fluid mb-10" style="width: 240px; height: 105px;"> -->
				</div>
				<div class="col-12 col-sm-6 col-md-6 col-lg-6">
					<h1 class="heading1 font-bold my-2 text-sm-end text-info">{{ (this.contractdata && this.contractdata.plan_category == 'Weight Management') ? 'Weight Management Agreement' : 'Membership Agreement' }}  </h1>
				</div>
			</div>
		</div>

		<div class="container">
			<div class="" v-if="this.server_error == ''">
				<section class="" v-if="this.is_pdf == false">
					<div class="box mt-20">
						<div class="box-body p-20">
							<p>This {{ (this.contractdata && this.contractdata.plan_category == 'Weight Management') ? 'Weight Management Agreement' : 'Membership Agreement' }} (“Agreement”) is made between Pure Wellness Medical, PLLC, Inc. d/b/a Pure Wellness Medical, PLLC (“PURE WELLNESS MEDICAL” or “Company”) and:</p>

							<p class="fw-600">Member : <span class="bg-lightest p-5 ms-1 b-1 border-secondary rounded5 d-inline-block"> {{contractdata['patient_name']}}</span></p>

							<p>Herein, the term “Member” or “Client,” for purposes of this Agreement, when singular, either may be referred to as the Party, or when plural, the Parties</p>

							<p v-if="this.contractdata && this.contractdata.plan_category == 'Weight Management'">
								<b>
									We have the ability for you to pay for our program through our financing or through outside sources. Your interest rate will be dependent on your credit profile. All fees are subject to change.
								</b>
							</p>

							<!-- <div class="row">
								<div class="col-12 col-md-2"><b>Payment Terms :</b></div>
								<div class="col-12 col-md-3">
									<div class="controls">
										<input type="checkbox" id="final_amount_1" class="filled-in chk-col-info" v-model="this.final_amount_1_value" @change="paymentCheck(1)">
										<label for="final_amount_1" class="m-0 text-start"><b> 1 Payment of ${{contractdata['final_amount_1']}}</b></label>
										<p v-if="this.final_amount_1_value == true" :class="this.different_class  == true ? 'text-success' : 'text-danger'" id="different_msg1" class="mb-1">{{this.different_msg}}</p>
									</div>
								</div>
								<div class="col-12 col-md-3" v-if="contractdata['is_heavy_discounted'] == false">
									<input type="checkbox" id="final_amount_6" class="filled-in chk-col-info" v-model="this.final_amount_6_value" @change="paymentCheck(6)">
									<label for="final_amount_6" class="m-0 text-start"><b> 6 Payment of ${{contractdata['final_amount_6']}} </b></label>
									<p v-if="this.final_amount_6_value == true" :class="this.different_class  == true ? 'text-success' : 'text-danger'" id="different_msg6" class="mb-1">{{this.different_msg}}</p>
								</div>

								<div class="col-12 col-md-3" v-if="contractdata['is_heavy_discounted'] == false">
									<input type="checkbox" id="final_amount_12" class="filled-in chk-col-info" v-model="this.final_amount_12_value" @change="paymentCheck(12)">
									<label for="final_amount_12" class="m-0 text-start"><b> 12 Payment of ${{contractdata['final_amount_12']}} </b></label>
									<p v-if="this.final_amount_12_value == true" :class="this.different_class  == true ? 'text-success' : 'text-danger'" id="different_msg12" class="mb-1">{{this.different_msg}}</p>
								</div>
							</div> -->
							<p><b>Please check all the statements marked with a check box below to start your program.</b></p>

							<p id="authorization_read"><b>Auto Pay Authorization <small class="text-danger">(All * are mandatory to check)</small></b></p>
							
							<template v-if="(this.contractdata && this.contractdata.plan_category == 'HRT') && this.contractdata.is_renewal">
								<div class="form-group check_alert">
									<input type="checkbox" id="pay_authorization0" class="filled-in chk-col-info" v-model="this.pay_authorization0">
									<label for="pay_authorization0" class="text-justify"><span class="text-danger">*</span> Member hereby authorize PURE WELLNESS MEDICAL to automatically collect dues by drafting Visa/MasterCard/AMEX or through Credit Financing as indicated below. This authorization will remain in full effect for a minimum of <b>twelve months</b> (“Initial Term”). This membership is continuous and only after the same day of month agreement is signed can be terminated without cause by written notification to PURE WELLNESS MEDICAL as set forth herein this Agreement.</label>
								</div>
								<div class="form-group check_alert">
									<input type="checkbox" id="pay_authorization1" class="filled-in chk-col-info" v-model="this.pay_authorization1">
									<label for="pay_authorization1" class="text-justify"><span class="text-danger">*</span> Member is fully aware and understand that if member intends to cancel, freeze, or alter membership in any way after the Initial Term, the member must do so giving a minimum of 90 days written notice.</label>
								</div>
								<div class="form-group check_alert">
									<input type="checkbox" id="pay_authorization2" class="filled-in chk-col-info" v-model="this.pay_authorization2">
									<label for="pay_authorization2" class="text-justify"><span class="text-danger">*</span> Member is fully aware and understand that you may be subjected to the contract cancellation fee of $175 and   needs to be done 90 days before the next paying cycle.</label>
								</div>
								<div class="form-group check_alert">
									<input type="checkbox" id="pay_authorization3" class="filled-in chk-col-info" v-model="this.pay_authorization3">
									<label for="pay_authorization3" class="text-justify"><span class="text-danger">*</span> You will be charged exactly on the date that this agreement is signed. Contract end twelve months from the signed agreement date.</label>
								</div>
								<div class="form-group check_alert">
									<input type="checkbox" id="pay_authorization4" class="filled-in chk-col-info" v-model="this.pay_authorization4">
									<label for="pay_authorization4" class="text-justify"><span class="text-danger">*</span> You will be contacted starting at 90-days regarding your renewal for your annual contract. Any medications that prescribed for 90-days will NOT BE ABLE TO BE ORDERED unless the renewal contract is signed. </label>
								</div>
								<div class="form-group check_alert">
									<input type="checkbox" id="pay_authorization5" class="filled-in chk-col-info" v-model="this.pay_authorization5">
									<label for="pay_authorization5" class="text-justify"><span class="text-danger">*</span> If you choose auto-renewal your annual contract will be renewed at same rate for the next 24 months from the date of this contract. Any questions or concerns, please contact us 60-days before the termination of your agreement.</label>
								</div>
								<div class="form-group check_alert">
									<input type="checkbox" id="pay_authorization6" class="filled-in chk-col-info" v-model="this.pay_authorization6">
									<label for="pay_authorization6" class="text-justify"><span class="text-danger">*</span> Member chose auto-renewal to guarantee discounted rate for next 24 months.</label>
								</div>
								<div class="form-group check_alert">
									<input type="checkbox" id="pay_authorization7" class="filled-in chk-col-info" v-model="this.pay_authorization7">
									<label for="pay_authorization7" class="text-justify"><span class="text-danger">*</span> Member is fully aware if on an auto renew, the member will be automatically billed 24-hours after the completion of contract.</label>
								</div>
								<div class="form-group check_alert">
									<input type="checkbox" id="pay_authorization8" class="filled-in chk-col-info" v-model="this.pay_authorization8">
									<label for="pay_authorization8" class="text-justify"><span class="text-danger">*</span> Member DOES NOT CHOOSE auto-renewal, no discounted price is guaranteed when contract is up for renewal.</label>
								</div>
								<div class="form-group check_alert">
									<input type="checkbox" id="pay_authorization9" class="filled-in chk-col-info" v-model="this.pay_authorization9">
									<label for="pay_authorization9" class="text-justify"><span class="text-danger">*</span> All prices are subject to change at any time. You will be notified of any change in prices or of included services at time of renewal.</label>
								</div>
								<div class="form-group check_alert">
									<input type="checkbox" id="pay_authorization10" class="filled-in chk-col-info" v-model="this.pay_authorization10">
									<label for="pay_authorization10" class="text-justify"><span class="text-danger">*</span> Monthly charges will occur on the 1st or 15th of the month, if the funds are not collected at time of charge, contract is automatically cancelled and a $175.00 re-initiation fee and other additional fees will be assessed to restart the contract.</label>
								</div>
							</template>
							<template v-else-if="(this.contractdata && this.contractdata.plan_category == 'HRT') && !this.contractdata.is_renewal">
								<div class="form-group check_alert">
									<input type="checkbox" id="pay_authorization0" class="filled-in chk-col-info" v-model="this.pay_authorization0">
									<label for="pay_authorization0" class="text-justify"><span class="text-danger">*</span>Member hereby authorize PURE WELLNESS MEDICAL to automatically collect dues by drafting Visa/MasterCard/AMEX or through Credit Financing as indicated below. This authorization will remain in full effect for a minimum of twelve months (“Initial Term”). This membership is continuous and only after the same day of month agreement is signed can be terminated without cause by written notification to PURE WELLNESS MEDICAL as set forth herein this Agreement.</label>
								</div>
								<div class="form-group check_alert">
									<input type="checkbox" id="pay_authorization1" class="filled-in chk-col-info" v-model="this.pay_authorization1">
									<label for="pay_authorization1" class="text-justify"><span class="text-danger">*</span>Member is fully aware and understand that if member intends to cancel, freeze, or alter membership in any way after the Initial Term, the member must do so giving a minimum of 90 days written notice.</label>
								</div>
								<div class="form-group check_alert">
									<input type="checkbox" id="pay_authorization2" class="filled-in chk-col-info" v-model="this.pay_authorization2">
									<label for="pay_authorization2" class="text-justify"><span class="text-danger">*</span>Member is fully aware and understand that you may be subjected to the contract cancellation fee of $175 and   needs to be done 90 days before the next paying cycle.</label>
								</div>
								<div class="form-group check_alert">
									<input type="checkbox" id="pay_authorization3" class="filled-in chk-col-info" v-model="this.pay_authorization3">
									<label for="pay_authorization3" class="text-justify"><span class="text-danger">*</span>You will be charged exactly on the date that this agreement is signed. Contract end twelve months from the signed agreement date.</label>
								</div>
								<div class="form-group check_alert">
									<input type="checkbox" id="pay_authorization4" class="filled-in chk-col-info" v-model="this.pay_authorization4">
									<label for="pay_authorization4" class="text-justify"><span class="text-danger">*</span>You will be contacted starting at 90-days regarding your renewal for your annual contract. Any medications that prescribed for 90-days will NOT BE ABLE TO BE ORDERED unless the renewal contract is signed.</label>
								</div>
								<div class="form-group check_alert">
									<input type="checkbox" id="pay_authorization5" class="filled-in chk-col-info" v-model="this.pay_authorization5">
									<label for="pay_authorization5" class="text-justify"><span class="text-danger">*</span>If you choose auto-renewal your annual contract will be renewed at same rate for the next 24 months from the date of this contract. Any questions or concerns, please contact us 60-days before the termination of your agreement.</label>
								</div>
								<div class="form-group check_alert">
									<input type="checkbox" id="pay_authorization6" class="filled-in chk-col-info" v-model="this.pay_authorization6">
									<label for="pay_authorization6" class="text-justify"><span class="text-danger">*</span>Member chose auto-renewal to guarantee discounted rate for next 24 months.</label>
								</div>
								<div class="form-group check_alert">
									<input type="checkbox" id="pay_authorization7" class="filled-in chk-col-info" v-model="this.pay_authorization7">
									<label for="pay_authorization7" class="text-justify"><span class="text-danger">*</span>Member is fully aware if on an auto renew, the member will be automatically billed 24-hours after the completion of contract.</label>
								</div>
								<div class="form-group check_alert">
									<input type="checkbox" id="pay_authorization8" class="filled-in chk-col-info" v-model="this.pay_authorization8">
									<label for="pay_authorization8" class="text-justify"><span class="text-danger">*</span>Member DOES NOT CHOOSE auto-renewal, no discounted price is guaranteed when contract is up for renewal.</label>
								</div>
								<div class="form-group check_alert">
									<input type="checkbox" id="pay_authorization9" class="filled-in chk-col-info" v-model="this.pay_authorization9">
									<label for="pay_authorization9" class="text-justify"><span class="text-danger">*</span>All prices are subject to change at any time. You will be notified of any change in prices or of included services at time of renewal.</label>
								</div>
								<div class="form-group check_alert">
									<input type="checkbox" id="pay_authorization10" class="filled-in chk-col-info" v-model="this.pay_authorization10">
									<label for="pay_authorization10" class="text-justify"><span class="text-danger">*</span>Monthly charges will occur on the 1st or 15th of the month, if the funds are not collected at time of charge, contract is automatically cancelled and a $175.00 re-initiation fee and other additional fees will be assessed to restart the contract.</label>
								</div>
							</template>
							<template v-else-if="(this.contractdata && this.contractdata.plan_category == 'Weight Management')">
								<div class="form-group check_alert">
									<input type="checkbox" id="pay_authorization0" class="filled-in chk-col-info" v-model="this.pay_authorization0">
									<label for="pay_authorization0" class="text-justify"><span class="text-danger">*</span> Member hereby authorize PURE WELLNESS MEDICAL to automatically collect dues by drafting Visa/MasterCard/AMEX or through our financing or through outside sources as indicated below. This authorization will remain in full effect for a minimum of twelve weeks (“Initial Term”). This membership is continuous and only after the same day of month agreement is signed can be terminated without cause by written notification to PURE WELLNESS MEDICAL as set forth herein this Agreement.</label>
								</div>
								<div class="form-group check_alert">
									<input type="checkbox" id="pay_authorization1" class="filled-in chk-col-info" v-model="this.pay_authorization1">
									<label for="pay_authorization1" class="text-justify"><span class="text-danger">*</span> Member is fully aware and understand that if I intend to cancel, freeze, or alter my membership in any way after the Initial Term, I must do so giving a minimum of 30 days written notice.</label>
								</div>
								<div class="form-group check_alert">
									<input type="checkbox" id="pay_authorization2" class="filled-in chk-col-info" v-model="this.pay_authorization2">
									<label for="pay_authorization2" class="text-justify"><span class="text-danger">*</span> Member is fully aware and understand the 24-hour cancellation policy which states a fee of $100 will be assessed for any scheduled appointment which is canceled by the member without 24-hour notice, or a no show and will result in a loss of that appointment.</label>
								</div>
							</template>
							<template v-if="(this.contractdata && this.contractdata.plan_category == 'HRT')">
								<p>The above member hereby purchases PURE WELLNESS MEDICAL membership services from PURE WELLNESS MEDICAL and agrees to pay PURE WELLNESS MEDICAL in accordance with this Agreement and to comply with all terms and conditions of this Membership Agreement (including the terms set forth on the following page, which are hereby incorporated by reference). To reinstate your membership following termination you will have to pay a new enrollment fee.</p>
							</template>
							<template v-else>
								<p>
									The above member hereby purchases Pure Wellness Medical Weight Management services from Pure Wellness Medical and agrees to pay Pure Wellness Medical in accordance with this Agreement and to comply with all terms and conditions of this Weight Management Agreement (including the terms set forth on the following page, which are hereby incorporated by reference). To reinstate your membership following termination you will have to pay a new enrollment fee.
								</p>
							</template>

							<h3 class="fw-600 text-center">NOTICE TO MEMBERS / CONSUMERS / NON-REFUNDABLE, NON-RETURNABLE POLICY</h3>
							<template v-if="(this.contractdata && this.contractdata.plan_category == 'HRT')">
								<p class="text-justify">You are entitled to a copy of this Member Agreement at the time that you sign it. If you cancel this Member Agreement, you are not entitled to a refund of your money for any amounts already earned and due. Additionally, for the Initial Term, such amounts due are final, non-refundable or non-transferable. You are purchasing this agreement at a discounted rate; any dispute of this non-cancellable contract will cause a charge of the full retail rate of charge. Services to be rendered to the Member under the Agreement shall not obligate the Member for more than three calendar years from the date the Member Agreement is signed by the Member. </p>
							</template>
							<template v-else>
								<p class="text-justify">You are entitled to a copy of this Member Agreement at the time that you sign it. If you cancel this Weight Management Agreement, you are not entitled to a refund of your money for any amounts already earned and due. Additionally, for the Initial Term, such amounts due are non-refundable or non-transferable.  Services to be rendered to the Member under the Weight Management Agreement shall not obligate the Member for more than three months from the date the Member Agreement is signed by the Member.</p>
							</template>

							<h3 class="fw-600 text-center">GENERAL TERMS & CONDITIONS</h3>

							<p class="text-justify">This Member Agreement is a contract between you and Pure Wellness Medical (“PURE WELLNESS MEDICAL”), which governs the terms and conditions of your relationship with PURE WELLNESS MEDICAL. Throughout the documentation, you will be referred to either as a member or Client. You also will be considered the Guarantor for all payments due under any Member Agreements or agreements hereunder. This applies to all PURE WELLNESS MEDICAL accounts.</p>

							<p class="text-justify">By execution of the below, and by your continued use of the services provided by PURE WELLNESS MEDICAL, you agree that you are a resident of the United States, you are at least 18 years old, or the age of majority in your state of residence. If you are a business client, the business must be organized in, operating in, or a resident of, the United States or one of its territories, must be in good standing, and must function in compliance with all laws, regulations and governmental policies and procedures.</p>

							<p class="text-justify">By becoming a client of PURE WELLNESS MEDICAL, and by using the PURE WELLNESS MEDICAL services, you agree to comply with all of the terms and conditions of this Member Agreement, including all other agreements identified below, and any upcoming changes, of which you will be notified. These terms include an agreement to resolve disputes by arbitration on an individual basis, a guarantor statement, and all HIPAA privacy forms and procedures.</p>

							<p class="text-justify">Please read carefully all of the terms and conditions of this Member Agreement, the terms of each of the Polices listed below, which can all be access via our webpage, and each of the other agreements that apply to you. See <a href="https://purewellnessmedical.com/terms-and-conditions/" target="_blank" class="text-decoration-underline fw-600">https://purewellnessmedical.com/terms-and-conditions/</a> for all relevant policies and terms.</p>

							<p class="text-justify">PURE WELLNESS MEDICAL may revise this {{ (this.contractdata && this.contractdata.plan_category == 'Weight Management') ? 'Weight Management Agreement' : 'Membership Agreement' }} and any of the policies listed below from time to time. The revised version will be effective at the time we post it on our websites, unless otherwise noted. If our changes reduce your rights or increase your responsibilities, we agree to provide you with at least 14 days advance notice for any changes impacting personal client accounts and/or at least 5 days advance notice for any changes impacting business client accounts. By continuing to use the PURE WELLNESS MEDICAL services after any changes to this Client Agreement, you agree to abide and be bound by those changes. If you do not agree with any changes to this user agreement, please notify our offices as soon as practical, so we may review the next steps toward resolution.</p>

							<p class="text-justify">By initialing below, you hereby agree that you have in fact read, understood, considered, and agreed to each individual policy of PURE WELLNESS MEDICAL as of the date of initialing. You also agree that you were given an opportunity to discuss such policy and its implications with Counsel of your choice. You hereby agree that you were not coerced into this agreement, that none of its terms are patently unfair, and that you signed on your own volition.</p>

							<div class="table-responsive outer-scroll_table mb-30" id="relevant_policy">
								<table class="table table-bordered fs-16 l-h-n">
									<thead>
										<tr>
											<th>Name of Relevant Policy & Document</th>
											<th>Check Confirms you <br> read Statement</th>
											<th>Request For you to Print <br> Have to choose One option</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>
												<a href="https://purewellnessmedical.com/terms-and-conditions/#Terms_Conditions" target="_blank" class="text-decoration-underline">Terms & Conditions</a>
											</td>
											<td :class="this.error.terms_and_cond_read_error  == true ? 'bg-danger' : ''">
												<div class="form-check check_alert d-inline-block" >
													<input type="checkbox" id="terms_and_cond_read" class="filled-in chk-col-info" v-model="this.form.terms_and_cond_read" @change="this.form.terms_and_cond_read == true ? this.error.terms_and_cond_read_error = '' : this.error.terms_and_cond_read_error = true ">
													<label for="terms_and_cond_read" class="">Yes</label>
												</div>
											</td>
											<td :class="this.error.terms_and_cond_error  == true ? 'bg-danger' : ''">
												<div class="form-check check_alert d-inline-block">
													<input type="checkbox" id="terms_and_cond_yes" class="filled-in chk-col-info" v-model="this.form.terms_and_cond_yes" @change="termsAndCondCheck('terms_and_cond_yes')">
													<label for="terms_and_cond_yes" class="me-3 ps-20">Yes</label>
													<input type="checkbox" id="terms_and_cond_no" class="filled-in chk-col-info" v-model="this.form.terms_and_cond_no" @change="termsAndCondCheck('terms_and_cond_no')">
													<label for="terms_and_cond_no" class="m-0 ps-20">No</label>
												</div>
											</td>
										</tr>

										<tr>
											<td><a href="https://purewellnessmedical.com/terms-and-conditions/#CONSENT_FORM_PARTICIPATE_IN_RESEARCH_STU" target="_blank" class="text-decoration-underline">Consent Form to Participate In A Research Study</a></td>
											<td>
												<div class="form-check d-inline-block">
													<input type="checkbox" id="consent_and_research_read" class="filled-in chk-col-info" v-model="this.form.consent_and_research_read">
													<label for="consent_and_research_read" class="">Yes</label>
												</div>
											</td>
											<td :class="this.error.consent_and_research_error  == true ? 'bg-danger' : ''">
												<div class="form-check check_alert d-inline-block">
													<input type="checkbox" id="consent_and_research_yes" class="filled-in chk-col-info" v-model="this.form.consent_and_research_yes" @change="consentAndResearchCheck('consent_and_research_yes')">
													<label for="consent_and_research_yes" class="me-3 ps-20">Yes</label>
													<input type="checkbox" id="consent_and_research_no" class="filled-in chk-col-info" v-model="this.form.consent_and_research_no" @change="consentAndResearchCheck('consent_and_research_no')">
													<label for="consent_and_research_no" class="m-0 ps-20">No</label>
												</div>
											</td>
										</tr> 

										<tr >
											<td><a href="https://purewellnessmedical.com/terms-and-conditions/#Arbitration_Agreement" target="_blank" class="text-decoration-underline">Arbitration Agreement</a></td>
											<td :class="this.error.arbitration_agree_read_error  == true ? 'bg-danger' : ''">
												<div class="form-check check_alert d-inline-block">
													<input type="checkbox" id="arbitration_agree_read" class="filled-in chk-col-info" v-model="this.form.arbitration_agree_read"  @change="this.form.arbitration_agree_read == true ? this.error.arbitration_agree_read_error = '' : this.error.arbitration_agree_read_error = true ">
													<label for="arbitration_agree_read" class="">Yes</label>
												</div>
											</td>
											<td :class="this.error.arbitration_agree_error  == true ? 'bg-danger' : ''">
												<div class="form-check check_alert d-inline-block">
													<input type="checkbox" id="arbitration_agree_yes" class="filled-in chk-col-info" v-model="this.form.arbitration_agree_yes" @change="arbitrationAgreeCheck('arbitration_agree_yes')">
													<label for="arbitration_agree_yes" class="me-3 ps-20">Yes</label>
													<input type="checkbox" id="checkbox_7" class="filled-in chk-col-info" v-model="this.form.arbitration_agree_no" @change="arbitrationAgreeCheck('arbitration_agree_no')">
													<label for="checkbox_7" class="m-0 ps-20">No</label>
												</div>
											</td>
										</tr>

										<tr>
											<td><a href="https://purewellnessmedical.com/terms-and-conditions/#HIPAA_Notice_Of_Privacy_Practices_Policy" target="_blank" class="text-decoration-underline">HIPAA Notice of Privacy Practices & Policy</a></td>
											<td :class="this.error.hippa_notice_privacy_read_error  == true ? 'bg-danger' : ''">
												<div class="form-check check_alert d-inline-block">
													<input type="checkbox" id="hippa_notice_privacy_read" class="filled-in chk-col-info" v-model="this.form.hippa_notice_privacy_read" @change="this.form.hippa_notice_privacy_read == true ? this.error.hippa_notice_privacy_read_error = '' : this.error.hippa_notice_privacy_read_error = true ">
													<label for="hippa_notice_privacy_read" class="">Yes</label>
												</div>
											</td>
											<td :class="this.error.hippa_notice_privacy_error  == true ? 'bg-danger' : ''">
												<div class="form-check check_alert d-inline-block">
													<input type="checkbox" id="hippa_notice_privacy_yes" class="filled-in chk-col-info" v-model="this.form.hippa_notice_privacy_yes" @change="hippaNoticePrivacyCheck('hippa_notice_privacy_yes')">
													<label for="hippa_notice_privacy_yes" class="me-3 ps-20">Yes</label>
													<input type="checkbox" id="hippa_notice_privacy_no" class="filled-in chk-col-info" v-model="this.form.hippa_notice_privacy_no" @change="hippaNoticePrivacyCheck('hippa_notice_privacy_no')">
													<label for="hippa_notice_privacy_no" class="m-0 ps-20">No</label>
												</div>
											</td>
										</tr>

										<tr>
											<td><a href="https://purewellnessmedical.com/terms-and-conditions/#HORMONE_REPLACEMENT_THERAPY_CONSENT" target="_blank" class="text-decoration-underline">Hormone Replacement Therapy Consent</a></td>
											<td :class="this.error.hormone_replace_therapy_read_error  == true ? 'bg-danger' : ''">
												<div class="form-check check_alert d-inline-block">
													<input type="checkbox" id="hormone_replace_therapy_read" class="filled-in chk-col-info" v-model="this.form.hormone_replace_therapy_read"  @change="this.form.hormone_replace_therapy_read == true ? this.error.hormone_replace_therapy_read_error = '' : this.error.hormone_replace_therapy_read_error = true ">
													<label for="hormone_replace_therapy_read" class="">Yes</label>
												</div>
											</td>
											<td :class="this.error.hormone_replace_therapy_error  == true ? 'bg-danger' : ''">
												<div class="form-check check_alert d-inline-block">
													<input type="checkbox" id="hormone_replace_therapy_yes" class="filled-in chk-col-info" v-model="this.form.hormone_replace_therapy_yes" @change="hormoneReplaceTherapyCheck('hormone_replace_therapy_yes')">
													<label for="hormone_replace_therapy_yes" class="me-3 ps-20">Yes</label>
													<input type="checkbox" id="hormone_replace_therapy_no" class="filled-in chk-col-info" v-model="this.form.hormone_replace_therapy_no" @change="hormoneReplaceTherapyCheck('hormone_replace_therapy_no')">
													<label for="hormone_replace_therapy_no" class="m-0 ps-20">No</label>
												</div>
											</td>
										</tr>

										<tr>
											<td><a href="https://purewellnessmedical.com/terms-and-conditions/#Electronic_Communication_Policy" target="_blank" class="text-decoration-underline">Electronic Communications Delivery Policy <br> (E-Sign Disclosure and Consent)</a></td>
											<td :class="this.error.esign_disclosure_read_error  == true ? 'bg-danger' : ''">
												<div class="form-check check_alert d-inline-block">
													<input type="checkbox" id="esign_disclosure_no_read" class="filled-in chk-col-info" v-model="this.form.esign_disclosure_read"  @change="this.form.esign_disclosure_read == true ? this.error.esign_disclosure_read_error = '' : this.error.esign_disclosure_read_error = true ">
													<label for="esign_disclosure_no_read" class="">Yes</label>
												</div>
											</td>
											<td :class="this.error.esign_disclosure_error  == true ? 'bg-danger' : ''">
												<div class="form-check check_alert d-inline-block">
													<input type="checkbox" id="esign_disclosure_yes" class="filled-in chk-col-info" v-model="this.form.esign_disclosure_yes" @change="esignDisclosureCheck('esign_disclosure_yes')">
													<label for="esign_disclosure_yes" class="me-3 ps-20">Yes</label>
													<input type="checkbox" id="esign_disclosure_no" class="filled-in chk-col-info" v-model="this.form.esign_disclosure_no" @change="esignDisclosureCheck('esign_disclosure_no')">
													<label for="esign_disclosure_no" class="m-0 ps-20">No</label>
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</div>

							<h3 class="fw-600 text-center">FINANCIAL RESPONSIBILY & GUARANTEE</h3>

							<p class="text-justify">The Client / Member is also a “Guarantor” for all debts or charges associated with this Membership Agreement, or any Services provided by PURE WELLNESS MEDICAL or Dr. Suarez. In accordance with the attached FINANCIAL RESPONSIBILITY & GUARANTEE STATEMENT, the Guarantor irrevocably and unconditionally guarantees, as a primary obligor and not merely as a surety, to PURE WELLNESS MEDICAL or Dr. Suarez, the punctual payment and performance when due, as it relates to any and all debts and costs, as of the time of execution of this Membership Agreement, or hereafter existing or arising under or relating to the Services provided or Membership Agreement, plus any and all expenses and costs (including reasonable attorney’s fees) relating to enforcement of PURE WELLNESS MEDICAL’s or Dr. Suarez’s rights (the “Guaranteed Obligations”). Guarantor further agrees that the Guaranteed Obligations are absolute, present and continuing and shall not be discharged or impaired for any reason.</p>

							<h3 class="fw-600 text-center">ENTIRE AGREEMENT</h3>

							<p class="text-justify">This Member Agreement, together with all underlying documentation, including but not limited to the Member Agreement Addendum, Terms & Conditions, Consent Form To Participate In A Research Study, Arbitration Agreement, HIPAA Notice of Privacy Practices & Policy, Hormone Replacement Therapy Consent, and Electronic Communications Delivery Policy, contain the entire understanding between the Parties with respect to services provided by PURE WELLNESS MEDICAL and Dr. Suarez, and supersede all prior agreements and understanding, oral or written, with respect to such matters, which the parties acknowledge have been merged into all such underlying documentation.</p>

							<p class="text-justify">This Agreement is the entire agreement between the Parties with respect to the subject matter hereof and supersedes any prior agreement or communications between the Parties, whether written, oral, electronic, or otherwise. No change, modification, amendment, or addition of or to this Agreement shall be valid unless in writing and signed by authorized representatives of the Parties. This Agreement shall be binding upon and inure to the benefit of the successors, assigns, and legal representatives of the Parties.</p>

							<p class="text-justify">The Member hereby acknowledges that the Member has fully read and understands each of the above provisions, and that the Member understands and acknowledges that they are giving up significant legal rights, including limiting the right to recover in events of conflict. The Member acknowledges that prior to signing this Agreement, the Member had an opportunity to consult with an attorney to review the Agreement. The Member is at least 18 years of age and fully competent. The member executed this agreement voluntarily and for adequate consideration, intending to be fully bound by its terms.</p>

							<p class="fw-600">Member : <span class="bg-lightest p-5 ms-1 b-1 border-secondary rounded5 d-inline-block">{{contractdata['patient_name']}}</span></p>

							<div class="row" id="legalguardian">
								<div class="col-12 col-md-7">
									<p>Are you the Member or the Member's Legal Guardian (If Member 18 years Old)? <span class="text-danger">*</span></p>
								</div>
								<div class="col-12 col-md-1">
									<div class="form-group check_alert">
										<input type="checkbox" id="legalguardianyes" class="filled-in chk-col-info" v-model="this.form.is_guardian_yes"  @change="guardianCheck('is_guardian_yes')">
										<label for="legalguardianyes" class="fw-900">Yes</label>
									</div>
								</div>
								<div class="col-12 col-md-1" >
									<div class="form-group check_alert">
										<input type="checkbox" id="legalguardianno" class="filled-in chk-col-info" v-model="this.form.is_guardian_no"  @change="guardianCheck('is_guardian_no')">
										<label for="legalguardianno" class="fw-900">No</label>  
									</div>
								</div>
							</div>

							<div class="row">
								<div class="col-12 col-sm-6">
									<p class="fw-600">Contract Start Date: <span class="bg-lightest p-5 ms-1 b-1 border-secondary rounded5 d-inline-block"> {{this.contractdata['contract_start']}} </span></p>
								</div>
								<div class="col-12 col-sm-6">
									<p class="fw-600">Contract End Date: <span class="bg-lightest p-5 ms-1 b-1 border-secondary rounded5 d-inline-block"> {{this.contractdata['contract_end']}} </span></p>
								</div>
							</div>

							<p class="fw-600">Date: <span class="bg-lightest p-5 ms-1 b-1 border-secondary rounded5 d-inline-block"> {{contractdata['contract_start']}}</span></p>

							<p class="bg-lightest p-5 b-1 border-secondary rounded5 fs-18 d-inline-block"><b>{{contractdata['wellness_coordinator']}}</b></p>
							<p>Pure Wellness Coordinator</p>

							<p class="text-justify">In conjunction with the Membership Agreement (“Agreement”) entered into by and between Pure Wellness Medical, PLLC, Inc. d/b/a Pure Wellness Medical, PLLC (“PURE WELLNESS MEDICAL” or “Company”) and <b class="bg-lightest p-5 b-1 border-secondary rounded5 fs-18 d-inline-block">{{contractdata['patient_name']}}</b> (“Member,” “Client,” or “Guarantor”), in consideration for the services rendered by PWM, the Member hereby agrees to the following:</p>

							<ol class="text-justify">
								<li>The Member agrees to be financially responsible for payment of PURE WELLNESS MEDICAL’s services. Cash, debit/credit cards, bank or certified checks are acceptable forms of payment for these services. Current insurance cards must be presented at every office visit. PURE WELLNESS MEDICAL is not responsible for filing your insurance claim, but as a courtesy we will do so. Member agrees to pay the remaining balance after the insurance has paid on behalf of the Member’s claim immediately upon receipt of a statement.</li>
								<li>Member agrees to give PURE WELLNESS MEDICAL their complete and accurate insurance information for primary and secondary insurance benefits. Member understands that a failure to do so may result in a delay in payment or denial of claim. In the event of any balance after Insurance, the Member agrees to pay accordingly. The Member understands that the Insurance Carrier may not agree to the usual, customary or reasonable charges for my local area, understands they may not cover all services or might deny payment for services that have been approved of in advance, and agrees to pay the balance remaining on the Member account after insurance has been processed.</li>
								<li>The Member agrees that if the Insurance Carrier requires the Member to provide a referral and if the referral is not in place before the Members appointment, the Member will pay in advance an estimate of charges for my office visit or reschedule my appointment.</li>
								<li>In the event that some services are not covered, and are considered complimentary/alternative medicine, not covered by insurance, the Member will accept full financial responsibility and agree to pay the balance in full. Member is obligated to pay any balance remaining on the Member’s account for any reason upon receipt of a statement.</li>
								<li>The Member understands that there is a charge if there is a missed appointment, or cancelled appointment, without a 24-notice period.<b> If the Member fails to provide at least a full 24-prior notice of a missed appointment or cancellation, the Member will be charged $100 for the missed office visit.</b></li>
								<li>PURE WELLNESS MEDICAL does not have a contract with the Member’s Insurance Carrier and is generally considered “out of network.” For this reason, the Insurance Carrier either may not provide the Member with any available benefits, or if they do, the payment may not go to PURE WELLNESS MEDICAL, but rather, to the Member.</li>
								<li>If the Member receives the check from the Insurance Carrier, the Member is required to bring the check and all paperwork to the PURE WELLNESS MEDICAL office and endorse the check to PURE WELLNESS MEDICAL. In the event that PURE WELLNESS MEDICAL does not receive payment in a timely manner, PURE WELLNESS MEDICAL will be forced to bill the Member the full amount, and in some cases, refer the Member to collections, who will charge also for any applicable penalties such as debt collection and attorney’s fees.</li>
								<li>This also applies to any fees let unpaid by the guarantor on any account. Members are considered guarantors of the fees applied to their account and are responsible for this balance. Member is responsible for any and all deductibles and co-payments, and in the event the Member fails to pay such fees due at the time of service, the Member may be subject to a rescheduling of their appointment.</li>
								<li>Member understands and agrees that if the Member account becomes delinquent, the Account may be forwarded to an outside collection agency without notice. If this happens, the Member will be responsible for all costs of collection, including but not limited to interest, rebilling fees, court costs, attorney fees, and collection agency costs.</li>
								<li v-if="(this.contractdata && this.contractdata.plan_category == 'Weight Management')">If the reason for the Members appointment is related to a work injury or auto accident, the Member agrees to give PWM the case number or policy number, the workman’s compensation or insurance carrier’s name, address or other contact information at the time of the Members appointment so that PWM can bill workman’s compensation or the auto insurance carrier for my visit. If the Member fails to do so, they will agree to pay for all charges for the Member’s visit.</li>
							</ol>

							<h3 class="fw-600 text-center">FINANCIAL RESPONSIBILY & GUARANTEE STATEMENT</h3>

							<p class="text-justify">The Client / Member is also a “Guarantor” for all debts or charges associated with this Membership Agreement, or any Services provided by PURE WELLNESS MEDICAL or Dr. Suarez. In accordance with the prices as specified in the particular Membership Agreement, the Guarantor irrevocably and unconditionally guarantees, as a primary obligor and not merely as a surety, to PURE WELLNESS MEDICAL or Dr. Suarez, the punctual payment and performance when due, as it relates to any and all debts and costs, as of the time of execution of this Membership Agreement, or hereafter existing or arising under or relating to the Services provided or Membership Agreement, plus any and all expenses and costs (including reasonable attorney’s fees) relating to enforcement of PURE WELLNESS MEDICAL’s or Dr. Suarez’s rights (the “Guaranteed Obligations”). Guarantor further agrees that the Guaranteed Obligations are absolute, present and continuing and shall not be discharged or impaired for any reason.</p>

							<h3 class="fw-600 text-center">ACKNOWLEDGEMENT & AGREEMENT</h3>

							<p class="text-justify">I <b class="bg-lightest p-5 b-1 border-secondary rounded5 fs-18 d-inline-block">{{this.contractdata['patient_name']}}</b>, have read and understand the PURE WELLNESS MEDICAL Financial Responsibility & Guarantee Statement, and I accept responsibility for the payment of any fees associated with my care. I have read and understand all of the above, have had an opportunity to discuss this with my attorney, and I am agreeing to these statements on my own free will and volition.</p>

							<div class="row">
								<div class="col-12 col-md-6">
									<signature 
										title=""
										labelClass=""
										divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-5">
									</signature>
							</div>
							<div class="col-12 col-md-6">
								<p class="fw-600">Date: <span class="bg-lightest p-5 ms-1 b-1 border-secondary rounded5 d-inline-block">{{this.contractdata['contract_start']}}</span></p>
							</div>
						</div>
						<p>Member / Client / Guarantor Signature</p>

						<p><b>{{this.contractdata['patient_name']}}</b></p>

						<p>Member / Client / Guarantor Print</p>
						<button type="button" class="btn waves-effect waves-light btn btn-info mt-10" @click="handleContractSign">Submit</button>
					</div>
				</div>
			</section>

			<section v-if="this.is_pdf == true">
				<div class="box">
					<div class="box-body">
						<iframe :src="contractdata['contract_url']" style="width:100%;height: 100vh" ></iframe>
					</div>
				</div>
			</section>
		</div>
	</div>
	<div class="mt-0" v-if="this.server_error != ''">
		<section class="content">
			<div class="box text-center">
				<div class="box-body py-50">
					<i class="fa fa-frown fs-70 text-danger"></i>
					<div class="content-header">
						<h1 class="fw-300 fs-36">{{ this.server_error }}</h1>
					</div>
				</div>
			</div>
		</section>
	</div>
</div>

</template>

<script>
	import axios from "@/scripts/axios.js";
	import Signature from "@/components/base/Signature.vue";
	import submitMixins from "@/scripts/mixins/submitMixins.js";
	export default {
		mixins: [ submitMixins ],
		name: "ContractSign",
		components: {
			Signature,
		},
		data() {
			return {
				different_class:'',
				different:'',
				different_msg:'',
				total_amount:'',
				is_pdf :false,
				contractdata:[],
				final_amount_1_value : false,
				final_amount_6_value : false,
				final_amount_12_value : false,
				pay_authorization0:false,
				pay_authorization1:false,
				pay_authorization2:false,
				pay_authorization3:false,
				pay_authorization4:false,
				pay_authorization5:false,
				pay_authorization6:false,
				pay_authorization7:false,
				pay_authorization8:false,
				pay_authorization9:false,
				pay_authorization10:false,
				is_error : false,
				server_error:'',
				form : {
					patient_chosen_payment_no:'',
					consent_given:'',
					terms_and_cond_read : false,
					terms_and_cond_yes:false,
					terms_and_cond_no:false,
					consent_and_research_read:false,
					consent_and_research_yes:false,
					consent_and_research_no:false,
					arbitration_agree_read:false,
					arbitration_agree_yes:false,
					arbitration_agree_no:false,
					hippa_notice_privacy_read:false,
					hippa_notice_privacy_yes:false,
					hippa_notice_privacy_no:false,
					hormone_replace_therapy_read:false,
					hormone_replace_therapy_yes:false,
					hormone_replace_therapy_no:false,
					esign_disclosure_read:false,
					esign_disclosure_yes:false,
					esign_disclosure_no:false,
					is_guardian_yes : false ,
					is_guardian_no : false,
					patient_sign:'',
					sign_token : this.$route.params.token,
				},
				error:{
					patient_chosen_payment_no_error:'',
					terms_and_cond_read_error : '',
					terms_and_cond_error:'',
					consent_and_research_error:'',
					consent_and_research_read_error : '',
					arbitration_agree_read_error:'',
					arbitration_agree_error:'',
					hippa_notice_privacy_read_error:'',
					hippa_notice_privacy_error:'',
					hormone_replace_therapy_read_error:'',
					hormone_replace_therapy_error:'',
					esign_disclosure_read_error:'',
					esign_disclosure_error:'',
				},
				businessLogo: ''

			}
		},
		mounted() {
			this.fetchContractAggrement();
		},
		methods:{
			handleContractSign(){
				this.form.patient_sign = this.$store.state.signature;

				if(this.form.is_guardian_yes == false && this.form.is_guardian_no == false) {
					this.moshaToast('You have not chosen a member legal guardian.','danger');
					this.is_error = true;
					var myEl = document.getElementById("legalguardian");
					this.$smoothScroll({
						scrollTo: myEl,
					});
				}

				if (this.form.terms_and_cond_read == false) {
					this.error.terms_and_cond_read_error = true;
					this.is_error = true;
					var myEl = document.getElementById("relevant_policy");
					this.$smoothScroll({
						scrollTo: myEl,
					});
				}

				this.form.consent_given = true;
				this.is_error = false;

				if (((this.contractdata && this.contractdata.plan_category == 'HRT')) && (this.pay_authorization0 == false || this.pay_authorization1 == false || this.pay_authorization2 == false || this.pay_authorization3 == false || this.pay_authorization4 == false || this.pay_authorization5 == false || this.pay_authorization6 == false || this.pay_authorization7 == false || this.pay_authorization8 == false  || this.pay_authorization9 == false  || this.pay_authorization10 == false)) {

					this.moshaToast('You have not accepted the consent.','danger');
					this.is_error = true;
					var myEl = document.getElementById("authorization_read");
					this.$smoothScroll({
						scrollTo: myEl,
					});
				}

				if ((this.contractdata && this.contractdata.plan_category == 'Weight Management') && (this.pay_authorization0 == false || this.pay_authorization1 == false || this.pay_authorization2 == false)) {
					
					this.moshaToast('You have not accepted the consent.','danger');
					this.is_error = true;
					var myEl = document.getElementById("authorization_read");
					this.$smoothScroll({
						scrollTo: myEl,
					});
				}

				
				if(this.form.patient_sign == ''){
					this.moshaToast('You have not signed.','danger');
					this.is_error = true;
				}
				/*if (this.form.patient_chosen_payment_no == false) {
					this.error.patient_chosen_payment_no_error = true;
					this.is_error = true;
				}*/
				
				if (this.form.terms_and_cond_yes == false && this.form.terms_and_cond_no == false) {
					this.error.terms_and_cond_error = true;
					this.is_error = true;
				}
				if(this.form.consent_and_research_yes == false && this.form.consent_and_research_no == false) {
					this.error.consent_and_research_error = true;
					this.is_error = true;
				}
				if (this.form.arbitration_agree_read == false) {
					this.error.arbitration_agree_read_error = true;
					this.is_error = true;
				}
				if(this.form.arbitration_agree_yes == false && this.form.arbitration_agree_no == false) {
					this.error.arbitration_agree_error = true;
					this.is_error = true;
				}
				if(this.form.hippa_notice_privacy_yes == false && this.form.hippa_notice_privacy_no == false) {
					this.error.hippa_notice_privacy_error = true;
					this.is_error = true;
				}
				if (this.form.hippa_notice_privacy_read == false) {
					this.error.hippa_notice_privacy_read_error = true;
					this.is_error = true;
				}
				if(this.form.hormone_replace_therapy_yes == false && this.form.hormone_replace_therapy_no == false) {
					this.error.hormone_replace_therapy_error = true;
					this.is_error = true;
				} 
				if (this.form.hormone_replace_therapy_read == false) {
					this.error.hormone_replace_therapy_read_error = true;
					this.is_error = true;
				}
				if(this.form.esign_disclosure_yes == false && this.form.esign_disclosure_no == false) {
					this.error.esign_disclosure_error = true;
					this.is_error = true;
				}
				if (this.form.esign_disclosure_read == false) {
					this.error.esign_disclosure_read_error = true;
					this.is_error = true;
				}
				console.log(this.error);
				console.log(this.form);
				console.log(this.is_error);
				console.log("call inside");

				if(this.is_error == false){
					this.$store.state.loader = true;
					axios.post("/contract-billing/submitContractSign", this.form)
					.then((response) => {
						this.moshaToast(response.data.data,'success');
						axios
						.post("/contract-billing/getOpenContractPdf", { sign_token: this.$route.params.token })
						.then((response) => {
							this.contractdata = response.data.data;
							this.paymentCheck(response.data.data.number_of_payment);
							this.removeToken();
							this.$store.state.loader = false;
						})
						.catch((error) => {
							this.server_error = error.response.data.message;
							this.$store.state.loader = false;
						});
					})
					.catch((error) => {
						this.errorMessage = error.response.data.message;
						this.$store.state.loader = false;
					});
				}
			},
			fetchContractAggrement() {
				axios.post("/contract-billing/getOpenContractPdf", { sign_token: this.$route.params.token })
				.then((response) => {

					this.contractdata = response.data.data;
					console.log(this.contractdata);
					/*this.paymentCheck(response.data.data.number_of_payment);
					if(this.contractdata.number_of_payment == 1){
						this.total_amount = this.contractdata.final_amount_1.replace(",", "");
					}
					else if(this.contractdata.number_of_payment == 6) {
						this.total_amount = this.contractdata.final_amount_6.replace(",", "") * 6;	
						
					}
					else{
						this.total_amount = this.contractdata.final_amount_12.replace(",", "") * 12;	
					}*/
				}).catch((error) => {
					this.server_error = error.response.data.message;
					console.log(error.response.data);
				});
			},
			paymentCheck(no_of_payment) {
				if(no_of_payment != this.contractdata.number_of_payment){
					if(no_of_payment == 1){
						this.different = this.total_amount - this.contractdata.final_amount_1.replace(",", "");
						$("#different_msg1").removeClass('d-none');
						$("#different_msg6").addClass('d-none');
						$("#different_msg12").addClass('d-none');
					}
					else if(no_of_payment == 6){
						this.different = this.total_amount - this.contractdata.final_amount_6.replace(",", "") * 6;
						$("#different_msg1").addClass('d-none');
						$("#different_msg6").removeClass('d-none');
						$("#different_msg12").addClass('d-none');
					}
					else{
						this.different = this.total_amount - this.contractdata.final_amount_12.replace(",", "") * 12;	
						$("#different_msg1").addClass('d-none');
						$("#different_msg6").addClass('d-none');
						$("#different_msg12").removeClass('d-none');
					}

					if(this.contractdata.number_of_payment < no_of_payment){
						this.different_msg = "(Loss - $"+Math.abs(this.different)+")";
						this.different_class = false;
					}
					else{
						this.different_msg = "(Savings - $"+this.different+")";
						this.different_class = true;
					}
				}
				else{
					$("#different_msg1").addClass('d-none');
					$("#different_msg6").addClass('d-none');
					$("#different_msg12").addClass('d-none');
				}

				if (no_of_payment == 1) {
					this.final_amount_1_value = true;
					this.final_amount_6_value = false;
					this.final_amount_12_value = false;
				} else if(no_of_payment == 6) {
					this.final_amount_6_value = true;
					this.final_amount_1_value = false;
					this.final_amount_12_value = false;
				} else {
					this.final_amount_12_value = true;
					this.final_amount_1_value = false;
					this.final_amount_6_value = false;
				}
				this.form.patient_chosen_payment_no = no_of_payment;
				this.error.patient_chosen_payment_no_error = '';
			},
			termsAndCondCheck(event) {
				this.error.terms_and_cond_error = '';
				if (event == 'terms_and_cond_no') {
					this.form.terms_and_cond_yes = false;
					this.form.terms_and_cond_no = true;
				} else {
					this.form.terms_and_cond_yes = true;
					this.form.terms_and_cond_no = false;
				}
			},
			consentAndResearchCheck(event) {
				this.error.consent_and_research_error = '';
				if (event == 'consent_and_research_no') {
					this.form.consent_and_research_yes = false;
					this.form.consent_and_research_no = true;
				} else {
					this.form.consent_and_research_yes = true;
					this.form.consent_and_research_no = false;
				}
			},
			arbitrationAgreeCheck(event) {
				this.error.arbitration_agree_error = '';
				if (event == 'arbitration_agree_no') {
					this.form.arbitration_agree_yes = false;
					this.form.arbitration_agree_no = true;
				} else {
					this.form.arbitration_agree_yes = true;
					this.form.arbitration_agree_no = false;
				}
			},
			hippaNoticePrivacyCheck(event) {
				this.error.hippa_notice_privacy_error = '';
				if (event == 'hippa_notice_privacy_no') {
					this.form.hippa_notice_privacy_yes = false;
					this.form.hippa_notice_privacy_no = true;
				} else {
					this.form.hippa_notice_privacy_yes = true;
					this.form.hippa_notice_privacy_no = false;
				}
			},
			hormoneReplaceTherapyCheck(event) {
				this.error.hormone_replace_therapy_error = '';
				if (event == 'hormone_replace_therapy_no') {
					this.form.hormone_replace_therapy_yes = false;
					this.form.hormone_replace_therapy_no = true;
				} else {
					this.form.hormone_replace_therapy_yes = true;
					this.form.hormone_replace_therapy_no = false;
				}
			},
			esignDisclosureCheck(event) {
				this.error.esign_disclosure_error = '';
				if (event == 'esign_disclosure_no') {
					this.form.esign_disclosure_yes = false;
					this.form.esign_disclosure_no = true;
				} else {
					this.form.esign_disclosure_yes = true;
					this.form.esign_disclosure_no = false;
				}
			},
			guardianCheck(event) {
				if (event == 'is_guardian_no') {
					this.form.is_guardian_yes = false;
					this.form.is_guardian_no = true;
				} else {
					this.form.is_guardian_yes = true;
					this.form.is_guardian_no = false;
				}
			},
			removeToken() {
				axios.post("/contract-billing/removeSignToken", { sign_token: this.$route.params.token })
				.then((response) => {
					this.is_pdf = true;
				})
				.catch((error) => {
					this.server_error = 'This link has been expired.';
				});
			},
			getBusinessLogo() {
				axios.get('/get_clinic_logo?sign_token='+ this.$route.params.token)
				.then((res) => { 
					this.businessLogo = res.data.data;
				});
			}
		},
		created() {
			this.getBusinessLogo();
		}
	}
</script>