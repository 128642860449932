<template>
	<div class="content-wrapper">
		<div class="container-full">
			<section class="content">
				<div class="content-header">
					<h1 class="heading1 m-0">Lifestyle Appointments</h1>
				</div>
				<div class="box">
					<div class="box-body">
						<div class="row">
							<div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
								<div class="form-group mb-5">
									<label class="form-label">Appointment Date Range</label>
									<div Class="availdate">
										<ejs-daterangepicker 
										id="daterangepicker" 
										:value="date" 
										:placeholder="waterMarkText" 
										@change="getdate">
										</ejs-daterangepicker>
									</div>
								</div>
							</div>
							<div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
								<div class="form-group">
									<label class="form-label">Campaign Type</label>
									<select name="select"  class="form-select form-control" v-model="form.campaign_type">
										<option value="">Select Campaign Type</option>
										<option value="Corporate">Corporate</option>
										<option value="Individual">Individual</option>
									</select>
								</div>
							</div>
							<div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
								<div class="form-group">
									<label class="form-label">Search</label>
									<input type="text" class="form-control" v-model="form.patient_name" placeholder="Patient Name">
								</div>
							</div>
							<div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
								<div class="mt-sm-25">
									<a @click="getFilterReportData()" class="waves-effect waves-light btn btn-info me-1">Search</a>
									<a @click="clearFiter()" class="waves-effect waves-light btn btn-info-light">Clear Filter</a>
								</div>
							</div>
						</div>
					</div>
				<hr class="mt-0">
				<div class="box-body">
					<div class="table-responsive">
						<table id="apptStatusDash" class="table table-condensed table-hover" :key="index">
							<thead>
								<tr>
									<th>Campaign Type</th>
									<th>Patient</th>
									<th>Cell Phone</th>
									<th>Email</th>
									<th>Date of Services</th>
									<th>Time slot</th>
									<th>Payment Status</th>
									<th width="20%">Action</th>
								</tr>
							</thead>
							<tbody>
								<tr
								class="hover-primary"
								v-for="(item, index) in appointment_list"
								:key="index"
								>
								<td>
									{{ item.corporate_id == 0 ? 'Individual' : 'Corporate' }}
								</td>
								<td>
									<a v-bind:href="'#/patient-chart/'+item.unique_id" target="_blank">
										{{ item.patient_name }}
									</a>
									<br>
									<a v-bind:href="'#/patient-chart/'+item.unique_id+'/payment/package-list'" target="_blank" class="text-decoration-underline">
										<i class="fa fa-plus fs-12"></i> Manage Package
									</a>
								</td>
								<td>
									{{ this.formatUS_cell_number(item.cell_phone) }}
								</td>
								<td>
									{{ item.email }}
								</td>
								<td>
									<Popper arrow  hover=true class="helptips">
										<template #content>
											<div style="text-align:center;">place of service</div>
											<div>{{ item.pos_address1}},{{item.pos_address2}},{{item.pos_city}},{{item.pos_state}} {{item.pos_country}},{{item.pos_zipcode}}.</div>
										</template>
										{{ this.get_date(item.schedule_time) }}
									</Popper>
								</td>
								<td>{{ $filters.displayDateTimeLocal(item.schedule_time,'',false,'hh:mm A') }}</td>
								<td>{{ item.payment_status == 0 ? 'Pending' : 'Charged' }}</td>
								<td>                      
									<a href="javascript:void(0)" class="waves-effect waves-light btn btn-info-light btn-sm mb-1 me-5" @click="apptBillingModal(item)"><i class="fa fa-plus fs-12"></i> Billing</a>
									<a href="javascript:void(0)" class="waves-effect waves-light btn btn-info-light btn-sm mb-1 me-5" @click="editAppointment(item)"><i class="fa fa-calendar"></i> Edit Appt. </a>
									<Popper arrow content="Send Payment Authorize Link" hover=true class="helptips">
										<a href="javascript:void(0)" :id="item.id" class="waves-effect waves-circle btn btn-circle btn-xs" :class="item.is_payment_link_sent ? 'btn-primary' : 'btn-info' " @click="sendAuthorizePaymentLink(item.id, item.is_payment_link_sent)">
											<i class="fa fa-paper-plane"></i>
										</a>
									</Popper>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</section>
</div>
</div>

<div id="covidPDF" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-lg">
		<div class="modal-content">
			<div class="modal-header">
				<h3 class="modal-title fw-900 mt-0">Covid PDF</h3>
			</div>
			<div class="modal-body">

			</div>
			<div class="modal-footer">
				<button type="button" class="waves-effect waves-light btn btn-danger" data-bs-dismiss="modal" aria-label="Close">Close</button>
			</div>
		</div>
	</div>
</div>

<div id="insDocsUpload" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-xl">
		<div class="modal-content">
			<div class="modal-header">
				<h3 class="modal-title fw-900 mt-0">Insurance Document</h3>
			</div>
			<div class="modal-body">
				<patient-insurance-external-use :patient_id="current_patient_id" :patient_unique_id="current_patient_unique_id"  v-if="current_patient_id > 0" :key="current_patient_id"></patient-insurance-external-use>
			</div>
			<div class="modal-footer">
				<button type="button" class="waves-effect waves-light btn btn-danger" @click="insDocsClosed">Close</button>
			</div>
		</div>
	</div>
</div>

<!--show Add Billing start-->
<div class="modal fade" id="addbilling" tabindex="-1" role="dialog" aria-labelledby="addbilling" aria-hidden="true">
	<div class="modal-dialog modal-xl" style="max-width: 1140px;">
		<div class="modal-content">
			<div class="modal-header">
				<h3 class="modal-title fw-900 mt-0">Add Billing</h3>
			</div>
			<div class="modal-body" style="max-height: 720px;overflow: auto;">
				<AppointmentBilling v-if="appointment_number" :is_inline_nav="is_inline_nav" :AppointmentNumber="appointment_number"  :key="appointment_number" reqfrom="lifestyle" paymentProfile="Cash" disabled="true" />
			</div>
		</div>
	</div>
</div>
<!--show Add Billing end-->

</template>
<script>
	import axios from "@/scripts/axios.js";
	import submitMixins from "@/scripts/mixins/submitMixins.js";
	import moment from 'moment';
	import Popper from "vue3-popper";
	import AppointmentBilling from "@/views/Appointments/AppointmentBilling.vue";


	export default {
		mixins: [submitMixins],
		components: {
			Popper,
			AppointmentBilling
		},
		data() {
			return {
				office_id: [],
				current_patient_id: '',
				current_patient_unique_id: '',
				waterMarkText: "Select Date Range",
				date: new Date(),
				appointment_list: {},
				appointmentstatus: [],
				appointmentstatusFiltered: [],
				offices : [],
				visit_profiles : [],
				example: '',
				updated_appointment_status_id: '',
				appointment_id: '',
				appointment_number: '',
				apptDropdownList: '',
				is_inline_nav : true,
				form: {
					patient_name: "",
					date_range: "",
					office_id: "",
					visit_profile_id: "",
					campaign_type: "",
					start_date: "",
					end_date: "",
				},
			};
		},
		mounted() {
			let externalScript = document.createElement('script')
			externalScript.setAttribute('src', 'https://cdn.jsdelivr.net/npm/jquery-datatables-checkboxes@1.2.12/js/dataTables.checkboxes.min.js')
			document.head.appendChild(externalScript);
			this.date = moment().subtract(30, 'days').format("MM-DD-YYYY")+' - '+moment().add(30, 'days').format("MM-DD-YYYY");
	  //this.date = moment().format("MM-DD-YYYY")+' - '+moment().format("MM-DD-YYYY");
	  this.getFilterReportData();
	},
	async created() {
	   //this.get_fiter_dropdown_list();
	//  this.getApptDropdownList();
},
methods: {
	editAppointment (item) {
		this.$router.push(`/patient-chart/${item.unique_id}/edit-appointment/`+item.appointment_number);
	},
	getApptDropdownList() {
		axios
		.post('/json_dropdown/patient-appointments',{patient_id:this.$store.state.patientDetail.patient_id,'appt_payment_type': 'Cash'})
		.then((response) => {
			this.apptDropdownList = response.data.data;
			console.log(this.apptDropdownList);
		})
	},
	apptBillingModal(item) {
		console.log(item);
		this.appointment_id = item.appointment_id;
		this.appointment_number = item.appointment_number;
		$('#addbilling').modal('show');
	},
  // sendConsentForm(item) {
  //   this.$store.state.loader = true;
  //   axios.post("pure-service/send-consent-form-link",{ patient_id : item.patient_id, appointment_id : item.appointment_id, clinic_id: item.clinic_id })
  //       .then(response => {
  //         this.$store.state.loader = false;
  //         this.moshaToast('Email sent successfully.', "success");
  //         this.initializeTable();
  //      }).catch((error) => {
  //         this.$store.state.loader = false;
  //         this.moshaToast(error.response.data.message, "danger");
  //      });
  // },
  sendAuthorizePaymentLink(id) {
  	this.$store.state.loader = true;
  	axios.post("pure-service/send-lifestyle-payment-link",{ id : id })
  	.then(response => {
  		console.log(response.data.message);
  		if(document.getElementById(id)) {
  			if(document.getElementById(id).classList.contains('btn-info')) {
  				document.getElementById(id).classList.remove('btn-info');
  				document.getElementById(id).classList.add("btn-primary");
  			}
  		}
  		this.$store.state.loader = false;
  		this.moshaToast('Email sent successfully.', "success");
  		this.initializeTable();
  	}).catch((error) => {
  		this.$store.state.loader = false;
  		this.moshaToast(error.response.data.message, "danger");
  	});

  }, 
  insDocsClosed() {
  	$('#insDocsUpload').modal('hide');
  	this.getFilterReportData();
  },
  uploadInsDocument (item) {
  	this.current_patient_id = item.patient_id;
  	this.current_patient_unique_id = item.unique_id;
  	$('#insDocsUpload').modal("show");
  },
  async getCovidNote(id){
  	this.$store.state.loader = true;
  	$("#covidPDF").modal("show");
  	const res = await fetch(process.env.VUE_APP_API_BASE_URL+'/appointment/view-pdf', {
  		method: 'POST',
  		headers: {
  			'Authorization' : 'Bearer '+localStorage.getItem('authToken'),
  			'Content-Type' : 'application/json'
  		},
  		body: JSON.stringify({ appointment_id: id  }),
  	});
  	const blob = await res.blob();
  	const urlObject = URL.createObjectURL(blob);
  	$("#covidPDF .modal-body").html('<iframe src="'+urlObject+'" width="100%" height="500px" ></iframe>');
  	this.$store.state.loader = false;
  },

  clearFiter() {
  	this.form.campaign_type = '';
  	this.form.office_id = '';
  	this.form.visit_profile_id = '';
  	this.form.patient_name = '';
  	this.form.appointment_status_id = '';
  },
  getFilterReportData() {
  	if (typeof $ !== 'undefined') {
  		$("#apptStatusDash").DataTable().destroy();
  	}
  	this.$store.state.loader = true;
  	axios.post("pure-service/service-appt-list",this.form)
  	.then(response => {
  		this.appointment_list = response.data.data;
  		
  		this.$store.state.loader = false;
  		this.initializeTable();
  	});
  },
  initializeTable() {
  	setTimeout(() => {
  		this.example = $("#apptStatusDash").DataTable({
	  /*'columnDefs': [
		   {
			  'targets': 0,
			  'checkboxes': {
				 'selectRow': true
			  },
			  'render': function (data, type, full, meta) {
				  var custom_value ='';
				  if ($(data).attr("value") != undefined) {
					  custom_value = $(data).attr("value");
				  } else {
					  custom_value = data;
				  }
				  return '<input type="checkbox" class="dt-checkboxes" name="id[]" value="' + $('<div/>').text(custom_value).html() + '">';
			  }
		   }
		],
		'select': {
		   'style': 'multi',
		   'selector': 'td:first-child'
		},*/
		"aaSorting": [],
		destroy: true,
	});
  		$("#apptStatusDash").DataTable().rows('.selected').nodes().to$().removeClass('selected');
  		let elem = document.getElementsByName("apptStatusDash_length")[0];
  		elem.classList.add("form-select");
  		elem.classList.add("form-control-sm");
  		elem.style.cssText += `border-color: #86a4c3 !important`;
  		elem = document.getElementsByTagName("input")[1];
  		elem.style.cssText += `outline: none`;
  		$('#apptStatusDash_filter').remove();
  	}, 500);
  },
  get_fiter_dropdown_list() {
  	axios.get("/json_dropdown/get-user-office-list")
  	.then((response) => (
  		this.offices = response.data.data
  		));
  	axios.get("/json_dropdown/appointment-profile")
  	.then((response) => (
  		this.visit_profiles = response.data.data
  		));
  	axios.get("/json_dropdown/appointment-status").then((response) => {
  		this.appointmentstatus = response.data.data;
  		this.appointmentstatusFiltered = this.appointmentstatus;
  		this.changeUpdtApptStatus();
  	});
  },
  getdate() {
  	var daterange = document.getElementById("daterangepicker").value;
  	var date = daterange.split(' - ');
  	this.form.from_date  = date[0];
  	this.form.to_date  = date[1];
  },
  billingDialog(){
  	this.$store.state.PaymentGatewayMigration.hidemodal = false;
  	$("#addbilling").modal('hide');
  	this.appointment_number = '';
  	this.initializeTable();
  },
  formatUS_cell_number(cell_number) {
  	if(cell_number!="") {
  		var cleaned = ('' + cell_number). replace(/\D/g, '')
  		var match = cleaned. match(/^(\d{3})(\d{3})(\d{4})$/)
  		if (match) {
  			return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  		}
  		return cell_number;
  	}
  	return "";
  },
  get_date(date_time) {
  	return moment(String(date_time.split(" ")[0].trim())). format('MM-DD-YYYY');
  },
  get_time(date_time) {
  		let time24= date_time.split(" ")[1].trim();
	  	const [hours, minutes] = time24.split(':');

		// Create a new Date object with today's date and the 24-hour time
		const date = new Date();
		date.setHours(+hours);
		date.setMinutes(minutes);

		// Get the hours and minutes in 12-hour format
		const hours12 = date.getHours() % 12 || 12;
		
		let minutes12 = date.getMinutes();
		// Determine whether it's AM or PM
		const amOrPm = date.getHours() < 12 ? 'AM' : 'PM';

		// Return the time in 12-hour format as a string
		let time12= `${hours12}:${minutes12} ${amOrPm}`;
		return moment(time12, 'hh:mm A').format('hh:mm A');
	}
  
},
watch: {
	"$store.state.PaymentGatewayMigration.hidemodal": function() {
		if (this.$store.state.PaymentGatewayMigration.hidemodal == true) {
			this.billingDialog();
		}
	},
}
};
</script>
<style >
	.dt-checkboxes {
		position: relative !important;
		opacity: unset !important;
	}
	#apptStatusDash input {
		position: relative !important;
		opacity: unset !important;
	}
</style>

