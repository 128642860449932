import {
    helpers,
    required,
    email,
    numeric,
} from "@vuelidate/validators";

const Vitals = {
  namespaced: true,
  state: {
    editID: "",
    listUrl: "/vital/list",
    getEditUrl: "/IV_life_style/category/edit/:id",
    tableData: {
      header: [
        "Vital Name",
        "Type",
        "Vital Status",
        "Actions",
      ]
    },
    defaultFormData: {
        vital:'',
        unit_name:'',
        vital_code:'',
        provider_id:'',
    }, 
    form: {
     
    },
    vuelidateExternalResults: {
      form: {
        vital:'',
        unit_name:'', 
        provider_id:'', 
      }
    },
    validationRules: {
      form: {
				vital: {
				required: helpers.withMessage("Vital Name is required.", required),
				},
				unit_name: {
				required: helpers.withMessage("Please select unit.", required),
				},   
			},
    },
    list: [],
  },
  getters: {
    getTableHeaders: (state) => state.header,
    getTableContents: (state) => state.list,
    getListURL: (state) => state.listUrl,
  },
  mutations: {},
  actions: {},
};

export default Vitals;