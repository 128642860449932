<template>
	<div class="content-wrapper">
		<div class="container-full">
			<section class="content">
				<div class="row ">
					<div class="col-12 col-sm-12 col-md-12 col-lg-8">
						<div class="row form-group">
							<label class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-5 col-form-label mb-0 text-sm-end"> Config </label>
							<div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-7">
								<select class="form-select form-control" @change="setConfig($event)">
									<option value="">Select Config</option>
									<option v-for="(item,index) in templateList" :value="item.id" :data-val="JSON.stringify(item.value.data)">{{item.key}}</option>
								</select>
							</div>
						</div>
						<div class="row form-group" v-for="(input, index) in this.form.value">
							<label class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-5 col-form-label mb-0 text-sm-end">{{ input.key }} </label>
							<div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-7">
								<input type="text" class="form-control" v-model="input.value" />
							</div>
						</div>
						<div class="text-end">
							<form-btn title="Save" btnCls="waves-effect waves-light btn btn-info" @click="saveConfig" ></form-btn>
						</div>
					</div>
				</div>
			</section>
		</div>
	</div>
</template>
<script>
	import axios from "@/scripts/axios.js";
	import submitMixins from "@/scripts/mixins/submitMixins.js";
	import useVuelidate from "@vuelidate/core";
	import FormBtn from "@/components/base/formFields/formBtn.vue";
	import $ from 'jquery';
	
	export default {
		mixins: [submitMixins],
			setup: () => {
			return { v$: useVuelidate() };
		  },
		  data() {
			return {
				templateList : [],
				selectedConfigInfo : [],
				form : {
					key : '',
					template_id : '',
					value : []
				}
			}
		},
		components: {
			FormBtn
		},
		async created() {
				axios
				.post('/third-party-config/get-template-list')
				.then((response) => {
						this.templateList = response.data.data;
				})
		},
		computed: {
		},
		methods:{
			setConfig($event) {
				var template_id = $event.target.value;
				if (template_id) {
					axios
					.post(`third-party-config/edit/${template_id}`)
					.then((response) => {
							console.log(response.data.data);
							this.form = response.data.data;
					});
				} else {
					this.form.key = '';
					this.form.value = '';
					this.form.template_id = '';
				}
			},
			saveConfig() {
				axios
				.post('/third-party-config/update',this.form)
				.then((response) => {
					this.moshaToast(response.data.message, "success");
				}).catch((error) => {
				  this.moshaToast(error.response.data.message, "danger");
				});;
			},
		},
		mounted(){
		}
	}
</script>