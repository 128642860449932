<template>
    <div class="content-wrapper">
		<div class="container-full">
			<div class="content-header">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                        <h1 class="heading1 m-0 d-inline-block">ERA Upload </h1>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-6 text-sm-end">
                        <a href="#" class="waves-effect waves-light btn btn-info mt-20 mt-sm-0" title="Upload ERA" data-bs-toggle="modal" data-bs-target="#uploadERA"> Upload ERA</a>
                    </div>
                </div>
            </div>

			<section class="content">
				<div class="box">
                    <div class="box-body">
                        <div class="table-responsive">
                            <table class="table table-bordered fs-16">
                                <thead class="text-center">
                                    <tr>
                                        <th>Sr. #</th>
                                        <th>File Name</th>
                                        <th>Uploaded Date</th>
                                        <th>Uploaded By</th>
                                        <th>View</th>
                                    </tr>
                                </thead>
                                <slot v-if="list.length>0">
                                    <tbody class="text-center">
                                        <tr v-for="(item, index) in list" :key="index">
                                            <td>{{index+1}}</td>
                                            <td>{{item.file_name}}</td>
                                            <td>{{ $filters.displayDateTimeLocal(item.created_at,'',false,"MMM D, YYYY h:mm A") }}</td>
                                            <td>{{item.addedBy}}</td>
                                            <td> <a href="javascript:void(0);" @click="viewERA(item.id)"> <i class="fa fa-eye" aria-hidden="true"></i> </a> </td>
                                        </tr>
                                    </tbody>
                                </slot>
                                <slot v-else>
                                    <tbody>
                                        <tr>
                                            <td colspan="6" align="center">No Records</td>
                                        </tr>
                                    </tbody>
                                </slot>
                            </table>
                        </div>
                    </div>
                </div>
            </section>
            <div id="uploadERA" class="modal fade" data-backdrop="static" data-keyboard="false">
				<div class="modal-dialog modal-md">
					<div class="modal-content">
						<div class="modal-header">
							<h3 class="modal-title fw-900 mt-0">Upload ERA</h3>
						</div>
						<div class="modal-body">
                            <div class="form-group row">
								<label class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end">Upload ERA</label>
								<div class="col-12 col-sm-6 col-md-8 col-lg-8 col-xl-8">
									<input type="file" title="Upload ERA" id="file_name" ref="file" class="form-control" v-on:change="FileUpload($event)"/>
								</div>
                                <div class="error-msg" id="GroupFileError">
                                    <ul class="mb-0 p-0 fs-14"></ul>
                                </div>
							</div>
						</div>
						<div class="modal-footer">
							<button type="button" @click="closemodal" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
                            <button type="button" @click="UploadERA" class="waves-effect waves-light btn btn-info">Upload</button>
						</div>
					</div>
				</div>
			</div>
            <div class="modal" tabindex="-1" id="viewERAModal">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h3 class="modal-title font-bold m-0" id="claim-data-head">
                                View ERA
                            </h3>
                            <button type="button" class="btn-close btn-xs" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body" id="uploadEOBModalBody">
                            <div id="sourceofERA" class="bg-light p-10 b-1 rounded5" style="background:#f2f2f5">
                                <div v-for="item in getERAarray" :key="item.id">
                                    <p class="mb-1 fs-12 font-weight-bold" v-if="item!=''" style="font-size:15px">{{item}}~</p>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-info" data-bs-dismiss="modal" aria-label="Close">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import submitMixins from "@/scripts/mixins/submitMixins.js";
import axios from "@/scripts/axios.js";
import permissionMixins from "@/scripts/mixins/permission.js";
import moment from 'moment';
import Popper from "vue3-popper";
import Autocomplete from "vue3-autocomplete";
import { mapGetters } from "vuex";
export default {
    mixins: [submitMixins, permissionMixins],
    setup: () => {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            list: [],            
            formSubmit:{
                file_name: null,
            },
            getERAarray:[],
        };
    },
    components: { Popper, Autocomplete },
    methods: {
        getList(){
            this.$store.state.loader = true;
            axios.post("/ERAUpload/list")
            .then((response) => {
                this.list = response.data.data;
                this.$store.state.loader = false;
            });
        },
        FileUpload(event) {
            console.log(this.$refs.file.files[0].type);
            this.formSubmit.file_name = this.$refs.file.files[0];
            $("#GroupFileError").find("ul").html("");
            const size = (this.$refs.file.files[0].size / 1024 / 1024).toFixed(2);
            if (size > 50) {
                $("#GroupFileError").find("ul").append('<li class="d-block confirmerror"> File size should not exceed more than 50 MB.</li>');
                this.formSubmit.file_name = "";
                $("#file_name").val("");
            }
            const acceptedImageTypes = [
                "text/plain",		
            ];
            if (!acceptedImageTypes.includes(this.$refs.file.files[0].type)) {
                $("#GroupFileError").find("ul").append('<li class="d-block confirmerror"> Only txt formats are supported.</li>');
                this.formSubmit.file_name = "";
                $("#file_name").val("");
            }
        },
        UploadERA(){
            if($(".confirmerror").text() == ''){
                var bodyFormData = new FormData();
                for (const [key, value] of Object.entries(this.formSubmit)) {
                    if (Array.isArray(this.formSubmit[key])) {
                        this.formSubmit[key].forEach((item) => {
                            if(item == null || item == '' || item == 'null'){
                                item = "";
                            }
                            bodyFormData.append(`${key}[]`, JSON.stringify(item));
                        });
                    } else {
                        if(value == null || value == '' || value == 'null'){
                            value = "";
                        }
                        bodyFormData.append(key, value);
                    }
                }
                this.$store.state.loader = true;
                axios.post("/ERAUpload/store",bodyFormData)
                .then((response) => {
                    this.$store.state.loader = false;
                    $("#uploadERA").modal('hide');                    
                    this.formSubmit.file_name = null;
                    $('#file_name').val('')
                    this.getList();
                });
            }
        },
        viewERA(id){
            axios.get("/ERAUpload/getERA/"+id)
            .then((response) => {
                let getcontent=response.data;
                let arr=getcontent.split("~");
                let data=[];
                arr.forEach(element => {
                    data.push(element.replaceAll("|","*"));
                });
                    let ST_POS= data.findIndex(element => element.includes("ST"));
                    let SE_POS= data.findIndex(element => element.includes("SE*"));
                    let length=SE_POS-ST_POS;
                for (let index = ST_POS; index <=SE_POS; index++) {
                    this.getERAarray.push(data[index]);                    
                }
                $("#viewERAModal").modal("show");
            });
        },
    },
    mounted() {
        this.getList();
    },
    computed: {
		...mapGetters("auth", ["user"]),
  	},
    async beforeCreate() {},
    validations() {},

}
</script>

<style>

</style>