const appointmentbilling = {
    namespaced: true,
    state: {
        form: {             
        },
        defaultFormData: {
            insurance_info: {
              id: null,
              claim_type: '',
              resubmission_code: '',
              is_employment: 'No',
              is_autoaccident: 'No',
              is_other_accident: 'No',
              autoaccident_state_id: null,
              onset_date_type: null,
              onset_date: null,
              other_date_type: null,
              other_date: null,
              ambulance_service_code: null,
              prior_authorization_number: null,              
              is_emergency_service: 'No',
              outside_lab_entity_id:null,
              outside_lab_entity_type:null,
              outside_lab_charges:null,
            },            
            appointment_id: '',
            icd10_code_id: [],
            icd10_table_id: [],
            icd10_code: [],
            icd10_description: [],
            cpt_table_id: [],
            cpt_code: [],
            cpt_code_id: [],
            cpt_modifier_a: [],
            cpt_modifier_b: [],
            cpt_modifier_c: [],
            cpt_modifier_d: [],
            cpt_icd10_pointer_a: [],
            cpt_icd10_pointer_b: [],
            cpt_icd10_pointer_c: [],
            cpt_icd10_pointer_d: [],
            cpt_qty: [],
            cpt_price: [],
            cpt_ndc_table_id: [],
            cpt_ndc_code: [],
            cpt_ndc_qty: [],
            cpt_ndc_units: [],
            custom_procedure_table_id: [],
            custom_procedure_code_id: [],
            custom_procedure_code: [],
            custom_procedure_description: [],
            custom_procedure_qty: [],
            custom_procedure_price: [],
            icddelete: [], 
            icdcodedelete: [], 
            cptdelete: [], 
            cptcodedelete: [], 
            ndcdelete: [], 
            ndccodedelete: [], 
            customproceduredelete: [],
            customprocedurecodedelete: [],
            payment_profile: null,
            billing_status: null,
            pre_billing_status_label: null,
            billing_status_label: null,
            insurance_type: null,
            cps:[],
            cps_list:[],
        },
        vuelidateExternalResults: {
            form : {
              insurance_info: {
                resubmission_code : [],
              }
            }
        },
        
    },
    getters: {
        getTableHeaders: (state) => state.header,  
        getTableContents: (state) => state.list,
        getListURL: (state) => state.listUrl,
    },
    mutations: {},
    actions: {},
  };
  
  export default appointmentbilling;
  