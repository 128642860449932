import {
    helpers,
    required,    
  } from "@vuelidate/validators";
  const smokingstatus = {
    namespaced: true,  
    state: {
        listUrl: `patient/:patient_unique_id/smoking-status/list`,
        tableData: {
            header: [
                "Smoking Status",
                "Start Date",
                "End Date",
                "Recorded Date",
                "Actions",               
            ],
        },      
        form: { 
            id: null,
            patient_id: '',
            smoking_id: '',
            start_date: '',          
            end_date: '',          
        },
        validationRules: {
            form: {
                smoking_id: {
                    required: helpers.withMessage("Smoking Status is required", required),
                },  
                start_date: {
                    required: helpers.withMessage("Start Date is required", required),
                }
            },
        },
        vuelidateExternalResults: {
            form: {
                smoking_id:  [],          
            },
        },
        list: [],
    },
    getters: {
        getTableHeaders: (state) => state.header,  
        getTableContents: (state) => state.list,
        getListURL: (state) => state.listUrl,
    },
    mutations: {},
    actions: {},
  };
  
  export default smokingstatus;
  