import {
  helpers,
  required,
} from "@vuelidate/validators";
const permissionList = {
  namespaced: true,

  state: {
    tableData: {
      header: [
        "Category",
        "Name",
        "Description",
      ],
    },
    listUrl: "/permission",
    list: [],
  },
  getters: {
    getTableHeaders: (state) => state.header,

    getTableContents: (state) => state.list,
    getListURL: (state) => state.listUrl,
  },
  mutations: {},
  actions: {},
}

const RolesList = {
  namespaced: true,
  
  state: {
    listUrl: "/permission/role",
    getEditUrl: "/permission/role/edit/:id",
    tableData: {
      header: [
        "Name",
        "Owner",
        "Description",
        "Active/Deactive",
        "Actions",
      ],
    },
    validationRules: {
        form: {
            name: {
              required: helpers.withMessage('Name is required.', required), 
            },
            description : {
              required: helpers.withMessage('Description is required.', required),  
            }
       },
    },
    vuelidateExternalResults: {
        form : {
          name : '',
          description : '',
        }
    },
    form : {
      name : '',
      description : '',
      permission_id : '',
    },
    defaultFormData : {
      name : '',
      description : '',
      permission_id : '',
    },

    list: [],
  },
  getters: {
    getTableHeaders: (state) => state.header,

    getTableContents: (state) => state.list,
    getListURL: (state) => state.listUrl,
  },
  mutations: {},
  actions: {},
}

const ProvidersList = {
  namespaced: true,

  state: {
    tableData: {
      header: [
        "Provider",
        "Action",
      ],
    },
    listUrl: "/permission/provider",
    list: [],
  },
  getters: {
    getTableHeaders: (state) => state.header,

    getTableContents: (state) => state.list,
    getListURL: (state) => state.listUrl,
  },
  mutations: {},
  actions: {},
}

const StaffpermissionList = {
  namespaced: true,

  state: {
    tableData: {
      header: [
        "Staff",
        "Roles",
        "Action",
      ],
    },
    listUrl: "/permission/staff",
    list: [],
  },
  getters: {
    getTableHeaders: (state) => state.header,

    getTableContents: (state) => state.list,
    getListURL: (state) => state.listUrl,
  },
  mutations: {},
  actions: {},
};

export { permissionList, RolesList, ProvidersList, StaffpermissionList};
//export default permissionList;

