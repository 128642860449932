import {
  helpers,
  required,
  requiredIf,
  email,
  minLength,
  maxLength,
  sameAs,
  url,
} from "@vuelidate/validators";

var alphaValidation = /^[a-zA-Z]*$/;
var regexAlpha = new RegExp(alphaValidation);
const alpha = (value) => !helpers.req(value) || regexAlpha.test(value);

var website_validation = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
var regex = new RegExp(website_validation);
const website_url = (value) => !helpers.req(value) || regex.test(value);


const provider = {
  namespaced: true,

  state: {
    editID: "",
    listUrl: "/provider/list",
    getEditUrl: "/provider/:id/edit",
    tableData: {
      header: [
        "Username",
        "Name",
        "E-mail",
        "Cell Phone",
        "Office Phone",
        "Provider Type",
        "Emergency Access",
        "Actions",
      ],
    },
    defaultFormData: {
      salutation: "",
      suffix: "",
      first_name: "",
      last_name: "",
      middle_name: "",
      email: "",
      email_signature: "",
      wesite: "",
      date_of_birth: "",
      specialty_id: "",
      job_title_id: "",
      provider_npi: "",
      country_id: "231",
      state_id: "",
      city: "",
      dea_number: "",
      /*ssn_number: "",*/
      state_license_number: "",
      office_access: [],
      additional_provider_id: [],
      cell_phone: "",
      office_phone: "",
      fax_number: "",
      /*tax_number: "",*/
      username: "",
      password: "",
      password_confirmation: "",
      provider_type: "0",
      is_lifestyle_specific:0,
      supervisor_sign_needed: 0,
      is_appointment_overlapping: 0,
      profile_avatar: "",
      business_logo: "",
      taxonomy_number: "",
      digtal_signature: "",
      disable_claim_npi: 0,
    },
    form: {
    },
    vuelidateExternalResults: {
      form: {
        first_name: [],
        last_name: [],
        middle_name: [],
        email: [],
        date_of_birth: [],
        specialty_id: [],
        job_title_id: [],
        provider_npi: [],
        country_id: [],
        state_id: [],
        city: [],
        dea_number: [],
        /*ssn_number: [],*/
        state_license_number: [],
        office_access: [],
        cell_phone: [],
        office_phone: [],
        fax_number: [],
        /*tax_number: [],*/
        username: [],
        password: [],
        password_confirmation: [],
        profile_avatar: [],
        business_logo: [],
        staff_assign:[]
      },
    },
    validationRules: {
      form: {
        first_name: {
          required: helpers.withMessage("First Name is required", required),
          alpha: helpers.withMessage("Only Alphabets allow", alpha),
          maxLength: helpers.withMessage(() => "First name can be up to 15 characters only",maxLength(15)),
        },

        last_name: {
          required: helpers.withMessage("Last Name is required", required),
          alpha: helpers.withMessage("Only Alphabets allow", alpha),
          maxLength: helpers.withMessage(() => "Last name can be up to 25 characters only",maxLength(25)),
        },
        email: {
          required: helpers.withMessage("Email Address is required", required),
          email: helpers.withMessage("Email address is not valid", email),
        },
        date_of_birth: {
          required: helpers.withMessage("Date Of Birth is required", required),
        },
        job_title_id: {
          required: helpers.withMessage("Job Title is required", required),
        },
        provider_npi: {
          required: helpers.withMessage("Provider NPI is required", required),
          max: maxLength(10),
        },
        country_id: {
          required: helpers.withMessage("Country is required", required),
        },
        state_id: {
          required: helpers.withMessage("State is required", required),
        },
        dea_number: {
          required: helpers.withMessage("DEA number is required", required),
          max: maxLength(9),
        },
       /* ssn_number: {
          max: maxLength(9),
        },*/
        state_license_number: {
          required: helpers.withMessage(
            "License number is required",
            required
          ),
          max: maxLength(10),
        },
        office_access: {
          required: helpers.withMessage("Office Access is required", required),
        },
        cell_phone: {
          required: helpers.withMessage(
            "Cell Phone Number is required",
            required
          ),
          max: maxLength(15),
        },
        office_phone: {
        },
        fax_number: {
        },
        /*tax_number: {
          required: helpers.withMessage("TAX number is required", required),
          max: maxLength(10),
        },*/
        username: {
          required: helpers.withMessage("Username is required", required),
          minLength: helpers.withMessage(() => 'Username must be between 8 to 16 characters.',minLength(8),),
          maxLength: helpers.withMessage(() => 'Username must be between 8 to 16 characters.',maxLength(16),),
        },
        password: {
          required: helpers.withMessage("Password is required", required),
          minLength: helpers.withMessage(() => 'Password must be between 8 to 16 characters.',minLength(8),),
          maxLength: helpers.withMessage(() => 'Password must be between 8 to 16 characters.',maxLength(16),),
        },
        password_confirmation: {
          required: helpers.withMessage(
            "Confirm Password is required",
            required
          ),
          minLength: helpers.withMessage(() => 'Password confirmatioin must be between 8 to 16 characters.',minLength(8),),
          maxLength: helpers.withMessage(() => 'Password confirmatioin must be between 8 to 16 characters.',maxLength(16),),          
        },
        website: {
          website_url: helpers.withMessage(
            "Website url is not valid",
            website_url
          ),
        },
        profile_avatar: {
        },
        business_logo: {
        },
        additional_provider_id:{
          required: requiredIf(function() {
              return this.form.provider_type == 2 ? true : false;
          }),            
        },
        staff_assign: {
          
        },
      },
    },
    list: [],
  },
  getters: {
    getTableHeaders: (state) => state.header,

    getTableContents: (state) => state.list,
    getListURL: (state) => state.listUrl,
  },
  mutations: {},
  actions: {},
};

const ArchivedProvider = {
  namespaced: true,

  state: {
    editID: "",
    listUrl: "/provider/archivedList",
    tableData: {
      header: [
        "Username",
        "Name",
        "E-mail",
        "Cell Phone",
        "Office Phone",
        "Supervising / Rendering Provider",
        "Emergency Access",
        "Action",
      ],
    },
    
    form: {},
    list: [],
  },
  getters: {
    getTableHeaders: (state) => state.header,
    getTableContents: (state) => state.list,
    getListURL: (state) => state.listUrl,
  },
  mutations: {},
  actions: {},
};

const providerAccess = {
  namespaced: true,

  state: {
    editID: "",
    
    form: {},
    list: [],
    defaultFormData: {
      permissions: [],
    },
    form: {
      permissions: []
    },
    vuelidateExternalResults: {
      form: {
        permissions:[]
      },
    },
    validationRules: {
      form: {
        permissions: {
          required: helpers.withMessage("Please select permission", required),
        }
      }
    },
    list: [],
  },
  getters: {
    getTableHeaders: (state) => state.header,
    getTableContents: (state) => state.list,
    getListURL: (state) => state.listUrl,
  },
  mutations: {},
  actions: {},
};

export {provider,ArchivedProvider, providerAccess};
