<template>
  <div class="content-wrapper">
    <div class="container-full">
      <section class="content">
        <div class="row justify-content-between">
          <!-- sidebar -->
          <the-side-bar></the-side-bar>
          <!-- content -->
          <component :is="$store.state.PermissionsHomeComp"></component>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import TheSideBar from "@/components/Settings/Permissions/TheSidebar.vue";
import PermissionsProviders from "@/components/Settings/Permissions/Home.vue";
import Permissionsstaff from "@/components/Settings/Permissions/Staff.vue";
import Permissionsroles from "@/components/Settings/Permissions/Roles.vue";
import Permissions from "@/components/Settings/Permissions/Permissionslist.vue";
import Permissionsemergency from "@/components/Settings/Permissions/Permissionsemergency.vue";

export default {
  components: { TheSideBar,PermissionsProviders,Permissionsstaff,Permissionsroles,Permissions,Permissionsemergency},
  created(){      
      this.$store.state.PermissionsHomeComp = this.$route.name;      
  },
};
</script>
