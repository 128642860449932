import {
  helpers,
  required,
  email,
  minLength,
  maxLength,
  sameAs,
  numeric,
} from "@vuelidate/validators";

const CarePlan = {
  namespaced: true,

  state: {
    tableData: {
      header: [
        "Type",
        "Plan Name",
        "Instructions",
        "SNOMED Code",
        "Scheduled",
        "Actions",
      ],
    },
    edit:'0',
    form: {
      plan_type: 'Goal'
    },
    vuelidateExternalResults: {
      form: {
        instructions:[],
        plan_type:[],
        scheduled_date:[],
        snomed_code:[],
        title:[],
      },
    },
    validationRules: {
      form: {
        plan_type: {
          required: helpers.withMessage("Plan Type is required", required),
        },
        scheduled_date: {
          required: helpers.withMessage("Scheduled Date is required", required),
        },
        title: {
          required: helpers.withMessage("Title is required", required),
        },
        snomed_code: {
          required: helpers.withMessage("Snomed Code is required", required),
        },
      },
    },
    listUrl: "/patient/:patient_unique_id/care-plan/list",
    list: [],
  },
  getters: {
    getTableHeaders: (state) => state.header,

    getTableContents: (state) => state.list,
    getListURL: (state) => state.listUrl,
  },
  mutations: {},
  actions: {},
}

export default CarePlan;
