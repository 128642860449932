<template>
  <div class="content-wrapper">
    <div class="content-header">
      <div class="row justify-content-between">
        <div class="col-12 col-sm-8 col-md-6 col-lg-8">
          <h1 class="heading1 mt-0">Pre Appointments</h1>
        </div>
        <!-- <div class="col-12 col-sm-8 col-md-6 col-lg-4 text-sm-end">
          <a href="#/staff/archived" class="waves-effect waves-light btn btn-info-light mb-1 me-1"> Show Archived </a>
          <router-link to="/staff/add" class="waves-effect waves-light btn btn-info mb-1" ><i class="fa fa-plus fs-14"></i> Staff Member</router-link>
        </div> -->
      </div>
    </div>
    <section class="content">
      <div class="box mb-10">
        <div class="box-body">
          <div class="row justify-content-between">
            <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-6">
              <div class="input-group float-start w-md-p60 mb-5 me-1">
                <input
                  type="text"
                  data-provide="media-search"
                  v-model="filter.search_text"
                  class="form-control"
                  placeholder="Search (e.g. Staff, Patient, Email)"
                />
                <button
                  type="button"
                  class="btn btn-info-light btn-md check_box px-2"
                  data-bs-toggle="dropdown"
                >
                  More Filters <i class="fa fa-caret-down"></i>
                </button>
              </div>
              <button
                type="button"
                @click="applyFilter"
                class="waves-effect waves-light btn btn-info me-1" style="height: 37px;"
              >
                Search
              </button>
              <button
                type="button"
                @click="resetFilter"
                class="waves-effect waves-light btn btn-info" style="height: 37px;"
              >
                Reset
              </button>
              <fieldset class="table_filters mt-5 w-p100">
            <div class="form-group mb-0">
              <input
                type="radio"
                v-model="filter.duplicate"
                id="converted_appt"
                name="filter_list"
                value="true"
                @change="filterchange('converted_appt')"
                class="filled-in chk-col-info"
              />
              <label for="converted_appt"
                >Only converted into appointment</label
              >
            </div>
            <div class="form-group mb-0">
              <input
                type="radio"
                v-model="filter.pre_with_converted_appt"
                id="patientsdates"
                name="filter_list"
                value="true"
                @change="filterchange('pre_with_converted_appt')"
                class="filled-in chk-col-info"
              />
              <label for="patientsdates"
                >Pre appointment with converted appointment.</label
              >
            </div>
            <div class="form-group mb-0">
              <input
                type="radio"
                v-model="filter.deleted_appt"
                id="patientsinclude"
                name="filter_list"
                value="true"
                @change="filterchange('deleted_appt')"
                class="filled-in chk-col-info"
              />
              <label for="patientsinclude">Deleted pre appointment</label>
            </div>
            
          </fieldset>
            </div>
          </div>
        </div>
      </div>
      <div class="box">
        <div class="box-body">
          <data-table-server
            dataType="preAppointment"
            dataMethod="post"
            :key="$store.state.preAppointment.key"
          >
            <template v-slot="slotProps">
              <!-- slot for table data -->
              <td>{{ slotProps.item.office_name }}</td>
              <td>{{ slotProps.item.provider_name }}</td>
              <td>{{ slotProps.item.coordinator_staff_name }}</td>
              <td v-if="!slotProps.item.patient_obj">{{ slotProps.item.first_name+' '+slotProps.item.last_name }}</td>
              <td v-if="slotProps.item.patient_obj">
                <router-link
                  :to="{
                    name: 'PatientDetails',
                    params: { id: slotProps.item.patient_obj.unique_id },
                  }"
                  :title="
                    'View Chart of ' +
                      slotProps.item.patient_obj.first_name +
                      '(' +
                      slotProps.item.patient_obj.chart_id +
                      ')'
                  " class="fw-500">{{ slotProps.item.patient_obj.first_name+' '+slotProps.item.patient_obj.last_name }}</router-link>
              </td>
              <td>{{ slotProps.item.cell_phone }}</td>
              <td>{{ slotProps.item.email}}</td>
              <td v-if="slotProps.item.appointment_id" >
                <a href="#" @click="editAppointment(slotProps.item)">{{ $filters.displayDateTimeLocal(slotProps.item.appt_schedule_date+" "+slotProps.item.appt_schedule_time) }}</a>
              </td>
              
              <td v-if="!slotProps.item.appointment_id">
                  {{ $filters.displayDateTimeLocal(slotProps.item.schedule_date+" "+slotProps.item.schedule_time) }}
              </td>
              <td>{{ slotProps.item.duration}} Minutes</td>
              <td>
                <div class="d-flex">
                 <Popper arrow content="Delete Record" :hover="true" class="helptips cursor-pointer" v-if="!slotProps.item.appointment_id">
                    <a href="#" @click="remove(slotProps.item.id)"
                      class="waves-effect waves-circle btn btn-circle btn-danger btn-xs me-1"
                      ><i class="fa fa-trash"></i></a>
                  </Popper>
                  <Popper arrow content="Create Appointment" :hover="true" class="helptips cursor-pointer" v-if="!slotProps.item.appointment_id">
                     <a href="#" @click="createAppointment(slotProps.item.id)"
                       class="waves-effect waves-circle btn btn-circle btn-info btn-xs me-1"
                       ><i class="fa fa-calendar"></i></a>
                   </Popper>
                </div>
              </td>
            </template>
          </data-table-server>
        </div>
      </div>
    </section>
    </div>
    
    <!--duplicate model start-->
    <div id="duplicate" class="duplicate modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header">
            <h3 class="modal-title fw-900 mt-0">Potential Duplicate Patient Found</h3>
          </div>
          <div class="modal-body">
          <p class="fs-14 text-start l-h-n">Please check and choose patient for this appointment.</p>
            <table class="table">
              <thead>
                <tr>
                  <th>Patient Name</th>
                  <th>Patient Chart Id</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in this.duplidate_patient">
                  <td>{{item.first_name+" "+item.last_name }}</td>
                  <td>{{item.chart_id}}</td>
                  <td><button type="button" class="waves-effect waves-light btn btn-info" @click="chooseFromDuplicate(item)">Choose</button></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="modal-footer">
            <button type="button" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
          </div>
        </div>
      </div>
    </div>
    <!--duplicate model end-->
</template>

<script>
import axios from "@/scripts/axios.js";
import moment from "moment";
import DataTableServer from "@/components/base/DataTableServer.vue";
import permissionMixins from "@/scripts/mixins/permission.js";
import submitMixins from "@/scripts/mixins/submitMixins.js";
import Popper from "vue3-popper";

export default {
  mixins: [permissionMixins,submitMixins],
  name: "Home",
  components: { DataTableServer, Popper },
  data() {
    return {
      key: 0,
      pre_appt_id: '',
      duplidate_patient: [],
      refresh_table: '',
    };
  },
 
  methods: {
    resetFilter() {
      this.filter.search_text = "";
      this.filter.converted_appt = false;
      this.filter.pre_with_converted_appt = false; 
      this.filter.deleted_appt = false;
      this.applyFilter();
    },

    editAppointment(item) {
        this.$router.push(`/patient-chart/${item.patient_obj.unique_id}/edit-appointment/`+item.appointment_number);
    },
    applyFilter() {
      this.$store.state.preAppointment.key++;
    },
    filterchange(field) {
      this.filter.converted_appt = false;
      this.filter.pre_with_converted_appt = false; 
      this.filter.deleted_appt = false;
      this.filter[field] = true;
    },
    chooseFromDuplicate(patientObj) {
      var appt_url = window.location.origin+"/#/patient-chart/"+patientObj.unique_id+"/schedule-appointment?pre_appt_id="+this.pre_appt_id;
      window.open(appt_url,'popup','width=700,height=600');
      $('#duplicate').modal('hide');
      this.pre_appt_id = '';
    },
    createAppointment(id) {
        this.pre_appt_id = id;
        axios.post("pre-appointment/checkAndConvertPotentialPatient",{"id" : id })
        .then((response) => {
            if (response.status == 206) {
                this.duplidate_patient = response.data.data.duplidate_patient;
                $('#duplicate').modal('show');
            } else {
                this.chooseFromDuplicate(response.data.data);
            }
        });
    },
    remove(id) {
      this.$swal
        .fire({
          title: '<h2 class="mt-0">Are you sure?</h2>',
          text:
            "You want to delete these records? This process cannot be undone.",
          icon: "warning",
          dangerMode: true,
          showCancelButton: true,
          confirmButtonColor: "#ee3158",
          cancelButtonColor: "#bdbdbd",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed == true) {
            axios.post("/pre-appointment/delete", { id: id }).then((response) => {
              this.$swal
                .fire(
                  "Deleted!",
                  "Record has been deleted.",
                  "success"
                )
                .then((result) => {
                  if (result.isConfirmed == true) {
                    location.reload();
                  }
                });
            });
          }
        });
    },
    onStorageUpdate(event) {
      if (event.key === "refresh_table") {
        this.moshaToast('Appointment created successfully.','success');
        setTimeout(function(){ location.reload(); }, 3000);
      }
    },
  },
  async beforeCreate() {
  },
  mounted() {
    if (localStorage.refresh_table) {
      this.refresh_table = localStorage.name;
    }
    window.addEventListener("storage", this.onStorageUpdate);
    if (window.jQuery) {
        $(".table_filters").hide();
        $(".check_box").click(function() {
          if ($(".table_filters").hasClass("d-block")) {
            $(".table_filters").removeClass("d-block");
          } else {
            $(".table_filters").addClass("d-block");
          }
        });
    }
    window.onload = function() {
      if (window.jQuery) {
          $(".table_filters").hide();
          $(".check_box").click(function() {
            if ($(".table_filters").hasClass("d-block")) {
              $(".table_filters").removeClass("d-block");
            } else {
              $(".table_filters").addClass("d-block");
            }
          });
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener("storage", this.onStorageUpdate);
  },
  computed: {
    filter() {
      return this.$store.state.preAppointment.filter;
    },
  },
};
</script>
