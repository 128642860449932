<template>
    <div class="panel pagecollap">
        <div class="panel-heading" id="condition-diagnosis" role="tab">
            <a class="panel-title collapsed font-bold" aria-controls="answer-3" aria-expanded="false" data-bs-toggle="collapse" href="#answer-3" data-parent="#accordion2">Problems</a>
        </div>
        <div class="panel-collapse collapse" id="answer-3" aria-labelledby="condition-diagnosis" role="tabpanel" data-bs-parent="#category-1" style="">
            <div class="panel-body">
                <div class="d-inline-block w-p100">
                    <p class="d-inline-block mb-2">(Generated from problem list)</p>
                </div>
                <div class="table-responsive">
                    <table class="table table-hover mb-0">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>ICD-10</th>
                                <th>Snomed Code</th>
                                <th>Diagnosed</th>
                                <th>Onset</th>
                                <th>Changed</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <slot v-if="problemList.length>0">
                                <tr v-for="(item, index) in problemList" :key="index">
                                    <td>{{item.problem}}</td>
                                    <td>{{item.ICD_10}}</td>
                                    <td>{{item.SNOMED_CT}}</td>
                                    <td>{{ $filters.displayDateTimeLocal(item.datetime_diagnosis) }}</td>
                                    <td>{{ $filters.displayDateTimeLocal(item.datetime_onset) }}</td>
                                    <td>{{ $filters.displayDateTimeLocal(item.datetime_changed) }}</td>
                                    <td>{{ item.status }}</td>
                                </tr>
                            </slot>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "@/scripts/axios.js";
import submitMixins from "@/scripts/mixins/submitMixins.js";
import permissionMixins from "@/scripts/mixins/permission.js";
import useVuelidate from '@vuelidate/core'
import TableBase from "@/components/base/Table.vue";
export default {
    mixins: [submitMixins,permissionMixins],
    components : {
        TableBase
    },
    setup () {
        return { v$: useVuelidate() }
    },
    name: "cqm",
    data() {
        return {
            patient_id: this.$store.state.patientDetail.patient_id,
            patient_unique_id: this.$store.state.patientDetail.patient_unique_id,
            problemList : [],
        };
    },
    computed: { 
       
    },
    mounted() {
    },
    beforeCreate() {
    },
    created() {
         axios.get(`/patient/${this.$route.params.id}/problem/cqm_list`, {
             params: {'patient_id': this.patient_id}
         }).then(response => {
            this.problemList = response.data.data;
        });
    },
    methods: {
    }
  }
</script>