<template>
   <div class="tab-pane" id="lockedclinical" role="tabpanel">
      <div class="box">
         <div class="box-body">
            <div class="pageheading">
               <h2 class="heading2">Lab Results</h2>
            </div>
            <div class="table-responsive">
                  <table-base :dataTypes="{dataType: 'LabResults', patient_id: patient_id,patient_unique_id:patient_unique_id,status:'Results Received'}">
                     <template v-slot="slotProps">
                        <td>{{ (slotProps.item.loinc_description != null) ? slotProps.item.loinc_description+" "+slotProps.item.loinc_code : "N/A"}}</td>
                        <td>{{ (slotProps.item.date_of_testing != null) ? $filters.displayDateTimeLocal(slotProps.item.date_of_testing,'',false,"ddd, MM-DD-YYYY",true) : "N/A"}}</td>
                        <td>{{ slotProps.item.provider_name }}</td>
                        <td>{{ (slotProps.item.abnormal_flag != null) ? slotProps.item.abnormal_flag : "N/A"}}</td>
                        <td>{{ (slotProps.item.result_value != null) ? slotProps.item.result_value : "N/A"}}</td>
                        <td>{{ (slotProps.item.lab_result_description != null) ? slotProps.item.lab_result_description : "N/A"}}</td>
                     </template>
                  </table-base>
            </div>
         </div>
      </div>
   </div>
</template>
<script>
import TableBase from "@/components/base/Table.vue";
import moment from "moment";

export default {
   name: "Home",
   components: { TableBase },
   data() 
   {
      return {
         patient_id : this.$store.state.patientDetail.patient_id,
         patient_unique_id:this.$store.state.patientDetail.patient_unique_id,
      };
   },
   methods: {
      formatDate(date, format) {
         return moment(date).format(format);
      },
      utcformat(date, format) {
         return moment(date).utcOffset(0).format(format);
      },
   },
};
</script>