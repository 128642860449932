<template>    
	<div class="position-relative d-inline-block" style="max-width: 100%;">
		<label @click="displayOption" class="badge badge-light fs-16 selectCheckBox m-1" style="max-width: 100%; overflow: hidden;">{{labelName}}
			<span v-if="all_selected" class="active mx-2 text-info">All</span> 
			<slot v-else> 
				<slot :set="count=1"> 
					<slot v-for="(item,index) in dataSource" :key="index"> 
						<slot v-if="count <= 3 && item.checked==true">
							<span class="text-info mx-1" :set="count++">{{item.name}}, <slot v-if="count==4"> ... </slot></span>
						</slot>
					</slot> 
				</slot>  
			</slot>
			<i class="fa fa-caret-down"></i>
		</label> 
		<div v-show="display_div" class="select-check-list selectCheckBox">
			<div class="select-check-option">
				<input type="checkbox" class="filled-in chk-col-info" :id="labelName.replaceAll(' ', '_')+'selectall'" value="selectall" v-model="all_selected" @click="selectall">
				<label :for="labelName.replaceAll(' ', '_')+'selectall'">Select All</label>
			</div>
			<slot v-for="(item,index) in dataSource" :key="index">
				<div class="select-check-option">
					<input type="checkbox" class="filled-in chk-col-info" name="selected_values" @change="selectitem" v-model="item.checked" :id="item.name.replace(/\s+/g, '')+index" :value="item.id">
					<label :for="item.name.replace(/\s+/g, '')+index">{{item.name}}</label>
				</div>
			</slot>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		dataSource:Object,
		labelName:String,
		displayDiv:Boolean,
	},
	emits: ["clicked"],
	data() {
		return {
			selected_list:null,
			display_div:this.displayDiv,
			all_selected:true,
		}
	},    
	methods:{
		selectall(){
			if(this.all_selected){
				this.selected_list = this.dataSource.map(obj=> ({ ...obj, checked: false }));
				this.$emit('clicked', this.selected_list)
			}else{
				this.selected_list = this.dataSource.map(obj=> ({ ...obj, checked: true }));
				this.$emit('clicked', this.selected_list)
			}
		},
		selectitem(){
			this.selected_list = this.dataSource;
			this.$emit('clicked', this.selected_list);
			var selected_count = this.selected_list.map((item) => {
					return (item.checked == true) ? item.id : null;
				}).filter(n => n).length;
			var all_count = this.dataSource.length;
			if(selected_count == all_count){
				this.all_selected=true
			}else{
				this.all_selected=false
			}
		},
		displayOption(){
			if(this.display_div){
				this.display_div=false;
			}else{
				this.display_div=true;
			}
		},
	},
	mounted(){
		this.selected_list = this.dataSource;
		var selected_count = this.selected_list.map((item) => {
				return (item.checked == true) ? item.id : null;
			}).filter(n => n).length;
		var all_count = this.dataSource.length;
		if(selected_count == all_count){
			this.all_selected=true
		}else{
			this.all_selected=false
		}
	},
}
</script>

<style>

</style>