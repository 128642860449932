<template>
      <iframe id="CCDAPreview" width="100%" height="1000px"> </iframe>
</template>

<script>
import axios from "@/scripts/axios.js";
export default {
mounted(){
    axios.post(`patient/${this.$route.params.id}/getCCDA/${this.$route.params.id2}`, {
        html: true,
    }).then((res) => {  
        var iframe = document.getElementById('CCDAPreview');
        var iframedoc = iframe.contentDocument || iframe.contentWindow.document;
        iframedoc.body.innerHTML = res.data;
    });
}
}
</script>