<template>
    <div class="box">
        <div class="box-body">
            <div class="pageheading">
                <div class="row justify-content-between align-items-center">
                  <div class="col-12 col-sm-7 col-md-7 col-lg-6 col-xs-6">
                    <h3 class="font-bold">Active Medication Orders
                        <!-- <i class="fa fa-refresh" style="cursor:pointer;"  @click="downloadActiveMedsAndAllergyByPatient" aria-hidden="true"></i> -->
                    </h3>
                  </div>
                  <div class="col-12 col-sm-5 col-md-5 col-lg-6 col-xs-4 text-sm-end">
                      <div class="bg-danger-light py-5 rounded5 fs-14 mb-10 w-p75 text-center" role="alert"
                        v-if="errorMessage">
                        {{ errorMessage }}
                      </div>
                    <a href="javascript:void(0)" class="waves-effect waves-light btn btn-info mb-10" @click="launchRxnt"><i class="fa fa-plus fs-14"></i> Medication</a>
                  </div>
                </div>
                <form style="display:none" name="rcopiaForm" method="post" action="https://web.staging.drfirst.com/sso/portalServices">
                    <div>
                        <!-- SOME COMMENTS: config value -->
                        <input type="text" runat="server" id="rcopia_portal_system_name" name="rcopia_portal_system_name" value="avendor9367" size="30" />
                        <input type="text" runat="server" id="rcopia_practice_user_name" name="rcopia_practice_user_name" value="pu4800" size="30" />
                        <input type="text" runat="server" id="rcopia_user_id" name="rcopia_user_id" value="" size="30" />
                        <input type="text" runat="server" id="rcopia_user_external_id" name="rcopia_user_external_id" value="provider1_pu4800" size="100" />
                        <!-- config value -->

                        <!-- SOME COMMENTS: patient Rcopiaid to open in patient context  -->
                        <input type="text" runat="server" id="rcopia_patient_id" name="rcopia_patient_id" value="26149471827" size="15" />
                        <!-- from EHR  -->
                        
                        <input type="text" runat="server" id="rcopia_patient_system_name" name="rcopia_patient_system_name" value="" size="30" />            
                        <input type="text" runat="server" id="rcopia_patient_external_id" name="rcopia_patient_external_id" value="" size="40" />

                        <!-- SOME COMMENTS: can be hardcoded for now -->
                        <input type="text" runat="server" id="service" name="service" value="rcopia" size="30" />
                        <input type="text" runat="server" id="startup_screen" name="startup_screen" value="patient" size="30" />
                        <input type="text" runat="server" id="close_window" name="close_window" value="n" />
                        <input type="text" runat="server" id="allow_popup_screens" name="allow_popup_screens" value="y" />
                        <!-- can be hardcoded for now -->

                        <!-- SOME COMMENTS: parent page (not this one). so that when you quit from Rcopia, it will take you to the screen from where you started -->
                        <input type="text" runat="server" id="logout_url" name="logout_url" :value="return_url" size="100" />
                        <!-- parent page (not this one). so that when you quit from Rcopia, it will take you to the screen from where you started -->

                        <input type="text" runat="server" id="limp_mode" name="limp_mode" value="y" size="100" />

                        <!--<input type="text" runat="server" id="time" name="time" value="120921115501"/>-->
                        <!--<input type="text" runat="server" id="skip_auth" name="skip_auth" value="n" />-->

                    </div>
                </form>
            </div>
            
            <div class="table-responsive">
                <table class="table table-hover">
                    <thead>
                      <tr>
                        <th>Drug Name</th>
                        <th>Start Date</th>
                        <th>Added By</th>
                        <th>Notes</th>
                      </tr>
                    </thead>
                    <tbody>
                        <tr v-if="medication.length>0" v-for="item in medication">
                            <td>{{item.DrugName}}</td>
                            <td>{{ $filters.displayDateTimeLocal(item.DateAdded,'',false,"ddd, MM-DD-YYYY",true) }}</td>
                            <td>{{item.DoctorFirstName}} {{item.DoctorLastName}}</td>
                            <td>{{item.Comments}}</td>
                        </tr>
                        <tr v-else>
                            <td colspan="4" class="text-center">No data available</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
    import axios from "@/scripts/axios.js";
    export default {
      components: {},
      data() {
        return {
            medication:[],
            sso_token : '',
            return_url : window.location.href,
        };
      },
      watch: {},
      created() {
        this.fetchLocalActiveMed();
      },
      mounted() {
      },
      methods: {
        launchRxnt : function() {
            var elem = document.createElement("input");
            elem.setAttribute("type", "text");
            elem.setAttribute("name", "action");
            elem.setAttribute("id", "action");
            elem.setAttribute("value", "login");
            document.rcopiaForm.appendChild(elem);

            var timenow = this.gmtTime();
            var elem = document.createElement("input");
            elem.setAttribute("type", "text");
            elem.setAttribute("name", "time");
            elem.setAttribute("id", "time");
            elem.setAttribute("value", timenow);
            document.rcopiaForm.appendChild(elem);

            var elem = document.createElement("input");
            elem.setAttribute("type", "text");
            elem.setAttribute("name", "skip_auth");
            elem.setAttribute("id", "skip_auth");
            elem.setAttribute("value", "n");
            document.rcopiaForm.appendChild(elem);

            var strMAC = this.GenerateStringForMAC(timenow);
            console.log(strMAC);
            //debugger;
            var md5Hash = CryptoJS.MD5(strMAC).toString(); //echo md5($str)
            md5Hash = md5Hash.toUpperCase();

            elem = document.createElement("input");
            elem.setAttribute("type", "text");
            elem.setAttribute("name", "MAC");
            elem.setAttribute("value", md5Hash);
            document.rcopiaForm.appendChild(elem);

            console.log(document.rcopiaForm);

            //document.rcopiaForm.submit();
            function submitToPopup(f) {
                var w = window.open('', 'form_open', 'height=600,width=1200');
                f.target = 'form_open';
                f.submit();
            }
            submitToPopup(document.rcopiaForm);
            //this.$store.state.loader = true;
            //this.errorMessage = '';
            /*axios
            .post("/createSSOString", { patient_id : this.$store.state.patientDetail.patient_id })
            .then((response) => {
                 this.sso_token = response.data.data.sso_token;
                 if (this.sso_token) {
                     var form = document.createElement("form");
                     form.setAttribute("method", "post");
                     form.setAttribute("action", "https://app.rxnt.com/ehrv8/SingleSignOnProcessor.ashx?OTO=1");

                     var hiddenInput = document.createElement('input');
                     hiddenInput.setAttribute('type', 'hidden');
                     hiddenInput.setAttribute('name', 'data');
                     hiddenInput.setAttribute('value', this.sso_token);
                     hiddenInput.setAttribute('value', 'Ay+CWnWhzbdBHvJzYRsXH5MGTcrQaOKqG0Qq2PoTdpMHHqbt1wyMzkregWZzRYZgIZwMOvWnKYw3K0NRQeXY1eepe9FnbBxz2BlHj98rBzK7z/qV3UIwjiDdSIwk3lBXMqwFfVPs3/YGKvoj2p7oY6U7JYgsLG0/12iBk8gst6BbSzNlc+gKlrCOGBMUtWrbQVudyctJhXT3bek4ovchhfchbzEsQAeWzPGdUsdUC/C8oGcvOrYg2cXKmF77sPoes4R95SVBpid4OX4siNb7UOJitT/Xq82P2TDOLPG+nGULO0F0yOl+WRB4l4bnoW802VpHXCvIpdWoZDvuQidAYQiOrHPsOsk3dZmaO+yosZaBmJwzRm/APVDrZXrl0kiIQ8N7PreAKcavclPZkSvO4ZP6gwQ27db91cbg43EuzNR3woIpg1Mc1qQmF5ofuKOw/xM27ocB7SDSidAT2RblJhGawrvNbbADZJ7V8GGrModAygVixC3NB9DBrSQ5rJgJofTwBckEIZGwFbpvWcP2JOKiu5/7Mopr4+WPuVtNtKK1QNAxVxz90p7jqTIJaL9wwflR4/U0fbBIKeI3UwOYRecyUm5f0SvYqa6ffCGlD/EwaWovu6Fv5BIMBu9/EuVUlS8h7W0PVjZhzQoj7DKsesycsx/64xLE6W3ZVK13OwyCMZaVyHkE6iWjoiPfV6NPcHwe10Mhi95/+2NbCbFXj+mVYTlgifhC7yXZmuQeWw34CbzEooOzxBrcLKU1sMhHg1zxw8JwvVoSXTLdcU9W2l1NReKPFWbKumqsAYV5Nvi4AckiQQQWOUHxSAX4XFbRsCr6MTaUprlP+tTlc+N5LFLAP42XCw4csCJA5BQ7X28=');
                     form.appendChild(hiddenInput);

                     var hiddenInput2 = document.createElement('input');
                     hiddenInput2.setAttribute('type', 'hidden');
                     hiddenInput2.setAttribute('name', 'returnURL');
                     hiddenInput2.setAttribute('value', this.return_url);
                     form.appendChild(hiddenInput2);

                     var hiddenInput3 = document.createElement('input');
                     hiddenInput3.setAttribute('type', 'hidden');
                     hiddenInput3.setAttribute('name', 'externalorderid');
                     hiddenInput3.setAttribute('value', 'MQ==');
                     form.appendChild(hiddenInput3);

                     document.body.appendChild(form);
                     function submitToPopup(f) {
                         var w = window.open('', 'form_open', 'height=600,width=1200');
                         f.target = 'form_open';
                         f.submit();
                     };
                     submitToPopup(form);
                    console.log(this.sso_token);
                 } else {
                    this.errorMessage = 'Could not fetch SSO token';
                 }
                this.$store.state.loader = false;
            })
            .catch((error) => {
                this.errorMessage = error.response.data.message;
            });*/
        },
        GenerateStringForMAC : function(timenowtemp) {
            var str = "rcopia_portal_system_name=" + document.getElementById("rcopia_portal_system_name").value +
                "&rcopia_practice_user_name=" + document.getElementById("rcopia_practice_user_name").value +
                "&rcopia_user_id=" + document.getElementById("rcopia_user_id").value +
                "&rcopia_user_external_id=" + document.getElementById("rcopia_user_external_id").value +
                "&rcopia_patient_id=" + document.getElementById("rcopia_patient_id").value +
                "&rcopia_patient_system_name=" + document.getElementById("rcopia_patient_system_name").value +
                "&rcopia_patient_external_id=" + document.getElementById("rcopia_patient_external_id").value +
                "&service=" + document.getElementById("service").value +
                "&startup_screen=" + document.getElementById("startup_screen").value +
                "&close_window=" + document.getElementById("close_window").value +
                "&allow_popup_screens=" + document.getElementById("allow_popup_screens").value +
                "&logout_url=" + document.getElementById("logout_url").value +
                "&limp_mode=" + document.getElementById("limp_mode").value +
                "&action=login" + 
                "&time=" + timenowtemp +
                "&skip_auth=" + document.getElementById("skip_auth").value +
                //"&posturl=" + posturl.Value +
                "crfys1g4";
            return str;
            //alert(str)
        },
        gmtTime : function () {
            var GMTTime = new Date();
            var month = GMTTime.getUTCMonth() + 1;
            var day = GMTTime.getUTCDate();
            var year = GMTTime.getUTCFullYear() - 2000;
            var hour = GMTTime.getUTCHours();
            var min = GMTTime.getUTCMinutes();
            var sec = GMTTime.getUTCSeconds();

            if (month < 10) { month = "0" + month; }
            if (day < 10) { day = "0" + day; }
            if (hour < 10) { hour = "0" + hour; }
            if (hour < 1) { hour = "00"; }
            if (min < 10) { min = "0" + min; }
            if (min < 1) { min = "00"; }
            if (sec < 10) { sec = "0" + sec; }
            if (sec < 1) { sec = "00"; }

            return  month.toString() + day.toString() + year.toString() + hour.toString() + min.toString() + sec.toString();
        },
        downloadActiveMedsAndAllergyByPatient : function() {
             this.$store.state.loader = true;
            axios
              .post("/downloadActiveMedsAndAllergyByPatient", { patient_id : this.$store.state.patientDetail.patient_id })
              .then((response) => {
                    this.$store.state.loader = false;
                    this.fetchLocalActiveMed();
              })
              .catch((error) => {
                this.errorMessage = error.response.data.message;
              });
        },
        fetchLocalActiveMed : function() {
            axios
              .post("/rxntpatientmedication", { patient_id : this.$store.state.patientDetail.patient_id })
              .then((response) => {
                    this.medication = response.data.data;
              })
              .catch((error) => {
                this.errorMessage = error.response.data.message;
              });
        }

      },
    };
</script>
<style type="text/css" scoped>
</style>