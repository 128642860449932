const AdvanceReport = {
    namespaced: true,  
    state: {
        listUrl: `reports/advance-report-list`,  
        header: [
            {"chart_id" : "Chart ID"},
            {"provider_name" : "Provider"},
            {"first_name" : "First Name"},
            {"last_name" : "Last Name"},
            {"date_of_birth" : "DOB"},
            {"home_phone" : "Home Phone"},
            {"cell_phone" : "Cell Phone"},
            {"email" : "Email"},
            {"last_appointment_date" : "Last Appt"},
        ],
        header1: [
            {"schedule_at" : "Date of Service"},
            {"patient_name" : "Patient"},
            {"provider_name" : "Provider"},
            {"office_name" : "Office"},
            {"exam_room" : "Exam Room"},
            {"status_label" : "Appt. Status"},
            {"billing_status" : "Billing Status"},
            {"claim_status" : "Insurance Status"},
            {"note_status" : "Lock Status"}
        ],
        pagination: {
            to: "",
            from: "",
            total: "",
            last_page: "",
            current_page: "",
            pageNavArr: [],
        },
        filter:{
            primary_provider: null,
            first_name: null,
            middle_name: null,
            last_name: null,
            patient_status: null,
            address: null,
            zipcode: null,
            city: null,
            state: null,
            home_phone: null,
            cell_phone: null,
            office_phone: null,
            email: null,
            dob: null,
            gender: null,
            ethnicity: null,
            race: null,
            visit_profile: null,
            doctor: null,
            office: null,
            exam_room: null,
            comma_separated_codes: null,
            result_for: null,
            appointment_status: null,
            billing_status: null,
            last_id: 0,
            first_id: null,
        },
        list: [],
        patientIds: [],
    },
    getters: {},
    mutations: {},
    actions: {},
  };
  
  export default AdvanceReport;
