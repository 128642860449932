<template>
  <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-2">
    <div class="sticky-scroll-box">
      <div class="accorsidebar">
        <div class="accordion-item">
          <p class="accordion-header display-vertical" id="addpatienttab">
              <span class="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapsedemogr" aria-expanded="false" aria-controls="collapsedemogr">Provider</span>
          </p>
          <div
            id="collapsedemogr"
            class="accordion-collapse collapse"
            aria-labelledby="addpatienttab"
            data-bs-parent="#accordionExample"
          >
           <div class="side-navigation">
            <ul class="side-bar-menu side-bar-tree patientTabs" data-widget="tree">
              <li>
                <a
                  ref="AddProviderHomeLink"
                  @click.prevent="changeComp('provider')"
                  class="active"
                  >Provider</a
                >
              </li>
              <li :class="newProvider">
                <a
                  ref="AddProviderBilling"
                  @click.prevent="changeComp('billing')"
                  >Provider Billing</a
                >
              </li>
              <li :class="newProvider">
                <a
                  ref="AddProviderScheduling"
                  @click.prevent="changeComp('scheduling')"
                  >Provider Scheduling</a
                >
              </li>
              <li :class="newProvider" v-if="permissioncheck(this.$store.state.permission.Settings)">
                <a
                  ref="AddAccessPermission"
                  @click.prevent="changeComp('accessPermission')"
                  >Provider's Access Permission</a
                >
              </li>

            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>
<script>
import permissionMixins from "@/scripts/mixins/permission.js";
export default {
  mixins: [permissionMixins],
  data() {
    return {};
  },
  computed: {
    newProvider() {
      return this.$route.name === "ProviderAdd" ? "linkDisabled" : "";
    },
  },

  watch: {
    "$route.name": function(newValue) {
      this.linkActive();
    },
  },

  mounted() {
    this.linkActive();
  },

  methods: {
    changeComp(name) {
      if (name === "scheduling" && this.$route.params.id) {
        this.$router.push(
          `/provider/edit/scheduling/${this.$route.params.id}`
        );
      } 
      else if (name === "billing" && this.$route.params.id) {
        this.$router.push(
          `/provider/edit/billing/${this.$route.params.id}`
        );
      }
      else if (name === "accessPermission" && this.$route.params.id) {
        this.$router.push(
          `/provider/edit/permission/${this.$route.params.id}`
        );
      }
      else if (name === "provider" && this.$route.params.id) {
        this.$router.push(
          `/provider/edit/${this.$route.params.id}`
        );
      }
    },
    linkActive() {
      if (
        this.$route.name === "ProviderAdd" ||
        this.$route.name === "ProviderEdit"
      ) {
        this.$refs.AddProviderHomeLink.classList.add("active");
        this.$refs.AddProviderScheduling.classList.remove("active");
        this.$refs.AddProviderBilling.classList.remove("active");
        this.$refs.AddAccessPermission && this.$refs.AddAccessPermission.classList.remove("active");
      }
      else if (this.$route.name === "ProviderBilling") {
        this.$refs.AddProviderBilling.classList.add("active");
        this.$refs.AddProviderHomeLink.classList.remove("active");
        this.$refs.AddProviderScheduling.classList.remove("active");
        this.$refs.AddAccessPermission && this.$refs.AddAccessPermission.classList.remove("active");
      }
      else if (this.$route.name === "ProviderEditScheduling") {
        this.$refs.AddProviderScheduling.classList.add("active");
        this.$refs.AddProviderHomeLink.classList.remove("active");
        this.$refs.AddProviderBilling.classList.remove("active");
        this.$refs.AddAccessPermission && this.$refs.AddAccessPermission.classList.remove("active");
      }
      else if (this.$route.name === "ProviderAccessPermission") {
        this.$refs.AddAccessPermission && this.$refs.AddAccessPermission.classList.add("active");
        this.$refs.AddProviderScheduling.classList.remove("active");
        this.$refs.AddProviderHomeLink.classList.remove("active");
        this.$refs.AddProviderBilling.classList.remove("active");
      }
    },
  },
};
</script>