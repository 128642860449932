<template>
    <div class="box p-15">
        <div class="mdc-tab">
            <TabbarComp routefrom="PackageList"></TabbarComp>
            <div class="tab-content">
                <div class="tab-pane active" id="packagelist" role="tabpanel">
                    <div class="my-10">
                        <div class="row justify-content-between">
                            <div class="col-12 col-sm-3 col-md-3 col-lg-4 col-xl-3">
                                <h2 class="heading2 my-5">Packages</h2>
                            </div>
                            <div class="col-12 col-sm-9 col-md-9 col-lg-8 col-xl-9 text-sm-end">
                                <a v-if="is_hippasign==1" href="javascript:void(0)" class="waves-effect waves-light btn btn-info" data-bs-toggle="modal" data-bs-target="#createPackageModal"><i class="fa fa-plus fs-14"></i> Create Package</a>
                                
                                <a v-if="is_hippasign==0" href="javascript:void(0)" class="waves-effect waves-light btn btn-info" @click="sendHippaInvitation()">
                                    Send Hippa Invitation
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>Package code</th>
                                    <th>Package Name</th>
                                    <th>Treatments</th>
                                    <th>Total paid amount</th>
                                    <th>Ramaining amount</th>
                                    <th>Status</th>
                                    <th style="min-width: 90px;">Action</th>
                                </tr>
                            </thead>
                            <tbody v-if="packages.length>0">
                                <tr v-for="(item, index) in packages" :key="index">
                                    <td>{{ index+1 }}</td>
                                    <td>{{ item.package_code }}</td>
                                    <td>{{ item.package_name }}</td>
                                    <td><b>{{ item.no_of_treatments-item.completed_no_of_treatments }}</b> left out of <b>{{ item.no_of_treatments }}</b></td>
                                    <td>${{ item.package_price }}</td>
                                    <td>${{ getRemainingAmount(item.price_per_treatment, item.completed_no_of_treatments, item.package_price) }}</td>
                                    <td>{{ item.status }}</td>
                                    <td style="min-width: 90px;">
                                        <a href="javascript:void(0)" class="waves-effect waves-circle btn btn-circle btn-info btn-xs me-1"  data-bs-toggle="modal" data-bs-target="#viewPackageModal" @click="getPackageDetail(item.id)">
                                            <i class="fa fa-eye" data-v-48ac0994-s=""></i>
                                        </a>
                                        <a href="javascript:void(0)" class="waves-effect waves-light btn btn-info btn-sm" data-bs-toggle="modal" data-bs-target="#payNowModal" @click="setPayNowValues(item.id, item.package_price)" v-if="item.status_id==4">Pay</a>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <td colspan="8" class="text-center">No Record Found.</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal for Create Package -->
    <div class="modal fade" id="createPackageModal" tabindex="-1" role="dialog" aria-labelledby="addbilling" aria-hidden="true">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <h3 class="modal-title fw-900 mt-0">Create New Package</h3>
                </div>
                <div class="modal-body" style="max-height: 720px;overflow: auto;">
                    <!-- <div class="form-group row">
                        <div class="col-12 col-sm-3 col-md-4 col-lg-3 col-xs-3 text-sm-end">
                            <label class="col-form-label">
                                Package code <small class="text-danger">*</small>
                            </label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-5 col-xl-5">
                            <input type="text" min="2" max="20" class="form-control d-inline-block ps-5 pe-5 me-1" v-model="form.package_code" />
                            <div class="error-msg fs-14" v-if="error.package_code!=''">{{ error.package_code }}</div>
                        </div>
                    </div> -->
                    <!-- <div class="form-group row">
                        <div class="col-12 col-sm-3 col-md-4 col-lg-3 col-xs-3 text-sm-end">
                            <label class="col-form-label">
                                Package name <small class="text-danger">*</small>
                            </label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-5 col-xl-5">
                            <input type="text" min="2" max="50" class="form-control d-inline-block ps-5 pe-5 me-1" v-model="form.package_name" />
                            <div class="error-msg fs-14" v-if="error.package_name!=''">{{ error.package_name }}</div>
                        </div>
                    </div> -->
                    <div class="form-group row">
                        <div class="col-12 col-sm-3 col-md-4 col-lg-3 col-xs-3 text-sm-end">
                            <label class="col-form-label">
                                Custom Procedures <small class="text-danger">*</small>
                            </label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-5 col-xl-5">
                            <div class="selectcontrols">
                                <Custom-procedure-code
                                    @clicked="customproceduresearchresult"
                                    :isSelected="false"
                                    :appointment_profile_id="22"
                                    >
                                </Custom-procedure-code>
                                <div class="error-msg fs-14" v-if="error.service_cpt!=''">{{ error.service_cpt }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="pb-20" v-if="customproceduretable.length > 0">
                        <div class="table-responsive">
                            <table class="table table-hover table-bordered mb-0">
                                <thead>
                                    <tr>
                                        <td>No</td>
                                        <td>Code</td>
                                        <td>Description</td>
                                        <td style="min-width: 70px">Quantity</td>
                                        <td style="min-width: 120px">Price</td>
                                        <td>Action</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in customproceduretable" :key="index">
                                        <td>{{ index + 1 }}</td>
                                        <td>
                                            {{ item.code }}
                                            <div v-if="cps_data">
                                                <ul v-if="cps_data.length>0 && cps_data[item.id]" class="ul-checklist-h">
                                                    <li v-for="(i, idx) in cps_data[item.id]" :key="i.service_id+idx">
                                                        <input type='checkbox' :checked="i.is_selected" @change="updateservices(idx, item.id)">  {{ i.product_name }}
                                                    </li>
                                                </ul>
                                            </div>
                                        </td>
                                        <td>{{ item.description }}</td>
                                        <td style="min-width: 70px">
                                            <input
                                            type="number"
                                            class="form-control mx-0"
                                            v-model="item.custom_procedure_qty"
                                            placeholder="Quantity"
                                            @change="update_qty_price(index)" 
                                            @keyup="update_qty_price(index)"
                                            readonly = "readonly"
                                            />
                                        </td>
                                        <td style="min-width: 120px">
                                            <div class="input-group">
                                                <span class="input-group-text"
                                                ><i class="fa fa-dollar"></i
                                                ></span>
                                                <input
                                                type="number"
                                                class="form-control mx-0"
                                                v-model="item.custom_procedure_price"
                                                placeholder="Price"
                                                @keyup="update_price(item.id, item.custom_procedure_price)"
                                                @change="update_price(item.id, item.custom_procedure_price)"
                                                />
                                            </div>
                                        </td>      
                                        <td>
                                            <Popper arrow content="Delete" :hover="true" class="helptips cursor-pointer">
                                                <a
                                                href="#"
                                                class="waves-effect waves-circle btn btn-circle btn-danger btn-xs"
                                                @click="customprocedureremove(item.id,item.id,item.code,index)"
                                                ><i class="fa fa-trash"></i></a>
                                            </Popper>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="form-group row align-items-center">
                        <div class="col-12 col-sm-3 col-md-4 col-lg-3 col-xs-3 text-sm-end">
                            <label class="col-form-label">Total price per treatment</label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-5 col-xl-5">
                            <input type="number" readonly="readonly" class="form-control d-inline-block ps-5 pe-5 me-1" v-model="form.price_per_treatment"/>
                        </div>
                    </div>
                    <div class="form-group row align-items-center">
                        <div class="col-12 col-sm-3 col-md-4 col-lg-3 col-xs-3 text-sm-end">
                            <label class="col-form-label">Enter number of treatments</label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-5 col-xl-5">
                            <input type="number" min="1" max="50" class="form-control d-inline-block ps-5 pe-5 me-1" v-model="form.no_of_treatments" @keyup="update_package_price()" @change="update_package_price()" />
                        </div>
                    </div> 
                    <div class="form-group row align-items-center">
                        <div class="col-12 col-sm-3 col-md-4 col-lg-3 col-xs-3 text-sm-end">
                            <label class="col-form-label">Total payable amount</label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-5 col-xl-5">
                            <input type="number" readonly="readonly" class="form-control d-inline-block ps-5 pe-5 me-1" v-model="form.package_price"/>
                        </div>
                    </div>
                    <div class="form-group row align-items-center">
                        <div class="col-12 col-sm-3 col-md-4 col-lg-3 col-xs-3 text-sm-end">
                            <label class="col-form-label">
                                Select Payment method <small class="text-danger">*</small>
                            </label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-5 col-xl-5">
                            <select class="form-select form-control" v-model="form.payment_method" id="payment_method">
                                <option label="Cash" value="cash" >Cash</option>
                                <option label="Credit Card" value="cc">Credit Card</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group row" v-if="form.payment_method=='cc'">
                        <div class="col-12 col-sm-3 col-md-4 col-lg-3 col-xs-3 text-sm-end">
                            <label class="col-form-label">
                                Select credit card <small class="text-danger">*</small>
                            </label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-5 col-xl-5">
                            <select class="form-select form-control" v-model="form.cctoken_id">
                              <option value="">- Select Credit-Card -</option>
                              <option value="addNew">Add New Card</option>
                              <option v-for="(item,index) in ccTokenData" :value="item.id" :data-val="item.id" :key="index">{{item.card_label}}</option>
                           </select>
                           <div class="error-msg fs-14" v-if="error.cctoken_id!=''">{{ error.cctoken_id }}</div>
                        </div>
                    </div>
                    <div class="form-group row" v-show="form.payment_method == 'cc' && (form.cctoken_id == 'addNew')">
                        <div style="display: none" id="customStyles">
                            body{margin:0;}
                            iframe{height: 100px;}
                            #payment-form {border: 1px solid rgb(212, 221, 230);max-height: 130px;width: 100%;padding: 20px 10px;border-radius: 4px;margin: 0px;background: rgb(245, 248, 250);}
                            #payment-form {color: #2d3c58;}
                            #payment-form .input-label {color: #2d3c58;border-top: 1px solid #d4dde6;font-size:13px;text-align: center !important;}
                        </div>
                        <div id="card1" style="height: 130px;"></div>
                        <div id="errorMessage1" style="margin-bottom: 8px; color: #c0392b;"></div>
                        <input
                        type="checkbox"
                        id="use_credidcard_for_future_checkbox1"
                        class="filled-in chk-col-info"
                        v-model="form.use_credidcard_for_future"
                        />
                        <label for="use_credidcard_for_future_checkbox1" class="m-0 text-start">Save for future use?</label>
                    </div>
                    <div class="form-group row">
                        <div class="col-12 col-sm-3 col-md-4 col-lg-3 col-xs-3 text-sm-end">
                            <label class="col-form-label">Select frequency</label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-5 col-xl-5">
                            <div class="d-flex align-items-center">
                                <select class="form-control" v-model="form.frequency_time">
                                    <option v-for="i in 10" :key="i" :value="i">{{i}}</option>
                                </select>
                                <lable class="px-10">Per</lable>
                                <select class="form-control" v-model="form.frquency_repetation">
                                    <option value="0">Week</option>
                                    <option value="1">Month</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer justify-content-center">
                    <button type="button" class="waves-effect waves-light btn me-1 waves-effect waves-light btn btn-info me-1" @click="createPackageToken()">Create</button>
                    <button type="button" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal for Pay -->
    <div class="modal fade" id="payNowModal" tabindex="-1" role="dialog" aria-labelledby="addbilling" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h3 class="modal-title fw-900 mt-0">Pay Now</h3>
                </div>
                <div class="modal-body" style="max-height: 720px;overflow: auto;">
                    <div class="form-group row">
                        <div class="col-12 col-sm-3 col-md-4 col-lg-3 col-xs-3 text-sm-end">
                            <label class="col-form-label">
                                Select Payment method <small class="text-danger">*</small>
                            </label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-5 col-xl-5">
                            <select class="form-select form-control" v-model="form.payment_method" id="payment_method">
                                <option label="Cash" value="cash" >Cash</option>
                                <option label="Credit Card" value="cc">Credit Card</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group row" v-if="form.payment_method=='cc'">
                        <div class="col-12 col-sm-5 col-md-5 col-lg-3 col-xs-3 text-sm-end">
                            <label class="col-form-label">
                                Select credit card <small class="text-danger">*</small>
                            </label>
                        </div>
                        <div class="col-12 col-sm-7 col-md-7 col-lg-6 col-xl-6">
                            <select class="form-select form-control" v-model="form.cctoken_id">
                              <option value="">- Select Credit-Card -</option>
                              <option value="addNew">Add New Card</option>
                              <option v-for="(item,index) in ccTokenData" :value="item.id" :data-val="item.id" :key="index">{{item.card_label}}</option>
                           </select>
                           <div class="error-msg fs-14" v-if="error.cctoken_id!=''">{{ error.cctoken_id }}</div>
                        </div>
                    </div>
                    <div class="form-group row" v-show="form.payment_method == 'cc' && (form.cctoken_id == 'addNew')">
                        <div style="display: none" id="customStyles">
                            body{margin:0;}
                            iframe{height: 100px;}
                            #payment-form {border: 1px solid rgb(212, 221, 230);max-height: 130px;width: 100%;padding: 20px 10px;border-radius: 4px;margin: 0px;background: rgb(245, 248, 250);}
                            #payment-form {color: #2d3c58;}
                            #payment-form .input-label {color: #2d3c58;border-top: 1px solid #d4dde6;font-size:13px;text-align: center !important;}
                        </div>
                        <div id="card" style="height: 130px;"></div>
                        <div id="errorMessage" style="margin-bottom: 8px; color: #c0392b;"></div>
                        <input
                        type="checkbox"
                        id="use_credidcard_for_future_checkbox"
                        class="filled-in chk-col-info"
                        v-model="form.use_credidcard_for_future"
                        />
                        <label for="use_credidcard_for_future_checkbox" class="m-0 text-start">Save for future use?</label>
                    </div>
                </div>
                <div class="modal-footer justify-content-center">
                    <button type="button" class="waves-effect waves-light btn me-1 waves-effect waves-light btn btn-info me-1" @click="payNow()">Pay</button>
                    <button type="button" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal for view package -->
    <div class="modal fade" id="viewPackageModal" tabindex="-1" role="dialog" aria-labelledby="addbilling" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h3 class="modal-title fw-900 mt-0">View package</h3>
                </div>
                <div class="modal-body" style="max-height: 720px;overflow: auto;">
                    <div class="form-group row align-items-center">
                        <div class="col-12 col-sm-3 col-md-4 col-lg-3 col-xs-3 text-sm-end">
                            <label class="col-form-label">
                                Package code
                            </label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-5 col-xl-5">
                            {{ package_detail.code }}
                        </div>
                    </div>
                    <div class="form-group row align-items-center">
                        <div class="col-12 col-sm-3 col-md-4 col-lg-3 col-xs-3 text-sm-end">
                            <label class="col-form-label">
                                Package name
                            </label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-5 col-xl-5">
                            {{ package_detail.name }}
                        </div>
                    </div>
                    <div class="form-group row align-items-center">
                        <div class="col-12 col-sm-3 col-md-4 col-lg-3 col-xs-3 text-sm-end">
                            <label class="col-form-label">Price per treatment</label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-5 col-xl-5">
                            ${{ package_detail.price_treatment }}
                        </div>
                    </div>
                    <div class="form-group row align-items-center">
                        <div class="col-12 col-sm-3 col-md-4 col-lg-3 col-xs-3 text-sm-end">
                            <label class="col-form-label">Number of treatments</label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-5 col-xl-5">
                            {{ package_detail.treatments }}
                        </div>
                    </div> 
                    <div class="form-group row align-items-center">
                        <div class="col-12 col-sm-3 col-md-4 col-lg-3 col-xs-3 text-sm-end">
                            <label class="col-form-label">Total amount</label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-5 col-xl-5">
                            ${{ package_detail.total }}
                        </div>
                    </div>
                    <h4><b>Custom Procedures</b></h4>
                    <div class="">
                        <div class="table-responsive">
                            <table class="table table-hover table-bordered mb-0">
                                <thead>
                                    <tr>
                                        <td>No</td>
                                        <td>Code</td>
                                        <td>Description</td>
                                        <td style="min-width: 70px">Quantity</td>
                                        <td style="min-width: 120px">Price</td>
                                    </tr>
                                </thead>
                                <tbody v-if="package_detail.cpts.length>0">
                                    <tr v-for="(item, indx) in package_detail.cpts" :key="indx">
                                        <td>{{ indx+1 }}</td>
                                        <td>
                                            {{ item.code }}
                                            <div v-if="cps_data">
                                                <ul v-if="cps_data.length>0 && cps_data[item.service_cpt_id]" class="ul-checklist-h">
                                                    <li v-for="(i, idx) in cps_data[item.service_cpt_id]" :key="i.service_id+idx">
                                                        <input type='checkbox' :checked="i.is_selected" disabled >  {{ i.product_name }}
                                                    </li>
                                                </ul>
                                            </div>
                                        </td>
                                        <td>{{ item.description }}</td>
                                        <td>{{ item.qty }}</td>
                                        <td>${{ item.service_cpt_price }}</td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr>
                                        <td class="text-center" colspan="5">No procedure available</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="modal-footer justify-content-center">
                    <button type="button" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
    .ul-checklist-h {font-size: 1rem; list-style: none; padding-left: 7px; margin-bottom: 0; margin-top: 0.4rem;}
    .ul-checklist-h li { padding: 2px 0; }
    .ul-checklist-h li input[type="checkbox"] { position: unset; opacity: 1 }

    .btn-toggle:before { content: 'Cash'; left: -5rem; }
    .btn-toggle:after { content: 'Credit Card'; right: -7rem; width: auto; }
    .btn.btn-toggle { background: #0265a0; } 
    .btn-toggle:before, .btn-toggle:after { color: #2d3c58; }
</style>
<script>
    import TabbarComp from "@/components/patients/details/Payment/TabbarComp.vue";
    import CustomProcedureCode from "@/components/base/CustomProcedureCode.vue";
    import axios from "@/scripts/axios.js";
    
    export default {
        components: {
            TabbarComp,
            CustomProcedureCode
        },
        data() {
            return {
                checked_service: [],
                customproceduretable: [],
                cps: [],
                form: {
                    no_of_treatments: 1,
                    cps: [],
                    package_price: '00.00',
                    frequency_time: 1,
                    frquency_repetation: 0,
                    price_per_treatment: '00.00',
                    patient_id: this.$store.state.patientDetail.patient_id,
                    package_code: '',
                    package_name: '',
                    service_cpt: [],
                    cctoken_id: '',
                    payment_method: 'cash',
                    cpt_names: [],
                    use_credidcard_for_future: false,
                    token: ''
                },
                error: {
                    package_code: '',
                    package_name: '',
                    service_cpt: '',
                    cctoken_id: ''
                },
                paynow: {
                    package_id: 0,
                    package_price: 0,
                },
                package_detail: {
                    code: '',
                    name: '',
                    price_treatment: 0,
                    treatments: 0,
                    total: 0,
                    cpts: []
                },
                packages: [],
                customproceduredelete: [],
                customprocedurecodedelete: [],
                prices: [],
                ccTokenData: [],
                is_hippasign: 2,
                cps_data: [],
				tokenpay: {},
                tokenpay1: {},
                tokenPayReady: false,
                checkingInterval: null,
            };
        },
        async created() {
            let localThis = this
            localThis.checkingInterval = setInterval(await function(){
				if (window.TokenPay) {
					localThis.tokenPayReady = true
				}
			}, 500);
            localThis.form.cps = [];
            localThis.getPackages();
            localThis.fetchPatientToken();
            localThis.getPatientHippaStatus();
            // localThis.initializeToken();
        },
		watch: {
			tokenPayReady: function(data) {
				if (data) { 
					clearInterval(this.checkingInterval) 
					this.initializeToken()
				}
			}
		},
        mounted() {
            let bridgePayScript = document.createElement('script')
			bridgePayScript.setAttribute('src', process.env.VUE_APP_API_RECTANGLEHEALTH_TOKENPAY_URL)
			document.head.appendChild(bridgePayScript);
        },
        methods: {
            getPackages() {
                this.$store.state.loader = true;
                axios
                .get('/patient-package/list/'+this.$store.state.patientDetail.patient_id)
                .then((response) => {
                    this.packages = response.data.data;
                    this.$store.state.loader = false;
                });
            },
            getRemainingAmount(price_per_treatment, completed_no_of_treatments, package_price) {
                return package_price-(price_per_treatment*completed_no_of_treatments);
            },
            async customproceduresearchresult(obj) {
                /*get services */
                var cps = "";
                var $this = this;
                $this.$store.state.loader = true;
                if(!this.checked_service[obj.id]) {
                    this.checked_service[obj.id] = [];
                }
                if(!$this.cps_data[obj.id])  {
                    $this.cps_data[obj.id] = [];
                }
                
                await axios.get("appointment-billing/services_id/" + obj.id).then((res) => {
                    if(res.data.data) {
                        $this.cps_data[obj.id] = res.data.data;
                        $this.cps_data[obj.id].forEach(cp => {
                            cp['is_selected'] = 1;
                        });
                    } else {
                        $this.cps_data[obj.id] = [];
                    }
                    this.customproceduretable.push({
                        id: obj.id,
                        code: obj.code,
                        description: obj.description,
                        custom_procedure_qty: 1,
                        custom_procedure_price: obj.price,
                        custom_procedure_table_id: "",
                        cps: cps
                    });
                    this.form.cpt_names.push(obj.code);
                });
                
                
                this.form.service_cpt.push({
                    service_cpt_id: obj.id,
                    type: 'cpt',
                    service_cpt_price: obj.price
                });
                this.prices[obj.id] = obj.price;
                this.form.price_per_treatment = parseFloat(this.form.price_per_treatment) + parseFloat(obj.price);
                this.update_package_price()
                this.$store.state.loader = false;
            },
            updateservices(index, cp_id) {
                this.cps_data[cp_id][index]['is_selected'] = this.cps_data[cp_id][index]['is_selected']==1 ? 0 : 1;
            },
            createPackageToken() {
                this.$store.state.loader = true;
                var tokenVal = "";
                if(this.form.cctoken_id=="addNew") {
                    this.tokenpay1.createToken((result) => { 
                        tokenVal = result.token;
                        this.form.token = tokenVal;
                        this.createPackage();
                    });
                } else {
                    this.createPackage();
                }
            },
            createPackage() {
                try {
                    this.$store.state.loader = true;
                    this.form.cps=[];
                    this.form.cps = this.cps_data;
                    
                    axios.post("patient-package", this.form)
                    .catch((error) => {
                        this.$store.state.loader = false;
                        let res = error.response.data.data;
                        if(res) {
                            if(res.package_code) {
                                this.error.package_code = res.package_code[0];
                            } else {
                                this.error.package_code = '';
                            }
                            if(res.package_name) {
                                this.error.package_name = res.package_name[0];
                            } else {
                                this.error.package_name = '';
                            }
                            if(res.service_cpt) {
                                this.error.service_cpt = res.service_cpt[0].replace("service cpt", "custom procedures");
                            } else {
                                this.error.service_cpt = '';
                            }
                            if(res.cctoken_id) {
                                this.error.cctoken_id = res.cctoken_id[0].replace("cctoken id", "Credit card");
                            } else {
                                this.error.cctoken_id = '';
                            }
                        }
                    })
                    .then((res) => {
                        if(res.data.status=="success") {
                            var msg = "Package created successfully.";
                            if(!res.data.data.wallet_response.success) {
                                msg = "Package created successfully. But Payment get failed. <br/>You can pay again from package list.";
                            }
                            this.$store.state.loader = false;
                            this.$swal.fire(
                                "Created!",
                                msg,
                                "success"
                            )
                            .then((result) => {
                                this.$store.state.loader = true;
                                this.$router.go();
                            });
                        } else {
                            this.$store.state.loader = false;
                        }
                    });
                } catch(e) {
                    this.$store.state.loader = false;
                }
            },
            customprocedureremove(cpsid, id, code, index){
                this.update_price(this.customproceduretable[index]['id'], 0);
                this.customproceduretable.splice(index, 1);
                if(cpsid != null) {
                    if(this.cps_data.length>0) {
                        this.cps_data.splice(cpsid, 1);
                    }
                    if (this.form.service_cpt.length>0) {
                        this.form.service_cpt.forEach((cps, ind) => {
                            if(this.form.service_cpt[ind]['service_cpt_id']==cpsid){
                                  this.form.service_cpt.splice(ind, 1);
                            }
                          
                        });
                       
                    }
                }
                if(id != null){
                    this.customproceduredelete.push(id)
                }
                if(code != null){
                    this.customprocedurecodedelete.push(code)
                    this.form.cpt_names.forEach((cn, cnidx) => {
                        if(cn==code) {
                            this.form.cpt_names.splice(cnidx, 1);
                        }
                    })
                }
            },
            update_price(id, price) {
                if(this.prices) {
                    this.prices[id] = price;
                    this.form.price_per_treatment = 0;
                    this.prices.forEach((price) => {
                        this.form.price_per_treatment = parseFloat(this.form.price_per_treatment) + parseFloat(price);
                    });
                    if(this.form.service_cpt) {
                        this.form.service_cpt.forEach((sc, ind) => {
                            if(sc.service_cpt_id==id) {
                                sc.service_cpt_price = price;
                            }
                        });
                    }
                    this.update_package_price()
                }
            },
            update_qty_price(id) {
                if(this.customproceduretable) {
                    if(this.customproceduretable[id]['custom_procedure_qty']<1) {
                        this.customproceduretable[id]['custom_procedure_qty'] = 1;
                    }
                    let price = this.customproceduretable[id]['custom_procedure_qty'] * this.customproceduretable[id]['custom_procedure_price'];
                    this.update_price(this.customproceduretable[id]['id'], price);
                }
            },
            update_package_price() {
                if(this.form.no_of_treatments<1) { this.form.no_of_treatments = 1 }
                this.form.package_price = this.form.no_of_treatments * this.form.price_per_treatment
            },
            fetchPatientToken() {
                axios
                .post('/payment/patient-cctoken',{patient_id:this.$store.state.patientDetail.patient_id})
                .then((response) => {
                    this.ccTokenData = response.data.data;
                });
            },
            payNow() {
                this.$store.state.loader = true;
                var tokenVal = "";
                if(this.form.cctoken_id=="addNew") {
                    this.tokenpay.createToken((result) => { 
                        tokenVal = result.token;
                        this.form.token = tokenVal;
                        this.makePayment();
                    });
                } else {
                    this.makePayment();
                }
                
            },
            makePayment() {
                axios.post('/patient-package/patient-pay', { 
                    'patient_id': this.form.patient_id,
                    'package_id': this.paynow.package_id,
                    'package_price': this.paynow.package_price,
                    'cctoken_id': this.form.cctoken_id,
                    'payment_method': this.form.payment_method,
                    'token': this.form.token
                })
                .catch((error) => {
                    var msg = '';
                    if(error.response.data.data.message) {
                        msg = error.response.data.data.message;
                    }
                    if(error.response.data.message) {
                        msg = error.response.data.message;
                    }
                    this.$store.state.loader = false;
                    this.$swal.fire(
                        "Payment failed!",
                        msg,
                        "error"
                    );
                })
                .then((response) => {
                    if(response.data.status=="success") {
                        this.$store.state.loader = false;
                        this.$swal.fire(
                            "Created!",
                            "Payment done successfully.",
                            "success"
                        )
                        .then((result) => {
                            this.$store.state.loader = true;
                            this.$router.go();
                        });
                    }
                });
            },
            setPayNowValues(package_id, package_price) {
                this.paynow.package_id = package_id;
                this.paynow.package_price = package_price;
            },
            getPatientHippaStatus() {
                axios.post('/lifestyleiv/hippa_form',{ 
                    patient_id: this.form.patient_id
                })
                .then((response) => {
                    if(response.data.data.trim()=="Hippa sign not required") {
                        this.is_hippasign=1;
                    } else {
                        this.is_hippasign=0;
                    }
                });
            },
            sendHippaInvitation() {
                this.$store.state.loader = true;
                axios.post('lifestyleiv/send-hippa/'+this.$store.state.patientDetail.patient_id)
                .then((response) => {
                    this.$store.state.loader = false;
                    this.$swal.fire(
                        "Invitation Sent!",
                        "Hippa Invitation Sent successfully.",
                        "success"
                    );
                });
            },
            async getPackageDetail(package_id) {
                this.$store.state.loader = true;
                await axios.get('/patient-package/package-detail/'+package_id)
                .then((response) => {
                    let packageDetail = response.data.data;
                    this.package_detail.code = packageDetail.package_code;
                    this.package_detail.name = packageDetail.package_name;
                    this.package_detail.price_treatment = packageDetail.price_per_treatment;
                    this.package_detail.treatments = packageDetail.no_of_treatments;
                    this.package_detail.total = packageDetail.package_price;
                    this.package_detail.cpts = packageDetail.cpts;
                    if(this.package_detail.cpts) {
                        this.cps_data=[];
                        this.package_detail.cpts.forEach((itm, indx) => {
                            if(!this.cps_data[itm.service_cpt_id]) {
                                this.cps_data[itm.service_cpt_id] = [];
                            }
                            var selectedcps = [];
                            if(itm.selected_cps) {
                                selectedcps = itm.selected_cps.split(",");
                            }
                            if(itm.cps) {
                                itm.cps.forEach((itm1,indx1) => {
                                    var is_selected = 0;
                                    selectedcps.forEach((sv, sk) => {
                                        if(sv==itm1.service_id) {
                                            is_selected = 1;
                                        }
                                    })
                                    this.cps_data[itm.service_cpt_id][indx1] = {
                                        "product_name": itm1.product_name,
                                        "is_selected": is_selected,
                                        "service_id": itm1.service_id
                                    }
                                });
                            }
                        });
                    }
                    this.$store.state.loader = false;
                });
            },
            is_selected(id, selected_cps) {
                if(selected_cps) {
                    let selectedcps = selected_cps.split(",");
                    if(selectedcps.includes(id)) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            },
			initializeToken : function() {
				this.tokenpay = {};
				this.tokenpay = TokenPay('tokenpay20181api20211929111905298');
				this.tokenpay.initialize({
					dataElement: '#card',
					errorElement: '#errorMessage',
					amountElement: 'amount',
					useACH: false,
                    //if displaying all 4 fields then useStyles=false, disableZip=false, disableCvv=false
                    //if displaying 3 out of 4 fields then useStyles=false, and set disableZip or disableCvv equal to true
                    //if displaying 2 out of 4 fields then useStyles=true, disableZip=true, disableCvv=true
                    useStyles: true,
                    disableZip: true,
                    disableCvv: false
				});
                this.tokenpay1 = {};
				this.tokenpay1 = TokenPay('tokenpay20181api20211929111905298');
                this.tokenpay1.initialize({
					dataElement: '#card1',
					errorElement: '#errorMessage1',
					amountElement: 'amount',
					useACH: false,
                    //if displaying all 4 fields then useStyles=false, disableZip=false, disableCvv=false
                    //if displaying 3 out of 4 fields then useStyles=false, and set disableZip or disableCvv equal to true
                    //if displaying 2 out of 4 fields then useStyles=true, disableZip=true, disableCvv=true
                    useStyles: true,
                    disableZip: true,
                    disableCvv: false
				});
			}
        }
    }
</script>


