import {
    helpers,
    required,
    email,
    numeric,
} from "@vuelidate/validators";

const CorporateLifeStyleServiceCategory = {
  namespaced: true,
  state: {
    editID: "",
    listUrl: "/IV_life_style/category/list",
    getEditUrl: "/IV_life_style/category/edit/:id",
    tableData: {
      header: [
        "Category name",
        "Category Status",
        "Actions",
      ]
    },
    defaultFormData: {
       category_name: '',
    }, 
    form: {},
    vuelidateExternalResults: {
      form: {
        category_name: [],
      }
    },
    validationRules: {
      form:{
        category_name: {
          required: helpers.withMessage("category Name is required", required),
        },

      }
    },
    list: [],
  },
  getters: {
    getTableHeaders: (state) => state.header,
    getTableContents: (state) => state.list,
    getListURL: (state) => state.listUrl,
  },
  mutations: {},
  actions: {},
};

export default CorporateLifeStyleServiceCategory;