<template>
	<div class="content-wrapper">
		<div class="container-full">
			<section class="content">
				<div class="row justify-content-sm-center">
					<div class="col-12 col-sm-12 col-md-12 col-lg-7 col-xl-6 col-xxl-5">
						<h1 class="font-bold mt-0 float-start me-10">CCDA Preferences <a href="#" data-bs-toggle="modal" data-bs-target="#ccdadocument" title="Reference of Knowledge Base" v-on:click.prevent="reset()"> <i class="fa fa-question-circle fs-20"></i></a></h1>
						<div class="float-start float-sm-end mb-10">
							<form-btn title="Save" btnCls="btn-info" @click="submitForm()"></form-btn>
							<form-btn title="Reset" btnCls="btn-info-light" @click="resetForm()"></form-btn>
						</div>
						<draggable tag="ul" :list="form.list" class="todo-list ui-sortable mb-20 w-p100" handle=".handle" item-key="name">
							<template #item="{ element, index }">
								<li class="py-1 px-0 b-0" :key="index">
									<span class="box p-10 mb-0 d-block border-light">
										<span class="fs-18 fw-400 me-10 float-start">
											<span class="float-start" style="cursor: move;">
												<i class="fa fa-ellipsis-v handle fw-100 fs-14 text-mute me-0"></i>
												<i class="fa fa-ellipsis-v handle fw-100 fs-14 text-mute me-10"></i>
											</span>
											 {{ element.name }} 
											</span>
										<span class="d-inline-block" style="margin-top: 2px;">
											<input type="checkbox" :id="element.name+index" v-model="element.checkvalue" class="form-control filled-in chk-col-info w-auto">
											<label :for="element.name+index"  class="text-start"></label>
											<small class="fs-14 align-top" v-if="element.name == 'Vital Signs' || element.name == 'Admission Diagnosis' || element.name == 'Chief Complaint' || element.name == 'Meds Administered' || element.name == 'Medical Equipment' || element.name == 'Referrals' || element.name == 'Discharge Instructions' || element.name == 'Admission Meds' || element.name == 'Discharge Meds'">(This object will be visible only if it will have data.)</small>
										</span>
									</span>
								</li>
							</template>
						</draggable>
					</div>
					<!--document model start-->
					<div id="ccdadocument" class="modal fade" tabindex="-1" role="dialog"
						aria-labelledby="myModalLabel" aria-hidden="true">
						<div class="modal-dialog modal-md">
							<div class="modal-content">
							<div class="modal-header">
							<h3 class="modal-title fw-900 mt-0">Reference of Knowledge Base</h3>
							</div>
							<div class="modal-body overflow-auto" style="max-height: 350px;">
							<table class="table table-bordered">
								<thead>
									<tr>
									<th scope="col">No.</th>
									<th scope="col">Knowledge Base Articles</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>1</td>
										<td> <a :href="path+'how-to-change-the-preferences-of-sections-in-ccda-in-pureehr'" target="_blank">How To Change The Preferences Of Sections In CCDA In PureEHR?</a></td>
									</tr>
								</tbody>
							</table>
							<Search :key="searchKey"></Search>
							</div>
							<div class="modal-footer">
							<button type="button" @click="" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
							</div>
							</div>
						</div>
					</div>
					<!--document model end-->
				</div>
			</section>
		</div>
	</div>
</template>
<script>
	import axios from "@/scripts/axios.js";
	import draggable from 'vuedraggable';
	import FormBtn from "@/components/base/formFields/formBtn.vue";
	import submitMixins from "@/scripts/mixins/submitMixins.js";
	import Search from "@/components/Search.vue";
	export default {
		mixins: [submitMixins],
		name: "handle",
		display: "Handle",
		instruction: "Drag using the handle icon",
		order: 5,
		components: {
			draggable,FormBtn,Search
		},
		data() {
			return {
				form: {
					list: [],
				},
				dragging: false,
				path: process.env.VUE_APP_KB_DOCUMENT,
				searchKey:0
			};
		},
		computed: {},
		methods: {
			reset() {
				this.searchKey = this.searchKey + 1;
			},
			submitForm(){
				var url = "/phimailbox/save-user-preferences";
				this.postForm(url,"", false, "json");
			},
			formPostSuccess(data) {
				this.moshaToast("Preferences added successfully.", "success"); 
			},        
			resetForm(id) {
				this.form.list.forEach(element => {
					element.checkvalue = true
				});
				this.$swal.fire({
					title: '<h2 class="mt-0">Are you sure?</h2>',
					text:
					"You want to reset these records? This process cannot be undone.",
					icon: "warning",
					dangerMode: true,
					showCancelButton: true,
					confirmButtonColor: "#ee3158",
					cancelButtonColor: "#bdbdbd",
					confirmButtonText: "Yes, reset it!",
				}).then((result) => {
					if (result.isConfirmed == true) {
						this.submitForm();
					}
				});
			},
		},
		async beforeCreate() {
			axios.post("/phimailbox/get-user-preferences")
			.then((response) => {
				this.form.list = response.data.data
			});
		}
	};
</script>