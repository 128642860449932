<template>
  <div class="form-group row" :class="{ error: errors.length}">
    <label
      :class="labelClass"
      >{{ title }}
      <small v-show="isRequired" class="text-danger asterisksign">*</small></label
    >
    <div :class="divClass">
      <div class="controls">
        <input
          :id="title"
          type="datetime-local"
          class="form-control"
          :value="formatDate(modelValue,'YYYY-MM-DDThh:mm')"
          @input="$emit('update:modelValue', $event.target.value)"
          :placeholder="title"
        />
        <span class="form-text text-muted" v-if="notes != ''">{{notes}}</span>
      </div>
      <div class="error-msg fs-14">
        <ul class="mb-0 p-0">
          <li class="d-block" v-for="(error, index) of errors" :key="index">
            {{ error.$message }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";

export default {
  props: {
    title: {
      type: String,
      default: ""
    },
    modelValue: {
      type: String,
      default: ""
    }, 
    errors: {
      type: Array,
      default: []
    }, 
    labelClass:{
      type: String,
      default: "col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end"
    },
    divClass:{
      type: String,
      default: "col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3"
    },
    isRequired:{
        type: Boolean,
        default : false
    },
    notes: {
      type: String,
      default: ""
    },
  },
  methods: {        
    formatDate(date, format) {
        return moment(date).format(format);
    },
  }
};
</script>