<template>
   <div class="box p-15">
      <div class="mdc-tab">
         <ul class="nav nav-tabs mt-0 text-center text-sm-start" role="tablist">
            <li class="nav-item"> <a class="nav-link" data-bs-toggle="tab" href="#healthCareBilling" @click.prevent="changeTab('HealthCareBilling')" role="tab">Health Care</a> </li>
            <li class="nav-item"> <a class="nav-link active" data-bs-toggle="tab" href="#billingPOS" @click.prevent="changeTab('BillingPOS')" role="tab">POS</a> </li>
            <li class="nav-item"> <a class="nav-link" data-bs-toggle="tab" href="#billingContract" @click.prevent="changeTab('BillingContract')" role="tab">Contract</a> </li>
            <li class="nav-item"> <a class="nav-link" data-bs-toggle="tab" href="#wallet" @click.prevent="changeTab('Wallet')" role="tab">Wallet</a> </li>
         </ul>
         <div class="tab-content">
            <div class="tab-pane active" id="billingPOS" role="tabpanel">
               <div class="box bg-lightest p-10 my-2">
                  <div class="text-md-right">
                     <a @click="neworder" href="javascript:void(0)" class="waves-effect waves-light btn btn-info me-1" data-bs-toggle="modal" data-bs-target="#neworder"><i class="fa fa-plus fs-14"></i> New Order</a>
                  </div>
               </div>
               <div class="table-responsive">
                  <table class="table">
                     <thead>
                        <tr>
                           <th></th>
                           <th>Order Date</th>
                           <th>Reference No</th>
                           <th>Purpose Of Payment</th>
                           <th>Billing Amount</th>
                           <th>Received Amount</th>
                           <th>Remaining Amount</th>
                           <th>Action</th>
                        </tr>
                     </thead>
                     <tbody v-for="item in orderData.slice(this.start,this.end)" v-if="orderData.length>0" :key="item.id">
                        <tr>
                           <td>
                              <a href="javascript:void(0)" class="waves-effect waves-light btn btn-info-light btn-xs allopenbtn" @click="getreceipt(item.id)" v-bind:id="'add'+item.id">
                                 <i class="fa fa-plus"></i>
                              </a>
                              <a href="javascript:void(0)" class="waves-effect waves-light btn btn-danger-light btn-xs allclosebtn d-none" @click="receiptclose(item.id)" v-bind:id="'min'+item.id">
                                 <i class="fa fa-minus"></i>
                              </a>
                           </td>
                           <td>{{$filters.displayDateTimeLocal(item.created_at,'',false,"ddd, MM-DD-YYYY",true)}}</td>
                           <td>{{item.order_reference_no}}</td>
                           <td>{{item.purposeofpayment}}</td>
                           <td> {{ $filters.toUSD(item.basic_amount) }}</td>
                           <td> {{ $filters.toUSD(item.received_amount) }}</td>
                           <td> {{ $filters.toUSD(item.basic_amount - item.received_amount) }}</td>
                           <td>
                              <a href="javascript:void(0)" class="waves-effect waves-light btn btn-info btn-sm" @click="changeOrder(item.id)"><i class="fa fa-plus fs-12"></i> Payment</a>
                           </td>
                        </tr>
                        <tr class="d-none receipt_hide b-1" v-bind:id="'receipt'+item.id" style="background:#f7fafc;">
                           <td colspan="9">
                              <table class="table">
                                 <thead>
                                    <tr>
                                       <td>Payment Date</td>
                                       <td>Amount</td>
                                       <td>Payment Type</td>
                                       <td>Payment Note</td>
                                       <td>Action</td>
                                    </tr>
                                 </thead>
                                 <tbody>
                                    <tr v-for="(items,index) in receiptlist" :key="index">
                                       <td>{{$filters.displayDateTimeLocal(items.payment_date,'',false,"ddd, MM-DD-YYYY",true)}}</td>
                                       <td>${{items.paid_amount}}</td>
                                       <td>{{(items.payment_type == 'Other') ? items.other_payment_type : items.payment_type }}</td>
                                       <td>{{items.payment_note}}</td>
                                       <td>
                                          <div class="d-flex">
                                             <Popper arrow content="View Receipt" hover=true class="helptips">
                                                   <a href="javascript:void(0)" class="waves-effect waves-circle btn btn-circle btn-info btn-xs me-1" @click ="printReceipt(items.id)">
                                                      <i class="fa fa-eye"></i>
                                                   </a>
                                              </Popper>
                                              <Popper arrow content="Email Receipt" hover=true class="helptips">
                                                   <a href="javascript:void(0)" class="waves-effect waves-circle btn btn-circle btn-info btn-xs" @click="sendReceipt(items.id)">
                                                      <i class="fa fa-paper-plane"></i>
                                                   </a>
                                              </Popper>
                                          </div>
                                       </td>
                                    </tr>
                                 </tbody>
                              </table>
                           </td>
                        </tr>
                     </tbody>
                     <tbody v-else>
                        <tr>
                           <td colspan="9" class="text-center">No Data Found</td>
                        </tr>
                     </tbody>
                  </table>
                  <label>Showing {{start}} to {{(end >= list_lenght )? list_lenght : end}} of {{list_lenght}} entries</label>
                  <a v-if="end < list_lenght" href="javascript:void(0)" class="btn btn-light float-end" @click="next(this.end)">Next</a>
                  <p class="float-end">
                     <a href="javascript:void(0)" class="btn btn-light me-5" v-for="item in steps" @click="pagejump(item)" :key="item">{{item}}</a>
                  </p>
                  <a v-if="this.start != 0" href="javascript:void(0)" class="btn btn-light float-end me-5" @click="previous(this.end,this.start)">Previous</a>
               </div>
            </div>
         </div>
         <div class="tab-pane animation-fade active" id="category-1" role="tabpanel">
            <div class="panel-group panel-group-simple panel-group-continuous mb-0" id="accordion2" aria-multiselectable="true" role="tablist">
            </div>
         </div>
      </div>
   </div>

<!--show New Order start-->
<div class="modal fade" id="neworder" tabindex="-1" role="dialog" aria-labelledby="neworder" aria-hidden="true">
   <div class="modal-dialog modal-md">
      <div class="modal-content">
         <div class="modal-header">
            <h3 class="modal-title fw-900 mt-0">Add Billing</h3>
         </div>
         <div class="modal-body">
            <div class="box box-solid">
               <div class="box-header with-border bg-secondary">
                  <h2 class="black-color m-0 fs-20" id="creditpayment">Payment</h2>
               </div>
               <div class="box-body text-dark">
                  <form @submit.prevent id="paymentForm" method="post">
                     <div  style="margin-bottom: 10px; color: #c0392b;" v-if="serverError">{{ serverError }}</div>
                     <div class="form-group">
                        <label for="name" class="form-label">Purpose Of Payment</label>
                        <div class="input-group">
                           <select name="select" class="form-select form-control" v-model="payment_frm.purpose_of_payment" :disabled="order_id != ''">
                              <option disabled="" value="">Select Purpose Of Payment</option>
                              <option value="other">Add New</option>
                              <option v-for="item in purposeofpayment" :key="item.id" :value="item.id">{{item.purposeofpayment}}</option>
                           </select>
                           <div
                              class="invalid-feedback"
                              style="display:block;"
                              v-for="(error, index) of v$.payment_frm.purpose_of_payment.$errors"
                              :key="index">
                              {{ error.$message }}
                           </div>
                        </div>
                     </div>
                     <div class="form-group" v-show="payment_frm.purpose_of_payment == 'other'">
                        <div class="input-group">
                           <input type="text" id="purpose_of_payment" class="form-control" v-model="payment_frm.purposeofpaymentother" placeholder="Add New" />
                           <div class="invalid-feedback" style="display:block;">{{purpose_of_paymenterror}}</div>                           
                        </div>
                     </div>
                     <div class="form-group">
                        <label for="name" class="form-label">Billing Amount</label>
                        <div class="input-group">
                           <span class="input-group-addon">$</span>
                           <input type="text" class="form-control" @blur="handlePaybleBlur" v-model="payment_frm.payable_amount" placeholder="Billing Amount" />
                           <div
                              class="invalid-feedback"
                              style="display:block;"
                              v-for="(error, index) of v$.payment_frm.payable_amount.$errors"
                              :key="index">
                              {{ error.$message }}
                           </div>
                        </div>
                     </div>
                     <div class="form-group" v-if="this.billing_summary.order_payment_info.length > 0">
                        <label for="name" class="form-label">Received Amount</label>
                        <div class="input-group">
                           <span class="input-group-addon">$</span>
                           <input type="text" class="form-control" readonly="" v-model="billing_summary.received_amount" placeholder="Billing Amount" />
                        </div>
                     </div>
                     <div class="form-group">
                        <label class="form-label">Amount to Charge</label>
                        <div class="input-group">
                           <span class="input-group-addon">$</span>
                           <input type="text" class="form-control" v-model="payment_frm.paid_amount" @blur="handlePaidBlur" v-on:keypress="numbersOnly" placeholder="Enter Amount" />
                           <div
                              class="invalid-feedback"
                              style="display:block;"
                              v-for="(error, index) of v$.payment_frm.paid_amount.$errors"
                              :key="index"
                              >
                              {{ error.$message }}
                           </div>
                        </div>
                     </div>
                     <div class="form-group">
                        <label class="form-label">Payment Method</label>
                        <select class="form-select form-control" v-model="payment_frm.payment_type" id="payment_type" @change="payTypeChange" >
                           <option value="">- Select Payment Type -</option>
                           <option label="Cash" value="Cash" >Cash</option>
                           <option label="Check" value="Check">Check</option>
                           <option label="Credit Card" value="Credit Card">Credit Card</option>
                           <option label="Other" value="Other">Other</option>
                           <option v-if="$store.state.patientDetail.wallet_amount > 0" :label="'Wallet ('+$filters.toUSD($store.state.patientDetail.wallet_amount)+')'" value="Wallet">Wallet ({{ $filters.toUSD(this.$store.state.patientDetail.wallet_amount) }}) </option>
                        </select>
                        <div class="invalid-feedback" style="display:block;" v-if="wallet_error != ''">{{ wallet_error }}</div>
                        <div
                           class="invalid-feedback"
                           style="display:block;"
                           v-for="(error, index) of v$.payment_frm.payment_type.$errors"
                           :key="index"
                           >
                           {{ error.$message }}
                        </div>
                     </div>
                     <div class=""  v-show="payment_frm.payment_type == 'Other'">
                        <div class="form-group">
                           <input type="text" class="form-control" v-model="payment_frm.other_payment_type" placeholder="Enter Other Payment">
                           <div
                              class="invalid-feedback"
                              style="display:block;"
                              v-for="(error, index) of v$.payment_frm.other_payment_type.$errors"
                              :key="index"
                              >
                              Other payment is required.
                           </div>
                        </div>
                     </div>
                     <div class=""  v-show="payment_frm.payment_type == 'Check'">
                        <div class="form-group">
                           <label class="form-label">Check No.</label>
                           <input type="text" class="form-control" v-model="payment_frm.check_trace" placeholder="Check No.">
                           <div
                              class="invalid-feedback"
                              style="display:block;"
                              v-for="(error, index) of v$.payment_frm.check_trace.$errors"
                              :key="index"
                              >
                              Check No. required
                           </div>
                        </div>
                     </div>
                     <div class="" v-if="payment_frm.payment_type == 'Credit Card'">
                        <div class="form-group">
                           <select class="form-select form-control" v-model="payment_frm.cctoken_id">
                              <option value="">- Select Credit-Card -</option>
                              <option value="addNew">Add New Card</option>
                              <option v-for="(item,index) in ccTokenData" :value="item.id" :data-val="item.id" :key="index">{{item.card_label}}</option>
                           </select>
                           <div
                              class="invalid-feedback"
                              style="display:block;"
                              v-for="(error, index) of v$.payment_frm.cctoken_id.$errors"
                              :key="index"
                              >
                              Please select any option.
                           </div>
                        </div>
                     </div>
                     <div class="form-group" v-show="payment_frm.payment_type == 'Credit Card' && (payment_frm.cctoken_id == 'addNew')">
                        <div style="display: none" id="customStyles">
                           body{margin:0;}
                           iframe{height: 100px;}
                           #payment-form {border: 1px solid rgb(212, 221, 230);max-height: 130px;width: 100%;padding: 20px 10px;border-radius: 4px;margin: 0px;background: rgb(245, 248, 250);}
                           #payment-form {color: #2d3c58;}
                           #payment-form .input-label {color: #2d3c58;border-top: 1px solid #d4dde6;font-size:13px;text-align: center !important;}
                        </div>
                        <div id="card" style="height: 130px;"></div>
                        <div id="errorMessage" style="margin-bottom: 8px; color: #c0392b;"></div>
                        <input
                           type="checkbox"
                           id="use_credidcard_for_future_checkbox"
                           class="filled-in chk-col-info"
                           v-model="payment_frm.use_credidcard_for_future"
                           />
                        <label for="use_credidcard_for_future_checkbox" class="m-0 text-start">Save for future use?</label>
                     </div>
                     <div class="form-group">
                        <textarea
                           class="form-control"
                           id="payment_note"
                           v-model="payment_frm.payment_note"
                           rows="2"
                           placeholder="Payment Note"
                           ></textarea>
                     </div>
                  </form>
                  <button v-if="permissioncheck(this.$store.state.permission.create_update)" type="button" class="waves-effect waves-light btn me-1 waves-effect waves-light btn btn-info me-1" @click="handlePOSPay">Pay</button>
                  <a v-if="permissioncheck(this.$store.state.permission.create_update)" href="javascript:void(0)" @click.prevent="resetForm" class="waves-effect waves-light btn me-1 btn-danger-light">Clear</a>
               </div>
            </div>
            <div v-if="!is_production_env">
               <div class="table-responsive">
                  <table class="ql-table-blob table" data-aura-rendered-by="9911:0">
                     <thead>
                        <tr>
                           <th>Card Type</th>
                           <th>Number</th>
                           <th>Expiry Date</th>
                           <th>Security Code</th>
                        </tr>
                     </thead>
                     <tbody>
                        <tr>
                           <td>Mastercard</td>
                           <td>5439750001500347</td>
                           <td>12/29</td>
                           <td>999</td>
                        </tr>
                        <tr>
                           <td>Visa</td>
                           <td>4005550000000019</td>
                           <td>12/29</td>
                           <td>999</td>
                        </tr>
                        <tr>
                           <td>Discover</td>
                           <td>60011111111111117</td>
                           <td>12/29</td>
                           <td>999</td>
                        </tr>
                        <tr>
                           <td>Diners</td>
                           <td>36999999999999</td>
                           <td>12/29</td>
                           <td>999</td>
                        </tr>
                        <tr>
                           <td>AMEX</td>
                           <td>374255312721002</td>
                           <td>12/29</td>
                           <td>9999</td>
                        </tr>
                     </tbody>
                  </table>
               </div>
            </div>
         </div>
         <div class="modal-footer">
            <button type="button" @click.prevent="resetForm" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
         </div>
      </div>
   </div>
</div>
<!--show New Order end-->

<!--show viewReceipt start-->
<div class="modal" id="viewReceipt" tabindex="-1">
   <div class="modal-dialog modal-md" style="max-width:75%">
      <div class="modal-content">
         <div class="modal-header">
            <button type="button" class="btn-close btn-xs" data-bs-dismiss="modal" aria-label="Close"></button>
         </div>
         <div class="modal-body">
            <vue-pdf-viewer height="1050px" :url="receipt_url" v-if="embed=0">
            </vue-pdf-viewer>
            <iframe :src="receipt_url" width="100%" height="500px" v-if="embed=1" />
            <img srd="images/1.gif" v-else> 
         </div>
      </div>
   </div>
</div>
<!--show viewReceipt end-->
</template>
<script>
   import VuePDFViewer from 'vue-instant-pdf-viewer';
   import permissionMixins from "@/scripts/mixins/permission.js";
   import axios from "@/scripts/axios.js";
   import submitMixins from "@/scripts/mixins/submitMixins.js";
   import Popper from "vue3-popper";
   import useVuelidate from "@vuelidate/core";
   import {
   	required,
   	helpers,
   	minLength,
   	maxLength,
   	numeric,
   	requiredIf,
   } from "@vuelidate/validators";
   export default {
   	components: {
   		'vue-pdf-viewer': VuePDFViewer, Popper
   	},
   	setup() {
   		return { v$: useVuelidate() };
   	},
   	mixins: [submitMixins,permissionMixins],
   	data() {
   		return {
            purpose_of_paymenterror:'',
   			is_production_env : process.env.VUE_APP_NODE_ENV == 'production',
   			order_id : '',
   			appointment_number : '',
   			orderData: [],
   			hash: '',
   			payment_frm : {
   				payment_type : '',
   				basic_amount : 0,
   				discount_amount : 0,
   				payable_amount : '',
   				paid_amount : '',
   				payment_note : '',
   				check_trace : '',
   				other_payment_type : '',
   				cctoken_id : '',
   				use_credidcard_for_future : false,
   				patient_id : this.$store.state.patientDetail.patient_id,
   				patient_unique_id : this.$store.state.patientDetail.patient_unique_id,
   				purpose_of_payment :'',
   				purposeofpaymentother:'',
   			},
   			billing_summary : {
   				healthcare_amount: 0,
   				custom_amount: 0,
   				basic_amount: 0,
   				discount_amount: 0,
   				payable_amount: 0,
   				received_amount: 0,
   				order_payment_info: [],
   				order_info: {},
   			},
   			tokenpay : {},
   			tokenPayReady: false, 
   			checkingInterval: null,
   			serverError: '',
            wallet_error: '',
   			ccTokenData: [],
   			receipt_url: '',
   			embed: 0,
   			purposeofpayment:[],
            receiptlist:[],
            list_lenght:'',
            start:0,
            end:10,
            step:10,
            steps:'',
   		};
   	},
   	validations() {
   		return {
   			payment_frm: {
   				purpose_of_payment:{
   					required: helpers.withMessage("Purpose Of Payment is required.", required),
   				},
   				payable_amount: {
   					required: helpers.withMessage("Billing amount is required.", required),
   				},
   				paid_amount: {
   					required: helpers.withMessage("Amount is required.", required),
   				},
   				payment_type: {
   					required: helpers.withMessage("Payment Type is required.", required),
   				},
   				check_trace: {
   					required: requiredIf(function() {
   						return this.payment_frm.payment_type == 'Check' ? true : false;
   					}),
   				},
   				other_payment_type: {
   					required: requiredIf(function() {
   						return this.payment_frm.payment_type == 'Other' ? true : false;
   					}),
   				},
   				cctoken_id: {
   					required: requiredIf(function() {
   						return this.payment_frm.payment_type == 'Credit Card' ? true : false;
   					}),
   				},
   			},
   		};
   	},
   	async created() {
   		let localThis = this 
   		this.checkingInterval = setInterval(function(){
   			if (window.TokenPay) {
   				localThis.tokenPayReady = true
   			}
   		}, 500);
   		this.fetchPatientToken();
   		this.fetchOrderlist();
   	},
   	watch: {
   		tokenPayReady: function(data) {
   			if (data) { 
   				clearInterval(this.checkingInterval) 
   				this.initializeToken()
   			}
   		}
   	},
   	computed: {
   	},
   	mounted() {
   		let bridgePayScript = document.createElement('script')
   		bridgePayScript.setAttribute('src', process.env.VUE_APP_API_RECTANGLEHEALTH_TOKENPAY_URL)
   		document.head.appendChild(bridgePayScript);
   		
   		this.$store.state.loader = true;
   		axios.get('/json_dropdown/purposeofpayment')
   		.then((response) => {
   			this.$store.state.loader = false;
   			this.purposeofpayment = response.data.data;
   		});
   	},
   	methods: {
         pagejump(id){
            var last_id = parseInt(id * this.step);
            this.start = parseInt(last_id - this.step);
            this.end = last_id;
         },
         next(end){
            this.start = end;
            this.end = parseInt(end) + parseInt(this.step);
         },
         previous(end,start){
            this.start = parseInt(start)-parseInt(this.step);
            this.end = start;
         },
         getreceipt(id){
            $('.receipt_hide').addClass('d-none');
            $('.allclosebtn').addClass('d-none');
            $('.allopenbtn').removeClass('d-none');
            var receiptid = "#receipt"+id;
            $(receiptid).removeClass('d-none');
            $("#add"+id).addClass('d-none');
            $("#min"+id).removeClass('d-none');
            axios.post("/payment/pos-receipt-list",{patient_id:this.$store.state.patientDetail.patient_id,patient_billing_order_id:id})
            .then((response) => {
               this.receiptlist = response.data.data;
            }).catch((error) => {
               this.moshaToast(error.response.data.message, "danger");
            });
         },
         receiptclose(id){
            $("#add"+id).removeClass('d-none');
            $("#min"+id).addClass('d-none');
            $("#receipt"+id).addClass('d-none');
         },
         neworder() {
            this.order_id = '';
         },
         payTypeChange() {
            if (this.payment_frm.payment_type == 'Wallet' && this.payment_frm.paid_amount > this.$store.state.patientDetail.wallet_amount) {
               this.wallet_error = 'Charge amount should not greater then wallet amount.';
            } else {
               this.wallet_error = '';
            }
         },
   		handlePaybleBlur() {
   			if (this.payment_frm.paid_amount <= 0) {
   				this.payment_frm.paid_amount = this.payment_frm.payable_amount;
   			}
   		},
   		fetchOrderlist() {
   			var $this = this;
   			$this.$store.state.loader = true;
   			axios
   			.post('payment/pos-list',{patient_id:this.$store.state.patientDetail.patient_id})
   			.then((response) => {
   				$this.$store.state.loader = false;
   				$this.orderData = response.data.data
               $this.list_lenght = $this.orderData.length;
               $this.steps = parseInt(this.list_lenght / this.step);
   			});
   		},
   		changeTab (components) {
   			this.$store.state.patientDetailsComp = components;
   			let props = this.$router.resolve({
   				name: components,
   				params: { id: this.$route.params.id},
   			});
				history.pushState(null, '',props.href);
   		},
   		numbersOnly(evt) {
   			evt = (evt) ? evt : window.event;
   			var charCode = (evt.which) ? evt.which : evt.keyCode;
   			if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
   				evt.preventDefault();;
   			} else {
   				return true;
   			}
   		},
   		printReceipt (id) {
   			this.getSrc(id);
   			$("#viewReceipt").modal("show");
   		},
   		sendReceipt(id) {
   			this.$store.state.loader = true;
   			axios
   			.post('payment/printApptReceipt',{payment_id : id , send_email : true })
   			.then((response) => {
   				this.$store.state.loader = false;
   				this.moshaToast('Email sent successfully.', "success");
   			})
   			.catch((error) => {
   				this.$store.state.loader = false;
   				this.moshaToast("Coundn't send email.", "danger");
   			});
   		},
   		_UIdentiTy(){
   			return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
   				(c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
   				);
   		},
   		async getSrc(id) {
   			var masterId = id;
   			const res = await fetch(process.env.VUE_APP_API_BASE_URL+'/payment/printApptReceipt', {
   				method: 'POST',
   				headers: {
   					'Authorization' : 'Bearer '+localStorage.getItem('authToken'),
   					'Content-Type' : 'application/json'
   				},
   				body: JSON.stringify({ payment_id: masterId }),
   			});
   			const blob = await res.blob();
   			const urlObject = URL.createObjectURL(blob);
   			this.receipt_url = urlObject;
   			this.browserCheck();
   		},
   		browserCheck(){
   			// Opera 8.0+
   			var isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
   
   			// Firefox 1.0+
   			var isFirefox = typeof InstallTrigger !== 'undefined';
   
   			// Safari 3.0+ "[object HTMLElementConstructor]" 
   			var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));
   
   			// Internet Explorer 6-11
   			var isIE = /*@cc_on!@*/false || !!document.documentMode;
   
   			// Edge 20+
   			var isEdge = !isIE && !!window.StyleMedia;
   
   			// Chrome 1 - 79
   			var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
   
   			// Edge (based on chromium) detection
   			var isEdgeChromium = isChrome && (navigator.userAgent.indexOf("Edg") != -1);
   
   			// Blink engine detection
   			var isBlink = (isChrome || isOpera) && !!window.CSS;
   
   			if (isChrome==true) {
   				this.embed = 0;
   			} else {
   				this.embed = 1;
   			}
   		},
   		changeOrder(id) {
   			this.order_id = id;
   			this.fetchApptBillingAmt(this.order_id);
   		},
   		fetchApptBillingAmt(orderId) {
   			axios
   			.post('payment/fetchApptBillingAmount',{order_id : orderId , patient_id:this.$store.state.patientDetail.patient_id })
   			.then((response) => {
   				this.payment_frm.payable_amount = response.data.data.order_info ? response.data.data.order_info.payable_amount : 0;
   				this.payment_frm.purpose_of_payment = response.data.data.order_info ? response.data.data.order_info.purposeofpayment : '';
   				this.billing_summary.received_amount = response.data.data.order_info ? response.data.data.order_info.received_amount : 0;
   				this.billing_summary.order_payment_info = response.data.data.order_payment_info;
   				this.billing_summary.order_info = response.data.data.order_info;
   				if (response.data.data.order_info) {
   					let remaing_amt = (response.data.data.order_info.payable_amount - response.data.data.order_info.received_amount);
   					if (remaing_amt > 0) {
   						this.payment_frm.paid_amount = remaing_amt;
   					} else {
   						this.payment_frm.paid_amount = '';
   					}
   				}
   			});
            $("#neworder").modal('show');
   		},
   		initializeToken : function() {
   			this.tokenpay = {};
   			this.tokenpay = TokenPay(process.env.VUE_APP_API_RECTANGLEHEALTH_TOKEN);
   			this.tokenpay.initialize({
   				dataElement: '#card',
   				errorElement: '#errorMessage',
   				amountElement: 'amount',
   				useACH: false,
   			  //if displaying all 4 fields then useStyles=false, disableZip=false, disableCvv=false
   			  //if displaying 3 out of 4 fields then useStyles=false, and set disableZip or disableCvv equal to true
   			  //if displaying 2 out of 4 fields then useStyles=true, disableZip=true, disableCvv=true
   			  useStyles: true,
   			  disableZip: true,
   			  disableCvv: false
   			});
   		},
   		fetchPatientToken : function() {
   			axios
   			.post('/payment/patient-cctoken',{patient_id:this.$store.state.patientDetail.patient_id})
   			.then((response) => {
   				this.ccTokenData = response.data.data;
   			});
   		},
   		resetForm : function() {
   			this.v$.$reset();
   			this.payment_frm.payment_type = '';
   			this.payment_frm.paid_amount = '';
   			this.payment_frm.check_trace = '';
   			this.payment_frm.other_payment_type = '';
   			this.payment_frm.cctoken_id = '';
   			this.payment_frm.payment_note = '';
   			this.payment_frm.use_credidcard_for_future = false;
            this.payment_frm.payable_amount = '';
            this.payment_frm.purpose_of_payment = '';
            this.payment_frm.purposeofpaymentother = '';
            this.billing_summary.healthcare_amount = 0;
            this.billing_summary.basic_amount = 0;
            this.billing_summary.discount_amount = 0;
            this.billing_summary.payable_amount = 0;
            this.billing_summary.received_amount = 0;
            this.billing_summary.order_payment_info = [];
            this.billing_summary.order_info = {};
   		},
   		handlePaymentCapture : function() {
   			var $this = this;
   			$this.$store.state.loader = true;
   			this.payment_frm.order_id = $this.order_id;
   			axios.post("payment/storePatientBillingPayment", this.payment_frm)
   			.then((response) => {
               if (this.payment_frm.payment_type == 'Wallet') {
                  this.$store.dispatch("refreshCurrentWallet");
               }
   				//$this.order_id = response.data.data.patient_billing_order_id;
   				$this.$store.state.loader = false;
   				$this.resetForm();
   				$this.moshaToast(response.data.message, "success");
   				this.fetchOrderlist();
               this.getreceipt(response.data.data.patient_billing_order_id);
   				//$this.fetchApptBillingAmt($this.order_id);
   				//$this.fetchPatientToken();
               $("#neworder").modal('hide');
   			})
   			.catch((error) => {
   				$this.$store.state.loader = false;
   				$this.serverError = error.response.data.message;
   			});
   		},
   		handlePOSPay: function() {
   			this.v$.$validate();
   			if (!this.v$.$error) {
               if($('#purpose_of_payment').val() == '' && this.payment_frm.purpose_of_payment == 'other'){
                  this.purpose_of_paymenterror = "Purpose Of Payment is required";
                  return false;
               }              
   				this.serverError = '';
               this.wallet_error = '';
   				this.$store.state.loader = true;
   				var vm = this;
   				if (this.payment_frm.payment_type == 'Credit Card' && this.payment_frm.cctoken_id == 'addNew') {
   					this.tokenpay.createToken(function (result) {
   						vm.payment_frm.token = result.token;
   						vm.handlePaymentCapture();
   					}, function (result) {
   						vm.$store.state.loader = false;
   					});
   				} else if(this.payment_frm.payment_type == 'Wallet') {
                  console.log("wallet");
                  if (this.payment_frm.paid_amount > this.$store.state.patientDetail.wallet_amount) {
                     this.wallet_error = 'Charge amount should not greater then wallet amount.';
                     this.$store.state.loader = false;
                  }  else {
                     vm.handlePaymentCapture();
                  }
   				} else {
   					vm.handlePaymentCapture();
               }
   			}
   		}
   	}
   }
</script>