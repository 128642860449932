<template>
	<div class="mt-10" v-if="this.patinet_ticket == 0">
		<div class="container-fluid">
			<div class="text-center">
				<img src="images/shm-logo-new.png" alt="PureEHR" title="PureEHR" class="mt-10 w-120" />
				<p class="mb-0">{{ clinic_final_address }}</p>
			</div>
		</div>
	</div>
	<div class="content-wrapper mt-0">
		<section class="content" v-if="this.patinet_ticket == 0">
			<ul>
				<li>Please don't auto-fill data. Instead, type it manually.</li>
			</ul>
			<div class="box">
				<div class="box-body">
					<form novalidate>
						<div class="row">
							<div class="col-12 col-sm-6 col-md-4">
								<div class="form-group" :class="{ error: v$.form.schedule_date.$errors.length }">
									<label class="form-label">TeleHealth Appointment Date</label> <small class="text-danger asterisksign">*</small>
										<div class=" availdate">
											
											<ejs-datepicker 
												id="schedule_date" 
												placeholder="MM-DD-YY" 
												:allowEdit="false"
												:value="schedule_date" 
												:showClearButton='false' 
												:format="visitdateFormats" 
												@change="scheduleDate"
												:step="15" 
												:min="todaydate"
												:renderDayCell="disableDate"
												:openOnFocus='true'
												autocomplete="chrome-off">
											</ejs-datepicker>
											<div class="error-msg">
												<ul class="mb-0 p-0 fs-14">
													<li class="d-block" v-for="(error, index) of v$.form.schedule_date.$errors" :key="index">
														{{ error.$message }}
													</li>
												</ul>
											</div>
										</div>
								</div>
							</div>
							<div class="col-12 col-sm-6 col-md-4">
								<div class="form-group" :class="{ error: v$.form.schedule_time.$errors.length }">
									<label class="form-label">TeleHealth Appointment Time</label> <small class="text-danger asterisksign">*</small>
									<select class="form-control" id="schedule_time" v-model="schedule_time" @change="timechange(schedule_time)" >
										<option selected="selected" disabled="disabled" value="">Select TeleHealth Appointment Time</option>
										<option v-for="(item,index) in time_list" :value="item" :key="index">{{item}}</option>
									</select>	
									<div class="error-msg">
										<ul class="mb-0 p-0 fs-14">
											<li class="d-block" v-for="(error, index) of v$.form.schedule_time.$errors" :key="index">
												{{ error.$message }}
											</li>
										</ul>
									</div>
								</div>
							</div>

							<div class="col-12 col-sm-12 col-md-12">
								<p class="mb-20 fw-300">The time slots are displayed as per their current availability. It may happen that one or more of these time slots are booked by the time you finish submitting your form. You may select another date and time slot of your choice if you receive a message regarding unavailability of selected time slot.</p>
							</div>
						</div>

						<div class="row">
							<div class="col-12 col-sm-6 col-md-4">
								<input-field
									title="First Name"
									:isRequired="true"
									v-model="v$.form.first_name.$model"
									:errors="v$.form.first_name.$errors"
									labelClass ="col-12 col-sm-12 col-md-12"
									divClass ="col-12 col-sm-12 col-md-12">
								</input-field>
							</div>

							<div class="col-12 col-sm-6 col-md-4">
								<input-field
									title="Middle Name"
									v-model="v$.form.middle_name.$model"
									:errors="v$.form.middle_name.$errors"
									labelClass ="col-12 col-sm-12 col-md-12"
									divClass ="col-12 col-sm-12 col-md-12">
								</input-field>
							</div>

							<div class="col-12 col-sm-6 col-md-4">
								<input-field
									title="Last Name"
									:isRequired="true"
									v-model="v$.form.last_name.$model"
									:errors="v$.form.last_name.$errors"
									labelClass ="col-12 col-sm-12 col-md-12"
									divClass ="col-12 col-sm-12 col-md-12">
								</input-field>
							</div>

							<div class="col-12 col-sm-6 col-md-4">
								<div class="form-group" :class="{ error: v$.form.date_of_birth.$errors.length }">
									<label>DOB</label> <small class="text-danger asterisksign">*</small>
									<div class="availdate">
										<ejs-datepicker 
											:value="date_of_birth" 
											id="date_of_birth" 
											:format="dateFormat" 
											placeholder="MM-dd-yyyy" 
											name="date_of_birth" 
											@change="getdate"
											:max="min_date"
											:strictMode='true' 
											:showClearButton='false' 
											:openOnFocus='true'>
										</ejs-datepicker>
										<div class="error-msg">
											<ul class="mb-0 p-0 fs-14">
												<li class="d-block" v-for="(error, index) of v$.form.date_of_birth.$errors" :key="index">
													{{ error.$message }}
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>

							<div class="col-12 col-sm-6 col-md-4">
								<input-field
									title="Email Address"
									id="email_address"
									v-model="v$.form.email_address.$model"
									:errors="v$.form.email_address.$errors"
									:isRequired="true"
									labelClass ="col-12 col-sm-12 col-md-12"
									divClass ="col-12 col-sm-12 col-md-12">
								</input-field>
							</div>

							<div class="col-12 col-sm-6 col-md-4">
								<input-field
									title="Phone Number"
									id="phone_no"
									:isRequired="true"
									mask="(000) 000-0000"
									v-model="v$.form.phone_no.$model"
									:errors="v$.form.phone_no.$errors"
									labelClass ="col-12 col-sm-12 col-md-12"
									divClass ="col-12 col-sm-12 col-md-12">
								</input-field>
							</div>

							<div class="col-12 col-sm-4 col-md-4">
								<div class="form-group" :class="{ error: v$.form.sex.$errors.length }">
									<label class="form-label">Gender</label> <small class="text-danger asterisksign">*</small>
									<select class="form-control" id="sex" v-model="form.sex">
										<option value="" selected="selected" disabled="disabled">Select Gender</option>
										<option value="Male">Male</option>
										<option value="Female">Female</option>
										<option value="Other">Other</option>
									</select>
									<div class="error-msg">
										<ul class="mb-0 p-0 fs-14">
											<li class="d-block" v-for="(error, index) of v$.form.sex.$errors" :key="index">
												{{ error.$message }}
											</li>
										</ul>
									</div>
								</div>
							</div>
							
							<div class="col-12 col-sm-6 col-md-4">
								<text-area 
									title="Address" 
									id="address"
									v-model="v$.form.address.$model" 
									:errors="v$.form.address.$errors" 
									:isRequired="true"
									:rows="1"
									labelClass ="col-12 col-sm-12 col-md-12"
									divClass ="col-12 col-sm-12 col-md-12">
								</text-area>
							</div>							
							<!-- <div class="col-12 col-sm-6 col-md-4">
								<div class="form-group row" :class="{ error: v$.form.country_id.$errors.length }">
									<label class="form-label col-12 col-sm-12 col-md-12">Country <small class="text-danger asterisksign">*</small></label>
						        	<div class="col-12 col-sm-12 col-md-12">
						                <Select2
						                	v-model="v$.form.country_id.$model"
						                  	id="country_id"
						                  	placeholder="Select Country"
						                  	:options="countries"
						                  	@select="countryChange($event)"
						                />
						                <div class="invalid-feedback" style="display:block;" v-for="(error, index) of v$.form.country_id.$errors" :key="index">
						                  	{{ error.$message }}
						                </div>
						          	</div>
						        </div>
							</div> -->

							<div class="col-12 col-sm-6 col-md-4">
								<input-field
									title="Zipcode"
									:isRequired="true"
									@focusout="getzipcode" 
									v-model="v$.form.zip_code.$model" 
									:errors="v$.form.zip_code.$errors" 
									mask="00000-0000"
									labelClass ="col-12 col-sm-12 col-md-12"
									divClass ="col-12 col-sm-12 col-md-12">
								</input-field>
								<div class="msg">
									<ul class="mb-0 p-0 fs-14">
										<li style="list-style: none;">
											Please add zip code manually.
										</li>
									</ul>
								</div>
							</div>

							<div class="col-12 col-sm-6 col-md-4">
								<div class="form-group row" :class="{ error: v$.form.state_id.$errors.length }">
									<label class="col-12 col-sm-12 col-md-12">State<small class="text-danger asterisksign">*</small></label>
									<div class="col-12 col-sm-12 col-md-12">
										<Select2
											v-model="v$.form.state_id.$model"
											id="state_id"
											:errors="v$.form.state_id.$errors"
											placeholder="Select State"
											:options="subscriber_state_list"/>
										<div class="error-msg">
											<ul class="mb-0 p-0 fs-14">
												<li class="d-block" v-for="(error, index) of v$.form.state_id.$errors" :key="index">
													{{ error.$message }}
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>

							<div class="col-12 col-sm-6 col-md-4">
								<input-field 
									title="City"
									id="city" 
									v-model="v$.form.city.$model" 
									:errors="v$.form.city.$errors" 
									:isRequired="true"
									labelClass ="col-12 col-sm-12 col-md-12"
									divClass ="col-12 col-sm-12 col-md-12">	
								</input-field>
							</div>
						</div>

						<div class="row">
							<div class="col-12 col-sm-6 col-md-4">
								<div class="row">
									<label class="col-12 col-sm-12 col-md-12 fw-500">Height</label>
									<div class="col-12 col-md-6">
										<div class="input-group mb-3">
											<select class="form-control custom-select" id="inputGroupSelect02" v-model="form.height_1">
												<option selected value=""> -Select Feet-</option>
												<option value="1">1</option>
												<option value="2">2</option>
												<option value="3">3</option>
												<option value="4">4</option>
												<option value="5">5</option>
												<option value="6">6</option>
												<option value="7">7</option>
											</select>
											<div class="input-group-append">
												<label class="input-group-text" for="inputGroupSelect02">ft</label>
											</div>
										</div>
									</div>
									<div class="col-12 col-md-6">
										<div class="input-group mb-3">
											<select class="form-control custom-select" id="inputGroupSelect02" v-model="form.height_2">
												<option selected value=""> -Select Inches-</option>
												<option value="0.5"> ½ </option>
												<option value="1">1</option>
												<option value="1.5">1 ½</option>
												<option value="2">2</option>
												<option value="2.5">2 ½</option>
												<option value="3">3</option>
												<option value="3.5">3 ½</option>
												<option value="4">4</option>
												<option value="4.5">4 ½</option>
												<option value="5">5</option>
												<option value="5.5">5 ½</option>
												<option value="6">6</option>
												<option value="6.5">6 ½</option>
												<option value="7">7</option>
												<option value="7.5">7 ½</option>
												<option value="8">8</option>
												<option value="8.5">8 ½</option>
												<option value="9">9</option>
												<option value="9.5">9 ½</option>
												<option value="10">10</option>
												<option value="10.5">10 ½</option>
												<option value="11">11</option>
												<option value="11.5">11 ½</option>
											</select>
											<div class="input-group-append">
												<label class="input-group-text" for="inputGroupSelect02">in</label>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div class="col-12 col-sm-5 col-md-5">
								<div class="form-group row">
									<label class="col-12 col-sm-12 col-md-12">Weight</label>
									
									<div class="col-12 col-sm-5 col-md-5" style="display: inherit;">
										<div class="input-group mb-3">
											<input type="text" class="form-control" placeholder="Weight" v-model="form.weight" aria-label="Weight" aria-describedby="basic-addon2">
											<div class="input-group-append">
												<span class="input-group-text" id="basic-addon2">lbs</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-12 col-sm-12 col-md-12">
								<check-box
									title=""
									id="insurance"
									v-model="v$.form.insurance.$model" 
									:errors="v$.form.insurance.$errors" 
									:isRequiredAfter="true"
						            @click="changeinsurance"
						            label="Insurance is mandatory at this location"
						            divClass="col-12 col-sm-12 col-md-12"
						            labelClass="col-12 col-sm-12 col-md-12"
						            >
								</check-box>
							</div>
						</div>
						<slot v-if="form.insurance">
							<hr>
							<div style="display:flex;"><h3 class="font-bold">Insurance</h3> </div>
							<div class="row align-items-center">
								<div class="col-12 col-sm-6 col-md-4">
									<div class="form-group row" :class="{ error: v$.form.insurance_company_id.$errors.length }">								
										<label class="col-12 col-sm-12 col-md-12">Insurance Name <small class="text-danger asterisksign">*</small></label>
										<div class="col-12 col-sm-12 col-md-12 availdate">
											<div class="selectcontrols">
												<Autocomplete max="30" 
													@input="getlist" 
													ref="autocomplete" 
													id="insurance_company_id"
													:results="insurance_list" 
													@onSelect="resultsvalue" 
													class="form-control ms-0 autosearch-list" 
													placeholder="Select insurance">                        
												</Autocomplete>		
											</div>
											<div class="error-msg">
												<ul class="mb-0 p-0 fs-14">
													<li class="d-block" v-for="(error, index) of v$.form.insurance_company_id.$errors" :key="index">
														{{ error.$message }}
													</li>
												</ul>
											</div>
										</div>
									</div>
								</div>

								<div class="col-12 col-sm-6 col-md-4">
									<input-field
										title="Insurance ID / Policy No"
										:isRequired="true"
										id="insurance_id_number"
										v-model="v$.form.insurance_id_number.$model"
										:errors="v$.form.insurance_id_number.$errors"
										labelClass ="col-12 col-sm-12 col-md-12"
										divClass ="col-12 col-sm-12 col-md-12">
									</input-field>
								</div>

								<div class="col-12 col-sm-6 col-md-4">
									<check-box
										title=""
										v-model="form.patient_is_subscriber"
										@click ="patientissubscriber"
										label="Subscriber is the Patient"
										divClass="col-12 col-sm-12 col-md-12"
										labelClass="col-12 col-sm-12 col-md-12"
										>
									</check-box>
								</div>
							</div>
							
							<div class="row" v-if="form.patient_is_subscriber == 0">
								<div class="col-12 col-sm-6 col-md-4">
									<div class="form-group row" :class="{ error: v$.form.subscriber_relation.$errors.length }">								
										<label class="col-12 col-sm-12 col-md-12">Patient relationship to the Subscriber <small class="text-danger asterisksign">*</small></label>
										<div class="col-12 col-sm-12 col-md-12 availdate">
											<select class="form-control me-2 h-36" id="insurance_select" v-model="form.subscriber_relation">
												<option value="" disabled>Select Patient relationship to the Subscriber</option>
												<option value="1">Self</option>
												<option value="2">Spouse</option>
												<option value="3">Son</option>
												<option value="4">Daughter</option>
												<option value="5">Other</option>
											</select>									
											<div class="error-msg">
												<ul class="mb-0 p-0 fs-14">
													<li class="d-block" v-for="(error, index) of v$.form.subscriber_relation.$errors" :key="index">
														{{ error.$message }}
													</li>
												</ul>
											</div>
										</div>
									</div>
								</div>

								<div class="col-12 col-sm-6 col-md-4">
									<input-field
										title="Subscriber First Name"
										:isRequired="true"
										v-model="v$.form.subscriber_first_name.$model"
										:errors="v$.form.subscriber_first_name.$errors"
										labelClass ="col-12 col-sm-12 col-md-12"
										divClass ="col-12 col-sm-12 col-md-12">
									</input-field>
								</div>

								<div class="col-12 col-sm-6 col-md-4">
									<input-field
										title="Subscriber Middle Name"
										:isRequired="true"
										v-model="v$.form.subscriber_middle_name.$model"
										:errors="v$.form.subscriber_middle_name.$errors"
										labelClass ="col-12 col-sm-12 col-md-12"
										divClass ="col-12 col-sm-12 col-md-12">
									</input-field>
								</div>

								<div class="col-12 col-sm-6 col-md-4">
									<input-field
										title="Subscriber Last Name"
										:isRequired="true"
										v-model="v$.form.subscriber_last_name.$model"
										:errors="v$.form.subscriber_last_name.$errors"
										labelClass ="col-12 col-sm-12 col-md-12"
										divClass ="col-12 col-sm-12 col-md-12">
									</input-field>
								</div>

								<div class="col-12 col-sm-6 col-md-4">
									<text-area 
										title="Subscriber Address" 
										v-model="v$.form.subscriber_address.$model" 
										:errors="v$.form.subscriber_address.$errors" 
										:isRequired="true"
										:rows="1"
										labelClass ="col-12 col-sm-12 col-md-12"
										divClass ="col-12 col-sm-12 col-md-12">
									</text-area>
								</div>

								<div class="col-12 col-sm-6 col-md-4">
									<input-field 
										title="Subscriber Zipcode" 
										@focusout="subscriberzipcode" 
										v-model="v$.form.subscriber_zip_code.$model" 
										:errors="v$.form.subscriber_zip_code.$errors" 
										:isRequired="true"
										labelClass ="col-12 col-sm-12 col-md-12"
										divClass ="col-12 col-sm-12 col-md-12">
									</input-field>
								</div>

								<div class="col-12 col-sm-6 col-md-4">
									<input-field 
										title="Subscriber City" 
										v-model="v$.form.subscriber_city.$model" 
										:errors="v$.form.subscriber_city.$errors" 
										:isRequired="true"
										labelClass ="col-12 col-sm-12 col-md-12"
										divClass ="col-12 col-sm-12 col-md-12">
									</input-field>
								</div>

								<div class="col-12 col-sm-6 col-md-4">
									<div class="form-group row" :class="{ error: v$.form.subscriber_state_id.$errors.length }">
										<label class="col-12 col-sm-12 col-md-12">Subscriber State<small class="text-danger asterisksign">*</small></label>
										<div class="col-12 col-sm-12 col-md-12">
											<Select2
												v-model="v$.form.subscriber_state_id.$model"
												id="state_id"
												:errors="v$.form.subscriber_state_id.$errors"
												placeholder="Select State"
												:options="subscriber_state_list"/>
											<div class="error-msg">
												<ul class="mb-0 p-0 fs-14">
													<li class="d-block" v-for="(error, index) of v$.form.subscriber_state_id.$errors" :key="index">
														{{ error.$message }}
													</li>
												</ul>
											</div>
										</div>
									</div>
								</div>
							</div>
							
							<div class="row align-items-center">
								<div class="col-12 col-sm-6 col-md-4">
									<div class="form-group">
										<div v-if="form.insurance_update==1">
											<label class="col-12 col-sm-12 col-md-12" id="insurancefront">Capture Insurance Front <small class="text-danger asterisksign">*</small></label>
											<web-cam btnname="Click and Upload Insurance Front" imgname="insurancefront"></web-cam>
											OR
											<div class="col-12 col-sm-6 col-md-4 col-lg-6 col-xl-6">
												<div class="controls">
													<input type="file" id="filefront" accept="image/png, .pdf, image/png" ref="filefront" v-on:change="onChangeFileUpload('front')"/>
												</div>
											</div>
										</div>
										<div v-else>
											<label class="col-12 col-sm-12 col-md-12" id="insuranceback">Insurance Front<small class="text-danger asterisksign">*</small></label>
										</div>
										<img :src="form.insurancefront" class="img-fluid rounded5 my-10" style="width:auto;">
										<div class="error-msg">
											<ul class="mb-0 p-0 fs-14">
												<li class="d-block" v-for="(error, index) of v$.form.insurancefront.$errors" :key="index">
													{{ error.$message }}
												</li>
											</ul>
										</div>
									</div>
								</div>
								
								<div class="col-12 col-sm-6 col-md-4">
									<div class="form-group">
										<div v-if="form.insurance_update==1">
											<label class="col-12 col-sm-12 col-md-12" id="insuranceback">Capture Insurance Back <small class="text-danger asterisksign">*</small></label>
											<web-cam btnname="Click and Upload Insurance Back" imgname="insuranceback"></web-cam>
											OR
											<div class="col-12 col-sm-6 col-md-4 col-lg-6 col-xl-6">
												<div class="controls">
													<input type="file" id="fileback" accept="image/png, .pdf, image/png" ref="fileback" v-on:change="onChangeFileUpload('back')"/>
												</div>
											</div>
										</div>
										<div v-else>
											<label class="col-12 col-sm-12 col-md-12" id="insuranceback">Insurance Back <small class="text-danger asterisksign">*</small></label>
										</div>
										<img :src="form.insuranceback" class="img-fluid rounded5 my-10" style="width:auto;">
										<div class="error-msg">
											<ul class="mb-0 p-0 fs-14">
												<li class="d-block" v-for="(error, index) of v$.form.insuranceback.$errors" :key="index">
													{{ error.$message }}
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>

							<div class="row align-items-center">
								<div class="col-12 col-sm-6 col-md-4">
									<div class="form-group">
										<label class="col-12 col-sm-12 col-md-12">Capture Driver's License </label>
										<web-cam btnname="Click and Upload ID Document" imgname="document"></web-cam>
										OR
										<div class="col-12 col-sm-6 col-md-4 col-lg-6 col-xl-6">
											<div class="controls">
												<input type="file" id="filelicense" accept="image/png, .pdf, image/png" ref="filelicense" v-on:change="onChangeFileUpload('driverLicense')"/>
											</div>
										</div>
										<img :src="form.document" class="img-fluid rounded5 my-10" style="width:auto;">
									</div>
								</div>
							</div>							
						</slot>
						<div v-for="index in notes" v-bind:id="'notes'+index"></div>
					</form>
				</div>
			</div>

			<div class="row">
				<div class="col-12 col-sm-12 col-md-12">
					<check-box
						title=""
			            v-model="form.terms0"
			            label="I acknowledge that I have answered these questions truthfully to the best of my knowledge"
			            divClass="col-12 col-sm-12 col-md-12"
			            labelClass="col-12 col-sm-12 col-md-12"
			            :isRequiredAfter="true"
			            >
					</check-box>
				</div>
			</div>
			<div class="text-center">
				<a v-if="this.form.terms0 == 1" href="javascript:void(0)" class="waves-effect waves-light btn btn-info l-h-n py-10 px-20 fs-22" id="form" @click="save()">Submit</a>
				<a v-else href="javascript:void(0)" class="waves-effect waves-light btn btn-info l-h-n py-10 px-20 fs-22 disabled" id="form" @click="save()">Submit</a>
			</div>
		</section>
		<div class="d-flex align-items-center" style="min-height: 600px;">
			<div class="box" v-if="this.patinet_ticket != 0">
				<div class="box-body text-center">
					<h2>You have successfully registered for a covid test with Suarez Health Medical through NJ Covid Tests. Your test is scheduled for {{ display_schedule_date }} at {{ form.schedule_time }} at {{ clinic_final_address }}. You must consult with one of our practitioners through our telehealth platform prior to the test. You will receive a text message and an email when the practitioner is ready to see you.</h2>
				</div>
			</div>
		</div>
	</div>
	

	<!--model start-->
	<div id="MyPatientModal" class=" MyPatientModal modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
		<div class="modal-dialog modal-lg">
			<div class="modal-content">
				<div class="modal-header">
					<h3 class="modal-title fw-900 mt-0">Patient List</h3>
				</div>
				<div class="modal-body">
					<div class="table-responsive">
						<table class="table">
							<thead>
								<tr>
									<th>Patient Name</th>
									<th>Email</th>
									<th>Action</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(item,index) in patinet_list" :key="index">
									<td>{{item.first_name}} {{item.last_name}}</td>
									<td>{{item.email}}</td>
									<td><a @click="get_patient(index)" href="javascript:void(0)" class="waves-effect waves-light btn btn-info">Select</a></td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="modal-footer">
					<a href="javascript:void(0)" class="waves-effect waves-light btn btn-danger" @click="close"> Close </a>
				</div>
			</div>
		</div>
	</div>
	<!--model end-->

	<!--insuranceModal start-->
	<div id="insuranceModal" class=" MyPatientModal modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
		<div class="modal-dialog modal-lg">
			<div class="modal-content">
				<div class="modal-header">
					<h3 class="modal-title fw-900 mt-0">Patient Insurance Details</h3>
				</div>
				<div class="modal-body">
					<h3 class="fw-900">We found below details of insurance in our record. do you want to use this details?</h3>
					<div class="table-responsive">
						<table class="table">
							<tbody>
								<tr>
									<td>Insurance Company</td>
									<td>{{patient_insurance.insurance_company}}</td>
								</tr>
								<tr>
									<td>Insurance Company ID Number</td>
									<td>{{patient_insurance.insurance_id_number}}</td>
								</tr>
								<tr>
									<td>Patient relationship to the Subscriber</td>
									<td>{{patient_insurance.subscriber_relation}}</td>
								</tr>
								<tr>
									<td>Subscriber First Name</td>
									<td>{{patient_insurance.subscriber_first_name}}</td>
								</tr>
								<tr>
									<td>Subscriber Middle Name</td>
									<td>{{patient_insurance.subscriber_middle_name}}</td>
								</tr>
								<tr>
									<td>Subscriber Last Name</td>
									<td>{{patient_insurance.subscriber_last_name}}</td>
								</tr>
								<tr>
									<td>Subscriber Address</td>
									<td>{{patient_insurance.subscriber_address}}</td>
								</tr>
								<tr>
									<td>Subscriber Zip Code</td>
									<td>{{patient_insurance.subscriber_zip_code}}</td>
								</tr>
								<tr>
									<td>Insurance Front</td>
									<td>
										<div class="col-6 col-sm-6 col-md-6 col-lg-6 text-center" v-if="patient_insurance.front_image != ''">
											<div class="position-relative">
												<img  v-if="this.front_extension == 'png' || this.front_extension == 'jpeg' || this.front_extension == 'jpg'" :src="patient_insurance.front_image" class="img-fluid rounded5 zooms b-1" style="height: 120px;">
											</div>
											<div class="position-relative" v-if="this.front_extension == 'pdf'">
												<iframe
												:src="this.patient_insurance.front_image" height="100%" width="100%" style="height:120px;"></iframe>
											</div>
                                		</div>
									</td>
								</tr>
								<tr>
									<td>Insurance Back</td>
									<td>
										<div class="col-6 col-sm-6 col-md-6 col-lg-6 text-center" v-if="patient_insurance.rear_image != ''">
											<div class="position-relative">
												<img  v-if="this.rear_extension == 'png' || this.rear_extension == 'jpeg' || this.rear_extension == 'jpg'" :src="patient_insurance.rear_image" class="img-fluid rounded5 zooms b-1" style="height: 120px;">
											</div>
											<div class="position-relative" v-if="this.rear_extension == 'pdf'">
												<iframe
												:src="this.patient_insurance.rear_image" height="100%" width="100%" style="height:120px;"></iframe>
											</div>
                                		</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="modal-footer">
					<a href="javascript:void(0)" class="waves-effect waves-light btn btn-success" @click="Insurance_yes"> Yes </a>
					<a href="javascript:void(0)" class="waves-effect waves-light btn btn-danger" @click="Insurance_no"> no </a>
				</div>
			</div>
		</div>
	</div>
	<!--insuranceModal end-->

	<!--alert start-->
	<div id="alertModal" class=" MyPatientModal modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
		<div class="modal-dialog modal-lg">
			<div class="modal-content">
				<div class="modal-header">
					<h3 class="modal-title fw-900 mt-0">Slot already booked.</h3>
				</div>
				<div class="modal-body">
					<h3 class="fw-900">The time slot you were trying to choose has been booked recently. Please try again with a different time slot.</h3>
				</div>
				<div class="modal-footer">
					<a href="javascript:void(0)" class="waves-effect waves-light btn btn-danger" @click="alertclose"> Close </a>
				</div>
			</div>
		</div>
	</div>
	<!--insuranceModal end-->

</template>
<script>
	import {helpers,required,} from "@vuelidate/validators";
	import InputField from "@/components/base/formFields/InputField.vue";
	import CheckBox from "@/components/base/formFields/checkBox.vue";
	import TextArea from "@/components/base/formFields/textArea.vue";
	import Select2 from "@/components/base/formFields/select2.vue";
	import WebCam from "@/views/CovidExams/TeleHealthCamera.vue";
	import axios from "@/scripts/axios.js";
	import submitMixins from "@/scripts/mixins/submitMixins.js";
	import useVuelidate from "@vuelidate/core";
	import moment from "moment";
	import Autocomplete from "vue3-autocomplete";
	//import $ from 'jquery';
	
	export default {
		mixins: [submitMixins],
		setup: () => {
			return { v$: useVuelidate() };
		},

		computed: {
			form() {
				return this.$store.state.TeleHealthRegistration.form;
			},
			vuelidateExternalResults() {
				return this.$store.state.TeleHealthRegistration.vuelidateExternalResults;
			},
			validationRules() {
				return this.$store.state.TeleHealthRegistration.validationRules;
			}
		},

		validations() {
			return this.$store.state.TeleHealthRegistration.validationRules;
		},

		components: {
			InputField,
			WebCam,
			CheckBox,
			TextArea,
			Select2,
			Autocomplete,
		},

		data() {
			return {
				countries: [],
				time_list:{},
				patient_insurance:{},
				submission:[],
				patinet_ticket: 0,
				notes: '',
				noteData: [{
					template_detail_data: [{
						id: '',
						template_id: '',
						name: '',
						category: '',
						patient_id: '',
						appointment_id: '',            
						is_included:'',
					}],
					components: [],
				}],
				dateFormat: 'MM-dd-yyyy',
				date_of_birth:'',
				visitdateFormats : 'MM-dd-yyyy',
				appointmentprofiles:[],
				patinet_list:[],
				schedule_date : '',
				schedule_time : '',
				front_extension:'',
				rear_extension:'',
				insurance_list:[],
				subscriber_state_list:[],
				todaydate:new Date(),
				display_schedule_date: '',
				min_date:"",
				clinic_final_address: ''
			}
		},

		mounted(){
			let bridgePayScript = document.createElement('script')
			bridgePayScript.setAttribute('src', process.env.VUE_APP_API_RECTANGLEHEALTH_TOKENPAY_URL)
			document.head.appendChild(bridgePayScript);
			this.form.sex ='';			
			this.form.insurance = null;
			this.form.insurance_company_id ='';			
			this.form.patient_is_subscriber = 1;
			this.form.country_id = 231;
			this.form.state_id = '';
			this.form.subscriber_relation= '';
			this.form.terms0 = 0;
			this.form.terms1 = 0;
			this.form.terms2 = 0;
			this.form.insurance_update = 1;
			this.form.schedule_time = '';
			this.form.height_1 = '';
			this.form.height_2 = '';
			this.form.weight = null;
			this.subscriber_state();
			this.min_date=new Date();
			this.min_date.setDate(this.min_date.getDate() - 2);
      
	  		
		},

		beforeCreate(){
			//this.$store.state.loader = true;
			this.$store.state.TeleHealthRegistration.form = {};
			Object.assign(this.$store.state.TeleHealthRegistration.form);
			var clinic_name = window.atob(this.$route.params.clinic_id);
			var office_id =window.atob(this.$route.params.office_id);
			axios.post("covid/clinic-info",{'clinic_id1':clinic_name,'office_id':office_id})
			.then((result) => { 
				this.form.clinic_id1 = window.atob(this.$route.params.clinic_id);
				this.form.provider_id = result.data.data.primary_provider_id;
				this.form.office_id = window.atob(this.$route.params.office_id);
				if(result.data.data.office_name && result.data.data.office_name!="") {
					this.clinic_final_address = result.data.data.office_name;
				}
				if(result.data.data.address && result.data.data.address!="") {
					this.clinic_final_address += ", "+result.data.data.address;
				}
				if(result.data.data.city && result.data.data.city!="") {
					this.clinic_final_address += ", "+result.data.data.city;
				}
				if(result.data.data.state && result.data.data.state!="") {
					this.clinic_final_address += ", "+result.data.data.state;
				}
				if(result.data.data.zip_code && result.data.data.zip_code!="") {
					this.clinic_final_address += " "+result.data.data.zip_code;
				}
				this.office_day();
				this.getnote();
			})
			.catch((error) => {
				this.$router.push(`/Unauthorized`);
			});

			axios.get("/covid/country")
				.then((response) =>
		        	(this.countries = response.data.data.map((country) => {
		          	return { id: country.id, text: country.name };
		        }))
	    	);

		},
		methods:{
			onChangeFileUpload(type){
				let reader = new FileReader;
				if(type=="front"){
					if (this.$refs.filefront&&this.$refs.filefront.files[0]) {
						reader.onload = e => {
							this.$store.state.TeleHealthRegistration.form.insurancefront = e.target.result;
						}
					}
					reader.readAsDataURL(this.$refs.filefront.files[0]);
				}else if(type=="back"){
					if (this.$refs.fileback&&this.$refs.fileback.files[0]) {
						reader.onload = e => {
							this.$store.state.TeleHealthRegistration.form.insuranceback = e.target.result;
						}
					}
					reader.readAsDataURL(this.$refs.fileback.files[0]);
					
				}else if(type=="driverLicense"){
					if (this.$refs.filelicense&&this.$refs.filelicense.files[0]) {
						reader.onload = e => {
							this.$store.state.TeleHealthRegistration.form.document = e.target.result;
						}
					}
					reader.readAsDataURL(this.$refs.filelicense.files[0]);
				}
			},
			office_day(){
				var thisobj = this;
				axios.post("/telehealth/avail-office-days",{'b_office_id':this.form.office_id,'b_clinic_id':this.form.clinic_id1})
				.then((result) => { 
					thisobj.day_lists = result.data.data;
				});
			},
			disableDate: function(args) {
				var arrayindex = [];
				var dayOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
				dayOfWeek.forEach((element,index) => {					
					if(!this.day_lists.includes(element)){
						arrayindex.push(index)
					}
				});
				arrayindex.forEach(element1 => {
					if (args.date.getDay() === element1) {
						args.isDisabled = true;
					}
				});
		    },
			getzipcode(){
				if(this.form.zip_code && this.form.zip_code.length > 3 ){
					axios.post("covid/state-city-auto-populations",{zip_code:this.form.zip_code})
					.then((response) => {
						this.form.city=response.data.data.City;
						this.form.state_id=response.data.data.id
					});
				}
			},
			timechange(schedule_time){
				const myArray = schedule_time.split(" To ");
				this.form.schedule_time = myArray[0];
				this.form.schedule_end_time = myArray[1];
			},
			Insurance_no(){
				$('#insuranceModal').modal('hide');
			},
			Insurance_yes(){
				this.form.insurance_id_number = this.patient_insurance.insurance_id_number; 				
				this.$refs.autocomplete.searchText = this.patient_insurance.insurance_company;
		      	this.form.insurance_company_id =this.patient_insurance.company_id;
		      	this.form.patient_is_subscriber = this.patient_insurance.patient_is_subscriber;
		      	this.form.subscriber_relation = this.patient_insurance.subscriber_relation;
		      	this.form.subscriber_first_name = this.patient_insurance.subscriber_first_name;
		      	this.form.subscriber_middle_name = this.patient_insurance.subscriber_middle_name;
		      	this.form.subscriber_last_name = this.patient_insurance.subscriber_last_name;
		      	this.form.subscriber_address = this.patient_insurance.subscriber_address;
		      	this.form.subscriber_zip_code = this.patient_insurance.subscriber_zip_code;
				this.form.insurancefront=this.patient_insurance.front_image;
				this.form.insuranceback=this.patient_insurance.rear_image;
		      	this.form.insurance_update = 0;
		      	this.subscriberzipcode();
		      	$('#insuranceModal').modal('hide');
			},
			getlist(obj) {
		    	if (obj.length > 1) {
		        	this.$refs.autocomplete.$el.classList.add("selectloader");
		        	axios.post("/covid/insurance-list", {term: obj})
		          	.then((response) => {
		            	this.insurance_list = response.data.data.map((insurance) => {
		              		return { id: insurance.id, name: insurance.insurance_name}
		            	});
		            	this.$refs.autocomplete.$el.classList.remove("selectloader");
		          	});
		      	}
		      	if (obj.length == 0) {
			        this.insurance_list = {};
		      	}
		    },
		    resultsvalue(obj) {
		      this.$emit("clicked", obj);
		      this.$refs.autocomplete.searchText = obj.name;
		      this.$store.state.TeleHealthRegistration.form.insurance_company_id = obj.id;
		      this.focusout();
		    },
		    focusout() {
				const btn = this.$refs.autocomplete;
		      	btn.$el
		        .querySelector(".vue3-autocomplete-container > .autosearch-list")
		        .blur();
		    },
			close(){
				$('#MyPatientModal').modal('hide');
			},

			get_patient(index){
				this.form.first_name = this.patinet_list[index]['first_name'];
				this.form.last_name = this.patinet_list[index]['last_name'];
				this.form.middle_name =  this.patinet_list[index]['middle_name'];
				this.form.email_address =  this.patinet_list[index]['email'];
				this.form.phone_no =  this.patinet_list[index]['cell_phone'];
				this.form.sex =  this.patinet_list[index]['sex'];
				this.form.appointment_profile_id = 19;
				$('#MyPatientModal').modal('hide');
			},

			onChangeAppointmentProfile(e) {
				if (e.target.options.selectedIndex > -1) {
					const theTarget = e.target.options[e.target.options.selectedIndex].dataset;
					this.form.color_code = theTarget.color_code
					this.form.duration = theTarget.default_duration
					this.form.reason_for_visit = theTarget.name
				}
			},

			getdate(e){
				if($('#date_of_birth').val() == '') {
					this.form.date_of_birth = '';
				} else {
					this.date_of_birth =  $('#date_of_birth').val();
					this.form.date_of_birth = moment(e.value).format('YYYY-MM-DD');
				}
			},

			scheduleDate(e){
				var clinic_name = window.atob(this.$route.params.clinic_id);
				this.form.schedule_date = moment(e.value).format('YYYY-MM-DD');
				axios.post("/telehealth/get-available-slots",{'book_date':this.form.schedule_date,'b_clinic_id':clinic_name,'b_provider_id':this.form.provider_id,'b_office_id':this.form.office_id})
				.then((result) => { 
					this.time_list = result.data.data;
					this.schedule_time = '';
				});
			},
			alertclose(){
				$('#alertModal').modal('hide');
			},
			saveform(){
				this.$store.state.loader = true;
				axios.post("/covid/store",this.form)
				.then((result) => { 
					if(result.status == 200){
						this.moshaToast("Record added successfully.", "success");
						this.display_schedule_date = moment(this.form.schedule_date).format('MM-DD-YYYY');
						this.patinet_ticket  = result.data.data.patinet_ticket;
					}
					else{
						this.moshaToast("Opp Somthing Wrong!.", "danger");	
					}
					this.$store.state.loader = false;
				});
			},
			save(){
				var submission = this.submission;
				var finalData = {};
				finalData['template_detail_data'] = submission.data.template_detail_data;
				delete submission.data['template_detail_data'];
				delete submission.data['components'];    
				finalData['fieldjsonData'] = submission.data;
				this.form.formdata = finalData['fieldjsonData'];
				this.v$.$validate();
  				if (!this.v$.$error) {
  					axios.post("/telehealth/check-slots-availability",{'b_clinic_id':this.form.clinic_id1,'b_office_id':this.form.office_id,'book_date':this.form.schedule_date,'book_time':this.form.schedule_time,'b_provider_id':this.form.provider_id})
					.then((result) => { 
						this.saveform();
					}).catch((error) => {
						$('#alertModal').modal('show');
					});
				}
				else{
					for (const [key, value] of Object.entries(this.vuelidateExternalResults.form)) {
	                  if (this.v$.form[key].$errors.length > 0) {
	                    const myEl = document.getElementById(key);
	                    if (myEl) {
	                      this.$smoothScroll({
	                        scrollTo: myEl,
	                      });
	                      break;
	                    }
	                  }
	                }
				}
			},

			changeinsurance(){
				var clinic_name = window.atob(this.$route.params.clinic_id);
				if(this.form.insurance === 0){
					this.$store.state.is_insurance = false
					this.form.insurance = null;
				}
				else{
					axios.post("covid/patient-insurance-check",{'first_name':this.form.first_name,'last_name':this.form.last_name,'date_of_birth':this.form.date_of_birth,'clinic_id1':clinic_name,'email':this.form.email_address})
				    .then((response) => {
				        if(response.data.data){
							if(response.data.data.front_image) {
								console.log(response.data.data.front_image);
								const primary_front = response.data.data.front_image.split(".");
								this.front_extension = primary_front[1];
							} else {
								this.front_extension = "";
							}
							if(response.data.data.rear_image) {
								const primary_rear = response.data.data.rear_image.split(".");
								this.rear_extension = primary_rear[1];
							} else {
								this.rear_extension = "";
							}
				        	this.patient_insurance = response.data.data;
							this.patient_insurance.front_image= process.env.VUE_APP_API_BASE_URL+"/stream-image?path="+response.data.data.front_image+"&slang="+clinic_name;
							this.patient_insurance.rear_image= process.env.VUE_APP_API_BASE_URL+"/stream-image?path="+response.data.data.rear_image+"&slang="+clinic_name;
				        	$('#insuranceModal').modal('show');
				        }
				    });
					this.$store.state.is_insurance = true
				}
			},

			patientissubscriber(){
				if(this.form.patient_is_subscriber === 0){
					this.$store.state.is_patient_is_subscriber = true
				}
				else{
					this.$store.state.is_patient_is_subscriber = false
				}	
			},

			subscriber_state(){
				axios.get("/covid/state/231")
			    .then((response) => {
			        this.subscriber_state_list = response.data.data.map((state) => {
			          return { id: state.id, text: state.name };
			      });
			    });
			},

			subscriberzipcode(){
				if(this.form.subscriber_zip_code && this.form.subscriber_zip_code.length > 3 ){
				    axios.post("covid/state-city-auto-populations",{zip_code:this.form.subscriber_zip_code})
				    .then((response) => {
				        this.form.subscriber_city=response.data.data.City;
				        this.form.subscriber_state_id=response.data.data.id
				    });
				}
			},
			
			async getnote(){
			
				let bodyFormData = new FormData();
				bodyFormData.append("clinic_id1", this.form.clinic_id1);
				let res = await axios.post(
					`covid/note`,
					bodyFormData
					);
				let data = res.data.data;
				this.notes = data.length;
				let $this = this;
				setTimeout(function(){ 
					$this.getNoteDetail(data);
				}, 1000);
			},
			
			async getNoteDetail(data){
				let $this = this;
				let componentsData =JSON.parse(data[0].template_json).components;
				$this.noteData.push({
						template_detail_data : {
							'id': '',
							'template_id' : data[0].id,
							'name' : data[0].name,
							'category' : data[0].category,
							'patient_id' : '1',
							'appointment_id' : '1',
							'patient_clinical_note_version' : '1',
						},
						components : componentsData,  
				});
				$this.createForm();
			},

			async createForm() {
				let index = 1;
				let self = this;
				
				await Formio.createForm(
					document.getElementById("notes"+index), 
					this.noteData[index]
					).then(
					function(form) {
						
					//$("div#notes"+index).prepend("<h1>"+ self.noteData[index].template_detail_data.name+"</h1>");
					form.submission = {
						data: self.data,
					};

					form.submission.data.template_detail_data = self.noteData[index].template_detail_data;
					form.submission.data.components = self.noteData[index].components;

					var valArr = {};
					form.on("change", function(submission) {
						self.submission = submission;
					});
					
				});				
				$(".provider_part").hide();
			},
		}
	}
</script>
<style scoped>
.note-uli{ text-align: left; list-style: none; padding-left: 20px;}
.note-uli li{ position: relative;}
.note-uli li:after{content:''; left:-20px; position: absolute; width: 6px; height: 6px; background-color: #ff0000; border-radius: 15px; margin: auto; top:0; bottom: 0;}
</style>
