import {
  helpers,
  required,
  email,
  minLength,
  maxLength,
  sameAs,
  numeric,
} from "@vuelidate/validators";

const LabResults = {
  namespaced: true,

  state: {
    tableData: {
      header: [
        "Lab",
        "Date of Testing",
        "Provider",
        "Abnormal?",
        "Result Count",
        "Test Results",
      ],
    },
    form: {},
    listUrl: "/patient/:patient_unique_id/lab-order/result",
    list: [],
  },
  getters: {
    getTableHeaders: (state) => state.header,

    getTableContents: (state) => state.list,
    getListURL: (state) => state.listUrl,
  },
  mutations: {},
  actions: {},
}

export default LabResults;
