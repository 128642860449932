<template>
  <div class="tab-pane" id="smokingstatus" role="tabpanel">
    <div class="patiboxdetail">
      <div class="authform-modal box">
          <div class="pageheading pb-10">
            <div class="row justify-content-between">
              <div class="col-12 col-sm-8 col-md-7 col-lg-8">
                <h2 class="heading2">Smoking Status <a href="#" data-bs-toggle="modal" data-bs-target="#smokingstatusdocument" title="Reference of Knowledge Base" v-on:click.prevent="reset()"> <i class="fa fa-question-circle fs-20"></i> </a></h2>
              </div>
              <div class="col-12 col-sm-4 col-md-5 col-lg-4 text-sm-end">
                <a
                  v-if="
                    permissioncheck(this.$store.state.permission.create_update)
                  "
                  href="javascript:void(0)"
                  class="waves-effect waves-light btn btn-info"
                  data-bs-toggle="modal"
                  data-bs-target="#smokingstatusrmodal"
                  ><i class="fa fa-plus fs-14"></i> Smoking Status</a
                >
              </div>
            </div>
          </div>
          <!--document model start-->
          <div id="smokingstatusdocument" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-md">
              <div class="modal-content">
                <div class="modal-header">
                  <h3 class="modal-title fw-900 mt-0">Reference of Knowledge Base</h3>
                </div>
                <div class="modal-body overflow-auto" style="max-height: 350px;">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                    <th scope="col">No.</th>
                    <th scope="col">Knowledge Base Articles</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td> <a :href="path+'how-to-add-patient-s-smoking-status-in-pureehr-9-6-2023'" target="_blank">How To Add Patient’s Smoking Status In PureEHR?</a></td>
                    </tr>
                  </tbody>
                </table>
                <Search :key="searchKey"></Search>
                </div>
                <div class="modal-footer">
                  <button type="button" @click="" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
                </div>
              </div>
            </div>
          </div>
        <!--document model end-->
          <div class="table-responsive">
            <Table
              :dataTypes="{
                dataType: 'smokingstatus',
                patient_id: patient_id,
                patient_unique_id: patient_unique_id,
                title :'No smoking status available',
              }"
              :lastcol='permissioncheck(this.$store.state.permission.create_update)'
              :key="key"
            >
              <template v-slot="slotProps">
                <td>{{ slotProps.item.description }}</td>
                <td>
                  <div v-if="slotProps.item.start_date != null">
                    {{$filters.displayDateTimeLocal(slotProps.item.start_date,'',false,"ddd, MM-DD-YYYY",true)}}
                  </div>
                  <div v-else>-</div>
                </td>
                <td>
                  <div v-if="slotProps.item.end_date != null">
                    {{ $filters.displayDateTimeLocal(slotProps.item.end_date,'',false,"ddd, MM-DD-YYYY",true) }}
                  </div>
                  <div v-else>-</div>
                </td>
                <td>
                  {{ $filters.displayDateTimeLocal(slotProps.item.created_at,'',false,"ddd, MM-DD-YYYY",true) }}
                </td>
                <td>
                  <div class="d-flex" v-if="permissioncheck(this.$store.state.permission.create_update)">
                    <Popper arrow content="Edit Smoking Status" :hover="true" class="helptips cursor-pointer">
                      <a
                        href="#"
                        class="waves-effect waves-circle btn btn-circle btn-info btn-xs" 
                        ><i class="fa fa-pencil" @click="editItem(slotProps.item.id)"></i></a>
                    </Popper>
                    <Popper arrow content="Delete Smoking Status" :hover="true" class="helptips cursor-pointer">
                      <a
                        href="#"
                        class="waves-effect waves-circle btn btn-circle btn-danger btn-xs"
                        @click="deleteItem(slotProps.item.id)"
                        ><i class="fa fa-trash"></i
                      ></a>
                    </Popper>
                  </div>
                </td>
              </template>
            </Table>
          </div>
      </div>
    </div>
    <div
      id="smokingstatusrmodal"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content permissmodal">
          <div class="modal-header">
            <h3 class="modal-title font-bold m-0">{{ title }}</h3>
          </div>
          <div class="modal-body">
            <form>
              <div
                class="row form-group" :class="{ error: v$.form.smoking_id.$errors.length }">
                <label class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end">Smoking Status <small class="text-danger asterisksign">*</small></label>
                <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
                  <select
                    class="form-control"
                    id="smokingstatus"
                    v-model="form.smoking_id"
                  >
                    <option value="" disabled
                      >Please Select Smoking Status</option
                    >
                    <option :selected="form.smoking_id == '1'" value="1"
                      >Never smoked tobacco</option
                    >
                    <option :selected="form.smoking_id == '2'" value="2"
                      >Tobacco smoking consumption unknown</option
                    >
                    <option :selected="form.smoking_id == '3'" value="3"
                      >Occasional tobacco smoker</option
                    >
                    <option :selected="form.smoking_id == '4'" value="4"
                      >Ex-smoker</option
                    >
                    <option :selected="form.smoking_id == '5'" value="5"
                      >Heavy tobacco smoker</option
                    >
                    <option :selected="form.smoking_id == '6'" value="6"
                      >Smokes tobacco daily</option
                    >
                    <option :selected="form.smoking_id == '7'" value="7"
                      >Smoker</option
                    >
                    <option :selected="form.smoking_id == '8'" value="8"
                      >Light tobacco smoker</option
                    >
                  </select>
                </div>
                <div
                  class="invalid-feedback"
                  style="display:block;"
                  v-for="(error, index) of v$.form.smoking_id.$errors"
                  :key="index"
                >
                  {{ error.$message }}
                </div>
              </div>

              <div class="form-group row">
                <label class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end">
                  Start Date <small class="text-danger asterisksign">*</small>
                </label>
                <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
                    <div :class="{ error: v$.form.start_date.$errors.length }" class="availdate">
                      <ejs-datepicker :value="start_date" id="start_date" v-model="v$.form.start_date.$model" :format="dateFormat"  :placeholder="waterMarkText" @change="getdate" :showClearButton='false' :blur="getdate" name="start_date" :strictMode='strict' :max='maxVal' :openOnFocus='true'>
                      </ejs-datepicker>

                      <div class="error-msg">
                        <ul class="mb-0 p-0 fs-14">
                          <li class="d-block" v-for="(error, index) of v$.form.start_date.$errors" :key="index">
                            {{ error.$message }}
                          </li>
                        </ul>
                      </div>
                    </div>
                </div>
              </div>

              <div class="form-group row mb-0">
                <label class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-form-label mb-0 text-sm-end">End Date</label>
                <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 availdate">
                  <ejs-datepicker :value="end_date" id="end_date" v-model="v$.form.start_date.$model" :format="dateFormat"  :placeholder="waterMarkText"  name="end_date" :strictMode='strict' :max='maxVal' @change="getenddate" :showClearButton='false' :blur="getenddate" :openOnFocus='true'>
                  </ejs-datepicker>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer" v-if="permissioncheck(this.$store.state.permission.create_update)">
            <button
              type="button"
              @click="submitForm()"
              class="waves-effect waves-light btn btn-info"
            >
              {{ btn }}
            </button>
            <button
              type="button"
              @click="closeModal()"
              class="waves-effect waves-light btn btn-danger"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Table from "@/components/base/Table.vue";
import DatePicker from "@/components/base/formFields/datePicker.vue";
import moment from "moment";
import useVuelidate from "@vuelidate/core";
import axios from "@/scripts/axios.js";
import submitMixins from "@/scripts/mixins/submitMixins.js";
import permissionMixins from "@/scripts/mixins/permission.js";
import { DatePickerPlugin} from '@syncfusion/ej2-vue-calendars';
import Popper from "vue3-popper";
import Search from "@/components/Search.vue";

export default {
  mixins: [submitMixins, permissionMixins],
  setup() {
    return { v$: useVuelidate() };
  },
  name: "SmokingStatusList",
  components: { Table, DatePicker, Popper, Search },
  data() {
    return {
      key: 0,
      patient_id: this.$store.state.patientDetail.patient_id,
      patient_unique_id: this.$store.state.patientDetail.patient_unique_id,
      waterMarkText : 'Choose a date',
      dateFormat: 'MM-dd-yyyy',
      strict: true,
      maxVal: new Date(),
      path: process.env.VUE_APP_KB_DOCUMENT,
      searchKey:0,
      
    };
  },
  computed: {
    form() {
      return this.$store.state.smokingstatus.form;
    },
    vuelidateExternalResults() {
      return this.$store.state.smokingstatus.vuelidateExternalResults;
    },
    validationRules() {
      return this.$store.state.smokingstatus.validationRules;
    },
    title() {
      if (this.form.id === undefined || this.form.id === null) {
        return "New Smoking Status";
      } else {
        return "Edit Smoking Status";
      }
    },
    btn() {
      if (this.form.id === undefined || this.form.id === null) {
        return "Create";
      } else {
        return "Update";
      }
    },
  },
  mounted() {},
  methods: {
    reset() {
      this.searchKey = this.searchKey+1;
    },
    getdate(){
      if($('#start_date').val() == '') {
        this.form.start_date = null;
      } else {
        this.form.start_date =  $('#start_date').val();
        this.form.start_date = moment(String(this.form.start_date)).format('YYYY-MM-DD');
      }
    },
    getenddate(){
      if($('#end_date').val() == '') {
        this.form.end_date = null;
      } else {
        this.form.end_date =  $('#end_date').val();
      }

    },
    closeModal() {
      this.$store.state.smokingstatus.form.id = null;
      this.$store.state.smokingstatus.form.patient_id = "";
      this.$store.state.smokingstatus.form.smoking_id = "";
      this.start_date = "";
      this.end_date = "";
      $("#smokingstatusrmodal").modal("hide");
      this.v$.$reset();
    },
    formatDate(date, format) {
      return moment(date).format(format);
    },
    editItem(id) {
      axios
        .get(`patient/${this.$route.params.id}/smoking-status/edit/` + id)
        .then(
          (response) =>
            (
              this.end_date =  moment(String(response.data.data.end_date)).format('MM/DD/YYYY'),
              this.start_date = moment(String(response.data.data.start_date)).format('MM/DD/YYYY'),
              this.$store.state.smokingstatus.form = response.data.data
            )
        );
      setTimeout(() => {
        this.form.id = id;
        $("#smokingstatusrmodal").modal("show");
      }, 100);
      

    },
    submitForm() {
      var end_date = $('#end_date').val();
      if (end_date != '') {
        this.form.end_date = moment(String(this.form.end_date)).format('YYYY-MM-DD');  
      }
      if (this.form.id === undefined || this.form.id === null) {
        var url = `patient/${this.$route.params.id}/smoking-status/store`;
      } else {
        var url = `patient/${this.$route.params.id}/smoking-status/update`;
      }
      this.form.patient_id = this.$store.state.patientDetail.patient_id;
      this.postForm(url, "smokingstatus", true, "json");
    },
    formPostSuccess(data) {
      if (this.form.id === undefined || this.form.id === null) {
        this.moshaToast("Record added successfully.", "success");
      } else {
        this.moshaToast("Record Updated successfully.", "success");
      }
      this.closeModal();
      this.key++;
    },
    deleteItem(id) {
      this.$swal
        .fire({
          title: '<h2 class="mt-0">Are you sure?</h2>',
          text:
            "You want to delete these records? This process cannot be undone.",
          icon: "warning",
          dangerMode: true,
          showCancelButton: true,
          confirmButtonColor: "#ee3158",
          cancelButtonColor: "#bdbdbd",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed == true) {
            axios
              .post(`patient/${this.$route.params.id}/smoking-status/delete`, {
                id: id,
              })
              .then((res) => {
                this.$swal.fire("Deleted!", res.data.message, "success");
                this.key++;
              });
          }
        });
    },
  },
  validations() {
    return this.$store.state.smokingstatus.validationRules;
  },
};
</script>

<style></style>
