<template>
	<div class="sticky-scroll-box">
	   <div class="accorsidebar">
	      <div class="accordion-item">
	         <div id="collapsedemogr" class="accordion-collapse collapse" aria-labelledby="addpatienttab" data-bs-parent="#accordionExample">
	            <div class="side-navigation">
	               <ul class="side-bar-menu side-bar-tree patientTabs tree" data-widget="tree">
                  	  <li>
      						<router-link to="/corporate/life-style-service-category" :class="isActive('CorporateLifeStyleServiceCategory')" >LifeStyle Service Category</router-link>
                  	  </li>
	                  <li>
  							<router-link to="/corporate/life-style-service" :class="isActive('CorporateLifeStyleService')" >LifeStyle Service</router-link>
              		  </li>
            		  <li>
							<router-link to="/custom-procedures?lifestyle=true" :class="isActive('CustomProcedures')" >Custom Procedures</router-link>
            		  </li>
            		  <li>
							<router-link to="/billing-profile-list?lifestyle=true" :class="isActive('BillingProfileList')" >Billing Profiles</router-link>
            		  </li>
  	                  <!-- <li>
							<router-link to="/corporate/list" :class="isActive('Corporatelist')" >Corporate</router-link>
            		  </li> -->

            		  <li><router-link to="/life-style-process"><i class="fa fa-arrow-left" aria-hidden="true"></i> Back</router-link></li>
	               </ul>
	            </div>
	         </div>
	      </div>
	   </div>
	</div>
</template>
<script>
	export default { 
        props: {
            routefrom: String
        },
        data() {
        },
        methods: {
            isActive(menuname) {
                return menuname==this.routefrom ? 'active' : '';
            }
		},
    }
</script>