const CardAuthorizationofProspectus = {
	namespaced: true,
  	state: {
    	listUrl: "/reports/card-authorization-of-prospectus",
		header: [
			{"chart_id":"Medical Chart ID"},
			{"first_name":"First Name"},
			{"last_name":"Last Name"},
			{"email":"Email"},
			{"legacy_source":"Source"},
			{"auth":"Authorization Status"},
			{"":"Action"},
			{"":""},
		],
		pagination: {
			to: "",
			from: "",
			total: "",
			last_page: "",
			current_page: "",
			pageNavArr: [],
		},	
		list: [],	
  },
  getters: {
    getTableHeaders: (state) => state.header,
    getTableContents: (state) => state.list,
    getListURL: (state) => state.listUrl,
  },
  mutations: {},
  actions: {},
}

export default CardAuthorizationofProspectus;