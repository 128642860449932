<template>
  <div class="content-wrapper">
    <div class="container-full">
      <div class="content-header">
        <div class="row justify-content-between">
          <div class="col-12 col-md-6">
            <h1 class="heading2 mb-0">Custom Profiles <a href="#" data-bs-toggle="modal" data-bs-target="#customdocument" title="Reference of Knowledge Base" v-on:click.prevent="reset()"> <i class="fa fa-question-circle fs-20"></i></a></h1>
          </div>
          <div class="col-12 col-sm-6 col-md-6 text-sm-end">
            <template v-if="this.show_lifestyle_menu">
                <router-link
                  to="/custom-procedures/archived?lifestyle=true"
                  class="waves-effect waves-light btn btn-info me-1 my-1"
                  >Show Archived</router-link
                >
                <router-link
                  to="/custom-procedures/add?lifestyle=true"
                  class="waves-effect waves-light btn btn-info my-1"
                  ><i class="fa fa-plus fs-14"></i> Custom Profiles</router-link>
            </template>
            <template v-else>
             <router-link
                to="/custom-procedures/archived"
                class="waves-effect waves-light btn btn-info me-1"
                >Show Archived</router-link
              >
              <router-link
                to="/custom-procedures/add"
                class="waves-effect waves-light btn btn-info"
                ><i class="fa fa-plus fs-14"></i> Custom Profiles</router-link>
            </template>
          </div>
        </div>
      </div>
      <div class="row">
          <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-2" v-if="this.show_lifestyle_menu">
              <life-style-header routefrom="CustomProcedures"></life-style-header>
          </div>
          <div v-bind:class="show_lifestyle_menu ? 'col-12 col-sm-12 col-md-9 col-lg-9 col-xl-10' : 'col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12' ">
            <section class="content">
              <div class="box mb-10">
                <div class="box-body">
                  <div class="row justify-content-between">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-6">
                      <div class="input-group float-start w-md-p60 mb-5 me-1">
                        <input
                          type="text"
                          data-provide="media-search"
                          class="form-control"
                          v-model="filter.search_text" 
                          placeholder="Search (e.g. Category, Code)"
                        />
                        
                      </div>
                      <button
                        type="button"
                        @click="applyFilter"
                        class="waves-effect waves-light btn btn-info me-1" style="height: 37px;"
                      >
                        Search
                      </button>
                      <button
                        type="button"
                        @click="resetFilter"
                        class="waves-effect waves-light btn btn-info" style="height: 37px;"
                      >
                        Reset
                      </button>
                      <fieldset class="table_filters mt-5 w-p100">
                    </fieldset>
                    </div>
                  </div>
                </div>
              </div>

              <div class="box">
                <div class="box-body">
                  <div class="table-responsive">
                    <center>
                      <span class="isvalid-feedback" style="display:block;">{{
                        success
                      }}</span>
                    </center>
                    <data-table-server dataType="CustomProcedures" :key="key">
                      <template v-slot="slotProps">
                        <td>{{ slotProps.item.category_name }}</td>
                        <td>
                          {{ slotProps.item.code }} ({{
                            slotProps.item.type_of_product
                          }})
                        </td>
                        <td>{{ slotProps.item.provider_name }}</td>
                        <td>{{ slotProps.item.description }}</td>
                        <td>{{ slotProps.item.note }}</td>
                        <td>${{ slotProps.item.price }}</td>
                        <td>${{ slotProps.item.price_with_tax }}</td>
                        <td>${{ slotProps.item.cost }}</td>
                        <td>{{ slotProps.item.duration }} mins</td>
                        <td>
                          <div class="d-flex">
                            <Popper arrow content="Edit Custom Profiles" :hover="true" class="helptips cursor-pointer">
                              <a
                                href="#"
                                @click="edit(slotProps.item.id)"
                                class="waves-effect waves-circle btn btn-circle btn-info btn-xs me-1"
                                ><i class="fa fa-pencil"></i
                              ></a>
                            </Popper>
                            <Popper arrow content="Delete Custom Profiles" :hover="true" class="helptips cursor-pointer">
                              <a
                                href="#"
                                @click="remove(slotProps.item.id)"
                                class="waves-effect waves-circle btn btn-circle btn-danger btn-xs"
                                ><i class="fa fa-trash"></i
                              ></a>
                            </Popper>
                          </div>
                        </td>
                      </template>
                    </data-table-server>

                  </div>
                </div>
              </div>
            </section>
          </div>
      </div>
      <!--document model start-->
      <div id="customdocument" class="modal fade" tabindex="-1" role="dialog"
         aria-labelledby="myModalLabel" aria-hidden="true">
         <div class="modal-dialog modal-md">
            <div class="modal-content">
            <div class="modal-header">
               <h3 class="modal-title fw-900 mt-0">Reference of Knowledge Base</h3>
            </div>
            <div class="modal-body overflow-auto" style="max-height: 350px;">
               <table class="table table-bordered">
                  <thead>
                     <tr>
                     <th scope="col">No.</th>
                     <th scope="col">Knowledge Base Articles</th>
                     </tr>
                  </thead>
                  <tbody>
                     <tr>
                        <td>1</td>
                        <td> <a :href="path+'how-to-add-custom-procedures-in-pureehr'" target="_blank">How To Add Custom Procedures In PureEHR?</a></td>
                     </tr>
                     <tr>
                        <td>2</td>
                        <td> <a :href="path+'how-to-edit-custom-procedures'" target="_blank">How To Edit Custom Procedures?</a></td>
                     </tr>
                     <tr>
                        <td>3</td>
                        <td> <a :href="path+'how-to-delete-remove-custom-procedure-in-pureehr'" target="_blank">How To Delete/ Remove Custom Procedure In PureEHR?</a></td>
                     </tr>
                     <tr>
                        <td>4</td>
                        <td> <a :href="path+'how-to-view-deleted-custom-procedure-in-pureehr'" target="_blank">How To View Deleted Custom Procedure In PureEHR?</a></td>
                     </tr>
                  </tbody>
               </table>
               <Search :key="searchKey"></Search>
            </div>
            <div class="modal-footer">
               <button type="button" @click="" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
            </div>
            </div>
         </div>
      </div>
      <!--document model end-->
    </div>
  </div>
</template>

<script>
import LifeStyleHeader from "@/components/Lifestyle/LifeStyleHeader.vue";
import axios from "@/scripts/axios.js";
import Popper from "vue3-popper";
import Search from "@/components/Search.vue";
import DataTableServer from "@/components/base/DataTableServer.vue";
import submitMixins from "@/scripts/mixins/submitMixins.js";
import permissionMixins from "@/scripts/mixins/permission.js";
import $ from "jquery";

export default {
  name: "Home",
  mixins: [submitMixins, permissionMixins],
  components: { DataTableServer, Popper, Search, LifeStyleHeader },
  data() {
    return {
      key: 0,
      success: "",
      result: "",
      show_lifestyle_menu : this.$route.query.lifestyle ? true : false,
      path: process.env.VUE_APP_KB_DOCUMENT,
      searchKey:0,
    };
  },
  mounted() {
    if (this.$route.params.message) {
      this.success = this.$route.params.message;
    }
    let currentLocalPage = localStorage.getItem('custom_profile_current_page');
    let filterSearch = localStorage.getItem('custom_profile_filter_search');
    this.resetlocalValue();
    if (currentLocalPage > 1) {
        if (filterSearch != null && filterSearch != '') {
          this.filter.search_text = filterSearch;
          this.getRefreshData('CustomProcedures', this.getCurrentPageNumber('CustomProcedures'), 'get', filterSearch);
        } else {
          this.getRefreshData('CustomProcedures', this.getCurrentPageNumber('CustomProcedures'));
        }
      } else {
        if (filterSearch != null && filterSearch != '') {
          this.filter.search_text = filterSearch;
          this.getRefreshData('CustomProcedures', 1, 'get', filterSearch);
        }
      }
  },
  methods: {
		reset() {
			this.searchKey = this.searchKey + 1;
		},
		applyFilter() {
			this.key++;
			this.search_drop = false;
		},
    resetFilter(){
      this.searchKey = this.searchKey + 1;
      this.filter.search_text = '';
      this.search_result = [];
      this.resetlocalValue();
      this.applyFilter();
    },
    remove(id) {
      this.$swal
        .fire({
          title: '<h2 class="mt-0">Are you sure?</h2>',
          text:
            "You want to delete these records? This process cannot be undone.",
          icon: "warning",
          dangerMode: true,
          showCancelButton: true,
          confirmButtonColor: "#ee3158",
          cancelButtonColor: "#bdbdbd",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed == true) {
            axios
              .post("/custom-procedure/delete", { id: id })
              .then((response) => {
                this.$swal
                  .fire(
                    "Deleted!",
                    "Your Custom Profiles has been deleted.",
                    "success"
                  )
                  .then((result) => {
                    if (result.isConfirmed == true) {
                      let filterSearch = this.filter.search_text;
                      if (filterSearch != '') {
                        this.getRefreshData('CustomProcedures', this.getCurrentPageNumber('CustomProcedures', 'delete'), 'get', filterSearch);
                      } else {
                        this.getRefreshData('CustomProcedures', this.getCurrentPageNumber('CustomProcedures', 'delete'));
                      }
                    }
                  });
              });
          }
        });
    },
    edit(id) {
      if (this.show_lifestyle_menu) {
        this.$router.push({ name: "customproceduresedit", params: { id: id }, query: { lifestyle: this.show_lifestyle_menu } });
      } else {
        localStorage.setItem("custom_profile_current_page", this.getCurrentPageNumber('CustomProcedures'));
        localStorage.setItem("custom_profile_filter_search", this.filter.search_text);
        this.$router.push({ name: "customproceduresedit", params: { id: id } });
      }
    },
    async getRefreshData(dataType,page,method="get", search="") {
      var queryStr = `?page=${page}&search_text=${search}`;
      await this.$store.dispatch("getDataTableContents", {
          dataType: dataType,
          method: method,
          queryStr: queryStr,
      });
    },
    getCurrentPageNumber(dataType, action='save') {
      var currentPage = this.getPaginationData(dataType).current_page;
      if (action == 'delete' && this.getPaginationData(dataType).from == this.getPaginationData(dataType).to && currentPage > 1) {
        currentPage = currentPage - 1;
      }
      return currentPage;
    },
    getPaginationData(dataType) {
      return this.$store.state[dataType].pagination;
    },
    getFilter(dataType) {
      return this.$store.state[dataType].filter;
    },
    increment() {
			this.filter.search_text = "";
			this.search_result = [];
		},
		checklength() {
			if (this.filter.search_text == "") {
				this.increment();
			}
		},
    resetlocalValue() {
      localStorage.setItem("custom_profile_current_page", 1);
      localStorage.setItem("custom_profile_filter_search", '');
    }
  },
	computed: {
		filter() {
			return this.$store.state.CustomProcedures.filter;
		},
	},
  watch: {
		"$route.name": function() {
			this.increment();
		},
		"filter.search_text": function() {
			this.checklength();
		},
	},

};
</script>
