<template>
    <div class="loginback">
      <div class="container-fluid">
        <div class="row align-items-center">
          <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 col-xxl-8 offset-lg-1">
            <div class="bg-white rounded10 p-35 pt-40 pb-40 my-20">
              <div class="text-start" v-if="this.show">
                <div class="row justify-content-between">
                  <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                    <img src="/images/pureehr.svg" alt="PureEHR" title="PureEHR" class="authcompany_logo mb-10"/>
                  </div>
                  <div class="col-12 col-sm-8 col-md-4 col-lg-4 col-xl-4">
                    <div class="form-group">
                      <label class="form-label">{{ select_language }}</label>
                      <div class="">
                          <Select2
                          v-model="form.language"
                          id="select_language"
                          :options="languages"
                          @select="languagaechange($event)"
                          />
                      </div>
                  </div>
                  </div>
                </div>
                
                <div class="">{{ patient_name }}</div>
                <p class="">{{ message }} </p>
                <label class="col-form-label"><b>{{ amount_label }}</b></label>
                <div class="row" v-if="payment_frm.type=='patient_can_not_edit_the_amount'">
                    <div class="col-12 col-sm-12 col-md-12">
                        <div class="availdate mb-20">
                          <ejs-numerictextbox id="numeric" :min="1" locale='de'  :value="this.payment_frm.paid_amount" :readonly="true"></ejs-numerictextbox>
                        </div>
                    </div>
                  </div>
                <div class="row" v-else>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-6">
                        
                        <div class="availdate mb-20">
                          <ejs-numerictextbox id="numeric" locale='de' :min="0" :value="this.payment_frm.paid_amount"></ejs-numerictextbox>
                          <span class="text-danger">{{ amounterror }}</span>
                        </div>
                    </div>
				        </div>
                <label class="form-label"><b>{{ credit_card_label }}</b></label>
                <div class="row">
					
                  <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                    <!-- iframe start-->
                    <div class="form-group">
                      <div style="display: none" id="customStyles">
                        body{margin:0;}
                        iframe{height: 100px;}
                        #payment-form {border: 1px solid rgb(212, 221, 230);max-height: 130px;width: 100%;padding: 20px 10px;border-radius: 4px;margin: 0px;background: rgb(245, 248, 250);}
                        #payment-form {color: #2d3c58;}
                        #payment-form .input-label {color: #2d3c58;border-top: 1px solid #d4dde6;font-size:13px;text-align: center !important;}
                      </div>
                      <div id="card" style="height: 130px;"></div>
                      <div id="errorMessage" style="margin-bottom: 8px; color: #c0392b;"></div>
                    </div>
                    <!-- iframe end-->
                  </div>
				      </div>
                <button v-if="this.paymentdone == false && errorMessage == ''" type="button" class="waves-effect waves-light btn  mb-5 me-1 waves-effect waves-light btn btn-info me-1" @click="handlePaymentPay">{{ pay_btn_label }}</button>
              </div>
              <div class="text-start" v-if="this.showthankyou">
                {{ msg }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import axios from "@/scripts/axios.js";
  import { mapGetters, mapActions } from "vuex";
  import useVuelidate from "@vuelidate/core";
  import { required, minLength, helpers } from "@vuelidate/validators";
  import $ from "jquery";
  import Select2 from "@/components/base/formFields/select2.vue";
  import InputField from "@/components/base/formFields/InputField.vue";
  import { NumericTextBoxPlugin } from "@syncfusion/ej2-vue-inputs";

  
  export default {
    name: "PatientWallet",
    setup() {
      return { v$: useVuelidate() };
    },
    components: {Select2,InputField,NumericTextBoxPlugin},
    data() {
      return {
        errorMessage: "",
        isDisabled: false,
        paymentdone: false,
        patient_name:'',
        message:'',
        select_language:'',
        amount_label:'',
        credit_card_label:'',
        pay_btn_label:'',
        show:true,
        showthankyou:false,
        msg:"",
  
        
        payment_frm : {
          payment_type : 'Credit Card',
          basic_amount : 0,
          discount_amount : 0,
          payable_amount : 0,
          paid_amount : 0,
          check_trace : '',
          other_payment_type : '',
          cctoken_id : 'addNew',
          use_credidcard_for_future : true,
          patient_id : '',
          clinicId:'',  
          type:'',      
        },
        form:{
            language:'',
        },
        languages:[],
        tokenpay : {},
        checkingInterval: null,
        serverError: '',
        amounterror:'',
        ccTokenData: [],
        receipt_url: '',
        embed: 0,
        publicKey : '',
      };
    },
    computed: {
      ...mapGetters(["errors"]),
    },
    beforeCreate(){
      $('.tophead').html("");
      this.$store.commit("setErrors", {});
      this.$store.state.headerRequired = false;
      this.$store.state.footerRequired = false;
      //this.$store.dispatch("auth/sendLogoutRequest");
    },
    async created() {
     
    },
   
    mounted() {
        let bridgePayScript = document.createElement('script')
        bridgePayScript.setAttribute('src', process.env.VUE_APP_API_RECTANGLEHEALTH_TOKENPAY_URL)
        document.head.appendChild(bridgePayScript);
        axios.get("language/patientwallet").then(
        (response) =>
        (this.languages = response.data.data.map((language) => {
            return { id: language.code, text: language.name };
        }))
        );
        this.tokencheck();
    },
    methods: {
        languagaechange(event){
          this.$store.state.loader = true;
          axios
          .post("/patient/wallet-token/"+this.form.language, { token: this.$route.params.token })
          .then((response) => {
            this.patient_name = response.data.data.patient_name;
            this.message=response.data.data.message;
            this.select_language= response.data.data.select_language;
            this.amount_label= response.data.data.amount_label;
            this.credit_card_label= response.data.data.credit_card_label;
            this.pay_btn_label= response.data.data.pay_now_label;
            let $this = this;
            setTimeout(function () {
                $this.$store.state.loader = false;
              }, 3000);
            })
            .catch((error) => {
              console.log(error);
              this.errorMessage = error.response;
            });
        },
        tokencheck(){
          axios
          .post("/patient/wallet-token", { token: this.$route.params.token })
          .then((response) => {
            this.$store.state.loader = true;
            if (response.data.status=="error") {
              this.show=false;
              this.showthankyou=true;
              this.msg="Your token is expired";
            }else{
              this.patient_name = response.data.data.patient_name;
              if (response.data.data.type!="free_type") {
                this.payment_frm.paid_amount = response.data.data.amount;
              }else{
                this.payment_frm.paid_amount = "";
              }
              this.message=response.data.data.message;
              this.payment_frm.patient_id = response.data.data.patient_id;
          
              this.payment_frm.clinicId = response.data.data.patient.clinic_id;
              this.payment_frm.type = response.data.data.type;
              this.select_language= response.data.data.select_language;
              this.amount_label= response.data.data.amount_label;
              this.credit_card_label= response.data.data.credit_card_label;
              this.pay_btn_label= response.data.data.pay_now_label;
              this.publicKey = response.data.data.PUBLICKEY;
              this.form.language=response.data.data.langugae.preferred_language;
              if( this.form.language!="es"){
                this.form.language="en";
              }
              let $this = this;
              setTimeout(function () {
              $this.initializeToken();
              $this.$store.state.loader = false;
              }, 3000);
            }
            
          
          })
          .catch((error) => {
            console.log(error);
            this.errorMessage = error.response;
          });
        },
        initializeToken : function() {
          this.tokenpay = {};
          if (this.publicKey) {
            this.tokenpay = TokenPay(this.publicKey);
            this.tokenpay.initialize({
              dataElement: '#card',
              errorElement: '#errorMessage',
              amountElement: 'amount',
              useACH: false,
              //if displaying all 4 fields then useStyles=false, disableZip=false, disableCvv=false
              //if displaying 3 out of 4 fields then useStyles=false, and set disableZip or disableCvv equal to true
              //if displaying 2 out of 4 fields then useStyles=true, disableZip=true, disableCvv=true
              useStyles: true,
              disableZip: true,
              disableCvv: false
            });
          }
          console.log(this.tokenpay);
        },
        
        handlePaymentCapture : function() {
          var $this = this;
          $this.$store.state.loader = true;
          axios.post("walletdata/store", this.payment_frm)
          .then((response) => {
            $this.$store.state.loader = false;
            $this.paymentdone=true;
            axios.post("/patient/wallet-token-changes", { token: this.$route.params.token,
              paid_amount:this.payment_frm.paid_amount })
            .then((response) => {
              this.show=false;
              this.showthankyou=true;
              if( this.form.language!="es"){
                this.msg="Thank you, your amount has been successfully credited  to the wallet.";
              }else{
                this.msg="Gracias, su cantidad se ha acreditado con éxito en la billetera.";
              }
             
            
            });
          })
          
        },
        handlePaymentPay: function() {
         var datavalue=$("#numeric").val();
          if(datavalue>0){
            this.payment_frm.paid_amount=datavalue;
            this.amounterror="";
            this.v$.$validate();
            if (!this.v$.$error) {
              this.serverError = '';
              this.$store.state.loader = true;
              var vm = this;
              if (this.payment_frm.payment_type == 'Credit Card' && this.payment_frm.cctoken_id == 'addNew') {
                this.tokenpay.createToken(function (result) {
                  vm.payment_frm.token = result.token;
                  vm.handlePaymentCapture();
                }, function (result) {
                  vm.$store.state.loader = false;
                });
              }
            }
          }else{
            this.amounterror="plase enter valid amount";
          }
         
        }
      }
  };
  </script>
  