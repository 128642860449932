<template>
    <div class="box p-15">
        <div class="mdc-tab">
            <ul class="nav nav-tabs text-center text-sm-start" role="tablist">
                <!-- <li class="nav-item"> <a class="nav-link" v-bind:class = "(activeDiv() == 'handp')?'active':''"  data-bs-toggle="tab" href="#handp" @click.prevent="changeTab('ClinicalNoteHandP')" role="tab">H&P</a> </li>
                <li class="nav-item"> <a class="nav-link" v-bind:class = "(activeDiv() == 'soap')?'active':''" data-bs-toggle="tab" href="#soap" role="tab" @click.prevent="changeTab('ClinicalNoteSoap')">SOAP</a> </li> -->
                <li class="nav-item"> <a class="nav-link" v-bind:class = "(activeDiv() == 'additional')?'active':''" data-bs-toggle="tab" href="#additionalnotes" role="tab" @click.prevent="changeTab('ClinicalNoteAdditional')">Custom Notes</a> </li>
                <li class="nav-item"> <a class="nav-link" v-bind:class = "(activeDiv() == 'amendment')?'active':''" data-bs-toggle="tab" href="#amendments" role="tab" @click.prevent="changeTab('PatientAmendments')">Amendments</a> </li>
                <li class="nav-item"> <a class="nav-link" data-bs-toggle="tab" href="#lockedNote" role="tab" @click.prevent="changeTab('PatientLockedClinicalNotes')">Locked Clinical Notes</a> </li>
            </ul>
            <div class="tab-content">
                <div class="tab-pane active" id="handp" role="tabpanel">
                    <label v-if="show"><b>Note: </b>Imported Note</label>
                    <a href="javascript:void(0)" @click="back_to_note" class="waves-effect waves-light btn btn-info-light mb-5 float-end"><i class="fa fa-arrow-left fs-14"></i> Back to Note</a>
                    <div class="box bg-lightest p-10 my-2" id="noteSection">
                        <p class="msg"> Loading Please wait...</p>
                        <template v-if="embed=0">
                            <vue-pdf-viewer height="1050px" :url="url"></vue-pdf-viewer>  
                        </template>
                        <template v-if="embed=1">
                            <iframe :src="url" height="1050px" />
                        </template>                            
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import VuePDFViewer from 'vue-instant-pdf-viewer';
import axios from "@/scripts/axios.js";
import ClinicalNoteHandP from '@/components/patients/details/ClinicalNote/HandP/Home.vue';
import ClinicalNoteSoap from '@/components/patients/details/ClinicalNote/SOAP/Home.vue';
import ClinicalNoteAdditional from '@/components/patients/details/ClinicalNote/Additional/Home.vue';

export default {
    components: { 'vue-pdf-viewer': VuePDFViewer, ClinicalNoteHandP, ClinicalNoteSoap, ClinicalNoteAdditional },
    props: {
        dataId: Number,
    },
    data() {
        return {
            loader: 0,
            url: '',
            embed: null,
            show: false
        };
    },
    mounted() {
        if(this.$store.state.clinicalNote.active_note_legasy=='imported')
        {
            this.show = true;
        }
        this.getSrc();
    },
    created(){
    },
    methods:{
        async getSrc() {
            const res = await fetch(process.env.VUE_APP_API_BASE_URL+'/preview/'+this.$store.state.clinicalNote.clinical_note_id, {
                method: 'GET',
                headers: {
                  'Authorization' : 'Bearer '+localStorage.getItem('authToken')
                }
            });

            if(res.status == 200)
            {
                const blob = await res.blob();
                const urlObject = URL.createObjectURL(blob);
                this.url = urlObject;
                $("p.msg").addClass("d-none");
            }
            else
            {
                this.embed = null;
                $("p.msg").text("Sorry! Please try again.");
            }
        },        
        browserCheck(){
            // Opera 8.0+
            var isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

            // Firefox 1.0+
            var isFirefox = typeof InstallTrigger !== 'undefined';

            // Safari 3.0+ "[object HTMLElementConstructor]" 
            var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));

            // Internet Explorer 6-11
            var isIE = /*@cc_on!@*/false || !!document.documentMode;

            // Edge 20+
            var isEdge = !isIE && !!window.StyleMedia;

            // Chrome 1 - 79
            var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

            // Edge (based on chromium) detection
            var isEdgeChromium = isChrome && (navigator.userAgent.indexOf("Edg") != -1);

            // Blink engine detection
            var isBlink = (isChrome || isOpera) && !!window.CSS;

            if(isChrome==true)
            {
                this.embed = 0;
            }else{
                this.embed = 1;
            }

        },
        activeDiv(){
            return this.$store.state.clinicalNote.active_note;
        },
        changeTab(components)
        {
            this.$store.state.patientDetailsComp = components;
            let props = this.$router.resolve({ 
              name: components,
              params: { id: this.$route.params.id},
            });
            if (history.pushState && props) {
                history.pushState(null, '',props.href);
            }
        },
        back_to_note()
        {
            this.$store.state.patientDetailsComp = this.$store.state.clinicalNote.active_note_component;
        }
    }
}
</script>