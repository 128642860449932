<template>
    <div class="content-wrapper">
        <div class="container-full">
            <div class="content-header">
                <h1 class="heading1 mt-0">Advance Report</h1>
            </div>
            <div class="box">
                <div class="box-body">
                    <ul class="nav nav-tabs mt-0 mb-20 text-center text-sm-start" id="filter_tabs" role="tablist">
                        <li class="nav-item"> 
                            <a class="nav-link active" data-bs-toggle="tab" href="#Patient" @click="tabChange('Patient')" role="tab">Patient</a>
                        </li>
                        <li class="nav-item"> 
                            <a class="nav-link" data-bs-toggle="tab" href="#Appointment" @click="tabChange('Appointment')" role="tab">Appointment</a>
                        </li>
                    </ul>
                    <div class="p-10 b-1 rounded5 mb-10">
                        <div class="btn-group me-10 my-1" role="group">
                            <button type="button" class="btn btn-light" @click="openmodal('PatientFilters')">Patient Filters</button>
                            <button type="button" class="btn btn-light" @click="openmodal('AppointmentFilters')">Appointment Filters</button>
                        </div>
                        <div class="btn-group me-10 my-1" role="group">
                            <button type="button" class="btn btn-light" @click="openmodal('AppointmentStatus')">Appointment Status</button>
                            <button type="button" class="btn btn-light" @click="openmodal('BillingStatus')">Billing Status</button>
                        </div>
                        <button type="button" class="waves-effect waves-light btn btn-info m-1" @click="update_filter">Update Filter</button>

                        <a class="waves-effect waves-light btn btn-info-light m-1" @click="export_report"> Export <i class="fa fa-download" aria-hidden="true"></i></a>       
                        <div v-if="csv_export_message!=null">
                            <div class="text-danger">{{csv_export_message}}</div>
                        </div>                
                        <hr>
                        <div class="filterSection">
                            <div class="filterSubSection">
                                <div class="row">
                                    <slot v-for="(item,index) in PatientFilterList" :key="index">
                                        <slot v-if="item.checked==true">
                                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                <slot v-if="item.id == 'primary_provider'">
                                                    <div class="form-group row mb-5">
                                                        <label class="col-form-label mb-0 col-12 col-sm-12 col-md-4 col-lg-4 col-xl-2 text-md-end">{{item.name}}</label>
                                                        <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-5 availdate d-flex align-items-center">
                                                            <ejs-multiselect id='multiselect_primary_provider' @change="selectedPrimaryProvider" :dataSource='accessproviders' :fields='default_fields' :mode='boxMode' :placeholder="'Select '+item.name"></ejs-multiselect>
                                                            <i class="fa fa-times cursor-pointer ms-2 text-mute" aria-hidden="true" @click="hideFilter('PatientFilterList',index)"></i>
                                                        </div>
                                                    </div>
                                                </slot>
                                                <slot v-else-if="item.id == 'patient_status'">
                                                    <div class="form-group row mb-5">
                                                        <label class="col-form-label mb-0 col-12 col-sm-12 col-md-4 col-lg-4 col-xl-2 text-md-end">{{item.name}}</label>
                                                        <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-5 availdate d-flex align-items-center">
                                                            <ejs-multiselect id='multiselect_patient_status' @change="selectedPatientStatus" :dataSource='patientstatus' :fields='default_fields' :mode='boxMode' :placeholder="'Select '+item.name"></ejs-multiselect>
                                                            <i class="fa fa-times cursor-pointer ms-2 text-mute" aria-hidden="true" @click="hideFilter('PatientFilterList',index)"></i>
                                                        </div>
                                                    </div>
                                                </slot>
                                                <slot v-else-if="item.id == 'gender'">
                                                    <div class="form-group row mb-5">
                                                        <label class="col-form-label mb-0 col-12 col-sm-12 col-md-4 col-lg-4 col-xl-2 text-md-end">{{item.name}}</label>
                                                        <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-5 availdate d-flex align-items-center">
                                                            <ejs-multiselect id='multiselect_gender' @change="selectedGender" :dataSource='genderlist' :fields='default_fields' :mode='boxMode' :placeholder="'Select '+item.name"></ejs-multiselect>
                                                            <i class="fa fa-times cursor-pointer ms-2 text-mute" aria-hidden="true" @click="hideFilter('PatientFilterList',index)"></i>
                                                        </div>
                                                    </div>
                                                </slot>
                                                <slot v-else-if="item.id == 'ethnicity'">
                                                    <div class="form-group row mb-5">
                                                        <label class="col-form-label mb-0 col-12 col-sm-12 col-md-4 col-lg-4 col-xl-2 text-md-end">{{item.name}}</label>
                                                        <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-5 availdate d-flex align-items-center">
                                                            <ejs-multiselect id='multiselect_ethnicity' @change="selectedEthnicity" :dataSource='ethnicitylist' :fields='default_fields' :mode='boxMode' :placeholder="'Select '+item.name"></ejs-multiselect>
                                                            <i class="fa fa-times cursor-pointer ms-2 text-mute" aria-hidden="true" @click="hideFilter('PatientFilterList',index)"></i>
                                                        </div>
                                                    </div>
                                                </slot>
                                                <slot v-else-if="item.id == 'race'">
                                                    <div class="form-group row mb-5">
                                                        <label class="col-form-label mb-0 col-12 col-sm-12 col-md-4 col-lg-4 col-xl-2 text-md-end">{{item.name}}</label>
                                                        <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-5 availdate d-flex align-items-center">
                                                            <ejs-multiselect id='multiselect_race' @change="selectedRace" :dataSource='racelist' :fields='default_fields' :mode='boxMode' :placeholder="'Select '+item.name"></ejs-multiselect>
                                                            <i class="fa fa-times cursor-pointer ms-2 text-mute" aria-hidden="true" @click="hideFilter('PatientFilterList',index)"></i>
                                                        </div>
                                                    </div>
                                                </slot>
                                                <slot v-else-if="item.id == 'state'">
                                                    <div class="form-group row mb-5">
                                                        <label class="col-form-label mb-0 col-12 col-sm-12 col-md-4 col-lg-4 col-xl-2 text-md-end">{{item.name}}</label>
                                                        <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-5 availdate d-flex align-items-center">
                                                            <ejs-multiselect id='multiselect_state' @change="selectedState" :dataSource='stateslist' :fields='default_fields' :mode='boxMode' :placeholder="'Select '+item.name"></ejs-multiselect>
                                                            <i class="fa fa-times cursor-pointer ms-2 text-mute" aria-hidden="true" @click="hideFilter('PatientFilterList',index)"></i>
                                                        </div>
                                                    </div>
                                                </slot>
                                                <slot v-else-if="item.id == 'office'">
                                                    <div class="form-group row mb-5">
                                                        <label class="col-form-label mb-0 col-12 col-sm-12 col-md-4 col-lg-4 col-xl-2 text-md-end">{{item.name}}</label>
                                                        <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-5 availdate d-flex align-items-center">
                                                            <ejs-multiselect id='multiselect_office' @change="selectedOffice" :dataSource='officelist' :fields='default_fields' :mode='boxMode' :placeholder="'Select '+item.name"></ejs-multiselect>
                                                            <i class="fa fa-times cursor-pointer ms-2 text-mute" aria-hidden="true" @click="hideFilter('PatientFilterList',index)"></i>
                                                        </div>
                                                    </div>
                                                </slot>
                                                <slot v-else>
                                                    <div class="form-group row" :id="'aks'+item.id">
                                                        <label class="col-form-label mb-0 col-12 col-sm-12 col-md-4 col-lg-4 col-xl-2 text-md-end">{{item.name}} </label>
                                                        <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-5 d-flex align-items-center">
                                                            <div class="controls w-p100">
                                                                <input :id="item.id" type="text" v-model="filter[item.id]" class="form-control" :placeholder="item.name" autocomplete="off">
                                                            </div>
                                                            <i class="fa fa-times cursor-pointer ms-2 text-mute" aria-hidden="true" @click="hideFilter('PatientFilterList',index)"></i>
                                                        </div>
                                                    </div>                                                    
                                                </slot>
                                            </div>
                                        </slot>
                                    </slot>
                                    <slot v-for="(item,index) in AppointmentFilterList" :key="index">
                                        <slot v-if="item.checked==true">
                                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                <slot v-if="item.id == 'visit_profile'">
                                                    <div class="form-group row mb-5">
                                                        <label class="col-form-label mb-0 col-12 col-sm-12 col-md-4 col-lg-4 col-xl-2 text-md-end">{{item.name}}</label>
                                                        <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-5 availdate d-flex align-items-center">
                                                            <ejs-multiselect id='multiselect_visit_profile' @change="selectedVisitProfile" :dataSource='visitprofiles' :fields='default_fields' :mode='boxMode' :placeholder="'Select '+item.name"></ejs-multiselect>
                                                            <i class="fa fa-times cursor-pointer ms-2 text-mute" aria-hidden="true" @click="hideFilter('AppointmentFilterList',index)"></i>
                                                        </div>
                                                    </div>
                                                </slot>
                                                <slot v-else-if="item.id == 'doctor'">
                                                    <div class="form-group row mb-5">
                                                        <label class="col-form-label mb-0 col-12 col-sm-12 col-md-4 col-lg-4 col-xl-2 text-md-end">Provider</label>
                                                        <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-5 availdate d-flex align-items-center">
                                                            <ejs-multiselect id='multiselect_doctor' @change="selectedDoctor" :dataSource='doctorlist' :fields='default_fields' :mode='boxMode' :placeholder="'Select '+item.name"></ejs-multiselect>
                                                            <i class="fa fa-times cursor-pointer ms-2 text-mute" aria-hidden="true" @click="hideFilter('AppointmentFilterList',index)"></i>
                                                        </div>
                                                    </div>
                                                </slot>
                                                <slot v-else-if="item.id == 'office'">
                                                    <div class="form-group row mb-5">
                                                        <label class="col-form-label mb-0 col-12 col-sm-12 col-md-4 col-lg-4 col-xl-2 text-md-end">{{item.name}}</label>
                                                        <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-5 availdate d-flex align-items-center">
                                                            <ejs-multiselect id='multiselect_office' @change="selectedOffice" :dataSource='officelist' :fields='default_fields' :mode='boxMode' :placeholder="'Select '+item.name"></ejs-multiselect>
                                                            <i class="fa fa-times cursor-pointer ms-2 text-mute" aria-hidden="true" @click="hideFilter('AppointmentFilterList',index)"></i>
                                                        </div>
                                                    </div>
                                                </slot>
                                                <slot v-else-if="item.id == 'exam_room'">
                                                    <div class="form-group row mb-5">
                                                        <label class="col-form-label mb-0 col-12 col-sm-12 col-md-4 col-lg-4 col-xl-2 text-md-end">{{item.name}}</label>
                                                        <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-5 availdate d-flex align-items-center">
                                                            <ejs-multiselect id='multiselect_exam_room' @change="selectedExamRoom" :dataSource='exam_room_list' :fields='default_fields' :mode='boxMode' :placeholder="'Select '+item.name"></ejs-multiselect>
                                                            <i class="fa fa-times cursor-pointer ms-2 text-mute" aria-hidden="true" @click="hideFilter('AppointmentFilterList',index)"></i>
                                                        </div>
                                                    </div>
                                                </slot>
                                                <slot v-else>
                                                    <div class="form-group row" :id="'aks'+item.id">
                                                        <label class="col-form-label mb-0 col-12 col-sm-12 col-md-4 col-lg-4 col-xl-2 text-md-end">{{item.name}} </label>
                                                        <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-5 d-flex align-items-center">
                                                            <div class="controls w-p100">
                                                                <input :id="item.id" type="text" v-model="filter[item.id]" class="form-control" :placeholder="item.name" autocomplete="off">
                                                            </div>  
                                                            <i class="fa fa-times cursor-pointer ms-2 text-mute" aria-hidden="true" @click="hideFilter('AppointmentFilterList',index)"></i>                                                          
                                                        </div>
                                                    </div>
                                                </slot>
                                            </div>
                                        </slot>
                                    </slot>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-content">
                        <div class="tab-pane active" id="Patient" role="tabpanel">
                            <div class="mb-10">
                                <div class="table-responsive">
                                    <data-table-server dataType="AdvanceReport" dataMethod="post" :key="key">
                                        <template v-slot="slotProps">
                                            <td>
                                                <slot v-if="permissioncheck(this.$store.state.permission.Access_Patient_Chart)">
                                                    <router-link :to="{ name: 'PatientDetails', params: { id: slotProps.item.unique_id } }"
                                                        :title="'View Chart of ' + slotProps.item.first_name + '(' + slotProps.item.chart_id + ')'"
                                                        class="fw-500">{{ slotProps.item.chart_id }}</router-link>
                                                </slot>
                                                <slot v-else>
                                                    <slot v-if="slotProps.item.chart_id!=null">
                                                        {{slotProps.item.chart_id}}
                                                    </slot>
                                                    <slot v-else>-</slot>
                                                </slot>
                                            </td>
                                            <td>
                                                <slot v-if="slotProps.item.provider_name!=null">
                                                    {{slotProps.item.provider_name}}
                                                </slot>
                                                <slot v-else>-</slot>
                                            </td>
                                            <td>
                                                <slot v-if="slotProps.item.first_name!=null">
                                                    {{slotProps.item.first_name}} 
                                                </slot>
                                                <slot v-else>-</slot>
                                            </td>
                                            <td> 
                                                <slot v-if="slotProps.item.last_name!=null">
                                                    {{slotProps.item.last_name}}  
                                                </slot>
                                                <slot v-else>-</slot>
                                            </td>
                                            <td> 
                                                <slot v-if="slotProps.item.date_of_birth!=null">
                                                    {{slotProps.item.date_of_birth}}  
                                                </slot>
                                                <slot v-else>-</slot>
                                            </td>
                                            <td> 
                                                <slot v-if="slotProps.item.home_phone!=null">
                                                    {{slotProps.item.home_phone}}  
                                                </slot>
                                                <slot v-else>-</slot>
                                            </td>
                                            <td> 
                                                <slot v-if="slotProps.item.cell_phone!=null">
                                                    {{slotProps.item.cell_phone}}  
                                                </slot>
                                                <slot v-else>-</slot>
                                            </td>
                                            <td> 
                                                <slot v-if="slotProps.item.email!=null">
                                                    {{slotProps.item.email}} 
                                                </slot>
                                                <slot v-else>-</slot>
                                            </td>
                                            <td> 
                                                <slot v-if="slotProps.item.last_appointment_date!='0000-00-00 00:00:00'&&slotProps.item.last_appointment_date!=null">
                                                    {{$filters.displayDateTimeLocal(slotProps.item.last_appointment_date,'',false,'MM-DD-YYYY hh:mm A')}}  
                                                </slot>
                                                <slot v-else>-</slot>
                                            </td>
                                        </template>
                                    </data-table-server>   
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane" id="Appointment" role="tabpanel">
                            <div class="mb-10">
                                <div class="table-responsive">
                                    <data-table-server dataType="AdvanceReport" dataMethod="post" :key="key1" :onLoadCall="onLoadParam">
                                        <template v-slot="slotProps">
                                            <td> 
                                                <slot v-if="slotProps.item.schedule_date!=null"> {{ dateFormated(slotProps.item.schedule_date,slotProps.item.schedule_time) }}</slot>
                                                <slot v-else>-</slot>
                                            </td>
                                            <td>
                                                <slot v-if="slotProps.item.patient_name!=null">{{slotProps.item.patient_name}} </slot>
                                                <slot v-else>-</slot>
                                            </td>
                                            <td>
                                                <slot v-if="slotProps.item.provider_name!=null">{{slotProps.item.provider_name}} </slot>
                                                <slot v-else>-</slot>
                                            </td>
                                            <td> 
                                                <slot v-if="slotProps.item.office_name!=null">{{slotProps.item.office_name}}  </slot>
                                                <slot v-else>-</slot>
                                            </td>
                                            <td> 
                                                <slot v-if="slotProps.item.exam_room!=null">{{slotProps.item.exam_room}}  </slot>
                                                <slot v-else>-</slot>
                                            </td>
                                            <td> 
                                                <slot v-if="slotProps.item.status_label!=null">{{slotProps.item.status_label}}  </slot>
                                                <slot v-else>-</slot>
                                            </td>
                                            <td> 
                                                <slot v-if="slotProps.item.billing_status!=null">{{slotProps.item.billing_status}}  </slot>
                                                <slot v-else>-</slot>
                                            </td>
                                            <td> 
                                                <slot v-if="slotProps.item.claim_status!=null">{{slotProps.item.claim_status}}  </slot>
                                                <slot v-else>-</slot>
                                            </td>
                                            <td> 
                                                <slot v-if="slotProps.item.note_status!=null">{{slotProps.item.note_status}}  </slot>
                                                <slot v-else>-</slot>
                                            </td>
                                        </template>
                                    </data-table-server>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="PatientFilters" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h3 class="modal-title fw-900 mt-0">Patient Filters</h3>
                        </div>
                        <div class="modal-body">
                            <a class="waves-effect waves-light btn btn-light btn-sm m-1" @click="checkAll('PatientFilterList')">Check All</a>
                            <a class="waves-effect waves-light btn btn-light btn-sm m-1" @click="uncheckAll('PatientFilterList')">Uncheck All</a>

                            <div class="selectCheckBox">
                                <div class="row">
                                    <slot :set="filtercount=PatientFilterList.length"></slot>
                                    <div class="col-12 col-sm-6 col-md-6">
                                        <slot v-for="(item,index) in PatientFilterList" :key="index">
                                            <slot v-if="(index+1) <= (filtercount/2)">
                                                <div class="select-check-option">
                                                    <input type="checkbox" class="filled-in chk-col-info" name="selected_values" @change="selectitem('PatientFilterList')" v-model="item.checked" :id="item.name.replace(/\s+/g, '')+index" :value="item.id">
                                                    <label :for="item.name.replace(/\s+/g, '')+index">{{item.name}}</label>
                                                </div>
                                            </slot>
                                        </slot>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-6">
                                        <slot v-for="(item,index) in PatientFilterList" :key="index">
                                            <slot v-if="(index+1) > (filtercount/2)">
                                                <div class="select-check-option">
                                                    <input type="checkbox" class="filled-in chk-col-info" name="selected_values" @change="selectitem('PatientFilterList')" v-model="item.checked" :id="item.name.replace(/\s+/g, '')+index" :value="item.id">
                                                    <label :for="item.name.replace(/\s+/g, '')+index">{{item.name}}</label>
                                                </div>
                                            </slot>
                                        </slot>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <a href="javascript:void(0)" class="waves-effect waves-light btn btn-danger" data-bs-dismiss="modal"> Close </a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="AppointmentFilters" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h3 class="modal-title fw-900 mt-0">Appointment Filters</h3>
                        </div>
                        <div class="modal-body">
                            <a class="waves-effect waves-light btn btn-light btn-sm mt-xs-5 me-1" @click="checkAll('AppointmentFilterList')">Check All</a>
                            <a class="waves-effect waves-light btn btn-light btn-sm mt-xs-5" @click="uncheckAll('AppointmentFilterList')">Uncheck All</a>

                            <div class="selectCheckBox">
                                <div class="row">
                                    <slot :set="filtercount=AppointmentFilterList.length"></slot>
                                    <div class="col-12 col-sm-6 col-md-6">
                                        <slot v-for="(item,index) in AppointmentFilterList" :key="index">
                                            <slot v-if="(index+1) <= (filtercount/2)">
                                                <div class="select-check-option">
                                                    <input type="checkbox" class="filled-in chk-col-info" name="selected_values" @change="selectitem('AppointmentFilterList')" v-model="item.checked" :id="item.name.replace(/\s+/g, '')+index" :value="item.id">
                                                    <label :for="item.name.replace(/\s+/g, '')+index" v-if="item.name == 'Doctor'">Provider</label>
                                                    <label :for="item.name.replace(/\s+/g, '')+index" v-else>{{item.name}}</label>
                                                </div>
                                            </slot>
                                        </slot>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-6">
                                        <slot v-for="(item,index) in AppointmentFilterList" :key="index">
                                            <slot v-if="(index+1) > (filtercount/2)">
                                                <div class="select-check-option">
                                                    <input type="checkbox" class="filled-in chk-col-info" name="selected_values" @change="selectitem('AppointmentFilterList')" v-model="item.checked" :id="item.name.replace(/\s+/g, '')+index" :value="item.id">
                                                    <label :for="item.name.replace(/\s+/g, '')+index">{{item.name}}</label>
                                                </div>
                                            </slot>
                                        </slot>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <a href="javascript:void(0)" class="waves-effect waves-light btn btn-danger" data-bs-dismiss="modal"> Close </a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="AppointmentStatus" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h3 class="modal-title fw-900 mt-0">Appointment Status</h3>
                        </div>
                        <div class="modal-body">
                            <a class="waves-effect waves-light btn btn-light btn-sm mt-xs-5 me-1" @click="checkAll('AppointmentStatusList')">Check All</a>
                            <a class="waves-effect waves-light btn btn-light btn-sm mt-xs-5" @click="uncheckAll('AppointmentStatusList')">Uncheck All</a>

                            <div class="selectCheckBox">
                                <div class="row">
                                    <slot :set="filtercount=AppointmentStatusList.length"></slot>
                                    <div class="col-12 col-sm-6 col-md-6">
                                        <slot v-for="(item,index) in AppointmentStatusList" :key="index">
                                            <slot v-if="(index+1) <= (filtercount/2)">
                                                <div class="select-check-option">
                                                    <input type="checkbox" class="filled-in chk-col-info" name="selected_values" @change="selectitem('AppointmentStatusList')" v-model="item.checked" :id="item.name.replace(/\s+/g, '')+index" :value="item.id">
                                                    <label :for="item.name.replace(/\s+/g, '')+index">{{item.name}}</label>
                                                </div>
                                            </slot>
                                        </slot>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-6">
                                        <slot v-for="(item,index) in AppointmentStatusList" :key="index">
                                            <slot v-if="(index+1) > (filtercount/2)">
                                                <div class="select-check-option">
                                                    <input type="checkbox" class="filled-in chk-col-info" name="selected_values" @change="selectitem('AppointmentStatusList')" v-model="item.checked" :id="item.name.replace(/\s+/g, '')+index" :value="item.id">
                                                    <label :for="item.name.replace(/\s+/g, '')+index">{{item.name}}</label>
                                                </div>
                                            </slot>
                                        </slot>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <a href="javascript:void(0)" class="waves-effect waves-light btn btn-danger" data-bs-dismiss="modal"> Close </a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="BillingStatus" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h3 class="modal-title fw-900 mt-0">Billing Status</h3>
                        </div>
                        <div class="modal-body">                            
                            <a class="waves-effect waves-light btn btn-light btn-sm mt-xs-5 me-1" @click="checkAll('BillingStatusList')">Check All</a>
                            <a class="waves-effect waves-light btn btn-light btn-sm mt-xs-5" @click="uncheckAll('BillingStatusList')">Uncheck All</a>

                            <div class="selectCheckBox">
                                <div class="row">
                                    <slot :set="filtercount=BillingStatusList.length"></slot>
                                    <div class="col-12 col-sm-6 col-md-6">
                                        <slot v-for="(item,index) in BillingStatusList" :key="index">
                                            <slot v-if="(index+1) <= (filtercount/2)">
                                                <div class="select-check-option">
                                                    <input type="checkbox" class="filled-in chk-col-info" name="selected_values" @change="selectitem('BillingStatusList')" v-model="item.checked" :id="item.name.replace(/\s+/g, '')+index" :value="item.id">
                                                    <label :for="item.name.replace(/\s+/g, '')+index">{{item.name}}</label>
                                                </div>
                                            </slot>
                                        </slot>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-6">
                                        <slot v-for="(item,index) in BillingStatusList" :key="index">
                                            <slot v-if="(index+1) > (filtercount/2)">
                                                <div class="select-check-option">
                                                    <input type="checkbox" class="filled-in chk-col-info" name="selected_values" @change="selectitem('BillingStatusList')" v-model="item.checked" :id="item.name.replace(/\s+/g, '')+index" :value="item.id">
                                                    <label :for="item.name.replace(/\s+/g, '')+index">{{item.name}}</label>
                                                </div>
                                            </slot>
                                        </slot>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <a href="javascript:void(0)" class="waves-effect waves-light btn btn-danger" data-bs-dismiss="modal"> Close </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "@/scripts/axios.js";
import moment from "moment";
import InputField from "@/components/base/formFields/InputField.vue";
import DataTableServer from "@/components/base/DataTableServer.vue";
import permissionMixins from "@/scripts/mixins/permission.js";
export default {
	mixins: [permissionMixins],
    computed: {
		filter() {
			return this.$store.state.AdvanceReport.filter;
		},
	},
    data() {
        return {
            key: 0,
            key1: 0,
            onLoadParam: false,
            AppointmentFilterList:[
                {id:"doctor", name: "Doctor", checked: false},
                {id:"office", name: "Office", checked: false},
                {id:"exam_room", name: "Exam Room", checked: false},
                {id:"comma_separated_codes", name: "CPT, HCPCS and Custom Procedures contains ANY of", checked: false},
                {id:"visit_profile", name: "Visit Profile", checked: false},
            ],
            PatientFilterList:[
                {id:"primary_provider", name: "Primary Provider", checked: false},
                {id:"first_name", name: "First Name", checked: false},
                {id:"middle_name", name: "Middle Name", checked: false},
                {id:"last_name", name: "Last Name", checked: false},                
                {id:"patient_status", name: "Patient Status", checked: false},
                {id:"address", name: "Address", checked: false},
                {id:"zipcode", name: "Zipcode", checked: false},
                {id:"city", name: "City", checked: false},
                {id:"state", name: "State", checked: false},
                {id:"home_phone", name: "Home Phone", checked: false},
                {id:"cell_phone", name: "Cell Phone", checked: false},
                {id:"office_phone", name: "Office Phone", checked: false},
                {id:"email", name: "Email", checked: false},
                {id:"dob", name: "DOB", checked: false},
                {id:"gender", name: "Gender", checked: false},
                {id:"ethnicity", name: "Ethnicity", checked: false},
                {id:"race", name: "Race", checked: false},
                {id:"office", name: "Primary Office", checked: false},
            ],
            AppointmentStatusList:[],
            BillingStatusList:[],
            patientstatus: [
                { id: "Active", name: "Active" },
                { id: "Inactive", name: "Inactive" },
                { id: "Prospect", name: "Prospect" },
            ],
            genderlist: [
                { id: "Male", name: "Male" },
                { id: "Female", name: "Female" },
                { id: "Others", name: "Others" },
                { id: "Unknown", name: "Unknown" },
                { id: "Declined to specify", name: "Declined to specify" },
            ],
            ethnicitylist: [
                { id: "Hispanic or Latino", name: "Hispanic or Latino" },
                { id: "Not Hispanic or Latino", name: "Not Hispanic or Latino" },
                { id: "Decline to specify", name: "Decline to specify" },
                { id: "Unknown", name: "Unknown" },
            ],
            accessproviders:[],
            visitprofiles:[],
            racelist:[],
            doctorlist:[],
            stateslist:[],
            officelist:[],
            exam_room_list:[],
            default_fields : { text: 'name', value: 'id' },
            boxMode: 'Box',
            resultPatientList:[],
            resultAppointmentList:[],
            arry_to_export:{
                result_for:null,
                ids_to_export:null,
            },
            csv_export_message:null,
        }
    },
    async beforeCreate() {
        axios.get("/json_dropdown/appointment-status")
        .then((response) => {
            this.AppointmentStatusList = response.data.data.map(item => {
                return {...item, checked: false };
            }); 
        });
        axios.get("/json_dropdown/get-billing-status")
        .then((response) => {
            this.BillingStatusList = response.data.data.map(item => {
                return {...item, checked: false };
            });
        });        
    },
    components: { InputField, DataTableServer },
    methods:{
        export_report(){
            var active_tab = $("#filter_tabs").find(".active").attr("href").substring(1);
            this.arry_to_export.result_for = active_tab;
            if(active_tab=='Appointment'){
                this.arry_to_export.ids_to_export = this.resultAppointmentList.map((item) => {
					return  item.id;
				}).filter(n => n);
            }else{
                this.arry_to_export.ids_to_export = this.$store.state.AdvanceReport.patientIds;
            }
            this.$store.state.loader = true;
            axios.post("/reports/advance-report-export",this.arry_to_export)
            .then((response) => {
                this.csv_export_message = "Please find your file in Download Logs Section.";
                this.$store.state.loader = false;
                setTimeout(() => {
                    this.csv_export_message = null;
                }, 8000);
            });
        },
        tabChange(module){
            this.filter.result_for = module
            if(module == 'Appointment'){
                this.onLoadParam = true;
                this.key1 = this.key1+1;
            }else{
                this.key = this.key+1;
            }
        },
        dateFormated(dt, time) {
            return moment(dt + " " + time).format("MM-DD-YYYY hh:mm A");
        },
        openmodal(modalId){
            $('#'+modalId).modal('show');
        },
        checkAll(Arrayname){
            this[Arrayname] = this[Arrayname].map(obj=> ({ ...obj, checked: true }));
            this[Arrayname].forEach( (currentValue, index, arr) => {
                if(currentValue.checked){      
                    switch (currentValue.id) {
                        case 'primary_provider':
                            axios.post("/get-user-provider")
                            .then((response) => {
                                this.accessproviders = response.data.data; 
                            });
                            break;
                        case 'visit_profile':
                            axios.get("/json_dropdown/appointment-profile")
                            .then((response) => {
                                this.visitprofiles = response.data.data;            
                            });
                            break;
                        case 'race':
                            axios.get("json_dropdown/race")
                            .then((response) => {
                                    this.racelist = response.data.data.map((race) => {
                                    return { id: race.id, name: race.description };
                                });
                            });
                            break;
                        case 'state':
                            axios.post("/json_autocomplete/" + 231 + "/state")
                            .then((response) => {
                                this.stateslist = response.data.data.map((state) => {
                                    return { id: state.id, name: state.name };
                                });                               
                            });                    
                            break;
                        case 'office':
                            axios.get("/json_dropdown/get-user-office-list")
                            .then((response) => (
                                this.officelist = response.data.data
                            ));
                            break;
                        case 'exam_room':
                            axios.post("/json_dropdown/get-user-rooms")
                            .then((response) => (
                                this.exam_room_list = response.data.data
                            ));
                            break;
                        case 'doctor':                            
                            axios.post("/get-user-provider")
                            .then((response) => {
                                this.doctorlist = response.data.data; 
                            });
                            break;
                        default:                                                
                            break;
                    }
                }
            })
        },
        uncheckAll(Arrayname){
            this[Arrayname] = this[Arrayname].map(obj=> ({ ...obj, checked: false }));
        },
        selectitem(Arrayname){
            this[Arrayname].forEach( (currentValue, index, arr) => {
                if(currentValue.checked){      
                    switch (currentValue.id) {
                        case 'primary_provider':
                            axios.post("/get-user-provider")
                            .then((response) => {
                                this.accessproviders = response.data.data; 
                            });
                            break;
                        case 'visit_profile':
                            axios.get("/json_dropdown/appointment-profile")
                            .then((response) => {
                                this.visitprofiles = response.data.data;            
                            });
                            break;
                        case 'race':
                            axios.get("json_dropdown/race")
                            .then((response) => {
                                    this.racelist = response.data.data.map((race) => {
                                    return { id: race.id, name: race.description };
                                });
                            });
                            break;
                        case 'state':
                            axios.post("/json_autocomplete/" + 231 + "/state")
                            .then((response) => {
                                this.stateslist = response.data.data.map((state) => {
                                    return { id: state.id, name: state.name };
                                });                               
                            });                    
                            break;
                        case 'office':
                            axios.get("/json_dropdown/get-user-office-list")
                            .then((response) => (
                                this.officelist = response.data.data
                            ));
                            break;
                        case 'exam_room':
                            axios.post("/json_dropdown/get-user-rooms")
                            .then((response) => (
                                this.exam_room_list = response.data.data
                            ));
                            break;
                        case 'doctor':                            
                            axios.post("/get-user-provider")
                            .then((response) => {
                                this.doctorlist = response.data.data; 
                            });
                            break;
                        default:                                                
                            break;
                    }
                }else{
                    if(currentValue.id=="last_id"){
                        this.filter.last_id = 0;
                    }else{
                        this.filter[currentValue.id] = null;
                    }        
                }
            })
        },
        hideFilter(Arrayname,index){
            this[Arrayname][index].checked = false;
            
            if(this[Arrayname][index].id=="last_id"){
                this.filter.last_id = 0;
            }else{
                this.filter[this[Arrayname][index].id] = null;
            }
            this.filter.office = [];
        },
        selectedPrimaryProvider(args){
            this.filter.primary_provider = args.value
		},
        selectedVisitProfile(args){
            this.filter.visit_profile = args.value
        },
        selectedPatientStatus(args){
            this.filter.patient_status = args.value
        },
        selectedGender(args){
            this.filter.gender = args.value
        },
        selectedEthnicity(args){
            this.filter.ethnicity = args.value
        },
        selectedRace(args){
            this.filter.race = args.value
        },
        selectedState(args){
            this.filter.state = args.value
        },
        selectedDoctor(args){
            this.filter.doctor = args.value
        },
        selectedOffice(args){
            this.filter.office = args.value
        },
        selectedExamRoom(args){
            this.filter.exam_room = args.value
        },
        update_filter(type=null){
            if(type != 'page'){
                this.filter.last_id = 0;
                this.filter.first_id = null;
            }
            var active_tab = $("#filter_tabs").find(".active").attr("href").substring(1);
            this.filter.result_for = active_tab;
            var selected_ids = this.AppointmentStatusList.map((item) => {
                return (item.checked == true) ? item.id : null;
            }).filter(n => n);
            this.filter.appointment_status = selected_ids;
            var selected_ids2 = this.BillingStatusList.map((item) => {
                return (item.checked == true) ? item.id : null;
            }).filter(n => n);
            this.filter.billing_status = selected_ids2;
            if(active_tab == 'Appointment'){
                this.onLoadParam = true;
                this.key1 = this.key1+1;
            }else{
                this.key = this.key+1;
            }             
        }
    },
}
</script>