<template>
	<div class="content-wrapper">
		<div class="container-full">
			<div class="content-header">
				<h1 class="heading1 mt-0">Claim Report <a href="#" data-bs-toggle="modal" data-bs-target="#claimdocument" title="Reference of Knowledge Base" v-on:click.prevent="reset()"> <i class="fa fa-question-circle fs-20"></i></a></h1>
			</div>
			<div class="box">
				<div class="box-body">
					<div class="row">
						<div class="col-12 col-sm-12 col-md-12 col-lg-7">
							<selectCheckBox :dataSource='billing_status' labelName="Billing Status" @clicked="selected_billing_status" :displayDiv="displayDiv" :key="select_box_key"/>
							<selectCheckBox :dataSource='office_list' labelName="Office" @clicked="selected_office" :displayDiv="displayDiv" :key="select_box_key"/>
							<selectCheckBox :dataSource='claim_status' labelName="Claim Status" @clicked="selected_claim_status" :displayDiv="displayDiv" :key="select_box_key"/>
							<selectCheckBox :dataSource='appt_profile_list' labelName="Appt. Profile" @clicked="selected_appt_profile_list" :displayDiv="displayDiv" :key="select_box_key"/>
						</div>
						<div class="col-12 col-sm-12 col-md-12 col-lg-5">
							<div class="form-group row mb-0 mt-sm-5">
								<label class="col-form-label mb-0 text-xl-end col-12 col-sm-3 col-md-3 col-lg-12 col-xl-4">Appointment Date</label>
								<div class="col-12 col-sm-9 col-md-9 col-lg-12 col-xl-8">
									<div class="d-flex">
										<div class="availdate">
											<ejs-daterangepicker format='MM-dd-yyyy' id="date_range" :value="date" 
											v-model="filter.date_range" strictMode='strict'
											placeholder="Select Date Range"  @change="onRangeSelect"></ejs-daterangepicker>
										</div>
										<a @click="filter_list" class="waves-effect waves-light btn btn-info mt-xs-5 ms-5"><i class="fa fa-search"></i></a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="box">
				<div class="box-body">
					<div class="row justify-content-between">
						<div class="col-12 col-sm-12 col-md-12 col-lg-10">
							<div class="d-md-flex ">
								<div class="me-2">
									<select-box-static title="Assign Billing Status" divClass="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" labelClass="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" v-model="billing_status_to_change" :option="billing_status_list"></select-box-static>
									<div v-if="update_error_message!=null">
										<div class="text-danger">{{update_error_message}}</div>
									</div>
								</div>
								<div class="mt-md-25">
									<a @click="update_status" class="waves-effect waves-light btn btn-info-light me-1 mt-xs-5">Update <i class="fa fa-pencil-square-o" aria-hidden="true"></i></a>								
									<a @click="upload_batch" class="waves-effect waves-light btn btn-info-light mt-xs-5">Upload Batch <i class="fa fa-cloud-upload" aria-hidden="true"></i></a>
									<div v-if="update_batch_error_message!=null">
										<div class="text-danger">{{update_batch_error_message}}</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-12 col-sm-12 col-md-12 col-lg-2 text-lg-end">
							<div class="mt-lg-25">
								<a @click="export_csv" class="waves-effect waves-light btn btn-info-light m-1">Export <i class="fa fa-download" aria-hidden="true"></i></a>
							</div>
							<div v-if="csv_export_message!=null">
								<div class="text-danger">{{csv_export_message}}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="box">
				<div class="box-body">
					<div class="table-responsive">
						<table class="table table-bordered fs-16">
							<thead>
								<tr class="text-center" rowspan="2">
									<th> <input type="checkbox" class="filled-in chk-col-info" id="selectall" value="selectall" v-model="select_all_claim" @click="selectAllClaim">
									<label for='selectall'></label>
									</th>
									<th>Ref. #</th>
									<th>Patient</th>
									<th>Date of Service</th>
									<th>Claim Amount</th>
									<th colspan="5" title="Patient Responsibility Amount">Pr. Responsibility</th>
									<th title="Adjustment Amount">Adjustment</th>
									<th title="Received from Payer Amount">Received Claim Amt</th>
									<th>Billing Status</th>
									<th>Claim Status</th>
									<th>Posting Status</th>
									<th title="Insurance Balance">Ins. Balance</th>
                                    <th title="Patient Balance">Pr. Balance</th>
								</tr>
								<tr>
									<th></th>
									<th></th>
									<th></th>
									<th></th>
									<th></th>
									<th>Copay</th>
									<th>CoInsurance</th>
									<th>Deductible</th>
									<th>Paid to patient</th>
									<th>Other</th>
									<th></th>
									<th></th>
									<th></th>
									<th></th>
									<th></th>
									<th></th>
									<th></th>
								</tr>
							</thead>
							<slot v-if="list.length>0">
								<tbody v-for="(item, index) in list" :key="index">
									<tr class="text-center" v-bind:class="(item.pending_details!='') ? 'bg-danger-light' : ''">
										<td>
											<input type="checkbox" class="filled-in chk-col-info" :id="'select'+index" @click="select_claim(index)" value="selectall" v-model="item.checked">
											<label :for="'select'+index"></label>
										</td>
										<td> 
											<Popper arrow content="Click Here to see Claim Data" :hover="true" class="helptips cursor-pointer"><a href="#" @click="claim_details_to_send(item.patientID, item.appointment_id)"> {{ item.claim_number.substring(item.claim_number.indexOf('I') + 1) }} </a></Popper>  

											<Popper v-if="item.pending_details!=''" arrow content="Click Here to see Missing Data" :hover="true" class="helptips cursor-pointer"><i class="fa fa-info-circle fs-14" aria-hidden="true" @click="checkDetailLog(item.id)"></i></Popper>

											<!-- if claim is pending due to missing details but details are setup but status not changed -->
											<Popper v-if="item.pending_details=='' && item.claim_status== 'not_claimed_due_to_missing_details'" arrow content="This claim will be processed when the next batch file will be uploaded." :hover="true" class="helptips cursor-pointer"><i class="fa fa-info-circle fs-14" aria-hidden="true"></i></Popper>
										</td>
										<td>
											<Popper arrow content="Click here to view patient chart" :hover="true" class="helptips cursor-pointer">
												<a v-bind:href="'#/patient-chart/'+item.patientUniqueID" class="fs-16 fw-600 text-dark" target="_blank">{{ item.first_name }} {{ item.last_name }}</a>
											</Popper>
										</td>
										<td>
											<Popper arrow content="Click here to view Appointment Details" :hover="true" class="helptips cursor-pointer">
												<a v-bind:href="'#/patient-chart/'+item.patientUniqueID+'/edit-appointment/'+item.appointment_number" class="fs-16 fw-600 text-dark" target="_blank">{{ $filters.displayDateTimeLocal(item.schedule_date,'',false,"ddd, MM-DD-YYYY",true) }} ( #{{ item.appointment_number }} )</a>
											</Popper>
										</td>
										<td>{{ $filters.toUSD(item.claim_amount) }}</td>
										<td>
											<span class="waves-effect waves-light badge badge-danger-light mb-1 me-1 pull-left" style="font-weight: 500;" title="Pending Amount">Pending:
												<slot v-if="item.copay_amt!=0 && item.copay_amt!=null">
													{{ $filters.toUSD(item.copay_amt-item.paid_copay) }}
												</slot> 
												<slot v-else>
													{{$filters.toUSD(item.copay_amt)}}
												</slot>
											</span>
                                        	<span class="waves-effect waves-light badge badge-success-light mb-1 me-1 pull-left" style="font-weight: 500;" title="Pending Amount">Paid: {{ $filters.toUSD(item.paid_copay) }}</span>
										</td>
										<td>
											<span class="waves-effect waves-light badge badge-danger-light mb-1 me-1 pull-left" style="font-weight: 500;" title="Pending Amount">Pending: 
												<slot v-if="item.coinsurance_amt!=0 && item.coinsurance_amt!=null">
													{{ $filters.toUSD(item.coinsurance_amt-item.paid_coinsurance) }}
												</slot> 
												<slot v-else>
													{{$filters.toUSD(item.coinsurance_amt)}}
												</slot>
											</span>
                                        	<span class="waves-effect waves-light badge badge-success-light mb-1 me-1 pull-left" style="font-weight: 500;" title="Pending Amount">Paid: {{ $filters.toUSD(item.paid_coinsurance) }}</span>
										</td>
										<td>
											<span class="waves-effect waves-light badge badge-danger-light mb-1 me-1 pull-left" style="font-weight: 500;" title="Pending Amount">Pending: 
												<slot v-if="item.deductible_amt!=0  && item.deductible_amt!=null">
													{{ $filters.toUSD(item.deductible_amt-item.paid_deductible) }}
												</slot> 
												<slot v-else>
													{{$filters.toUSD(item.deductible_amt)}}
												</slot>
											</span>
                                        	<span class="waves-effect waves-light badge badge-success-light mb-1 me-1 pull-left" style="font-weight: 500;" title="Pending Amount">Paid: {{ $filters.toUSD(item.paid_deductible) }}</span>
										</td>
										<td>
											<span class="waves-effect waves-light badge badge-danger-light mb-1 me-1 pull-left" style="font-weight: 500;" title="Pending Amount">Pending:
												<slot v-if="item.pr_paid_amt!=0 && item.pr_paid_amt!=null">
													{{ $filters.toUSD(item.pr_paid_amt-item.paid_pr_paid) }}
												</slot> 
												<slot v-else>
													{{$filters.toUSD(item.pr_paid_amt)}}
												</slot>
											</span>
                                        	<span class="waves-effect waves-light badge badge-success-light mb-1 me-1 pull-left" style="font-weight: 500;" title="Pending Amount">Paid: {{ $filters.toUSD(item.paid_pr_paid) }}</span>
										</td>
										<td>
											<span class="waves-effect waves-light badge badge-danger-light mb-1 me-1 pull-left" style="font-weight: 500;" title="Pending Amount">Pending:
												<slot v-if="item.other_amt!=0 && item.other_amt!=null">
													{{ $filters.toUSD(item.other_amt-item.paid_other) }}
												</slot> 
												<slot v-else>
													{{$filters.toUSD(item.other_amt)}}
												</slot>
											</span>
                                        	<span class="waves-effect waves-light badge badge-success-light mb-1 me-1 pull-left" style="font-weight: 500;" title="Pending Amount">Paid: {{ $filters.toUSD(item.paid_other) }}</span>
										</td>
										<td>{{ $filters.toUSD(item.adjustment_amount) }}</td>
										<td>{{ $filters.toUSD(item.total_claimed_accepted_amount) }}</td>
										<td> 
											<slot v-if="item.billing_status!=null">
												{{ item.billing_status }}
											</slot>
											<slot v-else>-</slot>
										</td>
										<td>
											{{ capitalizeFirstLetter(item.claim_status.replaceAll("_"," ")) }} 
											<a v-if="item.recent_response != null" href="javascript:void(0);" title="Status Details" @click="displayStatusDetails(item.id)"> <i class="fa fa-info-circle fs-14"></i> </a> 
										</td>
										<td> {{ item.posting_status }} </td>
										<td>
											<slot v-if="item.is_in_eob_batch==1">
												{{ $filters.toUSD(item.eob_insurance_balance) }}
											</slot>
											<slot v-else>
												<slot v-if="item.total_claimed_accepted_amount == 0 && item.patient_responsibility == 0">
													{{ $filters.toUSD(item.claim_amount) }}
												</slot>
												<slot v-else>
													-
												</slot>
											</slot>
										</td>
										<td>
											<slot v-if="item.is_in_eob_batch==1">
												{{ $filters.toUSD(item.eob_patient_balance) }}
											</slot>
											<slot v-else>
												{{ $filters.toUSD(item.patient_balance_amount) }}
											</slot>
										</td>
									</tr>
								</tbody>
							</slot>
							<tbody v-if="list.length==0">
								<tr>
									<td colspan="13" class="text-center"> No data available</td>
								</tr>
							</tbody>
						</table>
					</div> 
				</div>
			</div>
			<div class="modal" tabindex="-1" id="ClaimFieldsDetail">
				<div class="modal-dialog modal-lg">
					<div class="modal-content">
						<div class="modal-header">
							<h3 class="modal-title font-bold m-0" id="claim-data-head">
								Missing Details
							</h3>
							<button type="button" class="btn-close btn-xs" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div class="modal-body" id="ClaimFieldsDetailBody">
							
						</div>
					</div>
				</div>
			</div>
			<div class="modal" tabindex="-1" id="statusDetail">
				<div class="modal-dialog modal-lg">
					<div class="modal-content">
						<div class="modal-header">
							<h3 class="modal-title font-bold m-0">Status Details</h3>
							<button type="button" class="btn-close btn-xs" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div class="modal-body" id="statusDetailBody">
							
						</div>
					</div>
				</div>
			</div>
			<!--document model start-->
			<div id="claimdocument" class="modal fade" tabindex="-1" role="dialog"
				aria-labelledby="myModalLabel" aria-hidden="true">
				<div class="modal-dialog modal-md">
					<div class="modal-content">
						<div class="modal-header">
							<h3 class="modal-title fw-900 mt-0">Reference of Knowledge Base</h3>
						</div>
						<div class="modal-body overflow-auto" style="max-height: 350px;">
							<table class="table table-bordered">
								<thead>
									<tr>
									<th scope="col">No.</th>
									<th scope="col">Knowledge Base Articles</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>1</td>
										<td> <a :href="path+'how-to-check-the-claim-status-of-submitted-claims-in-pureehr'" target="_blank">How To Check The Claim Status Of Submitted Claims In PureEHR?</a></td>
									</tr>
								</tbody>
							</table>
							<Search :key="searchKey"></Search>
						</div>
						<div class="modal-footer">
							<button type="button" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
						</div>
					</div>
				</div>
			</div>
			<!--document model end--> 
		</div>
	</div>
</template>

<script>
	import moment from 'moment';
	import axios from "@/scripts/axios.js";
	import permissionMixins from "@/scripts/mixins/permission.js";
	import submitMixins from "@/scripts/mixins/submitMixins.js";
	import selectCheckBox from "@/components/base/selectCheckBox.vue";
	import Popper from "vue3-popper";
	import SelectBoxStatic from "@/components/base/formFields/SelectBoxStatic.vue";
	import Search from "@/components/Search.vue";

	export default {
		mixins: [permissionMixins,submitMixins],
		components: {
			selectCheckBox,
			Popper,
			SelectBoxStatic,
			Search
		},
		data() {
			return {
				billing_status_to_change: null,
				select_all_claim: false,
				list:[],
				filter:{
					billing_status:['All'],
					office_list:['All'],
					claim_status:['All'],
					appt_profile_list:['All'],
					start_date: moment().subtract(1, 'months').format("YYYY-MM-DD"),
					end_date:moment(new Date()).format("YYYY-MM-DD"),
				},
				billing_status:[],
				billing_status_list:[],
				claim_status:[
					{id:"not_claimed", name: "Not Claimed", checked: true},
					{id:"claimed", name: "Claimed", checked: true},
					{id:"rejected_by_clearing_house", name: "Rejected by Clearing House", checked: true},
					{id:"reclaimed", name: "Reclaimed", checked: true},
					{id:"changed_to_cash", name: "Changed to Cash", checked: true},
					{id:"not_claimed_due_to_missing_details", name: "Not Claimed due to Missing Details", checked: true},
					{id:"remit_received", name: "Remit Received", checked: true},
					{id:"requested_for_void", name: "Requested for void", checked: true},
					{id:"requested_for_resubmission", name: "Requested for resubmission", checked: true},
				],
				office_list:[],
				appt_profile_list:[],
				displayDiv:false,
				select_box_key:0,
				date : '',
				selected_data: [],
				update_error_message: null,
				update_batch_error_message: null,
				csv_export_message: null,
				path: process.env.VUE_APP_KB_DOCUMENT,
				searchKey:0,
			}
		},
		methods: {
			reset() {
				this.searchKey = this.searchKey + 1;
			},
			checkDetailLog(item){
				axios.post("/reports/claim-report-details",{claim_id:item})
				.then((response) => {
					var claim_details = response.data.data
					var missingInsuranceData, missingBillingData, missingPatientData;
					var missingDataArr, missingInsuranceDataArr, missingBillingDataArr, missingPatientDataArr, missingApptBillingData, missingApptBillingDataArr;

					missingDataArr = missingInsuranceDataArr = missingBillingDataArr = missingPatientDataArr =  missingApptBillingDataArr = '';

					if(claim_details.insurance_id_number == null){
						missingInsuranceData=1;
						missingInsuranceDataArr += "<li>Insurance Identity Missing</li>";	
					}
					if(claim_details.insurance_company_id == null){
						missingInsuranceData=1;
						missingInsuranceDataArr += "<li>Insurance Company Details</li>";	
					}
					/*Billing Provider Details*/
					if(claim_details.billing_provider_name==null){
						missingBillingData=1;
						missingBillingDataArr += "<li>Billing Provider Name Missing</li>";	
					}
					if(claim_details.billing_provider_number==null){
						missingBillingData=1;
						missingBillingDataArr += "<li>Billing Provider Phone Number Missing</li>";	
					}
					if(claim_details.billing_provider_address==null){
						missingBillingData=1;
						missingBillingDataArr += "<li>Billing Provider Address Missing</li>";	
					}
					if(claim_details.billing_provider_city==null){
						missingBillingData=1;
						missingBillingDataArr += "<li>Billing Provider City</li>";	
					}
					if(claim_details.billing_provider_state==null){
						missingBillingData=1;
						missingBillingDataArr += "<li>Billing Provider State</li>";	
					}
					if(claim_details.billing_provider_zipcode==null){
						missingBillingData=1;
						missingBillingDataArr += "<li>Billing Provider ZipCode</li>";	
					}
					if(claim_details.billing_provider_npi==null){
						missingBillingData=1;
						missingBillingDataArr += "<li>Billing Provider NPI</li>";	
					}
					if(claim_details.place_of_service==null){
						missingBillingData=1;
						missingBillingDataArr += "<li>Place of service is missing for office '"+claim_details.office_name+"'</li>";		
					}
					/*Billing Provider Details -- end*/
					/*Subscriber Detail*/
					if(claim_details.is_patient_sub != 1){
						if(claim_details.first_name==null){
							missingPatientData = 1;
							missingPatientDataArr += "<li>Subscriber's First name</li>";
						}
						if(claim_details.last_name==null){
							missingPatientData = 1;
							missingPatientDataArr += "<li>Subscriber's Last name</li>";
						}
						if(claim_details.address==null){
							missingPatientData = 1;
							missingPatientDataArr += "<li>Subscriber's Address</li>";
						}	
						if(claim_details.city==null){
							missingPatientData = 1;
							missingPatientDataArr += "<li>Subscriber's City</li>";
						}
						if(claim_details.state_id==null){
							missingPatientData = 1;
							missingPatientDataArr += "<li>Subscriber's Statee</li>";
						}
						if(claim_details.zip_code==null){
							missingPatientData = 1;
							missingPatientDataArr += "<li>Subscriber's ZipCode</li>";
						}
						if(claim_details.date_of_birth==null){
							missingPatientData = 1;
							missingPatientDataArr += "<li>Subscriber's Date of Birth</li>";
						}
						
					}else{
						if(claim_details.subscriber_first_name==null){
							missingPatientData = 1;
							missingPatientDataArr += "<li>Subscriber's First name</li>";
						}
						if(claim_details.subscriber_last_name==null){
							missingPatientData = 1;
							missingPatientDataArr += "<li>Subscriber's Last name</li>";
						}
						if(claim_details.subscriber_address==null){
							missingPatientData = 1;
							missingPatientDataArr += "<li>Subscriber's Address</li>";
						}
						if(claim_details.subscriber_zip_code==null){
							missingPatientData = 1;
							missingPatientDataArr += "<li>Subscriber's ZipCode</li>";
						}
						if(claim_details.subscriber_city==null){
							missingPatientData = 1;
							missingPatientDataArr += "<li>Subscriber's City</li>";
						}
						if(claim_details.subscriber_state_id==null){
							missingPatientData = 1;
							missingPatientDataArr += "<li>Subscriber's State</li>";
						}
						if(claim_details.subscriber_dob==null){
							missingPatientData = 1;
							missingPatientDataArr += "<li>Subscriber's Date of Birth</li>";
						}
					}
					/*Subscriber Detail -- End*/
					/*Appointment Detail*/
					if(claim_details.charges==null || claim_details.charges.replace(/\s+/g, '')==''){
						missingApptBillingData=1;
						missingApptBillingDataArr += "<li>Charges is missing for cpt code.</li>"
					}
					if(claim_details.cpt_code==null || claim_details.cpt_code.replace(/\s+/g, '')==''){
						missingApptBillingData=1;
						missingApptBillingDataArr += "<li>CPT Code is missing.</li>"
					}
					if(claim_details.icd10_code==null || claim_details.icd10_code.replace(/\s+/g, '')==''){
						missingApptBillingData=1;
						missingApptBillingDataArr += "<li>ICD10 Code is missing.</li>"
					}
					if(claim_details.pointers==null || claim_details.pointers.replace(/\s+/g, '')==''){
						missingApptBillingData=1;
						missingApptBillingDataArr += "<li>CPT Code pointers are missing.</li>"
					}
					if(claim_details.schedule_date==null || claim_details.schedule_date.replace(/\s+/g, '')==''){
						missingApptBillingData=1;
						missingApptBillingDataArr += "<li>Schedule Date is missing.</li>"
					}
					if(claim_details.units==null || claim_details.units.replace(/\s+/g, '')==''){
						missingApptBillingData=1;
						missingApptBillingDataArr += "<li>CPT Code unit is missing.</li>"
					}
					/*Appointment Detail -- End*/
					if(missingInsuranceData==1){
						missingDataArr += "<h3 class='fw-800'>Patient's Insurance Detail <a href='#/patient-chart/"+claim_details.patientUniqueID+"/insuranceinfo' target='_blank' title='Click here to add missing details' class='waves-effect waves-light btn btn-info-light btn-xs ms-2 float-end'><i class='fa fa-pencil'></i></a></h3>";
						missingDataArr += '<ul>';
						missingDataArr += missingInsuranceDataArr;	
						missingDataArr += '</ul>';
					}
					

					if(missingBillingData==1){
						missingDataArr += "<hr><h3 class='fw-800'>Patient's Billing Detail</h3>";
						missingDataArr += '<ul>';
						missingDataArr += missingBillingDataArr;
						missingDataArr += '</ul>';
					}


					if(missingPatientData==1){
						missingDataArr += "<hr><h3 class='fw-800'>Patient's Detail <a href='#/patient-chart/"+claim_details.patientUniqueID+"' title='Click here to add missing details' target='_blank' class='waves-effect waves-light btn btn-info-light btn-xs ms-2 float-end'><i class='fa fa-pencil'></i></a> </h3>";
						missingPatientDataArr += '<ul>';
						missingDataArr += missingPatientDataArr;
						missingDataArr += '</ul>';
					}
					
					if(missingApptBillingData==1){
						missingDataArr += "<hr><h3 class='fw-800'>Appointment Billing Detail <a href='#/patient-chart/"+claim_details.patientUniqueID+"/edit-appointment/"+claim_details.appointment_number+"/billing' title='Click here to add missing details' target='_blank' class='waves-effect waves-light btn btn-info-light btn-xs ms-2 float-end'><i class='fa fa-pencil'></i></a> </h3>";
						missingApptBillingDataArr += '<ul>';
						missingDataArr += missingApptBillingDataArr;
						missingDataArr += '</ul>';
					}
					$("#claim-data-head").html("Missing Details");
					$("#ClaimFieldsDetailBody").html(missingDataArr);
					$("#ClaimFieldsDetail").modal("show");
				});
			},
			claim_details_to_send(patient_id, appointment_id){
				var reqiredArr = [1,2,3,6,7,8,9,12,13,14,15,17,18,19,20,21,38,39,64,76,80,81,82,98,99,101,102,103,104,107,114,116,118,120,121,122];
				var background_color;
				var background_color2;
				axios.post("/reports/data/claim-billing-data-to-send", {'patient_id': patient_id, 'appointment_id': appointment_id})
					.then((response) => {
						var loop = response.data.data.all_fields;
						var tableData = background_color = '';
						var i = 0;
						tableData = '<table class="table table-bordered"><thead><tr><th>Field</th><th>Value</th></tr></thead><tbody>';								
							
						$.each( loop, function(index, value){
							var lbl = index.split("_");
							lbl = lbl.join(" ");
							if(jQuery.inArray(i, reqiredArr) !== -1 && (value=="null" || value==null || value===null || value=='')){
								background_color = "bg-danger-light";
							}else{
								background_color = '';
							}

							if(index != "clinic_id" && index != "patientID" && index != "patient_id" && index != "appointment_id"){
								tableData += '<tr class="'+background_color+'"><th>'+(i+1)+". "+lbl.toUpperCase()+'</th><td>'+(($.type(value) === null || $.type(value) === "null") ? '-' : value) +'</td></tr>';
							}
							i++;
						});

						tableData += '</tbody></table>';

						var loop2 = response.data.data.required_fields;
						var tableData2 = background_color2 = '';
						var i2 = 0;

						tableData2 = '<table class="table table-bordered"><thead><tr><th>Field</th><th>Value</th></tr></thead><tbody>';
						$.each( loop2, function(index, value){
							var lbl = index.split("_");
							lbl = lbl.join(" ");							
							if(value=="null" || value==null || value===null|| value==''){
								background_color2 = "bg-danger-light";
							}else{
								background_color2 = '';
							}

							if(index != "clinic_id" && index != "patientID" && index != "patient_id" && index != "appointment_id"){
								tableData2 += '<tr class="'+background_color2+'"><th>'+(i2+1)+". "+lbl.toUpperCase()+'</th><td>'+(($.type(value) === null || $.type(value) === "null") ? '-' : value) +'</td></tr>';
							}
							i2++;
						});

						tableData2 += '</tbody></table>';

						var view = '<ul class="nav nav-tabs mt-0 mb-20 text-center text-sm-start" role="tablist"><li class="nav-item"> <a class="nav-link active" data-bs-toggle="tab" href="#Requiredfield" role="tab">Required Field</a> </li><li class="nav-item"> <a class="nav-link" data-bs-toggle="tab" href="#allfield" role="tab">All Field</a> </li></ul><div class="tab-content"> <div class="tab-pane active" id="Requiredfield" role="tabpanel">'+tableData2+'</div> <div class="tab-pane" id="allfield" role="tabpanel"> '+tableData+' </div></div>';

						$("#ClaimFieldsDetailBody").html(view);
						$("#claim-data-head").html("Claim 837 data <small class='d-block text-danger'><i class='fa fa-circle fs-14'></i> Indicates missing required fields</small>");
						$("#ClaimFieldsDetail").modal("show");
					})
			},
			select_claim(index){
				if(this.list[index].claim_status == 'claimed' || this.list[index].claim_status == 'rejected_by_clearing_house' || this.list[index].claim_status == 'reclaimed' || this.list[index].claim_status == 'changed_to_cash' || this.list[index].claim_status == 'remit_received'){
					this.$swal.fire({
						title: 'Sorry!',
						text: 'Selected Records are already being claimed.',
						type: 'warning'
					});
					setTimeout(() => {
						this.list[index].checked = false;						
					}, 500);
				}
			},
			filter_list() {
				this.$store.state.loader = true;
				axios.post("/reports/claim-report",{filter:this.filter})
					.then((response) => {
						this.list = response.data.data;
						this.$store.state.loader = false;
						this.getpendingflag();
					});
			},
			getpendingflag(){
				var claim_ids = this.list.map((item) => {
					return  item.id;
				}).filter(n => n);
				axios.post("/reports/claim-pending-flag",{claim_ids:claim_ids})
				.then((response) => {
					response.data.data.map((item,index) => {
						this.list[index].pending_details = item.pending_details;
					});
				});
			},
			claim_statusChange(args){
				this.filter.claim_status = args.value
			},
			selected_office(result){
				this.office_list = result;
				this.filter.office_list = this.office_list.map((item) => {
					return (item.checked == true) ? item.id : null;
				});
				this.filter.office_list = this.filter.office_list.filter(n => n)

				var selected_count = this.office_list.map((item) => {
					return (item.checked == true) ? item.id : null;
				}).filter(n => n).length;
				var all_count = this.office_list.length;
				if(selected_count == all_count){
					this.filter.office_list = ['All'];
				}
			},
			selected_billing_status(result){
				this.billing_status = result;
				this.filter.billing_status = this.billing_status.map((item) => {
					return (item.checked == true) ? item.id : null;
				});
				this.filter.billing_status = this.filter.billing_status.filter(n => n)

				var selected_count = this.billing_status.map((item) => {
					return (item.checked == true) ? item.id : null;
				}).filter(n => n).length;
				var all_count = this.billing_status.length;
				if(selected_count == all_count){
					this.filter.billing_status = ['All'];
				}
			},
			selected_claim_status(result){
				this.claim_status = result;
				this.filter.claim_status = this.claim_status.map((item) => {
					return (item.checked == true) ? item.id : null;
				});
				this.filter.claim_status = this.filter.claim_status.filter(n => n)

				var selected_count = this.claim_status.map((item) => {
					return (item.checked == true) ? item.id : null;
				}).filter(n => n).length;
				var all_count = this.claim_status.length;
				if(selected_count == all_count){
					this.filter.claim_status = ['All'];
				}
			},
			selected_appt_profile_list(result){
				this.appt_profile_list = result;
				this.filter.appt_profile_list = this.appt_profile_list.map((item) => {
					return (item.checked == true) ? item.id : null;
				});
				this.filter.appt_profile_list = this.filter.appt_profile_list.filter(n => n)

				var selected_count = this.appt_profile_list.map((item) => {
					return (item.checked == true) ? item.id : null;
				}).filter(n => n).length;
				var all_count = this.appt_profile_list.length;
				if(selected_count == all_count){
					this.filter.appt_profile_list = ['All'];
				}
			},
			onRangeSelect() {
				var daterange = document.getElementById("date_range").value;
				if(daterange != ''&& daterange != null){
					var date = daterange.split(' - ');
					this.filter.start_date = moment(String(date[0])).format('YYYY-MM-DD');
					this.filter.end_date = moment(String(date[1])).format('YYYY-MM-DD');
				}else{
					this.filter.start_date = null;
					this.filter.end_date = null;
				}
			},
			capitalizeFirstLetter(string) {
				return string.charAt(0).toUpperCase() + string.slice(1);
			},
			displayStatusDetails(id){
				axios.post("/reports/data/get-claim-history",{id:id})
				.then((res) => {
					$("#statusDetail").modal("show");
					$("#statusDetailBody").html(res.data.data);
				});
			},
			selectAllClaim(){
				var selected_ids = this.list.map((item) => {
					return (item.checked == true && (item.claim_status == 'claimed' || item.claim_status == 'rejected_by_clearing_house' || item.claim_status == 'reclaimed' || item.claim_status == 'changed_to_cash' || item.claim_status == 'remit_received')) ? item.id : null;
				}).filter(n => n);	
				if(selected_ids>0){
					this.$swal.fire({
						title: 'Sorry!',
						text: 'Some of the selected records are already being claimed.',
						type: 'warning'
					});
					setTimeout(() => {
						this.list = this.list.map(item => {
							return {...item, checked: false };
						});
						this.select_all_claim = false;
					}, 500);
				}else{
					if(this.select_all_claim){
						this.list = this.list.map(item => {
							return {...item, checked: false };
						});
					}else{
						this.list = this.list.map(item => {
							return {...item, checked: true };
						});
					}
				}
			},
			update_status(){
				var selected_ids = this.list.map((item) => {
					return (item.checked == true) ? item.appointment_id : null;
				}).filter(n => n);				
				if(selected_ids.length > 0 && this.billing_status_to_change != null){
					this.$store.state.loader = true;
					axios.post("/claim/changeClaimstatus",{appointment_id:selected_ids,billing_status:this.billing_status_to_change})
					.then((response) => {
						this.moshaToast("Record updated successfully.", "success");
						this.filter_list();
						this.select_all_claim = false;
						this.$store.state.loader = false;
					});
				}else{
					if(this.billing_status_to_change == null){
						this.update_error_message = "Please select billing status to update.";
					}else{
						this.update_error_message = "Please select atleast one claim to update.";
					}
					setTimeout(() => {
						this.update_error_message = null;
					}, 5000);
				}
			},
			upload_batch(){
				var selected_ids = this.list.map((item) => {
					return (item.checked == true) ? item.appointment_id : null;
				}).filter(n => n);				
				var data = {dataarray : []}
				this.list.forEach((element) => {
					if(element.checked == true){
						data.dataarray.push({appointment_id:element.appointment_id, claim_id:element.id});
					}
				});
				if(selected_ids.length > 0){
					this.$store.state.loader = true;
					axios.post("/claim/claimbatch",data)
					.then((response) => {
						this.moshaToast("Batch uploaded successfully.", "success");
						this.filter_list();
						this.select_all_claim = false;
						this.$store.state.loader = false;
					});
				}else{
					this.update_batch_error_message = "Please select atleast one claim to upload.";
					setTimeout(() => {
						this.update_batch_error_message = null;
					}, 5000);
				}
			},
			export_csv(){
				var data = {dataarray : []}
				this.list.forEach((element) => {					
					data.dataarray.push({appointment_id:element.appointment_id, claim_id:element.id});
				});
				this.$store.state.loader = true;
				axios.post("/claim/generateCSV",data)
				.then((response) => {
					this.csv_export_message = "Please find your file in Download Logs Section.";
					this.$store.state.loader = false;
					setTimeout(() => {
						this.csv_export_message = null;
					}, 8000);
				});
				// this.$swal.fire({
				// 	title: 'Please confirm!!',
				// 	type: 'info',
				// 	showCancelButton: true,
				// 	confirmButtonColor: "#DD6B55",
				// 	confirmButtonText: "Export All",
				// 	cancelButtonText: "Export Current Page",
				// }).then((result) => {
				// 	if(result.isConfirmed){
						
				// 	}else{
				// 		var selected_ids = this.list.map((item) => {
				// 			return (item.checked == true) ? item.appointment_id : null;
				// 		}).filter(n => n);				
				// 		var data = {dataarray : []}
				// 		this.list.forEach((element) => {
				// 			if(element.checked == true){
				// 				data.dataarray.push({appointment_id:element.appointment_id, claim_id:element.id});
				// 			}
				// 		});
				// 		if(selected_ids.length > 0){
				// 			this.$store.state.loader = true;
				// 			axios.post("/claim/generateCSV",data)
				// 			.then((response) => {
				// 				this.csv_export_message = "Please find your file in Download Logs.";
				// 				this.$store.state.loader = false;
				// 				setTimeout(() => {
				// 					this.csv_export_message = null;
				// 				}, 8000);
				// 			});
				// 		}else{
				// 			this.update_batch_error_message = "Please select atleast one claim to upload.";
				// 			setTimeout(() => {
				// 				this.update_batch_error_message = null;
				// 			}, 5000);
				// 		}
				// 	}
				// });
			},
		},
		mounted() {
			this.filter_list();
			var $this = this;
			document.body.addEventListener("click", function (evt) {
				if($(evt.target).closest('.selectCheckBox').length){
				}else{
					$this.displayDiv=false;
					$this.select_box_key = $this.select_box_key+1;
				}
			});
			this.date = moment().subtract(1, 'months').format("MM-DD-YYYY")+' - '+moment(new Date()).format("MM-DD-YYYY");
		},
		async beforeCreate() {
			axios.get("/json_dropdown/claim-filter-list")
			.then((response) => {
				this.office_list = response.data.data.office_list.map(obj=> ({ ...obj, checked: true }));
				this.filter.office_list = ['All'];

				this.appt_profile_list = response.data.data.appt_profile.map(obj=> ({ ...obj, checked: true }));
				this.appt_profile_list.unshift({id:'null', name: 'No Profile or archived', checked: true})
				this.filter.appt_profile_list = ['All'];
			});	
			axios.get("/json_dropdown/get-billing-status")
			.then((response) => {
				this.billing_status = response.data.data.map((item) => {
					return { id: item.id, name: item.name, checked: true };
				});   
				this.billing_status_list = response.data.data.map((item) => {
					return { value: item.id, title: item.name };
				});    
			});
		},
	}
</script>

<style>
	@import "../../../node_modules/@syncfusion/ej2-base/styles/material.css";
	@import "../../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
	@import "../../../node_modules/@syncfusion/ej2-vue-dropdowns/styles/material.css";
	@import "../../../node_modules/@syncfusion/ej2-buttons/styles/material.css";
</style>