import {
    helpers,
    required,
    email,
    minLength,
    maxLength,
    sameAs,
    numeric,
  } from "@vuelidate/validators";
  
  const Medication = {
    namespaced: true,
  
    state: {
      tableData: {
        header: [
          "Medication Code",
          "Medication Description",          
          "Negation",
          "Is Order",
          "Actions",
        ],
      },
      form: {},
      vuelidateExternalResults: {
        form: {
            product_code:[],
            product_name:[],
            negationInd:[],
            status:[],
        },
      },
      validationRules: {
        form: {           
            // product_code:{
            //     required: helpers.withMessage("Medication Code is required", required), 
            // },
            // product_name:{
            //     required: helpers.withMessage("Medication Name is required", required), 
            // }
        },
      },
      listUrl: "/patient/:patient_unique_id/cqm-medication/list",
      list: [],
      edit: 0,
    },
    getters: {
      getTableHeaders: (state) => state.header,
      getTableContents: (state) => state.list,
      getListURL: (state) => state.listUrl,
    },
    mutations: {},
    actions: {},
  }
  
  export default Medication;
  