<template>
	<div class="content-wrapper">
		<div class="container-full">
			<div class="content-header">
				<h1 class="heading1 mt-0">Bulk Appointment Scheduler <a href="#" data-bs-toggle="modal" data-bs-target="#bulkappdocument" title="Reference of Knowledge Base" v-on:click.prevent="reset()"> <i class="fa fa-question-circle fs-20"></i></a></h1>
			</div>
			<section class="content">
				<div class="row justify-content-sm-center">
					<div class="col-12 col-sm-12 col-md-12 col-lg-6">
						<div class="box">
							<div class="box-body">
								<div class="pageheading mb-30">
									<h2 class="heading2 mt-0">Patients</h2>
								</div>
								<div class="form-group row" :class="{ error: v$.form.patient_id.$errors.length }">
									<label class="col-form-label col-12 col-sm-4 col-md-5 col-lg-4 mb-0">Select Patient(s)</label>
									<div class="col-12 col-sm-8 col-md-7 col-lg-8">
										<div class="lookup patients-search w-p100 patients-input">
											<Autocomplete max="30" 
												@input="getlist" 
												ref="autocomplete" 
												:results="result" 
												@onSelect="resultsvalue" 
												class="form-control ms-0 autosearch-list" 
												placeholder="Search"
												:use-html-for-results ="true" >
											</Autocomplete>
											<div class="invalid-feedback" style="display:block;" v-for="(error, index) of v$.form.patient_id.$errors" :key="index"> {{ error.$message }} </div>
										</div>
									</div>
								</div>
								<div class="pageheading mt-20 mb-10">
									<h2 class="heading2 mt-0">Arrange Patients</h2>
								</div>

								<draggable tag="ul" :list="patient" class="todo-list ui-sortable mb-20 w-p100" handle=".handle" item-key="name">
									<template #item="{ element, index }">
										<li class="py-1 px-0 b-0" :key="index">
											<span class="box p-10 mb-0 d-block border-light">
												<span class="fs-18 fw-400 me-10 float-start">
													<span class="float-start" style="cursor: move;">
														<i class="fa fa-ellipsis-v handle fw-100 fs-14 text-mute me-0"></i>
														<i class="fa fa-ellipsis-v handle fw-100 fs-14 text-mute me-10"></i>
													</span>

													 {{ element.name }} </span>
												<span class="d-inline-block text-danger" style="margin-top: 2px;cursor:pointer;" @click="removepatient(index)">
													<i class="fa fa-trash-o"></i>
												</span>
											</span>
										</li>
									</template>
								</draggable>

							</div>
						</div>
					</div>
					<div class="col-12 col-sm-12 col-md-12 col-lg-6">
						<div class="box">
							<div class="box-body">
								<div class="pageheading mb-30">
									<h2 class="heading2 mt-0">Schedule Appointment</h2>
								</div>
								
								<div class="form-group row" :class="{ error: v$.form.office_id.$errors.length }">
									<label class="col-form-label col-12 col-sm-4 col-md-5 col-lg-4 text-sm-end mb-0">Office <small class="text-danger asterisksign">*</small></label>
									<div class="col-12 col-sm-8 col-md-7 col-lg-8">
										<select class="form-control" v-model="form.office_id" @change="OfficeChange">
											<option value="" selected="selected" disabled="disabled">Select Office</option>
											<option v-for="(office,index) of offices" :value="office.id" :key="index">{{office.name}}</option>
										</select>
										<div class="invalid-feedback" style="display:block;" v-for="(error, index) of v$.form.office_id.$errors" :key="index"> {{ error.$message }} </div>
									</div>
								</div>

								<div class="form-group row" :class="{ error: v$.form.provider_id.$errors.length }">
									<label class="col-form-label col-12 col-sm-4 col-md-5 col-lg-4 text-sm-end mb-0">Provider <small class="text-danger asterisksign">*</small></label>
									<div class="col-12 col-sm-8 col-md-7 col-lg-8">
										<select class="form-control"  v-model="form.provider_id" ref="selectedProvider" @change="superviserCheck">
											<option value="" disabled="disabled">Select Provider</option>
											<option v-for="(provider,index) of providers" :value="provider.id" :data-is_supervisor="provider.is_supervisor" :key="index" :disabled="provider.disable == true">{{provider.name}}</option>
										</select>  
										<div class="invalid-feedback" style="display:block;" v-for="(error, index) of v$.form.provider_id.$errors" :key="index"> {{ error.$message }} </div>
									</div>
								</div>

								<div class="form-group row" >
									<label class="col-form-label col-12 col-sm-4 col-md-5 col-lg-4 text-sm-end mb-0">Supervising Manager <small v-if="$store.state.is_supervisor_required" class="text-danger asterisksign">*</small></label>
									<div class="col-12 col-sm-8 col-md-7 col-lg-8">
										<select class="form-control" v-model="form.supervisor_id">
											<option value="" disabled>Select Supervising Manager</option>
											<option v-for="(superprovider,index) of superproviders" :value="superprovider.id" :key="index">{{superprovider.name}}</option>
										</select>  
										<div class="invalid-feedback" style="display:block;" v-for="(error, index) of v$.form.supervisor_id.$errors" :key="index"> {{ error.$message }} </div>
									</div>
								</div>

								<div class="form-group row">
									<label class="col-form-label col-12 col-sm-4 col-md-5 col-lg-4 text-sm-end mb-0">Exam Room</label>
									<div class="col-12 col-sm-8 col-md-7 col-lg-8">
										<select class="form-control" v-model="form.room_id">
											<option value="" disabled>Select Exam Room</option>
											<option v-for="(room,index) of rooms" :value="room.id" :key="index">{{room.name}}</option>
										</select>
									</div>
								</div>

								<div class="form-group row">
									<label class="col-form-label col-12 col-sm-4 col-md-5 col-lg-4 text-sm-end mb-0">Visit Profile</label>
									<div class="col-12 col-sm-8 col-md-7 col-lg-8">
										<select class="form-control me-2 h-36" v-model="form.appointment_profile_id" @change="onChangeAppointmentProfile">
											<option value="" disabled>Select Visit Profile</option>
											<option v-for="(appointmentprofile,index) of appointmentprofiles" :value="appointmentprofile.id" :key="index" :data-color_code="appointmentprofile.color_code" :data-default_duration="appointmentprofile.default_duration" :data-name="appointmentprofile.name" :data-consent_form_ids="appointmentprofile.consent_form_ids">{{appointmentprofile.name}}</option>
										</select>
									</div>
								</div>

								<div class="form-group row">
									<label class="col-form-label col-12 col-sm-4 col-md-5 col-lg-4 text-sm-end mb-0">Color</label>
									<div class="col-12 col-sm-8 col-md-7 col-lg-8">
										<ejs-colorpicker :value="default_color" id="inline-color-palette" mode="Palette" :modeSwitcher="true" :inline="false" :showButtons="false" :change="onChangeColor" style="width: 100%;"></ejs-colorpicker>
									</div>
								</div>

								<div class="form-group row" :class="{ error: v$.form.duration.$errors.length }">
									<label class="col-form-label col-12 col-sm-4 col-md-5 col-lg-4 text-sm-end mb-0">Duration <small class="text-danger asterisksign">*</small></label>
									<div class="col-12 col-sm-8 col-md-7 col-lg-8 availdate position-relative">
										<ejs-numerictextbox format="###" id="Duration" ref="numeric_instance" :value="duration_value" :step="5" :min="5" :max="60" @change="durationChange"></ejs-numerictextbox>
										<small class="form-text text-muted position-absolute fs-14" style="right: 70px; top: 2px;">Minutes</small>
										<div class="invalid-feedback" style="display:block;" v-for="(error, index) of v$.form.duration.$errors" :key="index"> {{ error.$message }} </div>
									</div>
								</div>

								<div class="form-group row">
									<label class="col-form-label col-12 col-sm-4 col-md-5 col-lg-4 text-sm-end mb-0">Consent Forms</label>
									<div class="col-12 col-sm-8 col-md-7 col-lg-8 availdate">
										<ejs-multiselect id='multiselect' @change="consentformsChange" :dataSource='consentforms' :value="selected_forms" :fields='default_fields' :mode='boxMode' placeholder="Select Consent Forms"></ejs-multiselect>
									</div>
								</div>

								<div class="form-group row" :class="{ error: v$.form.reason_for_visit.$errors.length }">
									<label class="col-form-label col-12 col-sm-4 col-md-5 col-lg-4 text-sm-end mb-0">Reason <small class="text-danger asterisksign">*</small></label>
									<div class="col-12 col-sm-8 col-md-7 col-lg-8">
										<textarea name="textarea" rows="2" v-model="form.reason_for_visit" id="reason_for_visit" class="form-control" placeholder="Reason"></textarea>
										<div class="invalid-feedback" style="display:block;" v-for="(error, index) of v$.form.reason_for_visit.$errors" :key="index"> {{ error.$message }} </div>
									</div>
								</div>

								<div class="form-group row" :class="{ error: v$.form.schedule_time.$errors.length }">
									<label class="col-form-label col-12 col-sm-4 col-md-5 col-lg-4 text-sm-end mb-0">Start Time <small class="text-danger asterisksign">*</small></label>
									<div class="col-12 col-sm-8 col-md-7 col-lg-8 availdate">
										<ejs-datetimepicker id="schedule_time" placeholder="mm-dd-yyyy, --:-- --" :value="schedule_time" :showClearButton='false' :format="dateFormat" @change="scheduleDate" step="15" :openOnFocus='true'></ejs-datetimepicker>
										<div class="invalid-feedback" style="display:block;" v-for="(error, index) of v$.form.schedule_time.$errors" :key="index"> {{ error.$message }} </div>
									</div>
								</div>

								<div class="form-group row" :class="{ error: v$.form.schedule_end_time.$errors.length }">
									<label class="col-form-label col-12 col-sm-4 col-md-5 col-lg-4 text-sm-end mb-0">End Time <small class="text-danger asterisksign">*</small></label>
									<div class="col-12 col-sm-8 col-md-7 col-lg-8 availdate">
										<ejs-datetimepicker id="schedule_end_time" placeholder="mm-dd-yyyy, --:-- --" :value="schedule_end_time" :showClearButton='false' :format="dateFormat" @change="scheduleEndDate" step="15" :openOnFocus='true'></ejs-datetimepicker>
										<div class="invalid-feedback" style="display:block;" v-for="(error, index) of v$.form.schedule_end_time.$errors" :key="index"> {{ error.$message }} </div>
									</div>
								</div>
							  
								<div class="form-group row">
									<label class="col-form-label col-12 col-sm-4 col-md-5 col-lg-4 text-sm-end mb-0">Note</label>
									<div class="col-12 col-sm-8 col-md-7 col-lg-8">
										<textarea name="textarea" rows="2" v-model="form.extra_notes" id="extra_notes" class="form-control" placeholder="Notes"></textarea>
									</div>
								</div>

								<small class="text-danger">{{this.errormessage}}</small>
								<!-- <div class="form-group row">
									<label class="col-12 col-sm-4 col-md-5 col-lg-4">Enable Group Booking</label>
									<div class="col-12 col-sm-8 col-md-7 col-lg-8">
										<input type="checkbox" id="basic_checkbox_2" class="filled-in chk-col-info" />
										<label for="basic_checkbox_2" class="fs-14">Schedule multiple appointments in the same time range</label>
									</div>
								</div> -->

								<!-- <div class="form-group align-items-center">
									<input type="checkbox" id="checkbox_1" value="single" class="filled-in chk-col-info check_box">
									<label for="checkbox_1" class="mb-0 mt-2">Recurring Appointment</label>
								</div>
								<fieldset class="table_filters">
									<div class="form-group row align-items-center">
										<div class="col-12 col-sm-4 col-md-5 col-lg-4">
											<label class="mb-0">Weekdays</label>
										</div>
										<div class="col-12 col-sm-8 col-md-7 col-lg-8">
											<input type="checkbox" id="checkbox_2" value="single" class="filled-in chk-col-info">
											<label for="checkbox_2" class="mb-0 mt-5 me-2">Sun</label>
											<input type="checkbox" id="checkbox_3" value="single" class="filled-in chk-col-info">
											<label for="checkbox_3" class="mb-0 mt-5 me-2">Mon</label>
											<input type="checkbox" id="checkbox_4" value="single" class="filled-in chk-col-info">
											<label for="checkbox_4" class="mb-0 mt-5 me-2">Tue</label>
											<input type="checkbox" id="checkbox_5" value="single" class="filled-in chk-col-info">
											<label for="checkbox_5" class="mb-0 mt-5 me-2">Wed</label>
											<input type="checkbox" id="checkbox_6" value="single" class="filled-in chk-col-info">
											<label for="checkbox_6" class="mb-0 mt-5 me-2">Thu</label>
											<input type="checkbox" id="checkbox_7" value="single" class="filled-in chk-col-info">
											<label for="checkbox_7" class="mb-0 mt-5 me-2">Fri</label>
											<input type="checkbox" id="checkbox_8" value="single" class="filled-in chk-col-info">
											<label for="checkbox_8" class="mb-0 mt-5 me-2">Sat</label>
										</div>
									</div>
									<div class="form-group row align-items-center">
										<div class="col-12 col-sm-4 col-md-5 col-lg-4">
											<label class="mb-0">Recurs Every</label>
										</div>
										<div class="col-12 col-sm-8 col-md-7 col-lg-8">
											<input type="text" name="text" class="form-control" placeholder="Recurs Every">
										</div>
									</div>
									<div class="form-group row">
										<div class="col-12 col-sm-4 col-md-5 col-lg-4">
											<label class="mb-0">Last Appointment</label>
										</div>
										<div class="col-12 col-sm-8 col-md-7 col-lg-8 LA_never">
											<input class="form-control" type="date" name="date">
											<input type="checkbox" id="checkbox_9" value="single" class="filled-in chk-col-info">
											<label for="checkbox_9" class="mb-0 mt-5 me-2">Never Ends</label>
										</div>
										<div class="col-12 col-sm-4 col-md-5 col-lg-3">
										</div>
									</div>
								</fieldset> -->

								<button @click="submitForm" class="waves-effect waves-light btn btn-info float-end">Schedule Appointments</button>

							</div>
						</div>
					</div>
				</div>
			</section>
			<!--document model start-->
			<div id="bulkappdocument" class="modal fade" tabindex="-1" role="dialog"
				aria-labelledby="myModalLabel" aria-hidden="true">
				<div class="modal-dialog modal-md">
					<div class="modal-content">
						<div class="modal-header">
							<h3 class="modal-title fw-900 mt-0">Reference of Knowledge Base</h3>
						</div>
						<div class="modal-body overflow-auto" style="max-height: 350px;">
							<table class="table table-bordered">
								<thead>
									<tr>
										<th scope="col">No.</th>
										<th scope="col">Knowledge Base Articles</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>1</td>
										<td> <a :href="path+'how-to-schedule-bulk-appointments-in-pureehr'" target="_blank">How To Schedule Bulk Appointments In PureEHR?</a></td>
									</tr>
								</tbody>
							</table>
							<Search :key="searchKey"></Search>
						</div>
						<div class="modal-footer">
							<button type="button" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
						</div>
					</div>
				</div>
			</div>
      		<!--document model end-->
		</div>
	</div>
</template>
<script>
import axios from "@/scripts/axios.js";
import $ from "jquery";
import useVuelidate from "@vuelidate/core";
import moment from 'moment';
import submitMixins from "@/scripts/mixins/submitMixins.js";
import Autocomplete from "vue3-autocomplete";
import draggable from 'vuedraggable';
import Search from "@/components/Search.vue";
export default {
	mixins: [submitMixins],
	setup: () => {
		return { v$: useVuelidate() };
	},
	components: { Autocomplete,draggable, Search },
	data() 
	{
		return {
			result:[],
			patient:[],
			offices:[],
			providers:[],
			superproviders:[],
			accessproviders:[],
			rooms:[],
			appointmentprofiles:[],
			default_color:'',
			duration_value:'',
			selected_forms:'',
			duration_value:5,
			consentforms: [],
			selected_forms: [],
			default_fields : { text: 'name', value: 'id' },
			boxMode: 'Box',
			schedule_time : '',
			dateFormat : 'MM-dd-yyyy hh:mm a',
			schedule_end_time: '',
			errormessage:'',
			path: process.env.VUE_APP_KB_DOCUMENT,
			searchKey:0,
		}
	},
	async beforeCreate(){
		this.$store.state.BulkAppointments.form.office_id="";
		this.$store.state.BulkAppointments.form.provider_id="";
		this.$store.state.BulkAppointments.form.supervisor_id="";
		this.$store.state.BulkAppointments.form.room_id="";
		this.$store.state.BulkAppointments.form.is_provider_occupied=true;
		this.$store.state.BulkAppointments.form.appointment_profile_id="";
		this.$store.state.BulkAppointments.form.type="Office";        
		this.$store.state.BulkAppointments.form.status="1";  
		this.$store.state.BulkAppointments.form.duration="5";        
	  
		axios.post("/json_dropdown/get-user-office")
		.then((response) => (
			this.offices = response.data.data
		));

		axios.post("/get-user-provider")
		.then((response) => {
			this.accessproviders = response.data.data; 
		});

		axios.get("/json_dropdown/appointment-profile")
		.then((response) => {
			this.appointmentprofiles = response.data.data;            
		});        
		axios.get("/json_dropdown/consent-form")
		.then((response) => {
			this.consentforms = response.data.data;            
		});
	},
	computed: {
		form() {
			return this.$store.state.BulkAppointments.form;
		},
		vuelidateExternalResults() {
			return this.$store.state.BulkAppointments.vuelidateExternalResults;
		},
		validationRules() {
			return this.$store.state.BulkAppointments.validationRules;
		},
	},
	methods:{
		reset() {
			this.searchKey = this.searchKey + 1;
		},
		getlist(obj) {
			if (obj.length > 1) {
				this.$refs.autocomplete.$el.classList.add("selectloader");
				axios.post("/patient/patient-search", {search_text: obj,})
				.then((response) => {
					this.result = response.data.data.map((patient) => {
						return { id: patient.id, name: patient.first_name+" "+patient.last_name }
					});
					this.$refs.autocomplete.$el.classList.remove("selectloader");
				});
			}
			if (obj.length == 0) {
				this.result = {};
			}
		},
		resultsvalue(obj){
			this.$emit('clicked', obj)
			this.result= '';
			this.$refs.autocomplete.searchText = '';
			this.patient.push({"id":obj.id , "name":obj.name});
			this.focusout();
		},
		focusout() {
		  const btn = this.$refs.autocomplete;
		  btn.$el
			.querySelector(".vue3-autocomplete-container > .autosearch-list")
			.blur();
		},
		removepatient(index){
			this. patient.splice(index, 1)
		},
		OfficeChange(flag){
			axios.post("/json_dropdown/" + this.form.office_id +"/room")
			.then((response) => (
				this.rooms = response.data.data
			));
			axios.post("json_dropdown/office-provider",{Providers: this.accessproviders.map(({ id }) => id) , office_id: this.form.office_id})
			.then((response) => {
				this.providers = response.data.data.loop_data;
				if(this.accessproviders.map(({ id }) => id).includes( response.data.data.office_primary_provider )){
						this.form.provider_id = response.data.data.office_primary_provider;
				}
				else{
					var activeprovider = localStorage.getItem('activeProvider');
					if(activeprovider == 'all'){
						if(this.user.user_type == 'provider'){
							this.form.provider_id = this.user.user_id;
						}else{
							this.form.provider_id = this.user.primary_provider_id;
						}
					}else{
						this.form.provider_id = localStorage.getItem('activeProvider');
					}
				}
			});

			axios.post("json_dropdown/office-provider",{Providers: this.accessproviders.map(({ id }) => id) , office_id: this.form.office_id, is_supervisor: 1})
			.then((response) => {
				this.superproviders = response.data.data.loop_data;  
				this.form.supervisor_id = response.data.data.office_supervisor_provider; 
			});
		},
		superviserCheck(e){
			if (e.target.options.selectedIndex > -1 && this.form.provider_id != null && this.form.provider_id != '') {
				const theTarget = e.target.options[e.target.options.selectedIndex].dataset;
				if(theTarget.is_supervisor != 1){
					 this.$store.state.is_supervisor_required = true
				}else{
					 this.$store.state.is_supervisor_required = false
				}             
			}            
		},
		onChangeAppointmentProfile(e) {
			if (e.target.options.selectedIndex > -1) {
				const theTarget = e.target.options[e.target.options.selectedIndex].dataset;
				this.form.color_code = theTarget.color_code
				this.default_color = theTarget.color_code
				this.form.duration = theTarget.default_duration
				this.duration_value = theTarget.default_duration
				this.form.reason_for_visit = theTarget.name
				if(theTarget.consent_form_ids != null){
					this.selected_forms = theTarget.consent_form_ids.split(",");
				}else{
					this.selected_forms = '';
				}
			}
		},
		onChangeColor: function(args) {
			this.form.color_code = args.currentValue.hex;
		},
		durationChange(e){
			if(e.event){
				if(this.form.schedule_time != null){
					this.schedule_end_time = moment(this.form.schedule_time).add(e.value, 'minutes').format('YYYY-MM-DD hh:mm a');
					this.form.schedule_end_time = moment(this.form.schedule_time).add(e.value, 'minutes').format('YYYY-MM-DD hh:mm a');
				}
				this.duration_value = e.value;
				this.form.duration = e.value;
			}
		},
		consentformsChange(args){
			this.form.consent_form_ids = args.value
		},
		scheduleDate(e){
			if(e.event){
				this.form.schedule_time = moment(e.value).format('YYYY-MM-DD hh:mm a');
				this.form.schedule_end_time = moment(e.value).add(this.duration_value, 'minutes').format('YYYY-MM-DD hh:mm a');
				this.schedule_end_time = moment(e.value).add(this.duration_value, 'minutes').format('YYYY-MM-DD hh:mm a');
			}
		},
		scheduleEndDate(e){
			if(e.event){
				this.form.schedule_end_time = moment(e.value).format('YYYY-MM-DD hh:mm a');            
				var a = moment(this.form.schedule_end_time);
				var b = moment(this.form.schedule_time);
				this.duration_value = a.diff(b, 'minutes');
				this.form.duration = a.diff(b, 'minutes');
			}
		},
		submitForm(){
		  this.form.patient_id = this.patient;
		  this.v$.$validate();
		  this.errormessage = '';
		  if (!this.v$.$error) {
			axios.post("/appointment/bulk-appointments",this.form)
			.then((response) => {
				this.moshaToast("Record added successfully.", "success");
				setTimeout(function(){ location.reload() }, 3000);
			}).catch((error) => {
				this.errormessage = error.response.data.data.schedule_time;
			});
		  }
		}
	},
	validations() {
		return this.$store.state.BulkAppointments.validationRules;
	},        
}
</script>