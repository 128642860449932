<template>
  <div class="quick-info-header">
    <div
      class="box-header with-border bg-info d-flex justify-content-between"
      :style="`background: ${data.appointment_color_code} !important`" v-if="data.appointment_type!='Break'"
    >    
      <div class="">
        <h3 class="fw-700 m-0">{{ data.patient_name }}</h3>
        <small class="fs-13p" v-if="data.gender != null && data.date_of_birth != null">({{data.gender}} - {{data.date_of_birth}})</small>
        <small class="fs-13p" v-else-if="data.gender != null && data.date_of_birth == null">({{data.gender}})</small>
        <small class="fs-13p" v-else-if="data.date_of_birth != null">({{data.date_of_birth}})</small>
        <p class="m-0 fs-16 fw-500">
          Reason - <span class="fw-600"> {{ (data.reason_for_visit) ? data.reason_for_visit : data.appointment_profile_name }} </span>
        </p>
       
        <small class="fs-13p" v-if="data.type == 'Tele Health' && data.vcallroom != null">
        
          
          <a class="e-btn e-flat text-capitalize e-primary" target="_blank"  v-bind:href="'/#/telehealth/'+data.vcallroom+'/'+this.onlyDateFormat(data.schedule_time)+'/'+this.onlyTimeFormat(data.schedule_time)+'/'+this.onlyTimeFormat(data.EndTime)" style="font-size: 0.9rem !important;">
            <i class="fa fa-video-camera"></i> Join call 
          </a>
        </small>
      </div>
      <div class="w-p50">
        <button class="e-close e-control e-btn e-lib e-flat e-round e-small e-icon-btn position-absolute close-btn" title="Close" ></button>
        <p class="m-0 fs-14">
          Provider - <span class="fw-600">{{ data.provider_name }}</span>
        </p>
        <p class="m-0 fs-14">
          Coordinator - <span class="fw-600">{{ data.coordinator_staff_name }}</span>
        </p>
        <p class="m-0 fs-14">
          Status - <span class="fw-600" :id="'quick_info_status'+data.Id">{{ (data.status_label == 'Complete') ? "Completed" : data.status_label }}</span>
        </p>
        <p class="m-0 fs-14" v-if="data.legacy_source != null">
          Source - <span class="fw-600">{{ data.legacy_source }}</span>
        </p>
      </div>
    </div>
    <div
      class="box-header with-border bg-info d-flex justify-content-between"
      :style="`background: ${data.appointment_color_code} !important`" v-if="data.appointment_type=='Break'"
    >
      <div>
        <button class="e-close e-control e-btn e-lib e-flat e-round e-small e-icon-btn position-absolute close-btn" title="Close" ></button>
        <div class="row">
            <div class="col-md-6">
                <small class="fs-14 m-0">
                    Provider - <span class="fw-600">{{ data.provider_name }}</span>
                </small><br/>
                <small class="fs-14 m-0">
                Reason - <span class="fw-600">{{ data.reason_for_visit ? data.reason_for_visit : "Not available" }}</span>
                </small>
            </div>
            <div class="col-md-6">
                <small class="fs-14 m-0">
                    Exam Room - <span class="fw-600">{{ data.room_name }}</span>
                </small><br/>
                <small class="fs-14 m-0">
                    Duration - <span class="fw-600">{{ data.duration }}</span>
                </small>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Internationalization } from "@syncfusion/ej2-base";
import moment from 'moment-timezone'
export default {
  data() {
    return {
      intl: new Internationalization(),
      data: {},
    };
  },
  methods: {
    getHeaderStyles: function(data) {
      const scheduleObj = document.querySelector(".e-schedule")
        .ej2_instances[0];
      const resources = scheduleObj.getResourceCollections().slice(-1)[0];
      const resourceData = resources.dataSource.filter(
        (resource) => resource.Id === data.RoomId
      )[0];
      return resourceData.Color;
    },
    getHeaderTitle: function(data) {
      return data.elementType === "cell"
        ? "Add Appointment"
        : "Appointment Details";
    },
    getHeaderDetails: function(data) {
      return (
        this.intl.formatDate(data.StartTime, {
          type: "date",
          skeleton: "full",
        }) +
        " (" +
        this.intl.formatDate(data.StartTime, { skeleton: "hm" }) +
        " - " +
        this.intl.formatDate(data.EndTime, { skeleton: "hm" }) +
        ")"
      );
    },
    onlyDateFormat(date) {
    if (date) {
      let dt_format = "YYYY-MM-DD";
      console.log(moment(date).format(dt_format));
      return moment(date).format(dt_format);
      
    } else {
      return "";
    }
  },
  onlyTimeFormat(time) {
    if (time) {
      let dt_format =   "HH:mm:ss";
      console.log(moment(time).format(dt_format));
      return moment(time).format(dt_format);
      
    } else {
      return "";
    }
  },
  },
};
</script>
<style scoped>
.box-header.with-border {
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.box-header {
  display: block;
  padding: 0.8rem;
  position: relative;
  border-bottom: 1px solid #efeffd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.fs-14 {
  font-size: 14px !important;
}
.fs-13p {
  font-size: 13px !important;
}
.fw-700 {
  font-weight: 700;
}
</style>
