import { createStore } from "vuex";
import { provider,ArchivedProvider } from "./modules/provider.js";
import providerBilling from "./modules/providerBilling.js";

import generelSetting from './modules/generalSetting.js';
import macrosettings from './modules/GeneralSetting/macrosettings.js';
import auth from "./modules/auth.js";
import {office,Archivedoffice} from "./modules/office.js";
import officeBilling from "./modules/officeBilling.js";
import patientList from "./modules/patient/patientList.js";
import patientIdentification from "./modules/patient/patientIdentification.js";
import crmContacts from "./modules/crmContacts.js";
import preAppointment from "./modules/preAppointment.js";
import patientProblem from "./modules/patient/patientProblem.js";
import patientImplantableDevice from "./modules/patient/patientImplantableDevice.js";
import {
  patientLabOrders,
  patientLabOrdersEdit,
} from "./modules/patient/patientLabOrders.js";

import axios from "@/scripts/axios.js";
import {
  permissionList,
  RolesList,
  ProvidersList,
  StaffpermissionList,
} from "./modules/permissionList.js";
import {
  AppointmentProfilesList,
  AppointmentProfilesArchivedList,
  AppointmentStatusList,
} from "./modules/AppointmentProfilesList.js";

import { staff, staffscheduling, StaffArchivedList, StaffRXAccountSetup } from "./modules/staff.js";
import patient from "./modules/patient/patient.js";
import clinicSettings from "./modules/clinicSettings.js";
import demographics from "./modules/patient/demographics.js";
import patientDetail from "./modules/patient/patientDetail.js";
import ReferralDoctor from "./modules/ReferralDoctor.js";
import LockedClinicalNotes from "./modules/patient/Documents/LockedClinicalNotes.js";
import {
  SignedConsentForms,
  unsignedConsentForms,
} from "./modules/patient/Documents/SignedConsentForms.js";
import LabResults from "./modules/patient/Documents/LabResults.js";
import Amendments from "./modules/patient/Documents/Amendments.js";
import OngoingProblems from "./modules/patient/ClinicalDashboard/OngoingProblems.js";
import LabResult from "./modules/patient/ClinicalDashboard/LabResult.js";
import CarePlan from "./modules/patient/ClinicalDashboard/CarePlan.js";
import CareTeamMembers from "./modules/patient/ClinicalDashboard/CareTeamMembers.js";
import CognitiveStatus from "./modules/patient/ClinicalDashboard/CognitiveStatus.js";
import FunctionalStatus from "./modules/patient/ClinicalDashboard/FunctionalStatus.js";
import {
  ConsentForms,
  ConsentFormsArchived,
} from "./modules/ConsentForms/ConsentForms.js";
import {
  billingprofile,
  billingArchived,
} from "./modules/billingprofile.js";

import smokingstatus from "./modules/smokingstatus.js";
import documents from "./modules/documents.js";
import insuranceDetail from "./modules/insuranceDetail.js";
import radiologyorder from "./modules/radiologyorder.js";
import eob from "./modules/eob.js";
import permission from "./modules/permission.js";
import clinicalNote from "./modules/patient/clinicalNote.js";
import clinicalNote2 from "./modules/patient/clinicalNote2.js";
import patientinsuranceinfo from "./modules/patientinsuranceinfo.js";
import {
  CustomProcedures,
  CustomProceduresarchived,
} from "./modules/CustomProcedures/CustomProcedures.js";

import Procedure from "./modules/patient/CQM/Procedure.js";
import Assessment from "./modules/patient/CQM/Assessment.js";
import Intervention from "./modules/patient/CQM/Intervention.js";
import Medication from "./modules/patient/CQM/medication.js";
import {
  leave,
  leaveArchived,
  leaveAllArchived,
} from "./modules/leave.js";
import Agecvx from "./modules/Immunization/Agecvx.js";
import subcriptionPlan from "./modules/subcriptionPlan.js";
import VaccinesAvailable from "./modules/Immunization/VaccinesAvailable.js";
import unreaadmessage from "./modules/PatientPortal/unreaadmessage.js";
import Notification from "./modules/Notification.js";
import scheduleappointment from "./modules/scheduleappointment.js";
import appointmentbilling from "./modules/appointmentbilling.js";
import ProspectStatus from "./modules/ProspectStatus.js";
import phimailbox from "./modules/phimailbox.js";
import RecoverAppointments from "./modules/RecoverAppointments.js";
import BulkAppointments from "./modules/BulkAppointments.js";
import PaymentGatewayMigration from "./modules/PaymentGatewayMigration.js";
import CardAuthorizationofProspectus from "./modules/Reports/CardAuthorizationofProspectus.js";
import OnlineAppointment from "./modules/onlineAppointment.js";
import EmailTemplate from "./modules/EmailTemplate/EmailTemplate.js";
import SmsTemplate from "./modules/SmsTemplate/SmsTemplate.js";
import RecurringAppointments from "./modules/RecurringAppointments.js";
import personalinfo from "./modules/GeneralSetting/personalinfo.js";
import Reminderprofile from "./modules/Reminderprofile/Reminderprofile.js";
import Referral from "./modules/referral.js";
import CovidExams from "./modules/CovidExams.js";
import TeleHealthRegistration from "./modules/TeleHealthRegistration.js";
import OfficeLab from "./modules/offceLab.js";
import supportIndex from "./modules/Help/supportIndex.js";
import auditTemperProofLog from "./modules/auditTemperProofLog.js";
import auditRDSTemperProofLog from "./modules/auditRDSTemperProofLog.js";
import Patientidentification from "./modules/Patientidentification.js";
import {corporate,archivedcorporate} from "./modules/Corporate/Corporate.js";
import { workflow } from "./modules/workflow.js";
import { CRMPcontacts } from "./modules/CRMPcontacts.js";
import {CorporateAppointments} from "./modules/Corporate/CorporateAppointments.js";
import Pipeline from "./modules/Pipeline.js"
import CorporateLifeStyleService from "./modules/Corporate/CorporateLifeStyleService.js";
import CorporateLifeStyleServiceCategory from "./modules/Corporate/CorporateLifeStyleServiceCategory.js";
import Vitals from "./modules/Vitals.js";
import CorporateCampaign from "./modules/Corporate/CorporateCampaign.js";
import Coupon from "./modules/Coupon.js";
import RemittanceReport from "./modules/Reports/RemittanceReport.js";
import Contract from "./modules/Reports/Contract.js";
import ContractPayment from "./modules/Reports/ContractPayment.js";
import RefundReport from "./modules/Reports/RefundReport.js";
import PatientContactReport from "./modules/Reports/PatientContactReport.js";
import BulkSignClinicalNotes from "./modules/BulkSignClinicalNotes.js";
import { CustomBillingStatus, CustomBillingStatusArchive } from "./modules/CustomBillingStatus.js";
import AppointmentStatusDashboard from "./modules/AppointmentStatusDashboard.js";
import EligibilityResponses from "./modules/Reports/EligibilityResponses.js";
import AdvanceReport from "./modules/Reports/AdvanceReport.js";
import CovidExaminationReport from "./modules/Reports/CovidExaminationReport.js";
import UnmatchedERA from "./modules/Reports/UnmatchedERA.js";
export default createStore({
  state: {
    user_id :40,
    covid_exam_rpt_user_access :[40],
    timerCount: 30,
    date:'',
    signature:'',
    loader: false,
    processing: false,
    is_supervisor_required: false,
    is_insurance:false,
    is_patient_is_subscriber:false,
    toster: { type: "error", msg: "just error " },
    errors: [],
    permission_idss: '1,2,3',
    patientDetailsComp: "",
    addProviderComp: "AddProviderHome",
    addLeaveComp: "AddLeaveHome",
    StaffHomeComp: "StaffAdd",
    ScheduleHomeComp: "ScheduleForm",
    addOfficeComp: "AddOffice",
    AppointmentProfileHomeComp: "AppointmentProfile",
    PermissionsHomeComp: "Permissionslist",
    CorporateComp: "",
    newOfficeId: "",
    renderComponent:true,
    changes_pipeline_id:'1',
    MainInsurnceBilling:{
      hidemodal: false,
    },
    tableHeaders: {
      staffList: {
        content: [
          "Username",
          "Name",
          "E-mail",
          "Cell Number",
          "Office Number",
          "Archive",
        ],
      },
    },

    staffList: [
      {
        id: 1,
        first_name: "chintan",
        last_name: "gohil",
        email: "chintan@sdf.sdf",
        home_phone: null,
        cell_phone: null,
        username: "Davidsaurez",
        clinic_id: 1,
      },
      {
        id: 8,
        first_name: "chintan",
        last_name: "gohil",
        email: "chintan@sdf.sdf",
        home_phone: null,
        cell_phone: null,
        username: "chintan_gohil",
        clinic_id: 1,
      },
      {
        id: 8,
        first_name: "mukesh",
        last_name: "jagani",
        email: "mukesh@sdf.sdf",
        home_phone: null,
        cell_phone: null,
        username: "mukesh111",
        clinic_id: 1,
      },
    ],
    officeList: [],
  },
  getters: {
    errors: (state) => state.errors,
  },
  mutations: {
    setErrors(state, errors) {
      state.errors = errors;
    },
    showToster(state, data) {
      state.toster = { type: data[0], msg: data[1] };
    },
  },
  actions: {
    // pass data type as  { dataTaype: 'modulename', queryStr: ''}  // send null if no query string
    async getDataTableContents(context, data) {
      // console.log("==> ", context.state);
      // console.log("==> ", data.dataType);
      try {
        context.state.loader = true;
        if (data.method == "post") {
          var result = await axios.post(
            context.state[data.dataType].listUrl,
            data.queryStr
          );
        } else {
          var result = await axios.get(
            `${context.state[data.dataType].listUrl}${data.queryStr}`
          );
        }
        if (result.data.status === "success") {
          context.state.loader = true;
          if(result.data.data.u_t_text !== undefined && result.data.data.u_t_text != '' && data.dataType == 'BulkSignClinicalNotes'){
            context.state[data.dataType].userType = result.data.data.u_t_text;
          }

          if(data.dataType == "AdvanceReport"){
            context.state[data.dataType].patientIds = result.data.data.patientids;
          }

          if (result.data.data.data) {
            context.state[data.dataType].list = result.data.data.data;

            if(result.data.data.priceTotals){
              context.state[data.dataType].priceTotals = result.data.data.priceTotals;
            }
          } else {
            context.state[data.dataType].list = result.data.data;
            if(result.data.priceTotals){
              context.state[data.dataType].priceTotals = result.data.priceTotals;
            }
          }

          // set pagination data
          if (result.data.data.current_page) {
            context.state[data.dataType].pagination.to = result.data.data.to;
            context.state[data.dataType].pagination.from =
              result.data.data.from;
            context.state[data.dataType].pagination.total =
              result.data.data.total;
            context.state[data.dataType].pagination.last_page =
              result.data.data.last_page;
            context.state[data.dataType].pagination.current_page =
              result.data.data.current_page;

            // create pagination page nativation arr
            context.state[data.dataType].pagination.pageNavArr = [];
            for (let i = 1; i <= result.data.data.last_page; i++) {
              context.state[data.dataType].pagination.pageNavArr.push(i);
            }
          }
        } else {
          return "false";
        }
        context.state.loader = false;
      } catch (err) {
        if (err) {
          console.log(err)
          return "false";
        }
      }
    },
    async getSimpleTableContents(context, data) {
      try {
        var listUrl = context.state[data.dataType].listUrl;
        if (listUrl.indexOf(":patient_unique_id") !== -1) {
          var url1 = context.state[data.dataType].listUrl.replace(
            ":patient_unique_id",
            data.patient_unique_id
          );
        } else {
          var url1 = context.state[data.dataType].listUrl;
        }

        const url = url1.replace(":id2", data.id2);
        if (data.method == "get") {
          var result = await axios.get(`${url}`);
        } else {
          var result = await axios.post(`${url}`, data);
        }
         context.state.loader = true;
         console.log('result 2: ', result);
        if (result.data.status === "success") {
          context.state[data.dataType].list = result.data.data;
        } else {
          return "false";
        }
         context.state.loader = false;
      } catch (err) {
        if (err) {
           context.state.loader = false;
          return "false";
        }
      }
    },
    async getEditData(context, data) {
      try {
         context.state.loader = true;

        var url = context.state[data.dataType].getEditUrl;
        if (url.indexOf(":patient_unique_id") !== -1) {
          var url1 = context.state[data.dataType].getEditUrl.replace(
            ":patient_unique_id",
            data.patient_unique_id
          );
        } else {
          var url1 = context.state[data.dataType].getEditUrl;
        }

        var url = url1.replace(":id", data.id);

        const result = await axios.get(`${url}`);

        if (result.data.status === "success") {
          context.state[data.dataType][data.formType] = result.data.data;
          context.state[data.dataType].editID = result.data.data.id;
        } else {
          return "false";
        }
         context.state.loader = false;
      } catch (err) {
        if (err) {
          console.log(err);
           context.state.loader = false;
          return "false";
        }
      }
    },
    async postEditData(context, data) {
      try {
         context.state.loader = true;

        var url = context.state[data.dataType].getEditUrl;
        if (url.indexOf(":patient_unique_id") !== -1) {
          var url1 = context.state[data.dataType].getEditUrl.replace(
            ":patient_unique_id",
            data.patient_unique_id
          );
        } else {
          var url1 = context.state[data.dataType].getEditUrl;
        }

        var url = url1.replace(":id", data.id);

        const result = await axios.post(`${url}`,data);

        if (result.data.status === "success") {
          context.state[data.dataType][data.formType] = result.data.data;
          context.state[data.dataType].editID = result.data.data.id;
        } else {
          return "false";
        }
         context.state.loader = false;
      } catch (err) {
        if (err) {
          console.log(err);
           context.state.loader = false;
          return "false";
        }
      }
    },
    async getEditDataForMultipalId(context, data) {
      try {
         context.state.loader = true;

        const url1 = context.state[data.dataType].getEditUrl.replace(
          ":id",
          data.id
        );

        const url = url1.replace(":id2", data.id2);

        const result = await axios.get(`${url}`);

        if (result.data.status === "success") {
          context.state[data.dataType][data.formType] = result.data.data;
          context.state[data.dataType].editID = result.data.data.id;
        } else {
          return "false";
        }
        context.state.loader = false;
      } catch (err) {
        if (err) {
          console.log(err);
          context.state.loader = false;
          return "false";
        }
      }
    },
  },
  modules: {
    eob,
    provider,
    providerBilling,
    office,
    Archivedoffice,
    officeBilling,
    auth,
    permissionList,
    RolesList,
    ProvidersList,
    StaffpermissionList,
    AppointmentProfilesList,
    AppointmentProfilesArchivedList,
    staff,
    staffscheduling,
    patient,
    clinicSettings,
    demographics,
    patientDetail,
    staff,
    staffscheduling,
    Referral,
    ReferralDoctor,
    LockedClinicalNotes,
    patientList,
    patientIdentification,
    crmContacts,
    preAppointment,
    SignedConsentForms,
    unsignedConsentForms,
    LabResults,
    Amendments,
    OngoingProblems,
    LabResult,
    CarePlan,
    CareTeamMembers,
    CognitiveStatus,
    FunctionalStatus,
    ConsentForms,
    ConsentFormsArchived,
    patientProblem,
    patientImplantableDevice,
    patientLabOrders,
    patientLabOrdersEdit,
    billingprofile,
    billingArchived,
    smokingstatus,
    documents,
    insuranceDetail,
    radiologyorder,
    permission,
    CustomProcedures,
    CustomProceduresarchived,
    clinicalNote,
    clinicalNote2,
    leave,
    Procedure,
    Assessment,
    Intervention,
    Medication,
    leaveArchived,
    leaveAllArchived,
    Agecvx,
    subcriptionPlan,
    VaccinesAvailable,
    unreaadmessage,
    Notification,
    scheduleappointment,
    appointmentbilling,
    ProspectStatus,
    AppointmentStatusList,
    phimailbox,
    ArchivedProvider,
    StaffArchivedList,
    StaffRXAccountSetup,
    RecoverAppointments,
    BulkAppointments,
    PaymentGatewayMigration,
    CardAuthorizationofProspectus,
    generelSetting,
    macrosettings,
    OnlineAppointment,
    EmailTemplate,
    SmsTemplate,
    RecurringAppointments,
    personalinfo,
    Reminderprofile,
    CovidExams,
    OfficeLab,
    auditTemperProofLog,
    auditRDSTemperProofLog,
    patientinsuranceinfo,
    supportIndex,
    Patientidentification,
    workflow,
    CRMPcontacts,
    corporate,
    archivedcorporate,
    CorporateAppointments,
    Pipeline,
    TeleHealthRegistration,
    CorporateLifeStyleService,
    CorporateLifeStyleServiceCategory,
    Vitals,
    CorporateCampaign,
    Coupon,
    RemittanceReport,
    Contract,
    ContractPayment,
    RefundReport,
    PatientContactReport,
    BulkSignClinicalNotes,
    CustomBillingStatus,
    CustomBillingStatusArchive,
    AppointmentStatusDashboard,
    EligibilityResponses,
    AdvanceReport,
    CovidExaminationReport,
    UnmatchedERA
  },
});
