<template>
  <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-10">
    <div class="box">
      <div class="box-body">
        <div class="pageheading">
          <h2 class="font-bold mt-0">Providers <a href="#" data-bs-toggle="modal" data-bs-target="#permissiondocument" title="Reference of Knowledge Base" v-on:click.prevent="reset()"> <i class="fa fa-question-circle fs-20"></i></a></h2>
        </div>
        <div class="table-responsive">
          <data-table dataType="ProvidersList">
            <template v-slot="slotProps">
              <!-- slot for table data -->
              <td>
                {{ slotProps.item.full_name }} 
              </td>
              <td>
                <Popper arrow content="View/Edit Permissions" :hover="true" class="helptips cursor-pointer">
                <a href="#"
                  @click="
                    providersmodal(
                      slotProps.item.first_name,
                      slotProps.item.last_name,
                      slotProps.item.permission_ids,
                      slotProps.item.id,
                      slotProps.item.full_name,
                    )
                  "
                  class="waves-effect waves-circle btn btn-circle btn-info btn-xs"
                  data-bs-toggle="modal"
                  data-bs-target="#providermodal"
                  ><i class="fa fa-eye"></i></a>
                </Popper>
              </td>
            </template>
          </data-table>
        </div>
        <!--document model start-->
        <div id="permissiondocument" class="modal fade" tabindex="-1" role="dialog"
          aria-labelledby="myModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-md">
              <div class="modal-content">
              <div class="modal-header">
                <h3 class="modal-title fw-900 mt-0">Reference of Knowledge Base</h3>
              </div>
              <div class="modal-body overflow-auto" style="max-height: 350px;">
                <table class="table table-bordered">
                    <thead>
                      <tr>
                      <th scope="col">No.</th>
                      <th scope="col">Knowledge Base Articles</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                          <td>1</td>
                          <td> <a :href="path+'how-to-view-permissions-for-a-provider-in-pureehr-15-1-2023'" target="_blank">How To View Permissions For A Provider In PureEHR?</a></td>
                      </tr>
                      <tr>
                          <td>2</td>
                          <td> <a :href="path+'how-to-edit-permissions-for-a-provider-in-pureehr'" target="_blank">How To Edit Permissions For A Provider In PureEHR?</a></td>
                      </tr>
                      <tr>
                          <td>3</td>
                          <td> <a :href="path+'how-to-remove-permission-from-the-provider-account'" target="_blank">How To Remove Permission From The Provider Account?</a></td>
                      </tr>
                    </tbody>
                </table>
                <Search :key="searchKey"></Search>
              </div>
              <div class="modal-footer">
                <button type="button" @click="" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
              </div>
              </div>
          </div>
        </div>
        <!--document model end-->
      </div>
    </div>
  </div>

  <div
    id="providermodal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content permissmodal">
        <div class="modal-header">
          <h3 class="modal-title font-bold m-0">
            Permissions for {{ providername }}
          </h3>
        </div>

        <form @submit.prevent="permissionsupdate">
          <div class="modal-body">
            <div class="box mb-0">              
              <div class="box-body">
                <div class="form-check" v-for="(obj,index) in Editpermission" :key="index">
                  <div class="text-center font-bold">{{index}}</div>
                  <hr>
                  <slot v-for="(item,index1) in obj" :key="index1">
                    <div v-if="item.selected === 'true'">
                      <input
                        name="permissionsedit"
                        :value="item.id"
                        checked
                        type="checkbox"
                        v-bind:id="'md_checkbox_' + item.id"
                        class="filled-in chk-col-info"
                      />
                      <label
                        v-bind:for="'md_checkbox_' + item.id"
                        class="text-start"
                        >{{ item.name }}
                      </label>
                      <a
                        href="javascript:void(0)"
                        class="ms-5 black-color"
                        aria-label="patientinfo"
                      >
                        <Popper
                          arrow
                          :content="item.description"
                          hover="true"
                          class="helptips"
                        >
                          <i class="fa fa-info-circle fs-12"></i>
                        </Popper>
                      </a>
                    </div>
                    <div v-else>
                      <input
                        name="permissionsedit"
                        :value="item.id"
                        type="checkbox"
                        v-bind:id="'md_checkbox_' + item.id"
                        class="filled-in chk-col-info"
                      />
                      <label
                        v-bind:for="'md_checkbox_' + item.id"
                        class="text-start"
                        >{{ item.name }}
                      </label>
                      <a
                        href="javascript:void(0)"
                        class="ms-5 black-color"
                        aria-label="patientinfo"
                      >
                        <Popper
                          arrow
                          :content="item.description"
                          hover="true"
                          class="helptips"
                        >
                          <i class="fa fa-info-circle fs-12"></i>
                        </Popper>
                      </a>
                    </div>
                  </slot>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="waves-effect btn btn-danger"
              data-bs-dismiss="modal">
              Close
            </button>
            <!-- <button type="button" class="waves-effect btn btn-info float-end">Edit</button> -->
            <button type="submit" class="waves-effect btn btn-info float-end">Save</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/scripts/axios.js";
import dataTable from "@/components/base/DataTable.vue";
import submitMixins from "@/scripts/mixins/submitMixins.js";
import Popper from "vue3-popper";
import Search from "@/components/Search.vue";

export default {
  mixins: [submitMixins],
  name: "roles",
  components: { dataTable, Popper, Search },
  data() {
    return {
      providername: "",
      Editpermission: "",
      permissionupdate: "",
      id: "",
      path: process.env.VUE_APP_KB_DOCUMENT,
      searchKey:0,
    };
  },
  methods: {
    reset() {
      this.searchKey = this.searchKey + 1;
    },
    providersmodal(first_name, last_name, permission_ids, id,full_name) {
      this.id = id;
      this.providername = full_name;

      var thisObj = this;
      axios
        .post("permission-edit", { permission_id: permission_ids })
        .then((response) => {
          thisObj.Editpermission = response.data.data;
        });
    },
    permissionsupdate() {
      var permissionsedit = Array();
      $("input:checkbox[name=permissionsedit]:checked").each(function() {
        permissionsedit.push($(this).val());
      });

      this.$store.state.loader = true;
      var thisObj = this;
      axios
        .post("permission/update", {
          id: this.id,
          permission_id: permissionsedit,
          user_types: "provider",
        })
        .then((response) => {
          this.$store.state.loader = false;
          thisObj.permissionupdate = response.data.data;
          if (thisObj.permissionupdate.token) {
            localStorage.setItem("authToken", thisObj.permissionupdate.token);
            localStorage.setItem("permission", permissionsedit);
          }
          this.moshaToast("Record updated successfully.", "success");
          setTimeout(function() {
            location.reload();
          }, 1000);
        });

      $("#providermodal").modal("toggle");
    },
  },
};
</script>
