<template>
	<div class="content-wrapper">
		<div class="container-full">
			<div class="content-header">
				<h1 class="heading1 mt-0">Eligibility Search 
					<a href="#" data-bs-toggle="modal" data-bs-target="#eligibilitydocument" title="Reference of Knowledge Base"> 
						<i class="fa fa-question-circle fs-20"></i>
					</a>
				</h1>
			</div>
			<section class="content">
				<div class="box">
					<div class="box-body">
						<div class="row justify-content-center">
							<div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 px-5">
								<div class="form-group mb-sm-10">
									<label class="form-label d-block">Patient</label>
									<div class="selectcontrols">
										<Autocomplete 
											max="30" 
											@input="getlist" 
											ref="autocomplete" 
											:results="patientResult" 
											@onSelect="resultsvalue" 
											class="form-control ms-0 autosearch-list" 
											placeholder="Search Patient" 
											:use-html-for-results="true">
										</Autocomplete>
									</div>
								</div>
							</div>
							<div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 px-5">
								<div class="form-group mb-sm-10">
									<label class="form-label">Select Date Range</label>
									<div class="form-group mb-5 availdate">
										<ejs-daterangepicker 
											id="daterangepicker" 
											:placeholder="waterMarkText"
											:format="dateFormat" 
											:openOnFocus='true' 
											@change="getdate"
											:value="search.date_range">
										</ejs-daterangepicker>
									</div>
								</div>
							</div>
							<div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 px-5">
								<div class="form-group">
									<label class="form-label">Select Status</label>
									<select class="form-control" v-model="search.response_status">
										<option value=''>-- select status --</option>
										<option v-for="(res, index) in responseList" :value="res.response_status"
											:key="index">{{ res.response_status }}</option>
									</select>
								</div>
							</div>
							<div class="col-12 col-sm-6 col-md-12 col-lg-12 col-xl-1 px-5">
								<div class="mt-sm-30">
									<button class="b-0 waves-effect waves-light btn btn-info me-1 w-p100" @click="getData">
										Search </button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="box">
					<div class="box-body">
						<div class="table-responsive">
							<data-table-server dataType="EligibilityResponses" dataMethod="post" :key="key">
								<template v-slot="slotProps">
									<td>
										<Popper arrow content="Click here to view patient chart" :hover="true"
											class="helptips cursor-pointer">
											<a v-bind:href="'#/patient-chart/' + slotProps.item.unique_id"
												class="fs-16 fw-600 text-dark" target="_blank">{{ slotProps.item.first_name }}
												{{ slotProps.item.last_name }}</a>
										</Popper>
									</td>
									<td>
										{{ (slotProps.item.eligibility_recent_service_checked_date != '0000-00-00 00:00:00') ? $filters.displayDateTimeLocal(slotProps.item.eligibility_recent_service_checked_date) : '-' }}
									</td>
									<td>{{ $filters.displayDateTimeLocal(slotProps.item.eligibility_recent_checked_date,'',true) }}</td>
									<td>{{ slotProps.item.eligibility_recent_status }}</td>
									<td>
										<a href="javascript:void(0)" @click="getLogs(slotProps.item.id, slotProps.item.first_name + ' ' + slotProps.item.last_name)">
											<i class="fa fa-eye" aria-hidden="true"></i>
										</a>
									</td>
								</template>
							</data-table-server>
						</div>
					</div>
				</div>
			</section>
		</div>
	</div>
	<!--document model start-->
	<div id="eligibilitydocument" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
		aria-hidden="true">
		<div class="modal-dialog modal-md">
			<div class="modal-content">
				<div class="modal-header">
					<h3 class="modal-title fw-900 mt-0">Reference of Knowledge Base</h3>
				</div>
				<div class="modal-body overflow-auto" style="max-height: 350px;">
					<table class="table table-bordered">
						<thead>
							<tr>
								<th scope="col">No.</th>
								<th scope="col">Knowledge Base Articles</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>1</td>
								<td> <a :href="path + 'how-to-check-a-patient-s-eligibility-using-eligibility-report-in-pureehr'" target="_blank">How To Check A Patient’s Eligibility Using Eligibility Report In PureEHR?</a></td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="modal-footer">
					<button type="button" @click="" data-bs-dismiss="modal" aria-label="Close"
						class="waves-effect waves-light btn btn-danger">Close</button>
				</div>
			</div>
		</div>
	</div>
	<!--document model end-->
	<div class="modal" id="logs" tabindex="-1" role="dialog">
		<div class="modal-dialog" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<h2 class="modal-title mt-0">{{ patient_name }}</h2>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">
					<table class="table table-hover">
						<thead>
							<tr class="text-center">
								<th>Checked On</th>
								<th>Service Date</th>
								<th>Response</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(log, index) in responseLogs" class="text-center" :key="index">
								<td>{{ $filters.displayDateTimeLocal(log.created_at) }}</td>
								<td>{{ (log.service_date == '0000-00-00 00:00:00') ? '-' :
									$filters.displayDateTimeLocal(log.service_date) }}</td>
								<td>{{ log.response_status }}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import DataTableServer from "@/components/base/DataTableServer.vue";
import axios from "@/scripts/axios.js";
import submitMixins from "@/scripts/mixins/submitMixins.js";
import permissionMixins from "@/scripts/mixins/permission.js";
import Popper from "vue3-popper";
import Autocomplete from "vue3-autocomplete";

export default {
	mixins: [submitMixins, permissionMixins],
	components: { DataTableServer, Popper, Autocomplete },
	data() {
		return {
			waterMarkText: "Select a Range",
			dateFormat: "MM-dd-yyyy",
			patient_name: '',
			patientResult: {},
			responseList: [],
			responseLogs: [],
			path: process.env.VUE_APP_KB_DOCUMENT,
			key: 0
		};
	},
	computed: {
		search() {
			return this.$store.state.EligibilityResponses.filter;
		},
	},
	mounted() {
		this.eligibility_response_list();
	},
	methods: {
		getLogs(patient_id, patient_name) {
			this.patient_name = patient_name;
			axios.post("/reports/eligibility-response-logs", { patient_id: patient_id })
				.then((response) => {
					this.responseLogs = response.data.data;
					$("#logs").modal("show");
				});
		},
		eligibility_response_list() {
			axios.get("/reports/eligibility-response-list")
				.then((response) => {
					this.responseList = response.data.data;
				});
		},
		getlist(obj) {
			if (obj.length > 1) {
				this.$refs.autocomplete.$el.classList.add("selectloader");
				axios.post("/patient/patient-search", {
					search_text: obj,
				}).then((response) => {
					if (response.data.data.length > 0) {
						this.patientResult = response.data.data.map((patient) => {
							return { id: patient.id, name: patient.first_name + " " + patient.last_name }
						});
						this.$refs.autocomplete.$el.classList.remove("selectloader");
					}
					else {
						this.patientResult = {};
					}
				});
			}
			if (obj.length == 0) {
				this.patientResult = {};
				this.search.patient_id = '';
			}
		},
		resultsvalue(obj) {
			this.$emit("clicked", obj);
			this.$refs.autocomplete.searchText = obj.name;
			this.patientResult = {};
			this.search.patient_id = obj.id;
			this.focusout();
		},							
		getData() {
			this.key = 1+this.key;
		},		
		getdate() {
			this.search.date_range = $('#daterangepicker').val();
		},
	},
};
</script>
