import {
  helpers,
  required,
  email,
  minLength,
  maxLength,
  sameAs,
  numeric,
  alphaNum,
} from "@vuelidate/validators";
const CustomProcedures = {
  namespaced: true,
  state: {
    header: [
      {"category_name":"Category"},
      {"custom_procedure.code":"Code"},
      {"provider_name":"Provider"},
      {"custom_procedure.description":"Description"},
      {"custom_procedure.note":"Note"},
      {"custom_procedure.price":"Price"},
      {"custom_procedure.price_with_tax":"With Tax"},
      {"custom_procedure.cost":"Cost"},
      {"custom_procedure.duration":"Duration"},
      {"Action":"Action"},
    ],
    pagination: {
      to: "",
      from: "",
      total: "",
      last_page: "",
      current_page: "",
      pageNavArr: [],
    },
    filter: {
      search_text: '',
    },
    form: {},
    vuelidateExternalResults: {
      form: {
        code:[],
        qty:[],
        description:[],
        price:[],
        type_of_product:[]
      },
    },
    validationRules: {
      form: {
        code:{
          // max: helpers.withMessage("The maximum allowed length is 8 letters only.", maxLength(8)),
          // min: helpers.withMessage("The code should have minimum 3 letters.", minLength(3)),
          // alphaNum: helpers.withMessage("Max 8 upper case letters and/or numbers are valid.", alphaNum),
          required: helpers.withMessage("Code is required.", required),
        },
        qty: {
          numeric: helpers.withMessage("Quantity Units Value must be numeric.", numeric),
          required: helpers.withMessage("Quantity is required.", required),
        },
        description:{
          required: helpers.withMessage("Description is required.", required),
        },
        price:{
          required: helpers.withMessage("Price is required.", required),
        },
        type_of_product:{
          required: helpers.withMessage("Type of product is required.", required),
        }

      },
    },
    listUrl: "/custom-procedure/list",
    getEditUrl: "custom-procedure/:id/edit",
    list: [],
  },
  getters: {
    getTableHeaders: (state) => state.header,
    getTableContents: (state) => state.list,
    getListURL: (state) => state.listUrl,
  },
  mutations: {},
  actions: {},
}

const CustomProceduresarchived = {
  namespaced: true,
  state: {
    listUrl: "/custom-procedure/list/archived",
    tableData: {
      header: [
        "Category",
        "Code",
        "Provider",
        "Description",
        "Note",
        "Price",
        "with Tax",
        "Cost",
        "Duration",
        // "Actions",
      ],
    },
    list: [],
  },
  getters: {
    getTableHeaders: (state) => state.header,
    getTableContents: (state) => state.list,
    getListURL: (state) => state.listUrl,
  },
  mutations: {},
  actions: {},
}

export {CustomProcedures,CustomProceduresarchived}
