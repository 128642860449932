
const patientIdentification = {
  namespaced: true,

  state: {
    listUrl: "/reports/patient-identification",
    header: [
      {"chart_id":"Chart ID"},
      // "Provider",
      {"patinet_name":"Patient Name"},
      {"office_phone":"Office Phone"},
      {"cell_phone":"Cell Phone"},
      {"email":"Email"},
      {"":"Action"},
      {"":''},
    ],
    pagination: {
      to: "",
      from: "",
      total: "",
      last_page: "",
      current_page: "",
      pageNavArr: [],
    },
    list: [],
    patinet_search: "",
    status: "",
  },

  getters: {
    // getTableHeaders: (state) => state.header,
    // getTableContents: (state) => state.list,
    // getListURL: (state) => state.listUrl,
  },
  mutations: {},
  actions: {},
};

export default patientIdentification;
