<template>
  <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-2">
    <div class="sticky-scroll-box">
      <div class="accorsidebar">
        <div class="accordion-item">
          <p class="accordion-header display-vertical" id="addpatienttab">
                <span class="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapsedemogr" aria-expanded="false" aria-controls="collapsedemogr">Permissions</span>
            </p>
          <div id="collapsedemogr" class="accordion-collapse collapse" aria-labelledby="addpatienttab" data-bs-parent="#accordionExample">
            <div class="side-navigation">
              <ul class="side-bar-menu side-bar-tree patientTabs" data-widget="tree">
              <li>
                  <a href="javascript:void(0)" ref="PermissionsLink" @click.prevent="changeComp('Permissions')">Permissions</a>
              </li>
              <li>
                  <a href="javascript:void(0)" ref="PermissionsrolesLink" @click.prevent="changeComp('Permissionsroles')">Roles</a>
              </li>
              <li v-if="permissioncheck(this.$store.state.permission.Provider_Permission)">
                  <a href="javascript:void(0)" ref="PermissionsProvidersLink" @click.prevent="changeComp('PermissionsProviders')" >Providers</a>
              </li>
              <li v-if="permissioncheck(this.$store.state.permission.Staff_Permission)">
                  <a href="javascript:void(0)" ref="PermissionsstaffLink" @click.prevent="changeComp('Permissionsstaff')" >Staff</a>
              </li>
              <li v-if="user && user.is_super_admin == 1">
                  <a href="javascript:void(0)" ref="PermissionsemergencyLink" @click.prevent="changeComp('Permissionsemergency')" >Emergency Access</a>
              </li>
            </ul>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { mapGetters, mapActions } from "vuex";
import permissionMixins from "@/scripts/mixins/permission.js";
import $ from 'jquery';
export default {
  mixins: [permissionMixins],
  data(){
    return {
    }
  },  
  computed: {
  ...mapGetters("auth", ["user"]),
  },
   watch: {
    "$route.name": function(newValue) {
      this.linkActive();
    },
  },
  mounted() {
    setTimeout(() => {
      this.linkActive();
    }, 100);
  },
  methods: {
    changeComp(routerName) {
      this.$router.push({name: routerName});
    },
    linkActive() {      
      var name = this.$route.name;
      $(".patientTabs li").children("a").removeClass("active");
      var $tabName = name+"Link", $link;
      if($.type(this.$refs[$tabName]) !== undefined && $.type(this.$refs[$tabName]) !== "undefined")
      {
          this.$refs[$tabName].classList.add("active");
      }
    },
  },
}
</script>