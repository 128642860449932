<template>
  <div class="panel pagecollap">
    <div class="panel-heading" id="careplan" role="tab">
      <a
        class="panel-title"
        aria-controls="answer-9"
        aria-expanded="false"
        data-bs-toggle="collapse"
        href="#answer-9"
        data-parent="#accordion2"
        >Care Plan</a>
    </div>
    <!--pop start-->
    <div
      id="requestleave"
      class="requestleave modal fade"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content permissmodal">
          <div class="modal-header">
            <h3 class="modal-title font-bold m-0">New Care Plan</h3>
          </div>
          <div class="modal-body">
            <form>
              <input-field
                title="Instructions"
                id="instructions"
                v-model="form.instructions"
                :isRequired="false"
                labelClass="col-form-label col-12 col-sm-3 col-md-3 col-lg-4 text-sm-end mb-0"
                divClass="col-12 col-sm-9 col-md-9 col-lg-8"
              >
              </input-field>

              <select-box-static
                title="Plan Type"
                v-model="v$.form.plan_type.$model"
                :errors="v$.form.plan_type.$errors"
                :isRequired="true"
                :option="plan_type"
                labelClass="col-form-label col-12 col-sm-3 col-md-3 col-lg-4 text-sm-end mb-0"
                divClass="col-12 col-sm-9 col-md-9 col-lg-8"
              ></select-box-static>

              <div class="form-group row">
                <label class="col-form-label col-12 col-sm-3 col-md-3 col-lg-4 text-sm-end mb-0">
                  Schedule Date <small class="text-danger asterisksign">*</small>
                </label>
                <div class="col-12 col-sm-9 col-md-9 col-lg-8 availdate" :class="{ error: v$.form.scheduled_date.$errors.length }">
                  <ejs-datepicker :value="scheduled_date" id="scheduled_date" v-model="v$.form.scheduled_date.$model" :format="dateFormat"  placeholder="Schedule Date" :showClearButton='false'  @blur="getdate" @change="getdate"  name="scheduled_date" :strictMode='strict' :min='current_date' :openOnFocus='true'>
                  </ejs-datepicker>

                  <div class="error-msg">
                    <ul class="mb-0 p-0 fs-14">
                      <li class="d-block" v-for="(error, index) of v$.form.scheduled_date.$errors" :key="index">
                        {{ error.$message }}
                      </li>
                    </ul>
                  </div>
                  
                </div>
              </div>

              <div
                class="form-group row"
                :class="{ error: v$.form.snomed_code.$errors.length }">
                <label class="col-form-label col-12 col-sm-3 col-md-3 col-lg-4 text-sm-end mb-0">SNOMED Code <small class="text-danger asterisksign">*</small></label>
                <div class="col-12 col-sm-9 col-md-9 col-lg-8">
                  <div class="controls">
                    <div class="selectcontrols">
                      <Autocomplete max="30" 
                        @input="getlist" 
                        ref="autocomplete" 
                        :results="icd10" 
                        @onSelect="resultsvalue" 
                        class="form-control ms-0 autosearch-list" 
                        placeholder="SNOMED Code"
                        :use-html-for-results ="true"
                        >                        
                      </Autocomplete>
                    </div>

                  </div>
                  <div class="error-msg">
                    <ul class="mb-0 p-0 fs-14">
                      <li
                        class="d-block"
                        v-for="(error, index) of v$.form.snomed_code.$errors"
                        :key="index"
                      >
                        {{ error.$message }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <input-field
                title="Title"
                id="title"
                v-model="v$.form.title.$model"
                :errors="v$.form.title.$errors"
                :isRequired="true"
                labelClass="col-form-label col-12 col-sm-3 col-md-3 col-lg-4 text-sm-end mb-0"
                divClass="col-12 col-sm-9 col-md-9 col-lg-8"
              >
              </input-field>
            </form>
          </div>
          <div class="modal-footer" v-if="
                      permissioncheck(
                        this.$store.state.permission.create_update
                      )
                    ">
            <form-btn
              title="Save"
              btnCls="waves-effect waves-light btn btn-info"
              @click="save(false)"
            ></form-btn>
            <button
              type="button"
              class="waves-effect waves-light btn btn-danger"
              data-bs-dismiss="modal" @click="close"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    <!--pop End -->
    <div
      class="panel-collapse collapse"
      id="answer-9"
      aria-labelledby="careplan"
      role="tabpanel"
      data-bs-parent="#category-1"
    >
      <div class="panel-body">
        <p class="text-sm-end">
          <a
            v-if="permissioncheck(this.$store.state.permission.create_update)"
            href="javascript:void(0)"
            class="waves-effect waves-light btn btn-info"
            data-bs-toggle="modal"
            data-bs-target="#requestleave"
            ><i class="fa fa-plus fs-14"></i> Care Plan</a
          >
        </p>

        <!--Add Care Plan pop Start-->
        <add-care-plan></add-care-plan>
        <!--Add Care Plan pop End-->

        <div class="table-responsive">
          <table-base
            :dataTypes="{
              dataType: 'CarePlan',
              patient_id: patient_id,
              patient_unique_id: patient_unique_id,
              method: 'get',
              title:'No any care plan available.',
            }"
            :lastcol='permissioncheck(this.$store.state.permission.create_update)'
          >
            <template v-slot="slotProps">
              <td>{{ slotProps.item.plan_type }}</td>
              <td>{{ slotProps.item.title }}</td>
              <td>{{ slotProps.item.instructions }}</td>
              <td>{{ slotProps.item.snomed_code }}</td>
              <td>{{ $filters.displayDateTimeLocal(slotProps.item.scheduled_date,'',false,"ddd, MM-DD-YYYY",true) }}</td>
              <td>
                <div class="d-flex" v-if="
                      permissioncheck(
                        this.$store.state.permission.create_update
                      )
                    ">
                  <Popper arrow content="Edit Plan" :hover="true" class="helptips cursor-pointer">
                    <a
                      href="javascript:void(0)"
                      @click="careplanedit(slotProps.item.id)"
                      class="waves-effect waves-circle btn btn-circle btn-info btn-xs"
                      ><i class="fa fa-pencil"></i
                    ></a>
                  </Popper>
                  <Popper arrow content="Delete Plan" :hover="true" class="helptips cursor-pointer">
                    <a
                      href="javascript:void(0)"
                      @click="careplanremove(slotProps.item.id)"
                      class="waves-effect waves-circle btn btn-circle btn-danger btn-xs"
                      ><i class="fa fa-trash"></i
                    ></a>
                  </Popper>
                </div>
              </td>
            </template>
          </table-base>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Autocomplete from "vue3-autocomplete";
import TableBase from "@/components/base/Table.vue";
import SelectBoxStatic from "@/components/base/formFields/SelectBoxStatic.vue";
import InputField from "@/components/base/formFields/InputField.vue";
import DatePicker from "@/components/base/formFields/datePicker.vue";
import FormBtn from "@/components/base/formFields/formBtn.vue";
import SnomedCode from "@/components/patients/details/ClinicalDashboard/Snomedcode.vue";
import useVuelidate from "@vuelidate/core";
import submitMixins from "@/scripts/mixins/submitMixins.js";
import axios from "@/scripts/axios.js";
import permissionMixins from "@/scripts/mixins/permission.js";
import moment from "moment";
import Popper from "vue3-popper";

export default {
  mixins: [submitMixins, permissionMixins],
  setup: () => {
    return { v$: useVuelidate() };
  },
  components: {
    TableBase,
    SelectBoxStatic,
    InputField,
    DatePicker,
    FormBtn,
    SnomedCode,
    Autocomplete,
    Popper,
  },
  computed: {
    snomed_code: {
      get () {
        // return (this.selectValue) ? this.selectValue : null
        console.log('get method');
      },
      set (newValue) {        
        // this.selectValue.value = this.$store.state.CarePlan.form.snomed_code;
      }
    },
    form() {
      return this.$store.state.CarePlan.form;
    },
    vuelidateExternalResults() {
      return this.$store.state.CarePlan.vuelidateExternalResults;
    },
    validationRules() {
      return this.$store.state.CarePlan.validationRules;
    },
  },
  methods: {
    getlist(obj) {
      if (obj.length > 1) {
        this.$refs.autocomplete.$el.classList.add("selectloader");
        axios
          .get("/json_autocomplete/snomed-code", {
            params: { term: obj },
          })
          .then((response) => {
            //this.icd10 = response.data.data;
            this.icd10 = response.data.data.map((snomed) => {
              return { id: snomed.id, name: "<b>"+snomed.code+"</b><br>"+snomed.description,code:snomed.code,description:snomed.description }
            });
            this.$refs.autocomplete.$el.classList.remove("selectloader");
          });
      }
      if (obj.length == 0) {
        this.icd10 = {};
      }
    },
    resultsvalue(obj) {
      console.log(obj);
      this.$emit("clicked", obj);
      this.$refs.autocomplete.searchText = obj.code;
      this.$store.state.CarePlan.form.snomed_code = obj.code;
      this.icd10 = {};
      this.focusout();
    },
    focusout() {
      const btn = this.$refs.autocomplete;
      btn.$el
        .querySelector(".vue3-autocomplete-container > .autosearch-list")
        .blur();
    },
    resetForm() {
      var self = this;
      Object.keys(this.form).forEach(function(key, index) {
        self.form[key] = "";
      });
      this.form.snomed_code = '';
      this.$refs.autocomplete.searchText = '';
      this.scheduled_date = '';
      this.$store.state.CarePlan.edit = '';
      this.$store.state.CarePlan.form.snomed_code = '';
      this.v$.$reset();
    },
    getdate(){
      if($('#scheduled_date').val() == '') {
        this.form.scheduled_date = null;
      } else {
        this.form.scheduled_date =  $('#scheduled_date').val();
      }
    },
    careplanedit(id) {
      this.$store.state.CarePlan.edit = 1;
      axios
        .get("patient/" + this.$route.params.id + "/care-plan/edit/" + id)
        .then((result) => {
          this.$store.state.CarePlan.form = result.data.data;
          this.scheduled_date =  moment(String(result.data.data.scheduled_date)).format('MM/DD/YYYY');

          this.snomed_code = result.data.data.snomed_code;
          this.$refs.autocomplete.searchText = result.data.data.snomed_code;
        });
      $(".requestleave").modal("toggle");
    },
    snomedcodesearch(obj) {
      this.icd10table.push({
        id: obj.id,
        code: obj.code,
        describe: obj.describe,
      });
    },
    save(next) {
      if(this.form.scheduled_date != null){
        this.form.scheduled_date = moment(String(this.form.scheduled_date)).format('YYYY-MM-DD');
      }

      if (this.$store.state.CarePlan.edit == 0) {
        this.postForm(
          `/patient/${this.$route.params.id}/care-plan/store`,
          "CarePlan"
        );
      } else {
        this.postForm(
          `/patient/${this.$route.params.id}/care-plan/update`,
          "CarePlan"
        );
      }
    },
    close() {
      this.resetForm();
    },
    formPostSuccess(data) {
      this.$store.state.CarePlan.form = {};
      Object.assign(this.$store.state.CarePlan.form);
      this.v$.$reset();
      this.$refs.autocomplete.searchText = '';
      this.scheduled_date ='';
      this.$store.state.CarePlan.edit = 0;
      this.$store.dispatch("getSimpleTableContents", this.dataTypes);
      if (this.$store.state.CarePlan.edit == 0) {
        this.moshaToast("Record added successfully.", "success");
      } else {
        this.moshaToast("Record updated successfully.", "success");
      }
      this.$refs.autocomplete.searchText = '';
      this.scheduled_date ='';
      this.$store.state.CarePlan.edit = 0;
      $(".requestleave").modal("toggle");
    },
    careplanremove(id) {
      var delete_url = "patient/" + this.$route.params.id + "/care-plan/delete";
      this.$swal
        .fire({
          title: '<h2 class="mt-0">Are you sure?</h2>',
          text:
            "You want to delete these records? This process cannot be undone.",
          icon: "warning",
          dangerMode: true,
          showCancelButton: true,
          confirmButtonColor: "#ee3158",
          cancelButtonColor: "#bdbdbd",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed == true) {
            axios
              .post("patient/" + this.$route.params.id + "/care-plan/delete", {
                id: id,
              })
              .then((response) => {
                this.$swal
                  .fire(
                    "Deleted!",
                    "Your Care Plan has been deleted.",
                    "success"
                  )
                  .then((result) => {
                    if (result.isConfirmed == true) {
                      this.$store.dispatch(
                        "getSimpleTableContents",
                        this.dataTypes
                      );
                    }
                  });
              });
          }
        });
    },
  },
  mounted() {
    var d = new Date();
    var month = d.getMonth() + 1;
    var day = d.getDate();
    this.current_date =
      d.getFullYear() +
      "-" +
      (("" + month).length < 2 ? "0" : "") +
      month +
      "-" +
      (("" + day).length < 2 ? "0" : "") +
      day;
  },
  data() {
    return {
      icd10: [],
      current_date: "",
      dateFormat: 'MM-dd-yyyy',
      waterMarkText : 'Choose a date',
      strict: true,
      maxVal: new Date(),
      icd10table: [],
      patient_id: this.$store.state.patientDetail.patient_id,
      patient_unique_id: this.$store.state.patientDetail.patient_unique_id,
      plan_type: [
        { value: "Goal", title: "Goal" },
        { value: "Patient education", title: "Patient education" },
        { value: "Patient decision aid", title: "Patient decision aid" },
        {
          value: "Patient clinical instruction",
          title: "Patient clinical instruction",
        },
        {
          value: "Referral to other provider",
          title: "Referral to other provider",
        },
        { value: "Health Concerns", title: "Health Concerns" },
        { value: "Future Order", title: "Future Order" },
        { value: "Medication Order", title: "Medication Order" },
        { value: "Future Visit", title: "Future Visit" },
        { value: "Lab Order", title: "Lab Order" },
      ],
      dataTypes: {
        dataType: "CarePlan",
        patient_id: this.$store.state.patientDetail.patient_id,
        patient_unique_id: this.$store.state.patientDetail.patient_unique_id,
        method: "get",
      },
    };
  },
  validations() {
    return this.$store.state.CarePlan.validationRules;
  },
};
</script>
