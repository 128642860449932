<template>
    <div class="box">
    <div class="box-body">
        <div class="tab-pane animation-fade active" id="category-1" role="tabpanel">
            <div class="panel-group panel-group-simple panel-group-continuous mb-0" id="accordion2" aria-multiselectable="true" role="tablist">
                
                <!--Intervention start-->
                <Intervention></Intervention>
                <!--Intervention end-->
                <!--Assessment start-->
                <Assessment></Assessment>
                <!--Assessment End-->
                <!--Condition/Diagnosis/Problem start-->
                <Problem></Problem>
                <!--Condition/Diagnosis/Problem end-->

                <div class="panel pagecollap">
                    <div class="panel-heading" id="condition-diagnosis" role="tab">
                        <a class="panel-title collapsed font-bold" aria-controls="answer-4" aria-expanded="false" data-bs-toggle="collapse" href="#answer-4" data-parent="#accordion2">Encounters</a>
                    </div>
                    <div class="panel-collapse collapse" id="answer-4" aria-labelledby="condition-diagnosis" role="tabpanel" data-bs-parent="#category-1" style="">
                        <div class="panel-body">
                            <div class="d-inline-block w-p100">
                                <p class="d-inline-block mb-2">(Generated from Billing)</p>
                            </div>
                            <div class="table-responsive">
                                <table class="table table-hover mb-0">
                                    <thead>
                                        <tr>
                                            <th>Encounter Date</th>
                                            <th>CPT</th>
                                            <th>Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-if="encounterList.length>0" v-for="(item, index) in encounterList" :key="index">
                                            <td>{{ $filters.displayDateTimeLocal(item.schedule_date_time,'',false,"ddd, MM-DD-YYYY",true) }}</td>
                                            <td>{{ item.code }}</td>
                                            <td>{{ item.code_description }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <!--Procedure start-->
                <Procedure></Procedure>
                <!--Procedure end-->
             
                <div class="panel pagecollap">
                    <div class="panel-heading" id="condition-diagnosis" role="tab">
                        <a class="panel-title collapsed font-bold" aria-controls="answer-6" aria-expanded="false" data-bs-toggle="collapse" href="#answer-6" data-parent="#accordion2">Lab Results</a>
                    </div>
                    <div class="panel-collapse collapse" id="answer-6" aria-labelledby="condition-diagnosis" role="tabpanel" data-bs-parent="#category-1" style="">
                        <div class="panel-body">
                            <div class="d-inline-block w-p100">
                                <p class="d-inline-block mb-2">(Generated from Lab Orders)</p>
                            </div>
                            <div class="table-responsive">
                                <table class="table table-hover mb-0">
                                    <thead>
                                        <tr>
                                            <th>Performed</th>
                                            <th>LOINC Code </th>
                                            <th>Status</th>
                                            <th>Value</th>
                                            <th>Units</th>
                                            <th>Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-if="labOrderList.length>0" v-for="(item, index) in labOrderList" :key="index">
                                            <td>{{ $filters.displayDateTimeLocal(item.date_of_testing,'',false,"ddd, MM-DD-YYYY",true) }}</td>
                                            <td>{{ item.loinc_code }}</td>
                                            <td>{{ item.status }}</td>
                                            <td>{{ item.result_value }}</td>
                                            <td>{{ item.result_unit }}</td>
                                            <td>{{ item.loinc_description }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="panel pagecollap">
                    <div class="panel-heading" id="condition-diagnosis" role="tab">
                        <a class="panel-title collapsed font-bold" aria-controls="answer-7" aria-expanded="false" data-bs-toggle="collapse" href="#answer-7" data-parent="#accordion2">Functional Status</a>
                    </div>
                    <div class="panel-collapse collapse" id="answer-7" aria-labelledby="condition-diagnosis" role="tabpanel" data-bs-parent="#category-1" style="">
                        <div class="panel-body">
                            <div class="d-inline-block w-p100">
                                <p class="d-inline-block mb-2">(Generated from Clinical Dashboard -> Functional Status )</p>
                            </div>
                           <div class="table-responsive">
                             <table-base
                               :dataTypes="{
                                 dataType: 'FunctionalStatus',
                                 patient_id: patient_id,
                                 patient_unique_id: patient_unique_id,
                                 status: 'Results Received',
                                 method: 'get',
                               }"
                             >
                               <template v-slot="slotProps">
                                 <td>{{ slotProps.item.snomed_code }}</td>
                                 <td>{{ slotProps.item.snomed_description }}</td>
                                 <td>
                                   {{ $filters.displayDateTimeLocal(slotProps.item.observation_date,'',false,"ddd, MM-DD-YYYY",true) }}
                                 </td>
                               </template>
                             </table-base>
                           </div>
                        </div>
                    </div>
                </div>
                <!--Medication start-->
                <Medication></Medication>
                <!--Medication end-->
            </div>
        </div>
    </div>
</div>
</template>
<script>
import axios from "@/scripts/axios.js";
import submitMixins from "@/scripts/mixins/submitMixins.js";
import permissionMixins from "@/scripts/mixins/permission.js";
import useVuelidate from '@vuelidate/core'
import TableBase from "@/components/base/Table.vue";
import Problem from "@/components/patients/details/CQM/Problem.vue";
import Intervention from "@/components/patients/details/CQM/Intervention.vue";
import Medication from "@/components/patients/details/CQM/Medication.vue";
import Procedure from "@/components/patients/details/CQM/Procedure.vue";
import Assessment from "@/components/patients/details/CQM/Assessment.vue";
export default {
    mixins: [submitMixins,permissionMixins],
    components : {
        TableBase,
        Intervention,
        Procedure,
        Problem,
        Assessment,
        Medication,
    },
    setup () {
        return { v$: useVuelidate() }
    },
    name: "cqm",
    data() {
        return {
            patient_id: this.$store.state.patientDetail.patient_id,
            patient_unique_id: this.$store.state.patientDetail.patient_unique_id,
            problemList : [],
            labOrderList : [],
            encounterList : [],
        };
    },
    computed: { 
       
    },
    mounted() {
    },
    beforeCreate() {
    },
    created() {
       
        axios.get(`/patient/${this.$route.params.id}/lab-order/cqm_result`, {
             params: {'patient_id': this.patient_id}
         }).then(response => {
            this.labOrderList = response.data.data;
        });

        axios.post(`appointment-billing/cqm_encounter`,{'patient_id': this.patient_id}).then(response => {
            this.encounterList = response.data.data;
            console.log(this.encounterList);
        });
    },
    methods: {
    }
  }
</script>