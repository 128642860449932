<template>
	<div class="content-wrapper" style="min-height: 256px;">
		<div class="container-full">
			<div class="content-header">
				<h2 class="heading2 mb-0">Audit Log Report</h2>
			</div>
			<section class="content">
				<div class="box">
					<div class="box-body">
						<div class="row justify-content-sm-center">
							<div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-2">
								<div class="form-group mb-5">
									<label class="form-label">Patient</label>
									<input type="text" v-model="filter.filter_patient" class="form-control"
										placeholder="Patient Name" />
								</div>
							</div>
							<div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-2">
								<div class="form-group mb-5">
									<label class="form-label">User Name</label>
									<Multiselect class="form-control" v-model="filter.filter_user_id" mode="tags"
										:searchable="true" valueProp="id" placeholder="Select Name" label="name"
										trackBy="name" :options="user_list_option" :canClear="false" />
								</div>
							</div>
							<div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-2">
								<div class="form-group mb-5">
									<label class="form-label">Type</label>
									<Multiselect class="form-control" v-model="filter.filter_log_type" mode="tags"
										:searchable="true" valueProp="id" placeholder="Select Type" label="name"
										trackBy="name" :options="type_list_option" :canClear="false" />
								</div>
							</div>
							<div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-2">
								<div class="form-group mb-5">
									<label class="form-label">Action</label>
									<Multiselect class="form-control" v-model="filter.filter_action" mode="tags"
										:searchable="true" valueProp="id" placeholder="Select Action" label="name"
										trackBy="name" :options="action_list_option" :canClear="false" />
								</div>
							</div>
							<div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 px-5">
								<div class="form-group">
									<label class="form-label">Choose Date Range</label>
									<div class="form-group mb-5 availdate">
										<ejs-daterangepicker id="daterangepicker" :placeholder="waterMarkText"
											:format="dateFormat" :openOnFocus="true" @select="changeDateRangePicker"
											@change="changeDatePicker" :value="filter.date_range" maxDays=31
											:strictMode="mode" :htmlAttributes="htmlAttributes" :showCustomRangeLabel=false>
											<e-presets>
												<e-preset label="Today" :start='today' :end='today'></e-preset>
												<e-preset label="Yesterday" :start='yesterday' :end='yesterday'></e-preset>
												<e-preset label="Last 7 days" :start='last7days' :end='today'></e-preset>
												<e-preset label="Last 30 days" :start='last30days' :end='today'></e-preset>
												<e-preset label="This Month" :start='thisMonth' :end='today'></e-preset>
												<e-preset label="Last Month" :start='lastStart' :end='lastEnd'></e-preset>
											</e-presets>
										</ejs-daterangepicker>
									</div>
								</div>
							</div>
							<div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-2">
								<div class="form-group">
									<label class="form-label">Month & Year</label>
									<input type="month" class="form-control monthDateTimePicker"
										v-model="filter.filter_monthyear" @change="changeMonthDatePicker" />
								</div>
							</div>
							<div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-2">
								<div class="mt-25">
									<button class="b-0 waves-effect waves-light btn btn-info py-7 me-1"
										@click="getData('filter')">
										Search
									</button>
									<button class="b-0 waves-effect waves-light btn btn-info-light py-7"
										@click="clearFilter()" style="width: 81px;">
										Clear
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="box">
					<div class="box-body">
						<div class="table-responsive">
							<table id="audittable" class="table table-hover">
								<thead>
									<tr>
										<th width="5%">No.</th>
										<th width="10%">Patient</th>
										<th width="10%">Changed by</th>
										<th width="10%">Section</th>
										<th width="5%">Operation</th>
										<th width="35%">Details</th>
										<th width="15%">Log Updated At</th>
										<th width="10%">IP Address</th>
									</tr>
								</thead>
								<tbody>
									<tr class="hover-primary" v-for="(item, index) in tableData" :key="index">
										<td class="align-top"></td>
										<td class="align-top">{{ patient_list[item.patient_id] }}</td>
										<td class="align-top">{{ user_list[item.user_id] }}</td>
										<td class="align-top">{{ item.log_type }}</td>
										<td class="align-top">{{ item.action }}</td>
										<td class="word-break-all align-top" v-html="item.detail"></td>
										<td class="align-top" :data-order='item.updated_at_timestamp'>{{
											$filters.displayDateTimeLocal(item.updated_at, '',false, "MM-DD-Y, h:mm A") }}</td>
										<td class="align-top">{{ item.ip }}</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</section>
		</div>
	</div>
</template>

<script>
import "datatables.net-dt/js/dataTables.dataTables";
import $ from "jquery";
import permissionMixins from "@/scripts/mixins/permission.js";
import axios from "@/scripts/axios.js";
import Multiselect from "@vueform/multiselect";
import submitMixins from "@/scripts/mixins/submitMixins.js";
import moment from "moment";
export default {
	mixins: [permissionMixins, submitMixins],
	name: "Home",
	components: { Multiselect },
	data() {
		return {
			key: 0,
			tableData: [],
			patient_list: [],
			user_list: [],
			user_list_option: [],
			action_list_option: ["Create", "Update", "Delete", "View"],
			type_list_option: [
				"Staff",
				"Patient",
				"Patient Document",
				"Smoking Status",
				"Functional Status",
				"Cognitive Status",
				"Patient Vital",
				"Provider",
				"Visit Profile",
				"Billing Profile",
				"Consent Form",
				"Lab Order",
				"Patient Radiology",
				"Implantable Device",
				"Appointment",
				"Provider",
				"Problem List",
				"Care Team Member's",
				"Care Plan",
				"Leave",
				"Visit Profile",
				"Billing Profile",
				"Consent Form",
				"Lab Order",
				"Care Plan",
				"Patient Demographics",
				"Custom Procedure",
				"Patient Portal",
				"Emergency Access",
				"Role",
				"User Permission",
				"Prospect Patient",
				"Patient Contract",
				"PWM Admin",
			],
			filter: {
				filter_user_id: null,
				filter_patient: "",
				filter_log_type: null,
				filter_action: null,
				filter_monthyear: '',
				lastKey: null,
			},

			htmlAttributes: { showCustomRangeLabel: false },
			waterMarkText: "Select a Range",
			dateFormat: "MM-dd-yyyy",
			today: new Date(new Date().toDateString()),
			yesterday: new Date(new Date(new Date().setDate(new Date().getDate() - 1)).toDateString()),
			last7days: new Date(new Date(new Date().setDate(new Date().getDate() - 7)).toDateString()),
			weekStart: new Date(new Date(new Date().setDate(new Date().getDate() - (new Date().getDay() + 7) % 7)).toDateString()),
			weekEnd: new Date(new Date(new Date().setDate(new Date(new Date().setDate((new Date().getDate()
				- (new Date().getDay() + 7) % 7))).getDate() + 6)).toDateString()),
			last30days: new Date(new Date(new Date().setDate(new Date().getDate() - 30)).toDateString()),
			lastStart: new Date(new Date(new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(1)).toDateString()),
			lastEnd: new Date(new Date(new Date().setDate(0)).toDateString()),
			thisMonth: new Date(moment().subtract(1, 'month').startOf('month'))
		};
	},
	mounted() {
		this.getData("filter");
		axios
			.get("/json_dropdown/user-list")
			.then((response) => (this.user_list_option = response.data.data));
	},
	created() {
		this.filter.date_range = this.getFormattedDate(new Date(new Date(new Date().setDate(new Date().getDate() - 7)).toDateString()))
			+ " - " + this.getFormattedDate(new Date(new Date().toDateString()));
		this.filter.filter_monthyear = '';
	},
	methods: {
		formatDate(date, format) {
			return moment(date).format(format);
		},
		getData(type) {
			if (this.filter.filter_monthyear == "" && this.filter.date_range == "") {
				return this.moshaToast("Please select year and month or choose date and range.", "danger");
			}
			if (type === "filter") {
				this.$store.state.loader = true;
				$("#audittable").DataTable().destroy();
				axios.post("audit-log", this.filter).then((res) => {
					(this.tableData = res.data.data.audit_log),
						(this.filter.lastKey = res.data.data.lastKey),
						(this.patient_list = res.data.data.patient_list),
						(this.user_list = res.data.data.user_list);
					this.initializeTable();
				});
			}
		},
		initializeTable() {
			$("#audittable").DataTable().destroy();
			this.$store.state.loader = true;
			setTimeout(() => {
				var t = $("#audittable").DataTable({
					"order": [[6, 'desc']],
					"columnDefs": [
						{ "type": "date", "targets": 6 }
					],
				});
				t.on('order.dt search.dt', function () {
					let i = 1;
					t.cells(null, 0, { search: 'applied', order: 'applied' }).every(function (cell) {
						this.data(i++);
					});
				}).draw();
				let elem = document.getElementsByName("audittable_length")[0];
				elem.classList.add("form-select");
				elem.classList.add("form-control-sm");
				elem.style.cssText += `border-color: #86a4c3 !important`;
				elem = document.getElementsByTagName("input")[1];
				elem.style.cssText += `outline: none`;
				this.$store.state.loader = false;
			}, 500);
		},
		clearFilter() {
			this.filter.filter_user_id = null;
			this.filter.filter_patient = "";
			this.filter.filter_log_type = null;
			this.filter.filter_action = null;
			this.filter.filter_monthyear = '';
			this.filter.date_range = this.getFormattedDate(new Date(new Date(new Date().setDate(new Date().getDate() - (new Date().getDay() + 7) % 7)).toDateString())) + " - " + this.getFormattedDate(new Date(new Date(new Date().setDate(new Date(new Date().setDate((new Date().getDate() - (new Date().getDay() + 7) % 7))).getDate() + 6)).toDateString()));
			this.getData('filter');
			this.key++;
		},
		getdate() {
			this.filter.date_range = $("#daterangepicker").val();
		},
		getFormattedDate(date) {
			var year = date.getFullYear();
			var month = (1 + date.getMonth()).toString();
			month = month.length > 1 ? month : '0' + month;
			var day = date.getDate().toString();
			day = day.length > 1 ? day : '0' + day;
			return month + '-' + day + '-' + year;
		},
		changeMonthDatePicker() {
			this.filter.date_range = '';
		},
		changeDateRangePicker() {
			this.filter.filter_monthyear = '';
			this.getdate();
		},
		changeDatePicker() {
			this.getdate();
		}
	},
	computed: {},
};
</script>