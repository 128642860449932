<template >
  <div class='e-kanbanTooltipTemp'>
        <table>
            <tr>
                <td class="details">
                    <table>
                        <colgroup>
                            <col style="width:45%">
                            <col style="width:50%">
                        </colgroup>
                        <tbody>
                            <tr>
                                <td class="CardHeader">Source :</td>
                                <td>{{ data.legacy_source ? data.legacy_source : '-' }}</td>
                            </tr>
                            <tr>
                                <td class="CardHeader">Wellness Coordinator :</td>
                                <td>{{ data.wellness_coordinator_name ? data.wellness_coordinator_name : '-' }}</td>
                            </tr>
                            <tr>
                                <td class="CardHeader">Referral Source :</td>
                                <td>{{ data.marketing_consultant_name ? data.marketing_consultant_name : '-' }}</td>
                            </tr>
                            <!-- <tr>
                                <td class="CardHeader">Notes :</td>
                                <td>{{ data.notes }}</td>
                            </tr> -->
                        </tbody>
                    </table>
                </td>
            </tr>
        </table>
    </div>
</template>
<script>
   export default {
      data () {
        return {
        }
      },
      methods: {
      }
   }
</script>
<style type="text/css">
  e-kanbanTooltipTemp {
    width: 250px;
    padding: 3px;
}

.e-kanbanTooltipTemp>table {
    width: 100%;
}

.e-kanbanTooltipTemp td {
    vertical-align: top;
}
</style>