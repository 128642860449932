<template>
    <div class="panel pagecollap">
        <div class="panel-heading" id="summarycareprovided" role="tab">
            <a class="panel-title collapsed font-bold active" aria-controls="answer-1" aria-expanded="true" data-bs-toggle="collapse" href="#answer-1" data-parent="#accordion2">Intervention</a>
        </div>
        <div class="panel-collapse active collapse show" id="answer-1" aria-labelledby="summarycareprovided" role="tabpanel" data-bs-parent="#category-1" style="">
            <div class="panel-body">
                <div class="text-end">
                    <a v-if="permissioncheck(this.$store.state.permission.create_update)" href="javascript:void(0);" class="waves-effect waves-light btn btn-info mb-10 interventionbtn" @click="create"><i class="fa fa-plus fs-14"></i> Intervention</a>
                </div>
                <div class="table-responsive">
                    <div class="table-responsive" id="assessment_list">
                        <table-base 
                            :dataTypes="{
                                dataType: 'Intervention',
                                patient_id: patient_id,
                                patient_unique_id:patient_unique_id,
                                method:'post'}"
                            :lastcol='permissioncheck(this.$store.state.permission.create_update)'
                        >
                            <template v-slot="slotProps">
                               <td>{{ slotProps.item.intervention_code }}</td>
                               <td>{{ slotProps.item.intervention_description}}</td>
                               <td>{{ slotProps.item.reason_code }}</td>
                               <td>{{ slotProps.item.reason_description}}</td>
                               <td>{{ slotProps.item.negation}}</td>
                               <td>
                                    <slot v-if="slotProps.item.is_order==1">
                                        True
                                    </slot>
                                    <slot v-else>
                                        False
                                    </slot>
                                </td>
                               <td>{{ $filters.displayDateTimeLocal(slotProps.item.observation_date,'',false,"ddd, MM-DD-YYYY",true) }}</td>
                               <td>
                                    <div class="d-flex">
                                        <Popper arrow content="Edit Intervention" :hover="true" class="helptips cursor-pointer">
                                            <a 
                                            v-if="permissioncheck(this.$store.state.permission.create_update)"
                                            href="javascript:void(0)" @click="editCQMIntervention(slotProps.item.id)" class="waves-effect waves-circle btn btn-circle btn-info btn-xs" ><i class="fa fa-pencil"></i></a>
                                        </Popper>
                                        <Popper arrow content="Delete Intervention" :hover="true" class="helptips cursor-pointer">
                                            <a v-if="permissioncheck(this.$store.state.permission.create_update)" href="javascript:void(0)" @click="removeCQMIntervention(slotProps.item.id)" class="waves-effect waves-circle btn btn-circle btn-danger btn-xs"><i class="fa fa-trash"></i></a>
                                        </Popper>
                                    </div>
                                </td>
                            </template>
                        </table-base>
                    </div>
                </div>
            </div>

            <div class="box bg-lightest new-intervention d-none" id="new-intervention">
                <div class="box-body">
                    <div class="content-header">
                        <h3 class="heading3 font-bold m-0">{{title}}</h3>
                    </div>
                    <div class="form-group row" :class="{ error: v$.form.intervention_code.$errors.length }">
                        <label class="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end col-form-label">Intervention <small class="text-danger asterisksign">*</small></label>
                        <div class="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-3">
                            <div class="selectcontrols ">
                                <Autocomplete
                                    @input="intervention_code"
                                    ref="intervention_code"
                                    :results="intervention_codeList"
                                    @onSelect="intervention_codevalue"
                                    class="form-control"
                                    :value="modelValue"
                                    placeholder="Search SNOMED Code"
                                    :use-html-for-results ="true"
                                ></Autocomplete>
                            </div>
                            <div class="error-msg" v-if="v$.form.intervention_code.$errors.length > 0">
                                <ul class="mb-0 p-0 fs-14">
                                    <li class="d-block" v-for="(error, index) of v$.form.intervention_code.$errors">
                                        {{ error.$message }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row" :class="{ error: v$.form.intervention_description.$errors.length }">
                        <label class="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end">Description <small class="text-danger asterisksign">*</small></label>
                        <div class="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-3">
                            <div class="selectcontrols ">
                                <Autocomplete
                                    @input="intervention_description"
                                    ref="intervention_description"
                                    :results="intervention_descriptionList"
                                    @onSelect="intervention_descriptionvalue"
                                    class="form-control"
                                    :value="modelValue"
                                    placeholder="Search SNOMED Description"
                                    :use-html-for-results ="true"
                                ></Autocomplete>
                            </div>
                            <div class="error-msg" v-if="v$.form.intervention_description.$errors.length > 0">
                                <ul class="mb-0 p-0 fs-14">
                                    <li class="d-block" v-for="(error, index) of v$.form.intervention_description.$errors">
                                        {{ error.$message }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    
                    <div class="form-group row">
                        <label class="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end">Reason</label>
                        <div class="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-3">
                            <div class="selectcontrols ">
                                <Autocomplete
                                    @input="reason_code"
                                    ref="autocompletecode"
                                    :results="CodeList"
                                    @onSelect="codevalue"
                                    class="form-control"
                                    :value="modelValue"
                                    placeholder="Search SNOMED Code"
                                    :use-html-for-results ="true"
                                ></Autocomplete>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end">Description</label>
                        <div class="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-3">
                            <div class="selectcontrols ">
                                <Autocomplete
                                    @input="inputdescription"
                                    ref="autocompletedescription"
                                    :results="CodeLists"
                                    @onSelect="descriptionvalue"
                                    class="form-control"
                                    :value="modelValue"
                                    placeholder="Search SNOMED Description"
                                    :use-html-for-results ="true"
                                ></Autocomplete>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row" :class="{ error: v$.form.observation_date.$errors.length }">
                        <label class="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end">Observation Date <small class="text-danger asterisksign">*</small></label>
                        <div class="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-3 availdate">
                            <!-- <input
                                v-model="form.observation_date"
                                class="form-control"
                                type="date"
                                id="example-date-input"
                                :max="current_date"
                            /> -->
                            <ejs-datepicker 
                                :value="observation_date" 
                                id="observation_date" 
                                :format="dateFormat" 
                                placeholder="Observation Date"
                                @change="getdate" 
                                :strictMode='strict' 
                                :max='maxVal' 
                                :showClearButton='false' 
                                :blur="getdate"
                                :openOnFocus='true'>
                            </ejs-datepicker>
                            <div class="error-msg" v-if="v$.form.observation_date.$errors.length > 0">
                                <ul class="mb-0 p-0 fs-14">
                                    <li class="d-block" v-for="(error, index) of v$.form.observation_date.$errors">
                                        {{ error.$message }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-3 form-label mb-0 text-sm-end">Negation</label>
                        <div class="col-6 col-sm-8 col-md-8 col-lg-5 col-xl-3">
                            <input
                              type="checkbox"
                              id="negation_checkbox"
                              :checked="form.is_negation"
                              v-model="form.is_negation"
                              :value="form.is_negation"
                              class="filled-in chk-col-info"
                              @change="checkNegation"
                            />
                            <label for="negation_checkbox" class="m-0 text-start"></label>
                        </div>
                    </div>
                    <div class="form-group row" v-if="form.is_negation">
                        <label class="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end">Negation Reason</label>
                        <div class="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-3">
                            <select class="form-control" v-model="form.negation">
                                <option value="" selected="selected" disabled="disabled" >Select Negation Reason</option>
                                <option v-for="(item, index) in negation" :key="index" :value="item.name">{{item.name}}</option>
                            </select>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-3 form-label mb-0 text-sm-end">Is Order ?</label>
                        <div class="col-6 col-sm-8 col-md-8 col-lg-5 col-xl-3">
                            <input
                              type="checkbox"
                              id="is_order_checkbox"
                              :checked="form.is_order"
                              v-model="form.is_order"
                              :value="form.is_order"
                              class="filled-in chk-col-info"
                            />
                            <label for="is_order_checkbox" class="m-0 text-start"></label>
                        </div>
                    </div>

                    <div class="row mb-10">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-9 text-center">
                            <form-btn
                                :title="btn"
                                btnCls="waves-effect waves-light btn btn-info"
                                @click="save(false)"
                            ></form-btn>
                            <button @click="close" type="button" class="waves-effect waves-light btn btn-danger ">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import TableBase from "@/components/base/Table.vue";
import useVuelidate from "@vuelidate/core";
import submitMixins from "@/scripts/mixins/submitMixins.js";
import Autocomplete from "vue3-autocomplete";
import InputField from "@/components/base/formFields/InputField.vue";
import permissionMixins from "@/scripts/mixins/permission.js";
import FormBtn from "@/components/base/formFields/formBtn.vue";
import axios from "@/scripts/axios.js";
import moment from "moment";
import Popper from "vue3-popper";

export default {
    setup: () => {
        return { v$: useVuelidate() };
    },
    mixins: [submitMixins, permissionMixins],
    components: { 
        TableBase,
        Autocomplete,
        InputField,
        FormBtn,
        Popper,
    },
    computed: {
        form() {
            return this.$store.state.Intervention.form;
        },
        vuelidateExternalResults() {
            return this.$store.state.Intervention.vuelidateExternalResults;
        },
        validationRules() {
            return this.$store.state.Intervention.validationRules;
        },
    },
    validations() {
        return this.$store.state.Intervention.validationRules;
    },
    data() 
    {
        return {
            CodeList:[],
            CodeLists:[],
            current_date:'',
            patient_id : this.$store.state.patientDetail.patient_id,
            patient_unique_id:this.$store.state.patientDetail.patient_unique_id,
            dataTypes: {
                dataType: "Intervention",
                patient_id: this.$store.state.patientDetail.patient_id,
                patient_unique_id: this.$store.state.patientDetail.patient_unique_id,
            },
            title:'Create Intervention',
            btn:'Save',
            intervention_codeList:{},
            intervention_descriptionList:{},
            negation:{},
            strict: true,
            maxVal: new Date(),
            dateFormat: 'MM-dd-yyyy',
        };
    },
    methods:{
        getdate(){
            if($('#observation_date').val() == ''){
                this.form.observation_date = null;
              }else{
                this.observation_date = $('#observation_date').val();
                this.form.observation_date = moment(String(this.observation_date)).format('YYYY-MM-DD');
              }
        },
        create(){
            $("#new-intervention").removeClass("d-none");
            this.$store.state.Intervention.form = {};
            Object.assign(this.$store.state.Intervention.form);
            this.v$.$reset();
            this.$refs.autocompletecode.searchText = ""
            this.$refs.autocompletedescription.searchText = ""
            this.$refs.intervention_code.searchText = ""
            this.$refs.intervention_description.searchText = ""
            this.observation_date = "";
            this.$smoothScroll({
                scrollTo: document.getElementById("new-intervention"),
            });
        },
        checkNegation(event) {
            console.log(this.form.is_negation);
        },
        intervention_code(obj) {
            if (obj.length > 1) {
                this.$refs.intervention_code.$el.classList.add("selectloader");
                axios
                .get("/json_autocomplete/snomed-code-with-data-element", {
                    params: { term: obj,data_element:'intervention_performed,intervention_order_intervention_performed' },
                })
                .then((response) => {
                    //this.intervention_codeList = response.data.data;
                    this.intervention_codeList = response.data.data.map((intervention_code) => {
                      return { id: intervention_code.id, name: "<b>"+intervention_code.code+"</b><br>"+intervention_code.description,code:intervention_code.code,description:intervention_code.description }
                    });
                    this.$refs.intervention_code.$el.classList.remove("selectloader");
                });
            }   
            if (obj.length == 0) {
                this.intervention_codeList = {}; 
                this.form.intervention_code = '';
                this.form.intervention_description = '';
                this.$refs.intervention_code.searchText = '';
                this.$refs.intervention_description.searchText = '';
            }
        },
        intervention_codevalue(obj){
            this.form.intervention_code = obj.code
            this.form.intervention_description = obj.description
            this.$refs.intervention_code.searchText = obj.code
            this.$refs.intervention_description.searchText = obj.description
            this.$emit("update:modelValue", obj.code);
        },
        intervention_description(obj) {
            if (obj.length > 1) {
                this.$refs.intervention_description.$el.classList.add("selectloader");
                axios
                .get("/json_autocomplete/snomed-code-with-data-element", {
                    params: { term: obj,data_element:'intervention_performed,intervention_order_intervention_performed' },
                })
                .then((response) => {
                    //this.intervention_descriptionList = response.data.data;
                    this.intervention_descriptionList = response.data.data.map((intervention_description) => {
                      return { id: intervention_description.id, name: "<b>"+intervention_description.code+"</b><br>"+intervention_description.description,code:intervention_description.code,description:intervention_description.description }
                    });
                    this.$refs.intervention_description.$el.classList.remove("selectloader");
                });
            }   
            if (obj.length == 0) {
                this.intervention_descriptionList = {};
                this.form.intervention_code = '';
                this.form.intervention_description = '';
                this.$refs.intervention_code.searchText = '';
                this.$refs.intervention_description.searchText = '';    
            }
        },
        intervention_descriptionvalue(obj){
            this.form.intervention_code = obj.code
            this.form.intervention_description = obj.description
            this.$refs.intervention_code.searchText = obj.code
            this.$refs.intervention_description.searchText = obj.description
            this.$emit("update:modelValue", obj.code);
        },
        reason_code(obj) {
            if (obj.length > 1) {
                this.$refs.autocompletecode.$el.classList.add("selectloader");
                axios
                .get("/json_autocomplete/snomed-code-with-data-element", {
                    params: { term: obj,data_element:'intervention_order_reason' },
                })
                .then((response) => {
                    //this.CodeList = response.data.data;
                    this.CodeList = response.data.data.map((codelist) => {
                      return { id: codelist.id, name: "<b>"+codelist.code+"</b><br>"+codelist.description,code:codelist.code,description:codelist.description }
                    });
                    this.$refs.autocompletecode.$el.classList.remove("selectloader");
                });
            }   
            if (obj.length == 0) {
                this.CodeList = {};    
                this.$refs.autocompletecode.searchText = '';
                this.$refs.autocompletedescription.searchText = '';
                this.form.reason_code = '';
                this.form.reason_description = '';
            }
        },
        inputdescription(obj) {
            if (obj.length > 1) {
                this.$refs.autocompletedescription.$el.classList.add("selectloader");
                axios
                .get("/json_autocomplete/snomed-code-with-data-element", {
                    params: { term: obj,data_element:'assessment_result' },
                })
                .then((response) => {
                    //this.CodeLists = response.data.data;
                    this.CodeLists = response.data.data.map((Codedec) => {
                      return { id: Codedec.id, name: "<b>"+Codedec.code+"</b><br>"+Codedec.description,code:Codedec.code,description:Codedec.description }
                    });
                    this.$refs.autocompletedescription.$el.classList.remove("selectloader");
                });
            }   
            if (obj.length == 0) {
                this.CodeLists = {};
                this.$refs.autocompletecode.searchText = '';
                this.$refs.autocompletedescription.searchText = '';
                this.form.reason_code = '';
                this.form.reason_description = '';
            }
        },
        codevalue(obj){
            this.form.reason_code = obj.code
            this.form.reason_description = obj.description
            this.$refs.autocompletecode.searchText = obj.code
            this.$refs.autocompletedescription.searchText = obj.description
            this.$emit("update:modelValue", obj.code);
        },
        descriptionvalue(obj){
            this.form.reason_code = obj.code
            this.form.reason_description = obj.description
            this.$refs.autocompletecode.searchText = obj.code
            this.$refs.autocompletedescription.searchText = obj.description
            this.$emit("update:modelValue", obj.code);  
        },
        editCQMIntervention(id){
            this.$store.state.Intervention.edit = 1;
            this.title = 'Edit Intervention';
            this.btn = 'Update';
            axios.get("patient/" + this.$route.params.id + "/cqm-intervention/edit/" + id)
            .then((result) => {
                this.$store.state.Intervention.form = result.data.data;
                this.$refs.intervention_code.searchText = result.data.data.intervention_code
                this.$refs.intervention_description.searchText = result.data.data.intervention_description
                this.$refs.autocompletecode.searchText = result.data.data.reason_code;
                this.$refs.autocompletedescription.searchText = result.data.data.reason_description
                if(this.form.observation_date != null){
                    this.observation_date = moment(String(this.form.observation_date)).format('MM-DD-YYYY');
                }
            });
            $("#new-intervention").removeClass("d-none");
            this.$smoothScroll({
                scrollTo: document.getElementById("new-intervention"),
            });
        },
        removeCQMIntervention(id){
            this.$swal.fire({
                title: '<h2 class="mt-0">Are you sure?</h2>',
                text:"You want to delete these records? This process cannot be undone.",
                icon: "warning",
                dangerMode: true,
                showCancelButton: true,
                confirmButtonColor: "#ee3158",
                cancelButtonColor: "#bdbdbd",
                confirmButtonText: "Yes, delete it!",
            })
            .then((result) => {
                if (result.isConfirmed == true) {
                    axios
                    .post("patient/" + this.$route.params.id + "/cqm-intervention/delete", {id: id,})
                    .then((response) => {
                        this.$swal
                            .fire(
                                "Deleted!",
                                "Your Care Plan has been deleted.",
                                "success"
                            )
                        .then((result) => {
                            if (result.isConfirmed == true) {
                                this.$store.dispatch("getSimpleTableContents",this.dataTypes);
                            }
                        });
                    });
                }
            });
        },
        save(next) {
            if(this.$store.state.Intervention.edit == 1){
                this.postForm(`/patient/${this.$route.params.id}/cqm-intervention/update`,"Assessment");
            }
            else{
                this.postForm(`/patient/${this.$route.params.id}/cqm-intervention/store`,"Assessment");   
            }
        },
        formPostSuccess(data) {
            this.$store.state.Intervention.form = {};
            Object.assign(this.$store.state.Intervention.form);
            this.v$.$reset();
            this.$refs.autocompletecode.searchText = ""
            this.$refs.autocompletedescription.searchText = ""
            this.$refs.intervention_code.searchText = ""
            this.$refs.intervention_description.searchText = ""
            this.observation_date = "";
            this.$store.dispatch("getSimpleTableContents", this.dataTypes);
            if(this.$store.state.Intervention.edit == 1){
                this.moshaToast("Record added successfully.", "success");
            }
            else{
                this.moshaToast("Record updated successfully.", "success");   
            }
            $("#new-intervention").addClass("d-none");
            this.$smoothScroll({
                scrollTo: document.getElementById("assessment_list"),
            });
        },
        close(){
            this.$store.state.Intervention.edit = 0;
            this.$store.state.Intervention.form = {};
            Object.assign(this.$store.state.Intervention.form);
            this.v$.$reset();
            this.$store.dispatch("getSimpleTableContents", this.dataTypes);
            $("#new-intervention").addClass("d-none");
            this.$smoothScroll({
                scrollTo: document.getElementById("assessment_list"),
            });
        }
    },
    mounted(){
        var d = new Date();
        var month = d.getMonth() + 1;
        var day = d.getDate();

        this.current_date = d.getFullYear() + "-" + (("" + month).length < 2 ? "0" : "") + month + "-" + (("" + day).length < 2 ? "0" : "") + day;

        axios.get("/json_autocomplete/snomed-code-with-data-element", {
                params: {data_element:'medication_intervention_not_done_medical_reason' },
        })
        .then((response) => {
            this.negation = response.data.data;
        });
    }
};
</script>