<template>
  <div class="form-group row">    
      <label
          :class="labelClass"
        >{{ title }}
        <small v-show="isRequired" class="text-danger asterisksign">*</small></label
      >

    <div :class="divClass">
      <div>
        <Multiselect
          class="form-control"
          v-model="values"
          mode="tags"
          valueProp="id"
          :class="{ error: errors && errors.length}"
          :searchable="true"
          label="name"
          trackBy="name"
          :options="optionsvalue"
          :canClear="false"
        />
        <span class="form-text text-muted" v-if="notes != ''">{{notes}}</span>
      </div>

      <div class="error-msg">
        <ul class="mb-0 p-0 fs-14">
          <li class="d-block" v-for="(error, index) of errors" :key="index">
            {{ error.$message }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import submitMixins from "@/scripts/mixins/submitMixins.js";
// https://bestofvue.com/repo/vueform-multiselect-vuejs-form-select
import Multiselect from "@vueform/multiselect";

export default {
  components: { Multiselect },
  mixins: [submitMixins],
  props: {
    errors: {
      Object,
    },
    modelValue: {},
    title: {
      type: String,
    },
    isRequired: {
      type: Boolean,
    },
    labelClass:{
      type: String,
      default: "col-12 col-sm-6 col-md-4 col-lg-3 col-form-label text-sm-end mb-0"
    },
    divClass:{
      type: String,
      default: "col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3"
    },
    notes: {
      type: String,
      default: ""
    },
    optionsvalue:{
      Object,
      default: []
    }
  },
  watch: {
    values: function(newValue) {
      this.$emit("update:modelValue", this.values);
    },
    // server sends comma seprated string for arrays so need to convert it to JS array
    modelValue: function(newValue) {
      if (!Array.isArray(newValue)) {
        this.values = newValue.split(",");
        this.values = this.values.map((item) => {
          return item ; // convert string to number
        });
        this.$emit("update:modelValue", this.values);
      }
    },
  },
  mounted() {},
  methods: {},
  data() {
    return {
      data: [],
      error: false,
      values: [],
    };
  },

  beforeCreate() {},
  async created() {},
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>