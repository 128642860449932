<template>
    <div>
        <ul class="nav nav-tabs mt-0 text-center text-sm-start" role="tablist">
            <li class="nav-item"> <a class="nav-link" :class="isActive('MainBilling')" data-bs-toggle="tab" href="#mainBilling" @click.prevent="changeTab('MainBilling')" role="tab">Billing</a> </li>
            <li class="nav-item"> <a class="nav-link" :class="isActive('BillingContract')" data-bs-toggle="tab" href="#billingContract" @click.prevent="changeTab('BillingContract')" role="tab">Contract</a> </li>
            <li class="nav-item"> <a class="nav-link" :class="isActive('Wallet')" data-bs-toggle="tab" href="#wallet" @click.prevent="changeTab('Wallet')" role="tab">Wallet</a> </li>
            <li class="nav-item"> <a class="nav-link" :class="isActive('CreditCardList')" data-bs-toggle="tab" href="#creditcardlist" @click.prevent="changeTab('CreditCardList')" role="tab">Credit Cards</a> </li>
            <li class="nav-item"> <a class="nav-link" :class="isActive('PackageList')" data-bs-toggle="tab" href="#PackageList" @click.prevent="changeTab('PackageList')" role="tab">Packages</a> </li>
            <li class="m-2"><a href="#" data-bs-toggle="modal" data-bs-target="#billingdocument" title="Reference of Knowledge Base" v-on:click.prevent="reset()"> <i class="fa fa-question-circle fs-20"></i></a></li>
        </ul>

        <!-- 
            <ul class="nav nav-tabs mt-0 text-center text-sm-start" role="tablist">
            <li class="nav-item"> <a class="nav-link" data-bs-toggle="tab" href="#healthCareBilling" @click.prevent="changeTab('MainBilling')" role="tab">Billing</a> </li>
            
            <li class="nav-item"> <a class="nav-link" data-bs-toggle="tab" href="#billingContract" @click.prevent="changeTab('BillingContract')" role="tab">Contract</a> </li>
            <li class="nav-item"> <a class="nav-link active" data-bs-toggle="tab" href="#wallet" @click.prevent="changeTab('Wallet')" role="tab">Wallet</a> </li>
            <li class="nav-item"> <a class="nav-link" data-bs-toggle="tab" href="#creditcardlist" @click.prevent="changeTab('CreditCardList')" role="tab">Credit Cards</a> </li>
         </ul>
         -->
        <!--document model start-->
        <div id="billingdocument" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-md">
            <div class="modal-content">
            <div class="modal-header">
                <h3 class="modal-title fw-900 mt-0">Reference of Knowledge Base</h3>
            </div>
            <div class="modal-body overflow-auto" style="max-height: 350px;">
            <table class="table table-bordered">
                <thead>
                <tr>
                <th scope="col">No.</th>
                <th scope="col">Knowledge Base Articles</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td colspan="2"><b>Billing</b></td>
                </tr>
                <tr>
                    <td>1</td>
                    <td> <a :href="path+'billing-management'" target="_blank">Billing Management</a></td>
                </tr>
                <tr>
                    <td>2</td>
                    <td> <a :href="path+'how-to-view-payment-receipt-for-appointment-billing'" target="_blank">How To View Payment Receipt For Appointment Billing?</a></td>
                </tr>
                <tr>
                    <td>3</td>
                    <td> <a :href="path+'how-to-view-payment-receipt-for-pos-billing'" target="_blank">How To View Payment Receipt For POS Billing?</a></td>
                </tr>
                <tr>
                    <td>4</td>
                    <td> <a :href="path+'how-to-add-appointment-billing'" target="_blank">How To Add Appointment Billing?</a></td>
                </tr>
                <tr>
                    <td>5</td>
                    <td> <a :href="path+'how-to-edit-appointment-billing'" target="_blank">How To Edit Appointment Billing?</a></td>
                </tr>
                <tr>
                    <td>6</td>
                    <td> <a :href="path+'how-to-update-payment-for-appointment-billing'" target="_blank">How To Update Payment For Appointment Billing?</a></td>
                </tr>
                <tr>
                    <td>7</td>
                    <td> <a :href="path+'how-to-refund-payment-for-appointment-billing'" target="_blank">How To Refund Payment For Appointment Billing?</a></td>
                </tr>
                <tr>
                    <td>8</td>
                    <td> <a :href="path+'how-to-add-pos'" target="_blank">How To Add POS?</a></td>
                </tr>
                <tr>
                    <td>9</td>
                    <td> <a :href="path+'how-to-edit-pos'" target="_blank">How To Edit POS?</a></td>
                </tr>
                <tr>
                    <td>10</td>
                    <td> <a :href="path+'how-to-refund-remove-pos-from-billing'" target="_blank">How To Refund & Remove POS From Billing?</a></td>
                </tr>
                <tr>
                    <td>11</td>
                    <td> <a :href="path+'how-to-send-payment-receipt-email-to-patient'" target="_blank">How To Send Payment Receipt Email To Patient?</a></td>
                </tr>
                <tr>
                    <td colspan="2"><b>Contract</b></td>
                </tr>
                <tr>
                    <td>1</td>
                    <td> <a :href="path+'how-to-add-new-contract'" target="_blank">How To Add New Contract?</a></td>
                </tr>
                <tr>
                    <td>2</td>
                    <td> <a :href="path+'how-to-edit-contract'" target="_blank">How To Edit Contract ?</a></td>
                </tr>
                <tr>
                    <td>3</td>
                    <td> <a :href="path+'how-to-preview-contract'" target="_blank">How To Preview Contract?</a></td>
                </tr>
                <tr>
                    <td>4</td>
                    <td> <a :href="path+'how-to-send-contract-to-patient-for-signature'" target="_blank">How To Send Contract To Patient For Signature?</a></td>
                </tr>
                <tr>
                    <td>5</td>
                    <td> <a :href="path+'how-to-send-payment-receipt-for-charged-payment-of-contract'" target="_blank">How To Send Payment Receipt For Charged Payment Of Contract?</a></td>
                </tr>
                <tr>
                    <td>6</td>
                    <td> <a :href="path+'subscription-and-contracts'" target="_blank">Subscription And Contracts</a></td>
                </tr>
                <tr>
                    <td colspan="2"><b>Wallet</b></td>
                </tr>
                <tr>
                    <td>1</td>
                    <td> <a :href="path+'how-to-add-money-in-pureehr-wallet'" target="_blank">How To Add Money In PureEHR Wallet?</a></td>
                </tr>
                <tr>
                    <td>2</td>
                    <td> <a :href="path+'how-to-use-pureehr-wallet-for-payment'" target="_blank">How To Use PureEHR Wallet For Payment?</a></td>
                </tr>
                <tr>
                    <td colspan="2"><b>Credit Cards</b></td>
                </tr>
                <tr>
                    <td>1</td>
                    <td> <a :href="path+'how-to-add-a-credit-card'" target="_blank">How To Add A Credit Card?</a></td>
                </tr>
                <tr>
                    <td>2</td>
                    <td> <a :href="path+'how-to-delete-remove-credit-card'" target="_blank">How To Delete/Remove Credit Card?</a></td>
                </tr>
                <tr>
                    <td>3</td>
                    <td> <a :href="path+'how-to-set-default-credit-card'" target="_blank">How To Set Default Credit Card?</a></td>
                </tr>
                <tr>
                    <td>4</td>
                    <td> <a :href="path+'how-to-check-if-prospect-s-credit-card-is-authorized-in-pureehr'" target="_blank">How To Check If Prospect's Credit Card Is Authorized In PureEHR?</a></td>
                </tr>
                <tr>
                    <td>5</td>
                    <td> <a :href="path+'how-to-check-whether-the-patient-s-credit-card-is-authorized-in-pureehr'" target="_blank">How To Check Whether The Patient's Credit Card Is Authorized In PureEHR?</a></td>
                </tr>
                </tbody>
            </table>
            <Search :key="searchKey"></Search>
            </div>
            <div class="modal-footer">
                <button type="button" @click="" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
            </div>
            </div>
        </div>
        </div>
        <!--document model end-->
    </div>
</template>

<script>
import Search from "@/components/Search.vue";
	export default { 
        props: {
            routefrom: String
        },
        components: { Search },
        data() {
            return {
                active: '',
                path: process.env.VUE_APP_KB_DOCUMENT,
                searchKey:0,
            }
        },
        methods: {
			changeTab (components) {
				this.$store.state.patientDetailsComp = components;
				let props = this.$router.resolve({ 
					name: components,
					params: { id: this.$route.params.id},
				});
				history.pushState(null, '',props.href);
			},
            isActive(menuname) {
                return menuname==this.routefrom ? 'active' : '';
            },
            reset() {
                this.searchKey = this.searchKey + 1;
            },
		},
    }
</script>