<template>
  <div class="loginback">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-12">
          <div class="bg-white rounded10 p-35 pt-40 pb-40 my-20">
            <div class="text-start">
              <img src="/images/pureehr.svg" alt="PureEHR" title="PureEHR" class="authcompany_logo mb-10"/>
              <h2 class="mb-15 fw-900">Consent Form</h2>
              <p>Enter 6 digit verification Code sent to your Email.</p>
              <input type="text" v-model="this.securityCode" id="security_code" class="form-control">
            </div>
     
            <div class="bg-danger-light p-10 py-5 rounded5 fs-16 mb-15" role="alert" v-if="errorMessage">
              {{errorMessage}}
            </div>

            <div class="bg-success-light p-10 py-5 rounded5 fs-16 mb-15" role="alert" v-if="successMessage">
              {{successMessage}}
            </div>
            <button type="button" class="waves-effect waves-light btn btn btn-info me-1 mt-15" @click="viewPDF">Click here to view PDF</button>
          </div>
        </div>
      </div>
      <div class="row align-items-center" v-if="codeVerified==true && patientConsent1!=''">
        <div class="col-12">
          <div class="pdf-box p-35 my-20">
              <div id="PDF1" v-if="patientConsent1" v-html="patientConsent1"></div>
              <div id="PDF2" class="mt-25" v-if="patientConsent2" v-html="patientConsent2"></div>
              <div>
                <signature title="" labelClass="" mainClass="justify-content-center mt-25" divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-5"></signature>
                <button type="button" @click="signature_save" class="waves-effect waves-light btn btn-info" :disabled="((check == true) ? disabled : '')" id="saveData">Save</button>
              </div>
          </div>          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "@/scripts/axios.js";
import { mapGetters, mapActions } from "vuex";
import CheckBox from "@/components/base/formFields/checkBox.vue";
import useVuelidate from "@vuelidate/core";
import Signature from "@/components/base/Signature.vue";
import { required, minLength, helpers } from "@vuelidate/validators";
import $ from "jquery";
export default {
  name: "Login",
  setup() {
    return { v$: useVuelidate() };
  },
  components: {
    CheckBox,
    Signature
  },
  data() {
    return {
      errorMessage: "",
      paymentdone: false,
      patient_name:'',
      is_production_env : process.env.VUE_APP_NODE_ENV == 'production',
      appointment_id : '',
      patient_id : '',
      clinic_id1 : '',
      serverError: '',
      patient: '',
      securityCode: '',
      codeVerified: false,
      patientConsent1: '',
      patientConsent2: '',
      form: {
        signature: '',
        patient_id: '',
        appointment_id: ''
      },
      disabledClass: '',
      checkbox_1: false,
      checkbox_2: false,
      check: false,
      show_slog_loader: ''
    };
  },
  computed: {
    ...mapGetters(["errors"]),
  },
  beforeCreate(){
    $('.tophead').html("");
    this.$store.commit("setErrors", {});
    this.$store.state.headerRequired = false;
    this.$store.state.footerRequired = false;
  },
  async created() {
  },
  mounted() {
    $(document).on("change", "input[type=checkbox]", function(){
       var checkbox_1 = $("#checkbox_1").prop("checked");
       var checkbox_2 = true;
       if($("#checkbox_2").length>0){
          checkbox_2 = $("#checkbox_2").prop("checked");
       }     

       if(checkbox_1==true && checkbox_2==true){
          $("#saveData").removeAttr("disabled");
          this.check = false;
       }else{
          this.check = true;
          $("#saveData").attr("disabled", true);
       }
    });
  },
  methods: {
      signature_save(){
        this.form.signature = this.$store.state.signature;
        var $this = this;
        $this.$store.state.loader = true;
        axios
          .post("/lifestyle-patient-consent-sign-save", { token: this.$route.params.token, 'formData' : this.form })
          .then((response) => {
              this.successMessage = "Your consent has been signed successfully."
              this.patientConsent1 = '';
              $this.$store.state.loader = false;
              this.securityCode = '';
          }).catch((error) => {
              this.errorMessage = error.response.data.message;
          });
      },
      viewPDF() {
        axios
          .post("/token-check-lifestyle-patient-consent", { token: this.$route.params.token, security_code : this.securityCode })
          .then((response) => {
              var pdfData1, pdfData2 = '';

              if(response.data.data.pdf_file){
                this.codeVerified = true;
                $.each(response.data.data.pdf_file, function(index, value) {
                  if(index == 0){                  
                    pdfData1 = '<iframe src="'+value+'" width="80%" height="100%"></iframe><div class="form-group row justify-content-center"><div class="col-12 col-sm-2 col-md-6"><div class="controls text-center mt-25"><span class="check_item">I am giving consent</span><input type="checkbox" name="checkbox_1" class="filled-in chk-col-info" v-model="checkbox_1" id="checkbox_1" value="single"><label for="checkbox_1" class="m-0 text-start"><small class="text-danger" style="display: none;">*</small>  <small class="text-danger" style="display: none;">*</small></label></div></div></div>';
                  }else{                  
                    pdfData2 = '<iframe src="'+value+'" width="80%" height="100%"></iframe><div class="form-group row justify-content-center"><div class="col-12 col-sm-2 col-md-6"><div class="controls text-center mt-25"><span class="check_item">I am giving consent</span><input type="checkbox" name="checkbox_2" class="filled-in chk-col-info" v-model="checkbox_2" id="checkbox_2" value="single"><label for="checkbox_2" class="m-0 text-start"><small class="text-danger" style="display: none;">*</small>  <small class="text-danger" style="display: none;">*</small></label></div></div></div>';
                  }
                });
                this.errorMessage = '';
              }else{
                this.errorMessage = response.data.message;
              }

              this.patientConsent1 = pdfData1;
              this.patientConsent2 = pdfData2;
              this.form.patient_id = response.data.data.patient_id;
              this.form.clinic_id1 = response.data.data.clinic_id;
              this.form.appointment_id = response.data.data.appointment_id;
          }).catch((error) => {
              this.errorMessage = error.response.data.message;
          });        
      }
    }
};
</script>
<style type="text/css" scoped>
  .waves-effect{ display: block; margin-left: auto; }
  .check_item{ margin-right: 6px; }
</style>