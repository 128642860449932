<template>
	<div class="content-wrapper">
		<div class="container-full">
			<div class="content-header">
				<div class="row justify-content-between">
					<div class="col-12 col-sm-9 col-md-10 col-lg-10">
						<h1 class="heading1 mt-0 me-2 d-inline-block">Recover Deleted Appointments <a href="#" data-bs-toggle="modal" data-bs-target="#recoverappdocument" title="Reference of Knowledge Base" v-on:click.prevent="reset()"> <i class="fa fa-question-circle fs-20"></i></a></h1>						
					</div>
					<div class="col-12 col-sm-3 col-md-2 col-lg-2 text-sm-end">
						<a href="javascript:void(0)" class="waves-effect waves-light btn btn-info" @click="recover">Recover</a>
					</div>
				</div>
			</div>
			<section class="content">
				<div class="box">
					<div class="box-body">
						<div class="table-responsive">
							<data-table-server dataType="RecoverAppointments" dataMethod="GET">
								<template v-slot="slotProps">
									<td>{{ $filters.displayDateTimeLocal(slotProps.item.schedule_date,'',false,"ddd, MM-DD-YYYY",true) }} {{ $filters.timeFormat(slotProps.item.schedule_time) }}</td>
									<td>{{ slotProps.item.patinet_name }}</td>
									<td>{{ slotProps.item.username }}</td>
									<td>{{ $filters.displayDateTimeLocal(slotProps.item.deleted_at,'',false,"MM-DD-YYYY hh:mm A") }}</td>
									<td>{{ slotProps.item.reason_for_delete }}</td>
									<td class="text-center" style="padding-right: 30px;">
										<div class="controls">
											<input v-bind:id="'checkbox_id'+slotProps.item.id" type="checkbox" class="filled-in chk-col-info myCheckbox" @click="uncheckall(slotProps.item.id)" :value="slotProps.item.id" name="appointment_id">
											<label v-bind:for="'checkbox_id'+slotProps.item.id" class="m-0 text-start fs-16" style="padding-left: 18px;"></label>
										</div>
									</td>
								</template>
							</data-table-server>
						</div>
					</div>
				</div>
			</section>
		</div>
	</div>
	<!--document model start-->
	<div id="recoverappdocument" class="modal fade" tabindex="-1" role="dialog"
		aria-labelledby="myModalLabel" aria-hidden="true">
		<div class="modal-dialog modal-md">
			<div class="modal-content">
				<div class="modal-header">
					<h3 class="modal-title fw-900 mt-0">Reference of Knowledge Base</h3>
				</div>
				<div class="modal-body overflow-auto" style="max-height: 350px;">
					<table class="table table-bordered">
						<thead>
							<tr>
								<th scope="col">No.</th>
								<th scope="col">Knowledge Base Articles</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>1</td>
								<td> <a :href="path+'how-to-view-recover-deleted-appointments'" target="_blank">How To View & Recover Deleted Appointments?</a></td>
							</tr>
						</tbody>
					</table>
					<Search :key="searchKey"></Search>
				</div>
				<div class="modal-footer">
					<button type="button" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
				</div>
			</div>
		</div>
	</div>
	<!--document model end-->
	<!--model start-->
	<div id="recoverAppointment" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="recoverAppointment" aria-hidden="true">
		<div class="modal-dialog modal-xl">
			<div class="modal-content">
				<div class="modal-header">
					<h3 class="modal-title fw-900 mt-0">Below appointments can't be recovered because their slots are already booked.</h3>
				</div>
				<div class="modal-body">
					<div class="table-responsive">
						<table class="table table-hover">
							<thead>
								<tr class="text-center">
									<th>Appointment Date</th>
									<th>Patient Name</th>
									<th>Deleted By</th>
									<th>Deleted On</th>
									<th>Reason of Deletion</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for ="(item,index) in list" :key="index">
									<td>{{ $filters.displayDateTimeLocal(item.schedule_date,'',false,"ddd, MM-DD-YYYY",true) }} {{ $filters.timeFormat(item.schedule_time) }}</td>
									<td>{{ item.patinet_name }}</td>
									<td>{{ item.username }}</td>
									<td>{{ $filters.displayDateTimeLocal(item.deleted_at,'',false,"ddd, MM-DD-YYYY",true) }} {{ $filters.timeFormat(item.deleted_at,'MM-DD-YYYY') }}</td>
									<td>{{ item.reason_for_delete }}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="modal-footer">
					<button type="button" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger" @click="close">Ok</button>
				</div>
			</div>
		</div>
	</div>
	<!--model end-->
</template>

<script>
	import DataTableServer from "@/components/base/DataTableServer.vue";
	import axios from "@/scripts/axios.js";
	import submitMixins from "@/scripts/mixins/submitMixins.js";
	import Popper from "vue3-popper";
	import Search from "@/components/Search.vue";
	import moment from 'moment-timezone';
	export default {
		mixins: [submitMixins],
		components: { DataTableServer, Popper, Search },
		data() {
			return {
				table_load_count: 0,
				tablekey: 0,
				list:[],
				path: process.env.VUE_APP_KB_DOCUMENT,
				searchKey:0,
			};
		},
		methods:{
			reset() {
				this.searchKey = this.searchKey + 1;
			},
			close(){
				location.reload();
			},
			recover(){
				var appointment_id_array = Array();
				$("input:checkbox[name=appointment_id]:checked").each(function() {
					appointment_id_array.push($(this).val());
				});
				this.appointment_id = appointment_id_array;
				
				if(this.appointment_id != ''){
					axios.post("/appointment/recover",{appointment_id:appointment_id_array})
					.then((response) => {
						if(response.data.data == null || response.data.data == ""){
							this.moshaToast("Record updated successfully.", "success");
							setTimeout(function(){location.reload()}, 3000);
							
						}
						else{
							axios.get("/appointment/archived")
							.then((response) => {
								this.list =response.data.data; 
							});
							$("#recoverAppointment").modal('show');
						}
					});
				}
				else{
					this.moshaToast("Please select any one appointment.", "info");
				}
			},
			checkall(){
				if(this.$store.state.RecoverAppointments.checkall == 1){
					var checked = $('#checkAll').is(':checked');
					if(checked == true){
						$('.myCheckbox').prop('checked', true);
					}
					else{
						$('.myCheckbox').prop('checked', false); 
					}
				}
				this.$store.state.RecoverAppointments.checkall = 0;
			},
			uncheckall(id){
				var checked = $('#checkbox_id'+id).is(':checked');
				if(checked == false){
					$('#checkAll').prop('checked', false); 
				}
			},
		},
		watch: {
			"$store.state.RecoverAppointments.clickevent": function() {
				this.recover();
			},
			"$store.state.RecoverAppointments.checkall": function() {
				this.checkall();
			},

		}
	};
</script>
