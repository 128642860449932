<template>
   <div class="box">
      <div class="box-body">
         <div class="mb-5">
            <a href="#" data-bs-toggle="modal" data-bs-target="#clinicldashboarddocument" title="Reference of Knowledge Base" v-on:click.prevent="reset()" class="waves-effect waves-light btn btn-outline btn-info-light mb-5"> Help <i class="fa fa-question-circle"></i></a>
         </div>
         <div class="tab-pane animation-fade active" id="category-1" role="tabpanel">
   <div class="panel-group panel-group-simple panel-group-continuous mb-0" id="accordion2" aria-multiselectable="true" role="tablist">
       
      <!--Summary of Care Provided start-->
      <div class="panel pagecollap">
         <div class="panel-heading" id="summarycareprovided" role="tab">
            <a class="panel-title" aria-controls="answer-1" aria-expanded="true" data-bs-toggle="collapse" href="#answer-1" data-parent="#accordion2">Summary of Care Provided <!-- <small class="badge badge-danger-light px-2 py-1 ms-5 fs-14">Pending</small> --></a>
         </div>
         <div class="panel-collapse collapse show" id="answer-1" aria-labelledby="summarycareprovided" role="tabpanel" data-bs-parent="#category-1">
            <div class="panel-body">
               <div class="table-responsive">
                  <table id="example1" class="table table-hover">
                     <thead>
                        <tr>
                           <th>Appointment</th>
                           <th>Summary of Care</th>
                           <th>Summary of Care Requested and not available</th>
                           <th>Type</th>
                        </tr>
                     </thead>
                     <tbody>
                        <tr class="hover-primary">
                           <td>COVID IE</td>
                           <td>
                              <select class="form-control">
                                 <option>1</option>
                                 <option>2</option>
                                 <option>3</option>
                                 <option>4</option>
                                 <option>5</option>
                                 <option>6</option>
                              </select>
                           </td>
                           <td>
                              <div class="form-check">
                                 <input type="checkbox" id="md_checkbox_1" class="filled-in chk-col-info" />
                                 <label for="md_checkbox_1" class="text-start"></label>
                              </div>
                           </td>
                           <td>Transition of Care</td>
                        </tr>
                     </tbody>
                  </table>
               </div>
            </div>
         </div>
      </div>
      <!--Summary of Care Provided End-->
      <!--document model start-->
      <div id="clinicldashboarddocument" class="modal fade" tabindex="-1" role="dialog"
         aria-labelledby="myModalLabel" aria-hidden="true">
         <div class="modal-dialog modal-md">
               <div class="modal-content">
                  <div class="modal-header">
                     <h3 class="modal-title fw-900 mt-0">Reference of Knowledge Base</h3>
                  </div>
                  <div class="modal-body overflow-auto" style="max-height: 350px;">
                     <table class="table table-bordered">
                           <thead>
                              <tr>
                                 <th scope="col">No.</th>
                                 <th scope="col">Knowledge Base Articles</th>
                              </tr>
                           </thead>
                           <tbody>
                              <tr>
                                 <td>1</td>
                                 <td> <a :href="path+'what-is-clinical-dashboard-in-pureehr'" target="_blank">What Is Clinical Dashboard In PureEHR?</a></td>
                              </tr>
                              <tr>
                                 <td>2</td>
                                 <td> <a :href="path+'how-to-view-active-problems-from-the-clinical-dashboard'" target="_blank">How To View Active Problems From The Clinical Dashboard?</a></td>
                              </tr>
                              <tr>
                                 <td>3</td>
                                 <td> <a :href="path+'how-to-view-lab-results-from-clinical-dashboard'" target="_blank">How To View Lab Results From Clinical Dashboard?</a></td>
                              </tr>
                              <tr>
                                 <td>4</td>
                                 <td> <a :href="path+'how-to-download-ccda-from-clinical-dashboard'" target="_blank">How To Download CCDA From Clinical Dashboard?</a></td>
                              </tr>
                              <tr>
                                 <td>5</td>
                                 <td> <a :href="path+'how-to-send-ccda-to-patient-portal-via-clinical-dashboard'" target="_blank">How To Send CCDA To Patient Portal Via Clinical Dashboard?</a></td>
                              </tr>
                              <tr>
                                 <td>6</td>
                                 <td> <a :href="path+'how-to-add-a-new-care-plan'" target="_blank">How To Add A New Care Plan?</a></td>
                              </tr>
                              <tr>
                                 <td>7</td>
                                 <td> <a :href="path+'how-to-edit-a-care-plan'" target="_blank">How To Edit A Care Plan?</a></td>
                              </tr>
                              <tr>
                                 <td>8</td>
                                 <td> <a :href="path+'how-to-delete-a-care-plan'" target="_blank">How To Delete A Care Plan?</a></td>
                              </tr>
                              <tr>
                                 <td>9</td>
                                 <td> <a :href="path+'how-to-view-care-plan'" target="_blank">How To  View Care Plan?</a></td>
                              </tr>
                              <tr>
                                 <td>10</td>
                                 <td> <a :href="path+'how-to-add-care-team-members'" target="_blank">How To Add Care Team Members?</a></td>
                              </tr>
                              <tr>
                                 <td>11</td>
                                 <td> <a :href="path+'how-to-view-care-team-members'" target="_blank">How To View Care Team Members?</a></td>
                              </tr>
                              <tr>
                                 <td>12</td>
                                 <td> <a :href="path+'how-to-delete-care-team-members'" target="_blank">How To Delete Care Team Members?</a></td>
                              </tr>
                              <tr>
                                 <td>13</td>
                                 <td> <a :href="path+'how-to-add-functional-status'"  target="_blank">How To Add Functional Status?</a></td>
                              </tr>
                              <tr>
                                 <td>14</td>
                                 <td> <a :href="path+'how-to-view-functional-status'" target="_blank">How To View Functional Status?</a></td>
                              </tr>
                              <tr>
                                 <td>15</td>
                                 <td> <a :href="path+'how-to-edit-functional-status'" target="_blank">How To Edit Functional Status?</a></td>
                              </tr>
                              <tr>
                                 <td>16</td>
                                 <td> <a :href="path+'how-to-add-cognitive-status'" target="_blank">How To Add Cognitive Status?</a></td>
                              </tr>
                              <tr>
                                 <td>17</td>
                                 <td> <a :href="path+'how-to-edit-cognitive-status'" target="_blank">How To Edit Cognitive Status?</a></td>
                              </tr>
                              <tr>
                                 <td>18</td>
                                 <td> <a :href="path+'how-to-view-list-of-cognitive-status'" target="_blank">How To View List Of Cognitive Status?</a></td>
                              </tr>
                           </tbody>
                     </table>
                     <Search :key="searchKey"></Search>
                  </div>
                  <div class="modal-footer">
                     <button type="button" @click="" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
                  </div>
               </div>
         </div>
      </div>
      <!--document model end-->
      <!--Ongoing Problems Start-->
      <ongoing-problems></ongoing-problems>
      <!--Ongoing Problems End-->

      <!--Lab Result Start-->
      <lab-result></lab-result>
      <!--Lab Result End-->

      <!--Import Structured Clinical Record Start-->
      <clinical-data-management></clinical-data-management>
      <!--Import Structured Clinical Record End-->

      <!--Care Plan Start-->
      <care-plan></care-plan>
      <!--Care Plan End-->

      <!--Care Team Members start-->
      <care-team-members></care-team-members>
      <!--Care Team Members End-->

      <!--FunctionalStatus Start-->
      <functional-status></functional-status>
      <!--FunctionalStatus End-->

      <!--Mental Status start-->
      <cognitive-status></cognitive-status>
      <!--Mental Status end-->

   </div>
</div>
      </div>
   </div>
</template>
<script>
import OngoingProblems from "@/components/patients/details/ClinicalDashboard/OngoingProblems.vue";
import LabResult from "@/components/patients/details/ClinicalDashboard/LabResult.vue";
import CarePlan from "@/components/patients/details/ClinicalDashboard/CarePlan.vue";
import CareTeamMembers from "@/components/patients/details/ClinicalDashboard/CareTeamMembers.vue";
import CognitiveStatus from "@/components/patients/details/ClinicalDashboard/CognitiveStatus.vue";
import FunctionalStatus from "@/components/patients/details/ClinicalDashboard/FunctionalStatus.vue";
import ClinicalDataManagement from "@/components/patients/details/ClinicalDashboard/ClinicalDataManagement.vue";
import TableBase from "@/components/base/Table.vue";
import Search from "@/components/Search.vue";


export default {
   components: { 
      TableBase,
      OngoingProblems,
      LabResult,
      CarePlan,
      CareTeamMembers,
      CognitiveStatus,
      FunctionalStatus,
      ClinicalDataManagement,
      Search
   },
   data() 
   {
      return {
         patient_id : this.$store.state.patientDetail.patient_id,
         patient_unique_id:this.$store.state.patientDetail.patient_unique_id,
         path: process.env.VUE_APP_KB_DOCUMENT,
         searchKey:0,
      };
   },
   methods: {
      reset() {
         this.searchKey = this.searchKey + 1;
      }
      
   }
};
</script>