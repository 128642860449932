<template>       
		<div class="box p-15">
		<div class="mdc-tab">
			<ul class="nav nav-tabs mt-0 mb-20 text-center text-sm-start" role="tablist">
				<li class="nav-item"> <a  class="nav-link" href="#all" title="All" @click.prevent="changeTab('AllNotes','All')" data-bs-toggle="tab" role="tab" >All</a> </li>
				<li class="nav-item"> <a class="nav-link" href="#refill" title="Refill" data-bs-toggle="tab" @click.prevent="changeTab('RefillNotes','Refill')" role="tab">Refills</a> </li>
				<li class="nav-item"> <a class="nav-link" href="#WC-Check" title="WC Check"  data-bs-toggle="tab" @click.prevent="changeTab('WcNotes','WcCheck')" role="tab">Wc Check</a> </li>
                <li class="nav-item"> <a class="nav-link active" href="#General-Notes" title="Admin Notes" @click.prevent="changeTab('GeneralNotes','Admin Note')" data-bs-toggle="tab" role="tab">Admin Notes</a> </li>
                <li class="nav-item"> <a class="nav-link" href="#med-list" title="Med List" @click.prevent="changeTab('MedNotes','Med List')" data-bs-toggle="tab" role="tab">Med List</a> </li>
				<!-- <li class="nav-item"> <a class="m-5" href="#" data-bs-toggle="modal" data-bs-target="#identification" title="Reference of Knowledge Base" v-on:click.prevent="reset()"><i class="fa fa-question-circle fa-lg pt-2"></i></a> </li> -->
			</ul>
			<div class="">
                <div class="" id="General-Notes">
                    <div class="row align-items-center justify-content-between">
                        <div class="col-12 col-sm-6 col-md-6">
                            <h3 class="fw-500">Admin Notes </h3>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 text-sm-end">
                            <!-- <a v-if = "adminNoteListFiltered.length>0 && !show_admin_note_form" href="javascript:void(0)" @click.prevent="printSection('printGeneral')" class="waves-effect waves-light btn btn-info-light me-1 btn-sm" title="Download"><i class="fa fa-download"></i></a> -->
                            <a href="javascript:void(0)" @click.prevent="show_admin_note_form=!show_admin_note_form;this.admin_wc_check_note = this.admin_wc_check_note_default;this.admin_note_input.admin_note_id=''" class="waves-effect waves-light btn btn-info btn-sm"><i class="fa fa-plus "></i> New</a>
                        </div>
                    </div>
                    <template v-if="show_admin_note_form">
                        <div class="form-group mb-10">
                            <label class="form-label">Note</label>
                            <textarea type="text" v-model="admin_note_input.note" class="form-control" rows="7"></textarea>
                            <div
                            class="invalid-feedback"
                            style="display:block;"
                            v-if="admin_note_note_error"
                            >
                            {{ admin_note_note_error }}
                            </div>
                        </div>
                        <div class="text-end">
                            <a href="javascript:void(0)"  class="waves-effect waves-light btn btn-info me-1" @click="handleAdminNoteSubmit">Submit</a>
                            <a href="javascript:void(0)" @click.prevent="show_admin_note_form=!show_admin_note_form" class="waves-effect waves-light btn btn-danger">Cancel</a>
                        </div>
                    </template>
                    <template v-if="!show_admin_note_form">
                        <div id="printGeneral">
                            <template v-if="adminNoteListFiltered.length > 0">
                            <div class="notesList bt-1 py-10" v-for="item in adminNoteListFiltered" >
                                <template v-if="item.category == 'WcCheck'">
                                    <p class="mb-1">WC Check note</p>
                                </template>
                                <template v-else>
                                    <p class="mb-1" v-if="item.note.length<50">{{ item.note }}</p>
                                    <p class="mb-1" v-else>{{ item.note.substring(0,50)+".." }}</p>
                                </template>
                                <div class="row">
                                    <div class="col-5 col-sm-5 col-md-5">
                                        <p class="mb-1">Date: <span class="fw-500">{{ $filters.displayDateTimeLocal(item.created_at) }}</span></p>
                                    </div>
                                    <div class="col-5 col-sm-5 col-md-5">
                                        <p class="mb-1">By: <span class="fw-500">{{ item.username }}</span></p>
                                    </div>
                                    <div class="col-2 col-sm-2 col-md-2 text-center">
                                        <Popper  arrow content="View" :hover="true" class="helptips cursor-pointer ms-2">
                                            <a href="javascript:void(0)" class="waves-effect waves-light btn btn-info btn-xs" @click ="printReceipt(item)">
                                                    <i class="fa fa-eye"></i>
                                            </a>
                                        </Popper>
                                        <Popper  arrow content="Edit" :hover="true" class="helptips cursor-pointer ms-2">
                                            <a href="javascript:void(0)" @click="editAdminNote(item)" class="waves-effect waves-light btn btn-info-light btn-xs mx-1"><i class="fa fa-pencil"></i></a>
                                        </Popper>
                                        <Popper  arrow content="Delete" :hover="true" class="helptips cursor-pointer ms-2">
                                            <a href="javascript:void(0)"  @click="deleteAdminNote(item)" class="waves-effect waves-light btn btn-danger-light btn-xs"><i class="fa fa-trash"></i></a>
                                        </Popper>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <div v-if="demo_admin_note['notes'] != null">
                            <div class="row">
                                <div class="col-5 col-sm-5 col-md-5">
                                    <p class="mb-1" v-if="demo_admin_note['notes'].length<50">{{ demo_admin_note['notes']}}</p>
                                    <p class="mb-1" v-else>{{ demo_admin_note['notes'].substring(0,50)+".." }}</p>
                                    <p>Date: <span class="fw-500">{{ $filters.displayDateTimeLocal(demo_admin_note['created_at'])}}</span></p>
                                </div>
                                <div class="col-5 col-sm-5 col-md-5"></div>
                                <div class="col-2 col-sm-2 col-md-2 text-center">
                                    <Popper  arrow content="View" :hover="true" class="helptips cursor-pointer ms-2">
                                        <a href="javascript:void(0)" class="waves-effect waves-light btn btn-info btn-xs" @click ="printReceipt(demo_admin_note['notes'])">
                                            <i class="fa fa-eye"></i>
                                        </a>
                                    </Popper>
                                    <Popper  arrow content="The note you are looking into for editing is shifted from the Demographics section of primary information of the Patient to here." :hover="true" class="helptips cursor-pointer ms-2 mt-1 ">
                                        <a href="javascript:void(0)" class="waves-effect waves-light btn btn-info-light btn-xs">
                                            <i class="fa fa-info"></i>
                                        </a>
                                    </Popper>
                                </div>
                            </div>
                        </div>
                        </div>
                        
                        <template v-if="adminNoteListFiltered.length == 0 && demo_admin_note['notes'] == null">
                            <div class="notesList bt-1 py-10" >
                                <p class="mb-1">No record found.</p>
                            </div>
                        </template>
                    </template>
                </div>
        </div>
		</div>
	</div>
<!--viewNote Modal Start-->
<div class="modal" id="viewNote" tabindex="-1">
	<div class="modal-dialog modal-md" style="max-width:75%">
		<div class="modal-content">
			<div class="modal-header">
				<button type="button" class="btn-close btn-xs" data-bs-dismiss="modal" aria-label="Close"></button>
			</div>
			<div class="modal-body">
				<vue-pdf-viewer height="1050px" :url="receipt_url" v-if="embed=0">
				</vue-pdf-viewer>
				<iframe :src="receipt_url" width="100%" height="500px" v-if="embed=1" />
				<img srd="images/1.gif" v-else> 
			</div>
		</div>
	</div>
</div>
<!--viewNote Modal end-->
</template>
<script>
    import permissionMixins from "@/scripts/mixins/permission.js";
    import axios from "@/scripts/axios.js";
    import FormBtn from "@/components/base/formFields/formBtn.vue";
    import submitMixins from "@/scripts/mixins/submitMixins.js";
    import useVuelidate from "@vuelidate/core";
    import Popper from "vue3-popper";
    import {
    	required,
    	helpers,
    	minLength,
    	maxLength,
    	numeric,
    	requiredIf
    } from "@vuelidate/validators";
    export default {
        name: "GeneralNotes",
    	props: {
            patientId:String,
        },
        components: {
            FormBtn,Popper
        },
    	setup() {
    		return { v$: useVuelidate() };
    	},
    	mixins: [submitMixins,permissionMixins],
        computed: {
            adminNoteListFiltered: function() {
                let $this = this;
                return this.adminNoteList.filter(function(el) {
                    return ($this.current_category == 'All' ? true : el.category == $this.current_category);
                });
            }
        },
    	data() {
    		return {
                refill_status_id: '',
                refill_status_name: '',
                refill_pharmacy_id: '',
                refill_pharmacy_name: '',
                is_new_refill_status: false,
                is_new_refill_pharmacy: false,
                admin_note_input : {
                    note : '',
                    category : 'AdminNote',
                    confirmation_received : 'Yes',
                },
                admin_wc_check_note :[],
                admin_wc_check_note_default : [
                    {
                        'question' : 'Do you have any immediate concerns at this time that I can help you with?',
                        'answer' : '',
                    },
                    {
                        'question' : 'How are you feeling?',
                        'answer' : '',
                    },
                    {
                        'question' : 'Are you exercising more?',
                        'answer' : '',
                    },
                    {
                        'question' : 'Do you have any questions or concerns with nutrition or diet?',
                        'answer' : '',
                    },
                    {
                        'question' : 'Are you following your protocol closely?',
                        'answer' : '',
                    },
                    {
                        'question' : 'Has your quality of life improved?',
                        'answer' : '',
                    },
                    {
                        'question' : 'What positive changes have you noticed since our last session?',
                        'answer' : '',
                    },
                    {
                        'question' : 'Do you need any refills?',
                        'answer' : '',
                    },
                    {
                        'question' : 'Do you have any other questions?',
                        'answer' : '',
                    },
                    {
                        'question' : 'How long has it been Going On?',
                        'answer' : '',
                    },
                    {
                        'question' : 'How Severe is It?',
                        'answer' : '',
                    },
                    {
                        'question' : 'How Often does it occur?',
                        'answer' : '',
                    },
                    {
                        'question' : 'Does anything make it Better?',
                        'answer' : '',
                    },
                    {
                        'question' : 'Does anything make it Worse?',
                        'answer' : '',
                    },
                    {
                        'question' : 'Anything else you would want to tell me?',
                        'answer' : '',
                    }
                ],
                admin_note_note_error: '',
                admin_note_category_error: '',
                show_admin_note_form: false,
                admin_cat_disabled: false,
                adminNoteList: [],
                current_category: 'AdminNote',
                admin_note_refill_status: [],
                admin_note_refill_pharmacy: [],
                demo_admin_note: '',
                receipt_url: '',
    		};
    	},
    	async created() {
            this.getPatientAdminNote();
    	},
    	mounted() {
            this.admin_wc_check_note = this.admin_wc_check_note_default;
            this.getAdminNoteRefillStatusAndPharmacy();
    	},
    	methods: {
            printReceipt (item) {
				this.getSrc(item.admin_note_id);
				$("#viewNote").modal("show");
			},
            async getSrc(id) {
                console.log("id : "+id);
				var masterId = id;
					const res = await fetch(process.env.VUE_APP_API_BASE_URL+'/patient/printAdminNote', {
						method: 'POST',
						headers: {
							'Authorization' : 'Bearer '+localStorage.getItem('authToken'),
							'Content-Type' : 'application/json'
						},
						body: JSON.stringify({ "admin_note_id": masterId, "patient_id": this.$store.state.patientDetail.patient_id}),
					});
                    console.log(res);
					const blob = await res.blob();
					const urlObject = URL.createObjectURL(blob);
					if (/Android|webOS|Opera Mini/i.test(navigator.userAgent)) {
						$("#viewNote").modal("hide");
						window.open(urlObject, '_blank', 'height=600,width=1200');
					} else {
						this.receipt_url = urlObject;
						this.browserCheck();
					}
				 
			},
			browserCheck(){
				// Opera 8.0+
				var isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

				// Firefox 1.0+
				var isFirefox = typeof InstallTrigger !== 'undefined';

				// Safari 3.0+ "[object HTMLElementConstructor]" 
				var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));

				// Internet Explorer 6-11
				var isIE = /*@cc_on!@*/false || !!document.documentMode;

				// Edge 20+
				var isEdge = !isIE && !!window.StyleMedia;

				// Chrome 1 - 79
				var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

				// Edge (based on chromium) detection
				var isEdgeChromium = isChrome && (navigator.userAgent.indexOf("Edg") != -1);

				// Blink engine detection
				var isBlink = (isChrome || isOpera) && !!window.CSS;

				if (isChrome==true) {
					this.embed = 0;
				} else {
					this.embed = 1;
				}
			},
             async printSection($div) {
                await this.$htmlToPaper($div);
            },
            changeTab (components) {
				this.$store.state.patientDetailsComp = components;
				let props = this.$router.resolve({ 
					name: components,
					params: { id: this.$route.params.id},
				});
				history.pushState(null, '',props.href);
			},
            getAdminNoteRefillStatusAndPharmacy() {
                axios.post("/json_dropdown/getAdminNoteRefillStatus").then((response) => {
                    this.admin_note_refill_status = response.data.data;
                });
                axios.post("/json_dropdown/getAdminNoteRefillPharmacy").then((response) => {
                    this.admin_note_refill_pharmacy = response.data.data;
                });
            },
            getPatientAdminNote() {
                axios.post("/patient/getAdminNote", { patient_id: this.$store.state.patientDetail.patient_id }).then((response) => {
                    //this.adminNoteList = response.data.data;
                    this.adminNoteList = response.data.data['notes'];
                    this.demo_admin_note = response.data.data['admin_note'];
                });
            },
            getAdminNoteByTab(category,id) {
                $(".tab-content .tab-pane").removeClass("active");
                $("#"+id).addClass("active");
                if (category == 'WcCheck') {
                    this.admin_wc_check_note = this.admin_wc_check_note_default;
                }
                this.admin_note_input.note = '';
                this.admin_note_input.category = category;
                this.current_category = category;
                this.show_admin_note_form = false;
            },
            deleteAdminNote(item) {
                this.$swal
                    .fire({
                        title: '<h2 class="mt-0">Are you sure?</h2>',
                        text:
                            "You want to delete these records? This process cannot be undone.",
                        icon: "warning",
                        dangerMode: true,
                        showCancelButton: true,
                        confirmButtonColor: "#ee3158",
                        cancelButtonColor: "#bdbdbd",
                        confirmButtonText: "Yes, delete it!",
                    })
                    .then((result) => {
                        if (result.isConfirmed == true) {
                            axios.post("/patient/deleteAdminNote", item).
                            then((response) => {
                                this.moshaToast(response.data.message, "success");
                                this.getPatientAdminNote(item.patient_id);
                            });
                        }
                    });
            },
            editAdminNote(item) {
                if (item.category == 'WcCheck') {
                     this.admin_wc_check_note = JSON.parse(item.note);
                }
                this.refill_status_id = item.refill_status_id;
                this.refill_pharmacy_id = item.refill_pharmacy_id;
                this.admin_note_input.confirmation_received = item.confirmation_received;
                this.admin_note_input.note =  item.note;
                this.admin_note_input.category =  item.category;
                this.admin_note_input.admin_note_id =  item.admin_note_id;
                this.show_admin_note_form = true;
                this.admin_cat_disabled = true;
            },
            handleAdminNoteSubmit() {
                if (this.admin_note_input.category == 'Refill') {
                    this.admin_note_input.refill_status_id = this.refill_status_id;
                    this.admin_note_input.refill_status_name = this.refill_status_name;
                    this.admin_note_input.refill_pharmacy_id = this.refill_pharmacy_id;
                    this.admin_note_input.refill_pharmacy_name = this.refill_pharmacy_name;
                } else {
                    this.admin_note_input.refill_status_id = '';
                    this.admin_note_input.refill_status_name = '';
                    this.admin_note_input.refill_pharmacy_id = '';
                    this.admin_note_input.refill_pharmacy_name = '';
                }
                if (this.admin_note_input.category == 'WcCheck') {
                    this.admin_note_input.note = JSON.stringify(this.admin_wc_check_note);
                } else {
                    if (!this.admin_note_input.category || this.admin_note_input.category == 'All') {
                        this.admin_note_category_error = 'Category is required.';
                        return true;
                    }
                    if (!this.admin_note_input.note) {
                        this.admin_note_note_error = 'Note is required.';
                        return true;
                    }
                }
                this.admin_note_input.patient_id = this.$store.state.patientDetail.patient_id;
                axios.post("/patient/saveAdminNote", this.admin_note_input).
                then((response) => {
                    this.moshaToast(response.data.message, "success");
                    this.show_admin_note_form = false;
                    this.getPatientAdminNote(this.admin_note_input.patient_id);
                    this.getAdminNoteRefillStatusAndPharmacy();
                    this.is_new_refill_status= false;
                    this.is_new_refill_pharmacy= false;
                    this.refill_status_id = '';
                    this.refill_pharmacy_id = '';
                    this.refill_pharmacy_name = '';
                    this.refill_status_name = '';
                    this.admin_note_input.note = '';
                    this.admin_note_input.category = 'All';
                    this.admin_note_input.confirmation_received = 'Yes';
                });
            }
    	}
    }
</script>

<style src="@vueform/toggle/themes/default.css"></style>
<style>
/*.formio-component-columns{
	padding:10px 5px;
}*/
.card-body{
	padding:20px;
}
.tblClass .col{
	border: 1px solid #d1d3e0;
}
td div.formio-component p {text-align:center}
</style>