import {
  helpers,
  required,
  email,
  minLength,
  maxLength,
  sameAs,
  numeric,
} from "@vuelidate/validators";

const Intervention = {
  namespaced: true,

  state: {
    tableData: {
      header: [
        "Intervention Code",
        "Intervention Description",
        "Intervention Reason Code",
        "Intervention Reason Description",
        "Negation",
        "Is Order",
        "Date",
        "Actions",
      ],
    },
    form: {},
    vuelidateExternalResults: {
      form: {
        reason_code:[],
        reason_description:[],
        observation_date:[],
        intervention_code:[],
        intervention_description:[],
        is_negation:[],
      },
    },
    validationRules: {
      form: {
        observation_date:{
          required: helpers.withMessage("Observation Date is required", required), 
        },
        intervention_code:{
          required: helpers.withMessage("intervention code is required", required), 
        },
        intervention_description:{
          required: helpers.withMessage("intervention Description is required", required), 
        }
      },
    },
    listUrl: "/patient/:patient_unique_id/cqm-intervention/list",
    list: [],
    edit: 0,
  },
  getters: {
    getTableHeaders: (state) => state.header,
    getTableContents: (state) => state.list,
    getListURL: (state) => state.listUrl,
  },
  mutations: {},
  actions: {},
}

export default Intervention;
