<template>
	<div class="loginback">
		<div class="container-fluid">
			<div class="row align-items-center">
				<div class="col-12 col-sm-9 col-md-8 col-lg-6 col-xl-5 col-xxl-4 offset-sm-1">
					<div class="bg-white rounded10 p-35 pt-40 pb-40 my-20">
						<div class="text-start">
							<img src="/images/pureehr.svg" alt="PureEHR" title="PureEHR" class="authcompany_logo mb-10" />
							<h2 class="mb-15 fw-900" v-if="this.id == 0">Create your patient portal password.</h2>
							<h2 class="mb-15 fw-900" v-else>Create patient portal Representative password.</h2>
							<small v-if="isDisabled == false">The password must be at least 8 characters long, including one
								upper/lower case, at least one number, a special character, and no space.</small>
						</div>

						<div class="bg-danger-light p-10 py-5 rounded5 fs-16 mb-15" role="alert" v-if="errorMessage">
							{{ errorMessage }}
						</div>

						<div v-if="errorMessage != 'Provider Does Not Exist in Patient Portal'">
							<p v-if="errorMessage && isresendBtn == true">Your activation link is expired.Click on the
								following button to receive the new activation link.</p>
							<p v-if="errorMessage && isresendBtn == false">Please ask your provider to send you activation
								link again.</p>
							<button v-if="errorMessage && isresendBtn"
								class="waves-effect waves-light btn btn-info d-block fs-22 w-p100 started_btn"
								@click="resend()">Re-send link</button>
						</div>

						<div class="alert-success rounded5 py-5 px-10" role="alert" v-if="successMessage">
							{{ successMessage }}
						</div>
						<form @submit.prevent="handleReset" class="loginform mt-20" v-if="isDisabled == false">


							<password id="password" title="Password" v-model="v$.form.password.$model"
								:errors="v$.form.password.$errors" :isRequired="true" :showPassword="true"
								:suggestion="false" v-on:keyup="passPatternChk"
								labelClass="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-form-label"
								divClass="form-group mb-20">
							</password>

							<password id="password_confirmation" title="Confirm Password"
								v-model="v$.form.password_confirmation.$model"
								:errors="v$.form.password_confirmation.$errors" :isRequired="true" v-on:keyup="passcheck"
								labelClass="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-form-label"
								divClass="form-group mb-20">
							</password>
							<div class="text-center">
								<button :disabled="isDisabledBtn"
									class="waves-effect waves-light btn btn-info d-block fs-22 w-p100 started_btn">
									SUBMIT
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
// @ is an alias to /src
import Password from "@/components/base/formFields/password.vue";
import axios from "@/scripts/axios.js";
import { mapGetters, mapActions } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required, minLength, helpers } from "@vuelidate/validators";
import $ from "jquery";
export default {
	name: "Login",
	setup() {
		return { v$: useVuelidate() };
	},
	components: { Password },
	data() {
		return {
			form: {
				password: "",
				password_confirmation: "",
			},
			errorMessage: "",
			successMessage: "",
			is_loading: true,
			isDisabled: false,
			isDisabledBtn: false,
			isresendBtn: true,
			id: 0,
		};
	},
	validations() {
		return {
			form: {
				password: {
					required: helpers.withMessage("Password is required.", required),
					min: minLength(8),
				},
				password_confirmation: {
					required: helpers.withMessage(
						"Confirm password is required.",
						required
					),
					min: minLength(8),
				},
			},
		};
	},
	computed: {
		...mapGetters(["errors"]),
	},
	beforeCreate() {
		$('.tophead').html("");
		this.$store.commit("setErrors", {});
		this.$store.state.headerRequired = false;
		this.$store.state.footerRequired = false;
		//this.$store.dispatch("auth/sendLogoutRequest");
	},
	created() {
		axios.post("/patient/token-timeout-check", { token: this.$route.params.token, id: this.$route.params.id })
			.then((response) => {
				if (response.data.data.success != 1) {
					this.errorMessage = response.data.message;
					this.isDisabled = true;
					if (response.data.message == 'Token is not valid.') {
						this.isresendBtn = false;
					}
				}
			})
			.catch((error) => {
				this.errorMessage = error.response.data.message;
			});
		if (this.$route.params.id == undefined) {
			this.id = 0;
		} else {
			this.id = this.$route.params.id;
		}

	},
	mounted() {
		this.$store.commit("setErrors", {});
		this.$store.state.headerRequired = false;
		this.$store.state.footerRequired = false;
	},
	methods: {
		resend() {
			if (this.$route.params.id == undefined) {
				axios
					.post("/patient/resend-link", { token: this.$route.params.token })
					.then((response) => {
						if (response.data.message != null) {
							this.successMessage = null;
							this.errorMessage = response.data.message;
						} else {
							this.errorMessage = null;
							this.successMessage = response.data.data;
						}
						this.isDisabled = true;
						if (response.data.message == 'Token is not valid.' || response.data.data == 'Please check your email for the new activation link.') {
							this.isresendBtn = false;
						}
					})
					.catch((error) => {
						this.errorMessage = error.response.data.message;
					});
			} else {
				axios
					.post("/patient/represent/resend-link", { token: this.$route.params.token })
					.then((response) => {
						if (response.data.message != null) {
							this.successMessage = null;
							this.errorMessage = response.data.message;
						} else {
							this.errorMessage = null;
							this.successMessage = response.data.data;
						}
						this.isDisabled = true;
						if (response.data.message == 'Token is not valid.' || response.data.data == 'Please check your email for the new activation link.') {
							this.isresendBtn = false;
						}
					})
					.catch((error) => {
						this.errorMessage = error.response.data.message;
					});
			}
		},
		handleReset: function () {
			this.v$.$validate();
			if (!this.v$.$error) {
				this.form.token = this.$route.params.token;
				this.errorMessage = "";
				this.successMessage = "";
				this.$store.state.loader = true;
				this.isDisabledBtn = true;
				if (this.$route.params.id == undefined) {

					axios
						.post("/patient/pp-password-set", this.form)
						.then((response) => {
							this.successMessage = "Your Username is " + response.data.data.username;
							setTimeout(function () {
								window.location.href = `${process.env.VUE_APP_PUREPORTAL}`;
							}, 1000);
							this.$store.state.loader = false;
						})
						.catch((error) => {
							console.log(error);
							this.isDisabledBtn = false;
							this.errorMessage = error.response.data.message;
							this.$store.state.loader = false;
						});
				} else {
					axios
						.post("/patient/represent/pp-password-set", this.form)
						.then((response) => {
							this.successMessage = "Your Username is " + response.data.data.username;
							setTimeout(function () {
								window.location.href = `${process.env.VUE_APP_PUREPORTAL}`;
							}, 1000);
							this.$store.state.loader = false;
						})
						.catch((error) => {
							console.log(error);
							this.isDisabledBtn = false;
							this.errorMessage = error.response.data.message;
							this.$store.state.loader = false;
						});
				}
			}
		},
		passcheck() {
			if (this.form.password_confirmation != '' && this.form.password != this.form.password_confirmation) {
				if ($("#password_confirmation").find(".confirmerror").length != 1) {
					this.isDisabledBtn = true;
					$("#password_confirmation")
						.find("ul")
						.append(
							'<li class="d-block confirmerror">Password Confirmation Does Not Match.</li>'
						);
				}
				this.isDisabledBtn = true;
				this.confirmerror = true;
			} else {
				this.isDisabledBtn = false;
				$("#password_confirmation")
					.find(".confirmerror")
					.remove();
				this.confirmerror = false;
				this.passPatternChk()
			}

		},
		passPatternChk() {
			var passPattern = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
			if (!this.form.password.match(passPattern)) {
				this.isDisabledBtn = true;
				if ($("#password").find(".confirmerror").length != 1) {
					$("#password")
						.find("ul")
						.append(
							'<li class="d-block confirmerror">The password must be at least 8 characters long, including one upper/lower case, at least one number, a special character, and no space.</li>'
						);
				}
				this.confirmerror = true;
			} else {
				this.isDisabledBtn = false;
				$("#password")
					.find(".confirmerror")
					.remove();
				this.confirmerror = false;
				this.passcheck();
			}
		}
	},
};
</script>
