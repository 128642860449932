
import {
  helpers,
  required,
  email,
  minLength,
  maxLength,
  sameAs,
  numeric,
  requiredIf,
  alpha,
  alphaNum,
} from "@vuelidate/validators";

const zipcode = (value) => {
  if(value.length > 6 && value.length < 10){
    return false
  }
  return true;
}

const CovidExams = {
  namespaced: true,

  state: {
    form: {},
    vuelidateExternalResults: {
      form: {
        first_name:[],
        last_name:[],
        phone_no:[],
        date_of_birth:[],
        schedule_time:[],
        sex:[],
        address:[],
        zip_code:[],
        office_id:[],
        country_id:[],
        state_id:[],
        city:[],
        // insurance_company_id:[],
        // insurance_id_number:[],
        // subscriber_relation:[],
        // subscriber_first_name:[],
        // subscriber_middle_name:[],
        // subscriber_last_name:[],
        // subscriber_address:[],
        // subscriber_zip_code:[],
        // subscriber_city:[],
        // subscriber_state_id:[],
        driver_license_number:'',
        reasons_for_visit:[],
        email_address:[],
        document:[],
      },
    },
    validationRules: {
      form: {
        first_name: {
          required: helpers.withMessage("First Name is required", required),
          alpha: helpers.withMessage('Please enter valid First Name', alpha),
        },
        middle_name: {
          alpha: helpers.withMessage('Please enter valid Middle Name', alpha),
        },
        last_name: {
          required: helpers.withMessage("Last Name is required", required),
          alpha: helpers.withMessage('Please enter valid Last Name', alpha),
        },
        phone_no:{
          required: helpers.withMessage("Phone Number is required", required),  
        },
        date_of_birth:{
          required: helpers.withMessage("Date of Birth is required", required), 
        },
        schedule_time:{
          required: helpers.withMessage("Visit Date is required", required),    
        },
        sex:{
          required: helpers.withMessage("Gender is required", required),    
        },
        address:{
          required: helpers.withMessage("Address is required", required),    
        },
        city:{
          required: helpers.withMessage("city is required", required),
          alphaNum: helpers.withMessage("Please enter valid city name", alphaNum),
        },
        zip_code:{
          required: helpers.withMessage("Zip Code is required", required),    
          zipcode: helpers.withMessage('Please enter a valid zip code', zipcode),
          min: minLength(5),
          max: maxLength(10),
        },
        pcr_test: {
          required: helpers.withMessage('Please select any single test', required),
        },
        office_id:{
          required: helpers.withMessage("Office is required", required),    
        },
        country_id:{
          required: helpers.withMessage("Country is required", required),    
        },
        state_id:{
          required: helpers.withMessage("State is required", required),    
        },
        driver_license_number:{
          required: helpers.withMessage("Driver License Number is required", required),    
        },
        // insurance_company_id:{
        //     required: helpers.withMessage("Insurance Name is required", requiredIf(function() {
        //         return this.$store.state.is_insurance ? true : false;
        //     })),
        // },
        // insurance_id_number:{
        //   required: helpers.withMessage("Insurance Id is required", requiredIf(function() {
        //         return this.$store.state.is_insurance ? true : false;
        //   })),
        // },
        // subscriber_relation:{
        //   required: helpers.withMessage("Subscriber Relation is required", requiredIf(function() {
        //         return this.$store.state.is_patient_is_subscriber ? true : false;
        //   })),
        // },
        // subscriber_first_name:{
        //   required: helpers.withMessage("Subscriber First Name is required", requiredIf(function() {
        //         return this.$store.state.is_patient_is_subscriber ? true : false;
        //   })),
        // },
        // subscriber_middle_name:{
        //   required: helpers.withMessage("Subscriber Middle Name is required", requiredIf(function() {
        //         return this.$store.state.is_patient_is_subscriber ? true : false;
        //   })),
        // },
        // subscriber_last_name:{
        //   required: helpers.withMessage("Subscriber Last Name is required", requiredIf(function() {
        //         return this.$store.state.is_patient_is_subscriber ? true : false;
        //   })),
        // },
        // subscriber_address:{
        //   required: helpers.withMessage("Subscriber Address is required", requiredIf(function() {
        //         return this.$store.state.is_patient_is_subscriber ? true : false;
        //   })),
        // },
        // subscriber_zip_code:{
        //   required: helpers.withMessage("Subscriber Zipcode is required", requiredIf(function() {
        //         return this.$store.state.is_patient_is_subscriber ? true : false;
        //   })),
        // },
        // subscriber_city:{
        //   required: helpers.withMessage("Subscriber City is required", requiredIf(function() {
        //         return this.$store.state.is_patient_is_subscriber ? true : false;
        //   })),
        // },
        // subscriber_state_id:{
        //   required: helpers.withMessage("Subscriber State is required", requiredIf(function() {
        //         return this.$store.state.is_patient_is_subscriber ? true : false;
        //   })),
        // },
        reasons_for_visit:{
          required: helpers.withMessage("Reasons For Visit is required", required),    
        },
        email_address:{
          required: helpers.withMessage("Email Address is required", required), 
          email: helpers.withMessage("Email Address is not valid", email),   
        },
        // document : {
        //   required: requiredIf(function() {
        //     return this.form.license_expiry_date && this.form.driver_license_number ? true : false;
        //   }),
        // },
      },
    },
  },
  getters: {
    getTableHeaders: (state) => state.header,
    getTableContents: (state) => state.list,
    getListURL: (state) => state.listUrl,
  },
  mutations: {},
  actions: {},
}

export default CovidExams;
