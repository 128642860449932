<template>
  <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-2">
    <div class="sticky-scroll-box">
      <div class="accorsidebar">
        <div class="accordion-item">
          <p class="accordion-header display-vertical" id="addpatienttab">
              <span class="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapsedemogr" aria-expanded="false" aria-controls="collapsedemogr">Staff</span>
          </p>
          <div
            id="collapsedemogr"
            class="accordion-collapse collapse"
            aria-labelledby="addpatienttab"
            data-bs-parent="#accordionExample"
          >
          <div class="side-navigation">
            <ul class="side-bar-menu side-bar-tree patientTabs" data-widget="tree">
              <li>
                <a
                  href="javascript:void(0)"
                  ref="StaffAddLink"
                  @click.prevent="changeComp('StaffAdd')"
                  class="active"
                  >Staff Settings</a
                >
              </li>
              <li :class="newProvider">
                <a
                  href="javascript:void(0)"
                  ref="SchedulingLink"
                  @click.prevent="changeComp('Scheduling')"
                  >Staff Scheduling</a
                >
              </li>
              <li :class="newProvider" v-if="permissioncheck(this.$store.state.permission.Manage_Accounts)">
                <a
                  ref="StaffAccessPermission"
                  @click.prevent="changeComp('accessPermission')"
                  >Staff's Access Permission</a
                >
              </li>
            </ul>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import permissionMixins from "@/scripts/mixins/permission.js";
export default {
  mixins: [permissionMixins],
  data() {
    return {};
  },
  computed: {
    newProvider() {
      return this.$route.name === "StaffAdd" ? "linkDisabled" : "";
    },
  },

  watch: {
    "$route.name": function(newValue) {
      this.linkActive();
    },
  },

  mounted() {
    this.linkActive();
  },

  methods: {
    changeComp(name) {
      if (name === "Scheduling" && this.$route.params.id) {
        this.$router.push(
          `/staff/edit/scheduling/${this.$route.params.id}`
        );
      } else if (name === "StaffAdd" && this.$route.params.id) {
        this.$router.push(
          `/staff/edit/${this.$route.params.id}`
        );
      }
      else if (name === "accessPermission" && this.$route.params.id) {
        this.$router.push(
          `/staff/edit/permission/${this.$route.params.id}`
        );
      }
    },
    linkActive() {
      if (
        this.$route.name === "StaffAdd" ||
        this.$route.name === "Staffedit"
      ) {
        this.$refs.StaffAddLink.classList.add("active");
        this.$refs.SchedulingLink.classList.remove("active");
        this.$refs.StaffAccessPermission && this.$refs.StaffAccessPermission.classList.remove("active");
      } else if (this.$route.name === "StaffEditScheduling") {
        this.$refs.SchedulingLink.classList.add("active");
        this.$refs.StaffAddLink.classList.remove("active");
        this.$refs.StaffAccessPermission && this.$refs.StaffAccessPermission.classList.remove("active");
      } else if (this.$route.name === "StaffAccessPermission") {
        this.$refs.StaffAccessPermission && this.$refs.StaffAccessPermission.classList.add("active");
        this.$refs.SchedulingLink.classList.remove("active");
        this.$refs.StaffAddLink.classList.remove("active");
      }
    },
  },
};
</script>