import {
  helpers,
  required,
  email,
  minLength,
  maxLength,
  sameAs,
  numeric,
} from "@vuelidate/validators";

const LockedClinicalNotes = {
  namespaced: true,

  state: {
    tableData: {
      header: [
        "Date of Service",
        "Reason",
        "Locked by",
        "Locked on",
        "Actions",
      ],
    },
    form: {},
    listUrl: "/patient/:patient_unique_id/locked_clinical_notes",
    list: [],
  },
  getters: {
    getTableHeaders: (state) => state.header,

    getTableContents: (state) => state.list,
    getListURL: (state) => state.listUrl,
  },
  mutations: {},
  actions: {},
}

export default LockedClinicalNotes;
