<template>
  <div class="box notescollap">
    <div class="box-body">
      <div class="pageheading">
        <div class="row justify-content-between">
          <div class="col-12 col-sm-7 col-md-7 col-lg-6 col-xs-6">
            <h3 class="font-bold">Call Recordings</h3>
          </div>
        </div>
      </div>
      
      <div id="printPastAppointment">
        <div class="table-responsive">
          <table v-if="appointment_list" class="table table-hover">
            <thead>
              <tr>
                <th>Date</th>
                <th>File</th>
                <th>Duration</th>
                <th>Status</th>
                <th>Size</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody :set="count1=0">
              <tr v-for="(item, index) of call_log_list" :key="index">
                <td :set="count1++">{{ dateFormated(item.dateCreated.date) }}</td>
                <td>{{ item.sid }}.{{ item.containerFormat }}</td>
                <td>{{ secondsToHms(item.duration) }}</td>
                <td style="text-transform: capitalize;">{{ item.status }}</td>
                <td>{{ bytesToSize(item.size) }}</td>
                <td>
                  <a href="#" 
                    class="waves-effect waves-circle btn btn-circle btn-info btn-xs me-5" 
                    :class="item.status!='completed' ? 'disabled' : ''"
                    title="Download"
                    @click="downloadfile(item.mediaExternalLocation, item.sid, item.containerFormat)"
                  >
                    <i class="fa fa-download"></i>
                  </a>
                </td>
              </tr>
              <tr v-if="count1==0"><td align="center" colspan="6">No record found.</td></tr>
            </tbody>
          </table> 
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import axios from "@/scripts/axios.js";
import moment, * as moments from "moment";

export default {
  data() {
    return {
      patient_id: this.$store.state.patientDetail.patient_id,
      patient_unique_id: this.$store.state.patientDetail.patient_unique_id,
      output: null,
      curDate: '',
      appointment_list: 'jh',
      ccdarecipient: null,
      mail_subject: null,
      mail_body: null,
      ccda_appointment_id: null,
      loader: false,
      email_error: null,
      referring_doctor:[],
      referring_doctor_contact_id:'',
      ordering_doctor_contact_id:'',
      referral_doctor:0,
      id:'',
      action:[],
      room_sid: "",
      call_log_list: ""
    };
  },
  async mounted() {
    this.room_sid = this.$route.params.room_sid;
    this.$store.state.loader = true;
    await axios.post("/telehealth-twilio/get_recordings",{
      room_sid: this.room_sid
    })
    .then((response) => {
      this.call_log_list = response.data.data.result;
      this.$store.state.loader = false;
    });
  },
  methods: {
    dateFormated(dt) {
      return moment(dt).format("ddd MMM DD");
    },
    async downloadfile(fileURL, filename, filetype) {
      console.log(fileURL+"-----"+filename+"------"+filetype);
      
      this.$store.state.loader = true;
      await axios.post("/telehealth-twilio/get-download-link",{
        fileURL: fileURL,
        filename: filename,
        filetype: filetype
      })
      .then((response) => {
        this.$store.state.loader = false;
        window.open(response.data, '_blank').focus();
      });
    },
    secondsToHms(d) {
      d = Number(d);
      var h = Math.floor(d / 3600);
      var m = Math.floor(d % 3600 / 60);
      var s = Math.floor(d % 3600 % 60);

      var hDisplay = h > 0 ? (h<=9 ? "0" : "") + h : "00";
      var mDisplay = m > 0 ? (m<=9 ? "0" : "") + m : "00";
      var sDisplay = s > 0 ? (s<=9 ? "0" : "") + s : "00";
      return hDisplay + ":" + mDisplay + ":" + sDisplay; 
    },
    bytesToSize(bytes) {
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
      if (bytes === 0) return 'n/a'
      const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10)
      if (i === 0) return `${bytes} ${sizes[i]})`
      return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`
    }
  }
};
</script>
