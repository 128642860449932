<template>
<div class="content-wrapper">
	<div class="container-full">
		<div class="content-header">
			<h1 class="heading1 my-0 d-inline-block me-2">Sign Bulk Note</h1>
			<Popper arrow content="It is used to sign a large number of notes in one go." :hover="true" class="helptips cursor-pointer fs-14">
				<i class="fa fa-info-circle fs-16"></i>
			</Popper>
		</div>
		<section class="content">
			<div class="box p-10 mb-10">
				<div class="row">
					<div class="col-12 col-sm-6 col-md-4 col-lg-3" v-if="this.user_type == 'staff' || this.is_super_admin == 1 || this.is_super_visor == 1">
						<div class="form-group mb-5">
							<multi-select-box
								id="doctors"
								title="Provider"
								v-model="filter.provider_id"
								getDataURL="/get-user-provider"
								labelClass="col-12 col-sm-12 col-md-12 col-lg-12"
								divClass="col-12 col-sm-12 col-md-12 col-lg-12"
								>
							</multi-select-box>
						</div>
					</div>

					<div class="col-12 col-sm-6 col-md-4 col-lg-3" v-if="this.user_type == 'staff' || this.is_super_admin == 1 || this.is_super_visor == 1">
						<div class="form-group mb-5">
							<multi-select-box
								id="Supervisors"
								v-model="filter.supervisor_id"
								title="Supervisors"
								getDataURL="json_dropdown/provider?is_supervisor=1"
								labelClass="col-12 col-sm-12 col-md-12 col-lg-12"
								divClass="col-12 col-sm-12 col-md-12 col-lg-12"
								>
							</multi-select-box>
						</div>
					</div>
					<div class="col-12 col-sm-6 col-md-4 col-lg-3">
						<div class="form-group mb-5">
							<label class="col-12 col-sm-12 col-md-12 col-lg-12">Signature Status</label>
							<div Class="col-12 col-sm-12 col-md-12 col-lg-12">
								<select class="form-control" v-model="filter.note_status">
									<option selected="selected" value="">Select Signature Status </option>
									<option value="pending">Provider Sign Pending</option>
									<option value="provider_signed">Supervisor Sign Pending</option>
									<option value="locked">Locked</option>
								</select>
							</div>
						</div>
					</div>

					<div class="col-12 col-sm-6 col-md-4 col-lg-3">
						<div class="form-group mb-5 availdate">
							<label class="col-12 col-sm-12 col-md-12 col-lg-12">Date</label>
							<div Class="col-12 col-sm-12 col-md-12 col-lg-12">
								<ejs-daterangepicker 
									id="daterangepicker" 
									ref="daterangepicker"
									:value="date" 
									:placeholder="waterMarkText" 
									:focus="daterangefocus"
									@change="getdate">
								</ejs-daterangepicker>
							</div>
						</div>
					</div>

					<div class="col-12 col-sm-6 col-md-4 col-lg-3">
						<div class="form-group row">
							<label class="col-12 col-sm-12 col-md-12 col-lg-12">Patient</label>
							<div class="col-12 col-sm-12 col-md-12 col-lg-12">
								<div class="selectcontrols">
									<Autocomplete :max="30" 
										@input="getlist"
										ref="autocomplete" 
										:results="patientResult" 
										@onSelect="resultsvalue" 
										class="form-control ms-0 autosearch-list" 
										id="autosearch-list"
										placeholder="Search Patient"
										:use-html-for-results ="true"
										>                        
										</Autocomplete>
								</div>
							</div>
						</div>
					</div>

					<div class="col-12 col-sm-6 col-md-4 col-lg-3">
						<div class="form-group row">
							<div class="col-12 col-sm-12 col-md-12 col-lg-12 mt-sm-25">
								<div class="controls">
									<a @click="filter_data()" class="waves-effect waves-light btn btn-info-light py-7">Search</a>&nbsp;
									<a @click="reset_filter()" class="waves-effect waves-light btn btn-info-light py-7">Reset All</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- <a class="waves-effect waves-light btn btn-secondary mb-1 btn-sm">Export Report To Excel</a>
			<a class="waves-effect waves-light btn btn-secondary mb-1 btn-sm">Print non-blank Clinical Notes in Report</a>
			<a class="waves-effect waves-light btn btn-secondary mb-1 btn-sm">Bulk Lock Notes</a> -->

			<div class="box mt-10">
				<div class="box-body">
					<div class="mb-10" v-if="this.action_button != ''">
						 <a @click="sign_notes('lock')" class="waves-effect waves-light btn btn-info btn-sm">Sign and Lock</a>
					</div>
					<div class="table-responsive">
						<data-table-server dataType="BulkSignClinicalNotes" dataMethod="post" @checkall="checkall" :key="key">
							<template v-slot="slotProps">
								<td>
									<a v-bind:href="'#/patient-chart/'+slotProps.item.patient_chart" target="_blank">{{ slotProps.item.Patient }}</a>
								</td>
								<td>
									<a v-bind:href="'/#/patient-chart/'+slotProps.item.patient_chart+'/edit-appointment/'+slotProps.item.appt_number" target="_blank">
										{{ $filters.displayDateTimeLocal(slotProps.item.schedule_date,'',false,"ddd, MM-DD-YYYY",true) }} {{ $filters.timeFormat(slotProps.item.schedule_time) }}
									</a>
								</td>
								<td>{{ slotProps.item.rendering_provider }}</td>
								<td>{{ (slotProps.item.provider_id === slotProps.item.supervisor_id || slotProps.item.supervisor_id == null) ? '-' : slotProps.item.supervisor_provider }}</td>
								<td>
									<span class="badge badge-warning-light font-bold fs-14" v-if="slotProps.item.locked == 'Pending'">{{ slotProps.item.locked }}</span>
									<span class="badge font-bold fs-14" :class="slotProps.item.locked == 'Locked' ? 'badge-success-light' : 'badge-danger-light'" v-else>{{ slotProps.item.locked }}</span>
								</td>
								<td>
										{{ slotProps.item.rendering_signed_Off }}
								</td>
								<td> 
										{{ slotProps.item.supervising_signed_Off }}
								</td>
								<td>
									<Popper arrow content="Edit Notes" :hover="true" class="helptips cursor-pointer">
										<a v-bind:href="'/#/patient-chart/'+slotProps.item.patient_chart+'/clinical-notes/#'+slotProps.item.appt_number" class="waves-effect waves-circle btn btn-circle btn-info btn-xs"><i class="fa fa-pencil"></i></a>
									</Popper>
								</td>
								<td v-if="this.user_type == 'provider' || permissioncheck(this.$store.state.permission.Sign_Lock_Clinical_Notes)">
									<div v-if="slotProps.item.locked != 'Locked'" class="controls">
										<input v-bind:id="'checkbox_id'+slotProps.item.note_main_id" type="checkbox" class="filled-in chk-col-info myCheckbox" @click="uncheckall(slotProps.item.note_main_id)" :value="slotProps.item.note_main_id" name="patient_clinical_note">
										<label v-bind:for="'checkbox_id'+slotProps.item.note_main_id" class="m-0 text-start fs-16"></label>
									</div>
								</td>
							</template>
						</data-table-server>
					</div>
				</div>
			</div>
		</section>
	</div>
</div>
</template>

<script>
	import { mapGetters, mapActions } from "vuex";
	import DataTableServer from "@/components/base/DataTableServer.vue";
	import MultiSelectBox from "@/components/base/formFields/multiSelectBox.vue";
	import axios from "@/scripts/axios.js";
	import Popper from "vue3-popper";
	import permissionMixins from "@/scripts/mixins/permission.js";
	import Autocomplete from "vue3-autocomplete";

	export default {
		components: {
			MultiSelectBox,
			DataTableServer,
			Popper,
			Autocomplete
		},
		data() {
			return {
				user_type: '',
				is_super_admin: '',
				waterMarkText: "Select Date",
				date:new Date(),
				result:{},
				patientResult: [],
				clinical_note_id:{},
				action_button:'',	
				is_super_visor: '',	
				key:0,		
			}
		},
		mixins: [permissionMixins],
		async beforeCreate() {},
		created(){
			this.getUserData();
		},
		methods:{
			...mapActions("auth", ["getUserData"]),
			getlist(obj) {
				if (obj.length > 1) {
					this.$refs.autocomplete.$el.classList.add("selectloader");
						axios.post("/patient/patient-search", {
								search_text: obj,
							}).then((response) => {
								if(response.data.data.length>0){
									this.patientResult = response.data.data.map((patient) => {
										return { id: patient.id, name: patient.first_name+" "+patient.last_name }
									});
									this.$refs.autocomplete.$el.classList.remove("selectloader");                    
								}else{
										this.patientResult = {};
								}
							});
				}
				if (obj.length == 0) {
					this.patientResult = {};
					this.filter.patient_id  = '';
				}
			},
			resultsvalue(obj) {
				this.$emit("clicked", obj);
				this.$refs.autocomplete.searchText = obj.name;
				this.patientResult = {};
				this.filter.patient_id = obj.id;
				this.focusout();
			},
			focusout() {
				const btn = this.$refs.autocomplete;
				btn.$el
				.querySelector(".vue3-autocomplete-container > .autosearch-list")
				.blur();
			},
			sign_notes(lock=''){
				var clinical_note_id_Array = Array();
				$("input:checkbox[name=patient_clinical_note]:checked").each(function() {
					clinical_note_id_Array.push($(this).val());
				});
				this.clinical_note_id = clinical_note_id_Array;

				this.$store.state.loader = true;
				axios.post("clinical-notes/bulk-sign", {clinical_note_ids: this.clinical_note_id, lock: lock})
					.then((response) => {
						this.$store.state.loader = false; 
						this.$swal.fire("Done!", "Notes are signed.", "success")
							.then((result) => {
								if (result.isConfirmed == true) {
									this.key++;
									// location.reload();
								}
							}); 
					}).catch((error) => {
						this.$store.state.loader = false
						this.$swal.fire("Sorry!", "Something went wrong please try again.", "warning"); 
					});
			},
			checkall(){
				if($(".chk-col-info").eq(0).is(':checked')){
					if($('.myCheckbox').length > 0){
						this.action_button = "show";
						$('.myCheckbox').prop('checked', true);
					}
				}
				else{
					this.action_button = "";
					$('.myCheckbox').prop('checked', false); 
				}
			},
			uncheckall(id){
				var checked = $('#checkbox_id'+id).is(':checked');
				if($(".myCheckbox").prop("checked") == false)
				{
					this.action_button = "";
				}
				if(checked == false){
					$('#checkAll').prop('checked', false); 
				}
				else{
					this.action_button = "show";
				}
			},
			filter_data(){
				this.key++;
			},
			reset_filter(){
				$('#daterangepicker').val('');
				this.filter.provider_id = "";
				this.filter.supervisor_id = "";
				this.filter.note_status = "";
				this.filter.from_date = "";
				this.filter.patient_id = "";
				this.$refs.autocomplete.searchText = '';
				this.key++;
			},
			getdate(){
				var daterange = $('#daterangepicker').val();
				var date = daterange.split(' - ');
				this.filter.from_date  = date[0];
				this.filter.to_date  = date[1];
			},
			daterangefocus(){
				this.$refs.daterangepicker.show();
			}
		},
		computed: {
			...mapGetters("auth", ["user"]),
			filter() {
				return this.$store.state.BulkSignClinicalNotes.filter;
			}
		},
		mounted(){
		},
		watch: {
			"user": function() {
				this.is_super_visor = this.user.is_supervisor;
				this.user_type = this.user.user_type;
				this.is_super_admin = this.user.is_super_admin;
			}
		}
	}
</script>