<template>
    <table id="audit-table" class="table table-hover">
        <thead>
            <tr>
                <th>Date</th>
                <th>Time</th>
                <th>Name</th>
                <th>Action</th>
                <th>Details</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(item, index) in tableData" :key="index" class="hover-primary">
                <td>{{$filters.displayDateTimeLocal(item.log_date,'',false,'ddd, MM-DD-YYYY',true)}}</td>
                <td>{{$filters.displayDateTimeLocal(item.log_date+" "+item.log_time,'',true,'hh:mm A',false,true)}}</td>
                <td>{{user_list[item.user_id]}}</td>
                <td>{{item.action}}</td>
                <td v-html="item.detail"></td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import axios from "@/scripts/axios.js";
import submitMixins from "@/scripts/mixins/submitMixins.js";
import moment from 'moment';
export default {
    mixins: [submitMixins],
    props: {
        LogType: Object,
        patient_unique_id: Number,    
    },
    data() {
        return {
            tableData: [],            
            patient_list: [],
            user_list: [],
        };
    },
    mounted(){
        this.$store.state.loader = true;
        axios.post('audit-log',{'filter_log_type': [this.LogType], 'filter_patient_id': this.patient_unique_id, "activity":"patient_chart"})
        .then((res) => {
            this.tableData = res.data.data.audit_log,
            this.patient_list = res.data.data.patient_list,
            this.user_list = res.data.data.user_list,
            this.$store.state.loader = false
        })
        
    },
    methods:{
        localtime(date,time){
            var datadate=date+" "+time;
            var datatime=moment(datadate).utc(datadate);
            var timedata=datatime.local().format('h:mm A');
            return timedata;
        }
    },
    computed: {        
    },  
};
</script>