<template>
	<div class="content-wrapper">
		<div class="container-full">
			<div class="content-header">
				<h1 class="heading1 m-0">Collection Report <a href="#" data-bs-toggle="modal" data-bs-target="#collectiondocument" title="Reference of Knowledge Base" v-on:click.prevent="reset()"> <i class="fa fa-question-circle fs-20"></i></a></h1>
			</div>
			<section class="content">
				<div class="box">
					<div class="box-body">
						<div class="row">
							<div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 px-5">
								<div class="form-group">
									<label class="form-label">Choose Date Range</label>
									<div class="form-group mb-5 availdate">
										<ejs-daterangepicker
											id="daterangepicker"
											:placeholder="waterMarkText"
											:format="dateFormat"
											:openOnFocus="true"
											@change="getdate"
											:value="filter.date_range"
										>
											<e-presets>
												<e-preset label="Today" :start='today' :end='today'></e-preset>
												<e-preset label="Yesterday" :start='yesterday' :end='yesterday'></e-preset>
												<e-preset label="Last 7 days" :start='last7days' :end='today'></e-preset>
												<e-preset label="Last 30 days" :start='last30days' :end='today'></e-preset>
												<e-preset label="Last Month" :start='lastStart' :end='lastEnd'></e-preset>
												<e-preset label="Last 3 Months" :start='last3Start' :end='lastEnd'></e-preset>
												<e-preset label="Last 6 Months" :start='last6Start' :end='lastEnd'></e-preset>
												<e-preset label="Last 12 Months" :start='last12Start' :end='lastEnd'></e-preset>
											</e-presets>
										</ejs-daterangepicker>
									</div>
								</div>
							</div>
							<div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 px-5">
								<div class="form-group">
									<label class="form-label" style="width:100%;">Billing Type</label>
									<!-- <select class="form-select" v-model="filter.source">
										<option selected="selected" disabled="disabled" value="">Select Source</option>
										<option value="Healthcare">Healthcare</option>
										<option value="POS">POS</option>
										<option value="Insurance">Insurance</option>
										<option value="Contract">Contract Auto Pay</option>
									</select> -->
									<selectCheckBox :dataSource='source' labelName="Select Billing Type" @clicked="selected_source" :displayDiv="false" :key="select_box_key"/>
								</div>
							</div>
							<div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-2 px-5">
								<div class="mt-md-25">
									<button
										class="b-0 waves-effect waves-light btn btn-info py-7 me-1"
										@click="search">
										Search
									</button>
									<button
										class="b-0 waves-effect waves-light btn btn-info-light py-7 me-1"
										@click="Clear">
										Clear
									</button>
								</div>
							</div>
							<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4 px-5">
								<div class="mt-md-25 form-group" style="text-align: right;">
									<div class="d-inline-block"><label class="form-label">No. of Transaction:</label> {{ totalEnteries }}</div> &nbsp; &nbsp;
									<div class="d-inline-block"><label class="form-label">Total Billed Amount:</label> ${{ totalCollection }}</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="box">
					<div class="row">
						<div class="box-body col-12 col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-8">
							<div class="table-responsive">
								<table class="table table-hover">
									<thead>
										<tr class="text-center">
											<th>Medical Chart ID</th>
											<th>First Name</th>
											<th>Last Name</th>
											<th>Source</th>
											<th>Amount</th>
											<th>Payment Date</th>
										</tr>
									</thead>
									<tbody v-if="list.data && list.data.length > 0">
										<tr
											v-for="(item, index) in list.data"
											:key="index"
											class="text-center"
										>
											<td>
												<router-link
													:to="{
														name: 'PatientDetails',
														params: { id: item.unique_id },
													}"
													:title="
														'View Chart of ' +
															item.patinet_name +
															'(' +
															item.chart_id +
															')'
													"
													class="fw-500"
													>{{ item.chart_id }}</router-link
												>
											</td>
											<td>{{ item.first_name }}</td>
											<td>{{ item.last_name }}</td>
											<td>{{ item.pay_source }}</td>
											<td :class="item.charge<0 ? 'bg-danger-light' : 'bg-success-light'">{{ item.charge }}</td>
											<td>{{ $filters.displayDateTimeLocal(item.pay_date,'',false,"ddd, MM-DD-YYYY",true) }}</td>
										</tr>
										<tr class="text-center bg-info-light border-info text-bold">
											<td>&nbsp;</td>
											<td>&nbsp;</td>
											<td colspan="2">Page Total:</td>
											<td>${{ calculatePageTotal(list.data) }}</td>
											<td>&nbsp;</td>
										</tr>
										<tr class="text-center bg-info-light border-info text-bold">
											<td>&nbsp;</td>
											<td>&nbsp;</td>
											<td colspan="2">Total Billed Amount:</td>
											<td>${{ totalCollection }}</td>
											<td>&nbsp;</td>
										</tr>
									</tbody>
									<tbody v-else>
										<tr>
											<td colspan="8" class="text-center">No data available</td>
										</tr>
									</tbody>
								</table>
								<div style="float:right; margin-right: 10px">
									<ul class="pagination">
										<li
										class="paginate_button page-item "
										:class="isDisabled('previous')"
										@click="previous()"
										>
											<a
												href="#"
												aria-controls="example1"
												data-dt-idx="0"
												tabindex="0"
												class="page-link"
												>Previous</a>
										</li>
										<li class="paginate_button page-item active">
											
											<select 
												style="width: 80px; margin-right: 0px; display: inline-block; margin: 0 0px; height: 34px; outline: none; border-color: #d4dde6; background: #fff; border-radius: 3px;"
												v-model="filter.page"
												@change="pagejump($event.target.value)">
												<option v-for="(item, index) in list.last_page"  :key="index"  :value="item"
												>{{ item }}</option>
											</select>
										</li>

										<li
											class="paginate_button page-item next"
											:class="isDisabled('next')"
											id="example1_next"
											@click="next()">
											<a
												href="#"
												aria-controls="example1"
												data-dt-idx="2"
												tabindex="0"
												class="page-link"
												>Next</a>
										</li>
									</ul>
									</div>
							</div>
						</div>
				
						<div class="box-body col-12 col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
							<collectionReportChart :POS="totalPOS" :Healthcare="totalHealthcare" :Insurance="totalInsurance" :Contract="totalContract" :key="colChartKey" />
						</div>
					</div>
				</div>

			</section>
			<!--document model start-->
			<div id="collectiondocument" class="modal fade" tabindex="-1" role="dialog"
				aria-labelledby="myModalLabel" aria-hidden="true">
				<div class="modal-dialog modal-md" style="max-width: 600px;">
					<div class="modal-content">
					<div class="modal-header">
					<h3 class="modal-title fw-900 mt-0">Reference of Knowledge Base</h3>
					</div>
					<div class="modal-body overflow-auto" style="max-height: 350px;">
					<table class="table table-bordered">
						<thead>
							<tr>
							<th scope="col">No.</th>
							<th scope="col">Knowledge Base Articles</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>1</td>
								<td> <a :href="path+'how-to-find-the-amount-collected-from-insurance-or-cash-practice-in-pureehr'" target="_blank">How To Find The Amount Collected From Insurance Or Cash Practice In PureEHR?</a></td>
							</tr>
						</tbody>
					</table>
					<Search :key="searchKey"></Search>
					</div>
					<div class="modal-footer">
					<button type="button" @click="" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
					</div>
					</div>
				</div>
			</div>
			<!--document model end-->
		</div>
	</div>
</template>

<script>
import dataTable from "@/components/base/DataTable.vue";
import axios from "@/scripts/axios.js";
import submitMixins from "@/scripts/mixins/submitMixins.js";
import selectCheckBox from "@/components/base/selectCheckBox.vue";
import permissionMixins from "@/scripts/mixins/permission.js";
import Popper from "vue3-popper";
import Search from "@/components/Search.vue";
import collectionReportChart from "@/components/Report/CollectionReportChart1.vue";

export default {
	mixins: [submitMixins, permissionMixins],
	components: { dataTable, Popper, Search, selectCheckBox, collectionReportChart },
	data() {
		return {
			waterMarkText: "Select a Range",
			dateFormat: "MM-dd-yyyy",
			list: [],
			patientsource: [],
			filter: {
				date_range: "",
				source: ['All'],
				page: 1,
			},
			patinet_id: [],
			patinet_name: "",
			list_lenght: "",
			start: 0,
			end: 25,
			step: 25,
			steps: "",
			path: process.env.VUE_APP_KB_DOCUMENT,
			searchKey:0,
			source:[
				{ id:'Healthcare', name:'Healthcare' },
				{ id:'POS', name:'POS' },
				{ id:'Insurance', name:'Insurance' },
				{ id:'Contract', name:'Contract Auto Pay' },
			],
			totalEnteries: 0,
			totalCollection: 0,
			totalPOS: 0,
			totalHealthcare: 0,
			totalInsurance: 0,
			totalContract: 0,
			colChartKey: 0,
			select_box_key: 1,
			isPrevDisable: false,
			isNextDisable: false,
			today :  new Date(new Date().toDateString()),
			yesterday : new Date(new Date(new Date().setDate(new Date().getDate()-1)).toDateString()),
			last7days : new Date(new Date(new Date().setDate(new Date().getDate()-7)).toDateString()),
			last30days : new Date(new Date(new Date().setDate(new Date().getDate()-30)).toDateString()),
			weekStart :  new Date(new Date(new Date().setDate(new Date().getDate() - (new Date().getDay() + 7) % 7)).toDateString()),
			weekEnd : new Date(new Date(new Date().setDate(new Date(new Date().setDate((new Date().getDate() - (new Date().getDay() + 7) % 7))).getDate() + 6)).toDateString()),
			monthStart :  new Date(new Date(new Date().setDate(1)).toDateString()),
			monthEnd : new Date(new Date(new Date(new Date().setMonth(new Date().getMonth() + 1)).setDate(0)).toDateString()),
			lastStart : new Date(new Date(new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(1)).toDateString()),
			last3Start : new Date(new Date(new Date(new Date().setMonth(new Date().getMonth() - 3)).setDate(1)).toDateString()),
			last6Start : new Date(new Date(new Date(new Date().setMonth(new Date().getMonth() - 6)).setDate(1)).toDateString()),
			last12Start : new Date(new Date(new Date(new Date().setMonth(new Date().getMonth() - 12)).setDate(1)).toDateString()),
			lastEnd : new Date(new Date(new Date().setDate(0)).toDateString()),
			yearStart : new Date(new Date(new Date().getFullYear() - 1, 0, 1).toDateString()),
			yearEnd  :  new Date(new Date(new Date().getFullYear() - 1, 11, 31).toDateString())
		};
	},
	methods: {
		reset() {
			this.searchKey = this.searchKey + 1;
		},
		pagejump(id) {
			this.filter.page = id;
			this.search();
		},
		next() {
			if(this.isDisabled('next') != 'disabled') {
				this.filter.page = parseInt(this.filter.page) + 1;
				this.search();
			}
		},
		previous() {
			if(this.isDisabled('previous') != 'disabled') {
				this.filter.page = parseInt(this.filter.page) - 1;
				this.search();
			}
		},
		Clear() {
			this.$store.state.loader = true;
			this.filter.date_range = "";
			this.filter.source = ['All'];
			this.filter.page = 1;
			if(!document.getElementById("Select_Billing_Typeselectall").checked) {
				document.getElementById('Select_Billing_Typeselectall').click();
			}
			this.filter.date_range = this.getFormattedDate(new Date(new Date(new Date().setDate(new Date().getDate()-30)).toDateString())) + " - " + this.getFormattedDate(new Date(new Date().toDateString()));
			let params = "date_range=" + this.filter.date_range;
			axios.get("/reports/payment_report?" + params).then((response) => {
				this.list = response.data.data;
				this.totalEnteries = response.data.data.total;
				this.calculate_chart_data(response);
				this.$store.state.loader = false;
			});
		},
		resendVerificationLink(id) {
			this.$store.state.loader = true;
			axios
				.post("/payment-gateway-migration/resend-email", { patient_id: id })
				.then((res) => {
					(this.$store.state.loader = false),
						this.moshaToast("Email Sent successfully.", "success");
				});
		},
		getdate() {
			this.filter.date_range = $("#daterangepicker").val();
		},
		search() {
			this.$store.state.loader = true;
			let params = "page=" + this.filter.page;
			if (this.filter.source != "") {
				params = params + "&source=" + this.filter.source;
			}
			if (this.filter.date_range != "") {
				params = params + "&date_range=" + this.filter.date_range;
			}
			axios
				.get("/reports/payment_report?" + params, this.filter)
				.then((response) => {
					this.list = response.data.data;
					this.list_lenght = this.list.length;
					this.steps = parseInt(this.list_lenght / this.step);
					this.totalEnteries = response.data.data.total;
					this.calculate_chart_data(response);
					this.$store.state.loader = false;
				});
		},
		checkall() {
			var checked = $("#checkAll").is(":checked");
			if (checked == true) {
				$(".myCheckbox").prop("checked", true);
			} else {
				$(".myCheckbox").prop("checked", false);
			}
		},
		sendemail() {
			var patinet_id_array = Array();
			$("input:checkbox[name=cardauthorization]:checked").each(function() {
				patinet_id_array.push($(this).val());
			});
			this.patinet_id = patinet_id_array;
			this.$store.state.loader = true;
			axios
				.post("/reports/bulksendemail", { patinet_id: this.patinet_id })
				.then((res) => {
					this.moshaToast("Email Sent successfully.", "success"),
						$(".myCheckbox").prop("checked", false);
					$("#checkAll").prop("checked", false);
					this.$store.state.loader = false;
				});
		},
		selected_source(result) {
			this.source = result;
			this.filter.source = this.source.map((item) => {
				return (item.checked == true) ? item.id : null;
			});
			this.filter.source = this.filter.source.filter(n => n)
			var selected_count = this.source.map((item) => {
				return (item.checked == true) ? item.id : null;
			}).filter(n => n).length;
			var all_count = this.source.length;
			
			if(selected_count == all_count){
				this.filter.source = ['All'];
			}
		},
		calculate_chart_data(resdata) {
			this.totalCollection = 0;
			this.totalPOS = 0;
			this.totalHealthcare = 0;
			this.totalInsurance = 0;
			this.totalContract = 0;
			if(resdata.data.data['data']) {
				if(resdata.data.data['data'][0]) {
					resdata.data.data['data'][0].sum_amount.forEach((itm, indx) => {
						if(itm.pay_source=="POS") {
							this.totalPOS = parseFloat(itm.charge)
						}
						if(itm.pay_source=="Healthcare") {
							this.totalHealthcare = parseFloat(itm.charge)
						}
						if(itm.pay_source=="Insurance") {
							this.totalInsurance = parseFloat(itm.charge)
						}
						if(itm.pay_source=="Contract Auto Pay") {
							this.totalContract = parseFloat(itm.charge)
						}
						this.totalCollection = parseFloat(this.totalCollection) + parseFloat(itm.charge);
					});
					if(this.totalPOS>0) {
						this.totalPOS = parseFloat(((100*this.totalPOS)/this.totalCollection).toFixed(2));
					}
					if(this.totalHealthcare>0) {
						this.totalHealthcare = parseFloat(((100*this.totalHealthcare)/this.totalCollection).toFixed(2));
					}
					if(this.totalInsurance>0) {
						this.totalInsurance = parseFloat(((100*this.totalInsurance)/this.totalCollection).toFixed(2));
					}
					if(this.totalContract>0) {
						this.totalContract = parseFloat(((100*this.totalContract)/this.totalCollection).toFixed(2));
					}
				}
			}
			this.colChartKey++;
		},
		getFormattedDate(date) {
			var year = date.getFullYear();
			var month = (1 + date.getMonth()).toString();
			month = month.length > 1 ? month : '0' + month;
			var day = date.getDate().toString();
			day = day.length > 1 ? day : '0' + day;
			return month + '-' + day + '-' + year;
		},
		isDisabled(type) {
			if (type === "previous") {
				if (this.filter.page <= 1) {
					this.isPrevDisable = true;
					return "disabled";
				}
			}
			if (type === "next") {
				if (this.filter.page >= this.list.last_page) {
					this.isNextDisable = true;
					return "disabled";
				}
			}
		},
		calculatePageTotal(data) {
			var sum = 0;
    		for(let value in data){
				sum = parseInt(sum) + parseInt(data[value].charge);
			}
			return sum;
		}
	},
	created() {
		this.$store.state.loader = true;
		this.filter.date_range = this.getFormattedDate(new Date(new Date(new Date().setDate(new Date().getDate()-30)).toDateString())) + " - " + this.getFormattedDate(new Date(new Date().toDateString()));
		let params = "date_range=" + this.filter.date_range;
		axios.get("/reports/payment_report?" + params).then((response) => {
			this.list = response.data.data;
			this.list_lenght = this.list.length;
			this.steps = parseInt(this.list_lenght / this.step);
			this.totalEnteries = response.data.data.total;
			this.calculate_chart_data(response);
			if(!document.getElementById("Select_Billing_Typeselectall").checked) {
				document.getElementById('Select_Billing_Typeselectall').click();
			}
			this.$store.state.loader = false;
		});
	},
	mounted() {
		var $this = this;
		document.body.addEventListener("click", function (evt) {
			if($(evt.target).closest('.selectCheckBox').length){
			}else{
				$this.displayDiv=false;
				$this.select_box_key = $this.select_box_key+1;
			}
		});
	}
};
</script>
