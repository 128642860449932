<template>    
    <div class="selectcontrols">
        <Autocomplete :max="30" @input="getlist" @focus="getlist"  ref="autocomplete" :results="cpt" @onSelect="resultsvalue" class="form-control ms-0 autosearch-list" placeholder="Custom Procedure Code" :use-html-for-results ="true"></Autocomplete>
    </div>
</template>
<script>
import Autocomplete from 'vue3-autocomplete'
import axios from "@/scripts/axios.js";

export default {
    data () {
        return {
            cpt: [],
        }
    },
    props: {
        modelValue:{},
        isSelected: {
            type: Boolean,
            default: true
        },
        appointment_profile_id: {
            type: Number,
            default: 0
        },
    },
    mounted () {
        this.$refs.autocomplete.searchText = this.modelValue;
    },
    methods: {
        getlist (obj) {
            if(obj.length==undefined || obj.length==0) {
                axios.get("/json_autocomplete/custom-procedure-code", {
                    params: {term: '', appointment_profile_id : this.appointment_profile_id}
                }).then(response => { 
                    //this.cpt = response.data.data 
                    this.cpt = response.data.data.map((snomed) => {
                      return { id: snomed.id, name: "<b>"+snomed.code+"</b><br>"+snomed.description,code:snomed.code,description:snomed.description,price:snomed.price }
                    });
                    this.$refs.autocomplete.$el.classList.remove('selectloader');
                });
            }
            if(obj.length > 1){
               this.$refs.autocomplete.$el.classList.add('selectloader');
                axios.get("/json_autocomplete/custom-procedure-code", {
                    params: {term: obj, appointment_profile_id : this.appointment_profile_id}
                }).then(response => { 
                    //this.cpt = response.data.data 
                    this.cpt = response.data.data.map((snomed) => {
                      return { id: snomed.id, name: "<b>"+snomed.code+"</b><br>"+snomed.description,code:snomed.code,description:snomed.description,price:snomed.price }
                    });
                    this.$refs.autocomplete.$el.classList.remove('selectloader');
                });
            }            
            if(obj.length == 0){
                this.cpt = {};
            }
        },
        resultsvalue(obj){
            this.$emit('clicked', obj)
            this.$emit('update:modelValue', obj.code)
            if(this.isSelected){
                this.$refs.autocomplete.searchText = obj.code;
            }else{
                this.$refs.autocomplete.searchText = '';
            }
            this.focusout();
        },
        focusout(){
            const btn = this.$refs.autocomplete;
            btn.$el.querySelector('.vue3-autocomplete-container > .autosearch-list').blur();
        }
    },
    components: { Autocomplete },
}
</script>