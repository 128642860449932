<template>
  <div class="content-wrapper">
    <div class="container-full">
      <div class="content-header">
        <div class="row justify-content-between">
          <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xs-6">
            <h1 class="heading1 my-5">Archived Corporate</h1>
          </div>
          <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xs-6 text-sm-end">
            <router-link to="/corporate/list" class="waves-effect waves-light btn btn-info-light"><i class="fa fa-arrow-left fs-16"></i> Back</router-link>
          </div>
        </div>
      </div>

      <section class="content">
        <div class="box">
          <div class="box-body">
            <div class="table-responsive">
              <data-table dataType="archivedcorporate">
                <template v-slot="slotProps">
                  <!-- slot for table data -->
                  <td>{{ slotProps.item.corporate_name }}</td>
                  <td>
                    {{ `${slotProps.item.team_leader}` }}
                  </td>
                  <td>{{ slotProps.item.main_contact_name }}</td>
                  <td>{{ slotProps.item.main_contact_phone }}</td>
                  <td>
                    <button type="button"  data-on="Ready" data-off="Not Ready" @click="onToggleChange(slotProps.item.id,$event)" :id="['status_' + slotProps.item.id]" class="btn btn-toggle rounded30-im"  v-bind:class="[slotProps.item.deleted_at == NULL ? 'active' : '']" data-bs-toggle="button" aria-pressed="true" autocomplete="off">
                      <div class="handle rounded30-im"></div>
                    </button>
                  </td>
                </template>
              </data-table>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import axios from "@/scripts/axios.js";
import dataTable from "@/components/base/DataTable.vue";
import submitMixins from "@/scripts/mixins/submitMixins.js";
import permissionMixins from "@/scripts/mixins/permission.js";

export default {
  name: "Home",
  components: { dataTable },
  mixins: [submitMixins,permissionMixins],
  methods: {
    onToggleChange(id,event) {
      var class_list = document.getElementById("status_"+id).classList;
      var active_deactive = '';
      if(class_list.contains('active')) {
        active_deactive = 'A';
      } else {
        active_deactive = 'D';
        // console.log(class_list.contains('active'));
      }
      axios
        .post("corporate/reset",{"status":active_deactive,"id":id})
        .then((response) => {
          this.moshaToast('User has been restored successfully.','success');
          setTimeout(function(){ location.reload(); }, 2000);
        });

       

    },
  }
};
</script>