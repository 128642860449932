<template>
	<div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-10">
		<div class="patiboxdetail">
			<div class="">
				<div class="pageheading">
					<h2 class="heading2">Staff's Access Permission</h2>
				</div>
			</div>

			<div class="form-check" v-for="(obj,index) in Editpermission" :key="index">
				<div
					class="panel-group panel-group-simple panel-group-continuous mb-0"
					id="category-1"
					aria-multiselectable="true"
					role="tablist">
					<div class="panel pagestabscoll">
						<div class="panel-heading" v-bind:id="'permission-detail-' + index.replace(/\s+/, '-')" role="tab">
							<div class="pageheading">
							<a
								class="panel-title d-block mb-0"
								v-bind:aria-controls="'#permission-detail-' + index.replace(/\s+/, '-')"
								aria-expanded="true"
								data-bs-toggle="collapse"
								v-bind:href="'#permission-detail-' + index.replace(/\s+/, '-')"
								data-parent="#accordion1"
								>
								{{index}}
							</a>
							</div>
						</div>

						<div
							class="panel-collapse collapse show"
							v-bind:id="'permission-detail-' + index.replace(/\s+/, '-')"
							v-bind:aria-labelledby="'permission-detail-' + index.replace(/\s+/, '-')"
							role="tabpanel"
							data-bs-parent="#category-1"
							>
							<slot v-for="(item,index1) in obj" :key="index1">
								<div v-if="permissionsList.includes(item.id)">
									<input checked  type="checkbox" v-bind:id="'role_checkbox_' + item.id" class="filled-in chk-col-info" :value="item.id">   
									<label v-bind:for="'role_checkbox_' + item.id" class="text-start">{{ item.name }}</label>
									<a href="javascript:void(0)" class="ms-5 black-color">
									<Popper arrow :content="item.description" hover="true" class="helptips">
										<i class="fa fa-info-circle fs-12"></i>
									</Popper>
									</a>
								</div>
								<div v-else>
									<input type="checkbox" v-bind:id="'role_checkbox_' + item.id" class="filled-in chk-col-info" :value="item.id">   
									<label v-bind:for="'role_checkbox_' + item.id" class="text-start">{{ item.name }} </label>
									<a href="javascript:void(0)" class="ms-5 black-color">
									<Popper arrow :content="item.description" hover="true" class="helptips">
										<i class="fa fa-info-circle fs-12"></i>
									</Popper>
									</a>
								</div>
							</slot>
						</div>

					</div>
				</div>
			</div>
			<div class="row">
			    <div class="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-9 text-center">
					<button
						class="btn-info waves-effect waves-light btn me-1"
						@click="savePermission()"
						>Save</button>

							<router-link
								v-if="permissioncheck(this.$store.state.permission.Manage_Accounts)"
								:to="{ name: 'StaffList' }"
								class="waves-effect waves-light btn me-1 mb-1 btn-danger"
								>Cancel</router-link
							>
							<router-link
								v-else
								:to="{ name: 'ProfileSetting' }"
								class="waves-effect waves-light btn me-1 mb-1 btn-danger"
								>Cancel</router-link
							>
				</div>
			</div>
			
		</div>
	</div>
</template>
<script>
	import axios from "@/scripts/axios.js";
	import submitMixins from "@/scripts/mixins/submitMixins.js";
	import useVuelidate from "@vuelidate/core";
	import CheckBox from "@/components/base/formFields/checkBox.vue";
	import FormBtn from "@/components/base/formFields/formBtn.vue";
	import Popper from "vue3-popper";
	import InputField from "@/components/base/formFields/InputField.vue";
	import Select2 from "@/components/base/formFields/select2.vue";
	import TextArea from "@/components/base/formFields/textArea.vue";
	import permissionMixins from "@/scripts/mixins/permission.js";

	import $ from "jquery";
	import {
	helpers,
	required,
	email,
	minLength,
	maxLength,
	sameAs,
	} from "@vuelidate/validators";

	export default {
		name: "Home",
		mixins: [submitMixins, permissionMixins],
		setup: () => {
			return { v$: useVuelidate() };
		},
		components: {
			CheckBox,
			FormBtn,
			Popper,
			InputField,
			Select2,
			TextArea
		},
		watch: {

		},
		computed: {
			form() {
				return this.$store.state.provider.form;
			},
			vuelidateExternalResults() {
				return this.$store.state.provider.vuelidateExternalResults;
			},
			validationRules() {
				return this.$store.state.provider.validationRules;
			},
		},
		data() {
			return {
				role_id:"",
				Editpermission: [],
				permissionsList: [],
				provider_id: this.$route.params.id,
				fields: {
					permissions: []
				},
				errors: {},
			}
		},
		mounted(){
			this.getProviderDetail();
			// this.getUserRolePermission();
		},
		methods:{
			 getProviderDetail(){
				this.$store.state.loader = true;
				axios
				.get("/staff/list?staff_id="+this.provider_id, { staff_id: this.provider_id })
				.then((response) => {
					var detail = response.data.data[0];
					this.permissionsList = detail.permission_ids;
					this.role_id = detail.role_id;
					this.getUserRolePermission(detail.role_id);
				});
			},
			 getUserRolePermission(roleId) {
				axios
				.post("/permission/role-permission", { role_id: roleId })
				.then((response) => {
					this.Editpermission = response.data.data;
					this.$store.state.loader = false;
				});
			},
			savePermission: function (event) {
				console.log("staff");
				this.$store.state.loader = true;
				const url = '/staff/'+this.$route.params.id+'/store-permission';
				var permissionsedit = Array();
				$("input:checkbox:checked").each(function() {
					permissionsedit.push($(this).val());
				});
				this.form.permissions = permissionsedit;
				console.log(this.form);
				axios.post(url,{"id":this.$route.params.id,"form":this.form})
				.then(response => { 
					this.$store.state.loader = false;
					if(response.status==200){
						this.moshaToast("Records Updated successfully",'success');
						this.form = [];
					}
			})
			},
		},

	}
</script>