<template>
    <div class="box">
        <div class="box-body">
            <iframe :src="patient_cds_url" frameborder="0" allowfullscreen  v-if="patient_cds_url"></iframe>
        </div>
    </div>
</template>
<script>
    export default {
      components: {},
      data() {
        return {
            patient_cds_url : `${process.env.VUE_APP_API_CDS_URL}/cds/test/default.aspx?patientid=${this.$store.state.patientDetail.patient_id}`,
        };
      },
      watch: {},
      created() {
      },
      methods: {},
    };
</script>
<style type="text/css" scoped>
    iframe {height:calc(100vh - 4px);width:100%;box-sizing: border-box;}
</style>