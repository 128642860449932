import {
  helpers,
  required,
  email,
  minLength,
  maxLength,
  sameAs,
  numeric,
} from "@vuelidate/validators";

const staff = {
  namespaced: true,

  state: {
    tableData: {
      header: [
        "Username",
        "Role Name",
        "Name",
        "E-mail",
        "Primary Provider",
        "Cell Phone",
        "Home Phone",
        "Emergency Access",
        "Actions",
      ],
    },
    form: {}, 
    defaultFormData: {
       office_access:[],
    },   
    vuelidateExternalResults: {
      form: {
        first_name:[],
        last_name :[],
        primary_provider_id:[],
        role_id:[],
        email:[],
        username:[],
        cell_phone:[],
        home_phone:[],
        office_access:[],
        password: [],
        password_confirmation: [],
        profile_avatar: [],
        digtal_signature: "",
      },
    },
    validationRules: {
      form: {
        first_name: {
          required: helpers.withMessage("First Name is required", required),
        },
        last_name: {
          required: helpers.withMessage("Last Name is required", required),
        },
        primary_provider_id: {
          required: helpers.withMessage("Primary Provider is required", required),
        },
        role_id: {
          required: helpers.withMessage("Role is required", required),
        },
        email: {
          required: helpers.withMessage("Email Address is required", required),
          email: helpers.withMessage("Email Address is not valid", email),
        },
        username: {
          required: helpers.withMessage("Username is required", required),
          min: minLength(8),
          max: maxLength(14),
        },
        cell_phone:{
          min: minLength(10),
          max: maxLength(15),
        },
        home_phone:{
          min: minLength(10),
          max: maxLength(15),
        },
        office_access:{
          required: helpers.withMessage("Office Access is required", required),
        },
        profile_avatar:{

        },
       /* password: {
          required: helpers.withMessage("Password is required", required),
          min: minLength(8),
          max: maxLength(14),
        },
        password_confirmation: {
          sameAsPassword: sameAs('password')
        },*/
      },
    },
    listUrl: "/staff/list",
    getEditUrl: "/staff/edit/:id",
    list: [],
  },
  getters: {
    getTableHeaders: (state) => state.header,
    getTableContents: (state) => state.list,
    getListURL: (state) => state.listUrl,
  },
  mutations: {},
  actions: {},
}

const staffscheduling = {
  namespaced: true,

  state: {
    office_access:null,
    form:{
      week_day: [],
      Sunday_session_one_start: '',
      Sunday_session_one_end: '',
      Sunday_session_two_start: '',
      Sunday_session_two_end: '',
      Monday_session_one_start: '',
      Monday_session_one_end: '',
      Monday_session_two_start: '',
      Monday_session_two_end: '',
      Tuesday_session_one_start: '',
      Tuesday_session_one_end: '',
      Tuesday_session_two_start: '',
      Tuesday_session_two_end: '',
      Wednesday_session_one_start: '',
      Wednesday_session_one_end: '',
      Wednesday_session_two_start: '',
      Wednesday_session_two_end: '',
      Thursday_session_one_start: '',
      Thursday_session_one_end: '',
      Thursday_session_two_start: '',
      Thursday_session_two_end: '',
      Friday_session_one_start: '',
      Friday_session_one_end: '',
      Friday_session_two_start: '',
      Friday_session_two_end: '',
      Saturday_session_one_start: '',
      Saturday_session_one_end: '',
      Saturday_session_two_start: '',
      Saturday_session_two_end: '',    
      office_id: null,   
      staff_user_id:null,         
    },
    week_days: [
      {id:"md_checkbox_1", checked: "false", daynm:"Sunday", detail : ['Sunday_session_one_start','Sunday_session_one_end','Sunday_session_two_start', 'Sunday_session_two_end']},
      {id:"md_checkbox_2", checked: "false", daynm:"Monday", detail : ['Monday_session_one_start','Monday_session_one_end','Monday_session_two_start', 'Monday_session_two_end']},
      {id:"md_checkbox_3", checked: "false", daynm:"Tuesday", detail : ['Tuesday_session_one_start','Tuesday_session_one_end','Tuesday_session_two_start', 'Tuesday_session_two_end']},
      {id:"md_checkbox_4", checked: "false", daynm:"Wednesday", detail : ['Wednesday_session_one_start','Wednesday_session_one_end','Wednesday_session_two_start', 'Wednesday_session_two_end']},
      {id:"md_checkbox_5", checked: "false", daynm:"Thursday",detail : ['Thursday_session_one_start','Thursday_session_one_end','Thursday_session_two_start', 'Thursday_session_two_end']},
      {id:"md_checkbox_6", checked: "false", daynm:"Friday", detail : ['Friday_session_one_start','Friday_session_one_end','Friday_session_two_start', 'Friday_session_two_end']},
      {id:"md_checkbox_7", checked: "false", daynm:"Saturday", detail : ['Saturday_session_one_start','Saturday_session_one_end','Saturday_session_two_start', 'Saturday_session_two_end']}
    ],
    vuelidateExternalResults: {
      form:{
        week_day: [],
        Sunday_session_one_start: [],
        Sunday_session_one_end: [],
        Sunday_session_two_start: [],
        Sunday_session_two_end: [],
        Monday_session_one_start: [],
        Monday_session_one_end: [],
        Monday_session_two_start: [],
        Monday_session_two_end: [],
        Tuesday_session_one_start: [],
        Tuesday_session_one_end: [],
        Tuesday_session_two_start: [],
        Tuesday_session_two_end: [],
        Wednesday_session_one_start: [],
        Wednesday_session_one_end: [],
        Wednesday_session_two_start: [],
        Wednesday_session_two_end: [],
        Thursday_session_one_start: [],
        Thursday_session_one_end: [],
        Thursday_session_two_start: [],
        Thursday_session_two_end: [],
        Friday_session_one_start: [],
        Friday_session_one_end: [],
        Friday_session_two_start: [],
        Friday_session_two_end: [],
        Saturday_session_one_start: [],
        Saturday_session_one_end: [],
        Saturday_session_two_start: [],
        Saturday_session_two_end: [],    
        office_id:[],   
        staff_user_id:[],         
      }
    },
    validationRules: {
      form:{
        week_day: {},
        Sunday_session_one_start: {},
        Sunday_session_one_end: {},
        Sunday_session_two_start: {},
        Sunday_session_two_end: {},
        Monday_session_one_start: {},
        Monday_session_one_end: {},
        Monday_session_two_start: {},
        Monday_session_two_end: {},
        Tuesday_session_one_start: {},
        Tuesday_session_one_end: {},
        Tuesday_session_two_start: {},
        Tuesday_session_two_end: {},
        Wednesday_session_one_start: {},
        Wednesday_session_one_end: {},
        Wednesday_session_two_start: {},
        Wednesday_session_two_end: {},
        Thursday_session_one_start: {},
        Thursday_session_one_end: {},
        Thursday_session_two_start: {},
        Thursday_session_two_end: {},
        Friday_session_one_start: {},
        Friday_session_one_end: {},
        Friday_session_two_start: {},
        Friday_session_two_end: {},
        Saturday_session_one_start: {},
        Saturday_session_one_end: {},
        Saturday_session_two_start: {},
        Saturday_session_two_end: {},    
        office_id:{},   
        staff_user_id:{},         
      }
    },
  },
  getters: {},
  mutations: {},
  actions: {},
};

const StaffArchivedList = {
  namespaced: true,

  state: {
    tableData: {
      header: [
        "Username",
        "Role Name",
        "Name",
        "E-mail",
        "Primary Provider",
        "Cell Phone",
        "Home Phone",
        "Emergency Access",
        "Action",
      ],
    },
    form: {},    
    listUrl: "/staff/archived",
    list: [],
  },
  getters: {
    getTableHeaders: (state) => state.header,
    getTableContents: (state) => state.list,
    getListURL: (state) => state.listUrl,
  },
  mutations: {},
  actions: {},
}

const StaffRXAccountSetup = {
  namespaced: true,

  state: {
    form: {},  
    defaultFormData: {
      drfirst_username:'',
      drfirst_external_id :'',
      staff_id: '',
      is_lifestyle_specific:'0',
      is_drfirst_enable_mode: 'n',
    }, 
    vuelidateExternalResults: {
      form: {
        drfirst_username:[],
        drfirst_external_id :[],
      },
    },
    validationRules: {
      form: {
        drfirst_username: {
          required: helpers.withMessage("waystar username is required", required),
        },
        drfirst_external_id: {
          required: helpers.withMessage("waystar external id is required", required),
        },
      },
    },
  },
  mutations: {},
  actions: {},
}

// export default staff;
export { staff, staffscheduling, StaffArchivedList, StaffRXAccountSetup};
