import {
  helpers,
  required,
} from "@vuelidate/validators";

const ProspectStatus = {
  namespaced: true,
  
  state: {
    listUrl: "/crm/status/list",
    getEditUrl: "/crm/status/edit/:id",
    tableData: {
      header: [
        "#",
        "Name",
        "Type",
        "Sort Order",
        "Show On Calendar?",
        "Is Expanded ?",
        "Status",
        "Action",
      ],
    },
    validationRules: {
        form: {
            status_label: {
              required: helpers.withMessage('Status name is required.', required), 
            },
            pipeline_id:{
              required: helpers.withMessage('Pipeline is required.', required), 
            }
       },
    },
    vuelidateExternalResults: {
        form : {
          status_label : '',
          pipeline_id:'',
        }
    },
    form : {
      status_label : '',
      id : '',
      pipeline_id:'',
    },
    defaultFormData : {
      status_label : '',
      id : '',
    },

    list: [],
  },
  getters: {
    getTableHeaders: (state) => state.header,

    getTableContents: (state) => state.list,
    getListURL: (state) => state.listUrl,
  },
  mutations: {},
  actions: {},
}

export default ProspectStatus;