<template>
    <div class="sticky-scroll-box">
        <div class="accorsidebar">
            <div class="accordion-item">
                <p class="accordion-header display-vertical" id="addpatienttab">
                    <span class="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapsedemogr" aria-expanded="false" aria-controls="collapsedemogr">Offices</span>
                </p>
                <div
                  id="collapsedemogr"
                  class="accordion-collapse collapse"
                  aria-labelledby="addpatienttab"
                  data-bs-parent="#accordionExample"
                >
                    <div class="side-navigation">
                        <ul class="side-bar-menu side-bar-tree patientTabs" data-widget="tree">
                        <li>
                            <a href="javascript:void(0)" ref="OfficeAddLink" id="OfficeTab" class="active" @click.prevent="changeComp('AddOffice')">Offices</a>
                        </li>
                        <li>
                            <a href="javascript:void(0)" ref="OfficeBillingLink" v-bind:class="(routeNm=='OfficeAdd') ? 'linkDisabled' : ''" id="OfficeBillingTab" class="active" @click.prevent="changeComp('OfficeBilling')">Office Billing</a>
                        </li>
                        <li>
                            <a href="javascript:void(0)" ref="RoomsAddLink" id="RoomTab" v-bind:class="(routeNm=='OfficeAdd') ? 'linkDisabled' : ''" @click.prevent="changeComp('AddRooms')">Rooms</a>
                        </li>
                        <li>
                            <a href="javascript:void(0)" ref="ResourcesAddLink" id="ResourceTab" v-bind:class="(routeNm=='OfficeAdd') ? 'linkDisabled' : ''" @click.prevent="changeComp('AddResources')">Resources</a>
                        </li>
                        <li>
                            <a href="javascript:void(0)" ref="OfficeScheduleLink" id="ScheduleTab" v-bind:class="(routeNm=='OfficeAdd') ? 'linkDisabled' : ''" @click.prevent="changeComp('OfficeSchedule')">Office Scheduling</a>
                        </li>
                        <li>
                            <a href="javascript:void(0)" ref="LabSettingLink" id="LabTab" v-bind:class="(routeNm=='OfficeAdd') ? 'linkDisabled' : ''" @click.prevent="changeComp('LabSetting')">Lab Setting</a>
                        </li>
                        <li>
                            <a href="javascript:void(0)" ref="ProvidersLink" id="ProvidersTab" v-bind:class="(routeNm=='OfficeAdd') ? 'linkDisabled' : ''" @click.prevent="changeComp('OfficeProviderSchedule')">Providers</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        </div>
    </div>
</template>

<script>
import $ from 'jquery';
export default {
    data() {
        return {
            routeNm: this.$route.name
        };
    },
    computed: {
        
    },

    watch: {
        "$route.name": function(newValue) {
          this.linkActive();
        },
    },
    mounted() {
        this.linkActive();
    },
    methods: {    
        changeComp(name) {
            if (name === "OfficeSchedule" && this.$route.params.id) {
                this.$router.push(
                  `/office/${this.$route.params.id}/schedule`
                );
            } else if (name === "OfficeBilling" && this.$route.params.id) {
                this.$router.push(
                  `/office/${this.$route.params.id}/billing`
                );
            }
            else if (name === "AddRooms" && this.$route.params.id) {
                this.$router.push(
                  `/office/${this.$route.params.id}/room`
                );
            } else if (name === "AddResources" && this.$route.params.id) {
                this.$router.push(
                  `/office/${this.$route.params.id}/rooms/resources`
                );
            } else if (name === "AddOffice" && this.$route.params.id) {
                this.$router.push(
                  `/office/edit/${this.$route.params.id}`
                );
            }
             else if (name === "LabSetting" && this.$route.params.id) {
                this.$router.push(
                  `/office/${this.$route.params.id}/labs`
                );
            }
            else if (name === "OfficeProviderSchedule" && this.$route.params.id) {
                this.$router.push(
                  `/office/${this.$route.params.id}/provider`
                );
            }

        },

        linkActive() {
            var name = this.$route.name;
            name = (name == 'OfficeEdit') ? 'OfficeAdd' : name;
            $(".patientTabs li").children("a").removeClass("active");
            var $tabName = name+"Link", $link;
            if($.type(this.$refs[$tabName]) !== undefined && $.type(this.$refs[$tabName]) !== "undefined")
            {
                this.$refs[$tabName].classList.add("active");
            }
        },
    },
};
</script>