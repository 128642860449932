import {
    helpers,
    required,    
  } from "@vuelidate/validators";
  const radiologyorder = {
    namespaced: true,  
    state: {
        listUrl: `patient/:patient_unique_id/radiology-order/list`,
        tableData: {
            header: [
                "Ordering Dr.",
                "Image Order",
                "CPT Code",
                "Date",
                "Status",
                "File",
                "Comments",
                "Actions",
            ],
        },
        defaultFormData: {
            id: '',    
            result_document_id: '',    
            cpt_code: '',    
            description: '',    
            doctor_comments: '',
            order_status: '',
        },     
        form: {},        
        vuelidateExternalResults: {
            form: {
                cpt_code: '',  
                order_status:'',
                description:'',
            },            
        },
        validationRules: {            
            form: {
                cpt_code: {
                    required: helpers.withMessage("CPT Code is required", required),
                },
                description:{
                    required: helpers.withMessage("Description is required", required),
                },
                order_status: {
                    required: helpers.withMessage("Order Status is required", required),
                }   
            },
        },
        list: [],
    },
    getters: {
        getTableHeaders: (state) => state.header,
        getTableContents: (state) => state.list,
        getListURL: (state) => state.listUrl,
    },
    mutations: {},
    actions: {},
  };
  
  export default radiologyorder;
  