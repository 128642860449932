<template >
<div class="card-template">
    <div class="e-card-header">
      <div class="e-card-header-caption">
        <div class="e-card-header-title e-tooltip-text white-space-normal">{{ data.patient_name }}
          <span class="badge badge-primary ms-1 float-end p-1 l-h-n" :title="data.last_modified_since+' Days'" v-if="data.last_modified_since > 0">{{ data.last_modified_since }}</span>
        </div>
        <div class="e-card-header-title e-tooltip-text">{{ data.cell_phone }}</div>
      </div>
    </div>
    <div class="e-card-content e-tooltip-text">
      <div class="e-text" >{{ getShortString(data.notes) }}</div>
    </div>

    <div class="e-card-custom-footer " >
        <Popper v-if="data.is_cc_card_avail == 1" arrow content="Credit Card Authorized" :hover="true" class="helptips cursor-pointer ms-2 mt-1">
          <img src="images/patient_icons/pay-card-green.svg" alt="Credit Card Authorized" class="img-fluid" style="width: 20px;"/>
        </Popper>
        <Popper v-if="data.is_cc_card_avail == 0" arrow content="Creditcard Not Authorized" :hover="true" class="helptips cursor-pointer ms-2 mt-1">
          <img  src="images/patient_icons/pay-card-red.svg" alt="Credit Card Authorized" class="img-fluid" style="width: 20px;cursor: pointer;" />
        </Popper>

        <Popper v-if="data.is_ins_details_avail == 1" arrow content="Insurance Document available" :hover="true" class="helptips cursor-pointer ms-2 mt-1">
          <img  src="images/patient_icons/insurance-card-green.svg" alt="Insurance Document available" class="img-fluid" style="width: 20px;" />
        </Popper>
        <Popper v-if="data.is_ins_details_avail == 0" arrow content="Insurance Document not available" :hover="true" class="helptips cursor-pointer ms-2 mt-1">
          <img src="images/patient_icons/insurance-card-red.svg" alt="Insurance Document not available" class="img-fluid" style="width: 20px;" />
        </Popper>

        <Popper v-if="data.id_proof_avail == 1" arrow content="ID proof available" :hover="true" class="helptips cursor-pointer ms-2 mt-1">
          <img src="images/patient_icons/driving-licence-green.svg" alt="ID proof available" class="img-fluid" style="width: 20px;"  />
        </Popper>
        <Popper v-if="data.id_proof_avail == 0" arrow content="ID proof not available" :hover="true" class="helptips cursor-pointer ms-2 mt-1">
          <img src="images/patient_icons/driving-licence-red.svg" alt="ID proof not available" class="img-fluid" style="width: 20px;" />
        </Popper>
        <div class='e-card-avatar' v-if="data.wellness_coordinator_name" :title="data.wellness_coordinator_name">{{ this.getString(data.wellness_coordinator_name) }}</div>
    </div>
  </div>
</template>
<script>
  import Popper from "vue3-popper";
   export default {
      components: { Popper },
      data () {
        return {
        }
      },
      methods: {
        getString: function(name) {
          return name.match(/\b(\w)/g).join("").toUpperCase();
        },
        getShortString : function (note) {
            if (note) {
              return note.length > 50 ? note.substring(0,50)+"..." : note;
            } else {
              return "";
            }
        },
        getCardFooter: function(data) {
          let tagDiv = "";
         /* let tags = data.Tags.split(",");
          for (let tag of tags) {
            tagDiv += "<div class='e-card-tag-field e-tooltip-text'>" + tag + "</div>";
          }*/
          tagDiv += '<div class="inline-block" data-v-48ac0994=""><img src="images/patient_icons/contract.svg" alt="Contract" class="img-fluid mt-1" data-v-48ac0994-s="" style="width: 35px;"></div>';
          if (data.marketing_consultant_name) {
            tagDiv += "<div class='e-card-tag-field e-tooltip-text '>" + this.getString(data.marketing_consultant_name) + "</div>";
          }
          if (data.wellness_coordinator_name) {
            tagDiv += " <div class='e-card-avatar'>" + this.getString(data.wellness_coordinator_name) + "</div>";
          }
          return tagDiv;
        }
      }
   }
</script>