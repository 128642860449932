<template>
  <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-2">
    <div class="sticky-scroll-box">
      <div class="accorsidebar">
        <div class="accordion-item">
          <p class="accordion-header display-vertical" id="addpatienttab">
            <span
              class="accordion-button collapsed"
              data-bs-toggle="collapse"
              data-bs-target="#collapsedemogr"
              aria-expanded="false"
              aria-controls="collapsedemogr"
              >Information</span
            >
          </p>
          <div
            id="collapsedemogr"
            class="accordion-collapse collapse"
            aria-labelledby="addpatienttab"
            data-bs-parent="#accordionExample"
          >
            <div class="side-navigation">
              <ul
                class="side-bar-menu side-bar-tree patientTabs"
                data-widget="tree"
              >
                <li>
                  <a
                    class="active"
                    ref="PatientInformationLink"
                    @click.prevent="changeComp('PatientInformation')"
                    >Primary Information</a
                  >
                </li>
                <li :class="newProvider">
                  <a
                    ref="DemographicsLink"
                    @click.prevent="changeComp('Demographics')"
                    >Demographics</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    newProvider() {
      return this.$route.name === "PatientAdd" ? "linkDisabled" : "";
    },
  },
  watch: {
    "$route.name": function(newValue) {
      this.linkActive();
    },
  },
  mounted() {
    this.linkActive();
  },
  methods: {
    changeComp(name) {
      if (name === "Demographics" && this.$route.params.id) {
        this.$router.push(
          `/patient/edit/demographics/${this.$route.params.id}`
        );
      } else if (name === "PatientInformation" && this.$route.params.id) {
        this.$router.push(`/patient/edit/${this.$route.params.id}`);
      }
    },
    linkActive() {
      if (
        this.$route.name === "PatientAdd" ||
        this.$route.name === "PatientEdit"
      ) {
        this.$refs.PatientInformationLink.classList.add("active");
        this.$refs.DemographicsLink.classList.remove("active");
      } else if (
        this.$route.name === "PatientEditDemographics" ||
        this.$route.name === "PatientAddDemographics"
      ) {
        this.$refs.DemographicsLink.classList.add("active");
        this.$refs.PatientInformationLink.classList.remove("active");
      }
    },
  },
};
</script>
