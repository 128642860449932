import axios from "@/scripts/axios.js";
import router from '@/router';



export default {
  namespaced: true,
  state: {
    notificationData: null,
    notificationCounter: 0,
    userData: null,
    recentPatientList: [],
    providerList: null,
    activeProvider: null,
    userClinicList: null,
    is_lifestyle_specific:0,
    is_super_admin: 0,
    is_supervisor: 0,
    is_drfirst_enable: 'n',
    role_id: '',
    permission_ids: null,
    email_reply_not_url_list: [],
  },

  getters: {
    user: state => state.userData,
    provider_list: state => state.providerList,
    active_provider: state => state.activeProvider,
    user_clinic_list: state => state.userClinicList,
  },

  mutations: {
    SET_USER_PREFERENCE(state, user_preference){
      state.user_preference = user_preference;
    },
    SET_USER_DATA(state, user) {

      state.permission_ids = (user!=null) ? user.permission_ids : state.permission_ids;
      state.is_super_admin = (user!=null) ? user.is_super_admin : state.is_super_admin;
      state.is_supervisor = (user!=null) ? user.is_supervisor : state.is_supervisor;
      state.is_drfirst_enable = (user!=null) ? user.is_drfirst_enable : state.is_drfirst_enable;
      state.is_rx_setup = (user!=null) ? user.is_rx_setup : state.is_rx_setup;
      state.role_id = (user!=null) ? user.role_id : '';
      state.is_lifestyle_specific_enable= (user!=null) ? user.is_lifestyle_specific_enable:state.is_lifestyle_specific_enable;
      if(user){
        localStorage.setItem("role_id", user.role_id);
      }
      state.userData = user;
    },
    SET_PROVIDER_LIST(state, provider_list) {
        state.providerList = provider_list;
    },
    GET_ACTIVE_PROVIDER(state) {
      const activePro = localStorage.getItem('activeProvider');
      if(activePro == 'all'){
        if(state.userData.user_type == 'provider'){
          const activePros = state.providerList.filter(provider => { 
            return provider.id == state.userData.user_id 
          })
          state.activeProvider = activePros ? activePros[0] : {} ;
        }else{
          const activePros = state.providerList.filter(provider => { 
            return provider.id == state.userData.primary_provider_id
          })
          state.activeProvider = activePros ? activePros[0] : {} ;
        }       
      }else{
        const activePros = state.providerList.filter(provider => { 
          return provider.id == activePro 
        })
        state.activeProvider = activePros ? activePros[0] : {} ;
      }
    },
    SET_USER_CLINIC_LIST(state, user_clinic_list) {
      state.userClinicList = user_clinic_list ;
    },
  },
  actions: {
    getUserData({ commit }) {
      axios.get("user-info").then(response => {
            commit("SET_USER_DATA", response.data.data);
        }).catch(() => {
      localStorage.removeItem("authToken");
        });
    },
    getProviderList({ commit }) {
      axios.post("get-user-provider").then(response => {
            commit("SET_PROVIDER_LIST", response.data.data);
            commit("GET_ACTIVE_PROVIDER");
        }).catch((e) => {
             console.log("provider list error");
        });
    },
    setActiveProvider({ commit }, provider) {
      if(provider=='all'){
        localStorage.setItem("activeProvider", 'all');
      }else{
        localStorage.setItem("activeProvider", provider.id);
      }
      commit("GET_ACTIVE_PROVIDER");
      setTimeout(function() {
        location.reload(true);
      }, 100);
    },
    getRecentPatient(context) {
      let show_recent_patient = localStorage.getItem('show_recently_viewed_patient');
      let myRecentPatient = localStorage.getItem('myRecentPatient');
      if (show_recent_patient && show_recent_patient == 'yes' && myRecentPatient) {
          axios.post(`patient/get-recent-patient`, { 'myRecentPatient' : myRecentPatient })
          .then((response) => {
              context.state.recentPatientList=  response.data.data;
          });
      }
    },
    sendLoginRequest({ commit, dispatch }, data) {
      commit("setErrors", {}, { root: true });
        return axios.post("auth/login", data).then(response => {
          if (response.status == 206) {
            commit('SET_USER_CLINIC_LIST',response.data.data.clinic_option);
          } else {
            if (response.data.data.user) {
                commit("SET_USER_DATA", response.data.data.user);
                localStorage.setItem("authToken", response.data.data.token);
                localStorage.setItem("user_id", response.data.data.user.id);
                localStorage.setItem("user_credential_id", response.data.data.user.user_credential_id);
                localStorage.setItem("permission", response.data.data.user.permission_ids);
                localStorage.setItem("secret", response.data.data.googleauth.secret);
                localStorage.setItem("google2fa_url", response.data.data.googleauth.google2fa_url);
                localStorage.setItem("login_security", response.data.data.googleauth.login_security);
                localStorage.setItem("google_authentication", response.data.data.user_preference.google_authentication);
                localStorage.setItem("activeProvider", response.data.data.user.active_provider);
                if(response.data.data.user_preference.default_homepage != undefined){
                  localStorage.setItem("default_homepage", response.data.data.user_preference.default_homepage);
                }
                if(response.data.data.user_preference.show_recently_viewed_patient != undefined){
                  localStorage.setItem("show_recently_viewed_patient", response.data.data.user_preference.show_recently_viewed_patient);
                }
                if(response.data.data.user_preference.calendar_view != undefined){
                    localStorage.setItem("calendar_view", response.data.data.user_preference.calendar_view);
                }
                if(response.data.data.user_preference.exam_duration != undefined){
                    localStorage.setItem("exam_duration", response.data.data.user_preference.exam_duration);
                }
                if(response.data.data.user_preference.office_access != undefined){
                    localStorage.setItem("office_access", response.data.data.user_preference.office_access);
                }
                if(response.data.data.user_preference.calendar_increament != undefined){
                    localStorage.setItem("calendar_increament", response.data.data.user_preference.calendar_increament);
                }
                setTimeout(function() {
                    location.reload(true);
                }, 100);
            }
          }
        });
    },
    sendLogoutRequest({ commit }) {
      axios.post("auth/logout").then(() => {
        commit("SET_USER_DATA", null);
        localStorage.removeItem("authToken");
        localStorage.removeItem("activeProvider");
        localStorage.removeItem("calendar_view");
        localStorage.removeItem("exam_duration");
        localStorage.removeItem("office_access");
        localStorage.removeItem("calendar_increament");
        localStorage.removeItem("default_homepage");
        setTimeout(function() {
          location.reload(true);
        }, 100);
      });
    }
  }
};
