import {
    helpers,
    required,
    email,
    minLength,
    maxLength,
    numeric,
    requiredIf,
} from "@vuelidate/validators";

const providerBilling = {
    namespaced: true,
    state: {
        provider_name:'',
        editID: "",
        defaultFormData: {
            id:'',
            billing_provider_name: '',
            billing_provider_address: '',
            billing_provider_zipcode: '',
            billing_provider_city: '',
            billing_provider_state: '',
            billing_provider_country: '',
            billing_provider_npi: '',
            facility_code_or_place_of_service: '',
            office_or_provider_id: '',
            is_billing_enable: 0,
            taxonomy_number: null,
        },     
        form: {
            id:'',
            billing_provider_name: '',
            billing_provider_address: '',
            billing_provider_zipcode: '',
            billing_provider_city: '',
            billing_provider_state: '',
            billing_provider_country: '',
            billing_provider_npi: '',
            facility_code_or_place_of_service: '',
            office_or_provider_id: '',
            federal_tax_type: '',
            taxonomy_number: null,
        },
        vuelidateExternalResults:{
            form: {
                billing_provider_npi: [],
                facility_npi: [],
                zip_code: [],
                billing_provider_zipcode: [],
                facility_code_or_place_of_service: [],
                federal_tax_type: [],
                federal_tax_EIN: [],
                federal_tax_SSN: [],
            }
        },
        validationRules: {
            form: {
                federal_tax_type: {                    
                    // required: helpers.withMessage("Please select federal tax type", required),
                },
                federal_tax_EIN:{
                    // required: requiredIf(function() {
                    //     return this.form.federal_tax_type == 'EIN' ? true : false;
                    // }),
                    min: minLength(10),
                    max: maxLength(10),
                },
                federal_tax_SSN:{
                    // required: requiredIf(function() {
                    //     return this.form.federal_tax_type == 'SSN' ? true : false;
                    // }),
                    min: minLength(10),
                    max: maxLength(10),
                },
                billing_provider_npi: {
                    min: minLength(10),
                    max: maxLength(10),
                },
                billing_provider_zipcode: {
                    numeric,
                },                
            }
        },
        list: [],
    },
    getters: {
        getTableHeaders: (state) => state.header,
        getTableContents: (state) => state.list,
        getListURL: (state) => state.listUrl,
    },
    mutations: {},
    actions: {},
};

export default providerBilling;
