<template>
  <div class="form-group row" :class="{ error: errors.length}">
    <label
      :class="labelClass"
      >{{ title }}
      <small v-show="isRequired" class="text-danger asterisksign">*</small></label
    >
    <div :class="divClass">
      <div class="controls">
        <input
          :id="title"
          type="text"
          class="form-control"
          :value="modelValue"
          :style="style"
          @input="$emit('update:modelValue', $event.target.value)"
          :placeholder="placeholder1"
          :maxlength="maxlength"
          v-if="mask==null"
          autocomplete="chrome-off"
          :readonly="readonly"
        />
        <input
          :id="title"
          type="text"
          name="text"
          class="form-control"
          :value="modelValue"
          :readonly="readonly"
          :style="style"
          @input="$emit('update:modelValue', $event.target.value)"
          :placeholder="placeholder1"
          :maxlength="maxlength"
          autocomplete="chrome-off"
          v-mask="mask"
          v-else
        />
        <span class="form-text text-muted" v-if="notes != ''"><b>{{notes}}</b></span>
      </div>

      <div class="error-msg">
        <ul class="mb-0 p-0 fs-14">
          <li class="d-block" v-for="(error, index) of errors" :key="index">
            {{ error.$message }}
          </li>
        </ul>
      </div>
    </div>
    <div  v-show="toolTip!=''" class="col-12 col-sm-8 col-md-8 offset-sm-4 offset-lg-0 col-lg-4 col-xl-5">
      <Popper arrow :content="toolTip" :hover="true" class="helptips cursor-pointer">
        <i class="fa fa-info-circle"></i>
      </Popper>
    </div>
    <div :class="noteClass" v-show="note!=''" style="{vertical-align:-webkit-baseline-middle}"> <small class="form-text text-muted">{{ note }}</small></div>
  </div>
</template>

<script>
  import Popper from "vue3-popper";
export default {
  components: {Popper },
  props: {
    title: {
      type: String,
      default: ""
    },
    modelValue: {
      type: String,
      default: ""
    },
    errors: {
      type: Array,
      default: []
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    style: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ""
    },
    noteClass:{
      type: String,
      default: "col-12 col-sm-8 col-md-8 offset-sm-4 offset-lg-0 col-lg-4 col-xl-5"
    },
    note:{
      type: String,
      default: '',
    },
    labelClass:{
      type: String,
      default: "col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end"
    },
    divClass:{
      type: String,
      default: "col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3"
    },
    notes: {
      type: String,
      default: ""
    },
    toolTip: {
      type: String,
      default: ""
    },
    mask:{
      type: String,
      default: null
    },
    maxlength:{
      type: String,
      default: null
    }
  },
  computed: {
    placeholder1() {
      return this.placeholder ? this.placeholder : this.title;
    },    
  },
  data() {
    return {
        
    };
  },
  mounted(){
    this.id =  this.title.replace(" ", "_");
  }
};
</script>