<template>
  <div class="content-wrapper">
    <div class="container-full">
      <div class="content-header">
        <div class="row justify-content-between align-items-center">
          <div class="col-12 col-sm-5 col-md-5 col-lg-6">
            <h1 class="heading2 my-10">Visit Profiles <a href="#" data-bs-toggle="modal" data-bs-target="#profiledocument" title="Reference of Knowledge Base" v-on:click.prevent="reset()"> <i class="fa fa-question-circle fs-20"></i></a></h1>
          </div>
          <div class="col-12 col-sm-7 col-md-7 col-lg-6 text-sm-end">
            <router-link
              to="/visit-profile/archived"
              class="waves-effect waves-light btn btn-info mb-1"
              >Show Archived</router-link>
            <router-link
              to="/visit-profile/add"
              class="waves-effect waves-light btn btn-info ms-1 mb-1"
              ><i class="fa fa-plus fs-14"></i> Visit Profile</router-link>
          </div>
        </div>
      </div>
      <section class="content">
        <div class="box">
          <div class="box-body">
            <div class="table-responsive">
              <center>
                <span class="isvalid-feedback" style="display:block;">{{
                  success
                }}</span>
              </center>
              <data-table-server dataType="AppointmentProfilesList">
                <template v-slot="slotProps">
                  <td>{{ slotProps.item.name }}</td>
                  <td>
                    <div
                      v-bind:style="{
                        'background-color': slotProps.item.color_code,
                      }" class="selectedcolore"
                    ></div>
                  </td>
                  <td>{{ slotProps.item.default_duration }} minutes</td>
                  <td>{{ slotProps.item.reason_for_visit }}</td>
                  <td>{{ slotProps.item.billing_profile }}</td>
                  <td>cash</td>
                  <td>{{ slotProps.item.consent_form_total }}</td>
                  <td>{{ slotProps.item.updated_ats }}</td>
                  <td>{{ slotProps.item.created_ats }}</td>
                  <td>
                    <div class="d-flex">
                      <Popper arrow content="Edit Visit Profile" :hover="true" class="helptips cursor-pointer">
                      <a
                        href="#"
                        @click="edit(slotProps.item.id)"
                        class="waves-effect waves-circle btn btn-circle btn-info btn-xs me-1"><i class="fa fa-pencil"></i></a>
                      </Popper>
                      <Popper arrow content="Delete Visit Profile" :hover="true" class="helptips cursor-pointer">
                        <a
                          href="#"
                          @click="remove(slotProps.item.id)"
                          class="waves-effect waves-circle btn btn-circle btn-danger btn-xs"
                          ><i class="fa fa-trash"></i
                        ></a>
                      </Popper>
                    </div>
                  </td>
                </template>
              </data-table-server>
            </div>
          </div>
        </div>
      </section>
      <!--document model start-->
      <div id="profiledocument" class="modal fade" tabindex="-1" role="dialog"
         aria-labelledby="myModalLabel" aria-hidden="true">
         <div class="modal-dialog modal-md">
            <div class="modal-content">
               <div class="modal-header">
                  <h3 class="modal-title fw-900 mt-0">Reference of Knowledge Base</h3>
               </div>
               <div class="modal-body overflow-auto" style="max-height: 350px;">
                  <table class="table table-bordered">
                     <thead>
                        <tr>
                           <th scope="col">No.</th>
                           <th scope="col">Knowledge Base Articles</th>
                        </tr>
                     </thead>
                     <tbody>
                        <tr>
                           <td>1</td>
                           <td> <a :href="path+'how-to-create-a-visit-profile-in-pureehr'" target="_blank">How To Create A Visit Profile In PureEHR?</a></td>
                        </tr>
                        <tr>
                           <td>2</td>
                           <td> <a :href="path+'how-to-edit-a-visit-profile-in-pureehr'" target="_blank">How To Edit A Visit Profile In PureEHR?</a></td>
                        </tr>
                        <tr>
                           <td>3</td>
                           <td> <a :href="path+'how-to-delete-a-visit-profile-in-pureehr'" target="_blank">How To Delete A Visit Profile In PureEHR?</a></td>
                        </tr>
                        <tr>
                           <td>4</td>
                           <td> <a :href="path+'how-to-view-deleted-visit-profiles-in-pureehr'" target="_blank">How To View Deleted Visit Profiles In PureEHR?</a></td>
                        </tr>
                     </tbody>
                  </table>
                  <Search :key="searchKey"></Search>
               </div>
               <div class="modal-footer">
                  <button type="button" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
               </div>
            </div>
         </div>
      </div>
	    <!--document model end-->
    </div>
  </div>
</template>

<script>
import DataTableServer from "@/components/base/DataTableServer.vue";
import axios from "@/scripts/axios.js";
import Popper from "vue3-popper";
import Search from "@/components/Search.vue";

export default {
  name: "Home",
  components: { DataTableServer, Popper, Search },
  data() {
    return {
      success: "",
      result: "",
      path: process.env.VUE_APP_KB_DOCUMENT,
      searchKey:0,
    };
  },
  mounted() {
    if (this.$route.params.message) {
      this.success = this.$route.params.message;
    }
  },
  methods: {
    reset() {
      this.searchKey = this.searchKey + 1;
    },
    remove(id) {
      this.$swal
        .fire({
          title: '<h2 class="mt-0">Are you sure?</h2>',
          text:
            "You want to delete these records? This process cannot be undone.",
          icon: "warning",
          dangerMode: true,
          showCancelButton: true,
          confirmButtonColor: "#ee3158",
          cancelButtonColor: "#bdbdbd",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed == true) {
            axios
              .post("/appointment-profile/delete", { id: id })
              .then((response) => {
                this.$swal
                  .fire(
                    "Deleted!",
                    "Your Visit Profiles has been deleted.",
                    "success"
                  )
                  .then((result) => {
                    if (result.isConfirmed == true) {
                      location.reload();
                    }
                  });
              });
          }
        });
    },
    edit(id) {
      this.$router.push({
        name: "AppointmentProfilesEdit",
        params: { id: id },
      });
    },
  },
};
</script>
