<template>
  <div class="loginback">
    <div class="container-fluid">
      <div class="row align-items-center">
        <div
          class="col-12 col-sm-9 col-md-8 col-lg-6 col-xl-5 col-xxl-4 offset-sm-1"
        >
          <div class="bg-white rounded10 p-35 pt-40 pb-40 my-20">
            <div class="text-start">
              <img
                src="/images/pureehr.svg"
                alt="PureEHR"
                title="PureEHR"
                class="authcompany_logo mb-20"
              />
              <h2 class="mb-15 fw-900">Let's Get Started</h2>
            </div>
            <form @submit.prevent="handleReset" class="loginform mt-20">
              <div
                class="bg-danger-light p-10 py-5 rounded5 fs-16 mb-15"
                role="alert"
                v-if="errorMessage"
              >
                {{ errorMessage }}
              </div>
              <div
                class="alert-success rounded5 py-5 px-10"
                role="alert"
                v-if="successMessage"
              >
                {{ successMessage }}
              </div>
              <div
                class="form-group mb-20"
                :class="{ error: v$.form.password.$errors.length }"
              >
                <label class="fs-18">Password</label>
                <input
                  type="password"
                  class="form-control bg-transparent"
                  :class="{ 'is-invalid': errors.password }"
                  name="password"
                  id="password"
                  v-model="form.password"
                  placeholder=""
                />
                <div
                  class="invalid-feedback"
                  style="display:block;"
                  v-for="(error, index) of v$.form.password.$errors"
                  :key="index"
                >
                  {{ error.$message }}
                </div>
                <div
                  class="invalid-feedback"
                  style="display:block;"
                  v-if="errors.password"
                >
                  {{ errors.password[0] }}
                </div>
              </div>
              <div
                class="form-group mb-20"
                :class="{ error: v$.form.password_confirmation.$errors.length }"
              >
                <label class="fs-18">Confirm Password</label>
                <input
                  type="password"
                  class="form-control bg-transparent"
                  :class="{ 'is-invalid': errors.password_confirmation }"
                  placeholder=""
                  v-model="form.password_confirmation"
                  id="password_confirmation"
                  v-on:keyup="passcheck"
                />
                <div
                  class="invalid-feedback"
                  style="display:block;"
                  v-for="(error, index) of v$.form.password_confirmation
                    .$errors"
                  :key="index"
                >
                  {{ error.$message }}
                </div>
                <div
                  class="invalid-feedback"
                  style="display:block;"
                  v-if="errors.password_confirmation"
                >
                  {{ errors.password_confirmation[0] }}
                </div>
              </div>
              <div class="text-center">
                <button
                  :disabled="isDisabled"
                  class="waves-effect waves-light btn btn-info d-block fs-22 w-p100 started_btn"
                >
                  SUBMIT
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "@/scripts/axios.js";
import { mapGetters, mapActions } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required, minLength, helpers } from "@vuelidate/validators";
export default {
  name: "Login",
  setup() {
    return { v$: useVuelidate() };
  },
  components: {},
  data() {
    return {
      form: {
        password: "",
        password_confirmation: "",
      },
      errorMessage: "",
      successMessage: "",
      is_loading: true,
      isDisabled: false,
    };
  },
  validations() {
    return {
      form: {
        password: {
          required: helpers.withMessage("Password is required.", required),
          min: minLength(8),
        },
        password_confirmation: {
          required: helpers.withMessage(
            "Confirm password is required.",
            required
          ),
          min: minLength(8),
        },
      },
    };
  },
  computed: {
    ...mapGetters(["errors"]),
  },
  created() {
    axios
      .post("token-timeout-check", { token: this.$route.params.token })
      .then((response) => {
        if (response.data.data.success != 1) {
          this.errorMessage = response.data.message;
          this.isDisabled = true;
        }
      })
      .catch((error) => {
        this.errorMessage = error.response.data.message;
      });
  },
  mounted() {
    this.$store.commit("setErrors", {});
    this.$store.state.headerRequired = false;
    this.$store.state.footerRequired = false;
  },
  methods: {
    handleReset: function() {
      this.v$.$validate();
      if (!this.v$.$error) {
        this.form.token = this.$route.params.token;
        console.log(this.form);
        this.errorMessage = "";
        this.successMessage = "";
        axios
          .post("reset-password", this.form)
          .then((response) => {
            this.successMessage = response.data.message;
            let vm = this;
            setTimeout(function() {
              vm.$router.push({ name: "Login" });
            }, 3000);
          })
          .catch((error) => {
            this.errorMessage = error.response.data.message;
          });
      }
    },
    passcheck() {},
  },
};
</script>
