<template>
  <div class="content-wrapper">
    <div class="container-full">
      <section class="content">
        <div class="row justify-content-sm-center">
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div class="content-header">
              <h2 class="heading2 mb-0 font-bold">{{ title }} <a href="#" data-bs-toggle="modal" data-bs-target="#formdocument" title="Reference of Knowledge Base" v-on:click.prevent="reset()"> <i class="fa fa-question-circle fs-20"></i></a></h2>
              <p class="fs-14 mb-0" v-if="title != 'New Billing Profile'">
                Warning: Any updates will not apply to existing appointments
                with this billing profile.
              </p>
            </div>
            <div class="row">
                <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-2"  v-if="this.show_lifestyle_menu">
                    <life-style-header routefrom="BillingProfileList"></life-style-header>
                </div>
                <div v-bind:class="show_lifestyle_menu ? 'col-12 col-sm-12 col-md-9 col-lg-9 col-xl-10' : 'col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12' ">
                    <form novalidate>
                      <div class="box">
                        <div class="box-body">
                          <input-field
                            title="Profile Name"
                            v-model="v$.form.name.$model"
                            :errors="v$.form.name.$errors"
                            :isRequired="true"
                            labelClass="col-12 col-sm-3 col-md-4 col-lg-3 col-xl-3 text-sm-end col-form-label"
                            divClass="col-12 col-sm-6 col-md-6 col-lg-5 col-xl-3"
                          >
                          </input-field>
                        </div>
                      </div>
                      <div class="box">
                        <div class="box-body">
                          <div class="form-group row">
                            <div
                              class="col-12 col-sm-3 col-md-4 col-lg-3 col-xl-3 text-sm-end"
                            >
                              <label class="col-form-label">ICD-10 Codes</label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-5 col-xl-3">
                              <div class="selectcontrols">
                                <Icd10-code
                                  @clicked="icdsearchresult"
                                  :isSelected="false"
                                ></Icd10-code>
                              </div>
                            </div>
                          </div>
                          <div
                            class=""
                            v-if="icd10table.length > 0"
                          >
                            <div class="table-responsive">
                              <table class="table table-hover table-bordered mb-0">
                                <thead>
                                  <tr>
                                    <td>No</td>
                                    <td>Code</td>
                                    <td>Description</td>
                                    <td>Action</td>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr v-for="(item, index) in icd10table" :key="index">
                                    <td>{{ index + 1 }}</td>
                                    <td>{{ item.code }}</td>
                                    <td>{{ item.description }}</td>
                                    <td>
                                      <Popper arrow content="Delete" :hover="true" class="helptips cursor-pointer">
                                        <a href="#"
                                          class="waves-effect waves-circle btn btn-circle btn-danger btn-xs remove"
                                          @click="icd10table.splice(index, 1)"><i class="fa fa-trash"></i></a>
                                      </Popper>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="box">
                        <div class="box-body">
                          <div class="form-group row">
                            <div
                              class="col-12 col-sm-3 col-md-4 col-lg-3 col-xs-3 text-sm-end"
                            >
                              <label class="col-form-label">CPT Codes</label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-5 col-xl-3">
                              <div class="selectcontrols">
                                <Cpt-code
                                  @clicked="cptsearchresult"
                                  :isSelected="false"
                                ></Cpt-code>
                              </div>
                            </div>
                          </div>
                          <div
                            class=""
                            v-if="cpttable.length > 0"
                          >
                            <div class="table-responsive">
                              <table class="table table-hover table-bordered mb-0">
                                <thead>
                                  <tr>
                                    <th>No</th>
                                    <th>Code</th>
                                    <th>Modifiers</th>
                                    <th>ICD10 Dx Ptrs</th>
                                    <th style="width: 150px;">Quantity</th>
                                    <th style="width: 150px;">Price</th>
                                    <th>Actions</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr v-for="(item, index) in cpttable" :key="index">
                                    <td>{{ index + 1 }}</td>
                                    <td>{{ item.code }}</td>
                                    <td>
                                      <Cpt-hcpcs-modifier
                                        v-model="item.cpt_modifier_a"
                                        class="cptMod me-1"
                                        style="width:35px;"
                                      ></Cpt-hcpcs-modifier>
                                      <Cpt-hcpcs-modifier
                                        v-model="item.cpt_modifier_b"
                                        class="cptMod me-1"
                                        style="width:35px;"
                                      ></Cpt-hcpcs-modifier>
                                      <Cpt-hcpcs-modifier
                                        v-model="item.cpt_modifier_c"
                                        class="cptMod me-1"
                                        style="width:35px;"
                                      ></Cpt-hcpcs-modifier>
                                      <Cpt-hcpcs-modifier
                                        v-model="item.cpt_modifier_d"
                                        class="cptMod me-1"
                                        style="width:35px;"
                                      ></Cpt-hcpcs-modifier>
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        class="form-control d-inline-block ps-5 pe-5 me-1"
                                        v-model="item.cpt_icd10_pointer_a"
                                        style="width:38px;"
                                      />
                                      <input
                                        type="text"
                                        class="form-control d-inline-block ps-5 pe-5 me-1"
                                        v-model="item.cpt_icd10_pointer_b"
                                        style="width:38px;"
                                      />
                                      <input
                                        type="text"
                                        class="form-control d-inline-block ps-5 pe-5 me-1"
                                        v-model="item.cpt_icd10_pointer_c"
                                        style="width:38px;"
                                      />
                                      <input
                                        type="text"
                                        class="form-control d-inline-block me-1"
                                        v-model="item.cpt_icd10_pointer_d"
                                        style="width:38px;"
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        class="form-control mx-0"
                                        v-model="item.cpt_qty"
                                        placeholder="Quantity"
                                      />
                                    </td>
                                    <td>
                                      <div class="input-group">
                                        <span class="input-group-text"
                                          ><i class="fa fa-dollar"></i
                                        ></span>
                                        <input
                                          type="text"
                                          class="form-control mx-0"
                                          placeholder="Price"
                                          v-model="item.cpt_price"
                                        />
                                      </div>
                                    </td>
                                    <td>
                                      <a
                                        href="#"
                                        class="waves-effect waves-light btn btn-danger btn-xs me-1"
                                        @click="
                                          cptndctable.splice(
                                            cptndctable.indexOf(index),
                                            1
                                          )
                                        "
                                        v-if="cptndctable.includes(index)"
                                        ><i class="fa fa-minus"></i> NDC</a>
                                      <a
                                        href="#"
                                        class="waves-effect waves-light btn btn-info btn-xs me-1"
                                        @click="addCptNdc(index)"
                                        v-else
                                        ><i class="fa fa-plus"></i> NDC
                                      </a>
                                      <Popper arrow content="Delete" :hover="true" class="helptips cursor-pointer">
                                        <a
                                          href="#"
                                          class="waves-effect waves-circle btn btn-circle btn-danger btn-xs"
                                          @click="
                                            cpttable.splice(index, 1),
                                            cptndctable.splice(
                                            cptndctable.indexOf(index),
                                            1
                                          )"><i class="fa fa-trash"></i></a>
                                      </Popper>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="box"
                        v-if="cptndctable.length > 0"
                      >
                      <div class="box-body">
                        <div class="table-responsive-sm">
                          <table class="table table-hover table-bordered mb-0">
                            <thead>
                              <tr>
                                <td>Procedure</td>
                                <td>Package Code</td>
                                <td>Quantity</td>
                                <td>Units</td>
                                <td>Actions</td>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(item, index) in cptndctable" :key="index">
                                <td>
                                  {{ cpttable[item].code }}/{{
                                    cpttable[item].cpt_modifier_a
                                  }}<span v-if="cpttable[item].cpt_modifier_b"
                                    >:{{ cpttable[item].cpt_modifier_b }}</span
                                  ><span v-if="cpttable[item].cpt_modifier_c"
                                    >:{{ cpttable[item].cpt_modifier_c }}</span
                                  ><span v-if="cpttable[item].cpt_modifier_d"
                                    >:{{ cpttable[item].cpt_modifier_d }}</span
                                  >/${{ cpttable[item].cpt_price }}
                                </td>
                                <td>
                                  <NdcCode
                                    v-model="cpttable[item].cpt_ndc_code"
                                    @clicked="ndcsearchresult"
                                  ></NdcCode>
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    class="form-control mx-0"
                                    v-model="cpttable[item].cpt_ndc_qty"
                                    v-on:keyup="bindndcqty(item, $event.target.value)"
                                    placeholder="Quantity"
                                  />
                                </td>
                                <td>
                                  <div class="controls">
                                    <select
                                      name="select"
                                      v-model="cpttable[item].cpt_ndc_units"
                                      v-on:change="
                                        bindndcunit(item, $event.target.value)
                                      "
                                      class="form-select form-control"
                                    >
                                      <option value="">Select Units</option>
                                      <option
                                        :selected="cpttable[item].cpt_ndc_units == 'UN'"
                                        value="UN"
                                        >UN(Unit){{
                                          cpttable[item].cpt_ndc_units
                                        }}</option
                                      >
                                      <option
                                        :selected="cpttable[item].cpt_ndc_units == 'F2'"
                                        value="F2"
                                        >F2(International Unit)</option
                                      >
                                      <option
                                        :selected="cpttable[item].cpt_ndc_units == 'GR'"
                                        value="GR"
                                        >GR(Gram)</option
                                      >
                                      <option
                                        :selected="cpttable[item].cpt_ndc_units == 'ME'"
                                        value="ME"
                                        >ME(Milligram)</option
                                      >
                                    </select>
                                  </div>
                                </td>
                                <td>
                                  <Popper arrow content="Delete" :hover="true" class="helptips cursor-pointer">
                                    <a
                                      href="#"
                                      class="waves-effect waves-circle btn btn-circle btn-danger btn-xs"
                                      @click="cptndctable.splice(index, 1)"><i class="fa fa-trash"></i></a>
                                  </Popper>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      </div>
                      <div class="box">
                        <div class="box-body">
                          <div class="form-group row">
                            <div class="col-12 col-sm-3 col-md-4 col-lg-3 col-xs-3 text-sm-end">
                              <label class="col-form-label">Custom Procedures</label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-5 col-xl-3">
                              <div class="selectcontrols">
                                <Custom-procedure-code
                                  @clicked="customproceduresearchresult"
                                  :isSelected="false"
                                >
                                </Custom-procedure-code>
                              </div>
                            </div>
                          </div>
                          <div class="" v-if="customproceduretable.length > 0">
                            <div class="table-responsive">
                              <table class="table table-hover table-bordered mb-0">
                                <thead>
                                  <tr>
                                    <td>No</td>
                                    <td>Code</td>
                                    <td>Description</td>
                                    <td>Quantity</td>
                                    <td>Price</td>
                                    <td>Actions</td>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="(item, index) in customproceduretable"
                                    :key="index"
                                  >
                                    <td>{{ index + 1 }}</td>
                                    <td>{{ item.code }}</td>
                                    <td>{{ item.description }}</td>
                                    <td>
                                      <input
                                        type="text"
                                        class="form-control mx-0"
                                        v-model="item.custom_procedure_qty"
                                        placeholder="Quantity"
                                      />
                                    </td>
                                    <td>
                                      <div class="input-group">
                                        <span class="input-group-text"
                                          ><i class="fa fa-dollar"></i
                                        ></span>
                                        <input
                                          type="text"
                                          class="form-control mx-0"
                                          v-model="item.custom_procedure_price"
                                          placeholder="Price"
                                        />
                                      </div>
                                    </td>
                                    <td>
                                      <Popper arrow content="Delete" :hover="true" class="helptips cursor-pointer">
                                        <a
                                          href="#"
                                          class="waves-effect waves-circle btn btn-circle btn-danger btn-xs"
                                          @click="customproceduretable.splice(index, 1)" 
                                          ><i class="fa fa-trash"></i></a>
                                      </Popper>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                    <div class="my-20 text-center">
                      <form-btn
                        title="Save"
                        btnCls="btn-info"
                        @click="submitForm()"
                      ></form-btn>
                      <template v-if="show_lifestyle_menu">
                          <router-link
                            to="/billing-profile-list?lifestyle=true"
                            class="waves-effect waves-light btn btn-danger"
                            >Close</router-link
                          >
                      </template>
                      <template v-else>
                          <router-link
                            to="/billing-profile-list"
                            class="waves-effect waves-light btn btn-danger"
                            >Close</router-link
                          >
                      </template>
                    </div>
                </div>
            </div>
            <!--document model start-->
            <div id="formdocument" class="modal fade" tabindex="-1" role="dialog"
              aria-labelledby="myModalLabel" aria-hidden="true">
              <div class="modal-dialog modal-md">
                  <div class="modal-content">
                  <div class="modal-header">
                    <h3 class="modal-title fw-900 mt-0">Reference of Knowledge Base</h3>
                  </div>
                  <div class="modal-body overflow-auto" style="max-height: 350px;">
                    <table class="table table-bordered">
                        <thead>
                          <tr>
                          <th scope="col">No.</th>
                          <th scope="col">Knowledge Base Articles</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                              <td>1</td>
                              <td> <a :href="path+'how-to-add-a-new-billing-profile-in-pureehr'" target="_blank">How To Add A New Billing Profile In PureEHR?</a></td>
                          </tr>
                          <tr>
                              <td>2</td>
                              <td> <a :href="path+'how-to-edit-a-billing-profile-in-pureehr'" target="_blank">How To Edit A Billing Profile In PureEHR?</a></td>
                          </tr>
                          <tr>
                              <td>3</td>
                              <td> <a :href="path+'how-to-create-a-role-for-billing-administrator-in-pureehr'" target="_blank">How To Create A Role For Billing Administrator In PureEHR?</a></td>
                          </tr>
                        </tbody>
                    </table>
                    <Search :key="searchKey"></Search>
                  </div>
                  <div class="modal-footer">
                    <button type="button" @click="" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
                  </div>
                  </div>
              </div>
            </div>
            <!--document model end-->
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import InputField from "@/components/base/formFields/InputField.vue";
import FormBtn from "@/components/base/formFields/formBtn.vue";
import Icd10Code from "@/components/base/Icd10Code.vue";
import CptCode from "@/components/base/CptCode.vue";
import NdcCode from "@/components/base/NdcCode.vue";
import CustomProcedureCode from "@/components/base/CustomProcedureCode.vue";
import CptHcpcsModifier from "@/components/base/CptHcpcsModifier.vue";
import LifeStyleHeader from "@/components/Lifestyle/LifeStyleHeader.vue";
import axios from "@/scripts/axios.js";
import useVuelidate from "@vuelidate/core";
import submitMixins from "@/scripts/mixins/submitMixins.js";
import Popper from "vue3-popper";
import Search from "@/components/Search.vue";
export default {
  mixins: [submitMixins],
  setup: () => {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      icd10table: [],
      cpttable: [],
      cptndctable: [],
      customproceduretable: [],
      ndctable: [],
      show_lifestyle_menu : this.$route.query.lifestyle ? true : false,
      path: process.env.VUE_APP_KB_DOCUMENT,
      searchKey:0,
    };
  },
  components: {
    Icd10Code,
    CptCode,
    CustomProcedureCode,
    CptHcpcsModifier,
    InputField,
    FormBtn,
    NdcCode,
    Popper,
    LifeStyleHeader,
    Search
  },
  methods: {
    reset() {
      this.searchKey = this.searchKey + 1;
    },
    icdsearchresult(obj) {
      this.icd10table.push({
        id: obj.id,
        code: obj.code,
        description: obj.description,
      });
    },
    ndcsearchresult(obj) {
      this.ndctable[obj.code] = {
        id: obj.id,
        description: obj.name,
      };
    },
    cptsearchresult(obj) {
      this.cpttable.push({
        id: obj.id,
        code: obj.code,
        description: obj.description,
        cpt_modifier_a: "",
        cpt_modifier_b: "",
        cpt_modifier_c: "",
        cpt_modifier_d: "",
        cpt_icd10_pointer_a: "",
        cpt_icd10_pointer_b: "",
        cpt_icd10_pointer_c: "",
        cpt_icd10_pointer_d: "",
        cpt_qty: "",
        cpt_price: "",
        cpt_ndc_code_id: "",
        cpt_ndc_code: "",
        cpt_ndc_description: "",
        cpt_ndc_qty: "",
        cpt_ndc_units: "",
      });
    },
    customproceduresearchresult(obj) {
      this.customproceduretable.push({
        id: obj.id,
        code: obj.code,
        description: obj.description,
        custom_procedure_qty: 1,
        custom_procedure_price: obj.price,
      });
    },
    addCptNdc(index) {
      this.cptndctable.push(index);
    },
    bindndcqty(item, value) {
      this.cpttable[item].cpt_ndc_qty = value;
    },
    bindndcunit(item, value) {
      this.cpttable[item].cpt_ndc_units = value;
    },
    submitForm() {
      this.cpttable.forEach((entry) => {
        if (this.ndctable[entry.cpt_ndc_code]) {
          entry.cpt_ndc_code_id = this.ndctable[entry.cpt_ndc_code].id;
          entry.cpt_ndc_description = this.ndctable[
            entry.cpt_ndc_code
          ].description;
        }
      });
      this.form.icd10_id = this.icd10table.map((icdary) => {
        return icdary.id;
      });
      this.form.icd10_code = this.icd10table.map((icdary) => {
        return icdary.code;
      });
      this.form.icd10_description = this.icd10table.map((icdary) => {
        return icdary.description;
      });

      this.form.cpt_code = this.cpttable.map((cptary) => {
        return cptary.code;
      });
      this.form.cpt_description = this.cpttable.map((cptary) => {
        return cptary.description;
      });
      this.form.cpt_code_id = this.cpttable.map((cptary) => {
        return cptary.id;
      });
      this.form.cpt_modifier_a = this.cpttable.map((cptary) => {
        return cptary.cpt_modifier_a;
      });
      this.form.cpt_modifier_b = this.cpttable.map((cptary) => {
        return cptary.cpt_modifier_b;
      });
      this.form.cpt_modifier_c = this.cpttable.map((cptary) => {
        return cptary.cpt_modifier_c;
      });
      this.form.cpt_modifier_d = this.cpttable.map((cptary) => {
        return cptary.cpt_modifier_d;
      });
      this.form.cpt_icd10_pointer_a = this.cpttable.map((cptary) => {
        return cptary.cpt_icd10_pointer_a;
      });
      this.form.cpt_icd10_pointer_b = this.cpttable.map((cptary) => {
        return cptary.cpt_icd10_pointer_b;
      });
      this.form.cpt_icd10_pointer_c = this.cpttable.map((cptary) => {
        return cptary.cpt_icd10_pointer_c;
      });
      this.form.cpt_icd10_pointer_d = this.cpttable.map((cptary) => {
        return cptary.cpt_icd10_pointer_d;
      });
      this.form.cpt_qty = this.cpttable.map((cptary) => {
        return cptary.cpt_qty;
      });
      this.form.cpt_price = this.cpttable.map((cptary) => {
        return cptary.cpt_price;
      });

      this.form.cpt_ndc_code = this.cpttable.map((cptary) => {
        return cptary.cpt_ndc_code;
      });
      this.form.cpt_ndc_code_id = this.cpttable.map((cptary) => {
        return cptary.cpt_ndc_code_id;
      });
      this.form.cpt_ndc_description = this.cpttable.map((cptary) => {
        return cptary.cpt_ndc_description;
      });
      this.form.cpt_ndc_qty = this.cpttable.map((cptary) => {
        return cptary.cpt_ndc_qty;
      });
      this.form.cpt_ndc_units = this.cpttable.map((cptary) => {
        return cptary.cpt_ndc_units;
      });

      this.form.custom_procedure_code = this.customproceduretable.map(
        (customprocedureary) => {
          return customprocedureary.code;
        }
      );
      this.form.custom_procedure_code_id = this.customproceduretable.map(
        (customprocedureary) => {
          return customprocedureary.id;
        }
      );
      this.form.custom_procedure_description = this.customproceduretable.map(
        (customprocedureary) => {
          return customprocedureary.description;
        }
      );
      this.form.custom_procedure_qty = this.customproceduretable.map(
        (customprocedureary) => {
          return customprocedureary.custom_procedure_qty;
        }
      );
      this.form.custom_procedure_price = this.customproceduretable.map(
        (customprocedureary) => {
          return customprocedureary.custom_procedure_price;
        }
      );

      if (this.$route.name === "BillingProfileAdd") {
        var url = "billing-profile/store";
      } else {
        var url = "billing-profile/update";
      }
      this.postForm(url, "billingprofile", true, "formData");
    },
    formPostSuccess(data) {
      if (this.$route.name === "BillingProfileAdd") {
        this.moshaToast("Record added successfully", "success");
      } else {
        this.moshaToast("Record updated successfully", "success");
      }
      this.$store.state.billingprofile.form = {};
      Object.assign(
        this.$store.state.billingprofile.form,
        this.$store.state.billingprofile.defaultFormData
      );
      if (this.show_lifestyle_menu) {
          this.$router.push({ name: "BillingProfileList", query: { lifestyle: this.show_lifestyle_menu } });
      } else {
          this.$router.push({ name: "BillingProfileList" });
      }
     
    },
  },
  async beforeCreate() {
    this.$store.state.billingprofile.form = {};
    Object.assign(
      this.$store.state.billingprofile.form,
      this.$store.state.billingprofile.defaultFormData
    );
  },
  mounted() {
    if (this.$route.name === "BillingProfileEdit") {
      axios.get("billing-profile/edit/" + this.$route.params.id).then((res) => {
        this.form.id = res.data.data.billing_profile.id;
        this.form.name = res.data.data.billing_profile.name;
        this.icd10table = res.data.data.billing_icd10_record;
        this.cpttable = res.data.data.billing_cpt_record;
        this.customproceduretable = res.data.data.billing_custom_record;
        this.cpttable.forEach((data, i) => {
          if (data.cpt_ndc_code != null) {
            this.cptndctable.push(i);
          }
        });
        setTimeout(() => {
          $(".cptMod").each(function() {
            $(this).focus();
            $(this).blur();
          });
        }, 500);
      });
    }
  },
  computed: {
    form() {
      return this.$store.state.billingprofile.form;
    },
    vuelidateExternalResults() {
      return this.$store.state.billingprofile.vuelidateExternalResults;
    },
    validationRules() {
      return this.$store.state.billingprofile.validationRules;
    },
    title() {
      return this.$route.name === "BillingProfileAdd"
        ? "New Billing Profile"
        : "Edit Billing Profile";
    },
  },
  validations() {
    return this.$store.state.billingprofile.validationRules;
  },
};
</script>
