<template>
  <div class="form-group row">
    <label :class="labelClass">{{ title }}</label>
    <div :class="divClass">
      <div class="controls">
        <input
          type="checkbox"
          :value="value"
          :name="name"
          class="filled-in chk-col-info"
          :checked="modelValue"
          :id="id"
        />
        <label
          for="checkbox_1"
          class="m-0 text-start"
          @click="toggleCheckbox($event.target.value)"
        >
          <small v-show="isRequired" class="text-danger">*</small>
          {{ label }}
          <small v-show="isRequiredAfter" class="text-danger">*</small>
        </label>
        <span class="form-text text-muted" v-if="notes != ''">{{ notes }}</span>
      </div>
      <div class="error-msg">
        <ul class="mb-0 p-0 fs-14">
          <li class="d-block" v-for="(error, index) of errors" :key="index">
            {{ error.$message }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    value:{
      type: String,
      default: "single",
    },
    name:{
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    isRequiredAfter: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: "",
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
    labelClass: {
      type: String,
      default:
        "col-6 col-sm-4 col-md-4 col-lg-3 col-xl-3 form-label mb-0 text-sm-end",
    },
    divClass: {
      type: String,
      default: "col-6 col-sm-6 col-md-8 col-lg-5 col-xl-3",
    },
    notes: {
      type: String,
      default: "",
    },
    errors: {
      type: Array,
      default: []
    },
    id: {
      type: String,
      default: ''
    }
  },
  data() {
    return {};
  },

  methods: {
    toggleCheckbox() {
      let value = this.modelValue;
      if (this.modelValue === 0 || !this.modelValue) {
        value = 1;
      } else {
        value = 0;
      }
      this.$emit("update:modelValue", value);
    },
  },
};
</script>
