<template>
  <div class="tab-content">
    <div class="box">
      <div class="box-body">
        <div class="pageheading">
          <div class="row justify-content-between">
            <div class="col-12 col-sm-8 col-md-8 col-lg-6 col-xs-6">
              <div class="content-header">
                <h2 class="heading2 font-bold my-5">Lab Orders <a href="#" data-bs-toggle="modal" data-bs-target="#laborderdocument" title="Reference of Knowledge Base" v-on:click.prevent="reset()"> <i class="fa fa-question-circle fs-20"></i></a></h2>
              </div>
            </div>
            <div
              v-if="permissioncheck(this.$store.state.permission.create_update)"
              class="col-12 col-sm-4 col-md-4 col-lg-3 col-xs-3 text-sm-end">
              <a
                v-if="$route.name == 'PatientDetailsLaborders'"
                title="Add New Lab Order"
                href="javascript:void(0);"
                class="waves-effect waves-light btn btn-info mb-10"
                @click="addNewLabOrder"
                ><i class="fa fa-plus fs-14"></i> Lab Order</a>
              <a
                v-if="$route.name == 'PatientDetailsLabordersEdit'"
                title="Back to laborders"
                href="javascript:void(0);"
                class="waves-effect waves-light btn btn-info-light mb-10"
                @click="$router.push({ name: 'PatientDetailsLaborders' })"
                ><i class="fa fa-long-arrow-left fs-14 me-1"></i> Back to List</a>
            </div>
          </div>
        </div>
        <div class="table-responsive">
          <table-base
            :dataTypes="{
              dataType: 'patientLabOrders',
              patient_id: patient_id,
              patient_unique_id: patient_unique_id,
            }"
            :key="tabRender"
          >
            <template v-slot="slotProps">
              <td>
                {{
                  $filters.displayDateTimeLocal(
                    slotProps.item.schedule_date +
                      " " +
                      slotProps.item.schedule_time,'',
                    "ddd, MM-DD-YYYY h:mm A"
                  )
                }}
              </td>
              <td>{{ slotProps.item.loinc_description }}</td>
              <td>
                {{ $filters.displayDateTimeLocal(slotProps.item.date_of_testing,'','',"ddd, MM-DD-YYYY",true) }}
              </td>
              <td>{{ slotProps.item.status }}</td>
              <td>
                <Popper arrow content="View Lab order" :hover="true" class="helptips cursor-pointer">
                  <a href="javascript:void(0)"
                    @click="viewLaborder(slotProps.item.appointment_id)"
                    class="waves-effect waves-circle btn btn-circle btn-info btn-xs check_box"
                    ><i class="fa fa-eye"></i></a>
                </Popper>
                <Popper arrow content="Send To Laboratory" :hover="true" class="helptips cursor-pointer" v-if="!slotProps.item.order_document_name">
                  <a href="javascript:void(0)"
                    @click="labSendingDialog(slotProps.item.appointment_id)"
                    class="waves-effect waves-circle btn btn-circle btn-info btn-xs check_box"
                    ><i class="fa fa-flask"></i></a>
                </Popper>
              </td>
            </template>
          </table-base>
        </div>
      </div>
    </div>
    <div class="box manual_entry" id="manual_entry" v-if="editMode == 0">
      <div class="box-body">
        <div class="pageheading">
          <div class="content-header">
            <h3 class="heading3 font-bold m-0">New Lab Order</h3>
          </div>
        </div>
        <form novalidate>
          <div class="form-group row" :class="{ error: v$.form.appointment_id.$errors.length }">
            <label class="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end">Appointment <small class="text-danger asterisksign" style="display: none;">*</small></label>
            <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3">
              <select class="select2Single form-select form-control" v-model="v$.form.appointment_id.$model" id="appointment_id"  @change="changeAppointment($event)">
                <option selected="selected" disabled="disabled" value="">Select Appointment</option>
                <template  v-for="(item,index) in appointmentData"  :key="item.id">
                  <option v-if="item.imported_at == null " :value="item.id" :data-val="item.imported_at" :data-id="item.schedule_date" :disabled="this.appt_with_lab_order.includes(item.id)">
                    {{item.name}}
                  </option>
                </template>
                <optgroup label="Imported Appointment">
                  <template  v-for="(item,index) in appointmentData"  :key="item.id">
                    <option :value="item.id" v-if="item.imported_at != null " :data-val="item.imported_at" data-type="imported" :data-id="item.schedule_date">
                      {{ item.name }}
                    </option>
                  </template>
                </optgroup>
              </select>
              <div class="error-msg" v-if="v$.form.appointment_id.$errors.length > 0">
                  <ul class="mb-0 p-0 fs-14">
                      <li class="d-block" v-for="(error, index) of v$.form.appointment_id.$errors">
                          {{ error.$message }}
                      </li>
                  </ul>
              </div>
            </div>
          </div>
          <div class="form-group row availdate">
              <label class="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end">Date Test Performed</label> 
              
              <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3">
                <ejs-datepicker id="date_of_testing" :value="this.date_of_testing" v-model="this.date_of_testing" :format="dateFormat" :strictMode='strict'  :min='minDt' placeholder="mm-dd-yyyy" :showClearButton='false' @change="getDate" :openOnFocus='true'></ejs-datepicker>
              </div>
          </div>
          <check-box
            :id="md_checkbox_1"
            title="Doctor Signoff"
            v-model="form.is_doctor_reviewed"
            label="Check this box when the doctor has reviewed the lab result and taken appropriate action."
            divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3"
            labelClass="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 form-label mb-0 text-sm-end"
          ></check-box>

          <div class="form-group row">
            <label
              class="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end"
              >Status</label
            >
            <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3">
              <select
                class="form-control"
                data-placeholder="Status"
                v-model="form.status"
              >
                <option value="Order Entered">Order Entered</option>
                <option value="Discountinued">Discountinued</option>
                <option value="In Progress">In Progress</option>
                <option value="Results Received">Results Received</option>
                <option value="Results Reviewed with Patient"
                  >Results Reviewed with Patient</option
                >
                <option value="Paper Order">Paper Order</option>
              </select>
            </div>
          </div>
          <select-box
            title="Result Documents"
            id="result_document_id"
            v-model="form.result_document_id"
            :getDataURL="doclisturl"
            :isRequired="false"
            :requestData="{ method: 'post', data: { patient_id: patient_id ,folder_type:'lab_results'} }"
            labelClass="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end"
            divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3"
          ></select-box>
          <div class="row justify-content-end">
            <div class="col-12 col-sm-12 col-md-8 col-lg-9">
              <div class="mb-15">
                <small class="fs-12 l-h-n"><b>Note:</b> Upload a document by following the path - <br>Documents > Document upload > Lab</small>
              </div>
            </div>
          </div>
          <div class="form-group row">
              <label class="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end">Lab</label>
              <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3">
                  <div class="controls d-flex">
                      <select name="select"  v-model="form.lab_master_id"  class="form-select form-control" @change="fillLabData(form.lab_master_id)">
                          <option  value="" disabled="disabled">Select Lab</option>
                          <option :value="item.id" v-for="item in lab_list" :key="item.id" :data-text="item.lab_name" >{{
                            item.lab_name
                          }}</option>
                      </select>
                      <div class="waves-effect waves-light btn btn-info btn-xs pt-10 ms-1" @click="resetLabMaster" data-bs-toggle="modal"
                        data-bs-target="#addNewLabMaster">
                          <i class="fa fa-plus fs-14"></i>
                      </div>
                  </div>
              </div>
          </div>
          <div class="row justify-content-end">
            <div class="col-12 col-sm-12 col-md-8 col-lg-9">
              <div class="mb-15">
                <small class="fs-12 l-h-n"><b>Note:</b> The select and create lab is for data entry purpose</small>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end">Lab Country </label>
            <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3">
              <Select2
                v-model="form.lab_country_id"
                id="lab_country_id"
                placeholder="Select Country"
                :options="countries"
                @select="countryChange($event)"
              />              
            </div>
          </div>

          <input-field @keyup="zipcode" title="Lab Zipcode" v-model="form.lab_zip_code" labelClass="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end" divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3"></input-field>

          <div class="form-group row">
            <label class="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end"> Lab State </label>
            <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3">
              <Select2
                v-model="form.lab_state_id"
                id="state_id"
                placeholder="Select State"
                :options="states"
              />               
            </div>
          </div>
          

          <input-field title="Lab City" v-model="form.lab_city" labelClass="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end" divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3"></input-field>


          <text-area
              title="Lab Address"
              labelClass="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end"
              divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3"
              v-model="form.lab_address"
            ></text-area>

          <input-field title="Lab Mobile Number" v-model="form.lab_mobile" mask="(000) 000-0000" labelClass="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end" divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3">
        </input-field>

        <input-field title="Lab Phone Number" v-model="form.lab_phone" mask="(000) 000-0000" labelClass="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end" divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3">
        </input-field>
        <input-field
            id="lab_email"
            title="Lab Email"
            :isRequired="false"
            v-model="v$.form.lab_email.$model"
            :errors="v$.form.lab_email.$errors"
            divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3"
            labelClass="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end"
          >
          </input-field>

          <div class="table-responsive-md mb-20">
            <table class="table table-hover dataTable">
              <thead>
                <tr>
                  <th style="width:110px">LOINC Code</th>
                  <th>Description</th>
                  <th>Result Value</th>
                  <th>Units</th>
                  <th>Normal Value</th>
                  <th>Normal Units</th>
                  <th>Abnormal Flag</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="input in Oitems" v-bind:id="'tr_' + input.id">
                  <td>
                    <div class="selectcontrols">
                      <Autocomplete
                        max="30"
                        v-model="form.loinc_code[input.id]"
                        @input="getItemss"
                        :results="items"
                        @onSelect="selectItems($event, input.id)"
                        :id="input.id"
                        ref="autocompleteArr"
                        class="form-control"
                      ></Autocomplete>
                    </div>
                  </td>
                  <td v-bind:id="'lbl_' + input.id"></td>
                  <td style="width: 100px;">
                    <input
                      type="text"
                      name="result_value"
                      v-model="form.result_value[input.id]"
                      class="form-control"
                      placeholder="Result Value"
                    />
                  </td>
                  <td style="width: 100px;">
                    <input
                      type="text"
                      name="text"
                      v-model="form.result_unit[input.id]"
                      class="form-control"
                      placeholder="Result units"
                    />
                  </td>
                  <td style="width: 100px;">
                    <input
                      type="text"
                      name="text"
                      v-model="form.normal_value[input.id]"
                      class="form-control"
                      placeholder="Normal value"
                    />
                  </td>
                  <td style="width: 100px;">
                    <input
                      type="text"
                      name="text"
                      v-model="form.normal_unit[input.id]"
                      class="form-control"
                      placeholder="Normal unit"
                    />
                  </td>
                  <td>
                    <select
                      name="abnormal_flag"
                      v-model="form.abnormal_flag[input.id]"
                      id="salutationselect"
                      required=""
                      class="form-select form-control"
                    >
                      <option value=""></option>

                       <option value="L" >L -- Below low normal</option>

                       <option value="H">H -- Above high normal</option>

                       <option value="LL">LL -- Below lower panic limits</option>

                       <option value="HH">HH -- Above upper panic limits</option>

                       <option value="<">&lt; -- Below absolute low-off instrument scale</option>

                       <option value=">">&gt; -- Above absolute high-off instrument scale</option>

                       <option value="N">N -- Normal (applies to non-numeric results)</option>

                       <option value="A">A -- Abnormal (applies to non-numeric results)</option>

                       <option value="AA">AA -- Very abnormal (applies to non-numeric units, analogous to panic limits for numeric units)</option>

                       <option value="null">null -- No range defined, or normal ranges don't apply</option>

                       <option value="U">U -- Significant change up</option>

                       <option value="D">D -- Significant change down</option>

                       <option value="B">B -- Better--use when direction not relevant</option>

                       <option value="W">W -- Worse--use when direction not relevant</option>

                       <option value="S">S -- Susceptible. Indicates for microbiology susceptibilities only.</option>

                       <option value="R">R -- Resistant. Indicates for microbiology susceptibilities only.</option>

                       <option value="I">I -- Intermediate. Indicates for microbiology susceptibilities only.</option>

                       <option value="MS">MS -- Moderately susceptible. Indicates for microbiology susceptibilities only.</option>

                       <option value="VS">VS -- Very susceptible. Indicates for microbiology susceptibilities only.</option>
                    </select>
                  </td>
                  <td>
                    <a
                      href="javascript:void(0)"
                      title="remove"
                      v-if="input.id != 0"
                      @click="remove(input.id)"
                      class="waves-effect waves-circle btn btn-circle btn-danger btn-xs me-1"
                      ><i class="fa fa-trash"></i
                    ></a>
                  </td>
                </tr>
                <tr>
                  <td colspan="8" class="text-center text-md-end">
                    <a
                      href="javascript:void(0)"
                      title="Add New Lab Order"
                      @click="addNew($event)"
                      class="waves-effect waves-light btn btn-info"
                      ><i class="fa fa-plus fs-14"></i> Add Another</a
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </form>
        <div class="text-center">
          <form-btn
            v-if ="button_hideshow"
            title="Save"
            btnCls="waves-effect waves-light btn btn-info"
            @click="save()"
          ></form-btn>
          <form-btn
            type="button"
            title="Close"
            btnCls="waves-effect waves-light btn btn-danger me-5"
            @click="addNewLabOrder"
          ></form-btn>
        </div>
      </div>
    </div>

    <div class="box table_filters" id="lab_order_list" v-if="editMode == 1">
      <div class="box-body">
        <div class="pageheading">
          <div class="content-header">
            <h3 class="heading3 font-bold m-0">Edit Lab Order</h3>
          </div>
        </div>
        <div class="table-responsive">
          <table-base
            :dataTypes="{
              dataType: 'patientLabOrdersEdit',
              patient_id: patient_id,
              patient_unique_id: patient_unique_id,
              id2: $route.params.id2,
            }"
            :key="key"
          >
            <template v-slot="slotProps">
              <td>{{ slotProps.item.provider_name }}</td>
              <td>{{ slotProps.item.loinc_code }}</td>
              <td>{{ slotProps.item.loinc_description }}</td>
              <td>
                {{ $filters.displayDateTimeLocal(slotProps.item.date_of_testing,'',false,"ddd, MM-DD-YYYY",true) }}
              </td>
              <td>{{ slotProps.item.is_doctor_reviewed ? "yes" : "no" }}</td>
              <td>{{ slotProps.item.status }}</td>
              <td>
                {{ slotProps.item.result_value }}
                {{ slotProps.item.result_unit }}
              </td>
              <td>
                <a
                  href="javascript:void(0);"
                  @click="FileDisplay(slotProps.item.document_name)"
                >
                  {{ slotProps.item.document_name }}
                </a>
              </td>
              <td>{{ slotProps.item.lab_result_description }}</td>
              <td>
                <div class="d-flex" v-if="permissioncheck(this.$store.state.permission.create_update)">
                  <Popper arrow content="Edit Lab Order" :hover="true" class="helptips cursor-pointer">
                  <a                    
                    href="javascript:void(0)"
                    class="waves-effect waves-circle btn btn-circle btn-info btn-xs me-1 check_box"
                    @click="editBox(slotProps.item.id)"
                    ><i class="fa fa-pencil"></i></a>
                  </Popper>
                  <Popper arrow content="Delete Lab Order" :hover="true" class="helptips cursor-pointer">
                    <a
                      href="javascript:void(0)"
                      class="waves-effect waves-circle btn btn-circle btn-danger btn-xs"
                      @click="remove(slotProps.item.id)"
                      ><i class="fa fa-trash"></i></a>
                  </Popper>
                </div>
              </td>
            </template>
          </table-base>
        </div>
        <div class="edit_results" :class="dynamicClass">
          <div class="pageheading">
            <h3 class="heading3 font-bold">Edit Results</h3>
          </div>
          <form novalidate id="edit_results_div">
            <div class="form-group row">
              <label
                class="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end"
                >Abnormal Flag</label
              >
              <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3">
                <select
                  name="abnormal_flag"
                  v-model="form.abnormal_flag"
                  id="salutationselect"
                  required=""
                  class="form-select form-control">
                  <option value=""></option>

                   <option value="L" >L -- Below low normal</option>

                   <option value="H">H -- Above high normal</option>

                   <option value="LL">LL -- Below lower panic limits</option>

                   <option value="HH">HH -- Above upper panic limits</option>

                   <option value="<">&lt; -- Below absolute low-off instrument scale</option>

                   <option value=">">&gt; -- Above absolute high-off instrument scale</option>

                   <option value="N">N -- Normal (applies to non-numeric results)</option>

                   <option value="A">A -- Abnormal (applies to non-numeric results)</option>

                   <option value="AA">AA -- Very abnormal (applies to non-numeric units, analogous to panic limits for numeric units)</option>

                   <option value="null">null -- No range defined, or normal ranges don't apply</option>

                   <option value="U">U -- Significant change up</option>

                   <option value="D">D -- Significant change down</option>

                   <option value="B">B -- Better--use when direction not relevant</option>

                   <option value="W">W -- Worse--use when direction not relevant</option>

                   <option value="S">S -- Susceptible. Indicates for microbiology susceptibilities only.</option>

                   <option value="R">R -- Resistant. Indicates for microbiology susceptibilities only.</option>

                   <option value="I">I -- Intermediate. Indicates for microbiology susceptibilities only.</option>

                   <option value="MS">MS -- Moderately susceptible. Indicates for microbiology susceptibilities only.</option>

                   <option value="VS">VS -- Very susceptible. Indicates for microbiology susceptibilities only.</option>
                </select>
              </div>
            </div>
            <div class="form-group row">
              <label
                class="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end"
                >Normal Range</label
              >
              <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3">
                <input
                  type="text"
                  v-model="form.normal_value"
                  name="text"
                  class="form-control"
                  placeholder="Normal Range"
                />
              </div>
            </div>
            <div class="form-group row">
              <label
                class="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end"
                >Normal Range Units</label
              >
              <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3">
                <input
                  type="text"
                  v-model="form.normal_unit"
                  name="text"
                  class="form-control"
                  placeholder="Normal Range Units"
                />
              </div>
            </div>
            <div class="form-group row">
              <label
                class="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end"
                >Result Value</label
              >
              <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3">
                <input
                  type="text"
                  v-model="form.result_value"
                  name="text"
                  class="form-control"
                  placeholder="Result Value"
                />
              </div>
            </div>
            <div class="form-group row">
              <label
                class="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end"
                >Result Units</label
              >
              <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3">
                <input
                  type="text"
                  name="text"
                  v-model="form.result_unit"
                  class="form-control"
                  placeholder="Result units"
                />
              </div>
            </div>
            <div class="form-group row">
              <label
                class="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end"
                >LOINC Code</label
              >
              <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3">
                <div class="selectcontrols">
                  <Autocomplete
                    max="30"
                    v-model="form.loinc_code"
                    @input="getItems"
                    :results="items"
                    @onSelect="selectItems($event, 'edit0')"
                    id="edit0"
                    ref="autocomplete"
                    class="form-control"
                  ></Autocomplete>
                </div>
              </div>
            </div>

            <div class="form-group row" :class="{ error: v$.form.appointment_id.$errors.length }">
            <label class="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end">Appointment <small class="text-danger asterisksign" style="display: none;">*</small></label>
            <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3">
              <select class="select2Single form-select form-control" v-model="v$.form.appointment_id.$model" id="appointment_id"  @change="changeAppointment($event)">
                <option selected="selected" disabled="disabled" value="">Select Appointment</option>
                <template  v-for="(item,index) in appointmentData"  :key="item.id">
                  <option v-if="item.imported_at == null " :value="item.id" :data-val="item.imported_at" :data-id="item.schedule_date" :disabled="this.appt_with_lab_order.includes(item.id)">
                    {{item.name}}
                  </option>
                </template>
                <optgroup label="Imported Appointment">
                  <template  v-for="(item,index) in appointmentData"  :key="item.id">
                    <option :value="item.id" v-if="item.imported_at != null " :data-val="item.imported_at" data-type="imported" :data-id="item.schedule_date">
                      {{ item.name }}
                    </option>
                  </template>
                </optgroup>
              </select>
              <div class="error-msg" v-if="v$.form.appointment_id.$errors.length > 0">
                  <ul class="mb-0 p-0 fs-14">
                      <li class="d-block" v-for="(error, index) of v$.form.appointment_id.$errors">
                          {{ error.$message }}
                      </li>
                  </ul>
              </div>
            </div>
          </div>
          <div class="form-group row availdate">
            <label class="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end">Date Test Performed</label> 
            
            <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3">
              <ejs-datepicker id="edit_date_of_testing" :value="form.date_of_testing1" v-model="form.date_of_testing1" :format="dateFormat" :strictMode='strict' :min='minDt' placeholder="mm-dd-yyyy" :showClearButton='false' @change="getDateOfTesting" :openOnFocus='true'></ejs-datepicker>
            </div>
          </div>
            <text-area
              title="Comments"
              labelClass="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end"
              divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3"
              v-model="form.lab_result_description"
            ></text-area>

            <check-box
              :id="md_checkbox_1"
              title="Doctor Signoff"
              v-model="form.is_doctor_reviewed"
              label="Check this box when the doctor has reviewed the lab result and taken appropriate action."
               labelClass="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 form-label text-sm-end"
              divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3"
            ></check-box>

            <div class="form-group row">
              <label
                class="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end"
                >Status</label
              >
              <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3">
                <select
                  class="form-control"
                  data-placeholder="Status"
                  v-model="form.status"
                >
                  <option value="Order Entered">Order Entered</option>
                  <option value="Discountinued">Discountinued</option>
                  <option value="In Progress">In Progress</option>
                  <option value="Results Received">Results Received</option>
                  <option value="Results Reviewed with Patient"
                    >Results Reviewed with Patient</option
                  >
                  <option value="Paper Order">Paper Order</option>
                </select>
              </div>
            </div>
            <select-box
              title="Result Documents"
              id="result_document_id"
              v-model="form.result_document_id"
              :getDataURL="doclisturl"
              :isRequired="false"
              :requestData="{ method: 'post', data: { patient_id: patient_id ,folder_type:'lab_results'} }"
              labelClass="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end"
              divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3"
            ></select-box>
            <div class="row justify-content-end">
              <div class="col-12 col-sm-12 col-md-8 col-lg-9">
                <div class="mb-15">
                  <small class="fs-12 l-h-n"><b>Note:</b> Upload a document by following the path - <br>Documents > Document upload > Lab</small>
                </div>
              </div>
            </div>
            <div class="form-group row">
                <label class="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end">Lab</label>
                <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3">
                    <div class="controls d-flex">
                        <select name="select"  v-model="form.lab_master_id"  class="form-select form-control" @change="fillLabData(form.lab_master_id)">
                            <option  value="null" disabled="disabled">Select Lab</option>
                            <option :value="item.id" v-for="item in lab_list" :key="item.id" :data-text="item.lab_name" >{{
                              item.lab_name
                            }}</option>
                        </select>
                        <div class="waves-effect waves-light btn btn-info btn-xs pt-10 ms-1" data-bs-toggle="modal"
                          data-bs-target="#addNewLabMaster">
                            <i class="fa fa-plus fs-14"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-end">
            <div class="col-12 col-sm-12 col-md-8 col-lg-9">
              <div class="mb-15">
                <small class="fs-12 l-h-n"><b>Note:</b> The select and create lab is for data entry purpose</small>
              </div>
            </div>
          </div>


          <div class="form-group row">
            <label class="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end">Lab Country </label>
            <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3">
              <Select2
                v-model="form.lab_country_id"
                id="lab_country_id"
                placeholder="Select Country"
                :options="countries"
                @select="countryChange($event)"
              />              
            </div>
          </div>

          <div class="form-group row">
            <label class="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end"> Lab State </label>
            <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3">
              <Select2
                v-model="form.lab_state_id"
                id="state_id"
                placeholder="Select State"
                :options="states"
              />               
            </div>
          </div>

          <input-field title="Lab City" v-model="form.lab_city" labelClass="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end" divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3">
        </input-field>

          <text-area
              title="Lab Address"
              labelClass="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end"
              divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3"
              v-model="form.lab_address"
            ></text-area>
          <input-field title="Lab Zipcode" v-model="form.lab_zip_code" labelClass="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end" divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3">
        </input-field>

          <input-field title="Lab Mobile Number" v-model="form.lab_mobile" mask="(000) 000-0000" labelClass="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end" divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3">
        </input-field>

        <input-field title="Lab Phone Number" v-model="form.lab_phone" mask="(000) 000-0000" labelClass="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end" divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3">
        </input-field>
        <input-field
            id="lab_email"
            title="Lab Email"
            :isRequired="false"
            v-model="v$.form.lab_email.$model"
            :errors="v$.form.lab_email.$errors"
            labelClass="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end"
            divClass="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3"
          >
          </input-field>
          </form>
          <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-9 text-center">
              <form-btn
                title="Update"
                btnCls="btn-info"
                @click="save()"
              ></form-btn>
              <a
                href="javascript:void(0)"
                @click="closeUpdate()"
                class="waves-effect waves-light btn btn-danger"
                >Cancel</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    id="addNewLabMaster"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title font-bold m-0">New Lab</h3>
        </div>
         <div class="modal-body">
          <div class="form-group row">
              <label class="col-form-label col-12 col-sm-3 col-md-3 col-lg-4 text-sm-end mb-0">Lab Name</label>
              <div class="col-12 col-sm-9 col-md-9 col-lg-8">
                <div class="controls">
                  <input id="Lab Name" v-model="lab_form.lab_name" type="text" class="form-control" placeholder="Lab Name" autocomplete="off">
                </div>
                <div class="error-msg">
                  <ul class="mb-0 p-0 fs-14" v-if="errorMessage && !lab_form.lab_name"> {{ errorMessage }}</ul>
                </div>
              </div>
          </div>
            <div class="form-group row">
                <label class="col-form-label col-12 col-sm-3 col-md-3 col-lg-4 text-sm-end mb-0">Lab Country </label>
                <div class="col-12 col-sm-9 col-md-9 col-lg-8">
                    <Select2
                        v-model="lab_form.lab_country_id"
                        id="lab_country_id"
                        placeholder="Select Country"
                        :options="countries"
                        @select="countryChange($event)"
                        />
                </div>
            </div>
            <input-field @keyup="LabZipCode" title="Lab Zipcode" v-model="lab_form.lab_zip_code" labelClass="col-form-label col-12 col-sm-3 col-md-3 col-lg-4 text-sm-end mb-0" divClass="col-12 col-sm-9 col-md-9 col-lg-8"></input-field>
            <div class="form-group row">
                <label class="col-form-label col-12 col-sm-3 col-md-3 col-lg-4 text-sm-end mb-0"> Lab State </label>
                <div class="col-12 col-sm-9 col-md-9 col-lg-8">
                    <Select2
                        v-model="lab_form.lab_state_id"
                        id="state_id"
                        placeholder="Select State"
                        :options="states"
                        />
                </div>
            </div>
            <input-field title="Lab City" v-model="lab_form.lab_city" labelClass="col-form-label col-12 col-sm-3 col-md-3 col-lg-4 text-sm-end mb-0" divClass="col-12 col-sm-9 col-md-9 col-lg-8"></input-field>
            <text-area
                title="Lab Address"
                labelClass="col-form-label col-12 col-sm-3 col-md-3 col-lg-4 text-sm-end mb-0"
                divClass="col-12 col-sm-9 col-md-9 col-lg-8"
                v-model="lab_form.lab_address"
                ></text-area>
            <input-field title="Lab Mobile No." v-model="lab_form.lab_mobile" mask="(000) 000-0000" labelClass="col-form-label col-12 col-sm-3 col-md-3 col-lg-4 text-sm-end mb-0" divClass="col-12 col-sm-9 col-md-9 col-lg-8">
            </input-field>
            <input-field title="Lab Phone No." v-model="lab_form.lab_phone" mask="(000) 000-0000" labelClass="col-form-label col-12 col-sm-3 col-md-3 col-lg-4 text-sm-end mb-0" divClass="col-12 col-sm-9 col-md-9 col-lg-8">
            </input-field>
            <input-field
                id="lab_email"
                title="Lab Email"
                v-model="lab_form.lab_email"
                labelClass="col-form-label col-12 col-sm-3 col-md-3 col-lg-4 text-sm-end mb-0" divClass="col-12 col-sm-9 col-md-9 col-lg-8"
                >
            </input-field>
         </div>
         <div class="modal-footer">
           <button
             type="button"
             class="waves-effect waves-light btn btn-danger"
             data-bs-dismiss="modal"
           >
             Cancel
           </button>
           <form-btn title="Submit" btnCls="btn-info" @click="saveNewLab"></form-btn>
         </div>
      </div>
    </div>
  </div>

  <div
    id="sendLabOrderDialog"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title font-bold m-0">Send Lab Order</h3>
        </div>
         <div class="modal-body">
            <div class="row justify-content-center">
              <div class="col-12 col-sm-12 col-md-12 col-lg-9">
                <div class="mb-15">
                  <small class="fs-12 l-h-n"><b>Note:</b> The lab is already integrated to send lab orders successfully</small>
                </div>
              </div>
            </div>
            <div class="form-group row">
                <label class="col-form-label col-12 col-sm-3 col-md-3 col-lg-4 text-sm-end mb-0">Lab Name</label>
                <div class="col-12 col-sm-9 col-md-9 col-lg-8">
                    <div class="controls d-flex">
                        <select name="select" v-model="sending_lab_id"  class="form-select form-control">
                            <option  value="" disabled="disabled">Select Lab</option>
                            <option :value="item.id" v-for="item in lab_configured_list" :key="item.id" :data-text="item.lab_name" >{{
                              item.lab_name
                            }}</option>
                        </select>
                    </div>
                </div>
            </div>
         </div>
         <div class="modal-footer">
           <button
             type="button"
             class="waves-effect waves-light btn btn-danger"
             data-bs-dismiss="modal"
            >
             Cancel
           </button>
           <form-btn title="Send" btnCls="btn-info" @click="sendLabOrder(false)"></form-btn>
         </div>
      </div>
    </div>
  </div>
  <!--document model start-->
  <div id="laborderdocument" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header">
      <h3 class="modal-title fw-900 mt-0">Reference of Knowledge Base</h3>
      </div>
      <div class="modal-body overflow-auto" style="max-height: 350px;">
      <table class="table table-bordered">
        <thead>
        <tr>
          <th scope="col">No.</th>
          <th scope="col">Knowledge Base Articles</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>1</td>
          <td> <a :href="path+'how-to-send-lab-orders-to-laboratory'" target="_blank">How To Send Lab Orders To Laboratory?</a></td>
        </tr>
        <tr>
          <td>2</td>
          <td> <a :href="path+'how-to-delete-lab-orders'" target="_blank">How To Delete Lab Orders?</a></td>
        </tr>
        <tr>
          <td>3</td>
          <td> <a :href="path+'how-to-view-lab-orders'" target="_blank">How To View Lab Orders?</a></td>
        </tr>
        <tr>
          <td>4</td>
          <td> <a :href="path+'how-to-edit-lab-orders-16-1-2023'" target="_blank">How To Edit Lab Orders?</a></td>
        </tr>
        <tr>
          <td>5</td>
          <td> <a :href="path+'how-to-give-lab-orders'" target="_blank">How To Give Lab Orders?</a></td>
        </tr>
        </tbody>
      </table>
      <Search :key="searchKey"></Search>
      </div>
      <div class="modal-footer">
      <button type="button" @click="" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
      </div>
    </div>
    </div>
  </div>
  <!--document model end-->
</template>
<script>
import SelectBox from "@/components/base/formFields/selectBox.vue";
import DatePicker from "@/components/base/formFields/datePicker.vue";
import TextArea from "@/components/base/formFields/textArea.vue";
import CheckBox from "@/components/base/formFields/checkBox.vue";
import TableBase from "@/components/base/Table.vue";
import FormBtn from "@/components/base/formFields/formBtn.vue";
import submitMixins from "@/scripts/mixins/submitMixins.js";
import useVuelidate from "@vuelidate/core";
import Autocomplete from "vue3-autocomplete";
import axios from "@/scripts/axios.js";
import permissionMixins from "@/scripts/mixins/permission.js";
import InputField from "@/components/base/formFields/InputField.vue";
import Select2 from "@/components/base/formFields/select2.vue";
import moment from 'moment';
import Popper from "vue3-popper";
import Search from "@/components/Search.vue";

import {
  helpers,
  required,
  email,
  minLength,
  maxLength,
  sameAs,
} from "@vuelidate/validators";

export default {
  mixins: [submitMixins, permissionMixins],
  setup: () => {
    return { v$: useVuelidate() };
  },
  components: {
    TableBase,
    SelectBox,
    DatePicker,
    TextArea,
    CheckBox,
    Autocomplete,
    FormBtn,
    InputField,
    Select2,
    Popper,
    Search
  },
  data() {
    return {
      key: 0,
      tabRender: 1,
      appointmentData: [],
      msg:'',
      button_hideshow :true,
      date: new Date(),
      dateFormat: 'MM-dd-yyyy',
      strict:true,
      loincCode: [],
      doclisturl:
        "json_dropdown/patient/" +
        this.$store.state.patientDetail.patient_unique_id +
        "/documents",
      loincEditCode: "",
      minDt: null,
      dynamicClass: "hide",
      patient_id: this.$store.state.patientDetail.patient_id,
      patient_unique_id: this.$store.state.patientDetail.patient_unique_id,
      output: null,
      items: {},
      count: 0,
      editMode: 0,
      Oitems: [
        {
          id: 0,
          value: "",
          editFrm: 0,
        },
      ],
      countries: [],
      states: [], 
      lab_list: [], 
      lab_configured_list: [], 
      dateFormat: 'MM-dd-yyyy',
      lab_form : {
        lab_name : '',
        lab_city : '',
        lab_address : '',
        lab_state_id : '',
        lab_zip_code : '',
        lab_country_id : '231',
        lab_mobile : '',
        lab_phone : '',
        lab_email : '',
      },
      sending_lab_id : '',
      sedning_lab_appointment_id : '',
      errorMessage : '',
      lab_sending_error_message : '',
      appt_with_lab_order : [],
      path: process.env.VUE_APP_KB_DOCUMENT,
      searchKey:0,
    };
  },
  async created() {
    axios.post("/json_autocomplete/231/state").then(
        (response) =>
          (this.states = response.data.data.map((state) => {
            return { id: state.id, text: state.name };
          }))
      );
      axios.get(`/json_dropdown/lab-master?is_configured=true`).then((response) => {
          this.lab_configured_list = response.data.data;
      });
      this.fetchLabMaster();
  },
  async beforeCreate() {
    var $this = this;
    axios
      .post('/json_dropdown/patient-appointments',{patient_id:this.$store.state.patientDetail.patient_id})
      .then((response) => {
          $this.appointmentData = response.data.data
      });

    // clear form data since, the same data is being used for add and edit both
    if (this.$route.name == "PatientDetailsLaborders") {
      this.$store.state.patientLabOrders.form = {
        primary_provider_id: this.$store.state.patientDetail.primary_provider_id,
        loinc_id: [],
        loinc_code: [],
        loinc_description: [],
        result_value: [],
        result_unit: [],
        normal_value: [],
        normal_unit: [],
        abnormal_flag: [],
        appointment_id: "",
        result_document_id: "",
        is_doctor_reviewed: 0,
        status: "Order Entered",
        date_of_testing: "",
        date_of_testing1: "",
        patient_id: this.patient_id,
        patient_unique_id: this.patient_unique_id,
        lab_address: "",
        lab_name: "",
        lab_master_id: "",
        lab_country_id: "231",
        lab_state_id: "",
        lab_zip_code: "",
        lab_mobile: "",
        lab_phone: "",
        lab_email: "",
      };
      Object.assign(this.$store.state.patientLabOrders.form);
    } else {
      this.$store.state.patientLabOrdersEdit.form = {
        abnormal_flag: "",
        normal_value: "",
        normal_unit: "",
        result_value: "",
        result_unit: "",
        loinc_code: "",
        loinc_description: "",
        appointment_id: "",
        date_of_testing: "",
        lab_result_description: "Test Value",
        is_doctor_reviewed: "",
        status: "",
        result_document_id: "",
        loinc_id: "",
        id: "",
        lab_address: "",
        lab_name: "",
        lab_master_id: "",
        lab_country_id: "231",
        lab_state_id: "",
        lab_zip_code: "",
        lab_mobile: "",
        lab_phone: "",
        lab_email: "",
      };
      Object.assign(this.$store.state.patientLabOrdersEdit.form);
    }
  },
  computed: {
    form() {
      if (this.$route.name == "PatientDetailsLabordersEdit") {
        
        return this.$store.state.patientLabOrdersEdit.form;
      } else {
        return this.$store.state.patientLabOrders.form;
      }
    },
    vuelidateExternalResults() {
      if (this.$route.name == "PatientDetailsLabordersEdit") {
        return this.$store.state.patientLabOrdersEdit.vuelidateExternalResults;
      } else {
        return this.$store.state.patientLabOrders.vuelidateExternalResults;
      }
    },
    validationRules() {
      if (this.$route.name == "PatientDetailsLabordersEdit") {
        return this.$store.state.patientLabOrdersEdit.validationRules;
      } else {
        return this.$store.state.patientLabOrders.validationRules;
      }
    },
    profileColor() {
      return {
        color: "Red",
      };
    },
  },
  mounted() {
    if (this.$route.name == "PatientDetailsLabordersEdit") {
      this.editMode = 1;
    }
    axios.get("/json_autocomplete/country").then(
      (response) =>
        (this.countries = response.data.data.map((country) => {
          return { id: country.id, text: country.name };
        }))
    );    

    $(".table_filters").hide();
    $(".check_box").click(function() {
      if ($(".table_filters").hasClass("d-block")) {
        $(".table_filters").removeClass("d-block");
      } else {
        $(".table_filters").addClass("d-block");
      }
    });

    $(".edit_results").hide();
    $(".manual_entry").hide();
  },
  methods: {
    reset() {
      this.searchKey = this.searchKey + 1;
    },
    resetLabMaster() {
      this.lab_form.lab_name = '';
      this.lab_form.lab_city = '';
      this.lab_form.lab_address = '';
      this.lab_form.lab_state_id = '';
      this.lab_form.lab_zip_code = '';
      this.lab_form.lab_country_id = '231';
      this.lab_form.lab_mobile = '';
      this.lab_form.lab_phone = '';
      this.lab_form.lab_email = '';
    },
    fillLabData(labid) {
      axios
        .get(`/lab-master/edit/`+labid)
        .then((response) => {
            this.form.lab_name = response.data.data.lab_name;
            this.form.lab_state_id = response.data.data.lab_state_id;
            this.form.lab_city = response.data.data.lab_city;
            this.form.lab_zip_code = response.data.data.lab_zip_code;
            this.form.lab_country_id = response.data.data.lab_country_id;
            this.form.lab_address = response.data.data.lab_address;
            this.form.lab_mobile = response.data.data.lab_mobile;
            this.form.lab_phone = response.data.data.lab_phone;
            this.form.lab_email = response.data.data.lab_email;
        });
    },
    saveNewLab() {
      this.errorMessage = "";
      if (!this.lab_form.lab_name) {
        this.errorMessage = "Lab name is required.";
        console.log(this.errorMessage);
      } else {
        axios
          .post(`/lab-master/store`, this.lab_form)
          .then((response) => {
              this.moshaToast('Record added successfully.', 'success');
              this.fetchLabMaster();
              $("#addNewLabMaster").modal("hide");
          })
          .catch((error) => {
            if (error.response.status === 422) {
              this.errorMessage =error.response.data.data.lab_name[0];
            } else {
              this.errorMessage = error.response.data.message;
            }
          });
      }
    },
    fetchLabMaster()
    {
      axios.get(`/json_dropdown/lab-master`).then((response) => {
            this.lab_list = response.data.data;
        });
    },
    zipcode(){
      if(this.form.lab_zip_code && this.form.lab_zip_code.length > 3 ){
        axios.post("json_dropdown/state-city-auto-populations",{zip_code:this.form.lab_zip_code})
        .then((response) => {
            this.form.lab_city=response.data.data.City;
            this.form.lab_state_id=response.data.data.id
        });
      }
      else{
        this.form.lab_city= null;
        this.form.lab_state_id=null;
      }
    },
    LabZipCode(){
      if(this.lab_form.lab_zip_code && this.lab_form.lab_zip_code.length > 3 ){
        axios.post("json_dropdown/state-city-auto-populations",{zip_code:this.lab_form.lab_zip_code})
        .then((response) => {
            this.lab_form.lab_city=response.data.data.City;
            this.lab_form.lab_state_id=response.data.data.id
        });
      }
      else{
        this.lab_form.lab_city= null;
        this.lab_form.lab_state_id=null;
      }
    },
    changeAppointment($event) {
        this.appointment_id = $event.target.value;
        this.type = $("select#appointment_id option[value='"+this.appointment_id+"']").attr("data-type");
        this.imported_date = $("select#appointment_id option[value='"+this.appointment_id+"']").attr("data-val");
        this.scheduledate = $("select#appointment_id option[value='"+this.appointment_id+"']").attr("data-id");
        if(this.type != 'undefined' &&  this.type != null && this.type != ''){
          this.imported_date = moment(String(this.imported_date)).format('YYYY-MM-DD');
          this.scheduledate  = moment(String(this.scheduledate)).format('YYYY-MM-DD');
          if(this.imported_date > this.scheduledate){
             this.msg = "You are trying to modify the data of a patient imported from another EHR. You don't have the permission to make changes in the past data import date.";
             this.button_hideshow = false;
          }
          else{
             this.msg = "";
             this.button_hideshow = true; 
          }
        }
    },
    getDate() {
      this.date_of_testing = $('#date_of_testing').val();
    },
    getDateOfTesting() {
      this.date_of_testing = $('#edit_date_of_testing').val();
    },
    FileDisplay(fileName) {
      var document_name_url = process.env.VUE_APP_API_BASE_URL+"/stream-image?path="+fileName+"&slang="+this.$store.state.auth.userData.clinic_id;
      window.open(document_name_url);
    },
    formPostSuccess(data) {
      
      this.moshaToast(
        `Record ${
          this.$route.name === "PatientDetailsLaborders" ? "added" : "updated"
        } successfully`,
        "success"
      );
      this.$store.dispatch("getSimpleTableContents", {dataType: 'patientLabOrders', patient_id: this.$store.state.patientDetail.patient_id,patient_unique_id:this.$store.state.patientDetail.patient_unique_id,method:'post'});
      this.addNewLabOrder();
    },
    scroll(element) {
      var myEl = document.getElementById(element);
      this.$smoothScroll({
        scrollTo: myEl,
      });
    },
    addNewLabOrder() {
      if ($(".manual_entry").hasClass("d-block")) {
        $(".manual_entry").removeClass("d-block");
      } else {
        $(".manual_entry").addClass("d-block");
        this.scroll("manual_entry");
      }

      axios
      .post("/patient/" + this.patient_unique_id + '/lab-order/list')
      .then((response) => {
          this.appt_with_lab_order = response.data.data.map(a => a.appointment_id);
      }) .catch((error) => {
      });
    },
    getItems(object) {
      if (object.length > 1) {
        this.$refs.autocomplete.$el.classList.add("selectloader");
        axios
          .get("/json_autocomplete/loinc-code", {
            params: { term: object },
          })
          .then((response) => {
            this.items = response.data.data;
            this.$refs.autocomplete.$el.classList.remove("selectloader");
          });
      }
      if (object.length == 0) {
        this.items = {};
      }
    },

    getItemss(object) {
      if (object.length > 1) {
        this.$refs.autocompleteArr.$el.classList.add("selectloader");
        axios
          .get("/json_autocomplete/loinc-code", {
            params: { term: object },
          })
          .then((response) => {
            this.items = response.data.data;
            this.$refs.autocompleteArr.$el.classList.remove("selectloader");
          });
      }
      if (object.length == 0) {
        this.items = {};
      }
    },
    selectItems($event, id) {
      var object = JSON.stringify($event);
      var arr = JSON.parse(object);
      if (this.$route.name === "PatientDetailsLabordersEdit") {
        this.$store.state.patientLabOrdersEdit.form.loinc_id = arr["id"];
        this.$store.state.patientLabOrdersEdit.form.loinc_description =
          arr["name"];
        this.$refs.autocomplete.searchText = arr["loinc_num"];
        this.loincEditCode = arr["loinc_num"];
      } else {
        this.$store.state.patientLabOrders.form.loinc_id.push(arr["id"]);
        this.$store.state.patientLabOrders.form.loinc_description.push(
          arr["name"]
        );
        let el1 = document.getElementById("lbl_" + id);
        el1.innerText = arr["name"];

        this.loincCode.push(arr["loinc_num"]);
        this.$refs.autocompleteArr.searchText = arr["loinc_num"];
      }
    },
    save() {
      var date_of_testing = this.date_of_testing;
     
      if(date_of_testing != '') {
        this.$store.state.patientLabOrdersEdit.form.date_of_testing = moment(String(date_of_testing)).format('YYYY-MM-DD');
        this.form.date_of_testing = moment(String(date_of_testing)).format('YYYY-MM-DD');
      } else {
        this.$store.state.patientLabOrdersEdit.form.date_of_testing = '';
        this.form.date_of_testing = '';
      }
      
      if (this.$route.name === "PatientDetailsLabordersEdit") {
        this.$store.state.patientLabOrdersEdit.form.loinc_code = this.loincEditCode;
        this.postForm(
          "patient/" + this.patient_unique_id + "/lab-order/update",
          "patientLabOrdersEdit"
        );
      } else {
        this.$store.state.patientLabOrders.form.loinc_code = this.loincCode;
        this.postForm(
          "patient/" + this.patient_unique_id + "/lab-order/store",
          "patientLabOrders"
        );
      }
    },
    close() {
      $(".manual_box").trigger("click");
    },
    closeUpdate() {
      this.dynamicClass = "hide";
    },
    addNew($event) {
      var count = ++this.count;
      this.Oitems.push({
        id: `${count}`,
        value: "",
        editFrm: 0,
      });

    },
    viewLaborder($id) {
      this.editMode = 1;
      this.$router.push({
        name: "PatientDetailsLabordersEdit",
        params: { patient_unique_id: this.patient_unique_id, id2: $id },
      });
      this.scroll("lab_order_list");
    },
    labSendingDialog(lab_appointment_id) {
      this.sedning_lab_appointment_id = lab_appointment_id;
      $('#sendLabOrderDialog').modal("show");
    },
    sendLabOrder() {
        if (!this.sending_lab_id) {
          this.lab_sending_error_message = 'Please select lab.';
          return false;
        } else {
          this.$store.state.loader = true;
          axios
          .post("/patient/" + this.patient_unique_id + '/lab-order/send-to-labgen',{patient_id : this.$store.state.patientDetail.patient_id, appointment_id : this.sedning_lab_appointment_id, lab_master_id : this.sending_lab_id })
          .then((response) => {
            this.$store.state.loader = false;
            $('#sendLabOrderDialog').modal("hide");
            this.moshaToast(response.data.message, "success");
            this.tabRender++;
          }) .catch((error) => {
            this.$store.state.loader = false;
            this.moshaToast(error.response.data.message, "danger");
          });
        }
    },
    editBox($id) {
    
      var $this = this;
      this.dynamicClass = "d-block";
      axios
        .get("/patient/" + this.patient_unique_id + "/lab-order/edit/" + $id)
        .then((response) => {
         
          ($this.$store.state.patientLabOrdersEdit.form = response.data.data),
            (this.$refs.autocomplete.searchText = this.$store.state.patientLabOrdersEdit.form.loinc_code);
          this.loincEditCode = this.$store.state.patientLabOrdersEdit.form.loinc_code;
          if(this.form.date_of_testing1 != null){
           
            this.date_of_testing1 = moment(String(this.form.date_of_testing)).format('MM-DD-YYYY');
          }
          this.scroll("edit_results_div");
          

          if (this.$store.state.patientLabOrdersEdit.form.date_of_testing1 != '') {
            this.form.date_of_testing1 = moment(String(this.$store.state.patientLabOrdersEdit.form.date_of_testing)).format('MM-DD-YYYY');
            this.date_of_testing1 = moment(String(this.$store.state.patientLabOrdersEdit.form.date_of_testing)).format('MM-DD-YYYY');
          }
        });
      this.getAppointmentDate("appointment_id1");
    },
    remove($id) {
      if (this.$route.name === "PatientDetailsLabordersEdit") {
        this.$swal
          .fire({
            title: '<h2 class="mt-0">Are you sure?</h2>',
            text:
              "You want to delete these records? This process cannot be undone.",
            icon: "warning",
            dangerMode: true,
            showCancelButton: true,
            confirmButtonColor: "#ee3158",
            cancelButtonColor: "#bdbdbd",
            confirmButtonText: "Yes, delete it!",
          })
          .then((result) => {
            if (result.isConfirmed == true) {
              axios
                .post(
                  "patient/" + this.$route.params.id + "/lab-order/delete",
                  { id: $id }
                )
                .then((response) => {
                  this.$swal
                    .fire("Deleted!", "Laborder has been deleted.", "success")
                    .then((result) => {
                      if (result.isConfirmed == true) {
                        this.key++;
                      }
                    });
                });
            }
          });
      } else {
        $("#tr_" + $id).remove();
      }
    },
    getAppointmentDate(id = "appointment_id") {
      let vm = this;
      setTimeout(function() {
        var app = $("#" + id + " :selected").text();
        var data = app.split(" ");
        var dt = data[0];
        var newDat = vm.$filters.inputDateTime(dt, "YYYY-MM-DD");
        vm.minDt = newDat;
      }, 1000);
    },
    countryChange({ id, text }) {
      axios.post("/json_autocomplete/" + id + "/state").then(
        (response) =>
          (this.states = response.data.data.map((state) => {
            return { id: state.id, text: state.name };
          }))
      );
    },
  },
  validations() {
    return this.$store.state.patientLabOrders.validationRules;
  },
};
</script>
