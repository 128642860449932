<template>
    <div class="tab-content">
        <div class="text-center" v-if="choose_slot_loader"><img src="images/loader.gif" class="mt-50 img-fluid" style="width:50px;"></div>
        <div class="table-responsive" v-else>
            <table class="table table-bordered mb-0">
                <thead>
                    <tr>
                        <th style="min-width:30px;">No</th>
                        <th style="min-width:50px">Changed by</th>
                        <th style="min-width:50px">Operation</th>
                        <th style="min-width:120px;">Details</th>
                        <th style="min-width:50px">Log Updated At</th>
                        <th style="min-width:100px">IP Address</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        class="hover-primary"
                        v-for="(item, index) in auditlog"
                        :key="index"
                    >
                        <td class="align-top">{{index+1}}</td>
                        <td class="align-top">{{ user_list[item.user_id] }}</td>
                        <td class="align-top">{{ item.action }}</td>
                        <td class="word-break-all align-top" v-html="item.detail"></td>
                        <td class="align-top" :data-order='item.updated_at_timestamp'>{{ this.displayDateTime(item.updated_at, "MM-DD-Y, h:mm A") }}</td>
                        <td class="align-top">{{ item.ip }}</td>
                    </tr>
                </tbody>
            </table>
            <div class="text-center mt-2">
                <button @click="closeModel" class="waves-effect waves-light btn btn-danger mb-5 me-1">Close</button>
            </div>
        </div>
       
    </div>
</template>
<script>
import "datatables.net-dt/js/dataTables.dataTables";
import FormBtn from "@/components/base/formFields/formBtn.vue";
import axios from "@/scripts/axios.js";
import submitMixins from "@/scripts/mixins/submitMixins.js";
import moment from "moment";
export default {
     mixins: [submitMixins],
    components: {
		    FormBtn,    
		
	    },
        props: {
            patient_id: null,
		    appointment_id: null,
		    recurring_id: null,
            is_inline_nav : {
                type: Boolean,
                default: false,
                required: false
            },
        },
        data(){
            return{
                auditlog:'',
                user_list: [],
                choose_slot_loader: false,
            }
        },
        mounted() {
           this.choose_slot_loader=true;
           var index=0;
           if(index==0){
             axios.post("appointment/audit-logs",{appointment_id:this.appointment_id,recurring_id:this.recurring_id})
                .then((response) => {
                    this.choose_slot_loader=false;
                    this.auditlog=response.data.data.audit_log;
                    this.user_list = response.data.data.user_list;
                });
                index++;
           }
           
        },
        methods:{
            closeModel(){
                if(this.is_inline_nav == true){
                    this.$store.state.PaymentGatewayMigration.hidemodal =true;
                    this.$store.state.MainInsurnceBilling.hidemodal =true;
                }
                $(".e-footer-content").find(".e-event-cancel").trigger("click");
            },
            displayDateTime(date, format = "ddd, MM-DD-YYYY h:mm A" , timezone) {
                if (date) {
                    let dt_format = format ? format : "ddd, MM-DD-YYYY hh:mm A";
                    var datatime=moment(date);
                    var timedata=datatime.local().format(dt_format);
                    return  timedata;
                } else {
                return "";
                }
            },
        }
}
</script>