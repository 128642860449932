<template>
	<div class="content-wrapper">
		<div class="container-full">
			<section class="content">
				<div class="row justify-content-sm-center">
					<div class="col-12 col-sm-12 col-md-12 col-lg-8">
						<div class="row align-items-center">
						  	<div class="col-12 col-sm-6 col-md-6 col-lg-6">
						   		<h1 class="font-bold my-10">Macro Note Settings
						   			<Popper arrow content="Macros are used to automatically populate the most frequently used phrase and pull the data into the clinical notes." :hover="true" class="helptips cursor-pointer fs-14">
										<i class="fa fa-info-circle fs-16"></i>
									</Popper>
						   		</h1>
							</div>
							<div class="col-12 col-sm-6 col-md-6 col-lg-6 text-sm-end">
						   		<a href="#" class="waves-effect waves-light btn btn-info" @click="getData($event)"><i class="fa fa-plus fs-14"></i> New</a>
							</div>
						</div>
						<div class="box mt-10">
							<div class="box-body">
								<div class="mb-10 macrodiv">
									<a href="#" v-for="item in list" class="" @click="getData($event,item.id)"><i class="fa fa-file-text-o" aria-hidden="true"></i> {{ item.macro_title }}</a>
								</div>

								<input-field divClass="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" labelClass="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-form-label" id="macro_title" title="Macro Title" v-model="form.macro_title"> </input-field>

								<div class="jumbotron">
									<text-area id="mac_desc" title="Macro Description" divClass="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" labelClass="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-form-label" v-model="form.macro_text" rows="6"> </text-area>

									<div class="pre_defined_lbl">
										<a href="#" @click="getPredefineWord('patient_name')"><i class="fa fa-user"></i> Patient Name</a>
										<a href="#" @click="getPredefineWord('service_date')"><i class="fa fa-calendar"></i> Service Date</a>
										<a href="#" @click="getPredefineWord('service_date_time')"><i class="fa fa-clock-o"></i> Service Date & Time</a>
									</div>
								</div>

								<div class="form-group row">
									<label class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-form-label">Macro Status <small class="text-danger asterisksign" style="display: none;">*</small></label>
									<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
										<div class="controls">
											<select class="form-control" v-model="form.status">
												<option value=''>Select Status</option>
												<option value='1'>Active</option>
												<option value='0'>Inactive</option>
												<option value='d'>Delete</option>
											</select>
										</div>
									</div>
								</div>

								<div class="text-center">
									<form-btn title="Save" btnCls="waves-effect waves-light btn btn-info" @click="save(false)"></form-btn>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	</div>
</template>


<script>
import axios from "@/scripts/axios.js";
import InputField from "@/components/base/formFields/InputField.vue";
import SelectBox from "@/components/base/formFields/selectBox.vue";
import TextArea from "@/components/base/formFields/textArea.vue";
import FormBtn from "@/components/base/formFields/formBtn.vue";
import submitMixins from "@/scripts/mixins/submitMixins.js";
import Popper from "vue3-popper";

export default {
	mixins: [submitMixins],
	components: {
		FormBtn,
		InputField,
		SelectBox,
		TextArea,
		Popper
	},
	data() {
		return {
			enabled: true,
			list: [],
		};
	},
	mounted(){
		this.getList();
	},
	async beforeCreate() {
		this.$store.state.macrosettings.form = {};
		Object.assign(
			this.$store.state.macrosettings.form,
			this.$store.state.macrosettings.defaultFormData
			);
	},
	computed: {
		form() {
			return this.$store.state.macrosettings.form;
		}
	},
	methods: {
		save(){
			this.postForm("/macros/save","",false);
		},
		formPostSuccess(data) {
			this.moshaToast(`Record saved successfully`,"success");
			if(this.$store.state.macrosettings.form.status == 'd'){
				this.$store.state.macrosettings.form = {};
			}
			this.getList();
		},
		getList(){
			axios.get("/macros/list")
			.then((response) => {
				this.list = response.data.data
			});
		},
		getData($event, id=''){
			$($event.target).removeClass("macro_normal");
			$($event.target).siblings().removeClass("macro_active");
			$($event.target).addClass("macro_active");

			/*if($event!=''){
				$($event).target.addClass("badge-info");
				$($event).target.addClass("font-bold");
			}*/
			if(id!=''){
				axios.post("/macros/edit",{id:id})
					.then((response) => {
						this.$store.state.macrosettings.form =  response.data.data[0];
					});
			}
			else{
				
				this.$store.state.macrosettings.form = {};
			}
		},
		getPredefineWord(word){
			var replced_word = "##"+word+"##";
			var txt = $.trim($(".box-body .form-group textarea").val());

			var pos = document.getElementById("mac_desc").selectionStart;
			console.log(pos);
			if(txt){
				var newTxt = txt.slice(0,pos)+replced_word+txt.slice(pos);
				$("textarea#mac_desc").val(newTxt);
				this.$store.state.macrosettings.form.macro_text = newTxt;
			}else{
				$(".box-body .form-group textarea").val(replced_word);
				this.$store.state.macrosettings.form.macro_text = replced_word;
			}
		}
	}
};
</script>