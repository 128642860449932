<template>
    <div>
        <div class="mdc-tab" v-if="this.category.length>0" >
            <ul class="nav nav-tabs mt-0 text-center text-sm-start" id="tabs" role="tablist" style="margin:0">
                <li class="nav-item" v-for="(item, index) in this.category" :key="index"> <a class="nav-link" data-bs-toggle="tab" :class="isActive(index)" href="#healthCareBilling" @click.prevent="changeTab(item)" role="tab">{{item}}</a> </li>
            </ul>
        </div>
        <div v-else>
            <div class="box">
                <div class="box-body">
                    <h3 class="text-center m-0">No record found of filled Questionnaire.</h3>
                </div>
            </div>
        </div>

        <div class="box">
            <div class="box-body">
                <div id="noteDetail">
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "@/scripts/axios.js";


export default {
    components: {
    },
    data(){
        return{ 
            category:[],
            json_data:[],
            content:{},
            json_detail_data: {},
            data:{}
        };
    },
    mounted(){
        axios.get("patient/"+this.$store.state.patientDetail.patient_id +"/getQuestionnarie",{
        }).then(response => { 

                var data = response.data.data
                var firstCat = '';
                for (const key in data) {
                    if (Object.hasOwnProperty.call(data, key)) {
                        const element = data[key];
                        this.category.push(element.name);
                        this.json_data.push(element.template_json);
                        this.json_detail_data[element.name] = element.note_field_json
                        this.content[element.name] = element.template_json;
                        if(key == 0)
                            firstCat = element.name
                    }
                }
                let $this = this;
                setTimeout(function () {
                    $this.changeTab(firstCat);
                }, 100);
        });
    },
    methods: {
        async changeTab (category) {
            let $this = this;
            var jsonData = $.parseJSON(this.content[category]);   
            $this.data = this.json_detail_data[category];
            $this.data= ( $this.data!='') ? JSON.parse( $this.data) : []
            await Formio.createForm(
                document.getElementById("noteDetail"), jsonData
                )
                .then(
                    function(form) {
                        var arr = {};
                        jQuery.map( $this.data, function( value, index ) {
                            if (value=="true"||value=="false") {
                                 arr[index] = JSON.parse(value);
                                
                            }else{
                               arr[index] = value;
                            }
                            
                        });
                        form.submission = {
                            data: arr
                        } 
                    }
                )

            $("button.btn-info").addClass("d-none");
            $(".form-check-input").attr("disabled", true);
            $("input.form-control, textarea").attr("readonly", true);
        },
        isActive(menuname) {
            if (this.routefrom==undefined) {
                this.routefrom=0;
            }
            return menuname==this.routefrom ? 'active' : '';
        }
    }
}
</script>

<style src="@vueform/toggle/themes/default.css"></style>