<template>
	<div class="content-wrapper">
		<div class="container-full">
			
			<div class="tab-content">
				<div class="tab-pane active" id="openTicket" role="tabpanel">
				<section class="content">
					<div class="box">
						<div class="box-body">
							<div class="table-responsive">
								<data-table dataType="supportIndex" :key="render">
									<template v-slot="slotProps">
										<td style="width:50%;"><a :href="'#/support/'+slotProps.item.master_id+'/threads'" class="fw-500"> {{ slotProps.item.subject }} </a></td>
										<td v-if="slotProps.item.status == 'Client-Replied'"><span v-bind:class="BindThreadStatus(slotProps.item.status)" >You Replied</span></td>
										<td v-else><span v-bind:class="BindThreadStatus(slotProps.item.status)">{{ slotProps.item.status }}</span></td>
										<td>{{ formatDate(slotProps.item.time, "DD/MM/YYYY hh:mm a")  }}</td>
									</template>
								</data-table>
							</div>
						</div>
					</div>
				</section>
				</div>
			</div>	
			
		</div>
	</div>
</template>

<script>
	import dataTable from "@/components/base/DataTable.vue";
	import axios from "@/scripts/axios.js";
	import Popper from "vue3-popper";
	import TabbarComp from "@/components/Help/HelpTabbar.vue";
	import moment from "moment";

	export default {

		components: { dataTable, Popper,TabbarComp },
		data() {
			return {
				render: 0,
			};
		},
		methods: {
			BindThreadStatus: function(status){
				if(status=="New"){
					return "badge  badge-success font-bold fs-14";
				}
				if(status=="Answered"){
					return "badge badge-info font-bold fs-14";
				}if(status=="Client-Replied"){
					return "badge badge-secondary font-bold fs-14";
				}if(status=="Opened"){
					return "badge badge-warning font-bold fs-14";
				}
			},
			formatDate(date, format) {
				return moment(date).format(format);
			},

		},
	};
</script>