<template>
    <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-10">
        <div class="box mb-10">
            <div class="box-body">
                <div class="pageheading">
                    <h2 class="heading2 font-bold">Appointment Insurance Billing Log</h2>
                </div>
                <div v-if="$route.params.appointments_id!=undefined && $route.params.appointments_id!=null">
					<AppointmentInsuranceBadge :AppointmentNumber="$route.params.appointments_id" />
				</div>
                <InsuranceBillingLog :patient_id="$route.params.id" :appointment_id="this.$route.params.appointments_id" />
            </div>
        </div>
    </div>
</template>
<script>
import AppointmentInsuranceBadge from "@/components/Appointments/AppointmentInsuranceBadge.vue";
import InsuranceBillingLog from "@/components/patients/details/PatientInformation/InsuranceBillingLog.vue"
import axios from "@/scripts/axios.js";
export default {
    data() {
        return {
        };
    },
    components: { InsuranceBillingLog, AppointmentInsuranceBadge },
    mounted() {
        
    },
    methods:{        
    }
}
</script>

<style>

</style>