import {
  helpers,
  required,
} from "@vuelidate/validators";

const officeLab = {
  namespaced: true,

  state: {
    
    form: {
      lab_id: [],
      accession_no: [],
      },
    vuelidateExternalResults:{
      form: {
        lab_id: [],
        accession_no: [],
      }
    },
    validationRules: {
      form: {
        lab_id: {
          required: helpers.withMessage("Lab Name is required", required),
        },
        accession_no: {
          required: helpers.withMessage("Account Number is required", required),
        },
      }
    }
  },
  getters: {
    getTableHeaders: (state) => state.header,
    getTableContents: (state) => state.list,
    getListURL: (state) => state.listUrl,
  },
  mutations: {},
  actions: {},
};

export default officeLab;
