<template>
  <div class="form-group row" :class="{ error: errors.length }">
    <label :class="labelClass"
      >{{ title }}
      <small v-show="isRequired" class="text-danger asterisksign"
        >*</small
      ></label
    >
    <div :class="divClass">
      <div class="controls">
        <input
          :id="title"
          type="number"
          :name="title"
          class="form-control"
          :value="modelValue"
          @input="$emit('update:modelValue', $event.target.value)"
          :placeholder="placeholder1"
          min="0"
        />
        <span class="form-text text-muted" v-if="notes != ''">{{ notes }}</span>
      </div>

      <div class="error-msg">
        <ul class="mb-0 p-0 fs-14">
          <li class="d-block" v-for="(error, index) of errors" :key="index">
            {{ error.$message }}
          </li>
        </ul>
      </div>
    </div>
    <div
      :class="noteClass"
      v-show="note != ''"
      style="{vertical-align:-webkit-baseline-middle}"
    >
      <small class="align-sub">{{ note }}</small>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    modelValue: {
      type: String,
      default: "",
    },
    errors: {
      type: Array,
      default: [],
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    noteClass: {
      type: String,
      default: "col-12 col-sm-12 col-md-10 col-lg-4 text-sm-center text-lg-start",
    },
    note: {
      type: String,
      default: "",
    },
    labelClass: {
      type: String,
      default:
        "col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end",
    },
    divClass: {
      type: String,
      default: "col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3",
    },
    notes: {
      type: String,
      default: "",
    },
    mask: {
      type: String,
      default: null,
    },
  },
  computed: {
    placeholder1() {
      return this.placeholder ? this.placeholder : this.title;
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
