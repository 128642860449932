<template>
    <div class="panel pagecollap">
        <div class="panel-heading" id="summarycareprovided" role="tab">
            <a class="panel-title collapsed font-bold" aria-controls="answer-2" aria-expanded="false" data-bs-toggle="collapse" href="#answer-2" data-parent="#accordion2">Assessment</a>
        </div>
        <div class="panel-collapse collapse" id="answer-2" aria-labelledby="summarycareprovided" role="tabpanel" data-bs-parent="#category-1" style="">
            <div class="panel-body">
                <div class="text-end" v-if="permissioncheck(this.$store.state.permission.create_update)">
                    <a href="javascript:void(0);" class="waves-effect waves-light btn btn-info mb-10 interventionbtn" @click="create"><i class="fa fa-plus fs-14"></i> Assessment</a>
                </div>
                <div class="table-responsive">
                    <div class="table-responsive" id="assessment_list">
                        <table-base 
                            :dataTypes="{
                                dataType: 'Assessment',
                                patient_id: patient_id,
                                patient_unique_id:patient_unique_id,
                                method:'post'}"
                            :lastcol='permissioncheck(this.$store.state.permission.create_update)'>
                            <template v-slot="slotProps">
                               <td>{{ slotProps.item.loince_code }}</td>
                               <td>{{ slotProps.item.loince_description}}</td>
                               <td>{{ slotProps.item.snomed_code }}</td>
                               <td>{{ slotProps.item.snomed_description}}</td>
                               <td>{{ $filters.displayDateTimeLocal(slotProps.item.observation_date,'',false,"ddd, MM-DD-YYYY",true) }}</td>
                               <td>{{ slotProps.item.negation}}</td>
                               <td>
                                    <div class="d-flex" v-if="permissioncheck(this.$store.state.permission.create_update)">
                                        <Popper arrow content="Edit Assessment" :hover="true" class="helptips cursor-pointer">
                                            <a href="javascript:void(0)" @click="editCQMAssessment(slotProps.item.id)" class="waves-effect waves-circle btn btn-circle btn-info btn-xs" ><i class="fa fa-pencil"></i></a>
                                        </Popper>
                                        <Popper arrow content="Delete Assessment" :hover="true" class="helptips cursor-pointer">
                                            <a href="javascript:void(0)" @click="removeCQMAssessment(slotProps.item.id)" class="waves-effect waves-circle btn btn-circle btn-danger btn-xs" ><i class="fa fa-trash"></i></a>
                                        </Popper>
                                    </div>
                                </td>
                            </template>
                        </table-base>
                    </div>
                </div>
            </div>

            <div class="box bg-lightest new-assessment d-none" id="new-assessment">
                <div class="box-body">
                    <div class="content-header">
                        <h3 class="heading3 font-bold m-0">{{title}}</h3>
                    </div>
                    <div class="form-group row" :class="{ error: v$.form.loince_code.$errors.length }">
                        <label class="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end">Assessment <small class="text-danger asterisksign">*</small></label>
                        <div class="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-3">
                            <div class="selectcontrols ">
                                <Autocomplete
                                    @input="loinceCode"
                                    ref="loinceCode"
                                    :results="LoinceCodeList"
                                    @onSelect="loinceCodevalue"
                                    class="form-control"
                                    :value="modelValue"
                                    placeholder="Search LOINC Code"
                                    :use-html-for-results ="true"
                                ></Autocomplete>
                            </div>
                            <div class="error-msg" v-if="v$.form.loince_code.$errors.length > 0">
                                <ul class="mb-0 p-0 fs-14">
                                    <li class="d-block" v-for="(error, index) of v$.form.loince_code.$errors">
                                        {{ error.$message }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row" :class="{ error: v$.form.loince_description.$errors.length }">
                        <label class="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end">Assessment Description <small class="text-danger asterisksign">*</small></label>
                        <div class="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-3">
                            <div class="selectcontrols ">
                                <Autocomplete
                                    @input="loincedescription"
                                    ref="loincedescription"
                                    :results="loincedescriptionList"
                                    @onSelect="loincedescriptionvalue"
                                    class="form-control"
                                    :value="modelValue"
                                    placeholder="Search LOINC Description"
                                    :use-html-for-results ="true"
                                ></Autocomplete>
                            </div>
                            <div class="error-msg" v-if="v$.form.loince_description.$errors.length > 0">
                                <ul class="mb-0 p-0 fs-14">
                                    <li class="d-block" v-for="(error, index) of v$.form.loince_description.$errors">
                                        {{ error.$message }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    
                    <div class="form-group row" :class="{ error: v$.form.snomed_code.$errors.length }">
                        <label class="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end">Assessment Result<small class="text-danger asterisksign">*</small></label>
                        <div class="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-3">
                            <div class="selectcontrols ">
                                <Autocomplete
                                    @input="inputCode"
                                    ref="autocompletecode"
                                    :results="CodeList"
                                    @onSelect="codevalue"
                                    class="form-control"
                                    :value="modelValue"
                                    placeholder="Search SNOMED Code"
                                    :use-html-for-results ="true"
                                ></Autocomplete>
                            </div>
                            <div class="error-msg" v-if="v$.form.snomed_code.$errors.length > 0">
                                <ul class="mb-0 p-0 fs-14">
                                    <li class="d-block" v-for="(error, index) of v$.form.snomed_code.$errors">
                                        {{ error.$message }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row" :class="{ error: v$.form.snomed_description.$errors.length }">
                        <label class="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end">Assessment Result Description <small class="text-danger asterisksign">*</small></label>
                        <div class="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-3">
                            <div class="selectcontrols ">
                                <Autocomplete
                                    @input="inputdescription"
                                    ref="autocompletedescription"
                                    :results="CodeLists"
                                    @onSelect="descriptionvalue"
                                    class="form-control"
                                    :value="modelValue"
                                    placeholder="Search SNOMED Description"
                                    :use-html-for-results ="true"
                                ></Autocomplete>
                            </div>
                            <div class="error-msg" v-if="v$.form.snomed_description.$errors.length > 0">
                                <ul class="mb-0 p-0 fs-14">
                                    <li class="d-block" v-for="(error, index) of v$.form.snomed_description.$errors">
                                        {{ error.$message }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row" :class="{ error: v$.form.observation_date.$errors.length }">
                        <label class="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end">Observation Date <small class="text-danger asterisksign">*</small></label>
                        <div class="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-3 availdate">
                            <!-- <input
                                v-model="form.observation_date"
                                class="form-control"
                                type="date"
                                id="example-date-input"
                                :max="current_date"
                            /> -->
                            <ejs-datepicker 
                                :value="observation_date" 
                                id="observation_date_assessment" 
                                :format="dateFormat" 
                                placeholder="Observation Date"
                                @change="getdate" 
                                :strictMode='strict' 
                                :max='maxVal' 
                                :showClearButton='false' 
                                :blur="getdate"
                                :openOnFocus='true'>
                            </ejs-datepicker>
                            <div class="error-msg" v-if="v$.form.observation_date.$errors.length > 0">
                                <ul class="mb-0 p-0 fs-14">
                                    <li class="d-block" v-for="(error, index) of v$.form.observation_date.$errors">
                                        {{ error.$message }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-3 form-label mb-0 text-sm-end">Negation</label>
                        <div class="col-6 col-sm-8 col-md-8 col-lg-5 col-xl-3">
                            <input
                              type="checkbox"
                              id="ass_negation_checkbox"
                              :checked="form.is_negation"
                              v-model="form.is_negation"
                              :value="form.is_negation"
                              class="filled-in chk-col-info"
                              @change="checkNegation"
                            />
                            <label for="ass_negation_checkbox" class="m-0 text-start"></label>
                        </div>
                    </div>
                    <div class="form-group row" v-if="form.is_negation">
                        <label class="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end">Negation Reason</label>
                        <div class="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-3">
                            <select class="form-control" v-model="form.negation">
                                <option value="" selected="selected" disabled="disabled" >Select Negation Reason</option>
                                <option v-for="(item, index) in negation" :key="index" :value="item.name">{{item.name}}</option>
                            </select>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-9 text-center">
                            <form-btn
                                :title="btn"
                                btnCls="waves-effect waves-light btn btn-info"
                                @click="save(false)"
                            ></form-btn>
                            <button @click="close" type="button" class="waves-effect waves-light btn btn-danger">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import TableBase from "@/components/base/Table.vue";
import useVuelidate from "@vuelidate/core";
import submitMixins from "@/scripts/mixins/submitMixins.js";
import permissionMixins from "@/scripts/mixins/permission.js";
import Autocomplete from "vue3-autocomplete";
import InputField from "@/components/base/formFields/InputField.vue";
import FormBtn from "@/components/base/formFields/formBtn.vue";
import axios from "@/scripts/axios.js";
import moment from "moment";
import Popper from "vue3-popper";
export default {
    setup: () => {
        return { v$: useVuelidate() };
    },
    mixins: [submitMixins, permissionMixins],
    components: { 
        TableBase,
        Autocomplete,
        InputField,
        FormBtn,
        Popper,
    },
    computed: {
        form() {
            return this.$store.state.Assessment.form;
        },
        vuelidateExternalResults() {
            return this.$store.state.Assessment.vuelidateExternalResults;
        },
        validationRules() {
            return this.$store.state.Assessment.validationRules;
        },
    },
    validations() {
        return this.$store.state.Assessment.validationRules;
    },
    data() 
    {
        return {
            CodeList:[],
            CodeLists:[],
            current_date:'',
            patient_id : this.$store.state.patientDetail.patient_id,
            patient_unique_id:this.$store.state.patientDetail.patient_unique_id,
            dataTypes: {
                dataType: "Assessment",
                patient_id: this.$store.state.patientDetail.patient_id,
                patient_unique_id: this.$store.state.patientDetail.patient_unique_id,
            },
            negation:{},
            title:'Create Assessment',
            btn:'Save',
            LoinceCodeList:{},
            loincedescriptionList:{},
            strict: true,
            maxVal: new Date(),
            dateFormat: 'MM-dd-yyyy',
        };
    },
    methods:{
        getdate(){
            if($('#observation_date_assessment').val() == ''){
                this.form.observation_date = null;
              }else{
                this.observation_date = $('#observation_date_assessment').val();
                this.form.observation_date = moment(String(this.observation_date)).format('YYYY-MM-DD');
              }
        },
        create(){
            $("#new-assessment").removeClass("d-none");
            this.$store.state.Assessment.form = {};
            Object.assign(this.$store.state.Assessment.form);
            this.v$.$reset();
            this.$refs.loinceCode.searchText = "";
            this.$refs.loincedescription.searchText = "";
            this.$refs.autocompletecode.searchText = "";
            this.$refs.autocompletedescription.searchText = "";
            this.observation_date = "";
            this.title = 'Add Assessment';
            this.$smoothScroll({
                scrollTo: document.getElementById("new-assessment"),
            });
        },
        loinceCode(obj) {
            if (obj.length > 1) {
                this.$refs.loinceCode.$el.classList.add("selectloader");
                axios
                .get("/json_autocomplete/loinc-code-with-data-element", {
                    params: { term: obj,data_element:'assessment' },
                })
                .then((response) => {
                    //this.LoinceCodeList = response.data.data;
                    this.LoinceCodeList = response.data.data.map((LoinceCode) => {
                      return { id: LoinceCode.id, name: "<b>"+LoinceCode.code+"</b><br>"+LoinceCode.description,code:LoinceCode.code,description:LoinceCode.description }
                    });
                    this.$refs.loinceCode.$el.classList.remove("selectloader");
                });
            }   
            if (obj.length == 0) {
                this.LoinceCodeList = {}; 
                this.form.loince_code = '';
                this.form.loince_description = '';
                this.$refs.loinceCode.searchText = '';
                this.$refs.loincedescription.searchText = '';
            }
        },
        loinceCodevalue(obj){
            this.form.loince_code = obj.code
            this.form.loince_description = obj.description
            this.$refs.loinceCode.searchText = obj.code
            this.$refs.loincedescription.searchText = obj.description
            this.$emit("update:modelValue", obj.code);
        },
        loincedescription(obj) {
            if (obj.length > 1) {
                this.$refs.loincedescription.$el.classList.add("selectloader");
                axios
                .get("/json_autocomplete/loinc-code-with-data-element", {
                    params: { term: obj,data_element:'assessment' },
                })
                .then((response) => {
                    //this.loincedescriptionList = response.data.data;
                    this.loincedescriptionList = response.data.data.map((loincedescription) => {
                      return { id: loincedescription.id, name: "<b>"+loincedescription.code+"</b><br>"+loincedescription.description,code:loincedescription.code,description:loincedescription.description }
                    });
                    this.$refs.loincedescription.$el.classList.remove("selectloader");
                });
            }   
            if (obj.length == 0) {
                this.loincedescriptionList = {};
                this.form.loince_code = '';
                this.form.loince_description = '';
                this.$refs.loinceCode.searchText = '';
                this.$refs.loincedescription.searchText = '';    
            }
        },
        loincedescriptionvalue(obj){
            this.form.loince_code = obj.code
            this.form.loince_description = obj.description
            this.$refs.loinceCode.searchText = obj.code
            this.$refs.loincedescription.searchText = obj.description
            this.$emit("update:modelValue", obj.code);
        },
        inputCode(obj) {
            if (obj.length > 1) {
                this.$refs.autocompletecode.$el.classList.add("selectloader");
                axios
                .get("/json_autocomplete/snomed-code-with-data-element", {
                    params: { term: obj,data_element:'assessment_result' },
                })
                .then((response) => {
                    //this.CodeList = response.data.data;
                    this.CodeList = response.data.data.map((codelist) => {
                        return { id: codelist.id, name: "<b>"+codelist.code+"</b><br>"+codelist.description,code:codelist.code,description:codelist.description }
                    });
                    this.$refs.autocompletecode.$el.classList.remove("selectloader");
                });
            }   
            if (obj.length == 0) {
                this.CodeList = {};    
                this.$refs.autocompletecode.searchText = '';
                this.$refs.autocompletedescription.searchText = '';
                this.form.snomed_code = '';
                this.form.snomed_description = '';
            }
        },
        inputdescription(obj) {
            if (obj.length > 1) {
                this.$refs.autocompletedescription.$el.classList.add("selectloader");
                axios
                .get("/json_autocomplete/snomed-code-with-data-element", {
                    params: { term: obj,data_element:'assessment_result' },
                })
                .then((response) => {
                    //this.CodeLists = response.data.data;
                    this.CodeLists = response.data.data.map((Codedec) => {
                        return { id: Codedec.id, name: "<b>"+Codedec.code+"</b><br>"+Codedec.description,code:Codedec.code,description:Codedec.description }
                    });
                    this.$refs.autocompletedescription.$el.classList.remove("selectloader");
                });
            }   
            if (obj.length == 0) {
                this.CodeLists = {};
                this.$refs.autocompletecode.searchText = '';
                this.$refs.autocompletedescription.searchText = '';
                this.form.snomed_code = '';
                this.form.snomed_description = '';
            }
        },
        codevalue(obj){
            this.form.snomed_code = obj.code
            this.form.snomed_description = obj.description
            this.$refs.autocompletecode.searchText = obj.code
            this.$refs.autocompletedescription.searchText = obj.description
            this.$emit("update:modelValue", obj.code);
        },
        descriptionvalue(obj){
            this.form.snomed_code = obj.code
            this.form.snomed_description = obj.description
            this.$refs.autocompletecode.searchText = obj.code
            this.$refs.autocompletedescription.searchText = obj.description
            this.$emit("update:modelValue", obj.code);  
        },
        editCQMAssessment(id){
            this.$store.state.Assessment.edit = 1;
            this.title = 'Edit Assessment';
            this.btn = 'Update';
            axios.get("patient/" + this.$route.params.id + "/cqm-assessment/edit/" + id)
            .then((result) => {
                this.$store.state.Assessment.form = result.data.data;
                this.$refs.loinceCode.searchText = result.data.data.loince_code
                this.$refs.loincedescription.searchText = result.data.data.loince_description
                this.$refs.autocompletecode.searchText = result.data.data.snomed_code;
                this.$refs.autocompletedescription.searchText = result.data.data.snomed_description
                if(this.form.observation_date != null){
                    this.observation_date = moment(String(this.form.observation_date)).format('MM-DD-YYYY');
                }
            });
            $("#new-assessment").removeClass("d-none");
            this.$smoothScroll({
                scrollTo: document.getElementById("new-assessment"),
            });
        },
        removeCQMAssessment(id){
            this.$swal.fire({
                title: '<h2 class="mt-0">Are you sure?</h2>',
                text:"You want to delete these records? This process cannot be undone.",
                icon: "warning",
                dangerMode: true,
                showCancelButton: true,
                confirmButtonColor: "#ee3158",
                cancelButtonColor: "#bdbdbd",
                confirmButtonText: "Yes, delete it!",
            })
            .then((result) => {
                if (result.isConfirmed == true) {
                    axios
                    .post("patient/" + this.$route.params.id + "/cqm-assessment/delete", {id: id,})
                    .then((response) => {
                        this.$swal
                            .fire(
                                "Deleted!",
                                "Your Care Plan has been deleted.",
                                "success"
                            )
                        .then((result) => {
                            if (result.isConfirmed == true) {
                                this.$store.dispatch("getSimpleTableContents",this.dataTypes);
                            }
                        });
                    });
                }
            });
        },
        save(next) {
            if(this.$store.state.Assessment.edit == 1){
                this.postForm(`/patient/${this.$route.params.id}/cqm-assessment/update`,"Assessment");
            }
            else{
                this.postForm(`/patient/${this.$route.params.id}/cqm-assessment/store`,"Assessment");   
            }
        },
        formPostSuccess(data) {
            this.$store.state.Assessment.form = {};
            Object.assign(this.$store.state.Assessment.form);
            this.v$.$reset();
            this.$refs.loinceCode.searchText = "";
            this.$refs.loincedescription.searchText = "";
            this.$refs.autocompletecode.searchText = "";
            this.$refs.autocompletedescription.searchText = "";
            this.observation_date = "";
            this.$store.dispatch("getSimpleTableContents", this.dataTypes);
            if(this.$store.state.Assessment.edit == 1){
                this.moshaToast("Record added successfully.", "success");
            }
            else{
                this.moshaToast("Record updated successfully.", "success");   
            }
            $("#new-assessment").addClass("d-none");
            this.$smoothScroll({
                scrollTo: document.getElementById("assessment_list"),
            });
        },
        close(){
            this.$store.state.Assessment.form = {};
            Object.assign(this.$store.state.Assessment.form);
            this.v$.$reset();
            this.$refs.loinceCode.searchText = "";
            this.$refs.loincedescription.searchText = "";
            this.$refs.autocompletecode.searchText = "";
            this.$refs.autocompletedescription.searchText = "";
            this.observation_date = "";
            this.$store.dispatch("getSimpleTableContents", this.dataTypes);
            $("#new-assessment").addClass("d-none");
            this.$smoothScroll({
                scrollTo: document.getElementById("assessment_list"),
            });
        }
    },
    mounted(){
        var d = new Date();
        var month = d.getMonth() + 1;
        var day = d.getDate();

        this.current_date = d.getFullYear() + "-" + (("" + month).length < 2 ? "0" : "") + month + "-" + (("" + day).length < 2 ? "0" : "") + day;

        axios.get("/json_autocomplete/snomed-code-with-data-element", {
                params: {data_element:'medication_intervention_not_done_medical_reason' },
        })
        .then((response) => {
            this.negation = response.data.data;
        });
    }
};
</script>