<template>
	<div class="new_tabs-corporate mb-3">
		<ul class="nav nav-pills mb-0" id="pills-tab" role="tablist">
			<li class="nav-item me-1" role="presentation">
				<button class="nav-link fw-500 active" id="pills-corporateapp-tab" data-bs-toggle="pill" data-bs-target="#pills-corporateapp" type="button" role="tab" aria-controls="pills-corporateapp" aria-selected="true" @click="selectedTab(0)">Info</button>
			</li>
			<li class="nav-item me-1" role="presentation">
				<button class="nav-link fw-500" id="services-interest-tab" data-bs-toggle="pill" data-bs-target="#services-interest" type="button" role="tab" aria-controls="services-interest" aria-selected="true" @click="selectedTab(1)">Services Of Interest</button>
			</li>
			<li class="nav-item me-1" role="presentation">
				<button class="nav-link fw-500" id="pills-notes-tab" data-bs-toggle="pill" data-bs-target="#pills-notes" type="button" role="tab" aria-controls="pills-notes" aria-selected="true" @click="selectedTab(2)">Notes</button>
			</li>
		</ul>
	</div>
	<div class="tab-content" id="pills-tabContent">
		<div class="tab-pane fade show active" id="pills-corporateapp" role="tabpanel" aria-labelledby="pills-corporateapp-tab" style="height: 370px;">
			<div class="form-group patientcard_form">
				<label class="form-label">Patient</label>
				<div class="e-float-input e-control-wrapper e-input-group">
					<input id="Patient" name="Patient" type="text" class="e-field" v-model='data.patient_name' disabled />
				</div>
			</div>
			<div class="form-group patientcard_form">
				<label class="form-label">Status</label>				
				<ejs-dropdownlist 
					id="crm_prospect_patient_status" 
					name="crm_prospect_patient_status" 
					class="e-field" 
					:value="data.crm_prospect_patient_status" 
					v-model='data.crm_prospect_patient_status' :fields='crm_prospect_status_fields'  
					:dataSource="data.crm_prospect_status_list" 
					placeholder="Status"></ejs-dropdownlist>
			</div>
			<div class="form-group patientcard_form">
				<label class="form-label">Wellness Coordinator</label>				
				<ejs-dropdownlist 
					id="wellness_coordinator" 
					name="wellness_coordinator" 
					class="e-field" 
					:value="data.wellness_coordinator" 
					v-model='data.wellness_coordinator'
					:fields='crm_wellness_fields' 
					:dataSource="data.wellseff" 
					placeholder="Wellness Coordinator" 
					></ejs-dropdownlist>
			</div>
			<div class="form-group patientcard_form">
				<label class="form-label">Referral Source</label>
				<div v-if="is_new_marketing_consult == false " class="d-flex">
					<ejs-dropdownlist 
						id="mktConsult"  
						name="marketing_consultant" 
						class="e-field" 
						:value="data.marketing_consultant" 
						v-model='data.marketing_consultant' 
						:dataSource="data.mktConsultList" 
						placeholder="Referral Source" 
						:fields='crm_mkt_consult_fields'></ejs-dropdownlist>
					<Popper arrow content="Add New" :hover="true" class="helptips cursor-pointer">
						<a href="javascript:void(0)" class="waves-effect waves-light btn btn-info-light btn-xs py-5 ms-1"  @click="addNewMaktConsult">
							<i class="fa fa-plus" />
						</a>
					</Popper>
				</div>
				<div class="patientcard_form" v-if="is_new_marketing_consult == true">
					<input type="text" name="marketing_consultant_text" class="e-field form-control" id="marketing_consultant_text" placeholder="Enter name" v-model='data.marketing_consultant_text'/>
					<span class="e-float-line"></span>
				</div>
			</div>
			<div class="form-group patientcard_form">
				<label class="form-label">Priority</label>
				<ejs-dropdownlist 
					name="priority" 
					id="priority" 
					v-model='data.priority' 
					:value="data.priority" 
					popupHeight="300px" 
					class="e-field" 
					placeholder="Priority" 
					:dataSource="priority_list">
				</ejs-dropdownlist>
			</div>
		</div>
		<div class="tab-pane fade" id="services-interest" role="tabpanel" aria-labelledby="services-interest-tab">
			<div class="form-group patientcard_form" style="height: 360px;">
				<label class="form-label">Services Of Interest</label>
				<div v-html="getInterestTag(data.services_of_interest)"></div>
			</div>
		</div>
		<div class="tab-pane fade" id="pills-notes" role="tabpanel" aria-labelledby="pills-notes-tab">
			<div class="form-group patientcard_form" style="height: 370px;">
				<label class="form-label">Notes</label>
				<div class="e-float-input e-control-wrapper e-input-group" style="width: 100%; height: 60px; margin-bottom: 5px !important;">
					<!-- <input id="notes" name="notes" type="text" placeholder="Create note" class="e-field" v-model="form.notes" style="padding-right: 3px;" /> -->
					<textarea id="notes" name="notes" placeholder="Create note" class="e-field" v-model="form.notes" style="padding-right: 3px; max-height: 60px; resize: none;" @keyup="updateBtnStatus"></textarea>
					<button v-show="isAdd" class="btn btn-info btn-sm" title="Create note" style="min-width: 70px;" @click="create_note" :disabled="isDisable">
						<i class="fa fa-plus"></i> <b>Add</b>
					</button>
					<button v-show="!isAdd" class="btn btn-info btn-sm" title="Create note" style="min-width: 70px;" @click="update_note" :disabled="isDisable">
						<b>Update</b>
					</button>
				</div>
				<div class="e-float-input e-control-wrapper e-input-group" style="height: calc( 100% - 60px ); overflow-y: scroll;">
					<div style="width: 100%; margin: 5px;" v-if="data.notes && data.notes.length>0">
						<div style="border: 1px solid #d4dde6; border-radius: 3px; padding: 5px; margin: 5px 0;" v-for="(note, index) in data.notes" :key="index">
							<div><pre>{{note.note}}</pre></div>
							<div style="width: 100%; text-align: right;">
								<div style="font-size: smaller; width: auto; display: inline-flex;">{{ getFormattedDate(note.ghl_created_at) }}</div>
								<div style="width: auto; display: inline-flex; align-items: flex-end; margin: 0 0 0 10px;">
									<a href="javascript:void(0);" @click="getNote(note.note_id, note.note)" class="btn btn-link btn-xs" title="Edit note">
										<i class="fa fa-pencil"></i>
									</a>
									<a href="javascript:void(0);" @click="deleteNote(note.note_id, note.id)" class="btn btn-link btn-xs text-danger" title="Delete note">
										<i class="fa fa-close"></i>
									</a>
								</div>
							</div>
						</div>
					</div>
					<!-- <textarea type="text" name="notes" id="notes" rows="15" class="e-field" v-model='data.notes'></textarea> -->
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import axios from "@/scripts/axios.js";
	import Popper from "vue3-popper";
	import { DropDownListComponent } from "@syncfusion/ej2-vue-dropdowns";
	import moment from "moment";
	export default {
		components: {
			'ejs-dropdownlist' : DropDownListComponent,
			Popper
		},
		data () {
			return {
				crm_prospect_status_fields: { text: 'name', value: 'id' },
				crm_wellness_fields: { text: 'name', value: 'id' },
				crm_mkt_consult_fields: { text: 'name', value: 'id' },
				priority_list: ["Low", "Normal", "Critical", "High"],
				is_new_marketing_consult: false,
				data: {},
				form: {
					notes: "",
					note_id: ""
				},
				isAdd: true,
				isDisable: true
			}
		},
		mounted() {
			this.form.notes = "";
			this.form.note_id = "";
			this.isDisable = true;
			this.isAdd = true;
			$('#pills-corporateapp-tab').click();
			this.fetchCRMNotes();
			this.is_new_marketing_consult = false;
		},
		methods:{
			getInterestTag (services_of_interest) {
				 let tagDiv = "";
				 if (services_of_interest) {
					 let tags = services_of_interest.split(",");
					 for (let tag of tags) {
					   tagDiv += '<div class="service-interest" >' + tag + '</div>';
					 }
				 } else {
					tagDiv += '<div class="service-interest">NA</div>';
				 }
				 return tagDiv;
			},
			fetchCRMNotes : function() {
				axios.get("json_dropdown/crm-notes/"+this.data.patient_id)
				.then((response) => {
					this.data.notes = response.data.data;
				});
			},			
			addNewMaktConsult() {
				this.is_new_marketing_consult = !this.is_new_marketing_consult;
			},
			getFormattedDate(date) {
				return moment(String(date)).format('MM/DD/YYYY HH:mm');
			},
			create_note() {
				this.loader(true);
				axios.post("/crm/prospect/store/notes",{ notes: this.form.notes, patient_id: this.data.patient_id, pipeline_id: this.data.pipeline_id })
				.then((response) => {
					this.data.notes = response.data.data;
					this.form.notes = "";
					this.isDisable = true;
					this.loader(false);
				});
			},
			loader(status) {
				if(status) {
					document.getElementById("loader").style.display="block";
					document.getElementById("loader").style.opacity=0.7;
				} else {
					document.getElementById("loader").style.display="none";
					document.getElementById("loader").style.opacity=0.05;
				}
			},
			getNote(noteId, note) {
				this.form.note_id = noteId;
				this.form.notes = note;
				this.isAdd = false;
				if(this.form.notes.trim()!="") {
					this.isDisable = false;
				}
			},
			update_note() {
				this.loader(true);
				axios.post("/crm/prospect/update/notes",{ note_id: this.form.note_id, notes: this.form.notes, patient_id: this.data.patient_id })
				.then((response) => {
					this.data.notes = response.data.data;
					this.form.note_id = "";
					this.form.notes = "";
					this.isAdd = true;
					this.isDisable = true;
					this.loader(false);
				});				
			},
			deleteNote(noteId, nid) {
				if(confirm('Are you sure? You want to delete this note.')) {
					this.loader(true);
					axios.post("/crm/prospect/delete/notes",{ note_id: noteId, nid: nid, patient_id: this.data.patient_id })
					.then((response) => {
						this.data.notes = response.data.data;
						this.form.note_id = "";
						this.form.notes = "";
						this.isAdd = true;
						this.loader(false);
					});
				}
			},
			selectedTab(indx) {
				if(document.getElementsByClassName("e-dialog-delete")[0]) {
					if(indx==2) {
						document.getElementsByClassName("e-dialog-delete")[0].style.display="none";
						document.getElementsByClassName("e-dialog-edit")[0].style.display="none";
					} else {
						document.getElementsByClassName("e-dialog-delete")[0].style.display="inline-block";
						document.getElementsByClassName("e-dialog-edit")[0].style.display="inline-block";
					}
				}
			},
			updateBtnStatus() {
				if(this.form.notes.trim()=="") {
					this.isDisable = true;
				} else {
					this.isDisable = false;
				}
			}
		}
	}
</script>
<style type="text/css">
	#kanban_dialog_wrapper {
		width: 700px  !important;
	}
	.service-interest {
		background-color: #eff8fe;
		font-size: 15px;
		padding: 7px 0 7px 7px;
	}
	/* .e-dialog-delete { 
        display: none; 
    }  */
	/*.e-kanban-dialog .e-kanban-form-wrapper table .e-label, .e-kanban-dialog .e-kanban-form-container table .e-label {
		padding-right: 10px !important;
		vertical-align: top !important;
		width: 40%  !important;
	  }*/
</style>