<template>
	<div class="content-wrapper">
		<div class="container-full">
			<section class="content">
				<div class="row justify-content-sm-center">
					<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-8">
						<div class="content-header">
							<h1 class="heading1 mt-0 d-inline-block fs-24">Transfer Patients to Other Wellness Coordinator 
								<Popper arrow content="It is used to transfer patient from one wellness coordinator to the other." :hover="true" class="helptips cursor-pointer fs-18">
									<i class="fa fa-info-circle"></i>
								</Popper>
							</h1>
						</div>
						<div class="box">
							<div class="box-body">
								<div class="row mb-10">
									<div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
										<div class="d-flex form-group">
											<label class="col-form-label mb-0 me-3 w-200 text-center">Pipeline List</label>
											<select class="form-control" v-model="form.crm_pipeline" @change="getPipeline(form.crm_pipeline)">
												<option value="" disabled >Select pipeline</option>
												<option v-for="(crm_pipelilne,index) of crm_pipelilne_list" :value="crm_pipelilne.id"  :key="index" :selected="1">{{crm_pipelilne.name}}</option>
											</select>
										</div>
										<div
											class="invalid-feedback"
											style="display:block;"
											v-if="from_wellness_cord_id_error && !form.from_wellness_cord_id"
											> {{ from_wellness_cord_id_error }}
										</div>
									</div>
									<div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
										<div class="d-flex form-group">
											<label class="col-form-label mb-0 me-3 w-50 text-center">From</label>
											<select class="form-control" v-model="form.from_wellness_cord_id" @change="getPatientByWellnessCoord(form.from_wellness_cord_id)">
												<option value=""  >Select User</option>
												<option v-for="(wellness_coordinator,index) of wellness_coordinator_list" :value="wellness_coordinator.id"  :key="index" >{{wellness_coordinator.name}}</option>
											</select>
										</div>
										<div
											class="invalid-feedback"
											style="display:block;"
											v-if="from_wellness_cord_id_error && !form.from_wellness_cord_id"
											> {{ from_wellness_cord_id_error }}
										</div>
									</div>
									<div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
										<div class="d-flex form-group">
											<label class="col-form-label mb-0 me-3 w-50 text-center">To</label>
											<select class="form-control" v-model="form.to_wellness_cord_id">
												<option value=""  >Select User</option>
												<option v-for="(user,index) of user_list" :value="user.id"  :key="index" >{{user.name}}</option>
											</select>
										</div>
										<div
											class="invalid-feedback"
											style="display:block;"
											v-if="to_wellness_cord_id_error && !form.to_wellness_cord_id"
											> {{ to_wellness_cord_id_error }}
										</div>
									</div>
									<div class="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 text-center text-lg-start">
										<button type="button" class="waves-effect waves-light btn btn-info" @click="transferWellNessCoord">Transfer</button>
									</div>
								</div>
								<div class="table-responsive">
									<table class="table">
										<thead>
											<tr class="text-center">
												<th>
													<input type="checkbox" id="checkbox_for_check_all" class="filled-in chk-col-info" v-model="selectAll" />
													<label for="checkbox_for_check_all" class="mb-0 mt-5 me-2"></label>
												</th>
												<th>Patient Name</th>
												<th>Cell Phone</th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="patient of patient_list" :key="patient.id" class="text-center">
												<td>
													<input type="checkbox" :id="patient.id" class="filled-in chk-col-info" v-model="selected_patient" :value="patient.id" number />
													<label :for="patient.id" class="mb-0 mt-5 me-2"></label>
												</td>
												<td>
													<router-link :to="'/patient-chart/'+patient.unique_id" :title="'View Chart of ' +patient.patient_name " class="fw-500">{{ patient.patient_name }}</router-link>
												</td>
												<td> {{ patient.cell_phone }}</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	</div>
</template>
<script>
	import axios from "@/scripts/axios.js";
	import $ from "jquery";
	import submitMixins from "@/scripts/mixins/submitMixins.js";
	import Popper from "vue3-popper";

	export default {
		components: {
			Popper
		},
		mixins: [submitMixins],
		data() 
		{
			return {
				form : {
					from_wellness_cord_id : '',
					to_wellness_cord_id : '',
					crm_pipeline:1,
				},
				wellness_coordinator_list : [],
				user_list : [],
				patient_list : [],
				selected_patient: [],
				allSelected: false,
				from_wellness_cord_id_error: '',
				to_wellness_cord_id_error: '',
				crm_pipelilne_list:[],
				getpippeline_id:1,
			}
		},
		created() {
			axios.get("/json_dropdown/user-list")
			.then((response) => {
				let $this = this;
				response.data.data.forEach(function(value,key) {
					if (value.user_type == 'staff') {
						$this.user_list.push(value)
					}
				});
			});
			this.refreshWellnessCoordinator();
			axios.get("/json_dropdown/crm-pipeline-list")
			.then((response) => {
				let $this=this;
				response.data.data.forEach(function(value,key){
					if (value['name']=="First Pipeline") {
						value['selected']="true";
					}
					
					$this.crm_pipelilne_list.push(value)
				});
				
				});
				
		},
		computed: {
			selectAll: {
				get: function () {
					return this.patient_list.length > 0 ? this.selected_patient.length == this.patient_list.length : false;
				},
				set: function (value) {
					var selected = [];

					if (value) {
						this.patient_list.forEach(function (patient) {
							selected.push(patient.id);
						});
					}
					console.log(selected);
					this.selected_patient = selected;
				}
			}
		},
		methods:{
			transferWellNessCoord: function () {
				if (!this.form.from_wellness_cord_id) {
					this.from_wellness_cord_id_error = 'This field is required';
					return false;
				}
				if (!this.form.to_wellness_cord_id) {
					this.to_wellness_cord_id_error = 'This field is required';
					return false;
				}
				if (this.selected_patient.length == 0) {
					this.moshaToast("Please select patient.", "danger");
				}
				this.form.selected_patient = this.selected_patient;
				axios.post("/crm/bulkPatientCoordinatorUpdate",this.form)
				.then((response) => {
					this.moshaToast(response.data.message, "success");
					setTimeout(function(){ location.reload(); }, 300);
				}).catch((error) => {
				});
			},
			getPatientByWellnessCoord(id) {
			
				axios.post("/crm/getPatientByCoordinator",{'wellness_coordinator_id' : id,'getpipelline_id':this.getpippeline_id})
				.then((response) => {
					this.patient_list = response.data.data;
				}).catch((error) => {
				});
			},
			refreshWellnessCoordinator(id=0) {
				axios.post("/json_dropdown/wellness-coordinator/"+id)
				.then((response) => {
					this.wellness_coordinator_list = response.data.data;
				});
			},
			getPipeline(id){
				this.getpippeline_id=id;
				this.refreshWellnessCoordinator(id);
			}
		}
	}
</script>