<template>
  <div class="content-wrapper">
    <div class="container-full">
      <section class="content">
        <div class="row justify-content-between">
          <!-- sidebar -->
          <the-side-bar></the-side-bar>
          <!-- content -->
          <component
            :is="$store.state.patientComp"
            :key="Math.floor(Math.random() * 20)"
          ></component>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import TheSideBar from "@/components/patients/AddEdit/TheSidebar.vue";
import PatientInformation from "@/components/patients/AddEdit/Home.vue";
import Demographics from "@/components/patients/AddEdit/Demographics/Home.vue";

export default {
    
    components: {TheSideBar,PatientInformation,Demographics},
    data() {
        return {
        };
    },
    watch: {},
    created() {
      if (this.$route.name === "PatientAddDemographics") {
        this.$store.state.patientComp = "Demographics";
      } 
      else if (this.$route.name === "PatientEditDemographics") {
        this.$store.state.patientComp = "Demographics";
      } 
      else if (
        this.$route.name === "PatientEdit" ||
        this.$route.name === "PatientAdd"
      ) {
          this.$store.state.patientComp = "PatientInformation";
      }
    },
    methods: {},
};
</script>
