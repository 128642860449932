const preAppointment = {
  namespaced: true,

  state: {
    listUrl: "/pre-appointment/list",
    header: [
      {"office.office_name":"Office"},
      {"provider_name":"Provider"},
      {"coordinator_staff_name":"Staff"},
      {"patient_name":"Patient Name"},
      {"online_pre_appointment.cell_phone":"Cell Phone"},
      {"online_pre_appointment.email":"Email"},
      {"online_pre_appointment.schedule_date":"Pre Appt. Date/Time"},
      {"online_pre_appointment.duration":"Duration"},
      {"Action":"Action"},
    ],
    pagination: {
      to: "",
      from: "",
      total: "",
      last_page: "",
      current_page: "",
      pageNavArr: [],
    },
    filter: {
      converted_appt: false,
      pre_with_converted_appt: false, 
      deleted_appt: false,
      search_text: '',
    },
    list: [],
    key : 0,
  },

  getters: {
    // getTableHeaders: (state) => state.header,
    // getTableContents: (state) => state.list,
    // getListURL: (state) => state.listUrl,
  },
  mutations: {},
  actions: {},
};

export default preAppointment;
