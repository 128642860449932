<template>
	<div class="tab-pane active" id="AddRoomsLink" role="tabpanel">
		<div class="box">
			<div class="box-body">
				<div class="row">
					<div class="col-12 col-sm-12 col-md-12 col-lg-10">
						<div class="pageheading">
							<div class="row">
								<div class="col-12 col-sm-8 col-md-8 col-lg-8">
									<h2 class="fw-900 mt-0">Archived Rooms</h2>
								</div>
								<div class="col-12 col-sm-4 col-md-4 col-lg-4 text-sm-end">
									<router-link :to="'/office/'+ this.$route.params.id +'/room'" class="waves-effect waves-light btn btn-info-light me-1 mb-1"> <i class="fa fa-arrow-left fs-14"></i> Back </router-link>
								</div>
							</div>
						</div>
						<div class="table-responsive" v-if="rooms">
							<table id="example6" class="table table-bordered" cellspacing="0">
								<thead>
									<tr>
										<th>Room Name</th>
										<th>Action</th>
									</tr>
								</thead>
								<tbody v-if="rooms.length">
									<tr v-for="room in rooms">
										<td>{{ room.name }}</td>

										<td>
											<button type="button"  data-on="Ready" data-off="Not Ready" @click="onToggleChange(room.id,$event)" :id="['status_' + room.id]" class="btn btn-toggle rounded30-im"  v-bind:class="[room.deleted_at == NULL ? 'active' : '']" data-bs-toggle="button" aria-pressed="true" autocomplete="off">
												<div class="handle rounded30-im"></div>
											</button>
										</td>
									</tr>
								</tbody>
								<tbody v-else>
									<tr>
										<td colspan="2">No rooms available</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>

			</div>
		</div>
	</div>
</template>

<script>
	import submitMixins from "@/scripts/mixins/submitMixins.js";
	import axios from "@/scripts/axios.js";
	import $ from "jquery";
	export default {
		mixins: [submitMixins],  
		data() {
			return {
				rooms: null,
			}
		},
		mounted() {
			this.getroom();
		},
		methods: {
			getroom(){
				axios.post("/json_dropdown/" + this.$route.params.id + "/room/archived")
				.then((response) => {
					this.rooms = response.data.data;
				});
			},
			onToggleChange(id,event) {
				var class_list = document.getElementById("status_"+id).classList;
				var active_deactive = '';
				if(class_list.contains('active')) {
					active_deactive = 'A';
				} else {
					active_deactive = 'D';
				}

				axios
				.post("office/"+ this.$route.params.id +"/rooms/reset",{"status":active_deactive,"room_id":id})
				.then((response) => {
					this.moshaToast('User has been restored successfully.','success');
					setTimeout(function(){ location.reload(); }, 3000);
				});
			}
		}

	}
</script>