<template>
    <div class="content-wrapper" style="min-height: 256px;">
        <div class="container-full">
            <div class="content-header">
                <h2 class="heading2 mb-0">Download Logs</h2>
            </div>
            <section class="content">
                <div class="box">
                    <div class="box-body">
                        <div class="row justify-content-sm-center">
                            <div class="table-responsive">
						        <table class="table fs-16">
                                    <thead>
                                        <tr class="text-center">
                                            <td>Sr No.</td>
                                            <td>File Name</td>
                                            <td>Created By</td>
                                            <td>Created At</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="text-center" v-for="(item,index) in list" :key="index">
                                            <td>{{index+1}}</td>
                                            <td> <a href="javascript:void(0);" @click="getAssets(item.attachment_path,item.attachment_filename)"> {{item.title}} </a> </td>
                                            <td>{{item.created_name}}</td>
                                            <td>{{ $filters.displayDateTimeLocal(item.created_at) }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>
<script>
import axios from "@/scripts/axios.js";
import moment from 'moment';
export default {
    data() {
        return {
            list:[],
            base_url: `${process.env.VUE_APP_API_BASE_URL}`,
            start:0,
            end:20,
            step:20,
        }
    },
    mounted(){
        axios.get("reports/drop-box-list").then((response) => (this.list = response.data.data));
    },
    methods: {
        formatDate(date, format) {
            if(date != null && date != ''){
                return moment(date).format(format);
            }else{
                return '-';
            }
        },        
        getAssets(foldername,filename){
			axios.post("/get-download-file",{folder_clinic_id:this.$store.state.auth.userData.clinic_id,foldername:foldername,filename:filename})
			.then((response) => {
				window.open(response.data, '_blank').focus();
			});
		},
    }
}
</script>