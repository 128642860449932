const LabResult = {
  namespaced: true,

  state: {
    tableData: {
      header: [
        "LOINC Code ",
        "Description",
        "Date",
        "Sign-Off",
        "Status",
        "Result",
        "Comments",
      ],
    },
    form: {},
    listUrl: "/patient/:patient_unique_id/lab-order/result?req_type=ignore",
    list: [],
  },
  getters: {
    getTableHeaders: (state) => state.header,

    getTableContents: (state) => state.list,
    getListURL: (state) => state.listUrl,
  },
  mutations: {},
  actions: {},
}

export default LabResult;