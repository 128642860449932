<template>

	<div class="mb-20" v-if="details.payment_profile=='Insurance'">
		<p v-if="details.billing_status_txt!=null && details.billing_status_txt!=''">Billing Status: - [{{details.billing_status_txt}}]</p>
		<div class="text-md-left bg-light p-1">

			<span v-if="details.claim_status == 'not_claimed'" class="waves-effect waves-light badge b-1 border-danger m-1 fs-16 py-1 text-danger" style="font-weight: 500;" title=""> Claim not submitted</span>

      <span v-if="details.claim_status == 'claimed'" class="waves-effect waves-light badge badge-danger-light m-1 fs-16 py-1 text-danger" style="font-weight: 500;" title=""> Claimed for insurance</span>

      <span v-if="details.claim_status == 'rejected_by_clearing_house'" class="waves-effect waves-light badge badge-danger-light m-1 fs-16 py-1 text-danger" style="font-weight: 500;" title=""> Claim Rejected by Clearning House</span>

      <span v-if="details.claim_status == 'remit_received'" class="waves-effect waves-light badge badge-danger-light m-1 fs-16 py-1 text-danger" style="font-weight: 500;" title=""> Remit Received</span>

      <span v-if="details.claim_status == 'requested_for_resubmission'" class="waves-effect waves-light badge badge-danger-light m-1 fs-16 py-1 text-danger" style="font-weight: 500;" title=""> Claim resubmitted</span>

      <span v-if="details.claim_status == 'requested_for_void'" class="waves-effect waves-light badge badge-danger-light m-1 fs-16 py-1 text-danger" style="font-weight: 500;" title=""> Void Claim</span>

      <span v-if="details.claim_status == 'accepted_by_clearing_house'" class="waves-effect waves-light badge badge-danger-light m-1 fs-16 py-1 text-danger" style="font-weight: 500;" title=""> Claim Accepted by Clearing house</span>

      <span v-if="details.claim_status == 'not_claimed_due_to_missing_details'" class="waves-effect waves-light badge badge-danger-light m-1 fs-16 py-1 text-danger" style="font-weight: 500;" title=""><i class="fa fa-id-card"></i> Insurance not claimed due to missing details</span>

      <span class="waves-effect waves-light badge badge-info-light m-1 fs-16 py-1" style="font-weight: 500;" title="Total Patient Responsibility">
				Claim Amt.: {{ toUSD(details.claim_amount) }}
			</span>
			<span class="me-2" style="font-size: 22px;">:-</span>

			<span class="waves-effect waves-light badge badge-danger-light mb-1 me-1" style="font-weight: 500;" title="Total Patient Responsibility">
				Adjustment: {{ toUSD(details.adjustment_amount) }}
			</span>
			<span class="waves-effect waves-light badge badge-success-light mb-1 me-1" style="font-weight: 500;" title="Total Patient Responsibility">
				Payer Paid: {{ toUSD(details.total_claimed_accepted_amount) }}
			</span>
			<span class="waves-effect waves-light badge badge-success-light mb-1 me-1" style="font-weight: 500;" title="Total Patient Responsibility">
				Patient Paid: {{ toUSD(details.copay_coinsurance_received) }}
			</span>

		</div>
	</div>
</template>
<script>
import axios from "@/scripts/axios.js";
export default {
	props: {
        AppointmentNumber: {
          type: Number,
          required: true
        },  
        billingStatusLabel:{
        	type: String
        }    
    },
	created() {
        this.appointment_number = this.AppointmentNumber;
        this.billing_status = this.billingStatusLabel
    },
	data() {
        return { 
					appointment_number:null,
					details:{
						claim_amount:null,
						adjustment_amount:null,
						total_claimed_accepted_amount:null,
						copay_coinsurance_received:null,
						payment_profile:null,
					}
				}
	},
	mounted() {
		// this.$store.state.loader = true;
        axios.post('/appointment/get-insurance-billable-amount',{appointment_number:this.appointment_number}).then((response) => {
				this.details.claim_amount = response.data.data.claim_amount
				this.details.adjustment_amount = response.data.data.adjustment_amount
				this.details.total_claimed_accepted_amount = response.data.data.total_claimed_accepted_amount
				this.details.copay_coinsurance_received = response.data.data.copay_coinsurance_received
				this.details.payment_profile = response.data.data.payment_profile
				this.details.claim_status = response.data.data.claim_status
				this.details.billing_status_txt = response.data.data.billing_status_txt
				// this.$store.state.loader = false;
		});
        
    },
	methods: {
		toUSD (value) {
			if (value) {
				return `$${parseFloat(value).toFixed(2)}`;
			} else {
				return `$${parseFloat(0).toFixed(2)}`;
			}
		}		
	}
}
</script>

<style>

</style>