<template>
	<div class="box">
		<div class="box-body">
			<div class="pageheading">
				<!-- <h3 class="heading3 fw-600">Vaccines available for age {{this.$store.state.patientDetail.age}}</h3> -->
				<h3 class="heading3 fw-600">Available Vaccines</h3>
			</div>
			<div class="table-responsive">
				<table class="table">
					<thead>
						<tr>
							<th>Age Group</th>
							<th>Vaccine</th>
							<th>Vaccine Name</th>
							<th></th>
						</tr>
					</thead>
					<tbody v-if="age_vaccination.length>0">
						<slot v-for="(item, index) in age_vaccination" :key="index">
							<tr>
								<td>{{item.agegroup}}</td>
								<td>{{item.vaccine_name}}</td>
								<td>								
									<select v-bind:class="'form-control myselect'+item.id" v-bind:id="'myselect'+item.id" v-model="form.cvx_code" @change="vaccine_name(item.id)">
										<option value="" selected="selected" disabled="disabled">Select Vaccine</option>
										<option v-for="(items, indexs) in item.cvx" :key="indexs" v-bind:value="items.cvx_code">{{items.short_name}}</option>
									</select>
									<small v-bind:id="'select_vaccine_error'+item.id" class="text-danger select_vaccine_error d-none">Please select a vaccine</small>
								</td>
								<td>
									<a @click="AddNewVaccine(item.id)" href="javascript:void(0)" class="waves-effect waves-light btn btn-info mb-1 select_vaccine me-1">Select Vaccine</a>
									<a @click="Refuse(item.id)" href="javascript:void(0)" class="waves-effect waves-light btn btn-info-light mb-1 refuse-vaccine">Refuse</a>
								</td>
							</tr>
							
							<tr>
								<td class="b-0 p-0 d-none addvaccination" v-bind:id="'addvaccination'+item.id" colspan="4">
									<div class="mb-0 filter_vaccine">
										<div class="mt-20 px-15">
											<div class="pageheading">
												<h2 class="heading2">Add Vaccination Record</h2>
											</div>
											<form>

												<div class="form-group row" :class="{ error: v$.form.cvx_code.$errors.length }">
													<label class="col-form-label mb-0 col-12 col-sm-3 col-md-4 col-lg-4 col-xl-3 text-md-end text-md-end">CVX Code <small class="text-danger asterisksign">*</small></label>
													<div class="col-12 col-sm-9 col-md-8 col-lg-5 col-xl-3">
														<input type="text" id="0" class="form-control vue3-input" :value="form.cvx_code" placeholder="Search CVX Code" disabled="disabled">
														<div class="error-msg">
												<ul class="mb-0 p-0 fs-14">
														<li class="d-block" v-for="(error, index) of v$.form.cvx_code.$errors" :key="index">
														{{ error.$message }}
														</li>
												</ul>
												</div>
													</div>
												</div>

												<div class="form-group row" :class="{ error: v$.form.vaccine_name.$errors.length }">
													<label class="col-form-label mb-0 col-12 col-sm-3 col-md-4 col-lg-4 col-xl-3 text-md-end text-md-end">Vaccine Name<small class="text-danger asterisksign">*</small></label>
													<div class="col-12 col-sm-9 col-md-8 col-lg-5 col-xl-3">
														<input type="text" id="0" class="form-control vue3-input" :value="form.vaccine_name" disabled="disabled">
														<div class="error-msg">
												<ul class="mb-0 p-0 fs-14">
														<li class="d-block" v-for="(error, index) of v$.form.vaccine_name.$errors" :key="index">
														{{ error.$message }}
														</li>
												</ul>
												</div>
													</div>
												</div>

												<select-box-static
													title="Administered amount"
													v-model="v$.form.administered_amount.$model"
													:errors="v$.form.administered_amount.$errors"
													:option="administered_amountoption"
													:isRequired="true"
												></select-box-static>

												<select-box-static
													title="Vaccine site"
													v-model="v$.form.vaccine_site.$model"
													:errors="v$.form.vaccine_site.$errors"
													:option="vaccine_siteoption"
													:isRequired="true"
												></select-box-static>

												<select-box-static
													title="Vaccine route"
													v-model="v$.form.vaccine_route.$model"
													:errors="v$.form.vaccine_route.$errors"
													:option="vaccine_routeoption"
													:isRequired="true"
												></select-box-static>

												<div class="form-group row" :class="{ error: v$.form.vaccinated_on.$errors.length }">
													<label class="col-form-label mb-0 col-12 col-sm-3 col-md-4 col-lg-4 col-xl-3 text-md-end">Vaccinated on <small class="text-danger asterisksign">*</small></label>
													<div class="col-12 col-sm-9 col-md-8 col-lg-5 col-xl-3">
														<div class="availdate">
															<ejs-datepicker 
																:value="vaccinated_on" 
																id="vaccinated_on" 
																v-model="form.vaccinated_on" 
																:format="dateFormat"  
																:placeholder="waterMarkText"
																@change="getdateimu" 
																name="vaccinated_on" 
																:showClearButton='false' 
																:blur="getdateimu"
																:openOnFocus='true'>
															</ejs-datepicker>
														</div>
														<div class="error-msg">
															<ul class="mb-0 p-0 fs-14">
																<li class="d-block" v-for="(error, index) of v$.form.vaccinated_on.$errors" :key="index">
																	{{ error.$message }}
																</li>
															</ul>
														</div>
													</div>
												</div>

												<div class="form-group row">
													<label class="col-form-label mb-0 col-12 col-sm-3 col-md-4 col-lg-4 col-xl-3 text-md-end">Ordering doctor <small class="text-danger asterisksign">*</small></label>
													<div class="col-12 col-sm-9 col-md-8 col-lg-5 col-xl-3">
														<input class="form-control" type="text" :value="((this.$store.state.auth.activeProvider.salutation) ? this.$store.state.auth.activeProvider.salutation : '')+' '+this.$store.state.auth.activeProvider.first_name+' '+this.$store.state.auth.activeProvider.last_name+' '+((this.$store.state.auth.activeProvider.suffix)? this.$store.state.auth.activeProvider.suffix : '')" disabled="disabled">
													</div>
												</div>
												<div class="form-group row">
													<label class="col-form-label mb-0 col-12 col-sm-3 col-md-4 col-lg-4 col-xl-3 text-md-end">Administered by <small class="text-danger asterisksign">*</small></label>
													<div class="col-12 col-sm-9 col-md-8 col-lg-5 col-xl-3">
														<input type="text" class="form-control" :value="((this.$store.state.auth.activeProvider.salutation) ? this.$store.state.auth.activeProvider.salutation : '')+' '+this.$store.state.auth.activeProvider.first_name+' '+this.$store.state.auth.activeProvider.last_name+' '+((this.$store.state.auth.activeProvider.suffix)? this.$store.state.auth.activeProvider.suffix : '')" disabled="disabled">
													</div>
												</div>
												<select-box
													title="Administered at"
													id="administered_at"
													v-model="v$.form.administered_at.$model"
													:errors="v$.form.administered_at.$errors"
													:getDataURL="getOfficeDDURL"
													:isRequired="true">
												</select-box>
												<div class="row">
													<div class="col-12 col-sm-12 col-md-12 col-lg-9 text-center">
														<a @click="save" href="javascript:void(0)" class="waves-effect waves-light btn btn-info">Save Record</a>
													</div>
												</div>
											</form>
										</div>
									</div>
								</td>
							</tr>

							<tr>
								<td class="b-0 p-0 d-none vaccinerefusal" v-bind:id="'vaccinerefusal'+item.id" colspan="4">
									<div class="table_filters py-10 mb-0">
										<div class="row">
											<!-- <div class="form-group row refuse">
												<label class="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end">Vaccine refusal Code <small class="text-danger asterisksign">*</small></label>
												<div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3">
													<div class="selectcontrols">
														<Autocomplete
															max="30"
															@input="inputCode"
															ref="autocomplete"
															:results="refusalCodeList"
															@onSelect="refusalSelectedValue"
															class="form-control ms-0 autosearch-list"
															:value="modelValue"
															placeholder="Search Refusal Code"
															@keyup="onkeyup"
														></Autocomplete>
													</div>
													<div class="error-msg"><ul class="mb-0 p-0 fs-14"><li class="refused d-none d-block">Vaccine refusal Code is required</li></ul></div>
												</div>
											</div>
											<div class="form-group row refuse">
												<label class="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end">Vaccine refusal Description <small class="text-danger asterisksign">*</small></label>
												<div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3">
													<div class="selectcontrols">
														<Autocomplete
															max="30"
															@input="getfild"
															ref="description"
															:results="refusaldecList"
															@onSelect="refusaldecSelectedValue"
															class="form-control ms-0 autosearch-list"
															:value="modelValue"
															placeholder="Search Refusal Description"
														></Autocomplete>
													</div>
													<div class="error-msg"><ul class="mb-0 p-0 fs-14"><li class=" refused d-none d-block">Vaccine refusal Description is required</li></ul></div>
												</div>
											</div> -->
											<div class="form-group row refuse">
												<label class="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end">Vaccine refusal reason <small class="text-danger asterisksign">*</small></label>
												<div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-4">
													<select class="form-control" id="rejected_reason" v-model="refusalcode" @change="rejected_reason_change">
														<option value="" disabled>Please Select Refusal Reason</option>
														<option value="VACEFF">Vaccine efficacy concerns</option>
														<option value="RELIG">Religious objection</option>
														<option value="PHILISOP">Philosophical objection</option>
														<option value="PATOBJ">Patient objection</option>
														<option value="OSTOCK">Product out of stock</option>
														<option value="MEDPREC">Medical precaution</option>
														<option value="IMMUNE">Immunity</option>
														<option value="VACSAF">Vaccine safety concerns</option>
													</select>
												</div>
											</div>
										</div>
										<div class="row">
											<div class="col-12 col-sm-12 col-md-12 col-lg-9 text-center">
												<a @click="update" href="javascript:void(0)" class="waves-effect waves-light btn btn-info mt-1">Update</a>
											</div>
										</div>
									</div>
								</td>
							</tr>
						</slot>
					</tbody>
					<tbody v-else>
							<td colspan="3" class="text-center">There is no pending vaccination </td>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import useVuelidate from "@vuelidate/core";
import submitMixins from "@/scripts/mixins/submitMixins.js";
import axios from "@/scripts/axios.js";
import Autocomplete from "vue3-autocomplete";
import moment from "moment";
import SelectBoxStatic from "@/components/base/formFields/SelectBoxStatic.vue";
import SelectBox from "@/components/base/formFields/selectBox.vue";
export default {
	mixins: [submitMixins],
  	setup: () => {
	    return { v$: useVuelidate() };
  	},
  	components: {
		SelectBoxStatic,
		SelectBox,
		Autocomplete,
	},
  	computed: {
  		getOfficeDDURL() {
			var activeprovider = localStorage.getItem('activeProvider');
			if(activeprovider == 'all'){
				if(this.user.user_type == 'provider'){
					var provider_id = this.user.user_id;
				}else{
					var provider_id = this.user.primary_provider_id;
				}
			}else{
				var provider_id = localStorage.getItem('activeProvider');
			}
			return `/provider/office_access/`+provider_id;
	    },
    	form() {
      		return this.$store.state.VaccinesAvailable.form;
    	},
    	vuelidateExternalResults() {
      		return this.$store.state.VaccinesAvailable.vuelidateExternalResults;
	    },
    	validationRules() {
      		return this.$store.state.VaccinesAvailable.validationRules;
    	},
		...mapGetters("auth", ["user"]),
  	},
  	validations() {
	    return this.$store.state.VaccinesAvailable.validationRules;
  	},
  	data() {
    	return {
			vaccinated_on: null,
    		refusalCodeList: [],
    		refusaldecList:[],
    		refusalcode : "",
	      refusaldec :'',
    		dataTypes: {
		    	dataType: "VaccinesAvailable",
		        patient_id: this.$store.state.patientDetail.patient_id,
		        patient_unique_id: this.$store.state.patientDetail.patient_unique_id,
		    },
		    refuse:'',
    		age_vaccination:{},
    		administered_amountoption: [
		        { value: "BT", title: "BT - BOTTLE(S)" },
		        { value: "EA", title: "EA - EACH" },
		        { value: "GM", title: "GM - GRAM(S)" },
		        { value: "KG", title: "KG - KILOGRAM(S)" },
		        { value: "LB", title: "LB - POUND(S)" },
		        { value: "MEQ", title: "MEQ - MILLIEQUIVALENT(S)" },
		        { value: "MG", title: "MG - MILLIGRAM(S)" },
		        { value: "ML", title: "ML - MILLILITER(S)" },
		        { value: "OZ", title: "OZ - OUNCE(S)" },
		        { value: "PF", title: "PF - PUFF(S)" },
		        { value: "SC", title: "SC - SQUARE CENTIMETER(S)" },
		        { value: "TB", title: "TB - TABLET(S)" },
		        { value: "VL", title: "VL - VIAL(S)" },
		    ],
		    vaccine_siteoption:[
		    	{ value: "LA", title: "Left Arm" },
		    	{ value: "LD", title: "Left Deltoid" },
		    	{ value: "LG", title: "Left Gluteus Medius" },
		    	{ value: "LLFA", title: "Left Lower Forearm" },
		    	{ value: "LT", title: "Left Thigh" },
		    	{ value: "LVL", title: "Left Vastus Lateralis" },
		    	{ value: "RA", title: "Right Arm" },
		    	{ value: "RD", title: "Right Deltoid" },
		    	{ value: "RG", title: "Right Gluteus Medius" },
		    	{ value: "RT", title: "Right Thigh" },
		    	{ value: "RVL", title: "Right Vastus Lateralis" },
		    	{ value: "RLF", title: "Right Loweer Forearm" },
		    ],
		    vaccine_routeoption:[
		    	{ value: "ID", title: "Intradermal" },
		    	{ value: "IM", title: "Intramuscular" },
		    	{ value: "IV", title: "Intravenous" },
		    	{ value: "NS", title: "Nasal" },
		    	{ value: "PO", title: "Oral" },
		    	{ value: "OTH", title: "Other/Miscellaneous" },
		    	{ value: "PER", title: "Percutaneous" },
		    	{ value: "SC", title: "Subcutaneous" },
		    	{ value: "TD", title: "Transdermal" },
		    ],
		    waterMarkText : 'mm-dd-yyyy',
      	dateFormat: 'MM-dd-yyyy',
    	}
    },

    async beforeCreate() {
    	axios
        .post("patient/" + this.$route.params.id + "/vaccination/age-vaccination")
        .then((response) => {
          this.age_vaccination = response.data.data;
        });
  	},

  	methods:{
  		inputCode(obj) {
	      if (obj.length > 1) {
	      	this.$refs.autocomplete.$el.classList.add("selectloader");
	        axios
	          .get("/json_autocomplete/icd-code", {
	            params: { term: obj,filter:'z28' },
	          })
	          .then((response) => {
	            this.refusalCodeList = response.data.data;
	            this.$refs.autocomplete.$el.classList.remove("selectloader");
	          });
	      }
	      if (obj.length == 0) {
	        this.refusalCodeList = [];
	        this.refusaldecList = [];
	        this.refusalcode = '';
		      this.refusaldec = '';
		      this.$refs.autocomplete.searchText = '';
		      this.$refs.description.searchText = '';
	      }
    	},
    	refusalSelectedValue(obj) {
	      this.refusalcode = obj.code;
	      this.refusaldec = obj.description;
	      this.$refs.autocomplete.searchText = obj.code;
	      this.$refs.description.searchText = obj.description;
	      this.$emit("update:modelValue", obj.code);
	    },
	    getfild(obj){
	    	if (obj.length > 1) {
	      	this.$refs.description.$el.classList.add("selectloader");
	        axios
	          .get("/json_autocomplete/icd-code", {
	            params: { term: obj,filter:'z28' },
	          })
	          .then((response) => {
	            this.refusaldecList = response.data.data;
	            this.$refs.description.$el.classList.remove("selectloader");
	          });
	      }
	      if (obj.length == 0) {
	      	this.refusalCodeList = [];
	        this.refusaldecList = [];
	        this.refusalcode = '';
		      this.refusaldec = '';
		      this.$refs.autocomplete.searchText = '';
		      this.$refs.description.searchText = '';
	      }
	    },
	    refusaldecSelectedValue(obj){
	    	this.refusalcode = obj.code;
	      this.refusaldec = obj.description;
	      this.$refs.autocomplete.searchText = obj.code;
	      this.$refs.description.searchText = obj.description;
	      this.$emit("update:modelValue", obj.description);
	    },
  		AddNewVaccine(id){
  			if(this.form.cvx_code != ''){
  				var error_class = ".myselect"+id;
  				$(error_class).removeClass("Vaccine_error");
  				this.form.vaccination_status = 'Completed';
				var id = "#addvaccination"+id;
				$('.addvaccination').addClass("d-none");
				$('.vaccinerefusal').addClass("d-none");
				$(id).removeClass("d-none");
  			}
  			else{
  				var error_class = ".myselect"+id;
  				var error_msg = "#select_vaccine_error"+id;
  				$(error_class).addClass("Vaccine_error");
  				$(error_msg).removeClass("d-none");
  				
  			}
  		},
  		Refuse(id){
  			if(this.form.cvx_code != ''){
	  			var error_class = ".myselect"+id;
  				$(error_class).removeClass("Vaccine_error");
	  			var id = "#vaccinerefusal"+id;
					$('.vaccinerefusal').addClass("d-none");
					$('.addvaccination').addClass("d-none");
					this.form.vaccination_status = 'Refused';
					$(id).removeClass("d-none");	
				}
				else{
					var error_class = ".myselect"+id;
					var error_msg = "#select_vaccine_error"+id;
	  			$(error_class).addClass("Vaccine_error");
	  			$(error_msg).removeClass("d-none");
				}
  		},
  		vaccine_name(id){
  			this.form.vaccine_name = $( "#myselect"+id+" option:selected" ).text();
  			this.form.cvx_code =  this.form.cvx_code;
  			$('.select_vaccine_error').addClass("d-none");
  		},
  		getdateimu(){
			
  			if($('#vaccinated_on').val() == ''){
	        this.form.vaccinated_on = null;
	      }else{
	        	this.vaccinated_on = $('#vaccinated_on').val();	
		        this.form.vaccinated_on = moment(String(this.vaccinated_on)).format('YYYY-MM-DD');
				console.log(this.form.vaccinated_on);
	      }
	  		
    	},
    	save() {
    		this.form.ordering_doctor = this.$store.state.auth.activeProvider.first_name+' '+this.$store.state.auth.activeProvider.last_name;
    		this.form.administered_by = this.$store.state.auth.activeProvider.salutation+' '+this.$store.state.auth.activeProvider.first_name+' '+this.$store.state.auth.activeProvider.last_name+' '+this.$store.state.auth.activeProvider.suffix;
      		this.postForm("patient/"+ this.$route.params.id +"/vaccination/store", "VaccinesAvailable", true, "formdata");
    	},
    	formPostSuccess(data) {
      		this.$store.state.VaccinesAvailable.form = {};
      		this.v$.$reset();
      		Object.assign(this.$store.state.VaccinesAvailable.form);      
      		this.$store.dispatch("getSimpleTableContents", this.dataTypes);      
      		this.moshaToast("Record added successfully.", "success");
      		this.form.cvx_code ='';
      		this.refuse ='';
      		$('.addvaccination').addClass("d-none");
      		$('.vaccinerefusal').addClass("d-none");
      		this.$store.state.Agecvx.key = 1;
    	},
    	update(){
    		if (this.refusalcode != '') {
    			axios
			        .post("patient/"+ this.$route.params.id +"/vaccination/store",{
			        	cvx_code:this.form.cvx_code,
			        	vaccine_name:this.form.vaccine_name,
			        	refuse_reason_code:this.refusalcode,
			        	refuse_reason_description:this.refuse_dec,
			        	vaccination_status:this.form.vaccination_status,
			        })
			        .then((response) => {
						$('.addvaccination').addClass("d-none");
			      		$('.vaccinerefusal').addClass("d-none");
			          	this.$store.state.VaccinesAvailable.form = {};
			      		this.v$.$reset();
			      		Object.assign(this.$store.state.VaccinesAvailable.form);      
			      		this.$store.dispatch("getSimpleTableContents", this.dataTypes);
			      		this.moshaToast("Record added successfully.", "success");
			      		this.form.cvx_code ='';
			      		this.refusalCodeList = [];
				        this.refusaldecList = [];
				        this.refusalcode = '';
					      this.refusaldec = '';
					      this.$refs.autocomplete.searchText = '';
					      this.$refs.description.searchText = '';
			      		this.$store.state.Agecvx.key = 1;
			    });
    		}
    		else{
    			$('.refuse').addClass("error");
    			$('.refused').removeClass("d-none");

    		}
    	},
    	onkeyup(obj){
    		if(this.$refs.autocomplete.searchText != ''){
    			$('.refuse').removeClass("error");
    			$('.refused').addClass("d-none");
    		}
    	},
    	getuser(){
    		axios
			.post("patient/" + this.$route.params.id + "/vaccination/age-vaccination")
			.then((response) => {
			this.age_vaccination = response.data.data;
				this.$store.state.Agecvx.key = 0; 
			});
    	},
		rejected_reason_change(){
			this.refuse_dec = $("#rejected_reason option:selected").text();
		}
  	},
    watch: {
        "$store.state.Agecvx.key": function() {
          this.getuser();
        },
    }
}
</script>
<style >
	.Vaccine_error {
	    border: solid 1px red;
	}
	.refuse_error{
		border: solid 1px red;
	}	
</style>