<template>
  <div class="content-wrapper">
    <div class="container-full">
      <section class="content">
        <div class="row justify-content-sm-center">
            <div class="col-12 col-sm-12 col-md-9 col-lg-6">
              <div class="box">
                <div class="box-body">
                  <div class="content-header">
                    <h1 class="heading1 m-0">{{title}}</h1>
                  </div>

                  <form novalidate v-on:submit.prevent="onSubmit">
                    <div class="row">
								      <div class="col-12 col-sm-12 col-md-12">
                          <input-field 
                          title="Coupon Code"
                          id="coupon_code"
                          :isRequired="true"
                          v-model="form.coupon_code"
                          :errors="v$.form.coupon_code.$errors"
                          divClass="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                          labelClass="form-label col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                          >
                          </input-field>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 col-sm-12 col-md-12">
                          <text-area 
                            title="Description" 
                            v-model="form.description" 
                            :isRequired="false"
                            :rows="3"
                            labelClass ="col-12 col-sm-12 col-md-12"
                            divClass ="col-12 col-sm-12 col-md-12">
                          </text-area>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 form-group">
                        <check-box
                          label="Applicable for new patients only"
                          v-model="form.new_patient_flag"
                          labelClass=""
                          divClass="col-12 col-sm-2 col-md-6"
                        ></check-box>

                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 form-group">
                        <label class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 form-group">
                            Select Services
                          </label>
                          <Multiselect
                            class="form-control"
                            v-model="form.services_ids"
                            mode="tags"
                            :searchable="true"
                            valueProp="id"
                            placeholder="Select Services"
                            label="name"
                            trackBy="name"
                            :options="custom_procedure_list"
                            :canClear="false"
                          />
                      </div>
                    </div>

                    <div class="row">
                      <label class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0">Select Referral </label>
                    </div>
                    <div class="form-group row">
                      <div class="col-4 col-sm-4 col-md-4 col-lg-4">
                        <select-box-static
                          v-model="this.form.assign_user_type"
                          :option="assign_types"
                          label=""
                          labelClass="col-12 col-sm-12 col-md-12 col-lg-12"
                          divClass="col-12 col-sm-12 col-md-12 col-lg-12 form-group"
                          @change="getAssignUserData"
                        ></select-box-static>
                      </div>
                      <div class="col-8 col-sm-8 col-md-8 col-lg-8 form-group">
                        <div class="form-group row">

                        <Select2
                              id="assign_user_type"
                              v-model="this.form.assign_user_id"
                              placeholder="Select Assign User"
                              :options="assign_user"
                          />
                      </div>
                    </div>

                    </div>

                    <!-- <div class="row">
                      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 form-group">
                        <label class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 form-group">
                          Select Referral
                        </label>
                        <select
                          required
                          class="select2Single form-select form-control"
                          :value="modelValue" v-model="this.form.assign_user_id"
                          id="assign_user_id"
                          placeholder="Select Referral"
                          @input="staff_provider_id" 
                          @change="$emit('update:modelValue', $event.target.value);changeUserList();"
                          >
                              <optgroup label="Provider">
                                <template  v-for="item in staffList"  :key="item.id">
                                  <option :value="item.id" data-type="0" v-if="item.user_type == 'provider' ">
                                    {{ item.salutation }} {{ item.name }}
                                  </option>
                                </template>
                              </optgroup>  
                              <optgroup label="Staff">
                                <template  v-for="item in staffList"  :key="item.id">
                                  <option :value="item.id" data-type="1" v-if="item.user_type == 'staff' ">
                                    {{ item.name }}
                                  </option>
                                </template>
                              </optgroup>
                              <optgroup label="Refer">
                                <template  v-for="item in referList"  :key="item.id">
                                  <option :value="item.id" data-type="2">
                                    {{ item.text }}
                                  </option>
                                </template>
                              </optgroup>
                            </select>
                        </div>
                    </div> -->

                    <div class="row">
                      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 form-group">
                        <div class="col-12 col-sm-12 col-md-12">
                          <select-box-static
                            title="Discount Type"
                            v-model="form.type"
                            :errors="v$.form.type.$errors"
                            :option="discount_types"
                            isRequired="true"
                            labelClass="col-12 col-sm-12 col-md-12 col-lg-12"
                            divClass="col-12 col-sm-12 col-md-12 col-lg-12"
                            @change="changeDiscountType"
                          ></select-box-static>

                          </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 form-group">
                        <touch-spin 
                          title="Discount"
                          v-model="form.amount"
                          :errors="v$.form.amount.$errors"
                          isRequired="true"
                          labelClass="col-12 col-sm-12 col-md-12 col-lg-12 discountValLbl"
                          divClass="col-12 col-sm-12 col-md-12 col-lg-12"
                        >
                        </touch-spin>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 form-group">
                        <div class="form-group row">
                          <label class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 form-group">
                            Expiry Date<small class="text-danger asterisksign">*</small>
                          </label>
                          <div class="col-12 col-sm-12 col-md-12 col-lg-12 availdate" :class="{ error: v$.form.expiry_date.$errors.length }">
                          <ejs-datepicker 
                            id="expiry_date"
                            title="Expiry Date"
                            v-model="v$.form.expiry_date" 
                            :value="expiry_date"
                            :format="dateFormat" 
                            placeholder="select expiry date" 
                            name="expiry_date" 
                            :strictMode='strict' 
                            :max='maxVal'
                            :min="minVal"
                            :showClearButton='false'
                            isRequired="true"
                            @change="getdate($event)"
                            :openOnFocus='true'>
                         </ejs-datepicker>
                         <div class="error-msg">
                            <ul class="mb-0 p-0 fs-14">
                              <li class="d-block" v-for="(error, index) of v$.form.expiry_date.$errors" :key="index">
                                  {{ error.$message }}
                              </li>
                            </ul>
                          </div>
                        </div>
                        </div>
                      </div>
                    </div>
                    <form-btn :title="btnname" btnCls="btn-info saveData" ></form-btn>
                    <router-link
                      :to="{ name: 'CouponHome' }"
                      class="waves-effect waves-light btn me-1 btn-danger"
                      >Cancel</router-link
                    >

                  </form>

                </div>
              </div>
            </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import InputField from "@/components/base/formFields/InputField.vue";   
import FormBtn from "@/components/base/formFields/formBtn.vue";
import TextArea from "@/components/base/formFields/textArea.vue";
import Select2 from "@/components/base/formFields/select2.vue";
import MultiSelectBox from "@/components/base/formFields/multiSelectBox.vue";
import Multiselect from "@vueform/multiselect";
import SelectBoxStatic from "@/components/base/formFields/SelectBoxStatic.vue";
import TouchSpin from "@/components/base/formFields/Touchspin.vue";
import DatePicker from "@/components/base/formFields/datePicker.vue";
import axios from "@/scripts/axios.js";
import submitMixins from "@/scripts/mixins/submitMixins.js";
import useVuelidate from "@vuelidate/core";
import $ from 'jquery';
import moment from "moment";
import CheckBox from "@/components/base/formFields/checkBox.vue";
import Autocomplete from "vue3-autocomplete";

export default {
  mixins: [submitMixins],
  setup: () => {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      ServicesList: [{ id: 1, text: 'A' }, { id: 2, text: 'B' }],
      discount_types:[{ value: 0, title: 'Flat ($)' }, { value: 1, title: 'Percentage (%)' }],
      assign_types:[{ value: 0, title: 'Provider' }, { value: 1, title: 'Staff' }, { value: 2, title: 'Referral' }],
      assign_user: [],
      dateFormat: 'MM-dd-yyyy',
      minVal: new Date(),
      expiry_date: '',
      is_only_new_patient:0,
      custom_procedure_list: [],
      staffList:[],
      // assign_user_type:'',

    }
  },
  mounted(){
    this.form = {
      // assign_user_type: '',
      new_patient_flag:0,
      assign_user_id: 0,
    }
    this.getCustomProcedure();
    this.getUserData();
    this.changeDiscountType();
  },
  components: {
    InputField,
    FormBtn,
    TextArea,
    Select2,
    Multiselect,
    MultiSelectBox,
    TouchSpin,
    DatePicker,
    SelectBoxStatic,
    moment,
    CheckBox,
    Autocomplete
  },
  computed: {
    form() {
      return this.$store.state.Coupon.form;
    },
    title() {
      return this.$route.name === "CouponAdd" ? "New Coupon" : "Edit Coupon";
    },
    vuelidateExternalResults() {
      return this.$store.state.Coupon.vuelidateExternalResults;
    },
    validationRules() {
      return this.$store.state.Coupon.validationRules;
    },
    btnname() {
      return this.$route.name === "CouponAdd" ? "Save" : "Update";
    },
  },
  validationRules() {
      return this.$store.state.Coupon.validationRules;
  },
  validations() {
    return this.$store.state.Coupon.validationRules;
  },
  methods:{
    getUserData() {
			axios.get("/json_dropdown/user-list", {
			}).then(response => { 
				this.staffList = response.data.data 
			});
      axios.post("/referral/list")
        .then((response) => {
          this.referList = response.data.data.map((item) => {
					  return { id: item.id, text: item.referral_name, checked: true };
				  });
      }); 
		},
    reset(){
      this.$store.state.Coupon.form.coupon_code = "";
    },
    getdate($event){
        if($('#expiry_date').val() == '' && this.form.expiry_date == '' ) {
          this.form.expiry_date = null;
        } else {      
          this.form.expiry_date = moment($event.value).format('YYYY-MM-DD');
        }  
    },
    onSubmit () {
      // this.$store.state.Coupon.form.assign_user_type = this.assign_user_type;
      this.v$.$validate();
      this.$store.state.processing = true;
      if (this.$route.name === "CouponAdd") {
        this.postForm("coupon/store", 'coupon', true).then((response) =>{

        });
      } else {
        // this.postForm("coupon/store", 'coupon', true, {id: this.$route.params.id, user_type: this.assign_user_type}).then((response) =>{});
        this.postForm("coupon/store", 'coupon', true, {id: this.$route.params.id}).then((response) =>{});  
      }
    },
    formPostSuccess(data) {
      this.$store.state.processing = false;
      if (this.$route.name === "CouponAdd") {
        this.moshaToast("Record saved successfully .", "success");
      } else {
        axios.post("coupon/sync-to-pwm",{coupon_id:this.$route.params.id, 'mode':'auto'})
          .then((response) => {
        });
        this.moshaToast("Record updated successfully .", "success");
      }
      this.$router.push("/coupon/list");
    },
    getAssignUserData() {
      if (this.form.assign_user_type == '0') {
        axios.get("/provider/list")
        .then((response) => {
          this.assign_user = response.data.data.map((item) => {
					  return { id: item.id, text: item.full_name, checked: true };
				  });
        });
      } else if (this.form.assign_user_type == '1') {
        axios.get("/staff/list")
        .then((response) => {
          this.assign_user = response.data.data.map((item) => {
					  return { id: item.id, text: item.staff_name, checked: true };
				  });
        });
      } else {
        axios.post("/referral/list")
        .then((response) => {
          this.assign_user = response.data.data.map((item) => {
            return {id: item.id, text:item.referral_name,checked:true};
          });
        });
      }
   },
   getCustomProcedure(){
    axios.get("/custom-procedure/list?request_all_data=all")
        .then((response) => {
          this.custom_procedure_list = response.data.data.map((item) => {
					  return { id: item.id, name: item.code };
				  });
        });
   },
  //  changeUserList($event){
  //   this.assign_user_type = $("#assign_user_id").find('optgroup option:selected').data('type');
  //  },
   changeDiscountType() {
    if (this.$store.state.Coupon.form.type == '1') {
      $(".discountValLbl").text('Percentage Discount');
    } else if (this.$store.state.Coupon.form.type == '0'){
      $(".discountValLbl").text('Flat Discount');
    } else {
      $(".discountValLbl").text('Discount');
    }
   }
  },

  async beforeCreate(){
    if (this.$route.name === "CouponAdd") {
      this.$store.state.Coupon.form = {};
      this.is_only_new_patient = 0;
      this.$store.state.Coupon.form.type = '1';
      Object.assign(this.$store.state.Coupon.form);
    } else {
      await this.$store.dispatch("getEditData", {
        dataType: "Coupon", // pass the module name
        formType: "form", // pass the propery name
        id: this.$route.params.id,
      });
      this.expiry_date = this.$store.state.Coupon.form.expiry_date;
      this.is_only_new_patient = this.$store.state.Coupon.form.new_patient_flag;
      if (this.$store.state.Coupon.form.new_patient_flag == 1) {
        this.$store.state.Coupon.form.new_patient_flag = 1;
      } else {
        this.$store.state.Coupon.form.new_patient_flag = 0;
      }
      // this.assign_user_type = this.$store.state.Coupon.form.assign_user_type;
      this.getAssignUserData();
      this.getCustomProcedure();
      this.changeDiscountType();
    }
  },
  watch: {
      "$store.state.processing": function(newValue) {
        if(this.$store.state.processing){
          $(".saveData").text("Processing please wait..");
        }else{
          $(".saveData").text("Save");
        };
      },
    }, 

}
</script>