import {
    helpers,
    required,
    email,
    numeric,
} from "@vuelidate/validators";

const CorporateLifeStyleService = {
  namespaced: true,
  state: {
    editID: "",
    listUrl: "/IV_life_style/service/list",
    getEditUrl: "/IV_life_style/service/edit/:id",
    tableData: {
      header: [
        "Service name",
        "Category name",
        "Service Code",
        "Service Price",
        "Service Status",
        "Actions",
      ]
    },
    defaultFormData: {
      servicedata:[],
    }, 
    form: {},
    vuelidateExternalResults: {
      form: {
        category_id:[],
      }
    },
    validationRules: {
      form:{
        category_id: {
          required: helpers.withMessage("Category name is required", required),
        }
      }
    },
    list: [],
  },
  getters: {
    getTableHeaders: (state) => state.header,
    getTableContents: (state) => state.list,
    getListURL: (state) => state.listUrl,
  },
  mutations: {},
  actions: {},
};

export default CorporateLifeStyleService;