const RecurringAppointments = {
	namespaced: true,
  	state: {
    	listUrl: "/appointment/recurring-list",
		header: [
			{"provide":"Provider"},
			{"patient":"Patient"},
			{"original_scheduled_time":"Recurring Scheduled Time"},
			{"recur_description":"Recurring Information"},
			{"":"Recurring Appointments"},
			{"office_name":"Office & Exam Room"},
			{"created_at":"Details"},
			{"":"Delete From"},
		],
		pagination: {
			to: "",
			from: "",
			total: "",
			last_page: "",
			current_page: "",
			pageNavArr: [],
		},	
		list: [],	
  },
  getters: {
    getTableHeaders: (state) => state.header,
    getTableContents: (state) => state.list,
    getListURL: (state) => state.listUrl,
  },
  mutations: {},
  actions: {},
}

export default RecurringAppointments;
