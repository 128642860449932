<template>
  <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-10">
    <div class="box mb-10">
      <div class="box-body">
        <div class="pageheading"><h2 class="heading2 font-bold">Log Communication</h2></div>
        <div v-if="$route.params.appointments_id!=undefined && $route.params.appointments_id!=null">
					<AppointmentInsuranceBadge :AppointmentNumber="$route.params.appointments_id" />
				</div>
        <div class="table-responsive">
          <table class="table table-hover">
            <thead>
              <tr>
                <th>Title</th>
                <th>Duration</th>
                <th>Description</th>
                <th>Amount charged</th>
                <th>Type</th>
              </tr>
            </thead>
            <tbody v-if="list != ''">
              <tr v-for="(item, index) in list" :key="index">
                <td>{{item.title}}</td>
                <td>{{item.duration}} min</td>
                <td>{{item.message}}</td>
                <td v-if="item.case_charged">{{item.case_charged}}</td>
                <td v-else>-</td>
                <td v-if="item.type">{{item.type}}</td>
                <td v-else>-</td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="5" class="text-center">No data available</td>
              </tr>
            </tbody>
          </table>
        </div>
      <div class="form-group row" :class="{ error: v$.form.title.$errors.length }">
        <label
          class="col-form-label col-12 col-sm-4 col-md-4 col-lg-4 col-xl-3 text-md-end"
          >Title</label
        >
        <div class="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-4">
          <input
            type="text"
            class="form-control"
            id="title"
            placeholder="Title"
            v-model="form.title"
          />
          <div class="invalid-feedback " style="display:block;" v-for="(error, index) of v$.form.title.$errors" :key="index">                  
            {{ error.$message }}
          </div>
        </div>
        
      </div>
      <div class="form-group row">
        <label
          class="col-form-label col-12 col-sm-4 col-md-4 col-lg-4 col-xl-3 text-md-end"
          >Cash Charge</label
        >
        <div class="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-4">
          <input
            type="number"
            class="form-control"
            id="cashcharge"
            placeholder="Cash Charge"
            v-model="form.case_charged"
          />
        </div>
      </div>
      <div class="form-group row">
        <label
          class="col-form-label col-12 col-sm-4 col-md-4 col-lg-4 col-xl-3 text-md-end"
          >Type</label
        >
        <div class="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-4">
          <input
            type="text"
            class="form-control"
            id="type"
            placeholder="Type"
            v-model="form.type"
          />
        </div>
      </div>
      <div class="form-group row" :class="{ error: v$.form.duration.$errors.length }">
        <label class="col-form-label col-12 col-sm-4 col-md-4 col-lg-4 col-xl-3 text-md-end">Duration</label>
        <div class="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-4 position-relative">
          <input
            id="duration"
            class="e-field form-control"
            type="number"
            name="duration"
            min="5" 
            max="60"
            step="5"
            v-model="form.duration"
          />
          <small class="form-text text-muted position-absolute fs-14" style="right: 60px; top: 3px;">Minutes</small>
          <div class="invalid-feedback " style="display:block;" v-for="(error, index) of v$.form.duration.$errors" :key="index">                  
            {{ error.$message }}
          </div>
        </div> 
      </div>
      <div class="form-group row" :class="{ error: v$.form.message.$errors.length }">
        <label
          class="col-form-label col-12 col-sm-4 col-md-4 col-lg-4 col-xl-3 text-md-end"
          >Message</label
        >
        <div class="col-12 col-sm-8 col-md-8 col-lg-5 col-xl-4">
          <textarea
            rows="2"
            class="form-control"
            placeholder="Messages"
            v-model="form.message"
          ></textarea>
          <div class="invalid-feedback " style="display:block;" v-for="(error, index) of v$.form.message.$errors" :key="index">                  
            {{ error.$message }}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-10 text-center">
          <button
            type="submit"
            class="waves-effect waves-light btn btn-info me-1"
            @click="save"
          >
            Log Communication
          </button>
          <button @click="closeModel" class="waves-effect waves-light btn btn-danger me-1">Close</button>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
  import axios from "@/scripts/axios.js";
  import { createToast, withProps } from 'mosha-vue-toastify';
  import 'mosha-vue-toastify/dist/style.css';
  import useVuelidate from "@vuelidate/core";
  import AppointmentInsuranceBadge from "@/components/Appointments/AppointmentInsuranceBadge.vue";
  import {
    required,
    helpers,
    minLength,
    maxLength,
    requiredIf,
  } from "@vuelidate/validators";
  export default {
    components: { AppointmentInsuranceBadge },
    setup: () => {
        const moshaToast = () => {
          createToast("Record added successfully",{
            timeout: 4000,
            position: "top-right",
            type: "success",
            transition: "slide",
            showIcon: true,
            hideProgressBar: false,
            toastBackgroundColor: "#05825f",
            timeout: 2000, 
            })
      }
      return { moshaToast ,v$: useVuelidate() }
    },
    data() {
      return {
        list:[],
        form:{},
      }
    },
    created() {
      if(this.$store.state.scheduleappointment.patient_id != ''){
        this.form.appointment_id = this.$store.state.scheduleappointment.appointment_id;
        this.form.patient_id = this.$store.state.scheduleappointment.patient_id;
        this.form.duration = 5;
        this.getData();
      }
      else{
        this.$router.push(`/patient-chart/${this.$route.params.id}/edit-appointment/${this.$route.params.appointments_id}`); 
      }
    },
    methods: {
      closeModel(){
          this.$router.push(`/patient-chart/${this.$route.params.id}/appointments/`); 
      },
      async getData() {
        this.form.appointment_id = this.$store.state.scheduleappointment.appointment_id;
        this.form.patient_id = this.$store.state.scheduleappointment.patient_id;
        this.$store.state.loader = true;
        const result = await axios.post("appointment/phonelog/list", {
          appointment_id: this.$store.state.scheduleappointment.appointment_id,
          patient_id: this.$store.state.scheduleappointment.patient_id,
        });
        this.list = result.data.data;
        this.$store.state.loader = false;
        console.log(this.list);
      },
      save(){
        this.form.appointment_id = this.$store.state.scheduleappointment.appointment_id;
        this.form.patient_id = this.$store.state.scheduleappointment.patient_id;
        var url = "appointment/phonelog/store";
        this.v$.$validate();

        if (!this.v$.$error) {
          axios
          .post(url, this.form)
          .then((response) => {
            this.form = {};
            this.v$.$reset();
            this.form.appointment_id = this.$store.state.scheduleappointment.appointment_id;
            this.form.patient_id = this.$store.state.scheduleappointment.patient_id;
            this.form.duration = 5;
            this.getData();
            this.moshaToast("Record added successfully.", "success");
          })
          .catch((error) => {
              this.error = error;
              console.log(this.error);
          });
        }
      }
    },
    validations() {
      return {
        form: {
          title: {
            required: helpers.withMessage("Title is required.", required),
          },
          duration:{
            required: helpers.withMessage("Duration is required.", required),
          },
          message:{
            required: helpers.withMessage("Message is required.", required),
          }
        },
      };
    },
  }
</script>
