import {
  helpers,
  required,
} from "@vuelidate/validators";

const leave = {
  namespaced: true,

  state: {
    listUrl: "/leave/list",
    getEditUrl: "/leave/edit/:id",
    header: [
      {'username':'Name'},
      {'leave_master.from_date':'Date'},
      {'Days':'No of Days'},
      {'leave_master.status':'Status'},
      {'Action':'Action'},
    ],
    pagination: {
      to: "",
      from: "",
      total: "",
      last_page: "",
      current_page: "",
      pageNavArr: [],
    },
    filter: {
      search_text: '',
      staff_provider_id: '',
      status: '',
      from_date: '',
      to_date: '',
    },
    validationRules: {
        form: {
           staff_provider_id: {
              required: helpers.withMessage('Username is required.', required) ,
           },
           to_date:{
              required : helpers.withMessage('From Date is required.', required),
           },
           from_date: {
              required : helpers.withMessage('To Date is required.', required),
           }, 
           details: {
              required : helpers.withMessage('Detail is required.', required),
           }
       },
    },
    vuelidateExternalResults: {
        form : {
          staff_provider_id : '',
          date_range : '',
          from_date : '',
          to_date : '',
          details : '',
        }
    },
    form : {
      staff_provider_id : '',
      date_range : '',
      details : '',
      from_date : '',
      to_date : '',
    },
    defaultFormData : {
     staff_provider_id : '',
     details : '',
     date_range : '',
     from_date : '',
     to_date : '',
   },
    list: [],
  },

  getters: {
    getTableHeaders: (state) => state.header,
    getTableContents: (state) => state.list,
    getListURL: (state) => state.listUrl,
  },
  mutations: {},
  actions: {},
};

const leaveArchived = {
  namespaced: true,

  state: {
    listUrl: "/leave/completed-leave",
    getEditUrl: "/leave/edit/:id",
    tableData: {
      header: [
        'Name',
        'From Date',
        'To Date',
        'No of Days',
        'Status',
      ],
    },
    list: [],
  },

  getters: {
    getTableHeaders: (state) => state.header,
    getTableContents: (state) => state.list,
    getListURL: (state) => state.listUrl,
  },
  mutations: {},
  actions: {},
};

const leaveAllArchived = {
  namespaced: true,

  state: {
    listUrl: "/leave/archived-leave",
    getEditUrl: "/leave/edit/:id",
    tableData: {
      header: [
        'Name',
        'From Date',
        'To Date',
        'No of Days',
        'Status',
        'Actions',
      ],
    },
    list: [],
  },
  getters: {
    getTableHeaders: (state) => state.header,
    getTableContents: (state) => state.list,
    getListURL: (state) => state.listUrl,
  },
  mutations: {},
  actions: {},
}

export {leave,leaveArchived,leaveAllArchived};
