<template>
	<form novalidate autocomplete="off">
	  <div class="panel-group panel-group-simple panel-group-continuous mb-0" id="category-1" aria-multiselectable="true" role="tablist">
		 <div class="panel pagestabscoll">
			  <div class="pageheading">
				<h1 class="heading1 mt-0 d-inline-block me-2">Personal Info </h1>
				<Popper arrow content="It is used to edit the logged-in user's Personal Information." :hover="true" class="helptips cursor-pointer">
					<i class="fa fa-info-circle"></i>
				</Popper>
			  </div>
			  <div class="panel-collapse collapse show" id="answer-1" aria-labelledby="question-1" role="tabpanel" data-bs-parent="#category-1">
				  <div class="form-group row">
					 <label class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end">Salutation</label>
					 <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3">
						<div class="controls">
							<select
							  name="salutation"
							  v-model="form.salutation"
							  class="form-select form-control form-control">
							  <option value="" disabled>---------</option>
							  <option value="Dr.">Dr.</option>
							  <option value="Prof.">Prof.</option>
							  <option value="Prac.">Prac.</option>
							  <option value="D.O.">D.O.</option>
							</select>
						</div>
					 </div>
				  </div>

				  <input-field
					 title="First Name"
					 id="first_name"
					 v-model="v$.form.first_name.$model"
					 :errors="v$.form.first_name.$errors"
					 :isRequired="true">
				  </input-field>
				  <input-field
					 title="Last Name"
					 id="last_name"
					 v-model="v$.form.last_name.$model"
					 :errors="v$.form.last_name.$errors"
					 :isRequired="true">
				  </input-field>
				  <input-field
					 title="Suffix"
					 v-model="form.suffix"
					 placeholder="e.g. Jr., Sr., I, II">
				  </input-field>
				  <input-field
					 id="email"
					 title="E-mail Address"
					 :isRequired="true"
					 v-model="v$.form.email.$model"
					 :errors="v$.form.email.$errors">
				  </input-field>
				  <input-field
					 id="cell_phone"
					 title="Cell Phone"
					 v-model="v$.form.cell_phone.$model"
					 :errors="v$.form.cell_phone.$errors"
					 :isRequired="true"
					 mask="(000) 000-0000">
				  </input-field>
				  <div class="form-group row" :class="{ error: v$.form.country_id.$errors.length }">
					 <label class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end">Country <small class="text-danger asterisksign">*</small></label>
					 <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3">
						<Select2
							v-model="v$.form.country_id.$model"
							id="country_id"
							placeholder="Select Country"
							:options="countries"
							@select="countryChange($event)"/>
						<div class="invalid-feedback" style="display:block;" v-for="(error, index) of v$.form.country_id.$errors" :key="index">
							{{ error.$message }}
						</div>
					 </div>
				  </div>
				  <input-field 
					 title="Zipcode" 
					 v-model="form.zip_code" 
					 @focusout="zipcode"> 
				  </input-field>
				  <div class="form-group row" :class="{ error: v$.form.state_id.$errors.length }">
					 <label class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end">State <small class="text-danger asterisksign"> *</small></label>
					 <div class="col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3">
						<Select2
							v-model="form.state_id"
							id="state_id"
							placeholder="Select State"
							:options="states"/>
						<div class="invalid-feedback" style="display:block;" v-for="(error, index) of v$.form.state_id.$errors" :key="index">
							{{ error.$message }}
						</div>
					 </div>
				  </div>
				  <input-field
					 title="City"
					 v-model="form.city"
					 placeholder="City">
				  </input-field>
				  <text-area 
					 title="Address" 
					 v-model="form.address"> 
				  </text-area>
			</div>
		  </div>
	  </div>
	</form>
	<div class="row">
	  <div class="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-9 text-center">
		<form-btn title="Save" btnCls="btn-info" @click="save(false)" :disabled="disabled" id="submit"></form-btn>
		<button type="button" class="waves-effect waves-light btn me-1 btn-danger"  @click="getuser('cancle')">Cancel</button>
	  </div>
	</div>
</template>
<script>
	import { mapGetters, mapActions } from "vuex";
	import InputField from "@/components/base/formFields/InputField.vue";
	import CheckBox from "@/components/base/formFields/checkBox.vue";
	import SelectBox from "@/components/base/formFields/selectBox.vue";
	import StateSelectBox from "@/components/base/formFields/stateSelectBox.vue";
	import MultiSelectBox from "@/components/base/formFields/multiSelectBox.vue";
	import FileUpload from "@/components/base/formFields/fileUpload.vue";
	import TextArea from "@/components/base/formFields/textArea.vue";
	import FormBtn from "@/components/base/formFields/formBtn.vue";
	import DatePicker from "@/components/base/formFields/datePicker.vue";
	import Select2 from "@/components/base/formFields/select2.vue";
	import useVuelidate from "@vuelidate/core";
	import submitMixins from "@/scripts/mixins/submitMixins.js";
	import axios from "@/scripts/axios.js";
	import Password from "@/components/base/formFields/password.vue";
	import $ from "jquery";
	import moment from "moment";
	import Popper from "vue3-popper";
	import {
	 helpers,
	 required,
	 email,
	 minLength,
	 maxLength,
	 sameAs,
   } from "@vuelidate/validators";
   
   export default {
	 mixins: [submitMixins],
	 setup: () => {
		return { v$: useVuelidate() };
	 },
	 data() {
		return {
		 user_id: '',
		 saveAndNext: false,
		 countries: [],
		 states: [],
		 user_credential_id:'',
		 disabled:true,
		 count:0,
		 checkButton:'save',
		};
	 },

	 components: {Select2,DatePicker,InputField,CheckBox,SelectBox,FileUpload,TextArea,FormBtn,StateSelectBox,MultiSelectBox,Password,Popper},

	 watch: {
		"user": function() {
		 this.getuser('save');
		},
	 },
	 computed: {
		...mapGetters("auth", ["user", "provider_list", "active_provider"]),
		
		form() {
		 return this.$store.state.personalinfo.form;
		},

		vuelidateExternalResults() {
		 return this.$store.state.personalinfo.vuelidateExternalResults;
		},

		validationRules() {
		 return this.$store.state.personalinfo.validationRules;
		},

	 },
	 mounted(){
			this.checkButton = 'save';
			axios.get("/json_autocomplete/country").then(
			 (response) =>
				(this.countries = response.data.data.map((country) => {
				 return { id: country.id, text: country.name };
				}))
			);
			let $this = this;
			$("select, input, textarea").on("change keyup", function(e) {
				if ($this.count >= 4) {
					this.disabled = false;
					$("#submit").removeAttr('disabled');
				}
				$this.count = $this.count +1;
				})
				this.getuser('save');	
	 },
	 methods: {
		zipcode(){
		  if(this.form.zip_code && this.form.zip_code.length > 3 ){
			axios.post("json_dropdown/state-city-auto-populations",{zip_code:this.form.zip_code})
			.then((response) => {
			  this.form.city=response.data.data.City;
			  this.form.state_id=response.data.data.id
			});
		  }
		  else{
			this.form.city= null;
			this.form.state_id=null;
		  }
		},  

		countryChange({ id, text }) {
		  axios.post("/json_autocomplete/" + id + "/state")
		  .then((response) =>(this.states = response.data.data.map((state) => {
				return { id: state.id, text: state.name };
			 }))
		  );
		},

		save() {
		  if(this.user.user_type == "provider"){
			axios.post("provider/update",this.form)
			.then((response) => {
			  this.moshaToast("Record updated successfully.", "success");
			});
		  }
		  if(this.user.user_type == "staff"){
			axios.post("staff/update",this.form)
			.then((response) => {
			  this.moshaToast("Record updated successfully.", "success");
			}); 
		  }
		  $("#submit").attr("disabled", "disabled");
		},
		getuser(type) {
			if (type == 'cancle') {
				this.count = 2;
			}
		  var user_id = this.$store.state.auth.userData.user_id;
		  if(this.$store.state.auth.userData.user_type == "provider"){
			axios.get(`/provider/${user_id}/edit`)
			.then((response) => {
			 // console.log(response);
			  this.form.salutation = response.data.data.salutation;
			  this.form.first_name = response.data.data.first_name;
			  this.form.last_name = response.data.data.last_name;
			  this.form.suffix = response.data.data.suffix;
			  this.form.email = response.data.data.email;
			  this.form.cell_phone = response.data.data.cell_phone;
			  this.form.country_id = response.data.data.country_id;
			  this.form.state_id = response.data.data.state_id;
			  this.form.city = response.data.data.city;
			  this.form.address = response.data.data.address;
			  this.form.provider_id = response.data.data.id;
			  this.form.supervisor_sign_needed = response.data.data.supervisor_sign_needed;
			  this.form.is_supervisor = response.data.data.is_supervisor;
			  this.form.zip_code = response.data.data.zip_code;
			  this.form.date_of_birth = this.$store.state.auth.userData.date_of_birth;
			  this.form.dea_number = this.$store.state.auth.userData.dea_number;
			  this.form.job_title_id = this.$store.state.auth.userData.job_title_id;
			  this.form.office_access = [this.$store.state.auth.userData.office_access];
			  this.getstate(this.form.country_id)
			});
		  }

		  if(this.$store.state.auth.userData.user_type == "staff"){
			axios.get(`staff/edit/${user_id}`)
			.then((response) => {
			  this.form.primary_provider_id = response.data.data.primary_provider_id;
			  this.form.role_id = response.data.data.role_id;
			  this.form.salutation = response.data.data.salutation;
			  this.form.first_name = response.data.data.first_name;
			  this.form.last_name = response.data.data.last_name;
			  this.form.suffix = response.data.data.suffix;
			  this.form.email = response.data.data.email;
			  this.form.cell_phone = response.data.data.cell_phone;
			  this.form.country_id = response.data.data.country_id;
			  this.form.state_id = response.data.data.state_id;
			  this.form.city = response.data.data.city;
			  this.form.address = response.data.data.address;
			  this.form.id = response.data.data.id;
			  this.form.supervisor_sign_needed = response.data.data.supervisor_sign_needed;
			  this.form.is_supervisor = response.data.data.is_supervisor;
			  this.form.zip_code = response.data.data.zip_code;
			  this.form.date_of_birth = this.$store.state.auth.userData.date_of_birth;
			  this.form.dea_number = this.$store.state.auth.userData.dea_number;
			  this.form.job_title_id = this.$store.state.auth.userData.job_title_id;
			  this.form.office_access = [this.$store.state.auth.userData.office_access];
			  this.getstate(this.form.country_id)
			});
		  }
		},
		getstate(id)
		{
		  axios.post(`/json_autocomplete/${id}/state`)
		  .then((response) =>(this.states = response.data.data.map((state) => {
				return { id: state.id, text: state.name };
			}))
		  );
		}
	 },
	 validations() {
		return this.$store.state.personalinfo.validationRules;
	 },
   };
</script>