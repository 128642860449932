<template>
    <div class="content-wrapper">
    	<div class="container-full">
      		<div class="content-header">
        		<div class="row justify-content-between">
          			<div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xs-6">
            			<h1 class="heading1 my-5"> Shift Provider's Appointments</h1>
          			</div>
        		</div>
      		</div>
            <section class="content">
        		<div class="box">
          			<div class="box-body">
                        <div class="row">
                            <div class="col-6">
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Patient Details</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <slot v-for="(item, index) in appointmentlist" :key="index">
                                            <tr>
                                                <td>
                                                    <div class="row">
                                                        <div class="col-6">
                                                            <div>
                                                                <div class="me-5">
                                                                    <b>Patient : </b>
                                                                    <router-link :to="{ name: 'PatientDetails',params: { id: item[0].unique_id },}">
                                                                        {{capitalizeFirstLetter(item[0].first_name)}} {{capitalizeFirstLetter(item[0].middle_name)}} {{capitalizeFirstLetter(item[0].last_name)}}
                                                                    </router-link>
                                                                </div>
                                                                <div class="me-5">
                                                                    <b>Number of Appointments : </b> {{item.length}}
                                                                </div>
                                                                <a href="javascript:void(0);" class="waves-effect waves-light btn btn-info btn-sm me-1" @click="openListModal(item)" title="Appointment Details">
                                                                    Preview
                                                                </a>       
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="form-group">
                                                                <select class="form-select" :id="'provider_swap_id'+index">
                                                                    <option selected="selected" value="">Select Provider</option>
                                                                    <slot v-for="(item,index) in providerList" :key="index">
                                                                        <option :value="item.id">{{item.salutation}} {{item.first_name}} {{item.last_name}}</option>
                                                                    </slot>
                                                                </select>
                                                            </div>
                                                            <div  class="text-center mt-10" style="background: #a0cde7;">
                                                                <a href="javascript:void(0);" @click="MoveAppointments(index)">
                                                                    Shift <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </slot>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-6" id="responseList">
                                <slot v-for="(item,index) in responseList" :key="index">
                                    <slot v-if="responseList[index].length > 0">
                                        <div class="bg-info-light p-10 py-5 rounded5 fs-14 mb-10">
                                            <b>{{capitalizeFirstLetter(index)}}</b>
                                            <ul>
                                                <slot v-if="index=='office'">
                                                    <slot v-for="(item1,index1) in item" :key="index1">
                                                        <li> Please Add Provider's Schedule for office : <router-link target="_blank" :to="'/provider/edit/scheduling/'+provider_swap_id"> <b> {{item1.office_name}} </b> </router-link> </li>
                                                    </slot>
                                                </slot>
                                                <slot v-else>
                                                    <slot v-for="(item1,index1) in item" :key="index1">
                                                        <li :id="'pointment'+item1.id"> 
                                                            Provider Not Available on {{item1.schedule_date}} between {{item1.schedule_time}} & {{item1.schedule_end_time}}
                                                            <br>Check Available slots for appointment <a href="javascript:void(0);" @click="getavailableSlots(item1)"> <b> #{{item1.appointment_number}} </b></a>
                                                        </li>
                                                    </slot>
                                                </slot>
                                            </ul>
                                        </div>
                                    </slot>
                                </slot>
                            </div>
                        </div>
                        <div class="row" v-if="patient_ids.length > 0">
                            <div class="col-6 bg-info-light p-10 py-5 rounded5 fs-14 mb-10">
                                Found {{patient_ids.length}} patients with no appointment or having past appointment.
                                <div class="form-group">
                                    <select class="form-select" id='provider_swap_id'>
                                        <option selected="selected" value="">Select Provider</option>
                                        <slot v-for="(item,index) in providerList" :key="index">
                                            <option :value="item.id">{{item.salutation}} {{item.first_name}} {{item.last_name}}</option>
                                        </slot>
                                    </select>
                                </div>
                                <a href="#" @click="movePatient(patient_ids)"> <b>Shift Patients<i class="fa fa-angle-double-right" aria-hidden="true"></i></b> </a>
                            </div>
                        </div>
                    </div>
                </div>
                <!--Appointment List model start-->
                <div id="AppointmentList" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-lg">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h3 class="modal-title fw-900 mt-0">
                                    Appointment List
                                </h3>
                            </div>
                            <div class="modal-body">
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Schedule Time</th>
                                            <th>Duration</th>
                                            <th>Reason</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <slot v-for="(item, index) in appointmentsDetails" :key="index">
                                            <tr>
                                                <td>{{ dateFormated(item.schedule_date,item.schedule_time) }}</td>
                                                <td>{{ item.duration }} Mins</td>
                                                <td>{{ item.reason_for_visit }} Mins</td>
                                            </tr>
                                        </slot>
                                    </tbody>
                                </table>
                            </div>
                            <div class="modal-footer">
                                <button type="button" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <!--Appointment List model end-->
                <!--model start-->
                <div id="displayavailableslotsmodal" class="modal fade" tabindex="-1" role="dialog"  aria-labelledby="displayavailableslotsmodal" aria-hidden="true">
                    <div class="modal-dialog modal-sm">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h3 class="modal-title fw-900 mt-0">CHOOSE A FREE SLOT</h3>
                            </div>   
                            <div class="modal-body">
                                <div class="form-group mb-0" v-for="(item,index) in slot_suggestion_list" :key="index">
                                    <input v-model="slot_suggestion_time" type="radio" v-bind:id="'suggestion'+index" name="suggestion" class="filled-in chk-col-info" :value="item.schedule_time">
                                    <label v-bind:for="'suggestion'+index">{{item.value}}</label>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-info" @click="select_schedule_slot">Schedule</button>
                            </div>
                        </div>
                    </div>
                </div>
                <!--model end-->
            </section>            
        </div>
    </div>
</template>

<script>
import axios from "@/scripts/axios.js";
import moment from "moment";

export default {
    data() {
        return {
            appointmentlist: [],
            appointmentsDetails: [],
            providerList: [],
            provider_swap_id: "",
            selected_data: [],
            responseList: [],
            slot_suggestion_time: null,
            appointment_id: null,
            indexId: null,
            slot_suggestion_list: [],
            patient_ids: [],
        };
    },
    methods: {
        dateFormated(dt, time) {
            return moment(dt + " " + time).format("ddd MMM DD, YYYY h:mm A");
        },
        capitalizeFirstLetter(string) {
            if(string!=null){
                return string.charAt(0).toUpperCase() + string.slice(1);
            }else{
                return null;
            }
        },
        openListModal(list){
            this.appointmentsDetails = list;
            $("#AppointmentList").modal("show");
        },
        MoveAppointments(id){
            $("#provider_swap_id"+id).parent().removeClass("error");
            if($("#provider_swap_id"+id).val()){
                this.provider_swap_id = $("#provider_swap_id"+id).val();
                this.indexId = id;
                var providerId = $("#provider_swap_id"+id).val();
                this.$store.state.loader = true;
                axios.post("provider/moveAppointmentsAndPatients", { selected_data: this.appointmentlist[id], provider_id: providerId })
                .then((response) => {
                    this.$store.state.loader = false;
                    this.responseList = response.data.data;
                    if(response.data.data.count == 0){
                        delete this.appointmentlist[id];
                        if(this.appointmentlist && Object.keys(this.appointmentlist).length === 0 && Object.getPrototypeOf(this.appointmentlist) === Object.prototype){
                            var self = this;
                            this.$swal.fire({
                                input: 'textarea',
                                inputLabel: 'Enter reason for delete.',
                                inputPlaceholder: 'Enter reason for delete...',
                                inputAttributes: {
                                    'aria-label': 'Enter reason for delete'
                                },
                                allowOutsideClick: false,
                                showCancelButton: false,
                                confirmButtonText: 'Submit', 
                                inputValidator: function(inputValue) {
                                    return new Promise(function(resolve, reject) {
                                        if (inputValue && inputValue.length > 0) {
                                            resolve();
                                        } else {
                                            self.$swal.showValidationMessage('Can not be blank') 
                                            self.$swal.enableInput()
                                            self.$swal.enableButtons()
                                        }
                                    });
                                }                        
                            })
                            .then((result) => {
                                if (result.isConfirmed == true) {						
                                    axios.post(`/provider/delete`, { provider_id: this.$route.params.id, reason_for_delete: result.value  })
                                    .then((response) => {
                                        this.$swal.fire("Deleted!", "Provider has been deleted.", "success")
                                        .then((result) => {
                                            if (result.isConfirmed == true) {
                                                this.$router.push({ name: "ProviderList" });
                                            }
                                        });
                                    });
                                }
                            });                    
                        }                    
                    }else{                        
                        $('html, body').animate({
                            'scrollTop' : $("#responseList").position().top
                        });
                    }
                });
            }else{
                $("#provider_swap_id"+id).parent().addClass("error");
                $('html, body').animate({
                    'scrollTop' : $("#provider_swap_id"+id).position().top
                });
            }
        },
        getavailableSlots(data){
            this.$store.state.loader = true;
            axios.post("provider/getAvailableSlots", { selected_data: data, provider_id: this.provider_swap_id })
            .then((response) => {
                this.$store.state.loader = false;
                this.slot_suggestion_list = response.data.data.slots
                this.appointment_id = response.data.data.appointment_id
            });
            $("#displayavailableslotsmodal").modal("show");
        },
        select_schedule_slot(){
            this.$store.state.loader = true;
            axios.post("provider/appointmentTimeChange", { appointment_id: this.appointment_id, startTime : this.slot_suggestion_time, provider_id: this.provider_swap_id })
            .then((response) => {
                this.$store.state.loader = false;
                if(response.data.data == 0){
                    delete this.appointmentlist[this.indexId];
                    this.responseList = [];
                }else{
                    $("#pointment"+this.appointment_id).remove();
                }
                if(this.appointmentlist && Object.keys(this.appointmentlist).length === 0 && Object.getPrototypeOf(this.appointmentlist) === Object.prototype){
                    var self = this;
                    this.$swal.fire({
                        input: 'textarea',
                        inputLabel: 'Enter reason for delete.',
                        inputPlaceholder: 'Enter reason for delete...',
                        inputAttributes: {
                            'aria-label': 'Enter reason for delete'
                        },
                        allowOutsideClick: false,
                        showCancelButton: false,
                        confirmButtonText: 'Submit', 
                        inputValidator: function(inputValue) {
                            return new Promise(function(resolve, reject) {
                                if (inputValue && inputValue.length > 0) {
                                    resolve();
                                } else {
                                    self.$swal.showValidationMessage('Can not be blank') 
                                    self.$swal.enableInput()
                                    self.$swal.enableButtons()
                                }
                            });
                        }                        
                    })
                    .then((result) => {
                        if (result.isConfirmed == true) {						
                            axios.post(`/provider/delete`, { provider_id: this.$route.params.id, reason_for_delete: result.value  })
                            .then((response) => {
                                this.$swal.fire("Deleted!", "Provider has been deleted.", "success")
                                .then((result) => {
                                    if (result.isConfirmed == true) {
                                        this.$router.push({ name: "ProviderList" });
                                    }
                                });
                            });
                        }
                    });                    
                }
            });
        },
        movePatient(){
            if($("#provider_swap_id"+id).val()){
                var id  = $("#provider_swap_id").val();
                axios.post("provider/shiftPatient", { id: id, patient_ids: this.patient_ids })
                .then((response) => {
                    
                });
            }else{
                $("#provider_swap_id").parent().addClass("error");
                $('html, body').animate({
                    'scrollTop' : $("#provider_swap_id").position().top
                });
            }
        },
    },
    mounted() {
        this.$store.state.loader = true;
        axios.post("provider/availabilityCheck", { id: this.$route.params.id })
            .then((response) => {
                this.$store.state.loader = false;
                this.appointmentlist = response.data.data.data;
                this.providerList = response.data.data.provider_list;
                this.patient_ids = response.data.data.patient_ids;
            });
    },
}
</script>