<template>
  <div class="mdc-tab">
      <div class="tab-content">
          <div class="tab-pane active" id="billingSummary" role="tabpanel">
                <div class="box-body text-dark">
                    <div class="table-responsive">
                      <table class="table fs-16">
                        <thead>
                          <tr class="text-center">
                            <th></th>
                            <th>Patient Name</th>
                            <th>Event Started At</th>
                            <th>Status</th>
                          </tr>
                        </thead>

                        <tbody tbody v-for="item in patientWorkFlowList.slice(this.start,this.end)" v-if="patientWorkFlowList.length>0">
                          <tr>
                            <td>
                              <a href="javascript:void(0)" class="waves-effect waves-light btn btn-info-light btn-xs allopenbtn" @click="getSubDetails(item.id)" v-bind:id="'add'+item.id">
                                <i class="fa fa-plus"></i>
                              </a>
                              <a href="javascript:void(0)" class="waves-effect waves-light btn btn-danger-light btn-xs allclosebtn d-none" @click="getSubDetailsClose(item.id)" v-bind:id="'min'+item.id">
                                <i class="fa fa-minus"></i>
                              </a>
                            </td>
                            <td>{{item.full_name}} </td>
                            <td>{{ $filters.displayDateTimeLocal(item.event_started_at)}} </td>
                            <td>{{item.status}} </td>
                          </tr>
                          <tr class="d-none receipt_hide" v-bind:id="'receipt'+item.id" >
                            <td class="b-1" colspan="11" style="background: rgb(161 201 241);">
                              <table class="table">
                                 <thead>
                                  <tr>
                                    <th>Action Name</th>
                                    <th>Action Type</th>
                                    <th>Event Triggered At</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr v-for="(subitem,subindex) in patientWorkFlowSubList" v-if="patientWorkFlowSubList.length > 0">
                                    <td>{{ subitem.reminder_action_name }}</td>
                                    <td>{{ subitem.reminder_type }}</td>
                                    <td>{{ $filters.displayDateTimeLocal(subitem.event_triggered_at) }}</td>
                                  </tr>
                                  <tr v-if="patientWorkFlowSubList.length == 0">
                                    <td colspan="4"><center>No Record Found.</center></td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                        <tbody v-if="patientWorkFlowList.length==0">
                          <tr>
                            <td colspan="4" class="text-center"> No data available</td>
                          </tr>
                        </tbody>
                      </table>
                      <label>Showing {{start}} to {{(end >= list_lenght )? list_lenght : end}} of {{list_lenght}} entries</label>
                      <a v-if="end < list_lenght" href="javascript:void(0)" class="btn btn-light float-end" @click="next(this.end)">Next</a>
                      <p class="float-end">
                         <a href="javascript:void(0)" class="btn btn-light me-5" v-for="item in steps" @click="pagejump(item)">{{item}}</a>
                      </p>
                      <a v-if="this.start != 0" href="javascript:void(0)" class="btn btn-light float-end me-5" @click="previous(this.end,this.start)">Previous</a>
                    </div>
                </div>
          </div>
      </div>
  </div>
</template>

<script>
import axios from "@/scripts/axios.js";
import submitMixins from "@/scripts/mixins/submitMixins.js";
import permissionMixins from "@/scripts/mixins/permission.js";
import Popper from "vue3-popper";

export default {
  name: "Workflow",
  props: {
      workflowId: {
        type: Number,
        required: true
      }
  },
  data() {
    return {
      patientWorkFlowList :[],
      patientWorkFlowSubList :[],
      list_lenght:'',
      start:0,
      end:10,
      step:10,
      steps:'',
    }
  },
  mixins: [submitMixins, permissionMixins],
  components: { Popper },
  methods: {
    getSubDetailsClose(id) {
      $("#add"+id).removeClass('d-none');
      $("#min"+id).addClass('d-none');
      $("#receipt"+id).addClass('d-none');
    },
    getSubDetails(id) {
      $('.receipt_hide').addClass('d-none');
      $('.allclosebtn').addClass('d-none');
      $('.allopenbtn').removeClass('d-none');
      var receiptid = "#receipt"+id;
      $(receiptid).removeClass('d-none');
      $("#add"+id).addClass('d-none');
      $("#min"+id).removeClass('d-none');
      this.$store.state.loader = true;

      axios
      .post('/crm/workflow/getPatientWorkFlowSub',{ workflow_id : id })
      .then((response) => {
          this.patientWorkFlowSubList = response.data.data;
          this.$store.state.loader = false;
      }).catch((error) => {
        this.$store.state.loader = false;
        this.moshaToast(error.response.data.message, "danger");
      });
    },
    getPatientWorkFlow(id) {
      axios
      .post('/crm/workflow/getPatientWorkFlow',{ workflow_id : id })
      .then((response) => {
        this.patientWorkFlowList = response.data.data;
        this.list_lenght = this.patientWorkFlowList.length;
        this.steps = parseInt(this.list_lenght / this.step);
      })
    },
    pagejump(id){
       var last_id = parseInt(id * this.step);
       this.start = parseInt(last_id - this.step);
       this.end = last_id;
    },
    next(end){
       this.start = end;
       this.end = parseInt(end) + parseInt(this.step);
    },
    previous(end,start){
       this.start = parseInt(start)-parseInt(this.step);
       this.end = start;
    }
  },
  mounted() {
    this.getPatientWorkFlow(this.workflowId);
    console.log(this.workflowId);
  },
};
</script>