<template>
	<div class="content-wrapper">
		<div class="container-full">
			<div class="content-header">
				<div class="row justify-content-between">
					<div class="col-12 col-sm-8 col-md-9 col-lg-6 col-xs-6">
						<h1 class="mt-0">Add Vital</h1>
					</div>
				</div>
			</div>
			<section class="content">
					<div class="box">
						<div class="box-body">
								<div class="form-group row" id="health_status_update_date_div">
									<multi-select-box
										title="Units"
										 :isRequired="true"
										v-model="form.unit_name"
										getDataURL="/vital/unitlist"
									></multi-select-box>
								</div>
								 <div
									class="invalid-feedback"
									style="display:block;"
									v-for="(error, index) of v$.form.unit_name.$errors"
									:key="index"
									>
									{{ error.$message }}
								</div>
								<input-field
									:isRequired="true"
									title="Vital Name"
									v-model="this.form.vital"
									placeholder="e.g. Temperature">
								</input-field>
								<input-field
									title="Vital Code"
									v-model="this.form.vital_code"
									placeholder="e.g. 8310-5">
								</input-field>
								 <div
									class="invalid-feedback"
									style="display:block;"
									v-for="(error, index) of v$.form.vital.$errors"
									:key="index"
									>
									{{ error.$message }}
								</div>
								<div class="col-12 col-sm-12 col-md-12 col-lg-11 col-xl-9 text-center">
									<form-btn
									:title="btnname"
									btnCls="btn-info saveData"
									@click="save(false)"
									></form-btn>
									<router-link
									:to="{ name: 'PatientList' }"
									class="waves-effect waves-light btn me-1 btn-danger"
									>Close</router-link
									>
								</div>
						</div>
					</div>
			</section>	
		</div>
	</div>
</template>

<script>
	import axios from "@/scripts/axios.js";
	import InputField from "@/components/base/formFields/InputField.vue";
	import useVuelidate from "@vuelidate/core";
    import MultiSelectBox from "@/components/base/formFields/multiSelectBox.vue";
	import dataTable from "@/components/base/DataTable.vue";
	import FormBtn from "@/components/base/formFields/formBtn.vue";
	import submitMixins from "@/scripts/mixins/submitMixins.js";
	import {
		helpers,
		required,
		email,
		minLength,
		maxLength,
		sameAs,
		numeric,
		} from "@vuelidate/validators";
	export default {
		name: "Home",
		mixins: [submitMixins],
		setup: () => {
			return { v$: useVuelidate() };
		},
		components: {InputField, dataTable ,MultiSelectBox,FormBtn},
		computed: {
			 btnname() {
        		return this.$route.name === "vitalsEdit" ? "Update" : "Save";
      		},
		},
		data() {
			return {
			form:{
              vital:'',
			  unit_name:'',
			  vital_code:'',
            },
             unitList:null,   
			}
		},
		 validations() {
			return {
			form: {
				vital: {
				required: helpers.withMessage("Vital Name is required.", required),
				},
				unit_name: {
				required: helpers.withMessage("Please select unit.", required),
				},   
			},
			};
		},
		mounted(){
			axios.get("/vital/unitlist", {
            }).then(response => { 
            this.unitList = response.data.data 
            }); 
		},
		methods:{
			  
			save(next) {
				this.v$.$validate();
				if (!this.v$.$error) {
					console.log(this.form);
					this.errorMessage = '';
					if (this.$route.name === "PatientEdit"){
						this.postForm(
							`/patient/${this.$route.params.id}/update`,
							"patient",
							true,
							"form"
						);
					}else{
						axios.post("/vital/defultstore",this.form)
						.then(response => { 
						console.log(response);
						if(response.status==200){
							this.moshaToast("Records add successfully",'success');
							 this.$router.push("/vitals/list");
						}
						
						});
					}
				}
			},
			ChangeOption(){
				console.log($(this).value);
			}
		}
	
};
</script>
<style type="text/css">
	.btn-toggle:before{
		content: 'Inactive';
		left: -6rem;
	}
	.btn-toggle:after{
		content: 'Active';
		right: -5rem;
	}
</style>