
const patientList = {
  namespaced: true,

  state: {
    listUrl: "/patient/list",
    header: [
      {"":""},
      {"patient.chart_id":"Medical Chart ID"},
      {"patient.first_name":"First Name"},
      {"patient.last_name":"Last Name"},
      {"patient.office_phone":"Office Phone"},
      {"patient.cell_phone":"Cell Phone"},
      {"patient.last_appointment_date":"Last Appt"},
      {"patient.next_appointment_date":"Next Appt"},
    ],
    pagination: {
      to: "",
      from: "",
      total: "",
      last_page: "",
      current_page: "",
      pageNavArr: [],
    },
    filter: {
      duplicate: false,
      inactive_only: false, 
      active_inactive: false,
      search_text: '',
      having_followup: false,
      upcoming_appointment : false,
      approaching_appointment : false,
      new_patients : false,
      yesterday_appointment : false,
      today_appointment : false,
      office: false,
      office_id: 0,
    },
    list: [],
  },

  getters: {
    // getTableHeaders: (state) => state.header,
    // getTableContents: (state) => state.list,
    // getListURL: (state) => state.listUrl,
  },
  mutations: {},
  actions: {},
};

export default patientList;
