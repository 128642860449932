<template>
	<div class="authform-modal box">
		<div class="pageheading pb-10">
			<div class="row justify-content-between align-items-center">
				<div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xs-6">
					<h2 class="heading2 font-bold my-10">PurePORTAL <a href="#" data-bs-toggle="modal" data-bs-target="#pureportaldocument" title="Reference of Knowledge Base" v-on:click.prevent="reset()"> <i class="fa fa-question-circle fs-20"></i></a> </h2>
				</div>
				<div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xs-6 text-lg-end">
					<a v-if="this.$store.state.patientDetail.pp_id == 0 && this.$store.state.patientDetail.patient_portal_token == null" data-bs-toggle="modal" data-bs-target="#activepureportal" title="Activate PurePORTAL Access" href="javascript:void(0);" class="waves-effect waves-light btn btn-info mb-5">Enable PurePORTAL Access</a>
					<a v-if="this.$store.state.patientDetail.pp_id == 0 && this.$store.state.patientDetail.patient_portal_token != null" data-bs-toggle="modal" data-bs-target="#activepureportal" title="Activate PurePORTAL Access" href="javascript:void(0);" class="waves-effect waves-light btn btn-info mb-5 mt-2 me-2">Resend PurePortal Activation</a>
					<span v-if="this.$store.state.patientDetail.pp_id != 0">
						<a href=" javascript:void(0)" class="waves-effect waves-light btn btn-info my-1 me-5" data-bs-toggle="modal" data-bs-target="#formModal"><i class="fa fa-plus fs-14"></i> Form Access</a>
					</span>
					<span v-if="this.$store.state.patientDetail.pp_id != 0">
						<a href=" javascript:void(0)" class="waves-effect waves-light btn btn-info my-1 me-5" data-bs-toggle="modal" v-if="this.$store.state.patientDetail.pp_status == 0" data-bs-target="#representativeModal"><i class="fa fa-plus fs-14"></i> Patient Portal Representative</a>
						<a v-if="this.$store.state.patientDetail.pp_status == 0" @click="pp_status" href="javascript:void(0)" class="waves-effect waves-light btn btn-info my-1 me-5">Disable PurePORTAL</a>
						<a v-if="this.$store.state.patientDetail.pp_status != 0" @click="pp_status" href="javascript:void(0)" class="waves-effect waves-light btn btn-info my-1 me-5">Enable PurePORTAL</a>
					</span>
				</div>
			</div>
			<p class="mb-10 fs-14" v-if="this.$store.state.patientDetail.pp_email_count != null">The Patient portal activation request is already sent to this patient in email.To resend activation request, kindly click the button <b>Enable PurePORTAL Access</b>.OR contact the patient via phone.</p>
			<!--document model start-->
			<div id="pureportaldocument" class="modal fade" tabindex="-1" role="dialog"
				aria-labelledby="myModalLabel" aria-hidden="true">
				<div class="modal-dialog modal-md">
				<div class="modal-content">
					<div class="modal-header">
					<h3 class="modal-title fw-900 mt-0">Reference of Knowledge Base</h3>
					</div>
					<div class="modal-body overflow-auto" style="max-height: 350px;">
					<table class="table table-bordered">
						<thead>
						<tr>
							<th scope="col">No.</th>
							<th scope="col">Knowledge Base Articles</th>
						</tr>
						</thead>
						<tbody>
						<tr>
							<td>1</td>
							<td> <a :href="path+'use-of-pureportal-for-provider'" target="_blank">Use Of PurePORTAL ( For Provider)</a></td>
						</tr>
						</tbody>
					</table>
					<Search :key="searchKey"></Search>
					</div>
					<div class="modal-footer">
					<button type="button" @click="" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
					</div>
				</div>
				</div>
			</div>
			<!--document model end-->
			<!--model start-->
			<div id="activepureportal" class=" activepureportal modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
				<div class="modal-dialog modal-md">
					<div class="modal-content">
						<div class="modal-header">
							<h3 class="modal-title fw-900 mt-0">Enable PurePORTAL Access</h3>
						</div>
						<div class="modal-body">
							<div class="form-group" id="dob_error">
								<label>Patient DOB</label>
								<div class="availdate">
									<ejs-datepicker :value="date_of_birth" id="date_of_birth" :format="dateFormat" placeholder="select date of birth" @change="getdate($event)" name="date_of_birth" :strictMode='strict' :max='maxVal' :showClearButton='false' :enOnFocus='true'></ejs-datepicker>
								</div>
								<div class="error-msg d-none" id="dob_error_msg">
									<ul class="mb-0 p-0 fs-14">
										<li class="d-block" >Patient date of birth required</li>
									</ul>
								</div>
							</div>

							<div class="form-group" id="cell_error">
								<label>Cell Phone</label>
								<input type="text" class="form-control" id="cell_phone" placeholder="enter your Cell Phone" :value="this.$store.state.patientDetail.cell_phone" @change="cellnocheck">
								<div class="error-msg d-none" id="cell_error_msg">
									<ul class="mb-0 p-0 fs-14">
										<li class="d-block" >Patient Cell Phone required</li>
									</ul>
								</div>
							</div>

							<div class="form-group" id="email_error">
								<label>Email</label>
								<input type="text" class="form-control" id="email" placeholder="enter your email" :value="this.$store.state.patientDetail.email" @change="emailcheck">
								<div class="error-msg d-none" id="email_error_msg">
									<ul class="mb-0 p-0 fs-14">
										<li class="d-block" >Patient email address required</li>
									</ul>
								</div>
							</div>


						</div>
						<div class="modal-footer">
							<button @click="active" type="button" class="waves-effect waves-light btn btn-info">Enable</button>
							<button type="button" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
						</div>
					</div>
				</div>
			</div>
			<!--model end-->

			<!--form modal start-->
			<div id="formModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
            	<div class="modal-dialog modal-lg">
              		<div class="modal-content">
                		<div class="modal-header">
                  			<h3 class="modal-title fw-900 mt-0" id="myModalLabel">Form List</h3>
               			</div>
                		<div class="modal-body">
                			<div class="table-responsive">
								<table class="table">
									<thead>
										<tr>
											<th>Form Name</th>
											<th>Action</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="item in form_list">
											<td>{{item.name}}</td>
											<td>
												<button type="button"  data-on="Ready" data-off="Not Ready" :id="['status_' + item.id]" v-bind:class="[item.status == 'a' ? 'active' : '']" class="btn btn-toggle rounded30-im" data-bs-toggle="button" aria-pressed="true" autocomplete="off">
		                     						<div class="handle rounded30-im"></div>
		                    					</button>
											</td>
										</tr>
									</tbody>
								</table>
							</div>

							<h3 class="fw-900">Consent Forms</h3>

							<div class="table-responsive">
								<table class="table">
									<thead>
										<tr>
											<th>Form Name</th>
											<th>Action</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="item in consent_formlist">
											<td>{{item.title}}</td>
											<td>
												<button type="button"  data-on="Ready" data-off="Not Ready" :id="['consent_status_' + item.id]" v-bind:class="[item.status == 'a' ? 'active' : '']" class="btn btn-toggle rounded30-im" data-bs-toggle="button" aria-pressed="true" autocomplete="off">
		                     						<div class="handle rounded30-im"></div>
		                    					</button>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
                		</div>
                		<div class="modal-footer">
                  			<button type="button" class="waves-effect waves-light btn btn-info" @click="onToggleChange"> Save </button>
                  			<button type="button" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger"> Close</button>
                		</div>
              		</div>
            	</div>
          </div>
			<!--form modal end-->
			<!--representative modal start-->
			<div id="representativeModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
            	<div class="modal-dialog modal-lg">
              		<div class="modal-content">
                		<div class="modal-header">
                  			<h3 class="modal-title fw-900 mt-0" id="myModalLabel">Add Patient Portal Representative</h3>
               			</div>
                		<div class="modal-body">
                			<div class="form-group" id="first_nam_error">
								<input-field
									title="First Name"
									:isRequired="true"
									v-model="this.reprenstive_first_name"
									labelClass=""
									divClass=""
									placeholder="Enter Representative First Name"
								>
								</input-field>
								<div class="error-msg d-none" id="first_name_error_msg">
									<ul class="mb-0 p-0 fs-14">
										<li class="d-block" >Reprenstive First Name required</li>
									</ul>
								</div>
							</div>
                			<div class="form-group" id="middle_name_error">
								 <input-field
									title="Middle Name"
									:isRequired="true"
									v-model="this.reprenstive_middle_name"
									labelClass=""
									divClass=""
									placeholder="Enter Representative Middle Name"
								>
								</input-field>
								<div class="error-msg d-none" id="middle_name_error_msg">
									<ul class="mb-0 p-0 fs-14">
										<li class="d-block" >Reprenstive Middle Name required</li>
									</ul>
								</div>
							</div>
                			<div class="form-group" id="last_name_error">
								 <input-field
									title="Last Name"
									:isRequired="true"
									v-model="this.reprenstive_last_name"
									labelClass=""
									divClass=""
									placeholder="Enter Representative Last Name"
								>
								</input-field>
								<div class="error-msg d-none" id="last_name_error_msg">
									<ul class="mb-0 p-0 fs-14">
										<li class="d-block" >Reprenstive Last Name required</li>
									</ul>
								</div>
							</div>
							<div class="form-group" id="cell_error">
								 <input-field
									title="Cell Phone"
									mask="(000) 000-0000"
									v-model="this.reprenstive_cell_phone"
									labelClass=""
									divClass=""
								>
								</input-field>
								<div class="error-msg d-none" id="represent_cell_error_msg">
									<ul class="mb-0 p-0 fs-14">
										<li class="d-block" >Reprenstive Cell Phone required</li>
									</ul>
								</div>
							</div>
							<div class="form-group" id="email_error">
								<input-field
									title="Email"
									v-model="this.reprenstive_email"
									labelClass=""
									divClass=""
									placeholder="enter your email"
								>
								</input-field>
								<div class="error-msg d-none" id="email_error_msg">
									<ul class="mb-0 p-0 fs-14">
										<li class="d-block" >Reprenstive email address required</li>
									</ul>
								</div>
							</div>
                		</div>
                		<div class="modal-footer">
                  			<button type="button" class="waves-effect waves-light btn btn-info" @click="save"> Save </button>
                  			<button type="button" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger"> Close</button>
                		</div>
              		</div>
            	</div>
          </div>
		<!--representative modal end-->
		</div>

		<div class="authform-modal box">
         <div class="pageheading">
            <h2 class="heading2">Signed Consent Forms</h2>
         </div>
         <div class="table-responsive">
            <table class="table">
            	<thead>
	            	<tr>
	            		<th>Consent Form</th>
	            		<th>Date of Signature</th>
	            		<th>Actions</th>
	            	</tr>
	            </thead>
	            <tbody>
	            	<tr v-for="item in signform" v-if="signform.length > 0">
	            		<td>{{item.title}}</td>
	            		<td>{{$filters.displayDateTimeLocal(item.created_at)}}</td>
	            		<td>
	            			<Popper arrow content="View Consent Form" :hover="true" class="helptips cursor-pointer">
                           		<a data-bs-toggle="modal" data-bs-target="#SignConsentModal" @click="signformview(item.document_name_url,item.sign)" href="#" class="waves-effect waves-circle btn btn-circle btn-info btn-xs" ><i class="fa fa-eye"></i></a>
                        	</Popper>
	            		</td>
	            	</tr>
	            	<tr v-else>
						<td colspan="3" class="text-center">No Record Found</td>
	            	</tr>
	            </tbody>
            </table>
         </div>
      </div>

      	<div class="authform-modal box">
        	<div class="pageheading">
            	<h2 class="heading2">Activity Logs</h2>
         	</div>
			<div class="table-responsive" v-if="this.$store.state.patientDetail.pp_id != 0">
				<AuditLogTable LogType="Patient Portal" :patient_unique_id="this.$store.state.patientDetail.patient_unique_id" :key="table_load_count" />
			</div>
		</div>
	</div>

<!--model start-->
<div id="SignConsentModal" class=" SignConsentModal modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-xl">
    	<div class="modal-content">
       		<div class="modal-header">
          		<h3 class="modal-title fw-900 mt-0">Sign Consents Forms</h3>
       		</div>
       		<div class="modal-body">
				<template v-if="embed=0">
            		<vue-pdf-viewer height="1050px" :url="document_name_url"></vue-pdf-viewer>
            	</template>
            	<template v-else>
            		<iframe :src="document_name_url" height="1050px" width="100%" />
            	</template>
				<h3><b>Patient Signature</b></h3>
        		<center>
            		<img :src="this.patient_sign2">
            	</center>
        	</div>
       		<div class="modal-footer">
          		<button type="button" @click="close" data-bs-dismiss="modal" aria-label="Close" class="waves-effect waves-light btn btn-danger">Close</button>
       		</div>
    	</div>
	</div>
</div>
 <!--model end-->
</template>

<script>
	import InputField from "@/components/base/formFields/InputField.vue";
	import AuditLogTable from "@/components/base/AuditLogTable.vue";
	import axios from "@/scripts/axios.js";
	import submitMixins from "@/scripts/mixins/submitMixins.js";
	import PreviewPdf from '@/components/patients/details/ClinicalNote/PDF.vue';
	import Popper from "vue3-popper";
	import moment from "moment";
	import VuePDFViewer from 'vue-instant-pdf-viewer';
	import Search from "@/components/Search.vue";
	export default {
		mixins: [submitMixins],
		components: {
			AuditLogTable,
			'vue-pdf-viewer': VuePDFViewer,
			Popper,
			Search,
			InputField,
		},
		data(){
			return {
				email:'',
				date_of_birth:'',
				dateFormat: 'MM-dd-yyyy',
				strict: true,
				strict: true,
				maxVal: new Date(),
				isValid : 0,
				cell_phone: '',
				reprenstive_last_name:'',
				reprenstive_first_name:'',
				reprenstive_middle_name:'',
				reprenstive_cell_phone:'',
				form_list:{},
				consent_formlist:{},
				mask_represent:"(000) 000-0000",
				signform:{},
				embed:'',
				patient_sign2:'',
				document_name_url:'',
				path: process.env.VUE_APP_KB_DOCUMENT,
				searchKey:0,
				first_name:'',
				last_name:'',
				middle_name:'',
				reprenstive_email:'',
			}
		},
		methods:{
			reset() {
				this.searchKey = this.searchKey + 1;
			},
			NumbersOnly(evt) {
			evt = (evt) ? evt : window.event;
			var charCode = (evt.which) ? evt.which : evt.keyCode;
			if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
				evt.preventDefault();;
			} else {
				return true;
			}
			},
			signformview(document_name_url,sign){
				this.document_name_url = document_name_url;
				this.patient_sign2 = sign;
				axios.post('patient/'+this.$route.params.id+'/documents/signed-consent-form/view')
				.then(response => {
				});
			},
			pp_status(){
				axios.post("patient-portal/pp-status",{patient_id:this.$store.state.patientDetail.patient_id,pp_status:this.$store.state.patientDetail.pp_status})
				.then((response) =>{
					this.$store.state.patientDetail.pp_status = response.data.data.pp_status;
					if(response.data.data.pp_status == 0){
						this.moshaToast("Pure PORTAL enabled", "info")
					}
					else{
						this.moshaToast("Pure PORTAL disabled", "info")
					}
				});
			},
			getdate($event){
				if($('#date_of_birth').val() == '' && this.form.date_of_birth == '' ) {
					this.date_of_birth = null;
					this.dob = null;
				} else {      
					$('#dob_error').removeClass('error');
					$('#dob_error_msg').addClass('d-none');
					this.dob = moment($event.value).format('YYYY-MM-DD');
					this.date_of_birth = moment($event.value).format('MM-DD-YYYY');
				}  
			},
			emailcheck(){
				$('#email_error').removeClass('error');
				$('#email_error_msg').addClass('d-none');
			},
			cellnocheck(){
				this.cell_phone = $("#cell_phone").val();
			},
			active(){			
				this.email = $("#email").val();
				if(this.email == ''){
					$('#email_error').addClass('error');
					$('#email_error_msg').removeClass('d-none');
				}
				else if(this.dob == null){
					$('#dob_error').addClass('error');
					$('#dob_error_msg').removeClass('d-none');
				}
				else if(this.cell_phone == ''){
					$('#cell_error').addClass('error');
					$('#cell_error_msg').removeClass('d-none');	
				}
				else{
					this.$store.state.loader = true;
					axios.post("patient/pp-active",{unique_id:this.$route.params.id,email:this.email,dob:this.dob,isValid:this.isValid,patient_id:this.$store.state.patientDetail.patient_id,cell_phone:this.cell_phone})
					.then((response) =>{
						if(response.data.message){
							$('#activepureportal').modal('hide');
							this.moshaToast(response.data.message, "info");
							this.$store.state.loader = false;
						}
						else{
							$('#activepureportal').modal('hide');
							this.moshaToast(response.data.data, "success")	
							this.$store.state.loader = false;
							this.$store.state.patientDetail.patient_portal_token = Math.floor(Math.random() * 34985340593495);
							// this.$store.state.loader = false;
					        // setTimeout(function(){ 
					        // 	location.reload();
					        // },1000);
						}
					});
				}
			},
			save(){
				if(this.reprenstive_first_name == ''){
					this.$store.state.loader = false;
					$('#first_name').addClass('error');
					$('#first_name_error_msg').removeClass('d-none');
					return false;
				}
				else if(this.reprenstive_middle_name == ''){
					this.$store.state.loader = false;
					$('#middle_name').addClass('error');
					$('#middle_name_error_msg').removeClass('d-none');
					return false;
				}
				else if(this.reprenstive_last_name == ''){
					this.$store.state.loader = false;
					$('#last_name').addClass('error');
					$('#last_name_error_msg').removeClass('d-none');
					return false;
				}
				else if(this.reprenstive_cell_phone == ''){
					this.$store.state.loader = false;
					$('#represent_cell_phone').addClass('error');
					$('#represent_cell_error_msg').removeClass('d-none');	
					return false;
				}
				this.$store.state.loader = true;
					axios.post("patient/pr-active",{email:this.reprenstive_email,patient_id:this.$store.state.patientDetail.patient_id,cell_phone:this.reprenstive_cell_phone,first_name:this.reprenstive_first_name,middle_name:this.reprenstive_middle_name,last_name:this.reprenstive_last_name})
					.then((response) =>{
						if(response.data.message){
							this.moshaToast(response.data.message, "info")
						}
						else{
							$('#activepureportal').modal('hide');
							this.moshaToast(response.data.data, "success")	
							this.$store.state.loader = false;
					        setTimeout(function(){ 
					        	location.reload();
					        },1000);
						}
					});
			
			},
			isvalidcheck(){
				if(this.$store.state.patientDetail.date_of_birth != null){
					this.dob = moment(this.$store.state.patientDetail.date_of_birth).format('YYYY-MM-DD');
					this.date_of_birth = moment(this.$store.state.patientDetail.date_of_birth).format('MM-DD-YYYY');
				}
				else{
					this.isValid = 1;
				}
				if(this.$store.state.patientDetail.email == null){
					this.isValid = 1;	
				}
				if(this.$store.state.patientDetail.cell_phone == null){
					this.isValid = 1;	
				}
				else{
					this.cell_phone = this.$store.state.patientDetail.cell_phone;
				}
			},
			getform(){
				axios.post("patient-portal/form-list",{patient_id:this.$store.state.patientDetail.patient_id})
				.then((response) =>{
					this.form_list = response.data.data 						
				});
			},
			onToggleChange(){
				var status = Array();
				for (var i = 0; i < this.form_list.length; i++) {
					var form_id = this.form_list[i].id;
					var class_list = document.getElementById("status_"+form_id).classList;
					if(class_list.contains('active')) {
	      				status.push(form_id);
	      			} 
		      	}

		      	var consentstatus = Array();
				for (var i = 0; i < this.consent_formlist.length; i++) {
					var consent_form = this.consent_formlist[i].id;
					var class_list = document.getElementById("consent_status_"+consent_form).classList;
					if(class_list.contains('active')) {
	      				consentstatus.push(consent_form);
	      			} 
		      	}
		      	axios.post("patient-portal/form-permission",{patient_id:this.$store.state.patientDetail.patient_id,status:status.toString(),consentstatus:consentstatus.toString()})
				.then((response) =>{
					this.moshaToast("Record Updated Successfully.", "success");
				})
				.catch((error) => {
          			this.moshaToast(error.response.data.message, "danger");
        		});
        		this.getform();
				this.getconsentform();	
				this.getquestionnaireform();	
				//$('#formModal').modal('hide');

			},
			getconsentform(){
				axios.post("patient-portal/consent-form-list",{patient_id:this.$store.state.patientDetail.patient_id})
				.then((response) =>{
					this.consent_formlist = response.data.data
				});
			},
			getsignconsentform(){
				axios.post("patient-portal/get-patient-signform",{patient_id:this.$store.state.patientDetail.patient_id})
				.then((response) =>{
					console.log(response);
					this.signform = response.data.data;
				});	
			}

		},
		beforeCreate(){
	      // Opera 8.0+
	      var isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

	      // Firefox 1.0+
	      var isFirefox = typeof InstallTrigger !== 'undefined';

	      // Safari 3.0+ "[object HTMLElementConstructor]" 
	      var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));

	      // Internet Explorer 6-11
	      var isIE = /*@cc_on!@*/false || !!document.documentMode;

	      // Edge 20+
	      var isEdge = !isIE && !!window.StyleMedia;

	      // Chrome 1 - 79
	      var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

	      // Edge (based on chromium) detection
	      var isEdgeChromium = isChrome && (navigator.userAgent.indexOf("Edg") != -1);

	      // Blink engine detection
	      var isBlink = (isChrome || isOpera) && !!window.CSS;

	      if(isChrome==true)
	      {
	          this.embed = 0;
	      }else{
	          this.embed = 1;
	      }
	   },
		mounted() {
			this.reprenstive_email=this.$store.state.patientDetail.email;
			this.reprenstive_cell_phone=this.$store.state.patientDetail.cell_phone;
			this.isvalidcheck();
			this.getform();
			this.getconsentform();
			this.getsignconsentform();
		}
	}
</script>