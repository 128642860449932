<template>
  <div class="form-group row" :class="{ error: errors.length}">
    <label
      :class="labelClass"
      >{{ title }}
      <small v-show="isRequired" class="text-danger asterisksign">*</small></label
    >
    <div :class="divClass">
      <div class="controls">
        <select
          name="select"
          required
          class="select2Single form-select form-control"
          :value="modelValue"
          @change="$emit('update:modelValue', $event.target.value)"
        >
          <option value="">Select Your {{ title }}</option>
          <option :value="item.id" v-for="item in data" :key="item.id">{{
            item.name
          }}</option>
        </select>
        <span class="form-text text-muted" v-if="notes != ''">{{notes}}</span>
      </div>
      <p v-if="error" class="error-msg fs-14">
        Could not get {{ title }} options. Click
        <a @click="window.location.reload(true)" class="fs-14"> HERE </a> to reload the page
        and try again
      </p>
      <div class="error-msg">
        <ul class="mb-0 p-0 fs-14">
          <li class="d-block" v-for="(error, index) of errors" :key="index">
            {{ error.$message }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import submitMixins from "@/scripts/mixins/submitMixins.js";
import $ from "jquery";
import "select2/dist/js/select2.full";

export default {
  mixins: [submitMixins],
  props: {
    modelValue: {},
    errors: {
      type: Object,
    },
    title: {
      type: String,
    },
    getDataURL: {
      type: String,
    },
    isRequired: {
      type: Boolean,
    },
    labelClass:{
      type: String,
      default: "col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end"
    },
    divClass:{
      type: String,
      default: "col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3"
    },
    notes: {
      type: String,
      default: ""
    },
  },
  data() {
    return {
      data: "",
      error: false,
    };
  },
  computed: {
    getID() {
      return `select-${Math.floor(Math.random() * 25)}`;
    },
  },
  mounted() {
    // $(`.select2Single`).select2();
    if (this.title === "Job Title") {
      console.log("this code run");
    }
  },
  async created() {
    this.data = await this.getData(this.getDataURL);

    if (!this.data) {
      this.error = true;
    }
  },
};
</script>