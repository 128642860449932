import {
  helpers,
  required,
  email,
  minLength,
  maxLength,
  sameAs,
  url,
} from "@vuelidate/validators";


const CorporateAppointments = {
  namespaced: true,
  state: {
    editID: "",
    listUrl: "/corporate/list",
    tableData: {
      header: [
        "Corporate name",
        "Team Leader",
        "Primary Contact Name",
        "Primary Contact No",
        "Actions",
      ],
    },
    form: {},
    vuelidateExternalResults: {
      form: {
        corporate_name:[],
        crm_status:[],
        Duration:[],
        schedule_time:[],
        schedule_end_time:[],
        reason_for_visit:[],
      }
    },
    validationRules: {
      form: {
        corporate_name: {
          required: helpers.withMessage("Corporate Name is required", required),
        },
        crm_status:{
          required: helpers.withMessage("CRM Status is required", required),
        },
        duration_value:{
          required: helpers.withMessage("Duration is required", required),
        },
        schedule_time:{
          required: helpers.withMessage("Shedule Time is required", required),
        },
        schedule_end_time:{
          required: helpers.withMessage("Shedule End Time is required", required),
        },
        reason_for_visit:{
          required: helpers.withMessage("Reason for Visit is required", required),
        }
      }
    },
    list: [],
  },
  getters: {
    getTableHeaders: (state) => state.header,
    getTableContents: (state) => state.list,
    getListURL: (state) => state.listUrl,
  },
  mutations: {},
  actions: {},
};

export {CorporateAppointments};
