import {
    helpers,
    required,    
  } from "@vuelidate/validators";
  const phimailbox = {
    namespaced: true,  
    state: {
        listUrl: `phimailbox/list`,
        tableData: {
            header: [
                "MRN",
                "Received Date",
                "Sender",
                "First Name",
                "Last Name",
                "Date Of Birth",
                " ",
                " ",
            ],
        },
        list: [],
    },
    getters: {
        getTableHeaders: (state) => state.header,
        getTableContents: (state) => state.list,
        getListURL: (state) => state.listUrl,
    },
    mutations: {},
    actions: {},
  };
  
  export default phimailbox;
  