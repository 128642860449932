import {
  helpers,
  required,
} from "@vuelidate/validators";

const patientProblem = {
  namespaced: true,

  state: {
    listUrl: "/patient/:patient_unique_id/problem/list/Active",
    getEditUrl: "/patient/:patient_unique_id/problem/edit/:id",
    tableData: {
      header: [
         'Source',
         'Problem',
         'ICD Code',
         'Snomed Code',
         'DateTime Diagnosed',
         'DateTime Changed',
         'Status',
         'Notes',
         'DateTime Onset',
         'Actions',
      ],
    },
    validationRules: {
        form: {
           problem: {
               required : helpers.withMessage('Problem is required.', required),
           },
           status: {
               required : helpers.withMessage('Status is required.', required),
           }
       },
    },
    vuelidateExternalResults: {
        form : {
          problem_id : '',
          problem : '',
          ICD_10 : '',
          status : '',
          datetime_diagnosis : '',
          datetime_onset : '',
          datetime_changed : '',
          description : '',
          is_symptom : '',
        }
    },
    form : {},
    list: [],
  },

  getters: {
    getTableHeaders: (state) => state.header,
    getTableContents: (state) => state.list,
    getListURL: (state) => state.listUrl,
  },
  mutations: {},
  actions: {},
};

export default patientProblem;
