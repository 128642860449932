<template>
    <div class="box">
        <div class="box-body">
            <div class="pageheading">
                <div class="row justify-content-between align-items-center">
                    <div class="col-12 col-sm-7 col-md-7 col-lg-6 col-xs-6">
                        <h2 class="font-bold d-inline-block">Active Allergy Orders <button class="waves-effect waves-light btn btn-info-light btn-xs ms-5 fetch_allergy" @click="fetchAllergy"><i class="fa fa-refresh"></i></button></h2>
                    </div>
                    <div class="col-12 col-sm-5 col-md-5 col-lg-6 col-xs-4 text-sm-end" v-if="this.$store.state.auth.is_drfirst_enable == 'y' || (permissioncheck(
                    this.$store.state.permission.Rcopia_Access_Management) && this.$store.state.auth.is_rx_setup==true)">
                        <div class="bg-danger-light py-5 rounded5 fs-14 mb-10 w-p75 text-center" role="alert"
                        v-if="errorMessage">
                            {{ errorMessage }}
                        </div>
                        <a href="javascript:void(0)" class="waves-effect waves-light btn btn-info mb-5 add_allergy" @click="before_launchRxnt"><i class="fa fa-plus fs-14"></i> Allergy </a>
                    </div>
                </div>
                <form style="display:none" name="rcopiaForm" id="rcopiaForm" method="post">
                    <div>
                        <input type="text" id="rcopia_portal_system_name" name="rcopia_portal_system_name" value="" size="30" />
                        <input type="text" id="rcopia_practice_user_name" name="rcopia_practice_user_name" value="" size="30" />
                        <input type="text" id="rcopia_user_id" name="rcopia_user_id" value="" size="30" />
                        <input type="text" id="rcopia_user_external_id" name="rcopia_user_external_id" value="" size="100" />
                        <input type="text" id="rcopia_patient_id" name="rcopia_patient_id" value="{{ this.$store.state.patientDetail.patient_rcopia_id }}" size="15" />
                        <input type="text" id="rcopia_patient_system_name" name="rcopia_patient_system_name" value="" size="30" />            
                        <input type="text" id="rcopia_patient_external_id" name="rcopia_patient_external_id" value="{{ this.$store.state.patientDetail.patient_id }}" size="40" />
                        <input type="text" id="service" name="service" value="rcopia" size="30" />
                        <input type="text" id="startup_screen" name="startup_screen" value="patient" size="30" />
                        <input type="text" id="close_window" name="close_window" value="n" />
                        <input type="text" id="allow_popup_screens" name="allow_popup_screens" value="y" />
                        <input type="text" id="logout_url" name="logout_url" :value="return_url" size="100" />
                        <input type="text" id="timeout_url" name="timeout_url" :value="timeout_url" size="100" />
                        <input type="text" id="limp_mode" name="limp_mode" value="n" size="100" />
                    </div>
                </form>
            </div>
            <div class="controls d-block mb-2">
                <input type="checkbox" id="checkbox_1" class="filled-in chk-col-info" value="single" @change="show_inactive">
                <label for="checkbox_1" class="m-0">Show Inactive Allergy</label>
            </div>
            <div class="table-responsive">
                <table class="table table-hover">
                    <thead>
                          <tr>
                            <th>Allergy</th>
                            <th>Reaction</th>
                            <th>RxNorm Code</th>
                            <th>Status</th>
                            <th>Severity</th>
                            <th>Onset Date</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-if="allergy.length>0" v-for="item in allergy" :key="item.id">
                            <tr>
                                <td>{{ item.allergen_name}}</td>
                                <td>{{ item.reaction_description }}</td>
                                <td>{{ item.allergen_drug_rxnorm_id }}</td>
                                <td>{{ item.status }}</td>
                                <td>{{ item.severity_description }}</td>
                                <td>{{ item.onset_date }}</td>
                                <td>
                                    <Popper arrow content="View Allergy" :hover="true" class="helptips cursor-pointer">
                                        <a href="#" class="waves-effect waves-circle btn btn-circle btn-info btn-xs" @click="viewDetail(item.id)"><i class="fa fa-eye"></i></a>
                                    </Popper>
                                </td>
                            </tr>
                        </template>
                        <template v-else>
                            <tr>
                                <td colspan="7" class="text-center">No data available</td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <h2 class="font-bold d-inline-block" v-if="importedAllergyData.length>0">Imported Allergy Orders ({{ import_source }})</h2>

    <div class="table-responsive" v-if="importedAllergyData.length>0">
        <table class="table table-hover">
            <thead>
                <tr>
                    <th>Allergy</th>
                    <th>Code</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Reaction</th>
                    <th>Severity</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                <template v-for="item in importedAllergyData" :key="item.id">
                    <tr>
                        <td>{{ item.allergy_name}}</td>
                        <td>{{ item.allergy_code}}</td>
                        <td>{{ item.start_date}}</td>
                        <td>{{ item.end_date}}</td>
                        <td>{{ item.reaction_name}} {{ ((item.reaction_code == null) ? ( item.reaction_code ) : '') }} </td>
                        <td>{{ item.severity_name }} ( {{ item.severity_code }} )</td>
                        <td>
                            <Popper arrow content="View Allergy" :hover="true" class="helptips cursor-pointer">
                                <a href="#" class="waves-effect waves-circle btn btn-circle btn-info btn-xs" @click="viewDetail(item.id, 'imported')"><i class="fa fa-eye"></i></a>
                            </Popper>
                        </td>
                    </tr>
                </template>
            </tbody>
        </table>
    </div>

    <div class="modal" tabindex="-1" id="allergyDetail">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h3 class="modal-title m-0 font-bold">Detail Allergy</h3>
                    <button type="button" class="btn-close btn-xs" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div id="allergy_detail_table" class="table-responsive"></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import axios from "@/scripts/axios.js";
import Popper from "vue3-popper";
import permissionMixins from "@/scripts/mixins/permission.js";

export default {
    components: {Popper,},
    mixins: [permissionMixins],
    data() {
        return {
            key: 0,
            allergy:[],
            importedAllergyData: [],
            allergy_fetched_date: this.$store.state.patientDetail.allergy_fetched_date,
            sso_token : '',
            return_url : window.location.href,
            inactive: false,
            errorMessage: null,
            timeout_url : window.location.href+"/#rx-err",
            user_type: "",
        };
    },
    watch: {},
    created() {
        this.fetchLocalActiveAllergy();
        this.importedAllergy();
    },
    mounted() {
        /*RX SSO timeout Logs*/
        var location = window.location.href;
        if(location.indexOf('#rx-err')!=-1)
        {
            axios.post(`/patient/${this.$route.params.id}/create-rcopia-log`, { patient_id : this.$store.state.patientDetail.patient_id, patient_rcopia_id: this.$store.state.patientDetail.patient_rcopia_id, login_type: "SSO - Allergy", response_type: "Timeout" });
        }
        /*RX SSO timeout Logs -- End*/
        $("#rcopia_patient_id").val(this.$store.state.patientDetail.patient_rcopia_id);
        this.user_type = this.user.user_type;
    },
    methods: {
        show_inactive: function(args){        
            this.inactive = (args.target.checked) ? true : false;        
            this.fetchLocalActiveAllergy();
        },
        viewDetail: function(id, imported){
            $("#allergyDetail").modal("show"); 
            axios
                .post(`/patient/${this.$route.params.id}/get-allergy-detail`,{patient_id:this.$store.state.patientDetail.patient_id, allergy: id, imported: imported})
                .then((response) => {
                    $("#allergy_detail_table").html(response.data);
                });     
        },
        before_launchRxnt: function(){
            $("a.add_allergy").html("Loading Please wait..");
            if(this.$store.state.patientDetail.patient_rcopia_id==null)
            {
                axios
                    .post(`/patient/${this.$route.params.id}/rcopia_registration`,{patient_id: this.$store.state.patientDetail.patient_id, patient_rcopia_id:this.$store.state.patientDetail.patient_rcopia_id,source: 'allergy' })
                    .then((response) => {
                        if(response.data.data.patient_rcopia_id)
                        {
                            $("#rcopia_patient_id").val(response.data.data.patient_rcopia_id);
                            this.$store.state.patientDetail.patient_rcopia_id = response.data.data.patient_rcopia_id;
                            this.launchRxnt();
                        }
                    })
                    .catch((error) => {
                        this.$swal.fire("Sorry!",error.response.data.message,"warning");
                    });
            }
            else
            {
                this.launchRxnt();
            }
        },
        launchRxnt : function() {
                /*Rcopia*/
                axios
                    .post(`/patient/${this.$route.params.id}/rcopia-access`)
                    .then((response) => {
                        document.getElementById("rcopia_portal_system_name").value = response.data.data.system_name;
                        document.getElementById("rcopia_practice_user_name").value = response.data.data.practice_user_name;
                        document.getElementById("rcopiaForm").setAttribute("action",response.data.data.action_url);
                        document.getElementById("rcopia_user_external_id").value = response.data.data.provider_external_id;
                        var retries = response.data.data.no_of_retries, vendor_pwd = response.data.data.vendor_pwd;
                        
                        for(var i=0;i<retries;i++)
                        {
                            var $this = this;                            
                            var responseFrm = this.launchRxntFrm(vendor_pwd);
                            if($this.frmSubmit == "Success")
                            {
                                /*Rcopia SSO Logs*/
                                axios.post(`/patient/${this.$route.params.id}/create-rcopia-log`, { patient_id : this.$store.state.patientDetail.patient_id, patient_rcopia_id: this.$store.state.patientDetail.patient_rcopia_id, login_type: "SSO - Allergy" })
                                break;                                 
                            }
                            else{
                                /*Rcopia SSO Logs*/
                                axios.post(`/patient/${this.$route.params.id}/create-rcopia-log`, { patient_id : this.$store.state.patientDetail.patient_id, patient_rcopia_id: this.$store.state.patientDetail.patient_rcopia_id, login_type: "SSO - Allergy Error" })
                                /*Rcopia SSO Logs - End*/                                    
                            }
                            /*Rcopia SSO Logs - End*/
                        }
                    });
                /*Rcopia*/
        },
        launchRxntFrm : function(vendor_pwd) {
            $("a.add_allergy").html('<i class="fa fa-plus fs-14"></i> Allergy');

            document.getElementById("rcopia_patient_external_id").value = document.getElementById("rcopia_practice_user_name").value+document.getElementById("rcopia_portal_system_name").value+this.$store.state.auth.userData.clinic_id+'_'+this.$store.state.patientDetail.patient_id;

            if($("form#rcopiaForm input[name='MAC']").length>0)
            {
                var timenow = this.gmtTime();
                $("form#rcopiaForm input[name='time']").val(timenow);
                var strMAC = this.GenerateStringForMAC(timenow, vendor_pwd);
                var md5Hash = CryptoJS.MD5(strMAC).toString();
                md5Hash = md5Hash.toUpperCase();
                $("form#rcopiaForm input[name='MAC']").val(md5Hash);
            }
            else
            {
                var elem = document.createElement("input");
                elem.setAttribute("type", "text");
                elem.setAttribute("name", "action");
                elem.setAttribute("id", "action");
                elem.setAttribute("value", "login");
                document.rcopiaForm.appendChild(elem);

                var timenow = this.gmtTime();
                var elem = document.createElement("input");
                elem.setAttribute("type", "text");
                elem.setAttribute("name", "time");
                elem.setAttribute("id", "time");
                elem.setAttribute("value", timenow);
                document.rcopiaForm.appendChild(elem);

                var elem = document.createElement("input");
                elem.setAttribute("type", "text");
                elem.setAttribute("name", "skip_auth");
                elem.setAttribute("id", "skip_auth");
                elem.setAttribute("value", "n");
                document.rcopiaForm.appendChild(elem);
            
                var strMAC = this.GenerateStringForMAC(timenow, vendor_pwd);
                var md5Hash = CryptoJS.MD5(strMAC).toString();
                md5Hash = md5Hash.toUpperCase();

                elem = document.createElement("input");
                elem.setAttribute("type", "text");
                elem.setAttribute("name", "MAC");
                elem.setAttribute("value", md5Hash);
                document.rcopiaForm.appendChild(elem);
            }
            let $this = this;
            function submitToPopup(f) {
                f.target = '_blank';
                try {
                    f.submit();
                    $this.frmSubmit = 'Success';
                }
                catch(err) {
                    $this.frmSubmit = 'Error';
                }
            }
            submitToPopup(document.rcopiaForm);
        },
        GenerateStringForMAC : function(timenowtemp, vendor_pwd) {
            var str = "rcopia_portal_system_name=" + document.getElementById("rcopia_portal_system_name").value +
            "&rcopia_practice_user_name=" + document.getElementById("rcopia_practice_user_name").value +
            "&rcopia_user_id=" + document.getElementById("rcopia_user_id").value +
            "&rcopia_user_external_id=" + document.getElementById("rcopia_user_external_id").value +
            "&rcopia_patient_id=" + document.getElementById("rcopia_patient_id").value +
            "&rcopia_patient_system_name=" + document.getElementById("rcopia_patient_system_name").value +
            "&rcopia_patient_external_id=" + document.getElementById("rcopia_patient_external_id").value +
            "&service=" + document.getElementById("service").value +
            "&startup_screen=" + document.getElementById("startup_screen").value +
            "&close_window=" + document.getElementById("close_window").value +
            "&allow_popup_screens=" + document.getElementById("allow_popup_screens").value +
            "&logout_url=" + document.getElementById("logout_url").value +
            "&timeout_url=" + document.getElementById("timeout_url").value +
            "&limp_mode=" + document.getElementById("limp_mode").value +
            "&action=login" + 
            "&time=" + timenowtemp +
            "&skip_auth=" + document.getElementById("skip_auth").value +
                    //"&posturl=" + posturl.Value +
                    vendor_pwd;
                    return str;
        },
        gmtTime : function () {
                var GMTTime = new Date();
                var month = GMTTime.getUTCMonth() + 1;
                var day = GMTTime.getUTCDate();
                var year = GMTTime.getUTCFullYear() - 2000;
                var hour = GMTTime.getUTCHours();
                var min = GMTTime.getUTCMinutes();
                var sec = GMTTime.getUTCSeconds();

                if (month < 10) { month = "0" + month; }
                if (day < 10) { day = "0" + day; }
                if (hour < 10) { hour = "0" + hour; }
                if (hour < 1) { hour = "00"; }
                if (min < 10) { min = "0" + min; }
                if (min < 1) { min = "00"; }
                if (sec < 10) { sec = "0" + sec; }
                if (sec < 1) { sec = "00"; }

                return  month.toString() + day.toString() + year.toString() + hour.toString() + min.toString() + sec.toString();
        },
        fetchLocalActiveAllergy : function(msg='') {
            axios
            .post(`/patient/${this.$route.params.id}/get-allergy`, { patient_id : this.$store.state.patientDetail.patient_id, show_inactive:this.inactive })
            .then((response) => {

                if(response.data.data.length >0){
                    this.allergy = response.data.data;
                    this.allergy_fetched_date = response.data.data[0]['allergy_fetched_date'];
                    if(msg!='')
                    {
                        this.$swal.fire("Fetched!","Allergy fetched successfully.","success"); 
                    }
                }
                else
                {
                    if(msg!='')
                    {
                        this.$swal.fire("Fetched!","No Allergy found.","warning"); 
                    }
                }
            })
            .catch((error) => {
                this.errorMessage = error.response.data.message;
            });
        },
        fetchAllergy: function() {

            if(this.$store.state.patientDetail.patient_rcopia_id == null)
            {
                this.$swal
                    .fire({
                      title: '<h2 class="mt-0">Sorry!</h2>',
                      text:
                        "The patient is not available in Rcopia. Please add the patient in Rcopia to write allergy. Do you want to create patient in Rcopia now?",
                      icon: "warning",
                      dangerMode: true,
                      showCancelButton: true,
                      confirmButtonColor: "#ee3158",
                      cancelButtonColor: "#bdbdbd",
                      confirmButtonText: "Yes, please!",
                    })
                    .then((result) => {
                        this.before_launchRxnt();
                    });
            }
            else
            {
                $("button.fetch_allergy").html("<i class='fa fa-sync fa-spin'></i>");
                axios
                .post(`/patient/${this.$route.params.id}/fetch-allergy`, { patient_id : this.$store.state.patientDetail.patient_id, patient_rcopia_id: this.$store.state.patientDetail.patient_rcopia_id, allergy_fetched_date: this.allergy_fetched_date })
                .then((response) => {
                    $("button.fetch_allergy").html("<i class='fa fa-refresh'></i>");
                    this.key++;
                    this.fetchLocalActiveAllergy('msg');
                });
            }
        },
        importedAllergy: function() {
            axios
                .post(`/patient/${this.$route.params.id}/get-imported-allergy`, { patient_id : this.$store.state.patientDetail.patient_id })
                .then((response) => {
                    this.import_source = response.data.data.source;
                    this.importedAllergyData = response.data.data.loop_data;
                });
        }
    },
    computed: {
        ...mapGetters("auth", ["user", "provider_list", "active_provider"]),
    },
};
</script>