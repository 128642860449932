<template>
	<div class="content-wrapper">
		<div class="container-full">
			<div class="content-header">
				<h1 class="heading1 m-0 d-inline-block">PHI Mail Box</h1> <button type="button" class="waves-effect waves-light btn btn-info mb-5 float-end" @click="refreshList"><i class="fa fa-refresh fs-14"></i> Refresh</button>
			</div>
			<section class="content">
				<div class="box">
					<div class="box-body">
						<div class="table-responsive">
							<Table :dataTypes="{ dataType: 'phimailbox' }" :key="key">
								<template v-slot="slotProps">
									<td>
										<div v-if="slotProps.item.mrn == null">-</div>
										<div v-else>
											<router-link :to="{ name: 'PatientDetails', params: { id: slotProps.item.unique_id }, }" title='View Chart' class="fw-500">{{ slotProps.item.mrn }}</router-link>
										</div>
									</td>
									<td>
										{{ formatDate(slotProps.item.received_date, "MMM D, YYYY h:mm A") }}
									</td>
									<td>{{ slotProps.item.sender }}</td>
									<td>
										<div v-if="slotProps.item.patient_fname == null">-</div>
										<div v-else>{{ slotProps.item.patient_fname }}</div>
									</td>
									<td>
										<div v-if="slotProps.item.patient_lname == null">-</div>
										<div v-else>{{ slotProps.item.patient_lname }}</div>
									</td>
									<td>
										<div v-if="slotProps.item.patient_dob == null">-</div>
										<div v-else>{{ formatDate(slotProps.item.patient_dob, "MMM D, YYYY") }}</div>
									</td>
									<td>
										<a v-if="slotProps.item.extension == 'xml'" href="javascript:void(0);" @click="viewHtml(slotProps.item.id,slotProps.item.xml_name)" class="badge badge-info-light"> <i class="fa fa-eye"></i> View</a>
										<div v-if="slotProps.item.html_view != null && slotProps.item.extension != 'xml'" class=""><a href="javascript:void(0);" @click="getAssets(user.clinic_id,'phi_documents',slotProps.item.html_view)" class="badge badge-info-light"><i class="fa fa-eye"></i> View</a></div>
										<div v-if="slotProps.item.attachments_xml != null" class=""><a href="javascript:void(0);" @click="getAssets(user.clinic_id,'phi_documents',slotProps.item.attachments_xml)" class="badge badge-info-light" style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;vertical-align: text-top;"> View {{slotProps.item.attachments_xml}}</a></div>
										<div v-if="slotProps.item.pdf_url != null" class=""><a :href="slotProps.item.pdf_url" target="_blank" download class="badge badge-info-light"  style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;vertical-align: text-top;"><i class="fa fa-eye"></i> View {{slotProps.item.pdf_name}}</a></div>
										<div v-if="slotProps.item.zip_url != null" class=""><a :href="slotProps.item.zip_url" target="_blank" download class="badge badge-info-light" style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;vertical-align: text-top;"><i class="fa fa-eye"></i> View {{slotProps.item.zip_name}}</a></div>
										<div v-if="slotProps.item.txt_url != null" class=""><a :href="slotProps.item.txt_url" target="_blank" download class="badge badge-info-light" style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;vertical-align: text-top;"><i class="fa fa-eye"></i> View {{slotProps.item.txt_name}}</a></div>
									</td>
									<td>
										<div v-if="slotProps.item.validated == 'false'">
											<a v-if="slotProps.item.extension == 'xml'" href="javascript:void(0);" @click="validateCCDA(slotProps.item.id,slotProps.item.xml_name)" class="badge badge-success-light">Validate</a>
										</div>
										<div v-else-if="slotProps.item.validated == 'partial'" >
											<Popper arrow content="View Warning(s)." :hover="true" class="helptips cursor-pointer">
												<a href="javascript:void(0);" @click="validateCCDA(slotProps.item.id,slotProps.item.xml_name)" class="badge badge-warning-light text-warning"><i class="fa fa-exclamation-triangle"></i></a>
											</Popper>
										</div>
										<div v-else class="badge badge-success-light">
											Validated
										</div>
									</td>
								</template>
							</Table>
						</div>
					</div>
				</div>
				<div id="validation_error_list" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
					<div class="modal-dialog modal-xl">
						<div class="modal-content permissmodal">
							<div class="modal-header">
								<h4 class="modal-title font-bold m-0">Validation Error List : <slot v-if="file_name != null"> {{file_name.substring(10)}} </slot> </h4>
								<button type="button" class="btn-close btn-xs"  id="csvmodalclose" data-bs-dismiss="modal" aria-label="Close"></button>
							</div>
							<div class="modal-body">
								<div class="bg-success-light p-10 py-5 rounded5 fs-16 mb-15" role="alert">
									The HTML view of the CCDA is generated successfully with the below warning message. Kindly contact your system administrator for more information.
								</div>
								<div class="table-responsive">
									<table class="table table-hover">
										<thead>
											<tr>
												<th>#</th>
												<th>Section Id</th>
												<th>Message</th>
												<th>Path</th>
											</tr>
										</thead>
										<tbody>
											<tr class="hover-primary" v-for="(item, index) in validation_error" :key="index">
												<td>{{index+1}}</td>
												<td style="word-break: break-all;">{{item.componentsectiontemplateid}}</td>
												<td style="word-break: break-all;">{{item.message}}</td>
												<td style="word-break: break-all;">{{item.xpath}}</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	</div>
</template>
<script>
import axios from "@/scripts/axios.js";
import moment from "moment";
import submitMixins from "@/scripts/mixins/submitMixins.js";
import Table from '../../components/base/Table.vue';
import { mapGetters, mapActions } from "vuex";
import Popper from "vue3-popper";
export default {
	mixins: [submitMixins],
	data() {
		return {
			key:1,
			validation_error:[],
			doc_url: process.env.VUE_APP_API_BASE_URL,
			file_name: null,
		}
	},
	created() {
	},
	components: { Table, Popper, },
	methods: {
		formatDate(date, format) {
			return moment(date).format(format);
		},
		validateCCDA(id,file_name){
			this.$store.state.loader = true;
			this.file_name = file_name;
			axios.post("/phimailbox/validateCCDA",{id:id,file_name:file_name})
			.then((response) => {
				this.key++
				this.$store.state.loader = false;
				this.validation_error = response.data.data
				$("#validation_error_list").modal("show");
			});
		},
		refreshList(){
			this.$store.state.loader = true;
			var vm = this;
			axios.post("/phimailbox/receive")
			.then((response) => {
				this.key++
				this.$store.state.loader = false;
			}).catch(function (error) {
				vm.$store.state.loader = false;
				vm.moshaToast(error.response.data.message,'danger');
	      });
		},
		getAssets(clinic_id,foldername,filename){
			axios.post("/get-asset-link",{folder_clinic_id:clinic_id,foldername:foldername,filename:filename})
			.then((response) => {
				window.open(response.data, '_blank').focus();
			});
		},
		viewHtml(id,file_name){
			this.$store.state.loader = true;
			axios.post("/phimailbox/viewHTML",{id:id,file_name:file_name})
			.then((response) => { 
				this.$store.state.loader = false;
				var w = window.open();
				$(w.document.body).html(response.data.data); 
			});
		},
	},
	async beforeCreate() {
    },
	mounted() {

	},
    computed: {
		...mapGetters("auth", ["user"]),
  	},
}
</script>

<style>

</style>