<template>
	<div class="form-group row" v-bind:class="mainClass">
		<label :class="labelClass" >{{ title }} <small v-show="isRequired" class="text-danger asterisksign">*</small></label>
		<div :class="divClass">
			<div class="controls">
				<a href="javascript:void(0)" class="waves-effect waves-light btn me-1 btn-info" data-bs-toggle="modal" data-bs-target="#SignatureModal">Click Signature</a>
				<img :src="this.$store.state.signature" class="img-fluid mt-10">
				<!-- Signature Modal Start-->
	             <div class="modal fade" id="SignatureModal" tabindex="-1" role="dialog" aria-labelledby="SignatureModal" aria-hidden="true">
	                <div class="modal-dialog modal-lg" role="document">
	                  <div class="modal-content">
	                    <div class="modal-header">
	                      <h3 class="modal-title fw-900 mt-0">Signature</h3>
	                    </div>
	                    <div class="modal-body">
	                      
	                      <div class="box p-15">
	                        <div class="mdc-tab">
	                          <ul class="nav nav-tabs mt-0 text-center text-sm-start" role="tablist">
	                            <li class="nav-item"> <a @click="tabchange('digtal_signature')" class="nav-link active" data-bs-toggle="tab" href="#signature_pad" role="tab">Signature Pad</a> </li>
	                            <li class="nav-item"> <a @click="tabchange('free_text')" class="nav-link" data-bs-toggle="tab" href="#type" role="tab">Type</a> </li>
	                            <li class="nav-item"> <a @click="tabchange('image')" class="nav-link" data-bs-toggle="tab" href="#upload" role="tab">Upload</a> </li>
	                          </ul>
	                        </div>

	                        <div class="tab-content">
	                          <div class="tab-pane active" id="signature_pad" role="tabpanel">
                                <div class="text-center">
                                  <VueSignaturePad ref="signaturePad" style="border:2px solid rgb(204 217 223);width: 100%;height: 150px;border-radius: 3px;" :options="{onBegin: () => {$refs.signaturePad.resizeCanvas()}}" />
                                    <a href="javascript:void(0)" @click="clear" class="waves-effect waves-light btn btn-danger-light mt-10">Clear</a>
                                </div>
	                          </div>
	                          <div class="tab-pane" id="type" role="tabpanel">
	                              <div class="mb-20">
	                                <input type="text" class="form-control" placeholder="Enter your Name" v-model="signature_type">
	                              </div>
	                              <div class="row" v-if="signature_type != ''">
	                                <div class="col-12 col-md-6" id="border1" @click="signature_select(1)">
	                                  <div class="box text-center border-danger">
	                                    <p class="l-h-n text-center py-15 mb-0" style="font-family: 'Mea Culpa';font-size: 42px;word-break: break-word;">{{signature_type}}</p>
	                                  </div>
	                                </div>
	                                <div class="col-12 col-md-6" id="border2" @click="signature_select(2)">
	                                  <div class="box text-center">
	                                    <p class="l-h-n text-center py-15 mb-0" style="font-family: 'Parisienne';font-size: 42px;word-break: break-word;">{{signature_type}}</p>
	                                  </div>
	                                </div>
	                                <div class="col-12 col-md-6" id="border3" @click="signature_select(3)">
	                                  <div class="box text-center">
	                                    <p class="l-h-n text-center py-15 mb-0" style="font-family: 'Tangerine';font-size: 42px;word-break: break-word;">{{signature_type}}</p>
	                                  </div>
	                                </div>
	                                <div class="col-12 col-md-6" id="border4" @click="signature_select(4)">
	                                  <div class="box text-center">
	                                    <p class="l-h-n text-center py-15 mb-0" style="font-family: 'brush-script-std';font-size: 42px;word-break: break-word;">{{signature_type}}</p>
	                                  </div>
	                                </div>
	                                <div class="col-12 col-md-6" id="border5" @click="signature_select(5)">
	                                  <div class="box text-center">
	                                    <p class="l-h-n text-center py-15 mb-0" style="font-family: 'Great Vibes';font-size: 42px;word-break: break-word;">{{signature_type}}</p>
	                                  </div>
	                                </div>
	                                <div class="col-12 col-md-6" id="border6" @click="signature_select(6)">
	                                  <div class="box text-center">
	                                    <p class="l-h-n text-center py-15 mb-0" style="font-family: 'Comforter';font-size: 42px;word-break: break-word;">{{signature_type}}</p>
	                                  </div>
	                                </div>
	                              </div>
	                          </div>
	                          <div class="tab-pane" id="upload" role="tabpanel">
	                            <input type="file" class="form-control" id="myfile" @change="encodeImageFileAsURL">
	                          </div>
	                        </div>
	                      </div>

	                    </div>
	                    <div class="modal-footer">
	                      <button type="button" @click="signature_save" class="waves-effect waves-light btn btn-info">Save</button>
	                      <button type="button" id="close-model-signature" class="waves-effect waves-light btn btn-danger" data-bs-dismiss="modal">Close</button>
	                    </div>
	                  </div>
	                </div>
	            </div>
	            <!-- Signature Modal End-->      
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			title: {
		      type: String,
		      default: ""
		    },
		    labelClass:{
		      type: String,
		      default: "col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-form-label mb-0 text-sm-end"
		    },
		    isRequired: {
		      type: Boolean,
		      default: false
		    },
		    divClass:{
		      type: String,
		      default: "col-12 col-sm-6 col-md-8 col-lg-5 col-xl-3"
		    },
		    mainClass:{
		    	type: String,
		    	default: ""
		    }
		},
		data() {
		    return {
		      signature:'',
		      signature_type:'',
		      signature_selects:'#border1',
		      imagebase64:'',
		      type_image:'',
		      tabname :'digtal_signature',
			}
		},
		methods:{
			encodeImageFileAsURL(){
		    	var imagebase64 = '';
		      	var element = document.getElementById('myfile');
		      	var file = element.files[0];  
		      	var reader = new FileReader();  
		      	reader.onloadend = function() {  
		        	imagebase64 = reader.result;  
		      	}  
		      	reader.readAsDataURL(file);
		      	this.imagebase64 = reader;
		    },
		    tabchange(value){
		    	this.tabname = value;
		    },
		    signature_save(){
				var errMsg = "Signature is required.";
      			if(this.tabname == 'digtal_signature'){
					const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
        			this.signature = data;
        			console.log(this.signature);
					if(isEmpty) {
						alert(errMsg)
						return false;
					}
				}
				if(this.tabname == 'free_text'){
        			var element = $(this.signature_selects+" .box"); // global variable
        			var getCanvas; // global variable
        			var thisObj = this;
					if(element.length<=0) {
						alert(errMsg)
						return false;
					}
        			html2canvas(element, {
          				onrendered: function (canvas) {
	            			getCanvas = canvas;
	            			var imgageData = getCanvas.toDataURL("image/jpeg");
	            			thisObj.signature = getCanvas.toDataURL("image/jpeg");
	            			thisObj.$store.state.signature = getCanvas.toDataURL("image/jpeg");
          				}
        			});
        			console.log(thisObj.signature);
      			}
      			if(this.tabname == 'image'){
					console.log(this.imagebase64.result);
					if(!this.imagebase64.result) {
						alert(errMsg)
						return false;
					}
        			this.signature = this.imagebase64.result;
        			console.log(this.signature);
      			}
				document.getElementById("close-model-signature").click();
      			setTimeout(this.$store.state.signature = this.signature, 2000);
    		},
    		signature_select(id){
	      		$('.box').removeClass('border-danger');
      			$('#border'+id+' .box').addClass('border-danger');
      			this.signature_selects = '#border'+id;
    		},
    		clear(){
				this.$store.state.signature = "";
		    	this.$refs.signaturePad.clearSignature();
		    },
		}
	}
</script>