import axios from "@/scripts/axios.js";

export default {
  methods: {
    moshaToast(msg, type) {
      var toastColor;
      if (type == "success") {
        toastColor = "#05825f";
      } else if (type == "info") {
        toastColor = "#0073dc";
      }else if (type == "danger") {
        toastColor = "#ee3158";
      }else {
        toastColor = "#ff4444";
      }

      this.$moshaToast(msg, {
          timeout: 4000,
          position: "top-right",
          type: type,
          transition: "slide",
          showIcon: true,
          hideProgressBar: false,
          toastBackgroundColor: toastColor,
          timeout: 2000,
        });
    },
    reloadPage() {
      this.window.location.reload(true);
    },

    postDataError(err) {
      console.log("error occured");
    },

    postDataSuccess() {},
    async postData(url, data = {}) {
      try {
        const result = await axios.post(url, data);

        if (result && result.data.status === "success") {
          this.postDataSuccess();
          return result.data.data;
        } else {
          this.postDataError(`Could not get data`);
        }
      } catch (err) {
        if (err) {
          this.postDataError(err);
        }
      }
      return "";
    },

    // Get Data Methods

    getDataError(err) {
      console.log(err);
    },
    getDataSuccess() {},

    async getData(url, data = {}) {
      try {
        // this.$store.state.loader = true;
        const result = await axios.get(url);
        if (result && result.data.status === "success") {
          this.getDataSuccess();
          return result.data.data;
        } else {
          this.getDataError(`Could not get data`);
        }
      } catch (err) {
        if (err) {
          this.getDataError(err);
        }
      }
      // this.$store.state.loader = false;
      return "";
    },

    //  FORM POST METHODS

    createFormData() {
      // clear form data : if data needs to send using form data and there is unwanted field  which needs to be removed
      // create below method in component and delete the property before creating form data

      this.clearFormDataUnwanted();
      let bodyFormData = new FormData();

      for (const [key, value] of Object.entries(this.form)) {
        if (Array.isArray(this.form[key])) {
          this.form[key].forEach((item) => {
            bodyFormData.append(`${key}[]`, item);
          });
        } else {
          bodyFormData.append(key, value);
        }
      }
      return bodyFormData;
    },

    clearFormDataUnwanted() {},
    async postForm(url, dataType, validationRequired = true, type = "json") {

      this.$store.state.loader = true;
      let isValidated = true;

      if (validationRequired) {
        isValidated = await this.v$.form.$validate();        
      }
      if (isValidated) {

        if (type != "json") {
          var bodyFormData = new FormData();
          for (let [key, value] of Object.entries(this.form)) {
            if (Array.isArray(this.form[key])) {
              this.form[key].forEach((item) => {
                if(item === null || item === '' || item === 'null'){
                  item = "";
                }
                bodyFormData.append(`${key}[]`, item);
              });
            } else {
              if(value === null || value === '' || value === 'null'){
                value = "";  
              }
              bodyFormData.append(key, value);
            }
          }
        }
        try {
          if (type != "json") {
            var result = await axios.post(url, bodyFormData);
          } else {
            var result = await axios.post(url, this.form);
          }
          this.formPostSuccess(result.data.data);
          this.$store.state.loader = false;
        } catch (error) {
          if (error) {
            this.$store.state.loader = false;
            this.$store.state.processing = false;
            // handle validation error
            if (error.response.status === 422) {
              this.$store.state.patientDetail.renderComponent = true;
              // merge the errors into the validation results
              if (validationRequired) {
                Object.assign(
                  this.$store.state[dataType].vuelidateExternalResults.form,
                  error.response.data.data
                );

                // scroll when error
                for (const [key, value] of Object.entries(
                  this.vuelidateExternalResults.form
                )) {
                  if (this.v$.form[key].$errors.length > 0) {
                    const myEl = document.getElementById(key);
                    if (myEl) {
                      this.$smoothScroll({
                        scrollTo: myEl,
                      });
                      break;
                    }
                  }
                }
              }
            } else {
              if (error.response.data && error.response.data.message) {
                this.moshaToast(error.response.data.message,'danger');
              } else {
                this.moshaToast(`Error! Could not save data. Please try again`,'danger');
              }
            }
          }
        }
      } else {
        this.$store.state.loader = false;
        this.$store.state.processing = false;
        // scroll when error
        for (const [key, value] of Object.entries(this.v$.form)) {
          if (
            this.v$.form[key].$errors &&
            this.v$.form[key].$errors.length > 0
          ) {
            const myEl = document.getElementById(key);

            if (myEl) {
              this.$smoothScroll({
                scrollTo: myEl,
              });
              break;
            }
          }
        }
      }
    },
    async getTpConfigByKey(key ,subkey = '') {

      try {
        var url = `/getTpConfigByKey/${key}`;
        if (subkey) {
          url += '/'+subkey;
        }

        const result = await axios.get(url);
        if (result && result.data.status === "success") {
          return result.data.data;
        } else {
          this.getDataError(`Could not get data`);
        }
      } catch (err) {
        if (err) {
          this.getDataError(err);
        }
      }
      return "";
    },
  },
};
