import {
  helpers,
  required,
  email,
  minLength,
  maxLength,
  sameAs,
  numeric,
} from "@vuelidate/validators";

const unreaadmessage = {
  namespaced: true,

  state: {
    editID: "1000014",
    tableData: {
      header: [
        "Date",
        "From",
        "To",
        "Message",
        "Action",
      ],
    },
    listUrl: "/patient-portal/unreadmessages",
    
  },
  getters: {
    getTableHeaders: (state) => state.header,
    getTableContents: (state) => state.list,
    getListURL: (state) => state.listUrl,
  },
  mutations: {},
  actions: {},
}

export default unreaadmessage;