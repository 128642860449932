<template>
	<div class="content-wrapper">
		<div class="container-full">
			<div id="qrdaimport" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
				aria-hidden="true">
				<div class="modal-dialog">
					<div class="modal-content">
						<div class="modal-header">
							<h4 class="modal-title font-bold"> Import Patient Data From QRDA I Files </h4>
							<button type="button" class="btn-close btn-xs" data-bs-dismiss="modal"
								aria-label="Close"></button>
						</div>
						<div class="modal-body">
							<div class="form-group">
								<label class="form-label">Upload the QRDA I Files</label>
								<input type="file" id="file" class="form-control" />
							</div>
						</div>
						<div class="modal-footer">
							<button type="button" class="waves-effect waves-light btn btn-info m-0"> Upload </button>
						</div>
					</div>
				</div>
			</div>
			<div id="insertpatientcsv" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
				aria-hidden="true">
				<div class="modal-dialog modal-lg">
					<div class="modal-content">
						<div class="modal-header">
							<h4 class="modal-title font-bold m-0">Import Patient via CSV</h4>
							<button type="button" class="btn-close btn-xs" id="csvmodalclose" data-bs-dismiss="modal"
								aria-label="Close"></button>
						</div>
						<div class="modal-body">
							<div class="form-group row">
								<label
									class="col-12 col-sm-12 col-md-3 col-lg-4 col-xl-4 mb-0 col-form-label text-md-end">File
									to upload</label>
								<div class="col-12 col-sm-12 col-md-9 col-lg-8 col-xl-8">
									<input type="file" id="file_field" ref="file" class="form-control"
										v-on:change="FileUpload($event)" />
								</div>
							</div>
							<label> Sample Glimpse : </label>
							<img src="images/sample_image.png">
							<div class="form-group row">
								<div class="col-12 col-sm-12 col-md-9 col-lg-8 col-xl-8">
									<a href="sample_import.csv" download> Download sample file</a>
								</div>
							</div>
							<div class="error-msg" id="GroupFileError">
								<ul class="mb-0 p-0 fs-14"></ul>
							</div>
						</div>
						<div class="modal-footer" v-if="permissioncheck(this.$store.state.permission.create_update)">
							<form-btn title="Upload File" btnCls="waves-effect waves-light btn btn-info float-sm-end"
								@click="submitForm()"></form-btn>
							<button type="button" @click="closecsvmodel" class="waves-effect waves-light btn btn-danger"
								data-bs-dismiss="modal" aria-label="Close"> Close </button>
						</div>
					</div>
				</div>
			</div>
			<section class="content">
				<div class="box bg-white mb-10">
					<div class="box-body p-10">
						<div class="row justify-content-between">
							<div class="col-12 col-sm-12 col-md-7 col-lg-8 col-xl-6">
								<div class="input-group float-start w-md-p60 mb-5 me-1">
									<input type="text" data-provide="media-search" @keyup="globleSearch"
										v-model="filter.search_text" class="form-control" placeholder="Search" />
									<button type="button" class="btn btn-info-light btn-md check_box px-2"
										data-bs-toggle="dropdown">
										More Filters <i class="fa fa-caret-down"></i>
									</button>
									<div v-if="search_result.length > 0 && filter.search_text != '' && search_drop == true"
										class="glbsearch-list">
										<div v-for="(item, index) in search_result" :key="index" class="global_search">
											<router-link :to="{ name: 'PatientDetails', params: { id: item.unique_id }, }"
												@click="increment">
												<img v-if="item.profile_avatar" :src="item.profile_avatar"
													class="img-fluid" />
												<img v-else src="/images/patient-avtar.png" class="img-fluid" />
												<div class="filter_results">
													<p>{{ item.first_name }} {{ item.last_name }}</p>
													<span v-if="item.date_of_birth != null">
														{{ formatDate(item.date_of_birth, "MMM D, YYYY") }}
													</span>
												</div>
											</router-link>
										</div>
									</div>
									<div v-if="filter.search_text != '' && search_result.length == 0 && search_drop == true"
										class="global_search glbsearch-list">
										<div class="filter_results">
											<p>No patients found</p>
										</div>
									</div>
								</div>
								<button type="button" @click="applyFilter"
									class="waves-effect waves-light btn btn-info me-1" style="height: 37px;"> Search
								</button>
								<button type="button" @click="resetFilter" class="waves-effect waves-light btn btn-info"
									style="height: 37px;"> Reset</button>
								<a href="#" class="m-3" data-bs-toggle="modal" data-bs-target="#patientlistdocument"
									title="Reference of Knowledge Base" v-on:click.prevent="reset()"> <i
										class="fa fa-question-circle pt-1 fa-lg"></i></a>
								<fieldset class="table_filters mt-5 w-p100">
									<div class="form-group mb-0">
										<input type="radio" v-model="filter.duplicate" id="possibleduplicate"
											name="filter_list" value="true" @change="filterchange('duplicate')"
											class="filled-in chk-col-info" />
										<label for="possibleduplicate">Possibility of Duplicate Patients</label>
									</div>
									<div class="form-group mb-0">
										<input type="radio" v-model="filter.having_followup" id="patientsdates"
											name="filter_list" value="true" @change="filterchange('having_followup')"
											class="filled-in chk-col-info" />
										<label for="patientsdates">Patients with follow-up appointments</label>
									</div>
									<div class="form-group mb-0">
										<input type="radio" v-model="filter.active_inactive" id="patientsinclude"
											name="filter_list" value="true" @change="filterchange('active_inactive')"
											class="filled-in chk-col-info" />
										<label for="patientsinclude">All the Patients with Inactive status</label>
									</div>
									<div class="form-group mb-0">
										<input type="radio" v-model="filter.inactive_only" id="patientsonly"
											name="filter_list" value="true" @change="filterchange('inactive_only')"
											class="filled-in chk-col-info" />
										<label for="patientsonly"> Inactive Patients</label>
									</div>
									<div class="form-group mb-0">
										<input type="radio" v-model="filter.upcoming_appointment" id="upcoming_appointment"
											name="filter_list" value="true" @change="filterchange('upcoming_appointment')"
											class="filled-in chk-col-info" />
										<label for="upcoming_appointment">Upcoming appointments of next 5 days</label>
									</div>

									<!-- this variable(approaching_appointment) used for last 5 days appts. -->
									<div class="form-group mb-0">
										<input type="radio" v-model="filter.approaching_appointment"
											id="approaching_appointment" name="filter_list" value="true"
											@change="filterchange('approaching_appointment')"
											class="filled-in chk-col-info" />
										<label for="approaching_appointment">Previous appointments for past 5 days</label>
									</div>
									<div class="form-group mb-0">
										<input type="radio" v-model="filter.new_patients" id="new_patients"
											name="filter_list" value="true" @change="filterchange('new_patients')"
											class="filled-in chk-col-info" />
										<label for="new_patients">Recently added Patients in PureEHR</label>
									</div>
									<div class="form-group mb-0">
										<input type="radio" v-model="filter.yesterday_appointment"
											id="yesterday_appointment" name="filter_list" value="true"
											@change="filterchange('yesterday_appointment')"
											class="filled-in chk-col-info" />
										<label for="yesterday_appointment">Yesterday appointments</label>
									</div>
									<div class="form-group mb-0">
										<input type="radio" v-model="filter.today_appointment" id="today_appointment"
											name="filter_list" value="true" @change="filterchange('today_appointment')"
											class="filled-in chk-col-info" />
										<label for="today_appointment">Today appointments</label>
									</div>
									<div class="form-group mb-0">
										<input type="radio" v-model="filter.office" id="office"
											name="filter_list" value="true" @change="filterchange('office')"
											class="filled-in chk-col-info" />
										<label for="office">Office locations</label>
										<select class="form-control office_list" :class="showoffice==false?'d-none' : ''" v-model="filter.office_id">
											<option value="" selected="selected" disabled="disabled">Select Office</option>
											<option v-for="(office,index) of offices" :value="office.id" :key="index">{{office.name}}</option>
										</select>
									</div>
								</fieldset>
							</div>
							<div class="col-12 col-sm-12 col-md-5 col-lg-4 col-xl-4 text-md-end">
								<router-link v-if="permissioncheck(this.$store.state.permission.create_update)"
									to="/patient/add" class="waves-effect waves-light btn btn-info me-1 mt-1">
									<i class="fa fa-plus fs-14"></i> New Patient
								</router-link>
								<div class="btn-group mt-1 me-1">
									<button type="button"
										class="waves-effect waves-light btn btn-info-light dropdown-toggle"
										data-bs-toggle="dropdown" aria-expanded="false">Import</button>
									<div class="dropdown-menu" style="">
										<a href="javascript:void(0)" class="dropdown-item" data-bs-toggle="modal"
											data-bs-target="#insertpatientcsv">Import Patient via CSV</a>
									</div>
								</div>
								<a href="javascript:void(0);" class="waves-effect waves-light btn btn-info me-1 mt-1"
									@click="exportCCDA">Export CCDA</a>
								<Popper arrow :hover="true" class="helptips cursor-pointer ms-2 mt-1">
									<i class="fa fa-info-circle" aria-hidden="true"></i>
									<template #content>
										<div>Exporting Data: This action will export information only for patients who currently have appointments.</div>
									</template>
								</Popper>
							</div>
						</div>
					</div>
				</div>
				<div class="box">
					<div class="box-body">
						<data-table-server dataType="patientList" dataMethod="post" @checkall="checkall" :key="key">
							<template v-slot="slotProps">
								<td>
									<input v-bind:id="'checkbox_id' + slotProps.item.id" name="patient_id" type="checkbox"
										class="filled-in chk-col-info myCheckbox" :value="slotProps.item.id"
										@click="select(slotProps.item.id, $event)">
									<label v-bind:for="'checkbox_id' + slotProps.item.id" class="m-0 text-start fs-16"
										style="padding-left: 18px;"></label>
								</td>
								<td v-if="permissioncheck(this.$store.state.permission.Access_Patient_Chart)">
									<router-link :to="{ name: 'PatientDetails', params: { id: slotProps.item.unique_id }, }"
										:title="'View Chart of ' + slotProps.item.first_name + '(' + slotProps.item.chart_id + ')'"
										class="fw-500">
										{{ slotProps.item.chart_id }}
									</router-link>
								</td>
								<td v-else>
									{{ slotProps.item.chart_id }}
								</td>
								<td>{{ slotProps.item.first_name }}</td>
								<td>{{ slotProps.item.last_name }}</td>
								<td>{{ slotProps.item.office_phone }}</td>
								<td>{{ slotProps.item.cell_phone }}</td>

								<td v-if="slotProps.item.last_appointment_date != null">{{
									slotProps.item.last_appointment_date }}</td>
								<td v-else></td>

								<td v-if="slotProps.item.next_appointment_date != null">{{
									slotProps.item.next_appointment_date }}</td>
								<td v-else></td>

							</template>
						</data-table-server>
					</div>
				</div>
			</section>
			<!--document model start-->
			<div id="patientlistdocument" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
				aria-hidden="true">
				<div class="modal-dialog modal-md">
					<div class="modal-content">
						<div class="modal-header">
							<h3 class="modal-title fw-900 mt-0">Reference of Knowledge Base</h3>
						</div>
						<div class="modal-body overflow-auto" style="max-height: 350px;">
							<table class="table table-bordered">
								<thead>
									<tr>
										<th scope="col">No.</th>
										<th scope="col">Knowledge Base Articles</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>1</td>
										<td> <a :href="path + 'how-to-apply-filters-on-patient-list-in-pureehr'"
												target="_blank">How To Apply Filters On Patient List In PureEHR?</a></td>
									</tr>
									<tr>
										<td>2</td>
										<td> <a :href="path + 'how-to-add-a-new-patient-in-pureehr'" target="_blank">How To
												Add A New Patient In PureEHR?</a></td>
									</tr>
									<tr>
										<td>3</td>
										<td> <a :href="path + 'how-to-view-list-of-inactive-patients-in-pureehr'"
												target="_blank">How To View List Of Inactive Patients In PureEHR?</a></td>
									</tr>
								</tbody>
							</table>
							<Search :key="searchKey"></Search>
						</div>
						<div class="modal-footer">
							<button type="button" data-bs-dismiss="modal" aria-label="Close"
								class="waves-effect waves-light btn btn-danger">Close</button>
						</div>
					</div>
				</div>
			</div>
			<!--document model end-->
		</div>
	</div>
</template>

<script>
import axios from "@/scripts/axios.js";
import moment from "moment";
import DataTableServer from "@/components/base/DataTableServer.vue";
import permissionMixins from "@/scripts/mixins/permission.js";
import FormBtn from "@/components/base/formFields/formBtn.vue";
import $ from "jquery";
import submitMixins from "@/scripts/mixins/submitMixins.js";
import Search from "@/components/Search.vue";
import Popper from "vue3-popper";

export default {
	mixins: [permissionMixins, submitMixins],
	name: "Home",
	components: { DataTableServer, FormBtn, Search, Popper },
	data() {
		return {
			key: 0,
			search_result: [],
			search_drop: true,
			providers: [],
			csvform: {
				document_name: '',
			},
			path: process.env.VUE_APP_KB_DOCUMENT,
			searchKey: 0,
			patientIds: [],
			offices: [],
			showoffice:false,
		};
	},
	mounted() {
		$(".table_filters").hide();
		$(".check_box").click(function () {
			if ($(".table_filters").hasClass("d-block")) {
				$(".table_filters").removeClass("d-block");
			} else {
				$(".table_filters").addClass("d-block");
			}
		});

		axios.get("/provider/provider-offices").then(response => {
          this.offices = response.data;
        });
	},
	methods: {
		exportCCDA() {
			this.$store.state.loader = true;
			axios.post("exportPatientCCDA", { patient_ids: this.patientIds }, { responseType: 'blob' }).then((response) => {
				this.$store.state.loader = false;
				var a = document.createElement('a');
				if(response.data.size>0){
					var blob = new Blob([response.data], { type: "application/zip" })
					var url = window.URL.createObjectURL(blob);
					a.href = url;
					a.download = 'CCDAexport.zip';
					document.body.append(a);
					a.click();
					a.remove();
					window.URL.revokeObjectURL(url);
				}else{
					this.moshaToast("No CCDA found to export", "danger");
				}
			}).catch(error => {
				this.moshaToast("No CCDA found to export", "danger");
			});
		},
		checkall() {
			this.patientIds = [];
			var $this = this;
			var checked = $('#checkAll').is(':checked');
			if (checked == true) {
				var $this = this;
				$('.myCheckbox').prop('checked', true);
				$('.myCheckbox').each(function () {
					var patient_id = $(this).val()
					$this.patientIds.push(parseInt(patient_id));
				});
			} else {
				$('.myCheckbox').prop('checked', false);
			}
		},
		select(patient_id, event) {
			let id = patient_id;
			if (!event.target.checked) {
				this.patientIds.splice(this.patientIds.indexOf(id), 1);
				$('#checkAll').prop('checked', false);
			}
			else {
				this.patientIds.push(id);
			}
		},
		reset() {
			this.searchKey = this.searchKey + 1;
		},
		applyFilter() {
			this.key++;
			this.search_drop = false;
		},
		formatDate(date, format) {
			return moment(date).format(format);
		},
		globleSearch() {
			this.search_drop = true;
			if (this.filter.search_text.length > 1) {
				var data = { search_text: this.filter.search_text };
				axios
					.post("patient/patient-search", data)
					.then((response) => (this.search_result = response.data.data));
			}
		},
		increment() {
			this.filter.search_text = "";
			this.search_result = [];
		},
		checklength() {
			if (this.filter.search_text == "") {
				this.increment();
			}
		},
		resetFilter() {
			this.filter.search_text = "";
			this.search_result = [];
			this.filter.duplicate = false;
			this.filter.inactive_only = false;
			this.filter.active_inactive = false;
			this.filter.having_followup = false;
			this.filter.upcoming_appointment = false;
			this.filter.approaching_appointment = false;
			this.filter.new_patients = false;
			this.filter.yesterday_appointment = false;
			this.filter.today_appointment = false;
			this.filter.office = false;
			this.showoffice = false;
			this.filter.office_id = 0;
			this.applyFilter();
		},
		filterchange(field) {
			this.filter.duplicate = false;
			this.filter.inactive_only = false;
			this.filter.active_inactive = false;
			this.filter.having_followup = false;
			this.filter.upcoming_appointment = false;
			this.filter.approaching_appointment = false;
			this.filter.new_patients = false;
			this.filter.yesterday_appointment = false;
			this.filter.today_appointment = false;
			this.filter[field] = true;

			if(field == 'office'){
				this.showoffice = true;
			}else{
				this.showoffice = false;
				this.filter.office_id = 0;
			}
		},
		closecsvmodel() {
			this.csvform.document_name = "";
			$("#csvmodalclose").click();
		},
		FileUpload(event) {
			this.csvform.document_name = this.$refs.file.files[0];
			$("#GroupFileError").find("ul").html("");
			const size = (this.$refs.file.files[0].size / 1024 / 1024).toFixed(2);
			if (size > 50) {
				$("#GroupFileError").find("ul").append('<li class="d-block confirmerror"> File size should not exceed more than 50 MB.</li>');
				this.csvform.document_name = "";
				$("#file_field").val("");
			}
			const acceptedImageTypes = [
				"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
				"application/vnd.ms-excel",
				"text/csv",
			];
			if (!acceptedImageTypes.includes(this.$refs.file.files[0].type)) {
				$("#GroupFileError").find("ul").append('<li class="d-block confirmerror"> Only xls,xlsx & csv formats are supported.</li>');
				this.csvform.document_name = "";
				$("#file_field").val("");
			}
		},
		submitForm() {
			if (this.csvform.document_name == '') {
				$("#GroupFileError").find("ul").append('<li class="d-block confirmerror"> Please select file first.</li>');
			} else {
				var bodyFormData = new FormData();
				for (const [key, value] of Object.entries(this.csvform)) {
					if (Array.isArray(this.csvform[key])) {
						this.csvform[key].forEach((item) => {
							if (item == null || item == '' || item == 'null') {
								item = "";
							}
							bodyFormData.append(`${key}[]`, item);
						});
					} else {
						if (value == null || value == '' || value == 'null') {
							value = "";
						}
						bodyFormData.append(key, value);
					}
				}
				this.$store.state.loader = true;
				axios.post("/insert-patient-from-csv", bodyFormData).then((res) => {
					if (res.data.status === "success") {
						this.$store.state.loader = false;
						this.moshaToast("Patients added successfully.", "success");
						this.closecsvmodel();
					} else {
						this.$store.state.loader = false;
						this.moshaToast(res.data.message, 'danger');
						this.closecsvmodel();
					}
				});
			}
		},
	},
	async beforeCreate() {
		axios.post("/get-user-provider")
			.then((response) => {
				this.providers = response.data.data;
			});
	},
	computed: {
		filter() {
			return this.$store.state.patientList.filter;
		},
		list() {
			return this.$store.state.patientList.list;
		},
	},
	watch: {
		"$route.name": function () {
			this.increment();
		},
		"filter.search_text": function () {
			this.checklength();
		},
		"list": function () {
			const ids = this.list.map((item) => {
				return item.id;
			}).filter(n => n);
			axios.post("patient/apptdata", { ids: ids })
				.then((res) => {
					$('#patientList tbody tr').each(function (index) {
						var row = $(this);
						var lastCell = row.find('td:last');
						var secondLastCell = row.find('td:nth-last-child(2)');
						lastCell.text(res.data.data[index].next_appointment_date);
						secondLastCell.text(res.data.data[index].last_appointment_date);
					});
				});
		},
	},
};
</script>
